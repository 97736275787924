import { tutorFilterProps, addTutorUserDetailPayload } from "redux/tutor/tutor.const"

const profilePageDocumentUploadPayload = {
    isProfilePictureUploadEnabled: false,
    isVideoUploadEnabled: false
}

export const TUTOR_INITIAL_STATE = {
    tutorList: {
        isLoading: false,
        tutorList: null,
        filteredTutorList: null,
        errorMsg: null
    },

    tutorPublicProfile: {
        isLoading: false,
        tutorPublicProfile: null,
        errorMsg: null
    },

    myTutorList: {
        isLoading: false,
        myTutorList: null,
        filteredMyTutorList: null,
        errorMsg: null
    },

    favTutorList: {
        isLoading: false,
        favTutorList: null,
        filteredFavTutorList: null,
        errorMsg: null
    },

    addFavTutor: {
        isLoading: false,
        errorMsg: null
    },

    tutorUserDetail: {
        isLoading: false,
        tutorUserDetail: null,
        errorMsg: null
    },

    addTutorUserDetail: {
        isLoading: false,
        addTutorUserDetail: null,
        payload: addTutorUserDetailPayload,
        errorMsg: null
    },

    tutorUserPrice: {
        isLoading: false,
        tutorUserPrice: null,
        errorMsg: null
    },

    addTutorUserPrice: {
        isLoading: false,
        errorMsg: null
    },

    tutorUserLesson: {
        isLoading: false,
        tutorUserLesson: null,
        errorMsg: null
    },

    addTutorUserLesson: {
        isLoading: false,
        errorMsg: null
    },

    tutorUserLanguageList: {
        isLoading: false,
        tutorUserLanguageList: null,
        errorMsg: null
    },

    addTutorLanguageDetail: {
        isLoading: false,
        errorMsg: null
    },

    tutorUserCategoryList: {
        isLoading: false,
        tutorUserCategoryList: null,
        filteredTutorUserCategoryList: null,
        errorMsg: null
    },

    addTutorCategoryDetail: {
        isLoading: false,
        errorMsg: null
    },

    profilePageDocumentUpload: {
        profilePageDocumentUploadPayload: profilePageDocumentUploadPayload
    },


    // new modified tutor api

    // new tutor language api
    // tutor category api
    tutorLanguageList: {
        isLoading: false,
        data: null,
        message: null
    },
    addTutorLanguageDetail: {
        isLoading: false,
        message: null
    },
    updateTutorLanguage: {
        isLoading: false,
        message: null
    },
    updateTutorLanguageOrder: {
        isLoading: false,
        message: null
    },
    deleteTutorLanguage: {
        isLoading: false,
        message: null
    },

    // new modified tutor tag api
    tutorTagList: {
        isLoading: false,
        data: null,
        message: null
    },
    addTutorTagDetail: {
        isLoading: false,
        message: null
    },
    updateTutorTagOrder: {
        isLoading: false,
        message: null
    },
    deleteTutorTag: {
        isLoading: false,
        message: null
    },

    // tutor category api
    tutorCategoryList: {
        isLoading: false,
        data: null,
        message: null
    },
    addTutorCategoryDetail: {
        isLoading: false,
        message: null
    },
    updateTutorCategory: {
        isLoading: false,
        message: null
    },
    updateTutorCategoryOrder: {
        isLoading: false,
        message: null
    },
    deleteTutorCategory: {
        isLoading: false,
        message: null
    },

    // new tutor onboarding tutor apis
    tutorDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addTutorDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    updateTutorDetail: {
        isLoading: false,
        message: null
    },

    filterProps: tutorFilterProps
}