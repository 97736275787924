import { useCallback } from 'react';

import { IoIosCheckmark } from 'react-icons/io';
import { BsInfoCircle } from 'react-icons/bs';

import FloatingLabelSelect from 'components/floating/floatingLabelSelect';
import MuiDatePicker from 'components/MUIComponents/MuiDatePicker';

import { availabilityDetailBtnConst } from "pages/auth/preferences/data";
import { InputTextWithLabel } from "pages/auth/preferences/commonComponents/InputTextWithLabel";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModifyUserAvailabilityDetailPayload } from 'redux/availability/availability.slice';
import {availabilityEndTypeEnum, availabilityIsRecurringEnum, weekAvailTimeSlots } from 'redux/availability/availability.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';

const BasicInformation = () => {
    const { userAvailabilityDetail, modifyUserAvailabilityDetail } = useAppState((s) => s.availability)

    const dispatcher = useAppDispatcher()

    const onHandleRecurringAvailability = useCallback((key, value) => {
        let payload = {
            [key]: value
        }

        if (value === availabilityIsRecurringEnum?.RECURRING?.value) {
            payload = {
                ...payload,
                weeklyTimeSlots: userAvailabilityDetail?.data?.result?.weeklyTimeSlots || weekAvailTimeSlots,
                dateTimeSlots: userAvailabilityDetail?.data?.result?.dateTimeSlots || [],
                endType: userAvailabilityDetail?.data?.result?.endType || availabilityEndTypeEnum.FOREVER.value,
                startDateTime: userAvailabilityDetail?.data?.result?.startDateTime || dayjs()?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss"),
                endDateTime: userAvailabilityDetail?.data?.result?.endDateTime || null,
                weeklyCycle: userAvailabilityDetail?.data?.result?.weeklyCycle || null
            }
        } else {
            payload = {
                ...payload,
                dateTimeSlots: userAvailabilityDetail?.data?.result?.dateTimeSlots || [],
                weeklyTimeSlots: userAvailabilityDetail?.data?.result?.weeklyTimeSlots || weekAvailTimeSlots,
                endType: null,
                startDateTime: null,
                endDateTime: null,
                weeklyCycle: null
            }
        }
        dispatcher(setModifyUserAvailabilityDetailPayload({
            ...modifyUserAvailabilityDetail?.payload,
            ...payload
        }))
    }, [modifyUserAvailabilityDetail?.payload, userAvailabilityDetail?.data?.result])

    const onHandleChangeText = useCallback((key, value) => {
        if (modifyUserAvailabilityDetail?.isLoading) return;

        dispatcher(setModifyUserAvailabilityDetailPayload({
            ...modifyUserAvailabilityDetail?.payload,
            [key]: value
        }))
    }, [modifyUserAvailabilityDetail])

    return (
        <div className={"w-full grid grid-cols-12 gap-y-10 sm:gap-y-10 sm:gap-x-10"}>

            <div className={"col-start-1 col-span-full flex items-center justify-start gap-5"}>
                <span className={"font-bodyPri font-medium text-gray-900 text-base"}>
                    {"Availability ID:"}
                </span>
                <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                    {userAvailabilityDetail?.data?.result?.id}
                </span>
            </div>

            {/* name container */}
            <div className={"col-span-full"}>
                <InputTextWithLabel
                    isRequired={true}
                    inputLabel={availabilityDetailBtnConst.name.label}
                    id={availabilityDetailBtnConst.name.key}
                    inputLabelContent={availabilityDetailBtnConst.name.key}
                    placeholder={"Enter name here ..."}
                    inputName={availabilityDetailBtnConst.name.key}
                    value={modifyUserAvailabilityDetail?.payload?.name}
                    onChange={(event) => onHandleChangeText(availabilityDetailBtnConst.name.key, event?.target?.value)}
                    required={true}
                    maxLength={100}
                />
                <div className={"w-full flex justify-end font-bodyPri font-normal text-text-800 text-sm"}>
                    {`${modifyUserAvailabilityDetail?.payload?.name ? modifyUserAvailabilityDetail?.payload?.name?.length : 0}/${100}`}
                </div>
            </div>

            {/* isRecurring */}
            <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-6"}>
                <FloatingLabelSelect
                    labelItem={availabilityDetailBtnConst.isRecurring.key}
                    options={Object.values(availabilityIsRecurringEnum)}
                    onHandleSelect={(selectedOption) => onHandleRecurringAvailability(availabilityDetailBtnConst.isRecurring.key, selectedOption?.value)}
                    value={availabilityIsRecurringEnum[modifyUserAvailabilityDetail?.payload?.isRecurring?.toUpperCase()]?.label}
                    OptionChild={({ option }) => (
                        <div className={"bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between"}>
                            {option?.label}
                            {(option?.value === modifyUserAvailabilityDetail?.payload?.isRecurring)
                                && <IoIosCheckmark size={20} color="green" />
                            }
                        </div>
                    )}
                    label={availabilityDetailBtnConst.isRecurring.label}
                    dropdownWrapperClassName={"w-full"}
                    dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                    labelClassName={"font-medium text-base !text-gray-900"}
                    customBtnStyle={"!h-12"}
                    isDisabled={modifyUserAvailabilityDetail?.isLoading}
                />
            </div>

            {/* start date time */}
            {[availabilityIsRecurringEnum.RECURRING.value]?.includes(modifyUserAvailabilityDetail?.payload?.isRecurring) &&
                <div className={"w-full col-start-1 col-span-full md:col-start-7 md:col-span-6 space-y-2"}>
                    <MuiDatePicker
                        label={availabilityDetailBtnConst.startDateTime.label}
                        value={modifyUserAvailabilityDetail?.payload?.startDateTime ? dayjs(modifyUserAvailabilityDetail?.payload?.startDateTime).tz(timeZone) : null}
                        onChange={(date) => onHandleChangeText(availabilityDetailBtnConst.startDateTime.key, dayjs(date).tz(timeZone).format("YYYY-MM-DD"))}
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "#9e9e9e",
                                    height: 50,
                                },
                                "&:hover fieldset": {
                                    borderColor: "#2196f3",
                                },
                            },
                            "& .MuiInputLabel-root": {
                                fontWeight: '700',
                                color: '#212121',
                            },
                        }}
                        format={"DD-MM-YYYY"}
                        color={"primary"}
                        disablePast={true}
                        helperText={"SelectDate"}
                        margin={"dense"}
                        minDate={dayjs().tz(timeZone)}
                    />
                    <div className={"flex items-center justify-start gap-2"}>
                        <BsInfoCircle className={"text-text-700 text-lg"} />
                        <span className={"font-bodyPri font-normal text-text-700 text-sm"}>
                            {"You must have a session on the start date."}
                        </span>
                    </div>
                </div>
            }
        </div>
    )
}

export default BasicInformation;