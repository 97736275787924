import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "./product.const";

class ProductService {
    getProductDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_PRODUCT_DETAIL, params)
        )

        return response;
    }

    createProductDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_PRODUCT_DETAIL),
            body
        )

        return response;
    }

    updateProductDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_PRODUCT_DETAIL, params),
            body
        )

        return response;
    }

    createProductPriceDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_PRODUCT_PRICE_DETAIL),
            body
        )

        return response;
    }

    updateProductPriceDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_PRODUCT_PRICE_DETAIL, params),
            body
        )

        return response;
    }

    createProductSlugDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_PRODUCT_SLUG_DETAIL),
            body
        )

        return response;
    }

    updateProductSlugDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_PRODUCT_SLUG_DETAIL, params),
            body
        )

        return response;
    }
}

export default new ProductService()