import {useMemo} from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import { cn } from "utils/cn.utils";

import { OptionSelector } from "components/common-components/Select";

export default function NavigationTabs({ options = [] }) {
    const params = useParams();
    const pathParam = params["*"];
    
    const selectOptions = useMemo(() => {
        if (!!options?.length) {
            return options.map((option) => ({
                label: option?.label,
                value: option?.to,
                to: option?.routePath
            }))
        } else return []
    }, [options])
    
    const navigate = useNavigate();

    return (
        <div className={"w-full h-fit p-4 bg-white rounded-lg shadow-sm md:w-fit sticky top-[4.5rem]"}>
            <div className={"md:hidden"}>
                <OptionSelector
                    options={selectOptions}
                    onChange={(op) => {
                        navigate(`${op?.to}`);
                    }}
                    value={pathParam}
                />
            </div>

            <div className={"hidden md:flex flex-col gap-2"}>
                {!!options?.length && options?.map((option, index) => (
                    <Link
                        key={index}
                        to={`${option?.routePath}`}
                        className={cn(
                            "px-3 py-2 font-bodyPri font-semibold text-sm text-left rounded-md whitespace-nowrap",
                            (pathParam === option.to)
                                ? "bg-primary-dark text-text-50 hover:bg-primary-dark hover:text-text-50"
                                : "text-text-900 hover:bg-primary-light hover:text-primary-dark"
                        )}
                    >
                        {option?.label}
                    </Link>
                ))}
            </div>
        </div>
    );
}