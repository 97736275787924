import TimeLineItem from './TimeLineItem';
import {
    Container,
    TimeLineTitle,
    TimeLineContainer,
} from './VerticalTimeLineStyle';
import './verticalTimeLine.css';

const VerticalTimeLine = ({ title, timeLineData }) => {
    return (
        <Container>
            {title && <TimeLineTitle>{title}</TimeLineTitle>}
            <TimeLineContainer className='timeline-container'>
                {timeLineData.map((timeLineItem, index) => (
                    <TimeLineItem key={index} index={index} timeLineItem={timeLineItem} />
                ))}
            </TimeLineContainer>
        </Container>
    )
}

export default VerticalTimeLine;
