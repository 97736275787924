import { toast } from "react-toastify";

import PayoutService from "redux/payout/payout.service";

import {
    setUserPayoutListLoading,
    setUserPayoutList,
    setUserPayoutListErrorMsg,

    // setUserPayoutDetailLoading,
    // setUserPayoutDetail,
    // setUserPayoutDetailErrorMsg,

    setAddUserPayoutDetailLoading,
    setAddUserPayoutDetailErrorMsg
} from "redux/payout/payout.slice";

export const getUserPayoutListByUserId = (userId) => async (dispatch) => {
    dispatch(setUserPayoutListLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await PayoutService.getUserPayoutListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserPayoutList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserPayoutListErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setUserPayoutListLoading(false))
    }
}

export const createUserPayoutDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddUserPayoutDetailLoading(true))

    try {
        const { userPayoutList } = getState().payout
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await PayoutService.createUserPayoutDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserPayoutList([
                ...userPayoutList?.userPayoutList,
                response.data.data
            ]))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserPayoutDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddUserPayoutDetailLoading(false))
    }
}

// export const getUserPayoutDetailByUserPayoutId = (userPayoutId) => async (dispatch) => {
//     dispatch(setUserPayoutDetailLoading(true))

//     try {
//         const requestData = {
//             params: { userPayoutId: userPayoutId }
//         }
//         const response = await PayoutService.getUserPayoutDetailByUserPayoutId(requestData)
//         if (response.status === 200) {
//             dispatch(setUserPayoutDetail(response.data.data))
//         } else {
//             throw new Error(response)
//         }
//     } catch (error) {
//         console.error(error.response.data.message || error.response.data.error || error)
//         dispatch(setUserPayoutDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
//     } finally {
//         dispatch(setUserPayoutDetailLoading(false))
//     }
// }