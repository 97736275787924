import { useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

import PageHeader from 'components/pageHeader/PageHeader';

import DriveNavigation from 'pages/auth/drive/commonComponents/DriveNavigation';
import { navigationList, pageHeading } from "pages/auth/drive/data";

import { setPageInfo } from "redux/pageInfo/pageInfo.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";

import { pagesInfo } from "utils/pagesInfo";

const DrivePage = ({ allowedRoles }) => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { locals } = useAppState((state) => state.local)
  const { session } = useAppState((state) => state.auth)
  const { user } = useAppState((state) => state.user)

  const dispatcher = useAppDispatcher()
  const location = useLocation()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.DRIVE))
  }, [dispatcher, currentPageInfo])

  return (
    <div className={"h-full w-full p-3 mx-auto min-h-screen space-y-3"}>
      <PageHeader
        pageHeading={pageHeading}
        toolTipText={"Easily upload, store, and share your images, files, and resources for your products and services."}
      />
      <div className={"w-full flex flex-col space-y-3 md:space-y-0 md:space-x-3 md:flex-row"}>
        <DriveNavigation options={navigationList} />
        <div className={"w-full min-h-screen rounded-lg overflow-hidden"}>
          {session?.isLoggedIn && user?.user?.roles?.find((role) => allowedRoles?.includes(role.role) && role.role === locals.userRole)
            ? <Outlet />
            : <Navigate to={pagesInfo.UNAUTHORIZED.pagePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />
          }
        </div>
      </div>
    </div>
  );
};

export default DrivePage;
