import { createSlice } from "@reduxjs/toolkit";

import { STORAGE_INITIAL_STATE } from "redux/storage/storage.initialState";

const storage = createSlice({
    name: "storage",
    initialState: STORAGE_INITIAL_STATE,
    reducers: {
        // reducers for upload file
        setUploadFileLoading: (state, { payload }) => {
            state.uploadFile.isLoading = payload
        },
        setUploadFileData: (state, { payload }) => {
            state.uploadFile.data = payload
            state.uploadFile.message = STORAGE_INITIAL_STATE.uploadFile.message
        },
        setUploadFileMessage: (state, { payload }) => {
            state.uploadFile.message = payload
            state.uploadFile.data = STORAGE_INITIAL_STATE.uploadFile.data
        },
        resetUploadFile: (state) => {
            state.uploadFile = STORAGE_INITIAL_STATE.uploadFile
        },

        // reducers for upload file payload
        setUploadFilePayload: (state, { payload }) => {
            state.uploadFile.payload = payload
        },
        resetUploadFilePayload: (state, { payload }) => {
            state.uploadFile.payload = payload
        },
    }

})

export const {
    setUploadFileLoading,
    setUploadFileData,
    setUploadFileMessage,
    resetUploadFile,

    setUploadFilePayload,
    resetUploadFilePayload
} = storage.actions

export default storage.reducer
