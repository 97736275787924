import { useContext } from 'react';

import { DragContext } from './Drag';
import DropZone from './DropZone';

function DropZones({ dropType, prevId, nextId, split = "y", remember, children, ...props }) {
    const { dragType, isDragging } = useContext(DragContext);

    return (
        <div style={{ position: "relative" }} {...props}>
            {children}
            {((dragType === dropType) && isDragging) &&
                <div style={{ position: "absolute", inset: "0px", display: "flex", flexDirection: split === "x" ? "row" : "column" }}>
                    <DropZone dropId={prevId} style={{ width: "100%", height: "100%" }} dropType={dropType} remember={remember} {...props} />
                    <DropZone dropId={nextId} style={{ width: "100%", height: "100%" }} dropType={dropType} remember={remember} {...props} />
                </div>
            }
        </div>
    );
};

export default DropZones;