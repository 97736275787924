import { useCallback, useEffect, useState } from 'react';

import { cn } from "utils/cn.utils";

import { FiEdit } from 'react-icons/fi';
import { MdAdd } from 'react-icons/md';

import AddBorderButton from 'pages/auth/edulyteLms/commonComponents/AddBorderButton';

import { createProductPriceDetail, updateProductDetail } from 'redux/product/product.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setAddProductPriceDetailPayload, resetAddProductPriceDetailPayload } from 'redux/product/product.slice';
import { addProductPriceDetailPayload, productPriceDiscountTypeEnum, productPriceModelEnum, productPriceStatusEnum, productPriceTypeEnum } from "redux/product/product.const";
import { modalConst } from 'redux/local/local.const';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';

const PriceInformation = () => {
    const { modal } = useAppState((state) => state.local)
    const { productDetail, modifyProductDetail, addProductPriceDetail } = useAppState((state) => state.product)

    const dispatcher = useAppDispatcher()

    const [mouseHovered, setMouseHovered] = useState(null)

    useEffect(() => {
        if (productDetail?.data?.product_prices && (productDetail?.data?.product_prices?.length === 0)) {
            onHandleAddPrice()
        }
    }, [productDetail?.data?.product_prices])

    const onHandleAddPrice = useCallback(() => {
        dispatcher(resetAddProductPriceDetailPayload())
        dispatcher(setAddProductPriceDetailPayload({
            ...addProductPriceDetailPayload,
            product_id: productDetail?.data?.id,
            type: productPriceTypeEnum.ONE_TIME.value,
            currency_code: masterCurrencyCodeEnum?.USD?.value,
            discount_type: productPriceDiscountTypeEnum?.PERCENTAGE?.value,
            discount_pct: 10,
            price_model: productPriceModelEnum?.STANDARD?.value,
            status: productPriceStatusEnum?.ACTIVE?.value,
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.PRODUCT_PRICE_DETAIL_MODAL.stateKey]: true
        }))
    }, [modal, productDetail?.data])

    const onHandleMouseHover = useCallback((hoverItem) => {

        if (!hoverItem) setMouseHovered(null)
        if (hoverItem) setMouseHovered(hoverItem)
    }, [])

    const onHandleMakePriceDefault = useCallback((productPriceId) => {
        if (modifyProductDetail?.isLoading || productDetail?.isLoading) return;

        const body = {
            default_product_price_id: productPriceId
        }
        dispatcher(updateProductDetail(productDetail?.data?.id, body))
    }, [productDetail?.data])

    const onHandleEditPriceDetail = useCallback((productPrice) => {
        dispatcher(resetAddProductPriceDetailPayload())
        let payloadObj = {
            product_id: productPrice?.product,
            product_price_id: productPrice?.id,
            type: productPrice?.type,
            price_model: productPrice?.price_model,
            status: productPrice?.status,
            description: productPrice?.description,
            make_default_price: (productDetail?.data?.product_setting?.default_product_price?.id === productPrice?.id) ? true : false
        }
        if (productPrice?.price_model === productPriceModelEnum.STANDARD.value) {
            payloadObj = {
                ...payloadObj,
                currency_code: productPrice?.package_price?.master_currency?.code,
                price: productPrice?.package_price?.price,
                unit: productPrice?.package_price?.unit,
                discount_type: productPrice?.package_price?.discount_type,
                discount_price: productPrice?.package_price?.discount_price,
                discount_pct: productPrice?.package_price?.discount_pct,
            }
        }
        dispatcher(setAddProductPriceDetailPayload({
            ...payloadObj
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.PRODUCT_PRICE_DETAIL_MODAL.stateKey]: true
        }))
    }, [modal, productDetail?.data])

    const onHandleCreateFreePrice = useCallback(() => {
        if (productDetail?.isLoading) return;

        const body = {
            product_id: productDetail?.data?.id,
            currency_code: masterCurrencyCodeEnum?.USD?.value,
            type: productPriceTypeEnum?.ONE_TIME?.value,
            price_model: productPriceModelEnum?.FREE?.value,
            status: productPriceStatusEnum?.ACTIVE?.value
        }
        dispatcher(createProductPriceDetail(body))
    }, [productDetail?.data])

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <div className={"w-full flex flex-col gap-1"}>
                <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                    {"1. Price information"}
                </span>

                <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wide"}>
                    {"Set course price and any discounts."}
                </span>
            </div>

            {(!productDetail?.data?.product_prices || (productDetail?.data?.product_prices?.length === 0)) &&
                <AddBorderButton
                    isLoading={addProductPriceDetail?.isLoading}
                    btnTitle={"Add Price(s)"}
                    onHandleAdd={onHandleAddPrice}
                />
            }
            {(productDetail?.data?.product_prices?.length > 0) &&
                <div className={"w-full flex flex-col gap-5"}>
                    <div className={"w-full flex flex-col gap-5"}>
                        {productDetail?.data?.product_prices?.map((productPrice, index) => (
                            <div
                                key={index}
                                className={cn(
                                    "pb-1.5 border-b border-text-300 flex items-center justify-between gap-3 cursor-pointer",
                                    (mouseHovered === productPrice?.id) && "!border-primary-main"
                                )}
                                onMouseOver={() => onHandleMouseHover(productPrice?.id)}
                                onMouseOut={() => onHandleMouseHover(null)}
                            >
                                <div className={"flex items-center justify-start gap-5"}>
                                    <div className={"relative has-tooltip"}>
                                        <input
                                            type={"radio"}
                                            className={"cursor-pointer"}
                                            value={productDetail?.data?.product_setting?.default_product_price?.id}
                                            checked={productDetail?.data?.product_setting?.default_product_price?.id === productPrice?.id}
                                            onClick={() => onHandleMakePriceDefault(productPrice?.id)}
                                        />
                                        <span
                                            className={cn(
                                                `w-fit px-2 py-1 font-bodyPri font-normal text-sm md:text-xs rounded shadow-lg bg-text-900 text-text-50 tooltip translate-x-0 translate-y-0 md:-translate-y-10`,
                                                "flex flex-col items-start justify-start overflow-hidden whitespace-normal md:whitespace-nowrap"
                                            )}
                                        >
                                            {(productDetail?.data?.product_setting?.default_product_price?.id === productPrice?.id)
                                                ? "Default"
                                                : "Make Default"
                                            }
                                        </span>
                                    </div>
                                    {![productPriceModelEnum.FREE.value]?.includes(productPrice?.price_model) &&
                                        <span className={cn(
                                            "font-bodyPri font-normal text-text-700 text-base tracking-wide",
                                            ((productDetail?.data?.product_setting?.default_product_price?.id === productPrice?.id) && [productPriceStatusEnum?.ACTIVE?.value]?.includes(productPrice?.status))
                                            && "font-medium text-green-500"
                                        )}>
                                            {[masterCurrencyCodeEnum.USD.value, masterCurrencyCodeEnum.AUD.value]?.includes(productPrice?.package_price?.master_currency?.code)
                                                ? <span>{"$ "}</span>
                                                : <span>&#8377;</span>
                                            }
                                            {` ${(productPrice?.package_price?.price / 100)?.toFixed(2)} ${productPrice?.package_price?.master_currency?.code}`}
                                        </span>
                                    }
                                    {[productPriceModelEnum.FREE.value]?.includes(productPrice?.price_model) &&
                                        <span className={(cn(
                                            "font-bodyPri font-normal text-text-700 text-base tracking-wide",
                                            ((productDetail?.data?.product_setting?.default_product_price?.id === productPrice?.id) && [productPriceStatusEnum?.ACTIVE?.value]?.includes(productPrice?.status))
                                            && "font-medium text-green-500"
                                        ))}>
                                            {` Free`}
                                        </span>
                                    }
                                    {(productPrice?.status) &&
                                        <span className={(cn(
                                            "font-bodyPri font-normal text-text-700 text-base tracking-wide",
                                            ((productDetail?.data?.product_setting?.default_product_price?.id === productPrice?.id) && [productPriceStatusEnum?.ACTIVE?.value]?.includes(productPrice?.status))
                                            && "font-medium text-green-500"
                                        ))}>
                                            {`${productPriceStatusEnum[productPrice?.status?.toUpperCase()]?.label}`}
                                        </span>
                                    }
                                    {(productPrice?.description && (productPrice?.description?.length > 0)) &&
                                        <span className={(cn(
                                            "font-bodyPri font-normal text-text-700 text-base tracking-wide",
                                            ((productDetail?.data?.product_setting?.default_product_price?.id === productPrice?.id) && [productPriceStatusEnum?.ACTIVE?.value]?.includes(productPrice?.status))
                                            && "font-medium text-green-500"
                                        ))}>
                                            {`(${productPrice?.description})`}
                                        </span>
                                    }
                                </div>
                                {(mouseHovered === productPrice?.id) &&
                                    <FiEdit
                                        className={"text-lg text-text-800 cursor-pointer"}
                                        onClick={() => onHandleEditPriceDetail(productPrice)}
                                    />
                                }
                            </div>
                        ))}
                    </div>
                    <div
                        className={cn(
                            "w-fit flex items-center justify-center cursor-pointer",
                            "border border-divider-lightDark rounded-md px-2 py-1",
                            "font-bodyPri font-medium text-base text-text-700",
                            "hover:text-text-900 hover:border-divider-dark"
                        )}
                        onClick={onHandleAddPrice}
                    >
                        <MdAdd className={"text-xl"} />
                        <span className={"whitespace-nowrap"}>
                            {"Add another price"}
                        </span>
                    </div>
                </div>
            }
        </div>
    )
}

export default PriceInformation;