import tw, { styled } from 'twin.macro';
import Carousel from 'react-slick';
import { NavLink as RouteLink } from 'react-router-dom';
import { Section, SectionContent } from 'components/generalComponent/StyledComponent';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

export const Container = tw(Section)`
    bg-white 
`;

export const Content = tw(SectionContent)`
    col-start-1 col-span-12 lg:(col-start-2 col-span-10) gap-10
`;

export const Header = tw.div`
    flex flex-col items-center gap-5 md:mx-16 lg:mx-32
`;

export const Heading = tw.h3`
    font-heading font-semibold text-3xl text-text-900 text-center
`;

export const SubHeading = tw.h4`
    font-subHeading font-normal text-lg text-text-800 text-center
`;

export const ItemContainer = tw.div`
    flex flex-col max-w-full w-full
`;

export const Slider = tw.div`
    grid grid-cols-12 max-w-full w-full
`;

export const TestimonialContainer = tw(Carousel)`
    col-span-12 w-full flex flex-row
`;

export const ArrowContainer = tw.div`
    h-full w-full flex items-center justify-center
`;

export const PrevArrowIcon = tw(ArrowBack)`
    text-text-800 hover:text-text-900 text-3xl cursor-pointer
    transition duration-300 transform hover:scale-150
`;

export const NextArrowIcon = tw(ArrowForward)`
    text-text-800 hover:text-text-900 text-3xl cursor-pointer
    transition duration-300 transform hover:scale-150
`;

export const TestimonialItemContainer = tw.div`
    w-full grid grid-cols-12 
`;

export const ImageContainer = styled.div`
    ${tw`
        col-start-4 col-span-6 md:(col-start-1 col-span-3) w-full p-5
    `}
    img {
        ${tw`
            w-full h-full rounded overflow-hidden
        `}
    }
`;

export const TextContainer = tw.div`
    col-start-1 col-span-12 md:(col-start-4 col-span-9) w-full flex flex-col gap-3 
    items-center md:items-start justify-center text-center md:text-left
    px-10 lg:px-20
`;

export const Quote = tw.span`
    font-bodyPri font-normal text-lg text-text-900
`;

export const UserInfo = tw.span`
    font-bodyPri font-normal text-lg text-text-700
`;

export const ButtonContainer = tw.div`
    self-center mt-5 flex flex-col md:flex-row gap-5 items-center justify-center
`;

export const FindTutorBtn = tw(RouteLink)`
    font-buttons font-normal text-lg text-text-50 bg-primary-main
    rounded cursor-pointer w-48 py-1.5 flex justify-center items-center
    hover:(bg-secondary-dark)
`;

export const PostYourNeedBtn = tw(RouteLink)`
    font-buttons font-normal text-lg text-primary-main bg-white border-2 border-primary-main  
    rounded cursor-pointer w-48 py-1.5 flex justify-center items-center
    hover:(text-secondary-dark border-secondary-dark) 
`;

