import { useState, createContext } from 'react';

import DragItem from './DragItem';
import DropZone from './DropZone';
import DropZones from './DropZones';
import DropGuide from './DropGuide';

export const DragContext = createContext();

function Drag({ draggable = true, handleDrop, children }) {
    const [dragItem, setDragItem] = useState(null)
    const [dragType, setDragType] = useState(null)
    const [isDragging, setIsDragging] = useState(null)
    const [drop, setDrop] = useState(null)
    const [status, setStatus] = useState(null)

    const dragStart = function (e, dragId, dragType) {
        e.stopPropagation();
        e.dataTransfer.effectAllowed = 'move';
        setDragItem(dragId);
        setDragType(dragType);
    };

    const drag = function (e, dragId, dragType) {
        e.stopPropagation();
        setIsDragging(true);
    };

    const dragEnd = function () {
        setDragItem(null);
        setDragType(null);
        setIsDragging(false);
        setDrop(null);
        setStatus(null)
    };

    const onDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        handleDrop({ dragItem, dragType, drop, status });
        setDragItem(null);
        setDragType(null);
        setIsDragging(false);
        setDrop(null);
        setStatus(null)
    };

    return (
        <DragContext.Provider value={{ draggable, dragItem, dragType, isDragging, dragStart, drag, dragEnd, drop, status, setDrop, setStatus, onDrop }}>
            {typeof children === "function" ? children({ activeItem: dragItem, activeType: dragType, isDragging }) : children}
        </DragContext.Provider>
    );
};

export default Object.assign(Drag, { DragItem, DropZone, DropZones, DropGuide });