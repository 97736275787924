import { Link } from 'react-router-dom';

import { BiChevronRight } from 'react-icons/bi';

const Breadcrumb = ({ items }) => {
  
  return (
    <nav className='flex items-center text-[12px] font-bodyPri text-text-600'>
      <ul className='flex items-center space-x-1'>
        {items.map((item, index) => (
          <li key={index} className='flex items-center'>
            {index !== 0 && <BiChevronRight size={15} className='text-text-900' />}
            {item.link ? (
              <Link to={item.link} className='hover:underline'>
                {item.text}
              </Link>
            ) : (
              <span>{item.text}</span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
