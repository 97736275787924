import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import FaceRetouchingNaturalOutlinedIcon from '@mui/icons-material/FaceRetouchingNaturalOutlined';

export const tutorsTabData = {
    John: {
        imageUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/john-feature-tutor.png",
        name: "John",
        specialities: "English, Maths",
        description: "I have always been very passionate about helping my students build their confidence in their English-language skills, and I look forward to helping you build confidence and achieve your goals as well. I am a certified instructor who has taught in Sydney where I am from, New York, where I lived for 5 years, and London, where I am currently based.",
        price: 5.99,
        rating: 4.9,
        reviewsCount: 150,
        review: "My tutor I’ve ever met in my life, I recommend…",
        reviewProviderName: "Carty E.",
        badges: [
            {
                badgeName: "Digital ID",
                icon: BadgeOutlinedIcon
            },
            {
                badgeName: "Police Check",
                icon: LocalPoliceOutlinedIcon
            },
            {
                badgeName: "Working with Children check",
                icon: FaceRetouchingNaturalOutlinedIcon
            },
            {
                badgeName: "Certified",
                icon: GppGoodOutlinedIcon
            },
        ]
    },
    Lisa: {
        imageUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/lisa-feature-tutor.png",
        name: "Lisa",
        specialities: "Maths, Science",
        description: "I worked at a multicultural language school and taught many different nationalities from a beginners level up until advanced and have experience in teaching both group and private classes. My lessons will be structured around your needs so please feel free to communicate with me beforehand if you have specific expectations.",
        price: 5.99,
        rating: 4.5,
        reviewsCount: 95,
        review: "If you don’t understand maths, you must talk to Lisa. She is amazing…",
        reviewProviderName: "John K.",
        badges: [
            {
                badgeName: "Digital ID",
                icon: BadgeOutlinedIcon
            },
            {
                badgeName: "Police Check",
                icon: LocalPoliceOutlinedIcon
            },
            {
                badgeName: "Working with Children check",
                icon: FaceRetouchingNaturalOutlinedIcon
            },
            {
                badgeName: "Certified",
                icon: GppGoodOutlinedIcon
            },
        ]
    },
    James: {
        imageUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/james-feature-tutor.png",
        name: "James",
        specialities: "IELTS, Grammar",
        description: "Over eight years ago I changed my career to become a teacher. I worked as a garden designer/horticulturist for over ten years until I felt I wasn&#39;t learning anymore and took the jump into teaching. Being a teacher was always something I was interested in, so I started to work part-time helping students prepare for IELTS exams.",
        price: 5.99,
        rating: 4.8,
        reviewsCount: 147,
        review: "Thanks to James, I passed IELTS with flying colors..",
        reviewProviderName: "Roy J.",
        badges: [
            {
                badgeName: "Digital ID",
                icon: BadgeOutlinedIcon
            },
            {
                badgeName: "Police Check",
                icon: LocalPoliceOutlinedIcon
            },
            {
                badgeName: "Working with Children check",
                icon: FaceRetouchingNaturalOutlinedIcon
            },
            {
                badgeName: "Certified",
                icon: GppGoodOutlinedIcon
            },
        ]
    },
    Mary: {
        imageUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/mary-feature-tutor.png",
        name: "Mary",
        specialities: "Economics",
        description: "I have been teaching at The University of Sydney since 2016, prior to which I was a Business Assurance &amp; Advisory Analyst at Morgan Partners. I can teach any accounting subjects starting from the VCE level going up to the post-graduate level as well as any other high school and tertiary commerce subjects like marketing, management, finance, business studies, etc.",
        price: 5.99,
        rating: 4.7,
        reviewsCount: 125,
        review: "My fear of econ has gone away after I started learning from Mary…",
        reviewProviderName: "Tim S.",
        badges: [
            {
                badgeName: "Digital ID",
                icon: BadgeOutlinedIcon
            },
            {
                badgeName: "Police Check",
                icon: LocalPoliceOutlinedIcon
            },
            {
                badgeName: "Working with Children check",
                icon: FaceRetouchingNaturalOutlinedIcon
            },
            {
                badgeName: "Certified",
                icon: GppGoodOutlinedIcon
            },
        ]

    }
}