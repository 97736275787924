import FadedCollapse from 'components/fadedCollapse';

import { useAppState } from 'hooks/useStore';

import { dayjs, timeZone } from 'utils/dateTime.utils';

const EventlyTitleTimeHeader = () => {
    const { userEventlyDetail } = useAppState((state) => state.evently)

    return (
        <div className={"w-full grid grid-cols-12 gap-x-2 px-5"}>
            <div className={"col-start-1 col-span-full md:col-start-2 md:col-span-full flex flex-col gap-1"}>
                <span className={"font-bodyPri font-semibold text-md text-text-800 tracking-wide truncate"}>
                    {userEventlyDetail?.data?.title}
                </span>
                {!!userEventlyDetail?.data?.evently_events &&
                    <FadedCollapse
                        maxHeight={(userEventlyDetail?.data?.evently_events?.length > 3) ? "7.5rem" : '100%'}
                        isShowMore={userEventlyDetail?.data?.evently_events?.length > 3}
                        isFaded={userEventlyDetail?.data?.evently_events?.length > 3}
                    >
                        <div className={"flex flex-col gap-2"}>
                            {userEventlyDetail?.data?.evently_events?.map((eventlyEvent, index) => {
                                const isDateSimilar = dayjs(eventlyEvent?.event?.startDateTime).isSame(eventlyEvent?.event?.endDateTime, "date")
                                return (
                                    <div
                                        key={index}
                                        className={"w-full flex items-center justify-start gap-3 transition-transform ease-in-out duration-200 delay-150"}
                                    >
                                        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide truncate"}>
                                            {dayjs(eventlyEvent?.event?.startDateTime)?.tz(timeZone)?.format("dddd, DD-MMM-YYYY")}
                                        </span>
                                        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                            {dayjs(eventlyEvent?.event?.startDateTime)?.tz(timeZone)?.format("hh:mma")}
                                        </span>
                                        <span className={"font-bodyPri font-medium text-text-800 text-base"}>
                                            {"-"}
                                        </span>
                                        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                            {dayjs(eventlyEvent?.event?.endDateTime)?.tz(timeZone)?.format("hh:mma")}
                                        </span>
                                        {!isDateSimilar &&
                                            <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide truncate"}>
                                                {dayjs(eventlyEvent?.event?.endDateTime)?.tz(timeZone)?.format("dddd, DD-MMM-YYYY")}
                                            </span>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </FadedCollapse>
                }
            </div>
        </div>
    )
}

export default EventlyTitleTimeHeader;