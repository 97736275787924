import format from 'string-format';

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/wallet/wallet.const";


class WalletService {

    getWalletTxnList = async () => {
        const response = await baseAuthApiInstance().get(
            url.GET_WALLET_TXN_LIST
        )

        return response
    }

    addCreditToWallet = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.ADD_CREDIT_TO_WALLET,
            body
        )

        return response
    }

    getWalletConfirmationByVirtualTxnId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_WALLET_CONFIRMATION_BY_VIRTUAL_TXN_ID, params)
        )

        return response
    }
}

export default new WalletService()