import { pagesInfo } from 'utils/pagesInfo';
import {
    CenterNavLinksContainer,
    NavLink,
} from './NavBarStyle';

const CenterNavLinks = ({ isScrolled }) => {
    return (
        <CenterNavLinksContainer>
            <NavLink to={pagesInfo.FIND_COURSES.pagePath} isScrolled={isScrolled}>{'Find classes'}</NavLink>
            <NavLink to={pagesInfo.FIND_TUTORS.pagePath} isScrolled={isScrolled}>{'Find tutors'}</NavLink>
        </CenterNavLinksContainer>
    )
}

export default CenterNavLinks;