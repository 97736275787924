import { dayjs } from "utils/dateTime.utils";

export const JOINED_STATUS = "Joined"

export const eventActionBtnConst = {
    VIEW: {
        label: "View",
        value: "view"
    },
    RESET: {
        label: "Reset",
        value: "reset"
    },
    DELETE: {
        label: "Delete",
        value: "delete"
    }
}

export const eventlyGuestsActions = {
    IS_LOADING: {
        value: "IS_LOADING"
    },
    EMAIL_ADDRESS: {
        value: "EMAIL_ADDRESS"
    },
    INVALID_EMAIL: {
        value: "INVALID_EMAIL"
    },
    INITIAL_STATE: {
        value: "INITIAL_STATE"
    }
}
export const eventlyGuestsReducer = (state, action) => {
    switch (action.type) {
        case eventlyGuestsActions.IS_LOADING.value:
            return { ...state, isLoading: action.payload }
        case eventlyGuestsActions.EMAIL_ADDRESS.value:
            return { ...state, emailAddress: action.payload }
        case eventlyGuestsActions.INVALID_EMAIL.value:
            return { ...state, invalidEmail: action.payload }
        case eventlyGuestsActions.INITIAL_STATE.value:
            return {
                ...state,
                isLoading: false,
                emailAddress: "",
                invalidEmail: ""
            }
        default:
            return state;
    }
}

export const generateStartTimeOptions = (currentDateTime = dayjs(), timeZone = dayjs.tz.guess().toString()) => {
    let timeOptions = {};
    new Array(24).fill("").forEach((_, hour) => {
        [0, 15, 30, 45].forEach((minute) => {
            let key = dayjs(currentDateTime.format("YYYY-MM-DD")).tz(timeZone).add(hour, "hour").add(minute, "minute").format("HHmmss");
            timeOptions[key] = {
                label: dayjs(currentDateTime.format("YYYY-MM-DD")).tz(timeZone).add(hour, "hour").add(minute, "minute").format("hh:mma"),
                value: dayjs(currentDateTime.format("YYYY-MM-DD")).tz(timeZone).add(hour, "hour").add(minute, "minute").format("HH:mm:ss")
            };
        });
    });
    const keys = Object.keys(timeOptions).sort();
    let timeOptionList = keys.map((key) => {
        return {
            label: timeOptions[key].label,
            value: timeOptions[key].value
        };
    });

    return timeOptionList;
};

export const generateEndTimeOptions = (startDateTime) => {
    const timeSlots = [];
    let currentDateTime = dayjs(startDateTime);
    const firstFourIncrement = 15
    const restIncrement = 30
    let hourFraction = 0

    for (let i = 0; i < 4; i++) {
        const label = currentDateTime.format('hh:mma')
        const value = currentDateTime.format('HH:mm:ss')
        const duration = `${i * firstFourIncrement} mins`
        timeSlots.push({ label: `${label} (${duration})`, value })
        currentDateTime = currentDateTime.add(firstFourIncrement, 'minute')
        hourFraction += 0.25
    }

    while (timeSlots.length < 50) {
        const label = currentDateTime.format('hh:mma')
        const value = currentDateTime.format('HH:mm:ss')
        const duration = (hourFraction === 1) ? `${hourFraction} hr` : `${hourFraction} hrs`
        timeSlots.push({ label: `${label} (${duration})`, value })
        currentDateTime = currentDateTime.add(restIncrement, 'minute')
        hourFraction += 0.5
    }

    return timeSlots;
};