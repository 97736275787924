import { useEffect, memo, useState } from "react";
import { useParams } from "react-router-dom";

import { cn } from "utils/cn.utils";

import ComponentLoader from "components/loader/ComponentLoader";
import InfinitePagination from "components/pagination/InfinitePagination";

import { getLmsQuizPageImageResourceList } from "redux/edulyteLms/lmsQuiz/lmsQuiz.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetLmsQuizPageImageResourceList } from "redux/edulyteLms/lmsQuiz/lmsQuiz.slice";
import { resourceFileTypeEnum } from "redux/edulyteLms/lmsResource/lmsResource.const";
import { lmsQuizCategoryEnum } from "redux/edulyteLms/lmsQuiz/lmsQuiz.const";

const QuizImageListItems = memo(({ imageSearch }) => {
    const { lmsQuizPageImageResourceList } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()
    const { quizId } = useParams()

    const [isDragging, setIsDragging] = useState(false)

    useEffect(() => {
        if (quizId) {
            const requestDataPayload = {
                page: 1,
                records: 10,
                file_type: resourceFileTypeEnum?.IMAGE?.value,
                lms_quiz_id: `!${quizId}`
            }
            if (imageSearch) {
                requestDataPayload["search"] = imageSearch
            }
            dispatcher(getLmsQuizPageImageResourceList(requestDataPayload))
        }

        return () => {
            dispatcher(resetLmsQuizPageImageResourceList())
        }
    }, [quizId])


    const fetchMoreData = () => {
        if (lmsQuizPageImageResourceList?.data?.page === lmsQuizPageImageResourceList?.data?.totalPages) return;
        const requestDataPayload = {
            page: 1,
            records: lmsQuizPageImageResourceList?.data?.records
                ? (lmsQuizPageImageResourceList?.data?.records + 10)
                : 10,
            file_type: resourceFileTypeEnum?.IMAGE?.value,
            lms_quiz_id: `!${quizId}`
        }
        if (imageSearch) {
            requestDataPayload["search"] = imageSearch
        }
        dispatcher(getLmsQuizPageImageResourceList(requestDataPayload))
    }

    const onHandleDragStart = (event, resourceObject) => {
        const modifiedResourceObject = { quizCategoryType: lmsQuizCategoryEnum.IMAGE.value, ...resourceObject }
        event.dataTransfer.setData('application/json', JSON.stringify(modifiedResourceObject))
    }

    return (
        <>
            {lmsQuizPageImageResourceList?.isLoading &&
                <ComponentLoader isLoading={lmsQuizPageImageResourceList?.isLoading} />
            }
            {(lmsQuizPageImageResourceList?.message || (lmsQuizPageImageResourceList?.data?.results?.length === 0)) &&
                <div className={"flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                        {lmsQuizPageImageResourceList?.message || "No image(s) found!"}
                    </span>
                </div>
            }
            {(lmsQuizPageImageResourceList?.data && (lmsQuizPageImageResourceList?.data?.results?.length > 0)) &&
                <InfinitePagination
                    scrollableTarget={"scrollableDiv"}
                    dataLength={lmsQuizPageImageResourceList?.data?.totalRecords}
                    fetchData={fetchMoreData}
                    hasMore={(lmsQuizPageImageResourceList?.data?.page != lmsQuizPageImageResourceList?.data?.totalPages)}
                    loader={<ComponentLoader isLoading={lmsQuizPageImageResourceList?.isLoading} />}
                    isShowEndMessage={false}
                >
                    {lmsQuizPageImageResourceList?.data?.results?.filter((resourceItem) => {
                        return (!imageSearch || imageSearch === '')
                            ? resourceItem
                            : resourceItem?.title?.toLowerCase()?.includes(imageSearch?.toLowerCase())
                    })?.map((resourceItem) => (
                        <p
                            key={resourceItem?.id}
                            className={cn(
                                'mb-2 hover:bg-background-lightBlue rounded p-1',
                                isDragging ? "cursor-grabbing" : "cursor-grab"
                            )}
                            draggable={true}
                            onDragStart={(event) => onHandleDragStart(event, resourceItem)}
                            onMouseDown={() => setIsDragging(true)}
                            onMouseUp={() => setIsDragging(false)}
                            onMouseMove={() => {
                                if (isDragging) {
                                    setIsDragging(false)
                                }
                            }}
                        >
                            {resourceItem?.title
                                ? (resourceItem?.title?.length > 20)
                                    ? resourceItem?.title?.slice(0, 20) + " ..."
                                    : resourceItem?.title
                                : "-"
                            }
                        </p>
                    ))}
                </InfinitePagination>
            }
        </>
    )
})

export default QuizImageListItems;