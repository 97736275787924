import { cn } from "utils/cn.utils";

import { MdOutlineFormatAlignLeft } from 'react-icons/md';

import { useAppState } from 'hooks/useStore';

const EventlyDescription = () => {
    const { userEventlyDetail } = useAppState((state) => state.evently)

    return userEventlyDetail?.data?.description && (
        <div className={"w-full grid grid-cols-12 gap-x-2 px-5"}>
            <div className={cn(
                "col-start-1 col-span-1",
                (userEventlyDetail?.data?.description?.length > 20)
                    ? "self-start"
                    : "self-center"
            )}>
                <MdOutlineFormatAlignLeft className={"text-text-700 text-lg cursor-pointer"} />
            </div>
            <div className={"col-start-2 col-span-full self-center"}>
                <div
                    className={"line-clamp-4"}
                    dangerouslySetInnerHTML={{ __html: userEventlyDetail?.data?.description }}
                />
            </div>
        </div>
    )
}

export default EventlyDescription;