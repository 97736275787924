import { DEFAULT_ORDER_PAGE, DEFAULT_ORDER_RECORDS } from "redux/order/order.const";

export const pageHeading = {
    heading: "My Orders",
    subHeading: "Browse all your orders and take actions!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/bookings.svg"
}

export const orderToolTip = "Click on eye icon in the last column to view details of a order. See Schedule under any order, send message to your student who made any given order."

export const orderHeaders = [
    // "Order ID",
    // "User",
    "Product Name",
    "Amount",
    "Status",
    "Created At",
    "Actions",
];

export const orderItemLogConst = {
    PENDING: {
        title: "Pending",
    },
    PROCESSING: {
        title: "Processing"
    },
    CONFIRMED: {
        title: "Confirmed",
    },
    FAILED: {
        title: "Failed"
    },
    COMPLETED: {
        title: "Completed",
    },
    REFUNDED: {
        title: "Refunded",
    },
    CANCELLED: {
        title: "Cancelled",
    },
}

export const orderMenuOptions = {
    GET_A_REVIEW: {
        label: "Get a review",
        value: "get_a_review"
    }
}

export const searchParamsInfo = {
    page: {
        key: "page"
    },
    records: {
        key: "records"
    },
    status: {
        key: "status"
    },
    orderId: {
        key: "orderId"
    }
}

export const orderFilters = {
    [searchParamsInfo.status.key]: {
        key: "status",
        label: "Status",
    }
}

export const getOrderListPayload = async (searchQueryParams) => {

    let requestDataPayload = {
        page: searchQueryParams.get(searchParamsInfo.page.key) || DEFAULT_ORDER_PAGE,
        records: searchQueryParams.get(searchParamsInfo.records.key) || DEFAULT_ORDER_RECORDS
    }
    if (!!searchQueryParams.get(searchParamsInfo.status.key)) {
        requestDataPayload[searchParamsInfo.status.key] = searchQueryParams.get(searchParamsInfo.status.key)?.replaceAll("-", "_")
    }

    return requestDataPayload;
}