import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AiOutlineCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai';

import FilterBox from 'components/pageFilter/FilterBox';

import { searchParamsInfo } from '../lmsCourse.data';

import { DEFAULT_LMS_COURSE_PAGE } from 'redux/edulyteLms/lmsCourse/lmsCourse.const';

import { cn } from 'utils/cn.utils';

const CourseTitleFilter = memo(({ filterItem, activeFilter, setActiveFilter }) => {

    const navigate = useNavigate()
    const location = useLocation()

    const [titlePayload, setTitlePayload] = useState({
        title: "",
        message: ""
    })

    let searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        if (!!searchQueryParams?.get(searchParamsInfo.title.key)) {
            setTitlePayload({
                ...titlePayload,
                title: searchQueryParams?.get(searchParamsInfo.title.key)?.replaceAll("-", " "),
                message: ""
            })
        } else {
            setTitlePayload({
                message: "",
                title: ""
            })
        }
    }, [searchQueryParams?.get(searchParamsInfo.title.key)])

    const onHandleMouseLeave = () => {
        if (!searchQueryParams?.get(searchParamsInfo.title.key)) {
            setTitlePayload({
                message: "",
                title: ""
            })
        }
        setActiveFilter(null)
    }

    const onHandleKeyDown = (event) => {
        if ((event.keyCode === 13) && titlePayload?.title) {
            onHandleApplyFilter()
        }
    }

    const onHandleApplyFilter = useCallback(() => {
        if (!titlePayload?.title) {
            setTitlePayload({
                ...titlePayload,
                message: "Please enter title!"
            })
            return;
        }
        setTitlePayload({
            ...titlePayload,
            message: ""
        })
        searchQueryParams.delete(searchParamsInfo.title.key);
        searchQueryParams.delete(searchParamsInfo.page.key);
        searchQueryParams.set(searchParamsInfo.page.key, DEFAULT_LMS_COURSE_PAGE);
        searchQueryParams.set(searchParamsInfo.title.key, titlePayload?.title?.replaceAll(" ", "-"))
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
        setActiveFilter(null)
    }, [titlePayload, searchQueryParams])

    const onHandleClearFilter = useCallback((e) => {
        e.stopPropagation()
        setTitlePayload({
            ...titlePayload,
            title: "",
            message: ""
        })

        searchQueryParams.delete(searchParamsInfo.title.key)
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
        setActiveFilter(null)
    }, [searchQueryParams])

    return (
        <FilterBox
            boxTitle={filterItem?.label}
            boxIcon={!!searchQueryParams?.get(searchParamsInfo.title.key)
                ? <button onClick={onHandleClearFilter}>
                    <AiOutlineCloseCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
                : <button>
                    <AiOutlinePlusCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
            }
            isActive={activeFilter === filterItem.key}
            onHandleToggleBox={() => setActiveFilter(filterItem.key)}
            onHandleMouseLeave={onHandleMouseLeave}
            BoxItemContent={() => {
                return !!searchQueryParams?.get(searchParamsInfo.title.key) && (
                    <>
                        <div className={"h-[20px] border border-divider-lightDark"} />
                        <span className={"font-bodyPri font-normal text-sm text-primary-dark line-clamp-1 capitalize"}>
                            {searchQueryParams?.get(searchParamsInfo.title.key)?.replaceAll("-", " ")}
                        </span>
                    </>
                )
            }}
            customBoxClassName={!!searchQueryParams?.get(searchParamsInfo.title.key) && "!bg-text-200 !border-solid"}
            activeContainerClassName={""}
        >
            <div className={"w-60 md:w-72 p-3 flex flex-col gap-5"}>
                <span className={"font-bodyPri font-bold text-text-900 text-base tracking-wide"}>
                    {"Filter By Title"}
                </span>
                <input
                    type={'search'}
                    placeholder={"Search"}
                    className={cn(
                        "w-full flex-1 px-2 py-1 border border-text-500 rounded-md focus:outline-none focus:border focus:border-primary-dark bg-transparent",
                        "font-bodyPri font-normal text-text-800 text-sm"
                    )}
                    value={titlePayload?.title}
                    onChange={(e) => setTitlePayload({
                        ...titlePayload,
                        message: "",
                        title: e.target.value
                    })}
                    autoFocus={true}
                    onKeyDown={onHandleKeyDown}
                />
                <div className={cn(
                    "w-full py-1 flex items-center justify-center rounded-md border bg-primary-dark cursor-pointer hover:opacity-90",
                    (!titlePayload?.title) && "bg-text-300 text-text-300 hover:!bg-text-300 cursor-not-allowed"
                )}
                    onClick={onHandleApplyFilter}
                >
                    <span className={"font-bodyPri font-medium text-text-50 text-sm tracking-wide "}>
                        {"Apply Filters"}
                    </span>
                </div>
            </div>
        </FilterBox>
    )
});

export default CourseTitleFilter;