import { useCallback, useState } from 'react';

import { cn } from "utils/cn.utils";

import { FiUploadCloud } from 'react-icons/fi';
import { MdOutlinePreview } from "react-icons/md";

// import { AiOutlineCloseCircle } from 'react-icons/ai';

import ToolTipView from 'components/tooltipView';

import LmsCourseTooltip from 'pages/auth/edulyteLms/commonComponents/LmsCourseTooltip';

import { lmsQuizTooltips } from '../editLMSQuiz.data';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetPreviewImageContent, setModal, setPreviewImageContent } from 'redux/local/local.slice';
import { setThumbnailDetailPayload } from 'redux/other/other.slice';
import { resetUploadFile, setUploadFilePayload } from 'redux/storage/storage.slice';
import { modalConst } from 'redux/local/local.const';
import { fileCategories } from 'redux/storage/storage.const';
import { orientationTypeConst } from 'redux/other/other.const';

const QuizThumbnail = () => {
    const { modal, previewImage } = useAppState(s => s.local)
    const { uploadFile } = useAppState((s) => s.storage)
    const { thumbnailDetail } = useAppState((state) => state.other)
    const { lmsQuizDetail } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()

    const [hoverItem, setHoverItem] = useState(null)

    const onHandleSearchThumbnail = useCallback(() => {
        dispatcher(setThumbnailDetailPayload({
            ...thumbnailDetail?.thumbnailDetailPayload,
            keywords: lmsQuizDetail?.data?.tags?.map((quizTag) => ({
                skill: quizTag?.master_tag?.tag
            })),
            query: lmsQuizDetail?.data?.title
                ? lmsQuizDetail?.data?.title?.split(" ")?.slice(0, 3)?.join(" ")
                : "Learn English",
            orientation: orientationTypeConst.LANDSCAPE.value,
            dimensionUnit: 11
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.PHOTO_SEARCH_MODAL.stateKey]: true
        }))
    }, [lmsQuizDetail?.data, thumbnailDetail?.thumbnailDetailPayload, modal])

    const onHandleSelectImage = useCallback(() => {
        dispatcher(resetUploadFile())
        dispatcher(setUploadFilePayload({
            ...uploadFile?.payload,
            fileCategory: fileCategories.courseThumbnail.value
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.IMAGE_PICKER_MODAL.stateKey]: true
        }))
    }, [modal, uploadFile?.payload])

    const onHandlePreviewLink = useCallback(() => {
        dispatcher(resetPreviewImageContent())
        dispatcher(setPreviewImageContent({
            ...previewImage,
            previewUrl: lmsQuizDetail?.data?.thumbnail_pic_url
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.LMS_PREVIEW_IMAGE_MODAL.stateKey]: true
        }))
    }, [modal, previewImage, lmsQuizDetail?.data])

    // const onHandleDeleteThumbnail = useCallback(() => {
    //     if (!lmsQuizDetail?.data?.thumbnail_pic_url) return;

    //     dispatcher(updateLmsQuizDetail(
    //         lmsQuizDetail?.data?.id,
    //         {
    //             thumbnail_pic_url: ""
    //         }
    //     ))
    // }, [lmsQuizDetail?.data])

    return (
        <div className={'w-full md:w-[25rem] lg:w-full flex flex-col gap-5 font-bodyPri'}>
            <div className={"flex items-center justify-start gap-1"}>
                <LmsCourseTooltip tooltip={lmsQuizTooltips?.thumbnailTooltip} />
                <span className={'text-lg text-black font-medium'}>
                    {"Thumbnail"}
                </span>
            </div>
            <div className={"w-full flex flex-col gap-2"}>
                {lmsQuizDetail?.data?.thumbnail_pic_url &&
                    <div
                        className={cn(
                            "w-full relative flex flex-col content-between justify-between rounded-lg shadow-lg aspect-video test-xs cursor-pointer",
                            "group overflow-hidden",
                            "ring ring-white hover:ring-secondary-dark",
                        )}
                        onMouseOver={() => setHoverItem(true)}
                        onMouseOut={() => setHoverItem(false)}
                    >
                        <img
                            src={lmsQuizDetail?.data?.thumbnail_pic_url}
                            className={"w-full h-full object-cover scale-105 group-hover:scale-100 group-hover:transition duration-200 group-hover:shadow-lg"}
                            alt={"Thumbnail-photo"}
                        />
                        {hoverItem &&
                            <div className={"absolute w-full h-full rounded-lg overflow-hidden flex justify-center items-center"}>
                                <div className={"px-2 py-1 flex items-center justify-center gap-3 bg-white rounded-lg"}>

                                    <ToolTipView content={"Preview Thumbnail"}>
                                        <button className={"group"} onClick={onHandlePreviewLink}>
                                            <MdOutlinePreview className={"text-text-700 group-hover:text-primary-dark text-xl"} />
                                        </button>
                                    </ToolTipView>
                                </div>
                            </div>
                        }
                    </div>
                }
                {!lmsQuizDetail?.data?.thumbnail_pic_url &&
                    <div
                        className={cn(
                            "w-full p-5 flex flex-col items-center justify-center gap-3 rounded-lg",
                            "border-2 border-dashed border-divider-lightDark bg-text-200 cursor-pointer",
                        )}
                        onClick={onHandleSelectImage}
                    >
                        <FiUploadCloud size={50} color={"#2196f3"} />
                        <h2 className="font-bodyPri font-bold text-text-900 text-lg tracking-wide">
                            {"Upload File"}
                        </h2>
                        <span className={"font-bodyPri font-normal text-xs text-center"}>
                            {"Drag & drop your file here or click to browse"}
                        </span>
                    </div>
                }
                {lmsQuizDetail?.data?.thumbnail_pic_url &&
                    <div
                        className={cn(
                            "px-2 py-1 cursor-pointer bg-primary-dark rounded-lg text-center",
                            "font-bodyPri font-normal text-text-50 text-base",
                            "hover:opacity-90"
                        )}
                        onClick={onHandleSelectImage}
                    >
                        <span className={""}>
                            {"Upload"}
                        </span>
                    </div>
                }
                <div
                    className={cn(
                        "px-2 py-1 cursor-pointer border border-primary-dark rounded-lg text-center",
                        "font-bodyPri font-normal text-primary-dark text-base",
                        "hover:opacity-90 uploadThumbnail"
                    )}
                    onClick={onHandleSearchThumbnail}
                >
                    <span className={""}>
                        {"Search"}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default QuizThumbnail;