import { cn } from "utils/cn.utils";

import { MdOutlineFormatAlignLeft, MdOutlineLocationOn, MdPeople, MdZoomOutMap } from 'react-icons/md';
import { AiOutlineSkype } from 'react-icons/ai';
import { IoMdCalendar } from 'react-icons/io';
import { SiGooglemeet, SiGotomeeting } from 'react-icons/si';
import { FaCartPlus } from 'react-icons/fa';

import ToolTipView from "components/tooltipView";
import QuillEditor from 'components/textEditor/QuillEditor';

import EventlyGuests from './EventlyGuests';
import EventlyProduct from 'pages/auth/editEvently/components/eventlyProduct/EventlyProduct';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModifyUserEventlyDetailPayload } from 'redux/evently/evently.slice';

import { validateGoogleMeetUrl, validateSkypeMeetingUrl, validateZoomMeetingUrl } from 'utils/validation-functions';

const Editor = {
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ],
        clipboard: {
            matchVisual: false,
        }
    },
    formats: [
        'bold', 'italic', 'underline', 'blockquote', 'code-block',
        'list', 'bullet', 'indent',
    ]
}

const EventlyDetail = () => {
    const { userEventlyDetail, modifyUserEventlyDetail } = useAppState((state) => state.evently)

    const dispatcher = useAppDispatcher()

    const getMeetingIcon = () => {
        if (validateGoogleMeetUrl(modifyUserEventlyDetail?.payload?.meetingLink)) {
            return (
                <SiGooglemeet className={"text-text-700 text-xl cursor-pointer"} />
            )
        } else if (validateZoomMeetingUrl(modifyUserEventlyDetail?.payload?.meetingLink)) {
            return (
                <MdZoomOutMap className={"text-text-700 text-xl cursor-pointer"} />
            )
        } else if (validateSkypeMeetingUrl(modifyUserEventlyDetail?.payload?.meetingLink)) {
            return (
                <AiOutlineSkype className={"text-text-700 text-xl cursor-pointer"} />
            )
        } else {
            return (
                <SiGotomeeting className={"text-text-700 text-xl cursor-pointer"} />
            )
        }
    }

    const onHandleChangeMeetingLink = (event) => {
        dispatcher(setModifyUserEventlyDetailPayload({
            ...modifyUserEventlyDetail?.payload,
            meetingLink: event?.target?.value
        }))
    }

    const onHandleChangeLocation = (event) => {
        dispatcher(setModifyUserEventlyDetailPayload({
            ...modifyUserEventlyDetail?.payload,
            selectedLocation: event?.target?.value
        }))
    }

    const onHandleChangeDescription = (content) => {
        dispatcher(setModifyUserEventlyDetailPayload({
            ...modifyUserEventlyDetail?.payload,
            description: content
        }))
    }

    return (
        <div className={"w-full grid grid-cols-12 gap-y-5 md:gap-y-8"}>

            <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-6 flex items-start gap-5 md:gap-8"}>
                <ToolTipView content={"Guests emails"}>
                    <button className={"mt-1.5"}>
                        <MdPeople className={"text-text-700 text-lg"} />
                    </button>
                </ToolTipView>
                <EventlyGuests />
            </div>

            <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-6 flex items-center gap-5 md:gap-8"}>
                <ToolTipView content="Meeting Link">
                    <button className={""}>
                        {getMeetingIcon()}
                    </button>
                </ToolTipView>
                <input
                    type={"text"}
                    className={cn(
                        "flex-1 p-2 focus:outline-none bg-background-light border-b focus:border-primary-main",
                        "font-bodyPri font-normal text-text-900 text-base hover:bg-background-lightBlue placeholder:text-text-700"
                    )}
                    placeholder={"Enter Meeting Link"}
                    value={modifyUserEventlyDetail?.payload?.meetingLink}
                    onChange={onHandleChangeMeetingLink}
                />
            </div>

            <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-6 flex items-center gap-5 md:gap-8"}>
                <ToolTipView content={"Location"}>
                    <button className={""}>
                        <MdOutlineLocationOn className={"text-text-700 text-xl"} />
                    </button>
                </ToolTipView>
                <input
                    type={"text"}
                    className={cn(
                        "flex w-full p-2 focus:outline-none bg-background-light border-b focus:border-primary-main",
                        "font-bodyPri font-normal text-text-900 text-base hover:bg-background-lightBlue placeholder:text-text-700 capitalize"
                    )}
                    placeholder={"Enter Location"}
                    value={modifyUserEventlyDetail?.payload?.selectedLocation}
                    onChange={onHandleChangeLocation}
                />
            </div>

            <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-6 flex items-start gap-5 md:gap-8"}>
                <ToolTipView content={"Description"}>
                    <button className={"mt-1.5"}>
                        <MdOutlineFormatAlignLeft className={"text-text-700 text-xl"} />
                    </button>
                </ToolTipView>
                <QuillEditor
                    editorToolModules={Editor?.modules}
                    editorToolFormats={Editor?.formats}
                    className={"w-full focus:outline-none focus-within:border-primary-main border-1"}
                    editorText={modifyUserEventlyDetail?.payload?.description}
                    onHandleChangeText={onHandleChangeDescription}
                />
            </div>

            <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-6 flex items-start gap-5 md:gap-8"}>
                <div className={""}>
                    <FaCartPlus className={"text-text-700 text-lg cursor-pointer"} />
                </div>
                <EventlyProduct />
            </div>

            <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-6 flex items-center gap-5 md:gap-8"}>
                <IoMdCalendar className={"text-text-700 text-lg"} />
                <span className={"font-bodyPri text-text-900 font-medium text-base tracking-wide truncate"}>
                    {`${userEventlyDetail?.data?.owner_user?.first_name} ${userEventlyDetail?.data?.owner_user?.last_name}`}
                </span>
            </div>
        </div>
    )
}

export default EventlyDetail;