import MuxPlayer from "@mux/mux-player-react";
import { cn } from "utils/cn.utils";

import { MdOutlineCloudUpload } from "react-icons/md";
import { IoMdRefresh } from "react-icons/io";
import { BsRecordCircle } from "react-icons/bs";

import { MUX_ENV_KEY } from "const/default.const";

const CourseMuxVideoPlayer = (
    {
        streamType = "on-demand",
        thumbnailTime = "10",
        withLabel = true,
        disableCookies = true,
        autoPlay = false,
        paused = true,
        muted = false,
        isButtonDisabled = false,
        isShowRefreshButton = false,
        isShowButtons = true,
        playbackId,
        handleRecordVideo,
        handleUploadVideo,
        onHandleRefresh
    }
) => {

    return (
        <div className={"flex flex-col justify-start items-start sm:items-center sm:justify-center gap-3"}>
            <div className={"rounded-lg overflow-hidden w-full sm:w-[25rem] md:w-full h-full aspect-video z-0"}>
                <MuxPlayer
                    streamType={streamType}
                    // ref={videoRef}
                    style={{ height: '100%', maxWidth: '100%' }}
                    // debug={true}
                    disableCookies={disableCookies}
                    playbackId={playbackId}
                    thumbnailTime={thumbnailTime}
                    controls
                    autoPlay={autoPlay}
                    muted={muted}
                    primaryColor={"#9e9e9e"}
                    forwardSeekOffset={"10"}
                    backwardSeekOffset={"10"}
                    muxOptions={{
                        application_name: "Edulyte"
                    }}
                    metadata={{
                        envKey: MUX_ENV_KEY,
                        video_id: playbackId,
                        video_title: playbackId,
                        player_name: 'Mux Video React',
                        viewer_user_id: "user-id-007",
                    }}
                />
            </div>

            <div className={"w-full px-2 flex flex-col items-center justify-center sm:flex-row sm:items-center sm:justify-center gap-1.5 sm:gap-3"}>
                {isShowRefreshButton &&
                    <div
                        className={cn(
                            "relative has-tooltip w-24 sm:w-full px-2 py-1 flex items-center justify-center font-bodyPri font-normal text-sm cursor-pointer rounded-lg gap-2",
                            !isButtonDisabled && "border border-primary-dark text-primary-dark hover:bg-primary-dark hover:text-text-50",
                            isButtonDisabled && "border border-text-500 text-text-500 cursor-not-allowed"
                        )}
                        onClick={onHandleRefresh}
                    >
                        <IoMdRefresh className={"text-base md:text-lg lg:text-xl"} />
                        <span
                            className={cn(
                                "w-16 px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -top-5 right-2.5",
                                "flex flex-col items-center justify-start overflow-hidden"
                            )}
                        >
                            {"Refresh"}
                        </span>
                    </div>
                }
                {/* <div className={"w-full flex flex-col items-center justify-center sm:flex-row sm:items-center sm:justify-center gap-1.5"}> */}
                {isShowButtons &&
                    <div className={cn(
                        "relative has-tooltip w-24 sm:w-full px-2 py-1 flex items-center justify-center gap-2 rounded-lg font-bodyPri font-normal text-sm tracking-wide",
                        !isButtonDisabled && "border border-secondary-dark text-secondary-dark hover:bg-secondary-dark hover:text-text-50 cursor-pointer",
                        isButtonDisabled && "border border-text-500 text-text-500 cursor-not-allowed"
                    )}
                        onClick={handleRecordVideo}
                    >
                        <BsRecordCircle className={"text-base md:text-lg lg:text-xl"} />
                        <span
                            className={cn(
                                "w-16 px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -top-5 right-2.5",
                                "flex flex-col items-center justify-start overflow-hidden"
                            )}
                        >
                            {"Record"}
                        </span>
                    </div>
                }
                {isShowButtons &&
                    <div className={cn(
                        "relative has-tooltip w-24 sm:w-full px-2 py-1 flex items-center justify-center gap-2 rounded-lg font-bodyPri font-normal text-sm tracking-wide",
                        !isButtonDisabled && "border border-primary-dark text-primary-dark hover:bg-primary-dark hover:text-text-50 cursor-pointer",
                        isButtonDisabled && "border border-text-500 text-text-500 cursor-not-allowed"
                    )}
                        onClick={handleUploadVideo}
                    >
                        <MdOutlineCloudUpload className={"text-base md:text-lg lg:text-xl"} />
                        <span
                            className={cn(
                                "w-16 px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -top-5 right-2.5",
                                "flex flex-col items-center justify-start overflow-hidden"
                            )}
                        >
                            {"Upload"}
                        </span>

                    </div>
                }
                {/* </div> */}
            </div>
        </div>
    )
}

export default CourseMuxVideoPlayer