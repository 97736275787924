import tw, { styled } from 'twin.macro';
import { NavLink as RouteLink } from 'react-router-dom';
import {
    ArrowDropDown,
    ArrowDropUp,
    PersonAdd,
    Login,
    HelpOutlineRounded,
    Menu,
    CancelOutlined,
    Close,
    Category,
    People,
    LocalLibrary,
    DynamicForm,
    ChatBubbleOutline,
    FavoriteBorder,
    NotificationsNone,
} from '@mui/icons-material';
import { School } from '@mui/icons-material';
import { WifiProtectedSetupOutlined } from '@mui/icons-material';
import { FaChalkboardTeacher } from 'react-icons/fa';

import { motion } from 'framer-motion';


export const Container = styled.header`
    ${tw`
        w-full grid grid-cols-12
        font-bodyPri text-black bg-white
        hover:(bg-white text-black)
    `}
    ${({ isScrolled }) => (isScrolled) && tw`
        bg-primary-dark text-white hover:(bg-primary-dark text-white)
    `}
`;

export const LargeNavBar = tw.div`
    hidden
    lg:(col-start-2 col-span-10 flex flex-row items-center justify-between h-16)
`;

export const MediumNavBar = tw.div`
    hidden
    md:(col-start-2 col-span-10 flex flex-row items-center justify-between h-16)
    lg:hidden
`;

export const SmallNavBar = tw.div`
    col-start-2 col-span-10 flex flex-row items-center justify-between h-16
    md:hidden
`;

export const SideBarHamburgerButton = styled(Menu)`
    ${tw`
        hidden
        lg:(grid col-start-1 col-span-1 self-center justify-self-center text-4xl hover:text-primary-dark)
    `}

    ${({ isScrolled }) => (isScrolled) && tw`
        hover:text-white!
    `}
`;

export const SideBarCloseButton = tw(Close)`
    hidden
    lg:(grid col-start-1 col-span-1 self-center justify-self-center text-4xl hover:text-complementry-light)
`;

export const LeftNavLinksContainer = tw.div`
    flex items-center gap-48
    lg:(gap-10)
`;

export const CenterNavLinksContainer = tw.div`
    hidden
    lg:(flex items-center justify-center gap-10)
`;

export const RightNavLinksContainer = tw.div`
    flex items-center justify-end gap-10
`;

export const ArrowDropDownIcon = styled(ArrowDropDown)`
    font-size: 24px;
`;

export const ArrowDropUpIcon = styled(ArrowDropUp)`
    font-size: 24px;
`;

export const NavLinks = tw.div`
    inline-block
`;

export const DropDownContainer = tw.div`
    relative flex flex-col items-start justify-start gap-3 cursor-pointer
`;

export const learnersDropDownText = tw.div`
    font-normal tracking-wide text-base cursor-pointer
    hover:(text-primary-dark)
`;

export const NavLink = styled(RouteLink)`
    ${tw`
        font-normal tracking-wide text-base cursor-pointer
        hover:(text-primary-dark) 
    `}
    ${({ isScrolled }) => (isScrolled) && tw`
        hover:text-white!
    `}
`;

export const NavAnchorLink = tw.a`
    font-normal tracking-wide  text-base cursor-pointer
    hover:(text-complementry-light) 
`;

export const TeachWithUsLink = styled(NavLink)`
    ${tw`
        border-2 rounded-lg border-black px-2 py-1
        hover:(border-primary-dark! text-primary-dark!)
    `}
    ${({ isScrolled }) => (isScrolled) && tw`
        border-white hover:border-white! hover:text-white!
    `}
`;

export const LogoContainer = tw.div`
    flex gap-5 items-center justify-center
`;

export const LogoLink = styled(NavLink)`
    ${tw`
        font-bodyPri! text-3xl!
        hover:text-white
    `}
    ${({ isScrolled }) => (isScrolled) && tw`
        text-white hover:text-white
    `}
`;

export const LogoAnchorLink = styled(NavAnchorLink)`
    ${tw`
        font-bodyPri! text-3xl!
        hover:text-primary-dark
    `}
    ${({ isScrolled }) => (isScrolled) && tw`
        text-white hover:text-white
    `}
`;

export const BetaAnchorLink = styled(NavAnchorLink)`
    ${tw`
        px-1.5 py-0.5 rounded text-xs bg-secondary-dark !text-white
    `}
`;

export const QuestionIcon = styled(HelpOutlineRounded)`
    ${tw`
        text-black text-xl cursor-pointer
        hover:text-primary-dark!
    `}
    ${({ isScrolled }) => (isScrolled) && tw`
        text-white hover:text-white!
    `}
`;

export const AuthSection = tw(NavLink)`
    flex gap-2
`;

export const SignUpIcon = styled(PersonAdd)``;

export const LogInIcon = styled(Login)``;

export const HamburgerButton = styled(Menu)`
    ${tw`
        flex justify-end text-3xl cursor-pointer
        hover:text-primary-dark!
    `}
    ${({ isScrolled }) => (isScrolled) && tw`
        text-white hover:text-white!
    `}
`;

export const HamburgerMenuContainer = tw.div`
    min-w-[20rem] p-5 space-y-3 h-screen
`;

export const HamburgerAuthSection = tw(NavLink)`
    flex gap-2 hover:!text-secondary-main
`;

export const HamburgerHeader = tw.div`
    flex items-center justify-between
`;

export const HamburgerHeaderContent = tw.div`
    flex flex[1] items-center justify-evenly
`;

export const CloseIcon = styled(CancelOutlined)`
    ${tw`
        text-text-900 cursor-pointer shadow-xl hover:text-primary-dark
    `}
`;

export const HamburgerContent = tw.div`
    flex flex-col gap-3
`;

export const HamburgerFeatured = tw.div`
    grid grid-cols-1 gap-3 md:gap-5
`;

export const HamburgerFeaturedLink = tw(RouteLink)`
    flex items-center justify-start gap-3 cursor-pointer select-none
    bg-primary-light text-primary-dark text-base font-bodyPri font-medium py-2 px-3 rounded-md
    hover:(bg-primary-dark text-text-50)
`;

export const CategoryIcon = styled(Category)``;

export const FindTeacherIcon = styled(People)``;

export const FindCoursesIcon = styled(LocalLibrary)``;

export const TeachWithUsIcon = styled(DynamicForm)``;

// loged in style

export const LogedInNavLink = tw.div`
    flex flex-row gap-5 relative items-center
`;

export const ReferFriend = tw(TeachWithUsLink)``;

export const IconSection = tw.span`
    flex flex-row items-center gap-3
`;

export const IconContainer = styled.span`
    ${tw`
        relative cursor-pointer
        font-normal tracking-wide text-base
        hover:(text-primary-dark)
    `}
    ${({ isScrolled }) => (isScrolled) && tw`
        hover:text-white!
    `}
`;

// export const IconContainer = tw(NavLink)`
//     relative cursor-pointer
// `;

export const MessageIcon = tw(ChatBubbleOutline)`
    text-2xl
`;

export const QuestionHelpIcon = tw(HelpOutlineRounded)`
    text-2xl
`;

export const FavoriteIcon = tw(FavoriteBorder)`
    text-2xl
`;

export const NotificationIcon = tw(NotificationsNone)`
    text-2xl
`;

export const BadgeCounter = tw.span`
    absolute z-40 right-[-0.5rem] top-[-0.5rem] w-5 h-5 rounded-full bg-complementry-light
    font-bodyPri font-normal text-xs text-text-50 flex items-center justify-center
`;

export const ProfileIcon = styled.div`
    ${tw`
        w-8 h-8 rounded-full overflow-hidden relative
        border-2 border-text-800 cursor-pointer 
        group-hover:border-black hover:border-black!
    `}
    ${({ isScrolled }) => (isScrolled) && tw`
        border-white hover:border-white! group-hover:border-white
    `}
    img {
        ${tw`
            w-full h-full bg-cover
        `}
    }
`;

export const ToggleMenuContent = tw.div`
    w-full space-y-3
`;

export const ItemIcon = tw.span`
    text-primary-main text-4xl px-5 flex items-center h-full
`;

export const ItemName = motion(tw.span`
    inline-block overflow-hidden flex flex-nowrap items-center h-full
    font-bodyPri font-normal text-base whitespace-nowrap !text-text-800
`);

export const Item = tw.div`
     w-full flex flex-row py-2 md:py-4 cursor-pointer select-none rounded-md
    hover:(bg-text-100 text-primary-main! scale-150!) 
`;

export const HamburgerMenuContent = tw.div`
    w-full h-full sm:space-y-1 space-y-3 pb-5 md:pb-3
`;

export const ItemLink = tw(RouteLink)`
     w-full flex flex-row h-12 cursor-pointer select-none rounded-md
    hover:(bg-text-100 text-primary-main! scale-150!) 
`;

export const ProfileImageContainer = styled.div`
    ${tw`
        w-8 h-8 rounded-full overflow-hidden relative
        border-2 border-text-500 cursor-pointer
    `}
    img {
        ${tw`
            w-full h-full bg-cover bg-center
        `}
    }
`;

export const ListItem = tw.div`
    w-full flex flex-row items-center h-12 cursor-pointer select-none rounded-r-full pr-5
    hover:(bg-primary-light text-primary-main! scale-125)
`;

export const RoleSwitchIcon = motion(tw(WifiProtectedSetupOutlined)``);

export const StudentIcon = tw(School)``;

export const TutorIcon = tw(FaChalkboardTeacher)`
    text-2xl
`;

// sub menu item 
export const SubMenuItem = tw.div`
     w-full flex flex-row py-2 md:py-4 px-16 cursor-pointer select-none rounded-md
    hover:(bg-text-100 text-primary-main! scale-150!)
`;