import gptService from "./gpt.service";
import {
    setGptTokenTxnListLoading,
    setGptTokenTxnListData,
    setGptTokenTxnListMessage,
} from "./gpt.slice";

export const getGptTokenTxnList = () => async (dispatch) => {
    dispatch(setGptTokenTxnListLoading(true))

    try {
        const response = await gptService.getGptTokenTxnList()
        if (response.status === 200) {
            dispatch(setGptTokenTxnListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setGptTokenTxnListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setGptTokenTxnListLoading(false))
    }
}