import { useMemo, useEffect } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

import FullPageLoader from 'components/loader/FullPageLoader';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setLocals } from "redux/local/local.slice";
import { localsConst } from 'redux/local/local.const';
import { userRoles } from 'redux/auth/auth.const';

import { pagesInfo } from 'utils/pagesInfo';

export const PrivateRouter = ({ allowedRoles }) => {
    const { session } = useAppState(s => s.auth);
    const { user } = useAppState(s => s.user);
    const { locals } = useAppState(s => s.local)

    const location = useLocation();
    const dispatcher = useAppDispatcher()

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search), [location.search])

    useEffect(() => {
        if (session?.isLoggedIn && (userRoles.TUTOR.value !== locals.userRole) && user?.user?.roles?.find(role => allowedRoles?.includes(role.role) && (searchQueryParams.get("role_as") === userRoles.TUTOR.value))) {
            dispatcher(setLocals({
                ...locals,
                [localsConst.USER_ROLE.value]: userRoles.TUTOR.value
            }))
        }
    }, [location.search, session?.isLoggedIn])

    return (
        session?.isLoggedIn && user?.user?.roles?.find(role => allowedRoles?.includes(role.role) && ((searchQueryParams.get("role_as") === userRoles.TUTOR.value) || (role.role === locals.userRole)))
            ? <Outlet />
            : session?.isLoggedIn
                ? <Navigate to={pagesInfo.UNAUTHORIZED.pagePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />
                : !user?.isLoading
                    ? <Navigate to={pagesInfo.LOG_IN.pagePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />
                    : <FullPageLoader isLoading={true} />
    );
}

export default PrivateRouter;