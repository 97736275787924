import { useNavigate } from 'react-router-dom';

import { GiProcessor } from 'react-icons/gi';
import { FaSpinner } from 'react-icons/fa';

import { Container } from "pages/auth/orderConfirmation/OrderConfirmationPageStyle";

import { useAppState } from 'hooks/useStore';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';
import { bookingStatusEnum } from 'redux/booking/booking.const';

import { pagesInfo } from 'utils/pagesInfo';
import { currencyRateConversion } from 'utils/generators.utils';

const ProcessingBookingConfirmation = () => {
    const { locals } = useAppState(s => s.local)
    const { bookingDetailConfirmation } = useAppState((state) => state.booking)

    const navigate = useNavigate()

    const pageRedirect = () => {
        navigate(`${pagesInfo.USER_FINANCE.pagePath}${pagesInfo.BOOKINGS.pagePath}?actionBookingId=${bookingDetailConfirmation?.data?.result?.id}`, { replace: true })
    }

    return bookingDetailConfirmation?.data?.result ? (
        <Container className={"!shadow-all-md"}>
            <GiProcessor className={"text-8xl text-yellow-500 animate-pulse"} />
            <div className={"flex items-center justify-center gap-2 font-bodyPri font-semibold text-2xl text-primary-dark"}>
                <span>{bookingDetailConfirmation?.data?.result?.bookingPrice?.masterCurrency?.code}</span>
                <span>{parseFloat(bookingDetailConfirmation?.data?.result?.bookingPrice?.totalAmount / 100).toFixed(2)}</span>
            </div>
            {([masterCurrencyCodeEnum.USD.value]?.includes(bookingDetailConfirmation?.data?.result?.bookingPrice?.masterCurrency?.code) && (locals.currencyCode !== masterCurrencyCodeEnum.USD.value)) &&
                <div className={"flex items-center justify-center gap-2 font-bodyPri font-medium text-lg text-text-800"}>
                    <span>{currencyRateConversion(locals?.currencyCode, bookingDetailConfirmation?.data?.result?.bookingPrice?.totalAmount)}</span>
                </div>
            }
            <div className={"flex flex-col items-center gap-2"}>
                <span className={"font-bodyPri font-medium text-md text-text-800"}>
                    {"You have purchased the course"}
                </span>
                <span className={"font-bodyPri font-semibold text-lg text-yellow-500 tracking-wider"}>
                    {bookingStatusEnum[bookingDetailConfirmation?.data?.result?.status?.toUpperCase()].label}
                </span>
                <span className={"font-bodyPri font-medium text-md text-text-800"}>
                    {"Please wait while processing..."}
                </span>
            </div>
            <div className={"flex items-center justify-center gap-2"}>
                <span className={"font-bodyPri font-normal text-base text-800"}>
                    {"Booking ID:"}
                </span>
                <span className={"font-bodyPri font-semibold text-md text-text-900"}>
                    {bookingDetailConfirmation?.data?.result?.id}
                </span>
            </div>
            <div className={"w-36 py-2 flex justify-center rounded-full cursor-wait text-secondary-main border-2 border-secondary-main hover:text-text-50 hover:bg-secondary-main"}>
                <FaSpinner className={"text-xl animate-spin"} />
            </div>
        </Container>
    ) : null
}

export default ProcessingBookingConfirmation