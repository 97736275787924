import React from "react";
import { VideoCameraIcon } from "@heroicons/react/outline";

//Style imports
import { StyledChatViewMeetingButton } from "./ChatViewMeetingButton.styles";

export default function ChatViewMeetingButton({ onClick }) {
  return (
    <StyledChatViewMeetingButton onClick={onClick}>
      New
      <VideoCameraIcon className="h-5 w-5 ml-1.5" />
    </StyledChatViewMeetingButton>
  );
}
