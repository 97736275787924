import { cn } from "utils/cn.utils";

import { IoMdCalendar } from 'react-icons/io';

import { useAppState } from 'hooks/useStore';

const EventlyOrganizer = () => {
    const { userEventlyDetail } = useAppState((state) => state.evently)

    return (
        <div className={"w-full grid grid-cols-12 gap-x-2 gap-y-3 px-5"}>
            <div className={"relative has-tooltip col-start-1 col-span-1 self-center cursor-pointer"}>
                <IoMdCalendar className={"text-text-700 text-lg"} />
                <span
                    className={cn(
                        `w-fit !z-[500] px-3 py-0.5 md:py-1 font-bodyPri font-normal text-sm md:text-md rounded shadow-lg bg-text-900 text-text-50 tooltip -translate-y-12`,
                        "flex flex-col items-start justify-start overflow-hidden whitespace-normal sm:whitespace-nowrap"
                    )}
                >
                    {"Event owner's name"}
                </span>
            </div>
            <div className={"col-start-2 col-span-full self-center"}>
                <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide truncate"}>
                    {`${userEventlyDetail?.data?.owner_user?.first_name} ${userEventlyDetail?.data?.owner_user?.last_name}`}
                </span>
            </div>
        </div>
    )
}

export default EventlyOrganizer;