import { cn } from "utils/cn.utils";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ButtonLoader from 'components/loader/ButtonLoader';

import { createOffDayDetail, updateOffDayDetail } from 'redux/availability/availability.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setAddOffDayDetailPayload } from 'redux/availability/availability.slice';

import { dayjs, timeZone } from "utils/dateTime.utils";

const AddOffDayModal = () => {
    const { user } = useAppState((state) => state.user)
    const { addOffDayDetail } = useAppState((state) => state.availability)

    const dispatcher = useAppDispatcher()

    const onSubmit = (event) => {
        event.preventDefault()

        if (addOffDayDetail?.isLoading) return;

        const body = {
            userId: user?.user?.userId,
            timeZone: timeZone,
            name: addOffDayDetail?.addOffDayDetailPayload?.name,
            description: addOffDayDetail?.addOffDayDetailPayload?.description,
            startDateTime: addOffDayDetail?.addOffDayDetailPayload?.startDateTime,
            endDateTime: addOffDayDetail?.addOffDayDetailPayload?.endDateTime
        }

        if (addOffDayDetail?.addOffDayDetailPayload?.offDayId) {
            dispatcher(updateOffDayDetail(addOffDayDetail?.addOffDayDetailPayload?.offDayId, body))
            return;
        }
        dispatcher(createOffDayDetail(body))
    }

    return (
        <form className={"space-y-5 overflow-x-hidden"} onSubmit={onSubmit}>
            <div className={"min-h-[24rem] block pt-5 space-y-5"}>
                <div className={"w-full flex flex-col items-start justify-start gap-5"}>

                    {/* message container */}
                    <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                            {"Off day name"}
                            <span className={"font-bodyPri font-normal text-lg text-red-500"}>{"*"}</span>
                        </span>
                        <input
                            type={"text"}
                            className={cn(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            placeholder={"Reason for Offday (private to you only)"}
                            value={addOffDayDetail?.addOffDayDetailPayload?.name}
                            onChange={(event) => dispatcher(setAddOffDayDetailPayload({
                                ...addOffDayDetail?.addOffDayDetailPayload,
                                name: event?.target?.value
                            }))}
                        />
                    </div>

                    {/* description container */}
                    <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                            {"Description"}
                        </span>
                        <textarea
                            type={"text"}
                            className={cn(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            placeholder={"Enter Description..."}
                            rows={5}
                            minLength={20}
                            maxLength={500}
                            value={addOffDayDetail?.addOffDayDetailPayload?.description}
                            onChange={(event) => dispatcher(setAddOffDayDetailPayload({
                                ...addOffDayDetail?.addOffDayDetailPayload,
                                description: event?.target?.value
                            }))}
                        />
                    </div>

                    {/* start date Time */}
                    <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                            {"Start Date"}
                            <span className={"font-bodyPri font-normal text-lg text-red-500"}>{"*"}</span>
                        </span>
                        <DatePicker
                            className={cn(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-900 text-base placeholder:text-text-500 placeholder:text-sm"
                            )}
                            dateFormat={"eee, dd MMM yy hh:mm:ss a"}
                            value={dayjs(addOffDayDetail?.addOffDayDetailPayload?.startDateTime)?.toDate()}
                            selected={dayjs(addOffDayDetail?.addOffDayDetailPayload?.startDateTime)?.toDate()}
                            minDate={new Date()}
                            placeholderText={"Select Date"}
                            scrollableYearDropdown={true}
                            showYearDropdown={true}
                            showTimeSelect={true}
                            timeIntervals={15}
                            yearDropdownItemNumber={80}
                            showMonthDropdown={true}
                            onChange={(date) => dispatcher(setAddOffDayDetailPayload({
                                ...addOffDayDetail?.addOffDayDetailPayload,
                                startDateTime: dayjs(date)?.format("YYYY-MM-DD HH:mm:ss")
                            }))}
                            required={true}
                        />
                    </div>

                    {/* end date time */}
                    <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                            {"End Date"}
                            <span className={"font-bodyPri font-normal text-lg text-red-500"}>{"*"}</span>
                        </span>
                        <DatePicker
                            className={cn(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-900 text-base placeholder:text-text-500 placeholder:text-sm"
                            )}
                            dateFormat={"eee, dd MMM yy hh:mm:ss a"}
                            value={dayjs(addOffDayDetail?.addOffDayDetailPayload?.endDateTime)?.toDate()}
                            selected={dayjs(addOffDayDetail?.addOffDayDetailPayload?.endDateTime)?.toDate()}
                            minDate={dayjs(addOffDayDetail?.addOffDayDetailPayload?.endDateTime)?.toDate()}
                            placeholderText={"Select End Date"}
                            scrollableYearDropdown={true}
                            showYearDropdown={true}
                            showTimeSelect={true}
                            timeIntervals={15}
                            yearDropdownItemNumber={80}
                            showMonthDropdown={true}
                            onChange={(date) => dispatcher(setAddOffDayDetailPayload({
                                ...addOffDayDetail?.addOffDayDetailPayload,
                                endDateTime: dayjs(date)?.format("YYYY-MM-DD HH:mm:ss")
                            }))}
                            required={true}
                        />
                    </div>

                </div>
                {addOffDayDetail?.errorMsg &&
                    <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {addOffDayDetail?.errorMsg}
                    </div>
                }
            </div>

            <div className={"flex justify-end"}>
                {/* <div
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base",
                        "text-text-600 border border-text-600 hover:bg-text-600 hover:text-text-50",
                    )}
                    onClick={() => dispatcher(setClearAddUserItemDeta)}
                    >
                    {"Reset"}
                </div> */}
                <button
                    type={"submit"}
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full",
                        "font-buttons font-normal text-base",
                        !addOffDayDetail?.addOffDayDetailPayload?.name && "bg-text-300 hover:bg-text-300 text-text-50 cursor-not-allowed",
                        (addOffDayDetail?.addOffDayDetailPayload?.name && !addOffDayDetail?.isLoading) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                        (addOffDayDetail?.addOffDayDetailPayload?.name && addOffDayDetail?.isLoading) && "bg-secondary-main"
                    )}
                    disabled={!addOffDayDetail?.addOffDayDetailPayload?.name || !addOffDayDetail?.addOffDayDetailPayload?.startDateTime}
                >
                    {addOffDayDetail?.isLoading && <ButtonLoader isLoading={addOffDayDetail?.isLoading} />}
                    {!addOffDayDetail?.isLoading && "Submit"}
                </button>
            </div>
        </form>
    )
}

export default AddOffDayModal