import { createSlice } from "@reduxjs/toolkit";

import { DOWNLOADS_INITIAL_STATE } from "./downloads.initialState";

const downloads = createSlice({
    name: "Downloads",
    initialState: DOWNLOADS_INITIAL_STATE,
    reducers: {
        // user downloads list
        setUserDownloadsListLoading: (state, { payload }) => {
            state.userDownloadsList.isLoading = payload
        },
        setUserDownloadsListData: (state, { payload }) => {
            state.userDownloadsList.data = payload
            state.userDownloadsList.message = DOWNLOADS_INITIAL_STATE.userDownloadsList.message
        },
        setUserDownloadsListMessage: (state, { payload }) => {
            state.userDownloadsList.message = payload
            state.userDownloadsList.data = DOWNLOADS_INITIAL_STATE.userDownloadsList.data
        },
        resetUserDownloadsList: (state) => {
            state.userDownloadsList = DOWNLOADS_INITIAL_STATE.userDownloadsList
        },

        // user download detail
        setUserDownloadDetailLoading: (state, { payload }) => {
            state.userDownloadDetail.isLoading = payload
        },
        setUserDownloadDetailData: (state, { payload }) => {
            state.userDownloadDetail.data = payload
            state.userDownloadDetail.message = DOWNLOADS_INITIAL_STATE.userDownloadDetail.message
        },
        setUserDownloadDetailMessage: (state, { payload }) => {
            state.userDownloadDetail.message = payload
            state.userDownloadDetail.data = DOWNLOADS_INITIAL_STATE.userDownloadDetail.data
        },
        resetUserDownloadDetail: (state) => {
            state.userDownloadDetail = DOWNLOADS_INITIAL_STATE.userDownloadDetail
        },

        // owner user download list
        setOwnerUserDownloadsListLoading: (state, { payload }) => {
            state.ownerUserDownloadsList.isLoading = payload
        },
        setOwnerUserDownloadsListData: (state, { payload }) => {
            state.ownerUserDownloadsList.data = payload
            state.ownerUserDownloadsList.message = DOWNLOADS_INITIAL_STATE.ownerUserDownloadsList.message
        },
        setOwnerUserDownloadsListMessage: (state, { payload }) => {
            state.ownerUserDownloadsList.message = payload
            state.ownerUserDownloadsList.data = DOWNLOADS_INITIAL_STATE.ownerUserDownloadsList.data
        },
        resetOwnerUserDownloadsList: (state) => {
            state.ownerUserDownloadsList = DOWNLOADS_INITIAL_STATE.ownerUserDownloadsList
        },

        /* user download detail slice */
        setOwnerUserDownloadDetailLoading: (state, { payload }) => {
            state.ownerUserDownloadDetail.isLoading = payload
        },
        setOwnerUserDownloadDetailData: (state, { payload }) => {
            state.ownerUserDownloadDetail.data = payload
            state.ownerUserDownloadDetail.message = DOWNLOADS_INITIAL_STATE.ownerUserDownloadDetail.message
        },
        setOwnerUserDownloadDetailMessage: (state, { payload }) => {
            state.ownerUserDownloadDetail.message = payload
            state.ownerUserDownloadDetail.data = DOWNLOADS_INITIAL_STATE.ownerUserDownloadDetail.data
        },
        resetOwnerUserDownloadDetail: (state) => {
            state.ownerUserDownloadDetail = DOWNLOADS_INITIAL_STATE.ownerUserDownloadDetail
        },

        /* add user download Detail */
        setAddUserDownloadDetailLoading: (state, { payload }) => {
            state.addUserDownloadDetail.isLoading = payload
        },
        setAddUserDownloadDetailData: (state, { payload }) => {
            state.addUserDownloadDetail.data = payload
            state.addUserDownloadDetail.message = DOWNLOADS_INITIAL_STATE.addUserDownloadDetail.message
        },
        setAddUserDownloadDetailMessage: (state, { payload }) => {
            state.addUserDownloadDetail.message = payload
            state.addUserDownloadDetail.data = DOWNLOADS_INITIAL_STATE.addUserDownloadDetail.data
        },
        resetAddUserDownloadDetail: (state) => {
            state.addUserDownloadDetail = DOWNLOADS_INITIAL_STATE.addUserDownloadDetail
        },

        // add user download detail payload
        setAddUserDownloadDetailPayload: (state, { payload }) => {
            state.addUserDownloadDetail.payload = payload
        },
        resetAddUserDownloadDetailPayload: (state, { payload }) => {
            state.addUserDownloadDetail.payload = DOWNLOADS_INITIAL_STATE.addUserDownloadDetail.payload
        },

        // modify user download detail
        setModifyUserDownloadDetailLoading: (state, { payload }) => {
            state.modifyUserDownloadDetail.isLoading = payload
        },
        setModifyUserDownloadDetailData: (state, { payload }) => {
            state.modifyUserDownloadDetail.data = payload
            state.modifyUserDownloadDetail.message = DOWNLOADS_INITIAL_STATE.modifyDownloadUserDetail.message
        },
        setModifyUserDownloadDetailMessage: (state, { payload }) => {
            state.modifyUserDownloadDetail.message = payload
            state.modifyUserDownloadDetail.data = DOWNLOADS_INITIAL_STATE.modifyDownloadUserDetail.data
        },
        resetModifyUserDownloadDetail: (state) => {
            state.modifyUserDownloadDetail = DOWNLOADS_INITIAL_STATE.modifyUserDownloadDetail
        },

        // destroy user download detail
        setDestroyUserDownloadDetailLoading: (state, { payload }) => {
            state.destroyUserDownloadDetail.isLoading = payload
        },
        setDestroyUserDownloadDetailMessage: (state, { payload }) => {
            state.destroyUserDownloadDetail.message = payload
        },
        resetDestroyUserDownloadDetail: (state) => {
            state.destroyUserDownloadDetail = DOWNLOADS_INITIAL_STATE.destroyUserDownloadDetail
        },

        // create download preview detail
        setAddDownloadPreviewDetailLoading: (state, { payload }) => {
            state.addDownloadPreviewDetail.isLoading = payload
        },
        setAddDownloadPreviewDetailData: (state, { payload }) => {
            state.addDownloadPreviewDetail.data = payload
            state.addDownloadPreviewDetail.message = DOWNLOADS_INITIAL_STATE.addDownloadPreviewDetail.message
        },
        setAddDownloadPreviewDetailMessage: (state, { payload }) => {
            state.addDownloadPreviewDetail.message = payload
            state.addDownloadPreviewDetail.data = DOWNLOADS_INITIAL_STATE.addDownloadPreviewDetail.data
        },
        resetAddDownloadPreviewDetail: (state) => {
            state.addDownloadPreviewDetail = DOWNLOADS_INITIAL_STATE.addDownloadPreviewDetail
        },

        // modify order download preview detail
        setModifyOrderDownloadPreviewDetailLoading: (state, { payload }) => {
            state.modifyOrderDownloadPreviewDetail.isLoading = payload
        },
        setModifyOrderDownloadPreviewDetailMessage: (state, { payload }) => {
            state.modifyOrderDownloadPreviewDetail.message = payload
        },
        resetModifyOrderDownloadPreviewDetail: (state) => {
            state.modifyOrderDownloadPreviewDetail = DOWNLOADS_INITIAL_STATE.modifyOrderDownloadPreviewDetail
        },

        // destroy download preview detail
        setDestroyDownloadPreviewDetailLoading: (state, { payload }) => {
            state.destroyDownloadPreviewDetail.isLoading = payload
        },
        setDestroyDownloadPreviewDetailMessage: (state, { payload }) => {
            state.destroyDownloadPreviewDetail.message = payload
        },
        resetDestroyDownloadPreviewDetail: (state) => {
            state.destroyDownloadPreviewDetail = DOWNLOADS_INITIAL_STATE.destroyDownloadPreviewDetail
        },

        // create download file detail
        setAddDownloadFileDetailLoading: (state, { payload }) => {
            state.addDownloadFileDetail.isLoading = payload
        },
        setAddDownloadFileDetailData: (state, { payload }) => {
            state.addDownloadFileDetail.data = payload
            state.addDownloadFileDetail.message = DOWNLOADS_INITIAL_STATE.addDownloadFileDetail.message
        },
        setAddDownloadFileDetailMessage: (state, { payload }) => {
            state.addDownloadFileDetail.message = payload
            state.addDownloadFileDetail.data = DOWNLOADS_INITIAL_STATE.addDownloadFileDetail.data
        },
        resetAddDownloadFileDetail: (state) => {
            state.addDownloadFileDetail = DOWNLOADS_INITIAL_STATE.addDownloadFileDetail
        },

        // modify order download file detail
        setModifyOrderDownloadFileDetailLoading: (state, { payload }) => {
            state.modifyOrderDownloadFileDetail.isLoading = payload
        },
        setModifyOrderDownloadFileDetailMessage: (state, { payload }) => {
            state.modifyOrderDownloadFileDetail.message = payload
        },
        resetModifyOrderDownloadFileDetail: (state) => {
            state.modifyOrderDownloadFileDetail = DOWNLOADS_INITIAL_STATE.modifyOrderDownloadFileDetail
        },

        // destroy download file detail
        setDestroyDownloadFileDetailLoading: (state, { payload }) => {
            state.destroyDownloadFileDetail.isLoading = payload
        },
        setDestroyDownloadFileDetailMessage: (state, { payload }) => {
            state.destroyDownloadFileDetail.message = payload
        },
        resetDestroyDownloadFileDetail: (state) => {
            state.destroyDownloadFileDetail = DOWNLOADS_INITIAL_STATE.destroyDownloadFileDetail
        },

        // download user list
        setDownloadUserListLoading: (state, { payload }) => {
            state.downloadUserList.isLoading = payload
        },
        setDownloadUserListData: (state, { payload }) => {
            state.downloadUserList.data = payload
            state.downloadUserList.message = DOWNLOADS_INITIAL_STATE.downloadUserList.message
        },
        setDownloadUserListMessage: (state, { payload }) => {
            state.downloadUserList.message = payload
            state.downloadUserList.data = DOWNLOADS_INITIAL_STATE.downloadUserList.data
        },
        resetDownloadUserList: (state) => {
            state.downloadUserList = DOWNLOADS_INITIAL_STATE.downloadUserList
        },

        // modify download user detail
        setModifyDownloadUserDetailLoading: (state, { payload }) => {
            state.modifyDownloadUserDetail.isLoading = payload
        },
        setModifyDownloadUserDetailMessage: (state, { payload }) => {
            state.modifyDownloadUserDetail.message = payload
        },
        resetModifyDownloadUserDetail: (state) => {
            state.modifyDownloadUserDetail = DOWNLOADS_INITIAL_STATE.modifyDownloadUserDetail
        },

        // destroy download user detail
        setDestroyDownloadUserDetailLoading: (state, { payload }) => {
            state.destroyDownloadUserDetail.isLoading = payload
        },
        setDestroyDownloadUserDetailMessage: (state, { payload }) => {
            state.destroyDownloadUserDetail.message = payload
        },
        resetDestroyDownloadUserDetail: (state) => {
            state.destroyDownloadUserDetail = DOWNLOADS_INITIAL_STATE.destroyDownloadUserDetail
        },

        // download invitations
        setDownloadInvitationListLoading: (state, { payload }) => {
            state.downloadInvitationList.isLoading = payload
        },
        setDownloadInvitationListData: (state, { payload }) => {
            state.downloadInvitationList.data = payload
            state.downloadInvitationList.message = DOWNLOADS_INITIAL_STATE.downloadInvitationList.message
        },
        setDownloadInvitationListMessage: (state, { payload }) => {
            state.downloadInvitationList.message = payload
            state.downloadInvitationList.data = DOWNLOADS_INITIAL_STATE.downloadInvitationList.data
        },
        resetDownloadInvitationList: (state) => {
            state.downloadInvitationList = DOWNLOADS_INITIAL_STATE.downloadInvitationList
        },

        // send download invitation
        setSendDownloadInvitationDetailLoading: (state, { payload }) => {
            state.sendDownloadInvitationDetail.isLoading = payload
        },
        setSendDownloadInvitationDetailData: (state, { payload }) => {
            state.sendDownloadInvitationDetail.data = payload
            state.sendDownloadInvitationDetail.message = DOWNLOADS_INITIAL_STATE.sendDownloadInvitationDetail.message
        },
        setSendDownloadInvitationDetailMessage: (state, { payload }) => {
            state.sendDownloadInvitationDetail.message = payload
            state.sendDownloadInvitationDetail.data = DOWNLOADS_INITIAL_STATE.sendDownloadInvitationDetail.data
        },
        resetSendDownloadInvitationDetail: (state) => {
            state.sendDownloadInvitationDetail = DOWNLOADS_INITIAL_STATE.sendDownloadInvitationDetail
        },

        // send download invitation payload
        setSendDownloadInvitationDetailPayload: (state, { payload }) => {
            state.sendDownloadInvitationDetail.payload = payload
        },
        resetSendDownloadInvitationDetailPayload: (state) => {
            state.sendDownloadInvitationDetail.payload = DOWNLOADS_INITIAL_STATE.sendDownloadInvitationDetail.payload
        },

        // Validate Download Invitation Detail
        setValidateDownloadInvitationDetailLoading: (state, { payload }) => {
            state.validateDownloadInvitationDetail.isLoading = payload
        },
        setValidateDownloadInvitationDetailData: (state, { payload }) => {
            state.validateDownloadInvitationDetail.data = payload
        },

        setValidateDownloadInvitationDetailMessage: (state, { payload }) => {
            state.validateDownloadInvitationDetail.message = payload
        },

        resetValidateDownloadInvitationDetail: (state) => {
            state.validateDownloadInvitationDetail = DOWNLOADS_INITIAL_STATE.validateDownloadInvitationDetail
        },

        // Accept or Decline Download Invitation Detail
        setAcceptAndDeclineDownloadInvitationDetailLoading: (state, { payload }) => {
            state.acceptAndDeclineDownloadInvitationDetail.isLoading = payload
        },
        setAcceptAndDeclineDownloadInvitationDetailData: (state, { payload }) => {
            state.acceptAndDeclineDownloadInvitationDetail.data = payload
        },

        setAcceptAndDeclineDownloadInvitationDetailMessage: (state, { payload }) => {
            state.acceptAndDeclineDownloadInvitationDetail.message = payload
        },

        resetAcceptAndDeclineDownloadInvitationDetail: (state) => {
            state.acceptAndDeclineDownloadInvitationDetail = DOWNLOADS_INITIAL_STATE.acceptAndDeclineDownloadInvitationDetail
        },

        // delete download invitation detail
        setDestroyDownloadInvitationDetailLoading: (state, { payload }) => {
            state.destroyDownloadInvitationDetail.isLoading = payload
        },
        setDestroyDownloadInvitationDetailMessage: (state, { payload }) => {
            state.destroyDownloadInvitationDetail.message = payload
        },
        resetDestroyDownloadInvitationDetail: (state) => {
            state.destroyDownloadInvitationDetail = DOWNLOADS_INITIAL_STATE.destroyDownloadInvitationDetail
        },

        // my downloads payload
        setMyDownloadsPayloadDetail: (state, { payload }) => {
            state.myDownloadsPayloadDetail.payload = payload
        },
        resetMyDownloadsPayloadDetail: (state) => {
            state.myDownloadsPayloadDetail = DOWNLOADS_INITIAL_STATE.myDownloadsPayloadDetail
        },
    }
})

export const {
    setUserDownloadsListLoading,
    setUserDownloadsListData,
    setUserDownloadsListMessage,
    resetUserDownloadsList,

    setUserDownloadDetailLoading,
    setUserDownloadDetailData,
    setUserDownloadDetailMessage,
    resetUserDownloadDetail,

    setOwnerUserDownloadsListLoading,
    setOwnerUserDownloadsListData,
    setOwnerUserDownloadsListMessage,
    resetOwnerUserDownloadsList,

    setOwnerUserDownloadDetailLoading,
    setOwnerUserDownloadDetailData,
    setOwnerUserDownloadDetailMessage,
    resetOwnerUserDownloadDetail,

    setAddUserDownloadDetailLoading,
    setAddUserDownloadDetailData,
    setAddUserDownloadDetailMessage,
    resetAddUserDownloadDetail,

    setAddUserDownloadDetailPayload,
    resetAddUserDownloadDetailPayload,

    setModifyUserDownloadDetailLoading,
    setModifyUserDownloadDetailData,
    setModifyUserDownloadDetailMessage,
    resetModifyUserDownloadDetail,

    setDestroyUserDownloadDetailLoading,
    setDestroyUserDownloadDetailMessage,
    resetDestroyUserDownloadDetail,

    setAddDownloadPreviewDetailLoading,
    setAddDownloadPreviewDetailData,
    setAddDownloadPreviewDetailMessage,
    resetAddDownloadPreviewDetail,

    setModifyOrderDownloadPreviewDetailLoading,
    setModifyOrderDownloadPreviewDetailMessage,
    resetModifyOrderDownloadPreviewDetail,

    setDestroyDownloadPreviewDetailLoading,
    setDestroyDownloadPreviewDetailMessage,
    resetDestroyDownloadPreviewDetail,

    setAddDownloadFileDetailLoading,
    setAddDownloadFileDetailData,
    setAddDownloadFileDetailMessage,
    resetAddDownloadFileDetail,

    setModifyOrderDownloadFileDetailLoading,
    setModifyOrderDownloadFileDetailMessage,
    resetModifyOrderDownloadFileDetail,

    setDestroyDownloadFileDetailLoading,
    setDestroyDownloadFileDetailMessage,
    resetDestroyDownloadFileDetail,

    setDownloadUserListLoading,
    setDownloadUserListData,
    setDownloadUserListMessage,
    resetDownloadUserList,

    setModifyDownloadUserDetailLoading,
    setModifyDownloadUserDetailMessage,
    resetModifyDownloadUserDetail,

    setDestroyDownloadUserDetailLoading,
    setDestroyDownloadUserDetailMessage,
    resetDestroyDownloadUserDetail,

    setDownloadInvitationListLoading,
    setDownloadInvitationListData,
    setDownloadInvitationListMessage,
    resetDownloadInvitationList,

    setSendDownloadInvitationDetailLoading,
    setSendDownloadInvitationDetailData,
    setSendDownloadInvitationDetailMessage,
    resetSendDownloadInvitationDetail,

    setSendDownloadInvitationDetailPayload,
    resetSendDownloadInvitationDetailPayload,

    setValidateDownloadInvitationDetailLoading,
    setValidateDownloadInvitationDetailData,
    setValidateDownloadInvitationDetailMessage,
    resetValidateDownloadInvitationDetail,

    setAcceptAndDeclineDownloadInvitationDetailData,
    setAcceptAndDeclineDownloadInvitationDetailLoading,
    setAcceptAndDeclineDownloadInvitationDetailMessage,
    resetAcceptAndDeclineDownloadInvitationDetail,

    setDestroyDownloadInvitationDetailLoading,
    setDestroyDownloadInvitationDetailMessage,
    resetDestroyDownloadInvitationDetail,

    setMyDownloadsPayloadDetail,
    resetMyDownloadsPayloadDetail
} = downloads.actions;

export default downloads.reducer;