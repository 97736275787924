import { cn } from "utils/cn.utils";

import { BsCheckLg } from "react-icons/bs";

const AvailabilityFilter = ({ weekOptions, selectedAvailabilityList, onHandleSelectAvailability }) => {

    const handleOptionSelection = (week) => {
        const selected = weekOptions?.find((o) => o.value === week?.value)

        if (!selected?.value) {
            alert("Please select any!")
            return;
        }

        const filteredList = selectedAvailabilityList?.filter((option) => option?.value === selected?.value)
        if (filteredList?.length > 0) {
            const filteredOptions = selectedAvailabilityList?.filter((option) => option?.value !== selected?.value)
            onHandleSelectAvailability(filteredOptions);
            return;
        }
        if (filteredList?.length === 0) {
            onHandleSelectAvailability([...selectedAvailabilityList, selected]);
        }
    }

    return (
        <div className={"w-full rounded-lg px-3 py-3"}>
            <div className={"space-y-3"}>
                <span className={"font-bodyPri font-medium text-text-900 text-sm"}>
                    {"Days of the week"}
                </span>
                <div className={""}>
                    {weekOptions?.map((week, index) => (
                        <div
                            key={index}
                            className={cn(
                                "w-full px-1.5 py-3 flex items-center justify-start gap-5 cursor-pointer",
                            )}
                            onClick={() => handleOptionSelection(week)}
                        >
                            <span className={cn(
                                'relative w-5 h-5 border border-text-300 rounded',
                            )}>
                                {(selectedAvailabilityList?.map((option) => option?.value)?.includes(week?.value)) &&
                                    <span className={"absolute top-0.5 right-0"}>
                                        <BsCheckLg className={"text-base text-primary-dark"} />
                                    </span>
                                }
                            </span>
                            <span className={'w-11/12 font-bodyPri font-normal text-text-900 text-sm tracking-wide truncate'}>
                                {week.label}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div >
    )
}

export default AvailabilityFilter