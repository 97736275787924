import format from "string-format";

import { baseApiInstance, baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/course/course.const";

class CourseService {

    // NEW COURSE SERVICES
    getUserCourseList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_COURSE_LIST, params),
            { params: query }
        )

        return response;
    }

    getUserFavoriteCourseList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_FAV_COURSE_LIST, params),
            { params: query }
        )

        return response;
    }

    getPublicTutorCourseList = async ({ params, query }) => {
        const response = await baseApiInstance().get(
            format(url.GET_PUBLIC_TUTOR_COURSE_LIST, params),
            { params: query }
        )

        return response;
    }

    createCourseFavoriteDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_FAVORITE_DETAIL),
            body
        )

        return response;
    }

    deleteCourseFavoriteDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.CREATE_COURSE_FAVORITE_DETAIL),
            { data: body }
        )

        return response;
    }

    // Public Course Service
    getPublicCourseList = async ({ query }) => {
        const response = await baseApiInstance().get(
            format(url.GET_PUBLIC_COURSE_LIST),
            { params: query }
        )

        return response;
    }
    getPublicCourseDetail = async ({ params }) => {
        const response = await baseApiInstance().get(
            format(url.GET_PUBLIC_COURSE_DETAIL, params)
        )

        return response;
    }

    // Course Template Service
    getCourseTemplateList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_TEMPLATE_LIST),
            { params: query }
        )

        return response;
    }

    getCourseTemplateDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_TEMPLATE_DETAIL, params)
        )

        return response;
    }

    // Course service
    createCourseDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_DETAIL),
            body
        )

        return response;
    }
    getCourseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_DETAIL, params)
        )

        return response;
    }
    updateCourseDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_DETAIL, params),
            body
        )

        return response;
    }
    deleteCourseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_DETAIL, params)
        )

        return response;
    }

    // Course Preview Service
    getCoursePreviewDetail = async ({ query, params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_PREVIEW_DETAIL, params),
            { params: query }
        )

        return response;
    }

    // Course Tag Service
    createCourseTagDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_TAG_DETAIL),
            body
        )

        return response;
    }
    getCourseTagList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_TAG_LIST),
            { params: query }
        )

        return response;
    }
    updateCourseTagListOrder = async ({ body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_TAG_LIST_ORDER),
            body, { params: query }
        )

        return response;
    }
    deleteCourseTagDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_TAG_DETAIL, params)
        )

        return response;
    }

    // Course Language Service
    createCourseLanguageDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_LANGUAGE_DETAIL),
            body
        )

        return response;
    }
    getCourseLanguageList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_LANGUAGE_LIST),
            { params: query }
        )

        return response;
    }
    updateCourseLanguageListOrder = async ({ body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_LANGUAGE_LIST_ORDER),
            body, { params: query }
        )

        return response;
    }
    deleteCourseLanguageDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_LANGUAGE_DETAIL, params)
        )

        return response;
    }

    // Course Proficiency Service
    createCourseProficiencyDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_PROFICIENCY_DETAIL),
            body
        )

        return response;
    }
    getCourseProficiencyList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_PROFICIENCY_LIST),
            { params: query }
        )

        return response;
    }
    updateCourseProficiencyListOrder = async ({ body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_PROFICIENCY_LIST_ORDER),
            body, { params: query }
        )

        return response;
    }
    deleteCourseProficiencyDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_PROFICIENCY_DETAIL, params)
        )

        return response;
    }

    // Course Availability Service
    createCourseAvailabilityDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_AVAILABILITY_DETAIL),
            body
        )

        return response;
    }
    getCourseAvailabilityDetail = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_AVAILABILITY_DETAIL, params),
            { params: query }
        )

        return response;
    }
    updateCourseAvailabilityDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_AVAILABILITY_DETAIL, params),
            body
        )

        return response;
    }
    deleteCourseAvailabilityDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_AVAILABILITY_DETAIL, params)
        )

        return response;
    }

    // Course Price Service
    createCoursePriceDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_PRICE_DETAIL),
            body
        )

        return response;
    }
    getCoursePriceDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_PRICE_DETAIL, params)
        )

        return response;
    }
    updateCoursePriceDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_PRICE_DETAIL, params),
            body
        )

        return response;
    }
    deleteCoursePriceDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_PRICE_DETAIL, params)
        )

        return response;
    }

    // tutor course list
    getTutorCourseList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_TUTOR_COURSE_LIST, params),
            { params: query }
        )

        return response;
    }

    // tutor course detail
    getTutorCourseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_TUTOR_COURSE_DETAIL, params)
        )

        return response;
    }

    // clone course detail
    createCloneCourseDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CLONE_COURSE_DETAIL, params),
            body
        )

        return response;
    }

    // Course Tutor Service
    getCourseTutorList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_TUTOR_LIST),
            { params: query }
        )

        return response;
    }
    getCourseTutorDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_TUTOR_DETAIL, params)
        )

        return response;
    }

    // add next session detail
    createNextCourseSessionDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_NEXT_COURSE_SESSION_DETAIL, params),
            body
        )

        return response;
    }

    // get course comment list
    getCourseCommentList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_COMMENT_LIST),
            { params: query }
        )

        return response;
    }
    createCourseCommentDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_COMMENT_DETAIL),
            body
        )

        return response;
    }
}

export default new CourseService()