import { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

const FadedCollapse = ({
  isShowMore = true,
  isFaded = true,
  btnStyle = "",
  wrapperStyle = "",
  maxHeight = "7.5rem",
  children,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [children]);

  const toggleExpanded = (e) => {
    e.stopPropagation()
    setExpanded(!expanded);
  };

  const maxHeightInPixels = parseFloat(maxHeight) * 16;
  const fadedClass = isFaded && contentHeight > maxHeightInPixels ? "faded-text " : "";
  const computedHeight = contentHeight > maxHeightInPixels ? maxHeight : "auto";

  return (
    <>
      <div className={`overflow-hidden flex flex-col ${wrapperStyle}`}>
        <AnimatePresence>
          <motion.div
            ref={contentRef}
            initial={false}
            animate={{ height: expanded ? "100%" : computedHeight }}
            exit={{ opacity: 0, height: computedHeight }}
            transition={{ duration: 0.5 }}
            className={`relative overflow-hidden ${expanded ? "" : fadedClass}`}
          >
            {children}
          </motion.div>
        </AnimatePresence>
        {isShowMore && contentHeight > maxHeightInPixels && (
          <button
            className={`font-bodyPri text-primary-main hover:text-secondary-dark cursor-pointer ${btnStyle}`}
            onClick={toggleExpanded}
          >
            {expanded ? (
              <span className="flex items-center gap-0.5">
                {"Show less"}
                <BiChevronUp />
              </span>
            ) : (
              <span className="flex items-center gap-0.5">
                {"Show more"}
                <BiChevronDown />
              </span>
            )}
          </button>
        )}
      </div>
    </>
  );
};

export default FadedCollapse;