import { BsInfoCircle } from 'react-icons/bs';

import ToolTipView from 'components/tooltipView';

const LmsCourseTooltip = ({ tooltip }) => {
    return (
        <ToolTipView content={tooltip}>
            <button className={"cursor-pointer"}>
                <BsInfoCircle className="text-md text-text-900 hover:text-text-900" />
            </button>
        </ToolTipView>
    )
}

export default LmsCourseTooltip;