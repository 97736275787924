import { useState, useEffect } from "react";

import CollapsableContent from "pages/auth/onboarding/commonComponents/collapsableContent/CollapsableContent";
import CTAButtons from "pages/auth/onboarding/commonComponents/ctaButtons/CTAButtons";
import { profileDetailSections } from "pages/auth/onboarding/data";

import MyAboutStory from "pages/auth/onboarding/components/profileDetail/components/myAboutStory/MyAboutStory";
import MySkillsStyle from "pages/auth/onboarding/components/profileDetail/components/mySkillStyle/MySkillsStyle";
import MyIntroVideo from "pages/auth/onboarding/components/profileDetail/components/myIntroVideo/MyIntroVideo";
import Price from "pages/auth/onboarding/components/profileDetail/components/price";
import WeeklySchedule from "pages/auth/onboarding/components/profileDetail/components/weeklySchedule";
import MyLessons from "pages/auth/onboarding/components/profileDetail/components/myLessons/MyLessons";

import {
    getUserCultureDetailByUserId,
    getUserDetailByUserId,
    getUserEducationListByUserId
} from 'redux/user/user.request';
import {
    getTutorCategoryList,
    getTutorLanguageList,
    getTutorTagList,
    getTutorUserDetailByUserId
} from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import {
    setClearUserCultureDetail,
    setClearUserDetail,
    setAddUserDetailPayload,
    setClearUserEducationList
} from 'redux/user/user.slice';
import {
    resetTutorCategoryList,
    resetTutorLanguageList,
    resetTutorTagList,
    setAddTutorUserDetailPayload,
    setClearTutorUserDetail
} from 'redux/tutor/tutor.slice';

const ProfileDetail = () => {
    const { user, userDetail } = useAppState((state) => state.user)
    const { tutorUserDetail } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()

    const [clickedItem, setClickedItem] = useState(null)
    const [editBtnConst, setEditBtnConst] = useState(null)

    useEffect(() => {
        return () => {
            dispatcher(setClearUserDetail())
            dispatcher(setClearUserCultureDetail())
            dispatcher(setClearTutorUserDetail())
            dispatcher(setClearUserEducationList())
            dispatcher(resetTutorLanguageList())
            dispatcher(resetTutorTagList())
            dispatcher(resetTutorCategoryList())
        }
    }, [])

    useEffect(() => {
        dispatcher(getUserDetailByUserId(user?.user?.userId))
        dispatcher(getUserCultureDetailByUserId(user?.user?.userId))
        dispatcher(getTutorUserDetailByUserId(user?.user?.userId))
        dispatcher(getUserEducationListByUserId(user?.user?.userId))

        dispatcher(getTutorLanguageList({ tutorId: user?.user?.tutor?.tutorId }))
        dispatcher(getTutorTagList({ tutorId: user?.user?.tutor?.tutorId }))
        dispatcher(getTutorCategoryList({ tutorId: user?.user?.tutor?.tutorId }))

    }, [])

    useEffect(() => {
        if (userDetail?.userDetail) {
            dispatcher(setAddUserDetailPayload(userDetail?.userDetail))
        }
    }, [userDetail?.userDetail])

    useEffect(() => {
        if (tutorUserDetail?.tutorUserDetail) {
            dispatcher(setAddTutorUserDetailPayload(tutorUserDetail?.tutorUserDetail))
        }
    }, [tutorUserDetail?.tutorUserDetail])

    const onHandleToggleContainer = (value) => {
        if (editBtnConst) {
            if (!window.confirm(`You have unsaved changes in ${editBtnConst?.split(/(?=[A-Z])/)?.join(" ")?.toLowerCase()}, Would you like to discard it?`)) return;
        }
        if (clickedItem === value) {
            setClickedItem(null)
        } else {
            setClickedItem(value)
        }
        setEditBtnConst(null)
    }

    return (
        <div className={"space-y-5"}>
            <CollapsableContent
                isLoading={false}
                isOpen={clickedItem === profileDetailSections?.MY_STORY?.value}
                value={profileDetailSections?.MY_STORY?.value}
                title={"My Story: About Me"}
                onHandleToggle={onHandleToggleContainer}
                children={<MyAboutStory editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />}
            />
            <CollapsableContent
                isOpen={clickedItem === profileDetailSections?.MY_SKILLS_STYLE?.value}
                value={profileDetailSections?.MY_SKILLS_STYLE?.value}
                title={"Style, Skills and Mastery"}
                onHandleToggle={onHandleToggleContainer}
                children={<MySkillsStyle editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />}
            />
            <CollapsableContent
                isOpen={clickedItem === profileDetailSections?.INTRO_VIDEO?.value}
                value={profileDetailSections?.INTRO_VIDEO?.value}
                title={"My Introduction video for public"}
                onHandleToggle={onHandleToggleContainer}
                children={<MyIntroVideo />}
            />
            <CollapsableContent
                isOpen={clickedItem === profileDetailSections?.LESSONS?.value}
                value={profileDetailSections?.LESSONS?.value}
                title={"My Lesson(s)"}
                onHandleToggle={onHandleToggleContainer}
                children={<MyLessons />}
            />
            <CollapsableContent
                isOpen={clickedItem === profileDetailSections?.PRICE?.value}
                value={profileDetailSections?.PRICE?.value}
                title={"Set My Price"}
                onHandleToggle={onHandleToggleContainer}
                children={<Price editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />}
            />
            <CollapsableContent
                isOpen={clickedItem === profileDetailSections?.WEEKLY_SCHEDULE?.value}
                value={profileDetailSections?.WEEKLY_SCHEDULE?.value}
                title={"Weekly Schedule for One-on-One Private Lessons"}
                onHandleToggle={onHandleToggleContainer}
                children={<WeeklySchedule editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />}
            />
        </div>
    )
}

export default ProfileDetail