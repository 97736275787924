import CourseMuxVideoPlayer from "components/courseMuxVideoPlayer/CourseMuxVideoPlayer";

import { useAppDispatcher, useAppState } from "hooks/useStore";

import { MUX_DEMO_PLAYBACK_ID } from 'const/default.const';

const VideoSelector = () => {
    const { coursePreviewDetail } = useAppState((s) => s.course)

    const dispatcher = useAppDispatcher()

    return (
        <div className={"w-full h-full flex items-center justify-center rounded-t-lg"}>
            <CourseMuxVideoPlayer
                playbackId={coursePreviewDetail?.data?.introVideoUrl ? coursePreviewDetail?.data?.introVideoUrl : MUX_DEMO_PLAYBACK_ID}
                autoPlay={false}
                withLabel={false}
                isShowButtons={false}
                isShowRefreshButton={false}
                muted={true}
            />
        </div>
    );
};

export default VideoSelector;