import { useState, useCallback } from 'react';

import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

import { AnimatePresence, motion } from 'framer-motion';

import { availabilityDetailBtnConst } from "pages/auth/preferences/data";
import { InputTextAreaWithLabel, InputTextWithEndAdornment } from "pages/auth/preferences/commonComponents/InputTextWithLabel";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModifyUserAvailabilityDetailPayload } from 'redux/availability/availability.slice';

const MoreAvailabilityDetail = () => {
    const { modifyUserAvailabilityDetail } = useAppState((s) => s.availability)

    const dispatcher = useAppDispatcher()

    const [isExpandDetailEnabled, setIsExpandDetailEnabled] = useState(false)

    const onHandleChangeText = useCallback((key, value) => {
        if (modifyUserAvailabilityDetail?.isLoading) return;

        dispatcher(setModifyUserAvailabilityDetailPayload({
            ...modifyUserAvailabilityDetail?.payload,
            [key]: value
        }))
    }, [modifyUserAvailabilityDetail])

    return (
        <div className={"w-full space-y-10"}>
            <AnimatePresence>
                {isExpandDetailEnabled &&
                    <motion.div
                        className={"space-y-10"}
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        {/* description */}
                        <InputTextAreaWithLabel
                            inputLabel={availabilityDetailBtnConst.description.label}
                            id={availabilityDetailBtnConst.description.key}
                            inputLabelContent={availabilityDetailBtnConst.description.key}
                            placeholder={"Enter description here ..."}
                            inputName={availabilityDetailBtnConst.description.key}
                            value={modifyUserAvailabilityDetail?.payload?.description}
                            onChange={(event) => onHandleChangeText(availabilityDetailBtnConst.description.key, event?.target?.value)}
                            required={true}
                        />

                        {/* minimum notice period */}
                        <div className={"w-full flex flex-col gap-5 md:flex-row md:items-center md:justify-between md:gap-10"}>
                            <InputTextWithEndAdornment
                                containerClassName={"flex-1"}
                                wrapperClassName={"flex-1"}
                                inputLabel={availabilityDetailBtnConst.minTimeBeforeAvailStart.label}
                                id={availabilityDetailBtnConst.minTimeBeforeAvailStart.key}
                                inputLabelContent={availabilityDetailBtnConst.minTimeBeforeAvailStart.key}
                                placeholder={"Enter hour(s) ..."}
                                inputName={availabilityDetailBtnConst.minTimeBeforeAvailStart.key}
                                value={(modifyUserAvailabilityDetail?.payload?.minTimeBeforeAvailStart / 60)?.toString()}
                                onChange={(event) => onHandleChangeText(availabilityDetailBtnConst.minTimeBeforeAvailStart.key, Number(event?.target?.value) * 60)}
                                required={true}
                                endAdornment={
                                    <span className={"flex justify-end font-medium text-gray-900"}>
                                        {"Hour(s)"}
                                    </span>}
                            />

                            {/* Maximum time in advance */}
                            <InputTextWithEndAdornment
                                containerClassName={"flex-1"}
                                wrapperClassName={"flex-1"}
                                inputLabel={availabilityDetailBtnConst.advanceSlotPeriodDays.label}
                                id={availabilityDetailBtnConst.advanceSlotPeriodDays.key}
                                inputLabelContent={availabilityDetailBtnConst.advanceSlotPeriodDays.key}
                                placeholder={"Enter day(s) ..."}
                                inputName={availabilityDetailBtnConst.advanceSlotPeriodDays.key}
                                value={modifyUserAvailabilityDetail?.payload?.advanceSlotPeriodDays?.toString()}
                                onChange={(event) => onHandleChangeText(availabilityDetailBtnConst.advanceSlotPeriodDays.key, Number(event?.target?.value))}
                                required={true}
                                endAdornment={
                                    <span className={"flex justify-end font-medium text-gray-900"}>
                                        {"Day(s)"}
                                    </span>}
                            />
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.3 }}
                className={"flex items-center justify-start gap-1.5 cursor-pointer group"}
                onClick={() => setIsExpandDetailEnabled(!isExpandDetailEnabled)}
            >
                <span className={"font-bodyPri font-medium text-base text-primary-dark group-hover:text-primary-dark tracking-wide"}>
                    {isExpandDetailEnabled
                        ? "See Less"
                        : "See More"
                    }
                </span>
                {isExpandDetailEnabled
                    ? <ArrowUpward className={"text-sm text-primary-dark group-hover:text-primary-dark"} />
                    : <ArrowDownward className={"text-sm text-primary-dark group-hover:text-primary-dark"} />
                }
            </motion.button>
        </div>
    )
}

export default MoreAvailabilityDetail