import { useMemo, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AnimatePresence, motion } from "framer-motion";

import AppointmentCalendarInfo from 'pages/auth/editAppointment/calendar';
import { appointmentTabConst, searchParamsInfo } from 'pages/auth/editAppointment/data';
import SideBarHeader from 'pages/auth/editAppointment/commonComponents/SideBarHeader';
import ViewAppointmentDetail from 'pages/auth/editAppointment/detail/view/ViewAppointmentDetail';
import ViewPriceDetail from 'pages/auth/editAppointment/detail/view/ViewPriceDetail';
import SessionDetail from 'pages/auth/editAppointment/detail/view/SessionDetail';
import EditBasicDetail from 'pages/auth/editAppointment/detail/edit/basicDetail';
import EditPriceDetail from 'pages/auth/editAppointment/detail/edit/priceDetail';
import AppointmentSessionList from 'pages/auth/editAppointment/detail/edit/sessions';

import { cn } from 'utils/cn.utils';

const SectionView = () => {

    const navigate = useNavigate()
    const location = useLocation()

    const [activeTab, setActiveTab] = useState(appointmentTabConst.EDIT.value)

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    const onHandleActiveSection = useCallback((key, value) => {
        searchQueryParams.set(key, value)
        navigate(`${location.pathname}?${searchQueryParams.toString()}`)
    }, [location.search])

    const onHandleCloseSection = useCallback(() => {
        navigate(-1)
    }, [location.search])

    const SectionViewContent = () => {
        return (
            <div className={cn(
                "w-full flex-grow h-[calc(100vh-13rem)] md:h-[calc(100vh-10rem)] overflow-y-auto",
                "scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-light",
            )}
            >
                <ViewAppointmentDetail onHandleActiveSection={onHandleActiveSection} />
                <div className={"mx-5 self-center border-b border-text-300"} />
                <ViewPriceDetail onHandleActiveSection={onHandleActiveSection} />
                <div className={"mx-5 self-center border-b border-text-300"} />
                <SessionDetail onHandleActiveSection={onHandleActiveSection} />
                <div className={"mx-5 self-center border-b border-text-300"} />
            </div>
        )
    }

    return (
        <AnimatePresence initial={false}>
            {searchQueryParams.get(searchParamsInfo.action.key) && (
                <motion.div
                    key={"activeSection"}
                    initial={{ x: '-100%' }}
                    animate={{ x: 0 }}
                    exit={{ x: '-100%' }}
                    transition={{ duration: 0.3 }}
                    className={'w-full h-full absolute top-0 bg-white z-50'}
                >
                    {(searchQueryParams.get(searchParamsInfo.action.key) === searchParamsInfo.appointment.key) &&
                        <EditBasicDetail onHandleCloseSection={onHandleCloseSection} />
                    }
                    {(searchQueryParams.get(searchParamsInfo.action.key) === searchParamsInfo.price.key) &&
                        <EditPriceDetail onHandleCloseSection={onHandleCloseSection} />
                    }
                    {(searchQueryParams.get(searchParamsInfo.action.key) === searchParamsInfo.sessions.key) &&
                        <AppointmentSessionList onHandleCloseSection={onHandleCloseSection} />
                    }
                </motion.div>
            )}
            <aside className={"w-full h-full flex flex-col bg-white shadow-all"}>
                <SideBarHeader activeTab={activeTab} setActiveTab={setActiveTab} />
                <div className={"hidden md:flex"}>
                    <SectionViewContent />
                </div>
                {(!activeTab || [appointmentTabConst.EDIT.value]?.includes(activeTab)) &&
                    <div className={"flex md:hidden"}>
                        <SectionViewContent />
                    </div>
                }
                {[appointmentTabConst.PREVIEW.value]?.includes(activeTab) &&
                    <div className={cn("flex md:hidden items-center justify-center bg-white py-10 px-5")}>
                        <AppointmentCalendarInfo />
                    </div>
                }
            </aside>
        </AnimatePresence>
    )
}

export default SectionView;