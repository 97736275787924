import { useState, useEffect } from "react";

import { cn } from "utils/cn.utils";

import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";

import ButtonLoader from "components/loader/ButtonLoader";

import PasswordValidator from "components/passwordValidator/PasswordValidator";

import { updateUserPasswordByUserId } from "redux/auth/auth.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearAddUserPassword } from "redux/auth/auth.slice";
import { setModal } from "redux/local/local.slice";
import { setClearUserGeneralDetailPayload } from "redux/user/user.slice";
import { modalConst } from "redux/local/local.const";

const ChangePassword = () => {
    const { modal } = useAppState((state) => state.local)
    const { addUserPassword } = useAppState((state) => state.auth)
    const { user } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()

    const [password, setPassword] = useState({
        newPassword: "",
        confirmPassword: ""
    })
    const [isShowPassword, setIsShowPassword] = useState(false)
    const [isNewPasswordValid, setIsNewPasswordValid] = useState(false)
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false)

    useEffect(() => {
        if (addUserPassword?.addUserPassword) {
            dispatcher(setModal({
                ...modal,
                [modalConst.UPDATE_PASSWORD_MODAL.stateKey]: false
            }))
        }

        return () => {
            dispatcher(setClearAddUserPassword())
            dispatcher(setClearUserGeneralDetailPayload())
        }
    }, [addUserPassword?.addUserPassword])

    useEffect(() => {
        setIsConfirmPasswordValid((isNewPasswordValid && (password?.confirmPassword === password?.newPassword)))
    }, [password])

    const onHandleSubmit = () => {
        if (!password?.newPassword || !isNewPasswordValid || !password?.confirmPassword || !isConfirmPasswordValid) {
            return;
        }
        if ((password.newPassword && password.confirmPassword) && (password.newPassword !== password.confirmPassword)) return;

        dispatcher(updateUserPasswordByUserId(
            user?.user?.userId,
            {
                password: password?.confirmPassword
            }
        ))
    }

    return (
        <div className={"space-y-5 overflow-hidden"}>
            <div className={"w-full min-h-[24rem] flex flex-col justify-start items-start gap-8"}>
                <div className={"w-full flex flex-col items-start justify-start gap-5"}>

                    <PasswordValidator
                        inputPassword={password?.newPassword}
                        setIsNewPasswordValid={setIsNewPasswordValid}
                    />
                    {/* new password container */}
                    <div className={"w-full flex flex-col items-start justify-start gap-1"}>
                        <div className={"flex justify-start items-center gap-1.5"}>
                            <div className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                {"New Password"}
                            </div>
                        </div>
                        <div className={cn(
                            "w-full flex items-center justify-between gap-1",
                            "px-3 py-1 font-bodyPri font-normal text-text-800 text-base rounded-md",
                            "border-2 border-text-400 focus:outline-none focus:border-text-400",
                            password?.newPassword && "!border-text-400",
                            (password?.newPassword && isNewPasswordValid) && "!border-red-500",
                            (password?.newPassword && isNewPasswordValid) && "!border-green-500"
                        )}>
                            <input
                                type={isShowPassword ? "text" : "password"}
                                id={"newPassword"}
                                className={"w-full focus:outline-none"}
                                value={password.newPassword}
                                onChange={(event) => setPassword({
                                    ...password,
                                    newPassword: event.target.value
                                })}
                                autoFocus={true}
                            />
                        </div>
                    </div>

                    {/* confirm password container */}
                    <div className={"w-full flex flex-col items-start justify-start gap-1"}>
                        <div className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                            {"Confirm Password"}
                        </div>
                        <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                            <div className={cn(
                                "w-full flex items-center justify-between gap-1",
                                "px-3 py-1 font-bodyPri font-normal text-text-800 text-base rounded-md",
                                "border-2 border-text-400 focus:outline-none focus:border-text-400",
                                password?.confirmPassword && "!border-text-400",
                                (password?.confirmPassword && !isConfirmPasswordValid) && "!border-red-500",
                                (password?.confirmPassword && isConfirmPasswordValid) && "!border-green-500"
                            )}>
                                <input
                                    type={isShowPassword ? "text" : "password"}
                                    className={"w-full focus:outline-none"}
                                    value={password.confirmPassword}
                                    onChange={(event) => setPassword({
                                        ...password,
                                        confirmPassword: event.target.value
                                    })}
                                />
                            </div>
                            {((password.newPassword && password.confirmPassword) && (password.newPassword !== password.confirmPassword)) &&
                                <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                    {"The passwords you entered do not match"}
                                </span>
                            }
                        </div>
                    </div>
                    <div className={"w-fit flex items-center justify-start gap-2 cursor-pointer"} onClick={() => setIsShowPassword(!isShowPassword)}>
                        {isShowPassword && <ImCheckboxChecked id={"isShowPassword"} className={"text-primary-main"} />}
                        {!isShowPassword && <ImCheckboxUnchecked id={"isShowPassword"} className={"text-text-400"} />}
                        <label for={"isShowPassword"} className={"font-bodyPri font-normal text-base text-text-700"}>
                            {"Show Password"}
                        </label>
                    </div>
                </div>
                {addUserPassword?.errorMsg &&
                    <div className={"flex items-center justify-center font-bodyPri font-normal text-red-500 text-sm"}>
                        {addUserPassword?.errorMsg}
                    </div>
                }
                <div onClick={onHandleSubmit} className={cn(
                    "w-full py-1.5 flex item-center justify-center rounded-md cursor-pointer",
                    "font-buttons font-normal text-base text-text-50",
                    "bg-primary-main hover:bg-primary-dark"
                )}>
                    {addUserPassword?.isLoading &&
                        <ButtonLoader isLoading={addUserPassword?.isLoading} />
                    }
                    {!addUserPassword?.isLoading && "Submit"}
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;