import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { ImCross } from "react-icons/im";

// Components
import MultipleSelectSearchDropdown from "pages/global/findTutors/components/tutorPublicFilterDropdowns/MultipleSelectSearchDropdown";
import TutorSortByDropdown from "pages/global/findTutors/components/tutorPublicFilterDropdowns/TutorSortByDropdown";

import { dropDownButtonConst, getQueryString } from "pages/global/findTutors/data";

import { useAppState } from "hooks/useStore";
import { DEFAULT_TUTOR_PAGE, DEFAULT_TUTOR_RECORDS, tutorSortBy } from 'redux/tutor/tutor.const';

import { pagesInfo } from "utils/pagesInfo";

const Dropdowns = ({ dropDownButton, setDropDownButton }) => {
    const { languageList, profList } = useAppState((s) => s.master)
    const { filterProps } = useAppState((state) => state.tutor)

    const navigate = useNavigate()

    const [searchText, setSearchText] = useState(filterProps?.search)


    const [isSpeaksHoverEnabled, setIsSpeaksHoverEnabled] = useState(false)
    const [isStudentLevelHoverEnabled, setIsStudentLevelHoverEnabled] = useState(false)

    useEffect(() => {
        if (filterProps?.search) {
            setSearchText(filterProps?.search)
        }
    }, [filterProps?.search])

    const onHandleDropdown = (dropdownValue) => {
        if (!dropDownButton) {
            setDropDownButton(dropdownValue)
        }
        if (dropDownButton === dropdownValue) {
            setDropDownButton(null)
        }
        if (dropDownButton !== dropdownValue) {
            setDropDownButton(null)
            setDropDownButton(dropdownValue)
        }
    }

    const onHandleKeyDownSearch = (event) => {
        if ((event?.keyCode === 13) && searchText) {
            navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}?&pn=${DEFAULT_TUTOR_PAGE}&search=${searchText?.replaceAll(" ", "-")?.toString()?.toLowerCase()}`)
        }
    }

    return (
        <div className="relative">
            <div className="grid grid-cols-12">
                <div className={"col-span-12 lg:col-span-6"}>
                    <div className="flex flex-wrap flex-row justify-center items-center lg:justify-start gap-2">
                        <div className={"relative"}>
                            <div
                                className={cn(
                                    "px-3 py-1.5 flex items-center justify-center gap-3 rounded-xl cursor-pointer bg-white border border-text-400 hover:border-text-900 hover:shadow-all",
                                    filterProps?.language?.length > 0 && "pl-3 pr-8 bg-primary-dark"
                                )}
                                onClick={() => onHandleDropdown(dropDownButtonConst?.SPEAKS?.value)}
                            >
                                <span className={cn(
                                    "font-bodyPri font-normal text-text-900 text-base",
                                    filterProps?.language?.length > 0 && "text-text-50"
                                )}
                                >
                                    {"Speaks"}
                                </span>
                            </div>
                            {filterProps?.language?.length > 0 &&
                                <div
                                    className={"absolute top-2 right-2 z-20 w-5 h-5 flex items-center justify-start rounded-full bg-white"}
                                    onMouseOver={() => setIsSpeaksHoverEnabled(true)}
                                    onMouseOut={() => setIsSpeaksHoverEnabled(false)}
                                >
                                    {!isSpeaksHoverEnabled &&
                                        <span className={"w-full text-sm text-primary-dark text-center p-0.5"}>
                                            {filterProps?.language?.length}
                                        </span>
                                    }
                                    {isSpeaksHoverEnabled &&
                                        <ImCross
                                            className={"w-full text-sm text-primary-dark text-center p-0.5 cursor-pointer"}
                                            onClick={() => {
                                                const query = getQueryString({ ...filterProps, language: [], page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                                navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                                                setDropDownButton(dropDownButton !== dropDownButtonConst?.SPEAKS?.value)
                                            }}
                                        />
                                    }
                                </div>
                            }
                        </div>
                        {(dropDownButton === dropDownButtonConst?.SPEAKS?.value) &&
                            <MultipleSelectSearchDropdown
                                options={languageList?.languageList?.map((language) => ({
                                    label: language?.language,
                                    value: language?.language
                                }))}
                                selectedOption={filterProps?.language?.length > 0 ? filterProps?.language?.map((items) => ({
                                    label: items,
                                    value: items
                                })) : []}
                                isLoading={languageList?.isLoading}
                                isShowList={dropDownButton === dropDownButtonConst?.SPEAKS?.value}
                                setIsShowList={setDropDownButton}
                                className={"top-72 mt-5 left-20 w-fit md:w-72"}
                                multipleOptions={true}
                                isShowInputBox={true}
                                isShowCheckBox={true}
                                onApplyFilter={(option) => {
                                    const query = getQueryString({ ...filterProps, language: option?.map((opt) => opt?.value)?.toString(), page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                    navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                                }}
                                onClearFilter={() => {
                                    setDropDownButton(null)
                                    if (filterProps?.language?.length > 0) {
                                        const query = getQueryString({ ...filterProps, language: [], page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                        navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                                    }
                                }}
                            />
                        }
                        <div className={"relative"}>
                            <div
                                className={cn(
                                    "px-3 py-1.5 flex items-center justify-center gap-3 rounded-xl cursor-pointer bg-white border border-text-400 hover:border-text-900 hover:shadow-all",
                                    filterProps?.proficiency?.length > 0 && "pl-3 pr-8 bg-primary-dark"
                                )}
                                onClick={() => onHandleDropdown(dropDownButtonConst?.STUDENT_LEVEL?.value)}
                            >
                                <span className={cn(
                                    "font-bodyPri font-normal text-text-900 text-base",
                                    filterProps?.proficiency?.length > 0 && "text-text-50"
                                )}>
                                    {"Student Level"}
                                </span>
                            </div>
                            {filterProps?.proficiency?.length > 0 &&
                                <div
                                    className={"absolute top-2 right-2 z-20 w-5 h-5 flex items-center justify-start rounded-full bg-white"}
                                    onMouseOver={() => setIsStudentLevelHoverEnabled(true)}
                                    onMouseOut={() => setIsStudentLevelHoverEnabled(false)}
                                >
                                    {!isStudentLevelHoverEnabled &&
                                        <span className={"w-full text-sm text-primary-dark text-center p-0.5"}>
                                            {filterProps?.proficiency?.length}
                                        </span>
                                    }
                                    {isStudentLevelHoverEnabled &&
                                        <ImCross
                                            className={"w-full text-sm text-primary-dark text-center p-0.5 cursor-pointer"}
                                            onClick={() => {
                                                setDropDownButton(null)
                                                const query = getQueryString({ ...filterProps, proficiency: [], page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                                navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                                            }}
                                        />
                                    }
                                </div>
                            }
                        </div>
                        {(dropDownButton === dropDownButtonConst?.STUDENT_LEVEL?.value) &&
                            <div className={"absolute top-12 left-48 z-30 shadow-md w-fit md:w-72"}>
                                <MultipleSelectSearchDropdown
                                    options={profList?.profList?.map((proficiency) => ({
                                        label: proficiency?.proficiency,
                                        value: proficiency?.proficiency
                                    }))}
                                    selectedOption={filterProps?.proficiency?.length > 0 ? filterProps?.proficiency?.map((proficiency) => ({
                                        label: proficiency,
                                        value: proficiency
                                    })) : []}
                                    isLoading={profList?.isLoading}
                                    isShowList={dropDownButton === dropDownButtonConst?.STUDENT_LEVEL?.value}
                                    setIsShowList={setDropDownButton}
                                    className={"top-72 mt-5 left-40 w-fit md:w-72"}
                                    multipleOptions={true}
                                    isShowInputBox={true}
                                    isShowCheckBox={true}
                                    onApplyFilter={(option) => {
                                        setDropDownButton(null)
                                        const query = getQueryString({ ...filterProps, proficiency: option?.map((opt) => opt?.value)?.toString(), page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                        navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                                    }}
                                    onClearFilter={() => {
                                        setDropDownButton(null)
                                        if (filterProps?.proficiency?.length > 0) {
                                            const query = getQueryString({ ...filterProps, proficiency: [], page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                            navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>

                <div className={"relative col-span-12 lg:col-span-6 lg:mt-0 flex flex-row items-center justify-center lg:justify-end gap-5"}>
                    <div
                        className={"px-3 py-1.5 flex items-center justify-center gap-3 rounded-xl cursor-pointer bg-white border border-text-400 hover:border-text-900 hover:shadow-all"}
                        onClick={() => onHandleDropdown(dropDownButtonConst?.SORT_BY?.value)}
                    >
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {"Sort By" + ": " + (tutorSortBy[filterProps?.sortBy?.toUpperCase()]?.label || tutorSortBy?.RELEVANCE?.value)}
                        </span>
                    </div>
                    {(dropDownButton === dropDownButtonConst?.SORT_BY?.value) &&
                        <TutorSortByDropdown
                            options={Object.values(tutorSortBy)}
                            selected={{ label: filterProps?.sortBy, value: filterProps?.sortBy } || tutorSortBy?.RELEVANCE}
                            isShowList={dropDownButton === dropDownButtonConst?.SORT_BY?.value}
                            setIsShowList={setDropDownButton}
                            isShowCheckIcon={true}
                            className={"top-72 mt-5 right-[24rem] w-fit md:w-64"}
                            onApplyFilter={(option) => {
                                setDropDownButton(null)
                                const query = getQueryString({ ...filterProps, sortBy: option?.value, page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                            }}
                            onClearFilter={() => {
                                setDropDownButton(null)
                                const query = getQueryString({ ...filterProps, sortBy: tutorSortBy?.RELEVANCE?.value, page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                            }}
                        />
                    }

                    <div className="flex flex-row items-center bg-white rounded-xl border border-text-400 hover:border-text-900 hover:shadow-all px-3">
                        <input
                            type={"text"}
                            placeholder={"Search by keyword"}
                            className={"w-full px-3 py-1.5 font-bodyPri font-normal text-text-900 text-base focus:outline-none"}
                            value={searchText}
                            onChange={(event) => setSearchText(event?.target?.value)}
                            onKeyDown={onHandleKeyDownSearch}
                        />
                        <div className={"font-bodyPri font-normal text-text-900 text-sm border border-text-500 px-3 py-0.5 rounded-xl hover:border-secondary-dark hover:bg-secondary-dark hover:text-text-50 cursor-pointer"}
                            onClick={() => {
                                if (searchText) {
                                    navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}?&pn=${DEFAULT_TUTOR_PAGE}&search=${searchText?.replaceAll(" ", "-")?.toString()?.toLowerCase()}`)
                                }
                            }}
                        >
                            {'Search'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dropdowns;