import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { cn } from "utils/cn.utils";

import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";

import ToolTipView from "components/tooltipView";
import { Toggle } from "components/common-components/Toggle";

import Drag from "pages/auth/edulyteLms/assets/drag.svg";

import { deleteLmsCourseSectionLessonDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';
import { updateLmsLectureDetail } from "redux/edulyteLms/lmsLecture/lmsLecture.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { lmsCourseSectionLessonTypeEnum } from "redux/edulyteLms/lmsCourse/lmsCourse.const";
import { lmsLectureVisibilityEnum } from "redux/edulyteLms/lmsLecture/lmsLecture.const";

import { pagesInfo } from "utils/pagesInfo";

const LessonLectureContainer = memo(({ courseSectionLessonItem }) => {
    const { deleteLmsCourseSectionLesson } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const onHandleUpdateLectureVisibility = useCallback(() => {
        const lessonLecturePayload = {
            sectionId: courseSectionLessonItem?.lms_course_section_id,
            sectionLessonId: courseSectionLessonItem?.id,
            isShowToast: true
        }
        const lectureVisibility = (courseSectionLessonItem?.lecture?.visibility === lmsLectureVisibilityEnum?.PUBLIC?.value)
            ? lmsLectureVisibilityEnum?.PRIVATE?.value
            : lmsLectureVisibilityEnum?.PUBLIC?.value
        dispatcher(updateLmsLectureDetail(courseSectionLessonItem?.lecture?.id, { visibility: lectureVisibility }, lessonLecturePayload))

    }, [courseSectionLessonItem])

    const onHandleEditLecture = useCallback(() => {
        navigate(`${pagesInfo.EDIT_LMS_LECTURE.pagePath}/${courseSectionLessonItem?.lecture?.id}/edit`)
    }, [courseSectionLessonItem])

    const onHandleDeleteLecture = useCallback(() => {
        if (deleteLmsCourseSectionLesson?.isLoading) return;

        dispatcher(deleteLmsCourseSectionLessonDetail(courseSectionLessonItem?.id))
    }, [courseSectionLessonItem, deleteLmsCourseSectionLesson?.isLoading])

    return (
        <div className={cn(
            'flex flex-col md:flex-row items-end md:items-start justify-between gap-2 px-5 py-2 border bg-white rounded-lg',
            'border-divider-darkLight hover:border-divider-lightDark'
        )}>
            <div className={cn('w-full flex items-start gap-5')}>
                <img
                    src={Drag}
                    className={"mt-1.5 w-2.5 cursor-grab"}
                />
                {courseSectionLessonItem?.type &&
                    <span className={"inline-flex font-bodyPri font-medium text-text-900 text-md sm:text-lg line-clamp-2"}>
                        {`${lmsCourseSectionLessonTypeEnum[courseSectionLessonItem?.type?.toUpperCase()]?.label}: `}
                        <span className={'font-bodyPri font-medium text-text-900 text-md sm:text-lg'}>
                            {courseSectionLessonItem?.lecture?.title}
                        </span>
                    </span>
                }
            </div>
            <div className={'flex justify-end items-center gap-1.5'}>
                <ToolTipView
                    content={[lmsLectureVisibilityEnum?.PUBLIC?.value]?.includes(courseSectionLessonItem?.lecture?.visibility)
                        ? "Disable Previews"
                        : "Enable Previews"
                    }
                >
                    <button>
                        <Toggle
                            value={[lmsLectureVisibilityEnum?.PUBLIC?.value]?.includes(courseSectionLessonItem?.lecture?.visibility) ? true : false}
                            onChange={onHandleUpdateLectureVisibility}
                        />
                    </button>
                </ToolTipView>
                <ToolTipView content={'Edit Lecture'}>
                    <button className={"p-2 rounded-full hover:bg-divider-darkLight group"} onClick={onHandleEditLecture}>
                        <MdEdit className={"text-xl text-text-700 group-hover:text-primary-dark cursor-pointer"} />
                    </button>
                </ToolTipView>
                <ToolTipView content={"Remove Lecture"}>
                    <button className={"p-2 rounded-full hover:bg-divider-darkLight group"} onClick={onHandleDeleteLecture}>
                        <RiDeleteBin6Line className={"text-xl text-text-700 group-hover:text-red-500 cursor-pointer"} />
                    </button>
                </ToolTipView>
            </div>
        </div>
    )
});

export default LessonLectureContainer;