export const pageHeading = {
    heading: "My Downloads",
    subHeading: "Find the list of downloads content here.",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/my-courses.svg"
}

export const searchParamsInfo = {
    download_id: {
        key: "download_id"
    }
}