import { createSlice } from "@reduxjs/toolkit";

import { GPT_INITIAL_STATE } from "./gpt.initialState";

const gpt = createSlice({
    name: "gpt",
    initialState: GPT_INITIAL_STATE,
    reducers: {
        // gpt token txn list
        setGptTokenTxnListLoading: (state, { payload }) => {
            state.gptTokenTxnList.isLoading = payload
        },
        setGptTokenTxnListData: (state, { payload }) => {
            state.gptTokenTxnList.data = payload
            state.gptTokenTxnList.message = GPT_INITIAL_STATE.gptTokenTxnList.message
        },
        setGptTokenTxnListMessage: (state, { payload }) => {
            state.gptTokenTxnList.message = payload
            state.gptTokenTxnList.data = GPT_INITIAL_STATE.gptTokenTxnList.data
        },
        resetGptTokenTxnList: (state) => {
            state.gptTokenTxnList = GPT_INITIAL_STATE.gptTokenTxnList
        },
    }
})

export const {
    setGptTokenTxnListLoading,
    setGptTokenTxnListData,
    setGptTokenTxnListMessage,
    resetGptTokenTxnList,
} = gpt.actions

export default gpt.reducer;