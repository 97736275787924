import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { pageHeading } from 'pages/auth/createTemplate/data';

import FullPageLoader from 'components/loader/FullPageLoader';
import PageHeader from 'components/pageHeader/PageHeader';
import ProgressIndicator from 'pages/auth/createTemplate/ProgressIndicator';
import SelectTemplate from 'pages/auth/createTemplate/SelectTemplate';

import { getCourseTemplateList } from 'redux/course/course.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearCourseTemplateList } from 'redux/course/course.slice';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { pagesInfo } from 'utils/pagesInfo';

import { useTitle } from 'hooks/useTitle';
import { generateQueryParams } from 'utils/generators.utils';

const TemplatePage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { courseTemplateList } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.TEMPLATE))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Create Your Own Class | Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        const query = generateQueryParams(location.search)
        let requestDataPayload = {}
        if (query && query?.classType) {
            requestDataPayload = { type: query?.classType }
        }
        dispatcher(getCourseTemplateList(requestDataPayload))

        return () => {
            dispatcher(setClearCourseTemplateList())
        }
    }, [location.search])

    if (courseTemplateList?.isLoading) {
        return (
            <FullPageLoader isLoading={courseTemplateList?.isLoading} />
        )
    }

    return (
        <div className="h-full w-full p-3 mx-auto min-h-screen space-y-3">
            <PageHeader pageHeading={pageHeading} />
            <ProgressIndicator />
            {courseTemplateList?.courseTemplateList &&
                <div className="grid grid-cols-12">
                    <div className="col-span-full">
                        <SelectTemplate />
                    </div>
                </div>
            }
            {courseTemplateList?.errorMsg &&
                <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                    {courseTemplateList?.errorMsg}
                </div>
            }
        </div>
    )
}

export default TemplatePage
