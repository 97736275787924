import PageHeader from 'components/pageHeader/PageHeader'
import { pageHeading, getVideoData } from '../../../components/modals/video/data'
import { useAppDispatcher, useAppState } from 'hooks/useStore'
import { pagesInfo } from 'utils/pagesInfo'
import { setPageInfo } from 'redux/pageInfo/pageInfo.request'
import { useEffect } from 'react'
import { useTitle } from 'hooks/useTitle'

const VideoGuide = () => {
    const { locals } = useAppState(s => s.local)
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const dispatcher = useAppDispatcher()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.VIDEO_GUIDE))
    }, [useAppDispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Edulyte Guide Video Library"
        })
    }, [dispatcher, currentPageInfo])

    return (
        <div className={"w-full p-3 mx-auto space-y-3"}>
            <PageHeader pageHeading={pageHeading} />

            <div className='grid grid-cols-2 gap-5'>
                {Object.values(getVideoData(pagesInfo, locals?.userRole))?.map((item, index) => item?.roles?.includes(locals?.userRole) && (
                    <div
                        key={index}
                        className='flex flex-col items-center text-center gap-5 bg-white p-5 rounded-lg'
                    >
                        <h2 className='font-medium text-lg text-primary-dark'>{item?.title}</h2>
                        <iframe
                            src={`https://www.youtube.com/embed/${item?.videoId}?rel=0`}
                            title={"YouTube video player"}
                            className={"w-full aspect-video object-cover"}
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        >
                        </iframe>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default VideoGuide
