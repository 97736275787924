import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { BellIcon } from "@heroicons/react/outline";

import SideOverPanel from "components/common-components/SideOverPanel";
import InfinitePagination from "components/pagination/InfinitePagination";

import {  StyledNotificationBellContainer,  StyledNotificationCount,} from "../../pages/auth/message/components/NotificationBar/NotificationBar.styles";
import NotificationCard from "./NotificationCard";

import notificationService from "redux/notification/notification.service";
import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { useAppState } from "hooks/useStore";
import { EDULYTE_API_DOMAIN_URL } from 'const/default.const';

const notificationInitialState = {
  isLoading: false,
  data: null,
  message: null
}
const DEFAULT_PAGE_RECORDS = 15
const DEFAULT_PAGE_COUNT = 1

const notificationFilter = {
  ALL: {
    label: 'All',
    value: 'all'
  },
  UNREAD: {
    label: "Unread",
    value: 'unread'
  }
}

const ShowMoreAlertsSidePanel = () => {

  const [notificationList, setNotificationList] = useState(notificationInitialState);
  const [activeFilter, setActiveFilter] = useState('all');

  const getNotificationList = async (requestDataPayload) => {
    setNotificationList((prev) => ({
      ...prev, isLoading: true
    }))
    try {
      const requestData = {
        query: { ...requestDataPayload }
      }
      const response = await notificationService.getNotifications(requestData)

      if (response.status === 200) {

        setNotificationList((prev) => ({
          ...prev, data: response?.data?.data
        }))

      } else {
        throw new Error(response)
      }
    } catch (error) {

      console.error(error.response.data.message || error.response.data.error || error)
      toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
      setNotificationList((prev) => ({
        ...prev, message: error.response.data.message || error.response.data.error || "Something went wrong"
      }))
    } finally {
      setNotificationList((prev) => ({
        ...prev, isLoading: false
      }))
    }
  }

  useEffect(() => {
    const requestDataPayload = {
      page: notificationList?.data?.pagination?.page
        ? notificationList?.data?.pagination?.page + 1
        : DEFAULT_PAGE_COUNT,
      records: DEFAULT_PAGE_RECORDS
    }
    getNotificationList(requestDataPayload)
  }, [])

  const onHandleActiveFilter = (filter) => {
    if (activeFilter === filter.value) return
    const requestDataPayload = {
      page: DEFAULT_PAGE_COUNT,
      records: DEFAULT_PAGE_RECORDS
    }
    if (filter.value === notificationFilter.ALL.value) {
      getNotificationList(requestDataPayload)
    } else if (filter.value === notificationFilter.UNREAD.value) {
      requestDataPayload['status'] = notificationFilter.UNREAD.value
      getNotificationList(requestDataPayload)
    }
    setActiveFilter(filter.value)
  }

  const fetchMoreData = async () => {
    if ((notificationList?.data?.pagination?.page === notificationList?.data?.pagination?.totalPages) || notificationList?.isLoading) return;

    const requestDataPayload = {
      page: notificationList?.data?.pagination?.page
        ? notificationList?.data?.pagination?.page + 1
        : DEFAULT_PAGE_COUNT,
      records: DEFAULT_PAGE_RECORDS
    }

    if (activeFilter && (activeFilter === 'unread')) {
      requestDataPayload['status'] = 'unread'
    }
    setNotificationList(prev => ({ ...prev, isLoading: true }))

    try {
      const requestData = {
        query: requestDataPayload
      }
      const response = await notificationService.getNotifications(requestData)
      if (response.status === 200) {
        setNotificationList(prev => ({
          ...prev,
          data: prev?.data ? {
            ...prev?.data,
            result: [...prev?.data?.result, ...response?.data.data?.result],
            pagination: {
              ...prev?.data?.pagination,
              records: prev?.data?.pagination?.records + response.data.data?.pagination?.records,
              totalPages: response.data.data?.pagination?.totalPages,
              totalRecords: response.data.data?.pagination?.totalRecords,
              page: response.data.data?.pagination?.page
            },

          } : response.data.data
        }))
      } else {
        throw new Error(response)
      }
    } catch (error) {
      console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
      setNotificationList(prev => ({
        ...prev,
        message: error.response.data.message || error.response.data.error || "Something went wrong!"
      }))
      // toast.error(error.response.data.message || error.response.data.error || error)
    } finally {
      setNotificationList(prev => ({ ...prev, isLoading: false }))
    }
  }

  return (
    <div className="p-5 h-screen sm:px-8 max-w-2xl md:max-w-xl md:min-w-[28rem] font-bodyPri">
      <div className={"space-y-5 text-lg"}>
        <h2 className="font-medium">{"Notifications"}</h2>
        <div className={"w-full flex items-center gap-3 pb-3 border-b border-divider-lightDark"}>
          {Object.values(notificationFilter).map((filter, index) => {
            return (
              <div key={index} className="flex gap-3">
                <button
                  className={`px-3 text-sm py-1 rounded hover:scale-105 ease-in-out duration-300 ${activeFilter === filter.value ? 'bg-primary-main text-white' : 'bg-gray-200 text-gray-700'}`}
                  onClick={() => onHandleActiveFilter(filter)}
                >
                  <span>{filter.label}</span>
                </button>
              </div>
            )
          })}

        </div>
        <div
          id={"scrollableDiv"}
          style={{ maxHeight: "calc(100vh - 5rem)" }}
          className={"flex flex-col gap-5 pr-1 overflow-y-auto scrollbar-thin"}
        >
          {!!notificationList?.data?.result?.length &&
            <InfinitePagination
              scrollableTarget={"scrollableDiv"}
              inverse={false}
              dataLength={notificationList?.data?.result.length}
              fetchData={fetchMoreData}
              hasMore={(notificationList?.data?.pagination?.page != notificationList?.data?.pagination?.totalPages)}
              loader={<h4>Loading...</h4>}
              isShowEndMessage={false}
            >
              <div className="space-y-2">
                {notificationList?.data?.result?.map((notification, index) => (
                    <NotificationCard
                      key={index}
                      notification={notification}
                    />
                  ))}
              </div>
            </InfinitePagination>
          }
        </div>
        {(!notificationList?.isLoading && notificationList?.message || (notificationList?.data?.result?.length === 0)) &&
          <div className={'w-full flex items-center justify-center'}>
            <span className={'font-bodyPri font-normal text-secondary-dark text-base text-center'}>
              {notificationList.message || "No notifications..."}
            </span>
          </div>
        }
      </div>
    </div>
  )
}


export default function NewSideBarNotification() {
  const [showList, setShowList] = useState(false);
  const { user } = useAppState((state) => state.user)

  const showNumberOfNotification = async () => {

    setShowList(true);
    const response = await baseAuthApiInstance().post(
      `${EDULYTE_API_DOMAIN_URL}/v1/notifications/notifications/acknowledge`
    )
  }

  return (
    <>
      <SideOverPanel
        child={<ShowMoreAlertsSidePanel setShowList={setShowList} />}
        isOpen={showList}
        onClose={(isShow) => setShowList(isShow)}
      />
      <StyledNotificationBellContainer onClick={showNumberOfNotification}>
        <BellIcon className="h-8 w-8" />
        {user?.user?.notification_count > 0 && (
          <StyledNotificationCount>
            {user?.user?.notification_count > 9 ? '9+' : user?.user?.notification_count}
          </StyledNotificationCount>
        )}
      </StyledNotificationBellContainer>
    </>
  );
}