import { useNavigate } from "react-router-dom";

import { IoIosArrowForward } from "react-icons/io";
import { ImCross } from "react-icons/im";
import { BiSearch } from "react-icons/bi";

import SimpleSelectSearch from "pages/global/findTutors/components/tutorPublicFilterDropdowns/SimpleSelectSearch";

import { dropDownButtonConst, getQueryString } from "pages/global/findTutors/data";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { DEFAULT_TUTOR_PAGE, DEFAULT_TUTOR_RECORDS } from 'redux/tutor/tutor.const';

import { pagesInfo } from "utils/pagesInfo";

const SelectTopic = ({ dropDownButton, setDropDownButton }) => {
    const { categoryList } = useAppState((state) => state.category)
    const { filterProps } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    return (
        <div className={"relative w-1/2 bg-white px-3 py-2.5 rounded-l-lg"}>
            <div
                className={"w-full flex items-center justify-between gap-3 cursor-pointer"}
                onClick={() => {
                    if (!dropDownButton || dropDownButton !== dropDownButtonConst?.SELECT_TOPIC?.value) {
                        setDropDownButton(dropDownButtonConst?.SELECT_TOPIC?.value)
                    }
                    if (dropDownButton === dropDownButtonConst?.SELECT_TOPIC?.value) {
                        setDropDownButton(null)
                    }
                }}
            >
                <div className={"flex items-center justify-start gap-2"}>
                    <BiSearch className={"text-primary-dark text-xl"} />
                    <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide truncate"}>
                        {(filterProps?.category[0]?.length > 18
                            ? filterProps?.category[0]?.slice(0, 18) + "..."
                            : filterProps?.category[0])
                            || "Select Topic"
                        }
                    </span>
                </div>
                {filterProps?.category?.length === 0 &&
                    <span>
                        <IoIosArrowForward className={"text-base text-text-600"} />
                    </span>
                }
            </div>
            {filterProps?.category?.length > 0 &&
                <div
                    className={"absolute top-3 right-1 w-5 h-5 flex items-center justify-start rounded-full bg-white"}>
                    <ImCross
                        className={"w-full text-sm text-primary-dark text-center p-0.5 cursor-pointer"}
                        onClick={() => {
                            const query = getQueryString({ ...filterProps, category: [], page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                            navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                            setDropDownButton(null)
                        }}
                    />
                </div>
            }
            {(dropDownButton === dropDownButtonConst?.SELECT_TOPIC?.value) &&
                <SimpleSelectSearch
                    options={categoryList?.categoryList?.map((category) => ({
                        label: category?.category,
                        value: category?.category
                    }))}
                    isLoading={categoryList?.isLoading}
                    isShowInputBox={true}
                    isShowList={dropDownButton === dropDownButtonConst?.SELECT_TOPIC?.value}
                    setIsShowList={setDropDownButton}
                    className={"absolute top-64 left-12 md:top-56 md:left-32 w-fit md:w-80"}
                    onChange={(option) => {
                        setDropDownButton(null)
                        const query = getQueryString({ ...filterProps, category: option?.map((opt) => opt?.value), page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                        navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                    }}
                />
            }
        </div>
    )
}

export default SelectTopic