import {
    ItemContainer,
    Card1,
    Card2,
    StepText,
    VerticalDivider,
    // CardArrow,
    Line,
    Circle,
    CircleIcon,
} from './VerticalTimeLineStyle';
import TimeLineCardContent from './TimeLineCardContent';
import './verticalTimeLine.css';

const TimeLineItem = ({ index, timeLineItem }) => {
    return (
        <ItemContainer className='timeline-item'>
            <Card1 className='card1'>
                <TimeLineCardContent index={index} timeLineItem={timeLineItem} />
                {/* <CardArrow className='card-arrow' /> */}
            </Card1>
            <VerticalDivider >
                <Line />
                <Circle>
                    <CircleIcon />
                </Circle>
            </VerticalDivider>
            <Card2 className='card2'>
                <StepText>{'Step ' + (index + 1)}</StepText>
            </Card2>
        </ItemContainer>
    )
}

export default TimeLineItem;
