export const url = {
    GET_CRM_CONTACT_LIST: "crm/contacts/contacts/users/{userId}",
    UPDATE_BULK_CONTACT_LIST: "crm/contacts/contacts",
    DELETE_BULK_CONTACT_LIST: "crm/contacts/contacts",

    GET_CRM_CONTACT_DETAIL: "crm/contacts/contacts/{contactId}/users/{userId}",
    CREATE_CRM_CONTACT_DETAIL: "crm/contacts/contacts",
    UPDATE_CRM_CONTACT_DETAIL: "crm/contacts/contacts/{contactId}",
    DELETE_CRM_CONTACT_DETAIL: "crm/contacts/contacts/{contactId}",

    GET_CRM_CONTACT_STATUS_LIST: "crm/crm/contact-status",

    // contact label
    GET_CRM_CONTACT_LABEL_LIST: "crm/crm/contact-labels",
    CREATE_CRM_CONTACT_LABEL_DETAIL: "crm/crm/contact-labels",
    UPDATE_CRM_CONTACT_LABEL_DETAIL: "crm/crm/contact-labels/{labelId}",

    // create contact label detail by contact id
    CREATE_CONTACT_LABEL_DETAIL: "crm/contacts/labels",
    DELETE_CONTACT_LABEL_DETAIL: "crm/contacts/labels/{labelId}",

    // contact email detail
    CREATE_CRM_CONTACT_EMAIL_DETAIL: "crm/contacts/emails",
    UPDATE_CRM_CONTACT_EMAIL_DETAIL: "crm/contacts/emails/{emailId}",
    DELETE_CRM_CONTACT_EMAIL_DETAIL: "crm/contacts/emails/{emailId}",

    // contact phone detail
    CREATE_CRM_CONTACT_PHONE_DETAIL: "crm/contacts/phones",
    UPDATE_CRM_CONTACT_PHONE_DETAIL: "crm/contacts/phones/{phoneId}",
    DELETE_CRM_CONTACT_PHONE_DETAIL: "crm/contacts/phones/{phoneId}",

    // contact address detail
    CREATE_CRM_CONTACT_ADDRESS_DETAIL: "crm/contacts/addresses",
    UPDATE_CRM_CONTACT_ADDRESS_DETAIL: "crm/contacts/addresses/{addressId}",
    DELETE_CRM_CONTACT_ADDRESS_DETAIL: "crm/contacts/addresses/{addressId}",

    // contact social detail
    CREATE_CRM_CONTACT_SOCIAL_DETAIL: "crm/contacts/socials",
    UPDATE_CRM_CONTACT_SOCIAL_DETAIL: "crm/contacts/socials/{socialId}",
    DELETE_CRM_CONTACT_SOCIAL_DETAIL: "crm/contacts/socials/{socialId}",

    // contact note detail
    CREATE_CRM_CONTACT_NOTE_DETAIL: "crm/contacts/notes",
    UPDATE_CRM_CONTACT_NOTE_DETAIL: "crm/contacts/notes/{noteId}",
    DELETE_CRM_CONTACT_NOTE_DETAIL: "crm/contacts/notes/{noteId}",
}

export const titleOptionsEnum = {
    MR: {
        label: "Mr",
        value: "Mr",
    },
    MS: {
        label: "Ms",
        value: "Ms"
    },
    MRS: {
        label: "Mrs",
        value: "Mrs"
    },
    MISS: {
        label: "Miss",
        value: "Miss"
    },
    DR: {
        label: "Dr",
        value: "Dr"
    },
    SIR: {
        label: "Sir",
        value: "Sir"
    }
}

export const crmContactLabelsEnum = {
    HOME: {
        label: "Home",
        value: "home"
    },
    WORK: {
        label: "Work",
        value: "work"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}

export const crmContactCategoryEnum = {
    CONTACT: {
        label: "Contact",
        value: "contact"
    },
    LEAD: {
        label: "Lead",
        value: "lead"
    },
    CLIENT: {
        label: "Client",
        value: "client"
    }
}

export const modifyCrmBulkContactListPayload = {
    status: {},
    category: crmContactCategoryEnum.LEAD.value,
    selectedContactList: []
}

export const addCrmContactDetailPayload = {
    title: titleOptionsEnum.MR.value,
    first_name: "",
    profilePicInfo: {
        picture_url: "",
        fileList: [],
        fileUrl: "",
        file: {}
    },
    last_name: "",
    date_of_birth: "",
    company: "",
    job_title: "",
    labels: [],
    selectedLabel: {},
    emails: [],
    phones: [],
    socials: [],
    addresses: [],
    notes: [],
    status: null,
    category: crmContactCategoryEnum.LEAD.value
}

export const modifyCrmContactDetailPayload = {
    isEditEnabled:false,
    contact_id: null,
    title: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    company: "",
    job_title: "",
    labels: [],
    selectedLabel: "",
    emails: [],
    phones: [],
    socials: [],
    addresses: [],
    notes: [],
    status: "",
    category: ""
}

export const DEFAULT_CRM_CONTACT_PAGE = 1;
export const DEFAULT_CRM_CONTACT_RECORDS = 15;

export const crmFilterProps = {
    page: DEFAULT_CRM_CONTACT_PAGE,
    records: DEFAULT_CRM_CONTACT_RECORDS,
    search: ""
}