import { useNavigate } from "react-router-dom";

import { AiOutlinePlusCircle } from "react-icons/ai";

import ToolTipView from "components/tooltipView";

import { wallet } from "./data";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setAddCreditPayload, setClearAddCreditPayload, setClearAddCreditToWallet, setClearAddTokensPayload } from "redux/wallet/wallet.slice";
import { resetAddPaymentIntentDetail } from 'redux/payment/payment.slice';
import { modalConst } from "redux/local/local.const";
import { addCreditToWalletPayload } from "redux/wallet/wallet.initialState";

import { cn } from "utils/cn.utils";
import { pagesInfo } from "utils/pagesInfo";

const WalletCard = ({ walletItem }) => {
  const { modal } = useAppState((s) => s.local)

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()

  const onHandleAddCredit = () => {
    if (walletItem.value === wallet.TOKEN_CREDIT.value) {
      dispatcher(setClearAddTokensPayload())
      dispatcher(resetAddPaymentIntentDetail())
      navigate(pagesInfo.CHECKOUT_TOKEN.pagePath)
      return;
    }
    dispatcher(setClearAddCreditToWallet())
    dispatcher(setClearAddCreditPayload())
    dispatcher(setAddCreditPayload({
      ...addCreditToWalletPayload,
      currencyCode: walletItem?.currencyCode
    }))
    dispatcher(setModal({
      ...modal,
      [modalConst.ADD_CREDIT_MODAL.stateKey]: true
    }))
  }

  return (
    <div className={cn(
      "w-44 h-40 my-3 flex flex-col items-start justify-between gap-5 p-5 cursor-pointer",
      "rounded-3xl group bg-primary-light"
    )}>
      <div className={"w-full flex items-start justify-between gap-3"}>
        <ToolTipView content={walletItem.countryFlagTooltip}>
          <div className={"w-12 h-12 rounded-full overflow-hidden border-2 border-white"}>
            <img
              src={walletItem?.countryFlag}
              alt={"country-flag"}
              className={'w-full h-full object-cover'}
            />
          </div>
        </ToolTipView>

        {(walletItem.value !== wallet.PROMO_CREDIT.value) &&
        <ToolTipView content={walletItem?.addBtnTooltip}>
          <button
            className={"relative has-tooltip"}
            onClick={onHandleAddCredit}
          >
            <AiOutlinePlusCircle className={"text-xl md:text-2xl text-primary-dark"} />
          </button>
          </ToolTipView>
        }
      </div>
      <div className="w-full">
        <div className={"w-full text-start font-bodyPri font-bold text-md text-text-900 space-x-1"}>
          <span className={""}>
            {walletItem.amount ? parseFloat(walletItem.amount / 100).toFixed(2) : "0.00"}
          </span>
        </div>
        <div className={"w-full whitespace-nowrap font-bodyPri font-normal text-xs text-text-700 space-x-1"}>
          <span className={""}>
            {walletItem.currencyTitle}
          </span>
          {/* <span>
            {currencyRateConversion(locals?.currencyCode, walletItem.amount)}
          </span> */}
        </div>
      </div>
    </div>
  );
}

export default WalletCard;