import { useState } from "react";
import { cn } from "utils/cn.utils";

import { FiEdit } from 'react-icons/fi';
import { FaInfoCircle } from 'react-icons/fa';

import { profileEditBtn } from 'pages/auth/profile/data';

import SaveAndCancelBtn from 'pages/auth/profile/commonComponents/SaveAndCancelBtn';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { updateTutorUserDetailByUserId } from 'redux/tutor/tutor.request';

const MIN_CHARACTER_LENGTH = 50
const MAX_CHARACTER_LENGTH = 700


const MyTeachingStyle = ({ editBtnConst, setEditBtnConst }) => {
  const { user } = useAppState((state) => state.user);
  const { tutorUserDetail } = useAppState((state) => state.tutor);

  const dispatcher = useAppDispatcher()

  const [inputTeachingStyleBio, setInputTeachingStyleBio] = useState('')

  const onHandleSave = (key, value) => {
    if (!(inputTeachingStyleBio.length >= MIN_CHARACTER_LENGTH && inputTeachingStyleBio.length <= MAX_CHARACTER_LENGTH)) {
      alert("Please enter between 50 to 700 characters")
      return;
    }

    dispatcher(updateTutorUserDetailByUserId(user?.user?.userId, { [key]: value }))

    setEditBtnConst(null)
  }

  return (
    <div className="">
      <div className={"flex items-center gap-2"}>
        <div className={"relative has-tooltip cursor-pointer flex justify-end"}>
          <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
          <span
            className={cn(
              "w-56 px-2 py-0.5 font-bodyPri font-normal text-xs rounded shadow-lg bg-text-900 text-text-50 tooltip -top-8 -right-3 md:left-1",
              "flex flex-col items-start justify-start overflow-hidden"
            )}
          >
            {"Write briefly about your creative, innovative teaching style."}
          </span>
        </div>
        <div className={"text-xl text-text-900 font-medium font-bodyPri tracking-wide"}>
          {"My teaching style:"}
        </div>
        {!editBtnConst &&
          <FiEdit className={"text-lg text-text-500 cursor-pointer"}
            onClick={() => {
              setInputTeachingStyleBio(tutorUserDetail?.tutorUserDetail?.teachingStyleBio?.slice(0, 700)?.toString() || "")
              setEditBtnConst(profileEditBtn.teachingStyleBio.value)
            }}
          />
        }
      </div>

      <div className={"w-full"}>
        {editBtnConst !== profileEditBtn.teachingStyleBio.value &&
          <div className={"px-3 py-2"}>
            <span className={"font-bodyPri font-normal tracking-wide text-text-800 text-base"}>
              {tutorUserDetail?.tutorUserDetail?.teachingStyleBio}
            </span>
          </div>
        }
        {editBtnConst === profileEditBtn.teachingStyleBio.value &&
          <div className={"w-full flex flex-col items-start justify-start gap-2"}>
            <div className={"w-full flex flex-col justify-center gap-1"}>
              <textarea
                className={"w-full p-5 border-2 border-secondary-main border-dashed focus:outline-none font-bodyPri font-normal text-lg text-text-600 tracking-wide"}
                rows={5}
                minLength={MIN_CHARACTER_LENGTH}
                maxLength={MAX_CHARACTER_LENGTH}
                value={inputTeachingStyleBio}
                onChange={(event) => setInputTeachingStyleBio(event.target.value)}
              />
            </div>
            <div className={"w-full flex justify-between"}>
              <span className={"font-bodyPri font-normal tracking-wide text-red-500 text-xs"}>
                {"Please enter between 50 and 700 characters"}
              </span>
              <span className={"flex justify-end font-bodyPri font-normal text-text-800 text-xs"}>
                {inputTeachingStyleBio.length + "/" + MAX_CHARACTER_LENGTH}
              </span>
            </div>
            <SaveAndCancelBtn
              onCancel={() => setEditBtnConst(null)}
              onSave={() => onHandleSave(editBtnConst, inputTeachingStyleBio)}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default MyTeachingStyle;
