export const url = {
    GET_LMS_QUESTION_LIST: "lms/questions/tutor-questions",
    GET_LMS_QUESTION_DETAIL: "lms/questions/tutor-questions/{questionId}",
    CREATE_LMS_QUESTION_DETAIL: "lms/questions/questions",
    UPDATE_LMS_QUESTION_DETAIL: "lms/questions/questions/{questionId}",

    // create lms question resource
    CREATE_LMS_QUESTION_RESOURCE: "lms/questions/resources",
    DELETE_LMS_QUESTION_RESOURCE_DETAIL: "lms/questions/resources/{questionResourceId}",

    // lms question option endpoints
    CREATE_LMS_QUESTION_OPTION: "lms/questions/options",
    UPDATE_LMS_QUESTION_OPTION_DETAIL: "lms/questions/options/{optionId}",
    UPDATE_ORDER_LMS_QUESTION_OPTION: "lms/questions/options/order",
    DELETE_LMS_QUESTION_OPTION_DETAIL: "lms/questions/options/{optionId}",

    // lms question option resource
    CREATE_LMS_QUESTION_OPTION_RESOURCE: "lms/questions/option-resources",
    DELETE_LMS_QUESTION_OPTION_RESOURCE_DETAIL: "lms/questions/option-resources/{optionResourceId}",

    // lms question answer
    UPDATE_LMS_QUESTION_ANSWER_DETAIL: "lms/questions/answers/{answerId}",

    // lms question answer resource
    CREATE_LMS_QUESTION_ANSWER_RESOURCE: "lms/questions/answer-resources",
    DELETE_LMS_QUESTION_ANSWER_RESOURCE_DETAIL: "lms/questions/answer-resources/{answerResourceId}",

    // lms question instructor detail
    CREATE_LMS_QUESTION_INSTRUCTOR_DETAIL: "lms/questions/users",
    UPDATE_LMS_QUESTION_INSTRUCTOR_DETAIL: "lms/questions/users/{questionInstructorId}",
    DELETE_LMS_QUESTION_INSTRUCTOR_DETAIL: "lms/questions/users/{questionInstructorId}",

    // lms question tags
    GET_LMS_QUESTION_TAG_LIST: "lms/questions/tags",
    CREATE_LMS_QUESTION_TAG_DETAIL: "lms/questions/tags",
    UPDATE_LMS_QUESTION_TAG_LIST_ORDER: "lms/questions/tags/order",
    DELETE_LMS_QUESTION_TAG_DETAIL: "lms/questions/tags/{questionTagId}",

    // bulk question 
    CREATE_BULK_QUESTIONS: "lms/questions/questions/bulk-create"
}

export const lmsQuestionTypeEnum = {
    MULTIPLE_CHOICE: {
        label: "Multiple Choice",
        value: "multiple_choice"
    },
    SINGLE_CHOICE: {
        label: "Single Choice",
        value: "single_choice"
    },
    SHORT_ANSWER: {
        label: "Short Answer",
        value: "short_answer"
    },
    PARAGRAPH: {
        label: "Paragraph",
        value: "paragraph"
    }
}

export const lmsQuestionStatusEnum = {
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    }
}

export const lmsQuestionShareableEnum = {
    YES: {
        label: "Yes",
        value: "yes"
    },
    NO: {
        label: "No",
        value: "no"
    }
}

export const lmsQuestionVisibilityEnum = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    }
}

export const lmsQuestionInstructorPermissionEnum = {
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    }
}

export const addLmsQuestionDetailPayload = {
    title: "",
    description: "",
    questionOptions: [],
    answerResponse: {}
}

export const addLmsQuestionOptionPayload = {
    optionId: null,
    option: "",
    isCorrect: false,
}

export const updateLmsQuestionAnswerPayload = {
    answerId: null,
    answer: ""
}

export const lmsQuestionSettingsDetailPayload = {
    activeTab: "general"
}

export const addLmsQuestionInstructorDetailPayload = {
    userId: null,
    permission: null,
    questionInstructorId: null
}

export const DEFAULT_LMS_QUESTION_PAGE = 1
export const DEFAULT_LMS_QUESTION_RECORDS = 15