import { cn } from "utils/cn.utils";
import * as React from "react";
import { FiEdit } from 'react-icons/fi';
import { FaChevronRight, FaPenAlt } from "react-icons/fa";
import { useAppState } from "hooks/useStore";
import { editBtnConst } from "../../data";

export const SegmentCategoryView: React.FC<{ onEdit: () => void, editBtn: string }> = ({
  onEdit,
  editBtn
}) => {
  const { offeringCourse } = useAppState((s) => s.offering)

  return (
    <div
      className={cn(
        "relative w-full flex p-2 space-x-2 text-white",
        "items-center w-min pr-10 md:overflow-x-hidden",
        editBtn !== editBtnConst.category.value && "border border-secondary-dark border-dashed",
        editBtn === editBtnConst.category.value && "border-2 border-secondary-main border-solid",
      )}
    >
      <button className="absolute top-0 right-0 p-2" onClick={() => onEdit()}>
        {!editBtn &&
          <FiEdit className="text-lg text-text-500" />
        }
      </button>
      <p className="hidden text-xs font-medium md:block md:text-sm">Browse</p>
      <div className="hidden md:block">
        <FaChevronRight className="w-3 h-3 text-slate-200" />
      </div>
      <p className="text-xs font-medium md:text-sm whitespace-nowrap">
        {offeringCourse?.offeringCourse?.segment || "None"}
      </p>
      <div>
        <FaChevronRight className="w-3 h-3 text-slate-200" />
      </div>
      <p className="text-xs font-medium md:text-sm whitespace-nowrap">
        {offeringCourse?.offeringCourse?.category || "None"}
      </p>
    </div>
  );
};