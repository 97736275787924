import { useCallback, useState } from 'react';

import { toast } from "react-toastify";

import { Reorder } from 'framer-motion';
import { cn } from "utils/cn.utils";

import { MdAdd } from 'react-icons/md';

import ButtonLoader from 'components/loader/ButtonLoader';
import ComponentLoader from 'components/loader/ComponentLoader';

import AddBorderButton from '../../../../../../commonComponents/AddBorderButton';
import PreviousNextButtons from '../../../../../../commonComponents/lmsButtons/PreviousNextButtons';
import { courseDetailNavigationOptions, lmsCourseTooltips } from '../../../../editLMSCourse.data';
import FAQAccordionItem from './FAQAccordionItem';

import { createLmsCourseFaqDetail, updateLmsCourseFaqListOrder } from "redux/edulyteLms/lmsCourse/lmsCourse.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setLmsCourseDetailData } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';

import { isOrderUpdated } from 'utils/generators.utils';
import ShowMoreButton from 'pages/auth/edulyteLms/commonComponents/lmsButtons/ShowMoreButton';

const CourseFAQ = () => {
    const { lmsCourseDetail, addLmsCourseFaqDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    const [isAddFaqEnabled, setIsAddFaqEnabled] = useState(false)
    const [isShowAddButton, setIsShowAddButton] = useState(lmsCourseDetail?.data?.faqs?.length > 0)
    const [faqPayload, setFaqPayload] = useState({
        question: "",
        answer: ""
    })
    const [isFaqOrderUpdated, setIsFaqOrderUpdated] = useState(false)
    const [minFaqCount, setMinFaqCount] = useState(5)

    const onHandleAddFaq = useCallback(() => {
        setIsShowAddButton(false)
        setIsAddFaqEnabled(true)
        setFaqPayload({
            question: "",
            answer: ""
        })
    }, [isShowAddButton])

    const onHandleReorderGroup = (newOrder) => {
        if (newOrder) {
            const isOrderModified = isOrderUpdated(lmsCourseDetail?.data?.faqs, newOrder)
            if (!isOrderModified) {
                setIsFaqOrderUpdated(false)
                return;
            }
            setIsFaqOrderUpdated(true)
            dispatcher(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                faqs: newOrder
            }))
        }
    }

    const onHandleUpdateFaqOrder = () => {
        if (isFaqOrderUpdated && (lmsCourseDetail?.data?.faqs?.length > 1)) {
            const body = {
                order: lmsCourseDetail?.data?.faqs?.map((courseFaq) => ({
                    id: courseFaq?.id
                }))
            }
            dispatcher(updateLmsCourseFaqListOrder(body, { lms_course_id: lmsCourseDetail?.data?.id }))
            setIsFaqOrderUpdated(false)
        }
    }

    const onHandleChangeText = useCallback((event) => {
        setFaqPayload({
            ...faqPayload,
            [event?.target?.name]: event?.target?.value
        })
    }, [faqPayload])

    const onHandleSaveFaq = useCallback(() => {
        if (lmsCourseDetail?.isLoading || addLmsCourseFaqDetail?.isLoading) return;

        if (!faqPayload?.question) {
            toast.warn("Please add Question!")
            return;
        }
        if (!faqPayload?.answer) {
            toast.warn("Please add Answer!")
            return;
        }
        dispatcher(createLmsCourseFaqDetail({
            lms_course_id: lmsCourseDetail?.data?.id,
            question: faqPayload?.question,
            answer: faqPayload?.answer
        }))
        setIsShowAddButton(true)
        setIsAddFaqEnabled(false)
        setFaqPayload({
            question: "",
            answer: ""
        })
    }, [faqPayload])

    const onHandleResetFaq = useCallback(() => {
        setFaqPayload({
            question: "",
            answer: ""
        })
        if (lmsCourseDetail?.data?.faqs?.length > 0) {
            setIsShowAddButton(true)
        }
        setIsAddFaqEnabled(false)
    }, [])

    return (
        <div className={"w-full flex flex-col justify-between gap-5 rounded-lg overflow-hidden p-5 bg-white"}>
            <div className={"w-full flex flex-col gap-5"}>
                <div className={"w-full px-3 py-2 bg-divider-medium rounded-lg"}>
                    <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                        {"Frequently Asked Questions"}
                    </span>
                </div>
                <div className={"w-full text-center font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                    {lmsCourseTooltips?.faq}
                </div>
                {(!lmsCourseDetail?.data?.faqs || (lmsCourseDetail?.data?.faqs?.length === 0) && !isAddFaqEnabled) &&
                    <AddBorderButton
                        btnTitle={"Add FAQ(s)"}
                        onHandleAdd={onHandleAddFaq}
                    />
                }
                {(lmsCourseDetail?.data?.faqs?.length > 0) &&
                    <div className={""}>
                        <Reorder.Group
                            className={'w-full flex flex-col gap-3'}
                            values={lmsCourseDetail?.data?.faqs}
                            onReorder={onHandleReorderGroup}
                        >
                            {lmsCourseDetail?.data?.faqs?.slice(0, minFaqCount)?.map((itemFAQ, index) => (
                                <Reorder.Item
                                    key={itemFAQ?.id}
                                    value={itemFAQ}
                                    onDragEnd={onHandleUpdateFaqOrder}
                                >
                                    <FAQAccordionItem
                                        key={itemFAQ?.id}
                                        index={index}
                                        itemFAQ={itemFAQ}
                                    />
                                </Reorder.Item>
                            ))}
                        </Reorder.Group>
                        <ShowMoreButton
                            isShowLessBtn={(lmsCourseDetail?.data?.faqs?.length > 5) && (lmsCourseDetail?.data?.faqs?.length === minFaqCount)}
                            isShowMoreBtn={lmsCourseDetail?.data?.faqs?.length > minFaqCount}
                            showLessText={"Show less"}
                            showMoreText={"Show all FAQs"}
                            onHandleToggleBtn={() => setMinFaqCount(prevCount => (prevCount === 5) ? lmsCourseDetail?.data?.faqs?.length : 5)}
                        />
                    </div>
                }
                {addLmsCourseFaqDetail?.isLoading &&
                    <ComponentLoader isLoading={addLmsCourseFaqDetail?.isLoading} />
                }
                {(isShowAddButton && (lmsCourseDetail?.data?.faqs?.length > 0)) &&
                    <div className={"w-full flex items-center justify-center"}>
                        <div
                            className={cn(
                                "w-fit flex items-center justify-center cursor-pointer",
                                "border border-divider-lightDark rounded-md px-2 py-1",
                                "font-bodyPri font-medium text-base text-text-700",
                                "hover:text-text-900 hover:border-divider-dark"
                            )}
                            onClick={onHandleAddFaq}
                        >
                            <MdAdd className={"text-xl"} />
                            <span className={"whitespace-nowrap"}>
                                {"Add FAQ(s)"}
                            </span>
                        </div>
                    </div>
                }
                {isAddFaqEnabled &&
                    <div className={"w-full flex flex-col gap-5"}>
                        <div className={"w-full flex flex-col gap-2"}>
                            <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                                {"Question:"}
                            </span>
                            <input
                                type={"text"}
                                name={"question"}
                                placeholder={"Write question here ..."}
                                className={cn(
                                    "col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch",
                                    "h-fit px-3 py-2 border border-divider-lightDark rounded-lg",
                                    "font-bodyPri font-normal text-text-900 text-base tracking-wide first-letter:capitalize",
                                    "focus:outline-none focus:border-primary-main"
                                )}
                                autoFocus={true}
                                value={faqPayload?.question}
                                onChange={onHandleChangeText}
                            />
                        </div>
                        <div className={"w-full flex flex-col gap-2"}>
                            <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                                {"Answer:"}
                            </span>
                            <textarea
                                type={"text"}
                                name={"answer"}
                                className={cn(
                                    "col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch",
                                    "h-fit px-3 py-2 resize-none border border-divider-lightDark rounded-lg",
                                    "font-bodyPri font-normal text-text-900 text-base tracking-wide",
                                    "focus:outline-none focus:border-primary-main first-letter:capitalize"
                                )}
                                rows={5}
                                placeholder={"Write answer here ..."}
                                value={faqPayload?.answer}
                                onChange={onHandleChangeText}
                            />
                        </div>
                        <div className={"flex items-center justify-start gap-3"}>
                            <div
                                onClick={onHandleSaveFaq}
                                className={cn(
                                    "flex item-center justify-center w-20 py-1 rounded-md cursor-pointer",
                                    "font-buttons font-normal text-base text-text-50 bg-primary-main",
                                    "hover:bg-primary-dark"
                                )}>
                                {addLmsCourseFaqDetail?.isLoading &&
                                    <ButtonLoader isLoading={addLmsCourseFaqDetail?.isLoading} />
                                }
                                {!addLmsCourseFaqDetail?.isLoading &&
                                    <span className={""}>
                                        {"Save"}
                                    </span>
                                }
                            </div>
                            <div
                                onClick={onHandleResetFaq}
                                className={cn(
                                    "flex item-center justify-center w-20 py-1 rounded-md cursor-pointer",
                                    "font-buttons font-normal text-base text-text-800 bg-background-darkLight",
                                    "hover:bg-background-medium hover:text-text-900"
                                )}>
                                <span className={""}>
                                    {"Reset"}
                                </span>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <PreviousNextButtons
                previousBtn={courseDetailNavigationOptions?.WHAT_YOU_WILL_LEARN?.to}
                nextBtn={courseDetailNavigationOptions?.PRODUCT?.to}
            />
        </div>
    )
}

export default CourseFAQ;