import { useState, useCallback } from "react";

import { Reorder } from "framer-motion";

import LmsTextAreaDescription from "pages/auth/edulyteLms/commonComponents/LmsTextAreaDescription";

import EmptyCourseSectionItem from "./EmptyCourseSectionItem";
import LessonQuizContainer from "./lessonQuiz/LessonQuizContainer";
import LessonArticleContainer from "./lessonArticle/LessonArticleContainer";
import LessonLectureContainer from "./lessonLecture/LessonLectureContainer";

import { updateLmsCourseSection, updateOrderLmsCourseSectionLessonDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setLmsCourseDetailData } from "redux/edulyteLms/lmsCourse/lmsCourse.slice";
import { lmsCourseSectionLessonTypeEnum } from "redux/edulyteLms/lmsCourse/lmsCourse.const";

import { isOrderUpdated } from "utils/generators.utils";

const CourseSectionLesson = ({ courseSectionItem }) => {
    const { lmsCourseDetail, lmsCourseSectionDetail, updateLmsCourseSectionDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    const [sectionDescriptionStatus, setSectionDescriptionStatus] = useState("")
    const [isSectionLessonOrderUpdated, setIsSectionLessonOrderUpdated] = useState(false)

    const onHandleSaveDescription = useCallback((editedDescription) => {
        if (updateLmsCourseSectionDetail?.isLoading || lmsCourseSectionDetail?.isLoading) return;

        setSectionDescriptionStatus("Saving...")

        const body = {
            description: editedDescription
        }
        const payload = {
            isShowToast: false
        }
        dispatcher(updateLmsCourseSection(courseSectionItem?.id, body, payload))

        setTimeout(() => {
            setSectionDescriptionStatus("")
        }, 1000)

    }, [lmsCourseSectionDetail?.isLoading, updateLmsCourseSectionDetail?.isLoading, courseSectionItem])

    const onHandleReorderGroup = (newOrder) => {
        if (newOrder) {
            const isOrderModified = isOrderUpdated(courseSectionItem?.lms_course_section_lessons, newOrder)
            if (!isOrderModified) {
                setIsSectionLessonOrderUpdated(false)
                return;
            }
            if (isOrderModified) {
                setIsSectionLessonOrderUpdated(true)
                dispatcher(setLmsCourseDetailData({
                    ...lmsCourseDetail?.data,
                    course_sections: lmsCourseDetail?.data?.course_sections?.map((courseSectionContent) => (
                        (courseSectionContent?.id === courseSectionItem?.id)
                            ? { ...courseSectionContent, lms_course_section_lessons: newOrder }
                            : courseSectionContent
                    ))
                }))
            }
        }
    }

    const onHandleUpdateCourseSectionLessonItemOrder = () => {
        if (isSectionLessonOrderUpdated && (courseSectionItem?.lms_course_section_lessons?.length > 0)) {
            const body = {
                order: courseSectionItem?.lms_course_section_lessons?.map((courseSectionLesson) => ({
                    id: courseSectionLesson?.id
                }))
            }
            dispatcher(updateOrderLmsCourseSectionLessonDetail(body, { lms_course_section_id: courseSectionItem?.id }))
            setIsSectionLessonOrderUpdated(false)
        }
    }

    return (
        <div className={"flex flex-col gap-5 p-4 bg-primary-light rounded-lg transition-transform ease-in-out duration-300"}>
            <LmsTextAreaDescription
                isLoading={updateLmsCourseSectionDetail?.isLoading}
                isShowDescriptionLabel={false}
                description={courseSectionItem?.description}
                descriptionPlaceholder={"Add Description (optional)"}
                rows={2}
                autoFocus={true}
                descriptionInputStatus={sectionDescriptionStatus}
                setDescriptionInputStatus={setSectionDescriptionStatus}
                onHandleSaveDescription={onHandleSaveDescription}
            />
            {(courseSectionItem?.lms_course_section_lessons?.length > 0) &&
                <Reorder.Group
                    className={'w-full flex flex-col gap-3'}
                    values={courseSectionItem?.lms_course_section_lessons}
                    onReorder={onHandleReorderGroup}
                >
                    {courseSectionItem?.lms_course_section_lessons?.map((courseSectionLessonItem, index) => {
                        switch (courseSectionLessonItem?.type) {
                            case lmsCourseSectionLessonTypeEnum?.QUIZ?.value:
                                return (
                                    <Reorder.Item
                                        key={courseSectionLessonItem?.id}
                                        value={courseSectionLessonItem}
                                        onDragEnd={onHandleUpdateCourseSectionLessonItemOrder}
                                    >
                                        <LessonQuizContainer courseSectionLessonItem={courseSectionLessonItem} />
                                    </Reorder.Item>
                                )
                            case lmsCourseSectionLessonTypeEnum?.ARTICLE?.value:
                                return (
                                    <Reorder.Item
                                        key={courseSectionLessonItem?.id}
                                        value={courseSectionLessonItem}
                                        onDragEnd={onHandleUpdateCourseSectionLessonItemOrder}
                                    >
                                        <LessonArticleContainer courseSectionLessonItem={courseSectionLessonItem} />
                                    </Reorder.Item>
                                )
                            case lmsCourseSectionLessonTypeEnum?.LECTURE?.value:
                                return (
                                    <Reorder.Item
                                        key={courseSectionLessonItem?.id}
                                        value={courseSectionLessonItem}
                                        onDragEnd={onHandleUpdateCourseSectionLessonItemOrder}
                                    >
                                        <LessonLectureContainer courseSectionLessonItem={courseSectionLessonItem} />
                                    </Reorder.Item>
                                )
                            default:
                                return null
                        }
                    })}
                </Reorder.Group>
            }
            <EmptyCourseSectionItem courseSectionItem={courseSectionItem} />
        </div>
    );
};

export default CourseSectionLesson;