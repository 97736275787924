import { dayjs, timeZone } from "utils/dateTime.utils";

const CourseSessionCard = ({ sessionCourse }) => {

    return (
        <div className={"w-full flex flex-col items-start justify-start space-y-2"}>
            <div className="w-full flex items-center justify-center md:justify-between">
                <span className="text-xs md:text-base sm:flex-row sm:w-9/12">
                    {dayjs(sessionCourse?.session?.startDateTime)?.tz(timeZone)?.format('ddd, MMM DD, YYYY')}
                </span>
                <div className="w-full space-x-1 text-slate-600">
                    <span className="">
                        {dayjs(sessionCourse?.session?.startDateTime)?.tz(timeZone)?.format('hh:mm A')}
                    </span>
                    <span>{"-"}</span>
                    <span className="">
                        {dayjs(sessionCourse?.session?.endDateTime)?.tz(timeZone)?.format('hh:mm A')}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default CourseSessionCard;