import { useCallback, useMemo } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";

import CrmLayoutHeader from "../commonComponents/CrmLayoutHeader";

import {
  crmLeadsNavigationOptions,
  crmNavigationOptions,
  bulkDropdownContactItemsConst,
  searchParamsInfo,
} from "pages/auth/crm/data";

import { deleteCrmBulkContactList } from "redux/crm/crm.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import {
  resetAddCrmContactDetailPayload,
  resetModifyCrmBulkContactList,
  resetModifyCrmBulkContactListPayload,
  setAddCrmContactDetailPayload
} from "redux/crm/crm.slice";
import { addCrmContactDetailPayload, crmContactCategoryEnum } from "redux/crm/crm.const";
import { modalConst } from "redux/local/local.const";

import { pagesInfo } from "utils/pagesInfo";

const CrmLeadsLayoutPage = () => {
  const { modal } = useAppState((state) => state.local)
  const { modifyCrmBulkContactList, destroyCrmBulkContactList } = useAppState((state) => state.crm)

  const dispatcher = useAppDispatcher()
  const location = useLocation()
  const navigate = useNavigate()

  const searchQueryParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  const onHandleToggleView = () => {
    dispatcher(resetModifyCrmBulkContactList())
    dispatcher(resetModifyCrmBulkContactListPayload())
    if (location.pathname === crmLeadsNavigationOptions.TILE_VIEW.to) {
      navigate(`${pagesInfo.TUTOR_CRM.pagePath}${crmNavigationOptions.LEADS.pagePath}${crmLeadsNavigationOptions.LIST_VIEW.pagePath}`)
    } else if (location.pathname === crmLeadsNavigationOptions.LIST_VIEW.to) {
      navigate(`${pagesInfo.TUTOR_CRM.pagePath}${crmNavigationOptions.LEADS.pagePath}${crmLeadsNavigationOptions.TILE_VIEW.pagePath}`)
    }
  };

  const onHandleCreateNewContact = () => {
    dispatcher(resetAddCrmContactDetailPayload())
    dispatcher(setAddCrmContactDetailPayload({
      ...addCrmContactDetailPayload,
      category: crmContactCategoryEnum.LEAD.value
    }))
    searchQueryParams.set(searchParamsInfo.action.key, searchParamsInfo.createContact.key);
    navigate(`?${searchQueryParams.toString()}`);
  }

  const onHandleActionButtons = useCallback((option) => {
    if (destroyCrmBulkContactList?.isLoading || !modifyCrmBulkContactList?.payload?.selectedContactList?.length) return;

    switch (option.value) {
      case bulkDropdownContactItemsConst.MASS_UPDATE.value:
        dispatcher(setModal({
          ...modal,
          [modalConst.updateCrmBulkContactListModal.key]: {
            ...modalConst.updateCrmBulkContactListModal,
            isVisible: true
          }
        }))
        break;
      case bulkDropdownContactItemsConst.DELETE_CONTACT.value:
        if (!window.confirm("Are you sure?. You want to delete contact(s).")) return;
        const body = {
          data: modifyCrmBulkContactList?.payload?.selectedContactList?.map((item) => ({
            id: item
          }))
        }
        dispatcher(deleteCrmBulkContactList(body))
        break;
      case bulkDropdownContactItemsConst.CLEAR_ALL.value:
        dispatcher(resetModifyCrmBulkContactList())
        dispatcher(resetModifyCrmBulkContactListPayload())
        break;
      default:
        break;
    }
  }, [modal, destroyCrmBulkContactList, modifyCrmBulkContactList?.payload])

  return (
    <div className={"flex flex-col gap-3"}>
      <CrmLayoutHeader
        isLoading={(modifyCrmBulkContactList?.isLoading || destroyCrmBulkContactList?.isLoading)}
        isShowHeaderActionBtn={modifyCrmBulkContactList?.payload?.selectedContactList?.length}
        isTileViewActive={location.pathname === crmLeadsNavigationOptions.TILE_VIEW.to}
        headerTitle={"My Leads"}
        btnTitle={"Create Lead"}
        actionBtnList={Object.values(bulkDropdownContactItemsConst)}
        onHandleActionButtons={onHandleActionButtons}
        onHandleToggleView={onHandleToggleView}
        onHandleCreateNewContact={onHandleCreateNewContact}
      />
      <Outlet />
    </div>
  );
};

export default CrmLeadsLayoutPage;