import { Link } from "react-router-dom";
import { cn } from "utils/cn.utils";

const PeopleCard = ({ employee }) => {

    return (
        <div className='w-48 h-fit flex flex-col items-center justify-center gap-3'>
            <Link
                to={`/employee/${employee?.employeeId}`}
                className={"w-36 h-36 overflow-hidden rounded-full"}
            >
                <img
                    src={employee?.profilePicUrl}
                    alt={"employee-picture"}
                    className='w-full h-full object-cover'
                />
            </Link>
            <div className='flex flex-col justify-center items-center gap-3 overflow-hidden'>
                <div className='flex flex-col justify-center items-center gap-0.5'>
                    <Link
                        to={`/employee/${employee?.employeeId}`}
                        className={cn(
                            'text-text-900 font-bodyPri font-medium text-lg tracking-wide truncate',
                            "hover:text-secondary-main hover:underline decoration-1"
                        )}>
                        {employee?.firstName + " " + employee?.lastName?.charAt(0) + "."}
                    </Link>
                    <span className={"font-bodyPri font-normal italic text-sm text-text-700 tracking-wide"}>
                        {employee?.designation}
                    </span>
                </div>
                <div className={"font-bodyPri font-normal text-sm text-text-800"}>
                    <span className={"w-full text-center line-clamp-3"}>
                        {employee?.about}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default PeopleCard