export const weeklyTimeSlotObject = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: []
}

export const localsConst = {
    USER_ROLE: {
        label: "User Role",
        value: "userRole"
    },
    DISPLAY_LANGUAGE: {
        label: "Display Language",
        value: "displayLanguage"
    },
    CURRENCY_CODE: {
        label: "Currency Code",
        value: "currencyCode"
    },
    TIME_ZONE: {
        label: "Time zone",
        value: "timeZone"
    },
    TIME_FORMAT: {
        label: "Time Format",
        value: "timeFormat"
    }
}

export const environmentEnum = {
    local: {
        key: "local"
    },
    dev: {
        key: "dev"
    },
    prod: {
        key: "prod"
    }
}

export const socialAction = {
    SHARE_TUTOR_PROFILE: {
        label: "Share My Profile",
        value: "share_tutor_profile"
    },
    SHARE_TUTOR_COURSE: {
        label: "Share My Class",
        value: "share_my_course"
    },
    GET_MORE_REVIEWS: {
        label: "Get More Reviews",
        value: "get_more-reviews"
    },
    SHARE_LMS_COURSE: {
        label: "Share Course",
        value: "share_course"
    },
    SHARE_LMS_QUIZ: {
        label: "Share Quiz",
        value: "share_quiz"
    },
    SHARE_EVENTLY: {
        label: "Share Evently",
        value: "share_evently"
    },
    SHARE_APPOINTMENT: {
        label: "Appointment Booking Page",
        value: "share_appointment"
    },
    SHARE_DOWNLOAD: {
        label: "Share Download",
        value: "share_download"
    }
}

export const termsAndConditionsAction = {
    REFERRAL_TERMS_AND_CONDITIONS: {
        label: "Referral Terms and Conditions",
        value: "referral_terms_and_conditions"
    }
}

export const modalConst = {
    ROLE_SWITCH_MODAL: {
        stateKey: "isShowRoleSwitchModal",
    },
    COURSE_SCHEDULE_MODAL: {
        stateKey: "isShowCourseScheduleModal",
    },
    NO_SESSION_MODAL: {
        stateKey: "isShowNoOfSessionModal",
    },
    CHOOSE_TIME_SLOT_MODAL: {
        stateKey: "isShowChooseTimeSlotModal",
    },
    COURSE_CHECKOUT_MODAL: {
        stateKey: "isShowCourseCheckoutModal",
    },
    MAKE_PAYMENT_MODAL: {
        stateKey: "isShowMakePaymentModal",
    },
    PAYMENT_METHOD_MODAL: {
        stateKey: "isShowPaymentMethodModal",
    },
    ADD_CREDIT_MODAL: {
        stateKey: "isShowAddCreditModal",
    },
    TODO_MODAL: {
        stateKey: "isShowTodoModal",
    },
    SESSION_DETAIL_MODAL: {
        stateKey: "isShowSessionDetailModal",
    },
    SESSION_ACTION_MODAL: {
        stateKey: "isShowSessionActionModal",
    },
    COURSE_SCHEDULE_EDIT_MODAL: {
        stateKey: "isShowCourseScheduleEditModal",
    },
    IMAGE_PICKER_MODAL: {
        stateKey: "isShowImagePickerModal",
    },
    UPLOAD_DOCUMENT_MODAL: {
        stateKey: "isShowUploadDocumentModal",
    },
    PREVIEW_DOCUMENT_MODAL: {
        stateKey: "isShowPreviewDocumentModal",
    },
    QUESTION_ANSWER_MODAL: {
        stateKey: "isShowQuestionAnswerModal",
    },
    CHOOSE_INTERVIEW_SLOT: {
        stateKey: "isShowChooseIntervalSlot",
    },
    COMING_SOON_MODAL: {
        stateKey: "isShowComingSoonModal",
    },
    APPLY_REFERRAL_MODAL: {
        stateKey: "isShowApplyReferralModal",
    },
    AUTHORIZATION_MODAL: {
        stateKey: "isShowAuthorizationModal",
    },
    PERSONAL_DETAIL_MODAL: {
        stateKey: "isShowPersonalDetailModal",
    },
    CODE_VERIFICATION_MODAL: {
        stateKey: "isShowCodeVerificationModal",
    },
    SUBMIT_COURSE_MODAL: {
        stateKey: "isShowSubmitCourseModal",
    },
    MESSAGE_TEMPLATE_MODAL: {
        stateKey: "isShowMessageTemplateModal",
    },
    SHARE_SOCIAL_MODAL: {
        stateKey: "isShowShareSocialModal",
    },
    POST_REQUIREMENT_MODAL: {
        stateKey: "isShowPostRequirementModal",
    },
    COMPANY_PEOPLE_MODAL: {
        stateKey: "isShowCompanyPeopleModal",
    },
    VERIFY_EMAIL_MODAL: {
        stateKey: "isShowVerifyEmailModal",
    },
    VERIFY_MOBILE_NO_MODAL: {
        stateKey: "isShowVerifyMobileNoModal",
    },
    SPEAK_TO_US_MODAL: {
        stateKey: "isShowSpeakToUsModal",
    },
    NEED_A_TUTOR_MODAL: {
        stateKey: "isShowNeedATutorModal",
    },
    VALIDATION_BAR_EMAIL_VERIFY_MODAL: {
        stateKey: "isShowValidationBarEmailVerifyModal",
    },
    VALIDATION_BAR_MOBILE_VERIFY_MODAL: {
        stateKey: "isShowValidationBarMobileVerifyModal",
    },
    WITHDRAW_MONEY_MODAL: {
        stateKey: "isShowWithdrawMoneyModal",
    },
    CONFIRM_PAYOUT_MODAL: {
        stateKey: "isShowConfirmPayoutModal",
    },
    WITHDRAWAL_REQUEST_SUBMIT_MODAL: {
        stateKey: "isShowWithdrawalRequestSubmitModal",
    },
    FORGET_PASSWORD_MODAL: {
        stateKey: "isShowForgetPasswordModal",
    },
    RESET_PASSWORD_MODAL: {
        stateKey: "isShowRestPasswordModal",
    },
    VERIFY_AUTHENTICATION_MODAL: {
        stateKey: "isShowVerifyAuthenticationModal",
    },
    UPDATE_EMAIL_MODAL: {
        stateKey: "isShowUpdateEmailModal",
    },
    UPDATE_MOBILE_NO_MODAL: {
        stateKey: "isShowUpdateMobileNoModal",
    },
    UPDATE_PASSWORD_MODAL: {
        stateKey: "isShowUpdatePasswordModal",
    },
    UPLOAD_VIDEO_MODAL: {
        stateKey: "isShowUploadVideoModal",
    },
    RECORD_VIDEO_MODAL: {
        stateKey: "isShowRecordVideoModal",
    },
    PHOTO_SEARCH_MODAL: {
        stateKey: "isShowPhotoSearchModal",
    },
    PROMO_MODAL: {
        stateKey: "isShowPromoModal",
    },
    ADD_BOOKING_SESSION_MODAL: {
        stateKey: "isShowAddBookingSessionModal",
    },
    ADD_REVIEW_MODAL: {
        stateKey: "isShowAddReviewModal",
    },
    GET_MORE_REVIEWS: {
        stateKey: "isShowGetMoreReviewsModal",
    },
    ADD_OFF_DAY_MODAL: {
        stateKey: "isShowAddOffDayModal",
    },
    CHECKOUT_SLOT_MODAL: {
        stateKey: "isShowCheckoutSlotModal",
    },
    PAYMENT_CARD_MODAL: {
        stateKey: "isShowPaymentCardModal",
    },
    RESCHEDULE_TIME_SLOT_MODAL: {
        stateKey: "isShowRescheduleTimeSlotModal",
    },
    CANCEL_BOOKING_SESSION_MODAL: {
        stateKey: "isShowCancelBookingSessionModal",
    },
    EVENT_DETAIL_MODAL: {
        stateKey: "isShowEventDetailModal",
    },
    ADD_COURSE_NEXT_SESSION_MODAL: {
        stateKey: "isShowAddCourseNextSessionModal",
    },
    MANUAL_MOBILE_VERIFICATION_MODAL: {
        stateKey: "isShowManualMobileVerificationModal",
    },
    UPLOAD_DRIVE_FILE_MODAL: {
        stateKey: "isShowUploadDriveFileModal",
    },
    SHARE_DRIVE_FILE_MODAL: {
        stateKey: "isShowShareDriveFileModal",
    },

    // lms modals
    ATTACH_LIBRARY_MODAL: {
        stateKey: "isShowAttachLibraryModal",
    },
    CREATE_LMS_ARTICLE_MODAL: {
        stateKey: "isShowCreateLmsArticleModal",
    },
    CREATE_LMS_COURSE_MODAL: {
        stateKey: "isShowCreateLmsCourseModal",
    },
    CREATE_LMS_QUESTION_MODAL: {
        stateKey: "isShowCreateLmsQuestionModal",
    },
    CREATE_LMS_QUIZ_MODAL: {
        stateKey: "isShowCreateLmsQuizModal",
    },
    CREATE_LMS_TOPIC_MODAL: {
        stateKey: "isShowCreateLmsTopicModal",
    },
    CREATE_LMS_LECTURE_MODAL: {
        stateKey: "isShowCreateLmsLectureModal",
    },
    UPLOAD_LMS_MEDIA_MODAL: {
        stateKey: "isShowUploadLmsMediaModal",
    },
    LMS_PREVIEW_IMAGE_MODAL: {
        stateKey: "isShowLmsPreviewImageModal",
    },
    PRODUCT_PRICE_DETAIL_MODAL: {
        stateKey: "isShowProductPriceDetailModal",
    },
    inviteLmsCourseUserModal: {
        key: "inviteLmsCourseUserModal",
        isVisible: false,
        title: "Invite User",
    },
    inviteLmsQuizUserModal: {
        key: "inviteLmsQuizUserModal",
        isVisible: false,
        title: "Invite User",
    },
    addLmsLessonInstructorModal: {
        key: "addLmsLessonInstructorModal",
        isVisible: false,
        title: "Add Instructor",
    },
    addLmsQuestionInstructorModal: {
        key: "addLmsQuestionInstructorModal",
        isVisible: false,
        title: "Add Instructor",
    },
    addLmsQuizInstructorModal: {
        key: "addLmsQuizInstructorModal",
        isVisible: false,
        title: "Add Instructor",
    },
    addLmsArticleInstructorModal: {
        key: "addLmsArticleInstructorModal",
        isVisible: false,
        title: "Add Instructor",
    },
    addLmsLectureInstructorModal: {
        key: "addLmsLectureInstructorModal",
        isVisible: false,
        title: "Add Instructor",
    },

    addEventlySlotModal: {
        key: "addEventlySlotModal",
        isVisible: false,
        title: "Add Event Slot",
    },
    viewEventlySlotModal: {
        key: "viewEventlySlotModal",
        isVisible: false,
        title: "View Event Slot",
    },
    editEventlyEventSlotModal: {
        key: "editEventlyEventSlotModal",
        isVisible: false,
        title: "",
    },
    viewContactDetailModal: {
        key: "viewContactDetailModal",
        isVisible: false,
        title: "",
    },
    createCrmContactDetailModal: {
        key: "createCrmContactDetailModal",
        isVisible: false,
        title: ""
    },
    updateCrmBulkContactListModal: {
        key: "updateCrmBulkContactListModal",
        isVisible: false,
        title: ""
    },
    planTableModal: {
        key: "planTableModal",
        isVisible: false,
        title: ""
    },
    addDownloadModal: {
        key: "addDownloadModal",
        isVisible: false,
        title: ""
    },
    CREATE_BULK_QUESTIONS: {
        key: "createBulkQuestions",
        isVisible: false,
        title: ""
    },
    CREATE_CERTIFICATE_MODAL: {
        key: "createCertificateModal",
        isVisible: false,
        title: ""
    },
    createAppointmentDetailModal: {
        key: "createAppointmentDetailModal",
        isVisible: false,
        title: ""
    },
    inviteDownloadUserModal: {
        key: "inviteDownloadUserModal",
        isVisible: false,
        title: ""
    },
    myDownloadDetailModal: {
        key: "myDownloadDetailModal",
        isVisible: false,
        title: ""
    },
    appointmentLocationDetailModal: {
        key: "appointmentLocationDetailModal",
        isVisible: false,
        title: ""
    },
    checkoutLocationModal: {
        key: "checkoutLocationModal",
        isVisible: false,
        title: "Update Booking Location"
    },
};