import { useCallback } from 'react';
import { Link } from 'react-router-dom';

// import { cn } from "utils/cn.utils";

// import { MdAdd } from 'react-icons/md';

import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';

import LMSTableComponent from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTableComponent';
import { quizInstructorHeaderConst, quizInstructorMenuItemConst } from '../../../../editLMSQuiz.data';

import { deleteLmsQuizInstructorDetail, updateLmsQuizDetail, updateLmsQuizInstructorDetail } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import {
    resetAddLmsQuizInstructorDetail,
    resetAddLmsQuizInstructorDetailPayload,
    resetModifyLmsQuizInstructorDetail
} from 'redux/edulyteLms/lmsQuiz/lmsQuiz.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsQuizInstructorPermissionEnum } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.const';

import { pagesInfo } from 'utils/pagesInfo';
import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

const QuizInstructor = () => {
    const { modal } = useAppState((state) => state.local)
    const { lmsQuizDetail } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()

    const onHandleAddInstructor = useCallback(() => {
        dispatcher(resetAddLmsQuizInstructorDetail())
        dispatcher(resetModifyLmsQuizInstructorDetail())
        dispatcher(resetAddLmsQuizInstructorDetailPayload())
        dispatcher(setModal({
            ...modal,
            [modalConst.addLmsQuizInstructorModal.key]: {
                ...modal[modalConst.addLmsQuizInstructorModal.key],
                isVisible: true,
                title: "Add Instructor"
            }
        }))
    }, [modal])

    const sortQuizInstructor = (instructor1, instructor2) => {
        if (instructor1?.id === lmsQuizDetail?.data?.quiz_setting?.default_owner?.id) {
            return -1;
        } else if (instructor2?.id === lmsQuizDetail?.data?.quiz_setting?.default_owner?.id) {
            return 1;
        } else {
            return instructor1 - instructor2;
        }
    }

    const onHandleSelectMenuItem = (menuItem, quizInstructorItem) => {
        switch (menuItem.value) {
            case quizInstructorMenuItemConst?.MAKE_DEFAULT?.value:
                dispatcher(updateLmsQuizDetail(lmsQuizDetail?.data?.id, { owner_user_id: quizInstructorItem?.id }))
                return;
            case quizInstructorMenuItemConst?.VIEWER?.value:
                if (quizInstructorMenuItemConst?.VIEWER?.value === quizInstructorItem?.permission) return;
                dispatcher(updateLmsQuizInstructorDetail(quizInstructorItem?.id, { user_id: quizInstructorItem?.tutor?.user?.userId, permission: quizInstructorMenuItemConst?.VIEWER?.value }))
                break;
            case quizInstructorMenuItemConst?.EDITOR?.value:
                if (quizInstructorMenuItemConst?.EDITOR?.value === quizInstructorItem?.permission) return;
                dispatcher(updateLmsQuizInstructorDetail(quizInstructorItem?.id, { user_id: quizInstructorItem?.tutor?.user?.userId, permission: quizInstructorMenuItemConst?.EDITOR?.value }))
                break;
            case quizInstructorMenuItemConst?.OWNER?.value:
                if (quizInstructorMenuItemConst?.OWNER?.value === quizInstructorItem?.permission) return;
                dispatcher(updateLmsQuizInstructorDetail(quizInstructorItem?.id, { user_id: quizInstructorItem?.tutor?.user?.userId, permission: quizInstructorMenuItemConst?.OWNER?.value }))
                break;
            case quizInstructorMenuItemConst?.DELETE?.value:
                dispatcher(deleteLmsQuizInstructorDetail(quizInstructorItem?.id))
                break;

            default:
                break;
        }
    }

    const QuizTutorProfileImageContainer = ({ quizInstructor }) => {
        return (
            <div className={"flex items-center justify-center"}>
                <div className={'relative w-12 h-12 rounded-full'}>
                    <Link to={`${pagesInfo.TUTOR.pagePath}/${quizInstructor?.tutor?.user?.userId}`}>
                        <img
                            src={quizInstructor?.tutor?.user?.profile_pic_url}
                            alt={"profile-pic"}
                            className={"w-full h-full rounded-full object-cover"}
                        />
                    </Link>
                </div>
            </div>
        )
    }

    const QuizTutorNameContainer = ({ quizInstructor }) => {
        return (
            <div className={'flex items-center justify-center gap-1'}>
                <a
                    href={`${EDULYTE_APP_DOMAIN_URL}${pagesInfo.TUTOR.pagePath}/${quizInstructor?.tutor?.user?.userId}`}
                    target={"_blank"}
                    className={"w-fit"}
                >
                    <span className={'font-bodyPri font-normal text-base tracking-wide truncate capitalize'}>
                        {quizInstructor?.tutor?.user?.first_name} {quizInstructor?.tutor?.user?.last_name.charAt(0) + "."}
                    </span>
                </a>
                {(lmsQuizDetail?.data?.quiz_setting?.default_owner?.id === quizInstructor?.id) &&
                    <span className={"font-bodyPri font-medium text-green-500 text-sm"}>
                        {"(Primary)"}
                    </span>
                }
            </div>
        )
    }

    const quizInstructorRows = lmsQuizDetail?.data?.quiz_tutors && lmsQuizDetail?.data?.quiz_tutors?.slice(0)?.sort(sortQuizInstructor)?.map((quizInstructor) => [
        <QuizTutorProfileImageContainer quizInstructor={quizInstructor} />,
        <QuizTutorNameContainer quizInstructor={quizInstructor} />,
        lmsQuizInstructorPermissionEnum[quizInstructor?.permission?.toUpperCase()]?.label,
        (lmsQuizDetail?.data?.quiz_setting?.default_owner?.id != quizInstructor?.id)
            ? <div className={"w-full flex items-center justify-center"}>
                <ThreeDotMenu
                    menuItems={Object.values(quizInstructorMenuItemConst)}
                    onHandleSelect={(menuItem) => onHandleSelectMenuItem(menuItem, quizInstructor)}
                />
            </div>
            : "-"
    ])

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <div className={"w-full flex items-center justify-between gap-3"}>
                <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                    {"Instructor(s)"}
                </span>
                {/* <div
                    className={cn(
                        "flex items-center justify-center gap-1 py-1 px-3",
                        "border border-primary-dark text-primary-dark rounded-lg cursor-pointer hover:bg-primary-dark group"
                    )}
                    onClick={onHandleAddInstructor}
                >
                    <MdAdd className={"text-lg group-hover:text-text-50"} />
                    <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                        {"Add Instructor"}
                    </span>
                </div> */}
            </div>
            <div className={"w-full px-5"}>
                <LMSTableComponent
                    headers={Object.values(quizInstructorHeaderConst)}
                    subRowHeader={[]}
                    rows={(lmsQuizDetail?.data?.quiz_tutors?.length > 0) ? quizInstructorRows : []}
                    rowHeaderColor={"bg-background-medium"}
                    alternateRowColor={"bg-primary-light"}
                />
            </div>
        </div>
    )
}

export default QuizInstructor;