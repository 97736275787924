import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { cn } from "utils/cn.utils";

import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";

// import { Toggle } from 'components/common-components/Toggle';
import ToolTipView from 'components/tooltipView';

import Drag from "pages/auth/edulyteLms/assets/drag.svg";

import { deleteLmsCourseSectionLessonDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';
// import { updateLmsArticle } from 'redux/edulyteLms/lmsArticle/lmsArticle.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { lmsCourseSectionLessonTypeEnum } from 'redux/edulyteLms/lmsCourse/lmsCourse.const';
// import { lmsArticleVisibilityEnum } from 'redux/edulyteLms/lmsArticle/lmsArticle.const';

import { pagesInfo } from 'utils/pagesInfo';

const LessonArticleContainer = memo(({ courseSectionLessonItem }) => {
    const { deleteLmsCourseSectionLesson } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    // const onHandleUpdateArticleVisibility = () => {
    //     const sectionLessonPayload = {
    //         sectionId: courseSectionLessonItem?.lms_course_section_id,
    //         sectionLessonId: courseSectionLessonItem?.id,
    //         isShowToast: true
    //     }
    //     const articleVisibility = (courseSectionLessonItem?.article?.article_setting?.visibility === lmsArticleVisibilityEnum?.PUBLIC?.value)
    //         ? lmsArticleVisibilityEnum?.PRIVATE?.value
    //         : lmsArticleVisibilityEnum?.PUBLIC?.value
    //     dispatcher(updateLmsArticle(courseSectionLessonItem?.article?.id, { visibility: articleVisibility }, sectionLessonPayload))
    // }

    const onHandleEditArticle = useCallback(() => {
        navigate(`${pagesInfo.EDIT_LMS_ARTICLE.pagePath}/${courseSectionLessonItem?.article?.id}/edit`)

    }, [courseSectionLessonItem])

    const onHandleDeleteArticle = useCallback(() => {
        if (deleteLmsCourseSectionLesson?.isLoading) return;

        dispatcher(deleteLmsCourseSectionLessonDetail(courseSectionLessonItem?.id))

    }, [courseSectionLessonItem])

    return (
        <div className={cn(
            'flex flex-col md:flex-row items-end md:items-start justify-between gap-2 px-5 py-2 border bg-white rounded-lg',
            'border-divider-darkLight hover:border-divider-lightDark'
        )}>
            <div className={cn('w-full flex items-start gap-5')}>
                <img
                    src={Drag}
                    className={"mt-1.5 w-2.5 cursor-grab"}
                />
                {courseSectionLessonItem?.type &&
                    <span className={"inline-flex font-bodyPri font-medium text-text-900 text-md sm:text-lg line-clamp-2"}>
                        {`${lmsCourseSectionLessonTypeEnum[courseSectionLessonItem?.type?.toUpperCase()]?.label}: `}
                        <span className={'font-bodyPri font-medium text-text-900 text-md sm:text-lg'}>
                            {courseSectionLessonItem?.article?.title}
                        </span>
                    </span>
                }
            </div>
            <div className={'flex justify-end items-center gap-1.5'}>
                {/* <ToolTipView
                    content={[lmsArticleVisibilityEnum?.PUBLIC?.value]?.includes(courseSectionLessonItem?.article?.article_setting?.visibility)
                        ? "Disable Previews"
                        : "Enable Previews"
                    }
                >
                    <button>
                        <Toggle
                            value={(courseSectionLessonItem?.article?.article_setting?.visibility === lmsArticleVisibilityEnum?.PUBLIC?.value) && true}
                            onChange={onHandleUpdateArticleVisibility}
                        />

                    </button>
                </ToolTipView> */}
                <ToolTipView content={'Edit Article'}>
                    <button className={"p-2 rounded-full hover:bg-divider-darkLight group"} onClick={onHandleEditArticle}>
                        <MdEdit className={"text-xl text-text-700 group-hover:text-primary-dark cursor-pointer"} />
                    </button>
                </ToolTipView>
                <ToolTipView content={"Remove Article"}>
                    <button className={"p-2 rounded-full hover:bg-divider-darkLight group"} onClick={onHandleDeleteArticle}>
                        <RiDeleteBin6Line className={"text-xl text-text-700 group-hover:text-red-500 cursor-pointer"} />
                    </button>
                </ToolTipView>
            </div>
        </div>
    )
});

export default LessonArticleContainer;