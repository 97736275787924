import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import SelectSearch, { fuzzySearch } from "react-select-search";

import { FaSpinner } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { BsCameraFill } from "react-icons/bs";

import { companyEditBtn } from "pages/auth/editCompany/data";
import SaveAndCancelBtn from "pages/auth/editCompany/components/saveAndCancelBtn/SaveAndCancelBtn";

import {
    getCompanyPublicProfileByCompanyName,
    updateCompanyPublicProfileByCompanyId
} from "redux/company/company.request";

import { getMasterCityList, getMasterCountryList, getMasterStateList } from "redux/master/master.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearCompanyPublicProfile, setCompanyPublicProfile } from "redux/company/company.slice";

import { companyNameConst } from "pages/global/company/data";
import { setClearCountryList } from "redux/master/master.slice";
import { getYouTubeVideoIdFromUrl } from "utils/generators.utils";

const EditCompanyInformation = ({ editBtnConst, setEditBtnConst }) => {
    const { countryList, stateList, cityList } = useAppState((state) => state.master)
    const { companyPublicProfile } = useAppState((state) => state.company)

    const dispatcher = useAppDispatcher()
    const { companyName } = useParams()

    const [inputVideoUrl, setInputVideoUrl] = useState("")
    const [inputCompanyName, setInputCompanyName] = useState("")
    const [inputElevatorPitch, setInputElevatorPitch] = useState("")
    const [selectedCountry, setSelectedCountry] = useState(null)
    const [selectedState, setSelectedState] = useState(null)
    const [selectedCity, setSelectedCity] = useState(null)


    useEffect(() => {
        dispatcher(getCompanyPublicProfileByCompanyName(companyNameConst[companyName.toUpperCase()].value))

        return () => {
            dispatcher(setClearCompanyPublicProfile())
        }
    }, [])

    useEffect(() => {
        dispatcher(getMasterCountryList())

        return () => {
            dispatcher(setClearCountryList())
        }
    }, [])

    useEffect(() => {
        if (selectedCountry) {
            dispatcher(getMasterStateList(selectedCountry))
        }
    }, [selectedCountry])

    useEffect(() => {
        if (selectedState) {
            dispatcher(getMasterCityList(selectedState))
        }
    }, [selectedState])

    useEffect(() => {
        setInputVideoUrl(`https://www.youtube.com/embed/${companyPublicProfile?.companyPublicProfile?.videoUrl}`)
    }, [companyPublicProfile?.companyPublicProfile?.videoUrl])

    const onSubmitVideoUrl = (value) => {
        const youtubeVideoId = getYouTubeVideoIdFromUrl(value)
        // dispatcher(updateCompanyPublicProfileByCompanyId(companyPublicProfile?.companyPublicProfile?.companyId, {[key]: value}))
        dispatcher(setCompanyPublicProfile({
            ...companyPublicProfile?.companyPublicProfile,
            videoUrl: youtubeVideoId
        }))

        setEditBtnConst(null)
    }

    const onSubmitCompanyName = (key, value) => {
        if (value?.length === 0) {
            alert("Please Provide a Company Name")
            return;
        }
        dispatcher(setCompanyPublicProfile({
            ...companyPublicProfile?.companyPublicProfile,
            companyName: value
        }))
        setEditBtnConst(null)
    }

    const onSubmitElevatorPitch = (key, value) => {
        if (value?.length === 0) {
            alert("Please tell us something about your company")
            return;
        }
        dispatcher(setCompanyPublicProfile({
            ...companyPublicProfile?.companyPublicProfile,
            elevatorPitch: value
        }))
        setEditBtnConst(null)
    }

    const onSubmitCompanyLocation = (key, value) => {
        if (!selectedCountry || !selectedState || !selectedCity) {
            alert("company location is incomplete!")
            return;
        }
        dispatcher(setCompanyPublicProfile({
            ...companyPublicProfile?.companyPublicProfile,
            country: selectedCountry,
            state: selectedState,
            city: selectedState
        }))
        setEditBtnConst(null)
    }

    return (
        <div className={"flex items-center justify-center px-5 md:px-0 pb-1"}>
            <div className={"max-w-full md:max-w-3xl lg:max-w-5xl w-full bg-white p-5"}>
                <div className={"relative w-full h-20"}>
                    <div className={"w-full absolute -top-24 z-30 flex justify-between items-center"}>
                        <div className={cn(
                            "relative w-32 h-fit bg-white shadow-all",
                            !companyPublicProfile?.companyPublicProfile?.companyLogo && "p-2"
                        )}>
                            {companyPublicProfile?.companyPublicProfile?.companyLogo &&
                                <img
                                    src={companyPublicProfile?.companyPublicProfile?.companyLogo}
                                    className={"w-full h-full object-cover"}
                                    alt={"company-logo"}
                                />
                            }
                            {!companyPublicProfile?.companyPublicProfile?.companyLogo &&
                                <span className={"font-bodyPri font-semibold text-text-800 text-md"}>
                                    {companyPublicProfile?.companyPublicProfile?.companyName.replaceAll("_", " ")}
                                </span>
                            }
                            <div className={cn(
                                "absolute rounded-full bg-white p-1.5 shadow-all",
                                companyPublicProfile?.companyPublicProfile?.companyLogo && "top-24 -right-1",
                                !companyPublicProfile?.companyPublicProfile?.companyLogo && "top-12 -right-1"
                            )}>
                                <BsCameraFill className={"text-xl"} onClick={() => setEditBtnConst(companyEditBtn.coverPicUrl.value)} />
                            </div>
                        </div>
                        <div className={"h-40"}>
                            <div className={"relative hidden md:flex flex-col items-start justify-center"}>
                                {!editBtnConst &&
                                    <div className={"absolute top-0 right-0 bg-white rounded-full p-1 overflow-hidden cursor-pointer"}>
                                        <FiEdit className={"text-lg text-text-500 hover:text-text-700 cursor-pointer"}
                                            onClick={() => {
                                                setInputVideoUrl(inputVideoUrl)
                                                setEditBtnConst(companyEditBtn.companyVideoUrl.value)
                                            }}
                                        />
                                    </div>
                                }
                                {editBtnConst === companyEditBtn.companyVideoUrl.value &&
                                    <div className={"absolute top-0 right-0 w-full flex flex-col items-start justify-start gap-3"}>
                                        <input
                                            type={"text"}
                                            placeholder={"url"}
                                            autoFocus={true}
                                            className="w-full px-2 py-1 border-secondary-dark border-dashed border-2 focus:outline-none font-bodyPri font-normal text-lg text-text-600 tracking-wide"
                                            value={inputVideoUrl}
                                            onChange={(e) => setInputVideoUrl(e.target.value)}
                                        />
                                        <SaveAndCancelBtn
                                            onCancel={() => setEditBtnConst(null)}
                                            onSave={() => onSubmitVideoUrl(inputVideoUrl)}
                                        />
                                    </div>
                                }
                                <div className="rounded-t-lg overflow-hidden w-96 h-40">
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src={`https://www.youtube.com/embed/${companyPublicProfile?.companyPublicProfile?.videoUrl}`}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                                    </iframe>
                                </div>
                                <div className={"w-full px-2 py-2 flex justify-center items-center gap-5 bg-white rounded-b-lg shadow-xl"}>
                                    <div
                                        className={cn("w-36 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                                "border border-text-800 text-text-800",
                                                "font-buttons font-normal text-base",
                                                "hover:border-secondary-dark hover:bg-secondary-dark hover:text-text-50"
                                            )}
                                    >
                                        {"Message"}
                                    </div>
                                    <div
                                        className={cn
                                            ("w-36 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                                "border border-text-800 text-text-800",
                                                "font-buttons font-normal text-base",
                                                "hover:border-secondary-dark hover:bg-secondary-dark hover:text-text-50"
                                            )}>
                                        {"More"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"flex flex-col items-start justify-center gap-1"}>

                    {/* company name container */}
                    {editBtnConst !== companyEditBtn.companyName.value &&
                        <div className={"flex items-center justify-start gap-5"}>
                            <span className={"font-bodyPri font-semibold text-2xl text-text-800 capitalize"}>
                                {companyPublicProfile?.companyPublicProfile?.companyName?.replaceAll("_", " ")}
                            </span>
                            {!editBtnConst &&
                                <FiEdit className={"text-lg text-text-500 hover:text-text-700 cursor-pointer"}
                                    onClick={() => {
                                        setInputCompanyName(companyPublicProfile?.companyPublicProfile?.companyName)
                                        setEditBtnConst(companyEditBtn.companyName.value)
                                    }}
                                />
                            }
                        </div>
                    }
                    {editBtnConst === companyEditBtn.companyName.value &&
                        <div className={"flex flex-col items-start justify-start gap-3"}>
                            <input
                                type={"text"}
                                placeholder={"Company Name"}
                                autoFocus={true}
                                minLength={0}
                                maxLength={20}
                                className="w-full px-2 py-1 border-secondary-dark border-dashed border-2 focus:outline-none font-bodyPri font-normal text-lg text-text-600 tracking-wide"
                                value={inputCompanyName}
                                onChange={(e) => setInputCompanyName(e.target.value)}
                            />
                            <SaveAndCancelBtn
                                onCancel={() => setEditBtnConst(null)}
                                onSave={() => onSubmitCompanyName(editBtnConst, inputCompanyName)}
                            />
                        </div>
                    }

                    {/* elevator pitch container */}
                    {editBtnConst !== companyEditBtn.elevatorPitch.value &&
                        <div className={"flex items-center justify-start gap-5"}>
                            <span className={"font-bodyPri font-medium text-text-800 text-base tracking-wide"}>
                                {companyPublicProfile?.companyPublicProfile?.elevatorPitch}
                            </span>
                            {!editBtnConst &&
                                <FiEdit className={"text-lg text-text-500 hover:text-text-700 cursor-pointer"}
                                    onClick={() => {
                                        setInputElevatorPitch(companyPublicProfile?.companyPublicProfile?.elevatorPitch)
                                        setEditBtnConst(companyEditBtn.elevatorPitch.value)
                                    }}
                                />
                            }
                        </div>
                    }
                    {editBtnConst === companyEditBtn.elevatorPitch.value &&
                        <div className={"w-full sm:w-3/4 md:w-2/3 lg:w-1/2 flex flex-col items-start justify-start gap-3"}>
                            <input
                                type={"text"}
                                autoFocus={true}
                                minLength={0}
                                maxLength={50}
                                className="w-full px-2 py-1 border-secondary-dark border-dashed border-2 focus:outline-none font-bodyPri font-normal text-lg text-text-600 tracking-wide"
                                value={inputElevatorPitch}
                                onChange={(e) => setInputElevatorPitch(e.target.value)}
                            />
                            <SaveAndCancelBtn
                                onCancel={() => setEditBtnConst(null)}
                                onSave={() => onSubmitElevatorPitch(editBtnConst, inputElevatorPitch)}
                            />
                        </div>
                    }

                    {/* location container */}
                    {editBtnConst !== companyEditBtn.currentLocation.value &&
                        <div className={"flex items-center justify-start gap-5"}>
                            <span className={"font-bodyPri font-normal text-text-700 text-base"}>
                                {companyPublicProfile?.companyPublicProfile?.currentLocation}
                            </span>
                            {!editBtnConst &&
                                <FiEdit className={"text-lg text-text-500 hover:text-text-700 cursor-pointer"}
                                    onClick={() => {
                                        setSelectedCountry(companyPublicProfile?.companyPublicProfile?.country)
                                        setSelectedState(companyPublicProfile?.companyPublicProfile?.state)
                                        setSelectedCity(companyPublicProfile?.companyPublicProfile?.city)
                                        setEditBtnConst(companyEditBtn.currentLocation.value)
                                    }}
                                />
                            }
                        </div>
                    }
                    {editBtnConst === companyEditBtn.currentLocation.value &&
                        <div className={"flex flex-col justify-center items-start gap-3"}>
                            <div className={"flex justify-center items-center gap-3"}>
                                {countryList?.isLoading &&
                                    <div className={"flex items-center justify-start gap-0.5"}>
                                        <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                                            {"Please wait ..."}
                                        </span>
                                        <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                                    </div>
                                }
                                {(!countryList?.isLoading && (countryList?.countryList || [])) &&
                                    <SelectSearch
                                        options={countryList?.countryList?.map((country) => ({
                                            name: country?.country,
                                            value: country?.masterCountryId
                                        }))}
                                        search
                                        filterOptions={fuzzySearch}
                                        placeholder={"Select Country"}
                                        value={selectedCountry}
                                        onChange={(option) => setSelectedCountry(option)}
                                    />
                                }
                                {countryList?.errorMsg &&
                                    <span className={"font-bodyPri font-normal text-text-700 text-base"}>
                                        {countryList?.errorMsg}
                                    </span>
                                }
                                {stateList?.isLoading &&
                                    <div className={"flex items-center justify-start gap-0.5"}>
                                        <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                                            {"Please wait ..."}
                                        </span>
                                        <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                                    </div>
                                }
                                {(!stateList?.isLoading && (stateList?.stateList || [])) &&
                                    <SelectSearch
                                        options={stateList?.stateList?.map((state) => ({
                                            name: state?.state,
                                            value: state?.masterStateId
                                        }))}
                                        search
                                        filterOptions={fuzzySearch}
                                        placeholder={"Select state"}
                                        value={selectedState}
                                        onChange={(option) => setSelectedState(option)}
                                    />
                                }
                                {stateList?.errorMsg &&
                                    <span className={"font-bodyPri font-normal text-text-700 text-base"}>
                                        {stateList?.errorMsg}
                                    </span>
                                }
                                {cityList?.isLoading &&
                                    <div className={"flex items-center justify-start gap-0.5"}>
                                        <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                                            {"Please wait ..."}
                                        </span>
                                        <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                                    </div>
                                }
                                {(!cityList?.isLoading && (cityList?.cityList || [])) &&
                                    <SelectSearch
                                        options={cityList?.cityList?.map((city) => ({
                                            name: city?.city,
                                            value: city?.masterCityId
                                        }))}
                                        search
                                        filterOptions={fuzzySearch}
                                        placeholder={"Select city"}
                                        value={selectedCity}
                                        onChange={(option) => setSelectedCity(option)}
                                    />
                                }
                                {cityList?.errorMsg &&
                                    <span className={"font-bodyPri font-normal text-text-700 text-base"}>
                                        {cityList?.errorMsg}
                                    </span>
                                }
                            </div>
                            <SaveAndCancelBtn
                                onCancel={() => setEditBtnConst(null)}
                                onSave={() => onSubmitCompanyLocation(editBtnConst, inputCompanyName)}
                            />
                        </div>
                    }

                    {/* company video container */}
                    <div className={"relative md:hidden flex flex-col items-start justify-center mt-8"}>
                        {!editBtnConst &&
                            <div className={"absolute top-0 right-0 bg-white rounded-full p-1 overflow-hidden cursor-pointer"}>
                                <FiEdit className={"text-lg text-text-500 hover:text-text-700 cursor-pointer"}
                                    onClick={() => {
                                        setInputVideoUrl(inputVideoUrl)
                                        setEditBtnConst(companyEditBtn.companyVideoUrl.value)
                                    }}
                                />
                            </div>
                        }
                        {editBtnConst === companyEditBtn.companyVideoUrl.value &&
                            <div className={"absolute top-0 right-0 w-full flex flex-col items-start justify-start gap-3"}>
                                <input
                                    type={"text"}
                                    placeholder={"url"}
                                    autoFocus={true}
                                    className="w-full px-2 py-1 border-secondary-dark border-dashed border-2 focus:outline-none font-bodyPri font-normal text-lg text-text-600 tracking-wide"
                                    value={inputVideoUrl}
                                    onChange={(e) => setInputVideoUrl(e.target.value)}
                                />
                                <SaveAndCancelBtn
                                    onCancel={() => setEditBtnConst(null)}
                                    onSave={() => onSubmitVideoUrl(inputVideoUrl)}
                                />
                            </div>
                        }
                        <div className="rounded-t-lg overflow-hidden w-96 h-40">
                            <iframe
                                width="100%"
                                height="100%"
                                src={`https://www.youtube.com/embed/${companyPublicProfile?.companyPublicProfile?.videoUrl}`}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                            </iframe>
                        </div>
                        <div className={"w-full px-2 py-2 flex justify-center items-center gap-5 bg-white rounded-b-lg shadow-xl"}>
                            <div
                                className={cn
                                    ("w-36 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                        "border border-text-800 text-text-800",
                                        "font-buttons font-normal text-base",
                                        "hover:border-secondary-dark hover:bg-secondary-dark hover:text-text-50"
                                    )}
                            >
                                {"Message"}
                            </div>
                            <div
                                className={cn
                                    ("w-36 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                        "border border-text-800 text-text-800",
                                        "font-buttons font-normal text-base",
                                        "hover:border-secondary-dark hover:bg-secondary-dark hover:text-text-50"
                                    )}>
                                {"More"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default EditCompanyInformation