import { useEffect } from 'react';
import { cn } from "utils/cn.utils";

import { createInterviewSessionDetail } from 'redux/interview/interview.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setAddInterviewSessionDetailPayload, resetAddInterviewSessionDetail } from 'redux/interview/interview.slice';
import { modalConst } from 'redux/local/local.const';

import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';


const ChooseTimeSlot = ({ dailyAvail, timeSlot }) => {
  const { modal } = useAppState((state) => state.local)
  const { interviewDetail, addInterviewSessionDetail } = useAppState((state) => state.interview)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    if (addInterviewSessionDetail?.data) {
      dispatcher(resetAddInterviewSessionDetail())
      dispatcher(setModal({
        ...modal,
        [modalConst.CHOOSE_INTERVIEW_SLOT.stateKey]: false
      }))
    }
  }, [addInterviewSessionDetail?.data])

  const timeZoneOffset = getTimeZoneOffset(timeZone)

  let isSelected = false

  addInterviewSessionDetail?.payload?.schedule?.forEach((schedule) => {
    let currentDayjs = dayjs(dailyAvail.date + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
    let selectedDayjs = dayjs(schedule?.date + " " + schedule?.timeSlots[0]?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)

    isSelected = currentDayjs.format("YYYY-MM-DD HH:mm:ssZ") === selectedDayjs.format("YYYY-MM-DD HH:mm:ssZ")
  })

  const handleSelect = () => {
    if (!timeSlot?.isAvailable) return;

    dispatcher(setAddInterviewSessionDetailPayload({
      ...addInterviewSessionDetail?.payload,
      timeZone: timeZone,
      schedule: isSelected ? [] : [{
        date: dailyAvail?.date,
        timeSlots: [{
          startTime: timeSlot?.startTime,
          endTime: timeSlot?.endTime
        }]
      }]
    }))
  }

  const handleConfirm = () => {
    if (addInterviewSessionDetail?.isLoading) return;

    const body = {
      availabilityId: interviewDetail?.interviewDetail?.recruiter?.availabilityId,
      duration: interviewDetail?.interviewDetail?.duration,
      timeZone: timeZone,
      schedule: addInterviewSessionDetail?.payload?.schedule
    }

    dispatcher(createInterviewSessionDetail(interviewDetail?.interviewDetail?.interviewId, body))
  }

  if (addInterviewSessionDetail?.message) {
    return (
      <div className={"w-full h-full text-center font-bodyPri font-medium text-text-900 text-base"}>
        {addInterviewSessionDetail?.message}
      </div>
    )
  }

  return (
    <div className={"flex flex-row items-center justify-center"}>
      <span onClick={handleSelect} className={cn(
        "px-2 py-0.5 border-2 cursor-pointer border-primary-main",
        "font-bodyPri font-medium text-sm text-primary-main rounded",
        isSelected && "!text-text-50 bg-primary-main rounded-r-none",
        !timeSlot?.isAvailable && "text-secondary-light border-secondary-light"
      )}>
        {dayjs(dailyAvail.date + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("hh:mm A")}
      </span>
      {isSelected &&
        <span onClick={handleConfirm} className={cn(
          "px-2 py-0.5 border-2 cursor-pointer border-secondary-main rounded-r",
          "font-bodyPri font-medium text-sm",
          "transition ease-in-out delay-150",
          isSelected
            ? "bg-secondary-main text-text-50"
            : "hidden"
        )}>
          {"Confirm"}
        </span>
      }
    </div>
  )
}

export default ChooseTimeSlot