import { memo } from "react";

import { motion } from 'framer-motion';

import ButtonLoader from "components/loader/button-loader";
import ToolTipView from "components/tooltipView";

import { cn } from 'utils/cn.utils';

const SaveAndCancelBtn = memo(({
    isSaveBtnLoading = false,
    isSaveBtnDisabled = false,
    saveBtnTooltip = "No changes detected. Nothing to save",
    onHandleCancelBtn,
    onHandleSaveAndCloseBtn
}) => {

    return (
        <div className={"flex items-center justify-start gap-4"}>
            <motion.button
                key={"cancel"}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.3 }}
                className={"font-bodyPri font-normal text-text-800 text-base hover:underline cursor-pointer"}
                onClick={onHandleCancelBtn}
                disabled={isSaveBtnLoading}
            >
                <span className={""}>
                    {"Cancel"}
                </span>
            </motion.button>
            <ToolTipView content={saveBtnTooltip} disabled={!isSaveBtnDisabled || isSaveBtnLoading}>
                <motion.button
                    key={"save"}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ duration: 0.3 }}
                    className={cn(
                        "py-2 px-3 md:px-5 flex items-center justify-center text-white text-sm md:text-base ease-in-out duration-200 bg-primary-dark hover:bg-secondary-dark rounded-full",
                        "font-bodyPri font-normal text-sm sm:text-base cursor-pointer",
                        isSaveBtnDisabled && "bg-divider-medium hover:bg-divider-medium text-divider-light cursor-not-allowed",
                        isSaveBtnLoading && "cursor-wait"
                    )}
                    // disabled={isSaveBtnDisabled || isSaveBtnLoading}
                    onClick={onHandleSaveAndCloseBtn}
                >
                    {isSaveBtnLoading &&
                        <ButtonLoader isLoading={isSaveBtnLoading} className={"py-2.5"} />
                    }
                    {!isSaveBtnLoading &&
                        <span className={""}>
                            {"Save and Close"}
                        </span>
                    }
                </motion.button>
            </ToolTipView>
        </div>
    )
})

export default SaveAndCancelBtn;