import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { FiCheckSquare } from 'react-icons/fi';
import { FaSpinner } from 'react-icons/fa';
import { MdOutlineContentCopy } from 'react-icons/md';

import SaveAndCancelBtn from 'pages/auth/editDownload/commonComponents/SaveAndCancelBtn';
import DownloadTooltip from 'pages/auth/editDownload/commonComponents/DownloadTooltip';

import { downloadSlugActionButtons } from '../../data';

import { updateUserDownloadDetail } from 'redux/downloads/downloads.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearShareSocial, setModal, setShareSocial } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { cn } from 'utils/cn.utils';
import { validateSlug } from 'utils/validation-functions';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';

const WEB_DOWNLOAD_BASE_URL = `${EDULYTE_WEB_DOMAIN_URL}/download/`;
const MAX_LENGTH = 110;

const DownloadSlug = () => {
    const { modal, shareSocial } = useAppState((state) => state.local)
    const { ownerUserDownloadDetail, addUserDownloadDetail } = useAppState((state) => state.downloads)

    const dispatcher = useAppDispatcher()

    const [isAddLinkFormEnabled, setIsAddLinkFormEnabled] = useState(false)
    const [linkTitle, setLinkTitle] = useState("")
    const [copiedUrl, setCopiedURL] = useState(false)

    const onHandleSaveLink = useCallback(() => {
        if (ownerUserDownloadDetail?.isLoading) return;

        if (!linkTitle) {
            toast.warn("Please add link!")
            return;
        }

        if (!validateSlug(linkTitle?.toLowerCase()?.trim())) {
            toast.warn("Please provide valid link!")
            return;
        }

        const body = {
            slug: linkTitle?.toLowerCase()?.trim()
        }
        dispatcher(updateUserDownloadDetail(ownerUserDownloadDetail?.data?.id, body))
        setLinkTitle('')
        setIsAddLinkFormEnabled(false)
    }, [linkTitle, ownerUserDownloadDetail])

    const onHandleResetLink = useCallback(() => {
        setLinkTitle('')
        setIsAddLinkFormEnabled(false)
    }, [])

    const onHandleEditDownloadSlug = useCallback(() => {
        setLinkTitle(ownerUserDownloadDetail?.data?.slug)
        setIsAddLinkFormEnabled(true)
    }, [linkTitle, isAddLinkFormEnabled, ownerUserDownloadDetail?.data])

    const onHandleCopyDownloadSlug = useCallback(() => {
        navigator.clipboard.writeText(`${WEB_DOWNLOAD_BASE_URL}${ownerUserDownloadDetail?.data?.slug}`);
        setCopiedURL(true);
        toast.success("Copied!")
        setTimeout(() => {
            setCopiedURL(false);
        }, 2000);
    }, [ownerUserDownloadDetail?.data])

    const onHandleShareDownloadSlug = useCallback(() => {
        const DOWNLOAD_URL = `${WEB_DOWNLOAD_BASE_URL}${ownerUserDownloadDetail?.data?.slug}`
        dispatcher(setClearShareSocial())
        dispatcher(setShareSocial({
            ...shareSocial,
            socialAction: "",
            url: DOWNLOAD_URL
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
        }))
    }, [ownerUserDownloadDetail?.data])

    const onHandleActionButtons = useCallback((selectedAction) => {
        // if (selectedAction.value === downloadSlugActionButtons.EDIT.value) {
        //     onHandleEditDownloadSlug()
        // }
        if (selectedAction.value === downloadSlugActionButtons.COPY.value) {
            onHandleCopyDownloadSlug()
        } else if (selectedAction.value === downloadSlugActionButtons.SHARE.value) {
            onHandleShareDownloadSlug()
        }
    }, [ownerUserDownloadDetail?.data])

    return ownerUserDownloadDetail?.data?.slug && (
        <>
            {isAddLinkFormEnabled &&
                <div className={"w-full grid grid-cols-12 gap-3 items-center"}>
                    <div className={"col-span-8 order-1 lg:col-span-11 space-x-1 mt-0 md:mt-1"}>
                        <div className={"w-full flex items-center justify-start gap-0.5"}>
                            <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                {`${WEB_DOWNLOAD_BASE_URL}`}
                            </span>
                            <input
                                type={"text"}
                                name={"slug"}
                                placeholder={"title-of-the-course"}
                                className={cn(
                                    "h-fit flex-1 px-3 border-b-2 border-divider-lightDark",
                                    "font-bodyPri font-normal text-text-900 text-base tracking-wide",
                                    "focus:outline-none focus:border-primary-main"
                                )}
                                maxLength={MAX_LENGTH}
                                value={linkTitle}
                                autoFocus={true}
                                onChange={(event) => setLinkTitle(event?.target?.value?.toLowerCase())}
                                onKeyDown={onHandleSaveLink}
                            />
                        </div>
                        <span className={"w-full flex justify-end font-bodyPri font-normal text-sm text-text-800"}>
                            {`${linkTitle?.length}/${MAX_LENGTH}`}
                        </span>
                    </div>
                    <div className={"col-span-full order-3 lg:order-2 lg:col-start-12 lg:col-span-1 justify-self-stretch flex items-center justify-start gap-1"}>
                        {addUserDownloadDetail?.isLoading &&
                            <div className={"flex items-center justify-start gap-1.5"}>
                                <FaSpinner className={"animate-spin text-primary-dark text-base"} />
                                <span className={"font-bodyPri font-normal text-primary-dark text-sm"}>
                                    {"Please wait"}
                                </span>
                            </div>
                        }
                        {!addUserDownloadDetail?.isLoading &&
                            <SaveAndCancelBtn
                                onHandleSave={onHandleSaveLink}
                                onHandleReset={onHandleResetLink}
                            />
                        }
                    </div>
                </div>
            }
            {!isAddLinkFormEnabled &&
                <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                    <div className={"col-span-8 order-1 lg:col-span-7 w-full flex items-center justify-start lg:justify-between font-bodyPri font-normal text-text-700 text-base tracking-wide space-x-1 mt-0 md:mt-1"}>
                        <a
                            href={`${WEB_DOWNLOAD_BASE_URL}${ownerUserDownloadDetail?.data?.slug}`}
                            className={"w-fit font-bodyPri font-normal text-primary-dark text-base tracking-wide group hover:underline hover:text-primary-dark"}
                            target={"_blank"}
                        >
                            <span>
                                {`${WEB_DOWNLOAD_BASE_URL}`}
                            </span>
                            <span className={"text-primary-dark group-hover:text-primary-dark"}>
                                {ownerUserDownloadDetail?.data?.slug}
                            </span>
                        </a>
                        <span>{":"}</span>
                    </div>
                    <div className={"col-span-full order-3 lg:order-2 lg:col-span-5 justify-self-stretch flex items-center justify-start gap-1"}>
                        {Object.values(downloadSlugActionButtons).map((buttonItem, index) => (
                            <DownloadTooltip
                                key={index}
                                tooltip={buttonItem.tooltip}
                                children={
                                    <button
                                        className={"p-2 rounded-full hover:bg-divider-darkLight"}
                                        onClick={() => onHandleActionButtons(buttonItem)}
                                    >
                                        {(buttonItem?.value === downloadSlugActionButtons.COPY.value)
                                            ? <>
                                                {copiedUrl &&
                                                    <FiCheckSquare className={"text-xl text-green-500"} />
                                                }
                                                {!copiedUrl &&
                                                    <MdOutlineContentCopy className={"text-xl text-text-700 cursor-pointer"} />
                                                }
                                            </>
                                            : <>{buttonItem?.icon}</>
                                        }
                                    </button>
                                }
                            />
                        ))}
                    </div>
                </div>
            }
        </>
    )
}

export default DownloadSlug