import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { NavHashLink } from "react-router-hash-link";

import TableComponent from "components/common-components/Table";
import Pagination from "components/generalComponent/pagination/Pagination";
import ComponentLoader from "components/loader/ComponentLoader";

import TableFilter from "pages/auth/referral/TableFilter";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { getUserReferralList } from "redux/referral/referral.request";
import { setClearUserReferralList } from 'redux/referral/referral.slice';
import { setFilterProps } from "redux/referral/referral.slice";
import { filterName } from "redux/referral/referral.const";

import { currencyRateConversion } from 'utils/generators.utils';


const Referrals = ({ cols }) => {
  const { userReferralList, filterProps } = useAppState((s) => s.referral)

  const dispatcher = useAppDispatcher()

  const [firstIndex, setFirstIndex] = useState(0)
  const [lastIndex, setLastIndex] = useState(5)
  const [itemsPerPage] = useState(5)
  const [headers] = useState(cols);


  useEffect(() => {
    dispatcher(getUserReferralList())

    return () => {
      dispatcher(setClearUserReferralList())
    }
  }, [])

  useEffect(() => {
    setFirstIndex(0)
    setLastIndex(itemsPerPage)
  }, [itemsPerPage, userReferralList?.filteredUserReferralList])

  const sortReferralList = (referral1, referral2) => {
    let sortReferral1 = dayjs(referral1?.createdAt)
    let sortReferral2 = dayjs(referral2?.createdAt)
    
    return (sortReferral1.isBefore(sortReferral2)) ? 1 : -1;
  }

  const rows = userReferralList?.filteredUserReferralList?.slice(firstIndex, lastIndex)?.sort(sortReferralList)?.map(row => [
    dayjs(row?.createdAt).format("ddd, MMM D, YYYY"),
    row?.benificiaryUser?.firstName + " " + row?.benificiaryUser?.lastName?.charAt(0),
    row?.purchasedDate ? dayjs(row?.purchasedDate).format("ddd, MMM D, YYYY") : "-",
    row?.status,
    currencyRateConversion("USD", row?.amount)
  ])

  return (
    <div className="bg-white rounded-lg p-5 space-y-5">
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <div className="flex flex-col justify-center items-center relative gap-3">
            <div className="font-bodyPri text-3xl text-text-900 font-medium">
              {"Your referrals"}
            </div>
            <div className="font-bodyPri font-medium text-md text-text-700">
              {"See the history of all your referred friends here"}
            </div>
            <div className="absolute right-2 top-2 cursor-pointer">
              <TableFilter handleFilters={(referralStatus) => {
                dispatcher(dispatcher(setFilterProps({
                  filterProps: { ...filterProps, referralStatus: referralStatus },
                  list: userReferralList?.userReferralList,
                  filterName: filterName.REFERRAL.value
                })))
              }
              } />
            </div>
          </div>
        </div>
      </div>
      {userReferralList?.filteredUserReferralList && userReferralList?.filteredUserReferralList?.length !== 0 ?
        <>
          <ComponentLoader isLoading={userReferralList?.isLoading} />
          <TableComponent
            headers={headers}
            rows={rows}
            alternateRowColor="bg-sky-100"
            rowHeaderColor="bg-sky-200"
          />
        </>
        :
        <span className={"flex justify-center items-center gap-1 w-full text-sm sm:text-base md:text-xl text-center font-bodyPri tracking-wide"}>
          {"Sorry! You have No Referrals."}
          <NavHashLink to={"/marketing/referral/#refer"} className={"cursor-pointer text-primary-main"}>
            {"Refer"}
          </NavHashLink>
          {"to a Friend"}
        </span>
      }
      {userReferralList?.filteredUserReferralList && userReferralList?.filteredUserReferralList?.length !== 0 &&
        <div className="py-3 space-x-1 bg-white rounded-lg shadow-md border border-solid">
          <Pagination
            totalItems={userReferralList?.filteredUserReferralList?.length}
            itemsPerPage={5}
            onStartIndex={(firstindex) => (setFirstIndex(firstindex))}
            onLastIndex={(lastindex) => (setLastIndex(lastindex))}
          />
        </div>
      }
    </div>
  );
};

export default Referrals;
