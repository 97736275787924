import { useAppState } from "hooks/useStore";

export const CourseDescriptionEditor = () => {
  const { coursePreviewDetail } = useAppState((s: any) => s.course)

  return (
    <div className={"relative bg-white p-5"}>
      <div className={"relative w-full h-full overflow-hidden space-y-3"}>
        <div className={"w-full flex items-center justify-start gap-1"}>
          <div className="w-full flex flex-col justify-start items-start gap-1 md:flex-row md:items-center md:gap-3 pb-1 border-b">
            <span className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
              {"Class Description"}
            </span>
            <span className="text-base text-text-700 font-normal">
              ({"Class Details"})
            </span>
          </div>
        </div>
        <div className="p-3">
          <div
            className={""}
            dangerouslySetInnerHTML={{ __html: coursePreviewDetail?.data?.description }}
          >
          </div>
        </div>
      </div>
    </div>
  );
};
