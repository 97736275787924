import { cn } from "utils/cn.utils"
import { useState } from "react"
import { toast } from "react-toastify"

import { useAppDispatcher, useAppState } from "hooks/useStore"
import { modalConst } from "redux/local/local.const"
import { setModal } from "redux/local/local.slice"

const PostRequirementModal = () => {
    const { modal } = useAppState((s) => s.local)
    const dispatcher = useAppDispatcher()

    const [requirement, setRequirement] = useState("")

    const handleSubmitButtton = () => {
        if (requirement?.length > 0) {
            setRequirement("")
            toast.success("Thanks for your post")
        }
        dispatcher(setModal({
            ...modal,
            [modalConst.POST_REQUIREMENT_MODAL.stateKey]: false
        }))
    }

    return (
        <div className="space-y-5 overflow-x-hidden select-none pt-5">
            <span className='w-full flex justify-center items-center font-bodyPri font-semibold text-lg text-text-800 text-center truncate tracking-wide'>
                {"Requirement"}
            </span>
            <div className="min-h-[12rem] space-y-5 flex flex-col justify-between">
                <div className={"w-full"}>
                    <textarea
                        className={cn(
                            "w-full px-5 py-3 bg-background-light rounded-lg resize-none",
                            "outline-none border-2 border-primary-main focus:shadow-all-md focus:shadow-primary-light focus:border-primary-main",
                            "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                        )}
                        type={"text"}
                        placeholder="Write your Requirement ...."
                        value={requirement}
                        onChange={(event) => setRequirement(event.target.value)}
                        rows={4}
                        autoFocus={true}
                    />
                    {/* <div className={cn("flex justify-end",
                            characterLength === maxCharacterLength && "w-full flex justify-between items-center"
                        )}>
                            {characterLength === maxCharacterLength &&
                                <span className={"font-bodyPri font-normal text-sm text-red-500"}>
                                    {"Maximum character reached"}
                                </span>
                            }
                            <span className={"font-bodyPri font-normal text-text-800 text-xs"}>
                                {characterLength + "/" + maxCharacterLength}
                            </span>
                        </div> */}
                </div>
            </div>
            <div className={"flex justify-end"}>
                <span
                    className={cn(
                        "px-3 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base text-secondary-main",
                        "border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                    )}
                    onClick={handleSubmitButtton}
                >
                    {"Submit"}
                </span>
            </div>
        </div>
    )
}

export default PostRequirementModal