import { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import { cn } from "utils/cn.utils";

import { FaPlus } from 'react-icons/fa';
import { HiOutlineDocument } from 'react-icons/hi';
import { MdOutlinePreview } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';

import ToolTipView from 'components/tooltipView';

import { libraryTabConst } from 'components/modals/lmsModals/attachLibraryModal/data';
import { getThumbnailUrl } from '../../editLMSQuestion.data';

import { createLmsQuestionResource, deleteLmsQuestionResourceDetail } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetLocalTabs, setLocalTabs, setModal, setPreviewImageContent, resetPreviewImageContent } from 'redux/local/local.slice';
import { resetAddLmsQuestionOption, resetAddLmsQuestionOptionPayload, resetUpdateLmsQuestionAnswerPayload } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.slice';
import { setFilterProps } from 'redux/edulyteLms/lmsResource/lmsResource.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsResourceTypeEnum, resourceFileTypeEnum } from 'redux/edulyteLms/lmsResource/lmsResource.const';

import { getYouTubeVideoIdFromUrl } from 'utils/generators.utils';

const UploadSection = () => {
    const { modal, localTabs, previewImage } = useAppState((state) => state.local)
    const { filterProps } = useAppState((state) => state.lms.lmsResource)
    const { lmsQuestionDetail, addLmsQuestionOption, updateLmsQuestionAnswer, deleteLmsQuestionResource } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()

    const [hoverItem, setHoverItem] = useState(null)
    const [isDraggingOver, setIsDraggingOver] = useState(false)

    useEffect(() => {
        if (lmsQuestionDetail?.data && localTabs?.tabItem && (localTabs?.tabItem?.tabType === libraryTabConst.RESOURCES.value) && localTabs?.tabItem?.payload?.resourceId && !addLmsQuestionOption?.payload?.optionId && !updateLmsQuestionAnswer?.payload?.answerId) {
            const body = {
                question_id: lmsQuestionDetail?.data?.id,
                resource_id: localTabs?.tabItem?.payload?.resourceId
            }
            dispatcher(createLmsQuestionResource(body))
            dispatcher(resetLocalTabs())
            dispatcher(setModal({
                ...modal,
                [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: false
            }))
        }
    }, [localTabs])

    const onHandleAddMedia = useCallback(() => {
        dispatcher(resetLocalTabs())
        dispatcher(resetAddLmsQuestionOption())
        dispatcher(resetAddLmsQuestionOptionPayload())
        dispatcher(resetUpdateLmsQuestionAnswerPayload())
        dispatcher(setLocalTabs({
            ...localTabs,
            modalTitle: "Library",
            tabList: Object.values(libraryTabConst),
            activeTabList: [libraryTabConst.RESOURCES.value],
            currentActiveTab: libraryTabConst.RESOURCES.value,
        }))
        dispatcher(setFilterProps({
            filterProps: {
                ...filterProps,
                resourceType: lmsResourceTypeEnum.FILE.value,
                resourceFileType: resourceFileTypeEnum.IMAGE.value,
                disabledFilters: [lmsResourceTypeEnum.YOUTUBE.value, lmsResourceTypeEnum.LINK.value]
            }
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: true
        }))
    }, [modal, localTabs])

    const onHandlePreviewLink = useCallback((resourceUrl) => {
        dispatcher(resetPreviewImageContent())
        dispatcher(setPreviewImageContent({
            ...previewImage,
            previewUrl: resourceUrl
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.LMS_PREVIEW_IMAGE_MODAL.stateKey]: true
        }))
    }, [modal, previewImage])

    const onHandleDeleteResource = useCallback((resourceItem) => {
        if (lmsQuestionDetail?.isLoading || deleteLmsQuestionResource?.isLoading) return;

        dispatcher(deleteLmsQuestionResourceDetail(resourceItem?.id))
    }, [lmsQuestionDetail?.data, deleteLmsQuestionResource?.isLoading])

    const onHandleDragOver = (event) => {
        event.preventDefault();
        setIsDraggingOver(true)
    };

    const onHandleDragEnter = (event) => {
        event.preventDefault();
        setIsDraggingOver(true);
    };

    const onHandleDragLeave = (event) => {
        event.preventDefault();
        setIsDraggingOver(false);
    };


    const onHandleDrop = async (event) => {
        event.preventDefault();

        setIsDraggingOver(false)
        let parsedData = {}
        try {
            parsedData = JSON.parse(event.dataTransfer.getData("application/json"))
        } catch (error) {
            console.error(error)
            toast.error("Something went wrong!")
        }

        if (parsedData) {
            if (lmsQuestionDetail?.data?.question_resources?.length > 0) {
                const filteredQuestionResources = lmsQuestionDetail?.data?.question_resources?.filter((questionResource) => (questionResource?.resource?.id === parsedData?.id))
                if (filteredQuestionResources?.length > 0) {
                    alert("Uh-oh! You've already added this image. No duplicates, please!")
                    return;
                }
            }
            const body = {
                question_id: lmsQuestionDetail?.data?.id,
                resource_id: parsedData?.id
            }
            dispatcher(createLmsQuestionResource(body))
        }
    }

    return (
        <div className='w-full flex flex-col gap-5 bg-white px-4 py-3 rounded-lg font-bodyPri'>
            <p className='text-text-900 text-lg tracking-wide'>
                {"Attach"}
            </p>
            <div className="flex flex-col p-0 sm:px-4 sm:py-3 rounded items-start justify-start gap-5">
                {(lmsQuestionDetail?.data?.question_resources?.length > 0) &&
                    <div className={"w-full flex flex-wrap items-center gap-5"}>
                        {lmsQuestionDetail?.data?.question_resources?.map((questionResource, index) => {
                            let resourceUrl = null
                            if ([lmsResourceTypeEnum.FILE.value]?.includes(questionResource?.resource?.type)) {
                                resourceUrl = questionResource?.resource?.resource?.file?.file_url
                            } else {
                                resourceUrl = questionResource?.resource?.resource?.resource_link
                            }
                            return (
                                <div
                                    key={index}
                                    className={cn(
                                        "relative flex flex-col content-between justify-between rounded-lg shadow-lg h-20 aspect-square test-xs cursor-pointer",
                                        "hover:scale-105 hover:transition duration-200 hover:shadow-lg",
                                        "hover:ring-offset-2 hover:ring-4 hover:ring-secondary-dark",
                                        [lmsResourceTypeEnum.FILE.value]?.includes(questionResource?.resource?.type) &&
                                        ([resourceFileTypeEnum?.IMAGE?.value]?.includes(questionResource?.resource?.resource?.type)) && "bg-black",
                                        [lmsResourceTypeEnum.FILE.value]?.includes(questionResource?.resource?.type) &&
                                        (![resourceFileTypeEnum?.IMAGE?.value]?.includes(questionResource?.resource?.resource?.type)) && "bg-sky-200",
                                        [lmsResourceTypeEnum.YOUTUBE.value]?.includes(questionResource?.resource?.type) && "bg-black",
                                        [lmsResourceTypeEnum.LINK.value]?.includes(questionResource?.resource?.type) && "bg-sky-200",
                                    )}
                                    onMouseOver={() => setHoverItem(index)}
                                    onMouseOut={() => setHoverItem(null)}
                                >
                                    {[lmsResourceTypeEnum.FILE.value]?.includes(questionResource?.resource?.type)
                                        && ([resourceFileTypeEnum?.IMAGE?.value]?.includes(questionResource?.resource?.resource?.type))
                                        && <img
                                            src={resourceUrl}
                                            alt={questionResource?.resource?.title}
                                            className={"object-cover w-full h-full rounded-lg opacity-90"}
                                        />
                                    }
                                    {[lmsResourceTypeEnum.FILE.value]?.includes(questionResource?.resource?.type)
                                        && (![resourceFileTypeEnum?.IMAGE?.value]?.includes(questionResource?.resource?.resource?.type))
                                        && <div className={"mx-0.5 w-full h-full flex items-end justify-start gap-0.5 overflow-hidden"}>
                                            <span className={""}>
                                                <HiOutlineDocument className={"text-md text-text-600"} />
                                            </span>
                                            <span className={"w-fit font-bodyPri font-normal text-xs text-text-600 truncate"}>
                                                {(questionResource?.resource?.title?.length > 8)
                                                    ? questionResource?.resource?.title?.slice(0, 8) + "..."
                                                    : questionResource?.resource?.title}
                                            </span>
                                        </div>
                                    }
                                    {[lmsResourceTypeEnum.YOUTUBE.value]?.includes(questionResource?.resource?.type) &&
                                        <img
                                            src={getThumbnailUrl(getYouTubeVideoIdFromUrl(resourceUrl))}
                                            alt={'Video Thumbnail'}
                                            className={"object-cover w-full h-full rounded-lg opacity-90"}
                                        />
                                    }
                                    {[lmsResourceTypeEnum.LINK.value]?.includes(questionResource?.resource?.type) &&
                                        <div className={"mx-0.5 w-full h-full flex items-end justify-start gap-0.5 overflow-hidden"}>
                                            <span className={""}>
                                                <HiOutlineDocument className={"text-md text-text-600"} />
                                            </span>
                                            <span className={"w-fit font-bodyPri font-normal text-xs text-text-600 truncate"}>
                                                {(questionResource?.resource?.title?.length > 8)
                                                    ? questionResource?.resource?.title?.slice(0, 8) + "..."
                                                    : questionResource?.resource?.title}
                                            </span>
                                        </div>
                                    }
                                    {(hoverItem === index) &&
                                        <div className={"absolute w-full h-full rounded-lg overflow-hidden flex justify-center items-center"}>
                                            <div className={"px-2 py-0.5 flex items-center justify-center gap-3 bg-white rounded-lg"}>
                                                <ToolTipView content={"Preview Image"}>
                                                    <button className={"group"} onClick={() => onHandlePreviewLink(resourceUrl)}>
                                                        <MdOutlinePreview className={"text-text-700 group-hover:text-primary-dark text-xl"} />
                                                    </button>
                                                </ToolTipView>
                                                <ToolTipView content={"Remove Image"}>
                                                    <button className={"group"} onClick={() => onHandleDeleteResource(questionResource)}>
                                                        <RiDeleteBin6Line className={"text-xl text-text-700 hover:text-red-500 cursor-pointer"} />
                                                    </button>
                                                </ToolTipView>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })}
                    </div>
                }
                <button
                    className={cn(
                        'flex justify-center items-center gap-1 bg-white rounded-full px-5 py-1',
                        'border border-divider-dark hover:border-primary-dark hover:text-primary-dark',
                        'font-bodyPri font-normal text-text-900 text-base tracking-wide',
                        isDraggingOver && "bg-divider-darkLight"
                    )}
                    onDragOver={onHandleDragOver}
                    onDragEnter={onHandleDragEnter}
                    onDragLeave={onHandleDragLeave}
                    onDrop={onHandleDrop}
                    onClick={onHandleAddMedia}
                >
                    <span className={""}>
                        <FaPlus className={"text-lg text-text-700"} />
                    </span>
                    <span className={""}>
                        {"Add or Drag Resource(s)"}
                    </span>
                </button>
            </div>
        </div>

    );
};

export default UploadSection;