import ButtonContainer from "../ButtonContainer";
import PreviewCourseDetails from "./components/PreviewCourseDetails";

const PreviewAndStatusPage = ({ stepCount, setStepCount }) => {
    return (
        <div className={"space-y-5"}>
            <ButtonContainer stepCount={stepCount} setStepCount={setStepCount} />
            <PreviewCourseDetails />
        </div>
    )
}

export default PreviewAndStatusPage