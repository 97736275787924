import tw, { styled } from "twin.macro";

export const StyledReportUser = styled.div`
  ${tw`absolute top[50%] left[50%] transform[translate(-50%, -50%)] bg-white text-primary-main p-5 rounded-md w-max z-30`}
  p {
    ${tw`font-semibold mb-5 pb-2 text-center border-b border-dotted border-primary-main`}
  }

  button {
    ${tw`text-center py-1.5 px-7 text-sm font-semibold cursor-pointer text-primary-main mb-1.5 bg-primary-main bg-opacity-20 rounded-md`}
    &:first-of-type {
      ${tw`mr-5 bg-secondary-main text-white`}
    }
  }
`;
