export const url = {
    MY_PROFILE: "/users/me",
    USER_DETAIL: "/users/{userId}/details",
    USER_CULTURE_DETAIL: "/users/{userId}/cultures",
    USER_EDUCATION_LIST: "/users/{userId}/educations",
    EDUCATION_DETAIL: "/users/educations/{userEducationId}",
    USER_OTHER_PREF_DETAIL: "/users/{userId}/other-prefs",
    USER_SOCIAL_LIST: "users/{userId}/socials",
    USER_SOCIAL_DETAIL: "users/socials/{userSocialId}",
    USER_ADDRESS_LIST: "users/{userId}/addresses",
    USER_ADDRESS_DETAIL: "users/addresses/{userAddressId}",

    GET_USER_LIST: "users/users",
    UPDATE_USER_CONFIG: "users/users/{userId}/config"
}

export const userWsUrl = {
    PUBLIC_USER_DETAIL: "/users/users/{username}/public"
}



export const USER_DEMO_PROFILE = "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRaw"

export const addUserDetailPayload = {
    profilePicUrl: USER_DEMO_PROFILE,
    title: "",
    firstName: "",
    lastName: "",
    preferredName: "",
    gender: "",
    dateOfBirth: new Date()
}

export const addUserCultureDetailPayload = {
    livingIn: "",
    livingFrom: ""
}

export const blueTickEnum = {
    YES: {
        label: "Yes",
        value: "yes"
    },
    NO: {
        label: "No",
        value: 'no'
    }
}