import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { cn } from "utils/cn.utils";

import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";

import { questionType } from "components/modals/questionAnswerModal/data";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setUserOnboardingQuestionDetail } from "redux/onboarding/onboarding.slice";

const MAX_CHARACTER_LENGTH = 500

const QuestionContainer = ({ questionDetail, isFormDisabled }) => {
    const { userOnboardingQuestionDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()

    const [inputSkill, setInputSkill] = useState("")
    const [localResponse, setLocalResponse] = useState([])

    useEffect(() => {
        setLocalResponse(questionDetail?.response?.length > 0 ? questionDetail?.response?.split("|") : [])
    }, [questionDetail?.response])

    const handleOption = (selectedOption) => {
        let myResponse = []
        if (questionDetail?.questionType === questionType?.MULTIPLE_TEXT?.value) {
            if (localResponse?.includes(selectedOption)) {
                toast.error("Duplicate skills not allowed!")
                return;
            }
            myResponse = [...localResponse, selectedOption]
            setLocalResponse([...localResponse, selectedOption])
        }

        if (questionDetail?.questionType === questionType?.SINGLE_TEXT?.value) {
            myResponse = [selectedOption]
            setLocalResponse([selectedOption])
        }

        if (questionDetail?.questionType === questionType?.SINGLE_CHOICE?.value) {
            if (localResponse?.includes(selectedOption)) return;

            myResponse = [selectedOption]
            setLocalResponse([selectedOption])
        }

        if (questionDetail?.questionType === questionType?.MULTIPLE_CHOICE?.value) {
            if (localResponse?.includes(selectedOption)) {
                myResponse = localResponse?.filter((response) => response !== selectedOption)
                setLocalResponse(localResponse?.filter((response) => response !== selectedOption))
            } else {
                myResponse = [...localResponse, selectedOption]
                setLocalResponse([...localResponse, selectedOption])
            }
        }

        let newQuestions = userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.questions?.map((question) => {
            if (question?.questionResponseId !== questionDetail?.questionResponseId) return question;
            return { ...question, response: myResponse?.join("|") }
        })
        dispatcher(setUserOnboardingQuestionDetail({
            ...userOnboardingQuestionDetail?.userOnboardingQuestionDetail,
            questions: newQuestions
        }))
    }

    const onAddMultipleText = (event) => {
        if (event.keyCode === 13 && inputSkill.trim()) {
            if (!inputSkill || inputSkill?.split(" ")?.length > 3) {
                toast.error("Skills can have upto 3 words")
                return;
            }
            const skillTag = inputSkill.trim()
            setInputSkill("");
            const element = document.getElementById("skill");
            element.focus();
            handleOption(skillTag)
        }
    }


    const addMultipleTextOnMouse = () => {
        if (!inputSkill || inputSkill?.split(" ")?.length > 3) {
            toast.error("Skills can have upto 3 words")
            return;
        }
        const skillTag = inputSkill.trim()
        setInputSkill("");
        const element = document.getElementById("skill");
        element.focus();
        handleOption(skillTag)
    }

    const onDeleteMultipleText = (selectedSkill) => {
        if (isFormDisabled) return;
        let myResponse = localResponse?.filter((response) => response !== selectedSkill)
        setLocalResponse(localResponse?.filter((response) => response !== selectedSkill))

        let newQuestions = userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.questions?.map((question) => {
            if (question?.questionResponseId !== questionDetail?.questionResponseId) return question;
            return { ...question, response: myResponse?.join("|") }
        })

        dispatcher(setUserOnboardingQuestionDetail({
            ...userOnboardingQuestionDetail?.userOnboardingQuestionDetail,
            questions: newQuestions
        }))
        const element = document.getElementById("skill");
        element.focus();
    }

    return (
        <div className={"flex flex-col justify-items-start gap-3"}>
            <div className="flex justify-start gap-1">
                <span className="text-red-400">
                    {"*"}
                </span>
                <span className="font-bodyPri font-medium text-sm md:text-lg text-text-900">
                    {questionDetail?.question}
                </span>
            </div>
            {questionDetail?.questionType === questionType?.MULTIPLE_TEXT?.value &&
                <div className={cn(
                    "w-full py-2 px-4 flex flex-wrap gap-3 border-2 border-divider-medium rounded-md",
                    "focus:outline-none focus:border-2 focus:border-text-400"
                )}>
                    {localResponse.map((skill, index) => (
                        <div className={cn(
                            "w-max px-4 py-1 flex items-center gap-1 rounded-xl bg-blue-100",
                            "font-bodyPri font-medium text-sm md:text-base text-blue-600 tracking-wide"
                        )}
                            key={index}
                        >
                            <span>
                                {skill}
                            </span>
                            <AiOutlineClose
                                onClick={() => onDeleteMultipleText(skill)}
                                className={"text-lg cursor-pointer"}
                            />
                        </div>
                    ))}
                    <div className={"flex items-center justify-start gap-1.5"}>
                        <input
                            className={"w-40 h-10 rounded-lg px-2 py-0 border-2 border-text-100 focus:outline-none"}
                            type={"text"}
                            id={"skill"}
                            placeholder={"Add Skills"}
                            autoFocus={true}
                            value={inputSkill}
                            onChange={(e) => setInputSkill(e.target.value)}
                            onKeyDown={onAddMultipleText}
                            disabled={isFormDisabled}
                        />
                        <AiOutlineCheckCircle className={"text-2xl text-text-800 cursor-pointer"} onClick={addMultipleTextOnMouse} />
                    </div>
                </div>
            }
            {(questionDetail?.questionType === questionType?.SINGLE_TEXT?.value) &&
                <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                    <div className="w-full flex flex-col justify-center gap-1">
                        <textarea
                            className="w-full px-4 py-2 resize-none gap-3 border-2 border-divider-medium focus:outline-none focus:border-divider-lightDark rounded-md font-bodyPri font-normal text-lg text-text-600 tracking-wide"
                            rows={5}
                            // minLength={50}
                            maxLength={500}
                            value={localResponse?.length > 0 ? localResponse[0] : ""}
                            onChange={(event) => handleOption(event.target.value)}
                            disabled={isFormDisabled}
                        />
                    </div>
                    <div className={"w-full flex justify-between"}>
                        <div>
                            {localResponse && localResponse[0]?.length < 20 &&
                                <span className="font-bodyPri font-normal tracking-wide text-red-500 text-xs">
                                    {"Please enter between 20 and 500 characters"}
                                </span>
                            }
                        </div>
                        <span className="flex justify-end font-bodyPri font-normal text-text-800 text-xs">
                            {((localResponse && localResponse[0]?.length > 0) ? localResponse[0]?.length : 0) + "/" + MAX_CHARACTER_LENGTH}
                        </span>
                    </div>
                </div>
            }
            {questionDetail?.questionType === questionType?.SINGLE_CHOICE?.value &&
                questionDetail?.options?.map((option, index) => (
                    <div className={"flex flex-col items-start justify-start gap-3"} key={index}>
                        <div className={"flex items-center gap-3"}>
                            <input
                                type={"radio"}
                                onChange={(event) => handleOption(event?.target?.value)}
                                className={"border-2 border-divider-medium hover:border-divider-dark cursor-pointer"}
                                name={questionDetail?.questionResponseId?.toString()}
                                defaultValue={option?.option}
                                checked={localResponse?.includes(option?.option) && true}
                                disabled={isFormDisabled}
                            />
                            <label className={"font-bodyPri font-normal text-sm md:text-base text-text-900"}>
                                {option?.option}
                            </label>
                        </div>
                    </div>
                ))}
            {questionDetail?.questionType === questionType.MULTIPLE_CHOICE.value &&
                questionDetail?.options?.map((option, index) => (
                    <div className={"flex flex-col items-start justify-start gap-3"} key={index}>
                        <div className={"flex items-center gap-3"}>
                            <input
                                type={"checkbox"}
                                onChange={(event) => handleOption(event?.target?.value)}
                                className={"border-2 border-divider-medium hover:border-divider-dark cursor-pointer"}
                                name={questionDetail?.questionResponseId?.toString()}
                                defaultValue={option?.option}
                                checked={localResponse?.includes(option?.option) && true}
                                disabled={isFormDisabled}
                            />
                            <label className={"font-bodyPri font-normal text-sm md:text-base text-text-900"}>
                                {option?.option}
                            </label>
                        </div>
                    </div>
                ))}
        </div>
    )
}

export default QuestionContainer;