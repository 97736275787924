import { useEffect, useRef, useState, memo } from 'react'

import { MdArrowLeft, MdArrowRight } from "react-icons/md";

import { downloadPreviewTypeEnum } from 'redux/downloads/downloads.const';

const SliderNavigation = memo(({ sliderData = [], currentSlide, goToSlide }) => {

    const [showNavButtons, setShowNavButtons] = useState(false);
    const scrollRef = useRef(null);
    const containerRef = useRef(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: -100, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: 100, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const checkNavButtonsVisibility = () => {
            if (scrollRef.current && containerRef.current) {
                const scrollWidth = scrollRef.current.scrollWidth;
                const containerWidth = containerRef.current.offsetWidth;
                setShowNavButtons(scrollWidth > containerWidth);
            }
        };

        checkNavButtonsVisibility();
        window.addEventListener('resize', checkNavButtonsVisibility);

        return () => {
            window.removeEventListener('resize', checkNavButtonsVisibility);
        };
    }, [sliderData]);

    return (
        <div className='w-full flex justify-center mt-4 gap-1' ref={containerRef}>
            {showNavButtons && (
                <button className='h-12 md:h-16 w-4 flex justify-center items-center bg-background-darkLight bg-opacity-70 hover:bg-primary-light rounded-sm' onClick={scrollLeft}>
                    <MdArrowLeft size={20} color='black' />
                </button>
            )}
            <div className='grid grid-flow-col gap-2 overflow-hidden overflow-x-scroll scrollbar-none' ref={scrollRef}>
                {sliderData?.map((item, index) => (
                    <div key={index} className={`w-12 md:w-16 h-12 md:h-16 cursor-pointer ${currentSlide === index ? 'border-primary' : 'border-gray-300'}`}>
                        {(item?.type === downloadPreviewTypeEnum.IMAGE.value) && (
                            <img
                                className={`h-full w-full object-cover rounded-lg border-2 transition-all duration-300 
                                            ${currentSlide === index ? 'border-primary-main' : 'border-gray-300 hover:border-primary-main'}`}
                                src={item.preview_url}
                                onClick={() => goToSlide(index)}
                            />
                        )}
                        {(item?.type === downloadPreviewTypeEnum.VIDEO.value) && (
                            <video
                                className={`h-full w-full object-cover rounded-lg border-2 transition-all duration-300 
                                            ${currentSlide === index ? 'border-primary-main' : 'border-gray-300 hover:border-primary-main'}`}
                                src={item.preview_url}
                                controls={false}
                                onClick={() => goToSlide(index)}
                            />
                        )}
                    </div>
                ))}
            </div>
            {showNavButtons && (
                <button className='h-12 md:h-16 w-4 flex justify-center items-center bg-background-darkLight bg-opacity-70 hover:bg-primary-light rounded-sm' onClick={scrollRight}>
                    <MdArrowRight size={20} color='black' />
                </button>
            )}
        </div>
    )
})

export default SliderNavigation;