import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAppDispatcher, useAppState } from 'hooks/useStore'

import { setPageInfo } from 'redux/pageInfo/pageInfo.request'
import { verifySignup as getVerifySignup, signupS2 as createSignupS2 } from 'redux/auth/auth.request'
import { resetVerifySignup } from 'redux/auth/auth.slice'

import { pagesInfo } from 'utils/pagesInfo'
import { timeZone } from 'utils/dateTime.utils'
import FullPageLoader from 'components/loader/FullPageLoader'
import { cn } from 'utils/cn.utils'


const VerifySignup = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { session, verifySignup, signupS2 } = useAppState(s => s.auth)
    const {user} = useAppState(s => s.user)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location?.search?.toString())

    const [requireData, setRequireData] = useState({
        token: "",
        email: "",
        first_name: "",
        last_name: "",
        timeZone: ""
    })

    const from = location.state?.from?.url || pagesInfo.DASHBOARD.pagePath;

    const isSubmitEnable = signupS2?.isLoading || user?.isLoading

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.VERIFY_SIGNUP))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        dispatcher(getVerifySignup({ token: queryParams?.get("token") }))
    }, [queryParams?.get("token")])

    useEffect(() => {
        return () => {
            resetVerifySignup()
        }
    }, [])

    useEffect(() => {
        if (!!verifySignup?.data) {
            setRequireData({
                ...requireData,
                token: queryParams?.get("token"),
                email: verifySignup?.data?.email,
                first_name: verifySignup?.data?.optional_data?.first_name || "",
                last_name: verifySignup?.data?.optional_data?.first_name || "",
                timeZone: timeZone,
            })
        }
    }, [verifySignup?.data])

    if (!queryParams?.get("token")) {
        if (!!queryParams?.get("redirect_url")) {
            window.open(queryParams?.get("redirect_url"), "_self")
        } else {
            navigate(from, { replace: true })
        }
    }

    if (session?.isLoggedIn) {
        if (!!queryParams?.get("redirect_url")) {
            window.open(queryParams?.get("redirect_url"), "_self")
        } else {
            navigate(from, { replace: true })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const requestPayload = {
            token: requireData?.token,
            email: requireData?.email,
            first_name: requireData?.first_name,
            last_name: requireData?.last_name,
            timeZone: requireData?.timeZone
        }
        dispatcher(createSignupS2(requestPayload))
    }

    if (verifySignup?.isLoading) {
        return (
            <FullPageLoader isLoading={true} />
        )
    }

    if (!!verifySignup?.message) {
        return (
            <div className={"min-h-screen flex items-center justify-center"}>
                <div className={"max-w-md w-full p-5 text-center rounded-lg shadow-all bg-white"}>
                    <h2 className="font-bodyPri font-semibold text-2xl">{"Message"}</h2>
                    <p className={"font-bodyPri font-normal text-md"}>
                        {verifySignup?.message}
                    </p>
                </div>
            </div>
        )
    }

    return verifySignup?.data ? (
        <div className={"w-full min-h-screen flex justify-center items-center"}>
            <div className={"max-w-full sm:max-w-lg md:max-w-xl lg:max-w-2xl w-full px-5 py-8 rounded-lg bg-white"}>
                <form onSubmit={handleSubmit} className={"w-full flex flex-col items-center justify-center gap-3"}>
                    <h2 className={"font-bodyPri font-semibold text-2xl text-text-900 text-center mb-5"}>
                        {"Enter Your Detail To Verify Your account"}
                    </h2>
                    <div className={cn(
                        "relative w-4/5 group flex items-center justify-start bg-white"
                    )}>
                        <input
                            id={"email"}
                            name={"email"}
                            type={"email"}
                            value={requireData?.email}
                            onChange={(event) => setRequireData({ ...requireData, [event.target.name]: event.target.value })}
                            required={true}
                            disabled={true}
                            className={cn(
                                "relative overflow-hidden w-full px-5 py-2 focus:outline-none bg-transparent",
                                "transition ease-in-out duration-300",
                                "border-2 border-text-500 rounded-md",
                                "focus:border-primary-main",
                                requireData?.email && "!border-primary-main",
                            )}
                        />
                        <label for={"email"} className={cn(
                            "absolute z-1 left-3 whitespace-nowrap cursor-text",
                            "transition ease-in-out duration-300",
                            "font-bodyPri font-normal text-base text-text-500",
                            "group-focus-within:-translate-y-5 group-focus-within:scale-75 group-focus-within:text-primary-main",
                            "group-focus-within:bg-white group-focus-within:px-1 group-focus-within:py-0 group-focus-within:cursor-default",
                            requireData?.email && "-translate-y-5 scale-75 !text-primary-main",
                            requireData?.email && "bg-white px-1 py-0 cursor-default",
                        )}>
                            Email
                        </label>
                    </div>
                    <div className={cn(
                        "relative w-4/5 group flex items-center justify-start bg-white"
                    )}>
                        <input
                            id={"first_name"}
                            name={"first_name"}
                            type={"text"}
                            value={requireData?.first_name}
                            onChange={(event) => setRequireData({ ...requireData, [event.target.name]: event.target.value })}
                            required={true}
                            disabled={isSubmitEnable}
                            className={cn(
                                "relative overflow-hidden w-full px-5 py-2 focus:outline-none bg-transparent",
                                "transition ease-in-out duration-300",
                                "border-2 border-text-500 rounded-md",
                                "focus:border-primary-main",
                                requireData?.first_name && "!border-primary-main",
                            )}
                        />
                        <label for={"first_name"} className={cn(
                            "absolute z-1 left-3 whitespace-nowrap cursor-text",
                            "transition ease-in-out duration-300",
                            "font-bodyPri font-normal text-base text-text-500",
                            "group-focus-within:-translate-y-5 group-focus-within:scale-75 group-focus-within:text-primary-main",
                            "group-focus-within:bg-white group-focus-within:px-1 group-focus-within:py-0 group-focus-within:cursor-default",
                            requireData?.first_name && "-translate-y-5 scale-75 !text-primary-main",
                            requireData?.first_name && "bg-white px-1 py-0 cursor-default",
                        )}>
                            First Name
                        </label>
                    </div>
                    <div className={cn(
                        "relative w-4/5 group flex items-center justify-start bg-white"
                    )}>
                        <input
                            id={"last_name"}
                            name={"last_name"}
                            type={"text"}
                            value={requireData?.last_name}
                            onChange={(event) => setRequireData({ ...requireData, [event.target.name]: event.target.value })}
                            required={true}
                            disabled={isSubmitEnable}
                            className={cn(
                                "relative overflow-hidden w-full px-5 py-2 focus:outline-none bg-transparent",
                                "transition ease-in-out duration-300",
                                "border-2 border-text-500 rounded-md",
                                "focus:border-primary-main",
                                requireData?.last_name && "!border-primary-main",
                            )}
                        />
                        <label for={"last_name"} className={cn(
                            "absolute z-1 left-3 whitespace-nowrap cursor-text",
                            "transition ease-in-out duration-300",
                            "font-bodyPri font-normal text-base text-text-500",
                            "group-focus-within:-translate-y-5 group-focus-within:scale-75 group-focus-within:text-primary-main",
                            "group-focus-within:bg-white group-focus-within:px-1 group-focus-within:py-0 group-focus-within:cursor-default",
                            requireData?.last_name && "-translate-y-5 scale-75 !text-primary-main",
                            requireData?.last_name && "bg-white px-1 py-0 cursor-default",
                        )}>
                            Last Name
                        </label>
                    </div>
                    {!!signupS2?.message && (
                        <div className={"w-4/5 text-start font-bodyPri font-normal text-sm text-secondary-dark"}>
                            <span>{signupS2?.message}</span>
                        </div>
                    )}
                    <div className={cn(
                        "relative w-4/5 flex items-center"
                    )}>
                        <input
                            type={"submit"}
                            value={isSubmitEnable ? "Loading..." : "Submit"}
                            className={cn(
                                "w-full py-2 px-5 rounded-lg bg-secondary-dark hover:bg-secondary-main cursor-pointer",
                                "font-buttons font-medium text-md text-text-50",
                                isSubmitEnable && "cursor-wait"
                            )}
                            disabled={isSubmitEnable}
                        />
                    </div>
                </form>
            </div>
        </div>
    ) : null
}

export default VerifySignup