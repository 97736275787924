import { cn } from "utils/cn.utils";
import { Route, Routes } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';

import { sidebarTools } from 'pages/auth/tools/tools.data';

import Offers from 'pages/auth/offersAndDiscount/index';

import { useAppState } from 'hooks/useStore';


export const EducatorToolLinksSideBar = () => {
    const { locals } = useAppState((s) => s.local)

    return (
        <div className={cn(
            'relative w-full min-h-full bg-white shadow-sm rounded-lg px-5 py-3 md:p-0'
        )}>
            <div className='absolute flex justify-center w-full -top-3'>
                <span className={'text-sm max-w-xs px-3 py-1 text-white uppercase bg-primary-main rounded-2xl'}>
                    {"Popular Links"}
                </span>
            </div>
            <div className={cn(
                'grid gap-4 px-4 py-5 pt-10 grid-flow-col md:grid-flow-row',
                "overflow-x-auto md:overflow-hidden",
                "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm scrollbar-h-sm",
                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
            )}>
                {sidebarTools.map((link, index) => link.roles.includes(locals.userRole) && (
                    <NavHashLink to={link.path} key={index} className={cn(
                        "border border-primary-main text-primary-main",
                        "bg-white rounded-2xl shadow-sm text-center py-1",
                        "hover:bg-primary-main hover:text-white h-min",
                        "active:ring active:ring-primary-main ring-offset-2 cursor-pointer",
                        "self-center whitespace-nowrap px-2"
                    )}>
                        {link.label}
                        <Routes>
                            <Route path='/offers' element={<Offers />}></Route>
                        </Routes>
                    </NavHashLink>
                ))}
            </div>
        </div>
    )
}
