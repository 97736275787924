import { useState, useCallback, memo } from "react";
import { toast } from 'react-toastify';

import { FiX } from 'react-icons/fi';

import ComponentLoader from "components/loader/ComponentLoader";

import UploadIcon from 'pages/auth/edulyteLms/assets/uploadIcon.svg';

import { onHandleValidateFileDetails, acceptFileExtensionTypes } from "../data";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetAddUserDownloadDetailPayload, setAddUserDownloadDetailPayload } from "redux/downloads/downloads.slice";
import { profilePicTypeConst, videoFileTypeConst } from 'redux/storage/storage.const';
import { addUserDownloadDetailPayload } from 'redux/downloads/downloads.const';

import { cn } from 'utils/cn.utils';

const EmptyDownloadContent = memo(({ downloadType = "" }) => {
    const uploadFile = useAppState((state) => state.storage.uploadFile)
    const { addUserDownloadDetail, addDownloadPreviewDetail, addDownloadFileDetail } = useAppState((state) => state.downloads)

    const dispatcher = useAppDispatcher()

    const [isDraggingOver, setIsDraggingOver] = useState(false);

    const onHandleFileSelect = async (event) => {
        if (uploadFile?.isLoading) return;

        const { requestPayload, errorMsg } = await onHandleValidateFileDetails(event.target.files, downloadType)
        if (errorMsg) {
            toast.error(errorMsg)
            return;
        }
        if (requestPayload) {
            dispatcher(setAddUserDownloadDetailPayload({
                ...addUserDownloadDetailPayload,
                ...requestPayload
            }))
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();

        setIsDraggingOver(true)
    };

    const handleDragEnter = (event) => {
        event.preventDefault();
        setIsDraggingOver(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setIsDraggingOver(false);
    };


    const handleDrop = async (event) => {
        event.preventDefault();
        if (uploadFile?.isLoading) return;

        setIsDraggingOver(true)
        const { requestPayload, errorMsg } = await onHandleValidateFileDetails(event.dataTransfer.files, downloadType)
        if (errorMsg) {
            toast.error(errorMsg)
            return;
        }
        dispatcher(setAddUserDownloadDetailPayload({
            ...addUserDownloadDetailPayload,
            ...requestPayload
        }))
    };

    const onHandlePasteFile = async (event) => {
        if (uploadFile?.isLoading) return;

        const clipboardData = event.clipboardData;

        const { requestPayload, errorMsg } = await onHandleValidateFileDetails(clipboardData.files, downloadType)
        if (errorMsg) {
            toast.error(errorMsg)
            return;
        }
        dispatcher(setAddUserDownloadDetailPayload({
            ...addUserDownloadDetailPayload,
            ...requestPayload
        }))
    };

    const handleRemoveFile = useCallback(() => {
        dispatcher(resetAddUserDownloadDetailPayload())
        setIsDraggingOver(false)
    }, []);

    if (uploadFile?.isLoading || addDownloadPreviewDetail?.isLoading || addDownloadFileDetail?.isLoading) {
        return (
            <ComponentLoader isLoading={uploadFile?.isLoading || addDownloadPreviewDetail?.isLoading || addDownloadFileDetail?.isLoading} />
        )
    }

    return (
        <div className={"w-full flex flex-col items-center justify-center gap-3"}>
            {!addUserDownloadDetail?.payload?.selectedFile &&
                <div
                    className={cn("relative",
                        (isDraggingOver || addUserDownloadDetail?.payload?.selectedFile) ? "bg-divider-darkLight" : ""
                    )}
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onPaste={onHandlePasteFile}
                    onClick={onHandleFileSelect}
                >
                    <label
                        htmlFor={"fileInput"}
                        className={"flex flex-col justify-center items-center cursor-pointer text-gray-500"}
                    >
                        <img
                            src={UploadIcon}
                            style={{ width: '100px' }}
                            alt={"Upload"}
                        />
                        {"Browse or drag a file here"}
                    </label>
                    <input
                        id={"fileInput"}
                        type={"file"}
                        style={{ display: "none" }}
                        accept={(downloadType === "preview") ? [...profilePicTypeConst, ...videoFileTypeConst] : acceptFileExtensionTypes}
                        onChange={onHandleFileSelect}
                    />
                </div>
            }
            {addUserDownloadDetail?.payload?.selectedFile &&
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"hidden sm:block max-w-[24rem] font-bodyPri font-medium text-text-800 text-base truncate"}>
                        {addUserDownloadDetail?.payload?.selectedFile?.file?.name}
                    </span>
                    <span className={"block sm:hidden font-bodyPri font-medium text-text-800 text-base whitespace-nowrap"}>
                        {(addUserDownloadDetail?.payload?.selectedFile?.file?.name?.length > 25)
                            ? addUserDownloadDetail?.payload?.selectedFile?.file?.name?.slice(0, 25) + " ..."
                            : addUserDownloadDetail?.payload?.selectedFile?.file?.name
                        }
                    </span>
                    <button
                        className={"p-0.5 bg-text-50 rounded-full cursor-pointer"}
                        onClick={handleRemoveFile}
                    >
                        <FiX size={15} className={"font-bold text-divider-dark"} />
                    </button>
                </div>
            }
        </div>
    )
})

export default EmptyDownloadContent;