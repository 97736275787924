import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { googleRecaptchaPageName } from 'utils/pagesInfo';

import { useAppState } from 'hooks/useStore';
import { GOOGLE_SITE_KEY } from 'const/default.const';

const RecaptchaProvider = ({ children }) => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)

  if (googleRecaptchaPageName?.includes(currentPageInfo?.pageName)) {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={GOOGLE_SITE_KEY}
        scriptProps={{
          async: false,
          defer: true,
          appendTo: 'head',
          nonce: undefined
        }}>
        {children}
      </GoogleReCaptchaProvider>
    )
  }

  return children;
}

export default RecaptchaProvider;