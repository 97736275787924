import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import ButtonLoader from "components/loader/ButtonLoader";
import ComponentLoader from "components/loader/ComponentLoader";
import QuestionContainer from "components/modals/questionAnswerModal/components/QuestionContainer";
import { questionType } from "./data";

import { getUserOnboardingQuestionDetailByOnboardingId, updateUserOnboardingQuestionDetailByOnboardingId } from "redux/onboarding/onboarding.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setClearAddUserOnboardingQuestionDetail, setClearUserOnboardingQuestionDetail } from "redux/onboarding/onboarding.slice";
import { onBoardingStatus, relevantQuestionStatus } from "redux/onboarding/onboarding.const";
import { modalConst } from "redux/local/local.const";

const QuestionAnswerModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { userOnboardingDetail, userOnboardingQuestionDetail, addUserOnboardingQuestionDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    const [isFormDisabled, setIsFormDisabled] = useState(false)

    useEffect(() => {
        if (onboardingId) {
            dispatcher(getUserOnboardingQuestionDetailByOnboardingId(Number(onboardingId)))
        }

        return () => {
            dispatcher(setClearUserOnboardingQuestionDetail())
        }
    }, [onboardingId])

    // use effect for modal close
    useEffect(() => {
        if (addUserOnboardingQuestionDetail?.addUserOnboardingQuestionDetail) {
            dispatcher(setModal({
                ...modal,
                [modalConst.QUESTION_ANSWER_MODAL.stateKey]: false
            }))
        }

        return () => {
            dispatcher(setClearAddUserOnboardingQuestionDetail())
        }
    }, [addUserOnboardingQuestionDetail?.addUserOnboardingQuestionDetail])

    useEffect(() => {
        if ([relevantQuestionStatus?.APPROVED?.value, relevantQuestionStatus?.REJECTED?.value]?.includes(userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.status) ||
            [onBoardingStatus?.APPROVED?.value, onBoardingStatus?.REJECTED?.value, onBoardingStatus?.BLOCKED?.value]?.includes(userOnboardingDetail?.userOnboardingDetail?.status)
        ) {
            setIsFormDisabled(true)
        }
    }, [userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.status, userOnboardingDetail?.userOnboardingDetail?.status])

    const onSubmit = (event) => {
        event.preventDefault()
        if (isFormDisabled) return;
        const filteredResponse = userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.questions?.filter((question) => question?.response === "")
        if (filteredResponse?.length > 0) {
            toast.error("All fields are required!")
            return;
        }
        const singleTextQuestion = userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.questions?.filter((question) => question?.questionType === questionType?.SINGLE_TEXT?.value && question?.response?.length < 20)
        if (singleTextQuestion?.length > 0) {
            toast.warn("Please enter between 20 and 500 characters")
            return;
        }

        const body = {
            questions: userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.questions?.map((question) => ({
                questionResponseId: question?.questionResponseId,
                response: question?.response
            }))
        }

        if (userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.status === relevantQuestionStatus?.PENDING?.value || userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.status === relevantQuestionStatus?.REJECTED?.value) {
            body["status"] = relevantQuestionStatus?.SUBMITTED?.value
        }

        dispatcher(updateUserOnboardingQuestionDetailByOnboardingId(Number(onboardingId), body))
    }

    if (userOnboardingQuestionDetail?.isLoading) {
        return (
            <ComponentLoader isLoading={userOnboardingQuestionDetail?.isLoading} className={"min-h-[56rem]"} />
        )
    }

    if (addUserOnboardingQuestionDetail?.errorMsg) {
        return (
            <div className={"w-full min-h-[48rem] flex items-center justify-center font-bodyPri font-normal text-text-900 text-base"}>
                {addUserOnboardingQuestionDetail?.errorMsg}
            </div>
        )
    }

    return (
        <div className={"space-y-5 overflow-x-hidden select-none p-5"}>
            <div className={"min-h-[24rem] space-y-8"}>
                {userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.questions?.map((questionDetail, index) => (
                    <QuestionContainer questionDetail={questionDetail} isFormDisabled={isFormDisabled} key={index} />
                ))}
            </div>
            <div className={"flex justify-end"}>
                <button
                    onClick={onSubmit}
                    disabled={isFormDisabled}
                    className={cn(
                        "w-28 py-1 focus:outline-none flex justify-center items-center rounded-full",
                        "font-buttons font-normal text-base",
                        !isFormDisabled && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                        addUserOnboardingQuestionDetail?.isLoading && "bg-secondary-main",
                        isFormDisabled && "text-text-300 border border-text-300 cursor-not-allowed"
                    )}>
                    {addUserOnboardingQuestionDetail?.isLoading &&
                        <ButtonLoader isLoading={addUserOnboardingQuestionDetail?.isLoading} />
                    }
                    {!addUserOnboardingQuestionDetail?.isLoading && "Submit"}
                </button>
            </div>
        </div>
    )
}

export default QuestionAnswerModal;