import { useEffect, useMemo } from 'react';
import { Navigate, useLocation, Outlet } from "react-router-dom";

import PageHeader from "components/pageHeader/PageHeader";

import PreferencesNavigation from './commonComponents/navigations/PreferencesNavigation';
import { pageHeaderLabel, pageHeading, preferencesTabConst, weeklyScheduleToolTip } from 'pages/auth/preferences/data';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useTitle } from 'hooks/useTitle';
import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const PreferencesPage = ({ allowedRoles }) => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { locals } = useAppState((s) => s.local)
  const { session } = useAppState((s) => s.auth)
  const { user } = useAppState((s) => s.user)

  const dispatcher = useAppDispatcher()
  const location = useLocation()
  const [title, setTitle] = useTitle()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.PREFERENCES))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    setTitle({
      ...title,
      title: "My Preferences | Edulyte"
    })
  }, [dispatcher, currentPageInfo])

  const filteredOptions = useMemo(() => {
    if (locals.userRole) {
      return Object.values(preferencesTabConst).filter((option) => option.roles.includes(locals.userRole) && option.isShowNavigation)
    } else return []
  }, [locals.userRole])

  const pageHeaderContent = useMemo(() => {
    if (location.pathname.startsWith(`${pagesInfo.PREFERENCES.pagePath}${preferencesTabConst.AVAILABILITY.pagePath}`)) {
      return { ...pageHeading, heading: `Preferences / Availability` };
    }
    else if (pageHeaderLabel[location.pathname]) {
      return { ...pageHeading, heading: `Preferences / ${pageHeaderLabel[location.pathname]?.label}` };
    } else {
      return pageHeading;
    }
  }, [location.pathname])

  return (
    <div className="h-full w-full p-3 mx-auto min-h-screen space-y-3">
      <PageHeader pageHeading={pageHeaderContent} />
      <div className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3 ">
        <PreferencesNavigation options={filteredOptions} />
        <div className="w-full rounded-lg overflow-hidden p-5 bg-white">
          {session?.isLoggedIn && user?.user?.roles?.find(role => allowedRoles?.includes(role.role) && role.role === locals.userRole)
            ? <Outlet />
            : <Navigate to={pagesInfo.UNAUTHORIZED.pagePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />
          }
        </div>
      </div>
    </div>
  )
};


export default PreferencesPage;