import { useEffect, useState } from "react";
import { cn } from "utils/cn.utils"

import QuillEditor from 'components/textEditor/QuillEditor'
import FadedCollapse from "components/fadedCollapse";

import { createLmsQuizQuestionResponseDetail, updateLmsQuizQuestionResponseDetail } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { lmsResourceTypeEnum, resourceFileTypeEnum } from "redux/edulyteLms/lmsResource/lmsResource.const";


const Editor = {
    modules: {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'size': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline'],
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'color': [] }, { 'background': [] }]
        ],
        clipboard: {
            matchVisual: false,
        }
    },
    formats: [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'blockquote', 'code-block',
        'list', 'bullet', 'indent',
        'color', 'background',
    ]
}

let timer;
const debounce = (fn, delay = 1000) => {
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            fn(...args)
        }, delay)
    }
}

const ParagraphView = ({ question, quiz_response }) => {
    const { addLmsQuizQuestionResponse, updateLmsQuizQuestionResponse } = useAppState(state => state.lms.lmsQuiz)

    const [inputStatus, setInputStatus] = useState("Saved")
    const [answerInput, setAnswerInput] = useState("")

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (addLmsQuizQuestionResponse?.data || updateLmsQuizQuestionResponse?.data) {
            setInputStatus("Saved")
        }
    }, [addLmsQuizQuestionResponse?.data, updateLmsQuizQuestionResponse?.data])

    const question_response = quiz_response?.quiz_question_responses?.find(question_response => question_response?.question === question?.id)

    const has_content = !!new DOMParser()?.parseFromString(`<div>${answerInput}</div>`, "text/html")?.querySelector("div")?.textContent

    useEffect(() => {
        setAnswerInput(!!question_response?.response?.length ? question_response?.response[0] : "")
    }, [question_response?.response])

    const onHandleSave = (value) => {
        if (!question_response) {
            setInputStatus("Saving...")
            let requestBody = {
                quiz_response_id: quiz_response?.id,
                question_id: question?.id,
                response: [value?.trim()]
            }
            dispatcher(createLmsQuizQuestionResponseDetail(requestBody))
        } else {
            setInputStatus("Saving...")
            let requestBody = { response: [value?.trim()] }
            dispatcher(updateLmsQuizQuestionResponseDetail(question_response?.id, requestBody))
        }
    }

    const onHandleChangeAnswer = (value) => {

        setInputStatus("Typing...")
        setAnswerInput(value)

        const delayFunction = debounce(onHandleSave)
        delayFunction(value)
    }

    if (quiz_response?.is_completed) {

        let has_answer_resource = !!(question?.answer?.answer_resources?.filter(answer_resource => (
            lmsResourceTypeEnum.FILE.value === answer_resource?.resource?.type &&
            [resourceFileTypeEnum.IMAGE.value, resourceFileTypeEnum.VIDEO.value].includes(answer_resource?.resource?.resource?.type)
        ))?.length)

        const feedbackText = new DOMParser()?.parseFromString(`<div>${question?.answer?.feedback || ""}</div>`, "text/html")?.querySelector("div")?.textContent

        return (
            <div className={"space-y-5"}>

                {/* options */}
                <div className={"space-y-1"}>
                    <span className={"font-bodyPri font-medium text-base text-text-800"}>
                        {"Answer submitted:"}
                    </span>
                    <div className={"px-3"}>
                        {has_content && <div dangerouslySetInnerHTML={{ __html: answerInput }}></div>}
                        {!has_content && (
                            <span className={"font-bodyPri font-normal text-base text-text-900"}>
                                {"--Blank--"}
                            </span>
                        )}
                    </div>
                </div>

                {/* Correct answer view */}
                {(!!question?.answer?.answer?.length || has_answer_resource) && (
                    <div className={"space-y-1"}>
                        <span className={"font-bodyPri font-medium text-base text-text-800"}>
                            {"Correct answer:"}
                        </span>
                        {!!question?.answer?.answer?.length && (
                            <div className={"px-3"}>
                                <div dangerouslySetInnerHTML={{ __html: question?.answer?.answer[0] }}></div>
                            </div>
                        )}
                        {has_answer_resource && (
                            <div className={"px-3 flex flex-wrap gap-2"}>
                                {question?.answer?.answer_resources?.filter(answer_resource => (
                                    lmsResourceTypeEnum.FILE.value === answer_resource?.resource?.type &&
                                    [resourceFileTypeEnum.IMAGE.value, resourceFileTypeEnum.VIDEO.value].includes(answer_resource?.resource?.resource?.type)
                                ))?.map((answer_resource, index) => (
                                    <div key={index}
                                        className={"w-40 aspect-video border-2 border-text-40 overflow-hidden rounded-lg"}
                                    >
                                        {answer_resource?.resource?.resource?.type === resourceFileTypeEnum.IMAGE.value && (
                                            <img
                                                src={answer_resource?.resource?.resource?.file?.file_url}
                                                alt={answer_resource?.resource?.title}
                                                className={"w-full h-full object-cover"}
                                            />
                                        )}
                                        {answer_resource?.resource?.resource?.type === resourceFileTypeEnum.VIDEO.value && (
                                            <video
                                                src={answer_resource?.resource?.resource?.file?.file_url}
                                                alt={answer_resource?.resource?.title}
                                                className={"w-full h-full object-cover"}
                                                allowFullScreen={false}
                                                autoPlay={false}
                                                controls
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}

                {/* feedback */}
                {!!feedbackText && (
                    <div className={"space-y-1"}>
                        <span className={"font-bodyPri font-medium text-base text-text-800"}>
                            {"Feedback:"}
                        </span>
                        <FadedCollapse
                            isShowMore={feedbackText?.length > 500}
                            isFaded={feedbackText?.length > 500}
                            maxHeight={feedbackText?.length > 500 ? "7rem" : "100%"}
                            wrapperStyle="px-3"
                        >
                            <div dangerouslySetInnerHTML={{ __html: question?.answer?.feedback }}></div>
                        </FadedCollapse>
                    </div>
                )}
            </div>

        )
    }

    return (
        <div className={"space-y-2"}>

            <div className={"flex flex-row items-center justify-between gap-5"}>
                <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                    {"Write your answer..."}
                </span>
            </div>

            <QuillEditor
                editorToolModules={Editor?.modules}
                editorToolFormats={Editor?.formats}
                className={cn("rounded border-2 border-text-40 focus-within:border-primary-dark")}
                editorText={answerInput}
                onHandleChangeText={onHandleChangeAnswer}
            />

            {(!!question_response || inputStatus === "Typing..." || addLmsQuizQuestionResponse?.isLoading || updateLmsQuizQuestionResponse?.isLoading) && (
                <div className={"flex flex-row items-center justify-end"}>
                    <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                        {inputStatus}
                    </span>
                </div>
            )}
        </div>
    )
}

export default ParagraphView