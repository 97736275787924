import { useState, useMemo, useEffect } from 'react';

import { MdOutlineLocationOn } from 'react-icons/md';
import { IoIosCheckmark } from 'react-icons/io';

import FloatingLabelSelect from 'components/floating/floatingLabelSelect';
import FloatingLabelInput from 'components/floating/floatingLabelInput';

import SaveAndCancelBtn from 'components/modals/crmModals/commonComponents/SaveAndCancelBtn';
import { crmContactAddressInputConst, crmDetailBtnConst } from 'components/modals/crmModals/data';
import RadioInput from 'components/modals/crmModals/commonComponents/RadioInput';

import { useAppState } from 'hooks/useStore';
import { crmContactLabelsEnum } from 'redux/crm/crm.const';

const ContactAddressItem = ({
    index = 0,
    isLoading = false,
    isShowSaveBtn = false,
    isShowCancelBtn = false,
    editBtnConst = null,
    setEditBtnConst = () => { },
    addressItem = null,
    onHandlePrimaryAddressDetail,
    onHandleSaveAddressDetail,
    onHandleDeleteAddressDetail
}) => {
    const { countryList } = useAppState((state) => state.master)

    const [addressPayload, setAddressPayload] = useState(addressItem)

    useEffect(() => {
        setAddressPayload(addressItem)
    }, [addressItem])

    const isEditDisabled = useMemo(() => {
        if (editBtnConst && (editBtnConst?.id !== addressItem?.id)) return true;
        else return false;
    }, [editBtnConst, addressItem])

    const isSimilarAddressDetail = useMemo(() => {
        const isSimilarData = JSON.stringify(addressPayload) === JSON.stringify(addressItem)
        return isSimilarData;
    }, [addressPayload, addressItem, editBtnConst])

    const onHandleChangeAddressDetail = (updatedValue) => {
        setAddressPayload(updatedValue)
        if (!editBtnConst) {
            setEditBtnConst({
                ...editBtnConst,
                id: addressPayload?.id
            })
        }
    }

    return (
        <div className={"w-full flex flex-col sm:flex-row gap-5 sm:gap-3 border px-3 py-5 rounded shadow-sm"}>
            <div className={"flex justify-between gap-3"}>
                <div className={"flex justify-start gap-3 mt-2"}>
                    <div className="text-lg text-text-700">
                        <MdOutlineLocationOn />
                    </div>
                    <div className={"flex sm:hidden h-fit"}>
                        <RadioInput
                            isInputDisabled={isEditDisabled}
                            tooltipContent={addressPayload?.is_primary ? "Default" : "Make Default"}
                            isInputChecked={addressPayload?.is_primary}
                            onHandleClickInput={(event) => onHandlePrimaryAddressDetail(addressPayload?.id, {
                                ...addressPayload,
                                is_primary: event?.target?.checked
                            })}
                        />
                    </div>
                </div>
                <div className={"flex sm:hidden mt-2"}>
                    <SaveAndCancelBtn
                        isLoading={isLoading}
                        isShowSaveBtn={isShowSaveBtn}
                        isShowCancelBtn={isShowCancelBtn}
                        isBtnInvisible={isEditDisabled}
                        isBtnDisable={isSimilarAddressDetail || !addressPayload?.street_line_1}
                        saveBtnTooltip={'Save Address'}
                        cancelBtnTooltip={"Remove Address"}
                        onHandleSaveBtn={() => onHandleSaveAddressDetail(addressPayload)}
                        onHandleCancelBtn={() => onHandleDeleteAddressDetail(addressPayload?.id)}
                    />
                </div>
            </div>
            <div className={"hidden sm:flex h-fit mt-2"}>
                <RadioInput
                    isInputDisabled={isEditDisabled}
                    tooltipContent={addressPayload?.is_primary ? "Default" : "Make Default"}
                    isInputChecked={addressPayload?.is_primary}
                    onHandleClickInput={(event) => onHandlePrimaryAddressDetail(addressPayload?.id, {
                        ...addressPayload,
                        is_primary: event?.target?.checked
                    })}
                />
            </div>
            <div className="flex flex-col gap-5 w-full">
                <FloatingLabelSelect
                    labelItem={`address_country${index}${addressItem.id}`}
                    searchable={true}
                    options={countryList?.countryList?.map((country) => ({
                        label: country?.country,
                        value: country?.masterCountryId,
                        country: country
                    })) || []}
                    onHandleSelect={(selectedOption) => onHandleChangeAddressDetail({
                        ...addressPayload,
                        country: {
                            masterCountryId: selectedOption?.value,
                            country: selectedOption?.country?.country,
                            countryDomain: selectedOption?.country?.domain,
                            isdCode: selectedOption?.isdCode
                        }
                    })}
                    value={addressPayload?.country?.country}
                    OptionChild={({ option }) => (
                        <div className="bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between">
                            <div className="flex items-center gap-3">
                                <img
                                    src={`https://flagcdn.com/16x12/${option.country?.countryDomain}.png`.toLowerCase()}
                                    className={"w-10 h-6 object-cover"}
                                />
                                {option?.country?.country}
                            </div>
                            {(option?.value === addressPayload?.country?.masterCountryId) && <IoIosCheckmark size={20} color="green" />}
                        </div>
                    )}
                    label={crmDetailBtnConst.country.label}
                    dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                    isDisabled={isEditDisabled}
                    customBtnStyle={isEditDisabled ? "bg-text-200 hover:!border-divider-lightDark focus-within:!ring-1 focus-within:!ring-divider-lightDark focus-within:!border-divider-lightDark !cursor-not-allowed" : ""}
                />
                {Object.values(crmContactAddressInputConst).map((addressInput, index) => (
                    <FloatingLabelInput
                        key={index}
                        labelItem={`address_${addressInput?.value}${index}${addressItem.id}`}
                        inputName={addressInput?.value}
                        onChange={(event) => onHandleChangeAddressDetail({ ...addressPayload, [event?.target?.name]: event?.target?.value })}
                        value={addressPayload[addressInput?.value]}
                        label={addressInput?.label}
                        disabled={isEditDisabled}
                        inputClassName={"disabled:px-2 disabled:bg-text-200 disabled:cursor-not-allowed"}
                        customInputContainerStyle={isEditDisabled && "focus-within:!ring-none hover:!border-divider-lightDark !px-0.5"}
                    />
                ))}
                <FloatingLabelSelect
                    labelItem={`address_label${index}${addressItem.id}`}
                    options={Object.values(crmContactLabelsEnum)}
                    onHandleSelect={(selectedOption) => onHandleChangeAddressDetail({ ...addressPayload, label: selectedOption })}
                    value={addressPayload?.label?.label}
                    OptionChild={({ option }) => (
                        <div className="bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between">
                            {option?.label}
                            {option?.value === addressPayload?.label?.value && <IoIosCheckmark size={20} color="green" />}
                        </div>
                    )}
                    label={"Label"}
                    dropDownContainerClassName={"w-full"}
                    isDisabled={isEditDisabled}
                    customBtnStyle={isEditDisabled ? "bg-text-200 hover:!border-divider-lightDark focus-within:!ring-1 focus-within:!ring-divider-lightDark focus-within:!border-divider-lightDark !cursor-not-allowed" : ""}
                />
            </div>
            <div className={"hidden sm:flex mt-2 h-fit"}>
                <SaveAndCancelBtn
                    isLoading={isLoading}
                    isShowSaveBtn={isShowSaveBtn}
                    isShowCancelBtn={isShowCancelBtn}
                    isBtnInvisible={isEditDisabled}
                    isBtnDisable={isSimilarAddressDetail || !addressPayload?.street_line_1}
                    saveBtnTooltip={'Save Address'}
                    cancelBtnTooltip={"Remove Address"}
                    onHandleSaveBtn={() => onHandleSaveAddressDetail(addressPayload)}
                    onHandleCancelBtn={() => onHandleDeleteAddressDetail(addressPayload?.id)}
                />
            </div>
        </div>
    )
}

export default ContactAddressItem;