import { useEffect, useCallback, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Pagination from "components/pagination/Pagination";

import { getAvailabilityListPayload, searchParamsInfo } from "pages/auth/preferences/data";

import AvailabilityFilters from "./filters/AvailabilityFilters";
import AvailabilityCard, { AvailabilityCardLoader } from "./AvailabilityCard";

import { getUserAvailabilityList } from "redux/availability/availability.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetDestroyUserAvailabilityDetail, resetUserAvailabilityList, setUserAvailabilityListData } from "redux/availability/availability.slice";

const AvailabilityListPage = () => {
    const { user } = useAppState((state) => state.user)
    const { userAvailabilityList, destroyUserAvailabilityDetail } = useAppState((s) => s.availability)

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const navigate = useNavigate()

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        return () => {
            dispatcher(resetUserAvailabilityList())
        }
    }, [])

    useEffect(() => {
        if (destroyUserAvailabilityDetail?.data) {
            dispatcher(setUserAvailabilityListData({
                ...userAvailabilityList?.data,
                results: userAvailabilityList?.data?.result?.filter((item) => item?.id !== destroyUserAvailabilityDetail?.data?.id),
                pagination: {
                    ...userAvailabilityList?.data?.pagination,
                    records: userAvailabilityList?.data?.pagination?.records - 1,
                    totalRecords: userAvailabilityList?.data?.pagination?.totalRecords - 1
                },
            }))
            dispatcher(resetDestroyUserAvailabilityDetail())
        }
    }, [destroyUserAvailabilityDetail?.data])

    useEffect(() => {
        if (user?.user?.userId) {
            getAvailabilityList()
        }
    }, [location.search, user?.user?.userId])

    const getAvailabilityList = async () => {
        const requestDataPayload = await getAvailabilityListPayload(searchQueryParams)
        dispatcher(getUserAvailabilityList(user?.user?.userId, { ...requestDataPayload }))
    }

    const onHandleChangePage = useCallback((page) => {
        searchQueryParams.set(searchParamsInfo.page.key, page)
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`);
    }, [location.search])

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <AvailabilityFilters />
            <div className={"w-full flex flex-col max-w-4xl mx-auto container gap-5 sm:gap-10"}>
                {new Array(5).fill("").map((_, index) => <AvailabilityCardLoader key={index} isLoading={userAvailabilityList?.isLoading} />)}
                {(!userAvailabilityList?.isLoading && (userAvailabilityList?.message || !userAvailabilityList?.data?.result || (userAvailabilityList?.data?.result?.length === 0))) &&
                    <div className={"flex items-center justify-center w-full p-5"}>
                        <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                            {userAvailabilityList?.message || "No Availability Added!"}
                        </span>
                    </div>
                }
                {(!userAvailabilityList?.isLoading && !!userAvailabilityList?.data?.result?.length) && userAvailabilityList?.data?.result?.map((userAvailability, index) => (
                    <AvailabilityCard key={index} userAvailability={userAvailability} />
                ))}
                {!userAvailabilityList?.isLoading && (userAvailabilityList?.data && (userAvailabilityList?.data?.pagination?.totalPages > 1)) && (
                    <div className="w-full flex justify-center items-center">
                        <Pagination
                            page={userAvailabilityList?.data?.pagination?.page}
                            totalPages={userAvailabilityList?.data?.pagination?.totalPages}
                            onChangePage={onHandleChangePage}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default AvailabilityListPage;