import { useState, useEffect, useCallback } from 'react';

import AuthTabs from 'components/tabs/AuthTabs';

import { tabConfig } from './data';
import LogInContainer from './components/LogInContainer';
import SignUpContainer from './components/SignUpContainer';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setClearSendOtp, setClearVerifyOtp } from 'redux/notification/notification.slice';
import { setClearLogInPayload, setClearSignUpPayload, setClearVerifyUser } from 'redux/auth/auth.slice';
import { modalConst } from 'redux/local/local.const';

const AuthorizationModal = () => {
  const { modal } = useAppState((s) => s.local)
  const { session, logIn, signUp, verifyUser } = useAppState((s) => s.auth)
  const { sendOtp, verifyOtp } = useAppState((state) => state.notification)
  const { user } = useAppState((state) => state.user)

  const dispatcher = useAppDispatcher()

  const [currentActiveTab, setCurrentActiveTab] = useState(tabConfig?.LOG_IN)

  useEffect(() => {
    return () => {
      dispatcher(setClearLogInPayload())
      dispatcher(setClearSignUpPayload())
      dispatcher(setClearVerifyUser())
      dispatcher(setClearSendOtp())
      dispatcher(setClearVerifyOtp())
    }
  }, [])

  useEffect(() => {
    if (session?.isLoggedIn) {
      dispatcher(setModal({
        ...modal,
        [modalConst.AUTHORIZATION_MODAL.stateKey]: false
      }))
    }
  }, [session?.isLoggedIn])

  const onHandleToggleTab = useCallback((tab) => {
    if (verifyUser?.isLoading || sendOtp?.isLoading || verifyOtp?.isLoading || logIn?.isLoading || signUp?.isLoading || user?.isLoading) return;
    dispatcher(setClearLogInPayload())
    dispatcher(setClearSignUpPayload())
    dispatcher(setClearVerifyUser())
    dispatcher(setClearSendOtp())
    dispatcher(setClearVerifyOtp())
    setCurrentActiveTab(tab)
  }, [currentActiveTab])

  return (
    <div className={"space-y-5 overflow-x-hidden"}>
      <div className={"min-h-[16rem] block space-y-5"}>
        <div className='w-full flex items-center justify-center gap-3'>
          <AuthTabs
            tabs={Object.values(tabConfig)}
            activeTab={currentActiveTab}
            handleTabs={(tab) => onHandleToggleTab(tab)}
          />
        </div>
        <div className={"py-5"}>
          {(currentActiveTab?.value === tabConfig?.LOG_IN?.value) &&
            <LogInContainer setCurrentActiveTab={(option) => onHandleToggleTab(option)} />
          }
          {(currentActiveTab?.value === tabConfig?.SIGN_UP?.value) &&
            <SignUpContainer setCurrentActiveTab={(option) => onHandleToggleTab(option)} />
          }
        </div>
      </div>
    </div>
  )
}

export default AuthorizationModal;