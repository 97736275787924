
export const pricingDiscountConst = [
    {
        label: "5%",
        value: 5
    },
    {
        label: "10%",
        value: 10
    },
    {
        label: "15%",
        value: 15
    },
    {
        label: "20%",
        value: 20
    }
]

export const sessionDruation = [
    {
        label: "30 min",
        value: 30,
    },
    {
        label: "60 min",
        value: 60,
    },
    {
        label: "90 min",
        value: 90,
    },
    {
        label: "120 min",
        value: 120,
    },
]

export const editBtnConst = {
    category: {
        value: 'category',
        isEnable: false
    },
    title: {
        value: 'title',
        isEnable: false
    },
    titleDescription: {
        value: 'titleDescription',
        isEnable: false
    },
    price: {
        value: 'price',
        isEnable: false
    },
    level: {
        value: 'level',
        isEnable: false,
    },
    language: {
        value: 'language',
        isEnable: false,
    },
    sessionDuration: {
        value: 'sessionDuration',
        isEnable: false,
    },
    sessionCount: {
        value: 'sessionCount',
        isEnable: false
    },
    studentCapacity: {
        value: 'studentCapacity',
        isEnable: false
    },
    startDate: {
        value: 'startDate',
        isEnable: false,
    },
    endDate: {
        value: 'endDate',
        isEnable: false,
    },
    weeklyTimeSlots: {
        value: 'weeklyTimeSlots',
        isEnable: false,
    },
    skills: {
        value: 'skills',
        isEnable: false
    },
    courseDescription: {
        value: 'courseDescription',
        isEnable: false
    },
    thumbnail: {
        value: 'thumbnail',
        isEnable: false
    }
}

export const courseDescriptionToolTip = "Include all the relevant details of your class. Give an accurate overview of key content, uniqueness of your teaching style, innovative instructional, examples, and conclusion. Do not forget to mention it will be a personalised class catering to learners’ learning needs."

export const skillsToolTip = "Write five skills your learner is likely to learn. For the subject Maths, it can be number sense, critical thinking, analytical ability, quick calculations, and quantitative reasoning."

export const titleToolTip = "Use attention-grabbing words. Include the subject keywords and write in Title Case. Keep it short and simple with the spotlight on class outcomes. Mention it is a one-on-one class (Up to 10 words / 70 characters)"

export const subHeaderToolTip = "Expand on the class title / name and talk about the benefits of your class and the progress students will make through it.  Compel the reader to view class details. (30-60 words)"