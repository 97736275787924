import { useEffect } from "react";

import { FaSpinner } from "react-icons/fa";

import { motion } from "framer-motion";
import SelectSearch, { fuzzySearch } from "react-select-search";

import ButtonLoader from "components/loader/button-loader";

import { getMasterCurrencyList, getMasterLanguageList, getMasterTimezoneList } from "redux/master/master.request";
import { createLogout, sendForgetPasswordLink } from "redux/auth/auth.request";
import { getUserCultureDetailByUserId, updateUserCultureDetailByUserId } from "redux/user/user.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setUserGeneralDetailPayload } from "redux/user/user.slice";
import { setModal, setLocals } from "redux/local/local.slice";
import { timeFormatConst } from "redux/master/master.const";
import { modalConst, localsConst } from "redux/local/local.const";

import { cn } from "utils/cn.utils";

const General = () => {
  const { modal, locals } = useAppState((state) => state.local)
  const { languageList, timeZoneList, currencyList } = useAppState((state) => state.master)
  const { forgetPasswordLink, logout } = useAppState((state) => state.auth)
  const { user, userCultureDetail, userGeneralDetail } = useAppState((state) => state.user)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(getMasterLanguageList())
    dispatcher(getMasterTimezoneList())
    dispatcher(getMasterCurrencyList())
    dispatcher(getUserCultureDetailByUserId(user?.user?.userId))
  }, [])

  const onHandleEmail = () => {
    if (user?.user?.email && user?.user?.isEmailVerified) return;
    if (user?.user?.email) {
      dispatcher(setUserGeneralDetailPayload({
        ...userGeneralDetail?.userGeneralDetailPayload,
        isEmailUpdateEnabled: true
      }))
      dispatcher(setModal({
        ...modal,
        [modalConst.VERIFY_AUTHENTICATION_MODAL.stateKey]: true
      }))
    }
  }

  const handleVerifyEmail = () => {
    dispatcher(setModal({
      ...modal,
      [modalConst.VALIDATION_BAR_EMAIL_VERIFY_MODAL.stateKey]: true
    }))
  }

  const onHandleMobileNo = () => {
    if (user?.user?.mobileNo && user?.user?.isMobileNoVerified) return;
    dispatcher(setUserGeneralDetailPayload({
      ...userGeneralDetail?.userGeneralDetailPayload,
      isMobileNoUpdateEnabled: true
    }))
    dispatcher(setModal({
      ...modal,
      [modalConst.VERIFY_AUTHENTICATION_MODAL.stateKey]: true
    }))
  }

  const handleVerifyMobileNo = async () => {
    dispatcher(setModal({
      ...modal,
      [modalConst.VALIDATION_BAR_MOBILE_VERIFY_MODAL.stateKey]: true
    }))
  }

  const handleUpdatePassword = () => {
    dispatcher(setUserGeneralDetailPayload({
      ...userGeneralDetail?.userGeneralDetailPayload,
      isPasswordUpdateEnabled: true
    }))
    dispatcher(setModal({
      ...modal,
      [modalConst.VERIFY_AUTHENTICATION_MODAL.stateKey]: true

    }))
  }

  const handleSendPasswordResetLink = () => {
    if (forgetPasswordLink?.isLoading) return;

    if (!window.confirm("Are you sure? email a link to reset your password.")) return;

    dispatcher(sendForgetPasswordLink({ email: user?.user?.email }))

  }

  const onHandleLogOut = () => {
    if (logout?.isLoading) return;

    if (!window.confirm("Are you sure you want to log out? You will need to log in again to access your account.")) return;

    dispatcher(createLogout())
  }

  return (
    <div className={"grid grid-cols-12 gap-3 md:gap-5 lg:gap-8 p-5"}>

      {/* user id container */}
      <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
        {"User ID"}
      </span>
      <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-5 lg:col-start-3 lg:col-span-3 flex flex-col items-start justify-center gap-2"}>
        <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
          {user?.user?.userId}
        </span>
      </div>

      {/* email container */}
      <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
        {"Email"}
      </span>
      {user?.user?.email &&
        <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-5 lg:col-start-3 lg:col-span-3 flex flex-col items-start justify-center gap-2"}>
          <span className={"max-w-[18rem] font-bodyPri font-normal text-text-700 text-base tracking-wide truncate"}>
            {user?.user?.email}
          </span>
          {(user?.user?.email && !user?.user?.isEmailVerified) &&
            <span
              className={cn(
                "font-bodyPri font-medium text-text-800 text-sm tracking-wide",
                !user?.user?.isEmailVerified && "cursor-pointer hover:text-primary-main"
              )}
              onClick={onHandleEmail}
            >
              {"Change Email"}
            </span>
          }
        </div>
      }
      {(user?.user?.email && user?.user?.isEmailVerified) &&
        <span className={"col-start-1 col-span-full md:col-start-10 md:col-span-2 lg:col-start-7 lg:col-span-2 font-bodyPri font-normal text-green-500"}>
          {"Verified"}
        </span>
      }
      {(user?.user?.email && !user?.user?.isEmailVerified) &&
        <div
          className={cn(
            "col-start-1 col-span-full md:col-start-10 md:col-span-2 lg:col-start-7 lg:col-span-2",
          )}
          onClick={handleVerifyEmail}
        >
          <span className={cn(
            "font-bodyPri font-normal text-sm text-red-500 italic cursor-pointer whitespace-nowrap",
            "px-2 py-0.5 rounded-md border border-red-500"
          )}>
            {"Verify Email"}
          </span>
        </div>
      }
      {!user?.user?.email &&
        <span
          className={cn(
            "col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5 py-1.5 font-buttons font-medium text-text-800 text-sm text-center rounded-md tracking-wider",
            "bg-text-200 hover:bg-text-300 cursor-pointer"
          )}
          onClick={onHandleEmail}
        >
          {"Add Email"}
        </span>
      }

      {/* phone container */}
      <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
        {"Phone"}
      </span>
      {user?.user?.mobileNo &&
        <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-5 lg:col-start-3 lg:col-span-3 flex flex-col items-start justify-center gap-2"}>
          <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
            {"+" + user?.user?.isdCode + " - " + user?.user?.mobileNo}
          </span>
          {(user?.user?.mobileNo && !user?.user?.isMobileNoVerified) &&
            <span
              className={cn(
                "font-bodyPri font-medium text-text-800 text-sm tracking-wide",
                !user?.user?.isMobileNoVerified && "cursor-pointer hover:text-primary-main"
              )}
              onClick={onHandleMobileNo}
            >
              {"Change Phone"}
            </span>
          }
        </div>
      }
      {(user?.user?.mobileNo && user?.user?.isMobileNoVerified) &&
        <span className={"col-start-1 col-span-full md:col-start-10 md:col-span-2 lg:col-start-7 lg:col-span-2 font-bodyPri font-normal text-green-500"}>
          {"Verified"}
        </span>
      }
      {(user?.user?.mobileNo && !user?.user?.isMobileNoVerified) &&
        <div
          className={cn(
            "col-start-1 col-span-full md:col-start-10 md:col-span-2 lg:col-start-7 lg:col-span-2",
          )} onClick={handleVerifyMobileNo}
        >
          {/* <div className={'flex items-center justify-center'} id={'recaptcha-container'}></div> */}
          <span className={cn(
            "font-bodyPri font-normal text-sm text-red-500 italic cursor-pointer whitespace-nowrap",
            "px-2 py-0.5 rounded-md border border-red-500"
          )}>
            {"Verify Phone"}
          </span>
        </div>
      }
      {!user?.user?.mobileNo &&
        <span
          className={cn(
            "col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5 py-1.5 font-buttons font-medium text-text-800 text-sm text-center rounded-md tracking-wider",
            "bg-text-200 hover:bg-text-300 cursor-pointer"
          )}
          onClick={onHandleMobileNo}
        >
          {"Add Phone"}
        </span>
      }

      {/* password container */}
      <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
        {"Password"}
      </span>
      <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5 flex flex-col items-start justify-start gap-3"}>
        <span
          className={cn(
            "w-full py-1.5 font-buttons font-medium text-text-800 text-sm text-center rounded-md tracking-wider",
            "bg-text-200 hover:bg-text-300 cursor-pointer"
          )}
          onClick={handleUpdatePassword}
        >
          {"Update Password"}
        </span>

        {/* reset password link */}
        {forgetPasswordLink?.isLoading &&
          <FaSpinner className={"text-lg text-primary-main animate-spin"} />
        }
        {!forgetPasswordLink?.isLoading &&
          <span
            className={cn(
              "w-full font-bodyPri font-medium text-text-800 text-base text-center tracking-wide",
              "text-primary-dark hover:text-secondary-dark cursor-pointer"
            )}
            onClick={handleSendPasswordResetLink}
          >
            {"Email me a password reset link"}
          </span>
        }

      </div>

      {/* language container */}
      <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base lg:whitespace-nowrap"}>
        {"Display Language"}
      </span>
      {languageList?.isLoading &&
        <div className={"flex items-center justify-start gap-0.5"}>
          <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
            {"Please wait ..."}
          </span>
          <FaSpinner className={"inline-flex text-primary-main animate-spin text-lg"} />
        </div>
      }
      {(!languageList?.isLoading && (languageList?.languageList || [])) &&
        <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5 bg-white shadow-all rounded-lg"}>
          <SelectSearch
            options={languageList?.languageList?.map((language) => ({
              name: language?.language,
              value: language?.masterLanguageId
            }))?.filter((language) => language?.name === "English")}
            search
            filterOptions={fuzzySearch}
            value={userCultureDetail?.userCultureDetail?.displayLanguage?.masterLanguageId}
            onChange={(option) => {
              dispatcher(updateUserCultureDetailByUserId(user?.user?.userId, { masterLanguageId: option }))
              dispatcher(setLocals({
                ...locals,
                [localsConst.DISPLAY_LANGUAGE.value]: languageList?.languageList?.find(language => language?.masterLanguageId === option)?.language
              }))
            }}
          />
        </div>
      }

      {/* timezone container */}
      <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
        {"Timezone"}
      </span>
      {timeZoneList?.isLoading &&
        <div className={"flex items-center justify-start gap-0.5"}>
          <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
            {"Please wait ..."}
          </span>
          <FaSpinner className={"inline-flex text-primary-main animate-spin text-lg"} />
        </div>
      }
      {(!timeZoneList?.isLoading && (timeZoneList?.timeZoneList || [])) &&
        <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5 bg-white shadow-all rounded-lg"}>
          <SelectSearch
            options={timeZoneList?.timeZoneList?.map((timezone) => ({
              name: `${timezone?.timeZone} (${timezone?.country})`,
              value: timezone?.masterTimeZoneId
            }))}
            search
            placeholder={"Asia/Calcutta(UTC+05:30)"}
            filterOptions={fuzzySearch}
            value={userCultureDetail?.userCultureDetail?.timeZone?.masterTimeZoneId}
            onChange={(option) => {
              dispatcher(updateUserCultureDetailByUserId(user?.user?.userId, { masterTimeZoneId: option }))
              dispatcher(setLocals({
                ...locals,
                [localsConst.TIME_ZONE.value]: timeZoneList?.timeZoneList?.find(timezone => timezone?.masterTimeZoneId === option)?.timeZone
              }))
            }}
          />
        </div>
      }

      {/* currency container */}
      <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
        {"Currency"}
      </span>
      {currencyList?.isLoading &&
        <div className={"flex items-center justify-start gap-0.5"}>
          <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
            {"Please wait ..."}
          </span>
          <FaSpinner className={"inline-flex text-primary-main animate-spin text-lg"} />
        </div>
      }
      {(!currencyList?.isLoading && (currencyList?.currencyList || [])) &&
        <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5 bg-white shadow-all rounded-lg"}>
          <SelectSearch
            options={currencyList?.currencyList?.map((currency) => ({
              name: `${currency?.code} (${currency?.country})`,
              value: currency?.masterCurrencyId
            }))}
            search
            filterOptions={fuzzySearch}
            value={userCultureDetail?.userCultureDetail?.currency?.masterCurrencyId}
            onChange={(option) => {
              dispatcher(updateUserCultureDetailByUserId(user?.user?.userId, { masterCurrencyId: option }))
              dispatcher(setLocals({
                ...locals,
                [localsConst.CURRENCY_CODE.value]: currencyList?.currencyList?.find(currency => currency?.masterCurrencyId === option)?.code
              }))
            }}
          />
        </div>
      }

      {/* time format container */}
      <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
        {"Time Format"}
      </span>
      <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5 flex items-center justify-start gap-8"}>
        <div
          className={"flex items-center justify-center gap-1 cursor-pointer"}
          onClick={() => {
            dispatcher(updateUserCultureDetailByUserId(user?.user?.userId, { timeFormat: timeFormatConst.HRS_12.value }))
            dispatcher(setLocals({
              ...locals,
              [localsConst.TIME_FORMAT.value]: timeFormatConst.HRS_12.value
            }))
          }}
        >
          <input
            type={"radio"}
            className={"border-2 border-text-100 hover:border-text-700 cursor-pointer"}
            id={"time_format1"}
            name={"time_format"}
            checked={userCultureDetail?.userCultureDetail?.timeFormat === timeFormatConst.HRS_12.value && true}
          />
          <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wider"}>
            {timeFormatConst.HRS_12.label}
          </span>
        </div>
        <div
          className={"flex items-center justify-center gap-1 cursor-pointer"}
          onClick={() => {
            dispatcher(updateUserCultureDetailByUserId(user?.user?.userId, { timeFormat: timeFormatConst.HRS_24.value }))
            dispatcher(setLocals({
              ...locals,
              [localsConst.TIME_FORMAT.value]: timeFormatConst.HRS_24.value
            }))
          }}
        >
          <input
            type={"radio"}
            className={"border-2 border-text-100 hover:border-text-700 cursor-pointer"}
            id={"time_format2"}
            name={"time_format"}
            checked={userCultureDetail?.userCultureDetail?.timeFormat === timeFormatConst.HRS_24.value && true}
          />
          <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wider"}>
            {timeFormatConst.HRS_24.label}
          </span>
        </div>
      </div>

      {/* logout button */}
      <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
        {""}
      </span>
      <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5 flex items-center justify-start gap-8"}>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          transition={{ duration: 0.3 }}
          className={cn(
            "w-fit px-5 py-2 flex justify-center items-center rounded-full ease-in-out duration-200",
            "font-bodyPri font-normal text-lg tracking-wide",
            "bg-secondary-dark text-text-50 hover:opacity-90"
          )}
          onClick={onHandleLogOut}
        >
          {logout?.isLoading &&
            <ButtonLoader isLoading={logout?.isLoading} className={"px-3 py-2"} />
          }
          {!logout?.isLoading &&
            <span className={""}>
              {"Log Out"}
            </span>
          }
        </motion.button>
      </div>
      <div className={"col-start-1 col-span-full w-full h-[6rem]"}></div>
    </div>
  )
}

export default General