import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Joyride, { STATUS } from 'react-joyride';

import FullPageLoader from 'components/loader/FullPageLoader';

import QuizHeader from './components/quizHeader/QuizHeader';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getLmsQuizDetail } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetLmsQuizDetail } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.slice';

import { useTitle } from 'hooks/useTitle';

import { pagesInfo } from 'utils/pagesInfo';
import { resetLmsCreateBulkQuestions } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.slice';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { steps } from './editLMSQuiz.data';

const EditLMSQuizPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)
    const { lmsQuizDetail } = useAppState((state) => state.lms.lmsQuiz)
    const { lmsCreateBulkQuestions } = useAppState((state) => state.lms.lmsQuestion)
    const { modal } = useAppState((state) => state.local)
    const [runTour, setRunTour] = useState(false);

    const dispatcher = useAppDispatcher()
    const { quizId } = useParams()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        const tourSeen = localStorage.getItem('tourSeen');
        if (!tourSeen) {
            setRunTour(true);
        }
    }, []);

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.EDIT_LMS_QUIZ))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (user?.user) {
            setTitle({
                ...title,
                title: `${user?.user?.firstName}, Welcome to Edulyte LMS`
            })
        }
    }, [user?.user, currentPageInfo])

    useEffect(() => {
        if (lmsCreateBulkQuestions?.data) {
            dispatcher(setModal({
                ...modal,
                [modalConst.CREATE_BULK_QUESTIONS.stateKey]: false
            }))
            dispatcher(resetLmsCreateBulkQuestions())
            if (quizId) {
                dispatcher(getLmsQuizDetail(quizId))
            }
        }
    }, [lmsCreateBulkQuestions?.data])

    useEffect(() => {
        if (quizId) {
            dispatcher(getLmsQuizDetail(quizId))
        }

        return () => {
            dispatcher(resetLmsQuizDetail())
        }
    }, [quizId])

    if (lmsQuizDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={lmsQuizDetail?.isLoading} />
        )
    }

    const handleTourEnd = () => {
        localStorage.setItem('tourSeen', true);
        setRunTour(false);
    };

    const handleJoyrideCallback = (data) => {
        const { status } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
        if (finishedStatuses.includes(status)) {
            handleTourEnd();
        }
    };

    if (lmsQuizDetail?.message) {
        return (
            <div className='w-screen h-screen flex items-center justify-center'>
                <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                    {lmsQuizDetail?.message}
                </span>
            </div>
        )
    }

    return (
        <div className={'space-y-3 bg-background-light'}>
            <QuizHeader startTour={() => setRunTour(true)} />
            <div className={'w-full px-3'}>
                <Outlet />
            </div>
            <Joyride
                steps={steps}
                continuous
                disableScrolling
                run={runTour}
                scrollOffset="100"
                showSkipButton={true}
                callback={handleJoyrideCallback}
                locale={{ skip: "Skip", last: "Done", }}
                styles={{
                    options: {
                        arrowColor: '#fafafa',
                        backgroundColor: '#fafafa',
                        overlayColor: 'rgba(0, 0, 0, 0.2)',
                        primaryColor: '#0741ad',
                        textColor: '#212121',
                    },
                }}
            />
        </div>
    )
}

export default EditLMSQuizPage;