import { Outlet } from 'react-router-dom';

import { courseSettingsNavigationOptions } from '../../editLMSCourse.data';
import LMSNavigationTabs from "../../../../commonComponents/navigations/LMSNavigationTabs";

const EditLMSCourseSettingsPage = () => {

    return (
        <div className={"flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3"}>
            <LMSNavigationTabs options={Object.values(courseSettingsNavigationOptions)} />
            <div className="w-full rounded-lg overflow-hidden p-5 bg-white">
                <Outlet />
            </div>
        </div>
    )
}

export default EditLMSCourseSettingsPage;