import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { ImCross } from "react-icons/im";
import { FaRegComment } from "react-icons/fa";

import SideOverPanel from 'components/common-components/SideOverPanel';

import { PageContentStyle } from './createCourse.style';
import CreateCourseSideOverContent from './CreateCourseSideOverContent';
import NavigationOptions from './components/NavigationOptions';
import ButtonContainer from './components/ButtonContainer';
import SelectTemplatePage from './components/SelectTemplatePage';
import BasicInputPage from './components/basicInputPage/BasicInputPage';
import SetPrice from './components/setPricePage/SetPrice';
import ConfirmSchedulePage from './components/confirmSchedulePage/ConfirmSchedulePage';
import PreviewAndStatusPage from './components/previewAndStatusPage/PreviewAndStatusPage';
import { courseSteps } from './data';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getTutorCourseDetail } from 'redux/course/course.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearAddCourseDetail, setClearCourseDetail } from "redux/course/course.slice";

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from "utils/generators.utils";

const CreateCoursePage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)
    const { tutorCourseDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()
    const { courseId } = useParams()

    const [sideOverPanel, setSideOverPanel] = useState(false)
    const [stepCount, setStepCount] = useState(1)

    // const containerIDs = ["CREATE_A_TEMPLATE", "BASIC_INPUTS"]
    const from = location.state?.from?.url || `${pagesInfo.TUTOR_PRODUCTS.pagePath}/live-classes`;

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.CREATE_COURSE))
    }, [dispatcher, currentPageInfo])

    // useEffect(() => {
    //     if (tutorCourseDetail?.data && [courseStatus?.UNDER_REVIEW?.value, courseStatus?.PUBLISHED?.value]?.includes(tutorCourseDetail?.data?.status)) {
    //         dispatcher(setModal({
    //             ...modal,
    //             [modalConst.SUBMIT_COURSE_MODAL.stateKey]: false
    //         }))
    //         navigate(`${pagesInfo.TUTOR_PRODUCTS.pagePath}/live-classes`, { replace: true })
    //     }
    // }, [tutorCourseDetail?.data])

    useEffect(() => {
        if (tutorCourseDetail?.data) {
            const query = generateQueryParams(location.search)
            if (query && query?.step) {
                setStepCount(Number(query?.step))
            } else {
                setStepCount(tutorCourseDetail?.data?.step)
            }
        }
    }, [tutorCourseDetail?.data, location.search])

    useEffect(() => {
        if (courseId) {
            dispatcher(getTutorCourseDetail(courseId, user?.user?.tutor?.tutorId))
        }

        return () => {
            dispatcher(setClearCourseDetail())
            dispatcher(setClearAddCourseDetail())
        }
    }, [courseId])

    const onHandleLeavePage = () => {
        dispatcher(setClearCourseDetail())
        dispatcher(setClearAddCourseDetail())
        navigate(from, { replace: true });
    }

    const onHandleComments = () => {
        setSideOverPanel(true)
    }

    return (
        <PageContentStyle>
            <SideOverPanel
                child={<CreateCourseSideOverContent />}
                isOpen={sideOverPanel}
                onClose={(isShow) => setSideOverPanel(isShow)}
            />
            <div className={"w-full h-full min-h-screen lg:min-h-screen overflow-x-hidden bg-gradient-to-bl from-secondary-main/30 via-primary-main/30 to-primary-main/40"}>
                <div className='hidden w-full md:flex justify-end p-2 fixed'>
                    <span
                        className="p-1.5 bg-white border border-white rounded-full shadow-md cursor-pointer"
                        title={"Close"}
                        onClick={onHandleLeavePage}
                    >
                        <ImCross className={"text-text-400 hover:text-text-600 text-2xl"} />
                    </span>
                </div>
                <div className='w-full fixed bottom-0 left-0 md:left-5 flex items-end justify-start p-2'>
                    <span
                        className="p-1.5 bg-complementry-light border border-complementry-light rounded-full shadow-md cursor-pointer"
                        title={"Comments"}
                        onClick={onHandleComments}
                    >
                        <FaRegComment className={"text-text-600 hover:text-text-800 text-3xl md:text-4xl"} />
                    </span>
                </div>

                {/* w-full max-w-full sm:max-w-xl md:max-w-4xl lg:max-w-7xl  */}
                <div className={"mx-auto container px-5 py-5 space-y-3 md:px-0 md:py-5"}>
                    <div className={"w-full flex items-center justify-center gap-5"}>
                        <NavigationOptions options={Object.values(courseSteps)} stepCount={stepCount} setStepCount={setStepCount} />
                    </div>
                    {/* <ButtonContainer stepCount={stepCount} setStepCount={setStepCount} /> */}
                    {(stepCount === courseSteps?.SELECT_A_TEMPLATE?.stepNo) &&
                        <div className={"w-full h-full bg-white p-2 sm:p-10 rounded-lg space-y-5"}>
                            <ButtonContainer stepCount={stepCount} setStepCount={setStepCount} />
                            <SelectTemplatePage />
                        </div>
                    }
                    {(stepCount === courseSteps?.BASIC_INPUTS?.stepNo) &&
                        <div className={"w-full h-full bg-white p-2 sm:p-10 rounded-lg"}>
                            <BasicInputPage stepCount={stepCount} setStepCount={setStepCount} />
                        </div>
                    }
                    {(stepCount === courseSteps?.CONFIRM_SCHEDULE?.stepNo) &&
                        <div className={"w-full h-full bg-white p-2 sm:p-10 rounded-lg"}>
                            <ConfirmSchedulePage stepCount={stepCount} setStepCount={setStepCount} />
                        </div>
                    }
                    {(stepCount === courseSteps?.SET_PRICING?.stepNo) &&
                        <div className={"w-full h-full bg-white p-2 sm:p-10 rounded-lg"}>
                            <SetPrice stepCount={stepCount} setStepCount={setStepCount} />
                        </div>
                    }
                    {(stepCount === courseSteps?.PREVIEW_AND_STATUS?.stepNo) &&
                        <div className={"w-full h-full bg-white p-2 sm:p-10 rounded-lg"}>
                            <PreviewAndStatusPage stepCount={stepCount} setStepCount={setStepCount} />
                        </div>
                    }
                </div>
            </div>
        </PageContentStyle>
    )
}

export default CreateCoursePage;