import { useEffect, useState } from "react";
import { cn } from "utils/cn.utils";

import { createEmployeeByCompanyId, updateEmployeeDetailByEmployeeId } from 'redux/company/company.request';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { setEmployeeDetailPayload } from "redux/company/company.slice";
import { Link } from "react-router-dom";

const profilePictureUrl = "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRaw"

const CompanyPeopleModal = () => {
  const { modal } = useAppState((state) => state.local)
  const { employeeDetailPayload } = useAppState((state) => state.company)

  const dispatcher = useAppDispatcher()

  const [characterLength, setCharacterLength] = useState(0)
  const [maxCharacterLength] = useState(120)

  useEffect(() => {
    if (employeeDetailPayload?.employeeDetailPayload) {
      setCharacterLength(employeeDetailPayload?.employeeDetailPayload?.about?.slice(0, maxCharacterLength)?.length)
    }
  }, [])

  const handleUploadImage = () => {

  }

  const handleAddPeople = () => {
    dispatcher(setModal({
      ...modal,
      [modalConst.COMPANY_PEOPLE_MODAL.stateKey]: false
    }))
    if (!employeeDetailPayload?.employeeDetailPayload?.employeeId) {
      dispatcher(createEmployeeByCompanyId({
        ...employeeDetailPayload?.employeeDetailPayload,
        employeeId: Math.floor((Math.random() * 10) + 1),
        profilePicUrl: profilePictureUrl
      }))
    }

    if (employeeDetailPayload?.employeeDetailPayload?.employeeId) {
      dispatcher(updateEmployeeDetailByEmployeeId(employeeDetailPayload?.employeeDetailPayload))
      return;
    }
  }

  return (
    <form className={"space-y-5 overflow-x-hidden"} onSubmit={handleAddPeople}>
      <div className={"min-h-[24rem] block space-y-8 pt-5"}>
        <div className={"w-full grid grid-cols-12 gap-5"}>
          {/* <div className={"col-start-0 col-span-full font-bodyPri font-semibold text-lg text-text-800 tracking-wide whitespace-nowrap"}>
            {"Profile Photo"}
          </div> */}
          <div className={"col-start-0 col-span-1 w-20 h-20 overflow-hidden rounded-full"}>
            <img
              src={employeeDetailPayload?.employeeDetailPayload?.profilePicUrl}
              className={"w-full h-full object-cover"}
              alt={"profile-img"}
            />
          </div>
          <div className={"col-start-0 col-span-full md:col-start-5 md:col-span-full lg:col-start-4 lg:col-span-full flex flex-col justify-start items-start gap-2"}>
            <div className={"flex flex-col items-start justify-center gap-1 font-bodyPri font-normal text-text-800 text-sm"}>
              <span>
                {"This will be Public."}
              </span>
              <span className={"text-sm"}>
                {"Max Size: 2MB"}
              </span>
            </div>
            <div
              className={cn(
                "px-2 py-0.5 flex justify-center items-center rounded-lg cursor-pointer",
                "font-bodyPri font-normal text-sm",
                "border border-secondary-main text-secondary-main",
                "hover:bg-secondary-main hover:text-text-50"
              )}
              onClick={handleUploadImage}
            >
              {"Upload"}
            </div>
          </div>
        </div>

        {/* edit user details section */}
        <div className={"grid grid-cols-12 gap-5"}>

          {/* edit first name section */}
          <span className={"col-start-0 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
            {"First Name:"}
          </span>
          <div className={"col-start-0 col-span-full md:col-start-3 md:col-span-full lg:col-start-4 lg:col-span-full"}>
            <input
              type={"text"}
              value={employeeDetailPayload?.employeeDetailPayload?.firstName}
              className={cn(
                "w-full px-2 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
              )}
              onChange={(event) => dispatcher(setEmployeeDetailPayload({
                ...employeeDetailPayload?.employeeDetailPayload,
                firstName: event.target.value
              }))}
              required
            />
          </div>

          {/*last name section */}
          <span className={"col-start-0 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
            {"Last Name:"}
          </span>
          <div className={"col-start-0 col-span-full md:col-start-3 md:col-span-full lg:col-start-4 lg:col-span-full"}>
            <input
              type={"text"}
              value={employeeDetailPayload?.employeeDetailPayload?.lastName}
              className={cn(
                "w-full px-2 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
              )}
              onChange={(event) => dispatcher(setEmployeeDetailPayload({
                ...employeeDetailPayload?.employeeDetailPayload,
                lastName: event.target.value
              }))}
              required
            />
          </div>

          {/* designation section */}
          <span className={"col-start-0 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
            {"Designation:"}
          </span>
          <div className={"col-start-0 col-span-full md:col-start-3 md:col-span-full lg:col-start-4 lg:col-span-full"}>
            <input
              type={"text"}
              value={employeeDetailPayload?.employeeDetailPayload?.designation}
              className={cn(
                "w-full px-2 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
              )}
              onChange={(event) => dispatcher(setEmployeeDetailPayload({
                ...employeeDetailPayload?.employeeDetailPayload,
                designation: event.target.value
              }))}
              required
            />
          </div>

          {/* about section */}
          <span className={"col-start-0 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
            {"About:"}
          </span>
          <div className={"col-start-0 col-span-full md:col-start-3 md:col-span-full lg:col-start-4 lg:col-span-full"}>

            <textarea
              className={cn(
                "w-full px-2 py-1 rounded-lg border-2 border-divider-medium resize-none",
                "focus:outline-none focus:border-2 focus:border-text-400",
                "font-bodyPri font-normal text-base text-text-800 tracking-wide"
              )}
              type={"text"}
              placeholder="Tell us about this person ...."
              value={employeeDetailPayload?.employeeDetailPayload?.about?.slice(0, maxCharacterLength)}
              onChange={(event) => {
                setCharacterLength(event.target.value.length)
                dispatcher(setEmployeeDetailPayload({
                  ...employeeDetailPayload?.employeeDetailPayload,
                  about: event.target.value
                }))
              }}
              rows={4}
              maxlength={120}
              autoFocus={true}
              required
            />
            <div className={cn("flex justify-end",
              characterLength === maxCharacterLength && "w-full flex justify-between items-center"
            )}>
              {characterLength === maxCharacterLength &&
                <span className={"font-bodyPri font-normal text-sm text-red-500"}>
                  {"Maximum character reached"}
                </span>
              }
              <span className={"font-bodyPri font-normal text-text-800 text-xs"}>
                {characterLength + "/" + maxCharacterLength}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={"flex justify-between items-center"}>
        <Link to={"/"} className={"font-bodyPri font-normal text-sm text-primary-main tracking-wide cursor-pointer underline"}>
          {"Want to Add as teacher?"}
        </Link>
        <button
          type={"submit"}
          className={cn(
            "px-3 py-1 flex justify-center items-center rounded-full cursor-pointer",
            "font-buttons font-normal text-base text-secondary-main",
            "border border-secondary-main hover:bg-secondary-main hover:text-text-50"
          )}
        >
          {"Submit"}
        </button>
      </div>
    </form>
  )
}

export default CompanyPeopleModal