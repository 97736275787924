import { courseFilterProps, courseEndTypeEnum, tempCourseType, weekAvailTimeSlots } from "redux/course/course.const";
import { dayjs } from "utils/dateTime.utils";

const START_DATE_TIME = dayjs(new Date().setDate(new Date().getDate() + 8))?.format("YYYY-MM-DD HH:mm:ss")

const courseTemplateDetailPayload = {
    type: tempCourseType?.ALL?.value
}

const addCourseAvailDetailPayload = {
    userId: null,
    name: null,
    description: null,
    type: null,
    minTimeBeforeAvailStart: 720,
    advanceSlotPeriodDays: 30,
    duration: 2,
    isRecurring: null,
    timeZone: null,
    weeklyTimeSlots: weekAvailTimeSlots,
    startDateTime: START_DATE_TIME,
    endType: courseEndTypeEnum?.ON_DATE?.value,
    endDateTime: dayjs(new Date(START_DATE_TIME).setDate(new Date(START_DATE_TIME).getDate() + 14))?.format("YYYY-MM-DD HH:mm:ss"),
    weeklyCycle: null,
    dateTimeSlots: []
}

export const COURSE_INITIAL_STATE = {
    selectedCourse: {
        isLoading: false,
        selectedCourse: null,
        errorMsg: null
    },

    // new course state

    userCourseList: {
        isLoading: false,
        data: null,
        message: null
    },

    userFavCourseList: {
        isLoading: false,
        data: null,
        message: null
    },

    addCourseFavDetail: {
        isLoading: false,
        message: null
    },
    deleteCourseFavDetail: {
        isLoading: false,
        message: null
    },

    publicCourseList: {
        isLoading: false,
        data: null,
        message: null
    },

    publicCourseDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    selectedPublicCourseDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    publicTutorCourseList: {
        isLoading: false,
        data: null,
        message: null
    },

    courseTemplateList: {
        isLoading: false,
        courseTemplateList: null,
        errorMsg: null
    },

    courseTemplateDetail: {
        isLoading: false,
        courseTemplateDetail: null,
        courseTemplateDetailPayload: courseTemplateDetailPayload,
        errorMsg: null
    },

    courseDetail: {
        isLoading: false,
        courseDetail: null,
        errorMsg: null
    },
    addCourseDetail: {
        isLoading: false,
        addCourseDetail: null,
        addCourseDetailPayload: null,
        errorMsg: null
    },

    courseTagList: {
        isLoading: false,
        courseTagList: null,
        errorMsg: null
    },
    addCourseTagDetail: {
        isLoading: false,
        addCourseTagDetail: null,
        errorMsg: null
    },

    courseTutorList: {
        isLoading: false,
        courseTutorList: null,
        errorMsg: null
    },

    courseTutorDetail: {
        isLoading: false,
        courseTutorDetail: null,
        errorMsg: null
    },

    courseProfList: {
        isLoading: false,
        courseProfList: null,
        errorMsg: null
    },
    addCourseProfDetail: {
        isLoading: false,
        addCourseProfDetail: null,
        errorMsg: null
    },

    courseLanguageList: {
        isLoading: null,
        courseLanguageList: null,
        errorMsg: null
    },
    addCourseLanguageDetail: {
        isLoading: false,
        addCourseLanguageDetail: null,
        errorMsg: null
    },

    courseAvailDetail: {
        isLoading: false,
        courseAvailDetail: null,
        errorMsg: null
    },
    addCourseAvailDetail: {
        isLoading: false,
        addCourseAvailDetail: null,
        addCourseAvailDetailPayload: addCourseAvailDetailPayload,
        errorMsg: null
    },

    coursePriceDetail: {
        isLoading: false,
        coursePriceDetail: null,
        errorMsg: null
    },
    addCoursePriceDetail: {
        isLoading: false,
        addCoursePriceDetail: null,
        errorMsg: null
    },

    coursePreviewDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    tutorCourseList: {
        isLoading: false,
        data: null,
        message: null
    },

    tutorCourseDetail: {
        isLoading: false,
        tutorCourseDetail: null,
        errorMsg: null
    },

    cloneCourseDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    // next course session detail
    nextCourseSessionDetail: {
        isLoading: false,
        data: null,
        payload: null,
        message: null
    },

    // course comment list
    courseCommentList: {
        isLoading: false,
        data: null,
        message: null
    },
    addCourseCommentDetail: {
        isLoading: false,
        message: null
    },

    // camera recording status
    cameraStatus: {
        isCameraOpen: false
    },

    filterProps: courseFilterProps
}