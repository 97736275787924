import { useCallback, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import { cn } from "utils/cn.utils";

import { IoCloseOutline } from 'react-icons/io5';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';

import ButtonLoader from 'components/loader/ButtonLoader';

import { createSendLmsCourseInvitationDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import userService from 'redux/user/user.service';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import {
    resetSendLmsCourseInvitationDetail,
    resetSendLmsCourseInvitationDetailPayload,
    setSendLmsCourseInvitationDetailMessage,
    setSendLmsCourseInvitationDetailPayload
} from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { USER_DEMO_PROFILE } from 'redux/user/user.const';
import { lmsCourseInviteToEnum } from 'redux/edulyteLms/lmsCourse/lmsCourse.const';

import { validateEmail } from 'utils/validation-functions';

const InviteLmsCourseUserModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { sendLmsCourseInvitationDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()
    const { courseId } = useParams()
    const inputRef = useRef()

    const [emailAddress, setEmailAddress] = useState("")
    const [invalidEmail, setInvalidEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        return () => {
            dispatcher(resetSendLmsCourseInvitationDetail())
            dispatcher(resetSendLmsCourseInvitationDetailPayload())
        }
    }, [])

    useEffect(() => {
        if (sendLmsCourseInvitationDetail?.data) {
            dispatcher(resetSendLmsCourseInvitationDetail())
            dispatcher(setModal({
                ...modal,
                [modalConst.inviteLmsCourseUserModal.key]: {
                    ...modal[modalConst.inviteLmsCourseUserModal.key],
                    isVisible: false,
                    title: ""
                }
            }))
        }
    }, [sendLmsCourseInvitationDetail?.data])

    useEffect(() => {
        if (emailAddress && !validateEmail(emailAddress)) {
            setInvalidEmail("Please enter valid email address.")
        }
        if (emailAddress && validateEmail(emailAddress)) {
            setInvalidEmail("")
        }
    }, [emailAddress])

    const onHandleRemoveEmailAddress = useCallback((inviteEmail) => {
        const filteredUsers = sendLmsCourseInvitationDetail?.payload?.invitedUsers?.filter((emailItem) => emailItem?.email != inviteEmail?.email)
        dispatcher(setSendLmsCourseInvitationDetailPayload({
            ...sendLmsCourseInvitationDetail?.payload,
            invitedUsers: filteredUsers
        }))
        setEmailAddress("")
        if (sendLmsCourseInvitationDetail?.message) {
            dispatcher(setSendLmsCourseInvitationDetailMessage(null))
        }
    }, [sendLmsCourseInvitationDetail?.payload, emailAddress])

    const onHandleChangeEmailAddress = (event) => {
        if (sendLmsCourseInvitationDetail?.message) {
            dispatcher(setSendLmsCourseInvitationDetailMessage(null))
        }
        setEmailAddress(event?.target?.value?.toLowerCase())
    }

    const onHandleAddUser = async () => {
        if (emailAddress && !validateEmail(emailAddress)) {
            setInvalidEmail("Please enter valid email address.")
            return;
        }
        if (sendLmsCourseInvitationDetail?.payload?.invitedUsers?.length > 50) {
            setInvalidEmail("Maximum 50 emails can be sent.")
            return;
        }
        if (sendLmsCourseInvitationDetail?.payload?.invitedUsers?.length > 0) {
            const filterDuplicateEmail = sendLmsCourseInvitationDetail?.payload?.invitedUsers?.filter((emailItem) => (emailItem?.email === emailAddress))
            if (filterDuplicateEmail?.length > 0) {
                toast.warn("Duplicate emails are not allowed!")
                return;
            }
        }
        setIsLoading(true)
        let userObject = {
            name: emailAddress,
            profilePicUrl: USER_DEMO_PROFILE,
            email: emailAddress,
            isPlatformUser: false
        }
        const requestPayload = {
            query: { email: emailAddress }
        }
        const response = await userService.getUserList(requestPayload)
        if ((response.status === 200) && (response?.data.data?.records > 0)) {
            userObject = {
                name: `${response?.data.data?.results[0]?.firstName} ${response?.data.data?.results[0]?.lastName?.charAt(0)}`,
                profilePicUrl: response?.data.data?.results[0]?.profilePicUrl,
                email: response?.data?.data?.results[0]?.email,
                isPlatformUser: true
            }
        }
        dispatcher(setSendLmsCourseInvitationDetailPayload({
            ...sendLmsCourseInvitationDetail?.payload,
            invitedUsers: [...sendLmsCourseInvitationDetail?.payload?.invitedUsers, userObject]
        }))
        setIsLoading(false)
        setEmailAddress("")
        inputRef.current.focus()
    }

    const onHandleKeyDown = (e) => {
        if ((e.keyCode === 13) && emailAddress) {
            onHandleAddUser()
        }
        else if ((e.keyCode === 8) && (!emailAddress) && !!sendLmsCourseInvitationDetail?.payload?.invitedUsers) {
            const filteredUsers = sendLmsCourseInvitationDetail?.payload?.invitedUsers?.slice(0, -1)
            dispatcher(setSendLmsCourseInvitationDetailPayload({
                ...sendLmsCourseInvitationDetail?.payload,
                invitedUsers: filteredUsers
            }))
        }
    }

    const onHandleSendInvite = useCallback(() => {
        if (sendLmsCourseInvitationDetail?.isLoading || emailAddress || invalidEmail) return;

        if (!sendLmsCourseInvitationDetail?.payload?.invitedUsers || (sendLmsCourseInvitationDetail?.payload?.invitedUsers?.length === 0)) return;

        const body = {
            lms_course_id: courseId,
            emails: sendLmsCourseInvitationDetail?.payload?.invitedUsers?.map((invitedUser) => invitedUser?.email),
            invite_to: lmsCourseInviteToEnum.STUDENT.value,
            invite_email_sent: true,
            sender_user_id: user?.user?.userId
        }
        dispatcher(createSendLmsCourseInvitationDetail(body))
    }, [sendLmsCourseInvitationDetail, emailAddress, invalidEmail])

    return (
        <div className={"space-y-5"}>
            <div className={'min-h-[14rem] block space-y-5'}>
                <div className={"w-full flex items-start justify-start gap-2"}>
                    <span className={"font-bodyPri font-medium text-text-900 text-lg mt-0.5"}>
                        {"To:"}
                    </span>
                    <div className={cn(
                        "w-full inline-flex flex-wrap items-center justify-start gap-2 p-2",
                        "bg-background-light border-b focus-within:border-primary-main"
                    )}
                    >
                        {sendLmsCourseInvitationDetail?.payload?.invitedUsers?.map((userItem, index) => (
                            <div
                                key={index}
                                className={cn(
                                    "w-fit pl-1 pr-2 py-1 flex items-center justify-start gap-1.5 bg-white shadow-sm border rounded-full",
                                    !userItem?.isPlatformUser && "border-complementry-main"
                                )}
                            >
                                <div className={"relative w-[1.5rem] h-[1.5rem] rounded-full overflow-hidden"}>
                                    <img
                                        src={userItem?.profilePicUrl}
                                        className={"w-full h-full object-cover"}
                                        alt={""}
                                    />
                                </div>
                                <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wide"}>
                                    {userItem?.name}
                                </span>
                                <IoCloseOutline
                                    className={"text-text-700 hover:text-secondary-main text-md md:text-xl cursor-pointer"}
                                    onClick={() => onHandleRemoveEmailAddress(userItem)}
                                />
                            </div>
                        ))}
                        <div className={"flex flex-1 items-center justify-start gap-1.5"}>
                            <input
                                type={"email"}
                                ref={inputRef}
                                className={cn(
                                    "flex-1 focus:outline-none bg-transparent focus:border-primary-main",
                                    "font-bodyPri font-normal text-text-900 text-base placeholder:text-text-500"
                                )}
                                placeholder={"Enter email address"}
                                value={emailAddress}
                                onChange={onHandleChangeEmailAddress}
                                autoFocus={true}
                                onKeyDown={onHandleKeyDown}
                            />
                            {/* <AiOutlineCheckCircle
                                className={"text-2xl text-text-800 cursor-pointer"}
                                onClick={onHandleAddUser}
                            /> */}
                        </div>
                        {isLoading &&
                            <div className={"flex justify-end"}>
                                <FaSpinner className={"animate-spin text-primary-dark"} />
                            </div>
                        }
                    </div>
                </div>
                <div className={"w-full flex items-center justify-center gap-1 mt-1"}>
                    {((emailAddress?.length > 0) && !!invalidEmail) &&
                        <span className={"font-bodyPri font-normal text-red-500 text-sm tracking-wide"}>
                            {invalidEmail}
                        </span>
                    }
                </div>
            </div>

            <div className={"w-full flex items-center justify-center"}>
                {sendLmsCourseInvitationDetail?.message &&
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide text-center"}>
                        {sendLmsCourseInvitationDetail?.message}
                    </span>
                }
            </div>

            <div className={"flex justify-end"}>
                <span
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base text-secondary-main",
                        !sendLmsCourseInvitationDetail?.isLoading && "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                        sendLmsCourseInvitationDetail?.isLoading && "bg-secondary-main",
                        (!sendLmsCourseInvitationDetail?.payload?.invitedUsers || (sendLmsCourseInvitationDetail?.payload?.invitedUsers?.length === 0) || emailAddress || invalidEmail) && "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light"
                    )}
                    onClick={onHandleSendInvite}
                >
                    {sendLmsCourseInvitationDetail?.isLoading &&
                        <ButtonLoader isLoading={sendLmsCourseInvitationDetail?.isLoading} />
                    }
                    {!sendLmsCourseInvitationDetail?.isLoading && "Send"}
                </span>
            </div>
        </div>
    )
}

export default InviteLmsCourseUserModal;