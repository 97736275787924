import { useContext } from 'react';

import { DragContext } from './Drag';

function DropZone({ as, dropId, dropStatus, dropType, remember, style, children, ...props }) {
    const { dragItem, dragType, setDrop, drop, onDrop, setStatus } = useContext(DragContext);

    function handleDragEnter(e) {
        if (dragItem && (dropType === dragType)) {
            setDrop(dropId)
            setStatus(dropStatus)
        }
    }

    function handleDragOver(e) {
        if (e.preventDefault) {
            e.preventDefault();
        }
        return false;
    };

    function handleLeave() {
        if (!remember) {
            setDrop(null);
            setStatus(null)
        }
    };

    let Component = as || "div";

    return (
        <Component
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDrop={onDrop}
            style={{ position: "relative", ...style }}
            {...props}
        >
            {children}
            {(drop === dropId) &&
                <div
                    style={{ position: "absolute", inset: "0px" }}
                    onDragLeave={handleLeave}
                >
                </div>
            }
        </Component>
    );
};

export default DropZone;