import { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { motion } from "framer-motion";

import { AiOutlineClose } from "react-icons/ai";

import ButtonLoader from "components/loader/ButtonLoader";
import AiTextButton from "components/createWithAi/aiTextButton";

import { addLmsQuizDetail } from "redux/edulyteLms/lmsQuiz/lmsQuiz.request";

import useAiText from "hooks/useAiText";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetAddLmsQuiz } from "redux/edulyteLms/lmsQuiz/lmsQuiz.slice";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

import { cn } from "utils/cn.utils";
import { pagesInfo } from "utils/pagesInfo";

import { EDULYTE_APP_DOMAIN_URL } from "const/default.const";

const MAX_LENGTH = 128;

const CreateLmsQuizModal = ({
    openModal = false,
    onCloseModal,
    onBackModal,
    maxWidth = "max-w-2xl",
    bgColor = "bg-white",
}) => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { addLmsQuiz } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const navigate = useNavigate()

    const [titleError, setTitleError] = useState("")
    const [title, setTitle] = useState('');
    const [isShowInput, setIsShowInput] = useState(false)
    const [topic, setTopic] = useState('')

    const { aiText, generateAiText } = useAiText()

    const requestData = {
        purpose: "title", // description or title 
        total_chars: 85,
        topic: topic,
        response_type: "text" // text or html 
    };

    const getAiText = () => {
        if (topic === "") {
            toast.error("Please write ai prompt");
            return
        }
        setIsShowInput(false)
        generateAiText(requestData);
    };

    const handlePrompt = (e) => {
        const value = e.target.value
        setTopic(value)
    }

    useEffect(() => {
        if (aiText?.data?.ai_response) {
            setTitle(aiText.data.ai_response);
        }
    }, [aiText?.data]);

    const closeModal = () => {
        navigate(location.pathname)
        setTitle('')
        setTitleError("")
        onCloseModal(false)
    }

    useEffect(() => {
        if (addLmsQuiz?.data) {
            const quizId = addLmsQuiz?.data?.id
            dispatcher(resetAddLmsQuiz())
            setTitle("")
            setTitleError("")
            dispatcher(setModal({
                ...modal,
                [modalConst.CREATE_LMS_QUIZ_MODAL.stateKey]: false
            }))
            window.open(`${EDULYTE_APP_DOMAIN_URL}${pagesInfo.EDIT_LMS_QUIZ.pagePath}/${quizId}/edit`, "_self")
        }
    }, [addLmsQuiz?.data])

    useEffect(() => {
        return () => {
            dispatcher(resetAddLmsQuiz())
        }
    }, [])

    const handleCreateBtn = () => {
        if (!title || title?.length === 0) {
            setTitleError("Please enter title!")
            return;
        }
        const body = {
            title: title,
            owner_user_id: user?.user?.userId
        }
        dispatcher(addLmsQuizDetail(body))
        setTitleError("")
    }

    return (
        <Transition appear show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className="fixed max-h-screen inset-0 z-50 overflow-y-auto"
                open={openModal}
                onClose={closeModal}
            >
                <div className="p-5 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span aria-hidden="true" className="inline-block h-screen align-middle">&#8203;</span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={cn(
                            "inline-block w-full shadow-xl rounded-lg space-y-5 overflow-hidden",
                            `text-left align-middle transition-all transform ${maxWidth} ${bgColor}`
                        )}>
                            <Dialog.Description as="div">
                                <div className={cn(
                                    "w-full h-full overflow-hidden overflow-y-scroll",
                                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                                )}>
                                    <div className={"flex w-full justify-between items-center bg-background-light h-16 px-5 drop-shadow-sm"}>
                                        <p className="font-bodyPri font-medium text-text-900 text-lg">
                                            {"Create a quiz"}
                                        </p>
                                        <AiOutlineClose
                                            onClick={closeModal}
                                            className={'cursor-pointer'}
                                            size={20}
                                        />
                                    </div>
                                    <div className={"flex flex-col gap-5 bg-white p-10"}>
                                        <div className={"w-full flex flex-col gap-3"}>
                                            <div className="relative flex justify-between items-center">
                                                <AiTextButton
                                                    isShowInput={isShowInput}
                                                    setIsShowInput={setIsShowInput}
                                                    handlePrompt={handlePrompt}
                                                    getAiText={getAiText}
                                                    aiText={aiText}
                                                />
                                                <div className={"w-full flex justify-end"}>
                                                    {(title?.length > 0) &&
                                                        <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                                                            {`${title?.length}/${MAX_LENGTH}`}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                            <input
                                                className={cn(
                                                    "px-3 h-12 flex w-full focus:outline-none rounded-lg border border-background-lightDark focus:border focus:border-primary-main",
                                                    "font-bodyPri font-normal text-text-800 text-base placeholder:text-text-500"
                                                )}
                                                type={"text"}
                                                placeholder={'Add Quiz Title'}
                                                value={title}
                                                maxLength={MAX_LENGTH}
                                                onChange={(e) => setTitle(e.target.value)}
                                            />
                                            <div className={"flex items-center justify-start"}>
                                                {(titleError || addLmsQuiz?.message) &&
                                                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                                                        {titleError || addLmsQuiz?.message}
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <motion.button
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                            className={cn(
                                                'font-bodyPri rounded-full py-2 w-36 ease-in-out duration-100 self-end',
                                                addLmsQuiz?.isLoading && "bg-primary-dark",
                                                !addLmsQuiz?.isLoading && "bg-primary-dark hover:bg-secondary-dark text-white"
                                            )}
                                            onClick={handleCreateBtn}
                                        >
                                            {addLmsQuiz?.isLoading &&
                                                <ButtonLoader isLoading={addLmsQuiz?.isLoading} />
                                            }
                                            {!addLmsQuiz?.isLoading && "Create"}
                                        </motion.button>
                                    </div>
                                </div>
                            </Dialog.Description>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

export default CreateLmsQuizModal;