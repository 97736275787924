export const professionalReferenceBtnName = {
    not_submitted: 'Add Reference',
    submitted: "Submitted",
    under_review: "Submitted",
    verified: "Document approved",
    re_submit: "Upload Documents",
    rejected: "Rejected"
}

export const onBoardingStatus = {
    NOT_SUBMITTED: {
        label: "Not Submitted",
        value: "not_submitted",
        color: "text-red-500"
    },
    PENDING: {
        label: "Pending",
        value: "pending",
        color: "text-yellow-500"
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted",
        color: "text-yellow-500"
    },
    UNDER_REVIEW: {
        label: "Under Review",
        value: "under_review",
        color: "text-secondary-main"
    },
    VERIFIED: {
        label: "Verified",
        value: "verified",
        color: "text-green-500"
    },
    RE_UPLOAD: {
        label: "Re Upload",
        value: "re_upload",
        color: "text-orange-500"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        color: "text-red-500"
    }
}

export const tHeader = [
    "Category",
    "File Name",
    "status",
    "Edit",
    "Delete"
];

export const ReferenceTableHeader = [
    "Reference Name",
    "Relationship",
    "Years or Months",
    "Phone",
    "Email",
    "Edit",
    "Delete"
]

export const TutorPreferenceConst = {
    TUTOR_PROFILE: {
        label: "Tutor Profile",
        value: "isTutorProfileDone",
        btnName: "Update",
        link: `/settings/profile`
    },
    AVAILABILITY: {
        label: "Availability",
        value: "isAvailabilityDone",
        btnName: "Update",
        link: `/settings/preferences/availability`
    },
    PRICING_PREFERENCE: {
        label: "Pricing Preference",
        value: "isPricePrefDone",
        btnName: "Update",
        link: `/settings/preferences/price`
    },
    LESSON_PREFERENCE: {
        label: "Lesson Preference",
        value: "isLessonPrefDone",
        btnName: "Update",
        link: `/settings//preferences/lesson`
    },
    SUBJECT_PREFERENCE: {
        label: "Subject Preference",
        value: "isSubjectPrefDone",
        btnName: "Update",
        link: `/settings/preferences/subjects`
    }
}

export const videoCategory = {
    INTRO_VIDEO: {
        label: "Intro Video",
        value: "intro_video"
    }
}

export const videoTableHeader = [
    "Category",
    "File Name",
    "Status",
    "Edit",
    "Delete"
]

export const selectedSlotTableHeader = [
    "Interviewer",
    "Date",
    "Start Time",
    "Status",
    "Edit",
    "Delete"
]

export const monthsOptions = {
    JANUARY: {
        label: "0",
        value: "0"
    },
    FEBRUARY: {
        label: "1",
        value: "1"
    },
    MARCH: {
        label: "2",
        value: "2"
    },
    APRIL: {
        label: "3",
        value: "3"
    },
    MAY: {
        label: "4",
        value: "4"
    },
    JUNE: {
        label: "5",
        value: "5"
    },
    JULY: {
        label: "6",
        value: "6"
    },
    AUGUST: {
        label: "7",
        value: "7"
    },
    SEPTEMBER: {
        label: "8",
        value: "8"
    },
    OCTOBER: {
        label: "9",
        value: "9"
    },
    NOVEMBER: {
        label: "10",
        value: "10"
    },
    DECEMBER: {
        label: "11",
        value: "11"
    },
}