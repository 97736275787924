import { toast } from "react-toastify";

import LmsQuestionService from "redux/edulyteLms/lmsQuestion/lmsQuestion.service";

import {
    setLmsQuestionListLoading,
    setLmsQuestionListData,
    setLmsQuestionListMessage,

    setLmsQuestionDetailLoading,
    setLmsQuestionDetailData,
    setLmsQuestionDetailMessage,

    setAddLmsQuestionDetailLoading,
    setAddLmsQuestionDetailData,
    setAddLmsQuestionDetailMessage,

    setUpdateLmsQuestionLoading,
    setUpdateLmsQuestionMessage,

    setAddLmsQuestionResourceLoading,
    setAddLmsQuestionResourceMessage,

    setDeleteLmsQuestionResourceLoading,
    setDeleteLmsQuestionResourceMessage,

    setAddLmsQuestionOptionLoading,
    setAddLmsQuestionOptionMessage,

    setUpdateLmsQuestionOptionLoading,
    setUpdateLmsQuestionOptionMessage,

    setUpdateOrderLmsQuestionOptionLoading,
    setUpdateOrderLmsQuestionOptionMessage,

    setDeleteLmsQuestionOptionLoading,
    setDeleteLmsQuestionOptionMessage,

    setAddLmsQuestionOptionResourceLoading,
    setAddLmsQuestionOptionResourceMessage,

    setDeleteLmsQuestionOptionResourceLoading,
    setDeleteLmsQuestionOptionResourceMessage,

    setUpdateLmsQuestionAnswerLoading,
    setUpdateLmsQuestionAnswerMessage,

    setAddLmsQuestionAnswerResourceLoading,
    setAddLmsQuestionAnswerResourceMessage,

    setDeleteLmsQuestionAnswerResourceLoading,
    setDeleteLmsQuestionAnswerResourceMessage,

    setAddLmsQuestionInstructorDetailLoading,
    setAddLmsQuestionInstructorDetailData,
    setAddLmsQuestionInstructorDetailMessage,

    setModifyLmsQuestionInstructorDetailLoading,
    setModifyLmsQuestionInstructorDetailData,
    setModifyLmsQuestionInstructorDetailMessage,

    setDestroyLmsQuestionInstructorDetailLoading,
    setDestroyLmsQuestionInstructorDetailMessage,

    setLmsQuestionTagListLoading,
    setLmsQuestionTagListData,
    setLmsQuestionTagListMessage,

    setAddLmsQuestionTagDetailLoading,
    setAddLmsQuestionTagDetailMessage,

    setModifyLmsQuestionTagListOrderLoading,
    setModifyLmsQuestionTagListOrderMessage,

    setDestroyLmsQuestionTagDetailLoading,
    setDestroyLmsQuestionTagDetailMessage,

    setLmsQuestionPageResourceListLoading,
    setLmsQuestionPageResourceListData,
    setLmsQuestionPageResourceListMessage,

    setLmsCreateBulkQuestionsLoading,
    setLmsCreateBulkQuestionsMessage,
    setLmsCreateBulkQuestionsData,
} from "redux/edulyteLms/lmsQuestion/lmsQuestion.slice";
import { lmsQuestionTypeEnum } from "./lmsQuestion.const";

import lmsResourceService from "../lmsResource/lmsResource.service";

export const getLmsQuestionList = (query) => async (dispatch) => {
    dispatch(setLmsQuestionListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await LmsQuestionService.getLmsQuestionList(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuestionListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setLmsQuestionListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setLmsQuestionListLoading(false))
    }
}

export const getLmsQuestionDetail = (questionId) => async (dispatch) => {
    dispatch(setLmsQuestionDetailLoading(true))

    try {
        const requestData = {
            params: { questionId: questionId }
        }
        const response = await LmsQuestionService.getLmsQuestionDetail(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuestionDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setLmsQuestionDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setLmsQuestionDetailLoading(false))
    }
}

export const createLmsQuestionDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsQuestionDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await LmsQuestionService.createLmsQuestionDetail(requestData)
        if (response.status === 201) {
            dispatch(getLmsQuestionList({ page: 1, records: 10 }))
            dispatch(setAddLmsQuestionDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsQuestionDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsQuestionDetailLoading(false))
    }
}

// update lms question detail
export const updateLmsQuestionDetail = (questionId, body, payload = { isShowToast: true }) => async (dispatch, getState) => {
    dispatch(setUpdateLmsQuestionLoading(true))

    try {
        const requestData = {
            params: { questionId: questionId },
            body: body
        }
        const response = await LmsQuestionService.updateLmsQuestionDetail(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuestionDetailData(response.data.data))
            if (payload?.isShowToast) {
                toast.success(response.data.message)
            }
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setUpdateLmsQuestionMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setUpdateLmsQuestionLoading(false))
    }
}

// create lms question resource
export const createLmsQuestionResource = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsQuestionResourceLoading(true))

    try {
        const { lmsQuestionDetail } = getState().lms.lmsQuestion
        const requestData = {
            body: body
        }
        const response = await LmsQuestionService.createLmsQuestionResource(requestData)
        if (response.status === 201) {
            dispatch(setLmsQuestionDetailData({
                ...lmsQuestionDetail?.data,
                question_resources: [...lmsQuestionDetail?.data?.question_resources, response.data.data]
            }))
            toast.success(response.data.message)
        } else if (response.status === 200) {
            toast.warn(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsQuestionResourceMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setAddLmsQuestionResourceLoading(false))
    }
}

// delete lms question resource
export const deleteLmsQuestionResourceDetail = (questionResourceId) => async (dispatch, getState) => {
    dispatch(setDeleteLmsQuestionResourceLoading(true))

    try {
        const { lmsQuestionDetail } = getState().lms.lmsQuestion
        const requestData = {
            params: { questionResourceId: questionResourceId }
        }
        const response = await LmsQuestionService.deleteLmsQuestionResourceDetail(requestData)
        if (response.status === 204) {
            dispatch(setLmsQuestionDetailData({
                ...lmsQuestionDetail?.data,
                question_resources: lmsQuestionDetail?.data?.question_resources?.filter((questionResource) => questionResource?.id != questionResourceId)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDeleteLmsQuestionResourceMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDeleteLmsQuestionResourceLoading(false))
    }
}

// create question options
export const createLmsQuestionOption = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsQuestionOptionLoading(true))

    try {
        const { lmsQuestionDetail } = getState().lms.lmsQuestion
        const requestData = {
            body: body
        }
        const response = await LmsQuestionService.createLmsQuestionOption(requestData)
        if (response.status === 201) {
            dispatch(setLmsQuestionDetailData({
                ...lmsQuestionDetail?.data,
                question_options: [...lmsQuestionDetail?.data?.question_options, response.data.data]
            }))
            // dispatch(getLmsQuestionDetail(body?.question_id))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsQuestionOptionMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsQuestionOptionLoading(false))
    }
}

// update lms question option
export const updateLmsQuestionOptionDetail = (optionId, body, payload = { isShowToast: true }) => async (dispatch, getState) => {
    dispatch(setUpdateLmsQuestionOptionLoading(true))

    try {
        const { lmsQuestionDetail } = getState().lms.lmsQuestion
        const requestData = {
            params: { optionId: optionId },
            body: body
        }
        const response = await LmsQuestionService.updateLmsQuestionOptionDetail(requestData)
        if (response.status === 200) {
            if ([lmsQuestionTypeEnum.MULTIPLE_CHOICE.value]?.includes(lmsQuestionDetail?.data?.type)) {
                dispatch(setLmsQuestionDetailData({
                    ...lmsQuestionDetail?.data,
                    question_options: lmsQuestionDetail?.data?.question_options?.map((questionOption) => (
                        (questionOption?.option_id === optionId) ? response.data.data : questionOption
                    ))
                }))
            } else if ([lmsQuestionTypeEnum.SINGLE_CHOICE.value]?.includes(lmsQuestionDetail?.data?.type)) {
                dispatch(setLmsQuestionDetailData({
                    ...lmsQuestionDetail?.data,
                    question_options: lmsQuestionDetail?.data?.question_options?.map((questionOption) => (
                        (questionOption?.option_id === optionId) ? response.data.data : { ...questionOption, is_correct: false }
                    ))
                }))
            }
            if (payload?.isShowToast) {
                toast.success(response.data.message)
            }
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setUpdateLmsQuestionOptionMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setUpdateLmsQuestionOptionLoading(false))
    }
}

// update order lms question option
export const updateOrderLmsQuestionOption = (body, query) => async (dispatch, getState) => {
    dispatch(setUpdateOrderLmsQuestionOptionLoading(true))

    try {
        const { lmsQuestionDetail } = getState().lms.lmsQuestion
        const requestData = {
            body: body,
            query: query
        }
        const response = await LmsQuestionService.updateOrderLmsQuestionOption(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuestionDetailData({
                ...lmsQuestionDetail?.data,
                question_options: response.data.data
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setUpdateOrderLmsQuestionOptionMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setUpdateOrderLmsQuestionOptionLoading(false))
    }
}

// delete lms question option
export const deleteLmsQuestionOptionDetail = (optionId) => async (dispatch, getState) => {
    dispatch(setDeleteLmsQuestionOptionLoading(true))

    try {
        const { lmsQuestionDetail } = getState().lms.lmsQuestion
        const requestData = {
            params: { optionId: optionId }
        }
        const response = await LmsQuestionService.deleteLmsQuestionOptionDetail(requestData)
        if (response.status === 200 || response.status === 204) {
            dispatch(setLmsQuestionDetailData({
                ...lmsQuestionDetail?.data,
                question_options: lmsQuestionDetail?.data?.question_options?.filter((questionOption) => questionOption?.option_id != optionId)
            }))
            // dispatch(getLmsQuestionDetail(lmsQuestionDetail?.data?.id))
            toast.success(response.data.message || "Deleted successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDeleteLmsQuestionOptionMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDeleteLmsQuestionOptionLoading(false))
    }
}

// create lms question option resource
export const createLmsQuestionOptionResource = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsQuestionOptionResourceLoading(true))

    try {
        const { lmsQuestionDetail } = getState().lms.lmsQuestion
        const requestData = {
            body: body
        }
        const response = await LmsQuestionService.createLmsQuestionOptionResource(requestData)
        if (response.status === 201) {
            // toBeResolved
            dispatch(setLmsQuestionDetailData({
                ...lmsQuestionDetail?.data,
                question_options: lmsQuestionDetail?.data?.question_options?.map((questionOption) => (
                    (questionOption?.option_id === body?.question_option_id)
                        ? { ...questionOption, question_option_resources: [...questionOption?.question_option_resources, response.data.data] }
                        : questionOption
                ))
            }))
            // dispatch(getLmsQuestionDetail(lmsQuestionDetail?.data?.id))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsQuestionOptionResourceMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsQuestionOptionResourceLoading(false))
    }
}

// delete lms question option resource
export const deleteLmsQuestionOptionResourceDetail = (optionResourceId, questionOptionId = null) => async (dispatch, getState) => {
    dispatch(setDeleteLmsQuestionOptionResourceLoading(true))

    try {
        const { lmsQuestionDetail } = getState().lms.lmsQuestion
        const requestData = {
            params: { optionResourceId: optionResourceId }
        }
        const response = await LmsQuestionService.deleteLmsQuestionOptionResourceDetail(requestData)
        if (response.status === 204) {
            // toBeResolved
            if (questionOptionId) {
                dispatch(setLmsQuestionDetailData({
                    ...lmsQuestionDetail?.data,
                    question_options: lmsQuestionDetail?.data?.question_options?.map((questionOption) => (
                        (questionOption?.option_id === questionOptionId)
                            ? {
                                ...questionOption,
                                question_option_resources: questionOption?.question_option_resources?.filter((questionOptionResource) => questionOptionResource?.id != optionResourceId)
                            }
                            : questionOption
                    ))
                }))
            }
            // dispatch(getLmsQuestionDetail(lmsQuestionDetail?.data?.id))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDeleteLmsQuestionOptionResourceMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDeleteLmsQuestionOptionResourceLoading(false))
    }
}

// update lms question answer
export const updateLmsQuestionAnswerDetail = (answerId, body, payload = { isShowToast: true }) => async (dispatch, getState) => {
    dispatch(setUpdateLmsQuestionAnswerLoading(true))

    try {
        const { lmsQuestionDetail } = getState().lms.lmsQuestion
        const requestData = {
            params: { answerId: answerId },
            body: body
        }
        const response = await LmsQuestionService.updateLmsQuestionAnswerDetail(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuestionDetailData({
                ...lmsQuestionDetail?.data,
                answer: response.data.data
            }))
            if (payload?.isShowToast) {
                toast.success(response.data.message)
            }
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setUpdateLmsQuestionAnswerMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setUpdateLmsQuestionAnswerLoading(false))
    }
}

// create lms question answer resource
export const createLmsQuestionAnswerResource = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsQuestionAnswerResourceLoading(true))

    try {
        const { lmsQuestionDetail } = getState().lms.lmsQuestion
        const requestData = {
            body: body
        }
        const response = await LmsQuestionService.createLmsQuestionAnswerResource(requestData)
        if (response.status === 201) {
            dispatch(getLmsQuestionDetail(lmsQuestionDetail?.data?.id))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsQuestionAnswerResourceMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsQuestionAnswerResourceLoading(false))
    }
}

// delete lms question answer resource
export const deleteLmsQuestionAnswerResourceDetail = (answerResourceId) => async (dispatch, getState) => {
    dispatch(setDeleteLmsQuestionAnswerResourceLoading(true))

    try {
        const { lmsQuestionDetail } = getState().lms.lmsQuestion
        const requestData = {
            params: { answerResourceId: answerResourceId }
        }
        const response = await LmsQuestionService.deleteLmsQuestionAnswerResourceDetail(requestData)
        if (response.status === 204) {
            dispatch(getLmsQuestionDetail(lmsQuestionDetail?.data?.id))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDeleteLmsQuestionAnswerResourceMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDeleteLmsQuestionAnswerResourceLoading(false))
    }
}

// lms question instructor detail
// create lms question instructor detail
export const createLmsQuestionInstructorDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsQuestionInstructorDetailLoading(true))

    try {
        const { lmsQuestionDetail } = getState().lms.lmsQuestion
        const requestData = {
            body: body
        }
        const response = await LmsQuestionService.createLmsQuestionInstructorDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddLmsQuestionInstructorDetailData(response.data.data))
            dispatch(setLmsQuestionDetailData({
                ...lmsQuestionDetail?.data,
                question_tutors: [...lmsQuestionDetail?.data?.question_tutors, response.data.data]
            }))
            // dispatch(getLmsQuestionDetail(body?.question_id))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddLmsQuestionInstructorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsQuestionInstructorDetailLoading(false))
    }
}

// update lms question instructor detail
export const updateLmsQuestionInstructorDetail = (questionInstructorId, body) => async (dispatch, getState) => {
    dispatch(setModifyLmsQuestionInstructorDetailLoading(true))

    try {
        const { lmsQuestionDetail } = getState().lms.lmsQuestion
        const requestData = {
            params: { questionInstructorId: questionInstructorId },
            body: body
        }
        const response = await LmsQuestionService.updateLmsQuestionInstructorDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyLmsQuestionInstructorDetailData(response.data.data))
            dispatch(setLmsQuestionDetailData({
                ...lmsQuestionDetail?.data,
                question_tutors: lmsQuestionDetail?.data?.question_tutors?.map((questionTutor) => (
                    (questionTutor?.id === response.data.data.id) ? response.data.data : questionTutor
                ))
            }))
            // dispatch(getLmsQuestionDetail(lmsQuestionDetail?.data?.id))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyLmsQuestionInstructorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyLmsQuestionInstructorDetailLoading(false))
    }
}

// delete lms question instructor detail
export const deleteLmsQuestionInstructorDetail = (questionInstructorId) => async (dispatch, getState) => {
    dispatch(setDestroyLmsQuestionInstructorDetailLoading(true))

    try {
        const { lmsQuestionDetail } = getState().lms.lmsQuestion
        const requestData = {
            params: { questionInstructorId: questionInstructorId }
        }
        const response = await LmsQuestionService.deleteLmsQuestionInstructorDetail(requestData)
        if (response.status === 204) {
            dispatch(setLmsQuestionDetailData({
                ...lmsQuestionDetail?.data,
                question_tutors: lmsQuestionDetail?.data?.question_tutors?.filter((questionTutor) => questionTutor?.id != questionInstructorId)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyLmsQuestionInstructorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyLmsQuestionInstructorDetailLoading(false))
    }
}

// lms question tag list
export const getLmsQuestionTagList = (query) => async (dispatch) => {
    dispatch(setLmsQuestionTagListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await LmsQuestionService.getLmsQuestionTagList(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuestionTagListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setLmsQuestionTagListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setLmsQuestionTagListLoading(false))
    }
}

// create lms question tag detail
export const createLmsQuestionTagDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsQuestionTagDetailLoading(true))

    try {
        const { lmsQuestionTagList } = getState().lms.lmsQuestion
        const requestData = {
            body: body
        }
        const response = await LmsQuestionService.createLmsQuestionTagDetail(requestData)
        if (response.status === 201) {
            dispatch(setLmsQuestionTagListData({
                ...lmsQuestionTagList?.data,
                results: [...lmsQuestionTagList?.data?.results, response.data.data]
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddLmsQuestionTagDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsQuestionTagDetailLoading(false))
    }
}

// update lms question tag list order
export const updateLmsQuestionTagListOrder = (body, query) => async (dispatch) => {
    dispatch(setModifyLmsQuestionTagListOrderLoading(true))

    try {
        const requestData = {
            body: body,
            query: query
        }
        const response = await LmsQuestionService.updateLmsQuestionTagListOrder(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuestionTagListData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.message || "Something went wrong!")
        dispatch(setModifyLmsQuestionTagListOrderMessage(error.response.data.message || error.response.data.message || "Something went wrong!"))
    } finally {
        dispatch(setModifyLmsQuestionTagListOrderLoading(false))
    }
}

// delete lms question tag detail
export const deleteLmsQuestionTagDetail = (questionTagId, query) => async (dispatch, getState) => {
    dispatch(setDestroyLmsQuestionTagDetailLoading(true))

    try {
        const { lmsQuestionTagList } = getState().lms.lmsQuestion
        const requestData = {
            params: { questionTagId: questionTagId },
            query: query
        }
        const response = await LmsQuestionService.deleteLmsQuestionTagDetail(requestData)
        if (response.status === 204) {
            dispatch(setLmsQuestionTagListData({
                ...lmsQuestionTagList?.data,
                results: lmsQuestionTagList?.data?.results?.filter((tagItem) => tagItem?.id !== questionTagId)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDestroyLmsQuestionTagDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setDestroyLmsQuestionTagDetailLoading(false))
    }
}

// page based request
export const getLmsQuestionPageResourceList = (query) => async (dispatch) => {
    dispatch(setLmsQuestionPageResourceListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await lmsResourceService.getLmsResourceList(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuestionPageResourceListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setLmsQuestionPageResourceListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setLmsQuestionPageResourceListLoading(false))
    }
}


export const createBulkQuestions = (body) => async (dispatch) => {
    dispatch(setLmsCreateBulkQuestionsLoading(true))

    try {
        const requestData = {
            body: body,
        }
        const response = await LmsQuestionService.createBulkQuestions(requestData)
        if (response.status === 201) {
            dispatch(setLmsCreateBulkQuestionsData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setLmsCreateBulkQuestionsMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setLmsCreateBulkQuestionsLoading(false))
    }
}