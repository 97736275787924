import format from "string-format";

import { baseAuthApiInstanceFormData } from "apiInstances/axios.apiInstance";

import { url } from "redux/storage/storage.const";

class StorageService {

    uploadFileToS3 = async ({ body }) => {
        
        const response = await baseAuthApiInstanceFormData().post(
            format(url.UPLOAD_FILE_TO_S3),
            body
        )

        return response;
    }
}

export default new StorageService();