import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";

import FilterBox from 'components/pageFilter/FilterBox';

import { searchParamsInfo } from "../../data";

import { DEFAULT_BOOKING_PAGE, bookingStatusEnum } from "redux/booking/booking.const";

import { cn } from "utils/cn.utils";

const BookingStatusFilter = memo(({ filterItem, activeFilter, setActiveFilter }) => {

    const navigate = useNavigate()
    const location = useLocation()

    const [statusFilterPayload, setStatusFilterPayload] = useState({
        selectedStatus: null,
        message: ""
    })

    let searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        if (!!searchQueryParams?.get(filterItem?.key)) {
            setStatusFilterPayload({
                ...statusFilterPayload,
                selectedStatus: searchQueryParams?.get(filterItem?.key)?.replaceAll("-", "_"),
                message: ""
            })
        } else {
            setStatusFilterPayload({
                ...statusFilterPayload,
                selectedStatus: null,
                message: ""
            })
        }
    }, [searchQueryParams?.get(filterItem?.key)])

    const onHandleSelectOption = useCallback((selectedStatus) => {
        setStatusFilterPayload({
            ...statusFilterPayload,
            selectedStatus: selectedStatus,
            message: ""
        })
    }, [statusFilterPayload])

    const onHandleMouseLeave = () => {
        if (!searchQueryParams?.get(filterItem?.key)) {
            setStatusFilterPayload({
                message: "",
                selectedStatus: null
            })
        }
        setActiveFilter(null)
    }

    const onHandleApplyFilter = useCallback(() => {
        if (!statusFilterPayload?.selectedStatus) {
            setStatusFilterPayload({
                ...statusFilterPayload,
                message: "Please select status!"
            })
            return;
        }
        setStatusFilterPayload({
            ...statusFilterPayload,
            message: ""
        })

        searchQueryParams.delete(filterItem.key);
        searchQueryParams.delete(searchParamsInfo.page.key);
        searchQueryParams.set(searchParamsInfo.page.key, DEFAULT_BOOKING_PAGE);
        searchQueryParams.set(filterItem.key, statusFilterPayload?.selectedStatus?.replaceAll("_", "-"))
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
        setActiveFilter(null)
    }, [statusFilterPayload, searchQueryParams])

    const onHandleClearFilter = useCallback((e) => {
        e.stopPropagation()
        setStatusFilterPayload({
            ...statusFilterPayload,
            selectedStatus: null,
            message: ""
        })

        searchQueryParams.delete(filterItem?.key)
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
        setActiveFilter(null)
    }, [searchQueryParams])

    return (
        <FilterBox
            boxTitle={filterItem?.label}
            boxIcon={!!searchQueryParams?.get(filterItem?.key)
                ? <button onClick={onHandleClearFilter}>
                    <AiOutlineCloseCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
                : <button>
                    <AiOutlinePlusCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
            }
            isActive={activeFilter === filterItem.key}
            onHandleToggleBox={() => setActiveFilter(filterItem.key)}
            onHandleMouseLeave={onHandleMouseLeave}
            boxItems={!!searchQueryParams?.get(filterItem?.key) && [searchQueryParams?.get(filterItem?.key)?.replaceAll("-", " ")]}
            activeContainerClassName={""}
        >
            <div className={"w-60 md:w-72 p-3 flex flex-col gap-5"}>
                <span className={"font-bodyPri font-bold text-text-900 text-base tracking-wide"}>
                    {"Filter By Status"}
                </span>
                <div className={"space-y-5"}>
                    {Object.values(bookingStatusEnum)?.filter((type) => (type?.value !== bookingStatusEnum.ALL.value))?.map((item, index) => (
                        <div
                            key={index}
                            className={"w-full flex items-center justify-start gap-3 group cursor-pointer"}
                            onClick={() => onHandleSelectOption(item?.value)}
                        >
                            <input
                                type={"radio"}
                                checked={(statusFilterPayload?.selectedStatus === item?.value) && true}
                            />
                            <span className={"font-bodyPri font-normal text-text-900 text-sm tracking-wide line-clamp-1"}>
                                {item?.label}
                            </span>
                        </div>
                    ))}
                </div>
                <div className={cn(
                    "w-full py-1 flex items-center justify-center rounded-md border bg-primary-dark cursor-pointer hover:opacity-90",
                    (!statusFilterPayload?.selectedStatus) && "bg-text-300 text-text-300 hover:!bg-text-300 cursor-not-allowed"
                )}
                    onClick={onHandleApplyFilter}
                >
                    <span className={"font-bodyPri font-medium text-text-50 text-sm tracking-wide "}>
                        {"Apply Filters"}
                    </span>
                </div>
            </div>
        </FilterBox>
    )
});

export default BookingStatusFilter;