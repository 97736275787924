import { cn } from "utils/cn.utils";


const SendAndCancelBtn = ({ onCancel, onSend }) => {
    return (
        <div className={"flex items-center justify-center gap-3"}>
            <div onClick={onCancel} className={cn(
                "flex item-center justify-center px-2 py-1 rounded-md cursor-pointer",
                "font-buttons font-normal text-base text-text-800 bg-background-darkLight",
                "hover:bg-background-medium hover:text-text-900"
            )}>
                <span>
                    {"Cancel"}
                </span>
            </div>
            <div onClick={onSend} className={cn(
                "flex item-center justify-center px-2 py-1 rounded-md cursor-pointer",
                "font-buttons font-normal text-base text-text-50 bg-primary-main",
                "hover:bg-primary-dark"
            )}>
                <span>
                    {"Send Code"}
                </span>
            </div>
        </div>
    )
}

export default SendAndCancelBtn