import EditLMSQuestionBuilderPage from "./components/questionBuilder/EditLMSQuestionBuilderPage";
import EditLMSQuestionSettingsPage from "./components/questionSettings/EditLMSQuestionSettingsPage";

import QuestionGeneralSettings from "./components/questionSettings/components/generalSettings/QuestionGeneralSettings";
import QuestionInstructor from "./components/questionSettings/components/questionInstructor/QuestionInstructor";

export const lmsQuestionTabConst = {
    BUILDER: {
        label: "Builder",
        value: "builder",
        pagePath: "/builder",
        routePath: "builder",
        component: EditLMSQuestionBuilderPage
    },
    SETTINGS: {
        value: "settings",
        pagePath: "/settings",
        routePath: "settings",
        label: "Settings",
        component: EditLMSQuestionSettingsPage
    }
}

export const getThumbnailUrl = (videoId) => {
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
}

export const questionSettingsNavigationOptions = {
    GENERAL: {
        label: "General settings",
        value: "general",
        pagePath: "/general",
        routePath: "general",
        to: "settings/general",
        component: QuestionGeneralSettings
    },
    INSTRUCTOR: {
        label: "Instructor",
        value: "instructor",
        pagePath: "/instructors",
        routePath: "instructors",
        to: "settings/instructors",
        component: QuestionInstructor
    },
}

export const questionInstructorHeaderConst = {
    Image: {
        label: "Image",
        value: "image"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    PERMISSION: {
        label: "Permission",
        value: "permission"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

export const questionInstructorMenuItemConst = {
    MAKE_DEFAULT: {
        label: "Make Default",
        value: "make_default"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    DELETE: {
        label: "Delete",
        value: "delete"
    }
}

export const lmsQuestionTooltips = {
    feedbackTooltip: "Learners will see this after answering the question.",
    tagsTooltip: "Type relevant keywords."
}