import { cn } from "utils/cn.utils";
import ComponentLoader from 'components/loader/ComponentLoader';

import { CollapsableContainer, ArrowToggleIcon } from 'pages/auth/onboarding/TutorOnboardingS1Style';

const CollapsableContent = ({
    isLoading = false,
    isOpen,
    value,
    title = "",
    onHandleToggle,
    children
}) => {

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const answerVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    }

    const arrowToggleVariants = {
        open: { rotate: 0 },
        collapsed: { rotate: 90 }
    }

    return (
        <div className={"w-full h-full select-none"}>
            <div
                className={cn(
                    "flex flex-row items-center justify-between px-5 py-3 cursor-pointer",
                    "rounded-lg border border-divider-darkLight hover:shadow-sm hover:bg-primary-light hover:shadow-all",
                    isOpen && "bg-primary-light"
                )}
                onClick={() => onHandleToggle(value)}
            >
                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {title}
                </div>
                <ArrowToggleIcon
                    variants={arrowToggleVariants}
                    initial={'collapsed'}
                    animate={isOpen ? 'open' : 'collapsed'}
                    transition={transition}
                />
            </div>

            <CollapsableContainer
                isOpen={isOpen}
                variants={answerVariants}
                initial={'collapsed'}
                animate={isOpen ? 'open' : 'collapsed'}
                transition={transition}
            >
                {isLoading && <ComponentLoader isLoading={isLoading} className='w-full h-fit' />}
                {isOpen && children}
            </CollapsableContainer>
        </div>
    )
}

export default CollapsableContent;