import { logInPayload, signUpPayload, addUserAuthPayload, addResetPasswordPayload } from "redux/auth/auth.const";

export const AUTH_INITIAL_STATE = {
    session: {
        isLoading: true,
        isLoggedIn: false,
        accessToken: null,
        message: null
    },

    logIn: {
        isLoading: false,
        payload: logInPayload,
        message: null
    },

    signUp: {
        isLoading: false,
        payload: signUpPayload,
        message: null
    },

    signupWithToken: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },

    verifySignup: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
        status: null,
    },
    
    signupS2: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
        status: null,
    },

    verifyReferral: {
        isLoading: false,
        verifyReferral: null,
        errorMsg: null
    },

    logout: {
        isLoading: false,
        logout: null,
        errorMsg: null
    },

    logoutCurrentUserAndSwitchToAdmin: {
        isLoading: false,
    },

    addResetPassword: {
        isLoading: false,
        addResetPassword: null,
        addResetPasswordPayload: addResetPasswordPayload,
        errorMsg: null
    },

    addUserAuth: {
        isLoading: false,
        addUserAuth: null,
        addUserAuthPayload: addUserAuthPayload,
        errorMsg: null
    },

    addUserPassword: {
        isLoading: false,
        addUserPassword: null,
        errorMsg: null
    },

    verifyAuth: {
        isLoading: false,
        verifyAuth: null,
        errorMsg: null
    },

    verifyUser: {
        isLoading: false,
        verifyUser: null,
        errorMsg: null
    },

    passwordReset: {
        isLoading: false,
        data: null,
        message: null
    },

    addPasswordReset: {
        isLoading: false,
        data: null,
        message: null
    },

    forgetPasswordLink: {
        isLoading: false,
        message: null
    },
}