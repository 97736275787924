import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";

import { FiSettings } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";

import FilterIcon from "components/pageFilter/FilterIcon";
import ClearFilter from "components/pageFilter/ClearFilter";
import ToolTipView from 'components/tooltipView';
import RefreshAndAddBtn from "components/common-components/RefreshAndAddBtn";

import { calendarFilters, getCalendarListPayload, searchParamsInfo } from "../../data";
import EventTypeFilter from "./EventTypeFilter";

import { getCalendarSessionUserList } from "redux/session/session.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setAddUserEventlyDetailPayload } from "redux/evently/evently.slice";
import { modalConst } from "redux/local/local.const";
import { addUserEventlyDetailPayload } from "redux/evently/evently.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const ScheduleFilter = () => {
  const { modal } = useAppState((state) => state.local);
  const { user } = useAppState((state) => state.user);
  const { calendarSessionUserList, filterProps } = useAppState((s) => s.session)

  const dispatcher = useAppDispatcher();
  const location = useLocation()
  const navigate = useNavigate();

  const [activeFilter, setActiveFilter] = useState(null)
  const [mobileFilter, setMobileFilter] = useState(false)

  const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])
  const isQueryParamsChanged = useMemo(() => searchQueryParams.get(searchParamsInfo.type.key), [location.search])

  useEffect(() => {
    if (calendarSessionUserList?.data) {
      getCalendarEventList()
    }
  }, [isQueryParamsChanged])

  const getCalendarEventList = () => {
    const viewProps = { startDateTime: filterProps?.startDateTime, endDateTime: filterProps?.endDateTime }
    const requestDataPayload = getCalendarListPayload(searchQueryParams, viewProps)
    dispatcher(getCalendarSessionUserList(user?.user?.userId, { ...requestDataPayload }))
  }

  const onHandleCreateEventDetail = () => {
    const tomorrowStartDateTime = dayjs(dayjs().add(1, 'day'))?.startOf('day')?.tz(timeZone)?.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
    const tomorrowEndDateTime = dayjs(dayjs().add(2, 'day'))?.startOf('day')?.tz(timeZone).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
    const slotInfo = {
      start: tomorrowStartDateTime,
      end: tomorrowEndDateTime
    }
    dispatcher(setAddUserEventlyDetailPayload({
      ...addUserEventlyDetailPayload,
      startDateTime: slotInfo?.start,
      endDateTime: slotInfo?.end
    }))
    dispatcher(setModal({
      ...modal,
      [modalConst.addEventlySlotModal.key]: {
        ...modal[modalConst.addEventlySlotModal.key],
        isVisible: true,
        title: "Add Event"
      }
    }))
  }

  const handleMobileFilter = () => {
    setMobileFilter(!mobileFilter)
  }

  const FilterItemList = () => {
    return (
      <>
        {Object.values(calendarFilters)?.map((filterItem, index) => (
          <div key={index}>
            {(filterItem.key === searchParamsInfo.type.key) &&
              <EventTypeFilter
                filterItem={filterItem}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
              />
            }
          </div>
        ))}
        <ClearFilter setFilterPayload={setActiveFilter} />
      </>
    )
  }

  return (
    <div className={"relative w-full px-6 py-3 bg-white shadow-md rounded-lg"}>
      <div className={"flex flex-col items-center justify-center gap-3 sm:flex-row sm:justify-between sm:gap-5"}>
          <AnimatePresence initial={mobileFilter}>
            {mobileFilter && (
              <motion.div
                initial={{ x: -200 }}
                animate={{ x: 0 }}
                exit={{ x: -200 }}
                transition={{ duration: 0.3 }}
                className={'absolute left-0 top-0 h-screen w-60 flex flex-col gap-5 p-5 bg-white rounded lg:hidden shadow-md z-10'}
              >
                <div className={'flex justify-between items-center'}>
                  <h3 className={'text-lg font-bodyPri font-semibold text-text-900'}>{"Filter"}</h3>
                  <MdOutlineClose size={20} onClick={() => setMobileFilter(false)} className={'cursor-pointer'} />
                </div>
                <FilterItemList />
              </motion.div>
            )}
          </AnimatePresence>
          <div className={'hidden w-full lg:flex flex-grow flex-wrap justify-center items-center sm:justify-start gap-3'}>
            <ToolTipView content={'Calendar Settings'}>
              <button onClick={() => navigate("/settings/general/calendar")}>
                <FiSettings size={20} />
              </button>
            </ToolTipView>
            <FilterIcon iconTooltip={"Filter Events"} />
            <FilterItemList />
          </div>
        <div className={"flex w-full items-center justify-between lg:justify-end gap-3"}>
          <div className={"flex lg:hidden items-center justify-start gap-3"}>
            <ToolTipView content={'Calendar Settings'}>
              <button onClick={() => navigate("/settings/general/calendar")}>
                <FiSettings size={20} />
              </button>
            </ToolTipView>
            <div onClick={handleMobileFilter} className={'text-primary-main font-medium hover:text-secondary-dark whitespace-nowrap cursor-pointer'}>
              {"Filter"}
            </div>
          </div>
          <RefreshAndAddBtn
            btnName={"Create My Event"}
            onHandleRefresh={getCalendarEventList}
            onHandleAddBtn={onHandleCreateEventDetail}
          />
        </div>
      </div>
    </div>
  );
};

export default ScheduleFilter;