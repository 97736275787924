import {verifyPromoPayload, redeemPromoPayload} from 'redux/promo/promo.const'

export const PROMO_INITIAL_STATE = {
    verifyPromo: {
        isLoading: false,
        verifyPromo: null,
        verifyPromoPayload: verifyPromoPayload,
        errorMsg: null,
    },

    redeemPromo: {
        isLoading: false,
        redeemPromo: null,
        redeemPromoPayload: redeemPromoPayload,
        errorMsg: null,
    },
}