import { useNavigate } from 'react-router-dom';

import { BsFillCheckCircleFill } from 'react-icons/bs';

import { Container } from "pages/auth/orderConfirmation/OrderConfirmationPageStyle";

import { useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';


const ConfirmedOrderConfirmation = () => {
  const { confirmationOrder } = useAppState((state) => state.order)

  const navigate = useNavigate()

  const pageRedirect = () => {
    navigate(`${pagesInfo.USER_FINANCE.pagePath}/orders`, { replace: true })
  }

  return confirmationOrder?.data ? (
    <Container className={"!shadow-all-md"}>
      <BsFillCheckCircleFill className={"text-8xl text-green-500"} />
      <div className={"flex items-center justify-center gap-2 font-bodyPri font-semibold text-2xl text-primary-dark"}>
        <span>{confirmationOrder?.data?.master_currency?.code?.toUpperCase()}</span>
        <span>{parseFloat(confirmationOrder?.data?.total_amount / 100).toFixed(2)}</span>
      </div>
      <div className={"flex flex-col items-center gap-2"}>
        <span className={"font-bodyPri font-medium text-lg text-text-800"}>
          {"Congratulations"}
        </span>
        <span className={"font-bodyPri font-medium text-md text-text-800"}>
          {"Transaction was successful"}
        </span>
      </div>
      <div className={"flex items-center justify-center gap-2"}>
        <span className={"font-bodyPri font-normal text-base text-800"}>
          {"Transaction ID:"}
        </span>
        <span className={"font-bodyPri font-semibold text-md text-primary-dark"}>
          {confirmationOrder?.data?.order_wallet_txns[0]?.wallet_txn?.virtualTxnId}
        </span>
      </div>
      <div className={"flex items-center justify-center gap-2"}>
        <span className={"font-bodyPri font-normal text-base text-800"}>
          {"Order No:"}
        </span>
        <span className={"font-bodyPri font-semibold text-md text-text-900"}>
          {confirmationOrder?.data?.order_no}
        </span>
      </div>
      <div onClick={pageRedirect}>
        <span className={"px-5 py-1.5 rounded-full cursor-pointer font-buttons font-medium text-md text-secondary-main border-2 border-secondary-main hover:text-text-50 hover:bg-secondary-main"}>
          {"Go To Order"}
        </span>
      </div>
    </Container>
  ) : null
}

export default ConfirmedOrderConfirmation