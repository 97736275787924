import { createSlice } from "@reduxjs/toolkit";
import { MEETING_INITIAL_STATE } from "redux/meeting/meeting.initialState";


const meeting = createSlice({
    name: "meeting",
    initialState: MEETING_INITIAL_STATE,
    reducers: {
        // Reducers for meeting
        setMeetingLoading: (state, { payload }) => {
            state.meeting.isLoading = payload
        },
        setMeeting: (state, { payload }) => {
            state.meeting.meeting = payload
            state.meeting.errorMsg = MEETING_INITIAL_STATE.meeting.errorMsg
        },
        setMeetingErrorMsg: (state, { payload }) => {
            state.meeting.errorMsg = payload
            state.meeting.meeting = MEETING_INITIAL_STATE.meeting.meeting
        },
        setClearMeeting: (state) => {
            state.meeting = MEETING_INITIAL_STATE.meeting
        },

        // Join Meeting Action
        setJoinMeetingLoading: (state, { payload }) => {
            state.joinMeeting.isLoading = payload
        },
        setJoinMeetingData: (state, { payload }) => {
            state.joinMeeting.data = payload
            state.joinMeeting.message = MEETING_INITIAL_STATE.joinMeeting.message
        },
        setJoinMeetingMessage: (state, { payload }) => {
            state.joinMeeting.message = payload
            state.joinMeeting.data = MEETING_INITIAL_STATE.joinMeeting.data
        },
        resetJoinMeeting: (state) => {
            state.joinMeeting = MEETING_INITIAL_STATE.joinMeeting
        }
    }
})

export const {
    setMeetingLoading,
    setMeeting,
    setMeetingErrorMsg,
    setClearMeeting,

    setJoinMeetingLoading,
    setJoinMeetingData,
    setJoinMeetingMessage,
    resetJoinMeeting
} = meeting.actions

export default meeting.reducer