import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { cn } from "utils/cn.utils";

import { MdAdd } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';
import ToolTipView from 'components/tooltipView';
import Pagination from 'components/pagination/Pagination';
import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';

import LMSTableComponent from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTableComponent';
import { lmsQuizTooltips, quizParticipantsNavigationOptions, quizStudentInvitationHeaderConst, quizStudentInviteMenuItemsConst } from '../../../editLMSQuiz.data';

import { deleteLmsQuizInvitationDetail, getLmsQuizInvitationList } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetLmsQuizInvitationList, resetSendLmsQuizInvitationDetail, resetSendLmsQuizInvitationDetailPayload } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsQuizInviteStatusEnum, lmsQuizInviteToEnum, lmsQuizStatusEnum } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';
import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';

const RECORDS = 10;

const InvitedParticipants = () => {
    const { modal } = useAppState((state) => state.local)
    const { lmsQuizDetail, lmsQuizInvitationList, sendLmsQuizInvitationDetail } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const query = generateQueryParams(location.search)

    useEffect(() => {

        return () => {
            dispatcher(resetLmsQuizInvitationList())
        }
    }, [])

    useEffect(() => {

        return () => {
            dispatcher(resetSendLmsQuizInvitationDetail())
            dispatcher(resetSendLmsQuizInvitationDetailPayload())
            dispatcher(setModal({
                ...modal,
                [modalConst.inviteLmsQuizUserModal.key]: {
                    ...modal[modalConst.inviteLmsQuizUserModal.key],
                    isVisible: false,
                    title: "Invite Student"
                }
            }))
        }
    }, [query?.action])

    useEffect(() => {
        if (lmsQuizDetail?.data) {
            if (query && (query?.action && (query?.action === "invite-participants"))) {
                if (lmsQuizDetail?.data?.quiz_setting?.status === lmsQuizStatusEnum?.PUBLISHED?.value) {
                    dispatcher(resetSendLmsQuizInvitationDetail())
                    dispatcher(resetSendLmsQuizInvitationDetailPayload())
                    dispatcher(setModal({
                        ...modal,
                        [modalConst.inviteLmsQuizUserModal.key]: {
                            ...modal[modalConst.inviteLmsQuizUserModal.key],
                            isVisible: true,
                            title: "Invite Student"
                        }
                    }))
                } else {
                    navigate(`${pagesInfo?.EDIT_LMS_QUIZ?.pagePath}/${lmsQuizDetail?.data?.id}/edit/${quizParticipantsNavigationOptions?.INVITED?.to}`)
                }
            }
        }
    }, [lmsQuizDetail?.data, query?.action]);

    useEffect(() => {
        if (sendLmsQuizInvitationDetail?.data) {
            navigate(`${pagesInfo?.EDIT_LMS_QUIZ?.pagePath}/${lmsQuizDetail?.data?.id}/edit/${quizParticipantsNavigationOptions?.INVITED?.to}`)
        }
    }, [sendLmsQuizInvitationDetail?.data])

    useEffect(() => {
        if (lmsQuizDetail?.data?.id) {
            const query = {
                page: 1,
                records: RECORDS,
                invite_to: lmsQuizInviteToEnum.STUDENT.value,
                quiz_id: lmsQuizDetail?.data?.id
            }
            dispatcher(getLmsQuizInvitationList(query))
        }
    }, [sendLmsQuizInvitationDetail?.data, lmsQuizDetail?.data?.id])

    const onHandleInviteStudent = useCallback(() => {
        if (lmsQuizDetail?.data?.quiz_setting?.status !== lmsQuizStatusEnum?.PUBLISHED?.value) return;

        navigate(`${pagesInfo?.EDIT_LMS_QUIZ?.pagePath}/${lmsQuizDetail?.data?.id}/edit/${quizParticipantsNavigationOptions?.INVITED?.to}?action=invite-participants`)

    }, [lmsQuizDetail?.data])

    const onHandleChangePage = useCallback((page) => {
        const query = {
            page: page,
            records: RECORDS,
            invite_to: lmsQuizInviteToEnum.STUDENT.value,
            quiz_id: lmsQuizDetail?.data?.id
        }
        dispatcher(getLmsQuizInvitationList(query))
    }, [lmsQuizDetail?.data])

    const onHandleSelectMenu = useCallback((menuItem, quizInvitation) => {
        switch (menuItem?.value) {
            case quizStudentInviteMenuItemsConst?.DELETE?.value:
                dispatcher(deleteLmsQuizInvitationDetail(quizInvitation?.id))
                break;
            default:
                break;
        }
    }, [lmsQuizInvitationList?.data])

    const StudentImageContainer = ({ profilePicUrl }) => {
        return (
            <div className={"flex items-center justify-center"}>
                <div className={"relative w-10 h-10 overflow-hidden rounded-full"}>
                    <img
                        src={profilePicUrl}
                        className={"w-full h-full object-cover"}
                    />
                </div>
            </div>
        )
    }

    const StudentEmailContainer = ({ quizInvitationUser }) => {
        return quizInvitationUser?.user?.email ? (
            <div className={"flex items-center justify-start"}>
                <span className={"font-bodyPri font-normal"}>
                    {`${quizInvitationUser?.user?.email}`}
                </span>
            </div>
        ) : "-"
    }

    const quizInvitationRows = lmsQuizInvitationList?.data?.results?.map((quizInvitation) => [
        <StudentImageContainer profilePicUrl={quizInvitation?.user?.profile_pic_url} />,
        <StudentEmailContainer quizInvitationUser={quizInvitation?.user?.email} />,
        quizInvitation?.user?.first_name ? `${quizInvitation?.user?.first_name} ${quizInvitation?.user?.last_name?.charAt(0)}.` : "-",
        lmsQuizInviteStatusEnum[quizInvitation?.status?.toUpperCase()]?.label,
        dayjs(quizInvitation?.created_at)?.tz(timeZone)?.format("ddd, MMM D, YYYY"),
        <div className={"flex items-center justify-center"}>
            <ThreeDotMenu
                menuItems={Object.values(quizStudentInviteMenuItemsConst)}
                onHandleSelect={(menuItem) => onHandleSelectMenu(menuItem, quizInvitation)}
            />
        </div>
    ])

    return (
        <div className={"flex flex-col gap-5"}>
            <div className={"w-full flex items-center justify-end gap-3"}>
                <ToolTipView
                    content={(lmsQuizDetail?.data?.quiz_setting?.status === lmsQuizStatusEnum?.PUBLISHED?.value)
                        ? lmsQuizTooltips?.publishedInviteBtnTooltip
                        : lmsQuizTooltips?.disabledInviteBtnTooltip
                    }
                >
                    <div
                        className={cn(
                            "flex items-center justify-center gap-1 py-1 px-3 rounded-lg",
                            (lmsQuizDetail?.data?.quiz_setting?.status === lmsQuizStatusEnum?.PUBLISHED?.value)
                                ? "border border-primary-dark text-primary-dark cursor-pointer hover:bg-primary-dark group"
                                : "border border-divider-medium bg-text-300 text-text-400 cursor-not-allowed"
                        )}
                        onClick={onHandleInviteStudent}
                    >
                        <MdAdd className={"text-lg group-hover:text-text-50"} />
                        <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                            {"Invite someone"}
                        </span>
                    </div>
                </ToolTipView>
            </div>

            <div className={"px-5 space-y-3"}>
                {lmsQuizInvitationList?.isLoading &&
                    <ComponentLoader isLoading={lmsQuizInvitationList?.isLoading} />
                }
                {lmsQuizInvitationList?.message &&
                    <div className={"flex items-center justify-start gap-1"}>
                        <span className={"font-bodyPri font-medium text-red-500 text-base tracking-wide"}>
                            {lmsQuizInvitationList?.message}
                        </span>
                    </div>
                }
                {(!lmsQuizInvitationList?.isLoading && lmsQuizInvitationList?.data) &&
                    <LMSTableComponent
                        isLoading={lmsQuizInvitationList?.isLoading}
                        headers={Object.values(quizStudentInvitationHeaderConst)}
                        subRowHeader={[]}
                        rows={(lmsQuizInvitationList?.data?.results?.length > 0) ? quizInvitationRows : []}
                        rowHeaderColor={"bg-background-medium"}
                        alternateRowColor={"bg-primary-light"}
                    />
                }
                {(lmsQuizInvitationList?.data && (lmsQuizInvitationList?.data?.totalPages > 1)) &&
                    <div className={"flex justify-center items-center"}>
                        <Pagination
                            page={lmsQuizInvitationList?.data?.page}
                            totalPages={lmsQuizInvitationList?.data?.totalPages}
                            onChangePage={onHandleChangePage}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default InvitedParticipants;