import { useCallback, useMemo, useState } from 'react';

import { FaChartLine } from "react-icons/fa";

import ComponentLoader from 'components/loader/ComponentLoader';
import { OptionSelector } from 'components/common-components/Select';
import LineChart from 'components/charts/lineChart/LineChart';

import { getLastSevenDays, sevenDaysAgo, sortTransactions } from '../data';

import { useAppState } from 'hooks/useStore';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';
import { txnType } from "redux/wallet/wallet.const";

import { dayjs, timeZone } from 'utils/dateTime.utils';
import { cn } from 'utils/cn.utils';

const WalletTxnChart = () => {
    const { walletTxnList } = useAppState(state => state.wallet)

    const [selectedCurrencyType, setSelectedCurrencyType] = useState(masterCurrencyCodeEnum.USD.value)

    const getSimilarWalletTxnType = useCallback((txnItem) => {
        let currency = "USD"
        switch (txnItem?.txnType) {
            case txnType?.INR_WALLET?.value:
                currency = "INR"
                break;
            case txnType?.AUD_WALLET?.value:
                currency = "AUD"
                break;
            default:
                currency = "USD"
                break;
        }

        return selectedCurrencyType === currency
    }, [selectedCurrencyType])

    const amountByDay = getLastSevenDays().reduce((obj, day) => {
        obj[day] = 0
        return obj
    }, {})

    if (!!walletTxnList?.walletTxnList) {
        walletTxnList?.walletTxnList.slice().sort(sortTransactions).filter((txnItem) => {
            const isInLastSevenDays = dayjs(txnItem.createdAt).tz(timeZone).isAfter(sevenDaysAgo)
            const isSimilarCurrencyCode = getSimilarWalletTxnType(txnItem)
            return isInLastSevenDays && isSimilarCurrencyCode
        }).forEach((item) => {
            const dayOfWeek = dayjs(item.createdAt).tz(timeZone).format("dddd")
            amountByDay[dayOfWeek] = parseFloat(amountByDay[dayOfWeek]) + parseFloat((item.amount / 100).toFixed(2))
        })
    }

    const maxAmount = useMemo(() => {
        const amounts = Object.values(amountByDay).map(amount => Math.ceil(parseFloat(amount)))
        return Math.max(...amounts)
    }, [amountByDay])

    return (
        <div className={"bg-white rounded-lg py-5 px-8 flex flex-col gap-5"}>
            <div className={"flex flex-col gap-3 px-5"}>
                <div className={"flex items-center justify-between gap-3"}>
                    <div className={"p-1.5 flex items-center justify-center border border-text-400 rounded-md"}>
                        <span className={"font-bodyPri font-medium text-text-800 text-sm"}>
                            {"Last 7 days"}
                        </span>
                    </div>
                    <div className={"flex items-center justify-start gap-5"}>
                        <OptionSelector
                            className={"w-full border border-text-400"}
                            options={Object.values(masterCurrencyCodeEnum)}
                            value={selectedCurrencyType}
                            onChange={(option) => setSelectedCurrencyType(option?.value)}
                        />
                        <FaChartLine className={"text-text-800 text-lg"} />
                    </div>
                </div>
                <div className={"w-full h-0.5 bg-divider-medium"} />
                <ComponentLoader isLoading={walletTxnList.isLoading} />
                {!walletTxnList?.isLoading &&
                    <div className={cn(
                        "flex h-[30rem] overflow-hidden overflow-y-auto",
                        " scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                    )}>
                        <LineChart
                            lineData={{
                                labels: Object.keys(amountByDay),
                                datasets: [{
                                    label: 'Amount (in USD)',
                                    data: Object.values(amountByDay),
                                    fill: false,
                                    borderColor: '#0741ad',
                                    tension: 0.1
                                }]
                            }}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        display: false
                                    },
                                },
                                scales: {
                                    y: {
                                        min: 0,
                                        max: (maxAmount > 1000) ? maxAmount : 1000
                                    }
                                }
                            }}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default WalletTxnChart;