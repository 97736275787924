import { Link } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { pagesInfo } from "utils/pagesInfo";

const Skills = ({ tutor }) => {
  return (
    <div className="p-2.5 md:p-5 space-y-5" id={"SKILLS"}>
      <span className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
        {"Skills I Teach"}
      </span>
      <div className="flex flex-wrap gap-2">
        {tutor?.skills?.length > 0
          && tutor?.skills?.map((ele, idx) => (
            <Link to={`${pagesInfo?.FIND_TUTORS?.pagePath}?skill=${ele?.skill?.replaceAll(" ", "-")?.toLowerCase()}`}
              key={idx}
              className={cn(
                'bg-primary-light border border-primary-light py-0.5 md:py-1 px-3 rounded-lg text-text-900 font-bodyPri font-normal text-base',
                "hover:text-primary-dark hover:border-primary-dark hover:bg-text-50"
              )}
            >
              {ele?.skill}
            </Link>
          ))}
        {(!tutor?.skills || tutor?.skills?.length === 0)
          && <span className={"font-bodyPri font-normal text-text-800 text-lg py-0.5 md:py-1 px-3"}>
            {"Not Added Yet"}
          </span>
        }
      </div>
    </div>
  );
};

export default Skills;