export const url = {
    GET_USER_TODO_LIST: "/todos/list",
    ADD_USER_TODO: "/todos/add",
    UPDATE_USER_TODO: "/todos/update/{userTodoId}",
    DELETE_USER_TODO: "/todos/delete/{userTodoId}"
}

export const filterName = {
    TODO: {
        value: "todo"
    }
}

export const todoStatus = {
    ALL: {
        label: 'All',
        value: 'all',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
    PENDING: {
        label: 'Pending',
        value: 'pending',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    COMPLETED: {
        label: 'Completed',
        value: 'completed',
        lightColor: 'green-100',
        darkColor: 'green-500',
    }
}

export const todoAction = {
    ADD_TODO: {
        label: 'Add Todo',
        value: 'add_todo',
    },
    UPDATE_TODO: {
        label: 'Update Todo',
        value: 'update_todo',
    }
}

export const todoFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: "",
    todoStatus: todoStatus.ALL.value
}