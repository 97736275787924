import tw, { styled } from 'twin.macro';
import { motion } from 'framer-motion';
import { NavLink as RouterLink } from 'react-router-dom';

import { School } from '@mui/icons-material';
import { WifiProtectedSetupOutlined } from '@mui/icons-material';
import { FaChalkboardTeacher } from 'react-icons/fa';

import { sideTopBarPagesName } from 'utils/pagesInfo';
import SvgIcon from 'components/generalComponent/SvgIcon';


export const Container = motion(styled.div`
    ${tw`
        hidden
    `}
    ${({ pageName }) => sideTopBarPagesName.includes(pageName) && tw`
        lg:flex sticky top-0 z-40 h-screen pb-5 w-full bg-primary-main overflow-hidden overflow-y-auto 
        scrollbar hover:scrollbar-thumb-divider-medium
    `}
    ::-webkit-scrollbar {
        ${tw`
        w-2.5 bg-primary-main hover:bg-divider-medium
        `}
         ${({ isTutor }) => isTutor && tw`!bg-[#334155]`}
    }
`);

export const Content = tw.div``;

export const ListItems = styled.div`
    ${tw`
        flex flex-col justify-center text-text-50 gap-2 py-2
    `}
    .active {
        ${tw`
        bg-white text-primary-main shadow-xl
        `}
        ${({ isTutor }) => isTutor && tw`!text-[#334155]`}
    }
`;

export const ListItem = styled.div`
   ${tw`
    w-full flex flex-row items-center h-8 cursor-pointer select-none rounded-r-full pr-5
    hover:(bg-primary-light !text-primary-main scale-125)
   `}
   ${({ isTutor }) => isTutor && tw`hover:(!text-[#334155] scale-125)`}
`;

export const ItemLink = styled(RouterLink)`
    ${tw`
    w-full flex flex-row items-center h-8 cursor-pointer select-none rounded-r-full pr-5
    hover:(bg-primary-light !text-primary-main scale-125)
   `}
   ${({ isTutor }) => isTutor && tw`hover:(!text-[#334155] scale-125)`}
`;

export const ItemIcon = tw.span`
    text-2xl px-5 flex items-center h-full
`;

export const AppIcon = tw(SvgIcon)`
    px-5 fill-current w-8 h-8 whitespace-nowrap
`;

export const ItemName = motion(tw.span`
    inline-block overflow-hidden flex flex-nowrap items-center h-full
    font-bodyPri text-base whitespace-nowrap
`);

export const RoleSwitchIcon = motion(tw(WifiProtectedSetupOutlined)``);

export const StudentIcon = motion(tw(School)``);

export const TutorIcon = motion(tw(FaChalkboardTeacher)`
    text-2xl
`);

// sub menu styles
export const SubMenuItemLink = styled(RouterLink)`
    ${tw`
    w-full flex flex-row items-center h-8 cursor-pointer select-none rounded-r-full pl-16
    hover:(bg-primary-light !text-primary-main scale-125)
   `}
   ${({ isTutor }) => isTutor && tw`hover:(!text-[#334155] scale-125)`}
`;

export const SubMenuItemName = motion(tw.span`
    inline-block overflow-hidden flex flex-nowrap items-center h-full
    font-bodyPri font-normal text-base whitespace-nowrap
`);