import { cn } from "utils/cn.utils";

import ComponentLoader from "components/loader/ComponentLoader";

import DriveMenu from "pages/auth/drive/commonComponents/DriveMenu";
import { fileExtensionConst, formatFileSize } from "pages/auth/drive/data";

import { useAppState } from "hooks/useStore";

import { dayjs, timeZone } from "utils/dateTime.utils";

const RecentOpenContainer = ({ fileData }) => {

    return (
        <div className="w-full flex items-start justify-between gap-3">
            <div className={cn(
                `w-8 h-8 p-2 rounded-full flex items-center justify-center`,
                fileExtensionConst[fileData?.file_name?.split(".")?.pop()?.toUpperCase()]
                    ? `bg-${fileExtensionConst[fileData?.file_name?.split(".")?.pop()?.toUpperCase()]?.darkColor}`
                    : "bg-orange-500"
            )}>
                <p className={`font-bodyPri font-normal text-text-50 text-xs text-center m-0`}>
                    {fileData?.file_name.split(".").pop()?.toLowerCase()}
                </p>
            </div>
            <div className="w-[60%] flex flex-col items-start justify-start gap-1.5">
                <span className="font-bodyPri font-medium text-text-900 text-base truncate">
                    {fileData?.file_name?.length > 10
                        ? fileData?.file_name?.slice(0, 10) + " ..."
                        : fileData?.file_name
                    }
                </span>

                <span className="font-bodyPri font-normal text-text-900 text-sm">
                    {dayjs(fileData?.last_accessed)?.tz(timeZone)?.format('DD/MM/YY hh:mm a')}
                </span>
            </div>
            <div className={"w-[30%] flex flex-col items-end justify-start gap-1.5"}>
                <DriveMenu fileItems={fileData} />
                <div className={"font-bodyPri font-normal text-text-700 text-sm whitespace-normal sm:whitespace-nowrap"}>
                    {formatFileSize(fileData?.file_size)}
                </div>
            </div>
        </div>
    )
}

const RecentOpenFiles = () => {
    const { recentOpenFileList } = useAppState((state) => state.drive)

    return (
        <div className={cn(
            "w-full p-5 bg-white flex flex-col items-start justify-start space-y-5 rounded-lg",
            (recentOpenFileList?.data && recentOpenFileList?.data?.length === 0) ? "min-h-[23rem]" : "min-h-[40rem]",
        )}>
            <b className="font-bodyPri font-bold text-black text-base tracking-wide">
                {"Recent Open"}
            </b>
            {recentOpenFileList?.isLoading &&
                <ComponentLoader isLoading={recentOpenFileList?.isLoading} className="h-96 lg:h-[40em]" />
            }
            {(recentOpenFileList?.data && recentOpenFileList?.data?.length === 0) &&
                <span className="w-full h-full flex items-center justify-center font-bodyPri font-normal text-text-800 text-base tracking-wide">
                    {"No files found!"}
                </span>
            }
            <div className={cn(
                "flex flex-col w-full max-h-96 overflow-hidden overflow-y-scroll space-y-3 pl-2 transition ease-in-out delay-150 duration-300",
                "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight",
                recentOpenFileList?.data?.length > 5 && "hover: pr-5"
            )}>
                {(!recentOpenFileList?.isLoading && recentOpenFileList?.data?.length > 0) && recentOpenFileList?.data?.map((fileData, index) => (
                    <RecentOpenContainer key={index} fileData={fileData} />
                ))}
            </div>
            {(!recentOpenFileList?.isLoading && (recentOpenFileList?.message || !recentOpenFileList?.data)) &&
                <span className="w-full font-bodyPri font-normal text-red-500 text-base text-center tracking-wide">
                    {recentOpenFileList?.message}
                </span>
            }
        </div>
    );
};

export default RecentOpenFiles;
