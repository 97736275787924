export const pageHeading = {
    heading: "Get the help you need",
    subHeading: "Our team is here to help you succeed, there are many ways to get support!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/support.svg"
}

export const SupportActionList = [
    {
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/support-page/knowledge-base.svg",
        text: "Knowledge Base"
    },
    {
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/support-page/send-an-email.svg",
        text: "Send an email"
    },
    {
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/support-page/create-a-ticket.svg",
        text: "Create a ticket"
    },
    {
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/support-page/chat-with-us.svg",
        text: "Chat with us"
    }
]

export const actionTextConst  = {
    KNOWLEDGE_BASE: {
        label: "Knowledge Base",
        value: "knowledge_base"
    },
    SEND_AN_EMAIL: {
        label: "Send an email",
        value: "send_an_email"
    },
    CREATE_A_TICKET: {
        label: "Create a ticket",
        value: "create_a_ticket"
    },
    CHAT_WITH_US: {
        label: "Chat with us",
        value: "chat_with_us"
    }
}

export const studentKnowledgeBase = 'https://support.edulyte.com/portal/en/kb/student-helpdesk'
export const teacherKnowledgeBase = 'https://support.edulyte.com/portal/en/kb/tutor-helpdesk'
export const sendEmailToSupport = 'mailto:support@edulyte.com'
export const createATicket = 'https://support.edulyte.com/portal/en/newticket?departmentId=31629000000010772&layoutId=31629000000011350'

export const studentRoleFaqsScript = "https://desk.zoho.in/portal/api/web/inapp/31629000010193096?orgId=60006409303"

export const tutorRoleFaqsScript = "https://desk.zoho.in/portal/api/web/inapp/31629000010193032?orgId=60006409303"