export const weeklyTimeSlot = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: []
}

const addInterviewSessionDetailPayload = {
    timeZone: null,
    duration: 60,
    schedule: [],
}

export const INTERVIEW_INITIAL_STATE = {
    interviewDetail: {
        isLoading: false,
        interviewDetail: null,
        errorMsg: null
    },
    addInterviewSessionDetail: {
        isLoading: false,
        data: null,
        payload: addInterviewSessionDetailPayload,
        message: null
    },
}