import { useEffect, useState } from 'react'

import { Reorder } from "framer-motion";

import { BsInfoCircle } from 'react-icons/bs';

import ToolTipView from 'components/tooltipView';

import LinkTreeCard, { LinkTreeCardLoader } from './linkTreeCard'
import AddLink from './addLink'
import image from '../../../../assets/image/no-item-found.png'

import { getMasterSocialList } from 'redux/master/master.request'
import { createLinkInBio, reorderLink } from 'redux/linkTree/linkTree.request'
import { deleteLink } from 'redux/linkTree/linkTree.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore'
import { setClearAddLinkInBio } from 'redux/linkTree/linkTree.slice';

import { debounce } from 'utils/generators.utils';

const LinkInBio = () => {
  const { user } = useAppState((state) => state.user)
  const { createLinkTree } = useAppState((state) => state.linkTree)

  const [links, setLinks] = useState([]);

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    return () => dispatcher(setClearAddLinkInBio())
  }, [])

  useEffect(() => {
    dispatcher(createLinkInBio(
      {
        slug: user?.user?.username
      }
    ))
    dispatcher(getMasterSocialList())
  }, [])

  useEffect(() => {
    if (createLinkTree?.data?.result?.links) {
      setLinks(createLinkTree.data.result.links.slice(0).sort(sortLink));
    }
  }, [createLinkTree?.data?.result?.links]);

  const sortLink = (link1, link2) => link1.order < link2.order ? -1 : 1;

  const handleDeleteLink = (id) => {
    dispatcher(deleteLink(id));
    setLinks((s) => s.filter((link) => link.id !== id));
  };

  const handleReorder = (newOrder) => {
    setLinks(newOrder);
    const reorderedPayload = { order: newOrder.map((link) => ({ id: link.id })) };

    const delayFunction = debounce.debounce((reorderedPayload) => {
      dispatcher(reorderLink(createLinkTree?.data?.result?.id, reorderedPayload));
    }, 1000)
    delayFunction(reorderedPayload)
  };

  return (
    <div className={'space-y-5 p-0 md:p-5 border md:border-none border-background-light rounded shadow-none md:shadow'}>
      <div className={"flex items-center justify-start gap-2"}>
        <ToolTipView content={"Add your important links here to display them on your public profile."}>
          <button className={""}>
            <BsInfoCircle className={"inline text-md text-text-900 hover:text-text-900"} />
          </button>
        </ToolTipView>
        <h1 className='font-medium text-text-700'>
          {"Select Your Preferred Link Type"}
        </h1>
      </div>
      <div className='static lg:sticky top-10 z-10 pb-5 pt-2 bg-white'>
        <AddLink />
      </div>
      {createLinkTree?.isLoading ? (
        <>
          {new Array(10).fill("").map((_, index) => (
            <div className='space-y-5'>
              <LinkTreeCardLoader />
            </div>
          ))}
        </>
      ) : (
        <Reorder.Group className='space-y-5' axis="y" values={links} onReorder={handleReorder}>
          {links.map((item) => (
            <Reorder.Item key={item.id} value={item}>
              <LinkTreeCard link={item} onDelete={handleDeleteLink} />
            </Reorder.Item>
          ))}
        </Reorder.Group>
      )}
      {links?.length === 0 && (
        <div className='space-y-5 text-sm text-text-600'>
          <img
            className='h-32 aspect-video'
            alt='no item found'
            src={image}
          />
          <p>No Link Added Yet</p>
        </div>
      )}
    </div>
  )
}

export default LinkInBio
