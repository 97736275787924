import { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import format from "string-format"

import { PageContentStyle } from "pages/auth/bookingConfirmation/BookingConfirmationPageStyle";

import FullPageLoader from 'components/loader/FullPageLoader';

import PendingBookingConfirmation from 'pages/auth/bookingConfirmation/components/PendingBookingConfirmation';
import ProcessingBookingConfirmation from 'pages/auth/bookingConfirmation/components/ProcessingBookingConfirmation';
import ConfirmedBookingConfirmation from 'pages/auth/bookingConfirmation/components/ConfirmedBookingConfirmation';
import FailedBookingConfirmation from 'pages/auth/bookingConfirmation/components/FailedBookingConfirmation';
import NotExistBookingConfirmation from 'pages/auth/bookingConfirmation/components/NotExistBookingConfirmation';

import { edulyteAuthWebSocket } from 'websocket/edulyte.websocket';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getBookingDetailConfirmation } from 'redux/booking/booking.request';
import { resetBookingDetailConfirmation, setBookingDetailConfirmationData } from 'redux/booking/booking.slice';
import { bookingStatusEnum, bookingWsUrl } from 'redux/booking/booking.const';

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';

import { useTitle } from 'hooks/useTitle';

const BookingConfirmationPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { bookingDetailConfirmation } = useAppState((state) => state.booking)

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const navigate = useNavigate()
    const [title, setTitle] = useTitle()

    let bookingConfirmationWebSocket = null
    const getBookingConfirmation = async (booking_id) => {
        console.log("booking called ws", booking_id)
        const wsConfig = { url: format(bookingWsUrl.BOOKING_CONFIRMATION, { booking_id }) }
        bookingConfirmationWebSocket = await edulyteAuthWebSocket(wsConfig)

        bookingConfirmationWebSocket.onmessage = async (event) => {
            const bookingConfirmation = JSON.parse(event?.data)
            console.log("eventl booking confirmation \n", bookingConfirmation)
            if (bookingConfirmation?.error) return;
            dispatcher(setBookingDetailConfirmationData({ result: bookingConfirmation }))
        }
    }

    const from = location.state?.from?.url || pagesInfo.DASHBOARD.pagePath;

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.BOOKING_CONFIRMATION))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Thank You for Your Booking | Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        return () => {
            dispatcher(resetBookingDetailConfirmation())
            if (!!bookingConfirmationWebSocket &&
                (bookingConfirmationWebSocket.readyState === WebSocket.OPEN)) {
                bookingConfirmationWebSocket?.close()
            }
        }
    }, [])

    useEffect(() => {
        let query = generateQueryParams(location.search)

        if (!query?.booking_id) {
            navigate(from, { replace: true })
        } else if (query?.booking_id) {
            dispatcher(getBookingDetailConfirmation(query?.booking_id))
            getBookingConfirmation(query?.booking_id)
        }

    }, [location.search])

    if (bookingDetailConfirmation?.isLoading) {
        return (
            <FullPageLoader isLoading={bookingDetailConfirmation?.isLoading} />
        )
    }

    if (bookingDetailConfirmation?.message) {
        return (
            <PageContentStyle>
                <FailedBookingConfirmation />
            </PageContentStyle>
        )
    }

    return bookingDetailConfirmation?.data?.result ? (
        <PageContentStyle>
            {[bookingStatusEnum.PENDING.value].includes(bookingDetailConfirmation?.data?.result?.status) &&
                <PendingBookingConfirmation />
            }
            {[bookingStatusEnum.PROCESSING.value].includes(bookingDetailConfirmation?.data?.result?.status) &&
                <ProcessingBookingConfirmation />
            }
            {[bookingStatusEnum.CONFIRMED.value, bookingStatusEnum.COMPLETED.value, bookingStatusEnum.REFUNDED.value, bookingStatusEnum.CANCELLED.value].includes(bookingDetailConfirmation?.data?.result?.status) &&
                <ConfirmedBookingConfirmation />
            }
            {[bookingStatusEnum.FAILED.value].includes(bookingDetailConfirmation?.data?.result?.status) &&
                <FailedBookingConfirmation />
            }
            {bookingDetailConfirmation?.data?.result?.status === "not_exist" &&
                <NotExistBookingConfirmation />
            }
        </PageContentStyle>
    ) : null
};

export default BookingConfirmationPage;
