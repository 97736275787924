import { toast } from "react-toastify";

import sessionService from "redux/session/session.service";

import {
    setDashboardUpcomingEventListLoading,
    setDashboardUpcomingEventListData,
    setDashboardUpcomingEventListMessage,

    setDashboardCalendarEventListLoading,
    setDashboardCalendarEventListData,
    setDashboardCalendarEventListMessage,

    setCalendarSessionUserListLoading,
    setCalendarSessionUserListData,
    setCalendarSessionUserListMessage,

    setSessionUserDetailLoading,
    setSessionUserDetailData,
    setSessionUserDetailMessage,

    setStartSessionUserDetailLoading,
    setStartSessionUserDetailData,
    setStartSessionUserDetailMessage,

    setAcceptSessionUserDetailLoading,
    setAcceptSessionUserDetailData,
    setAcceptSessionUserDetailMessage,

    setDeclineSessionUserDetailLoading,
    setDeclineSessionUserDetailData,
    setDeclineSessionUserDetailMessage,

    setRescheduleSessionUserDetailLoading,
    setRescheduleSessionUserDetailData,
    setRescheduleSessionUserDetailMessage,

    setCompleteSessionUserDetailLoading,
    setCompleteSessionUserDetailData,
    setCompleteSessionUserDetailMessage,

    setSessionCourseListLoading,
    setSessionCourseListData,
    setSessionCourseListMessage,

    setSessionAppointmentListLoading,
    setSessionAppointmentListData,
    setSessionAppointmentListMessage,

    setSessionLocationListLoading,
    setSessionLocationListData,
    setSessionLocationListMessage,

    setSessionLocationDetailLoading,
    setSessionLocationDetailData,
    setSessionLocationDetailMessage,

    setAddSessionLocationDetailLoading,
    setAddSessionLocationDetailData,
    setAddSessionLocationDetailMessage,

    setModifySessionLocationDetailLoading,
    setModifySessionLocationDetailData,
    setModifySessionLocationDetailMessage,

    setDestroySessionLocationDetailLoading,
    setDestroySessionLocationDetailMessage,
} from "redux/session/session.slice";


// get dashboard upcoming event list (for dashboard page)
export const getDashboardUpcomingEventList = (userId, query) => async (dispatch) => {
    dispatch(setDashboardUpcomingEventListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: query
        }
        const response = await sessionService.getCalendarSessionUserList(requestData)
        if (response.status === 200) {
            dispatch(setDashboardUpcomingEventListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setDashboardUpcomingEventListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setDashboardUpcomingEventListLoading(false))
    }
}

// get dashboard calendar event list (for dashboard page calendar)
export const getDashboardCalendarEventList = (userId, query) => async (dispatch) => {
    dispatch(setDashboardCalendarEventListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: query
        }
        const response = await sessionService.getCalendarSessionUserList(requestData)
        if (response.status === 200) {
            dispatch(setDashboardCalendarEventListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setDashboardCalendarEventListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setDashboardCalendarEventListLoading(false))
    }
}

// get calendar session user list (for calendar page)
export const getCalendarSessionUserList = (userId, query) => async (dispatch) => {
    dispatch(setCalendarSessionUserListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: query
        }
        const response = await sessionService.getCalendarSessionUserList(requestData)
        if (response.status === 200) {
            dispatch(setCalendarSessionUserListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setCalendarSessionUserListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setCalendarSessionUserListLoading(false))
    }
}

// get session user detail
export const getSessionUserDetail = (sessionId, userId) => async (dispatch) => {
    dispatch(setSessionUserDetailLoading(true))

    try {
        const requestData = {
            params: { sessionId: sessionId, userId: userId }
        }
        const response = await sessionService.getSessionUserDetail(requestData)
        if (response.status === 200) {
            dispatch(setSessionUserDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setSessionUserDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setSessionUserDetailLoading(false))
    }
}

// start session user detail
export const startSessionUser = (sessionId, body) => async (dispatch) => {
    dispatch(setStartSessionUserDetailLoading(true))

    try {
        const requestData = {
            params: { sessionId: sessionId },
            body: body
        }
        const response = await sessionService.startSessionUser(requestData)
        if (response.status === 200) {
            dispatch(setStartSessionUserDetailData(response.data.data))
            toast.success(response?.data?.data?.message || "Updated successfully")
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
        dispatch(setStartSessionUserDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
    } finally {
        dispatch(setStartSessionUserDetailLoading(false))
    }
}

// accept session user detail
export const acceptSessionUser = (sessionId, body) => async (dispatch) => {
    dispatch(setAcceptSessionUserDetailLoading(true))

    try {
        const requestData = {
            params: { sessionId: sessionId },
            body: body
        }
        const response = await sessionService.acceptSessionUser(requestData)
        if (response.status === 200) {
            dispatch(setAcceptSessionUserDetailData(response.data.data))
            toast.success(response?.data?.data?.message || "Updated successfully")
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
        dispatch(setAcceptSessionUserDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
    } finally {
        dispatch(setAcceptSessionUserDetailLoading(false))
    }
}

// decline session user detail
export const declineSessionUser = (sessionId, body) => async (dispatch) => {
    dispatch(setDeclineSessionUserDetailLoading(true))

    try {
        const requestData = {
            params: { sessionId: sessionId },
            body: body
        }
        const response = await sessionService.declineSessionUser(requestData)
        if (response.status === 200) {
            dispatch(setDeclineSessionUserDetailData(response.data.data))
            toast.success(response?.data?.data?.message || "Updated successfully")
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
        dispatch(setDeclineSessionUserDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong"))
        toast.error(error.response.message)
    } finally {
        dispatch(setDeclineSessionUserDetailLoading(false))
    }
}

// reschedule session user detail
export const rescheduleSessionUser = (sessionId, body) => async (dispatch) => {
    dispatch(setRescheduleSessionUserDetailLoading(true))

    try {
        const requestData = {
            params: { sessionId: sessionId },
            body: body
        }
        const response = await sessionService.rescheduleSessionUser(requestData)
        if (response.status === 200) {
            dispatch(setRescheduleSessionUserDetailData(response?.data?.data))
            toast.success(response?.data?.data?.message || "Rescheduled Successfully")
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
        dispatch(setRescheduleSessionUserDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
    } finally {
        dispatch(setRescheduleSessionUserDetailLoading(false))
    }
}

// complete session user detail
export const completeSessionUser = (sessionId, body) => async (dispatch) => {
    dispatch(setCompleteSessionUserDetailLoading(true))

    try {
        const requestData = {
            params: { sessionId: sessionId },
            body: body
        }
        const response = await sessionService.completeSessionUser(requestData)
        if (response.status === 200) {
            dispatch(setCompleteSessionUserDetailData(response.data.data))
            toast.success(response?.data?.data?.message || "Completed successfully")
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
        dispatch(setCompleteSessionUserDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
    } finally {
        dispatch(setCompleteSessionUserDetailLoading(false))
    }
}

// session course list
export const getSessionCourseList = (query) => async (dispatch) => {
    dispatch(setSessionCourseListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await sessionService.getSessionCourseList(requestData)
        if (response.status === 200) {
            dispatch(setSessionCourseListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setSessionCourseListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setSessionCourseListLoading(false))
    }
}

// session appointment list
export const getSessionAppointmentList = (query) => async (dispatch) => {
    dispatch(setSessionAppointmentListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await sessionService.getSessionAppointmentList(requestData)
        if (response.status === 200) {
            dispatch(setSessionAppointmentListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setSessionAppointmentListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setSessionAppointmentListLoading(false))
    }
}

// session location list
export const getSessionLocationList = (query) => async (dispatch) => {
    dispatch(setSessionLocationListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await sessionService.getSessionLocationList(requestData)
        if (response.status === 200) {
            dispatch(setSessionLocationListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setSessionLocationListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setSessionLocationListLoading(false))
    }
}

// session location detail
export const getSessionLocationDetail = (sessionId, locationId) => async (dispatch) => {
    dispatch(setSessionLocationDetailLoading(true))

    try {
        const requestData = {
            params: { sessionId: sessionId, locationId: locationId }
        }
        const response = await sessionService.getSessionLocationDetail(requestData)
        if (response.status === 200) {
            dispatch(setSessionLocationDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setSessionLocationDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setSessionLocationDetailLoading(false))
    }
}

// add session location detail
export const createSessionLocationDetail = (sessionId, locationId, body) => async (dispatch) => {
    dispatch(setAddSessionLocationDetailLoading(true))

    try {
        const requestData = {
            params: { sessionId: sessionId, locationId: locationId },
            body: body
        }
        const response = await sessionService.createSessionLocationDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddSessionLocationDetailData(response.data.data))
            toast.success(response?.data?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddSessionLocationDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddSessionLocationDetailLoading(false))
    }
}

// update session location detail
export const updateSessionLocationDetail = (sessionId, locationId, body) => async (dispatch) => {
    dispatch(setModifySessionLocationDetailLoading(true))

    try {
        const requestData = {
            params: { sessionId: sessionId, locationId: locationId },
            body: body
        }
        const response = await sessionService.updateSessionLocationDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifySessionLocationDetailData(response.data.data))
            toast.success(response?.data?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setModifySessionLocationDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setModifySessionLocationDetailLoading(false))
    }
}

// delete session location detail
export const deleteSessionLocationDetail = (sessionId, locationId) => async (dispatch) => {
    dispatch(setDestroySessionLocationDetailLoading(true))

    try {
        const requestData = {
            params: { sessionId: sessionId, locationId: locationId }
        }
        const response = await sessionService.deleteSessionLocationDetail(requestData)
        if (response.status === 204) {
            toast.success(response?.data?.data?.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setDestroySessionLocationDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setDestroySessionLocationDetailLoading(false))
    }
}