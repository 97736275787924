export const productPriceDetailConst = {
    PRICE_TYPE: {
        value: "type"
    },
    PRICE_MODEL: {
        value: "price_model"
    },
    CURRENCY_CODE: {
        value: "currency_code"
    },
    PRICE: {
        value: "price"
    },
    UNIT: {
        value: "unit"
    },
    PRESET_PRICE: {
        value: "preset_price"
    },
    MIN_PRICE: {
        value: "min_price"
    },
    MAX_PRICE: {
        value: "max_price"
    },
    DISCOUNT_TYPE: {
        value: "discount_type"
    },
    DISCOUNT_PRICE: {
        value: "discount_price"
    },
    DISCOUNT_PCT: {
        value: "discount_pct"
    },
    STATUS: {
        value: "status"
    },
    DESCRIPTION: {
        value: "description"
    },
    MAKE_DEFAULT_PRICE: {
        value:"make_default_price"
    }
}

export const lmsCoursePriceModel = {
    FREE: {
        label: "Free",
        value: "free"
    },
    STANDARD: {
        label: "Standard",
        value: "standard"
    }
}