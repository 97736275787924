import { useMemo } from "react";

import { FiEdit } from "react-icons/fi";
import { ImCross } from "react-icons/im";

import ToolTipView from "components/tooltipView";

import { useAppDispatcher, useAppState } from "hooks/useStore";

import { setModifyUserEventlyDetailPayload } from "redux/evently/evently.slice";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

import { dayjs, getTimeZoneOffset, timeZone } from 'utils/dateTime.utils';
import { cn } from "utils/cn.utils";

const EventlySchedule = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { userEventlyDetail, modifyUserEventlyDetail } = useAppState((state) => state.evently)

    const dispatcher = useAppDispatcher()

    const isMeetingOwner = useMemo(() => userEventlyDetail?.data?.owner_user?.userId === user?.user?.userId, [userEventlyDetail?.data?.owner_user?.userId, user?.user?.userId])

    const onHandleEditSlot = (eventSlot) => {
        if (!isMeetingOwner) return;

        dispatcher(setModifyUserEventlyDetailPayload({
            ...modifyUserEventlyDetail?.payload,
            eventId: eventSlot?.id,
            startDateTime: eventSlot?.startDateTime,
            endDateTime: eventSlot?.endDateTime,
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.editEventlyEventSlotModal.key]: {
                ...modal[modalConst.editEventlyEventSlotModal.key],
                isVisible: true,
                title: "Modify Slot"
            }
        }))
    }

    return (
        <div className={"w-full grid grid-cols-12 gap-3"}>
            {!!modifyUserEventlyDetail?.payload?.eventlyEvents &&
                <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-6 flex items-center gap-5 md:gap-8"}>
                    <div className={"invisible"}>
                        <ImCross className={'text-lg text-text-500 hover:text-text-800 focus:bg-background-light cursor-pointer'} />
                    </div>
                    <div className={"w-full flex flex-col gap-3"}>
                        {modifyUserEventlyDetail?.payload?.eventlyEvents?.map((eventlyEvent, index) => {
                            const isDateSimilar = dayjs(eventlyEvent?.event?.startDateTime).isSame(eventlyEvent?.event?.endDateTime, "date")
                            return (
                                <div key={index} className={"w-full flex items-start lg:items-center justify-between gap-3"}>
                                    <div className={"flex flex-wrap items-center justify-start gap-3"}>
                                        <span className={cn(
                                            "w-fit p-2 bg-background-light hover:bg-background-lightBlue rounded-md cursor-pointer",
                                            "font-bodyPri font-normal text-text-800 text-base whitespace-normal md:whitespace-nowrap"
                                        )}
                                            onClick={() => onHandleEditSlot(eventlyEvent?.event)}
                                        >
                                            {dayjs(eventlyEvent?.event?.startDateTime)?.format("dddd, DD-MMM-YYYY")}
                                        </span>
                                        <span className={cn(
                                            "w-fit p-2 bg-background-light hover:bg-background-lightBlue rounded-md cursor-pointer",
                                            "font-bodyPri font-normal text-text-800 text-base whitespace-normal md:whitespace-nowrap"
                                        )}
                                            onClick={() => onHandleEditSlot(eventlyEvent?.event)}
                                        >
                                            {dayjs(eventlyEvent?.event?.startDateTime)?.format("hh:mma")}
                                        </span>
                                        <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                                            {"to"}
                                        </span>
                                        <span className={cn(
                                            "w-fit p-2 bg-background-light hover:bg-background-lightBlue rounded-md cursor-pointer",
                                            "font-bodyPri font-normal text-text-800 text-base whitespace-normal md:whitespace-nowrap"
                                        )}
                                            onClick={() => onHandleEditSlot(eventlyEvent?.event)}
                                        >
                                            {dayjs(eventlyEvent?.event?.endDateTime)?.format("hh:mma")}
                                        </span>
                                        {!isDateSimilar &&
                                            <span className={cn(
                                                "w-fit p-2 bg-background-light hover:bg-background-lightBlue rounded-md cursor-pointer",
                                                "font-bodyPri font-normal text-text-800 text-base whitespace-normal md:whitespace-nowrap"
                                            )}
                                                onClick={() => onHandleEditSlot(eventlyEvent?.event)}
                                            >
                                                {dayjs(eventlyEvent?.event?.endDateTime)?.format("dddd, DD-MMM-YYYY")}
                                            </span>
                                        }
                                        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide whitespace-normal md:whitespace-nowrap"}>
                                            {`${timeZone} (${getTimeZoneOffset(timeZone)})`}
                                        </span>
                                    </div>
                                    {isMeetingOwner &&
                                        <ToolTipView content={"Edit Slot"}>
                                            <button className={""}>
                                                <FiEdit
                                                    className={"text-lg text-text-700 cursor-pointer"}
                                                    onClick={() => onHandleEditSlot(eventlyEvent?.event)}
                                                />
                                            </button>
                                        </ToolTipView>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
        </div>
    )
}

export default EventlySchedule;