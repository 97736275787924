import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { USER_LANGUAGE_INITIAL_STATE } from "redux//culture/culture.const";


const language = createSlice({
    name: "language",
    initialState: USER_LANGUAGE_INITIAL_STATE,
    reducers: {
        // private reducers
        setUserLanguageLoading: (state, action) => {
            state.userLanguage.isLoading = action.payload
        },
        setUserLanguage: (state, action) => {
            state.userLanguage.userLanguage = action.payload
        },
        setUserLanguageError: (state, action) => {
            state.userLanguage.errorMsg = action.payload
        },
        clearUserLanguage: (state, action) => {
            state.userLanguage.userLanguage = null
        },
    }
})

export const {
    setUserLanguageLoading,
    setUserLanguage,
    setUserLanguageMsg,
    setUserLanguageError,
    clearUserLanguage
} = language.actions

export default language.reducer;