import { ImCross } from "react-icons/im";

import { logoutCurrentUserAndSwitchToAdmin } from "redux/auth/auth.request";

import { useAppDispatcher } from 'hooks/useStore';

const AdminLoginAsNavBar = () => {

    const dispatcher = useAppDispatcher()

    const onHandleAdminLoginAs = () => {
        dispatcher(logoutCurrentUserAndSwitchToAdmin())
    }

    return (
        <div className='sticky top-0 z-50 bg-complementry-main px-5 py-1 w-full flex items-center justify-between gap-3'>
            <div className="flex w-full items-center justify-center">
                <span className='w-full font-bodyPri font-normal text-base text-white text-center'>
                    {"Logout From Current User and Switch to Admin."}
                </span>
                <div className='flex justify-end'>
                    <ImCross
                        className=" cursor-pointer text-base text-text-50"
                        onClick={onHandleAdminLoginAs}
                    />
                </div>
            </div>
        </div>)
};

export default AdminLoginAsNavBar;