import { useCallback, useEffect } from 'react';

import MuxVideoPlayer from 'components/muxVideoPlayer/MuxVideoPlayer';

import PreviousNextButtons from '../../../../../../commonComponents/lmsButtons/PreviousNextButtons';
import { courseDetailNavigationOptions, lmsCourseTooltips } from '../../../../editLMSCourse.data';

import { getLmsCourseDetail, updateLmsCourseDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetUploadFile, setUploadFilePayload } from 'redux/storage/storage.slice';
import { modalConst } from 'redux/local/local.const';
import { fileCategories } from 'redux/storage/storage.const';

import { MUX_DEMO_PLAYBACK_ID } from 'const/default.const';

const CourseVideo = () => {
    const { modal } = useAppState((state) => state.local)
    const { uploadFile } = useAppState((state) => state.storage)
    const { lmsCourseDetail, modifyLmsCourseDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (uploadFile?.data && (uploadFile?.data?.length > 0) && (uploadFile?.payload?.fileCategory === fileCategories.INTRO_VIDEO.value)) {
            dispatcher(updateLmsCourseDetail(
                lmsCourseDetail?.data?.id,
                {
                    intro_video_url: uploadFile?.data[0]?.fileUrl
                }
            ))
            dispatcher(resetUploadFile())
        }
    }, [uploadFile?.data])


    useEffect(() => {
        if (modifyLmsCourseDetail?.data) {
            dispatcher(setModal({
                ...modal,
                [modalConst.UPLOAD_VIDEO_MODAL.stateKey]: false,
                [modalConst.RECORD_VIDEO_MODAL.stateKey]: false
            }))
        }
    }, [modifyLmsCourseDetail?.data])

    const onHandleRefresh = useCallback(() => {
        if (lmsCourseDetail?.isLoading) return;

        dispatcher(getLmsCourseDetail(lmsCourseDetail?.data?.id))
    }, [lmsCourseDetail?.data])

    const onHandleRecordVideo = useCallback(() => {
        dispatcher(resetUploadFile())
        dispatcher(setUploadFilePayload({
            ...uploadFile?.payload,
            fileCategory: fileCategories.INTRO_VIDEO.value
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.RECORD_VIDEO_MODAL.stateKey]: true
        }))
    }, [uploadFile?.payload])

    const onHandleUploadVideo = useCallback(() => {
        dispatcher(resetUploadFile())
        dispatcher(setUploadFilePayload({
            ...uploadFile?.payload,
            fileCategory: fileCategories.INTRO_VIDEO.value
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.UPLOAD_VIDEO_MODAL.stateKey]: true
        }))
    }, [uploadFile?.payload])

    return (
        <div className={"w-full flex flex-col justify-between gap-5 rounded-lg overflow-hidden p-5 bg-white"}>
            <div className={"w-full flex flex-col gap-5"}>
                <div className={"w-full px-3 py-2 bg-divider-medium rounded-lg"}>
                    <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                        {"Promo Video"}
                    </span>
                </div>

                <div className={"w-full flex items-center justify-center"}>
                    <div className={"w-full flex flex-col items-center justify-center space-y-3"}>
                        <div className={"font-bodyPri font-normal text-text-800 text-base tracking-wide text-center"}>
                            {lmsCourseTooltips?.promoVideo}
                        </div>
                        <div className={"w-full md:w-[25rem]"}>
                            <MuxVideoPlayer
                                playbackId={lmsCourseDetail?.data?.intro_video_url ? lmsCourseDetail?.data?.intro_video_url : MUX_DEMO_PLAYBACK_ID}
                                isShowRefreshButton={true}
                                onHandleRefresh={onHandleRefresh}
                                handleUploadVideo={onHandleUploadVideo}
                                handleRecordVideo={onHandleRecordVideo}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <PreviousNextButtons
                previousBtn={courseDetailNavigationOptions?.THUMBNAIL?.to}
                nextBtn={courseDetailNavigationOptions?.WHAT_YOU_WILL_LEARN?.to}
            />
        </div>
    )
}

export default CourseVideo;