import {
    Container,
    Content,
    Header,
    Heading,
    ItemContainer,
    BlogsSection,
    BtnSeeBlogs,
} from './BlogsStyle'
import { blogsData } from './BlogsData'
import BlogItem from './BlogItem'

const Blogs = () => {
    return (
        <Container>
            <Content>
                <Header>
                    <Heading>{'Blogs, guides and more'}</Heading>
                </Header>
                <ItemContainer>
                    <BlogsSection>
                        {blogsData.map((blogItem, index) => (
                            <BlogItem key={index} blogItem={blogItem} />
                        ))}
                    </BlogsSection>
                    <BtnSeeBlogs href="https://www.edulyte.com/blog/" target="_blank">{'See our blog'}</BtnSeeBlogs>
                </ItemContainer>
            </Content>
        </Container>
    )
}

export default Blogs
