import { useState, useRef, useEffect } from 'react';

import { cn } from "utils/cn.utils";

import { FiCheck, FiEdit } from 'react-icons/fi';
import { FaSpinner } from 'react-icons/fa';

const DescriptionInputText = ({
    isLoading = false,
    isShowCheckIcon = false,
    descriptionPlaceholder = 'Description (optional)',
    descriptionText,
    onHandleChangeDescription,
    onHandleUpdateDescription
}) => {

    const [editedDescription, setEditedDescription] = useState(descriptionText);
    const [expanded, setExpanded] = useState(false);
    const textareaRef = useRef(null);

    useEffect(() => {
        if (expanded && textareaRef.current) {
            textareaRef.current.focus();
            textareaRef.current.select();
        }
    }, [expanded]);


    const handleExpand = () => {
        setExpanded(true);
    };

    const onHandleEditDescription = (event) => {
        const inputDescription = event.target.value
        setEditedDescription(inputDescription)
        onHandleChangeDescription(inputDescription)
    }

    const onHandleSaveDescription = () => {
        onHandleUpdateDescription(editedDescription)
        setExpanded(false)
    }

    return (
        <>
            {expanded ? (
                <div className={"flex items-center justify-between w-full rounded-lg bg-white"}>
                    <textarea
                        ref={textareaRef}
                        className={cn(
                            'w-full border outline-none rounded-lg p-2 focus:border-primary-main opacity-100 transition-opacity duration-300',
                            'font-bodyPri font-normal text-text-800 text-sm tracking-wide placeholder:text-text-500'
                        )}
                        placeholder={descriptionPlaceholder}
                        type={'text'}
                        value={editedDescription}
                        rows={3}
                        maxLength={180}
                        onChange={onHandleEditDescription}
                    />
                    {isLoading &&
                        <FaSpinner className={"animate-spin text-md text-primary-dark"} />
                    }
                    {(!isLoading && isShowCheckIcon) &&
                        <FiCheck
                            size={18}
                            onClick={onHandleSaveDescription}
                            className={'cursor-pointer text-primary-main mx-3 w-5 h-5'}
                        />
                    }
                </div>
            ) : (
                <div className={"p-3 w-full flex items-center justify-between gap-3 rounded-lg bg-white"}>
                    <span className={'font-bodyPri font-normal text-text-800 text-sm tracking-wide'}>
                        {editedDescription ? editedDescription : descriptionPlaceholder}
                    </span>
                    <FiEdit
                        onClick={handleExpand}
                        className={`cursor-pointer text-divider-dark text-lg`}
                    />
                </div>
            )}
        </>
    );
};

export default DescriptionInputText;