import { cn } from "utils/cn.utils";

import { FaSpinner } from 'react-icons/fa';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { updateUserBookingDetail } from 'redux/booking/booking.request';
import { setModal } from 'redux/local/local.slice';
import { setModifyUserBookingDetailPayload } from 'redux/booking/booking.slice';
import { modalConst } from 'redux/local/local.const';

import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';
import { bookingOfferingTypeEnum } from "redux/booking/booking.const";

const TimeSlot = ({ dailyAvail, timeSlot }) => {
    const { modal } = useAppState((s) => s.local)
    const { user } = useAppState((state) => state.user)
    const { modifyUserBookingDetail, userBookingDetail } = useAppState((s) => s.booking)

    const dispatcher = useAppDispatcher()

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    let isSelected = false
    modifyUserBookingDetail?.payload?.schedule?.forEach((schedule) => {
        let currentDayjs = dayjs(dailyAvail.date + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
        let selectedDayjs = dayjs(schedule?.date + " " + schedule?.timeSlots[0]?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)

        isSelected = currentDayjs.format("YYYY-MM-DD HH:mm:ssZ") === selectedDayjs.format("YYYY-MM-DD HH:mm:ssZ")
    })

    const handleSelect = () => {
        if (!timeSlot?.isAvailable || modifyUserBookingDetail?.isLoading) return;

        dispatcher(setModifyUserBookingDetailPayload({
            ...modifyUserBookingDetail?.payload,
            timeZone: timeZone,
            schedule: isSelected ? [] : [{
                date: dailyAvail?.date,
                timeSlots: [{
                    startTime: timeSlot?.startTime,
                    endTime: timeSlot?.endTime
                }]
            }]
        }))
    }

    const handleConfirm = () => {
        if (!timeSlot?.isAvailable || !isSelected || modifyUserBookingDetail?.isLoading) return;

        let requestBody = {}
        if (modifyUserBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value) {
            requestBody = {
                appointment: {
                    appointmentId: modifyUserBookingDetail?.payload?.appointment?.id,
                    timeZone: timeZone,
                    schedule: modifyUserBookingDetail?.payload?.schedule
                }
            }
        } else if (modifyUserBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COURSE.value) {
            requestBody = {
                course: {
                    courseId: modifyUserBookingDetail?.payload?.course?.id,
                    timeZone: timeZone,
                }
            }
        }

        dispatcher(updateUserBookingDetail(userBookingDetail?.data?.result?.id, user?.user?.userId, requestBody))

        setTimeout(() => {
            dispatcher(setModal({
                ...modal,
                [modalConst?.CHECKOUT_SLOT_MODAL.stateKey]: false
            }))
        }, 2000)
    }

    return (
        <div className={"flex flex-row items-center justify-center"}>
            <span onClick={handleSelect} className={cn(
                "px-2 py-0.5 border-2 cursor-pointer border-primary-main",
                "font-bodyPri font-medium text-sm text-primary-main rounded",
                isSelected && "!text-text-50 bg-primary-main rounded-r-none",
                !timeSlot?.isAvailable && "text-secondary-light border-secondary-light"
            )}>
                {dayjs(dailyAvail.date + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("hh:mm A")}
            </span>
            {isSelected &&
                <span onClick={handleConfirm} className={cn(
                    "px-2 py-0.5 border-2 cursor-pointer border-secondary-main rounded-r",
                    "font-bodyPri font-medium text-sm",
                    "transition ease-in-out delay-150",
                    isSelected
                        ? "bg-secondary-main text-text-50"
                        : "hidden"
                )}>
                    {!modifyUserBookingDetail?.isLoading ? "Confirm" : ""}
                    {modifyUserBookingDetail?.isLoading &&
                        <FaSpinner className="inline-flex text-secondary-light animate-spin" />
                    }
                </span>
            }
        </div>
    )
}

export default TimeSlot