import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import FullPageLoader from 'components/loader/FullPageLoader';

import CourseHeader from 'pages/auth/edulyteLms/editLmsPages/editLmsCourse/components/courseHeader/CourseHeader';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getLmsCourseDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetLmsCourseDetail, resetAddLmsCourseDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';

import { pagesInfo } from 'utils/pagesInfo';
import { useTitle } from 'hooks/useTitle';

const EditLMSCoursePage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)
    const { lmsCourseDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()
    const { courseId } = useParams()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.EDIT_LMS_COURSE))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (user?.user) {
            setTitle({
                ...title,
                title: `${user?.user?.firstName}, Welcome to Edulyte LMS`
            })
        }
    }, [user?.user, currentPageInfo])

    useEffect(() => {
        return () => {
            dispatcher(resetLmsCourseDetail())
            dispatcher(resetAddLmsCourseDetail())
        }
    }, [])

    useEffect(() => {
        if (courseId) {
            dispatcher(getLmsCourseDetail(courseId))
        }
    }, [courseId])

    if (lmsCourseDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={lmsCourseDetail?.isLoading} />
        )
    }

    if (lmsCourseDetail?.message) {
        return (
            <div className='w-screen h-screen flex items-center justify-center'>
                <span className='w-full font-bodyPri font-normal text-red-500 text-base tracking-wide text-center'>
                    {lmsCourseDetail?.message}
                </span>
            </div>
        )
    }

    return (
        <div className={'space-y-3 bg-background-light'}>
            <CourseHeader />
            <div className={'w-full px-3'}>
                <Outlet />
            </div>
        </div>
    )
}

export default EditLMSCoursePage;