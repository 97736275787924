import { useCallback, useState } from 'react';

import { BsInfoCircle } from 'react-icons/bs';
import { IoIosCheckmark } from 'react-icons/io';

import ToolTipView from 'components/tooltipView';

import { appointmentDetailTooltips, sessionDurationConst } from 'pages/auth/editAppointment/data';
import AppointmentDropdown from 'pages/auth/editAppointment/commonComponents/AppointmentDropdown';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModifyAppointmentDetailPayload } from 'redux/appointment/appointment.slice';

import { cn } from 'utils/cn.utils';
import { debounce } from 'utils/generators.utils';

const SessionDuration = () => {
    const { modifyAppointmentDetail } = useAppState((state) => state.appointment)

    const dispatcher = useAppDispatcher()

    const [isCustomDurationEnabled, setIsCustomDurationEnabled] = useState(modifyAppointmentDetail?.payload?.duration > 60)
    const [sessionDuration, setSessionDuration] = useState(modifyAppointmentDetail?.payload?.duration > 60 ? sessionDurationConst.CUSTOM.value : modifyAppointmentDetail?.payload?.duration)
    const [customDuration, setCustomDuration] = useState(modifyAppointmentDetail?.payload?.duration)

    const onHandleChangeText = useCallback((key, value) => {
        dispatcher(setModifyAppointmentDetailPayload({
            ...modifyAppointmentDetail?.payload,
            [key]: value
        }))
    }, [modifyAppointmentDetail?.payload])

    const onHandleChangeDuration = useCallback((key, value) => {
        setCustomDuration(value)

        if (value && (value >= 30)) {
            const delayFunction = debounce.debounce(onHandleChangeText)
            delayFunction(key, value)
        }
    }, [customDuration, modifyAppointmentDetail?.payload])

    const onHandleSelectDuration = useCallback((selectedOption) => {
        if ([sessionDurationConst.THIRTY.value, sessionDurationConst.FORTY_FIVE.value, sessionDurationConst.SIXTY.value]?.includes(selectedOption?.value)) {
            setIsCustomDurationEnabled(false)

            setSessionDuration(selectedOption?.value)

            const delayFunction = debounce.debounce(onHandleChangeText)
            delayFunction("duration", selectedOption?.value)
        } else {
            setSessionDuration(null)
            setCustomDuration(30)

            const delayFunction = debounce.debounce(onHandleChangeText)
            delayFunction("duration", 30)
            setIsCustomDurationEnabled(true)
        }
    }, [modifyAppointmentDetail?.payload, sessionDuration, customDuration])

    return (
        <div className={"w-full flex flex-col gap-2"}>
            <div className={"flex items-center justify-start gap-2"}>
                <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                    {"Duration *"}
                </span>
                <ToolTipView content={appointmentDetailTooltips.duration}>
                    <button className={""}>
                        <BsInfoCircle className={"inline text-md text-text-900 hover:text-text-900"} />
                    </button>
                </ToolTipView>
            </div>
            <div className={"w-full sm:w-[12rem]"}>
                <AppointmentDropdown
                    labelItem={`appointment_duration`}
                    showLabel={false}
                    options={Object.values(sessionDurationConst)}
                    onHandleSelect={onHandleSelectDuration}
                    value={[sessionDurationConst.THIRTY.value, sessionDurationConst.FORTY_FIVE.value, sessionDurationConst.SIXTY.value]?.includes(sessionDuration) ? sessionDuration + " min" : sessionDurationConst.CUSTOM.label}
                    OptionChild={({ option }) => (
                        <div className="bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between">
                            <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                {option?.label}
                            </span>
                            {(option?.value === sessionDuration)
                                && <IoIosCheckmark size={20} color="green" />
                            }
                        </div>
                    )}
                    dropdownWrapperClassName={"w-full sm:w-[12rem]"}
                    dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                />
            </div>
            {isCustomDurationEnabled &&
                <div className={"flex flex-col items-start justify-start gap-1"}>
                    <div className={"flex items-center justify-start gap-3"}>
                        <input
                            type={"number"}
                            name={"duration"}
                            className={cn(
                                "w-16 h-10 px-3 input-number-spin-none text-text-900 bg-white rounded bg-transparent outline-none placeholder:text-text-500",
                                "border border-divider-lightDark hover:border-primary-main font-bodyPri cursor-text",
                                "focus:ring-2 focus:ring-primary-main focus:border-none transition duration-200",
                                (!customDuration || (customDuration < 30)) && "focus:ring-red-500 border-red-500 hover:border-red-500",
                            )}
                            placeholder={"E.g., 30 minutes"}
                            autoFocus={true}
                            minValue={2}
                            defaultValue={2}
                            value={(customDuration / 15)?.toString()}
                            onChange={(event) => onHandleChangeDuration(event?.target?.name, Number(event?.target?.value * 15))}
                            required={true}
                        />
                        <span className={"font-bodyPri font-normal text-text-800 text-base whitespace-nowrap"}>
                            {"x 15 min. ="}
                        </span>
                        <div className={"inline-flex items-center justify-start gap-1 font-bodyPri font-bold text-text-800 text-lg"}>
                            <span className={""}>
                                {((customDuration / 15) <= 3)
                                    ? (customDuration)
                                    : (parseFloat("" + (customDuration / 60))?.toFixed(2))
                                }
                            </span>
                            <span className={""}>
                                {((customDuration / 15) <= 3)
                                    ? " min"
                                    : " hr"
                                }
                            </span>
                        </div>
                    </div>
                    {(customDuration < 30) &&
                        <div className={"font-bodyPri font-normal text-red-500 text-sm tracking-wide"}>
                            {"The duration must be at least 30 minutes."}
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default SessionDuration;