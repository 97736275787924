import { toast } from 'react-toastify';

import lmsLessonService from 'redux/edulyteLms/lmsLesson/lmsLesson.service';
import {
    /* student lms course */
    setStudentLmsLessonListLoading,
    setStudentLmsLessonListData,
    setStudentLmsLessonListMessage,

    setStudentLmsLessonDetailLoading,
    setStudentLmsLessonDetailData,
    setStudentLmsLessonDetailMessage,

    /* tutor lms course */
    setTutorLmsLessonListLoading,
    setTutorLmsLessonListData,
    setTutorLmsLessonListMessage,

    setTutorLmsLessonDetailLoading,
    setTutorLmsLessonDetailData,
    setTutorLmsLessonDetailMessage,

    /* lms course */
    setLmsLessonListLoading,
    setLmsLessonListData,
    setLmsLessonListMessage,

    setLmsLessonDetailLoading,
    setLmsLessonDetailData,
    setLmsLessonDetailMessage,

    setAddLmsLessonDetailLoading,
    setAddLmsLessonDetailData,
    setAddLmsLessonDetailMessage,

    setModifyLmsLessonDetailLoading,
    setModifyLmsLessonDetailData,
    setModifyLmsLessonDetailMessage,

    setDestroyLmsLessonDetailLoading,
    setDestroyLmsLessonDetailMessage,

    /* lms course user */
    setLmsLessonUserListLoading,
    setLmsLessonUserListData,
    setLmsLessonUserListMessage,

    setLmsLessonUserDetailLoading,
    setLmsLessonUserDetailData,
    setLmsLessonUserDetailMessage,

    setAddLmsLessonUserDetailLoading,
    setAddLmsLessonUserDetailData,
    setAddLmsLessonUserDetailMessage,

    setModifyLmsLessonUserDetailLoading,
    setModifyLmsLessonUserDetailData,
    setModifyLmsLessonUserDetailMessage,

    setDestroyLmsLessonUserDetailLoading,
    setDestroyLmsLessonUserDetailMessage,

    /* lms course slug */
    setLmsLessonSlugListLoading,
    setLmsLessonSlugListData,
    setLmsLessonSlugListMessage,

    setLmsLessonSlugDetailLoading,
    setLmsLessonSlugDetailData,
    setLmsLessonSlugDetailMessage,

    setAddLmsLessonSlugDetailLoading,
    setAddLmsLessonSlugDetailData,
    setAddLmsLessonSlugDetailMessage,

    setModifyLmsLessonSlugDetailLoading,
    setModifyLmsLessonSlugDetailData,
    setModifyLmsLessonSlugDetailMessage,

    setDestroyLmsLessonSlugDetailLoading,
    setDestroyLmsLessonSlugDetailMessage,

    /* lms course setting */
    setLmsLessonSettingListLoading,
    setLmsLessonSettingListData,
    setLmsLessonSettingListMessage,

    setLmsLessonSettingDetailLoading,
    setLmsLessonSettingDetailData,
    setLmsLessonSettingDetailMessage,

    setAddLmsLessonSettingDetailLoading,
    setAddLmsLessonSettingDetailData,
    setAddLmsLessonSettingDetailMessage,

    setModifyLmsLessonSettingDetailLoading,
    setModifyLmsLessonSettingDetailData,
    setModifyLmsLessonSettingDetailMessage,

    setDestroyLmsLessonSettingDetailLoading,
    setDestroyLmsLessonSettingDetailMessage,

    /* lms course lecture */
    setLmsLessonLectureListLoading,
    setLmsLessonLectureListData,
    setLmsLessonLectureListMessage,

    setLmsLessonLectureDetailLoading,
    setLmsLessonLectureDetailData,
    setLmsLessonLectureDetailMessage,

    setAddLmsLessonLectureDetailLoading,
    setAddLmsLessonLectureDetailData,
    setAddLmsLessonLectureDetailMessage,

    setModifyLmsLessonLectureDetailLoading,
    setModifyLmsLessonLectureDetailData,
    setModifyLmsLessonLectureDetailMessage,

    setDestroyLmsLessonLectureDetailLoading,
    setDestroyLmsLessonLectureDetailMessage,

    /* lms course article */
    setLmsLessonArticleListLoading,
    setLmsLessonArticleListData,
    setLmsLessonArticleListMessage,

    setLmsLessonArticleDetailLoading,
    setLmsLessonArticleDetailData,
    setLmsLessonArticleDetailMessage,

    setAddLmsLessonArticleDetailLoading,
    setAddLmsLessonArticleDetailData,
    setAddLmsLessonArticleDetailMessage,

    setModifyLmsLessonArticleDetailLoading,
    setModifyLmsLessonArticleDetailData,
    setModifyLmsLessonArticleDetailMessage,

    setDestroyLmsLessonArticleDetailLoading,
    setDestroyLmsLessonArticleDetailMessage,

    /* lms course quiz */
    setLmsLessonQuizListLoading,
    setLmsLessonQuizListData,
    setLmsLessonQuizListMessage,

    setLmsLessonQuizDetailLoading,
    setLmsLessonQuizDetailData,
    setLmsLessonQuizDetailMessage,

    setAddLmsLessonQuizDetailLoading,
    setAddLmsLessonQuizDetailData,
    setAddLmsLessonQuizDetailMessage,

    setModifyLmsLessonQuizDetailLoading,
    setModifyLmsLessonQuizDetailData,
    setModifyLmsLessonQuizDetailMessage,

    setDestroyLmsLessonQuizDetailLoading,
    setDestroyLmsLessonQuizDetailMessage,

    setAddLmsLessonInstructorDetailLoading,
    setAddLmsLessonInstructorDetailData,
    setAddLmsLessonInstructorDetailMessage,

    setModifyLmsLessonInstructorDetailLoading,
    setModifyLmsLessonInstructorDetailData,
    setModifyLmsLessonInstructorDetailMessage,

    setDestroyLmsLessonInstructorDetailLoading,
    setDestroyLmsLessonInstructorDetailMessage,
} from 'redux/edulyteLms/lmsLesson/lmsLesson.slice';

/* student lms lesson request */
export const getStudentLmsLessonList = (query) => async (dispatch) => {
    dispatch(setStudentLmsLessonListLoading(true))
    try {
        const requestData = {
            query: query
        }
        const response = await lmsLessonService.getStudentLmsLessonList(requestData)
        if (response.status === 200) {
            dispatch(setStudentLmsLessonListData(response.data.data))
        } else {
            throw new Error(response)
        }
    }
    catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setStudentLmsLessonListMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    }
    finally {
        dispatch(setStudentLmsLessonListLoading(false))
    }
}

export const getStudentLmsLessonDetail = (slug) => async (dispatch) => {
    dispatch(setStudentLmsLessonDetailLoading(true))

    try {
        const requestData = {
            params: {
                slug: slug
            }
        }
        const response = await lmsLessonService.getStudentLmsLessonDetail(requestData)
        if (response.status === 200) {
            dispatch(setStudentLmsLessonDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setStudentLmsLessonDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    } finally {
        dispatch(setStudentLmsLessonDetailLoading(false))
    }
}


/* tutor lms lesson request */
export const getTutorLmsLessonList = (query) => async (dispatch) => {
    dispatch(setTutorLmsLessonListLoading(true))
    try {
        const requestData = {
            query: query
        }
        const response = await lmsLessonService.getTutorLmsLessonList(requestData)
        if (response.status === 200) {
            dispatch(setTutorLmsLessonListData(response.data.data))
        } else {
            throw new Error(response)
        }
    }
    catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setTutorLmsLessonListMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    }
    finally {
        dispatch(setTutorLmsLessonListLoading(false))
    }
}

export const getTutorLmsLessonDetail = (id) => async (dispatch) => {
    dispatch(setTutorLmsLessonDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLessonService.getTutorLmsLessonDetail(requestData)
        if (response.status === 200) {
            dispatch(setTutorLmsLessonDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setTutorLmsLessonDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    } finally {
        dispatch(setTutorLmsLessonDetailLoading(false))
    }
}

/* lms lesson request */
export const getLmsLessonList = (query) => async (dispatch) => {
    dispatch(setLmsLessonListLoading(true))
    try {
        const requestData = {
            query: query
        }
        const response = await lmsLessonService.getLmsLessonList(requestData)
        if (response.status === 200) {
            dispatch(setLmsLessonListData(response.data.data))
        } else {
            throw new Error(response)
        }
    }
    catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsLessonListMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    }
    finally {
        dispatch(setLmsLessonListLoading(false))
    }
}

export const getLmsLessonDetail = (id) => async (dispatch) => {
    dispatch(setLmsLessonDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLessonService.getLmsLessonDetail(requestData)

        if (response.status === 200) {
            dispatch(setLmsLessonDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsLessonDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    } finally {
        dispatch(setLmsLessonDetailLoading(false))
    }
}

export const createLmsLessonDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsLessonDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await lmsLessonService.createLmsLessonDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddLmsLessonDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddLmsLessonDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setAddLmsLessonDetailLoading(false))
    }
}

export const updateLmsLessonDetail = (id, body) => async (dispatch) => {
    dispatch(setModifyLmsLessonDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            },
            body: body
        }
        const response = await lmsLessonService.updateLmsLessonDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyLmsLessonDetailData(response?.data?.data))
            dispatch(setTutorLmsLessonDetailData(response?.data?.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyLmsLessonDetailMessage(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setModifyLmsLessonDetailLoading(false))
    }
}

export const deleteLmsLessonDetail = (id) => async (dispatch) => {
    dispatch(setDestroyLmsLessonDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLessonService.deleteLmsLesson(requestData)
        if (response.status === 204) {
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyLmsLessonDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setDestroyLmsLessonDetailLoading(false))
    }
}

/* lms lesson user request */
export const getLmsLessonUserList = (query) => async (dispatch) => {
    dispatch(setLmsLessonUserListLoading(true))
    try {
        const requestData = {
            query: query
        }
        const response = await lmsLessonService.getLmsLessonUserList(requestData)
        if (response.status === 200) {
            dispatch(setLmsLessonUserListData(response.data.data))
        } else {
            throw new Error(response)
        }
    }
    catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsLessonUserListMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    }
    finally {
        dispatch(setLmsLessonUserListLoading(false))
    }
}

export const getLmsLessonUserDetail = (id) => async (dispatch) => {
    dispatch(setLmsLessonUserDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLessonService.getLmsLessonUserDetail(requestData)

        if (response.status === 200) {
            dispatch(setLmsLessonUserDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsLessonUserDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    } finally {
        dispatch(setLmsLessonUserDetailLoading(false))
    }
}

export const createLmsLessonUserDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsLessonUserDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await lmsLessonService.createLmsLessonUserDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddLmsLessonUserDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddLmsLessonUserDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setAddLmsLessonUserDetailLoading(false))
    }
}

export const updateLmsLessonUserDetail = (id, body) => async (dispatch) => {
    dispatch(setModifyLmsLessonUserDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            },
            body: body
        }
        const response = await lmsLessonService.updateLmsLessonUserDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyLmsLessonUserDetailData(response?.data?.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyLmsLessonUserDetailMessage(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setModifyLmsLessonUserDetailLoading(false))
    }
}

export const deleteLmsLessonUserDetail = (id) => async (dispatch) => {
    dispatch(setDestroyLmsLessonUserDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLessonService.deleteLmsLesson(requestData)
        if (response.status === 204) {
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyLmsLessonUserDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setDestroyLmsLessonUserDetailLoading(false))
    }
}

/* lms lesson slug request */
export const getLmsLessonSlugList = (query) => async (dispatch) => {
    dispatch(setLmsLessonSlugListLoading(true))
    try {
        const requestData = {
            query: query
        }
        const response = await lmsLessonService.getLmsLessonSlugList(requestData)
        if (response.status === 200) {
            dispatch(setLmsLessonSlugListData(response.data.data))
        } else {
            throw new Error(response)
        }
    }
    catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsLessonSlugListMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    }
    finally {
        dispatch(setLmsLessonSlugListLoading(false))
    }
}

export const getLmsLessonSlugDetail = (id) => async (dispatch) => {
    dispatch(setLmsLessonSlugDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLessonService.getLmsLessonSlugDetail(requestData)

        if (response.status === 200) {
            dispatch(setLmsLessonSlugDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsLessonSlugDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    } finally {
        dispatch(setLmsLessonSlugDetailLoading(false))
    }
}

export const createLmsLessonSlugDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsLessonSlugDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await lmsLessonService.createLmsLessonSlugDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddLmsLessonSlugDetailData(response.data.data))
            dispatch(updateLmsLessonDetail(body?.lesson_id, { default_slug_id: response?.data?.data?.id }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddLmsLessonSlugDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setAddLmsLessonSlugDetailLoading(false))
    }
}

export const updateLmsLessonSlugDetail = (id, body) => async (dispatch) => {
    dispatch(setModifyLmsLessonSlugDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            },
            body: body
        }
        const response = await lmsLessonService.updateLmsLessonSlugDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyLmsLessonSlugDetailData(response?.data?.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyLmsLessonSlugDetailMessage(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setModifyLmsLessonSlugDetailLoading(false))
    }
}

export const deleteLmsLessonSlugDetail = (id) => async (dispatch, getState) => {
    dispatch(setDestroyLmsLessonSlugDetailLoading(true))

    try {
        const { tutorLmsLessonDetail } = getState().lms.lmsLesson
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLessonService.deleteLmsLessonSlugDetail(requestData)
        if (response.status === 204) {
            dispatch(setTutorLmsLessonDetailData({
                ...tutorLmsLessonDetail?.data,
                lesson_slugs: tutorLmsLessonDetail?.data?.lesson_slugs?.filter((lessonSlug) => lessonSlug?.id != id)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyLmsLessonSlugDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setDestroyLmsLessonSlugDetailLoading(false))
    }
}

/* lms lesson setting request */
export const getLmsLessonSettingList = (query) => async (dispatch) => {
    dispatch(setLmsLessonSettingListLoading(true))
    try {
        const requestData = {
            query: query
        }
        const response = await lmsLessonService.getLmsLessonSettingList(requestData)
        if (response.status === 200) {
            dispatch(setLmsLessonSettingListData(response.data.data))
        } else {
            throw new Error(response)
        }
    }
    catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsLessonSettingListMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    }
    finally {
        dispatch(setLmsLessonSettingListLoading(false))
    }
}

export const getLmsLessonSettingDetail = (id) => async (dispatch) => {
    dispatch(setLmsLessonSettingDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLessonService.getLmsLessonSettingDetail(requestData)

        if (response.status === 200) {
            dispatch(setLmsLessonSettingDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsLessonSettingDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    } finally {
        dispatch(setLmsLessonSettingDetailLoading(false))
    }
}

export const createLmsLessonSettingDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsLessonSettingDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await lmsLessonService.createLmsLessonSettingDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddLmsLessonSettingDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddLmsLessonSettingDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setAddLmsLessonSettingDetailLoading(false))
    }
}

export const updateLmsLessonSettingDetail = (id, body) => async (dispatch) => {
    dispatch(setModifyLmsLessonSettingDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            },
            body: body
        }
        const response = await lmsLessonService.updateLmsLessonSettingDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyLmsLessonSettingDetailData(response?.data?.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyLmsLessonSettingDetailMessage(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setModifyLmsLessonSettingDetailLoading(false))
    }
}

export const deleteLmsLessonSettingDetail = (id) => async (dispatch) => {
    dispatch(setDestroyLmsLessonSettingDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLessonService.deleteLmsLesson(requestData)
        if (response.status === 204) {
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyLmsLessonSettingDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setDestroyLmsLessonSettingDetailLoading(false))
    }
}

/* lms lesson lecture request */
export const getLmsLessonLectureList = (query) => async (dispatch) => {
    dispatch(setLmsLessonLectureListLoading(true))
    try {
        const requestData = {
            query: query
        }
        const response = await lmsLessonService.getLmsLessonLectureList(requestData)
        if (response.status === 200) {
            dispatch(setLmsLessonLectureListData(response.data.data))
        } else {
            throw new Error(response)
        }
    }
    catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsLessonLectureListMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    }
    finally {
        dispatch(setLmsLessonLectureListLoading(false))
    }
}

export const getLmsLessonLectureDetail = (id) => async (dispatch) => {
    dispatch(setLmsLessonLectureDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLessonService.getLmsLessonLectureDetail(requestData)

        if (response.status === 200) {
            dispatch(setLmsLessonLectureDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsLessonLectureDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    } finally {
        dispatch(setLmsLessonLectureDetailLoading(false))
    }
}

export const createLmsLessonLectureDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsLessonLectureDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await lmsLessonService.createLmsLessonLectureDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddLmsLessonLectureDetailData(response.data.data))
            dispatch(getTutorLmsLessonDetail(body?.lesson_id))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddLmsLessonLectureDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setAddLmsLessonLectureDetailLoading(false))
    }
}

export const updateLmsLessonLectureDetail = (id, body) => async (dispatch, getState) => {
    dispatch(setModifyLmsLessonLectureDetailLoading(true))

    try {
        const { tutorLmsLessonDetail } = getState().lms.lmsLesson
        const requestData = {
            params: { id: id },
            body: body
        }
        const response = await lmsLessonService.updateLmsLessonLectureDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyLmsLessonLectureDetailData(response?.data?.data))
            dispatch(getTutorLmsLessonDetail(tutorLmsLessonDetail?.data?.id))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyLmsLessonLectureDetailMessage(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setModifyLmsLessonLectureDetailLoading(false))
    }
}

export const deleteLmsLessonLectureDetail = (id) => async (dispatch, getState) => {
    dispatch(setDestroyLmsLessonLectureDetailLoading(true))

    try {
        const { tutorLmsLessonDetail } = getState().lms.lmsLesson
        const requestData = {
            params: { id: id }
        }
        const response = await lmsLessonService.deleteLmsLessonLectureDetail(requestData)
        if (response.status === 204) {
            dispatch(getTutorLmsLessonDetail(tutorLmsLessonDetail?.data?.id))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyLmsLessonLectureDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setDestroyLmsLessonLectureDetailLoading(false))
    }
}

/* lms lesson article request */
export const getLmsLessonArticleList = (query) => async (dispatch) => {
    dispatch(setLmsLessonArticleListLoading(true))
    try {
        const requestData = {
            query: query
        }
        const response = await lmsLessonService.getLmsLessonArticleList(requestData)
        if (response.status === 200) {
            dispatch(setLmsLessonArticleListData(response.data.data))
        } else {
            throw new Error(response)
        }
    }
    catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsLessonArticleListMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    }
    finally {
        dispatch(setLmsLessonArticleListLoading(false))
    }
}

export const getLmsLessonArticleDetail = (id) => async (dispatch) => {
    dispatch(setLmsLessonArticleDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLessonService.getLmsLessonArticleDetail(requestData)

        if (response.status === 200) {
            dispatch(setLmsLessonArticleDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsLessonArticleDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    } finally {
        dispatch(setLmsLessonArticleDetailLoading(false))
    }
}

export const createLmsLessonArticleDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsLessonArticleDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await lmsLessonService.createLmsLessonArticleDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddLmsLessonArticleDetailData(response.data.data))
            dispatch(getTutorLmsLessonDetail(body?.lesson_id))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddLmsLessonArticleDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setAddLmsLessonArticleDetailLoading(false))
    }
}

export const updateLmsLessonArticleDetail = (id, body) => async (dispatch, getState) => {
    dispatch(setModifyLmsLessonArticleDetailLoading(true))

    try {
        const { tutorLmsLessonDetail } = getState().lms.lmsLesson
        const requestData = {
            params: {
                id: id
            },
            body: body
        }
        const response = await lmsLessonService.updateLmsLessonArticleDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyLmsLessonArticleDetailData(response?.data?.data))
            dispatch(getTutorLmsLessonDetail(tutorLmsLessonDetail?.data?.id))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyLmsLessonArticleDetailMessage(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setModifyLmsLessonArticleDetailLoading(false))
    }
}

export const deleteLmsLessonArticleDetail = (id) => async (dispatch, getState) => {
    dispatch(setDestroyLmsLessonArticleDetailLoading(true))

    try {
        const { tutorLmsLessonDetail } = getState().lms.lmsLesson
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLessonService.deleteLmsLessonArticleDetail(requestData)
        if (response.status === 204) {
            dispatch(getTutorLmsLessonDetail(tutorLmsLessonDetail?.data?.id))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyLmsLessonArticleDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setDestroyLmsLessonArticleDetailLoading(false))
    }
}

/* lms lesson quiz request */
export const getLmsLessonQuizList = (query) => async (dispatch) => {
    dispatch(setLmsLessonQuizListLoading(true))
    try {
        const requestData = {
            query: query
        }
        const response = await lmsLessonService.getLmsLessonQuizList(requestData)
        if (response.status === 200) {
            dispatch(setLmsLessonQuizListData(response.data.data))
        } else {
            throw new Error(response)
        }
    }
    catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsLessonQuizListMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    }
    finally {
        dispatch(setLmsLessonQuizListLoading(false))
    }
}

export const getLmsLessonQuizDetail = (id) => async (dispatch) => {
    dispatch(setLmsLessonQuizDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLessonService.getLmsLessonQuizDetail(requestData)

        if (response.status === 200) {
            dispatch(setLmsLessonQuizDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsLessonQuizDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    } finally {
        dispatch(setLmsLessonQuizDetailLoading(false))
    }
}

export const createLmsLessonQuizDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsLessonQuizDetailLoading(true))

    try {
        const { tutorLmsLessonDetail } = getState().lms.lmsLesson
        const requestData = {
            body: body
        }
        const response = await lmsLessonService.createLmsLessonQuizDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddLmsLessonQuizDetailData(response.data.data))
            dispatch(getTutorLmsLessonDetail(tutorLmsLessonDetail?.data?.id))
            // dispatch(setTutorLmsLessonDetailData({
            //     ...tutorLmsLessonDetail?.data,
            //     lesson_quiz: response.data.data
            // }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddLmsLessonQuizDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setAddLmsLessonQuizDetailLoading(false))
    }
}

export const updateLmsLessonQuizDetail = (id, body) => async (dispatch, getState) => {
    dispatch(setModifyLmsLessonQuizDetailLoading(true))

    try {
        const { tutorLmsLessonDetail } = getState().lms.lmsLesson
        const requestData = {
            params: {
                id: id
            },
            body: body
        }
        const response = await lmsLessonService.updateLmsLessonQuizDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyLmsLessonQuizDetailData(response?.data?.data))
            dispatch(getTutorLmsLessonDetail(tutorLmsLessonDetail?.data?.id))
            // dispatch(setTutorLmsLessonDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyLmsLessonQuizDetailMessage(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setModifyLmsLessonQuizDetailLoading(false))
    }
}

export const deleteLmsLessonQuizDetail = (id) => async (dispatch, getState) => {
    dispatch(setDestroyLmsLessonQuizDetailLoading(true))

    try {
        const { tutorLmsLessonDetail } = getState().lms.lmsLesson
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLessonService.deleteLmsLessonQuizDetail(requestData)
        if (response.status === 204) {
            // dispatch(getTutorLmsLessonDetail(tutorLmsLessonDetail?.data?.id))
            dispatch(setTutorLmsLessonDetailData({
                ...tutorLmsLessonDetail?.data,
                type: null,
                lesson_quiz: null
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyLmsLessonQuizDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setDestroyLmsLessonQuizDetailLoading(false))
    }
}

// lms lesson instructor detail
export const createLmsLessonInstructorDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsLessonInstructorDetailLoading(true))

    try {
        const { tutorLmsLessonDetail } = getState().lms.lmsLesson
        const requestData = {
            body: body
        }
        const response = await lmsLessonService.createLmsLessonInstructorDetail(requestData)
        // check for status update from backend
        if (response.status === 200 || response.status === 201) {
            dispatch(setAddLmsLessonInstructorDetailData(response.data.data))
            dispatch(setTutorLmsLessonDetailData({
                ...tutorLmsLessonDetail?.data,
                lesson_tutors: [...tutorLmsLessonDetail?.data?.lesson_tutors, response.data.data]
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddLmsLessonInstructorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsLessonInstructorDetailLoading(false))
    }
}

// update lms lesson instructor detail
export const updateLmsLessonInstructorDetail = (lessonInstructorId, body) => async (dispatch, getState) => {
    dispatch(setModifyLmsLessonInstructorDetailLoading(true))

    try {
        const { tutorLmsLessonDetail } = getState().lms.lmsLesson
        const requestData = {
            params: { lessonInstructorId: lessonInstructorId },
            body: body
        }
        const response = await lmsLessonService.updateLmsLessonInstructorDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyLmsLessonInstructorDetailData(response.data.data))
            dispatch(setTutorLmsLessonDetailData({
                ...tutorLmsLessonDetail?.data,
                lesson_tutors: tutorLmsLessonDetail?.data?.lesson_tutors?.map((lessonTutor) => (
                    (lessonTutor?.id === response.data.data.id) ? response.data.data : lessonTutor
                ))
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyLmsLessonInstructorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyLmsLessonInstructorDetailLoading(false))
    }
}

// delete lms lesson instructor detail
export const deleteLmsLessonInstructorDetail = (lessonInstructorId) => async (dispatch, getState) => {
    dispatch(setDestroyLmsLessonInstructorDetailLoading(false))

    try {
        const { tutorLmsLessonDetail } = getState().lms.lmsLesson
        const requestData = {
            params: { lessonInstructorId: lessonInstructorId }
        }
        const response = await lmsLessonService.deleteLmsLessonInstructorDetail(requestData)
        if (response.status === 204) {
            dispatch(setTutorLmsLessonDetailData({
                ...tutorLmsLessonDetail?.data,
                lesson_tutors: tutorLmsLessonDetail?.data?.lesson_tutors?.filter((lessonTutor) => lessonTutor?.id != lessonInstructorId)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyLmsLessonInstructorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyLmsLessonInstructorDetailLoading(false))
    }
}