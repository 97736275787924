import FadedCollapse from "components/fadedCollapse";

const DescriptionTab = ({ content = "" }) => {
  return (
    <div className='overflow-hidden'>
      <FadedCollapse>
        <div>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </FadedCollapse>
    </div>
  )
}

export default DescriptionTab
