import { cn } from "utils/cn.utils";

import { FaRegComment } from "react-icons/fa";
import { FcGraduationCap } from "react-icons/fc";

import RatingStars from "components/ratingStars/RatingStars";
import MuxVideoPlayer from "components/muxVideoPlayer/MuxVideoPlayer";

import { useAppState } from "hooks/useStore";

const BookingTutorCard = () => {
    const { tutorPublicProfile } = useAppState((s) => s.tutor)

    const NEW_TUTOR_PUBLIC_PROFILE_RATING = 2

    return (
        <div className={"w-full space-y-5"}>
            <div className={"w-full font-bodyPri font-bold text-text-900 text-lg text-center tracking-wide leading-6"}>
                {"Your Tutor"}
            </div>
            <div className={"w-full flex flex-col items-start justify-start gap-10"}>
                <div className={"w-full flex flex-col items-start justify-start gap-5"}>
                    <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>
                    <div className={"w-full flex items-center justify-start gap-5"}>
                        <div className={"relative w-40 h-40 rounded-full sm:w-32 sm:h-32"}>
                            <img
                                src={tutorPublicProfile?.tutorPublicProfile?.profilePicUrl}
                                alt={"profileImg"}
                                className={"w-full h-full p-2 rounded-full object-cover"}
                            />
                            <div className={"relative has-tooltip"}>
                                <div className={'absolute -top-9 bottom-5 right-5 w-6 h-6 rounded-full overflow-hidden border-2 border-white'}>
                                    <img
                                        src={`https://flagcdn.com/16x12/${tutorPublicProfile?.tutorPublicProfile?.fromCountry?.countryDomain}.png`.toLowerCase()}
                                        alt={"country-flag"}
                                        className='w-full h-full object-cover'
                                    />
                                </div>
                                <span
                                    className={cn(
                                        "w-16 px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -top-2 -right-0",
                                        "flex flex-col items-center justify-start overflow-hidden"
                                    )}
                                >
                                    {tutorPublicProfile?.tutorPublicProfile?.fromCountry?.country}
                                </span>
                            </div>
                        </div>
                        <div className={"flex flex-col items-start justify-start gap-3"}>
                            <div className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                {tutorPublicProfile?.tutorPublicProfile?.firstName + " " + tutorPublicProfile?.tutorPublicProfile?.lastName?.charAt(0)?.toUpperCase() + "."}
                            </div>


                            <div className="font-bodyPri font-normal text-lg">
                                {tutorPublicProfile?.tutorPublicProfile?.elevatorPitch}
                            </div>
                            <div className='h-1 w-5 bg-secondary-main rounded-lg'></div>


                            <div className='flex flex-wrap gap-2 items-center'>
                                {tutorPublicProfile?.tutorPublicProfile?.badges?.map((badge, index) => (
                                    <div
                                        key={index}
                                        className='relative has-tooltip w-8 h-8 rounded-full border border-text-500 p-0.5 cursor-pointer'
                                    >
                                        <img
                                            src={badge?.badge?.badgePicUrl}
                                            alt={badge?.badge?.tooltip}
                                            className={"w-full h-full object-cover"}
                                        />
                                        <span className='tooltip top-8 -left-5 w-fit px-2 py-1 bg-black text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide sm:whitespace-nowrap'>
                                            {badge?.badge?.tooltip}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {tutorPublicProfile?.tutorPublicProfile?.rating?.avgRating > NEW_TUTOR_PUBLIC_PROFILE_RATING &&
                        <div className="flex flex-col items-center gap-1">
                            <RatingStars
                                rating={tutorPublicProfile?.tutorPublicProfile.rating?.avgRating}
                                fullRating={5}
                            />
                            <div className="font-normal text-xs text-complementry-main">
                                {tutorPublicProfile?.tutorPublicProfile?.rating?.avgRating?.toFixed(1)} ({tutorPublicProfile?.tutorPublicProfile?.rating?.totalStars?.count})
                            </div>
                        </div>
                    }

                    <div className={"w-full md:w-[25rem]"}>
                        <MuxVideoPlayer
                            playbackId={tutorPublicProfile?.tutorPublicProfile?.videoUrl}
                            isShowButtons={false}
                            isShowDownloadButton={false}
                        />
                    </div>
                </div>
                <div className={"flex flex-col items-start justify-start gap-3"}>
                    <div className={"flex text-base font-bodyPri tracking-wide gap-2"}>
                        <FaRegComment size={24} color="#CCC" />
                        <span className={"text-text-600"}>
                            {"Speaks:"}
                        </span>
                        {tutorPublicProfile?.tutorPublicProfile?.languages?.slice(0, 2).map((language, index) => (
                            <span
                                key={index}
                                className={"flex justify-center items-center gap-1 text-medium font-medium"}
                            >
                                {index !== 0 &&
                                    <p>{"|"}</p>
                                }
                                {language?.language}
                            </span>
                        ))}
                    </div>
                    <div className={"flex text-base font-bodyPri tracking-wide gap-2 overflow-hidden"}>
                        <FcGraduationCap size={24} color="#CCC" />
                        <span className={"text-text-600"}>
                            {"Teaches:"}
                        </span>
                        <span className={"text-medium font-medium"}>
                            {tutorPublicProfile?.tutorPublicProfile?.categories?.slice(0, 2).join(" | ")}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingTutorCard;