import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { MdGroups } from 'react-icons/md';
import { IoMdPeople } from 'react-icons/io';
import { AiOutlineCalendar } from 'react-icons/ai';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setLocals } from 'redux/local/local.slice';
import { localsConst } from 'redux/local/local.const';
import { userRoles } from 'redux/auth/auth.const';
import { availabilityIsRecurringEnum, availabilityEndTypeEnum } from 'redux/availability/availability.const';
import { bookingOfferingTypeEnum, bookingRegularPriceModelEnum } from 'redux/booking/booking.const';
import { coursePricingModelTypeEnum, courseType } from 'redux/course/course.const';

import { cn } from "utils/cn.utils";
import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

const DayItems = ({ localDay, timeSlots }) => {

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    return timeSlots?.length > 0 && (
        <div className={cn(
            "col-span-1 flex flex-col justify-start items-center gap-1 py-2 w-32 h-full",
            "border-2 border-divider-medium rounded-lg bg-primary-light"
        )}>
            <span className={"font-bodyPri font-medium text-base text-primary-dark"}>
                {localDay}
            </span>
            <div className={"flex flex-col items-center gap-1"}>
                {timeSlots?.length > 0 && timeSlots?.map((timeSlot, index) => (
                    <span key={index} className={"font-bodyPri font-medium text-base text-primary-dark"}>
                        {dayjs(dayjs().tz(timeZone).format("YYYY-MM-DD") + " " + timeSlot?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("hh:mm A")}
                    </span>
                ))}
            </div>
        </div>
    )
}

const DateTimeItems = ({ dateTimeSlot }) => {

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    return dateTimeSlot?.timeSlots?.length > 0 && (
        <div className={cn(
            "col-span-1 flex flex-col justify-start items-center gap-1 py-2 w-32 h-full",
            "border-2 border-divider-medium rounded-lg bg-primary-light"
        )}>
            <span className={"font-bodyPri font-medium text-base text-primary-dark"}>
                {dayjs(dateTimeSlot?.date + " " + dateTimeSlot?.timeSlots[0]?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("ddd, DD MMM YY")}
            </span>
            <div className={"flex flex-col items-center gap-1"}>
                {dateTimeSlot?.timeSlots.map((timeSlot, index) => (
                    <span key={index} className={"font-bodyPri font-medium text-base text-primary-dark"}>
                        {dayjs(dateTimeSlot?.date + " " + timeSlot?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("hh:mm A")}
                    </span>
                ))}
            </div>
        </div>
    )
}

const BookingCheckoutCard = () => {
    const { locals } = useAppState((state) => state.local)
    const { user } = useAppState(s => s.user)
    const { session } = useAppState(s => s.auth)
    const { userPreBookingDetail } = useAppState(s => s.booking)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    const isMyOffering = useMemo(() => (user?.user?.userId === userPreBookingDetail?.data?.result?.course?.tutor?.user?.id), [user?.user?.userId, userPreBookingDetail?.data?.result])
    const isMyCourse = useMemo(() => (userPreBookingDetail?.data?.result?.course?.type === courseType?.GROUP.value) && user?.user?.myCourses?.includes(userPreBookingDetail?.data?.result?.course?.id), [userPreBookingDetail?.data?.result, user?.user?.myCourses])

    const onHandleCourse = () => {
        window.open(`${EDULYTE_APP_DOMAIN_URL}${pagesInfo?.COURSE?.pagePath}/${userPreBookingDetail?.data?.result?.course?.id}`, "_blank")
    }

    const onHandleCheckout = () => {
        if (!session?.isLoggedIn || !user?.user) {
            navigate(`${pagesInfo?.LOG_IN?.pagePath}?redirect_url=${window.location.href}`)
            return;
        }
        if (session?.isLoggedIn && (user?.user && (locals.userRole === userRoles?.TUTOR?.value))) {
            if (!window.confirm("To proceed, please switch to the learner role")) return;
            dispatcher(setLocals({
                ...locals,
                [localsConst.USER_ROLE.value]: userRoles?.STUDENT?.value
            }))

        }

        navigate(`${pagesInfo?.CHECKOUT_BOOKING?.pagePath}/${userPreBookingDetail?.data?.result?.id}`)
    }

    return (
        <div className={"w-full md:w-[28rem] h-[44rem] bg-white rounded-lg shadow-all space-y-8 p-5"}>
            <div className={"space-y-8"}>

                {(userPreBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value) &&
                    <div className={"grid grid-cols-12 gap-5"}>
                        <div className={"w-full col-start-1 col-span-9 flex flex-col items-start gap-1"}>
                            <span className={"w-full self-start truncate text-start font-bodyPri font-medium text-base text-text-900"}>
                                {userPreBookingDetail?.data?.result?.appointment?.title.substring(0, 30) + (userPreBookingDetail?.data?.result?.appointment?.title.length > 30 ? "..." : "")}
                            </span>
                            <span className={"w-full self-end truncate text-end font-bodyPri font-normal text-sm text-text-800"}>
                                {userPreBookingDetail?.data?.result?.appointment?.user?.firstName + " " + userPreBookingDetail?.data?.result?.appointment?.user?.lastName.charAt(0) + "."}
                            </span>
                        </div>
                        <div className={"col-start-10 col-span-3"}>
                            <div className={"w-full flex items-center justify-end"}>
                                <div className={'relative w-12 h-12 rounded-full'}>
                                    <img
                                        src={userPreBookingDetail?.data?.result?.appointment?.user?.profilePicUrl}
                                        alt={"profile-pic"}
                                        className="w-full h-full rounded-full object-cover"
                                    />
                                    <div className='absolute right-0.5 bottom-0.5 w-3 h-3 rounded-full overflow-hidden border-white'>
                                        <img
                                            src={`https://flagcdn.com/16x12/${userPreBookingDetail?.data?.result?.appointment?.user?.fromCountry?.countryDomain}.png`.toLowerCase()}
                                            alt={"country-flag"}
                                            className='w-full h-full object-cover'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {(userPreBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum.COURSE.value) &&
                    <div className={"grid grid-cols-12 gap-5"}>
                        <div className={"w-full col-start-1 col-span-9 flex flex-col items-start gap-1"}>
                            <span
                                className={"w-full self-start truncate text-start font-bodyPri font-medium text-base text-primary-dark cursor-pointer"}
                                onClick={onHandleCourse}
                            >
                                {userPreBookingDetail?.data?.result?.course?.title.substring(0, 30) + (userPreBookingDetail?.data?.result?.course?.title.length > 30 ? "..." : "")}
                            </span>
                            <span className={"w-full self-end truncate text-end font-bodyPri font-normal text-sm text-text-800"}>
                                {userPreBookingDetail?.data?.result?.course?.tutor?.user?.firstName + " " + userPreBookingDetail?.data?.result?.course?.tutor?.user?.lastName.charAt(0) + "."}
                            </span>
                        </div>
                        <div className={"col-start-10 col-span-3"}>
                            <div className={"w-full flex items-center justify-end"}>
                                <div className={'relative w-12 h-12 rounded-full'}>
                                    <img
                                        src={userPreBookingDetail?.data?.result?.course?.tutor?.user?.profilePicUrl}
                                        alt={"profile-pic"}
                                        className="w-full h-full rounded-full object-cover"
                                    />
                                    <div className='absolute right-0.5 bottom-0.5 w-3 h-3 rounded-full overflow-hidden border-white'>
                                        <img
                                            src={`https://flagcdn.com/16x12/${userPreBookingDetail?.data?.result?.course?.tutor?.user?.fromCountry?.countryDomain}.png`.toLowerCase()}
                                            alt={"country-flag"}
                                            className='w-full h-full object-cover'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>

                <div className={"w-full space-y-2"}>
                    <div className={"flex flex-row items-center justify-between"}>
                        <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                            {"No of sessions"}
                        </span>
                    </div>
                    <div className={"flex items-center justify-between"}>
                        <span className={"font-bodyPrim font-medium text-base text-text-900"}>
                            {userPreBookingDetail?.data?.result?.bookingPrice?.sessionCount + " session(s)"}
                        </span>
                        <span className={"bg-primary-light px-3 py-1 rounded flex gap-1 border-2 border-divider-medium"}>
                            {((userPreBookingDetail?.data?.result?.bookingPrice?.discountPct === 100)
                                || (userPreBookingDetail?.data?.result?.bookingPrice?.priceModel === bookingRegularPriceModelEnum.FREE.value))
                                ? bookingRegularPriceModelEnum.FREE.label
                                : (`${userPreBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code} ${parseFloat(userPreBookingDetail?.data?.result?.bookingPrice?.amount / 100).toFixed(2)}`
                                    + ` ${coursePricingModelTypeEnum[userPreBookingDetail?.data?.result?.bookingPrice?.priceModel?.toUpperCase()]?.label}`)
                            }
                        </span>
                    </div>
                </div>

                <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>

                <div className={"w-full space-y-2"}>
                    <div className={"flex items-center justify-between"}>
                        <span className={"font-bodyPri font-medium text-md text-text-800"}>
                            {`Date and time (${dayjs().tz(timeZone).format("z")} ${timeZone})`}
                        </span>
                    </div>

                    <div className={"flex flex-col gap-2"}>
                        {(userPreBookingDetail?.data?.result?.bookingSchedule?.type === courseType.GROUP.value) && userPreBookingDetail?.data?.result?.bookingSchedule?.startDateTime &&
                            <div className={"flex flex-row items-start justify-start gap-2"}>
                                <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                    {dayjs(userPreBookingDetail?.data?.result?.bookingSchedule?.startDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format('ddd, MMM DD YYYY')}
                                </span>
                                {(userPreBookingDetail?.data?.result?.bookingSchedule?.endType === availabilityEndTypeEnum.ON_DATE.value)
                                    && userPreBookingDetail?.data?.result?.bookingSchedule?.endDateTime &&
                                    <>
                                        <span className={"font-bodyPri font-medium text-base text-text-700"}>{"-"}</span>
                                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                            {dayjs(userPreBookingDetail?.data?.result?.bookingSchedule?.endDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format('ddd, MMM DD YYYY')}
                                        </span>
                                    </>
                                }
                                {(userPreBookingDetail?.data?.result?.bookingSchedule?.endType === availabilityEndTypeEnum.WEEKLY_CYCLE.value) &&
                                    <>
                                        <span className={"font-bodyPri font-medium text-base text-text-700"}>{"-"}</span>
                                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                            {`Repeat ${userPreBookingDetail?.data?.result?.bookingSchedule?.weeklyCycle} weeks`}
                                        </span>
                                    </>
                                }
                                {(userPreBookingDetail?.data?.result?.bookingSchedule?.endType === availabilityEndTypeEnum.FOREVER.value) &&
                                    <>
                                        <span className={"font-bodyPri font-medium text-base text-text-700"}>{"-"}</span>
                                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                            {"Never Ends"}
                                        </span>
                                    </>
                                }
                            </div>
                        }

                        {(userPreBookingDetail?.data?.result?.bookingSchedule?.isRecurring === availabilityIsRecurringEnum.NON_RECURRING.value) &&
                            <div className={cn(
                                "grid grid-flow-col place-content-start gap-2 px-1 pb-3 overflow-hidden overflow-x-scroll",
                                "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                            )}>
                                {userPreBookingDetail?.data?.result?.bookingSchedule?.schedule?.map((dateTimeSlot, index) => (
                                    <DateTimeItems key={index} dateTimeSlot={dateTimeSlot} />
                                ))}
                            </div>
                        }
                        {(userPreBookingDetail?.data?.result?.bookingSchedule?.isRecurring === availabilityIsRecurringEnum.RECURRING.value) &&
                            <div className={cn(
                                "grid grid-flow-col gap-2 w-[18rem] md:w-[26rem] px-1 pb-3 overflow-hidden overflow-x-scroll",
                                "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                            )}>
                                {Object.keys(userPreBookingDetail?.data?.result?.bookingSchedule?.schedule)?.map((localDay, index) => (
                                    <DayItems key={index} localDay={localDay} timeSlots={userPreBookingDetail?.data?.result?.bookingSchedule?.schedule[localDay]} />
                                ))}
                            </div>
                        }
                        {!userPreBookingDetail?.data?.result?.bookingSchedule?.schedule &&
                            <div className={"w-fit mx-auto mt-3 px-5 py-2 rounded-md bg-background-darkLight"}>
                                <span className={"font-bodyPri font-normal text-base text-text-700 text-center"}>
                                    {"Please choose session time slot(s) at checkout."}
                                </span>
                            </div>
                        }
                    </div>
                </div>

                <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>

                <div className={"w-full space-y-2"}>
                    <div className={"flex flex-row items-center justify-between"}>
                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                            {(userPreBookingDetail?.data?.result?.course?.type === courseType.ONE_ON_ONE.value) && "One on One"}
                            {(userPreBookingDetail?.data?.result?.course?.type === courseType.GROUP.value) && "Group"}
                        </span>
                        {(userPreBookingDetail?.data?.result?.course?.type === courseType.ONE_ON_ONE.value) &&
                            <IoMdPeople className={"text-xl text-text-800"} />
                        }
                        {(userPreBookingDetail?.data?.result?.course?.type === courseType.GROUP.value) &&
                            <MdGroups className={"text-xl text-text-800"} />
                        }
                    </div>
                    <div className={"flex flex-row items-center justify-between"}>
                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                            {((userPreBookingDetail?.data?.result?.course?.type === courseType.ONE_ON_ONE.value)) && "Single event"}
                            {(userPreBookingDetail?.data?.result?.course?.type === courseType.GROUP.value) && "Recurring event"}
                        </span>
                        <AiOutlineCalendar className={"text-xl text-text-800"} />
                    </div>
                </div>
            </div>

            {!(isMyOffering || isMyCourse) &&
                <div className={"flex justify-end items-end pt-12"}>
                    <span onClick={onHandleCheckout} className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base text-secondary-main",
                        "border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                    )}>
                        {"Checkout"}
                    </span>
                </div>
            }
        </div>
    )
}

export default BookingCheckoutCard;