import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { cn } from "utils/cn.utils";

import { Draggable } from 'react-drag-reorder';

import { FaTimes } from 'react-icons/fa';
import { AiOutlineCheckCircle } from 'react-icons/ai';

import ComponentLoader from 'components/loader/ComponentLoader';

import LmsCourseTooltip from 'pages/auth/edulyteLms/commonComponents/LmsCourseTooltip';

import { lmsQuizTooltips } from '../editLMSQuiz.data';

import { createLmsQuizTagDetail, deleteLmsQuizTagDetail, updateLmsQuizTagListOrder } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

const QuizTags = () => {
    const { lmsQuizDetail, addLmsQuizTagDetail, destroyLmsQuizTagDetail } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()

    const [inputTag, setInputTag] = useState("")

    const onHandleRemoveTag = useCallback((tagId) => {
        if (lmsQuizDetail?.isLoading) return;

        dispatcher(deleteLmsQuizTagDetail(tagId))
    }, [lmsQuizDetail?.data])


    const onHandleAddNewTag = useCallback(() => {
        if (!inputTag || inputTag?.split(" ")?.length > 3) {
            toast.error("Skills can have upto 3 words")
            return;
        }
        const skillTag = inputTag.trim()
        const filteredTags = lmsQuizDetail?.data?.tags?.filter((skillItem) => (skillItem?.master_tag?.tag === skillTag))

        if (filteredTags?.length > 0) {
            toast.warn("Duplicate tags are not allowed!")
            return;
        }
        const body = {
            quiz_id: lmsQuizDetail?.data?.id,
            tag: skillTag
        }
        dispatcher(createLmsQuizTagDetail(body))
        setInputTag("");
        const element = document.getElementById("skill");
        element.focus();
    }, [lmsQuizDetail?.data, inputTag])

    const onHandleKeyboardSave = useCallback((event) => {
        if ((event.keyCode === 9 || event.keyCode === 13) && inputTag.trim()) {
            onHandleAddNewTag()
        }
    }, [lmsQuizDetail?.data, inputTag])


    const onHandleUpdateTagOrder = useCallback((currentPos, newPos) => {
        var updatedList = [...lmsQuizDetail?.data?.tags];
        const [reorderedItem] = updatedList.splice(currentPos, 1);
        updatedList.splice(newPos, 0, reorderedItem);

        const body = {
            order: updatedList?.map((tag) => ({
                id: tag?.id
            }))
        }
        dispatcher(updateLmsQuizTagListOrder(body, { quiz_id: lmsQuizDetail?.data?.id }))
    }, [lmsQuizDetail?.data])

    return (
        <div className={'w-full flex flex-col gap-5 font-bodyPri'}>
            <div className={"flex items-center justify-start gap-1"}>
                <LmsCourseTooltip tooltip={lmsQuizTooltips?.tagsTooltip} />
                <span className={'text-lg text-black font-medium'}>
                    {"Tags"}
                </span>
            </div>
            <div className={cn(
                "w-full px-2 py-3 flex flex-col items-start justify-start border border-dashed border-text-500 rounded-lg",
                "font-bodyPri font-normal text-text-900"
            )}>
                {(addLmsQuizTagDetail?.isLoading || destroyLmsQuizTagDetail?.isLoading) &&
                    <ComponentLoader isLoading={addLmsQuizTagDetail?.isLoading || destroyLmsQuizTagDetail?.isLoading} />
                }
                {(!addLmsQuizTagDetail?.isLoading && !destroyLmsQuizTagDetail?.isLoading) &&
                    <div className={"flex flex-wrap gap-1"}>
                        <Draggable onPosChange={onHandleUpdateTagOrder}>
                            {(lmsQuizDetail?.data?.tags?.length > 0)
                                && lmsQuizDetail?.data?.tags?.map((tag, index) => (
                                    <div key={index} className="w-fit flex items-center px-3 py-1 bg-primary-light rounded-lg font-bodyPri font-normal text-text-900 text-sm">
                                        <span className="sm:whitespace-nowrap">
                                            {tag?.master_tag?.tag}
                                        </span>
                                        <button className={"pl-3 cursor-pointer"}>
                                            <FaTimes className={"font-light text-secondary-dark"}
                                                onClick={() => onHandleRemoveTag(tag?.id)}
                                            />
                                        </button>
                                    </div>
                                ))}
                        </Draggable>
                        <div className="flex items-center gap-1.5 addTag">
                            <input
                                className={cn(
                                    "w-max px-3 py-1 border-2 border-text-100 rounded-lg focus:outline-none focus:border-2 focus:border-text-300",
                                    "font-bodyPri font-normal text-text-700 text-sm placeholder:text-text-400 placeholder:text-sm"
                                )}
                                type={"text"}
                                placeholder={"Tag name"}
                                value={inputTag}
                                onChange={(event) => setInputTag(event.target.value)}
                                onKeyDown={onHandleKeyboardSave}
                                autoFocus={true}
                            />
                            <AiOutlineCheckCircle className={"text-2xl text-text-800 cursor-pointer"} onClick={onHandleAddNewTag} />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default QuizTags;