import { cn } from "utils/cn.utils";
import ComponentLoader from "components/loader/ComponentLoader";

import { BsCheckLg } from "react-icons/bs";

const StudentLevel = ({
    options,
    isLoading,
    selectedLevels,
    onHandleSelectedLevels,
    onClearFilter
}) => {

    const handleOptionSelection = (option) => {
        const selected = options?.find((o) => o.value === option?.value)

        if (!selected?.value) {
            alert("Please select any!")
            return;
        }

        const filteredList = selectedLevels?.filter((option) => option?.value === selected?.value)
        if (filteredList?.length > 0) {
            const filteredOptions = selectedLevels?.filter((option) => option?.value !== selected?.value)
            onHandleSelectedLevels(filteredOptions);
            return;
        }
        if (filteredList?.length === 0) {
            onHandleSelectedLevels([...selectedLevels, selected]);
        }
    }

    return (
        <div className={"space-y-2"}>
            <div className={"w-full flex items-center justify-between gap-3"}>
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-medium text-text-900 text-base"}>
                        {"Student Level"}
                    </span>
                    {selectedLevels?.length > 0 &&
                        <span className={"w-5 h-5 flex items-center justify-center bg-primary-dark rounded-full text-text-50 text-sm"}>
                            {selectedLevels?.length}
                        </span>
                    }
                </div>
                <div
                    className={"font-bodyPri font-medium text-primary-dark text-sm underline cursor-pointer"}
                    onClick={() => onClearFilter()}>
                    {"Clear"}
                </div>
            </div>
            <div className={"space-y-3 py-1"}>
                {isLoading &&
                    <ComponentLoader isLoading={isLoading} />
                }
                {options?.map((item, index) => (
                    <div
                        key={index}
                        className={"w-full px-3 py-1 flex items-center justify-start gap-5 group cursor-pointer"}
                        onClick={() => handleOptionSelection(item)}
                    >
                        <span className={cn(
                            'relative w-5 h-5 border border-text-800 rounded',
                        )}>
                            {(selectedLevels?.map((option) => option?.value)?.includes(item?.value)) &&
                                <span className={"absolute top-0.5 right-0"}>
                                    <BsCheckLg className={"text-base text-primary-dark"} />
                                </span>
                            }
                        </span>
                        <span className={'w-11/12 font-bodyPri font-normal text-text-900 text-sm tracking-wide truncate'}>
                            {item.label}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default StudentLevel