import referralServices from "redux/referral/referral.service"
import {
    setUserReferralListLoading,
    setUserReferralList,
    setUserReferralListErrorMsg,
} from "redux/referral/referral.slice"


export const getUserReferralList = () => async (dispatch, getState) => {
    dispatch(setUserReferralListLoading(true))

    const { user } = getState().user

    try {
        const requestData = {
            params: { userId: user.user.userId }
        }
        const response = await referralServices.getUserReferralList(requestData)

        if (response.status === 200) {
            dispatch(setUserReferralList(response.data))
        }
        else {
            dispatch(setUserReferralListErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setUserReferralListErrorMsg(error.message))
    } finally {
        dispatch(setUserReferralListLoading(false))
    }
}