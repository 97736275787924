import { DEFAULT_LMS_QUESTION_PAGE, DEFAULT_LMS_QUESTION_RECORDS } from "redux/edulyteLms/lmsQuestion/lmsQuestion.const"

import { dayjs, timeZone } from "utils/dateTime.utils"

export const pageHeading = {
    heading: "My Questions",
    subHeading: "Find the list of questions",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/my-courses.svg"
}

export const lmsQuestionTableHeaderConst = [
    "Title",
    "Type",
    "Created Date",
    "Actions",
]

export const searchParamsInfo = {
    page: {
        key: "page"
    },
    records: {
        key: "records"
    },
    title: {
        key: "title"
    },
    type: {
        key: "type"
    },
    date: {
        key: "date"
    },
    minCreatedAt: {
        key: "minCreatedAt"
    },
    maxCreatedAt: {
        key: "maxCreatedAt"
    }
}

export const lmsQuestionFilters = {
    [searchParamsInfo.title.key]: {
        key: "title",
        label: "Title"
    },
    [searchParamsInfo.type.key]: {
        key: "type",
        label: "Type"
    },
    [searchParamsInfo.date.key]: {
        key: "date",
        label: "Date"
    }
}

export const getLmsQuestionListPayload = async (searchQueryParams) => {

    let requestDataPayload = {
        page: searchQueryParams.get(searchParamsInfo.page.key) || DEFAULT_LMS_QUESTION_PAGE,
        records: searchQueryParams.get(searchParamsInfo.records.key) || DEFAULT_LMS_QUESTION_RECORDS,
        timeZone: timeZone
    }
    if (!!searchQueryParams.get(searchParamsInfo.title.key)) {
        requestDataPayload[searchParamsInfo.title.key] = searchQueryParams.get(searchParamsInfo.title.key).replaceAll("-", " ")
    }
    if (!!searchQueryParams?.getAll(searchParamsInfo.type.key)?.length) {
        requestDataPayload[searchParamsInfo.type.key] = searchQueryParams?.getAll(searchParamsInfo.type.key)?.map((item) => item?.replaceAll("-", "_"))?.join(",")
    }
    if (!!searchQueryParams.get(searchParamsInfo.minCreatedAt.key)) {
        requestDataPayload[searchParamsInfo.minCreatedAt.key] = dayjs(searchQueryParams.get(searchParamsInfo.minCreatedAt.key)).tz(timeZone).format("YYYY-MM-DD")
    }
    if (!!searchQueryParams.get(searchParamsInfo.maxCreatedAt.key)) {
        requestDataPayload[searchParamsInfo.maxCreatedAt.key] = dayjs(searchQueryParams.get(searchParamsInfo.maxCreatedAt.key)).tz(timeZone).format("YYYY-MM-DD")
    }

    return requestDataPayload;
}