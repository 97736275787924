import { useEffect, useCallback } from 'react';

import ComponentLoader from 'components/loader/ComponentLoader';
import StatusButton from 'components/common-components/StatusButton';
import Pagination from 'components/pagination/Pagination';

import { libraryTabConst, quizTableHeaderConst } from 'components/modals/lmsModals/attachLibraryModal/data';
import LMSTable from "pages/auth/edulyteLms/commonComponents/tableComponent/LMSTable";

import LibraryActionButtons from '../components/LibraryActionButtons';

import { getLmsQuizList } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setLocalTabs, setModal } from 'redux/local/local.slice';
import { resetLmsQuizList } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsQuizStatusEnum } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.const';

import { pagesInfo } from 'utils/pagesInfo';
import { timeZone, dayjs } from 'utils/dateTime.utils';
import { cn } from "utils/cn.utils";

import { EDULYTE_APP_DOMAIN_URL, EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';

const QuizTab = () => {
  const { modal, localTabs } = useAppState((state) => state.local)
  const { lmsQuizList } = useAppState((state) => state.lms.lmsQuiz)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(getLmsQuizList({ page: 1, records: 5 }))

    return () => {
      dispatcher(resetLmsQuizList())
    }
  }, [])

  const onHandleCreateQuiz = () => {
    dispatcher(setModal({
      ...modal,
      [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: false,
      [modalConst.CREATE_LMS_QUIZ_MODAL.stateKey]: true
    }))
  }

  const onHandleEditQuiz = useCallback((quizId) => {
    window.open(`${EDULYTE_APP_DOMAIN_URL}${pagesInfo.EDIT_LMS_QUIZ.pagePath}/${quizId}/edit`, "_self")
  }, [])

  const onHandleInsertQuiz = useCallback((quiz) => {
    if (![lmsQuizStatusEnum?.PUBLISHED?.value]?.includes(quiz?.quiz_setting?.status) || !quiz?.title) return;

    dispatcher(setLocalTabs({
      ...localTabs,
      tabItem: {
        ...localTabs?.tabItem,
        tabType: libraryTabConst.QUIZZES.value,
        payload: {
          ...localTabs?.tabItem?.payload,
          quizId: quiz?.id
        }
      }
    }))
  }, [localTabs])

  const onHandleChangePage = useCallback((page) => {
    if (lmsQuizList?.isLoading) return;

    dispatcher(getLmsQuizList({ page: page, records: 5 }))
  }, [])


  const onHandleNavigateToQuiz = (quizItem) => {
    if (quizItem?.quiz_setting?.status === lmsQuizStatusEnum?.PUBLISHED?.value) {
      window.open(`${EDULYTE_WEB_DOMAIN_URL}/quiz/${quizItem?.active_slug}`, "_blank")
    }
  }

  const QuizTitleContainer = ({ quiz }) => {
    return (
      <span
        className={cn(
          'font-bodyPri font-normal text-text-900 text-base truncate tracking-wide',
          (quiz?.quiz_setting?.status === lmsQuizStatusEnum?.PUBLISHED?.value) && "cursor-pointer hover:text-primary-dark hover:underline"
        )}
        title={quiz?.title && quiz?.title}
        onClick={() => onHandleNavigateToQuiz(quiz)}
      >
        {quiz?.title
          ? (quiz?.title?.length > 20)
            ? quiz?.title?.slice(0, 20) + "..."
            : quiz?.title
          : "-"
        }
      </span>
    )
  }

  const tableRowsConst = lmsQuizList?.data?.results?.map((quiz) => ([
    <QuizTitleContainer quiz={quiz} />,
    <StatusButton
      status={quiz?.quiz_setting?.status}
      className={`bg-${lmsQuizStatusEnum[quiz?.quiz_setting?.status?.toUpperCase()]?.lightColor} text-${lmsQuizStatusEnum[quiz?.quiz_setting?.status?.toUpperCase()]?.darkColor}`}
    />,
    dayjs(quiz?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",
    <LibraryActionButtons
      editBtnTooltip={"Edit Quiz"}
      insertBtnTooltip={"Insert Quiz"}
      isEnterBtnDisabled={![lmsQuizStatusEnum?.PUBLISHED?.value]?.includes(quiz?.quiz_setting?.status) || !quiz?.title}
      onHandleEditBtn={() => onHandleEditQuiz(quiz?.id)}
      onHandleInsertBtn={() => onHandleInsertQuiz(quiz)}
    />,
  ]))

  return (
    <div className={"flex flex-col w-full px-5 py-3 bg-white gap-2"}>
      <div className='flex items-center justify-between gap-3'>
        <span className={"font-bodyPri font-medium text-text-800 text-md tracking-wide"}>
          {"List of Quizzes"}
        </span>
        <div
          className={cn(
            'w-fit px-5 py-1 flex items-center justify-center border border-background-black rounded ease-in-out duration-200 group cursor-pointer',
            'hover:bg-secondary-dark hover:text-white hover:border-secondary-dark'
          )}
          onClick={onHandleCreateQuiz}
        >
          <span className={"font-bodyPri font-normal text-text-800 text-base group-hover:text-white"}>
            {"Create new quiz"}
          </span>
        </div>
      </div>
      {lmsQuizList?.isLoading &&
        <ComponentLoader isLoading={lmsQuizList?.isLoading} className={"w-full"} />
      }
      {lmsQuizList?.message &&
        <div className={"flex items-center justify-center"}>
          <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
            {lmsQuizList?.message}
          </span>
        </div>
      }
      {!lmsQuizList?.isLoading &&
        <LMSTable
          headers={quizTableHeaderConst}
          rows={tableRowsConst}
          alternateRowColor="bg-sky-100"
          rowHeaderColor="bg-sky-200"
        />
      }
      {(!lmsQuizList?.isLoading && (lmsQuizList?.data?.records > 0) && (lmsQuizList?.data?.totalPages > 1)) &&
        <div className={'w-full flex items-center justify-center'}>
          <Pagination
            page={lmsQuizList?.data?.page}
            isScrollToTop={false}
            totalPages={lmsQuizList?.data?.totalPages}
            onChangePage={(page) => onHandleChangePage(page)}
          />
        </div>
      }
    </div>
  )
}

export default QuizTab
