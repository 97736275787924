import { useState } from 'react';

import Skills from 'pages/auth/profile/skillsInformation/Skills';
import SubjectAndCategory from 'pages/auth/profile/skillsInformation/SubjectAndCategory';

const SkillsInformation = () => {

    const [editBtnConst, setEditBtnConst] = useState(null)

    return (
        <div className={'space-y-3 p-5 border border-background-light rounded shadow'}>
            <Skills
                editBtnConst={editBtnConst}
                setEditBtnConst={setEditBtnConst}
            />
            <SubjectAndCategory
                editBtnConst={editBtnConst}
                setEditBtnConst={setEditBtnConst}
            />
        </div>
    )
}

export default SkillsInformation;