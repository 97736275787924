export const questionData = [
    {
        questionId: 1,
        type: 'single_choice',
        title: '',
        options: [
            { id: 1, title: '', is_correct: false },
            { id: 2, title: '', is_correct: false },
            { id: 3, title: '', is_correct: false },
            { id: 4, title: '', is_correct: false }
        ],
        feedback: ''
    }
];