import { toast } from "react-toastify";

import ProductService from "redux/product/product.service";

import {
    setProductDetailLoading,
    setProductDetailData,
    setProductDetailMessage,

    setAddProductDetailLoading,
    setAddProductDetailData,
    setAddProductDetailMessage,

    setModifyProductDetailLoading,
    setModifyProductDetailData,
    setModifyProductDetailMessage,

    setAddProductPriceDetailLoading,
    setAddProductPriceDetailData,
    setAddProductPriceDetailMessage,

    setModifyProductPriceDetailLoading,
    setModifyProductPriceDetailData,
    setModifyProductPriceDetailMessage,

    setAddProductSlugDetailLoading,
    setAddProductSlugDetailMessage,

    setModifyProductSlugDetailLoading,
    setModifyProductSlugDetailMessage
} from "redux/product/product.slice";

// get product detail
export const getProductDetail = (productId) => async (dispatch) => {
    dispatch(setProductDetailLoading(true))

    try {
        const requestData = {
            params: { productId: productId }
        }
        const response = await ProductService.getProductDetail(requestData)
        if (response.status === 200) {
            dispatch(setProductDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setProductDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setProductDetailLoading(false))
    }
}

// create product detail
export const createProductDetail = (body) => async (dispatch) => {
    dispatch(setAddProductDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await ProductService.createProductDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddProductDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddProductDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddProductDetailLoading(false))
    }
}

// update product detail
export const updateProductDetail = (productId, body) => async (dispatch) => {
    dispatch(setModifyProductDetailLoading(true))

    try {
        const requestData = {
            params: { productId: productId },
            body: body
        }
        const response = await ProductService.updateProductDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyProductDetailData(response.data.data))
            dispatch(setProductDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyProductDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyProductDetailLoading(false))
    }
}

// create product price detail
export const createProductPriceDetail = (body) => async (dispatch) => {
    dispatch(setAddProductPriceDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await ProductService.createProductPriceDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddProductPriceDetailData(response.data.data))
            dispatch(getProductDetail(body?.product_id))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddProductPriceDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddProductPriceDetailLoading(false))
    }
}

// update product price detail
export const updateProductPriceDetail = (productPriceId, body) => async (dispatch) => {
    dispatch(setModifyProductPriceDetailLoading(true))

    try {
        const requestData = {
            params: { productPriceId: productPriceId },
            body: body
        }
        const response = await ProductService.updateProductPriceDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyProductPriceDetailData(response.data.data))
            dispatch(getProductDetail(body?.product_id))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyProductPriceDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyProductPriceDetailLoading(false))
    }
}

// create product slug detail
export const createProductSlugDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddProductSlugDetailLoading(true))

    try {
        const { productDetail } = getState().product
        const requestData = {
            body: body
        }
        const response = await ProductService.createProductSlugDetail(requestData)
        if (response.status === 201) {
            // dispatch(getProductDetail(body?.product_id))
            dispatch(setProductDetailData({
                ...productDetail?.data,
                product_slug: [...productDetail?.data?.product_slug, response.data.data]
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddProductSlugDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddProductSlugDetailLoading(false))
    }
}

// update product slug detail
export const updateProductSlugDetail = (productSlugId, body) => async (dispatch, getState) => {
    dispatch(setModifyProductSlugDetailLoading(true))

    try {
        const { productDetail } = getState().product
        const requestData = {
            params: { productSlugId: productSlugId },
            body: body
        }
        const response = await ProductService.updateProductSlugDetail(requestData)
        if (response.status === 200) {
            // dispatch(getProductDetail(productDetail?.data?.id))
            dispatch(setProductDetailData({
                ...productDetail?.data,
                product_slug: productDetail?.data?.product_slug?.map((productSlug) => (
                    (productSlug?.id === response.data.data.id) ? response.data.data : productSlug
                ))
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyProductSlugDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyProductSlugDetailLoading(false))
    }
}