import imagePreviewImage from "pages/auth/editDownload/assets/imagePreviewImage.png";
import videoPreviewImage from "pages/auth/editDownload/assets/videoPreviewImage.png";
import slidePreviewImage from "pages/auth/editDownload/assets/slidePreviewImage.png";
import documentPreviewImage from "pages/auth/editDownload/assets/documentPreviewImage.png";
import sheetPreviewImage from "pages/auth/editDownload/assets/sheetPreviewImage.png";
import pdfPreviewImage from "pages/auth/editDownload/assets/pdfPreviewImage.jpg";
import otherPreviewImage from "pages/auth/editDownload/assets/otherPreviewImage.png";

export const url = {
    GET_USER_DOWNLOADS_LIST: "downloadable/downloadables/users/{userId}",
    GET_USER_DOWNLOAD_DETAIL: "downloadable/downloadables/{downloadId}/users/{userId}",

    GET_OWNER_USER_DOWNLOADS_LIST: "downloadable/downloadables/owner-users/{userId}",
    GET_OWNER_USER_DOWNLOAD_DETAIL: "downloadable/downloadables/{downloadId}/owner-users/{userId}",
    CREATE_USER_DOWNLOAD_DETAIL: "downloadable/downloadables",
    UPDATE_USER_DOWNLOAD_DETAIL: "downloadable/downloadables/{downloadId}",
    UPDATE_USER_DOWNLOAD_DETAIL_ORDER: "downloadable/downloadables/{downloadId}/order",
    DELETE_USER_DOWNLOAD_DETAIL: "downloadable/downloadables/{downloadId}",

    // download preview detail
    CREATE_DOWNLOAD_PREVIEW_DETAIL: "downloadable/previews",
    UPDATE_ORDER_DOWNLOAD_PREVIEW_DETAIL: "downloadable/previews/{downloadId}/order",
    DELETE_DOWNLOAD_PREVIEW_DETAIL: "downloadable/previews/{downloadPreviewId}",

    // download file detail
    CREATE_DOWNLOAD_FILE_DETAIL: "downloadable/files",
    UPDATE_ORDER_DOWNLOAD_FILE_DETAIL: "downloadable/files/{downloadFileId}/order",
    DELETE_DOWNLOAD_FILE_DETAIL: "downloadable/files/{downloadFileId}",

    // download user list
    GET_DOWNLOAD_USER_LIST: "downloadable/downloadable-users",
    UPDATE_DOWNLOAD_USER_DETAIL: "downloadable/downloadable-users/{downloadUserId}",
    DELETE_DOWNLOAD_USER_DETAIL: "downloadable/downloadable-users/{downloadUserId}",

    // download invitation list
    GET_DOWNLOAD_INVITATION_LIST: "downloadable/invitations",
    CREATE_SEND_DOWNLOAD_INVITATION_DETAIL: "downloadable/invitations/send",
    GET_VALIDATE_DOWNLOAD_INVITATION_DETAIL: 'downloadable/invitations/validate',
    GET_ACCEPT_AND_DECLINE_DOWNLOAD_INVITATION_DETAIL: 'downloadable/invitations/accept',
    DELETE_DOWNLOAD_INVITATION_DETAIL: "downloadable/invitations/{downloadInvitationId}"
}

export const downloadStatusEnum = {
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    }
}

export const downloadPreviewTypeEnum = {
    IMAGE: {
        label: "Image",
        value: "image"
    },
    VIDEO: {
        label: "Video",
        value: "video"
    }
}

export const downloadFileTypeEnum = {
    VIDEO: {
        label: "Video",
        value: "video",
        previewImage: videoPreviewImage
    },
    IMAGE: {
        label: "Image",
        value: "image",
        previewImage: imagePreviewImage
    },
    SLIDE: {
        label: "Slide",
        value: "slide",
        previewImage: slidePreviewImage
    },
    PDF: {
        label: "PDF",
        value: "pdf",
        previewImage: pdfPreviewImage
    },
    DOC: {
        label: "Document",
        value: "doc",
        previewImage: documentPreviewImage
    },
    SHEET: {
        label: "XLSX",
        value: "sheet",
        previewImage: sheetPreviewImage
    },
    OTHER: {
        label: "Other",
        value: "other",
        previewImage: otherPreviewImage
    }
}

export const downloadShareableEnum = {
    YES: {
        label: "Yes",
        value: "yes"
    },
    NO: {
        label: "No",
        value: "no"
    }
}

export const downloadVisibilityEnum = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    }
}

export const downloadInstructorPermissionEnum = {
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    }
}

export const downloadUserStatusEnum = {
    PENDING: {
        label: "Pending",
        value: "pending"
    },
    JOINED: {
        label: "Joined",
        value: "joined"
    },
    CANCELLED: {
        label: "Cancelled",
        value: "cancelled"
    }
}

export const downloadUserIsActiveEnum = {
    ACTIVE: {
        label: "Active",
        value: "active"
    },
    INACTIVE: {
        label: "In Active",
        value: "inactive"
    },
    BLOCK: {
        label: "Block",
        value: "block"
    }
}

export const downloadInviteToEnum = {
    TUTOR: {
        label: "Tutor",
        value: "tutor"
    },
    STUDENT: {
        label: "Student",
        value: "student"
    }
}

export const downloadJoiningFlowEnum = {
    CHECKOUT: {
        label: "Checkout",
        value: "checkout"
    },
    INVITE: {
        label: "Invite",
        value: "invite"
    },
    MANUAL: {
        label: "Manual",
        value: "manual"
    },
}

export const downloadInviteStatusEnum = {
    INVITED: {
        label: "Invited",
        value: "invited"
    },
    RE_INVITED: {
        label: "Re Invited",
        value: "re_invited"
    },
    ACCEPTED: {
        label: "Accepted",
        value: "accepted"
    },
    DECLINED: {
        label: "Declined",
        value: "declined"
    }
}

export const addUserDownloadDetailPayload = {
    downloadType: null,
    selectedFile: null,
    type: null
}

export const sendDownloadInvitationDetailPayload = {
    downloadId: null,
    emails: [],
    invitedUsers: [],
    inviteTo: null,
    inviteEmailSent: true,
    senderUserId: null
}

export const DEFAULT_DOWNLOADS_PAGE = 1;
export const DEFAULT_DOWNLOADS_RECORDS = 15;