import {
    FaBookReader,
    FaCalendar,
    FaCertificate,
    FaGifts,
    FaGoogle,
    FaHockeyPuck,
    FaLevelUpAlt,
    FaChalkboardTeacher,
} from 'react-icons/fa';
import { BiChalkboard } from 'react-icons/bi';

import { MdOutlineAddBusiness, MdOutlineCastForEducation, MdReduceCapacity } from 'react-icons/md';

import { userRoles } from 'redux/auth/auth.const';
import { courseType } from 'redux/course/course.const';

export const pageHeading = {
    heading: "My Tools",
    subHeading: "Quickly navigate to your frequently used tools!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/tools.svg"
}

export const popularTools = [
    {
        icon: <BiChalkboard className="text-primary-main" size={40} />,
        label: 'Online Whiteboard',
        to: "/resources/tools",
        toolTip: "Collaborate in real-time using the free and easy-to-share online whiteboard, Miro. A third-party tool, not managed by us.",
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    {
        icon: <FaHockeyPuck className="text-primary-main" size={40} />,
        label: 'Create a live class',
        to: "/create-class",
        toolTip: "Create a live class and share it with your students",
        roles: [userRoles.TUTOR.value],
    },
    {
        icon: <FaCertificate className="text-primary-main" size={40} />,
        label: 'Tutor Onboarding',
        to: "/tutor-onboarding",
        toolTip: "Please complete all the steps to start your journey with us.",
        roles: [userRoles.TUTOR.value],
    },
    {
        icon: <FaCalendar className="text-primary-main" size={40} />,
        label: 'Weekly Schedule',
        to: "/settings/preferences/availability",
        toolTip: "Add or update your general weekly schedule for seven days.",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
    {
        icon: <FaCalendar className="text-primary-main" size={40} />,
        label: 'Refer & Earn',
        to: "/marketing/referral",
        toolTip: "Refer a friend and get rewarded!",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
    // {
    //     icon: <FaGifts className="text-primary-main" size={40} />,
    //     label: 'Send a gift',
    //     to: "/buy-gift-card",
    //     toolTip: "Check and update your weekly schedule",
    //     roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    // },
    {
        icon: <FaLevelUpAlt className="text-primary-main" size={40} />,
        label: 'Upgrade Plan',
        to: "/resources/tools",
        toolTip: "Upgrade your subscription (under development).",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
    {
        icon: <FaGoogle className="text-primary-main" size={40} />,
        label: 'Google Calendar',
        to: "/settings/general/calendar",
        toolTip: "Connect your google calendar to sync events (under development)",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
    {
        icon: <FaBookReader className="text-primary-main" size={40} />,
        label: 'Bookings',
        to: "/finance/bookings",
        toolTip: "Browse all your bookings and take action.",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
    {
        icon: <FaChalkboardTeacher className="text-primary-main" size={40} />,
        label: 'Apply to Teach',
        to: "/teach-with-us",
        toolTip: "Check and update your weekly schedule",
        roles: [userRoles.STUDENT.value],
    },
]

export const companyTools = [
    {
        icon: <MdOutlineAddBusiness className="text-primary-main" size={40} />,
        label: 'Edulyte Page',
        to: "/company/edulyte",
        toolTip: "Check and update your weekly schedule",
        roles: [userRoles.TUTOR.value],
        roleId: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
        icon: <MdOutlineCastForEducation className="text-primary-main" size={40} />,
        label: 'CFI Education Page',
        to: "/company/cfieducation",
        toolTip: "Check and update your weekly schedule",
        roles: [userRoles.TUTOR.value],
        roleId: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
        icon: <MdReduceCapacity className="text-primary-main" size={40} />,
        label: 'pepp Page',
        to: "/company/pepp",
        toolTip: "Check and update your weekly schedule",
        roles: [userRoles.TUTOR.value],
        roleId: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
        icon: <MdOutlineAddBusiness className="text-primary-main" size={40} />,
        label: 'Edulyte Edit Page',
        to: "/edit-company/edulyte",
        toolTip: "Check and update your weekly schedule",
        roles: [userRoles.TUTOR.value],
        roleId: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
]

export const sidebarTools = [
    {
        label: "Add 1 to 1 Session",
        to: "/add-1-to-1-session",
        roles: [userRoles.TUTOR.value],
        path: `/create-class?classType=${courseType?.ONE_ON_ONE?.value}`
    },
    {
        label: "Add Group Session",
        to: "/add-group-session",
        roles: [userRoles.TUTOR.value],
        path: `/create-class?classType=${courseType?.GROUP?.value}`
    },
    {
        label: "Redeem Gift Card",
        to: "/gift-card",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
        path: '/finance/wallet'
    },
    {
        label: "Your Todo List",
        to: "/to-do",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
        path: '/calendar/#todoSection'
    },
    {
        label: "Offers and discount",
        to: "/offers-discounts",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
        path: '/resources/partner-offers'
    },
    // {
    //     label: "Withdraw",
    //     to: "/withdraw",
    //     roles: [userRoles.TUTOR.value],
    //     path: '/wallet'
    // },
    // {
    //     label: "Attend free class",
    //     to: "/free-class",
    //     roles: [userRoles.STUDENT.value],
    //     path: '/'
    // },
]