import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { BellIcon } from "@heroicons/react/outline";
//Helper functions
import updateNotifications from "../../helpers/updateNotifications";

//Component imports
import NotificationListItem from "../NotificationListItem/NotificationListItem";
import GroupNotificationListItem from "../GroupNotificationListItem/GroupNotificationListItem";

//Style imports
import {
  StyledNotificationList,
  StyledNotificationListHeader,
} from "./NotificationList.styles";

import { pagesInfo } from 'utils/pagesInfo';


export default function NotificationList({
  notifications,
  groupNotifications,
  setShowList,
  user,
}) {
  const navigate = useNavigate()
  const combinedNotifications = [...notifications, ...groupNotifications];

  useEffect(() => {
    return () => {
      notificationsUpdate()
    }
  }, [])

  async function notificationsUpdate() {
    if (combinedNotifications && combinedNotifications?.length > 0) {
      await updateNotifications(combinedNotifications, user);
    }
    setShowList(false);
  }

  async function closeNotificationList(notification) {
    await notificationsUpdate();
    navigate(`${pagesInfo.MESSAGES.pagePath}/${notification?.chatId}`)
  }

  return (
    <StyledNotificationList id={"dropdown"}>
      <StyledNotificationListHeader>Notifications</StyledNotificationListHeader>
      {combinedNotifications.map((notification) => {
        return (
          <div onClick={() => closeNotificationList(notification)} key={notification.id}>
            {notification.chatType === "individual" && (
              <NotificationListItem notification={notification} />
            )}
            {notification.chatType === "group" && (
              <GroupNotificationListItem notification={notification} />
            )}
          </div>
        );
      })}

      {(!combinedNotifications || combinedNotifications.length < 1) &&
        <div className={"w-full aspect-square flex flex-col items-center justify-center gap-3 mb-8"}>
          <BellIcon className={cn(
            "w-32 h-32 rounded-full ring-6 ring-secondary-light bg-secondary-light text-secondary-dark",
            "animate-bounce"
          )} />
          <span className={"font-bodyPri font-semibold text-xl text-text-700 text-center whitespace-nowrap"}>
            {"No Notifications..."}
          </span>
        </div>
      }
    </StyledNotificationList>
  );
}
