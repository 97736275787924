import { memo, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { motion } from "framer-motion";

import ComponentLoader from "components/loader/ComponentLoader";

import { searchParamsInfo } from "pages/auth/crm/data";

import ContactIdentity from "components/modals/crmModals/viewContactDetailModal/components/viewContactDetail/components/contactIdentity";
import ContactActions from "components/modals/crmModals/viewContactDetailModal/components/viewContactDetail/components/contactActions";
import ContactBasicDetails from "components/modals/crmModals/viewContactDetailModal/components/viewContactDetail/components/contactBasicDetails";
import ContactNumbers from "components/modals/crmModals/viewContactDetailModal/components/viewContactDetail/components/contactNumbers";
import ContactAddresses from "components/modals/crmModals/viewContactDetailModal/components/viewContactDetail/components/contactAddresses";
import ContactNotes from "components/modals/crmModals/viewContactDetailModal/components/viewContactDetail/components/contactNotes";

import { getCrmContactDetail } from "redux/crm/crm.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetCrmContactDetail } from "redux/crm/crm.slice";

const ViewContactContent = memo(({ closeModal }) => {
    const { user } = useAppState((state) => state.user)
    const { crmContactDetail } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()
    const location = useLocation()

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        return () => {
            dispatcher(resetCrmContactDetail())
        }
    }, [])

    useEffect(() => {
        if (!!searchQueryParams?.get(searchParamsInfo.contact.key)) {
            dispatcher(getCrmContactDetail(searchQueryParams?.get(searchParamsInfo.contact.key), user?.user?.userId))
        }
    }, [searchQueryParams])

    if (crmContactDetail?.isLoading) {
        return (
            <ComponentLoader isLoading={crmContactDetail?.isLoading} className={"min-h-[25rem]"} />
        )
    }

    if (crmContactDetail?.message) {
        return (
            <div className={"flex items-center justify-center min-h-[25rem]"}>
                <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                    {crmContactDetail?.message}
                </span>
            </div>
        )
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className={"flex flex-col gap-5 p-5 font-bodyPri"}>
                <ContactIdentity contact={crmContactDetail?.data} onClose={closeModal} />
                <ContactActions contact={crmContactDetail?.data} />
                <ContactBasicDetails contact={crmContactDetail?.data} />
                <ContactNumbers contact={crmContactDetail?.data} />
                <ContactAddresses contact={crmContactDetail?.data} />
                <ContactNotes contact={crmContactDetail?.data} />
            </div>
        </motion.div>
    )
});

export default ViewContactContent;