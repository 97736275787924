import { createSlice } from "@reduxjs/toolkit";

import { INTERVIEW_INITIAL_STATE } from "redux/interview/interview.initialState";

const interview = createSlice({
    name: "interview",
    initialState: INTERVIEW_INITIAL_STATE,
    reducers: {
        // interview detail reducers
        setInterviewDetailLoading: (state, { payload }) => {
            state.interviewDetail.isLoading = payload
        },
        setInterviewDetail: (state, { payload }) => {
            state.interviewDetail.interviewDetail = payload
            state.interviewDetail.errorMsg = INTERVIEW_INITIAL_STATE.interviewDetail.errorMsg
        },
        setInterviewDetailErrorMsg: (state, { payload }) => {
            state.interviewDetail.errorMsg = payload
            state.interviewDetail.interviewDetail = INTERVIEW_INITIAL_STATE.interviewDetail.interviewDetail
        },
        setClearInterviewDetail: (state) => {
            state.interviewDetail = INTERVIEW_INITIAL_STATE.interviewDetail
        },

        // add interview session detail
        setAddInterviewSessionDetailLoading: (state, { payload }) => {
            state.addInterviewSessionDetail.isLoading = payload
        },
        setAddInterviewSessionDetailData: (state, { payload }) => {
            state.addInterviewSessionDetail.data = payload
            state.addInterviewSessionDetail.errorMsg = INTERVIEW_INITIAL_STATE.addInterviewSessionDetail.errorMsg
        },
        setAddInterviewSessionDetailMessage: (state, { payload }) => {
            state.addInterviewSessionDetail.errorMsg = payload
            state.addInterviewSessionDetail.data = INTERVIEW_INITIAL_STATE.addInterviewSessionDetail.data
        },
        resetAddInterviewSessionDetail: (state) => {
            state.addInterviewSessionDetail = INTERVIEW_INITIAL_STATE.addInterviewSessionDetail
        },

        // add interview event detail payload
        setAddInterviewSessionDetailPayload: (state, { payload }) => {
            state.addInterviewSessionDetail.payload = payload
        },
        resetAddInterviewSessionDetailPayload: (state) => {
            state.addInterviewSessionDetail.payload = INTERVIEW_INITIAL_STATE.addInterviewSessionDetail.payload
        },
    }
})

export const {
    setInterviewDetailLoading,
    setInterviewDetail,
    setInterviewDetailErrorMsg,
    setClearInterviewDetail,

    setAddInterviewSessionDetailLoading,
    setAddInterviewSessionDetailData,
    setAddInterviewSessionDetailMessage,
    resetAddInterviewSessionDetail,

    setAddInterviewSessionDetailPayload,
    resetAddInterviewSessionDetailPayload
} = interview.actions;
export default interview.reducer;