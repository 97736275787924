import tw, { styled } from "twin.macro";


export const StyledDashboardContainer = styled.div`
  ${tw``}
`;

//height[100vh] w-full flex flex-col items-center justify-center gap-3 px-5 pb-5

export const StyledChatViewContainer = styled.div`
${tw`width[100%] block md:flex rounded-lg overflow-hidden`}
`;

export const StyledDashBoardSelectChat = styled.div`
  ${tw`flex flex-col items-center justify-center font-bold text-primary-dark w-full px-4 bg-white height[80vh] hidden md:(flex max-w-full px-0) lg:(flex max-w-full px-0)`}
`;