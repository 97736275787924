import { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { cn } from "utils/cn.utils";

import ComponentLoader from 'components/loader/ComponentLoader';

const SimpleSelectSearch = ({
    options,
    onChange,
    isLoading = false,
    isShowList = false,
    isShowInputBox = false,
    setIsShowList,
    multipleOptions = false,
    className
}) => {

    const [query, setQuery] = useState('')

    const handleSelectOption = (option) => {
        const selected = options?.find((o) => o.value === option.value)
        if (!selected?.value) {
            alert("Please select any!")
            return;
        }

        onChange([selected]);
        setIsShowList(false)

        // if (multipleOptions === false) {
        //     onChange([selected]);
        //     setIsShowList(false)
        //     return;
        // }
        // const filteredList = selectedOptions?.filter((option) => option?.value === selected?.value)
        // if (filteredList?.length > 0) {
        //     const filteredOptions = selectedOptions?.filter((option) => option?.value !== selected?.value)
        //     onChange(filteredOptions);
        //     return;
        // }
        // if (filteredList?.length === 0) {
        //     onChange([...selectedOptions, selected]);
        // }
    }

    const filteredList =
        query === ''
            ? options
            : options.filter((speak) => {
                return speak.value.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
            })

    return (
        <Transition appear show={isShowList} as={Fragment}>
            <Dialog
                as="div"
                className={cn("absolute z-30 overflow-y-auto", className)}
                open={isShowList}
                onClose={setIsShowList}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className={cn("bg-white w-full max-h-80 rounded-lg px-3 py-1.5")}>
                        {isShowInputBox &&
                            <input
                                className={"w-full border border-text-300 rounded-md cursor-text py-1.5 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:outline-none"}
                                onChange={(event) => setQuery(event.target.value)}
                                autoFocus={true}
                                placeholder={"Type"}
                            />
                        }
                        {isLoading &&
                            <ComponentLoader isLoading={isLoading} />
                        }
                        {filteredList &&
                            <div className={'w-full h-56 overflow-y-auto px-3 py-3'}>
                                <div className={""}>
                                    {filteredList.length === 0 && query !== '' ? (
                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                            {"Nothing found."}
                                        </div>
                                    ) : (
                                        <div className={"space-y-3"}>
                                            {isShowList && filteredList?.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className={"flex items-center justify-start gap-5 group cursor-pointer"}
                                                    onClick={() => handleSelectOption(item)}
                                                >
                                                    <span className={'font-bodyPri font-normal text-text-900 text-sm tracking-wide truncate'}>
                                                        {item.label}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        }

                    </div>
                </Transition.Child>
            </Dialog>
        </Transition>
    )
}
export default SimpleSelectSearch;