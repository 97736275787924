export const url = {
    LOGIN: "/auth/login",
    GOOGLE_LOGIN: "/auth/google-login",
    ONE_TAP_GOOGLE_LOGIN: "/auth/google-tab-login",
    SIGN_UP: "/auth/signup",
    SIGNUP_WITH_TOKEN: "/auth/signup-with-token",
    VERIFY_SIGNUP: "/auth/signup/verify",
    SIGNUP_S2: "/auth/signup/s2",
    GET_TOKEN: "/auth/token",
    VERIFY_REFERRAL_BY_REFERRAL_CODE: "/auth/verify-referral/{referralCode}",
    LOGOUT: "/auth/logout",
    RESET_PASSWORD: "/auth/reset-password",
    UPDATE_USER_AUTH: "/auth/update-auth/users/{userId}",
    UPDATE_USER_PASSWORD: "/auth/update-password/users/{userId}",
    VERIFY_AUTH: "/auth/verify-auth",
    VERIFY_USER: "/auth/verify-user",
    GET_PASSWORD_RESET: "/auth/password-reset",
    CREATE_PASSWORD_RESET: "/auth/password-reset",
    SEND_FORGET_PASSWORD_LINK: "/auth/forgot-password",
    LOGOUT_CURRENT_USER_AND_SWITCH_ADMIN: '/auth/logout-and-switch',

    IDENTIFIER: "/auth/identifier",
    CHALLENGE_OTP: "/auth/challenge/otp",
    CHALLENGE_PASSWORD: "/auth/challenge/password",
    SIGNUP_WITH_OTP: "/auth/signup-with-otp",
    VERIFY_SIGNUP_WITH_OTP: "/auth/signup-with-otp/verify",

    CREATE_GOOGLE_CALENDAR_INTEGRATION_AUTH: "/auth/google-calendar-auth",
    CREATE_GOOGLE_CALENDAR_INTEGRATION: "/auth/google-calendar-integrate",
    CREATE_REVOKE_GOOGLE_CALENDAR_ACCESS: "/auth/revoke-google-calendar-access"
}

export const wsUrl = {
    CURRENT_USER: "/auth/current-user"
}

export const identifierPurposeEnum = {
    sign_in: {
        key: "sign_in",
        label: "Sign in"
    },
    change_password: {
        key: "change_password",
        label: "Change Password"
    }
}

export const identifierChallengeEnum = {
    otp: {
        key: "otp",
        label: "OTP"
    },
    password: {
        key: "password",
        label: "Password"
    }
}

export const signupAsEnum = {
    user: {
        key: "user",
        label: "User"
    },
    student: {
        key: "student",
        label: "Student"
    },
    tutor: {
        key: "tutor",
        label: "Tutor"
    }
}

export const logInWith = {
    EMAIL: {
        label: "Email",
        value: "email"
    },
    MOBILE_NO: {
        label: "MobileNo",
        value: "mobileNo"
    }
}

export const authenticateWith = {
    PASSWORD: {
        label: "Password",
        value: "password"
    },
    OTP: {
        label: "Code",
        value: "otp"
    }
}

export const logInPayload = {
    logInWith: logInWith.EMAIL.value,
    email: "",
    mobileNo: "",
    authenticateWith: authenticateWith.PASSWORD.value,
    otp: "",
    otpNotificationId: ""
}

export const signUpPayload = {
    companyId: 1,
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    isEmailVerified: false,
    isMobileNoVerified: false,
    country: null,
    isPromotionActive: true,
    referralCode: "",
    rechaptchaToken: null,
    promoCode: "",
    termsConditions: true
}

export const addResetPasswordPayload = {
    email: "",
    otpId: "",
    otp: "",
    accessToken: "",
    password: "",
    isEmailVerified: false
}

export const addUserAuthPayload = {
    email: "",
    password: "",
    mobileNo: "",
    isdCode: "",
    isOnlyVerifyEmail: false,
    isOnlyVerifyMobileNo: false,
}

export const userRoles = {
    VIEW_ONLY: {
        label: 'View Only',
        value: 'view_only'
    },
    TUTOR: {
        label: 'Tutor',
        value: 'tutor'
    },
    STUDENT: {
        label: 'Student',
        value: 'student'
    },
    SUPER_ADMIN: {
        label: 'Super Admin',
        value: 'super_admin'
    },
    ADMIN: {
        label: 'Admin',
        value: 'admin'
    },
    MANAGER: {
        label: 'Manager',
        value: 'manager',
    },
    SUPPORT: {
        label: 'Support',
        value: 'support',
    },
    COMPANY_SUPER_ADMIN: {
        label: 'Company Super Admin',
        value: 'company_super_admin',
    },
    COMPANY_ADMIN: {
        label: 'Company Admin',
        value: 'company_admin',
    },
    RECRUITER: {
        label: 'Recruiter',
        value: 'recruiter',
    }

}