import { addLmsLectureInstructorDetailPayload } from "./lmsLecture.const";

export const LMS_LECTURE_INITIAL_STATE = {

    /* tutor lms lecture */
    tutorLmsLectureList: {
        isLoading: false,
        data: null,
        message: null
    },
    tutorLmsLectureDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    /* lms lecture */
    addLmsLectureDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    modifyLmsLectureDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    destroyLmsLectureDetail: {
        isLoading: false,
        message: null
    },

    // lms Lecture instructor
    addLmsLectureInstructorDetail: {
        isLoading: false,
        data: null,
        payload: addLmsLectureInstructorDetailPayload,
        message: null
    },
    modifyLmsLectureInstructorDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    destroyLmsLectureInstructorDetail: {
        isLoading: false,
        message: null
    },

    /* lms lecture setting */
    lmsLectureSettingList: {
        isLoading: false,
        data: null,
        message: null
    },
    lmsLectureSettingDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addLmsLectureSettingDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    modifyLmsLectureSettingDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    destroyLmsLectureSettingDetail: {
        isLoading: false,
        message: null
    },

    // page based state
    lmsLecturePageResourceList: {
        isLoading: false,
        data: null,
        message: null
    }
}