import { useEffect } from "react";
import { cn } from "utils/cn.utils";
import { Link } from "react-router-dom";

// Components
import MyTypography from "components/Typography";
import ComponentLoader from "components/loader/ComponentLoader";
// import CourseCard from "components/courseCard/CourseCard";
import CourseCardTwo from "components/courseCardTwo/CourseCardTwo";

import { getPublicTutorCourseList, createCourseFavoriteDetail, deleteCourseFavoriteDetail } from "redux/course/course.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearPublicTutorCourseList } from "redux/course/course.slice";

import { pagesInfo } from "utils/pagesInfo";

const Courses = ({ ...props }) => {
  const { user } = useAppState((s) => s.user)
  const { publicTutorCourseList, addCourseFavDetail, deleteCourseFavDetail } = useAppState((s) => s.course)
  const { tutorPublicProfile } = useAppState((s) => s.tutor)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    if (tutorPublicProfile?.tutorPublicProfile?.tutorId) {
      dispatcher(getPublicTutorCourseList(tutorPublicProfile?.tutorPublicProfile?.tutorId, { page: 1, records: 5 }))
    }

    return () => {
      dispatcher(setClearPublicTutorCourseList())
    }
  }, [tutorPublicProfile?.tutorPublicProfile])

  return (
    <div className="p-2.5 md:p-6 space-y-5" id={"CLASSES"}>
      <div className="flex justify-between items-center">
        <MyTypography className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
          {"My Classes"}
        </MyTypography>
        <Link
          to={`${pagesInfo.TUTOR_COURSE.pagePath}/${tutorPublicProfile?.tutorPublicProfile?.userId}/classes` + "?pageNo=1"}
          className="cursor-pointer text-sm text-primary-main font-bodyPri tracking-wide"
        >
          {"View All"}
        </Link>
      </div>
      {publicTutorCourseList?.isLoading && <ComponentLoader isLoading={publicTutorCourseList?.isLoading} />}
      <div className={cn(
        "grid grid-flow-col gap-5 overflow-hidden overflow-x-scroll",
        "scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight",
        (publicTutorCourseList?.data?.records > 0) && "p-5"
      )}>
        {(publicTutorCourseList?.data?.records > 0)
          && publicTutorCourseList?.data?.results?.slice(0, 5)?.map((course, index) => (
            <div className={"w-64"}>
              <CourseCardTwo
                key={index}
                course={course}
                isFav={user?.user?.favoriteCourses?.filter((favCourse) =>
                  favCourse.courseId === course.id).length > 0 ? true : false}
                handleFav={(courseId) => {
                  if (addCourseFavDetail?.isLOading || deleteCourseFavDetail?.isLoading) return;
                  const favoriteCourses = user?.user?.favoriteCourses?.filter((favCourse) =>
                    favCourse.courseId === courseId)
                  if (favoriteCourses?.length > 0) {
                    dispatcher(deleteCourseFavoriteDetail({ userId: user?.user?.userId, courseId: courseId }))
                  }
                  else {
                    dispatcher(createCourseFavoriteDetail({ userId: user?.user?.userId, courseId: courseId }))
                  }
                }}
              />
            </div>
          ))}
      </div>
      {(publicTutorCourseList?.data?.message || publicTutorCourseList?.data?.records === 0 || !publicTutorCourseList?.data) &&
        <span className={"font-bodyPri font-normal text-text-800 text-lg py-0.5 md:py-1 px-3"}>
          {publicTutorCourseList?.data?.message || "Classes Coming Soon!"}
        </span>
      }
    </div>
  );
};

export default Courses;
