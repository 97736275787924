import { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";

import { cn } from "utils/cn.utils";

import { AiOutlinePlus } from "react-icons/ai";

import ComponentLoader from "components/loader/ComponentLoader";
import { libraryTabConst } from "components/modals/lmsModals/attachLibraryModal/data";
import { uploadLmsMediaConst } from "components/modals/lmsModals/uploadLmsMediaModal/data";

import LectureItemActionButtons from "./LectureItemActionButtons";

import { updateLmsLectureDetail } from "redux/edulyteLms/lmsLecture/lmsLecture.request";
import { createLmsResourceDetail } from "redux/edulyteLms/lmsResource/lmsResource.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetLocalTabs, setLocalTabs, setModal } from "redux/local/local.slice";
import { resetAddLmsResourceDetail, resetFilterProps, setFilterProps } from "redux/edulyteLms/lmsResource/lmsResource.slice";
import { modalConst } from "redux/local/local.const";
import { lmsResourceTypeEnum, resourceFileTypeEnum } from "redux/edulyteLms/lmsResource/lmsResource.const";

import { getYoutubePlayId } from "utils/generators.utils";

const LectureItemContainer = ({ lectureItem }) => {
    const { modal, localTabs } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { modifyLmsLectureDetail } = useAppState((state) => state.lms.lmsLecture)
    const { addLmsResourceDetail, filterProps } = useAppState((state) => state.lms.lmsResource)

    const [isDraggingOver, setIsDraggingOver] = useState(false)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (localTabs?.tabItem && localTabs?.tabItem?.payload?.resourceId) {
            dispatcher(updateLmsLectureDetail(lectureItem?.lecture_id, {
                resource_id: localTabs?.tabItem?.payload?.resourceId
            }))
            dispatcher(resetLocalTabs())
            dispatcher(setModal({
                ...modal,
                [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: false
            }))
        }
    }, [localTabs])

    useEffect(() => {
        if (addLmsResourceDetail?.data) {
            dispatcher(resetLocalTabs())
            dispatcher(resetFilterProps())
            dispatcher(setFilterProps({
                filterProps: {
                    ...filterProps,
                    resourceType: lmsResourceTypeEnum.FILE.value,
                    resourceFileType: resourceFileTypeEnum.VIDEO.value,
                    disabledFilters: [lmsResourceTypeEnum.LINK.value]
                }
            }))
            dispatcher(setLocalTabs({
                ...localTabs,
                modalTitle: "Library",
                tabList: Object.values(libraryTabConst),
                activeTabList: [libraryTabConst.RESOURCES.value],
                currentActiveTab: libraryTabConst.RESOURCES.value,
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.UPLOAD_LMS_MEDIA_MODAL.stateKey]: false,
                [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: true
            }))
            dispatcher(resetAddLmsResourceDetail())
        }
    }, [addLmsResourceDetail?.data])

    useEffect(() => {
        if (localTabs?.tabItem && (localTabs?.tabItem?.tabType === uploadLmsMediaConst.MY_FILES.value) && localTabs?.tabItem?.payload?.fileId) {
            const body = {
                type: lmsResourceTypeEnum.FILE.value,
                title: localTabs?.tabItem?.payload?.fileName,
                file_id: localTabs?.tabItem?.payload?.fileId,
                resource_owner_user_id: user?.user?.userId
            }
            dispatcher(createLmsResourceDetail(body))
            dispatcher(resetLocalTabs())
            dispatcher(setModal({
                ...modal,
                [modalConst.UPLOAD_LMS_MEDIA_MODAL.stateKey]: false
            }))
        }
    }, [localTabs])

    const onHandleOpenResourceModal = useCallback(() => {
        dispatcher(resetLocalTabs())
        dispatcher(setLocalTabs({
            ...localTabs,
            modalTitle: "Library",
            tabList: Object.values(libraryTabConst),
            activeTabList: [libraryTabConst.RESOURCES.value],
            currentActiveTab: libraryTabConst.RESOURCES.value,
            tabItem: {
                ...localTabs?.tabItem,
                payload: {
                    lectureId: lectureItem?.lecture_id
                }
            }
        }))
        dispatcher(setFilterProps({
            filterProps: {
                ...filterProps,
                resourceType: lmsResourceTypeEnum.FILE.value,
                resourceFileType: resourceFileTypeEnum.VIDEO.value,
                disabledFilters: [lmsResourceTypeEnum.LINK.value]
            }
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: true
        }))
    }, [modal, localTabs])

    const onHandleDragOver = (event) => {
        event.preventDefault();
        setIsDraggingOver(true)
    };

    const onHandleDragEnter = (event) => {
        event.preventDefault();
        setIsDraggingOver(true);
    };

    const onHandleDragLeave = (event) => {
        event.preventDefault();
        setIsDraggingOver(false);
    };

    const onHandleDrop = async (event) => {
        event.preventDefault();

        setIsDraggingOver(false)
        let parsedData = {}
        try {
            parsedData = JSON.parse(event.dataTransfer.getData("application/json"))
        } catch (error) {
            console.error(error)
            toast.error("Something went wrong!")
        }

        if (parsedData) {
            dispatcher(updateLmsLectureDetail(lectureItem?.lecture_id, {
                resource_id: parsedData?.id
            }))
        }
    }

    return (
        <div className={"flex flex-col gap-5 p-5 bg-white rounded-lg"}>
            {(!lectureItem || !lectureItem?.resource) &&
                <div
                    className={cn(
                        "w-full px-5 py-3 bg-white rounded-lg flex items-center justify-center gap-3",
                        "border border-dashed border-divider-dark cursor-pointer",
                        isDraggingOver && "bg-divider-darkLight"
                    )}
                    onDragOver={onHandleDragOver}
                    onDragEnter={onHandleDragEnter}
                    onDragLeave={onHandleDragLeave}
                    onDrop={onHandleDrop}
                >
                    {modifyLmsLectureDetail?.isLoading &&
                        <ComponentLoader isLoading={modifyLmsLectureDetail?.isLoading} className={"h-8"} />
                    }
                    {!modifyLmsLectureDetail?.isLoading &&
                        <div className={"flex items-center justify-start gap-2"}>
                            <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                {"Drag & drop from right "}
                            </span>
                            <span className={"font-bodyPri font-bold text-text-800 text-base"}>
                                {" OR "}
                            </span>
                            <div className='flex items-center justify-center'>
                                <button
                                    className={cn(
                                        'flex justify-center items-center bg-white rounded-full px-5 py-1',
                                        'border border-divider-dark hover:border-primary-dark hover:text-primary-dark',
                                        'font-bodyPri font-normal text-text-900 text-base tracking-wide'
                                    )}
                                    onClick={onHandleOpenResourceModal}
                                >
                                    <AiOutlinePlus className={'text-lg'} />
                                    {"Video"}
                                </button>
                            </div>
                        </div>
                    }
                </div>
            }
            {(lectureItem && (lectureItem?.resource?.type === lmsResourceTypeEnum?.YOUTUBE?.value) && lectureItem?.resource?.resource?.resource_link) &&
                <div className={"flex flex-col md:flex-row gap-2"}>
                    <div className={"w-full md:w-[36rem] h-[20rem] shadow-all rounded-md overflow-hidden aspect-video"}>
                        <iframe
                            src={`https://www.youtube.com/embed/${getYoutubePlayId(lectureItem?.resource?.resource?.resource_link)}?autoplay=1`}
                            title={"YouTube video player"}
                            className={"w-full h-full object-cover"}
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        >
                        </iframe>
                    </div>
                    <LectureItemActionButtons
                        lectureId={lectureItem?.lecture_id}
                        lectureResourceId={lectureItem?.resource?.id}
                        resourceLink={lectureItem?.resource?.resource?.resource_link}
                    />
                </div>
            }
            {(lectureItem && (lectureItem?.resource?.resource?.type === resourceFileTypeEnum?.VIDEO?.value) && lectureItem?.resource?.resource?.file?.file_url) &&
                <div className={"flex flex-col md:flex-row gap-2"}>
                    <div className={"w-full md:w-[36rem] h-[20rem] shadow-all rounded-md overflow-hidden aspect-video"}>
                        <video
                            src={lectureItem?.resource?.resource?.file?.file_url}
                            className={'w-full h-full'}
                            width={"100%"}
                            height={"100%"}
                            controls
                        />
                    </div>
                    <LectureItemActionButtons
                        lectureId={lectureItem?.lecture_id}
                        lectureResourceId={lectureItem?.resource?.id}
                        resourceLink={lectureItem?.resource?.resource?.file?.file_url}
                    />
                </div>
            }
        </div>
    );
};

export default LectureItemContainer;