import { cn } from "utils/cn.utils";

import { RiDeleteBin6Line } from "react-icons/ri";

import ToolTipView from "components/tooltipView";

import Drag from "pages/auth/edulyteLms/assets/drag.svg";

import { deleteLmsQuizCategoryDetail } from "redux/edulyteLms/lmsQuiz/lmsQuiz.request";

import { useAppDispatcher } from "hooks/useStore";
import { lmsResourceTypeEnum, resourceFileTypeEnum } from "redux/edulyteLms/lmsResource/lmsResource.const";

const QuizSectionVideo = ({ quizSectionCategoryItem }) => {

    const dispatcher = useAppDispatcher()

    const onHandleDeleteVideo = () => {
        dispatcher(deleteLmsQuizCategoryDetail(quizSectionCategoryItem?.id, quizSectionCategoryItem?.quiz_section))
    }

    if (
        !quizSectionCategoryItem?.resource ||
        !quizSectionCategoryItem?.resource?.resource ||
        (quizSectionCategoryItem?.resource?.type === lmsResourceTypeEnum.YOUTUBE.value && !quizSectionCategoryItem?.resource?.resource?.resource_link) ||
        ((quizSectionCategoryItem?.resource?.resource?.type === lmsResourceTypeEnum.FILE.value && quizSectionCategoryItem?.resource?.resource?.resource?.type === resourceFileTypeEnum.VIDEO.value) && !(
            quizSectionCategoryItem?.resource?.resource?.resource?.file ||
            quizSectionCategoryItem?.resource?.resource?.resource?.file?.file_url
        ))
    ) {
        return (
            <div className={"flex items-center justify-center px-5 py-2"}>
                <span className={"font-bodyPri font-semibold text-2xl text-test-800"}>
                    {"Video lecture not found!"}
                </span>
            </div>
        )
    }

    return quizSectionCategoryItem?.resource?.resource?.resource?.file && (
        <div className={"w-full p-5 bg-white rounded-lg space-y-5 transition-transform ease-in-out duration-300"}>
            <div className={cn("w-full flex items-center justify-between gap-5 cursor-grab")}>
                <div className={"flex items-center justify-start gap-5"}>
                    <img
                        src={Drag}
                        className={"w-2.5 cursor-grab"}
                    />
                    <span className={"font-bodyPri font-medium text-text-900 text-md tracking-wide"}>
                        {"Video"}
                    </span>
                </div>
                <ToolTipView content={"Remove Video"}>
                    <button className={"p-2 rounded-full hover:bg-divider-darkLight"}>
                        <RiDeleteBin6Line
                            className={"text-xl text-text-700 hover:text-red-500 cursor-pointer"}
                            onClick={onHandleDeleteVideo}
                        />
                    </button>
                </ToolTipView>
            </div>
            <div className={"px-0 flex items-center justify-center md:justify-start md:px-5"}>
                <div className={"w-[24rem] h-[16rem] aspect-video shadow-all rounded-md overflow-hidden"}>
                    <video
                        src={quizSectionCategoryItem?.resource?.resource?.resource?.file?.file_url}
                        className={'w-full h-full'}
                        width={"100%"}
                        height={"100%"}
                        controls
                    />
                </div>
            </div>
        </div>
    )
}

export default QuizSectionVideo;