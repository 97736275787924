import { toast } from "react-toastify";
import driveService from 'redux/drive/drive.service';

import {
    setFileDetailLoading,
    setFileDetailData,
    setFileDetailMessage,

    setFileListLoading,
    setFileListData,
    setFileListMessage,

    setFileUploadLoading,
    setFileUploadData,
    setFileUploadMessage,

    setFileViewLoading,
    setFileViewData,
    setFileViewMessage,

    setUserStorageLoading,
    setUserStorageData,
    setUserStorageMessage,

    setUserReceivedFileListLoading,
    setUserReceivedFileListData,
    setUserReceivedFileListMessage,

    setUserMeetingRecordingListLoading,
    setUserMeetingRecordingListData,
    setUserMeetingRecordingListMessage,

    setRecentOpenFileListLoading,
    setRecentOpenFileListData,
    setRecentOpenFileListMessage,

    setShareDriveFileDetailLoading,
    setShareDriveFileDetailData,
    setShareDriveFileDetailMessage,
} from 'redux/drive/drive.slice';

import { generateDriveFormData } from 'redux/drive/drive.utils';

export const getFileList = (query) => async (dispatch) => {
    dispatch(setFileListLoading(true))
    try {
        const requestData = {
            query: query
        }
        const response = await driveService.getFileList(requestData)
        if (response.status === 200) {
            dispatch(setFileListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error)
        dispatch(setFileListMessage(error))
    } finally {
        dispatch(setFileListLoading(false))
    }
}

export const getFileDetail = (body) => async (dispatch) => {
    dispatch(setFileDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await driveService.getFileDetail(requestData)
        if (response.status === 200) {
            dispatch(setFileDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error)
        dispatch(setFileDetailMessage(error))
    } finally {
        dispatch(setFileDetailLoading(false))
    }
}

export const uploadFileDetail = (fileDetail) => async (dispatch) => {
    dispatch(setFileUploadLoading(true));

    try {
        const formData = await generateDriveFormData(fileDetail)
        const requestData = {
            body: formData
        };
        const response = await driveService.uploadFileDetail(requestData);
        if (response.status === 201) {
            dispatch(setFileUploadData(response.data));
            dispatch(getFileList({ page: 1, records: 10 }))
            dispatch(getUserStorageDetail())
            toast.success(response.data.message || "File uploaded successfully!")
        } else {
            throw new Error(response);
        }
    } catch (error) {
        console.error(error.response?.data?.message || error.response?.data?.error);
        dispatch(setFileUploadMessage(error.response?.data?.message || error.response?.data?.error || "Something went wrong!"));
        toast.error(error.response?.data?.message || error.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setFileUploadLoading(false));
    }
};

export const viewFileDetail = (id) => async (dispatch) => {
    dispatch(setFileViewLoading(true));

    try {
        const requestData = {
            params: { id: id }
        }
        const response = await driveService.viewFileDetail(requestData);
        if (response.status === 200) {
            dispatch(setFileViewData(response.data.data));
            return response;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        console.error(error.response?.data?.message || error.response?.data?.error);
        dispatch(setFileViewMessage(error.response?.data?.message || error.response?.data?.error));
    } finally {
        dispatch(setFileViewLoading(false));
    }
};

// userStorage information
export const getUserStorageDetail = () => async (dispatch) => {
    dispatch(setUserStorageLoading(true));

    try {
        const response = await driveService.getUserStorageDetail();
        if (response.status === 200) {
            dispatch(setUserStorageData(response.data));
        } else {
            throw new Error(response);
        }
    } catch (error) {
        console.error(error.response?.data?.message || error.response?.data?.error);
        dispatch(setUserStorageMessage(error.response?.data?.message || error.response?.data?.error));
    } finally {
        dispatch(setUserStorageLoading(false))
    }
}

export const getRecentOpenFileList = () => async (dispatch) => {
    dispatch(setRecentOpenFileListLoading(true));

    try {
        const response = await driveService.getRecentOpenFileList();
        if (response.status === 200) {
            dispatch(setRecentOpenFileListData(response.data));
        } else {
            throw new Error(response);
        }
    } catch (error) {
        console.error(error.response?.data?.message || error.response?.data?.error);
        dispatch(setRecentOpenFileListMessage(error.response?.data?.message || error.response?.data?.error));
    } finally {
        dispatch(setRecentOpenFileListLoading(false))
    }
}

// user meeting recording list
export const getUserMeetingRecordingList = (query) => async (dispatch) => {
    dispatch(setUserMeetingRecordingListLoading(true));

    try {
        const requestData = {
            query: query
        }
        const response = await driveService.getUserMeetingRecordingList(requestData);
        if (response.status === 200) {
            dispatch(setUserMeetingRecordingListData(response.data.data));
        } else {
            throw new Error(response);
        }
    } catch (error) {
        console.error(error.response?.data?.message || error.response?.data?.error);
        dispatch(setUserMeetingRecordingListMessage(error.response?.data?.message || error.response?.data?.error));
    } finally {
        dispatch(setUserMeetingRecordingListLoading(false))
    }
}

// user file received details list
export const getUserReceiveFileList = (query) => async (dispatch) => {
    dispatch(setUserReceivedFileListLoading(true))
    try {
        const requestData = {
            query: query
        }
        const response = await driveService.getUserReceiveFileList(requestData)
        if (response.status === 200) {
            dispatch(setUserReceivedFileListData(response.data.data))
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error)
        dispatch(setUserReceivedFileListMessage(error))
    } finally {
        dispatch(setUserReceivedFileListLoading(false))
    }
}

// share drive file detail
export const createShareDriveFileDetail = (fileId, body) => async (dispatch) => {
    dispatch(setShareDriveFileDetailLoading(true))

    try {
        const requestData = {
            params: { fileId: fileId },
            body: body
        }
        const response = await driveService.createShareDriveFileDetail(requestData)
        if (response.status === 200) {
            dispatch(setShareDriveFileDetailData(response.data))
            dispatch(getFileList({ page: 1, records: 10 }))
            toast.success(response.data.message || "file shared successfully!")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setShareDriveFileDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setShareDriveFileDetailLoading(false))
    }
}