import { useCallback, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { AnimatePresence } from "framer-motion";

import ComponentLoader from "components/loader/ComponentLoader";

import { setUserAvailabilityDetailPayload, validateAvailabilityDetail, validateUserAvailabilityDetail } from "pages/auth/preferences/data";
import AvailabilityHeader from "pages/auth/preferences/commonComponents/AvailabilityHeader";
import RecurringAvailabilityCard from 'pages/auth/preferences/commonComponents/availabilityRecurring/RecurringAvailabilityCard';
import SaveAndResetButtons from "pages/auth/preferences/commonComponents/preferenceButtons/SaveAndResetButtons";

import BasicInformation from "./BasicInformation";
import NonRecurringSchedule from "./NonRecurringSchedule";
import StartEndDateSchedule from "./StartEndDateSchedule";
import MoreAvailabilityDetail from "./MoreAvailabilityDetail";

import { getUserAvailabilityDetail, updateUserAvailabilityDetail } from "redux/availability/availability.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetModifyUserAvailabilityDetail, resetModifyUserAvailabilityDetailPayload, resetUserAvailabilityDetail, setModifyUserAvailabilityDetailPayload } from "redux/availability/availability.slice";
import { availabilityIsRecurringEnum, modifyUserAvailabilityDetailPayload } from "redux/availability/availability.const";

import { weekdays, timeZone } from 'utils/dateTime.utils';

const AvailabilityDetailPage = () => {
    const { user } = useAppState((state) => state.user)
    const { userAvailabilityDetail, modifyUserAvailabilityDetail } = useAppState((s) => s.availability)

    const { availabilityId } = useParams()
    const dispatcher = useAppDispatcher()

    const isSimilarOrValidAvailability = useMemo(() => {
        if (userAvailabilityDetail?.data?.result && modifyUserAvailabilityDetail?.payload) {
            const isDataSimilar = validateAvailabilityDetail(userAvailabilityDetail?.data?.result, modifyUserAvailabilityDetail?.payload)
            return isDataSimilar;
        }
    }, [userAvailabilityDetail?.data?.result, modifyUserAvailabilityDetail?.payload])

    useEffect(() => {
        return () => {
            dispatcher(resetUserAvailabilityDetail())
            dispatcher(resetModifyUserAvailabilityDetail())
            dispatcher(resetModifyUserAvailabilityDetailPayload())
        }
    }, [])

    useEffect(() => {
        if (availabilityId) {
            dispatcher(getUserAvailabilityDetail(availabilityId, user?.user?.userId, { timeZone: timeZone }))
        }
    }, [availabilityId])

    useEffect(() => {
        if (userAvailabilityDetail?.data?.result) {
            setUserAvailabilityDetail()
        }
    }, [userAvailabilityDetail?.data?.result])

    const setUserAvailabilityDetail = async () => {
        const requestPayload = await setUserAvailabilityDetailPayload(userAvailabilityDetail?.data?.result)

        dispatcher(setModifyUserAvailabilityDetailPayload({
            ...modifyUserAvailabilityDetailPayload,
            ...requestPayload
        }))
    }

    const saveWeeklyAvail = async (localWeeklyAvail) => {
        if (modifyUserAvailabilityDetail?.isLoading) return;

        dispatcher(setModifyUserAvailabilityDetailPayload({
            ...modifyUserAvailabilityDetail?.payload,
            weeklyTimeSlots: localWeeklyAvail
        }))
    }

    const onHandleResetAvailabilityDetail = useCallback(() => {
        if (modifyUserAvailabilityDetail?.isLoading) return;

        dispatcher(getUserAvailabilityDetail(availabilityId, user?.user?.userId, { timeZone: timeZone }))
    }, [modifyUserAvailabilityDetail?.isLoading, user?.user, availabilityId])

    const onHandleSaveAvailabilityDetail = async () => {
        if (modifyUserAvailabilityDetail?.isLoading || isSimilarOrValidAvailability) return;

        const { requestPayload, errorMsg } = await validateUserAvailabilityDetail(modifyUserAvailabilityDetail?.payload)

        if (errorMsg) {
            toast.warn(errorMsg)
            return;
        } if (requestPayload) {
            dispatcher(updateUserAvailabilityDetail(userAvailabilityDetail?.data?.result?.id, { ...requestPayload }))
        }
    }

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <AvailabilityHeader headerText={"Update Availability"} />
            {userAvailabilityDetail?.isLoading &&
                <ComponentLoader isLoading={userAvailabilityDetail?.isLoading} />
            }
            {userAvailabilityDetail?.message &&
                <div className={"flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-base"}>
                        {userAvailabilityDetail?.message}
                    </span>
                </div>
            }
            <AnimatePresence initial={userAvailabilityDetail?.isLoading || modifyUserAvailabilityDetail?.payload}>
                {(!userAvailabilityDetail?.isLoading && userAvailabilityDetail?.data) &&
                    <div className={"w-full flex flex-col mx-auto container max-w-6xl gap-5 sm:gap-10"}>
                        <BasicInformation />
                        <div className={"h-0.5 bg-divider-medium w-full"}></div>

                        <div className={"w-full flex flex-col gap-10"}>
                            {([availabilityIsRecurringEnum.RECURRING.value]?.includes(modifyUserAvailabilityDetail?.payload?.isRecurring) && modifyUserAvailabilityDetail?.payload?.weeklyTimeSlots) &&
                                <>
                                    {weekdays.map((day, index) => (
                                        <RecurringAvailabilityCard
                                            key={index}
                                            localDay={day}
                                            weeklyAvailability={modifyUserAvailabilityDetail?.payload?.weeklyTimeSlots}
                                            saveWeeklyAvail={saveWeeklyAvail}
                                        />
                                    ))}
                                    <div className={"h-0.5 bg-divider-medium w-full"}></div>

                                    <StartEndDateSchedule />
                                </>
                            }
                            {[availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(modifyUserAvailabilityDetail?.payload?.isRecurring) &&
                                <NonRecurringSchedule />
                            }

                            <div className={"h-0.5 bg-divider-medium w-full"}></div>
                            <MoreAvailabilityDetail />
                        </div>
                        {(!userAvailabilityDetail?.isLoading && userAvailabilityDetail?.message) &&
                            <div className={"flex items-center justify-center"}>
                                <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                                    {userAvailabilityDetail?.message || "No Availability Found!"}
                                </span>
                            </div>
                        }
                        <SaveAndResetButtons
                            isSaveBtnLoading={modifyUserAvailabilityDetail?.isLoading}
                            isResetBtnLoading={userAvailabilityDetail?.isLoading}
                            saveBtnDisabled={isSimilarOrValidAvailability}
                            resetBtnDisabled={modifyUserAvailabilityDetail?.isLoading || userAvailabilityDetail?.isLoading}
                            onHandleSave={onHandleSaveAvailabilityDetail}
                            onHandleReset={onHandleResetAvailabilityDetail}
                        />
                    </div>
                }
            </AnimatePresence>
        </div>
    );
}

export default AvailabilityDetailPage;