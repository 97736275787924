import { useCallback, useState } from 'react';

import { toast } from "react-toastify";
import { Draggable } from 'react-drag-reorder';

import { cn } from "utils/cn.utils";

import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FaTimes } from 'react-icons/fa';

import ComponentLoader from 'components/loader/ComponentLoader';

import AddBorderButton from '../../../../../../commonComponents/AddBorderButton';
import PreviousNextButtons from '../../../../../../commonComponents/lmsButtons/PreviousNextButtons';
import { courseDetailNavigationOptions, lmsCourseTooltips } from '../../../../editLMSCourse.data';

import { createLmsCourseTagDetail, updateLmsCourseTagListOrder, deleteLmsCourseTag } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

const CourseSkills = () => {
    const { lmsCourseDetail, addLmsCourseTagDetail, deleteLmsCourseTagDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    const [isShowSkills, setIsShowSkills] = useState(lmsCourseDetail?.data?.tags?.length > 0)
    const [inputSkill, setInputSkill] = useState("")

    const onHandleAddTags = useCallback(() => {
        setIsShowSkills(true)
    }, [isShowSkills])

    const onRemoveSkillTag = useCallback((selectedSkillId) => {

        dispatcher(deleteLmsCourseTag(selectedSkillId))
    }, [lmsCourseDetail?.data])

    const onHandleAddSkill = useCallback(() => {
        if (!inputSkill || (inputSkill?.split(" ")?.length > 3)) {
            toast.error("Skills can have upto 3 words")
            return;
        }
        const skillTag = inputSkill.trim()
        const filteredSkills = lmsCourseDetail?.data?.tags?.filter((skill) => skill?.master_tag?.tag === skillTag)

        if (filteredSkills?.length > 0) {
            toast.warn("Duplicate skills are not allowed!")
            return;
        }
        const body = {
            lms_course_id: lmsCourseDetail?.data?.id,
            tag: skillTag
        }
        dispatcher(createLmsCourseTagDetail(body))
        setInputSkill("");
    }, [lmsCourseDetail?.data, inputSkill])

    const onKeyboardSave = useCallback((e) => {
        if ((e.keyCode === 9 || e.keyCode === 13) && inputSkill.trim()) {
            if (!inputSkill || (inputSkill?.split(" ")?.length > 3)) {
                toast.error("Skills can have upto 3 words")
                return;
            }
            const skillTag = inputSkill.trim()
            const filteredSkills = lmsCourseDetail?.data?.tags?.filter((skill) => skill?.master_tag?.tag === skillTag)

            if (filteredSkills?.length > 0) {
                toast.warn("Duplicate skills are not allowed!")
                return;
            }
            dispatcher(createLmsCourseTagDetail({
                lms_course_id: lmsCourseDetail?.data?.id,
                tag: skillTag
            }))
            setInputSkill("");
        }
    }, [lmsCourseDetail?.data, inputSkill])

    const onHandleUpdateTagOrder = useCallback((currentPos, newPos) => {
        var updatedList = [...lmsCourseDetail?.data?.tags];
        const [reorderedItem] = updatedList.splice(currentPos, 1);
        updatedList.splice(newPos, 0, reorderedItem);

        const body = {
            order: updatedList?.map((skill) => ({
                id: skill?.id
            }))
        }
        dispatcher(updateLmsCourseTagListOrder(body, { lms_course_id: lmsCourseDetail?.data?.id }))
    }, [lmsCourseDetail?.data])

    return (
        <div className={"w-full flex flex-col justify-between gap-5 rounded-lg overflow-hidden p-5 bg-white"}>
            <div className={"w-full flex flex-col gap-5"}>
                <div className={"w-full px-3 py-2 bg-divider-medium rounded-lg"}>
                    <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                        {"Skills you'll learn (keywords)"}
                    </span>
                </div>

                <div className={"w-full text-center font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                    {lmsCourseTooltips?.skills}
                </div>
                {!isShowSkills &&
                    <AddBorderButton
                        btnTitle={"Add Skill(s)"}
                        onHandleAdd={onHandleAddTags}
                    />
                }
                {isShowSkills &&
                    <div className={cn(
                        "w-full px-3 py-3 flex flex-col items-start justify-start border border-dashed border-text-500 rounded-lg",
                        "font-bodyPri font-normal text-text-900"
                    )}>
                        {(addLmsCourseTagDetail?.isLoading || deleteLmsCourseTagDetail?.isLoading) &&
                            <ComponentLoader isLoading={addLmsCourseTagDetail?.isLoading || deleteLmsCourseTagDetail?.isLoading} />
                        }
                        {(!addLmsCourseTagDetail?.isLoading && !deleteLmsCourseTagDetail?.isLoading) &&
                            <div className={"flex flex-wrap gap-2"}>
                                <Draggable onPosChange={onHandleUpdateTagOrder}>
                                    {(lmsCourseDetail?.data?.tags?.length > 0)
                                        && lmsCourseDetail?.data?.tags?.map((skill, index) => (
                                            <div key={index} className="w-fit flex items-center px-3 py-1 bg-primary-light rounded-lg font-bodyPri font-normal text-text-900 text-sm">
                                                <span className="sm:whitespace-nowrap">
                                                    {skill?.master_tag?.tag}
                                                </span>
                                                <button className="pl-3 cursor-pointer">
                                                    <FaTimes className="font-light text-secondary-dark" onClick={() => onRemoveSkillTag(skill?.id)} />
                                                </button>
                                            </div>
                                        ))}
                                </Draggable>
                                <div className="flex items-center gap-3">
                                    <input
                                        className={cn(
                                            "w-40 px-3 py-1 border-2 border-text-100 rounded-lg focus:outline-none focus:border-2 focus:border-text-300",
                                            "font-bodyPri font-normal text-text-700 text-sm placeholder:text-text-400 placeholder:text-sm"
                                        )}
                                        type={"text"}
                                        placeholder={"Skill name"}
                                        value={inputSkill}
                                        onChange={(event) => setInputSkill(event.target.value)}
                                        onKeyDown={onKeyboardSave}
                                        autoFocus={true}
                                    />
                                    <AiOutlineCheckCircle className={"text-2xl text-text-800 cursor-pointer"} onClick={onHandleAddSkill} />
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
            <PreviousNextButtons
                previousBtn={courseDetailNavigationOptions?.DESCRIPTION?.to}
                nextBtn={courseDetailNavigationOptions?.LANGUAGES?.to}
            />
        </div>
    )
}

export default CourseSkills;