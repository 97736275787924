import { useState, useCallback, useMemo, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { toast } from 'react-toastify';

import PageHeader from 'components/pageHeader/PageHeader';
import StatusButton from 'components/common-components/StatusButton';
import Pagination from 'components/pagination/Pagination';
import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';

import LMSTable from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTable';

import { lmsQuizMenuItems, lmsQuizTableHeaderConst, pageHeading, searchParamsInfo } from "pages/auth/edulyteLms/components/lmsQuiz/lmsQuiz.data";
import TableFilterActions from './quizFilters/TableFilterActions';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal, setShareSocial } from 'redux/local/local.slice';
import { modalConst, socialAction } from 'redux/local/local.const';
import { lmsQuizStatusEnum } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.const';

import { pagesInfo } from 'utils/pagesInfo';
import { timeZone, dayjs } from 'utils/dateTime.utils';
import { cn } from "utils/cn.utils";

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';

const LMSQuizPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { modal, shareSocial } = useAppState((s) => s.local)
  const { lmsQuizList } = useAppState((state) => state.lms.lmsQuiz)

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()
  const location = useLocation()

  const [copiedUrl, setCopiedURL] = useState(false)

  const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.LMS_QUIZ_PAGE))
  }, [useAppDispatcher, currentPageInfo])

  const onHandleNavigateToQuiz = (quizItem) => {
    if (quizItem?.quiz_setting?.status === lmsQuizStatusEnum?.PUBLISHED?.value) {
      window.open(`${EDULYTE_WEB_DOMAIN_URL}/quiz/${quizItem?.active_slug}`, "_blank")
    }
  }

  const onHandleSelectMenuItem = useCallback((menuItem, quizItem) => {
    if (menuItem?.value === lmsQuizMenuItems?.INVITE?.value) {
      navigate(`${pagesInfo.EDIT_LMS_QUIZ.pagePath}/${quizItem?.id}/edit/participants/invited?action=invite-participants`)
    }
    if (quizItem?.quiz_setting?.status === lmsQuizStatusEnum?.PUBLISHED?.value) {
      if (menuItem?.value === lmsQuizMenuItems?.SHARE?.value) {
        dispatcher(setShareSocial({
          ...shareSocial,
          socialAction: socialAction.SHARE_LMS_QUIZ,
          url: `${EDULYTE_WEB_DOMAIN_URL}/quiz/${quizItem?.active_slug}`
        }))
        dispatcher(setModal({
          ...modal,
          [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
        }))
      }
      if (menuItem?.value === lmsQuizMenuItems?.COPY?.value) {
        navigator.clipboard.writeText(`${EDULYTE_WEB_DOMAIN_URL}/quiz/${quizItem?.active_slug}`);
        setCopiedURL(true);
        toast.success("Copied")
        setTimeout(() => {
          setCopiedURL(false);
        }, 2000);
      }
    }
    if (menuItem?.value === lmsQuizMenuItems?.PARTICIPANTS?.value) {
      navigate(`${pagesInfo.EDIT_LMS_QUIZ.pagePath}/${quizItem?.id}/edit/participants`)
    }
    if (menuItem?.value === lmsQuizMenuItems?.EDIT?.value) {
      navigate(`${pagesInfo.EDIT_LMS_QUIZ.pagePath}/${quizItem?.id}/edit`)
    }
  }, [copiedUrl, modal, shareSocial])

  const onHandlePageChange = async (page) => {
    searchQueryParams.set(searchParamsInfo.page.key, page)
    navigate(`${location.pathname}?${searchQueryParams?.toString()}`);
  }

  const QuizTitleContainer = ({ quiz }) => {
    return (
      <span
        className={cn(
          'font-bodyPri font-normal text-text-900 text-base line-clamp-1 tracking-wide',
          (quiz?.quiz_setting?.status === lmsQuizStatusEnum?.PUBLISHED?.value) && "cursor-pointer hover:text-primary-dark hover:underline"
        )}
        title={quiz?.title && quiz?.title}
        onClick={() => onHandleNavigateToQuiz(quiz)}
      >
        {quiz?.title
          ? (quiz?.title?.length > 30)
            ? quiz?.title?.slice(0, 30) + " ..."
            : quiz?.title
          : "-"
        }
      </span>
    )
  }

  const tableRowsConst = lmsQuizList?.data?.results?.map((quiz) => ([
    <QuizTitleContainer quiz={quiz} />,
    <StatusButton
      status={lmsQuizStatusEnum[quiz?.quiz_setting?.status?.toUpperCase()]?.label}
      className={`bg-${lmsQuizStatusEnum[quiz?.quiz_setting?.status?.toUpperCase()]?.lightColor} text-${lmsQuizStatusEnum[quiz?.quiz_setting?.status?.toUpperCase()]?.darkColor}`}
    />,
    dayjs(quiz?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",
    <div className={"flex items-center justify-start"}>
      <ThreeDotMenu
        iconClassName={"rotate-[90deg]"}
        menuItems={(quiz?.quiz_setting?.status === lmsQuizStatusEnum?.PUBLISHED?.value)
          ? Object.values(lmsQuizMenuItems)
          : Object.values(lmsQuizMenuItems)?.filter((menuItem) => ((menuItem?.value !== lmsQuizMenuItems?.COPY?.value) && (menuItem?.value !== lmsQuizMenuItems?.SHARE?.value) && (menuItem?.value !== lmsQuizMenuItems?.INVITE?.value)))
        }
        onHandleSelect={(option) => onHandleSelectMenuItem(option, quiz)}
      />
    </div>
  ]))

  return (
    <div className={'space-y-3'}>
      <PageHeader
        pageHeading={pageHeading}
        toolTipText={"Create, customise, and share smarter quizzes with anyone."}
      />
      <TableFilterActions />
      <LMSTable
        isLoading={lmsQuizList?.isLoading}
        headers={lmsQuizTableHeaderConst}
        rows={tableRowsConst}
        alternateRowColor={"bg-sky-100"}
        rowHeaderColor={"bg-sky-200"}
      />
      {(!lmsQuizList?.isLoading && lmsQuizList?.data?.totalPages > 1) &&
        <div className={'w-full flex items-center justify-center'}>
          <Pagination
            page={lmsQuizList?.data?.page}
            totalPages={lmsQuizList?.data?.totalPages}
            onChangePage={onHandlePageChange}
          />
        </div>
      }
      {(!lmsQuizList?.isLoading && lmsQuizList?.message) &&
        <div className={'w-full flex items-center justify-center'}>
          <span className={'font-bodyPri font-normal text-red-500 text-base text-center'}>
            {lmsQuizList.message}
          </span>
        </div>
      }
    </div>
  )
}

export default LMSQuizPage;