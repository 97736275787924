import tw, { styled } from 'twin.macro';
import { motion } from 'framer-motion';
import { Section, SectionContent } from 'components/generalComponent/StyledComponent';
import { TrendingUp } from '@mui/icons-material';

export const Container = tw(Section)``;

export const Content = tw(SectionContent)`
    col-start-2 col-span-10 md:col-start-3 md:col-span-8 flex flex-col items-center justify-center
`;

export const Header = tw.div`
    flex flex-col items-center gap-5 md:mx-16 lg:mx-32
`;

export const Heading = tw.h3`
    font-heading font-semibold text-3xl text-text-900 text-center
`;

export const SubHeading = tw.h4`
    font-subHeading font-normal text-lg text-text-800 text-center
`;

export const ItemContainer = tw.div`
    mt-5 flex flex-col w-full
`;

export const TabsController = tw.div`
    self-center flex flex-wrap justify-center items-center
`;

export const TabItem = styled.span`
    ${tw`
        font-buttons font-medium text-lg text-text-700 hover:text-text-900 cursor-pointer
        w-32 py-2 flex items-center justify-center border-b-[3px] border-divider-light select-none
    `}
    ${({ isActive }) => isActive && tw`
        text-primary-main border-b-[3px] border-primary-main hover:text-primary-main!
    `}
`;

export const FeaturedTutorContainer = motion(tw.div`
    mt-16 w-full
`);

export const TutorItemContainer = tw.div`
    flex flex-col gap-2 w-full h-full bg-white rounded-xl shadow-lg lg:flex-row
`;

export const ImageContainer = styled.div`
    ${tw`
        flex items-center justify-center w-full h-full md:h-[45rem] lg:w-72 lg:h-full
    `}
`;

export const TextContainer = tw.div`
    flex flex[1] flex-col gap-3 items-start w-full h-full p-5
`;

export const NameText = tw.span`
    font-bodySec font-semibold text-2xl text-text-800 hover:text-primary-main
`;

export const CardHeading = tw.div`
flex flex-col gap-1`

export const LongText = tw.div`
    flex flex-col gap-1 w-full
`;

export const SpecialitiesText = tw.span`
    font-bodyPri font-semibold text-sm text-text-800 text-left tracking-wide
`;

export const Description = tw.span`
    font-bodyPri font-normal text-base text-text-800 text-left
`;

export const TrustAndBadges = tw.div`
    flex flex-col md:flex-row gap-5 w-full mt-5
`;

export const TrustContainer = tw.div`
    flex flex-col gap-3
    odd:flex[2] even:flex[1]
`;

export const TrustAndBadgesContainer = tw.div`
    flex flex-col gap-5
    odd:flex[2] even:flex[1]
`;

export const VerticalLineContainer = tw.div`
    border border-text-300 rotate-[22.5deg]
`;

export const TrustAndBadgesTitle = tw.span`
    font-bodySec font-semibold text-sm text-text-800
`;

export const RatingSection = tw.div`
    flex flex-col gap-0.5
`;

export const Text = tw.span`
    font-bodyPri font-normal text-sm text-text-800 tracking-wide italic
`;

export const ReviewSection = tw.div`
    flex flex-col gap-3
`;

export const ReviewTitle = tw.span`
    font-bodySec font-semibold text-sm text-text-800
`;

export const ReviewProviderName = tw.span`
    flex justify-end font-bodyPri font-medium text-base text-text-700
`;

export const BadgesItem = tw.div`
    flex flex-row items-center gap-8
`;

export const BadgeItemName = tw.div`
    font-bodySec font-medium text-sm text-text-800 whitespace-nowrap
`;

export const TrendingUpIcon = tw(TrendingUp)``;