export const data = {
    "courseId": 102,
    "courseTitle": "Python for Data Science",
    "courseTitleDescription": "Become a pro in writing essays",
    "courseDescription": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <a href=\"\">incididunt</a> ut labore et dolore magna aliqua.</p><p>Ut<strong>enim ad minim veniam, quis nostrud exercitation</strong>ullamco laboris nisi ut aliquip ex ea c",
    "courseType": "one_on_one",
    "segment": "Academics",
    "category": "Computer course",
    "skillsTag": ["Learn with Edulyte", "Edulyte", "Learning", "Study", "English", "English", "Spoken"],
    "proficiency": "Intermediate",
    "language": "English/Hindi",
    "studentCapacity": 1,
    "noOfSessions": 1,
    "sessionDuration": 30,
    "startDate": null,
    "endDate": null,
    "weeklyTimeSlot": {},
    "price": 500.0,
    "discountPct": 20,
    "thumbnailPicUrl": "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1074&q=80",
    "coverPicUrl": "",
    "videoUrl": "hxyp_LkKDdk",
    "visibility": "",
    "status": "",
    "updatedAt": "",
    "createdAt": "",
    "tutor": {
        "tutorId": 2,
        "userId": 1,
        "firstName": "shivi",
        "lastName": "S",
        "profilePicUrl": "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRaw",
        "countryDomain": "usa",
        "avgRating": 2.5,
        "ratingCount": 6,
        "onlineStatus": "online",
        "lastActiveTime": ""
    }
}

export const courseSections = {
    MORE_DETAIL_SECTION: {
        value: "more_detail_section"
    }
}