import { toast } from "react-toastify";

import LmsResourceService from "redux/edulyteLms/lmsResource/lmsResource.service";

import {
    setLmsResourceListLoading,
    setLmsResourceListData,
    setLmsResourceListMessage,

    setAddLmsResourceDetailLoading,
    setAddLmsResourceDetailData,
    setAddLmsResourceDetailMessage,
} from "redux/edulyteLms/lmsResource/lmsResource.slice";

export const getLmsResourceList = (query) => async (dispatch) => {
    dispatch(setLmsResourceListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await LmsResourceService.getLmsResourceList(requestData)
        if (response.status === 200) {
            dispatch(setLmsResourceListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setLmsResourceListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setLmsResourceListLoading(false))
    }
}

export const createLmsResourceDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsResourceDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await LmsResourceService.createLmsResourceDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddLmsResourceDetailData(response.data.data || response.data.message))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsResourceDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsResourceDetailLoading(false))
    }
}