import { useState, useEffect, memo, useMemo } from "react";

import { OptionSelector } from "components/common-components/Select";

import { weeklyScheduleActionButtons } from "pages/auth/preferences/data";
import PreferenceIconButtons from "pages/auth/preferences/commonComponents/preferenceButtons/PreferenceIconButtons";

import { timeZone, generateTimeOption } from 'utils/dateTime.utils';

const AvailabilityScheduleTimeRange = memo(({
    isLoading = false,
    timeSlot,
    updateTimeSlot,
    deleteTimeSlot
}) => {

    const timeOptions = useMemo(() => generateTimeOption(timeZone), [timeZone])
    const startTimeOption = useMemo(() => timeOptions, [timeOptions])
    const endTimeOption = useMemo(() => timeOptions, [timeOptions])

    const [startTimeIndex, setStartTimeIndex] = useState(0)

    useEffect(() => {
        for (let index = 0; index < endTimeOption.length; index++) {
            if (timeSlot.startTime === endTimeOption[index].value) {
                setStartTimeIndex(index)
                break;
            }
        }
    }, [timeSlot])

    const onHandleIconButton = (selectedBtn) => {
        if (selectedBtn.value === weeklyScheduleActionButtons.DELETE.value) {
            deleteTimeSlot(timeSlot)
        }
    }

    return (
        <div className={"w-full flex items-start justify-start md:justify-between gap-5"}>
            <div className={"w-full flex flex-col items-start justify-start gap-1"}>
                <div className={"w-full p-1 flex items-center justify-start space-x-1 rounded-lg bg-divider-darkLight"}>
                    <div className="w-[85px] sm:w-24 md:w-full">
                        <OptionSelector
                            options={startTimeOption}
                            onChange={(option) => updateTimeSlot(timeSlot, { startTime: option?.value, endTime: timeSlot?.endTime })}
                            value={timeSlot?.startTime}
                        />
                    </div>
                    <span className="font-bodyPri font-normal text-text-900 text-base px-1">
                        {"-"}
                    </span>
                    <div className="w-[85px] sm:w-24 md:w-full">
                        <OptionSelector
                            options={[...endTimeOption.slice(startTimeIndex + 1), timeOptions[0]]}
                            onChange={(option) => updateTimeSlot(timeSlot, { startTime: timeSlot?.startTime, endTime: option?.value })}
                            value={timeSlot?.endTime}
                        />
                    </div>
                </div>
            </div>

            <div className={"w-full flex items-center justify-end sm:justify-end"}>
                <PreferenceIconButtons
                    isLoading={isLoading}
                    iconButtonList={Object.values(weeklyScheduleActionButtons)?.filter((iconBtn) => iconBtn?.value === weeklyScheduleActionButtons.DELETE.value)}
                    onHandleIconButton={onHandleIconButton}
                />
            </div>
        </div>

    );
});

export default AvailabilityScheduleTimeRange;