import { cn } from "utils/cn.utils";
import { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';


import { useTitle } from 'hooks/useTitle';

import FullPageLoader from 'components/loader/FullPageLoader';
import CourseCard from 'components/courseCard/CourseCard';
import Footer1 from 'components/footer1/Footer1';

import { PageContentStyle } from 'pages/global/course/CoursePage.style';

import CourseHeader from 'pages/global/course/CourseHeader';
import CourseDetailBar from 'pages/global/course/CourseDetailBar';
import SkillsDiv from 'pages/global/course/SkillsDiv';
import MoreDetailsSection from 'pages/global/course/MoreDetailsSection';
import CourseDescriptionDiv from 'pages/global/course/CourseDescriptionDiv';
import { SubHeaders } from 'pages/global/course/CourseDetailed1To1PageStyle';
import CourseHeaderCard from 'pages/global/course/CourseHeaderCard';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import {
    getPublicCourseList,
    getPublicCourseDetail,
    createCourseFavoriteDetail,
    deleteCourseFavoriteDetail
} from "redux/course/course.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearPublicCourseDetail, setClearPublicCourseList } from 'redux/course/course.slice'

import { pagesInfo } from 'utils/pagesInfo';

const RECORDS = 6


const CoursePage = () => {
    const { currentPageInfo } = useAppState((s) => s.pageInfo)
    const { user } = useAppState((s) => s.user)
    const { publicCourseDetail, publicCourseList, addCourseFavDetail, deleteCourseFavDetail } = useAppState((s) => s.course)

    const dispatcher = useAppDispatcher()
    const { courseId } = useParams()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.COURSE))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (publicCourseDetail?.data) {
            setTitle({
                ...title,
                title: `Live Class | ${publicCourseDetail?.data?.title} | Edulyte`,
                description: publicCourseDetail?.data?.subTitle
            })
        }
    }, [publicCourseDetail?.data])

    useEffect(() => {
        if (courseId) {
            dispatcher(setClearPublicCourseList())
            dispatcher(getPublicCourseDetail(courseId))
        }

        return () => {
            dispatcher(setClearPublicCourseDetail())
        }
    }, [courseId])

    useEffect(() => {
        if (publicCourseDetail?.data) {
            dispatcher(getPublicCourseList({ page: 1, records: RECORDS, category: publicCourseDetail?.data?.category, courseId: `!${publicCourseDetail?.data?.id}` }))
        }

        return () => {
            dispatcher(setClearPublicCourseList())
        }

    }, [publicCourseDetail?.data])


    if (publicCourseDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={publicCourseDetail?.isLoading} />
        )
    }

    if (publicCourseDetail?.message) {
        return (
            <PageContentStyle>
                <div className={"flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                    {publicCourseDetail?.message ? "Sorry, class not found." : ""}
                </div>
            </PageContentStyle>
        )
    }

    return publicCourseDetail?.data ? (
        <PageContentStyle>
            <div className={"pt-16 bg-background-dark"}>
                <div className={"w-full sm:max-w-xl md:max-w-3xl lg:max-w-5xl mx-auto px-5"}>
                    <CourseHeader />
                </div>
            </div>

            <div className={"w-full sm:max-w-xl md:max-w-3xl lg:max-w-5xl mx-auto px-5"}>
                <div className={"grid grid-cols-12 gap-3"}>
                    <div className={"col-start-1 col-span-full lg:col-start-1 lg:col-span-8 space-y-3"}>

                        <div className="bg-white p-5">
                            <CourseDetailBar />
                        </div>
                        <div className="bg-white p-5">
                            <SkillsDiv />
                        </div>
                        <div className="bg-white p-5">
                            <MoreDetailsSection />
                        </div>
                        <div className='lg:hidden h-auto relative bg-white p-5'>
                            <CourseHeaderCard />
                        </div>
                        <div className="bg-white p-5">
                            <CourseDescriptionDiv />
                        </div>
                        {(publicCourseDetail?.data && publicCourseList?.data?.records > 0 && !publicCourseList?.data?.message) &&
                            <div className="bg-white p-5 space-y-3">
                                <div className='w-full flex items-center justify-between gap-3'>
                                    <SubHeaders>{"Similar Classes"}</SubHeaders>
                                    <Link to={`${pagesInfo?.FIND_COURSES.pagePath}/${publicCourseDetail?.data?.category?.replaceAll(" ", "-")?.toLowerCase()}`}
                                        className={'font-bodyPri font-normal text-text-800 text-sm cursor-pointer'}>
                                        {"View All"}
                                    </Link>
                                </div>
                                <div className={cn(
                                    "grid grid-flow-col gap-12 py-5 overflow-hidden overflow-x-scroll",
                                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight",
                                )}>
                                    {publicCourseList?.data?.results?.map((course, index) => (
                                        <div className={"min-w-[20rem] max-w-[20rem] shadow-all"} key={index}>
                                            <CourseCard
                                                course={course}
                                                isFav={user?.user?.favoriteCourses?.filter((favCourse) =>
                                                    favCourse.courseId === course.id).length > 0 ? true : false}
                                                handleFav={(courseId) => {
                                                    if (addCourseFavDetail?.isLoading || deleteCourseFavDetail?.isLoading) return;
                                                    const favoriteCourses = user?.user?.favoriteCourses?.filter((favCourse) =>
                                                        favCourse.courseId === courseId)
                                                    if (favoriteCourses?.length > 0) {
                                                        dispatcher(deleteCourseFavoriteDetail({ courseId: courseId, userId: user?.user?.userId }))
                                                    }
                                                    else {
                                                        dispatcher(createCourseFavoriteDetail({ courseId: courseId, userId: user?.user?.userId }))
                                                    }
                                                }}
                                            />
                                        </div>
                                    ))}
                                    {/* {publicCourseDetail?.data && (publicCourseList?.data?.results?.length > 0 && publicCourseList?.data?.results?.filter((course) =>
                                        (course.category === publicCourseDetail?.data?.category && course?.id !== publicCourseDetail?.data?.id))?.length === 0) &&
                                        <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                                            {"No Content"}
                                        </span>
                                    } */}
                                    {((publicCourseDetail?.data && publicCourseList?.data?.results?.length === 0) || publicCourseList?.message) &&
                                        <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                                            {publicCourseList?.message || "No Content!"}
                                        </span>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div className={"hidden lg:block col-start-9 col-span-4 -mt-[16rem]"}>
                        <CourseHeaderCard />
                    </div>
                </div>
            </div>
            <Footer1 />
        </PageContentStyle>
    ) : null
}

export default CoursePage