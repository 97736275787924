import { Link } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { MdGroups } from 'react-icons/md';
import { IoMdPeople } from 'react-icons/io';

import RatingStars from 'components/ratingStars/RatingStars';

import { useAppState } from 'hooks/useStore';
import { courseType } from "redux/course/course.const";
import { currencyRateConversion } from 'utils/generators.utils';

const CourseCard = ({ course }) => {
    const { locals } = useAppState((s) => s.local)

    const RATING_COUNT = 2

    const handleKnowMore = () => {
    }

    return (
        <div className='w-[22rem] h-full flex flex-col bg-white rounded-lg shadow-[0_0_10px_1px_rgba(0,0,0,0.3)]'>
            <div className='w-full h-48 overflow-hidden relative'>
                <div className='rounded-t-lg overflow-hidden h-full w-full'>
                    <Link to={`/class/${course?.courseId}`}>
                        <img
                            src={course?.thumbnailPicUrl}
                            alt={"class-thumbnail"}
                            className='w-full h-full object-contain'
                        />
                    </Link>
                </div>
                {/* {session?.isLoggedIn &&
                    <div className='absolute right-2 top-2 p-1.5 bg-white rounded-full shadow-md'>
                        <span onClick={() => handleFav(course.courseId)} className="cursor-pointer">
                            {isFav
                                ? (<FavoriteIcon className='text-red-500' style={{ fontSize: "1.5rem" }} />)
                                : <FavoriteBorderIcon className='text-text-700' style={{ fontSize: "1.5rem" }} />
                            }
                        </span>
                    </div>
                } */}
            </div>
            <div className='flex w-full flex-col gap-3 p-5 pt-3'>
                <div className='h-full flex flex-col justify-between gap-5'>
                    <div className='flex flex-col justify-start gap-5'>
                        <div className='flex justify-between items-center'>
                            <span className='text-text-900 font-normal font-bodyPri text-sm tracking-wide'>
                                {course?.category}
                            </span>
                            <div className='flex justify-center items-center gap-2'>
                                {Object.keys(courseType).map((item, index) => courseType[item].value === course?.courseType &&
                                    <span key={index} className='px-2 py-0.5 flex items-center justify-center rounded-full bg-text-300 font-normal font-bodyPri text-sm tracking-wide whitespace-nowrap'>
                                        {courseType[item]?.label}
                                    </span>
                                )}
                                {course?.courseType === courseType.GROUP.value
                                    ? <MdGroups className='text-text-700 text-2xl' />
                                    : <IoMdPeople className='text-text-700 text-2xl' />
                                }
                            </div>
                        </div>
                        <Link to={`/class/${course?.courseId}`} className={cn(
                            'text-text-900 font-bodyPri font-medium text-lg tracking-wide truncate',
                            "hover:text-secondary-main hover:underline decoration-1"
                        )}>
                            {course?.courseTitle}
                        </Link>
                    </div>
                    <div className='flex flex-col justify-end gap-5'>
                        <div className='flex justify-between items-center'>
                            <div className='flex justify-start gap-2'>
                                <div className='relative w-12 h-12 rounded-full'>
                                    <Link to={`/tutor/${course?.tutor?.tutorId}`}>
                                        <img src={course?.tutor?.profilePicUrl} alt={"profile-pic"} className={"w-full h-full rounded-full object-cover"} />
                                    </Link>
                                    <div className='absolute right-0.5 bottom-0.5 w-3 h-3 rounded-full overflow-hidden border-white'>
                                        <img
                                            src={`https://flagcdn.com/16x12/${course?.tutor?.fromCountryDomain}.png`.toLowerCase()}
                                            alt={"country-flag"} className={'w-full h-full object-cover'}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col justify-evenly items-start'>
                                    <div className='flex justify-center items-center gap-2'>
                                        <Link to={`/tutor/${course?.tutor?.tutorId}`}>
                                            <span className='font-medium font-bodyPri text-md tracking-wide'>
                                                {course?.tutor?.firstName + " " + course?.tutor?.lastName.charAt(0) + "."}
                                            </span>
                                        </Link>
                                        {course?.tutor?.onlineStatus === "online" && <span className={"w-2 h-2 rounded-full bg-green-600"}></span>}
                                    </div>
                                    <div className='flex justify-start items-center gap-0.5'>
                                        {course?.tutor?.rating?.totalStars?.count > RATING_COUNT
                                            && <>
                                                <RatingStars rating={course?.tutor?.rating?.avgRating?.toFixed(1)} fullRating={5} size={0.8} />
                                                <span className='font-medium text-sm font-bodyPri tracking-wide'>{course?.tutor?.rating?.avgRating?.toFixed(1)}</span>
                                                <span className='font-normal text-sm font-bodyPri'>({String(course?.tutor?.rating?.totalStars?.count).padStart(2, "0")})</span>
                                            </>
                                            // : <span className='font-normal text-sm font-bodyPri tracking-wide text-complementry-main'>{"New"}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div className={cn(
                                'flex flex-col justify-evenly items-center gap-1 font-normal text-sm font-bodyPri tracking-wide',
                            )}>
                                <span onClick={handleMessage} className={cn(
                                    isMyOffering
                                        ? "text-text-300"
                                        : "text-text-700 cursor-pointer",
                                )}>
                                    {"Message >"}
                                </span>
                                <Link to={`/class/${course?.courseId}`}>
                                    <span className='text-text-700 cursor-pointer'>
                                        {"View Detail >"}
                                    </span>
                                </Link>
                            </div> */}
                        </div>
                        <hr />
                        <div className='flex justify-between items-center gap-2'>
                            <div className='flex items-center gap-2 whitespace-nowrap'>
                                <span className='font-medium text-md font-bodyPri tracking-wide'>{currencyRateConversion(locals?.currencyCode, course?.price)}</span>
                                <span className='font-normal text-sm font-bodyPri tracking-wide'>{course?.courseType === courseType.GROUP.value ? "per session" : "per hour"}</span>
                            </div>
                            <div className={"flex items-center justify-center gap-3"}>
                                <span onClick={handleKnowMore} className={cn(
                                    'px-3 py-1 flex justify-center items-center gap-1 rounded-full font-normal font-buttons border whitespace-nowrap',
                                    "border-secondary-main text-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer"
                                )}>
                                    <span>
                                        {"Know More"}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseCard;