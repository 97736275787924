import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FaEye } from 'react-icons/fa';

import PageHeader from 'components/pageHeader/PageHeader';
import SideOverPanel from "components/common-components/SideOverPanel";
import TableComponent from "components/common-components/Table";
import Pagination from "components/pagination/Pagination";

import { pageHeading, orderToolTip, orderHeaders, getOrderListPayload, searchParamsInfo } from "pages/auth/orderList/data";

import { TableFilerActions } from "pages/auth/orderList/components/filters/TableFilterActions";
import OrderSideOverContent from './components/orderSideOverContent/OrderSideOverContent';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getUserOrderItemList } from 'redux/order/order.request';

import { useTitle } from 'hooks/useTitle';
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setUserOrderItemDetailPayload, resetUserOrderItemList } from 'redux/order/order.slice';
import { userOrderItemDetailPayload, orderItemStatusEnum } from 'redux/order/order.const';

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from 'utils/dateTime.utils';

const OrderListPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)
    const { userOrderItemList, userOrderItemDetail } = useAppState((s) => s.order)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()
    const [title, setTitle] = useTitle()

    const [sideOverPanel, setSideOverPanel] = useState(false)

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.ORDERS))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Browse all Your Orders and Take Action | Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        return () => {
            dispatcher(resetUserOrderItemList())
        }
    }, [])

    useEffect(() => {
        if (user?.user?.userId) {
            getUserOrderList()
        }
    }, [location.search, user?.user])

    const getUserOrderList = async () => {
        const requestDataPayload = await getOrderListPayload(searchQueryParams)

        dispatcher(getUserOrderItemList(user?.user?.userId, { ...requestDataPayload }))
    }

    const onHandleViewOrderItemDetail = (orderItemId) => {
        if (userOrderItemList?.isLoading || userOrderItemDetail?.isLoading) return;

        dispatcher(setUserOrderItemDetailPayload({
            ...userOrderItemDetailPayload,
            orderItemId: orderItemId
        }))
        setSideOverPanel(true)
    }

    const onHandleChangePage = async (page) => {
        searchQueryParams.set(searchParamsInfo.page.key, page)
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`);
    }

    const orderRows = userOrderItemList?.data?.results?.map((orderItem) => [
        (orderItem?.order_item_product_prices?.length > 0)
            ? orderItem?.order_item_product_prices[0]?.product_price?.product?.title
            : "-"
        ,
        `${orderItem?.master_currency?.code?.toUpperCase()} ${parseFloat("" + (orderItem?.total_amount / 100)).toFixed(2)}` || "-",
        orderItemStatusEnum[orderItem?.status?.toUpperCase()]?.label || "-",
        dayjs(orderItem?.createdAt).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A') || "-",
        <div className="flex">
            <button
                className={"p-1 text-sky-400"}
                onClick={() => onHandleViewOrderItemDetail(orderItem?.id)}
            >
                <FaEye size={20} className={"hover:scale-110"} />
            </button>
        </div>
    ])

    return (
        <div className={"h-full w-full p-3 mx-auto min-h-screen"}>
            <SideOverPanel
                child={<OrderSideOverContent />}
                isOpen={sideOverPanel}
                onClose={(isShow) => setSideOverPanel(isShow)}
            />
            <div className={"space-y-3"}>
                <PageHeader
                    pageHeading={pageHeading}
                    toolTipText={orderToolTip}
                    translateX={"translate-x-5"}
                    translateY={"-translate-y-[20%]"}
                />
                <TableFilerActions />
                <TableComponent
                    isLoading={userOrderItemList?.isLoading}
                    headers={orderHeaders}
                    rows={orderRows}
                    message={userOrderItemList?.message || "No Order(s) Found!"}
                    alternateRowColor={"bg-sky-100"}
                    rowHeaderColor={"bg-sky-200"}
                />
                {(!!userOrderItemList?.data?.results?.length && (userOrderItemList?.data?.totalPages > 1)) &&
                    <div className="w-full flex justify-center items-center">
                        <Pagination
                            page={userOrderItemList?.data?.page}
                            totalPages={userOrderItemList?.data?.totalPages}
                            onChangePage={onHandleChangePage}
                        />
                    </div>
                }
            </div>
        </div>
    );
}

export default OrderListPage;