import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/withdrawal/withdrawal.const";

class WithdrawalService {
    getUserWithdrawalListByUserId = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.USER_WITHDRAWAL_LIST, params),
            { params: query }
        )

        return response;
    }

    createUserWithdrawalDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.USER_WITHDRAWAL_LIST, params),
            body
        )

        return response;
    }
}
export default new WithdrawalService();