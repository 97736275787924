import { useState } from 'react'
import { getVideoData } from './data'
import ToolTipView from 'components/tooltipView'
import { useAppState } from 'hooks/useStore'
import { MdPlayCircleOutline } from "react-icons/md";

import VideoOverlay from './videoOverlay'
import { pagesInfo } from 'utils/pagesInfo'

const VideoHelpButton = () => {
    const [openGuideVideo, setOpenGuideVideo] = useState(false)
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { locals } = useAppState((state) => state.local)

    const videoGuideData = getVideoData(pagesInfo, locals?.userRole)
    const pageName = currentPageInfo.pageName;

    return (
        <>
            {Object.keys(videoGuideData).includes(pageName) && (
                <>
                    <ToolTipView content={'Video guide'}>
                        <button className='hover:text-primary-dark' onClick={() => setOpenGuideVideo(true)}>
                            <MdPlayCircleOutline size={25} />
                        </button>
                    </ToolTipView>
                    <VideoOverlay handleOpen={openGuideVideo} handleClose={() => setOpenGuideVideo(false)} videoId={videoGuideData[pageName]?.videoId} />
                </>
            )}
        </>
    )
}

export default VideoHelpButton
