import MUITabs from "components/MUIComponents/MUITabs";

const Tabs = ({ data, ...props }) => {
  return (
    <div className={"w-full"}>
      <MUITabs tabs={data.tabs} containerIDs={props.containerIDs} />
    </div>
  );
};

export default Tabs;
