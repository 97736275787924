import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "redux/drive/drive.const";

class DriveService {
    getFileDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_FILE_DETAILS, params)
        )
        return response
    }

    getFileList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_FILE_LIST_DETAILS),
            { params: query }
        )

        return response;
    }

    uploadFileDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.FILE_UPLOAD),
            body
        )

        return response;
    }

    viewFileDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().post(
            format(url.VIEW_FILE_DETAIL, params)
        )
        return response;
    }

    // User Storage information
    getUserStorageDetail = async () => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_STORAGE_INFO)
        )

        return response;
    }

    // user received file list 
    getUserReceiveFileList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_FILES_RECEIVED_LIST_DETAILS),
            { params: query }
        )

        return response;
    }

    // user meeting recordings
    getUserMeetingRecordingList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USERS_MEETING_RECORDING_LIST),
            { params: query }
        )

        return response;
    }

    // get recent open file list
    getRecentOpenFileList = async () => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_RECENT_OPEN_FILE_LIST)
        )

        return response;
    }

    // create share drive files
    createShareDriveFileDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.ADD_SHARE_DRIVE_FILE_DETAIL, params),
            body
        )

        return response;
    }

}

export default new DriveService();