import { cn } from "utils/cn.utils";

const LeadCapturingProgressBar = ({ pctValue = 0 }) => {

    return (
        <div className={"flex items-center w-full h-2 rounded-2xl overflow-hidden cursor-pointer bg-background-medium shadow"}>
            <div style={{ width: `${pctValue}%` }} className={cn(`h-full bg-secondary-dark`)}></div>
        </div>
    )
}

export default LeadCapturingProgressBar