export const viewContainerConst = {
    LEVEL: {
        label: "Level",
        value: "level"
    },
    SUBJECT: {
        label: "Subject",
        value: "subject"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    EMAIL: {
        label: "Email",
        value: "email"
    },
    EMAIL_CODE: {
        label: "Email Code",
        value: "email_code"
    },
    VERIFY_CODE: {
        label: "Verify Code",
        value: "verify_code"
    },
    PHONE: {
        label: "Phone",
        value: "phone"
    },
    CITY: {
        label: "City",
        value: "city"
    },
    CONTACT_TIME: {
        label: "Contact Time",
        value: "contact_time"
    }
}

export const levelBtnConst = {
    PRIMARY: {
        label: "Primary",
        value: "primary"
    },
    SECONDARY: {
        label: "Secondary",
        value: "secondary"
    },
    SENIOR_SECONDARY: {
        label: "Senior Secondary",
        value: "senior_secondary"
    },
    COLLEGE: {
        label: "College / University",
        value: "college"
    },
    ADULT: {
        label: "Adult / Professional",
        value: "adult"
    }
}

export const editBtnConst = {
    EMAIL: {
        label: "Email",
        value: "email"
    }
}