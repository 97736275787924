import { useCallback, useEffect } from 'react';

import { FaCrown, FaSpinner } from 'react-icons/fa';

import ToolTipView from 'components/tooltipView';
import { Toggle } from 'components/common-components/Toggle';

import LmsCourseTooltip from 'pages/auth/edulyteLms/commonComponents/LmsCourseTooltip';
import PreviousNextButtons from '../../../../../../commonComponents/lmsButtons/PreviousNextButtons';
import { courseDetailNavigationOptions, lmsCourseTabConst, lmsCourseTooltips } from '../../../../editLMSCourse.data';

import CourseProductDetail from './CourseProductDetail';

import { updateLmsCourseDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { modalConst } from 'redux/local/local.const';
import { setModal } from 'redux/local/local.slice';
import { resetAddProductDetail, resetAddProductPriceDetailPayload, resetModifyProductDetail } from 'redux/product/product.slice';
import { subscriptionPlanEnum, subscriptionStatusEnum, userPlans } from 'redux/subscription/subscription.const';

import { pagesInfo } from 'utils/pagesInfo';
import { cn } from 'utils/cn.utils';

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

const CourseProduct = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { lmsCourseDetail, modifyLmsCourseDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        return () => {
            dispatcher(resetAddProductDetail())
            dispatcher(resetAddProductPriceDetailPayload())
            dispatcher(resetModifyProductDetail())
        }
    }, [])

    const onHandleUpdateProductDetail = () => {
        if (lmsCourseDetail?.data?.product_lms_course?.product?.id) {
            window.open(`${EDULYTE_APP_DOMAIN_URL}${pagesInfo.PRODUCTS?.pagePath}/${lmsCourseDetail?.data?.product_lms_course?.product?.id}/edit`, "_blank")
        } else {
            alert("Something went wrong!")
        }
    }

    const onHandleChangeSetting = useCallback((key, value) => {
        if (!value) {
            const filteredActiveSubscriptions = user?.user?.user_subscriptions?.filter((subscriptionItem) => subscriptionItem?.status === subscriptionStatusEnum.active.key)
            if ((user?.user?.active_product_count >= 15) && !filteredActiveSubscriptions?.length) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.planTableModal.key]: {
                        ...modalConst.planTableModal,
                        isVisible: true,
                        title: "Unlock Premium Features: Subscribe to Go Pro",
                        userPlans
                    },
                }))
                return;
            }
        }
        const courseDetailPayload = {
            [key]: value
        }
        dispatcher(updateLmsCourseDetail(lmsCourseDetail?.data?.id, courseDetailPayload))
    }, [lmsCourseDetail?.data, user?.user])

    const handleUpgrade = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.planTableModal.key]: {
                ...modalConst.planTableModal,
                isVisible: true,
                title: "Unlock Premium Features: Subscribe to Go Pro",
                userPlans
            },
        }))
    }

    return (
        <div className={"w-full flex flex-col justify-between gap-5 rounded-lg overflow-hidden p-5 bg-white"}>
            <div className={"w-full flex flex-col gap-5"}>
                <div className={"w-full px-3 py-2 flex items-center justify-between gap-3 bg-divider-medium rounded-lg"}>
                    <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                        {"Product & Pricing"}
                    </span>
                    <button
                        className={cn(
                            'w-fit px-3 py-1 flex justify-center items-center rounded-lg font-normal font-buttons border',
                            "border-primary-dark text-primary-dark hover:bg-primary-dark hover:text-text-50 cursor-pointer",
                            "transition-transform duration-300 delay-150 ease-in-out"
                        )}
                        onClick={onHandleUpdateProductDetail}
                    >
                        {"Update Product"}
                    </button>
                </div>

                <div className={"w-full flex flex-col gap-5 md:gap-8 px-5"}>
                    <div className={"w-full text-center font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                        {lmsCourseTooltips?.productPricing}
                    </div>
                    <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                        <div className={"col-span-8 order-1 lg:col-span-2 w-full flex items-center gap-1.5"}>
                            <ToolTipView
                                disabled={!user?.user?.user_subscriptions?.length}
                                allowHTML={true}
                                theme={"light"}
                                content={(() => (
                                    <div className={"p-2 rounded flex flex-col items-start justify-start gap-1"}>
                                        {user?.user?.user_subscriptions?.map((user_subscription, index) => (
                                            <span key={index} className={"font-bodyPri font-medium text-base text-text-900"}>
                                                {`${subscriptionPlanEnum[user_subscription?.plan]?.label}: ${subscriptionStatusEnum[user_subscription?.status]?.label}`}
                                            </span>
                                        ))}
                                    </div>
                                ))()}
                            >
                                <button onClick={handleUpgrade}>
                                    <FaCrown className={"text-2xl text-complementry-main cursor-pointer"} />
                                </button>
                            </ToolTipView>
                            <p className={cn(
                                "font-bodyPri font-bold text-text-700 text-base tracking-wide",
                                lmsCourseDetail?.data?.course_setting?.no_checkout && "text-green-500"
                            )}>
                                {"This is Free"}
                            </p>
                            <LmsCourseTooltip tooltip={"No checkout needed if toggled on."} />
                        </div>
                        <div className={"col-span-full order-3 lg:order-2 lg:col-span-5 justify-self-stretch -m-2 flex"}>
                            <Toggle
                                value={lmsCourseDetail?.data?.course_setting?.no_checkout}
                                onChange={(v) => onHandleChangeSetting("no_checkout", v)}
                            />
                            {modifyLmsCourseDetail?.isLoading &&
                                <div className={"flex items-center gap-1"}>
                                    <FaSpinner className={"animate-spin text-lg text-primary-dark"} />
                                    <span className={"font-bodyPri font-normal text-primary-dark text-sm"}>
                                        {"Please wait ..."}
                                    </span>
                                </div>
                            }
                        </div>
                    </div>

                    {!lmsCourseDetail?.data?.course_setting?.no_checkout &&
                        <CourseProductDetail />
                    }
                </div>
            </div>
            <PreviousNextButtons
                previousBtn={courseDetailNavigationOptions?.FAQS?.to}
                nextBtn={lmsCourseTabConst.COURSE_BUILDER.routePath}
            />
        </div>
    )
}

export default CourseProduct;