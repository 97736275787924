import tw from 'twin.macro';
import { Link as RouteLink } from 'react-router-dom';
import { ScreenCard } from 'components/generalComponent/StyledComponent';

export const Container = tw(ScreenCard)``;

export const AnchorLink = tw(RouteLink)`
    text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer
`;

export const LogInCardContent = tw.div`
    pt-10 px-3 min-h-full min-w-full
`;

export const LogInCardHeader = tw.div`
    flex flex-col items-center justify-center gap-1
`;

export const Heading = tw.h1`
    text-xl font-bodyPri font-bold text-text-900
`;

export const SubHeading = tw.span`
    font-subHeading font-normal text-text-500 flex flex-col justify-center w-full gap-1 items-center text-base
    lg:flex-row
`;

export const LogInFormWrapper = tw.div`
    my-5 px-5
`;

export const DividerWrapper = tw.div`
    md:px-10
`;

export const SocialLogInWrapper = tw.div`
    px-5 my-5
`;