export const orientationTypeConst = {
    LANDSCAPE: {
        value: "landscape",
        dimension: {
            width: 12,
            height: 9
        }
    },
    PORTRAIT: {
        value: "portrait",
        dimension: {
            width: 9,
            height: 12
        }
    }

}

export const orderByConst = {
    RELEVANT: {
        value: "relevant"
    },
    LATEST: {
        value: "latest"
    }
}