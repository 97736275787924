import { useEffect, useState, Fragment } from "react";
import { cn } from "utils/cn.utils";
import { Dialog, Transition } from "@headlessui/react";


const AvailabilityDropdown = ({
    dayTimeOptions,
    weekOptions,
    selectedOption,
    isShowList,
    setIsShowList,
    className,
    onApplyFilter,
    onClearFilter
}) => {
    const [selectedOptionList, setSelectedOptionList] = useState(selectedOption)

    useEffect(() => {
        if (selectedOption?.length > 0) {
            setSelectedOptionList(selectedOption)
        } else {
            setSelectedOptionList([])
        }
    }, [selectedOption])

    const handleOptionSelection = (week) => {
        const selected = weekOptions?.find((o) => o.value === week?.value)

        if (!selected?.value) {
            alert("Please select any!")
            return;
        }

        const filteredList = selectedOptionList?.filter((option) => option?.value === selected?.value)
        if (filteredList?.length > 0) {
            const filteredOptions = selectedOptionList?.filter((option) => option?.value !== selected?.value)
            setSelectedOptionList(filteredOptions);
            return;
        }
        if (filteredList?.length === 0) {
            setSelectedOptionList([...selectedOptionList, selected]);
        }
    }

    return (
        <Transition appear show={isShowList} as={Fragment}>
            <Dialog
                as="div"
                className={cn("absolute z-30 overflow-y-auto", className)}
                open={isShowList}
                onClose={setIsShowList}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className={"bg-white w-full max-h-80 overflow-y-auto rounded-lg px-3 py-3"}>
                        <div className={"space-y-3"}>
                            <span className={"font-bodyPri font-medium text-text-900 text-sm"}>
                                {"Days of the week"}
                            </span>
                            <div className={"flex items-center justify-start gap-0.5 border border-text-300 rounded-xl"}>
                                {weekOptions?.map((week, index) => (
                                    <div
                                        key={index}
                                        className={cn(
                                            "flex items-center justify-start gap-1 cursor-pointer",
                                            selectedOptionList?.map((option) => option?.value)?.includes(week?.value) && "bg-primary-main rounded"
                                        )}
                                        onClick={() => handleOptionSelection(week)}
                                    >
                                        <span className={cn(
                                            "px-3 py-2.5 font-bodyPri font-normal text-text-800 text-sm",
                                            !selectedOptionList && "hover:text-primary-main",
                                            selectedOptionList?.map((option) => option?.value)?.includes(week?.value) && "text-text-50"
                                        )}
                                        >
                                            {week?.label}
                                        </span>
                                        {(index !== (weekOptions?.length - 1)) &&
                                            <span className={"rotate-180 border border-text-300 h-10"}></span>
                                        }
                                    </div>
                                ))}
                            </div>
                            <div className={"w-full flex items-center justify-between gap-3 py-2"}>
                                <span
                                    className={"font-bodyPri font-medium text-primary-dark text-sm underline cursor-pointer"}
                                    onClick={() => {
                                        setSelectedOptionList([])
                                        onClearFilter()
                                    }}
                                >
                                    {"Clear"}
                                </span>
                                <div className={cn(
                                    "px-4 py-1 rounded-full border border-primary-dark cursor-pointer",
                                    "font-bodyPri font-medium text-primary-dark text-sm tracking-wide hover:bg-primary-dark hover:text-text-50"
                                )}
                                    onClick={() => {
                                        onApplyFilter(selectedOptionList)
                                        setIsShowList(false)
                                    }}
                                >
                                    {"Apply Filters"}
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition>
    )
}

export default AvailabilityDropdown;