import { BsCheckLg } from "react-icons/bs";

const CourseTypeFilter = ({
    options,
    selectedCourseTypes,
    onHandleSelectCourseType,
    onClearFilter
}) => {

    const handleOptionSelection = (courseType) => {
        const selected = options?.find((o) => o.value === courseType?.value)

        if (!selected?.value) {
            alert("Please select any!")
            return;
        }

        const filteredList = selectedCourseTypes?.filter((option) => option?.value === selected?.value)
        if (filteredList?.length > 0) {
            const filteredOptions = selectedCourseTypes?.filter((option) => option?.value !== selected?.value)
            onHandleSelectCourseType(filteredOptions);
            return;
        }
        if (filteredList?.length === 0) {
            onHandleSelectCourseType([...selectedCourseTypes, selected]);
        }
    }
    return (
        <div className={"bg-white w-full max-h-96 overflow-y-auto rounded-lg px-3 py-1.5"}>
            <div className={"w-full space-y-5 px-3 py-1.5"}>
                <div className={"w-full flex items-center justify-between gap-3"}>
                    <div className={"flex items-center justify-start gap-2"}>
                        <span className={"font-bodyPri font-medium text-text-900 text-base"}>
                            {"Class Types"}
                        </span>
                        {selectedCourseTypes?.length > 0 &&
                            <span className={"w-5 h-5 flex items-center justify-center bg-primary-dark rounded-full text-text-50 text-sm"}>
                                {selectedCourseTypes?.length}
                            </span>
                        }
                    </div>
                    <div
                        className={"font-bodyPri font-medium text-primary-dark text-sm underline cursor-pointer"}
                        onClick={() => onClearFilter()}>
                        {"Clear"}
                    </div>
                </div>
                <div className={"w-full flex flex-col items-start justify-start gap-3"}>
                    {options?.map((courseType) => (
                        <div className={"w-full flex items-start justify-start gap-3 cursor-pointer group"} onClick={() => handleOptionSelection(courseType)}>
                            <div className={"relative w-5 h-5 border border-text-300 rounded"}>
                                {(selectedCourseTypes?.map((option) => option?.value)?.includes(courseType?.value)) &&
                                    <span className={"absolute top-0.5 -right-0"}>
                                        <BsCheckLg className={"text-base text-primary-dark"} />
                                    </span>
                                }
                            </div>
                            <div className={"w-11/12 flex flex-col items-start justify-start gap-1"}>
                                <div className={"font-bodyPri font-semibold text-text-900 text-base"}>
                                    {courseType?.label}
                                </div>
                                <div className={"font-bodyPri font-normal text-text-700 text-sm"}>
                                    {courseType?.description}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CourseTypeFilter