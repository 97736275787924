import { memo } from 'react';

import { MdOutlineNoteAlt } from 'react-icons/md';

import FloatingLabelInput from 'components/floating/floatingLabelInput';

import SaveAndCancelBtn from '../../commonComponents/SaveAndCancelBtn';

const ContactNoteItem = memo(({
    index = 0,
    isLoading = false,
    isShowSaveBtn = false,
    isShowCancelBtn = false,
    noteItem = null,
    onHandleChangeNoteDetail,
    onHandleSaveNoteDetail = () => { },
    onHandleDeleteNoteDetail,
}) => {

    return (
        <div className={"w-full flex flex-col sm:flex-row gap-5 sm:gap-3 border px-3 py-5 rounded shadow-sm"}>
            <div className={"flex justify-between gap-3 mt-2"}>
                <div className="text-lg text-text-700">
                    <MdOutlineNoteAlt />
                </div>
                <div className={"flex sm:hidden"}>
                    <SaveAndCancelBtn
                        isLoading={isLoading}
                        isShowSaveBtn={isShowSaveBtn}
                        isShowCancelBtn={isShowCancelBtn}
                        saveBtnTooltip={'Save Note'}
                        cancelBtnTooltip={"Remove Note"}
                        isBtnDisable={!noteItem?.title}
                        onHandleSaveBtn={() => onHandleSaveNoteDetail(noteItem)}
                        onHandleCancelBtn={() => onHandleDeleteNoteDetail(noteItem?.id)}
                    />
                </div>
            </div>
            <div className="flex flex-col gap-5 w-full">
                <FloatingLabelInput
                    labelItem={`note_title${index}${noteItem?.id}`}
                    label={"Note Title"}
                    inputName={"title"}
                    value={noteItem?.title}
                    onChange={(event) => onHandleChangeNoteDetail(noteItem?.id, { ...noteItem, [event.target.name]: event.target.value })}
                    inputClassName={"disabled:px-2 disabled:bg-text-200 disabled:cursor-not-allowed"}
                />
                <textarea
                    className={"border rounded outline-primary-main px-3 py-1 disabled:bg-text-200 disabled:cursor-not-allowed"}
                    placeholder={"Type your notes here"}
                    value={noteItem?.note}
                    name={"note"}
                    onChange={(event) => onHandleChangeNoteDetail(noteItem?.id, { ...noteItem, [event.target.name]: event.target.value })}
                />
            </div>
            <div className={"hidden sm:flex mt-2 h-fit"}>
                <SaveAndCancelBtn
                    isLoading={isLoading}
                    isShowSaveBtn={isShowSaveBtn}
                    isShowCancelBtn={isShowCancelBtn}
                    saveBtnTooltip={'Save Note'}
                    cancelBtnTooltip={"Remove Note"}
                    isBtnDisable={!noteItem?.title}
                    onHandleSaveBtn={() => onHandleSaveNoteDetail(noteItem)}
                    onHandleCancelBtn={() => onHandleDeleteNoteDetail(noteItem?.id)}
                />
            </div>
        </div>
    )
})

export default ContactNoteItem;