import { useCallback, useState } from 'react';

import SideBarExpendable from 'pages/auth/edulyteLms/commonComponents/SideBarExpendable';

import LmsResourceListItems from './LmsResourceListItems';

import { getLmsLecturePageResourceList } from 'redux/edulyteLms/lmsLecture/lmsLecture.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

import { debounce } from 'utils/generators.utils';

const LectureSideBarItems = () => {
    const { tutorLmsLectureDetail } = useAppState((state) => state.lms.lmsLecture)

    const dispatcher = useAppDispatcher()

    const [resourceSearch, setResourceSearch] = useState('')

    const onHandleSearchResource = useCallback((value) => {
        dispatcher(getLmsLecturePageResourceList({ search: value }))
    }, [resourceSearch])

    const onHandleChangeResourceSearch = useCallback((e) => {
        setResourceSearch(e.target.value)
        const delayFunction = debounce.debounce(onHandleSearchResource)
        delayFunction(e.target.value)
    }, [resourceSearch])

    return (
        <div className={'flex flex-col w-full gap-5 font-bodyPri'}>
            <div className={'w-full flex flex-col gap-5'}>
                <div>
                    <p className={'text-lg text-black font-medium'}>
                        {"Library"}
                    </p>
                    <p className={'text-sm text-text-600'}>
                        {"Quick access, pick from library."}
                    </p>
                </div>
                <SideBarExpendable
                    placeholder={'Search resources'}
                    disabledClassName={"border-text-200"}
                    category={'Resources'}
                    onChange={onHandleChangeResourceSearch}
                    children={<LmsResourceListItems resourceSearch={resourceSearch} />}
                    expendHeight={'300px'}
                />
            </div>
        </div>
    )
};

export default LectureSideBarItems;