import { userRoles } from "redux/auth/auth.const";

export const pageHeading = {
    heading: "My Courses (Self-Paced)",
    subHeading: "Find your self-paced courses",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/lms-course.png"
}

export const navigationOptions = [
    { label: "All Courses", to: "/courses", roles: [userRoles.STUDENT.value] },
    { label: "Favourite", to: "/favourite", roles: [userRoles.STUDENT.value] },
];