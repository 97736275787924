import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { FiEdit2 } from 'react-icons/fi';
import { BsGenderFemale } from "react-icons/bs";
import { RiCakeLine } from "react-icons/ri";
import { AiOutlineHome } from "react-icons/ai";
import { FaRegAddressCard } from "react-icons/fa";
import { IoIosCheckmark } from "react-icons/io";

import ToolTipView from "components/tooltipView";
import FloatingLabelSelect from "components/floating/floatingLabelSelect";
import MuiDatePicker from "components/MUIComponents/MuiDatePicker";

import { genderOptions } from "pages/auth/profile/data";
import SaveAndCancelBtn from "pages/auth/profile/commonComponents/SaveAndCancelBtn";
import LivingInDetail from "pages/auth/profile/personalInformation/personalDetails/LivingInDetail";
import UserFromDetail from "pages/auth/profile/personalInformation/personalDetails/UserFromDetail";

import { updateUserDetailByUserId } from "redux/user/user.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { userRoles } from "redux/auth/auth.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const PersonalDetail = () => {
    const { locals } = useAppState((s) => s.local)
    const { user, userDetail, addUserDetail } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()

    const [gender, setGender] = useState(userDetail?.userDetail?.gender || genderOptions.MALE.value)
    const [birthDate, setBirthDate] = useState(new Date())
    const [showIcon, setShowIcon] = useState(false)
    const [editDob, setEditDob] = useState(false)

    useEffect(() => {
        if (userDetail?.userDetail?.gender) {
            setGender(userDetail?.userDetail?.gender)
        }
        if (userDetail?.userDetail?.dateOfBirth) {
            setBirthDate(dayjs(userDetail?.userDetail?.dateOfBirth)?.toDate())
        }
    }, [userDetail?.userDetail?.gender || userDetail?.userDetail?.dateOfBirth])

    const onSaveUserDetail = () => {
        if (birthDate > new Date()) {
            toast.warn("Invalid Date!")
            return;
        }
        if (locals.userRole === userRoles.TUTOR.value) {
            if ((dayjs(new Date())?.format("YYYY") - dayjs(birthDate)?.format("YYYY")) < 18) {
                toast.warn("Minimum age should be 18 years!")
                return;
            }
        }
        dispatcher(updateUserDetailByUserId(
            user?.user?.userId,
            {
                dateOfBirth: dayjs(birthDate).format("YYYY-MM-DD")
            }
        ))
        setEditDob(false)
    }

    const handleGenderSelect = (e) => {
        setGender(e.value)
        dispatcher(updateUserDetailByUserId(
            user?.user?.userId,
            {
                gender: e.value
            }
        ))
    }

    return (
        <>
            <div className='w-full flex flex-col lg:flex-row gap-3 items-center'>
                {/* Gender */}
                <div className='w-full flex gap-2 items-center'>
                    <div className='flex items-center gap-1 text-text-600 '>
                        <BsGenderFemale size={16} />
                        <p className='whitespace-nowrap'>Gender:</p>
                    </div>
                    <div
                        className="w-full"
                        onMouseEnter={() => setShowIcon(true)}
                        onMouseLeave={() => setShowIcon(false)}
                    >
                        <FloatingLabelSelect
                            labelItem={"gender"}
                            showLabel={false}
                            isLoading={addUserDetail?.isLoading}
                            options={Object.values(genderOptions)}
                            onHandleSelect={(e) => handleGenderSelect(e)}
                            value={<p className="font-medium">{genderOptions[gender.toUpperCase()].label}</p>}
                            OptionChild={({ option }) => (
                                <div className="bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between">
                                    {option?.label}
                                    {option?.value === gender && <IoIosCheckmark size={20} color="green" />}
                                </div>
                            )}
                            showIcon={showIcon}
                            customBtnStyle={"border-transparent hover:border-transparent hover:shadow"}
                            dropDownContainerClassName={"w-full"}
                        />
                    </div>
                </div>
                {/* Date of birth */}
                <div className='w-full flex gap-2 items-center'>
                    <ToolTipView content="Private to you">
                        <div className='flex items-center gap-1 text-text-600 '>
                            <RiCakeLine size={16} />
                            <p className='whitespace-nowrap'>Date of Birth:</p>
                        </div>
                    </ToolTipView>
                    {!editDob &&
                        <div
                            onClick={() => setEditDob(true)}
                            className="w-full flex justify-between items-center hover:shadow rounded px-3 py-1.5 cursor-text group">
                            <span className='text-text-900 font-medium'>
                                {dayjs(userDetail?.userDetail?.dateOfBirth).format('dddd, D MMM, YYYY')}
                            </span>
                            <FiEdit2 className='hidden group-hover:block group-hover:text-text-500' />
                        </div>
                    }
                    {editDob && (
                        <div className="w-full flex gap-1 items-center">
                            <MuiDatePicker
                                label={null}
                                format={"dddd, DD MMM, YYYY"}
                                value={dayjs(userDetail?.userDetail?.dateOfBirth).tz(timeZone)}
                                onChange={(date) => setBirthDate(date, dayjs(date).tz(timeZone).format("YYYY-MM-DD"))}
                            />
                            <SaveAndCancelBtn
                                onSave={() => onSaveUserDetail()}
                                onCancel={() => setEditDob(false)}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className='w-full flex flex-col lg:flex-row gap-3 items-center'>
                
                {/* from */}
                <div className='w-full flex gap-2 items-center'>
                    <div className='flex items-center gap-1 text-text-600 '>
                        <FaRegAddressCard size={16} />
                        <p className='whitespace-nowrap'>From:</p>
                    </div>
                    <UserFromDetail />
                </div>

                {/* Living In */}
                <div className='w-full flex gap-2 items-center'>
                    <div className='flex items-center gap-1 text-text-600 '>
                        <AiOutlineHome size={16} />
                        <p className='whitespace-nowrap'>Living In:</p>
                    </div>
                    <LivingInDetail />
                </div>
            </div>




            {/* <div className="grid grid-cols-12 gap-3 p-5">
                <div className='col-start-1 col-span-full md:col-start-1 md:col-span-2 text-text-700 font-normal font-bodyPri'>
                    {"Gender:"}
                </div>
                {editBtnConst !== profileEditBtn.gender.value &&
                    Object.keys(genderOptions).map((items) => genderOptions[items].value === gender &&
                        <span className='col-start-1 col-span-3 md:col-start-3 md:col-span-3 text-text-900 font-medium font-bodyPri'>
                            {genderOptions[items].label}
                        </span>
                    )}
                {editBtnConst === profileEditBtn.gender.value &&
                    <div className="w-full col-start-1 col-span-8 md:col-start-3 md:col-span-3 flex flex-col items-start justify-center gap-3">
                        <div className={"w-full"}>
                            <OptionSelector
                                options={Object.values(genderOptions)}
                                value={gender}
                                onChange={(option) => setGender(option.value)}
                            />
                        </div>
                        <SaveAndCancelBtn
                            onSave={() => onSaveUserDetail()}
                            onCancel={() => setEditBtnConst(null)}
                        />
                    </div>
                }
                {!editBtnConst &&
                    <FiEdit
                        className={"col-start-9 col-span-1 md:col-start-6 md:col-span-1 text-lg text-text-500 cursor-pointer"}
                        onClick={() => setEditBtnConst(profileEditBtn.gender.value)}
                    />
                }
                <span className='col-start-1 col-span-full md:col-start-1 md:col-span-2 text-text-700 font-normal font-bodyPri whitespace-nowrap'>
                    {"Date of Birth:"}
                </span>
                {editBtnConst !== profileEditBtn.dateOfBirth.value &&
                    <span className='col-start-1 col-span-8 md:col-start-3 md:col-span-3 text-text-900 font-medium font-bodyPri'>
                        {dayjs(userDetail?.userDetail?.dateOfBirth).format('ddd, MMM D, YYYY')}
                        <div className="font-bodyPri font-normal text-sm text-text-700">
                            {"Private to you"}
                        </div>
                    </span>
                }
                {editBtnConst === profileEditBtn.dateOfBirth.value &&
                    <div className="col-start-1 col-span-8 md:col-start-3 md:col-span-3 flex flex-col items-start justify-center gap-3">
                        <div className={"space-y-2"}>
                            <DatePicker
                                className={cn(
                                    "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                autoFocus={true}
                                dateFormat={"dd/MM/yyyy"}
                                value={birthDate}
                                selected={birthDate}
                                maxDate={new Date()}
                                placeholderText={"Select Date"}
                                scrollableYearDropdown={true}
                                showYearDropdown={true}
                                yearDropdownItemNumber={80}
                                showMonthDropdown={true}
                                onChange={(date) => setBirthDate(date)}
                            />
                            <div className="flex items-start justify-start gap-1">
                                <span className='font-bodyPri font-medium text-text-900 text-xs text-start w-full'>
                                    {"Private to you"}
                                </span>
                            </div>
                        </div>
                        <SaveAndCancelBtn
                            onSave={() => onSaveUserDetail()}
                            onCancel={() => setEditBtnConst(null)}
                        />
                    </div>
                }
                {!editBtnConst &&
                    <FiEdit
                        className={"col-start-9 col-span-1 md:col-start-6 md:col-span-1 text-lg text-text-500 cursor-pointer"}
                        onClick={() => setEditBtnConst(profileEditBtn.dateOfBirth.value)}
                    />
                }
                <span className='col-start-1 col-span-full md:col-start-1 md:col-span-2 text-text-700 font-normal font-bodyPri whitespace-nowrap'>
                    {"From:"}
                </span>
                {editBtnConst !== profileEditBtn.from.value &&
                    <div className='col-start-1 col-span-8 md:col-start-3 md:col-span-3'>
                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                            {userCultureDetail?.userCultureDetail?.fromCountry?.country && userCultureDetail?.userCultureDetail?.fromCountry?.country}
                            {userCultureDetail?.userCultureDetail?.fromState && ", " + userCultureDetail?.userCultureDetail?.fromState}
                            {userCultureDetail?.userCultureDetail?.fromCity && ", " + userCultureDetail?.userCultureDetail?.fromCity}
                        </span>
                    </div>
                }
                {editBtnConst === profileEditBtn.from.value &&
                    <UserFromDetail
                        setEditBtnConst={setEditBtnConst}
                    />
                }
                {!editBtnConst &&
                    <FiEdit
                        className={"col-start-9 col-span-1 md:col-start-6 md:col-span-1 text-lg text-text-500 cursor-pointer"}
                        onClick={() => setEditBtnConst(profileEditBtn.from.value)}
                    />
                }
                <span className='col-start-1 col-span-full md:col-start-1 md:col-span-2 text-text-700 font-normal font-bodyPri whitespace-nowrap'>
                    {"Living In:"}
                </span>
                {editBtnConst !== profileEditBtn.livingIn.value &&
                    <div className='col-start-1 col-span-8 md:col-start-3 md:col-span-3'>
                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                            {userCultureDetail?.userCultureDetail?.liveInCountry?.country && userCultureDetail?.userCultureDetail?.liveInCountry?.country}
                            {userCultureDetail?.userCultureDetail?.fromState && ", " + userCultureDetail?.userCultureDetail?.fromState}
                            {userCultureDetail?.userCultureDetail?.fromCity && ", " + userCultureDetail?.userCultureDetail?.fromCity}
                        </span>
                    </div>
                }
                {editBtnConst === profileEditBtn.livingIn.value &&
                    <LivingInDetail
                        setEditBtnConst={setEditBtnConst}
                    />
                }
                {!editBtnConst &&
                    <FiEdit
                        className={"col-start-9 col-span-1 md:col-start-6 md:col-span-1 text-lg text-text-500 cursor-pointer"}
                        onClick={() => setEditBtnConst(profileEditBtn.livingIn.value)}
                    />
                }
            </div> */}
        </>
    )
}

export default PersonalDetail