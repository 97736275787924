import { cn } from "utils/cn.utils";
import ComponentLoader from "components/loader/ComponentLoader";

export default function DriveTable({
    isLoading = false,
    headers,
    rows,
    DropDownContainer,
    className,
    onHandleTableHeader,
    alternateRowColor = "bg-white",
    rowHeaderColor = "bg-white",
}) {

    return (
        <div className={"flex flex-col -my-2 sm:-mx-6 lg:-mx-8"}>
            <div className={"inline-block min-w-full align-middle sm:px-6 lg:px-8"}>
                <div className={cn(
                    "min-w-full md:max-w-[48rem] lg:max-w-[38rem] border-b border-divider-lightDark rounded-lg",
                    "overflow-hidden overflow-x-scroll scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                )}>

                    {/* table */}
                    <table className="w-full divide-y divide-divider-lightDark">
                        <thead className={cn(rowHeaderColor)}>
                            <tr>
                                {headers.map((head, index) => (
                                    <th
                                        key={index}
                                        scope="col"
                                        className={cn(
                                            "px-3 sm:px-6 py-3 text-xs sm:text-sm tracking-wider text-left text-text-800 capitalize",
                                            "font-bodyPri font-bold whitespace-nowrap"
                                        )}
                                    >
                                        <div className={"flex items-center justify-start gap-1.5"}>
                                            <span className={"font-bodyPri font-bold text-left text-text-800"}>
                                                {head?.label}
                                            </span>
                                            {head?.icon &&
                                                <span className={""} onClick={() => onHandleTableHeader(head)}>
                                                    {head?.icon}
                                                </span>
                                            }
                                        </div>
                                        {/* {DropDownContainer} */}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-divider-lightDark">
                            {isLoading &&
                                <tr>
                                    <td colSpan={7}>
                                        <ComponentLoader isLoading={true} />
                                    </td>
                                </tr>
                            }
                            {(!isLoading && rows?.length > 0) && rows?.map((row, idx) => (
                                <tr
                                    key={idx}
                                    className={cn(
                                        idx % 2 === 0 ? "bg-white" : alternateRowColor
                                    )}
                                >
                                    {row.map((r) => (
                                        <td className="px-6 py-4 truncate">
                                            <div className="text-sm text-text-900 capitalize">
                                                {r || "-"}
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {!isLoading && (!rows || rows?.length === 0) &&
                        <div className="w-full bg-white rounded-md p-5 flex justify-center items-center font-bodyPri font-medium text-text-800 text-base tracking-wide whitespace-nowrap">
                            {"No Data Found!"}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}