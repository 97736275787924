import { useState, useEffect, useCallback } from 'react';

import { cn } from "utils/cn.utils";

import { FaSpinner } from 'react-icons/fa';
import { FiEdit, FiSearch } from 'react-icons/fi';

import { productBtnConst } from '../../product.data';
import SaveAndCancelBtn from "pages/auth/edulyteLms/commonComponents/lmsButtons/SaveAndCancelBtn";

import { updateProductDetail } from 'redux/product/product.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetUploadFile, setUploadFilePayload } from 'redux/storage/storage.slice';
import { setAddProductDetailPayload } from "redux/product/product.slice";
import { setClearThumbnailDetailPayload, setThumbnailDetailPayload, setThumbnailDetailPayloadLoading } from 'redux/other/other.slice';
import { modalConst } from 'redux/local/local.const';
import { fileCategories } from 'redux/storage/storage.const';
import { productTypeEnum } from 'redux/product/product.const';
import { orientationTypeConst } from 'redux/other/other.const';

const ProductInformation = () => {
    const { modal } = useAppState((state) => state.local)
    const { uploadFile } = useAppState((s) => s.storage)
    const { thumbnailDetail } = useAppState((state) => state.other)
    const { productDetail, addProductDetail, modifyProductDetail } = useAppState((state) => state.product)

    const dispatcher = useAppDispatcher()

    const [mouseHovered, setMouseHovered] = useState(null)
    const [editBtn, setEditBtn] = useState(null)

    const onHandleMouseHover = useCallback((hoverItem) => {
        if (editBtn) return;

        if (!hoverItem) setMouseHovered(null)
        if (hoverItem) setMouseHovered(hoverItem)
    }, [editBtn])

    useEffect(() => {
        if (uploadFile?.data && (uploadFile?.payload?.fileCategory === fileCategories.courseThumbnail.value)) {
            dispatcher(updateProductDetail(
                productDetail?.data?.id,
                {
                    thumbnail_pic_url: uploadFile?.data[0]?.fileUrl
                }
            ))
            dispatcher(resetUploadFile())
        }
    }, [uploadFile?.data])

    useEffect(() => {
        if (thumbnailDetail?.thumbnailDetailPayload?.url) {
            dispatcher(setThumbnailDetailPayloadLoading(true))
            dispatcher(updateProductDetail(
                productDetail?.data?.id,
                {
                    thumbnail_pic_url: thumbnailDetail?.thumbnailDetailPayload?.url
                }
            ))
            setTimeout(() => {
                dispatcher(setClearThumbnailDetailPayload())
                dispatcher(setModal({
                    ...modal,
                    [modalConst.PHOTO_SEARCH_MODAL.stateKey]: false
                }))
            }, 2000)
        }
    }, [thumbnailDetail?.thumbnailDetailPayload?.url])

    const handleOnSelectImage = () => {
        dispatcher(resetUploadFile())
        dispatcher(setUploadFilePayload({
            ...uploadFile?.payload,
            fileCategory: fileCategories.courseThumbnail.value
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.IMAGE_PICKER_MODAL.stateKey]: true
        }))
    }

    const onHandleSearchThumbnail = () => {
        dispatcher(setThumbnailDetailPayload({
            ...thumbnailDetail?.thumbnailDetailPayload,
            keywords: [],
            query: "Learn English",
            orientation: orientationTypeConst.LANDSCAPE.value,
            dimensionUnit: 11
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.PHOTO_SEARCH_MODAL.stateKey]: true
        }))
    }

    const onHandleEditDetail = useCallback((editBtn) => {
        dispatcher(setAddProductDetailPayload({
            ...addProductDetail?.payload,
            [editBtn]: productDetail?.data[editBtn]
        }))
        setMouseHovered(null)
        setEditBtn(editBtn)
    }, [addProductDetail?.payload, editBtn])

    const onHandleChangeBasicDetail = useCallback((event) => {
        dispatcher(setAddProductDetailPayload({
            ...addProductDetail?.payload,
            [event?.target?.name]: event?.target?.value
        }))
    }, [])

    const onHandleSaveBasicDetail = useCallback((btnKey) => {
        if (productDetail?.isLoading || modifyProductDetail?.isLoading) return;

        if (productDetail?.data[btnKey] === addProductDetail?.payload[btnKey]) {
            setEditBtn(null)
            return;
        }
        dispatcher(updateProductDetail(
            productDetail?.data?.id,
            {
                [btnKey]: addProductDetail?.payload[btnKey]
            }
        ))
        setEditBtn(null)
    }, [addProductDetail?.payload])

    const onHandleResetBasicDetail = useCallback(() => {
        setEditBtn(null)
    }, [])

    return (
        <div className={"w-full p-5 flex flex-col gap-5 bg-white rounded-lg overflow-hidden"}>
            <span className={"font-bodyPri font-semibold text-text-900 text-lg tracking-wide"}>
                {"Product information"}
            </span>

            <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                {"Product details"}
            </span>

            {/* type / read only */}
            <div className={cn("grid grid-cols-12 gap-2 items-start")}>
                <span className={"col-span-8 order-1 lg:col-span-2 w-fill flex items-center justify-start lg:justify-between font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                    <span>
                        {"Product Type"}
                    </span>
                    <span>{":"}</span>
                </span>
                <span className={"col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch font-bodyPri font-normal text-text-900 text-md tracking-wide"}>
                    {productTypeEnum[productDetail?.data?.type?.toUpperCase()]?.label}
                </span>
            </div>

            <div
                className={cn("grid grid-cols-12 gap-2 items-start", !editBtn && "cursor-pointer")}
                onMouseOver={() => onHandleMouseHover(productBtnConst.TITLE.value)}
                onMouseOut={() => onHandleMouseHover(null)}
            >
                <span className={"col-span-8 order-1 lg:col-span-2 w-fill flex items-center justify-start lg:justify-between font-bodyPri font-medium text-text-700 text-base tracking-wide"}>
                    <span className={cn(
                        (mouseHovered === productBtnConst.TITLE.value) && "transition-transform duration-300 scale-110 text-primary-dark"
                    )}>
                        {"Title"}
                    </span>
                    <span>{":"}</span>
                </span>
                {(editBtn !== productBtnConst.TITLE.value) && (
                    <span className={"col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch font-bodyPri font-normal text-text-900 text-md tracking-wide truncate"}>
                        {productDetail?.data?.title || "-"}
                    </span>
                )}
                {(editBtn === productBtnConst.TITLE.value) && (
                    <input
                        type={"text"}
                        name={productBtnConst.TITLE.value}
                        placeholder={""}
                        value={addProductDetail?.payload?.title}
                        onChange={onHandleChangeBasicDetail}
                        className={cn(
                            "col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch",
                            "h-fit px-3 py-2 border border-divider-lightDark rounded-lg",
                            "font-bodyPri font-normal text-text-900 text-base tracking-wide",
                            "focus:outline-none focus:border-primary-main"
                        )}
                    />
                )}
                {(mouseHovered === productBtnConst.TITLE.value) &&
                    <span className={"col-span-4 lg:col-span-1 order-2 lg:order-3 justify-self-end"}>
                        <FiEdit
                            className={"text-lg text-text-800"}
                            onClick={() => onHandleEditDetail(productBtnConst.TITLE.value)}
                        />
                    </span>
                }
                {(editBtn === productBtnConst.TITLE.value) &&
                    <SaveAndCancelBtn
                        className={"col-span-4 lg:col-span-1 order-2 lg:order-3 justify-self-end flex flex-row gap-3"}
                        onHandleSave={() => onHandleSaveBasicDetail(productBtnConst.TITLE.value)}
                        onHandleReset={() => onHandleResetBasicDetail(productBtnConst.TITLE.value)}
                    />
                }
            </div>

            {/* description / read-write */}
            <div
                className={cn("grid grid-cols-12 gap-2 items-start", !editBtn && "cursor-pointer")}
                onMouseOver={() => onHandleMouseHover(productBtnConst.DESCRIPTION.value)}
                onMouseOut={() => onHandleMouseHover(null)}
            >
                <span className={"col-span-8 order-1 lg:col-span-2 w-fill flex items-center justify-start lg:justify-between font-bodyPri font-medium text-text-700 text-base tracking-wide"}>
                    <span className={cn(
                        (mouseHovered === productBtnConst.DESCRIPTION.value) && "transition-transform duration-300 scale-110 text-primary-dark"
                    )}>
                        {"Description"}
                    </span>
                    <span>{":"}</span>
                </span>
                {(editBtn !== productBtnConst.DESCRIPTION.value) && (
                    <span className={"col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch font-bodyPri font-normal text-text-900 text-md tracking-wide truncate"}>
                        {productDetail?.data?.description || "-"}
                    </span>
                )}
                {(editBtn === productBtnConst.DESCRIPTION.value) && (
                    <input
                        type={"text"}
                        name={productBtnConst.DESCRIPTION.value}
                        placeholder={""}
                        value={addProductDetail?.payload?.description}
                        onChange={onHandleChangeBasicDetail}
                        className={cn(
                            "col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch",
                            "h-fit px-3 py-2 border border-divider-lightDark rounded-lg",
                            "font-bodyPri font-normal text-text-900 text-base tracking-wide",
                            "focus:outline-none focus:border-primary-main"
                        )}
                    />
                )}
                {(mouseHovered === productBtnConst.DESCRIPTION.value) &&
                    <span className={"col-span-4 lg:col-span-1 order-2 lg:order-3 justify-self-end"}>
                        <FiEdit
                            className={"text-lg text-text-800"}
                            onClick={() => onHandleEditDetail(productBtnConst.DESCRIPTION.value)}
                        />
                    </span>
                }
                {(editBtn === productBtnConst.DESCRIPTION.value) &&
                    <SaveAndCancelBtn
                        className={"col-span-4 lg:col-span-1 order-2 lg:order-3 justify-self-end flex flex-row gap-3"}
                        onHandleSave={() => onHandleSaveBasicDetail(productBtnConst.DESCRIPTION.value)}
                        onHandleReset={() => onHandleResetBasicDetail(productBtnConst.DESCRIPTION.value)}
                    />
                }
            </div>

            {/* thumbnail / read-write */}
            <div
                className={cn("grid grid-cols-12 gap-2 items-start")}
                onMouseOver={() => onHandleMouseHover(productBtnConst.THUMBNAIL_PIC_URL.value)}
                onMouseOut={() => onHandleMouseHover(null)}
            >
                <span className={"col-span-8 order-1 lg:col-span-2 w-fill flex items-center justify-start lg:justify-between font-bodyPri font-medium text-text-700 text-base tracking-wide"}>
                    <span className={cn(
                        (mouseHovered === productBtnConst.THUMBNAIL_PIC_URL.value) && "transition-transform duration-300 scale-110 text-primary-dark"
                    )}>
                        {"Thumbnail"}
                    </span>
                    <span>{":"}</span>
                </span>
                {(editBtn !== productBtnConst.THUMBNAIL_PIC_URL.value) && (
                    <div className={"col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch space-y-3"}>
                        {modifyProductDetail?.isLoading &&
                            <div className={"flex items-center justify-start gap-1"}>
                                <span className={"font-bodyPri font-normal text-sm text-primary-dark tracking-wide"}>
                                    {"Please wait ..."}
                                </span>
                                <FaSpinner className={"animate-spin text-lg text-primary-dark"} />
                            </div>
                        }
                        {(!modifyProductDetail?.isLoading && productDetail?.data?.thumbnail_pic_url) &&
                            <div className={cn(
                                "relative flex flex-col content-between justify-between rounded-lg shadow-lg bg-black h-28 aspect-square test-xs cursor-pointer",
                                "hover:scale-105 hover:transition duration-200 hover:shadow-lg",
                                "hover:ring-offset-2 hover:ring-4 hover:ring-secondary-dark",
                            )}>
                                <img
                                    src={productDetail?.data?.thumbnail_pic_url}
                                    alt={productDetail?.data?.thumbnail_pic_url}
                                    className={"object-cover w-full h-full rounded-lg opacity-90"}
                                />
                            </div>
                        }
                        <div className={"flex flex-wrap items-center justify-start gap-3"}>
                            <div className={cn(
                                "min-w-80 max-w-full pl-5 pr-1 py-1 flex items-center justify-between cursor-default space-x-5",
                                "font-bodyPri font-normal text-text-500 text-base cursor-pointer",
                                "border-2 border-divider-medium rounded-full hover:outline-none hover:border-2 hover:border-text-400"
                            )}
                                onClick={onHandleSearchThumbnail}
                            >
                                <span className={"overflow-hidden truncate"}>
                                    {`e.g: Learn English`}
                                </span>
                                <span className={"flex items-center justify-center gap-1 rounded-full px-2 py-0.5 bg-primary-dark text-text-50 font-bodyPri font-light text-base cursor-pointer"}>
                                    <FiSearch className={"text-text-50 text-base"} />
                                    <span className={"text-sm"}>
                                        {"Search"}
                                    </span>
                                </span>
                            </div>
                            <div className={"font-bodyPri font-normal text-text-900 text-base"}>
                                <span className={""}>
                                    {"Or"}
                                </span>
                            </div>
                            <div
                                className={cn(
                                    "px-2 py-0.5 cursor-pointer border-2 border-primary-dark rounded-lg text-center",
                                    "font-bodyPri font-normal text-primary-dark text-base",
                                    "hover:bg-primary-dark hover:text-text-50"
                                )}
                                onClick={handleOnSelectImage}
                            >
                                <span className={""}>
                                    {"Upload"}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProductInformation;