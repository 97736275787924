import { FaCheck } from "react-icons/fa";

const SortByContainer = ({
    options,
    selectedSorting,
    handleSelectSorting,
    onClearFilter
}) => {
    return (
        <div className={"space-y-2"}>
            <div className={"space-y-3 py-1"}>
                {options?.map((item, index) => (
                    <div
                        key={index}
                        className={"w-full flex items-center justify-start gap-5 group cursor-pointer px-3 py-1"}
                        onClick={() => handleSelectSorting(item)}
                    >
                        <div className={`w-4 h-4`}>
                            {(selectedSorting?.value === item?.value) &&
                                <FaCheck className={"text-sm text-primary-dark"} />
                            }
                        </div>
                        <span className={'w-11/12 font-bodyPri font-normal text-text-900 text-sm tracking-wide truncate'}>
                            {item.label}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SortByContainer