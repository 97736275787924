import {
    userPaymentMethodDetailPayload,
    joinUserPaymentMethodDetailPayload,
    addPaymentIntentDetailPayload
} from 'redux/payment/payment.const';

const makePaymentPayload = {
    paymentType: null,
    email: '',
    firstName: '',
    lastName: '',
    description: null,
    gatewayName: null,
    currency: null,
    amountToPay: 0,
    processingFeePct: 0,
    processingFee: 0,
    totalAmountToPay: 0,
    paymentMethodId: null,
}

export const PAYMENT_INITIAL_STATE = {
    makePayment: {
        isLoading: false,
        makePaymentPayload: makePaymentPayload,
        makePayment: null,
        makePaymentConfirm: null,
        errorMsg: null
    },

    createUserCustomerPortalSession: {
        isLoading: false,
        data: null,
        message: null
    },
    createUserCustomerSession: {
        isLoading: false,
        data: null,
        message: null
    },
    userPaymentMethodList: {
        isLoading: false,
        data: null,
        message: null
    },
    userPaymentMethodDetail: {
        isLoading: false,
        payload: userPaymentMethodDetailPayload,
        message: null
    },
    joinUserPaymentMethodDetail: {
        isLoading: false,
        payload: joinUserPaymentMethodDetailPayload,
        message: null
    },
    removeUserPaymentMethodDetail: {
        isLoading: false,
        message: null
    },

    createUserCheckoutSession: {
        isLoading: false,
        data: null,
        message: null
    },
    
    addPaymentIntentDetail: {
        isLoading: false,
        data: null,
        payload: addPaymentIntentDetailPayload,
        message: null
    },
    paymentIntentDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addTokenThroughWalletDetail: {
        isLoading: false,
        data: null,
        message: null
    }
}