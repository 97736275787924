import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/default/default.const";

class DefaultService {
    getUserDefaultDetailsByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.USER_DEFAULT_DETAILS, params)
        )

        return response;
    }

    updateUserDefaultDetailsByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.USER_DEFAULT_DETAILS, params),
            body
        )

        return response;
    }

    // getUserPayoutDetailByUserPayoutId = async ({params}) => {
    //     const response = await baseAuthApiInstance().get(
    //         format(url.USER_PAYOUT_DETAIL, params)
    //     )

    //     return response
    // }
}

export default new DefaultService();