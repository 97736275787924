import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import format from 'string-format';

import { FaSpinner } from 'react-icons/fa';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { addWalletCredit } from 'redux/wallet/wallet.request';

import AmountTopUpCard from 'components/modals/addCreditModal/AmountTopUpCard';
import AddCreditModalFooter from 'components/modals/addCreditModal/AddCreditModalFooter';
import { setClearAddCreditToWallet, setAddCreditToWalletErrorMsg } from 'redux/wallet/wallet.slice';
import { setClearMakePayment } from 'redux/payment/payment.slice';
import { setClearModal } from 'redux/local/local.slice';
import { paymentIntentStatusEnum } from 'redux/payment/payment.const';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';

import { currencyRateConversion } from 'utils/generators.utils';

const walletConfirmationUrl = "/wallet/confirmation?virtual_txn_id={virtual_txn_id}&redirect_status={redirect_status}"

const AddCreditModal = () => {
    const { locals } = useAppState(s => s.local)
    const { user } = useAppState(s => s.user)
    const { makePayment } = useAppState(s => s.payment)
    const { addCreditToWallet } = useAppState(s => s.wallet)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    useEffect(() => {
        if (makePayment?.makePaymentConfirm?.status === paymentIntentStatusEnum.SUCCEEDED.value) {
            dispatcher(addWalletCredit())
            dispatcher(setClearMakePayment())
        } else if (makePayment?.makePaymentConfirm) {
            dispatcher(setClearMakePayment())
            dispatcher(setAddCreditToWalletErrorMsg("Something went wrong!"))
        }
    }, [makePayment?.makePaymentConfirm])

    useEffect(() => {
        if (addCreditToWallet?.addCreditToWallet) {
            const query = {
                virtual_txn_id: addCreditToWallet?.addCreditToWallet.virtualTxnId,
                redirect_status: 'success',
            }
            dispatcher(setClearAddCreditToWallet())
            dispatcher(setClearModal())
            navigate(format(walletConfirmationUrl, query))
        }
    }, [addCreditToWallet?.addCreditToWallet])

    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"min-h-[24rem] block space-y-5 pt-5"}>

                <div className={""}>
                    <div className={"flex items-center justify-between gap-1"}>
                        <span className={"font-bodyPri font-medium text-base text-text-800"}>
                            {"Available Credit:"}
                        </span>
                        <div className={"flex items-center justify-center gap-1 font-bodyPri font-semibold text-lg text-text-900"}>
                            <span className={""}>
                                {masterCurrencyCodeEnum[addCreditToWallet?.addCreditPayload?.currencyCode?.toUpperCase()]?.label}
                            </span>
                            {([masterCurrencyCodeEnum.USD.value]?.includes(addCreditToWallet?.addCreditPayload?.currencyCode)) &&
                                <span className={""}>
                                    {parseFloat(user?.user?.netWalletCredit / 100).toFixed(2)}
                                </span>
                            }
                            {([masterCurrencyCodeEnum.AUD.value]?.includes(addCreditToWallet?.addCreditPayload?.currencyCode)) &&
                                <span className={""}>
                                    {parseFloat(user?.user?.netAudWalletCredit / 100).toFixed(2)}
                                </span>
                            }
                            {([masterCurrencyCodeEnum.INR.value]?.includes(addCreditToWallet?.addCreditPayload?.currencyCode)) &&
                                <span className={""}>
                                    {parseFloat(user?.user?.netInrWalletCredit / 100).toFixed(2)}
                                </span>
                            }
                        </div>
                    </div>
                    {([masterCurrencyCodeEnum.USD.value]?.includes(addCreditToWallet?.addCreditPayload?.currencyCode)) &&
                        <div className={"flex items-center justify-end gap-1"}>
                            <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                                {"Approx."}
                            </span>
                            <div className={"flex items-center justify-center gap-1 font-bodyPri font-normal text-sm text-text-800"}>
                                <span>{currencyRateConversion(locals?.currencyCode, user?.user?.netWalletCredit)}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className={""}>
                    <AmountTopUpCard />
                </div>
                <div className={"w-full h-10"}>
                    {(addCreditToWallet?.errorMsg) &&
                        <div className={"flex items-center justify-start"}>
                            <span className={"font-bodyPri font-medium text-base text-red-500 text-center"}>
                                {addCreditToWallet?.errorMsg}
                            </span>
                        </div>
                    }
                    {(addCreditToWallet?.isLoading) &&
                        <div className={"flex items-center justify-start gap-2 whitespace-nowrap"}>
                            <span className={"font-bodyPri font-normal text-base text-text-700 whitespace-nowrap"}>
                                {"Please wait while processing ..."}
                            </span>
                            <FaSpinner className="inline-flex text-primary-main animate-spin" />
                        </div>
                    }
                </div>

            </div>

            <div className={"flex justify-end"}>
                <AddCreditModalFooter />
            </div>
        </div>
    )
}

export default AddCreditModal