import { BsCameraFill } from "react-icons/bs";

import Hero2 from "pages/global/company/images/Hero2.jpg";

import { companyEditBtn } from "pages/auth/editCompany/data";

const EditHero = ({ editBtnConst, setEditBtnConst }) => {
    return (
        <div className={"relative w-full h-64"}>
            <img
                src={Hero2}
                className={"w-full h-full object-cover"}
            />
            <div className={"absolute top-8 right-10 rounded-full bg-white p-3 cursor-pointer"}>
                <BsCameraFill className={"text-xl"} onClick={() => setEditBtnConst(companyEditBtn.coverPicUrl.value)} />
            </div>
        </div>
    )
}

export default EditHero;