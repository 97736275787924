import { Link } from 'react-router-dom';

import { useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';
import { cn } from "utils/cn.utils";

const SkillsDiv = () => {
    const { publicCourseDetail } = useAppState((s) => s.course)

    return (
        <div className='space-y-3'>
            <div className="text-lg font-medium font-bodyPri tracking-wide text-text-900">
                {"Skills you will gain"}
            </div>
            {publicCourseDetail?.data?.courseTags?.length > 0 &&
                <div className={'flex flex-wrap gap-2 px-3'}>
                    {publicCourseDetail?.data?.courseTags?.map((skillTag, index) => (
                        <Link to={`${pagesInfo?.FIND_COURSES?.pagePath}?skill=${skillTag?.masterTag?.tag?.replaceAll(" ", "-")?.toLowerCase()}`}
                            key={index}
                            className={cn(
                                'bg-primary-light border border-primary-light py-0.5 md:py-1 px-3 rounded-lg text-text-900 font-bodyPri font-normal text-base cursor-pointer',
                                "hover:text-primary-dark hover:border-primary-dark hover:bg-text-50"
                            )}
                        >
                            {skillTag?.masterTag?.tag}
                        </Link>
                    ))}
                </div>
            }
            {publicCourseDetail?.data?.courseTags?.length === 0 &&
                <div className={"font-bodyPri font-normal text-text-900 text-base px-3 py-0.5"}>
                    {"No Skills Added!"}
                </div>
            }

        </div>
    )
}

export default SkillsDiv
