import { FiFilter } from "react-icons/fi";

import ToolTipView from 'components/tooltipView';

const FilterIcon = ({ iconTooltip = "Filter", onClick }) => {
    return (
        <ToolTipView content={iconTooltip}>
            <button className={""} onClick={onClick}>
                <FiFilter className={"text-2xl font-medium text-text-600"} />
            </button>
        </ToolTipView>
    )
}

export default FilterIcon;