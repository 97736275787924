import { memo } from "react";
import { motion } from "framer-motion";
import { cn } from "utils/cn.utils";
import { IoMdClose } from "react-icons/io";

const SaveAndCancelBtn = memo(({ onCancel, onSave, isSaveBtnDisabled = false }) => {
    return (
        <div className={"flex items-center justify-center gap-1"}>
            <motion.button
                whileHover={!isSaveBtnDisabled && { scale: 1.05 }}
                whileTap={!isSaveBtnDisabled && { scale: 0.95 }}
                onClick={onSave}
                disabled={isSaveBtnDisabled}
                className={cn(
                    "flex h-10 px-2 items-center justify-center ease-in-out duration-200",
                    "text-sm  border rounded border-primary-main hover:border-primary-dark text-primary-main hover:text-primary-dark",
                    isSaveBtnDisabled && "bg-text-100 text-text-300 hover:!bg-text-100 hover:border-text-300 hover:text-text-300 cursor-not-allowed border-text-300"
                )}
            >
                <span>{"Save"}</span>
            </motion.button>
            <motion.button
                whileHover={{ scale: 1.09 }}
                whileTap={{ scale: 0.95 }}
                onClick={onCancel}
                className={"text-text-600 text-tex hover:text-secondary-dark ease-in-out duration-200"}>
                <IoMdClose size={20} />
            </motion.button>
        </div>
    )
})

export default SaveAndCancelBtn