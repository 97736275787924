export const tooltipPlacementConst = {
    BOTTOM_END: {
        value: "bottom-end"
    },
    BOTTOM_START: {
        value: "bottom-start"
    },
    BOTTOM: {
        value: "bottom"
    },
    LEFT_END: {
        value: "left-end"
    },
    LEFT_START: {
        value: "left-start"
    },
    LEFT: {
        value: "left"
    },
    RIGHT_END: {
        value: "right-end"
    },
    RIGHT_START: {
        value: "right-start"
    },
    RIGHT: {
        value: "right"
    },
    TOP_END: {
        value: "top-end"
    },
    TOP_START: {
        value: "top-start"
    },
    TOP: {
        value: "top"
    }
}