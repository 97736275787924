import { useEffect } from "react";

import { BankTransferSection } from "pages/auth/settings/components/payoutMethods/BankTransferSection";
import { PayIDTransferSection } from "pages/auth/settings/components/payoutMethods/PayIDTransferSection";
import { UPITransferSection } from "pages/auth/settings/components/payoutMethods/UPITransferSection";
import { PaypalTransferSection } from "pages/auth/settings/components/payoutMethods/PaypalTransferSection";

import { getUserPayoutListByUserId } from "redux/payout/payout.request";
import { getUserDefaultDetailsByUserId } from "redux/default/default.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearUserPayoutList } from "redux/payout/payout.slice";
import { setClearUserDefaultDetail } from "redux/default/default.slice";
import ComponentLoader from "components/loader/ComponentLoader";

export default function PayoutPage() {
  const { user } = useAppState((state) => state.user)
  const { userPayoutList } = useAppState((state) => state.payout)

  const dispatcher = useAppDispatcher();

  useEffect(() => {
    dispatcher(getUserPayoutListByUserId(user?.user?.userId))

    return () => {
      dispatcher(setClearUserPayoutList())
    }
  }, [])

  useEffect(() => {
    dispatcher(getUserDefaultDetailsByUserId(user?.user?.userId))

    return () => {
      dispatcher(setClearUserDefaultDetail())
    }
  }, [userPayoutList?.userPayoutList])

  if (userPayoutList?.isLoading) {
    return (
      <ComponentLoader isLoading={userPayoutList?.isLoading} />
    )
  }

  return (
    <div>
      <div className={"py-4 mx-8 border-b"}>
        <p className={"font-bodyPri font-normal text-text-800 text-lg text-center"}>
          {"Payout Methods"}
        </p>
      </div>

      <div>
        <p className={"my-4 font-bodyPri font-normal text-text-800 text-base text-center tracking-wider opacity-80"}>
          {" We will use this information to safely send earning to your account"}
        </p>

        <div className={"grid grid-cols-12 gap-5"}>
          <BankTransferSection />
          <PayIDTransferSection />
          <UPITransferSection />
          <PaypalTransferSection />
        </div>
      </div>
    </div>
  );
}
