import tw, { styled } from "twin.macro";

export const StyledDropdownContainer = styled.div`
  ${tw`relative border border-gray-300 px-5 py-1.5 rounded-md w-full cursor-pointer`}
`;

export const StyledDropdownList = styled.div`
  ${tw`absolute left-0 mt-2.5 rounded-md w-full z-30 bg-white`}
`;

export const StyledDropdownListItem = styled.div`
  ${tw`border border-gray-300 px-5 py-1.5 rounded-md mt-1 cursor-pointer`}
`;
