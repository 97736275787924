import { useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { OptionSelector } from "components/common-components/Select";

import { cn } from "utils/cn.utils";

export default function PreferencesNavigation({
  options,
}) {
  const params = useParams();
  const pathParam = params["*"];

  const selectOptions = useMemo(() => {
    if (!!options?.length) {
      return options.map((option) => ({
        label: option.label,
        value: option.routePath,
      }))
    } else return []
  }, [options])

  const navigate = useNavigate();

  return (
    <div className="w-full h-fit p-5 bg-white rounded-lg shadow-sm md:w-60 font-bodyPri">
      <div className="md:hidden">
        <OptionSelector
          options={selectOptions}
          onChange={(op) => {
            navigate(`${op.value}`);
          }}
          value={pathParam}
        />
      </div>
      <div className="flex-col hidden gap-3 md:flex">
        {options.map((option, idx) => (
          <Link
            key={idx}
            to={`.${option?.pagePath}`}
            className={cn(
              "font-bodyPri px-3 py-2 text-base text-center rounded-md",
              "hover:bg-blue-300 hover:text-white",
              pathParam?.startsWith(option?.routePath) ? "bg-blue-500 text-white" : ""
            )}
          >
            {option.label}
          </Link>
        ))}
      </div>
    </div>
  );
}
