import { useNavigate } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { FaSpinner } from "react-icons/fa";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setAddLeadDetailPayload } from 'redux/lead/lead.slice';
import { modalConst } from "redux/local/local.const";
import { setModal } from "redux/local/local.slice";
import { contactTime } from "redux/lead/lead.const";

import { pagesInfo } from "utils/pagesInfo";
import { updateLeadDetailByLeadId } from "redux/lead/lead.request";

const ContactTime = ({ setStepCount }) => {
    const { modal } = useAppState((state) => state.local)
    const { addLeadDetail } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const onSelectContactTime = (selectedTime) => {
        dispatcher(setAddLeadDetailPayload({
            ...addLeadDetail?.addLeadDetailPayload,
            selectedTime: selectedTime?.value,
        }))
        dispatcher(updateLeadDetailByLeadId(
            addLeadDetail?.addLeadDetail?.leadId,
            {
                contactTime: selectedTime?.value
            }
        ))
        setStepCount(10)
    }

    const handleOnSeeClasses = () => {
        if (addLeadDetail?.isLoading) return;

        dispatcher(setAddLeadDetailPayload({
            ...addLeadDetail?.addLeadDetailPayload,
            isDone: true
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.NEED_A_TUTOR_MODAL.stateKey]: false
        }))
        navigate(pagesInfo.FIND_COURSES.pagePath)
    }

    return (
        <div className={"w-full flex flex-col items-start justify-start gap-10 px-2 transition ease-in-out delay-1000 duration-1000 transform"}>
            <span className={"font-bodyPri font-bold text-4xl text-text-900 tracking-wide"}>
                {"Finally, choose a convenient time to contact"}
            </span>
            <div className={"w-full flex flex-col items-center justify-center gap-12"}>
                <div className={"flex flex-wrap items-center justify-center gap-12"}>
                    {Object.values(contactTime)?.map((img) => (
                        <div
                            className={cn(
                                "flex flex-col items-center justify-between gap-2 pt-2 rounded-lg w-36 h-36 cursor-pointer",
                                "ring-2 ring-primary-light hover:ring-secondary-main group",
                                "hover:scale-105 hover:transition duration-200 hover:shadow-lg",
                                img?.value === addLeadDetail?.addLeadDetailPayload?.selectedTime
                                && "ring-4 !ring-secondary-dark"
                            )}
                            onClick={() => onSelectContactTime(img)}
                        >
                            <div className={cn(
                                "w-36 h-36 rounded-t-lg overflow-hidden"
                            )}>
                                <img src={img?.imgUrl} className={"w-full h-full object-contain"} />
                            </div>
                            <div className={"p-1 font-bodyPri font-normal text-text-800 text-md tracking-wide group-hover:text-secondary-main"}>
                                {img?.label}
                            </div>
                            {/* <div className={"pb-1 font-bodyPri font-normal text-text-800 text-sm"}>
                                ({img?.contactTime})
                            </div> */}
                        </div>
                    ))}
                </div>
                <div className={"flex justify-center items-center"}>
                    <span
                        className={cn(
                            "px-12 py-3 flex justify-center items-center rounded-full cursor-pointer",
                            "font-buttons font-medium text-2xl text-text-50 bg-primary-dark",
                            "transition ease-in-out delay-100 duration-300 transform hover:scale-105"
                        )}
                        onClick={handleOnSeeClasses}
                    >
                        {"See Classes"}
                    </span>
                </div>
            </div>
            {addLeadDetail?.isLoading &&
                <div className={"flex items-center justify-center gap-2"}>
                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                    <span className={"font-bodyPri font-normal text-base text-text-700 whitespace-nowrap"}>
                        {"Please wait ..."}
                    </span>
                </div>
            }
            {addLeadDetail?.errorMsg &&
                <div className={"flex items-center justify-center font-bodyPri font-normal text-base text-red-500"}>
                    {addLeadDetail?.errorMsg}
                </div>
            }
        </div>
    )
}

export default ContactTime