import React, { useEffect, useState } from "react";

//firebase
import { doc, onSnapshot } from "firebase/firestore";
import { database } from "config/firebase.config"

//context imports
import { useChat } from "../../contexts/chatContext";
import { useUser } from "../../contexts/userContext";

//helper functions
import updateNotification from "../../helpers/updateNotification";

//icon imports
import { VolumeUpIcon } from "@heroicons/react/outline";
import { VolumeOffIcon } from "@heroicons/react/outline";

export default function MuteNotifications() {
  const { selectedChat } = useChat();
  const { user } = useUser();
  const [muted, setMuted] = useState(false);

  const { userId } = user;

  useEffect(() => {
    const chatId = selectedChat.id;

    const notificationQuery = doc(database, "groupnotifications", chatId);
    const unsubscribe = onSnapshot(notificationQuery, (docSnapshot) => {
      const formattedNotification = {
        id: docSnapshot.id,
        ...docSnapshot.data(),
      };
      const { muted } = formattedNotification;
      const chatMuted = muted.includes(userId);
      setMuted(chatMuted);
    });
    return () => unsubscribe;
  }, [selectedChat, userId]);

  async function updateMuteStatus() {
    await updateNotification(muted, selectedChat, user);
  }

  return (
    <div
      onClick={updateMuteStatus}
      className="bg-gray-100 rounded-md flex align-center p-1.5 cursor-pointer"
    >
      {muted ? (
        <VolumeOffIcon className="text-primary-dark h-4 w-4 md:h-5 md: w-5" />
      ) : (
        <VolumeUpIcon className="text-primary-dark h-4 w-4 md:h-5 md: w-5" />
      )}
      <p className="text-primary-dark text-xs ml-2 md:text-sm">
        {muted ? "Unmute" : "Mute"}
      </p>
    </div>
  );
}
