import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { FaSpinner } from 'react-icons/fa';

import IsdCodeSelector from 'components/optionSelector/IsdCodeSelector';

// import { generateRecaptcha, sendCodeToMobileNumber } from "redux/firebase/firebase.request";
import { getMasterCountryList } from 'redux/master/master.request';
import { createSendOtp } from "redux/notification/notification.request";
import { updateUserAuthByUserId } from "redux/auth/auth.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setAddUserAuthPayload } from "redux/auth/auth.slice";
import { setClearCountryList } from 'redux/master/master.slice';
import { setClearSendOtp, setClearVerifyOtp } from "redux/notification/notification.slice";
import { otpDeliveryMethod, otpStatus } from "redux/notification/notification.const";

import { validateMobileNo } from "utils/validation-functions";

const NewMobileNoVerificationBox = ({ setIsCodeSent }) => {
    const { countryList, timeZoneCountryDetail } = useAppState(s => s.master)
    const { sendOtp, verifyOtp } = useAppState((s) => s.notification)
    const { addUserAuth } = useAppState((state) => state.auth)
    const { user } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()

    const [inputMobileNo, setInputMobileNo] = useState("")
    const [myCountry, setMyCountry] = useState({
        masterCountryId: 0,
        country: "",
        countryDomain: "",
        isdCode: ""
    })
    const [isInputMobileNoError, setIsInputMobileNoError] = useState({
        isAlreadyExistMobileNo: false,
        isInvalidMobileNo: false
    })

    useEffect(() => {
        setInputMobileNo(user?.user?.mobileNo)
        dispatcher(getMasterCountryList())

        return () => {
            dispatcher(setClearCountryList())
        }
    }, [])

    useEffect(() => {
        if (countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country) => (
                    country.countryDomain === "US"
                ))
            }
            setMyCountry(myLocalCountryList[0])
        }
    }, [countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

    useEffect(() => {
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            dispatcher(setClearVerifyOtp())
            dispatcher(setClearSendOtp())
            dispatcher(updateUserAuthByUserId(
                user?.user?.userId,
                {
                    mobileNo: myCountry?.isdCode + "-" + inputMobileNo
                }
            ))
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.PENDING.value) {
            toast.warning("Something went wrong")
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.EXPIRED.value) {
            toast.error("Code Expired")
            return;
        }
    }, [verifyOtp?.verifyOtp || verifyOtp?.errorMsg])

    useEffect(() => {
        if (sendOtp?.sendOtp) {
            setIsCodeSent(true)
        }
        if (sendOtp?.errorMsg) {
            setIsCodeSent(false)
        }
    }, [sendOtp?.sendOtp])

    useEffect(() => {
        if (!validateMobileNo(inputMobileNo)) {
            setIsInputMobileNoError({
                isAlreadyExistMobileNo: false,
                isInvalidMobileNo: true
            })
        }
        if (inputMobileNo === user?.user?.mobileNo) {
            setIsInputMobileNoError({
                isAlreadyExistMobileNo: true,
                isInvalidMobileNo: false
            })
        }
        if (validateMobileNo(inputMobileNo) && inputMobileNo !== user?.user?.mobileNo) {
            setIsInputMobileNoError({
                isAlreadyExistMobileNo: false,
                isInvalidMobileNo: false
            })
        }
    }, [inputMobileNo])

    const onSelectIsdCode = (option) => {
        const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option.value)
        setMyCountry(selectedCountry)
    }

    const onSendCode = async () => {
        if (!inputMobileNo || isInputMobileNoError?.isAlreadyExistMobileNo || isInputMobileNoError?.isInvalidMobileNo) return;

        dispatcher(setAddUserAuthPayload({
            ...addUserAuth?.addUserAuthPayload,
            mobileNo: inputMobileNo,
            isdCode: myCountry?.isdCode
        }))
        dispatcher(createSendOtp({
            channel: otpDeliveryMethod.SMS.value,
            mobileNo: myCountry?.isdCode + "-" + inputMobileNo
        }))
        // generateRecaptcha()
        // const mobileNumber = String(myCountry?.isdCode + "-" + inputMobileNo)
        // const confirmation = await sendCodeToMobileNumber(mobileNumber)
        // if (confirmation) {
        //     setIsCodeSent(true)
        // } else {
        //     setIsCodeSent(false)
        // }
    }

    // window?.recaptchaVerifier?.render().then((widgetId) => {
    //     window.recaptchaWidgetId = widgetId;
    // });

    return (
        <div className={"w-full h-full flex flex-col items-center justify-center gap-8"}>
            <div className={"w-full flex flex-col justify-start items-start gap-1.5"}>
                <div className={"font-bodyPri font-medium text-text-800 text-base"}>
                    {"Phone:"}
                </div>
                <div className={cn(
                    "w-full flex flex-col items-start justify-center gap-1",
                )}>
                    <div className={cn(
                        "w-full flex items-center justify-start px-2",
                        (isInputMobileNoError?.isInvalidMobileNo || isInputMobileNoError?.isAlreadyExistMobileNo)
                        && "border-2 border-red-500",
                        (!isInputMobileNoError?.isInvalidMobileNo || !isInputMobileNoError?.isAlreadyExistMobileNo)
                        && "border-2 border-divider-medium focus:border-2 focus:border-text-400 hover:border-text-400"
                    )}>
                        {countryList?.isLoading &&
                            <FaSpinner className={"text-lg text-primary-main animate-spin"} />
                        }
                        {countryList?.countryList &&
                            <IsdCodeSelector
                                options={countryList?.countryList?.map((country) => ({
                                    label: country?.isdCode,
                                    value: country?.masterCountryId,
                                    country: country
                                }))}
                                value={myCountry?.masterCountryId}
                                onChange={(option) => onSelectIsdCode(option)}
                            />
                        }
                        <input
                            type={"number"}
                            className={cn(
                                "w-full py-1 rounded-md focus:outline-none input-number-spin-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                            )}
                            value={inputMobileNo}
                            onChange={(event) => setInputMobileNo(event.target.value)}
                        />
                    </div>
                    <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                        {isInputMobileNoError?.isInvalidMobileNo
                            && "Invalid Phone Number!"
                        }
                    </span>
                    <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                        {isInputMobileNoError?.isAlreadyExistMobileNo
                            && "An account already exist for this mobile number"
                        }
                    </span>
                </div>
            </div>
            {/* <div className={'flex items-center justify-center'} id={'recaptcha-container'}></div> */}
            <div onClick={onSendCode}
                className={cn(
                    "w-full py-1 flex item-center justify-center rounded-md",
                    "font-buttons font-normal text-base text-text-50",
                    (isInputMobileNoError?.isInvalidMobileNo || isInputMobileNoError?.isAlreadyExistMobileNo)
                    && "bg-text-300",
                    (!isInputMobileNoError?.isInvalidMobileNo && !isInputMobileNoError?.isAlreadyExistMobileNo)
                    && "bg-primary-main cursor-pointer"
                )}>
                <span className={""}>
                    {"Send Code"}
                </span>
            </div>
        </div>
    )
}

export default NewMobileNoVerificationBox