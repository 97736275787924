import tw, { styled } from 'twin.macro';

export const DropDownMenuContainer = styled.div`
    ${tw`
        bg-white rounded-xl absolute top-[4rem] z-40 px-4 py-2 w-auto shadow-2xl overflow-hidden overflow-y-auto
        scrollbar scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full 
        hover:(scrollbar-thumb-divider-lightDark scrollbar-track-divider-darkLight)
    `}
    ::-webkit-scrollbar {
        ${tw`w-1.5`}
    }
    opacity: ${({ isActive }) => isActive ? 1 : 0};
    visibility:  ${({ isActive }) => isActive ? `visible` : `hidden`};
    transform: ${({ isActive }) => isActive ? `translateY(0)` : `translateY(-20px)`};
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    ${({ boxStyle }) => boxStyle}
    
    &::before {
        content: '';
        transform: rotate(45deg);
        ${tw`
            absolute top-[-0.5rem] left-1/2 w-5 h-5 bg-white
        `}
        ${({ arrowStyle }) => arrowStyle}
    }
`;
