import { createSlice } from "@reduxjs/toolkit";

import { CHARGEBEE_INITIAL_STATE } from "./chargeBee.initialState";

const chargeBee = createSlice({
    name: "chargeBee",
    initialState: CHARGEBEE_INITIAL_STATE,
    reducers: {
    }
})

export const {
} = chargeBee.actions

export default chargeBee.reducer