import { createSlice } from "@reduxjs/toolkit";

import { ORDER_INITIAL_STATE } from "./order.initialState";

const order = createSlice({
    name: "order",
    initialState: ORDER_INITIAL_STATE,
    reducers: {
        // user order list
        setUserOrderItemListLoading: (state, { payload }) => {
            state.userOrderItemList.isLoading = payload
        },
        setUserOrderItemListData: (state, { payload }) => {
            state.userOrderItemList.data = payload
            state.userOrderItemList.message = ORDER_INITIAL_STATE.userOrderItemList.message
        },
        setUserOrderItemListMessage: (state, { payload }) => {
            state.userOrderItemList.message = payload
            state.userOrderItemList.data = ORDER_INITIAL_STATE.userOrderItemList.data
        },
        resetUserOrderItemList: (state) => {
            state.userOrderItemList = ORDER_INITIAL_STATE.userOrderItemList
        },

        // user order detail
        setUserOrderItemDetailLoading: (state, { payload }) => {
            state.userOrderItemDetail.isLoading = payload
        },
        setUserOrderItemDetailData: (state, { payload }) => {
            state.userOrderItemDetail.data = payload
            state.userOrderItemDetail.message = ORDER_INITIAL_STATE.userOrderItemDetail.message
        },
        setUserOrderItemDetailMessage: (state, { payload }) => {
            state.userOrderItemDetail.message = payload
            state.userOrderItemDetail.data = ORDER_INITIAL_STATE.userOrderItemDetail.data
        },
        resetUserOrderItemDetail: (state) => {
            state.userOrderItemDetail = ORDER_INITIAL_STATE.userOrderItemDetail
        },

        // user order detail payload
        setUserOrderItemDetailPayload: (state, { payload }) => {
            state.userOrderItemDetail.payload = payload
        },
        resetUserOrderItemDetailPayload: (state) => {
            state.userOrderItemDetail.payload = ORDER_INITIAL_STATE.userOrderItemDetail.payload
        },

        // filter props
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
        },
        resetFilterProps: (state) => {
            state.filterProps = ORDER_INITIAL_STATE.filterProps
        },

        // Order
        // confirmation
        setConfirmationOrderLoading: (state, { payload }) => {
            state.confirmationOrder.isLoading = payload
        },
        setConfirmationOrderData: (state, { payload }) => {
            state.confirmationOrder.data = payload
            state.confirmationOrder.message = ORDER_INITIAL_STATE.confirmationOrder.message
        },
        setConfirmationOrderMessage: (state, { payload }) => {
            state.confirmationOrder.message = payload
            state.confirmationOrder.data = ORDER_INITIAL_STATE.confirmationOrder.data
        },
        resetConfirmationOrder: (state) => {
            state.confirmationOrder = ORDER_INITIAL_STATE.confirmationOrder
        },
    }
})

export const {
    setUserOrderItemListLoading,
    setUserOrderItemListData,
    setUserOrderItemListMessage,
    resetUserOrderItemList,

    setUserOrderItemDetailLoading,
    setUserOrderItemDetailData,
    setUserOrderItemDetailMessage,
    resetUserOrderItemDetail,
    setUserOrderItemDetailPayload,
    resetUserOrderItemDetailPayload,

    setFilterProps,
    resetFilterProps,

    // Order 
    setConfirmationOrderLoading,
    setConfirmationOrderData,
    setConfirmationOrderMessage,
    resetConfirmationOrder,
} = order.actions;

export default order.reducer;