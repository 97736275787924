import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import {
    SignUpCardContent,
    SignUpCardHeader,
    SubHeading,
    SignUpFormWrapper,
    SignUpCardFooter,
    TextLink,
} from '../Authorization.style';

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

import ModalSignUpForm from './ModalSignUpForm';

import { tabConfig } from '../data';

import { createSignUp } from 'redux/auth/auth.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setSignUpLoading } from 'redux/auth/auth.slice';
import { otpStatus } from 'redux/notification/notification.const';

import { timeZone } from 'utils/dateTime.utils';
import VerifyAccountContainer from './VerifyAccountContainer';

const edulyteTermsOfService = "https://www.edulyte.com/terms-of-service/"
const edulytePrivacyPolicy = "https://www.edulyte.com/privacy-policy/"

const SignUpContainer = ({ setCurrentActiveTab }) => {
    const { signUp } = useAppState((s) => s.auth)
    const { verifyOtp } = useAppState((s) => s.notification)

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    const pageUrl = EDULYTE_APP_DOMAIN_URL + location.pathname

    const getRecaptchaToken = async () => {
        dispatcher(setSignUpLoading(true))
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha('signup');
        if (token) {
            return token;
        }
    }

    const createNewSignUpUser = async () => {
        const generatedToken = await getRecaptchaToken()
        if (generatedToken) {
            const body = {
                firstName: signUp?.payload?.firstName,
                lastName: signUp?.payload?.lastName,
                email: signUp?.payload?.email,
                referralCode: signUp?.payload?.referralCode,
                isPromotionActive: signUp?.payload?.isPromotionActive,
                rechaptcha_token: generatedToken,
                timeZone: timeZone,
                source: pageUrl
            }
            if (signUp?.payload?.mobileNo) {
                body["mobileNo"] = String(signUp?.payload?.country?.isdCode + "-" + signUp?.payload?.mobileNo)
                body["isMobileNoVerified"] = signUp?.payload?.isMobileNoVerified
            }
            dispatcher(createSignUp(body))
        }
    }

    useEffect(() => {
        if (verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            createNewSignUpUser()
        }
    }, [verifyOtp?.verifyOtp])

    if (isFormSubmitted) {
        return (
            <VerifyAccountContainer />
        )
    }

    return (
        <SignUpCardContent>
            <SignUpCardHeader>
                <SubHeading>
                    {'Already have an account?'}
                    <TextLink onClick={() => setCurrentActiveTab(tabConfig?.LOG_IN)}>
                        {'Log in'}
                    </TextLink>
                </SubHeading>
            </SignUpCardHeader>
            <SignUpFormWrapper>
                <ModalSignUpForm setIsFormSubmitted={setIsFormSubmitted} />
            </SignUpFormWrapper>
            <SignUpCardFooter>
                {"By signing up, I agree to "}
                <a
                    href={edulyteTermsOfService}
                    rel={"noreferrer"}
                    target={"_blank"}
                    className={"hover:text-primary-dark text-text-700 text-sm font-bodyPri cursor-pointer"}
                >
                    {"Terms of service"}
                </a>,
                <a
                    href={'#'}
                    className={"hover:text-primary-dark text-text-700 text-sm font-bodyPri cursor-pointer"}
                >
                    {" Community Guidelines"}
                </a>
                {" and "}
                <a
                    href={edulytePrivacyPolicy}
                    rel={"noreferrer"}
                    target={"_blank"}
                    className={"text-text-700 hover:text-primary-dark text-sm font-bodyPri cursor-pointer px-5"}
                >
                    {"Privacy Policy"}
                </a>
            </SignUpCardFooter>
        </SignUpCardContent>
    )
}

export default SignUpContainer;