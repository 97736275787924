import { toast } from "react-toastify";
import LeadService from "redux/lead/lead.service";

import {
    setAddLeadDetailLoading,
    setAddLeadDetail,
    setAddLeadDetailErrorMsg
} from "redux/lead/lead.slice";

export const createLeadDetail = (body) => async (dispatch) => {
    dispatch(setAddLeadDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await LeadService.createLeadDetail(requestData)
        if (response.status === 200) {
            dispatch(setAddLeadDetail(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddLeadDetailErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddLeadDetailLoading(false))
    }
}

export const updateLeadDetailByLeadId = (leadId, body) => async (dispatch) => {
    dispatch(setAddLeadDetailLoading(true))

    try {
        const requestData = {
            params: { leadId: leadId },
            body: body
        }
        const response = await LeadService.updateLeadDetailByLeadId(requestData)
        if (response.status === 200) {
            dispatch(setAddLeadDetail(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddLeadDetailErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddLeadDetailLoading(false))
    }
}