import { useState, useCallback, useEffect } from 'react'

import { toast } from 'react-toastify';
import { cn } from "utils/cn.utils";

import SelectSearch, { fuzzySearch } from 'react-select-search';

import ButtonLoader from 'components/loader/ButtonLoader';
import ComponentLoader from 'components/loader/ComponentLoader';
import { OptionSelector } from 'components/common-components/Select';

import { createLmsArticleInstructorDetail, updateLmsArticleInstructorDetail } from 'redux/edulyteLms/lmsArticle/lmsArticle.request';
import { getTutorPublicList } from 'redux/tutor/tutor.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import {
    resetAddLmsArticleInstructorDetail,
    resetAddLmsArticleInstructorDetailPayload,
    resetModifyLmsArticleInstructorDetail,
    setAddLmsArticleInstructorDetailPayload
} from 'redux/edulyteLms/lmsArticle/lmsArticle.slice';
import { setClearTutorList } from 'redux/tutor/tutor.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsArticleInstructorPermissionEnum } from 'redux/edulyteLms/lmsArticle/lmsArticle.const';

const AddLmsArticleInstructorModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { tutorList } = useAppState((state) => state.tutor)
    const { lmsArticleDetail, addLmsArticleInstructorDetail, modifyLmsArticleInstructorDetail } = useAppState((state) => state.lms.lmsArticle)

    const dispatcher = useAppDispatcher()

    const [searchText, setSearchText] = useState("")

    useEffect(() => {
        if (addLmsArticleInstructorDetail?.data || modifyLmsArticleInstructorDetail?.data) {
            dispatcher(resetAddLmsArticleInstructorDetail())
            dispatcher(resetModifyLmsArticleInstructorDetail())
            dispatcher(resetAddLmsArticleInstructorDetailPayload())
            dispatcher(setModal({
                ...modal,
                [modalConst.addLmsArticleInstructorModal.key]: {
                    ...modal[modalConst.addLmsArticleInstructorModal.key],
                    isVisible: false,
                    title: "Add Instructor"
                }
            }))
        }
    }, [addLmsArticleInstructorDetail?.data || modifyLmsArticleInstructorDetail?.data])

    useEffect(() => {

        return () => {
            dispatcher(resetAddLmsArticleInstructorDetail())
            dispatcher(resetModifyLmsArticleInstructorDetail())
            dispatcher(resetAddLmsArticleInstructorDetailPayload())
        }
    }, [])

    useEffect(() => {
        const query = {
            page: 1,
            records: 5
        }
        dispatcher(getTutorPublicList(query))

        return () => {
            dispatcher(setClearTutorList())
        }
    }, [])

    const onHandleGetOptions = useCallback((query) => {
        if (searchText && !query) {
            setSearchText("")
            dispatcher(getTutorPublicList({ page: 1, records: 5 }))
            return;
        }
        if (!query || (query === searchText)) return;

        setSearchText(query)
        if (!isNaN(query)) {
            dispatcher(getTutorPublicList({ userId: Number(query) }))
        } else if (isNaN(query)) {
            dispatcher(getTutorPublicList({ search: query }))
        }
    }, [searchText])

    const onHandleSelectInstructor = useCallback((option) => {
        if (option === addLmsArticleInstructorDetail?.payload?.userId) return;

        dispatcher(setAddLmsArticleInstructorDetailPayload({
            ...addLmsArticleInstructorDetail?.payload,
            userId: option
        }))
    }, [addLmsArticleInstructorDetail?.payload])

    const onHandleSelectPermission = useCallback((option) => {
        if (option?.value === addLmsArticleInstructorDetail?.payload?.permission) return;

        dispatcher(setAddLmsArticleInstructorDetailPayload({
            ...addLmsArticleInstructorDetail?.payload,
            permission: option?.value
        }))
    }, [addLmsArticleInstructorDetail?.payload])

    const onHandleSubmit = useCallback(() => {
        if (addLmsArticleInstructorDetail?.isLoading || modifyLmsArticleInstructorDetail?.isLoading) return;

        if (!addLmsArticleInstructorDetail?.payload?.userId) {
            toast.warn("Please select instructor!")
            return;
        }
        if (!addLmsArticleInstructorDetail?.payload?.permission) {
            toast.warn("Please select permission")
            return;
        }
        if (addLmsArticleInstructorDetail?.payload?.lessonInstructorId) {
            const body = {
                user_id: addLmsArticleInstructorDetail?.payload?.userId,
                permission: addLmsArticleInstructorDetail?.payload?.permission
            }

            dispatcher(updateLmsArticleInstructorDetail(addLmsArticleInstructorDetail?.payload?.lessonInstructorId, body))
        } else {
            const body = {
                article_id: lmsArticleDetail?.data?.article_id,
                user_id: addLmsArticleInstructorDetail?.payload?.userId,
                permission: addLmsArticleInstructorDetail?.payload?.permission
            }
            dispatcher(createLmsArticleInstructorDetail(body))
        }

    }, [addLmsArticleInstructorDetail?.payload])

    return (
        <div className={"space-y-5"}>
            <div className={'min-h-[16rem] flex flex-col gap-5 md:gap-10'}>

                <div className={"w-full flex flex-col gap-1"}>
                    <span className={"font-bodyPri font-medium text-text-900 text-md"}>
                        {"Select Instructor:"}
                    </span>
                    <SelectSearch
                        options={tutorList?.filteredTutorList?.results?.map((tutor) => ({
                            name: tutor?.userId + ". " + tutor?.firstName + " " + tutor?.lastName.charAt(0) + ".",
                            value: tutor?.userId
                        })) || []}
                        search
                        filterOptions={fuzzySearch}
                        autoFocus={false}
                        value={addLmsArticleInstructorDetail?.payload?.userId}
                        placeholder={"Select Instructor"}
                        onChange={onHandleSelectInstructor}
                        debounce={1000}
                        getOptions={onHandleGetOptions}
                        emptyMessage={() => (
                            <div className={"w-full bg-white shadow-sm"}>
                                {tutorList?.isLoading &&
                                    <ComponentLoader isLoading={tutorList?.isLoading} />
                                }
                                {(tutorList?.filteredTutorList && (tutorList?.filteredTutorList?.records === 0) || tutorList?.errorMsg) &&
                                    <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                                        {"No tutors found!"}
                                    </span>
                                }
                            </div>
                        )}
                        renderValue={(valueProps) => (
                            <input
                                className={cn(
                                    "w-full px-2 py-1 bg-white border border-divider-lightDark shadow rounded-lg focus:outline-none",
                                    "focus:border focus:border-primary-main placeholder:text-text-500",
                                    "font-bodyPri font-normal text-text-800 text-base tracking-wide"
                                )}
                                autoFocus={false}
                                value={searchText}
                                {...valueProps}
                            />
                        )}
                    />
                </div>
                <div className={"w-full flex flex-col gap-1"}>
                    <span className={"font-bodyPri font-medium text-text-900 text-md"}>
                        {"Select Permission:"}
                    </span>
                    <OptionSelector
                        options={Object.values(lmsArticleInstructorPermissionEnum)}
                        className={""}
                        value={addLmsArticleInstructorDetail?.payload?.permission}
                        onChange={onHandleSelectPermission}
                    />
                </div>
            </div>

            <div className={"w-full flex items-center justify-center"}>
                {(addLmsArticleInstructorDetail?.message || modifyLmsArticleInstructorDetail?.message) &&
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide text-center"}>
                        {addLmsArticleInstructorDetail?.message || modifyLmsArticleInstructorDetail?.message}
                    </span>
                }
            </div>

            <div className={"flex justify-end"}>
                <span
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base text-secondary-main",
                        (!addLmsArticleInstructorDetail?.isLoading && !modifyLmsArticleInstructorDetail?.isLoading) && "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                        (addLmsArticleInstructorDetail?.isLoading || modifyLmsArticleInstructorDetail?.isLoading) && "!bg-secondary-main",
                        (!addLmsArticleInstructorDetail?.payload?.userId || !addLmsArticleInstructorDetail?.payload?.permission) && "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light"
                    )}
                    onClick={onHandleSubmit}
                >
                    {(addLmsArticleInstructorDetail?.isLoading || modifyLmsArticleInstructorDetail?.isLoading) &&
                        <ButtonLoader isLoading={addLmsArticleInstructorDetail?.isLoading || modifyLmsArticleInstructorDetail?.isLoading} />
                    }
                    {(!addLmsArticleInstructorDetail?.isLoading && !modifyLmsArticleInstructorDetail?.isLoading) && "Submit"}
                </span>
            </div>
        </div>
    )
}

export default AddLmsArticleInstructorModal;