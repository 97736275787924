import { memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { BsChatLeft } from 'react-icons/bs';

import { getFBChat, getFBChatId } from 'redux/firebase/firebase.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setMessageTemplate } from 'redux/firebase/firebase.slice';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';
import { userRoles } from 'redux/auth/auth.const';
import { modalConst } from 'redux/local/local.const';
import { bookingRegularPriceModelEnum } from 'redux/booking/booking.const';

import { pagesInfo } from 'utils/pagesInfo';
import { currencyRateConversion } from 'utils/generators.utils';

const AppointmentProfile = memo(({ newBookingDetail }) => {
    const { locals, modal } = useAppState((state) => state.local)
    const { session } = useAppState((state) => state.auth)
    const { user } = useAppState((state) => state.user)
    const { messageTemplate } = useAppState((s) => s.firebase)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const userIdOne = useMemo(() => user?.user?.userId, [user?.user?.userId])

    let userIdTwo = 0
    if (locals.userRole === userRoles.STUDENT.value) {
        userIdTwo = newBookingDetail?.data?.result?.course?.tutor?.user?.id
    } else if (locals.userRole === userRoles.TUTOR.value) {
        userIdTwo = newBookingDetail?.data?.result?.user?.id
    }

    const handleSendMessage = async () => {
        if (!session?.isLoggedIn) {
            navigate(`${pagesInfo.LOG_IN.pagePath}?redirect_url=${window.location.href}`)
            return;
        }

        if (userIdOne === userIdTwo) {
            alert("Something went wrong!")
            return;
        }

        const chatId = await getFBChatId(userIdOne, userIdTwo)
        let fbChat = await getFBChat(chatId)

        if (!fbChat || fbChat === undefined) {
            dispatcher(setMessageTemplate({
                ...messageTemplate,
                isLoading: false,
                userIdOne: userIdOne,
                userIdTwo: userIdTwo,
                message: '',
                errorMsg: null
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: true
            }))
            return;
        }

        navigate(`${pagesInfo.MESSAGES.pagePath}/${fbChat.chatId}`)
    }

    return (
        <div className={"px-2 rounded-lg"}>
            <div className={"flex justify-between items-center text-slate-500 text-sm md:text-base"}>
                <span className={"font-bodyPri tracking-wide text-text-800"}>
                    {"Booking ID :"} {newBookingDetail?.data?.result?.id}
                </span>
                <div className={"flex items-center gap-1 font-bodyPri tracking-wide text-text-800"}>
                    <span className={"font-bodyPri font-normal"}>
                        {"Price : "}
                    </span>
                    <div className={"flex flex-col gap-0.5"}>
                        {((newBookingDetail?.data?.result?.bookingPrice?.discount_pct === 100)
                            || (newBookingDetail?.data?.result?.bookingPrice?.price_model === bookingRegularPriceModelEnum.FREE.value))
                            ? <span className={"font-bodyPri font-normal"}>
                                {bookingRegularPriceModelEnum.FREE.label}
                            </span>
                            : <span className={"font-bodyPri font-normal"}>
                                {`${newBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code} ${parseFloat(newBookingDetail?.data?.result?.bookingPrice?.totalAmount / 100).toFixed(2)}`}
                            </span>
                        }
                    </div>
                </div>
            </div>
            {([masterCurrencyCodeEnum.USD.value]?.includes(newBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code) && (locals.currencyCode !== masterCurrencyCodeEnum.USD.value)) &&
                <div className={"w-full flex justify-end"}>
                    <span className={"font-bodyPri font-normal text-xs"}>
                        {`(Approx. ${(currencyRateConversion(locals?.currencyCode, newBookingDetail?.data?.result?.bookingPrice?.totalAmount))})`}
                    </span>
                </div>
            }
            <div className="flex flex-col items-center justify-between sm:flex-row">

                {/* Profile */}
                <div className="flex flex-col items-center justify-center my-4 space-y-1 sm:flex-row sm:justify-center sm:items-center">
                    <div className={"w-14 h-14 overflow-hidden rounded-full"}>
                        {(locals?.userRole === userRoles?.STUDENT?.value) &&
                            <img
                                src={newBookingDetail?.data?.result?.appointment?.user?.profilePicUrl}
                                alt={"profile"}
                                className={"w-full h-full object-cover"}
                            />
                        }
                        {(locals?.userRole === userRoles?.TUTOR?.value) &&
                            <img
                                src={newBookingDetail?.data?.result?.user?.profilePicUrl}
                                alt={"profile"}
                                className={"w-full h-full object-cover"}
                            />
                        }
                    </div>
                    <div className="pl-2">
                        {(locals?.userRole === userRoles?.STUDENT?.value) &&
                            <p className="text-lg font-semibold font-bodyPri tracking-wide text-text-900 capitalize">
                                {newBookingDetail?.data?.result?.appointment?.user?.firstName + " " + newBookingDetail?.data?.result?.appointment?.user?.lastName.charAt(0) + "."}
                            </p>
                        }

                        {(locals?.userRole === userRoles?.TUTOR?.value) &&
                            <p className="text-lg font-semibold font-bodyPri tracking-wide text-text-900 capitalize">
                                {newBookingDetail?.data?.result?.user?.firstName + " " + newBookingDetail?.data?.result?.user?.lastName.charAt(0) + "."}
                            </p>
                        }

                        <p className="text-sm text-center text-opacity-40 md:text-left">
                            {(locals.userRole === userRoles.TUTOR.value)
                                ? userRoles?.STUDENT?.label
                                : userRoles?.TUTOR?.label
                            }
                        </p>
                    </div>
                </div>

                {/* Actions */}
                <div className="inline-flex flex-end items-center space-x-2">
                    <div>
                        <BsChatLeft size={15} />
                    </div>
                    <div>
                        <button onClick={() => handleSendMessage()} className="px-3 py-1 font-semibold bg-primary-light rounded-lg text-sky-500 font-bodyPri tracking-wide">
                            {"Send Message"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default AppointmentProfile;