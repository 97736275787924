export const commentTemplateList = [
    {
        message: "My requirements have changed."
    },
    {
        message: "Instructor asked me to cancel."
    },
    {
        message: "I found another session."
    },
    {
        message: "Another reason."
    },
]