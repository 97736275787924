import { useState } from 'react';
import { cn } from "utils/cn.utils";

import {
    TaskItemContainer,
    TaskDetails,
    TaskTitleDesc,
    TaskTitleText,
    TaskDescText,
    TaskAction,
    Submitted,
    UnderReview,
    Verified,
    ArrowToggleIcon,
    TaskTime,
    VerifiedIcon,
    SubmittedIcon,
    Rejected,
    RejectedIcon,
    DisabledButton,
    Pending,
    PendingIcon
} from 'pages/auth/tutorOnboard/TutorOnboardStyle';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { modalConst } from 'redux/local/local.const';
import { relevantQuestionStatus } from "redux/onboarding/onboarding.const";
import { setModal } from 'redux/local/local.slice';

const RelevantQuestions = () => {
    const { modal } = useAppState((state) => state.local)
    const { userOnboardingDetail, addUserOnboardingQuestionDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()

    const [clickedItemIndex] = useState(null);

    // useEffect(() => {
    //     if (addUserOnboardingQuestionDetail?.addUserOnboardingQuestionDetail) {
    //         dispatcher(setModal({
    //             ...modal,
    //             [modalConst.QUESTION_ANSWER_MODAL.stateKey]: false
    //         }))
    //     }
    //     dispatcher(setClearUserOnboardingQuestionDetail())
    // }, [addUserOnboardingQuestionDetail?.addUserOnboardingQuestionDetail])

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const isOpen = clickedItemIndex === 1;

    const isEmailMobileVerified = userOnboardingDetail?.userOnboardingDetail?.user?.isEmailVerified && userOnboardingDetail?.userOnboardingDetail?.user?.isMobileNoVerified

    const handleTaskItems = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.QUESTION_ANSWER_MODAL.stateKey]: true
        }))
    }

    return (
        <TaskItemContainer>
            <TaskDetails>
                <span className={cn(
                    "px-2.5 py-0.5 h-auto self-start rounded-full font-bodyPri font-medium text-lg",
                    (!isEmailMobileVerified || !userOnboardingDetail?.userOnboardingDetail?.onboardingQuestion?.status) && "border-2 border-text-500 text-text-900 bg-white",
                    (isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingQuestion?.status) && `text-text-50 bg-${relevantQuestionStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingQuestion?.status?.toUpperCase()]?.darkColor}`
                )}>
                    {"3"}
                </span>
                <TaskTitleDesc>
                    <TaskTitleText>
                        {"Answer a few relevant questions"}
                    </TaskTitleText>
                    <TaskDescText>
                        {"Answer the questions to help us learn more about your profile."}
                    </TaskDescText>
                </TaskTitleDesc>
            </TaskDetails>
            <TaskAction>
                {(!isEmailMobileVerified || (isEmailMobileVerified && !userOnboardingDetail?.userOnboardingDetail?.onboardingQuestion?.status)) &&
                    <DisabledButton>
                        {"Show Questions"}
                    </DisabledButton>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingQuestion?.status === relevantQuestionStatus?.PENDING?.value) &&
                    <Pending onClick={handleTaskItems}>
                        <PendingIcon />
                        {relevantQuestionStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingQuestion?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Pending>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingQuestion?.status === relevantQuestionStatus?.SUBMITTED?.value) &&
                    <Submitted onClick={handleTaskItems}>
                        <SubmittedIcon />
                        {relevantQuestionStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingQuestion?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Submitted>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingQuestion?.status === relevantQuestionStatus?.UNDER_REVIEW?.value) &&
                    <UnderReview onClick={handleTaskItems}>
                        <SubmittedIcon />
                        {relevantQuestionStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingQuestion?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </UnderReview>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingQuestion?.status === relevantQuestionStatus?.APPROVED?.value) &&
                    <Verified onClick={handleTaskItems}>
                        <VerifiedIcon />
                        {relevantQuestionStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingQuestion?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Verified>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingQuestion?.status === relevantQuestionStatus?.REJECTED?.value) &&
                    <Rejected>
                        <RejectedIcon />
                        {relevantQuestionStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingQuestion?.status?.toUpperCase()]?.label}
                    </Rejected>
                }
                <TaskTime>{"1 min"}</TaskTime>
            </TaskAction>
        </TaskItemContainer>
    )
}

export default RelevantQuestions;