import { useEffect, useState, memo } from 'react';
import { cn } from "utils/cn.utils";

import { useAppState } from 'hooks/useStore';
import { sessionStatusEnum } from 'redux/session/session.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';

const SessionDetailCountDown = memo(({ sessionUserInfo }) => {
    const { sessionUserDetail } = useAppState(s => s.session)

    const [isSessionLinkActive, setIsSessionLinkActive] = useState(false)
    const [isSessionStarted, setIsSessionStarted] = useState(false)
    const [isSessionEnded, setIsSessionEnded] = useState(false)
    const [isMeetingLinkCopied, setIsMeetingLinkCopied] = useState(false)
    const [countDownTimer, setCountDownTimer] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    })

    useEffect(() => {
        let interval
        if ((sessionUserDetail?.data?.result && sessionUserInfo?.status) && [sessionStatusEnum.SCHEDULED.value, sessionStatusEnum.ON_GOING.value, sessionStatusEnum.COMPLETED.value].includes(sessionUserInfo?.status)) {
            const nowTimeStamp = parseInt(dayjs().tz(timeZone).valueOf() / 1000)
            const startTimeStamp = parseInt(dayjs(sessionUserDetail?.data?.result?.startDateTime).tz(timeZone).valueOf() / 1000)
            const endTimeStamp = parseInt(dayjs(sessionUserDetail?.data?.result?.endDateTime).tz(timeZone).valueOf() / 1000)
            const deltaTimeStampToActiveLink = startTimeStamp - (nowTimeStamp + 600)
            const deltaTimeStampToStart = startTimeStamp - nowTimeStamp
            const deltaTimeStampToEnd = endTimeStamp - nowTimeStamp
            setIsSessionLinkActive(deltaTimeStampToActiveLink < 0)
            setIsSessionStarted(deltaTimeStampToStart < 0)
            setIsSessionEnded(deltaTimeStampToEnd < 0)

            interval = setInterval(() => {
                if (!isSessionStarted) {
                    setCountDownTimer({
                        ...countDownTimer,
                        seconds: parseInt(deltaTimeStampToStart) % 60,
                        minutes: parseInt(deltaTimeStampToStart / 60) % 60,
                        hours: parseInt(deltaTimeStampToStart / (60 * 60)) % 24,
                        days: parseInt(deltaTimeStampToStart / (60 * 60 * 24)),
                    })
                }
            }, 1000)
        }

        return () => {
            clearInterval(interval)
        }
    })

    const handleCopyMeetingUrl = () => {
        if (isMeetingLinkCopied || !isSessionLinkActive || isSessionEnded) return;

        navigator.clipboard.writeText(sessionUserDetail?.data?.result?.meeting?.meetingUrl)
        setIsMeetingLinkCopied(true)
        setTimeout(() => {
            setIsMeetingLinkCopied(false)
        }, 1000)
    }

    const handleRedirectMeeting = () => {
        if (!isSessionLinkActive || isSessionEnded) return;

        window.open(sessionUserDetail?.data?.result?.meeting?.meetingUrl, '_blank')
    }

    return (sessionUserDetail?.data?.result && sessionUserInfo?.status) &&
        [sessionStatusEnum.SCHEDULED.value, sessionStatusEnum.ON_GOING.value].includes(sessionUserInfo?.status) ? (
        <div className={"space-y-5"}>
            <div className={"flex flex-col items-center justify-center gap-2"}>
                <div className={"font-bodyPri font-medium text-base text-text-900"}>
                    {isSessionEnded
                        ? "Meeting Ended"
                        : isSessionStarted
                            ? "Meeting Started"
                            : "Meeting Starts In"
                    }
                </div>
                <div className={"w-full flex items-center justify-evenly gap-2"}>
                    {Object.keys(countDownTimer).map((key, index) => (
                        <span key={index} className={cn(
                            "flex flex-col items-center justify-center w-16 py-2",
                            "rounded-lg border-2 border-divider-medium shadow"
                        )}>
                            <span className={"font-bodyPri font-semibold text-lg text-primary-dark"}>
                                {String(countDownTimer[key]).padStart(2, '0')}
                            </span>
                            <span className={"font-bodyPri font-normal text-xs text-text-900 capitalize"}>
                                {key}
                            </span>
                        </span>
                    ))}
                </div>
            </div>
            {/* <div className={"flex items-center justify-between gap-2"}>
                <div onClick={handleCopyMeetingUrl} className={cn(
                    "flex items-center justify-center gap-1 text-text-700 whitespace-nowrap",
                    "border border-text-500 w-28 py-0.5 rounded-md",
                    (!isSessionLinkActive || isSessionEnded)
                        ? "!text-text-400 border-text-200"
                        : "hover:!text-text-50 hover:!border-text-500 hover:!bg-text-500 cursor-pointer"
                )}>
                    <span className={"font-buttons font-normal text-base"}>
                        {isMeetingLinkCopied ? "Copied" : "Copy Link"}
                    </span>
                    {!isMeetingLinkCopied && <MdOutlineContentCopy className={"text-base"} />}
                </div>
                <div onClick={handleRedirectMeeting} className={cn(
                    "flex items-center justify-center gap-1 text-secondary-main whitespace-nowrap",
                    "border border-secondary-main w-28 py-0.5 rounded-md",
                    (!isSessionLinkActive || isSessionEnded)
                        ? "!text-text-400 border-text-200"
                        : "hover:!text-text-50 hover:!border-secondary-main hover:!bg-secondary-main cursor-pointer"
                )}>
                    <span className={"font-buttons font-normal text-base"}>
                        {"Join Meeting"}
                    </span>
                </div>
            </div> */}
        </div>
    ) : null
})

export default SessionDetailCountDown