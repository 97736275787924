import { cn } from "utils/cn.utils";

import { MdGroups } from "react-icons/md";
import { IoMdPeople } from "react-icons/io";
import { BsInfoCircle } from "react-icons/bs";

import VideoSelector from "pages/auth/createCoursePreview/Components/VideoSelector";

import { useAppState } from "hooks/useStore";
import { courseType, coursePricingModelTypeEnum, coursePriceTypeEnum } from "redux/course/course.const";

import { dayjs } from "utils/dateTime.utils";
import { currencyRateConversion } from "utils/generators.utils";

const HorizontalStickyCard = () => {
    const { locals } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { coursePreviewDetail, userCourseList } = useAppState((state) => state.course)

    // const DISCOUNTED_PRICE = (coursePreviewDetail?.data?.price * ((100 - coursePreviewDetail?.data?.discountPct) / 100))

    const isMyOffering = user?.user?.userId === coursePreviewDetail?.data?.tutor?.user?.id
    const isMyCourse = userCourseList?.data?.results?.filter((course) => course?.type === courseType?.GROUP.value)
        ?.filter((myCourse) => myCourse?.id === coursePreviewDetail?.data?.id)?.length > 0

    return (
        <div className={"w-full p-5 bg-white space-y-3"}>
            <div className={"flex justify-center items-center"}>
                <VideoSelector />
            </div>
            <div className={"p-3 z-[1000] space-y-3"}>

                {/* regular price Details */}
                {(coursePreviewDetail?.data?.coursePrice?.type === coursePriceTypeEnum?.REGULAR?.value) &&
                    <div className="flex items-center gap-2 w-full">
                        <div className='flex justify-center items-center gap-2'>
                            <span className='text-2xl text-text-900'>
                                {currencyRateConversion(locals?.currencyCode, coursePreviewDetail?.data?.basicDetail?.discountedPrice)}
                            </span>
                        </div>
                        {coursePreviewDetail?.data?.coursePrice?.discountPct > 0 &&
                            <div className='flex flex-col'>
                                <span className='text-text-900 font-semibold'>
                                    <del className='text-text-600'>
                                        {currencyRateConversion(locals?.currencyCode, coursePreviewDetail?.data?.coursePrice?.price)}
                                    </del>
                                    {" " + coursePreviewDetail?.data?.coursePrice?.discountPct + "% off"}
                                </span>
                                <span className="text-text-900 font-medium font-bodyPri">
                                    {coursePricingModelTypeEnum[coursePreviewDetail?.data?.coursePrice?.priceModel?.toUpperCase()]?.label}
                                </span>
                            </div>
                        }
                        {(!coursePreviewDetail?.data?.coursePrice?.discountPct || coursePreviewDetail?.data?.coursePrice?.discountPct === 0) &&
                            <span className="text-text-900 font-medium font-bodyPri">
                                {coursePricingModelTypeEnum[coursePreviewDetail?.data?.coursePrice?.priceModel?.toUpperCase()]?.label}
                            </span>
                        }
                    </div>
                }

                {/* plan price Details */}
                {(coursePreviewDetail?.data?.coursePrice?.type === coursePriceTypeEnum?.PLAN?.value) &&
                    <div className="flex items-center gap-2 w-full">
                        <div className='flex justify-center items-center gap-2'>
                            <span className='text-2xl text-text-900'>
                                {currencyRateConversion(locals?.currencyCode, coursePreviewDetail?.data?.coursePrice?.itemPrices[0]?.price)}
                            </span>
                            <span className="text-text-900 font-medium font-bodyPri">
                                {coursePricingModelTypeEnum[coursePreviewDetail?.data?.coursePrice?.itemPrices[0]?.pricing_model?.toUpperCase()]?.label}
                            </span>
                        </div>
                        {/* <div className='flex flex-col'>
                            <span className='text-text-900 font-semibold'>
                                <del className='text-text-600'>
                                    {currencyRateConversion(locals?.currencyCode, coursePreviewDetail?.data?.coursePrice?.itemPrices?.price)}
                                </del>
                                {" " + coursePreviewDetail?.data?.coursePrice?.discountPct + "% off"}
                            </span>
                            <span className="text-text-900 font-medium font-bodyPri">
                                {coursePricingModelTypeEnum[coursePreviewDetail?.data?.coursePrice?.itemPrices?.data[0]?.pricing_model?.toUpperCase()]?.label}
                            </span>
                        </div> */}
                        {/* {(!coursePreviewDetail?.data?.coursePrice?.discountPct || coursePreviewDetail?.data?.coursePrice?.discountPct === 0) &&
                            <span className="text-text-900 font-medium font-bodyPri">
                                {coursePricingModelTypeEnum[coursePreviewDetail?.data?.coursePrice?.priceModel?.toUpperCase()]?.label}
                            </span>
                        } */}
                    </div>
                }

                <div className='flex items-center w-full justify-evenly'>
                    <span className={cn(
                        'w-2/3 py-1 flex justify-center items-center gap-1 rounded-full font-normal font-buttons border',
                        isMyOffering
                            ? "border-text-300 text-text-300"
                            : isMyCourse
                                ? "border-text-500 text-text-700"
                                : "border-secondary-main text-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer"
                    )}>
                        {isMyCourse && <BsInfoCircle className={"text-primary-main text-base"} />}
                        <span className={""}>
                            {isMyCourse
                                ? "Course Purchased"
                                : (coursePreviewDetail?.data?.coursePrice?.type === coursePriceTypeEnum?.REGULAR?.value)
                                    ? "Book Now"
                                    : "Subscribe"
                            }
                        </span>
                    </span>
                </div>

                <div className='flex flex-col space-y-2'>

                    {/* Course Type */}
                    {(coursePreviewDetail?.data?.type === courseType.ONE_ON_ONE.value) &&
                        <div className='flex justify-start gap-3'>
                            <IoMdPeople className="text-gray-700 text-xl" />
                            <span className="text-text-700 font-normal font-bodyPri">
                                {"Class Type:"}
                            </span>
                            <span className='text-text-900 font-medium font-bodyPri'>
                                {courseType.ONE_ON_ONE.label}
                            </span>
                        </div>
                    }
                    {(coursePreviewDetail?.data?.type === courseType.GROUP.value) &&
                        <div className='flex justify-start gap-3'>
                            <MdGroups className="text-gray-700 text-xl" />
                            <span className="text-text-700 font-normal font-bodyPri">
                                {"Class Type:"}
                            </span>
                            <span className='text-text-900 font-medium font-bodyPri'>
                                {courseType.GROUP.label}
                            </span>
                        </div>
                    }

                    {(coursePreviewDetail?.data?.type === courseType?.GROUP?.value) &&
                        <div className={'flex flex-col justify-center items-center p-2 bg-yellow-300 font-bold text-text-900'}>
                            <span className={""}>
                                {"Enroll Now"}
                            </span>
                            <span>{"Starts on " + dayjs(coursePreviewDetail?.data?.startDateTime).format("DD MMMM, YYYY")}</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default HorizontalStickyCard;