import { useCallback } from 'react';
import { Link } from 'react-router-dom';
// import { cn } from "utils/cn.utils";

// import { MdAdd } from 'react-icons/md';

import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';

import LMSTableComponent from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTableComponent';
import { questionInstructorHeaderConst, questionInstructorMenuItemConst } from '../../../../editLMSQuestion.data';

import { deleteLmsQuestionInstructorDetail, updateLmsQuestionDetail, updateLmsQuestionInstructorDetail } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import {
    resetAddLmsQuestionInstructorDetail,
    resetAddLmsQuestionInstructorDetailPayload,
    resetModifyLmsQuestionInstructorDetail
} from 'redux/edulyteLms/lmsQuestion/lmsQuestion.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsQuestionInstructorPermissionEnum } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.const';

import { pagesInfo } from 'utils/pagesInfo';
import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

const QuestionInstructor = () => {
    const { modal } = useAppState((state) => state.local)
    const { lmsQuestionDetail } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()

    const onHandleAddInstructor = useCallback(() => {
        dispatcher(resetAddLmsQuestionInstructorDetail())
        dispatcher(resetModifyLmsQuestionInstructorDetail())
        dispatcher(resetAddLmsQuestionInstructorDetailPayload())
        dispatcher(setModal({
            ...modal,
            [modalConst.addLmsQuestionInstructorModal.key]: {
                ...modal[modalConst.addLmsQuestionInstructorModal.key],
                isVisible: true,
                title: "Add Instructor"
            }
        }))
    }, [modal])

    const sortQuestionInstructor = (instructor1, instructor2) => {
        if (instructor1?.id === lmsQuestionDetail?.data?.question_setting?.default_owner?.id) {
            return -1;
        } else if (instructor2?.id === lmsQuestionDetail?.data?.question_setting?.default_owner?.id) {
            return 1;
        } else {
            return instructor1 - instructor2;
        }
    }

    const onHandleSelectMenuItem = (menuItem, questionInstructorItem) => {
        switch (menuItem.value) {
            case questionInstructorMenuItemConst?.MAKE_DEFAULT?.value:
                dispatcher(updateLmsQuestionDetail(lmsQuestionDetail?.data?.id, { default_owner_id: questionInstructorItem?.id }))
                break;
            case questionInstructorMenuItemConst?.VIEWER?.value:
                if (questionInstructorMenuItemConst?.VIEWER?.value === questionInstructorItem?.permission) return;
                dispatcher(updateLmsQuestionInstructorDetail(questionInstructorItem?.id, { user_id: questionInstructorItem?.tutor?.user?.userId, permission: questionInstructorMenuItemConst?.VIEWER?.value }))
                break;
            case questionInstructorMenuItemConst?.EDITOR?.value:
                if (questionInstructorMenuItemConst?.EDITOR?.value === questionInstructorItem?.permission) return;
                dispatcher(updateLmsQuestionInstructorDetail(questionInstructorItem?.id, { user_id: questionInstructorItem?.tutor?.user?.userId, permission: questionInstructorMenuItemConst?.EDITOR?.value }))
                break;
            case questionInstructorMenuItemConst?.OWNER?.value:
                if (questionInstructorMenuItemConst?.OWNER?.value === questionInstructorItem?.permission) return;
                dispatcher(updateLmsQuestionInstructorDetail(questionInstructorItem?.id, { user_id: questionInstructorItem?.tutor?.user?.userId, permission: questionInstructorMenuItemConst?.OWNER?.value }))
                break;
            case questionInstructorMenuItemConst?.DELETE?.value:
                dispatcher(deleteLmsQuestionInstructorDetail(questionInstructorItem?.id))
                break;

            default:
                break;
        }
    }

    const QuestionTutorProfileImageContainer = ({ questionInstructor }) => {
        return (
            <div className={"flex items-center justify-center"}>
                <div className={'relative w-12 h-12 rounded-full'}>
                    <Link to={`${pagesInfo.TUTOR.pagePath}/${questionInstructor?.tutor?.user?.userId}`}>
                        <img
                            src={questionInstructor?.tutor?.user?.profile_pic_url}
                            alt={"profile-pic"}
                            className={"w-full h-full rounded-full object-cover"}
                        />
                    </Link>
                </div>
            </div>
        )
    }

    const QuestionTutorNameContainer = ({ questionInstructor }) => {
        return (
            <div className={'flex items-center justify-center gap-1'}>
                <a
                    href={`${EDULYTE_APP_DOMAIN_URL}${pagesInfo.TUTOR.pagePath}/${questionInstructor?.tutor?.user?.userId}`}
                    target={"_blank"}
                    className={"w-fit"}
                >
                    <span className={'font-bodyPri font-normal text-base tracking-wide truncate capitalize'}>
                        {questionInstructor?.tutor?.user?.first_name} {questionInstructor?.tutor?.user?.last_name.charAt(0) + "."}
                    </span>
                </a>
                {(lmsQuestionDetail?.data?.question_setting?.default_owner?.id === questionInstructor?.id) &&
                    <span className={"font-bodyPri font-medium text-green-500 text-sm"}>
                        {"(Primary)"}
                    </span>
                }
            </div>
        )
    }

    const questionInstructorRows = lmsQuestionDetail?.data?.question_tutors && lmsQuestionDetail?.data?.question_tutors?.slice(0)?.sort(sortQuestionInstructor)?.map((questionInstructor) => [
        <QuestionTutorProfileImageContainer questionInstructor={questionInstructor} />,
        <QuestionTutorNameContainer questionInstructor={questionInstructor} />,
        lmsQuestionInstructorPermissionEnum[questionInstructor?.permission?.toUpperCase()]?.label,
        (lmsQuestionDetail?.data?.question_setting?.default_owner?.id != questionInstructor?.id)
            ? <div className={"w-full flex items-center justify-center"}>
                <ThreeDotMenu
                    menuItems={Object.values(questionInstructorMenuItemConst)}
                    onHandleSelect={(menuItem) => onHandleSelectMenuItem(menuItem, questionInstructor)}
                />
            </div>
            : "-"
    ])

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <div className={"w-full flex items-center justify-between gap-3"}>
                <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                    {"Instructor(s)"}
                </span>
                {/* <div
                    className={cn(
                        "flex items-center justify-center gap-1 py-1 px-3",
                        "border border-primary-dark text-primary-dark rounded-lg cursor-pointer hover:bg-primary-dark group"
                    )}
                    onClick={onHandleAddInstructor}
                >
                    <MdAdd className={"text-lg group-hover:text-text-50"} />
                    <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                        {"Add Instructor"}
                    </span>
                </div> */}
            </div>
            <div className={"w-full px-5"}>
                <LMSTableComponent
                    headers={Object.values(questionInstructorHeaderConst)}
                    subRowHeader={[]}
                    rows={(lmsQuestionDetail?.data?.question_tutors?.length > 0) ? questionInstructorRows : []}
                    rowHeaderColor={"bg-background-medium"}
                    alternateRowColor={"bg-primary-light"}
                />
            </div>
        </div>
    )
}

export default QuestionInstructor;