import { Section, SectionContent } from 'components/generalComponent/StyledComponent';
import tw, { styled } from 'twin.macro';

export const Container = tw(Section)`
    bg-background-light
`;

export const Content = styled(SectionContent)`
    ${tw`
        grid-cols-1 md:grid-cols-2 items-center justify-items-center overflow-hidden
    `}
    img {
        ${tw`
            max-w-[18rem] w-full bg-cover bg-center
        `}
    }
`;

export const ListContainer = styled.div`
    ${tw`
        flex flex-col gap-5
    `}
    ul {
        ${tw`
            list-disc list-inside
        `}
        li {
            ${tw`
                font-bulletPoints font-medium text-base lg:text-xl text-text-700    
            `}
        }
    }
`;

export const ListHeading = tw.span`
    font-subHeading font-semibold text-2xl md:text-xl lg:text-3xl text-text-900
`;