import { cn } from "utils/cn.utils";

import { FaCalendarAlt } from "react-icons/fa";

import { getAvailableSlotDetail } from "redux/availability/availability.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetAddSessionUserDetailPayload, setAddSessionUserDetailPayload } from "redux/session/session.slice";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

import { timeZone } from "utils/dateTime.utils";

const minCharacterLength = 20
const maxCharacterLength = 100

const RescheduleComment = ({ setStepCount }) => {
    const { modal } = useAppState((state) => state.local)
    const { addSessionUserDetailPayload } = useAppState((state) => state.session)

    const dispatcher = useAppDispatcher()

    const onHandleNeverMind = () => {
        dispatcher(resetAddSessionUserDetailPayload())
        dispatcher(setModal({
            ...modal,
            [modalConst.RESCHEDULE_TIME_SLOT_MODAL.stateKey]: false,
            [modalConst.SESSION_DETAIL_MODAL.stateKey]: true
        }))
    }

    const onHandlePickNewTime = () => {
        if (addSessionUserDetailPayload?.payload?.availabilityId) {
            dispatcher(getAvailableSlotDetail(addSessionUserDetailPayload?.payload?.availabilityId, { timeZone: timeZone, duration: addSessionUserDetailPayload?.payload?.duration }))
            setStepCount(2)
        } else {
            alert("Something Went Wrong!")
        }
    }

    return (
        <div className={"w-full flex flex-col items-start justify-start gap-5"}>
            <div className={"w-full flex flex-col items-center justify-center gap-3"}>
                <FaCalendarAlt className={"text-5xl text-primary-dark"} />
                {/* <span className={"font-bodyPri font-semibold text-text-900 text-lg tracking-wide"}>
                    {"Reschedule your session"}
                </span> */}
                <span className={"font-bodyPri font-semibold text-text-900 text-md tracking-wide border-b-2 border-divider-lightDark"}>
                    {addSessionUserDetailPayload?.payload?.title}
                </span>
            </div>

            <div className={"w-full flex flex-col items-start gap-3"}>
                <div className={"w-full flex flex-col items-start justify-start gap-1"}>
                    <span className={"font-bodyPri font-semibold text-text-900 text-md tracking-wide"}>
                        {"Reason to Reschedule"}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-900 text-xs tracking-wide"}>
                        {"Please give sufficient notice with reason. This will be sent to participant(s), they must accept the new proposed time."}
                    </span>
                </div>
                <textarea
                    className={cn(
                        "w-full px-5 py-3 bg-background-light rounded-lg resize-none",
                        "outline-none border-2 border-primary-main focus:shadow-all-md focus:shadow-primary-light focus:border-primary-main",
                        "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                    )}
                    minLength={minCharacterLength}
                    maxLength={maxCharacterLength}
                    rows={4}
                    name={"todo"}
                    placeholder="Write your reason ...."
                    value={addSessionUserDetailPayload?.payload?.note}
                    onChange={(event) => dispatcher(setAddSessionUserDetailPayload({
                        ...addSessionUserDetailPayload?.payload,
                        note: event?.target?.value
                    }))}
                />
                <div className={"w-full flex justify-between"}>
                    <span className={cn("font-bodyPri font-normal text-xs text-text-800")}>
                        {`Please enter between ${minCharacterLength} and ${maxCharacterLength} characters`}
                    </span>
                    <span className="flex justify-end font-bodyPri font-normal text-text-800 text-xs">
                        {addSessionUserDetailPayload?.payload?.note.length + "/" + maxCharacterLength}
                    </span>
                </div>

            </div>
            <div className={"w-full flex items-center justify-between mt-5"}>
                <span onClick={onHandleNeverMind} className={cn(
                    "px-3 py-1.5 flex justify-center items-center rounded-lg cursor-pointer uppercase",
                    "font-buttons font-normal text-sm text-primary-dark",
                    "border border-primary-dark hover:bg-primary-dark hover:text-text-50"
                )}>
                    {"Never Mind"}
                </span>
                <span onClick={onHandlePickNewTime} className={cn(
                    "px-3 py-1.5 flex justify-center items-center rounded-lg cursor-pointer uppercase",
                    "font-buttons font-normal text-sm text-primary-dark",
                    "border border-primary-dark hover:bg-primary-dark hover:text-text-50"
                )}>
                    {"Pick New Time"}
                </span>
            </div>
        </div>
    )
}

export default RescheduleComment