import { useNavigate, Link } from 'react-router-dom';

import { IoMdArrowRoundBack } from 'react-icons/io';
import { MdPlayLesson } from 'react-icons/md'

import ToolTipView from 'components/tooltipView';

import TopTabNavigation from 'pages/auth/edulyteLms/commonComponents/navigations/TopTabNavigation';
import RefreshButton from 'pages/auth/edulyteLms/commonComponents/lmsButtons/RefreshButton';
import PublishBtn from 'pages/auth/edulyteLms/commonComponents/lmsButtons/PublishBtn';

import { lmsArticleTabConst } from '../../data';

import { getLmsArticleDetail, updateLmsArticle } from 'redux/edulyteLms/lmsArticle/lmsArticle.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { lmsArticleStatusEnum } from 'redux/edulyteLms/lmsArticle/lmsArticle.const';

import { pagesInfo } from 'utils/pagesInfo';

const ArticleHeader = () => {
    const { lmsArticleDetail, updateLmsArticleDetail } = useAppState((state) => state.lms.lmsArticle)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const onHandleBack = () => {
        navigate(-1)
    }

    const onHandleRefreshArticle = () => {
        if (lmsArticleDetail?.isLoading) return;

        dispatcher(getLmsArticleDetail(lmsArticleDetail?.data?.article_id))
    }

    const onHandleUpdateStatus = (articleStatusType) => {
        if (lmsArticleDetail?.isLoading || updateLmsArticleDetail?.isLoading) return;

        if (articleStatusType?.value === lmsArticleStatusEnum.DRAFT.value) {
            if (!window.confirm("Are you sure?. You want to save it as draft article.")) return;
        }
        if (articleStatusType?.value === lmsArticleStatusEnum.PUBLISHED.value) {
            if (!window.confirm("Are you sure?. You want to publish this article.")) return;
        }
        if (articleStatusType?.value === lmsArticleStatusEnum.INACTIVE.value) {
            if (!window.confirm("Are you sure?. You want to deactivate this article.")) return;
        }
        dispatcher(updateLmsArticle(lmsArticleDetail?.data?.article_id, { status: articleStatusType?.value }))

    }

    return (
        <>
            <div className={"sticky top-0 z-50 h-16 bg-white flex items-center justify-between drop-shadow-sm px-5"}>
                <div className={"h-10 flex justify-center items-center gap-2"}>
                    <ToolTipView content={"Back"}>
                        <button className={"p-2 rounded-full hover:bg-divider-darkLight cursor-pointer"} onClick={onHandleBack}>
                            <IoMdArrowRoundBack
                                className={'text-background-dark'}
                                size={25}
                            />
                        </button>
                    </ToolTipView>
                    <Link to={`${pagesInfo.TUTOR_PRODUCTS.pagePath}/courses`} className={"flex items-center justify-center gap-2  group"}>
                        <MdPlayLesson
                            size={30}
                            className={"text-primary-dark group-hover:text-secondary-dark"}
                        />
                        <p className={'font-bodyPri font-normal text-lg md:text-2xl text-text-900 group-hover:text-secondary-dark group-hover:underline'}>
                            {"Article"}
                        </p>
                    </Link>
                </div>
                <div className={"hidden md:flex items-center"}>
                    <TopTabNavigation tabs={Object.values(lmsArticleTabConst)} />
                </div>
                <div className={'flex items-center justify-start gap-5'}>
                    <RefreshButton onHandleRefreshButton={onHandleRefreshArticle} />
                    <PublishBtn
                        isLoading={updateLmsArticleDetail?.isLoading}
                        loaderBtnClassName={`bg-${lmsArticleStatusEnum[lmsArticleDetail?.data?.article_setting?.status?.toUpperCase()]?.darkColor}`}
                        selectedBtnClassName={`text-white bg-${lmsArticleStatusEnum[lmsArticleDetail?.data?.article_setting?.status?.toUpperCase()]?.darkColor}`}
                        dropdownBtnClassName={`text-white bg-${lmsArticleStatusEnum[lmsArticleDetail?.data?.article_setting?.status?.toUpperCase()]?.darkColor}`}
                        optionsList={Object.values(lmsArticleStatusEnum)?.filter((statusItem) => (statusItem?.value != lmsArticleDetail?.data?.article_setting?.status))}
                        selectedOption={lmsArticleStatusEnum[lmsArticleDetail?.data?.article_setting?.status?.toUpperCase()]?.label}
                        onHandleUpdateStatus={onHandleUpdateStatus}
                    />
                </div>
            </div>
            <div className={"flex md:hidden justify-center items-center w-full rounded-lg"}>
                <TopTabNavigation tabs={Object.values(lmsArticleTabConst)} />
            </div>
        </>
    )
}

export default ArticleHeader;