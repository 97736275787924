import format from "string-format";

import { baseApiInstance, baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/appointment/appointment.const";

class Appointment {

    getPublicAppointmentList = async ({ query }) => {
        const response = await baseApiInstance().get(
            format(url.GET_PUBLIC_APPOINTMENT_LIST),
            { params: query }
        )

        return response;
    }

    getPublicUserAppointmentList = async ({ params, query }) => {
        const response = await baseApiInstance().get(
            format(url.GET_PUBLIC_USER_APPOINTMENT_LIST, params),
            { params: query }
        )

        return response;
    }

    getPublicAppointmentDetail = async ({ params }) => {
        const response = await baseApiInstance().get(
            format(url.GET_PUBLIC_APPOINTMENT_DETAIL, params)
        )

        return response;
    }

    getUserAppointmentList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_APPOINTMENT_LIST, params),
            { params: query }
        )

        return response;
    }

    getUserAppointmentDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_APPOINTMENT_DETAIL, params)
        )

        return response;
    }

    createAppointmentDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.ADD_APPOINTMENT_DETAIL),
            body
        )

        return response;
    }

    updateAppointmentDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_APPOINTMENT_DETAIL, params),
            body
        )

        return response;
    }

    deleteAppointmentDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_APPOINTMENT_DETAIL, params)
        )

        return response;
    }

    // location
    getAppointmentLocationList = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_APPOINTMENT_LOCATION_LIST, params)
        )

        return response;
    }

    createAppointmentLocationDetail = async ({params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_APPOINTMENT_LOCATION_DETAIL, params),
            body
        )

        return response;
    }

    updateAppointmentLocationDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_APPOINTMENT_LOCATION_DETAIL, params),
            body
        )

        return response;
    }

    deleteAppointmentLocationDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_APPOINTMENT_LOCATION_DETAIL, params)
        )

        return response;
    }
}

export default new Appointment()