import { addLmsQuizSectionPayload, lmsQuizSettingsPayload, addLmsQuizInstructorDetailPayload, sendLmsQuizInvitationDetailPayload } from "redux/edulyteLms/lmsQuiz/lmsQuiz.const";

export const LMS_QUIZ_INITIAL_STATE = {
    lmsQuizList: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    lmsAiQuizDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    lmsQuizDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    updateLmsQuiz: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    addLmsQuiz: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },

    addLmsQuizSection: {
        isLoading: false,
        payload: addLmsQuizSectionPayload,
        data: null,
        message: null,
    },
    lmsQuizSection: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    lmsQuizSectionList: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    updateLmsQuizSection: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    deleteLmsQuizSection: {
        isLoading: false,
        message: null,
    },
    updateOrderLmsQuizSection: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },

    addLmsQuizCategory: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    lmsQuizCategory: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    lmsQuizCategoryList: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    updateLmsQuizCategory: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    deleteLmsQuizCategory: {
        isLoading: false,
        message: null,
    },
    
    // lms quiz tag
    addLmsQuizTagDetail: {
        isLoading: false,
        message: null
    },
    modifyLmsQuizTagListOrderDetail: {
        isLoading: false,
        message: null
    },
    destroyLmsQuizTagDetail: {
        isLoading: false,
        message: null
    },

    addLmsQuizSetting: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    lmsQuizSetting: {
        isLoading: false,
        payload: lmsQuizSettingsPayload,
        data: null,
        message: null,
    },
    lmsQuizSettingList: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    updateLmsQuizSetting: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    deleteLmsQuizSetting: {
        isLoading: false,
        message: null
    },

    addLmsQuizUser: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    lmsQuizUserList: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    lmsQuizUser: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    updateLmsQuizUser: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    deleteLmsQuizUser: {
        isLoading: false,
        message: null
    },

    addLmsQuizResponse: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    lmsQuizResponseList: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    lmsQuizResponse: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    updateLmsQuizResponse: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    deleteLmsQuizResponse: {
        isLoading: false,
        message: null
    },

    addLmsQuizQuestionResponse: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    lmsQuizQuestionResponseList: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    lmsQuizQuestionResponse: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    updateLmsQuizQuestionResponse: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    deleteLmsQuizQuestionResponse: {
        isLoading: false,
        message: null
    },

    addLmsQuizQuestionResponseResource: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    lmsQuizQuestionResponseResourceList: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    lmsQuizQuestionResponseResource: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    updateLmsQuizQuestionResponseResource: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    deleteLmsQuizQuestionResponseResource: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },

    // lms quiz slug detail
    addLmsQuizSlugDetail: {
        isLoading: false,
        message: null
    },
    destroyLmsQuizSlugDetail: {
        isLoading: false,
        message: null
    },

    // lms quiz instructor detail
    addLmsQuizInstructorDetail: {
        isLoading: false,
        data: null,
        payload: addLmsQuizInstructorDetailPayload,
        message: null
    },
    modifyLmsQuizInstructorDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    destroyLmsQuizInstructorDetail: {
        isLoading: false,
        message: null
    },

    // student lms quiz list
    studentLmsQuizList: {
        isLoading: false,
        data: null,
        message: null     
    },

    // student lms quiz detail
    studentLmsQuizDetail: {
        isLoading: false,
        data: null,
        message: null     
    },

    /* lms quiz student quiz response */
    addLmsQuizStudentQuizResponse: {
        isLoading: false,
        data: null,
        message: null,
    },
    modifyLmsQuizStudentQuizResponse: {
        isLoading: false,
        message: null
    },

    // lms quiz student detail
    lmsQuizStudentList: {
        isLoading: false,
        data: null,
        message: null
    },
    addLmsQuizStudentDetail: {
        isLoading: false,
        message: null
    },
    modifyLmsQuizStudentDetail: {
        isLoading: false,
        message: null
    },
    destroyLmsQuizStudentDetail: {
        isLoading: false,
        message: null
    },

    // lms quiz invitation list
    lmsQuizInvitationList: {
        isLoading: false,
        data: null,
        message: null
    },
    sendLmsQuizInvitationDetail: {
        isLoading: false,
        data: null,
        payload: sendLmsQuizInvitationDetailPayload,
        message: null
    },
    validateLmsQuizInvitationDetail: {
        isLoading: false,
        data: null,
        message: null,
    },
    acceptAndDeclineLmsQuizInvitationDetail: {
        isLoading: false,
        data: null,
        message: null,
    },
    destroyLmsQuizInvitationDetail: {
        isLoading: false,
        message: null
    },

    // page based state
    lmsQuizPageQuestionList: {
        isLoading: false,
        data: null,
        message: null
    },
    lmsQuizPageArticleList: {
        isLoading: false,
        data: null,
        message: null
    },
    lmsQuizPageVideoResourceList: {
        isLoading: false,
        data: null,
        message: null
    },
    lmsQuizPageImageResourceList: {
        isLoading: false,
        data: null,
        message: null
    },
}