import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cn } from "utils/cn.utils";

import ComponentLoader from "components/loader/ComponentLoader";
import { OptionSelector } from 'components/common-components/Select';

import CreateCourseTooltip from "pages/auth/createCourse/CreateCourseTooltip";
import { oneOnOnePricingModelType, groupPricingModelType, oneOnOneRegularPriceTooltip, oneOnOneRegularDiscountTooltip, regularPriceModelTooltip } from "pages/auth/createCourse/data";
import SaveAndCancelBtn from "pages/auth/createCourse/SaveAndCancelBtn";

import {
    updateCourseDetail,
    createCoursePriceDetail,
    deleteCoursePriceDetail,
    getCoursePriceDetail,
    updateCoursePriceDetail
} from "redux/course/course.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { courseType, courseEndTypeEnum, coursePriceTypeEnum, coursePricingModelTypeEnum, courseIsRecurringEnum, courseStatus } from "redux/course/course.const";

import { pagesInfo } from "utils/pagesInfo";

const RegularPriceTypeContainer = ({ setIsAddCoursePriceEnabled, stepCount, setStepCount }) => {
    const { tutorCourseDetail, courseAvailDetail, coursePriceDetail, addCoursePriceDetail, addCourseDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [regularPriceType, setRegularPriceType] = useState({
        course: tutorCourseDetail?.data?.id,
        type: coursePriceTypeEnum?.REGULAR?.value,
        priceModel: null,
        sessionCount: null,
        price: 0,
        discountPct: 0
    })

    useEffect(() => {
        return () => {
            setRegularPriceType({
                course: tutorCourseDetail?.data?.id,
                type: coursePriceTypeEnum?.REGULAR?.value,
                priceModel: null,
                sessionCount: null,
                price: 0,
                discountPct: 0
            })
        }
    }, [])

    useEffect(() => {
        if (isOneOnOneCourseType) {
            setRegularPriceType({
                ...regularPriceType,
                sessionCount: 1
            })
        }
    }, [tutorCourseDetail?.data])

    useEffect(() => {
        if (coursePriceDetail?.coursePriceDetail && coursePriceDetail?.coursePriceDetail?.type === coursePriceTypeEnum?.REGULAR?.value) {
            setRegularPriceType({
                ...regularPriceType,
                priceModel: coursePriceDetail?.coursePriceDetail?.priceModel,
                sessionCount: coursePriceDetail?.coursePriceDetail?.sessionCount,
                price: coursePriceDetail?.coursePriceDetail?.price,
                discountPct: coursePriceDetail?.coursePriceDetail?.discountPct
            })
        }
    }, [coursePriceDetail?.coursePriceDetail])

    const pricingModelType = (tutorCourseDetail?.data?.type === courseType?.ONE_ON_ONE?.value) ? oneOnOnePricingModelType : groupPricingModelType
    const isGroupCourseType = tutorCourseDetail?.data?.type === courseType?.GROUP?.value
    const isOneOnOneCourseType = tutorCourseDetail?.data?.type === courseType?.ONE_ON_ONE?.value
    const isRegularPriceTypeSimilar = JSON.stringify({ type: coursePriceDetail?.coursePriceDetail?.type, priceModel: coursePriceDetail?.coursePriceDetail?.priceModel, price: coursePriceDetail?.coursePriceDetail?.price, sessionCount: coursePriceDetail?.coursePriceDetail?.sessionCount, discountPct: coursePriceDetail?.coursePriceDetail?.discountPct }) === JSON.stringify({ type: coursePriceTypeEnum?.REGULAR?.value, priceModel: regularPriceType?.priceModel, price: regularPriceType?.price, sessionCount: regularPriceType?.sessionCount, discountPct: regularPriceType?.discountPct })

    const isCourseUnderReview = tutorCourseDetail?.data?.status === courseStatus?.UNDER_REVIEW?.value
    const isCoursePublished = tutorCourseDetail?.data?.status === courseStatus?.PUBLISHED?.value

    const onHandleDeleteRegularPrice = () => {
        if (!tutorCourseDetail?.data?.coursePrice || (coursePriceDetail?.coursePriceDetail?.type === coursePriceTypeEnum?.PLAN?.value) || isCourseUnderReview || isCoursePublished) return;

        dispatcher(deleteCoursePriceDetail(coursePriceDetail?.coursePriceDetail?.id))
    }

    const onHandleCancelBtn = () => {
        if (isRegularPriceTypeSimilar) return;
        if (coursePriceDetail?.coursePriceDetail) {
            dispatcher(getCoursePriceDetail(coursePriceDetail?.coursePriceDetail?.id))
        } else {
            setIsAddCoursePriceEnabled(false)
            setRegularPriceType({
                ...regularPriceType,
                priceModel: null,
                price: null,
                sessionCount: null,
                discountPct: 0
            })
        }
    }

    const onHandleNextBtn = () => {
        if (coursePriceDetail?.isLoading || addCoursePriceDetail?.isLoading || addCourseDetail?.isLoading) return;
        if (!tutorCourseDetail?.data?.coursePrice) {
            alert("Please submit your class price!")
            return;
        }
        if (tutorCourseDetail?.data?.step <= stepCount) {
            dispatcher(updateCourseDetail(tutorCourseDetail?.data?.id, { step: tutorCourseDetail?.data?.step + 1 }))
        }
        const queryStepCount = stepCount < 5 ? stepCount + 1 : 5
        navigate(`${pagesInfo?.CREATE_COURSE?.pagePath}/${tutorCourseDetail?.data?.id}/edit?step=${queryStepCount}`)
        setStepCount(queryStepCount)
    }

    const onHandleSavePrice = () => {
        if (coursePriceDetail?.isLoading || addCoursePriceDetail?.isLoading || addCourseDetail?.isLoading || isRegularPriceTypeSimilar) return;

        if (isGroupCourseType && [courseEndTypeEnum?.ON_DATE?.value, courseEndTypeEnum?.WEEKLY_CYClE?.value]?.includes(courseAvailDetail?.courseAvailDetail?.availability?.endType) && coursePriceDetail?.coursePriceDetail?.type === coursePriceTypeEnum?.PLAN?.value) {
            alert("You need to delete plan price, if you want regular price for the class!")
            return;
        }
        if (isGroupCourseType && [courseEndTypeEnum?.FOREVER?.value]?.includes(courseAvailDetail?.courseAvailDetail?.availability?.endType) && coursePriceDetail?.coursePriceDetail?.type === coursePriceTypeEnum?.REGULAR?.value) {
            alert("Regular price cannot be set for a group class which have forever end type")
            return;
        }

        if (isGroupCourseType && courseAvailDetail?.courseAvailDetail?.availability?.isRecurring === courseIsRecurringEnum?.NON_RECURRING?.value && coursePriceDetail?.coursePriceDetail?.type === coursePriceTypeEnum?.PLAN?.value) {
            alert("You need to delete plan price, if you want regular price for the class!")
            return;
        }

        if (!regularPriceType?.priceModel) {
            toast.warn("Please select price type!")
            return;
        }
        if (isGroupCourseType && (!regularPriceType?.sessionCount || regularPriceType?.sessionCount < 0)) {
            toast.warn("Enter session count!")
            return;
        }
        if (regularPriceType?.price < 0) {
            toast.warn("Enter class price!")
            return;
        }

        if (regularPriceType?.discountPct > 100) {
            toast.warn("Please enter valid discount!")
            return;
        }

        if (!coursePriceDetail?.coursePriceDetail?.id) {
            dispatcher(createCoursePriceDetail(regularPriceType))
            return;
        }
        if (coursePriceDetail?.coursePriceDetail?.id) {
            dispatcher(updateCoursePriceDetail(coursePriceDetail?.coursePriceDetail?.id, regularPriceType))
            return;
        }
    }

    if (addCoursePriceDetail?.isLoading) {
        return (
            <div className={"w-full flex items-center justify-start"}>
                <ComponentLoader isLoading={addCoursePriceDetail?.isLoading} />
            </div>
        )
    }

    return (
        <div className={"grid grid-cols-12 gap-5"}>
            <div className={"flex items-center justify-start gap-1"}>
                <CreateCourseTooltip tooltip={regularPriceModelTooltip} translateX={0} />
                <span className="col-start-1 col-span-full md:col-start-1 md:col-span-3 font-bodyPri font-normal text-text-700 text-base tracking-wide whitespace-nowrap">
                    {"Pricing Model"}
                    <span className={"text-red-500 text-lg"}>{"*"}</span>
                </span>
            </div>
            <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-full md:w-80"}>
                <OptionSelector
                    options={Object.values(pricingModelType)}
                    className={""}
                    value={regularPriceType?.priceModel}
                    onChange={(option) => {
                        if (isCourseUnderReview || isCoursePublished) {
                            alert("You can not change pricing model for this class!")
                            return;
                        }
                        setRegularPriceType({
                            ...regularPriceType,
                            priceModel: option?.value
                        })
                    }}
                />
            </div>

            {/* no of sessions container */}
            {isGroupCourseType &&
                <span className="col-start-1 col-span-full md:col-start-1 md:col-span-3 font-bodyPri font-normal text-text-700 text-base tracking-wide whitespace-nowrap">
                    {"No of sessions"}
                    <span className={"text-red-500 text-lg"}>{"*"}</span>
                </span>
            }
            {isGroupCourseType &&
                <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-full"}>
                    <input
                        type={"number"}
                        className={cn(
                            "w-full md:w-80 px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        placeholder={"Enter session count"}
                        value={regularPriceType?.sessionCount?.toString()}
                        onChange={(event) => {
                            if (isCourseUnderReview || isCoursePublished) return;
                            setRegularPriceType({
                                ...regularPriceType,
                                sessionCount: Number(event?.target?.value)
                            })
                        }}
                        required={true}
                        disabled={isCourseUnderReview || isCoursePublished}
                    />
                </div>
            }

            {/* Price container */}
            <span className="col-start-1 col-span-full md:col-start-1 md:col-span-3">
                <div className={"flex items-center justify-start gap-1"}>
                    <CreateCourseTooltip tooltip={oneOnOneRegularPriceTooltip} translateX={0} />
                    <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide whitespace-nowrap"}>
                        {"Price"}
                        <span className={"text-red-500 text-lg"}>{"*"}</span>
                    </span>
                </div>
            </span>
            <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-full w-full md:w-80 inline-flex items-center gap-1 bg-transparent border-2 border-text-300 px-1 py-0.5 rounded-md"}>
                <span className={cn(
                    "text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide"
                )}>
                    {"USD"}
                </span>
                <input
                    autoFocus={true}
                    type="number"
                    className={cn(
                        "w-full px-1 py-1 bg-transparent input-number-spin-none focus:outline-none",
                        "text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide"
                    )}
                    placeholder={"Price..."}
                    value={(regularPriceType?.price / 100)?.toString()}
                    onChange={(e) => {
                        let safe_number = Number(e.target.value)
                        if (isNaN(safe_number)) {
                            safe_number = 0
                        }
                        if (safe_number <= 1500) {
                            setRegularPriceType({
                                ...regularPriceType,
                                price: safe_number * 100
                            })
                        }
                    }}
                />
                <p className={cn("whitespace-nowrap font-bodyPri tracking-wide")}>
                    {regularPriceType?.priceModel === coursePricingModelTypeEnum?.FLAT_FEE?.value && "flat fee"}
                    {regularPriceType?.priceModel === coursePricingModelTypeEnum?.PER_HOUR?.value && "per hour"}
                    {regularPriceType?.priceModel === coursePricingModelTypeEnum?.PER_SESSION?.value && "per session"}
                </p>
            </div>
            <span className="col-start-1 col-span-full md:col-start-1 md:col-span-3">
                <div className={"flex items-center justify-start gap-1"}>
                    <CreateCourseTooltip tooltip={oneOnOneRegularDiscountTooltip} translateX={0} />
                    <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide whitespace-nowrap"}>
                        {"Discount"}
                        <span className={"text-red-500 text-lg"}>{"*"}</span>
                    </span>
                </div>
            </span>
            <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-full w-full md:w-80 flex items-center justify-start gap-1 border-2 border-text-300 px-2 rounded-md"}>
                <input
                    type={"number"}
                    className={cn(
                        "w-full px-1 py-1 input-number-spin-none focus:outline-none",
                        "text-text-700 font-normal whitespace-nowrap px-2 font-bodyPri tracking-wide"
                    )}
                    value={regularPriceType?.discountPct?.toString()}
                    onChange={(e) => {
                        let safe_number = Number(e.target.value)
                        if (isNaN(safe_number)) {
                            safe_number = 0
                        }
                        if (safe_number >= 0 && safe_number <= 100) {
                            setRegularPriceType({
                                ...regularPriceType,
                                discountPct: safe_number
                            })
                        }
                    }}
                />
                <span className={cn("whitespace-nowrap")}>
                    {"%"}
                </span>
            </div>
            <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-8 flex flex-col items-start justify-start gap-5"}>
                {addCoursePriceDetail?.errorMsg &&
                    <div className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {addCoursePriceDetail?.errorMsg}
                    </div>
                }
                <SaveAndCancelBtn
                    isLoading={addCoursePriceDetail?.isLoading}
                    isNextBtnLoading={addCourseDetail?.isLoading}
                    isShowCancelBtn={true}
                    isShowDeleteBtn={true}
                    isShowNextBtn={true}
                    deleteBtnLabel={"Delete & Start Over"}
                    nexBtnLabel={"Next"}
                    isSaveBtnDisabled={!regularPriceType?.priceModel || isRegularPriceTypeSimilar}
                    isCancelBtnDisabled={!regularPriceType?.priceModel || !regularPriceType?.price || isRegularPriceTypeSimilar}
                    isDeleteBtnDisabled={!tutorCourseDetail?.data?.coursePrice || (coursePriceDetail?.coursePriceDetail?.type === coursePriceTypeEnum?.PLAN?.value) || isCourseUnderReview || isCoursePublished}
                    isNextBtnDisabled={!tutorCourseDetail?.data?.coursePrice}
                    onSave={onHandleSavePrice}
                    onCancel={onHandleCancelBtn}
                    onDelete={onHandleDeleteRegularPrice}
                    onNext={onHandleNextBtn}
                />
            </div>
        </div>
    )
}

export default RegularPriceTypeContainer;