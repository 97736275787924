import { arrayRemove, deleteDoc, updateDoc, doc } from "firebase/firestore";
import { database } from "config/firebase.config"

export default async function updateNotifications(notifications, user) {
  if (notifications.length === 0) return;
  try {
    notifications.forEach(async (notification) => {
      if (notification.chatType === "individual") {
        const notificationRef = doc(database, "notifications", notification.id);
        await deleteDoc(notificationRef);
      } else {
        const groupNotificationRef = doc(database, "groupnotifications", notification.chatId);
        await updateDoc(groupNotificationRef, {
          members: arrayRemove(user.userId),
        });
      }
    });
  } catch (err) {
    console.log(err);
  }
}
