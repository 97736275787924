import { useNavigate, NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { IoMdArrowRoundBack } from "react-icons/io";
import { IoMdShare } from "react-icons/io";
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';

import ToolTipView from 'components/tooltipView';

import { createStudentFavLmsCourseDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal, setShareSocial } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { EDULYTE_WEB_DOMAIN_URL, EDULYTE_APP_DOMAIN_URL } from "const/default.const";
import { pagesInfo } from "utils/pagesInfo";
import ThreeDotMenu from "components/threeDotMenu/ThreeDotMenu";

const menuItemEnum = Object.freeze({
    dashboard: {
        label: "My Dashboard",
        value: "dashboard"
    },
    share: {
        label: "Share",
        value: "share"
    },
    make_fav: {
        label: "Favourite",
        value: "make_fav"
    },
    remove_fav: {
        label: "Un Favourite",
        value: "remove_fav"
    },
    fullscreen: {
        label: "Full Screen",
        value: "fullscreen"
    },
    exit_fullscreen: {
        label: "Exit Full Screen",
        value: "exit_fullscreen"
    },
})


const HeaderView = ({
    courseId,
    courseTitle,
    thumbnail,
    activeSlug,
    isFullScreen = false,
    toggleFullScreen = () => { }
}) => {
    const { modal, shareSocial } = useAppState(state => state.local)
    const { user } = useAppState((state) => state.user)
    const { addStudentFavLmsCourseDetail } = useAppState((s) => s.lms.lmsCourse)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const COURSE_URL = `${EDULYTE_WEB_DOMAIN_URL}/course/${activeSlug}`

    const isFav = !!user?.user?.favoriteLmsCourses?.filter((course) => course.lmsCourseId === courseId)?.length

    const onHandleFavCourse = () => {
        if (addStudentFavLmsCourseDetail?.isLoading) return;

        dispatcher(createStudentFavLmsCourseDetail({ lms_course_id: courseId, user_id: user?.user?.userId }))
    }

    const handleShareCourse = () => {
        dispatcher(setShareSocial({
            ...shareSocial,
            socialAction: "",
            url: COURSE_URL
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
        }))
    }

    const handleBack = () => {
        navigate(-1)
    }

    const selectMenuOption = (option) => {
        switch (option.value) {
            case menuItemEnum.dashboard.value:
                navigate(pagesInfo.DASHBOARD.pagePath)
                break;
            case menuItemEnum.share.value:
                handleShareCourse()
                break;
            case menuItemEnum.make_fav.value:
                onHandleFavCourse()
                break;
            case menuItemEnum.remove_fav.value:
                onHandleFavCourse()
                break;
            case menuItemEnum.fullscreen.value:
                toggleFullScreen()
                break;
            case menuItemEnum.exit_fullscreen.value:
                toggleFullScreen()
                break;
            default:
                return;
        }
    }

    return (
        <header className='flex items-center justify-between w-full h-16 px-2 lg:px-5 py-1 bg-white shadow-lg'>
            <div className='grow flex items-center gap-3 lg:gap-8'>
                <ToolTipView content={"Go Back"}>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className='p-2 text-text-300 rounded-full ease-in-out duration-200 hover:bg-background-medium'
                        onClick={handleBack}
                    >
                        <IoMdArrowRoundBack className='text-text-700' style={{ fontSize: "1.5rem" }} />
                    </motion.button>
                </ToolTipView>
                <div className={"grow flex items-center md:items-start justify-start gap-3"}>
                    <div className={"w-12 md:w-16 lg:w-20 aspect-video overflow-hidden rounded"}>
                        <img
                            src={thumbnail}
                            alt={courseTitle}
                            className={"w-full h-full object-cover"}
                        />
                    </div>
                    <div className={"grow flex flex-col items-start justify-center"}>
                        <span className={"flex items-center justify-start gap-1 font-buttons font-normal text-sm text-text-600"}>
                            <NavLink to={pagesInfo.DASHBOARD.pagePath} className={"hover:text-secondary-dark hover:underline"}>
                                {"Dashboard"}
                            </NavLink>
                            <span>{"/"}</span>
                            <NavLink to={pagesInfo.STUDENT_LMS_COURSES.pagePath} className={"hover:text-secondary-dark hover:underline whitespace-nowrap"}>
                                {"My Courses"}
                            </NavLink>
                        </span>
                        <a
                            href={`${EDULYTE_WEB_DOMAIN_URL}/course/${activeSlug}`}
                            className={"font-bodyPri font-medium text-lg text-text-800 line-clamp-1 hover:text-secondary-dark hover:underline"}
                        >
                            {courseTitle}
                        </a>
                    </div>
                </div>
            </div>
            <div className='grow-0 flex items-center gap-5'>
                <NavLink
                    to={pagesInfo.DASHBOARD.pagePath}
                    className='hidden lg:block bg-background-lightBlue border border-primary-dark px-5 py-2 text-primary-dark rounded hover:bg-primary-light ease-in-out duration-200'
                >
                    {"My Dashboard"}
                </NavLink>
                <div className={"hidden lg:block"}>
                    <ToolTipView content={"Share"}>
                        <button
                            className='bg-background-lightBlue border border-primary-dark p-2 text-primary-dark rounded hover:bg-primary-light ease-in-out duration-200'
                            onClick={handleShareCourse}
                        >
                            <IoMdShare className={"text-xl"} />
                        </button>
                    </ToolTipView>
                </div>
                <div className={"hidden lg:block"}>
                    <ToolTipView content={isFav ? "Favourite" : "Mark Favourite"}>
                        <button
                            className='bg-background-lightBlue border border-primary-dark p-2 text-text-300 rounded ease-in-out duration-200'
                            onClick={onHandleFavCourse}
                        >
                            {isFav
                                ? <FavoriteIcon className='text-red-500' style={{ fontSize: "1.5rem" }} />
                                : <FavoriteBorderIcon className='text-primary-dark' style={{ fontSize: "1.5rem" }} />
                            }
                        </button>
                    </ToolTipView>
                </div>
                <div className={"hidden lg:block"}>
                    <ToolTipView content={isFullScreen ? "Exit full screen" : "Full screen"}>
                        <button
                            className='bg-background-lightBlue border border-primary-dark p-2 text-primary-dark rounded hover:bg-primary-light ease-in-out duration-200'
                            onClick={toggleFullScreen}
                        >
                            {isFullScreen
                                ? <MdFullscreenExit className='text-xl' />
                                : <MdFullscreen className='text-xl' />
                            }
                        </button>
                    </ToolTipView>
                </div>
                <div className="block lg:hidden bg-background-lightBlue border border-primary-dark px-3 py-2 text-primary-dark rounded hover:bg-primary-light ease-in-out duration-200 cursor-pointer">
                    <ThreeDotMenu
                        menuItems={[
                            menuItemEnum.dashboard,
                            menuItemEnum.share,
                            isFav ? menuItemEnum.remove_fav : menuItemEnum.make_fav,
                            isFullScreen ? menuItemEnum.exit_fullscreen : menuItemEnum.fullscreen
                        ]}
                        onHandleSelect={(option) => selectMenuOption(option)}
                    />
                </div>
            </div>
        </header>
    )
}

export default HeaderView;