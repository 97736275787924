import { useAppState } from 'hooks/useStore';
import { productTypeEnum } from 'redux/product/product.const';

const ProductInformation = () => {
    const { productDetail } = useAppState((state) => state.product)

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <div className={"w-full flex flex-col gap-1"}>
                <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                    {"2. Product details"}
                </span>

                <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wide"}>
                    {"Information essential for transactions."}
                </span>
            </div>

            <div className={"w-full flex flex-col gap-5"}>
                {/* type / read only */}
                <div className={"grid grid-cols-12 gap-2 items-start"}>
                    <span className={"col-span-8 order-1 lg:col-span-2 w-fill flex items-center justify-start lg:justify-between font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                        <span>
                            {"Product Type"}
                        </span>
                        <span>{":"}</span>
                    </span>
                    <span className={"col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch font-bodyPri font-normal text-text-900 text-md tracking-wide"}>
                        {productTypeEnum[productDetail?.data?.type?.toUpperCase()]?.label || "-"}
                    </span>
                </div>

                {/* title / read-write */}
                <div className={"grid grid-cols-12 gap-2 items-start"}>
                    <span className={"col-span-8 order-1 lg:col-span-2 w-fill flex items-center justify-start lg:justify-between font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                        <span className={""}>
                            {"Title"}
                        </span>
                        <span>{":"}</span>
                    </span>
                    <span className={"col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch font-bodyPri font-normal text-text-900 text-md tracking-wide truncate"}>
                        {productDetail?.data?.title || "-"}
                    </span>
                </div>

                {/* description / read-write */}
                <div className={"grid grid-cols-12 gap-2 items-start"}>
                    <span className={"col-span-8 order-1 lg:col-span-2 w-fill flex items-center justify-start lg:justify-between font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                        <span className={""}>
                            {"Description"}
                        </span>
                        <span>{":"}</span>
                    </span>
                    <span className={"col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch font-bodyPri font-normal text-text-900 text-md tracking-wide truncate"}>
                        {productDetail?.data?.description || "-"}
                    </span>
                </div>

                {/* thumbnail / read-write */}
                <div className={"grid grid-cols-12 gap-2 items-start"}>
                    <span className={"col-span-8 order-1 lg:col-span-2 w-fill flex items-center justify-start lg:justify-between font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                        <span className={""}>
                            {"Thumbnail"}
                        </span>
                        <span>{":"}</span>
                    </span>
                    <div className={"col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch font-bodyPri font-normal text-text-900 text-md tracking-wide truncate"}>
                        {(!productDetail?.data?.thumbnail_pic_url) &&
                            <span>{"-"}</span>
                        }
                        {(productDetail?.data?.thumbnail_pic_url) &&
                            <div className={"relative rounded-lg shadow-lg bg-black h-28 aspect-video test-xs"}>
                                <img
                                    src={productDetail?.data?.thumbnail_pic_url}
                                    alt={"product_thumbnail_url"}
                                    className={"object-cover w-full h-full rounded-lg opacity-90"}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductInformation;