import { memo, useCallback, useState } from 'react';

import { FiCheckSquare, FiEdit } from 'react-icons/fi';
import { MdOutlineContentCopy } from 'react-icons/md';
import { RiDeleteBin6Line } from "react-icons/ri";

import ToolTipView from 'components/tooltipView';

import { libraryTabConst } from 'components/modals/lmsModals/attachLibraryModal/data';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetLocalTabs, setLocalTabs, setModal } from 'redux/local/local.slice';
import { setFilterProps } from 'redux/edulyteLms/lmsResource/lmsResource.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsResourceTypeEnum, resourceFileTypeEnum } from 'redux/edulyteLms/lmsResource/lmsResource.const';

const LectureItemActionButtons = memo(({
    copyBtnToolTip = "Copy Video Url",
    editVideoBtnToolTip = "Update Video",
    removeVideoBtnToolTip = "Remove Video",
    lectureId,
    lectureResourceId,
    resourceLink,
}) => {
    const { modal, localTabs } = useAppState((state) => state.local)
    const { filterProps } = useAppState((state) => state.lms.lmsResource)

    const dispatcher = useAppDispatcher()

    const [isVideoUrlCopied, setIsVideoUrlCopied] = useState(false)

    const onHandleCopyVideoUrl = useCallback(() => {
        if (resourceLink) {
            setIsVideoUrlCopied(true)
            navigator.clipboard.writeText(resourceLink)

            setTimeout(() => {
                setIsVideoUrlCopied(false)
            }, 1000)
        }
    }, [resourceLink])

    const onHandleEditVideo = useCallback(() => {
        dispatcher(resetLocalTabs())
        dispatcher(setLocalTabs({
            ...localTabs,
            modalTitle: "Library",
            tabList: Object.values(libraryTabConst),
            activeTabList: [libraryTabConst.RESOURCES.value],
            currentActiveTab: libraryTabConst.RESOURCES.value,
            tabItem: {
                ...localTabs?.tabItem,
                payload: {
                    lectureId: lectureId
                }
            }
        }))
        dispatcher(setFilterProps({
            filterProps: {
                ...filterProps,
                resourceType: lmsResourceTypeEnum.FILE.value,
                resourceFileType: resourceFileTypeEnum.VIDEO.value,
                disabledFilters: [lmsResourceTypeEnum.LINK.value]
            }
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: true
        }))
    }, [modal, localTabs])

    const onHandleDeleteVideo = useCallback(() => {
        if (lectureResourceId) {
            // dispatcher(deleteLmsLectureResourceDetail(lectureResourceId))
        }
    }, [lectureResourceId])

    return (
        <div className={"w-fit h-fit px-2 py-1.5 flex flex-row md:flex-col gap-2 bg-primary-light rounded-md"}>
            <ToolTipView content={copyBtnToolTip}>
                <button
                    className={"p-2 hover:bg-divider-darkLight rounded-full"}
                    onClick={onHandleCopyVideoUrl}
                >
                    {isVideoUrlCopied &&
                        <FiCheckSquare className={"text-xl text-green-500"} />
                    }
                    {!isVideoUrlCopied &&
                        <MdOutlineContentCopy className={"text-xl text-text-700 cursor-pointer"} />
                    }
                </button>
            </ToolTipView>
            <ToolTipView content={editVideoBtnToolTip}>
                <button
                    className={"p-2 hover:bg-divider-darkLight rounded-full"}
                    onClick={onHandleEditVideo}
                >
                    <FiEdit className={"text-xl text-text-800 cursor-pointer"} />
                </button>
            </ToolTipView>
            {/* <ToolTipView content={removeVideoBtnToolTip}>
                <button
                    className={"p-2 hover:bg-divider-darkLight rounded-full"}
                    onClick={onHandleDeleteVideo}
                >
                    <RiDeleteBin6Line className={"text-xl text-text-700 cursor-pointer"} />
                </button>
            </ToolTipView> */}
        </div>
    )
});

export default LectureItemActionButtons;