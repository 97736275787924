import * as React from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";
import { FiEdit } from 'react-icons/fi';

import { editBtnConst } from "../data";
import EditAndDoneBtn from "./EditAndDoneBtn";

import { setOfferingCourse } from "redux/offering/offering.slice"
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { updateOfferingByCourseId } from "redux/offering/offering.request";

type Props = {
  value: any;
  onChange: (value: any) => void;
  textClassName?: string;
  rows?: number;
  className?: string;
  editBtn?: string | null;
  setEditBtn: (editBtn: string | null) => void;
  title?: string;
  titleDescription?: string;
  onSave?: (btnType: string) => void;
  onCancel?: (btnType: string) => void;
};

export const InlineTextEditor: React.FC<Props> = ({
  value,
  onChange,
  className,
  textClassName,
  children,
  editBtn,
  setEditBtn,
  title,
  titleDescription,
  rows = 1,
}) => {
  const { offeringCourse } = useAppState((state: any) => state.offering)

  const dispatcher = useAppDispatcher()
  const { courseId } = useParams()

  const [editMode, setEditMode] = React.useState(false);
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    if (inputRef != null) {
      inputRef.current?.focus();
    }
  }, [editMode]);

  const onSave = (btnType: any) => {
    if (btnType === editBtnConst.title.value) {
      dispatcher(updateOfferingByCourseId(Number(courseId),
        {
          courseType: offeringCourse?.offeringCourse?.courseType,
          courseTitle: offeringCourse?.offeringCourse?.courseTitle
        }))
    }
    if (btnType === editBtnConst.titleDescription.value) {
      dispatcher(updateOfferingByCourseId(Number(courseId),
        {
          courseType: offeringCourse?.offeringCourse?.courseType,
          courseTitleDescription: offeringCourse?.offeringCourse?.courseTitleDescription
        }))
    }
    setEditBtn(null)
  }

  const onCancel = () => {
    setEditBtn(null)
  }

  return (
    <div className={cn(className, "relative w-full")}>
      <button className={"absolute p-2 -top-1.5 -right-0 md:-top-1.5 md:-right-0.5"}>
        {(editBtn === editBtnConst.title.value && title === "title") &&
          <EditAndDoneBtn
            onSave={() => onSave(editBtn)}
            onCancel={onCancel}
          />
        }
        {(editBtn === editBtnConst.titleDescription.value && titleDescription === "titleDescription") &&
          <EditAndDoneBtn
            onSave={() => onSave(editBtn)}
            onCancel={onCancel}
          />
        }
        {!editBtn &&
          <FiEdit
            className="text-lg text-text-500"
            onClick={() => {
              if (title === "title") {
                setEditBtn(editBtnConst.title.value)
              } else {
                setEditBtn(editBtnConst.titleDescription.value)
              }
            }}
          />
        }

      </button>
      {(editBtn === editBtnConst.title.value && title === "title") &&
        <textarea
          ref={inputRef}
          rows={rows}
          maxLength={80}
          className={cn(
            textClassName,
            "p-2 w-full bg-transparent resize-none",
            "focus:outline-none"
          )}
          disabled={!title}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      }
      {(editBtn === editBtnConst.titleDescription.value && titleDescription === "titleDescription") &&
        <textarea
          ref={inputRef}
          rows={rows}
          maxLength={250}
          className={cn(
            textClassName,
            "p-2 w-full bg-transparent resize-none",
            "focus:outline-none"
          )}
          disabled={titleDescription !== "titleDescription"}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      }
      {(editBtn !== editBtnConst.title.value && title === "title") &&
        <div
          className={cn(
            textClassName,
            "p-2 w-full bg-transparent border-secondary-dark border-dashed focus:outline-none"
          )}
        >{value}
        </div>
      }
      {(editBtn !== editBtnConst.titleDescription.value && titleDescription === "titleDescription") &&
        <div
          className={cn(
            textClassName,
            "p-2 w-full bg-transparent border-secondary-dark border-dashed focus:outline-none"
          )}
        >{value}
        </div>
      }
    </div>
  );
};

export const AffixedInlineTextEditor: React.FC<
  {
    value: any;
    className?: string;
    textClassName: string;
    discountPercentage?: any;
    discountValue?: any;
    prefix: string;
    suffix: string;
    prefixClassName?: string;
    suffixClassName?: string;
    editBtn: string;
    setEditBtn: (editBtn: string | null) => void;
  }
> = ({
  discountPercentage,
  discountValue,
  value,
  className,
  prefix,
  suffix,
  children,
  editBtn,
  setEditBtn
}) => {

    const { offeringCourse } = useAppState((s) => s.offering)
    const [price, setPrice] = React.useState(0)
    const [discountedPercentage, setDiscountedPercentage] = React.useState(0)

    const inputRef = React.useRef<HTMLInputElement>(null);
    const dispatcher = useAppDispatcher()
    const { courseId } = useParams()


    React.useEffect(() => {
      if (inputRef != null) {
        inputRef.current?.focus();
      }
    }, [editBtn]);

    const handleOnEditBtn = () => {
      // setEditMode(!editMode)
      // if (editMode) {
      //   dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, price: price * 100, discountPct: parseInt(discountedPercentage + "") }))
      //   setPrice(0)
      //   setDiscountedPercentage(0)
      // } else {
      //   setPrice(offeringCourse?.offeringCourse?.price / 100)
      //   setDiscountedPercentage(offeringCourse?.offeringCourse?.discountPct)
      // }
    }

    const onSavePrice = () => {
      dispatcher(updateOfferingByCourseId(
        Number(courseId),
        {
          courseType: offeringCourse?.offeringCourse?.courseType,
          price: offeringCourse?.offeringCourse?.price,
          discountPct: parseInt(offeringCourse?.offeringCourse?.discountPct + "")
        }
      ))
      // dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, price: price * 100, discountPct: parseInt(discountedPercentage + "") }))
      // setPrice(0)
      // setDiscountedPercentage(0)
      setEditBtn(null)
    }

    const onCancelPrice = () => {
      setEditBtn(null)
    }

    return (
      <>
        <div className={cn(className, "w-full relative")}>
          <div className="flex items-center justify-between gap-2 w-full p-2">
            <div className={"flex justify-start items-center gap-2"}>
              <div className='flex justify-center items-center gap-2'>
                <span className='text-lg text-text-800'>
                  {prefix}
                </span>
                <span className='text-2xl text-text-900'>
                  {parseFloat("" + (discountValue / 100)).toFixed(2)}
                </span>
              </div>
              <div className='flex flex-col'>
                {discountPercentage > 0 &&
                  <span className='text-text-900 font-semibold'>
                    <del className='text-text-600'>
                      {value && prefix + " " + parseFloat("" + (value / 100)).toFixed(2)}
                    </del>
                    {discountPercentage && " " + discountPercentage + "% off"}
                  </span>
                }
                <span className="text-text-900 font-medium font-bodyPri">
                  {suffix}
                </span>
              </div>
            </div>
            <span>
              {!editBtn &&
                <FiEdit className="inline text-lg text-text-500 cursor-pointer" onClick={() => setEditBtn(editBtnConst.price.value)} />
              }
            </span>
          </div>
        </div>
        <div className={cn(className, "w-full relative")}>
          {(editBtn === editBtnConst.price.value) &&
            <div className={"flex flex-col items-center justify-start gap-3 pb-1"}>
              <hr className="m-2 border border-solid" />
              <div className="px-3 flex flex-col gap-2">
                <div className="flex items-center w-full space-x-1">
                  <span className="text-text-700 font-normal font-bodyPri tracking-wide whitespace-nowrap">
                    {"Price:"}
                  </span>
                  <div className={"inline-flex items-center gap-1 bg-transparent border-2 border-text-300 px-1 py-0.5 rounded-md"}>
                    <span className={cn("text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide")}>
                      {prefix}
                    </span>
                    <input
                      autoFocus={true}
                      type="number"
                      className={cn(
                        "bg-transparent w-full input-number-spin-none focus:outline-none",
                        "text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide"
                      )}
                      placeholder={"Price..."}
                      value={(offeringCourse?.offeringCourse?.price / 100)?.toString()}
                      onChange={(e) => {
                        let safe_number = Number(e.target.value)
                        if (isNaN(safe_number)) {
                          safe_number = 0
                        }
                        if (safe_number <= 1500) {
                          dispatcher(setOfferingCourse({
                            ...offeringCourse?.offeringCourse, price: safe_number * 100
                          }))
                        }
                      }}
                    />
                    <p className={cn("whitespace-nowrap font-bodyPri tracking-wide")}>
                      {suffix}
                    </p>
                  </div>
                </div>
                <div className="flex items-center w-full space-x-2">
                  <span className="text-text-700 font-normal font-bodyPri tracking-wide whitespace-nowrap">
                    {"Discount: "}
                  </span>
                  <div className={"inline-flex items-center gap-1 bg-transparent border-2 border-text-300 px-1 py-0.5 rounded-md"}>
                    <input
                      type={"number"}
                      className={cn(
                        "bg-transparent w-full input-number-spin-none focus:outline-none",
                        "text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide"
                      )}
                      placeholder={'0'}
                      value={offeringCourse?.offeringCourse?.discountPct?.toString()}
                      onChange={(e) => {
                        let safe_number = Number(e.target.value)
                        if (isNaN(safe_number)) {
                          safe_number = 0
                        }
                        if (safe_number >= 0 && safe_number <= 100) {
                          dispatcher(setOfferingCourse({
                            ...offeringCourse?.offeringCourse,
                            discountPct: safe_number
                          }))
                        }
                      }}
                    />
                    <p className={cn("whitespace-nowrap")}>
                      {"%"}
                    </p>
                  </div>
                </div>
              </div>
              {(editBtn === editBtnConst.price.value) &&
                <EditAndDoneBtn onSave={onSavePrice} onCancel={onCancelPrice} />
              }
            </div>
          }
        </div>
      </>
    );
  };