import { Link, useNavigate } from 'react-router-dom';

import { MdArrowBackIos } from "react-icons/md";

import { pagesInfo } from 'utils/pagesInfo';

const GoBackHeader = () => {
    const navigate = useNavigate()

    const handleGoBack = () => {
        navigate(-1)
    }

    const handleSendEmail = () => {
        window.open("mailto: support@edulyte.com")
    }

    return (
        <div className={"hidden col-start-1 col-span-full z-30 w-full md:flex justify-start items-center p-5 bg-primary-light shadow"}>
            <div className={"flex items-center justify-start gap-5 container mx-auto"}>
                <div className={"flex items-center justify-start gap-1 cursor-pointer group"} onClick={handleGoBack}>
                    <MdArrowBackIos className={"text-xl text-primary-dark group-hover:text-secondary-main"} />
                    <span className={"font-bodyPri font-normal text-primary-dark text-md group-hover:text-secondary-main"}>
                        {"Go Back"}
                    </span>
                </div>
                <a
                    href={"https://support.edulyte.com/portal/en/kb/tutor-helpdesk"}
                    target={"_blank"}
                    className={"font-bodyPri font-normal text-text-700 text-base hover:text-secondary-main hover:underline"}>
                    {"See Guides and FAQs"}
                </a>
            </div>
            <div className={"flex flex-col items-start justify-start md:flex-row md:items-center md:justify-center gap-1.5 font-bodyPri font-normal text-text-800 text-base tracking-wide md:whitespace-nowrap"}>
                <span>
                    {"Need help? email to"}
                </span>
                <span className={"text-primary-main hover:underline cursor-pointer"} onClick={handleSendEmail}>
                    {" support@edulyte.com"}
                </span>
            </div>
        </div>
    )
}

export default GoBackHeader