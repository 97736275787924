import { useCallback, useMemo, useState, useEffect } from "react";
import { toast } from 'react-toastify';

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { MdAccessTime } from "react-icons/md";
import { BsDashLg } from "react-icons/bs";

import { cn } from "utils/cn.utils";

import ButtonLoader from 'components/loader/ButtonLoader';
import FloatingLabelSelect from "components/floating/floatingLabelSelect";

import { generateStartTimeOptions, generateEndTimeOptions } from "components/modals/eventlyModals/data";

import { updateEventlyEventsDetail } from 'redux/evently/evently.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetModifyEventlyEventsDetail, setModifyUserEventlyDetailPayload } from 'redux/evently/evently.slice';
import { modalConst } from 'redux/local/local.const';

import { dayjs, timeZone } from "utils/dateTime.utils";


const EditEventlyEventSlotModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { modifyUserEventlyDetail, modifyEventlyEventsDetail } = useAppState((state) => state.evently)

    const dispatcher = useAppDispatcher()

    const [toggle, setToggle] = useState({
        isShowEndDate: false,
        startDatePopup: false,
        endDatePopup: false
    });

    const startTimeOption = useMemo(() => generateStartTimeOptions(dayjs().tz(timeZone)), [])
    const endTimeOption = useMemo(() => generateEndTimeOptions(modifyUserEventlyDetail?.payload?.startDateTime), [modifyUserEventlyDetail?.payload?.startDateTime])

    useEffect(() => {
        return () => {
            dispatcher(resetModifyEventlyEventsDetail())
        }
    }, [])

    useEffect(() => {
        if (modifyEventlyEventsDetail?.data) {
            dispatcher(resetModifyEventlyEventsDetail())
            dispatcher(setModal({
                ...modal,
                [modalConst.editEventlyEventSlotModal.key]: {
                    ...modal[modalConst.editEventlyEventSlotModal.key],
                    isVisible: false,
                    title: ""
                }
            }))
        }
    }, [modifyEventlyEventsDetail?.data])

    useEffect(() => {
        if (modifyUserEventlyDetail?.payload?.startDateTime && modifyUserEventlyDetail?.payload?.endDateTime) {
            const isDateSimilar = dayjs(modifyUserEventlyDetail?.payload?.startDateTime).isSame(modifyUserEventlyDetail?.payload?.endDateTime, "date")
            if (!isDateSimilar) {
                setToggle({
                    ...toggle,
                    isShowEndDate: true
                })
            }
        }
    }, [])

    const onHandleSelectStartDate = useCallback((startDate) => {
        let newEndDateTime = dayjs(modifyUserEventlyDetail?.payload?.endDateTime)?.tz(timeZone)
        newEndDateTime = dayjs(startDate).add(1, "hour").format("YYYY-MM-DD HH:mm:ss");
        dispatcher(setModifyUserEventlyDetailPayload({
            ...modifyUserEventlyDetail?.payload,
            startDateTime: dayjs(startDate).tz(timeZone).format("YYYY-MM-DD HH:mm:ss"),
            endDateTime: newEndDateTime
        }))
        setToggle({
            isShowEndDate: toggle.isShowEndDate,
            startDatePopup: false,
            endDatePopup: false
        })
    }, [modifyUserEventlyDetail?.payload, toggle])

    const onHandleSelectEndDate = useCallback((endDate) => {
        dispatcher(setModifyUserEventlyDetailPayload({
            ...modifyUserEventlyDetail?.payload,
            endDateTime: dayjs(endDate).tz(timeZone).format("YYYY-MM-DD HH:mm:ss")
        }))
        setToggle({
            isShowEndDate: toggle.isShowEndDate,
            startDatePopup: false,
            endDatePopup: false
        })
    }, [modifyUserEventlyDetail?.payload, toggle])

    const onHandleSelectStartTimeSlot = (slotInfo) => {
        let newStartDateTime = dayjs(modifyUserEventlyDetail?.payload?.startDateTime).tz(timeZone)
        let newEndDateTime = dayjs(modifyUserEventlyDetail?.payload?.endDateTime).tz(timeZone)
        let startDate = newStartDateTime?.format("YYYY-MM-DD")
        newStartDateTime = startDate + " " + slotInfo?.value
        newEndDateTime = dayjs(newStartDateTime).add(1, "hour").format("YYYY-MM-DD HH:mm:ss");

        const endDateChanged = !dayjs(newStartDateTime).isSame(dayjs(newEndDateTime), 'day');

        dispatcher(setModifyUserEventlyDetailPayload({
            ...modifyUserEventlyDetail?.payload,
            startDateTime: newStartDateTime,
            endDateTime: newEndDateTime
        }))

        if (endDateChanged) {
            setToggle({
                isShowEndDate: true,
                startDatePopup: false,
                endDatePopup: false
            })
        } else {
            setToggle({
                isShowEndDate: false,
                startDatePopup: false,
                endDatePopup: false
            })
        }
    }

    const onHandleSelectEndTimeSlot = (slotInfo) => {
        let newStartDateTime = dayjs(modifyUserEventlyDetail?.payload?.startDateTime).tz(timeZone)
        let startDate = newStartDateTime?.format("YYYY-MM-DD")
        let newEndDateTime = dayjs(modifyUserEventlyDetail?.payload?.endDateTime).tz(timeZone)
        let endDate = newEndDateTime?.format("YYYY-MM-DD")
        newEndDateTime = endDate + " " + slotInfo?.value

        const selectedTime = dayjs(slotInfo?.value, 'HH:mm:ss');

        const startHour = newStartDateTime.hour();
        const startMinute = newStartDateTime.minute();
        const selectedHour = selectedTime.hour();
        const selectedMinute = selectedTime.minute();

        const crossesMidnight = (selectedHour < startHour) || ((selectedHour === startHour) && (selectedMinute < startMinute));

        if (crossesMidnight) {
            endDate = dayjs(startDate).add(1, "day").format("YYYY-MM-DD");
            newEndDateTime = endDate + " " + slotInfo?.value
            setToggle({
                isShowEndDate: true,
                startDatePopup: false,
                endDatePopup: false
            });
        } else {
            newEndDateTime = startDate + " " + slotInfo?.value
            setToggle({
                isShowEndDate: false,
                startDatePopup: false,
                endDatePopup: false
            });
        }
        dispatcher(setModifyUserEventlyDetailPayload({
            ...modifyUserEventlyDetail?.payload,
            endDateTime: newEndDateTime
        }))
    }

    const onHandleSubmit = () => {
        if (!modifyUserEventlyDetail?.payload?.startDateTime) {
            toast.warn("Please select start time.")
            return;
        }
        if (!modifyUserEventlyDetail?.payload?.endDateTime) {
            toast.warn("Please select end time.")
            return;
        }

        const body = {
            timezone: timeZone,
            slot: {
                startDateTime: dayjs(modifyUserEventlyDetail?.payload?.startDateTime)?.format("YYYY-MM-DD HH:mm:ss"),
                endDateTime: dayjs(modifyUserEventlyDetail?.payload?.endDateTime)?.format("YYYY-MM-DD HH:mm:ss")
            }
        }

        dispatcher(updateEventlyEventsDetail(modifyUserEventlyDetail?.payload?.eventId, body))
    }

    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={'min-h-[14rem] block space-y-5'}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className={"w-full grid grid-cols-12 gap-x-2 px-5"}>
                        <div className={"col-start-1 col-span-1 mt-2"}>
                            <MdAccessTime className={"text-text-800 text-lg"} />
                        </div>
                        <div className={"main-container col-start-2 col-span-full flex flex-wrap items-center gap-3 font-bodyPri font-normal"}>
                            {!toggle?.startDatePopup && (
                                <button
                                    className={"p-2 hover:bg-background-lightBlue text-text-800 rounded"}
                                    onClick={() => setToggle({
                                        startDatePopup: true,
                                        endDatePopup: false,
                                        isShowEndDate: toggle.isShowEndDate
                                    })}
                                >
                                    {dayjs(modifyUserEventlyDetail?.payload?.startDateTime)?.tz(timeZone)?.format("dddd, DD-MMM-YYYY")}
                                </button>
                            )}
                            {toggle?.startDatePopup && (
                                <div className="p-2">
                                    <DatePicker
                                        slotProps={{ textField: { variant: "standard", size: "small" } }}
                                        format={"dddd, DD-MMM-YYYY"}
                                        disablePast
                                        value={dayjs(modifyUserEventlyDetail?.payload?.startDateTime)?.tz(timeZone)}
                                        onChange={onHandleSelectStartDate}
                                    />
                                </div>
                            )}
                            <div className={"flex items-center gap-1"}>
                                <FloatingLabelSelect
                                    showLabel={false}
                                    showIcon={false}
                                    customBtnStyle={"p-2 bg-white hover:bg-background-lightBlue text-text-800 border-none border-b border-b-white focus-within:!ring-0 focus-within:border-b-primary-main focus-within:bg-background-lightBlue"}
                                    dropdownWrapperClassName={"w-36"}
                                    dropDownContainerClassName={"max-h-52 overflow-y-auto scrollbar-thin"}
                                    value={dayjs(modifyUserEventlyDetail?.payload?.startDateTime)?.tz(timeZone)?.format("hh:mma")}
                                    options={startTimeOption}
                                    onHandleSelect={onHandleSelectStartTimeSlot}
                                    OptionChild={({ option }) => (
                                        <div className={"bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between"}>
                                            {option.label}
                                        </div>
                                    )}
                                />
                                <BsDashLg />
                                <FloatingLabelSelect
                                    showLabel={false}
                                    showIcon={false}
                                    customBtnStyle={"p-2 bg-white hover:bg-background-lightBlue text-text-800 border-none border-b border-b-white focus-within:!ring-0 focus-within:border-b-primary-main focus-within:bg-background-lightBlue"}
                                    dropdownWrapperClassName={"w-44"}
                                    dropDownContainerClassName={"max-h-52 overflow-y-auto scrollbar-thin"}
                                    value={dayjs(modifyUserEventlyDetail?.payload?.endDateTime)?.tz(timeZone)?.format("hh:mma")}
                                    options={endTimeOption}
                                    onHandleSelect={onHandleSelectEndTimeSlot}
                                    OptionChild={({ option }) => (
                                        <div className="bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between whitespace-nowrap">
                                            {option.label}
                                        </div>
                                    )}
                                />
                                {/* <button className={"text-primary-main p-2 rounded bg-white hover:bg-background-lightBlue whitespace-nowrap flex items-center justify-center gap-1"}>
                            <MdOutlineAdd />
                            {"Add new"}
                        </button> */}
                            </div>

                            {(toggle?.isShowEndDate && !toggle?.endDatePopup) &&
                                <button
                                    className={"p-2 hover:bg-background-lightBlue text-text-800 rounded"}
                                    onClick={() => setToggle({
                                        startDatePopup: false,
                                        endDatePopup: true,
                                        isShowEndDate: toggle.isShowEndDate
                                    })}
                                >
                                    {dayjs(modifyUserEventlyDetail?.payload?.endDateTime)?.tz(timeZone)?.format("dddd, DD-MMM-YYYY")}
                                </button>
                            }
                            {toggle?.endDatePopup && (
                                <div className="p-2">
                                    <DatePicker
                                        slotProps={{ textField: { variant: "standard", size: "small" } }}
                                        format={"dddd, DD-MMM-YYYY"}
                                        value={dayjs(modifyUserEventlyDetail?.payload?.endDateTime)?.tz(timeZone)}
                                        disablePast
                                        onChange={onHandleSelectEndDate}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </LocalizationProvider>

                <div className={"w-full flex items-center justify-center"}>
                    {modifyEventlyEventsDetail?.message &&
                        <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide text-center"}>
                            {modifyEventlyEventsDetail?.message}
                        </span>
                    }
                </div>
            </div>
            <div className={"flex justify-end px-5"}>
                <button
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full",
                        "font-buttons font-normal text-base",
                        (!modifyEventlyEventsDetail?.isLoading) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                        (modifyEventlyEventsDetail?.isLoading) && "bg-secondary-main"
                    )}
                    disabled={!modifyUserEventlyDetail?.payload?.startDateTime}
                    onClick={onHandleSubmit}
                >
                    {modifyEventlyEventsDetail?.isLoading && <ButtonLoader isLoading={modifyEventlyEventsDetail?.isLoading} />}
                    {!modifyEventlyEventsDetail?.isLoading && "Submit"}
                </button>
            </div>
        </div>
    )
}

export default EditEventlyEventSlotModal;