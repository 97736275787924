import EditLMSLectureBuilderPage from "./components/lectureBuilder/EditLMSLectureBuilderPage";
import EditLMSLectureSettingsPage from "./components/lectureSettings/EditLMSLectureSettingsPage";

import LectureGeneralSettings from "./components/lectureSettings/LectureGeneralSettings";
import LectureInstructor from "./components/lectureSettings/LectureInstructor";

export const lmsLectureTabConst = {
    BUILDER: {
        label: "Builder",
        value: "builder",
        pagePath: "/builder",
        routePath: "builder",
        component: EditLMSLectureBuilderPage
    },
    SETTINGS: {
        label: "Settings",
        value: "settings",
        pagePath: "/settings",
        routePath: "settings",
        component: EditLMSLectureSettingsPage
    }
}

export const lectureSettingsNavigationOptions = {
    GENERAL: {
        label: "General settings",
        value: "general",
        pagePath: "/general",
        routePath: "general",
        to: "settings/general",
        component: LectureGeneralSettings
    },
    USER: {
        label: "Instructor",
        value: "user",
        pagePath: "/instructors",
        routePath: "instructors",
        to: "settings/instructors",
        component: LectureInstructor
    },
}


export const lectureInstructorHeaderConst = {
    Image: {
        label: "Image",
        value: "image"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    PERMISSION: {
        label: "Permission",
        value: "permission"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

export const lectureInstructorMenuItemConst = {
    MAKE_DEFAULT: {
        label: "Make Default",
        value: "make_default"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    DELETE: {
        label: "Delete",
        value: "delete"
    }
}