import { useState } from 'react';
import { cn } from "utils/cn.utils";

import {
    TaskItemContainer,
    TaskDetails,
    TaskTitleDesc,
    TaskTitleText,
    TaskDescText,
    TaskAction,
    DisabledButton,
    Submitted,
    UnderReview,
    Verified,
    ArrowToggleIcon,
    TaskTime,
    VerifiedIcon,
    SubmittedIcon,
    TutorPreferenceContainer,
    Rejected,
    RejectedIcon,
    Pending,
    PendingIcon
} from 'pages/auth/tutorOnboard/TutorOnboardStyle';

import TutorPreferenceBox from "pages/auth/tutorOnboard/components/tutorPreferences/components/TutorPreferenceBox";

import { useAppState } from 'hooks/useStore';
import { tutorPrefStatus } from "redux/onboarding/onboarding.const";

const TutorPreferences = () => {
    const { userOnboardingDetail } = useAppState((state) => state.onboarding)

    const [isTutorPreferenceEnabled, setIsPreferenceEnabled] = useState(false)
    const [clickedItemIndex, setClickedItemIndex] = useState(null);

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const isOpen = clickedItemIndex === 1;

    const isEmailMobileVerified = userOnboardingDetail?.userOnboardingDetail?.user?.isEmailVerified && userOnboardingDetail?.userOnboardingDetail?.user?.isMobileNoVerified

    const handleTaskItems = () => {
        setClickedItemIndex(isOpen ? null : 1)
        setIsPreferenceEnabled(!isTutorPreferenceEnabled)
    }

    return (
        <TaskItemContainer>
            <TaskDetails>
                <span className={cn(
                    "px-2.5 py-0.5 h-auto self-start rounded-full font-bodyPri font-medium text-lg",
                    (!isEmailMobileVerified || !userOnboardingDetail?.userOnboardingDetail?.onboardingTutorPref?.status) && "border-2 border-text-500 text-text-900 bg-white",
                    (isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingTutorPref?.status) && `text-text-50 bg-${tutorPrefStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingTutorPref?.status?.toUpperCase()]?.darkColor}`
                )}>
                    {"5"}
                </span>
                <TaskTitleDesc>
                    <TaskTitleText>
                        {"Tutor Preferences"}
                    </TaskTitleText>
                    <TaskDescText>
                        {"Select your availability for our platform and allow us to get you students. All fields are mandatory."}
                    </TaskDescText>
                </TaskTitleDesc>
            </TaskDetails>
            <TaskAction>
                {(!isEmailMobileVerified || (isEmailMobileVerified && !userOnboardingDetail?.userOnboardingDetail?.onboardingTutorPref?.status)) &&
                    <DisabledButton>
                        {"Preference"}
                    </DisabledButton>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingTutorPref?.status === tutorPrefStatus?.PENDING?.value) &&
                    <Pending onClick={handleTaskItems}>
                        <PendingIcon />
                        {tutorPrefStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingTutorPref?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Pending>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingTutorPref?.status === tutorPrefStatus?.SUBMITTED?.value) &&
                    <Submitted onClick={handleTaskItems}>
                        <SubmittedIcon />
                        {tutorPrefStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingTutorPref?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Submitted>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingTutorPref?.status === tutorPrefStatus?.UNDER_REVIEW?.value) &&
                    <UnderReview onClick={handleTaskItems}>
                        <SubmittedIcon />
                        {tutorPrefStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingTutorPref?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </UnderReview>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingTutorPref?.status === tutorPrefStatus?.APPROVED?.value) &&
                    <Verified onClick={handleTaskItems}>
                        <VerifiedIcon />
                        {tutorPrefStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingTutorPref?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Verified>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingTutorPref?.status === tutorPrefStatus?.REJECTED?.value) &&
                    <Rejected onClick={handleTaskItems}>
                        <RejectedIcon />
                        {tutorPrefStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingTutorPref?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Rejected>
                }
                <TaskTime>{"10 mins"}</TaskTime>
            </TaskAction>
            {isTutorPreferenceEnabled &&
                <TutorPreferenceContainer>
                    <TutorPreferenceBox />
                </TutorPreferenceContainer>
            }
        </TaskItemContainer>
    )
}

export default TutorPreferences;