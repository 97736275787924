import { useCallback, useEffect, memo } from 'react';

import { cn } from "utils/cn.utils";

import ComponentLoader from 'components/loader/ComponentLoader';
import Pagination from 'components/pagination/Pagination';

import LMSTable from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTable';

import { libraryTabConst, questionTableHeaderConst } from '../data';
import LibraryActionButtons from '../components/LibraryActionButtons';

import { getLmsQuestionList } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setLocalTabs, setModal } from 'redux/local/local.slice';
import { resetLmsQuestionList } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsQuestionTypeEnum } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';
import { pagesInfo } from 'utils/pagesInfo';

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

const QuestionTab = () => {
  const { modal, localTabs } = useAppState((state) => state.local)
  const { lmsQuestionList } = useAppState((state) => state.lms.lmsQuestion)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(getLmsQuestionList({ page: 1, records: 5 }))

    return () => {
      dispatcher(resetLmsQuestionList())
    }
  }, [])

  const onHandleCreateQuestion = useCallback(() => {
    dispatcher(setModal({
      ...modal,
      [modalConst.CREATE_LMS_QUESTION_MODAL.stateKey]: true,
      [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: false
    }))
  }, [modal])

  const onHandlePageChange = useCallback((page) => {
    dispatcher(getLmsQuestionList({ page: page, records: 5 }))
  }, [])

  const onHandleEditQuestion = useCallback((questionItem) => {
    window.open(`${EDULYTE_APP_DOMAIN_URL}${pagesInfo?.EDIT_LMS_QUESTION?.pagePath}/${questionItem?.id}/edit`, "_self")
  }, [])

  const onHandleInsertQuestion = useCallback((questionItem) => {
    if (!questionItem?.title || !questionItem?.type) return;

    dispatcher(setLocalTabs({
      ...localTabs,
      tabItem: {
        ...localTabs?.tabItem,
        tabType: libraryTabConst.QUESTIONS.value,
        payload: {
          ...localTabs?.tabItem?.payload,
          questionId: questionItem?.id
        }
      }
    }))
  }, [localTabs])

  const QuestionTitleContainer = memo(({ question }) => {
    return (
      <div
        className={'flex flex-col items-start justify-start gap-1'}
        title={question?.title && question?.title}
      >
        <span className='font-bodyPri font-normal text-text-900 text-base truncate tracking-wide'>
          {question?.title
            ? (question?.title?.length > 20)
              ? question?.title?.slice(0, 20) + " ..."
              : question?.title
            : "-"
          }
        </span>
      </div>
    )
  });

  const tableRowsConst = lmsQuestionList?.data?.results?.map((question) => ([
    <QuestionTitleContainer question={question} />,
    lmsQuestionTypeEnum[question?.type?.toUpperCase()]?.label || "NA",
    dayjs(question?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",
    <LibraryActionButtons
      editBtnTooltip={"Edit Question"}
      insertBtnTooltip={"Insert Question"}
      isEnterBtnDisabled={!question?.title || !question?.type}
      onHandleEditBtn={() => onHandleEditQuestion(question)}
      onHandleInsertBtn={() => onHandleInsertQuestion(question)}
    />,
  ]))

  return (
    <div className={"flex flex-col w-full px-5 py-3 bg-white gap-2"}>
      <div className='flex items-center justify-between gap-3'>
        <span className={"font-bodyPri font-medium text-text-800 text-md tracking-wide"}>
          {"List of Questions"}
        </span>
        <div
          className={cn(
            'w-fit px-5 py-1 flex items-center justify-center border border-background-black rounded ease-in-out duration-200 group cursor-pointer',
            'hover:bg-secondary-dark hover:text-white hover:border-secondary-dark'
          )}
          onClick={onHandleCreateQuestion}
        >
          <span className={"font-bodyPri font-normal text-text-800 text-base group-hover:text-white"}>
            {"Create new question"}
          </span>
        </div>
      </div>
      {lmsQuestionList?.isLoading &&
        <ComponentLoader isLoading={lmsQuestionList?.isLoading} className={"w-full"} />
      }
      {lmsQuestionList?.message &&
        <div className={"flex items-center justify-center"}>
          <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
            {lmsQuestionList?.message}
          </span>
        </div>
      }
      {(!lmsQuestionList?.isLoading && lmsQuestionList?.data) &&
        <LMSTable
          headers={questionTableHeaderConst}
          rows={tableRowsConst}
          alternateRowColor={"bg-sky-100"}
          rowHeaderColor={"bg-sky-200"}
        />
      }
      {(!lmsQuestionList?.isLoading && (lmsQuestionList?.data?.totalPages > 1)) &&
        <div className={'w-full flex items-center justify-center'}>
          <Pagination
            page={lmsQuestionList?.data?.page}
            isScrollToTop={false}
            totalPages={lmsQuestionList?.data?.totalPages}
            onChangePage={(page) => onHandlePageChange(page)}
          />
        </div>
      }
    </div>
  )
}

export default QuestionTab