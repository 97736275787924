import { orderFilterProps, userOrderItemDetailPayload } from "./order.const";

export const ORDER_INITIAL_STATE = {
    userOrderItemList: {
        isLoading: false,
        data: null,
        message: null
    },
    userOrderItemDetail: {
        isLoading: false,
        data: null,
        payload: userOrderItemDetailPayload,
        message: null
    },
    filterProps: orderFilterProps,

    // order
    confirmationOrder: {
        isLoading: false,
        data: null,
        message: null
    },
}