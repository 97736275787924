import { createSlice } from "@reduxjs/toolkit";
import { TODO_INITIAL_STATE } from "redux/todo/todo.initialState";
import { filterName, todoStatus } from 'redux/todo/todo.const';


const todo = createSlice({
    name: "todo",
    initialState: TODO_INITIAL_STATE,
    reducers: {
        // reducers for user todo list
        setTodoListLoading: (state, { payload }) => {
            state.todoList.isLoading = payload
        },
        setTodoList: (state, { payload }) => {
            state.todoList.todoList = payload
            state.todoList.filteredTodoList = payload
            state.todoList.errorMsg = TODO_INITIAL_STATE.todoList.errorMsg
        },
        setTodoListErrorMsg: (state, { payload }) => {
            state.todoList.errorMsg = payload
            state.todoList.todoList = TODO_INITIAL_STATE.todoList.todoList
            state.todoList.filteredTodoList = TODO_INITIAL_STATE.todoList.filteredTodoList
        },
        setClearTodoList: (state) => {
            state.todoList = TODO_INITIAL_STATE.todoList
        },

        // reducer for add todo
        setAddTodoLoading: (state, { payload }) => {
            state.addTodo.isLoading = payload
        },
        setAddTodo: (state, { payload }) => {
            state.addTodo.addTodo = payload
        },
        setAddTodoErrorMsg: (state, { payload }) => {
            state.addTodo.errorMsg = payload
        },
        setAddTodoPayload: (state, { payload }) => {
            state.addTodo.addTodoPayload = payload
        },
        setClearAddTodo: (state) => {
            state.addTodo = TODO_INITIAL_STATE.addTodo
        },

        // reducer for filter
        setFilteredProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
            const filteredList = payload.list
                .filter((todo) => (
                    todo.status === payload?.filterProps.todoStatus || todoStatus.ALL.value === payload?.filterProps.todoStatus
                ))
            switch (payload.filterName) {
                case filterName.TODO.value:
                    state.todoList.filteredTodoList = filteredList
                    break;
                default:
                    break;
            }
        },
        setClearFilterProps: (state) => {
            state.filterProps = TODO_INITIAL_STATE.filterProps
            state.todoList.filteredTodoList = state.todoList.todoList
        },
    }
})

export const {
    setTodoListLoading,
    setTodoList,
    setTodoListErrorMsg,
    setClearTodoList,

    setFilteredProps,
    setClearFilterProps,

    setAddTodoLoading,
    setAddTodo,
    setAddTodoErrorMsg,
    setAddTodoPayload,
    setClearAddTodo,
} = todo.actions

export default todo.reducer;