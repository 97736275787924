import InfiniteScroll from 'react-infinite-scroll-component';

import ComponentLoader from 'components/loader/ComponentLoader';

const EndMessageContainer = () => {
    return (
        <p className={"my-1.5 w-full text-center font-bodyPri font-medium text-text-800 text-sm tracking-wide"}>
            {"You are at the bottom."}
        </p>
    )
}

const InfinitePagination = ({
    scrollableTarget = "",
    dataLength,
    fetchData,
    hasMore = false,
    loader = <ComponentLoader isLoading={true} />,
    children,
    isShowEndMessage = false,
    endMessageContainer = <EndMessageContainer />,
    onHandleRefresh,
    pullDownToRefresh = false,
    pullDownToRefreshThreshold = 50,
    props
}) => {
    return (
        <InfiniteScroll
            scrollableTarget={scrollableTarget}
            dataLength={dataLength}
            next={fetchData}
            hasMore={hasMore}
            loader={loader}
            endMessage={isShowEndMessage && endMessageContainer}
            // below props only if you need pull down functionality
            refreshFunction={onHandleRefresh}
            pullDownToRefresh={pullDownToRefresh}
            pullDownToRefreshThreshold={pullDownToRefreshThreshold}
            pullDownToRefreshContent={
                <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
            }
            releaseToRefreshContent={
                <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
            }
            {...props}
        >
            {children}
        </InfiniteScroll>
    )
}

export default InfinitePagination;