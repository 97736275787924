import { cn } from "utils/cn.utils";
import { useState, useEffect } from 'react';
import { toast } from "react-toastify";

import { MdEdit } from 'react-icons/md';
import { AiFillCheckCircle } from "react-icons/ai";
import { FaSpinner } from "react-icons/fa";
import { MdVerified } from "react-icons/md";

import ButtonLoader from "components/loader/ButtonLoader";
import IsdCodeSelector from 'components/optionSelector/IsdCodeSelector';
import InputOtp from "components/inputOtp/InputOtp";

import { getMasterCountryList } from 'redux/master/master.request';
import { createSendOtp, createVerifyOtp } from 'redux/notification/notification.request';
import { updateUserAuthByUserId } from 'redux/auth/auth.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { otpStatus } from 'redux/notification/notification.const';
import { setClearSendOtp, setClearVerifyOtp } from 'redux/notification/notification.slice';
import { setModal } from "redux/local/local.slice";
import { setClearCountryList } from 'redux/master/master.slice';
import { modalConst } from "redux/local/local.const";
import { setClearAddUserAuth } from 'redux/auth/auth.slice';
import { otpDeliveryMethod } from 'redux/notification/notification.const';

import { validateMobileNo } from 'utils/validation-functions';

const ValidationBarMobileVerifyModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { countryList, timeZoneCountryDetail } = useAppState(s => s.master)
    const { addUserAuth } = useAppState((state) => state.auth)
    const { user } = useAppState((state) => state.user)
    const { sendOtp, verifyOtp } = useAppState((s) => s.notification)

    const dispatcher = useAppDispatcher()

    const [myOtp, setMyOtp] = useState("")
    const [isOtpError, setIsOtpError] = useState(false)

    const [isOpenMobileOtpContainer, setIsOpenMobileOtpContainer] = useState(false)
    const [isMobileEditMode, setIsMobileEditMode] = useState(false)
    const [myCountry, setMyCountry] = useState({
        masterCountryId: 0,
        country: "",
        countryDomain: "",
        isdCode: ""
    })
    const [mobileDetail, setMobileDetail] = useState({
        countryCode: user?.user?.isdCode,
        mobileNo: user?.user?.mobileNo
    })

    useEffect(() => {
        dispatcher(getMasterCountryList())
        setIsMobileEditMode(true)

        return () => {
            dispatcher(setClearCountryList())
        }
    }, [])

    useEffect(() => {
        if (countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country) => (
                    country.countryDomain === "US"
                ))
            }
            setMyCountry(myLocalCountryList[0])
        }
    }, [countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

    useEffect(() => {
        if (addUserAuth?.addUserAuth) {
            dispatcher(setModal({
                ...modal,
                [modalConst.VALIDATION_BAR_MOBILE_VERIFY_MODAL.stateKey]: false
            }))
        }
        return () => {
            dispatcher(setClearAddUserAuth())
        }
    }, [addUserAuth?.addUserAuth])

    useEffect(() => {
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            dispatcher(setClearVerifyOtp())
            dispatcher(setClearSendOtp())
            dispatcher(updateUserAuthByUserId(
                user?.user?.userId,
                {
                    mobileNo: mobileDetail?.countryCode + "-" + mobileDetail?.mobileNo
                }
            ))
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.PENDING.value) {
            toast.warning("Something went wrong")
            dispatcher(setClearSendOtp())
            dispatcher(setClearVerifyOtp())
            dispatcher(setModal({
                ...modal,
                [modalConst.VALIDATION_BAR_MOBILE_VERIFY_MODAL.stateKey]: false,
                [modalConst.MANUAL_MOBILE_VERIFICATION_MODAL.stateKey]: true
            }))
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.EXPIRED.value) {
            toast.error("Code Expired")
            dispatcher(setClearSendOtp())
            dispatcher(setClearVerifyOtp())
            dispatcher(setModal({
                ...modal,
                [modalConst.VALIDATION_BAR_MOBILE_VERIFY_MODAL.stateKey]: false,
                [modalConst.MANUAL_MOBILE_VERIFICATION_MODAL.stateKey]: true
            }))
            return;
        }
        if (!verifyOtp?.verifyOtp && verifyOtp?.errorMsg) {
            dispatcher(setClearSendOtp())
            dispatcher(setClearVerifyOtp())
            dispatcher(setModal({
                ...modal,
                [modalConst.VALIDATION_BAR_MOBILE_VERIFY_MODAL.stateKey]: false,
                [modalConst.MANUAL_MOBILE_VERIFICATION_MODAL.stateKey]: true
            }))
        }
    }, [verifyOtp?.verifyOtp || verifyOtp?.errorMsg])

    useEffect(() => {
        if (sendOtp?.sendOtp) {
            setIsOpenMobileOtpContainer(true)
        }
        if (sendOtp?.errorMsg) {
            setIsOpenMobileOtpContainer(false)
            dispatcher(setClearSendOtp())
            dispatcher(setClearVerifyOtp())
            dispatcher(setModal({
                ...modal,
                [modalConst.VALIDATION_BAR_MOBILE_VERIFY_MODAL.stateKey]: false,
                [modalConst.MANUAL_MOBILE_VERIFICATION_MODAL.stateKey]: true
            }))
        }
    }, [sendOtp?.sendOtp, sendOtp?.errorMsg])

    const handleEditMobileNo = () => {
        setIsOpenMobileOtpContainer(false)
        if (isMobileEditMode) {
            if (validateMobileNo(mobileDetail?.mobileNo)) {
                setMobileDetail({
                    countryCode: myCountry?.isdCode,
                    mobileNo: mobileDetail?.mobileNo
                })
                setIsMobileEditMode(!isMobileEditMode)
            }
            else {
                toast.warning("Enter Valid Mobile Number!")
            }
        }
        else {
            setIsMobileEditMode(!isMobileEditMode)
        }
    }

    const handleMobileVerification = async () => {
        if (!mobileDetail?.mobileNo) {
            toast.warning("Please Enter mobile number for Verification")
            setIsMobileEditMode(true)
        }
        if (isOpenMobileOtpContainer) {
            if (myOtp?.length < 6 || sendOtp?.isLoading || verifyOtp?.isLoading) return;
            dispatcher(setClearVerifyOtp())
            if (sendOtp?.sendOtp?.otpId) {
                dispatcher(createVerifyOtp({
                    otpId: sendOtp?.sendOtp?.otpId,
                    otp: myOtp
                }))
            }
        }
        if (!isOpenMobileOtpContainer) {
            if (isMobileEditMode) return;
            if (validateMobileNo(mobileDetail?.mobileNo) && !isMobileEditMode) {
                dispatcher(createSendOtp({
                    channel: otpDeliveryMethod.SMS.value,
                    mobileNo: mobileDetail?.countryCode + "-" + mobileDetail?.mobileNo
                }))
            }
        }
    }

    const onSelectIsdCode = (option) => {
        const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option.value)
        setMyCountry(selectedCountry)
    }

    if (user?.user?.isMobileNoVerified) {
        return (
            <div className={'flex flex-col justify-center items-center space-y-2'}>
                <MdVerified className={'text-4xl text-green-500'} />
                <div className={"flex items-center justify-center gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                        {"Phone:"}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                        {user?.user?.mobileNo}
                    </span>
                </div>
                <span className={'w-full font-bodyPri font-semibold text-lg text-text-900 text-center'}>
                    {"Phone number verified successfully"}
                </span>
            </div>
        )
    }

    return (
        <div className={'space-y-5 overflow-hidden'}>
            <div className={"min-h-[16rem] block w-full space-y-5"}>
                <div className={cn(
                    !isMobileEditMode && 'flex flex-col items-center justify-start gap-3 md:flex-row md:items-center md:justify-center',
                    isMobileEditMode && "flex flex-col items-center justify-start gap-3"
                )}>
                    <span className='font-bodyPri font-normal text-text-700 text-base whitespace-nowrap'>
                        {"Phone No. : "}
                    </span>
                    <div className={"flex items-center justify-start gap-5"}>
                        {!isMobileEditMode &&
                            <span className='font-bodySec font-normal text-text-900 text-base'>
                                {mobileDetail?.mobileNo && "+" + mobileDetail?.countryCode + " - " + mobileDetail?.mobileNo}
                            </span>
                        }
                        {isMobileEditMode &&
                            <div className={cn(
                                "w-full flex items-center justify-start px-2 py-1.5 input-number-spin-none border-2 border-secondary-main border-dashed focus:outline-none",
                                "font-bodySec font-normal text-text-900 text-base"
                            )}>
                                {countryList?.isLoading &&
                                    <FaSpinner className={"text-lg text-primary-main animate-spin"} />
                                }
                                {countryList?.countryList &&
                                    <IsdCodeSelector
                                        options={countryList?.countryList?.map((country) => ({
                                            label: country?.isdCode,
                                            value: country?.masterCountryId,
                                            country: country
                                        }))}
                                        value={myCountry?.masterCountryId}
                                        onChange={(option) => onSelectIsdCode(option)}
                                    />
                                }
                                <input
                                    type={"number"}
                                    className={cn(
                                        "w-full px-3 py-1 input-number-spin-none focus:outline-none",
                                        "font-bodySec font-normal text-text-900 text-base"
                                    )}
                                    value={mobileDetail?.mobileNo}
                                    autoFocus={true}
                                    onChange={(e) => setMobileDetail({
                                        ...mobileDetail,
                                        mobileNo: e.target.value
                                    })}
                                />
                            </div>
                        }
                        <div className={'cursor-pointer'} onClick={handleEditMobileNo}>
                            {!isMobileEditMode && <MdEdit className='text-2xl text-text-500' />}
                            {isMobileEditMode && <AiFillCheckCircle className='text-2xl text-secondary-main' />}
                        </div>
                    </div>
                </div>
                {isOpenMobileOtpContainer &&
                    <InputOtp
                        mobileNo={mobileDetail?.countryCode + "-" + mobileDetail?.mobileNo}
                        otpLength={6}
                        setMyOtp={setMyOtp}
                        isOtpError={isOtpError}
                        setIsOtpError={setIsOtpError}
                    />
                }
                {/* <div className={'flex items-center justify-center'} id={'recaptcha-container'}>
                </div> */}
                {addUserAuth?.errorMsg &&
                    <div className={"flex justify-center items-center gap-1"}>
                        <span className={"w-full font-bodyPri font-bold text-primary-main text-lg whitespace-nowrap text-center"}>
                            {addUserAuth?.errorMsg}
                        </span>
                    </div>
                }
                <div
                    className={cn(
                        "py-1.5 rounded-lg",
                        "text-text-50 font-buttons font-medium text-base w-full text-center",
                        (!mobileDetail?.mobileNo || isMobileEditMode || (isOpenMobileOtpContainer && myOtp?.length < 6)) && "bg-divider-medium !text-text-500",
                        ((!isMobileEditMode && !isOpenMobileOtpContainer && mobileDetail?.mobileNo) || (isOpenMobileOtpContainer && myOtp?.length === 6)) && "bg-primary-main hover:bg-secondary-dark cursor-pointer",
                        (sendOtp?.isLoading || verifyOtp?.isLoading) && "bg-primary-main"
                    )}
                    onClick={handleMobileVerification}
                >
                    {(sendOtp?.isLoading || verifyOtp?.isLoading || addUserAuth?.isLoading) &&
                        <ButtonLoader isLoading={sendOtp?.isLoading || verifyOtp?.isLoading || addUserAuth?.isLoading} />
                    }
                    {(!verifyOtp?.isLoading && !addUserAuth?.isLoading && isOpenMobileOtpContainer) && "Verify Code"}
                    {(!sendOtp?.isLoading && !isOpenMobileOtpContainer) && "Send Code"}
                </div>
            </div>
        </div>
    )
}

export default ValidationBarMobileVerifyModal;