import tw, { styled } from "twin.macro";

export const StyledChatViewCourse = styled.div(({ isSender }) => [
  tw`flex items-center`,
  isSender && tw`justify-end`,
]);

export const StyledChatViewCourseText = styled.div(({ isSender }) => [
  tw`flex flex-col bg-white text-base mb-2 p-2.5 rounded-md max-width[250px] md:max-width[250px] shadow-md`,
  isSender && tw`bg-primary-dark text-white`,
]);

export const StyledChatViewCourseTextTime = styled.p(({ isSender }) => [
  tw`italic text-xs text-gray-500 flex justify-end border-b py-1.5`,
  isSender && tw`text-white`,
]);

export const StyledCardHeader = styled.h3(({ isSender }) => [
  tw`font-bold text-base text-primary-dark`,
  isSender && tw`text-white`,
]);

export const StyledCardDescription = styled.p(({ isSender }) => [
  tw`text-sm text-gray-500`,
  isSender && tw`text-white`,
]);

export const StyledCardLink = styled.a(({ isSender }) => [
  tw`text-sm text-primary-dark text-center py-1 my-2 font-bold cursor-pointer rounded-md bg-primary-main text-white`,
  isSender && tw`text-white bg-secondary-main`,
]);

export const StyledChatViewCourseImage = styled.div`
  ${tw`mb-2`}
`;

export const StyledChatViewCourseImageContainer = styled.img`
  ${tw`width[250px] rounded-md`}
`;
