import React from "react";

//Context imports
import { useChat } from "../../contexts/chatContext";

//Style imports
import { StyledChatFilterModalListItem } from "./ChatFilterModal.styles";

export default function ChatFilterModalListItem({ filter }) {
  const { filters, dispatch } = useChat();
  const filterToDisplay = filter === "sent" ? "unread" : filter;

  function selectFilter() {
    const newFilters = filters;

    if (filters.includes(filter)) {
      const index = newFilters.indexOf(filter);
      newFilters.splice(index, 1);
      dispatch({ type: "SET_FILTERS", payload: newFilters });
      return;
    }

    if (filter === "individual" || filter === "group") {
      filters.forEach((filter) => {
        if (filter !== "sent") {
          const index = newFilters.indexOf(filter);
          newFilters.splice(index, 1);
        }
      });
    }

    dispatch({
      type: "SET_FILTERS",
      payload: [...newFilters, filter],
    });
  }

  const selected = filters.includes(filter);

  return (
    <StyledChatFilterModalListItem onClick={selectFilter} selected={selected}>
      <p>
        {filterToDisplay.charAt(0).toUpperCase() + filterToDisplay.slice(1)}
      </p>
    </StyledChatFilterModalListItem>
  );
}
