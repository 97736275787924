import { memo, useCallback } from "react";

import { cn } from "utils/cn.utils";

import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlinePreview } from "react-icons/md";

import ToolTipView from "components/tooltipView";

import Drag from "pages/auth/edulyteLms/assets/drag.svg";

import { deleteLmsQuizCategoryDetail } from "redux/edulyteLms/lmsQuiz/lmsQuiz.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetPreviewImageContent, setModal, setPreviewImageContent } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { lmsResourceTypeEnum, resourceFileTypeEnum } from "redux/edulyteLms/lmsResource/lmsResource.const";

const QuizSectionImage = memo(({ quizSectionCategoryItem }) => {
    const { modal, previewImage } = useAppState(s => s.local)

    const dispatcher = useAppDispatcher()

    const onHandlePreviewImage = useCallback(() => {
        dispatcher(resetPreviewImageContent())
        dispatcher(setPreviewImageContent({
            ...previewImage,
            previewUrl: quizSectionCategoryItem?.resource?.resource?.resource?.file?.file_url
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.LMS_PREVIEW_IMAGE_MODAL.stateKey]: true
        }))

    }, [quizSectionCategoryItem, modal, previewImage])

    const onHandleDeleteImage = useCallback(() => {
        dispatcher(deleteLmsQuizCategoryDetail(quizSectionCategoryItem?.id, quizSectionCategoryItem?.quiz_section))
    }, [quizSectionCategoryItem])

    if (
        !quizSectionCategoryItem?.resource ||
        !quizSectionCategoryItem?.resource?.resource ||
        ((quizSectionCategoryItem?.resource?.resource?.type === lmsResourceTypeEnum.FILE.value && quizSectionCategoryItem?.resource?.resource?.resource?.type === resourceFileTypeEnum.IMAGE.value) && !(
            quizSectionCategoryItem?.resource?.resource?.resource?.file ||
            quizSectionCategoryItem?.resource?.resource?.resource?.file?.file_url
        ))
    ) {
        return (
            <div className={"flex items-center justify-center px-5 py-2"}>
                <span className={"font-bodyPri font-semibold text-2xl text-test-800"}>
                    {"Image not found!"}
                </span>
            </div>
        )
    }

    return quizSectionCategoryItem?.resource?.resource?.resource?.file && (
        <div className={"w-full px-5 py-2.5 bg-white rounded-lg space-y-5 transition-transform ease-in-out duration-300"}>
            <div className={cn("flex flex-col md:flex-row items-end md:items-start justify-between gap-2")}>
                <div className={cn('w-full flex items-start gap-5')}>
                    <img
                        src={Drag}
                        className={"mt-1.5 w-2.5 cursor-grab"}
                    />
                    <span className={"font-bodyPri font-medium text-text-900 text-md tracking-wide"}>
                        {"Image"}
                    </span>
                </div>
                <div className={'flex justify-end items-center gap-1.5'}>
                    <ToolTipView content={"Preview Image"}>
                        <button className={"p-2 rounded-full hover:bg-divider-darkLight"}>
                            <MdOutlinePreview
                                className={"text-xl text-text-700 cursor-pointer"}
                                onClick={onHandlePreviewImage}
                            />
                        </button>
                    </ToolTipView>
                    <ToolTipView content={"Remove Image"}>
                        <button className={"p-2 rounded-full hover:bg-divider-darkLight"}>
                            <RiDeleteBin6Line
                                className={"text-xl text-text-700 hover:text-red-500 cursor-pointer"}
                                onClick={onHandleDeleteImage}
                            />
                        </button>
                    </ToolTipView>
                </div>
            </div>
            <div className={"px-0 flex items-center justify-center md:justify-start md:px-5"}>
                <div className={"w-[12rem] h-[12rem] aspect-video overflow-hidden"}>
                    <img
                        className={"w-full h-full object-cover"}
                        src={quizSectionCategoryItem?.resource?.resource?.resource?.file?.file_url}
                        alt={"Image-url"}
                    />
                </div>
            </div>
        </div>
    )
});

export default QuizSectionImage;