import { createSlice } from "@reduxjs/toolkit";

import { APPOINTMENT_INITIAL_STATE } from "redux/appointment/appointment.initialState";

const appointment = createSlice({
    name: "appointment",
    initialState: APPOINTMENT_INITIAL_STATE,
    reducers: {
        // update user appointment detail
        setUpdateUserAppointmentDetailLoading: (state, { payload }) => {
            state.updateUserAppointmentDetail.isLoading = payload
        },
        setUpdateUserAppointmentDetailData: (state, { payload }) => {
            state.updateUserAppointmentDetail.data = payload
            state.updateUserAppointmentDetail.message = APPOINTMENT_INITIAL_STATE.updateUserAppointmentDetail.message
        },
        setUpdateUserAppointmentDetailMessage: (state, { payload }) => {
            state.updateUserAppointmentDetail.message = payload
            state.updateUserAppointmentDetail.data = APPOINTMENT_INITIAL_STATE.updateUserAppointmentDetail.data
        },
        resetUpdateUserAppointmentDetail: (state) => {
            state.updateUserAppointmentDetail = APPOINTMENT_INITIAL_STATE.updateUserAppointmentDetail
        },

        // update user appointment detail payload
        setUpdateUserAppointmentDetailPayload: (state, { payload }) => {
            state.updateUserAppointmentDetail.payload = payload
        },
        resetUpdateUserAppointmentDetailPayload: (state) => {
            state.updateUserAppointmentDetail.payload = APPOINTMENT_INITIAL_STATE.updateUserAppointmentDetail.payload
        },

        // public appointments
        setPublicAppointmentsLoading: (state, { payload }) => {
            state.publicAppointments.isLoading = payload
        },
        setPublicAppointmentsData: (state, { payload }) => {
            state.publicAppointments.data = payload
            state.publicAppointments.message = APPOINTMENT_INITIAL_STATE.publicAppointments.message
        },
        setPublicAppointmentsStatus: (state, { payload }) => {
            state.publicAppointments.status = payload
        },
        setPublicAppointmentsMessage: (state, { payload }) => {
            state.publicAppointments.message = payload
            state.publicAppointments.data = APPOINTMENT_INITIAL_STATE.publicAppointments.data
        },
        resetPublicAppointments: (state, { payload }) => {
            state.publicAppointments = APPOINTMENT_INITIAL_STATE.publicAppointments
        },

        // public appointment
        setPublicAppointmentLoading: (state, { payload }) => {
            state.publicAppointment.isLoading = payload
        },
        setPublicAppointmentData: (state, { payload }) => {
            state.publicAppointment.data = payload
            state.publicAppointment.message = APPOINTMENT_INITIAL_STATE.publicAppointment.message
        },
        setPublicAppointmentStatus: (state, { payload }) => {
            state.publicAppointment.status = payload
        },
        setPublicAppointmentMessage: (state, { payload }) => {
            state.publicAppointment.message = payload
            state.publicAppointment.data = APPOINTMENT_INITIAL_STATE.publicAppointment.data
        },
        resetPublicAppointment: (state, { payload }) => {
            state.publicAppointment = APPOINTMENT_INITIAL_STATE.publicAppointment
        },

        // user appointment list
        setUserAppointmentListLoading: (state, { payload }) => {
            state.userAppointmentList.isLoading = payload
        },
        setUserAppointmentListData: (state, { payload }) => {
            state.userAppointmentList.data = payload
            state.userAppointmentList.message = APPOINTMENT_INITIAL_STATE.userAppointmentList.message
        },
        setUserAppointmentListMessage: (state, { payload }) => {
            state.userAppointmentList.message = payload
            state.userAppointmentList.data = APPOINTMENT_INITIAL_STATE.userAppointmentList.data
        },
        resetUserAppointmentList: (state) => {
            state.userAppointmentList = APPOINTMENT_INITIAL_STATE.userAppointmentList
        },

        // user appointment detail
        setUserAppointmentDetailLoading: (state, { payload }) => {
            state.userAppointmentDetail.isLoading = payload
        },
        setUserAppointmentDetailData: (state, { payload }) => {
            state.userAppointmentDetail.data = payload
            state.userAppointmentDetail.message = APPOINTMENT_INITIAL_STATE.userAppointmentDetail.message
        },
        setUserAppointmentDetailMessage: (state, { payload }) => {
            state.userAppointmentDetail.message = payload
            state.userAppointmentDetail.data = APPOINTMENT_INITIAL_STATE.userAppointmentDetail.data
        },
        resetUserAppointmentDetail: (state) => {
            state.userAppointmentDetail = APPOINTMENT_INITIAL_STATE.userAppointmentDetail
        },

        // add appointment detail
        setAddAppointmentDetailLoading: (state, { payload }) => {
            state.addAppointmentDetail.isLoading = payload
        },
        setAddAppointmentDetailData: (state, { payload }) => {
            state.addAppointmentDetail.data = payload
            state.addAppointmentDetail.message = APPOINTMENT_INITIAL_STATE.addAppointmentDetail.message
        },
        setAddAppointmentDetailMessage: (state, { payload }) => {
            state.addAppointmentDetail.message = payload
            state.addAppointmentDetail.data = APPOINTMENT_INITIAL_STATE.addAppointmentDetail.data
        },
        resetAddAppointmentDetail: (state) => {
            state.addAppointmentDetail = APPOINTMENT_INITIAL_STATE.addAppointmentDetail
        },

        // modify appointment detail
        setModifyAppointmentDetailLoading: (state, { payload }) => {
            state.modifyAppointmentDetail.isLoading = payload
        },
        setModifyAppointmentDetailData: (state, { payload }) => {
            state.modifyAppointmentDetail.data = payload
            state.modifyAppointmentDetail.message = APPOINTMENT_INITIAL_STATE.modifyAppointmentDetail.message
        },
        setModifyAppointmentDetailMessage: (state, { payload }) => {
            state.modifyAppointmentDetail.message = payload
            state.modifyAppointmentDetail.data = APPOINTMENT_INITIAL_STATE.modifyAppointmentDetail.data
        },
        resetModifyAppointmentDetail: (state) => {
            state.modifyAppointmentDetail = APPOINTMENT_INITIAL_STATE.modifyAppointmentDetail
        },

        // appointment detail payload
        setModifyAppointmentDetailPayload: (state, { payload }) => {
            state.modifyAppointmentDetail.payload = payload
        },
        resetModifyAppointmentDetailPayload: (state) => {
            state.modifyAppointmentDetail.payload = APPOINTMENT_INITIAL_STATE.modifyAppointmentDetail.payload
        },

        // destroy appointment detail
        setDestroyAppointmentDetailLoading: (state, { payload }) => {
            state.destroyAppointmentDetail.isLoading = payload
        },
        setDestroyAppointmentDetailData: (state, { payload }) => {
            state.destroyAppointmentDetail.data = payload
            state.destroyAppointmentDetail.message = APPOINTMENT_INITIAL_STATE.destroyAppointmentDetail.message
        },
        setDestroyAppointmentDetailMessage: (state, { payload }) => {
            state.destroyAppointmentDetail.message = payload
            state.destroyAppointmentDetail.data = APPOINTMENT_INITIAL_STATE.destroyAppointmentDetail.data
        },
        resetDestroyAppointmentDetail: (state) => {
            state.destroyAppointmentDetail = APPOINTMENT_INITIAL_STATE.destroyAppointmentDetail
        },

        // appointment location list
        setAppointmentLocationListLoading: (state, { payload }) => {
            state.appointmentLocationList.isLoading = payload
        },
        setAppointmentLocationListData: (state, { payload }) => {
            state.appointmentLocationList.data = payload
            state.appointmentLocationList.message = APPOINTMENT_INITIAL_STATE.appointmentLocationList.message
        },
        setAppointmentLocationListMessage: (state, { payload }) => {
            state.appointmentLocationList.message = payload
            state.appointmentLocationList.data = APPOINTMENT_INITIAL_STATE.appointmentLocationList.data
        },
        resetAppointmentLocationList: (state) => {
            state.appointmentLocationList = APPOINTMENT_INITIAL_STATE.appointmentLocationList
        },

        // add appointment location detail
        setAddAppointmentLocationDetailLoading: (state, { payload }) => {
            state.addAppointmentLocationDetail.isLoading = payload
        },
        setAddAppointmentLocationDetailData: (state, { payload }) => {
            state.addAppointmentLocationDetail.data = payload
            state.addAppointmentLocationDetail.message = APPOINTMENT_INITIAL_STATE.addAppointmentLocationDetail.message
        },
        setAddAppointmentLocationDetailMessage: (state, { payload }) => {
            state.addAppointmentLocationDetail.message = payload
            state.addAppointmentLocationDetail.data = APPOINTMENT_INITIAL_STATE.addAppointmentLocationDetail.data
        },
        resetAddAppointmentLocationDetail: (state) => {
            state.addAppointmentLocationDetail = APPOINTMENT_INITIAL_STATE.addAppointmentLocationDetail
        },

        // modify appointment location detail
        setModifyAppointmentLocationDetailLoading: (state, { payload }) => {
            state.modifyAppointmentLocationDetail.isLoading = payload
        },
        setModifyAppointmentLocationDetailData: (state, { payload }) => {
            state.modifyAppointmentLocationDetail.data = payload
            state.modifyAppointmentLocationDetail.message = APPOINTMENT_INITIAL_STATE.modifyAppointmentLocationDetail.message
        },
        setModifyAppointmentLocationDetailMessage: (state, { payload }) => {
            state.modifyAppointmentLocationDetail.message = payload
            state.modifyAppointmentLocationDetail.data = APPOINTMENT_INITIAL_STATE.modifyAppointmentLocationDetail.data
        },
        resetModifyAppointmentLocationDetail: (state) => {
            state.modifyAppointmentLocationDetail = APPOINTMENT_INITIAL_STATE.modifyAppointmentLocationDetail
        },

        // destroy appointment location detail
        setDestroyAppointmentLocationDetailLoading: (state, { payload }) => {
            state.destroyAppointmentLocationDetail.isLoading = payload
        },
        setDestroyAppointmentLocationDetailMessage: (state, { payload }) => {
            state.destroyAppointmentLocationDetail.message = payload
        },
        resetDestroyAppointmentLocationDetail: (state) => {
            state.destroyAppointmentLocationDetail = APPOINTMENT_INITIAL_STATE.destroyAppointmentLocationDetail
        },
    }
})

export const {
    setPublicAppointmentsLoading,
    setPublicAppointmentsData,
    setPublicAppointmentsStatus,
    setPublicAppointmentsMessage,
    resetPublicAppointments,

    setPublicAppointmentLoading,
    setPublicAppointmentData,
    setPublicAppointmentStatus,
    setPublicAppointmentMessage,
    resetPublicAppointment,

    setUserAppointmentListLoading,
    setUserAppointmentListData,
    setUserAppointmentListMessage,
    resetUserAppointmentList,
    
    setUserAppointmentDetailLoading,
    setUserAppointmentDetailData,
    setUserAppointmentDetailMessage,
    resetUserAppointmentDetail,

    setAddAppointmentDetailLoading,
    setAddAppointmentDetailData,
    setAddAppointmentDetailMessage,
    resetAddAppointmentDetail,

    setModifyAppointmentDetailLoading,
    setModifyAppointmentDetailData,
    setModifyAppointmentDetailMessage,
    resetModifyAppointmentDetail,

    setModifyAppointmentDetailPayload,
    resetModifyAppointmentDetailPayload,

    setDestroyAppointmentDetailLoading,
    setDestroyAppointmentDetailData,
    setDestroyAppointmentDetailMessage,
    resetDestroyAppointmentDetail,

    setAppointmentLocationListLoading,
    setAppointmentLocationListData,
    setAppointmentLocationListMessage,
    resetAppointmentLocationList,

    setAddAppointmentLocationDetailLoading,
    setAddAppointmentLocationDetailData,
    setAddAppointmentLocationDetailMessage,
    resetAddAppointmentLocationDetail,

    setModifyAppointmentLocationDetailLoading,
    setModifyAppointmentLocationDetailData,
    setModifyAppointmentLocationDetailMessage,
    resetModifyAppointmentLocationDetail,

    setDestroyAppointmentLocationDetailLoading,
    setDestroyAppointmentLocationDetailMessage,
    resetDestroyAppointmentLocationDetail,
} = appointment.actions;

export default appointment.reducer;