import { useNavigate } from "react-router-dom";

import ToolTipView from "components/tooltipView";

import {
    SubMenuItem,
    ItemName
} from './NavBarStyle';

const HamburgerSubMenu = ({ menuItem, setIsMediumHamburger }) => {

    const navigate = useNavigate()

    const onHandleSubMenuItems = (event) => {
        if (menuItem?.pathType === 'href') {
            window.open(menuItem?.path, "_blank")
        } else {
            navigate(menuItem?.path)
        }
        setIsMediumHamburger(false)

        event.preventDefault();
    }


    return (
        <ToolTipView content={menuItem?.name}>
            <SubMenuItem onClick={onHandleSubMenuItems}>
                <ItemName >
                    {menuItem?.name}
                </ItemName >
            </SubMenuItem>
        </ToolTipView>
    )
}

export default HamburgerSubMenu;