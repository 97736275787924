import { availabilityEndTypeEnum, addUserAvailabilityDetailPayload, modifyUserAvailabilityDetailPayload } from "redux/availability/availability.const";
import { weekAvailTimeSlots } from "redux/course/course.const";

import { dayjs } from "utils/dateTime.utils";

const addAvailabilityDetailPayload = {
    userId: null,
    name: null,
    description: null,
    type: null,
    minTimeBeforeAvailStart: 720,
    advanceSlotPeriodDays: 30,
    isRecurring: null,
    timeZone: null,
    weeklyTimeSlots: weekAvailTimeSlots,
    startDateTime: dayjs(new Date().setDate(new Date().getDate() + 1))?.format("YYYY-MM-DD HH:mm:ss"),
    endType: availabilityEndTypeEnum?.FOREVER?.value
}

const addOffDayDetailPayload = {
    userId: null,
    offDayId: null,
    name: "",
    description: "",
    timeZone: null,
    startDateTime: dayjs(new Date().setDate(new Date().getDate() + 1))?.format("YYYY-MM-DD HH:mm:ss"),
    endDateTime: dayjs(new Date().setDate(new Date().getDate() + 2))?.format("YYYY-MM-DD HH:mm:ss")
}

export const AVAILABILITY_INITIAL_STATE = {
    userAvailabilityList: {
        isLoading: false,
        data: null,
        message: null
    },
    addUserAvailabilityDetail: {
        isLoading: false,
        data: null,
        payload: addUserAvailabilityDetailPayload,
        message: null
    },
    userAvailabilityDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    modifyUserAvailabilityDetail: {
        isLoading: false,
        data: null,
        payload: modifyUserAvailabilityDetailPayload,
        message: null,
    },
    destroyUserAvailabilityDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    // off day
    offDayList: {
        isLoading: false,
        offDayList: null,
        errorMsg: null
    },

    offDayDetail: {
        isLoading: false,
        offDayDetail: null,
        errorMsg: null
    },
    addOffDayDetail: {
        isLoading: false,
        addOffDayDetail: null,
        addOffDayDetailPayload: addOffDayDetailPayload,
        errorMsg: null
    },

    // available slot detail
    availableSlotDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    ////////////////////////////////////////////////////////////
    
    availability: {
        isLoading: false,
        availability: null,
        errorMsg: null,
    },

    availabilityDetail: {
        isLoading: false,
        availabilityDetail: null,
        errorMsg: null
    },
    addAvailabilityDetail: {
        isLoading: false,
        addAvailabilityDetail: null,
        addAvailabilityDetailPayload: addAvailabilityDetailPayload,
        errorMsg: null
    },
}