export const url = {
    GET_CERTIFICATE_LIST: "/certificates/owner-users/{userId}",
    CREATE_CERTIFICATE: "/certificates/",
    GET_CERTIFICATE_TEMPLATE_LIST: "/certificates/templates",
    GET_CERTIFICATE_DETAILS: "/certificates/{certificateId}/owner-users/{userId}",
    UPDATE_CERTIFICATE: "/certificates/{certificateId}",
    STUDENT_CERTIFICATES: "/certificates/users/{userId}",
    CERTIFICATE_VERIFY: "/certificates/verify",
    DELETE_CERTIFICATE: "/certificates/{certificateId}",
    CLONE_CERTIFICATE: "/certificates/{certificateId}/clone",
}