import { reviewVisibility } from "redux/review/review.const";

const addUserReviewDetailPayload = {
    userId: null,
    recieverUserId: null,
    providerUserId: null,
    rating: null,
    review: "",
    visibility: reviewVisibility.PUBLIC.value,
    isNavigateToTutorPage: false
}

export const REVIEW_INITIAL_STATE = {
    tutorReviewList: {
        isLoading: false,
        tutorReviewList: null,
        filteredTutorReviewList: null,
        errorMsg: null
    },
    userReviewList: {
        isLoading: false,
        userReviewList: null,
        filteredUserReviewList: null,
        errorMsg: null
    },
    addUserReviewDetail: {
        isLoading: false,
        addUserReviewDetail: null,
        addUserReviewDetailPayload: addUserReviewDetailPayload,
        errorMsg: null
    }
}