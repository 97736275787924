import ButtonLoader from 'components/loader/button-loader';

import { cn } from 'utils/cn.utils';

const ProductPriceBtn = ({
    isLoading = false,
    disabled = false,
    onHandleProductBtn,
    btnText = "Add Pricing (Product)"
}) => {

    return (
        <div
            className={cn(
                "w-fit px-5 py-2 flex items-center justify-center rounded-md",
                disabled && "bg-divider-medium text-text-400 cursor-not-allowed",
                !disabled && "bg-primary-dark text-text-50 cursor-pointer hover:opacity-90"
            )}
            onClick={onHandleProductBtn}
        >
            {isLoading &&
                <ButtonLoader isLoading={isLoading} className={"w-20 py-2"} />
            }
            {!isLoading &&
                <span className={"font-bodyPri font-medium text-base tracking-wide"}>
                    {btnText}
                </span>
            }
        </div>
    )
}

export default ProductPriceBtn