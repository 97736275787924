import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import AddContactEmailItem from "components/modals/crmModals/createCrmContactDetailModal/commonComponents/ContactEmailItem";
import ContactEmailItem from "components/modals/crmModals/viewContactDetailModal/commonComponents/ContactEmailItem";
import AddItemBtn from "components/modals/crmModals/commonComponents/AddItemBtn";
import { crmDetailBtnConst, sortDefaultContact } from "components/modals/crmModals/data";

import crmService from "redux/crm/crm.service";
import { createCrmContactEmailDetail, deleteCrmContactEmailDetail, updateCrmContactEmailDetail } from "redux/crm/crm.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetAddCrmContactEmailDetail, resetModifyCrmContactEmailDetail, setCrmContactDetailData, setModifyCrmContactDetailPayload } from "redux/crm/crm.slice";
import { crmContactLabelsEnum } from "redux/crm/crm.const";

const newEmailInitialState = {
    id: 0,
    isShowInput: false,
    email: "",
    label: null,
    is_primary: false
}

function EditContactEmails({ editBtnConst, setEditBtnConst }) {
    const { crmContactDetail, addCrmContactEmailDetail, modifyCrmContactDetail, modifyCrmContactEmailDetail, destroyCrmContactEmailDetail } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()

    const [newEmailDetail, setNewEmailDetail] = useState({
        id: 0,
        isShowInput: false,
        email: "",
        label: null,
        is_primary: false
    })

    const getUpdatedContactEmailDetail = () => {
        if (addCrmContactEmailDetail?.data) {
            const emailPayload = {
                id: addCrmContactEmailDetail?.data?.id,
                email: newEmailDetail?.email,
                label: newEmailDetail?.label,
                is_primary: newEmailDetail?.is_primary
            }
            if (!!modifyCrmContactDetail?.payload?.emails?.length && newEmailDetail?.is_primary) {
                const filteredEmailList = modifyCrmContactDetail?.payload?.emails?.map((item) => ({ ...item, is_primary: false }))
                dispatcher(setModifyCrmContactDetailPayload({
                    ...modifyCrmContactDetail?.payload,
                    emails: [...filteredEmailList, emailPayload]
                }))
                dispatcher(setCrmContactDetailData({
                    ...crmContactDetail?.data,
                    email: addCrmContactEmailDetail?.data
                }))
            } else {
                dispatcher(setModifyCrmContactDetailPayload({
                    ...modifyCrmContactDetail?.payload,
                    emails: [...modifyCrmContactDetail?.payload?.emails, emailPayload]
                }))
            }
            dispatcher(resetAddCrmContactEmailDetail())
        } else if (modifyCrmContactEmailDetail?.data) {
            const emailPayload = {
                id: modifyCrmContactEmailDetail?.data?.id,
                email: newEmailDetail?.email,
                label: newEmailDetail?.label,
                is_primary: newEmailDetail?.is_primary
            }
            dispatcher(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                emails: modifyCrmContactDetail?.payload?.emails?.map((emailItem) => (
                    (emailItem?.id === modifyCrmContactEmailDetail?.data?.id) ? emailPayload : emailItem
                ))
            }))
            dispatcher(resetModifyCrmContactEmailDetail())
        }
        setNewEmailDetail(newEmailInitialState)
        setEditBtnConst(null)
    }

    useEffect(() => {
        if (addCrmContactEmailDetail?.data || modifyCrmContactEmailDetail?.data) {
            getUpdatedContactEmailDetail()
        }
    }, [addCrmContactEmailDetail?.data, modifyCrmContactEmailDetail?.data])

    const onHandleAddNewEmailDetail = () => {
        if (modifyCrmContactEmailDetail?.isLoading || destroyCrmContactEmailDetail?.isLoading || modifyCrmContactDetail?.isLoading) return;

        if (editBtnConst) return;

        const defaultEmail = modifyCrmContactDetail?.payload?.emails?.filter((item) => item?.is_primary)
        setNewEmailDetail({
            ...newEmailDetail,
            isShowInput: true,
            email: "",
            label: { label: crmContactLabelsEnum.HOME.label, value: crmContactLabelsEnum.HOME.value },
            is_primary: (defaultEmail?.length > 0) ? false : true
        })
        setEditBtnConst({
            ...editBtnConst,
            id: 0,
            active: crmDetailBtnConst?.emails.key
        })
    }

    const onHandlePrimaryEmailDetail = async (emailItemId, updatedValue) => {
        if (modifyCrmContactEmailDetail?.isLoading || destroyCrmContactEmailDetail?.isLoading || modifyCrmContactDetail?.isLoading) return;

        if (crmContactDetail?.data?.email?.id === emailItemId) return;

        setEditBtnConst(null)
        try {
            const requestPayload = {
                params: { contactId: crmContactDetail?.data?.id },
                body: {
                    default_email_id: emailItemId
                }
            }
            const response = await crmService.updateCrmContactDetail(requestPayload)
            if (response.status === 200) {
                dispatcher(setModifyCrmContactDetailPayload({
                    ...modifyCrmContactDetail?.payload,
                    emails: modifyCrmContactDetail?.payload?.emails?.map((email) => (
                        (email?.id === emailItemId) ? updatedValue : { ...email, is_primary: false }
                    ))
                }))
                dispatcher(setCrmContactDetailData({
                    ...crmContactDetail?.data,
                    email: { ...response.data.data.email, id: emailItemId }
                }))
                toast.success(response.data.message || "Updated Successfully")
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || error)
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        }
    }

    const onHandleSaveEmailDetail = (emailItem) => {
        if (addCrmContactEmailDetail?.isLoading || modifyCrmContactEmailDetail?.isLoading) return;

        const payload = {
            email: emailItem?.email,
            label: emailItem?.label?.value,
            is_primary: emailItem?.is_primary
        }
        dispatcher(updateCrmContactEmailDetail(emailItem?.id, payload))
        setNewEmailDetail(emailItem)
    }

    const onHandleDeleteEmailDetail = useCallback((emailItemId) => {
        if (modifyCrmContactEmailDetail?.isLoading || destroyCrmContactEmailDetail?.isLoading) return;
        if (!window.confirm("Are you sure?. You want to delete email details.")) return;

        dispatcher(deleteCrmContactEmailDetail(emailItemId))
        setEditBtnConst(null)
    }, [modifyCrmContactEmailDetail?.isLoading, destroyCrmContactEmailDetail?.isLoading])

    const onHandleNewPrimaryEmail = useCallback((emailItem, updatedValue) => {
        if (newEmailDetail?.is_primary === updatedValue?.is_primary) return;
        setNewEmailDetail(updatedValue)
    }, [newEmailDetail])

    const onHandleSaveNewEmail = (emailItem) => {
        if (addCrmContactEmailDetail?.isLoading || modifyCrmContactEmailDetail?.isLoading) return;
        if (!emailItem?.email) {
            toast.warn("Please enter email!")
            return;
        }
        if (!emailItem?.label?.value) {
            toast.warn("Please select label!")
            return;
        }
        const duplicateEmail = modifyCrmContactDetail?.payload?.emails?.filter((email) => (email?.email === emailItem?.email))
        if (!!duplicateEmail?.length) {
            toast.warn("Email already added!")
            return;
        }
        const payload = {
            contact_id: modifyCrmContactDetail?.payload?.contact_id,
            email: emailItem?.email,
            label: emailItem?.label?.value,
            is_primary: emailItem?.is_primary
        }
        dispatcher(createCrmContactEmailDetail(payload))
        setNewEmailDetail(emailItem)
    }

    const onHandleDeleteNewEmail = useCallback(() => {
        setNewEmailDetail(newEmailInitialState)
        setEditBtnConst(null)
    }, [])

    return (
        <div className="w-full flex flex-col gap-8 items-center">
            {!!modifyCrmContactDetail?.payload?.emails?.length &&
                modifyCrmContactDetail?.payload?.emails?.slice(0)?.sort((item1, item2) => sortDefaultContact(item1?.id, item2?.id, crmContactDetail?.data?.email?.id))?.map((emailItem, index) => (
                    <ContactEmailItem
                        key={emailItem?.id}
                        index={index}
                        isLoading={modifyCrmContactEmailDetail?.isLoading || destroyCrmContactEmailDetail?.isLoading}
                        isShowSaveBtn={true}
                        isShowCancelBtn={true}
                        emailItem={emailItem}
                        editBtnConst={editBtnConst}
                        setEditBtnConst={setEditBtnConst}
                        onHandlePrimaryEmailDetail={onHandlePrimaryEmailDetail}
                        onHandleSaveEmailDetail={onHandleSaveEmailDetail}
                        onHandleDeleteEmailDetail={onHandleDeleteEmailDetail}
                    />
                ))}
            {newEmailDetail?.isShowInput &&
                <AddContactEmailItem
                    isLoading={addCrmContactEmailDetail?.isLoading}
                    isShowSaveBtn={true}
                    isShowCancelBtn={true}
                    emailItem={newEmailDetail}
                    onHandleChangeEmailDetail={(EmailId, updatedValue) => setNewEmailDetail(updatedValue)}
                    onHandlePrimaryEmailDetail={onHandleNewPrimaryEmail}
                    onHandleSaveEmailDetail={onHandleSaveNewEmail}
                    onHandleDeleteEmailDetail={onHandleDeleteNewEmail}
                />
            }
            {!newEmailDetail?.isShowInput &&
                <AddItemBtn
                    btnTitle={"Add Email"}
                    onHandleAddBtn={onHandleAddNewEmailDetail}
                    isBtnDisable={editBtnConst}
                />
            }
        </div>
    );
}

export default EditContactEmails;