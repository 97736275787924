import { tutorSortBy } from "redux/tutor/tutor.const";

export const pageHeading = {
  heading: "Find and Meet Your Tutor Today",
  subHeading: "Start learning live on video calls. Hand-picked, vetted, and trustworthy tutors committed to help you.",
  headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/messages.svg"
}

export const dropDownButtonConst = {
  TOPIC: {
    value: "topic"
  },
  PRICE: {
    value: "price"
  },
  AVAILABILITY: {
    value: "availability"
  },
  TUTOR_FROM: {
    value: "tutorFrom"
  },
  SPEAKS: {
    value: "speaks"
  },
  COURSE_TYPE: {
    value: "courseType"
  },
  STUDENT_LEVEL: {
    value: "studentLevel"
  },
  SORT_BY: {
    value: "sortBy"
  },
  SELECT_TOPIC: {
    value: "selectTopic"
  },
  FILTERS: {
    value: "filters"
  }
}

export const weekOptions = [
  {
    label: "Sun",
    value: "Sunday"
  },
  {
    label: "Mon",
    value: "Monday"
  },
  {
    label: "Tue",
    value: "Tuesday"
  },
  {
    label: "Wed",
    value: "Wednesday"
  },
  {
    label: "Thu",
    value: "Thursday"
  },
  {
    label: "Fri",
    value: "Friday"
  },
  {
    label: "Sat",
    value: "Saturday"
  },
]

export const tutorFilterConst = {
  PRICE: {
    label: "Price",
    value: "price"
  },
  AVAILABILITY: {
    label: "Availability",
    value: "availability"
  },
  TUTOR_IS_FROM: {
    label: "Tutor Is From",
    value: "tutorIsFrom"
  },
  SPEAKS: {
    label: "Speaks",
    value: "speaks"
  },
  STUDENT_LEVEL: {
    label: "Student Level",
    value: "student_level"
  },
  SORT_BY: {
    label: "Sort By",
    value: "sort_by"
  }
}

export const getQueryString = (filterObject) => {
  let query = ""

  if (filterObject?.page) {
    query += "&pn=" + filterObject?.page
  }
  if (filterObject?.sortBy !== tutorSortBy?.RELEVANCE?.value && filterObject?.sortBy != undefined) {
    query += "&sortBy=" + filterObject?.sortBy?.toString()?.trim()
  }
  if (filterObject?.search) {
    query += "&search=" + filterObject?.search?.replaceAll(" ", "-")?.toString()?.toLowerCase()
  }
  if (filterObject?.minHrsPrice != 0 && filterObject?.minHrsPrice != undefined) {
    query += "&minHrsPrice=" + filterObject?.minHrsPrice?.toString()
  }
  if (filterObject?.maxHrsPrice != 0 && filterObject?.maxHrsPrice != undefined) {
    query += "&maxHrsPrice=" + filterObject?.maxHrsPrice?.toString()
  }
  if (filterObject?.proficiency?.length > 0) {
    query += "&level=" + filterObject?.proficiency?.toString()?.replaceAll(" ", "-")?.toLowerCase()
  }
  if (filterObject?.tutorFrom?.length > 0) {
    query += "&tutorFrom=" + filterObject?.tutorFrom?.toString()?.replaceAll(" ", "-")?.toLowerCase()
  }
  if (filterObject?.language?.length > 0) {
    query += "&speak=" + filterObject?.language?.toString()?.replaceAll(" ", "-")?.toLowerCase()
  }
  if (filterObject?.day?.length > 0) {
    query += "&day=" + filterObject?.day?.toString()?.replaceAll(" ", "-")?.toLowerCase()
  }

  query = query?.length > 0 ? query?.slice(1) : ""
  if (filterObject?.category?.length > 0) {
    return ("/" + (filterObject?.category[0] + "-tutors")?.toString()?.replaceAll(" ", "-")?.toLowerCase() + "?" + query)
  }

  return query?.length > 0 ? "?" + query : ""
}