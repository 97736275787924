import { cn } from "utils/cn.utils";

import { viewContainerConst } from 'components/modals/needATutorModal/data';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setAddLeadDetailPayload } from 'redux/lead/lead.slice';

const Name = ({ setStepCount, setViewContainer }) => {
    const { addLeadDetail } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()

    const onAddFirstName = (e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
            document.getElementById("input2").focus();
        }
    }
    const onAddLastName = (e) => {
        if (e.keyCode === 13) {
            onHandleButton()
        }
    }
    const onHandleButton = (event) => {
        event.preventDefault()
        setStepCount(4)
        setViewContainer(viewContainerConst.EMAIL.value)
    }

    return (
        <form
            className={"w-full flex flex-col items-start justify-start gap-12 px-2 transition ease-in-out delay-1000 duration-1000 transform"}
            id={"form"}
            onSubmit={onHandleButton}
        >
            <div className={"flex flex-col items-start justify-start gap-2"}>
                <span className={"font-bodyPri font-bold text-4xl text-text-900 tracking-wide"}>
                    {"What is your name?"}
                </span>
                <span className={'font-bodyPri font-normal text-text-800 text-sm italic'}>
                    {"(Marked with * is required field)"}
                </span>
            </div>
            <div className={"w-full flex flex-col items-start justify-start md:flex-row md:items-center gap-12"}>
                <div className={"flex flex-col justify-start items-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                        {"First Name"}
                        <span className={"text-lg text-red-500"}>{" *"}</span>
                    </span>
                    <input
                        type={"text"}
                        placeholder={"First Name"}
                        className={cn(
                            "w-full px-5 py-3 border-2 border-divider-medium rounded focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-lg",
                            "placeholder:text-text-500 placeholder:text-lg"
                        )}
                        autoFocus={true}
                        value={addLeadDetail?.addLeadDetailPayload?.firstName}
                        onChange={(event) => dispatcher(setAddLeadDetailPayload({
                            ...addLeadDetail?.addLeadDetailPayload,
                            firstName: event.target.value
                        }))}
                        onKeyDown={onAddFirstName}
                        required
                    />
                </div>
                <div className={"flex flex-col justify-start items-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                        {"Last Name"}
                        <span className={"text-lg text-red-500"}>{" *"}</span>
                    </span>
                    <input
                        type={"text"}
                        id={"input2"}
                        placeholder={"Last Name"}
                        className={cn(
                            "w-full px-5 py-3 border-2 border-divider-medium rounded focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-lg",
                            "placeholder:text-text-500 placeholder:text-lg"
                        )}
                        value={addLeadDetail?.addLeadDetailPayload?.lastName}
                        onChange={(event) => dispatcher(setAddLeadDetailPayload({
                            ...addLeadDetail?.addLeadDetailPayload,
                            lastName: event.target.value
                        }))}
                        onKeyDown={onAddLastName}
                        required
                    />

                </div>
            </div>
            <button
                type={"submit"}
                className={cn(
                    "px-12 py-3 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-medium text-2xl text-text-50 bg-primary-dark",
                    "transition ease-in-out delay-100 duration-300 transform hover:scale-105"
                )}>
                {"Continue"}
            </button>
        </form>
    )
}

export default Name;