import { toast } from "react-toastify";
import { setMessageTemplateErrorMsg, setMessageTemplateLoading } from "redux/firebase/firebase.slice";
import notificationService from "redux/notification/notification.service";
import {
    setSendOtpLoading,
    setSendOtp,
    setSendOtpErrorMsg,

    setVerifyOtpLoading,
    setVerifyOtp,
    setVerifyOtpErrorMsg,
} from "redux/notification/notification.slice";

export const createSendOtp = (body) => async (dispatch) => {
    dispatch(setSendOtpLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await notificationService.createSendOtp(requestData)
        if (response.status === 200) {
            dispatch(setSendOtp(response.data.data))
            toast.success(response.data.message)
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setSendOtpErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setSendOtpLoading(false))
    }
}

export const createVerifyOtp = (body) => async (dispatch) => {
    dispatch(setVerifyOtpLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await notificationService.createVerifyOtp(requestData)
        if (response.status === 200) {
            dispatch(setVerifyOtp(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setVerifyOtpErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setVerifyOtpLoading(false))
    }
}

export const sendMessageEmail = (body) => async (dispatch) => {
    dispatch(setMessageTemplateLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await notificationService.sendMessageEmail(requestData)
        if (response.status === 200) {
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setMessageTemplateErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setMessageTemplateLoading(false))
    }
}