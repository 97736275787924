import { setAndGetLocalStorage } from "redux/local/local.utils";
import { localsConst } from "redux/local/local.const";
import { userRoles } from "redux/auth/auth.const";

import store from "redux/store";
import { setLocals } from "redux/local/local.slice";


export const setUserLocals = (user) => {
    const { dispatch } = store
    const { locals } = store.getState().local
    
    const userRole = setAndGetLocalStorage(localsConst.USER_ROLE.value)
    const displayLanguage = setAndGetLocalStorage(localsConst.DISPLAY_LANGUAGE.value)
    const currencyCode = setAndGetLocalStorage(localsConst.CURRENCY_CODE.value)
    const timeZone = setAndGetLocalStorage(localsConst.TIME_ZONE.value)
    const timeFormat = setAndGetLocalStorage(localsConst.TIME_FORMAT.value)

    const filteredRoles = user?.roles?.filter(role => role.role === userRole) || []

    dispatch(setLocals({
        ...locals,
        [localsConst.USER_ROLE.value]: filteredRoles.length !== 0 ? userRole : userRoles.STUDENT.value,
        [localsConst.DISPLAY_LANGUAGE.value]: user?.displayLanguage || displayLanguage,
        [localsConst.CURRENCY_CODE.value]: user?.currencyCode || currencyCode,
        [localsConst.TIME_ZONE.value]: user?.timeZone || timeZone,
        [localsConst.TIME_FORMAT.value]: user?.timeFormat || timeFormat,
    }))
}