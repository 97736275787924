import { useNavigate } from "react-router-dom";

import { IoIosArrowForward } from "react-icons/io";
import { ImCross } from "react-icons/im";

import SimpleSearchSelect from "components/dropdowns/simpleSearchSelect/SimpleSearchSelect";

import { dropDownButtonConst, getQueryString } from "pages/global/findCourses/data";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearFilterProps } from "redux/course/course.slice";
import { DEFAULT_COURSE_PAGE, DEFAULT_COURSE_RECORDS } from "redux/course/course.const";

import { pagesInfo } from "utils/pagesInfo";

const SearchCategory = ({ dropDownButton, setDropDownButton }) => {
    const { categoryList } = useAppState((state) => state.category)
    const { filterProps } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    return (
        <div className={"relative w-1/2 bg-white px-3 py-2.5 rounded-l-lg"}>
            <div
                className={"w-full flex items-center justify-between gap-3 cursor-pointer"}
                onClick={() => {
                    if (!dropDownButton || dropDownButton !== dropDownButtonConst?.CATEGORY?.value) {
                        setDropDownButton(dropDownButtonConst?.CATEGORY?.value)
                    }
                    if (dropDownButton === dropDownButtonConst?.CATEGORY?.value) {
                        setDropDownButton(null)
                    }
                }}
            >
                <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide truncate"}>
                    {filterProps?.category[0] || "Select Topic"}
                </span>
                {filterProps?.category?.length === 0 &&
                    <span>
                        <IoIosArrowForward className={"text-base text-text-600"} />
                    </span>
                }
            </div>
            {filterProps?.category?.length > 0 &&
                <div
                    className={"absolute top-3 right-1 w-5 h-5 flex items-center justify-start rounded-full bg-white"}>
                    <ImCross
                        className={"w-full text-sm text-primary-dark text-center p-0.5 cursor-pointer"}
                        onClick={() => {
                            const query = getQueryString({ ...filterProps, category: [], page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
                            navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
                            setDropDownButton(null)
                        }}
                    />
                </div>
            }
            {(dropDownButton === dropDownButtonConst?.CATEGORY?.value) &&
                <SimpleSearchSelect
                    options={categoryList?.categoryList?.map((category) => ({
                        label: category?.category,
                        value: category?.category
                    }))}
                    isLoading={categoryList?.isLoading}
                    isShowInputBox={true}
                    isShowList={dropDownButton === dropDownButtonConst?.CATEGORY?.value}
                    setIsShowList={setDropDownButton}
                    className={"absolute w-fit top-72 left-5 mt-7 sm:mt-0.5 sm:left-16 md:mt-2 md:left-24 md:w-80 lg:left-60 lg:mt-7 lg:w-72 z-30 overflow-y-auto"}
                    onChange={(option) => {
                        dispatcher(setClearFilterProps())
                        navigate(option[0]?.value?.replaceAll(" ", "-")?.toLowerCase())
                    }}
                />
            }
        </div>
    )
}

export default SearchCategory