import { useAppState } from 'hooks/useStore';


const SessionDetailTitle = () => {
  const { sessionUserDetail } = useAppState(s => s.session)

  return sessionUserDetail?.data?.result?.name ? (
    <div className={"flex items-center justify-center overflow-hidden"}>
      <span className={"font-bodyPri font-semibold text-lg text-text-900 text-center truncate"}>
        {sessionUserDetail?.data?.result?.name}
      </span>
    </div>
  ) : null
}

export default SessionDetailTitle