import { useCallback } from "react";

import ContactEmailItem from "components/modals/crmModals/createCrmContactDetailModal/commonComponents/ContactEmailItem";
import AddItemBtn from "components/modals/crmModals/commonComponents/AddItemBtn";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setAddCrmContactDetailPayload } from "redux/crm/crm.slice";
import { crmContactLabelsEnum } from "redux/crm/crm.const";

function AddContactEmails() {
    const { addCrmContactDetail } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()

    const onHandleAddNewEmailAddress = useCallback(() => {
        const newId = (addCrmContactDetail?.payload?.emails?.length > 0)
            ? addCrmContactDetail?.payload?.emails[addCrmContactDetail?.payload?.emails?.length - 1].id + 1
            : 1
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            emails: !!addCrmContactDetail?.payload?.emails?.length
                ? [...addCrmContactDetail?.payload?.emails, { id: newId, email: "", label: { label: crmContactLabelsEnum.HOME.label, value: crmContactLabelsEnum.HOME.value }, is_primary: false }]
                : [{ id: newId, email: "", label: { label: crmContactLabelsEnum.HOME.label, value: crmContactLabelsEnum.HOME.value }, is_primary: true }]
        }))
    }, [addCrmContactDetail?.payload])

    const onHandleChangeEmailDetail = useCallback((emailId, updatedValue) => {
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            emails: addCrmContactDetail?.payload?.emails?.map((emailItem) => (
                (emailItem?.id === emailId) ? updatedValue : emailItem
            ))
        }))
    }, [addCrmContactDetail?.payload])

    const onHandlePrimaryEmailDetail = useCallback((emailId, updatedValue) => {
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            emails: addCrmContactDetail?.payload?.emails?.map((emailItem) => (
                (emailItem?.id === emailId) ? updatedValue : { ...emailItem, is_primary: false }
            ))
        }))
    }, [addCrmContactDetail?.payload])

    const onHandleDeleteEmailDetail = useCallback((emailId) => {
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            emails: addCrmContactDetail?.payload?.emails?.filter((emailItem) => emailItem?.id !== emailId)
        }))
    }, [addCrmContactDetail?.payload])

    return (
        <div className="w-full flex flex-col gap-8 items-center">
            {!!addCrmContactDetail?.payload?.emails?.length &&
                addCrmContactDetail?.payload?.emails.map((emailItem, index) => (
                    <ContactEmailItem
                        key={index}
                        index={index}
                        isShowSaveBtn={false}
                        isShowCancelBtn={true}
                        emailItem={emailItem}
                        onHandleChangeEmailDetail={onHandleChangeEmailDetail}
                        onHandlePrimaryEmailDetail={onHandlePrimaryEmailDetail}
                        onHandleDeleteEmailDetail={onHandleDeleteEmailDetail}
                    />
                ))}
            <AddItemBtn
                btnTitle={"Add Email"}
                onHandleAddBtn={onHandleAddNewEmailAddress}
            />
        </div>
    );
}

export default AddContactEmails;