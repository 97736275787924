import { lmsLessonSettingDetailPayload, addLmsLessonInstructorDetailPayload } from "redux/edulyteLms/lmsLesson/lmsLesson.const";

export const LMS_LESSON_INITIAL_STATE = {
    /* tutor lms lesson */
    studentLmsLessonList: {
        isLoading: false,
        data: null,
        message: null,
    },
    studentLmsLessonDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    /* tutor lms lesson */
    tutorLmsLessonList: {
        isLoading: false,
        data: null,
        message: null,
    },
    tutorLmsLessonDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    /* lms lesson */
    lmsLessonList: {
        isLoading: false,
        data: null,
        message: null,
    },
    lmsLessonDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addLmsLessonDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    modifyLmsLessonDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    destroyLmsLessonDetail: {
        isLoading: false,
        message: null,
    },

    /* lms lesson user */
    lmsLessonUserList: {
        isLoading: false,
        data: null,
        message: null,
    },
    lmsLessonUserDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addLmsLessonUserDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    modifyLmsLessonUserDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    destroyLmsLessonUserDetail: {
        isLoading: false,
        message: null,
    },

    /* lms lesson slug */
    lmsLessonSlugList: {
        isLoading: false,
        data: null,
        message: null,
    },
    lmsLessonSlugDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addLmsLessonSlugDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    modifyLmsLessonSlugDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    destroyLmsLessonSlugDetail: {
        isLoading: false,
        message: null,
    },

    /* lms lesson setting */
    lmsLessonSettingList: {
        isLoading: false,
        data: null,
        message: null,
    },
    lmsLessonSettingDetail: {
        isLoading: false,
        data: null,
        payload: lmsLessonSettingDetailPayload,
        message: null
    },
    addLmsLessonSettingDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    modifyLmsLessonSettingDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    destroyLmsLessonSettingDetail: {
        isLoading: false,
        message: null,
    },

    /* lms lesson lecture */
    lmsLessonLectureList: {
        isLoading: false,
        data: null,
        message: null,
    },
    lmsLessonLectureDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addLmsLessonLectureDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    modifyLmsLessonLectureDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    destroyLmsLessonLectureDetail: {
        isLoading: false,
        message: null,
    },

    /* lms lesson article */
    lmsLessonArticleList: {
        isLoading: false,
        data: null,
        message: null,
    },
    lmsLessonArticleDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addLmsLessonArticleDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    modifyLmsLessonArticleDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    destroyLmsLessonArticleDetail: {
        isLoading: false,
        message: null,
    },

    /* lms lesson quiz */
    lmsLessonQuizList: {
        isLoading: false,
        data: null,
        message: null,
    },
    lmsLessonQuizDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addLmsLessonQuizDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    modifyLmsLessonQuizDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    destroyLmsLessonQuizDetail: {
        isLoading: false,
        message: null,
    },

    /* lesson Topic to be delete later */
    lmsLessonTopicDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    lmsLessonTopicList: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    addLmsLessonTopicDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    updateLmsLessonTopicDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    deleteLmsLessonTopicDetail: {
        isLoading: false,
        message: null
    },

    // lms lesson instructor
    addLmsLessonInstructorDetail: {
        isLoading: false,
        data: null,
        payload: addLmsLessonInstructorDetailPayload,
        message: null
    },
    modifyLmsLessonInstructorDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    destroyLmsLessonInstructorDetail: {
        isLoading: false,
        message: null
    }
}