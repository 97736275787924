import { cn } from "utils/cn.utils";

import { MdOutlineFormatAlignLeft } from 'react-icons/md';

import QuillEditor from "components/textEditor/QuillEditor";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setAddUserEventlyDetailPayload } from 'redux/evently/evently.slice';

const Editor = {
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ],
        clipboard: {
            matchVisual: false,
        }
    },
    formats: [
        'bold', 'italic', 'underline', 'blockquote', 'code-block',
        'list', 'bullet', 'indent',
    ]
}

const EventlyDescription = () => {
    const { addUserEventlyDetail } = useAppState((state) => state.evently)

    const dispatcher = useAppDispatcher()


    const onHandleAddDescription = () => {
        dispatcher(setAddUserEventlyDetailPayload({
            ...addUserEventlyDetail?.payload,
            isShowDescription: true
        }))
    }

    const onHandleChangeDescription = (content) => {
        dispatcher(setAddUserEventlyDetailPayload({
            ...addUserEventlyDetail?.payload,
            description: content
        }))
    }

    return (
        <div className={"w-full grid grid-cols-12 gap-x-2 px-5"}>
            <div className={cn(
                "col-start-1 col-span-1",
                addUserEventlyDetail?.payload?.isShowDescription ? "" : "self-center"
            )}>
                <MdOutlineFormatAlignLeft className={"text-text-700 text-lg"} />
            </div>
            {!addUserEventlyDetail?.payload?.isShowDescription &&
                <div
                    className={"col-start-2 col-span-full self-center p-2 hover:bg-background-lightBlue cursor-pointer"}
                    onClick={onHandleAddDescription}
                >
                    <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                        {"Add description"}
                    </span>
                </div>
            }
            {addUserEventlyDetail?.payload.isShowDescription &&
                <div className={"col-start-2 col-span-full self-center"}>
                    <QuillEditor
                        editorToolModules={Editor?.modules}
                        editorToolFormats={Editor?.formats}
                        className={"w-full h-full focus:outline-none border-1 placeholder:text-text-700"}
                        editorText={addUserEventlyDetail?.payload?.description}
                        onHandleChangeText={onHandleChangeDescription}
                    />
                </div>
            }
        </div>
    )
}

export default EventlyDescription;