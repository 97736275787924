const Topics = ({ tutor }) => {

  return (
    <div className={"p-2 md:p-5 space-y-5"} id={"TOPICS"}>
      <span className={"text-xl font-medium font-bodyPri tracking-wide text-text-900"}>
        {"Topics/Subjects"}
      </span>
      <div className={"space-y-3 divide-y divide-text-200 px-3 py-0.5 md:py-1"}>
        {tutor?.categoryProficiency?.length > 0
          && tutor?.categoryProficiency?.map((ele, idx) => (
            <div key={idx} className={"grid grid-cols-2 gap-5 pt-2"}>
              <div className={"col-span-1 font-bodyPri font-medium text-text-700 text-base"}>
                {ele.category}
              </div>
              <div className={"col-span-1 font-bodyPri font-medium text-text-900 text-base"}>
                {ele.proficiency}
              </div>
            </div>
          ))}
        {(!tutor?.categoryProficiency || tutor?.categoryProficiency?.length === 0)
          && <span className={"font-bodyPri font-normal text-text-800 text-lg"}>
            {"Subject Information Pending"}
          </span>
        }
      </div>
    </div>
  );
};

export default Topics;
