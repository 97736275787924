import { Fragment, memo, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Dialog, Transition } from "@headlessui/react";

import { searchParamsInfo } from 'pages/auth/myDownloadable/data';

import DownloadDetailContent from "./DownloadDetailContent";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

import { cn } from "utils/cn.utils";

const MyDownloadDetailModal = memo(({
    openModal = false,
    maxWidth = "max-w-6xl",
    bgColor = "bg-white"
}) => {
    const { modal } = useAppState((state) => state.local);

    const dispatcher = useAppDispatcher();
    const navigate = useNavigate();
    const location = useLocation()

    let searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    const closeModal = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.myDownloadDetailModal.key]: {
                ...modalConst.myDownloadDetailModal,
                isVisible: false
            }
        }))
        searchQueryParams.delete(searchParamsInfo.download_id.key);
        navigate(`?${searchQueryParams.toString()}`);
    };

    return (
        <Transition appear show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className={"fixed max-h-screen inset-0 z-50 overflow-y-auto"}
                open={openModal}
                onClose={closeModal}
            >
                <div className={"p-0 md:p-5 text-center"}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className={"fixed inset-0 bg-black opacity-50"} />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span aria-hidden={"true"} className={"inline-block h-screen align-middle"}>
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className={cn(
                                "inline-block w-full shadow-xl rounded-lg space-y-5 overflow-hidden",
                                `text-left align-middle transition-all transform ${maxWidth} ${bgColor}`
                            )}
                        >
                            <Dialog.Description
                                as="div"
                                className={cn(
                                    "w-full h-full overflow-x-hidden overflow-y-scroll min-h-[650px]",
                                    "scrollbar-thin"
                                )}
                            >
                                <DownloadDetailContent closeModal={closeModal} />
                            </Dialog.Description>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
});

export default MyDownloadDetailModal;