import { Outlet } from 'react-router-dom';

import { courseDetailNavigationOptions } from '../../editLMSCourse.data';
import LMSNavigationTabs from "../../../../commonComponents/navigations/LMSNavigationTabs";

const EditLMSCourseDetailPage = () => {

    return (
        <div className={"flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3"}>
            <LMSNavigationTabs options={Object.values(courseDetailNavigationOptions)} />
            <Outlet />
        </div>
    )
}

export default EditLMSCourseDetailPage;