import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Footer1 from 'components/footer1/Footer1';

import {
    PageContentStyle,
    SignUpContainer,
    SignUpCardContainer,
    ImageContainer
} from './SignUpPageStyle';

import SignUpCard from './signUpCard/SignUpCard';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { setClearSignUpPayload } from 'redux/auth/auth.slice';

import { pagesInfo } from 'utils/pagesInfo';
import { useTitle } from 'hooks/useTitle';

const signUpOneImageUrl = "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/auth-page/sign-up-one.png"


const SignUpPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { modal } = useAppState((state) => state.local)
    const { session } = useAppState((s) => s.auth)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()
    const [title, setTitle] = useTitle()

    const from = location.state?.from?.url || pagesInfo.DASHBOARD.pagePath;

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.SIGN_UP))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Sign Up Free | Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    if (session?.isLoggedIn) {
        navigate(from, { replace: true });
        dispatcher(setModal({
            ...modal,
            [modalConst.CODE_VERIFICATION_MODAL.stateKey]: false
        }))
        dispatcher(setClearSignUpPayload())
    }

    return (
        <PageContentStyle>
            <div className={"my-10 md:my-0 flex justify-between items-center"}>
                <div className='-ml-[34rem] w-1/2 min-h-screen bg-primary-dark rounded-br-full'></div>
                <div className='-mr-[34rem] w-1/2 min-h-screen bg-secondary-dark rounded-tl-full'></div>
                <SignUpContainer>
                    <SignUpCardContainer>
                        <SignUpCard />
                    </SignUpCardContainer>
                    <ImageContainer>
                        <img src={signUpOneImageUrl} alt={"sign-up-one"} className={"w-full h-full object-cover"} />
                    </ImageContainer>
                </SignUpContainer>
            </div>
            <Footer1 />
        </PageContentStyle>
    )
}
export default SignUpPage;