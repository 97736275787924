import { useCallback } from 'react';
import { cn } from "utils/cn.utils";
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import RatingStars from 'components/ratingStars/RatingStars';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

import { createStudentFavLmsCourseDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { pagesInfo } from 'utils/pagesInfo';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 3,
    borderRadius: 3,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#bdbdbd",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#0741ad',
    },
}));

const StudentLmsCourseCard = ({ course }) => {
    const { user } = useAppState((s) => s.user)
    const { addStudentFavLmsCourseDetail } = useAppState((s) => s.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    const isFav = !!user?.user?.favoriteLmsCourses?.find((favCourse) => favCourse?.lmsCourseId === course?.lms_course?.id)

    const onHandleFavCourse = useCallback(() => {
        if (addStudentFavLmsCourseDetail?.isLoading) return;

        dispatcher(createStudentFavLmsCourseDetail({ lms_course_id: course?.lms_course?.id, user_id: user?.user?.userId }))
    }, [])

    return (
        <div className={'w-[22rem] h-full flex flex-col bg-white rounded-lg shadow-all'}>
            <div className={'w-full h-48 overflow-hidden relative'}>
                <div className={'rounded-t-lg overflow-hidden h-full w-full'}>
                    <Link to={`/course/${course?.lms_course?.active_slug}/learn`}>
                        <img
                            src={course?.lms_course?.thumbnail_pic_url}
                            alt={"course-thumbnail"}
                            className={'w-full h-full object-cover'}
                        />
                    </Link>
                </div>
                <div className={'absolute right-2 top-2 p-1.5 bg-white rounded-full shadow-md'}>
                    <span
                        onClick={onHandleFavCourse}
                        className={"cursor-pointer"}
                    >
                        {isFav
                            ? (<FavoriteIcon className='text-red-500' style={{ fontSize: "1.5rem" }} />)
                            : <FavoriteBorderIcon className='text-text-700' style={{ fontSize: "1.5rem" }} />
                        }
                    </span>
                </div>
            </div>
            <div className='flex flex-col justify-start gap-5 px-5 py-3'>
                <div className={"w-full flex flex-col gap-1"}>
                    <Link
                        to={`/course/${course?.lms_course?.active_slug}/learn`}
                        className={cn(
                            'text-text-900 font-bodyPri font-medium text-lg tracking-wide truncate',
                            "hover:text-secondary-main hover:underline decoration-1"
                        )}
                    >
                        {course?.lms_course?.title}
                    </Link>
                    <Link to={`${pagesInfo?.FIND_COURSES?.pagePath}/${course?.lms_course?.category?.category?.replaceAll(" ", "-")?.toLowerCase()}`}
                        className='text-text-900 font-normal font-bodyPri text-sm tracking-wide'>
                        {course?.lms_course?.category?.category}
                    </Link>
                </div>

                {((course?.lms_course_student?.completed_lesson_count > 0) && (course?.lms_course_student?.total_lesson_count > 0))
                    ? <div className={"w-full flex flex-col gap-1"}>
                        <Box sx={{ flexGrow: 1 }}>
                            <BorderLinearProgress
                                variant={"determinate"}
                                value={parseFloat(((course?.lms_course_student?.completed_lesson_count / course?.lms_course_student?.total_lesson_count) * 100)?.toFixed(1))}
                            />
                        </Box>
                        <div className={"w-full flex items-start justify-between gap-2"}>
                            <span className={"font-bodyPri font-normal text-xs text-text-900 tracking-wide"}>
                                {`${parseFloat(((course?.lms_course_student?.completed_lesson_count / course?.lms_course_student?.total_lesson_count) * 100)?.toFixed(1))}%`}
                            </span>
                            {/* <div className={"flex flex-col justify-center items-end gap-0.5"}>
                                <RatingStars rating={2} fullRating={5} />
                                <span className={"font-bodyPri font-normal text-text-700 text-xs tracking-wide"}>
                                    {"Your rating"}
                                </span>
                            </div> */}
                        </div>
                    </div>
                    : <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wide"}>
                        {"START COURSE"}
                    </span>
                }
            </div>
        </div>
    )
}

export default StudentLmsCourseCard;