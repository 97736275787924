import * as React from 'react';
import { cn } from "utils/cn.utils";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { BsCheckLg } from 'react-icons/bs';

import { useAppState } from 'hooks/useStore';
import { coursePriceTypeEnum } from 'redux/course/course.const';

import { currencyRateConversion } from 'utils/generators.utils';

const minDistance = 0.1;

export default function PriceFilter({
    options,
    selectedOption,
    minValue,
    maxValue,
    setIsShowList,
    onHandlePriceRange,
    onClearFilter
}) {
    const { locals } = useAppState((s) => s.local)
    const { currencyRateList } = useAppState((state) => state.master)
    const targetCurrencyRate = currencyRateList?.currencyRateList[(locals?.currencyCode || "USD")]

    const [value, setValue] = React.useState([0, 100]);
    const [localCurrency, setLocalCurrency] = React.useState({ minLocal: minValue, maxLocal: maxValue })
    const [selectedPriceTypeOption, setSelectedPriceTypeOption] = React.useState(selectedOption)
    const [isPriceRangeEnabled, setIsPriceRangeEnabled] = React.useState(false)

    React.useEffect(() => {
        let minVal = (minValue * 100) / (targetCurrencyRate * 500)
        let maxVal = (maxValue * 100) / (targetCurrencyRate * 500)
        setValue([minVal, maxVal])
        setSelectedPriceTypeOption(selectedOption)
    }, [minValue, maxValue, selectedOption])

    const handleChange = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setValue([parseFloat(Math.min(newValue[0], value[1] - minDistance)?.toFixed(2)), value[1]]);
            setLocalCurrency({ ...localCurrency, minLocal: ((parseFloat(newValue[0] * (targetCurrencyRate * 500))) / 100)?.toFixed(2) })
            // onHandlePriceRange({ minLocal: ((parseFloat(newValue[0] * (targetCurrencyRate * 500))) / 100)?.toFixed(2) })
        } else {
            setValue([value[0], parseFloat(Math.max(newValue[1], value[0] + minDistance)?.toFixed(2))]);
            setLocalCurrency({ ...localCurrency, maxLocal: ((parseFloat(newValue[1] * (targetCurrencyRate * 500))) / 100)?.toFixed(2) })
            // onHandlePriceRange({ maxLocal: ((parseFloat(newValue[1] * (targetCurrencyRate * 500))) / 100)?.toFixed(2) })
        }

        onHandlePriceRange({
            priceType: selectedPriceTypeOption,
            minLocal: ((parseFloat(newValue[0] * (targetCurrencyRate * 500))) / 100)?.toFixed(2),
            maxLocal: ((parseFloat(newValue[1] * (targetCurrencyRate * 500))) / 100)?.toFixed(2)
        })
    };


    const onHandleSelectPriceType = (priceType) => {
        if (priceType === coursePriceTypeEnum?.PLAN?.value) {
            setIsPriceRangeEnabled(false)
        }
        setSelectedPriceTypeOption(priceType)
        onHandlePriceRange({
            priceType: priceType,
            minLocal: 0,
            maxLocal: 0
        })
    }

    const onHandleSelectPriceRange = () => {
        if (isPriceRangeEnabled) {
            setIsPriceRangeEnabled(false)
            return
        }
        if (selectedPriceTypeOption === coursePriceTypeEnum?.REGULAR?.value) {
            setIsPriceRangeEnabled(true)
        } else {
            setIsPriceRangeEnabled(false)
        }
    }

    const onHandleClear = () => {
        setSelectedPriceTypeOption(null)
        setIsPriceRangeEnabled(false)
        onClearFilter()
    }

    return (
        <div className={"bg-white w-full rounded-xl px-3 py-1.5"}>
            <div className={"w-full space-y-5 px-3 py-1.5"}>
                <div className={"w-full flex items-center justify-between gap-3"}>
                    <div className={"flex items-center justify-start gap-2"}>
                        <span className={"font-bodyPri font-medium text-text-900 text-base"}>
                            {"Price Types"}
                        </span>
                        {selectedPriceTypeOption &&
                            <span className={"w-5 h-5 flex items-center justify-center bg-primary-dark rounded-full text-text-50 text-sm"}>
                                {1}
                            </span>
                        }
                    </div>
                    <span
                        className={"font-bodyPri font-medium text-primary-dark text-sm underline cursor-pointer"}
                        onClick={onHandleClear}
                    >
                        {"Clear"}
                    </span>
                </div>
                <div className={"w-full flex flex-col items-start justify-start gap-3"}>
                    {options?.map((priceType) => (
                        <div className={"w-full flex items-start justify-start gap-3 cursor-pointer group"} onClick={() => onHandleSelectPriceType(priceType?.value)}>
                            <div className={"relative w-5 h-5 border border-text-300 rounded"}>
                                {(selectedPriceTypeOption === priceType?.value) &&
                                    <span className={"absolute top-0.5 -right-0"}>
                                        <BsCheckLg className={"text-base text-primary-dark"} />
                                    </span>
                                }
                            </div>
                            <div className={"w-11/12 flex flex-col items-start justify-start gap-1"}>
                                <div className={"font-bodyPri font-semibold text-text-900 text-base"}>
                                    {priceType?.label}
                                </div>
                                {/* <div className={"font-bodyPri font-normal text-text-700 text-sm"}>
                                                {priceType?.description}
                                            </div> */}
                            </div>
                        </div>
                    ))}
                </div>

                <div
                    className={cn(
                        "font-bodyPri font-medium text-sm underline text-text-400 cursor-not-allowed",
                        (selectedPriceTypeOption === coursePriceTypeEnum?.REGULAR?.value) && "!text-primary-dark !cursor-pointer"
                    )}
                    onClick={onHandleSelectPriceRange}
                >
                    {isPriceRangeEnabled ? "Remove Price Range" : "Select Price Range"}
                </div>

                {/* {currencyRateConversion(locals?.currencyCode, localCurrency?.minLocal) + " - " + currencyRateConversion(locals?.currencyCode, localCurrency?.maxLocal)} */}
                {isPriceRangeEnabled &&
                    <Box className={"px-3 py-2 bg-white rounded-xl space-y-2"}>
                        <div className={"font-bodyPri font-normal text-text-900 text-lg"}>
                            {locals?.currencyCode + " " + parseFloat(localCurrency?.minLocal)?.toFixed(2) + " - " + locals?.currencyCode + " " + parseFloat(localCurrency?.maxLocal)?.toFixed(2)}
                        </div>
                        <div className={"px-3"}>
                            <Slider
                                getAriaLabel={() => 'Minimum distance'}
                                value={value}
                                onChange={handleChange}
                                disableSwap
                            />
                        </div>
                    </Box>
                }
            </div>
        </div >
    );
}