import Dropdowns from "pages/global/findCourses/components/courseFilter/Dropdowns";

import SearchCategory from "pages/global/findCourses/components/courseFilter/SearchCategory";
import FilterCourse from "pages/global/findCourses/components/courseFilter/FilterCourse";

const CoursePublicFilters = ({ props, dropDownButton, setDropDownButton }) => {

    return (
        <>
            <div className={"mx-auto container px-5 hidden lg:block"}>
                <Dropdowns props={props} dropDownButton={dropDownButton} setDropDownButton={setDropDownButton} />
            </div>
            <div className={"mx-auto container px-5 block lg:hidden"}>
                <div className={"w-full flex items-center justify-stretch shadow rounded-lg"}>
                    <SearchCategory props={props} dropDownButton={dropDownButton} setDropDownButton={setDropDownButton} />
                    <span className={"rotate-180 border border-text-300 h-10"}></span>
                    <FilterCourse props={props} dropDownButton={dropDownButton} setDropDownButton={setDropDownButton} />
                </div>
            </div>
        </>
    )
}

export default CoursePublicFilters