import { cn } from "utils/cn.utils";

import { MdAdd } from 'react-icons/md'

const AddBorderButton = ({ btnTitle = "", onHandleAdd }) => {
    return (
        <div className={cn(
            "w-full px-3 py-3 my-2 flex items-center justify-center border border-dashed border-text-500 rounded-lg",
            "font-bodyPri font-normal text-text-900 cursor-pointer"
        )}
            onClick={onHandleAdd}
        >
            <MdAdd className={"text-xl text-text-900"} />
            <span className={"text-xl"}>
                {btnTitle}
            </span>
        </div>
    )
}

export default AddBorderButton