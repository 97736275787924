import { useState } from 'react';
import {
    Container,
    Content,
    Header,
    Heading,
    SubHeading,
    VideoButton,
    PlayVideoButton,
    ItemContainer,
    VideoModal,
} from './HowEdulyteWorksStyle';

import { workData } from './HowEdulyteWorksData';
import HowEdulyteWorksItem from './HowEdulyteWorksItem';

const EMBED_VIDEO_URL = 'https://youtube.com/embed/kZmwL5AcQMA?autoplay=1&mute=1';

const HowEdulyteWorks = () => {

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false);

    return (
        <>
            <VideoModal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <iframe
                    className={"bg-white"}
                    title={'Embeded Video'}
                    src={EMBED_VIDEO_URL}
                    allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
                    allowFullScreen={"true"}
                    loading={"lazy"}
                    frameBorder={'0'}
                />
            </VideoModal>
            <Container>
                <Content>
                    <Header>
                        <Heading>{'How Edulyte Works'}</Heading>
                        <SubHeading>{'Learn live with qualified and trusted tutors, anytime, anywhere'}</SubHeading>
                        <VideoButton onClick={handleOpen}>
                            {'See how it works'}
                            <PlayVideoButton />
                        </VideoButton>
                    </Header>
                    <ItemContainer>
                        {workData.map((workDataItem, index) => (
                            <HowEdulyteWorksItem key={index} workDataItem={workDataItem} />
                        ))}
                    </ItemContainer>
                </Content>
            </Container>
        </>
    )
}

export default HowEdulyteWorks
