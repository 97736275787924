import { useCallback } from "react";

import ContactAddressItem from "components/modals/crmModals/createCrmContactDetailModal/commonComponents/ContactAddressItem";
import AddItemBtn from "components/modals/crmModals/commonComponents/AddItemBtn";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setAddCrmContactDetailPayload } from "redux/crm/crm.slice";
import { crmContactLabelsEnum } from "redux/crm/crm.const";

const addressInitialState = {
    street_line_1: "",
    street_line_2: "",
    country: {},
    state: "",
    city: "",
    post_office: "",
    zip: "",
    label: {},
    is_primary: false
}

function AddContactAddresses() {
    const { countryList, timeZoneCountryDetail } = useAppState((state) => state.master)
    const { addCrmContactDetail } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()

    const onHandleAddNewAddressDetail = useCallback(() => {
        let myLocalCountryList = countryList?.countryList?.filter((country) => (
            country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
        ))

        if (myLocalCountryList.length === 0) {
            myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.countryDomain === "US"
            ))
        }

        let newAddressDetail = { ...addressInitialState, country: myLocalCountryList[0], label: { label: crmContactLabelsEnum.HOME.label, value: crmContactLabelsEnum.HOME.value } }

        const newId = (addCrmContactDetail?.payload?.addresses?.length > 0)
            ? addCrmContactDetail?.payload?.addresses[addCrmContactDetail?.payload?.addresses?.length - 1]?.id + 1
            : 1
        if (!addCrmContactDetail?.payload?.addresses?.length) {
            newAddressDetail = { ...newAddressDetail, is_primary: true }
        }
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            addresses: !!addCrmContactDetail?.payload?.addresses?.length
                ? [...addCrmContactDetail?.payload?.addresses, { id: newId, ...newAddressDetail }]
                : [{ id: newId, ...newAddressDetail }]
        }))
    }, [addCrmContactDetail?.payload, countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

    const onHandleChangeAddressDetail = useCallback((addressId, updatedValue) => {
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            addresses: addCrmContactDetail?.payload?.addresses?.map((addressItem) => (
                (addressItem?.id === addressId) ? updatedValue : addressItem
            ))
        }))
    }, [addCrmContactDetail?.payload])

    const onHandlePrimaryAddressDetail = useCallback((addressId, updatedValue) => {
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            addresses: addCrmContactDetail?.payload?.addresses?.map((addressItem) => (
                (addressItem?.id === addressId) ? updatedValue : { ...addressItem, is_primary: false }
            ))
        }))
    }, [addCrmContactDetail?.payload])


    const onHandleDeleteAddressDetail = useCallback((addressId) => {
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            addresses: addCrmContactDetail?.payload?.addresses?.filter((addressItem) => addressItem?.id !== addressId)
        }))
    }, [addCrmContactDetail?.payload])

    return (
        <div className="w-full flex flex-col gap-8 items-center mt-3 sm:mt-5">
            {!!addCrmContactDetail?.payload?.addresses?.length &&
                addCrmContactDetail?.payload?.addresses?.map((addressItem, index) => (
                    <ContactAddressItem
                        key={index}
                        index={index}
                        isShowCancelBtn={true}
                        addressItem={addressItem}
                        onHandleChangeAddressDetail={onHandleChangeAddressDetail}
                        onHandlePrimaryAddressDetail={onHandlePrimaryAddressDetail}
                        onHandleDeleteAddressDetail={onHandleDeleteAddressDetail}
                    />
                ))}
            <AddItemBtn
                btnTitle={"Add Address"}
                onHandleAddBtn={onHandleAddNewAddressDetail}
            />
        </div>
    );
}

export default AddContactAddresses;