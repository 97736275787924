import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const TopTabNavigation = ({ tabs }) => {
    const params = useParams()
    const pathParam = params["*"]

    const activeTabButtonStyle = {
        color: '#0741ad',
        borderColor: '#0741ad',
        backgroundColor: '#e3f2fd'
    };

    return (
        <>
            {tabs.map((tab, index) => (
                <Link
                    key={index}
                    to={`${tab?.value}`}
                    style={pathParam?.startsWith(tab?.value) ? activeTabButtonStyle : {}}
                    className='border-b px-5 py-2 w-full whitespace-nowrap font-bodyComp border-divider-darkLight bg-background-light duration-200 ease-in-out text-background-black hover:bg-background-lightBlue'
                >
                    {tab.label}
                </Link>
            ))}
        </>
    )
}

export default TopTabNavigation;