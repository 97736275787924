import { useEffect, useState } from 'react';
import { cn } from "utils/cn.utils";

import { AiTwotoneCalendar } from 'react-icons/ai';

import ComponentLoader from 'components/loader/ComponentLoader';
import TimeSlot from 'components/modals/rescheduleTimeSlotModal/TimeSlot';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { resetAvailableSlotDetail } from 'redux/availability/availability.slice';
import { courseType } from 'redux/course/course.const';

import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';

const RescheduleTimeSlots = () => {
    const { addSessionUserDetailPayload } = useAppState((s) => s.session)
    const { availableSlotDetail } = useAppState((s) => s.availability)

    const dispatcher = useAppDispatcher()

    const [nowDayjs, setNowDayjs] = useState(dayjs())

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    useEffect(() => {
        const interval = setInterval(() => {
            setNowDayjs(dayjs())
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    useEffect(() => {
        return () => {
            dispatcher(resetAvailableSlotDetail())
        }
    }, [])

    if (availableSlotDetail?.isLoading) {
        return (
            <ComponentLoader
                isLoading={availableSlotDetail?.isLoading}
                className={"min-h-[24rem] flex justify-center items-center"}
            />
        )
    }

    if (availableSlotDetail?.message) {
        return (
            <div className={"min-h-[24rem] flex justify-center items-center"}>
                <span className={"font-bodyPri font-medium text-md text-text-700"}>
                    {availableSlotDetail?.message}
                </span>
            </div>
        )
    }

    if (availableSlotDetail?.data?.result && availableSlotDetail?.data?.result?.type === courseType.GROUP.value) {
        return (
            <div className={"min-h-[24rem] flex justify-center items-center"}>
                <span className={"font-bodyPri font-medium text-md text-text-700"}>
                    {"Required One on One Availability"}
                </span>
            </div>
        )
    }

    return (
        <div className={"space-y-5 overflow-hidden"}>

            <div className={'grid grid-cols-12 gap-5'}>

                <div className={"col-span-12 space-y-3"}>
                    <span className={"truncate block text-center font-bodyPri font-medium text-base text-text-900"}>
                        {addSessionUserDetailPayload?.payload?.title?.substring(0, 50) + (addSessionUserDetailPayload?.payload?.title?.length > 50 ? "..." : "")}
                    </span>
                    <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>
                </div>

                <div className={"col-span-12 space-y-2"}>

                    <div className={"flex flex-wrap items-center justify-between"}>
                        <span className={"font-bodyPri font-normal text-sm text-text-700 text-center"}>
                            {"Available Slots"}
                        </span>
                        <span className={"font-bodyPri font-normal text-sm text-text-700 text-center"}>
                            {nowDayjs.tz(timeZone).format(`ddd, DD/MMM/YY hh:mm:ss A (z)`)}
                        </span>
                    </div>

                    <div className={cn(
                        "w-full flex flex-col items-start justify-start gap-5 h-full md:h-96 overflow-hidden overflow-y-scroll",
                        "scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                    )}>
                        <ComponentLoader isLoading={availableSlotDetail?.isLoading} />
                        {(availableSlotDetail?.message || availableSlotDetail?.data?.result?.availableSlots?.length <= 0) &&
                            <div className={"w-full h-full flex items-center justify-center"}>
                                <span className={"font-bodyPri font-semibold text-md text-text-700"}>
                                    {"No Available Slots"}
                                </span>
                            </div>
                        }
                        {availableSlotDetail?.data?.result?.availableSlots?.map((dailyAvail, index) => (
                            <div key={index} className={"flex flex-col items-start justify-start gap-3"}>
                                <span className={"font-bodyPri font-medium text-base text-text-900 flex items-center justify-start gap-2"}>
                                    <AiTwotoneCalendar />
                                    {dayjs(dailyAvail.date + timeZoneOffset, "YYYY-MM-DDZ").tz(timeZone).format('dddd, DD MMM YYYY')}
                                </span>
                                <div className={"px-3 flex flex-wrap items-center gap-2"}>
                                    {dailyAvail.timeSlots.map((timeSlot, index) => (
                                        <TimeSlot key={index} timeSlot={timeSlot} dailyAvail={dailyAvail} />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RescheduleTimeSlots;