import { useRef } from 'react'
import { cn } from 'utils/cn.utils';
import { motion, AnimatePresence } from 'framer-motion'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { MdKeyboardArrowRight, MdOutlineClose } from "react-icons/md";
import { RotatingLines } from 'react-loader-spinner';
import ToolTipView from 'components/tooltipView';

const AiTextButton = ({
    isShowInput,
    handlePrompt,
    getAiText,
    setIsShowInput,
    aiText,
    placeholder = "Enter ai prompt...",
    btnTitle = "Create Title with AI",
    btnClassName = "",
    containerClassName = "top-0 left-0",
    tooltip = false
}) => {
    const inputRef = useRef(null);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getAiText();
        }
    };

    return (
        <AnimatePresence initial={isShowInput}>
            {isShowInput && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    onBlur={() => setIsShowInput(false)}
                    className={cn('absolute z-50 w-60 h-32 pl-3 py-1 bg-white shadow-2xl flex flex-col justify-between border rounded-lg focus-within:border-primary-main',
                        containerClassName
                    )}>
                    <textarea
                        ref={inputRef}
                        className="w-full h-full outline-none resize-none scrollbar-thin"
                        placeholder={placeholder}
                        onChange={(e) => handlePrompt(e)}
                        onKeyDown={handleKeyDown}
                        autoFocus={true}
                    />
                    <button
                        className='flex items-center justify-center gap-0.5 text-primary-dark hover:text-secondary-dark'
                        onClick={getAiText}
                        onMouseDown={(e) => e.preventDefault()}
                    >
                        Generate
                        <MdKeyboardArrowRight size={16} />
                    </button>
                    <button
                        className='absolute -top-1 -right-1 rounded text-text-500 bg-white hover:text-secondary-dark'
                        onClick={() => setIsShowInput(false)}
                    >
                        <MdOutlineClose size={15} />
                    </button>
                </motion.div>
            )}
            {tooltip ? (
                <ToolTipView content={tooltip}>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className={cn(
                            'flex items-center gap-2 px-3 py-1 font-medium rounded-full whitespace-nowrap text-xs shadow',
                            'bg-white text-secondary-dark border border-secondary-dark',
                            aiText?.isLoading && "text-green-500 border-green-400", btnClassName
                        )}
                        onClick={() => setIsShowInput(true)}
                    >
                        {aiText?.isLoading ? (
                            <RotatingLines
                                visible={aiText?.isLoading}
                                height="16"
                                width="16"
                                strokeWidth="5"
                                animationDuration="0.75"
                            />
                        ) : (
                            <AutoFixHighIcon style={{ fontSize: 14 }} className={'animate-pulse text-secondary-dark'} />
                        )}
                        {aiText?.isLoading ? "Thinking..." : btnTitle}
                    </motion.button>
                </ToolTipView>
            ) : (
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={cn(
                        'flex items-center gap-2 px-3 py-1 font-medium rounded-full whitespace-nowrap text-xs shadow',
                        'bg-white text-secondary-dark border border-secondary-dark',
                        aiText?.isLoading && "text-green-500 border-green-400", btnClassName
                    )}
                    onClick={() => setIsShowInput(true)}
                >
                    {aiText?.isLoading ? (
                        <RotatingLines
                            visible={aiText?.isLoading}
                            height="16"
                            width="16"
                            strokeWidth="5"
                            animationDuration="0.75"
                        />
                    ) : (
                        <AutoFixHighIcon style={{ fontSize: 14 }} className={'animate-pulse text-secondary-dark'} />
                    )}
                    {aiText?.isLoading ? "Thinking..." : btnTitle}
                </motion.button>
            )}
        </AnimatePresence>
    )
}

export default AiTextButton
