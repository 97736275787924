import { createSlice } from "@reduxjs/toolkit";

import { WITHDRAWAL_INITIAL_STATE } from "redux/withdrawal/withdrawal.initialState";

const withdrawal = createSlice({
    name: "withdrawal",
    initialState: WITHDRAWAL_INITIAL_STATE,
    reducers: {
        // reducers for get my withdrawal list
        setMyWithdrawalListLoading: (state, { payload }) => {
            state.myWithdrawalList.isLoading = payload
        },
        setMyWithdrawalList: (state, { payload }) => {
            state.myWithdrawalList.myWithdrawalList = payload
            state.myWithdrawalList.filteredMyWithdrawalList = payload
            state.myWithdrawalList.errorMsg = WITHDRAWAL_INITIAL_STATE.myWithdrawalList.errorMsg
        },
        setMyWithdrawalListErrorMsg: (state, { payload }) => {
            state.myWithdrawalList.errorMsg = payload
            state.myWithdrawalList.myWithdrawalList = WITHDRAWAL_INITIAL_STATE.myWithdrawalList.myWithdrawalList
            state.myWithdrawalList.filteredMyWithdrawalList = WITHDRAWAL_INITIAL_STATE.myWithdrawalList.filteredMyWithdrawalList
        },
        setClearMyWithdrawalList: (state, { payload }) => {
            state.myWithdrawalList = WITHDRAWAL_INITIAL_STATE.myWithdrawalList
        },

        // reducers for get user withdrawal list
        setUserWithdrawalListLoading: (state, { payload }) => {
            state.userWithdrawalList.isLoading = payload
        },
        setUserWithdrawalList: (state, { payload }) => {
            state.userWithdrawalList.userWithdrawalList = payload
            state.userWithdrawalList.filteredUserWithdrawalList = payload
            state.userWithdrawalList.errorMsg = WITHDRAWAL_INITIAL_STATE.userWithdrawalList.errorMsg
        },
        setUserWithdrawalListErrorMsg: (state, { payload }) => {
            state.userWithdrawalList.errorMsg = payload
            state.userWithdrawalList.userWithdrawalList = WITHDRAWAL_INITIAL_STATE.userWithdrawalList.userWithdrawalList
            state.userWithdrawalList.filteredUserWithdrawalList = WITHDRAWAL_INITIAL_STATE.userWithdrawalList.filteredUserWithdrawalList
        },
        setClearUserWithdrawalList: (state) => {
            state.userWithdrawalList = WITHDRAWAL_INITIAL_STATE.userWithdrawalList
        },

        // reducers for add user withdrawal details
        setAddUserWithdrawalDetailLoading: (state, { payload }) => {
            state.addUserWithdrawalDetail.isLoading = payload
        },
        setAddUserWithdrawalDetail: (state, { payload }) => {
            state.addUserWithdrawalDetail.addUserWithdrawalDetail = payload
            state.addUserWithdrawalDetail.errorMsg = WITHDRAWAL_INITIAL_STATE.addUserWithdrawalDetail.errorMsg
        },
        setAddUserWithdrawalDetailErrorMsg: (state, { payload }) => {
            state.addUserWithdrawalDetail.errorMsg = payload
        },
        setClearAddUserWithdrawalDetail: (state) => {
            state.addUserWithdrawalDetail = WITHDRAWAL_INITIAL_STATE.addUserWithdrawalDetail
        },

        setAddUserWithdrawalDetailPayload: (state, { payload }) => {
            state.addUserWithdrawalDetail.userWithdrawalDetailPayload = payload
        },
        setClearAddUserWithdrawalDetailPayload: (state) => {
            state.addUserWithdrawalDetail.userWithdrawalDetailPayload = WITHDRAWAL_INITIAL_STATE.addUserWithdrawalDetail.userWithdrawalDetailPayload
        },
    }
})

export const {
    setMyWithdrawalListLoading,
    setMyWithdrawalList,
    setMyWithdrawalListErrorMsg,
    setClearMyWithdrawalList,

    setUserWithdrawalListLoading,
    setUserWithdrawalList,
    setUserWithdrawalListErrorMsg,
    setClearUserWithdrawalList,

    setAddUserWithdrawalDetailLoading,
    setAddUserWithdrawalDetail,
    setAddUserWithdrawalDetailErrorMsg,
    setClearAddUserWithdrawalDetail,
    
    setAddUserWithdrawalDetailPayload,
    setClearAddUserWithdrawalDetailPayload,
} = withdrawal.actions

export default withdrawal.reducer;