import { useState, memo, useEffect } from "react";

import Menu from "@mui/material/Menu";
import IconButton from '@mui/material/IconButton';
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";

import { BsThreeDotsVertical } from "react-icons/bs";

import useWindowSize from "hooks/useWindowSize";

import { cn } from "utils/cn.utils";

const useStyles = makeStyles({
    root: {},
});

const ITEM_HEIGHT = 48;

const PreviewMenuButtons = memo(({
    iconClassName = "",
    menuItems,
    onHandleSelect
}) => {

    const classes = useStyles();
    const { width } = useWindowSize()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (width >= 640) {
            setAnchorEl(null)
        }
    }, [width])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onHandleCloseMenu = () => {
        setAnchorEl(null)
    }
    const handleClose = (ele) => {
        setAnchorEl(null);
        onHandleSelect(ele)
    };

    return (
        <div className={""}>
            <IconButton
                aria-label={"more"}
                id={"long-button"}
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup={"true"}
                onClick={handleClick}
            >
                <BsThreeDotsVertical className={cn("text-xl md:text-2xl text-text-800 cursor-pointer", iconClassName)} />
            </IconButton>
            <Menu
                id={"long-menu"}
                anchorEl={anchorEl}
                open={open}
                onClose={onHandleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                classes={{
                    root: classes.root,
                }}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '16ch',
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {Object.values(menuItems).map((ele, idx) => {
                    if (!!ele?.acceptFileTypes) {
                        return (
                            <MenuItem
                                key={`${ele?.value}${idx}`}
                                // onClick={() => handleClose(ele)}
                                value={ele.label}
                            >
                                <label
                                    for={ele?.labelInputId}
                                    className={"w-full h-full flex items-center justify-start gap-2 cursor-pointer"}
                                >
                                    <span className={"whitespace-nowrap"}>
                                        {ele?.label}
                                    </span>
                                </label>
                                <input
                                    id={ele?.labelInputId}
                                    type={"file"}
                                    style={{ display: "none", width: "100%", height: "100%", cursor: "pointer" }}
                                    accept={ele?.acceptFileTypes}
                                    onChange={(event) => handleClose(ele, event)}
                                />
                            </MenuItem>
                        )
                    } else {
                        return (
                            <MenuItem key={idx} onClick={(event) => handleClose(ele, event)} value={ele.label}>
                                {ele.label}
                            </MenuItem>
                        )
                    }
                }
                )}
            </Menu>
        </div>
    );
});

export default PreviewMenuButtons;