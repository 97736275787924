import { useState, useEffect, memo, useCallback } from "react";

import { cn } from "utils/cn.utils";

import { AiOutlinePlus } from "react-icons/ai";

import ComponentLoader from "components/loader/ComponentLoader";
import InfinitePagination from "components/pagination/InfinitePagination";

import { libraryTabConst } from "components/modals/lmsModals/attachLibraryModal/data";

import { getLmsCoursePageQuizList } from "redux/edulyteLms/lmsCourse/lmsCourse.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetLocalTabs, setLocalTabs, setModal } from 'redux/local/local.slice';
import { resetLmsCoursePageQuizList } from "redux/edulyteLms/lmsCourse/lmsCourse.slice";
import { modalConst } from "redux/local/local.const";
import { lmsCourseSectionLessonTypeEnum } from "redux/edulyteLms/lmsCourse/lmsCourse.const";
import { lmsQuizStatusEnum } from "redux/edulyteLms/lmsQuiz/lmsQuiz.const";

const LmsQuizListItems = memo(({ quizSearch }) => {
    const { modal, localTabs } = useAppState((state) => state.local)
    const { lmsCoursePageQuizList } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    const [isDragging, setIsDragging] = useState(false)

    useEffect(() => {
        const requestDataPayload = {
            page: 1,
            records: 10
        }
        if (quizSearch) {
            requestDataPayload["search"] = quizSearch
        }
        dispatcher(getLmsCoursePageQuizList(requestDataPayload))

        return () => {
            dispatcher(resetLmsCoursePageQuizList())
        }
    }, [])

    const fetchMoreData = () => {
        if (lmsCoursePageQuizList?.data?.page === lmsCoursePageQuizList?.data?.totalPages) return;

        const requestDataPayload = {
            page: 1,
            records: lmsCoursePageQuizList?.data?.records
                ? (lmsCoursePageQuizList?.data?.records + 10)
                : lmsCoursePageQuizList?.data?.records || 10
        }
        if (quizSearch) {
            requestDataPayload["quizSearch"] = quizSearch
        }
        dispatcher(getLmsCoursePageQuizList(requestDataPayload))
    }

    const onHandleDragStart = (event, quizObject) => {
        if (![lmsQuizStatusEnum?.PUBLISHED?.value]?.includes(quizObject?.quiz_setting?.status)) return;

        const updatedQuizObject = { lessonType: lmsCourseSectionLessonTypeEnum.QUIZ.value, ...quizObject }
        event.dataTransfer.setData('application/json', JSON.stringify(updatedQuizObject))
    }

    const onHandleOpenQuizModal = useCallback(() => {
        dispatcher(resetLocalTabs())
        dispatcher(setLocalTabs({
            ...localTabs,
            tabList: Object.values(libraryTabConst),
            activeTabList: [libraryTabConst.QUIZZES.value],
            currentActiveTab: libraryTabConst.QUIZZES.value,
            tabItem: {
                ...localTabs?.tabItem,
                payload: null
            }
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: true,
        }))
    }, [modal, localTabs])

    return (
        <>
            {lmsCoursePageQuizList?.isLoading &&
                <ComponentLoader isLoading={lmsCoursePageQuizList?.isLoading} />
            }
            {lmsCoursePageQuizList?.message &&
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                        {lmsCoursePageQuizList?.message || "No Quiz Added"}
                    </span>
                </div>
            }
            {(lmsCoursePageQuizList?.data && (lmsCoursePageQuizList?.data?.results?.length === 0)) &&
                <div className={"w-full flex items-center justify-center gap-3"}>
                    <button
                        className={cn(
                            'w-full flex justify-center items-center gap-1 bg-white rounded-lg px-3 py-1.5',
                            'bg-text-200 hover:bg-text-300 ',
                            'font-bodyPri font-normal text-text-900 text-sm tracking-wide whitespace-nowrap'
                        )}
                        onClick={onHandleOpenQuizModal}
                    >
                        <AiOutlinePlus className={''} />
                        <span className={""}>
                            {"Add Quiz"}
                        </span>
                    </button>
                </div>
            }
            {(lmsCoursePageQuizList?.data && lmsCoursePageQuizList?.data?.results?.length > 0) &&
                <InfinitePagination
                    scrollableTarget={"scrollableDiv"}
                    dataLength={lmsCoursePageQuizList?.data?.totalRecords}
                    fetchData={fetchMoreData}
                    hasMore={(lmsCoursePageQuizList?.data?.page != lmsCoursePageQuizList?.data?.totalPages)}
                    loader={<ComponentLoader isLoading={lmsCoursePageQuizList?.isLoading} />}
                    isShowEndMessage={false}
                >
                    {lmsCoursePageQuizList?.data?.results?.map((quizItem) => (
                        <div
                            key={quizItem?.id}
                            className={cn(
                                'font-bodyPri line-clamp-1 mb-2 hover:bg-background-lightBlue rounded p-1',
                                isDragging ? "cursor-grabbing" : "cursor-grab",
                                ![lmsQuizStatusEnum?.PUBLISHED?.value]?.includes(quizItem?.quiz_setting?.status) && "!cursor-not-allowed"
                            )}
                            draggable={[lmsQuizStatusEnum?.PUBLISHED?.value]?.includes(quizItem?.quiz_setting?.status) && true}
                            onDragStart={(event) => onHandleDragStart(event, quizItem)}
                            onMouseDown={() => setIsDragging(true)}
                            onMouseUp={() => setIsDragging(false)}
                            onMouseMove={() => {
                                if (isDragging) {
                                    setIsDragging(false)
                                }
                            }}
                        >
                            {quizItem?.quiz_setting?.status &&
                                <span className={`text-xs text-${lmsQuizStatusEnum[quizItem?.quiz_setting?.status?.toUpperCase()]?.darkColor}`}>
                                    {`(${lmsQuizStatusEnum[quizItem?.quiz_setting?.status?.toUpperCase()]?.label})`}
                                </span>
                            }
                            <span className={"pl-1"}>{`${quizItem?.title}`}</span>
                        </div>
                    ))}
                </InfinitePagination>
            }
        </>
    )
});

export default LmsQuizListItems;