import { BsCircle } from "react-icons/bs";
import { BsRecordCircleFill } from "react-icons/bs";

import { updateUserDefaultDetailsByUserId } from "redux/default/default.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { payoutType } from 'redux/payout/payout.const';

const UPITransferDetails = ({ userPayout }) => {
    const { user } = useAppState((state) => state.user)
    const { userDefaultDetail } = useAppState((state) => state.userDefault)

    const dispatcher = useAppDispatcher()

    const handleUPIWithdrawMethod = () => {
            dispatcher(updateUserDefaultDetailsByUserId(
                user?.user?.userId,
                {
                    userPayoutId: userPayout?.userPayoutId
                }
            ))
    }

    return (
        <div className={"w-full flex justify-between items-center gap-3"}>
            <span className={"font-bodyPri font-medium text-text-900 text-lg"}>
                {payoutType[userPayout?.payoutType?.toUpperCase()]?.label}
            </span>
            <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                {userPayout?.upiId}
            </span>
            {(userDefaultDetail?.userDefaultDetail?.userPayoutId !== userPayout?.userPayoutId) &&
                <BsCircle
                    className={"text-lg text-text-800 cursor-pointer"}
                    onClick={handleUPIWithdrawMethod}
                />
            }
            {(userDefaultDetail?.userDefaultDetail?.userPayoutId === userPayout?.userPayoutId) &&
                <BsRecordCircleFill
                    className={"text-lg text-primary-dark cursor-pointer"}
                    onClick={handleUPIWithdrawMethod}
                />
            }
        </div>
    )
}

export default UPITransferDetails