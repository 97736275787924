import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { motion } from "framer-motion";

import { FaSpinner } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import { MdEdit } from "react-icons/md";

import ButtonLoader from "components/loader/button-loader";
import ToolTipView from "components/tooltipView";

import { validateContactPayload } from "components/modals/crmModals/data";

import { uploadFileToS3 } from "redux/storage/storage.request";
import { deleteCrmContactDetail, updateCrmContactDetail } from "redux/crm/crm.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetModifyCrmContactDetail, resetModifyCrmContactDetailPayload, setModifyCrmContactDetailPayload } from "redux/crm/crm.slice";
import { resetUploadFile } from "redux/storage/storage.slice";
import { USER_DEMO_PROFILE } from "redux/user/user.const";
import { mbToKbConst, profilePicTypeConst } from "redux/storage/storage.const";

import { cn } from "utils/cn.utils";

function EditContactIdentity({ onClose, editBtnConst, onHandleGoBack }) {
    const { uploadFile } = useAppState((state) => state.storage)
    const { user } = useAppState((state) => state.user)
    const { modifyCrmContactDetail, destroyCrmContactDetail } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()
    const location = useLocation()

    useEffect(() => {
        if (uploadFile?.data && uploadFile?.data?.length > 0) {
            dispatcher(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                profilePicInfo: {
                    fileList: [],
                    file: {},
                    fileUrl: "",
                    picture_url: uploadFile?.data[0]?.fileUrl
                }
            }))
            dispatcher(resetUploadFile())
            // toast.success("Profile picture uploaded!")
        }
    }, [uploadFile?.data])

    useEffect(() => {
        if (!!modifyCrmContactDetail?.payload?.profilePicInfo?.fileList?.length) {
            dispatcher(uploadFileToS3(modifyCrmContactDetail?.payload?.profilePicInfo?.fileList, user?.user?.userId))
        }
    }, [modifyCrmContactDetail?.payload?.profilePicInfo?.fileList])

    const onHandleSelectImage = useCallback((event) => {
        if (uploadFile?.isLoading || editBtnConst) return;

        const files = event.target.files;
        if (!files || files.length === 0) {
            alert("Image not selected!")
            return;
        }

        const file = files[0];
        if (file?.size <= (2 * mbToKbConst)) {
            if (profilePicTypeConst?.includes(file?.type)) {
                dispatcher(setModifyCrmContactDetailPayload({
                    ...modifyCrmContactDetail?.payload,
                    profilePicInfo: {
                        ...modifyCrmContactDetail?.payload?.profilePicInfo,
                        fileList: event?.target?.files,
                        file: file,
                        fileUrl: URL.createObjectURL(file)
                    }
                }))
            } else {
                toast.warn("Please select a valid document!")
            }
        } else {
            toast.error("File size is too large!")
        }
    }, [uploadFile, modifyCrmContactDetail?.payload, editBtnConst])

    const onHandleDeleteContact = useCallback(() => {
        if (destroyCrmContactDetail?.isLoading || editBtnConst) return;
        const searchQueryParams = new URLSearchParams(location.search.toString())
        const contactId = searchQueryParams?.get("contact")
        if (!window.confirm("Are you sure?. You want to delete contact(s).")) return;

        dispatcher(deleteCrmContactDetail(contactId))
    }, [destroyCrmContactDetail?.isLoading, modifyCrmContactDetail?.payload, editBtnConst])

    const onHandleUpdateContact = async () => {
        if (modifyCrmContactDetail?.isLoading || editBtnConst) return;

        const { errorMsg, requestPayload } = await validateContactPayload(modifyCrmContactDetail?.payload)
        if (errorMsg) {
            toast.warn(errorMsg)
        }
        if (!errorMsg && requestPayload) {
            const searchQueryParams = new URLSearchParams(location.search.toString())
            const contactId = searchQueryParams?.get("contact")
            dispatcher(updateCrmContactDetail(contactId, requestPayload))
        }
    }

    const onHandleCloseModal = useCallback(() => {
        dispatcher(resetModifyCrmContactDetail())
        dispatcher(resetModifyCrmContactDetailPayload())
        onClose()
    }, [modifyCrmContactDetail?.payload])

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <div className="flex item-center justify-between gap-5">
                <ToolTipView content={"Back"}>
                    <button className={"w-fit p-2 rounded-full hover:bg-divider-darkLight cursor-pointer"} onClick={onHandleGoBack}>
                        <IoMdArrowRoundBack
                            className={'text-background-dark'}
                            size={25}
                        />
                    </button>
                </ToolTipView>
                <div className="flex items-center gap-3">
                    <ToolTipView disabled={editBtnConst} content={"Delete Contact"}>
                        <motion.button
                            whileHover={!editBtnConst && { scale: 1.05 }}
                            whileTap={!editBtnConst && { scale: 0.9 }}
                            transition={!editBtnConst && { duration: 0.3 }}
                            className={cn(
                                "flex justify-center items-center h-8 w-8 text-xl focus:outline-none",
                                !editBtnConst && "hover:bg-secondary-light rounded-full ease-in-out duration-200 cursor-pointer",
                                editBtnConst && "bg-text-200 text-text-400 cursor-not-allowed"
                            )}
                            onClick={onHandleDeleteContact}
                            disabled={editBtnConst}
                        >
                            {destroyCrmContactDetail?.isLoading &&
                                <FaSpinner className={"animate-spin text-primary-dark text-lg"} />
                            }
                            {!destroyCrmContactDetail?.isLoading &&
                                <AiOutlineDelete />
                            }
                        </motion.button>
                    </ToolTipView>
                    <motion.button
                        whileHover={!editBtnConst && { scale: 1.05 }}
                        whileTap={!editBtnConst && { scale: 0.9 }}
                        transition={!editBtnConst && { duration: 0.3 }}
                        className={cn(
                            "flex justify-center items-center h-8 px-5 focus:outline-none",
                            !editBtnConst && "text-white bg-primary-dark hover:bg-secondary-dark rounded ease-in-out duration-200",
                            editBtnConst && "bg-text-200 text-text-400 cursor-not-allowed"
                        )}
                        disabled={editBtnConst}
                        onClick={onHandleUpdateContact}
                    >
                        {modifyCrmContactDetail?.isLoading &&
                            <ButtonLoader isLoading={modifyCrmContactDetail?.isLoading} />
                        }
                        {!modifyCrmContactDetail?.isLoading && "Save"}
                    </motion.button>
                    <ToolTipView content={"Close"}>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ duration: 0.3 }}
                            className="flex justify-center items-center h-8 w-8 text-lg hover:bg-background-light rounded-full ease-in-out duration-200"
                            onClick={onHandleCloseModal}
                        >
                            <AiOutlineClose />
                        </motion.button>
                    </ToolTipView>
                </div>
            </div>
            <div className={"w-28 h-28 aspect-square rounded-full border border-primary-main relative"}>
                <img
                    src={modifyCrmContactDetail?.payload?.profilePicInfo?.picture_url || USER_DEMO_PROFILE}
                    alt={`${modifyCrmContactDetail?.payload?.title}. ${modifyCrmContactDetail?.payload?.first_name} ${modifyCrmContactDetail?.payload?.last_name}`}
                    className={"w-full h-full rounded-full object-cover"}
                />
                <label
                    htmlFor={"file"}
                    className={cn(
                        "absolute -bottom-1 -right-1 w-8 h-8 flex justify-center items-center rounded-full border-2",
                        !editBtnConst && "bg-primary-light border-white cursor-pointer hover:text-secondary-dark",
                        editBtnConst && "bg-text-200 cursor-not-allowed"
                    )}>
                    <input
                        className={"w-full"}
                        type={"file"}
                        id={"file"}
                        name={"file"}
                        style={{ display: "none" }}
                        multiple={false}
                        accept={"image/x-png, image/png, image/jpg, image/jpeg, image/gif"}
                        onChange={onHandleSelectImage}
                    />
                    {uploadFile?.isLoading && <FaSpinner className={"animate-spin text-primary-dark text-md"} />}
                    {!uploadFile?.isLoading && <MdEdit />}
                </label>
            </div>
        </div>
    );
}

export default EditContactIdentity;