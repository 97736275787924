const initialState = {
    currentPageInfo: {
        pageName: '',
        pagePath: '',
        component: null,
    },
    previousPageInfo: {
        pageName: '',
        pagePath: '',
        component: null,
    },
}

export default initialState