import React from "react";

// Components
import Icon from "../Icon";
import dayjs from "dayjs";

import RatingStars from "components/ratingStars/RatingStars";

const ReviewCard = (props) => {

    return (
        <div className={"h-fit w-full col-span-1 bg-gray-100 p-5 space-y-3 rounded-lg"}>
            <div className="flex gap-3">
                <RatingStars fullRating={props?.rating} rating={props?.rating} />
            </div>

            <div className="italic">
                {props.content}
            </div>
            <div className="flex items-center gap-3">
                <div className="w-10 h-10">
                    <img
                        src={props.profileImg}
                        alt="profileImg"
                        className="w-full h-full rounded-full object-cover"
                    />
                </div>
                <div className="">
                    <div className="flex gap-1 text-base font-medium">
                        <span>
                            {props.fName}
                        </span>
                    </div>
                    <div className="text-gray-500 text-sm">
                        {dayjs(props.createdAt).local().format("MMM D, YY HH:MM A").toString()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewCard;