import { addLmsQuestionDetailPayload, addLmsQuestionOptionPayload, updateLmsQuestionAnswerPayload, lmsQuestionSettingsDetailPayload, addLmsQuestionInstructorDetailPayload } from "redux/edulyteLms/lmsQuestion/lmsQuestion.const";

export const LMS_QUESTION_INITIAL_STATE = {
    lmsQuestionList: {
        isLoading: false,
        data: null,
        message: null
    },

    // lms question detail state
    lmsQuestionDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    // add lms question state
    addLmsQuestionDetail: {
        isLoading: false,
        data: null,
        payload: addLmsQuestionDetailPayload,
        message: null
    },

    // update lms question state
    updateLmsQuestion: {
        isLoading: false,
        message: null
    },

    // add lms question resource
    addLmsQuestionResource: {
        isLoading: false,
        message: null
    },

    // delete lms question resource
    deleteLmsQuestionResource: {
        isLoading: false,
        message: null
    },

    // add lms question option
    addLmsQuestionOption: {
        isLoading: false,
        data: null,
        payload: addLmsQuestionOptionPayload,
        message: null
    },

    // update lms question option
    updateLmsQuestionOption: {
        isLoading: false,
        data: null,
        message: null,
    },

    // update order lms question option
    updateOrderLmsQuestionOption: {
        isLoading: false,
        message: null
    },

    // delete lms question option
    deleteLmsQuestionOption: {
        isLoading: false,
        message: null
    },

    // add lms question option resource
    addLmsQuestionOptionResource: {
        isLoading: false,
        message: null
    },

    // delete lms question option resource
    deleteLmsQuestionOptionResource: {
        isLoading: false,
        message: null
    },

    // update lms question answer
    updateLmsQuestionAnswer: {
        isLoading: false,
        data: null,
        payload: updateLmsQuestionAnswerPayload,
        message: null
    },

    // add lms question answer resource
    addLmsQuestionAnswerResource: {
        isLoading: false,
        message: null
    },

    // delete lms question answer resource
    deleteLmsQuestionAnswerResource: {
        isLoading: false,
        message: null
    },

    // lms question settings detail
    lmsQuestionSettingsDetail: {
        isLoading: false,
        payload: lmsQuestionSettingsDetailPayload,
        message: null
    },

    // lms question instructor detail
    addLmsQuestionInstructorDetail: {
        isLoading: false,
        data: null,
        payload: addLmsQuestionInstructorDetailPayload,
        message: null
    },
    modifyLmsQuestionInstructorDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    destroyLmsQuestionInstructorDetail: {
        isLoading: false,
        message: null
    },

    // question tag list
    lmsQuestionTagList: {
        isLoading: false,
        data: null,
        message: null
    },
    addLmsQuestionTagDetail: {
        isLoading: false,
        message: null
    },
    modifyLmsQuestionTagListOrder: {
        isLoading: false,
        message: null,
    },
    destroyLmsQuestionTagDetail: {
        isLoading: false,
        message: null
    },

    // page based state
    lmsQuestionPageResourceList: {
        isLoading: false,
        data: null,
        message: null
    },
    lmsCreateBulkQuestions: {
        isLoading: false,
        data: null,
        message: null
    },

}