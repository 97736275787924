import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import AddContactPhoneItem from "components/modals/crmModals/createCrmContactDetailModal/commonComponents/ContactPhoneItem";
import ContactPhoneItem from "components/modals/crmModals/viewContactDetailModal/commonComponents/ContactPhoneItem";
import AddItemBtn from "components/modals/crmModals/commonComponents/AddItemBtn";
import { crmDetailBtnConst, sortDefaultContact } from "components/modals/crmModals/data";

import crmService from "redux/crm/crm.service";
import { createCrmContactPhoneDetail, deleteCrmContactPhoneDetail, updateCrmContactPhoneDetail } from "redux/crm/crm.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetAddCrmContactPhoneDetail, resetModifyCrmContactPhoneDetail, setCrmContactDetailData, setModifyCrmContactDetailPayload } from "redux/crm/crm.slice";
import { crmContactLabelsEnum } from "redux/crm/crm.const";

const newPhoneInitialState = {
    id: 0,
    isShowInput: false,
    country: null,
    phone: "",
    label: null,
    is_primary: false
}

function EditContactNumbers({ editBtnConst, setEditBtnConst }) {
    const { countryList, timeZoneCountryDetail } = useAppState((state) => state.master)
    const { crmContactDetail, modifyCrmContactDetail, addCrmContactPhoneDetail, modifyCrmContactPhoneDetail, destroyCrmContactPhoneDetail } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()

    const [newPhoneDetail, setNewPhoneDetail] = useState({
        id: 0,
        isShowInput: false,
        country: null,
        phone: "",
        label: null,
        is_primary: false
    })

    const getUpdatedContactPhoneDetail = () => {
        if (addCrmContactPhoneDetail?.data) {
            const phonePayload = {
                id: addCrmContactPhoneDetail?.data?.id,
                country: newPhoneDetail?.country,
                phone: newPhoneDetail?.phone,
                label: newPhoneDetail?.label,
                is_primary: newPhoneDetail?.is_primary
            }
            if (!!modifyCrmContactDetail?.payload?.phones?.length && newPhoneDetail?.is_primary) {
                const filteredPhoneList = modifyCrmContactDetail?.payload?.phones?.map((item) => ({ ...item, is_primary: false }))
                dispatcher(setModifyCrmContactDetailPayload({
                    ...modifyCrmContactDetail?.payload,
                    phones: [...filteredPhoneList, phonePayload]
                }))
                dispatcher(setCrmContactDetailData({
                    ...crmContactDetail?.data,
                    phone: addCrmContactPhoneDetail?.data
                }))
            } else {
                dispatcher(setModifyCrmContactDetailPayload({
                    ...modifyCrmContactDetail?.payload,
                    phones: [...modifyCrmContactDetail?.payload?.phones, phonePayload]
                }))
            }
            dispatcher(resetAddCrmContactPhoneDetail())
        } else if (modifyCrmContactPhoneDetail?.data) {
            const phonePayload = {
                id: modifyCrmContactPhoneDetail?.data?.id,
                country: newPhoneDetail?.country,
                phone: newPhoneDetail?.phone,
                label: newPhoneDetail?.label,
                is_primary: newPhoneDetail?.is_primary
            }
            dispatcher(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                phones: modifyCrmContactDetail?.payload?.phones?.map((phoneItem) => (
                    (phoneItem?.id === modifyCrmContactPhoneDetail?.data?.id) ? phonePayload : phoneItem
                ))
            }))
            dispatcher(resetModifyCrmContactPhoneDetail())
        }
        setNewPhoneDetail(newPhoneInitialState)
        setEditBtnConst(null)
    }

    useEffect(() => {
        if (addCrmContactPhoneDetail?.data || modifyCrmContactPhoneDetail?.data) {
            getUpdatedContactPhoneDetail()
        }
    }, [addCrmContactPhoneDetail?.data, modifyCrmContactPhoneDetail?.data])

    const onHandleAddNewPhone = () => {
        if (modifyCrmContactPhoneDetail?.isLoading || destroyCrmContactPhoneDetail?.isLoading || modifyCrmContactDetail?.isLoading) return;

        if (editBtnConst) return;

        let myLocalCountryList = countryList?.countryList?.filter((country) => (
            country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
        ))

        if (myLocalCountryList.length === 0) {
            myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.countryDomain === "US"
            ))
        }
        const defaultPhoneNumber = modifyCrmContactDetail?.payload?.phones?.filter((item) => item?.is_primary)
        setNewPhoneDetail({
            ...newPhoneDetail,
            isShowInput: true,
            country: myLocalCountryList[0],
            phone: "",
            label: { label: crmContactLabelsEnum.HOME.label, value: crmContactLabelsEnum.HOME.value },
            is_primary: (defaultPhoneNumber?.length > 0) ? false : true
        })
        setEditBtnConst({
            ...editBtnConst,
            id: 0,
            active: crmDetailBtnConst?.phones.key
        })
    }

    const onHandlePrimaryPhoneDetail = async (phoneItemId, updatedValue) => {
        if (crmContactDetail?.data?.phone?.id === phoneItemId) return;
        setEditBtnConst(null)
        try {
            const requestPayload = {
                params: { contactId: crmContactDetail?.data?.id },
                body: {
                    default_phone_id: phoneItemId
                }
            }
            const response = await crmService.updateCrmContactDetail(requestPayload)
            if (response.status === 200) {
                dispatcher(setModifyCrmContactDetailPayload({
                    ...modifyCrmContactDetail?.payload,
                    phones: modifyCrmContactDetail?.payload?.phones?.map((phone) => (
                        (phone?.id === phoneItemId) ? updatedValue : { ...phone, is_primary: false }
                    ))
                }))
                dispatcher(setCrmContactDetailData({
                    ...crmContactDetail?.data,
                    phone: { ...response.data.data.phone, id: phoneItemId }
                }))
                toast.success(response.data.message || "Updated Successfully")
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || error)
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        }
    }

    const onHandleSavePhoneDetail = (phoneItem) => {
        if (addCrmContactPhoneDetail?.isLoading || modifyCrmContactPhoneDetail?.isLoading) return;

        const payload = {
            phone: `${phoneItem?.country?.isdCode}-${phoneItem?.phone}`,
            label: phoneItem?.label?.value,
            is_primary: phoneItem?.is_primary
        }
        dispatcher(updateCrmContactPhoneDetail(phoneItem?.id, payload))
        setNewPhoneDetail(phoneItem)
    }

    const onHandleDeletePhoneDetail = useCallback((phoneItemId) => {
        if (modifyCrmContactPhoneDetail?.isLoading || destroyCrmContactPhoneDetail?.isLoading) return;
        if (!window.confirm("Are you sure?. You want to delete phone details.")) return;

        dispatcher(deleteCrmContactPhoneDetail(phoneItemId))
        setEditBtnConst(null)
    }, [modifyCrmContactPhoneDetail?.isLoading, destroyCrmContactPhoneDetail?.isLoading])

    const onHandleNewPrimaryPhone = useCallback((phoneItem, updatedValue) => {
        if (newPhoneDetail?.is_primary === updatedValue?.is_primary) return;
        setNewPhoneDetail(updatedValue)
    }, [newPhoneDetail])

    const onHandleSaveNewPhone = (phoneItem) => {
        if (addCrmContactPhoneDetail?.isLoading || modifyCrmContactPhoneDetail?.isLoading) return;
        if (!phoneItem?.phone) {
            toast.warn("Please enter phone number!")
            return;
        }
        if (!phoneItem?.label?.value) {
            toast.warn("Please select label!")
            return;
        }
        const duplicatePhone = modifyCrmContactDetail?.payload?.phones?.filter((phone) => (phone?.phone === phoneItem?.phone))
        if (!!duplicatePhone?.length) {
            toast.warn("Phone already added!")
            return;
        }
        const payload = {
            contact_id: modifyCrmContactDetail?.payload?.contact_id,
            phone: `${phoneItem?.country?.isdCode}-${phoneItem?.phone}`,
            label: phoneItem?.label?.value,
            is_primary: phoneItem?.is_primary
        }
        dispatcher(createCrmContactPhoneDetail(payload))
        setNewPhoneDetail(phoneItem)
    }

    const onHandleDeleteNewPhone = useCallback(() => {
        setNewPhoneDetail(newPhoneInitialState)
        setEditBtnConst(null)
    }, [])

    return (
        <div className="w-full flex flex-col gap-8 items-center">
            {!!modifyCrmContactDetail?.payload?.phones?.length &&
                modifyCrmContactDetail?.payload?.phones?.slice(0)?.sort((item1, item2) => sortDefaultContact(item1?.id, item2?.id, crmContactDetail?.data?.phone?.id))?.map((phoneItem, index) => (
                    <ContactPhoneItem
                        key={phoneItem?.id}
                        index={index}
                        isLoading={modifyCrmContactPhoneDetail?.isLoading || destroyCrmContactPhoneDetail?.isLoading}
                        isShowSaveBtn={true}
                        isShowCancelBtn={true}
                        phoneItem={phoneItem}
                        editBtnConst={editBtnConst}
                        setEditBtnConst={setEditBtnConst}
                        onHandlePrimaryPhoneDetail={onHandlePrimaryPhoneDetail}
                        onHandleSavePhoneDetail={onHandleSavePhoneDetail}
                        onHandleDeletePhoneDetail={onHandleDeletePhoneDetail}
                    />
                ))}
            {newPhoneDetail?.isShowInput &&
                <AddContactPhoneItem
                    isLoading={addCrmContactPhoneDetail?.isLoading}
                    isShowSaveBtn={true}
                    isShowCancelBtn={true}
                    phoneItem={newPhoneDetail}
                    onHandleChangePhoneDetail={(phoneId, updatedValue) => setNewPhoneDetail(updatedValue)}
                    onHandlePrimaryPhoneDetail={onHandleNewPrimaryPhone}
                    onHandleSavePhoneDetail={onHandleSaveNewPhone}
                    onHandleDeletePhoneDetail={onHandleDeleteNewPhone}
                />
            }
            {!newPhoneDetail?.isShowInput &&
                <AddItemBtn
                    btnTitle={"Add Phone"}
                    isBtnDisable={editBtnConst}
                    onHandleAddBtn={onHandleAddNewPhone}
                />
            }
        </div>
    );
}

export default EditContactNumbers;