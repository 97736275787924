import { toast } from 'react-toastify';

import lmsCourseService from 'redux/edulyteLms/lmsCourse/lmsCourse.service';
import lmsQuizService from 'redux/edulyteLms/lmsQuiz/lmsQuiz.service';
import lmsArticleService from 'redux/edulyteLms/lmsArticle/lmsArticle.service';
import lmsLectureService from 'redux/edulyteLms/lmsLecture/lmsLecture.service';

import {
    setLmsCourseListLoading,
    setLmsCourseListData,
    setLmsCourseListMessage,

    setLmsCourseDetailLoading,
    setLmsCourseDetailData,
    setLmsCourseDetailMessage,

    setAddLmsCourseDetailLoading,
    setAddLmsCourseDetailData,
    setAddLmsCourseDetailMessage,

    setModifyLmsCourseDetailLoading,
    setModifyLmsCourseDetailData,
    setModifyLmsCourseDetailMessage,

    setAddLmsCourseSectionDetailLoading,
    setAddLmsCourseSectionDetailData,
    setAddLmsCourseSectionDetailMessage,

    setLmsCourseSectionDetailLoading,
    setLmsCourseSectionDetailData,
    setLmsCourseSectionDetailMessage,

    setLmsCourseSectionListLoading,
    setLmsCourseSectionListData,
    setLmsCourseSectionListMessage,

    setUpdateLmsCourseSectionLoading,
    setUpdateLmsCourseSectionData,
    setUpdateLmsCourseSectionMessage,

    setUpdateOrderLmsCourseSectionLoading,
    setUpdateOrderLmsCourseSectionData,
    setUpdateOrderLmsCourseSectionMessage,

    setDeleteLmsCourseSectionDetailLoading,
    setDeleteLmsCourseSectionDetailMessage,

    setAddLmsCourseSectionLessonLoading,
    setAddLmsCourseSectionLessonMessage,

    setLmsCourseSectionLessonData,
    setLmsCourseSectionLessonMessage,
    setLmsCourseSectionLessonLoading,

    setLmsCourseSectionLessonListData,
    setLmsCourseSectionLessonListMessage,
    setLmsCourseSectionLessonListLoading,

    setUpdateLmsCourseSectionLessonLoading,
    setUpdateLmsCourseSectionLessonData,
    setUpdateLmsCourseSectionLessonMessage,

    setUpdateOrderLmsCourseSectionLessonLoading,
    setUpdateOrderLmsCourseSectionLessonData,
    setUpdateOrderLmsCourseSectionLessonMessage,

    setDeleteLmsCourseSectionLessonLoading,
    setDeleteLmsCourseSectionLessonMessage,

    setAddLmsCourseTagDetailLoading,
    setAddLmsCourseTagDetailMessage,

    setUpdateLmsCourseTagListOrderLoading,
    setUpdateLmsCourseTagListOrderMessage,

    setDeleteLmsCourseTagDetailLoading,
    setDeleteLmsCourseTagDetailMessage,

    setAddLmsCourseLanguageDetailLoading,
    setAddLmsCourseLanguageDetailMessage,

    setUpdateLmsCourseLanguageListOrderLoading,
    setUpdateLmsCourseLanguageListOrderMessage,

    setDeleteLmsCourseLanguageDetailLoading,
    setDeleteLmsCourseLanguageDetailMessage,

    setAddLmsCourseProfDetailLoading,
    setAddLmsCourseProfDetailMessage,

    setUpdateLmsCourseProfListOrderLoading,
    setUpdateLmsCourseProfListOrderMessage,

    setDeleteLmsCourseProfDetailLoading,
    setDeleteLmsCourseProfDetailMessage,

    setAddLmsCourseLearningDetailLoading,
    setAddLmsCourseLearningDetailMessage,

    setModifyLmsCourseLearningDetailLoading,
    setModifyLmsCourseLearningDetailData,
    setModifyLmsCourseLearningDetailMessage,

    setUpdateLmsCourseLearningListOrderLoading,
    setUpdateLmsCourseLearningListOrderMessage,

    setDeleteLmsCourseLearningDetailLoading,
    setDeleteLmsCourseLearningDetailMessage,

    setAddLmsCourseFaqDetailLoading,
    setAddLmsCourseFaqDetailMessage,

    setModifyLmsCourseFaqDetailLoading,
    setModifyLmsCourseFaqDetailData,
    setModifyLmsCourseFaqDetailMessage,

    setUpdateLmsCourseFaqListOrderLoading,
    setUpdateLmsCourseFaqListOrderMessage,

    setDeleteLmsCourseFaqDetailLoading,
    setDeleteLmsCourseFaqDetailMessage,

    setAddLmsCourseSlugDetailLoading,
    setAddLmsCourseSlugDetailMessage,

    setDestroyLmsCourseSlugDetailLoading,
    setDestroyLmsCourseSlugDetailMessage,

    setAddLmsCourseInstructorDetailLoading,
    setAddLmsCourseInstructorDetailMessage,

    setModifyLmsCourseInstructorDetailLoading,
    setModifyLmsCourseInstructorDetailMessage,

    setDestroyLmsCourseInstructorDetailLoading,
    setDestroyLmsCourseInstructorDetailMessage,

    setLmsCourseStudentListLoading,
    setLmsCourseStudentListData,
    setLmsCourseStudentListMessage,

    setAddLmsCourseStudentDetailLoading,
    setAddLmsCourseStudentDetailMessage,

    setModifyLmsCourseStudentDetailLoading,
    setModifyLmsCourseStudentDetailMessage,

    setDestroyLmsCourseStudentDetailLoading,
    setDestroyLmsCourseStudentDetailMessage,

    setStudentLmsCourseListLoading,
    setStudentLmsCourseListData,
    setStudentLmsCourseListMessage,

    setStudentFavLmsCourseListLoading,
    setStudentFavLmsCourseListData,
    setStudentFavLmsCourseListMessage,

    setAddStudentFavLmsCourseDetailLoading,
    setAddStudentFavLmsCourseDetailMessage,

    setStudentLmsCourseDetailLoading,
    setStudentLmsCourseDetailData,
    setStudentLmsCourseDetailMessage,

    setLmsCourseInvitationListLoading,
    setLmsCourseInvitationListData,
    setLmsCourseInvitationListMessage,

    setValidateLmsCourseInvitationDetailData,
    setValidateLmsCourseInvitationDetailLoading,
    setValidateLmsCourseInvitationDetailMessage,
    
    setAcceptAndDeclineLmsCourseInvitationDetailLoading,
    setAcceptAndDeclineLmsCourseInvitationDetailMessage,
    
    setSendLmsCourseInvitationDetailLoading,
    setSendLmsCourseInvitationDetailData,
    setSendLmsCourseInvitationDetailMessage,

    setDestroyLmsCourseInvitationDetailLoading,
    setDestroyLmsCourseInvitationDetailMessage,
    setAddDestroyLmsCourseStudentSectionLessonLoading,
    setAddDestroyLmsCourseStudentSectionLessonMessage,

    setAddLmsCourseStudentQuizResponseDetailLoading,
    setAddLmsCourseStudentQuizResponseDetailData,
    setAddLmsCourseStudentQuizResponseDetailMessage,

    setModifyLmsCourseStudentQuizResponseDetailLoading,
    setModifyLmsCourseStudentQuizResponseDetailMessage,

    /* lms course student note */
    setAddLmsCourseStudentNoteDetailLoading,
    setAddLmsCourseStudentNoteDetailData,
    setAddLmsCourseStudentNoteDetailMessage,

    setLmsCourseStudentNoteListLoading,
    setLmsCourseStudentNoteListData,
    setLmsCourseStudentNoteListMessage,

    setLmsCourseStudentNoteDetailLoading,
    setLmsCourseStudentNoteDetailData,
    setLmsCourseStudentNoteDetailMessage,

    setModifyLmsCourseStudentNoteDetailLoading,
    setModifyLmsCourseStudentNoteDetailData,
    setModifyLmsCourseStudentNoteDetailMessage,

    setDestroyLmsCourseStudentNoteDetailLoading,
    setDestroyLmsCourseStudentNoteDetailData,
    setDestroyLmsCourseStudentNoteDetailMessage,

    setLmsCoursePageLectureListLoading,
    setLmsCoursePageLectureListData,
    setLmsCoursePageLectureListMessage,

    setLmsCoursePageArticleListLoading,
    setLmsCoursePageArticleListData,
    setLmsCoursePageArticleListMessage,

    setLmsCoursePageQuizListLoading,
    setLmsCoursePageQuizListData,
    setLmsCoursePageQuizListMessage,
} from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';

import { setUser } from 'redux/user/user.slice';

export const getLmsCourseList = (query) => async (dispatch) => {
    dispatch(setLmsCourseListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await lmsCourseService.getTutorLmsCourseList(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
        dispatch(setLmsCourseListMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
    }
    finally {
        dispatch(setLmsCourseListLoading(false))
    }
}

export const createLmsCourseDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsCourseDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await lmsCourseService.createLmsCourseDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddLmsCourseDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
        dispatch(setAddLmsCourseDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
    } finally {
        dispatch(setAddLmsCourseDetailLoading(false))
    }
}

export const getLmsCourseDetail = (courseId) => async (dispatch) => {
    dispatch(setLmsCourseDetailLoading(true))

    try {
        const requestData = {
            params: { courseId: courseId }
        }
        const response = await lmsCourseService.getTutorLmsCourseDetail(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong.")
        dispatch(setLmsCourseDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong."))
    } finally {
        dispatch(setLmsCourseDetailLoading(false))
    }
}

export const updateLmsCourseDetail = (courseId, body = {}, payload = { isShowToast: true }) => async (dispatch) => {
    dispatch(setModifyLmsCourseDetailLoading(true))

    try {
        const requestData = {
            params: { courseId: courseId },
            body: body
        }
        const response = await lmsCourseService.updateLmsCourseDetail(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseDetailData(response.data.data))
            dispatch(setModifyLmsCourseDetailData(response.data.data))
            if (payload?.isShowToast) {
                toast.success(response.data.message)
            }
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong.")
        dispatch(setModifyLmsCourseDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong.")
    } finally {
        dispatch(setModifyLmsCourseDetailLoading(false))
    }
}

export const deleteLmsCourse = (courseId) => async (dispatch) => {
    dispatch(setModifyLmsCourseDetailLoading(true))

    try {
        const requestData = {
            courseId: courseId
        }
        const response = await lmsCourseService.deleteLmsCourseDetail(requestData)
        if (response.status === 204) {
            // dispatch(setUpdateLmsCourseDetailMessage(response.data.message))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
        dispatch(setModifyLmsCourseDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
    } finally {
        dispatch(setModifyLmsCourseDetailLoading(false))
    }
}

// lms course section requests
export const createLmsCourseSectionDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsCourseSectionDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            body: body
        }
        const response = await lmsCourseService.createLmsCourseSectionDetail(requestData)
        if (response.status === 201 || response.status === 200) {
            dispatch(setAddLmsCourseSectionDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
        dispatch(setAddLmsCourseSectionDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
    } finally {
        dispatch(setAddLmsCourseSectionDetailLoading(false))
    }
}

export const getLmsCourseSectionList = (query) => async (dispatch) => {
    dispatch(setLmsCourseSectionListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await lmsCourseService.getLmsCourseSectionList(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseSectionListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
        dispatch(setLmsCourseSectionListMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
    } finally {
        dispatch(setLmsCourseSectionListLoading(false))
    }
}

export const getLmsCourseSectionDetail = (sectionId) => async (dispatch) => {
    dispatch(setLmsCourseSectionDetailLoading(true))

    try {
        const requestData = {
            params: { sectionId: sectionId }
        }
        const response = await lmsCourseService.getLmsCourseSectionDetail(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseSectionDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
        dispatch(setLmsCourseSectionDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
    } finally {
        dispatch(setLmsCourseSectionDetailLoading(false))
    }
}

export const updateLmsCourseSection = (sectionId, body, payload = { isShowToast: true }) => async (dispatch, getState) => {
    dispatch(setUpdateLmsCourseSectionLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: { sectionId: sectionId },
            body: body
        }
        const response = await lmsCourseService.updateLmsCourseSectionDetail(requestData)
        if (response.status === 200) {
            dispatch(setUpdateLmsCourseSectionData(response.data.data))
            // dispatch(getLmsCourseDetail(lmsCourseDetail?.data?.id))
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                course_sections: lmsCourseDetail?.data?.course_sections?.map((courseSection) => (
                    (courseSection?.id === sectionId) ? response.data.data : courseSection
                ))
            }))
            if (payload?.isShowToast) {
                toast.success(response.data.message)
            }
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
        dispatch(setUpdateLmsCourseSectionMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
    } finally {
        dispatch(setUpdateLmsCourseSectionLoading(false))
    }
}

export const updateOrderLmsCourseSection = (body, query) => async (dispatch, getState) => {
    dispatch(setUpdateOrderLmsCourseSectionLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            body: body,
            query: query
        }
        const response = await lmsCourseService.updateLmsCourseSectionOrderList(requestData)

        if (response.status === 200) {
            dispatch(setUpdateOrderLmsCourseSectionData(response.data.data))
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                course_sections: response.data.data
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
        dispatch(setUpdateOrderLmsCourseSectionMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
        toast.success(error.response.data.message || error.response.data.error || 'Something went wrong.')
    } finally {
        dispatch(setUpdateOrderLmsCourseSectionLoading(false))
    }
}

export const deleteLmsCourseSection = (sectionId) => async (dispatch, getState) => {
    dispatch(setDeleteLmsCourseSectionDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: { sectionId: sectionId }
        }
        const response = await lmsCourseService.deleteLmsCourseSectionDetail(requestData)
        if (response.status === 204) {
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                course_sections: lmsCourseDetail?.data?.course_sections?.filter((courseSection) => courseSection?.id !== sectionId)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
        dispatch(setDeleteLmsCourseSectionDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
    } finally {
        dispatch(setDeleteLmsCourseSectionDetailLoading(false))
    }
}

// lms course section lesson requests
export const createLmsCourseSectionLessonDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsCourseSectionLessonLoading(true))
    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            body: body
        }
        const response = await lmsCourseService.createLmsCourseSectionLessonDetail(requestData)
        if (response.status === 201 || response.status === 200) {
            // dispatch(getLmsCourseDetail(lmsCourseDetail?.data?.id))
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                course_sections: lmsCourseDetail?.data?.course_sections?.map((courseSection) => (
                    (courseSection?.id === body?.lms_course_section_id)
                        ? { ...courseSection, lms_course_section_lessons: [...courseSection?.lms_course_section_lessons, response.data.data] }
                        : courseSection
                ))
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
        dispatch(setAddLmsCourseSectionLessonMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
    } finally {
        dispatch(setAddLmsCourseSectionLessonLoading(false))
    }
}

export const getLmsCourseSectionLessonDetail = (lessonSectionId) => async (dispatch) => {
    dispatch(setLmsCourseSectionLessonLoading(true))

    try {
        const requestData = {
            params: {
                lessonSectionId: lessonSectionId
            }
        }
        const response = await lmsCourseService.getLmsCourseSectionLessonDetail(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseSectionLessonData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
        dispatch(setLmsCourseSectionLessonMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
    }

    finally {
        dispatch(setLmsCourseSectionLessonLoading(false))
    }
}

export const getLmsCourseSectionLessonList = (query) => async (dispatch) => {
    dispatch(setLmsCourseSectionLessonListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await lmsCourseService.getLmsCourseSectionLessonList(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseSectionLessonListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
        dispatch(setLmsCourseSectionLessonListMessage(error.response.data.message || error.response.data.error || 'Somthing went wrong.'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
    }
    finally {
        dispatch(setLmsCourseSectionLessonListLoading(false))
    }

}

export const updateLmsCourseSectionLessonDetail = (lessonSectionId, body) => async (dispatch) => {
    dispatch(setUpdateLmsCourseSectionLessonLoading(true))

    try {
        const requestData = {
            params: { lessonSectionId: lessonSectionId },
            body: body
        }
        const response = await lmsCourseService.updateLmsCourseSectionLessonDetail(requestData)
        if (response.status === 200) {
            dispatch(setUpdateLmsCourseSectionLessonData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong.")
        dispatch(setUpdateLmsCourseSectionLessonMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
    } finally {
        dispatch(setUpdateLmsCourseSectionLessonLoading(false))
    }
}

export const updateOrderLmsCourseSectionLessonDetail = (body, query) => async (dispatch, getState) => {
    dispatch(setUpdateOrderLmsCourseSectionLessonLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            body: body,
            query: query
        }
        const response = await lmsCourseService.updateLmsCourseSectionLessonOrderList(requestData)
        if (response.status === 200) {
            dispatch(setUpdateOrderLmsCourseSectionLessonData(response.data.data))
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                course_sections: lmsCourseDetail?.data?.course_sections?.map((courseSection) => (
                    (courseSection?.id === query?.lms_course_section_id) ? { ...courseSection, lms_course_section_lessons: response.data.data } : courseSection
                ))
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
        dispatch(setUpdateOrderLmsCourseSectionLessonMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
    }

    dispatch(setUpdateOrderLmsCourseSectionLessonLoading(false))
}

export const deleteLmsCourseSectionLessonDetail = (lessonSectionId) => async (dispatch, getState) => {
    dispatch(setDeleteLmsCourseSectionLessonLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: {
                lessonSectionId: lessonSectionId
            }
        }
        const response = await lmsCourseService.deleteLmsCourseSectionLessonDetail(requestData)
        if (response.status === 204) {
            // dispatch(getLmsCourseDetail(lmsCourseDetail?.data?.id))
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                course_sections: lmsCourseDetail?.data?.course_sections?.map((courseSection) => (
                    {
                        ...courseSection,
                        lms_course_section_lessons: courseSection?.lms_course_section_lessons?.filter((courseSectionLesson) => courseSectionLesson?.id != lessonSectionId)
                    }
                ))
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
        dispatch(setDeleteLmsCourseSectionLessonMessage(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
    } finally {
        dispatch(setDeleteLmsCourseSectionLessonLoading(false))
    }
}

// create lms course tag detail
export const createLmsCourseTagDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsCourseTagDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            body: body
        }
        const response = await lmsCourseService.createLmsCourseTagDetail(requestData)
        if (response.status === 201) {
            // dispatch(getLmsCourseDetail(body?.lms_course_id))
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                tags: [...lmsCourseDetail?.data?.tags, response.data.data]
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsCourseTagDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsCourseTagDetailLoading(false))
    }
}

// update lms course tag list order
export const updateLmsCourseTagListOrder = (body, query) => async (dispatch, getState) => {
    dispatch(setUpdateLmsCourseTagListOrderLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            body: body,
            query: query
        }
        const response = await lmsCourseService.updateLmsCourseTagListOrder(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                tags: response.data.data
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setUpdateLmsCourseTagListOrderMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setUpdateLmsCourseTagListOrderLoading(false))
    }
}

// delete lms course tag detail
export const deleteLmsCourseTag = (courseTagId) => async (dispatch, getState) => {
    dispatch(setDeleteLmsCourseTagDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: { courseTagId: courseTagId }
        }
        const response = await lmsCourseService.deleteLmsCourseTag(requestData)
        if (response.status === 204) {
            // dispatch(getLmsCourseDetail(lmsCourseDetail?.data?.id))
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                tags: lmsCourseDetail?.data?.tags?.filter((tagItem) => tagItem?.id != courseTagId)
            }))
            toast.success(response.data.message || "Delete Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDeleteLmsCourseTagDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDeleteLmsCourseTagDetailLoading(false))
    }
}

// create lms course language
export const createLmsCourseLanguageDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsCourseLanguageDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            body: body
        }
        const response = await lmsCourseService.createLmsCourseLanguageDetail(requestData)
        if (response.status === 201) {
            // dispatch(getLmsCourseDetail(body?.lms_course_id))
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                languages: [...lmsCourseDetail?.data?.languages, response.data.data]
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsCourseLanguageDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsCourseLanguageDetailLoading(false))
    }
}

// update lms course language list order
export const updateLmsCourseLanguageListOrder = (body, query) => async (dispatch, getState) => {
    dispatch(setUpdateLmsCourseLanguageListOrderLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            body: body,
            query: query
        }
        const response = await lmsCourseService.updateLmsCourseLanguageListOrder(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                languages: response.data.data
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setUpdateLmsCourseLanguageListOrderMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setUpdateLmsCourseLanguageListOrderLoading(false))
    }
}

// delete lms course language detail
export const deleteLmsCourseLanguage = (courseLanguageId) => async (dispatch, getState) => {
    dispatch(setDeleteLmsCourseLanguageDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: { courseLanguageId: courseLanguageId }
        }
        const response = await lmsCourseService.deleteLmsCourseLanguage(requestData)
        if (response.status === 204) {
            // dispatch(getLmsCourseDetail(lmsCourseDetail?.data?.id))
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                languages: lmsCourseDetail?.data?.languages?.filter((language) => language?.id != courseLanguageId)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDeleteLmsCourseLanguageDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDeleteLmsCourseLanguageDetailLoading(false))
    }
}

// create lms course prof detail
export const createLmsCourseProfDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsCourseProfDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            body: body
        }
        const response = await lmsCourseService.createLmsCourseProfDetail(requestData)
        if (response.status === 201) {
            // dispatch(getLmsCourseDetail(body?.lms_course_id))
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                proficiencies: [...lmsCourseDetail?.data?.proficiencies, response.data.data]
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsCourseProfDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsCourseProfDetailLoading(false))
    }
}

// update lms course prof list order
export const updateLmsCourseProfListOrder = (body, query) => async (dispatch, getState) => {
    dispatch(setUpdateLmsCourseProfListOrderLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            body: body,
            query: query
        }
        const response = await lmsCourseService.updateLmsCourseProfListOrder(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                proficiencies: response.data.data
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setUpdateLmsCourseProfListOrderMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setUpdateLmsCourseProfListOrderLoading(false))
    }
}

// delete lms course proficiency
export const deleteLmsCourseProf = (courseProfId) => async (dispatch, getState) => {
    dispatch(setDeleteLmsCourseProfDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: { courseProfId: courseProfId }
        }
        const response = await lmsCourseService.deleteLmsCourseProf(requestData)
        if (response.status === 204) {
            // dispatch(getLmsCourseDetail(lmsCourseDetail?.data?.id))
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                proficiencies: lmsCourseDetail?.data?.proficiencies?.filter((proficiency) => proficiency?.id != courseProfId)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDeleteLmsCourseProfDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDeleteLmsCourseProfDetailLoading(false))
    }
}

// create lms course Learning
export const createLmsCourseLearningDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsCourseLearningDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            body: body
        }
        const response = await lmsCourseService.createLmsCourseLearningDetail(requestData)
        if (response.status === 200) {
            // dispatch(getLmsCourseDetail(body?.lms_course_id))
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                what_you_will_learns: [...lmsCourseDetail?.data?.what_you_will_learns, response.data.data]
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsCourseLearningDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsCourseLearningDetailLoading(false))
    }
}

// update lms course learning
export const updateLmsCourseLearningDetail = (courseLearningId, body) => async (dispatch, getState) => {
    dispatch(setModifyLmsCourseLearningDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: { courseLearningId: courseLearningId },
            body: body
        }
        const response = await lmsCourseService.updateLmsCourseLearningDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyLmsCourseLearningDetailData(response.data.data))
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                what_you_will_learns: lmsCourseDetail?.data?.what_you_will_learns?.map((courseLearning) => (
                    (courseLearning?.id === courseLearningId) ? response.data.data : courseLearning
                ))
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyLmsCourseLearningDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyLmsCourseLearningDetailLoading(false))
    }
}

// update lms course Learning order
export const updateLmsCourseLearningListOrder = (body, query) => async (dispatch, getState) => {
    dispatch(setUpdateLmsCourseLearningListOrderLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            body: body,
            query: query
        }
        const response = await lmsCourseService.updateLmsCourseLearningListOrder(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                what_you_will_learns: response.data.data
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setUpdateLmsCourseLearningListOrderMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setUpdateLmsCourseLearningListOrderLoading(false))
    }
}

// delete lms course Learning detail
export const deleteLmsCourseLearning = (courseLearningId) => async (dispatch, getState) => {
    dispatch(setDeleteLmsCourseLearningDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: { courseLearningId: courseLearningId }
        }
        const response = await lmsCourseService.deleteLmsCourseLearning(requestData)
        if (response.status === 204) {
            // dispatch(getLmsCourseDetail(lmsCourseDetail?.data?.id))
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                what_you_will_learns: lmsCourseDetail?.data?.what_you_will_learns?.filter((learningCourse) => learningCourse?.id !== courseLearningId)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDeleteLmsCourseLearningDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDeleteLmsCourseLearningDetailLoading(false))
    }
}

// create lms course faq
export const createLmsCourseFaqDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsCourseFaqDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            body: body
        }
        const response = await lmsCourseService.createLmsCourseFaqDetail(requestData)
        if (response.status === 201) {
            // dispatch(getLmsCourseDetail(body?.lms_course_id))
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                faqs: [...lmsCourseDetail?.data?.faqs, response.data.data]
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsCourseFaqDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsCourseFaqDetailLoading(false))
    }
}

// update lms course faq
export const updateLmsCourseFaqDetail = (courseFaqId, body) => async (dispatch, getState) => {
    dispatch(setModifyLmsCourseFaqDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: { courseFaqId: courseFaqId },
            body: body
        }
        const response = await lmsCourseService.updateLmsCourseFaqDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyLmsCourseFaqDetailData(response.data.data))
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                faqs: lmsCourseDetail?.data?.faqs?.map((faq) => (
                    (faq?.id === courseFaqId) ? response.data.data : faq
                ))
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyLmsCourseFaqDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyLmsCourseFaqDetailLoading(false))
    }
}

// update lms course faq order
export const updateLmsCourseFaqListOrder = (body, query) => async (dispatch, getState) => {
    dispatch(setUpdateLmsCourseFaqListOrderLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            body: body,
            query: query
        }
        const response = await lmsCourseService.updateLmsCourseFaqListOrder(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                faqs: response.data.data
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setUpdateLmsCourseFaqListOrderMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setUpdateLmsCourseFaqListOrderLoading(false))
    }
}

// delete lms course faq detail
export const deleteLmsCourseFaq = (courseFaqId) => async (dispatch, getState) => {
    dispatch(setDeleteLmsCourseFaqDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: { courseFaqId: courseFaqId }
        }
        const response = await lmsCourseService.deleteLmsCourseFaq(requestData)
        if (response.status === 204) {
            // dispatch(getLmsCourseDetail(lmsCourseDetail?.data?.id))
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                faqs: lmsCourseDetail?.data?.faqs?.filter((faq) => faq?.id != courseFaqId)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDeleteLmsCourseFaqDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDeleteLmsCourseFaqDetailLoading(false))
    }
}

// create lms course slug detail
export const createLmsCourseSlugDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsCourseSlugDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            body: body
        }
        const response = await lmsCourseService.createLmsCourseSlugDetail(requestData)
        if (response.status === 201) {
            dispatch(updateLmsCourseDetail(body?.lms_course_id, { default_slug_id: response?.data?.data?.id }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsCourseSlugDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsCourseSlugDetailLoading(false))
    }
}

// delete lms course slug detail
export const deleteLmsCourseSlugDetail = (courseSlugId) => async (dispatch, getState) => {
    dispatch(setDestroyLmsCourseSlugDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: { courseSlugId: courseSlugId }
        }
        const response = await lmsCourseService.deleteLmsCourseSlugDetail(requestData)
        if (response.status === 204) {
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                course_slugs: lmsCourseDetail?.data?.course_slugs?.filter((courseSlug) => courseSlug?.id != courseSlugId)
            }))
            toast.success(response.data.message || "Delete Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDestroyLmsCourseSlugDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyLmsCourseSlugDetailLoading(false))
    }
}

// create lms course instructor detail
export const createLmsCourseInstructorDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsCourseInstructorDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            body: body
        }
        const response = await lmsCourseService.createLmsCourseInstructorDetail(requestData)
        if (response.status === 201) {
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                course_tutors: [...lmsCourseDetail?.data?.course_tutors, response.data.data]
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsCourseInstructorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsCourseInstructorDetailLoading(false))
    }
}

// update lms course instructor detail
export const updateLmsCourseInstructorDetail = (courseUserId, body) => async (dispatch, getState) => {
    dispatch(setModifyLmsCourseInstructorDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: { courseUserId: courseUserId },
            body: body
        }
        const response = await lmsCourseService.updateLmsCourseInstructorDetail(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                course_tutors: lmsCourseDetail?.data?.course_tutors?.map((courseTutor) => (
                    courseTutor.id === response.data.data.id ? response.data.data : courseTutor
                ))
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyLmsCourseInstructorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyLmsCourseInstructorDetailLoading(false))
    }
}
// delete lms course instructor detail
export const deleteLmsCourseInstructorDetail = (courseInstructorId) => async (dispatch, getState) => {
    dispatch(setDestroyLmsCourseInstructorDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: { courseInstructorId: courseInstructorId }
        }
        const response = await lmsCourseService.deleteLmsCourseInstructorDetail(requestData)
        if (response.status === 204) {
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                course_tutors: lmsCourseDetail?.data?.course_tutors?.filter((courseTutor) => courseTutor?.id != courseInstructorId)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.message || "Something went wrong!")
        dispatch(setDestroyLmsCourseInstructorDetailMessage(error.response.data.message || error.response.data.message || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.message || "Something went wrong!")
    } finally {
        dispatch(setDestroyLmsCourseInstructorDetailLoading(false))
    }
}


// lms course invitation list
export const getLmsCourseInvitationList = (query) => async (dispatch) => {
    dispatch(setLmsCourseInvitationListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await lmsCourseService.getLmsCourseInvitationList(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseInvitationListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        dispatch(setLmsCourseInvitationListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setLmsCourseInvitationListLoading(false))
    }
}

// create send lms course invitation detail
export const createSendLmsCourseInvitationDetail = (body) => async (dispatch) => {
    dispatch(setSendLmsCourseInvitationDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await lmsCourseService.createSendLmsCourseInvitationDetail(requestData)
        if (response.status === 201) {
            dispatch(setSendLmsCourseInvitationDetailData(response.data.data))
            toast.success(response.data.message || "Created Successfully!")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setSendLmsCourseInvitationDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    }
    finally {
        dispatch(setSendLmsCourseInvitationDetailLoading(false))
    }
}


// Validate LmsCourse Invitation detail
export const getValidateLmsCourseInvitationDetail = (query) => async(dispatch) => {
    dispatch(setValidateLmsCourseInvitationDetailLoading(true));

    try {
        const requestData = {
            query
        }
        const response = await lmsCourseService.getValidateLmsCourseInvitationDetail(requestData);
        if ( response.status === 200 ) {
            dispatch(setValidateLmsCourseInvitationDetailData(response.data.data));
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.message || 'Something went wrong!')
        dispatch(setValidateLmsCourseInvitationDetailMessage(error.response.data.message || error.response.data.message || 'Something went wrong!'))
    } finally {
        dispatch(setValidateLmsCourseInvitationDetailLoading(false));
    }
}

// Accept or Decline LmsCourse Invitation
export const getAcceptAndDeclineLmsCourseInvitationDetail = (body, query) => async( dispatch ) => {
    dispatch(setAcceptAndDeclineLmsCourseInvitationDetailLoading(true));

    try {
        const requestData = {
            body, query
        }
        const response = await lmsCourseService.getAcceptAndDeclineLmsCourseInvitationDetail(requestData);
        if (response.status === 200) {
            dispatch(setValidateLmsCourseInvitationDetailData(response.data.data));
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.message || 'Something went wrong!')
        dispatch(setAcceptAndDeclineLmsCourseInvitationDetailMessage(error.response.data.message || error.response.data.message || 'Something went wrong!'))
    }
    finally {
        dispatch(setAcceptAndDeclineLmsCourseInvitationDetailLoading(false));
    }
}


// delete lms course invitation detail
export const deleteLmsCourseInvitationDetail = (courseInvitationId, query = { page: 1, records: 5 }) => async (dispatch) => {
    dispatch(setDestroyLmsCourseInvitationDetailLoading(true))

    try {
        const requestData = {
            params: { courseInvitationId: courseInvitationId }
        }
        const response = await lmsCourseService.deleteLmsCourseInvitationDetail(requestData)
        if (response.status === 204) {
            dispatch(getLmsCourseInvitationList(query))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.message || 'Something went wrong!')
        dispatch(setDestroyLmsCourseInvitationDetailMessage(error.response.data.message || error.response.data.message || 'Something went wrong!'))
        toast.error(error.response.data.message || error.response.data.message || 'Something went wrong!')
    }
    finally {
        dispatch(setDestroyLmsCourseInvitationDetailLoading(false))
    }
}

// student lms course request
export const getStudentLmsCourseList = (studentId, query) => async (dispatch) => {
    dispatch(setStudentLmsCourseListLoading(true))

    try {
        const requestData = {
            params: {
                studentId: studentId
            },
            query: query
        }
        const response = await lmsCourseService.getStudentLmsCourseList(requestData)
        if (response.status === 200) {
            dispatch(setStudentLmsCourseListData(response?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setStudentLmsCourseListMessage(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setStudentLmsCourseListLoading(false))
    }
}

// student lms course fav list
export const getStudentFavLmsCourseList = (studentId, query) => async (dispatch) => {
    dispatch(setStudentFavLmsCourseListLoading(true))

    try {
        const requestData = {
            params: { studentId: studentId },
            query: query
        }
        const response = await lmsCourseService.getStudentFavLmsCourseList(requestData)
        if (response.status === 200) {
            dispatch(setStudentFavLmsCourseListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setStudentFavLmsCourseListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setStudentFavLmsCourseListLoading(false))
    }
}

// add student lms course fav detail
export const createStudentFavLmsCourseDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddStudentFavLmsCourseDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            body: body
        }
        const response = await lmsCourseService.createStudentFavLmsCourseDetail(requestData)
        if (response.status === 201) {
            dispatch(setUser({
                ...user?.user,
                favoriteLmsCourses: [...user?.user?.favoriteLmsCourses, { lmsCourseId: body?.lms_course_id }]
            }))
            toast.success(response.data.message)
        } else if (response.status === 204) {
            dispatch(setUser({
                ...user?.user,
                favoriteLmsCourses: user?.user?.favoriteLmsCourses?.filter((lmsCourse) => lmsCourse?.lmsCourseId != body?.lms_course_id)
            }))
            toast.success(response.data.message || "Removed Successfully")
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddStudentFavLmsCourseDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddStudentFavLmsCourseDetailLoading(false))
    }
}

export const getStudentLmsCourseDetail = (lmsCourseSlug, studentId) => async (dispatch) => {
    dispatch(setStudentLmsCourseDetailLoading(true))

    try {
        const requestData = {
            params: {
                slug: lmsCourseSlug,
                studentId: studentId
            }
        }
        const response = await lmsCourseService.getStudentLmsCourseDetail(requestData)
        if (response.status === 200) {
            dispatch(setStudentLmsCourseDetailData(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setStudentLmsCourseDetailMessage(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setStudentLmsCourseDetailLoading(false))
    }
}

// get lms course student list
export const getLmsCourseStudentList = (query) => async (dispatch) => {
    dispatch(setLmsCourseStudentListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await lmsCourseService.getLmsCourseStudentList(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseStudentListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setLmsCourseStudentListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setLmsCourseStudentListLoading(false))
    }
}

// create lms course student detail
export const createLmsCourseStudentDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsCourseStudentDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await lmsCourseService.createLmsCourseStudentDetail(requestData)
        if (response.status === 201) {
            dispatch(getLmsCourseDetail(body?.lms_course_id))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsCourseStudentDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsCourseStudentDetailLoading(false))
    }
}

// update lms course student detail
export const updateLmsCourseStudentDetail = (courseStudentId, body) => async (dispatch, getState) => {
    dispatch(setModifyLmsCourseStudentDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: { courseStudentId: courseStudentId },
            body: body
        }
        const response = await lmsCourseService.updateLmsCourseStudentDetail(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                students: lmsCourseDetail?.data?.students?.map((courseStudent) => (
                    courseStudent.id === response.data.data.id ? response.data.data : courseStudent
                ))
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyLmsCourseStudentDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyLmsCourseStudentDetailLoading(false))
    }
}

// delete lms course student detail
export const deleteLmsCourseStudentDetail = (courseStudentId) => async (dispatch, getState) => {
    dispatch(setDestroyLmsCourseStudentDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: { courseStudentId: courseStudentId }
        }
        const response = await lmsCourseService.deleteLmsCourseStudentDetail(requestData)
        if (response.status === 204) {
            dispatch(getLmsCourseDetail(lmsCourseDetail?.data?.id))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDestroyLmsCourseStudentDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyLmsCourseStudentDetailLoading(false))
    }
}

/* lms course student section lesson */
export const createDeleteLmsCourseStudentSectionLesson = (body) => async (dispatch, getState) => {
    dispatch(setAddDestroyLmsCourseStudentSectionLessonLoading(true))

    try {
        const { studentLmsCourseDetail: { data } } = getState().lms.lmsCourse
        const requestData = {
            body: body
        }
        const response = await lmsCourseService.createDeleteLmsCourseStudentSectionLesson(requestData)
        if (response.status === 201) {
            dispatch(setStudentLmsCourseDetailData({
                ...data,
                lms_course_student: {
                    ...data?.lms_course_student,
                    lms_course_student_section_lessons: [
                        ...data?.lms_course_student?.lms_course_student_section_lessons,
                        response.data.data
                    ]
                }
            }))
            toast.success("Marked Completed" || response.data.message || "Something went wrong!")
        } else if (response.status === 200) {
            const filteredList = data?.lms_course_student?.lms_course_student_section_lessons?.filter(section_lesson => !(
                section_lesson?.lms_course_student === body?.lms_course_student_id &&
                section_lesson?.lms_course_section_lesson?.id === body?.lms_course_section_lesson_id
            ))
            dispatch(setStudentLmsCourseDetailData({
                ...data,
                lms_course_student: {
                    ...data?.lms_course_student,
                    lms_course_student_section_lessons: filteredList
                }
            }))
            toast.success("Marked Uncompleted" || response.data.message || "Something went wrong!")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddDestroyLmsCourseStudentSectionLessonMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddDestroyLmsCourseStudentSectionLessonLoading(false))
    }
}

/* lms course student quiz response */
export const createLmsCourseStudentQuizResponseDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsCourseStudentQuizResponseDetailLoading(true))
    try {
        const { studentLmsCourseDetail: { data } } = getState().lms.lmsCourse
        const requestData = {
            body: body
        }
        const response = await lmsCourseService.createLmsCourseStudentQuizResponseDetail(requestData)
        if (response.status === 201) {
            const filteredList = data?.lms_course_student?.lms_course_student_quiz_responses?.filter(quiz_response => quiz_response?.id !== response.data.data.id)
            dispatch(setStudentLmsCourseDetailData({
                ...data,
                lms_course_student: {
                    ...data?.lms_course_student,
                    lms_course_student_quiz_responses: [...filteredList, { ...response.data.data, quiz: response.data.data?.quiz, quiz_response: response.data.data?.quiz_response }]
                }
            }))
            dispatch(setAddLmsCourseStudentQuizResponseDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddLmsCourseStudentQuizResponseDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setAddLmsCourseStudentQuizResponseDetailLoading(false))
    }
}

export const updateLmsCourseStudentQuizResponseDetail = (id, body) => async (dispatch, getState) => {
    dispatch(setModifyLmsCourseStudentQuizResponseDetailLoading(true))
    try {
        const { studentLmsCourseDetail: { data } } = getState().lms.lmsCourse
        const requestData = {
            params: { id: id },
            body: body
        }
        const response = await lmsCourseService.updateLmsCourseStudentQuizResponseDetail(requestData)
        if (response.status === 200) {
            const filteredList = data?.lms_course_student?.lms_course_student_quiz_responses?.filter(quiz_response => quiz_response?.id !== response.data.data.id)
            dispatch(setStudentLmsCourseDetailData({
                ...data,
                lms_course_student: {
                    ...data?.lms_course_student,
                    lms_course_student_quiz_responses: [...filteredList, { ...response.data.data, quiz: response.data.data?.quiz, quiz_response: response.data.data?.quiz_response }]
                }
            }))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyLmsCourseStudentQuizResponseDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyLmsCourseStudentQuizResponseDetailLoading(false))
    }
}

/* lms course student note request */
export const createLmsCourseStudentNote = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsCourseStudentNoteDetailLoading(true))

    try {
        const { studentLmsCourseDetail: { data } } = getState().lms.lmsCourse
        const requestData = {
            body: body
        }
        const response = await lmsCourseService.createLmsCourseStudentNoteDetail(requestData)
        if (response.status === 201 || response.status === 200) {
            const filteredList = data?.lms_course_student?.lms_course_student_notes?.filter(lms_course_student_note => lms_course_student_note?.id !== response?.data?.data?.id)
            dispatch(setStudentLmsCourseDetailData({
                ...data,
                lms_course_student: {
                    ...data?.lms_course_student,
                    lms_course_student_notes: [response?.data?.data, ...filteredList]
                }
            }))
            dispatch(setAddLmsCourseStudentNoteDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
        dispatch(setAddLmsCourseStudentNoteDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
    } finally {
        dispatch(setAddLmsCourseStudentNoteDetailLoading(false))
    }
}

export const getLmsCourseStudentNoteList = (query) => async (dispatch) => {
    dispatch(setLmsCourseStudentNoteListLoading(true))

    try {
        const requestData = {
            query: query
        }

        const response = await lmsCourseService.getLmsCourseStudentNoteList(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseStudentNoteListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsCourseStudentNoteListMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
    } finally {
        dispatch(setLmsCourseStudentNoteListLoading(false))
    }
}

export const getLmsCourseStudentNoteDetail = (id) => async (dispatch) => {
    dispatch(setLmsCourseStudentNoteDetailLoading(true))

    try {
        const requestData = {
            params: { id: id }
        }
        const response = await lmsCourseService.getLmsCourseStudentNoteDetail(requestData)
        if (response.status === 200) {
            dispatch(setLmsCourseStudentNoteDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsCourseStudentNoteDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
    } finally {
        dispatch(setLmsCourseStudentNoteDetailLoading(false))
    }
}

export const updateLmsCourseStudentNote = (id, body) => async (dispatch, getState) => {
    dispatch(setModifyLmsCourseStudentNoteDetailLoading(true))

    try {
        const { studentLmsCourseDetail: { data } } = getState().lms.lmsCourse
        const requestData = {
            params: { id: id },
            body: body
        }
        const response = await lmsCourseService.updateLmsCourseStudentNoteDetail(requestData)
        if (response.status === 200) {
            const filteredList = data?.lms_course_student?.lms_course_student_notes?.map(lms_course_student_note => lms_course_student_note?.id === id ? response?.data?.data : lms_course_student_note)
            dispatch(setStudentLmsCourseDetailData({
                ...data,
                lms_course_student: {
                    ...data?.lms_course_student,
                    lms_course_student_notes: [...filteredList]
                }
            }))
            dispatch(setModifyLmsCourseStudentNoteDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
        dispatch(setModifyLmsCourseStudentNoteDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
    } finally {
        dispatch(setModifyLmsCourseStudentNoteDetailLoading(false))
    }
}

export const deleteLmsCourseStudentNote = (id) => async (dispatch, getState) => {
    dispatch(setDestroyLmsCourseStudentNoteDetailLoading(true))

    try {
        const { studentLmsCourseDetail: { data } } = getState().lms.lmsCourse
        const requestData = {
            params: { id: id }
        }
        const response = await lmsCourseService.deleteLmsCourseStudentNoteDetail(requestData)
        if (response.status === 204) {
            const filteredList = data?.lms_course_student?.lms_course_student_notes?.filter(lms_course_student_note => lms_course_student_note?.id !== id)
            dispatch(setStudentLmsCourseDetailData({
                ...data,
                lms_course_student: {
                    ...data?.lms_course_student,
                    lms_course_student_notes: [...filteredList]
                }
            }))
            toast.success(response.data.message || "Deleted successfully" || "Something went wrong!")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
        dispatch(setDestroyLmsCourseStudentNoteDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
    } finally {
        dispatch(setDestroyLmsCourseStudentNoteDetailLoading(false))
    }
}

// course page based rquest
// lms course lecture list
export const getLmsCoursePageLectureList = (query) => async (dispatch) => {
    dispatch(setLmsCoursePageLectureListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await lmsLectureService.getTutorLmsLectureList(requestData)
        if (response.status === 200) {
            dispatch(setLmsCoursePageLectureListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setLmsCoursePageLectureListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setLmsCoursePageLectureListLoading(false))
    }
}

// lms course article list
export const getLmsCoursePageArticleList = (query) => async (dispatch) => {
    dispatch(setLmsCoursePageArticleListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await lmsArticleService.getLmsArticleList(requestData)
        if (response.status === 200) {
            dispatch(setLmsCoursePageArticleListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setLmsCoursePageArticleListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setLmsCoursePageArticleListLoading(false))
    }
}

// lms course quiz list
export const getLmsCoursePageQuizList = (query) => async (dispatch) => {
    dispatch(setLmsCoursePageQuizListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await lmsQuizService.getLmsQuizList(requestData)
        if (response.status === 200) {
            dispatch(setLmsCoursePageQuizListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setLmsCoursePageQuizListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setLmsCoursePageQuizListLoading(false))
    }
}