import { useState, useEffect, Fragment } from "react";
import { cn } from "utils/cn.utils";
import { Dialog, Transition } from "@headlessui/react";

import { BsCheckLg } from "react-icons/bs";

const CourseTypeDropdown = ({
    options,
    selectedOption,
    isShowList,
    setIsShowList,
    className,
    onApplyFilter,
    onClearFilter
}) => {

    const [selectedOptionList, setSelectedOptionList] = useState(selectedOption)

    useEffect(() => {
        if (selectedOption?.length > 0) {
            setSelectedOptionList(selectedOption)
        } else {
            setSelectedOptionList([])
        }
    }, [selectedOption])

    const handleOptionSelection = (courseType) => {
        const selected = options?.find((o) => o.value === courseType?.value)

        if (!selected?.value) {
            alert("Please select any!")
            return;
        }

        const filteredList = selectedOptionList?.filter((option) => option?.value === selected?.value)
        if (filteredList?.length > 0) {
            const filteredOptions = selectedOptionList?.filter((option) => option?.value !== selected?.value)
            setSelectedOptionList(filteredOptions);
            return;
        }
        if (filteredList?.length === 0) {
            setSelectedOptionList([...selectedOptionList, selected]);
        }
    }

    return (
        <Transition appear show={isShowList} as={Fragment}>
            <Dialog
                as="div"
                className={cn("absolute z-30 overflow-y-auto", className)}
                open={isShowList}
                onClose={setIsShowList}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className={"bg-white w-full max-h-96 overflow-y-auto rounded-lg px-3 py-1.5"}>
                        <div className={"w-full space-y-5 px-3 py-1.5"}>
                            <span className={"font-bodyPri font-medium text-text-900 text-base"}>
                                {"Class Types"}
                            </span>
                            <div className={"w-full flex flex-col items-start justify-start gap-3"}>
                                {options?.map((courseType) => (
                                    <div className={"w-full flex items-start justify-start gap-3 cursor-pointer group"} onClick={() => handleOptionSelection(courseType)}>
                                        <div className={"relative w-5 h-5 border border-text-300 rounded"}>
                                            {(selectedOptionList?.map((option) => option?.value)?.includes(courseType?.value)) &&
                                                <span className={"absolute top-0.5 -right-0"}>
                                                    <BsCheckLg className={"text-base text-primary-dark"} />
                                                </span>
                                            }
                                        </div>
                                        <div className={"w-11/12 flex flex-col items-start justify-start gap-1"}>
                                            <div className={"font-bodyPri font-semibold text-text-900 text-base"}>
                                                {courseType?.label}
                                            </div>
                                            <div className={"font-bodyPri font-normal text-text-700 text-sm"}>
                                                {courseType?.description}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={"w-full flex items-center justify-between gap-3 py-2"}>
                                <span
                                    className={"font-bodyPri font-medium text-primary-dark text-sm underline cursor-pointer"}
                                    onClick={() => {
                                        setSelectedOptionList([])
                                        onClearFilter()
                                    }}>
                                    {"Clear"}
                                </span>
                                <div className={cn(
                                    "px-4 py-1 rounded-full border border-primary-dark cursor-pointer",
                                    "font-bodyPri font-medium text-primary-dark text-sm tracking-wide hover:bg-primary-dark hover:text-text-50"
                                )}
                                    onClick={() => onApplyFilter(selectedOptionList)}
                                >
                                    {"Apply Filters"}
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition>
    )
}

export default CourseTypeDropdown