import {
    Container,
    Content,
} from './TeachWithUsFeaturedStyle';
import { featuredData } from './TeachWithUsFeaturedData'

import FeaturedItem from './FeaturedItem';

const TeachWithUsFeatured = () => {
    return (
        <Container>
            <Content>
                {featuredData.map((featuredItem, index) => (
                    <FeaturedItem featuredItem={featuredItem} key={index} />
                ))}
            </Content>
        </Container>
    )
}

export default TeachWithUsFeatured;
