import { useCallback, memo } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { FaPlus } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';

import { cn } from 'utils/cn.utils';

const AdditionalInformation = memo(({ locationPayload, setLocationPayload }) => {

    const onHandleAdditionalInformation = useCallback(() => {
        setLocationPayload({
            ...locationPayload,
            isAdditionInfoEnabled: true
        })
    }, [locationPayload])

    const onHandleBlurAdditionInfo = useCallback(() => {
        setLocationPayload({
            ...locationPayload,
            isAdditionInfoEnabled: false
        })
    }, [locationPayload])

    const onHandleChangeText = useCallback((event) => {
        setLocationPayload({
            ...locationPayload,
            info: event?.target?.value
        })
    }, [locationPayload])

    return (
        <AnimatePresence initial={locationPayload?.isAdditionInfoEnabled}>
            {!locationPayload?.isAdditionInfoEnabled &&
                <>
                    {locationPayload?.info &&
                    <div className={"w-full flex items-center justify-between gap-3 cursor-pointer group"} onClick={onHandleAdditionalInformation}>
                            <span className={"font-bodyPri font-normal text-primary-main text-base tracking-wide"}>
                                {locationPayload?.info}
                            </span>
                            <MdEdit className={"hidden group-hover:flex text-xl text-text-800"} />
                        </div>
                    }
                    {!locationPayload?.info &&
                        <div className={"w-fit flex items-center justify-start gap-1.5 group cursor-pointer"} onClick={onHandleAdditionalInformation}>
                            <FaPlus className={"text-base text-primary-main"} />
                            <span className={"font-bodyPri font-normal text-primary-main text-base tracking-wide group-hover:underline"}>
                                {"Include additional information"}
                            </span>
                        </div>
                    }
                </>
            }
            {locationPayload?.isAdditionInfoEnabled &&
                <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                    className={"w-full"}
                >
                <textarea
                    className={cn(
                        "w-full px-5 py-3 bg-background-light rounded-lg resize-none",
                        "outline-none border-2 border-primary-main focus:shadow-all-md focus:shadow-primary-light focus:border-primary-main",
                        "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                    )}
                    maxLength={100}
                    rows={4}
                    name={"description"}
                    autoFocus={true}
                    placeholder={"Write your text here ...."}
                    value={locationPayload?.info}
                    onChange={onHandleChangeText}
                    onBlur={onHandleBlurAdditionInfo}
                />
                </motion.div>
            }
        </AnimatePresence>
    )
})

export default AdditionalInformation;