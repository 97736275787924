import { useState, useEffect } from "react";

// Local Components
import { useAppState } from "hooks/useStore";

const TeachingStyle = () => {
    const { tutorPublicProfile } = useAppState((s) => s.tutor)

    const [isReadMore, setIsReadMore] = useState(false);

    useEffect(() => {
        if (tutorPublicProfile?.tutorPublicProfile?.bio?.length > 300) {
            setIsReadMore(true)
        }
    }, [tutorPublicProfile?.tutorPublicProfile?.bio])

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return tutorPublicProfile?.tutorPublicProfile && (
        <div className={"p-2.5 md:p-5 space-y-3 shadow bg-white flex flex-col"} id={"TEACHING_STYLE"}>
            <span className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
                {"My Teaching Style"}
            </span>
            {tutorPublicProfile?.tutorPublicProfile?.teachingStyleBio?.length > 0 &&
                <p className="text-lg text-text-700 px-3 py-0.5 md:py-1">
                    {isReadMore
                        ? tutorPublicProfile?.tutorPublicProfile?.teachingStyleBio?.slice(0, 300)
                        : tutorPublicProfile?.tutorPublicProfile?.teachingStyleBio
                    }
                    <span
                        onClick={toggleReadMore}
                        className="read-or-hide text-blue-500 cursor-pointer"
                    >
                        {isReadMore ?
                            "... Read more"
                            : tutorPublicProfile?.tutorPublicProfile?.teachingStyleBio?.length > 300
                                ? " Show less"
                                : ""
                        }
                    </span>
                </p>
            }
            {(!tutorPublicProfile?.tutorPublicProfile?.teachingStyleBio || tutorPublicProfile?.tutorPublicProfile?.teachingStyleBio?.length === 0) &&
                <span className="font-bodyPri font-normal text-text-800 text-lg px-3 py-0.5 md:py-1">
                    {"Creating my personalised teaching style. Stay tuned!"}
                </span>
            }
        </div>
    );
}

export default TeachingStyle