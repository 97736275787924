import { useEffect } from 'react'
import { cn } from "utils/cn.utils";

const AuthTabs = ({
    tabs,
    activeTab,
    handleTabs,
    startIndex = 0,
    lastIndex = tabs.length,
}) => {

    useEffect(() => {
        handleTabs(activeTab)
    }, [activeTab])

    return (
        <div className="p-4 bg-white rounded-lg">
            <div className="flex flex-col sm:flex-row items-center justify-between gap-3">
                <div className="rounded-md overflow-hidden ">
                    {tabs.slice(startIndex, lastIndex).map((tab, idx) => (
                        <button
                            key={idx}
                            className={cn(
                                "py-2 w-32 font-bodyPri font-medium text-base focus:outline-none",
                                (activeTab.value === tab.value)
                                    ? "bg-primary-light text-primary-dark"
                                    : "text-text-900 hover:bg-text-100"
                            )}
                            onClick={() => handleTabs(tab)}
                        >
                            {tab.label}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AuthTabs;