import { Fragment, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { cn } from "utils/cn.utils";

import GridLoader from "components/loader/GridLoader";

const ScreenLoaderOverlayModal = ({
    openModal = false,
    onCloseModal,
    maxWidth = "max-w-2xl",
    bgColor = "",
}) => {

    const closeModal = useCallback(() => {
        onCloseModal()
    }, [openModal])

    return (
        <Transition appear show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className={"fixed max-h-screen inset-0 z-50 overflow-y-auto"}
                open={openModal}
                onClose={closeModal}
            >
                <div className="p-5 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span aria-hidden="true" className="inline-block h-screen align-middle">&#8203;</span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={cn(
                            "inline-block w-full overflow-hidden",
                            `text-left align-middle transition-all transform ${maxWidth} ${bgColor}`
                        )}>
                            <Dialog.Description as="div">
                                <GridLoader isLoading={true} />
                            </Dialog.Description>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

export default ScreenLoaderOverlayModal;