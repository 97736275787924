import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import ButtonLoader from "components/loader/ButtonLoader";
import InputOtp from "components/inputOtp/InputOtp";

import { updateUserAuthByUserId } from "redux/auth/auth.request";
import { createSendOtp, createVerifyOtp } from 'redux/notification/notification.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setClearAddUserAuth } from "redux/auth/auth.slice";
import { setClearUserGeneralDetailPayload } from "redux/user/user.slice";
import { setClearVerifyOtp, setClearSendOtp } from 'redux/notification/notification.slice';
import { modalConst } from "redux/local/local.const";
import { otpDeliveryMethod, otpStatus } from "redux/notification/notification.const";

import { validateEmail } from "utils/validation-functions";

const UpdateEmailModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { addUserAuth } = useAppState((state) => state.auth)
    const { user } = useAppState((state) => state.user)
    const { sendOtp, verifyOtp } = useAppState((s) => s.notification)

    const dispatcher = useAppDispatcher()

    const [myOtp, setMyOtp] = useState("")
    const [isOtpError, setIsOtpError] = useState(false)
    const [isCodeSent, setIsCodeSent] = useState(false)
    const [emailAddress, setEmailAddress] = useState({
        newEmailAddress: "",
        confirmNewEmailAddress: ""
    })
    const [isInputEmailError, setIsInputEmailError] = useState({
        isAlreadyExistEmail: false,
        isInvalidEmail: false,
        isEmailSimilar: true
    })

    useEffect(() => {
        if (emailAddress?.newEmailAddress && !validateEmail(emailAddress?.newEmailAddress)) {
            setIsInputEmailError({
                ...isInputEmailError,
                isInvalidEmail: true
            })
        }
        if (emailAddress?.newEmailAddress && validateEmail(emailAddress?.newEmailAddress)) {
            setIsInputEmailError({
                ...isInputEmailError,
                isInvalidEmail: false
            })
        }
        if (emailAddress?.newEmailAddress && emailAddress?.newEmailAddress === user?.user?.email) {
            setIsInputEmailError({
                ...isInputEmailError,
                isAlreadyExistEmail: true,
            })
        }
        if (emailAddress?.newEmailAddress && emailAddress?.confirmNewEmailAddress && emailAddress?.newEmailAddress !== emailAddress?.confirmNewEmailAddress) {
            setIsInputEmailError({
                ...isInputEmailError,
                isEmailSimilar: false
            })
        }

        if (validateEmail(emailAddress?.newEmailAddress) && emailAddress?.newEmailAddress !== user?.user?.email && emailAddress?.newEmailAddress === emailAddress?.confirmNewEmailAddress) {
            setIsInputEmailError({
                ...isInputEmailError,
                isAlreadyExistEmail: false,
                isInvalidEmail: false,
                isEmailSimilar: true
            })
        }
    }, [emailAddress])

    useEffect(() => {
        if (sendOtp?.sendOtp) {
            setIsCodeSent(true)
        }
        if (sendOtp?.errorMsg) {
            setIsCodeSent(false)
        }
    }, [sendOtp?.sendOtp])

    useEffect(() => {
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            dispatcher(setClearVerifyOtp())
            dispatcher(setClearSendOtp())
            dispatcher(updateUserAuthByUserId(
                user?.user?.userId,
                {
                    email: emailAddress?.newEmailAddress
                }
            ))
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.PENDING.value) {
            setIsCodeSent(false)
            toast.warning("Something went wrong")
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.EXPIRED.value) {
            setIsCodeSent(false)
            toast.error("Code Expired")
            return;
        }
        if (!verifyOtp?.verifyOtp && verifyOtp?.errorMsg) {
            setIsCodeSent(true)
            setIsOtpError(true)
        }
    }, [verifyOtp?.verifyOtp || verifyOtp?.errorMsg])

    useEffect(() => {
        if (addUserAuth?.addUserAuth) {
            dispatcher(setModal({
                ...modal,
                [modalConst.UPDATE_EMAIL_MODAL.stateKey]: false
            }))
            setIsCodeSent(false)
        }

        return () => {
            dispatcher(setClearAddUserAuth())
            dispatcher(setClearUserGeneralDetailPayload())
        }
    }, [addUserAuth?.addUserAuth])

    const onSendCode = () => {
        if (!emailAddress?.newEmailAddress || !emailAddress?.confirmNewEmailAddress || isInputEmailError?.isAlreadyExistEmail || isInputEmailError?.isInvalidEmail || !isInputEmailError?.isEmailSimilar) {
            toast.warn("Incorrect email!")
            return;
        }

        // dispatcher(setAddUserAuthPayload({
        //     ...addUserAuth?.addUserAuthPayload,
        //     email: emailAddress?.newEmailAddress
        // }))
        dispatcher(createSendOtp({
            channel: otpDeliveryMethod?.EMAIL?.value,
            email: emailAddress?.newEmailAddress
        }))
    }


    const handleVerifyCode = () => {
        if (myOtp?.length < 6) return;
        dispatcher(setClearVerifyOtp())
        if (sendOtp?.sendOtp?.otpId) {
            dispatcher(createVerifyOtp({
                otpId: sendOtp?.sendOtp?.otpId,
                otp: myOtp
            }))
        }
    }


    if (isCodeSent) {
        return (
            <div className={"flex flex-col items-center justify-center gap-3"}>
                <div className={"flex items-center justify-center gap-3"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-sm"}>
                        {"Email: "}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                        {emailAddress?.newEmailAddress}
                    </span>
                </div>
                <InputOtp
                    email={emailAddress?.newEmailAddress}
                    setMyOtp={setMyOtp}
                    otpLength={6}
                    isOtpError={isOtpError}
                    setIsOtpError={setIsOtpError}
                />
                {(verifyOtp?.errorMsg || addUserAuth?.errorMsg) &&
                    <span className={"font-bodyPri font-medium text-red-500 text-sm"}>
                        {verifyOtp?.errorMsg || addUserAuth?.errorMsg}
                    </span>
                }
                <div
                    className={cn(
                        "w-full flex items-center justify-center space-x-5 py-1.5 rounded-lg",
                        "text-text-50 font-buttons font-medium text-base text-center",
                        myOtp?.length < 6 && "bg-divider-medium",
                        myOtp?.length === 6 && "bg-primary-main hover:bg-secondary-dark cursor-pointer"
                    )}
                    onClick={handleVerifyCode}
                >
                    {(verifyOtp?.isLoading || addUserAuth?.isLoading) &&
                        <ButtonLoader isLoading={verifyOtp?.isLoading || addUserAuth?.isLoading} />
                    }
                    {(!verifyOtp?.isLoading && !addUserAuth?.isLoading) && "Verify Code"}
                </div>
            </div>
        )
    }

    return (
        <div className={"space-y-5 overflow-hidden"}>
            <div className={cn(
                "w-full min-h-[16rem] flex flex-col justify-start items-start gap-8",
            )}
            >
                <div className={"w-full flex flex-col justify-start items-start gap-5"}>
                    {user?.user?.email &&
                        <div className={"flex items-center justify-center gap-2"}>
                            <div className={"font-bodyPri font-normal text-text-600 text-base"}>
                                {"Email:"}
                            </div>
                            <div className={"font-bodyPri font-normal text-text-900 text-base"}>
                                {user?.user?.email}
                            </div>
                        </div>
                    }
                    <div className={"w-full flex flex-col items-start justify-start gap-3"}>
                        <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                            <div className={"font-bodyPri font-normal text-text-600 text-base"}>
                                {"New Email:"}
                            </div>
                            <div className={"w-full flex flex-col items-start justify-center gap-1"}>
                                <input
                                    type={"text"}
                                    className={cn(
                                        "w-full px-3 py-1 rounded-md focus:outline-none",
                                        "font-bodyPri font-normal text-text-800 text-base",
                                        (isInputEmailError?.isInvalidEmail || isInputEmailError?.isAlreadyExistEmail)
                                        && "border-2 border-red-500",
                                        (!isInputEmailError?.isInvalidEmail || !isInputEmailError?.isAlreadyExistEmail)
                                        && "border-2 border-divider-medium focus:border-2 focus:border-text-400 hover:border-text-400",
                                        (emailAddress?.newEmailAddress && emailAddress?.confirmNewEmailAddress && emailAddress?.newEmailAddress === emailAddress?.confirmNewEmailAddress)
                                        && "border-2 border-green-500"
                                    )}
                                    value={emailAddress?.newEmailAddress}
                                    onChange={(event) => setEmailAddress({
                                        ...emailAddress,
                                        newEmailAddress: event?.target?.value
                                    })}
                                />
                                <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                    {isInputEmailError?.isInvalidEmail &&
                                        "Invalid Email!"
                                    }
                                </span>
                                <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                    {isInputEmailError?.isAlreadyExistEmail &&
                                        "An account already exist for this email"
                                    }
                                </span>
                            </div>
                        </div>
                        <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                            <div className={"font-bodyPri font-normal text-text-600 text-base"}>
                                {"Confirm New Email:"}
                            </div>
                            <div className={"w-full flex flex-col items-start justify-center gap-1"}>
                                <input
                                    type={"text"}
                                    className={cn(
                                        "w-full px-3 py-1 rounded-md focus:outline-none",
                                        "font-bodyPri font-normal text-text-800 text-base",
                                        (isInputEmailError?.isInvalidEmail || isInputEmailError?.isAlreadyExistEmail)
                                        && "border-2 border-red-500",
                                        (!isInputEmailError?.isInvalidEmail || !isInputEmailError?.isAlreadyExistEmail)
                                        && "border-2 border-divider-medium focus:border-2 focus:border-text-400 hover:border-text-400",
                                        (emailAddress?.newEmailAddress && emailAddress?.confirmNewEmailAddress && emailAddress?.newEmailAddress === emailAddress?.confirmNewEmailAddress)
                                        && "border-2 border-green-500"
                                    )}
                                    value={emailAddress?.confirmNewEmailAddress}
                                    onChange={(event) => setEmailAddress({
                                        ...emailAddress,
                                        confirmNewEmailAddress: event?.target?.value
                                    })}
                                />
                                {!isInputEmailError?.isEmailSimilar &&
                                    <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                        {"The email you entered do not match!"}
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={onSendCode}
                    className={cn(
                        "w-full py-1 flex item-center justify-center rounded-md",
                        "font-buttons font-normal text-base text-text-50",
                        (isInputEmailError?.isInvalidEmail || isInputEmailError?.isAlreadyExistEmail)
                        && "bg-text-300",
                        ((!isInputEmailError?.isInvalidEmail && !isInputEmailError?.isAlreadyExistEmail) || sendOtp?.isLoading)
                        && "bg-primary-main cursor-pointer"
                    )}>
                    <span className={""}>
                        {sendOtp?.isLoading &&
                            <ButtonLoader isLoading={sendOtp?.isLoading} />
                        }
                        {!sendOtp?.isLoading && "Send Code"}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default UpdateEmailModal;