import { useState, Fragment } from "react";
import { cn } from "utils/cn.utils";
import { Dialog, Transition } from "@headlessui/react";

import { FaCheck } from "react-icons/fa";

const TutorSortByDropdown = ({
    options,
    selected,
    onApplyFilter,
    onClearFilter,
    isShowList,
    setIsShowList,
    className
}) => {
    const [selectedOption, setSelectedOption] = useState(selected)

    const handleSelectOption = (option) => {
        const selected = options?.find((o) => o.value === option.value)
        if (!selected?.value) {
            alert("Please select any!")
            return;
        }
        setSelectedOption(selected);
    }
    return (
        <Transition appear show={isShowList} as={Fragment}>
            <Dialog
                as="div"
                className={cn("absolute z-30 overflow-y-auto", className)}
                open={isShowList}
                onClose={setIsShowList}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className={cn("bg-white w-full max-h-80 rounded-lg px-3 py-1.5")}>
                        <div className={"space-y-3 py-1"}>
                            {isShowList && options?.map((item, index) => (
                                <div
                                    key={index}
                                    className={"w-full flex items-center justify-start gap-5 group cursor-pointer"}
                                    onClick={() => handleSelectOption(item)}
                                >
                                    <div className={`w-4 h-4`}>
                                        {(selectedOption?.value === item?.value) &&
                                            <FaCheck className={"text-sm text-primary-dark"} />
                                        }
                                    </div>
                                    <span className={'w-11/12 font-bodyPri font-normal text-text-900 text-sm tracking-wide truncate'}>
                                        {item.label}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className={"w-full flex items-center justify-between gap-3 py-2"}>
                            <span
                                className={"font-bodyPri font-medium text-primary-dark text-sm underline cursor-pointer"}
                                onClick={() => onClearFilter()}>
                                {"Clear"}
                            </span>
                            <div className={cn(
                                "px-4 py-1 rounded-full border border-primary-dark cursor-pointer",
                                "font-bodyPri font-medium text-primary-dark text-sm tracking-wide hover:bg-primary-dark hover:text-text-50"
                            )}
                                onClick={() => {
                                    onApplyFilter(selectedOption)
                                    setIsShowList(false)
                                }}
                            >
                                {"Apply Filters"}
                            </div>
                        </div>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition>
    )
}

export default TutorSortByDropdown