import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import ComponentLoader from "components/loader/ComponentLoader";

import { cn } from "utils/cn.utils";

type Props = {
  className?: string;
  isLoading?: boolean;
  headers: string[];
  rows: any[];
  title?: string;
  message?: string;
  rowHeaderColor?: string;
  alternateRowColor?: string;
  pageSize?: number;
  onPageChange?: (pageNumber: number) => void;
  activePageNumber?: number;
};

export default function TableComponent({
  isLoading = false,
  headers = [],
  rows = [],
  className = "",
  title = "",
  message= "",
  alternateRowColor = "bg-gray-100",
  rowHeaderColor = "bg-gray-100",
  onPageChange = () => { },
  pageSize = 0,
  activePageNumber = 0,
}: Props) {
  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= pageSize; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };
  return (
    <div className={"flex flex-col"}>
      <div className={"-my-2 overflow-x-auto scrollbar-thin sm:-mx-6 lg:-mx-8"}>
        <div className={"inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 overflow-x-auto scrollbar-thin"}>
          <div className={"min-w-full lg:w-[48rem] border-b border-gray-200 rounded-lg shadow"}>
            {title && (
              <p className="px-6 pt-6 font-bold tracking-wider text-left text-slate-700">
                {title}
              </p>
            )}
            {/* table */}
            <table className="w-full divide-y divide-gray-200">
              <thead className={cn(rowHeaderColor)}>
                <tr>
                  {headers.map((head, index) => (
                    <th
                      key={index}
                      scope="col"
                      className={cn(
                        "px-6 py-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
                        "font-bold whitespace-nowrap"
                      )}
                    >
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className={"bg-white divide-y divide-gray-200"}>
                {isLoading &&
                  <tr>
                    <td colSpan={headers?.length || 8}>
                      <ComponentLoader isLoading={true} className={"bg-white"} />
                    </td>
                  </tr>
                }
                {(!isLoading && (rows?.length > 0)) && rows?.map((row: any[], idx) => (
                  <tr
                    key={idx}
                    className={cn(
                      idx % 2 === 0 ? "bg-white" : alternateRowColor
                    )}
                  >
                    {row?.map((r) => (
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 capitalize">{r}</div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {(!isLoading && (!rows || (rows?.length <= 0))) &&
              <div className="w-full bg-white rounded-md p-5 flex justify-center items-center font-bodyPri font-medium text-text-800 text-base tracking-wide whitespace-nowrap">
                {message || "No Data Found!"}
              </div>
            }

            {/* table-pagination */}
            {pageSize !== 0 && (
              <div className="flex items-center justify-center w-full space-x-2 bg-white rounded-lg shadow-md py-7">
                <button
                  className="p-2 bg-white border rounded-full shadow-sm border-sky-400"
                  onClick={() => {
                    if (activePageNumber !== 1) {
                      onPageChange(activePageNumber - 1);
                    }
                  }}
                >
                  <FaChevronLeft className="text-sky-500" />
                </button>
                <div className="flex space-x-1">
                  {getPageNumbers().map((page) => (
                    <button
                      key={page}
                      className={cn(
                        " px-3 py-1 cursor-pointer rounded-full",
                        activePageNumber === page
                          ? "font-bold bg-blue-500 text-white"
                          : "text-sky-500",
                        "hover:scale-110 "
                      )}
                      onClick={() => onPageChange(page)}
                    >
                      {page}
                    </button>
                  ))}
                </div>
                <button
                  onClick={() => {
                    if (activePageNumber !== pageSize) {
                      onPageChange(activePageNumber + 1);
                    }
                  }}
                  className="p-2 bg-white border rounded-full shadow-sm border-sky-400"
                >
                  <FaChevronRight className="text-sky-500" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
