import { useState } from 'react';

import { MdOutlineVideoCall } from 'react-icons/md';
import { BiTimeFive } from 'react-icons/bi';
import { MdGroups } from 'react-icons/md';
import { IoIosStats } from 'react-icons/io';
import { MdOutlineAccountBalanceWallet } from 'react-icons/md';
import { BiCalendar } from 'react-icons/bi';
import { FaLanguage } from 'react-icons/fa';
import { FiMessageSquare } from 'react-icons/fi';
import { BsInfoCircle } from "react-icons/bs";
import { IoMdPeople } from "react-icons/io";
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

import { CollapsableContainer } from 'pages/global/course/CourseDetailed1To1PageStyle';
import { courseSections } from "pages/global/course/data";

import { useAppState } from 'hooks/useStore';
import { coursePriceTypeEnum, courseType, courseIsRecurringEnum } from 'redux/course/course.const';

import { currencyRateConversion } from 'utils/generators.utils';

const MoreDetailsSection = () => {
    const { locals } = useAppState((s) => s.local)
    const { publicCourseDetail } = useAppState((state) => state.course)

    const [clickedItem, setClickedItem] = useState(null)


    const isOpen = courseSections?.MORE_DETAIL_SECTION?.value === clickedItem;

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const answerVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    }

    // const DISCOUNTED_PRICE = publicCourseDetail?.data?.coursePrice?.price - (publicCourseDetail?.data?.coursePrice?.price * publicCourseDetail?.data?.coursePrice?.discountPct) / 100


    const onHandleToggle = () => {
        if (!clickedItem || (clickedItem !== courseSections?.MORE_DETAIL_SECTION?.value)) {
            setClickedItem(courseSections?.MORE_DETAIL_SECTION?.value)
        } else {
            setClickedItem(null)
        }
    }

    return (
        <div className={'space-y-2'}>
            <div className={"font-bodyPri font-medium text-lg text-text-900 tracking-wide"}>
                {"Class Details"}
            </div>

            <div className={'w-full flex flex-col items-start justify-start gap-3 px-3'}>

                {/* proficiency */}
                <div className='flex justify-start gap-3'>
                    <IoIosStats className="text-gray-700 text-xl" />
                    <span className="text-text-700 font-normal font-bodyPri">
                        {"Level:"}
                    </span>
                    {publicCourseDetail?.data?.courseProficiencies?.length > 0 &&
                        publicCourseDetail?.data?.courseProficiencies?.map((proficiency, index) => (
                            <div className={"flex items-center justify-start gap-1"} key={index}>
                                <span className='text-text-900 font-medium font-bodyPri text-base'>
                                    {proficiency?.masterProficiency?.proficiency}
                                </span>
                                {(publicCourseDetail?.data?.courseProficiencies?.length > index + 1) &&
                                    <span className={"font-bodyPri font-normal text-text-900 text-base"}>{"|"}</span>
                                }
                            </div>
                        ))}
                    {(!publicCourseDetail?.data?.courseProficiencies || publicCourseDetail?.data?.courseProficiencies?.length === 0) &&
                        <span className={"font-bodyPri font-normal text-text-900 text-sm"}>
                            {"No level found!"}
                        </span>
                    }
                </div>

                {/* languages */}
                <div className="flex justify-start gap-3">
                    <FaLanguage className="text-gray-700 text-xl" />
                    <span className="text-text-700 font-normal font-bodyPri">
                        {"Language:"}
                    </span>
                    {publicCourseDetail?.data?.courseLanguages?.length > 0 &&
                        publicCourseDetail?.data?.courseLanguages?.map((language, index) => (
                            <div className={"flex items-center justify-start gap-1"} key={index}>
                                <span className="text-text-900 font-medium font-bodyPri">
                                    {language?.masterLanguage?.language}
                                </span>
                                {(publicCourseDetail?.data?.courseLanguages?.length > index + 1) &&
                                    <span className={"font-bodyPri font-normal text-text-900 text-base"}>{"|"}</span>
                                }
                            </div>
                        ))}
                    {(!publicCourseDetail?.data?.courseLanguages || publicCourseDetail?.data?.courseLanguages?.length === 0) &&
                        <span className={"font-bodyPri font-normal text-text-900 text-sm"}>
                            {"No language found!"}
                        </span>
                    }
                </div>

                {/* session duration */}

                <div className="flex justify-start gap-3">
                    <BiTimeFive className="text-gray-700 text-xl" />
                    <span className="text-text-700 font-normal font-bodyPri">
                        {"Session Duration:"}
                    </span>
                    <span className="text-text-900 font-medium font-bodyPri">
                        {(publicCourseDetail?.data?.type === courseType?.GROUP?.value)
                            ? publicCourseDetail?.data?.basicDetail?.duration + " min"
                            : "upto 2 hrs"
                        }
                    </span>
                    <span className="text-text-900 font-medium font-bodyPri">
                    </span>
                </div>

                <CollapsableContainer
                    isOpen={isOpen}
                    variants={answerVariants}
                    initial={'collapsed'}
                    animate={isOpen ? 'open' : 'collapsed'}
                    transition={transition}
                >
                    <div className={"w-full flex flex-col items-start justify-start gap-3"}>
                        {/* session count */}
                        {(publicCourseDetail?.data?.type === courseType?.GROUP?.value) &&
                            <div className="flex justify-start gap-3">
                                <MdOutlineVideoCall className="text-gray-700 text-xl" />
                                <span className="text-text-700 font-normal font-bodyPri">
                                    {"Session Count:"}
                                </span>
                                {(publicCourseDetail?.data?.basicDetail?.isRecurring === courseIsRecurringEnum?.RECURRING?.value) &&
                                    <span className="text-text-900 font-medium font-bodyPri">
                                        {publicCourseDetail?.data?.basicDetail?.sessionCount + " per week"}
                                    </span>
                                }
                                {(publicCourseDetail?.data?.basicDetail?.isRecurring === courseIsRecurringEnum?.NON_RECURRING?.value) &&
                                    <span className="text-text-900 font-medium font-bodyPri">
                                        {publicCourseDetail?.data?.basicDetail?.sessionCount}
                                    </span>
                                }
                            </div>
                        }

                        {/* total duration */}
                        {(publicCourseDetail?.data?.type === courseType?.GROUP?.value) &&
                            <div className="flex justify-start gap-3">
                                <BiTimeFive className="text-gray-700 text-xl" />
                                <span className="text-text-700 font-normal font-bodyPri">
                                    {"Duration:"}
                                </span>
                                {(publicCourseDetail?.data?.basicDetail?.isRecurring === courseIsRecurringEnum?.RECURRING?.value) &&
                                    <span className="text-text-900 font-medium font-bodyPri">
                                        {(publicCourseDetail?.data?.basicDetail?.totalDuration < 60)
                                            ? publicCourseDetail?.data?.basicDetail?.totalDuration + " min. per week"
                                            : (publicCourseDetail?.data?.basicDetail?.totalDuration / 60)?.toFixed(2) + " hour(s) per week"
                                        }
                                    </span>
                                }
                                {(publicCourseDetail?.data?.basicDetail?.isRecurring === courseIsRecurringEnum?.NON_RECURRING?.value) &&
                                    <span className="text-text-900 font-medium font-bodyPri">
                                        {(publicCourseDetail?.data?.basicDetail?.totalDuration < 60)
                                            ? publicCourseDetail?.data?.basicDetail?.totalDuration
                                            : (publicCourseDetail?.data?.basicDetail?.totalDuration / 60)?.toFixed(2)
                                        }
                                    </span>
                                }
                            </div>
                        }

                        {/* Participants */}

                        {(publicCourseDetail?.data?.type === courseType?.GROUP?.value) &&
                            <div className="flex justify-start gap-3">
                                <MdGroups className="text-gray-700 text-xl" />
                                <span className="text-text-700 font-normal font-bodyPri">
                                    {"Participants:"}
                                </span>
                                <span className="text-text-900 font-medium font-bodyPri">
                                    {publicCourseDetail?.data?.studentCapacity + " max"}
                                </span>
                            </div>
                        }

                        {/* total Cost */}
                        {(publicCourseDetail?.data?.coursePrice?.type === coursePriceTypeEnum?.REGULAR?.value) &&
                            <div className="flex justify-start gap-3">
                                <MdOutlineAccountBalanceWallet className="text-gray-700 text-xl" />
                                <span className="text-gray-600 mr-3 font-bodyPri">
                                    {"Total Cost:"}
                                </span>
                                <span className="text-text-900 font-medium font-bodyPri">
                                    {currencyRateConversion(locals?.currencyCode, publicCourseDetail?.data?.basicDetail?.totalPrice)}
                                </span>
                                {publicCourseDetail?.data?.coursePrice?.discountPct > 0 &&
                                    <span className={"text-green-600"}>
                                        {`(${publicCourseDetail?.data?.coursePrice?.discountPct}% off)`}
                                    </span>
                                }
                            </div>
                        }
                    </div>
                </CollapsableContainer>
                {(publicCourseDetail?.data?.type !== courseType?.ONE_ON_ONE?.value && publicCourseDetail?.data?.coursePrice?.type !== coursePriceTypeEnum?.PLAN?.value) &&
                    <div
                        className={"flex flex-row items-center justify-start bg-white rounded-lg cursor-pointer group"}
                        onClick={onHandleToggle}
                    >

                        <div className={"font-bodyPri font-medium text-base text-primary-dark group-hover:text-primary-dark tracking-wide"}>
                            {isOpen
                                ? "See Less"
                                : "See More"
                            }
                        </div>
                        {isOpen
                            ? <ArrowUpward className={"text-sm text-primary-dark group-hover:text-primary-dark"} />
                            : <ArrowDownward className={"text-sm text-primary-dark group-hover:text-primary-dark"} />
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default MoreDetailsSection