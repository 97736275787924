import { useNavigate } from "react-router-dom";

import {
    SubMenuItem,
    ItemName
} from './NavBarStyle';

const RightLoggedInSubMenu = ({ menuItem, setIsClick }) => {

    const navigate = useNavigate()

    const onHandleSubMenuItems = (event) => {
        if (menuItem?.pathType === 'href') {
            window.open(menuItem?.path, "_blank")
        } else {
            navigate(menuItem?.path)
        } 
        setIsClick(false)

        event.preventDefault();
    }


    return (
        <SubMenuItem onClick={onHandleSubMenuItems}>
            <ItemName >
                {menuItem?.name}
            </ItemName >
        </SubMenuItem>
    )
}

export default RightLoggedInSubMenu;