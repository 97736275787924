import { memo } from 'react';

import { FaSpinner } from 'react-icons/fa';

import ToolTipView from 'components/tooltipView';

import { cn } from 'utils/cn.utils';

const PreferenceIconButtons = memo(({
    isLoading = false,
    activeLoading = null,
    iconButtonList = [],
    onHandleIconButton
}) => {
    return (
        <>
            {iconButtonList?.map((iconButton, index) => (
                <>
                    {(activeLoading?.value === iconButton?.value) &&
                        <FaSpinner className={"animate-spin text-lg text-primary-dark"} />
                    }
                    {(!activeLoading || (activeLoading?.value !== iconButton?.value)) &&
                        <ToolTipView key={index} content={iconButton?.tooltip}>
                            <button
                                key={index}
                                className={cn(
                                    "p-2 rounded-full hover:bg-divider-darkLight group",
                                    isLoading && "cursor-wait"
                                )}
                                onClick={() => onHandleIconButton(iconButton)}
                                disabled={isLoading}
                            >
                                {iconButton.icon}
                            </button>
                        </ToolTipView>
                    }
                </>
            ))}
        </>
    )
});

export default PreferenceIconButtons;