import tw, { styled } from "twin.macro";

export const StyledChatUserMenu = styled.div`
  ${tw`absolute top[44px] right-0 bg-primary-light rounded-b-md w-40 flex items-center flex-col border border-primary-light z-30`}
  p {
    ${tw`text-base text-primary-main py-2.5 px-2.5 border-b border-dotted border-primary-main hover:(cursor-pointer)`}
    &:last-of-type {
      ${tw`border-0 `}
    }
  }
`;
