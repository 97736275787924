import { useCallback } from 'react';

import { BsInfoCircle } from 'react-icons/bs';
import { FaCrown, FaSpinner } from 'react-icons/fa';

import { Toggle } from 'components/common-components/Toggle';
import ToolTipView from 'components/tooltipView';

import EventlyProductDetail from './EventlyProductDetail';

import { updateUserEventlyDetail } from 'redux/evently/evently.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { modalConst } from 'redux/local/local.const';
import { setModal } from 'redux/local/local.slice';
import { resetEventlyInvitationList } from 'redux/evently/evently.slice';
import { subscriptionPlanEnum, subscriptionStatusEnum, userPlans } from 'redux/subscription/subscription.const';

import { cn } from 'utils/cn.utils';

const EventlyProduct = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { userEventlyDetail, modifyUserEventlyDetail } = useAppState((state) => state.evently)

    const dispatcher = useAppDispatcher()

    const onHandleToggleCheckout = useCallback((value) => {
        if (!value) {
            const filteredActiveSubscriptions = user?.user?.user_subscriptions?.filter((subscriptionItem) => subscriptionItem?.status === subscriptionStatusEnum.active.key)
            if ((user?.user?.active_product_count >= 15) && !filteredActiveSubscriptions?.length) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.planTableModal.key]: {
                        ...modalConst.planTableModal,
                        isVisible: true,
                        title: "Unlock Premium Features: Subscribe to Go Pro",
                        userPlans
                    },
                }))
                return;
            }
        }
        const EventlyDetailPayload = {
            no_checkout: value
        }
        dispatcher(updateUserEventlyDetail(userEventlyDetail?.data?.id, EventlyDetailPayload))
        dispatcher(resetEventlyInvitationList())
    }, [userEventlyDetail?.data, user?.user])

    const handleUpgrade = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.planTableModal.key]: {
                ...modalConst.planTableModal,
                isVisible: true,
                title: "Unlock Premium Features: Subscribe to Go Pro",
                userPlans
            },
        }))
    }

    return (
        <div className={"w-full flex flex-col gap-3"}>
            <div className={"w-full flex items-center justify-between gap-3"}>
                <div className={"flex items-center gap-1.5"}>
                    <ToolTipView
                        disabled={!user?.user?.user_subscriptions?.length}
                        allowHTML={true}
                        theme={"light"}
                        content={(() => (
                            <div className={"p-2 rounded flex flex-col items-start justify-start gap-1"}>
                                {user?.user?.user_subscriptions?.map((user_subscription, index) => (
                                    <span key={index} className={"font-bodyPri font-medium text-base text-text-900"}>
                                        {`${subscriptionPlanEnum[user_subscription?.plan]?.label}: ${subscriptionStatusEnum[user_subscription?.status]?.label}`}
                                    </span>
                                ))}
                            </div>
                        ))()}
                    >
                        <button onClick={handleUpgrade}>
                            <FaCrown className={"text-2xl text-complementry-main cursor-pointer"} />
                        </button>
                    </ToolTipView>
                    <p className={cn(
                        "font-bodyPri font-bold text-text-700 text-base tracking-wide",
                        userEventlyDetail?.data?.no_checkout && "text-green-500"
                    )}>
                        {"This is Free"}
                    </p>
                    <ToolTipView content={"No checkout needed if toggled on."}>
                        <button className={"cursor-pointer"}>
                            <BsInfoCircle className="text-md text-text-900 hover:text-text-900" />
                        </button>
                    </ToolTipView>
                </div>
                <div className={"-m-2 flex"}>
                    <Toggle
                        value={userEventlyDetail?.data?.no_checkout}
                        onChange={onHandleToggleCheckout}
                    />
                    {modifyUserEventlyDetail?.isLoading &&
                        <div className={"flex items-center gap-1"}>
                            <FaSpinner className={"animate-spin text-lg text-primary-dark"} />
                            <span className={"font-bodyPri font-normal text-primary-dark text-sm"}>
                                {"Please wait ..."}
                            </span>
                        </div>
                    }
                </div>
            </div>
            {!userEventlyDetail?.data?.no_checkout &&
                <EventlyProductDetail />
            }
        </div>
    )
}

export default EventlyProduct;