import { lmsResourceTypeEnum, resourceFileTypeEnum } from "redux/edulyteLms/lmsResource/lmsResource.const";

const ResourceView = ({ resource }) => {

    if (
        !resource ||
        resource?.type !== lmsResourceTypeEnum.FILE.value ||
        !resource?.resource ||
        ![resourceFileTypeEnum.IMAGE.value, resourceFileTypeEnum.VIDEO.value].includes(resource?.resource?.type)
    ) {
        return null
    }

    return (
        <div className={"w-full aspect-video overflow-hidden rounded-lg"}>
            {resource?.resource?.type === resourceFileTypeEnum.IMAGE.value && (
                <img
                    src={resource?.resource?.file?.file_url}
                    alt={resource?.title}
                    className={"w-full h-full object-cover"}
                />
            )}
            {resource?.resource?.type === resourceFileTypeEnum.VIDEO.value && (
                <video
                    src={resource?.resource?.file?.file_url}
                    alt={resource?.title}
                    className={"w-full h-full object-cover"}
                    allowFullScreen={false}
                    autoPlay={false}
                    controls
                ></video>
            )}
        </div>
    )
}

export default ResourceView