import { useCallback, useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";

import { IoMdClose } from "react-icons/io";
import { GoPlus } from "react-icons/go";
import { MdCheck, MdEdit } from "react-icons/md";
import { FaSpinner, FaTimes } from "react-icons/fa";

import ToolTipView from "components/tooltipView";
import FloatingLabelSelect from "components/floating/floatingLabelSelect";

import { createCrmContactLabelDetail, getCrmContactLabelList, updateCrmContactLabelDetail } from "redux/crm/crm.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import {
    resetAddCrmContactLabelDetail,
    resetCrmContactLabelList,
    resetModifyCrmContactLabelDetail,
    setAddCrmContactDetailPayload,
    setCrmContactLabelListData
} from "redux/crm/crm.slice";

const CreateLabel = () => {
    const { user } = useAppState((state) => state.user)
    const { addCrmContactDetail, addCrmContactLabelDetail, modifyCrmContactLabelDetail, crmContactLabelList } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()

    const [labelPayload, setLabelPayload] = useState({
        isShowLabelList: false,
        isShowInput: false,
        labelId: "",
        labelText: "",
        labelKey: "",
        labelColor: "#000"
    });

    const filteredLabelList = useMemo(() => {
        if (crmContactLabelList?.data?.length) {
            let newContactLabelList = crmContactLabelList.data;
            if (!!addCrmContactDetail?.payload?.labels?.length) {
                newContactLabelList = newContactLabelList.filter((item) =>
                    !addCrmContactDetail.payload.labels.some((labelItem) => labelItem.value === item.key)
                );
            }
            return newContactLabelList;
        }
        return [];
    }, [crmContactLabelList?.data, addCrmContactDetail?.payload?.labels]);

    useEffect(() => {
        return () => {
            dispatcher(resetCrmContactLabelList())
        }
    }, [])

    useEffect(() => {
        if (user?.user?.userId) {
            dispatcher(getCrmContactLabelList({ owner_user_id: user?.user?.userId }))
        }
    }, [user?.user?.userId])

    useEffect(() => {
        if (addCrmContactLabelDetail?.data) {
            dispatcher(setCrmContactLabelListData([addCrmContactLabelDetail?.data, ...crmContactLabelList?.data]))
            dispatcher(setAddCrmContactDetailPayload({
                ...addCrmContactDetail?.payload,
                labels: !!addCrmContactDetail?.payload?.labels?.length
                    ? [...addCrmContactDetail?.payload?.labels, { label: addCrmContactLabelDetail?.data?.name, value: addCrmContactLabelDetail?.data?.key, color: addCrmContactLabelDetail?.data?.color }]
                    : [{ label: addCrmContactLabelDetail?.data?.name, value: addCrmContactLabelDetail?.data?.key, color: addCrmContactLabelDetail?.data?.color }],
            }))
            dispatcher(resetAddCrmContactLabelDetail())
            setLabelPayload({
                isShowLabelList: false,
                isShowInput: false,
                labelText: "",
                labelKey: "",
                labelColor: "#000"
            });
        }
    }, [addCrmContactLabelDetail?.data])

    useEffect(() => {
        if (modifyCrmContactLabelDetail?.data) {
            const newLabelList = crmContactLabelList?.data?.map((contactLabel) => (
                (contactLabel?.id === modifyCrmContactLabelDetail?.data?.id)
                    ? modifyCrmContactLabelDetail?.data
                    : contactLabel
            ))
            dispatcher(setCrmContactLabelListData(newLabelList))
            dispatcher(resetModifyCrmContactLabelDetail())
            setLabelPayload({
                isShowLabelList: true,
                isShowInput: false,
                labelText: "",
                labelKey: "",
                labelColor: "#000"
            });
        }
    }, [modifyCrmContactLabelDetail?.data])

    const onHandleSelectOption = useCallback((selectedOption) => {
        if (!!addCrmContactDetail?.payload?.labels?.length) {
            const isLabelAlreadyPresent = addCrmContactDetail?.payload?.labels?.filter((labelItem) => labelItem?.value === selectedOption?.value)

            if (!!isLabelAlreadyPresent?.length) {
                toast.warn("Label already added!")
                return;
            }
        }
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            labels: [...addCrmContactDetail?.payload?.labels, selectedOption],
        }))
    }, [addCrmContactDetail?.payload]);

    const onHandleDeleteLabelDetail = useCallback((option) => {
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            labels: addCrmContactDetail?.payload?.labels?.filter((labelItem) => labelItem?.value !== option?.value),
        }))
    }, [addCrmContactDetail?.payload])

    const onHandleUpdateLabelDetail = (event, selectedLabel) => {
        event.stopPropagation()
        setLabelPayload({
            ...labelPayload,
            isShowLabelList: false,
            isShowInput: true,
            labelId: selectedLabel?.id,
            labelText: selectedLabel?.label,
            labelKey: selectedLabel?.value,
            labelColor: selectedLabel?.color
        })
    }

    const onHandleChangeLabel = useCallback((event) => {
        let labelKey = labelPayload?.labelKey
        if (event.target.name == "labelText") {
            labelKey = event.target.value.toLowerCase()?.replaceAll(" ", "-")
        }
        setLabelPayload({
            ...labelPayload,
            [event.target.name]: event.target.value,
            labelKey: labelKey
        })
    }, [labelPayload])

    const onHandleKeyDown = (event) => {
        if (event.keyCode === 13) {
            onHandleAddNewLabel()
        }
    }

    const onHandleAddNewLabel = () => {
        if (addCrmContactLabelDetail?.isLoading || modifyCrmContactLabelDetail?.isLoading) return;

        if (labelPayload?.labelText?.trim() === "") {
            toast.warn("Please enter label name.");
            return;
        }
        if (!!addCrmContactDetail?.payload?.labels?.length) {
            const isLabelAlreadyPresent = addCrmContactDetail?.payload?.labels?.filter((labelItem) => labelItem?.value === labelPayload?.labelKey)

            if (!!isLabelAlreadyPresent?.length) {
                toast.warn("Label already added!")
                return;
            }
        }

        let requestDataPayload = {
            name: labelPayload?.labelText?.trim(),
            color: labelPayload?.labelColor || "#000"
        }
        if (labelPayload?.labelId) {
            dispatcher(updateCrmContactLabelDetail(labelPayload?.labelId, requestDataPayload))
        } else {
            requestDataPayload["owner_user_id"] = user?.user?.userId
            dispatcher(createCrmContactLabelDetail(requestDataPayload))
        }

    }

    return (
        <div className={"w-full inline-flex flex-wrap items-start gap-5"}>
            {!!addCrmContactDetail?.payload?.labels?.length &&
                <>
                    {addCrmContactDetail?.payload?.labels?.map((labelItem, index) => (
                        <div
                            key={index}
                            className={`w-fit px-2 h-9 flex items-center justify-start rounded !${labelItem?.color}`}
                            style={{ backgroundColor: labelItem?.color }}
                        >
                            <span className={`font-bodyPri font-normal text-text-50 text-sm capitalize`}>
                                {labelItem?.label}
                            </span>
                            <button
                                className={"pl-3 cursor-pointer"}
                                onClick={() => onHandleDeleteLabelDetail(labelItem)}
                            >
                                <FaTimes className={"font-light text-text-50"} />
                            </button>
                        </div>
                    ))}
                </>
            }
            <div className={"flex-1 min-w-[12rem]"}>
                <FloatingLabelSelect
                    labelItem={`label_label_select_label`}
                    isLoading={crmContactLabelList?.isLoading}
                    options={filteredLabelList?.map((label) => ({
                        id: label?.id,
                        label: label?.name,
                        value: label?.key,
                        color: label?.color
                    })) || []}
                    searchable={!!filteredLabelList?.length && true}
                    isOpenDropdown={labelPayload?.isShowLabelList}
                    onHandleSelect={onHandleSelectOption}
                    value={"Select label"}
                    label={"Label"}
                    customBtnStyle={"!h-9"}
                    dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                    isShowEmptyContent={!addCrmContactDetail?.payload?.labels?.length || !filteredLabelList?.length}
                    EmptyContentContainer={() => {
                        return (
                            <button
                                onClick={() => setLabelPayload({
                                    ...labelPayload,
                                    isShowLabelList: false,
                                    isShowInput: true,
                                    labelText: "",
                                    labelKey: "",
                                    labelColor: "#000"
                                })}
                                className={"w-full flex items-center justify-center gap-1 py-1.5"}
                            >
                                <GoPlus />
                                {"Create label"}
                            </button>
                        )
                    }}
                    OptionChild={({ option }) => (
                        <div className={"bg-white hover:bg-gray-100 px-4 py-2 flex justify-between gap-3"}>
                            <div
                                className={"flex items-center gap-1"}
                                style={{ color: option?.color }}
                                title={option?.label}
                            >
                                <span className={"line-clamp-1"}>
                                    {option?.label}
                                    <span className={"pl-1.5 text-sm"}>{`(${option?.value})`}</span>
                                </span>
                            </div>
                            <button className={"p-1 rounded-full hover:bg-divider-darkLight group"} onClick={(event) => onHandleUpdateLabelDetail(event,option)}>
                                <MdEdit className={"text-xl text-text-700 group-hover:text-primary-dark cursor-pointer"} />
                            </button>
                        </div>
                    )}
                />
            </div>
            {labelPayload?.isShowInput ? (
                <div className={"flex flex-col gap-1 overflow-hidden"}>
                    <div className={"flex items-center justify-center gap-1 px-3 h-9 border rounded border-[#adadad88] focus-within:hover:border-primary-main"}>
                        <input
                            type={"text"}
                            value={labelPayload?.labelText}
                            name={"labelText"}
                            onChange={onHandleChangeLabel}
                            placeholder={"Enter new label"}
                            className={"flex-1 h-full outline-none text-black"}
                            onKeyDown={onHandleKeyDown}
                            autoFocus={true}
                        />
                        <ToolTipView content={"Choose label color"}>
                            <input
                                type={"color"}
                                name={"labelColor"}
                                className={"w-[2rem] h-full rounded-full cursor-pointer"}
                                value={labelPayload?.labelColor}
                                onChange={onHandleChangeLabel}
                            />
                        </ToolTipView>
                        <div className={"flex items-center gap-2 pl-1"}>
                            <ToolTipView content={"Save label"}>
                                <button
                                    className="text-primary-dark hover:text-secondary-dark text-lg"
                                    disabled={addCrmContactLabelDetail?.isLoading || modifyCrmContactLabelDetail?.isLoading}
                                    onClick={onHandleAddNewLabel}
                                >
                                    {(addCrmContactLabelDetail?.isLoading || modifyCrmContactLabelDetail?.isLoading) && <FaSpinner className={"animate-spin text-primary-dark"} />}
                                    {(!addCrmContactLabelDetail?.isLoading && !modifyCrmContactLabelDetail?.isLoading) && <MdCheck />}
                                </button>
                            </ToolTipView>
                            <ToolTipView content={"Reset"}>
                                <button
                                    className="cursor-pointer text-lg"
                                    disabled={addCrmContactLabelDetail?.isLoading || modifyCrmContactLabelDetail?.isLoading}
                                    onClick={() => {
                                        setLabelPayload({
                                            isShowLabelList: false,
                                            isShowInput: false,
                                            labelId: 0,
                                            labelText: "",
                                            labelKey: "",
                                            labelColor: "#000",
                                        })
                                    }}>
                                    <IoMdClose />
                                </button>
                            </ToolTipView>
                        </div>
                    </div>
                    {!!labelPayload?.labelKey?.length &&
                        <span className={"text-sm line-clamp-1"}>
                            {`key: `}
                            <span className={"font-medium"}>
                                {labelPayload?.labelKey}
                            </span>
                        </span>
                    }
                </div>
            ) : (
                <button
                    onClick={() => setLabelPayload({
                        ...labelPayload,
                        isShowLabelList: false,
                        isShowInput: true,
                        labelText: "",
                        labelKey: "",
                        labelColor: "#000",
                    })}
                    className={"flex items-center justify-center gap-1 px-3 md:px-5 h-9 border border-[#adadad88] rounded hover:text-primary-main hover:border-primary-main"}
                >
                    <GoPlus />
                    {"Create label"}
                </button>
            )}
        </div>
    );
};

export default CreateLabel;