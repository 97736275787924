import { useState } from 'react';
import { toast } from 'react-toastify';

import format from "string-format";

import { MdContentCopy } from "react-icons/md";
import { AiOutlineCheckCircle } from "react-icons/ai";

import { socialLinks } from "components/modals/getMoreReviewsModal/reviewsData";

import { useAppState } from 'hooks/useStore';

import { cn } from "utils/cn.utils";

import { QR_CODE_URL } from "const/default.const";

const GET_MORE_REVIEWS = "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/review/get-review-modal.png"

const GetMoreReviewsModal = () => {
    const { shareSocial } = useAppState((s) => s.local)

    const [isURLCopied, setIsURLCopied] = useState("")
    const [showQR, setShowQR] = useState(true);

    const qrUrl = format(QR_CODE_URL, { link: shareSocial?.url });

    const handleDownloadQRCode = () => {
        fetch(qrUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = "qrcode.png";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => console.error("Error downloading QR code:", error));
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(shareSocial?.url);
        setIsURLCopied(true);
        toast.success("Copied!")
        setTimeout(() => {
            setIsURLCopied(false);
        }, 2000);
    }

    return (
        <div className={"space-y-5"}>
            <div className={"min-h-[28rem] block pt-5 space-y-10"}>
                <div className={"w-full flex flex-col items-center justify-center gap-2"}>
                    <div className={"flex items-center justify-center font-bodyPri font-normal text-text-900 text-md tracking-wide"}>
                        {"Give customers a link to review your services on Edulyte."}
                    </div>
                    <div className={"flex items-center justify-center font-bodyPri font-normal text-text-800 text-sm"}>
                        {"Become a top rated provider. Reviews build trust and help your Profile stand out."}
                    </div>
                </div>
                <div className={"w-full flex items-center justify-center"}>
                    <div className={"w-60 h-28 overflow-hidden"}>
                        <img
                            src={GET_MORE_REVIEWS}
                            className={"w-full h-full object-cover"}
                            alt={"profileImg"}
                        />
                    </div>
                </div>
                <div className={"flex flex-col justify-center items-center gap-3"}>
                    {showQR && (
                        <div className="flex flex-col items-center justify-center">
                            <img
                                src={qrUrl}
                                className="w-full aspect-square object-cover rounded"
                            />
                            <button
                                className="px-5 py-2 rounded-lg border border-gray-500 hover:!bg-blue-500"
                                onClick={handleDownloadQRCode}
                            >
                                <span>
                                    {"Download"}
                                </span>
                            </button>
                        </div>
                    )}
                    <div className="font-bodyPri font-semibold text-text-900 text-md">
                        {"Share Via"}
                    </div>
                    <div className='flex flex-wrap gap-3'>
                        <div className={"flex flex-col items-center justify-start gap-1.5"}>
                            <div
                                className={"w-[3.2rem] h-[3.2rem] flex items-center justify-center bg-primary-dark cursor-pointer"}
                                onClick={handleCopyLink}
                            >
                                {!isURLCopied
                                    ? <MdContentCopy className={"text-2xl text-text-50"} />
                                    : <AiOutlineCheckCircle className={"text-2xl text-text-50"} />
                                }

                            </div>
                            <span className={"font-bodyPri font-normal text-text-900 text-xs"}>
                                {"Copy"}
                            </span>
                        </div>

                        <div className={"flex flex-col items-center justify-start gap-1.5"}>
                            <div
                                className={"w-[3.2rem] h-[3.2rem] flex items-center justify-center bg-primary-dark cursor-pointer"}
                                onClick={() => setShowQR(!showQR)}>
                                <img
                                    src={qrUrl}
                                    alt={"QR Code"}
                                    className={"w-full h-full object-contain"}
                                />
                            </div>
                            <span className={"font-bodyPri font-normal text-text-900 text-xs"}>
                                {"QR Code"}
                            </span>
                        </div>

                        {socialLinks && Object.values(socialLinks).map((socialLink) =>
                            <div className={"flex flex-col items-center justify-start gap-0.5 "}>
                                <span>
                                    {socialLink?.component({ url: shareSocial?.url, size: 50 })}
                                </span>
                                <span className={"font-bodyPri font-normal text-text-900 text-xs"}>
                                    {socialLink?.label}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className={"w-full flex items-center justify-center py-5"}>
                        <div
                            className={cn(
                                "w-96 relative px-3 py-2 border border-text-400 rounded-md",
                                "focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600"
                            )}
                        >
                            <label
                                htmlFor={"name"}
                                className="absolute inline-block px-1 -mt-px text-xs font-medium text-gray-900 bg-white -top-2 left-2"
                            >
                                {"Review Link"}
                            </label>
                            <div className={"w-full flex items-center justify-between gap-3"}>
                                <input
                                    type={"text"}
                                    className={cn(
                                        "block w-full p-0 text-gray-900 placeholder-gray-500 ",
                                        "border-0 focus:ring-0 sm:text-sm focus:outline-none"
                                    )}
                                    value={shareSocial?.url}
                                />
                                <span className={"cursor-pointer"} onClick={handleCopyLink}>
                                    {!isURLCopied
                                        ? <MdContentCopy className={"text-2xl text-text-700"} />
                                        : <AiOutlineCheckCircle className={"text-2xl text-text-700"} />
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={"w-full flex items-center justify-center gap-1 font-bodyPri font-light text-text-900 text-xs"}>
                        <a
                            href={"https://support.edulyte.com/portal/en/kb/articles/how-can-i-earn-more-reviews"}
                            className={"text-primary-main cursor-pointer hover:underline"}
                            target={"_blank"}
                        >
                            {"Learn more"}
                        </a>
                        <span>
                            {"about best practices for asking for reviews, and what to do about negative reviews."}
                        </span>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default GetMoreReviewsModal;