
function ContactCardLoader({ isLoading = false }) {

    return isLoading ? (
        <div className={"w-[300px] font-bodyPri bg-white p-3 rounded border grid grid-cols-3 gap-3 divide-x"}>
            <div className="col-span-1 flex flex-col gap-2 contact-center animate-pulse">
                <div className={"relative w-20 aspect-square border rounded-full overflow-hidden bg-slate-300"}>
                    <img className={"w-full h-full object-cover"} />
                </div>
                <p className="h-3 text-xs text-text-600 text-center line-clamp-1 bg-slate-300">
                    {""}
                </p>
            </div>
            <div className="col-span-2 space-y-0.5 pl-3 animate-pulse">
                <p className="h-5 font-medium truncate bg-slate-200">
                    {""}
                </p>
                <p className="w-16 h-3 text-sm text-text-600 truncate bg-slate-200">
                    {""}
                </p>
                <p className="w-10 h-2 text-sm text-text-800 truncate bg-slate-200">
                    {""}
                </p>
                <div className="flex items-center gap-2 pt-2">
                    <button className={"flex-1 h-6 flex justify-center items-center rounded-md shadow-md bg-slate-200"}>
                    </button>
                    <button
                        className={"flex-1 h-6 flex justify-center items-center rounded-md shadow-md bg-slate-200"}>
                    </button>
                </div>
            </div>
        </div>
    ) : null
}

export default ContactCardLoader;