import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { cn } from "utils/cn.utils";

import { FiEdit } from 'react-icons/fi';
import { FaSpinner } from 'react-icons/fa';
import { MdOutlineEmail } from 'react-icons/md';

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

import ComponentLoader from 'components/loader/ComponentLoader';
import ButtonLoader from 'components/loader/ButtonLoader';
import InputOtp from 'components/inputOtp/InputOtp';

import { viewContainerConst, editBtnConst } from 'components/modals/needATutorModal/data';
import SaveAndCancelBtn from 'components/modals/needATutorModal/SendAndCancelBtn';

import { createSendOtp, createVerifyOtp } from "redux/notification/notification.request";
import { createSignUp, createVerifyUser } from 'redux/auth/auth.request';
import { createLeadDetail } from 'redux/lead/lead.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearSendOtp, setClearVerifyOtp } from 'redux/notification/notification.slice';
import { setAddLeadDetailPayload } from 'redux/lead/lead.slice';
import { setClearVerifyUser } from 'redux/auth/auth.slice';
import { otpDeliveryMethod, otpStatus } from 'redux/notification/notification.const';

import { validateEmail } from 'utils/validation-functions';
import { timeZone } from 'utils/dateTime.utils';

const edulyteTermsOfService = "https://www.edulyte.com/terms-of-service/"
const edulytePrivacyPolicy = "https://www.edulyte.com/privacy-policy/"

const VerifyCode = ({ setStepCount, setViewContainer }) => {
    const { signUp, verifyUser } = useAppState((s) => s.auth)
    const { user } = useAppState((state) => state.user)
    const { sendOtp, verifyOtp } = useAppState((state) => state.notification)
    const { addLeadDetail } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [myOtp, setMyOtp] = useState("")
    const [isOtpError, setIsOtpError] = useState(false)
    const [editBtn, setEditBtn] = useState(null)
    const [emailAddress, setEmailAddress] = useState()
    const [emailAddressError, setEmailAddressError] = useState()

    const pageUrl = EDULYTE_APP_DOMAIN_URL + location.pathname

    const getRecaptchaToken = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha('signup');
        if (token) {
            return token;
        }
    }, [executeRecaptcha])

    const createNewSignUpUser = async () => {
        const generatedToken = await getRecaptchaToken()

        if (generatedToken) {
            dispatcher(createSignUp({
                email: addLeadDetail?.addLeadDetailPayload?.email,
                firstName: addLeadDetail?.addLeadDetailPayload?.firstName,
                lastName: addLeadDetail?.addLeadDetailPayload?.lastName,
                isPromotionActive: false,
                rechaptcha_token: generatedToken,
                timeZone: timeZone,
                source: pageUrl
            }))
        }
    }

    useEffect(() => {
        if (addLeadDetail?.addLeadDetailPayload?.email) {
            setEmailAddress(addLeadDetail?.addLeadDetailPayload?.email)
        }
    }, [addLeadDetail?.addLeadDetailPayload?.email])

    useEffect(() => {
        if (emailAddress && !validateEmail(emailAddress)) {
            setEmailAddressError("Invalid Email")
        } else {
            setEmailAddressError("")
        }
    }, [emailAddress])

    useEffect(() => {
        if (addLeadDetail?.addLeadDetail) {
            dispatcher(setClearVerifyUser())
            dispatcher(setClearVerifyOtp())
            dispatcher(setClearSendOtp())
            if (!user?.user?.mobileNo) {
                setStepCount(6)
                setViewContainer(viewContainerConst.PHONE.value)
            }
            if (user?.user?.mobileNo) {
                setStepCount(7)
                setViewContainer(viewContainerConst.CITY.value)
            }
        }
    }, [addLeadDetail?.addLeadDetail])

    useEffect(() => {
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            dispatcher(createVerifyUser({
                email: addLeadDetail?.addLeadDetailPayload?.email
            }))
            createNewSignUpUser()
            dispatcher(setAddLeadDetailPayload({
                ...addLeadDetail?.addLeadDetailPayload,
                isEmailVerified: true,
                isDone: true
            }))
        }
        if (verifyOtp?.verifyOtp && (verifyOtp?.verifyOtp?.status === otpStatus.PENDING.value || verifyOtp?.verifyOtp?.status === otpStatus.EXPIRED.value)) {
            setViewContainer(viewContainerConst.EMAIL.value)
            toast.warning("Something went wrong! Please wait!")
            return;
        }
        if (!verifyOtp?.verifyOtp && verifyOtp?.errorMsg) {

            setIsOtpError(true)
            return;
        }
    }, [verifyOtp?.verifyOtp || verifyOtp?.errorMsg])

    useEffect(() => {
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            const body = {
                grade: addLeadDetail?.addLeadDetailPayload?.level,
                topic: addLeadDetail?.addLeadDetailPayload?.subject,
                firstName: addLeadDetail?.addLeadDetailPayload?.firstName,
                lastName: addLeadDetail?.addLeadDetailPayload?.lastName,
                email: addLeadDetail?.addLeadDetailPayload?.email,
                isEmailVerified: true,
                classFor: addLeadDetail?.addLeadDetailPayload?.journey,
                contactTime: addLeadDetail?.addLeadDetailPayload?.contactTime,
                timeZone: timeZone,
                actionPageLink: addLeadDetail?.addLeadDetailPayload?.actionPageLink
            }
            if (!verifyUser?.verifyUser && verifyUser?.errorMsg === "User does not exist") {
                body["isDisableEmail"] = true
            }
            dispatcher(createLeadDetail(body))
        }
    }, [verifyUser?.verifyUser || verifyUser?.errorMsg])

    const onSendCode = () => {
        if (addLeadDetail?.isLoading) return;

        dispatcher(setClearSendOtp())
        if (!emailAddress) {
            alert("Please enter email!")
            return;
        }
        if (emailAddress && (emailAddress !== addLeadDetail?.addLeadDetailPayload?.email)) {
            dispatcher(setAddLeadDetailPayload({
                ...addLeadDetail?.addLeadDetailPayload,
                email: emailAddress
            }))
            dispatcher(createSendOtp({
                channel: otpDeliveryMethod?.EMAIL?.value,
                email: emailAddress
            }))
            setEditBtn(null)
            return;
        }
        if (emailAddress && (emailAddress === addLeadDetail?.addLeadDetailPayload?.email && addLeadDetail?.addLeadDetailPayload?.isEmailVerified)) {
            toast.warning("Email already verified!")
            return;
        }
    }

    const onCancel = () => {
        if (!validateEmail(emailAddress)) {
            alert("Please enter valid email address")
            return;
        }
        setEmailAddress(addLeadDetail?.addLeadDetailPayload?.email)
        setEditBtn(null)
    }

    const onHandleVerifyButton = () => {
        if (myOtp?.length < 6) return;
        if (addLeadDetail?.isLoading) return;

        dispatcher(setClearVerifyOtp())
        if (sendOtp?.sendOtp?.otpId) {
            dispatcher(createVerifyOtp({
                otpId: sendOtp?.sendOtp?.otpId,
                otp: myOtp
            }))
        }
    }

    return (
        <div className={"w-full flex flex-col items-start justify-start gap-10 px-2 transition ease-in-out delay-1000 duration-1000 transform"}>
            <div className={"flex flex-col items-start justify-start gap-2"}>
                <span className={"font-bodyPri font-bold text-4xl text-text-900 tracking-wide"}>
                    <span className={"capitalize"}>{addLeadDetail?.addLeadDetailPayload?.firstName}</span>
                    {", we've emailed a code to verify your email address."}
                </span>
                <span className={"font-bodyPri font-normal text-md text-text-800 italic"}>
                    {"You will get a shiny new dashboard with many other tools to support your learning process."}
                </span>
            </div>
            <div className={"w-full flex flex-col items-start justify-center gap-2"}>
                {editBtn !== editBtnConst.EMAIL.value &&
                    <div className={"flex items-center justify-start gap-3"}>
                        <span className={"font-bodyPri font-medium text-xl text-text-900 tracking-wide italic"}>
                            {emailAddress}
                        </span>
                        <FiEdit className={"text-text-500 text-lg cursor-pointer"} onClick={() => setEditBtn(editBtnConst.EMAIL.value)} />
                    </div>
                }
                {editBtn === editBtnConst.EMAIL.value &&
                    <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                        <div className={cn(
                            "w-1/2 px-2 py-3 flex items-center justify-start gap-1.5 border-2 border-divider-medium focus:border-2 focus:border-text-400 rounded-md"
                        )}>
                            <MdOutlineEmail className={"text-3xl text-text-500"} />
                            <input
                                type={"text"}
                                placeholder={"email@domain.com"}
                                className={cn(
                                    "w-full px-2 focus:outline-none font-bodyPri font-normal text-text-800 text-lg",
                                    "placeholder:text-text-500 placeholder:text-lg"
                                )}
                                autoFocus={true}
                                value={emailAddress}
                                onChange={(event) => setEmailAddress(event.target.value)}
                            />
                        </div>
                        {emailAddressError &&
                            <span className={"font-bodyPri font-normal text-red-500 text-base"}>
                                {emailAddressError}
                            </span>
                        }
                        <SaveAndCancelBtn
                            onCancel={() => onCancel()}
                            onSend={() => onSendCode()}
                        />
                    </div>
                }
                {(editBtn !== editBtnConst.EMAIL.value) &&
                    <div className={"space-y-1.5"}>
                        <span className={"font-bodyPri font-medium text-xl text-text-900 tracking-wide"}>
                            {"Code"}
                        </span>
                        {sendOtp?.isLoading && <ComponentLoader isLoading={sendOtp?.isLoading} />}
                        {sendOtp?.sendOtp &&
                            <InputOtp
                                email={emailAddress}
                                otpLength={6}
                                setMyOtp={setMyOtp}
                                isOtpError={isOtpError}
                                setIsOtpError={setIsOtpError}
                            />
                        }
                        {sendOtp?.errorMsg &&
                            <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                                {sendOtp?.errorMsg}
                            </span>
                        }
                    </div>
                }
            </div>
            {(editBtn !== editBtnConst.EMAIL.value) &&
                <span
                    className={cn(
                        "w-48 py-3 flex justify-center items-center rounded-full",
                        "font-buttons font-medium text-2xl text-text-50",
                        myOtp?.length === 6 && "bg-primary-dark cursor-pointer transition ease-in-out delay-100 duration-300 transform hover:scale-105",
                        myOtp?.length < 6 && "bg-divider-medium",
                    )}
                    onClick={onHandleVerifyButton}
                >
                    {verifyOtp?.isLoading && <ButtonLoader isLoading={verifyOtp?.isLoading} />}
                    {!verifyOtp?.isLoading && "Verify"}
                </span>
            }
            {(signUp?.isLoading || addLeadDetail?.isLoading) &&
                <div className={"flex items-center justify-center gap-2"}>
                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                    <span className={"font-bodyPri font-normal text-base text-text-700 whitespace-nowrap"}>
                        {"Please wait ..."}
                    </span>
                </div>
            }
            {(signUp?.message || addLeadDetail?.errorMsg) &&
                <div className={"flex items-center justify-center font-bodyPri font-normal text-base text-red-500"}>
                    {signUp?.message || addLeadDetail?.errorMsg}
                </div>
            }
            <div className={"font-bodyPri font-normal text-text-800 text-xs tracking-wide"}>
                {"Rest assured, we will not spam you. By signing up, you agree to"}
                <a
                    href={edulyteTermsOfService}
                    rel={"noreferrer"}
                    target={"_blank"}
                    className={"text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer"}
                >
                    {"Terms of service"}
                </a>
                {"and"}
                <a
                    href={edulytePrivacyPolicy}
                    rel={"noreferrer"}
                    target={"_blank"}
                    className={"text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer"}
                >
                    {"Privacy Policy"}
                </a>
            </div>
        </div>
    )
}

export default VerifyCode;