export const url = {
    GET_LMS_ARTICLE_LIST: "lms/articles/tutor-articles",
    GET_LMS_ARTICLE_DETAIL: "lms/articles/tutor-articles/{articleId}",
    CREATE_LMS_ARTICLE_DETAIL: "lms/articles/articles",
    UPDATE_LMS_ARTICLE_DETAIL: "lms/articles/articles/{articleId}",
    DELETE_LMS_ARTICLE_DETAIL: "lms/articles/articles/{articleId}",

    // lms course instructor detail
    CREATE_LMS_ARTICLE_INSTRUCTOR_DETAIL: "lms/articles/users",
    UPDATE_LMS_ARTICLE_INSTRUCTOR_DETAIL: "lms/articles/users/{articleInstructorId}",
    DELETE_LMS_ARTICLE_INSTRUCTOR_DETAIL: "lms/articles/users/{articleInstructorId}",
}

export const lmsArticleStatusEnum = {
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    }
}

export const lmsArticleShareableEnum = {
    YES: {
        label: "Yes",
        value: "yes"
    },
    NO: {
        label: "No",
        value: "no"
    }
}

export const lmsArticleVisibilityEnum = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    }
}

export const lmsArticleInstructorPermissionEnum = {
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    }
}
export const addLmsArticleDetailPayload = {
    title: '',
    description: ''
}

export const addLmsArticleInstructorDetailPayload = {
    userId: null,
    permission: null,
    articleInstructorId: null

}