import { useCallback, useState } from "react";

import { Reorder } from "framer-motion";

import LmsTextAreaDescription from "pages/auth/edulyteLms/commonComponents/LmsTextAreaDescription";

import EmptyQuizSectionContainer from "./EmptyQuizSectionContainer";
import QuizSectionQuestion from "./QuizSectionQuestion";
import QuizSectionVideo from "./QuizSectionVideo";
import QuizSectionImage from "./QuizSectionImage";
import QuizSectionArticle from "./QuizSectionArticle";

import { updateLmsQuizCategoryOrderList, updateLmsQuizSectionDetail } from "redux/edulyteLms/lmsQuiz/lmsQuiz.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setLmsQuizDetailData } from "redux/edulyteLms/lmsQuiz/lmsQuiz.slice";
import { lmsQuizCategoryEnum } from "redux/edulyteLms/lmsQuiz/lmsQuiz.const";
import { resourceFileTypeEnum } from "redux/edulyteLms/lmsResource/lmsResource.const";

import { isOrderUpdated } from "utils/generators.utils";

const LmsQuizSectionCategoryList = ({ quizSectionItem }) => {
    const { lmsQuizDetail, updateLmsQuizSection } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()

    const [sectionDescriptionStatus, setSectionDescriptionStatus] = useState("")
    const [isSectionCategoryOrderUpdated, setIsSectionCategoryOrderUpdated] = useState(false)

    const onHandleSaveDescription = useCallback((editedDescription) => {
        if (editedDescription === quizSectionItem?.description) return;

        setSectionDescriptionStatus("Saving...")
        const body = {
            description: editedDescription
        }
        const payload = {
            isShowToast: false
        }
        dispatcher(updateLmsQuizSectionDetail(quizSectionItem?.id, body, payload))

        setTimeout(() => {
            setSectionDescriptionStatus("")
        }, 1000)
    }, [lmsQuizDetail?.data])

    const onHandleReorderGroup = (newOrder) => {
        if (newOrder) {
            const isOrderModified = isOrderUpdated(quizSectionItem?.quiz_categories, newOrder)
            if (!isOrderModified) {
                setIsSectionCategoryOrderUpdated(false)
                return;
            }
            if (isOrderModified) {
                setIsSectionCategoryOrderUpdated(true)
                dispatcher(setLmsQuizDetailData({
                    ...lmsQuizDetail?.data,
                    quiz_sections: lmsQuizDetail?.data?.quiz_sections?.map((quizSectionContent) => (
                        (quizSectionContent?.id === quizSectionItem?.id)
                            ? { ...quizSectionContent, quiz_categories: newOrder }
                            : quizSectionContent
                    ))
                }))
            }
        }
    }

    const onHandleUpdateQuizSectionCategoryOrder = () => {
        if (isSectionCategoryOrderUpdated && (quizSectionItem?.quiz_categories?.length > 0)) {
            const body = {
                order: quizSectionItem?.quiz_categories?.map((quizSectionItem) => ({
                    id: quizSectionItem?.id
                }))
            }
            dispatcher(updateLmsQuizCategoryOrderList(body, { quiz_section_id: quizSectionItem?.id }))
            setIsSectionCategoryOrderUpdated(false)
        }
    }

    return (
        <div className={"flex flex-col gap-5 p-4 bg-primary-light rounded-lg transition-transform ease-in-out duration-300"}>
            <LmsTextAreaDescription
                isLoading={updateLmsQuizSection?.isLoading}
                isShowDescriptionLabel={false}
                description={quizSectionItem?.description}
                descriptionPlaceholder={"Description (optional)"}
                rows={2}
                autoFocus={true}
                descriptionInputStatus={sectionDescriptionStatus}
                setDescriptionInputStatus={setSectionDescriptionStatus}
                onHandleSaveDescription={onHandleSaveDescription}
            />
            {(quizSectionItem?.quiz_categories && (quizSectionItem?.quiz_categories?.length > 0)) &&
                <Reorder.Group
                    className={'w-full flex flex-col gap-3'}
                    values={quizSectionItem?.quiz_categories}
                    onReorder={onHandleReorderGroup}
                >
                    {quizSectionItem?.quiz_categories?.map((quizSectionCategoryItem, index) => {
                        switch (quizSectionCategoryItem?.type) {
                            case lmsQuizCategoryEnum.QUESTION.value:
                                return (
                                    <Reorder.Item
                                        key={quizSectionCategoryItem?.id}
                                        value={quizSectionCategoryItem}
                                        onDragEnd={onHandleUpdateQuizSectionCategoryOrder}
                                    >
                                        <QuizSectionQuestion quizSectionCategoryItem={quizSectionCategoryItem} />
                                    </Reorder.Item>
                                )
                            case lmsQuizCategoryEnum.ARTICLE.value:
                                return (
                                    <Reorder.Item
                                        key={quizSectionCategoryItem?.id}
                                        value={quizSectionCategoryItem}
                                        onDragEnd={onHandleUpdateQuizSectionCategoryOrder}
                                    >
                                        <QuizSectionArticle quizSectionCategoryItem={quizSectionCategoryItem} />
                                    </Reorder.Item>
                                )
                            case lmsQuizCategoryEnum.IMAGE.value:
                                if (quizSectionCategoryItem?.resource?.resource?.resource?.type === resourceFileTypeEnum.IMAGE.value) {
                                    return (
                                        <Reorder.Item
                                            key={quizSectionCategoryItem?.id}
                                            value={quizSectionCategoryItem}
                                            onDragEnd={onHandleUpdateQuizSectionCategoryOrder}
                                        >
                                            <QuizSectionImage quizSectionCategoryItem={quizSectionCategoryItem} />
                                        </Reorder.Item>
                                    )
                                }
                                if (quizSectionCategoryItem?.resource?.resource?.resource?.type === resourceFileTypeEnum.VIDEO.value) {
                                    return (
                                        <Reorder.Item
                                            key={quizSectionCategoryItem?.id}
                                            value={quizSectionCategoryItem}
                                            onDragEnd={onHandleUpdateQuizSectionCategoryOrder}
                                        >
                                            <QuizSectionVideo quizSectionCategoryItem={quizSectionCategoryItem} />
                                        </Reorder.Item>
                                    )
                                }
                            default:
                                return null
                        }
                    })}
                </Reorder.Group>
            }
            <EmptyQuizSectionContainer quizSectionItem={quizSectionItem} />
        </div>
    )
};

export default LmsQuizSectionCategoryList