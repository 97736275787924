import { useState, useCallback } from "react";

import UpdateLmsInputText from "pages/auth/edulyteLms/commonComponents/UpdateLmsInputText";
import LmsEditorDescription from "pages/auth/edulyteLms/commonComponents/LmsEditorDescription";

import LectureItemContainer from "./LectureItemContainer";
import LectureSideBar from "../lectureSideBar/LectureSideBar";

import { updateLmsLectureDetail } from "redux/edulyteLms/lmsLecture/lmsLecture.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';

const EditLMSLectureBuilderPage = () => {
    const { tutorLmsLectureDetail, modifyLmsLectureDetail } = useAppState((state) => state.lms.lmsLecture)

    const dispatcher = useAppDispatcher()

    const [lectureTitleStatus, setLectureTitleStatus] = useState("")

    const onHandleSaveTitle = useCallback((editedTitle) => {
        if (tutorLmsLectureDetail?.isLoading || modifyLmsLectureDetail?.isLoading || (editedTitle === tutorLmsLectureDetail?.data?.title)) return;

        setLectureTitleStatus("Saving...")

        const body = {
            title: editedTitle
        }
        const payload = {
            isShowToast: false
        }
        dispatcher(updateLmsLectureDetail(tutorLmsLectureDetail?.data?.lecture_id, body, payload))

        setTimeout(() => {
            setLectureTitleStatus("")
        }, 1000)
    }, [tutorLmsLectureDetail?.data])

    const onHandleSaveDescription = useCallback((editedDescription) => {
        if (tutorLmsLectureDetail?.isLoading || modifyLmsLectureDetail?.isLoading || (editedDescription === tutorLmsLectureDetail?.data?.description)) return;

        const body = {
            description: editedDescription
        }
        const payload = {
            isShowToast: false
        }
        dispatcher(updateLmsLectureDetail(tutorLmsLectureDetail?.data?.lecture_id, body, payload))
    }, [tutorLmsLectureDetail?.data])

    return (
        <div className={"grid grid-cols-12 gap-3 pb-5"}>
            <div className={'col-start-1 col-span-full lg:col-start-1 lg:col-span-9 space-y-3'}>
                <div className={"w-full flex flex-col gap-3"}>
                    <UpdateLmsInputText
                        isLoading={modifyLmsLectureDetail?.isLoading}
                        isShowInputLabel={true}
                        containerClassName={"mt-3 mb-1"}
                        inputClassName={"font-tagLine font-semibold text-primary-dark text-lg border-b border-text-400"}
                        title={tutorLmsLectureDetail?.data?.title}
                        titleInputStatus={lectureTitleStatus}
                        setTitleInputStatus={setLectureTitleStatus}
                        onHandleSaveTitle={onHandleSaveTitle}
                    />
                    <LmsEditorDescription
                        isLoading={modifyLmsLectureDetail?.isLoading}
                        descriptionPlaceholder={"Description (optional)"}
                        editorContainerClassName={"px-3 py-2 bg-white rounded-lg"}
                        description={tutorLmsLectureDetail?.data?.description}
                        onHandleSaveDescription={onHandleSaveDescription}
                    />
                </div>
                <LectureItemContainer lectureItem={tutorLmsLectureDetail?.data} />
            </div>
            <div className={"hidden lg:block col-start-10 col-span-3"}>
                <div className={"sticky top-16"}>
                    <LectureSideBar />
                </div>
            </div>
        </div>
    )
}

export default EditLMSLectureBuilderPage;