import { addLmsCourseDetailPayload, addLmsCourseSectionDetailPayload, sendLmsCourseInvitationDetailPayload } from "./lmsCourse.const";

export const LMS_COURSE_INITIAL_STATE = {
    lmsCourseList: {
        isLoading: false,
        data: null,
        message: null,
    },
    lmsCourseDetail: {
        isLoading: false,
        data: null,
        message: null,
    },
    addLmsCourseDetail: {
        isLoading: false,
        data: null,
        payload: addLmsCourseDetailPayload,
        message: null
    },
    modifyLmsCourseDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    // lmsCourse sections
    addLmsCourseSectionDetail: {
        isLoading: false,
        data: null,
        payload: addLmsCourseSectionDetailPayload,
        message: null
    },
    lmsCourseSectionDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    lmsCourseSectionList: {
        isLoading: false,
        data: null,
        message: null
    },
    updateLmsCourseSectionDetail: {
        isLoading: false,
        data: null,
        payload: null,
        message: null
    },
    updateOrderLmsCourseSectionDetail: {
        isLoading: false,
        data: null,
        payload: null,
        message: null
    },
    deleteLmsCourseSectionDetail: {
        isLoading: false,
        message: null
    },

    /* lms Course Lesson-Sections */
    addLmsCourseSectionLesson: {
        isLoading: false,
        data: null,
        payload: null,
        message: null
    },
    lmsCourseSectionLesson: {
        isLoading: false,
        data: null,
        message: null
    },
    lmsCourseSectionLessonList: {
        isLoading: false,
        data: null,
        message: null
    },
    updateLmsCourseSectionLesson: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },
    updateOrderLmsCourseSectionLesson: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },
    deleteLmsCourseSectionLesson: {
        isLoading: false,
        message: null
    },

    // lms course tag
    addLmsCourseTagDetail: {
        isLoading: false,
        message: null
    },
    updateLmsCourseTagListOrderDetail: {
        isLoading: false,
        message: null
    },
    deleteLmsCourseTagDetail: {
        isLoading: false,
        message: null
    },

    // lms course language
    addLmsCourseLanguageDetail: {
        isLoading: false,
        message: null
    },
    updateLmsCourseLanguageListOrderDetail: {
        isLoading: false,
        message: null
    },
    deleteLmsCourseLanguageDetail: {
        isLoading: false,
        message: null
    },

    // lms course prof
    addLmsCourseProfDetail: {
        isLoading: false,
        message: null
    },
    updateLmsCourseProfListOrderDetail: {
        isLoading: false,
        message: null
    },
    deleteLmsCourseProfDetail: {
        isLoading: false,
        message: null
    },

    // add lms course learning detail
    addLmsCourseLearningDetail: {
        isLoading: false,
        message: null
    },
    modifyLmsCourseLearningDetail: {
        isLoading: false,
        data: null,
        message: null,
    },
    updateLmsCourseLearningListOrderDetail: {
        isLoading: false,
        message: null
    },
    deleteLmsCourseLearningDetail: {
        isLoading: false,
        message: null
    },

    // add lms course faq detail
    addLmsCourseFaqDetail: {
        isLoading: false,
        message: null
    },
    modifyLmsCourseFaqDetail: {
        isLoading: false,
        data: null,
        message: null,
    },
    updateLmsCourseFaqListOrderDetail: {
        isLoading: false,
        message: null
    },
    deleteLmsCourseFaqDetail: {
        isLoading: false,
        message: null
    },

    // lms course slug detail
    addLmsCourseSlugDetail: {
        isLoading: false,
        message: null
    },
    destroyLmsCourseSlugDetail: {
        isLoading: false,
        message: null
    },

    // lms course instructor detail
    addLmsCourseInstructorDetail: {
        isLoading: false,
        message: null
    },
    modifyLmsCourseInstructorDetail: {
        isLoading: false,
        message: null
    },
    destroyLmsCourseInstructorDetail: {
        isLoading: false,
        message: null
    },

    // lms course student detail
    lmsCourseStudentList: {
        isLoading: false,
        data: null,
        message: null
    },
    addLmsCourseStudentDetail: {
        isLoading: false,
        message: null
    },
    modifyLmsCourseStudentDetail: {
        isLoading: false,
        message: null
    },
    destroyLmsCourseStudentDetail: {
        isLoading: false,
        message: null
    },

    /* student lms course */
    studentLmsCourseList: {
        isLoading: false,
        data: null,
        message: null
    },
    studentLmsCourseDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    studentFavLmsCourseList: {
        isLoading: false,
        data: null,
        message: null
    },
    
    // add student fav lms course detail
    addStudentFavLmsCourseDetail: {
        isLoading: false,
        message: null
    },

    // lms course invitation list
    lmsCourseInvitationList: {
        isLoading: false,
        data: null,
        message: null
    },
    sendLmsCourseInvitationDetail: {
        isLoading: false,
        data: null,
        payload: sendLmsCourseInvitationDetailPayload,
        message: null
    },
    validateLmsCourseInvitationDetail: {
        isLoading: false,
        data: null,
        message: null,
    },
    acceptAndDeclineLmsCourseInvitationDetail: {
        isLoading: false,
        data: null,
        message: null,
    },
    destroyLmsCourseInvitationDetail: {
        isLoading: false,
        message: null
    },
    /* lms course student section lesson */
    addDestroyLmsCourseStudentSectionLesson: {
        isLoading: false,
        message: null
    },

    /* lms course student quiz response */
    addLmsCourseStudentQuizResponseDetail: {
        isLoading: false,
        data: null,
        message: null,
    },
    modifyLmsCourseStudentQuizResponseDetail: {
        isLoading: false,
        message: null,
    },

    /* lms course student note */
    addLmsCourseStudentNoteDetail: {
        isLoading: false,
        data: null,
        message: null,
    },
    lmsCourseStudentNoteList: {
        isLoading: false,
        data: null,
        message: null,
    },
    lmsCourseStudentNoteDetail: {
        isLoading: false,
        data: null,
        message: null,
    },
    modifyLmsCourseStudentNoteDetail: {
        isLoading: false,
        data: null,
        message: null,
    },
    destroyLmsCourseStudentNoteDetail: {
        isLoading: false,
        data: null,
        message: null,
    },

    // lms course page lecture list
    lmsCoursePageLectureList: {
        isLoading: false,
        data: null,
        message: null
    },
    // lms course page article list
    lmsCoursePageArticleList: {
        isLoading: false,
        data: null,
        message: null
    },
    // lms course page quiz list
    lmsCoursePageQuizList: {
        isLoading: false,
        data: null,
        message: null
    },
}