import { baseApiInstance, baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/notification/notification.const"


class NotificationService {
    createSendOtp = async ({body}) => {
        const response = await baseApiInstance().post(
            url.SEND_OTP,
            body
        )

        return response
    }

    createVerifyOtp = async ({body}) => {
        const response = await baseApiInstance().post(
            url.VERIFY_OTP,
            body
        )

        return response
    }

    sendMessageEmail = async ({body}) => {
        const response = await baseAuthApiInstance().post(
            url.SEND_MESSAGE_EMAIL,
            body
        )

        return response
    }

    getNotifications = async ({query}) => {
        const response = await baseAuthApiInstance().get(
            url.GET_NOTIFICATION_LIST,
            { params: query }
        )

        return response
    }

    acknowledgedNotification = async () => {
        const response = await baseAuthApiInstance().post(
            url.ACKNOWLEDGED_NOTIFICATION,
        )
        return response
    }
}

export default new NotificationService()