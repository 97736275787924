import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { cn } from "utils/cn.utils";

import ComponentLoader from "components/loader/ComponentLoader";

import { getOnboardingInterviewDetailByOnboardingId } from "redux/onboarding/onboarding.request";
import { getInterviewDetailByInterviewId } from "redux/interview/interview.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearOnboardingInterviewDetail } from "redux/onboarding/onboarding.slice";
import { resetAddInterviewSessionDetail } from "redux/interview/interview.slice";
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { sessionStatusEnum } from "redux/session/session.const";

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from 'utils/dateTime.utils';

const InterviewSlotSelection = () => {
    const { onboardingInterviewDetail } = useAppState((s) => s.onboarding)
    const { user } = useAppState((state) => state.user)
    const { modal } = useAppState(s => s.local)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const { onboardingId } = useParams()

    useEffect(() => {
        dispatcher(getOnboardingInterviewDetailByOnboardingId(Number(onboardingId)))

        return () => {
            dispatcher(setClearOnboardingInterviewDetail())
        }
    }, [])

    const handleView = (sessionUsers) => {
        const filterSessionUser = sessionUsers?.filter((sessionUser) => (sessionUser?.user?.id === user?.user?.userId))
        if (filterSessionUser?.length > 0) {
            navigate(`${pagesInfo?.SCHEDULE?.pagePath}?sessionId=${filterSessionUser[0]?.sessionId}`)
        }
    }

    const handleSelectSlot = (interviewId) => {
        dispatcher(resetAddInterviewSessionDetail())
        dispatcher(getInterviewDetailByInterviewId(interviewId))
        dispatcher(setModal({
            ...modal,
            [modalConst.CHOOSE_INTERVIEW_SLOT.stateKey]: true
        }))
    }

    const sortInterviews = (interview1, interview2) => {
        const interview1StartTime = dayjs(interview1.session?.startDateTime).utc()
        const interview2StartTime = dayjs(interview2.session?.startDateTime).utc()
        return interview1StartTime.isSameOrBefore(interview2StartTime) ? 1 : -1
    }

    if (onboardingInterviewDetail?.isLoading) {
        return (
            <ComponentLoader isLoading={onboardingInterviewDetail?.isLoading} />
        )
    }

    return (
        <div className="pl-10 space-y-5">
            <div className={"w-full flex flex-col items-start justify-start gap-5"}>
                {onboardingInterviewDetail?.onboardingInterviewDetail?.interviews?.slice(0)?.sort(sortInterviews)?.map((interview, index) => (
                    <div key={index} className={"w-full border border-text-300 rounded-lg px-5 py-3 flex flex-col items-start justify-start gap-3"}>
                        <div className={"w-full flex justify-between items-center gap-2"}>
                            <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                                {interview?.interviewTitle?.slice(0, 40)}
                            </span>
                            {interview?.session &&
                                <span
                                    className={cn(
                                        "rounded-md px-2 py-0.5 cursor-pointer",
                                        "border border-primary-main bg-primary-main",
                                        "font-bodyPri font-normal text-text-50 text-sm text-center hover:text-text-50 hover:bg-secondary-main hover:border hover:border-secondary-main"
                                    )}
                                    onClick={() => handleView(interview?.session?.userSessions)}
                                >
                                    {"View"}
                                </span>
                            }
                            {!interview?.session &&
                                <span
                                    className={cn(
                                        "rounded-md px-2 py-0.5 cursor-pointer",
                                        "border border-primary-main bg-primary-main",
                                        "font-bodyPri font-normal text-text-50 text-sm text-center hover:text-text-50 hover:bg-secondary-main hover:border hover:border-secondary-main"
                                    )}
                                    onClick={() => handleSelectSlot(interview?.interviewId)}
                                >
                                    {"Select a slot"}
                                </span>
                            }
                        </div>
                        {interview?.session &&
                            <div className={"w-full flex items-center justify-between gap-3"}>
                                <div className={"flex items-center justify-start gap-10"}>
                                    <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                        {dayjs(interview?.session?.startDateTime)?.tz(timeZone)?.format("ddd, DD, MMM, YYYY")}
                                    </span>
                                    <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                        {dayjs(interview?.session?.startDateTime)?.tz(timeZone).format("HH:MM A")}
                                    </span>
                                </div>
                                <div className={`font-bodyPri font-normal text-base text-${sessionStatusEnum[interview?.session?.status?.toUpperCase()]?.darkColor}`}>
                                    {sessionStatusEnum[interview?.session?.status?.toUpperCase()]?.label}
                                </div>
                            </div>
                        }
                    </div>
                ))}
            </div>
            {(!onboardingInterviewDetail?.onboardingInterviewDetail?.interviews || onboardingInterviewDetail?.onboardingInterviewDetail?.interviews?.length === 0) &&
                <div className={"border border-text-300 rounded-lg px-5 py-3"}>
                    <div className={"flex justify-center items-center gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-sm text-center"}>
                            {"No slots yet, under review."}
                        </span>
                    </div>
                </div>
            }
        </div>
    )
}

export default InterviewSlotSelection;