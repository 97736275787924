import {
    Container,
    Text,
} from './HDividerWithTextStyle';

import { HorizontalLine } from 'components/generalComponent/StyledComponent';

const HDividerWithText = ({ text }) => {
    return (
        <Container>
            <HorizontalLine />
            <Text>{text}</Text>
            <HorizontalLine />
        </Container>
    )
}

export default HDividerWithText;
