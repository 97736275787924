import * as React from "react";
import { cn } from "utils/cn.utils";

import { FaChevronRight } from "react-icons/fa";

import { useAppState } from "hooks/useStore";

export const SegmentCategoryView: React.FC = () => {
  const { coursePreviewDetail } = useAppState((s) => s.course)

  return (
    <div
      className={cn(
        "relative w-full flex p-2 space-x-2 text-white",
        "items-center w-min pr-10 md:overflow-x-hidden"
      )}
    >
      <p className="hidden text-xs font-medium md:block md:text-sm">
        {"Browse"}
      </p>
      <div className="hidden md:block">
        <FaChevronRight className="w-3 h-3 text-slate-200" />
      </div>
      <p className="text-xs font-medium md:text-sm whitespace-nowrap">
        {coursePreviewDetail?.data?.segment || "None"}
      </p>
      <div>
        <FaChevronRight className="w-3 h-3 text-slate-200" />
      </div>
      <p className="text-xs font-medium md:text-sm whitespace-nowrap">
        {coursePreviewDetail?.data?.category || "None"}
      </p>
    </div>
  );
};