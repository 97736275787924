import { useState } from "react";

import { FiEdit } from 'react-icons/fi';

import { profileEditBtn } from 'pages/auth/profile/data';

import SaveAndCancelBtn from 'pages/auth/profile/commonComponents/SaveAndCancelBtn';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { updateStudentUserDetailByUserId } from 'redux/student/student.request';

const MIN_CHARACTER_LENGTH = 50
const MAX_CHARACTER_LENGTH = 700


const MyLearningStyle = ({ editBtnConst, setEditBtnConst }) => {
  const { user } = useAppState((state) => state.user);
  const { studentUserDetail } = useAppState((state) => state.student);

  const dispatcher = useAppDispatcher()

  const [inputLearningStyleBio, setInputLearningStyleBio] = useState('')

  const onHandleSave = (key, value) => {
    if (!(inputLearningStyleBio.length >= MIN_CHARACTER_LENGTH && inputLearningStyleBio.length <= MAX_CHARACTER_LENGTH)) {
      alert("Please enter between 50 to 700 characters")
      return;
    }

    dispatcher(updateStudentUserDetailByUserId(user?.user?.userId, { [key]: value }))

    setEditBtnConst(null)
  }

  return (
    <div className="">
      <div className="flex items-center gap-2 px-5">
        <div className="text-xl text-text-900 font-medium font-bodyPri tracking-wide">
          {"My learning style:"}
        </div>
        {!editBtnConst &&
          <FiEdit className="text-lg text-text-500 cursor-pointer"
            onClick={() => {
              setInputLearningStyleBio(studentUserDetail?.studentUserDetail?.learningStyleBio?.slice(0, 700)?.toString() || "")
              setEditBtnConst(profileEditBtn.learningStyleBio.value)
            }}
          />
        }
      </div>

      <div className="w-full">
        {editBtnConst !== profileEditBtn.learningStyleBio.value &&
          <div className="px-5 py-2">
            <span className="font-bodyPri font-normal tracking-wide text-text-800 text-base">
              {studentUserDetail?.studentUserDetail?.learningStyleBio}
            </span>
          </div>
        }
        {editBtnConst === profileEditBtn.learningStyleBio.value &&
          <div className="w-full flex flex-col items-start justify-start gap-2">
            <div className="w-full flex flex-col justify-center gap-1">
              <textarea
                className="w-full p-5 border-2 border-secondary-main border-dashed focus:outline-none font-bodyPri font-normal text-lg text-text-600 tracking-wide"
                rows={5}
                minLength={MIN_CHARACTER_LENGTH}
                maxLength={MAX_CHARACTER_LENGTH}
                value={inputLearningStyleBio}
                onChange={(event) => setInputLearningStyleBio(event.target.value)}
              />
            </div>
            <div className={"w-full flex justify-between"}>
              <span className="font-bodyPri font-normal tracking-wide text-red-500 text-xs">
                {"Please enter between 50 and 700 characters"}
              </span>
              <span className="flex justify-end font-bodyPri font-normal text-text-800 text-xs">
                {inputLearningStyleBio.length + "/" + MAX_CHARACTER_LENGTH}
              </span>
            </div>
            <SaveAndCancelBtn
              onCancel={() => setEditBtnConst(null)}
              onSave={() => onHandleSave(editBtnConst, inputLearningStyleBio)}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default MyLearningStyle;