import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { BsInfoCircle } from 'react-icons/bs';

import { tooltipPlacementConst } from './data,';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#212121',
    color: '#fafafa',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #212121',
  },
}));


const MyTooltip = ({ tooltip = "", placement = tooltipPlacementConst.RIGHT_START.value, arrow = false }) => {

  return (
    <HtmlTooltip
      placement={placement}
      arrow={arrow}
      title={
        <>
          {tooltip}
        </>
      }
    >
      <span>
        <BsInfoCircle className="inline text-md text-text-900 hover:text-text-900" />
      </span>
    </HtmlTooltip>
  );
};

export default MyTooltip;
