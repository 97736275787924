import tw, { styled } from 'twin.macro';
import { Page, PageContainer } from 'components/generalComponent/StyledComponent';

import { pagesInfo, sideTopBarPagesName, hiddenNavBarPageName, staticNavBarPageName } from 'utils/pagesInfo';

export const PageStyle = styled(Page)``;

export const BarContainer = styled.div`
    ${tw`
        sticky top-0 z-50 w-full shadow
    `}
    ${({ pageName }) => [pagesInfo.HOME.pageName].includes(pageName) && tw`
        shadow-none
    `}
    ${({ pageName }) => staticNavBarPageName.includes(pageName) && tw`
        static
    `}
    ${({ pageName }) => hiddenNavBarPageName.includes(pageName) && tw`
        hidden
    `}
`;

export const PageContainerStyle = styled(PageContainer)`
    ${tw`
        min-h-screen relative
    `}
    ${({ pageName }) => sideTopBarPagesName.includes(pageName) && tw`
        lg:(flex-row)
    `}
`;