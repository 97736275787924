import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { FaSpinner } from 'react-icons/fa';

import { createCourseDetail, getCourseTemplateList } from "redux/course/course.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setCourseTemplateDetailPayload, setClearAddCourseDetail } from 'redux/course/course.slice';
import { tempCourseType } from 'redux/course/course.const';

import { generateQueryParams } from 'utils/generators.utils';
import { pagesInfo } from 'utils/pagesInfo';


const SelectTemplate = () => {
    const { user } = useAppState((state) => state.user)
    const { courseTemplateDetail, courseTemplateList, addCourseDetail } = useAppState((state) => state.course)
    const filteredTempCourseList = courseTemplateList?.courseTemplateList

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.url || `/class/${addCourseDetail?.addCourseDetail?.id}/edit?step=${addCourseDetail?.addCourseDetail?.step}`
    // const from = location.state?.from?.url || `/class/b283a306-0b94-4ab6-90e7-93288c8368ef/edit`

    const [myTemplate, setMyTemplate] = useState(null);

    const handleTemplate = (template) => {
        setMyTemplate(template)
    }
    useEffect(() => {
        const query = generateQueryParams(location.search)
        if (courseTemplateList?.courseTemplateList && query && query?.classType) {
            dispatcher(setCourseTemplateDetailPayload({
                type: query?.classType
            }))
        }
    }, [location.search])

    useEffect(() => {
        if (filteredTempCourseList) {
            setMyTemplate(filteredTempCourseList[0])
        }
    }, [filteredTempCourseList])

    useEffect(() => {
        if (addCourseDetail?.addCourseDetail) {
            dispatcher(setClearAddCourseDetail())
            navigate(from, { replace: true })
        }
    }, [addCourseDetail?.addCourseDetail])

    const onHandleEditCourse = (template) => {
        if (addCourseDetail?.isLoading) {
            return;
        }

        // navigate(`${pagesInfo.CREATE_COURSE.pagePath}/1/edit?step=1`)
        dispatcher(createCourseDetail({ courseTemplate: template?.id, tutor: user?.user?.tutor?.tutorId }))
    }

    const handleOnChangeTempCourseType = (courseType) => {
        dispatcher(setCourseTemplateDetailPayload({
            type: courseType
        }))

        if (courseType === tempCourseType?.ALL?.value) {
            navigate(`${pagesInfo?.TEMPLATE?.pagePath}`)
            return;
        }
        navigate(`${pagesInfo?.TEMPLATE?.pagePath}?classType=${courseType}`)
    }


    return (
        <div className={"space-y-3"}>
            <div className="w-full px-4 py-5 bg-white shadow-md rounded-lg space-y-5">
                <div className={"flex flex-row gap-3 self-start"}>
                    {Object.keys(tempCourseType).map((key, index) => {
                        return (
                            <div key={index} onClick={() => handleOnChangeTempCourseType(tempCourseType[key].value)}
                                className={cn(
                                    "w-24 py-1 font-buttons font-normal text-base",
                                    "flex items-center justify-center rounded-full cursor-pointer",
                                    tempCourseType[key].value === courseTemplateDetail?.courseTemplateDetailPayload?.type ?
                                        "bg-primary-main text-text-50" :
                                        "bg-primary-light text-primary-main hover:bg-primary-main hover:text-text-50"
                                )}
                            >
                                {tempCourseType[key].label}
                            </div>
                        )
                    })}
                </div>
                {courseTemplateDetail?.courseTemplateDetailPayload?.type === tempCourseType?.ALL?.value &&
                    <p className="text-base font-normal text-text-700 text-center self-centre">
                        {"Select & customise a template to create your own classes."}
                    </p>
                }
                {courseTemplateDetail?.courseTemplateDetailPayload?.type === tempCourseType?.ONE_ON_ONE?.value &&
                    <p className="text-base font-normal text-text-700 text-center self-centre">
                        {`Select & customise a template to create your own ${tempCourseType?.ONE_ON_ONE?.label} classes.`}
                    </p>
                }
                {courseTemplateDetail?.courseTemplateDetailPayload?.type === tempCourseType?.GROUP?.value &&
                    <p className="text-base font-normal text-text-700 text-center self-centre">
                        {`Select & customise a template to create your own ${tempCourseType?.GROUP?.label} classes.`}
                    </p>
                }
                <div className={cn(
                    "grid grid-flow-col-dense gap-8 px-4 py-4 overflow-hidden overflow-x-scroll",
                    "scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                )}>
                    {courseTemplateList?.courseTemplateList?.map((templateItem, idx) => (
                        <div key={idx} onClick={() => handleTemplate(templateItem)} className={cn(
                            "relative flex flex-col content-between justify-between rounded-lg shadow-lg bg-black w-52 aspect-video cursor-pointer",
                            "hover:scale-105 hover:transition duration-200 hover:shadow-lg",
                            templateItem?.id === myTemplate?.id ?
                                "ring-offset-2 ring-4 ring-secondary-dark" :
                                "ring-offset-2 ring-4 ring-white"
                        )}>
                            <img src={templateItem?.thumbnailPicUrl} alt="template-thumbnail"
                                className="object-cover w-full h-full rounded-lg opacity-90"
                            />
                            <div className={"absolute w-full h-full rounded-lg overflow-hidden flex justify-center items-center"}>
                                <span className={"bg-black bg-opacity-50 px-4 py-2 rounded-sm text-text-50 font-bodyPri font-normal text-base"}>
                                    {templateItem?.title}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {myTemplate &&
                <div className='w-full px-4 py-5 bg-white shadow-md rounded-lg space-y-3 flex flex-col'>
                    <div className={cn(
                        "cursor-pointer px-3 py-2 flex items-center justify-center rounded-xl self-center",
                        "font-buttons font-normal text-base text-primary-main ring-2 ring-primary-main",
                        "hover:bg-primary-main hover:text-text-50 group"
                    )} onClick={() => onHandleEditCourse(myTemplate)}>
                        {!addCourseDetail?.isLoading &&
                            <span>{'Customise This Template'}</span>
                        }
                        {addCourseDetail?.isLoading
                            && <FaSpinner className={"text-2xl animate-spin text-primary-main group-hover:text-text-50"} />
                        }
                    </div>
                    {addCourseDetail?.errorMsg &&
                        <div className={"w-full flex items-center justify-center"}>
                            <span className={"font-bodyPri font-normal text-sm text-red-500"}>
                                {addCourseDetail?.errorMsg}
                            </span>
                        </div>
                    }
                    <img src={myTemplate?.previewPicUrl} alt={'template-preview'}
                        className={"w-full"}
                    />
                </div>
            }
        </div>
    )
}

export default SelectTemplate