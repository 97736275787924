import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'

import { motion } from 'framer-motion';

import PageHeader from 'components/pageHeader/PageHeader';
import Pagination from 'components/pagination/Pagination';

import { pageHeading, searchParamsInfo } from './data'
import MyDownloadedCard, { MyDownloadedCardLoader } from './components/myDownloadedCard';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getUserDownloadsList } from 'redux/downloads/downloads.request';

import { useTitle } from 'hooks/useTitle';
import { useAppDispatcher, useAppState } from 'hooks/useStore';
import {  setModal } from "redux/local/local.slice";
import { resetUserDownloadsList } from 'redux/downloads/downloads.slice';
import { modalConst } from 'redux/local/local.const';

import { pagesInfo } from 'utils/pagesInfo';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';

const RECORDS = 15

const MyDownloads = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((s) => s.user)
    const { userDownloadsList } = useAppState((s) => s.downloads)

    const dispatcher = useAppDispatcher()
    const [title, setTitle] = useTitle()
    const navigate = useNavigate()
    const location = useLocation()

    let searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.MY_DOWNLOADS))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "My Downloads | Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (searchQueryParams?.get(searchParamsInfo.download_id.key)) {
            dispatcher(setModal({
                ...modal,
                [modalConst.myDownloadDetailModal.key]: {
                    ...modal[modalConst.myDownloadDetailModal.key],
                    isVisible: true,
                    title: "Download"
                }
            }))
        }
    }, [searchQueryParams.get(searchParamsInfo.download_id.key)])

    useEffect(() => {
        if (user?.user?.userId) {
            dispatcher(getUserDownloadsList(user?.user?.userId, { page: 1, records: RECORDS }))
        }
        return () => {
            dispatcher(resetUserDownloadsList())
        }
    }, [user?.user?.userId])

    return (
        <div className='h-full w-full p-3 mx-auto min-h-screen space-y-3'>
            <PageHeader pageHeading={pageHeading} />
            <div className='bg-white p-5 rounded-lg '>
                {userDownloadsList?.isLoading && (
                    <div className={"grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"}>
                        {new Array(RECORDS).fill("").map((_, index) => (
                            <MyDownloadedCardLoader key={index} isLoading={userDownloadsList?.isLoading} />
                        ))}
                    </div>
                )}
                {(userDownloadsList?.data?.result?.length === 0 || userDownloadsList?.message) &&
                    <div className='flex flex-col gap-3 items-center justify-center font-bodyPri p-5'>
                        <span className='text-center font-bodyPri tracking-wide text-lg'>
                            {userDownloadsList?.message || "No Downloads Found!"}
                        </span>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => window.open(`${EDULYTE_WEB_DOMAIN_URL}/downloads`, "_blank")}
                            className="px-5 py-1 ease-in-out duration-300 rounded-full border text-lg font-medium text-md bg-white text-text-700 border-background-dark hover:text-primary-main hover:border-primary-main"
                        >
                            View Downloads
                        </motion.button>
                    </div>
                }
                <div className={"grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"}>
                    {userDownloadsList?.data?.result?.map((downloads, index) => (
                        <MyDownloadedCard key={index} item={downloads} />
                    ))}
                </div>
            </div>
            {(userDownloadsList?.data && (userDownloadsList?.data?.pagination?.totalPages > 1)) && (
                <div className="w-full flex justify-center items-center">
                    <Pagination
                        page={userDownloadsList?.data?.pagination?.page}
                        totalPages={userDownloadsList?.data?.pagination?.totalPages}
                        onChangePage={(page) => navigate(`${pagesInfo.MY_DOWNLOADS.pagePath}/downloads?pn=${page}`)}
                    />
                </div>
            )}
        </div>
    )
}

export default MyDownloads
