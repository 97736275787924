import React from "react";
import { useAppState } from "hooks/useStore";

const PricingPage = () => {
    const {user} = useAppState((state) => state.user);
    return (
        <stripe-pricing-table
            pricing-table-id="prctbl_1P4bpdD3hA6KTc2NwO2qnGea"
            publishable-key="pk_test_51JA6dtD3hA6KTc2Ntp9V9pJIlUNCZAZr0gWnnJMpwb64m8GqY9zmB3LdBgdEeomqUZMuFgOkc9oZnnNPYaXEj8e7002LmCeZr0"
            customer-email={user?.user?.email}
        >
        </stripe-pricing-table>
    );
};

export default PricingPage;
