import { memo } from 'react';

import { motion, AnimatePresence } from 'framer-motion';
import { Checkbox } from '@mui/material'

import { IoMdArrowDropdown } from 'react-icons/io';
import { BsThreeDotsVertical } from 'react-icons/bs'

import ComponentLoader from 'components/loader/ComponentLoader'

import ToolTipView from 'components/tooltipView';
import SelectIcon from './SelectIcon';

import { USER_DEMO_PROFILE } from 'redux/user/user.const';

import { cn } from 'utils/cn.utils';

const headStyle = "p-3 text-left font-medium text-text-900 tracking-wider";
const listStyle = "px-3 py-2 truncate ";
const buttonStyles = {
    backgroundColor: "transparent",
    color: "#424242",
    textTransform: "none",
    borderRadius: "25px",
    padding: "5px 1px",
}


const LabelIncludes = ({ contactLabels = [] }) => {

    return !!contactLabels?.length ? (
        <div className={"flex flex-col gap-1.5 px-2 py-1"} onClick={(event) => event.stopPropagation()}>
            {contactLabels?.slice(1,)?.map((item, index) => (
                <span
                    key={index}
                    className={"font-bodyPri font-normal text-base capitalize"}
                    style={{ color: item?.label?.color }}
                >
                    {item?.label?.name}
                </span>
            ))}
        </div>
    ) : null;
}

const ContactTable = memo(({
    isLoading = false,
    rows = [],
    isAllContactsChecked = false,
    dropdownMenuItemsConst = [],
    menuItemsConst = [],
    selectedContactList = [],
    onHandleSelectAllContacts,
    handleOptionSelect,
    handelViewContact,
    onHandleSelectContactItem,
    onHandleContactSelection
}) => {
    return (
        <div className="flex flex-col w-full font-bodyPri text-text-900 rounded-lg overflow-hidden overflow-x-auto shadow text-sm md:text-base space-y-5">
            <table className="w-full divide-y divide-gray-200 table-auto">
                <AnimatePresence>
                    {isAllContactsChecked &&
                        <thead className="bg-primary-light">
                            <motion.tr
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <th colSpan={4}>
                                    <div className={"flex items-center gap-10"}>
                                        <div className={"pl-1 py-3 flex items-center gap-5 text-left text-text-900"}>
                                            <span className={"relative"}>
                                                <ToolTipView content={"Select All"}>
                                                    <Checkbox
                                                        checked={isAllContactsChecked}
                                                        onChange={onHandleSelectAllContacts}
                                                    />
                                                </ToolTipView>
                                                <span className={"absolute left-5 top-1.5"}>
                                                    <SelectIcon
                                                        buttonLabel={<IoMdArrowDropdown className="text-lg" />}
                                                        menuItems={dropdownMenuItemsConst}
                                                        onSelectOption={onHandleContactSelection}
                                                    />
                                                </span>
                                            </span>
                                            <span className={"font-normal text-primary-main text-base"}>
                                                {`${selectedContactList?.length} selected`}
                                            </span>
                                        </div>
                                    </div>
                                </th>
                                <th className={headStyle}><span className={"invisible"}>{"Email"}</span></th>
                                <th className={headStyle}><span className={"invisible"}>{"Email"}</span></th>
                                <th className={headStyle}><span className={"invisible"}>{"Email"}</span></th>
                                {/* <th className={headStyle}><span className={"invisible"}>{"Email"}</span></th> */}
                            </motion.tr>
                        </thead>
                    }
                    {!isAllContactsChecked &&
                        <thead className="bg-primary-light">
                            <motion.tr
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <th className={"pl-1 py-3 max-w-5 text-left text-text-900"}>
                                    <ToolTipView content={"Select All"}>
                                        <Checkbox
                                            checked={isAllContactsChecked}
                                            onChange={onHandleSelectAllContacts}
                                        />
                                    </ToolTipView>
                                </th>
                                <th className={"p-3 pl-1 text-left font-medium text-text-900 tracking-wider"}>
                                    {"Name"}
                                </th>
                                <th className={headStyle}>{"Email"}</th>
                                {/* <th className={headStyle}>{"Country"}</th> */}
                                <th className={headStyle}>{"Phone"}</th>
                                <th className={headStyle}>{"Labels"}</th>
                                <th className={headStyle}>{"Status"}</th>
                                <th className={headStyle}>{"Action"}</th>
                            </motion.tr>
                        </thead>
                    }
                </AnimatePresence>
                <tbody className={"bg-white divide-y divide-gray-200"}>
                    {isLoading &&
                        <tr>
                            <td colSpan={7}>
                                <ComponentLoader isLoading={true} />
                            </td>
                        </tr>
                    }
                    {(!isLoading && (rows?.length > 0))
                        && rows?.map((item, index) => (
                            <tr
                                key={index}
                                className={cn(
                                    "hover:bg-primary-light cursor-pointer ease-in-out duration-200",
                                    (index % 2 === 0) ? "bg-white" : "bg-background-light"
                                )}
                                onClick={() => handelViewContact(item)}
                            >
                                <td className={"pl-1 py-3 max-w-5"}>
                                    <Checkbox
                                        checked={selectedContactList?.includes(item?.id)}
                                        onClick={(event) => onHandleSelectContactItem(event, item)}
                                    />
                                </td>
                                <td className={"min-w-[160px] pr-3 pl-1 py-2 truncate"}>
                                    <div className="flex items-center gap-3">
                                        <div className="w-10 h-10 rounded-full border border-primary-main">
                                            <img
                                                className="w-full h-full rounded-full object-cover"
                                                src={item?.picture_url || USER_DEMO_PROFILE}
                                            />
                                        </div>
                                        <div className={"truncate max-w-[160px] capitalize"}>
                                            {`${item?.title && (item?.title + ".")} ${item?.first_name} ${item?.last_name}`}
                                            <p className="text-xs truncate capitalize">{item?.company}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className={listStyle + "max-w-[150px]"}>{item?.email?.email || "-"}</td>
                                {/* <td className={listStyle + "max-w-[150px]"}>{item?.address?.country || "-"}</td> */}
                                <td className={listStyle + "max-w-[120px]"}>
                                    {(item?.phone?.isd && item?.phone?.phone)
                                        ? `+${item?.phone?.isd}-${item?.phone?.phone}`
                                        : "-"
                                    }
                                </td>
                                <td className={listStyle + "max-w-[100px] capitalize"}>
                                    {!!item?.contact_labels?.length
                                        ? (item?.contact_labels?.length > 1)
                                            ? <div className={"flex items-center gap-1 capitalize"}>
                                                <span
                                                    className={"font-bodyPri font-normal text-base capitalize"}
                                                    style={{ color: item?.label?.color }}
                                                >
                                                    {item?.contact_labels?.slice(0, 1)?.map((item) => item?.label?.name)}
                                                </span>
                                                <ToolTipView
                                                    content={<LabelIncludes contactLabels={item?.contact_labels} />}
                                                    allowHtml={true}
                                                    theme={"light"}
                                                    interactive={true}
                                                >
                                                    <div className={"px-1.5 py-0.5 rounded-full bg-primary-light"}>
                                                        <span className={"text-text-900 text-sm text-center"}>
                                                            {`+${item?.contact_labels?.length - 1}`}
                                                        </span>
                                                    </div>
                                                </ToolTipView>
                                            </div>
                                            : <span
                                                className={"font-bodyPri font-normal text-base capitalize"}
                                                style={{ color: item?.label?.color }}
                                            >
                                                {item?.contact_labels?.map((item) => item?.label?.name)}
                                            </span>
                                        : "-"
                                    }
                                </td>
                                <td
                                    className={listStyle + "max-w-[100px]"}
                                    style={{ color: item?.status?.color }}
                                >
                                    {item?.status?.name || "-"}
                                </td>
                                <td className={listStyle + "max-w-[20px]"} onClick={(event) => event.stopPropagation()}>
                                    <SelectIcon
                                        buttonLabel={<BsThreeDotsVertical className="hover:text-secondary-dark" />}
                                        menuItems={menuItemsConst}
                                        buttonStyles={buttonStyles}
                                        onSelectOption={(option) => handleOptionSelect(option, item?.id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    {!isLoading && (!rows || (rows?.length === 0)) &&
                        <tr className={""}>
                            <td colSpan={7} className={"w-full bg-white rounded-md p-5"}>
                                <div className={"flex items-center justify-center"}>
                                    <span className={"font-bodyPri font-medium text-text-800 text-base tracking-wide whitespace-nowrap"}>
                                        {"No Data Found!"}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )
})

export default ContactTable;