import {
    LeftNavLinksContainer,
    LogoContainer,
    LogoAnchorLink,
    // BetaAnchorLink,
} from './NavBarStyle';

import CenterNavLinks from './CenterNavLinks';
// import LearnersDropdownMenu from "./LearnersDropdownMenu";

import { useAppState } from 'hooks/useStore';
import { userRoles } from 'redux/auth/auth.const';

import { pagesInfo } from 'utils/pagesInfo';

const LeftNavLinks = ({ isScrolled }) => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { locals } = useAppState((state) => state.local)
    const { session } = useAppState((state) => state.auth)
    const pageName = currentPageInfo.pageName;

    return (
        <LeftNavLinksContainer>
            <LogoContainer>
                {/* <LogoLink to={pagesInfo.HOME.pagePath}
                    isScrolled={isScrolled}
                >
                    {'Edulyte'}
                </LogoLink> */}
                <LogoAnchorLink href={"https://www.edulyte.com"}
                    isScrolled={isScrolled}
                >
                    {'Edulyte'}
                </LogoAnchorLink>
                {/* <BetaAnchorLink href={"https://support.edulyte.com/portal/en/kb/articles/beta-disclaimer"}>
                    {"BETA"}
                </BetaAnchorLink> */}
            </LogoContainer>

            {/* {((pageName !== pagesInfo?.TUTOR_ONBOARDING_S1?.pageName) && (pageName !== pagesInfo?.TUTOR_ONBOARDING_S2?.pageName)) &&
                <LearnersDropdownMenu isScrolled={isScrolled} />
            } */}

            {((pageName === pagesInfo.HOME.pageName) && ((!session?.isLoggedIn) || (session?.isLoggedIn && (![userRoles?.TUTOR?.value]?.includes(locals?.userRole))))) &&
                <CenterNavLinks isScrolled={isScrolled}/>
            }
        </LeftNavLinksContainer>
    )
}

export default LeftNavLinks;