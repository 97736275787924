import { BiHome } from "react-icons/bi";
import { RiBuilding2Line } from "react-icons/ri";
import { BiMessageRoundedDots } from "react-icons/bi";

import { crmContactLabelsEnum, titleOptionsEnum } from "redux/crm/crm.const";

export const crmDetailBtnConst = {
    title: {
        label: "Title",
        key: "title",
        message: "Please select title"
    },
    first_name: {
        label: "First name",
        key: "first_name",
        message: "Please enter first name"
    },
    last_name: {
        label: "Last name",
        key: "last_name",
        message: "Please enter last name"
    },
    date_of_birth: {
        label: "Date of Birth",
        key: "date_of_birth",
        message: "Please select date of birth"
    },
    company: {
        label: "Company",
        key: "company",
        message: "Please enter company name"
    },
    department: {
        label: "Department",
        key: "department",
        message: "Please enter department"
    },
    job_title: {
        label: "Job Title",
        key: "job_title",
        message: "Please enter job title"
    },
    category: {
        label: "Category",
        key: "category",
        message: "Please select category"
    },
    status: {
        label: "Status",
        key: "status",
        message: "Please select status"
    },
    labels: {
        label: "Labels",
        key: "labels",
        message: "Please enter label detail"
    },
    phones: {
        label: "Phones",
        key: "phones",
        message: "Please enter phone details"
    },
    emails: {
        label: "Emails",
        key: "emails",
        message: "Please enter email details"
    },
    socials: {
        label: "Socials",
        key: "socials",
        message: "Please enter social details"
    },
    addresses: {
        label: "Addresses",
        key: "addresses",
        message: "Please enter address details"
    },
    country: {
        label: "Country",
        key: "country",
        message: "Please select country"
    },
    state: {
        label: "State",
        key: "state",
        message: "Please enter state name"
    },
    city: {
        label: "City",
        key: "city",
        message: "Please enter city name"
    },
    street_line_1: {
        label: "Street address line 1",
        key: "street_line_1",
        message: "Please enter address details"
    },
    street_line_2: {
        label: "Street address line 2",
        key: "street_line_2",
        message: "Please enter address details"
    },
    notes: {
        label: "Note",
        key: "note",
        message: "Please enter note title and description"
    }
}

export const crmContactAddressInputConst = {
    STREET_LINE_1: {
        label: "Street address line 1",
        value: "street_line_1"
    },
    STREET_LINE_2: {
        label: "Street address line 2",
        value: "street_line_2"
    },
    STATE: {
        label: "State",
        value: "state"
    },
    CITY: {
        label: "City",
        value: "city"
    },
    POST_OFFICE: {
        label: "Po box",
        value: "post_office"
    },
    ZIP: {
        label: "Zip",
        value: "zip"
    },
}

export const contactLabelIcons = {
    home: {
        key: "home",
        label: "Home",
        icon: BiHome,
    },
    work: {
        key: "work",
        label: "Work",
        icon: RiBuilding2Line,
    },
    other: {
        key: "other",
        label: "Other",
        icon: BiMessageRoundedDots,
    },
};

export const sortDefaultContact = (item1, item2, defaultId) => {
    if (item1 === defaultId) return -1;
    if (item2 === defaultId) return 1;
    return 0;
}

export const getContactDetailPayload = async (contactData, countryList = []) => {
    let requestPayload = {
        title: titleOptionsEnum.MR.value
    }

    if (contactData?.id) {
        requestPayload['contact_id'] = contactData?.id
    }
    if (contactData?.picture_url) {
        const profilePicInfo = {
            picture_url: contactData?.picture_url,
            fileList: [],
            fileUrl: "",
            file: {}
        }
        requestPayload["profilePicInfo"] = profilePicInfo
    }
    if (contactData?.title) {
        requestPayload["title"] = contactData?.title
    }
    if (contactData?.first_name) {
        requestPayload["first_name"] = contactData?.first_name
    }
    if (contactData?.middle_name) {
        requestPayload["middle_name"] = contactData?.middle_name
    }
    if (contactData?.last_name) {
        requestPayload["last_name"] = contactData?.last_name
    }
    if (contactData?.date_of_birth) {
        requestPayload["date_of_birth"] = contactData?.date_of_birth
    }
    if (contactData?.company) {
        requestPayload["company"] = contactData?.company
    }
    if (contactData?.department) {
        requestPayload["department"] = contactData?.department
    }
    if (contactData?.job_title) {
        requestPayload["job_title"] = contactData?.job_title
    }
    if (contactData?.category) {
        requestPayload["category"] = contactData?.category
    }
    if (contactData?.status?.name) {
        requestPayload["status"] = { label: contactData?.status?.name, value: contactData?.status?.key }
    }
    if (!!contactData?.contact_labels?.length) {
        requestPayload["labels"] = contactData?.contact_labels?.map((labelItem) => ({
            id: labelItem?.id,
            label: labelItem?.label?.name,
            value: labelItem?.label?.key,
            color: labelItem?.label?.color
        }))
    }
    if (!!contactData?.contact_emails?.length) {
        requestPayload["emails"] = contactData?.contact_emails?.map((emailItem) => ({
            id: emailItem?.id,
            email: emailItem?.email,
            label: { label: contactLabelIcons[emailItem?.label].label, value: emailItem?.label },
            is_primary: emailItem?.id === contactData?.email?.id
        }))
    }
    if (!!contactData?.contact_phones?.length) {
        requestPayload["phones"] = contactData?.contact_phones?.map((phoneItem) => ({
            id: phoneItem?.id,
            country: countryList?.find((countryItem) => countryItem?.isdCode === phoneItem?.isd),
            phone: `${phoneItem?.phone}`,
            label: { label: contactLabelIcons[phoneItem?.label]?.label, value: phoneItem?.label },
            is_primary: phoneItem?.id === contactData?.phone?.id
        }))
    }
    if (!!contactData?.contact_addresses?.length) {
        requestPayload["addresses"] = contactData?.contact_addresses?.map((addressItem) => ({
            id: addressItem?.id,
            street_line_1: addressItem?.street_line_1,
            street_line_2: addressItem?.street_line_2,
            country: countryList?.find((countryItem) => countryItem?.country === addressItem?.country),
            state: addressItem?.state,
            city: addressItem?.city,
            post_office: addressItem?.post_office,
            zip: addressItem?.zip,
            label: { label: contactLabelIcons[addressItem?.label]?.label, value: addressItem?.label },
            is_primary: addressItem?.id === contactData?.address?.id
        }))
    }
    if (!!contactData?.contact_socials?.length) {
        requestPayload["socials"] = contactData?.contact_socials?.map((socialItem) => ({
            id: socialItem?.id,
            social: { label: socialItem?.social?.name, value: socialItem?.social?.key },
            link: socialItem?.link,
        }))
    }
    if (!!contactData?.contact_notes?.length) {
        requestPayload["notes"] = contactData?.contact_notes?.map((noteItem) => ({
            id: noteItem?.id,
            title: noteItem?.title,
            note: noteItem?.note,
        }))
    }

    return requestPayload;
}

export const validateContactPayload = async (contactPayload) => {
    let errorMsg = ""
    let requestPayload = {}

    if (!contactPayload?.first_name) {
        errorMsg = "Please enter first name"
        return { errorMsg, requestPayload };
    }
    if (!contactPayload?.status?.label) {
        errorMsg = "Please select status"
        return { errorMsg, requestPayload };
    }

    // request payload
    if (contactPayload?.profilePicInfo?.picture_url) {
        requestPayload["picture_url"] = contactPayload?.profilePicInfo?.picture_url
    }
    if (contactPayload?.title) {
        requestPayload["title"] = contactPayload?.title
    }
    if (contactPayload?.first_name) {
        requestPayload["first_name"] = contactPayload?.first_name
    }
    if (contactPayload?.middle_name) {
        requestPayload["middle_name"] = contactPayload?.middle_name
    }
    if (contactPayload?.last_name) {
        requestPayload["last_name"] = contactPayload?.last_name
    }
    if (contactPayload?.date_of_birth) {
        requestPayload["date_of_birth"] = contactPayload?.date_of_birth
    }
    if (contactPayload?.job_title) {
        requestPayload["job_title"] = contactPayload?.job_title
    }
    if (contactPayload?.company) {
        requestPayload["company"] = contactPayload?.company
    }
    if (contactPayload?.department) {
        requestPayload["department"] = contactPayload?.department
    }
    if (contactPayload?.category) {
        requestPayload["category"] = contactPayload?.category
    }
    if (contactPayload?.status) {
        requestPayload["status"] = contactPayload?.status?.value
    }

    return { errorMsg, requestPayload }
}


export const validateCreateContactPayload = async (contactPayload) => {
    let errorMsg = ""
    let requestPayload = {}

    if (!contactPayload?.first_name) {
        errorMsg = "Please enter first name"
        return { errorMsg, requestPayload };
    }
    if (!contactPayload?.status?.label) {
        errorMsg = "Please select status"
        return { errorMsg, requestPayload };
    }
    if (!!contactPayload?.labels?.length) {
        const invalidLabels = contactPayload?.labels?.filter((labelItem) => !labelItem?.value)
        if (!!invalidLabels?.length) {
            errorMsg = "Please enter valid label"
            return { errorMsg, requestPayload };
        }
    }
    // if (!!contactPayload?.emails?.length) {
    //     const invalidEmails = contactPayload?.emails?.filter((emailItem) => (!emailItem?.email || !validateEmail(emailItem?.email) || !emailItem?.label?.label))
    //     if (!!invalidEmails?.length) {
    //         errorMsg = "Please enter valid email and type"
    //         return { errorMsg, requestPayload };
    //     }
    // }
    // if (!!contactPayload?.phones?.length) {
    //     const invalidPhones = contactPayload?.phones?.filter((phoneItem) => (!phoneItem?.phone || !validateMobileNo(phoneItem?.phone) || !phoneItem?.label?.label))
    //     if (!!invalidPhones?.length) {
    //         errorMsg = "Please enter valid phone number and label"
    //         return { errorMsg, requestPayload };
    //     }
    // }
    if (!!contactPayload?.addresses?.length) {
        const invalidAddress = contactPayload?.addresses?.filter((addressItem) => !addressItem?.street_line_1)
        if (!!invalidAddress?.length) {
            errorMsg = "Please enter street 1 address"
            return { errorMsg, requestPayload };
        }
    }
    if (!!contactPayload?.socials?.length) {
        const invalidSocials = contactPayload?.socials?.filter((socialItem) => !socialItem?.social?.value || !socialItem?.link)
        if (!!invalidSocials?.length) {
            errorMsg = "Please enter valid socials"
            return { errorMsg, requestPayload };
        }
    }
    if (!!contactPayload?.notes?.length) {
        const invalidNotes = contactPayload?.notes?.filter((noteItem) => !noteItem?.title)
        if (!!invalidNotes?.length) {
            errorMsg = "Please enter note title and content"
            return { errorMsg, requestPayload };
        }
    }
    // request payload
    if (contactPayload?.profilePicInfo?.picture_url) {
        requestPayload["picture_url"] = contactPayload?.profilePicInfo?.picture_url
    }
    if (contactPayload?.title) {
        requestPayload["title"] = contactPayload?.title
    }
    if (contactPayload?.first_name) {
        requestPayload["first_name"] = contactPayload?.first_name
    }
    if (contactPayload?.middle_name) {
        requestPayload["middle_name"] = contactPayload?.middle_name
    }
    if (contactPayload?.last_name) {
        requestPayload["last_name"] = contactPayload?.last_name
    }
    if (contactPayload?.date_of_birth) {
        requestPayload["date_of_birth"] = contactPayload?.date_of_birth
    }
    if (contactPayload?.job_title) {
        requestPayload["job_title"] = contactPayload?.job_title
    }
    if (contactPayload?.company) {
        requestPayload["company"] = contactPayload?.company
    }
    if (contactPayload?.department) {
        requestPayload["department"] = contactPayload?.department
    }
    if (!!contactPayload?.labels?.length) {
        requestPayload["labels"] = contactPayload?.labels?.map((labelItem) => labelItem?.value)
    }
    if (!!contactPayload?.emails?.length) {
        requestPayload["emails"] = contactPayload?.emails?.filter((email) => (!!email?.email && !!email?.label?.value))?.map((emailItem) => ({
            email: emailItem?.email,
            label: emailItem?.label?.value,
            is_primary: emailItem?.is_primary
        }))
    }
    if (!!contactPayload?.phones?.length) {
        requestPayload["phones"] = contactPayload?.phones?.filter((phone) => (!!phone?.phone && !!phone?.label?.value))?.map((phoneItem) => ({
            phone: `${phoneItem?.country?.isdCode}-${phoneItem?.phone}`,
            label: phoneItem?.label?.value,
            is_primary: phoneItem?.is_primary
        }))
    }
    if (!!contactPayload?.addresses?.length) {
        requestPayload["addresses"] = contactPayload?.addresses?.map((addressItem) => ({
            street_line_1: addressItem?.street_line_1,
            street_line_2: addressItem?.street_line_2,
            country: addressItem?.country?.country,
            state: addressItem?.state,
            city: addressItem?.city,
            post_office: addressItem?.post_office,
            zip: addressItem?.zip,
            label: addressItem?.label?.value,
            is_primary: addressItem?.is_primary
        }))
    }
    if (!!contactPayload?.socials?.length) {
        requestPayload["socials"] = contactPayload?.socials?.map((socialItem) => ({
            social: socialItem?.social?.value,
            link: socialItem?.link,
        }))
    }
    if (!!contactPayload?.notes?.length) {
        requestPayload["notes"] = contactPayload?.notes?.map((noteItem) => ({
            title: noteItem?.title,
            note: noteItem?.note,
        }))
    }
    if (contactPayload?.category) {
        requestPayload["category"] = contactPayload?.category
    }
    if (contactPayload?.status) {
        requestPayload["status"] = contactPayload?.status?.value
    }

    return { errorMsg, requestPayload }
}

export const getDefaultEmailPhoneDetail = async (countryList, timeZoneCountryDetail) => {
    if (!countryList?.length || !timeZoneCountryDetail) return;

    let myLocalCountryList = countryList?.filter((country) => (
        country.masterCountryId === timeZoneCountryDetail?.country?.masterCountryId
    ))

    if (!myLocalCountryList || myLocalCountryList?.length === 0) {
        myLocalCountryList = countryList?.countryList?.filter((country) => (
            country.countryDomain === "US"
        ))
    }

    let requestPayload = {
        emails: [{ id: 1, email: "", label: { label: crmContactLabelsEnum.HOME.label, value: crmContactLabelsEnum.HOME.value }, is_primary: true }],
        phones: [{ id: 1, country: myLocalCountryList[0], phone: "", label: { label: crmContactLabelsEnum.HOME.label, value: crmContactLabelsEnum.HOME.value }, is_primary: true }]
    }

    return requestPayload;
}