import React, { useRef, useState, useEffect, memo, useMemo, useCallback } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import ReactSlider from 'components/slider/ReactSlider';

import SliderNavigation from './SliderNavigation';

import { downloadPreviewTypeEnum } from 'redux/downloads/downloads.const';

const DownloadPreviewModal = memo(({
    isOpenModal = false,
    overlaySlide = 0,
    sliderData = [],
    onHandleCloseModal,
    setOverlaySlide,
}) => {
    const overlaySliderRef = useRef(null);

    const overlaySliderSettings = useMemo(() => ({
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: overlaySlide,
        beforeChange: (oldIndex, newIndex) => setOverlaySlide(newIndex)
    }), [isOpenModal, overlaySlide]);

    const goToOverlaySlide = useCallback((index) => {
        setOverlaySlide(index);
        overlaySliderRef.current.slickGoTo(index);
    }, [overlaySliderRef, overlaySlide]);

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape') {
            onHandleCloseModal();
        }
    }, []);

    useEffect(() => {
        if (isOpenModal) {
            overlaySliderRef.current.slickGoTo(overlaySlide);
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpenModal]);

    return (
        <AnimatePresence initial={isOpenModal}>
            {!!isOpenModal && (
                <motion.div
                    className='fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-[5000]'
                    onClick={onHandleCloseModal}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.1 }}
                >
                    <button className='absolute top-5 right-5 text-white text-4xl z-10' onClick={onHandleCloseModal}>&times;</button>
                    <div className="w-full lg:w-4/5" onClick={(e) => e.stopPropagation()}>
                        <ReactSlider sliderRef={overlaySliderRef} settings={overlaySliderSettings}>
                            {sliderData?.map((item, index) => (
                                <React.Fragment key={index}>
                                    {(item?.type === downloadPreviewTypeEnum.IMAGE.value) && (
                                        <img
                                            className='w-full h-52 lg:h-[80vh] aspect-video object-cover rounded-lg'
                                            src={item.preview_url}
                                        />
                                    )}
                                    {(item?.type === downloadPreviewTypeEnum.VIDEO.value) && (
                                        <video
                                            className='w-full h-52 lg:h-[80vh] aspect-video object-cover rounded-lg'
                                            src={item.preview_url}
                                            allowFullScreen={true}
                                            controls={true}
                                        />
                                    )}
                                </React.Fragment>
                            ))}
                        </ReactSlider>
                        {(sliderData?.length > 1) && (
                            <SliderNavigation
                                sliderData={sliderData}
                                currentSlide={overlaySlide}
                                goToSlide={goToOverlaySlide}
                            />
                        )}
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
});

export default DownloadPreviewModal