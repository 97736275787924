export const url = {
    USER_WITHDRAWAL_LIST: "/withdrawals/users/{userId}"
}

export const withdrawalStatus = {
    PENDING: {
        label: "Pending",
        value: "pending"
    },
    APPROVED: {
        label: "Approved",
        value: "approved"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected"
    },
    CANCELLED: {
        label: "Cancelled",
        value: "cancelled"
    }
}