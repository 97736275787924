import { toast } from "react-toastify"

import offeringListService from "redux/offering/offering.service"
import {
    setOfferingListLoading,
    setOfferingList,
    setOfferingListErrorMsg,

    setOfferingCourseLoading,
    setOfferingCourse,
    setOfferingCourseErrorMsg,

    setAddOfferingLoading,
    setAddOffering,
    setAddOfferingErrorMsg,

    setCloneOfferingLoading,
    setCloneOffering,
    setCloneOfferingErrorMsg
} from "redux/offering/offering.slice"

export const getOfferingList = (query) => async (dispatch) => {
    dispatch(setOfferingListLoading(true))

    try {
        const requestData = {
            query: { roleStatus: "active", ...query }
        }
        let response = await offeringListService.getOfferingList(requestData)
        if (response.status === 200) {
            dispatch(setOfferingList(response.data.data))
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setOfferingListErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setOfferingListLoading(false))
    }
}

export const getOfferingByCourseId = (courseId) => async (dispatch) => {
    dispatch(setOfferingCourseLoading(true))

    try {
        const requestData = {
            params: { courseId: courseId },
            query: { roleStatus: "active", }
        }
        const response = await offeringListService.getOfferingByCourseId(requestData)

        if (response.status === 200) {
            dispatch(setOfferingCourse(response.data.data))
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setOfferingCourseErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setOfferingCourseLoading(false))
    }
}

export const createOffering = (tempCourseId) => async (dispatch, getState) => {
    dispatch(setAddOfferingLoading(true))

    try {
        const requestData = {
            body: { tempCourseId: tempCourseId }
        }
        const response = await offeringListService.createOffering(requestData)
        if (response.status === 200) {
            dispatch(setAddOffering(response.data.data))
            toast.success(response.data.message)
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddOfferingErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddOfferingLoading(false))
    }
}
export const updateOfferingByCourseId = (courseId, body) => async (dispatch, getState) => {
    dispatch(setAddOfferingLoading(true))

    try {
        const { offeringList } = getState().offering
        const requestData = {
            params: { courseId: courseId },
            body: body
        }
        const response = await offeringListService.updateOfferingByCourseId(requestData)
        if (response.status === 200) {
            const filterOffering = offeringList?.filteredOfferingList?.results?.map((offering) => (
                offering?.courseId === courseId ? response.data.data : offering
            ))
            dispatch(setOfferingCourse(response.data.data))
            dispatch(setAddOffering(response.data.data))
            dispatch(setOfferingList({ ...offeringList?.filteredOfferingList, results: filterOffering }
            ))
            toast.success(response.data.message)
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddOfferingErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddOfferingLoading(false))
    }
}

export const deleteOfferingByCourseId = (courseId) => async (dispatch, getState) => {
    dispatch(setAddOfferingLoading(true))

    try {
        const requestData = {
            params: { courseId: courseId }
        }
        const response = await offeringListService.deleteOfferingByCourseId(requestData)
        if (response.status === 200) {
            dispatch(setAddOffering(response.data.message))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddOfferingErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddOfferingLoading(false))
    }
}

export const createCloneOfferingByCourseId = (courseId) => async (dispatch) => {
    dispatch(setCloneOfferingLoading(true))

    try {
        const requestData = {
            params: { courseId: courseId }
        }
        const response = await offeringListService.createCloneOfferingByCourseId(requestData)
        if (response.status === 200) {
            dispatch(setCloneOffering(response.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setCloneOfferingErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setCloneOfferingLoading(false))
    }
}