import { createSlice } from "@reduxjs/toolkit";

import { LMS_RESOURCE_INITIAL_STATE } from "redux/edulyteLms/lmsResource/lmsResource.initialState";

const lmsResource = createSlice({
    name: "lms resource",
    initialState: LMS_RESOURCE_INITIAL_STATE,
    reducers: {
        setLmsResourceListLoading: (state, { payload }) => {
            state.lmsResourceList.isLoading = payload
        },
        setLmsResourceListData: (state, { payload }) => {
            state.lmsResourceList.data = payload
            state.lmsResourceList.message = LMS_RESOURCE_INITIAL_STATE.lmsResourceList.message
        },
        setLmsResourceListMessage: (state, { payload }) => {
            state.lmsResourceList.message = payload
            state.lmsResourceList.data = LMS_RESOURCE_INITIAL_STATE.lmsResourceList.data
        },
        resetLmsResourceList: (state) => {
            state.lmsResourceList = LMS_RESOURCE_INITIAL_STATE.lmsResourceList
        },

        // reducers for add lms resource
        setAddLmsResourceDetailLoading: (state, { payload }) => {
            state.addLmsResourceDetail.isLoading = payload
        },
        setAddLmsResourceDetailData: (state, { payload }) => {
            state.addLmsResourceDetail.data = payload
            state.addLmsResourceDetail.message = LMS_RESOURCE_INITIAL_STATE.addLmsResourceDetail.message
        },
        setAddLmsResourceDetailMessage: (state, { payload }) => {
            state.addLmsResourceDetail.message = payload
        },
        resetAddLmsResourceDetail: (state) => {
            state.addLmsResourceDetail = LMS_RESOURCE_INITIAL_STATE.addLmsResourceDetail
        },

        // filter props
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps;
        },
        resetFilterProps: (state) => {
            state.filterProps = LMS_RESOURCE_INITIAL_STATE.filterProps
        }
    }
})

export const {
    setLmsResourceListLoading,
    setLmsResourceListData,
    setLmsResourceListMessage,
    resetLmsResourceList,

    setAddLmsResourceDetailLoading,
    setAddLmsResourceDetailData,
    setAddLmsResourceDetailMessage,
    resetAddLmsResourceDetail,

    setFilterProps,
    resetFilterProps
} = lmsResource.actions

export default lmsResource.reducer;