import categoryService from "redux/category/category.service"
import {
    setCategoryListLoading,
    setCategoryList,
    setCategoryListErrorMsg
} from "redux/category/category.slice"

export const getAllCategoryList = () => async (dispatch) => {
    dispatch(setCategoryListLoading(true))

    try {
        const response = await categoryService.getAllCategoryList()
        if (response.status === 200) {
            dispatch(setCategoryList(response.data.data))
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setCategoryListErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setCategoryListLoading(false))
    }
}
