import { useEffect, useCallback, memo } from 'react';

import { cn } from "utils/cn.utils";

import { FaEye } from 'react-icons/fa';

import ComponentLoader from 'components/loader/ComponentLoader';
import Pagination from 'components/pagination/Pagination';

import LMSTable from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTable';

import LibraryActionButtons from '../../components/LibraryActionButtons';
import { resourceTableHeaderConst, libraryTabConst } from '../../data';
import ResourceHeader from './components/ResourceHeader';

import { getLmsResourceList } from 'redux/edulyteLms/lmsResource/lmsResource.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetFilterProps, resetLmsResourceList } from 'redux/edulyteLms/lmsResource/lmsResource.slice';
import { setLocalTabs } from 'redux/local/local.slice';
import { lmsResourceTypeEnum, resourceFileTypeEnum } from 'redux/edulyteLms/lmsResource/lmsResource.const';

import { timeZone, dayjs } from 'utils/dateTime.utils';
import { removeFileExtensionFromFileName } from 'utils/generators.utils';

const ResourceTab = () => {
    const { localTabs } = useAppState((state) => state.local)
    const { lmsResourceList, filterProps } = useAppState((state) => state.lms.lmsResource)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        const query = { page: 1, records: 5 }
        if (filterProps?.resourceType && (filterProps?.resourceType === lmsResourceTypeEnum.FILE.value)) {
            query["file_type"] = filterProps?.resourceFileType
        }
        if (filterProps?.resourceType && (filterProps?.resourceType != lmsResourceTypeEnum.FILE.value)) {
            query["type"] = filterProps?.resourceType
        }
        dispatcher(getLmsResourceList(query))

        return () => {
            dispatcher(resetLmsResourceList())
            dispatcher(resetFilterProps())
        }
    }, [])

    const onHandleViewResource = useCallback((resourceItem) => {
        let resourceUrl = ""
        if ([lmsResourceTypeEnum?.YOUTUBE?.value, lmsResourceTypeEnum?.LINK?.value]?.includes(resourceItem?.type)) {
            resourceUrl = resourceItem?.resource?.resource_link
            window.open(resourceUrl, '_blank')
        } else {
            resourceUrl = resourceItem?.resource?.file?.file_url
            window.open(resourceUrl, '_blank')
        }
    }, [lmsResourceList?.data])

    const onHandleInsertResource = useCallback((resourceItem) => {
        if ((resourceItem?.file && !resourceItem?.file?.fileUrl) || ([lmsResourceTypeEnum?.YOUTUBE?.value, lmsResourceTypeEnum?.LINK?.value]?.includes(resourceItem?.type) && !resourceItem?.resource?.resource_link)) return;

        dispatcher(setLocalTabs({
            ...localTabs,
            tabItem: {
                ...localTabs?.tabItem,
                tabType: libraryTabConst.RESOURCES.value,
                payload: {
                    ...localTabs?.tabItem?.payload,
                    resourceId: resourceItem?.id
                }
            }
        }))
    }, [localTabs])

    const onHandlePageChange = useCallback((page) => {
        const query = { page: page, records: 5 }
        if (filterProps?.resourceType && (filterProps?.resourceType === lmsResourceTypeEnum.FILE.value)) {
            query["file_type"] = filterProps?.resourceFileType
        }
        if (filterProps?.resourceType && (filterProps?.resourceType != lmsResourceTypeEnum.FILE.value)) {
            query["type"] = filterProps?.resourceType
        }
        dispatcher(getLmsResourceList(query))
    }, [filterProps])


    const ResourceTitleContainer = memo(({ resource }) => {
        const fileTitle = removeFileExtensionFromFileName(resource?.title)

        return resource?.title ? (
            <div
                className={'flex flex-col items-start justify-start gap-1'}
                title={fileTitle && fileTitle}
            >
                <span className={'font-bodyPri font-normal text-text-900 text-base truncate tracking-wide'}>
                    {fileTitle
                        ? (fileTitle?.length > 20)
                            ? fileTitle?.slice(0, 20) + " ..."
                            : fileTitle
                        : "-"
                    }
                </span>
            </div>
        ) : <span>{"-"}</span>
    });

    const ResourceTypeContainer = memo(({ resource }) => {
        return resource?.type ? (
            <span className={"font-bodyPri font-normal text-sm tracking-wide"}>
                {(resource?.type === lmsResourceTypeEnum?.FILE?.value)
                    ? `${lmsResourceTypeEnum[resource?.type?.toUpperCase()]?.label} (${resourceFileTypeEnum[resource?.resource?.type?.toUpperCase()]?.label})`
                    : lmsResourceTypeEnum[resource?.type?.toUpperCase()]?.label
                }
            </span>
        ) : <span>{"-"}</span>
    })

    const tableRowsConst = lmsResourceList?.data?.results?.map((resource) => ([
        <ResourceTitleContainer resource={resource} />,
        <ResourceTypeContainer resource={resource} />,
        dayjs(resource?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",
        <LibraryActionButtons
            isShowViewBtn={true}
            isShowEditBtn={false}
            viewBtnTooltip={"View Resource"}
            insertBtnTooltip={"Insert Resource"}
            isEnterBtnDisabled={(resource?.file && !resource?.file?.fileUrl) || ([lmsResourceTypeEnum?.YOUTUBE?.value, lmsResourceTypeEnum?.LINK?.value]?.includes(resource?.type) && !resource?.resource?.resource_link)}
            onHandleViewBtn={() => onHandleViewResource(resource)}
            onHandleInsertBtn={() => onHandleInsertResource(resource)}
        />,
    ]))

    return (
        <div className={"flex flex-col w-full px-5 py-3 bg-white gap-2"}>
            <div className={"space-y-3"}>
                <span className={"font-bodyPri font-medium text-text-800 text-md tracking-wide underline"}>
                    {"List of Resources"}
                </span>
                <ResourceHeader />
            </div>
            {lmsResourceList?.isLoading &&
                <ComponentLoader isLoading={lmsResourceList?.isLoading} className={"w-full"} />
            }
            {(!lmsResourceList?.isLoading && lmsResourceList?.data) &&
                <LMSTable
                    headers={resourceTableHeaderConst}
                    rows={tableRowsConst}
                    alternateRowColor={"bg-sky-100"}
                    rowHeaderColor={"bg-sky-200"}
                />
            }
            {(!lmsResourceList?.isLoading && (lmsResourceList?.data?.totalPages > 1)) &&
                <div className={'w-full flex items-center justify-center'}>
                    <Pagination
                        page={lmsResourceList?.data?.page}
                        totalPages={lmsResourceList?.data?.totalPages}
                        isScrollToTop={false}
                        onChangePage={onHandlePageChange}
                    />
                </div>
            }
            {lmsResourceList?.message &&
                <div className={"flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {lmsResourceList?.message}
                    </span>
                </div>
            }
        </div>
    )
}

export default ResourceTab;