import { useState } from 'react'
import { LinearProgress } from '@mui/material';

import IdentifierForm, { defaultIdentifierFormData } from '../identifier-form';
import SignupForm, { defaultSignupFormData } from '../signup-form';
import VerifyOtpForm, { defaultVerifyOtpFormData } from '../verify-otp-form';


const formSteps = {
    "identifier_form": {
        key: "identifier_form",
        count: 0
    },
    "signup_form": {
        key: "signup_form",
        count: 1
    },
    "verify_otp_form": {
        key: "verify_otp_form",
        count: 2
    },
}

export const textPlace = {
    identifier: {
        heading: "Enter your email address",
        subheading: "We'll need your email to create your account and send important updates.",
        placeholder: {
            email: "yourname@example.com",
        }
    },
    signup: {
        heading: "Enter your first and last name",
        subheading: "Please provide your name to personalise your experience.",
        placeholder: {
            first_name: "First Name",
            last_name: "Last Name",
        }
    },
    verify: {
        heading: "Verify your email",
        subheading: "We’ve sent a verification code to your email. Please enter it below to continue.",
        placeholder: {
        }
    }
}


const CtaFormStep = () => {
    const [step, setStep] = useState(formSteps.identifier_form)
    const [data, setData] = useState({
        identifier: defaultIdentifierFormData,
        isUser: false,
        signup: defaultSignupFormData,
        verifyOtp: defaultVerifyOtpFormData,
        success: null
    })

    const onHandleIdentifierNext = (identifierData) => {
        setData(s => ({ ...s, identifier: identifierData, isUser: false, signup: defaultSignupFormData, verifyOtp: defaultVerifyOtpFormData }))
        setStep(formSteps.signup_form)
    }

    const onHandleUpdateIdentifier = (identifierData) => {
        setData(s => ({ ...s, identifier: identifierData, isUser: false, signup: defaultSignupFormData, verifyOtp: defaultVerifyOtpFormData }))
        setStep(formSteps.identifier_form)
    }

    const onHandleUpdateSignup = (identifierData, signupData) => {
        setData(s => ({ ...s, identifier: identifierData, isUser: false, signup: signupData, verifyOtp: defaultVerifyOtpFormData }))
        setStep(formSteps.signup_form)
    }

    const onHandleVerify = (token, identifierData = defaultIdentifierFormData, isUser = false, signupData = defaultSignupFormData) => {
        setData(s => ({ ...s, verifyOtp: { ...defaultVerifyOtpFormData, token }, identifier: identifierData, isUser, signup: signupData }))
        setStep(formSteps.verify_otp_form)
    }

    return (
        <div className='relative isolate aspect-auto max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl w-full mx-auto rounded-xl shadow-[0_0_5px_1px_rgba(0,0,0,0.2)] bg-white overflow-hidden'>
            <LinearProgress
                variant="determinate"
                value={(step.count) * 33}
                className='w-full'
                color={"primary"}
            />
            {step.key === formSteps.identifier_form.key && (
                <IdentifierForm
                    textPlace={textPlace?.identifier}
                    formData={data?.identifier}
                    onHandleIdentifierNext={onHandleIdentifierNext}
                    onHandleVerify={onHandleVerify}
                />
            )}
            {step.key === formSteps.signup_form.key && (
                <SignupForm
                    textPlace={textPlace?.signup}
                    identifierData={data?.identifier}
                    formData={data?.signup}
                    onHandleUpdateIdentifier={onHandleUpdateIdentifier}
                    onHandleVerify={onHandleVerify}
                />
            )}
            {step.key === formSteps.verify_otp_form.key && (
                <VerifyOtpForm
                    textPlace={textPlace?.verify}
                    identifierData={data?.identifier}
                    isUser={data?.isUser}
                    signupData={data?.signup}
                    formData={data?.verifyOtp}
                    onHandleUpdateIdentifier={onHandleUpdateIdentifier}
                    onHandleUpdateSignup={onHandleUpdateSignup}
                    onHandleVerify={onHandleVerify}
                />
            )}
        </div>
    )
}

export default CtaFormStep