import { useCallback, useEffect, useMemo, useState } from 'react';

import { Reorder } from 'framer-motion';

import ComponentLoader from 'components/loader/ComponentLoader';

import CreateSection from 'pages/auth/edulyteLms/commonComponents/CreateSection';
import AddSection from 'pages/auth/edulyteLms/commonComponents/AddSection';

import { getTotalQuizQuestionsCount } from '../../../editLMSQuiz.data';
import LMSQuizSectionItem from './LMSQuizSectionItem';

import { createLmsQuizSectionDetail, updateOrderLmsQuizSectionList } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetAddLmsQuizSectionData, setAddLmsQuizSectionPayload, setLmsQuizDetailData } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.slice';
import { addLmsQuizSectionPayload } from "redux/edulyteLms/lmsQuiz/lmsQuiz.const";

import { isOrderUpdated } from 'utils/generators.utils';

const QuizSection = () => {
    const { lmsQuizDetail, addLmsQuizSection } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()

    const [sectionTitle, setSectionTitle] = useState('');
    const [isInputVisible, setInputVisible] = useState(false);
    const [isQuizSectionOrderUpdated, setIsQuizSectionOrderUpdated] = useState(false)

    const totalQuestionsCount = useMemo(() => getTotalQuizQuestionsCount(lmsQuizDetail?.data?.quiz_sections), [lmsQuizDetail?.data])

    useEffect(() => {
        if (lmsQuizDetail?.data?.quiz_sections && !!lmsQuizDetail?.data?.quiz_sections?.length) {
            dispatcher(setAddLmsQuizSectionPayload({
                ...addLmsQuizSectionPayload,
                sectionId: lmsQuizDetail?.data?.quiz_sections[0]?.id
            }))
        }
    }, [])

    useEffect(() => {
        if (addLmsQuizSection?.data) {
            dispatcher(setAddLmsQuizSectionPayload({
                ...addLmsQuizSectionPayload,
                sectionId: addLmsQuizSection?.data?.id
            }))
            dispatcher(setLmsQuizDetailData({
                ...lmsQuizDetail?.data,
                quiz_sections: [...lmsQuizDetail?.data?.quiz_sections, addLmsQuizSection?.data]
            }))
            dispatcher(resetAddLmsQuizSectionData())
        }
    }, [addLmsQuizSection?.data])

    const onHandleReorderGroup = (newOrder) => {
        if (newOrder) {
            const isOrderModified = isOrderUpdated(lmsQuizDetail?.data?.quiz_sections, newOrder)
            if (!isOrderModified) {
                setIsQuizSectionOrderUpdated(false)
                return;
            }
            setIsQuizSectionOrderUpdated(true)
            dispatcher(setLmsQuizDetailData({
                ...lmsQuizDetail?.data,
                quiz_sections: newOrder
            }))
        }
    }

    const onHandleUpdateQuizSectionOrder = () => {
        if (isQuizSectionOrderUpdated && (lmsQuizDetail?.data?.quiz_sections?.length > 0)) {
            const body = {
                order: lmsQuizDetail?.data?.quiz_sections?.map((quizSectionItem) => ({
                    id: quizSectionItem?.id
                }))
            }
            dispatcher(updateOrderLmsQuizSectionList(body, { quiz_id: lmsQuizDetail?.data?.id }))
            setIsQuizSectionOrderUpdated(false)
        }
    }

    const onHandleAddNewSection = useCallback(() => {
        setSectionTitle("")
        setInputVisible(true)
    }, [isInputVisible])

    const onHandleCreateSection = useCallback(() => {
        if (lmsQuizDetail?.isLoading || addLmsQuizSection?.isLoading) return;

        dispatcher(createLmsQuizSectionDetail({
            quiz_id: lmsQuizDetail?.data?.id,
            title: sectionTitle,
        }))
        setInputVisible(false)
    }, [sectionTitle, lmsQuizDetail?.data])

    return (
        <div className={"w-full flex flex-col items-start justify-start gap-5"}>
            {(lmsQuizDetail?.data?.quiz_sections?.length > 0) &&
                <div className={"w-full flex items-center justify-between gap-3"}>
                    <span className={"ml-3 font-bodyPri font-medium text-sm md:text-lg tracking-wide"}>
                        {"Quiz Content"}
                    </span>
                    {(lmsQuizDetail?.data?.total_question_count && totalQuestionsCount) &&
                        <span className={"font-bodyPri text-sm text-text-700 tracking-wide"}>
                            {`Total ${totalQuestionsCount} Questions`}
                        </span>
                    }
                </div>
            }
            {(lmsQuizDetail?.data?.quiz_sections?.length > 0) &&
                <Reorder.Group
                    className={'w-full flex flex-col gap-5'}
                    values={lmsQuizDetail?.data?.quiz_sections}
                    onReorder={onHandleReorderGroup}
                >
                    {lmsQuizDetail?.data?.quiz_sections?.map((quizSectionItem) => (
                        <Reorder.Item
                            key={quizSectionItem?.id}
                            value={quizSectionItem}
                            onDragEnd={onHandleUpdateQuizSectionOrder}
                        >
                            <LMSQuizSectionItem quizSectionItem={quizSectionItem} />
                        </Reorder.Item>
                    ))}
                </Reorder.Group>
            }
            {addLmsQuizSection?.isLoading &&
                <ComponentLoader isLoading={addLmsQuizSection?.isLoading} />
            }
            {!isInputVisible &&
                <AddSection
                    onAddNewPress={onHandleAddNewSection}
                />
            }
            {isInputVisible && (
                <CreateSection
                    isLoading={addLmsQuizSection?.isLoading}
                    sectionName={'New Section'}
                    onClose={() => setInputVisible(false)}
                    value={sectionTitle}
                    onChange={(e) => setSectionTitle(e.target.value)}
                    placeholder={'Section Title'}
                    onCreate={onHandleCreateSection}
                />
            )}
        </div>
    );
};

export default QuizSection;