import { Link, useNavigate, useParams } from 'react-router-dom';

import { cn } from "utils/cn.utils";
import { pagesInfo } from 'utils/pagesInfo';

const NavigationTabs = ({ options }) => {

    const navigate = useNavigate()
    const params = useParams();
    const pathParam = params["*"];

    return (
        <div className={"p-4 bg-white rounded-lg"}>
            <div className={"flex flex-col sm:flex-row items-center justify-between gap-3"}>
                <div className={"rounded-md ring-2 ring-primary-main divide-x-2 divide-primary-main overflow-hidden"}>
                    {options?.map((option, idx) => (
                        <button
                            key={idx}
                            // to={`.${option?.to}`}
                            className={cn(
                                "w-32 py-2 font-buttons font-normal text-base",
                                pathParam === option?.to?.slice(1)
                                    ? "bg-primary-main text-white"
                                    : "text-primary-main hover:bg-primary-light"
                            )}
                            onClick={() => navigate(`${pagesInfo?.STUDENT_LMS_COURSES?.pagePath}${option?.to}`)}
                        >
                            {option?.label}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default NavigationTabs;