import { FiSearch } from "react-icons/fi";
import { MdIosShare, MdOutlinePreview } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaPlus } from "react-icons/fa";

import BuilderPage from "pages/auth/editDownload/builder";
import settingsPage from "pages/auth/editDownload/settings";
import ParticipantsPage from "pages/auth/editDownload/participants";

import Invited from "pages/auth/editDownload/participants/invited";
import Joined from "pages/auth/editDownload/participants/joined";

import GeneralSettings from "./settings/generalSettings";
import Product from "./settings/product";

import { imageFileTypeEnum, mbToKbConst, profilePicTypeConst, videoFileTypeConst, videoFileTypeEnum } from "redux/storage/storage.const";
import { downloadFileTypeEnum, downloadPreviewTypeEnum } from "redux/downloads/downloads.const";

export const downloadTabConst = {
    BUILDER: {
        label: "Builder",
        value: "builder",
        pagePath: "/builder",
        routePath: "builder",
        component: BuilderPage
    },
    SETTINGS: {
        label: "Settings",
        value: "settings",
        pagePath: "/settings",
        routePath: "settings",
        component: settingsPage
    },
    PARTICIPANTS: {
        label: "Customers",
        value: "participants",
        pagePath: "/participants",
        routePath: "participants",
        component: ParticipantsPage
    }
}

export const downloadSettingsNavigationOptions = {
    GENERAL: {
        label: "General settings",
        value: "general",
        pagePath: "/general",
        routePath: "general",
        to: "settings/general",
        component: GeneralSettings
    },
    PRODUCT: {
        label: "Pricing (Product)",
        value: "product",
        pagePath: "/product",
        routePath: "product",
        to: "settings/product",
        component: Product
    },
}

export const downloadParticipantsNavigationOptions = {
    JOINED: {
        label: "Joined",
        value: "joined",
        pagePath: "/joined",
        routePath: "joined",
        to: "participants/joined",
        component: Joined
    },
    INVITED: {
        label: "Invited",
        value: "invited",
        pagePath: "/invited",
        routePath: "invited",
        to: "participants/invited",
        component: Invited
    },
}

export const downloadInstructorHeaderConst = {
    Image: {
        label: "Image",
        value: "image"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    PERMISSION: {
        label: "Permission",
        value: "permission"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

export const downloadInstructorInvitationHeaderConst = {
    IMAGE: {
        label: "Image",
        value: "image"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    INVITE_TO: {
        label: "Invite to",
        value: "invite_to"
    },
    INVITE_EMAIL_SENT: {
        label: "Invite email sent",
        value: "invite_email_sent"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE_JOINED: {
        label: "Date Joined",
        value: "date_joined"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

export const downloadInstructorMenuItemConst = {
    MAKE_DEFAULT: {
        label: "Make Default",
        value: "make_default"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    DELETE: {
        label: "Delete",
        value: "delete"
    }
}

export const downloadInstructorInviteMenuItemsConst = {
    DELETE: {
        label: "Delete",
        value: "delete"
    }
}

export const downloadStudentHeaderConst = {
    Image: {
        label: "Image",
        value: "image"
    },
    USER_ID: {
        label: "User ID",
        value: "user_id"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    JOINING_FLOW: {
        label: "Joining Flow",
        value: "joining_flow"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    IS_ACTIVE: {
        label: "Is Active",
        value: "is_active"
    },
    JOINED_ON: {
        label: "Joined on",
        value: "joined_on"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

export const downloadStudentInvitationHeaderConst = {
    IMAGE: {
        label: "Image",
        value: "image"
    },
    EMAIL: {
        label: "Email",
        value: "email"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE_JOINED: {
        label: "Date Joined",
        value: "date_joined"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

export const downloadStudentInviteMenuItemsConst = {
    DELETE: {
        label: "Delete",
        value: "delete"
    }
}

export const downloadTooltips = {
    productPricing: "Set the price for your download. Consider your content's value and your target audience. Free or paid, it's up to you!",
    shareableDownloadLink: "Share a direct link to your download. Perfect for promoting your download on social media, emails, or any other marketing channels.",
    publishedInviteBtnTooltip: "Send download invitations through email.",
    disabledInviteBtnTooltip: "Publish this download to invite participants.",
}


export const mimeToEnumMap = {
    "video/mp4": downloadFileTypeEnum.VIDEO.value,
    "video/x-msvideo": downloadFileTypeEnum.VIDEO.value,
    "video/x-m4v": downloadFileTypeEnum.VIDEO.value,
    "video/x-matroska": downloadFileTypeEnum.VIDEO.value,
    "video/webm": downloadFileTypeEnum.VIDEO.value,
    "video/ogg": downloadFileTypeEnum.VIDEO.value,
    "video/quicktime": downloadFileTypeEnum.VIDEO.value,
    "image/jpeg": downloadFileTypeEnum.IMAGE.value,
    "image/png": downloadFileTypeEnum.IMAGE.value,
    "image/gif": downloadFileTypeEnum.IMAGE.value,
    "image/bmp": downloadFileTypeEnum.IMAGE.value,
    "image/svg+xml": downloadFileTypeEnum.IMAGE.value,
    "image/tiff": downloadFileTypeEnum.IMAGE.value,
    "image/webp": downloadFileTypeEnum.IMAGE.value,
    "image/ico": downloadFileTypeEnum.IMAGE.value,
    "application/pdf": downloadFileTypeEnum.PDF.value,
    "application/msword": downloadFileTypeEnum.DOC.value,
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": downloadFileTypeEnum.DOC.value,
    "application/vnd.ms-excel": downloadFileTypeEnum.SHEET.value,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": downloadFileTypeEnum.SHEET.value,
    "application/vnd.ms-powerpoint": downloadFileTypeEnum.SLIDE.value,
    "application/vnd.google-apps.presentation": downloadFileTypeEnum.SLIDE.value,
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": downloadFileTypeEnum.SLIDE.value
}

const mimeTypeToExtensionMap = {
    "video/mp4": ".mp4",
    "video/x-msvideo": ".avi",
    "video/x-m4v": ".m4v",
    "video/x-matroska": ".mkv",
    "video/webm": ".webm",
    "video/ogg": ".ogv",
    "video/quicktime": ".mov",
    "image/jpeg": ".jpg",
    "image/png": ".png",
    "image/gif": ".gif",
    "image/bmp": ".bmp",
    "image/svg+xml": ".svg",
    "image/tiff": ".tiff",
    "image/webp": ".webp",
    "image/ico": ".ico",
    "application/pdf": ".pdf",
    "application/msword": ".doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ".docx",
    "application/vnd.ms-excel": ".xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
    "application/vnd.ms-powerpoint": ".ppt",
    "application/vnd.google-apps.presentation": ".pptx",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": ".pptx",
};

export const acceptFileExtensionTypes = Array.from(new Set(Object.values(mimeTypeToExtensionMap))).join(",");

export const previewActionButtons = {
    ADD_IMAGE: {
        label: "Add Image",
        value: "add_image",
        icon: <FaPlus className={"text-base text-primary-dark group-hover:text-text-50"} />,
        labelInputId: "preview_addImage",
        acceptFileTypes: profilePicTypeConst,
        className: ""
    },
    ADD_VIDEO: {
        label: "Add Video",
        value: "add_video",
        icon: <FaPlus className={"text-base text-primary-dark group-hover:text-text-50"} />,
        labelInputId: "preview_addVideo",
        acceptFileTypes: videoFileTypeConst,
        className: ""
    },
    SEARCH_IMAGE: {
        label: "Search Image",
        value: "search_image",
        icon: <FiSearch className={"text-base text-primary-dark group-hover:text-text-50"} />,
        labelInputId: "preview_searchImage",
        acceptFileTypes: null,
        className: ""
    },
}

export const fileActionButtons = {
    ADD_FILE: {
        label: "Add File",
        value: "add_file",
        icon: <FaPlus className={"text-base text-primary-dark group-hover:text-text-50"} />,
        labelInputId: "file_addFile",
        acceptFileTypes: acceptFileExtensionTypes,
        className: ""
    },
}

export const previewItemActionButtons = {
    MAKE_DEFAULT: {
        value: "make_default",
        icon: "",
        tooltip: "Make Default"
    },
    PREVIEW: {
        value: "preview",
        icon: <MdOutlinePreview className={"text-xl text-text-700 group-hover:text-primary-dark cursor-pointer"} />,
        tooltip: "Preview",
    },
    REMOVE: {
        value: "remove",
        icon: <RiDeleteBin6Line className={"text-xl text-text-700 group-hover:text-red-500 cursor-pointer"} />,
        tooltip: "Remove",
    }
}

export const fileItemActions = {
    COPY: {
        value: "Copy",
        icon: "",
        tooltip: "Copy"
    },
    DELETE: {
        value: "delete",
        icon: "",
        tooltip: "Delete"
    }
}

export const downloadSlugActionButtons = {
    COPY: {
        value: "copy",
        icon: "",
        tooltip: "Copy",
    },
    SHARE: {
        value: "share",
        icon: <MdIosShare className={"text-text-700 text-xl cursor-pointer"} />,
        tooltip: "Share",
    }
}

const getFileTypeEnum = (mimeType) => {
    return mimeToEnumMap[mimeType] || downloadFileTypeEnum.OTHER.value;
};

export const downloadTypeConst = {
    PREVIEW: {
        key: "preview"
    },
    FILE: {
        key: "file"
    }
}

export const onHandleValidateFileDetails = async (files = [], downloadType = "") => {
    if (!files?.length) return;

    let requestPayload = {};
    let errorMsg = "";

    const file = files[0];
    if (!file || (file.length === 0)) {
        alert("file not selected!")
        return;
    }
    if ([downloadTypeConst.PREVIEW.key]?.includes(downloadType)) {
        if (imageFileTypeEnum[file?.type]) {
            if (file?.size < (2 * mbToKbConst)) {
                requestPayload = {
                    type: downloadPreviewTypeEnum.IMAGE.value,
                    selectedFile: {
                        file: file,
                        fileList: files,
                        fileUrl: URL.createObjectURL(file)
                    }
                }
            } else {
                errorMsg = "File size is too large!"
            }
        }
        if (videoFileTypeEnum[file?.type]) {
            if (file?.size < (10 * mbToKbConst)) {
                requestPayload = {
                    type: downloadPreviewTypeEnum.VIDEO.value,
                    selectedFile: {
                        file: file,
                        fileList: files,
                        fileUrl: URL.createObjectURL(file)
                    }
                }
            } else {
                errorMsg = "File size is too large!"
            }
        }
        if (!imageFileTypeEnum[file?.type] && !videoFileTypeEnum[file?.type]) {
            errorMsg = "Please select a valid image or video file!"
        }
    }
    if ([downloadTypeConst.FILE.key]?.includes(downloadType)) {
        if (!mimeTypeToExtensionMap[file?.type]) {
            errorMsg = "Please select a valid document!"
        } else {
            requestPayload = {
                type: getFileTypeEnum(file?.type),
                selectedFile: {
                    file: file,
                    fileList: files,
                    fileUrl: URL.createObjectURL(file)
                }
            }
        }
    }

    return { requestPayload, errorMsg }
}

export const downloadFileExtensionConst = {
    MP4: {
        label: "MP4",
        value: "mp4"
    },

    PNG: {
        label: "PNG",
        value: "png"
    },

    PDF: {
        label: "PDF",
        value: "pdf"
    },
    DOCX: {
        label: "Docx",
        value: "docx"
    },
    DOC: {
        label: "Doc",
        value: "doc"
    },
    TXT: {
        label: "Txt",
        value: 'txt'
    },
    JPG: {
        label: "JPG",
        value: "jpg"
    },
    JPEG: {
        label: "JPEG",
        value: "jpeg"
    },
}

export const fileIconsConst = {

}