import { useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import PageTabs from 'pages/auth/edulyteLms/commonComponents/navigations/PageTabs';

import { quizParticipantsNavigationOptions } from '../../editLMSQuiz.data';

import { useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const EditLMSQuizParticipantsPage = () => {
    const { lmsQuizDetail } = useAppState((state) => state.lms.lmsQuiz)

    const navigate = useNavigate()

    const onHandleSelectTab = useCallback((activeTab) => {
        navigate(`${pagesInfo?.EDIT_LMS_QUIZ?.pagePath}/${lmsQuizDetail?.data?.id}/edit/${quizParticipantsNavigationOptions[activeTab?.value?.toUpperCase()]?.to}`)
    }, [lmsQuizDetail?.data?.id])

    return (
        <div className={"flex flex-col space-y-3"}>
            <PageTabs
                tabs={Object.values(quizParticipantsNavigationOptions)}
                onHandleTabs={onHandleSelectTab}
            />
            <div className={"w-full h-full rounded-lg overflow-hidden p-5 bg-white"}>
                <Outlet />
            </div>
        </div>
    )
}

export default EditLMSQuizParticipantsPage;