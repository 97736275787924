import format from 'string-format'
import { baseAuthApiInstance } from 'apiInstances/axios.apiInstance'
import { url } from './lmsCourse.const'


class LmsCourseService {

    static lmsCourseService = new LmsCourseService()

    // Lms course services
    createLmsCourseDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_COURSE_DETAIL),
            body
        )

        return response;
    }
    updateLmsCourseDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_COURSE_DETAIL, params),
            body
        )

        return response;
    }
    deleteLmsCourseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().put(
            format(url.DELETE_LMS_COURSE_DETAIL, params)
        )

        return response;
    }

    // Tutor Lms Course Services
    getTutorLmsCourseList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_TUTOR_LMS_COURSE_LIST),
            { params: query }

        )
        return response;
    }
    getTutorLmsCourseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_TUTOR_LMS_COURSE_DETAIL, params)
        )
        return response;
    }

    // Lms Course Section Services
    createLmsCourseSectionDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_COURSE_SECTION_DETAIL),
            body
        )

        return response;
    }
    getLmsCourseSectionList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_COURSE_SECTION_LIST),
            { params: query }
        )
        return response;
    }
    updateLmsCourseSectionOrderList = async ({ body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_COURSE_SECTION_ORDER_LIST),
            body, { params: query }
        )

        return response;
    }
    getLmsCourseSectionDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_COURSE_SECTION_DETAIL, params),
        )

        return response;
    }
    updateLmsCourseSectionDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_COURSE_SECTION_DETAIL, params),
            body
        )

        return response;
    }
    deleteLmsCourseSectionDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_COURSE_SECTION_DETAIL, params)
        )

        return response;
    }

    // Lms Course Section Lesson Services
    createLmsCourseSectionLessonDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_COURSE_SECTION_LESSON_DETAIL),
            body
        )

        return response;
    }
    getLmsCourseSectionLessonList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_COURSE_SECTION_LESSON_LIST),
            { params: query }
        )

        return response;
    }
    updateLmsCourseSectionLessonOrderList = async ({ body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_COURSE_SECTION_LESSON__ORDER_LIST),
            body, { params: query }
        )

        return response;
    }
    getLmsCourseSectionLessonDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_COURSE_SECTION_LESSON_DETAIL, params)
        )

        return response;
    }
    updateLmsCourseSectionLessonDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_COURSE_SECTION_LESSON_DETAIL, params),
            body
        )

        return response;
    }
    deleteLmsCourseSectionLessonDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_COURSE_SECTION_LESSON_DETAIL, params)
        )

        return response;
    }

    // create lms course tag detail
    createLmsCourseTagDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_COURSE_TAG_DETAIL),
            body
        )

        return response;
    }

    // update lms course tag list order
    updateLmsCourseTagListOrder = async ({ body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_COURSE_TAG_LIST_ORDER),
            body,
            { params: query }
        )

        return response;
    }

    // delete lms course tag detail
    deleteLmsCourseTag = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_COURSE_TAG_DETAIL, params)
        )

        return response;
    }

    // create lms course language detail
    createLmsCourseLanguageDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_COURSE_LANGUAGE_DETAIL),
            body
        )

        return response;
    }

    // update lms course language list order
    updateLmsCourseLanguageListOrder = async ({ body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_COURSE_LANGUAGE_LIST_ORDER),
            body,
            { params: query }
        )

        return response;
    }

    // delete lms course language detail
    deleteLmsCourseLanguage = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_COURSE_LANGUAGE_DETAIL, params)
        )

        return response;
    }

    // create lms course prof detail
    createLmsCourseProfDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_COURSE_PROF_DETAIL),
            body
        )

        return response;
    }

    // update lms course prof list order
    updateLmsCourseProfListOrder = async ({ body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_COURSE_PROF_LIST_ORDER),
            body,
            { params: query }
        )

        return response;
    }

    // delete lms course prof detail
    deleteLmsCourseProf = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_COURSE_PROF_DETAIL, params)
        )

        return response;
    }

    // create lms course learning detail
    createLmsCourseLearningDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_COURSE_LEARNING_DETAIL),
            body
        )

        return response;
    }

    // update lms course learning detail
    updateLmsCourseLearningDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_COURSE_LEARNING_DETAIL, params),
            body,
        )

        return response;
    }

    // update lms course learning order
    updateLmsCourseLearningListOrder = async ({ body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_COURSE_LEARNING_LIST_ORDER),
            body,
            { params: query }
        )

        return response;
    }

    // delete lms course learning
    deleteLmsCourseLearning = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_COURSE_LEARNING_DETAIL, params)
        )

        return response;
    }

    // create lms course faq detail
    createLmsCourseFaqDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_COURSE_FAQ_DETAIL),
            body
        )

        return response;
    }

    // update lms course faq detail
    updateLmsCourseFaqDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_COURSE_FAQ_DETAIL, params),
            body,
        )

        return response;
    }

    // update lms course faq order
    updateLmsCourseFaqListOrder = async ({ body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_COURSE_FAQ_LIST_ORDER),
            body,
            { params: query }
        )

        return response;
    }

    // delete lms course faq
    deleteLmsCourseFaq = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_COURSE_FAQ_DETAIL, params)
        )

        return response;
    }

    // create lms course slug detail
    createLmsCourseSlugDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_COURSE_SLUG_DETAIL),
            body
        )

        return response;
    }

    // delete lms course slug detail
    deleteLmsCourseSlugDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_COURSE_SLUG_DETAIL, params)
        )

        return response;
    }

    // create lms course instructor detail
    createLmsCourseInstructorDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_COURSE_INSTRUCTOR_DETAIL),
            body
        )

        return response;
    }

    // update lms course instructor detail
    updateLmsCourseInstructorDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_COURSE_INSTRUCTOR_DETAIL, params),
            body
        )

        return response;
    }

    // delete lms course instructor detail
    deleteLmsCourseInstructorDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_COURSE_INSTRUCTOR_DETAIL, params)
        )

        return response;
    }

    // get lms course student list
    getLmsCourseStudentList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_COURSE_STUDENT_LIST),
            { params: query }
        )

        return response;
    }

    // create lms course student detail
    createLmsCourseStudentDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_COURSE_STUDENT_DETAIL),
            body
        )

        return response;
    }

    // update lms course student detail
    updateLmsCourseStudentDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_COURSE_STUDENT_DETAIL, params),
            body
        )

        return response;
    }

    // delete lms course student detail
    deleteLmsCourseStudentDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_COURSE_STUDENT_DETAIL, params)
        )

        return response;
    }

    // student lms course service
    getStudentLmsCourseList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_STUDENT_LMS_COURSE_LIST, params),
            { params: query }
        )

        return response;
    }

    getStudentFavLmsCourseList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_STUDENT_FAV_LMS_COURSE_LIST, params),
            { params: query }
        )

        return response;
    }

    createStudentFavLmsCourseDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_STUDENT_FAV_LMS_COURSE_DETAIL),
            body
        )

        return response;
    }

    getStudentLmsCourseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_STUDENT_LMS_COURSE_DETAIL, params)
        )

        return response
    }

    getLmsCourseInvitationList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_COURSE_INVITATION_LIST),
            { params: query }
        )

        return response;
    }

    createSendLmsCourseInvitationDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_SEND_LMS_COURSE_INVITATION_DETAIL),
            body
        )

        return response;
    }

    getValidateLmsCourseInvitationDetail = async ({ query }) => {

        const response = await baseAuthApiInstance().get(
            url.GET_VALIDATE_LMS_COURSE_INVITATION_DETAIL,
            { params: query }
        );

        return response;
    }

    getAcceptAndDeclineLmsCourseInvitationDetail = async ({ body, query }) => {

        const response = await baseAuthApiInstance().post(
            url.GET_ACCEPT_AND_DECLINE_LMS_COURSE_INVITATION_DETAIL,
            body,
            { params: query }
        )

        return response;
    }

    // lms course student section lesson service
    createDeleteLmsCourseStudentSectionLesson = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_DELETE_LMS_COURSE_STUDENT_SECTION_LESSON),
            body
        )

        return response
    }

    // lms course student quiz response service
    createLmsCourseStudentQuizResponseDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_COURSE_STUDENT_QUIZ_RESPONSE_DETAIL),
            body
        )

        return response
    }
    updateLmsCourseStudentQuizResponseDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.UPDATE_LMS_COURSE_STUDENT_QUIZ_RESPONSE_DETAIL, params),
            body
        )

        return response
    }

    // lms course student note service
    createLmsCourseStudentNoteDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_COURSE_STUDENT_NOTE_DETAIL),
            body
        )

        return response;
    }

    deleteLmsCourseInvitationDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_COURSE_INVITATION_DETAIL, params)
        )

        return response;
    }

    getLmsCourseStudentNoteList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_COURSE_STUDENT_NOTE_LIST),
            { params: query }
        )

        return response;
    }
    getLmsCourseStudentNoteDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_COURSE_STUDENT_NOTE_DETAIL, params),
        )

        return response;
    }
    updateLmsCourseStudentNoteDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_COURSE_STUDENT_NOTE_DETAIL, params),
            body
        )

        return response;
    }
    deleteLmsCourseStudentNoteDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_COURSE_STUDENT_NOTE_DETAIL, params)
        )

        return response;
    }
}

export default LmsCourseService.lmsCourseService;