import { MdOutlineLocationOn } from 'react-icons/md';

import { useAppState } from 'hooks/useStore';

const EventlyLocation = () => {
    const { userEventlyDetail } = useAppState((state) => state.evently)

    return (
        <div className={"w-full grid grid-cols-12 gap-x-2 px-5"}>
            <div className={"col-start-1 col-span-1 self-center"}>
                <MdOutlineLocationOn className={"text-text-700 text-lg"} />
            </div>
            <div className={"col-start-2 col-span-full self-center"}>
                <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide capitalize"}>
                    {userEventlyDetail?.data?.location || "-"}
                </span>
            </div>
        </div>
    )
}

export default EventlyLocation;