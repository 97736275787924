import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion";
import { MdOutlineClose } from "react-icons/md";

import CreateWithAiBtn from 'components/createWithAi/createWithAiIBtn';
import ClearFilter from 'components/pageFilter/ClearFilter';
import FilterIcon from 'components/pageFilter/FilterIcon';

import RefreshButton from 'pages/auth/edulyteLms/commonComponents/lmsButtons/RefreshButton';

import { getLmsQuizListPayload, lmsQuizFilters, searchParamsInfo } from '../lmsQuiz.data';
import QuizTitleFilter from './QuizTitleFilter';
import QuizStatusFilter from './QuizStatusFilter';
import QuizDateFilter from './QuizDateFilter';

import { getLmsQuizList } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetAddLmsQuiz, resetLmsQuizList } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.slice';
import { modalConst } from 'redux/local/local.const';

import { pagesInfo } from 'utils/pagesInfo';
import { cn } from 'utils/cn.utils';

const TableFilterActions = () => {
    const { modal } = useAppState((state) => state.local)
    const { lmsQuizList } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const [activeFilter, setActiveFilter] = useState(null)
    const [mobileFilter, setMobileFilter] = useState(false)

    let searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    const getQuizList = async () => {
        const requestDataPayload = await getLmsQuizListPayload(searchQueryParams)

        dispatcher(getLmsQuizList({ ...requestDataPayload }))
    }

    useEffect(() => {
        return () => {
            dispatcher(resetLmsQuizList())
        }
    }, [])

    useEffect(() => {
        getQuizList()
    }, [location.search])

    const onHandleRefresh = async () => {
        if (lmsQuizList?.isLoading) return;

        const requestDataPayload = await getLmsQuizListPayload(searchQueryParams)

        dispatcher(getLmsQuizList({ requestDataPayload }))
    }

    const onHandleCreateAiQuiz = () => {
        navigate(pagesInfo.CREATE_AI_QUIZ.pagePath)
    }

    const onHandleAddQuiz = () => {
        dispatcher(resetAddLmsQuiz())
        dispatcher(setModal({
            ...modal,
            [modalConst.CREATE_LMS_QUIZ_MODAL.stateKey]: true
        }))
    }

    const handleMobileFilter = () => {
        setMobileFilter(!mobileFilter)
    }

    const Filter = () => {
        return (
            <>
                {Object?.values(lmsQuizFilters)?.map((filterItem, index) => (
                    <div key={index}>
                        {(filterItem.key === searchParamsInfo.title.key) &&
                            <QuizTitleFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                        {(filterItem.key === searchParamsInfo.status.key) &&
                            <QuizStatusFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                        {(filterItem.key === searchParamsInfo.date.key) &&
                            <QuizDateFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                    </div>
                ))}
                <ClearFilter setFilterPayload={setActiveFilter} />
            </>
        )
    }

    return (
        <div className={"relative w-full px-6 py-3 bg-white shadow-md rounded-lg"}>
            <div className={"flex flex-col items-center justify-between gap-3 sm:flex-row sm:gap-5"}>
                <AnimatePresence initial={mobileFilter}>
                    {mobileFilter && (
                        <motion.div
                            initial={{ x: -200 }}
                            animate={{ x: 0 }}
                            exit={{ x: -200 }}
                            transition={{ duration: 0.3 }}
                            className='absolute left-0 top-0 h-screen w-60 flex flex-col gap-5 p-5 bg-white rounded lg:hidden shadow-md z-10'
                        >
                            <div className='flex justify-between items-center'>
                                <h3 className='text-lg font-bodyPri font-semibold text-text-900'>{"Filter"}</h3>
                                <MdOutlineClose size={20} onClick={() => setMobileFilter(false)} className='cursor-pointer' />
                            </div>
                            <Filter />
                        </motion.div>
                    )}
                </AnimatePresence>
                <div className='hidden w-full lg:flex flex-grow flex-wrap justify-center items-center sm:justify-start gap-3'>
                    <FilterIcon iconTooltip={"Filter Quizzes"} />
                    <Filter />
                </div>
                <div className={"flex w-full items-center justify-between lg:justify-end gap-3"}>
                    <div className='flex items-center gap-3'>
                        <div onClick={handleMobileFilter} className='block lg:hidden text-primary-main font-medium hover:text-secondary-dark whitespace-nowrap cursor-pointer'>
                            {"Filter"}
                        </div>
                        <RefreshButton onHandleRefreshButton={onHandleRefresh} />
                        <div className='hidden lg:block'>
                            <CreateWithAiBtn onHandleClickBtn={onHandleCreateAiQuiz} />
                        </div>
                        <div className='lg:hidden'>
                            <CreateWithAiBtn onHandleClickBtn={onHandleCreateAiQuiz} title='' />
                        </div>
                    </div>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        transition={{ duration: 0.3 }}
                        className={cn(
                            "py-2 px-3 md:px-5 flex items-center justify-center text-white text-sm md:text-base ease-in-out duration-200 bg-primary-dark hover:bg-secondary-dark rounded",
                            "font-bodyPri font-normal text-sm sm:text-base",
                        )}
                        onClick={onHandleAddQuiz}
                    >
                        <span className={""}>
                            {"Add a quiz"}
                        </span>
                    </motion.button>
                </div>
            </div>
        </div>
    )
}

export default TableFilterActions;