import { Outlet } from 'react-router-dom';

import { downloadSettingsNavigationOptions } from '../data';
import NavigationTabs from "../commonComponents/NavigationTabs";

const SettingsPage = () => {

    return (
        <div className={"flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3"}>
            <NavigationTabs options={Object.values(downloadSettingsNavigationOptions)} />
            <div className="w-full rounded-lg overflow-hidden p-5 bg-white">
                <Outlet />
            </div>
        </div>
    )
}

export default SettingsPage;