import { createSlice, } from "@reduxjs/toolkit";
import { EVENT_INITIAL_STATE } from "redux/event/event.initialState";
import { filterName, eventStatus } from "redux/event/event.const";
import { courseType } from "redux/course/course.const";


const event = createSlice({
    name: "event",
    initialState: EVENT_INITIAL_STATE,
    reducers: {
        // NEW
        // reducers for user event list
        setUser_EventListLoading: (state, { payload }) => {
            state.user_EventList.isLoading = payload
        },
        setUser_EventListData: (state, { payload }) => {
            state.user_EventList.data = payload
            state.user_EventList.message = EVENT_INITIAL_STATE.user_EventList.message
        },
        setUser_EventListMessage: (state, { payload }) => {
            state.user_EventList.message = payload
            state.user_EventList.data = EVENT_INITIAL_STATE.user_EventList.data
        },
        resetUserEventList: (state) => {
            state.user_EventList = EVENT_INITIAL_STATE.user_EventList
        },

        // OLD
        // Reducers for  Event List
        setUserEventListLoading: (state, { payload }) => {
            state.userEventList.isLoading = payload
        },
        setUserEventList: (state, { payload }) => {
            state.userEventList.userEventList = payload
            state.userEventList.filteredUserEventList = payload
            state.userEventList.errorMsg = EVENT_INITIAL_STATE.userEventList.errorMsg
        },
        setUserEventListErrorMsg: (state, { payload }) => {
            state.userEventList.errorMsg = payload
            state.userEventList.userEventList = EVENT_INITIAL_STATE.userEventList.userEventList
            state.userEventList.filteredUserEventList = EVENT_INITIAL_STATE.userEventList.filteredUserEventList
        },
        setClearUserEventList: (state) => {
            state.userEventList = EVENT_INITIAL_STATE.userEventList
        },

        // Reducers for Event Detail
        setEventDetailLoading: (state, { payload }) => {
            state.eventDetail.isLoading = payload
        },
        setEventDetail: (state, { payload }) => {
            state.eventDetail.eventDetail = payload
            state.eventDetail.errorMsg = EVENT_INITIAL_STATE.eventDetail.errorMsg
        },
        setEventDetailErrorMsg: (state, { payload }) => {
            state.eventDetail.errorMsg = payload
            state.eventDetail.eventDetail = EVENT_INITIAL_STATE.eventDetail.eventDetail
        },
        setClearEventDetail: (state) => {
            state.eventDetail.isLoading = EVENT_INITIAL_STATE.eventDetail.isLoading
            state.eventDetail.eventDetail = EVENT_INITIAL_STATE.eventDetail.eventDetail
            state.eventDetail.errorMsg = EVENT_INITIAL_STATE.eventDetail.errorMsg
        },

        // Reducers for update event
        setUpdateEventLoading: (state, { payload }) => {
            state.updateEvent.isLoading = payload
        },
        setUpdateEvent: (state, { payload }) => {
            state.updateEvent.updateEvent = payload
            state.updateEvent.errorMsg = EVENT_INITIAL_STATE.updateEvent.errorMsg
        },
        setUpdateEventErrorMsg: (state, { payload }) => {
            state.updateEvent.errorMsg = payload
            state.updateEvent.updateEvent = EVENT_INITIAL_STATE.updateEvent.updateEvent
        },
        setUpdateEventPayload: (state, { payload }) => {
            state.updateEvent.updateEventPayload = payload
        },
        setClearUpdateEvent: (state) => {
            state.updateEvent = EVENT_INITIAL_STATE.updateEvent
        },

        // Reducers for Filter Props
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
            const filteredList = payload.list
                .filter((event) => (
                    event.status === payload.filterProps.eventStatus || eventStatus.ALL.value === payload.filterProps.eventStatus
                ))
                .filter((event) => (
                    event.course.courseType === payload.filterProps.courseType || courseType.ALL.value === payload.filterProps.courseType
                ))

            switch (payload.filterName) {
                case filterName.SCHEDULE.value:
                    state.userEventList.filteredUserEventList = filteredList
                    break;
                default:
                    break;
            }
        },
        setClearFilterProps: (state) => {
            state.filterProps = EVENT_INITIAL_STATE.filterProps
        }
    }
})

export const {
    // NEW
    setUser_EventListLoading,
    setUser_EventListData,
    setUser_EventListMessage,
    resetUserEventList,

    // OLD
    setUserEventListLoading,
    setUserEventList,
    setUserEventListErrorMsg,
    setClearUserEventList,

    setEventDetailLoading,
    setEventDetail,
    setEventDetailErrorMsg,
    setClearEventDetail,

    setUpdateEventLoading,
    setUpdateEvent,
    setUpdateEventErrorMsg,
    setUpdateEventPayload,
    setClearUpdateEvent,

    setFilterProps,
    setClearFilterProps
} = event.actions

export default event.reducer