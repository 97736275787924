import { useEffect } from 'react';

import TokenTxnItem from 'pages/auth/wallet/tokenTxn/TokenTxnItem';

import ComponentLoader from 'components/loader/ComponentLoader';

import { getGptTokenTxnList } from 'redux/gpt/gpt.request';

import { resetGptTokenTxnList } from 'redux/gpt/gpt.slice';
import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { dayjs } from "utils/dateTime.utils";
import { cn } from "utils/cn.utils";

const TokenTxnListCard = () => {
    const { gptTokenTxnList } = useAppState(state => state.gpt)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(getGptTokenTxnList())

        return () => {
            dispatcher(resetGptTokenTxnList())
        }
    }, [])

    const sortTokenTxnList = (txn1, txn2) => {
        let dateTime1 = dayjs(txn1?.createdAt)
        let dateTime2 = dayjs(txn2?.createdAt)
        return (dateTime1.isBefore(dateTime2)) ? 1 : -1;
    }

    return (
        <div className="bg-white rounded-lg py-5 px-8 flex flex-col gap-5">
            <div className={cn(
                "flex flex-col gap-2 divide-y-2 h-[30rem] px-5 overflow-hidden overflow-y-auto",
                " scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full",
                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
            )}>
                <ComponentLoader isLoading={gptTokenTxnList.isLoading} />
                {!gptTokenTxnList.isLoading && gptTokenTxnList?.data?.slice()?.sort(sortTokenTxnList)?.map((tokenTxnItem, index) => (
                    <TokenTxnItem key={index} tokenTxnItem={tokenTxnItem} />
                ))}
                {!gptTokenTxnList.isLoading && (!gptTokenTxnList?.data || (gptTokenTxnList?.data?.length === 0)) &&
                    <span className={"h-full flex items-center justify-center font-bodyPri font-medium text-lg text-text-500"}>
                        {gptTokenTxnList.errorMsg || "No Transactions found!"}
                    </span>
                }
            </div>
        </div>
    )
};

export default TokenTxnListCard;