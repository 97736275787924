import { Grid } from "react-loader-spinner";

const GridLoader = ({ isLoading = false, containerClassName = "", wrapperStyle = {} }) => {
    return isLoading ? (
        <div className={`w-full h-full flex justify-center items-center ${containerClassName}`}>
            <Grid
                visible={true}
                height={"80"}
                width={"80"}
                color={"#4b5563"}
                ariaLabel={"grid-loading"}
                radius={"12.5"}
                wrapperStyle={wrapperStyle}
                wrapperClass={"grid-wrapper"}
            />
        </div>
    ) : null
}

export default GridLoader;