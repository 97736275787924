import { cn } from "utils/cn.utils";

import { AiOutlineQuestionCircle } from 'react-icons/ai';

import { useAppState } from 'hooks/useStore';
import { courseType } from 'redux/course/course.const';
import { bookingType } from 'redux/booking/booking.const';


const OrderSummery = () => {
    const { addBooking } = useAppState(s => s.booking)
    const addBookingPayload = addBooking?.addBookingPayload
    const { session } = useAppState(s => s.auth)
    const { user } = useAppState(s => s.user)

    let coursePrice = 0
    let courseDiscountPrice = 0
    let volumeDiscountPrice = 0
    let tutorDiscountPrice = 0
    let offerDiscountPrice = 0
    let totalDiscountPrice = 0
    if (addBookingPayload?.course?.courseType === courseType.ONE_ON_ONE.value) {
        coursePrice = parseFloat(addBookingPayload?.course?.price / 4.0) * (addBookingPayload?.sessionDuration / 15) * addBookingPayload?.noOfSessions
        if (addBookingPayload?.course?.bookingType === bookingType.TRIAL.value) {
            coursePrice = parseFloat(addBookingPayload?.course?.price / 4.0) * (60 / 15) * addBookingPayload?.noOfSessions
        }
        courseDiscountPrice = parseFloat(coursePrice * (addBookingPayload?.coursePctDiscount / 100))
        let volumePctDiscount = addBookingPayload?.noOfSessions === 5 ? addBookingPayload?.volumePctDiscount : 0
        volumeDiscountPrice = parseFloat((coursePrice - courseDiscountPrice) * (volumePctDiscount / 100))
    }
    if (addBookingPayload?.course?.courseType === courseType.GROUP.value) {
        coursePrice = parseFloat(addBookingPayload?.course?.price * addBookingPayload?.noOfSessions)
        courseDiscountPrice = parseFloat(coursePrice * (addBookingPayload?.coursePctDiscount / 100))
    }
    tutorDiscountPrice = parseFloat((coursePrice - courseDiscountPrice - volumeDiscountPrice) * (addBookingPayload?.tutorPctDiscount / 100))
    offerDiscountPrice = parseFloat((coursePrice - courseDiscountPrice - volumeDiscountPrice - tutorDiscountPrice) * (addBookingPayload?.offerPctDiscount / 100))
    totalDiscountPrice = courseDiscountPrice + volumeDiscountPrice + tutorDiscountPrice + offerDiscountPrice

    return (
        <div className={"p-5 rounded-lg space-y-3 border border-text-300"}>
            <div className={"w-full flex items-center justify-center"}>
                <span className={"text-center font-bodyPri font-medium text-md text-text-800"}>
                    {"Order Summary"}
                </span>
            </div>
            <div className={"space-y-1"}>

                {coursePrice !== 0 &&
                    <div className={"flex items-center justify-between font-bodyPri font-normal text-sm text-text-700"}>
                        <span>
                            {"Order price:"}
                        </span>
                        <span>
                            {"USD"} {parseFloat(coursePrice / 100).toFixed(2)}
                        </span>
                    </div>
                }

                {totalDiscountPrice !== 0 && <div className={"h-0.5 bg-divider-darkLight w-full rounded-full"}></div>}

                {totalDiscountPrice !== 0 &&
                    <div className={"flex items-center justify-between font-bodyPri font-normal text-sm text-text-700"}>
                        <div className={"flex items-center justify-center gap-1"}>
                            <span>
                                {"Discount:"}
                            </span>
                            <span className={"relative has-tooltip cursor-pointer"}>
                                <AiOutlineQuestionCircle className={"text-text-700"} />
                                <span className={cn(
                                    'tooltip z-50! bottom-5 -left-20 px-2 py-1 leading-0.5 text-center',
                                    'bg-black/90 text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide',
                                )}>
                                    {courseDiscountPrice !== 0 &&
                                        <div className={"flex items-center justify-center gap-1 whitespace-nowrap"}>
                                            <span>
                                                {"Course discount:"}
                                            </span>
                                            <span>
                                                {"USD"} {parseFloat(courseDiscountPrice / 100).toFixed(2)}
                                            </span>
                                        </div>
                                    }
                                    {volumeDiscountPrice !== 0 &&
                                        <div className={"flex items-center justify-center gap-1 whitespace-nowrap"}>
                                            <span>
                                                {"Volume discount:"}
                                            </span>
                                            <span>
                                                {"USD"} {parseFloat(volumeDiscountPrice / 100).toFixed(2)}
                                            </span>
                                        </div>
                                    }
                                    {tutorDiscountPrice !== 0 &&
                                        <div className={"flex items-center justify-center gap-1 whitespace-nowrap"}>
                                            <span>
                                                {"Tutor discount:"}
                                            </span>
                                            <span>
                                                {"USD"} {parseFloat(tutorDiscountPrice / 100).toFixed(2)}
                                            </span>
                                        </div>
                                    }
                                    {offerDiscountPrice !== 0 &&
                                        <div className={"flex items-center justify-center gap-1 whitespace-nowrap"}>
                                            <span>
                                                {"Offer discount:"}
                                            </span>
                                            <span>
                                                {"USD"} {parseFloat(offerDiscountPrice / 100).toFixed(2)}
                                            </span>
                                        </div>
                                    }
                                </span>
                            </span>
                        </div>
                        <span>
                            {"- USD"} {parseFloat(totalDiscountPrice / 100).toFixed(2)}
                        </span>
                    </div>
                }

                {(session?.isLoggedIn && user?.user) && <div className={"h-0.5 bg-divider-darkLight w-full rounded-full"}></div>}

                {(session?.isLoggedIn && user?.user) &&
                    <div className={"flex items-center justify-between font-bodyPri font-normal text-sm text-text-700"}>
                        <span>
                            {"Wallet used:"}
                        </span>
                        <span>
                            {"USD"} {parseFloat(addBookingPayload?.walletAmountToPay / 100).toFixed(2)}
                        </span>
                    </div>
                }

                {(session?.isLoggedIn && user?.user) && <div className={"h-0.5 bg-divider-darkLight w-full rounded-full"}></div>}

                {(session?.isLoggedIn && user?.user) &&
                    <div className={"flex items-center justify-between font-bodyPri font-normal text-sm text-text-700"}>
                        <span>
                            {"Promo used:"}
                        </span>
                        <span>
                            {"USD"} {parseFloat(addBookingPayload?.promoCreditUsed / 100).toFixed(2)}
                        </span>
                    </div>
                }
            </div>
        </div>
    )
}

export default OrderSummery;