import * as React from "react";
import { cn } from "utils/cn.utils";
import { FaCheck } from "react-icons/fa";

type TIndicator = {
  name: string;
  status: "complete" | "incomplete" | "in-progress";
};

const pageProgressIndicators: TIndicator[] = [
  {
    name: "Select a template",
    status: "complete",
  },
  {
    name: "Edit",
    status: "in-progress",
  },
  {
    name: "Publish",
    status: "incomplete",
  },
];

const ProgressIndicatorV2 = () => {
  const LineIndicator: React.FC<{ isComplete?: boolean }> = ({
    isComplete,
  }) => (
    <div className="flex w-20 md:w-40">
      <div
        className={cn(
          "bg-blue-500 w-full my-auto",
          isComplete ? "h-[3px]" : "h-px"
        )}
      ></div>
    </div>
  );
  return (
    <div className="max-w-3xl">
      <div className="flex px-4 pt-6 pb-10 bg-white border shadow-md rounded-2xl">
        <div className="flex px-16">
          {pageProgressIndicators.map((indicator, index) => (
            <>
              {index > 0 && (
                <LineIndicator isComplete={indicator.status !== "incomplete"} />
              )}
              <div className="flex items-center">
                <div
                  className={cn(
                    "relative flex justify-center items-center rounded-full bg-blue-500",
                    indicator.status !== "incomplete" ? "w-4 h-4" : "w-1 h-1"
                  )}
                >
                  <p
                    className={cn(
                      "text-xs absolute -bottom-5 whitespace-nowrap text-blue-500 tracking-wide",
                      indicator.status === "incomplete"
                        ? "font-light"
                        : "font-semibold"
                    )}
                  >
                    {indicator.name}
                  </p>
                  <p className="">
                    {indicator.status === "complete" && (
                      <FaCheck className="w-2 h-2 text-white" />
                    )}
                    {indicator.status === "in-progress" && (
                      <div className="w-1 h-1 rounded-full shadow-lg bg-blue-50"></div>
                    )}
                  </p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgressIndicatorV2;
