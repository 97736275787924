import { addLmsArticleDetailPayload, addLmsArticleInstructorDetailPayload } from "redux/edulyteLms/lmsArticle/lmsArticle.const";

export const LMS_ARTICLE_INITIAL_STATE= {
    lmsArticleList: {
        isLoading: false,
        data: null,
        message: null
    },
    lmsArticleDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addLmsArticleDetail: {
        isLoading: false,
        data: null,
        payload: addLmsArticleDetailPayload,
        message: null
    },
    updateLmsArticleDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    deleteLmsArticleDetail: {
        isLoading: false,
        message: null
    },

    // lms Article instructor
    addLmsArticleInstructorDetail: {
        isLoading: false,
        data: null,
        payload: addLmsArticleInstructorDetailPayload,
        message: null
    },
    modifyLmsArticleInstructorDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    destroyLmsArticleInstructorDetail: {
        isLoading: false,
        message: null
    }
}