import { gptTokenTxnActions } from 'redux/gpt/gpt.const';

import { dayjs, timeZone } from "utils/dateTime.utils";

const TokenTxnItem = ({ tokenTxnItem }) => {
    let tokenCount = 0;
    let color = null;

    if (tokenTxnItem.impact === 'debit') {
        tokenCount = "- " + tokenTxnItem.token
        color = 'text-primary-main'
    } else if (tokenTxnItem.impact === 'credit') {
        tokenCount = "+ " + tokenTxnItem.token
        color = 'text-green-600'
    } else {
        tokenCount = "(+/-) " + tokenTxnItem.token
        color = 'text-text-700'
    }

    return (
        <div className={"space-y-1"}>
            <div className="flex flex-row items-center justify-between">
                {tokenTxnItem?.action &&
                    <span className={"font-bodyPri font-medium text-base text-text-900"}>
                        {gptTokenTxnActions[(tokenTxnItem?.action?.toUpperCase() || "OTHER")]?.label}
                    </span>
                }
                <span className={`font-bodyPri font-normal text-sm text-text-900 ${color}`}>
                    {tokenCount}
                </span>
            </div>
            <div className={"flex flex-row items-center justify-between"}>
                <span className={"font-bodyPri font-normal text-sm text-text-900"}>
                    {dayjs(tokenTxnItem.createdAt).tz(timeZone).format('DD/MM/YY hh:mm a')}
                </span>
            </div>
        </div>
    )
}

export default TokenTxnItem;