import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

let timeZone = dayjs.tz.guess().toString()
// timeZone = "Asia/Calcutta"
// timeZone = "America/New_York"
// timeZone = "Australia/Adelaide"

const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

const generateUtcWeekDays = () => {
  let weekDays = {};
  const onWeekDays = 7;
  new Array(onWeekDays).fill("").forEach((_, i) => {
    const dayName = dayjs().utc().add(i, "day").format("dddd");
    const date = dayjs().utc().add(i, "day").format("YYYY-MM-DD");
    weekDays = {
      ...weekDays,
      [dayName.toUpperCase()]: {
        day: dayName,
        date: date
      }
    };
  });

  return weekDays;
};

const generateLocalWeekDays = (timeZone = dayjs.tz.guess().toString()) => {
  let weekDays = {};
  const onWeekDays = 7;
  new Array(onWeekDays).fill("").forEach((_, i) => {
    const dayName = dayjs().tz(timeZone).add(i, "day").format("dddd");
    const date = dayjs().tz(timeZone).add(i, "day").format("YYYY-MM-DD");
    weekDays = {
      ...weekDays,
      [dayName.toUpperCase()]: {
        day: dayName,
        date: date
      }
    };
  });
  return weekDays;
};


const getTimeZoneOffset = (timeZone) => {
  return dayjs().tz(timeZone).format("Z")
}

// const getTimeZoneOffset = () => {
//   var offset = new Date().getTimezoneOffset(),
//     o = Math.abs(offset);
//   return (
//     (offset < 0 ? "+" : "-") +
//     ("00" + Math.floor(o / 60)).slice(-2) +
//     ":" +
//     ("00" + (o % 60)).slice(-2)
//   );
// }

const generateTimeOption = (timeZone = dayjs.tz.guess().toString()) => {
  let timeOptions = {};
  new Array(24).fill("").forEach((_, hour) => {
    [0, 15, 30, 45].forEach((minute) => {
      let key = dayjs(dayjs().format("YYYY-MM-DD")).tz(timeZone).add(hour, "hour").add(minute, "minute").format("HHmmss");
      timeOptions[key] = {
        label: dayjs(dayjs().format("YYYY-MM-DD")).tz(timeZone).add(hour, "hour").add(minute, "minute").format("hh:mm A"),
        value: dayjs(dayjs().format("YYYY-MM-DD")).tz(timeZone).add(hour, "hour").add(minute, "minute").format("HH:mm:ss")
      };
    });
  });
  const keys = Object.keys(timeOptions).sort();
  let timeOptionList = keys.map((key) => {
    return {
      label: timeOptions[key].label,
      value: timeOptions[key].value
    };
  });

  return timeOptionList;
};

export {
  dayjs,
  timeZone,
  weekdays,
  generateUtcWeekDays,
  generateLocalWeekDays,
  getTimeZoneOffset,
  generateTimeOption,
}