import { useCallback, useEffect, useState } from "react";

import { AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import { FaSpinner } from "react-icons/fa";
import { IoIosCheckmark } from "react-icons/io";

import { motion } from "framer-motion";

import ToolTipView from "components/tooltipView";

import ViewContactDropdown from "../../../commonComponents/ViewContactDropdown";
import CreateLabel from "../../../commonComponents/createLabel";
import SaveAndCancelBtn from "components/modals/crmModals/commonComponents/SaveAndCancelBtn";

import { deleteCrmContactDetail, getCrmModalContactStatusList, updateCrmContactDetail } from "redux/crm/crm.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetModifyCrmContactDetailPayload, setModifyCrmContactDetailPayload } from "redux/crm/crm.slice";
import { crmContactCategoryEnum, modifyCrmContactDetailPayload } from "redux/crm/crm.const";
import { USER_DEMO_PROFILE } from "redux/user/user.const";
import { crmDetailBtnConst } from "components/modals/crmModals/data";

function ContactIdentity({ contact, onClose }) {
    const { crmModalContactStatusList, modifyCrmContactDetail, destroyCrmContactDetail } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()

    const [isShowActionBtn, setIsShowActionBtn] = useState(false)

    useEffect(() => {
        if (contact) {
            let contactPayload = {
                contact_id: contact?.id
            }
            if (contact?.category) {
                contactPayload["category"] = contact?.category
            }
            if (contact?.status) {
                contactPayload["status"] = { label: contact?.status?.name, value: contact?.status?.key }
            }
            if (!!contact?.contact_labels?.length) {
                contactPayload["labels"] = contact?.contact_labels?.map((labelItem) => ({
                    id: labelItem?.id,
                    label: labelItem?.label?.name,
                    value: labelItem?.label?.key,
                    color: labelItem?.label?.color
                }))
            }
            dispatcher(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                ...contactPayload
                
            }))
        }
    }, [contact])

    useEffect(() => {
        if (modifyCrmContactDetail?.payload?.category) {
            dispatcher(getCrmModalContactStatusList({ category: modifyCrmContactDetail?.payload?.category }))
        }
    }, [modifyCrmContactDetail?.payload?.category])

    useEffect(() => {
        if (modifyCrmContactDetail?.payload?.category && !!crmModalContactStatusList?.data?.length) {
            dispatcher(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                status: {
                    label: modifyCrmContactDetail?.payload?.status?.label || crmModalContactStatusList?.data[0]?.name,
                    value: modifyCrmContactDetail?.payload?.status?.value || crmModalContactStatusList?.data[0]?.key
                }
            }))
        }
    }, [crmModalContactStatusList?.data])

    const onHandleEditContact = async () => {
        dispatcher(resetModifyCrmContactDetailPayload())
        dispatcher(setModifyCrmContactDetailPayload({
            ...modifyCrmContactDetailPayload,
            isEditEnabled: true
        }))
    }

    const onHandleDeleteContact = useCallback(() => {
        if (destroyCrmContactDetail?.isLoading) return;
        if (!window.confirm("Are you sure?. You want to delete contact(s).")) return;

        dispatcher(deleteCrmContactDetail(contact?.id))
    }, [contact, destroyCrmContactDetail?.isLoading])

    const onHandleChangeBasicDetail = useCallback((key, value) => {
        if (modifyCrmContactDetail?.isLoading) return;

        let payload = {
            [key]: value
        }
        if (key === crmDetailBtnConst.category.key) {
            payload = {
                ...payload,
                status: { label: "", value: "" }
            }
        }
        dispatcher(setModifyCrmContactDetailPayload({
            ...modifyCrmContactDetail?.payload,
            ...payload
        }))
        setIsShowActionBtn(true)
    }, [modifyCrmContactDetail])

    const onHandleSaveDetail = () => {
        if (modifyCrmContactDetail?.isLoading) return;

        const requestPayload = {
            category: modifyCrmContactDetail?.payload?.category,
            status: modifyCrmContactDetail?.payload?.status?.value
        }
        dispatcher(updateCrmContactDetail(contact?.id, requestPayload))
    }

    const onHandleResetDetail = () => {
        if (modifyCrmContactDetail?.isLoading) return;

        dispatcher(setModifyCrmContactDetailPayload({
            ...modifyCrmContactDetail?.payload,
            category: contact?.category,
            status: { label: contact?.status?.name, value: contact?.status?.key}
        }))
        setIsShowActionBtn(false)
    }

    return (
        <div className={"w-full flex flex-col items-center justify-center sm:items-start sm:justify-start gap-5"}>
            <div className={"w-full flex flex-col-reverse gap-5 sm:flex-row justify-center sm:justify-between items-center sm:items-start"}>
                <div className={"w-full flex flex-col md:flex-row items-start gap-5"}>
                    <div className={"w-24 aspect-square rounded-full border border-primary-main"}>
                        <img
                            src={contact?.picture_url || USER_DEMO_PROFILE}
                            alt={`${contact?.first_name}`}
                            className={"w-full h-full rounded-full"}
                        />
                    </div>
                    <div className={"flex flex-col gap-1.5 items-center md:items-start md:mt-2"}>
                        <h2 className={"font-medium text-lg text-background-black capitalize line-clamp-1"}>
                            {`${contact?.title && contact?.title + "."} ${contact?.first_name} ${contact?.last_name}`}
                        </h2>
                        {contact?.address &&
                            <div className="flex items-center gap-2">
                                {contact?.address?.country_domain &&
                                    <div className="h-5 w-5 rounded-full overflow-hidden aspect-square">
                                        <img
                                            src={`https://flagcdn.com/16x12/${contact?.address?.country_domain}.png`.toLowerCase()}
                                            alt={"country-flag"}
                                            className={"w-full h-full object-cover"}
                                        />
                                    </div>
                                }
                                <p className="text-sm text-text-700 capitalize">
                                    {`${contact?.address?.city} ${(contact?.address?.city && contact?.address?.country) && ","} ${contact?.address?.country}`}
                                </p>
                            </div>
                        }
                        {(contact?.status && contact?.category) &&
                            <div className={"flex items-center justify-start gap-5"}>
                                <div className={"flex items-center justify-start gap-1.5"}>
                                    <ViewContactDropdown
                                        options={Object.values(crmContactCategoryEnum).filter((item) => item?.value !== modifyCrmContactDetail?.payload?.category)}
                                        value={crmContactCategoryEnum[modifyCrmContactDetail?.payload?.category?.toUpperCase()]?.label}
                                        onHandleSelect={(selectedOption) => onHandleChangeBasicDetail(crmDetailBtnConst.category.key, selectedOption?.value)}
                                        OptionChild={({ option }) => (
                                            <div className="bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between">
                                                {option.label}
                                                {(option.value === crmContactCategoryEnum[modifyCrmContactDetail?.payload?.category?.toUpperCase()]?.value)
                                                    && <IoIosCheckmark size={20} color="green" />
                                                }
                                            </div>
                                        )}
                                    />
                                    <span className={"text-sm text-text-700"}>
                                        {"/"}
                                    </span>
                                    <ViewContactDropdown
                                        isLoading={crmModalContactStatusList?.isLoading}
                                        options={crmModalContactStatusList?.data?.filter((statusItem) => (statusItem?.key !== contact?.status?.key))?.map((contactStatus) => ({
                                            label: contactStatus?.name,
                                            value: contactStatus?.key
                                        })) || []}
                                        value={modifyCrmContactDetail?.payload?.status?.label}
                                        onHandleSelect={(selectedOption) => onHandleChangeBasicDetail(crmDetailBtnConst.status.key, selectedOption)}
                                        OptionChild={({ option }) => (
                                            <div className="bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between">
                                                {option.label}
                                                {(option.value === modifyCrmContactDetail?.payload?.status?.value)
                                                    && <IoIosCheckmark size={20} color="green" />
                                                }
                                            </div>
                                        )}
                                    />
                                </div>
                                {isShowActionBtn &&
                                    <SaveAndCancelBtn
                                        isLoading={modifyCrmContactDetail?.isLoading}
                                        isShowSaveBtn={true}
                                        isShowCancelBtn={true}
                                        onHandleSaveBtn={onHandleSaveDetail}
                                        onHandleCancelBtn={onHandleResetDetail}
                                    />
                                }
                            </div>
                        }
                    </div>
                </div>

                <div className="flex items-center gap-3">
                    <ToolTipView content={"Delete Contact"}>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ duration: 0.3 }}
                            className="flex justify-center items-center h-8 w-8 text-xl hover:bg-secondary-light rounded-full ease-in-out duration-200"
                            onClick={onHandleDeleteContact}
                        >
                            {destroyCrmContactDetail?.isLoading &&
                                <FaSpinner className={"animate-spin text-primary-dark text-lg"} />
                            }
                            {!destroyCrmContactDetail?.isLoading &&
                                <AiOutlineDelete />
                            }
                        </motion.button>
                    </ToolTipView>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ duration: 0.3 }}
                        className="flex justify-center items-center h-8 px-5 text-white bg-primary-dark hover:bg-secondary-dark rounded ease-in-out duration-200"
                        onClick={onHandleEditContact}
                    >
                        {"Edit"}
                    </motion.button>
                    <ToolTipView content={"Close"}>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ duration: 0.3 }}
                            className={"flex justify-center items-center h-8 w-8 text-lg hover:bg-background-light rounded-full ease-in-out duration-200"}
                            onClick={onClose}
                        >
                            <AiOutlineClose />
                        </motion.button>
                    </ToolTipView>
                </div>
            </div>
            <div className={"w-full"}>
                <CreateLabel customLabelDropdownWrapper={"!min-w-[16rem]"} />
            </div>
        </div>
    );
}

export default ContactIdentity;