import { cn } from "utils/cn.utils";

import { PageContentStyle, Container } from './MeetingRoomPage.style';

const MeetingRoomMessage = ({ errorMsg = null }) => {

    const meetingErrorMsgImageUrl = "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/meeting/something-went-wrong.png"
    const contactUsLink = "https://www.edulyte.com/contact-us/"

    return (
        <PageContentStyle>
            <Container>
                <div className={"p-5 md:p-10 min-h-full"}>
                    <div className={"w-full flex flex-col items-center justify-center gap-5"}>
                        <div className={"w-36 h-36 sm:w-64 sm:h-64 md:w-[22rem] md:h-[22rem] aspect-square overflow-hidden"}>
                            <img src={meetingErrorMsgImageUrl}
                                className={"w-full h-full object-cover"}
                                alt={"Error"}
                            />
                        </div>
                        <div className={"w-full flex flex-col items-center justify-center gap-5"}>
                            <div className={"w-full font-bodyPri font-bold text-text-900 text-3xl tracking-wide text-center"}>
                                {"Oh no, something went wrong!"}
                            </div>
                            <div className={"w-full font-subHeading font-normal text-text-700 text-lg text-center"}>
                                {"So sorry, please try again. If the problem persists, please get in touch with our support team"}
                            </div>
                        </div>

                        <div className={"w-full flex items-center justify-center"}>
                            <a
                                href={contactUsLink}
                                target={"_blank"}
                                className={cn(
                                    "w-fit px-4 py-2 rounded-md bg-secondary-dark hover:opacity-90 cursor-pointer",
                                    "text-center font-buttons font-medium text-base text-text-50 whitespace-nowrap"
                                )}
                            >
                                <span className={""}>
                                    {"Contact Us"}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </Container>
        </PageContentStyle>
    )
}

export default MeetingRoomMessage