export const pageHeading = {
    heading: "Intergrations",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/tools.svg"
}

export const cardData = {
    google_calendar: {
        key: "google_calendar",
        title: "View Edulyte Events in Google Calendar",
        subTitle: "Connect Google Calendar to Edulyte to see your Edulyte events directly in your Google Calendar. Stay organised and never miss an important event.",
        logo: "https://staging-wpwwwdotcom-stage.kinsta.cloud/wp-content/uploads/2024/07/google-calendar-logo.png",
        name: "Google Calendar",
        learnMore: "https://www.edulyte.com/",
        active: false
    },
}