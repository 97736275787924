import { useState } from "react";
import { toast } from "react-toastify";
import SelectSearch, { fuzzySearch } from "react-select-search";

import { FaPlusCircle } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";

import ComponentLoader from "components/loader/ComponentLoader";
import { IconWithTextButton } from "components/common-components/Buttons";

import { profileEditBtn } from "pages/auth/profile/data";
import { createStudentUserCategoryDetailByUserId, deleteStudentCategoryDetailByStudentCategoryId, } from "redux/student/student.request";
import { createTutorUserCategoryDetailByUserId, deleteTutorCategoryDetailByTutorCategoryId, } from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { userRoles } from "redux/auth/auth.const";

const SubjectAndCategory = ({ editBtnConst, setEditBtnConst }) => {
    const { locals } = useAppState((s) => s.local)
    const { profList } = useAppState((s) => s.master)
    const { categoryList } = useAppState((s) => s.category)
    const { user } = useAppState((s) => s.user)
    const { studentUserCategoryList } = useAppState((s) => s.student)
    const { tutorUserCategoryList } = useAppState((s) => s.tutor)

    const [isVisible, setIsVisible] = useState(false)
    const [selectedCategoryProf, setSelectedCategoryProf] = useState({
        category: categoryList?.categoryList?.category,
        proficiency: profList?.profList?.proficiency
    });

    const dispatcher = useAppDispatcher();

    const onHandleSaveDetail = () => {
        if (tutorUserCategoryList?.isLoading || studentUserCategoryList?.isLoading) return;

        if (!selectedCategoryProf.category || !selectedCategoryProf.proficiency) {
            toast.warn("Please select category and level")
            return;
        }

        if (locals.userRole === userRoles.TUTOR.value) {
            const categoryProfList = tutorUserCategoryList?.tutorUserCategoryList
            const filteredCategoryProfList = categoryProfList?.filter((categoryProf) => (
                (categoryProf.category.categoryId === selectedCategoryProf.category) && categoryProf.proficiency.masterProficiencyId === selectedCategoryProf.proficiency
            ))

            if (filteredCategoryProfList.length > 0) {
                alert("Duplicate entries not allowed")
                return;
            }

            dispatcher(createTutorUserCategoryDetailByUserId(
                user?.user?.userId,
                {
                    categoryId: selectedCategoryProf.category,
                    masterProficiencyId: selectedCategoryProf.proficiency
                }
            ))
        }
        if (locals.userRole === userRoles.STUDENT.value) {
            const categoryProfList = studentUserCategoryList?.studentUserCategoryList
            const filteredCategoryProfList = categoryProfList?.filter((categoryProf) => (
                (categoryProf.category.categoryId === selectedCategoryProf.category) && categoryProf.proficiency.masterProficiencyId === selectedCategoryProf.proficiency
            ))

            if (filteredCategoryProfList.length > 0) {
                alert("Duplicate entries not allowed")
                return;
            }

            dispatcher(createStudentUserCategoryDetailByUserId(
                user?.user?.userId,
                {
                    categoryId: selectedCategoryProf.category,
                    masterProficiencyId: selectedCategoryProf.proficiency
                }
            ))
        }
        setIsVisible(false)
        setEditBtnConst(null)
    }

    const removeSelectedSubject = (categoryProf) => {
        if (tutorUserCategoryList?.isLoading || studentUserCategoryList?.isLoading) return;

        if (locals.userRole === userRoles.TUTOR.value) {
            dispatcher(deleteTutorCategoryDetailByTutorCategoryId(categoryProf?.tutorCategoryId))
        }

        if (locals.userRole === userRoles.STUDENT.value) {
            dispatcher(deleteStudentCategoryDetailByStudentCategoryId(categoryProf?.studentCategoryId))
        }
    }

    if (tutorUserCategoryList?.isLoading || studentUserCategoryList?.isLoading || categoryList?.isLoading || profList?.isLoading) {
        return (
            <ComponentLoader
                isLoading={tutorUserCategoryList?.isLoading || studentUserCategoryList?.isLoading || categoryList?.isLoading || profList?.isLoading
                }
                className={"min-h-[16rem]"}
            />
        )
    }

    return (
        <div className={"space-y-8"}>
            {/* Header Section */}
            <div className={"flex items-center justify-start gap-5"}>
                <span className={"text-xl font-medium text-text-800 font-bodyPri tracking-wide whitespace-nowrap"}>
                    {'Subject & Category:'}
                </span>
                {(!editBtnConst || (editBtnConst !== profileEditBtn.subject.value)) && (
                    <IconWithTextButton
                        placeholder={"Add New"}
                        icon={<FaPlusCircle />}
                        onClick={() => {
                            setSelectedCategoryProf({ category: null, proficiency: null });
                            setIsVisible(true);
                            setEditBtnConst(profileEditBtn.subject.value)
                        }}
                    />
                )}
            </div>

            {/* Items Section */}
            <div className={"space-y-10"}>
                <div className={"grid grid-cols-12 gap-5 md:gap-8"}>
                    {/* Column Headers */}
                    <div className={"col-start-1 col-span-4"}>
                        <span className={"text-md font-bold text-text-900 font-bodyPri tracking-wide leading-6"}>
                            {'Subject'}
                        </span>
                    </div>
                    <div className={"col-start-5 col-span-7"}>
                        <span className={"text-md font-bold text-text-900 font-bodyPri tracking-wide leading-6"}>
                            {'Preferred Level'}
                        </span>
                    </div>

                    {/* Displaying Categories for Tutor */}
                    {(locals.userRole === userRoles.TUTOR.value) && (
                        <div className={"col-start-1 col-span-12 space-y-3"}>
                            {(tutorUserCategoryList?.tutorUserCategoryList?.length > 0) ? (
                                tutorUserCategoryList.tutorUserCategoryList.map((categoryProf) => (
                                    <div key={categoryProf?.category?.categoryId} className={"grid grid-cols-3 gap-5"}>
                                        <div className={"col-span-1 font-bodyPri font-normal text-base text-text-800"}>
                                            {categoryProf?.category?.category}
                                        </div>
                                        <div className={"col-span-1 font-bodyPri font-normal text-base text-text-800"}>
                                            {categoryProf?.proficiency?.proficiency}
                                        </div>
                                        <div className={"col-span-1"}>
                                            <FaTimesCircle
                                                size={16}
                                                className={"text-red-700 cursor-pointer"}
                                                onClick={() => removeSelectedSubject(categoryProf)}
                                            />
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className={"text-center text-text-800 font-bodyPri font-semibold"}>
                                    {'No subject preferences added yet!'}
                                </div>
                            )}
                        </div>
                    )}

                    {/* Displaying Categories for Student */}
                    {(locals.userRole === userRoles.STUDENT.value) && (
                        <div className={"col-start-1 col-span-12 space-y-3"}>
                            {(studentUserCategoryList?.studentUserCategoryList?.length > 0) ? (
                                studentUserCategoryList.studentUserCategoryList.map((categoryProf) => (
                                    <div key={categoryProf?.category?.categoryId} className={"grid grid-cols-3 gap-5"}>
                                        <div className={"col-span-1 font-bodyPri font-normal text-base text-text-800"}>
                                            {categoryProf?.category?.category}
                                        </div>
                                        <div className={"col-span-1 font-bodyPri font-normal text-base text-text-800"}>
                                            {categoryProf?.proficiency?.proficiency}
                                        </div>
                                        <div className={"col-span-1"}>
                                            <FaTimesCircle
                                                size={16}
                                                className={"text-red-700 cursor-pointer"}
                                                onClick={() => removeSelectedSubject(categoryProf)}
                                            />
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className={"text-center text-text-800 font-bodyPri font-semibold"}>
                                    {'No subject preferences added yet!'}
                                </div>
                            )}
                        </div>
                    )}

                    {/* Error Handling */}
                    {(tutorUserCategoryList?.errorMsg || studentUserCategoryList?.errorMsg) && (
                        <div className={"col-start-1 col-span-12 text-center text-text-800 font-bodyPri font-semibold"}>
                            {tutorUserCategoryList?.errorMsg || studentUserCategoryList?.errorMsg}
                        </div>
                    )}
                </div>

                {/* Add New Category Section */}
                {isVisible && (
                    <>
                        <div className={"w-full flex flex-col gap-8 md:flex-row md:items-center md:gap-5"}>
                            <div className={"w-full h-48 z-20"}>
                                <SelectSearch
                                    options={categoryList?.categoryList?.map((category) => ({
                                        name: category.category,
                                        value: category.categoryId,
                                    }))}
                                    search
                                    autoFocus={false}
                                    placeholder="Select Category"
                                    filterOptions={fuzzySearch}
                                    value={selectedCategoryProf?.category}
                                    onChange={(option) => {
                                        setSelectedCategoryProf((prev) => ({
                                            ...prev,
                                            category: option,
                                        }));
                                    }}
                                />
                            </div>
                            <div className={"w-full h-48 z-20"}>
                                <SelectSearch
                                    options={profList?.profList?.map((proficiency) => ({
                                        name: proficiency.proficiency,
                                        value: proficiency.masterProficiencyId,
                                    }))}
                                    search
                                    autoFocus={false}
                                    placeholder="Select Level"
                                    filterOptions={fuzzySearch}
                                    value={selectedCategoryProf?.proficiency}
                                    onChange={(option) => {
                                        setSelectedCategoryProf((prev) => ({
                                            ...prev,
                                            proficiency: option,
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"flex justify-start items-center gap-5"}>
                            <button
                                className={"flex justify-center items-center cursor-pointer w-28 py-1 border border-red-500 text-red-500 rounded-full hover:bg-red-500 hover:text-text-50 font-buttons tracking-wide text-lg"}
                                onClick={() => {
                                    setEditBtnConst(null)
                                    setIsVisible(false)
                                }}
                            >
                                {"Cancel"}
                            </button>
                            <button
                                className={"flex justify-center items-center cursor-pointer w-28 py-1 border border-primary-main text-primary-main rounded-full hover:bg-primary-main hover:text-text-50 font-buttons tracking-wide text-lg"}
                                onClick={onHandleSaveDetail}
                            >
                                {"Done"}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default SubjectAndCategory;