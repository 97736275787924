import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import MUIHorizontalTabs from "components/MUIComponents/MUIHorizontalTabs";

import { walletTabConst } from "pages/auth/wallet/data";
import WalletTxnListCard from "pages/auth/wallet/walletTxn/WalletTxnListCard";
import WalletTxnChart from "pages/auth/wallet/walletTxn/WalletTxnChart";
import TokenTxnListCard from "pages/auth/wallet/tokenTxn/TokenTxnListCard";
import TokenTxnChart from "pages/auth/wallet/tokenTxn/TokenTxnChart";
import WithdrawalListCard from "pages/auth/wallet/withdrawal/WithdrawalListCard";
import WithdrawalListChart from "pages/auth/wallet/withdrawal/WithdrawalListChart";

const searchQueryKey = "view";

const WalletTabContent = () => {

    const navigate = useNavigate()
    const location = useLocation()

    let searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        if (!searchQueryParams.get(searchQueryKey)) {
            searchQueryParams.set(searchQueryKey, walletTabConst.WALLET_TRANSACTIONS.value)
            navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
        }
    }, [searchQueryParams])

    const onHandleChangeTab = useCallback((event, newValue) => {
        switch (newValue) {
            case walletTabConst.WALLET_TRANSACTIONS.value:
                searchQueryParams.set(searchQueryKey, walletTabConst.WALLET_TRANSACTIONS.value)
                navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
                break;
            case walletTabConst.TOKEN_TRANSACTIONS.value:
                searchQueryParams.set(searchQueryKey, walletTabConst.TOKEN_TRANSACTIONS.value)
                navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
                break;
            case walletTabConst.MY_WITHDRAWALS.value:
                searchQueryParams.set(searchQueryKey, walletTabConst.MY_WITHDRAWALS.value)
                navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
                break;
            default:
                searchQueryParams.set(searchQueryKey, walletTabConst.WALLET_TRANSACTIONS.value)
                navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
                break;
        }
    }, [location.search])

    return (
        <>
            <div className={"w-full bg-white p-3 rounded-lg"}>
                <MUIHorizontalTabs
                    tabList={Object.values(walletTabConst)}
                    activeTab={searchQueryParams.get(searchQueryKey) || walletTabConst.WALLET_TRANSACTIONS.value}
                    onHandleChangeTab={onHandleChangeTab}
                />
            </div>

            {(!!searchQueryParams.get(searchQueryKey) && (searchQueryParams.get(searchQueryKey) === walletTabConst.WALLET_TRANSACTIONS.value)) &&
                <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                    <WalletTxnListCard />
                    <WalletTxnChart />
                </div>
            }
            {(!!searchQueryParams.get(searchQueryKey) && (searchQueryParams.get(searchQueryKey) === walletTabConst.TOKEN_TRANSACTIONS.value)) &&
                <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                    <TokenTxnListCard />
                    <TokenTxnChart />
                </div>
            }
            {(!!searchQueryParams.get(searchQueryKey) && (searchQueryParams.get(searchQueryKey) === walletTabConst.MY_WITHDRAWALS.value)) &&
                <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                    <WithdrawalListCard />
                    <WithdrawalListChart />
                </div>
            }
        </>
    )
}

export default WalletTabContent;