// import { toast } from "react-toastify";

import orderService from "redux/order/order.service";
import {
    setUserOrderItemListLoading,
    setUserOrderItemListData,
    setUserOrderItemListMessage,

    setUserOrderItemDetailLoading,
    setUserOrderItemDetailData,
    setUserOrderItemDetailMessage,

    setConfirmationOrderLoading,
    setConfirmationOrderData,
    setConfirmationOrderMessage,
} from "redux/order/order.slice";

// user order list
export const getUserOrderItemList = (userId, query) => async (dispatch) => {
    dispatch(setUserOrderItemListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: query
        }
        const response = await orderService.getUserOrderItemList(requestData)
        if (response.status === 200) {
            dispatch(setUserOrderItemListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserOrderItemListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setUserOrderItemListLoading(false))
    }
}

// user order detail
export const getUserOrderItemDetail = (orderItemId, userId) => async (dispatch) => {
    dispatch(setUserOrderItemDetailLoading(true))

    try {
        const requestData = {
            params: { orderItemId: orderItemId, userId: userId }
        }
        const response = await orderService.getUserOrderItemDetail(requestData)
        if (response.status === 200) {
            dispatch(setUserOrderItemDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserOrderItemDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setUserOrderItemDetailLoading(false))
    }
}


// Order
export const confirmationOrderDetail = (id) => async (dispatch) => {
    dispatch(setConfirmationOrderLoading(true))

    try {
        const requestData = {
            params: { id: id }
        }
        const response = await orderService.confirmationOrderDetail(requestData)
        if (response.status === 200) {
            dispatch(setConfirmationOrderData(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setConfirmationOrderMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setConfirmationOrderLoading(false))
    }
}