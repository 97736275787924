import { memo } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const defaultSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    adaptiveHeight: true,
    customPaging: (i) => (
        <div className="slick-custom-dot relative flex items-center h-2 w-2 bg-background-medium rounded">
            <div className="slick-custom-dot"></div>
            <div className="slick-loading" />
        </div>
    ),
    responsive: [
        {
            breakpoint: 1350,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const ReactSlider = memo(({ sliderRef, settings = defaultSettings, children }) => {
    return (
        <Slider ref={sliderRef} {...settings}>
            {children}
        </Slider>
    )
})

export default ReactSlider;