import { modifyAppointmentDetailPayload } from "redux/appointment/appointment.const";

export const APPOINTMENT_INITIAL_STATE = {
    publicAppointments: {
        isLoading: false,
        data: null,
        status: null,
        message: null,
    },
    publicAppointment: {
        isLoading: false,
        data: null,
        status: null,
        message: null,
    },

    // user appointment list (product page)
    userAppointmentList: {
        isLoading: false,
        data: null,
        message: null
    },
    userAppointmentDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addAppointmentDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    modifyAppointmentDetail: {
        isLoading: false,
        data: null,
        payload: modifyAppointmentDetailPayload,
        message: null
    },
    destroyAppointmentDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    // location
    appointmentLocationList: {
        isLoading: false,
        data: null,
        message: null
    },
    addAppointmentLocationDetail: {
        isLoading: false,
        data: null,
        message: null,
    },
    modifyAppointmentLocationDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    destroyAppointmentLocationDetail: {
        isLoading: false,
        message: null
    },
}