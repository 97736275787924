import React, { useEffect, useState } from "react";

//context imports
import { useChat } from "../../contexts/chatContext";
import { useUser } from "../../contexts/userContext";

//helper functions
import deleteGroupUsers from "../../helpers/deleteGroupUsers";

//component imports
import Button from "../presentationcomponents/Button/Button";

//style imports
import {
  StyledGroupDetailsContainer,
  StyledCrossIconContainer,
  StyledGroupDetailsHeader,
  StyledGroupDetailsTitle,
  StyledGroupDetailsSubtitle,
  StyledGroupDetailsMember,
  StyledGroupDetailsMemberImage,
  StyledGroupDetailsMemberRole,
  StyledGroupDetailsMemberName,
  StyledGroupDetailsMemberList,
  // StyledRemoveMemberButton,
} from "./GroupDetails.styles";
import { ImCross } from "react-icons/im";

export default function GroupDetails({ setShowGroupDetails }) {
  const { groupMembers, selectedChat } = useChat();
  const { user } = useUser();
  const { userId } = user;
  const [removingUser, setRemovingUser] = useState(false);
  const currentMember = groupMembers.filter(
    (member) => member.id === userId
  )[0];
  const canDelete =
    currentMember.role === "moderator" || currentMember.role === "admin";

  useEffect(() => {
    if (selectedChat.type === "individual") {
      setShowGroupDetails(false);
    }
  }, [selectedChat, setShowGroupDetails]);

  function closeGroupMemberDetails() {
    setShowGroupDetails(false);
  }

  async function handleDelete(member) {
    if (removingUser) return;
    if (member.id === userId) return;
    if (!canDelete) return;
    try {
      setRemovingUser(true);
      await deleteGroupUsers(member.id, selectedChat.id);
      setRemovingUser(false);
    } catch (err) {
      setRemovingUser(false);
      console.log(err);
    }
  }

  const filterGroupMembers = (groupMember) => {
    if (['admin', 'moderator']?.includes(groupMember?.role)) {
      return groupMembers
    }

    if (groupMember?.role === 'participant') {
      return groupMembers?.filter((member) => ['admin', 'moderator']?.includes(member?.role) || member?.id === userId)
    }
  }

  return (
    <StyledGroupDetailsContainer>
      <StyledCrossIconContainer>
        <ImCross className={"text-primary-dark text-lg hover:opacity-90 cursor-pointer"} onClick={closeGroupMemberDetails}/>
      </StyledCrossIconContainer>
      <StyledGroupDetailsHeader>
        <img
          src={selectedChat.image}
          alt="group"
          className="w-12 h-12 object-cover rounded-full"
        />
        <StyledGroupDetailsTitle>
          {selectedChat.name}
        </StyledGroupDetailsTitle>
        <StyledGroupDetailsSubtitle>
          {`Group. ${groupMembers.length} members`}
        </StyledGroupDetailsSubtitle>
      </StyledGroupDetailsHeader>
      <StyledGroupDetailsTitle>Group Members</StyledGroupDetailsTitle>
      <StyledGroupDetailsMemberList>
        {groupMembers.filter(filterGroupMembers)?.map((member) => {
          return (
            <StyledGroupDetailsMember>
              <StyledGroupDetailsMemberImage
                src={member.image}
                alt={member.name}
                className="w-12 h-12 object-cover rounded-full"
              />
              <div>
                {member.name &&
                  <StyledGroupDetailsMemberName>
                    {member.name?.split(" ")[0] + " " + member.name?.split(" ")[1]?.charAt(0) + "."}
                  </StyledGroupDetailsMemberName>
                }
                <StyledGroupDetailsMemberRole>
                  {member.role.charAt(0).toUpperCase() + member.role.slice(1)}
                </StyledGroupDetailsMemberRole>
              </div>
              {/* {canDelete && member.id !== userId && (
                <StyledRemoveMemberButton
                  onClick={() => handleDelete(member)}
                  loading={removingUser}
                >
                  Remove
                </StyledRemoveMemberButton>
              )} */}
            </StyledGroupDetailsMember>
          );
        })}
      </StyledGroupDetailsMemberList>
      {/* <div className="mt-3 ">
        <Button
          text="Close"
          variant="secondary"
          onClick={closeGroupMemberDetails}
        />
      </div> */}
    </StyledGroupDetailsContainer>
  );
}
