import { courseSortBy } from "redux/course/course.const"

export const pageHeading = {
  heading: "Find Your Live Online Classes",
  subHeading: "Book your personalised, effective and flexible classes. Start learning live on video calls.",
  headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/messages.svg"
}

export const data = {
  filterText: {
    title: 'Choose your online live classes',
    titleDescription: 'Book your personalised, effective and flexible classes. Start learning live on video calls.',
    steps: [
      {
        step: '1',
        stepText: 'Choose a live classes',
      },
      {
        step: '2',
        stepText: 'Confirm booking',
      },
      {
        step: '3',
        stepText: 'Start learning',
      },
    ]
  },
  filters: {
    topics: {
      label: "TOPIC",
    },
    hourlyFees: {
      label: "PRICE",
    },
    availability: {
      label: "AVAILABILITY",
      dayTimeOptions: [
        {
          timeRange: "6-9",
          label: "Morning"
        },
        {
          timeRange: "9-12",
          label: "Late morning"
        },
        {
          timeRange: "12-15",
          label: "Afternoon"
        },
        {
          timeRange: "15-18",
          label: "Late afternoon"
        },
        {
          timeRange: "18-21",
          label: "Evening"
        },
        {
          timeRange: "21-24",
          label: "Late evening"
        },
        {
          timeRange: "0-3",
          label: "Night"
        },
        {
          timeRange: "3-6",
          label: "Late Night"
        },
      ],
      weekOptions: [
        {
          label: "Sun",
          value: "Sunday"
        },
        {
          label: "Mon",
          value: "Monday"
        },
        {
          label: "Tue",
          value: "Tuesday"
        },
        {
          label: "Wed",
          value: "Wednesday"
        },
        {
          label: "Thu",
          value: "Thursday"
        },
        {
          label: "Fri",
          value: "Friday"
        },
        {
          label: "Sat",
          value: "Saturday"
        },
      ]
    },
    tutorIsFrom: {
      label: "TUTOR IS FROM",
    },
    speaks: {
      label: "Speaks",
    },
    sessionType: {
      label: "Session Type",
      options: [
        // {
        //   title: "All Access",
        //   description: "See classes included with Learning Membership."
        // },
        {
          label: "One on One Class",
          value: "one_on_one",
          description: "Personalised and fully customisable sessions between a tutor and a learner on live video calls."
        },
        {
          label: "Group Class",
          value: "group",
          description: "Affordable small class sizes allow for personalised and more collaborative learning on live video meetings."
        }
      ]
    },
    studentLevel: {
      label: "Student Level",
    },
    sortBy: {
      label: "Sort by",
    }
  },
}

export const dropDownButtonConst = {
  TOPIC: {
    value: "topic"
  },
  PRICE: {
    value: "price"
  },
  AVAILABILITY: {
    value: "availability"
  },
  TUTOR_FROM: {
    value: "tutorFrom"
  },
  SPEAKS: {
    value: "speaks"
  },
  COURSE_TYPE: {
    value: "courseType"
  },
  STUDENT_LEVEL: {
    value: "studentLevel"
  },
  SORT_BY: {
    value: "sortBy"
  },
  CATEGORY: {
    value: "category"
  },
  FILTERS: {
    value: "filters"
  }
}

export const courseFilterConst = {
  PRICE: {
    label: "Price",
    value: "price"
  },
  AVAILABILITY: {
    label: "Availability",
    value: "availability"
  },
  TUTOR_IS_FROM: {
    label: "Tutor Is From",
    value: "tutorIsFrom"
  },
  SPEAKS: {
    label: "Speaks",
    value: "speaks"
  },
  CLASS_TYPE: {
    label: "Class Type",
    value: "class_type"
  },
  STUDENT_LEVEL: {
    label: "Student Level",
    value: "student_level"
  },
  // SORT_BY: {
  //   label: "Sort By",
  //   value: "sort_by"
  // }
}

export const courseTypeConst = [
  // {
  //   title: "All Access",
  //   description: "See classes included with Learning Membership."
  // },
  {
    label: "One on One Class",
    value: "one_on_one",
    description: "Personalised and fully customisable sessions between a tutor and a learner on live video calls."
  },
  {
    label: "Group Class",
    value: "group",
    description: "Affordable small class sizes allow for personalised and more collaborative learning on live video meetings."
  }
]

export const getQueryString = (filterObject) => {
  let query = ""

  if (filterObject?.page) {
    query += "&pn=" + filterObject?.page
  }
  if (filterObject?.sortBy !== courseSortBy?.RELEVANCE?.value && filterObject?.sortBy != undefined) {
    query += "&sortBy=" + filterObject?.sortBy?.toString()?.trim()
  }
  if (filterObject?.search) {
    query += "&search=" + filterObject?.search?.replaceAll(" ", "-")?.toString()?.toLowerCase()
  }
  if (filterObject?.priceType) {
    query += "&priceType=" + filterObject?.priceType?.replaceAll(" ", "-")?.toString()?.toLowerCase()
  }
  if (filterObject?.minPrice != 0 && filterObject?.minPrice != undefined) {
    query += "&minPrice=" + filterObject?.minPrice?.toString()
  }
  if (filterObject?.maxPrice != 0 && filterObject?.maxPrice != undefined) {
    query += "&maxPrice=" + filterObject?.maxPrice?.toString()
  }
  if (filterObject?.courseType?.length > 0) {
    query += "&classType=" + filterObject?.courseType?.toString()?.toLowerCase()
  }
  if (filterObject?.proficiency?.length > 0) {
    query += "&level=" + filterObject?.proficiency?.toString()?.replaceAll(" ", "-")?.toLowerCase()
  }
  if (filterObject?.tutorFrom?.length > 0) {
    query += "&tutorFrom=" + filterObject?.tutorFrom?.toString()?.replaceAll(" ", "-")?.toLowerCase()
  }
  if (filterObject?.speak?.length > 0) {
    query += "&speak=" + filterObject?.speak?.toString()?.replaceAll(" ", "-")?.toLowerCase()
  }
  if (filterObject?.day?.length > 0) {
    query += "&day=" + filterObject?.day?.toString()?.replaceAll(" ", "-")?.toLowerCase()
  }

  query = query?.length > 0 ? query?.slice(1) : ""

  if (filterObject?.category?.length > 0) {
    return ("/" + filterObject?.category[0]?.toString()?.replaceAll(" ", "-")?.toLowerCase() + "?" + query)
  }

  return query?.length > 0 ? "?" + query : ""
}