import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { MdEdit } from "react-icons/md";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdCancel } from "react-icons/md";

import DayItems from './DayItems';

import { editBtnConst } from 'pages/auth/editCourse/data';

import { updateOfferingByCourseId } from 'redux/offering/offering.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setOfferingCourse } from 'redux/offering/offering.slice';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { dayjs, timeZone } from "utils/dateTime.utils";


const CourseScheduleEditModal = () => {
    const { offeringCourse } = useAppState((s) => s.offering)
    const { modal } = useAppState((s) => s.local)

    const dispatcher = useAppDispatcher()
    const { courseId } = useParams()

    const [editBtn, setEditBtn] = useState(editBtnConst)

    const handleOnEditBtn = (editBtnValue) => {
        if (editBtn[editBtnValue].isEnable) {
            dispatcher(updateOfferingByCourseId(Number(courseId), {
                courseType: offeringCourse?.offeringCourse?.courseType,
                [editBtnValue]: offeringCourse?.offeringCourse?.[editBtnValue]

            }))
        }
        setEditBtn({
            ...editBtnConst,
            [editBtnValue]: {
                ...editBtn[editBtnValue],
                isEnable: !editBtn[editBtnValue].isEnable
            }
        })
    }

    const handleOnDone = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.COURSE_SCHEDULE_EDIT_MODAL.stateKey]: false
        }))
    }

    return (
        <div className={"space-y-5"}>
            <div className={"min-h-[24rem] flex flex-col gap-5 overflow-auto"}>
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"turncate text-center font-bodyPri font-medium text-lg text-text-900"}>
                        {offeringCourse?.offeringCourse?.courseTitle.substring(0, 50) + (offeringCourse?.offeringCourse?.courseTitle.length > 50 ? "..." : "")}
                    </span>
                </div>

                <div className={"w-full space-y-1 p-3 rounded-lg bg-primary-light border-2 border-divider-medium"}>
                    <div className={"w-full flex items-center justify-bwtween gap-2"}>
                        <div className={"flex w-full items-center justify-start gap-1"}>
                            <span className={"font-bodyPri font-normal text-sm text-text-800 whitespace-nowrap"}>
                                {"Starts on:"}
                            </span>
                            {!editBtn.startDate.isEnable &&
                                <span className={"font-bodyPri font-medium text-base text-primary-dark"}>
                                    {dayjs(offeringCourse?.offeringCourse?.startDate).tz(timeZone).format('ddd, DD MMM YYYY')}
                                </span>
                            }
                            {editBtn.startDate.isEnable &&
                                <input className={cn(
                                    "w-full inline-flex items-center justify-start outline-none cursor-pointer",
                                    "font-bodyPri font-medium text-base text-text-800 bg-white",
                                    "border-2 border-divider-medium rounded-lg px-3 py-0.5"
                                )}
                                    type={"date"}
                                    format={"ddd, DD MM YYYY"}
                                    value={dayjs(offeringCourse?.offeringCourse?.startDate).tz(timeZone).format("YYYY-MM-DD")}
                                    onChange={(event) => dispatcher(setOfferingCourse({
                                        ...offeringCourse?.offeringCourse,
                                        startDate: dayjs(event.target.value).tz(timeZone).format("YYYY-MM-DD")
                                    }))}
                                />
                            }
                        </div>
                        <div className={"text-lg cursor-pointer"}>
                            {!editBtn.startDate.isEnable &&
                                <MdEdit className={"text-secondary-dark"} onClick={() => setEditBtn({
                                    ...editBtnConst,
                                    startDate: {
                                        ...editBtnConst?.startDate, isEnable: true
                                    }
                                })} />
                            }
                            {editBtn.startDate.isEnable &&
                                <div className={"flex items-center justify-center gap-3"}>
                                    <span className={""} onClick={() => handleOnEditBtn(editBtnConst.startDate.value)}>
                                        <AiFillCheckCircle className={"text-green-500 text-lg cursor-pointer"} />
                                    </span>
                                    <span className={""} onClick={() => setEditBtn(editBtnConst)}>
                                        <MdCancel className={"text-text-500 text-lg cursor-pointer"} />
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={"w-full flex items-center justify-bwtween gap-2"}>
                        <div className={"flex w-full items-center justify-start gap-1"}>
                            <span className={"font-bodyPri font-normal text-sm text-text-800 whitespace-nowrap"}>
                                {"Ends on:"}
                            </span>
                            {!editBtn.endDate.isEnable &&
                                <span className={"font-bodyPri font-medium text-base text-primary-dark"}>
                                    {dayjs(offeringCourse?.offeringCourse?.endDate).tz(timeZone).format('ddd, DD MMM YYYY')}
                                </span>
                            }
                            {editBtn.endDate.isEnable &&
                                <input className={cn(
                                    "w-full inline-flex items-center justify-start outline-none cursor-pointer",
                                    "font-bodyPri font-medium text-base text-text-800 bg-white",
                                    "border-2 border-divider-medium rounded-lg px-3 py-0.5"
                                )}
                                    type={"date"}
                                    format={"ddd, DD MM YYYY"}
                                    value={dayjs(offeringCourse?.offeringCourse?.endDate).tz(timeZone).format("YYYY-MM-DD")}
                                    onChange={(event) => dispatcher(setOfferingCourse({
                                        ...offeringCourse?.offeringCourse,
                                        endDate: dayjs(event.target.value).tz(timeZone).format("YYYY-MM-DD")
                                    }))}
                                />
                            }
                        </div>
                        <div className={"text-lg cursor-pointer"}>
                            {!editBtn.endDate.isEnable &&
                                <MdEdit className={"text-secondary-dark"} onClick={() => setEditBtn({
                                    ...editBtnConst,
                                    endDate: {
                                        ...editBtnConst?.endDate, isEnable: true
                                    }
                                })} />
                            }
                            {editBtn.endDate.isEnable &&
                                <div className={"flex items-center justify-center gap-3"}>
                                    <span className={""} onClick={() => handleOnEditBtn(editBtnConst.endDate.value)}>
                                        <AiFillCheckCircle className={"text-green-500 text-lg cursor-pointer"} />
                                    </span>
                                    <span className={""} onClick={() => setEditBtn(editBtnConst)}>
                                        <MdCancel className={"text-text-500 text-lg cursor-pointer"} />
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className={"flex flex-col gap-3"}>
                    <div className={"flex items-center justify-between"}>
                        <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                            {`Session Duration: ${offeringCourse?.offeringCourse?.sessionDuration} min | Session Count: ${offeringCourse?.offeringCourse?.noOfSessions}`}
                        </span>
                        <div className={"self-end cursor-pointer"}>
                            {!editBtn.weeklyTimeSlots.isEnable &&
                                <MdEdit className={"text-secondary-dark"} onClick={() => setEditBtn({
                                    ...editBtnConst,
                                    weeklyTimeSlots: {
                                        ...editBtnConst?.weeklyTimeSlots, isEnable: true
                                    }
                                })} />
                            }
                            {editBtn.weeklyTimeSlots.isEnable &&
                                <div className={"flex items-center justify-center gap-3"}>
                                    <span className={""} onClick={() => handleOnEditBtn(editBtnConst.weeklyTimeSlots.value)}>
                                        <AiFillCheckCircle className={"text-green-500 text-lg cursor-pointer"} />
                                    </span>
                                    <span className={""} onClick={() => setEditBtn(editBtnConst)}>
                                        <MdCancel className={"text-text-500 text-lg cursor-pointer"} />
                                    </span>
                                </div>
                            }
                        </div>
                    </div>

                    <div className={"self-center w-fit grid grid-cols-2 gap-3"}>
                        {offeringCourse?.offeringCourse &&
                            Object.keys(offeringCourse?.offeringCourse?.weeklyTimeSlot).map((day, index) => (
                                <DayItems
                                    key={index}
                                    day={day}
                                    timeSlots={offeringCourse?.offeringCourse?.weeklyTimeSlot[day]}
                                    editBtnIsEnable={editBtn.weeklyTimeSlots.isEnable}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>

            <div className={"flex justify-end"}>
                <span onClick={handleOnDone} className={cn(
                    "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-normal text-base text-secondary-main",
                    "border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                )}>
                    {"Done"}
                </span>
            </div>

        </div>
    )
}

export default CourseScheduleEditModal