import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "redux/edulyteLms/lmsQuestion/lmsQuestion.const";

class LmsQuestionService {
    getLmsQuestionList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            url.GET_LMS_QUESTION_LIST,
            { params: query }
        )

        return response;
    }

    getLmsQuestionDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUESTION_DETAIL, params)
        )

        return response;
    }

    createLmsQuestionDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.CREATE_LMS_QUESTION_DETAIL,
            body
        )

        return response;
    }

    updateLmsQuestionDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUESTION_DETAIL, params),
            body
        )

        return response;
    }

    // add lms question resource
    createLmsQuestionResource = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUESTION_RESOURCE),
            body
        )

        return response;
    }

    // delete lms question resource
    deleteLmsQuestionResourceDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUESTION_RESOURCE_DETAIL, params)
        )

        return response;
    }

    // add lms question option
    createLmsQuestionOption = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUESTION_OPTION),
            body
        )

        return response;
    }

    // update lms question option
    updateLmsQuestionOptionDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUESTION_OPTION_DETAIL, params),
            body
        )

        return response;
    }

    // update order lms question option
    updateOrderLmsQuestionOption = async ({ body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_ORDER_LMS_QUESTION_OPTION),
            body, { params: query }
        )

        return response;
    }

    // delete lms question option
    deleteLmsQuestionOptionDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUESTION_OPTION_DETAIL, params)
        )

        return response;
    }

    // create lms question option resource
    createLmsQuestionOptionResource = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUESTION_OPTION_RESOURCE),
            body
        )

        return response;
    }

    // delete lms question option resource
    deleteLmsQuestionOptionResourceDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUESTION_OPTION_RESOURCE_DETAIL, params)
        )

        return response;
    }

    // update lms question answer
    updateLmsQuestionAnswerDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUESTION_ANSWER_DETAIL, params),
            body
        )

        return response;
    }

    // create lms question answer resource
    createLmsQuestionAnswerResource = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUESTION_ANSWER_RESOURCE),
            body
        )

        return response;
    }

    // delete lms question answer resource
    deleteLmsQuestionAnswerResourceDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUESTION_ANSWER_RESOURCE_DETAIL, params)
        )

        return response;
    }

    // lms question instructor detail
    createLmsQuestionInstructorDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUESTION_INSTRUCTOR_DETAIL),
            body
        )

        return response;
    }

    updateLmsQuestionInstructorDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUESTION_INSTRUCTOR_DETAIL, params),
            body
        )

        return response;
    }

    deleteLmsQuestionInstructorDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUESTION_INSTRUCTOR_DETAIL, params)
        )

        return response;
    }

    // lms question tag list
    getLmsQuestionTagList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUESTION_TAG_LIST),
            { params: query }
        )

        return response;
    }

    createLmsQuestionTagDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUESTION_TAG_DETAIL),
            body
        )

        return response;
    }

    updateLmsQuestionTagListOrder = async ({ body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUESTION_TAG_LIST_ORDER),
            body,
            { params: query }
        )

        return response;
    }

    deleteLmsQuestionTagDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUESTION_TAG_DETAIL, params)
        )

        return response;
    }

    createBulkQuestions = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_BULK_QUESTIONS),
            body
        )

        return response;
    }
}

export default new LmsQuestionService();