import tw, { styled } from 'twin.macro';

export const Container = styled.div`
    span:first-of-type {
        ${tw`hidden md:flex`}
    };
    span:last-of-type {
        ${tw`hidden md:flex`}
    };
    ${tw`
        flex flex-row items-center justify-center gap-1
    `}
`;

export const Text = tw.span`
    flex justify-center font-subHeading text-base font-normal text-text-800 w-full text-center whitespace-nowrap
`;