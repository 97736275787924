import { LocalLibrary } from '@mui/icons-material'
import tw, { styled } from 'twin.macro';

export const Container = tw.div`
    flex flex-col items-center justify-center
`;

export const TimeLineTitle = tw.span`
    font-subHeading font-bold text-3xl text-text-900 text-center mb-4
`;

export const TimeLineContainer = tw.div`
    flex flex-col w-full justify-center
`;

export const ItemContainer = tw.div`
    grid grid-cols-12 flex flex-row items-center justify-center w-full
    even:(flex-row-reverse)
`;

export const VerticalDivider = tw.div`
    grid col-start-6 col-span-2
    w-32 max-w-[8rem] h-full relative
    flex justify-center items-center
`;

export const Line = tw.div`
    bg-primary-main w-1.5 h-full
    absolute z-10 
`;

export const Circle = tw.div`
    bg-white w-10 h-10 rounded-full border-4 border-primary-main
    absolute z-10 flex items-center justify-center text-primary-main
`;

export const CircleIcon = styled(LocalLibrary)``;

export const CardArrow = tw.span`
    w-5 h-5 bg-red-500 absolute z-10 my-auto transform rotate-45 shadow-xl
`;

export const Card = tw.div`
    max-w-full w-full h-full
`;

export const Card1 = tw.div`
    grid col-start-1 col-span-5 flex items-center justify-end w-full
`;

export const Card2 = tw.div`
    grid col-start-8 col-span-5 flex items-center justify-start w-full
`;

export const StepText = tw.span`
    font-bulletPoints font-bold text-2xl text-text-900
`;

export const CardContainer = tw.div`
    bg-background-light w-full md:w-5/6 h-auto ml-5 md:ml-0 lg:ml-0 mr-5 my-3 shadow-lg rounded-xl px-5 py-3
    flex flex-col gap-2
`;

export const Heading = tw.h4`
    font-subHeading font-semibold text-xl text-text-900
`;

export const Description = tw.p`
    font-bodyPri font-normal text-base text-text-800
`;

export const MoreButton = tw.button`
    font-bold text-primary-main hover:text-secondary-main
`;