import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import { cn } from "utils/cn.utils";

import { FiEdit } from "react-icons/fi";
import { GiCancel } from "react-icons/gi";
import { FaPlusCircle } from "react-icons/fa";

import { IconWithTextButton } from "components/common-components/Buttons";
import ComponentLoader from 'components/loader/ComponentLoader';

import { tHeader, videoCategory } from "pages/auth/tutorOnboard/TutorOnboardData";

import {
    getOnboardingDocListByOnboardingId,
    createOnboardingDocDetailByOnboardingId,
    updateOnboardingDocDetailByOnboardingDocId,
    deleteOnboardingDocDetailByOnboardingDocId
} from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import {
    setClearAddOnboardingDocDetail,
    setAddOnboardingDocDetailPayload,
    setClearAddOnboardingDocDetailPayload,
    setDocumentCategoryList,
    setClearDocumentCategoryList,
    setClearOnboardingDocList
} from "redux/onboarding/onboarding.slice";
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { docType, documentStatus } from 'redux/onboarding/onboarding.const';
// import { setClearUploadDocument } from 'redux/document/document.slice';
import { onBoardingStatus } from 'redux/onboarding/onboarding.const';
import { resetUploadFile } from 'redux/storage/storage.slice';


const DocumentsTable = () => {
    const { modal } = useAppState((s) => s.local)
    // const { uploadDocument } = useAppState((state) => state.document)
    const { uploadFile } = useAppState((state) => state.storage)
    const { categoryList, onboardingDocList, addOnboardingDocDetail, userOnboardingDetail } = useAppState((s) => s.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    const [isUpdateDisabled, setIsUpdateDisabled] = useState(false)

    const filteredDocumentList = onboardingDocList?.onboardingDocList?.filter((documents) => documents?.docType !== videoCategory?.INTRO_VIDEO?.value)


    useEffect(() => {
        if (onboardingId) {
            dispatcher(getOnboardingDocListByOnboardingId(Number(onboardingId)))
        }

        return () => {
            dispatcher(setClearOnboardingDocList())
        }
    }, [onboardingId])

    // useEffect(() => {
    //     if (onboardingDocList?.onboardingDocList && filteredDocumentList?.length == 0) {
    //         dispatcher(setClearDocumentCategoryList())
    //         dispatcher(setDocumentCategoryList({
    //             categoryListItems: docType,
    //             isVideoUploadEnabled: false
    //         }))
    //         dispatcher(setModal({
    //             ...modal,
    //             [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: true
    //         }))
    //     }
    // }, [filteredDocumentList])

    useEffect(() => {
        if ([onBoardingStatus?.APPROVED?.value, onBoardingStatus?.REJECTED?.value, onBoardingStatus?.BLOCKED?.value]?.includes(userOnboardingDetail?.userOnboardingDetail?.status)) {
            setIsUpdateDisabled(true)
        }
    }, [userOnboardingDetail?.userOnboardingDetail?.status])

    useEffect(() => {
        if (uploadFile?.data && uploadFile?.data?.length > 0 && !addOnboardingDocDetail?.addOnboardingDocDetailPayload?.documentId && !categoryList?.categoryList?.isVideoUploadEnabled) {
            dispatcher(createOnboardingDocDetailByOnboardingId(
                Number(onboardingId),
                {
                    docType: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileCategory || Object.values(docType)[0]?.value,
                    docName: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileName,
                    docUrl: uploadFile?.data[0]?.fileUrl
                }
            ))
            dispatcher(resetUploadFile())
        }
        if (uploadFile?.data && addOnboardingDocDetail?.addOnboardingDocDetailPayload?.documentId && !categoryList?.categoryList?.isVideoUploadEnabled) {
            dispatcher(updateOnboardingDocDetailByOnboardingDocId(
                addOnboardingDocDetail?.addOnboardingDocDetailPayload?.documentId,
                {
                    docType: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileCategory || Object.values(docType)[0]?.value,
                    docName: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileName,
                    docUrl: uploadFile?.data[0]?.fileUrl
                }
            ))
            dispatcher(resetUploadFile())
        }
    }, [uploadFile?.data])

    // useEffect(() => {
    //     if (addOnboardingDocDetail?.addOnboardingDocDetail && !categoryList?.categoryList?.isVideoUploadEnabled) {
    //         dispatcher(setModal({
    //             ...modal,
    //             [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: false,
    //         }))
    //         dispatcher(setClearAddOnboardingDocDetail())
    //     }
    // }, [addOnboardingDocDetail?.addOnboardingDocDetail])

    const handleAddDocument = () => {
        if (isUpdateDisabled) return;
        dispatcher(setClearDocumentCategoryList())
        dispatcher(setClearAddOnboardingDocDetailPayload())
        dispatcher(setDocumentCategoryList({
            categoryListItems: docType,
            isVideoUploaded: false
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: true
        }))
    }

    const handleEditDocument = (document) => {
        if ([documentStatus?.APPROVED?.value, documentStatus?.REJECTED?.value]?.includes(document?.status)) return;

        const filteredDocument = filteredDocumentList?.find((doc) => doc?.onboardingDocId === document?.onboardingDocId)
        dispatcher(setDocumentCategoryList({
            categoryListItems: docType,
            isVideoUploaded: false
        }))
        dispatcher(setAddOnboardingDocDetailPayload({
            documentId: filteredDocument?.onboardingDocId,
            fileCategory: filteredDocument?.docType,
            fileName: filteredDocument?.docName,
            fileUrl: filteredDocument?.docUrl
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: true
        }))
    }

    const handleDeleteDocument = (document) => {
        if ([documentStatus?.APPROVED?.value, documentStatus?.REJECTED?.value]?.includes(document?.status)) return;

        dispatcher(setClearAddOnboardingDocDetail())
        dispatcher(deleteOnboardingDocDetailByOnboardingDocId(document?.onboardingDocId))
    }

    if (onboardingDocList?.isLoading || addOnboardingDocDetail?.isLoading) {
        return (
            <ComponentLoader isLoading={onboardingDocList?.isLoading || addOnboardingDocDetail?.isLoading} />
        )
    }

    if (filteredDocumentList && filteredDocumentList?.length === 0) {
        return (
            <div className={"col-span-full flex items-center justify-center gap-1.5"}>
                <IconWithTextButton
                    placeholder="Add a document"
                    icon={<FaPlusCircle />}
                    className={cn(isUpdateDisabled && "text-text-300")}
                    onClick={handleAddDocument}
                />
            </div>
        )
    }

    return (filteredDocumentList && filteredDocumentList?.length > 0) && (
        <div className={'space-y-3'}>
            <div className={'w-full flex flex-col justify-start gap-0.5'}>
                <span className='w-full flex justify-end'>
                    <IconWithTextButton
                        placeholder="Add New"
                        icon={<FaPlusCircle />}
                        className={cn(isUpdateDisabled && "text-text-300")}
                        onClick={handleAddDocument}
                    />
                </span>
                <div className={"inline-block min-w-full py-2 align-middle sm:px-6 md:px-0 overflow-x-auto"}>
                    <div className={cn(
                        "min-w-full lg:w-[48rem] border-b border-gray-200 rounded-lg shadow overflow-x-auto",
                        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                    )}>
                        <table className={"w-full border-separate rounded-lg"}>
                            <thead>
                                <tr>
                                    {tHeader.map((head, index) => (
                                        <th
                                            key={index}
                                            className={cn(
                                                "px-5 py-2 text-base font-medium font-bodyPri tracking-wider text-left text-text-600",
                                                "font-bold whitespace-nowrap border border-gray-200"
                                            )}
                                        >
                                            {head}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className={"divide-y divide-text-200"}>
                                {filteredDocumentList?.map((document, index) => (
                                    <tr className={''} key={index}>
                                        <td className="border border-gray-200">
                                            <span className='px-5 py-2 font-bodyPri font-medium text-text-900 text-base text-center truncate'>
                                                {docType[document?.docType?.toUpperCase()]?.label}
                                            </span>
                                        </td>
                                        <td className={"border border-gray-200"}>
                                            <a
                                                href={document?.docUrl}
                                                target={"_blank"}
                                                className='px-5 py-2 font-bodyPri font-medium text-primary-main text-base text-center truncate'>
                                                {document?.docName}
                                            </a>
                                        </td>
                                        <td className={"border border-gray-200"}>
                                            <span className={`px-2 font-bodyPri font-normal text-base italic text-center text-${documentStatus[document?.status?.toUpperCase()]?.textColor}`}>
                                                {documentStatus[document?.status?.toUpperCase()]?.textName}
                                            </span>
                                        </td>
                                        <td className={"px-5 py-2 border border-gray-200"}>
                                            <FiEdit
                                                className={cn(
                                                    "text-xl",
                                                    [documentStatus?.APPROVED?.value, documentStatus?.REJECTED?.value]?.includes(document?.status) && "text-text-300",
                                                    ![documentStatus?.APPROVED?.value, documentStatus?.REJECTED?.value]?.includes(document?.status) && "text-primary-main cursor-pointer hover:text-primary-dark"
                                                )}
                                                onClick={() => handleEditDocument(document)}
                                            />
                                        </td>
                                        <td className={"px-5 py-2 border border-gray-200"}>
                                            <GiCancel
                                                className={cn(
                                                    "text-xl",
                                                    [documentStatus?.APPROVED?.value, documentStatus?.REJECTED?.value]?.includes(document?.status) && "text-text-300",
                                                    ![documentStatus?.APPROVED?.value, documentStatus?.REJECTED?.value]?.includes(document?.status) && "text-text-500 hover:text-text-600 cursor-pointer"
                                                )}
                                                onClick={() => handleDeleteDocument(document)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentsTable;