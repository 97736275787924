import { Fragment, memo, useEffect, useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Dialog, Transition } from "@headlessui/react";

import { cn } from "utils/cn.utils";

import { AiOutlineClose } from "react-icons/ai";

import ButtonLoader from "components/loader/ButtonLoader";

import { createLmsQuestionDetail } from "redux/edulyteLms/lmsQuestion/lmsQuestion.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { resetAddLmsQuestionDetail, setAddLmsQuestionDetailPayload } from "redux/edulyteLms/lmsQuestion/lmsQuestion.slice";
import { modalConst } from "redux/local/local.const";

import { pagesInfo } from "utils/pagesInfo";
import { EDULYTE_APP_DOMAIN_URL } from "const/default.const";

const MAX_LENGTH = 512

const CreateLmsQuestionModal = memo(({
    openModal = false,
    onCloseModal,
    maxWidth = "max-w-2xl",
    bgColor = "bg-white",
}) => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { addLmsQuestionDetail } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const navigate = useNavigate()

    const [questionError, setQuestionError] = useState({
        title: ""
    })

    useEffect(() => {

        return () => {
            dispatcher(resetAddLmsQuestionDetail())
        }
    }, [])

    useEffect(() => {
        if (addLmsQuestionDetail?.data) {
            const questionId = addLmsQuestionDetail?.data?.id
            dispatcher(resetAddLmsQuestionDetail())
            dispatcher(setModal({
                ...modal,
                [modalConst.CREATE_LMS_QUESTION_MODAL.stateKey]: false
            }))
            window.open(`${EDULYTE_APP_DOMAIN_URL}${pagesInfo.EDIT_LMS_QUESTION.pagePath}/${questionId}/edit`, "_self")
        }
    }, [addLmsQuestionDetail?.data])

    const closeModal = () => {
        setQuestionError({
            title: ""
        })
        navigate(location.pathname)
        onCloseModal(false)
    }

    const onHandleChangeTitle = useCallback((event) => {
        dispatcher(setAddLmsQuestionDetailPayload({
            ...addLmsQuestionDetail?.payload,
            title: event.target.value
        }))
    }, [addLmsQuestionDetail?.payload])

    const handleCreateBtn = () => {
        if (addLmsQuestionDetail.isLoading) return;

        if (!addLmsQuestionDetail?.payload?.title || addLmsQuestionDetail?.payload?.title.length === 0) {
            setQuestionError({
                ...questionError,
                title: "Title is required!"
            })
            return
        }
        const body = {
            owner_user_id: Number(user.user.userId),
            title: addLmsQuestionDetail?.payload?.title
        }
        dispatcher(createLmsQuestionDetail(body))
        setQuestionError({
            title: ""
        })
    }

    return (
        <Transition appear show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className="fixed max-h-screen inset-0 z-50 overflow-y-auto"
                open={openModal}
                onClose={closeModal}
            >
                <div className="p-5 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span aria-hidden="true" className="inline-block h-screen align-middle">&#8203;</span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={cn(
                            "inline-block w-full shadow-xl rounded-lg space-y-5 overflow-hidden",
                            `text-left align-middle transition-all transform ${maxWidth} ${bgColor}`
                        )}>
                            <Dialog.Description as="div">
                                <div className={cn(
                                    "w-full h-full overflow-hidden overflow-y-scroll",
                                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                                )}>
                                    <div className={"flex w-full justify-between items-center bg-background-light h-16 px-5 drop-shadow-sm"}>
                                        <p className="font-bodyPri font-medium text-text-900 text-lg">
                                            {"Create a question"}
                                        </p>
                                        <AiOutlineClose
                                            onClick={closeModal}
                                            className={'cursor-pointer'}
                                            size={20}
                                        />
                                    </div>
                                    <div className={"flex flex-col gap-5 bg-white p-10"}>
                                        <div className={"w-full flex flex-col gap-1.5"}>
                                            <input className={cn(
                                                "px-3 h-12 flex w-full focus:outline-none rounded-lg border border-background-lightDark focus:border focus:border-primary-main",
                                                "font-bodyPri font-normal text-text-800 text-base placeholder:text-text-500"
                                            )}
                                                type={"text"}
                                                placeholder={'Add Question Title'}
                                                maxLength={MAX_LENGTH}
                                                value={addLmsQuestionDetail?.payload.title}
                                                onChange={onHandleChangeTitle}
                                            />
                                            <div className={"w-full flex justify-end"}>
                                                {addLmsQuestionDetail?.payload.title?.length > 0 &&
                                                    <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                                                        {`${addLmsQuestionDetail?.payload.title?.length}/${MAX_LENGTH}`}
                                                    </span>
                                                }
                                            </div>
                                            <div className={"flex items-center justify-start"}>
                                                {(questionError?.title || addLmsQuestionDetail?.message) &&
                                                    <span className={"font-bodyPri font-normal text-sm text-red-500 tracking-wide"}>
                                                        {questionError?.title || addLmsQuestionDetail?.message}
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <button
                                            className={cn(
                                                'font-bodyPri py-2 w-36 bg-primary-dark text-white rounded-full hover:bg-secondary-dark ease-in-out duration-100',
                                                addLmsQuestionDetail?.isLoading && "bg-primary-dark"
                                            )}
                                            onClick={handleCreateBtn}
                                        >
                                            {addLmsQuestionDetail?.isLoading &&
                                                <ButtonLoader isLoading={addLmsQuestionDetail?.isLoading} />
                                            }
                                            {!addLmsQuestionDetail?.isLoading && "Create"}
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Description>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
});

export default CreateLmsQuestionModal;