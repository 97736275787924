import { useCallback } from "react";

import AddItemBtn from "components/modals/crmModals/commonComponents/AddItemBtn";
import ContactPhoneItem from "components/modals/crmModals/createCrmContactDetailModal/commonComponents/ContactPhoneItem";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setAddCrmContactDetailPayload } from "redux/crm/crm.slice";
import { crmContactLabelsEnum } from "redux/crm/crm.const";

function AddContactNumbers() {
    const { countryList, timeZoneCountryDetail } = useAppState((state) => state.master)
    const { addCrmContactDetail } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()

    const onHandleAddNewPhone = useCallback(() => {
        const newId = (addCrmContactDetail?.payload?.phones?.length > 0)
            ? addCrmContactDetail?.payload?.phones[addCrmContactDetail?.payload?.phones?.length - 1].id + 1
            : 1

        let myLocalCountryList = countryList?.countryList?.filter((country) => (
            country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
        ))

        if (!myLocalCountryList || myLocalCountryList?.length === 0) {
            myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.countryDomain === "US"
            ))
        }
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            phones: !!addCrmContactDetail?.payload?.phones?.length
                ? [...addCrmContactDetail?.payload?.phones, { id: newId, country: myLocalCountryList[0], phone: "", label: { label: crmContactLabelsEnum.HOME.label, value: crmContactLabelsEnum.HOME.value }, is_primary: false }]
                : [{ id: newId, country: myLocalCountryList[0], phone: "", label: { label: crmContactLabelsEnum.HOME.label, value: crmContactLabelsEnum.HOME.value }, is_primary: true }]
        }))
    }, [addCrmContactDetail?.payload, countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

    const onHandleChangePhoneDetail = useCallback((phoneId, updatedValue) => {
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            phones: addCrmContactDetail?.payload?.phones?.map((phoneItem) => (
                (phoneItem?.id === phoneId) ? updatedValue : phoneItem
            ))
        }))
    }, [addCrmContactDetail?.payload])

    const onHandlePrimaryPhoneDetail = useCallback((phoneId, updatedValue) => {
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            phones: addCrmContactDetail?.payload?.phones?.map((phoneItem) => (
                (phoneItem?.id === phoneId) ? updatedValue : { ...phoneItem, is_primary: false }
            ))
        }))
    }, [addCrmContactDetail?.payload])

    const onHandleDeletePhoneDetail = useCallback((phoneId) => {
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            phones: addCrmContactDetail?.payload?.phones?.filter((phone) => phone?.id !== phoneId)
        }))
    }, [addCrmContactDetail?.payload])

    return (
        <div className="w-full flex flex-col gap-8 items-center">
            {!!addCrmContactDetail?.payload?.phones?.length &&
                addCrmContactDetail?.payload?.phones?.map((phoneItem, index) => (
                    <ContactPhoneItem
                        key={index}
                        index={index}
                        isShowCancelBtn={true}
                        phoneItem={phoneItem}
                        onHandleChangePhoneDetail={onHandleChangePhoneDetail}
                        onHandlePrimaryPhoneDetail={onHandlePrimaryPhoneDetail}
                        onHandleDeletePhoneDetail={onHandleDeletePhoneDetail}
                    />
                ))}
            <AddItemBtn
                btnTitle={"Add Phone"}
                onHandleAddBtn={onHandleAddNewPhone}
            />
        </div>
    );
}

export default AddContactNumbers;