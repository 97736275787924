import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { IoIosArrowForward } from "react-icons/io";
import { ImCross } from "react-icons/im";
import { FiFilter } from "react-icons/fi";

import SideOverPanel from "components/common-components/SideOverPanel";

import FilterCourseSideOverContent from './FilterCourseSideOverContent';
import { dropDownButtonConst, getQueryString } from '../../data';

import { useAppState } from 'hooks/useStore';
import { courseFilterProps, courseSortBy } from 'redux/course/course.const';

import { pagesInfo } from 'utils/pagesInfo';

const FilterCourse = ({ dropDownButton, setDropDownButton }) => {
    const { filterProps } = useAppState((state) => state.course)

    const navigate = useNavigate()

    const [sideOverPanel, setSideOverPanel] = useState(false)
    const [totalFilterCount, setTotalFilterCount] = useState(0)
    const [isCourseFilterHoverEnabled, setIsCourseFilterHoverEnabled] = useState(false)

    useEffect(() => {
        let count = 0
        if (filterProps?.priceType?.length > 0) {
            count++
        }
        if (filterProps?.minPrice != 0) {
            count++
        }
        if (filterProps?.maxPrice != 0) {
            count++
        }
        if (filterProps?.day?.length > 0) {
            count++
        }
        if (filterProps?.tutorFrom?.length > 0) {
            count++
        }
        if (filterProps?.language?.length > 0) {
            count++
        }
        if (filterProps?.courseType?.length > 0) {
            count++
        }
        if (filterProps?.proficiency?.length > 0) {
            count++
        }
        // if (filterProps?.sortBy !== courseSortBy?.RELEVANCE?.value) {
        //     count++
        // }

        setTotalFilterCount(count)
    }, [filterProps])

    const throttle = (callbackFunc, delay) => {
        let inProgress = false
        return (...args) => {
            if (inProgress) return;
            callbackFunc(...args)
            inProgress = true
            setTimeout(() => {
                inProgress = false
            }, delay)
        }
    }

    const onHandleResize = throttle(() => {
        if (window.innerWidth > 1024) {
            setSideOverPanel(false)
        } else return;
    }, 1000)

    useEffect(() => {
        window.addEventListener("resize", onHandleResize)

        return () => {
            window.removeEventListener("resize", onHandleResize)
        }
    }, [])

    return (
        <>
            <SideOverPanel
                child={<FilterCourseSideOverContent onClose={setSideOverPanel} />}
                isOpen={sideOverPanel}
                onClose={null}
                enterFrom={"bottom"}
                enterTo={"top"}
                leaveFrom={"top"}
                leaveTo={"bottom"}
            />
            <div
                className={"relative w-1/2 bg-white px-3 py-2.5 cursor-pointer flex items-center justify-between gap-3 rounded-r-lg group"}
                onClick={() => {
                    if (!dropDownButton || dropDownButton !== dropDownButtonConst?.FILTERS?.value) {
                        setDropDownButton(dropDownButtonConst?.FILTERS?.value)
                    }
                    if (dropDownButton === dropDownButtonConst?.FILTERS?.value) {
                        setDropDownButton(null)
                    }
                    setSideOverPanel(true)
                }}
            >
                <div className={"flex items-center justify-start gap-2"}>
                    <FiFilter className={"text-lg text-primary-dark"} />
                    <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide group-hover:text-primary-dark"}>
                        {"Filters"}
                    </span>
                </div>
                {totalFilterCount > 0 &&
                    <div
                        className={"absolute top-3 left-28 z-20 w-5 h-5 flex items-center justify-start rounded-full bg-primary-dark"}
                        onMouseOver={() => setIsCourseFilterHoverEnabled(true)}
                        onMouseOut={() => setIsCourseFilterHoverEnabled(false)}
                    >
                        {!isCourseFilterHoverEnabled &&
                            <span className={"w-full text-sm text-white text-center p-0.5"}>
                                {totalFilterCount}
                            </span>
                        }
                        {isCourseFilterHoverEnabled &&
                            <ImCross
                                className={"w-full text-sm text-white text-center p-0.5 cursor-pointer"}
                                onClick={() => {
                                    const query = getQueryString(courseFilterProps)
                                    navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
                                    setSideOverPanel(false)
                                }}
                            />
                        }
                    </div>
                }
                <span className={""}>
                    <IoIosArrowForward className={"text-base text-text-600"} />
                </span>
            </div>
        </>
    )
}

export default FilterCourse