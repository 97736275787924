export const sessionNoteConst = {
    PENDING: {
        value: "pending",
        isNoteRequired: false,
    },
    DECLINED: {
        value: "declined",
        isNoteRequired: true,
    },
    RESCHEDULED: {
        value: "rescheduled",
        isNoteRequired: false,
    },
    SCHEDULED: {
        value: "scheduled",
        isNoteRequired: false,
    },
    ON_GOING: {
        value: "on_going",
        isNoteRequired: false,
    },
    COMPLETED: {
        value: "completed",
        isNoteRequired: false,
    },
    CANCELLED: {
        value: "cancelled",
        isNoteRequired: true,
    },
}
