import { dayjs } from "utils/dateTime.utils";

export const dateRangeConst = {
    TODAY: {
        label: "Today"
    },
    YESTERDAY: {
        label: "Yesterday"
    },
    LAST_SEVEN_DAYS: {
        label: "Last 7 Days"
    },
    LAST_THIRTY_DAYS: {
        label: "Last 30 Days"
    },
    THIS_MONTH: {
        label: "This Month"
    },
    LAST_MONTH: {
        label: "Last Month"
    },
}

export const dateRangesLabels = {
    [dateRangeConst.TODAY.label]: [dayjs().toDate(), dayjs().toDate()],
    [dateRangeConst.YESTERDAY.label]: [
        dayjs().subtract(1, 'days').toDate(),
        dayjs().subtract(1, 'days').toDate(),
    ],
    [dateRangeConst.LAST_SEVEN_DAYS.label]: [
        dayjs().subtract(6, 'days').toDate(),
        dayjs().toDate()
    ],
    [dateRangeConst.LAST_THIRTY_DAYS.label]: [
        dayjs().subtract(29, 'days').toDate(),
        dayjs().toDate()
    ],
    [dateRangeConst.THIS_MONTH.label]: [
        dayjs().startOf('month').toDate(),
        dayjs().endOf('month').toDate()
    ],
    [dateRangeConst.LAST_MONTH.label]: [
        dayjs().subtract(1, 'month').startOf('month').toDate(),
        dayjs().subtract(1, 'month').endOf('month').toDate(),
    ]
}