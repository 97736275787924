import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { cn } from "utils/cn.utils";

import { AiOutlineClose } from "react-icons/ai";

import ButtonLoader from "components/loader/ButtonLoader";

import { createLmsLectureDetail } from "redux/edulyteLms/lmsLecture/lmsLecture.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { resetAddLmsLectureDetail } from "redux/edulyteLms/lmsLecture/lmsLecture.slice";
import { modalConst } from "redux/local/local.const";

import { pagesInfo } from "utils/pagesInfo";
import { EDULYTE_APP_DOMAIN_URL } from "const/default.const";

const MAX_LENGTH = 128;

const CreateLmsLectureModal = ({
    openModal = false,
    onCloseModal,
    onBackModal,
    maxWidth = "max-w-2xl",
    bgColor = "bg-white",
}) => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { addLmsLectureDetail } = useAppState((state) => state.lms.lmsLecture)

    const dispatcher = useAppDispatcher()

    const [title, setTitle] = useState('');
    const [lectureError, setLectureError] = useState(null)

    useEffect(() => {
        return () => {
            setTitle("")
            setLectureError("")
        }
    }, [])

    useEffect(() => {
        if (addLmsLectureDetail?.data) {
            const lectureId = addLmsLectureDetail?.data?.lecture_id
            dispatcher(resetAddLmsLectureDetail())
            setTitle("")
            setLectureError("")
            dispatcher(setModal({
                ...modal,
                [modalConst.CREATE_LMS_LECTURE_MODAL.stateKey]: false
            }))
            window.open(`${EDULYTE_APP_DOMAIN_URL}${pagesInfo.EDIT_LMS_LECTURE.pagePath}/${lectureId}/edit`, "_self")
        }
    }, [addLmsLectureDetail?.data])

    const closeModal = () => {
        onCloseModal(false)
        setTitle('')
        setLectureError('')
    }

    const onHandleCreateLectureVideo = () => {
        if (addLmsLectureDetail?.isLoading) return;

        if (!title || (title.length === 0)) {
            setLectureError("Title is required!")
            return;
        }
        const body = {
            title: title,
            owner_user_id: user?.user?.userId
        }
        dispatcher(createLmsLectureDetail(body))
        setLectureError("")
    }

    return (
        <Transition appear show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className="fixed max-h-screen inset-0 z-50 overflow-y-auto"
                open={openModal}
                onClose={closeModal}
            >
                <div className="p-5 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span aria-hidden="true" className="inline-block h-screen align-middle">&#8203;</span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={cn(
                            "inline-block w-full shadow-xl rounded-lg space-y-5 overflow-hidden",
                            `text-left align-middle transition-all transform ${maxWidth} ${bgColor}`
                        )}>
                            <Dialog.Description as="div">
                                <div className={cn(
                                    "w-full h-full overflow-hidden overflow-y-scroll",
                                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                                )}>
                                    <div className="flex w-full justify-between items-center bg-background-light h-16 px-5 drop-shadow-sm">
                                        <p className="font-bodyPri font-medium text-text-900 text-lg">
                                            {"Create a Lecture"}
                                        </p>
                                        <AiOutlineClose onClick={closeModal} className='cursor-pointer' size={20} />
                                    </div>
                                    <div className={"flex flex-col gap-5 bg-white p-10"}>
                                        <div className={"w-full flex flex-col gap-1.5"}>
                                            <input className={cn(
                                                "px-3 h-12 flex w-full focus:outline-none rounded-lg border border-background-lightDark focus:border focus:border-primary-main",
                                                "font-bodyPri font-normal text-text-800 text-base placeholder:text-text-500"
                                            )}
                                                type={"text"}
                                                placeholder={'Add Lecture Title'}
                                                maxLength={MAX_LENGTH}
                                                value={title}
                                                onChange={(event) => setTitle(event?.target?.value)}
                                            />
                                            <div className={"w-full flex justify-end"}>
                                                {(title?.length > 0) &&
                                                    <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                                                        {`${title?.length}/${MAX_LENGTH}`}
                                                    </span>
                                                }
                                            </div>
                                            <div className={"flex items-center justify-start"}>
                                                {(lectureError || addLmsLectureDetail?.message) &&
                                                    <span className={"font-bodyPri font-normal text-sm text-red-500 tracking-wide"}>
                                                        {lectureError || addLmsLectureDetail?.message}
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <button
                                            className={cn(
                                                'font-bodyPri py-2 w-36 bg-primary-dark text-white rounded-full hover:bg-secondary-dark ease-in-out duration-100',
                                                addLmsLectureDetail?.isLoading && "bg-primary-dark"
                                            )}
                                            onClick={onHandleCreateLectureVideo}
                                        >
                                            {addLmsLectureDetail?.isLoading &&
                                                <ButtonLoader isLoading={addLmsLectureDetail?.isLoading} />
                                            }
                                            {!addLmsLectureDetail?.isLoading && "Create"}
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Description>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

export default CreateLmsLectureModal;