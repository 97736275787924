import LessonTab from "./tabComponents/LessonTab";
import QuizTab from "./tabComponents/QuizTab";
import QuestionTab from "./tabComponents/QuestionTab";
// import TopicTab from "./tabComponents/TopicTab";
import LectureTab from "./tabComponents/LectureTab";
import ArticleTab from "./tabComponents/ArticleTab";
import ResourceTab from "./tabComponents/resourceTab/ResourceTab";

export const libraryTabConst = {
    // LESSONS: {
    //     label: "Lessons",
    //     value: "lessons",
    //     component: <LessonTab />
    // },
    QUIZZES: {
        label: "Quizzes",
        value: "quizzes",
        component: <QuizTab />
    },
    // TOPICS: {
    //     label: "Topics",
    //     value: "topics",
    //     component: <TopicTab />
    // },
    LECTURES: {
        label: "Lectures",
        value: "lectures",
        component: <LectureTab />
    },
    ARTICLES: {
        label: "Articles",
        value: "articles",
        component: <ArticleTab />
    },
    QUESTIONS: {
        label: "Questions",
        value: "questions",
        component: <QuestionTab />
    },
    RESOURCES: {
        label: "Resources",
        value: "resources",
        component: <ResourceTab />
    }
}

export const lessonTableHeaderConst = [
    "Title",
    "Status",
    "Created Date",
    "Actions",
]

export const quizTableHeaderConst = [
    "Title",
    "Status",
    "Created Date",
    "Actions",
]

export const topicTableHeaderConst = [
    "Title",
    "Status",
    "Created Date",
    "Actions",
]

export const lectureTableHeaderConst = [
    "Title",
    "Status",
    "Created Date",
    "Actions",
]

export const articleTableHeaderConst = [
    "Title",
    "Status",
    "Created Date",
    "Actions",
]

export const questionTableHeaderConst = [
    "Title",
    "Type",
    "Created Date",
    "Actions",
]

export const resourceTableHeaderConst = [
    "Title",
    "Type",
    "Created Date",
    "Actions",
]