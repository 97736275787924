import { userRoles } from "redux/auth/auth.const";

export const pageHeading = {
    heading: "My Quizzes (Self-Paced)",
    subHeading: "Find your self-paced quizzes",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/lms-course.png"
}

export const navigationOptions = [
    { label: "All Quizzes", to: "/quizzes", roles: [userRoles.STUDENT.value] },
];