import { weeklyTimeSlot, chooseTimeSlotAction, bookingType } from "redux/booking/booking.const"
import { addBookingDetailPayload, modifyUserBookingDetailPayload, cancelBookingSessionDetailPayload, bookingFilterProps } from "redux/booking/booking.const"


const oldAddBookingPayload = {
    course: null,
    coursePrice: 0,
    purchasedPrice: 0,
    promoCreditUsed: 0,
    coursePctDiscount: 0,
    volumePctDiscount: 0,
    tutorPctDiscount: 0,
    offerPctDiscount: 0,
    sessionDuration: 60,
    noOfSessions: 5,
    confirm: false,
    chooseTimeSlotAction: chooseTimeSlotAction.BOOKING.value,
    startDate: null,
    endDate: null,
    weeklyTimeSlot: weeklyTimeSlot,
    bookingType: bookingType.REGULAR.value,
    isWalletAdded: true,
    walletAmountToPay: 0,
    isPromoCreditUsed: true,
    gatewayAmountToPay: 0,
}

const chooseTimeSlotPayload = {
    chooseTimeSlotAction: chooseTimeSlotAction.BOOKING.value,
    actionItem: {
        courseId: null,
        eventId: null,
        bookingId: null,
    },
    sessionDuration: 60,
    noOfSessions: 5,
    startDate: null,
    endDate: null,
    weeklyTimeSlot: weeklyTimeSlot,
}


export const BOOKING_INITIAL_STATE = {
    // Booking state
    addBookingDetail: {
        isLoading: false,
        payload: addBookingDetailPayload,
        message: null
    },
    booking_Detail: {
        isLoading: false,
        data: null,
        message: null
    },
    initializeBookingDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    confirmBookingDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    bookingDetailConfirmation: {
        isLoading: false,
        data: null,
        message: null
    },

    // User Booking State
    userBookingList: {
        isLoading: false,
        data: null,
        message: null
    },
    userBookingDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    modifyUserBookingDetail: {
        isLoading: false,
        payload: modifyUserBookingDetailPayload,
        message: null
    },

    // user pre booking detail
    userPreBookingDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    // tutor booking state
    tutorBookingList: {
        isLoading: false,
        data: null,
        message: null
    },
    tutorBookingDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    // booking session detail
    addBookingSessionDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    // cancel booking session detail
    cancelBookingSessionDetail: {
        isLoading: false,
        data: null,
        payload: cancelBookingSessionDetailPayload,
        message: null
    },

    filterProps: bookingFilterProps,

    // OLD
    bookingDetail: {
        bookingDetailPayload: null,
    },

    addBooking: {
        isLoading: false,
        addBookingPayload: oldAddBookingPayload,
        chooseTimeSlotPayload: chooseTimeSlotPayload,
        addBooking: null,
        errorMsg: null
    },
}