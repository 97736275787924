const learnEnglish = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/learn-english-category-featured.png';
const ielts = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/ielts-category-featured.png';
const ptePearson = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/pte-academic-category-featured.png';
const cfaLevelOne = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/cfa-level-one-category-featured.png';
const studyInAustralia = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/study-in-australia-category-featured.png';
const mathematics = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/maths-category-featured.png';
const assignmentHelp = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/assignment-help-category-featured.png';

export const popularCategoriesStats = [
    {
        label: 'Learn English',
        imageUrl: learnEnglish,
        url: '/classes/learn-english',
    },
    {
        label: 'PTE Pearson',
        imageUrl: ptePearson,
        url: '/classes/pte',
    },
    {
        label: 'IELTS',
        imageUrl: ielts,
        url: '/classes/ielts',
    },
    {
        label: 'Maths',
        imageUrl: mathematics,
        url: '/classes/maths',
    },
    {
        label: 'Teacher Training',
        imageUrl: cfaLevelOne,
        url: '/classes/teacher-training',
    },
    {
        label: 'English Grammar',
        imageUrl: studyInAustralia,
        url: '/classes/?skill=english-grammar',
    },
    {
        label: 'Technology',
        imageUrl: assignmentHelp,
        url: '/classes/?segment=technology',
    },
    {
        label: 'Science',
        imageUrl: assignmentHelp,
        url: '/classes/science',
    },
    {
        label: 'Business',
        imageUrl: assignmentHelp,
        url: '/classes/?segment=business',
    },
    {
        label: 'Coding',
        imageUrl: assignmentHelp,
        url: '/classes/coding',
    },
    {
        label: 'Language',
        imageUrl: assignmentHelp,
        url: '/classes/?segment=language',
    },
    {
        label: 'Excel Spreadsheets',
        imageUrl: assignmentHelp,
        url: '/classes/excel',
    },
]