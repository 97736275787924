import format from "string-format";

import { baseApiInstance, baseCredApiInstance, baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/auth/auth.const";


class AuthService {
    createLogIn = async ({ body }) => {
        const response = await baseCredApiInstance().post(
            url.LOGIN,
            body
        )

        return response
    }

    createGoogleLogIn = async ({ body }) => {
        const response = await baseCredApiInstance().post(
            url.GOOGLE_LOGIN,
            body
        )

        return response
    }

    createOneTapGoogleLogIn = async ({ body }) => {
        const response = await baseCredApiInstance().post(
            url.ONE_TAP_GOOGLE_LOGIN,
            body
        )

        return response
    }

    getToken = async () => {
        const response = await baseCredApiInstance().get(
            url.GET_TOKEN
        )

        return response
    }

    createSignUp = async ({ body }) => {
        const response = await baseCredApiInstance().post(
            url.SIGN_UP,
            body
        )

        return response
    }

    signUpWithToken = async ({ body }) => {

        const response = await baseCredApiInstance().post(
            url.SIGNUP_WITH_TOKEN,
            body
        )

        return response;
    }

    verifySignup = async ({ query }) => {

        const response = await baseCredApiInstance().get(
            url.VERIFY_SIGNUP,
            { params: query }
        )

        return response
    }

    signupS2 = async ({ body }) => {
        const response = await baseCredApiInstance().post(
            url.SIGNUP_S2,
            body
        )

        return response
    }

    createVerifyReferral = async ({ params }) => {
        const response = await baseApiInstance().get(
            format(url.VERIFY_REFERRAL_BY_REFERRAL_CODE, params)
        )
        return response
    }

    logout = async () => {
        const response = await baseAuthApiInstance().post(
            url.LOGOUT
        )

        return response
    }

    updateResetPassword = async ({ body }) => {
        const response = await baseApiInstance().put(
            url.RESET_PASSWORD,
            body
        )

        return response;
    }

    updateUserAuthByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_USER_AUTH, params),
            body
        )

        return response;
    }

    updateUserPasswordByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_USER_PASSWORD, params),
            body
        )

        return response;
    }

    verifyAuthorizedUser = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.VERIFY_AUTH,
            body
        )

        return response;
    }

    createVerifyUser = async ({ body }) => {
        const response = await baseApiInstance().post(
            url.VERIFY_USER,
            body
        )

        return response;
    }

    getPasswordReset = async ({ query }) => {
        const response = await baseApiInstance().get(
            url.GET_PASSWORD_RESET,
            { params: query }
        )

        return response;
    }

    createPasswordReset = async ({ body }) => {
        const response = await baseCredApiInstance().post(
            url.CREATE_PASSWORD_RESET,
            body
        )

        return response;
    }

    sendForgetPasswordLink = async ({ body }) => {
        const response = await baseApiInstance().post(
            format(url.SEND_FORGET_PASSWORD_LINK),
            body
        )

        return response;
    }

    logoutCurrentUserAndSwitchToAdmin = async () => {
        const response = await baseAuthApiInstance().post(
            url.LOGOUT_CURRENT_USER_AND_SWITCH_ADMIN
        )

        return response
    }

    identifier = async ({ body }) => {
        const response = await baseApiInstance().post(
            url.IDENTIFIER,
            body
        )

        return response
    }

    challengeOtp = async ({ body }) => {
        const response = await baseCredApiInstance().post(
            url.CHALLENGE_OTP,
            body
        )

        return response
    }

    challengePassword = async ({ body }) => {
        const response = await baseCredApiInstance().post(
            url.CHALLENGE_PASSWORD,
            body
        )

        return response
    }

    signupWithOtp = async ({ body }) => {
        const response = await baseApiInstance().post(
            url.SIGNUP_WITH_OTP,
            body
        )

        return response
    }

    verifySignupWithOtp = async ({ body }) => {
        const response = await baseCredApiInstance().post(
            url.VERIFY_SIGNUP_WITH_OTP,
            body
        )

        return response
    }

    createGoogleCalendarIntegrationAuth = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.CREATE_GOOGLE_CALENDAR_INTEGRATION_AUTH,
            body
        )

        return response;
    }

    createGoogleCalendarIntegration = async () => {
        const response = await baseAuthApiInstance().post(
            url.CREATE_GOOGLE_CALENDAR_INTEGRATION
        )

        return response;
    }

    createRevokeGoogleCalendarAccess = async () => {
        const response = await baseAuthApiInstance().post(
            url.CREATE_REVOKE_GOOGLE_CALENDAR_ACCESS
        )

        return response;
    }
}

export default new AuthService()