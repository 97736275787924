import { createSlice } from "@reduxjs/toolkit";

import { LMS_ARTICLE_INITIAL_STATE } from "./lmsArticle.initialState";

const lmsArticle = createSlice({
    name: "lms article",
    initialState: LMS_ARTICLE_INITIAL_STATE,
    reducers: {
        // lms article list
        setLmsArticleListLoading: (state, { payload }) => {
            state.lmsArticleList.isLoading = payload
        },
        setLmsArticleListData: (state, { payload }) => {
            state.lmsArticleList.data = payload
            state.lmsArticleList.message = LMS_ARTICLE_INITIAL_STATE.lmsArticleList.message
        },
        setLmsArticleListMessage: (state, { payload }) => {
            state.lmsArticleList.message = payload
            state.lmsArticleList.data = LMS_ARTICLE_INITIAL_STATE.lmsArticleList.data
        },
        resetLmsArticleList: (state) => {
            state.lmsArticleList = LMS_ARTICLE_INITIAL_STATE.lmsArticleList
        },

        // lms article detail
        setLmsArticleDetailLoading: (state, { payload }) => {
            state.lmsArticleDetail.isLoading = payload
        },
        setLmsArticleDetailData: (state, { payload }) => {
            state.lmsArticleDetail.data = payload
            state.lmsArticleDetail.message = LMS_ARTICLE_INITIAL_STATE.lmsArticleDetail.message
        },
        settLmsArticleDetailMessage: (state, { payload }) => {
            state.lmsArticleDetail.message = payload
            state.lmsArticleDetail.data = LMS_ARTICLE_INITIAL_STATE.lmsArticleDetail.data
        },
        resetLmsArticleDetail: (state) => {
            state.lmsArticleDetail = LMS_ARTICLE_INITIAL_STATE.lmsArticleDetail
        },

        // add lms article detail
        setAddLmsArticleDetailLoading: (state, { payload }) => {
            state.addLmsArticleDetail.isLoading = payload
        },
        setAddLmsArticleDetailData: (state, { payload }) => {
            state.addLmsArticleDetail.data = payload
            state.addLmsArticleDetail.message = LMS_ARTICLE_INITIAL_STATE.addLmsArticleDetail.message
        },
        setAddLmsArticleDetailMessage: (state, { payload }) => {
            state.addLmsArticleDetail.message = payload
            state.addLmsArticleDetail.data = LMS_ARTICLE_INITIAL_STATE.addLmsArticleDetail.data
        },
        resetAddLmsArticleDetail: (state) => {
            state.addLmsArticleDetail = LMS_ARTICLE_INITIAL_STATE.addLmsArticleDetail
        },

        // add lms article detail payload
        setAddLmsArticleDetailPayload: (state, { payload }) => {
            state.addLmsArticleDetail.payload = payload
        },
        resetAddLmsArticleDetailPayload: (state) => {
            state.addLmsArticleDetail.payload = LMS_ARTICLE_INITIAL_STATE.addLmsArticleDetail.payload
        },

        //update lms article detail
        setUpdateLmsArticleDetailLoading: (state, { payload }) => {
            state.updateLmsArticleDetail.isLoading = payload
        },
        setUpdateLmsArticleDetailData: (state, { payload }) => {
            state.updateLmsArticleDetail.data = payload
            state.updateLmsArticleDetail.message = LMS_ARTICLE_INITIAL_STATE.updateLmsArticleDetail.message
        },
        setUpdateLmsArticleDetailMessage: (state, { payload }) => {
            state.updateLmsArticleDetail.message = payload
        },
        resetUpdateLmsArticleDetail: (state) => {
            state.updateLmsArticleDetail = LMS_ARTICLE_INITIAL_STATE.updateLmsArticleDetail
        },

        // delete lms article detail
        setDeleteLmsArticleDetailLoading: (state, { payload }) => {
            state.deleteLmsArticleDetail.isLoading = payload
        },
        setDeleteLmsArticleDetailMessage: (state, { payload }) => {
            state.deleteLmsArticleDetail.message = payload
        },
        resetDeleteLmsArticleDetail: (state) => {
            state.deleteLmsArticleDetail = LMS_ARTICLE_INITIAL_STATE.deleteLmsArticleDetail
        },

        // lms article settings
        // add lms lesson instructor detail
        setAddLmsArticleInstructorDetailLoading: (state, { payload }) => {
            state.addLmsArticleInstructorDetail.isLoading = payload
        },
        setAddLmsArticleInstructorDetailData: (state, { payload }) => {
            state.addLmsArticleInstructorDetail.data = payload
            state.addLmsArticleInstructorDetail.message = LMS_ARTICLE_INITIAL_STATE.addLmsArticleInstructorDetail.message
        },
        setAddLmsArticleInstructorDetailMessage: (state, { payload }) => {
            state.addLmsArticleInstructorDetail.message = payload
            state.addLmsArticleInstructorDetail.data = LMS_ARTICLE_INITIAL_STATE.addLmsArticleInstructorDetail.data
        },
        resetAddLmsArticleInstructorDetail: (state) => {
            state.addLmsArticleInstructorDetail = LMS_ARTICLE_INITIAL_STATE.addLmsArticleInstructorDetail
        },

        // add lms lesson instructor detail payload
        setAddLmsArticleInstructorDetailPayload: (state, { payload }) => {
            state.addLmsArticleInstructorDetail.payload = payload
        },
        resetAddLmsArticleInstructorDetailPayload: (state) => {
            state.addLmsArticleInstructorDetail.payload = LMS_ARTICLE_INITIAL_STATE.addLmsArticleInstructorDetail.payload
        },

        setModifyLmsArticleInstructorDetailLoading: (state, { payload }) => {
            state.modifyLmsArticleInstructorDetail.isLoading = payload
        },
        setModifyLmsArticleInstructorDetailData: (state, { payload }) => {
            state.modifyLmsArticleInstructorDetail.data = payload
            state.modifyLmsArticleInstructorDetail.message = LMS_ARTICLE_INITIAL_STATE.modifyLmsArticleInstructorDetail.message
        },
        setModifyLmsArticleInstructorDetailMessage: (state, { payload }) => {
            state.modifyLmsArticleInstructorDetail.message = payload
            state.modifyLmsArticleInstructorDetail.data = LMS_ARTICLE_INITIAL_STATE.modifyLmsArticleInstructorDetail.data
        },
        resetModifyLmsArticleInstructorDetail: (state) => {
            state.modifyLmsArticleInstructorDetail = LMS_ARTICLE_INITIAL_STATE.modifyLmsArticleInstructorDetail
        },

        setDestroyLmsArticleInstructorDetailLoading: (state, { payload }) => {
            state.destroyLmsArticleInstructorDetail.isLoading = payload
        },
        setDestroyLmsArticleInstructorDetailMessage: (state, { payload }) => {
            state.destroyLmsArticleInstructorDetail.message = payload
        },
        resetDestroyLmsArticleInstructorDetail: (state) => {
            state.destroyLmsArticleInstructorDetail = LMS_ARTICLE_INITIAL_STATE.destroyLmsArticleInstructorDetail
        },
    }
})
export const {
    setLmsArticleListLoading,
    setLmsArticleListData,
    setLmsArticleListMessage,
    resetLmsArticleList,

    setLmsArticleDetailLoading,
    setLmsArticleDetailData,
    settLmsArticleDetailMessage,
    resetLmsArticleDetail,

    setAddLmsArticleDetailLoading,
    setAddLmsArticleDetailData,
    setAddLmsArticleDetailMessage,
    resetAddLmsArticleDetail,

    setAddLmsArticleDetailPayload,
    resetAddLmsArticleDetailPayload,

    setUpdateLmsArticleDetailLoading,
    setUpdateLmsArticleDetailData,
    setUpdateLmsArticleDetailMessage,
    resetUpdateLmsArticleDetail,

    setDeleteLmsArticleDetailLoading,
    setDeleteLmsArticleDetailMessage,
    resetDeleteLmsArticleDetail,

    // lms article instructor
    setAddLmsArticleInstructorDetailLoading,
    setAddLmsArticleInstructorDetailData,
    setAddLmsArticleInstructorDetailMessage,
    resetAddLmsArticleInstructorDetail,
    setAddLmsArticleInstructorDetailPayload,
    resetAddLmsArticleInstructorDetailPayload,

    setModifyLmsArticleInstructorDetailLoading,
    setModifyLmsArticleInstructorDetailData,
    setModifyLmsArticleInstructorDetailMessage,
    resetModifyLmsArticleInstructorDetail,

    setDestroyLmsArticleInstructorDetailLoading,
    setDestroyLmsArticleInstructorDetailMessage,
    resetDestroyLmsArticleInstructorDetail
} = lmsArticle.actions;

export default lmsArticle.reducer;