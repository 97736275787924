import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "./lmsLecture.const";

class LmsLectureService {
    
    static lmsLectureService = new LmsLectureService()

    /* tutor lms lecture service */
    getTutorLmsLectureList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_TUTOR_LMS_LECTURE_LIST, params),
            { params: query }
        )

        return response;
    }
    getTutorLmsLectureDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_TUTOR_LMS_LECTURE_DETAIL, params)
        )

        return response;
    }

    // lms lecture service
    createLmsLectureDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_LECTURE_DETAIL),
            body
        )

        return response;
    }
    updateLmsLectureDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_LECTURE_DETAIL, params),
            body
        )

        return response;
    }
    destroyLmsLectureDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_LECTURE_DETAIL, params)
        )

        return response;
    }

    /* lms lecture user service */
    createLmsLectureUserDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_LECTURE_USER_DETAIL),
            body
        )

        return response;
    }
    getLmsLectureUserList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LECTURE_USER_LIST),
            { params: query }
        )

        return response;
    }
    getLmsLectureUserDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LECTURE_USER_DETAIL, params)
        )

        return response;
    }
    updateLmsLectureUserDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_LECTURE_USER_DETAIL, params),
            body
        )

        return response;
    }
    deleteLmsLectureUserDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_LECTURE_USER_DETAIL, params)
        )

        return response;
    }

    /* lms lecture setting service */
    createLmsLectureSettingDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_LECTURE_SETTING_DETAIL),
            body
        )

        return response;
    }
    getLmsLectureSettingList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LECTURE_SETTING_LIST),
            { params: query }
        )

        return response;
    }
    getLmsLectureSettingDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LECTURE_SETTING_DETAIL, params)
        )

        return response;
    }
    updateLmsLectureSettingDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_LECTURE_SETTING_DETAIL, params),
            body
        )

        return response;
    }
    deleteLmsLectureSettingDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_LECTURE_SETTING_DETAIL, params)
        )

        return response;
    }
}

export default LmsLectureService.lmsLectureService;