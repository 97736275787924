import { createContext, useContext, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";

//firebase imports
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

//context imports
import { useUser } from "./userContext";
import { database } from "config/firebase.config"

const ChatContext = createContext();

export function useChat() {
  return useContext(ChatContext);
}

function chatReducer(state, action) {
  switch (action.type) {
    case "START_MEETING":
      return { ...state, meeting: action.payload };
    case "SET_FILTERS":
      return { ...state, filters: action.payload };
    case "LOADING_CHATS":
      return { ...state, loadingChats: action.payload };
    case "SET_CHATS":
      return { ...state, chats: action.payload };
    case "SET_SELECTED_CHAT":
      return { ...state, selectedChat: action.payload };
    case "SET_MESSAGES":
      return { ...state, messages: action.payload };
    case "SET_MEMBERS":
      return { ...state, groupMembers: action.payload };
    case "SET_MAKE_PAYMENT":
      return { ...state, payment: action.payload };
    default:
      return state;
  }
}

const initialState = {
  meeting: false,
  filters: [],
  loadingChats: true,
  chats: [],
  selectedChat: null,
  messages: [],
  groupMembers: [],
  payment: null,
};

export function ChatContextProvider({ children, courses }) {
  const { user } = useUser();
  const [state, dispatch] = useReducer(chatReducer, initialState);
  const { selectedChat } = state;
  const { userId } = user;

  const { chatId } = useParams();

  //fetch all the chats that the current user is a part of
  useEffect(() => {
    dispatch({
      type: "LOADING_CHATS",
      payload: true,
    });
    const chatsRef = collection(database, "chats");
    const chatsQuery = query(
      chatsRef,
      where("users", "array-contains", userId),
      orderBy("lastMessage.sentAt", "desc")
    );

    const unsubscribe = onSnapshot(chatsQuery, (chatsSnapshot) => {
      const chats = chatsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dispatch({ type: "SET_CHATS", payload: chats });
      dispatch({
        type: "LOADING_CHATS",
        payload: false,
      });
    });

    return () => {
      unsubscribe();
    };
  }, [userId]);

  //Fetch all messages for the selected chat
  useEffect(() => {
    if (!selectedChat) {
      dispatch({
        type: "SET_MESSAGES",
        payload: [],
      });
      return;
    }

    const messagesRef = collection(
      database,
      "chats",
      selectedChat.id,
      "messages"
    );
    const messageQuery = query(messagesRef, orderBy("sentAt", "asc"));
    const unsubscribe = onSnapshot(messageQuery, (messagesSnapshot) => {
      const messages = messagesSnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      dispatch({
        type: "SET_MESSAGES",
        payload: messages,
      });
    });

    return () => {
      unsubscribe();
    };
  }, [selectedChat]);

  //Fetch group members
  useEffect(() => {
    if (!selectedChat) {
      dispatch({
        type: "SET_MEMBERS",
        payload: [],
      });
      return;
    }

    if (selectedChat.type !== "group") return;
    const membersRef = collection(
      database,
      "chats",
      selectedChat.id,
      "members"
    );
    const membersQuery = query(membersRef);
    const unsubscribe = onSnapshot(membersQuery, (membersSnapshot) => {
      const members = membersSnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      dispatch({
        type: "SET_MEMBERS",
        payload: members,
      });
      return () => unsubscribe();
    });
  }, [selectedChat]);

  //Get id from url params or use chat saved in local storage
  useEffect(() => {
    if (!chatId) {
      dispatch({
        type: "SET_SELECTED_CHAT",
        payload: null,
      });
    }

    const newChat = state?.chats?.find((chat) => chat.id === chatId);
    if (newChat) {
      dispatch({
        type: "SET_SELECTED_CHAT",
        payload: newChat,
      });
    } else {
      dispatch({
        type: "SET_SELECTED_CHAT",
        payload: null,
      });
    }
  }, [state.chats, chatId]);

  const value = {
    courses,
    ...state,
    dispatch,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}
