const teachWithUsFeaturedFindStudentImageUrl = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/teach-with-us/expand-your-reach.png';
const teachWithUsFeaturedAnytimeAnywhereImageUrl = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/teach-with-us/flexible-teaching-options.png';
const teachWithUsFeaturedTeachWhatYouWantImageUrl = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/teach-with-us/professional-profile.png';


export const featuredData = [
    {
        icon: teachWithUsFeaturedFindStudentImageUrl,
        title: 'Expand Your Reach',
        description: 'Break free from geographical boundaries and make a global impact as an educator with edulyte.',
    },
    {
        icon: teachWithUsFeaturedAnytimeAnywhereImageUrl,
        title: 'Flexible Teaching Options',
        description: 'Customise your approach to match your teaching style. Offer trial and group classes.',
    },
    {
        icon: teachWithUsFeaturedTeachWhatYouWantImageUrl,
        title: 'Professional Profile',
        description: 'Stand out and inspire confidence. Your profile becomes a powerful tool for sales conversion.',
    },
]