import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

//Context imports
import { useChat } from "../../contexts/chatContext";
import { useUser } from "../../contexts/userContext";

//Helper functions
import chatName from "../../helpers/chatName";

//Icon imports
import { ArrowLeftIcon } from "@heroicons/react/outline";

//style imports
import {
  StyledChatViewHeaderUser,
  StyledViewChatViewHeaderUserImage, 
  StyledViewChatViewHeaderUserImageContainer,
  StyledChatViewHeaderUserText,
  StyledChatViewHeaderPresence,
} from "./ChatViewHeader.styles";

//firebase imports
import { doc, getDoc } from "firebase/firestore";
import { database } from "config/firebase.config"
import getLastSeen from "../../helpers/lastSeen";

import { pagesInfo } from 'utils/pagesInfo';


export default function ChatViewHeaderUser({ toggleGroupDetails }) {
  const navigate = useNavigate()
  const { selectedChat } = useChat();
  const [chatUserDetails, setChatUserDetails] = useState(null);

  const { user } = useUser();
  const { userId } = user;
  const { type } = selectedChat;

  const otherUser = selectedChat.users.filter(
    (chatUser) => chatUser !== userId
  )[0];

  useEffect(() => {
    if (type === "group") return;
    async function fetchUserDetails() {
      const userRef = doc(database, "users", otherUser);
      const response = await getDoc(userRef);
      const details = { id: response.id, ...response.data() };
      setChatUserDetails(details);
    }
    fetchUserDetails();
  }, [otherUser, type]);

  //Get chat image
  function imageToDisplay() {
    if (selectedChat.type === "group") {
      const { image } = selectedChat;
      return image;
    }

    const userDetails = selectedChat[otherUser];
    const { image } = userDetails;
    return image;
  }

  const chatNameToDisplay = chatName(selectedChat, user);
  const formattedChatName =
    chatNameToDisplay.length > 20
      ? `${chatNameToDisplay.substring(0, 20)}...`
      : chatNameToDisplay;

  function closeChatView() {
    navigate(`${pagesInfo.MESSAGES.pagePath}`, {replace: true})
  }

  function showLastSeen() {
    if (chatUserDetails?.available) return "Available";
    if (!chatUserDetails?.available) {
      const lastSeen = getLastSeen(chatUserDetails?.lastSeen);
      return `Last seen ${lastSeen}`;
    }
  }

  const isGroup = selectedChat.type === "group";

  return (
    <div className={"flex flex-row items-center justify-start"}>
      <ArrowLeftIcon
        onClick={closeChatView}
        className="inline-block w-6 h-6 mr-3 text-secondary-dark cursor-pointer hover:ring-4 hover:ring-secondary-light hover:bg-secondary-light hover:rounded-full md:hidden"
      />
      <StyledChatViewHeaderUser onClick={toggleGroupDetails} isGroup={isGroup}>
        <StyledViewChatViewHeaderUserImageContainer>
          <StyledViewChatViewHeaderUserImage
            src={imageToDisplay()}
            alt="user avatar"
          />
        </StyledViewChatViewHeaderUserImageContainer>
        <div>
          <StyledChatViewHeaderUserText>
            {formattedChatName}
          </StyledChatViewHeaderUserText>
          {chatUserDetails && chatUserDetails.lastSeen && (
            <StyledChatViewHeaderPresence>
              {showLastSeen()}
            </StyledChatViewHeaderPresence>
          )}
        </div>
      </StyledChatViewHeaderUser>
    </div>
  );
}
