export const url = {
    GET_USER_EVENTLY_LIST: "evently/evently/users/{userId}",
    GET_USER_EVENTLY_DETAIL: "evently/evently/{eventlyId}",
    CREATE_USER_EVENTLY_DETAIL: "evently/evently",
    UPDATE_USER_EVENTLY_DETAIL: "evently/evently/{eventlyId}",
    DELETE_USER_EVENTLY_DETAIL: "evently/evently/{eventlyId}",

    // evently events
    UPDATE_EVENTLY_EVENTS_DETAIL: "events/events/{eventId}",

    // evently invitations
    GET_EVENTLY_INVITATION_LIST: "evently/invitations",
    CREATE_SEND_EVENTLY_INVITATION_DETAIL: "evently/invitations/send",
    GET_VALIDATE_EVENTLY_INVITATION_DETAIL: 'evently/invitations/validate',
    GET_ACCEPT_AND_DECLINE_EVENTLY_INVITATION_DETAIL: 'evently/invitations/accept',
    DELETE_EVENTLY_INVITATION_DETAIL: "evently/invitations/{eventlyInvitationId}"
}

export const eventAvailabilityConst = {
    SUN: [],
    MON: [],
    TUE: [],
    WED: [],
    THU: [],
    FRI: [],
    SAT: []
}
export const addUserEventlyDetailPayload = {
    id: null,
    box: {
        clientX: null,
        clientY: null
    },
    title: "",
    startDateTime: null,
    endDateTime: null,
    isShowStartDatePopup: false,
    isShowEndDatePopup: false,
    isShowTimeSlots: false,
    isShowGuestList: false,
    isShowMeetingLinkInput: false,
    isShowLocationList: false,
    isShowDescriptionEditor: false,
    selectedStartDate: new Date(),
    selectedEndDate: new Date(),
    eventAvailability: eventAvailabilityConst,
    selectedGuestsList: [],
    isSendEmail: false,
    meetingLink: "",
    selectedLocation: null,
    description: ""
}

export const modifyUserEventlyDetailPayload = {
    id: null,
    title: "",
    startDateTime: new Date(),
    endDateTime: new Date(),
    eventlyEvents: null,
    eventAvailability: eventAvailabilityConst,
    selectedGuestsList: [],
    isSendEmail: false,
    selectedLocation: null,
    meetingLink: "",
    description: ""   
}


export const eventlyInviteToEnum = {
    TUTOR: {
        label: "Tutor",
        value: "tutor"
    },
    STUDENT: {
        label: "Student",
        value: "student"
    }
}

export const eventlyInviteStatusEnum = {
    INVITED: {
        label: "Invited",
        value: "invited"
    },
    RE_INVITED: {
        label: "Re Invited",
        value: "re_invited"
    },
    ACCEPTED: {
        label: "Accepted",
        value: "accepted"
    },
    DECLINED: {
        label: "Declined",
        value: "declined"
    }
}

export const DEFAULT_EVENTLY_PAGE = 1;
export const DEFAULT_EVENTLY_RECORDS = 15;