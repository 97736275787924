import { Listbox, Transition } from "@headlessui/react";
import { cn } from "utils/cn.utils";
import { Fragment } from "react";

import { FaSortDown } from "react-icons/fa";


const IsdCodeSelector = ({
    onChange,
    showCheckMark = false,
    options,
    value,
}) => {
    const selectedOption = options?.find((option) => option.value === value) || options[0];

    return (
        <div className="">
            <Listbox value={selectedOption.value} onChange={(value) => {
                const selected = options.find((option) => option.value === value)
                onChange(selected)
            }}>
                <div className="relative w-full">
                    <Listbox.Button className={cn(
                        "w-full flex justify-center items-center gap-1.5 bg-white rounded-lg cursor-pointer text-left",
                        "focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm"
                    )}>
                        <div className={"w-5 h-5 overflow-hidden rounded-sm"}>
                            <img src={`https://flagcdn.com/16x12/${selectedOption?.country?.countryDomain}.png`.toLowerCase()} alt={"country-flag"} className={"w-full h-full object-cover"} />
                        </div>
                        <span className="block truncate">
                            {"+" + selectedOption.label}
                        </span>
                        <span className="flex items-center pb-2 pr-2 pointer-events-none">
                            <FaSortDown
                                className="w-5 h-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className={cn(
                            "absolute z-50 w-fit py-1 top-8 -left-2 overflow-auto max-h-40 rounded-md",
                            "text-base bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                        )}>
                            {options.map((option, optionIdx) => (
                                <Listbox.Option key={optionIdx} value={option.value} className={({ active, selected }) => cn(
                                    `cursor-pointer select-none relative py-2 pr-4 ${showCheckMark ? "pl-10" : "pl-5"}`,
                                    selected && "text-white bg-primary-dark",
                                    active && !selected && "text-primary-dark bg-primary-light"
                                )}>
                                    {() => (
                                        <div className={"flex justify-start items-center gap-1.5 whitespace-nowrap"}>
                                            <div className={"w-5 h-5 flex items-center justify-start overflow-hidden rounded-sm"}>
                                                <img src={`https://flagcdn.com/16x12/${option?.country?.countryDomain}.png`.toLowerCase()} alt={"country-flag"} className={"w-full h-full object-cover"}
                                                />
                                            </div>
                                            <span>
                                                {option?.country?.country}
                                            </span>
                                        </div>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    );
};

export default IsdCodeSelector