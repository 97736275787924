import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";

import AddContactSocialItem from "components/modals/crmModals/createCrmContactDetailModal/commonComponents/ContactSocialItem";
import ContactSocialItem from "components/modals/crmModals/viewContactDetailModal/commonComponents/ContactSocialItem";
import AddItemBtn from "components/modals/crmModals/commonComponents/AddItemBtn";
import { crmDetailBtnConst } from "components/modals/crmModals/data";

import { createCrmContactSocialDetail, deleteCrmContactSocialDetail, updateCrmContactSocialDetail } from "redux/crm/crm.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetAddCrmContactSocialDetail, resetModifyCrmContactSocialDetail, setModifyCrmContactDetailPayload } from "redux/crm/crm.slice";

const newSocialInitialState = {
    id: 0,
    isShowInput: false,
    social: null,
    link: ""
}

function EditContactSocials({ editBtnConst, setEditBtnConst }) {
    const { addCrmContactSocialDetail, modifyCrmContactDetail, modifyCrmContactSocialDetail, destroyCrmContactSocialDetail } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()

    const [newSocialDetail, setNewSocialDetail] = useState({
        id: 0,
        isShowInput: false,
        social: null,
        link: ""
    })

    const getUpdatedContactSocialDetail = () => {
        if (addCrmContactSocialDetail?.data) {
            const socialPayload = {
                id: addCrmContactSocialDetail?.data?.id,
                social: newSocialDetail?.social,
                link: newSocialDetail?.link
            }
            dispatcher(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                socials: [...modifyCrmContactDetail?.payload?.socials, socialPayload]
            }))
            dispatcher(resetAddCrmContactSocialDetail())
        } else if (modifyCrmContactSocialDetail?.data) {
            const socialPayload = {
                id: modifyCrmContactSocialDetail?.data?.id,
                social: newSocialDetail?.social,
                link: newSocialDetail?.link
            }
            dispatcher(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                socials: modifyCrmContactDetail?.payload?.socials?.map((socialItem) => (
                    (socialItem?.id === modifyCrmContactSocialDetail?.data?.id) ? socialPayload : socialItem
                ))
            }))
            dispatcher(resetModifyCrmContactSocialDetail())
        }
        setNewSocialDetail(newSocialInitialState)
        setEditBtnConst(null)
    }

    useEffect(() => {
        if (addCrmContactSocialDetail?.data || modifyCrmContactSocialDetail?.data) {
            getUpdatedContactSocialDetail()
        }
    }, [addCrmContactSocialDetail?.data, modifyCrmContactSocialDetail?.data])

    const onHandleAddNewSocialDetail = useCallback(() => {
        if (editBtnConst) return;

        setNewSocialDetail({
            ...newSocialDetail,
            isShowInput: true,
            social: null,
            link: ""
        })
        setEditBtnConst({
            ...editBtnConst,
            id: 0,
            active: crmDetailBtnConst?.socials.key
        })
    }, [newSocialDetail, editBtnConst])

    const onHandleSaveSocialDetail = (socialItem) => {
        if (addCrmContactSocialDetail?.isLoading || modifyCrmContactSocialDetail?.isLoading) return;

        const payload = {
            link: socialItem?.link,
            social: socialItem?.social?.value,
        }
        dispatcher(updateCrmContactSocialDetail(socialItem?.id, payload))
        setNewSocialDetail(socialItem)
    }

    const onHandleDeleteSocialDetail = useCallback((socialItemId) => {
        if (modifyCrmContactSocialDetail?.isLoading || destroyCrmContactSocialDetail?.isLoading) return;
        if (!window.confirm("Are you sure?. You want to delete social details.")) return;

        dispatcher(deleteCrmContactSocialDetail(socialItemId))
        setEditBtnConst(null)
    }, [modifyCrmContactSocialDetail?.isLoading, destroyCrmContactSocialDetail?.isLoading])

    const onHandleSaveNewSocial = (socialItem) => {
        if (addCrmContactSocialDetail?.isLoading || modifyCrmContactSocialDetail?.isLoading) return;
        if (!socialItem?.link) {
            toast.warn("Please enter link!")
            return;
        }
        if (!socialItem?.social?.value) {
            toast.warn("Please select social!")
            return;
        }
        const duplicateSocial = modifyCrmContactDetail?.payload?.socials?.filter((social) => (social?.link === socialItem?.link))
        if (!!duplicateSocial?.length) {
            toast.warn("Social detail already added!")
            return;
        }
        const payload = {
            contact_id: modifyCrmContactDetail?.payload?.contact_id,
            social: socialItem?.social?.value,
            link: socialItem?.link
        }
        dispatcher(createCrmContactSocialDetail(payload))
        setNewSocialDetail(socialItem)
    }

    const onHandleDeleteNewSocial = useCallback(() => {
        setNewSocialDetail(newSocialInitialState)
        setEditBtnConst(null)
    }, [])

    return (
        <div className="w-full flex flex-col gap-8 items-center mt-5">
            {!!modifyCrmContactDetail?.payload?.socials?.length &&
                modifyCrmContactDetail?.payload?.socials?.map((socialItem, index) => (
                    <ContactSocialItem
                        key={socialItem?.id}
                        index={index}
                        isLoading={modifyCrmContactSocialDetail?.isLoading || destroyCrmContactSocialDetail?.isLoading}
                        isShowSaveBtn={true}
                        isShowCancelBtn={true}
                        socialItem={socialItem}
                        editBtnConst={editBtnConst}
                        setEditBtnConst={setEditBtnConst}
                        onHandleSaveSocialDetail={onHandleSaveSocialDetail}
                        onHandleDeleteSocialDetail={onHandleDeleteSocialDetail}
                    />
                ))}
            {newSocialDetail?.isShowInput &&
                <AddContactSocialItem
                    isLoading={addCrmContactSocialDetail?.isLoading}
                    isShowSaveBtn={true}
                    isShowCancelBtn={true}
                    socialItem={newSocialDetail}
                    onHandleChangeSocialDetail={(SocialId, updatedValue) => setNewSocialDetail(updatedValue)}
                    onHandleSaveSocialDetail={onHandleSaveNewSocial}
                    onHandleDeleteSocialDetail={onHandleDeleteNewSocial}
                />
            }
            {!newSocialDetail?.isShowInput &&
                <AddItemBtn
                    btnTitle={"Add Social Link"}
                    onHandleAddBtn={onHandleAddNewSocialDetail}
                    isBtnDisable={editBtnConst}
                />
            }
        </div>
    );
}

export default EditContactSocials;