import { cn } from "utils/cn.utils";

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { ImCross } from 'react-icons/im';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetPreviewImageContent } from 'redux/local/local.slice';

const LmsPreviewImageModal = ({
    openModal = false,
    onCloseModal,
    maxWidth = "max-w-2xl",
    bgColor = "bg-white",
}) => {
    const { previewImage } = useAppState((state) => state.local)

    const dispatcher = useAppDispatcher()

    const onHandleClose = () => {
        dispatcher(resetPreviewImageContent())
        onCloseModal()
    }

    return openModal && (
        <Modal
            className={"relative bg-black w-[90vw] min-h-screen overflow-hidden self-center justify-self-center mx-auto my-auto border border-white"}
            open={openModal}
            onClose={onHandleClose}
            aria-labelledby={"modal-modal-title"}
            aria-describedby={"modal-modal-description"}
        >
            <Box className={cn(
                "absolute inset-0 w-full min-h-[24rem] rounded-lg overflow-y-scroll bg-white focus:outline-none",
                "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
            )}>
                <div className={"w-full h-full"}>
                    <img
                        src={previewImage?.previewUrl}
                        className={"w-full h-full object-cover"}
                        alt={'template-preview'}
                    />
                </div>
                <span className={"fixed top-0 sm:top-3 right-[3rem] flex justify-end bg-white p-1 rounded-lg"}>
                    <ImCross
                        onClick={onHandleClose}
                        className={"text-2xl text-divider-dark hover:text-text-900 cursor-pointer"}
                    />
                </span>
            </Box>
        </Modal>
    )
}

export default LmsPreviewImageModal;