import { cn } from "utils/cn.utils";

import { useAppState } from 'hooks/useStore';
import { courseStatus } from 'redux/course/course.const';

import { pagesInfo } from 'utils/pagesInfo';

const PreviewCourseDetails = () => {
    const { tutorCourseDetail } = useAppState((state) => state.course)

    const handleOpen = () => {
        if (tutorCourseDetail?.data?.status === courseStatus?.PUBLISHED?.value) {
            window.open(`${pagesInfo?.COURSE?.pagePath}/${tutorCourseDetail?.data?.slug}`, "_blank")
        } else {
            window.open(`${pagesInfo?.CREATE_COURSE_PREVIEW?.pagePath}/${tutorCourseDetail?.data?.slug}/preview?previewAs=tutor`, "_blank")
        }
    }

    return (
        <div className={"w-full h-full bg-white rounded-lg select-none"}>
            <div className={`w-full px-5 py-3 rounded-lg bg-${courseStatus[tutorCourseDetail?.data?.status?.toUpperCase()]?.lightColor}`}>
                <span className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Class Preview"}
                </span>
            </div>
            <div className={"w-full min-h-[60vh] flex flex-col items-center justify-center gap-5"}>
                <div className={"w-full flex items-center justify-center gap-3"}>
                    <span className={"font-bodyPri font-normal text-text-700 text-xl"}>
                        {"Class Status:"}
                    </span>
                    <span className={`font-bodyPri font-normal text-xl text-${courseStatus[tutorCourseDetail?.data?.status?.toUpperCase()]?.darkColor}`}>
                        {courseStatus[tutorCourseDetail?.data?.status?.toUpperCase()]?.label}
                    </span>
                </div>
                <div className={cn(
                    "mx-auto w-full md:w-[25%] relative flex flex-col items-center justify-center rounded-lg shadow-lg bg-black aspect-video cursor-pointer",
                    "hover:scale-105 hover:transition duration-200 hover:shadow-lg",
                    "ring-offset-2 ring-4 ring-white"
                )}>
                    <img
                        src={tutorCourseDetail?.data?.thumbnailPicUrl}
                        className="object-cover w-full h-full rounded-lg opacity-90"
                        alt="course-thumbnail"
                    />
                    <div
                        className={"absolute w-full h-full rounded-lg overflow-hidden flex justify-center items-center"}>
                        <span
                            className={"bg-black bg-opacity-50 px-4 py-2 rounded-sm text-text-50 font-bodyPri font-normal text-base"}
                            onClick={handleOpen}
                        >
                            {(tutorCourseDetail?.data?.status === courseStatus?.PUBLISHED?.value) ? "View" : "Preview"}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreviewCourseDetails;