import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";

export const socialLinks = {
    FACBOOK_SHARE: {
        label: "Facebook",
        value: "facebook_share",
        component: ({ url, size }) => (
            <FacebookShareButton url={url}>
                <FacebookIcon size={size} />
            </FacebookShareButton>
        )
    },
    WHATSAPP_SHARE: {
        label: "Whatsapp",
        value: "whatsapp_share",
        component: ({ url, size }) => (
            <WhatsappShareButton url={url}>
                <WhatsappIcon size={size} />
            </WhatsappShareButton>
        )
    },
    TWITTER_SHARE: {
        label: "Twitter",
        value: "twitter_share",
        component: ({ url, size }) => (
            <TwitterShareButton url={url}>
                <TwitterIcon size={size} />
            </TwitterShareButton>
        )
    },
    LINKDIN_SHARE: {
        label: "Linkdin",
        value: "linkdin_share",
        component: ({ url, size }) => (
            <LinkedinShareButton url={url}>
                <LinkedinIcon size={size} />
            </LinkedinShareButton>
        )
    }
}