import { useContext } from 'react';

import { DragContext } from './Drag';

function DragItem({ as, dragId, dragType, ...props }) {
    const { draggable, dragStart, drag, dragEnd } = useContext(DragContext);

    let Component = as || "div";
    return (
        <Component
            onDragStart={(e) => dragStart(e, dragId, dragType)}
            onDrag={drag}
            draggable={draggable}
            onDragEnd={dragEnd}
            {...props}
        />
    )
};

export default DragItem;