import format from 'string-format';

import { baseAuthApiInstance, baseApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "redux/availability/availability.const";

class AvailabilityService {

    // new availability service
    getUserAvailabilityList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_AVAILABILITY_LIST, params),
            { params: query }
        )

        return response;
    }

    getUserAvailabilityDetail = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_AVAILABILITY_DETAIL, params),
            { params: query }
        )

        return response;
    }

    createUserAvailabilityDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_USER_AVAILABILITY_DETAIL),
            body
        )

        return response;
    }

    updateUserAvailabilityDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_USER_AVAILABILITY_DETAIL, params),
            body
        )

        return response;
    }

    deleteUserAvailabilityDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_USER_AVAILABILITY_DETAIL, params),
        )

        return response;
    }

    getOffDayList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.OFF_DAY_LIST),
            { params: query }
        )

        return response;
    }

    getOffDyDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.OFF_DAY_DETAIL, params)
        )

        return response;
    }

    createOffDayDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.OFF_DAY_LIST),
            body
        )

        return response;
    }

    updateOffDayDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.OFF_DAY_DETAIL, params),
            body
        )

        return response;
    }

    deleteOffDayDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.OFF_DAY_DETAIL, params)
        )

        return response;
    }

    getAvailableSlotDetail = async ({ params, query }) => {
        const response = await baseApiInstance().get(
            format(url.GET_AVAILABILITY_SLOT_DETAIL, params),
            { params: query }
        )

        return response;
    }
}

export default new AvailabilityService()