import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quill-editor.css';

import { cn } from "utils/cn.utils";

const ViewOnlyQuillEditor = ({
  editorClassName,
  editorContent = "",
  readOnly = true,
  modules = { toolbar: false },
  ...rest
}) => {

  return (
    <ReactQuill
      className={cn("react-quill", editorClassName)}
      readOnly={readOnly}
      modules={modules}
      value={editorContent}
      {...rest}
    />
  )
}

export default ViewOnlyQuillEditor