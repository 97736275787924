import { BiCustomize } from "react-icons/bi";
import { MdOutlineLocationOn } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { IoVideocamOutline } from "react-icons/io5";
import { SiGooglemeet } from "react-icons/si";

export const url = {
    GET_SESSION_USER_DETAIL: "/sessions/sessions/{sessionId}/users/{userId}",

    START_SESSION_USER_DETAIL: "/sessions/sessions/{sessionId}/start",
    ACCEPT_SESSION_USER_DETAIL: "/sessions/sessions/{sessionId}/schedule",
    DECLINE_SESSION_USER_DETAIL: "/sessions/sessions/{sessionId}/decline",
    RESCHEDULE_SESSION_USER_DETAIL: "/sessions/sessions/{sessionId}/reschedule",
    COMPLETE_SESSION_USER_DETAIL: "/sessions/sessions/{sessionId}/complete",

    GET_SESSION_COURSE_LIST: "sessions/courses",
    GET_SESSION_APPOINTMENT_LIST: "sessions/appointments",

    // Session locations
    GET_SESSION_LOCATION_LIST: "sessions/{sessionId}/locations",
    CREATE_SESSION_LOCATION_DETAIL: "sessions/{sessionId}/locations",
    GET_SESSION_LOCATION_DETAIL: "sessions/{sessionId}/locations/{locationId}",
    UPDATE_SESSION_LOCATION_DETAIL: "sessions/{sessionId}/locations/{locationId}",
    DELETE_SESSION_LOCATION_DETAIL: "sessions/{sessionId}/locations/{locationId}",

    // updated after evently addition
    GET_CALENDAR_SESSION_USER_LIST: "events/events/users/{userId}",
}

export const addSessionUserDetailPayload = {
    step: 1,
    sessionId: null,
    note: '',
    availabilityId: null,
    title: "",
    duration: 60,
    schedule: []
}

export const sessionTypeEnum = {
    APPOINTMENT: {
        label: "Appointment",
        value: "appointment"
    },
    COURSE: {
        label: "Course",
        value: "course"
    },
    INTERVIEW: {
        label: "Interview",
        value: "interview"
    }
}

export const sessionCourseTypeEnum = {
    ALL: {
        label: "All",
        value: "all"
    },
    ONE_ON_ONE: {
        label: "One on One",
        value: "one_on_one"
    },
    GROUP: {
        label: "Group",
        value: "group"
    }
}

export const calendarFiltersEnum = {
    ALL: {
        label: "All",
        value: "all"
    },
    SESSION_USER: {
        label: "Sessions",
        value: "session_user"
    },
    EVENTLY: {
        label: "Evently",
        value: "evently"
    },
    // APPOINTMENT: {
    //     label: "Appointment",
    //     value: "appointment"
    // }
}

export const calendarEventTypeEnum = {
    SESSION_USER: {
        label: "Session_user",
        value: "session_user"
    },
    EVENTLY: {
        label: "Evently",
        value: "evently"
    }
}

export const sessionUserTypeEnum = {
    PARTICIPANT: {
        label: "Participant",
        value: "participant"
    },
    MODERATOR: {
        label: "Moderator",
        value: "moderator"
    }
}

export const sessionStatusEnum = {
    ALL: {
        label: "All",
        value: "all",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
        lightColorHexCode: '#f5f5f5',
        darkColorHexCode: '#9e9e9e'
    },
    NOT_MAPPED: {
        label: "Not Mapped",
        value: "not_mapped",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
        lightColorHexCode: '#f5f5f5',
        darkColorHexCode: '#9e9e9e'
    },
    PENDING: {
        label: "Pending",
        value: "pending",
        lightColor: 'orange-100',
        darkColor: 'orange-500',
        lightColorHexCode: '#ffe0b2',
        darkColorHexCode: '#ff9800'
    },
    DECLINED: {
        label: "Declined",
        value: "declined",
        lightColor: 'red-100',
        darkColor: 'red-500',
        lightColorHexCode: '#ffe0b2',
        darkColorHexCode: '#f44336'
    },
    RESCHEDULED: {
        label: "Rescheduled",
        value: "rescheduled",
        lightColor: 'pink-100',
        darkColor: 'pink-500',
        lightColorHexCode: '#fce4ec',
        darkColorHexCode: '#e91e63'
    },
    SCHEDULED: {
        label: "Scheduled",
        value: "scheduled",
        lightColor: 'blue-100',
        darkColor: 'blue-500',
        lightColorHexCode: '#bbdefb',
        darkColorHexCode: '#2196f3'
    },
    ON_GOING: {
        label: "On Going",
        value: "on_going",
        lightColor: 'yellow-100',
        darkColor: 'yellow-500',
        lightColorHexCode: '#fff9c4',
        darkColorHexCode: '#ffeb3b'
    },
    COMPLETED: {
        label: "Completed",
        value: "completed",
        lightColor: 'green-100',
        darkColor: 'green-500',
        lightColorHexCode: '#dcedc8',
        darkColorHexCode: '#4caf50'
    },
    CANCELLED: {
        label: "Cancelled",
        value: "cancelled",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
        lightColorHexCode: '#f5f5f5',
        darkColorHexCode: '#9e9e9e'
    },
}

export const sessionLocationLabelsEnum = {
    home: {
        key: "home",
        label: "Home",
    },
    work: {
        key: "work",
        label: "Work",
    },
    custom: {
        key: "custom",
        label: "Custom",
    },
}

export const sessionLocationTypeEnum = {
    custom: {
        key: "custom",
        label: "Custom",
        icon: BiCustomize,
    },
    in_person_meeting: {
        key: "in_person_meeting",
        label: "In Person Meeting",
        icon: MdOutlineLocationOn,
    },
    phone_call: {
        key: "phone_call",
        label: "Phone Call",
        icon: FiPhoneCall,
    },
    invitee: {
        key: "invitee",
        label: "Ask Invitee",
        icon: AiOutlineQuestionCircle,
    },
    google_meet: {
        key: "google_meet",
        label: "Google Meet",
        icon: SiGooglemeet,
    },
    edulyte_meet: {
        key: "edulyte_meet",
        label: "Edulyte Meet",
        icon: IoVideocamOutline,
    },
}

export const sessionLocationPhoneCallTypeEnum = {
    i_will_call: {
        label: "",
        key: "i_will_call",
        title: "I will call my invitee",
        subtitle: "Edulyte will require your invitee’s phone number before scheduling."
    },
    call_me: {
        label: "",
        key: "call_me",
        title: "My invitee should call me",
        subtitle: "Edulyte will provide your number after the call has been scheduled."
    }
}

export const appointmentSessionStatusActions = {
    PARTICIPANT: {
        PENDING: {
            PENDING: {
                label: 'Pending',
                value: 'pending'
            },
        },
        DECLINED: {
            DECLINED: {
                label: 'Declined',
                value: 'declined'
            },
        },
        RESCHEDULED: {
            RESCHEDULED: {
                label: 'Reschedule',
                value: 'rescheduled'
            },
        },
        SCHEDULED: {
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
        },
        ON_GOING: {
            ON_GOING: {
                label: 'On Going',
                value: 'on_going'
            }
        },
        COMPLETED: {
            COMPLETED: {
                label: 'Completed',
                value: 'completed'
            }
        },
        CANCELLED: {
            CANCELLED: {
                label: 'Cancelled',
                value: 'cancelled'
            }
        },
    },
    MODERATOR: {
        PENDING: {
            PENDING: {
                label: 'Pending',
                value: 'pending'
            },
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            DECLINED: {
                label: 'Declined',
                value: 'declined'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            },
        },
        DECLINED: {
            DECLINED: {
                label: 'Declined',
                value: 'declined'
            },
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            RESCHEDULED: {
                label: 'Reschedule',
                value: 'rescheduled'
            }
        },
        RESCHEDULED: {
            RESCHEDULED: {
                label: 'Reschedule',
                value: 'rescheduled'
            },
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
        },
        SCHEDULED: {
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            ON_GOING: {
                label: "On Going",
                value: "on_going"
            },
            RESCHEDULED: {
                label: 'Reschedule',
                value: 'rescheduled'
            },
        },
        ON_GOING: {
            ON_GOING: {
                label: 'On Going',
                value: 'on_going'
            },
            RESCHEDULED: {
                label: 'Reschedule',
                value: 'rescheduled'
            },
        },
        COMPLETED: {
            COMPLETED: {
                label: 'Completed',
                value: 'completed'
            }
        },
        CANCELLED: {
            CANCELLED: {
                label: 'Cancelled',
                value: 'cancelled'
            }
        },
    }
}

export const courseSessionStatusActions = {
    PARTICIPANT: {
        PENDING: {
            PENDING: {
                label: 'Pending',
                value: 'pending'
            },
        },
        DECLINED: {
            DECLINED: {
                label: 'Declined',
                value: 'declined'
            },
        },
        RESCHEDULED: {
            RESCHEDULED: {
                label: 'Reschedule',
                value: 'rescheduled'
            }
        },
        SCHEDULED: {
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
        },
        ON_GOING: {
            ON_GOING: {
                label: 'On Going',
                value: 'on_going'
            }
        },
        COMPLETED: {
            COMPLETED: {
                label: 'Completed',
                value: 'completed'
            }
        },
        CANCELLED: {
            CANCELLED: {
                label: 'Cancelled',
                value: 'cancelled'
            }
        },
    },
    MODERATOR: {
        PENDING: {
            PENDING: {
                label: 'Pending',
                value: 'pending'
            },
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        DECLINED: {
            DECLINED: {
                label: 'Declined',
                value: 'declined'
            },
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        RESCHEDULED: {
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        SCHEDULED: {
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            ON_GOING: {
                label: "On Going",
                value: "on_going"
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        ON_GOING: {
            ON_GOING: {
                label: 'On Going',
                value: 'on_going'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        COMPLETED: {
            COMPLETED: {
                label: 'Completed',
                value: 'completed'
            }
        },
        CANCELLED: {
            CANCELLED: {
                label: 'Cancelled',
                value: 'cancelled'
            }
        },
    }
}

export const interviewSessionStatusActions = {
    PARTICIPANT: {
        PENDING: {
            PENDING: {
                label: 'Pending',
                value: 'pending'
            }
        },
        DECLINED: {
            DECLINED: {
                label: 'Declined',
                value: 'declined'
            }
        },
        RESCHEDULED: {
            RESCHEDULED: {
                label: 'Reschedule',
                value: 'rescheduled'
            }
        },
        SCHEDULED: {
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            }
        },
        ON_GOING: {
            ON_GOING: {
                label: 'On Going',
                value: 'on_going'
            }
        },
        CANCELLED: {
            CANCELLED: {
                label: 'Cancelled',
                value: 'cancelled'
            }
        },
        COMPLETED: {
            COMPLETED: {
                label: 'Completed',
                value: 'completed'
            }
        },
    },
    MODERATOR: {
        PENDING: {
            PENDING: {
                label: 'Pending',
                value: 'pending'
            },
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        DECLINED: {
            DECLINED: {
                label: 'Declined',
                value: 'declined'
            },
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        RESCHEDULED: {
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        SCHEDULED: {
            ON_GOING: {
                label: 'On Going',
                value: 'on_going'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        ON_GOING: {
            ON_GOING: {
                label: 'On Going',
                value: 'on_going'
            },
            RESCHEDULE: {
                label: "Reschedule",
                value: "reschedule"
            }
        },
        CANCELLED: {
            CANCELLED: {
                label: 'Cancelled',
                value: 'cancelled'
            }
        },
        COMPLETED: {
            COMPLETED: {
                label: 'Completed',
                value: 'completed'
            }
        },
    },
}

export const sessionFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: "",
    sessionType: calendarFiltersEnum?.ALL?.value,
    eventType: calendarFiltersEnum.ALL.value,
    sessionStatus: sessionStatusEnum?.ALL?.value,
    startDateTime: "",
    endDateTime: "",
    view: "month"
}