import { FaSpinner } from 'react-icons/fa';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const SaveCancelBtn = ({ isLoading = false, isShowBtn = false, onHandleSave, onHandleCancel }) => {
    return isShowBtn ? (
        <>
            {isLoading
                ? <div className={"flex items-center justify-start gap-0.5"}>
                    <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                        {"Please wait ..."}
                    </span>
                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                </div>
                : <div className={"flex items-center justify-start space-x-2"}>
                    <CheckIcon className={"text-base text-green-500 cursor-pointer"} onClick={onHandleSave} />
                    <CloseIcon className={"text-base text-red-500 cursor-pointer"} onClick={onHandleCancel} />
                </div>
            }
        </>
    ) : null
}

export default SaveCancelBtn;