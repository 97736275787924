import './styles/global.css';

import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';

import { GOOGLE_CLIENT_ID } from 'const/default.const'

import store from "redux/store";

import InitRouter from './routers/Router';

import { UserContextProvider } from "pages/auth/message/contexts/userContext";

const App = () => {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        <UserContextProvider>
          <Router>
            <Routes>
              <Route path={"/*"} element={<InitRouter />} />
            </Routes>
          </Router>
        </UserContextProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;