import { useEffect, useState } from "react";

import ComponentLoader from 'components/loader/ComponentLoader';
import DoughnutChart from 'components/charts/doughnutChart/DoughnutChart';

import { useAppState } from 'hooks/useStore';

const DataStorage = () => {
    const { userStorage } = useAppState((state) => state.drive)

    const [userStorageStatus, setUserStorageStatus] = useState({
        labels: ["Storage Used", "Storage Remaining"],
        datasets: [
            {
                label: "Storage Used",
                data: [],
                backgroundColor: ["#2196f3", "#4caf50"]
            },
        ]
    })

    useEffect(() => {
        if (userStorage?.data) {
            setUserStorageStatus({
                labels: ["Storage Used", "Remaining Storage"],
                datasets: [
                    {
                        label: "Storage Used",
                        data: [userStorage?.data?.storage_info?.storage_usage, userStorage?.data?.storage_info?.remaining_storage],
                        backgroundColor: ["#2196f3", "#e0e0e0"]
                    },
                ],
                hoverOffset: 4,
                hoverBackgroundColor: ["#2196f3", "#e0e0e0"]
            })
        }
    }, [userStorage?.data])

    return (
        <div className={"w-full p-5 bg-white flex flex-col items-start justify-start space-y-3 rounded-lg"}>
            <p className={"font-bodyPri font-bold text-black text-base tracking-wide"}>
                {"Data Storage"}
            </p>
            {userStorage?.isLoading &&
                <ComponentLoader isLoading={userStorage?.isLoading} className={"h-48"} />
            }
            {(!userStorage?.isLoading && userStorage?.data) &&
                <div className={"w-full flex flex-col items-center justify-center gap-5"}>
                    <div className={"w-[200px] md:w-[200px]"}>
                        <DoughnutChart
                            doughnutData={userStorageStatus}
                            options={{
                                responsive: true,
                                rotation: 180,
                                plugins: {
                                    legend: {
                                        display: false,
                                        position: 'bottom',
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className={"w-full flex flex-col items-center justify-start gap-1.5"}>
                        <div className={"flex items-center justify-start gap-2"}>
                            <span className={"w-12 h-2 bg-[#4caf50]"}>
                                {""}
                            </span>
                            <span className={"font-bodyPri font-normal text-text-700 text-sm tracking-wide"}>
                                {`Total Storage: `}
                                <span className={"font-bold text-text-900"}>
                                    {`5 GB`}
                                </span>
                            </span>
                        </div>
                        <div className={"flex items-center justify-start gap-2"}>
                            <span className={"w-12 h-2 bg-primary-main"}>
                                {""}
                            </span>
                            <span className={"font-bodyPri font-normal text-text-700 text-sm tracking-wide"}>
                                {`Storage Used: `}
                                <span className={"font-bold text-text-900"}>
                                    {`${userStorage?.data?.storage_info?.storage_usage}%`}
                                </span>
                            </span>
                        </div>
                        <div className={"flex items-center justify-start gap-2"}>
                            <span className={"w-12 h-2 bg-divider-medium"}>
                                {""}
                            </span>
                            <span className={"font-bodyPri font-normal text-text-700 text-sm tracking-wide"}>
                                {`Remaining Storage: `}
                                <span className={"font-bold text-text-900"}>
                                    {`${userStorage?.data?.storage_info?.remaining_storage}%`}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default DataStorage;