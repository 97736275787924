import { useState, useEffect } from "react";
import { cn } from "utils/cn.utils";

import { FaSpinner } from "react-icons/fa";

import { viewContainerConst } from 'components/modals/needATutorModal//data';

import { createLeadDetail } from "redux/lead/lead.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setAddLeadDetailPayload } from 'redux/lead/lead.slice';
import { JourneyConst } from 'redux/lead/lead.const';

import { timeZone } from "utils/dateTime.utils"

const Subject = ({ setStepCount, setViewContainer }) => {
    const { user } = useAppState((state) => state.user)
    const { session } = useAppState(s => s.auth)
    const { addLeadDetail } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()

    const [subjectName, setSubjectName] = useState("")
    const [subjectNameError, setSubjectNameError] = useState("")


    useEffect(() => {
        if (addLeadDetail?.addLeadDetail) {
            dispatcher(setAddLeadDetailPayload({
                ...addLeadDetail?.addLeadDetailPayload,
                isDone: true
            }))
            if (!user?.user?.mobileNo) {
                setStepCount(6)
                setViewContainer(viewContainerConst.PHONE.value)
            }
            if (user?.user?.mobileNo) {
                setStepCount(7)
                setViewContainer(viewContainerConst.CITY.value)
            }
        }
    }, [addLeadDetail?.addLeadDetail])

    useEffect(() => {
        if (addLeadDetail?.addLeadDetailPayload?.subject) {
            setSubjectName(addLeadDetail?.addLeadDetailPayload?.subject)
        }
    }, [addLeadDetail?.addLeadDetailPayload?.subject])

    const onAddSubject = (e) => {
        if (e.keyCode === 13) {
            onHandleButton()
        }
    }

    const onHandleButton = () => {
        if (!subjectName) {
            setSubjectNameError("Please enter subject name")
            return;
        }
        if (addLeadDetail?.isLoading) return;

        if (!session?.isLoggedIn) {
            setStepCount(3)
            setViewContainer(viewContainerConst.NAME.value)
        }
        if (session?.isLoggedIn) {
            const body = {
                grade: addLeadDetail?.addLeadDetailPayload?.level,
                firstName: user?.user?.firstName,
                lastName: user?.user?.lastName,
                email: user?.user?.email,
                isEmailVerified: user?.user?.isEmailVerified,
                classFor: addLeadDetail?.addLeadDetailPayload?.journey,
                contactTime: addLeadDetail?.addLeadDetailPayload?.contactTime,
                timeZone: timeZone,
                actionPageLink: addLeadDetail?.addLeadDetailPayload?.actionPageLink
            }
            if (addLeadDetail?.addLeadDetailPayload?.level) {
                body["grade"] = addLeadDetail?.addLeadDetailPayload?.level
            }
            if (user?.user?.isdCode && user?.user?.mobileNo) {
                body["mobileNo"] = user?.user?.isdCode + "-" + user?.user?.mobileNo
            }

            dispatcher(createLeadDetail(body))
        }
        dispatcher(setAddLeadDetailPayload({
            ...addLeadDetail?.addLeadDetailPayload,
            subject: subjectName.trim()
        }))
    }

    return (
        <div className={"w-full h-full flex flex-col items-start justify-start gap-12 px-2 transition ease-in-out delay-1000 duration-1000 transform"}>
            <span className={"font-bodyPri font-bold text-4xl text-text-900 tracking-wide"}>
                {addLeadDetail?.addLeadDetailPayload?.journey === JourneyConst.ME.value
                    && "Which topic/subject would you like help with?"
                }
                {addLeadDetail?.addLeadDetailPayload?.journey === JourneyConst.OTHER.value
                    && "Which topics/subject would they like help with?"
                }
            </span>
            <div className={"w-full flex flex-col items-start justify-start gap-1"}>
                <input
                    type={"text"}
                    placeholder={"Start Typing - English, Mathematics..."}
                    className={cn(
                        "w-10/12 p-5 rounded-md focus:outline-none",
                        "font-bodyPri font-normal text-text-800 text-lg",
                        "placeholder:text-text-500 placeholder:text-lg",
                        subjectNameError && "border-2 border-red-500 focus:border-red-700",
                        !subjectNameError && "border-2 border-divider-medium focus:border-2 focus:border-text-400"
                    )}
                    autoFocus={true}
                    value={subjectName}
                    onChange={(event) => setSubjectName(event.target.value)}
                    onKeyDown={onAddSubject}
                />
                {subjectNameError &&
                    <span className={"font-bodyPri font-normal text-red-500 text-xs"}>
                        {subjectNameError}
                    </span>
                }
            </div>
            <span
                className={cn(
                    "px-12 py-3 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-medium text-2xl text-text-50 bg-primary-dark",
                    "transition ease-in-out delay-100 duration-300 transform hover:scale-105"
                )}
                onClick={onHandleButton}
            >
                {"Continue"}
            </span>
            {addLeadDetail?.isLoading &&
                <div className={"flex items-center justify-center gap-2"}>
                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                    <span className={"font-bodyPri font-normal text-base text-text-700 whitespace-nowrap"}>
                        {"Please wait ..."}
                    </span>
                </div>
            }
            {addLeadDetail?.errorMsg &&
                <div className={"flex items-center justify-center font-bodyPri font-normal text-base text-red-500"}>
                    {addLeadDetail?.errorMsg}
                </div>
            }
        </div>
    )
}

export default Subject;