import { useState } from 'react';

import { FiUser } from "react-icons/fi";

import InputTextSwitch from 'components/InputTextSwitch/inputTextSwitch';

import Qualification from './Qualification';
import PersonalDetail from './personalDetails';

import { updateUserDetailByUserId } from 'redux/user/user.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { debounce } from 'utils/generators.utils';

const PersonalInformation = () => {
    const { user } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()

    const [editBtnConst, setEditBtnConst] = useState(null)
    const [inputName, setInputName] = useState({
        firstName: user?.user?.firstName || "",
        lastName: user?.user?.lastName || ""
    })

    const onSubmit = (key, value) => {
        dispatcher(updateUserDetailByUserId(user?.user?.userId, { [key]: value }))
        setEditBtnConst(null)
    }

    const changeName = (key, value) => {
        setInputName(s => ({ ...s, [key]: value }))
        const delayFunction = debounce.debounce(onSubmit)
        delayFunction(key, value)
    }

    return (
        <div className={'w-full space-y-3 p-5 pb-20 border border-background-light rounded shadow'}>
            <div className={'w-full flex-col lg:flex-row flex gap-3 items-center'}>
                <div className={'w-full flex gap-2 items-center'}>
                    <div className={'flex items-center gap-1 text-text-600'}>
                        <FiUser size={16} />
                        <p className={'whitespace-nowrap'}>First Name:</p>
                    </div>
                    <InputTextSwitch
                        textStyle={"text-text-900 font-medium"}
                        placeholder={"First Name"}
                        text={<p>{inputName.firstName}</p>}
                        value={inputName.firstName}
                        onChange={(e) => changeName("firstName", e.target.value)}
                    />
                </div>
                <div className={'w-full flex gap-2 items-center'}>
                    <div className={'flex items-center gap-1 text-text-600'}>
                        <FiUser size={16} />
                        <p className={'whitespace-nowrap'}>Last Name:</p>
                    </div>
                    <InputTextSwitch
                        textStyle={"text-text-900 font-medium"}
                        placeholder={"Last Name"}
                        text={<p>{inputName.lastName}</p>}
                        value={inputName.lastName}
                        onChange={(e) => changeName("lastName", e.target.value)}
                    />
                </div>
            </div>
            <PersonalDetail />
            <Qualification
                editBtnConst={editBtnConst}
                setEditBtnConst={setEditBtnConst}
            />
        </div>
    )
}

export default PersonalInformation;