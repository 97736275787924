import { useCallback, useState } from 'react';

import { FaRegPlusSquare } from 'react-icons/fa';

import ToolTipView from 'components/tooltipView';

import SideBarExpendable from 'pages/auth/edulyteLms/commonComponents/SideBarExpendable';

import LmsLectureListItems from './LmsLectureListItems';
import LmsArticleListItems from './LmsArticleListItems';
import LmsQuizListItems from './LmsQuizListItems';

import { getLmsCoursePageArticleList, getLmsCoursePageLectureList, getLmsCoursePageQuizList } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsCourseSectionLessonTypeEnum } from 'redux/edulyteLms/lmsCourse/lmsCourse.const';

import { debounce } from 'utils/generators.utils';

const CourseSideBarItems = () => {
    const { modal } = useAppState((state) => state.local)
    const { lmsCourseDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    const [articleSearch, setArticleSearch] = useState('')
    const [lectureSearch, setLectureSearch] = useState('')
    const [quizSearch, setQuizSearch] = useState('')

    const onHandleSearchLecture = useCallback((value) => {
        dispatcher(getLmsCoursePageLectureList({ search: value }))
    }, [lectureSearch])

    const onHandleSearchArticle = useCallback((value) => {
        dispatcher(getLmsCoursePageArticleList({ search: value }))
    }, [articleSearch])

    const onHandleSearchQuiz = useCallback((value) => {
        dispatcher(getLmsCoursePageQuizList({ search: value }))
    }, [quizSearch])

    const onHandleChangeLectureSearch = useCallback((e) => {
        setArticleSearch(e.target.value)
        const delayFunction = debounce.debounce(onHandleSearchLecture)
        delayFunction(e.target.value)
    }, [lectureSearch])

    const onHandleChangeArticleSearch = useCallback((e) => {
        setLectureSearch(e.target.value)
        const delayFunction = debounce.debounce(onHandleSearchArticle)
        delayFunction(e.target.value)
    }, [lectureSearch])

    const onHandleChangeQuizSearch = useCallback((e) => {
        setQuizSearch(e.target.value)
        const delayFunction = debounce.debounce(onHandleSearchQuiz)
        delayFunction(e.target.value)
    }, [lectureSearch])

    const onHandleAddQuiz = useCallback(() => {
        dispatcher(setModal({
            ...modal,
            [modalConst.CREATE_LMS_QUIZ_MODAL.stateKey]: true
        }))
    }, [modal])

    const onHandleAddVideoLecture = useCallback(() => {
        dispatcher(setModal({
            ...modal,
            [modalConst.CREATE_LMS_LECTURE_MODAL.stateKey]: true
        }))
    }, [modal])

    const onHandleAddArticle = useCallback(() => {
        dispatcher(setModal({
            ...modal,
            [modalConst.CREATE_LMS_ARTICLE_MODAL.stateKey]: true
        }))
    }, [modal])

    return (
        <div className={'flex flex-col w-full gap-5 font-bodyPri'}>
            <div className={'w-full flex flex-col gap-5'}>
                <div>
                    <p className={'text-lg text-black font-medium'}>
                        {"Library"}
                    </p>
                    <p className={'text-sm text-text-600'}>
                        {"Quick access, pick from library."}
                    </p>
                </div>
                <div className={"flex items-start justify-start gap-1.5"}>
                    <SideBarExpendable
                        isExpended={true}
                        placeholder={'Search video lectures'}
                        disabledClassName={"border-text-200"}
                        contentTooltip={"Add course section to enable these features."}
                        isDisabled={!lmsCourseDetail?.data?.course_sections || (lmsCourseDetail?.data?.course_sections?.length === 0)}
                        category={`${lmsCourseSectionLessonTypeEnum?.LECTURE?.label}s`}
                        onChange={onHandleChangeLectureSearch}
                        children={<LmsLectureListItems lectureSearch={lectureSearch} />}
                        expendHeight={'300px'}
                    />
                    <ToolTipView content={"Add Video Lecture"}>
                        <button
                            className={"p-2 rounded-full transition-transform duration-200 hover:bg-divider-darkLight"}
                            onClick={onHandleAddVideoLecture}
                        >
                            <FaRegPlusSquare className={"text-2xl text-text-700 hover:text-primary-dark"} />
                        </button>
                    </ToolTipView>
                </div>
                <div className={"flex items-start justify-start gap-1.5"}>
                    <SideBarExpendable
                        placeholder={'Search quiz'}
                        disabledClassName={"border-text-200"}
                        contentTooltip={"Add course section to enable these features."}
                        isDisabled={!lmsCourseDetail?.data?.course_sections || (lmsCourseDetail?.data?.course_sections?.length === 0)}
                        category={`${lmsCourseSectionLessonTypeEnum?.QUIZ?.label}zes`}
                        onChange={onHandleChangeQuizSearch}
                        children={(<LmsQuizListItems quizSearch={quizSearch} />)}
                        expendHeight={'300px'}
                    />

                    <ToolTipView content={"Add Quiz"}>
                        <button
                            className={"p-2 rounded-full transition-transform duration-200 hover:bg-divider-darkLight"}
                            onClick={onHandleAddQuiz}
                        >
                            <FaRegPlusSquare className={"text-2xl text-text-700 hover:text-primary-dark"} />
                        </button>
                    </ToolTipView>
                </div>

                <div className={"flex items-start justify-start gap-1.5"}>
                    <SideBarExpendable
                        placeholder={'Search article'}
                        disabledClassName={"border-text-200"}
                        contentTooltip={"Add course section to enable these features."}
                        isDisabled={!lmsCourseDetail?.data?.course_sections || (lmsCourseDetail?.data?.course_sections?.length === 0)}
                        category={`${lmsCourseSectionLessonTypeEnum?.ARTICLE?.label}s`}
                        onChange={onHandleChangeArticleSearch}
                        children={(<LmsArticleListItems articleSearch={articleSearch} />)}
                        expendHeight={'300px'}
                    />
                    <ToolTipView content={"Add Article"}>
                        <button
                            className={"p-2 rounded-full transition-transform duration-200 hover:bg-divider-darkLight"}
                            onClick={onHandleAddArticle}
                        >
                            <FaRegPlusSquare className={"text-2xl text-text-700 hover:text-primary-dark"} />
                        </button>
                    </ToolTipView>
                </div>
            </div>
        </div>
    )
};

export default CourseSideBarItems;