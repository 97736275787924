export const pageHeading = {
    heading: "My Classes",
    subHeading: "Find the list of classes and liked classes",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/my-courses.svg"
}

export const courseTabs = {
    ALL_COURSES: {
        label: "All Classes",
        value: "all_courses"
    },
    FAV_COURSES: {
        label: "Favourite",
        value: "fav_courses"
    }
}

export const pageName = {
    ALL_COURSES: {
        value: "all_courses"
    }
}
