import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Discount } from "pages/auth/offersAndDiscount/store/types";
import { INITIAL_STATE } from "./Mock_Data";

export const offersSlice = createSlice({
    name: "offers",
    initialState: INITIAL_STATE,
    reducers: {
        setDiscountAmount: (
            state,
            action: PayloadAction<Discount>
        ) => {
            const { discount, code, name, termsAndConditions } = action.payload;
            state.discounts.map((items: any) => {
                items.discount = discount;
                items.code = code;
                items.name = name;
                items.termsAndConditions = termsAndConditions;
            })

        }
    }
});

export const { setDiscountAmount } = offersSlice.actions;

export default offersSlice.reducer