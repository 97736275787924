import { useState, useEffect } from 'react';
import { cn } from "utils/cn.utils";

import { FaInfoCircle } from 'react-icons/fa';

import ButtonLoader from 'components/loader/ButtonLoader';
import ComponentLoader from 'components/loader/ComponentLoader';
import Pagination from 'components/pagination/Pagination';
import CourseSessionCard from 'components/modals/addCourseNextSessionModal/CourseSessionCard';

import { createNextCourseSessionDetail } from 'redux/course/course.request';
import { getSessionCourseList } from 'redux/session/session.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetNextCourseSessionDetail, resetNextCourseSessionDetailPayload } from 'redux/course/course.slice';
import { resetSessionCourseList } from 'redux/session/session.slice';
import { modalConst } from 'redux/local/local.const';

import { dayjs } from "utils/dateTime.utils";

const AddCourseNextSessionModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { nextCourseSessionDetail } = useAppState((state) => state.course)
    const { sessionCourseList } = useAppState((state) => state.session)

    const dispatcher = useAppDispatcher()

    const [newWeekCount, setNewWeekCount] = useState()

    useEffect(() => {
        if (nextCourseSessionDetail?.data) {
            dispatcher(setModal({
                ...modal,
                [modalConst.ADD_COURSE_NEXT_SESSION_MODAL.stateKey]: false
            }))
        }
    }, [nextCourseSessionDetail?.data])

    useEffect(() => {
        return () => {
            dispatcher(resetSessionCourseList())
            dispatcher(resetNextCourseSessionDetail())
            dispatcher(resetNextCourseSessionDetailPayload())
        }
    }, [])

    useEffect(() => {
        if (nextCourseSessionDetail?.payload?.courseId) {
            dispatcher(getSessionCourseList({ page: 1, records: 10, courseId: nextCourseSessionDetail?.payload?.courseId }))
        }
    }, [nextCourseSessionDetail?.payload?.courseId])

    const onHandleCreateSessions = () => {
        if (nextCourseSessionDetail?.isLoading) return;

        if (newWeekCount <= 0 || newWeekCount > 5) {
            alert("Session count cannot be less than 0 or more than 5")
            return;
        }

        if (!nextCourseSessionDetail?.payload?.courseId) {
            alert("Something Went Wrong!")
            return;
        }
        dispatcher(createNextCourseSessionDetail(nextCourseSessionDetail?.payload?.courseId, { weekCount: newWeekCount }))
    }

    const sortSessionCourse = (sessionCourse1, sessionCourse2) => {
        const sessionCourse1StartDateTime = dayjs(sessionCourse1.session.startDateTime).utc()
        const sessionCourse2StartDateTime = dayjs(sessionCourse2.session.startDateTime).utc()
        return sessionCourse1StartDateTime.isSameOrBefore(sessionCourse2StartDateTime) ? 1 : -1
    }

    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"min-h-[24rem] block space-y-5"}>
                <div className={"flex items-center justify-center"}>
                    <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide line-clamp-1 border-b-2 border-divider-medium"}>
                        {nextCourseSessionDetail?.payload?.courseTitle}
                    </span>
                </div>
                <div className={cn(
                    "w-full flex flex-col items-start justify-start gap-1.5"
                )}>
                    <div className={"flex items-center justify-start gap-1"}>
                        <label for={"weeklyCycles"} className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                            {"No. of future cycles (weekly)"}
                        </label>
                        <div className={"relative has-tooltip cursor-pointer -mt-1"}>
                            <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                            <span
                                className={cn(
                                    "w-fit md:w-56 px-2 py-0.5 font-bodyPri font-normal text-xs rounded shadow-lg bg-text-900 text-text-50 tooltip -translate-x-[50%] translate-y-2",
                                    "flex flex-col items-start justify-start overflow-hidden"
                                )}
                            >
                                {"Use a number from 1 to 5 to add sessions for up to 5 weeks in the future. But wait, if sessions are already set, it won't happen. Keep the sessions within the start and end dates of your group/cohort."}
                            </span>
                        </div>
                    </div>
                    <div className={"w-full flex items-center justify-start gap-5"}>
                        <input
                            id={"weeklyCycles"}
                            name={"weeklyCycles"}
                            type={"number"}
                            placeholder={"Any number from 1 to 5"}
                            className={cn(
                                "w-full input-number-spin-none px-5 py-1.5 focus:outline-none focus:border-primary-main",
                                "transition ease-in-out duration-300",
                                "border-2 border-text-500 rounded-md",
                                "font-bodyPri font-normal text-text-800 text-base"
                            )}
                            value={newWeekCount?.toString()}
                            onChange={(event) => setNewWeekCount(Number(event?.target?.value))}
                        />
                        <button
                            type={"submit"}
                            className={cn(
                                "px-3 py-1 flex justify-center items-center rounded-lg",
                                "font-buttons font-normal text-base whitespace-nowrap",
                                !newWeekCount && "bg-text-300 hover:bg-text-300 text-text-50 cursor-not-allowed",
                                (newWeekCount && !nextCourseSessionDetail?.isLoading) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                                (newWeekCount && nextCourseSessionDetail?.isLoading) && "bg-secondary-main"
                            )}
                            onClick={onHandleCreateSessions}
                        >
                            {nextCourseSessionDetail?.isLoading && <ButtonLoader isLoading={nextCourseSessionDetail?.isLoading} />}
                            {!nextCourseSessionDetail?.isLoading && "Create Sessions"}
                        </button>
                    </div>
                </div>

                <div className={"flex items-center justify-center"}>
                    <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide text-center border-b-2 border-divider-medium"}>
                        {"Previously created sessions"}
                    </span>
                </div>

                <ComponentLoader isLoading={sessionCourseList?.isLoading} className={"h-80"} />
                {(!sessionCourseList?.isLoading && sessionCourseList?.data?.records > 0) &&
                    <div className={cn(
                        "flex flex-col w-full max-h-80 overflow-hidden overflow-y-scroll space-y-3 transition ease-in-out delay-150 duration-300",
                        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight",
                        sessionCourseList?.data?.records > 10 && "hover:pr-3"
                    )}>
                        {sessionCourseList?.data?.results?.slice(0)?.sort(sortSessionCourse)?.map((sessionCourse, index) => (
                            <CourseSessionCard key={index} sessionCourse={sessionCourse} />
                        ))
                        }
                    </div>
                }

                {(!sessionCourseList?.isLoading && sessionCourseList?.data && sessionCourseList?.data?.records > 0) && (
                    <div className="w-full flex justify-center items-center">
                        <Pagination
                            page={sessionCourseList?.data?.page}
                            totalPages={sessionCourseList?.data?.totalPages}
                            onChangePage={(page) => dispatcher(getSessionCourseList({ page: page || 1, records: 10, courseId: nextCourseSessionDetail?.payload?.courseId }))}
                        />
                    </div>
                )}

                {(!sessionCourseList?.isLoading && (!sessionCourseList?.data || sessionCourseList?.data?.records === 0 || sessionCourseList?.message)) &&
                    <div className={"flex items-center justify-center"}>
                        <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                            {sessionCourseList?.message || "No sessions found!"}
                        </span>
                    </div>
                }
            </div>
        </div>
    )
}

export default AddCourseNextSessionModal