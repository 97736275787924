import { cn } from "utils/cn.utils";

import { SiGooglemeet } from 'react-icons/si';
import { MdZoomOutMap } from "react-icons/md";
import { AiOutlineSkype } from "react-icons/ai";
import { IoVideocamOutline } from "react-icons/io5";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setAddUserEventlyDetailPayload } from "redux/evently/evently.slice";

import { validateGoogleMeetUrl, validateSkypeMeetingUrl, validateZoomMeetingUrl } from 'utils/validation-functions';

const EventlyMeetingLink = () => {
    const { addUserEventlyDetail } = useAppState((state) => state.evently)

    const dispatcher = useAppDispatcher()

    const onHandleChangeMeetingLink = (event) => {
        dispatcher(setAddUserEventlyDetailPayload({
            ...addUserEventlyDetail?.payload,
            meetingLink: event?.target?.value
        }))
    }

    const onHandleAddMeetingLink = () => {
        dispatcher(setAddUserEventlyDetailPayload({
            ...addUserEventlyDetail?.payload,
            isShowMeetingLinkInput: true
        }))
    }

    const getMeetingIcon = () => {
        if (validateGoogleMeetUrl(addUserEventlyDetail?.payload?.meetingLink)) {
            return (
                <SiGooglemeet className={"text-text-700 text-xl"} />
            )
        } else if (validateZoomMeetingUrl(addUserEventlyDetail?.payload?.meetingLink)) {
            return (
                <MdZoomOutMap className={"text-text-700 text-xl"} />
            )
        } else if (validateSkypeMeetingUrl(addUserEventlyDetail?.payload?.meetingLink)) {
            return (
                <AiOutlineSkype className={"text-text-700 text-xl"} />
            )
        } else {
            return (
                <IoVideocamOutline className={"text-text-700 text-xl cursor-pointer"} />
            )
        }
    }

    return (
        <div className={"w-full grid grid-cols-12 gap-x-2 px-5"}>
            <div className={"col-start-1 col-span-1 self-center"}>
                {getMeetingIcon()}
            </div>
            {!addUserEventlyDetail?.payload?.isShowMeetingLinkInput &&
                <div
                    className={"col-start-2 col-span-full self-center p-2 hover:bg-background-lightBlue rounded cursor-pointer"}
                    onClick={onHandleAddMeetingLink}
                >
                    <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                        {"Add meeting link"}
                    </span>
                </div>
            }
            {addUserEventlyDetail?.payload?.isShowMeetingLinkInput &&
                <div className={"col-start-2 col-span-full self-center"}>
                    <input
                        type={"text"}
                        className={cn(
                            "flex w-full p-2 focus:outline-none bg-background-light border-b focus:border-primary-main",
                            "font-bodyPri font-normal text-text-900 text-base placeholder:text-text-700"
                        )}
                        placeholder={"Enter meeting link"}
                        value={addUserEventlyDetail?.payload?.meetingLink}
                        onChange={onHandleChangeMeetingLink}
                        autoFocus={true}
                    />
                </div>
            }
        </div>
    )
}

export default EventlyMeetingLink;