import { referralFilterProps } from "redux/referral/referral.const"

export const REFERRAL_INITIAL_STATE = {
    userReferralList: {
        isLoading: false,
        userReferralList: null,
        filteredUserReferralList: null,
        errorMsg: null
    },
    filterProps: referralFilterProps
}