import { memo } from 'react';

import { cn } from 'utils/cn.utils';

type TstatusButtonProps = {
    status: string,
    className?: string
}

const StatusButton = memo(({ status, className = "" }: TstatusButtonProps) => {
    return status ? (
        <span className={cn(`px-2 py-1 rounded-xl font-bodyPri text-base`, className)}>
            {status}
        </span>
    ) : <span>{"-"}</span>
})

export default StatusButton;