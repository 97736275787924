import tw, { styled } from 'twin.macro';
import { Section, SectionContent } from 'components/generalComponent/StyledComponent';

export const Container = tw(Section)``;

export const Content = tw(SectionContent)`
    col-start-2 col-span-10 md:col-start-3 md:col-span-8 flex flex-col items-center justify-center
`;

export const Header = tw.div`
    flex flex-col items-center gap-5
`;

export const Heading = tw.h3`
    font-heading font-semibold text-3xl text-text-900 text-center
`;

export const ItemContainer = tw.div`
    w-full flex items-center justify-center mt-10
`;

export const InputContainer = styled.div`
    ${tw`
        flex flex-row items-center w-full h-10 md:mx-20 lg:mx-48
    `}
    input {
        ${tw`
            flex[3] h-full px-5 font-bodyPri font-normal text-lg text-text-900 
            focus:(outline-none border-divider-lightDark) rounded-l-lg bg-white
            border-2 border-divider-medium hover:border-divider-lightDark border-r-0
        `}
    }
`;

export const BtnSubmit = tw.div`
    font-buttons font-normal text-lg text-white bg-primary-main hover:bg-secondary-main
    flex flex[1] items-center justify-center h-full px-5 rounded-r-lg cursor-pointer
`;