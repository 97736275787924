import format from 'string-format';

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "./review.const"

class ReviewService {

    getTutorReviewListByTutorId = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.TUTOR_REVIEWS_LIST, params),
            { params: query }
        )

        return response
    }

    getPublicUserReviewListByUserId = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.USER_REVIEW_LIST, params),
            { params: query }
        )

        return response;
    }

    createUserReviewDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.USER_REVIEW_DETAIL),
            body
        )

        return response;
    }
}
export default new ReviewService();