import React from "react";
// import sendicon from "../../icons/sendicon.svg";
import { cn } from "utils/cn.utils";

import { PaperAirplaneIcon } from "@heroicons/react/outline";

export default function ChatViewSendMessageSend({ sendEnable = false }) {
  return (
    <PaperAirplaneIcon className={cn(
      "rotate-90 h-8 w-8 text-text-500",
      sendEnable && "!text-primary-dark hover:bg-primary-light hover:ring-4 hover:ring-primary-light hover:rounded-full cursor-pointer"
    )} />
  );
}
