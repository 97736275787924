import { useCallback, useEffect, useState } from 'react';
import { toast } from "react-toastify";

import { cn } from "utils/cn.utils";

import { AiOutlinePlus } from 'react-icons/ai';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ComponentLoader from 'components/loader/ComponentLoader';

import { libraryTabConst } from 'components/modals/lmsModals/attachLibraryModal/data';
import { uploadLmsMediaConst } from 'components/modals/lmsModals/uploadLmsMediaModal/data';

import { searchAlreadyAddedCategory } from '../../../editLMSQuiz.data';

import { createLmsQuizCategoryDetail } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';
import { createLmsResourceDetail } from 'redux/edulyteLms/lmsResource/lmsResource.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetLocalTabs, setLocalTabs, setModal } from 'redux/local/local.slice';
import { resetAddLmsQuizSectionPayload, setAddLmsQuizSectionPayload } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.slice';
import { resetAddLmsResourceDetail, resetFilterProps, setFilterProps } from 'redux/edulyteLms/lmsResource/lmsResource.slice';
import { modalConst } from 'redux/local/local.const';
import { addLmsQuizSectionPayload, lmsQuizCategoryEnum } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.const';
import { lmsResourceTypeEnum, resourceFileTypeEnum } from 'redux/edulyteLms/lmsResource/lmsResource.const';

const EmptyQuizSectionContainer = ({ quizSectionItem }) => {
    const { modal, localTabs } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { lmsQuizDetail, addLmsQuizSection, addLmsQuizCategory } = useAppState((state) => state.lms.lmsQuiz)
    const { addLmsResourceDetail, filterProps } = useAppState((state) => state.lms.lmsResource)

    const dispatcher = useAppDispatcher()

    const [isDraggingOver, setIsDraggingOver] = useState(false)

    useEffect(() => {
        if ((addLmsQuizSection?.payload?.sectionId && addLmsQuizSection?.payload?.quizCategoryType) && (localTabs?.tabItem?.payload?.questionId || localTabs?.tabItem?.payload?.articleId || localTabs?.tabItem?.payload?.resourceId)) {
            let filteredQuizCategoryItem = null;
            let targetQuizCategoryId = localTabs?.tabItem?.payload?.questionId || localTabs?.tabItem?.payload?.articleId || localTabs?.tabItem?.payload?.resourceId
            filteredQuizCategoryItem = searchAlreadyAddedCategory(lmsQuizDetail?.data?.quiz_sections, targetQuizCategoryId)
            if (!filteredQuizCategoryItem) {
                const body = {
                    quiz_section_id: addLmsQuizSection?.payload?.sectionId,
                    type: addLmsQuizSection?.payload?.quizCategoryType?.value,
                }
                if (localTabs?.tabItem?.payload?.questionId) {
                    body["question_id"] = localTabs?.tabItem?.payload?.questionId
                }
                if (localTabs?.tabItem?.payload?.articleId) {
                    body["article_id"] = localTabs?.tabItem?.payload?.articleId
                }
                if (localTabs?.tabItem?.payload?.resourceId) {
                    body["resource_id"] = localTabs?.tabItem?.payload?.resourceId
                }
                dispatcher(createLmsQuizCategoryDetail(body))
                dispatcher(resetLocalTabs())
                dispatcher(setModal({
                    ...modal,
                    [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: false
                }))
            }
        }
    }, [localTabs])

    useEffect(() => {
        if (addLmsResourceDetail?.data) {
            dispatcher(resetLocalTabs())
            dispatcher(resetFilterProps())
            if ([lmsQuizCategoryEnum?.IMAGE?.label]?.includes(addLmsQuizSection?.payload?.quizCategoryType?.label)) {
                dispatcher(setFilterProps({
                    filterProps: {
                        ...filterProps,
                        resourceType: lmsResourceTypeEnum.FILE.value,
                        resourceFileType: resourceFileTypeEnum.IMAGE.value,
                        disabledFilters: [lmsResourceTypeEnum.YOUTUBE.value, lmsResourceTypeEnum.LINK.value]
                    }
                }))
            } else {
                dispatcher(setFilterProps({
                    filterProps: {
                        ...filterProps,
                        resourceType: lmsResourceTypeEnum.FILE.value,
                        resourceFileType: resourceFileTypeEnum.VIDEO.value,
                        disabledFilters: [lmsResourceTypeEnum.YOUTUBE.value, lmsResourceTypeEnum.LINK.value]
                    }
                }))
            }
            dispatcher(setLocalTabs({
                ...localTabs,
                modalTitle: "Library",
                tabList: Object.values(libraryTabConst),
                activeTabList: [libraryTabConst.RESOURCES.value],
                currentActiveTab: libraryTabConst.RESOURCES.value,
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.UPLOAD_LMS_MEDIA_MODAL.stateKey]: false,
                [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: true
            }))
            dispatcher(resetAddLmsResourceDetail())
        }
    }, [addLmsResourceDetail?.data])

    useEffect(() => {
        if (localTabs?.tabItem && (localTabs?.tabItem?.tabType === uploadLmsMediaConst.MY_FILES.value) && localTabs?.tabItem?.payload?.fileId) {
            const body = {
                type: lmsResourceTypeEnum.FILE.value,
                title: localTabs?.tabItem?.payload?.fileName,
                file_id: localTabs?.tabItem?.payload?.fileId,
                resource_owner_user_id: user?.user?.userId
            }
            dispatcher(createLmsResourceDetail(body))
            dispatcher(resetLocalTabs())
            dispatcher(setModal({
                ...modal,
                [modalConst.UPLOAD_LMS_MEDIA_MODAL.stateKey]: false
            }))
        }
    }, [localTabs])

    const onHandleDragOver = (event) => {
        event.preventDefault();
        setIsDraggingOver(true)
    };

    const onHandleDragEnter = (event) => {
        event.preventDefault();
        setIsDraggingOver(true);
    };

    const onHandleDragLeave = (event) => {
        event.preventDefault();
        setIsDraggingOver(false);
    };


    const onHandleDrop = async (event) => {
        event.preventDefault();

        setIsDraggingOver(false)
        let parsedData = {}
        try {
            parsedData = JSON.parse(event.dataTransfer.getData("application/json"))
        } catch (error) {
            console.error(error)
            toast.error("Something went wrong!")
        }

        if (parsedData) {
            let filteredQuizCategoryItem = null;
            if ((lmsQuizDetail?.data?.quiz_sections?.length > 0)) {
                if (parsedData?.quizCategoryType === lmsQuizCategoryEnum.QUESTION.value) {
                    filteredQuizCategoryItem = searchAlreadyAddedCategory(lmsQuizDetail?.data?.quiz_sections, parsedData?.id)
                    if (filteredQuizCategoryItem) return;
                }
                if (parsedData?.quizCategoryType === lmsQuizCategoryEnum.ARTICLE.value) {
                    filteredQuizCategoryItem = searchAlreadyAddedCategory(lmsQuizDetail?.data?.quiz_sections, parsedData?.article_id)
                    if (filteredQuizCategoryItem) return;
                }
                if (parsedData?.quizCategoryType === lmsQuizCategoryEnum.VIDEO.value) {
                    filteredQuizCategoryItem = searchAlreadyAddedCategory(lmsQuizDetail?.data?.quiz_sections, parsedData?.id)
                    if (filteredQuizCategoryItem) return;
                }
                if (parsedData?.quizCategoryType === lmsQuizCategoryEnum.IMAGE.value) {
                    filteredQuizCategoryItem = searchAlreadyAddedCategory(lmsQuizDetail?.data?.quiz_sections, parsedData?.id)
                    if (filteredQuizCategoryItem) return;
                }
            }
            if (!filteredQuizCategoryItem) {
                const body = {
                    quiz_section_id: quizSectionItem?.id,
                    type: parsedData?.quizCategoryType,
                }
                if (parsedData?.quizCategoryType === lmsQuizCategoryEnum.QUESTION.value) {
                    body["question_id"] = parsedData?.id
                }
                if (parsedData?.quizCategoryType === lmsQuizCategoryEnum.ARTICLE.value) {
                    body["article_id"] = parsedData?.article_id
                }
                if (parsedData?.quizCategoryType === lmsQuizCategoryEnum.VIDEO.value) {
                    body["resource_id"] = parsedData?.id
                }
                if (parsedData?.quizCategoryType === lmsQuizCategoryEnum.IMAGE.value) {
                    body["resource_id"] = parsedData?.id
                }
                dispatcher(createLmsQuizCategoryDetail(body))
            }
        }
    }

    const onHandleOpenModal = useCallback((selectedItem) => {
        dispatcher(resetAddLmsQuizSectionPayload())
        dispatcher(resetLocalTabs())
        dispatcher(setAddLmsQuizSectionPayload({
            ...addLmsQuizSectionPayload,
            sectionId: quizSectionItem?.id,
            quizCategoryType: selectedItem,
        }))
        if ([lmsQuizCategoryEnum.BULK_QUESTION.value].includes(selectedItem?.value)) {
            dispatcher(setAddLmsQuizSectionPayload({
                ...addLmsQuizSectionPayload,
                sectionId: quizSectionItem?.id,
                quizCategoryType: selectedItem,
            }))

            dispatcher(setModal({
                ...modal,
                [modalConst.CREATE_BULK_QUESTIONS.stateKey]: true

            }))
            return;
        }

        if ([lmsQuizCategoryEnum?.QUESTION?.value]?.includes(selectedItem?.value)) {
            dispatcher(setLocalTabs({
                ...localTabs,
                modalTitle: "Library",
                tabList: Object.values(libraryTabConst),
                activeTabList: [libraryTabConst.QUESTIONS.value],
                currentActiveTab: libraryTabConst.QUESTIONS.value,
            }))
        }
        if ([lmsQuizCategoryEnum?.ARTICLE?.value]?.includes(selectedItem?.value)) {
            dispatcher(setLocalTabs({
                ...localTabs,
                modalTitle: "Library",
                tabList: Object.values(libraryTabConst),
                activeTabList: [libraryTabConst.ARTICLES.value],
                currentActiveTab: libraryTabConst.ARTICLES.value,
            }))
        }
        if ([lmsQuizCategoryEnum?.IMAGE?.value, lmsQuizCategoryEnum?.VIDEO?.value]?.includes(selectedItem?.value)) {
            dispatcher(resetFilterProps())
            dispatcher(setLocalTabs({
                ...localTabs,
                modalTitle: "Library",
                tabList: Object.values(libraryTabConst),
                activeTabList: [libraryTabConst.RESOURCES.value],
                currentActiveTab: libraryTabConst.RESOURCES.value,
            }))
            if ([lmsQuizCategoryEnum?.IMAGE?.label]?.includes(selectedItem?.label)) {
                dispatcher(setFilterProps({
                    filterProps: {
                        ...filterProps,
                        resourceType: lmsResourceTypeEnum.FILE.value,
                        resourceFileType: resourceFileTypeEnum.IMAGE.value,
                        disabledFilters: [lmsResourceTypeEnum.YOUTUBE.value, lmsResourceTypeEnum.LINK.value]
                    }
                }))
            } else {
                dispatcher(setFilterProps({
                    filterProps: {
                        ...filterProps,
                        resourceType: lmsResourceTypeEnum.FILE.value,
                        resourceFileType: resourceFileTypeEnum.VIDEO.value,
                        disabledFilters: [lmsResourceTypeEnum.YOUTUBE.value, lmsResourceTypeEnum.LINK.value]
                    }
                }))
            }
        }
        dispatcher(setModal({
            ...modal,
            [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: true
        }))
    }, [localTabs, modal, addLmsQuizSection?.payload])

    return (
        <div
            className={cn(
                "w-full px-5 py-3 bg-white rounded-lg flex items-center justify-center gap-3",
                "border border-dashed border-divider-dark cursor-pointer",
                isDraggingOver && "bg-divider-darkLight"
            )}
            onDragOver={onHandleDragOver}
            onDragEnter={onHandleDragEnter}
            onDragLeave={onHandleDragLeave}
            onDrop={onHandleDrop}
        >
            {addLmsQuizCategory?.isLoading &&
                <ComponentLoader isLoading={addLmsQuizCategory?.isLoading} className={"h-7"} />
            }
            {!addLmsQuizCategory?.isLoading &&
                <div className={"flex flex-col gap-1 lg:flex-row flex-wrap items-center justify-center lg:gap-3 text-sm font-buttons"}>
                    <span className={"font-normal text-text-800 tracking-wide"}>
                        {"Drag & drop from right "}
                    </span>
                    <span className={"font-bold text-text-800 "}>
                        {" OR "}
                    </span>
                    <div className={'flex flex-wrap items-center justify-center gap-2'}>
                        {Object.values(lmsQuizCategoryEnum)?.map((quizType, index) => (
                            <button
                                key={index}
                                className={cn(
                                    'flex justify-center items-center bg-white rounded-full gap-1 px-3 py-0.5 md:px-5 md:py-1',
                                    'border border-divider-dark hover:border-primary-dark hover:text-primary-dark',
                                    'font-normal text-text-900  tracking-wide ease-in-out duration-200',
                                    index === 0 && "addAiQuestion bg-secondary-dark hover:bg-primary-dark text-white hover:text-white border-secondary-dark hover:border-primary-dark shadow"
                                )}
                                onClick={() => onHandleOpenModal(quizType)}
                            >
                                {index === 0 ? (<AutoFixHighIcon style={{ fontSize: 18 }} className={'text-yellow-200 animate-pulse'} />
                                ) : (
                                    <AiOutlinePlus className={'text-lg'} />
                                )}
                                {quizType.label}
                            </button>
                        ))}
                    </div>
                </div>
            }
        </div>
    )
}

export default EmptyQuizSectionContainer;