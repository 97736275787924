import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { cn } from "utils/cn.utils";

import { BsCheckAll } from "react-icons/bs";
import { IoMdPeople } from 'react-icons/io';
import { AiOutlineMail } from "react-icons/ai";
import { FaMobileAlt } from "react-icons/fa";
import { FaSpinner } from 'react-icons/fa';
import { MdCancel } from "react-icons/md";

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

import Accordion from "components/accordion/Accordion";
import ButtonLoader from 'components/loader/ButtonLoader';
import IsdCodeSelector from 'components/optionSelector/IsdCodeSelector';

import { PageContentStyle } from './PromoSignUpPageStyle';
import { dataFAQ } from 'pages/global/promoSignup/data';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getMasterCountryList } from 'redux/master/master.request';
import { createSignUp } from 'redux/auth/auth.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearCountryList } from 'redux/master/master.slice';
import { setClearSendOtp, setClearVerifyOtp } from 'redux/notification/notification.slice';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { setSignUpPayload, setClearSignUpPayload } from 'redux/auth/auth.slice';
import { otpStatus } from 'redux/notification/notification.const';

import { pagesInfo } from 'utils/pagesInfo';
import { timeZone } from 'utils/dateTime.utils';
import { currencyRateConversion } from 'utils/generators.utils';
import { validateEmail, validateMobileNo } from 'utils/validation-functions';
import { useTitle } from 'hooks/useTitle';

const signupHeaderUrl = "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/promo-signup-page/promo-signup-limited-time-header.png"

const edulyteTermsOfService = "https://www.edulyte.com/terms-of-service/"
const edulytePrivacyPolicy = "https://www.edulyte.com/privacy-policy/"
const edulytePromoOffersTerms = "https://support.edulyte.com/portal/en/kb/articles/upfront-credit-promo"

const SignUpPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { modal, locals } = useAppState((state) => state.local)
    const { countryList, timeZoneCountryDetail } = useAppState(s => s.master)
    const { verifyOtp } = useAppState((s) => s.notification)
    const { session, signUp } = useAppState((s) => s.auth)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [title, setTitle] = useTitle()

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
    const [emailErrorText, setEmailErrorText] = useState('')
    const [mobileErrorText, setMobileErrorText] = useState('')
    const [myCountry, setMyCountry] = useState({
        masterCountryId: 0,
        country: "",
        countryDomain: "",
        isdCode: ""
    })

    const pageUrl = EDULYTE_APP_DOMAIN_URL + location.pathname

    const from = location.state?.from?.url || pagesInfo.DASHBOARD.pagePath;

    if (session?.isLoggedIn) {
        navigate(from, { replace: true });
        dispatcher(setModal({
            ...modal,
            [modalConst.CODE_VERIFICATION_MODAL.stateKey]: false
        }))
        dispatcher(setClearSignUpPayload())
    }

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.PROMO_SIGN_UP))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Sign Up Offer from Edulyte",
            description: "Limited Period Offer, get credit in your wallet and use it to book your lessons on Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        dispatcher(getMasterCountryList())

        return () => {
            // dispatcher(setClearSignUpPayload())
            dispatcher(setClearCountryList())
        }
    }, [])

    useEffect(() => {
        if (countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail && !signUp?.payload?.country) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country) => (
                    country.countryDomain === "US"
                ))
            }
            setMyCountry(myLocalCountryList[0])
        }
        if (countryList?.countryList && signUp?.payload?.country) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.masterCountryId === signUp?.payload?.country.masterCountryId
            ))
            setMyCountry(myLocalCountryList[0])
        }
    }, [countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail, signUp?.payload?.country])

    useEffect(() => {
        if (signUp?.payload?.email && !validateEmail(signUp?.payload?.email)) setEmailErrorText('Enter valid Email!');
        else setEmailErrorText('');

        if (signUp?.payload?.mobileNo && !validateMobileNo(signUp?.payload?.mobileNo)) setMobileErrorText('Enter valid MobileNo!');
        else setMobileErrorText('');

        const isDisabled = (emailErrorText.length === 0 || mobileErrorText.length === 0) && !(signUp?.payload.firstName && signUp?.payload.lastName && (signUp?.payload.email || signUp?.payload.mobileNo))
        setIsSubmitDisabled(isDisabled)

    }, [signUp?.payload])

    useEffect(() => {
        if (verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            createNewSignUpUser()
        }
    }, [verifyOtp?.verifyOtp])

    const getRecaptchaToken = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha('signup');
        if (token) {
            return token;
        }
    }, [executeRecaptcha])

    const createNewSignUpUser = async () => {
        const generatedToken = await getRecaptchaToken()

        if (generatedToken) {
            const body = {
                firstName: signUp?.payload?.firstName,
                lastName: signUp?.payload?.lastName,
                email: signUp?.payload?.email,
                promoCode: "EDULYTE10",
                rechaptcha_token: generatedToken,
                isPromotionActive: signUp?.payload?.isPromotionActive,
                timeZone: timeZone,
                source: pageUrl
            }
            if (signUp?.payload?.mobileNo) {
                body["mobileNo"] = String(signUp?.payload?.country?.isdCode + "-" + signUp?.payload?.mobileNo)
                body["isMobileNoVerified"] = signUp?.payload?.isMobileNoVerified
            }
            dispatcher(createSignUp(body))
        }
    }

    const handleOnChange = (event) => {
        dispatcher(setSignUpPayload({
            ...signUp?.payload,
            [event?.target.name]: event?.target?.value
        }))
    }

    const onSelectIsdCode = (option) => {
        const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option.value)
        setMyCountry(selectedCountry)
    }

    const onSubmit = (event) => {
        event.preventDefault()

        if (emailErrorText) {
            toast.warn("Invalid Email!")
            return;
        }

        if (mobileErrorText) {
            toast.warn("Invalid Mobile Number!")
            return;
        }

        dispatcher(setClearSendOtp())
        dispatcher(setClearVerifyOtp())
        dispatcher(setSignUpPayload({
            ...signUp?.payload,
            country: myCountry,
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.CODE_VERIFICATION_MODAL.stateKey]: true
        }))
    }

    return (
        <PageContentStyle>
            <div className={"h-full lg:h-screen bg-gradient-to-bl from-secondary-main/30 via-primary-main/30 to-primary-main/40"}>
                <div className={"mx-auto container"}>
                    <div className={"flex items-center justify-center lg:justify-start gap-3 px-3 py-3"}>
                        <span className={"font-brand font-bold text-primary-dark text-3xl"}>
                            {"E"}
                        </span>
                        <span className={"font-bodyPri font-semibold text-primary-dark text-2xl"}>
                            {"Edulyte"}
                        </span>
                    </div>
                    <div className={"w-full space-y-5 lg:space-y-8"}>
                        <div className={"w-full flex flex-col items-center justify-center gap-5 px-5"}>
                            <div className={"w-full font-bodyPri font-normal text-text-800 text-lg md:text-xl text-center"}>
                                {"Signup free, learn live on video calls"}
                            </div>
                            <div className={"w-full font-bodyPri font-medium text-black text-2xl lg:text-3xl text-center"}>
                                {`Get US$ 10 (~${currencyRateConversion(locals?.currencyCode, 1000)}) Credit Today`}
                            </div>
                            <div className={"w-full font-bodyPri font-normal text-text-800 text-lg md:text-xl text-center"}>
                                {"Contact any tutor from 50+ countries after filling in this form."}
                            </div>
                        </div>
                        <div className={"w-full grid grid-cols-2 gap-5 px-3 lg:px-12"}>
                            <div className={"col-span-full lg:col-span-1 flex flex-col items-center justify-center"}>
                                <div className={"col-start-1 col-span-full w-28 aspect-square overflow-hidden rounded-full"}>
                                    <img
                                        src={signupHeaderUrl}
                                        className={"w-full h-full object-cover"}
                                    />
                                </div>
                                <form
                                    className={"w-full md:w-[28rem] lg:w-[25rem] col-start-1 col-span-full lg:col-start-1 lg:col-span-3 flex flex-col items-center justify-center gap-5 px-5 md:px-0"}
                                    onSubmit={onSubmit}
                                >
                                    <div className={"w-full flex flex-col items-center justify-center"}>
                                        <div className={"font-bodyPri font-normal text-black text-xl"}>
                                            {"Sign up Offer"}
                                        </div>
                                        <div className={"w-full flex flex-col justify-center items-center gap-1 md:flex-row font-subHeading font-normal text-sm text-text-800"}>
                                            {'Already have an account?'}
                                            <Link to={pagesInfo.LOG_IN.pagePath}
                                                className={"font-bodyPri text-sm text-primary-main hover:text-secondary-dark cursor-pointer"}
                                            >
                                                {'Log in'}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className={"w-full flex flex-col items-start justify-start sm:flex-row sm:items-center gap-3 rounded-full"}>
                                        <div className={"w-full sm:w-1/2 flex items-center justify-start gap-3 px-5 py-3 bg-white rounded-full shadow"}>
                                            <IoMdPeople className={"text-text-700 text-2xl"} />
                                            <input
                                                type={"text"}
                                                name={"firstName"}
                                                className={"w-full focus:outline-none placeholder:font-bodyPri placeholder:text-text-500 placeholder:font-medium"}
                                                placeholder={"First Name *"}
                                                value={signUp?.payload?.firstName}
                                                onChange={handleOnChange}
                                                autoFocus={true}
                                                required={true}
                                            />
                                        </div>
                                        <div className={"w-full sm:w-1/2 flex items-center justify-start gap-2 px-5 py-3 bg-white rounded-full shadow"}>
                                            <IoMdPeople className={"text-text-700 text-2xl"} />
                                            <input
                                                type={"text"}
                                                name={'lastName'}
                                                className={"w-full focus:outline-none placeholder:font-bodyPri placeholder:text-text-500 placeholder:font-medium"}
                                                placeholder={"Last Name *"}
                                                value={signUp?.payload?.lastName}
                                                onChange={handleOnChange}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className={"w-full flex items-center justify-start gap-2 px-5 py-3 bg-white rounded-full shadow"}>
                                        <AiOutlineMail className={"text-text-700 text-2xl"} />
                                        <input
                                            type={"email"}
                                            name={'email'}
                                            className={"w-full focus:outline-none placeholder:font-bodyPri placeholder:text-text-500 placeholder:font-medium"}
                                            placeholder={"Your Email *"}
                                            value={signUp?.payload?.email}
                                            onChange={handleOnChange}
                                            required={true}
                                        />
                                        {(signUp?.payload?.email && (emailErrorText.length === 0)) && <BsCheckAll className={'text-lg text-green-500'} />}
                                        {(signUp?.payload?.email && (emailErrorText.length > 0)) && <MdCancel className={'text-lg text-red-800'} />}
                                    </div>
                                    <div className={"w-full flex items-center justify-start gap-2 px-5 py-3 bg-white rounded-full shadow"}>
                                        <FaMobileAlt className={"text-text-700 text-2xl"} />
                                        <div className={"flex items-center justify-start gap-2"}>
                                            {countryList?.isLoading &&
                                                <FaSpinner className={"text-lg text-primary-main animate-spin"} />
                                            }
                                            {countryList?.countryList &&
                                                <IsdCodeSelector
                                                    options={countryList?.countryList?.map((country) => ({
                                                        label: country?.isdCode,
                                                        value: country?.masterCountryId,
                                                        country: country
                                                    }))}
                                                    name={"country"}
                                                    value={myCountry?.masterCountryId}
                                                    onChange={(option) => onSelectIsdCode(option)}
                                                />
                                            }
                                            <input
                                                type={"number"}
                                                name={'mobileNo'}
                                                className={"w-full input-number-spin-none focus:outline-none placeholder:font-bodyPri placeholder:text-text-500 placeholder:font-medium"}
                                                placeholder={"Your Phone (optional)"}
                                                value={signUp?.payload?.mobileNo}
                                                onChange={handleOnChange}
                                            />
                                            {(signUp?.payload?.mobileNo && (mobileErrorText.length === 0)) && <BsCheckAll className='text-lg text-green-500' />}
                                            {(signUp?.payload?.mobileNo && (mobileErrorText.length > 0)) && <MdCancel className=' text-lg font-bodyPri text-red-800' />}
                                        </div>
                                    </div>
                                    <button
                                        disabled={isSubmitDisabled}
                                        type={"submit"}
                                        className={cn(
                                            "w-full px-3 py-3 flex items-center justify-center rounded-full border-2 border-secondary-main bg-secondary-main cursor-pointer",
                                            "font-bodyPri font-semibold text-text-50 text-lg hover:bg-secondary-dark hover:border-secondary-main",
                                            isSubmitDisabled && "border-secondary-light bg-secondary-light hover:bg-secondary-light hover:border-secondary-light cursor-not-allowed"
                                        )}>
                                        {signUp?.isLoading &&
                                            <ButtonLoader isLoading={signUp?.isLoading} />
                                        }
                                        {!signUp?.isLoading && "Get Credit"}
                                    </button>
                                    <pre className={"w-full flex items-center justify-center pt-2 md:pt-5 font-bodyPri font-normal text-sm text-text-800"}>
                                        {"I agree to "}
                                        <a
                                            href={edulytePromoOffersTerms}
                                            target={"_blank"}
                                            className={"text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer"}
                                        >
                                            {"offers terms"}
                                        </a>,
                                        <a
                                            href={edulyteTermsOfService}
                                            target={"_blank"}
                                            className={"text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer"}
                                        >
                                            {" terms of service"}
                                        </a>
                                        <span>{", and "}</span>
                                        <a
                                            href={edulytePrivacyPolicy}
                                            target={"_blank"}
                                            className={"text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer"}
                                        >
                                            {"privacy policy"}
                                        </a>
                                    </pre>
                                </form>
                            </div>
                            <div className={"col-start-1 col-span-full lg:col-span-1 mx-auto"}>
                                <div className={"h-[20rem] md:h-[28rem] lg:h-[36rem] aspect-square overflow-hidden"}>
                                    <img
                                        src={"https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/promo-signup-page/promo-signup-one.gif"}
                                        className={"w-full h-full object-cover"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* faq container */}
            <div className={"h-full lg:h-screen pt-32 bg-gradient-to-tl from-secondary-main/30 via-primary-main/30 to-primary-main/40"}>
                <div className={"mx-auto container px-3 md:px-8 py-5"}>
                    <Accordion
                        title={'Frequently Asked Questions?'}
                        dataFAQ={dataFAQ}
                    />
                </div>
            </div>
        </PageContentStyle>
    )
}
export default SignUpPage;