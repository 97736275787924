import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { ImCheckboxChecked } from 'react-icons/im';
import { ImCheckboxUnchecked } from 'react-icons/im';
import { FaSpinner } from 'react-icons/fa';
import { BsFillCheckCircleFill } from 'react-icons/bs';

import PasswordValidator from 'components/passwordValidator/PasswordValidator';

import { createPasswordReset } from "redux/auth/auth.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";

import { pagesInfo } from 'utils/pagesInfo';

const initialStatePassword = { newPassword: "", confirmPassword: "" }

const ResetPassword = () => {
    const { addPasswordReset, passwordReset } = useAppState((state) => state.auth)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const [password, setPassword] = useState(initialStatePassword)
    const [isShowPassword, setIsShowPassword] = useState(false)
    const [isNewPasswordValid, setIsNewPasswordValid] = useState(false)
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false)

    const from = location.state?.from?.url || pagesInfo.DASHBOARD.pagePath;

    useEffect(() => {
        setIsConfirmPasswordValid((isNewPasswordValid && password?.confirmPassword === password?.newPassword))
    }, [password])

    useEffect(() => {
        let interval
        if (addPasswordReset?.data) {
            let second = document.getElementById("second")
            let progress = document.getElementById("progress")
            let count = 0

            interval = setInterval(() => {
                second.innerText = (count < 3000) ? parseInt((4000 - count) / 1000) : 0
                progress.style.width = (count < 3000) ? `${parseInt(((count) * 100) / 3000)}%` : "100%"
                ++count

                if (count >= 3000) {
                    clearInterval(interval)
                    navigate(from, { replace: true })
                }
            }, 1)
        }

        return () => {
            clearInterval(interval)
        }
    })

    const submit = (event) => {
        event.preventDefault()

        if (!password?.newPassword || !isNewPasswordValid || !password?.confirmPassword || !isConfirmPasswordValid) {
            return;
        }

        dispatcher(createPasswordReset({
            token: passwordReset?.data?.token,
            newPassword: password?.confirmPassword
        }))
    }

    if (addPasswordReset?.data) {

        return (
            <div className={cn(
                "bg-white p-10 rounded-lg shadow-all w-96",
                "flex flex-col items-center gap-5 select-none"
            )}>
                <div className={"flex flex-col items-center gap-3"}>
                    <BsFillCheckCircleFill className={"text-8xl text-green-500"} />
                    <span className={"font-bodyPri font-semibold text-lg text-green-500 text-center"}>
                        Password Changed!
                    </span>
                    <p className={"font-bodyPri font-normal text-base text-text-700 text-center"}>
                        Your password has been changed successfully.
                    </p>
                </div>
                <hr className={"h-0.5 w-full rounded-full bg-divider-medium"} />
                <div className={"w-full flex flex-col items-center gap-3"}>
                    <span className={"font-bodyPri font-normal text-sm text-text-700 text-center"}>
                        Redirecting you to dashboard. In <span id={"second"}>3</span> seconds
                    </span>
                    <div className={"w-full rounded-md h-2 border border-text-500 flex items-start"}>
                        <span id={"progress"} className={`w-0 h-full bg-primary-dark`}></span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={cn(
            "bg-white p-10 rounded-lg shadow-all w-96",
            "flex flex-col items-start select-none"
        )}>
            <form onSubmit={submit} className={"w-full space-y-3"}>
                <PasswordValidator
                    inputPassword={password?.newPassword}
                    setIsNewPasswordValid={setIsNewPasswordValid}
                />
                <div className={cn(
                    "relative w-full group flex items-center justify-start"
                )}>
                    <input
                        id={"newPassword"}
                        name={"newPassword"}
                        type={isShowPassword ? "text" : "password"}
                        className={cn(
                            "relative overflow-hidden w-full px-5 py-2 focus:outline-none",
                            "transition ease-in-out duration-300",
                            "border-2 border-text-500 rounded-md",
                            "focus:border-primary-main",
                            password?.newPassword && "!border-primary-main",
                            password?.newPassword && !isNewPasswordValid && "!border-red-500",
                            password?.newPassword && isNewPasswordValid && "!border-green-500",
                        )}
                        value={password?.newPassword}
                        onChange={(event) => setPassword({ ...password, [event.target.name]: event.target.value })}
                    />
                    <label for={"newPassword"} className={cn(
                        "absolute z-1 left-3 whitespace-nowrap cursor-text",
                        "transition ease-in-out duration-300",
                        "font-bodyPri font-normal text-base text-text-500",
                        "group-focus-within:-translate-y-5 group-focus-within:-translate-x-4 group-focus-within:scale-75 group-focus-within:text-primary-main",
                        "group-focus-within:bg-white group-focus-within:px-1 group-focus-within:py-0 group-focus-within:cursor-default",
                        password?.newPassword && "-translate-y-5 -translate-x-4 scale-75 !text-primary-main",
                        password?.newPassword && "bg-white px-1 py-0 cursor-default",
                        password?.newPassword && !isNewPasswordValid && "group-focus-within:!text-red-500",
                        password?.newPassword && !isNewPasswordValid && "!text-red-500",
                        password?.newPassword && isNewPasswordValid && "group-focus-within:!text-green-500",
                        password?.newPassword && isNewPasswordValid && "!text-green-500",
                    )}>
                        New Password
                    </label>
                </div>
                <div className={cn(
                    "relative w-full group flex items-center justify-start"
                )}>
                    <input
                        id={"confirmPassword"}
                        name={"confirmPassword"}
                        type={isShowPassword ? "text" : "password"}
                        className={cn(
                            "relative overflow-hidden w-full px-5 py-2 focus:outline-none",
                            "transition ease-in-out duration-300",
                            "border-2 border-text-500 rounded-md",
                            "focus:border-primary-main",
                            password?.confirmPassword && "!border-primary-main",
                            password?.confirmPassword && !isConfirmPasswordValid && "!border-red-500",
                            password?.confirmPassword && isConfirmPasswordValid && "!border-green-500",
                        )}
                        value={password?.confirmPassword}
                        onChange={(event) => setPassword({ ...password, [event.target.name]: event.target.value })}
                    />
                    <label for={"confirmPassword"} className={cn(
                        "absolute z-1 left-3 whitespace-nowrap cursor-text",
                        "transition ease-in-out duration-300",
                        "font-bodyPri font-normal text-base text-text-500",
                        "group-focus-within:-translate-y-5 group-focus-within:-translate-x-4 group-focus-within:scale-75 group-focus-within:text-primary-main",
                        "group-focus-within:bg-white group-focus-within:px-1 group-focus-within:py-0 group-focus-within:cursor-default",
                        password?.confirmPassword && "-translate-y-5 -translate-x-4 scale-75 !text-primary-main",
                        password?.confirmPassword && "bg-white px-1 py-0 cursor-default",
                        password?.confirmPassword && !isConfirmPasswordValid && "group-focus-within:!text-red-500",
                        password?.confirmPassword && !isConfirmPasswordValid && "!text-red-500",
                        password?.confirmPassword && isConfirmPasswordValid && "group-focus-within:!text-green-500",
                        password?.confirmPassword && isConfirmPasswordValid && "!text-green-500",
                    )}>
                        Confirm Password
                    </label>
                </div>
                <div className={"w-fit flex items-center justify-start gap-2 cursor-pointer"} onClick={() => setIsShowPassword(!isShowPassword)}>
                    {isShowPassword && <ImCheckboxChecked id={"isShowPassword"} className={"text-primary-main"} />}
                    {!isShowPassword && <ImCheckboxUnchecked id={"isShowPassword"} className={"text-text-400"} />}
                    <label for={"isShowPassword"} className={"font-bodyPri font-normal text-base text-text-700"}>
                        Show Password
                    </label>
                </div>
                <button className={cn(
                    "w-full px-5 py-2 rounded-lg text-white bg-secondary-dark cursor-pointer",
                    "font-bodyPri font-medium text-base text-center",
                    "hover:opacity-70",
                    (!password?.newPassword || !isNewPasswordValid || !password?.confirmPassword || !isConfirmPasswordValid || addPasswordReset?.isLoading)
                    && "!bg-secondary-light hover:!bg-secondary-light hover:opacity-100 cursor-not-allowed"
                )}>
                    {"Submit"}
                </button>
                {addPasswordReset?.isLoading &&
                    <div className={"w-full flex items-center justify-center gap-1"}>
                        <span className={"font-bodyPri font-medium text-base text-text-700"}>Loading...</span>
                        <FaSpinner className={"text-md text-primary-dark animate-spin"} />
                    </div>
                }
                {addPasswordReset?.message &&
                    <div className={"w-full font-bodyPri font-normal text-sm text-red-500 text-center"}>
                        {addPasswordReset?.message}
                    </div>
                }
            </form>
        </div>
    )
}

export default ResetPassword