export const PAYOUT_INITIAL_STATE = {
    userPayoutList: {
        isLoading: false,
        userPayoutList: null,
        errorMsg: null
    },
    userPayoutDetail: {
        isLoading: false,
        userPayoutDetail: null,
        errorMsg: null
    },
    addUserPayoutDetail: {
        isLoading: false,
        errorMsg: null
    }
}