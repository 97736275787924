import { useState } from 'react';

import ButtonContainer from '../ButtonContainer';
import SetPriceDetailsSection from './components/SetPriceDetailsContainer';

const SetPrice = ({ stepCount, setStepCount }) => {
    const [clickedItem, setClickedItem] = useState('')

    return (
        <div className={"space-y-5"}>
            <ButtonContainer stepCount={stepCount} setStepCount={setStepCount} />
            <SetPriceDetailsSection clickedItem={clickedItem} setClickedItem={setClickedItem} />
        </div>
    )
}

export default SetPrice;