import tw, { styled } from 'twin.macro';

export const Container = tw.div`
    flex items-center w-full h-auto rounded-2xl overflow-hidden cursor-pointer bg-background-medium
`;

export const Completed = styled.span`
    ${({ pctValue }) => `
        width: ${pctValue}%;
    `}
    ${tw`
        bg-green-500 text-center text-text-50 font-bodyPri font-normal h-full whitespace-nowrap
    `}
`;

export const InCompleted = styled.span`
    ${({ pctValue }) => `
        width: ${pctValue}%;
    `}
    ${tw`
        bg-background-medium text-center text-text-700 font-bodyPri font-normal h-full whitespace-nowrap
    `}
`;