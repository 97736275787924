import { memo } from 'react';

import ToolTipView from 'components/tooltipView';

const RadioInput = memo(({
    isInputDisabled = false,
    tooltipContent = "",
    isInputChecked = false,
    onHandleClickInput,
    props,
}) => {
    return (
        <ToolTipView
            disabled={isInputDisabled}
            content={tooltipContent}
        >
            <div className={"cursor-pointer"}>
                <input
                    type={"radio"}
                    className={"w-4 h-4 border border-divider-medium rounded-full cursor-pointer disabled:cursor-not-allowed"}
                    checked={isInputChecked}
                    onClick={onHandleClickInput}
                    disabled={isInputDisabled}
                    {...props}
                />
            </div>
        </ToolTipView>
    )
})

export default RadioInput;