import { motion } from "framer-motion";

import { FaRegAddressCard, FaList, FaSpinner } from "react-icons/fa";
import { TiContacts } from "react-icons/ti";

import ToolTipView from "components/tooltipView";
import { OptionSelector } from "components/common-components/Select";

const CrmLayoutHeader = ({
    isLoading = false,
    isShowHeaderActionBtn = false,
    isTileViewActive = false,
    headerTitle = "My Contacts",
    btnTitle = "Create Contact",
    actionBtnList = [],
    onHandleActionButtons,
    onHandleToggleView,
    onHandleCreateNewContact
}) => {

  return (
      <div className="flex justify-between w-full bg-white p-3 rounded">
          <div className={"flex items-center justify-start gap-5"}>
              <div className="flex items-center gap-3 text-2xl">
                  <TiContacts />
                  <h1 className="hidden md:block font-medium font-heading">
                    {headerTitle}
                    </h1>
              </div>
              {(!!isShowHeaderActionBtn && !!actionBtnList?.length) &&
                  <>
                      <div className={"flex flex-1 md:hidden items-center gap-5"}>
                          <OptionSelector
                              className={"flex-1"}
                              options={actionBtnList}
                              value={""}
                              onChange={onHandleActionButtons}
                          />
                      </div>
                      <div className={"hidden md:flex items-center gap-5"}>
                          {actionBtnList?.map((btnItem, index) => (
                              <motion.button
                                  key={index}
                                  whileHover={{ scale: 1.05 }}
                                  whileTap={{ scale: 0.95 }}
                                  transition={{ duration: 0.3 }}
                                  className={btnItem?.className}
                                  onClick={() => onHandleActionButtons(btnItem)}
                              >
                                  {btnItem?.label}
                              </motion.button>
                          ))}
                      </div>
                      {isLoading &&
                          <div className={"flex items-center gap-1.5"}>
                              <FaSpinner className={"animate-spin text-primary-main"} />
                              <span className={"font-bodyPri font-normal text-primary-main text-base"}>
                                  {"Please wait ..."}
                              </span>
                          </div>
                      }

                  </>
              }
          </div>
          <div className="flex items-center gap-3 font-bodyPri">
              <ToolTipView content={!isTileViewActive ? "Card View" : "List View"}>
                  <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.8 }}
                      className="flex items-center gap-2 h-10 py-2 px-3 text-primary-dark ease-in-out duration-200 bg-primary-light rounded"
                      onClick={onHandleToggleView}
                  >
                      {!isTileViewActive ? <FaRegAddressCard size={20} /> : <FaList />}
                  </motion.button>
              </ToolTipView>
              <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ duration: 0.3 }}
                  className="py-2 px-2 md:px-5 text-white text-sm md:text-base ease-in-out duration-200 bg-primary-dark hover:bg-secondary-dark rounded"
                  onClick={onHandleCreateNewContact}
              >
                  {btnTitle}
              </motion.button>
          </div>
      </div>
  )
}

export default CrmLayoutHeader