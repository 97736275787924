import { useAppState } from 'hooks/useStore';
import { pagesInfo } from 'utils/pagesInfo';

import {
    RightNavLinksContainer,
    NavLink,
    TeachWithUsLink,
    QuestionIcon,
    AuthSection,
    SignUpIcon,
    LogInIcon,
} from './NavBarStyle';
import RightLogedInNavLinks from './RightLogedInNavLinks';

const RightNavLinks = ({ isScrolled }) => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const pageName = currentPageInfo.pageName;
    const { session } = useAppState((state) => state.auth);

    const handleKnowledgeBase = () => {
        window.open("https://support.edulyte.com/portal/en/kb", "_blank")
    }

    if (session?.isLoggedIn) {
        return (
            <RightNavLinksContainer>
                <RightLogedInNavLinks isScrolled={isScrolled} />
            </RightNavLinksContainer>
        )
    }

    return (
        <RightNavLinksContainer>
            {pageName === pagesInfo.HOME.pageName &&
                <>
                    <NavLink to={pagesInfo.SIGN_UP.pagePath} isScrolled={isScrolled}>{'Sign up'}</NavLink>
                    <NavLink to={pagesInfo.LOG_IN.pagePath} isScrolled={isScrolled}>{'Log in'}</NavLink>
                    <TeachWithUsLink to={pagesInfo.TEACH_WITH_US.pagePath} isScrolled={isScrolled}>
                        {'Teach with us'}
                    </TeachWithUsLink>
                </>
            }
            {pageName !== pagesInfo.HOME.pageName &&
                <QuestionIcon isScrolled={isScrolled} onClick={handleKnowledgeBase} />
            }
            {(pageName !== pagesInfo.HOME.pageName && pageName !== pagesInfo.SIGN_UP.pageName) &&
                <AuthSection to={pagesInfo.SIGN_UP.pagePath} isScrolled={isScrolled}>
                    <SignUpIcon />
                    {'Sign up'}
                </AuthSection>
            }
            {(pageName !== pagesInfo.HOME.pageName && pageName !== pagesInfo.LOG_IN.pageName) &&
                <AuthSection to={pagesInfo.LOG_IN.pagePath} isScrolled={isScrolled}>
                    <LogInIcon />
                    {'Log in'}
                </AuthSection>
            }
        </RightNavLinksContainer>
    )
}

export default RightNavLinks;