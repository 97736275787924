import { createSlice } from "@reduxjs/toolkit";
import { AVAILABILITY_INITIAL_STATE } from "redux/availability/availability.initialState";


const auth = createSlice({
    name: "availability",
    initialState: AVAILABILITY_INITIAL_STATE,
    reducers: {
        // reducers for availability detail
        setAvailabilityDetail: (state, { payload }) => {
            state.availabilityDetail.availabilityDetail = payload
            state.availabilityDetail.errorMsg = AVAILABILITY_INITIAL_STATE.availabilityDetail.errorMsg
        },
        setClearAvailabilityDetail: (state) => {
            state.availabilityDetail = AVAILABILITY_INITIAL_STATE.availabilityDetail
        },

        //reducers for add availability detail
        setAddAvailabilityDetailLoading: (state, { payload }) => {
            state.addAvailabilityDetail.isLoading = payload
        },
        setAddAvailabilityDetail: (state, { payload }) => {
            state.addAvailabilityDetail.addAvailabilityDetail = payload
            state.addAvailabilityDetail.errorMsg = AVAILABILITY_INITIAL_STATE.addAvailabilityDetail.errorMsg
        },
        setAddAvailabilityDetailErrorMsg: (state, { payload }) => {
            state.addAvailabilityDetail.errorMsg = payload
            state.addAvailabilityDetail.addAvailabilityDetail = AVAILABILITY_INITIAL_STATE.addAvailabilityDetail.addAvailabilityDetail
        },
        setClearAddAvailabilityDetail: (state, { payload }) => {
            state.addAvailabilityDetail = AVAILABILITY_INITIAL_STATE.addAvailabilityDetail
        },

        // reducers for add availability detail payload
        setAddAvailabilityDetailPayload: (state, { payload }) => {
            state.addAvailabilityDetail.addAvailabilityDetailPayload = payload
        },
        setClearAddAvailabilityDetailPayload: (state) => {
            state.addAvailabilityDetail.addAvailabilityDetailPayload = AVAILABILITY_INITIAL_STATE.addAvailabilityDetail.addAvailabilityDetailPayload
        },

        // reducers for off day list
        setOffDayListLoading: (state, { payload }) => {
            state.offDayList.isLoading = payload
        },
        setOffDayList: (state, { payload }) => {
            state.offDayList.offDayList = payload
            state.offDayList.errorMsg = AVAILABILITY_INITIAL_STATE.offDayList.errorMsg
        },
        setOffDayListErrorMsg: (state, { payload }) => {
            state.offDayList.errorMsg = payload
            state.offDayList.offDayList = AVAILABILITY_INITIAL_STATE.offDayList.offDayList
        },
        setClearOffDayList: (state) => {
            state.offDayList = AVAILABILITY_INITIAL_STATE.offDayList
        },

        // reducers for off day detail
        setOffDayDetailLoading: (state, { payload }) => {
            state.offDayDetail.isLoading = payload
        },
        setOffDayDetail: (state, { payload }) => {
            state.offDayDetail.offDayDetail = payload
            state.offDayDetail.errorMsg = AVAILABILITY_INITIAL_STATE.offDayDetail.errorMsg
        },
        setOffDayDetailErrorMsg: (state, { payload }) => {
            state.offDayDetail.errorMsg = payload
            state.offDayDetail.offDayDetail = AVAILABILITY_INITIAL_STATE.offDayDetail.offDayDetail
        },
        setClearOffDayDetail: (state) => {
            state.offDayDetail = AVAILABILITY_INITIAL_STATE.offDayDetail
        },

        // reducers for update off day detail
        setAddOffDayDetailLoading: (state, { payload }) => {
            state.addOffDayDetail.isLoading = payload
        },
        setAddOffDayDetail: (state, { payload }) => {
            state.addOffDayDetail.addOffDayDetail = payload
            state.addOffDayDetail.errorMsg = AVAILABILITY_INITIAL_STATE.addOffDayDetail.errorMsg
        },
        setAddOffDayDetailErrorMsg: (state, { payload }) => {
            state.addOffDayDetail.errorMsg = payload
            state.addOffDayDetail.addOffDayDetail = AVAILABILITY_INITIAL_STATE.addOffDayDetail.addOffDayDetail
        },
        setClearAddOffDayDetail: (state, { payload }) => {
            state.addOffDayDetail = AVAILABILITY_INITIAL_STATE.addOffDayDetail
        },

        // reducers for add off day detail payload
        setAddOffDayDetailPayload: (state, { payload }) => {
            state.addOffDayDetail.addOffDayDetailPayload = payload
        },
        setClearAddOffDayDetailPayload: (state) => {
            state.addOffDayDetail.addOffDayDetailPayload = AVAILABILITY_INITIAL_STATE.addOffDayDetail.addOffDayDetailPayload
        },

        // reducers for available Slot detail
        setAvailableSlotDetailLoading: (state, { payload }) => {
            state.availableSlotDetail.isLoading = payload
        },
        setAvailableSlotDetailData: (state, { payload }) => {
            state.availableSlotDetail.data = payload
            state.availableSlotDetail.message = AVAILABILITY_INITIAL_STATE.availableSlotDetail.message
        },
        setAvailableSlotDetailMessage: (state, { payload }) => {
            state.availableSlotDetail.message = payload
            state.availableSlotDetail.data = AVAILABILITY_INITIAL_STATE.availableSlotDetail.data
        },
        resetAvailableSlotDetail: (state) => {
            state.availableSlotDetail = AVAILABILITY_INITIAL_STATE.availableSlotDetail
        },

        // new availability slice
        setUserAvailabilityListLoading: (state, { payload }) => {
            state.userAvailabilityList.isLoading = payload
        },
        setUserAvailabilityListData: (state, { payload }) => {
            state.userAvailabilityList.data = payload
            state.userAvailabilityList.message = AVAILABILITY_INITIAL_STATE.userAvailabilityList.message
        },
        setUserAvailabilityListMessage: (state, { payload }) => {
            state.userAvailabilityList.message = payload
            state.userAvailabilityList.data = AVAILABILITY_INITIAL_STATE.userAvailabilityList.data
        },
        resetUserAvailabilityList: (state, { payload }) => {
            state.userAvailabilityList = AVAILABILITY_INITIAL_STATE.userAvailabilityList
        },

        setAddUserAvailabilityDetailLoading: (state, { payload }) => {
            state.addUserAvailabilityDetail.isLoading = payload
        },
        setAddUserAvailabilityDetailData: (state, { payload }) => {
            state.addUserAvailabilityDetail.data = payload
            state.addUserAvailabilityDetail.message = AVAILABILITY_INITIAL_STATE.addUserAvailabilityDetail.message
        },
        setAddUserAvailabilityDetailMessage: (state, { payload }) => {
            state.addUserAvailabilityDetail.message = payload
            state.addUserAvailabilityDetail.data = AVAILABILITY_INITIAL_STATE.addUserAvailabilityDetail.data
        },
        resetAddUserAvailabilityDetail: (state, { payload }) => {
            state.addUserAvailabilityDetail = AVAILABILITY_INITIAL_STATE.addUserAvailabilityDetail
        },

        // reducers for add user availability detail payload
        setAddUserAvailabilityDetailPayload: (state, { payload }) => {
            state.addUserAvailabilityDetail.payload = payload
        },
        resetAddUserAvailabilityDetailPayload: (state) => {
            state.addUserAvailabilityDetail.payload = AVAILABILITY_INITIAL_STATE.addUserAvailabilityDetail.payload
        },

        setUserAvailabilityDetailLoading: (state, { payload }) => {
            state.userAvailabilityDetail.isLoading = payload
        },
        setUserAvailabilityDetailData: (state, { payload }) => {
            state.userAvailabilityDetail.data = payload
            state.userAvailabilityDetail.message = AVAILABILITY_INITIAL_STATE.userAvailabilityDetail.message
        },
        setUserAvailabilityDetailMessage: (state, { payload }) => {
            state.userAvailabilityDetail.message = payload
            state.userAvailabilityDetail.data = AVAILABILITY_INITIAL_STATE.userAvailabilityDetail.data
        },
        resetUserAvailabilityDetail: (state, { payload }) => {
            state.userAvailabilityDetail = AVAILABILITY_INITIAL_STATE.userAvailabilityDetail
        },

        setModifyUserAvailabilityDetailLoading: (state, { payload }) => {
            state.modifyUserAvailabilityDetail.isLoading = payload
        },
        setModifyUserAvailabilityDetailData: (state, { payload }) => {
            state.modifyUserAvailabilityDetail.data = payload
            state.modifyUserAvailabilityDetail.message = AVAILABILITY_INITIAL_STATE.modifyUserAvailabilityDetail.message
        },
        setModifyUserAvailabilityDetailMessage: (state, { payload }) => {
            state.modifyUserAvailabilityDetail.message = payload
            state.modifyUserAvailabilityDetail.data = AVAILABILITY_INITIAL_STATE.modifyUserAvailabilityDetail.data
        },
        resetModifyUserAvailabilityDetail: (state, { payload }) => {
            state.modifyUserAvailabilityDetail = AVAILABILITY_INITIAL_STATE.modifyUserAvailabilityDetail
        },

        // reducers for modify user availability detail payload
        setModifyUserAvailabilityDetailPayload: (state, { payload }) => {
            state.modifyUserAvailabilityDetail.payload = payload
        },
        resetModifyUserAvailabilityDetailPayload: (state) => {
            state.modifyUserAvailabilityDetail.payload = AVAILABILITY_INITIAL_STATE.modifyUserAvailabilityDetail.payload
        },

        setDestroyUserAvailabilityDetailLoading: (state, { payload }) => {
            state.destroyUserAvailabilityDetail.isLoading = payload
        },
        setDestroyUserAvailabilityDetailData: (state, { payload }) => {
            state.destroyUserAvailabilityDetail.data = payload
            state.destroyUserAvailabilityDetail.message = AVAILABILITY_INITIAL_STATE.destroyUserAvailabilityDetail.message
        },
        setDestroyUserAvailabilityDetailMessage: (state, { payload }) => {
            state.destroyUserAvailabilityDetail.message = payload
            state.destroyUserAvailabilityDetail.data = AVAILABILITY_INITIAL_STATE.destroyUserAvailabilityDetail.data
        },
        resetDestroyUserAvailabilityDetail: (state, { payload }) => {
            state.destroyUserAvailabilityDetail = AVAILABILITY_INITIAL_STATE.destroyUserAvailabilityDetail
        },
    }
})

export const {
    setAvailabilityDetail,
    setClearAvailabilityDetail,

    setAddAvailabilityDetailLoading,
    setAddAvailabilityDetail,
    setAddAvailabilityDetailErrorMsg,
    setClearAddAvailabilityDetail,

    setAddAvailabilityDetailPayload,
    setClearAddAvailabilityDetailPayload,

    setOffDayListLoading,
    setOffDayList,
    setOffDayListErrorMsg,
    setClearOffDayList,

    setOffDayDetailLoading,
    setOffDayDetail,
    setOffDayDetailErrorMsg,
    setClearOffDayDetail,

    setAddOffDayDetailLoading,
    setAddOffDayDetail,
    setAddOffDayDetailErrorMsg,
    setClearAddOffDayDetail,

    setAddOffDayDetailPayload,
    setClearAddOffDayDetailPayload,

    setAvailableSlotDetailLoading,
    setAvailableSlotDetailData,
    setAvailableSlotDetailMessage,
    resetAvailableSlotDetail,

    setUserAvailabilityListLoading,
    setUserAvailabilityListData,
    setUserAvailabilityListMessage,
    resetUserAvailabilityList,

    setAddUserAvailabilityDetailLoading,
    setAddUserAvailabilityDetailData,
    setAddUserAvailabilityDetailMessage,
    resetAddUserAvailabilityDetail,

    setAddUserAvailabilityDetailPayload,
    resetAddUserAvailabilityDetailPayload,

    setUserAvailabilityDetailLoading,
    setUserAvailabilityDetailData,
    setUserAvailabilityDetailMessage,
    resetUserAvailabilityDetail,

    setModifyUserAvailabilityDetailLoading,
    setModifyUserAvailabilityDetailData,
    setModifyUserAvailabilityDetailMessage,
    resetModifyUserAvailabilityDetail,

    setModifyUserAvailabilityDetailPayload,
    resetModifyUserAvailabilityDetailPayload,

    setDestroyUserAvailabilityDetailLoading,
    setDestroyUserAvailabilityDetailData,
    setDestroyUserAvailabilityDetailMessage,
    resetDestroyUserAvailabilityDetail,
} = auth.actions

export default auth.reducer