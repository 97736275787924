import { motion } from 'framer-motion'
import { outsideIcons, socialKey } from './data'
import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { addNewLink } from 'redux/linkTree/linkTree.request';
import ToolTipView from 'components/tooltipView';
import { RotatingLines } from 'react-loader-spinner';

const AddLink = () => {
    const { createLinkTree, updateLink, addLink, deleteLink, reorderLink } = useAppState((state) => state.linkTree)
    const { socialList } = useAppState((s) => s.master)

    const dispatcher = useAppDispatcher()

    const reorder = (social) => {
        const order = socialKey?.indexOf(social?.key)
        return { ...social, order: order < 0 ? socialList?.data?.length - 1 : order }
    }
    const sortSocial = (social1, social2) => social1.order >= social2.order ? 1 : -1

    const handleAddNewLink = (item) => {
        dispatcher(addNewLink(
            {
                link_tree_id: createLinkTree?.data?.result?.id,
                social_key: item.key,
                title: item.name,
            }
        ))
    }

    const SocialItems = () => {
        return (
            <div className='grid grid-cols-3 gap-5 p-5'>
                {socialList?.data?.filter(item => !outsideIcons.includes(item.key)).map(reorder).sort(sortSocial)?.map((item) => (
                    <motion.button
                        whileHover={{ scale: 1.09 }}
                        key={item?.id}
                        onClick={() => handleAddNewLink(item)}
                        disabled={addLink?.isLoading}
                        className='w-20 h-20 p-2 gap-0.5 flex flex-col justify-center items-center bg-white hover:bg-background-light rounded-lg hover:shadow cursor-pointer'
                    >
                        <img
                            className='w-10 h-10 aspect-square'
                            src={item?.picture_url}
                            alt={item?.name}
                        />
                        <p className='whitespace-nowrap text-sm'>{item?.name}</p>
                    </motion.button>
                ))}
            </div>
        )
    }

    return (
        <div className='flex flex-col gap-3 md:gap-0 md:flex-row w-full justify-between'>
            <div className='w-fit grid grid-cols-3 lg:grid-cols-7 md:grid-cols-4 gap-5'>
                <>
                    {socialList?.data?.filter(item => outsideIcons.includes(item.key)).map(reorder).sort(sortSocial)?.map((item) => (
                        <motion.button
                            whileHover={{ scale: 1.09 }}
                            key={item?.id}
                            disabled={addLink?.isLoading}
                            onClick={() => handleAddNewLink(item)}
                            className='w-20 h-20 p-2 gap-0.5 flex flex-col justify-center items-center bg-white hover:bg-background-light rounded-lg hover:shadow cursor-pointer'
                        >
                            <img
                                className='w-10 h-10 aspect-square'
                                src={item?.picture_url}
                                alt={item?.name}
                            />
                            <p className='whitespace-nowrap text-sm'>{item?.name}</p>
                        </motion.button>
                    ))}
                    <ToolTipView
                        content={<SocialItems />}
                        allowHTML={true}
                        theme={"light"}
                        interactive={true}
                        placement='bottom-end'
                        hideOnClick={false}
                    >
                        <motion.div
                            whileHover={{ scale: 1.09 }}
                            className='w-20 h-20 p-2 gap-0.5 flex flex-col justify-center items-center bg-white hover:bg-background-light rounded-lg hover:shadow cursor-pointer'
                        >
                            <img
                                className='w-10 h-10 aspect-square'
                                src={"https://img.icons8.com/?size=600&id=44028&format=png"}
                                alt={'other'}
                            />
                            <p className='whitespace-nowrap text-sm'>{'More'}</p>
                        </motion.div>
                    </ToolTipView>
                    <div className='flex justify-center items-center md:hidden'>
                        <RotatingLines
                            visible={updateLink?.isLoading || addLink?.isLoading || deleteLink?.isLoading || reorderLink?.isLoading}
                            height="25"
                            width="25"
                            color="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                        />
                    </div>
                </>
            </div>
            <div className='hidden md:block'>
                <RotatingLines
                    visible={updateLink?.isLoading || addLink?.isLoading || deleteLink?.isLoading || reorderLink?.isLoading}
                    height="25"
                    width="25"
                    color="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                />
            </div>
        </div>
    )
}


export default AddLink
