const MOCK_NOTIFICATION_DATA: TNotificationSettings[] = [
  {
    id: "1",
    title: "Push Notification",
    description:
      "Receive notification on your iPhone, iPad, Android, and Windows Phone",
    settings: [
      {
        title: "Enable Push Notifications",
        code: "push_notifications",
        description:
          "Download our Mobile application to receive push notifications",
        status: true,
      },
    ],
  },
  {
    id: "2",
    title: "Email Notifications",
    description: "Receive email notifications when you have new messages",
    settings: [
      {
        title: "Lesson Updates",
        code: "lesson_updates",
        description: "Lesson Scheduling and Changes",
        status: false,
      },
      {
        title: "Account Updates",
        code: "account_updates",
        description: "Notification related to account settings",
        status: true,
      },
      {
        title: "Messages",
        code: "message_updates",
        description: "Messages from other users",
        status: true,
      },
      {
        title: "Offers and promotions",
        code: "offers_and_promotions",
        description: "Offers and promotions",
        status: true,
      }
    ],
  },
];

export const MockMySettingsPageState: MySettingsPageState = {
  payout: {
    bankTransfer: null,
    payoutUpi: null,
    _meta: {
      editBankTransfer: false,
      editPayoutUpi: false,
      default: "upi",
    },
  },
  notificationSettings: MOCK_NOTIFICATION_DATA,
  billingSettings: {
    GSTStatus: false,
    addresses: [],
    bussinessType: "Freelancer",
    myCountry: {
      masterCountryId: 0,
      country: "",
      countryDomain: "",
      isdCode: ""
    }
  },
};

export type MySettingsPageState = {
  payout: {
    bankTransfer: PayoutBankTransfer | null;
    payoutUpi: PayoutUPI | null;
    _meta: {
      editBankTransfer: boolean;
      editPayoutUpi: boolean;
      default: "upi" | "bank";
    };
  };
  notificationSettings: TNotificationSettings[];
  billingSettings: BillingSetting;
};

export type PayoutBankTransfer = {
  bankName: string;
  bankAccountNumber: string;
  IFSCCode: string;
};

export type PayoutUPI = {
  upiId: string;
};

type TNotificationSettings = {
  id: string;
  title: string;
  description: string;
  settings: {
    title: string;
    description: string;
    code: string;
    status: boolean;
  }[];
};

export type Address = {
  _id: number;
  billingAddress: string;
  shippingAddress: string;
};

export type BillingSetting = {
  bussinessType: string;
  myCountry: {
    masterCountryId: number,
    country: string,
    countryDomain: string,
    isdCode: string
  };
  GSTStatus: boolean;
  GSTNumber?: string;
  defaultAddress?: number;
  addresses: Address[];
};
