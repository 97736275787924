import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "./order.const";

class OrderService {

    static orderService = new OrderService()

    getUserOrderItemList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_ORDER_ITEM_LIST, params),
            { params: query }
        )

        return response;
    }

    getUserOrderItemDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_ORDER_ITEM_DETAIL, params)
        )

        return response;
    }
    confirmationOrderDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.CONFIRMATION_ORDER_DETAIL, params)
        );
        return response;
    };
}

export default OrderService.orderService;