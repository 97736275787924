import { useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import PageTabs from 'pages/auth/editDownload/commonComponents/PageTabs';

import { downloadParticipantsNavigationOptions } from '../data';

import { useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const ParticipantsPage = () => {
    const ownerUserDownloadDetail  = useAppState((state) => state.downloads.ownerUserDownloadDetail)

    const navigate = useNavigate()

    const onHandleSelectTab = useCallback((activeTab) => {
        navigate(`${pagesInfo?.EDIT_DOWNLOAD?.pagePath}/${ownerUserDownloadDetail?.data?.id}/edit/${downloadParticipantsNavigationOptions[activeTab?.value?.toUpperCase()]?.to}`)
    }, [ownerUserDownloadDetail?.data?.id])

    return (
        <div className={"flex flex-col space-y-3"}>
            <PageTabs
                tabs={Object.values(downloadParticipantsNavigationOptions)}
                onHandleTabs={onHandleSelectTab}
            />
            <div className="w-full rounded-lg overflow-hidden p-5 bg-white">
                <Outlet />
            </div>
        </div>
    )
}

export default ParticipantsPage;