import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AiOutlineCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai';

import ReactDateRangePicker from 'components/datePickers/ReactDateRangePicker';
import FilterBox from 'components/pageFilter/FilterBox';

import { searchParamsInfo } from '../lmsQuestion.data';

import { DEFAULT_LMS_QUESTION_PAGE } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.const';

import { cn } from 'utils/cn.utils';
import { dayjs, timeZone } from "utils/dateTime.utils";

const initialDatePayload = {
    isDatePickerActive: false,
    startDate: dayjs(dayjs().subtract(29, 'days'))?.tz(timeZone)?.format("MM/DD/YYYY"),
    endDate: dayjs()?.tz(timeZone)?.format("MM/DD/YYYY"),
    message: ""
}
const QuestionDateFilter = memo(({ filterItem, activeFilter, setActiveFilter }) => {

    const navigate = useNavigate()
    const location = useLocation()
    const dateRangeRef = useRef(null)

    const [datePayload, setDatePayload] = useState({ ...initialDatePayload })

    let searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        if (!!searchQueryParams?.get(searchParamsInfo.minCreatedAt.key) && !!searchQueryParams?.get(searchParamsInfo.maxCreatedAt.key)) {
            setDatePayload({
                ...datePayload,
                isDatePickerActive: false,
                startDate: searchQueryParams?.get(searchParamsInfo.minCreatedAt.key),
                endDate: searchQueryParams?.get(searchParamsInfo.maxCreatedAt.key),
                message: ""
            })
        } else {
            setDatePayload(initialDatePayload)
        }
    }, [searchQueryParams?.get(searchParamsInfo.minCreatedAt.key), searchQueryParams?.get(searchParamsInfo.maxCreatedAt.key)])

    const onHandleSelectedDate = (dateObject) => {
        setDatePayload({
            ...datePayload,
            isDatePickerActive: false,
            startDate: dayjs(dateObject?.startDate).tz(timeZone).format("MM/DD/YYYY"),
            endDate: dayjs(dateObject?.endDate).tz(timeZone).format("MM/DD/YYYY")
        })
    }

    const onHandleMouseLeave = () => {
        if (datePayload?.isDatePickerActive) return;

        if (!searchQueryParams?.get(searchParamsInfo.minCreatedAt.key) || !searchQueryParams?.get(searchParamsInfo.maxCreatedAt.key)) {
            setDatePayload(initialDatePayload)
        }
        setActiveFilter(null)
    }

    const onHandleApplyFilter = useCallback(() => {
        if (!datePayload?.startDate || !datePayload?.endDate) {
            setDatePayload({
                ...datePayload,
                message: "Please select date range"
            })
            return;
        }
        setDatePayload({
            ...datePayload,
            message: ""
        })
        searchQueryParams.delete(searchParamsInfo.minCreatedAt.key);
        searchQueryParams.delete(searchParamsInfo.maxCreatedAt.key);
        searchQueryParams.delete(searchParamsInfo.page.key);
        searchQueryParams.set(searchParamsInfo.page.key, DEFAULT_LMS_QUESTION_PAGE);
        searchQueryParams.set(searchParamsInfo.minCreatedAt.key, dayjs(datePayload?.startDate).format("MM-DD-YYYY"))
        searchQueryParams.set(searchParamsInfo.maxCreatedAt.key, dayjs(datePayload?.endDate).format("MM-DD-YYYY"))
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
        setActiveFilter(null)
    }, [datePayload, searchQueryParams])

    const onHandleClearFilter = useCallback((e) => {
        e.stopPropagation()
        setDatePayload(initialDatePayload)

        searchQueryParams.delete(searchParamsInfo.minCreatedAt.key);
        searchQueryParams.delete(searchParamsInfo.maxCreatedAt.key);
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
        setActiveFilter(null)
    }, [searchQueryParams])

    return (
        <FilterBox
            boxTitle={filterItem?.label}
            boxIcon={(!!searchQueryParams?.get(searchParamsInfo.minCreatedAt.key) && !!searchQueryParams?.get(searchParamsInfo.maxCreatedAt.key))
                ? <button onClick={onHandleClearFilter}>
                    <AiOutlineCloseCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
                : <button>
                    <AiOutlinePlusCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
            }
            isActive={activeFilter === filterItem.key}
            onHandleToggleBox={() => setActiveFilter(filterItem.key)}
            onHandleMouseLeave={onHandleMouseLeave}
            BoxItemContent={() => {
                return (!!searchQueryParams?.get(searchParamsInfo.minCreatedAt.key) && !!searchQueryParams?.get(searchParamsInfo.maxCreatedAt.key)) && (
                    <>
                        <div className={"h-[20px] border border-divider-lightDark"} />
                        <span className={"font-bodyPri font-normal text-primary-dark text-base"}>
                            {`${dayjs(searchQueryParams?.get(searchParamsInfo.minCreatedAt.key))?.tz(timeZone)?.format("MM/DD/YYYY")}-${dayjs(searchQueryParams?.get(searchParamsInfo.maxCreatedAt.key))?.format("MM/DD/YYYY")}`}
                        </span>
                    </>
                )
            }}
            customBoxClassName={(!!searchQueryParams?.get(searchParamsInfo.minCreatedAt.key) && !!searchQueryParams?.get(searchParamsInfo.maxCreatedAt.key)) && "!bg-text-200 !border-solid"}
            activeContainerClassName={""}
        >
            <div className={"w-60 md:w-72 p-3 flex flex-col gap-5"}>
                <span className={"font-bodyPri font-bold text-text-900 text-base tracking-wide"}>
                    {"Filter By Date"}
                </span>
                <ReactDateRangePicker
                    dateRangeRef={dateRangeRef}
                    selectedDateRange={{
                        startDate: datePayload?.startDate,
                        endDate: datePayload?.endDate
                    }}
                    onHandleSelectedRange={onHandleSelectedDate}
                    onHandleCancel={initialDatePayload}
                    onHandleInputClick={() => setDatePayload({
                        ...datePayload,
                        isDatePickerActive: true
                    })}
                    onHandleBlurInput={() => setDatePayload({
                        ...datePayload,
                        isDatePickerActive: false
                    })}
                />
                <div className={cn(
                    "w-full py-1 flex items-center justify-center rounded-md border bg-primary-dark cursor-pointer hover:opacity-90",
                    (!datePayload?.startDate || !datePayload?.endDate) && "bg-text-300 text-text-300 hover:!bg-text-300 cursor-not-allowed"
                )}
                    onClick={onHandleApplyFilter}
                >
                    <span className={"font-bodyPri font-medium text-text-50 text-sm tracking-wide "}>
                        {"Apply Filters"}
                    </span>
                </div>
            </div>
        </FilterBox>
    )
});

export default QuestionDateFilter;