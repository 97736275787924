// import {useState} from 'react';

import ButtonContainer from "../ButtonContainer";
import AddScheduleDetail from "./components/AddScheduleDetail";

const ConfirmSchedulePage = ({ stepCount, setStepCount }) => {
    return (
        <div className={"space-y-5"}>
            <ButtonContainer stepCount={stepCount} setStepCount={setStepCount} />
            <AddScheduleDetail stepCount={stepCount} setStepCount={setStepCount} />
        </div>
    )
}

export default ConfirmSchedulePage;