import { useNavigate } from 'react-router-dom';

import { BsFillCheckCircleFill } from 'react-icons/bs';

import { Container } from "pages/auth/walletConfirmation/WalletConfirmationPageStyle";

import { useAppState } from 'hooks/useStore';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';
import { txnType } from 'redux/wallet/wallet.const';

import { pagesInfo } from 'utils/pagesInfo';
import { currencyRateConversion } from 'utils/generators.utils';

const SuccessWalletConfirmation = () => {
    const { locals } = useAppState(s => s.local)
    const { walletConfirmation } = useAppState((state) => state.wallet)

    const navigate = useNavigate()

    let currency = "USD"
    switch (walletConfirmation?.walletConfirmation?.txnType) {
        case txnType?.INR_WALLET?.value:
            currency = "INR"
            break;
        case txnType?.AUD_WALLET?.value:
            currency = "AUD"
            break;
        default:
            currency = "USD"
            break;
    }

    const pageRedirect = () => {
        navigate(`${pagesInfo.USER_FINANCE.pagePath}${pagesInfo.WALLET.pagePath}?view=token_transactions`, { replace: true })
    }

    return walletConfirmation?.walletConfirmation ? (
        <Container className={"!shadow-all-md"}>
            <BsFillCheckCircleFill className={"text-8xl text-green-500"} />
            <div className={"flex items-center justify-center gap-2 font-bodyPri font-semibold text-2xl text-primary-dark"}>
                <span>{currency}</span>
                <span>{parseFloat(walletConfirmation?.walletConfirmation?.amount / 100).toFixed(2)}</span>
            </div>
            {[masterCurrencyCodeEnum.USD.value]?.includes(currency?.toUpperCase()) &&
                <div className={"flex items-center justify-center gap-2 font-bodyPri font-medium text-lg text-text-800"}>
                    <span>{currencyRateConversion(locals?.currencyCode, walletConfirmation?.walletConfirmation?.amount)}</span>
                </div>
            }
            <div className={"flex items-center"}>
                <span className={"font-bodyPri font-semibold text-lg text-green-500 tracking-wider"}>
                    {"Payment Successful"}
                </span>
            </div>
            <div className={"flex items-center justify-center gap-2"}>
                <span className={"font-bodyPri font-normal text-base text-800"}>
                    {"Transaction ID:"}
                </span>
                <span className={"font-bodyPri font-semibold text-md text-primary-dark"}>
                    {walletConfirmation?.walletConfirmation?.virtualTxnId}
                </span>
            </div>
            <div onClick={pageRedirect}>
                <span className={"px-5 py-1.5 rounded-full cursor-pointer font-buttons font-medium text-md text-secondary-main border-2 border-secondary-main hover:text-text-50 hover:bg-secondary-main"}>
                    {"Wallet & Transactions"}
                </span>
            </div>
        </Container>
    ) : null
}

export default SuccessWalletConfirmation