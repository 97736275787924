export const url = {
    VERIFY_PROMO: "/promos/verify",
    REDEEM_PROMO: "/promos/redeem"
}


export const promoStatusConst = {
    PENDING: {
        value: 'pending',
        label: 'Pending',
        lightColor: 'yellow-100',
        darkColor: 'yellow-500'
    },
    ACTIVE: {
        value: 'active',
        label: 'Active',
        lightColor: 'green-100',
        darkColor: 'green-500'
    },
    INACTIVE: {
        value: 'inactive',
        label: 'In Active',
        lightColor: 'gray-100',
        darkColor: 'gray-500'
    },
    EXPIRE: {
        value: 'expire',
        label: 'Expire',
        lightColor: 'red-100',
        darkColor: 'red-500'
    }
}

export const promoTypeConst = {
    DISCOUNT: {
        value: 'discount',
        label: 'Discount',
    },
    CASHBACK: {
        value: 'cashback',
        label: 'Cashback',
    },
    UPFRONT: {
        value: 'upfront',
        label: 'Upfront',
    },
}

export const useTypeConst = {
    SINGLE: {
        value: 'single',
        label: 'Single',
    },
    MULTIPLE: {
        value: 'multiple',
        label: 'Multiple',
    },
}

export const applicationTypeConst = {
    AMOUNT: {
        value: 'amount',
        label: 'Amount',
    },
    PERCENTAGE: {
        value: 'percentage',
        label: 'Percentage',
    },
    UNIT: {
        value: 'unit',
        label: 'Unit',
    },
}

export const validToTypeConst = {
    NEVER: {
        value: 'never',
        label: 'Never',
    },
    FIXED: {
        value: 'fixed',
        label: 'Fixed',
    },
}

export const promoTxnTypeConst = {
    WALLET: {
        value: 'wallet',
        label: 'Wallet',
    },
    PENDING: {
        value: 'pending',
        label: 'Pending',
    },
    PROMO: {
        value: 'promo',
        label: 'Promo',
    },
}


export const verifyPromoPayload = {
    promoCode: ""
}

export const redeemPromoPayload = {
    promoCode: ""
}