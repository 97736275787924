import { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { FaCrown } from "react-icons/fa";
import { BiHelpCircle } from "react-icons/bi";

import { useUser } from 'pages/auth/message/contexts/userContext';

import {
    LogedInNavLink,
    ReferFriend,
    IconSection,
    IconContainer,
    MessageIcon,
    NotificationIcon,
    BadgeCounter,
    ProfileIcon,
} from './NavBarStyle';
import RightLogedInToggleMenu from './RightLogedInToggleMenu';
import DropDownMenu from 'components/dropdownMenu/DropDownMenu';
import ToolTipView from 'components/tooltipView';
import NotificationBar from 'pages/auth/message/components/NotificationBar/NotificationBar';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { subscriptionPlanEnum, subscriptionStatusEnum, userPlans } from 'redux/subscription/subscription.const';
import { modalConst } from 'redux/local/local.const';
import VideoHelpButton from 'components/modals/video/videoHelpButton';
import NewSideBarNotification from 'components/notification/NewSideBarNotification';

const messageCount = 11;
const notificationCount = 5;

const RightLogedInNavLinks = ({ isScrolled, isMouseHover }) => {
    const { modal, locals } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { session, logout } = useAppState(s => s.auth)
    const pageName = currentPageInfo.pageName;
    const [notificationCount, setNotificationCount] = useState({});

    const [isClick, setIsClick] = useState(false)

    const userContext = useUser()
    const dispatcher = useAppDispatcher()

    const handleUpgrade = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.planTableModal.key]: {
                ...modalConst.planTableModal,
                isVisible: true,
                title: "Unlock Premium Features: Subscribe to Go Pro",
                userPlans
            },
        }))
    }

    return (
        <LogedInNavLink>
            {/* {pageName !== pagesInfo.REFERRAL.pageName &&
                <ReferFriend to={`${pagesInfo.USER_MARKETING.pagePath}/referral`} isScrolled={isScrolled}>
                    {'Refer a friend'}
                </ReferFriend>
            } */}
            <VideoHelpButton />

            <IconSection>
                <IconContainer isScrolled={isScrolled}>
                    {userContext?.loadSuccess && userContext?.user &&
                        <NotificationBar />
                    }
                </IconContainer>
                <IconContainer isScrolled={isScrolled}>
                    <NewSideBarNotification />
                </IconContainer>
            </IconSection>
            <ProfileIcon onClick={() => setIsClick(!isClick)} isScrolled={isScrolled}>
                <img src={user?.user?.profilePicUrl} alt={'profile-pic'} />
            </ProfileIcon>
            {!!user?.user?.user_subscriptions?.length && (
                <ToolTipView allowHTML={true} theme={"light"} content={(() => (
                    <div className={"p-2 rounded flex flex-col items-start justify-start gap-1"}>
                        {user?.user?.user_subscriptions?.map((user_subscription, index) => (
                            <span key={index} className={"font-bodyPri font-medium text-base text-text-900"}>
                                {`${subscriptionPlanEnum[user_subscription?.plan]?.label}: ${subscriptionStatusEnum[user_subscription?.status]?.label}`}
                            </span>
                        ))}
                    </div>
                ))()}>
                    <button onClick={handleUpgrade}>
                        <FaCrown className={"text-2xl text-complementry-main cursor-pointer"} />
                    </button>
                </ToolTipView>
            )}
            <DropDownMenu isClick={isClick} setIsClick={setIsClick} boxStyle={tw`right-0 max-h-[36rem] min-w-[20rem]`} arrowStyle={tw`bg-white`}>
                <RightLogedInToggleMenu setIsClick={setIsClick} />
            </DropDownMenu>
        </LogedInNavLink>
    )
}

export default RightLogedInNavLinks
