import { useEffect, useMemo, useRef, useState } from "react";

import { motion, AnimatePresence } from "framer-motion";

import { FaCaretDown, FaSpinner } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";

import { cn } from "utils/cn.utils";

const FloatingLabelSelect = ({
    isLoading = false,
    label = "Label",
    labelItem = 0,
    optionStyle,
    options = [],
    onHandleSelect,
    value = "Select any",
    OptionChild,
    isShowEmptyContent = false,
    searchable = false,
    showLabel = true,
    showIcon = true,
    isOpenDropdown = false,
    isDisabled = false,
    EmptyContentContainer = () => { },
    dropdownWrapperClassName = "",
    dropDownContainerClassName = "",
    customBtnStyle = "",
    labelClassName = "",
}) => {
    const [isFocused, setIsFocused] = useState(isOpenDropdown);
    const [searchValue, setSearchValue] = useState("");
    const containerRef = useRef(null);

    useEffect(() => {
        if (isOpenDropdown) {
            setIsFocused(isOpenDropdown)
        }
    }, [isOpenDropdown])

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setIsFocused(false);
        }
    };

    const toggleDropdown = () => {
        if (isDisabled) return;

        setIsFocused((s) => !s);
        setSearchValue("")
        if (!isFocused) {
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }
    };

    const handleSelect = (e, option) => {
        e.stopPropagation();
        onHandleSelect(option);
        setIsFocused(false);
    };

    const filteredOptions = useMemo(() => options?.filter((option) => option?.label?.toLowerCase().includes(searchValue?.toLowerCase())), [options, searchValue]);

    return (
        <div ref={containerRef} className={"relative flex justify-center items-center font-bodyPri"}>
            <motion.button
                className={cn(
                    "w-full flex justify-between items-center group font-bodyPri cursor-pointer",
                    "h-10 px-3 text-text-900 bg-white border rounded border-divider-lightDark",
                    "hover:border-primary-main focus-within:ring-2 focus-within:ring-primary-main focus-within:border-none",
                    customBtnStyle
                )}
                onClick={toggleDropdown}
            >
                <button
                    id={labelItem}
                    type="text"
                    className="w-full bg-transparent outline-none flex justify-between items-center gap-3 capitalize"
                    disabled={isDisabled}
                >
                    {value}
                    {isLoading &&
                        <FaSpinner className={"animate-spin text-lg text-primary-dark"} />
                    }
                    {(!isLoading && showIcon) && (
                        <span>
                            <FaCaretDown className={cn("text-text-700 ease-in-out duration-300", isFocused && "-rotate-180")} />
                        </span>
                    )}
                </button>

                {showLabel && (
                    <label
                        for={labelItem}
                        onClick={toggleDropdown}
                        className={cn(
                            "text-opacity-75 absolute left-2.5 top-2.5 px-1 transition duration-200 text-sm cursor-pointer",
                            (isFocused || value) ? "text-primary-main transform -translate-y-5 -translate-x-1 scale-90 bg-white" : "",
                            ((!isFocused && value) ? "text-black" : ""),
                            labelClassName
                        )}
                    >
                        {label}
                    </label>
                )}
            </motion.button>
            <AnimatePresence>
                {(isFocused && !isDisabled) && (
                    <motion.ul
                        initial={{ opacity: 0, y: -10, height: 0 }}
                        animate={{ opacity: 1, y: 0, height: "auto" }}
                        exit={{ opacity: 0, y: -10, height: 0 }}
                        transition={{ type: "spring", duration: 0.5 }}
                        className={cn(
                            "w-full absolute z-30 top-11 left-0 rounded-md overflow-hidden bg-white shadow-lg ring-1 ring-black/5 focus:outline-none",
                            dropdownWrapperClassName
                        )}
                    >
                        {searchable && (
                            <div className={"sticky top-0 z-40 bg-white flex items-center border-b border-gray-300 px-3 py-2 w-full focus-within:border-b-primary-main"}>
                                <input
                                    type="text"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    placeholder="Search..."
                                    className="w-full outline-none"
                                    autoFocus={true}
                                />
                                <BiSearch className="text-text-600 text-lg" />
                            </div>
                        )}
                        <div className={cn("w-full", dropDownContainerClassName)}>
                            {(isShowEmptyContent && !filteredOptions?.length) &&
                                <EmptyContentContainer />
                            }
                            {filteredOptions?.map((option, index) => (
                                <motion.li
                                    key={index}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className={cn("cursor-pointer w-full", optionStyle)}
                                    onClick={(e) => handleSelect(e, option)}
                                >
                                    <OptionChild option={{ ...option, index: index }} />
                                </motion.li>
                            ))}
                        </div>
                    </motion.ul>
                )}
            </AnimatePresence>
        </div>
    );
};

export default FloatingLabelSelect;