import { useEffect } from "react";

import MyDriveFiles from 'pages/auth/drive/components/myFiles/components/myDriveFiles/MyDriveFiles';
import DataStorage from 'pages/auth/drive/components/myFiles/components/dataStorage/DataStorage';
import RecentOpenFiles from 'pages/auth/drive/components/myFiles/components/recentOpen/RecentOpenFiles';

import { getFileList, getRecentOpenFileList, getUserStorageDetail } from 'redux/drive/drive.request';

import { useAppDispatcher } from 'hooks/useStore';
import { resetFileList, resetRecentOpenFileList, resetUserStorage } from 'redux/drive/drive.slice';

const MyFiles = () => {
    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(getFileList({ page: 1, records: 10 }))
        dispatcher(getUserStorageDetail())
        dispatcher(getRecentOpenFileList())

        return () => {
            dispatcher(resetFileList())
            dispatcher(resetUserStorage())
            dispatcher(resetRecentOpenFileList())
        }
    }, [])

    return (
        <div className="relative w-full flex flex-col items-start justify-start lg:flex-row lg:items-start lg:justify-start gap-3">
            <MyDriveFiles />
            <section className={"w-full lg:w-[40%] flex flex-col items-start justify-start gap-3"}>
                <DataStorage />
                <RecentOpenFiles />
            </section>
        </div>
    )
}

export default MyFiles