import tw, { styled } from "twin.macro";

export const StyledChatListContainerHeader = styled.div`
  ${tw`w-full flex justify-between border-b border-gray-200 px-5 padding-bottom[22px] padding-top[22px]`}
  p {
    ${tw`text-primary-dark text-base font-bold`}
  }
  div {
    ${tw`flex items-center`}
    p {
      ${tw`ml-2.5 cursor-pointer`}
    }
  }
`;
