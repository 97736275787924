import { useState, useEffect } from "react";

import { IoIosCheckmark } from "react-icons/io";

import FloatingLabelSelect from "components/floating/floatingLabelSelect";

import { sortCountryList } from "pages/auth/profile/data";

import { updateUserCultureDetailByUserId } from "redux/user/user.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";

const UserFromDetail = () => {
    const { user, userCultureDetail, addUserCultureDetail } = useAppState((state) => state.user)
    const { countryList } = useAppState((s) => s.master)
    const [showIcon, setShowIcon] = useState(false)

    const dispatcher = useAppDispatcher()

    const [fromLocationName, setFromLocationName] = useState({
        countryDetail: {
            masterCountryId: 0,
            country: "",
            countryDomain: "",
            isdCode: ""
        },
        stateDetail: {
            masterStateId: 0,
            state: ""
        },
        cityDetail: {
            masterCityId: 0,
            city: ""
        }
    })

    const onSelectCountry = (option) => {
        const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option.value)
        dispatcher(updateUserCultureDetailByUserId(
            user?.user?.userId,
            {
                fromMasterCountryId: selectedCountry?.masterCountryId
            }
        ))
    }

    return (
        <div className={"w-full flex items-center gap-3"}>
            <div
                className="w-full"
                onMouseEnter={() => setShowIcon(true)}
                onMouseLeave={() => setShowIcon(false)}
            >
                <FloatingLabelSelect
                    isLoading={addUserCultureDetail?.isLoading}
                    labelItem={"from"}
                    searchable={true}
                    showLabel={false}
                    showIcon={showIcon}
                    customBtnStyle={"border-transparent hover:border-transparent hover:shadow"}
                    dropDownContainerClassName={"w-full max-h-40 overflow-y-auto scrollbar-thin"}
                    options={countryList?.countryList?.slice().sort((country1, country2) => sortCountryList(country1.masterCountryId, country2.masterCountryId, userCultureDetail?.userCultureDetail?.fromCountry?.masterCountryId)).map((country) => ({
                        label: country?.country,
                        value: country?.masterCountryId,
                        country: country
                    })) || []}
                    onHandleSelect={(option) => onSelectCountry(option)}
                    value={<p className="font-medium">{countryList?.countryList?.find((country) => country?.masterCountryId === userCultureDetail?.userCultureDetail?.fromCountry?.masterCountryId)?.country}</p>}
                    OptionChild={({ option }) => (
                        <div className="bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between">
                            <div className="flex items-center gap-3">
                                <img
                                    src={`https://flagcdn.com/16x12/${option.country?.countryDomain}.png`.toLowerCase()}
                                    className={"w-10 h-6 object-cover"}
                                />
                                {option?.country?.country}
                            </div>
                            {(option?.value === userCultureDetail?.userCultureDetail?.fromCountry?.masterCountryId) && <IoIosCheckmark size={20} color="green" />}
                        </div>
                    )}

                />
            </div>
        </div>
    )
}

export default UserFromDetail