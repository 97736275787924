import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/interview/interview.const";

class InterviewService {
    getInterviewDetailByInterviewId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.INTERVIEW_DETAIL, params)
        )

        return response;
    }

    // new interview session detail
    createInterviewSessionDetail = async ({params, body}) => {
        const response = await baseAuthApiInstance().post(
            format(url.INTERVIEW_SESSION_DETAIL, params),
            body
        )

        return response;
    }
}

export default new InterviewService();