import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { cn } from "utils/cn.utils";

import ButtonLoader from 'components/loader/ButtonLoader';
// import ComponentLoader from 'components/loader/ComponentLoader';
import { OptionSelector } from 'components/common-components/Select';

// import { uploadDocumentToS3 } from 'redux/document/document.request';
import { uploadFileToS3 } from 'redux/storage/storage.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
// import { setUploadDocumentPayload } from 'redux/document/document.slice';
import { setAddOnboardingDocDetailPayload, setClearAddOnboardingDocDetail } from 'redux/onboarding/onboarding.slice';
import { onBoardingStatus } from 'redux/onboarding/onboarding.const';
import { modalConst } from 'redux/local/local.const';
import { documentFileTypeConst, documentFileTypeEnum, videoFileTypeConst, videoFileTypeEnum } from "redux/storage/storage.const";

const UploadDocumentModal = () => {
    const { modal } = useAppState((state) => state.local)
    // const { uploadDocument } = useAppState((state) => state.document)
    const { uploadFile } = useAppState((state) => state.storage)
    const { user } = useAppState((state) => state.user)
    const { userOnboardingDetail, addOnboardingDocDetail, categoryList } = useAppState((s) => s.onboarding)

    const dispatcher = useAppDispatcher()

    const [fileDetail, setFileDetail] = useState(null)
    const [isFormDisabled, setIsFormDisabled] = useState(false)

    useEffect(() => {
        if (addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileUrl) {
            setFileDetail({
                file: { name: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileUrl },
                fileUrl: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileUrl
            })
        }
    }, [addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileUrl])

    // use effect for close modal
    useEffect(() => {
        if (addOnboardingDocDetail?.addOnboardingDocDetail) {
            dispatcher(setModal({
                ...modal,
                [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: false,
            }))
            dispatcher(setClearAddOnboardingDocDetail())
        }
    }, [addOnboardingDocDetail?.addOnboardingDocDetail])

    useEffect(() => {
        if ([onBoardingStatus?.APPROVED?.value, onBoardingStatus?.REJECTED?.value, onBoardingStatus?.BLOCKED?.value]?.includes(userOnboardingDetail?.userOnboardingDetail?.status)) {
            setIsFormDisabled(true)
        }
    }, [userOnboardingDetail?.userOnboardingDetail?.status])

    const onHandleCategory = (option) => {
        if (!addOnboardingDocDetail?.addOnboardingDocDetailPayload?.documentId) {
            dispatcher(setAddOnboardingDocDetailPayload({
                ...addOnboardingDocDetail?.addOnboardingDocDetailPayload,
                fileCategory: option?.value
            }))
        }
        if (addOnboardingDocDetail?.addOnboardingDocDetailPayload?.documentId && addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileCategory !== Object.values(categoryList?.categoryList?.categoryListItems)[0]?.value) {
            toast.error("You cannot change document category!")
        }
    }

    const onHandleFileUpload = (event) => {
        let selectedFile = event.target.files[0];
        if (!selectedFile || selectedFile.length === 0) {
            alert("file not selected!")
            return;
        }
        if (!categoryList?.categoryList?.isVideoUploadEnabled && selectedFile?.size < 2 * 1048576) {
            if (!documentFileTypeEnum[selectedFile?.type]) {
                toast.warn("Please select a valid document!")
                return;
            } else {
                const url = URL.createObjectURL(selectedFile)
                setFileDetail({
                    fileList: event.target.files,
                    file: selectedFile,
                    fileUrl: url,
                })
            }
            return;
        }
        if (!categoryList?.categoryList?.isVideoUploadEnabled && selectedFile?.size > 2 * 1048576) {
            toast.error("File size too large!")
            return;
        }
        if (categoryList?.categoryList?.isVideoUploadEnabled && selectedFile?.size < 100 * 1048576) {
            if (!videoFileTypeEnum[selectedFile?.type]) {
                toast.warn("Please select a valid document!")
                return;
            } else {
                const url = URL.createObjectURL(selectedFile)
                setFileDetail({
                    fileList: event.target.files,
                    file: selectedFile,
                    fileUrl: url,
                })
            }

            return;
        }
        if (categoryList?.categoryList?.isVideoUploadEnabled && selectedFile?.size > 100 * 1048576) {
            toast.error("File size too large!")
            return;
        }
    }

    const onUploadDocument = (event) => {
        event.preventDefault()
        if (addOnboardingDocDetail?.addOnboardingDocDetailPayload?.documentId && fileDetail?.fileUrl === addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileUrl) return;
        // dispatcher(setUploadDocumentPayload({
        //     ...uploadDocument?.uploadDocumentPayload,
        //     fileName: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileName,
        //     fileType: fileDetail?.file?.type,
        //     file: fileDetail?.file,
        //     fileCategory: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileCategory?.replaceAll("[^a-zA-Z0-9]", "-") || Object.values(categoryList?.categoryList?.categoryListItems)[0]?.value
        // }))
        let updatedFileList = []
        const fileList = fileDetail?.fileList
        for (let i = 0; i < fileList?.length; i++) {
            const newFile = fileList[i]
            const newFileExtension = newFile?.name.slice(newFile?.name.lastIndexOf(".") + 1);
            const updatedFile = new File([newFile], `${addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileName}.${newFileExtension}`, {
                type: newFile?.type,
                lastModified: newFile?.lastModified,
            });
            updatedFileList.push(updatedFile)
        }

        dispatcher(uploadFileToS3(updatedFileList, user?.user?.userId))
    }

    // if (uploadDocument?.isLoading || addOnboardingDocDetail?.isLoading) {
    //     return (
    //         <ComponentLoader isLoading={uploadDocument?.isLoading || addOnboardingDocDetail?.isLoading} className={"w-full min-h-[24rem]"} />
    //     )
    // }

    return (
        <form onSubmit={onUploadDocument} className="space-y-5">
            <div className={"min-h-[24rem] block w-full border border-text-400 space-y-8"}>
                <div className='grid grid-cols-12 gap-5 p-3'>
                    <div className='col-start-0 col-span-full md:col-start-0 md:col-span-6 space-y-2'>
                        <label className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Category"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </label>
                        <OptionSelector
                            options={Object.values(categoryList?.categoryList?.categoryListItems)}
                            placeholder={"Please pick a type"}
                            value={addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileCategory}
                            onChange={(option) => onHandleCategory(option)}
                            required={true}
                        />
                    </div>
                    <div className={"col-start-0 col-span-full md:col-start-0 md:col-span-6 space-y-2"}>
                        <label className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"File Name"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </label>
                        <input
                            type={"text"}
                            rows={2}
                            className={cn(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            minLength={5}
                            maxLength={50}
                            placeholder={categoryList?.categoryList?.isVideoUploadEnabled ? "example.mp4, example.mkv" : "example.pdf, example.docx"}
                            value={addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileName}
                            onChange={(e) => dispatcher(setAddOnboardingDocDetailPayload({
                                ...addOnboardingDocDetail?.addOnboardingDocDetailPayload,
                                fileName: e.target.value
                            }))}
                            required={true}
                            disabled={((addOnboardingDocDetail?.addOnboardingDocDetailPayload?.documentId && fileDetail?.fileUrl === addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileUrl) || isFormDisabled) && true}
                        />
                    </div>
                    <div className={'col-start-0 col-span-full md:col-start-0 md:col-span-6 space-y-2'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm whitespace-nowrap space-x-1'>
                            <span>{"Select File"}</span>
                            <span>{categoryList?.categoryList?.isVideoUploadEnabled ? "(maximum 100 MB)" : "(maximum 3 MB each)"}</span>
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <label className='relative inline-flex mx-auto shadow-sm'>
                            <input
                                type={"file"}
                                style={{ display: "none" }}
                                accept={categoryList?.categoryList?.isVideoUploadEnabled ? videoFileTypeConst : documentFileTypeConst}
                                onChange={onHandleFileUpload}
                                disabled={isFormDisabled}
                            />
                            <input
                                type={"text"}
                                className={cn(
                                    "w-full px-3 py-1 border-2 border-divider-medium rounded-l-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                placeholder={"No File Chosen"}
                                required={true}
                                value={fileDetail?.file?.name}
                                disabled={isFormDisabled}
                            />
                            <span className='p-2 rounded-r-md bg-primary-light border-2 border-primary-light hover:(text-text-50 border-secondary-dark) cursor-pointer font-buttons font-normal text-sm text-primary-dark whitespace-nowrap'
                            >
                                {"Choose a file.."}
                            </span>
                        </label>
                    </div>
                    {fileDetail?.file?.size &&
                        <div className={"col-start-1 col-span-full font-bodyPri font-normal text-text-900 text-sm tracking-wide"}>
                            {categoryList?.categoryList?.isVideoUploadEnabled
                                ? `Selected video upload size is ${(fileDetail?.file?.size / 1048576)?.toFixed(0)} MB`
                                : `Selected document size is ${(fileDetail?.file?.size / 1048576)?.toFixed(0)} MB`
                            }
                        </div>
                    }
                </div>
                {(uploadFile?.message || addOnboardingDocDetail?.errorMsg) &&
                    <div className={"w-full flex items-end justify-center font-bodyPri font-normal text-red-500 text-base"}>
                        {uploadFile?.message || addOnboardingDocDetail?.errorMsg}
                    </div>
                }
            </div>
            <div className={"flex justify-end"}>
                <button
                    type={"submit"}
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full",
                        "font-buttons font-normal text-base",
                        (!fileDetail?.fileUrl || (fileDetail && fileDetail?.fileUrl === addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileUrl)) && "text-text-300 border border-text-300 cursor-not-allowed",
                        (fileDetail && fileDetail?.fileUrl !== addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileUrl) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                        (uploadFile?.isLoading || addOnboardingDocDetail?.isLoading) && "border border-secondary-main bg-secondary-main text-text-50"
                    )}>
                    {(uploadFile?.isLoading || addOnboardingDocDetail?.isLoading) &&
                        <ButtonLoader isLoading={uploadFile?.isLoading || addOnboardingDocDetail?.isLoading} />
                    }
                    {(!uploadFile?.isLoading && !addOnboardingDocDetail?.isLoading) && "Upload"}
                </button>
            </div>
        </form>
    )
}

export default UploadDocumentModal;