import {
    WorksItemContainer,
    ItemContent,
    ItemImage,
    ItemTextContainer,
    StepCount,
    TextContainer,
    Title,
    Description,
    RedirectLink,
    RedirectLinkArrow,
} from './HowEdulyteWorksStyle';
const HowEdulyteWorksItem = ({ workDataItem }) => {
    return (
        <WorksItemContainer>
            <ItemContent>
                <ItemTextContainer>
                    <StepCount>{workDataItem.step}</StepCount>
                    <TextContainer>
                        <Title>{workDataItem.title}</Title>
                        <Description>{workDataItem.description}</Description>
                        <RedirectLink to={workDataItem.link}>
                            {workDataItem.linkText}
                            <RedirectLinkArrow />
                        </RedirectLink>
                    </TextContainer>
                </ItemTextContainer>
            </ItemContent>
            <ItemImage>
                <img src={workDataItem.imageUrl} alt={'how-edulyte-works'} />
            </ItemImage>
        </WorksItemContainer>
    )
}

export default HowEdulyteWorksItem
