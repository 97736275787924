import { useCallback } from "react";

import ContactSocialItem from "components/modals/crmModals/createCrmContactDetailModal/commonComponents/ContactSocialItem";
import AddItemBtn from "components/modals/crmModals/commonComponents/AddItemBtn";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setAddCrmContactDetailPayload } from "redux/crm/crm.slice";

function AddContactSocials() {
    const { addCrmContactDetail } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()

    const onHandleAddNewSocialDetail = useCallback(() => {
        const newId = (addCrmContactDetail?.payload?.socials?.length > 0)
            ? addCrmContactDetail?.payload?.socials[addCrmContactDetail?.payload?.socials?.length - 1].id + 1
            : 1
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            socials: !!addCrmContactDetail?.payload?.socials?.length
                ? [...addCrmContactDetail?.payload?.socials, { id: newId, link: "", social: {} }]
                : [{ id: newId, link: "", social: {} }]
        }))
    }, [addCrmContactDetail?.payload])

    const onHandleChangeSocialDetail = useCallback((socialId, updatedValue) => {
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            socials: addCrmContactDetail?.payload?.socials?.map((socialItem) => (
                (socialItem?.id === socialId) ? updatedValue : socialItem
            ))
        }))
    }, [addCrmContactDetail?.payload])

    const onHandleDeleteSocialDetail = useCallback((socialId) => {
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            socials: addCrmContactDetail?.payload?.socials?.filter((socialItem) => socialItem?.id !== socialId)
        }))
    }, [addCrmContactDetail?.payload])

    return (
        <div className="w-full flex flex-col gap-8 items-center mt-5">
            {!!addCrmContactDetail?.payload?.socials?.length &&
                addCrmContactDetail?.payload?.socials?.map((socialItem, index) => (
                    <ContactSocialItem
                        key={index}
                        index={index}
                        isShowCancelBtn={true}
                        socialItem={socialItem}
                        onHandleChangeSocialDetail={onHandleChangeSocialDetail}
                        onHandleDeleteSocialDetail={onHandleDeleteSocialDetail}
                    />
                ))}
            <AddItemBtn
                btnTitle={"Add Social Link"}
                onHandleAddBtn={onHandleAddNewSocialDetail}
            />
        </div>
    );
}

export default AddContactSocials;