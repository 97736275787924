import { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { PeopleAlt } from '@mui/icons-material';

import {
    ToggleMenuContent,
    Item,
    ItemIcon,
    ItemName,
    RoleSwitchIcon
} from './NavBarStyle';

import { IoIosArrowForward } from 'react-icons/io';

import RightLoggedInToggleSubMenu from "./RightLoggedInToggleSubMenu";
import { RightLoggedInMenuItems } from 'components/navbar/RightLogedInToggleMenu.data'

import { createLogout } from "redux/auth/auth.request"

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setLocals } from "redux/local/local.slice";
import { localsConst } from "redux/local/local.const";
import { userRoles } from "redux/auth/auth.const"

import { pagesInfo } from "utils/pagesInfo";


const RightLogedInToggleMenu = ({ setIsClick }) => {
    const { locals } = useAppState((s) => s.local)
    const { user } = useAppState((s) => s.user)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const [activeMenuItem, setActiveMenuItem] = useState(null);


    useEffect(() => {
        const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
        const topLevelPath = pathSegments[0];
        setActiveMenuItem(topLevelPath);

    }, [location.pathname]);

    const handleOnClickItem = (dataItem) => {
        if (dataItem.name === "Log Out") {
            dispatcher(createLogout())
            setIsClick(false)
            return;
        }
        if (!dataItem?.subMenu || dataItem?.subMenu?.length === 0) {
            navigate(dataItem.path)
            setIsClick(false)
        }
        if (dataItem?.subMenu?.length > 0) {
            if (!activeMenuItem || (activeMenuItem !== dataItem?.value)) {
                setActiveMenuItem(dataItem?.value)
            } else {
                setActiveMenuItem(null)
            }
        }
    }

    const handleRole = () => {
        let role = userRoles.STUDENT.value === locals.userRole
            ? userRoles.TUTOR.value
            : userRoles.STUDENT.value

        dispatcher(setLocals({
            ...locals,
            [localsConst.USER_ROLE.value]: role
        }))
        navigate(pagesInfo.DASHBOARD.pagePath, { replaceAll: true })
        setIsClick(false)
    }

    const excludeItem = (dataItem) => {
        if (dataItem?.name === "Apply to Teach" && !!user?.user?.roles?.find(role => role?.role === userRoles.TUTOR.value)) {
            return false
        }
        return true
    }

    return (
        <ToggleMenuContent>
            <Item
                className={"pt-2"}
                onClick={() => {
                    navigate(`${pagesInfo?.SETTINGS?.pagePath}${pagesInfo?.PROFILE?.pagePath}`)
                    setIsClick(false)
                }}
            >
                <ItemIcon>
                    <PeopleAlt />
                </ItemIcon>
                <ItemName>
                    <div className={"w-full h-full flex flex-col justify-start items-start"}>
                        <span>{user?.user?.firstName + " " + user?.user?.lastName?.charAt(0)}</span>
                        <span className={"text-xs text-primary-main"}>{"My Profile"}</span>
                    </div>
                </ItemName>
            </Item>

            {!!locals && !!user?.user?.roles?.find(role => role?.role === userRoles.TUTOR.value) && (
                <Item onClick={handleRole}>
                    <ItemIcon>
                        <RoleSwitchIcon />
                    </ItemIcon>
                    <ItemName>
                        {(locals?.userRole === userRoles.STUDENT.value ? userRoles.TUTOR.label : locals?.userRole === userRoles.TUTOR.value ? userRoles.STUDENT.label : "") + " Dashboard"}
                    </ItemName>
                </Item>
            )}

            {RightLoggedInMenuItems?.map((dataItem, index) => dataItem.roles.includes(locals.userRole) && excludeItem(dataItem) && (
                <>
                    <Item key={index} onClick={() => handleOnClickItem(dataItem)}>
                        <ItemIcon>
                            <dataItem.icon />
                        </ItemIcon>
                        <ItemName>
                            {dataItem?.name}
                        </ItemName>
                        {(dataItem?.subMenu?.length > 0) &&
                            <div className={"pl-5 flex justify-end"}>
                                <IoIosArrowForward className={cn(
                                    "font-bold text-2xl text-text-600 transition ease-in-out delay-50 duration-100",
                                    (activeMenuItem && (activeMenuItem === dataItem?.value)) && "rotate-90",
                                    (!activeMenuItem || (activeMenuItem && (activeMenuItem !== dataItem?.value))) && "rotate-0",
                                )}
                                />
                            </div>
                        }
                    </Item>
                    {((dataItem?.subMenu?.length > 0) && (activeMenuItem === dataItem?.value)) &&
                        <>
                            {dataItem?.subMenu?.map((menuItem, index) => menuItem?.roles?.includes(locals.userRole) && (
                                <RightLoggedInToggleSubMenu
                                    key={index}
                                    menuItem={menuItem}
                                    setIsClick={setIsClick}
                                />
                            ))}
                        </>
                    }
                </>
            ))}

        </ToggleMenuContent>
    )
}

export default RightLogedInToggleMenu
