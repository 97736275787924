import tw, { styled } from 'twin.macro';
import Modal from '@mui/material/Modal';
import { NavLink as RouteLink } from 'react-router-dom';
import { Section, SectionContent } from 'components/generalComponent/StyledComponent';
import { ArrowForwardIos, PlayCircleOutline } from '@mui/icons-material';

export const Container = tw(Section)`
    bg-white
`;

export const Content = tw(SectionContent)`
    md:(col-start-2 col-span-10) lg:(col-start-3 col-span-8)
`;

export const Header = tw.div`
    flex flex-col items-center gap-5 md:mx-16 lg:mx-32
`;

export const Heading = tw.h3`
    font-heading font-semibold text-3xl text-text-900 text-center
`;

export const SubHeading = tw.h4`
    font-subHeading font-normal text-lg text-text-800 text-center
`;

export const VideoButton = tw.div`
    flex gap-2 cursor-pointer
    font-buttons font-normal text-lg text-secondary-main text-center 
    hover:text-secondary-dark
`;

export const PlayVideoButton = tw(PlayCircleOutline)``;

export const ItemContainer = tw.div`
    mt-5 flex flex-col overflow-hidden
`;

export const WorksItemContainer = tw.div`
    grid grid-cols-1 flex flex-col w-full gap-5
    md:(grid-cols-2 flex-row gap-0 even:(flex-row-reverse))
`;

export const ItemImage = tw.div`
    col-start-2 col-span-1 w-full flex justify-center items-center
`;

export const ItemContent = tw.div`
    col-start-1 col-span-1 w-full flex items-center
`;

export const ItemTextContainer = tw.div`
    flex gap-5
`;

export const StepCount = tw.span`
    font-bodyPri font-black text-5xl text-primary-main bg-primary-light
    rounded-2xl px-3 py-2 self-start
`;

export const TextContainer = tw.div`
    flex flex-col gap-2 
`;

export const Title = tw.span`
    font-bulletPoints font-bold text-xl text-text-900 
`;

export const Description = tw.span`
    font-bodyPri font-normal text-base text-text-800 text-left
`;

export const RedirectLink = tw(RouteLink)`
    font-bodyPri font-medium text-base text-text-900
    hover:(text-secondary-main) text-left
`;

export const RedirectLinkArrow = tw(ArrowForwardIos)`
    text-[.9rem]
`;

export const VideoModal = styled(Modal)`
    ${tw`
        bg-black w-3/4 h-1/2 md:h-3/4 rounded-md overflow-hidden self-center justify-self-center mx-auto my-auto
    `}
    iframe {
        ${tw`
            w-full h-full
        `}
    }
`;
