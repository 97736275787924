import { useEffect, useState } from 'react'
import { MdOutlineDragIndicator } from "react-icons/md";
import { FiShare } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { motion } from 'framer-motion';

import Switch from '@mui/material/Switch';
import InputTextSwitch from 'components/InputTextSwitch/inputTextSwitch';
import ToolTipView from 'components/tooltipView';
import FloatingLabelSelect from 'components/floating/floatingLabelSelect';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

import { updateLink as modifyLink } from 'redux/linkTree/linkTree.request';
import { debounce } from 'utils/generators.utils';
import { cn } from 'utils/cn.utils';
import { IoIosCheckmark } from 'react-icons/io';
import SelectIcon from 'pages/auth/crm/commonComponents/SelectIcon';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { menuItemsConst } from './data';

const LinkTreeCard = ({ link, onDelete }) => {
    const { updateLink } = useAppState((s) => s.linkTree);
    const { socialList } = useAppState((s) => s.master)
    const [update, setUpdate] = useState({ ...link });

    const dispatcher = useAppDispatcher()

    const handleChange = (field, value) => {
        setUpdate((s) => ({
            ...s,
            [field]: value
        }));
        const delayFunction = debounce.debounce(onSubmit)
        delayFunction(field, value)
    };

    const handleUpdate = (field, value) => {
        setUpdate((s) => ({
            ...s,
            [field]: value
        }));
        onSubmit(field, value)
    };

    const updateSocial = (field, value) => {
        const social = socialList?.data?.find(social => social?.key === value)
        setUpdate((s) => ({
            ...s,
            social: social
        }));
        onSubmit(field, value)
    }

    const onSubmit = (field, value) => {
        dispatcher(modifyLink(link?.id, { [field]: value }))
    }

    const buttonStyles = {
        backgroundColor: "transparent",
        color: "#424242",
        textTransform: "none",
        borderRadius: "25px",
        padding: "5px 1px",
    }

    const handleOptionSelect = (option, id) => {
        if (option?.value === menuItemsConst?.DELETE?.value) {
            onDelete(id)
        }
    }

    return (
        <div className='bg-white w-full flex items-center gap-3 rounded-lg border p-3'>
            <div className='w-fit'>
                <MdOutlineDragIndicator className='text-xl hover:text-text-900 text-text-700 cursor-move' />
            </div>
            <div className='w-full flex flex-col gap-0.5'>
                <div className='w-full flex items-center'>
                    <FloatingLabelSelect
                        labelItem={"social"}
                        options={socialList?.data?.map((socialContent) => ({
                            label: socialContent?.name,
                            value: socialContent?.key,
                            picture_url: socialContent?.picture_url,
                            color: socialContent?.color
                        }))}
                        customBtnStyle={"border-transparent hover:border-transparent hover:shadow"}
                        dropdownWrapperClassName={"w-60"}
                        dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                        searchable={true}
                        showLabel={false}
                        onHandleSelect={(e) => updateSocial("social_key", e.value)}
                        value={<img src={update?.social?.picture_url} className='w-5 h-5 aspect-square' />}
                        OptionChild={({ option }) => (
                            <div className={"bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between"}>
                                <div className={"flex items-center gap-1"}>
                                    <div className={"w-6 h-6 overflow-hidden rounded-full"}>
                                        <img
                                            src={option?.picture_url}
                                            className={`mr-1 ${option?.color} w-full h-full object-cover`}
                                            alt={option?.value}
                                        />
                                    </div>
                                    {option?.label}
                                </div>
                                {option?.value === update?.social?.key && <IoIosCheckmark size={20} color="green" />}
                            </div>
                        )}
                    />
                    <InputTextSwitch
                        textStyle={"text-text-900 font-medium line-clamp-1"}
                        placeholder="Link Title"
                        text={<p>{update.title.length === 0 ? "Add link title" : update.title}</p>}
                        value={update.title}
                        onChange={(e) => handleChange("title", e.target.value)}
                    />
                </div>
                <InputTextSwitch
                    textStyle={cn("text-text-900 text-sm font-medium line-clamp-1", update.link.length === 0 ? "text-text-500" : "")}
                    placeholder="Add URL"
                    text={<p>{update.link.length === 0 ? "Add URL" : update.link}</p>}
                    value={update.link}
                    onChange={(e) => handleChange("link", e.target.value)}
                />
            </div>
            <div className='w-20 flex flex-col items-end justify-end gap-1'>
                <SelectIcon
                    buttonLabel={<BsThreeDotsVertical className="hover:text-secondary-dark" />}
                    menuItems={Object.values(menuItemsConst)}
                    buttonStyles={buttonStyles}
                    onSelectOption={(option) => handleOptionSelect(option, link?.id)}
                />
                <ToolTipView content={update.active ? "Show" : "Hide"}>
                    <Switch checked={update.active} onChange={() => handleUpdate("active", !update.active)} />
                </ToolTipView>
            </div>
        </div>
    )
}

export default LinkTreeCard;

export const LinkTreeCardLoader = () => {
    return (
        <div className='bg-white w-full flex items-center gap-5 rounded-lg border p-3'>
            <div className='h-10 w-5 rounded animate-pulse bg-slate-200'></div>
            <div className='w-full flex flex-col gap-3'>
                <div className='flex gap-5 w-full'>
                    <div className='h-8 w-8 rounded-full animate-pulse bg-slate-200'></div>
                    <div className='h-8 w-full rounded animate-pulse bg-slate-200'></div>
                </div>
                <div className='h-6 w-full rounded animate-pulse bg-slate-200'></div>
            </div>
            <div className='w-20 flex flex-col items-end gap-2'>
                <div className='h-6 w-6 rounded-full animate-pulse bg-slate-200'></div>
                <div className='h-6 w-8 rounded-full animate-pulse bg-slate-200'></div>
            </div>
        </div>
    )
}
