import { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { PageContentStyle } from "./TokenPaymentConfirmationPageStyle";

import FullPageLoader from 'components/loader/FullPageLoader';

import PaymentSucceed from 'pages/auth/tokenPaymentConfirmation/components/PaymentSucceed';
import PaymentProcessing from 'pages/auth/tokenPaymentConfirmation/components/PaymentProcessing';
import PaymentFailed from 'pages/auth/tokenPaymentConfirmation/components/PaymentFailed';
import PaymentNotExist from 'pages/auth/tokenPaymentConfirmation/components/PaymentNotExist';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getPaymentIntentDetail } from 'redux/payment/payment.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetPaymentIntentDetail } from 'redux/payment/payment.slice';
import { setClearModal } from 'redux/local/local.slice';
import { paymentIntentStatusEnum } from 'redux/payment/payment.const';

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';

const PaymentConfirmation = () => {
    const { paymentIntentDetail } = useAppState((state) => state.payment)

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const navigate = useNavigate()

    const from = location.state?.from?.url || pagesInfo.DASHBOARD.pagePath;

    useEffect(() => {
        return () => {
            dispatcher(resetPaymentIntentDetail())
        }
    }, [])

    useEffect(() => {
        dispatcher(setClearModal())
    }, [])

    useEffect(() => {
        let query = generateQueryParams(location?.search)
        if (!query?.payment_intent) {
            navigate(from, { replace: true })
        } else if (query?.payment_intent) {
            dispatcher(getPaymentIntentDetail(query?.payment_intent))
        }
    }, [location.search])

    if (paymentIntentDetail.isLoading) {
        return (
            <FullPageLoader isLoading={paymentIntentDetail?.isLoading} />
        )
    }

    if (paymentIntentDetail?.message) {
        return (
            <PageContentStyle>
                <PaymentNotExist />
            </PageContentStyle>
        )
    }

    return paymentIntentDetail?.data ? (
        <PageContentStyle>
            <div className={"my-10 md:my-0"}>
                {[paymentIntentStatusEnum.SUCCEEDED.value].includes(paymentIntentDetail?.data?.status) &&
                    <PaymentSucceed />
                }
                {[paymentIntentStatusEnum.PROCESSING.value].includes(paymentIntentDetail?.data?.status) &&
                    <PaymentProcessing />
                }
                {![paymentIntentStatusEnum.SUCCEEDED.value, paymentIntentStatusEnum.PROCESSING.value].includes(paymentIntentDetail?.data?.status) &&
                    <PaymentFailed />
                }
            </div>
        </PageContentStyle>
    ) : null
};

const TokenPaymentConfirmationPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState(s => s.user)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.TOKEN_PAYMENT_CONFIRMATION))
    }, [dispatcher, currentPageInfo])

    return (
        <Elements stripe={loadStripe(user?.user?.stripePk)}>
            <PaymentConfirmation />
        </Elements>
    )
}

export default TokenPaymentConfirmationPage;