import { useEffect } from 'react'
import { motion } from "framer-motion";
import { z } from "zod";
import { useForm, useWatch } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod";

import { MdOutlineMailOutline } from "react-icons/md";
import { MdChevronRight } from "react-icons/md";

import ButtonLoader from "components/loader/button-loader";

import authService from 'redux/auth/auth.service';
import { identifierPurposeEnum, identifierChallengeEnum } from "redux/auth/auth.const";

import { cn } from "utils/cn.utils";
import { validateEmail } from 'utils/validation-functions';

export const identifierFormSchema = z.object({
    email: z.string().email().refine((value) => validateEmail(value), { message: "Invalid Email" }),
    purpose: z.literal(identifierPurposeEnum.sign_in.key),
    challenge: z.literal(identifierChallengeEnum.otp.key)
})

export const defaultIdentifierFormData = {
    email: "",
    purpose: identifierPurposeEnum.sign_in.key,
    challenge: identifierChallengeEnum.otp.key
}

const IdentifierForm = ({
    formData = defaultIdentifierFormData,
    textPlace,
    onHandleIdentifierNext = () => { },
    onHandleVerify = () => { },
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isLoading },
        reset,
        setError,
        control,
        setFocus,
    } = useForm({
        mode: "onChange",
        resolver: zodResolver(identifierFormSchema),
        defaultValues: formData
    })

    const values = useWatch({ control })

    useEffect(() => {
        setFocus("email")
    }, [setFocus])

    const onSubmit = async (data) => {
        try {
            const requestData = {
                body: { ...data }
            }
            const response = await authService.identifier(requestData)
            if (response.status === 200) {
                onHandleVerify(response?.data?.data?.token, data, true)
            } else if (response.status === 404 || response.status === 403) {
                onHandleIdentifierNext(data)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error.response.status === 404 || error.response.status === 403) {
                onHandleIdentifierNext(data)
            } else {
                console.log({ "error": error?.response?.data?.message || error?.response?.data?.error || error })
                setError("root", { message: error?.response?.data?.message || error?.response?.data?.error || "Oops, Something went wrong!" })
            }
        }
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={'w-full h-full flex flex-col justify-between gap-8 p-5 md:p-10'}
        >
            <div className={"w-full space-y-8"}>
                <div className={"space-y-2"}>
                    <h2 className={'font-bodyPri font-bold  text-lg sm:text-xl md:text-2xl text-text-900 text-left'}>
                        {textPlace?.heading}
                    </h2>
                    <p className={'font-bodyPri font-normal italic text-sm sm:text-base md:text-md text-text-600 text-left'}>
                        {textPlace?.subheading}
                    </p>
                </div>

                <div className={"w-full space-y-2"}>
                    <div
                        className={cn(
                            "relative group w-full h-14 px-3 flex gap-3 items-center border-b focus-within:shadow",
                            errors?.email
                                ? 'border-secondary-dark focus-within:border-secondary-main shadow shadow-secondary-main/20 focus-within:shadow-secondary-main/20'
                                : values?.email
                                    ? 'border-primary-main focus-within:border-primary-main shadow shadow-primary-main/20 focus-within:shadow-primary-main/20'
                                    : 'border-text-400 focus-within:border-primary-main focus-within:shadow-primary-main/20'
                        )}
                    >
                        <MdOutlineMailOutline size={20} className={cn(
                            errors?.email
                                ? "text-secondary-main group-focus-within:text-secondary-main"
                                : values?.email
                                    ? "text-primary-main group-focus-within:text-primary-main"
                                    : "text-text-600 group-focus-within:text-primary-main"
                        )} />
                        <input
                            type={"email"}
                            placeholder={textPlace?.placeholder?.email}
                            className={cn(
                                'w-full h-full bg-transparent outline-none',
                                'font-bodyPri font-normal text-text-900 text-base')}
                            {...register("email")}
                        />
                    </div>
                    {errors?.email && (
                        <span className={"font-bodyPri font-normal text-xs text-secondary-main text-left transition-all ease-in-out duration-200"}>
                            {errors?.email?.message}
                        </span>
                    )}
                </div>
            </div>

            <div className={"w-full flex flex-row items-center justify-center gap-3"}>
                <motion.button
                    disabled={isSubmitting || isLoading}
                    whileHover={{ scale: !(isSubmitting || isLoading) ? 1.01 : 1, y: !(isSubmitting || isLoading) ? -3 : 0 }}
                    whileTap={{ scale: 1, y: !(isSubmitting || isLoading) ? 3 : 0 }}
                    className={cn(
                        "w-2/3 md:w-1/3 h-10 md:h-14 flex items-center justify-center border-2",
                        "transition-all ease-in-out duration-100",
                        "font-buttons font-medium text-lg rounded-full",
                        (isSubmitting || isLoading)
                            ? "text-text-50 bg-primary-dark cursor-wait"
                            : "text-primary-dark border-primary-dark hover:text-text-50 hover:bg-primary-dark cursor-pointer"
                    )}
                >
                    {(isSubmitting || isLoading)
                        ? <ButtonLoader />
                        : (
                            <span className={"inline-flex items-center justify-center"}>
                                {'Next'}
                                <MdChevronRight size={28} className={"hidden sm:inline-flex"} />
                            </span>
                        )}
                </motion.button>
            </div>
            <div className={"w-full text-right"}>
                {errors?.root && (
                    <span className={"font-bodyPri font-normal text-xs text-secondary-main text-left transition-all ease-in-out duration-200"}>
                        {errors?.root?.message}
                    </span>
                )}
            </div>
            <div className={"w-full text-left"}>
                <p className={"font-bodyPri font-normal text-sm text-text-700"}>By signing up, you agree to <a href={"https://www.edulyte.com/terms-of-service/"} target='_blank' className='cursor-pointer text-primary-dark hover:underline'>Terms of service</a> and <a href={"https://www.edulyte.com/privacy-policy/"} target='_blank' className='cursor-pointer text-primary-dark hover:underline'>Privacy Policy</a>. Rest assured, we will not spam you.</p>
            </div>
        </form>
    )
}

export default IdentifierForm