import { Link } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { navigationButtons } from "pages/auth/onboarding/data";


const PreviousDashboardBtn = () => {

    return (
        <div className={"px-5 py-8 w-full flex flex-col items-center justify-center gap-5 md:flex-row md:items-center md:justify-between md:pb-32 md:gap-3"}>
            {navigationButtons?.map((navButton, index) => (
                <Link
                    key={index}
                    to={navButton?.url}
                    className={cn(
                        "w-56 sm:w-48 px-2 py-1 flex items-center justify-center gap-1 rounded-lg",
                        "font-bodyPri font-medium text-xl cursor-pointer",
                        "border-2 rounded-xl border-primary-main text-primary-main  hover:bg-primary-main hover:text-text-50"
                    )}
                    title={navButton?.title}
                >
                    <span className={""}>
                        {navButton?.title}
                    </span>
                </Link>
            ))}

        </div>
    )
}

export default PreviousDashboardBtn