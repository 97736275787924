import { cn } from "utils/cn.utils";
import { Link, useNavigate, useParams } from "react-router-dom";

import { OptionSelector } from "components/common-components/Select";



export default function MySettingsNavigation({
    options,
}) {
    const params = useParams();
    const pathParam = params["*"];
    const selectOptions = options.map((option) => ({
        label: option.label,
        value: option.to,
        roles: option.roles
    }));
    const navigate = useNavigate();

    return (
        <div className="w-full min-h-full p-5 bg-white rounded-lg shadow-sm md:w-60">
            <div className="md:hidden">
                <OptionSelector
                    options={selectOptions}
                    onChange={(op) => {
                        navigate(`.${op.value}`);
                    }}
                    value={`/${pathParam}`}
                />
            </div>

            <div className={"flex-col hidden gap-3 md:flex"}>
                {options.map((option, idx) => (
                    <Link
                        key={idx}
                        to={`.${option.to}`}
                        className={cn(
                            "px-3 py-2 text-base text-center rounded-md",
                            "hover:bg-blue-300 hover:text-white",
                            pathParam === option.to.slice(1) ? "bg-blue-500 text-white" : ""
                        )}
                    >
                        {option.label}
                    </Link>
                ))}
            </div>
        </div>
    );
}
