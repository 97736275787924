import { useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { AiOutlineCheckCircle } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";

import ComponentLoader from "components/loader/ComponentLoader";

import { profileEditBtnConst } from 'pages/auth/onboarding/data';
import SaveCancelBtn from 'pages/auth/onboarding/commonComponents/onboardingButtons/SaveCancelBtn';
import AddBorderButton from "pages/auth/onboarding/commonComponents/onboardingButtons/AddBorderButton";

import {
    createUserEducationDetailByUserId,
    deleteEducationDetailByUserEducationId,
    updateEducationDetailByUserEducationId
} from "redux/user/user.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearAddUserEducationDetail, setClearUserEducationList } from "redux/user/user.slice";

const qualificationReducer = (state, action) => {
    switch (action.type) {
        case "SHOW_ADD_BTN":
            return { ...state, isShowAddBtn: action.payload }
        case "EDUCATION_LIST":
            return { ...state, educationList: action.payload }
        case "ADD_EDUCATION":
            return { ...state, education: action.payload }
        case "IS_UPDATE_ENABLED":
            return { ...state, isUpdateEnabled: action.payload }
        case "INITIAL_STATE":
            return { ...state, isUpdateEnabled: false, education: { education: "", masterEducationId: null } }

        default:
            return state;
    }
}

const QualificationExpertise = ({ editBtnConst, setEditBtnConst }) => {
    const { user, userEducationList, addUserEducationDetail } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()

    const [state, dispatch] = useReducer(qualificationReducer, {
        isUpdateEnabled: false,
        education: {
            education: "",
            id: null
        }
    })

    useEffect(() => {
        return () => {
            // dispatcher(setClearUserEducationList())
            dispatcher(setClearAddUserEducationDetail())
        }
    }, [])

    // useEffect(() => {
    //     if (user?.user?.userId) {
    //         dispatcher(getUserEducationListByUserId(user?.user?.userId))
    //     }
    // }, [user?.user?.userId])


    const onHandleSave = () => {

        dispatch({
            type: "INITIAL_STATE",
            payload: null
        })
        setEditBtnConst(null)
    }

    const onHandleCancel = () => {

        dispatcher(setClearAddUserEducationDetail())

        dispatch({
            type: "INITIAL_STATE",
            payload: null
        })

        setEditBtnConst(null)
    }

    const onHandleAddEducation = () => {
        // dispatch({
        //     type: "SHOW_ADD_BTN",
        //     payload: editBtnConst === profileEditBtnConst?.QUALIFICATIONS?.value
        // })
        if (!editBtnConst || editBtnConst !== profileEditBtnConst?.QUALIFICATIONS?.value) {
            setEditBtnConst(profileEditBtnConst?.QUALIFICATIONS?.value)
        }
    }

    const onHandleChange = (value) => {
        if (!editBtnConst || editBtnConst !== profileEditBtnConst?.QUALIFICATIONS?.value) {
            setEditBtnConst(profileEditBtnConst?.QUALIFICATIONS?.value)
        }
        dispatch({
            type: "ADD_EDUCATION",
            payload: { ...state.education, education: value }
        })
    }

    const onHandleUpdateUserEducation = () => {

        dispatcher(updateEducationDetailByUserEducationId(
            state.education.id,
            {
                education: state.education.education
            }
        ))
        dispatch({
            type: "INITIAL_STATE",
            payload: null
        })
        setEditBtnConst(null)

    }

    const onHandleAddNewEducation = () => {
        if (!state?.education?.education) {
            toast.warn("Please add valid qualification!")
            return;
        }
        if (state.isUpdateEnabled) {
            onHandleUpdateUserEducation()
            return;
        }

        const filteredEducationList = userEducationList?.userEducationList?.filter((education) => (
            education?.education === state?.education?.education
        ))

        if (filteredEducationList?.length > 0) {
            toast.warn("Duplicate Entries are not allowed")
            return;
        }
        dispatcher(createUserEducationDetailByUserId(user?.user?.userId, { education: state.education.education }))
        dispatch({
            type: "INITIAL_STATE",
            payload: null
        })

    }

    const onHandleKeyDown = (e) => {
        if (editBtnConst && editBtnConst !== profileEditBtnConst?.QUALIFICATIONS?.value) return;

        if ((e.keyCode === 9 || e.keyCode === 13) && state.education.education.trim()) {
            onHandleAddNewEducation()
        }
    }

    const onHandleEditEducation = (userEducation) => {
        dispatch({
            type: "IS_UPDATE_ENABLED",
            payload: true
        })

        dispatch({
            type: "ADD_EDUCATION",
            payload: { ...state.education, education: userEducation?.education, id: userEducation?.userEducationId }
        })
        setEditBtnConst(profileEditBtnConst?.QUALIFICATIONS?.value)
    }

    const onHandleRemoveEducation = (userEducation) => {
        if (addUserEducationDetail?.isLoading) return;

        dispatcher(deleteEducationDetailByUserEducationId(userEducation?.userEducationId))
    }

    return (
        <div className='w-full flex flex-col items-start justify-start gap-1'>
            <div className={"w-full flex items-center justify-between gap-2"}>
                <div className='font-bodyPri font-medium text-text-900 text-base tracking-wide'>
                    {"Qualifications: Expertise & Credentials"}
                </div>
                {!userEducationList?.isLoading &&
                    <SaveCancelBtn
                        isShowBtn={editBtnConst === profileEditBtnConst?.QUALIFICATIONS?.value || addUserEducationDetail?.isLoading}
                        onHandleSave={onHandleSave}
                        onHandleCancel={onHandleCancel}
                    />
                }
            </div>

            {userEducationList?.isLoading &&
                <ComponentLoader isLoading={userEducationList?.isLoading} />
            }
            {(!userEducationList?.isLoading && userEducationList?.message) &&
                <div className={"flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {userEducationList?.message}
                    </span>
                </div>
            }

            {!userEducationList?.isLoading && (!editBtnConst || editBtnConst !== profileEditBtnConst?.QUALIFICATIONS?.value) && (!userEducationList?.userEducationList || userEducationList?.userEducationList?.length === 0) &&
                <AddBorderButton btnTitle={"Add Qualification(s)"} onHandleAdd={onHandleAddEducation} />
            }

            {(!userEducationList?.isLoading && (editBtnConst === profileEditBtnConst?.QUALIFICATIONS?.value || userEducationList?.userEducationList?.length > 0)) &&
                <div className={"w-full px-3 py-3 border border-divider-medium rounded-lg space-y-3"}>
                    {addUserEducationDetail?.isLoading &&
                        <ComponentLoader isLoading={addUserEducationDetail?.isLoading} />
                    }
                    {(!addUserEducationDetail?.isLoading && userEducationList?.userEducationList?.length > 0) &&
                        <div className={"w-full space-y-3"}>
                            {userEducationList?.userEducationList?.map((education, index) => (
                                <div key={index} className={"grid grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-2"}>
                                    <div className={"col-start-1 col-span-1 flex items-center justify-start gap-3"}>
                                        <div className={"w-fit px-3 py-1 border border-divider-lightDark rounded-lg overflow-hidden"}>
                                            <span className={"font-bodyPri font-medium text-text-900 text-sm tracking-wide line-clamp-1"}>
                                                {education?.education}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={"col-span-1 flex items-center justify-start gap-1.5"}>
                                        <FiEdit
                                            title={"Edit"}
                                            className={"text-lg text-text-500 cursor-pointer"}
                                            onClick={() => onHandleEditEducation(education)}
                                        />
                                        <MdOutlineDeleteOutline
                                            title={"Delete"}
                                            className={"text-xl text-text-500 cursor-pointer"}
                                            onClick={() => onHandleRemoveEducation(education)}
                                        />
                                    </div>

                                    <div className={"col-start-1 col-span-full h-0.5 border border-divider-medium"}>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }


                    <div className={"flex items-center justify-start gap-1.5"}>
                        <input
                            type={"text"}
                            className={cn(
                                "px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                            )}
                            placeholder={"e.g. Masters, Graduate"}
                            value={state?.education?.education && state?.education?.education?.charAt(0)?.toUpperCase() + (state?.education?.education?.slice(1))}
                            onChange={(e) => onHandleChange(e.target.value)}
                            onKeyDown={onHandleKeyDown}
                        // disabled={editBtnConst !== profileEditBtnConst?.QUALIFICATIONS?.value}
                        />
                        <AiOutlineCheckCircle
                            className={"text-2xl text-text-800 cursor-pointer"}
                            onClick={onHandleAddNewEducation}
                        />
                    </div>
                    {addUserEducationDetail?.errorMsg &&
                        <div className={"flex items-center justify-start gap-2"}>
                            <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                                {addUserEducationDetail?.errorMsg}
                            </span>
                        </div>
                    }
                </div>

            }

        </div>
    )
}

export default QualificationExpertise