import { createSlice } from "@reduxjs/toolkit";
import { WALLET_INITIAL_STATE } from "redux/wallet/wallet.initialState";


const wallet = createSlice({
    name: "wallet",
    initialState: WALLET_INITIAL_STATE,
    reducers: {
        // Actions for add credit to wallet
        setAddCreditToWalletLoading: (state, { payload }) => {
            state.addCreditToWallet.isLoading = payload
        },
        setAddCreditToWallet: (state, { payload }) => {
            state.addCreditToWallet.addCreditToWallet = payload
        },
        setAddCreditToWalletErrorMsg: (state, { payload }) => {
            state.addCreditToWallet.errorMsg = payload
            state.addCreditToWallet.addCreditToWallet = WALLET_INITIAL_STATE.addCreditToWallet.addCreditToWallet
        },
        setClearAddCreditToWallet: (state) => {
            state.addCreditToWallet.isLoading = WALLET_INITIAL_STATE.addCreditToWallet.isLoading
            state.addCreditToWallet.addCreditToWallet = WALLET_INITIAL_STATE.addCreditToWallet.addCreditToWallet
            state.addCreditToWallet.errorMsg = WALLET_INITIAL_STATE.addCreditToWallet.errorMsg
        },
        setAddCreditPayload: (state, { payload }) => {
            state.addCreditToWallet.addCreditPayload = payload
        },
        setClearAddCreditPayload: (state) => {
            state.addCreditToWallet.addCreditPayload = WALLET_INITIAL_STATE.addCreditToWallet.addCreditPayload
        },

        // Actions for tokens to wallet
        setAddTokensLoading: (state, { payload }) => {
            state.addTokens.isLoading = payload
        },
        setAddTokens: (state, { payload }) => {
            state.addTokens.addTokenToWallet = payload
        },
        setAddTokensErrorMsg: (state, { payload }) => {
            state.addTokens.errorMsg = payload
            state.addTokens.addTokenToWallet = WALLET_INITIAL_STATE.addTokens.addTokenToWallet
        },
        setClearAddTokens: (state) => {
            state.addTokens.isLoading = WALLET_INITIAL_STATE.addTokens.isLoading
            state.addTokens.addTokenToWallet = WALLET_INITIAL_STATE.addTokens.addTokenToWallet
            state.addTokens.errorMsg = WALLET_INITIAL_STATE.addTokens.errorMsg
        },
        setAddTokensPayload: (state, { payload }) => {
            state.addTokens.addTokenPayload = payload
        },
        setClearAddTokensPayload: (state) => {
            state.addTokens.addTokenPayload = WALLET_INITIAL_STATE.addTokens.addTokenPayload
        },

        // Reducer for wallet confirmation
        setWalletConfirmationLoading: (state, { payload }) => {
            state.walletConfirmation.isLoading = payload
        },
        setWalletConfirmation: (state, { payload }) => {
            state.walletConfirmation.walletConfirmation = payload
            state.walletConfirmation.errorMsg = WALLET_INITIAL_STATE.walletConfirmation.errorMsg
        },
        setWalletConfirmationErrorMsg: (state, { payload }) => {
            state.walletConfirmation.errorMsg = payload
            state.walletConfirmation.walletConfirmation = WALLET_INITIAL_STATE.walletConfirmation.walletConfirmation
        },
        setClearWalletConfirmation: (state) => {
            state.walletConfirmation = WALLET_INITIAL_STATE.walletConfirmation
        },

        // Actions for wallet txn list
        setWalletTxnListLoading: (state, { payload }) => {
            state.walletTxnList.isLoading = payload
        },
        setWalletTxnList: (state, { payload }) => {
            state.walletTxnList.walletTxnList = payload
        },
        setWalletTxnListErrorMsg: (state, { payload }) => {
            state.walletTxnList.errorMsg = payload
            state.walletTxnList.walletTxnList = WALLET_INITIAL_STATE.walletTxnList.walletTxnList
        },
        setClearWalletTxnList: (state) => {
            state.walletTxnList.isLoading = WALLET_INITIAL_STATE.walletTxnList.isLoading
            state.walletTxnList.walletTxnList = WALLET_INITIAL_STATE.walletTxnList.walletTxnList
            state.walletTxnList.errorMsg = WALLET_INITIAL_STATE.walletTxnList.errorMsg
        },
    }
})

export const {
    setAddCreditToWalletLoading,
    setAddCreditToWallet,
    setAddCreditToWalletErrorMsg,
    setClearAddCreditToWallet,
    setAddCreditPayload,
    setClearAddCreditPayload,

    setAddTokensLoading,
    setAddTokens,
    setAddTokensErrorMsg,
    setAddTokensPayload,
    setClearAddTokensPayload,
    setClearAddTokens,


    setWalletConfirmationLoading,
    setWalletConfirmation,
    setWalletConfirmationErrorMsg,
    setClearWalletConfirmation,

    setWalletTxnListLoading,
    setWalletTxnList,
    setWalletTxnListErrorMsg,
    setClearWalletTxnList,
} = wallet.actions

export default wallet.reducer