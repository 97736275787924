import { useCallback, useState, useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import PageHeader from 'components/pageHeader/PageHeader';
import StatusButton from 'components/common-components/StatusButton';
import Pagination from 'components/pagination/Pagination';
import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';

import LMSTable from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTable';

import { searchParamsInfo, lmsCourseMenuItems, lmsCourseTableHeaderConst, pageHeading } from 'pages/auth/edulyteLms/components/lmsCourse/lmsCourse.data';
import TableFilterActions from './courseFilters/TableFilterActions';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal, setShareSocial } from 'redux/local/local.slice';
import { modalConst, socialAction } from 'redux/local/local.const';
import { lmsCourseStatusEnum } from 'redux/edulyteLms/lmsCourse/lmsCourse.const';

import { pagesInfo } from 'utils/pagesInfo';
import { timeZone, dayjs } from 'utils/dateTime.utils';
import { cn } from "utils/cn.utils";

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';

const LmsCoursePage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { modal, shareSocial } = useAppState((s) => s.local)
  const { lmsCourseList } = useAppState((state) => state.lms.lmsCourse)

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()
  const location = useLocation()

  const [copiedUrl, setCopiedURL] = useState(false)

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.LMS_COURSE_PAGE))
  }, [useAppDispatcher, currentPageInfo])

  const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

  const onHandleSelectMenuItem = useCallback((menuItem, courseItem) => {
    if (menuItem?.value === lmsCourseMenuItems?.INVITE?.value) {
      navigate(`${pagesInfo.EDIT_LMS_COURSE.pagePath}/${courseItem?.id}/edit/participants/invited?action=invite-participants`)
    }
    if (courseItem?.course_setting?.status === lmsCourseStatusEnum?.PUBLISHED?.value) {
      if (menuItem?.value === lmsCourseMenuItems?.SHARE?.value) {
        dispatcher(setShareSocial({
          ...shareSocial,
          socialAction: socialAction.SHARE_LMS_COURSE,
          url: `${EDULYTE_WEB_DOMAIN_URL}/course/${courseItem?.active_slug}`
        }))
        dispatcher(setModal({
          ...modal,
          [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
        }))
      }
      if (menuItem?.value === lmsCourseMenuItems?.COPY?.value) {
        navigator.clipboard.writeText(`${EDULYTE_WEB_DOMAIN_URL}/course/${courseItem?.active_slug}`);
        setCopiedURL(true);
        toast.success("Copied")
        setTimeout(() => {
          setCopiedURL(false);
        }, 2000);
      }
    }
    if (menuItem?.value === lmsCourseMenuItems?.PARTICIPANTS?.value) {
      navigate(`${pagesInfo.EDIT_LMS_COURSE.pagePath}/${courseItem?.id}/edit/participants`)
    }
    if (menuItem?.value === lmsCourseMenuItems?.EDIT?.value) {
      navigate(`${pagesInfo.EDIT_LMS_COURSE.pagePath}/${courseItem?.id}/edit`)
    }
  }, [copiedUrl, modal, shareSocial])

  const onHandleNavigateToCourse = (courseItem) => {
    if (courseItem?.course_setting?.status === lmsCourseStatusEnum?.PUBLISHED?.value) {
      window.open(`${EDULYTE_WEB_DOMAIN_URL}/course/${courseItem?.active_slug}`, "_blank")
    }
  }

  const onHandlePageChange = async (page) => {
    searchQueryParams.set(searchParamsInfo.page.key, page)
    navigate(`${location.pathname}?${searchQueryParams?.toString()}`);
  }

  const CourseTitleContainer = ({ course }) => {
    return (
      <div className='flex flex-col items-start justify-start gap-1'>
        <span
          className={cn(
            'font-bodyPri font-normal text-text-900 text-base line-clamp-1 tracking-wide',
            (course?.course_setting?.status === lmsCourseStatusEnum?.PUBLISHED?.value) && "cursor-pointer hover:text-primary-dark hover:underline"
          )}
          title={course?.title && course?.title}
          onClick={() => onHandleNavigateToCourse(course)}
        >
          {course?.title
            ? (course?.title?.length > 30)
              ? course?.title?.slice(0, 30) + " ..."
              : course?.title
            : "-"
          }
        </span>
      </div>
    )
  }

  const tableRowsConst = lmsCourseList?.data?.results?.map((course) => ([
    <CourseTitleContainer course={course} />,
    <StatusButton
      status={lmsCourseStatusEnum[course?.course_setting?.status?.toUpperCase()]?.label}
      className={`bg-${lmsCourseStatusEnum[course?.course_setting?.status?.toUpperCase()]?.lightColor} text-${lmsCourseStatusEnum[course?.course_setting?.status?.toUpperCase()]?.darkColor}`}
    />,
    dayjs(course?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",
    <div className={"flex items-center justify-start"}>
      <ThreeDotMenu
        iconClassName={"rotate-[90deg]"}
        menuItems={(course?.course_setting?.status === lmsCourseStatusEnum?.PUBLISHED?.value)
          ? Object.values(lmsCourseMenuItems)
          : Object.values(lmsCourseMenuItems)?.filter((menuItem) => ((menuItem?.value !== lmsCourseMenuItems?.COPY?.value) && (menuItem?.value !== lmsCourseMenuItems?.SHARE?.value) && (menuItem?.value !== lmsCourseMenuItems?.INVITE?.value)))
        }
        onHandleSelect={(option) => onHandleSelectMenuItem(option, course)}
      />
    </div>
  ]))

  return (
    <div className={'space-y-3'}>
      <PageHeader
        pageHeading={pageHeading}
        toolTipText={"Create, manage and access all your self-paced courses in one place."}
      />
      <TableFilterActions />
      <LMSTable
        isLoading={lmsCourseList?.isLoading}
        headers={lmsCourseTableHeaderConst}
        rows={tableRowsConst}
        alternateRowColor={"bg-sky-100"}
        rowHeaderColor={"bg-sky-200"}
      />
      {(!lmsCourseList?.isLoading && (lmsCourseList?.data?.totalPages > 1)) &&
        <div className={'w-full flex items-center justify-center'}>
          <Pagination
            page={lmsCourseList?.data?.page}
            totalPages={lmsCourseList?.data?.totalPages}
            onChangePage={onHandlePageChange}
          />
        </div>
      }
      {(!lmsCourseList?.isLoading && lmsCourseList?.message) &&
        <div className={'w-full flex items-center justify-center'}>
          <span className={'font-bodyPri font-normal text-red-500 text-base text-center'}>
            {lmsCourseList.message}
          </span>
        </div>
      }
    </div>
  )
}

export default LmsCoursePage;