import { useState } from "react";

import { useAppState } from "hooks/useStore";

const About = () => {
    const { companyPublicProfile } = useAppState((state) => state.company)
    const [companyAboutLength, setCompanyAboutLength] = useState(180)

    const handleSeeMoreAbout = () => {
        setCompanyAboutLength(companyPublicProfile?.companyPublicProfile?.bio?.length)
    }
    const handleSeeLessAbout = () => {
        setCompanyAboutLength(180)
    }

    return (
        <div className={"flex items-center justify-center px-5 md:px-0"} id={"ABOUT"}>
            <div className={"relative max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
                <div className={"p-5 space-y-3 bg-white"}>
                    <div className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                        {"About"}
                    </div>
                    <div className={"font-bodyPri font-normal text-text-700 text-base"}>
                        {companyPublicProfile?.companyPublicProfile?.bio?.slice(0, companyAboutLength)}
                        {companyPublicProfile?.companyPublicProfile?.bio?.length > companyAboutLength &&
                            <span
                                className={"text-secondary-main cursor-pointer"}
                                onClick={handleSeeMoreAbout}
                            >
                                {"....see more"}
                            </span>
                        }
                        {companyPublicProfile?.companyPublicProfile?.bio?.length <= companyAboutLength &&
                            <span
                                className={"text-secondary-main cursor-pointer"}
                                onClick={handleSeeLessAbout}
                            >
                                {" see less"}
                            </span>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About