import BasicDetail from './components/BasicDetail';
import ElevatorPitch from "./components/ElevatorPitch";
import AboutMe from "./components/AboutMe";
import LanguagesSpeak from "./components/LanguagesSpeak";
import QualificationExpertise from './components/QualificationExpertise';

const MyAboutStory = ({ editBtnConst, setEditBtnConst }) => {

    return (
        <div className={"space-y-5 py-3"}>
            <BasicDetail editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />
            <ElevatorPitch editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />
            <AboutMe editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />
            <LanguagesSpeak editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />
            <QualificationExpertise editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />
        </div>
    )
}

export default MyAboutStory;