import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { preferencesTabConst, validateAvailabilityDetail, validateUserAvailabilityDetail } from "pages/auth/preferences/data";
import AvailabilityHeader from "pages/auth/preferences/commonComponents/AvailabilityHeader";
import RecurringAvailabilityCard from 'pages/auth/preferences/commonComponents/availabilityRecurring/RecurringAvailabilityCard';
import SaveAndResetButtons from "pages/auth/preferences/commonComponents/preferenceButtons/SaveAndResetButtons";

import BasicInformation from "./BasicInformation";
import NonRecurringSchedule from "./NonRecurringSchedule";
import StartEndDateSchedule from "./StartEndDateSchedule";
import MoreAvailabilityDetail from "./MoreAvailabilityDetail";

import { createUserAvailabilityDetail } from "redux/availability/availability.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetAddUserAvailabilityDetail, resetAddUserAvailabilityDetailPayload, resetUserAvailabilityDetail, setAddUserAvailabilityDetailPayload } from "redux/availability/availability.slice";
import { availabilityIsRecurringEnum } from "redux/availability/availability.const";

import { weekdays } from 'utils/dateTime.utils';
import { pagesInfo } from "utils/pagesInfo";

const CreateAvailabilityDetailPage = () => {
    const { user } = useAppState((state) => state.user)
    const { addUserAvailabilityDetail } = useAppState((s) => s.availability)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    useEffect(() => {
        return () => {
            dispatcher(resetAddUserAvailabilityDetail())
            dispatcher(resetAddUserAvailabilityDetailPayload())
        }
    }, [])

    useEffect(() => {
        if (addUserAvailabilityDetail?.data) {
            const availabilityId = addUserAvailabilityDetail?.data?.result?.id
            dispatcher(resetAddUserAvailabilityDetail())
            dispatcher(resetAddUserAvailabilityDetailPayload())
            navigate(`${pagesInfo.PREFERENCES.pagePath}${preferencesTabConst.AVAILABILITY.pagePath}/${availabilityId}/edit`, { replace: true })
        }
    }, [addUserAvailabilityDetail?.data])

    const saveWeeklyAvail = async (localWeeklyAvail) => {
        if (addUserAvailabilityDetail?.isLoading) return;

        dispatcher(setAddUserAvailabilityDetailPayload({
            ...addUserAvailabilityDetail?.payload,
            weeklyTimeSlots: localWeeklyAvail
        }))
    }

    const onHandleResetAvailabilityDetail = useCallback(() => {
        if (addUserAvailabilityDetail?.isLoading) return;

        dispatcher(resetAddUserAvailabilityDetail())
        dispatcher(resetAddUserAvailabilityDetailPayload())
    }, [addUserAvailabilityDetail?.isLoading])

    const onHandleSaveAvailabilityDetail = async () => {
        if (addUserAvailabilityDetail?.isLoading) return;

        const { requestPayload, errorMsg } = await validateUserAvailabilityDetail(addUserAvailabilityDetail?.payload)

        if (errorMsg) {
            toast.warn(errorMsg)
            return;
        } if (requestPayload) {
            let requestDataPayload = { userId: user?.user?.userId, ...requestPayload }
            dispatcher(createUserAvailabilityDetail(requestDataPayload))
        }
    }

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <AvailabilityHeader headerText={"Create Availability"} />
            <div className={"w-full flex flex-col mx-auto container max-w-6xl gap-5 sm:gap-10"}>
                <BasicInformation />
                <div className={"h-0.5 bg-divider-medium w-full"}></div>

                <div className={"w-full flex flex-col max-w-6xl gap-10"}>
                    {([availabilityIsRecurringEnum.RECURRING.value]?.includes(addUserAvailabilityDetail?.payload?.isRecurring) && addUserAvailabilityDetail?.payload?.weeklyTimeSlots) &&
                        <>
                            {weekdays.map((day, index) => (
                                <RecurringAvailabilityCard
                                    key={index}
                                    localDay={day}
                                    weeklyAvailability={addUserAvailabilityDetail?.payload?.weeklyTimeSlots}
                                    saveWeeklyAvail={saveWeeklyAvail}
                                />
                            ))}
                            <div className={"h-0.5 bg-divider-medium w-full"}></div>

                            <StartEndDateSchedule />
                        </>
                    }
                    {[availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(addUserAvailabilityDetail?.payload?.isRecurring) &&
                        <NonRecurringSchedule />
                    }
                    <MoreAvailabilityDetail />
                </div>

                {addUserAvailabilityDetail?.message &&
                    <div className={"flex items-center justify-center"}>
                        <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                            {addUserAvailabilityDetail?.message || "No Availability Found!"}
                        </span>
                    </div>
                }
                <SaveAndResetButtons
                    isSaveBtnLoading={addUserAvailabilityDetail?.isLoading}
                    saveBtnDisabled={!addUserAvailabilityDetail?.payload?.name}
                    resetBtnDisabled={addUserAvailabilityDetail?.isLoading}
                    onHandleSave={onHandleSaveAvailabilityDetail}
                    onHandleReset={onHandleResetAvailabilityDetail}
                />
            </div>
        </div>
    );
}

export default CreateAvailabilityDetailPage;