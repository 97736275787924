import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
// import html2canvas from "html2canvas";
// import { jsPDF } from "jspdf";

import { cn } from "utils/cn.utils";

import { MdArrowBack } from "react-icons/md";
import { FaSpinner } from 'react-icons/fa'

import ArticleView from '../article'
import ResourceView from '../resource'
import QuestionView from '../question'

import { deleteLmsQuizResponseDetail } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { lmsQuizCategoryEnum } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.const'


const ResultView = ({ quiz, quiz_response }) => {
    const { deleteLmsQuizResponse, updateLmsQuizResponse } = useAppState(state => state.lms.lmsQuiz)

    const [isViewResponse, setIsViewResponse] = useState(false)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const resultRef = useRef()

    const sortQuizSection = (section1, section2) => section1?.order > section2?.order ? 1 : -1
    let sections = [...quiz?.quiz_sections]?.sort(sortQuizSection)

    const shortQuizCategory = (quiz_category1, quiz_category2) => quiz_category1?.order > quiz_category2?.order ? 1 : -1

    const questions = quiz?.quiz_sections?.reduce((initial, section) => [...initial, ...section?.quiz_categories?.filter(category => category?.type === lmsQuizCategoryEnum.QUESTION.value)], [])

    const onHandleClearResponse = () => {
        if (deleteLmsQuizResponse?.isLoading || updateLmsQuizResponse?.isLoading) return;

        dispatcher(deleteLmsQuizResponseDetail(quiz_response?.id))
    }

    const onHandleBack = () => {
        setIsViewResponse(false)
    }

    const handleOnBack = () => {
        navigate(-1)
    }

    const onHandleSavePdf = async () => {
        window.print()
        return;

        // if (!resultRef?.current) return
        // try {
        //     const canvas = await html2canvas(resultRef?.current)
        //     const imgData = canvas.toDataURL('image/png');

        //     const pdf = new jsPDF({
        //         orientation: "portrait",
        //         unit: "px",
        //         format: "a4"
        //     })

        //     const width = pdf.internal.pageSize.getWidth()
        //     const height = (canvas.height * width) / canvas.width

        //     pdf.addImage(imgData, 'PNG', 0, 0, width, height);
        //     pdf.save(`${quiz?.active_slug}.pdf`);
        // } catch (error) { 
        //     console.error("Error", error)
        // }
    }

    return (
        <div className={"space-y-8"}>

            {/* thank you message */}
            {!isViewResponse && (
                <div className={"w-full flex flex-col items-center justify-center gap-10 relative"}>
                    {/* <h2 className={"font-tagLine font-semibold text-3xl text-secondary-dark"}>
                        {"Great, you answered all questions!"}
                    </h2> */}
                    {/* Score Section */}
                    {!!quiz_response?.total_marks && (
                        <div className={"w-full flex flex-col gap-5 mt-20"}>
                            <p className={"font-bodyPri font-bold text-5xl text-text-800 text-center"}>
                                {"Your Score"}
                            </p>
                            <div className={"space-y-3"}>
                                <p className={"font-bodyPri font-bold text-9xl text-green-500 text-center"}>
                                    {`${100 * (quiz_response?.obtain_marks / quiz_response?.total_marks)?.toFixed(2)}%`}
                                </p>
                                <div className={"w-full flex items-center justify-center gap-10"}>
                                    <span className={"font-bodyPri font-medium text-lg text-text-900 text-left"}>
                                        {"Points"}
                                    </span>
                                    <span className={"font-bodyPri font-medium text-lg text-text-900 text-left"}>
                                        {`${quiz_response?.obtain_marks} / ${quiz_response?.total_marks}`}
                                    </span>
                                </div>
                                <div className={"w-full flex items-center justify-center gap-10"}>
                                    <span className={"font-bodyPri font-medium text-lg text-text-900 text-left"}>
                                        {"Answered"}
                                    </span>
                                    <span className={"font-bodyPri font-medium text-lg text-text-900 text-left"}>
                                        {`${quiz_response?.quiz_question_responses?.length} / ${questions?.length || 0}`}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={"flex items-center justify-center gap-5"}>
                        <button
                            className={"px-5 py-2 hover:bg-background-darkLight border-2 border-divider-darkLight font-buttons font-medium text-md text-text-800 hover:text-text-900"}
                            onClick={() => handleOnBack()}
                        >
                            {"Go Back"}
                        </button>
                        <button
                            className={"px-5 py-2 bg-primary-dark hover:opacity-90 font-buttons font-medium text-md text-text-50"}
                            onClick={() => setIsViewResponse(true)}
                        >
                            {"View Feedback"}
                        </button>
                    </div>
                </div>
            )}

            {/* section */}
            {isViewResponse && (
                <>
                    <div className={"mb-10"}>
                        <button className={"flex items-center justify-center gap-2 text-text-800 hover:text-text-700 group"}
                            onClick={onHandleBack}
                        >
                            <MdArrowBack className={"text-2xl text-text-600 group-hover:text-text-700"} />
                            <span className={"font-buttons font-medium text-xl"}>{"Back"}</span>
                        </button>
                    </div>
                    <div ref={resultRef} className={"flex flex-col gap-10 bg-white"}>
                        {sections?.map((section, index) => {
                            let questionNo = sections?.slice(0, index)?.reduce((initial, section) => [...initial, ...section?.quiz_categories?.filter(category => category?.type === lmsQuizCategoryEnum.QUESTION.value)], [])?.length || 0

                            return (
                                <div className={"w-full flex flex-col gap-3"}>
                                    <div className={"relative px-3 py-2 rounded-lg border-t-8 border-primary-dark bg-white shadow-all"}>
                                        {(sections?.length > 1) && (
                                            <span className={"absolute z-1 -top-8 -left-0 px-3 py-1 rounded-t-lg bg-primary-dark font-bodyPri font-normal text-base text-text-50"}>
                                                {`Section ${(index + 1)} of ${sections?.length}`}
                                            </span>
                                        )}
                                        <h4 className={"font-bodyPri font-semibold text-xl text-text-800"}>{section?.title}</h4>
                                        {section?.description && (
                                            <span className={"font-bodyPri font-normal text-base text-text-800"}>
                                                {section?.description}
                                            </span>
                                        )}
                                    </div>
                                    <div className={"w-full flex flex-col gap-3"}>
                                        {[...section?.quiz_categories]?.sort(shortQuizCategory)?.map((category, index) => {
                                            questionNo = category?.type === lmsQuizCategoryEnum.QUESTION.value ? (questionNo + 1) : questionNo
                                            return (
                                                <div key={index} className={"w-full p-5 rounded-lg shadow-all bg-white"}>
                                                    {category?.type === lmsQuizCategoryEnum.ARTICLE.value && (
                                                        <ArticleView article={category?.article?.article} />
                                                    )}
                                                    {![lmsQuizCategoryEnum.QUESTION.value, lmsQuizCategoryEnum.ARTICLE.value].includes(category?.type) && (
                                                        <ResourceView resource={category?.resource?.resource} />
                                                    )}
                                                    {category?.type === lmsQuizCategoryEnum.QUESTION.value && (
                                                        <QuestionView
                                                            question={{ ...category?.question?.question, questionNo: questionNo }}
                                                            quiz_response={quiz_response}
                                                        />
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>
                            )
                        })}
                    </div>

                    <hr className={"h-0.5 w-full bg-text-300"} />

                    <div className={"w-full flex flex-row items-center justify-between gap-5"}>
                        <div className={"flex flex-col sm:flex-row items-center justify-center gap-5"}>
                            <button className={cn(
                                "flex flex-nowrap items-center justify-center gap-2 font-buttons font-medium text-md text-primary-dark hover:opacity-90"
                            )}
                                onClick={onHandleBack}
                                disabled={updateLmsQuizResponse?.isLoading || deleteLmsQuizResponse?.isLoading}
                            >
                                {"Back"}
                            </button>
                            <button className={cn(
                                "flex flex-nowrap items-center justify-center gap-2 font-buttons font-medium text-md text-primary-dark hover:opacity-90"
                            )}
                                onClick={onHandleSavePdf}
                            >
                                {"Save PDF"}
                            </button>
                        </div>
                        <button className={cn(
                            "flex flex-nowrap items-center justify-center gap-2 font-buttons font-medium text-md text-primary-dark hover:opacity-90"
                        )}
                            onClick={onHandleClearResponse}
                            disabled={updateLmsQuizResponse?.isLoading || deleteLmsQuizResponse?.isLoading}
                        >
                            {deleteLmsQuizResponse?.isLoading ? "Loading... " : "Clear Response"}
                            {deleteLmsQuizResponse?.isLoading && <FaSpinner className={"text-xl animate-spin text-primary-dark"} />}
                        </button>
                    </div>
                </>
            )}

            <div id="myMm" style={{ height: "1mm" }} />
        </div>
    )
}

export default ResultView