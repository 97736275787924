import { useEffect, useState } from 'react';
import { cn } from "utils/cn.utils";

import { FaKeyboard } from 'react-icons/fa';

import { PageContentStyle } from 'pages/auth/meeting/MeetingPage.style';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { pagesInfo } from 'utils/pagesInfo';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { EDULYTE_MEET_DOMAIN_URL } from 'const/default.const';

import { useTitle } from 'hooks/useTitle';

const meetingPageImage = "https://images.unsplash.com/photo-1531545514256-b1400bc00f31?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"


const MeetingPage = () => {
    const { currentPageInfo } = useAppState((s) => s.pageInfo)

    const dispatcher = useAppDispatcher()
    const [title, setTitle] = useTitle()

    const [meetingInput, setMeetingInput] = useState('')

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.MEETING))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Video Meeting App | Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    const handleJoinMeetingRoom = () => {
        if (!meetingInput) return;
        let code = meetingInput;
        // if (["http", "https"].includes(meetingInput.split(":")[0])) {
        //     let meetingInputArray = meetingInput.split("/")
        //     code = meetingInputArray[meetingInputArray.length - 1]
        // }
        if (meetingInput.includes(EDULYTE_MEET_DOMAIN_URL)) {
            let meetingInputArray = meetingInput.split("/")
            code = meetingInputArray[meetingInputArray.length - 1]
        }

        window.open(`${EDULYTE_MEET_DOMAIN_URL}/${code}`, "_blank")
        return;
    }

    return (
        <PageContentStyle>
            <div className={"w-full grid grid-cols-12 items-center gap-10 lg:gap-0"}>
                <div className={"col-start-2 col-span-10 lg:col-start-2 lg:col-span-4 flex flex-col items-center lg:items-start gap-8"}>
                    <div className={"flex flex-col items-start gap-5"}>
                        <span className={"font-tagLine font-semibold text-4xl text-text-800 text-center lg:text-left"}>
                            {"Premium video meetings"}
                        </span>
                        <span className={"font-bodyPri font-base text-lg text-text-700 text-center lg:text-left"}>
                            {"Securely connect and collaborate anywhere, anytime."}
                        </span>
                    </div>
                    <div className={"flex flex-col md:flex-row items-center justify-start gap-5 group"}>
                        <div className={cn(
                            "flex items-center justify-start gap-2 px-4 py-2 border-2  rounded-md",
                            meetingInput
                                ? "border-primary-main"
                                : "border-text-500 group-focus-within:border-primary-main"
                        )}>
                            <FaKeyboard className={"text-lg text-text-500 group-focus:text-text-700"} />
                            <input
                                className={cn(
                                    "font-bodyPri font-normal text-md text-text-800 outline-none",
                                    "placeholder:font-bodyPri placeholder:font-normal placeholder:text-md placeholder:text-text-500"
                                )}
                                type={"text"}
                                placeholder={"Enter meeting id or link"}
                                value={meetingInput}
                                onChange={(event) => setMeetingInput(event.target.value)}
                                onKeyDown={(event) => { if (meetingInput && (event.keyCode === 9 || event.keyCode === 13 || event.keyCode === 32)) handleJoinMeetingRoom() }}

                            />
                        </div>
                        <span onClick={handleJoinMeetingRoom} className={cn(
                            "transition ease-in-out duration-300 delay-150",
                            "font-buttons font-medium text-base",
                            meetingInput
                                ? "flex text-primary-main cursor-pointer"
                                : "hidden group-focus-within:flex group-focus-within:text-text-500",
                        )}>
                            {"Join"}
                        </span>
                    </div>
                </div>
                <div className={"col-start-2 col-span-10 lg:col-start-8 lg:col-span-4 flex flex-col items-center justify-center gap-5"}>
                    <div className={"w-96 h-96 rounded-full overflow-hidden"}>
                        <img src={meetingPageImage} alt={"meeting"} className={"w-full h-full object-cover"} />
                    </div>
                    <div className={"flex flex-col items-center gap-1"}>
                        <span className={"font-bodyPri font-medium text-lg text-text-800 text-center"}>
                            {"Get a link that you can share"}
                        </span>
                        <span className={"font-bodyPri font-normal text-base text-text-700 text-center px-20"}>
                            {"Enter a meeting id or a meeting link to join."}
                        </span>
                    </div>
                </div>
            </div>
        </PageContentStyle>
    )
}

export default MeetingPage