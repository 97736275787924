import { cn } from "utils/cn.utils";

import { FaEye } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { AiOutlineEnter } from 'react-icons/ai';

import ToolTipView from 'components/tooltipView';

const LibraryActionButtons = ({
    isShowViewBtn = false,
    isShowEditBtn = true,
    viewBtnTooltip = "",
    editBtnTooltip = "",
    insertBtnTooltip = "",
    isEnterBtnDisabled = false,
    enterBtnDisabledClassName = "!text-text-400 !bg-background-darkLight !cursor-not-allowed",
    onHandleViewBtn,
    onHandleEditBtn,
    onHandleInsertBtn
}) => {
    return (
        <div className={"w-full flex items-center justify-center"}>
            {isShowViewBtn &&
                <ToolTipView content={viewBtnTooltip}>
                    <button
                        className={"px-2 py-1 bg-background-lightDark text-white rounded-l-md"}
                        onClick={onHandleViewBtn}
                    >
                        <FaEye className={"text-xl"} />
                    </button>
                </ToolTipView>
            }
            {isShowEditBtn &&
                <ToolTipView content={editBtnTooltip}>
                    <button
                        className={"px-2 py-1 bg-background-lightDark text-white rounded-l-md"}
                        onClick={onHandleEditBtn}
                    >
                        <MdEdit className={"text-xl"} />
                    </button>
                </ToolTipView>
            }
            <ToolTipView content={insertBtnTooltip}>
                <button
                    className={cn(
                        "px-2 py-1 bg-primary-dark text-white rounded-r-md",
                        (isEnterBtnDisabled) && enterBtnDisabledClassName
                    )}
                    onClick={onHandleInsertBtn}
                >
                    <AiOutlineEnter className={"text-xl"} />
                </button>
            </ToolTipView>
        </div>
    )
}

export default LibraryActionButtons