import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SaveAndCancelBtn from "pages/auth/createCourse/SaveAndCancelBtn";
import { sortDateTimeSlots } from "pages/auth/createCourse/data";
import DoesNotRepeatScheduleCard from "./DoesNotRepeatScheduleCard";

import { createAvailabilityDetail, updateAvailabilityDetail } from "redux/availability/availability.request";
import { deleteCourseAvailabilityDetail, getCourseAvailabilityDetail } from "redux/course/course.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setAddCourseAvailDetailPayload, setClearAddCourseAvailDetailPayload } from "redux/course/course.slice";
import { courseType, courseIsRecurringEnum, courseStatus, coursePriceTypeEnum } from "redux/course/course.const";

import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';

const DoesNotRepeatSchedule = ({ setIsAddCourseScheduleEnabled }) => {
    const { user } = useAppState((state) => state.user)
    const { userAvailabilityDetail } = useAppState((state) => state.availability)
    const { tutorCourseDetail, courseAvailDetail, addAvailabilityDetail, addCourseAvailDetail, coursePriceDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const todayDate = new Date()
    const dateAfterSevenDays = todayDate.setDate(todayDate.getDate() + 8)

    const isGroupCourseType = tutorCourseDetail?.data?.type === courseType?.GROUP?.value
    const underReviewOrPublishedStatus = [courseStatus?.UNDER_REVIEW?.value, courseStatus?.PUBLISHED?.value]?.includes(tutorCourseDetail?.data?.status)

    const isCourseScheduleSimilar = JSON.stringify({ duration: courseAvailDetail?.courseAvailDetail?.availability?.duration, dateTimeSlots: courseAvailDetail?.courseAvailDetail?.availability?.dateTimeSlots }) === JSON.stringify({ duration: addCourseAvailDetail?.addCourseAvailDetailPayload?.duration * 15, dateTimeSlots: addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots })

    const [current, setCurrent] = useState(dayjs().tz(timeZone))
    const [selectedDateTimeSlot, setSelectedDateTimeSlot] = useState({
        date: dayjs(dateAfterSevenDays)?.tz(timeZone)?.format("YYYY-MM-DD"),
        timeSlots: []
    })
    const [isShowDatePicker, setIsShowDatePicker] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrent(dayjs().tz(timeZone))
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    useEffect(() => {
        if ((addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring === courseIsRecurringEnum.NON_RECURRING?.value) && (addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots?.length === 0)) {
            dispatcher(setAddCourseAvailDetailPayload({
                ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                dateTimeSlots: [selectedDateTimeSlot]
            }))
        }
    }, [addCourseAvailDetail?.addCourseAvailDetailPayload])

    const validateDateTimeSlots = async () => {
        let newDateTimeSlots = []
        addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots?.forEach(dateTime => {
            if (dateTime?.timeSlots?.length === 0) {
                newDateTimeSlots?.push(dateTime?.date)
            }
        })

        if (newDateTimeSlots?.length > 0) {
            return false
        } else {
            return true
        }
    }

    const onHandleDelete = () => {
        if (!courseAvailDetail?.courseAvailDetail) return;

        if (isGroupCourseType && underReviewOrPublishedStatus) return;

        if (!window.confirm("The action will erase any saved schedule, are you sure?")) return;

        if (tutorCourseDetail?.data?.courseAvailability?.id) {
            dispatcher(deleteCourseAvailabilityDetail(tutorCourseDetail?.data?.courseAvailability?.id))
        }
        dispatcher(setClearAddCourseAvailDetailPayload())

    }

    const onHandleCancel = () => {

        if ((isGroupCourseType && underReviewOrPublishedStatus) || isCourseScheduleSimilar) return;
        if (tutorCourseDetail?.data?.courseAvailability?.id) {
            dispatcher(getCourseAvailabilityDetail(tutorCourseDetail?.data?.courseAvailability?.id, { timeZone: timeZone }))
        } else {
            setIsAddCourseScheduleEnabled(false)
            dispatcher(setClearAddCourseAvailDetailPayload())
        }
    }

    const onHandleSave = async (event) => {
        event.preventDefault()
        if (addCourseAvailDetail?.isLoading || addAvailabilityDetail?.isLoading || isCourseScheduleSimilar) return;

        if (isGroupCourseType && underReviewOrPublishedStatus) return;

        if (isGroupCourseType) {
            if (!addCourseAvailDetail?.addCourseAvailDetailPayload?.duration || addCourseAvailDetail?.addCourseAvailDetailPayload?.duration < 1) {
                toast.error("Please enter session duration!")
                return;
            }
        }

        if (addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots?.length === 0) {
            alert("Please add date time slots!")
            return;
        }

        const isDateTimeSlotsValid = await validateDateTimeSlots()

        if (!isDateTimeSlotsValid) {
            alert("Each date must have a timeslot!")
            return;
        }

        if (isGroupCourseType && (addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring === courseIsRecurringEnum?.NON_RECURRING?.value) && (coursePriceDetail?.coursePriceDetail && coursePriceDetail?.coursePriceDetail?.type === coursePriceTypeEnum?.PLAN?.value)) {
            alert("You need to delete plan price first, if you want doesn't repeat schedule for this class!")
            return;
        }

        if (!tutorCourseDetail?.data?.courseAvailability?.id) {
            const body = {
                name: tutorCourseDetail?.data?.title,
                description: tutorCourseDetail?.data?.subTitle,
                type: tutorCourseDetail?.data?.type,
                isRecurring: addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring,
                timeZone: timeZone,
            }
            if (isGroupCourseType) {
                body["duration"] = addCourseAvailDetail?.addCourseAvailDetailPayload?.duration * 15
            }
            if (addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring === courseIsRecurringEnum?.NON_RECURRING?.value) {
                body["dateTimeSlots"] = addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots
            }
            if (isGroupCourseType) {
                body["userId"] = user?.user?.userId
                dispatcher(createAvailabilityDetail(body))
                return;
            } else {
                dispatcher(updateAvailabilityDetail(userAvailabilityDetail?.data?.id, body))
                return;
            }
        }

        if (tutorCourseDetail?.data?.courseAvailability?.id) {
            const body = {
                name: tutorCourseDetail?.data?.title,
                description: tutorCourseDetail?.data?.subTitle,
                type: tutorCourseDetail?.data?.type,
                isRecurring: addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring,
                timeZone: timeZone,
            }
            if (isGroupCourseType) {
                body["duration"] = addCourseAvailDetail?.addCourseAvailDetailPayload?.duration * 15
            }
            if (addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring === courseIsRecurringEnum?.NON_RECURRING?.value) {
                body["dateTimeSlots"] = addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots
            }
            dispatcher(updateAvailabilityDetail(tutorCourseDetail?.data?.courseAvailability?.availability, body))
            return;
        }
    }

    const onHandleAddDate = (date) => {
        if (isGroupCourseType && underReviewOrPublishedStatus) return;

        if (addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots?.length > 0) {
            const filteredDateTimeSlots = addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots?.slice()?.sort(sortDateTimeSlots)?.filter((dateTimeSlot) => dateTimeSlot?.date === dayjs(date)?.format("YYYY-MM-DD"))
            if (filteredDateTimeSlots?.length > 0) {
                toast.warn("Same dates are added more than once!")
                return;
            }
        }
        dispatcher(setAddCourseAvailDetailPayload({
            ...addCourseAvailDetail?.addCourseAvailDetailPayload,
            dateTimeSlots: [
                ...addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots,
                {
                    date: dayjs(date)?.format("YYYY-MM-DD"), timeSlots: []
                }
            ]
        }))
        setIsShowDatePicker(false)
    }

    const saveDateTimeSlots = async (localDateTimeSlots) => {
        if (addAvailabilityDetail?.isLoading || addCourseAvailDetail?.isLoading) return;

        if (isGroupCourseType && underReviewOrPublishedStatus) return;

        dispatcher(setAddCourseAvailDetailPayload({
            ...addCourseAvailDetail?.addCourseAvailDetailPayload,
            dateTimeSlots: localDateTimeSlots
        }))
    }

    return (
        <div className={"grid grid-cols-12 gap-5 w-full"}>

            <div className={"col-start-1 col-span-full lg:col-start-4 lg:col-span-full w-full space-y-3"}>
                <div className={"space-y-1"}>
                    <p className="font-bodyPri font-normal text-sm text-text-700 text-right">
                        {current.format("ddd, DD MMM YY hh:mm:ss A")} - [{timeZone} ({getTimeZoneOffset(timeZone)})]
                    </p>
                    <div className={"h-0.5 bg-divider-medium w-full"}></div>

                </div>
                <div className={"w-full sm:max-w-xl md:max-w-3xl lg:max-w-5xl mx-auto space-y-3"}>
                    {addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots?.length > 0 &&
                        addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots?.slice()?.sort(sortDateTimeSlots)?.map((dateTimeSlot, index) => (
                            <DoesNotRepeatScheduleCard
                                key={index}
                                dateTimeSlots={addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots}
                                dateTimeSlot={dateTimeSlot}
                                saveDateTimeSlots={saveDateTimeSlots}
                            />
                        ))}
                </div>
                {(addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots?.length === 0 || !addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots) &&
                    <div className={"space-y-3"}>
                        <DoesNotRepeatScheduleCard
                            dateTimeSlots={addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots}
                            dateTimeSlot={selectedDateTimeSlot}
                            saveDateTimeSlots={saveDateTimeSlots}
                        />
                    </div>
                }
            </div>

            <div className={"col-start-1 col-span-full lg:col-start-4 lg:col-span-full"}>
                {!isShowDatePicker &&
                    <span className={"font-bodyPri font-normal text-primary-main text-md cursor-pointer"} onClick={() => setIsShowDatePicker(true)}>
                        {"Add a date"}
                    </span>
                }
                {isShowDatePicker &&
                    <DatePicker
                        className={"font-bodyPri font-normal text-text-900 text-base placeholder:text-text-500 placeholder:text-sm focus:outline-none"}
                        dateFormat={"dd/MM/yyyy"}
                        autoFocus={true}
                        value={new Date()}
                        selected={new Date()}
                        minDate={dateAfterSevenDays}
                        placeholderText={"Select Date"}
                        scrollableYearDropdown={true}
                        showYearDropdown={true}
                        yearDropdownItemNumber={80}
                        showMonthDropdown={true}
                        onChange={(date) => onHandleAddDate(date)}
                    />
                }
            </div>

            <div className={"col-start-1 col-span-full lg:col-start-4 lg:col-span-full flex items-center justify-start mt-5"}>
                <SaveAndCancelBtn
                    isLoading={addAvailabilityDetail?.isLoading || addCourseAvailDetail?.isLoading}
                    isShowDeleteBtn={true}
                    isShowCancelBtn={true}
                    isShowResetBtn={false}
                    isSaveBtnDisabled={(isGroupCourseType && underReviewOrPublishedStatus) || isCourseScheduleSimilar}
                    isCancelBtnDisabled={(isGroupCourseType && underReviewOrPublishedStatus) || isCourseScheduleSimilar}
                    isDeleteBtnDisabled={!courseAvailDetail?.courseAvailDetail || (isGroupCourseType && underReviewOrPublishedStatus)}
                    onSave={onHandleSave}
                    onCancel={onHandleCancel}
                    onDelete={onHandleDelete}
                />
            </div>
        </div>
    )
}

export default DoesNotRepeatSchedule;