import { cn } from "utils/cn.utils";
import dayjs from 'dayjs';


const LogItem = ({ logItem, index }) => {

    return (
        <div className={cn("relative", index !== 0 && "pt-8")}>
            <>
                {index !== 0 &&
                    <div aria-hidden="true" className="-ml-px absolute -top-10 left-2 w-0.5 h-full bg-background-medium" />
                }
                <div className="flex items-start gap-5 group cursor-pointer">
                    <span className="flex items-center h-9" aria-hidden="true">
                        <span className={cn(
                            "relative z-10 flex items-center justify-center w-4 h-4 rounded-full border-2",
                            `bg-white border-${logItem.darkColor}`
                        )}>
                            <span className={cn("h-3 w-3 rounded-full", `group-hover:bg-${logItem.darkColor}`)} />
                        </span>
                    </span>
                    <span className="flex flex-col min-w-0 overflow-hidden">
                        <span className={cn("font-bodyPri font-normal text-sm tracking-wide truncate", `text-${logItem.darkColor}`)}>
                            {logItem.title}
                        </span>
                        <span className={cn("font-bodyPri font-normal text-sm tracking-wide text-text-600 truncate")}>
                            {logItem.note}
                        </span>
                        <span className={cn("text-sm font-bodyPri font-normal text-text-600")}>
                            {dayjs(logItem.createdAt).format("ddd, MMM D, YY h:mm A")}</span>
                    </span>
                </div>
            </>
        </div>
    )
}

export default LogItem


// const { value, label, title, status, note, createdAt, lightColor, darkColor } = logItem