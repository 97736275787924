export const socialKey = [
    "facebook",
    "twitterx",
    "linkedin",
    "instagram",
    "whatsapp",
    "edulyte",
    "web-link",
    "pinterest",
    "snapchat",
    "spotify",
    "amazon",
    "threads",
]

export const outsideIcons = ["facebook", "twitterx", "whatsapp", "instagram", "linkedin", "web-link"];

export const menuItemsConst = {
    // MOVE_TOP: {
    //     label: "Move to Top",
    //     value: "move_top"
    // },
    // MOVE_BOTTOM: {
    //     label: "Move to Bottom",
    //     value: "move_bottom"
    // },
    DELETE: {
        label: "Delete",
        value: "delete"
    },
}