import React from 'react';
import Modal from '@mui/material/Modal';
import { IoClose } from 'react-icons/io5';
import styled from 'styled-components';
import tw from 'twin.macro';

const VideoModal = styled(Modal)`
  ${tw`
    flex items-center justify-center
  `}
`;

const ModalContent = styled.div`
  ${tw`
    relative bg-black w-3/4 h-1/2 md:h-3/4 rounded-md overflow-hidden
  `}
`;

const CloseButton = styled.button`
  ${tw`
    absolute top-2 right-2 text-white bg-transparent border-none cursor-pointer
  `}
  &:hover {
    ${tw`
      text-red-500
    `}
  }
`;

const VideoIframe = styled.iframe`
  ${tw`
    w-full h-full
  `}
`;

const VideoOverlay = ({ handleOpen, handleClose, videoId }) => {
  return (
    <VideoModal
      open={handleOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContent>
        <CloseButton onClick={handleClose}>
          <IoClose size={24} />
        </CloseButton>
        <VideoIframe
          title={'Embeded Video'}
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0`}
          allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
          allowFullScreen={"true"}
          loading={"lazy"}
        />
      </ModalContent>
    </VideoModal>
  );
};

export default VideoOverlay;
