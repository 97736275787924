import { memo } from 'react';
import { cn } from "utils/cn.utils";

import { OptionSelector } from 'components/common-components/Select';

import { bulkBookingDiscountConst, onboardingTooltipConst, profileEditBtnConst } from 'pages/auth/onboarding/data';
import OnboardingTooltip from 'pages/auth/onboarding/commonComponents/OnboardingTooltip';

const SessionBookingPrice = memo(({
    editBtnConst,
    setEditBtnConst,
    tutorPricePayload,
    setTutorPricePayload
}) => {

    return (
        <div className={"grid grid-cols-12 gap-5 w-full"}>

            {/* Minimum hourly charges for One-on-One sessions */}
            <div className='col-start-1 col-span-full lg:col-start-1 lg:col-span-6 space-y-1'>
                <div className={"flex items-start md:items-center justify-start gap-1"}>
                    <OnboardingTooltip tooltip={onboardingTooltipConst?.oneOnOneChargeSessionTooltip} />
                    <span className='font-bodyPri font-medium text-text-900 text-base line-clamp-1'>
                        {"Minimum hourly charges for One-on-One sessions"}
                    </span>
                </div>
                <div className={cn(
                    "w-full px-3 py-2 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                    "flex items-center justify-start gap-3"
                )}>
                    <span className="text-gray-500 sm:text-sm">
                        {"USD"}
                    </span>

                    <input
                        type={"number"}
                        className={cn(
                            "w-full input-number-spin-none focus:outline-none",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        value={(tutorPricePayload?.minOneToOneCoursePrice / 100)?.toString()}
                        onChange={(event) => {
                            if (!editBtnConst || editBtnConst !== profileEditBtnConst?.PRICE?.value) {
                                setEditBtnConst(profileEditBtnConst?.PRICE?.value)
                            }
                            let safe_number = Number(event.target.value)
                            if (isNaN(safe_number)) {
                                safe_number = 0
                            }
                            setTutorPricePayload({
                                ...tutorPricePayload,
                                minOneToOneCoursePrice: (safe_number * 100)
                            })
                        }}
                    />
                </div>
            </div>

            {/* Minimum charges for group sessions */}
            <div className='col-start-1 col-span-full lg:col-start-7 lg:col-span-full space-y-1'>
                <div className={"flex items-start justify-start gap-1 md:items-center"}>
                    <OnboardingTooltip tooltip={onboardingTooltipConst?.GroupChargeSessionTooltip} />
                    <span className='font-bodyPri font-medium text-text-900 text-base line-clamp-1'>
                        {"Minimum charges for group sessions"}
                    </span>
                </div>
                <div className={cn(
                    "w-full px-3 py-2 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                    "flex items-center justify-start gap-3"
                )}>
                    <span className="text-gray-500 sm:text-sm">
                        {"USD"}
                    </span>

                    <input
                        type={"number"}
                        className={cn(
                            "w-full input-number-spin-none focus:outline-none",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        value={(tutorPricePayload?.minGroupCoursePrice / 100)?.toString()}
                        onChange={(event) => {
                            if (!editBtnConst || editBtnConst !== profileEditBtnConst?.PRICE?.value) {
                                setEditBtnConst(profileEditBtnConst?.PRICE?.value)
                            }
                            let safe_number = Number(event.target.value)
                            if (isNaN(safe_number)) {
                                safe_number = 0
                            }
                            setTutorPricePayload({
                                ...tutorPricePayload,
                                minGroupCoursePrice: (safe_number * 100)

                            })
                        }}
                    />
                </div>
            </div>

            {/* bulk booking container */}
            <div className='col-start-1 col-span-full lg:col-start-1 lg:col-span-6 space-y-1'>
                <div className={"flex items-center justify-start gap-1"}>
                    <OnboardingTooltip tooltip={onboardingTooltipConst?.bulkBookingTooltip} />
                    <span className='font-bodyPri font-medium text-text-900 text-base line-clamp-1'>
                        {"Discount for bulk booking"}
                    </span>
                </div>
                <div className={"h-36 z-[500]"}>
                    <OptionSelector
                        options={Object.values(bulkBookingDiscountConst)}
                        className={"w-full"}
                        value={tutorPricePayload?.pctDisOnFiveOneToOneSession}
                        onChange={(option) => {
                            if (!editBtnConst || editBtnConst !== profileEditBtnConst?.PRICE?.value) {
                                setEditBtnConst(profileEditBtnConst?.PRICE?.value)
                            }
                            setTutorPricePayload({
                                ...tutorPricePayload,
                                pctDisOnFiveOneToOneSession: option?.value
                            })
                        }}
                    />
                </div>
            </div>
        </div>
    )
})

export default SessionBookingPrice;