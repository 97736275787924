import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";

import FilterBox from 'components/pageFilter/FilterBox';

import { searchParamsInfo } from "../lmsQuiz.data";

import { DEFAULT_LMS_QUIZ_PAGE, lmsQuizStatusEnum } from "redux/edulyteLms/lmsQuiz/lmsQuiz.const";

import { cn } from "utils/cn.utils";

const QuizStatusFilter = memo(({ filterItem, activeFilter, setActiveFilter }) => {

    const navigate = useNavigate()
    const location = useLocation()

    const [statusFilterPayload, setStatusFilterPayload] = useState({
        selectedStatusList: [],
        message: ""
    })

    let searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        if (!!searchQueryParams?.getAll(searchParamsInfo.status.key)) {
            setStatusFilterPayload({
                ...statusFilterPayload,
                selectedStatusList: searchQueryParams?.getAll(searchParamsInfo.status.key)?.map((item) => item?.replaceAll("-", "_")),
                message: ""
            })
        } else {
            setStatusFilterPayload({
                ...statusFilterPayload,
                selectedStatusList: [],
                message: ""
            })
        }
    }, [searchQueryParams.getAll(searchParamsInfo.status.key)?.length])

    const onHandleSelectOption = useCallback((option) => {
        if (statusFilterPayload?.selectedStatusList?.length > 0) {
            const filterDuplicateStatus = statusFilterPayload?.selectedStatusList?.filter((selectedItem) => (selectedItem === option))
            if (filterDuplicateStatus?.length > 0) {
                setStatusFilterPayload({
                    ...statusFilterPayload,
                    selectedStatusList: statusFilterPayload?.selectedStatusList?.filter((item) => (item !== option)),
                    message: ""
                })
            } else {
                setStatusFilterPayload({
                    ...statusFilterPayload,
                    selectedStatusList: [...statusFilterPayload?.selectedStatusList, option],
                    message: ""
                })
            }
        } else {
            setStatusFilterPayload({
                ...statusFilterPayload,
                selectedStatusList: [...statusFilterPayload?.selectedStatusList, option],
                message: ""
            })
        }
    }, [statusFilterPayload, activeFilter])

    const onHandleMouseLeave = () => {
        if (!searchQueryParams?.getAll(searchParamsInfo.status.key)) {
            setStatusFilterPayload({
                message: "",
                selectedStatusList: []
            })
        }
        setActiveFilter(null)
    }

    const onHandleApplyFilter = useCallback(() => {
        if (!statusFilterPayload?.selectedStatusList?.length) {
            setStatusFilterPayload({
                ...statusFilterPayload,
                message: "Please select status!"
            })
            return;
        }
        setStatusFilterPayload({
            ...statusFilterPayload,
            message: ""
        })

        searchQueryParams.delete(searchParamsInfo.status.key);
        searchQueryParams.delete(searchParamsInfo.page.key);
        searchQueryParams.set(searchParamsInfo.page.key, DEFAULT_LMS_QUIZ_PAGE);
        statusFilterPayload?.selectedStatusList.forEach(item => {
            searchQueryParams.append(searchParamsInfo.status.key, item);
        });
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
        setActiveFilter(null)
    }, [statusFilterPayload, searchQueryParams, activeFilter])

    const onHandleClearFilter = useCallback((e) => {
        e.stopPropagation()
        setStatusFilterPayload({
            ...statusFilterPayload,
            selectedStatusList: [],
            message: ""
        })

        searchQueryParams.delete(searchParamsInfo.status.key)
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
        setActiveFilter(null)
    }, [searchQueryParams, activeFilter])

    return (
        <FilterBox
            boxTitle={filterItem?.label}
            boxIcon={!!searchQueryParams?.getAll(searchParamsInfo.status.key)?.length
                ? <button onClick={onHandleClearFilter}>
                    <AiOutlineCloseCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
                : <button>
                    <AiOutlinePlusCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
            }
            isActive={activeFilter === filterItem.key}
            onHandleToggleBox={() => setActiveFilter(filterItem.key)}
            onHandleMouseLeave={onHandleMouseLeave}
            BoxItemContent={() => {
                return !!searchQueryParams?.getAll(searchParamsInfo.status.key)?.length && (
                    <>
                        <div className={"h-[20px] border border-divider-lightDark"} />
                        <span className={"font-bodyPri font-normal text-sm text-primary-dark line-clamp-1 capitalize"}>
                            {searchQueryParams?.getAll(searchParamsInfo.status.key)?.map((item) => lmsQuizStatusEnum[item?.replaceAll("-", "_").toUpperCase()]?.label)?.join(" , ")}
                        </span>
                    </>
                )
            }}
            customBoxClassName={!!searchQueryParams?.getAll(searchParamsInfo.status.key)?.length && "!bg-text-200 !border-solid"}
            activeContainerClassName={""}
        >
            <div className={"w-60 md:w-72 p-3 flex flex-col gap-5"}>
                <span className={"font-bodyPri font-bold text-text-900 text-base tracking-wide"}>
                    {"Filter By Status"}
                </span>
                <div className={"space-y-5"}>
                    {Object.values(lmsQuizStatusEnum)?.map((item, index) => (
                        <div
                            key={index}
                            className={"w-full flex items-center justify-start gap-3 group cursor-pointer"}
                            onClick={() => onHandleSelectOption(item?.value)}
                        >
                            <input
                                type={"checkbox"}
                                checked={(statusFilterPayload?.selectedStatusList?.includes(item?.value)) && true}
                            />
                            <span className={"font-bodyPri font-normal text-text-900 text-sm tracking-wide line-clamp-1"}>
                                {item?.label}
                            </span>
                        </div>
                    ))}
                </div>
                <div className={cn(
                    "w-full py-1 flex items-center justify-center rounded-md border bg-primary-dark cursor-pointer hover:opacity-90",
                    (!statusFilterPayload?.selectedStatusList?.length) && "bg-text-300 text-text-300 hover:!bg-text-300 cursor-not-allowed"
                )}
                    onClick={onHandleApplyFilter}
                >
                    <span className={"font-bodyPri font-medium text-text-50 text-sm tracking-wide "}>
                        {"Apply Filters"}
                    </span>
                </div>
            </div>
        </FilterBox>
    )
});

export default QuizStatusFilter;