import { memo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FiEdit } from 'react-icons/fi';
import { FaSpinner } from 'react-icons/fa';
import { IoIosCheckmark } from 'react-icons/io';
import { GoPlus } from 'react-icons/go';
import { MdEdit } from 'react-icons/md';

import { AnimatePresence, motion } from 'framer-motion';

import { profileEditBtnConst } from 'pages/auth/onboarding/data';
import AppointmentDropdown from './AppointmentDropdown';
import EmptyAppointmentContent from './EmptyAppointmentContent';

import { getUserAppointmentList } from 'redux/appointment/appointment.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { appointmentPriceModelEnum, appointmentStatusEnum } from 'redux/appointment/appointment.const';

import { pagesInfo } from 'utils/pagesInfo';
import { cn } from 'utils/cn.utils';
import { debounce } from 'utils/generators.utils';

const AppointmentDetail = memo(({ editBtnConst, setEditBtnConst, selectedAvailability, setSelectedAvailability }) => {
    const { modal } = useAppState((state) => state.local)
    const { user, userDetail, addUserDetail } = useAppState((state) => state.user)
    const { userAppointmentList } = useAppState((state) => state.appointment)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [searchValue, setSearchValue] = useState("")

    const onHandleAddAppointment = useCallback(() => {
        dispatcher(setModal({
            ...modal,
            [modalConst.createAppointmentDetailModal.key]: {
                ...modalConst.createAppointmentDetailModal,
                isVisible: true
            }
        }));
    }, [modal])

    const onHandleEditAppointmentDetail = useCallback(() => {
        const requestPayload = {
            status: appointmentStatusEnum.PUBLISHED.value,
            page: 1, records: 5
        }
        dispatcher(getUserAppointmentList(user?.user?.userId, requestPayload))
        setSelectedAvailability(userDetail?.userDetail?.appointment)
        setEditBtnConst(profileEditBtnConst?.PRICE?.value)
    }, [editBtnConst, userDetail?.userDetail?.appointment, user?.user?.userId])

    const onHandleNavigateToAppointmentDetail = useCallback(() => {
        navigate(`${pagesInfo.EDIT_APPOINTMENT.pagePath}/${userDetail?.userDetail?.appointment?.id}/edit`)
    }, [userDetail?.userDetail?.appointment])

    const onHandleUpdateAppointment = useCallback((event, selectedOption) => {
        event.stopPropagation()
        navigate(`${pagesInfo.EDIT_APPOINTMENT.pagePath}/${selectedOption?.value}/edit`)
    }, [userAppointmentList?.data?.result])

    const onHandleSearch = useCallback((searchText) => {
        const requestPayload = {
            status: appointmentStatusEnum.PUBLISHED.value
        }
        if (searchText) {
            requestPayload["title"] = searchText
            dispatcher(getUserAppointmentList(user?.user?.userId, requestPayload))
        } else {
            dispatcher(getUserAppointmentList(user?.user?.userId, requestPayload))
        }
    }, [user?.user?.userId])

    const onHandleSearchAppointment = useCallback((searchText) => {
        setSearchValue(searchText)
        const delayFunction = debounce.debounce(onHandleSearch)
        delayFunction(searchText)
    }, [searchValue])

    const onHandleSelectAppointment = useCallback((selectedOption) => {
        if (addUserDetail?.isLoading) return;
        setSelectedAvailability(selectedOption?.appointment)
    }, [addUserDetail])

    return (
        <div className={"space-y-3"}>
            <div className={"flex items-center justify-start gap-2"}>
                <span className={"text-xl text-text-900 font-medium font-bodyPri tracking-wide"}>
                    {"Default Appointment:"}
                </span>
                {!editBtnConst &&
                    <FiEdit
                        className={"text-lg text-text-500 cursor-pointer"}
                        onClick={onHandleEditAppointmentDetail}
                    />
                }
                {(editBtnConst === profileEditBtnConst?.PRICE?.value) &&
                    <motion.button
                        key={"add_new_schedule"}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        transition={{ duration: 0.3 }}
                        className={cn(
                            "w-fit py-1 px-2 flex items-center justify-center gap-1.5 text-primary-main ease-in-out duration-200 border border-primary-main rounded",
                            "hover:bg-primary-dark hover:text-text-50 font-bodyPri font-normal cursor-pointer whitespace-nowrap group",
                        )}
                        disabled={addUserDetail?.isLoading}
                        onClick={onHandleAddAppointment}
                    >
                        <GoPlus className={"text-lg group-hover:text-text-50"} />
                        <span className={"text-sm line-clamp-1"}>
                            {"Add Appointment"}
                        </span>
                    </motion.button>
                }

                {addUserDetail?.isLoading &&
                    <div className={"flex items-center justify-start gap-0.5"}>
                        <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                            {"Please wait ..."}
                        </span>
                        <FaSpinner className={"inline-flex text-primary-dark animate-spin"} />
                    </div>
                }
            </div>
            <AnimatePresence initial={editBtnConst} className={"space-y-5"}>
                {(editBtnConst === profileEditBtnConst?.PRICE?.value) &&
                    <motion.div
                        className={"flex flex-col justify-start items-start space-y-5"}
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <AppointmentDropdown
                            isLoading={userAppointmentList?.isLoading}
                            searchable={true}
                            labelItem={`appointment_schedule`}
                            showLabel={false}
                            isShowEmptyContent={(!userAppointmentList?.data?.result || (userAppointmentList?.data?.result?.length === 0)) && true}
                            options={userAppointmentList?.data?.result?.map((item) => ({
                                label: `${item?.title}`,
                                value: item?.id,
                                appointment: item
                            }))}
                            value={<span className={"line-clamp-1"}>
                                {selectedAvailability ? `${selectedAvailability?.title}` : "Select an existing appointment or create a new one"}
                            </span>}
                            searchValue={searchValue}
                            setSearchValue={onHandleSearchAppointment}
                            EmptyContentContainer={<EmptyAppointmentContent searchValue={searchValue} />}
                            onHandleSelect={onHandleSelectAppointment}
                            OptionChild={({ option }) => (
                                <div className={"bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between"}>
                                    <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide line-clamp-1"}>
                                        {option?.label}
                                    </span>
                                    <div className={"flex items-center justify-start gap-1.5"}>
                                        <button>
                                            {(option?.value === selectedAvailability?.id)
                                                && <IoIosCheckmark size={20} color="green" />
                                            }
                                        </button>
                                        <button
                                            className={""}
                                            onClick={(event) => onHandleUpdateAppointment(event, option)}
                                        >
                                            <MdEdit className={"text-xl text-text-700 group-hover:text-primary-dark cursor-pointer"} />
                                        </button>
                                    </div>
                                </div>
                            )}
                            dropDownContainerClassName={"w-full md:w-[25rem] max-h-44 overflow-y-auto scrollbar-thin"}
                            customBtnStyle={"w-full md:w-[25rem]"}
                        />
                    </motion.div>
                }
                {(editBtnConst !== profileEditBtnConst?.PRICE?.value) &&
                    <div className={"w-full flex flex-col group"}>
                        <div className={"w-full p-4 bg-blue-100 rounded-lg shadow-md"}>
                            <div className={"w-full flex items-start justify-between gap-3"}>
                                <div className={"w-full"}>
                                    <a
                                        href={`${pagesInfo.APPOINTMENT.pagePath}/${userDetail?.userDetail?.appointment?.slug}`}
                                        target={"_blank"}
                                        className={"font-bodyPri font-medium text-lg text-text-900 line-clamp-1 cursor-pointer hover:underline hover:text-primary-dark"}
                                    >
                                        {userDetail?.userDetail?.appointment?.title}
                                    </a>

                                    <div className={"font-bodyPri font-normal text-text-800 text-sm line-clamp-1"}>
                                        {userDetail?.userDetail?.appointment?.subtitle}
                                    </div>
                                </div>
                                <FiEdit
                                    className={"hidden group-hover:flex text-lg text-primary-dark cursor-pointer"}
                                    onClick={onHandleNavigateToAppointmentDetail}
                                />
                            </div>
                        </div>
                        <div className="px-2 divide-y divide-blue-200 bg-blue-50">
                            <div className={"w-full flex flex-col items-start justify-start gap-3 p-4"}>
                                <div className={"w-full flex items-center justify-start gap-5"}>
                                    {(userDetail?.userDetail?.appointment?.appointment_price?.price_model === appointmentPriceModelEnum.FLAT_FEE.value) && (
                                        <div className={"flex items-center justify-start gap-0.5"}>
                                            <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                                {"Price:"}
                                            </span>
                                            <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                                                {userDetail?.userDetail?.appointment?.appointment_price?.masterCurrency?.code + " " + parseFloat((userDetail?.userDetail?.appointment?.appointment_price?.price - ((userDetail?.userDetail?.appointment?.appointment_price?.price * userDetail?.userDetail?.appointment?.appointment_price?.discount_pct) / 100)) / 100).toFixed(2)}
                                            </span>

                                            <span className="text-text-900 font-medium font-bodyPri flex gap-1">
                                                ({(userDetail?.userDetail?.appointment?.appointment_availability?.duration < 60)
                                                    ? userDetail?.userDetail?.appointment?.appointment_availability?.duration + " min"
                                                    : (userDetail?.userDetail?.appointment?.appointment_availability?.duration / 60)?.toFixed(2) + " hour(s)"
                                                })
                                            </span>
                                        </div>
                                    )}
                                    {(userDetail?.userDetail?.appointment?.appointment_price?.price_model === appointmentPriceModelEnum.FREE.value) && (
                                        <div className={"flex items-center justify-start gap-0.5"}>
                                            <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                                {"Duration:"}
                                            </span>
                                            <span className="text-text-900 font-medium font-bodyPri flex gap-1">
                                                {(userDetail?.userDetail?.appointment?.appointment_availability?.duration < 60)
                                                    ? userDetail?.userDetail?.appointment?.appointment_availability?.duration + " min"
                                                    : (userDetail?.userDetail?.appointment?.appointment_availability?.duration / 60)?.toFixed(2) + " hour(s)"
                                                }
                                            </span>
                                        </div>
                                    )}
                                    <div className={"flex items-center justify-start gap-0.5"}>
                                        <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                            {"Price Model:"}
                                        </span>
                                        <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                                            {appointmentPriceModelEnum[userDetail?.userDetail?.appointment?.appointment_price?.price_model?.toUpperCase()]?.label}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </AnimatePresence>
        </div>
    )
})

export default AppointmentDetail;