import { useState } from 'react'

import TabComponent from 'components/tab/TabComponent';

import PricingTable from './pricing-table';

import { cn } from 'utils/cn.utils';


const Plans = ({ planData }) => {
    const { tabs } = planData
    const [activeTab, setActiveTab] = useState(tabs.month.key);

    return (
        <section className="w-full p-5 flex flex-col items-center gap-5">
            <div className="flex border border-primary-dark w-fit rounded-full overflow-hidden">
                <TabComponent
                    tabs={Object.values(tabs).map(item => ({ ...item, id: item.key }))}
                    activeTab={activeTab}
                    setActiveTab={(tabItem) => setActiveTab(tabItem?.key)}
                    tabStyle="px-10 py-1.5 font-semibold text-white"
                    pillStyle="bg-primary-dark rounded-full"
                    activeStyle="!text-primary-dark hover:!text-secondary-dark"
                />
            </div>
            <PricingTable
                interval={activeTab}
                planData={planData}
            />
            <div className='flex items-center gap-2'>
                {Object.values(tabs).map((tab, index) => (
                    <div
                        key={index}
                        className={cn('w-4 h-4 rounded-full cursor-pointer', activeTab === tab.key ? 'bg-primary-dark' : 'bg-blue-200')}
                        onClick={() => setActiveTab(tab.key)}
                    />
                ))}
            </div>
        </section>
    );
};

export default Plans;