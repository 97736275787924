import { useState } from 'react';
import { cn } from "utils/cn.utils";

import {
    TaskItemContainer,
    TaskDetails,
    TaskTitleDesc,
    TaskTitleText,
    TaskDescText,
    TaskAction,
    Scheduled,
    UnderReview,
    Verified,
    ArrowToggleIcon,
    TaskTime,
    VerifiedIcon,
    SubmittedIcon,
    SelectSlotContainer,
    Rejected,
    RejectedIcon,
    Pending,
    PendingIcon,
    DisabledButton
} from 'pages/auth/tutorOnboard/TutorOnboardStyle';

import InterviewSlotSelection from "pages/auth/tutorOnboard/components/attendVideoCall/components/InterviewSlotSelection";

import { useAppState } from 'hooks/useStore';
import { interviewCallStatus } from "redux/onboarding/onboarding.const";

const AttendVideoCall = () => {
    const { userOnboardingDetail } = useAppState((state) => state.onboarding)
    const [openSlotBox, setOpenSlotBox] = useState(false)
    const [clickedItemIndex, setClickedItemIndex] = useState(null);

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const isOpen = clickedItemIndex === 1;

    const isEmailMobileVerified = userOnboardingDetail?.userOnboardingDetail?.user?.isEmailVerified && userOnboardingDetail?.userOnboardingDetail?.user?.isMobileNoVerified

    const handleTaskItems = () => {
        setClickedItemIndex(isOpen ? null : 1)
        setOpenSlotBox(!openSlotBox)
    }

    return (
        <TaskItemContainer>
            <TaskDetails>
                <span className={cn(
                    "px-2.5 py-0.5 h-auto self-start rounded-full font-bodyPri font-medium text-lg",
                    (!isEmailMobileVerified || !userOnboardingDetail?.userOnboardingDetail?.onboardingInterview?.status) && "border-2 border-text-500 text-text-900 bg-white",
                    (isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingInterview?.status) && `text-text-50 bg-${interviewCallStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingInterview?.status?.toUpperCase()]?.darkColor}`
                )}>
                    {"6"}
                </span>
                <TaskTitleDesc>
                    <TaskTitleText>{"Attend a video call, if invited (optional)"}</TaskTitleText>
                    <TaskDescText>
                        {"We will review your profile and may  meet you on a short video call. If this option gets activated, you will receive a notification to select a suitable time slot. A video meeting link will be shared upon confirmation. You will be asked to proceed to the next step if we decide to skip the call."}
                    </TaskDescText>
                </TaskTitleDesc>
            </TaskDetails>
            <TaskAction>
                {(!isEmailMobileVerified || (isEmailMobileVerified && !userOnboardingDetail?.userOnboardingDetail?.onboardingInterview?.status)) &&
                    <DisabledButton>
                        {"Select a slot"}
                    </DisabledButton>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingInterview?.status === interviewCallStatus?.PENDING?.value) &&
                    <Pending onClick={handleTaskItems} >
                        <PendingIcon />
                        {interviewCallStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingInterview?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Pending>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingInterview?.status === interviewCallStatus.SCHEDULED.value) &&
                    <Scheduled onClick={handleTaskItems}>
                        {/* <SubmittedIcon /> */}
                        {interviewCallStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingInterview?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Scheduled>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingInterview?.status === interviewCallStatus.ON_GOING.value) &&
                    <UnderReview onClick={handleTaskItems}>
                        <SubmittedIcon />
                        {interviewCallStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingInterview?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </UnderReview>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingInterview?.status === interviewCallStatus.APPROVED.value) &&
                    <Verified onClick={handleTaskItems}>
                        <VerifiedIcon />
                        {interviewCallStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingInterview?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Verified>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingInterview?.status === interviewCallStatus.REJECTED.value) &&
                    <Rejected onClick={handleTaskItems}>
                        <RejectedIcon />
                        {interviewCallStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingInterview?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Rejected>
                }
                <TaskTime>{"15 - 30 mins"}</TaskTime>
            </TaskAction>
            {openSlotBox &&
                <SelectSlotContainer>
                    <InterviewSlotSelection />
                </SelectSlotContainer>
            }
        </TaskItemContainer>
    )
}

export default AttendVideoCall;