import { baseAuthApiInstance } from 'apiInstances/axios.apiInstance'
import { url } from './gpt.const'


class GptServiceService {

    static gptService = new GptServiceService()

    createGptContent = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.GENERATE_GPT_CONTENT, body
        )

        return response;
    }

    createGptText = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.GENERATE_GPT_TEXT, body
        )

        return response;
    }

    getGptTokenTxnList = async () => {
        const response = await baseAuthApiInstance().get(
            url.GET_GPT_TXN_LIST_FOR_USERS
        )

        return response;
    }
    // ai question generation
    createAiSingleQuestion = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.CREATE_AI_SINGLE_QUESTION, body
        )

        return response;
    }
}

export default GptServiceService.gptService;