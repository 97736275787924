import { useMemo, memo } from 'react'

import FadedCollapse from 'components/fadedCollapse';

import ResultView from "../../../course/components/lesson/quiz/components/result";

const QuizResult = memo(({
  quiz,
  lmsQuizResponse
}) => {

  const descriptionText = useMemo(() => new DOMParser()?.parseFromString(`<div>${quiz?.description || ""}</div>`, "text/html")?.querySelector("div")?.textContent, [quiz?.description])

  return (
    <div className={"max-w-full sm:max-w-xl md:max-w-3xl lg:max-w-5xl w-full mx-auto px-3 md:px-8 py-5 space-y-10 select-none"}>

      {/* header */}
      <div className={"flex flex-col items-start gap-1"}>
        <h2 className={"font-bodyPri font-bold text-2xl text-900 line-clamp-1"}>{`Quiz: ${quiz?.title}`}</h2>
        {!!descriptionText && (
          <FadedCollapse
            isShowMore={descriptionText?.length > 500}
            isFaded={descriptionText?.length > 500}
            maxHeight={descriptionText?.length > 500 ? "7rem" : "100%"}
            wrapperStyle={"block mt-3"}
          >
            <div dangerouslySetInnerHTML={{ __html: quiz?.description }}></div>
          </FadedCollapse>
        )}
        <hr className={"h-0.5 w-full bg-text-300"} />
      </div>

      {/* Section */}
      <ResultView quiz={quiz} quiz_response={lmsQuizResponse} />

    </div>
  )
})

export default QuizResult