import { toast } from "react-toastify";
import { useState } from "react";

import UpdateLmsInputText from "pages/auth/edulyteLms/commonComponents/UpdateLmsInputText";
import LmsTextAreaDescription from "pages/auth/edulyteLms/commonComponents/LmsTextAreaDescription";

import CourseSection from "./components/CourseSection";
import CourseSideBar from "../courseSideBar/CourseSideBar";

import { updateLmsCourseDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

const EditLMSCourseBuilderPage = () => {
    const { lmsCourseDetail, modifyLmsCourseDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    const [titleInputStatus, setTitleInputStatus] = useState("")
    const [subTitleInputStatus, setSubTitleInputStatus] = useState("")

    const onHandleSaveTitle = (editedTitle) => {
        setTitleInputStatus("Saving...")
        if (!editedTitle || (editedTitle?.length === 0)) {
            toast.warn("Please enter title ...")
            setTitleInputStatus("Saved")
            return;
        }
        const body = {
            title: editedTitle
        }
        const payload = {
            isShowToast: false
        }
        dispatcher(updateLmsCourseDetail(lmsCourseDetail?.data?.id, body, payload))

        setTimeout(() => {
            setTitleInputStatus("")
        }, 1000)
    }

    const onHandleSaveSubTitle = (editedSubTitle) => {
        setSubTitleInputStatus("Saving...")
        const body = {
            sub_title: editedSubTitle
        }
        const payload = {
            isShowToast: false
        }
        dispatcher(updateLmsCourseDetail(lmsCourseDetail?.data?.id, body, payload))

        setTimeout(() => {
            setSubTitleInputStatus("")
        }, 1000)
    }

    return (
        <div className={"grid grid-cols-12 gap-3 pb-5"}>
            <div className={'col-start-1 col-span-full lg:col-start-1 lg:col-span-9 space-y-3'}>
                <div className={"w-full flex flex-col gap-3"}>
                    <UpdateLmsInputText
                        isLoading={modifyLmsCourseDetail?.isLoading}
                        isShowInputLabel={true}
                        containerClassName={"mt-3 mb-1"}
                        inputClassName={"font-tagLine font-semibold text-primary-dark text-lg border-b border-text-400"}
                        title={lmsCourseDetail?.data?.title}
                        titleInputStatus={titleInputStatus}
                        setTitleInputStatus={setTitleInputStatus}
                        onHandleSaveTitle={onHandleSaveTitle}
                        isShowAiBtn={true}
                    />
                    <LmsTextAreaDescription
                        isLoading={modifyLmsCourseDetail?.isLoading}
                        isShowInputLabel={true}
                        inputClassName={"!translate-y-2"}
                        inputLabelClassName={(lmsCourseDetail?.data?.sub_title || subTitleInputStatus) && "!-translate-y-10"}
                        description={lmsCourseDetail?.data?.sub_title}
                        descriptionInputStatus={subTitleInputStatus}
                        setDescriptionInputStatus={setSubTitleInputStatus}
                        onHandleSaveDescription={onHandleSaveSubTitle}
                        isShowAiBtn={true}
                    />
                </div>
                <CourseSection />
            </div>
            <div className={"hidden lg:block col-start-10 col-span-3"}>
                <div className={"sticky top-16"}>
                    <CourseSideBar />
                </div>
            </div>
        </div>
    )
}

export default EditLMSCourseBuilderPage;