import { useMemo } from 'react'

import FadedCollapse from 'components/fadedCollapse'

const ArticleView = ({ article }) => {

  const descriptionText = useMemo(() => new DOMParser()?.parseFromString(`<div>${article?.description || ""}</div>`, "text/html")?.querySelector("div")?.textContent, [article?.description])
  const contentText = useMemo(() => new DOMParser()?.parseFromString(`<div>${article?.content || ""}</div>`, "text/html")?.querySelector("div")?.textContent, [article?.content])

  return (
    <div className={"space-y-5"}>

      {/* heading */}
      <div className={"self-center"}>
        <h4 className={"font-bodyPri font-medium text-xl text-text-800 text-center"}>{article?.title}</h4>
        {!!descriptionText?.length && (
          <FadedCollapse
            isShowMore={descriptionText?.length > 500}
            isFaded={descriptionText?.length > 500}
            maxHeight={contentText?.length > 500 ? "7.5rem" : "100%"}
            wrapperStyle={"block"}
          >
            <div dangerouslySetInnerHTML={{ __html: article?.description }}></div>
          </FadedCollapse>
        )}
      </div>
      {/* content */}
      {!!contentText?.length && (
        <FadedCollapse
          isShowMore={contentText?.length > 500}
          isFaded={contentText?.length > 500}
          maxHeight={contentText?.length > 500 ? "7.5rem" : "100%"}
        >
          <div dangerouslySetInnerHTML={{ __html: article?.content }}></div>
        </FadedCollapse>
      )}

    </div>
  )
}

export default ArticleView