//firebase imports
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";

import { database } from "config/firebase.config"

//Send Message
export default async function sendMessage(
  messageObject,
  selectedChat,
  groupMembers
) {
  // const userIds = [sender, reciever];
  // userIds.sort();
  // const chatId = userIds.join(":");

  // //Create a new chat if it does not exist
  // const chatRef = doc(database, "testchat", chatId);
  // const chatSnap = await getDoc(chatRef);

  // if (chatSnap.exists()) {
  //   console.log("Chat exists, proceed to send message");
  // } else {
  //   const chatDetails = {
  //     users: [sender, reciever],
  //     typing: {
  //       [sender]: false,
  //       [reciever]: false,
  //     },
  //     startedAt: {
  //       [sender]: Timestamp.fromDate(new Date()),
  //       [reciever]: Timestamp.fromDate(new Date()),
  //     },
  //   };
  //   await setDoc(chatRef, chatDetails);
  // }

  const chatId = selectedChat.id;
  const chatRef = doc(database, "chats", chatId);

  //Save message to messages subcollection
  const messageRef = collection(database, "chats", chatId, "messages");

  await updateDoc(chatRef, {
    lastMessage: messageObject,
  });

  if (selectedChat.type === "group") {
    groupMembers.forEach(async (member) => {
      if (member.id !== messageObject.sender) {
        const newMemberUnreadMessageCount = parseInt(member.unreadMessages) + 1;
        const memberRef = doc(
          database,
          "chats",
          selectedChat.id,
          "members",
          member.id
        );
        await updateDoc(memberRef, {
          unreadMessages: `${newMemberUnreadMessageCount}`,
        });
      }
    });
  }
  await addDoc(messageRef, messageObject);
}
