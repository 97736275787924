export const brandData = [
    {
        imageUrl: " https://edulyte-website-image.s3.ap-south-1.amazonaws.com/bwl.png"
    },
    {
        imageUrl: "https://edulyte-website-image.s3.ap-south-1.amazonaws.com/HRW.png"
    },
    {
        imageUrl: "https://edulyte-website-image.s3.ap-south-1.amazonaws.com/TET3.jpeg"
    },
    {
        imageUrl: "https://edulyte-website-image.s3.ap-south-1.amazonaws.com/ED2.jpg"
    },
    {
        imageUrl: "https://edulyte-website-image.s3.ap-south-1.amazonaws.com/HED.png"
    },
    {
        imageUrl: "https://edulyte-website-image.s3.ap-south-1.amazonaws.com/IT1.png"
    },
    {
        imageUrl: "https://edulyte-website-image.s3.ap-south-1.amazonaws.com/SVN.png"
    },
    {
        imageUrl: "https://edulyte-website-image.s3.ap-south-1.amazonaws.com/THN.jpg"
    },
    {
        imageUrl: "https://edulyte-website-image.s3.ap-south-1.amazonaws.com/ESN1.jpg"
    },
    {
        imageUrl: "https://edulyte-website-image.s3.ap-south-1.amazonaws.com/IED1.jpg"
    }
];