import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { BsCheckAll } from "react-icons/bs";

import { updateCourseDetail } from "redux/course/course.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";

import { pagesInfo } from "utils/pagesInfo";

const HamburgerMenu = ({ setIsMediumHamburger, options, stepCount, setStepCount }) => {

    const { tutorCourseDetail, addCourseDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate();

    const onHandleNavigation = (stepNo) => {
        if (addCourseDetail?.isLoading || stepNo === stepCount) return;

        // if (courseTagList?.courseTagList?.results?.length < 3) {
        //     alert("Please add at least 3 skills!")
        //     return;
        // }
        if (stepNo === 4 && !tutorCourseDetail?.data?.courseAvailability?.id) {
            alert("Please submit your class schedule!")
            return;
        }

        if (stepNo === 5 && !tutorCourseDetail?.data?.coursePrice) {
            alert("Please submit your class price!")
            return;
        }

        setStepCount(stepNo)
        navigate(`${pagesInfo?.CREATE_COURSE?.pagePath}/${tutorCourseDetail?.data?.id}/edit?step=${stepNo}`)
        if (tutorCourseDetail?.data?.step < stepNo) {
            dispatcher(updateCourseDetail(tutorCourseDetail?.data?.id, { step: (tutorCourseDetail?.data?.step + 1) }))
            return;
        }
    }

    return (
        <div className={"min-w-[20rem] p-5 space-y-3 h-screen"}>
            {/* <div className={"font-bodyPri font-normal text-text-800 text-base"}>
                {"Class"}
            </div> */}
            <div className={"space-y-3"}>
                {options.map((option, index) => (
                    <div key={index} className={cn(
                        "w-full flex flex-col items-start justify-start",
                        (stepCount == option?.stepNo)
                            ? "border-b-2 border-primary-main text-primary-main"
                            : "border-b-2 border-white"
                    )}>
                        <div
                            key={index}
                            onClick={() => onHandleNavigation(option?.stepNo)}
                            className={"w-full px-3 py-2 flex items-center justify-start gap-2 text-center truncate cursor-pointer"}>
                            <div className={"w-20 h-20 overflow-hidden rounded-full"}>
                                <img
                                    src={option?.image}
                                    className={"w-full h-full object-cover"}
                                    alt={"Image"}
                                />
                            </div>
                            <div className={"flex flex-col items-start justify-start gap-2"}>
                                {(tutorCourseDetail?.data?.step <= option?.stepNo) &&
                                    <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                                        {`Step ${option?.stepNo}`}
                                    </span>
                                }
                                {(tutorCourseDetail?.data?.step > option?.stepNo) &&
                                    <div className={"flex items-center justify-start gap-1"}>
                                        <BsCheckAll className={"text-green-600 text-base"} />
                                        <span className={"font-bodyPri font-normal text-sm text-green-600"}>
                                            {"Done"}
                                        </span>
                                    </div>
                                }
                                <span className={"font-bodyPri font-semibold text-base"}>
                                    {option.label}
                                </span>
                            </div>
                        </div>
                        {(index != (options?.length - 1)) &&
                            <span className={"w-full border border-divider-darkLight overflow-hidden"}></span>
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}

export default HamburgerMenu