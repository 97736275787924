import { useState, useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { AnimatePresence, motion } from 'framer-motion';

import { MdOutlineClose } from 'react-icons/md';

import FilterIcon from "components/pageFilter/FilterIcon";
import ClearFilter from "components/pageFilter/ClearFilter";
import RefreshAndAddBtn from "components/common-components/RefreshAndAddBtn";

import { eventlyFilters, getEventlyListPayload, searchParamsInfo } from "../../data";
import EventlyIdFilter from "./EventlyIdFilter";
import EventlyTitleFilter from "./EventlyTitleFilter";
import EventlyDateFilter from "./EventlyDateFilter";

import { getUserEventlyList } from "redux/evently/evently.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setAddUserEventlyDetailPayload } from "redux/evently/evently.slice";
import { modalConst } from "redux/local/local.const";
import { addUserEventlyDetailPayload } from "redux/evently/evently.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const FilterSection = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { userEventlyList } = useAppState((s) => s.evently)

    const dispatcher = useAppDispatcher();
    const location = useLocation()

    const [activeFilter, setActiveFilter] = useState(null)
    const [mobileFilter, setMobileFilter] = useState(false)

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        if (userEventlyList?.data) {
            getEventlyList()
        }
    }, [
        searchQueryParams.get(searchParamsInfo.page.key),
        searchQueryParams.get(searchParamsInfo.id.key),
        searchQueryParams.get(searchParamsInfo.title.key),
        searchQueryParams.get(searchParamsInfo.minCreatedAt.key),
        searchQueryParams.get(searchParamsInfo.maxCreatedAt.key)
    ])

    const getEventlyList = async () => {
        const requestDataPayload = await getEventlyListPayload(searchQueryParams)

        dispatcher(getUserEventlyList(user?.user?.userId, { ...requestDataPayload }))
    }

    const onHandleCreateEventDetail = () => {
        const tomorrowStartDateTime = dayjs(dayjs().add(1, 'day'))?.startOf('day')?.tz(timeZone)?.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
        const tomorrowEndDateTime = dayjs(dayjs().add(2, 'day'))?.startOf('day')?.tz(timeZone).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
        const slotInfo = {
            start: tomorrowStartDateTime,
            end: tomorrowEndDateTime
        }
        dispatcher(setAddUserEventlyDetailPayload({
            ...addUserEventlyDetailPayload,
            startDateTime: slotInfo?.start,
            endDateTime: slotInfo?.end
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.addEventlySlotModal.key]: {
                ...modal[modalConst.addEventlySlotModal.key],
                isVisible: true,
                title: "Add Event"
            }
        }))
    }

    const handleMobileFilter = () => {
        setMobileFilter(!mobileFilter)
    }

    const FilterItemList = () => {
        return (
            <>
                {Object?.values(eventlyFilters)?.map((filterItem, index) => (
                    <div key={index}>
                        {(filterItem.key === searchParamsInfo.id.key) &&
                            <EventlyIdFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                        {(filterItem.key === searchParamsInfo.title.key) &&
                            <EventlyTitleFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                        {(filterItem.key === searchParamsInfo.date.key) &&
                            <EventlyDateFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                    </div>
                ))}
                <ClearFilter setFilterPayload={setActiveFilter} />
            </>
        )
    }

    return (
        <div className={"relative w-full px-6 py-3 bg-white shadow-md rounded-lg"}>
            <div className={"flex flex-col items-center justify-center gap-3 sm:flex-row sm:justify-between sm:gap-5"}>
                <AnimatePresence initial={mobileFilter}>
                    {mobileFilter && (
                        <motion.div
                            initial={{ x: -200 }}
                            animate={{ x: 0 }}
                            exit={{ x: -200 }}
                            transition={{ duration: 0.3 }}
                            className={'absolute left-0 top-0 h-screen w-60 flex flex-col gap-5 p-5 bg-white rounded lg:hidden shadow-md z-10'}
                        >
                            <div className={'flex justify-between items-center'}>
                                <h3 className={'text-lg font-bodyPri font-semibold text-text-900'}>{"Filter"}</h3>
                                <MdOutlineClose size={20} onClick={() => setMobileFilter(false)} className='cursor-pointer' />
                            </div>
                            <FilterItemList />
                        </motion.div>
                    )}
                </AnimatePresence>
                <div className={'hidden w-full lg:flex flex-grow flex-wrap justify-center items-center sm:justify-start gap-3'}>
                    <FilterIcon iconTooltip={"Filter Events"} />
                    <FilterItemList />
                </div>
                <div className={"flex w-full items-center justify-between lg:justify-end gap-3"}>
                    <div onClick={handleMobileFilter} className={'block lg:hidden text-primary-main font-medium hover:text-secondary-dark whitespace-nowrap cursor-pointer'}>
                        {"Filter"}
                    </div>
                    <RefreshAndAddBtn
                        btnName={"Create New Event"}
                        onHandleRefresh={getEventlyList}
                        onHandleAddBtn={onHandleCreateEventDetail}
                    />
                </div>
            </div>
        </div>
    );
};

export default FilterSection;