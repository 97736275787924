import { memo } from "react";

import { FaCheck, FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

import ToolTipView from "components/tooltipView";

import { cn } from "utils/cn.utils";

const SaveAndCancelBtn = memo(({
    isLoading = false,
    isBtnInvisible = false,
    isBtnDisable = false,
    saveBtnTooltip = "Save",
    cancelBtnTooltip = "Reset",
    isShowSaveBtn = false,
    isShowCancelBtn = false,
    onHandleSaveBtn,
    onHandleCancelBtn
}) => {

    return (
        <>
            {isLoading &&
                <div className={"text-lg"}>
                    <FaSpinner className={"animate-spin text-primary-dark"} />
                </div>
            }
            {!isLoading &&
                <div className={cn(
                    "flex justify-start items-center gap-3",
                    isBtnInvisible && "invisible"
                )}>
                    {isShowSaveBtn &&
                        <ToolTipView content={saveBtnTooltip} disabled={isBtnDisable}>
                            <button
                                disabled={isBtnDisable}
                                onClick={onHandleSaveBtn}
                                className={cn(
                                    "cursor-pointer text-lg text-green-500",
                                    isBtnDisable && "text-text-400 cursor-not-allowed"
                                )}>
                                <FaCheck />
                            </button>
                        </ToolTipView>
                    }
                    {isShowCancelBtn &&
                        <ToolTipView content={cancelBtnTooltip}>
                            <button
                                onClick={onHandleCancelBtn}
                                className={"hover:text-secondary-dark cursor-pointer text-lg"}>
                                <IoMdClose />
                            </button>
                        </ToolTipView>
                    }
                </div>
            }
        </>
    )
})

export default SaveAndCancelBtn;