import { shareDriveFileDetailPayload } from "redux/drive/drive.const";

export const DRIVE_INITIAL_STATE = {
    fileDetail: {
        isLoading: false, // loader
        data: null, // response data
        message: null, // response message

    },

    fileList: {
        isLoading: false,
        data: null,
        message: null,
    },

    fileUpload: {
        isLoading: false,
        progress: 0,
        data: null,
        message: null,
    },

    fileDownload: {
        isLoading: false,
        data: null,
        message: null,
    },

    fileView: {
        isLoading: false,
        data: null,
        message: null,
    },

    userStorage: {
        isLoading: false,
        data: null,
        message: null
    },

    userReceivedFileList: {
        isLoading: false,
        data: null,
        message: null
    },

    userMeetingRecordingList: {
        isLoading: false,
        data: null,
        message: null
    },
    recentOpenFileList: {
        isLoading: false,
        data: null,
        message: null
    },

    shareDriveFileDetail: {
        isLoading: false,
        data: null,
        payload: shareDriveFileDetailPayload,
        message: null
    }
}