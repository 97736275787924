import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import tw from 'twin.macro';

import { cn } from "utils/cn.utils";

import { ImCross } from 'react-icons/im';

import ToolTipView from 'components/tooltipView';
import DropDownMenu from 'components/dropdownMenu/DropDownMenu';
import RightLogedInToggleMenu from 'components/navbar/RightLogedInToggleMenu';

import EventlyCtaButtons from '../commonComponents/EventlyCtaButtons';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModifyUserEventlyDetailPayload } from 'redux/evently/evently.slice';

const LOGO_IMAGE = "https://www.edulyte.com/wp-content/uploads/2023/08/Edulyte_logo.png"

const EventlyTitleActions = () => {
    const { user } = useAppState((state) => state.user)
    const { modifyUserEventlyDetail } = useAppState((state) => state.evently)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const [isClick, setIsClick] = useState(false)

    const onHandleCancel = useCallback(() => {
        navigate(-1)
    }, [location])

    const onHandleChangeTitle = (event) => {
        dispatcher(setModifyUserEventlyDetailPayload({
            ...modifyUserEventlyDetail?.payload,
            title: event?.target?.value
        }))
    }

    const UserDetail = () => {
        return (
            <div className={"p-1 space-y-1"}>
                <div className={"font-bodyPri font-normal text-white text-sm line-clamp-1"}>
                    {`${user?.user?.firstName} ${user?.user?.lastName}`}
                </div>
                <div className={"font-bodyPri font-normal text-white text-sm line-clamp-1"}>
                    {`${user?.user?.email}`}
                </div>
            </div>
        )
    }

    return (
        <div className={"w-full flex items-start justify-start gap-5"}>
            <div className={"w-full grid grid-cols-12 gap-x-2 pt-1"}>
                <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-7 flex items-center gap-5 md:gap-8"}>
                    <ToolTipView content={"Close"}>
                        <button className={""} onClick={onHandleCancel}>
                            <ImCross className={'text-lg text-text-500 hover:text-text-800 focus:bg-background-light cursor-pointer'} />
                        </button>
                    </ToolTipView>
                    <input
                        type={"text"}
                        value={modifyUserEventlyDetail?.payload?.title}
                        onChange={onHandleChangeTitle}
                        placeholder={"Add title and time"}
                        className={cn(
                            "w-full md:w-[55rem] p-2 focus:outline-none bg-background-light border-b focus:border-primary-main",
                            "font-bodyPri text-text-900 text-lg font-medium hover:bg-background-lightBlue placeholder:text-text-700 placeholder:font-normal placeholder:text-lg"
                        )}
                    />
                </div>
                <div className={"hidden md:flex col-start-2 col-span-full md:col-start-8 md:col-span-full"}>
                    <EventlyCtaButtons />
                </div>
            </div>

            <ToolTipView
                content={<UserDetail />}
                allowHTML={true}
                theme={"dark"}
                interactive={true}
            >
                <div
                    className={"flex items-center justify-start gap-2 border border-text-300 rounded-lg px-2 py-1.5 cursor-pointer"}
                    onClick={() => setIsClick(!isClick)}
                >
                    <div className={"h-9 w-fit"}>
                        <img
                            src={LOGO_IMAGE}
                            alt={"logo"}
                            className={"w-full h-full object-contain aspect-auto"}
                        />
                    </div>
                    <div className={cn(
                        " w-9 h-8 rounded-full overflow-hidden relative",
                        "border-2 border-text-800 cursor-pointer",
                    )}>
                        <img
                            src={user?.user?.profilePicUrl}
                            alt={'profile-pic'}
                            className={"w-full h-full object-cover"}
                        />
                    </div>
                    <DropDownMenu
                        isClick={isClick}
                        setIsClick={setIsClick}
                        boxStyle={tw`mt-3 mr-8 right-0 max-h-[36rem] min-w-[20rem]`}
                        arrowStyle={tw`bg-white`}
                    >
                        <RightLogedInToggleMenu setIsClick={setIsClick} />
                    </DropDownMenu>
                </div>
            </ToolTipView>
        </div>
    )
}

export default EventlyTitleActions;