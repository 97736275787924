import { useEffect } from "react";

import Pagination from "components/pagination/Pagination";

import DriveTable from "pages/auth/drive/commonComponents/DriveTable";
import DriveMenu from "pages/auth/drive/commonComponents/DriveMenu";

import MyFilesMembers from "pages/auth/drive/commonComponents/MyFilesMembers";

import { getUserReceiveFileList } from "redux/drive/drive.request";

import { fileExtensionConst, formatFileSize, myDriveFilesHeaders } from "pages/auth/drive/data";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetUserReceivedFileList } from "redux/drive/drive.slice";

import { dayjs } from "utils/dateTime.utils";

const SharedWithMe = () => {
    const { userReceivedFileList } = useAppState((state) => state.drive)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(getUserReceiveFileList({ page: 1, records: 10 }))

        return () => {
            dispatcher(resetUserReceivedFileList())
        }
    }, [])

    const sortUserReceivedFileList = (file1, file2) => {
        const file1StartTime = dayjs(file1?.shared_at).utc()
        const file2StartTime = dayjs(file2?.shared_at).utc()
        return file1StartTime.isSameOrBefore(file2StartTime) ? 1 : -1
    }

    const onHandleChangePage = (page) => {
        if (userReceivedFileList?.isLoading) return;

        dispatcher(getUserReceiveFileList({ page: page, records: 10 }))
    }

    const myDriveFilesRows = (userReceivedFileList?.data && userReceivedFileList?.data?.results) && userReceivedFileList?.data?.results?.received_files?.slice(0)?.sort(sortUserReceivedFileList)?.map((fileItems) => [
        <div className="flex items-center justify-start gap-2">
            <div className={`w-[35px] h-[35px] rounded-full flex items-center justify-center bg-${fileExtensionConst[fileItems?.file_name?.split(".")?.pop()?.toUpperCase()]?.darkColor || "orange-500"}`}>
                <p className={`font-bodyPri font-normal text-text-50 text-xs text-center m-0`}>
                    {fileItems?.file_name.split(".").pop()?.toLowerCase()}
                </p>
            </div>
            <div className="font-bodyPri font-bold text-text-900 text-sm tracking-wide text-left">
                {fileItems?.file_name?.length > 35
                    ? fileItems?.file_name?.slice(0, 35) + " ..."
                    : fileItems?.file_name
                }
            </div>
        </div> || "-",
        <MyFilesMembers
            sharedByUser={fileItems?.file_owner}
            sharedWithUsers={fileItems?.shared_with || []}
        />,
        formatFileSize(fileItems?.file_size) || "-",
        fileItems?.shared_at
            ? dayjs(fileItems?.shared_at)?.format("dddd, DD MMM YYYY ")
            : "-",
        <div className={"flex justify-start"}>
            <DriveMenu fileItems={{ id: fileItems?.file_id, file_name: fileItems?.file_name }} />
        </div>
    ])

    return (
        <div className="w-full min-h-screen p-5 bg-white flex flex-col items-start justify-start gap-3 rounded-lg">
            <p className="font-bodyPri font-bold text-black text-base tracking-wide">
                {"Files"}
            </p>
            <div className="w-full relative space-y-8">
                <DriveTable
                    isLoading={userReceivedFileList?.isLoading}
                    headers={Object.values(myDriveFilesHeaders)}
                    rows={myDriveFilesRows}
                />
                {(userReceivedFileList?.data && userReceivedFileList?.data?.totalPages > 1) &&
                    <div className="w-full flex justify-center items-center">
                        <Pagination
                            page={userReceivedFileList?.data?.page}
                            totalPages={userReceivedFileList?.data?.totalPages}
                            onChangePage={(page) => onHandleChangePage(page)}
                        />
                    </div>
                }
                {userReceivedFileList?.message &&
                    <div className="font-bodyPri font-normal text-red-500 text-base tracking-wide">
                        {userReceivedFileList?.message}
                    </div>
                }
            </div>
        </div>
    )
}

export default SharedWithMe;