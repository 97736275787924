import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { GrAddCircle } from 'react-icons/gr';
import { AiOutlineEnter } from 'react-icons/ai';

import AvailabilityListPage from 'pages/auth/preferences/features/availability/list';
import CreateAvailabilityDetailPage from 'pages/auth/preferences/features/availability/create';
import AvailabilityDetailPage from 'pages/auth/preferences/features/availability/detail';
import OffDayListPage from 'pages/auth/preferences/features/offDayList/OffDayListPage';
import PricingPreferencesSettings from 'pages/auth/preferences/features/price/PricingPreferencesSettings';
import OtherPreferencesSettings from 'pages/auth/preferences/features/others/OtherPreferencesSettings';

import { DEFAULT_AVAILABILITY_LIST_PAGE, DEFAULT_AVAILABILITY_LIST_RECORDS, availabilityEndTypeEnum, availabilityIsRecurringEnum, availabilityTypeEnum, weekAvailTimeSlots } from "redux/availability/availability.const";
import { userRoles } from 'redux/auth/auth.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';

export const availabilityPageHeading = {
    heading: "Availability / Schedule",
    subHeading: "Manage your time, topics, pricing etc.",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/preferences.svg"
}

export const pageHeading = {
    heading: "Preferences",
    subHeading: "Manage your time, topics, pricing etc.",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/preferences.svg"
}

export const pageHeaderLabel = {
    ["/settings/preferences/availability"]: {
        label: "Availability"
    },
    ["/settings/preferences/availability/create"]: {
        label: "Availability"
    },
    ["/settings/preferences/availability/:availabilityId/edit"]: {
        label: "Availability"
    },
    ["/settings/preferences/off-day"]: {
        label: "Off Day"
    },
    ["/settings/preferences/price"]: {
        label: "Price"
    },
    ["/settings/preferences/other"]: {
        label: "Other"
    },
}

const otherPrefConst = {
    contentCreation: {
        label: "Count me in for content creation work",
        description: "You will create your own content."
    },
    paidInfluencer: {
        label: "Count me in for paid influencer work",
        description: "We will get in touch for any influencer work."
    },
    personalisedReco: {
        label: "Personal recommendation",
        description: "Allow personal recommendation."
    }
}

export const setGetOtherPref = (otherPref) => (
    Object.keys(otherPref).map((other) => ({
        key: other,
        label: otherPrefConst[other].label,
        description: otherPrefConst[other].description,
        value: otherPref[other]
    }))
)

export const pricingPrefDurationConst = [
    {
        label: "30 min",
        value: 30,
    },
    {
        label: "60 min",
        value: 60,
    },
    {
        label: "90 min",
        value: 90,
    },
    {
        label: "120 min",
        value: 120,
    }
]
export const pricingPrefDiscountConst = [
    {
        label: "5%",
        value: 5
    },
    {
        label: "10%",
        value: 10
    },
    {
        label: "15%",
        value: 15
    },
    {
        label: "20%",
        value: 20
    }
]

export const subscriptionHeaders = [
    "Name",
    "Product Family",
    "Status",
    "Last Modified",
    "action"
]

export const subscriptionPlansMenuOptions = {
    VIEW_PLANS: {
        label: "View",
        value: "view"
    },
    EDIT_PLANS: {
        label: "Edit",
        value: "edit"
    },
    DELETE_PLANS: {
        label: "Delete",
        value: "delete"
    }
}

export const subscriptionPriceMenuOptions = {
    EDIT_PRICE: {
        label: "Edit",
        value: "edit"
    },
    DELETE_PRICE: {
        label: "Delete",
        value: "delete"
    }
}
export const setGetOffDays = (offDays) => (
    offDays.map((offDay) => ({
        date: offDay.date,
        message: offDay.name,
        schedule: offDay.timeSlots
    }))
)

export const validateTimeRange = (st, et, nt) => {
    const startTime = dayjs(st, "hh:m A");
    const endTime = dayjs(et, "hh:m A");
    const newTime = dayjs(nt, "hh:m A");

    return (
        newTime.isBefore(startTime) ||
        newTime.isAfter(endTime) ||
        newTime.isSame(endTime)
    );
}

export const weeklyScheduleToolTip = "Add or update your general weekly schedule for seven days by the hour.Use plus sign to add hours for any given day.Click on pencil icon to modify time table/hours on any day.Click on red cross icon to delete hours.Please don't forget to save after making changes."

export const tutorSubjectPreferenceToolTip = "Choose topic and level of students you would like to teach.Use plus sign to add a new topic and student level."

export const studentSubjectPreferenceToolTip = "Choose the topic you are interested in and your current level (Beginner, Intermediate, Advanced etc.) you Use plus sign to add a new topic and difficulty level."

export const pricingPreferenceToolTip = "Set default price for your trial classes, One-on-One and group classes.Use your judgment and not set your price very low or very high.Offer discounts to students who book One-on-One sessions in a package. They can book sessions depending on your available time slots in your calendar."

export const tutorOtherPreferenceToolTip = "We will contact you if you opt for some extra work, subject to available opportunities."

export const studentOtherPreferenceToolTip = "We will contact you if you opt for some part-time / casual work, subject to available opportunities."

export const subscriptionPlanToolTip = "Plans you can attach to your classes. Sell subscriptions to earn recurring income."


// preference urls
export const preferencesTabConst = {
    AVAILABILITY: {
        label: "Availability",
        value: "availability",
        pagePath: "/availability",
        routePath: "availability",
        component: AvailabilityListPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
        isShowNavigation: true
    },
    OFF_DAY: {
        label: "Off Day",
        value: "off-day",
        pagePath: "/off-day",
        routePath: "off-day",
        component: OffDayListPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
        isShowNavigation: true
    },
    CREATE_AVAILABILITY_DETAIL: {
        label: "Create Availability",
        value: "create-availability",
        pagePath: "/availability",
        routePath: "availability/create",
        component: CreateAvailabilityDetailPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
        isShowNavigation: false
    },
    AVAILABILITY_DETAIL: {
        label: "Availability Detail",
        value: "availability_detail",
        pagePath: "/availability",
        routePath: "availability/:availabilityId/edit",
        component: AvailabilityDetailPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
        isShowNavigation: false
    },
    PRICE: {
        label: "Price",
        value: "price",
        pagePath: "/price",
        routePath: "price",
        component: PricingPreferencesSettings,
        roles: [userRoles.TUTOR.value],
        isShowNavigation: true
    },
    OTHER: {
        label: "Other",
        value: "other",
        pagePath: "/other",
        routePath: "other",
        component: OtherPreferencesSettings,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
        isShowNavigation: true
    }
}

export const availabilityIconButtons = {
    EDIT: {
        value: "edit",
        tooltip: "Edit",
        icon: <FiEdit className={"text-xl text-text-700 hover:text-primary-dark cursor-pointer"} />
    },
    DELETE: {
        value: "delete",
        tooltip: "Delete",
        icon: <RiDeleteBin6Line className={"text-xl text-text-700 hover:text-red-500 cursor-pointer"} />
    }
}

export const searchParamsInfo = {
    page: {
        key: "page"
    },
    records: {
        key: "records"
    },
    id: {
        key: "id"
    },
    name: {
        key: "name"
    },
    isRecurring: {
        key: "isRecurring"
    },
    date: {
        key: "date"
    },
    minCreatedAt: {
        key: "minCreatedAt"
    },
    maxCreatedAt: {
        key: "maxCreatedAt"
    }
}

export const availabilityFilters = {
    [searchParamsInfo.id.key]: {
        key: "id",
        label: "ID"
    },
    [searchParamsInfo.name.key]: {
        key: "name",
        label: "Name"
    },
    [searchParamsInfo.isRecurring.key]: {
        key: "isRecurring",
        label: "Recurring"
    },
    [searchParamsInfo.date.key]: {
        key: "date",
        label: "Created On"
    }
}

export const getAvailabilityListPayload = async (searchQueryParams) => {

    let requestDataPayload = {
        page: searchQueryParams.get(searchParamsInfo.page.key) || DEFAULT_AVAILABILITY_LIST_PAGE,
        records: searchQueryParams.get(searchParamsInfo.records.key) || DEFAULT_AVAILABILITY_LIST_RECORDS,
        timeZone: timeZone,
    }

    if (!!searchQueryParams.get(searchParamsInfo.id.key)) {
        requestDataPayload[searchParamsInfo.id.key] = Number(searchQueryParams.get(searchParamsInfo.id.key))
    }
    if (!!searchQueryParams.get(searchParamsInfo.name.key)) {
        requestDataPayload[searchParamsInfo.name.key] = searchQueryParams.get(searchParamsInfo.name.key).replaceAll("-", " ")
    }
    if (!!searchQueryParams.get(searchParamsInfo.isRecurring.key)) {
        requestDataPayload[searchParamsInfo.isRecurring.key] = searchQueryParams.get(searchParamsInfo.isRecurring.key).replaceAll("-", "_")
    }
    if (!!searchQueryParams.get(searchParamsInfo.minCreatedAt.key)) {
        requestDataPayload[searchParamsInfo.minCreatedAt.key] = dayjs(searchQueryParams.get(searchParamsInfo.minCreatedAt.key)).tz(timeZone).format("YYYY-MM-DD")
    }
    if (!!searchQueryParams.get(searchParamsInfo.maxCreatedAt.key)) {
        requestDataPayload[searchParamsInfo.maxCreatedAt.key] = dayjs(searchQueryParams.get(searchParamsInfo.maxCreatedAt.key)).tz(timeZone).format("YYYY-MM-DD")
    }

    return requestDataPayload;
}

export const availabilityDetailBtnConst = {
    name: {
        label: "Name",
        key: "name"
    },
    description: {
        label: "Description",
        key: "description"
    },
    minTimeBeforeAvailStart: {
        label: "Minimum notice period required, before appointment can be booked",
        key: "minTimeBeforeAvailStart"
    },
    advanceSlotPeriodDays: {
        label: "Maximum time in advance that an appointment can be booked",
        key: "advanceSlotPeriodDays"
    },
    isRecurring: {
        label: "Repeat",
        key: "isRecurring"
    },
    startDateTime: {
        label: "Start Date",
        key: "startDateTime"
    },
    endType: {
        label: "Ends",
        key: "endType"
    },
    endDateTime: {
        label: "End Date",
        key: "endDateTime"
    },
    weeklyCycle: {
        label: "Weekly Cycle",
        key: "weeklyCycle"
    }
}

export const weeklyScheduleActionButtons = {
    DELETE: {
        value: "delete",
        tooltip: "delete",
        icon: <RiDeleteBin6Line className={"text-xl text-text-700 hover:text-red-500 cursor-pointer"} />
    },
    ADD: {
        value: "add",
        tooltip: "Add",
        icon: <GrAddCircle className={"text-xl text-text-700 hover:text-primary-dark"} />
    },
    APPLY_TO_ALL: {
        value: "apply_to_all",
        tooltip: "Apply to All",
        icon: <AiOutlineEnter className={"text-xl text-text-700 hover:text-primary-dark"} />
    }
}

export const sortTimeSlots = (timeSlot1, timeSlot2) => {
    return timeSlot2?.startTime < timeSlot1?.startTime ? 1 : -1
}

export const sortDateTimeSlots = (dateTime1, dateTime2) => {
    return dateTime2?.date < dateTime1?.date ? 1 : -1
}

export const validateAvailabilityDetail = (responseData, requestPayload) => {
    let isDataSimilarOrInvalid = false

    const responsePayload = {
        name: responseData?.name,
        isRecurring: responseData?.isRecurring,
        weeklyTimeSlots: responseData?.weeklyTimeSlots || weekAvailTimeSlots,
        dateTimeSlots: responseData?.dateTimeSlots || [],
        startDateTime: responseData?.startDateTime || null,
        endType: responseData?.endType || null,
        weeklyCycle: responseData?.weeklyCycle || null,
        endDateTime: responseData?.endDateTime || null,
        description: responseData?.description || "",
        minTimeBeforeAvailStart: responseData?.minTimeBeforeAvailStart,
        advanceSlotPeriodDays: responseData?.advanceSlotPeriodDays
    }

    const localPayload = {
        name: requestPayload?.name,
        isRecurring: requestPayload?.isRecurring,
        weeklyTimeSlots: requestPayload?.weeklyTimeSlots || weekAvailTimeSlots,
        dateTimeSlots: requestPayload?.dateTimeSlots || [],
        startDateTime: requestPayload?.startDateTime || null,
        endType: requestPayload?.endType || null,
        weeklyCycle: requestPayload?.weeklyCycle || null,
        endDateTime: requestPayload?.endDateTime || null,
        description: requestPayload?.description || "",
        minTimeBeforeAvailStart: requestPayload?.minTimeBeforeAvailStart,
        advanceSlotPeriodDays: requestPayload?.advanceSlotPeriodDays
    }

    if (!requestPayload?.name) {
        isDataSimilarOrInvalid = true
    }
    if (!requestPayload?.isRecurring) {
        isDataSimilarOrInvalid = true
    }
    if ([availabilityIsRecurringEnum.RECURRING.value]?.includes(requestPayload?.isRecurring)) {
        if ((requestPayload?.endType === availabilityEndTypeEnum?.ON_DATE?.value) && !requestPayload?.endDateTime) {
            isDataSimilarOrInvalid = true
        }
        if ((requestPayload?.endType === availabilityEndTypeEnum?.WEEKLY_CYCLE?.value) && !requestPayload?.weeklyCycle) {
            isDataSimilarOrInvalid = true
        }
    }
    if ([availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(requestPayload?.isRecurring) && (requestPayload?.dateTimeSlots?.length === 0)) {
        isDataSimilarOrInvalid = true
    }
    isDataSimilarOrInvalid = JSON.stringify(responsePayload) === JSON.stringify(localPayload)
    return isDataSimilarOrInvalid;
}

export const setUserAvailabilityDetailPayload = async (availabilityData) => {
    let requestPayload = {}

    if (availabilityData?.user?.userId) {
        requestPayload["userId"] = availabilityData?.user?.userId
    }
    if (availabilityData?.name) {
        requestPayload["name"] = availabilityData?.name
    }
    if (availabilityData?.description) {
        requestPayload["description"] = availabilityData?.description
    }
    if (availabilityData?.minTimeBeforeAvailStart) {
        requestPayload["minTimeBeforeAvailStart"] = availabilityData?.minTimeBeforeAvailStart
    }
    if (availabilityData?.advanceSlotPeriodDays) {
        requestPayload["advanceSlotPeriodDays"] = availabilityData?.advanceSlotPeriodDays
    }
    if (availabilityData?.type) {
        requestPayload["type"] = availabilityData?.type
    }
    if (availabilityData?.isRecurring) {
        requestPayload["isRecurring"] = availabilityData?.isRecurring
    }
    if ([availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(availabilityData?.isRecurring)) {
        requestPayload["dateTimeSlots"] = availabilityData?.dateTimeSlots
        requestPayload["endType"] = null
        requestPayload["startDateTime"] = null
        requestPayload["endDateTime"] = null
        requestPayload["weeklyCycle"] = null
    }
    if ([availabilityIsRecurringEnum.RECURRING.value]?.includes(availabilityData?.isRecurring)) {
        if (availabilityData?.weeklyTimeSlots) {
            requestPayload["weeklyTimeSlots"] = availabilityData?.weeklyTimeSlots
        }
        if (availabilityData?.startDateTime) {
            requestPayload["startDateTime"] = availabilityData?.startDateTime || dayjs()?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss")
        }
        if (availabilityData?.endType) {
            requestPayload["endType"] = availabilityData?.endType || availabilityEndTypeEnum.FOREVER.value
        }
        if ([availabilityEndTypeEnum.ON_DATE.value]?.includes(availabilityData?.endType)) {
            requestPayload["endDateTime"] = availabilityData?.endDateTime || null
        }
        if ([availabilityEndTypeEnum.WEEKLY_CYCLE.value]?.includes(availabilityData?.endType)) {
            requestPayload["weeklyCycle"] = availabilityData?.weeklyCycle || null
        }
    }
    return requestPayload;
}

export const validateUserAvailabilityDetail = async (payload) => {
    let requestPayload = {}
    let errorMsg = null

    if (!payload?.name) {
        errorMsg = "Please enter name!"
        return { errorMsg, requestPayload }
    }
    if (!payload?.minTimeBeforeAvailStart) {
        errorMsg = "Please enter minimum availability time!"
        return { errorMsg, requestPayload }
    }
    if (!payload?.advanceSlotPeriodDays) {
        errorMsg = "Please enter maximum advance booking time!"
        return { errorMsg, requestPayload }
    }

    if ((payload?.endType === availabilityEndTypeEnum?.ON_DATE?.value) && !payload?.endDateTime) {
        errorMsg = "Please enter end date for the availability!"
        return { errorMsg, requestPayload }
    }
    if ((payload?.endType === availabilityEndTypeEnum?.WEEKLY_CYCLE?.value) && !payload?.weeklyCycle) {
        errorMsg = "Please enter weekly cycle count!"
        return { errorMsg, requestPayload }
    }
    if ([availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(payload?.isRecurring) && (payload?.dateTimeSlots?.length === 0)) {
        errorMsg = "Please add date time slots!"
        return { errorMsg, requestPayload }
    }
    if ([availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(payload?.isRecurring)) {
        const filteredTimeSlots = payload?.dateTimeSlots?.filter((item) => item?.timeSlots?.length === 0)
        if (!!filteredTimeSlots?.length) {
            errorMsg = "Please add date time slots!"
            return { errorMsg, requestPayload }
        }
    }

    requestPayload = {
        name: payload?.name,
        timeZone: timeZone,
        type: availabilityTypeEnum?.ONE_ON_ONE?.value,
        minTimeBeforeAvailStart: payload?.minTimeBeforeAvailStart,
        advanceSlotPeriodDays: payload?.advanceSlotPeriodDays,
        isRecurring: payload?.isRecurring,
    }
    if (payload?.description) {
        requestPayload["description"] = payload?.description
    }
    if ([availabilityIsRecurringEnum?.NON_RECURRING?.value]?.includes(payload?.isRecurring)) {
        requestPayload["dateTimeSlots"] = payload?.dateTimeSlots
    }
    if ([availabilityIsRecurringEnum?.RECURRING?.value]?.includes(payload?.isRecurring)) {
        let newStartDateTime = payload?.startDateTime
        let newEndDateTime = payload?.endDateTime

        // newStartDateTime = await getDateTimeSlotFromDay(payload)

        const startDayName = dayjs(newStartDateTime)?.tz(timeZone)?.format('dddd')

        const startDayTimeSlot = payload?.weeklyTimeSlots[startDayName?.slice(0, 3)?.toUpperCase()]
        if (startDayTimeSlot?.length === 0) {
            errorMsg = "You must have a session on the start date. Please update"
            return { requestPayload, errorMsg };
        } else {
            const startDateTimeFormat = dayjs(newStartDateTime)?.tz(timeZone)?.format("YYYY-MM-DD") + " " + startDayTimeSlot[0]?.startTime
            newStartDateTime = dayjs(startDateTimeFormat)?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss")
        }

        if (payload?.endType === availabilityEndTypeEnum?.ON_DATE?.value) {
            const endDayName = dayjs(payload?.endDateTime)?.tz(timeZone)?.format('dddd')

            const endDayTimeSlot = payload?.weeklyTimeSlots[endDayName?.slice(0, 3)?.toUpperCase()]
            if (endDayTimeSlot?.length === 0) {
                errorMsg = "You must have a session on the end date. Please update"
                return { requestPayload, errorMsg };
            } else {
                const endDateTimeFormat = dayjs(payload?.endDateTime)?.tz(timeZone)?.format("YYYY-MM-DD") + " " + endDayTimeSlot[0]?.endTime
                newEndDateTime = dayjs(endDateTimeFormat)?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss")
            }
        }
        requestPayload["weeklyTimeSlots"] = payload?.weeklyTimeSlots
        requestPayload["startDateTime"] = newStartDateTime
        requestPayload["endType"] = payload?.endType
        if (payload?.endType === availabilityEndTypeEnum?.ON_DATE?.value) {
            requestPayload["endDateTime"] = newEndDateTime
        }
        if (payload?.endType === availabilityEndTypeEnum?.WEEKLY_CYCLE?.value) {
            requestPayload["weeklyCycle"] = payload?.weeklyCycle
        }
    }

    return { requestPayload, errorMsg }
}

// price appointment

export const validateAppointmentDetailPayload = (responseData, localPayload) => {
    return (!localPayload?.title || !localPayload?.duration || (localPayload?.duration < 30)) || !localPayload?.availability?.id || !localPayload?.price_model
        || (responseData && (JSON.stringify({
            title: responseData?.title,
            subtitle: responseData?.subtitle,
            duration: responseData?.appointment_availability?.duration,
            availabilityId: responseData?.appointment_availability?.availability?.id,
            description: responseData?.description,
            duration: responseData?.appointment_availability?.duration,
            masterCurrency: responseData?.appointment_price?.masterCurrency,
            price_model: responseData?.appointment_price?.price_model,
            price: responseData?.appointment_price?.price || 0,
            discount_pct: responseData?.appointment_price?.discount_pct || 0,
        }) === JSON.stringify({
            title: localPayload?.title,
            subtitle: localPayload?.subtitle,
            duration: localPayload?.duration,
            availabilityId: localPayload?.availability?.id,
            description: localPayload?.description,
            duration: localPayload?.duration,
            masterCurrency: localPayload?.masterCurrency,
            price_model: localPayload?.price_model,
            price: localPayload?.price,
            discount_pct: localPayload?.discount_pct,
        })))
}

export const sessionDurationConst = {
    THIRTY: {
        label: "30 min",
        value: 30
    },
    FORTY_FIVE: {
        label: "45 min",
        value: 45
    },
    SIXTY: {
        label: "60 min",
        value: 60
    },
    CUSTOM: {
        label: "Custom",
        value: "custom"
    }
}

export const myPriceKeyConst = {
    TITLE: {
        value: "title"
    },
    SUB_TITLE: {
        value: "subtitle"
    },
    AVAILABILITY: {
        value: "availability"
    },
    DESCRIPTION: {
        value: "description"
    },
    DURATION: {
        value: "duration"
    },
    PRICE_MODEL: {
        value: "price_model"
    },
    PRICE: {
        value: "price"
    },
    DISCOUNT_PCT: {
        value: "discount_pct"
    }
}

export const appointmentDetailTooltips = {
    title: "Enter the name of your appointment or event",
    subtitle: "Add a brief description or tagline for your appointment.",
    duration: "Specify the length of each appointment or session",
    schedule: "Select an existing schedule or create a new one.",
    description: "Provide additional details or special instructions for attendees",
    price: "Set the cost for each appointment or session, if applicable.",
}