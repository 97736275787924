import { createSlice } from "@reduxjs/toolkit";

import { LMS_LECTURE_INITIAL_STATE } from "./lmsLecture.initialState";

const lmsLecture = createSlice({
    name: "lms lecture",
    initialState: LMS_LECTURE_INITIAL_STATE,
    reducers: {
        /* tutor lms lecture */
        // list
        setTutorLmsLectureListLoading: (state, { payload }) => {
            state.tutorLmsLectureList.isLoading = payload
        },
        setTutorLmsLectureListData: (state, { payload }) => {
            state.tutorLmsLectureList.data = payload
            state.tutorLmsLectureList.message = LMS_LECTURE_INITIAL_STATE.tutorLmsLectureList.message
        },
        setTutorLmsLectureListMessage: (state, { payload }) => {
            state.tutorLmsLectureList.message = payload
            state.tutorLmsLectureList.data = LMS_LECTURE_INITIAL_STATE.tutorLmsLectureList.data
        },
        resetTutorLmsLectureList: (state) => {
            state.tutorLmsLectureList = LMS_LECTURE_INITIAL_STATE.tutorLmsLectureList
        },

        // detail
        setTutorLmsLectureDetailLoading: (state, { payload }) => {
            state.tutorLmsLectureDetail.isLoading = payload
        },
        setTutorLmsLectureDetailData: (state, { payload }) => {
            state.tutorLmsLectureDetail.data = payload
            state.tutorLmsLectureDetail.message = LMS_LECTURE_INITIAL_STATE.tutorLmsLectureDetail.message
        },
        setTutorLmsLectureDetailMessage: (state, { payload }) => {
            state.tutorLmsLectureDetail.message = payload
            state.tutorLmsLectureDetail.data = LMS_LECTURE_INITIAL_STATE.tutorLmsLectureDetail.data
        },
        resetTutorLmsLectureDetail: (state) => {
            state.tutorLmsLectureDetail = LMS_LECTURE_INITIAL_STATE.tutorLmsLectureDetail
        },

        /* lms lecture */
        // add
        setAddLmsLectureDetailLoading: (state, { payload }) => {
            state.addLmsLectureDetail.isLoading = payload
        },
        setAddLmsLectureDetailPayload: (state, { payload }) => {
            state.addLmsLectureDetail.payload = payload
        },
        setAddLmsLectureDetailData: (state, { payload }) => {
            state.addLmsLectureDetail.data = payload
            state.addLmsLectureDetail.message = LMS_LECTURE_INITIAL_STATE.addLmsLectureDetail.message
        },
        setAddLmsLectureDetailMessage: (state, { payload }) => {
            state.addLmsLectureDetail.message = payload
            state.addLmsLectureDetail.data = LMS_LECTURE_INITIAL_STATE.addLmsLectureDetail.data
        },
        resetAddLmsLectureDetail: (state) => {
            state.addLmsLectureDetail = LMS_LECTURE_INITIAL_STATE.addLmsLectureDetail
        },

        // modify
        setModifyLmsLectureDetailLoading: (state, { payload }) => {
            state.modifyLmsLectureDetail.isLoading = payload
        },
        setModifyLmsLectureDetailPayload: (state, { payload }) => {
            state.modifyLmsLectureDetail.payload = payload
        },
        setModifyLmsLectureDetailData: (state, { payload }) => {
            state.modifyLmsLectureDetail.data = payload
            state.modifyLmsLectureDetail.message = LMS_LECTURE_INITIAL_STATE.modifyLmsLectureDetail.message
        },
        setModifyLmsLectureDetailMessage: (state, { payload }) => {
            state.modifyLmsLectureDetail.message = payload
            state.modifyLmsLectureDetail.data = LMS_LECTURE_INITIAL_STATE.modifyLmsLectureDetail.data
        },
        resetModifyLmsLectureDetail: (state) => {
            state.modifyLmsLectureDetail = LMS_LECTURE_INITIAL_STATE.modifyLmsLectureDetail
        },

        // destroy
        setDestroyLmsLectureDetailLoading: (state, { payload }) => {
            state.destroyLmsLectureDetail.isLoading = payload
        },
        setDestroyLmsLectureDetailMessage: (state, { payload }) => {
            state.destroyLmsLectureDetail.message = payload
        },
        resetDestroyLmsLectureDetail: (state) => {
            state.destroyLmsLectureDetail = LMS_LECTURE_INITIAL_STATE.destroyLmsLectureDetail
        },

        // add lms lecture instructor detail
        setAddLmsLectureInstructorDetailLoading: (state, { payload }) => {
            state.addLmsLectureInstructorDetail.isLoading = payload
        },
        setAddLmsLectureInstructorDetailData: (state, { payload }) => {
            state.addLmsLectureInstructorDetail.data = payload
            state.addLmsLectureInstructorDetail.message = LMS_LECTURE_INITIAL_STATE.addLmsLectureInstructorDetail.message
        },
        setAddLmsLectureInstructorDetailMessage: (state, { payload }) => {
            state.addLmsLectureInstructorDetail.message = payload
            state.addLmsLectureInstructorDetail.data = LMS_LECTURE_INITIAL_STATE.addLmsLectureInstructorDetail.data
        },
        resetAddLmsLectureInstructorDetail: (state) => {
            state.addLmsLectureInstructorDetail = LMS_LECTURE_INITIAL_STATE.addLmsLectureInstructorDetail
        },

        // add lms lesson instructor detail payload
        setAddLmsLectureInstructorDetailPayload: (state, { payload }) => {
            state.addLmsLectureInstructorDetail.payload = payload
        },
        resetAddLmsLectureInstructorDetailPayload: (state) => {
            state.addLmsLectureInstructorDetail.payload = LMS_LECTURE_INITIAL_STATE.addLmsLectureInstructorDetail.payload
        },

        setModifyLmsLectureInstructorDetailLoading: (state, { payload }) => {
            state.modifyLmsLectureInstructorDetail.isLoading = payload
        },
        setModifyLmsLectureInstructorDetailData: (state, { payload }) => {
            state.modifyLmsLectureInstructorDetail.data = payload
            state.modifyLmsLectureInstructorDetail.message = LMS_LECTURE_INITIAL_STATE.modifyLmsLectureInstructorDetail.message
        },
        setModifyLmsLectureInstructorDetailMessage: (state, { payload }) => {
            state.modifyLmsLectureInstructorDetail.message = payload
            state.modifyLmsLectureInstructorDetail.data = LMS_LECTURE_INITIAL_STATE.modifyLmsLectureInstructorDetail.data
        },
        resetModifyLmsLectureInstructorDetail: (state) => {
            state.modifyLmsLectureInstructorDetail = LMS_LECTURE_INITIAL_STATE.modifyLmsLectureInstructorDetail
        },

        setDestroyLmsLectureInstructorDetailLoading: (state, { payload }) => {
            state.destroyLmsLectureInstructorDetail.isLoading = payload
        },
        setDestroyLmsLectureInstructorDetailMessage: (state, { payload }) => {
            state.destroyLmsLectureInstructorDetail.message = payload
        },
        resetDestroyLmsLectureInstructorDetail: (state) => {
            state.destroyLmsLectureInstructorDetail = LMS_LECTURE_INITIAL_STATE.destroyLmsLectureInstructorDetail
        },

        /* lms lecture setting */
        // list
        setLmsLectureSettingListLoading: (state, { payload }) => {
            state.lmsLectureSettingList.isLoading = payload
        },
        setLmsLectureSettingListData: (state, { payload }) => {
            state.lmsLectureSettingList.data = payload
            state.lmsLectureSettingList.message = LMS_LECTURE_INITIAL_STATE.lmsLectureSettingList.message
        },
        setLmsLectureSettingListMessage: (state, { payload }) => {
            state.lmsLectureSettingList.message = payload
            state.lmsLectureSettingList.data = LMS_LECTURE_INITIAL_STATE.lmsLectureSettingList.data
        },
        resetLmsLectureSettingList: (state) => {
            state.lmsLectureSettingList = LMS_LECTURE_INITIAL_STATE.lmsLectureSettingList
        },

        // detail
        setLmsLectureSettingDetailLoading: (state, { payload }) => {
            state.lmsLectureSettingDetail.isLoading = payload
        },
        setLmsLectureSettingDetailData: (state, { payload }) => {
            state.lmsLectureSettingDetail.data = payload
            state.lmsLectureSettingDetail.message = LMS_LECTURE_INITIAL_STATE.lmsLectureSettingDetail.message
        },
        setLmsLectureSettingDetailMessage: (state, { payload }) => {
            state.lmsLectureSettingDetail.message = payload
            state.lmsLectureSettingDetail.data = LMS_LECTURE_INITIAL_STATE.lmsLectureSettingDetail.data
        },
        resetLmsLectureSettingDetail: (state) => {
            state.lmsLectureSettingDetail = LMS_LECTURE_INITIAL_STATE.lmsLectureSettingDetail
        },

        // add
        setAddLmsLectureSettingDetailLoading: (state, { payload }) => {
            state.addLmsLectureSettingDetail.isLoading = payload
        },
        setAddLmsLectureSettingDetailPayload: (state, { payload }) => {
            state.addLmsLectureSettingDetail.payload = payload
        },
        setAddLmsLectureSettingDetailData: (state, { payload }) => {
            state.addLmsLectureSettingDetail.data = payload
            state.addLmsLectureSettingDetail.message = LMS_LECTURE_INITIAL_STATE.addLmsLectureSettingDetail.message
        },
        setAddLmsLectureSettingDetailMessage: (state, { payload }) => {
            state.addLmsLectureSettingDetail.message = payload
            state.addLmsLectureSettingDetail.data = LMS_LECTURE_INITIAL_STATE.addLmsLectureSettingDetail.data
        },
        resetAddLmsLectureSettingDetail: (state) => {
            state.addLmsLectureSettingDetail = LMS_LECTURE_INITIAL_STATE.addLmsLectureSettingDetail
        },

        // modify
        setModifyLmsLectureSettingDetailLoading: (state, { payload }) => {
            state.modifyLmsLectureSettingDetail.isLoading = payload
        },
        setModifyLmsLectureSettingDetailPayload: (state, { payload }) => {
            state.modifyLmsLectureSettingDetail.payload = payload
        },
        setModifyLmsLectureSettingDetailData: (state, { payload }) => {
            state.modifyLmsLectureSettingDetail.data = payload
            state.modifyLmsLectureSettingDetail.message = LMS_LECTURE_INITIAL_STATE.modifyLmsLectureSettingDetail.message
        },
        setModifyLmsLectureSettingDetailMessage: (state, { payload }) => {
            state.modifyLmsLectureSettingDetail.message = payload
            state.modifyLmsLectureSettingDetail.data = LMS_LECTURE_INITIAL_STATE.modifyLmsLectureSettingDetail.data
        },
        resetModifyLmsLectureSettingDetail: (state) => {
            state.modifyLmsLectureSettingDetail = LMS_LECTURE_INITIAL_STATE.modifyLmsLectureSettingDetail
        },

        // destroy
        setDestroyLmsLectureSettingDetailLoading: (state, { payload }) => {
            state.modifyLmsLectureSettingDetail.isLoading = payload
        },
        setDestroyLmsLectureSettingDetailMessage: (state, { payload }) => {
            state.modifyLmsLectureSettingDetail.message = payload
        },
        resetDestroyLmsLectureSettingDetail: (state) => {
            state.modifyLmsLectureSettingDetail = LMS_LECTURE_INITIAL_STATE.modifyLmsLectureSettingDetail
        },

        // page based reducers
        setLmsLecturePageResourceListLoading: (state, { payload }) => {
            state.lmsLecturePageResourceList.isLoading = payload
        },
        setLmsLecturePageResourceListData: (state, { payload }) => {
            state.lmsLecturePageResourceList.data = payload
            state.lmsLecturePageResourceList.message = LMS_LECTURE_INITIAL_STATE.lmsLecturePageResourceList.message
        },
        setLmsLecturePageResourceListMessage: (state, { payload }) => {
            state.lmsLecturePageResourceList.message = payload
            state.lmsLecturePageResourceList.data = LMS_LECTURE_INITIAL_STATE.lmsLecturePageResourceList.data
        },
        resetLmsLecturePageResourceList: (state) => {
            state.lmsLecturePageResourceList = LMS_LECTURE_INITIAL_STATE.lmsLecturePageResourceList
        },
    }
})

export const {
    /* tutor lms lecture */
    setTutorLmsLectureListLoading,
    setTutorLmsLectureListData,
    setTutorLmsLectureListMessage,
    resetTutorLmsLectureList,

    setTutorLmsLectureDetailLoading,
    setTutorLmsLectureDetailData,
    setTutorLmsLectureDetailMessage,
    resetTutorLmsLectureDetail,

    /* lms lecture */
    setAddLmsLectureDetailLoading,
    setAddLmsLectureDetailPayload,
    setAddLmsLectureDetailData,
    setAddLmsLectureDetailMessage,
    resetAddLmsLectureDetail,

    setModifyLmsLectureDetailLoading,
    setModifyLmsLectureDetailPayload,
    setModifyLmsLectureDetailData,
    setModifyLmsLectureDetailMessage,
    resetModifyLmsLectureDetail,

    setDestroyLmsLectureDetailLoading,
    setDestroyLmsLectureDetailMessage,
    resetDestroyLmsLectureDetail,

    // lms lecture instructor
    setAddLmsLectureInstructorDetailLoading,
    setAddLmsLectureInstructorDetailData,
    setAddLmsLectureInstructorDetailMessage,
    resetAddLmsLectureInstructorDetail,
    setAddLmsLectureInstructorDetailPayload,
    resetAddLmsLectureInstructorDetailPayload,

    setModifyLmsLectureInstructorDetailLoading,
    setModifyLmsLectureInstructorDetailData,
    setModifyLmsLectureInstructorDetailMessage,
    resetModifyLmsLectureInstructorDetail,

    setDestroyLmsLectureInstructorDetailLoading,
    setDestroyLmsLectureInstructorDetailMessage,
    resetDestroyLmsLectureInstructorDetail,

    /* lms lecture setting */
    setLmsLectureSettingListLoading,
    setLmsLectureSettingListData,
    setLmsLectureSettingListMessage,
    resetLmsLectureSettingList,

    setLmsLectureSettingDetailLoading,
    setLmsLectureSettingDetailData,
    setLmsLectureSettingDetailMessage,
    resetLmsLectureSettingDetail,

    setAddLmsLectureSettingDetailLoading,
    setAddLmsLectureSettingDetailPayload,
    setAddLmsLectureSettingDetailData,
    setAddLmsLectureSettingDetailMessage,
    resetAddLmsLectureSettingDetail,

    setModifyLmsLectureSettingDetailLoading,
    setModifyLmsLectureSettingDetailPayload,
    setModifyLmsLectureSettingDetailData,
    setModifyLmsLectureSettingDetailMessage,
    resetModifyLmsLectureSettingDetail,

    setDestroyLmsLectureSettingDetailLoading,
    setDestroyLmsLectureSettingDetailMessage,
    resetDestroyLmsLectureSettingDetail,

    setLmsLecturePageResourceListLoading,
    setLmsLecturePageResourceListData,
    setLmsLecturePageResourceListMessage,
    resetLmsLecturePageResourceList,

} = lmsLecture.actions
export default lmsLecture.reducer