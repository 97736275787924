import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import AddContactAddressItem from "components/modals/crmModals/createCrmContactDetailModal/commonComponents/ContactAddressItem";
import ContactAddressItem from "components/modals/crmModals/viewContactDetailModal/commonComponents/ContactAddressItem";
import AddItemBtn from "components/modals/crmModals/commonComponents/AddItemBtn";
import { crmDetailBtnConst, sortDefaultContact } from "components/modals/crmModals/data";

import crmService from "redux/crm/crm.service";
import { createCrmContactAddressDetail, deleteCrmContactAddressDetail, updateCrmContactAddressDetail } from "redux/crm/crm.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetAddCrmContactAddressDetail, resetModifyCrmContactAddressDetail, setCrmContactDetailData, setModifyCrmContactDetailPayload } from "redux/crm/crm.slice";
import { crmContactLabelsEnum } from "redux/crm/crm.const";

const newAddressInitialState = {
    id: 0,
    isShowInput: false,
    street_line_1: "",
    street_line_2: "",
    country: null,
    state: "",
    city: "",
    post_office: "",
    zip: "",
    label: null,
    is_primary: false
}

function EditContactAddresses({ editBtnConst, setEditBtnConst }) {
    const { countryList, timeZoneCountryDetail } = useAppState((state) => state.master)
    const { crmContactDetail, addCrmContactAddressDetail, modifyCrmContactDetail, modifyCrmContactAddressDetail, destroyCrmContactAddressDetail } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()

    const [newAddressDetail, setNewAddressDetail] = useState({
        id: 0,
        isShowInput: false,
        street_line_1: "",
        street_line_2: "",
        country: null,
        state: "",
        city: "",
        post_office: "",
        zip: "",
        label: null,
        is_primary: false
    })

    const getUpdatedContactAddressDetail = () => {
        if (addCrmContactAddressDetail?.data) {
            const addressPayload = {
                id: addCrmContactAddressDetail?.data?.id,
                street_line_1: newAddressDetail?.street_line_1,
                street_line_2: newAddressDetail?.street_line_2,
                country: newAddressDetail?.country,
                state: newAddressDetail?.state,
                city: newAddressDetail?.city,
                post_office: newAddressDetail?.post_office,
                zip: newAddressDetail?.zip,
                label: newAddressDetail?.label,
                is_primary: newAddressDetail?.is_primary
            }
            if (!!modifyCrmContactDetail?.payload?.addresses?.length && newAddressDetail?.is_primary) {
                const filteredAddressList = modifyCrmContactDetail?.payload?.addresses?.map((item) => ({ ...item, is_primary: false }))
                dispatcher(setModifyCrmContactDetailPayload({
                    ...modifyCrmContactDetail?.payload,
                    addresses: [...filteredAddressList, addressPayload]
                }))
                dispatcher(setCrmContactDetailData({
                    ...crmContactDetail?.data,
                    address: addCrmContactAddressDetail?.data
                }))
            } else {
                dispatcher(setModifyCrmContactDetailPayload({
                    ...modifyCrmContactDetail?.payload,
                    addresses: [...modifyCrmContactDetail?.payload?.addresses, addressPayload]
                }))
            }
            dispatcher(resetAddCrmContactAddressDetail())
        } else if (modifyCrmContactAddressDetail?.data) {
            const addressPayload = {
                id: modifyCrmContactAddressDetail?.data?.id,
                street_line_1: newAddressDetail?.street_line_1,
                street_line_2: newAddressDetail?.street_line_2,
                country: newAddressDetail?.country,
                state: newAddressDetail?.state,
                city: newAddressDetail?.city,
                post_office: newAddressDetail?.post_office,
                zip: newAddressDetail?.zip,
                label: newAddressDetail?.label,
                is_primary: newAddressDetail?.is_primary
            }
            dispatcher(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                addresses: modifyCrmContactDetail?.payload?.addresses?.map((addressItem) => (
                    (addressItem?.id === modifyCrmContactAddressDetail?.data?.id) ? addressPayload : addressItem
                ))
            }))
            dispatcher(resetModifyCrmContactAddressDetail())
        }
        setNewAddressDetail(newAddressInitialState)
        setEditBtnConst(null)
    }

    useEffect(() => {
        if (addCrmContactAddressDetail?.data || modifyCrmContactAddressDetail?.data) {
            getUpdatedContactAddressDetail()
        }
    }, [addCrmContactAddressDetail?.data, modifyCrmContactAddressDetail?.data])

    const sortDefaultAddresses = (addressItem1, addressItem2) => {
        if (addressItem1?.id === crmContactDetail?.data?.address?.id) return -1;
        if (addressItem2?.id === crmContactDetail?.data?.address?.id) return 1;
        return 0;
    }

    const onHandleAddNewAddressDetail = () => {
        if (modifyCrmContactAddressDetail?.isLoading || destroyCrmContactAddressDetail?.isLoading || modifyCrmContactDetail?.isLoading) return;

        if (editBtnConst) return;

        let myLocalCountryList = countryList?.countryList?.filter((country) => (
            country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
        ))

        if (myLocalCountryList.length === 0) {
            myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.countryDomain === "US"
            ))
        }
        const defaultAddress = modifyCrmContactDetail?.payload?.addresses?.filter((item) => item?.is_primary)
        setNewAddressDetail({
            ...newAddressDetail,
            isShowInput: true,
            country: myLocalCountryList[0],
            label: { label: crmContactLabelsEnum.HOME.label, value: crmContactLabelsEnum.HOME.value },
            is_primary: (defaultAddress?.length > 0) ? false : true
        })
        setEditBtnConst({
            ...editBtnConst,
            id: 0,
            active: crmDetailBtnConst?.addresses.key
        })
    }

    const onHandlePrimaryAddressDetail = async (addressItemId, updatedValue) => {
        if (modifyCrmContactAddressDetail?.isLoading || destroyCrmContactAddressDetail?.isLoading || modifyCrmContactDetail?.isLoading) return;

        if (crmContactDetail?.data?.address?.id === addressItemId) return;
        setEditBtnConst(null)
        try {
            const requestPayload = {
                params: { contactId: crmContactDetail?.data?.id },
                body: {
                    default_address_id: addressItemId
                }
            }
            const response = await crmService.updateCrmContactDetail(requestPayload)
            if (response.status === 200) {
                dispatcher(setModifyCrmContactDetailPayload({
                    ...modifyCrmContactDetail?.payload,
                    addresses: modifyCrmContactDetail?.payload?.addresses?.map((address) => (
                        (address?.id === addressItemId) ? updatedValue : { ...address, is_primary: false }
                    ))
                }))
                dispatcher(setCrmContactDetailData({
                    ...crmContactDetail?.data,
                    address: { ...response.data.data.address, id: addressItemId }
                }))
                toast.success(response.data.message || "Updated Successfully")
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || error)
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        }
    }

    const onHandleSaveAddressDetail = (addressItem) => {
        if (addCrmContactAddressDetail?.isLoading || modifyCrmContactAddressDetail?.isLoading) return;
        
        if (!addressItem?.country) {
            toast.warn("Please select country")
            return;
        }
        const payload = {
            street_line_1: addressItem?.street_line_1,
            street_line_2: addressItem?.street_line_2,
            country: addressItem?.country?.country,
            state: addressItem?.state,
            city: addressItem?.city,
            post_office: addressItem?.post_office,
            zip: addressItem?.zip,
            label: addressItem?.label?.value,
            is_primary: addressItem?.is_primary
        }
        dispatcher(updateCrmContactAddressDetail(addressItem?.id, payload))
        setNewAddressDetail(addressItem)
    }

    const onHandleDeleteAddressDetail = useCallback((addressId) => {
        if (modifyCrmContactAddressDetail?.isLoading || destroyCrmContactAddressDetail?.isLoading) return;
        if (!window.confirm("Are you sure?. You want to delete address details.")) return;

        dispatcher(deleteCrmContactAddressDetail(addressId))
        setEditBtnConst(null)
    }, [modifyCrmContactAddressDetail?.isLoading, destroyCrmContactAddressDetail?.isLoading])

    const onHandleNewPrimaryAddress = useCallback((addressItem, updatedValue) => {
        if (newAddressDetail?.is_primary === updatedValue?.is_primary) return;
        setNewAddressDetail(updatedValue)
    }, [newAddressDetail])

    const onHandleSaveNewAddress = (addressItem) => {
        if (addCrmContactAddressDetail?.isLoading || modifyCrmContactAddressDetail?.isLoading) return;
        if (!addressItem?.country) {
            toast.warn("Please select country")
            return;
        }
        if (!addressItem?.street_line_1) {
            toast.warn("Please enter street 1 address!")
            return;
        }
        if (!addressItem?.label?.value) {
            toast.warn("Please select label!")
            return;
        }
        const duplicateAddress = modifyCrmContactDetail?.payload?.addresses?.filter((address) => (address?.address === addressItem?.street_line_1))
        if (!!duplicateAddress?.length) {
            toast.warn("Address already added!")
            return;
        }
        const payload = {
            contact_id: modifyCrmContactDetail?.payload?.contact_id,
            street_line_1: addressItem?.street_line_1,
            street_line_2: addressItem?.street_line_2,
            country: addressItem?.country?.country,
            state: addressItem?.state,
            city: addressItem?.city,
            post_office: addressItem?.post_office,
            zip: addressItem?.zip,
            label: addressItem?.label?.value,
            is_primary: addressItem?.is_primary
        }
        dispatcher(createCrmContactAddressDetail(payload))
        setNewAddressDetail(addressItem)
    }

    const onHandleDeleteNewAddress = useCallback(() => {
        setNewAddressDetail(newAddressInitialState)
        setEditBtnConst(null)
    }, [])

    return (
        <div className="w-full flex flex-col gap-8 items-center mt-5">
            {!!modifyCrmContactDetail?.payload?.addresses?.length &&
                modifyCrmContactDetail?.payload?.addresses?.slice(0)?.sort((item1, item2) => sortDefaultContact(item1?.id, item2?.id, crmContactDetail?.data?.address?.id))?.map((addressItem, index) => (
                    <ContactAddressItem
                        key={addressItem?.id}
                        index={index}
                        isLoading={modifyCrmContactAddressDetail?.isLoading || destroyCrmContactAddressDetail?.isLoading}
                        isShowSaveBtn={true}
                        isShowCancelBtn={true}
                        addressItem={addressItem}
                        editBtnConst={editBtnConst}
                        setEditBtnConst={setEditBtnConst}
                        onHandlePrimaryAddressDetail={onHandlePrimaryAddressDetail}
                        onHandleSaveAddressDetail={onHandleSaveAddressDetail}
                        onHandleDeleteAddressDetail={onHandleDeleteAddressDetail}
                    />
                ))}
            {newAddressDetail?.isShowInput &&
                <AddContactAddressItem
                    isLoading={addCrmContactAddressDetail?.isLoading}
                    isShowSaveBtn={true}
                    isShowCancelBtn={true}
                    addressItem={newAddressDetail}
                    onHandleChangeAddressDetail={(AddressId, updatedValue) => setNewAddressDetail(updatedValue)}
                    onHandlePrimaryAddressDetail={onHandleNewPrimaryAddress}
                    onHandleSaveAddressDetail={onHandleSaveNewAddress}
                    onHandleDeleteAddressDetail={onHandleDeleteNewAddress}
                />
            }
            {!newAddressDetail?.isShowInput &&
                <AddItemBtn
                    btnTitle={"Add Address"}
                    onHandleAddBtn={onHandleAddNewAddressDetail}
                    isBtnDisable={editBtnConst}
                />
            }
        </div>
    );
}

export default EditContactAddresses;