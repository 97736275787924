import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "./crm.const";

class CrmService {
    static crmService = new CrmService()

    getCrmContactList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_CRM_CONTACT_LIST, params),
            { params: query }
        )

        return response;
    }

    updateCrmBulkContactList = async ({ body }) => {
        const response = await baseAuthApiInstance().put(
            url.UPDATE_BULK_CONTACT_LIST,
            body
        )

        return response;
    }

    deleteCrmBulkContactList = async ({ body }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_BULK_CONTACT_LIST),
            { data: body }
        )

        return response;
    }

    createCrmContactDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.CREATE_CRM_CONTACT_DETAIL,
            body
        )

        return response;
    }

    getCrmContactDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_CRM_CONTACT_DETAIL, params)
        )

        return response;
    }

    updateCrmContactDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_CRM_CONTACT_DETAIL, params),
            body
        )

        return response;
    }

    deleteCrmContactDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_CRM_CONTACT_DETAIL, params)
        )

        return response;
    }

    getCrmContactStatusList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            url.GET_CRM_CONTACT_STATUS_LIST,
            { params: query }
        )

        return response;
    }

    getCrmContactLabelList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            url.GET_CRM_CONTACT_LABEL_LIST,
            { params: query }
        )

        return response;
    }
    createCrmContactLabelDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.CREATE_CRM_CONTACT_LABEL_DETAIL, body
        )

        return response;
    }
    updateCrmContactLabelDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_CRM_CONTACT_LABEL_DETAIL, params),
            body
        )

        return response;
    }

    createContactLabelDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.CREATE_CONTACT_LABEL_DETAIL, body
        )

        return response;
    }

    deleteContactLabelDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_CONTACT_LABEL_DETAIL, params)
        )

        return response;
    }

    createCrmContactEmailDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.CREATE_CRM_CONTACT_EMAIL_DETAIL, body
        )

        return response;
    }

    updateCrmContactEmailDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_CRM_CONTACT_EMAIL_DETAIL, params),
            body
        )

        return response;
    }

    deleteCrmContactEmailDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_CRM_CONTACT_EMAIL_DETAIL, params)
        )

        return response;
    }

    createCrmContactPhoneDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.CREATE_CRM_CONTACT_PHONE_DETAIL, body
        )

        return response;
    }

    updateCrmContactPhoneDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_CRM_CONTACT_PHONE_DETAIL, params),
            body
        )

        return response;
    }

    deleteCrmContactPhoneDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_CRM_CONTACT_PHONE_DETAIL, params)
        )

        return response;
    }

    createCrmContactSocialDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.CREATE_CRM_CONTACT_SOCIAL_DETAIL, body
        )

        return response;
    }

    updateCrmContactSocialDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_CRM_CONTACT_SOCIAL_DETAIL, params),
            body
        )

        return response;
    }

    deleteCrmContactSocialDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_CRM_CONTACT_SOCIAL_DETAIL, params)
        )

        return response;
    }

    createCrmContactAddressDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.CREATE_CRM_CONTACT_ADDRESS_DETAIL, body
        )

        return response;
    }

    updateCrmContactAddressDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_CRM_CONTACT_ADDRESS_DETAIL, params),
            body
        )

        return response;
    }

    deleteCrmContactAddressDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_CRM_CONTACT_ADDRESS_DETAIL, params)
        )

        return response;
    }

    createCrmContactNoteDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.CREATE_CRM_CONTACT_NOTE_DETAIL, body
        )

        return response;
    }

    updateCrmContactNoteDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_CRM_CONTACT_NOTE_DETAIL, params),
            body
        )

        return response;
    }

    deleteCrmContactNoteDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_CRM_CONTACT_NOTE_DETAIL, params)
        )

        return response;
    }
}

export default CrmService.crmService;