export const url = {
    GET_TEMP_COURSE_LIST: "/templates/course/list"
}

export const filterName = {
    CREATE_COURSE: {
        value: "create_course",
    }
}

export const tempCourseType = {
    ALL: {
        value: "all",
        label: "All"
    },
    ONE_ON_ONE: {
        value: "one_on_one",
        label: "One on One"
    },
    GROUP: {
        value: "group",
        label: "Group"
    }
}

export const tempStatus = {
    ALL: {
        value: 'all',
        label: 'All',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    UNDER_REVIEW: {
        value: 'under_review',
        label: 'Under Review',
        lightColor: 'blue-100',
        darkColor: 'blue-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
}

export const tempIsVerified = {
    VERIFIED: {
        value: 'verified',
        label: "Verified"
    },
    NOT_VERIFIED: {
        value: 'not_verified',
        label: "Not Verified"
    },
}