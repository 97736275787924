export const url = {
    GET_LMS_RESOURCE_LIST: "lms/resources/tutor-resources",
    CREATE_LMS_RESOURCE_DETAIL: "lms/resources/resources"
}

export const lmsResourceTypeEnum = {
    FILE: {
        label: "File",
        value: "file"
    },
    YOUTUBE: {
        label: "Youtube",
        value: "youtube"
    },
    LINK: {
        label: "Link",
        value: "link"
    }
}

export const resourceFileTypeEnum = {
    VIDEO: {
        label: "Video",
        value: "video"
    },
    IMAGE: {
        label: "Image",
        value: "image"
    },
    SLIDE: {
        label: "Slide",
        value: "slide"
    },
    PDF: {
        label: "PDF",
        value: "pdf"
    },
    DOC: {
        label: "Doc",
        value: "doc"
    },
    SHEET: {
        label: "Sheet",
        value: "sheet"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}
export const lmsResourceStatusEnum = {
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    }
}

export const lmsResourceFilterProps = {
    page: 1,
    records: 5,
    search: "",
    resourceType: "",
    resourceFileType: "",
    disabledFilters: []
}