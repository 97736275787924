import { setAndGetLocalStorage } from "redux/local/local.utils";
import { localsConst } from "redux/local/local.const";

import store from "redux/store";
import { setLocals } from "redux/local/local.slice";


export const setMasterLocals = (master) => {
    const { dispatch } = store
    const { locals } = store.getState().local
    
    const currencyCode = setAndGetLocalStorage(localsConst.CURRENCY_CODE.value)
    const timeZone = setAndGetLocalStorage(localsConst.TIME_ZONE.value)

    dispatch(setLocals({
        ...locals,
        [localsConst.CURRENCY_CODE.value]: master?.currencyCode || currencyCode,
        [localsConst.TIME_ZONE.value]: master?.timeZone || timeZone,
    }))
}