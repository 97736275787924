import { useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import PageTabs from 'pages/auth/edulyteLms/commonComponents/navigations/PageTabs';

import { courseParticipantsNavigationOptions } from '../../editLMSCourse.data';

import { useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const EditLMSCourseParticipantsPage = () => {
    const { lmsCourseDetail } = useAppState((state) => state.lms.lmsCourse)

    const navigate = useNavigate()

    const onHandleSelectTab = useCallback((activeTab) => {
        navigate(`${pagesInfo?.EDIT_LMS_COURSE?.pagePath}/${lmsCourseDetail?.data?.id}/edit/${courseParticipantsNavigationOptions[activeTab?.value?.toUpperCase()]?.to}`)
    }, [lmsCourseDetail?.data?.id])

    return (
        <div className={"flex flex-col space-y-3"}>
            <PageTabs
                tabs={Object.values(courseParticipantsNavigationOptions)}
                onHandleTabs={onHandleSelectTab}
            />
            <div className="w-full rounded-lg overflow-hidden p-5 bg-white">
                <Outlet />
            </div>
        </div>
    )
}

export default EditLMSCourseParticipantsPage;