import { useCallback, useEffect, useReducer, useState } from "react";

import { cn } from "utils/cn.utils";

import { AnimatePresence, motion } from "framer-motion";

import { AiOutlineClose } from "react-icons/ai";
import { MdEdit } from "react-icons/md";

import ButtonLoader from "components/loader/ButtonLoader";

import { appointmentActions, appointmentReducer } from "./data";

import { createAppointmentDetail } from "redux/appointment/appointment.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { resetAddAppointmentDetail } from "redux/appointment/appointment.slice";
import { modalConst } from "redux/local/local.const";

import { pagesInfo } from "utils/pagesInfo";

import { EDULYTE_APP_DOMAIN_URL } from "const/default.const";

const MAX_LENGTH = 128;

const CreateAppointmentDetailModalContent = ({ closeModal }) => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { addAppointmentDetail } = useAppState((state) => state.appointment)

    const dispatcher = useAppDispatcher()

    const [state, dispatch] = useReducer(appointmentReducer, {
        title: "",
        subtitle: "",
        titleError: ""
    })
    const [isShowSubHeading, setIsShowSubHeading] = useState(false)

    useEffect(() => {
        return () => {
            dispatcher(resetAddAppointmentDetail())
        }
    }, [])

    useEffect(() => {
        if (addAppointmentDetail?.data) {
            const appointmentId = addAppointmentDetail?.data?.result?.id
            dispatcher(resetAddAppointmentDetail())
            dispatcher(setModal({
                ...modal,
                [modalConst.createAppointmentDetailModal.key]: {
                    ...modalConst.createAppointmentDetailModal,
                    isVisible: false
                }
            }));
            window.open(`${EDULYTE_APP_DOMAIN_URL}${pagesInfo.EDIT_APPOINTMENT.pagePath}/${appointmentId}/edit`, "_self")
        }
    }, [addAppointmentDetail?.data])

    const onHandleCloseModal = () => {
        dispatcher(resetAddAppointmentDetail())
        closeModal()
    }

    const onHandleChangeInput = useCallback((event) => {
        if (addAppointmentDetail?.isLoading) return;

        dispatch({
            type: event.target.name,
            payload: event.target.value
        })
    }, [addAppointmentDetail?.isLoading, state])

    const onHandleCreateNewAppointment = useCallback(() => {
        if (addAppointmentDetail.isLoading) return;

        if (!state.title || (state.title?.length === 0)) {
            dispatch({
                type: appointmentActions.TITLE_ERROR.action,
                payload: "Please enter title!"
            })
            return;
        }

        const body = {
            user_id: user.user.userId,
            title: state.title
        }
        if (state.subtitle?.length > 0) {
            body["subtitle"] = state.subtitle
        }
        dispatcher(createAppointmentDetail(body))
        dispatch({
            type: appointmentActions.INITIAL_STATE.action
        })
    }, [addAppointmentDetail?.isLoading, state])

    return (
        <>
            <div className={"flex w-full justify-between items-center bg-background-light h-16 px-5 drop-shadow-sm"}>
                <p className="font-bodyPri font-medium text-text-900 text-lg">
                    {"Create an appointment"}
                </p>
                <AiOutlineClose
                    onClick={onHandleCloseModal}
                    className={'cursor-pointer'}
                    size={20}
                />
            </div>
            <div className={"w-full flex flex-col gap-5 bg-white p-10"}>
                <div className={"w-full flex flex-col gap-1.5"}>
                    <input
                        className={cn(
                            "px-3 h-12 flex w-full focus:outline-none rounded-lg border border-background-lightDark focus:border focus:border-primary-main",
                            "font-bodyPri font-normal text-text-800 text-base placeholder:text-text-500"
                        )}
                        type={"text"}
                        placeholder={'Session with [Your Name]'}
                        name={appointmentActions.TITLE.action}
                        maxLength={MAX_LENGTH}
                        value={state.title}
                        onChange={onHandleChangeInput}
                    />
                    <div className={"w-full flex justify-end"}>
                        {(state.title?.length > 0) &&
                            <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                                {`${state.title?.length}/${MAX_LENGTH}`}
                            </span>
                        }
                    </div>
                </div>
                <AnimatePresence initial={isShowSubHeading}>
                    {!isShowSubHeading &&
                        <div
                            className={"w-full flex items-center justify-between gap-3 cursor-pointer pt-1 group"}
                            onClick={() => setIsShowSubHeading(true)}
                        >
                            <span className={"font-bodyPri font-normal text-primary-dark text-sm tracking-wide line-clamp-2"}>
                                {state.subtitle
                                    ? state.subtitle
                                    : "Sub Heading (optional)"
                                }
                            </span>
                            <MdEdit className={"text-xl text-text-900 hidden group-hover:flex"} />
                        </div>
                    }
                    {isShowSubHeading &&
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                            className={"w-full"}
                        >
                            <textarea
                                type={'text'}
                                className={cn(
                                    'w-full border outline-none rounded-lg p-2 focus:border-primary-main opacity-100 transition-opacity duration-300',
                                    'font-bodyPri font-normal text-text-800 text-sm tracking-wide placeholder:text-text-500'
                                )}
                                placeholder={"Sub Heading (optional)"}
                                name={appointmentActions.SUB_TITLE.action}
                                value={state.subtitle}
                                rows={3}
                                onChange={onHandleChangeInput}
                                autoFocus={true}
                                onBlur={() => setIsShowSubHeading(false)}
                            />
                        </motion.div>
                    }
                </AnimatePresence>
                <div className={"w-full flex items-center justify-start"}>
                    {(addAppointmentDetail?.message || state.titleError) &&
                        <span className={"w-full font-bodyPri font-normal text-red-500 text-base tracking-wide text-center"}>
                            {addAppointmentDetail?.message || state.titleError}
                        </span>
                    }
                </div>
                <button
                    className={cn(
                        'font-bodyPri font-normal py-2 w-36 bg-primary-dark text-white rounded-full hover:bg-secondary-dark ease-in-out duration-100',
                        addAppointmentDetail?.isLoading && "bg-primary-dark cursor-wait"
                    )}
                    onClick={onHandleCreateNewAppointment}
                >
                    {addAppointmentDetail?.isLoading &&
                        <ButtonLoader isLoading={addAppointmentDetail?.isLoading} />
                    }
                    {!addAppointmentDetail?.isLoading && "Create"}
                </button>
            </div>
        </>
    )
}

export default CreateAppointmentDetailModalContent;