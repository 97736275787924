import React from 'react';
import { styled } from '@mui/material/styles';
import tw from 'twin.macro';
import PercentIcon from '@mui/icons-material/Percent';
import { useAppState } from "hooks/useStore";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const DivDialog = tw.div`text-2xl`
const DialogOpenDiv = tw.div`
border border-solid px-1 py-1 mx-2 my-1
`;
const Div = tw.div`sm:w-full 
flex flex-row flex-wrap justify-between h-full px-2 py-2  overflow-hidden bg-white rounded-md my-2 space-y-6 border  border-green-600 
`;
const Div1 = tw.div`flex justify-between flex-wrap w-full h-full `
const Div2 = tw.div
    `flex flex-wrap w-full h-full text-yellow-500 mx-5
`;
const Div3 = tw.div
    `flex justify-between flex-wrap w-full h-full `
const CouponCard = () => {
    const state = useAppState((s) => s.offers.discounts);
    
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        // setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const BootstrapButton = styled(Button)({
        boxShadow: 'none',
        textTransform: 'none',
    },
    );
    interface DialogTitleProps {
        id: string;
        children?: React.ReactNode;
        onClose: () => void;
    }
    const BootstrapDialogTitle = (props: DialogTitleProps) => {
        const { children, onClose, ...other } = props;
        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}>
                        <CloseIcon color='error' />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    return (

        <div>
            {state.map((items: any) => (
                <Div>
                    <Div1><div className='text-secondary-main text-4xl px-9 py-3 font-bold'><h3>{items.name}
                    </h3></div>
                        <div className='my-5'>
                            <div className=' w-12 h-12 relative'>
                                <div className='absolute w-full h-full bg-yellow-300'></div>
                                <div className='absolute w-full h-full bg-yellow-300 rotate-[22.5deg]'>
                                </div>
                                <div className='absolute w-full h-full bg-yellow-300 rotate-[45deg]'></div>
                                <div className='absolute w-full h-full bg-yellow-300 rotate-[67.5deg]'>
                                </div>
                                <div className='absolute w-full h-full z-20 flex items-center justify-center'>
                                    <PercentIcon style={{ fontSize: '2.3rem', color: '#FF4081', fontWeight: 'bold' }} />
                                </div>

                            </div>
                        </div>
                        <div>
                            <BootstrapButton color='primary' variant='text' size='small' onClick={handleClickOpen} disableRipple> Know more</BootstrapButton>

                            <Dialog open={open} onClose={handleClose}>
                                <DialogOpenDiv>

                                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                        Terms & Conditions
                                    </BootstrapDialogTitle>
                                    {items.termsAndConditions.map((item: any) => (
                                        <DialogContent dividers>
                                            <DivDialog><li>{item.title}</li>
                                            </DivDialog>
                                            <Typography gutterBottom>
                                                {item.content}
                                            </Typography>

                                        </DialogContent>))}
                                </DialogOpenDiv>
                            </Dialog>

                        </div>
                    </Div1>
                    <Div2><div className='text-4xl px-5 font-bold'><h1 >Use Code:  {items.code}</h1></div></Div2>
                    <Div3> <div className='px-9 py-5 font-semibold'><h4>{items.discount} off on your next lesson</h4></div>
                        <button className='text-red-600 bg-yellow-400 px-5 m-5 w-100 h-8 font-bold'>Redeem now</button></Div3>
                </Div>))}
        </div>
    )
}

export default CouponCard;