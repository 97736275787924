import React from "react";
import { cn } from "utils/cn.utils";
import { Link } from "react-router-dom";

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import Icon from "./Icon";

import { TimelineView } from "pages/auth/dashboard/components/TimelimeView";

import { getDashboardCalendarEventList } from "redux/session/session.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetDashboardCalendarEventList } from "redux/session/session.slice";
import { userRoles } from "redux/auth/auth.const";

import { dayjs, getTimeZoneOffset, timeZone } from 'utils/dateTime.utils';

const CalendarComponent = () => {
  const { locals } = useAppState((s: any) => s.local)
  const { user } = useAppState((state: any) => state.user)

  const dispatcher = useAppDispatcher()

  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
  const [optionsVisible, setOptionsVisible] = React.useState(false);

  React.useEffect(() => {

    return () => {
      dispatcher(resetDashboardCalendarEventList())
    }
  }, [])

  React.useEffect(() => {
    if (selectedDate) {
      dispatcher(getDashboardCalendarEventList(user?.user?.userId, { startDateTime: dayjs(selectedDate)?.utc()?.format("YYYY-MM-DD HH:mm:ssZ") }))
    }
  }, [selectedDate])

  return (
    <div className="p-5 space-y-3 w-full bg-white rounded-lg flex flex-col lg:flex-col">
      <div className="flex justify-between">
        <span className="text-lg font-medium font-bodyPri text-text-900 tracking-wider">
          {"Schedule"}
        </span>
        <span className="text-sm text-text-700 font-bodyPri whitespace-nowrap">
          {`${timeZone} ${getTimeZoneOffset(timeZone)}`}
        </span>
      </div>
      <div className="flex flex-col items-center justify-center w-full">
        <Calendar
          onChange={(newDate: Date) => setSelectedDate(newDate)}
          value={selectedDate}
          showNeighboringMonth={true}
          locale={"en-US"}
        />
      </div>
      <div className="w-full space-y-2">
        <div className="w-full flex justify-between">
          <p className="w-full flex justify-center text-medium text-text-600 font-bodypri font-medium tracking-wide">
            {dayjs().isSame(selectedDate, "day")
              ? "Today's Schedule"
              : `${dayjs(selectedDate).format("dddd, MMMM DD")} Schedule`}
          </p>
          {(locals.userRole === userRoles.TUTOR.value) &&
            <div className="relative">
              <button
                className=""
                onClick={() => setOptionsVisible(!optionsVisible)}
              >
                <Icon type="threedot2" />
              </button>
              {optionsVisible && (
                <Link to={"/settings/preferences/availability"}>
                  <div
                    className={cn(
                      "absolute w-52 right-0 -bottom-7",
                      "px-3 py-2 bg-text-100 rounded-md shadow-xl",
                      "font-bodyPri text-sm border-2 cursor-pointer"
                    )}
                  >
                    <p>{"Set Weekly Schedule"}</p>
                  </div>
                </Link>
              )}
            </div>
          }
        </div>
        <div className={cn(
          "max-h-full overflow-scroll",
          "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm scrollbar-w-sm",
          "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
        )}>
          <TimelineView selectedDate={selectedDate} />
        </div>
      </div>
    </div>
  );
};

export default CalendarComponent;