import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { cn } from "utils/cn.utils";

import { AiOutlineCheckCircle } from 'react-icons/ai';
import { MdContentCopy } from 'react-icons/md';

import { socialLinks } from '../eventlyData';

import { useAppState } from 'hooks/useStore';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';

const ShareEventlyDetail = () => {
    const { userEventlyDetail } = useAppState((state) => state.evently)

    const publicEventlyUrl = useMemo(() => (`${EDULYTE_WEB_DOMAIN_URL}/evently/${userEventlyDetail?.data?.id}`), [userEventlyDetail?.data])

    const [isURLCopied, setIsURLCopied] = useState("")

    const handleCopyLink = () => {
        navigator.clipboard.writeText(publicEventlyUrl);
        setIsURLCopied(true);
        toast.success("Copied!")
        setTimeout(() => {
            setIsURLCopied(false);
        }, 2000);
    }

    return (
        <div className={"flex flex-col justify-center items-center gap-5"}>
            <div className="font-bodyPri font-semibold text-text-900 text-md">
                {"Share Via"}
            </div>
            <div className={"w-full flex flex-col items-center justify-center gap-5"}>
                <div
                    className={cn(
                        "w-96 relative px-3 py-2 border border-text-400 rounded-md",
                        "focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600"
                    )}
                >
                    <label
                        htmlFor={"name"}
                        className="absolute inline-block px-1 -mt-px text-xs font-medium text-gray-900 bg-white -top-2 left-2"
                    >
                        {"Evently Link"}
                    </label>
                    <div className={"w-full flex items-center justify-between gap-3"}>
                        <input
                            type={"text"}
                            className={cn(
                                "block w-full p-0 text-gray-900 placeholder-gray-500 ",
                                "border-0 focus:ring-0 sm:text-sm focus:outline-none"
                            )}
                            value={publicEventlyUrl}
                        />
                        <span className={"cursor-pointer"} onClick={handleCopyLink}>
                            {!isURLCopied
                                ? <MdContentCopy className={"text-xl text-text-700"} />
                                : <AiOutlineCheckCircle className={"text-xl text-text-700"} />
                            }
                        </span>
                    </div>
                </div>

                <div className='flex flex-wrap gap-3'>
                    <div className={"flex flex-col items-center justify-start gap-1.5"}>
                        <div
                            className={"w-[2.5rem] h-[2.5rem] flex items-center justify-center bg-primary-dark cursor-pointer"}
                            onClick={handleCopyLink}
                        >
                            {!isURLCopied
                                ? <MdContentCopy className={"text-2xl text-text-50"} />
                                : <AiOutlineCheckCircle className={"text-2xl text-text-50"} />
                            }

                        </div>
                        <span className={"font-bodyPri font-normal text-text-900 text-xs"}>
                            {"Copy"}
                        </span>
                    </div>
                    {socialLinks && Object.values(socialLinks).map((socialLink) =>
                        <div className={"flex flex-col items-center justify-start gap-0.5 "}>
                            <span>
                                {socialLink?.component({ url: publicEventlyUrl, size: 40 })}
                            </span>
                            <span className={"font-bodyPri font-normal text-text-900 text-xs"}>
                                {socialLink?.label}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ShareEventlyDetail;