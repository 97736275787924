import { baseApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/segment/segment.const"

class SegmentListService {
    getAllSegmentCategoryList = async () => {
        const response = await baseApiInstance().get(
            url.SEGMENT_CATEGORY_LIST
        )
        return response
    }
}

export default new SegmentListService();