import { toast } from "react-toastify";

import { database, auth, RecaptchaVerifier } from "config/firebase.config";
import {
    doc, getDoc, getDocs, query, setDoc, addDoc, Timestamp, collection,
    orderBy, updateDoc, arrayUnion, arrayRemove, deleteDoc, onSnapshot,
} from 'firebase/firestore';

import { signInWithPhoneNumber } from "firebase/auth";


// const userId = "cd3obmfY6wKHQXiLVLqj"  // teacher
// const userId = "LxMGncg4PVaMLfXnIawl" // student


export const getFireBaseUser = async (user, userContext) => {
    userContext.dispatch({ type: "LOAD_USER" })
    const fbUser = await createFBUser(user)
    if (fbUser) {
        await setFBUserOnline(fbUser.userId)
        userContext.dispatch({ type: "SUCCESS_USER", payload: fbUser })
    } else {
        userContext.dispatch({ type: "ERROR_USER", payload: { message: 'User not found!' } })
    }
}

export const getFBUserRef = async (userId) => {
    return doc(database, "users", userId.toString())
}

export const getFBChatRef = async (chatId) => {
    return doc(database, "chats", chatId.toString())
}

export const getFBChatMessageRef = async (chatId) => {
    return collection(database, "chats", chatId, "messages")
}

export const getFBChatMembersRef = async (chatId) => {
    return collection(database, 'chats', chatId.toString(), 'members')
}

export const getFBChatMemberRef = async (chatId, userId) => {
    return doc(database, 'chats', chatId.toString(), 'members', userId.toString())
}


export const getFBUser = async (userId) => {
    const userRef = await getFBUserRef(userId.toString())
    const userDocument = await getDoc(userRef)
    if (!userDocument.exists())
        return null

    return userDocument.data()
};

export const createFBUser = async (user) => {
    const fbUser = await getFBUser(user.userId.toString())
    if (fbUser)
        return fbUser

    const userRef = await getFBUserRef(user.userId.toString())
    const newUser = {
        userId: user.userId.toString(),
        firstName: user.firstName,
        lastName: user.lastName,
        profilePicUrl: user.profilePicUrl,
        roles: [...user.roles],
        memberShip: [...user.memberShip]
    }
    await setDoc(userRef, newUser)
    const userDocument = await getDoc(userRef)
    return userDocument.data()
}

export const getFBChatId = async (userIdOne, userIdTwo) => {
    return [userIdOne.toString(), userIdTwo.toString()].sort().join(':')
}

export const getFBChat = async (chatId) => {
    const chatRef = await getFBChatRef(chatId.toString())
    const chatDocument = await getDoc(chatRef)
    if (!chatDocument.exists())
        return null

    return chatDocument.data()
}

export const createFBIndividualChat = async (userIdOne, userIdTwo) => {
    const chatId = await getFBChatId(userIdOne.toString(), userIdTwo.toString())
    const fbChat = await getFBChat(chatId)
    if (fbChat)
        return fbChat

    const userDetailsOne = await getFBUser(userIdOne.toString())
    const userDetailsTwo = await getFBUser(userIdTwo.toString())

    if (!userDetailsOne || !userDetailsTwo)
        return null

    const fbChatId = await getFBChatId(userIdOne.toString(), userIdTwo.toString())
    const chatRef = await getFBChatRef(fbChatId);
    const newChat = {
        [userDetailsOne.userId]: {
            name: `${userDetailsOne.firstName} ${userDetailsOne.lastName}`,
            image: userDetailsOne.profilePicUrl,
            typing: false,
            startedAt: Timestamp.now()
        },
        [userDetailsTwo.userId]: {
            name: `${userDetailsTwo.firstName} ${userDetailsTwo.lastName}`,
            image: userDetailsTwo.profilePicUrl,
            typing: false,
            startedAt: Timestamp.now()
        },
        type: "individual",
        lastMessage: {
            sentAt: Timestamp.fromDate(new Date()),
        },
        meeting: {},
        started: {
            [userDetailsOne.userId]: Timestamp.now(),
            [userDetailsTwo.userId]: Timestamp.now(),
        },
        users: fbChatId.split(":"),
        chatId: fbChatId
    }
    await setDoc(chatRef, newChat)
    const chatDocument = await getDoc(chatRef)
    return chatDocument.data()
}

export const createFBGroupChat = async (groupChatDetails) => {
    const chatId = groupChatDetails.groupId.toString()
    const fbChat = await getFBChat(chatId)
    if (fbChat)
        return fbChat

    const chatRef = await getFBChatRef(chatId)
    const newChat = {
        chatId: chatId,
        name: groupChatDetails.name,
        image: groupChatDetails.image,
        type: 'group',
        lastMessage: {
            sentAt: Timestamp.fromDate(new Date()),
        },
        meeting: {},
        users: []
    }
    await setDoc(chatRef, newChat)
    const chatDocument = await getDoc(chatRef)
    return chatDocument.data()
}

export const sendFBMessage = async (messageDetails) => {
    const chatId = messageDetails.chatId.toString()
    const fbChatRef = await getFBChatRef(chatId)
    const fbChat = await getFBChat(chatId)

    if (!fbChat) return;

    const messageObject = {
        sender: messageDetails.senderUserId.toString(),
        text: messageDetails.text,
        type: "text",
        status: "sent",
        sentAt: Timestamp.fromDate(new Date())
    }
    const messageRef = await getFBChatMessageRef(chatId)
    await addDoc(messageRef, messageObject)
    await updateDoc(fbChatRef, { lastMessage: messageObject });

    if (fbChat?.type === 'group') {
        const membersRef = await getFBChatMembersRef(fbChat?.chatId)
        const membersQuery = query(membersRef);
        const membersSnapshot = await getDocs(membersQuery)
        const memberIds = membersSnapshot?.docs?.map(doc => doc.id)?.filter(id => id !== messageDetails.senderUserId.toString())
        memberIds?.forEach(async (memberId) => {
            let memberRef = await getFBChatMemberRef(fbChat?.chatId, memberId)
            let memberObject = await getDocs(memberRef)
            await updateDoc(memberRef, { unreadMessages: `${parseInt(memberObject?.unreadMessages) + 1}`, });
        })

    }
}

export const sendNotifications = async (notificationObject) => {
    // Individual (chatId, senderUserId, receiverUserId, type), Group (chatId, senderUserId, type)
    const fbChat = await getFBChat(notificationObject?.chatId.toString())
    if (!fbChat) return;

    if (fbChat?.type === 'group') {
        const membersRef = await getFBChatMembersRef(fbChat?.chatId)
        const membersQuery = query(membersRef);
        const membersSnapshot = await getDocs(membersQuery)
        const memberIds = membersSnapshot?.docs?.map(doc => doc.id)?.filter(id => id !== notificationObject?.senderUserId.toString())

        const notificationPayload = {
            type: notificationObject?.type,
            sentAt: Timestamp.fromDate(new Date()),
        }

        let notificationRef = await doc(database, 'groupnotifications', fbChat?.chatId)
        let notificationDoc = await getDocs(notificationRef)
        if (!notificationDoc.exists()) {
            const newNotification = {
                ...notificationPayload,
                groupName: fbChat?.name,
                chatId: fbChat?.chatId,
                chatType: fbChat?.type,
                members: memberIds,
                muted: [],
            };
            await addDoc(notificationRef, newNotification)
        } else {
            await updateDoc(notificationRef, { ...notificationPayload, members: memberIds })
        }

    } else if (fbChat?.type === 'individual') {
        const userDetailsSender = await getFBUser(notificationObject?.senderUserId.toString())
        const userDetailsReceiver = await getFBUser(notificationObject?.receiverUserId.toString())
        let notificationPayload = {
            fromName: `${userDetailsSender?.firstName} ${userDetailsSender?.lastName}`,
            fromId: userDetailsSender?.userId,
            receiver: userDetailsReceiver?.userId,
            type: notificationObject?.type,
            seen: false,
            chatId: fbChat?.chatId,
            chatType: fbChat?.type,
            sentAt: Timestamp.fromDate(new Date()),
        };
        const notificationsRef = await collection(database, 'notifications')
        await addDoc(notificationsRef, notificationPayload)
    }
}

export const getFBMessages = async (chatId) => {
    const chatMessageRef = await getFBChatMessageRef(chatId.toString())
    const messagesQuery = await query(chatMessageRef, orderBy('sentAt', 'asc'))
    const messagesSnapshot = await getDocs(messagesQuery)
}

export const addFBUserToGroup = async (groupId, userDetails) => {
    const chatRef = await getFBChatRef(groupId.toString())
    const chatMemberRef = await getFBChatMemberRef(groupId.toString(), userDetails.userId.toString())

    const newUser = {
        name: `${userDetails.firstName} ${userDetails.lastName}`,
        image: userDetails.profilePicUrl,
        role: userDetails.groupUserRole,
        unreadMessages: "0",
    }

    await updateDoc(chatRef, { users: arrayUnion(userDetails.userId.toString()) })
    await setDoc(chatMemberRef, newUser)
}

export const removeFBUserFromGroup = async (groupId, userId) => {
    const chatRef = await getFBChatRef(groupId.toString())
    const chatMemberRef = await getFBChatMemberRef(groupId.toString(), userId.toString())
    await updateDoc(chatRef, { users: arrayRemove(userId) });
    await deleteDoc(chatMemberRef);
}

export const updateFBUserProfile = async (userDetails) => {
    const updateUser = {}
    const userRef = await getFBUserRef(userDetails.userId.toString())

    const firstName = userDetails.get('firstName', null)
    if (firstName) updateUser['firstName'] = firstName

    const lastName = userDetails.get('lastName', null)
    if (lastName) updateUser['lastName'] = lastName

    const profilePicUrl = userDetails.get('profilePicUrl', null)
    if (profilePicUrl) updateUser['profilePicUrl'] = profilePicUrl

    const roles = userDetails.get('roles', null)
    if (roles) updateUser['roles'] = roles

    const memberShip = userDetails.get('memberShip', null)
    if (memberShip) updateUser['memberShip'] = memberShip

    await updateDoc(userRef, updateUser)
}

export const setFBUserLogin = async (userId, userContext) => {
    userContext.dispatch({ type: "LOAD_USER" })
    const fbUser = await getFBUser(userId)
    if (fbUser) {
        await setFBUserOnline(fbUser.userId)
        userContext.dispatch({ type: "SUCCESS_USER", payload: fbUser })
    } else {
        userContext.dispatch({ type: "ERROR_USER", payload: { message: 'User not found!' } })
    }
}

export const setFBUserLogout = async (userContext) => {
    userContext.dispatch({ type: "LOAD_USER" })
    await setFBUserOffline(userContext.user.userId)
    userContext.dispatch({ type: "SUCCESS_USER", payload: null })
}

export const setFBUserOnline = async (userId) => {
    const userRef = await getFBUserRef(userId.toString())
    await updateDoc(userRef, { available: true })
}

export const setFBUserOffline = async (userId) => {
    const userRef = await getFBUserRef(userId.toString())
    await updateDoc(userRef, { lastSeen: Timestamp.fromDate(new Date()), available: false })
}

// function for generate recaptcha
export const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': (recaptchaToken) => { },
        'expired-callback': () => {
            window.recaptchaVerifier = undefined
        }
    }, auth)
}

export const sendCodeToMobileNumber = async (mobileNo) => {
    try {
        const confirmationResult = await signInWithPhoneNumber(auth, String("+" + mobileNo?.replaceAll("-", "")), window.recaptchaVerifier)
        window.confirmationResult = confirmationResult
        toast.success("Code sent successfully")
        return confirmationResult;
    } catch (error) {
        console.error(error)
        toast.error("Something went wrong")
        return;
    }
}

export const verifyMobileNumberCode = async (code) => {
    try {
        const confirmationResult = await window.confirmationResult.confirm(code)
        toast.success("Code verified successfully")
        return confirmationResult;
    } catch (error) {
        console.error(error)
        toast.error("Invalid code")
        return;
    }
}



// This object is firebase user who will be user to login
// const user = {
//   userId: "cd3obmfY6wKHQXiLVLqj",
//   email: "testmail@gmail.com",
//   mobile: "1234567890",
//   emailVerify: true,
//   mobileVerify: true,
//   firstName: "John",
//   lastName: "Doe",
//   profilePicUrl:
//     "https://firebasestorage.googleapis.com/v0/b/edulyte-7223b.appspot.com/o/resize3.jpg?alt=media&token=77a8779a-d384-45ef-bab3-1c575babc9da",
//   roles: ["teacher"],
//   memberShip: ["premium"],
// };