import { useState } from 'react'
import { cn } from "utils/cn.utils"

import { FaSpinner } from 'react-icons/fa'

import QuestionView from "../question"
import ArticleView from "../article"
import ResourceView from "../resource"

import { deleteLmsQuizResponseDetail, updateLmsQuizResponseDetail } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request'

import { useAppDispatcher, useAppState } from 'hooks/useStore'
import { lmsQuizCategoryEnum } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.const'


const SectionsView = ({ sections, quiz_response, onHandleCancel }) => {
    const { deleteLmsQuizResponse, updateLmsQuizResponse } = useAppState(state => state.lms.lmsQuiz)

    const [activeSectionIndex, setActiveSectionIndex] = useState(0)

    const dispatcher = useAppDispatcher()

    const onHandleSubmit = () => {
        if (!quiz_response?.id || updateLmsQuizResponse?.isLoading || deleteLmsQuizResponse?.isLoading) return;

        let requestBody = {
            is_completed: true
        }
        dispatcher(updateLmsQuizResponseDetail(quiz_response?.id, requestBody))
    }

    const onHandleClearResponse = () => {
        if (deleteLmsQuizResponse?.isLoading || updateLmsQuizResponse?.isLoading) return;

        dispatcher(deleteLmsQuizResponseDetail(quiz_response?.id))
    }

    const shortQuizCategory = (quiz_category1, quiz_category2) => quiz_category1?.order > quiz_category2?.order ? 1 : -1

    return (
        <>
            {sections?.map((section, index) => {

                if (activeSectionIndex !== index) {
                    return null;
                }

                let questionNo = sections?.slice(0, index)?.reduce((initial, section) => [...initial, ...section?.quiz_categories?.filter(category => category?.type === lmsQuizCategoryEnum.QUESTION.value)], [])?.length || 0

                return (
                    <div className={"w-full flex flex-col gap-3"}>
                        <div className={"relative px-3 py-2 rounded-lg border-t-8 border-primary-dark bg-white shadow-all"}>
                            {(sections?.length > 1) && (
                                <span className={"absolute z-1 -top-8 -left-0 px-3 py-1 rounded-t-lg bg-primary-dark font-bodyPri font-normal text-base text-text-50"}>
                                    {`Section ${(index + 1)} of ${sections?.length}`}
                                </span>
                            )}
                            <h4 className={"font-bodyPri font-semibold text-xl text-text-800"}>{section?.title}</h4>
                            {section?.description && (
                                <span className={"font-bodyPri font-normal text-base text-text-800"}>
                                    {section?.description}
                                </span>
                            )}
                        </div>
                        <div className={"w-full flex flex-col gap-3"}>
                            {[...section?.quiz_categories]?.sort(shortQuizCategory)?.map((category, index) => {
                                questionNo = category?.type === lmsQuizCategoryEnum.QUESTION.value ? (questionNo + 1) : questionNo
                                return (
                                    <div key={index} className={"w-full p-5 rounded-lg shadow-all bg-white"}>
                                        {category?.type === lmsQuizCategoryEnum.ARTICLE.value && (
                                            <ArticleView article={category?.article?.article} />
                                        )}
                                        {![lmsQuizCategoryEnum.QUESTION.value, lmsQuizCategoryEnum.ARTICLE.value].includes(category?.type) && (
                                            <ResourceView resource={category?.resource?.resource} />
                                        )}
                                        {category?.type === lmsQuizCategoryEnum.QUESTION.value && (
                                            <QuestionView
                                                question={{ ...category?.question?.question, questionNo: questionNo }}
                                                quiz_response={quiz_response}
                                            />
                                        )}
                                    </div>
                                )
                            })}
                        </div>

                        <hr className={"h-0.5 w-full bg-text-300"} />

                        <div className={"w-full flex flex-row items-center justify-between gap-5"}>
                            <div className={"flex items-center justify-center gap-3"}>
                                <button className={cn(
                                    "flex flex-nowrap items-center justify-center gap-2 font-buttons font-medium text-md text-text-700"
                                )}
                                    onClick={onHandleCancel}
                                    disabled={updateLmsQuizResponse?.isLoading || deleteLmsQuizResponse?.isLoading}
                                >
                                    {"Cancel"}
                                </button>
                                <button className={cn(
                                    "flex flex-nowrap items-center justify-center gap-2 font-buttons font-medium text-md text-primary-dark"
                                )}
                                    onClick={onHandleClearResponse}
                                    disabled={updateLmsQuizResponse?.isLoading || deleteLmsQuizResponse?.isLoading}
                                >
                                    {deleteLmsQuizResponse?.isLoading ? "Loading... " : "Clear Response"}
                                    {deleteLmsQuizResponse?.isLoading && <FaSpinner className={"text-xl animate-spin text-primary-dark"} />}
                                </button>
                            </div>
                            <div className={"flex flex-row items-center justify-center gap-3"}>
                                {(index > 0) && (
                                    <button className={"font-buttons font-medium text-md text-text-700"}
                                        onClick={() => setActiveSectionIndex((index - 1))}
                                    >
                                        {"Previous"}
                                    </button>
                                )}
                                {(sections?.length > 0 && index < (sections?.length - 1)) && (
                                    <button className={"px-4 py-1.5 bg-primary-dark hover:opacity-90 rounded font-buttons font-medium text-md text-text-50"}
                                        onClick={() => setActiveSectionIndex((index + 1))}
                                    >
                                        {"Next"}
                                    </button>
                                )}
                                {(index === (sections?.length - 1)) && (
                                    <button className={"flex flex-nowrap gap-2 px-4 py-1.5 bg-primary-dark hover:opacity-90 rounded font-buttons font-medium text-md text-text-50"}
                                        onClick={onHandleSubmit}
                                        disabled={updateLmsQuizResponse?.isLoading || deleteLmsQuizResponse?.isLoading}
                                    >
                                        {(updateLmsQuizResponse?.isLoading) ? "Loading... " : "Submit & Review"}
                                        {(updateLmsQuizResponse?.isLoading) && <FaSpinner className={"text-xl animate-spin text-text-50"} />}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default SectionsView