import React from "react";
import { Outlet } from 'react-router-dom';

//Component imports
import ChatListContainer from "../ChatListContainer/ChatListContainer";

//Context imports
import { useChat } from "../../contexts/chatContext";

//Style imports
import {
  StyledDashboardContainer,
  StyledChatViewContainer,
  StyledDashBoardSelectChat,
} from "./ChatDashboard.styles";

export default function ChatDashboard() {
  const chat = useChat();

  return (
    <StyledDashboardContainer>
      <StyledChatViewContainer>
        {<ChatListContainer />}
        {chat?.selectedChat && <Outlet/>}
        {!chat?.selectedChat && 
          <StyledDashBoardSelectChat>
            <p>No chat Selected! You can select one on the left</p>
          </StyledDashBoardSelectChat>
        }
      </StyledChatViewContainer>
    </StyledDashboardContainer>
  );
}
