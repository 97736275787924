import { addUserEventlyDetailPayload, modifyUserEventlyDetailPayload } from "./evently.const";

export const EVENTLY_INITIAL_STATE = {
    userEventlyList: {
        isLoading: false,
        data: null,
        message: null,
    },
    userEventlyDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addUserEventlyDetail: {
        isLoading: false,
        data: null,
        payload: addUserEventlyDetailPayload,
        message: null
    },
    modifyUserEventlyDetail: {
        isLoading: false,
        data: null,
        payload:modifyUserEventlyDetailPayload,
        message: null
    },
    destroyUserEventlyDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    // update evently events
    modifyEventlyEventsDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    // evently invitation list
    eventlyInvitationList: {
        isLoading: false,
        data: null,
        message: null
    },
    
    // evently invitation detail
    sendEventlyInvitationDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    validateEventlyInvitationDetail: {
        isLoading: false,
        data: null,
        message: null,
    },
    acceptAndDeclineEventlyInvitationDetail: {
        isLoading: false,
        data: null,
        message: null,
    },
    destroyEventlyInvitationDetail: {
        isLoading: false,
        message: null
    },
}