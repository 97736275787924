import { useNavigate } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';

import ButtonLoader from 'components/loader/ButtonLoader';

import { updateCourseDetail } from 'redux/course/course.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { courseType } from 'redux/course/course.const';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { pagesInfo } from 'utils/pagesInfo';

const ButtonContainer = ({ stepCount, setStepCount }) => {
    const { modal } = useAppState((state) => state.local)
    const { tutorCourseDetail, addCourseDetail, courseTagList } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const onHandlePreviousBtn = () => {
        if (addCourseDetail?.isLoading) return;
        setStepCount(stepCount > 1 ? stepCount - 1 : 1)
        const queryStepCount = stepCount > 1 ? stepCount - 1 : 1
        navigate(`${pagesInfo?.CREATE_COURSE?.pagePath}/${tutorCourseDetail?.data?.id}/edit?step=${queryStepCount}`)

        // if (tutorCourseDetail?.data?.step > 1) {
        //     dispatcher(updateCourseDetail(tutorCourseDetail?.data?.id, { step: (tutorCourseDetail?.data?.step - 1) }))
        // } else return;
    }

    const onHandleNextBtn = () => {
        if (addCourseDetail?.isLoading) return;

        if (courseTagList?.courseTagList?.results?.length < 3) {
            alert("Please add at least 3 skills!")
            return;
        }

        if (stepCount === 3 && !tutorCourseDetail?.data?.courseAvailability?.id) {
            alert("Please submit your class schedule!")
            return;
        }

        if (stepCount === 4 && !tutorCourseDetail?.data?.coursePrice) {
            alert("Please submit your class price!")
            return;
        }

        const queryStepCount = stepCount < 5 ? stepCount + 1 : 5
        navigate(`${pagesInfo?.CREATE_COURSE?.pagePath}/${tutorCourseDetail?.data?.id}/edit?step=${queryStepCount}`)

        if (stepCount === 5) {
            dispatcher(setModal({
                ...modal,
                [modalConst.SUBMIT_COURSE_MODAL.stateKey]: true
            }))
            return;
        }

        if (tutorCourseDetail?.data?.step <= stepCount) {
            dispatcher(updateCourseDetail(tutorCourseDetail?.data?.id, { step: tutorCourseDetail?.data?.step + 1 }))
        }

        setStepCount(stepCount < 5 ? stepCount + 1 : 5)
    }

    return (
        <div className={"w-full flex items-center justify-between gap-3"}>
            <div
                className={cn(
                    "px-5 py-1 flex items-center justify-center gap-1 rounded-lg",
                    "font-bodyPri font-normal text-xl",
                    stepCount === 1 && "border border-text-400 text-text-400",
                    stepCount > 1 && "text-primary-dark border border-primary-dark hover:bg-primary-dark hover:text-text-50 cursor-pointer group"
                )}
                title={"Previous"}
                onClick={onHandlePreviousBtn}
            >
                <ArrowBackIos className={"group-hover:text-text-50"} />
                <span className={""}>
                    {"Previous"}
                </span>
            </div>

            {/* course title container */}
            {tutorCourseDetail?.data &&
                <div className={"flex items-center justify-center border-b-2 border-divider-medium"}>
                    <span className={"font-bodyPri font-medium text-text-900 text-md tracking-wide capitalize"}>
                        {`${tutorCourseDetail?.data?.title} | ${courseType[tutorCourseDetail?.data?.type?.toUpperCase()]?.label} Class`}
                    </span>
                </div>
            }

            <div
                className={cn(
                    "px-5 py-1 flex items-center justify-center gap-1 rounded-lg",
                    "font-bodyPri font-normal text-xl",
                    // (stepCount === 5) && "border border-text-400 text-text-400",
                    addCourseDetail?.isLoading && "bg-primary-dark",
                    stepCount <= 5 && "text-primary-dark border border-primary-dark hover:bg-primary-dark hover:text-text-50 cursor-pointer group"
                )}
                title={stepCount < 5 ? "Next" : "Submit"}
                onClick={onHandleNextBtn}
            >
                {addCourseDetail?.isLoading && <ButtonLoader isLoading={addCourseDetail?.isLoading} />}
                {!addCourseDetail?.isLoading &&
                    <div className={"flex items-center justify-start gap-1.5"}>
                        {(!addCourseDetail?.isLoading && stepCount < 5) &&
                            <span className={""}>
                                {"Next"}
                            </span>
                        }
                        {(!addCourseDetail?.isLoading && stepCount === 5) &&
                            <span className={""}>
                                {"Submit"}
                            </span>
                        }
                        <ArrowForwardIos className={"group-hover:text-text-50"} />
                    </div>
                }
            </div>
        </div>
    )
}

export default ButtonContainer