import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/promo/promo.const"

class PromoServices {
    verifyPromo = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.VERIFY_PROMO,
            body
        )
        return response
    }

    redeemPromo = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.REDEEM_PROMO,
            body
        )
        return response
    }

}
export default new PromoServices();