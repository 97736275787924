import { useCallback } from "react";

import ContactNoteItem from "components/modals/crmModals/createCrmContactDetailModal/commonComponents/ContactNoteItem";
import AddItemBtn from "components/modals/crmModals/commonComponents/AddItemBtn";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setAddCrmContactDetailPayload } from "redux/crm/crm.slice";

function AddContactNotes() {
    const { addCrmContactDetail } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()

    const onHandleAddNewNoteDetail = useCallback(() => {
        const newId = (addCrmContactDetail?.payload?.notes?.length > 0)
            ? addCrmContactDetail?.payload?.notes[addCrmContactDetail?.payload?.notes?.length - 1].id + 1
            : 1
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            notes: !!addCrmContactDetail?.payload?.notes?.length
                ? [...addCrmContactDetail?.payload?.notes, { id: newId, title: "", note: "" }]
                : [{ id: newId, title: "", note: "" }]
        }))
    }, [addCrmContactDetail?.payload])

    const onHandleChangeNoteDetail = useCallback((noteId, updatedValue) => {
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            notes: addCrmContactDetail?.payload?.notes?.map((noteItem) => (
                (noteItem?.id === noteId) ? updatedValue : noteItem
            ))
        }))
    }, [addCrmContactDetail?.payload])

    const onHandleDeleteNoteDetail = useCallback((noteId) => {
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            notes: addCrmContactDetail?.payload?.notes?.filter((noteItem) => noteItem?.id !== noteId)
        }))
    }, [addCrmContactDetail?.payload])


    return (
        <div className="w-full flex flex-col gap-8 items-center mt-5">
            {!!addCrmContactDetail?.payload?.notes?.length &&
                addCrmContactDetail?.payload?.notes.map((noteItem, index) => (
                    <ContactNoteItem
                        key={index}
                        index={index}
                        isShowCancelBtn={true}
                        noteItem={noteItem}
                        onHandleChangeNoteDetail={onHandleChangeNoteDetail}
                        onHandleDeleteNoteDetail={onHandleDeleteNoteDetail}
                    />
                ))}
            <AddItemBtn
                btnTitle={"Add Note"}
                onHandleAddBtn={onHandleAddNewNoteDetail}
            />
        </div>
    );
}

export default AddContactNotes;