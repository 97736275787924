import format from 'string-format';

import { baseApiInstance, baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/payment/payment.const";


class PaymentService {

    addMakePayment = async ({ body }) => {
        const response = await baseApiInstance().post(
            url.MAKE_PAYMENT,
            body
        )

        return response
    }

    // User Customer Portal Session Service
    createUserCustomerPortalSession = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_USER_CUSTOMER_PORTAL_SESSION),
            body
        )

        return response
    }

    // User Customer Session Service
    createUserCustomerSession = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_USER_CUSTOMER_SESSION),
            body
        )

        return response
    }

    // Product Prices Service
    getProductsPrices = async ({ query }) => {
        const response = await baseApiInstance().get(
            format(url.GET_PRODUCTS_PRICES),
            { params: query }
        )

        return response
    }
    getProductPrices = async ({ params, query }) => {
        const response = await baseApiInstance().get(
            format(url.GET_PRODUCT_PRICES, params),
            { params: query }
        )

        return response
    }

    // User Payment Method Service
    getUserPaymentMethodList = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_PAYMENT_METHOD_LIST, params)
        )

        return response
    }
    attachUserPaymentMethodDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.ATTACH_USER_PAYMENT_METHOD_DETAIL, params),
            body
        )

        return response
    }
    detachUserPaymentMethodDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.DETACH_USER_PAYMENT_METHOD_DETAIL, params),
            body
        )

        return response
    }

    createUserCheckoutSession = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_USER_CHECKOUT_SESSION),
            body
        )

        return response
    }

    // Payment Intent Service
    createPaymentIntentDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_PAYMENT_INTENT_DETAIL),
            body
        )

        return response
    }
    getPaymentIntentDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_PAYMENT_INTENT_DETAIL, params)
        )

        return response
    }

    // Add token through wallet
    createTokenThroughWalletDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.ADD_TOKENS_THROUGH_WALLET),
            body
        )

        return response
    }
}

export default new PaymentService()