import { useEffect, useState } from "react";

import ComponentLoader from "components/loader/ComponentLoader";
import MuxVideoPlayer from "components/muxVideoPlayer/MuxVideoPlayer";

import { ArrowToggleIcon, CollapsableContainer } from 'pages/auth/createCourse/createCourse.style';
import CreateCourseTooltip from "pages/auth/createCourse/CreateCourseTooltip";
import { inputSections, videoTooltip } from "pages/auth/createCourse/data";

import { getTutorCourseDetail, updateCourseDetail } from "redux/course/course.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
// import { setUploadDocumentPayload, setClearUploadDocument } from "redux/document/document.slice";
import { resetUploadFile, setUploadFilePayload } from "redux/storage/storage.slice";
import { modalConst } from "redux/local/local.const";
import { fileCategories } from "redux/document/document.const";

import { MUX_DEMO_PLAYBACK_ID } from 'const/default.const';

const CourseVideoSection = ({ clickedItem, setClickedItem }) => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    // const { uploadDocument } = useAppState((state) => state.document)
    const { uploadFile } = useAppState((state) => state.storage)
    const { tutorCourseDetail, addCourseDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const [videoPlayBackId, setVideoPlayBackId] = useState(tutorCourseDetail?.data?.introVideoUrl ? tutorCourseDetail?.data?.introVideoUrl : MUX_DEMO_PLAYBACK_ID)

    const isOpen = inputSections?.COURSE_VIDEO?.value === clickedItem;

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const answerVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    }

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }


    useEffect(() => {
        if (uploadFile?.data && uploadFile?.data?.length > 0 && (uploadFile?.payload?.fileCategory === fileCategories.INTRO_VIDEO.value)) {
            dispatcher(updateCourseDetail(
                tutorCourseDetail?.data?.id,
                {
                    introVideoUrl: uploadFile?.data[0]?.fileUrl
                }
            ))
            dispatcher(resetUploadFile())
        }
    }, [uploadFile?.data])

    useEffect(() => {
        if (clickedItem === inputSections?.COURSE_VIDEO?.value && addCourseDetail?.addCourseDetail) {
            setVideoPlayBackId(addCourseDetail?.addCourseDetail?.introVideoUrl)
            dispatcher(setModal({
                ...modal,
                [modalConst.UPLOAD_VIDEO_MODAL.stateKey]: false,
                [modalConst.RECORD_VIDEO_MODAL.stateKey]: false
            }))
        }
    }, [addCourseDetail?.addCourseDetail])

    const onHandleToggle = () => {
        if (!clickedItem || (clickedItem !== inputSections?.COURSE_VIDEO?.value)) {
            setClickedItem(inputSections?.COURSE_VIDEO?.value)
        } else {
            setClickedItem(null)
        }
    }

    const onHandleRefresh = () => {
        dispatcher(getTutorCourseDetail(tutorCourseDetail?.data?.id, user?.user?.tutor?.tutorId))
    }

    const onHandleUploadVideo = () => {
        dispatcher(resetUploadFile())
        dispatcher(setUploadFilePayload({
            ...uploadFile?.payload,
            fileCategory: fileCategories.INTRO_VIDEO.value
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.UPLOAD_VIDEO_MODAL.stateKey]: true
        }))
    }

    const onHandleRecordVideo = () => {
        dispatcher(resetUploadFile())
        dispatcher(setUploadFilePayload({
            ...uploadFile?.payload,
            fileCategory: fileCategories.INTRO_VIDEO.value
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.RECORD_VIDEO_MODAL.stateKey]: true
        }))
    }

    return (
        <div className={"w-full h-full bg-white rounded-lg select-none"}>
            <div
                className={"flex flex-row items-center justify-between px-5 py-3 bg-background-darkLight rounded-lg cursor-pointer hover:shadow-all-md"}
                onClick={onHandleToggle}
            >

                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Video"}
                </div>
                <ArrowToggleIcon
                    variants={arrowToggleVariants}
                    initial={'collapsed'}
                    animate={isOpen ? 'open' : 'collapsed'}
                    transition={transition}
                />
            </div>

            <CollapsableContainer
                isOpen={isOpen}
                variants={answerVariants}
                initial={'collapsed'}
                animate={isOpen ? 'open' : 'collapsed'}
                transition={transition}
            >
                {(tutorCourseDetail?.isLoading || addCourseDetail?.isLoading) &&
                    <ComponentLoader isLoading={(tutorCourseDetail?.isLoading || addCourseDetail?.isLoading)} />
                }
                {(!tutorCourseDetail?.isLoading && !addCourseDetail?.isLoading) &&
                    <div className={"w-full flex items-center justify-center"}>
                        <div className={"flex flex-col items-center justify-center space-y-3"}>
                            <div className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                {"Mute device while recording to avoid echo. Playback for quality check, submit for processing. Wait a few seconds, then refresh."}
                            </div>
                            <div className={"flex items-start justify-start gap-1"}>
                                <div className={"w-full md:w-[25rem]"}>
                                    <MuxVideoPlayer
                                        playbackId={videoPlayBackId}
                                        isShowRefreshButton={true}
                                        onHandleRefresh={onHandleRefresh}
                                        handleUploadVideo={onHandleUploadVideo}
                                        handleRecordVideo={onHandleRecordVideo}
                                    />
                                </div>
                                <CreateCourseTooltip tooltip={videoTooltip} translateX={0} translateY={0} />
                            </div>
                        </div>
                    </div>
                }
            </CollapsableContainer>
        </div>
    )
}

export default CourseVideoSection;