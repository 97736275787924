import * as React from 'react';
import { cn } from "utils/cn.utils";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { BsCheckLg } from 'react-icons/bs';

import { useAppState } from 'hooks/useStore';
import { currencyRateConversion } from 'utils/generators.utils';
import { coursePriceTypeEnum } from 'redux/course/course.const';

const minDistance = 0.1;

export default function PriceRangeSlider({
    options,
    minValue,
    maxValue,
    selectedOption,
    isShowList,
    setIsShowList,
    onApplyFilter,
    onClearFilter,
    className
}) {
    const { locals } = useAppState((s) => s.local)
    const { currencyRateList } = useAppState((state) => state.master)
    const targetCurrencyRate = currencyRateList?.currencyRateList[(locals?.currencyCode || "USD")]

    const [value, setValue] = React.useState([0, 100]);
    const [localCurrency, setLocalCurrency] = React.useState({ minLocal: minValue, maxLocal: maxValue })
    const [selectedPriceTypeOption, setSelectedPriceTypeOption] = React.useState(selectedOption)
    const [isPriceRangeEnabled, setIsPriceRangeEnabled] = React.useState(false)
    const [isPriceRangeSelected, setIsPriceRangeSelected] = React.useState(false)

    React.useEffect(() => {
        let minVal = (minValue * 100) / (targetCurrencyRate * 500)
        let maxVal = (maxValue * 100) / (targetCurrencyRate * 500)
        setValue([minVal, maxVal])

        if (selectedOption) {
            setSelectedPriceTypeOption(selectedOption)
        }
    }, [minValue, maxValue, selectedOption])

    const handleChange = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setValue([parseFloat(Math.min(newValue[0], value[1] - minDistance)?.toFixed(2)), value[1]]);
            setLocalCurrency({ ...localCurrency, minLocal: ((parseFloat(newValue[0] * (targetCurrencyRate * 500))) / 100)?.toFixed(2) })
        } else {
            setValue([value[0], parseFloat(Math.max(newValue[1], value[0] + minDistance)?.toFixed(2))]);
            setLocalCurrency({ ...localCurrency, maxLocal: ((parseFloat(newValue[1] * (targetCurrencyRate * 500))) / 100)?.toFixed(2) })
        }

        setIsPriceRangeSelected(true)
    };

    const onHandleSelectPriceType = (priceType) => {
        if (priceType === coursePriceTypeEnum?.PLAN?.value) {
            setIsPriceRangeEnabled(false)
        }
        setSelectedPriceTypeOption(priceType)
    }

    const onHandleSelectPriceRange = () => {
        if (isPriceRangeEnabled) {
            setIsPriceRangeEnabled(false)
            return
        }
        if (selectedPriceTypeOption === coursePriceTypeEnum?.REGULAR?.value) {
            setIsPriceRangeEnabled(true)
        } else {
            setIsPriceRangeEnabled(false)
        }
    }

    const onHandleClear = () => {
        setSelectedPriceTypeOption(null)
        setIsPriceRangeEnabled(false)
        setIsPriceRangeSelected(false)
        onClearFilter()
    }

    const onHandleApply = () => {
        if (!selectedPriceTypeOption) return;

        if (selectedPriceTypeOption) {
            if (isPriceRangeSelected) {
                onApplyFilter({ priceType: selectedPriceTypeOption, minValue: localCurrency?.minLocal, maxValue: localCurrency?.maxLocal })
            } else {
                onApplyFilter({ priceType: selectedPriceTypeOption, minValue: 0, maxValue: 0 })
            }
        }
        setIsShowList(false)
    }

    return (
        <Transition appear show={isShowList} as={Fragment}>
            <Dialog
                as="div"
                className={cn("absolute z-30 overflow-y-auto", className)}
                open={isShowList}
                onClose={setIsShowList}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className={"bg-white w-full min-w-[24rem] max-h-96 overflow-y-auto rounded-lg px-3 py-1.5"}>
                        <div className={"w-full space-y-5 px-3 py-1.5"}>
                            <span className={"font-bodyPri font-medium text-text-900 text-base"}>
                                {"Price Types"}
                            </span>
                            <div className={"w-full flex flex-col items-start justify-start gap-3"}>
                                {options?.map((priceType) => (
                                    <div className={"w-full flex items-start justify-start gap-3 cursor-pointer group"} onClick={() => onHandleSelectPriceType(priceType?.value)}>
                                        <div className={"relative w-5 h-5 border border-text-300 rounded"}>
                                            {(selectedPriceTypeOption === priceType?.value) &&
                                                <span className={"absolute top-0.5 -right-0"}>
                                                    <BsCheckLg className={"text-base text-primary-dark"} />
                                                </span>
                                            }
                                        </div>
                                        <div className={"w-11/12 flex flex-col items-start justify-start gap-1"}>
                                            <div className={"font-bodyPri font-semibold text-text-900 text-base"}>
                                                {priceType?.label}
                                            </div>
                                            {/* <div className={"font-bodyPri font-normal text-text-700 text-sm"}>
                                                {priceType?.description}
                                            </div> */}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* {currencyRateConversion(locals?.currencyCode, localCurrency?.minLocal) + " - " + currencyRateConversion(locals?.currencyCode, localCurrency?.maxLocal)} */}
                            {isPriceRangeEnabled &&
                                <Box className={"px-3 py-2 bg-white rounded-xl space-y-2"}>
                                    <div className={"font-bodyPri font-normal text-text-900 text-lg"}>
                                        {locals?.currencyCode + " " + parseFloat(localCurrency?.minLocal)?.toFixed(2) + " - " + locals?.currencyCode + " " + parseFloat(localCurrency?.maxLocal)?.toFixed(2)}
                                    </div>
                                    <div className={"px-3"}>
                                        <Slider
                                            getAriaLabel={() => 'Minimum distance'}
                                            value={value}
                                            onChange={handleChange}
                                            disableSwap
                                        />
                                    </div>
                                </Box>
                            }

                            <div className={"w-full flex items-center justify-between gap-3 py-2"}>
                                <span
                                    className={"font-bodyPri font-medium text-primary-dark text-sm underline cursor-pointer"}
                                    onClick={onHandleClear}
                                >
                                    {"Clear"}
                                </span>
                                <span
                                    className={cn(
                                        "font-bodyPri font-medium text-sm underline text-text-400 cursor-not-allowed",
                                        (selectedPriceTypeOption === coursePriceTypeEnum?.REGULAR?.value) && "!text-primary-dark !cursor-pointer"
                                    )}
                                    onClick={onHandleSelectPriceRange}>
                                    {isPriceRangeEnabled ? "Remove Price Range" : "Select Price Range"}
                                </span>
                                <div className={cn(
                                    "px-4 py-1 rounded-full border border-primary-dark cursor-pointer",
                                    "font-bodyPri font-medium text-primary-dark text-sm tracking-wide hover:bg-primary-dark hover:text-text-50"
                                )}
                                    onClick={onHandleApply}
                                >
                                    {"Apply Filters"}
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition>
    );
}