import { memo, useState, useRef, useEffect } from 'react';
import { cn } from 'utils/cn.utils';

export const InputSwitch = memo(({
    containerClassName = '',
    inputClassName = '',
    onChange,
    value,
    placeholder = 'Enter title here ...',
    textClassName,
    text,
    autoFocus = false,
    ...props
}) => {
    const [isShow, setIsShow] = useState(autoFocus);
    const inputRef = useRef();

    useEffect(() => {
        if (isShow) {
            inputRef?.current?.focus();
        }
    }, [isShow]);

    return (
        <div className={cn('w-full transition-transform ease-in-out duration-300', containerClassName)}>
            {isShow ? (
                <input
                    ref={inputRef}
                    className={cn('w-full focus:outline-none', inputClassName)}
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={() => setIsShow(false)}
                    autoFocus={isShow}
                    {...props}
                />
            ) : (
                <div onClick={() => setIsShow(true)} className={cn('w-full h-full cursor-text', textClassName)}>{text || placeholder}</div>
            )}
        </div>
    );
});


export const TextArea = memo(({
    containerClassName = '',
    inputClassName = '',
    onChange,
    value,
    placeholder = 'Enter text here ...',
    textClassName,
    text,
    autoFocus = false,
    ...props
}) => {
    const [isShow, setIsShow] = useState(autoFocus);

    const inputRef = useRef()

    useEffect(() => {
        if (isShow) {
            inputRef?.current?.focus()
        }
    }, [isShow])


    return (
        <div className={cn('w-full transition-transform ease-in-out duration-300', containerClassName)}>
            {isShow ? (
                <textarea
                    ref={inputRef}
                    className={cn('w-full h-full resize-none focus:outline-none', inputClassName)}
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={() => setIsShow(false)}
                    autoFocus={isShow}
                    {...props}
                />
            ) : (
                <div onClick={() => setIsShow(true)} className={cn('w-full h-full cursor-text', textClassName)}>{text || placeholder}</div>
            )}
        </div>
    );
});

