import { createSlice } from "@reduxjs/toolkit";

import { EVENTLY_INITIAL_STATE } from "./evently.initialState";

const evently = createSlice({
    name: "evently",
    initialState: EVENTLY_INITIAL_STATE,
    reducers: {
        // user evently list
        setUserEventlyListLoading: (state, { payload }) => {
            state.userEventlyList.isLoading = payload
        },
        setUserEventlyListData: (state, { payload }) => {
            state.userEventlyList.data = payload
            state.userEventlyList.message = EVENTLY_INITIAL_STATE.userEventlyList.message
        },
        setUserEventlyListMessage: (state, { payload }) => {
            state.userEventlyList.message = payload
            state.userEventlyList.data = EVENTLY_INITIAL_STATE.userEventlyList.data
        },
        resetUserEventlyList: (state) => {
            state.userEventlyList = EVENTLY_INITIAL_STATE.userEventlyList
        },

        // user evently detail
        setUserEventlyDetailLoading: (state, { payload }) => {
            state.userEventlyDetail.isLoading = payload
        },
        setUserEventlyDetailData: (state, { payload }) => {
            state.userEventlyDetail.data = payload
            state.userEventlyDetail.message = EVENTLY_INITIAL_STATE.userEventlyDetail.message
        },
        setUserEventlyDetailMessage: (state, { payload }) => {
            state.userEventlyDetail.message = payload
            state.userEventlyDetail.data = EVENTLY_INITIAL_STATE.userEventlyDetail.data
        },
        resetUserEventlyDetail: (state) => {
            state.userEventlyDetail = EVENTLY_INITIAL_STATE.userEventlyDetail
        },

        // add user evently detail
        setAddUserEventlyDetailLoading: (state, { payload }) => {
            state.addUserEventlyDetail.isLoading = payload
        },
        setAddUserEventlyDetailData: (state, { payload }) => {
            state.addUserEventlyDetail.data = payload
            state.addUserEventlyDetail.message = EVENTLY_INITIAL_STATE.addUserEventlyDetail.message
        },
        setAddUserEventlyDetailMessage: (state, { payload }) => {
            state.addUserEventlyDetail.message = payload
            state.addUserEventlyDetail.data = EVENTLY_INITIAL_STATE.addUserEventlyDetail.data
        },
        resetAddUserEventlyDetail: (state) => {
            state.addUserEventlyDetail = EVENTLY_INITIAL_STATE.addUserEventlyDetail
        },

        // add user evently detail payload
        setAddUserEventlyDetailPayload: (state, { payload }) => {
            state.addUserEventlyDetail.payload = payload
        },
        resetAddUserEventlyDetailPayload: (state) => {
            state.addUserEventlyDetail.payload = EVENTLY_INITIAL_STATE.addUserEventlyDetail.payload
        },

        // modify user evently detail
        setModifyUserEventlyDetailLoading: (state, { payload }) => {
            state.modifyUserEventlyDetail.isLoading = payload
        },
        setModifyUserEventlyDetailData: (state, { payload }) => {
            state.modifyUserEventlyDetail.data = payload
            state.modifyUserEventlyDetail.message = EVENTLY_INITIAL_STATE.modifyEventlyDetail.message
        },
        setModifyUserEventlyDetailMessage: (state, { payload }) => {
            state.modifyUserEventlyDetail.message = payload
            state.modifyUserEventlyDetail.data = EVENTLY_INITIAL_STATE.modifyUserEventlyDetail.data
        },
        resetModifyUserEventlyDetail: (state) => {
            state.modifyUserEventlyDetail = EVENTLY_INITIAL_STATE.modifyUserEventlyDetail
        },

        // modify user evently detail payload
        setModifyUserEventlyDetailPayload: (state, { payload }) => {
            state.modifyUserEventlyDetail.payload = payload
        },
        resetModifyUserEventlyDetailPayload: (state) => {
            state.modifyUserEventlyDetail.payload = EVENTLY_INITIAL_STATE.modifyUserEventlyDetail.payload
        },

        // destroy user evently detail
        setDestroyUserEventlyDetailLoading: (state, { payload }) => {
            state.destroyUserEventlyDetail.isLoading = payload
        },
        setDestroyUserEventlyDetailData: (state, { payload }) => {
            state.destroyUserEventlyDetail.data = payload
            state.destroyUserEventlyDetail.message = EVENTLY_INITIAL_STATE.destroyUserEventlyDetail.message
        },
        setDestroyUserEventlyDetailMessage: (state, { payload }) => {
            state.destroyUserEventlyDetail.message = payload
            state.destroyUserEventlyDetail.data = EVENTLY_INITIAL_STATE.destroyUserEventlyDetail.data
        },
        resetDestroyUserEventlyDetail: (state) => {
            state.destroyUserEventlyDetail = EVENTLY_INITIAL_STATE.destroyUserEventlyDetail
        },

        // evently events detail
        setModifyEventlyEventsDetailLoading: (state, { payload }) => {
            state.modifyEventlyEventsDetail.isLoading = payload
        },
        setModifyEventlyEventsDetailData: (state, { payload }) => {
            state.modifyEventlyEventsDetail.data = payload
            state.modifyEventlyEventsDetail.payload = EVENTLY_INITIAL_STATE.modifyEventlyEventsDetail.payload
        },
        setModifyEventlyEventsDetailMessage: (state, { payload }) => {
            state.modifyEventlyEventsDetail.message = payload
            state.modifyEventlyEventsDetail.data = EVENTLY_INITIAL_STATE.modifyEventlyEventsDetail.data
        },
        resetModifyEventlyEventsDetail: (state) => {
            state.modifyEventlyEventsDetail = EVENTLY_INITIAL_STATE.modifyEventlyEventsDetail
        },

        // user evently invitation list
        setEventlyInvitationListLoading: (state, { payload }) => {
            state.eventlyInvitationList.isLoading = payload
        },
        setEventlyInvitationListData: (state, { payload }) => {
            state.eventlyInvitationList.data = payload
            state.eventlyInvitationList.message = EVENTLY_INITIAL_STATE.eventlyInvitationList.message
        },
        setEventlyInvitationListMessage: (state, { payload }) => {
            state.eventlyInvitationList.message = payload
            state.eventlyInvitationList.data = EVENTLY_INITIAL_STATE.eventlyInvitationList.data
        },
        resetEventlyInvitationList: (state) => {
            state.eventlyInvitationList = EVENTLY_INITIAL_STATE.eventlyInvitationList
        },

        // send user evently invitation detail
        setSendEventlyInvitationDetailLoading: (state, { payload }) => {
            state.sendEventlyInvitationDetail.isLoading = payload
        },
        setSendEventlyInvitationDetailData: (state, { payload }) => {
            state.sendEventlyInvitationDetail.data = payload
            state.sendEventlyInvitationDetail.message = EVENTLY_INITIAL_STATE.sendEventlyInvitationDetail.message
        },
        setSendEventlyInvitationDetailMessage: (state, { payload }) => {
            state.sendEventlyInvitationDetail.message = payload
            state.sendEventlyInvitationDetail.data = EVENTLY_INITIAL_STATE.sendEventlyInvitationDetail.data
        },
        resetSendEventlyInvitationDetail: (state) => {
            state.sendEventlyInvitationDetail = EVENTLY_INITIAL_STATE.sendEventlyInvitationDetail
        },

        // Validate Evently Invitation Detail
        setValidateEventlyInvitationDetailLoading: (state, { payload }) => {
            state.validateEventlyInvitationDetail.isLoading = payload
        },
        setValidateEventlyInvitationDetailData: (state, { payload }) => {
            state.validateEventlyInvitationDetail.data = payload
        },

        setValidateEventlyInvitationDetailMessage: (state, { payload }) => {
            state.validateEventlyInvitationDetail.message = payload
        },

        resetValidateEventlyInvitationDetail: (state) => {
            state.validateEventlyInvitationDetail = EVENTLY_INITIAL_STATE.validateEventlyInvitationDetail
        },

        // Accept or Decline Evently Invitation Detail
        setAcceptAndDeclineEventlyInvitationDetailLoading: (state, { payload }) => {
            state.acceptAndDeclineEventlyInvitationDetail.isLoading = payload
        },
        setAcceptAndDeclineEventlyInvitationDetailData: (state, { payload }) => {
            state.acceptAndDeclineEventlyInvitationDetail.data = payload
        },

        setAcceptAndDeclineEventlyInvitationDetailMessage: (state, { payload }) => {
            state.acceptAndDeclineEventlyInvitationDetail.message = payload
        },

        resetAcceptAndDeclineEventlyInvitationDetail: (state, { payload }) => {
            state.acceptAndDeclineEventlyInvitationDetail = EVENTLY_INITIAL_STATE.acceptAndDeclineEventlyInvitationDetail
        },

        // destroy evently invitation detail
        setDestroyEventlyInvitationDetailLoading: (state, {payload}) => {
            state.destroyEventlyInvitationDetail.isLoading = payload
        },
        setDestroyEventlyInvitationDetailMessage: (state, {payload}) => {
            state.destroyEventlyInvitationDetail.message = payload
            state.destroyEventlyInvitationDetail.data = EVENTLY_INITIAL_STATE.destroyEventlyInvitationDetail.data
        },
        resetDestroyEventlyInvitationDetail: (state) => {
            state.destroyEventlyInvitationDetail = EVENTLY_INITIAL_STATE.destroyEventlyInvitationDetail
        },
    }
});

export const {
    setUserEventlyListLoading,
    setUserEventlyListData,
    setUserEventlyListMessage,
    resetUserEventlyList,

    setUserEventlyDetailLoading,
    setUserEventlyDetailData,
    setUserEventlyDetailMessage,
    resetUserEventlyDetail,

    setAddUserEventlyDetailLoading,
    setAddUserEventlyDetailData,
    setAddUserEventlyDetailMessage,
    resetAddUserEventlyDetail,

    setAddUserEventlyDetailPayload,
    resetAddUserEventlyDetailPayload,

    setModifyUserEventlyDetailLoading,
    setModifyUserEventlyDetailData,
    setModifyUserEventlyDetailMessage,
    resetModifyUserEventlyDetail,

    setModifyUserEventlyDetailPayload,
    resetModifyUserEventlyDetailPayload,

    setDestroyUserEventlyDetailLoading,
    setDestroyUserEventlyDetailData,
    setDestroyUserEventlyDetailMessage,
    resetDestroyUserEventlyDetail,

    setModifyEventlyEventsDetailLoading,
    setModifyEventlyEventsDetailData,
    setModifyEventlyEventsDetailMessage,
    resetModifyEventlyEventsDetail,

    setEventlyInvitationListLoading,
    setEventlyInvitationListData,
    setEventlyInvitationListMessage,
    resetEventlyInvitationList,

    setSendEventlyInvitationDetailLoading,
    setSendEventlyInvitationDetailData,
    setSendEventlyInvitationDetailMessage,
    resetSendEventlyInvitationDetail,

    setValidateEventlyInvitationDetailLoading,
    setValidateEventlyInvitationDetailData,
    setValidateEventlyInvitationDetailMessage,
    resetValidateEventlyInvitationDetail,

    setAcceptAndDeclineEventlyInvitationDetailData,
    setAcceptAndDeclineEventlyInvitationDetailLoading,
    setAcceptAndDeclineEventlyInvitationDetailMessage,
    resetAcceptAndDeclineEventlyInvitationDetail,

    setDestroyEventlyInvitationDetailLoading,
    setDestroyEventlyInvitationDetailMessage,
    resetDestroyEventlyInvitationDetail
} = evently.actions

export default evently.reducer