import { useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { FaInfoCircle } from 'react-icons/fa';

import ComponentLoader from 'components/loader/ComponentLoader';
import MuxVideoPlayer from 'components/muxVideoPlayer/MuxVideoPlayer';

import { videoCategory, } from 'pages/auth/tutorOnboard/TutorOnboardData';

import {
    getOnboardingDocListByOnboardingId,
    createOnboardingDocDetailByOnboardingId,
    updateOnboardingDocDetailByOnboardingDocId
} from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetUploadFile } from 'redux/storage/storage.slice';
// import { setClearUploadDocument } from 'redux/document/document.slice';
import { setAddOnboardingDocDetailPayload, setClearAddOnboardingDocDetailPayload } from 'redux/onboarding/onboarding.slice';
import {
    setDocumentCategoryList,
    setClearDocumentCategoryList,
} from "redux/onboarding/onboarding.slice";
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { introVideoStatus, onBoardingStatus } from "redux/onboarding/onboarding.const";

import { pagesInfo } from 'utils/pagesInfo';

import { MUX_DEMO_PLAYBACK_ID } from 'const/default.const';

const VideoDetail = () => {
    const { modal } = useAppState((state) => state.local)
    // const { uploadDocument } = useAppState((state) => state.document)
    const { uploadFile } = useAppState((state) => state.storage)
    const { categoryList, onboardingDocList, addOnboardingDocDetail, userOnboardingDetail } = useAppState((s) => s.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()
    const navigate = useNavigate()

    const filteredDocumentList = onboardingDocList?.onboardingDocList?.filter((documents) => documents?.docType === videoCategory?.INTRO_VIDEO?.value)

    const disableButtons = [introVideoStatus?.APPROVED?.value, introVideoStatus?.REJECTED?.value]?.includes(userOnboardingDetail?.userOnboardingDetail?.introVideo?.status) ||
        [onBoardingStatus?.APPROVED?.value, onBoardingStatus?.REJECTED?.value, onBoardingStatus?.BLOCKED?.value]?.includes(userOnboardingDetail?.userOnboardingDetail?.status)

    useEffect(() => {
        if (onboardingId) {
            dispatcher(getOnboardingDocListByOnboardingId(Number(onboardingId)))
        }
    }, [onboardingId])

    useEffect(() => {
        if ((uploadFile?.data && uploadFile?.data?.length > 0) && !addOnboardingDocDetail?.addOnboardingDocDetailPayload?.documentId && categoryList?.categoryList?.isVideoUploadEnabled) {
            dispatcher(createOnboardingDocDetailByOnboardingId(
                Number(onboardingId),
                {
                    docType: videoCategory?.INTRO_VIDEO?.value,
                    docName: "intro video",
                    docUrl: uploadFile?.data[0]?.fileUrl
                }
            ))
            dispatcher(setClearDocumentCategoryList())
            dispatcher(resetUploadFile())
        }
        if ((uploadFile?.data && uploadFile?.data?.length > 0) && addOnboardingDocDetail?.addOnboardingDocDetailPayload?.documentId && categoryList?.categoryList?.isVideoUploadEnabled) {
            dispatcher(updateOnboardingDocDetailByOnboardingDocId(
                addOnboardingDocDetail?.addOnboardingDocDetailPayload?.documentId,
                {
                    docType: videoCategory?.INTRO_VIDEO?.value,
                    docName: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileName,
                    docUrl: uploadFile?.data[0]?.fileUrl
                }
            ))
            dispatcher(setClearDocumentCategoryList())
            dispatcher(resetUploadFile())
        }
    }, [uploadFile?.data])

    const handleUploadVideo = () => {
        if (disableButtons) {
            navigate(`${pagesInfo?.SETTINGS?.pagePath}/profile`)
            return;
        }
        dispatcher(resetUploadFile())
        dispatcher(setClearDocumentCategoryList())
        dispatcher(setDocumentCategoryList({
            categoryListItems: videoCategory,
            isVideoUploadEnabled: true
        }))
        if (filteredDocumentList && filteredDocumentList?.length > 0) {
            dispatcher(setClearAddOnboardingDocDetailPayload())
            dispatcher(setAddOnboardingDocDetailPayload({
                documentId: filteredDocumentList[0]?.onboardingDocId,
                fileCategory: filteredDocumentList[0]?.docType,
                fileName: filteredDocumentList[0]?.docName,
                fileUrl: filteredDocumentList[0]?.docUrl
            }))
        }
        dispatcher(setModal({
            ...modal,
            [modalConst.UPLOAD_VIDEO_MODAL.stateKey]: true
        }))
    }

    const handleRecordVideo = () => {
        if (disableButtons) {
            navigate(`${pagesInfo?.SETTINGS?.pagePath}/profile`)
            return;
        }
        dispatcher(resetUploadFile())
        dispatcher(setClearDocumentCategoryList())
        dispatcher(setDocumentCategoryList({
            categoryListItems: videoCategory,
            isVideoUploadEnabled: true
        }))
        if (filteredDocumentList && filteredDocumentList?.length > 0) {
            dispatcher(setClearAddOnboardingDocDetailPayload())
            dispatcher(setAddOnboardingDocDetailPayload({
                documentId: filteredDocumentList[0]?.onboardingDocId,
                fileCategory: filteredDocumentList[0]?.docType,
                fileName: filteredDocumentList[0]?.docName,
                fileUrl: filteredDocumentList[0]?.docUrl
            }))
        }
        dispatcher(setModal({
            ...modal,
            [modalConst.RECORD_VIDEO_MODAL.stateKey]: true
        }))
    }

    const handleRefreshButton = () => {
        dispatcher(getOnboardingDocListByOnboardingId(Number(onboardingId)))
    }

    if (onboardingDocList?.isLoading || addOnboardingDocDetail?.isLoading) {
        return (
            <ComponentLoader isLoading={onboardingDocList?.isLoading || addOnboardingDocDetail?.isLoading} />
        )
    }

    return (
        <div className={"flex items-center justify-center"}>
            <div className={"w-full flex flex-col items-center justify-center space-y-3"}>
                <div className={"w-full font-bodyPri font-normal text-text-800 text-base tracking-wide text-center"}>
                    {"Mute device while recording to avoid echo. Playback for quality check, submit for processing. Wait a few seconds, then refresh."}
                </div>
                <div className={"flex items-start justify-start gap-1.5"}>
                    <div className={"w-full sm:w-[28rem] md:w-[30rem] h-full overflow-hidden rounded-lg"}>
                        <MuxVideoPlayer
                            playbackId={filteredDocumentList ? filteredDocumentList[0]?.docUrl : MUX_DEMO_PLAYBACK_ID}
                            handleRecordVideo={handleRecordVideo}
                            handleUploadVideo={handleUploadVideo}
                            onHandleRefresh={handleRefreshButton}
                            isShowRefreshButton={true}
                            autoPlay={false}
                            muted={false}
                            isButtonDisabled={disableButtons}
                        />
                    </div>
                    <div className={"relative has-tooltip cursor-pointer flex items-start justify-start"}>
                        <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                        <span
                            className={cn(
                                `w-fit md:w-60 !z-[500] px-3 py-0.5 md:py-1 font-bodyPri font-normal text-sm md:text-md rounded shadow-lg bg-text-900 text-text-50 tooltip -translate-x-[50%] -translate-y-[100%]`,
                                "flex flex-col items-start justify-start overflow-hidden"
                            )}
                        >
                            {"You have the option to upload a new, short video specifically for this class, or you can choose to keep your default introductory video."}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoDetail