import { useNavigate } from 'react-router-dom';

import { BsFillCheckCircleFill } from 'react-icons/bs';

import { Container } from "pages/auth/paymentConfirmation/PaymentConfirmationPageStyle";

import { useAppState } from 'hooks/useStore';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';

import { pagesInfo } from 'utils/pagesInfo';
import { currencyRateConversion } from 'utils/generators.utils';

const PaymentProcessing = () => {
    const { locals } = useAppState(s => s.local)
    const { paymentIntentDetail } = useAppState((state) => state.payment)

    const navigate = useNavigate()

    const pageRedirect = () => {
        navigate(`${pagesInfo.USER_FINANCE.pagePath}${pagesInfo.WALLET.pagePath}?view=token_transactions`, { replace: true })
    }

    return (
        <Container className={"!shadow-all-md"}>
            <BsFillCheckCircleFill className={"text-8xl text-yellow-500"} />
            <div className={"flex items-center justify-center gap-2 font-bodyPri font-semibold text-2xl text-primary-dark"}>
                <span>{paymentIntentDetail?.data?.currency?.toUpperCase()}</span>
                <span>{parseFloat(paymentIntentDetail?.data?.amount / 100).toFixed(2)}</span>
            </div>
            {([masterCurrencyCodeEnum.USD.value]?.includes(paymentIntentDetail?.data?.currency?.toUpperCase()) && (locals.currencyCode !== masterCurrencyCodeEnum.USD.value)) &&
                <div className={"flex items-center justify-center gap-2 font-bodyPri font-medium text-lg text-text-800"}>
                    <span>{currencyRateConversion(locals?.currencyCode, paymentIntentDetail?.data?.amount)}</span>
                </div>
            }
            <div className={"flex flex-col items-center gap-2"}>
                <span className={"font-bodyPri font-medium text-md text-text-800"}>
                    {"Hold tight your order is being processed. We will email you when order is succeed."}
                </span>
                <span className={"font-bodyPri font-semibold text-lg text-yellow-500 tracking-wider"}>
                    {"Processing"}
                </span>
            </div>
            <div className={"flex flex-col items-center justify-center gap-2"}>
                <span className={"font-bodyPri font-normal text-base text-800"}>
                    {"Reference ID:"}
                </span>
                <span className={"font-bodyPri font-semibold text-md text-primary-dark"}>
                    {paymentIntentDetail?.data?.id}
                </span>
            </div>
            <div onClick={pageRedirect}>
                <span className={"px-5 py-1.5 rounded-full cursor-pointer font-buttons font-medium text-md text-secondary-main border-2 border-secondary-main hover:text-text-50 hover:bg-secondary-main"}>
                    {"Wallet & Transactions"}
                </span>
            </div>
        </Container>
    )
}

export default PaymentProcessing