export const url = {
    MAKE_PAYMENT: "/payments/make-payment",

    CREATE_USER_CUSTOMER_PORTAL_SESSION: "/payments/customer-portal-sessions",

    CREATE_USER_CUSTOMER_SESSION: "/payments/customer-sessions",

    GET_PRODUCTS_PRICES: "/payments/products",
    GET_PRODUCT_PRICES: "/payments/products/{id}",

    GET_USER_PAYMENT_METHOD_LIST: "/payments/payment-methods/users/{userId}",
    ATTACH_USER_PAYMENT_METHOD_DETAIL: "/payments/payment-methods/users/{userId}/attach",
    DETACH_USER_PAYMENT_METHOD_DETAIL: "/payments/payment-methods/users/{userId}/detach",

    CREATE_USER_CHECKOUT_SESSION: "/payments/checkout-sessions",

    CREATE_PAYMENT_INTENT_DETAIL: "/payments/payment-intents",
    GET_PAYMENT_INTENT_DETAIL: "/payments/payment-intents/{paymentIntentId}",

    ADD_TOKENS_THROUGH_WALLET: "/gpt/checkout"
}

export const userPaymentMethodDetailPayload = {
    name: "",
    email: "",
    paymentMethodId: ""
}

export const joinUserPaymentMethodDetailPayload = {
    userId: null,
    email: "",
    name: ""
}

export const addPaymentIntentDetailPayload = {
    paymentType: null,
    email: "",
    name: "",
    description: null,
    gatewayName: null,
    currency: null,
    amountToPay: 0,
    processingFeePct: 0,
    processingFee: 0,
    totalAmountToPay: 0,
    paymentMethodId: null,
}

export const paymentType = {
    BUY_COURSE: {
        type: 'buy_course'
    },
    BUY_COURSE_AS_GUEST: {
        type: 'buy_course_as_guest'
    },
    ADD_CREDIT_TO_WALLET: {
        type: 'add_credit_to_wallet'
    },
    BUY_GIFT_CARD: {
        type: 'buy_gift_card'
    },
    REDEEM_GIFT_CARD: {
        type: 'redeem_gift_card'
    },
}

export const paymentTypeEnum = {
    BOOKING: {
        value: 'booking',
        label: 'Booking'
    },
    GPT_TOKEN: {
        value: 'gpt_token',
        label: 'Gpt Token'
    },
    WALLET_CREDIT: {
        value: 'wallet_credit',
        label: 'Wallet Credit'
    }
}

export const paymentIntentStatusEnum = {
    SUCCEEDED: {
        label: 'Succeeded',
        value: 'succeeded',
        lightColor: '',
        darkColor: '',
    },
    PROCESSING: {
        label: 'Processing',
        value: 'processing',
        lightColor: '',
        darkColor: '',
    },
    CANCELED: {
        label: 'Cancelled',
        value: 'canceled',
        lightColor: '',
        darkColor: '',
    },
    REQUIRES_PAYMENT_METHOD: {
        label: 'Requires Payment Method',
        value: 'requires_payment_method',
        lightColor: '',
        darkColor: '',
    },
    requires_confirmation: {
        label: 'Requires Confirmation',
        value: 'requires_confirmation',
        lightColor: '',
        darkColor: '',
    },
    REQUIRES_CAPTURE: {
        label: 'Requires Capture',
        value: 'requires_capture',
        lightColor: '',
        darkColor: '',
    },
    REQUIRES_ACTION: {
        label: 'Requires Action',
        value: 'requires_action',
        lightColor: '',
        darkColor: '',
    },
}

export const paymentFundingEnum = {
    CREDIT: {
        value: 'credit',
        label: 'Credit'
    },
    DEBIT: {
        value: 'debit',
        label: 'Debit'
    },
    PREPAID: {
        value: 'prepaid',
        label: 'Prepaid'
    },
    UNKNOWN: {
        value: 'unknown',
        label: 'Unknown'
    },
}