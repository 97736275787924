import { useState, useEffect, useMemo, useCallback } from 'react'
import { cn } from "utils/cn.utils";
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { BsArrowRight, BsArrowsCollapse, BsArrowsExpand } from 'react-icons/bs';
import { MdFullscreen, MdFullscreenExit, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { MdCheckBox } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";

import FullPageLoader from "components/loader/FullPageLoader";
import ToolTipView from 'components/tooltipView';

import HeaderView from "./components/header"
import SectionView from "./components/section"
import TabView from "./components/tab"
import ContentView from "./components/tab/content"
import DescriptionView from "./components/tab/description"
import NoteView from "./components/tab/note"
// import ReviewView from "./components/tab/review"

import { useTitle } from 'hooks/useTitle';
import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { createDeleteLmsCourseStudentSectionLesson, getStudentLmsCourseDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';
import { resetStudentLmsCourseDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';

import { pagesInfo } from 'utils/pagesInfo';
import { tabEnum } from './data';
import { lmsCourseSectionLessonTypeEnum } from 'redux/edulyteLms/lmsCourse/lmsCourse.const';

const initialActive = { previous: null, current: null, next: null }


function CoursePage() {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState(state => state.user)
    const { studentLmsCourseDetail, addDestroyLmsCourseStudentSectionLesson } = useAppState(state => state.lms.lmsCourse)

    const lms_course = useMemo(() => studentLmsCourseDetail?.data?.lms_course, [studentLmsCourseDetail?.data?.lms_course])
    const lms_course_student = useMemo(() => studentLmsCourseDetail?.data?.lms_course_student, [studentLmsCourseDetail?.data?.lms_course_student])

    const dispatcher = useAppDispatcher();
    const navigate = useNavigate();
    const params = useParams();
    const [title, setTitle] = useTitle()

    const [isSidebarClosed, setIsSidebarClosed] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [activeTab, setActiveTab] = useState(tabEnum.description.value);

    const [activeSection, setActiveSection] = useState(initialActive)
    const [activeLesson, setActiveLesson] = useState(initialActive)

    useEffect(() => {
        setTitle({
            ...title,
            title: `Edulyte Course Study Mode`
        })
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.STUDENT_LMS_COURSE))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (params?.slug && user?.user?.student?.studentId) {
            dispatcher(getStudentLmsCourseDetail(params?.slug, user?.user?.student?.studentId))
        }
        return () => {
            dispatcher(resetStudentLmsCourseDetail())
        }
    }, [params?.slug, user?.user?.student])

    useEffect(() => {
        if (
            !params?.lesson_slug &&
            lms_course &&
            !!lms_course?.course_sections?.length &&
            !!lms_course?.course_sections[0]?.lms_course_section_lessons?.length
        ) {
            let section_lessons = lms_course?.course_sections?.reduce((initial, course_section) => {
                return [...initial, ...course_section?.lms_course_section_lessons]
            }, [])

            let not_completed_section_lesson;
            for (let i = 0; i < section_lessons?.length; i++) {
                const completed_section_lesson = lms_course_student?.lms_course_student_section_lessons?.find(student_section_lesson => student_section_lesson?.lms_course_section_lesson?.id === section_lessons[i]?.id)
                if (!completed_section_lesson) {
                    not_completed_section_lesson = section_lessons[i]
                    break
                }
            }

            let section_lesson_id;
            if (!!not_completed_section_lesson) {
                section_lesson_id = not_completed_section_lesson?.id
            } else {
                section_lesson_id = section_lessons[(section_lessons?.length - 1)]?.id
            }
            navigate(`lesson/${section_lesson_id}`, { replace: true })
        }
    }, [lms_course])

    useEffect(() => {
        if (
            params?.lesson_slug &&
            !!lms_course?.course_sections?.length &&
            !!lms_course?.course_sections[0]?.lms_course_section_lessons?.length
        ) {

            let section_lesson_id = Number(params?.lesson_slug)

            for (let index = 0; index < lms_course?.course_sections?.length; index++) {
                let section_lesson = lms_course?.course_sections[index]?.lms_course_section_lessons?.find(section_lesson => section_lesson_id === section_lesson?.id)
                if (!!section_lesson) {
                    setActiveSection({
                        ...initialActive,
                        previous: (index > 0) ? lms_course?.course_sections[(index - 1)] : null,
                        current: lms_course?.course_sections[index],
                        next: (index >= 0 && index < (lms_course?.course_sections?.length - 1)) ? lms_course?.course_sections[(index + 1)] : null
                    })
                    break;
                }
            }

            let section_lessons = lms_course?.course_sections?.reduce((initial, course_section) => {
                return [...initial, ...course_section?.lms_course_section_lessons]
            }, [])
            for (let index = 0; index < section_lessons?.length; index++) {
                if (section_lesson_id === section_lessons[index]?.id) {
                    setActiveLesson({
                        ...initialActive,
                        previous: (index > 0) ? section_lessons[(index - 1)] : null,
                        current: section_lessons[index],
                        next: (index >= 0 && index < (section_lessons?.length - 1)) ? section_lessons[(index + 1)] : null
                    })
                    break;
                }
            }
        }
    }, [lms_course, params?.lesson_slug])

    // useEffect(() => {
    //     const handleFullScreenChange = (e) => {
    //         if (!document.fullscreenElement) {
    //             document.exitFullscreen();
    //             setIsFullScreen(false);
    //         }
    //     }

    //     window.addEventListener("fullscreenchange", handleFullScreenChange)

    //     return () => window.removeEventListener("fullscreenchange", handleFullScreenChange)
    // }, [])

    const toggleFullScreen = () => {
        const toggleContainer = document.getElementById('fullScreen');
        const isFullScreen = document.fullscreenElement;

        if (isFullScreen) {
            document.exitFullscreen();
            setIsFullScreen(false);
        } else {
            toggleContainer.requestFullscreen();
            setIsFullScreen(true);
        }
    };

    const getIsCompleted = useCallback(() => {
        return !!lms_course_student?.lms_course_student_section_lessons?.find(student_section_lesson => student_section_lesson?.lms_course_section_lesson?.id === activeLesson?.current?.id)
    }, [lms_course_student?.lms_course_student_section_lessons, activeLesson])

    const onHandleComplete = useCallback(() => {
        if (!activeLesson?.current) return;

        const requestBody = {
            lms_course_student_id: lms_course_student?.id,
            lms_course_section_lesson_id: activeLesson?.current?.id
        }
        dispatcher(createDeleteLmsCourseStudentSectionLesson(requestBody))
    }, [lms_course_student, activeLesson])

    const getLesson = (section_lesson) => {
        let id = 0
        let title = ""

        if (section_lesson?.type === lmsCourseSectionLessonTypeEnum?.LECTURE?.value && !!section_lesson?.lecture) {
            id = section_lesson?.lecture?.id
            title = section_lesson?.lecture?.title
        } else if (section_lesson?.type === lmsCourseSectionLessonTypeEnum?.ARTICLE?.value && !!section_lesson?.article) {
            id = section_lesson?.article?.id
            title = section_lesson?.article?.title
        } else if (section_lesson?.type === lmsCourseSectionLessonTypeEnum?.QUIZ?.value && !!section_lesson?.quiz) {
            id = section_lesson?.quiz?.id
            title = section_lesson?.quiz?.title
        }

        return { id, title }
    }

    if (user?.isLoading || studentLmsCourseDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={true} />
        )
    }

    if (studentLmsCourseDetail?.message) {
        return (
            <div className={"w-full h-screen flex items-center justify-center"}>
                <div className={"flex flex-col items-center justify-center gap-8"}>
                    <span className={"font-bodyPri font-semibold text-2xl text-text-700"}>{"Message"}</span>
                    <span className={"font-bodyPri font-normal text-lg text-text-800"}>{studentLmsCourseDetail?.message}</span>
                </div>
            </div>
        )
    }


    return (
        <main className='min-h-screen w-full bg-white relative'>
            <HeaderView
                logo="https://www.edulyte.com/wp-content/uploads/2023/08/Edulyte_logo.png"
                courseId={lms_course?.id}
                courseTitle={lms_course?.title}
                thumbnail={lms_course?.thumbnail_pic_url}
                activeSlug={lms_course?.active_slug}
                isFullScreen={isFullScreen}
                toggleFullScreen={toggleFullScreen}
            />
            <section className='w-full flex relative'>
                <AnimatePresence initial={false}>
                    <motion.div className="hidden w-[450px] lg:block"
                        animate={{ width: isSidebarClosed ? 0 : "450px", opacity: [0, 1] }}
                        exit={{ width: 0, opacity: 0 }}
                        onTransitionEnd={{ display: "none", opacity: 0 }}
                    >
                        <div className='sticky top-[4rem]'>
                            <SectionView
                                activeSection={activeSection}
                                activeLesson={activeLesson}
                                getLesson={getLesson}
                                course_sections={lms_course?.course_sections || []}
                                onSideBarClose={() => setIsSidebarClosed(true)}
                            />
                        </div>
                    </motion.div>
                </AnimatePresence>
                <div className='w-full'>
                    <button
                        className={cn(
                            "flex item-center justify-center bg-background-darkLight text-text-900 absolute top-10 left-0 px-3 z-20 py-2 gap-2 ease-in-out duration-500 rounded-sm",
                            isSidebarClosed ? "-translate-x-[7.7rem] hover:translate-x-0" : "hidden"
                        )}
                        onClick={() => setIsSidebarClosed(false)}
                    >
                        {"Course Content"}
                        <BsArrowRight size={22} />
                    </button>
                    <div id='fullScreen' className={"w-full h-[70vh] relative flex flex-col bg-white"}>
                        <ToolTipView content={`Back | ${getLesson(activeLesson?.previous)?.title}`} disabled={!activeLesson?.previous} placement={"right"}>
                            <button
                                className={cn(
                                    "absolute left-0 top-[50%] transform -translate-y-[50%] z-30 rounded-sm opacity-30 hover:opacity-100 bg-background-black text-white px-0.5 py-3",
                                    !activeLesson?.previous && "hover:opacity-30 cursor-not-allowed"
                                )}
                                onClick={() => navigate(`lesson/${activeLesson?.previous?.id}`)}
                                disabled={!activeLesson?.previous}
                            >
                                <MdKeyboardArrowLeft size={25} />
                            </button>
                        </ToolTipView>
                        {(lms_course && params?.lesson_slug) && (
                            <Outlet />
                        )}
                        {!params?.lesson_slug && (
                            <div className={"grow h-ful flex items-center justify-center"}>
                                <span className={"font-bodyPri font-bold text-xl text-secondary-main"}>
                                    {"Oops, Please select any lesson..."}</span>
                            </div>
                        )}
                        <ToolTipView content={`Next | ${getLesson(activeLesson?.next)?.title}`} disabled={!activeLesson?.next} placement={"left"}>
                            <button
                                className={cn(
                                    "absolute right-0 top-[50%] transform -translate-y-[50%] z-30 rounded-sm opacity-30 hover:opacity-100 bg-background-black text-white px-0.5 py-3",
                                    !activeLesson?.next && "hover:opacity-30 cursor-not-allowed"
                                )}
                                onClick={() => navigate(`lesson/${activeLesson?.next?.id}`)}
                                disabled={!activeLesson?.next}
                            >
                                <MdKeyboardArrowRight size={25} />
                            </button>
                        </ToolTipView>
                        <div className="w-full flex items-center justify-between gap-4 border-b px-5">
                            {(lms_course && params?.lesson_slug) && (
                                <button
                                    className={"group cursor-pointer flex items-center justify-center gap-2"}
                                    onClick={onHandleComplete}
                                    disabled={addDestroyLmsCourseStudentSectionLesson?.isLoading}
                                >
                                    {getIsCompleted() ? (
                                        <MdCheckBox size={20} className={"text-green-500"} />
                                    ) : (
                                        <MdCheckBoxOutlineBlank size={20} className={"text-text-600 group-hover:text-text-700"} />
                                    )}
                                    <span className={cn(
                                        "font-bodyPri font-medium text-base text-text-700 group-hover:text-text-800",
                                        getIsCompleted() && "!text-green-500 !group-hover:text-green-500"
                                    )}>
                                        {getIsCompleted() ? "Completed" : "Make Complete"}
                                    </span>
                                </button>
                            )}
                            <div className='flex justify-end items-center gap-5 h-10'>
                                {!isFullScreen ? (isSidebarClosed ? (
                                    <ToolTipView content={"Exit expanded view"}>
                                        <span>
                                            <BsArrowsCollapse
                                                className='cursor-pointer rotate-90'
                                                size={20}
                                                onClick={() => setIsSidebarClosed(!isSidebarClosed)}
                                            />
                                        </span>
                                    </ToolTipView>
                                ) : (
                                    <ToolTipView content={"Expanded view"}>
                                        <span>
                                            <BsArrowsExpand
                                                className='cursor-pointer rotate-90'
                                                size={20}
                                                onClick={() => setIsSidebarClosed(!isSidebarClosed)}
                                            />
                                        </span>
                                    </ToolTipView>
                                )) : null}
                                {isFullScreen ? (
                                    <ToolTipView content={"Exit full screen"}>
                                        <span>
                                            <MdFullscreenExit
                                                className='cursor-pointer'
                                                size={28}
                                                onClick={toggleFullScreen}
                                            />
                                        </span>
                                    </ToolTipView>
                                ) : (
                                    <ToolTipView content={"Full screen"}>
                                        <span>
                                            <MdFullscreen
                                                className='cursor-pointer'
                                                size={28}
                                                onClick={toggleFullScreen}
                                            />
                                        </span>
                                    </ToolTipView>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex lg:hidden items-center bg-white gap-1">
                        <TabView
                            tabs={Object.values(tabEnum)}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                        />
                    </div>
                    <div className="hidden lg:flex items-center bg-white border-b gap-1">
                        <TabView
                            tabs={isSidebarClosed ? Object.values(tabEnum) : Object.values(tabEnum)?.filter(tab => tab.value !== tabEnum.content.value)}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                        />
                    </div>
                    {activeTab === tabEnum.content.value && (
                        <div className='bg-white px-5 py-2'>
                            <ContentView />
                        </div>
                    )}
                    {activeTab === tabEnum.description.value && (
                        <div className='bg-white px-5 py-2'>
                            <DescriptionView content={lms_course?.description} />
                        </div>
                    )}
                    {activeTab === tabEnum.notes.value && (
                        <div className='bg-white px-5 py-2'>
                            <NoteView
                                activeSection={activeSection}
                                activeLesson={activeLesson}
                                getLesson={getLesson}
                            />
                        </div>
                    )}
                    {/* {activeTab === tabEnum.reviews.value && (
                        <div className='bg-white px-5 py-2'>
                            <ReviewView />
                        </div>
                    )} */}
                </div>
            </section>
        </main>
    )
}
export default CoursePage


