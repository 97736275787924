import { toast } from "react-toastify";

import crmService from "./crm.service";
import {
    setCrmContactListLoading,
    setCrmContactListData,
    setCrmContactListMessage,

    setModifyCrmBulkContactListLoading,
    setModifyCrmBulkContactListData,
    setModifyCrmBulkContactListMessage,

    setDestroyCrmBulkContactListLoading,
    setDestroyCrmBulkContactListData,
    setDestroyCrmBulkContactListMessage,

    setAddCrmContactDetailLoading,
    setAddCrmContactDetailData,
    setAddCrmContactDetailMessage,

    setCrmContactDetailLoading,
    setCrmContactDetailData,
    setCrmContactDetailMessage,

    setModifyCrmContactDetailLoading,
    setModifyCrmContactDetailData,
    setModifyCrmContactDetailMessage,

    setDestroyCrmContactDetailLoading,
    setDestroyCrmContactDetailData,
    setDestroyCrmContactDetailMessage,

    setCrmContactStatusListLoading,
    setCrmContactStatusListData,
    setCrmContactStatusListMessage,

    setCrmModalContactStatusListLoading,
    setCrmModalContactStatusListData,
    setCrmModalContactStatusListMessage,

    setCrmContactLabelListLoading,
    setCrmContactLabelListData,
    setCrmContactLabelListMessage,

    setAddCrmContactLabelDetailLoading,
    setAddCrmContactLabelDetailData,
    setAddCrmContactLabelDetailMessage,

    setModifyCrmContactLabelDetailLoading,
    setModifyCrmContactLabelDetailData,
    setModifyCrmContactLabelDetailMessage,

    setAddContactLabelDetailLoading,
    setAddContactLabelDetailData,
    setAddContactLabelDetailMessage,

    setDestroyContactLabelDetailLoading,
    setDestroyContactLabelDetailMessage,

    setAddCrmContactEmailDetailLoading,
    setAddCrmContactEmailDetailData,
    setAddCrmContactEmailDetailMessage,

    setModifyCrmContactEmailDetailLoading,
    setModifyCrmContactEmailDetailData,
    setModifyCrmContactEmailDetailMessage,

    setDestroyCrmContactEmailDetailLoading,
    setDestroyCrmContactEmailDetailMessage,

    setAddCrmContactPhoneDetailLoading,
    setAddCrmContactPhoneDetailData,
    setAddCrmContactPhoneDetailMessage,

    setModifyCrmContactPhoneDetailLoading,
    setModifyCrmContactPhoneDetailData,
    setModifyCrmContactPhoneDetailMessage,

    setDestroyCrmContactPhoneDetailLoading,
    setDestroyCrmContactPhoneDetailMessage,

    setAddCrmContactSocialDetailLoading,
    setAddCrmContactSocialDetailData,
    setAddCrmContactSocialDetailMessage,

    setModifyCrmContactSocialDetailLoading,
    setModifyCrmContactSocialDetailData,
    setModifyCrmContactSocialDetailMessage,

    setDestroyCrmContactSocialDetailLoading,
    setDestroyCrmContactSocialDetailMessage,

    setAddCrmContactAddressDetailLoading,
    setAddCrmContactAddressDetailData,
    setAddCrmContactAddressDetailMessage,

    setModifyCrmContactAddressDetailLoading,
    setModifyCrmContactAddressDetailData,
    setModifyCrmContactAddressDetailMessage,

    setDestroyCrmContactAddressDetailLoading,
    setDestroyCrmContactAddressDetailData,
    setDestroyCrmContactAddressDetailMessage,

    setAddCrmContactNoteDetailLoading,
    setAddCrmContactNoteDetailData,
    setAddCrmContactNoteDetailMessage,

    setModifyCrmContactNoteDetailLoading,
    setModifyCrmContactNoteDetailData,
    setModifyCrmContactNoteDetailMessage,

    setDestroyCrmContactNoteDetailLoading,
    setDestroyCrmContactNoteDetailMessage,

    setModifyCrmContactDetailPayload,
} from "redux/crm/crm.slice";

export const getCrmContactList = (userId, query) => async (dispatch) => {
    dispatch(setCrmContactListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: query
        }
        const response = await crmService.getCrmContactList(requestData)
        if (response.status === 200) {
            dispatch(setCrmContactListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setCrmContactListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setCrmContactListLoading(false))
    }
}

export const updateCrmBulkContactList = (body, query = { page: 1, records: 10 }) => async (dispatch, getState) => {
    dispatch(setModifyCrmBulkContactListLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            body: body
        }
        const response = await crmService.updateCrmBulkContactList(requestData)
        if (response.status === 200) {
            dispatch(setModifyCrmBulkContactListData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyCrmBulkContactListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyCrmBulkContactListLoading(false))
    }
}

export const deleteCrmBulkContactList = (body, query = { page: 1, records: 10 }) => async (dispatch) => {
    dispatch(setDestroyCrmBulkContactListLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await crmService.deleteCrmBulkContactList(requestData)
        if (response.status === 200) {
            dispatch(setDestroyCrmBulkContactListData(response.data.message || "Deleted Successfully!"))
            toast.success(response.data.message || "Deleted Successfully!")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyCrmBulkContactListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyCrmBulkContactListLoading(false))
    }
}

export const createCrmContactDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddCrmContactDetailLoading(true))

    try {
        const { crmLeadList } = getState().crm
        const requestData = {
            body: body
        }

        const response = await crmService.createCrmContactDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddCrmContactDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddCrmContactDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddCrmContactDetailLoading(false))
    }
}

export const getCrmContactDetail = (contactId, userId) => async (dispatch) => {
    dispatch(setCrmContactDetailLoading(true))

    try {
        const requestData = {
            params: {
                contactId: contactId,
                userId: userId
            }
        }

        const response = await crmService.getCrmContactDetail(requestData)
        if (response.status === 200) {
            dispatch(setCrmContactDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setCrmContactDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setCrmContactDetailLoading(false))
    }
}

export const updateCrmContactDetail = (contactId, body, query = { page: 1, records: 10 }) => async (dispatch) => {
    dispatch(setModifyCrmContactDetailLoading(true))

    try {
        const requestData = {
            params: { contactId: contactId },
            body: body
        }
        const response = await crmService.updateCrmContactDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyCrmContactDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyCrmContactDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyCrmContactDetailLoading(false))
    }
}

export const deleteCrmContactDetail = (contactId, query = { page: 1, records: 10 }) => async (dispatch, getState) => {
    dispatch(setDestroyCrmContactDetailLoading(true))

    try {
        const { crmContactList } = getState().crm
        const requestData = {
            params: { contactId: contactId }
        }
        const response = await crmService.deleteCrmContactDetail(requestData)
        if (response.status === 200) {
            dispatch(setDestroyCrmContactDetailData(response.data.message || "Deleted Successfully!"))
            dispatch(setCrmContactListData({
                ...crmContactList?.data,
                results: crmContactList?.data?.results?.filter((item) => item?.id !== contactId)
            }))
            toast.success(response.data.message || "Deleted Successfully!")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyCrmContactDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyCrmContactDetailLoading(false))
    }
}

// crm contact status list
export const getCrmContactStatusList = (query) => async (dispatch) => {
    dispatch(setCrmContactStatusListLoading(true))

    try {
        const requestDataPayload = {
            query: query
        }
        const response = await crmService.getCrmContactStatusList(requestDataPayload)
        if (response.status === 200) {
            const handleStatusOrder = (data) => {
                const status_list = data.slice();
                let activeStatus;
                for (let i = 0; i < status_list.length; i++) {
                    const obj = status_list[i];
                    if (obj.category === 'lead') {
                        return status_list.reverse();
                    } else if (obj.category === 'client' && obj.key === 'active') {
                        activeStatus = obj;
                        status_list.splice(i, 1);
                        break;
                    }
                }
                if (activeStatus) {
                    return [activeStatus, ...status_list];
                } else {
                    return status_list;
                }
            }
            dispatch(setCrmContactStatusListData(handleStatusOrder(response.data.data)))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setCrmContactStatusListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setCrmContactStatusListLoading(false))
    }
}

// crm modal contact status list
export const getCrmModalContactStatusList = (query) => async (dispatch) => {
    dispatch(setCrmModalContactStatusListLoading(true))

    try {
        const requestDataPayload = {
            query: query
        }
        const response = await crmService.getCrmContactStatusList(requestDataPayload)
        if (response.status === 200) {
            const handleStatusOrder = (data) => {
                const status_list = data.slice();
                let activeStatus;
                for (let i = 0; i < status_list.length; i++) {
                    const obj = status_list[i];
                    if (obj.category === 'lead') {
                        return status_list.reverse();
                    } else if (obj.category === 'client' && obj.key === 'active') {
                        activeStatus = obj;
                        status_list.splice(i, 1);
                        break;
                    }
                }
                if (activeStatus) {
                    return [activeStatus, ...status_list];
                } else {
                    return status_list;
                }
            }
            dispatch(setCrmModalContactStatusListData(handleStatusOrder(response.data.data)))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setCrmModalContactStatusListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setCrmModalContactStatusListLoading(false))
    }
}

// crm contact label list
export const getCrmContactLabelList = (query) => async (dispatch) => {
    dispatch(setCrmContactLabelListLoading(true))

    try {
        const requestData = {
            query: query
        }

        const response = await crmService.getCrmContactLabelList(requestData)
        if (response.status === 200) {
            dispatch(setCrmContactLabelListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setCrmContactLabelListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setCrmContactLabelListLoading(false))
    }
}

// add crm contact label
export const createCrmContactLabelDetail = (body) => async (dispatch) => {
    dispatch(setAddCrmContactLabelDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await crmService.createCrmContactLabelDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddCrmContactLabelDetailData(response.data.data))
            // toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddCrmContactLabelDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddCrmContactLabelDetailLoading(false))
    }
}

// update crm contact label
export const updateCrmContactLabelDetail = (labelId, body) => async (dispatch) => {
    dispatch(setModifyCrmContactLabelDetailLoading(true))

    try {
        const requestData = {
            params: { labelId: labelId },
            body: body
        }
        const response = await crmService.updateCrmContactLabelDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyCrmContactLabelDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyCrmContactLabelDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyCrmContactLabelDetailLoading(false))
    }
}

// add contact label
export const createContactLabelDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddContactLabelDetailLoading(true))

    try {
        const { crmContactDetail } = getState().crm
        const requestData = {
            body: body
        }
        const response = await crmService.createContactLabelDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddContactLabelDetailData(response.data.data))
            dispatch(setCrmContactDetailData({
                ...crmContactDetail?.data,
                contact_labels: [...crmContactDetail?.data?.contact_labels, response.data.data]
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddContactLabelDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddContactLabelDetailLoading(false))
    }
}

// delete contact label
export const deleteContactLabelDetail = (labelId) => async (dispatch, getState) => {
    dispatch(setDestroyContactLabelDetailLoading(true))

    try {
        const { crmContactDetail, modifyCrmContactDetail } = getState().crm
        const requestData = {
            params: { labelId: labelId }
        }
        const response = await crmService.deleteContactLabelDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                labels: modifyCrmContactDetail?.payload?.labels?.filter((labelItem) => labelItem?.id !== labelId),
            }))
            dispatch(setCrmContactDetailData({
                ...crmContactDetail?.data,
                contact_labels: crmContactDetail?.data?.contact_labels?.filter((label) => label?.id !== labelId)
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyContactLabelDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyContactLabelDetailLoading(false))
    }
}

// add crm contact email
export const createCrmContactEmailDetail = (body) => async (dispatch) => {
    dispatch(setAddCrmContactEmailDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await crmService.createCrmContactEmailDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddCrmContactEmailDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddCrmContactEmailDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddCrmContactEmailDetailLoading(false))
    }
}

// update crm contact email
export const updateCrmContactEmailDetail = (emailId, body) => async (dispatch) => {
    dispatch(setModifyCrmContactEmailDetailLoading(true))

    try {
        const requestData = {
            params: { emailId: emailId },
            body: body
        }
        const response = await crmService.updateCrmContactEmailDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyCrmContactEmailDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyCrmContactEmailDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyCrmContactEmailDetailLoading(false))
    }
}

// delete crm contact email
export const deleteCrmContactEmailDetail = (emailId) => async (dispatch, getState) => {
    dispatch(setDestroyCrmContactEmailDetailLoading(true))

    try {
        const { modifyCrmContactDetail } = getState().crm
        const requestData = {
            params: { emailId: emailId }
        }
        const response = await crmService.deleteCrmContactEmailDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                emails: modifyCrmContactDetail?.payload?.emails?.filter((email) => email?.id !== emailId)
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyCrmContactEmailDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyCrmContactEmailDetailLoading(false))
    }
}

// add crm contact phone
export const createCrmContactPhoneDetail = (body) => async (dispatch) => {
    dispatch(setAddCrmContactPhoneDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await crmService.createCrmContactPhoneDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddCrmContactPhoneDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddCrmContactPhoneDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddCrmContactPhoneDetailLoading(false))
    }
}

// update crm contact phone
export const updateCrmContactPhoneDetail = (phoneId, body) => async (dispatch) => {
    dispatch(setModifyCrmContactPhoneDetailLoading(true))

    try {
        const requestData = {
            params: { phoneId: phoneId },
            body: body
        }
        const response = await crmService.updateCrmContactPhoneDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyCrmContactPhoneDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyCrmContactPhoneDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyCrmContactPhoneDetailLoading(false))
    }
}

// delete crm contact phone
export const deleteCrmContactPhoneDetail = (phoneId) => async (dispatch, getState) => {
    dispatch(setDestroyCrmContactPhoneDetailLoading(true))

    try {
        const { modifyCrmContactDetail } = getState().crm
        const requestData = {
            params: { phoneId: phoneId }
        }
        const response = await crmService.deleteCrmContactPhoneDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                phones: modifyCrmContactDetail?.payload?.phones?.filter((phone) => phone?.id !== phoneId)
            }))
            toast.success(response.data.message || "Deleted successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyCrmContactPhoneDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyCrmContactPhoneDetailLoading(false))
    }
}

// add crm contact social
export const createCrmContactSocialDetail = (body) => async (dispatch) => {
    dispatch(setAddCrmContactSocialDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await crmService.createCrmContactSocialDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddCrmContactSocialDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddCrmContactSocialDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddCrmContactSocialDetailLoading(false))
    }
}

// update crm contact social
export const updateCrmContactSocialDetail = (socialId, body) => async (dispatch) => {
    dispatch(setModifyCrmContactSocialDetailLoading(true))

    try {
        const requestData = {
            params: { socialId: socialId },
            body: body
        }
        const response = await crmService.updateCrmContactSocialDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyCrmContactSocialDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyCrmContactSocialDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyCrmContactSocialDetailLoading(false))
    }
}

// delete crm contact social
export const deleteCrmContactSocialDetail = (socialId) => async (dispatch, getState) => {
    dispatch(setDestroyCrmContactSocialDetailLoading(true))

    try {
        const { modifyCrmContactDetail } = getState().crm
        const requestData = {
            params: { socialId: socialId }
        }
        const response = await crmService.deleteCrmContactSocialDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                socials: modifyCrmContactDetail?.payload?.socials?.filter((social) => social?.id !== socialId)
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyCrmContactSocialDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyCrmContactSocialDetailLoading(false))
    }
}

// add crm contact address
export const createCrmContactAddressDetail = (body) => async (dispatch) => {
    dispatch(setAddCrmContactAddressDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await crmService.createCrmContactAddressDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddCrmContactAddressDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddCrmContactAddressDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddCrmContactAddressDetailLoading(false))
    }
}

// update crm contact address
export const updateCrmContactAddressDetail = (addressId, body) => async (dispatch) => {
    dispatch(setModifyCrmContactAddressDetailLoading(true))

    try {
        const requestData = {
            params: { addressId: addressId },
            body: body
        }
        const response = await crmService.updateCrmContactAddressDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyCrmContactAddressDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyCrmContactAddressDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyCrmContactAddressDetailLoading(false))
    }
}

// delete crm contact address
export const deleteCrmContactAddressDetail = (addressId) => async (dispatch, getState) => {
    dispatch(setDestroyCrmContactAddressDetailLoading(true))

    try {
        const { modifyCrmContactDetail } = getState().crm
        const requestData = {
            params: { addressId: addressId }
        }
        const response = await crmService.deleteCrmContactAddressDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                addresses: modifyCrmContactDetail?.payload?.addresses?.filter((addressItem) => addressItem?.id !== addressId)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyCrmContactAddressDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyCrmContactAddressDetailLoading(false))
    }
}

// add crm contact note
export const createCrmContactNoteDetail = (body) => async (dispatch) => {
    dispatch(setAddCrmContactNoteDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await crmService.createCrmContactNoteDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddCrmContactNoteDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddCrmContactNoteDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddCrmContactNoteDetailLoading(false))
    }
}

// update crm contact note
export const updateCrmContactNoteDetail = (noteId, body) => async (dispatch) => {
    dispatch(setModifyCrmContactNoteDetailLoading(true))

    try {
        const requestData = {
            params: { noteId: noteId },
            body: body
        }
        const response = await crmService.updateCrmContactNoteDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyCrmContactNoteDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyCrmContactNoteDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyCrmContactNoteDetailLoading(false))
    }
}

// delete crm contact note
export const deleteCrmContactNoteDetail = (noteId) => async (dispatch, getState) => {
    dispatch(setDestroyCrmContactNoteDetailLoading(true))

    try {
        const { modifyCrmContactDetail } = getState().crm
        const requestData = {
            params: { noteId: noteId }
        }
        const response = await crmService.deleteCrmContactNoteDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                notes: modifyCrmContactDetail?.payload?.notes?.filter((note) => note?.id !== noteId)
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyCrmContactNoteDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyCrmContactNoteDetailLoading(false))
    }
}