import format from 'string-format';

import { baseAuthApiInstance } from 'apiInstances/axios.apiInstance';

import { url } from './lmsQuiz.const';


class LmsQuizService {

    addLmsQuizDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUIZ_DETAIL),
            body
        )

        return response;
    }

    createAiLmsQuiz = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_AI_LMS_QUIZ),
            body
        )

        return response;
    }

    getAiLmsQuiz = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_AI_LMS_QUIZ),
            { params: query }
        )

        return response;
    }

    createAiContentDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_AI_CONTENT_DETAIL),
            body
        )

        return response;
    }

    createAIlmsQuizDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_AI_LMS_QUIZ_DETAIL),
            body
        )
        return response;
    }

    getLmsQuizList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_LIST),
            { params: query }
        )
        return response;
    }

    getLmsQuizDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_DETAIL, params)
        )

        return response;
    }

    updateLmsQuizDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUIZ_DETAIL, params),
            body
        )
        return response;
    }


    /**************************** Quiz Section **************************/

    createLmsQuizSectionDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUIZ_SECTION_DETAIL),
            body
        )

        return response;
    }

    getLmsQuizSectionDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_SECTION_DETAIL, params)
        )

        return response;
    }

    getLmsQuizSectionList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_SECTION_LIST),
            { params: query }
        )

        return response;
    }

    updateLmsQuizSectionDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUIZ_SECTION_DETAIL, params),
            body
        )

        return response;
    }

    deleteLmsQuizSectionDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUIZ_SECTION_DETAIL, params)
        )

        return response;
    }

    updateOrderLmsQuizSectionList = async ({ query, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_ORDER_LMS_QUIZ_SECTION_LIST),
            body,
            { params: query }
        )

        return response;
    }

    createLmsQuizCategoryDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUIZ_CATEGORY_DETAIL),
            body
        )
        return response;
    }

    getLmsQuizCategoryDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_CATEGORY_DETAIL, params)
        )
        return response;
    }

    getLmsQuizCategoryList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_CATEGORY_LIST),
            { params: query }
        )
        return response;
    }

    updateLmsQuizCategoryOrderList = async ({ body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUIZ_CATEGORY_ORDER_LIST),
            body,
            { params: query }
        )
        return response;
    }

    updateLmsQuizCategoryDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUIZ_CATEGORY_DETAIL, params),
            body
        )
        return response;
    }

    deleteLmsQuizCategoryDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUIZ_CATEGORY_DETAIL, params)
        )
        return response;
    }

    // create lms quiz tag detail
    createLmsQuizTagDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUIZ_TAG_DETAIL),
            body
        )

        return response;
    }

    // update lms quiz tag list order
    updateLmsQuizTagListOrder = async ({ body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUIZ_TAG_LIST_ORDER),
            body,
            { params: query }
        )

        return response;
    }

    // delete lms quiz tag detail
    deleteLmsQuizTagDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUIZ_TAG_DETAIL, params)
        )

        return response;
    }

    createLmsQuizSettingDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUIZ_SETTING_DETAIL),
            body
        )
        return response;
    }

    getLmsQuizSettingDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_SETTING_DETAIL, params),
        )
        return response;
    }

    getLmsQuizSettinglist = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_SETTING_LIST),
            { params: query }
        )
        return response;
    }

    updateLmsQuizSettingDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUIZ_SETTING_DETAIL, params),
            body
        )
        return response;
    }

    deleteLmsQuizSettingDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUIZ_SETTING_DETAIL, params),
        )
        return response;
    }

    createLmsQuizUserDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUIZ_USER_DETAIL),
            body
        )

        return response;
    }

    getLmsQuizUserList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_USER_LIST),
            { params: query }
        )
        return response;
    }

    getLmsQuizUserDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_USER_DETAIL, params)
        )

        return response;
    }

    updateLmsQuizUserDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUIZ_USER_DETAIL, params),
            body
        )

        return response;
    }

    deleteLmsQuizUserDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUIZ_USER_DETAIL, params)
        )
        return response;
    }

    createLmsQuizResponseDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUIZ_RESPONSE_DETAIL),
            body
        )
        return response;
    }

    getLmsQuizResponseList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_RESPONSE_LIST),
            { params: query }
        )
        return response;
    }

    getLmsQuizResponseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_RESPONSE_DETAIL, params)
        )
        return response;
    }

    updateLmsQuizResponseDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUIZ_RESPONSE_DETAIL, params),
            body
        )
        return response;
    }

    deleteLmsQuizResponseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUIZ_RESPONSE_DETAIL, params),
        )
        return response;
    }

    createLmsQuizQuestionResponseDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUIZ_QUESTION_RESPONSE_DETAIL),
            body
        )

        return response;
    }

    getLmsQuizQuestionResponseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_QUESTION_RESPONSE_DETAIL, params)
        )
        return response;
    }

    getLmsQuizQuestionResponseList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_QUESTION_RESPONSE_LIST),
            { params: query }
        )
        return response;
    }

    updateLmsQuizQuestionResponseDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUIZ_QUESTION_RESPONSE_DETAIL, params),
            body
        )
        return response;
    }

    deleteLmsQuizQuestionResponseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUIZ_QUESTION_RESPONSE_DETAIL, params)
        )
        return response;
    }

    createLmsQuizQuestionResponseResourceDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUIZ_QUESTION_RESPONSE_RESOURCE_DETAIL),
            body
        )
        return response;
    }

    getLmsQuizQuestionResponseResourceList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_QUESTION_RESPONSE_RESOURCE_LIST),
            { params: query }
        )
        return response;
    }

    getLmsQuizQuestionResponseResourceDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_QUESTION_RESPONSE_RESOURCE_DETAIL, params)
        )
        return response;
    }

    updateLmsQuizQuestionResponseResourceDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUIZ_QUESTION_RESPONSE_RESOURCE_DETAIL, params),
            body
        )

        return response;
    }

    deleteLmsQuizQuestionResponseResourceDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUIZ_QUESTION_RESPONSE_RESOURCE_DETAIL, params)
        )

        return response;
    }

    // lms quiz slug detail
    createLmsQuizSlugDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUIZ_SLUG_DETAIL),
            body
        )

        return response;
    }

    deleteLmsQuizSlugDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUIZ_SLUG_DETAIL, params)
        )

        return response;
    }

    // lms quiz instructor detail
    createLmsQuizInstructorDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUIZ_INSTRUCTOR_DETAIL),
            body
        )

        return response;
    }

    updateLmsQuizInstructorDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUIZ_INSTRUCTOR_DETAIL, params),
            body
        )

        return response;
    }

    deleteLmsQuizInstructorDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUIZ_INSTRUCTOR_DETAIL, params)
        )

        return response;
    }

    // student lms quiz list
    getStudentLmsQuizList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_STUDENT_LMS_QUIZ_LIST, params),
            { params: query }
        )

        return response;
    }

    // student lms quiz detail
    getStudentLmsQuizDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_STUDENT_LMS_QUIZ_DETAIL, params)
        )

        return response;
    }

    // lms quiz student quiz response service
    createLmsQuizStudentQuizResponseDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUIZ_STUDENT_QUIZ_RESPONSE_DETAIL),
            body
        )

        return response
    }
    updateLmsQuizStudentQuizResponseDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUIZ_STUDENT_QUIZ_RESPONSE_DETAIL, params),
            body
        )

        return response
    }

    // get lms quiz student list
    getLmsQuizStudentList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_STUDENT_LIST),
            { params: query }
        )

        return response;
    }

    // create lms quiz student detail
    createLmsQuizStudentDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_QUIZ_STUDENT_DETAIL),
            body
        )

        return response;
    }

    // update lms quiz student detail
    updateLmsQuizStudentDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_QUIZ_STUDENT_DETAIL, params),
            body
        )

        return response;
    }

    // delete lms quiz student detail
    deleteLmsQuizStudentDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUIZ_STUDENT_DETAIL, params)
        )

        return response;
    }

    getLmsQuizInvitationList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_INVITATION_LIST),
            { params: query }
        )

        return response;
    }

    createSendLmsQuizInvitationDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_SEND_LMS_QUIZ_INVITATION_DETAIL),
            body
        )

        return response;
    }

    getValidateLmsQuizInvitationDetail = async (query) => {

        const response = await baseAuthApiInstance().get(
            url.GET_VALIDATE_LMS_QUIZ_INVITATION_DETAIL,
            { params: query }
        );

        return response;
    }

    getAcceptAndDeclineLmsQuizInvitationDetail = async ({ body, query }) => {

        const response = await baseAuthApiInstance().post(
            url.GET_ACCEPT_AND_DECLINE_LMS_QUIZ_INVITATION_DETAIL,
            body,
            { params: query }
        )

        return response;
    }

    deleteLmsQuizInvitationDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUIZ_INVITATION_DETAIL, params)
        )

        return response;
    }
}

export default new LmsQuizService();