import { memo } from "react";

import { motion } from 'framer-motion';

import ButtonLoader from "components/loader/button-loader";

import { cn } from 'utils/cn.utils';

const UpdateAndCancelBtn = memo(({
    updateBtnText = "Update",
    isUpdateBtnLoading = false,
    isUpdateBtnDisabled = false,
    onHandleCancelBtn,
    onHandleUpdateBtn
}) => {

    return (
        <div className={"w-full flex items-center justify-start gap-4 font-bodyPri"}>
            <motion.button
                key={"cancel"}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.3 }}
                className={cn(
                    "w-1/2 py-2 flex items-center justify-center border border-divider-dark hover:bg-divider-darkLight text-white text-sm md:text-base ease-in-out duration-200 rounded-full",
                    "font-normal text-text-800 text-base cursor-pointer"
                )}
                onClick={onHandleCancelBtn}
                disabled={isUpdateBtnLoading}
            >
                <span className={""}>
                    {"Cancel"}
                </span>
            </motion.button>
            <motion.button
                key={"save"}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.3 }}
                className={cn(
                    "w-1/2 py-2 flex items-center justify-center text-white text-sm md:text-base ease-in-out duration-200 bg-primary-dark hover:bg-secondary-dark rounded-full",
                    "font-normal text-sm sm:text-base cursor-pointer",
                    isUpdateBtnDisabled && "bg-divider-medium hover:bg-divider-medium text-divider-light cursor-not-allowed",
                    isUpdateBtnLoading && "cursor-wait"
                )}
                disabled={isUpdateBtnDisabled || isUpdateBtnLoading}
                onClick={onHandleUpdateBtn}
            >
                {isUpdateBtnLoading &&
                    <ButtonLoader isLoading={isUpdateBtnLoading} className={"py-2.5"} />
                }
                {!isUpdateBtnLoading &&
                    <span className={""}>
                        {updateBtnText}
                    </span>
                }
            </motion.button>
        </div>
    )
})

export default UpdateAndCancelBtn;