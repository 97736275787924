import { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { MdOutlineEmail } from 'react-icons/md';

import ButtonLoader from 'components/loader/ButtonLoader';

import { viewContainerConst } from 'components/modals/needATutorModal/data';

import { createSendOtp } from "redux/notification/notification.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setAddLeadDetailPayload } from 'redux/lead/lead.slice';
import { otpDeliveryMethod } from "redux/notification/notification.const";

import { validateEmail } from 'utils/validation-functions';

const Email = ({ setStepCount, setViewContainer }) => {
    const { addLeadDetail } = useAppState((state) => state.lead)
    const { sendOtp } = useAppState((state) => state.notification)

    const dispatcher = useAppDispatcher()

    const [emailAddress, setEmailAddress] = useState("")
    const [emailAddressError, setEmailAddressError] = useState("")

    useEffect(() => {
        if (addLeadDetail?.addLeadDetailPayload?.email) {
            setEmailAddress(addLeadDetail?.addLeadDetailPayload?.email)
        }
    }, [addLeadDetail?.addLeadDetailPayload?.email])

    useEffect(() => {
        if (emailAddress && !validateEmail(emailAddress)) {
            setEmailAddressError("Invalid Email")
        } else {
            setEmailAddressError("")
        }
    }, [emailAddress])

    useEffect(() => {
        if (sendOtp?.sendOtp) {
            setStepCount(5)
            setViewContainer(viewContainerConst.VERIFY_CODE.value)
        }
    }, [sendOtp?.sendOtp])

    const onAddEmailAddress = (e) => {
        if (e.keyCode === 13) {
            onHandleButton()
        }
    }

    const onHandleButton = () => {
        if (emailAddressError) return;
        if (emailAddress && (emailAddress === addLeadDetail?.addLeadDetailPayload?.email && addLeadDetail?.addLeadDetailPayload?.isEmailVerified)) {
            setStepCount(6)
            setViewContainer(viewContainerConst.PHONE.value)
            toast.success("Email already verified!")
            return;
        }
        dispatcher(setAddLeadDetailPayload({
            ...addLeadDetail?.addLeadDetailPayload,
            email: emailAddress
        }))
        dispatcher(createSendOtp({
            channel: otpDeliveryMethod?.EMAIL?.value,
            email: emailAddress
        }))
        return;
    }

    return (
        <div className={"w-full flex flex-col items-start justify-start gap-10 px-2 transition ease-in-out delay-1000 duration-1000 transform"}>
            <span className={"font-bodyPri font-bold text-4xl text-text-900 tracking-wide"}>
                <span className={"capitalize"}>
                    {addLeadDetail?.addLeadDetailPayload?.firstName}
                </span>
                {", where can we send your tutor details?"}
            </span>
            <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                <span className={"font-bodyPri font-medium text-xl text-text-900 tracking-wide"}>
                    {"Your Email Address"}
                </span>
                <div className={cn(
                    "w-full md:w-1/2 px-2 py-3 flex items-center justify-start gap-1.5 border-2 border-divider-medium focus:border-2 focus:border-text-400 rounded-md"
                )}>
                    <MdOutlineEmail className={"text-3xl text-text-500"} />
                    <input
                        type={"email"}
                        placeholder={"email@domain.com"}
                        className={cn(
                            "w-full px-2 focus:outline-none font-bodyPri font-normal text-text-800 text-lg",
                            "placeholder:text-text-500 placeholder:text-lg"
                        )}
                        autoFocus={true}
                        value={emailAddress}
                        onChange={(event) => setEmailAddress(event.target.value.toLowerCase())}
                        onKeyDown={onAddEmailAddress}
                    />
                </div>
                {emailAddressError &&
                    <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                        {emailAddressError}
                    </span>
                }
            </div>
            <span
                className={cn(
                    "px-12 py-3 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-medium text-2xl text-text-50 bg-primary-dark",
                    "transition ease-in-out delay-100 duration-300 transform hover:scale-105"
                )}
                onClick={onHandleButton}
            >
                {sendOtp?.isLoading &&
                    <ButtonLoader isLoading={sendOtp?.isLoading} />
                }
                {!sendOtp?.isLoading && "Continue"}
            </span>
        </div>
    )
}

export default Email;