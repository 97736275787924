//Firebase
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "@firebase/storage";

import { Timestamp } from "@firebase/firestore";

//upload file to cloud storage
export default async function cloudStorageFileUpload(file, user, selectedChat) {
  const storage = getStorage();
  const chatId = selectedChat.id;
  const sender = user.userId;
  const fileName = file.name;

  const fileRef = ref(storage, `chatfiles/${chatId}/${fileName}`);

  const metaData = {
    cacheContol: "public,max-age=4000",
  };

  const uploadTask = await uploadBytes(fileRef, file, metaData);
  const downloadURL = await getDownloadURL(uploadTask.ref);

  const fileDetails = {
    fileName: fileName,
    fileSize: file.size,
    fileType: file.type,
    status: "sent",
    sender: sender,
    sentAt: Timestamp.fromDate(new Date()),
    type: "file",
    url: downloadURL,
  };

  return fileDetails;
}
