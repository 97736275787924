import { DEFAULT_LMS_QUIZ_PAGE, DEFAULT_LMS_QUIZ_RECORDS, lmsQuizStatusEnum } from "redux/edulyteLms/lmsQuiz/lmsQuiz.const"

import {dayjs, timeZone } from "utils/dateTime.utils"

export const pageHeading = {
    heading: "My Quizzes",
    subHeading: "Find the list of quizzes",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/my-courses.svg"
}

export const lmsQuizTableHeaderConst = [
    "Title",
    "Status",
    "Created Date",
    "Actions",
]

export const lmsQuizMenuItems = {
    INVITE: {
        label: "Invite",
        value: "invite"
    },
    SHARE: {
        label: "Share",
        value: "share"
    },
    COPY: {
        label: "Copy",
        value: "copy"
    },
    PARTICIPANTS: {
        label: "Participants",
        value: "participants"
    },
    EDIT: {
        label: "Edit",
        value: "edit"
    },
}


export const searchParamsInfo = {
    page: {
        key: "page"
    },
    records: {
        key: "records"
    },
    title: {
        key: "title"
    },
    status: {
        key: "status"
    },
    date: {
        key: "date"
    },
    minCreatedAt: {
        key: "minCreatedAt"
    },
    maxCreatedAt: {
        key: "maxCreatedAt"
    }
}

export const lmsQuizFilters = {
    [searchParamsInfo.title.key]: {
        key: "title",
        label: "Title"
    },
    [searchParamsInfo.status.key]: {
        key: "status",
        label: "Status"
    },
    [searchParamsInfo.date.key]: {
        key: "date",
        label: "Date"
    }
}

export const getLmsQuizListPayload = async (searchQueryParams) => {

    let requestDataPayload = {
        page: searchQueryParams.get(searchParamsInfo.page.key) || DEFAULT_LMS_QUIZ_PAGE,
        records: searchQueryParams.get(searchParamsInfo.records.key) || DEFAULT_LMS_QUIZ_RECORDS,
        timeZone: timeZone
    }
    if (!!searchQueryParams.get(searchParamsInfo.title.key)) {
        requestDataPayload[searchParamsInfo.title.key] = searchQueryParams.get(searchParamsInfo.title.key).replaceAll("-", " ")
    }
    if (!!searchQueryParams?.getAll(searchParamsInfo.status.key)?.length) {
        requestDataPayload[searchParamsInfo.status.key] = searchQueryParams?.getAll(searchParamsInfo.status.key)?.map((item) => item?.replaceAll("-", "_"))?.join(",")
    }
    if (!!searchQueryParams.get(searchParamsInfo.minCreatedAt.key)) {
        requestDataPayload[searchParamsInfo.minCreatedAt.key] = dayjs(searchQueryParams.get(searchParamsInfo.minCreatedAt.key)).tz(timeZone).format("YYYY-MM-DD")
    }
    if (!!searchQueryParams.get(searchParamsInfo.maxCreatedAt.key)) {
        requestDataPayload[searchParamsInfo.maxCreatedAt.key] = dayjs(searchQueryParams.get(searchParamsInfo.maxCreatedAt.key)).tz(timeZone).format("YYYY-MM-DD")
    }

    return requestDataPayload;
}

export const getTokenCountWithSeparator = (tokenCount) => {
    return Intl.NumberFormat().format(tokenCount)
}

export const gptAiLevels = [
    { value: 'beginner', label: 'Beginner' },
    { value: 'intermediate', label: 'Intermediate' },
    { value: 'advance', label: 'Advance' },
    { value: 'expert', label: 'Expert' },
    { value: 'any', label: 'All Level' },
]