export const url = {
    CREATE_LMS_QUIZ_DETAIL: 'lms/quizzes/quizzes',
    GET_LMS_QUIZ_LIST: 'lms/quizzes/tutor-quizzes',
    UPDATE_LMS_QUIZ_DETAIL: 'lms/quizzes/quizzes/{quizId}',
    GET_LMS_QUIZ_DETAIL: 'lms/quizzes/tutor-quizzes/{quizId}',
    DELETE_LMS_QUIZ_DETAIL: 'lms/quizzes/quizzes/{quizId}',

    CREATE_AI_LMS_QUIZ: 'lms/quizzes/quizzes/ai',
    GET_AI_LMS_QUIZ: 'lms/quizzes/quizzes/ai',

    CREATE_LMS_QUIZ_SECTION_DETAIL: 'lms/quizzes/sections',
    GET_LMS_QUIZ_SECTION_LIST: 'lms/quizzes/sections/',
    UPDATE_LMS_QUIZ_SECTION_DETAIL: 'lms/quizzes/sections/{quizSectionId}',
    GET_LMS_QUIZ_SECTION_DETAIL: 'lms/quizzes/sections/{quizSectionId}',
    DELETE_LMS_QUIZ_SECTION_DETAIL: 'lms/quizzes/sections/{quizSectionId}',
    UPDATE_ORDER_LMS_QUIZ_SECTION_LIST: 'lms/quizzes/sections/order',

    CREATE_LMS_QUIZ_CATEGORY_DETAIL: 'lms/quizzes/categories',
    GET_LMS_QUIZ_CATEGORY_LIST: 'lms/quizzes/categories',
    GET_LMS_QUIZ_CATEGORY_DETAIL: 'lms/quizzes/categories/{categoryId}',
    UPDATE_LMS_QUIZ_CATEGORY_ORDER_LIST: 'lms/quizzes/categories/order',
    UPDATE_LMS_QUIZ_CATEGORY_DETAIL: 'lms/quizzes/categories/{categoryId}',
    DELETE_LMS_QUIZ_CATEGORY_DETAIL: 'lms/quizzes/categories/{categoryId}',

    // lms quiz tag detail
    CREATE_LMS_QUIZ_TAG_DETAIL: "/lms/quizzes/tags",
    UPDATE_LMS_QUIZ_TAG_LIST_ORDER: "/lms/quizzes/tags/order",
    DELETE_LMS_QUIZ_TAG_DETAIL: "lms/quizzes/tags/{quizTagId}",

    CREATE_LMS_QUIZ_SETTING_DETAIL: 'lms/quizzes/settings',
    GET_LMS_QUIZ_SETTING_LIST: 'lms/quizzes/settings',
    GET_LMS_QUIZ_SETTING_DETAIL: 'lms/quizzes/settings/{settingId}',
    UPDATE_LMS_QUIZ_SETTING_DETAIL: 'lms/quizzes/settings/{settingId}',
    DELETE_LMS_QUIZ_SETTING_DETAIL: 'lms/quizzes/settings/{settingId}',

    CREATE_LMS_QUIZ_USER_DETAIL: 'lms/quizzes/users',
    GET_LMS_QUIZ_USER_LIST: 'lms/quizzes/users/{quizUserId}',
    GET_LMS_QUIZ_USER_DETAIL: 'lms/quizzes/users/{quizUserId}',
    UPDATE_LMS_QUIZ_USER_DETAIL: 'lms/quizzes/users/{quizUserId}',
    DELETE_LMS_QUIZ_USER_DETAIL: 'lms/quizzes/users/{quizUserId}',

    CREATE_LMS_QUIZ_RESPONSE_DETAIL: 'lms/quizzes/responses',
    GET_LMS_QUIZ_RESPONSE_LIST: 'lms/quizzes/responses',
    GET_LMS_QUIZ_RESPONSE_DETAIL: 'lms/quizzes/responses/{quizResponseId}',
    UPDATE_LMS_QUIZ_RESPONSE_DETAIL: 'lms/quizzes/responses/{quizResponseId}',
    DELETE_LMS_QUIZ_RESPONSE_DETAIL: 'lms/quizzes/responses/{quizResponseId}',

    CREATE_LMS_QUIZ_QUESTION_RESPONSE_DETAIL: 'lms/quizzes/question-responses',
    GET_LMS_QUIZ_QUESTION_RESPONSE_LIST: 'lms/quizzes/question-responses',
    GET_LMS_QUIZ_QUESTION_RESPONSE_DETAIL: 'lms/quizzes/question-responses/{quizQuestionResponseId}',
    UPDATE_LMS_QUIZ_QUESTION_RESPONSE_DETAIL: 'lms/quizzes/question-responses/{quizQuestionResponseId}',
    DELETE_LMS_QUIZ_QUESTION_RESPONSE_DETAIL: 'lms/quizzes/question-responses/{quizQuestionResponseId}',

    CREATE_LMS_QUIZ_QUESTION_RESPONSE_RESOURCE_DETAIL: 'lms/quizzes/question-response-resources',
    GET_LMS_QUIZ_QUESTION_RESPONSE_RESOURCE_LIST: 'lms/quizzes/question-response-resources',
    GET_LMS_QUIZ_QUESTION_RESPONSE_RESOURCE_DETAIL: 'lms/quizzes/question-response-resources/{quizQuestionResponseResourceId}',
    UPDATE_LMS_QUIZ_QUESTION_RESPONSE_RESOURCE_DETAIL: 'lms/quizzes/question-response-resources/{quizQuestionResponseResourceId}',
    DELETE_LMS_QUIZ_QUESTION_RESPONSE_RESOURCE_DETAIL: 'lms/quizzes/question-response-resources/{quizQuestionResponseResourceId}',

    CREATE_LMS_QUIZ_SLUG_DETAIL: "lms/quizzes/slugs",
    DELETE_LMS_QUIZ_SLUG_DETAIL: "lms/quizzes/slugs/{quizSlugId}",

    CREATE_LMS_QUIZ_INSTRUCTOR_DETAIL: "lms/quizzes/users",
    UPDATE_LMS_QUIZ_INSTRUCTOR_DETAIL: "lms/quizzes/users/{quizInstructorId}",
    DELETE_LMS_QUIZ_INSTRUCTOR_DETAIL: "lms/quizzes/users/{quizInstructorId}",


    GET_STUDENT_LMS_QUIZ_LIST: "lms/quizzes/quizzes/students/{student_id}",
    GET_STUDENT_LMS_QUIZ_DETAIL: "lms/quizzes/quizzes/{slug}/students/{student_id}",

    // lms quiz student quiz response
    CREATE_LMS_QUIZ_STUDENT_QUIZ_RESPONSE_DETAIL: "lms/quizzes/student-quiz-responses",
    UPDATE_LMS_QUIZ_STUDENT_QUIZ_RESPONSE_DETAIL: "lms/quizzes/student-quiz-responses/{id}",

    // lms quiz student detail
    GET_LMS_QUIZ_STUDENT_LIST: "lms/quizzes/students",
    CREATE_LMS_QUIZ_STUDENT_DETAIL: "lms/quizzes/students",
    UPDATE_LMS_QUIZ_STUDENT_DETAIL: "lms/quizzes/students/{quizStudentId}",
    DELETE_LMS_QUIZ_STUDENT_DETAIL: "lms/quizzes/students/{quizStudentId}",

    // lms quiz invitation
    GET_LMS_QUIZ_INVITATION_LIST: "lms/quizzes/invitations",
    CREATE_SEND_LMS_QUIZ_INVITATION_DETAIL: "lms/quizzes/invitations/send",
    GET_VALIDATE_LMS_QUIZ_INVITATION_DETAIL: 'lms/quizzes/invitations/validate',
    GET_ACCEPT_AND_DECLINE_LMS_QUIZ_INVITATION_DETAIL: 'lms/quizzes/invitations/accept',
    DELETE_LMS_QUIZ_INVITATION_DETAIL: "lms/quizzes/invitations/{quizInvitationId}",

    // lms quiz creation through AI (openAI API)
    CREATE_AI_CONTENT_DETAIL: "gpt/generate-response",
    CREATE_AI_LMS_QUIZ_DETAIL: 'lms/quizzes/quizzes/create-bulk-quizzes',
}

export const lmsQuizStatusEnum = {
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
}

export const lmsQuizCategoryEnum = {
    BULK_QUESTION: {
        label: "AI Questions",
        value: "questions"
    },
    QUESTION: {
        label: "Question",
        value: "question"
    },
    ARTICLE: {
        label: "Article",
        value: "article"
    },
    IMAGE: {
        label: "Image",
        value: "resource"
    },
    VIDEO: {
        label: "Video",
        value: "resource"
    }
}

export const lmsQuizShareableEnum = {
    YES: {
        label: "Yes",
        value: "yes"
    },
    NO: {
        label: "No",
        value: "no"
    }
}

export const lmsQuizVisibilityEnum = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    }
}

export const lmsQuizCheckoutEnum = {
    YES: {
        label: "Yes",
        value: "yes"
    },
    NO: {
        label: "No",
        value: "no"
    }
}

export const lmsQuizInstructorPermissionEnum = {
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    }
}

export const lmsQuizStudentStatusEnum = {
    PENDING: {
        label: "pending",
        value: "pending"
    },
    JOINED: {
        label: "Joined",
        value: "joined"
    },
    CANCELLED: {
        label: "Cancelled",
        value: "cancelled"
    }
}

export const lmsQuizStudentIsActiveEnum = {
    ACTIVE: {
        label: "Active",
        value: "active"
    },
    INACTIVE: {
        label: "In Active",
        value: "inactive"
    },
    BLOCKED: {
        label: "Block",
        value: "blocked"
    }
}

export const lmsQuizInviteToEnum = {
    TUTOR: {
        label: "Tutor",
        value: "tutor"
    },
    STUDENT: {
        label: "Student",
        value: "student"
    }
}

export const lmsQuizJoiningFlowEnum = {
    CHECKOUT: {
        label: "Checkout",
        value: "checkout"
    },
    INVITE: {
        label: "Invite",
        value: "invite"
    },
    MANUAL: {
        label: "Manual",
        value: "manual"
    },
}

export const lmsQuizInviteStatusEnum = {
    INVITED: {
        label: "Invited",
        value: "invited"
    },
    RE_INVITED: {
        label: "Re Invited",
        value: "re_invited"
    },
    ACCEPTED: {
        label: "Accepted",
        value: "accepted"
    },
    DECLINED: {
        label: "Declined",
        value: "declined"
    }
}

export const addLmsQuizSectionPayload = {
    sectionId: null,
    quizCategoryType: ""
}

export const lmsQuizSettingsPayload = {
    activeTab: "general"
}

export const addLmsQuizInstructorDetailPayload = {
    userId: null,
    permission: null,
    quizInstructorId: null
}

export const sendLmsQuizInvitationDetailPayload = {
    lmsQuizId: null,
    emails: [],
    invitedUsers: [],
    inviteTo: null,
    inviteEmailSent: true,
    senderUserId: null
}

export const DEFAULT_LMS_QUIZ_PAGE = 1
export const DEFAULT_LMS_QUIZ_RECORDS = 15