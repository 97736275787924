import { eventStatus } from 'redux/event/event.const';
import { sessionStatusEnum } from 'redux/session/session.const';
import { dayjs, getTimeZoneOffset, timeZone } from "utils/dateTime.utils";

export const pageHeading = {
    heading: " My Schedule & Calendar",
    subHeading: "See your daily, weekly or monthly schedule in your personalised calendar",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/schedule.svg"
}

export const statData = {
    ALL: {
        ...eventStatus.ALL,
        label: 'All Sessions',
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/calendar/all.png",
        data: 0,
        unit: 'All time',
    },
    PENDING: {
        ...eventStatus.PENDING,
        label: 'Pending',
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/calendar/pending.png",
        data: 0,
        unit: 'All time',
    },
    SCHEDULED: {
        ...eventStatus.SCHEDULED,
        label: 'Scheduled',
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/calendar/scheduled.png",
        data: 0,
        unit: 'All time',
    },
    ON_GOING: {
        ...eventStatus.ON_GOING,
        label: 'On Going',
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/calendar/on-going.png",
        data: 0,
        unit: 'All time',
    },
    COMPLETED: {
        ...eventStatus.COMPLETED,
        label: 'Completed',
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/calendar/completed.png",
        data: 0,
        unit: 'All time',
    },
    DECLINED: {
        ...eventStatus.DECLINED,
        label: 'Declined',
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/calendar/declined.png",
        data: 0,
        unit: 'All time',
    },
    CANCELLED: {
        ...eventStatus.CANCELLED,
        label: 'Cancelled',
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/calendar/cancelled.png",
        data: 0,
        unit: 'All time',
    }
}

export const getStartEndDateTime = (startDateTime) => {
    let newModifiedStartDateTime = dayjs(startDateTime).startOf('day')
    let newModifiedEndDateTime = dayjs(startDateTime).startOf('day')

    const currentMinute = dayjs().minute()

    if (currentMinute < 30) {
        newModifiedStartDateTime = newModifiedStartDateTime.set('hour', dayjs().hour()).set('minute', 30).set('second', 0)
        newModifiedEndDateTime = newModifiedEndDateTime.set('hour', dayjs().hour() + 1).set('minute', 30).set('second', 0)
    } else {
        newModifiedStartDateTime = newModifiedStartDateTime.set('hour', dayjs().hour() + 1).set('minute', 0).set('second', 0)
        newModifiedEndDateTime = newModifiedEndDateTime.set('hour', dayjs().hour() + 2).set('minute', 0).set('second', 0)
    }

    return { newModifiedStartDateTime, newModifiedEndDateTime }
}

export const searchParamsInfo = {
    page: {
        key: "page"
    },
    records: {
        key: "records"
    },
    type: {
        key: "type"
    },
    sessionId: {
        key: "sessionId"
    },
    eventlyId: {
        key: "eventlyId"
    }
}

export const calendarFilters = {
    [searchParamsInfo.type.key]: {
        key: "type",
        label: "Type",
    },
}

export const getCalendarListPayload = (searchQueryParams, viewProps) => {

    let requestDataPayload = {}

    if (!!searchQueryParams.get(searchParamsInfo.type.key)) {
        requestDataPayload["type"] = searchQueryParams.get(searchParamsInfo.type.key)?.replaceAll("-", "_")
    }
    if (!!viewProps?.startDateTime) {
        requestDataPayload["minStartDateTime"] = viewProps?.startDateTime
    }
    if (!!viewProps?.endDateTime) {
        requestDataPayload["maxEndDateTime"] = viewProps?.endDateTime
    }

    return requestDataPayload;
}

export const calendarViewConst = {
    month: {
        key: "month"
    },
    week: {
        key: "week"
    },
    day: {
        key: "day"
    },
    agenda: {
        key: "agenda"
    }
}

export const getDefaultViewRange = (view = calendarViewConst.month.key) => {
    let startDayjs, endDayjs

    const currentDayjs = dayjs().tz(timeZone)

    if (view === calendarViewConst.month.key) {
        startDayjs = currentDayjs.startOf('month')
        if (startDayjs.day() !== 0) {
            startDayjs = startDayjs.subtract(startDayjs.day(), 'day')
        }
        endDayjs = startDayjs.add(34, 'day').endOf('day')
    } else if (view === calendarViewConst.week.key) {
        startDayjs = currentDayjs.startOf('week')
        endDayjs = startDayjs.add(6, 'day').endOf('day')
    } else if (view === calendarViewConst.day.key) {
        startDayjs = currentDayjs.startOf('day')
        endDayjs = currentDayjs.endOf('day')
    } else if (view === calendarViewConst.agenda.key) {
        startDayjs = currentDayjs
        endDayjs = currentDayjs.add(1, 'month')
    } else {
        throw new Error(`Unsupported view type: ${view}`)
    }

    return {
        startDateTime: startDayjs.format("YYYY-MM-DD HH:mm:ssZ"),
        endDateTime: endDayjs.format("YYYY-MM-DD HH:mm:ssZ"),
    }
}

export const getVariableViewRange = (range) => {
    const { viewProps, viewType:view } = calendarViewDetail()
    
    const timeZoneOffset = getTimeZoneOffset(timeZone)

    if (view) {
        let viewRange;

        if ([calendarViewConst.month.key, calendarViewConst.agenda.key]?.includes(view)) {
            const startDateTime = dayjs(dayjs(range?.start).format("YYYY-MM-DD HH:mm:ss") + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("YYYY-MM-DD HH:mm:ssZ")
            const endDateTime = dayjs(dayjs(range?.end).format("YYYY-MM-DD HH:mm:ss") + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("YYYY-MM-DD HH:mm:ssZ")
            viewRange = { startDateTime: startDateTime, endDateTime: endDateTime }
        } else if ([calendarViewConst.week.key]?.includes(view)) {
            const startDateTime = dayjs(dayjs(range[0]).format("YYYY-MM-DD HH:mm:ss") + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("YYYY-MM-DD HH:mm:ssZ")
            const endDateTime = dayjs(dayjs(range[6]).format("YYYY-MM-DD HH:mm:ss") + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").endOf("day").tz(timeZone).format("YYYY-MM-DD HH:mm:ssZ")
            viewRange = { startDateTime: startDateTime, endDateTime: endDateTime }
        } else if ([calendarViewConst.day.key]?.includes(view)) {
            const startDateTime = dayjs(dayjs(range[0]).format("YYYY-MM-DD HH:mm:ss") + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("YYYY-MM-DD HH:mm:ssZ")
            const endDateTime = dayjs(dayjs(range[0]).format("YYYY-MM-DD HH:mm:ss") + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").endOf("day").tz(timeZone).format("YYYY-MM-DD HH:mm:ssZ")
            viewRange = { startDateTime: startDateTime, endDateTime: endDateTime }
        }

        return viewRange;
    } else return null;
}

export const calendarViewDetail = () => {
    const path = window.location.pathname

    const regex = /^\/calendar\/(month|week|day|agenda)$/;
    const match = path.match(regex)
    if (match) {
        const viewType = match[1]
        if ([calendarViewConst.month.key, calendarViewConst.week.key, calendarViewConst.day.key, calendarViewConst.agenda.key]?.includes(viewType)) {
            const viewProps = getDefaultViewRange(viewType)
            return {viewProps, viewType};
        } else return null;
    } else return null;
}

export const colorCodes = {
    'gray-100': '#f5f5f5',
    'gray-500': '#9e9e9e',
    'orange-100': '#ffe0b2',
    'orange-500': '#ff9800',
    'red-100': '#ffebee',
    'red-500': '#f44336',
    'pink-100': '#fce4ec',
    'pink-500': '#e91e63',
    'blue-100': '#bbdefb',
    'blue-500': '#2196f3',
    'yellow-100': '#fff9c4',
    'yellow-500': '#ffeb3b',
    'green-100': '#dcedc8',
    'green-500': '#4caf50'
};

export const sessionStatusColorCodes = Object.fromEntries(
    Object.entries(sessionStatusEnum).map(([key, value]) => [key, colorCodes[value?.darkColor]])
)