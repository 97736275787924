import { useCallback, useEffect } from 'react';

import { FaCrown, FaSpinner } from 'react-icons/fa';

import { Toggle } from 'components/common-components/Toggle';
import ToolTipView from 'components/tooltipView';

import LmsCourseTooltip from 'pages/auth/edulyteLms/commonComponents/LmsCourseTooltip';

import QuizProductDetail from './QuizProductDetail';

import { updateLmsQuizDetail } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { modalConst } from 'redux/local/local.const';
import { setModal } from 'redux/local/local.slice';
import { resetAddProductDetail, resetAddProductPriceDetailPayload, resetModifyProductDetail } from 'redux/product/product.slice';
import { subscriptionPlanEnum, subscriptionStatusEnum, userPlans } from 'redux/subscription/subscription.const';

import { cn } from 'utils/cn.utils';

const QuizProduct = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { lmsQuizDetail, updateLmsQuiz } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        return () => {
            dispatcher(resetAddProductDetail())
            dispatcher(resetAddProductPriceDetailPayload())
            dispatcher(resetModifyProductDetail())
        }
    }, [])

    const onHandleChangeSetting = useCallback((key, value) => {
        if (!value) {
            const filteredActiveSubscriptions = user?.user?.user_subscriptions?.filter((subscriptionItem) => subscriptionItem?.status === subscriptionStatusEnum.active.key)
            if ((user?.user?.active_product_count >= 15) && !filteredActiveSubscriptions?.length) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.planTableModal.key]: {
                        ...modalConst.planTableModal,
                        isVisible: true,
                        title: "Unlock Premium Features: Subscribe to Go Pro",
                        userPlans
                    },
                }))
                return;
            }
        }
        const quizDetailPayload = {
            [key]: value
        }
        dispatcher(updateLmsQuizDetail(lmsQuizDetail?.data?.id, quizDetailPayload))
    }, [lmsQuizDetail?.data, user?.user])

    const handleUpgrade = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.planTableModal.key]: {
                ...modalConst.planTableModal,
                isVisible: true,
                title: "Unlock Premium Features: Subscribe to Go Pro",
                userPlans
            },
        }))
    }

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <div className={"w-full px-3 py-2 bg-divider-medium rounded-lg"}>
                <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                    {"Product & Pricing"}
                </span>
            </div>

            <div className={"w-full flex flex-col gap-5 md:gap-8 px-5"}>
                <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                    <div className={"col-span-8 order-1 lg:col-span-2 w-full flex items-center gap-1.5"}>
                        <ToolTipView
                            disabled={!user?.user?.user_subscriptions?.length}
                            allowHTML={true}
                            theme={"light"}
                            content={(() => (
                                <div className={"p-2 rounded flex flex-col items-start justify-start gap-1"}>
                                    {user?.user?.user_subscriptions?.map((user_subscription, index) => (
                                        <span key={index} className={"font-bodyPri font-medium text-base text-text-900"}>
                                            {`${subscriptionPlanEnum[user_subscription?.plan]?.label}: ${subscriptionStatusEnum[user_subscription?.status]?.label}`}
                                        </span>
                                    ))}
                                </div>
                            ))()}
                        >
                            <button onClick={handleUpgrade}>
                                <FaCrown className={"text-2xl text-complementry-main cursor-pointer"} />
                            </button>
                        </ToolTipView>
                        <p className={cn(
                            "font-bodyPri font-bold text-text-700 text-base tracking-wide",
                            lmsQuizDetail?.data?.quiz_setting?.no_checkout && "text-green-500"
                        )}>
                            {"This is Free"}
                        </p>
                        <LmsCourseTooltip tooltip={"No checkout needed if toggled on."} />
                    </div>
                    <div className={"col-span-full order-3 lg:order-2 lg:col-span-5 justify-self-stretch -m-2 flex"}>
                        <Toggle
                            value={lmsQuizDetail?.data?.quiz_setting?.no_checkout}
                            onChange={(v) => onHandleChangeSetting("no_checkout", v)}
                        />
                        {updateLmsQuiz?.isLoading &&
                            <div className={"flex items-center gap-1"}>
                                <FaSpinner className={"animate-spin text-lg text-primary-dark"} />
                                <span className={"font-bodyPri font-normal text-primary-dark text-sm"}>
                                    {"Please wait ..."}
                                </span>
                            </div>
                        }
                    </div>
                </div>

                {!lmsQuizDetail?.data?.quiz_setting?.no_checkout &&
                    <QuizProductDetail />
                }
            </div>
        </div>
    )
}

export default QuizProduct;