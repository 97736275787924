import { useState } from "react";

import { useNavigate } from "react-router-dom";

import Filters from "pages/global/findTutors/components/TutorFilter";
import Dropdowns from "pages/global/findTutors/components/Dropdowns";

import SelectTopic from "pages/global/findTutors/components/mobileView/SelectTopic";
import FilterTutor from "pages/global/findTutors/components/mobileView/FilterTutor";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setFilterProps } from "redux/tutor/tutor.slice";
import { DEFAULT_TUTOR_PAGE } from "redux/tutor/tutor.const";

import { pagesInfo } from "utils/pagesInfo";

const TutorPublicFilters = () => {
    const { filterProps } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [dropDownButton, setDropDownButton] = useState(null)

    const OnHandleKeyDownSearch = (event) => {
        if ((event.keyCode === 13) && filterProps?.search) {
            navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}?&pn=${DEFAULT_TUTOR_PAGE}&search=${filterProps?.search?.replaceAll(" ", "-")?.toString()?.toLowerCase()}`)
        }
    }

    return (
        <>
            <div className={"mx-auto container px-5 hidden lg:block space-y-3"}>
                <Filters dropDownButton={dropDownButton} setDropDownButton={setDropDownButton} />
                <Dropdowns dropDownButton={dropDownButton} setDropDownButton={setDropDownButton} />
            </div>
            <div className={"mx-auto container px-5 block lg:hidden space-y-3"}>
                <div className={"w-full flex items-center justify-stretch gap-0.5 shadow rounded-lg bg-background-medium"}>
                    <SelectTopic dropDownButton={dropDownButton} setDropDownButton={setDropDownButton} />
                    <FilterTutor dropDownButton={dropDownButton} setDropDownButton={setDropDownButton} />
                </div>
                <div className="flex flex-row items-center bg-white shadow rounded-lg border border-text-400 hover:border-text-900 hover:shadow-all px-3">
                    <input
                        type={"text"}
                        placeholder={"Search by keyword"}
                        className={"w-full px-3 py-1.5 font-bodyPri font-normal text-text-900 text-base focus:outline-none"}
                        value={filterProps?.search}
                        autoFocus={true}
                        onChange={(event) => dispatcher(setFilterProps({
                            filterProps: { ...filterProps, search: event?.target?.value }
                        }))}
                        onKeyDown={OnHandleKeyDownSearch}
                    />
                    <div className={"font-bodyPri font-normal text-text-900 text-sm border border-text-500 px-3 py-0.5 rounded-lg hover:border hover:border-secondary-dark hover:bg-secondary-dark hover:text-text-50 cursor-pointer"}
                        onClick={() => {
                            if (filterProps?.search) {
                                navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}?&pn=${DEFAULT_TUTOR_PAGE}&search=${filterProps?.search?.replaceAll(" ", "-")?.toString()?.toLowerCase()}`)
                            }
                        }}
                    >
                        {'Search'}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TutorPublicFilters;