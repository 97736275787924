import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { TiArrowSortedDown } from "react-icons/ti";
import { ImCross } from "react-icons/im";

import SimpleSearchSelect from "components/dropdowns/simpleSearchSelect/SimpleSearchSelect";
import RangeSlider from "components/dropdowns/rangeSlider/RangeSlider";
import AvailabilityDropdown from "components/dropdowns/availabilityDropdown/AvailabilityDropdown";

// Components
import SelectSearchDropDown from "components/dropdowns/selectSearchDropdown/SelectSearchDropDown";
import CourseTypeDropdown from "components/dropdowns/courseTypeDropdown/CourseTypeDropdown";
import StudentLevelDropDown from "components/dropdowns/studentLevelDropdown/StudentLevelDropDown";
import SortByDropDown from "components/dropdowns/sortByDropdown/SortByDropDown";

import { dropDownButtonConst, getQueryString } from "pages/global/findCourses/data";

import { useAppState } from "hooks/useStore";
import { DEFAULT_COURSE_PAGE, DEFAULT_COURSE_RECORDS, coursePriceTypeEnum, courseSortBy } from "redux/course/course.const";

import { pagesInfo } from "utils/pagesInfo";

const Dropdowns = ({ props, dropDownButton, setDropDownButton }) => {
  const { locals } = useAppState((s) => s.local)
  const { categoryList } = useAppState((state) => state.category)
  const { countryList, languageList, profList, currencyRateList } = useAppState((state) => state.master)
  const { filterProps } = useAppState((state) => state.course)

  const navigate = useNavigate()

  const [isTutorFromHoverEnabled, setIsTutorFromHoverEnabled] = useState(false)
  const [isSpeaksHoverEnabled, setIsSpeaksHoverEnabled] = useState(false)
  const [isCourseTypeHoverEnabled, setIsCourseTypeHoverEnabled] = useState(false)
  const [isStudentLevelHoverEnabled, setIsStudentLevelHoverEnabled] = useState(false)

  const onHandleDropdown = (dropdownValue) => {
    if (!dropDownButton) {
      setDropDownButton(dropdownValue)
    }
    if (dropDownButton === dropdownValue) {
      setDropDownButton(null)
    }
    if (dropDownButton !== dropdownValue) {
      setDropDownButton(null)
      setDropDownButton(dropdownValue)
    }
  }

  return (
    <div className="w-full flex flex-wrap flex-row justify-center items-center gap-5">

      {/* topic dropdown */}
      <div className={"relative"}>
        <div
          className={cn(
            "px-3 py-1.5 flex items-center justify-center gap-3 rounded-xl cursor-pointer bg-white border border-text-400 hover:border-text-900 hover:shadow-all",
            filterProps?.category?.length > 0 && "pl-3 pr-8 bg-primary-dark"
          )}
          onClick={() => onHandleDropdown(dropDownButtonConst?.TOPIC?.value)}
        >
          <span className={cn(
            "font-bodyPri font-normal text-text-900 text-base",
            filterProps?.category?.length > 0 && "text-text-50"
          )}
          >
            {filterProps?.category[0] || "Topic"}
          </span>
        </div>
        {filterProps?.category?.length > 0 &&
          <div className={"absolute top-2 right-2 z-20 w-5 h-5 flex items-center justify-start rounded-full bg-white"}>
            <ImCross
              className={"w-full text-sm text-primary-dark text-center p-0.5 cursor-pointer"}
              onClick={() => {
                const query = getQueryString({ ...filterProps, category: [], page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
                navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
                setDropDownButton(null)
              }}
            />
          </div>
        }
      </div>
      {(dropDownButton === dropDownButtonConst?.TOPIC?.value) &&
        <SimpleSearchSelect
          options={categoryList?.categoryList?.map((category) => ({
            label: category?.category,
            value: category?.category
          }))}
          isLoading={categoryList?.isLoading}
          isShowInputBox={true}
          isShowList={dropDownButton === dropDownButtonConst?.TOPIC?.value}
          setIsShowList={setDropDownButton}
          className={"absolute w-fit top-72 left-5 mt-7 sm:mt-0.5 sm:left-16 md:mt-2 md:left-24 md:w-80 lg:left-60 lg:mt-7 lg:w-72 z-30 overflow-y-auto"}
          onChange={(option) => navigate(option[0]?.value?.replaceAll(" ", "-")?.toLowerCase())}
        />
      }

      {/* price range dropdown */}
      <div className={"relative"}>
        <div
          className={cn(
            "px-3 py-1.5 flex items-center justify-center gap-3 rounded-xl cursor-pointer bg-white border border-text-400 hover:border-text-900 hover:shadow-all",
            ([coursePriceTypeEnum?.REGULAR?.value, coursePriceTypeEnum?.PLAN?.value]?.includes(filterProps?.priceType)) && "pl-3 pr-8 bg-primary-dark"
          )}
          onClick={() => onHandleDropdown(dropDownButtonConst?.PRICE?.value)}
        >
          <span className={cn(
            "font-bodyPri font-normal text-text-900 text-base",
            ([coursePriceTypeEnum?.REGULAR?.value, coursePriceTypeEnum?.PLAN?.value]?.includes(filterProps?.priceType)) && "text-text-50"
          )}
          >
            {(!filterProps?.priceType || filterProps?.priceType?.length === 0) &&
              "Price"
            }
            {((filterProps?.minPrice === 0 && filterProps?.maxPrice === 0) && (filterProps?.priceType === coursePriceTypeEnum?.REGULAR?.value)) &&
              coursePriceTypeEnum?.REGULAR?.label
            }
            {((filterProps?.minPrice != 0 && filterProps?.maxPrice != 0) && (filterProps?.priceType === coursePriceTypeEnum?.REGULAR?.value))
              && coursePriceTypeEnum[filterProps?.priceType?.toUpperCase()]?.label + "-" + filterProps?.minPrice + " - " + filterProps?.maxPrice
            }
            {(filterProps?.priceType === coursePriceTypeEnum?.PLAN?.value) &&
              coursePriceTypeEnum?.PLAN?.label
            }
          </span>
        </div>
        {([coursePriceTypeEnum?.REGULAR?.value, coursePriceTypeEnum?.PLAN?.value]?.includes(filterProps?.priceType)) &&
          <div
            className={"absolute top-2 right-2 z-20 w-5 h-5 flex items-center justify-start rounded-full bg-white"}>
            <ImCross
              className={"w-full text-sm text-primary-dark text-center p-0.5 cursor-pointer"}
              onClick={() => {
                setDropDownButton(null)
                const query = getQueryString({ ...filterProps, priceType: "", minPrice: 0, maxPrice: 0, page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
                navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
              }}
            />
          </div>
        }
      </div>
      {(dropDownButton === dropDownButtonConst?.PRICE?.value) &&
        <RangeSlider
          options={Object.values(coursePriceTypeEnum)}
          selectedOption={filterProps?.priceType}
          minValue={filterProps?.minPrice || currencyRateList?.currencyRateList[(locals?.currencyCode || "USD")]?.toFixed(2)}
          maxValue={filterProps?.maxPrice || (currencyRateList?.currencyRateList[(locals?.currencyCode || "USD")] * 500)?.toFixed(2)}
          isShowList={dropDownButton === dropDownButtonConst?.PRICE?.value}
          setIsShowList={setDropDownButton}
          className={"top-72 mt-7 left-48"}
          onApplyFilter={(option) => {
            const query = getQueryString({ ...filterProps, priceType: option?.priceType, minPrice: option?.minValue, maxPrice: option?.maxValue, page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
            navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
          }}
          onClearFilter={() => {
            setDropDownButton(null)
            if (filterProps?.minPrice >= 0 && filterProps?.maxPrice > 0) {
              const query = getQueryString({ ...filterProps, priceType: "", minPrice: 0, maxPrice: 0, page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
              navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
            }
          }}
        />
      }

      {/* availability dropdown */}
      <div className={"relative"}>
        {(filterProps?.day?.length === 0) &&
          <div
            className={cn(
              "px-3 py-1.5 flex items-center justify-center gap-3 rounded-xl cursor-pointer bg-white border border-text-400 hover:border-text-900 hover:shadow-all"
            )}
            onClick={() => onHandleDropdown(dropDownButtonConst?.AVAILABILITY?.value)}
          >
            <span className={"font-bodyPri font-normal text-text-900 text-base"}>
              {"Availability"}
            </span>
          </div>
        }

        {filterProps?.day?.length > 0 &&
          <div
            className={cn(
              "px-3 py-1.5 flex items-center justify-center gap-3 rounded-xl cursor-pointer bg-white border border-text-400 hover:border-text-900 hover:shadow-all",
              filterProps?.day?.length > 0 && "pl-3 pr-8 bg-primary-dark"
            )}
            onClick={() => onHandleDropdown(dropDownButtonConst?.AVAILABILITY?.value)}
          >
            <div className={"w-fit truncate"}>
              {filterProps?.day?.map((availability, index) => (
                <span className={cn(
                  "font-bodyPri font-normal text-text-900 text-base",
                  filterProps?.day?.length > 0 && "text-text-50"
                )}
                >
                  {index !== 0 && ", "}
                  {availability[0]}
                </span>
              ))}
            </div>
            <div className={"absolute top-2 right-2 z-20 w-5 h-5 flex items-center justify-start rounded-full bg-white"}>
              <ImCross className={"w-full text-sm text-primary-dark text-center p-0.5 cursor-pointer"}
                onClick={() => {
                  setDropDownButton(null)
                  const query = getQueryString({ ...filterProps, day: [], page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
                  navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
                }} />
            </div>
          </div>
        }
      </div>
      {(dropDownButton === dropDownButtonConst?.AVAILABILITY?.value) &&
        <AvailabilityDropdown
          weekOptions={props?.filters?.availability?.weekOptions}
          selectedOption={filterProps?.day?.map((items) => ({
            label: items,
            value: items
          })) || []}
          isShowList={dropDownButton === dropDownButtonConst?.AVAILABILITY?.value}
          setIsShowList={setDropDownButton}
          className={"top-72 mt-7 left-72"}
          onApplyFilter={(option) => {
            setDropDownButton(null)
            const query = getQueryString({ ...filterProps, day: option?.map((opt) => opt?.value), page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
            navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
          }}
          onClearFilter={() => {
            setDropDownButton(null)
            if (filterProps?.day?.length > 0) {
              const query = getQueryString({ ...filterProps, day: [], page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
              navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
            }
          }}
        />
      }

      {/* tutor is from dropdown */}
      <div className={"relative"}>
        <div
          className={cn(
            "px-3 py-1.5 flex items-center justify-center gap-3 rounded-xl cursor-pointer bg-white border border-text-400 hover:border-text-900 hover:shadow-all",
            filterProps?.tutorFrom?.length > 0 && "pl-3 pr-8 bg-primary-dark"
          )}
          onClick={() => onHandleDropdown(dropDownButtonConst?.TUTOR_FROM?.value)}
        >
          <span className={cn(
            "font-bodyPri font-normal text-text-900 text-base",
            filterProps?.tutorFrom?.length > 0 && "text-text-50"
          )}
          >
            {"Tutor Is From"}
          </span>
        </div>
        {filterProps?.tutorFrom?.length > 0 &&
          <div
            className={"absolute top-2 right-2 z-20 w-5 h-5 flex items-center justify-start rounded-full bg-white"}
            onMouseOver={() => setIsTutorFromHoverEnabled(true)}
            onMouseOut={() => setIsTutorFromHoverEnabled(false)}
          >
            {!isTutorFromHoverEnabled &&
              <span className={"w-full text-sm text-primary-dark text-center p-0.5"}>
                {filterProps?.tutorFrom?.length}
              </span>
            }
            {isTutorFromHoverEnabled &&
              <ImCross
                className={"w-full text-sm text-primary-dark text-center p-0.5 cursor-pointer"}
                onClick={() => {
                  setDropDownButton(null)
                  const query = getQueryString({ ...filterProps, tutorFrom: [], page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
                  navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
                }}
              />
            }
          </div>
        }
      </div>
      {(dropDownButton === dropDownButtonConst?.TUTOR_FROM?.value) &&
        <SelectSearchDropDown
          options={countryList?.countryList?.map((country) => ({
            label: country?.country,
            value: country?.country,
          }))}
          selectedOption={filterProps?.tutorFrom?.map((items) => ({
            label: items,
            value: items
          })) || []}
          isLoading={countryList?.isLoading}
          isShowList={dropDownButton === dropDownButtonConst?.TUTOR_FROM?.value}
          setIsShowList={setDropDownButton}
          className={"top-72 mt-7 left-[33rem] w-fit md:w-72"}
          multipleOptions={true}
          isShowInputBox={true}
          isShowCheckBox={true}
          onApplyFilter={(option) => {
            const query = getQueryString({ ...filterProps, tutorFrom: option?.map((opt) => opt?.value), page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
            navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
          }}
          onClearFilter={() => {
            setDropDownButton(null)
            if (filterProps?.tutorFrom?.length > 0) {
              const query = getQueryString({ ...filterProps, tutorFrom: [], page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
              navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
            }
          }}
        />
      }

      {/* speaks container */}
      <div className={"relative"}>
        <div
          className={cn(
            "px-3 py-1.5 flex items-center justify-center gap-3 rounded-xl cursor-pointer bg-white border border-text-400 hover:border-text-900 hover:shadow-all",
            filterProps?.speak?.length > 0 && "pl-3 pr-8 bg-primary-dark"
          )}
          onClick={() => onHandleDropdown(dropDownButtonConst?.SPEAKS?.value)}
        >
          <span className={cn(
            "font-bodyPri font-normal text-text-900 text-base",
            filterProps?.speak?.length > 0 && "text-text-50"
          )}
          >
            {"Speaks"}
          </span>
        </div>
        {filterProps?.speak?.length > 0 &&
          <div
            className={"absolute top-2 right-2 z-20 w-5 h-5 flex items-center justify-start rounded-full bg-white"}
            onMouseOver={() => setIsSpeaksHoverEnabled(true)}
            onMouseOut={() => setIsSpeaksHoverEnabled(false)}
          >
            {!isSpeaksHoverEnabled &&
              <span className={"w-full text-sm text-primary-dark text-center p-0.5"}>
                {filterProps?.speak?.length}
              </span>
            }
            {isSpeaksHoverEnabled &&
              <ImCross
                className={"w-full text-sm text-primary-dark text-center p-0.5 cursor-pointer"}
                onClick={() => {
                  const query = getQueryString({ ...filterProps, speak: [], page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
                  navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
                  setDropDownButton(dropDownButton !== dropDownButtonConst?.SPEAKS?.value)
                }}
              />
            }
          </div>
        }
      </div>
      {(dropDownButton === dropDownButtonConst?.SPEAKS?.value) &&
        <SelectSearchDropDown
          options={languageList?.languageList?.map((language) => ({
            label: language?.language,
            value: language?.language
          }))}
          selectedOption={filterProps?.speak?.length > 0 ? filterProps?.speak?.map((items) => ({
            label: items,
            value: items
          })) : []}
          isLoading={languageList?.isLoading}
          isShowList={dropDownButton === dropDownButtonConst?.SPEAKS?.value}
          setIsShowList={setDropDownButton}
          className={"top-72 mt-7 right-[30rem] w-fit md:w-72"}
          multipleOptions={true}
          isShowInputBox={true}
          isShowCheckBox={true}
          onApplyFilter={(option) => {
            const query = getQueryString({ ...filterProps, speak: option?.map((opt) => opt?.value)?.toString(), page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
            navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
          }}
          onClearFilter={() => {
            setDropDownButton(null)
            if (filterProps?.speak?.length > 0) {
              const query = getQueryString({ ...filterProps, speak: [], page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
              navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
            }
          }}
        />
      }

      {/* class type dropdown */}
      <div className={"relative"}>
        <div
          className={cn(
            "px-3 py-1.5 flex items-center justify-center gap-3 rounded-xl cursor-pointer bg-white border border-text-400 hover:border-text-900 hover:shadow-all",
            filterProps?.courseType?.length > 0 && "pl-3 pr-8 bg-primary-dark"
          )}
          onClick={() => onHandleDropdown(dropDownButtonConst?.COURSE_TYPE?.value)}
        >
          <span className={cn(
            "font-bodyPri font-normal text-text-900 text-base",
            filterProps?.courseType?.length > 0 && "text-text-50"
          )}
          >
            {"Class Type"}
          </span>
        </div>
        {filterProps?.courseType?.length > 0 &&
          <div
            className={"absolute top-2 right-2 z-20 w-5 h-5 flex items-center justify-start rounded-full bg-white"}
            onMouseOver={() => setIsCourseTypeHoverEnabled(true)}
            onMouseOut={() => setIsCourseTypeHoverEnabled(false)}
          >
            {!isCourseTypeHoverEnabled &&
              <span className={"w-full text-sm text-primary-dark text-center p-0.5"}>
                {filterProps?.courseType?.length}
              </span>
            }
            {isCourseTypeHoverEnabled &&
              <ImCross
                className={"w-full text-sm text-primary-dark text-center p-0.5 cursor-pointer"}
                onClick={() => {
                  setDropDownButton(null)
                  const query = getQueryString({ ...filterProps, courseType: [], page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
                  navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
                }}
              />
            }
          </div>
        }
      </div>
      {(dropDownButton === dropDownButtonConst?.COURSE_TYPE?.value) &&
        <CourseTypeDropdown
          options={props.filters.sessionType.options}
          selectedOption={filterProps?.courseType?.length > 0 ? filterProps?.courseType?.map((items) => ({
            label: items,
            value: items
          })) : []}
          isShowList={dropDownButton === dropDownButtonConst?.COURSE_TYPE?.value}
          setIsShowList={setDropDownButton}
          className={"top-72 mt-7 right-[22rem] w-fit md:w-80"}
          onApplyFilter={(options) => {
            setDropDownButton(null)
            const query = getQueryString({ ...filterProps, courseType: options?.map((option) => option?.value)?.toString(), page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
            navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
          }}
          onClearFilter={() => {
            setDropDownButton(null)
            if (filterProps?.courseType?.length > 0) {
              const query = getQueryString({ ...filterProps, courseType: [], page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
              navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
            }
          }}
        />
      }

      {/* student level dropdown */}
      <div className={"relative"}>
        <div
          className={cn(
            "px-3 py-1.5 flex items-center justify-center gap-3 rounded-xl cursor-pointer bg-white border border-text-400 hover:border-text-900 hover:shadow-all",
            filterProps?.proficiency?.length > 0 && "pl-3 pr-8 bg-primary-dark"
          )}
          onClick={() => onHandleDropdown(dropDownButtonConst?.STUDENT_LEVEL?.value)}
        >
          <span className={cn(
            "font-bodyPri font-normal text-text-900 text-base",
            filterProps?.proficiency?.length > 0 && "text-text-50"
          )}>
            {"Student Level"}
          </span>

          {/* <span className={""}>
                <BsChevronDown className={"text-text-800 text-base"} />
              </span> */}
        </div>
        {filterProps?.proficiency?.length > 0 &&
          <div
            className={"absolute top-2 right-2 z-20 w-5 h-5 flex items-center justify-start rounded-full bg-white"}
            onMouseOver={() => setIsStudentLevelHoverEnabled(true)}
            onMouseOut={() => setIsStudentLevelHoverEnabled(false)}
          >
            {!isStudentLevelHoverEnabled &&
              <span className={"w-full text-sm text-primary-dark text-center p-0.5"}>
                {filterProps?.proficiency?.length}
              </span>
            }
            {isStudentLevelHoverEnabled &&
              <ImCross
                className={"w-full text-sm text-primary-dark text-center p-0.5 cursor-pointer"}
                onClick={() => {
                  setDropDownButton(null)
                  const query = getQueryString({ ...filterProps, proficiency: [], page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
                  navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
                }}
              />
            }
          </div>
        }
      </div>
      {(dropDownButton === dropDownButtonConst?.STUDENT_LEVEL?.value) &&
        <StudentLevelDropDown
          options={profList?.profList?.map((proficiency) => ({
            label: proficiency?.proficiency,
            value: proficiency?.proficiency
          }))}
          selectedOption={filterProps?.proficiency?.length > 0 ? filterProps?.proficiency?.map((proficiency) => ({
            label: proficiency,
            value: proficiency
          })) : []}
          isLoading={profList?.isLoading}
          isShowList={dropDownButton === dropDownButtonConst?.STUDENT_LEVEL?.value}
          setIsShowList={setDropDownButton}
          className={"top-72 mt-7 right-64 w-fit md:w-72"}
          multipleOptions={true}
          isShowInputBox={true}
          isShowCheckBox={true}
          onApplyFilter={(option) => {
            setDropDownButton(null)
            const query = getQueryString({ ...filterProps, proficiency: option?.map((opt) => opt?.value)?.toString(), page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
            navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
          }}
          onClearFilter={() => {
            setDropDownButton(null)
            if (filterProps?.proficiency?.length > 0) {
              const query = getQueryString({ ...filterProps, proficiency: [], page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
              navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
            }
          }}
        />
      }

      {/* sort by dropdown */}
      {/* <div
        className={cn(
          "px-3 py-1.5 flex items-center justify-center gap-3 rounded-xl cursor-pointer bg-white border border-text-400 hover:border-text-900 hover:shadow-all",
          (filterProps?.sortBy !== courseSortBy?.RELEVANCE?.value) && "bg-primary-dark"
        )}
        onClick={() => onHandleDropdown(dropDownButtonConst?.SORT_BY?.value)}
      >
        <span className={cn(
          "font-bodyPri font-normal text-text-900 text-base",
          (filterProps?.sortBy !== courseSortBy?.RELEVANCE?.value) && "text-text-50"
        )}>
          {props?.filters?.sortBy?.label + ": " + (courseSortBy[filterProps?.sortBy?.toUpperCase()]?.label || props.filters.sortBy.options[0]?.label)}
        </span>
      </div>
      {(dropDownButton === dropDownButtonConst?.SORT_BY?.value) &&
        <SortByDropDown
          options={Object.values(courseSortBy)}
          selected={{ label: filterProps?.sortBy, value: filterProps?.sortBy } || courseSortBy?.RELEVANCE}
          isShowList={dropDownButton === dropDownButtonConst?.SORT_BY?.value}
          setIsShowList={setDropDownButton}
          isShowCheckIcon={true}
          className={"top-72 mt-7 right-28 w-fit md:w-72"}
          onApplyFilter={(option) => {
            setDropDownButton(null)
            const query = getQueryString({ ...filterProps, sortBy: option?.value, page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
            navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
          }}
          onClearFilter={() => {
            setDropDownButton(null)
            const query = getQueryString({ ...filterProps, sortBy: courseSortBy?.RELEVANCE?.value, page: DEFAULT_COURSE_PAGE, records: DEFAULT_COURSE_RECORDS })
            navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
          }}
        />
      } */}
    </div>
  );
};

export default Dropdowns;
