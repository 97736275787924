import { cn } from "utils/cn.utils";

import SkillTag from "components/skillTag/SkillTag";

import { useAppState } from "hooks/useStore";

export const SkillsSection = () => {
  const { coursePreviewDetail } = useAppState((state) => state.course)

  return (
    <div className={"p-5 bg-white space-y-3"}>
      <div className="w-full flex flex-col items-start justify-start md:flex-row md:items-center gap-1 md:gap-3 pb-1 border-b">
        <p className="text-xl font-medium font-bodyPri tracking-wide text-text-900 whitespace-nowrap">
          {"Skills you will gain"}
        </p>
        <p className="text-base text-text-700 font-normal whitespace-nowrap">
          ({"Benefits from this class"})
        </p>
      </div>

      <div className="flex flex-wrap py-5 space-x-3 space-y-2">
        {coursePreviewDetail?.data?.courseTags?.length > 0 &&
          <SkillTag
            skillList={coursePreviewDetail?.data?.courseTags?.map((skill) => ({
              label: skill?.masterTag?.tag,
              value: skill?.masterTag?.tag
            })) || []}
            isShowDeleteBtn={false}
            isShowInput={false}
          />
        }
        {!coursePreviewDetail?.data?.courseTags || (coursePreviewDetail?.data?.courseTags?.length === 0) &&
          <span className={"font-bodyPri font-normal text-text-800 text-base"}>
            {"No skill found!"}
          </span>
        }
      </div>
    </div>
  );
};
