import { useNavigate } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

import { updateLmsCourseDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

import { pagesInfo } from "utils/pagesInfo";

const PreviousNextButtons = ({ shouldUpdateDescription = false, body, previousBtn, nextBtn }) => {
    const { lmsCourseDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const onHandlePreviousBtn = () => {
        if (!previousBtn) return;

        if (shouldUpdateDescription) {
            dispatcher(updateLmsCourseDetail(lmsCourseDetail?.data?.id, body))
        }
        navigate(`${pagesInfo.EDIT_LMS_COURSE.pagePath}/${lmsCourseDetail?.data?.id}/edit/${previousBtn}`)
    }

    const onHandleNextBtn = () => {
        if (shouldUpdateDescription) {
            dispatcher(updateLmsCourseDetail(lmsCourseDetail?.data?.id, body))
        }
        navigate(`${pagesInfo.EDIT_LMS_COURSE.pagePath}/${lmsCourseDetail?.data?.id}/edit/${nextBtn}`)
    }

    return (
        <div className={"w-full flex items-center justify-end gap-5"}>
            <div
                className={cn(
                    "w-fit px-2 md:px-5 py-1 flex items-center justify-center gap-1 rounded-lg",
                    "font-bodyPri font-normal text-base md:text-lg lg:text-xl",
                    !previousBtn && "border border-text-400 text-text-400",
                    (previousBtn) && "text-primary-dark border border-primary-dark hover:bg-primary-dark hover:text-text-50 cursor-pointer group"
                )}
                title={"Previous"}
                onClick={onHandlePreviousBtn}
            >
                <ArrowBackIos className={"group-hover:text-text-50"} />
                <span className={""}>
                    {"Previous"}
                </span>
            </div>

            <div
                className={cn(
                    "w-fit px-2 md:px-5 py-1 flex items-center justify-center gap-1 rounded-lg",
                    "font-bodyPri font-normal text-base md:text-lg lg:text-xl",
                    "text-primary-dark border border-primary-dark hover:bg-primary-dark hover:text-text-50 cursor-pointer group"
                )}
                onClick={onHandleNextBtn}
            >
                <span className={""}>
                    {"Next"}
                </span>
                <ArrowForwardIos className={"group-hover:text-text-50"} />
            </div>
        </div>
    )
}

export default PreviousNextButtons;