export const url = {
    TUTOR_REVIEWS_LIST: "/reviews/tutor/list/{tutorId}",
    USER_REVIEW_LIST: "reviews/user-reviews/receiver-users/{userId}/public",
    USER_REVIEW_DETAIL: "/reviews/user-reviews"
};

export const reviewVisibility = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    HIDDEN: {
        label: "Hidden",
        value: "hidden"
    },
    BLOCK: {
        label: "Block",
        value: "block"
    }
}