import { motion } from "framer-motion";

import ButtonLoader from "components/loader/button-loader";

import { createUserCustomerPortalSession } from "redux/payment/payment.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";

import { cn } from "utils/cn.utils";

const HeroSection = () => {
    const { user } = useAppState((state) => state.user)
    const { createUserCustomerPortalSession: addUserCustomerPortalSession } = useAppState(s => s.payment)

    const dispatcher = useAppDispatcher()

    const onHandleManageSubscription = () => {
        if (addUserCustomerPortalSession?.isLoading) return;
        
        dispatcher(createUserCustomerPortalSession({ "userId": user?.user?.userId }))

    }

    return (
        <div className="px-5 pt-10 sm:pt-16 md:pt-24 pb-5 space-y-5 text-center">
            <div className={"flex justify-center md:justify-end"}>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ duration: 0.3 }}
                    className={cn(
                        'px-5 md:px-8 py-2 flex items-center justify-center bg-primary-dark text-text-50 rounded-xl',
                        'font-bodyPri font-normal text-sm sm:text-base hover:bg-secondary-dark ease-in-out duration-200',
                        addUserCustomerPortalSession?.isLoading && "cursor-wait"
                    )}
                    onClick={onHandleManageSubscription}
                >
                    {addUserCustomerPortalSession?.isLoading &&
                        <ButtonLoader isLoading={addUserCustomerPortalSession?.isLoading} className={"px-5 py-2"} />
                    }
                    {!addUserCustomerPortalSession?.isLoading &&
                        <span className={""}>
                            {"Manage Subscription"}
                        </span>
                    }
                </motion.button>
            </div>
            <div className="bg-gradient-to-r from-primary-main to-primary-dark text-transparent bg-clip-text relative">
                <h1 className={"font-heading font-semibold text-2xl md:text-3xl text-primary-dark text-center"}>
                    {"Unlock Premium Features: Subscribe to Go Pro"}
                </h1>
            </div>
        </div>
    );
};

export default HeroSection;

