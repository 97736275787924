import { memo, useState, useRef, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { FiEdit2 } from "react-icons/fi";
import { Comment } from 'react-loader-spinner';
import { cn } from 'utils/cn.utils';

const InputTextSwitch = memo(({
    onChange,
    value,
    placeholder = 'Enter title here ...',
    text,
    textStyle,
    autoFocus = false,
    isLoading = false,
    ...props
}) => {
    const [isShow, setIsShow] = useState(autoFocus);
    const inputRef = useRef();

    useEffect(() => {
        if (isShow) {
            inputRef?.current?.focus();
        }
    }, [isShow]);

    return (
        <div className={cn('w-full transition-transform ease-in-out duration-300 focus-within:border-b-primary-main rounded focus-within:rounded-none hover:shadow border-b border-b-transparent cursor-text group')}>
            {isShow ? (
                <div className='flex w-full h-full justify-between items-center focus-within:px-3 focus-within:py-1.5'>
                    <input
                        ref={inputRef}
                        className={cn('w-full h-full bg-transparent focus:outline-none outline-none', textStyle)}
                        type="text"
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        onBlur={() => setIsShow(false)}
                        autoFocus={isShow}
                        {...props}
                    />
                    <Comment
                        visible={isShow}
                        height="20"
                        width="20"
                        ariaLabel="comment-loading"
                        wrapperClass="comment-wrapper"
                        color="#fff"
                        backgroundColor="#2196f3"
                    />
                </div>
            ) : (
                <div onClick={() => setIsShow(true)} className={cn('flex items-center justify-between w-full h-full px-3 py-1.5')} >
                    <div className={cn('w-full h-full', textStyle)}>{text || placeholder}</div>
                    <FiEdit2 className='hidden group-hover:block group-hover:text-text-500' />
                    {isLoading &&
                        <FaSpinner className={"animate-spin text-lg text-primary-dark"} />
                    }
                </div>
            )}
        </div>
    );
});

export default InputTextSwitch;
