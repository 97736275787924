import actionTypes from 'redux/pageInfo/pageInfo.action-type';
import initialState from 'redux/pageInfo/pageInfo.initialState'

const pageNameReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.SET_CURRENT_PAGE_INFO:
            return {
                ...state,
                currentPageInfo: payload
            };

        case actionTypes.SET_PREVIOUS_PAGE_INFO:
            return {
                ...state,
                previousPageInfo: payload
            };

        default:
            return { ...state };
    }
};

export default pageNameReducer;