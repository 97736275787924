import { useEffect, useRef, useCallback, memo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
    StyledJitsiMeetComponent,
    StyledCloseButton,
    StyledJitsiRenderSpinner
} from "pages/auth/meetingRoom/MeetingRoomPage.style";
import MeetingRoomMessage from 'pages/auth/meetingRoom/MeetingRoomMessage';

import FullPageLoader from 'components/loader/FullPageLoader';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { joinMeetingByCode } from 'redux/meeting/meeting.request';
import { setClearMeeting } from 'redux/meeting/meeting.slice';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { pagesInfo } from 'utils/pagesInfo';

import { useTitle } from 'hooks/useTitle';


const MeetingComponent = memo(({ joinMeetingData }) => {

    const navigate = useNavigate()
    const iFrameRef = useRef()

    const initIframeAPI = useCallback(() => {
        const domain = '8x8.vc';
        const options = {
            roomName: joinMeetingData?.roomName,
            jwt: joinMeetingData?.jaasJwtToken,
            configOverwrite: {
                brandingRoomAlias: joinMeetingData?.code,
                disableThirdPartyRequests: true,
                disableLocalVideoFlip: false,
                backgroundAlpha: 0.5,
                readOnlyName: true
            },
            interfaceConfigOverwrite: {
                VIDEO_LAYOUT_FIT: "nocrop",
                MOBILE_APP_PROMO: false,
                TILE_VIEW_MAX_COLUMNS: 4,
            },
            parentNode: iFrameRef.current
        };
        const iFrameApi = new window.JitsiMeetExternalAPI(domain, options);

        iFrameApi.addListener("videoConferenceLeft", () => {
            navigate(pagesInfo.MEETING.pagePath, { replace: true })
        });

        return () => {
            iFrameApi.removeListener("videoConferenceLeft", () => {
                navigate(pagesInfo.MEETING.pagePath, { replace: true })
            });
        }
    }, [joinMeetingData, navigate])

    useEffect(() => {
        if (joinMeetingData && window.JitsiMeetExternalAPI) {
            initIframeAPI();
        }
    }, [initIframeAPI, joinMeetingData]);

    return (
        <div className={"w-screen h-screen"} ref={iFrameRef} />
    )
})

const MeetingRoomPage = () => {
    const { currentPageInfo } = useAppState((s) => s.pageInfo)
    const { joinMeeting } = useAppState((s) => s.meeting)

    const dispatcher = useAppDispatcher()
    const params = useParams()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.MEETING_ROOM))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Edulyte Live Session"
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (params.code) {
            dispatcher(joinMeetingByCode(params?.code))
        }

        return () => {
            dispatcher(setClearMeeting())
        }
    }, [dispatcher, params.code])

    return (
        <StyledJitsiMeetComponent>
            {joinMeeting?.isLoading && <FullPageLoader isLoading={joinMeeting?.isLoading} />}
            {(!!joinMeeting?.data && !joinMeeting?.isLoading) && <MeetingComponent joinMeetingData={joinMeeting?.data} />}
            {(!!joinMeeting?.errorMsg && !joinMeeting?.isLoading) && <MeetingRoomMessage errorMsg={joinMeeting?.errorMsg} />}
        </StyledJitsiMeetComponent>
    )
}

export default MeetingRoomPage