import React, { useEffect, useState } from 'react'
import { cn } from "utils/cn.utils";

export type TabElement = {
    label: string,
    value: string
}

const Tabs = ({
    tabs,
    handleTabs,
    startIndex = 0,
    lastIndex = tabs.length,
}: {
    startIndex?: number
    lastIndex?: number
    tabs: TabElement[],
    handleTabs: (activeTab: TabElement) => void
}
) => {
    const [activeTab, setActiveTab] = useState(tabs[0])

    useEffect(() => {
        handleTabs(activeTab)
    }, [activeTab])

    return (
        <div className="p-4 bg-white rounded-lg">
            <div className="flex flex-col sm:flex-row items-center justify-between gap-3">
                <div className="rounded-md ring-2 ring-primary-main divide-x-2 divide-primary-main overflow-hidden ">
                    {tabs.slice(startIndex, lastIndex).map((tab, idx) => (
                        <button
                            key={idx}
                            className={cn(
                                "py-2 w-32 font-buttons font-normal text-base",
                                activeTab.value === tab.value
                                    ? "bg-primary-main text-white"
                                    : "text-primary-main hover:bg-primary-light"
                            )}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab.label}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Tabs;