import tw from "twin.macro";

export const StyledSearch = tw.input`
rounded-md 
inline-block
py-3
width[90%]
border
border-gray-200
pl-4
bg-gray-100
outline-none
mt-3
ml-5
mr-5
 `;
