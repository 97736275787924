import Dayjs from "dayjs";
import { GiftCardPlan, TGiftCard } from "./types";

export const GIFT_CARD_IMAGES = [
  "https://images.unsplash.com/photo-1478145787956-f6f12c59624d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1080&q=80",
  "https://images.unsplash.com/photo-1497414146483-5bcafdccfdff?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
  "https://images.unsplash.com/photo-1482017276394-d2ddc6d4c978?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1080&q=80",
];

export const MOCK_GIFT_CARD_PLANS: GiftCardPlan[] = [
  {
    id: "1",
    name: "$50 Gift Card",
    price: 50,
  },
  {
    id: "2",
    name: "$100 Gift Card",
    price: 100,
  },
  {
    id: "3",
    name: "$200 Gift Card",
    price: 200,
  },
  {
    id: "4",
    name: "$300 Gift Card",
    price: 300,
  },
  {
    id: "Any",
    name: "Any",
    price: 10,
  },
];

export const INITIAL_DATA: TGiftCard = {
  activeStep: "step1",
  selectedImageIdx: 0,
  cardSelection: {
    isCompleted: false,
    selectedCard: null,
  },
  recipentSelection: {
    isCompleted: false,
    selectedRecipent: {
      type: "yourself",
      message: "",
      name: "",
      quantity: 1,
      recipientEmail: "",
      recipientName: "",
      isPrint: false,
      deliveryType: "Instantly",
      selectedDate: Dayjs().format("DD/MM/YYYY"),
      selectedTime: {
        label: "Morning",
        value: "08:00 AM",
      },
    },
  },
  orderSummary: {
    isCompleted: false,
    paymentEmail: "",
    paymentPhone: "",
  },
  paymentSummary: {
    isPaymentSuccess: false,
    isCompleted: false,
    paymentId: null,
    orderId: null,
    otherdetails: "",
  },
  _meta: {
    isLoading: false,
    showPaymentModal: false,
  },
};
