import { Link } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import RatingStars from 'components/ratingStars/RatingStars';
import { useAppState } from "hooks/useStore";

import { pagesInfo } from 'utils/pagesInfo';


export const AuthorProfileSection = () => {
  const { offeringCourse } = useAppState((s) => s.offering)
  const AVG_RATING = 2

  return (
    <div className="w-full flex items-center justify-start gap-3">
      <div className='relative w-20 h-20'>
        <Link to={`${pagesInfo?.TUTOR?.pagePath}/${offeringCourse?.offeringCourse?.tutor?.userId}`}>
          <img src={offeringCourse?.offeringCourse?.tutor?.profilePicUrl} alt={"tutor-public-profile"} className="w-full h-full object-cover rounded-full" />
        </Link>
        <div className={"relative has-tooltip"}>
          <div className={'absolute bottom-0 right-0 w-6 h-6 rounded-full overflow-hidden border-2 border-white'}>
            <img
              src={`https://flagcdn.com/16x12/${offeringCourse?.offeringCourse?.tutor?.fromCountry?.countryDomain}.png`.toLowerCase()}
              alt={"country-flag"}
              className='w-full h-full object-cover'
            />
          </div>
          <span
            className={cn(
              "w-16 px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -top-0 -right-0",
              "flex flex-col items-center justify-start overflow-hidden"
            )}
          >
            {offeringCourse?.offeringCourse?.tutor?.fromCountry?.country}
          </span>
        </div>
      </div>
      <div className="text-white flex flex-col items-start justify-center">
        <div className='flex items-center gap-2'>
          <span className="text-lg font-bodyPri capitalize">
            {offeringCourse?.offeringCourse?.tutor?.firstName + " " + offeringCourse?.offeringCourse?.tutor?.lastName.charAt(0) + "."}
          </span>
          {offeringCourse?.offeringCourse?.tutor?.onlineStatus === "online" && <span className={"w-2 h-2 rounded-full bg-green-600"}></span>}
        </div>
        <div className='flex items-center'>
          {offeringCourse?.offeringCourse?.tutor?.tutor?.rating?.avgRating > AVG_RATING
            ? <>
              <RatingStars rating={offeringCourse?.offeringCourse?.tutor?.tutor?.rating?.avgRating} fullRating={5} />
              <span className="font-bold">
                {offeringCourse?.offeringCourse?.tutor?.tutor?.rating?.avgRating}({offeringCourse?.offeringCourse?.tutor?.tutor?.ratingCount})
              </span>
            </>
            : <div className='text-sm font-bodyPri tracking-wide text-complementry-main'>{"New"}</div>
          }
        </div>
      </div>
    </div>
  );
};