import React from "react";

//Icon imports
import { PaperClipIcon } from "@heroicons/react/outline";
import { RefreshIcon } from "@heroicons/react/outline";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

//Style imports
import {
  StyledChatViewSendMessageAttachInput,
  StyledChatViewSendMessageAttachLabel,
} from "./ChatViewSendMessage.styles";

export default function ChatViewSendMessageAttach({
  uploadFile,
  fileUploading,
  fileUploadError,
}) {
  return (
      <StyledChatViewSendMessageAttachLabel title={"Attach here..."}>
        <StyledChatViewSendMessageAttachInput
          type="file"
          onChange={(e) => uploadFile(e)}
        />
        {fileUploading ? (
          <RefreshIcon className="h-6 w-6 text-gray-500 animate-spin" />
        ) : fileUploadError ? (
          <ExclamationCircleIcon className="h-6 w-6 text-secondary-dark hover:text-secondary-dark hover:rounded-full hover:bg-secondary-light hover:ring-4 hover:ring-secondary-light" />
        ) : (
          <PaperClipIcon className="h-6 w-6 text-gray-500 hover:text-secondary-dark hover:rounded-full hover:bg-secondary-light hover:ring-4 hover:ring-secondary-light" />
        )}
      </StyledChatViewSendMessageAttachLabel>
  );
}
