import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "./lmsResource.const";

class LmsResourceService {
    getLmsResourceList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            url.GET_LMS_RESOURCE_LIST,
            { params: query }
        )

        return response;
    }

    createLmsResourceDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.CREATE_LMS_RESOURCE_DETAIL,
            body
        )

        return response;
    }
}

export default new LmsResourceService();