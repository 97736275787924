import {
    FaYoutubeSquare,
    FaQuora,
} from 'react-icons/fa';

import {
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    PinterestIcon,
    WhatsappIcon,
    TelegramIcon,
} from "react-share";

// import { ImFacebook2 } from "react-icons/im";

export const footerData = [
    {
        linksTitle: 'FOR LEARNERS',
        linksItems: [
            {
                name: 'Find a Tutor',
                path: '/find-tutors',
                pathType: 'to',
            },
            {
                name: 'Group Class',
                path: '/find-classes',
                pathType: 'to',
            },
            {
                name: 'Sign in',
                path: '/log-in',
                pathType: 'to',
            },
            {
                name: 'Learner Blog',
                path: 'https://www.edulyte.com/blog',
                pathType: 'href',
            },
            {
                name: 'Refer & Earn',
                path: '/marketing/referral',
                pathType: 'to',
            },
            {
                name: 'Frequently Asked Questions',
                path: 'https://support.edulyte.com/portal/en/kb/student-helpdesk',
                pathType: 'href',
            },
            {
                name: 'Subscribe Now!',
                path: '/',
                pathType: 'onlyText',
            },
        ]
    },
    {
        linksTitle: 'FOR TUTORS',
        linksItems: [
            {
                name: 'How it works',
                path: 'https://www.edulyte.com/teaching-online-tools/',
                pathType: 'href'
            },
            {
                name: 'Apply to teach',
                path: '/teach-with-us',
                pathType: 'to',
            },
            // {
            //     name: 'Teach English Online',
            //     path: '#',
            //     pathType: 'onlyText',
            // },
            // {
            //     name: 'Online Tutoring Jobs',
            //     path: '#',
            //     pathType: 'onlyText',
            // },
            // {
            //     name: 'Tutor Resources',
            //     path: '#',
            //     pathType: 'onlyText',
            // },
            {
                name: 'Global Tutor Network',
                path: 'https://www.edulyte.com/teacher-group/',
                pathType: 'href',
            },
            {
                name: 'Frequently Asked Questions',
                path: 'https://support.edulyte.com/portal/en/kb/tutor-helpdesk',
                pathType: 'href',
            },
            {
                name: 'Subscribe Now!',
                path: '#',
                pathType: 'onlyText',
            },
        ]
    },
    {
        linksTitle: 'COMPANY',
        linksItems: [
            {
                name: 'About Edulyte',
                path: 'https://www.edulyte.com/about/',
                pathType: 'href',
            },
            // {
            //     name: 'How it Works?',
            //     path: '/',
            //     pathType: 'href',
            // },
            {
                name: 'Work at Edulyte',
                path: 'https://careers.edulyte.com/jobs/Careers',
                pathType: 'href',
            },
            {
                name: 'Share Feedback',
                path: 'https://wp.edulyte.com/app-feedback/',
                pathType: 'href',
            },
            {
                name: 'Contact Us',
                path: 'https://www.edulyte.com/contact-us/',
                pathType: 'href',
            },
        ]
    },
    {
        linksTitle: 'QUICK LINKS',
        linksItems: [
            // {
            //     name: 'Online Tutoring',
            //     path: '#',
            //     pathType: 'onlyText',
            // },
            {
                name: 'All Categories',
                path: '/skills',
                pathType: 'to',
            },
            {
                name: 'Blog',
                path: 'https://www.edulyte.com/blog/',
                pathType: 'href',
            },
        ]
    },
]

export const contactAndSocialData = [
    {
        linksTitle: 'SUPPORT',
        linksItems: [
            {
                name: 'Help Pages',
                path: 'https://support.edulyte.com/portal/en/kb',
                pathType: 'href',
            },
            // {
            //     name: 'Get back to me',
            //     path: '#',
            //     pathType: 'onlyText',
            // },
            {
                name: 'Submit a request',
                path: 'https://support.edulyte.com/portal/en/newticket',
                pathType: 'href',
            },
        ]
    },
    {
        linksTitle: "CONTACT US",
        linksItems: [
            {
                name: "Australia",
                path: "",
                pathType: ""
            },
            {
                name: "9 Light Square, Adelaide SA 5000",
                path: "",
                pathType: "onlyText"
            },
            {
                name: "support@edulyte.com",
                path: "",
                pathType: "onlyText"
            },
        ]
    }
]

export const socialIconsData = [
    {
        name: 'FacebookF',
        icon: FacebookIcon,
        path: 'https://www.facebook.com/EdulyteMarketplace',
        buttonName: "FacebookShareButton",
        color: "primary-main"
    },
    {
        name: 'LinkedinIn',
        icon: LinkedinIcon,
        buttonName: "FacebookShareButton",
        path: 'https://www.linkedin.com/company/edulyte/',
        color: "primary-main"
    },
    {
        name: 'Twitter',
        icon: TwitterIcon,
        buttonName: "FacebookShareButton",
        path: 'https://twitter.com/edulyte',
        color: "primary-main"
    },
    {
        name: 'Youtube',
        icon: FaYoutubeSquare,
        buttonName: "FacebookShareButton",
        path: 'https://www.youtube.com/channel/UC0PXqH2UhwqGVz7NBKLQkVA',
        color: ""
    },
    {
        name: "Quora",
        icon: FaQuora,
        buttonName: "FacebookShareButton",
        path: "https://edulyte.quora.com/",
        color: "red-500"
    },
    {
        name: 'Pinterest',
        icon: PinterestIcon,
        buttonName: "FacebookShareButton",
        path: 'https://www.pinterest.com/edulyte/',
        color: "red-500"
    },
    {
        name: 'Whatsapp',
        icon: WhatsappIcon,
        buttonName: "WhatsappShareButton",
        path: 'https://api.whatsapp.com/message/MVMIAUQBIAFHD1?autoload=1&app_absent=0',
        color: "green-500"
    },
    {
        name: 'Twitter',
        icon: TelegramIcon,
        buttonName: "TwitterShareButton",
        path: 'https://t.me/Edulyte_bot',
        color: "red-500"
    },
    // {
    //     name: 'Github',
    //     icon: GithubIcon,
    //     buttonName: "FacebookShareButton",
    //     path: 'https://www.facebook.com/EdulyteMarketplace',
    //     color: "secondary-main"
    // },
    // {
    //     name: 'Tumblr',
    //     icon: TumblrIcon,
    //     buttonName: "FacebookShareButton",
    //     path: 'https://edulyte.tumblr.com/',
    //     color: "secondary-main"
    // },
    // {
    //     name: 'Instagram',
    //     icon: InstapaperIcon,
    //     buttonName: "FacebookShareButton",
    //     path: 'https://www.instagram.com/edulyte2020',
    //     color: "secondary-main"
    // },
]

export const coursesAndTutorsData = [
    // {
    //     linksTitle: "LEARN & SUCCEED",
    //     linksItems: [
    //         {
    //             name: "English as a Second Language",
    //             path: "#",
    //             pathType: "onlyText"
    //         },
    //         {
    //             name: "International English Language Tests",
    //             path: "#",
    //             pathType: "onlyText"
    //         },
    //         {
    //             name: "Study in Australia",
    //             path: "https://www.edulyte.com/study-abroad/study-in-australia/",
    //             pathType: "href"
    //         },
    //     ]
    // },
    {
        linksTitle: "GROUP CLASSES",
        linksItems: [
            {
                name: "English For Beginners",
                path: "#",
                pathType: "onlyText"
            },
            {
                name: "PTE Exam Preparation",
                path: "#",
                pathType: "onlyText"
            },
            {
                name: "IELTS Exam Preparation",
                path: "#",
                pathType: "onlyText"
            },
            {
                name: "Online Maths Tutoring",
                path: "#",
                pathType: "onlyText"
            },
            {
                name: "All Group Lessons",
                path: "#",
                pathType: "onlyText"
            },
        ]
    },
    {
        linksTitle: "1-ON-1 TUTORS",
        linksItems: [
            {
                name: "English Tutors",
                path: "#",
                pathType: "onlyText"
            },
            {
                name: "Maths Tutors",
                path: "#",
                pathType: "onlyText"
            },
            {
                name: "High School Tutors",
                path: "#",
                pathType: "onlyText"
            },
            {
                name: "IELTS Tutors",
                path: "#",
                pathType: "onlyText"
            },
            {
                name: "All other Tutors",
                path: "#",
                pathType: "onlyText"
            },
        ]
    },
]

export const learnersResourcesData = [
    {
        linksTitle: "STUDY TIPS",
        linksItems: [
            {
                name: "Motivational Study Quotes",
                path: "https://www.edulyte.com/motivational-study-quotes/",
                pathType: "href"
            },
            {
                name: "How to take notes?",
                path: "https://www.edulyte.com/blog/how-to-take-study-notes/",
                pathType: "href"
            },
            {
                name: "Study Planners",
                path: "https://www.edulyte.com/blog/study-planners/",
                pathType: "href"
            },
            {
                name: "Your guide to online Lessons",
                path: "https://www.edulyte.com/blog/top-study-tips-for-exam-preparation/",
                pathType: "href"
            },
            {
                name: "Tips for Exam Prep",
                path: "https://www.edulyte.com/blog/top-study-tips-for-exam-preparation/",
                pathType: "href"
            },
            {
                name: "Study Hacks for Better Grades",
                path: "https://www.edulyte.com/blog/study-hacks-for-better-grades/",
                pathType: "href"
            },
            {
                name: "More Study Tips!",
                path: "https://www.edulyte.com/topics/education/study-skills/",
                pathType: "href"
            }
        ]
    },
    {
        linksTitle: "ENGLISH",
        linksItems: [
            {
                name: "Greetings in English Language",
                path: "https://www.edulyte.com/blog/greetings-in-english-language/",
                pathType: "href"
            },
            // {
            //     name: "Persuasive Language Techniques",
            //     path: "",
            //     pathType: "onlyText"
            // },
            {
                name: "Slangs in Australia",
                path: "https://www.edulyte.com/blog/slangs-in-australia/",
                pathType: "href"
            },
            {
                name: "IELTS Topic for Speaking",
                path: "https://www.edulyte.com/blog/ielts-topic-for-speaking/",
                pathType: "href"
            },
            {
                name: "Topics for Debates",
                path: "https://www.edulyte.com/blog/topics-for-debates/",
                pathType: "href"
            },
            // {
            //     name: "PTE Read Aloud",
            //     path: "",
            //     pathType: "onlyText"
            // },
            {
                name: "More on English!",
                path: "https://www.edulyte.com/topics/esl/",
                pathType: "href"
            },
        ]
    },
    {
        linksTitle: "MATHS",
        linksItems: [
            // {
            //     name: "Maths for Everyone",
            //     path: "",
            //     pathType: ""
            // },
            // {
            //     name: "Maths Problem Solving",
            //     path: "",
            //     pathType: ""
            // },
            {
                name: "Bodmas Rule",
                path: "https://www.edulyte.com/blog/bodmas-rule/",
                pathType: "href"
            },
            // {
            //     name: "Times Tables",
            //     path: "https://www.edulyte.com/blog/online-timers-for-classrooms/",
            //     pathType: "href"
            // },
            // {
            //     name: "Calculating Compound Interest",
            //     path: "",
            //     pathType: ""
            // },
            // {
            //     name: "Names of The Shapes",
            //     path: "",
            //     pathType: ""
            // },
            {
                name: "Application of Mathematics",
                path: "https://www.edulyte.com/blog/application-of-mathematics-in-daily-life/",
                pathType: "href"
            },
            {
                name: "Famous Mathematicians",
                path: "https://www.edulyte.com/blog/famous-mathematicians/",
                pathType: "href"
            },
            {
                name: "More on Maths!",
                path: "https://www.edulyte.com/topics/mathematics/",
                pathType: "href"
            },
        ]
    },
    {
        linksTitle: "OTHERS",
        linksItems: [
            {
                name: "History Websites",
                path: "https://www.edulyte.com/top-history-websites-for-the-historian-in-you/",
                pathType: "href"
            },
            {
                name: "Golden Rules of Accounting",
                path: "https://www.edulyte.com/blog/what-are-the-golden-rules-of-accounting/",
                pathType: "href"
            },
            // {
            //     name: "The Order of Planets",
            //     path: "",
            //     pathType: "onlyText"
            // },
            // {
            //     name: "Excel Pivot Table",
            //     path: "",
            //     pathType: "onlyText"
            // },
            // {
            //     name: "Excel Conditional Formatting",
            //     path: "",
            //     pathType: "onlyText"
            // },
            // {
            //     name: "Excel VLookup",
            //     path: "",
            //     pathType: "onlyText"
            // },
            {
                name: "See More!",
                path: "https://www.edulyte.com/blog/",
                pathType: "href"
            },
        ]
    }
]