import { cn } from "utils/cn.utils";

import { FaInfoCircle } from "react-icons/fa";
import { userSocialMediaLinksConst } from 'components/modals/personalDetailModal/data';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setOnboardingUserDetail } from 'redux/onboarding/onboarding.slice';

const PersonalSocialMediaPresence = ({ isFormDisabled }) => {
    const { onboardingUserDetail } = useAppState((s) => s.onboarding)

    const dispatcher = useAppDispatcher()

    return (
        <div className={"flex flex-col items-start justify-start gap-5 px-2 py-1 pb-5"}>
            <span className='font-bodyPri font-medium text-md text-text-900 tracking-wide'>
                {"My Social Media Presence:"}
            </span>

            <div className={"grid grid-cols-12 gap-5 w-full"}>
                {Object.values(userSocialMediaLinksConst)?.map((socialMediaLink, index) => (
                    <div className={"col-start-0 col-span-full md:col-start-0 md:col-span-6"} key={index}>
                        <div className={"flex items-center justify-start gap-3"}>
                            <span className={"font-bodyPri font-normal text-text-900 text-sm"}>
                                {socialMediaLink.label}
                            </span>
                            <div className={"relative has-tooltip"}>
                                <FaInfoCircle className="text-sm text-text-400" />
                                <span
                                    className={cn(
                                        "px-2 py-0.5 text-xs rounded shadow-lg bg-text-300 text-text-800 tooltip -top-5",
                                        "flex items-center justify-start overflow-hidden"
                                    )}
                                >
                                    {socialMediaLink?.demoKey1}
                                </span>
                            </div>
                        </div>
                        <input
                            type={"text"}
                            placeholder={socialMediaLink.placeholder}
                            className={cn(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            value={onboardingUserDetail?.onboardingUserDetail?.[socialMediaLink?.profileLinkType]}
                            onChange={(event) => dispatcher(setOnboardingUserDetail({
                                ...onboardingUserDetail?.onboardingUserDetail,
                                [socialMediaLink?.profileLinkType]: event?.target?.value
                            }))}
                            disabled={isFormDisabled}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PersonalSocialMediaPresence;