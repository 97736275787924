import PersonalInformation from "pages/auth/profile/personalInformation";
import AboutInformation from "pages/auth/profile/aboutInformation";
import VideoSelector from "pages/auth/profile/commonComponents/VideoSelector";
import SkillsInformation from "pages/auth/profile/skillsInformation";
import LinkInBio from "pages/auth/profile/linkInBio";
import AppointmentDetail from "pages/auth/profile/appointmentDetail";

import { userRoles } from "redux/auth/auth.const";

export const pageHeading = {
    heading: "My Profile",
    subHeading: "View and update your professional profile!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/profile.svg"
}

export const genderOptions = {
    MALE: {
        label: "Male",
        value: "male"
    },
    FEMALE: {
        label: "Female",
        value: "female"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}

export const profileEditBtn = {
    profilePicUrl: {
        value: 'profilePicUrl',
    },
    firstName: {
        value: 'firstName',
    },
    lastName: {
        value: 'lastName',
    },
    gender: {
        value: "gender",
    },
    dateOfBirth: {
        value: "dateOfBirth",
    },
    educations: {
        value: 'educations',
    },
    videoUrl: {
        value: 'videoUrl',
    },
    elevatorPitch: {
        value: 'elevatorPitch',
    },
    bio: {
        value: 'bio',
    },
    learningStyleBio: {
        value: "learningStyleBio",
    },
    teachingStyleBio: {
        value: 'teachingStyleBio',
    },
    skills: {
        value: 'skills',
    },
    languages: {
        value: 'languages',
    },
    from: {
        value: 'from'
    },
    livingIn: {
        value: 'livingIn'
    },
    appointmentDetail: {
        value: "appointmentDetail"
    },
    subject: {
        value: "subject"
    }
}

export const sortCountryList = (item1, item2, defaultId) => {
    if (item1 === defaultId) return -1;
    if (item2 === defaultId) return 1;
    return 0;
}

export const getProfileCountDetail = (payload) => {
    
    let count = 0;
    if (payload?.profilePicUrl) {
        count++
    }
    if (payload?.firstName) {
        count++
    }
    if (payload?.lastName) {
        count++
    }
    if (payload?.isEducationListEnabled) {
        count++
    }
    if (payload?.gender) {
        count++
    }
    if (payload?.dateOfBirth) {
        count++
    }
    if (payload?.fromCountry) {
        count++
    }
    if (payload?.liveInCountry) {
        count++
    }

    if (payload?.isStudentRoleEnabled && payload?.isStudentLanguageListEnabled) {
        count++
    }
    if (payload?.isStudentRoleEnabled && payload?.elevatorPitch) {
        count++
    }
    if (payload?.isStudentRoleEnabled && payload?.learningStyleBio) {
        count++
    }
    if (payload?.isStudentRoleEnabled && payload?.bio) {
        count++
    }
    if (payload?.isStudentRoleEnabled && payload?.isValidStudentSkills) {
        count++
    }

    if (payload?.isTutorRoleEnabled && payload?.videoUrl) {
        count++
    }
    if (payload?.isTutorRoleEnabled && payload?.isTutorLanguageListEnabled) {
        count++
    }
    if (payload?.isTutorRoleEnabled && payload?.elevatorPitch) {
        count++
    }
    if (payload?.isTutorRoleEnabled && payload?.bio) {
        count++
    }
    if (payload?.isTutorRoleEnabled && payload?.teachingStyleBio) {
        count++
    }
    if (payload?.isTutorRoleEnabled && payload?.isValidTutorSkills) {
        count++
    }
    if ((payload?.isTutorRoleEnabled) && payload?.appointment) {
        count++
    }
}

export const profileTabConst = {
    PERSONAL_DETAILS: {
        value: "personal_details",
        label: 'Personal Details',
        pagePath: "/personal-details",
        routePath: "personal-details",
        component: PersonalInformation,
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
    ABOUT: {
        value: "about",
        label: 'About Me',
        pagePath: "/about",
        routePath: "about",
        component: AboutInformation,
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
    INTRO_VIDEO: {
        value: "intro_video",
        label: 'Intro Video',
        pagePath: "/intro-video",
        routePath: "intro-video",
        component: VideoSelector,
        roles: [userRoles.TUTOR.value],
    },
    SKILLS: {
        value: "skills",
        label: "Skills & Category",
        pagePath: "/skills",
        routePath: "skills",
        component: SkillsInformation,
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
    LINK_IN_BIO: {
        value: "link_in_bio",
        label: 'Link In Bio',
        pagePath: "/link-in-bio",
        routePath: "link-in-bio",
        component: LinkInBio,
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
    APPOINTMENT: {
        value: "appointment",
        label: 'Appointment',
        pagePath: "/appointment",
        routePath: "appointment",
        component: AppointmentDetail,
        roles: [userRoles.TUTOR.value],
    },
}

export const getCurrentActiveRoute = () => {
    const path = window.location.pathname

    const pathParts = path.split('/').filter(part => part !== '')
    
    const lastItem = pathParts[pathParts.length - 1]
    const profileRouteArray = Object.values(profileTabConst)?.map((tabItem) => tabItem?.routePath)

    if (profileRouteArray?.includes(lastItem)) {
        const item = profileTabConst[lastItem?.replaceAll("-", "_")?.toUpperCase()]?.value
        return item;
    } else return profileTabConst.PERSONAL_DETAILS.value
}

export const isProfileRoutesAllowed = () => {
    const path = window.location.pathname

    const validRoutes = Object.values(profileTabConst).map((tabItem) => tabItem?.routePath)

    const regex = new RegExp(`^/settings/profile/(${validRoutes.join("|")})$`);
    const match = path.match(regex)

    return match !== null
}