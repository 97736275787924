import { useEffect, useState } from 'react';

import { cn } from "utils/cn.utils";

import { BsCheckLg } from "react-icons/bs";
import { ImCross } from "react-icons/im";

import { validatePassword } from 'utils/validation-functions';

const initialStatePasswordCharValid = {
    isRequiredCharValid: false,
    isUppercaseCharValid: false,
    isLowercaseCharValid: false,
    isDigitCharValid: false,
    isNoSpaceCharValid: false
}

const PasswordValidator = ({ inputPassword, setIsNewPasswordValid }) => {
    const [passwordCharValid, setPasswordCharValid] = useState(initialStatePasswordCharValid)

    useEffect(() => {
        const validate = validatePassword(inputPassword)
        setPasswordCharValid(validate)

        setIsNewPasswordValid(validate?.isPasswordValid)

    }, [inputPassword])

    return (
        <div className={"flex flex-col items-start gap-1"}>
            <div className={"font-bodyPri font-medium text-sm text-text-800"}>
                {"Password format:"}
            </div>
            <ul className={cn(
                "px-1 flex flex-col items-start gap-1 list-inside list-disc",
                "font-bodyPri font-normal text-sm text-text-700"
            )}>
                <li className={"flex items-center gap-2"}>
                    <span className={cn(
                        inputPassword && !passwordCharValid?.isRequiredCharValid && "!text-red-500",
                        inputPassword && passwordCharValid?.isRequiredCharValid && "!text-green-500"
                    )}>
                        {"8-15 characters"}
                    </span>
                    {inputPassword && !passwordCharValid?.isRequiredCharValid && <ImCross className={"!text-red-500 text-xs"} />}
                    {inputPassword && passwordCharValid?.isRequiredCharValid && <BsCheckLg className={"!text-green-500 text-xs"} />}
                </li>
                <li className={"flex items-center gap-2"}>
                    <span className={cn(
                        inputPassword && !passwordCharValid?.isUppercaseCharValid && "!text-red-500",
                        inputPassword && passwordCharValid?.isUppercaseCharValid && "!text-green-500"
                    )}>
                        {"At least one capital letter"}
                    </span>
                    {inputPassword && !passwordCharValid?.isUppercaseCharValid && <ImCross className={"!text-red-500 text-xs"} />}
                    {inputPassword && passwordCharValid?.isUppercaseCharValid && <BsCheckLg className={"!text-green-500 text-xs"} />}
                </li>
                <li className={"flex items-center gap-2"}>
                    <span className={cn(
                        inputPassword && !passwordCharValid?.isLowercaseCharValid && "!text-red-500",
                        inputPassword && passwordCharValid?.isLowercaseCharValid && "!text-green-500"
                    )}>
                        {"At least one small letter"}
                    </span>
                    {inputPassword && !passwordCharValid?.isLowercaseCharValid && <ImCross className={"!text-red-500 text-xs"} />}
                    {inputPassword && passwordCharValid?.isLowercaseCharValid && <BsCheckLg className={"!text-green-500 text-xs"} />}
                </li>
                <li className={"flex items-center gap-2"}>
                    <span className={cn(
                        inputPassword && !passwordCharValid?.isDigitCharValid && "!text-red-500",
                        inputPassword && passwordCharValid?.isDigitCharValid && "!text-green-500"
                    )}>
                        {"At least one number"}
                    </span>
                    {inputPassword && !passwordCharValid?.isDigitCharValid && <ImCross className={"!text-red-500 text-xs"} />}
                    {inputPassword && passwordCharValid?.isDigitCharValid && <BsCheckLg className={"!text-green-500 text-xs"} />}
                </li>
                <li className={"flex items-center gap-2"}>
                    <span className={cn(
                        inputPassword && !passwordCharValid?.isNoSpaceCharValid && "!text-red-500",
                        inputPassword && passwordCharValid?.isNoSpaceCharValid && "!text-green-500"
                    )}>
                        {"No spaces"}
                    </span>
                    {inputPassword && !passwordCharValid?.isNoSpaceCharValid && <ImCross className={"!text-red-500 text-xs"} />}
                    {inputPassword && passwordCharValid?.isNoSpaceCharValid && <BsCheckLg className={"!text-green-500 text-xs"} />}
                </li>
            </ul>
        </div>
    )
}

export default PasswordValidator;