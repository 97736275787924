import { useState } from "react";
import { toast } from "react-toastify";
import format from "string-format";

import { MdContentCopy } from "react-icons/md";
import { AiOutlineCheckCircle } from "react-icons/ai";

// import ShareSocialPreview from "components/modals/shareSocialModal/ShareSocialPreview";
import { socialLinks } from "components/modals/shareSocialModal/shareSocialData";

import { useAppState } from 'hooks/useStore';
import { QR_CODE_URL } from "const/default.const";

const ShareSocialModal = () => {
  const { shareSocial } = useAppState((s) => s.local)

  const [isURLCopied, setIsURLCopied] = useState("")
  const [showQR, setShowQR] = useState(true);

  const qrUrl = format(QR_CODE_URL, { link: shareSocial?.url });

  const handleDownloadQRCode = () => {
    fetch(qrUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "qrcode.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error("Error downloading QR code:", error));
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareSocial?.url);
    setIsURLCopied(true);
    toast.success("Copied!")
    setTimeout(() => {
      setIsURLCopied(false);
    }, 2000);
  }

  return (
    <div className="space-y-5 overflow-x-hidden select-none pt-5">
      {shareSocial?.socialAction &&
        <span className={"w-full flex justify-center items-center font-bodyPri font-semibold text-lg text-text-800 text-center truncate tracking-wide"}>
          {shareSocial?.socialAction.label}
        </span>
      }

      <div className={"min-h-[5rem] space-y-5 flex flex-col justify-between"}>
        {/* {shareSocial && <LinkPreview
          url={shareSocial?.url}
          render={({ loading, preview }) => (
            <ShareSocialPreview loading={loading} preview={{ ...preview, url: shareSocial?.url }} />
          )}
        />
        } */}
        <div className={"flex flex-col justify-center items-center gap-3"}>
          {showQR && (
            <div className="flex flex-col items-center justify-center">
              <img
                src={qrUrl}
                className="w-40 aspect-square object-cover rounded"
              />
              <button
                className="px-5 py-2 rounded-lg border border-gray-500 hover:text-secondary-dark hover:border-secondary-dark"
                onClick={handleDownloadQRCode}
              >
                <span>
                  {"Download"}
                </span>
              </button>
            </div>
          )}
          <div className="font-bodyPri font-semibold text-text-900 text-md">
            {"Share Via"}
          </div>
          <div className='flex flex-wrap gap-3'>
            <div className={"flex flex-col items-center justify-start gap-1.5"}>
              <div className={"w-[3.2rem] h-[3.2rem] flex items-center justify-center bg-primary-dark cursor-pointer"} onClick={handleCopyLink}>
                {!isURLCopied
                  ? <MdContentCopy className={"text-2xl text-text-50"} />
                  : <AiOutlineCheckCircle className={"text-2xl text-text-50"} />
                }

              </div>
              <span className={"font-bodyPri font-normal text-text-900 text-xs"}>
                {"Copy"}
              </span>
            </div>

            <div className="flex flex-col items-center justify-start gap-1.5">
              <div
                className="w-[3.2rem] h-[3.2rem] flex items-center justify-center bg-primary-dark cursor-pointer"
                onClick={() => setShowQR(!showQR)}>
                <img
                  src={qrUrl}
                  alt="QR Code"
                  className="w-full h-full object-contain"
                />
              </div>
              <span className={"font-bodyPri font-normal text-text-900 text-xs"}>
                {"QR Code"}
              </span>
            </div>

            {socialLinks && Object.values(socialLinks).map((socialLink) =>
              <div className={"flex flex-col items-center justify-start gap-0.5 "}>
                <span>
                  {socialLink?.component({ url: shareSocial?.url, size: 50 })}
                </span>
                <span className={"font-bodyPri font-normal text-text-900 text-xs"}>
                  {socialLink?.label}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShareSocialModal