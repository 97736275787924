export const becomeTutorData = [
    {
        label: 'Choice of diverse teaching profiles'
    },
    {
        label: 'Create your own classes and live sessions'
    },
    {
        label: 'Get instant support from our responsive team'
    },
    {
        label: 'Streamlined and integrated payment system'
    },
    {
        label: 'Reach your prospective students easily'
    },
];