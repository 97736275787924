import { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import {
    PageContentStyle,
    Container,
    UnauthorizedCard,
    UnauthorizedCardHeader,
    UnauthorizedContentContainer,
    UnAuthorizedContent,
    Heading,
    SubHeading,
    HomeButtons,
    RoleSwitchButton,
    GoToDashboardButton
} from "pages/global/unauthorized/UnauthorizedPageStyle";

import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

import { pagesInfo } from "utils/pagesInfo";
import { useTitle } from "hooks/useTitle";

const UnauthorizedPage = ({
    errorTitle = "Unauthorized Access",
    errorDescription = "Your Account does not have access to this page. Please switch or login your account"
}) => {
    const { modal } = useAppState((state) => state.local)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        setTitle({
            ...title,
            title: "You are not authorised to view this page | Edulyte"
        })

        dispatcher(setModal({
            ...modal,
            [modalConst.ROLE_SWITCH_MODAL.stateKey]: true
        }))
    }, [])

    const onHandleClickDashboard = () => {
        navigate(pagesInfo.DASHBOARD.pagePath, { replace: true })
    }

    const onHandleSwitchRole = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.ROLE_SWITCH_MODAL.stateKey]: true
        }))
    }

    return (
        <PageContentStyle>
            <Container>
                <UnauthorizedCard>
                    <UnauthorizedCardHeader>
                        <ReportProblemOutlinedIcon className={"text-yellow-400 drop-shadow-lg rounded-full"} sx={{ fontSize: 60 }} />
                        <UnauthorizedContentContainer>
                            <UnAuthorizedContent>
                                <Heading>
                                    {errorTitle}
                                </Heading>
                                <SubHeading>
                                    {errorDescription}
                                </SubHeading>
                            </UnAuthorizedContent>
                            <HomeButtons>
                                <GoToDashboardButton onClick={onHandleClickDashboard}>
                                    {"Go To Dashboard"}
                                </GoToDashboardButton>
                                <RoleSwitchButton onClick={onHandleSwitchRole}>
                                    {"Switch Role"}
                                </RoleSwitchButton>
                            </HomeButtons>
                        </UnauthorizedContentContainer>
                    </UnauthorizedCardHeader>
                </UnauthorizedCard>
            </Container>
        </PageContentStyle>
    )
}

export default UnauthorizedPage
