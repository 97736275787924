import { toast } from "react-toastify";

import downloadsService from "./downloads.service";

import {
    setUserDownloadsListLoading,
    setUserDownloadsListData,
    setUserDownloadsListMessage,

    setUserDownloadDetailLoading,
    setUserDownloadDetailData,
    setUserDownloadDetailMessage,

    setOwnerUserDownloadsListLoading,
    setOwnerUserDownloadsListData,
    setOwnerUserDownloadsListMessage,

    setOwnerUserDownloadDetailLoading,
    setOwnerUserDownloadDetailData,
    setOwnerUserDownloadDetailMessage,

    setAddUserDownloadDetailLoading,
    setAddUserDownloadDetailData,
    setAddUserDownloadDetailMessage,

    setModifyUserDownloadDetailLoading,
    setModifyUserDownloadDetailData,
    setModifyUserDownloadDetailMessage,

    setDestroyUserDownloadDetailLoading,
    setDestroyUserDownloadDetailMessage,

    setAddDownloadPreviewDetailLoading,
    setAddDownloadPreviewDetailMessage,

    setModifyOrderDownloadPreviewDetailLoading,
    setModifyOrderDownloadPreviewDetailMessage,

    setDestroyDownloadPreviewDetailLoading,
    setDestroyDownloadPreviewDetailMessage,

    setAddDownloadFileDetailLoading,
    setAddDownloadFileDetailMessage,

    setModifyOrderDownloadFileDetailLoading,
    setModifyOrderDownloadFileDetailMessage,

    setDestroyDownloadFileDetailLoading,
    setDestroyDownloadFileDetailMessage,

    setDownloadUserListLoading,
    setDownloadUserListData,
    setDownloadUserListMessage,

    setModifyDownloadUserDetailLoading,
    setModifyDownloadUserDetailMessage,

    setDestroyDownloadUserDetailLoading,
    setDestroyDownloadUserDetailMessage,

    setDownloadInvitationListLoading,
    setDownloadInvitationListData,
    setDownloadInvitationListMessage,

    setSendDownloadInvitationDetailLoading,
    setSendDownloadInvitationDetailData,
    setSendDownloadInvitationDetailMessage,

    setValidateDownloadInvitationDetailData,
    setValidateDownloadInvitationDetailLoading,
    setValidateDownloadInvitationDetailMessage,

    setAcceptAndDeclineDownloadInvitationDetailLoading,
    setAcceptAndDeclineDownloadInvitationDetailMessage,

    setDestroyDownloadInvitationDetailLoading,
    setDestroyDownloadInvitationDetailMessage,
} from "redux/downloads/downloads.slice";

export const getUserDownloadsList = (userId, query) => async (dispatch) => {
    dispatch(setUserDownloadsListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: query
        }
        const response = await downloadsService.getUserDownloadsList(requestData)
        if (response.status === 200) {
            dispatch(setUserDownloadsListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setUserDownloadsListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
    } finally {
        dispatch(setUserDownloadsListLoading(false))
    }
}

export const getUserDownloadDetail = (downloadId, userId) => async (dispatch) => {
    dispatch(setUserDownloadDetailLoading(true))

    try {
        const requestData = {
            params: { downloadId: downloadId, userId: userId }
        }
        const response = await downloadsService.getUserDownloadDetail(requestData)
        if (response.status === 200) {
            dispatch(setUserDownloadDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setUserDownloadDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
    } finally {
        dispatch(setUserDownloadDetailLoading(false))
    }
}

export const getOwnerUserDownloadsList = (userId, query) => async (dispatch) => {
    dispatch(setOwnerUserDownloadsListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: query
        }
        const response = await downloadsService.getOwnerUserDownloadsList(requestData)
        if (response.status === 200) {
            dispatch(setOwnerUserDownloadsListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setOwnerUserDownloadsListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
    } finally {
        dispatch(setOwnerUserDownloadsListLoading(false))
    }
}

export const getOwnerUserDownloadDetail = (downloadId, userId) => async (dispatch) => {
    dispatch(setOwnerUserDownloadDetailLoading(true))

    try {
        const requestData = {
            params: { downloadId: downloadId, userId: userId }
        }
        const response = await downloadsService.getOwnerUserDownloadDetail(requestData)
        if (response.status === 200) {
            dispatch(setOwnerUserDownloadDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setOwnerUserDownloadDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
    } finally {
        dispatch(setOwnerUserDownloadDetailLoading(false))
    }
}

export const createUserDownloadDetail = (body) => async (dispatch) => {
    dispatch(setAddUserDownloadDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await downloadsService.createUserDownloadDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddUserDownloadDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setAddUserDownloadDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setAddUserDownloadDetailLoading(false))
    }
}

export const updateUserDownloadDetail = (downloadId, body, payload = { isShowToast: true }) => async (dispatch) => {
    dispatch(setModifyUserDownloadDetailLoading(true))

    try {
        const requestData = {
            params: { downloadId: downloadId },
            body: body
        }
        const response = await downloadsService.updateUserDownloadDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyUserDownloadDetailData(response.data.data))
            dispatch(setOwnerUserDownloadDetailData(response.data.data))
            if (payload?.isShowToast) {
                toast.success(response.data.message)
            }
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setModifyUserDownloadDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setModifyUserDownloadDetailLoading(false))
    }
}

export const deleteUserDownloadDetail = (downloadId) => async (dispatch, getState) => {
    dispatch(setDestroyUserDownloadDetailLoading(true))

    try {
        const { ownerUserDownloadList } = getState().downloads
        const requestData = {
            params: { downloadId: downloadId }
        }
        const response = await downloadsService.deleteUserDownloadDetail(requestData)
        if (response.status === 200) {
            dispatch(setOwnerUserDownloadsListData({
                ...ownerUserDownloadList?.data,
                result: ownerUserDownloadList?.data?.result?.filter((item) => item?.id !== downloadId),
                pagination: {
                    ...ownerUserDownloadList?.data?.pagination,
                    records: ownerUserDownloadList?.data?.pagination?.records - 1,
                    totalRecords: ownerUserDownloadList?.data?.pagination?.totalRecords - 1
                },
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setDestroyUserDownloadDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyUserDownloadDetailLoading(false))
    }
}

// download preview
export const createDownloadPreviewDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddDownloadPreviewDetailLoading(true))

    try {
        const { ownerUserDownloadDetail } = getState().downloads
        const requestData = {
            body: body
        }
        const response = await downloadsService.createDownloadPreviewDetail(requestData)
        if (response.status === 201) {
            dispatch(setOwnerUserDownloadDetailData({
                ...ownerUserDownloadDetail?.data,
                previews: [...ownerUserDownloadDetail?.data?.previews, response.data.data]
            }))
            toast.success(response.data.message || "Created Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setAddDownloadPreviewDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setAddDownloadPreviewDetailLoading(false))
    }
}

export const updateOrderDownloadPreviewDetail = (downloadId, body) => async (dispatch, getState) => {
    dispatch(setModifyOrderDownloadPreviewDetailLoading(true))

    try {
        const { ownerUserDownloadDetail } = getState().downloads
        const requestData = {
            params: { downloadId: downloadId },
            body: body
        }
        const response = await downloadsService.updateOrderDownloadPreviewDetail(requestData)
        if (response.status === 200) {
            dispatch(setOwnerUserDownloadDetailData({
                ...ownerUserDownloadDetail?.data,
                previews: response?.data?.data
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setModifyOrderDownloadPreviewDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setModifyOrderDownloadPreviewDetailLoading(false))
    }
}

export const deleteDownloadPreviewDetail = (downloadPreviewId) => async (dispatch, getState) => {
    dispatch(setDestroyDownloadPreviewDetailLoading(true))

    try {
        const { ownerUserDownloadDetail } = getState().downloads
        const requestData = {
            params: { downloadPreviewId: downloadPreviewId }
        }
        const response = await downloadsService.deleteDownloadPreviewDetail(requestData)
        if (response.status === 200) {
            dispatch(setOwnerUserDownloadDetailData({
                ...ownerUserDownloadDetail?.data,
                previews: ownerUserDownloadDetail?.data?.previews?.filter((item) => item?.id !== downloadPreviewId)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setDestroyDownloadPreviewDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyDownloadPreviewDetailLoading(false))
    }
}

// download file
export const createDownloadFileDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddDownloadFileDetailLoading(true))

    try {
        const { ownerUserDownloadDetail } = getState().downloads
        const requestData = {
            body: body
        }
        const response = await downloadsService.createDownloadFileDetail(requestData)
        if (response.status === 201) {
            dispatch(setOwnerUserDownloadDetailData({
                ...ownerUserDownloadDetail?.data,
                files: [response.data.data, ...ownerUserDownloadDetail?.data?.files]
            }))
            toast.success(response.data.message || "Created Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setAddDownloadFileDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setAddDownloadFileDetailLoading(false))
    }
}

export const updateOrderDownloadFileDetail = (body, query) => async (dispatch, getState) => {
    dispatch(setModifyOrderDownloadFileDetailLoading(true))

    try {
        const { ownerUserDownloadDetail } = getState().downloads
        const requestData = {
            query: query,
            body: body
        }
        const response = await downloadsService.updateOrderDownloadFileDetail(requestData)
        if (response.status === 200) {
            dispatch(setOwnerUserDownloadDetailData({
                ...ownerUserDownloadDetail?.data,
                files: response?.data?.data
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setModifyOrderDownloadFileDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setModifyOrderDownloadFileDetailLoading(false))
    }
}

export const deleteDownloadFileDetail = (downloadFileId) => async (dispatch, getState) => {
    dispatch(setDestroyDownloadFileDetailLoading(true))

    try {
        const { ownerUserDownloadDetail } = getState().downloads
        const requestData = {
            params: { downloadFileId: downloadFileId }
        }
        const response = await downloadsService.deleteDownloadFileDetail(requestData)
        if (response.status === 200) {
            dispatch(setOwnerUserDownloadDetailData({
                ...ownerUserDownloadDetail?.data,
                files: ownerUserDownloadDetail?.data?.files?.filter((item) => item?.id !== downloadFileId)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setDestroyDownloadFileDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyDownloadFileDetailLoading(false))
    }
}

// download user
export const getDownloadUserList = (query) => async (dispatch) => {
    dispatch(setDownloadUserListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await downloadsService.getDownloadUserList(requestData)
        if (response.status === 200) {
            dispatch(setDownloadUserListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setDownloadUserListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
    } finally {
        dispatch(setDownloadUserListLoading(false))
    }
}

export const updateDownloadUserDetail = (downloadUserId, body) => async (dispatch, getState) => {
    dispatch(setModifyDownloadUserDetailLoading(true))

    try {
        const { downloadUserList } = getState().downloads
        const requestData = {
            params: { downloadUserId: downloadUserId },
            body: body
        }
        const response = await downloadsService.updateDownloadUserDetail(requestData)
        if (response.status === 200) {
            dispatch(setDownloadUserListData({
                ...downloadUserList?.data,
                result: downloadUserList?.data?.result?.map((item) => (item?.id === downloadUserId) ? response.data.data : item)
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setModifyDownloadUserDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setModifyDownloadUserDetailLoading(false))
    }
}

export const deleteDownloadUserDetail = (downloadUserId) => async (dispatch, getState) => {
    dispatch(setDestroyDownloadUserDetailLoading(true))

    try {
        const { downloadUserList } = getState().downloads
        const requestData = {
            params: { downloadUserId: downloadUserId }
        }
        const response = await downloadsService.deleteDownloadUserDetail(requestData)
        if (response.status === 200) {
            dispatch(setDownloadUserListData({
                ...downloadUserList?.data,
                result: downloadUserList?.data?.result?.filter((item) => item?.id !== downloadUserId),
                pagination: {
                    ...downloadUserList?.data?.pagination,
                    records: downloadUserList?.data?.pagination?.records - 1,
                    totalRecords: downloadUserList?.data?.pagination?.totalRecords - 1
                },
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setDestroyDownloadUserDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyDownloadUserDetailLoading(false))
    }
}

// download invitation list
export const getDownloadInvitationList = (query) => async (dispatch) => {
    dispatch(setDownloadInvitationListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await downloadsService.getDownloadInvitationList(requestData)
        if (response.status === 200) {
            dispatch(setDownloadInvitationListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setDownloadInvitationListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
    } finally {
        dispatch(setDownloadInvitationListLoading(false))
    }
}

// send download invitation detail
export const createSendDownloadInvitationDetail = (body) => async (dispatch) => {
    dispatch(setSendDownloadInvitationDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await downloadsService.createSendDownloadInvitationDetail(requestData)
        if (response.status === 201) {
            dispatch(setSendDownloadInvitationDetailData(response.data.data))
            toast.success(response.data.message || "Created Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setSendDownloadInvitationDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setSendDownloadInvitationDetailLoading(false))
    }
}

// Validate Download Invitation detail
export const getValidateDownloadInvitationDetail = (query) => async (dispatch) => {
    dispatch(setValidateDownloadInvitationDetailLoading(true));

    try {
        const requestData = {
            query
        }
        const response = await downloadsService.getValidateDownloadInvitationDetail(requestData);
        if (response.status === 200) {
            dispatch(setValidateDownloadInvitationDetailData(response.data.data));
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.message || 'Something went wrong!')
        dispatch(setValidateDownloadInvitationDetailMessage(error.response.data.message || error.response.data.message || 'Something went wrong!'))
    } finally {
        dispatch(setValidateDownloadInvitationDetailLoading(false));
    }
}

// Accept or Decline Download Invitation
export const getAcceptAndDeclineDownloadInvitationDetail = (body, query) => async (dispatch) => {
    dispatch(setAcceptAndDeclineDownloadInvitationDetailLoading(true));

    try {
        const requestData = {
            body, query
        }
        const response = await downloadsService.getAcceptAndDeclineDownloadInvitationDetail(requestData);
        if (response.status === 200) {
            dispatch(setValidateDownloadInvitationDetailData(response.data.data));
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.message || 'Something went wrong!')
        dispatch(setAcceptAndDeclineDownloadInvitationDetailMessage(error.response.data.message || error.response.data.message || 'Something went wrong!'))
    }
    finally {
        dispatch(setAcceptAndDeclineDownloadInvitationDetailLoading(false));
    }
}

// delete download invitation detail
export const deleteDownloadInvitationDetail = (downloadInvitationId) => async (dispatch, getState) => {
    dispatch(setDestroyDownloadInvitationDetailLoading(true))

    try {
        const { downloadInvitationList } = getState().downloads
        const requestData = {
            params: { downloadInvitationId: downloadInvitationId }
        }
        const response = await downloadsService.deleteDownloadInvitationDetail(requestData)
        if (response.status === 200) {
            dispatch(setDownloadInvitationListData({
                ...downloadInvitationList?.data,
                result: downloadInvitationList?.data?.result?.filter((item) => item?.id !== downloadInvitationId),
                pagination: {
                    ...downloadInvitationList?.data?.pagination,
                    records: downloadInvitationList?.data?.pagination?.records - 1,
                    totalRecords: downloadInvitationList?.data?.pagination?.totalRecords - 1
                },
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setDestroyDownloadInvitationDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyDownloadInvitationDetailLoading(false))
    }
}