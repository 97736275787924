import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import CardMembershipIcon from '@mui/icons-material/CardMembership';

import { userRoles } from 'redux/auth/auth.const';

import image1 from "./images/australian-flag.png";
import image2 from "./images/india-flag.png";
import image3 from "./images/united-states-flag.png";
import image4 from "./images/promo-image.jpg";
import image5 from "./images/token-wallet.jpg";

import { dayjs, timeZone } from 'utils/dateTime.utils';

export const tutorPageHeading = {
    heading: "Wallet & Transactions",
    subHeading: "See your balance, transactions and withdraw money!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/wallet.svg"
}

export const studentPageHeading = {
    heading: "Wallet & Transactions",
    subHeading: "See your balance and transactions!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/wallet.svg"
}

export const wallet = {
    TOKEN_CREDIT: {
        title: "AI Tokens",
        value: "token_credit",
        amount: 0,
        currencyCode: "USD",
        currencyTitle: "AI Tokens",
        countryFlagTooltip: "AI Tokens",
        addBtnTooltip: "Add Token(s)",
        countryFlag: image5,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    WALLET_CREDIT: {
        title: "Available",
        value: 'wallet_credit',
        amount: 0,
        currencyCode: "USD",
        currencyTitle: "US Dollar",
        countryFlagTooltip: "US Dollar",
        addBtnTooltip: "Add Credit",
        countryFlag: image3,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    // PENDING_CREDIT: {
    //     title: "Pending",
    //     value: 'pending_credit',
    //     amount: 0,
    //     currencyCode: "USD",
    //     currencyTitle: "US Dollar",
    //     countryFlagTooltip: "us",
    // addBtnTooltip: "Add Credit",
    //     countryFlag: image3,
    //     roles: [userRoles.TUTOR.value]
    // },
    PROMO_CREDIT: {
        title: "Promo",
        value: 'promo_credit',
        amount: 0,
        currencyCode: "USD",
        currencyTitle: "US Dollar",
        countryFlagTooltip: "Promo in USD",
        addBtnTooltip: "",
        countryFlag: image4,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    AUD_CREDIT: {
        title: "AUD Available",
        value: "aud_available",
        amount: 0,
        currencyCode: "AUD",
        currencyTitle: "Australian Dollar",
        countryFlagTooltip: "Australian Dollar",
        addBtnTooltip: "Add Credit",
        countryFlag: image1,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    INR_CREDIT: {
        title: "INR Available",
        value: "inr_available",
        amount: 0,
        currencyCode: "INR",
        currencyTitle: "Indian Rupee",
        countryFlagTooltip: "Indian Rupee",
        addBtnTooltip: "Add Credit",
        countryFlag: image2,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    }
}

export const buttonFeature = {
    WITHDRAW: {
        label: 'Withdraw',
        value: 'withdraw',
        image: <AccountBalanceWalletIcon style={{ width: '60px', height: '60px' }} />,
        roles: [userRoles.TUTOR.value]
    },
    ADD_CREDIT: {
        label: 'Add Credit',
        value: 'add_credit',
        image: <MonetizationOnIcon style={{ width: '60px', height: '60px' }} />,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    REEDEM_GIFT_CARD: {
        label: 'Gift Card',
        value: 'reedem_gift_card',
        image: <CardGiftcardIcon style={{ width: '60px', height: '60px' }} />,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    PROMO: {
        label: 'Promo',
        value: 'promo',
        image: <LoyaltyIcon style={{ width: '60px', height: '60px' }} />,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    MANAGE_SUBSCRIPTION: {
        label: 'Subscription',
        value: 'manage_subscription',
        image: <CardMembershipIcon style={{ width: '60px', height: '60px' }} />,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
}

export const txnCardTitles = {
    WALLET: {
        label: 'Wallet Transactions',
        value: 'wallet'
    },
    WITHDRAWAL: {
        label: 'My Withdrawals',
        value: 'withdrawal'
    }
}

export const walletFilter = {

}

export const bookingFilter = {

}

export const walletTabConst = {
    WALLET_TRANSACTIONS: {
        label: "Wallet Transactions",
        value: "wallet_transactions"
    },
    TOKEN_TRANSACTIONS: {
        label: "Token Transactions",
        value: "token_transactions"
    },
    MY_WITHDRAWALS: {
        label: "My Withdrawals",
        value: "my_withdrawals"
    }
}

export const sortTransactions = (txn1, txn2) => {
    let dateTime1 = dayjs(txn1?.createdAt)
    let dateTime2 = dayjs(txn2?.createdAt)
    return (dateTime1.isBefore(dateTime2)) ? 1 : -1;
}

export const getLastSevenDays = () => {
    const currentDate = dayjs().tz(timeZone)
    const lastSevenDays = []
    const currentDayIndex = currentDate.day();
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    for (let i = 0; i < 7; i++) {
        const index = (currentDayIndex + i) % 7
        const dayName = dayNames[index]
        lastSevenDays.push(dayName)
    }

    return lastSevenDays.reverse();
}

export const currentDate = dayjs().tz(timeZone)
export const sevenDaysAgo = currentDate.subtract(7, 'day')