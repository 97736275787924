export const url = {
    // lms Course 
    CREATE_LMS_COURSE_DETAIL: 'lms/lms-courses/lms-courses',
    UPDATE_LMS_COURSE_DETAIL: 'lms/lms-courses/lms-courses/{courseId}',
    DELETE_LMS_COURSE_DETAIL: 'lms/lms-courses/lms-courses/{courseId}',

    // tutor lms course
    GET_TUTOR_LMS_COURSE_LIST: "lms/lms-courses/lms-courses/tutors",
    GET_TUTOR_LMS_COURSE_DETAIL: "lms/lms-courses/lms-courses/{courseId}/tutors",

    // Lms Course Section
    CREATE_LMS_COURSE_SECTION_DETAIL: 'lms/lms-courses/sections',
    GET_LMS_COURSE_SECTION_LIST: 'lms/courses/sections',
    UPDATE_LMS_COURSE_SECTION_ORDER_LIST: 'lms/lms-courses/sections/order',
    UPDATE_LMS_COURSE_SECTION_DETAIL: 'lms/lms-courses/sections/{sectionId}',
    GET_LMS_COURSE_SECTION_DETAIL: 'lms/lms-courses/sections/{sectionId}',
    DELETE_LMS_COURSE_SECTION_DETAIL: 'lms/lms-courses/sections/{sectionId}',

    // lms Course section lesson
    CREATE_LMS_COURSE_SECTION_LESSON_DETAIL: 'lms/lms-courses/section-lessons',
    GET_LMS_COURSE_SECTION_LESSON_LIST: 'lms/lms-courses/section-lessons',
    UPDATE_LMS_COURSE_SECTION_LESSON__ORDER_LIST: 'lms/lms-courses/section-lessons/order',
    GET_LMS_COURSE_SECTION_LESSON_DETAIL: 'lms/lms-courses/section-lessons/{lessonSectionId}',
    UPDATE_LMS_COURSE_SECTION_LESSON_DETAIL: 'lms/lms-courses/section-lessons/{lessonSectionId}',
    DELETE_LMS_COURSE_SECTION_LESSON_DETAIL: 'lms/lms-courses/section-lessons/{lessonSectionId}',

    // lms course tag detail
    CREATE_LMS_COURSE_TAG_DETAIL: "/lms/lms-courses/tags",
    UPDATE_LMS_COURSE_TAG_LIST_ORDER: "/lms/lms-courses/tags/order",
    DELETE_LMS_COURSE_TAG_DETAIL: "lms/lms-courses/tags/{courseTagId}",

    // lms course language detail
    CREATE_LMS_COURSE_LANGUAGE_DETAIL: "lms/lms-courses/languages",
    UPDATE_LMS_COURSE_LANGUAGE_LIST_ORDER: "lms/lms-courses/languages/order",
    DELETE_LMS_COURSE_LANGUAGE_DETAIL: "lms/lms-courses/languages/{courseLanguageId}",

    // lms course prof detail
    CREATE_LMS_COURSE_PROF_DETAIL: "lms/lms-courses/proficiencies",
    UPDATE_LMS_COURSE_PROF_LIST_ORDER: "lms/lms-courses/proficiencies/order",
    DELETE_LMS_COURSE_PROF_DETAIL: "lms/lms-courses/proficiencies/{courseProfId}",

    // lms course what you will learn detail
    CREATE_LMS_COURSE_LEARNING_DETAIL: "lms/lms-courses/what-you-will-learns",
    UPDATE_LMS_COURSE_LEARNING_DETAIL: "lms/lms-courses/what-you-will-learns/{courseLearningId}",
    UPDATE_LMS_COURSE_LEARNING_LIST_ORDER: "lms/lms-courses/what-you-will-learns/order",
    DELETE_LMS_COURSE_LEARNING_DETAIL: "lms/lms-courses/what-you-will-learns/{courseLearningId}",

    // lms course faq detail
    CREATE_LMS_COURSE_FAQ_DETAIL: "lms/lms-courses/faqs",
    UPDATE_LMS_COURSE_FAQ_DETAIL: "lms/lms-courses/faqs/{courseFaqId}",
    UPDATE_LMS_COURSE_FAQ_LIST_ORDER: "lms/lms-courses/faqs/order",
    DELETE_LMS_COURSE_FAQ_DETAIL: "lms/lms-courses/faqs/{courseFaqId}",

    //lms course slug detail
    CREATE_LMS_COURSE_SLUG_DETAIL: "lms/lms-courses/slugs",
    DELETE_LMS_COURSE_SLUG_DETAIL: "lms/lms-courses/slugs/{courseSlugId}",

    // lms course instructor detail
    CREATE_LMS_COURSE_INSTRUCTOR_DETAIL: "lms/lms-courses/users",
    UPDATE_LMS_COURSE_INSTRUCTOR_DETAIL: "lms/lms-courses/users/{courseUserId}",
    DELETE_LMS_COURSE_INSTRUCTOR_DETAIL: "lms/lms-courses/users/{courseInstructorId}",

    // lms course student detail
    GET_LMS_COURSE_STUDENT_LIST: "lms/lms-courses/students",
    CREATE_LMS_COURSE_STUDENT_DETAIL: "lms/lms-courses/students",
    UPDATE_LMS_COURSE_STUDENT_DETAIL: "lms/lms-courses/students/{courseStudentId}",
    DELETE_LMS_COURSE_STUDENT_DETAIL: "lms/lms-courses/students/{courseStudentId}",

    // student lms course
    GET_STUDENT_LMS_COURSE_LIST: "lms/lms-courses/lms-courses/students/{studentId}",
    GET_STUDENT_LMS_COURSE_DETAIL: "lms/lms-courses/lms-courses/{slug}/students/{studentId}",

    // student lms course fav detail
    GET_STUDENT_FAV_LMS_COURSE_LIST: "lms/lms-courses/lms-courses/students/{studentId}/favorite",
    CREATE_STUDENT_FAV_LMS_COURSE_DETAIL: "lms/lms-courses/favorites/create-delete",

    // lms course invitation
    GET_LMS_COURSE_INVITATION_LIST: "lms/lms-courses/invitations",
    CREATE_SEND_LMS_COURSE_INVITATION_DETAIL: "lms/lms-courses/invitations/send",
    GET_VALIDATE_LMS_COURSE_INVITATION_DETAIL: 'lms/lms-courses/invitations/validate',
    GET_ACCEPT_AND_DECLINE_LMS_COURSE_INVITATION_DETAIL: 'lms/lms-courses/invitations/accept',
    DELETE_LMS_COURSE_INVITATION_DETAIL: "lms/lms-courses/invitations/{courseInvitationId}",

    // lms course student section lesson
    CREATE_DELETE_LMS_COURSE_STUDENT_SECTION_LESSON: "lms/lms-courses/student-section-lessons/add-remove",

    // lms course student quiz response
    CREATE_LMS_COURSE_STUDENT_QUIZ_RESPONSE_DETAIL: "lms/lms-courses/student-quiz-responses",
    UPDATE_LMS_COURSE_STUDENT_QUIZ_RESPONSE_DETAIL: "lms/lms-courses/student-quiz-responses/{id}",

    // lms course student note
    CREATE_LMS_COURSE_STUDENT_NOTE_DETAIL: 'lms/lms-courses/student-notes',
    GET_LMS_COURSE_STUDENT_NOTE_LIST: 'lms/lms-courses/student-notes',
    GET_LMS_COURSE_STUDENT_NOTE_DETAIL: 'lms/lms-courses/student-notes/{id}',
    UPDATE_LMS_COURSE_STUDENT_NOTE_DETAIL: 'lms/lms-courses/student-notes/{id}',
    DELETE_LMS_COURSE_STUDENT_NOTE_DETAIL: 'lms/lms-courses/student-notes/{id}',
}


export const lmsCourseSectionLessonTypeEnum = {
    LECTURE: {
        value: "lecture",
        label: "Video Lecture"
    },
    ARTICLE: {
        value: "article",
        label: "Article"
    },
    QUIZ: {
        value: 'quiz',
        label: 'Quiz',
    },
    // ASSIGNMENT: {
    //     value: 'assignment',
    //     label: 'Assignment',
    // },
    // TOPIC: {
    //     value: 'topic',
    //     label: 'Topic',
    // },
    // CODING_EXERCISE: {
    //     value: 'coding_exercise',
    //     label: 'Coding Exercise',
    // },
    // PRACTICE_TEST: {
    //     value: 'practice_test',
    //     label: 'Practice Test',
    // },
}

export const lmsCourseStatusEnum = {
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    }
}

export const lmsCourseShareableEnum = {
    YES: {
        label: "Yes",
        value: "yes"
    },
    NO: {
        label: "No",
        value: "no"
    }
}

export const lmsCourseVisibilityEnum = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    }
}

export const lmsCourseInstructorPermissionEnum = {
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    }
}

export const lmsCourseStudentStatusEnum = {
    PENDING: {
        label: "pending",
        value: "pending"
    },
    JOINED: {
        label: "Joined",
        value: "joined"
    },
    CANCELLED: {
        label: "Cancelled",
        value: "cancelled"
    }
}

export const lmsCourseStudentIsActiveEnum = {
    ACTIVE: {
        label: "Active",
        value: "active"
    },
    INACTIVE: {
        label: "In Active",
        value: "inactive"
    },
    BLOCKED: {
        label: "Block",
        value: "blocked"
    }
}

export const lmsCourseInviteToEnum = {
    TUTOR: {
        label: "Tutor",
        value: "tutor"
    },
    STUDENT: {
        label: "Student",
        value: "student"
    }
}

export const lmsCourseJoiningFlowEnum = {
    CHECKOUT: {
        label: "Checkout",
        value: "checkout"
    },
    INVITE: {
        label: "Invite",
        value: "invite"
    },
    MANUAL: {
        label: "Manual",
        value: "manual"
    },
}

export const lmsCourseInviteStatusEnum = {
    INVITED: {
        label: "Invited",
        value: "invited"
    },
    RE_INVITED: {
        label: "Re Invited",
        value: "re_invited"
    },
    ACCEPTED: {
        label: "Accepted",
        value: "accepted"
    },
    DECLINED: {
        label: "Declined",
        value: "declined"
    }
}

export const addLmsCourseDetailPayload = {
    title: "",
    sub_title: "",
    category: "",
    courseHeaderActiveTab: "course_details",
    courseDetailActiveTab: "general",
    courseSettingsActiveTab: "general"
}
export const addLmsCourseSectionDetailPayload = {
    sectionId: null
}
export const sendLmsCourseInvitationDetailPayload = {
    lmsCourseId: null,
    emails: [],
    invitedUsers: [],
    inviteTo: null,
    inviteEmailSent: true,
    senderUserId: null
}

export const DEFAULT_LMS_COURSE_PAGE = 1
export const DEFAULT_LMS_COURSE_RECORDS = 15