import { createSlice } from "@reduxjs/toolkit";
import { PAYMENT_INITIAL_STATE } from "redux/payment/payment.initialState";


const payment = createSlice({
    name: "payment",
    initialState: PAYMENT_INITIAL_STATE,
    reducers: {
        // Actions for make payment
        setMakePaymentLoading: (state, { payload }) => {
            if (payload) {
                state.makePayment.errorMsg = PAYMENT_INITIAL_STATE.makePayment.errorMsg
            }
            state.makePayment.isLoading = payload
        },
        setMakePayment: (state, { payload }) => {
            state.makePayment.makePayment = payload
            state.makePayment.errorMsg = PAYMENT_INITIAL_STATE.makePayment.errorMsg
        },
        setMakePaymentErrorMsg: (state, { payload }) => {
            state.makePayment.errorMsg = payload
            state.makePayment.makePayment = PAYMENT_INITIAL_STATE.makePayment.makePayment
            state.makePayment.makePaymentConfirm = PAYMENT_INITIAL_STATE.makePayment.makePaymentConfirm
        },
        setClearMakePayment: (state) => {
            state.makePayment.isLoading = PAYMENT_INITIAL_STATE.makePayment.isLoading
            state.makePayment.makePayment = PAYMENT_INITIAL_STATE.makePayment.makePayment
            state.makePayment.makePaymentConfirm = PAYMENT_INITIAL_STATE.makePayment.makePaymentConfirm
            state.makePayment.errorMsg = PAYMENT_INITIAL_STATE.makePayment.errorMsg
        },
        setMakePaymentPayload: (state, { payload }) => {
            state.makePayment.makePaymentPayload = payload
        },
        setClearMakePaymentPayload: (state) => {
            state.makePayment.makePaymentPayload = PAYMENT_INITIAL_STATE.makePayment.makePaymentPayload
        },
        setMakePaymentConfirm: (state, { payload }) => {
            state.makePayment.makePaymentConfirm = payload
            state.makePayment.errorMsg = PAYMENT_INITIAL_STATE.makePayment.errorMsg
        },
        setClearMakePaymentConfirm: (state) => {
            state.makePayment.makePaymentConfirm = PAYMENT_INITIAL_STATE.makePayment.makePaymentConfirm
        },


        // User Customer Portal Session Reducer
        setCreateUserCustomerPortalSessionLoading: (state, { payload }) => {
            state.createUserCustomerPortalSession.isLoading = payload
        },
        setCreateUserCustomerPortalSessionData: (state, { payload }) => {
            state.createUserCustomerPortalSession.data = payload
            state.createUserCustomerPortalSession.message = PAYMENT_INITIAL_STATE.createUserCustomerPortalSession.message
        },
        setCreateUserCustomerPortalSessionMessage: (state, { payload }) => {
            state.createUserCustomerPortalSession.message = payload
            state.createUserCustomerPortalSession.data = PAYMENT_INITIAL_STATE.createUserCustomerPortalSession.data
        },
        resetCreateUserCustomerPortalSession: (state) => {
            state.createUserCustomerPortalSession = PAYMENT_INITIAL_STATE.createUserCustomerPortalSession
        },

        // User Customer Session Reducer
        setCreateUserCustomerSessionLoading: (state, { payload }) => {
            state.createUserCustomerSession.isLoading = payload
        },
        setCreateUserCustomerSessionData: (state, { payload }) => {
            state.createUserCustomerSession.data = payload
            state.createUserCustomerSession.message = PAYMENT_INITIAL_STATE.createUserCustomerSession.message
        },
        setCreateUserCustomerSessionMessage: (state, { payload }) => {
            state.createUserCustomerSession.message = payload
            state.createUserCustomerSession.data = PAYMENT_INITIAL_STATE.createUserCustomerSession.data
        },
        resetCreateUserCustomerSession: (state) => {
            state.createUserCustomerSession = PAYMENT_INITIAL_STATE.createUserCustomerSession
        },

        // User Payment Method List Reducer
        setUserPaymentMethodListLoading: (state, { payload }) => {
            state.userPaymentMethodList.isLoading = payload
        },
        setUserPaymentMethodListData: (state, { payload }) => {
            state.userPaymentMethodList.data = payload
            state.userPaymentMethodList.message = PAYMENT_INITIAL_STATE.userPaymentMethodList.message
        },
        setUserPaymentMethodListMessage: (state, { payload }) => {
            state.userPaymentMethodList.message = payload
            state.userPaymentMethodList.data = PAYMENT_INITIAL_STATE.userPaymentMethodList.data
        },
        resetUserPaymentMethodList: (state) => {
            state.userPaymentMethodList = PAYMENT_INITIAL_STATE.userPaymentMethodList
        },

        // User Payment Method Detail Reducer
        setUserPaymentMethodDetailLoading: (state, { payload }) => {
            state.userPaymentMethodDetail.isLoading = payload
        },
        setUserPaymentMethodDetailPayload: (state, { payload }) => {
            state.userPaymentMethodDetail.payload = payload
        },
        setUserPaymentMethodDetailMessage: (state, { payload }) => {
            state.userPaymentMethodDetail.message = payload
        },
        resetUserPaymentMethodDetail: (state) => {
            state.userPaymentMethodDetail = PAYMENT_INITIAL_STATE.userPaymentMethodDetail
        },

        // Join User Payment Method Detail Reducer
        setJoinUserPaymentMethodDetailLoading: (state, { payload }) => {
            state.joinUserPaymentMethodDetail.isLoading = payload
        },
        setJoinUserPaymentMethodDetailPayload: (state, { payload }) => {
            state.joinUserPaymentMethodDetail.payload = payload
        },
        setJoinUserPaymentMethodDetailMessage: (state, { payload }) => {
            state.joinUserPaymentMethodDetail.message = payload
        },
        resetJoinUserPaymentMethodDetail: (state) => {
            state.joinUserPaymentMethodDetail = PAYMENT_INITIAL_STATE.joinUserPaymentMethodDetail
        },

        // Remove User Payment Method Detail Reducer
        setRemoveUserPaymentMethodDetailLoading: (state, { payload }) => {
            state.removeUserPaymentMethodDetail.isLoading = payload
        },
        setRemoveUserPaymentMethodDetailMessage: (state, { payload }) => {
            state.removeUserPaymentMethodDetail.message = payload
        },
        resetRemoveUserPaymentMethodDetail: (state) => {
            state.removeUserPaymentMethodDetail = PAYMENT_INITIAL_STATE.removeUserPaymentMethodDetail
        },

        /** User Checkout Session **/
        setCreateUserCheckoutSessionLoading: (state, { payload }) => {
            state.createUserCheckoutSession.isLoading = payload
        },
        setCreateUserCheckoutSessionData: (state, { payload }) => {
            state.createUserCheckoutSession.data = payload
            state.createUserCheckoutSession.message = PAYMENT_INITIAL_STATE.createUserCheckoutSession.message
        },
        setCreateUserCheckoutSessionMessage: (state, { payload }) => {
            state.createUserCheckoutSession.message = payload
            state.createUserCheckoutSession.data = PAYMENT_INITIAL_STATE.createUserCheckoutSession.data
        },
        resetCreateUserCheckoutSession: (state) => {
            state.createUserCheckoutSession = PAYMENT_INITIAL_STATE.createUserCheckoutSession
        },

        // Add Payment Intent Detail Reducer
        setAddPaymentIntentDetailLoading: (state, { payload }) => {
            state.addPaymentIntentDetail.isLoading = payload
        },
        setAddPaymentIntentDetailData: (state, { payload }) => {
            state.addPaymentIntentDetail.data = payload
            state.addPaymentIntentDetail.message = PAYMENT_INITIAL_STATE.addPaymentIntentDetail.message
        },
        setAddPaymentIntentDetailPayload: (state, { payload }) => {
            state.addPaymentIntentDetail.payload = payload
            state.addPaymentIntentDetail.message = PAYMENT_INITIAL_STATE.addPaymentIntentDetail.message
        },
        setAddPaymentIntentDetailMessage: (state, { payload }) => {
            state.addPaymentIntentDetail.message = payload
            state.addPaymentIntentDetail.data = PAYMENT_INITIAL_STATE.addPaymentIntentDetail.data
        },
        resetAddPaymentIntentDetail: (state) => {
            state.addPaymentIntentDetail = PAYMENT_INITIAL_STATE.addPaymentIntentDetail
        },
        // Payment Intent Detail Reducer
        setPaymentIntentDetailLoading: (state, { payload }) => {
            state.paymentIntentDetail.isLoading = payload
        },
        setPaymentIntentDetailData: (state, { payload }) => {
            state.paymentIntentDetail.data = payload
            state.paymentIntentDetail.message = PAYMENT_INITIAL_STATE.paymentIntentDetail.message
        },
        setPaymentIntentDetailMessage: (state, { payload }) => {
            state.paymentIntentDetail.message = payload
            state.paymentIntentDetail.data = PAYMENT_INITIAL_STATE.paymentIntentDetail.data
        },
        resetPaymentIntentDetail: (state) => {
            state.paymentIntentDetail = PAYMENT_INITIAL_STATE.paymentIntentDetail
        },

        // Add token through wallet
        setAddTokenThroughWalletDetailLoading: (state, { payload }) => {
            state.addTokenThroughWalletDetail.isLoading = payload
        },
        setAddTokenThroughWalletDetailData: (state, { payload }) => {
            state.addTokenThroughWalletDetail.data = payload
            state.addTokenThroughWalletDetail.message = PAYMENT_INITIAL_STATE.addTokenThroughWalletDetail.message
        },
        setAddTokenThroughWalletDetailMessage: (state, { payload }) => {
            state.addTokenThroughWalletDetail.message = payload
            state.addTokenThroughWalletDetail.data = PAYMENT_INITIAL_STATE.addTokenThroughWalletDetail.data
        },
        resetAddTokenThroughWalletDetail: (state) => {
            state.addTokenThroughWalletDetail = PAYMENT_INITIAL_STATE.addTokenThroughWalletDetail
        }
    }
})

export const {
    setMakePaymentLoading,
    setMakePayment,
    setMakePaymentErrorMsg,
    setClearMakePayment,
    setMakePaymentPayload,
    setClearMakePaymentPayload,
    setMakePaymentConfirm,
    setClearMakePaymentConfirm,

    setCreateUserCustomerPortalSessionLoading,
    setCreateUserCustomerPortalSessionData,
    setCreateUserCustomerPortalSessionMessage,
    resetCreateUserCustomerPortalSession,

    setCreateUserCustomerSessionLoading,
    setCreateUserCustomerSessionData,
    setCreateUserCustomerSessionMessage,
    resetCreateUserCustomerSession,

    setUserPaymentMethodListLoading,
    setUserPaymentMethodListData,
    setUserPaymentMethodListMessage,
    resetUserPaymentMethodList,

    setUserPaymentMethodDetailLoading,
    setUserPaymentMethodDetailPayload,
    setUserPaymentMethodDetailMessage,
    resetUserPaymentMethodDetail,

    setJoinUserPaymentMethodDetailLoading,
    setJoinUserPaymentMethodDetailPayload,
    setJoinUserPaymentMethodDetailMessage,
    resetJoinUserPaymentMethodDetail,

    setRemoveUserPaymentMethodDetailLoading,
    setRemoveUserPaymentMethodDetailMessage,
    resetRemoveUserPaymentMethodDetail,

    setCreateUserCheckoutSessionLoading,
    setCreateUserCheckoutSessionData,
    setCreateUserCheckoutSessionMessage,
    resetCreateUserCheckoutSession,

    setAddPaymentIntentDetailLoading,
    setAddPaymentIntentDetailData,
    setAddPaymentIntentDetailPayload,
    setAddPaymentIntentDetailMessage,
    resetAddPaymentIntentDetail,

    setPaymentIntentDetailLoading,
    setPaymentIntentDetailData,
    setPaymentIntentDetailMessage,
    resetPaymentIntentDetail,

    setAddTokenThroughWalletDetailData,
    setAddTokenThroughWalletDetailLoading,
    setAddTokenThroughWalletDetailMessage,
    resetAddTokenThroughWalletDetail
} = payment.actions

export default payment.reducer