import { toast } from "react-toastify";

import lmsArticleService from "redux/edulyteLms/lmsArticle/lmsArticle.service";

import {
    setLmsArticleListLoading,
    setLmsArticleListData,
    setLmsArticleListMessage,

    setLmsArticleDetailLoading,
    setLmsArticleDetailData,
    settLmsArticleDetailMessage,

    setAddLmsArticleDetailLoading,
    setAddLmsArticleDetailData,
    setAddLmsArticleDetailMessage,

    setUpdateLmsArticleDetailLoading,
    setUpdateLmsArticleDetailData,
    setUpdateLmsArticleDetailMessage,

    setDeleteLmsArticleDetailLoading,
    setDeleteLmsArticleDetailMessage,

    // lms article instructor
    setAddLmsArticleInstructorDetailLoading,
    setAddLmsArticleInstructorDetailData,
    setAddLmsArticleInstructorDetailMessage,

    setModifyLmsArticleInstructorDetailLoading,
    setModifyLmsArticleInstructorDetailData,
    setModifyLmsArticleInstructorDetailMessage,

    setDestroyLmsArticleInstructorDetailLoading,
    setDestroyLmsArticleInstructorDetailMessage,
} from "redux/edulyteLms/lmsArticle/lmsArticle.slice";

import { setLmsCourseDetailData } from "../lmsCourse/lmsCourse.slice";

export const getLmsArticleList = (query) => async (dispatch) => {
    dispatch(setLmsArticleListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await lmsArticleService.getLmsArticleList(requestData)
        if (response.status === 200) {
            dispatch(setLmsArticleListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setLmsArticleListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setLmsArticleListLoading(false))
    }
}

// lms article detail
export const getLmsArticleDetail = (articleId) => async (dispatch) => {
    dispatch(setLmsArticleDetailLoading(true))

    try {
        const requestData = {
            params: { articleId: articleId }
        }
        const response = await lmsArticleService.getLmsArticleDetail(requestData)
        if (response.status === 200) {
            dispatch(setLmsArticleDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(settLmsArticleDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setLmsArticleDetailLoading(false))
    }
}

// create lms article detail
export const createLmsArticleDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsArticleDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await lmsArticleService.createLmsArticleDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddLmsArticleDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsArticleDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsArticleDetailLoading(false))
    }
}

// update lms article detail
export const updateLmsArticle = (articleId, body, payload = { isShowToast: true }) => async (dispatch, getState) => {
    dispatch(setUpdateLmsArticleDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: { articleId: articleId },
            body: body
        }
        const response = await lmsArticleService.updateLmsArticle(requestData)
        if (response.status === 200) {
            dispatch(setUpdateLmsArticleDetailData(response.data.data))
            dispatch(setLmsArticleDetailData(response.data.data))
            if (payload?.sectionId && payload?.sectionLessonId) {
                dispatch(setLmsCourseDetailData({
                    ...lmsCourseDetail?.data,
                    course_sections: lmsCourseDetail?.data?.course_sections?.map((courseSection) => (
                        (courseSection?.id === payload?.sectionId)
                            ? {
                                ...courseSection, lms_course_section_lessons: courseSection?.lms_course_section_lessons?.map((courseSectionLesson) => (
                                    (courseSectionLesson?.id === payload?.sectionLessonId)
                                        ? { ...courseSectionLesson, article: { ...courseSectionLesson?.article, article_setting: { ...courseSectionLesson?.article?.article_setting, visibility: response?.data?.data?.article_setting?.visibility } } }
                                        : courseSectionLesson
                                ))
                            }
                            : courseSection
                    ))
                }))
            }
            if (payload?.isShowToast) {
                toast.success(response.data.message)
            }
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setUpdateLmsArticleDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setUpdateLmsArticleDetailLoading(false))
    }
}

// delete lms article detail
export const deleteLmsArticle = (articleId) => async (dispatch) => {
    dispatch(setDeleteLmsArticleDetailLoading(true))

    try {
        const requestData = {
            params: { articleId: articleId }
        }
        const response = await lmsArticleService.deleteLmsArticle(requestData)
        if (response.status === 204) {
            dispatch(getLmsArticleDetail(articleId))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDeleteLmsArticleDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setDeleteLmsArticleDetailLoading(false))
    }
}

// lms article settings
// lms article instructors
export const createLmsArticleInstructorDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsArticleInstructorDetailLoading(true))

    try {
        const { lmsArticleDetail } = getState().lms.lmsArticle
        const requestData = {
            body: body
        }

        const response = await lmsArticleService.createLmsArticleInstructorDetail(requestData)
        if ((response.status === 200) || (response.status === 201)) {
            dispatch(setAddLmsArticleInstructorDetailData(response.data.data))
            dispatch(setLmsArticleDetailData({
                ...lmsArticleDetail?.data,
                article_users: [...lmsArticleDetail?.data?.article_users, response.data.data]
            }))
            toast.success(response.data.message || "Created Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsArticleInstructorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsArticleInstructorDetailLoading(false))
    }
}

// update lms course instructor detail
export const updateLmsArticleInstructorDetail = (articleInstructorId, body) => async (dispatch, getState) => {
    dispatch(setModifyLmsArticleInstructorDetailLoading(true))

    try {
        const { lmsArticleDetail } = getState().lms.lmsArticle
        const requestData = {
            params: { articleInstructorId: articleInstructorId },
            body: body
        }
        const response = await lmsArticleService.updateLmsArticleInstructorDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyLmsArticleInstructorDetailData(response.data.data))
            dispatch(setLmsArticleDetailData({
                ...lmsArticleDetail?.data,
                article_users: lmsArticleDetail?.data?.article_users?.map((articleTutor) => (
                    (articleTutor?.id === response.data.data.id) ? response.data.data : articleTutor
                ))
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyLmsArticleInstructorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyLmsArticleInstructorDetailLoading(false))
    }
}
// delete lms course instructor detail
export const deleteLmsArticleInstructorDetail = (articleInstructorId) => async (dispatch, getState) => {
    dispatch(setDestroyLmsArticleInstructorDetailLoading(true))

    try {
        const { lmsArticleDetail } = getState().lms.lmsArticle
        const requestData = {
            params: { articleInstructorId: articleInstructorId }
        }
        const response = await lmsArticleService.deleteLmsArticleInstructorDetail(requestData)
        if (response.status === 204) {
            dispatch(setLmsArticleDetailData({
                ...lmsArticleDetail?.data,
                article_users: lmsArticleDetail?.data?.article_users?.filter((articleTutor) => articleTutor?.id != articleInstructorId)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.message || "Something went wrong!")
        dispatch(setDestroyLmsArticleInstructorDetailMessage(error.response.data.message || error.response.data.message || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.message || "Something went wrong!")
    } finally {
        dispatch(setDestroyLmsArticleInstructorDetailLoading(false))
    }
}