import { useEffect } from 'react';
import { Outlet } from "react-router-dom";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const EdulyteLMS = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.EDULYTE_LMS))
  }, [dispatcher, currentPageInfo])

  return (
    <div className="relative h-full w-full p-3 mx-auto min-h-screen space-y-3 overflow-hidden">
      <div className="w-full rounded-lg">
        <Outlet />
      </div>
    </div>
  );
}

export default EdulyteLMS;