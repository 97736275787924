import { userRoles } from "redux/auth/auth.const";

export const pageHeading = {
    heading: "FileBox",
    subHeading: "Find all your uploaded files and recordings",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/filebox.png"
}

export const myDriveFilesHeaders = {
    FILE_NAME: {
        label: "File Name",
        value: "file_name",
        icon: null
    },
    MEMBERS: {
        label: "Members",
        value: "members",
        icon: null
    },
    FILE_SIZE: {
        label: "File Size",
        value: "file_size",
        icon: null
    },
    LAST_MODIFIED: {
        label: "Last Modified",
        value: "last_modified",
        icon: null
    },
    ACTIONS: {
        label: "Actions",
        value: "actions",
        icon: null
    }
}

export const formatFileSize = (fileSize) => {
    if (fileSize < 1024) {
        return `${(fileSize).toFixed(2)} B`
    } else if (fileSize < 1024 * 1024) {
        return `${(fileSize / 1024).toFixed(2)} KB`
    } else if (fileSize < 1024 * 1024 * 1024) {
        return `${((fileSize / (1024 * 1024)).toFixed(2))} MB`
    } else {
        return `${fileSize / (1024 * 1024 * 1024).toFixed(2)} GB`
    }
}

export const menuItemsConst = {
    VIEW: {
        label: "View",
        value: "view"
    },
    // DOWNLOAD: {
    //     label: "Download",
    //     value: "download"
    // },
    SHARE: {
        label: "Share",
        value: "share"
    },
    // MOVE: {
    //     label: "Move",
    //     value: "move"
    // }
}


export const fileExtensionConst = {
    MP4: {
        label: "mp4",
        value: "mp4",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },

    PNG: {
        label: "Png",
        value: "png",
        lightColor: "red-100",
        darkColor: "red-500"
    },

    PDF: {
        label: "Pdf",
        value: "pdf",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    DOCX: {
        label: "Docx",
        value: "docx",
        lightColor: "orange-100",
        darkColor: "orange-500"
    },
    DOC: {
        label: "Doc",
        value: "doc",
        lightColor: "purple-100",
        darkColor: "purple-500"
    },
    TXT: {
        label: "Txt",
        value: 'txt',
        lightColor: "pink-100",
        darkColor: "pink-500"
    },
    JPG: {
        label: "Jpg",
        value: "jpg",
        lightColor: "green-100",
        darkColor: "green-500"
    }
}


export const navigationList = [
    { label: "My Files", to: "/my-files", roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },
    { label: "Recorded Sessions", to: "/recording", roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },
    { label: "Shared with me", to: '/share-with-me', roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },

];