import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ImCheckmark } from 'react-icons/im';

import FullPageLoader from "components/loader/FullPageLoader";

import { PageContentStyle } from "./BookingSummary.style";
import BookingCard from 'pages/auth/bookingSummary/bookingCard/BookingCard';
import BookingCheckoutCard from 'pages/auth/bookingSummary/bookingCheckout/BookingCheckoutCard';

import { setPageInfo } from "redux/pageInfo/pageInfo.request";
import { getUserPreBookingDetail } from "redux/booking/booking.request";

import { useTitle } from "hooks/useTitle";
import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearTutorPublicProfile } from "redux/tutor/tutor.slice";
import { resetUserBookingDetail } from "redux/booking/booking.slice";

import { pagesInfo } from "utils/pagesInfo";
import { timeZone } from "utils/dateTime.utils";

const BookingSummaryPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)
    const { userPreBookingDetail } = useAppState((state) => state.booking)

    const dispatcher = useAppDispatcher()
    const [title, setTitle] = useTitle()
    const { bookingId } = useParams()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.BOOKING_SUMMARY))
    }, [dispatcher, currentPageInfo])


    useEffect(() => {
        if (userPreBookingDetail?.data) {
            setTitle({
                ...title
            })
        }
    }, [userPreBookingDetail?.data])

    useEffect(() => {
        if (bookingId) {
            dispatcher(getUserPreBookingDetail(bookingId, user?.user?.userId, { timeZone: timeZone }))
        }

        return () => {
            dispatcher(resetUserBookingDetail())
            dispatcher(setClearTutorPublicProfile())
        }
    }, [bookingId])

    if (userPreBookingDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={userPreBookingDetail?.isLoading} />
        )
    }

    if (userPreBookingDetail?.message) {
        return (
            <PageContentStyle>
                <div className={"w-screen h-screen flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                    {userPreBookingDetail?.message ? "Sorry, Booking not found." : ""}
                </div>
            </PageContentStyle>
        )
    }

    return userPreBookingDetail?.data && (
        <PageContentStyle>
            <div className={"w-full h-full lg:min-h-screen bg-gradient-to-bl from-secondary-main/30 via-primary-main/30 to-primary-main/40"}>
                <div className={"px-5 py-10 space-y-5 mx-auto container md:px-0"}>
                    <div className={"flex items-center justify-center"}>
                        <div className="w-full md:w-[60rem] p-5 bg-white rounded-lg flex items-center justify-center gap-5 cursor-pointer">
                            <span className={"flex items-center justify-center rounded-full p-3 bg-complementary-main"}>
                                <ImCheckmark className={"text-2xl text-text-50"} />
                            </span>
                            <span className={"pr-0 md:pr-28 font-bodyPri font-semibold text-primary-dark text-2xl whitespace-nowrap"}>
                                {"Confirm & Pay"}
                            </span>
                        </div>
                    </div>
                    <div className={"flex flex-col-reverse items-center justify-center md:flex-row gap-8"}>
                        <BookingCard />
                        <BookingCheckoutCard />
                    </div>

                </div>
            </div>
        </PageContentStyle>
    )
}

export default BookingSummaryPage;