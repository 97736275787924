import { useCallback } from 'react';

import { cn } from "utils/cn.utils";

import { Toggle } from 'components/common-components/Toggle';

import { updateLmsQuizDetail } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { lmsQuizStatusEnum, lmsQuizVisibilityEnum, lmsQuizCheckoutEnum } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';

const QuizGeneralSettings = () => {
    const { lmsQuizDetail } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()

    const onHandleChangeSetting = useCallback((key, value) => {
        const body = {
            [key]: value
        }
        dispatcher(updateLmsQuizDetail(lmsQuizDetail?.data?.id, body))
    }, [lmsQuizDetail?.data])

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <div className={"w-full px-3 py-2 bg-divider-medium rounded-lg"}>
                <span className={"font-bodyPri font-bold text-text-800 text-lg tracking-wide"}>
                    {"General Settings"}
                </span>
            </div>

            <div className={"w-full flex flex-col gap-3 px-5"}>
                {/* <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                    <div className={"col-span-8 order-1 lg:col-span-7 w-full flex flex-col items-start justify-start"}>
                        <p className={"font-bodyPri font-medium text-text-700 text-base tracking-wide"}>
                            {"Share this quiz with other instructors."}
                        </p>
                        <p className={"font-bodyPri font-normal text-text-500 text-sm"}>
                            {"add description here."}
                        </p>
                    </div>
                    <div className={"col-span-full order-3 lg:order-2 lg:col-span-5 justify-self-stretch"}>
                        <Toggle
                            value={(lmsQuizDetail?.data?.quiz_setting?.shareable === lmsQuizShareableEnum.YES.value) ? true : false}
                            onChange={(v) => onHandleChangeSetting("shareable", v ? lmsQuizShareableEnum.YES.value : lmsQuizShareableEnum.NO.value)}
                        />
                    </div>
                </div> */}
                <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                    <span className={"col-span-8 order-1 lg:col-span-7 w-full flex items-center justify-start lg:justify-between font-bodyPri font-medium text-text-700 text-base tracking-wide"}>
                        <span>{"Make Quiz Visible for"}</span>
                        <span>{":"}</span>
                    </span>

                    <div className={"col-span-full order-3 lg:order-2 lg:col-span-5 justify-self-stretch flex items-center justify-start gap-5"}>
                        {Object.values(lmsQuizVisibilityEnum)?.map((visibility, index) => (
                            <div
                                key={index}
                                className={"flex items-center justify-start gap-2 cursor-pointer"}
                                onClick={() => onHandleChangeSetting("visibility", visibility?.value)}
                            >
                                <input
                                    type={"radio"}
                                    className={""}
                                    value={visibility?.value}
                                    checked={visibility?.value === lmsQuizDetail?.data?.quiz_setting?.visibility}
                                />
                                <span className={"font-bodyPri font-normal text-text-900 text-md tracking-wide"}>
                                    {visibility?.label}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                    <span className={"col-span-8 order-1 lg:col-span-7 w-full flex items-center justify-start lg:justify-between font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                        <span>{"Status"}</span>
                        <span>{":"}</span>
                    </span>
                    <span className={cn(
                        "col-span-full order-3 lg:order-2 lg:col-span-5 justify-self-stretch",
                        `font-bodyPri font-semibold text-base tracking-wide text-${lmsQuizStatusEnum[lmsQuizDetail?.data?.quiz_setting?.status?.toUpperCase()]?.darkColor}`
                    )}>
                        {lmsQuizStatusEnum[lmsQuizDetail?.data?.quiz_setting?.status?.toUpperCase()]?.label}
                    </span>
                </div>

                <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                    <span className={"col-span-8 order-1 lg:col-span-7 w-full flex items-center justify-start lg:justify-between font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                        <span>{"Created At"}</span>
                        <span>{":"}</span>
                    </span>
                    <span className={"col-span-full order-3 lg:order-2 lg:col-span-5 justify-self-stretch font-bodyPri font-normal text-text-900 text-md tracking-wide"}>
                        {dayjs(lmsQuizDetail?.data?.quiz_setting?.createdAt)?.tz(timeZone)?.format("ddd, MMM D, YY h:mm A")}
                    </span>
                </div>

                <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                    <span className={"col-span-8 order-1 lg:col-span-7 w-full flex items-center justify-start lg:justify-between font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                        <span>{"Updated At"}</span>
                        <span>{":"}</span>
                    </span>
                    <span className={"col-span-full order-3 lg:order-2 lg:col-span-5 justify-self-stretch font-bodyPri font-normal text-text-900 text-md tracking-wide"}>
                        {dayjs(lmsQuizDetail?.data?.quiz_setting?.updatedAt)?.tz(timeZone)?.format("ddd, MMM D, YY h:mm A")}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default QuizGeneralSettings;