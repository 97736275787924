import { createSlice } from "@reduxjs/toolkit";
import { LINK_TREE_INITIAL_STATE } from "./linkTree.initialState";

const linkInBio = createSlice({
    name: "linkInBio",
    initialState: LINK_TREE_INITIAL_STATE,
    reducers: {
        setAddLinkInBioLoading: (state, { payload }) => {
            state.createLinkTree.isLoading = payload
        },
        setAddLinkInBioData: (state, { payload }) => {
            state.createLinkTree.data = payload
        },
        seAddLinkInBioErrorMsg: (state, { payload }) => {
            state.createLinkTree.errorMsg = payload
        },
        setClearAddLinkInBio: (state) => {
            state.createLinkTree = LINK_TREE_INITIAL_STATE.createLinkTree
        },

        setUpdateLinkLoading: (state, { payload }) => {
            state.updateLink.isLoading = payload
        },
        setUpdateLinkData: (state, { payload }) => {
            state.updateLink.data = payload
        },
        seUpdateLinkErrorMsg: (state, { payload }) => {
            state.updateLink.errorMsg = payload
        },
        setClearUpdateLink: (state) => {
            state.updateLink = LINK_TREE_INITIAL_STATE.linkInBio
        },

        setDeleteLinkLoading: (state, { payload }) => {
            state.deleteLink.isLoading = payload
        },
        seDeleteLinkErrorMsg: (state, { payload }) => {
            state.deleteLink.errorMsg = payload
        },
        setClearDeleteLink: (state) => {
            state.deleteLink = LINK_TREE_INITIAL_STATE.linkInBio
        },

        setAddLinkLoading: (state, { payload }) => {
            state.addLink.isLoading = payload
        },
        setAddLinkData: (state, { payload }) => {
            state.addLink.data = payload
        },
        seAddLinkErrorMsg: (state, { payload }) => {
            state.addLink.errorMsg = payload
        },
        setClearAddLink: (state) => {
            state.addLink = LINK_TREE_INITIAL_STATE.linkInBio
        },

        setReorderLinkLoading: (state, { payload }) => {
            state.reorderLink.isLoading = payload
        },
        seReorderLinkErrorMsg: (state, { payload }) => {
            state.reorderLink.errorMsg = payload
        },
        setClearReorderLink: (state) => {
            state.reorderLink = LINK_TREE_INITIAL_STATE.linkInBio
        },
    }
})

export const {
    setAddLinkInBioLoading,
    setAddLinkInBioData,
    seAddLinkInBioErrorMsg,
    setClearAddLinkInBio,

    setUpdateLinkLoading,
    setUpdateLinkData,
    seUpdateLinkErrorMsg,
    setClearUpdateLink,

    setDeleteLinkLoading,
    seDeleteLinkErrorMsg,
    setClearDeleteLink,

    setAddLinkLoading,
    setAddLinkData,
    seAddLinkErrorMsg,
    setClearAddLink,

    setReorderLinkLoading,
    seReorderLinkErrorMsg,
    setClearReorderLink,
} = linkInBio.actions

export default linkInBio.reducer