import { useState } from "react";
import { cn } from "utils/cn.utils";

import { FiEdit } from 'react-icons/fi';
import { FaInfoCircle } from 'react-icons/fa';

import { profileEditBtn } from 'pages/auth/profile/data';

import SaveAndCancelBtn from 'pages/auth/profile/commonComponents/SaveAndCancelBtn';

import { updateStudentUserDetailByUserId } from 'redux/student/student.request';
import { updateTutorUserDetailByUserId } from 'redux/tutor/tutor.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { userRoles } from 'redux/auth/auth.const';

const MIN_CHARACTER_LENGTH = 50
const MAX_CHARACTER_LENGTH = 700


const About = ({ editBtnConst, setEditBtnConst }) => {
  const { locals } = useAppState((state) => state.local);
  const { user } = useAppState((state) => state.user);
  const { studentUserDetail } = useAppState((state) => state.student);
  const { tutorUserDetail } = useAppState((state) => state.tutor);

  const dispatcher = useAppDispatcher()

  const [inputBio, setInputBio] = useState("");

  const onHandleSave = (key, value) => {
    if (!(inputBio.length >= MIN_CHARACTER_LENGTH && inputBio.length <= MAX_CHARACTER_LENGTH)) {
      alert("Please enter between 50 to 700 characters")
      return;
    }

    if (locals.userRole === userRoles.STUDENT.value) {
      dispatcher(updateStudentUserDetailByUserId(user?.user?.userId, { [key]: value }))
    } else if (locals.userRole === userRoles.TUTOR.value) {
      dispatcher(updateTutorUserDetailByUserId(user?.user?.userId, { [key]: value }))
    }
    setEditBtnConst(null)
  }

  return (
    <div className="">
      <div className="flex items-center gap-2">
        <div className={"relative has-tooltip cursor-pointer flex justify-end"}>
          <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
          <span
            className={cn(
              "w-56 px-2 py-0.5 font-bodyPri font-normal text-xs rounded shadow-lg bg-text-900 text-text-50 tooltip -top-20 left-1",
              "flex flex-col items-start justify-start overflow-hidden"
            )}
          >
            {"Include your compelling / aspirational story or background, what you teach and why in the About me field, as your potential learners will keenly go through it."}
          </span>
        </div>
        <div className="text-xl text-text-900 font-medium font-bodyPri tracking-wide">
          {" About Me:"}
        </div>
        {!editBtnConst &&
          <FiEdit className="text-lg text-text-500 cursor-pointer"
            onClick={() => {
              if (locals.userRole === userRoles.STUDENT.value) {
                setInputBio(studentUserDetail?.studentUserDetail?.bio?.slice(0, 700) || "")
              } else if (locals.userRole === userRoles.TUTOR.value) {
                setInputBio(tutorUserDetail?.tutorUserDetail?.bio?.slice(0, 700) || "")
              }
              setEditBtnConst(profileEditBtn.bio.value)
            }}
          />
        }
      </div>

      <div className="w-full">
        {editBtnConst !== profileEditBtn.bio.value &&
          <div className="px-5 py-2">
            {locals.userRole === userRoles.STUDENT.value &&
              <span className="font-bodyPri font-normal tracking-wide text-text-800 text-base">
                {studentUserDetail?.studentUserDetail?.bio?.slice(0, 700)?.toString() || ""}
              </span>
            }
            {locals.userRole === userRoles.TUTOR.value &&
              <span className="font-bodyPri font-normal tracking-wide text-text-800 text-base">
                {tutorUserDetail?.tutorUserDetail?.bio?.slice(0, 700)?.toString() || ""}
              </span>
            }
          </div>
        }
        {editBtnConst === profileEditBtn.bio.value &&
          <div className="w-full flex flex-col items-start justify-start gap-2">
            <div className="w-full flex flex-col justify-center gap-1">
              <textarea
                className="w-full p-5 border-2 border-secondary-main border-dashed focus:outline-none font-bodyPri font-normal text-lg text-text-600 tracking-wide"
                rows={5}
                minLength={50}
                maxLength={700}
                value={inputBio}
                onChange={(event) => setInputBio(event.target.value)}
              />
            </div>
            <div className={"w-full flex justify-between"}>
              <span className="font-bodyPri font-normal tracking-wide text-red-500 text-xs">
                {"Please enter between 50 and 700 characters"}
              </span>
              <span className="flex justify-end font-bodyPri font-normal text-text-800 text-xs">
                {inputBio.length + "/" + MAX_CHARACTER_LENGTH}
              </span>
            </div>
            <SaveAndCancelBtn
              onCancel={() => setEditBtnConst(null)}
              onSave={() => onHandleSave(editBtnConst, inputBio)}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default About;

{/* <TextEditor aboutMe={preference?.preference?.profilePref?.bio} handleSubmitAboutMe={props.handleSubmitAboutMe} /> */ }
