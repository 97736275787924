import { useState, useEffect, useCallback, useMemo } from "react";
import { toast } from "react-toastify";

import { AnimatePresence } from "framer-motion";

import ComponentLoader from "components/loader/ComponentLoader";

// make this component common later
import RecurringAvailabilityCard from "pages/auth/preferences/commonComponents/availabilityRecurring/RecurringAvailabilityCard";

import { setUserAvailabilityDetailPayload, validateAvailabilityDetail, validateUserAvailabilityDetail } from "pages/auth/onboarding/data";
import SaveAndResetBtn from "pages/auth/onboarding/commonComponents/ctaButtons/SaveAndResetBtn";
import BasicInformation from "./components/BasicInformation";
import NonRecurringSchedule from "./components/NonRecurringSchedule";
import MoreAvailabilityDetail from "./components/MoreAvailabilityDetail";
import StartEndDateSchedule from "./components/StartEndDateSchedule";

import { getUserAppointmentDetail } from "redux/appointment/appointment.request";
import { getUserAvailabilityDetail, updateUserAvailabilityDetail } from "redux/availability/availability.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetUserAvailabilityDetail, setModifyUserAvailabilityDetailPayload } from "redux/availability/availability.slice";
import { resetUserAppointmentDetail } from "redux/appointment/appointment.slice";
import { availabilityIsRecurringEnum, modifyUserAvailabilityDetailPayload } from "redux/availability/availability.const";

import { dayjs, weekdays, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';

export default function WeeklySchedule({ setEditBtnConst }) {
    const { user } = useAppState((state) => state.user)
    const { userAppointmentDetail } = useAppState((state) => state.appointment)
    const { userAvailabilityDetail, modifyUserAvailabilityDetail } = useAppState((s) => s.availability)

    const dispatcher = useAppDispatcher()

    const [current, setCurrent] = useState(dayjs().tz(timeZone))

    const isSimilarOrValidAvailability = useMemo(() => {
        if (userAvailabilityDetail?.data?.result && modifyUserAvailabilityDetail?.payload) {
            const isDataSimilar = validateAvailabilityDetail(userAvailabilityDetail?.data?.result, modifyUserAvailabilityDetail?.payload)
            return isDataSimilar;
        }
    }, [userAvailabilityDetail?.data?.result, modifyUserAvailabilityDetail?.payload])

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrent(dayjs().tz(timeZone))
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    useEffect(() => {
        if (user?.user?.userId) {
            dispatcher(getUserAppointmentDetail(user?.user?.appointment_id, user?.user?.userId))
        }

        return () => {
            dispatcher(resetUserAppointmentDetail())
        }
    }, [user?.user])

    useEffect(() => {
        if (userAppointmentDetail?.data?.result?.appointment_availability?.availability?.id) {
            dispatcher(getUserAvailabilityDetail(userAppointmentDetail?.data?.result?.appointment_availability?.availability?.id, user?.user?.userId, { timeZone: timeZone }))
        }

        return () => {
            dispatcher(resetUserAvailabilityDetail())
        }
    }, [userAppointmentDetail?.data])

    useEffect(() => {
        if (userAvailabilityDetail?.data?.result) {
            setUserAvailabilityDetail()
        }
    }, [userAvailabilityDetail?.data?.result])

    const setUserAvailabilityDetail = async () => {
        const requestPayload = await setUserAvailabilityDetailPayload(userAvailabilityDetail?.data?.result)

        dispatcher(setModifyUserAvailabilityDetailPayload({
            ...modifyUserAvailabilityDetailPayload,
            ...requestPayload
        }))
    }

    const saveWeeklyAvail = async (localWeeklyAvail) => {
        if (modifyUserAvailabilityDetail?.isLoading) return;

        dispatcher(setModifyUserAvailabilityDetailPayload({
            ...modifyUserAvailabilityDetail?.payload,
            weeklyTimeSlots: localWeeklyAvail
        }))
    }

    const onHandleResetAvailabilityDetail = useCallback(() => {
        if (modifyUserAvailabilityDetail?.isLoading) return;

        dispatcher(getUserAvailabilityDetail(userAppointmentDetail?.data?.result?.appointment_availability?.availability?.id, user?.user?.userId, { timeZone: timeZone }))
        setEditBtnConst(null)
    }, [modifyUserAvailabilityDetail?.isLoading, user?.user, userAppointmentDetail?.data?.result])

    const onHandleSaveAvailabilityDetail = async () => {
        if (modifyUserAvailabilityDetail?.isLoading || isSimilarOrValidAvailability) return;

        const { requestPayload, errorMsg } = await validateUserAvailabilityDetail(modifyUserAvailabilityDetail?.payload)

        if (errorMsg) {
            toast.warn(errorMsg)
            return;
        } if (requestPayload) {
            dispatcher(updateUserAvailabilityDetail(userAvailabilityDetail?.data?.result?.id, { ...requestPayload }))
        }
        setEditBtnConst(null)
    }

    if (userAppointmentDetail?.isLoading || userAvailabilityDetail?.isLoading) {
        return (
            <ComponentLoader isLoading={userAppointmentDetail?.isLoading || userAvailabilityDetail?.isLoading} className={"h-60"} />
        )
    }

    return (
        <div className="w-full flex flex-col mx-auto container gap-5 sm:gap-10 py-3">
            <div className="space-y-2">
                <p className="font-bodyPri font-normal text-sm text-text-700 text-right">
                    {current.format("ddd, DD MMM YY hh:mm:ss A")} - [{timeZone} ({getTimeZoneOffset(timeZone)})]
                </p>
                <div className="h-0.5 bg-gray-300 w-full"></div>
            </div>
            {userAvailabilityDetail?.message &&
                <div className={"flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-base"}>
                        {userAvailabilityDetail?.message}
                    </span>
                </div>
            }
            {(!userAvailabilityDetail?.isLoading && userAvailabilityDetail?.data) &&
                <div className={"w-full flex flex-col mx-auto container max-w-6xl gap-5 sm:gap-10"}>
                    <BasicInformation />
                    <div className={"h-0.5 bg-divider-medium w-full"}></div>

                    <div className={"w-full flex flex-col gap-10"}>
                        {([availabilityIsRecurringEnum.RECURRING.value]?.includes(modifyUserAvailabilityDetail?.payload?.isRecurring) && modifyUserAvailabilityDetail?.payload?.weeklyTimeSlots) &&
                            <>
                                {weekdays.map((day, index) => (
                                    <RecurringAvailabilityCard
                                        key={index}
                                        localDay={day}
                                        weeklyAvailability={modifyUserAvailabilityDetail?.payload?.weeklyTimeSlots}
                                        saveWeeklyAvail={saveWeeklyAvail}
                                    />
                                ))}
                                <div className={"h-0.5 bg-divider-medium w-full"}></div>

                                <StartEndDateSchedule />
                            </>
                        }
                        {[availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(modifyUserAvailabilityDetail?.payload?.isRecurring) &&
                            <NonRecurringSchedule />
                        }

                        <div className={"h-0.5 bg-divider-medium w-full"}></div>
                        <MoreAvailabilityDetail />
                    </div>
                    {(!userAvailabilityDetail?.isLoading && userAvailabilityDetail?.message) &&
                        <div className={"flex items-center justify-center"}>
                            <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                                {userAvailabilityDetail?.message || "No Availability Found!"}
                            </span>
                        </div>
                    }
                    <SaveAndResetBtn
                        isSaveBtnLoading={modifyUserAvailabilityDetail?.isLoading}
                        isResetBtnLoading={userAvailabilityDetail?.isLoading}
                        saveBtnDisabled={isSimilarOrValidAvailability}
                        resetBtnDisabled={modifyUserAvailabilityDetail?.isLoading || userAvailabilityDetail?.isLoading}
                        onHandleSave={onHandleSaveAvailabilityDetail}
                        onHandleReset={onHandleResetAvailabilityDetail}
                    />
                </div>
            }
        </div>
    );
}