import { createSlice } from "@reduxjs/toolkit";

import { CRM_INITIAL_STATE } from "./crm.initialState";

const crm = createSlice({
    name: "crm",
    initialState: CRM_INITIAL_STATE,
    reducers: {
        // crm contact list
        setCrmContactListLoading: (state, { payload }) => {
            state.crmContactList.isLoading = payload
        },
        setCrmContactListData: (state, { payload }) => {
            state.crmContactList.data = payload
            state.crmContactList.message = CRM_INITIAL_STATE.crmContactList.message
        },
        setCrmContactListMessage: (state, { payload }) => {
            state.crmContactList.message = payload
            state.crmContactList.data = CRM_INITIAL_STATE.crmContactList.data
        },
        resetCrmContactList: (state) => {
            state.crmContactList = CRM_INITIAL_STATE.crmContactList
        },

        // crm lead list
        setCrmLeadListLoading: (state, { payload }) => {
            state.crmLeadList.isLoading = payload
        },
        setCrmLeadListData: (state, { payload }) => {
            state.crmLeadList.data = payload
            state.crmLeadList.message = CRM_INITIAL_STATE.crmLeadList.message
        },
        setCrmLeadListMessage: (state, { payload }) => {
            state.crmLeadList.message = payload
            state.crmLeadList.data = CRM_INITIAL_STATE.crmLeadList.data
        },
        resetCrmLeadList: (state) => {
            state.crmLeadList = CRM_INITIAL_STATE.crmLeadList
        },

        // modify crm bulk contact list
        setModifyCrmBulkContactListLoading: (state, { payload }) => {
            state.modifyCrmBulkContactList.isLoading = payload
        },
        setModifyCrmBulkContactListData: (state, { payload }) => {
            state.modifyCrmBulkContactList.data = payload
            state.modifyCrmBulkContactList.message = CRM_INITIAL_STATE.modifyCrmBulkContactList.message
        },
        setModifyCrmBulkContactListMessage: (state, { payload }) => {
            state.modifyCrmBulkContactList.message = payload
            state.modifyCrmBulkContactList.data = CRM_INITIAL_STATE.modifyCrmBulkContactList.data
        },
        resetModifyCrmBulkContactList: (state) => {
            state.modifyCrmBulkContactList = CRM_INITIAL_STATE.modifyCrmBulkContactList
        },

        // modify crm bulk contact list payload
        setModifyCrmBulkContactListPayload: (state, { payload }) => {
            state.modifyCrmBulkContactList.payload = payload
        },
        resetModifyCrmBulkContactListPayload: (state) => {
            state.modifyCrmBulkContactList.payload = CRM_INITIAL_STATE.modifyCrmBulkContactList.payload
        },

        // destroy crm bulk contact list
        setDestroyCrmBulkContactListLoading: (state, { payload }) => {
            state.destroyCrmBulkContactList.isLoading = payload
        },
        setDestroyCrmBulkContactListData: (state, { payload }) => {
            state.destroyCrmBulkContactList.data = payload
            state.destroyCrmBulkContactList.message = CRM_INITIAL_STATE.destroyCrmBulkContactList.message
        },
        setDestroyCrmBulkContactListMessage: (state, { payload }) => {
            state.destroyCrmBulkContactList.message = payload
            state.destroyCrmBulkContactList.data = CRM_INITIAL_STATE.destroyCrmBulkContactList.data
        },
        resetDestroyCrmBulkContactList: (state) => {
            state.destroyCrmBulkContactList = CRM_INITIAL_STATE.destroyCrmBulkContactList
        },

        // crm contact detail
        setCrmContactDetailLoading: (state, { payload }) => {
            state.crmContactDetail.isLoading = payload
        },
        setCrmContactDetailData: (state, { payload }) => {
            state.crmContactDetail.data = payload
            state.crmContactDetail.message = CRM_INITIAL_STATE.crmContactDetail.message
        },
        setCrmContactDetailMessage: (state, { payload }) => {
            state.crmContactDetail.message = payload
            state.crmContactDetail.data = CRM_INITIAL_STATE.crmContactDetail.data
        },
        resetCrmContactDetail: (state) => {
            state.crmContactDetail = CRM_INITIAL_STATE.crmContactDetail
        },

        // add crm contact detail
        setAddCrmContactDetailLoading: (state, { payload }) => {
            state.addCrmContactDetail.isLoading = payload
        },
        setAddCrmContactDetailData: (state, { payload }) => {
            state.addCrmContactDetail.data = payload
            state.addCrmContactDetail.message = CRM_INITIAL_STATE.addCrmContactDetail.message
        },
        setAddCrmContactDetailMessage: (state, { payload }) => {
            state.addCrmContactDetail.message = payload
            state.addCrmContactDetail.data = CRM_INITIAL_STATE.addCrmContactDetail.data
        },
        resetAddCrmContactDetail: (state) => {
            state.addCrmContactDetail = CRM_INITIAL_STATE.addCrmContactDetail
        },

        // add crm contact detail payload
        setAddCrmContactDetailPayload: (state, { payload }) => {
            state.addCrmContactDetail.payload = payload
        },
        resetAddCrmContactDetailPayload: (state) => {
            state.addCrmContactDetail.payload = CRM_INITIAL_STATE.addCrmContactDetail.payload
        },

        // modify crm contact detail
        setModifyCrmContactDetailLoading: (state, { payload }) => {
            state.modifyCrmContactDetail.isLoading = payload
        },
        setModifyCrmContactDetailData: (state, { payload }) => {
            state.modifyCrmContactDetail.data = payload
            state.modifyCrmContactDetail.message = CRM_INITIAL_STATE.modifyCrmContactDetail.message
        },
        setModifyCrmContactDetailMessage: (state, { payload }) => {
            state.modifyCrmContactDetail.message = payload
            state.modifyCrmContactDetail.data = CRM_INITIAL_STATE.modifyCrmContactDetail.data
        },
        resetModifyCrmContactDetail: (state, { payload }) => {
            state.modifyCrmContactDetail = CRM_INITIAL_STATE.modifyCrmContactDetail
        },

        // modify crm contact detail payload
        setModifyCrmContactDetailPayload: (state, { payload }) => {
            state.modifyCrmContactDetail.payload = payload
        },
        resetModifyCrmContactDetailPayload: (state) => {
            state.modifyCrmContactDetail.payload = CRM_INITIAL_STATE.modifyCrmContactDetail.payload
        },

        // destroy crm contact detail
        setDestroyCrmContactDetailLoading: (state, { payload }) => {
            state.destroyCrmContactDetail.isLoading = payload
        },
        setDestroyCrmContactDetailData: (state, { payload }) => {
            state.destroyCrmContactDetail.data = payload
            state.destroyCrmContactDetail.message = CRM_INITIAL_STATE.destroyCrmContactDetail.message
        },
        setDestroyCrmContactDetailMessage: (state) => {
            state.destroyCrmContactDetail.message = CRM_INITIAL_STATE.destroyCrmContactDetail.message
        },
        resetDestroyCrmContactDetail: (state) => {
            state.destroyCrmContactDetail = CRM_INITIAL_STATE.destroyCrmContactDetail
        },

        // crm contact status list
        setCrmContactStatusListLoading: (state, { payload }) => {
            state.crmContactStatusList.isLoading = payload
        },
        setCrmContactStatusListData: (state, { payload }) => {
            state.crmContactStatusList.data = payload
            state.crmContactStatusList.message = CRM_INITIAL_STATE.crmContactStatusList.message
        },
        setCrmContactStatusListMessage: (state, { payload }) => {
            state.crmContactStatusList.message = payload
            state.crmContactStatusList.data = CRM_INITIAL_STATE.crmContactStatusList.data
        },
        resetCrmContactStatusList: (state) => {
            state.crmContactStatusList = CRM_INITIAL_STATE.crmContactStatusList
        },

        // crm modal contact status list
        setCrmModalContactStatusListLoading: (state, { payload }) => {
            state.crmModalContactStatusList.isLoading = payload
        },
        setCrmModalContactStatusListData: (state, { payload }) => {
            state.crmModalContactStatusList.data = payload
            state.crmModalContactStatusList.message = CRM_INITIAL_STATE.crmModalContactStatusList.message
        },
        setCrmModalContactStatusListMessage: (state, { payload }) => {
            state.crmModalContactStatusList.message = payload
            state.crmModalContactStatusList.data = CRM_INITIAL_STATE.crmModalContactStatusList.data
        },
        resetCrmModalContactStatusList: (state) => {
            state.crmModalContactStatusList = CRM_INITIAL_STATE.crmModalContactStatusList
        },

        // crm contact label list
        setCrmContactLabelListLoading: (state, { payload }) => {
            state.crmContactLabelList.isLoading = payload
        },
        setCrmContactLabelListData: (state, { payload }) => {
            state.crmContactLabelList.data = payload
            state.crmContactLabelList.message = CRM_INITIAL_STATE.crmContactLabelList.message
        },
        setCrmContactLabelListMessage: (state, { payload }) => {
            state.crmContactLabelList.message = payload
            state.crmContactLabelList.data = CRM_INITIAL_STATE.crmContactLabelList.data
        },
        resetCrmContactLabelList: (state) => {
            state.crmContactLabelList = CRM_INITIAL_STATE.crmContactLabelList
        },

        // create crm contact label
        setAddCrmContactLabelDetailLoading: (state, { payload }) => {
            state.addCrmContactLabelDetail.isLoading = payload
        },
        setAddCrmContactLabelDetailData: (state, { payload }) => {
            state.addCrmContactLabelDetail.data = payload
            state.addCrmContactLabelDetail.message = CRM_INITIAL_STATE.addCrmContactLabelDetail.message
        },
        setAddCrmContactLabelDetailMessage: (state, { payload }) => {
            state.addCrmContactLabelDetail.message = payload
            state.addCrmContactLabelDetail.data = CRM_INITIAL_STATE.addCrmContactLabelDetail.data
        },
        resetAddCrmContactLabelDetail: (state) => {
            state.addCrmContactLabelDetail = CRM_INITIAL_STATE.addCrmContactLabelDetail
        },

        // modify crm contact label detail
        setModifyCrmContactLabelDetailLoading: (state, { payload }) => {
            state.modifyCrmContactLabelDetail.isLoading = payload
        },
        setModifyCrmContactLabelDetailData: (state, { payload }) => {
            state.modifyCrmContactLabelDetail.data = payload
            state.modifyCrmContactLabelDetail.message = CRM_INITIAL_STATE.modifyCrmContactLabelDetail.message
        },
        setModifyCrmContactLabelDetailMessage: (state, { payload }) => {
            state.modifyCrmContactLabelDetail.message = payload
            state.modifyCrmContactLabelDetail.data = CRM_INITIAL_STATE.modifyCrmContactLabelDetail.data
        },
        resetModifyCrmContactLabelDetail: (state) => {
            state.modifyCrmContactLabelDetail = CRM_INITIAL_STATE.modifyCrmContactLabelDetail
        },

        // create contact label
        setAddContactLabelDetailLoading: (state, { payload }) => {
            state.addContactLabelDetail.isLoading = payload
        },
        setAddContactLabelDetailData: (state, { payload }) => {
            state.addContactLabelDetail.data = payload
            state.addContactLabelDetail.message = CRM_INITIAL_STATE.addContactLabelDetail.message
        },
        setAddContactLabelDetailMessage: (state, { payload }) => {
            state.addContactLabelDetail.message = payload
            state.addContactLabelDetail.data = CRM_INITIAL_STATE.addContactLabelDetail.data
        },
        resetAddContactLabelDetail: (state) => {
            state.addContactLabelDetail = CRM_INITIAL_STATE.addCrmContactLabelDetail
        },

        // destroy contact label detail
        setDestroyContactLabelDetailLoading: (state, { payload }) => {
            state.destroyContactLabelDetail.isLoading = payload
        },
        setDestroyContactLabelDetailMessage: (state, { payload }) => {
            state.destroyContactLabelDetail.message = payload
        },
        resetDestroyContactLabelDetail: (state) => {
            state.destroyContactLabelDetail = CRM_INITIAL_STATE.destroyContactLabelDetail
        },

        // create crm contact email
        setAddCrmContactEmailDetailLoading: (state, { payload }) => {
            state.addCrmContactEmailDetail.isLoading = payload
        },
        setAddCrmContactEmailDetailData: (state, { payload }) => {
            state.addCrmContactEmailDetail.data = payload
            state.addCrmContactEmailDetail.message = CRM_INITIAL_STATE.addCrmContactEmailDetail.message
        },
        setAddCrmContactEmailDetailMessage: (state, { payload }) => {
            state.addCrmContactEmailDetail.message = payload
            state.addCrmContactEmailDetail.data = CRM_INITIAL_STATE.addCrmContactEmailDetail.data
        },
        resetAddCrmContactEmailDetail: (state) => {
            state.addCrmContactEmailDetail = CRM_INITIAL_STATE.addCrmContactEmailDetail
        },

        // update crm contact email
        setModifyCrmContactEmailDetailLoading: (state, { payload }) => {
            state.modifyCrmContactEmailDetail.isLoading = payload
        },
        setModifyCrmContactEmailDetailData: (state, { payload }) => {
            state.modifyCrmContactEmailDetail.data = payload
            state.modifyCrmContactEmailDetail.message = CRM_INITIAL_STATE.modifyCrmContactEmailDetail.message
        },
        setModifyCrmContactEmailDetailMessage: (state, { payload }) => {
            state.modifyCrmContactEmailDetail.message = payload
            state.modifyCrmContactEmailDetail.data = CRM_INITIAL_STATE.modifyCrmContactEmailDetail.data
        },
        resetModifyCrmContactEmailDetail: (state) => {
            state.modifyCrmContactEmailDetail = CRM_INITIAL_STATE.modifyCrmContactEmailDetail
        },

        // delete crm contact email
        setDestroyCrmContactEmailDetailLoading: (state, { payload }) => {
            state.destroyCrmContactEmailDetail.isLoading = payload
        },
        setDestroyCrmContactEmailDetailMessage: (state, { payload }) => {
            state.destroyCrmContactEmailDetail.message = payload
        },
        resetDestroyCrmContactEmailDetail: (state) => {
            state.destroyCrmContactEmailDetail = CRM_INITIAL_STATE.destroyCrmContactEmailDetail
        },

        // create crm contact phone
        setAddCrmContactPhoneDetailLoading: (state, { payload }) => {
            state.addCrmContactPhoneDetail.isLoading = payload
        },
        setAddCrmContactPhoneDetailData: (state, { payload }) => {
            state.addCrmContactPhoneDetail.data = payload
            state.addCrmContactPhoneDetail.message = CRM_INITIAL_STATE.addCrmContactPhoneDetail.message
        },
        setAddCrmContactPhoneDetailMessage: (state, { payload }) => {
            state.addCrmContactPhoneDetail.message = payload
            state.addCrmContactPhoneDetail.data = CRM_INITIAL_STATE.addCrmContactPhoneDetail.data
        },
        resetAddCrmContactPhoneDetail: (state) => {
            state.addCrmContactPhoneDetail = CRM_INITIAL_STATE.addCrmContactPhoneDetail
        },

        // update crm contact phone
        setModifyCrmContactPhoneDetailLoading: (state, { payload }) => {
            state.modifyCrmContactPhoneDetail.isLoading = payload
        },
        setModifyCrmContactPhoneDetailData: (state, { payload }) => {
            state.modifyCrmContactPhoneDetail.data = payload
            state.modifyCrmContactPhoneDetail.message = CRM_INITIAL_STATE.modifyCrmContactPhoneDetail.message
        },
        setModifyCrmContactPhoneDetailMessage: (state, { payload }) => {
            state.modifyCrmContactPhoneDetail.message = payload
            state.modifyCrmContactPhoneDetail.data = CRM_INITIAL_STATE.modifyCrmContactPhoneDetail.data
        },
        resetModifyCrmContactPhoneDetail: (state) => {
            state.modifyCrmContactPhoneDetail = CRM_INITIAL_STATE.modifyCrmContactPhoneDetail
        },

        // delete crm contact phone
        setDestroyCrmContactPhoneDetailLoading: (state, { payload }) => {
            state.destroyCrmContactPhoneDetail.isLoading = payload
        },
        setDestroyCrmContactPhoneDetailMessage: (state, { payload }) => {
            state.destroyCrmContactPhoneDetail.message = payload
        },
        resetDestroyCrmContactPhoneDetail: (state) => {
            state.destroyCrmContactPhoneDetail = CRM_INITIAL_STATE.destroyCrmContactPhoneDetail
        },

        // create crm contact social
        setAddCrmContactSocialDetailLoading: (state, { payload }) => {
            state.addCrmContactSocialDetail.isLoading = payload
        },
        setAddCrmContactSocialDetailData: (state, { payload }) => {
            state.addCrmContactSocialDetail.data = payload
            state.addCrmContactSocialDetail.message = CRM_INITIAL_STATE.addCrmContactSocialDetail.message
        },
        setAddCrmContactSocialDetailMessage: (state, { payload }) => {
            state.addCrmContactSocialDetail.message = payload
            state.addCrmContactSocialDetail.data = CRM_INITIAL_STATE.addCrmContactSocialDetail.data
        },
        resetAddCrmContactSocialDetail: (state) => {
            state.addCrmContactSocialDetail = CRM_INITIAL_STATE.addCrmContactSocialDetail
        },

        // update crm contact social
        setModifyCrmContactSocialDetailLoading: (state, { payload }) => {
            state.modifyCrmContactSocialDetail.isLoading = payload
        },
        setModifyCrmContactSocialDetailData: (state, { payload }) => {
            state.modifyCrmContactSocialDetail.data = payload
            state.modifyCrmContactSocialDetail.message = CRM_INITIAL_STATE.modifyCrmContactSocialDetail.message
        },
        setModifyCrmContactSocialDetailMessage: (state, { payload }) => {
            state.modifyCrmContactSocialDetail.message = payload
            state.modifyCrmContactSocialDetail.data = CRM_INITIAL_STATE.modifyCrmContactSocialDetail.data
        },
        resetModifyCrmContactSocialDetail: (state) => {
            state.modifyCrmContactSocialDetail = CRM_INITIAL_STATE.modifyCrmContactSocialDetail
        },

        // delete crm contact social
        setDestroyCrmContactSocialDetailLoading: (state, { payload }) => {
            state.destroyCrmContactSocialDetail.isLoading = payload
        },
        setDestroyCrmContactSocialDetailMessage: (state, { payload }) => {
            state.destroyCrmContactSocialDetail.message = payload
        },
        resetDestroyCrmContactSocialDetail: (state) => {
            state.destroyCrmContactSocialDetail = CRM_INITIAL_STATE.destroyCrmContactSocialDetail
        },

        // create crm contact address
        setAddCrmContactAddressDetailLoading: (state, { payload }) => {
            state.addCrmContactAddressDetail.isLoading = payload
        },
        setAddCrmContactAddressDetailData: (state, { payload }) => {
            state.addCrmContactAddressDetail.data = payload
            state.addCrmContactAddressDetail.message = CRM_INITIAL_STATE.addCrmContactAddressDetail.message
        },
        setAddCrmContactAddressDetailMessage: (state, { payload }) => {
            state.addCrmContactAddressDetail.message = payload
            state.addCrmContactAddressDetail.data = CRM_INITIAL_STATE.addCrmContactAddressDetail.data
        },
        resetAddCrmContactAddressDetail: (state) => {
            state.addCrmContactAddressDetail = CRM_INITIAL_STATE.addCrmContactAddressDetail
        },

        // update crm contact address
        setModifyCrmContactAddressDetailLoading: (state, { payload }) => {
            state.modifyCrmContactAddressDetail.isLoading = payload
        },
        setModifyCrmContactAddressDetailData: (state, { payload }) => {
            state.modifyCrmContactAddressDetail.data = payload
            state.modifyCrmContactAddressDetail.message = CRM_INITIAL_STATE.modifyCrmContactAddressDetail.message
        },
        setModifyCrmContactAddressDetailMessage: (state, { payload }) => {
            state.modifyCrmContactAddressDetail.message = payload
            state.modifyCrmContactAddressDetail.data = CRM_INITIAL_STATE.modifyCrmContactAddressDetail.data
        },
        resetModifyCrmContactAddressDetail: (state) => {
            state.modifyCrmContactAddressDetail = CRM_INITIAL_STATE.modifyCrmContactAddressDetail
        },

        // delete crm contact address
        setDestroyCrmContactAddressDetailLoading: (state, { payload }) => {
            state.destroyCrmContactAddressDetail.isLoading = payload
        },
        setDestroyCrmContactAddressDetailMessage: (state, { payload }) => {
            state.destroyCrmContactAddressDetail.message = payload
        },
        resetDestroyCrmContactAddressDetail: (state) => {
            state.destroyCrmContactAddressDetail = CRM_INITIAL_STATE.destroyCrmContactAddressDetail
        },

        // create crm contact note
        setAddCrmContactNoteDetailLoading: (state, { payload }) => {
            state.addCrmContactNoteDetail.isLoading = payload
        },
        setAddCrmContactNoteDetailData: (state, { payload }) => {
            state.addCrmContactNoteDetail.data = payload
            state.addCrmContactNoteDetail.message = CRM_INITIAL_STATE.addCrmContactNoteDetail.message
        },
        setAddCrmContactNoteDetailMessage: (state, { payload }) => {
            state.addCrmContactNoteDetail.message = payload
            state.addCrmContactNoteDetail.data = CRM_INITIAL_STATE.addCrmContactNoteDetail.data
        },
        resetAddCrmContactNoteDetail: (state) => {
            state.addCrmContactNoteDetail = CRM_INITIAL_STATE.addCrmContactNoteDetail
        },

        // update crm contact note
        setModifyCrmContactNoteDetailLoading: (state, { payload }) => {
            state.modifyCrmContactNoteDetail.isLoading = payload
        },
        setModifyCrmContactNoteDetailData: (state, { payload }) => {
            state.modifyCrmContactNoteDetail.data = payload
            state.modifyCrmContactNoteDetail.message = CRM_INITIAL_STATE.modifyCrmContactNoteDetail.message
        },
        setModifyCrmContactNoteDetailMessage: (state, { payload }) => {
            state.modifyCrmContactNoteDetail.message = payload
            state.modifyCrmContactNoteDetail.data = CRM_INITIAL_STATE.modifyCrmContactNoteDetail.data
        },
        resetModifyCrmContactNoteDetail: (state) => {
            state.modifyCrmContactNoteDetail = CRM_INITIAL_STATE.modifyCrmContactNoteDetail
        },

        // delete crm contact note
        setDestroyCrmContactNoteDetailLoading: (state, { payload }) => {
            state.destroyCrmContactNoteDetail.isLoading = payload
        },
        setDestroyCrmContactNoteDetailMessage: (state, { payload }) => {
            state.destroyCrmContactNoteDetail.message = payload
        },
        resetDestroyCrmContactNoteDetail: (state) => {
            state.destroyCrmContactNoteDetail = CRM_INITIAL_STATE.destroyCrmContactNoteDetail
        },
    }
})

export const {
    setCrmContactListLoading,
    setCrmContactListData,
    setCrmContactListMessage,
    resetCrmContactList,

    setCrmLeadListLoading,
    setCrmLeadListData,
    setCrmLeadListMessage,
    resetCrmLeadList,

    setModifyCrmBulkContactListLoading,
    setModifyCrmBulkContactListData,
    setModifyCrmBulkContactListMessage,
    resetModifyCrmBulkContactList,

    setModifyCrmBulkContactListPayload,
    resetModifyCrmBulkContactListPayload,

    setDestroyCrmBulkContactListLoading,
    setDestroyCrmBulkContactListData,
    setDestroyCrmBulkContactListMessage,
    resetDestroyCrmBulkContactList,

    setCrmContactDetailLoading,
    setCrmContactDetailData,
    setCrmContactDetailMessage,
    resetCrmContactDetail,

    setAddCrmContactDetailLoading,
    setAddCrmContactDetailData,
    setAddCrmContactDetailMessage,
    resetAddCrmContactDetail,

    setAddCrmContactDetailPayload,
    resetAddCrmContactDetailPayload,

    setModifyCrmContactDetailLoading,
    setModifyCrmContactDetailData,
    setModifyCrmContactDetailMessage,
    resetModifyCrmContactDetail,

    setModifyCrmContactDetailPayload,
    resetModifyCrmContactDetailPayload,

    setDestroyCrmContactDetailLoading,
    setDestroyCrmContactDetailData,
    setDestroyCrmContactDetailMessage,
    resetDestroyCrmContactDetail,

    setCrmContactStatusListLoading,
    setCrmContactStatusListData,
    setCrmContactStatusListMessage,
    resetCrmContactStatusList,

    setCrmModalContactStatusListLoading,
    setCrmModalContactStatusListData,
    setCrmModalContactStatusListMessage,
    resetCrmModalContactStatusList,

    setCrmContactLabelListLoading,
    setCrmContactLabelListData,
    setCrmContactLabelListMessage,
    resetCrmContactLabelList,

    setAddCrmContactLabelDetailLoading,
    setAddCrmContactLabelDetailData,
    setAddCrmContactLabelDetailMessage,
    resetAddCrmContactLabelDetail,

    setModifyCrmContactLabelDetailLoading,
    setModifyCrmContactLabelDetailData,
    setModifyCrmContactLabelDetailMessage,
    resetModifyCrmContactLabelDetail,

    setAddContactLabelDetailLoading,
    setAddContactLabelDetailData,
    setAddContactLabelDetailMessage,
    resetAddContactLabelDetail,

    setDestroyContactLabelDetailLoading,
    setDestroyContactLabelDetailMessage,
    resetDestroyContactLabelDetail,

    setAddCrmContactEmailDetailLoading,
    setAddCrmContactEmailDetailData,
    setAddCrmContactEmailDetailMessage,
    resetAddCrmContactEmailDetail,

    setModifyCrmContactEmailDetailLoading,
    setModifyCrmContactEmailDetailData,
    setModifyCrmContactEmailDetailMessage,
    resetModifyCrmContactEmailDetail,

    setDestroyCrmContactEmailDetailLoading,
    setDestroyCrmContactEmailDetailMessage,
    resetDestroyCrmContactEmailDetail,

    setAddCrmContactPhoneDetailLoading,
    setAddCrmContactPhoneDetailData,
    setAddCrmContactPhoneDetailMessage,
    resetAddCrmContactPhoneDetail,

    setModifyCrmContactPhoneDetailLoading,
    setModifyCrmContactPhoneDetailData,
    setModifyCrmContactPhoneDetailMessage,
    resetModifyCrmContactPhoneDetail,

    setDestroyCrmContactPhoneDetailLoading,
    setDestroyCrmContactPhoneDetailMessage,
    resetDestroyCrmContactPhoneDetail,

    setAddCrmContactSocialDetailLoading,
    setAddCrmContactSocialDetailData,
    setAddCrmContactSocialDetailMessage,
    resetAddCrmContactSocialDetail,

    setModifyCrmContactSocialDetailLoading,
    setModifyCrmContactSocialDetailData,
    setModifyCrmContactSocialDetailMessage,
    resetModifyCrmContactSocialDetail,

    setDestroyCrmContactSocialDetailLoading,
    setDestroyCrmContactSocialDetailMessage,
    resetDestroyCrmContactSocialDetail,

    setAddCrmContactAddressDetailLoading,
    setAddCrmContactAddressDetailData,
    setAddCrmContactAddressDetailMessage,
    resetAddCrmContactAddressDetail,

    setModifyCrmContactAddressDetailLoading,
    setModifyCrmContactAddressDetailData,
    setModifyCrmContactAddressDetailMessage,
    resetModifyCrmContactAddressDetail,

    setDestroyCrmContactAddressDetailLoading,
    setDestroyCrmContactAddressDetailMessage,
    resetDestroyCrmContactAddressDetail,

    setAddCrmContactNoteDetailLoading,
    setAddCrmContactNoteDetailData,
    setAddCrmContactNoteDetailMessage,
    resetAddCrmContactNoteDetail,

    setModifyCrmContactNoteDetailLoading,
    setModifyCrmContactNoteDetailData,
    setModifyCrmContactNoteDetailMessage,
    resetModifyCrmContactNoteDetail,

    setDestroyCrmContactNoteDetailLoading,
    setDestroyCrmContactNoteDetailMessage,
    resetDestroyCrmContactNoteDetail,
} = crm.actions;

export default crm.reducer;