import tw, { styled } from "twin.macro";

export const StyledChatViewMessageList = styled.div`
  ${tw`
    relative px-5 pt-2.5 flex flex-col overflow-hidden overflow-y-scroll 
    height[calc(100vh - 140px)] md:max-height[calc(100% - 140px)]
    scrollbar scrollbar-track-rounded-full scrollbar-thumb-rounded-full
    hover:(scrollbar-thumb-divider-lightDark scrollbar-track-white)
  `}

  ::-webkit-scrollbar {
    ${tw`w-1.5`}
  }

  div {
    &:first-of-type {
      ${tw`mt-auto`}
    }
  }
`;
