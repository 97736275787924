import {
    Container,
    Content,
    ListContainer,
    ListHeading,
} from './BenifitsOfOnlineTutoringStyle';
import { data } from './BenifitsOfOnlineTutoringData';

const benifitsOfOnlineTutoringImageUrl = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/teach-with-us/teach-with-us-benifits-of-online-tutoring.png';

const BenifitsOfOnlineTutoring = () => {
    return (
        <Container>
            <Content>
                <img src={benifitsOfOnlineTutoringImageUrl} alt={'benifits-of-online-tutoring'} />
                <ListContainer>
                    <ListHeading>{'Benefits of Online Teaching'}</ListHeading>
                    <ul>
                        {data.map((item, index) => (
                            <li key={index}>{item.listItem}</li>
                        ))}
                    </ul>
                </ListContainer>
            </Content>
        </Container>
    )
}

export default BenifitsOfOnlineTutoring;
