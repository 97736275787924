import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { Helmet } from 'react-helmet';

import {
  Section,
  Button,
  HLine,
  Footer
} from 'pages/auth/support/style';

import {
  pageHeading,
  SupportActionList,
  actionTextConst,
  studentKnowledgeBase,
  teacherKnowledgeBase,
  sendEmailToSupport,
  createATicket,
  // tutorRoleFaqsScript,
  // studentRoleFaqsScript
} from 'pages/auth/support/support.data';

import PageHeader from 'components/pageHeader/PageHeader';

import SendEmailAction from "pages/auth/support/components/SendEmailAction"

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { userRoles } from 'redux/auth/auth.const';

import { pagesInfo } from 'utils/pagesInfo';

import { useTitle } from 'hooks/useTitle';

function Support() {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { locals } = useAppState((s) => s.local)

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()
  const [title, setTitle] = useTitle()

  const [isSendEmailTextField, setIsSendEmailTextField] = useState(false)
  // const [faqScriptSrc, setFaqScriptSrc] = useState(null)

  // useEffect(() => {
  //   return () => {
  //     setFaqScriptSrc(null)
  //   }
  // }, [])

  // useEffect(() => {
  //   if (locals?.userRole === userRoles?.TUTOR?.value) {
  //     setFaqScriptSrc(tutorRoleFaqsScript)
  //   }
  //   if (locals?.userRole === userRoles?.STUDENT?.value) {
  //     setFaqScriptSrc(studentRoleFaqsScript)
  //   }
  // }, [locals?.userRole])

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.SUPPORT))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    setTitle({
      ...title,
      title: "Get the Help You Need | Edulyte"
    })
  }, [dispatcher, currentPageInfo])

  const handleOnClick = (action) => {
    if (action.text === actionTextConst.KNOWLEDGE_BASE.label) {
      if (locals.userRole === userRoles.TUTOR.value) {
        window.open(teacherKnowledgeBase, "_blank")
      }
      if (locals.userRole === userRoles.STUDENT.value) {
        window.open(studentKnowledgeBase, "_blank")
      }
    }
    if (action.text === actionTextConst.SEND_AN_EMAIL.label) {
      window.open(sendEmailToSupport)
      // setIsSendEmailTextField(!isSendEmailTextField)
    }
    if (action.text === actionTextConst.CREATE_A_TICKET.label) {
      window.open(createATicket, "_blank")
    }
    if (action.text === actionTextConst.CHAT_WITH_US.label) {
      navigate(pagesInfo.MESSAGES.pagePath)
    }
  }

  const closeEmailContainer = () => {
    setIsSendEmailTextField(false)
  }

  return (
    <div className={"w-full h-full p-3 mx-auto min-h-screen space-y-3"}>
      <PageHeader pageHeading={pageHeading} />
      <Section>
        {SupportActionList.map((action, index) => (
          <Button key={index} onClick={() => handleOnClick(action)}>
            <div className={"w-16 h-16 overflow-hidden"}>
              <img
                src={action.image}
                className={"w-full h-full object-cover"}
                alt={action.text}
              />
            </div>
            <span className={"font-semibold text-center text-base text-primary-main group-hover:text-secondary-main whitespace-nowrap"}>
              {action.text}
            </span>
          </Button>
        ))}
      </Section>
      {isSendEmailTextField &&
        <Section>
          <SendEmailAction
            closeEmailContainer={closeEmailContainer}
          />
        </Section>
      }
      <Footer>
        <h3 className={"font-bodyPri font-semibold text-text-800 text-md pt-10"}>
          {"Frequently Asked Questions "}
          <span className='font-bodyPri font-semibold text-text-800 text-sm'>
            {" (Coming Soon)"}
          </span>
        </h3>
        <HLine>
          <hr />
        </HLine>

        {/* {faqScriptSrc &&
          <Helmet>
            <script
              type={"text/javascript"}
              nonce={"qKA9y/EN4JUjUv1eHlzxhA=="}
              src={faqScriptSrc}
              defer
            />
          </Helmet>
        } */}
      </Footer>
    </div>
  );
}

export default Support;
