const uploadDocumentPayload = {
    fileCategory: '',
    fileName: '',
    fileType: '',
    file: null,
}

export const DOCUMENT_INITIAL_STATE = {
    uploadDocument: {
        isLoading: false,
        uploadDocument: null,
        uploadDocumentPayload: uploadDocumentPayload,
        errorMsg: null,
    }
}