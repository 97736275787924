import { useCallback, useEffect, useReducer, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { toast } from "react-toastify";

import { AiOutlineClose } from "react-icons/ai";
import { MdEdit } from "react-icons/md";

import ButtonLoader from "components/loader/ButtonLoader";
import AiTextButton from "components/createWithAi/aiTextButton";

import { downloadActions, downloadReducer } from "./data";

import { createUserDownloadDetail } from "redux/downloads/downloads.request";

import useAiText from "hooks/useAiText";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { resetAddUserDownloadDetail } from "redux/downloads/downloads.slice";
import { modalConst } from "redux/local/local.const";

import { pagesInfo } from "utils/pagesInfo";
import { cn } from "utils/cn.utils";

import { EDULYTE_APP_DOMAIN_URL } from "const/default.const";

const MAX_LENGTH = 128;

const AddDownloadModalContent = ({ closeModal }) => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const addUserDownloadDetail = useAppState((state) => state.downloads.addUserDownloadDetail)

    const dispatcher = useAppDispatcher()

    const [state, dispatch] = useReducer(downloadReducer, {
        title: "",
        subTitle: "",
        titleError: ""
    })

    const [isShowSubHeading, setIsShowSubHeading] = useState(false)
    const [isShowInput, setIsShowInput] = useState(false)
    const [topic, setTopic] = useState('')

    const { aiText, generateAiText } = useAiText()

    const requestData = {
        purpose: "title", // description or title 
        total_chars: 85,
        topic: topic,
        response_type: "text" // text or html 
    };

    const getAiText = () => {
        if (topic === "") {
            toast.error("Please write ai prompt");
            return
        }
        setIsShowInput(false)
        generateAiText(requestData);
    };

    const handlePrompt = (e) => {
        const value = e.target.value
        setTopic(value)
    }

    useEffect(() => {
        if (aiText?.data?.ai_response) {
            dispatch({
                type: downloadActions.TITLE.action,
                payload: aiText.data.ai_response
            })
        }
    }, [aiText?.data]);

    useEffect(() => {
        return () => {
            dispatcher(resetAddUserDownloadDetail())
        }
    }, [])

    useEffect(() => {
        if (addUserDownloadDetail?.data) {
            const downloadId = addUserDownloadDetail?.data?.id
            dispatcher(resetAddUserDownloadDetail())
            dispatcher(setModal({
                ...modal,
                [modalConst.CREATE_LMS_COURSE_MODAL.stateKey]: false
            }))
            window.open(`${EDULYTE_APP_DOMAIN_URL}${pagesInfo.EDIT_DOWNLOAD.pagePath}/${downloadId}/edit`, "_self")
        }
    }, [addUserDownloadDetail?.data])

    const onHandleCloseModal = () => {
        dispatcher(resetAddUserDownloadDetail())
        closeModal()
    }

    const onHandleChangeInput = useCallback((event) => {
        if (addUserDownloadDetail?.isLoading) return;

        dispatch({
            type: event.target.name,
            payload: event.target.value
        })
    }, [addUserDownloadDetail?.isLoading, state])

    const onHandleCreateNewDownload = useCallback(() => {
        if (addUserDownloadDetail.isLoading) return;

        if (!state.title || (state.title?.length === 0)) {
            dispatch({
                type: downloadActions.TITLE_ERROR.action,
                payload: "Please enter title!"
            })
            return;
        }

        const body = {
            owner_user_id: user.user.userId,
            title: state.title
        }
        if (state.subTitle?.length > 0) {
            body["subtitle"] = state.subTitle
        }
        dispatcher(createUserDownloadDetail(body))
        dispatch({
            type: downloadActions.INITIAL_STATE.action
        })
    }, [addUserDownloadDetail?.isLoading, state])

    return (
        <>
            <div className={"flex w-full justify-between items-center bg-background-light h-16 px-5 drop-shadow-sm"}>
                <p className="font-bodyPri font-medium text-text-900 text-lg">
                    {"Create a download"}
                </p>
                <AiOutlineClose
                    onClick={onHandleCloseModal}
                    className={'cursor-pointer'}
                    size={20}
                />
            </div>
            <div className={"w-full flex flex-col gap-5 bg-white p-10"}>
                <div className={"w-full flex flex-col gap-3"}>
                    <div className="relative flex justify-between items-center">
                        <AiTextButton
                            isShowInput={isShowInput}
                            setIsShowInput={setIsShowInput}
                            handlePrompt={handlePrompt}
                            getAiText={getAiText}
                            aiText={aiText}
                        />
                        <div className={"w-full flex justify-end"}>
                            {(state.title?.length > 0) &&
                                <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                                    {`${state.title?.length}/${MAX_LENGTH}`}
                                </span>
                            }
                        </div>
                    </div>
                    <input
                        className={cn(
                            "px-3 h-12 flex w-full focus:outline-none rounded-lg border border-background-lightDark focus:border focus:border-primary-main",
                            "font-bodyPri font-normal text-text-800 text-base placeholder:text-text-500"
                        )}
                        type={"text"}
                        placeholder={'Add Download Title'}
                        name={downloadActions.TITLE.action}
                        maxLength={MAX_LENGTH}
                        value={state.title}
                        onChange={onHandleChangeInput}
                    />
                    <div className={"w-full flex justify-end"}>
                        {(state.title?.length > 0) &&
                            <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                                {`${state.title?.length}/${MAX_LENGTH}`}
                            </span>
                        }
                    </div>
                </div>
                <AnimatePresence initial={isShowSubHeading}>
                    {!isShowSubHeading &&
                        <div
                            className={"w-full flex items-center justify-between gap-3 cursor-pointer pt-1 group"}
                            onClick={() => setIsShowSubHeading(true)}
                        >
                            <span className={"font-bodyPri font-normal text-primary-dark text-sm tracking-wide line-clamp-2"}>
                                {state.subTitle
                                    ? state.subTitle
                                    : "Sub Heading (optional)"
                                }
                            </span>
                            <MdEdit className={"text-xl text-text-900 hidden group-hover:flex"} />
                        </div>
                    }
                    {isShowSubHeading &&
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                            className={"w-full"}
                        >
                            <textarea
                                type={'text'}
                                className={cn(
                                    'w-full border outline-none rounded-lg p-2 focus:border-primary-main opacity-100 transition-opacity duration-300',
                                    'font-bodyPri font-normal text-text-800 text-sm tracking-wide placeholder:text-text-500'
                                )}
                                placeholder={"Sub Header (optional)"}
                                name={downloadActions.SUB_TITLE.action}
                                value={state.subTitle}
                                rows={3}
                                maxLength={180}
                                onChange={onHandleChangeInput}
                                autoFocus={true}
                                onBlur={() => setIsShowSubHeading(false)}
                            />
                        </motion.div>
                    }
                </AnimatePresence>
                <div className={"w-full flex items-center justify-start"}>
                    {(addUserDownloadDetail?.message || state.title_error) &&
                        <span className={"w-full font-bodyPri font-normal text-red-500 text-base tracking-wide text-center"}>
                            {addUserDownloadDetail?.message || state.title_error}
                        </span>
                    }
                </div>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={cn(
                        'self-end font-bodyPri font-normal py-2 w-36 bg-primary-dark text-white rounded-full hover:bg-secondary-dark ease-in-out duration-100',
                        addUserDownloadDetail?.isLoading && "bg-primary-dark"
                    )}
                    onClick={onHandleCreateNewDownload}
                >
                    {addUserDownloadDetail?.isLoading &&
                        <ButtonLoader isLoading={addUserDownloadDetail?.isLoading} />
                    }
                    {!addUserDownloadDetail?.isLoading && "Create"}
                </motion.button>
            </div>
        </>
    )
}

export default AddDownloadModalContent;