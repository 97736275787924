import tw, { styled } from 'twin.macro';
import { Link as RouteLink } from 'react-router-dom';

export const Container = styled.div`
    ${tw`
         h-screen bg-center bg-white flex justify-center overflow-hidden
        md:(justify-start)
    `}
`;

export const Content = tw.div`
    grid grid-cols-12 h-full w-full gap-8 md:gap-1 lg:gap-0 place-items-center lg:place-items-stretch
`;

export const TeachWithUsCard = tw.div`
    min-w-[24rem] col-start-1 col-span-full sm:col-start-2 sm:col-span-10 self-center flex flex-col gap-5 px-5 md:px-0 lg:px-3
    md:col-start-5 md:col-span-5 lg:col-start-2 lg:col-span-3
`;

export const TeachWithUsImage = tw.div`
    hidden col-start-1 col-span-full -mt-0 md:-mt-5 overflow-hidden rounded-md
    md:block lg:block lg:col-start-7 lg:col-span-3 lg:w-[36rem] lg:h-[36rem] md:w-[22rem] md:h-[22rem]
`;

export const CardHeader = tw.div`
    flex flex-col items-center justify-center gap-3
`;

export const Heading = tw.h1`
    text-xl font-bodyPri font-bold text-text-900
`;

export const SubHeading = tw.span`
    font-subHeading font-normal text-text-800 flex flex-col justify-center items-center w-full gap-1 text-base text-center
    lg:flex-row leading-5
`;

export const CardFooter = tw.span`
    w-full font-bodyPri font-normal text-sm text-text-700 leading-6
`;

export const AnchorLink = tw(RouteLink)`
    text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer
`;

export const SocialSignUpWrapper = tw.div`
    my-5 w-full
`;