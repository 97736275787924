import { cn } from "utils/cn.utils";

import { FaInfoCircle } from "react-icons/fa";

import ToolTipView from "components/tooltipView";

const PageHeader = ({
    pageHeading,
    toolTipText = null,
}) => {
    return (
        <div className="bg-white rounded-lg px-10 py-2">
            <div className={"flex justify-between items-start gap-3"}>
                <div className={'flex flex-col items-start gap-1'}>
                    <div className={"flex items-start justify-start gap-1"}>
                        <span className="font-tagLine font-bold text-xl text-text-900">
                            {pageHeading.heading}
                        </span>
                        {toolTipText &&
                            <ToolTipView content={toolTipText}>
                                <button className={"relative has-tooltip cursor-pointer"}>
                                    <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                                </button>
                            </ToolTipView>
                        }
                    </div>
                    {/* <span className="font-bodyPri font-medium text-sm md:text-base text-text-600">
                        {pageHeading.subHeading}
                    </span> */}
                </div>
                <div className={"w-16 h-16"}>
                    <img src={pageHeading.headingPicUrl} alt={pageHeading.heading} className={"w-full h-full object-cover"} />
                </div>
            </div>
        </div>
    )
}

export default PageHeader