import { useState, useCallback } from 'react';

import { toast } from 'react-toastify';

import gptService from 'redux/gpt/gpt.service';

const useAiText = () => {
    const [aiText, setAiText] = useState({
        isLoading: false,
        data: null,
        error: null,
    });

    const generateAiText = useCallback(async (requestData) => {
        setAiText(s => ({ ...s, isLoading: true, error: null }));
        try {
            const requestBody = {
                body: JSON?.stringify(requestData)
            };
            const response = await gptService?.createGptText(requestBody);
            if (response.status === 200) {
                const data = response?.data;
                setAiText(s => ({ ...s, data: data }));
            } else {
                throw new Error(response.statusText);
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || error?.response?.data?.error || "Something went wrong";
            console.error(errorMessage);
            toast.error(errorMessage);
            setAiText(s => ({ ...s, error: errorMessage }));
        } finally {
            setAiText(s => ({ ...s, isLoading: false }));
        }
    }, []);

    return { aiText, generateAiText };
};

export default useAiText;