import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import ComponentLoader from 'components/loader/ComponentLoader';

import SessionDetailTitle from 'components/modals/sessionDetailModal/SessionDetailTitle';
import SessionDetailDateTimeSlot from 'components/modals/sessionDetailModal/SessionDetailDateTimeSlot';
import SessionDetailCountDown from 'components/modals/sessionDetailModal/SessionDetailCountDown';
import SessionDefaultLocation from 'components/modals/sessionDetailModal/SessionDefaultLocation';
import SessionDetailTypeView from 'components/modals/sessionDetailModal/SessionDetailTypeView';
import SessionDetailRoleView from 'components/modals/sessionDetailModal/SessionDetailRoleView';
import SessionDetailCourseType from 'components/modals/sessionDetailModal/SessionDetailCourseType';
import SessionDetailStatus from 'components/modals/sessionDetailModal/SessionDetailStatus';
import SessionDetailLocation from 'components/modals/sessionDetailModal/SessionDetailLocation';
import SessionDetailParticipants from 'components/modals/sessionDetailModal/SessionDetailParticipants';
import SessionDetailSessionLog from 'components/modals/sessionDetailModal/SessionDetailSessionLog';
// import SessionDetailFooter from 'components/modals/sessionDetailModal/SessionDetailFooter';

import { getSessionUserDetail } from "redux/session/session.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetSessionUserDetail } from "redux/session/session.slice";
import { sessionTypeEnum } from 'redux/session/session.const';

import { generateQueryParams } from 'utils/generators.utils';

const SessionDetailModal = () => {
    const { user } = useAppState((state) => state.user)
    const { sessionUserDetail, completeSessionUserDetail } = useAppState(s => s.session)

    const dispatcher = useAppDispatcher();
    const location = useLocation()

    useEffect(() => {
        if (user?.user) {
            const query = generateQueryParams(location.search)
            if (query && query?.sessionId) {
                dispatcher(resetSessionUserDetail())
                dispatcher(getSessionUserDetail(query?.sessionId, user?.user?.userId))
            }
        }
    }, [location.search, user?.user, completeSessionUserDetail?.data?.result]);

    const sessionUserInfo = useMemo(() => sessionUserDetail?.data?.result?.session_users?.find((sessionUser) => sessionUser?.user?.id === user?.user?.userId), [sessionUserDetail?.data?.result?.session_users, user?.user?.userId])

    return (
        <div className={"space-y-5 overflow-x-hidden select-none"}>

            <div className={"min-h-[24rem] block space-y-5 pt-2"}>

                {sessionUserDetail?.isLoading &&
                    <div className={"h-full w-full flex items-center justify-center gap-2"}>
                        <ComponentLoader isLoading={sessionUserDetail?.isLoading} />
                    </div>
                }

                {sessionUserDetail?.message &&
                    <div className={"h-full w-full flex items-center justify-center gap-2 font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {sessionUserDetail?.message}
                    </div>
                }

                <SessionDetailTitle />

                <SessionDetailTypeView />

                <SessionDetailDateTimeSlot />

                <SessionDetailCountDown sessionUserInfo={sessionUserInfo} />

                {(!!sessionUserDetail?.data?.result?.session_locations?.length && [sessionTypeEnum?.APPOINTMENT.value]?.includes(sessionUserDetail?.data?.result?.type)) &&
                    <SessionDefaultLocation sessionLocationInfo={sessionUserDetail?.data?.result?.session_locations[0]} />
                }

                <SessionDetailRoleView sessionUserInfo={sessionUserInfo} />

                <SessionDetailCourseType />

                <SessionDetailStatus sessionUserInfo={sessionUserInfo} />

                {(sessionUserDetail?.data?.result && [sessionTypeEnum?.APPOINTMENT.value]?.includes(sessionUserDetail?.data?.result?.type)) && <div className={"h-0.5 bg-background-medium w-full rounded-full"}></div>}

                {[sessionTypeEnum?.APPOINTMENT.value]?.includes(sessionUserDetail?.data?.result?.type) &&
                    <SessionDetailLocation />
                }

                {sessionUserDetail?.data?.result && <div className={"h-0.5 bg-background-medium w-full rounded-full"}></div>}

                <SessionDetailParticipants sessionUserInfo={sessionUserInfo} />

                {sessionUserDetail?.data?.result && <div className={"h-0.5 bg-background-medium w-full rounded-full"}></div>}

                <SessionDetailSessionLog />

            </div>

            {/* <SessionDetailFooter /> */}
        </div>
    )
}

export default SessionDetailModal