import { cn } from "utils/cn.utils";
import { Link } from 'react-router-dom';

import { MdGroups } from 'react-icons/md';
import { BsInfoCircle } from "react-icons/bs";
import { IoMdPeople } from 'react-icons/io';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { getSelectedPublicCourseDetail } from 'redux/course/course.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { courseType, coursePriceTypeEnum, coursePricingModelTypeEnum } from "redux/course/course.const";
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { setAddBookingDetailPayload } from 'redux/booking/booking.slice';
import { bookingOfferingTypeEnum, addBookingDetailPayload } from 'redux/booking/booking.const';

import { pagesInfo } from 'utils/pagesInfo';
import { currencyRateConversion } from 'utils/generators.utils';

const CourseCardTwo = ({ course, isFav = false, handleFav }) => {
    const { session } = useAppState(s => s.auth)
    const { user } = useAppState((s) => s.user)
    const { modal, locals } = useAppState((s) => s.local)
    const { publicTutorCourseList } = useAppState((s) => s.course)

    const dispatcher = useAppDispatcher()

    const isMyOffering = user?.user?.userId === course?.tutor?.user?.id

    const userCourse = user?.user?.bookedCourses?.find((courseItem) => courseItem?.courseId === course?.id)
    const isMyCourse = publicTutorCourseList?.data?.results?.filter((publicCourse) => publicCourse?.type === courseType?.GROUP?.value)
        .filter((myCourse) => myCourse?.id === userCourse?.courseId)?.length > 0

    const handleBookNow = () => {
        if (isMyCourse || isMyOffering) return;

        dispatcher(setAddBookingDetailPayload({
            ...addBookingDetailPayload,
            offeringType: bookingOfferingTypeEnum.COURSE.value,
            courseId: course?.id,
            slug: course?.slug
        }))
        dispatcher(getSelectedPublicCourseDetail(course?.slug))

        if (course?.type === courseType.GROUP.value) {
            dispatcher(setModal({ ...modal, [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: true }))
        } else if (course?.type === courseType.ONE_ON_ONE.value && course?.coursePrice?.type === coursePriceTypeEnum.REGULAR.value) {
            dispatcher(setModal({ ...modal, [modalConst.NO_SESSION_MODAL.stateKey]: true }))
        }
    }

    return (
        <div className={'min-w-[16rem] max-w-[24rem] h-full flex flex-col bg-white rounded-lg shadow-all'}>
            <div className={'w-full h-full overflow-hidden relative'}>
                <div className={'rounded-t-lg overflow-hidden h-full w-full aspect-video'}>
                    <Link to={`${pagesInfo?.COURSE?.pagePath}/${course?.id}`}>
                        <img
                            src={course?.thumbnailPicUrl}
                            alt={"class-thumbnail"}
                            className='w-full h-full object-cover'
                        />
                    </Link>
                </div>
                {session?.isLoggedIn &&
                    <div className='absolute right-2 top-2 p-1.5 bg-white bg-opacity-60 hover:bg-opacity-100 rounded-full shadow-md'>
                        <span onClick={() => handleFav(course.id)} className={"cursor-pointer"}>
                            {isFav
                                ? (<FavoriteIcon className={'text-red-500'} style={{ fontSize: "1.5rem" }} />)
                                : <FavoriteBorderIcon className={'text-text-700'} style={{ fontSize: "1.5rem" }} />
                            }
                        </span>
                    </div>
                }
            </div>
            <div className='w-full h-full px-5 py-3 aspect-video flex flex-col justify-between gap-3'>
                <div className='flex flex-col justify-start gap-3'>
                    <div className='flex justify-between items-center'>
                        <span className='text-text-900 font-normal font-bodyPri text-sm tracking-wide whitespace-nowrap truncate'>
                            {course?.category}
                        </span>
                        <div className='flex justify-center items-center gap-2'>
                            {Object.keys(courseType).map((item, index) => courseType[item].value === course?.type &&
                                <span key={index} className='px-2 py-0.5 flex items-center justify-center rounded-full bg-text-300 font-normal font-bodyPri text-xs tracking-wide whitespace-nowrap'>
                                    {courseType[item]?.label}
                                </span>
                            )}
                            {course?.type === courseType.GROUP.value
                                ? <MdGroups className='text-text-700 text-xl' />
                                : <IoMdPeople className='text-text-700 text-xl' />
                            }
                        </div>
                    </div>
                    <Link to={`/class/${course?.id}`} className={cn(
                        'text-text-900 font-bodyPri font-medium text-lg tracking-wide truncate',
                        "hover:text-secondary-main hover:underline decoration-1"
                    )}>
                        {course?.title}
                    </Link>
                </div>
                <div className='flex flex-col justify-start gap-2'>
                    <hr />
                    {course?.coursePrice?.type === coursePriceTypeEnum?.REGULAR?.value &&
                        <div className='flex items-center gap-2 whitespace-nowrap'>
                            <span className='font-medium text-md font-bodyPri tracking-wide'>
                                {currencyRateConversion(locals?.currencyCode, course?.basicDetail?.price)}
                            </span>
                            <span className='font-normal text-sm font-bodyPri tracking-wide'>
                                {coursePricingModelTypeEnum[course?.basicDetail?.priceModel?.toUpperCase()]?.label}
                            </span>
                        </div>
                    }
                    {course?.coursePrice?.type === coursePriceTypeEnum?.PLAN?.value &&
                        <div className='flex items-center gap-2 whitespace-nowrap'>
                            <span className='font-medium text-md font-bodyPri tracking-wide'>
                                {currencyRateConversion(locals?.currencyCode, course?.coursePrice?.itemPrices[0]?.price)}
                            </span>
                            <span className='font-normal text-sm font-bodyPri tracking-wide'>
                                {coursePricingModelTypeEnum[course?.coursePrice?.itemPrices[0]?.pricing_model?.toUpperCase()]?.label}
                            </span>
                        </div>
                    }
                    <div onClick={handleBookNow} className={cn(
                        'px-3 py-1 flex justify-center items-center gap-1 rounded-lg font-normal font-buttons border whitespace-nowrap transition ease-in-out duration-300 delay-100',
                        isMyOffering
                            ? "border-text-300 text-text-300"
                            : isMyCourse
                                ? "border-text-500 text-text-700"
                                : "border-secondary-dark bg-white text-secondary-dark hover:bg-secondary-dark hover:text-text-50 cursor-pointer"
                    )}>
                        {isMyCourse && <BsInfoCircle className={"text-primary-main text-base"} />}
                        <span>
                            {isMyCourse ? "Purchased" : "Book Now"}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseCardTwo;