import { memo } from 'react';

import { useAppState } from 'hooks/useStore';
import { sessionUserTypeEnum } from 'redux/session/session.const';

const SessionDetailRoleView = memo(({ sessionUserInfo }) => {
    const { user } = useAppState((state) => state.user)
    const { sessionUserDetail } = useAppState((state) => state.session)

    return (sessionUserDetail?.data?.result && sessionUserInfo?.userType) ? (
        <div className={"flex items-center justify-start gap-2"}>
            <span className={"font-bodyPri font-normal text-base text-text-800"}>
                {"Me As: "}
            </span>
            <div className={"flex items-center justify-center gap-1"}>
                <span className={"font-bodyPri font-medium text-md text-text-900"}>
                    {sessionUserTypeEnum[sessionUserInfo?.userType?.toUpperCase()]?.label}
                </span>
                <span className={"font-bodyPri font-normal text-sm text-text-900"}>
                    {(sessionUserDetail?.data?.result?.owner_user?.id === user?.user?.userId) && "(Owner)"}
                </span>
            </div>
        </div>
    ) : null
})

export default SessionDetailRoleView;