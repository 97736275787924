import {
    Container,
    Content,
} from './TeachWithUsFAQStyle';
import { dataFAQ } from './TeachWithUsFAQData';
import Accordion from 'components/accordion/Accordion';

const TeachWithUsFAQ = () => {
    return (
        <Container id={"faq"}>
            <Content>
                <Accordion
                    title={'Frequently Asked Questions?'}
                    dataFAQ={dataFAQ}
                />
            </Content>
        </Container>
    )
}

export default TeachWithUsFAQ;
