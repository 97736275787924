import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import ComponentLoader from 'components/loader/ComponentLoader';

import AddBorderButton from 'pages/auth/edulyteLms/commonComponents/AddBorderButton';

import PriceInformation from './PriceInformation';
import ProductInformation from './ProductInformation';

import { createProductDetail, getProductDetail } from 'redux/product/product.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setLmsCourseDetailData } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';
import { resetAddProductDetail, resetAddProductPriceDetailPayload, resetProductDetail, setAddProductPriceDetailPayload } from 'redux/product/product.slice';
import { modalConst } from 'redux/local/local.const';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';
import { addProductPriceDetailPayload, productPriceDiscountTypeEnum, productPriceModelEnum, productPriceStatusEnum, productPriceTypeEnum, productTypeEnum } from 'redux/product/product.const';

const CourseProductDetail = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { productDetail, addProductDetail } = useAppState((state) => state.product)
    const { lmsCourseDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (lmsCourseDetail?.data?.product_lms_course?.product?.id) {
            dispatcher(getProductDetail(lmsCourseDetail?.data?.product_lms_course?.product?.id))
        }

        return () => {
            dispatcher(resetProductDetail())
        }
    }, [lmsCourseDetail?.data?.product_lms_course?.product?.id])

    useEffect(() => {
        if (addProductDetail?.data) {
            dispatcher(resetAddProductPriceDetailPayload())
            dispatcher(setAddProductPriceDetailPayload({
                ...addProductPriceDetailPayload,
                product_id: addProductDetail?.data?.id,
                type: productPriceTypeEnum.ONE_TIME.value,
                currency_code: masterCurrencyCodeEnum?.USD?.value,
                discount_type: productPriceDiscountTypeEnum?.PERCENTAGE?.value,
                discount_pct: 10,
                price_model: productPriceModelEnum?.STANDARD?.value,
                status: productPriceStatusEnum?.ACTIVE?.value,
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.PRODUCT_PRICE_DETAIL_MODAL.stateKey]: true
            }))
            dispatcher(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                product_lms_course: {
                    ...lmsCourseDetail?.data?.product_lms_course,
                    id: addProductDetail?.data?.id
                }
            }))
            dispatcher(resetAddProductDetail())
        }
    }, [addProductDetail?.data])

    const onHandleAddProduct = useCallback(() => {
        if (!lmsCourseDetail?.data?.title) {
            toast.warn("Please add title!")
            return;
        }
        const body = {
            lms_course_id: lmsCourseDetail?.data?.id,
            title: lmsCourseDetail?.data?.title,
            thumbnail_pic_url: lmsCourseDetail?.data?.thumbnail_pic_url,
            type: productTypeEnum?.LMS_COURSE?.value,
            owner_user_id: user?.user?.userId
        }
        if (lmsCourseDetail?.data?.sub_title) {
            body["description"] = lmsCourseDetail?.data?.sub_title
        }
        if (lmsCourseDetail?.data?.thumbnail_pic_url) {
            body["thumbnail_pic_url"] = lmsCourseDetail?.data?.thumbnail_pic_url
        }

        dispatcher(createProductDetail(body))
    }, [lmsCourseDetail?.data])

    if (productDetail?.isLoading || addProductDetail?.isLoading) {
        return (
            <ComponentLoader isLoading={productDetail?.isLoading || addProductDetail?.isLoading} />
        )
    }

    if (productDetail?.message || addProductDetail?.message) {
        return (
            <div className={"w-full flex items-center justify-center"}>
                <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                    {productDetail?.message || addProductDetail?.message}
                </span>
            </div>
        )
    }

    return (
        <div className={"w-full flex flex-col gap-3"}>
            {!lmsCourseDetail?.data?.product_lms_course?.product?.id &&
                <AddBorderButton
                    isLoading={addProductDetail?.isLoading}
                    btnTitle={"Add Price(s)"}
                    onHandleAdd={onHandleAddProduct}
                />
            }
            {(lmsCourseDetail?.data?.product_lms_course?.product?.id) &&
                <>
                    <hr className={"border border-text-300"} />
                    <div className={"w-full flex flex-col gap-8"}>
                        <PriceInformation />
                        <ProductInformation />
                    </div>
                </>
            }
        </div>
    )
}

export default CourseProductDetail;