import { createSlice } from "@reduxjs/toolkit";
import { NOTIFICATION_INITIAL_STATE } from "redux/notification/notification.initialState";

export const notification = createSlice({
    name: "notification",
    initialState: NOTIFICATION_INITIAL_STATE,
    reducers: {
        // reducers for notification
        setSendOtpLoading: (state, { payload }) => {
            state.sendOtp.isLoading = payload
        },
        setSendOtp: (state, { payload }) => {
            state.sendOtp.sendOtp = payload
            state.sendOtp.errorMsg = NOTIFICATION_INITIAL_STATE.sendOtp.errorMsg
        },
        setSendOtpErrorMsg: (state, { payload }) => {
            state.sendOtp.errorMsg = payload
            state.sendOtp.sendOtp = NOTIFICATION_INITIAL_STATE.sendOtp.sendOtp
        },
        setClearSendOtp: (state) => {
            state.sendOtp = NOTIFICATION_INITIAL_STATE.sendOtp
        },

        // verify otp reducers
        setVerifyOtpLoading: (state, { payload }) => {
            state.verifyOtp.isLoading = payload
        },
        setVerifyOtp: (state, { payload }) => {
            state.verifyOtp.verifyOtp = payload
            state.verifyOtp.errorMsg = NOTIFICATION_INITIAL_STATE.verifyOtp.errorMsg
        },
        setVerifyOtpErrorMsg: (state, { payload }) => {
            state.verifyOtp.errorMsg = payload
            state.verifyOtp.verifyOtp = NOTIFICATION_INITIAL_STATE.verifyOtp.verifyOtp
        },
        setClearVerifyOtp: (state) => {
            state.verifyOtp = NOTIFICATION_INITIAL_STATE.verifyOtp
        },
    }
})

export const {
    setSendOtpLoading,
    setSendOtp,
    setSendOtpErrorMsg,
    setClearSendOtp,

    setVerifyOtpLoading,
    setVerifyOtp,
    setVerifyOtpErrorMsg,
    setClearVerifyOtp,
} = notification.actions
export default notification.reducer;