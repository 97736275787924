import format from 'string-format';

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance"
import { url } from './certificate.const';


class CertificateServices {

    getUserCertificateList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_CERTIFICATE_LIST, params),
            { params: query }
        )
        return response;
    }

    createCertificate = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_CERTIFICATE),
            body
        )

        return response;
    }

    getCertificateTemplateList = async () => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_CERTIFICATE_TEMPLATE_LIST)
        )
        return response;
    }

    getCertificateDetails = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_CERTIFICATE_DETAILS, params),
        )
        return response;
    }

    updateCertificate = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_CERTIFICATE, params),
            body
        )
        return response;
    }

    getStudentCertificateList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.STUDENT_CERTIFICATES, params),
            { params: query }
        )
        return response;
    }

    verifyCertificate = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.CERTIFICATE_VERIFY),
            { params: query }
        )
        return response;
    }

    deleteCertificate = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_CERTIFICATE, params),
        )
        return response;
    }

    cloneCertificate = async ({ params }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CLONE_CERTIFICATE, params),
        )
        return response;
    }

}
export default new CertificateServices();