export const MEETING_INITIAL_STATE = {
    meeting: {
        isLoading: false,
        meeting: null,
        errorMsg: null,
    },

    joinMeeting: {
        isLoading: false,
        data: null,
        message: null
    }
}