import { useState } from "react";

import Menu from "@mui/material/Menu";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";

import { BsThreeDotsVertical } from "react-icons/bs";

import { menuItemsConst } from "pages/auth/drive/data";

import { viewFileDetail } from "redux/drive/drive.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setShareDriveFileDetailPayload } from "redux/drive/drive.slice";
import { modalConst } from "redux/local/local.const";
import { shareDriveFileDetailPayload } from "redux/drive/drive.const";

const useStyles = makeStyles({
    root: {},
});

const DriveMenu = ({ fileItems }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { modal } = useAppState((state) => state.local)

    const dispatcher = useAppDispatcher()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = async (ele) => {
        setAnchorEl(null);

        if (ele.value === menuItemsConst?.VIEW?.value) {
            try {
                const response = await dispatcher(viewFileDetail(fileItems?.id));
                const fileUrl = response.data.file_url;
                window.open(fileUrl, "_blank")
            } catch (error) {
                console.error("Error downloading file:", error);
                // Handle the error and display an error message to the user
            }
        }
        if (ele.value === menuItemsConst?.SHARE?.value) {
            dispatcher(setShareDriveFileDetailPayload({
                ...shareDriveFileDetailPayload,
                fileId: fileItems?.id,
                fileName: fileItems?.file_name
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst?.SHARE_DRIVE_FILE_MODAL?.stateKey]: true
            }))
        }
    };

    return (
        <div className="">
            <div onClick={handleClick}>
                <BsThreeDotsVertical className={"text-xl md:text-2xl text-text-800 rotate-[90deg] cursor-pointer"} />
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                classes={{
                    root: classes.root,
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {Object.values(menuItemsConst).map((ele, idx) =>
                    <MenuItem key={idx} value={ele.value} onClick={() => handleClose(ele)}>
                        {ele.label}
                    </MenuItem>
                )
                }
            </Menu>
        </div>
    )
}


export default DriveMenu;