import { useEffect } from "react";

import Pagination from 'components/pagination/Pagination';

import ComponentLoader from "components/loader/ComponentLoader";
import { formatFileSize } from "pages/auth/drive/data";

import { getUserMeetingRecordingList } from 'redux/drive/drive.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetUserMeetingRecordingList } from 'redux/drive/drive.slice';

import { dayjs, timeZone } from "utils/dateTime.utils";

const RecordedSessions = () => {
    const { userMeetingRecordingList } = useAppState((state) => state.drive)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(getUserMeetingRecordingList({ page: 1, records: 10 }))

        return () => {
            dispatcher(resetUserMeetingRecordingList())
        }
    }, [])

    const onHandleChangePage = (page) => {
        if (userMeetingRecordingList?.isLoading) return;

        dispatcher(getUserMeetingRecordingList({ page: page, records: 10 }))
    }

    return (
        <div className={"w-full min-h-screen bg-white"}>
            <div className={"w-full flex flex-wrap items-center justify-start gap-5"}>
                <ComponentLoader isLoading={userMeetingRecordingList?.isLoading} className={"h-screen"} />
                {userMeetingRecordingList?.data?.results?.map((recording, index) => (
                    <div key={index} className='w-[28rem] h-[20rem] shadow-all bg-white p-3 space-y-5 rounded-md'>
                        <div className='w-full h-52 flex items-center justify-center rounded-lg overflow-hidden'>
                            <video
                                src={recording?.file_url}
                                className={'w-full'}
                                controls
                            />
                        </div>
                        <div className='flex flex-col items-start justify-start gap-3'>
                            <span className='font-bodyPri font-medium text-text-900 text-md tracking-wide'>
                                {recording?.file_name}
                            </span>
                            <div className='w-full flex justify-between items-center gap-3'>
                                <span className='font-bodyPri font-normal text-text-700 text-base tracking-wide'>
                                    {`${dayjs().tz(timeZone).format("ddd, DD MMM")} at ${dayjs()?.tz(timeZone)?.format("hh: mm a")}`}
                                </span>
                                <span className='font-bodyPri font-normal text-text-700 text-sm tracking-wide'>
                                    {formatFileSize(recording?.file_size)}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
                {(userMeetingRecordingList?.data && userMeetingRecordingList?.data?.totalPages > 1) &&
                    <div className="w-full flex justify-center items-center">
                        <Pagination
                            page={userMeetingRecordingList?.data?.page}
                            totalPages={userMeetingRecordingList?.data?.totalPages}
                            onChangePage={(page) => onHandleChangePage(page)}
                        />
                    </div>
                }
                {userMeetingRecordingList?.data && userMeetingRecordingList?.data?.results?.length === 0 &&
                    <div className="w-full h-screen rounded-md p-5 flex justify-center items-center font-bodyPri font-medium text-text-800 text-lg tracking-wide whitespace-nowrap">
                        {"No recording found!"}
                    </div>
                }
                {!userMeetingRecordingList?.isLoading && (userMeetingRecordingList?.message || !userMeetingRecordingList?.data) &&
                    <div className={"w-full h-screen rounded-md p-5 flex justify-center items-center font-bodyPri font-normal text-red-500 text-lg tracking-wide"}>
                        {userMeetingRecordingList?.message || "No recording found!"}
                    </div>
                }
            </div>
        </div>
    )
}

export default RecordedSessions;