import { useContext } from 'react';

import { DragContext } from './Drag';

function DropGuide({ as, dropId, ...props }) {
    const { drop } = useContext(DragContext);

    let Component = as || "div";
    return (drop === dropId) ? <Component {...props} /> : null;
};

export default DropGuide;