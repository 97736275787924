import { useMemo, useState } from 'react';

import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FaSpinner } from 'react-icons/fa';
import { BiSearch } from 'react-icons/bi';

import { cn } from 'utils/cn.utils';

const BootstrapButton = styled(Button)(({ theme, menuOpen }) => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: `1px solid ${menuOpen ? '#9e9e9e' : '#9e9e9e'}`,
    borderRadius: 8,
    lineHeight: 1.5,
    width: "100%",
    '&:hover': {
        border: `1px solid ${menuOpen ? '#9e9e9e' : '#9e9e9e'}`,
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        border: `1px solid ${menuOpen ? '#9e9e9e' : '#9e9e9e'}`,
        outline: 'none',
        backgroundColor: 'white',
    },
    '&:focus': {
        outline: 'none',
        backgroundColor: 'white',
        boxShadow: 'none',
    },
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme, customMenuStyles }) => ({
    '& .MuiPaper-root': {
        borderRadius: 8,
        marginTop: theme.spacing(1),
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
        ...customMenuStyles
    },
}));

export default function DropdownMenu({
    isLoading = false,
    isShowArrowIcon = true,
    isSearchEnabled = false,
    isShowEmptyContent = false,
    options = [],
    selectedOption = null,
    selectedMenuItem = null,
    onHandleSelect = () => { },
    EmptyMenuListContent = () => { },
    OptionChild = () => { },
    menuListProps,
    customMenuStyles = { width: "47ch", minWidth: 180 },
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchQuery, setSearchQuery] = useState("")

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectOption = (option) => {
        setAnchorEl(null);
        onHandleSelect(option)
    }

    const onHandleSearchInput = (event) => {
        setSearchQuery(event.target.value)
    }

    const filteredOptions = useMemo(() => {
        if (!!options?.length) {
            let filteredList = options
            if (searchQuery) {
                filteredList = options?.filter((option) => option?.label?.toLowerCase()?.includes(searchQuery?.toLowerCase()))
                return filteredList
            } else return filteredList
        } else return []
    }, [options, searchQuery])

    return (
        <div className={"w-full"}>
            <BootstrapButton
                id={"demo-customized-button"}
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup={"true"}
                aria-expanded={open ? 'true' : undefined}
                variant={"outlined"}
                disableElevation
                onClick={handleClick}
                menuOpen={open}
            >
                <div className={"w-full flex items-center justify-between gap-3"}>
                    {selectedOption}
                    {isLoading &&
                        <FaSpinner className={"animate-spin text-xl text-primary-dark"} />
                    }
                    {(!isLoading && isShowArrowIcon) &&
                        <KeyboardArrowDownIcon
                            className={cn(
                                "text-xl text-primary-dark",
                                open ? "rotate-180" : ""
                            )}
                        />
                    }
                </div>
            </BootstrapButton>
            <StyledMenu
                id={"demo-customized-menu"}
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                customMenuStyles={customMenuStyles}
                {...menuListProps}
            >
                {isSearchEnabled &&
                    <div className={"sticky top-0 z-40 bg-white flex items-center border-b border-gray-300 px-3 py-2 w-full focus-within:border-b-primary-main"}>
                        <input
                            type={"text"}
                            value={searchQuery}
                            onChange={onHandleSearchInput}
                            placeholder={"Search..."}
                            className={"w-full font-bodyPri outline-none"}
                            autoFocus={true}
                        />
                        <BiSearch className="text-text-600 text-lg" />
                    </div>
                }
                <div className={"w-full max-h-60 scrollbar-thin"}>
                    {(!isLoading && !filteredOptions?.length) &&
                        <div className={"w-full px-3 py-2 flex items-center justify-center"}>
                            <span className={"font-bodyPri font-normal text-text-800 text-sm italic"}>
                                {"No result found"}
                            </span>
                        </div>
                    }
                    {(!isLoading && !!filteredOptions?.length) && filteredOptions?.map((option, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => handleSelectOption(option)}
                            selected={selectedMenuItem && (option?.value === selectedMenuItem)}
                            disableRipple
                        >
                            <OptionChild option={{ ...option, index: index }} />
                        </MenuItem>
                    ))}
                </div>
            </StyledMenu>
        </div>
    );
}