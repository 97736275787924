import React from "react";

//Context imports
import { useChat } from "../../contexts/chatContext";
import { useUser } from "../../contexts/userContext";

//Helper functions
import getTimestamps from "../../helpers/getTimestamps";
import showProfilePicture from "../../helpers/showProfilePicture";

//Component imports
import ChatViewMessageRead from "..//ChatViewMessageRead/ChatViewMessageRead";

//Style imports
import {
  StyldChatViewPaymentRequest,
  StyledChatViewPaymentRequestCourse,
  StyledChatViewPaymentRequestAmount,
  StyledChatViewPaymentRequestStatus,
  StyldChatViewPaymentRequestContainer,
  StyledChatViewPaymentRequestTime,
} from "./ChatViewPaymentRequest.styles";

export default function ChatViewPaymentRequest({ message, isGroup }) {
  const { user } = useUser();
  const { dispatch, selectedChat, messages, groupMembers } = useChat();
  const { status, course, amount, paymentStatus, sentAt, sender } = message;

  const { userId } = user;

  const isSender = userId === message.sender;

  const timeToDisplay = getTimestamps(sentAt);

  function openMakePayment() {
    if (isSender) return;
    dispatch({
      type: "SET_MAKE_PAYMENT",
      payload: message,
    });
  }

  function buttonText() {
    if (paymentStatus === "pending" && isSender) return "Pending";
    if (paymentStatus === "pending" && !isSender) return "Pay";
    if (paymentStatus === "paid" && isSender) return "Paid";
    if (paymentStatus === "paid" && !isSender) return "Paid";
  }

  const getButtonText = buttonText();

  const otherUser = selectedChat.users.filter(
    (chatUser) => chatUser !== userId
  )[0];

  function getImageToDisplay() {
    if (selectedChat.type === "group") {
      const photoUrl = groupMembers.filter((member) => member.id === sender)[0]
        ?.image;
      return photoUrl;
    } else {
      return selectedChat[otherUser]?.image;
    }
  }

  const imageToDisplay = getImageToDisplay();

  const showPhoto = showProfilePicture(message, messages);

  return (
    <StyldChatViewPaymentRequestContainer isSender={isSender}>
      {showPhoto && !isSender && (
        <img
          className="w-12 h-12 rounded-full mr-2"
          src={imageToDisplay}
          alt="profile"
        />
      )}
      {!showPhoto && <div className="w-12 h-12 rounded-full mr-2"></div>}
      <StyldChatViewPaymentRequest isSender={isSender}>
        <StyledChatViewPaymentRequestCourse>
          <p>{course}</p>
        </StyledChatViewPaymentRequestCourse>
        <StyledChatViewPaymentRequestAmount>
          <p>{`$${amount}`}</p>
        </StyledChatViewPaymentRequestAmount>
        <StyledChatViewPaymentRequestStatus
          isSender={isSender}
          onClick={openMakePayment}
        >
          <p>{getButtonText}</p>
        </StyledChatViewPaymentRequestStatus>
        <StyledChatViewPaymentRequestTime isSender={isSender}>
          {timeToDisplay}
        </StyledChatViewPaymentRequestTime>
      </StyldChatViewPaymentRequest>
      {isSender && !isGroup && <ChatViewMessageRead seen={status === "seen"} />}
    </StyldChatViewPaymentRequestContainer>
  );
}
