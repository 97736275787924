import { useState, useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";

import { MdOutlineClose } from "react-icons/md";

import FilterIcon from "components/pageFilter/FilterIcon";
import ClearFilter from "components/pageFilter/ClearFilter";
import RefreshAndAddBtn from "components/common-components/RefreshAndAddBtn";

import { downloadsFilters, getDownloadsListPayload, searchParamsInfo } from "../../data";
import DownloadTitleFilter from "./DownloadTitleFilter";
import DownloadStatusFilter from "./DownloadStatusFilter";
import DownloadDateFilter from "./DownloadDateFilter";

import { getOwnerUserDownloadsList } from "redux/downloads/downloads.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

const FilterSection = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { ownerUserDownloadsList } = useAppState((s) => s.downloads)

    const dispatcher = useAppDispatcher();
    const location = useLocation()

    const [activeFilter, setActiveFilter] = useState(null)
    const [mobileFilter, setMobileFilter] = useState(false)

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        if (ownerUserDownloadsList?.data) {
            getDownloadList()
        }
    }, [
        searchQueryParams.get(searchParamsInfo.page.key),
        searchQueryParams.get(searchParamsInfo.title.key),
        searchQueryParams.get(searchParamsInfo.status.key),
        searchQueryParams.get(searchParamsInfo.minCreatedAt.key),
        searchQueryParams.get(searchParamsInfo.maxCreatedAt.key)
    ])

    const getDownloadList = async () => {
        const requestDataPayload = await getDownloadsListPayload(searchQueryParams)

        dispatcher(getOwnerUserDownloadsList(user?.user?.userId, { ...requestDataPayload }))
    }

    const onHandleCreateNewDownloadDetail = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.addDownloadModal.key]: {
                ...modal[modalConst.addDownloadModal.key],
                isVisible: true,
                title: "Add Download"
            }
        }))
    }

    const DownloadFilters = () => {
        return (
            <>
                {Object?.values(downloadsFilters)?.map((filterItem, index) => (
                    <div key={index}>
                        {(filterItem.key === searchParamsInfo.title.key) &&
                            <DownloadTitleFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                        {(filterItem.key === searchParamsInfo.status.key) &&
                            <DownloadStatusFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                        {(filterItem.key === searchParamsInfo.date.key) &&
                            <DownloadDateFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                    </div>
                ))}
            </>
        )
    }

    return (
        <div className={"relative w-full px-6 py-3 bg-white shadow-md rounded-lg"}>
            <div className={"flex flex-col items-center justify-center gap-3 sm:flex-row sm:justify-between sm:gap-5"}>
                <AnimatePresence initial={mobileFilter}>
                    {mobileFilter && (
                        <motion.div
                            initial={{ x: -200 }}
                            animate={{ x: 0 }}
                            exit={{ x: -200 }}
                            transition={{ duration: 0.3 }}
                            className='absolute left-0 top-0 h-screen w-60 flex flex-col gap-5 p-5 bg-white rounded lg:hidden shadow-md z-10'
                        >
                            <div className='flex justify-between items-center'>
                                <h3 className='text-lg font-bodyPri font-semibold text-text-900'>{"Filter"}</h3>
                                <MdOutlineClose size={20} onClick={() => setMobileFilter(false)} className='cursor-pointer' />
                            </div>
                            <DownloadFilters />
                            <ClearFilter />
                        </motion.div>
                    )}
                </AnimatePresence>
                <AnimatePresence initial={mobileFilter}>
                    <div className={'hidden w-full lg:flex flex-grow flex-wrap justify-center items-center sm:justify-start gap-3'}>
                        <FilterIcon iconTooltip={"Filter Downloads"} />
                        <DownloadFilters />
                        <ClearFilter />
                    </div>
                </AnimatePresence>
                <div className={"flex w-full items-center justify-between lg:justify-end gap-3"}>
                    <div className='flex items-center gap-3'>
                        <div onClick={() => setMobileFilter(!mobileFilter)} className={'block lg:hidden text-primary-main font-medium hover:text-secondary-dark whitespace-nowrap cursor-pointer'}>
                            {"Filter"}
                        </div>
                    </div>
                    <RefreshAndAddBtn
                        btnName={"Add New"}
                        onHandleRefresh={getDownloadList}
                        onHandleAddBtn={onHandleCreateNewDownloadDetail}
                    />
                </div>
            </div>
        </div>
    );
};

export default FilterSection;