import {
    Container,
    Content,
    Header,
    Heading,
    SubHeading,
    ItemContainer,
} from './PersonalisedTutoringStyle';
import { tutoringData } from './PersonalisedTutoringData';
import TutoringItem from './TutoringItem';

const PersonalisedTutoring = () => {
    return (
        <Container>
            <Content>
                <Header>
                    <Heading>{'Personalised tutoring at its best'}</Heading>
                    <SubHeading>{'Bots are learning everyday, human can do much better. Get help from certified and capable experts eager to see you succeed.'}</SubHeading>
                </Header>
                <ItemContainer>
                    {tutoringData.map((tutoringItem, index) => (
                        <TutoringItem key={index} tutoringItem={tutoringItem} />
                    ))}
                </ItemContainer>
            </Content>
        </Container>
    )
}

export default PersonalisedTutoring;
