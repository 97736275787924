export const url = {
    /* tutor lecture */
    GET_TUTOR_LMS_LECTURE_LIST: "lms/lectures/tutor-lectures",
    GET_TUTOR_LMS_LECTURE_DETAIL: "lms/lectures/tutor-lectures/{id}",

    /* lecture */
    CREATE_LMS_LECTURE_DETAIL: "lms/lectures/lectures",
    UPDATE_LMS_LECTURE_DETAIL: "lms/lectures/lectures/{id}",
    DELETE_LMS_LECTURE_DETAIL: "lms/lectures/lectures/{id}",

    /* lecture user */
    CREATE_LMS_LECTURE_USER_DETAIL: "lms/lectures/users",
    GET_LMS_LECTURE_USER_LIST: "lms/lectures/users",
    GET_LMS_LECTURE_USER_DETAIL: "lms/lectures/users/{id}",
    UPDATE_LMS_LECTURE_USER_DETAIL: "lms/lectures/users/{id}",
    DELETE_LMS_LECTURE_USER_DETAIL: "lms/lectures/users/{id}",

    /* setting */
    CREATE_LMS_LECTURE_SETTING_DETAIL: "lms/lectures/settings",
    GET_LMS_LECTURE_SETTING_LIST: "lms/lectures/settings",
    GET_LMS_LECTURE_SETTING_DETAIL: "lms/lectures/settings/{id}",
    UPDATE_LMS_LECTURE_SETTING_DETAIL: "lms/lectures/settings/{id}",
    DELETE_LMS_LECTURE_SETTING_DETAIL: "lms/lectures/settings/{id}",
}

export const lmsLectureStatusEnum = {
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
}

export const lmsLectureVisibilityEnum = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    }
}

export const lmsLectureInstructorPermissionEnum = {
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    }

}

export const addLmsLectureInstructorDetailPayload = {
    userId: null,
    permission: null,
    lectureInstructorId: null

}