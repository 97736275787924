import { createSlice } from "@reduxjs/toolkit";

import { PAYOUT_INITIAL_STATE } from "redux/payout/payout.initialState";

const payout = createSlice({
    name: "payout",
    initialState: PAYOUT_INITIAL_STATE,
    reducers: {
        // reducers for get user payout list
        setUserPayoutListLoading: (state, { payload }) => {
            state.userPayoutList.isLoading = payload
        },
        setUserPayoutList: (state, { payload }) => {
            state.userPayoutList.userPayoutList = payload
            state.userPayoutList.errorMsg = PAYOUT_INITIAL_STATE.userPayoutList.errorMsg
        },
        setUserPayoutListErrorMsg: (state, { payload }) => {
            state.userPayoutList.errorMsg = payload
            state.userPayoutList.userPayoutList = PAYOUT_INITIAL_STATE.userPayoutList.userPayoutList
        },
        setClearUserPayoutList: (state) => {
            state.userPayoutList = PAYOUT_INITIAL_STATE.userPayoutList
        },

        // reducers for user payout details
        setUserPayoutDetailLoading: (state, { payload }) => {
            state.userPayoutDetail.isLoading = payload
        },
        setUserPayoutDetail: (state, { payload }) => {
            state.userPayoutDetail.userPayoutDetail = payload
            state.userPayoutDetail.errorMsg = PAYOUT_INITIAL_STATE.userPayoutList.errorMsg
        },
        setUserPayoutDetailErrorMsg: (state, { payload }) => {
            state.userPayoutDetail.errorMsg = payload
            state.userPayoutDetail.userPayoutDetail = PAYOUT_INITIAL_STATE.userPayoutDetail.userPayoutDetail
        },
        setClearUserPayoutDetail: (state) => {
            state.userPayoutDetail = PAYOUT_INITIAL_STATE.userPayoutDetail
        },

        // reducers for add user payout details
        setAddUserPayoutDetailLoading: (state, {payload}) => {
            state.addUserPayoutDetail.isLoading = payload
        },
        setAddUserPayoutDetailErrorMsg: (state, {payload}) => {
            state.addUserPayoutDetail.errorMsg = payload
        },
        setClearAddUserPayoutDetail: (state) => {
            state.addUserPayoutDetail = PAYOUT_INITIAL_STATE.addUserPayoutDetail
        }
    }
})

export const {
    setUserPayoutListLoading,
    setUserPayoutList,
    setUserPayoutListErrorMsg,
    setClearUserPayoutList,

    setUserPayoutDetailLoading,
    setUserPayoutDetail,
    setUserPayoutDetailErrorMsg,
    setClearUserPayoutDetail,

    setAddUserPayoutDetailLoading,
    setAddUserPayoutDetailErrorMsg,
    setClearAddUserPayoutDetail
} = payout.actions

export default payout.reducer;