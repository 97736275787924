import { useState, useEffect, useMemo } from 'react';

import SessionActionTitle from 'components/modals/sessionActionModal/SessionActionTitle';
import SessionActionInput from 'components/modals/sessionActionModal/SessionActionInput';
import SessionActionFooter from 'components/modals/sessionActionModal/SessionActionFooter';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { sessionStatusEnum } from 'redux/session/session.const';
import { modalConst } from 'redux/local/local.const';

const SessionStatusActionModal = () => {
    const { user } = useAppState((state) => state.user)
    const {
        sessionUserDetail,
        startSessionUserDetail,
        acceptSessionUserDetail,
        declineSessionUserDetail,
        rescheduleSessionUserDetail,
        completeSessionUSerDetail
    } = useAppState((s) => s.session)
    const { modal } = useAppState((s) => s.local)

    const dispatcher = useAppDispatcher()

    const [selectedAction, setSelectedAction] = useState(sessionStatusEnum[sessionUserDetail?.data?.result?.session_users?.find((sessionUser) => sessionUser?.user?.id === user?.user?.userId)?.status?.toUpperCase()])

    useEffect(() => {
        if (startSessionUserDetail?.data?.result || acceptSessionUserDetail?.data?.result || declineSessionUserDetail?.data?.result || rescheduleSessionUserDetail?.data?.result || completeSessionUSerDetail?.data?.result) {
            dispatcher(setModal({
                ...modal,
                [modalConst.SESSION_ACTION_MODAL.stateKey]: false,
                [modalConst.SESSION_DETAIL_MODAL.stateKey]: true,
            }))
        }
    }, [startSessionUserDetail?.data?.result, acceptSessionUserDetail?.data?.result, declineSessionUserDetail?.data?.result, rescheduleSessionUserDetail?.data?.result, completeSessionUSerDetail?.data?.result])

    const sessionUserInfo = useMemo(() => {
        if (sessionUserDetail?.data?.result) {
            const currentSessionUser = sessionUserDetail?.data?.result?.session_users?.find((sessionUser) => sessionUser?.user?.id === user?.user?.userId)
            setSelectedAction(sessionStatusEnum[currentSessionUser?.status?.toUpperCase()])
            return currentSessionUser;
        }
    }, [sessionUserDetail?.data?.result, user?.user?.userId])

    return (
        <div className={"space-y-5 overflow-x-hidden select-none"}>

            <div className={"min-h-[24rem] block space-y-5 pt-2"}>

                <SessionActionTitle />

                <SessionActionInput
                    sessionUserInfo={sessionUserInfo}
                    selectedAction={selectedAction}
                    setSelectedAction={setSelectedAction}
                />

            </div>
            <SessionActionFooter
                sessionUserInfo={sessionUserInfo}
                selectedAction={selectedAction}
            />
        </div>
    )
}

export default SessionStatusActionModal;