import {
    LogInCardContent,
    SubHeading,
    LogInFormWrapper,
    TextLink,
} from 'components/modals/authorizationModal/Authorization.style';
import LogInForm from 'components/logInForm/LogInForm';

import { tabConfig } from '../data';

import { createLogIn } from "redux/auth/auth.request";

import { useAppDispatcher } from 'hooks/useStore';

const pattern = /[a-zA-z]{1}/;

const LogInContainer = ({ setCurrentActiveTab }) => {

    const dispatcher = useAppDispatcher();

    const formSubmit = async (userData) => {
        if (pattern?.test(userData.emailOrPhone) && userData?.password) {
            const user = {
                email: userData.emailOrPhone,
                password: userData.password,
            }
            dispatcher(createLogIn(user))
            return;
        }
        if (pattern?.test(userData.emailOrPhone) && userData?.otp) {
            const user = {
                email: userData.emailOrPhone,
                otpId: userData?.otpId,
                otp: String(userData?.otp)
            }
            dispatcher(createLogIn(user))
            return;
        }
    }

    return (
        <>
            <LogInCardContent>
                <SubHeading>
                    {`Don't have an account?`}
                    <TextLink
                        onClick={() => setCurrentActiveTab(tabConfig?.SIGN_UP)}>
                        {'Sign up'}
                    </TextLink>
                </SubHeading>
                <LogInFormWrapper>
                    <LogInForm formSubmit={formSubmit} />
                </LogInFormWrapper>
            </LogInCardContent>
        </>
    )
}

export default LogInContainer;