import * as React from 'react';
import { cn } from "utils/cn.utils";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { useAppState } from 'hooks/useStore';
import { currencyRateConversion } from 'utils/generators.utils';

const minDistance = 0.1;

export default function PriceRangeSlider({
    minValue,
    maxValue,
    isShowList,
    setIsShowList,
    onApplyFilter,
    onClearFilter,
    className
}) {
    const { locals } = useAppState((s) => s.local)
    const { currencyRateList } = useAppState((state) => state.master)
    const targetCurrencyRate = currencyRateList?.currencyRateList[(locals?.currencyCode || "USD")]

    const [value, setValue] = React.useState([0, 100]);
    const [localCurrency, setLocalCurrency] = React.useState({ minLocal: minValue, maxLocal: maxValue })

    React.useEffect(() => {
        let minVal = (minValue * 100) / (targetCurrencyRate * 500)
        let maxVal = (maxValue * 100) / (targetCurrencyRate * 500)
        setValue([minVal, maxVal])
    }, [minValue, maxValue])

    const handleChange = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setValue([parseFloat(Math.min(newValue[0], value[1] - minDistance)?.toFixed(2)), value[1]]);
            setLocalCurrency({ ...localCurrency, minLocal: ((parseFloat(newValue[0] * (targetCurrencyRate * 500))) / 100)?.toFixed(2) })
        } else {
            setValue([value[0], parseFloat(Math.max(newValue[1], value[0] + minDistance)?.toFixed(2))]);
            setLocalCurrency({ ...localCurrency, maxLocal: ((parseFloat(newValue[1] * (targetCurrencyRate * 500))) / 100)?.toFixed(2) })
        }
    };
    const onHandleApply = () => {
        onApplyFilter({ minValue: localCurrency?.minLocal, maxValue: localCurrency?.maxLocal })
        setIsShowList(false)
    }

    return (
        <Transition appear show={isShowList} as={Fragment}>
            <Dialog
                as="div"
                className={cn("z-30 overflow-y-auto", className)}
                open={isShowList}
                onClose={setIsShowList}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Box sx={{ width: 320 }} className={"px-3 py-2 bg-white rounded-xl space-y-2"}>
                        <div className={"font-bodyPri font-normal text-text-900 text-lg"}>
                            {/* {currencyRateConversion(locals?.currencyCode, localCurrency?.minLocal) + " - " + currencyRateConversion(locals?.currencyCode, localCurrency?.maxLocal)} */}
                            {locals?.currencyCode + " " + parseFloat(localCurrency?.minLocal)?.toFixed(2) + " - " + locals?.currencyCode + " " + parseFloat(localCurrency?.maxLocal)?.toFixed(2)}
                        </div>
                        <div className={"px-3"}>
                            <Slider
                                getAriaLabel={() => 'Minimum distance'}
                                value={value}
                                onChange={handleChange}
                                disableSwap
                            />
                        </div>
                        <div className={"w-full flex items-center justify-between gap-3 py-2"}>
                            <span
                                className={"font-bodyPri font-medium text-primary-dark text-sm underline cursor-pointer"}
                                onClick={() => onClearFilter()}
                            >
                                {"Clear"}
                            </span>
                            <div className={cn(
                                "px-4 py-1 rounded-full border border-primary-dark cursor-pointer",
                                "font-bodyPri font-medium text-primary-dark text-sm tracking-wide hover:bg-primary-dark hover:text-text-50"
                            )}
                                onClick={onHandleApply}
                            >
                                {"Apply Filters"}
                            </div>
                        </div>
                    </Box>
                </Transition.Child>
            </Dialog>
        </Transition>
    );
}