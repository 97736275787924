import { useCallback, useState } from 'react';

import { SiGooglemeet } from 'react-icons/si'; 
import { MdZoomOutMap } from "react-icons/md";
import { AiOutlineSkype } from "react-icons/ai";
import { SiGotomeeting } from "react-icons/si";
import { MdOutlineContentCopy } from 'react-icons/md';
import { FiCheckSquare } from 'react-icons/fi';

import { useAppState } from 'hooks/useStore';

import { validateGoogleMeetUrl, validateSkypeMeetingUrl, validateZoomMeetingUrl } from 'utils/validation-functions';

const EventlyMeetingLink = () => {
    const { userEventlyDetail } = useAppState((state) => state.evently)

    const [copiedURL, setCopiedURL] = useState(false)

    const onHandleMeetingLink = useCallback(() => {
        window.open(userEventlyDetail?.data?.link, "_blank")
    }, [userEventlyDetail?.data])

    const onHandleCopyUrl = () => {
        navigator.clipboard.writeText(userEventlyDetail?.data?.link);
        setCopiedURL(true);
        setTimeout(() => {
            setCopiedURL(false);
        }, 2000);

    }

    const getMeetingIcon = () => {
        if (validateGoogleMeetUrl(userEventlyDetail?.data?.link)) {
            return (
                <SiGooglemeet className={"text-text-700 text-xl"} />
            )
        } else if (validateZoomMeetingUrl(userEventlyDetail?.data?.link)) {
            return (
                <MdZoomOutMap className={"text-text-700 text-xl"} />
            )
        } else if (validateSkypeMeetingUrl(userEventlyDetail?.data?.link)) {
            return (
                <AiOutlineSkype className={"text-text-700 text-xl"} />
            )
        }
         else {
            return (
                <SiGotomeeting className={"text-text-700 text-xl"} />
            )
        }
    }

    return (
        <div className={"w-full grid grid-cols-12 gap-x-2 px-5"}>
            <div className={"col-start-1 col-span-1 self-center"}>
                {getMeetingIcon()}
            </div>
            <div className={"col-start-2 col-span-full self-center flex items-center justify-between gap-3"}>
                <span
                    className={"font-bodyPri font-normal text-primary-dark hover:underline text-base tracking-wide cursor-pointer"}
                    onClick={onHandleMeetingLink}
                >
                    {userEventlyDetail?.data?.link}
                </span>
                {copiedURL &&
                    <FiCheckSquare className={"text-xl text-green-500"} />
                }
                {!copiedURL &&
                    <MdOutlineContentCopy
                        className={"text-xl text-text-700 cursor-pointer"}
                        onClick={onHandleCopyUrl}
                    />
                }
            </div>
        </div>
    )
}

export default EventlyMeetingLink;