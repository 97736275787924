import { useNavigate } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { TiArrowSortedDown } from "react-icons/ti";
import { ImCross } from "react-icons/im";

import SimpleSelectSearch from "pages/global/findTutors/components/tutorPublicFilterDropdowns/SimpleSelectSearch";
import PriceRangeSlider from "pages/global/findTutors/components/tutorPublicFilterDropdowns/PriceRangeSlider";
import AvailabilityDropdown from "pages/global/findTutors/components/tutorPublicFilterDropdowns/AvailabilityDropdown";
import MultipleSelectSearchDropdown from "pages/global/findTutors/components/tutorPublicFilterDropdowns/MultipleSelectSearchDropdown";

import { dropDownButtonConst, getQueryString, weekOptions } from "pages/global/findTutors/data";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { DEFAULT_TUTOR_PAGE, DEFAULT_TUTOR_RECORDS } from 'redux/tutor/tutor.const';

import { pagesInfo } from "utils/pagesInfo";

const Filters = ({ dropDownButton, setDropDownButton }) => {

    const { locals } = useAppState((s) => s.local)
    const { categoryList } = useAppState((state) => state.category)
    const { countryList, currencyRateList } = useAppState((state) => state.master)
    const { filterProps } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const onHandleDropdown = (dropdownValue) => {
        if (!dropDownButton) {
            setDropDownButton(dropdownValue)
        }
        if (dropDownButton === dropdownValue) {
            setDropDownButton(null)
        }
        if (dropDownButton !== dropdownValue) {
            setDropDownButton(null)
            setDropDownButton(dropdownValue)
        }
    }

    return (
        <div className="bg-white rounded-2xl border shadow border-gray-200 w-full h-auto overflow-hidden">
            <div className="grid grid-cols-12 overflow-hidden">
                <div className={cn(
                    "col-span-6 md:col-span-3 flex flex-col justify-start items-center space-y-1 px-4 py-2 border-r-2 hover:rounded-2xl hover:shadow-2xl hover:border-0",
                    dropDownButton === dropDownButtonConst?.TOPIC?.value && "shadow-all z-50 rounded-2xl"
                )}
                >
                    <div className={"w-full relative"}>
                        <div className={"w-full font-bodyPri font-medium text-text-700 text-sm text-center"}>
                            {"TOPIC"}
                        </div>
                        <div
                            className={"w-full flex items-center justify-between gap-3 cursor-pointer"}
                            onClick={() => onHandleDropdown(dropDownButtonConst?.TOPIC?.value)}
                        >
                            <span className={"font-bodyPri font-normal text-text-900 text-sm"}>
                                {filterProps?.category[0] || "Select Topic"}
                            </span>
                            {filterProps?.category?.length === 0 &&
                                <span>
                                    <TiArrowSortedDown className={"text-base text-text-600"} />
                                </span>
                            }
                        </div>
                        {filterProps?.category?.length > 0 &&
                            <div
                                className={"absolute top-5 right-0 w-5 h-5 flex items-center justify-start rounded-full bg-white"}>
                                <ImCross
                                    className={"w-full text-sm text-primary-dark text-center p-0.5 cursor-pointer"}
                                    onClick={() => {
                                        const query = getQueryString({ ...filterProps, category: [], page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                        navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                                        setDropDownButton(null)
                                    }}
                                />
                            </div>
                        }
                    </div>
                    {(dropDownButton === dropDownButtonConst?.TOPIC?.value) &&
                        <SimpleSelectSearch
                            options={categoryList?.categoryList?.map((category) => ({
                                label: category?.category,
                                value: category?.category
                            }))}
                            isLoading={categoryList?.isLoading}
                            isShowInputBox={true}
                            isShowList={dropDownButton === dropDownButtonConst?.TOPIC?.value}
                            setIsShowList={setDropDownButton}
                            className={"top-64 left-24 w-fit md:w-72"}
                            onChange={(option) => navigate(option[0]?.value?.replaceAll(" ", "-")?.toLowerCase() + "-tutors")}
                        />
                    }
                </div>
                <div className={cn(
                    "col-span-6 md:col-span-3 flex flex-col justify-start items-center space-y-1 px-4 py-2 border-r-2 hover:rounded-2xl hover:shadow-2xl hover:border-0",
                    dropDownButton === dropDownButtonConst?.PRICE?.value && "shadow-all rounded-2xl"
                )}>
                    <div className={"font-bodyPri font-medium text-text-700 text-sm"}>
                        {"HOURLY PRICE"}
                    </div>
                    <div
                        className={"w-full cursor-pointer"}
                        onClick={() => onHandleDropdown(dropDownButtonConst?.PRICE?.value)}>
                        {filterProps?.minHrsPrice && filterProps?.maxHrsPrice
                            ? <div className={"flex items-center justify-start gap-1"}>
                                <div className={"w-fit truncate font-bodyPri font-normal text-text-900 text-base"}>
                                    {filterProps?.minHrsPrice + " - " + filterProps?.maxHrsPrice}
                                </div>
                                <span
                                    className={"bg-text-600 rounded-full p-1"}
                                    onClick={() => {
                                        setDropDownButton(null)
                                        const query = getQueryString({ ...filterProps, minHrsPrice: 0, maxHrsPrice: 0, page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                        navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                                    }}
                                >
                                    <ImCross className={"text-xs text-text-300"} />
                                </span>
                            </div>
                            : <div className={"w-full flex justify-between items-center gap-3"}>
                                <span className={"font-bodyPri font-normal text-text-900 text-sm"}>
                                    {"Select Price"}
                                </span>
                                <span>
                                    <TiArrowSortedDown className={"text-base text-text-600"} />
                                </span>
                            </div>
                        }
                    </div>
                    {(dropDownButton === dropDownButtonConst?.PRICE?.value) &&
                        <div className={"absolute top-16 z-30 shadow-md w-fit"}>
                            <PriceRangeSlider
                                minValue={filterProps?.minHrsPrice || currencyRateList?.currencyRateList[(locals?.currencyCode || "USD")]?.toFixed(2)}
                                maxValue={filterProps?.maxHrsPrice || (currencyRateList?.currencyRateList[(locals?.currencyCode || "USD")] * 500)?.toFixed(2)}
                                isShowList={dropDownButton === dropDownButtonConst?.PRICE?.value}
                                setIsShowList={setDropDownButton}
                                className={"absolute top-64 left-[25rem]"}
                                onApplyFilter={(option) => {
                                    const query = getQueryString({ ...filterProps, minHrsPrice: option?.minValue, maxHrsPrice: option?.maxValue, page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                    navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                                }}
                                onClearFilter={() => {
                                    setDropDownButton(null)
                                    if (filterProps?.minHrsPrice >= 0 && filterProps?.maxHrsPrice > 0) {
                                        const query = getQueryString({ ...filterProps, minHrsPrice: 0, maxHrsPrice: 0, page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                        navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                                    }
                                }}
                            />
                        </div>
                    }
                </div>
                <div className={cn(
                    "col-span-6 md:col-span-3 flex flex-col justify-start items-center space-y-1 px-4 py-2 border-r-2 hover:rounded-2xl hover:shadow-2xl hover:border-0",
                    dropDownButton === dropDownButtonConst?.AVAILABILITY?.value && "shadow-all rounded-2xl"
                )}>
                    <div className={"font-bodyPri font-medium text-text-700 text-sm"}>
                        {"AVAILABILITY"}
                    </div>
                    <div
                        className={"w-full cursor-pointer"}
                        onClick={() => onHandleDropdown(dropDownButtonConst?.AVAILABILITY?.value)}
                    >
                        {filterProps?.day?.length > 0
                            ? <div className={"flex items-center justify-start gap-1"}>
                                <div className={"w-fit truncate"}>
                                    {filterProps?.day?.map((availability, index) => (
                                        <span className={"font-bodyPri font-normal text-text-900 text-sm"}>
                                            {index !== 0 && ", "}
                                            {availability}
                                        </span>
                                    ))}
                                </div>
                                <span
                                    className={"bg-text-600 rounded-full p-1"}
                                    onClick={() => {
                                        setDropDownButton(null)
                                        const query = getQueryString({ ...filterProps, day: [], page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                        navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                                    }}>
                                    <ImCross className={"text-xs text-text-300"} />
                                </span>
                            </div>
                            : <div className={"w-full flex justify-between items-center gap-3"}>
                                <span className={"font-bodyPri font-normal text-text-900 text-sm"}>
                                    {"Select day"}
                                </span>
                                <span>
                                    <TiArrowSortedDown className={"text-base text-text-600"} />
                                </span>
                            </div>
                        }
                    </div>
                    {(dropDownButton === dropDownButtonConst?.AVAILABILITY?.value) &&
                        <div className={"absolute top-16 z-30 shadow-md w-fit"}>
                            <AvailabilityDropdown
                                weekOptions={weekOptions}
                                selectedOption={filterProps?.day?.map((items) => ({
                                    label: items,
                                    value: items
                                })) || []}
                                isShowList={dropDownButton === dropDownButtonConst?.AVAILABILITY?.value}
                                setIsShowList={setDropDownButton}
                                className={"top-64 right-[22rem]"}
                                onApplyFilter={(option) => {
                                    setDropDownButton(null)
                                    const query = getQueryString({ ...filterProps, day: option?.map((opt) => opt?.value), page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                    navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                                }}
                                onClearFilter={() => {
                                    setDropDownButton(null)
                                    if (filterProps?.day?.length > 0) {
                                        const query = getQueryString({ ...filterProps, day: [], page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                        navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                                    }
                                }}
                            />
                        </div>
                    }
                </div>
                <div className={cn(
                    "col-span-6 md:col-span-3 flex flex-col justify-start items-center space-y-1 px-4 py-2 border-r-2 hover:rounded-2xl hover:shadow-2xl hover:border-0",
                    dropDownButton === dropDownButtonConst?.TUTOR_FROM?.value && "shadow-all rounded-2xl"
                )}>
                    <div className={"font-bodyPri font-medium text-text-700 text-sm"}>
                        {"TUTOR IS FROM"}
                    </div>
                    <div
                        className={"w-full cursor-pointer"}
                        onClick={() => onHandleDropdown(dropDownButtonConst?.TUTOR_FROM?.value)}
                    >
                        {filterProps?.tutorFrom?.length > 0
                            ? <div className={"flex items-center justify-start gap-1"}>
                                <div className={"w-fit truncate"}>
                                    {filterProps?.tutorFrom?.map((country, index) => (
                                        <span className={"font-bodyPri font-normal text-text-900 text-sm"}>
                                            {index !== 0 && ", "}
                                            {country}
                                        </span>
                                    ))}
                                </div>
                                <span
                                    className={"bg-text-600 rounded-full p-1"}
                                    onClick={() => {
                                        setDropDownButton(null)
                                        const query = getQueryString({ ...filterProps, tutorFrom: [], page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                        navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                                    }
                                    }
                                >
                                    <ImCross className={"text-xs text-text-300"} />
                                </span>
                            </div>
                            : <div className={"w-full flex justify-between items-center gap-3"}>
                                <span className={"font-bodyPri font-normal text-text-900 text-sm"}>
                                    {"Select Country"}
                                </span>
                                <span>
                                    <TiArrowSortedDown className={"text-base text-text-600"} />
                                </span>
                            </div>
                        }
                    </div>
                    {(dropDownButton === dropDownButtonConst?.TUTOR_FROM?.value) &&
                        <MultipleSelectSearchDropdown
                            options={countryList?.countryList?.map((country) => ({
                                label: country?.country,
                                value: country?.country,
                            }))}
                            selectedOption={filterProps?.tutorFrom?.map((items) => ({
                                label: items,
                                value: items
                            })) || []}
                            isLoading={countryList?.isLoading}
                            isShowList={dropDownButton === dropDownButtonConst?.TUTOR_FROM?.value}
                            setIsShowList={setDropDownButton}
                            className={"top-64 right-24 w-fit md:w-80"}
                            multipleOptions={true}
                            isShowInputBox={true}
                            isShowCheckBox={true}
                            onApplyFilter={(option) => {
                                const query = getQueryString({ ...filterProps, tutorFrom: option?.map((opt) => opt?.value), page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                            }}
                            onClearFilter={() => {
                                setDropDownButton(null)
                                if (filterProps?.tutorFrom?.length > 0) {
                                    const query = getQueryString({ ...filterProps, tutorFrom: [], page: DEFAULT_TUTOR_PAGE, records: DEFAULT_TUTOR_RECORDS })
                                    navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
                                }
                            }}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default Filters;