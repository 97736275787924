import React from 'react'
import image from 'assets/image/success-icon.png'
import { cn } from 'utils/cn.utils'


const Success = ({ title, subTitle, onButtonClick, buttonText, containerClass, icon = image, disabled = false, buttonClass }) => {
    return (
        <div className={cn('rounded-lg bg-white border shadow p-8 flex flex-col justify-between items-center gap-8', containerClass)}>
            <img
                className='h-20 aspect-square object-contain'
                alt='error'
                src={icon}
            />
            <div className='space-y-1 text-center font-bodyPri'>
                <p className='font-medium text-xl'>{title}</p>
                <p className='text-sm text-text-600 line-clamp-3'>{subTitle}</p>
            </div>
            <button
                onClick={onButtonClick}
                disabled={disabled}
                className={cn("border px-5 py-1 font-medium ease-in-out duration-200 rounded text-primary-dark border-primary-dark hover:text-white hover:border-secondary-dark hover:bg-secondary-dark",
                    buttonClass
                )}
            >
                {buttonText}
            </button>
        </div>
    )
}

export default Success
