import { useEffect, useState } from 'react';

import { PageContentStyle, CompanySection, FooterContainer } from 'pages/auth/editCompany/EditCompany.style';

import Footer1 from 'components/footer1/Footer1';

import { tabsList } from 'pages/auth/editCompany/data';
import EditHero from 'pages/auth/editCompany/components/editHero/EditHero';
import EditCompanyInformation from 'pages/auth/editCompany/components/editCompanyInformation/EditCompanyInformation';
import Tabs from "pages/auth/editCompany/components/tabs/Tabs";
import EditAbout from "pages/auth/editCompany/components/editAbout/EditAbout";
import EditCourse from "pages/auth/editCompany/components/editCourse/EditCourse";
import EditPeople from "pages/auth/editCompany/components/editPeople/EditPeople";
import EditImportantLinks from "pages/auth/editCompany/components/editImportantLinks/EditImportantLinks";
import EditCompanyFAQ from "pages/auth/editCompany/components/editCompanyFAQ/EditCompanyFAQ";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

import { useTitle } from 'hooks/useTitle';

const EditCompanyPage = () => {
  const { currentPageInfo } = useAppState(s => s.pageInfo)

  const dispatcher = useAppDispatcher()
  const [title, setTitle] = useTitle()

  const [editBtnConst, setEditBtnConst] = useState(null)

  const containerIDs = ["HOME", "ABOUT", "COURSES", "PEOPLE", "LINKS", "FAQ"]

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.EDIT_COMPANY))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    setTitle({
      ...title,
      title: "Update Organisation | Work in Progress | Edulyte"
    })
  }, [dispatcher, currentPageInfo])

  return (
    <PageContentStyle>
      <EditHero
        editBtnConst={editBtnConst}
        setEditBtnConst={setEditBtnConst}
      />
      <EditCompanyInformation
        editBtnConst={editBtnConst}
        setEditBtnConst={setEditBtnConst}
      />
      <CompanySection>
        <Tabs tabsList={tabsList} containerIDs={containerIDs} />
        <EditAbout
          editBtnConst={editBtnConst}
          setEditBtnConst={setEditBtnConst}
        />
        <EditCourse
          editBtnConst={editBtnConst}
          setEditBtnConst={setEditBtnConst}
        />
        <EditPeople
          editBtnConst={editBtnConst}
          setEditBtnConst={setEditBtnConst}
        />
        <EditImportantLinks
          editBtnConst={editBtnConst}
          setEditBtnConst={setEditBtnConst}
        />
        <EditCompanyFAQ
          editBtnConst={editBtnConst}
          setEditBtnConst={setEditBtnConst}
        />
      </CompanySection>
      <FooterContainer>
        <Footer1 />
      </FooterContainer>
    </PageContentStyle>
  )
}

export default EditCompanyPage