const howDoesItWorkLeftSide = "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/refer-and-earn-page/featured-one-refer-and-earn.png"
const howDoesItWorkMiddle = "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/refer-and-earn-page/featured-two-refer-and-earn.png"
const howDoesItWorkRightSide = "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/refer-and-earn-page/featured-three-refer-and-earn.png"

export const cols = [
  "Date Joined",
  "Name",
  "Purchase Date",
  "Status",
  "Amount",
];

export const howDoesItWork = [
  {
    title: "Invite your friends using unique code or link.",
    image: howDoesItWorkLeftSide
  },
  {
    title: "Your friends use your link or code to register on our app website, and purchase a lesson.",
    image: howDoesItWorkMiddle
  },
  {
    title: "You and your friend both will get {amount1} (approx {amount2}) in your wallet to spend on lessons.",
    image: howDoesItWorkRightSide
  },
]