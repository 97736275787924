import { useCallback, useMemo, useState } from 'react';

import { cn } from "utils/cn.utils";

import { FiEdit } from 'react-icons/fi';

import SaveAndCancelBtn from "pages/auth/edulyteLms/commonComponents/lmsButtons/SaveAndCancelBtn";

import { updateLmsQuestionDetail } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { lmsQuestionStatusEnum } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';

const QuestionGeneralSettings = () => {
    const { lmsQuestionDetail, destroyLmsQuestionDetail } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()

    const isDeleteBtnDisabled = useMemo(() => ([lmsQuestionStatusEnum?.INACTIVE?.value, lmsQuestionStatusEnum?.PUBLISHED?.value]?.includes(lmsQuestionDetail?.data?.status)), [lmsQuestionDetail?.data])

    const [marksDetail, setMarksDetail] = useState({
        isCorrectMarkEditEnabled: false,
        correctMarks: null,
        isIncorrectMarkEditEnabled: false,
        incorrectMarks: null
    })

    const onHandleDeleteQuestion = useCallback(() => {
        if (lmsQuestionDetail?.isLoading || destroyLmsQuestionDetail?.isLoading || isDeleteBtnDisabled) return;

        if (!window.confirm("Are you sure?. You want to delete question.")) return;

        // dispatcher(deleteLmsQuestionDetail(lmsQuestionDetail?.data?.id))
    }, [lmsQuestionDetail?.data, destroyLmsQuestionDetail?.isLoading, isDeleteBtnDisabled])

    const onHandleSaveCorrectMarks = useCallback(() => {
        if (lmsQuestionDetail?.data?.question_setting?.correct_marks === marksDetail?.correctMarks) {
            setMarksDetail({
                isCorrectMarkEditEnabled: false,
                correctMarks: null
            })
            return;
        }
        dispatcher(updateLmsQuestionDetail(lmsQuestionDetail?.data?.id, { correct_marks: marksDetail?.correctMarks }))
        setMarksDetail({
            isCorrectMarkEditEnabled: false,
            correctMarks: null
        })
    }, [lmsQuestionDetail?.data, marksDetail])

    const onHandleSaveInCorrectMarks = useCallback(() => {
        if (lmsQuestionDetail?.data?.question_setting?.incorrect_marks === marksDetail?.incorrectMarks) {
            setMarksDetail({
                isCorrectMarkEditEnabled: false,
                correctMarks: null,
                isIncorrectMarkEditEnabled: false,
                incorrectMarks: null,
            })
            return;
        }
        dispatcher(updateLmsQuestionDetail(lmsQuestionDetail?.data?.id, { incorrect_marks: marksDetail?.incorrectMarks }))
        setMarksDetail({
            isCorrectMarkEditEnabled: false,
            correctMarks: null,
            isIncorrectMarkEditEnabled: false,
            incorrectMarks: null,
        })
    }, [lmsQuestionDetail?.data, marksDetail])

    const onHandleResetMarks = useCallback(() => {
        setMarksDetail({
            isCorrectMarkEditEnabled: false,
            correctMarks: null,
            isIncorrectMarkEditEnabled: false,
            incorrectMarks: null
        })
    }, [])

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <div className={"w-full px-3 py-2 bg-divider-medium rounded-lg"}>
                <span className={"font-bodyPri font-bold text-text-800 text-lg tracking-wide"}>
                    {"General Settings"}
                </span>
            </div>

            <div className={"w-full flex flex-col gap-3"}>
                <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                    <div className={"col-span-8 order-1 lg:col-span-7 w-full flex items-center justify-start lg:justify-between"}>
                        <p className={"font-bodyPri font-medium text-text-700 text-base tracking-wide"}>
                            {"Correct mark point value"}
                        </p>
                        <span>{":"}</span>
                    </div>
                    <div className={"col-span-full order-3 lg:order-2 lg:col-span-5 justify-self-stretch flex items-center gap-1.5"}>
                        {!marksDetail?.isCorrectMarkEditEnabled &&
                            <div className={"flex items-center justify-start gap-5"}>
                                <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                    {lmsQuestionDetail?.data?.question_setting?.correct_marks + " points"}
                                </span>
                                <FiEdit
                                    className={"text-lg text-text-800 hover:opacity-90 cursor-pointer"}
                                    title={"Edit Point"}
                                    onClick={() => setMarksDetail({
                                        isIncorrectMarkEditEnabled: false,
                                        incorrectMarks: null,
                                        isCorrectMarkEditEnabled: true,
                                        correctMarks: lmsQuestionDetail?.data?.question_setting?.correct_marks
                                    })} />
                            </div>
                        }
                        {marksDetail?.isCorrectMarkEditEnabled &&
                            <div className={"flex items-center gap-5"}>
                                <div className={"flex items-center gap-1.5"}>
                                    <input
                                        type={"number"}
                                        className={cn(
                                            "w-28 px-3 py-1 border-b border-divider-lightDark shadow-md focus:outline-none focus:border-primary-dark",
                                            "font-bodyPri font-normal text-text-800 text-base tracking-wide"
                                        )}
                                        autoFocus={true}
                                        value={marksDetail?.correctMarks?.toString()}
                                        onChange={(event) => setMarksDetail({
                                            ...marksDetail,
                                            correctMarks: Number(event?.target?.value)
                                        })}
                                    />
                                    <span className={"font-bodyPri font-normal text-text-700 text-sm tracking-wide"}>
                                        {"points"}
                                    </span>
                                </div>
                                <SaveAndCancelBtn
                                    onHandleSave={onHandleSaveCorrectMarks}
                                    onHandleReset={onHandleResetMarks}
                                />
                            </div>
                        }
                    </div>
                </div>

                <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                    <div className={"col-span-8 order-1 lg:col-span-7 w-full flex items-center justify-start lg:justify-between"}>
                        <p className={"font-bodyPri font-medium text-text-700 text-base tracking-wide"}>
                            {"Incorrect mark point value"}
                        </p>
                        <span>{":"}</span>
                    </div>
                    <div className={"col-span-full order-3 lg:order-2 lg:col-span-5 justify-self-stretch flex items-center gap-1.5"}>
                        {!marksDetail?.isIncorrectMarkEditEnabled &&
                            <div className={"flex items-center justify-start gap-5"}>
                                <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                    {lmsQuestionDetail?.data?.question_setting?.incorrect_marks + " points"}
                                </span>
                                <FiEdit
                                    className={"text-lg text-text-800 hover:opacity-90 cursor-pointer"}
                                    title={"Edit Point"}
                                    onClick={() => setMarksDetail({
                                        isCorrectMarkEditEnabled: false,
                                        correctMarks: null,
                                        isIncorrectMarkEditEnabled: true,
                                        incorrectMarks: lmsQuestionDetail?.data?.question_setting?.incorrect_marks
                                    })} />
                            </div>
                        }
                        {marksDetail?.isIncorrectMarkEditEnabled &&
                            <div className={"flex items-center gap-5"}>
                                <div className={"flex items-center gap-1.5"}>
                                    <input
                                        type={"number"}
                                        className={cn(
                                            "w-28 px-3 py-1 border-b border-divider-lightDark shadow-md focus:outline-none focus:border-primary-dark",
                                            "font-bodyPri font-normal text-text-800 text-base tracking-wide"
                                        )}
                                        autoFocus={true}
                                        value={marksDetail?.incorrectMarks?.toString()}
                                        onChange={(event) => setMarksDetail({
                                            ...marksDetail,
                                            incorrectMarks: Number(event?.target?.value)
                                        })}
                                    />
                                    <span className={"font-bodyPri font-normal text-text-700 text-sm tracking-wide"}>
                                        {"points"}
                                    </span>
                                </div>
                                <SaveAndCancelBtn
                                    onHandleSave={onHandleSaveInCorrectMarks}
                                    onHandleReset={onHandleResetMarks}
                                />
                            </div>
                        }
                    </div>
                </div>

                {/* <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                    <span className={"col-span-8 order-1 lg:col-span-7 w-full flex items-center justify-start lg:justify-between font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                        <span>{"Delete Question"}</span>
                        <span>{":"}</span>
                    </span>
                    <div className={"col-span-full order-3 lg:order-2 lg:col-span-5 justify-self-stretch flex items-center"}>
                        <button
                            className={cn(
                                'w-fit flex items-center justify-center px-5 py-1 font-bodyPri font-normal rounded-md',
                                isDeleteBtnDisabled && "bg-text-300 text-text-400 cursor-not-allowed",
                                (!isDeleteBtnDisabled && !destroyLmsQuestionDetail?.isLoading) && "bg-secondary-dark hover:opacity-90 text-white ease-in-out duration-300",
                                (!isDeleteBtnDisabled && destroyLmsQuestionDetail?.isLoading) && "bg-secondary-dark"
                            )}
                            onClick={onHandleDeleteQuestion}
                            disabled={isDeleteBtnDisabled}
                        >
                            {destroyLmsQuestionDetail?.isLoading &&
                                <ButtonLoader isLoading={destroyLmsQuestionDetail?.isLoading} />
                            }
                            {!destroyLmsQuestionDetail?.isLoading && "Delete"}
                        </button>
                    </div>
                </div> */}

                <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                    <span className={"col-span-8 order-1 lg:col-span-7 w-full flex items-center justify-start lg:justify-between font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                        <span>{"Created At"}</span>
                        <span>{":"}</span>
                    </span>
                    <span className={"col-span-full order-3 lg:order-2 lg:col-span-5 justify-self-stretch font-bodyPri font-normal text-text-900 text-md tracking-wide"}>
                        {dayjs(lmsQuestionDetail?.data?.createdAt)?.tz(timeZone)?.format("ddd, MMM D, YY h:mm A")}
                    </span>
                </div>

                <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                    <span className={"col-span-8 order-1 lg:col-span-7 w-full flex items-center justify-start lg:justify-between font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                        <span>{"Updated At"}</span>
                        <span>{":"}</span>
                    </span>
                    <span className={"col-span-full order-3 lg:order-2 lg:col-span-5 justify-self-stretch font-bodyPri font-normal text-text-900 text-md tracking-wide"}>
                        {dayjs(lmsQuestionDetail?.data?.updatedAt)?.tz(timeZone)?.format("ddd, MMM D, YY h:mm A")}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default QuestionGeneralSettings;