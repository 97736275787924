import { useEffect } from "react";
import { cn } from "utils/cn.utils";

import { FaPlusCircle } from "react-icons/fa";

import ComponentLoader from "components/loader/ComponentLoader";

import OffDayCard from "./OffDayCard";

import { getOffDayList } from 'redux/availability/availability.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setClearAddOffDayDetail, setClearAddOffDayDetailPayload, setClearOffDayList } from "redux/availability/availability.slice";
import { modalConst } from "redux/local/local.const";

import { timeZone } from 'utils/dateTime.utils';

export default function OffDaysSection() {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((s) => s.user)
    const { addOffDayDetail, offDayList } = useAppState((s) => s.availability)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (user?.user?.userId) {
            const query = {
                userId: user?.user?.userId,
                timeZone: timeZone
            }
            dispatcher(getOffDayList(query))
        }

        return () => {
            dispatcher(setClearOffDayList())
        }
    }, [user?.user])

    useEffect(() => {
        if (addOffDayDetail?.addOffDayDetail) {
            dispatcher(getOffDayList({ userId: user?.user?.userId, timeZone: timeZone }))
            dispatcher(setModal({
                ...modal,
                [modalConst.ADD_OFF_DAY_MODAL.stateKey]: false
            }))

            dispatcher(setClearAddOffDayDetail())
        }
    }, [addOffDayDetail?.addOffDayDetail])

    const handleShowAddForm = () => {
        if (offDayList?.isLoading) return;

        dispatcher(setClearAddOffDayDetailPayload())
        dispatcher(setModal({
            ...modal,
            [modalConst.ADD_OFF_DAY_MODAL.stateKey]: true
        }))
    }

    return (
        <div className="flex flex-col max-w-4xl mx-auto space-y-5">

            <div className={"flex items-center justify-center"}>
                <div
                    onClick={handleShowAddForm}
                    className={cn(
                        "flex justify-center gap-3 py-3 px-5 w-fit rounded-lg",
                        "border-2 border-text-300 cursor-pointer group hover:scale-105",
                        "transition duration-150 delay-75 ease-in-out"
                    )}
                >
                    <p className="font-bodyPri font-medium text-lg text-primary-main group-hover:text-primary-dark">
                        {"Add a day off"}
                    </p>

                    <button className={cn(
                        "inline-flex items-center justify-center",
                        "transform rounded-full bg-gray-50"
                    )}>
                        <FaPlusCircle className={"text-xl text-primary-main group-hover:text-primary-dark"} />
                    </button>
                </div>
            </div>

            {offDayList?.isLoading &&
                <ComponentLoader isLoading={offDayList?.isLoading} className={"min-h-[16rem]"} />
            }

            {(!offDayList?.isLoading && (offDayList?.offDayList && offDayList?.offDayList.length > 0)) &&
                offDayList?.offDayList?.map((localOffDay, index) => (
                    <OffDayCard
                        key={index}
                        localOffDay={localOffDay}
                    />
                ))
            }

            {(!offDayList?.isLoading && offDayList?.errorMsg) &&
                <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base"}>
                    {offDayList?.errorMsg}
                </div>
            }
        </div>
    );
}