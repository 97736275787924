export const tabEnum = {
  content: {
    value: "content",
    label: "Course Content"
  },
  description: {
    value: "description",
    label: "Description"
  },
  notes: {
    value: "notes",
    label: "Notes"
  },
  // reviews: {
  //   value: "reviews",
  //   label: "Reviews"
  // }
}

export const notesData = [
  {
    id: "1",
    note: "The tutorials and challenges build on each other, so taking the course is a bit like hiking a mountain."
  },
  {
    id: "2",
    note: "Modules are really cool and there's a temptation to jump straight to it, but without the proper"
  },
  {
    id: "3",
    note: "For complete beginners, I recommend starting from the very beginning and making sure you don't skip any of the lessons or modules. The tutorials and challenges build on each other, so taking the course is a bit like hiking a mountain. I know some of the later modules are really cool and there's a temptation to jump straight to it, but without the proper foundations, it's easy to get lost and demotivated."
  }
]