import tw, { styled } from "twin.macro";

// export const StyledNotificationList = styled.div`
//   ${tw`absolute z-50 shadow-lg top[100%] right[0] bg-white`}
// `;
export const StyledNotificationList = tw.div`
  !bg-white max-w-sm w-full
`;

export const StyledNotificationListHeader = styled.div`
  ${tw`font-semibold text-sm border-b border-gray-200 py-2 pl-0.5 !text-primary-dark`}
`;