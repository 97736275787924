import { cn } from "utils/cn.utils";

import { FiPlus } from "react-icons/fi";

import Pagination from "components/pagination/Pagination";

import { fileExtensionConst, formatFileSize, myDriveFilesHeaders } from "pages/auth/drive/data";
import DriveTable from "pages/auth/drive/commonComponents/DriveTable";
import DriveMenu from "pages/auth/drive/commonComponents/DriveMenu";
import MyFilesMembers from "pages/auth/drive/commonComponents/MyFilesMembers";

import { getFileList, viewFileDetail } from "redux/drive/drive.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { subscriptionPlanEnum, subscriptionStatusEnum, userPlans } from "redux/subscription/subscription.const";
import { GBToBConst } from "redux/storage/storage.const";

import { dayjs } from "utils/dateTime.utils";

const MyDriveFiles = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { fileList, fileView } = useAppState((state) => state.drive)

    const dispatcher = useAppDispatcher()

    const sortFileList = (file1, file2) => {
        const file1StartTime = dayjs(file1?.created_at).utc()
        const file2StartTime = dayjs(file2?.created_at).utc()
        return file1StartTime.isSameOrBefore(file2StartTime) ? 1 : -1
    }

    const onHandleAddNewFile = () => {
        if (!!user?.user?.user_subscriptions?.length) {
            const filteredActiveProSubscriptions = user?.user?.user_subscriptions?.filter((subscriptionItem) => (
                [subscriptionStatusEnum.active.key]?.includes(subscriptionItem?.status)
                && [subscriptionPlanEnum.pro_user.key]?.includes(subscriptionItem?.plan))
            )
            if (!!filteredActiveProSubscriptions?.length && (user?.user?.storage_used >= 50 * GBToBConst)) {
                alert("You don't have enough space to upload the file.")
                return;
            } else {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.UPLOAD_DRIVE_FILE_MODAL.stateKey]: true
                })) 
                return;
            }
        } else if ((user?.user?.user_subscriptions?.length === 0) && (user?.user?.storage_used >= 5 * GBToBConst)) {
            dispatcher(setModal({
                ...modal,
                [modalConst.planTableModal.key]: {
                    ...modalConst.planTableModal,
                    isVisible: true,
                    title: "Unlock Premium Features: Subscribe to Go Pro",
                    userPlans
                },
            }))
            return;
        }
        dispatcher(setModal({
            ...modal,
            [modalConst.UPLOAD_DRIVE_FILE_MODAL.stateKey]: true
        }))
    };

    const onHandleOpenFile = async (fileId) => {
        if (fileView?.isLoading) return;

        const response = await dispatcher(viewFileDetail(fileId));
        const fileUrl = response.data.file_url;
        window.open(fileUrl, "_blank")
    }

    const onHandleChangePage = (page) => {
        if (fileList?.isLoading) return;

        dispatcher(getFileList({ page: page, records: 10 }))
    }

    const myDriveFilesRows = (fileList?.data && fileList?.data?.results?.length > 0) && fileList?.data?.results?.slice(0)?.sort(sortFileList)?.map((fileItems, index) => [
        <div key={index} className="flex items-center justify-start gap-2">
            <div className={cn(
                `w-[35px] h-[35px] rounded-full flex items-center justify-center`,
                fileExtensionConst[fileItems?.file_name?.split(".")?.pop()?.toUpperCase()]
                    ? `bg-${fileExtensionConst[fileItems?.file_name?.split(".")?.pop()?.toUpperCase()]?.darkColor}`
                    : "bg-orange-500"
            )}>
                <p className={`font-bodyPri font-normal text-text-50 text-xs text-center m-0`}>
                    {fileItems?.file_name.split(".").pop()?.toLowerCase()}
                </p>
            </div>
            <div
                onClick={() => onHandleOpenFile(fileItems?.id)}
                className={"font-bodyPri font-bold text-primary-dark text-sm tracking-wide text-left cursor-pointer hover:underline"}
            >
                {fileItems?.file_name?.length > 25
                    ? fileItems?.file_name?.slice(0, 25) + " ..."
                    : fileItems?.file_name
                }
            </div>
        </div> || "-",
        <MyFilesMembers
            sharedByUser={fileItems?.shared_by}
            sharedWithUsers={fileItems?.shared_with}
        />,
        formatFileSize(fileItems?.file_size) || "-",
        fileItems?.created_at
            ? dayjs(fileItems?.created_at)?.format("dddd, DD MMM YYYY ")
            : "-",
        <div className={"flex justify-center"}>
            <DriveMenu fileItems={fileItems} />
        </div>
    ])

    return (
        <div className="w-full min-h-screen p-5 bg-white flex flex-col items-start justify-start gap-3 rounded-lg">
            <div className={"w-full flex items-center justify-between gap-5"}>
                <p className="font-bodyPri font-bold text-black text-base tracking-wide">
                    {"Files"}
                </p>
                <button
                    className={cn(
                        "w-fit px-5 py-2 flex items-center justify-center gap-1.5 rounded-lg cursor-pointer",
                        "bg-primary-dark hover:bg-secondary-dark",
                        "font-bodyPri font-medium text-text-50 text-base tracking-wide"
                    )}
                    onClick={onHandleAddNewFile}
                >
                    <FiPlus className={"text-lg text-text-50"} />
                    <div className={"font-bodyPri font-medium text-text-50 text-base tracking-wide"}>
                        {"Add File"}
                    </div>
                </button>
            </div>
            <div className={"w-full relative space-y-8"}>
                <DriveTable
                    isLoading={fileList?.isLoading}
                    headers={Object.values(myDriveFilesHeaders)}
                    rows={myDriveFilesRows}
                />
                {!fileList?.isLoading && (fileList?.data && fileList?.data?.totalPages > 1) &&
                    <div className="w-full flex justify-center items-center">
                        <Pagination
                            page={fileList?.data?.page}
                            totalPages={fileList?.data?.totalPages}
                            onChangePage={(page) => onHandleChangePage(page)}
                        />
                    </div>
                }
                {(!fileList?.isLoading && fileList?.message) &&
                    <div className="font-bodyPri font-normal text-red-500 text-base tracking-wide">
                        {fileList?.message}
                    </div>
                }
            </div>
        </div>
    )
}

export default MyDriveFiles;