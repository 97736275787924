import meetingService from 'redux/meeting/meeting.service';
import {
    setMeetingLoading,
    setMeeting,
    setMeetingErrorMsg,

    setJoinMeetingLoading,
    setJoinMeetingData,
    setJoinMeetingMessage
} from 'redux/meeting/meeting.slice'


export const getMeetingByMeetingId = (meetingId) => async (dispatch) => {
    dispatch(setMeetingLoading(true));

    try {
        const requestData = {
            params: { meetingId: meetingId }
        }
        const response = await meetingService.getMeetingByMeetingId(requestData)
        if (response.status === 200) {
            dispatch(setMeeting(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setMeetingErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setMeetingLoading(false));
    }
}

// joinMeetingByCode request
export const joinMeetingByCode = (code) => async (dispatch) => {
    dispatch(setJoinMeetingLoading(true));

    try {
        const requestData = {
            params: { code: code }
        }
        const response = await meetingService.joinMeetingByCode(requestData)
        if (response.status === 200) {
            dispatch(setJoinMeetingData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setJoinMeetingMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setJoinMeetingLoading(false));
    }
}