import { createSlice } from "@reduxjs/toolkit";
import { LOCAL_INITIAL_STATE } from "redux/local/local.initialState";
import { setMyLocals } from "./local.utils";


const local = createSlice({
    name: "local",
    initialState: LOCAL_INITIAL_STATE,
    reducers: {
        // Reducers for locals
        setLocals: (state, { payload }) => {
            state.locals = payload
            setMyLocals(payload)
        },
        setClearLocals: (state) => {
            state.locals = LOCAL_INITIAL_STATE.locals
        },

        // Reducers for madal
        setModal: (state, { payload }) => {
            state.modal = payload
        },
        setClearModal: (state) => {
            state.modal = LOCAL_INITIAL_STATE.modal
        },

        // Reducers for pages
        setPages: (state, { payload }) => {
            state.pages = payload
        },
        setClearPages: (state) => {
            state.pages = LOCAL_INITIAL_STATE.pages
        },

        // Reducers for share social
        setShareSocial: (state, { payload }) => {
            state.shareSocial = payload
        },
        setClearShareSocial: (state) => {
            state.shareSocial = LOCAL_INITIAL_STATE.shareSocial
        },

        // reducers for terms and conditions
        setTermsAndConditions: (state, { payload }) => {
            state.termsAndConditions = payload
        },
        setClearTermsAndConditions: (state) => {
            state.termsAndConditions = LOCAL_INITIAL_STATE.termsAndConditions
        },

        // reducers for show user role modal
        setShowRoleModal: (state, { payload }) => {
            state.showRoleModal.isShowRoleModal = payload
        },
        resetShowRoleModal: (state) => {
            state.showRoleModal = LOCAL_INITIAL_STATE.showRoleModal
        },

        // reducers for preview image content
        setPreviewImageContent: (state, { payload }) => {
            state.previewImage = payload
        },
        resetPreviewImageContent: (state) => {
            state.previewImage = LOCAL_INITIAL_STATE.previewImage
        },

        // reducers for local tabs
        setLocalTabs: (state, { payload }) => {
            state.localTabs = payload
        },
        resetLocalTabs: (state) => {
            state.localTabs = LOCAL_INITIAL_STATE.localTabs
        },
    }
})

export const {
    setLocals,
    setClearLocals,

    setModal,
    setClearModal,

    setPages,
    setClearPages,

    setShareSocial,
    setClearShareSocial,

    setTermsAndConditions,
    setClearTermsAndConditions,

    setShowRoleModal,
    resetShowRoleModal,

    setPreviewImageContent,
    resetPreviewImageContent,

    setLocalTabs,
    resetLocalTabs
} = local.actions

export default local.reducer