export const timeLineData = [
    {
        title: 'Sign Up for Free',
        description: 'Register using your valid email address and phone number to get started on your teaching journey.',
    },
    {
        title: 'Create Your Winning Profile',
        description: 'Craft a professional profile that stands out. Share your story, list the subjects you teach at different levels, and add a captivating one-liner.',
    },
    {
        title: 'Boost Your Impact',
        description: 'Utilise our software to offer your services with ease. Gain trust with a blue checkmark and grow with customised marketing solutions.',
    },
    {
        title: 'List on Marketplace',
        description: 'Optionally, proceed with our screening process to showcase your profile and services on the Edulyte Marketplace. Expand your reach to a broader audience.',
    }
]