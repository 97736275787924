import { useEffect } from 'react';

import { getAllCategoryList } from 'redux/category/category.request';
import { getMasterCountryList, getMasterProficiencyList } from 'redux/master/master.request';
import { getMasterLanguageLevelList, getMasterLanguageList } from 'redux/master/master.request';
import { getUserDetailByUserId, getUserCultureDetailByUserId, getUserEducationListByUserId } from 'redux/user/user.request';
import { getStudentUserCategoryListByUserId, getStudentUserDetailByUserId, getStudentUserLanguageListByUserId } from 'redux/student/student.request';
import { getTutorUserCategoryListByUserId, getTutorUserDetailByUserId, getTutorUserLanguageListByUserId } from 'redux/tutor/tutor.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearCategoryList } from 'redux/category/category.slice';
import { setClearCountryList, setClearLanguageLevelList, setClearLanguageList, setClearProfList } from 'redux/master/master.slice';
import { setClearUserCultureDetail, setClearUserDetail, setClearUserEducationList } from 'redux/user/user.slice';
import { setClearStudentUserDetail, setClearStudentUserLanguageList, setClearUserStudentCategoryList } from 'redux/student/student.slice';
import { setClearTutorUserCategoryList, setClearTutorUserDetail, setClearTutorUserLanguageList } from 'redux/tutor/tutor.slice';
import { resetUserAppointmentList } from 'redux/appointment/appointment.slice';
import { userRoles } from 'redux/auth/auth.const';

export function useGetProfileDetails() {
    const { locals } = useAppState((state) => state.local)
    const { countryList, languageList, languageLevelList } = useAppState((state) => state.master)
    const { user, userDetail, userCultureDetail, userEducationList } = useAppState((state) => state.user)
    const { tutorUserLanguageList, tutorUserDetail } = useAppState((state) => state.tutor)
    const { studentUserLanguageList, studentUserDetail } = useAppState((state) => state.student)

    const dispatcher = useAppDispatcher()

    const isLoading = countryList?.isLoading || languageList?.isLoading || languageLevelList?.isLoading
        || userDetail?.isLoading || userCultureDetail?.isLoading || userEducationList?.isLoading
        || studentUserLanguageList?.isLoading || studentUserDetail?.isLoading || tutorUserLanguageList?.isLoading
        || tutorUserDetail?.isLoading

    const message = countryList?.errorMsg || languageList?.errorMsg || languageLevelList?.errorMsg
        || userDetail?.errorMsg || userCultureDetail?.errorMsg || userEducationList?.errorMsg
        || studentUserLanguageList?.errorMsg || studentUserDetail?.errorMsg || tutorUserLanguageList?.errorMsg
        || tutorUserDetail?.errorMsg

    const getProfileData = () => {
        dispatcher(getAllCategoryList())
        dispatcher(getMasterCountryList())
        dispatcher(getMasterLanguageList())
        dispatcher(getMasterLanguageLevelList())
        dispatcher(getMasterProficiencyList())
        dispatcher(getUserDetailByUserId(user?.user?.userId))
        dispatcher(getUserCultureDetailByUserId(user?.user?.userId))
        dispatcher(getUserEducationListByUserId(user?.user?.userId))

        if (locals.userRole === userRoles.STUDENT.value) {
            dispatcher(getStudentUserDetailByUserId(user?.user?.userId))
            dispatcher(getStudentUserLanguageListByUserId(user?.user?.userId))
            dispatcher(getStudentUserCategoryListByUserId(user?.user?.userId))
        } else if (locals.userRole === userRoles.TUTOR.value) {
            dispatcher(getTutorUserDetailByUserId(user?.user?.userId))
            dispatcher(getTutorUserLanguageListByUserId(user?.user?.userId))
            dispatcher(getTutorUserCategoryListByUserId(user?.user?.userId))
        }
    }

    useEffect(() => {
        getProfileData()

        return () => {
            dispatcher(setClearCategoryList())
            dispatcher(setClearCountryList())
            dispatcher(setClearLanguageList())
            dispatcher(setClearLanguageLevelList())
            dispatcher(setClearProfList())
            dispatcher(setClearUserDetail())
            dispatcher(setClearUserCultureDetail())
            dispatcher(setClearUserEducationList())
            dispatcher(setClearStudentUserDetail())
            dispatcher(setClearStudentUserLanguageList())
            dispatcher(setClearTutorUserDetail())
            dispatcher(setClearTutorUserLanguageList())
            dispatcher(resetUserAppointmentList())
            dispatcher(setClearUserStudentCategoryList())
            dispatcher(setClearTutorUserCategoryList())
        }

    }, [])

    return { isLoading, message };
}