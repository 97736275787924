import { memo, useCallback, useEffect } from 'react';

import { IoIosCheckmark } from 'react-icons/io';

import DropdownMenu from '../../commonComponents/DropdownMenu';
import FloatingLabelInput from 'components/floating/floatingLabelInput';

import { useAppState } from 'hooks/useStore';
import { locationPhoneCallTypeEnum } from "redux/appointment/appointment.const";

import { validateMobileNo } from 'utils/validation-functions';

const PhoneCallDetail = memo(({ locationPayload, setLocationPayload }) => {
    const { modal } = useAppState((state) => state.local)
    const { countryList, timeZoneCountryDetail } = useAppState((state) => state.master)

    useEffect(() => {
        if ([locationPhoneCallTypeEnum.call_me.key]?.includes(locationPayload?.phone_call?.location_phone_call_type)
            && !locationPayload?.phone_call?.country?.country
            && countryList?.countryList
            && timeZoneCountryDetail?.timeZoneCountryDetail
        ) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country?.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country) => (
                    country.countryDomain === "US"
                ))
            }
            setLocationPayload({
                ...locationPayload,
                phone_call: {
                    ...locationPayload?.phone_call,
                    country: myLocalCountryList[0]
                }
            })
        }
    }, [locationPayload?.phone_call, countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

    const sortCountryList = (country1, country2) => {
        if (country1?.masterCountryId === locationPayload?.phone_call?.country?.masterCountryId) return -1;
        if (country2?.masterCountryId === locationPayload?.phone_call?.country?.masterCountryId) return 1;
        return 0;
    }

    const onHandleSelectCallType = useCallback((key, value) => {
        setLocationPayload({
            ...locationPayload,
            phone_call: {
                ...modal?.appointmentLocationDetailModal?.payload?.phone_call,
                [key]: value
            }
        })
    }, [modal, locationPayload])

    const onHandleChangeText = useCallback((key, value) => {
        setLocationPayload({
            ...locationPayload,
            phone_call: {
                ...locationPayload?.phone_call,
                [key]: value
            }
        })
    }, [locationPayload])


    return (
        <div className={"py-2 w-full flex flex-col gap-5"}>

            {Object.values(locationPhoneCallTypeEnum).map((callType) => (
                <div
                    className={"flex items-start justify-start gap-3 cursor-pointer"}
                    onClick={() => onHandleSelectCallType("location_phone_call_type", callType?.key)}
                >
                    <div className={"mt-0.5"}>
                        <input
                            type={"radio"}
                            className={""}
                            name={"location_phone_call_type"}
                            value={locationPayload?.phone_call?.location_phone_call_type}
                            checked={(locationPayload?.phone_call?.location_phone_call_type === callType?.key) && true}
                            onChange={() => onHandleSelectCallType("location_phone_call_type", callType?.key)}
                        />
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {callType?.title}
                        </span>
                        <span className={"font-bodyPri font-normal text-text-600 text-sm"}>
                            {callType.subtitle}
                        </span>
                    </div>
                </div>
            ))}
            {[locationPhoneCallTypeEnum.call_me.key]?.includes(locationPayload?.phone_call?.location_phone_call_type) &&
                <div className={"w-full flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-start sm:gap-2"}>
                    <div className={"w-full md:w-[12rem]"}>
                        <DropdownMenu
                            isLoading={countryList?.isLoading}
                            isSearchEnabled={true}
                            options={countryList?.countryList?.slice(0)?.sort(sortCountryList)?.map((country) => ({
                                label: `${country?.country} (+${country?.isdCode})`,
                                value: country?.masterCountryId,
                                country: country
                            })) || []}
                            onHandleSelect={(option) => onHandleChangeText("country", option?.country)}
                            selectedOption={<div
                                title={`${locationPayload?.phone_call?.country?.country}: +${locationPayload?.phone_call?.country?.isdCode}`}
                                className={"flex items-center gap-1 font-bodyPri font-normal text-text-800"}
                            >
                                <img
                                    src={`https://flagcdn.com/16x12/${locationPayload?.phone_call?.country?.countryDomain}.png`.toLowerCase()}
                                    className={"w-8 h-5 object-cover"}
                                />
                                <span className={"text-sm"}>{` (+${locationPayload?.phone_call?.country?.isdCode})`}</span>
                            </div>}
                            selectedMenuItem={locationPayload?.phone_call?.country?.masterCountryId}
                            OptionChild={({ option }) => (
                                <div className={"w-full flex items-center justify-between gap-3"}>
                                    <div className={"flex items-center gap-3"}>
                                        <img
                                            src={`https://flagcdn.com/16x12/${option?.country?.countryDomain}.png`.toLowerCase()}
                                            className={"w-10 h-6 object-cover"}
                                        />
                                        {option?.label}
                                    </div>
                                    {(option?.value === locationPayload?.phone_call?.country?.masterCountryId)
                                        && <IoIosCheckmark size={20} color="green" />
                                    }
                                </div>
                            )}
                            menuListProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                }
                            }}
                        />
                    </div>
                    <FloatingLabelInput
                        labelItem={`phone_phone`}
                        onChange={(event) => onHandleChangeText(event.target.name, event.target.value)}
                        value={locationPayload?.phone_call?.phone_number}
                        label={"Phone *"}
                        type={"number"}
                        inputName={"phone_number"}
                        isInvalidInput={locationPayload?.phone_call?.phone_number && !validateMobileNo(locationPayload?.phone_call?.phone_number)}
                        inputClassName={"input-number-spin-none disabled:px-2 disabled:bg-text-200 disabled:cursor-not-allowed"}
                        autoFocus={true}
                    />
                </div>
            }
        </div>
    )
})

export default PhoneCallDetail;