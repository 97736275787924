import { useCallback, useEffect, useState } from "react";

import UpdateLmsInputText from "pages/auth/edulyteLms/commonComponents/UpdateLmsInputText";
import LmsEditorDescription from "pages/auth/edulyteLms/commonComponents/LmsEditorDescription";

import QuizThumbnail from "../../commonComponents/QuizThumbnail";
import QuizTags from "../../commonComponents/QuizTags";

import QuizSideBar from 'pages/auth/edulyteLms/editLmsPages/editLmsQuiz/components/quizSideBar/QuizSideBar';
import QuizSection from './components/QuizSection';

import { updateLmsQuizDetail } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setClearThumbnailDetailPayload, setThumbnailDetailPayloadLoading } from 'redux/other/other.slice';
import { resetUploadFile } from 'redux/storage/storage.slice';
import { modalConst } from 'redux/local/local.const';
import { fileCategories } from 'redux/storage/storage.const';

const EditLMSQuizBuilderPage = () => {
    const { modal } = useAppState(s => s.local)
    const { lmsQuizDetail, updateLmsQuiz } = useAppState((state) => state.lms.lmsQuiz)
    const { uploadFile } = useAppState((s) => s.storage)
    const { thumbnailDetail } = useAppState((state) => state.other)

    const dispatcher = useAppDispatcher()

    const [titleInputStatus, setTitleInputStatus] = useState("")

    useEffect(() => {
        if (uploadFile?.data && (uploadFile?.payload?.fileCategory === fileCategories.courseThumbnail.value)) {
            dispatcher(updateLmsQuizDetail(
                lmsQuizDetail?.data?.id,
                {
                    thumbnail_pic_url: uploadFile?.data[0]?.fileUrl
                }
            ))
            dispatcher(resetUploadFile())
        }
    }, [uploadFile?.data])

    useEffect(() => {
        if (thumbnailDetail?.thumbnailDetailPayload?.url) {
            dispatcher(setThumbnailDetailPayloadLoading(true))
            dispatcher(updateLmsQuizDetail(
                lmsQuizDetail?.data?.id,
                {
                    thumbnail_pic_url: thumbnailDetail?.thumbnailDetailPayload?.url
                }
            ))
            setTimeout(() => {
                dispatcher(setClearThumbnailDetailPayload())
                dispatcher(setModal({
                    ...modal,
                    [modalConst.PHOTO_SEARCH_MODAL.stateKey]: false
                }))
            }, 2000)
        }
    }, [thumbnailDetail?.thumbnailDetailPayload?.url])

    const onHandleSaveTitle = useCallback((editedTitle) => {
        if (lmsQuizDetail?.isLoading || (editedTitle === lmsQuizDetail?.data?.title)) return;
        setTitleInputStatus("Saving...")

        const body = {
            title: editedTitle
        }
        const payload = {
            isShowToast: false
        }
        dispatcher(updateLmsQuizDetail(lmsQuizDetail?.data?.id, body, payload))

        setTimeout(() => {
            setTitleInputStatus("")
        }, 1000)
    }, [lmsQuizDetail?.data])

    const onHandleSaveDescription = useCallback((editedDescription) => {
        if (lmsQuizDetail?.isLoading || (editedDescription === lmsQuizDetail?.data?.description)) return;

        const body = {
            description: editedDescription
        }
        const payload = {
            isShowToast: false
        }
        dispatcher(updateLmsQuizDetail(lmsQuizDetail?.data?.id, body, payload))
    }, [lmsQuizDetail?.data])

    return (
        <div className={"grid grid-cols-12 gap-3 pb-5"}>
            <div className={'col-start-1 col-span-full lg:col-start-1 lg:col-span-9 space-y-3 mt-3'}>
                <div className={"w-full flex flex-col gap-2"}>
                    <UpdateLmsInputText
                        isLoading={updateLmsQuiz?.isLoading}
                        isShowInputLabel={true}
                        inputClassName={"font-tagLine font-semibold text-primary-dark text-lg border-b border-text-400 my-first-step"}
                        title={lmsQuizDetail?.data?.title}
                        titleInputStatus={titleInputStatus}
                        setTitleInputStatus={setTitleInputStatus}
                        onHandleSaveTitle={onHandleSaveTitle}
                        isShowAiBtn={true}
                    />
                    <LmsEditorDescription
                        isLoading={updateLmsQuiz?.isLoading}
                        descriptionPlaceholder={"Description (optional)"}
                        editorContainerClassName={"bg-white my-other-step"}
                        description={lmsQuizDetail?.data?.description}
                        onHandleSaveDescription={onHandleSaveDescription}
                        isShowAiBtn={true}
                    />
                </div>
                <QuizSection />
            </div>
            <div className={"hidden lg:block col-start-10 col-span-3"}>
                <div className={"sticky top-16"}>
                    <QuizSideBar />
                </div>
            </div>
            <div className={'col-start-1 col-span-full px-3 py-2 bg-white rounded-lg flex lg:hidden flex-col w-full gap-5'}>
                <QuizThumbnail />
                <QuizTags />
            </div>
        </div>
    )
}

export default EditLMSQuizBuilderPage;