import { useState, useCallback, useMemo } from 'react';

import { TiDocumentText } from 'react-icons/ti';
import { FaSpinner } from 'react-icons/fa';

import ViewOnlyQuillEditor from 'components/textEditor/ViewOnlyQuillEditor';
import ToolTipView from 'components/tooltipView';
import FadedCollapse from 'components/fadedCollapse';

import LmsEditorDescription from 'pages/auth/edulyteLms/commonComponents/LmsEditorDescription';
import LmsCourseTooltip from 'pages/auth/edulyteLms/commonComponents/LmsCourseTooltip';

import { lmsQuestionTooltips } from 'pages/auth/edulyteLms/editLmsPages/editLmsQuestion/editLMSQuestion.data';

import { updateLmsQuestionAnswerDetail } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

const CustomDescriptionPlaceholderContainer = ({ lmsQuestionDetail, onHandleFeedback }) => {

    return (
        <ToolTipView
            content={!lmsQuestionDetail?.data?.answer?.feedback && lmsQuestionTooltips?.feedbackTooltip}
            disabled={lmsQuestionDetail?.data?.answer?.feedback}
        >
            <div
                className={"w-fit flex items-center justify-start gap-2 cursor-pointer"}
                onClick={onHandleFeedback}
            >
                <TiDocumentText className={"text-lg text-primary-dark"} />
                <span className={"font-bodyPri font-normal text-primary-dark hover:opacity-90"}>
                    {"Add answer feedback"}
                </span>
            </div>
        </ToolTipView>
    )
}

const CustomDescriptionContentContainer = ({ isLoading = false, lmsQuestionDetail }) => {

    const contentLength = useMemo(() => {
        const parsedHTML = new DOMParser().parseFromString(`<div>${lmsQuestionDetail?.data?.answer?.feedback}</div>`, "text/html");
        const textContent = parsedHTML.querySelector("div")?.textContent || '';
        return textContent.length > 200;
    }, [lmsQuestionDetail?.data?.answer?.feedback]);

    return (
        <div className={"w-full px-3 py-2 bg-white rounded-lg space-y-3 hover:bg-text-100"}>
            <div className={"w-full flex items-center justify-between gap-3"}>
                <div className={"flex items-center justify-start gap-2"}>
                    <LmsCourseTooltip tooltip={lmsQuestionTooltips?.feedbackTooltip} />
                    <span className={"font-bodyPri font-medium text-text-900 text-lg tracking-wide"}>
                        {"Feedback"}
                    </span>
                </div>
                {isLoading &&
                    <div className={"flex justify-end gap-1 pr-4"}>
                        <span className={"font-bodyPri font-normal text-primary-dark text-sm"}>
                            {"Please wait"}
                        </span>
                        <FaSpinner className={"animate-spin text-lg text-primary-dark"} />
                    </div>
                }
            </div>
            <FadedCollapse
                isShowMore={contentLength}
                maxHeight={contentLength ? "7.5rem" : '3rem'}
            >
                <ViewOnlyQuillEditor
                    editorContent={lmsQuestionDetail?.data?.answer?.feedback}
                    editorClassName={""}
                />
            </FadedCollapse>
        </div>
    )
}

const AnswerFeedback = () => {
    const { lmsQuestionDetail, updateLmsQuestionAnswer } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()

    const has_content = useMemo(() => (!!new DOMParser()?.parseFromString(`<div>${lmsQuestionDetail?.data?.answer?.feedback}</div>`, "text/html")?.querySelector("div")?.textContent), [lmsQuestionDetail?.data?.answer?.feedback])

    const [isShowFeedback, setIsShowFeedback] = useState(has_content)

    const onHandleSaveFeedback = useCallback((editedFeedback) => {
        if (editedFeedback === lmsQuestionDetail?.data?.answer?.feedback) return;

        const body = {
            feedback: editedFeedback
        }
        const payload = {
            isShowToast: false
        }
        dispatcher(updateLmsQuestionAnswerDetail(lmsQuestionDetail?.data?.answer?.id, body, payload))
    }, [lmsQuestionDetail?.data])

    const onHandleFeedback = useCallback(() => {
        setIsShowFeedback(!isShowFeedback)
    }, [isShowFeedback])

    return (
        <LmsEditorDescription
            isLoading={updateLmsQuestionAnswer?.isLoading}
            isEditorVisible={isShowFeedback}
            editorContainerClassName={"px-3 py-2 bg-white rounded-lg"}
            editorClassName={"rounded border-b border-text-400 focus-within:border-primary-dark"}
            description={lmsQuestionDetail?.data?.answer?.feedback}
            customDescriptionPlaceholderContainer={() => <CustomDescriptionPlaceholderContainer
                lmsQuestionDetail={lmsQuestionDetail}
                onHandleFeedback={onHandleFeedback}
            />
            }
            customDescriptionContentContainer={() => <CustomDescriptionContentContainer
                isLoading={updateLmsQuestionAnswer?.isLoading}
                lmsQuestionDetail={lmsQuestionDetail}
            />
            }
            onHandleSaveDescription={onHandleSaveFeedback}
        />
    )
}

export default AnswerFeedback;