import { useCallback, useEffect, useState } from 'react';

import { cn } from "utils/cn.utils";

import { toast } from "react-toastify";
import { Draggable } from 'react-drag-reorder';

import { FaTimes } from 'react-icons/fa';

import ComponentLoader from 'components/loader/ComponentLoader';
import { OptionSelector } from 'components/common-components/Select';

import { courseDetailNavigationOptions, lmsCourseTooltips } from '../../../../editLMSCourse.data';
import AddBorderButton from '../../../../../../commonComponents/AddBorderButton';
import PreviousNextButtons from '../../../../../../commonComponents/lmsButtons/PreviousNextButtons';

import { getMasterProficiencyList } from 'redux/master/master.request';
import { createLmsCourseProfDetail, updateLmsCourseProfListOrder, deleteLmsCourseProf } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearProfList } from 'redux/master/master.slice';

const CourseProficiency = () => {
    const { profList } = useAppState((state) => state.master)
    const { lmsCourseDetail, addLmsCourseProfDetail, deleteLmsCourseProfDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    const [isShowProficiency, setIsShowProficiency] = useState(lmsCourseDetail?.data?.proficiencies?.length > 0)

    useEffect(() => {
        dispatcher(getMasterProficiencyList())

        return () => {
            dispatcher(setClearProfList())
        }
    }, [])

    const onHandleAddProficiency = useCallback(() => {
        setIsShowProficiency(true)
    }, [isShowProficiency])

    const getChangedPos = useCallback((currentPos, newPos) => {
        var updatedList = [...lmsCourseDetail?.data?.proficiencies];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(currentPos, 1);
        // Add dropped item
        updatedList.splice(newPos, 0, reorderedItem);
        // Update State

        const body = {
            order: updatedList?.map((prof) => ({
                id: prof?.id
            }))
        }
        dispatcher(updateLmsCourseProfListOrder(body, { lms_course_id: lmsCourseDetail?.data?.id }))
    }, [lmsCourseDetail?.data]);

    const onRemoveProficiency = useCallback((selectedProfId) => {

        dispatcher(deleteLmsCourseProf(selectedProfId))
    }, [lmsCourseDetail?.data])

    const onHandleSelectProficiency = (option) => {
        const filteredProficiencyList = lmsCourseDetail?.data?.proficiencies?.filter((courseProficiency) => courseProficiency?.master_proficiency?.proficiency === option)

        if (filteredProficiencyList?.length > 0) {
            toast.warn("Duplicate Proficiency are not allowed!")
            return;
        }
        dispatcher(createLmsCourseProfDetail({ lms_course_id: lmsCourseDetail?.data?.id, proficiency: option }))
    }

    return (
        <div className={"w-full flex flex-col justify-between gap-5 rounded-lg overflow-hidden p-5 bg-white"}>
            <div className={"w-full flex flex-col gap-5"}>
                <div className={"w-full px-3 py-2 bg-divider-medium rounded-lg"}>
                    <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                        {"Level of difficulty"}
                    </span>
                </div>
                <div className={"w-full text-center font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                    {lmsCourseTooltips?.levelOfDifficulty}
                </div>
                {!isShowProficiency &&
                    <AddBorderButton
                        btnTitle={"Add Proficiency(s)"}
                        onHandleAdd={onHandleAddProficiency}
                    />
                }
                {isShowProficiency &&
                    <div className={cn(
                        "w-full px-3 py-3 flex flex-col items-start justify-start border border-dashed border-text-500 rounded-lg",
                        "font-bodyPri font-normal text-text-900"
                    )}>
                        {(profList?.isLoading || addLmsCourseProfDetail?.isLoading || deleteLmsCourseProfDetail?.isLoading) &&
                            <ComponentLoader isLoading={profList?.isLoading || addLmsCourseProfDetail?.isLoading || deleteLmsCourseProfDetail?.isLoading} />
                        }
                        {(!profList?.isLoading && !addLmsCourseProfDetail?.isLoading && !deleteLmsCourseProfDetail?.isLoading) &&
                            <div className={"flex flex-wrap items-start justify-start gap-3"}>
                                <Draggable onPosChange={getChangedPos}>
                                    {lmsCourseDetail?.data?.proficiencies?.map((courseProf, idx) => (
                                        <div
                                            key={idx}
                                            className={'w-max px-3 py-1 border-2 border-text-300 flex items-center justify-start gap-1.5 rounded-xl'}
                                        >
                                            <span className={"font-bodyPri font-medium text-text-900 text-base"}>
                                                {courseProf?.master_proficiency?.proficiency}
                                            </span>
                                            <button className="pl-3 cursor-pointer">
                                                <FaTimes
                                                    className="font-light text-secondary-dark"
                                                    onClick={() => onRemoveProficiency(courseProf?.id)}
                                                />
                                            </button>
                                        </div>
                                    ))}
                                </Draggable>
                                {profList?.profList &&
                                    <div className={cn(
                                        "w-52 h-32 md:h-48 z-[500] overflow-y-scroll rounded-lg bg-white shadow-all",
                                        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                                    )}>
                                        <OptionSelector
                                            options={profList?.profList?.map((proficiency) => ({
                                                label: proficiency?.proficiency,
                                                value: proficiency?.proficiency
                                            }))}
                                            className={""}
                                            value={""}
                                            onChange={(option) => onHandleSelectProficiency(option?.value)}
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
            <PreviousNextButtons
                previousBtn={courseDetailNavigationOptions?.LANGUAGES?.to}
                nextBtn={courseDetailNavigationOptions?.THUMBNAIL?.to}
            />
        </div>
    )
}

export default CourseProficiency;