import * as React from "react";
import { cn } from "utils/cn.utils";

type Props = {
  value: any;
  textClassName?: string;
  rows?: number;
  className?: string;
};

export const InlineTextEditor: React.FC<Props> = ({
  value,
  className,
  textClassName,
  children,
  rows = 1,
}) => {

  return (
    <div className={cn(className, "relative w-full")}>
      <div
        className={cn(
          textClassName,
          "p-2 w-full bg-transparent focus:outline-none"
        )}
      >{value}
      </div>
    </div>
  );
};

export const AffixedInlineTextEditor: React.FC<
  {
    value: any;
    className?: string;
    textClassName: string;
    discountPercentage?: any;
    discountValue?: any;
    prefix: string;
    suffix: string;
    prefixClassName?: string;
    suffixClassName?: string;
  }
> = ({
  discountPercentage,
  discountValue,
  value,
  className,
  prefix,
  suffix,
  children,
}) => {

    return (
      <div className={"flex justify-start items-center gap-2"}>
        <div className='flex justify-center items-center gap-2'>
          <span className='text-lg text-text-800'>
            {prefix}
          </span>
          <span className='text-2xl text-text-900'>
            {parseFloat("" + (discountValue / 100)).toFixed(2)}
          </span>
        </div>
        <div className='flex flex-col'>
          {discountPercentage > 0 &&
            <span className='text-text-900 font-semibold'>
              <del className='text-text-600'>
                {value && prefix + " " + parseFloat("" + (value / 100)).toFixed(2)}
              </del>
              {discountPercentage && " " + discountPercentage + "% off"}
            </span>
          }
          <span className="text-text-900 font-medium font-bodyPri">
            {suffix}
          </span>
        </div>
      </div>
    );
  };