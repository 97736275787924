import { useState, useEffect, useCallback } from 'react';
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { useRecordWebcam } from "react-record-webcam";

import ButtonLoader from 'components/loader/ButtonLoader';
import ComponentLoader from 'components/loader/ComponentLoader';
import { OptionSelector } from 'components/common-components/Select';

// import { uploadDocumentToS3 } from "redux/document/document.request";
import { uploadFileToS3 } from "redux/storage/storage.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setCameraStatus } from "redux/course/course.slice";
// import { setUploadDocumentPayload } from "redux/document/document.slice";

const OPTIONS = {
    fileName: "Video Intro",
    width: 1920,
    height: 1080,
    disableLogs: true
}

const RecordVideoModal = () => {
    // const { uploadDocument } = useAppState(s => s.document)
    const { uploadFile } = useAppState(s => s.storage)
    const { user } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()
    const recordWebCam = useRecordWebcam(OPTIONS)

    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedAudioDevice, setSelectedAudioDevice] = useState("")
    const [devices, setDevices] = useState([]);

    const onHandleOpenCamera = async () => {
        dispatcher(setCameraStatus(true))
        await recordWebCam.open()
    }

    const onHandleCloseCamera = async () => {
        dispatcher(setCameraStatus(false))
        await recordWebCam.close()
    }

    const handleDevices = useCallback(
        mediaDevices =>
            setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
        [setDevices]
    );

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }, [handleDevices]);

    useEffect(() => {
        if (devices?.length > 0) {
            setSelectedAudioDevice(devices[0]?.label)
        }
    }, [devices])

    useEffect(() => {
        onHandleOpenCamera()

        return () => {
            recordWebCam.stop()
            recordWebCam.close()
        }
    }, [])

    const getRecordingFile = async () => {
        const blob = await recordWebCam.getRecording();
        const myFile = new File([blob], 'Intro video.mp4', {
            type: blob.type,
        });

        setSelectedFile({
            file: myFile,
            fileUrl: URL.createObjectURL(myFile)
        })
    };

    const onHandleStopRecording = async () => {
        await recordWebCam.stop()
        dispatcher(setCameraStatus(false))
        // onHandleCloseCamera()
        await getRecordingFile()

    }

    const handleRetake = async () => {
        await recordWebCam.retake()
        setSelectedFile(null)
    }

    const onHandleSubmit = () => {
        if (uploadFile?.isLoading) return;

        if (!selectedFile) {
            toast.warn("No Recording Found!")
            return;
        }
        if (!window.confirm("Wait a few seconds, then refresh. Submit now?")) return;
        // dispatcher(setUploadDocumentPayload({
        //     ...uploadDocument?.uploadDocumentPayload,
        //     fileCategory: "intro_video",
        //     fileName: selectedFile?.file.name,
        //     fileType: selectedFile?.file?.type,
        //     file: selectedFile?.file
        // }))

        dispatcher(uploadFileToS3([selectedFile?.file], user?.user?.userId))
    }

    return (
        <div className={"space-y-5"}>
            <div className={"min-h-[12rem] block w-full space-y-12 py-5"}>
                <div className={"w-full h-full flex flex-col items-center justify-center gap-5"}>
                    {recordWebCam.status === "INIT" &&
                        <ComponentLoader isLoading={true} />
                    }
                    <div className={"relative aspect-video rounded-lg overflow-hidden"}>
                        <video
                            className={""}
                            ref={recordWebCam.webcamRef}
                            style={{
                                display: `${recordWebCam.status === "OPEN" ||
                                    recordWebCam.status === "RECORDING"
                                    ? "block"
                                    : "none"
                                    }`
                            }}
                            autoPlay
                        />
                        {(recordWebCam.status === "RECORDING") &&
                            <div className={"absolute top-3 right-3 flex items-center justify-start gap-1.5"}>
                                <span className={"w-5 h-5 rounded-full bg-red-700"}>
                                    {""}
                                </span>
                                <span className={"font-bodyPri font-bold text-red-700 text-base"}>
                                    {"REC"}
                                </span>
                            </div>
                        }
                        <video
                            controls
                            ref={recordWebCam.previewRef}
                            style={{
                                display: `${recordWebCam.status === "PREVIEW" ? "block" : "none"}`
                            }}
                            autoPlay
                        />
                    </div>
                    <div className={"flex flex-col items-start justify-start sm:flex-row sm:items-center sm:justify-between gap-5"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {"Camera status"}: {recordWebCam.status}
                        </span>
                        {devices?.length > 0 &&
                            <OptionSelector
                                options={devices?.map((device) => ({
                                    label: device?.label,
                                    value: device?.label
                                }))}
                                className={"w-full"}
                                value={selectedAudioDevice}
                                onChange={(option) => setSelectedAudioDevice(option?.value)}
                            />
                        }
                    </div>

                    <div className={"flex flex-wrap flex-col items-start justify-start gap-3 sm:flex-row sm:items-center sm:justify-center"}>
                        <button
                            className={cn(
                                "px-2 py-1 rounded-lg focus:outline-none",
                                !["RECORDING", "PREVIEW", "INIT", "OPEN"]?.includes(recordWebCam.status)
                                    ? "font-bodyPri font-normal text-red-500 rounded-lg border border-red-500 hover:bg-red-700 hover:text-text-50"
                                    : "border border-text-400 bg-text-300 text-text-500"
                            )}
                            disabled={
                                recordWebCam.status === "RECORDING" ||
                                recordWebCam.status === "PREVIEW" ||
                                recordWebCam.status === "INIT" ||
                                recordWebCam.status === "OPEN"
                            }
                            onClick={onHandleOpenCamera}
                        >
                            {"Open Camera"}
                        </button>
                        <button
                            className={cn(
                                "px-2 py-1 rounded-lg focus:outline-none",
                                !["RECORDING", "PREVIEW", "INIT", "CLOSED"]?.includes(recordWebCam.status)
                                    ? "font-bodyPri font-normal text-red-500 rounded-lg border border-red-500 hover:bg-red-700 hover:text-text-50"
                                    : "border border-text-400 bg-text-300 text-text-500"
                            )}
                            disabled={
                                recordWebCam.status === "RECORDING" ||
                                recordWebCam.status === "PREVIEW" ||
                                recordWebCam.status === "INIT" ||
                                recordWebCam.status === "CLOSED"
                            }
                            onClick={onHandleCloseCamera}
                        >
                            {"Close Camera"}
                        </button>
                        <button
                            className={cn(
                                "px-2 py-1 rounded-lg focus:outline-none",
                                !["CLOSED", "RECORDING", "PREVIEW", "INIT"]?.includes(recordWebCam.status)
                                    ? "font-bodyPri font-normal text-red-500 rounded-lg border border-red-500 hover:bg-red-700 hover:text-text-50"
                                    : "border border-text-400 bg-text-300 text-text-500"
                            )}
                            disabled={
                                recordWebCam.status === "CLOSED" ||
                                recordWebCam.status === "RECORDING" ||
                                recordWebCam.status === "PREVIEW" ||
                                recordWebCam.status === "INIT"
                            }
                            onClick={recordWebCam.start}
                        >
                            {"Start recording"}
                        </button>
                        <button
                            className={cn(
                                "px-2 py-1 rounded-lg focus:outline-none",
                                recordWebCam.status === "RECORDING"
                                    ? "font-bodyPri font-normal text-red-500 rounded-lg border border-red-500 hover:bg-red-700 hover:text-text-50"
                                    : "border border-text-400 bg-text-300 text-text-500"
                            )}
                            disabled={recordWebCam.status !== "RECORDING"}
                            onClick={onHandleStopRecording}
                        >
                            {"Stop recording"}
                        </button>
                        <button
                            className={cn(
                                "px-2 py-1 rounded-lg focus:outline-none",
                                recordWebCam.status === "PREVIEW"
                                    ? " font-bodyPri font-normal text-secondary-main rounded-lg border border-secondary-main hover:bg-secondary-dark hover:text-text-50"
                                    : "border border-text-400 bg-text-300 text-text-500"
                            )}
                            disabled={recordWebCam.status !== "PREVIEW"}
                            onClick={handleRetake}
                        >
                            {"Retake"}
                        </button>
                        <button
                            className={cn(
                                "px-2 py-1 rounded-lg focus:outline-none",
                                recordWebCam.status === "PREVIEW"
                                    ? " font-bodyPri font-normal text-red-500 rounded-lg border border-red-500 hover:bg-red-700 hover:text-text-50"
                                    : "border border-text-400 bg-text-300 text-text-500"
                            )}
                            disabled={recordWebCam.status !== "PREVIEW"}
                            onClick={recordWebCam.download}
                        >
                            {"Download"}
                        </button>
                    </div>
                </div>

            </div>
            {uploadFile?.message &&
                <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                    {uploadFile?.message}
                </div>
            }

            <div className={"flex justify-end items-center gap-5"}>
                <div>
                    {(uploadFile?.isLoading) &&
                        <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                            {"Processing ..."}
                        </span>
                    }
                </div>
                <button
                    type={"submit"}
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full focus:outline-none",
                        "font-buttons font-normal text-base",
                        (!selectedFile) && "text-text-300 border border-text-300 cursor-not-allowed",
                        (selectedFile) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                        (uploadFile?.isLoading) && "border border-secondary-main bg-secondary-main text-text-50"
                    )}
                    onClick={onHandleSubmit}
                >
                    {(uploadFile?.isLoading) &&
                        <ButtonLoader isLoading={uploadFile?.isLoading} />
                    }
                    {(!uploadFile?.isLoading) && "Submit"}
                </button>
            </div>
        </div>
    )
}

export default RecordVideoModal