import { useEffect, useState } from 'react';
import { cn } from "utils/cn.utils";

import { FaSpinner } from 'react-icons/fa';

import { viewContainerConst } from 'components/modals/needATutorModal/data';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setAddLeadDetailPayload } from 'redux/lead/lead.slice';
import { updateLeadDetailByLeadId } from 'redux/lead/lead.request';

const City = ({ setStepCount, setViewContainer }) => {
    const { user } = useAppState((state) => state.user)
    const { addLeadDetail } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()

    const [city, setCity] = useState("")

    useEffect(() => {
        if (addLeadDetail?.addLeadDetail?.city && (addLeadDetail?.addLeadDetail?.city === addLeadDetail?.addLeadDetailPayload?.city)) {
            setStepCount(8)
            setViewContainer(viewContainerConst.CONTACT_TIME.value)
        }
    }, [addLeadDetail?.addLeadDetail])

    useEffect(() => {
        if (addLeadDetail?.addLeadDetailPayload?.city) {
            setCity(addLeadDetail?.addLeadDetailPayload?.city)
        }
    }, [addLeadDetail?.addLeadDetailPayload?.city])

    const onAddCity = (e) => {
        if (e.keyCode === 13) {
            onHandleButton()
        }
    }

    const onHandleButton = () => {
        if (addLeadDetail?.isLoading) return;

        dispatcher(setAddLeadDetailPayload({
            ...addLeadDetail?.addLeadDetailPayload,
            city: city?.trim()
        }))
        dispatcher(updateLeadDetailByLeadId(
            addLeadDetail?.addLeadDetail?.leadId,
            {
                city: city?.trim()
            }
        ))
    }

    return (
        <div className={"w-full flex flex-col items-start justify-start gap-10 px-2 transition ease-in-out delay-1000 duration-1000 transform"}>
            <span className={"font-bodyPri font-bold text-4xl text-text-900 tracking-wide"}>
                <span className={"capitalize"}>
                    {addLeadDetail?.addLeadDetailPayload?.firstName || user?.user?.firstName}
                </span>
                {", which city/locality are you from?"}
            </span>
            <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                <span className={"font-bodyPri font-medium text-xl text-text-900 tracking-wide"}>
                    {"Your City"}
                </span>
                <input
                    type={"text"}
                    placeholder={"for example Sydney..."}
                    className={cn(
                        "w-1/2 p-3 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                        "font-bodyPri font-normal text-text-800 text-lg",
                        "placeholder:text-text-500 placeholder:text-lg"
                    )}
                    autoFocus={true}
                    value={city}
                    onChange={(event) => setCity(event.target.value)}
                    onKeyDown={onAddCity}
                />
            </div>
            <span
                className={cn(
                    "px-12 py-3 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-medium text-2xl text-text-50 bg-primary-dark",
                    "transition ease-in-out delay-100 duration-300 transform hover:scale-105"
                )}
                onClick={onHandleButton}
            >
                {"Continue"}
            </span>
            {addLeadDetail?.isLoading &&
                <div className={"flex items-center justify-center gap-2"}>
                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                    <span className={"font-bodyPri font-normal text-base text-text-700 whitespace-nowrap"}>
                        {"Please wait ..."}
                    </span>
                </div>
            }
            {addLeadDetail?.errorMsg &&
                <div className={"flex items-center justify-center font-bodyPri font-normal text-base text-red-500"}>
                    {addLeadDetail?.errorMsg}
                </div>
            }
        </div>
    )
}

export default City;