import { useEffect, useState } from 'react'
import { motion, AnimatePresence } from "framer-motion";

import PriceCard from './price-card';

import { getProductsPrices, initialResponse } from './request'

const PricingTable = ({ interval, planData }) => {
    const { plans, hasFree, productIds } = planData

    const [productsPrices, setProductsPrices] = useState(initialResponse)

    useEffect(() => {
        getPlan()
    }, [interval])

    const getPlan = async () => {
        setProductsPrices(s => ({ ...s, isLoading: true }))
        const response = await getProductsPrices({ query: { product_id: productIds.toString(), interval } })
        setProductsPrices({ ...response, data: response?.data?.filter(item => item?.price_items?.length > 0) })
    }

    return (
        <AnimatePresence key={interval}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.8 }}
            >
                <div className="w-full mx-auto py-5 space-y-5">
                    <p className={"font-bodyPri font-normal text-center text-lg md:text-xl"}>
                        {"Premium Tools, Clear Pricing. Advance Your Journey Now!"}
                    </p>
                    <div className={"h-full flex flex-col md:flex-row gap-8"}>
                        {hasFree && (<PriceCard plan={plans.free[interval]} />)}
                        {productsPrices?.data?.map((productPrice, index) => {
                            return Object.keys(plans).includes(productPrice?.metadata?.plan)
                                ? <PriceCard
                                    key={index}
                                    plan={{ ...plans[productPrice?.metadata?.plan][interval], productPrice }}
                                    isLoading={productsPrices?.isLoading}
                                    message={productsPrices?.message}
                                />
                                : null
                        })}
                    </div>
                </div>
            </motion.div>
        </AnimatePresence>
    )
}

export default PricingTable