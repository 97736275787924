
const MyFilesMembers = ({ sharedWithUsers, sharedByUser }) => {

    return (
        <div className={"flex items-center justify-start gap-1.5"}>
            <div className={"flex items-center justify-start"}>
                {sharedByUser?.picture &&
                    <div className={"w-[2.5rem] h-[2.5rem] md:w-[3rem] md:h-[3rem] rounded-full overflow-hidden z-40 -mx-1.5 border-2 border-white shadow-all-md"}>
                        <img
                            className={`w-full h-full object-cover`}
                            alt={"Image"}
                            src={sharedByUser?.picture}
                        />
                    </div>
                }
                {!sharedByUser?.picture &&
                    <div className="flex items-center justify-center text-text-900">
                        {"-"}
                    </div>
                }
                {(!sharedByUser && (!sharedWithUsers || sharedWithUsers?.length === 0)) &&
                    <div className="flex items-center justify-center text-text-900">
                        {"-"}
                    </div>
                }

                {sharedWithUsers?.length > 0 && sharedWithUsers?.slice(0, 3)?.map((sharedItems, index) => (
                    <div className={`w-[2.5rem] h-[2.5rem] md:w-[3rem] md:h-[3rem] z-${40 - ((index + 1) * 10)} -mx-1.5 border-2 border-white shadow-all-md rounded-full overflow-hidden`}>
                        <img
                            key={index}
                            className={"w-full h-full object-cover"}
                            alt={"Image"}
                            src={sharedItems?.picture}
                        />
                    </div>
                ))}
            </div>

            {sharedWithUsers?.length > 3 &&
                <div className={"m-0 p-1.5 rounded-full bg-primary-light border-2 border-white shadow-all-md flex items-center justify-center -mx-1"}>
                    <span className="w-full font-bodyPri font-normal text-primary-main text-sm text-center">
                        {`+${sharedWithUsers?.length - 3}`}
                    </span>
                </div>
            }
        </div>
    )
}

export default MyFilesMembers