import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import ButtonLoader from "components/loader/ButtonLoader";
import IsdCodeSelector from "components/optionSelector/IsdCodeSelector";
import InputOtp from "components/inputOtp/InputOtp";

import { getMasterCountryList } from 'redux/master/master.request';
import { createSendOtp, createVerifyOtp } from "redux/notification/notification.request";
import { updateUserAuthByUserId } from "redux/auth/auth.request";
// import { generateRecaptcha, sendCodeToMobileNumber, verifyMobileNumberCode } from "redux/firebase/firebase.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearSendOtp, setClearVerifyOtp } from "redux/notification/notification.slice";
import { setModal } from "redux/local/local.slice";
import { setClearUserGeneralDetailPayload } from "redux/user/user.slice";
import { setClearCountryList } from 'redux/master/master.slice';
import { otpDeliveryMethod, otpStatus } from "redux/notification/notification.const";
import { setClearAddUserAuth } from "redux/auth/auth.slice";
import { modalConst } from "redux/local/local.const";

import { validateMobileNo } from "utils/validation-functions";

const UpdateMobileModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { countryList, timeZoneCountryDetail } = useAppState(s => s.master)
    const { addUserAuth } = useAppState((state) => state.auth)
    const { user } = useAppState((state) => state.user)
    const { sendOtp, verifyOtp } = useAppState((s) => s.notification)

    const dispatcher = useAppDispatcher()

    const [myOtp, setMyOtp] = useState("")
    const [isOtpError, setIsOtpError] = useState(false)
    const [isCodeSent, setIsCodeSent] = useState(false)
    const [myCountry, setMyCountry] = useState({
        masterCountryId: 0,
        country: "",
        countryDomain: "",
        isdCode: ""
    })
    const [MobileNo, setMobileNo] = useState({
        newMobileNo: null,
        confirmNewMobileNo: null
    })
    const [isInputMobileNoError, setIsInputMobileNoError] = useState({
        isAlreadyExistMobileNo: false,
        isInvalidMobileNo: false,
        isMobileNoSimilar: true
    })

    useEffect(() => {
        dispatcher(getMasterCountryList())

        return () => {
            dispatcher(setClearCountryList())
        }
    }, [])

    useEffect(() => {
        if (countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country) => (
                    country.countryDomain === "US"
                ))
            }
            setMyCountry(myLocalCountryList[0])
        }
    }, [countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])


    useEffect(() => {
        if (MobileNo?.newMobileNo && !validateMobileNo(MobileNo?.newMobileNo)) {
            setIsInputMobileNoError({
                isAlreadyExistMobileNo: false,
                isInvalidMobileNo: true,
                isMobileNoSimilar: true
            })
        }
        if (MobileNo?.newMobileNo && validateMobileNo(MobileNo?.newMobileNo)) {
            setIsInputMobileNoError({
                isAlreadyExistMobileNo: false,
                isInvalidMobileNo: false,
                isMobileNoSimilar: true
            })
        }
        if (MobileNo?.newMobileNo === user?.user?.email) {
            setIsInputMobileNoError({
                isInvalidMobileNo: false,
                isMobileNoSimilar: true,
                isAlreadyExistMobileNo: true,
            })
        }
        if (MobileNo?.newMobileNo && MobileNo?.confirmNewMobileNo && MobileNo?.newMobileNo !== MobileNo?.confirmNewMobileNo) {
            setIsInputMobileNoError({
                isInvalidMobileNo: false,
                isAlreadyExistMobileNo: false,
                isMobileNoSimilar: false
            })
        }

        if (validateMobileNo(MobileNo?.newMobileNo) && MobileNo?.newMobileNo !== user?.user?.email && MobileNo?.newMobileNo === MobileNo?.confirmNewMobileNo) {
            setIsInputMobileNoError({
                ...isInputMobileNoError,
                isAlreadyExistMobileNo: false,
                isInvalidMobileNo: false,
                isMobileNoSimilar: true
            })
        }
    }, [MobileNo?.newMobileNo || MobileNo?.confirmNewMobileNo || isInputMobileNoError])


    useEffect(() => {
        if (addUserAuth?.addUserAuth) {
            dispatcher(setModal({
                ...modal,
                [modalConst.UPDATE_MOBILE_NO_MODAL.stateKey]: false
            }))
        }
        return () => {
            dispatcher(setClearAddUserAuth())
            dispatcher(setClearUserGeneralDetailPayload())
        }
    }, [addUserAuth?.addUserAuth])

    useEffect(() => {
        if (sendOtp?.sendOtp) {
            setIsCodeSent(true)
        }
        if (sendOtp?.errorMsg) {
            setIsCodeSent(false)
        }
    }, [sendOtp?.sendOtp])

    useEffect(() => {
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            dispatcher(setClearVerifyOtp())
            dispatcher(setClearSendOtp())
            dispatcher(updateUserAuthByUserId(
                user?.user?.userId,
                {
                    mobileNo: String(myCountry?.isdCode + "-" + MobileNo?.newMobileNo)
                }
            ))
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.PENDING.value) {
            toast.warning("Something went wrong")
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.EXPIRED.value) {
            toast.error("Code Expired")
            return;
        }
        if (!verifyOtp?.verifyOtp && verifyOtp?.errorMsg) {
            setIsOtpError(true)
        }
    }, [verifyOtp?.verifyOtp || verifyOtp?.errorMsg])


    const onSendCode = async () => {
        if (!MobileNo?.newMobileNo || !MobileNo?.confirmNewMobileNo || isInputMobileNoError?.isAlreadyExistMobileNo || isInputMobileNoError?.isInvalidMobileNo || !isInputMobileNoError?.isMobileNoSimilar) {
            toast.warn("Incorrect phone number!")
            return;
        }

        // dispatcher(setAddUserAuthPayload({
        //     ...addUserAuth?.addUserAuthPayload,
        //     mobileNo: MobileNo?.newMobileNo,
        //     isdCode: myCountry?.isdCode
        // }))
        dispatcher(createSendOtp({
            channel: otpDeliveryMethod.SMS.value,
            mobileNo: String(myCountry?.isdCode + "-" + MobileNo?.newMobileNo)
        }))
        // generateRecaptcha()
        // const mobileNumber = String(myCountry?.isdCode + "-" + MobileNo?.newMobileNo)
        // const confirmation = await sendCodeToMobileNumber(mobileNumber)
        // if (confirmation) {
        //     setIsCodeSent(true)
        // } else {
        //     setIsCodeSent(false)
        // }
    }

    const handleVerifyCode = async () => {
        if (myOtp?.length < 6) return;
        dispatcher(setClearVerifyOtp())
        if (sendOtp?.sendOtp?.otpId) {
            dispatcher(createVerifyOtp({
                otpId: sendOtp?.sendOtp?.otpId,
                otp: myOtp
            }))
        }
        // const confirmResult = await verifyMobileNumberCode(myOtp)
        // if (confirmResult) {
        //     dispatcher(updateUserAuthByUserId(
        //         user?.user?.userId,
        //         {
        //             mobileNo: String(myCountry?.isdCode + "-" + MobileNo?.newMobileNo)
        //         }
        //     ))
        // }
    }

    const onSelectIsdCode = (option) => {
        const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option.value)
        setMyCountry(selectedCountry)
    }

    window?.recaptchaVerifier?.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
    });


    if (isCodeSent) {
        return (
            <div className={"flex flex-col items-center justify-center gap-3"}>
                <div className={"flex items-center justify-center gap-3"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-sm"}>
                        {"Phone Number: "}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                        {"+" + myCountry?.isdCode + "-" + MobileNo?.newMobileNo}
                    </span>
                </div>
                <InputOtp
                    MobileNo={myCountry?.isdCode + "-" + MobileNo?.newMobileNo}
                    setMyOtp={setMyOtp}
                    otpLength={6}
                    isOtpError={isOtpError}
                    setIsOtpError={setIsOtpError}
                />
                {(verifyOtp?.errorMsg || addUserAuth?.errorMsg) &&
                    <span className={"font-bodyPri font-medium text-red-500 text-sm"}>
                        {verifyOtp?.errorMsg || addUserAuth?.errorMsg}
                    </span>
                }
                <div
                    className={cn(
                        "w-full flex items-center justify-center space-x-5 py-1.5 rounded-lg",
                        "text-text-50 font-buttons font-medium text-base text-center",
                        myOtp?.length < 6 && "bg-divider-medium",
                        myOtp?.length === 6 && "bg-primary-main hover:bg-secondary-dark cursor-pointer"
                    )}
                    onClick={handleVerifyCode}
                >
                    {(verifyOtp?.isLoading || addUserAuth?.isLoading) &&
                        <ButtonLoader isLoading={verifyOtp?.isLoading || addUserAuth?.isLoading} />
                    }
                    {(!verifyOtp?.isLoading && !addUserAuth?.isLoading) && "Verify Code"}
                </div>
            </div>
        )
    }

    return (
        <div className={"space-y-5 overflow-hidden"}>
            <div className={"w-full min-h-[16rem] flex flex-col justify-start items-start gap-5"}>
                <div className={"w-full flex flex-col items-start justify-start gap-5"}>
                    {(user?.user?.isdCode && user?.user?.mobileNo) &&
                        <div className={"flex items-center justify-center gap-2"}>
                            <div className={"font-bodyPri font-normal text-text-600 text-base"}>
                                {"Phone Number:"}
                            </div>
                            <div className={"font-bodyPri font-normal text-text-900 text-base"}>
                                {"+" + user?.user?.isdCode + " - " + user?.user?.mobileNo}
                            </div>
                        </div>
                    }
                    <div className={"w-full flex flex-col items-start justify-start gap-3"}>
                        <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                            <div className={"font-bodyPri font-normal text-text-600 text-base"}>
                                {"New Phone Number:"}
                            </div>
                            <div className={cn(
                                "w-full px-3 py-1 flex items-center justify-start rounded-md focus:outline-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                                (isInputMobileNoError?.isInvalidMobileNo || isInputMobileNoError?.isAlreadyExistMobileNo || !isInputMobileNoError?.isMobileNoSimilar)
                                && "border-2 border-red-500",
                                (!isInputMobileNoError?.isInvalidMobileNo || !isInputMobileNoError?.isAlreadyExistMobileNo || isInputMobileNoError?.isMobileNoSimilar)
                                && "border-2 border-divider-medium focus:border-2 focus:border-text-400 hover:border-text-400",
                                (MobileNo?.newMobileNo && MobileNo?.confirmNewMobileNo && MobileNo?.newMobileNo === MobileNo?.confirmNewMobileNo)
                                && "border-2 border-green-500"
                            )}
                            >
                                {countryList?.countryList &&
                                    <IsdCodeSelector
                                        options={countryList?.countryList?.map((country) => ({
                                            label: country?.isdCode,
                                            value: country?.masterCountryId,
                                            country: country
                                        }))}
                                        value={myCountry?.masterCountryId}
                                        onChange={(option) => onSelectIsdCode(option)}
                                    />
                                }
                                <input
                                    type={"number"}
                                    className={"w-full input-number-spin-none px-2 focus:outline-none"}
                                    value={MobileNo?.newMobileNo}
                                    onChange={(event) => setMobileNo({
                                        ...MobileNo,
                                        newMobileNo: event?.target?.value
                                    })}
                                />
                            </div>
                            {isInputMobileNoError?.isInvalidMobileNo &&
                                <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                    {"Invalid Phone Number!"}
                                </span>
                            }
                            {isInputMobileNoError?.isAlreadyExistMobileNo &&
                                <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                    {"An account already exist for this Phone Number"}
                                </span>
                            }
                        </div>
                        <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                            <div className={"font-bodyPri font-normal text-text-600 text-base"}>
                                {"Confirm New Phone Number:"}
                            </div>
                            <div className={cn(
                                "w-full px-3 py-1 flex items-center justify-start rounded-md focus:outline-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                                (isInputMobileNoError?.isInvalidMobileNo || isInputMobileNoError?.isAlreadyExistMobileNo || !isInputMobileNoError?.isMobileNoSimilar)
                                && "border-2 border-red-500",
                                (!isInputMobileNoError?.isInvalidMobileNo || !isInputMobileNoError?.isAlreadyExistMobileNo || isInputMobileNoError?.isMobileNoSimilar)
                                && "border-2 border-divider-medium focus:border-2 focus:border-text-400 hover:border-text-400",
                                (MobileNo?.newMobileNo && MobileNo?.confirmNewMobileNo && MobileNo?.newMobileNo === MobileNo?.confirmNewMobileNo)
                                && "border-2 border-green-500"
                            )}
                            >
                                <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                                    {myCountry?.isdCode && "+" + myCountry?.isdCode + "-"}
                                </span>
                                <input
                                    type={"number"}
                                    className={"w-full input-number-spin-none px-2 focus:outline-none"}
                                    value={MobileNo?.confirmNewMobileNo}
                                    onChange={(event) => setMobileNo({
                                        ...MobileNo,
                                        confirmNewMobileNo: event?.target?.value
                                    })}
                                />
                            </div>
                            {!isInputMobileNoError?.isMobileNoSimilar &&
                                <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                    {"The phone number you entered do not match!"}
                                </span>
                            }
                        </div>
                    </div>
                </div>

                <div className={'flex items-center justify-center'} id={'recaptcha-container'}></div>
                <div onClick={onSendCode}
                    className={cn(
                        "w-full py-1 flex item-center justify-center rounded-md",
                        "font-buttons font-normal text-base text-text-50",
                        (isInputMobileNoError?.isInvalidMobileNo || isInputMobileNoError?.isAlreadyExistMobileNo)
                        && "bg-text-300",
                        ((!isInputMobileNoError?.isInvalidMobileNo && !isInputMobileNoError?.isAlreadyExistMobileNo) || sendOtp?.isLoading)
                        && "bg-primary-main cursor-pointer"
                    )}>
                    <span className={""}>
                        {sendOtp?.isLoading &&
                            <ButtonLoader isLoading={sendOtp?.isLoading} />
                        }
                        {!sendOtp?.isLoading && "Send Code"}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default UpdateMobileModal;