export const appointmentActions = {
    TITLE: {
        action: "title"
    },
    SUB_TITLE: {
        action: "sub_title"
    },
    TITLE_ERROR: {
        action: "title_error"
    },
    INITIAL_STATE: {
        action: "initial_state"
    }
}

export const appointmentReducer = (state, action) => {
    switch (action.type) {
        case appointmentActions.TITLE.action:
            return { ...state, title: action.payload }

        case appointmentActions.SUB_TITLE.action:
            return { ...state, subtitle: action.payload }

        case appointmentActions.TITLE_ERROR.action:
            return { ...state, titleError: action.payload }

        case appointmentActions.INITIAL_STATE.action:
            return state;

        default:
            return state;
    }
}