import { useState } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { MdOutlineEdit } from 'react-icons/md'
import userService from 'redux/user/user.service'
import { certificateStatusEnum } from '../../data'
import { cn } from 'utils/cn.utils'

const UserDetails = ({ certificate, setCertificateDetails }) => {
    const [verifyEmail, setVerifyEmail] = useState('')
    const [showUser, setShowUser] = useState(null)
    const [isUserLoading, setIsUserLoading] = useState(false)
    const [error, setError] = useState('')
    const [edit, setEdit] = useState(false)

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }

    const handleVerifyEmail = async () => {
        setError('')
        if (!validateEmail(verifyEmail)) {
            setError('Please enter a valid email address.')
            return
        }
        setIsUserLoading(true)
        try {
            const requestPayload = { query: { email: verifyEmail } }
            const response = await userService.getUserList(requestPayload)
            if (response.status === 200 && response?.data.data?.records > 0) {
                const userObject = {
                    name: `${response?.data.data?.results[0]?.firstName} ${response?.data.data?.results[0]?.lastName}`,
                    profilePicUrl: response?.data.data?.results[0]?.profilePicUrl,
                    userId: response?.data.data?.results[0]?.userId
                }
                setShowUser(userObject)
                setCertificateDetails(s => ({
                    ...s,
                    result: {
                        ...s.result,
                        user: userObject?.userId,
                        placeholder: {
                            ...s.result.placeholder,
                            user_name: `${response?.data.data?.results[0]?.firstName} ${response?.data.data?.results[0]?.lastName}`,
                        }
                    }
                }))
                setEdit(true)
            } else {
                setError('User not found.')
                setShowUser(null)
            }
        } catch (error) {
            console.error(error)
            setError('Something went wrong. Please try again.')
            setShowUser(null)
        } finally {
            setIsUserLoading(false)
        }
    }

    return (
        <>
            {!edit ? (
                <div className='space-y-2'>
                    <label className='px-2 text-text-600'>Recipient's Email</label>
                    <div className={cn('flex h-10 w-full gap-2 rounded border pl-3 overflow-hidden',
                        'hover:border-primary-main focus-within:outline-primary-main focus-within:ring-2 focus-within:ring-primary-main focus-within:border-none',
                        certificate?.status === certificateStatusEnum.issued.value && 'hover:border-text-200 focus-within:outline-text-200 focus-within:ring-text-200'
                    )}>
                        <input
                            className='w-full h-full outline-none'
                            placeholder={"Enter Recipient's Email"}
                            type="email"
                            disabled={certificate?.status === certificateStatusEnum.issued.value}
                            value={verifyEmail}
                            onChange={(e) => setVerifyEmail(e.target.value)}
                        />
                        <button
                            className={cn('px-3 py-1 bg-primary-main hover:bg-primary-main text-white ease-in-out duration-200',
                                certificate?.status === certificateStatusEnum.issued.value && 'bg-text-200 hover:bg-text-200'
                            )}
                            onClick={handleVerifyEmail}
                            disabled={certificate?.status === certificateStatusEnum.issued.value}
                        >
                            {isUserLoading ? <FaSpinner className={"animate-spin text-lg text-white"} /> : "Verify"}
                        </button>
                    </div>
                    {error && <p className="text-red-600">{error}</p>}
                </div>
            ) : (
                <>
                    {showUser && (
                        <div className='flex flex-col gap-3 justify-center items-center p-3 rounded bg-background-light relative'>
                            <MdOutlineEdit size={20} className='absolute top-3 right-3 text-text-700 hover:text-secondary-dark cursor-pointer' onClick={() => setEdit(false)} />
                            <img
                                className='h-16 w-16 rounded-full object-cover border border-primary-main'
                                src={showUser?.profilePicUrl}
                                alt='User Profile'
                            />
                            <div className='flex flex-col gap-0.5 justify-center items-center'>
                                <p className='text-text-700 font-medium'>{showUser?.name}</p>
                                <p className='text-text-500 text-sm'>{verifyEmail}</p>
                            </div>
                        </div>
                    )}
                </>
            )}

            <div className='space-y-2'>
                <label className='px-2 text-text-600'>Recipient's Print Name</label>
                <input
                    className={cn('w-full px-3 py-2 rounded border hover:border-primary-main outline-primary-main',
                        certificate?.status === certificateStatusEnum.issued.value && 'hover:border-text-200 outline-text-200'
                    )}
                    placeholder={"Enter Recipient's Print Name"}
                    disabled={certificate?.status === certificateStatusEnum.issued.value}
                    value={certificate?.placeholder?.user_name}
                    onChange={(e) => setCertificateDetails(s => ({
                        ...s,
                        result: {
                            ...s.result,
                            placeholder: {
                                ...s.result.placeholder,
                                user_name: e.target.value
                            }
                        }
                    }))}
                />
            </div>
        </>
    )
}

export default UserDetails
