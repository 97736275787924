import { createSlice } from "@reduxjs/toolkit";

import { LMS_QUESTION_INITIAL_STATE } from "./lmsQuestion.initialState";

const lmsQuestion = createSlice({
    name: "lms question",
    initialState: LMS_QUESTION_INITIAL_STATE,
    reducers: {
        // lms question list
        setLmsQuestionListLoading: (state, { payload }) => {
            state.lmsQuestionList.isLoading = payload
        },
        setLmsQuestionListData: (state, { payload }) => {
            state.lmsQuestionList.data = payload
            state.lmsQuestionList.message = LMS_QUESTION_INITIAL_STATE.lmsQuestionList.message
        },
        setLmsQuestionListMessage: (state, { payload }) => {
            state.lmsQuestionList.message = payload
            state.lmsQuestionList.data = LMS_QUESTION_INITIAL_STATE.lmsQuestionList.data
        },
        resetLmsQuestionList: (state) => {
            state.lmsQuestionList = LMS_QUESTION_INITIAL_STATE.lmsQuestionList
        },

        // lms question detail
        setLmsQuestionDetailLoading: (state, { payload }) => {
            state.lmsQuestionDetail.isLoading = payload
        },
        setLmsQuestionDetailData: (state, { payload }) => {
            state.lmsQuestionDetail.data = payload
            state.lmsQuestionDetail.message = LMS_QUESTION_INITIAL_STATE.lmsQuestionDetail.message
        },
        setLmsQuestionDetailMessage: (state, { payload }) => {
            state.lmsQuestionDetail.message = payload
            state.lmsQuestionDetail.data = LMS_QUESTION_INITIAL_STATE.lmsQuestionDetail.data
        },
        resetLmsQuestionDetail: (state) => {
            state.lmsQuestionDetail = LMS_QUESTION_INITIAL_STATE.lmsQuestionDetail
        },

        // add lms question detail
        setAddLmsQuestionDetailLoading: (state, { payload }) => {
            state.addLmsQuestionDetail.isLoading = payload
        },
        setAddLmsQuestionDetailData: (state, { payload }) => {
            state.addLmsQuestionDetail.data = payload
            state.addLmsQuestionDetail.message = LMS_QUESTION_INITIAL_STATE.addLmsQuestionDetail.message
        },
        setAddLmsQuestionDetailMessage: (state, { payload }) => {
            state.addLmsQuestionDetail.message = payload
            state.addLmsQuestionDetail.data = LMS_QUESTION_INITIAL_STATE.addLmsQuestionDetail.data
        },
        resetAddLmsQuestionDetail: (state) => {
            state.addLmsQuestionDetail = LMS_QUESTION_INITIAL_STATE.addLmsQuestionDetail
        },

        // add lms question detail payload
        setAddLmsQuestionDetailPayload: (state, { payload }) => {
            state.addLmsQuestionDetail.payload = payload
        },
        resetAddLmsQuestionDetailPayload: (state) => {
            state.addLmsQuestionDetail.payload = LMS_QUESTION_INITIAL_STATE.addLmsQuestionDetail.payload
        },

        // update lms question
        setUpdateLmsQuestionLoading: (state, { payload }) => {
            state.updateLmsQuestion.isLoading = payload
        },
        setUpdateLmsQuestionMessage: (state, { payload }) => {
            state.updateLmsQuestion.message = payload
        },
        resetUpdateLmsQuestion: (state) => {
            state.updateLmsQuestion = LMS_QUESTION_INITIAL_STATE.updateLmsQuestion
        },

        // add lms question resource
        setAddLmsQuestionResourceLoading: (state, { payload }) => {
            state.addLmsQuestionResource.isLoading = payload
        },
        setAddLmsQuestionResourceMessage: (state, { payload }) => {
            state.addLmsQuestionResource.message = payload
        },
        resetAddLmsQuestionResource: (state, { payload }) => {
            state.addLmsQuestionResource = LMS_QUESTION_INITIAL_STATE.addLmsQuestionResource
        },

        // delete question resource
        setDeleteLmsQuestionResourceLoading: (state, { payload }) => {
            state.deleteLmsQuestionResource.isLoading = payload
        },
        setDeleteLmsQuestionResourceMessage: (state, { payload }) => {
            state.deleteLmsQuestionResource.message = payload
        },
        resetDeleteLmsQuestionResource: (state) => {
            state.deleteLmsQuestionResource = LMS_QUESTION_INITIAL_STATE.deleteLmsQuestionResource
        },

        // add lms question option
        setAddLmsQuestionOptionLoading: (state, { payload }) => {
            state.addLmsQuestionOption.isLoading = payload
        },
        setAddLmsQuestionOptionData: (state, { payload }) => {
            state.addLmsQuestionOption.data = payload
            state.addLmsQuestionOption.message = LMS_QUESTION_INITIAL_STATE.addLmsQuestionOption
        },
        setAddLmsQuestionOptionMessage: (state, { payload }) => {
            state.addLmsQuestionOption.message = payload
            state.addLmsQuestionOption.data = LMS_QUESTION_INITIAL_STATE.addLmsQuestionOption.data
        },
        resetAddLmsQuestionOption: (state) => {
            state.addLmsQuestionOption = LMS_QUESTION_INITIAL_STATE.addLmsQuestionOption
        },
        // add lms question option payload
        setAddLmsQuestionOptionPayload: (state, { payload }) => {
            state.addLmsQuestionOption.payload = payload
        },
        resetAddLmsQuestionOptionPayload: (state) => {
            state.addLmsQuestionOption.payload = LMS_QUESTION_INITIAL_STATE.addLmsQuestionOption.payload
        },

        // update lms question option
        setUpdateLmsQuestionOptionLoading: (state, { payload }) => {
            state.updateLmsQuestionOption.isLoading = payload
        },
        setUpdateLmsQuestionOptionMessage: (state, { payload }) => {
            state.updateLmsQuestionOption.message = payload
        },
        resetUpdateLmsQuestionOption: (state) => {
            state.updateLmsQuestionOption = LMS_QUESTION_INITIAL_STATE.updateLmsQuestionOption
        },

        // update order lms question option
        setUpdateOrderLmsQuestionOptionLoading: (state, { payload }) => {
            state.updateOrderLmsQuestionOption.isLoading = payload
        },
        setUpdateOrderLmsQuestionOptionMessage: (state, { payload }) => {
            state.updateOrderLmsQuestionOption.message = payload
        },
        resetUpdateOrderLmsQuestionOption: (state, { payload }) => {
            state.updateOrderLmsQuestionOption = LMS_QUESTION_INITIAL_STATE.updateOrderLmsQuestionOption
        },

        // delete lms question option
        setDeleteLmsQuestionOptionLoading: (state, { payload }) => {
            state.deleteLmsQuestionOption.isLoading = payload
        },
        setDeleteLmsQuestionOptionMessage: (state, { payload }) => {
            state.deleteLmsQuestionOption.message = payload
        },
        resetDeleteLmsQuestionOption: (state) => {
            state.deleteLmsQuestionOption = LMS_QUESTION_INITIAL_STATE.deleteLmsQuestionOption
        },

        // create lms question option resource
        setAddLmsQuestionOptionResourceLoading: (state, { payload }) => {
            state.addLmsQuestionOptionResource.isLoading = payload
        },
        setAddLmsQuestionOptionResourceMessage: (state, { payload }) => {
            state.addLmsQuestionOptionResource.message = payload
        },
        resetAddLmsQuestionOptionResource: (state) => {
            state.addLmsQuestionOptionResource = LMS_QUESTION_INITIAL_STATE.addLmsQuestionOptionResource
        },

        // delete question option resource
        setDeleteLmsQuestionOptionResourceLoading: (state, { payload }) => {
            state.deleteLmsQuestionOptionResource.isLoading = payload
        },
        setDeleteLmsQuestionOptionResourceMessage: (state, { payload }) => {
            state.deleteLmsQuestionOptionResource.message = payload
        },
        resetDeleteLmsQuestionOptionResource: (state) => {
            state.deleteLmsQuestionOptionResource = LMS_QUESTION_INITIAL_STATE.deleteLmsQuestionOptionResource
        },

        // update lms question answer
        setUpdateLmsQuestionAnswerLoading: (state, { payload }) => {
            state.updateLmsQuestionAnswer.isLoading = payload
        },
        setUpdateLmsQuestionAnswerData: (state, { payload }) => {
            state.updateLmsQuestionAnswer.data = payload
            state.updateLmsQuestionAnswer.message = LMS_QUESTION_INITIAL_STATE.updateLmsQuestionAnswer.message
        },
        setUpdateLmsQuestionAnswerMessage: (state, { payload }) => {
            state.updateLmsQuestionAnswer.message = payload
        },
        resetUpdateLmsQuestionAnswer: (state) => {
            state.updateLmsQuestionAnswer = LMS_QUESTION_INITIAL_STATE.updateLmsQuestionAnswer
        },

        // update lms question answer payload
        setUpdateLmsQuestionAnswerPayload: (state, { payload }) => {
            state.updateLmsQuestionAnswer.payload = payload
        },
        resetUpdateLmsQuestionAnswerPayload: (state) => {
            state.updateLmsQuestionAnswer.payload = LMS_QUESTION_INITIAL_STATE.updateLmsQuestionAnswer.payload
        },

        // create lms question answer resource
        setAddLmsQuestionAnswerResourceLoading: (state, { payload }) => {
            state.addLmsQuestionAnswerResource.isLoading = payload
        },
        setAddLmsQuestionAnswerResourceMessage: (state, { payload }) => {
            state.addLmsQuestionAnswerResource.message = payload
            state.addLmsQuestionAnswerResource.data = LMS_QUESTION_INITIAL_STATE.addLmsQuestionAnswerResource.data
        },
        resetAddLmsQuestionAnswerResource: (state) => {
            state.addLmsQuestionAnswerResource = LMS_QUESTION_INITIAL_STATE.addLmsQuestionAnswerResource
        },

        // delete lms question answer resource
        setDeleteLmsQuestionAnswerResourceLoading: (state, { payload }) => {
            state.deleteLmsQuestionAnswerResource.isLoading = payload
        },
        setDeleteLmsQuestionAnswerResourceMessage: (state, { payload }) => {
            state.deleteLmsQuestionAnswerResource.message = payload
        },
        resetDeleteLmsQuestionAnswerResource: (state) => {
            state.deleteLmsQuestionAnswerResource = LMS_QUESTION_INITIAL_STATE.deleteLmsQuestionAnswerResource
        },

        // lms question settings detail payload
        setLmsQuestionSettingsDetailPayload: (state, { payload }) => {
            state.lmsQuestionSettingsDetail.payload = payload
        },
        resetLmsQuestionSettingsDetailPayload: (state) => {
            state.lmsQuestionSettingsDetail.payload = LMS_QUESTION_INITIAL_STATE.lmsQuestionSettingsDetail.payload
        },

        // lms question instructor detail
        // add lms question instructor detail
        setAddLmsQuestionInstructorDetailLoading: (state, { payload }) => {
            state.addLmsQuestionInstructorDetail.isLoading = payload
        },
        setAddLmsQuestionInstructorDetailData: (state, { payload }) => {
            state.addLmsQuestionInstructorDetail.data = payload
            state.addLmsQuestionInstructorDetail.message = LMS_QUESTION_INITIAL_STATE.addLmsQuestionInstructorDetail.message
        },
        setAddLmsQuestionInstructorDetailMessage: (state, { payload }) => {
            state.addLmsQuestionInstructorDetail.message = payload
            state.addLmsQuestionInstructorDetail.data = LMS_QUESTION_INITIAL_STATE.addLmsQuestionInstructorDetail.data
        },
        resetAddLmsQuestionInstructorDetail: (state) => {
            state.addLmsQuestionInstructorDetail = LMS_QUESTION_INITIAL_STATE.addLmsQuestionInstructorDetail
        },

        // add lms question instructor detail payload
        setAddLmsQuestionInstructorDetailPayload: (state, { payload }) => {
            state.addLmsQuestionInstructorDetail.payload = payload
        },
        resetAddLmsQuestionInstructorDetailPayload: (state) => {
            state.addLmsQuestionInstructorDetail.payload = LMS_QUESTION_INITIAL_STATE.addLmsQuestionInstructorDetail.payload
        },

        // modify lms question instructor detail
        setModifyLmsQuestionInstructorDetailLoading: (state, { payload }) => {
            state.modifyLmsQuestionInstructorDetail.isLoading = payload
        },
        setModifyLmsQuestionInstructorDetailData: (state, { payload }) => {
            state.modifyLmsQuestionInstructorDetail.data = payload
            state.modifyLmsQuestionInstructorDetail.message = LMS_QUESTION_INITIAL_STATE.modifyLmsQuestionInstructorDetail.message
        },
        setModifyLmsQuestionInstructorDetailMessage: (state, { payload }) => {
            state.modifyLmsQuestionInstructorDetail.message = payload
            state.modifyLmsQuestionInstructorDetail.data = LMS_QUESTION_INITIAL_STATE.modifyLmsQuestionInstructorDetail.data
        },
        resetModifyLmsQuestionInstructorDetail: (state) => {
            state.modifyLmsQuestionInstructorDetail = LMS_QUESTION_INITIAL_STATE.modifyLmsQuestionInstructorDetail
        },

        // delete lms question instructor detail
        setDestroyLmsQuestionInstructorDetailLoading: (state, { payload }) => {
            state.destroyLmsQuestionInstructorDetail.isLoading = payload
        },
        setDestroyLmsQuestionInstructorDetailMessage: (state, { payload }) => {
            state.destroyLmsQuestionInstructorDetail.message = payload
        },
        resetDestroyLmsQuestionInstructorDetail: (state) => {
            state.destroyLmsQuestionInstructorDetail = LMS_QUESTION_INITIAL_STATE.destroyLmsQuestionInstructorDetail
        },

        // lms question tag reducers
        // lms question tag list
        setLmsQuestionTagListLoading: (state, { payload }) => {
            state.lmsQuestionTagList.isLoading = payload
        },
        setLmsQuestionTagListData: (state, { payload }) => {
            state.lmsQuestionTagList.data = payload
            state.lmsQuestionTagList.message = LMS_QUESTION_INITIAL_STATE.lmsQuestionTagList.message
        },
        setLmsQuestionTagListMessage: (state, { payload }) => {
            state.lmsQuestionTagList.message = payload
            state.lmsQuestionTagList.data = LMS_QUESTION_INITIAL_STATE.lmsQuestionList.data
        },
        resetLmsQuestionTagList: (state) => {
            state.lmsQuestionTagList = LMS_QUESTION_INITIAL_STATE.lmsQuestionTagList
        },

        // add lms question tag detail
        setAddLmsQuestionTagDetailLoading: (state, { payload }) => {
            state.addLmsQuestionTagDetail.isLoading = payload
        },
        setAddLmsQuestionTagDetailMessage: (state, { payload }) => {
            state.addLmsQuestionTagDetail.message = payload
        },
        resetAddLmsQuestionTagDetail: (state) => {
            state.addLmsQuestionTagDetail = LMS_QUESTION_INITIAL_STATE.addLmsQuestionTagDetail
        },

        // update lms question tag list order
        setModifyLmsQuestionTagListOrderLoading: (state, { payload }) => {
            state.modifyLmsQuestionTagListOrder.isLoading = payload
        },
        setModifyLmsQuestionTagListOrderMessage: (state, { payload }) => {
            state.modifyLmsQuestionTagListOrder.message = payload
        },
        resetModifyLmsQuestionTagListOrder: (state) => {
            state.modifyLmsQuestionTagListOrder = LMS_QUESTION_INITIAL_STATE.modifyLmsQuestionTagListOrder
        },

        // destroy lms question tag detail
        setDestroyLmsQuestionTagDetailLoading: (state, { payload }) => {
            state.destroyLmsQuestionTagDetail.isLoading = payload
        },
        setDestroyLmsQuestionTagDetailMessage: (state, { payload }) => {
            state.destroyLmsQuestionTagDetail.message = payload
        },
        resetDestroyLmsQuestionTagDetail: (state) => {
            state.destroyLmsQuestionTagDetail = LMS_QUESTION_INITIAL_STATE.destroyLmsQuestionTagDetail
        },

        // page based reducers
        setLmsQuestionPageResourceListLoading: (state, { payload }) => {
            state.lmsQuestionPageResourceList.isLoading = payload
        },
        setLmsQuestionPageResourceListData: (state, { payload }) => {
            state.lmsQuestionPageResourceList.data = payload
            state.lmsQuestionPageResourceList.message = LMS_QUESTION_INITIAL_STATE.lmsQuestionPageResourceList.message
        },
        setLmsQuestionPageResourceListMessage: (state, { payload }) => {
            state.lmsQuestionPageResourceList.message = payload
            state.lmsQuestionPageResourceList.data = LMS_QUESTION_INITIAL_STATE.lmsQuestionPageResourceList.data
        },
        resetLmsQuestionPageResourceList: (state) => {
            state.lmsQuestionPageResourceList = LMS_QUESTION_INITIAL_STATE.lmsQuestionPageResourceList
        },

        setLmsCreateBulkQuestionsLoading: (state, { payload }) => {
            state.lmsCreateBulkQuestions.isLoading = payload
        },
        setLmsCreateBulkQuestionsMessage: (state, { payload }) => {
            state.lmsCreateBulkQuestions.message = payload
            state.lmsCreateBulkQuestions.data = LMS_QUESTION_INITIAL_STATE.lmsCreateBulkQuestions.data
        },
        setLmsCreateBulkQuestionsData: (state, { payload }) => {
            state.lmsCreateBulkQuestions.data = payload
            state.lmsCreateBulkQuestions.message = LMS_QUESTION_INITIAL_STATE.lmsCreateBulkQuestions.message
        },
        resetLmsCreateBulkQuestions: (state) => {
            state.lmsCreateBulkQuestions = LMS_QUESTION_INITIAL_STATE.lmsCreateBulkQuestions
        },
    }
})

export const {
    setLmsQuestionListLoading,
    setLmsQuestionListData,
    setLmsQuestionListMessage,
    resetLmsQuestionList,

    setLmsQuestionDetailLoading,
    setLmsQuestionDetailData,
    setLmsQuestionDetailMessage,
    resetLmsQuestionDetail,

    setAddLmsQuestionDetailLoading,
    setAddLmsQuestionDetailData,
    setAddLmsQuestionDetailMessage,
    resetAddLmsQuestionDetail,
    setAddLmsQuestionDetailPayload,
    resetAddLmsQuestionDetailPayload,

    setUpdateLmsQuestionLoading,
    setUpdateLmsQuestionMessage,
    resetUpdateLmsQuestion,

    setAddLmsQuestionResourceLoading,
    setAddLmsQuestionResourceMessage,

    setDeleteLmsQuestionResourceLoading,
    setDeleteLmsQuestionResourceMessage,
    resetDeleteLmsQuestionResource,

    setAddLmsQuestionOptionLoading,
    setAddLmsQuestionOptionData,
    setAddLmsQuestionOptionMessage,
    resetAddLmsQuestionOption,
    setAddLmsQuestionOptionPayload,
    resetAddLmsQuestionOptionPayload,

    setUpdateLmsQuestionOptionLoading,
    setUpdateLmsQuestionOptionMessage,
    resetUpdateLmsQuestionOption,

    setUpdateOrderLmsQuestionOptionLoading,
    setUpdateOrderLmsQuestionOptionMessage,
    resetUpdateOrderLmsQuestionOption,

    setDeleteLmsQuestionOptionLoading,
    setDeleteLmsQuestionOptionMessage,
    resetDeleteLmsQuestionOption,

    setAddLmsQuestionOptionResourceLoading,
    setAddLmsQuestionOptionResourceMessage,
    resetAddLmsQuestionOptionResource,

    setDeleteLmsQuestionOptionResourceLoading,
    setDeleteLmsQuestionOptionResourceMessage,
    resetDeleteLmsQuestionOptionResource,

    setUpdateLmsQuestionAnswerLoading,
    setUpdateLmsQuestionAnswerData,
    setUpdateLmsQuestionAnswerMessage,
    resetUpdateLmsQuestionAnswer,
    setUpdateLmsQuestionAnswerPayload,
    resetUpdateLmsQuestionAnswerPayload,

    setAddLmsQuestionAnswerResourceLoading,
    setAddLmsQuestionAnswerResourceMessage,
    resetAddLmsQuestionAnswerResource,

    setDeleteLmsQuestionAnswerResourceLoading,
    setDeleteLmsQuestionAnswerResourceMessage,
    resetDeleteLmsQuestionAnswerResource,

    // lms question settings detail
    setLmsQuestionSettingsDetailPayload,
    resetLmsQuestionSettingsDetailPayload,

    // lms question instructor detail
    setAddLmsQuestionInstructorDetailLoading,
    setAddLmsQuestionInstructorDetailData,
    setAddLmsQuestionInstructorDetailMessage,
    resetAddLmsQuestionInstructorDetail,
    setAddLmsQuestionInstructorDetailPayload,
    resetAddLmsQuestionInstructorDetailPayload,

    setModifyLmsQuestionInstructorDetailLoading,
    setModifyLmsQuestionInstructorDetailData,
    setModifyLmsQuestionInstructorDetailMessage,
    resetModifyLmsQuestionInstructorDetail,

    setDestroyLmsQuestionInstructorDetailLoading,
    setDestroyLmsQuestionInstructorDetailMessage,
    resetDestroyLmsQuestionInstructorDetail,

    setLmsQuestionTagListLoading,
    setLmsQuestionTagListData,
    setLmsQuestionTagListMessage,
    resetLmsQuestionTagList,

    setAddLmsQuestionTagDetailLoading,
    setAddLmsQuestionTagDetailMessage,
    resetAddLmsQuestionTagDetail,

    setModifyLmsQuestionTagListOrderLoading,
    setModifyLmsQuestionTagListOrderMessage,
    resetModifyLmsQuestionTagListOrder,

    setDestroyLmsQuestionTagDetailLoading,
    setDestroyLmsQuestionTagDetailMessage,
    resetDestroyLmsQuestionTagDetail,

    setLmsQuestionPageResourceListLoading,
    setLmsQuestionPageResourceListData,
    setLmsQuestionPageResourceListMessage,
    resetLmsQuestionPageResourceList,

    setLmsCreateBulkQuestionsLoading,
    setLmsCreateBulkQuestionsMessage,
    setLmsCreateBulkQuestionsData,
    resetLmsCreateBulkQuestions,
} = lmsQuestion.actions

export default lmsQuestion.reducer