import format from "string-format";

import { baseApiInstance, baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "redux/lead/lead.const";

class LeadService {
    createLeadDetail = async ({ body }) => {
        const response = await baseApiInstance().post(
            url.CREATE_LEAD_DETAIL,
            body
        )

        return response;
    }

    updateLeadDetailByLeadId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LEAD_DETAIL, params),
            body
        )

        return response;
    }
}

export default new LeadService();