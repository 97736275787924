import { useEffect } from 'react';
import { useParams } from 'react-router-dom';


import Footer1 from 'components/footer1/Footer1';

import { PageContentStyle, CompanySection, FooterContainer } from "pages/global/company/CompanyPage.Style";

import { tabsList, companyNameConst } from 'pages/global/company/data';
import Hero from 'pages/global/company/components/hero/Hero';
import CompanyInformation from 'pages/global/company/components/companyInformation/CompanyInformation';
import Tabs from 'pages/global/company/components/tabs/Tabs';
import About from 'pages/global/company/components/about/About';
import Courses from 'pages/global/company/components/courses/Courses';
import People from "pages/global/company/components/people/People";
import Reviews from 'pages/global/company/components/reviews/Reviews';
import LinkTree from "pages/global/company/components/importantLinks/ImportantLinks";
// import PeopleAlsoView from 'pages/global/company/components/peopleAlsoView/PeopleAlsoView';
import CompanyFAQ from 'pages/global/company/components/companyFaq/CompanyFAQ';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getCompanyPublicProfileByCompanyName } from "redux/company/company.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const CompanyPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)

  const dispatcher = useAppDispatcher()
  const { companyName } = useParams()

  const containerIDs = ["HOME", "ABOUT", "COURSES", "PEOPLE", "REVIEWS", "LINKS"]

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.COMPANY))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    if (companyName) {
      dispatcher(getCompanyPublicProfileByCompanyName(companyNameConst[companyName.toUpperCase()].value))
    }
  }, [companyName])

  return (
    <PageContentStyle>
      <Hero />
      <CompanyInformation />
      <CompanySection>
        <Tabs tabsList={tabsList} containerIDs={containerIDs} />
        <About />
        <Courses />
        <People />
        <Reviews />
        <LinkTree />
        {/* <PeopleAlsoView /> */}
        <CompanyFAQ />
      </CompanySection>
      <FooterContainer>
        <Footer1 />
      </FooterContainer>
    </PageContentStyle>
  )
}

export default CompanyPage;