import AddLink from "./tabComponents/AddLink";
import MyFiles from "./tabComponents/myFiles/MyFiles";
import UploadFile from "./tabComponents/UploadFile";
import YouTubeUpload from "./tabComponents/YouTubeUpload";

export const uploadLmsMediaConst = {
    MY_FILES: {
        value: "my_files",
        label: "My Files",
        component: <MyFiles />
    },
    UPLOAD: {
        value: "upload",
        label: "Upload",
        component: <UploadFile />
    },
    YOUTUBE: {
        value: "youtube",
        label: "YouTube",
        component: <YouTubeUpload />
    },
    ADD_LINK: {
        value: "add_link",
        label: "Add Link",
        component: <AddLink />
    }
}

export const myFilesTableHeaderConst = [
    "File Name",
    "Members",
    "File Size",
    "Last Modified",
    "Actions"
]