import { toast } from "react-toastify";

import lmsLectureService from "redux/edulyteLms/lmsLecture/lmsLecture.service";
import {

    /* tutor lms lecture */
    setTutorLmsLectureListLoading,
    setTutorLmsLectureListData,
    setTutorLmsLectureListMessage,

    setTutorLmsLectureDetailLoading,
    setTutorLmsLectureDetailData,
    setTutorLmsLectureDetailMessage,

    /* lms lecture */
    setAddLmsLectureDetailLoading,
    setAddLmsLectureDetailData,
    setAddLmsLectureDetailMessage,

    setModifyLmsLectureDetailLoading,
    setModifyLmsLectureDetailData,
    setModifyLmsLectureDetailMessage,

    setDestroyLmsLectureDetailLoading,
    setDestroyLmsLectureDetailMessage,

    // lms lecture instructor
    setAddLmsLectureInstructorDetailLoading,
    setAddLmsLectureInstructorDetailData,
    setAddLmsLectureInstructorDetailMessage,

    setModifyLmsLectureInstructorDetailLoading,
    setModifyLmsLectureInstructorDetailData,
    setModifyLmsLectureInstructorDetailMessage,

    setDestroyLmsLectureInstructorDetailLoading,
    setDestroyLmsLectureInstructorDetailMessage,

    /* lms lecture setting */
    setLmsLectureSettingListLoading,
    setLmsLectureSettingListData,
    setLmsLectureSettingListMessage,

    setLmsLectureSettingDetailLoading,
    setLmsLectureSettingDetailData,
    setLmsLectureSettingDetailMessage,

    setAddLmsLectureSettingDetailLoading,
    setAddLmsLectureSettingDetailData,
    setAddLmsLectureSettingDetailMessage,

    setModifyLmsLectureSettingDetailLoading,
    setModifyLmsLectureSettingDetailData,
    setModifyLmsLectureSettingDetailMessage,

    setDestroyLmsLectureSettingDetailLoading,
    setDestroyLmsLectureSettingDetailMessage,

    // page based reducers
    setLmsLecturePageResourceListLoading,
    setLmsLecturePageResourceListData,
    setLmsLecturePageResourceListMessage
} from "redux/edulyteLms/lmsLecture/lmsLecture.slice";

import { setLmsCourseDetailData } from "../lmsCourse/lmsCourse.slice";
import lmsResourceService from "../lmsResource/lmsResource.service";


/* tutor lms lecture request */
export const getTutorLmsLectureList = (query) => async (dispatch) => {
    dispatch(setTutorLmsLectureListLoading(true))
    try {
        const requestData = {
            query: query
        }
        const response = await lmsLectureService.getTutorLmsLectureList(requestData)
        if (response.status === 200) {
            dispatch(setTutorLmsLectureListData(response.data.data))
        } else {
            throw new Error(response)
        }
    }
    catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setTutorLmsLectureListMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    }
    finally {
        dispatch(setTutorLmsLectureListLoading(false))
    }
}

export const getTutorLmsLectureDetail = (id) => async (dispatch) => {
    dispatch(setTutorLmsLectureDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLectureService.getTutorLmsLectureDetail(requestData)
        if (response.status === 200) {
            dispatch(setTutorLmsLectureDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setTutorLmsLectureDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    } finally {
        dispatch(setTutorLmsLectureDetailLoading(false))
    }
}

/* lms lecture request */
export const createLmsLectureDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsLectureDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await lmsLectureService.createLmsLectureDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddLmsLectureDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddLmsLectureDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setAddLmsLectureDetailLoading(false))
    }
}

export const updateLmsLectureDetail = (id, body, payload = { isShowToast: true }) => async (dispatch, getState) => {
    dispatch(setModifyLmsLectureDetailLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: { id: id },
            body: body
        }
        const response = await lmsLectureService.updateLmsLectureDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyLmsLectureDetailData(response?.data?.data))
            dispatch(setTutorLmsLectureDetailData(response?.data?.data))
            if (payload?.sectionId && payload?.sectionLessonId)
                dispatch(setLmsCourseDetailData({
                    ...lmsCourseDetail?.data,
                    course_sections: lmsCourseDetail?.data?.course_sections?.map((courseSection) => (
                        (courseSection?.id === payload?.sectionId)
                            ? {
                                ...courseSection, lms_course_section_lessons: courseSection?.lms_course_section_lessons?.map((courseSectionLesson) => (
                                    (courseSectionLesson?.id === payload?.sectionLessonId)
                                        ? { ...courseSectionLesson, lecture: { ...courseSectionLesson?.lecture, visibility: response.data.data?.lecture_setting?.visibility } }
                                        : courseSectionLesson
                                ))
                            }
                            : courseSection
                    ))
                }))
            if (payload?.isShowToast) {
                toast.success(response.data.message)
            }
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyLmsLectureDetailMessage(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setModifyLmsLectureDetailLoading(false))
    }
}

export const deleteLmsLectureDetail = (id) => async (dispatch) => {
    dispatch(setDestroyLmsLectureDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLectureService.deleteLmsLecture(requestData)
        if (response.status === 204) {
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyLmsLectureDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setDestroyLmsLectureDetailLoading(false))
    }
}

// lms lecture instructors
export const createLmsLectureInstructorDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsLectureInstructorDetailLoading(true))

    try {
        const { lmsLectureDetail } = getState().lms.lmsLecture
        const requestData = {
            body: body
        }

        const response = await lmsLectureService.createLmsLectureInstructorDetail(requestData)
        if ((response.status === 200) || (response.status === 201)) {
            dispatch(setAddLmsLectureInstructorDetailData(response.data.data))
            dispatch(setTutorLmsLectureDetailData({
                ...lmsLectureDetail?.data,
                lecture_users: [...lmsLectureDetail?.data?.lecture_users, response.data.data]
            }))
            toast.success(response.data.message || "Created Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsLectureInstructorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsLectureInstructorDetailLoading(false))
    }
}

// update lms course instructor detail
export const updateLmsLectureInstructorDetail = (lectureInstructorId, body) => async (dispatch, getState) => {
    dispatch(setModifyLmsLectureInstructorDetailLoading(true))

    try {
        const { lmsLectureDetail } = getState().lms.lmsLecture
        const requestData = {
            params: { lectureInstructorId: lectureInstructorId },
            body: body
        }
        const response = await lmsLectureService.updateLmsLectureInstructorDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyLmsLectureInstructorDetailData(response.data.data))
            dispatch(setTutorLmsLectureDetailData({
                ...lmsLectureDetail?.data,
                lecture_users: lmsLectureDetail?.data?.lecture_users?.map((lectureTutor) => (
                    (lectureTutor?.id === response.data.data.id) ? response.data.data : lectureTutor
                ))
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyLmsLectureInstructorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyLmsLectureInstructorDetailLoading(false))
    }
}
// delete lms course instructor detail
export const deleteLmsLectureInstructorDetail = (lectureInstructorId) => async (dispatch, getState) => {
    dispatch(setDestroyLmsLectureInstructorDetailLoading(true))

    try {
        const { lmsLectureDetail } = getState().lms.lmsLecture
        const requestData = {
            params: { lectureInstructorId: lectureInstructorId }
        }
        const response = await lmsLectureService.deleteLmsLectureInstructorDetail(requestData)
        if (response.status === 204) {
            dispatch(setTutorLmsLectureDetailData({
                ...lmsLectureDetail?.data,
                lecture_users: lmsLectureDetail?.data?.lecture_users?.filter((lectureTutor) => lectureTutor?.id != lectureInstructorId)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.message || "Something went wrong!")
        dispatch(setDestroyLmsLectureInstructorDetailMessage(error.response.data.message || error.response.data.message || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.message || "Something went wrong!")
    } finally {
        dispatch(setDestroyLmsLectureInstructorDetailLoading(false))
    }
}

/* lms lecture setting request */
export const getLmsLectureSettingList = (query) => async (dispatch) => {
    dispatch(setLmsLectureSettingListLoading(true))
    try {
        const requestData = {
            query: query
        }
        const response = await lmsLectureService.getLmsLectureSettingList(requestData)
        if (response.status === 200) {
            dispatch(setLmsLectureSettingListData(response.data.data))
        } else {
            throw new Error(response)
        }
    }
    catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsLectureSettingListMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    }
    finally {
        dispatch(setLmsLectureSettingListLoading(false))
    }
}

export const getLmsLectureSettingDetail = (id) => async (dispatch) => {
    dispatch(setLmsLectureSettingDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLectureService.getLmsLectureSettingDetail(requestData)

        if (response.status === 200) {
            dispatch(setLmsLectureSettingDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsLectureSettingDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
    } finally {
        dispatch(setLmsLectureSettingDetailLoading(false))
    }
}

export const createLmsLectureSettingDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsLectureSettingDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await lmsLectureService.createLmsLectureSettingDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddLmsLectureSettingDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddLmsLectureSettingDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setAddLmsLectureSettingDetailLoading(false))
    }
}

export const updateLmsLectureSettingDetail = (id, body) => async (dispatch) => {
    dispatch(setModifyLmsLectureSettingDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            },
            body: body
        }
        const response = await lmsLectureService.updateLmsLectureSettingDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyLmsLectureSettingDetailData(response?.data?.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyLmsLectureSettingDetailMessage(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setModifyLmsLectureSettingDetailLoading(false))
    }
}

export const deleteLmsLectureSettingDetail = (id) => async (dispatch) => {
    dispatch(setDestroyLmsLectureSettingDetailLoading(true))

    try {
        const requestData = {
            params: {
                id: id
            }
        }
        const response = await lmsLectureService.deleteLmsLecture(requestData)
        if (response.status === 204) {
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyLmsLectureSettingDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setDestroyLmsLectureSettingDetailLoading(false))
    }
}

export const getLmsLecturePageResourceList = (query) => async (dispatch) => {
    dispatch(setLmsLecturePageResourceListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await lmsResourceService.getLmsResourceList(requestData)
        if (response.status === 200) {
            dispatch(setLmsLecturePageResourceListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLmsLecturePageResourceListMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
    } finally {
        dispatch(setLmsLecturePageResourceListLoading(false))
    }
}