import { useEffect } from "react";
import { Link } from "react-router-dom";
import { cn } from "utils/cn.utils";

import CompanyCourseCard from "components/companyCourseCard/CompanyCourseCard";

import { companyEditBtn } from "pages/auth/editCompany/data";

import { getCourseListByCompanyId } from 'redux/company/company.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { FiEdit } from "react-icons/fi";
import { setClearCompanyCourseList } from "redux/company/company.slice";

const EditCourses = ({ editBtnConst, setEditBtnConst }) => {
    const { companyPublicProfile, companyCourseList } = useAppState((state) => state.company)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (companyPublicProfile?.companyPublicProfile?.companyId) {
            dispatcher(getCourseListByCompanyId(companyPublicProfile?.companyPublicProfile?.companyId))
        }

        return () => {
            dispatcher(setClearCompanyCourseList())
        }
    }, [companyPublicProfile?.companyPublicProfile?.companyId])

    return (
        <div className={"flex items-center justify-center px-5 md:px-0"} id={"COURSES"}>
            <div className={"max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
                <div className={"space-y-3 p-5 bg-white"}>
                    <div className={"flex items-center justify-start gap-5"}>
                        <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                            {"Courses"}
                        </span>
                        {!editBtnConst &&
                            <Link to={"/"} className={""}>
                                <FiEdit className={"text-lg text-text-500 hover:text-text-700 cursor-pointer"}
                                    onClick={() => {
                                        setEditBtnConst(companyEditBtn.courses.value)
                                    }}
                                />
                            </Link>
                        }
                    </div>
                    <div className={cn(
                        "grid grid-flow-col gap-5 p-5 overflow-hidden overflow-x-scroll",
                        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                    )}>
                        {companyCourseList?.companyCourseList?.map((course, index) => (
                            <CompanyCourseCard
                                key={index}
                                course={course}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditCourses;