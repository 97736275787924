import { useCallback, useState, useEffect, useMemo } from 'react';

import { IoIosCheckmark } from 'react-icons/io';
import { MdOutlineLocalPhone } from 'react-icons/md';

import FloatingLabelSelect from 'components/floating/floatingLabelSelect';
import FloatingLabelInput from 'components/floating/floatingLabelInput';

import SaveAndCancelBtn from 'components/modals/crmModals/commonComponents/SaveAndCancelBtn';
import RadioInput from 'components/modals/crmModals/commonComponents/RadioInput';

import { useAppState } from 'hooks/useStore';
import { crmContactLabelsEnum } from 'redux/crm/crm.const';

import { validateMobileNo } from "utils/validation-functions";

const ContactPhoneItem = ({
    index = 0,
    isLoading = false,
    isShowSaveBtn = false,
    isShowCancelBtn = false,
    editBtnConst = null,
    setEditBtnConst = () => { return },
    phoneItem = null,
    onHandlePrimaryPhoneDetail,
    onHandleSavePhoneDetail,
    onHandleDeletePhoneDetail
}) => {
    const { countryList } = useAppState((state) => state.master)

    const [phonePayload, setPhonePayload] = useState(phoneItem)
    const [invalidPhoneDetail, setInvalidPhoneDetail] = useState({
        phoneId: null,
        isInvalidPhone: false
    })

    useEffect(() => {
        setPhonePayload(phoneItem)
    }, [phoneItem])

    const isEditDisabled = useMemo(() => {
        if (editBtnConst && (editBtnConst?.id !== phoneItem?.id)) return true;
        else return false;
    }, [editBtnConst, phoneItem])

    const isSimilarPhoneDetail = useMemo(() => JSON.stringify(phonePayload) === JSON.stringify(phoneItem), [phonePayload, phoneItem])

    const onHandleChangePhoneDetail = (updatedValue) => {
        setPhonePayload(updatedValue)
        if (!editBtnConst) {
            setEditBtnConst({
                ...editBtnConst,
                id: phonePayload?.id
            })
        }
    }

    const onHandleUpdatePhoneNumber = useCallback((event) => {
        const newPhoneNumber = event.target.value
        if (newPhoneNumber && !validateMobileNo(newPhoneNumber)) {
            setInvalidPhoneDetail({
                phoneId: phonePayload?.id,
                isInvalidPhone: true
            })
        } else {
            setInvalidPhoneDetail(null)
        }
        onHandleChangePhoneDetail({ ...phonePayload, [event.target.name]: newPhoneNumber })
    }, [invalidPhoneDetail, phonePayload])

    return (
        <div className={"w-full flex flex-col sm:flex-row gap-5 sm:gap-3 items-center border px-3 py-5 rounded shadow-sm"}>
            <div className={"w-full sm:w-fit flex justify-between gap-3"}>
                <div className={"flex gap-3 mt-2"}>
                    <div className="flex text-lg text-text-700">
                        <MdOutlineLocalPhone />
                    </div>
                    <div className={"flex sm:hidden"}>
                        <RadioInput
                            isInputDisabled={isEditDisabled}
                            tooltipContent={phonePayload?.is_primary ? "Default" : "Make Default"}
                            isInputChecked={phonePayload?.is_primary}
                            onHandleClickInput={(event) => onHandlePrimaryPhoneDetail(phonePayload?.id, {
                                ...phonePayload,
                                is_primary: event?.target?.checked
                            })}
                        />
                    </div>
                </div>
                <div className={"flex sm:hidden mt-2"}>
                    <SaveAndCancelBtn
                        isLoading={isLoading}
                        isShowSaveBtn={isShowSaveBtn}
                        isShowCancelBtn={isShowCancelBtn}
                        isBtnInvisible={isEditDisabled}
                        isBtnDisable={isSimilarPhoneDetail || invalidPhoneDetail?.isInvalidPhone || !phonePayload?.phone}
                        saveBtnTooltip={'Save Phone'}
                        cancelBtnTooltip={"Remove Phone"}
                        onHandleSaveBtn={() => onHandleSavePhoneDetail(phonePayload)}
                        onHandleCancelBtn={() => onHandleDeletePhoneDetail(phonePayload?.id)}
                    />
                </div>
            </div>
            <div className={"hidden sm:flex mt-2.5"}>
                <RadioInput
                    isInputDisabled={isEditDisabled}
                    tooltipContent={phonePayload?.is_primary ? "Default" : "Make Default"}
                    isInputChecked={phonePayload?.is_primary}
                    onHandleClickInput={(event) => onHandlePrimaryPhoneDetail(phonePayload?.id, {
                        ...phonePayload,
                        is_primary: event?.target?.checked
                    })}
                />
            </div>
            <div className={"w-full sm:w-[16rem]"}>
                <FloatingLabelSelect
                    labelItem={`phone_country${index}${phonePayload.id}`}
                    searchable={true}
                    options={countryList?.countryList?.map((country) => ({
                        label: `${country?.country} (+${country?.isdCode})`,
                        value: country?.masterCountryId,
                        country: country
                    })) || []}
                    onHandleSelect={(selectedOption) => onHandleChangePhoneDetail({
                        ...phonePayload,
                        country: {
                            masterCountryId: selectedOption?.value,
                            country: selectedOption?.label,
                            countryDomain: selectedOption?.country?.countryDomain,
                            isdCode: selectedOption?.country?.isdCode
                        }
                    })}
                    value={
                        <div className={"flex items-center gap-1"}>
                            <img
                                src={`https://flagcdn.com/16x12/${phonePayload?.country?.countryDomain}.png`.toLowerCase()}
                                className={"w-8 h-5 object-cover"}
                            />
                            <span className={"text-sm"}>{` (+${phonePayload?.country?.isdCode})`}</span>
                        </div>
                    }
                    OptionChild={({ option }) => (
                        <div className="bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between">
                            <div className="flex items-center gap-3">
                                <img
                                    src={`https://flagcdn.com/16x12/${option?.country?.countryDomain}.png`.toLowerCase()}
                                    className={"w-10 h-6 object-cover"}
                                />
                                {option?.label}
                            </div>
                            {(option?.value === phonePayload?.country?.masterCountryId)
                                && <IoIosCheckmark size={20} color="green" />
                            }
                        </div>
                    )}
                    label={"Country"}
                    isDisabled={isEditDisabled}
                    dropdownWrapperClassName={"w-full sm:w-[25.4rem]"}
                    dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                    customBtnStyle={isEditDisabled ? "bg-text-200 hover:!border-divider-lightDark focus-within:!ring-1 focus-within:!ring-divider-lightDark focus-within:!border-divider-lightDark !cursor-not-allowed" : ""}
                />
            </div>
            <FloatingLabelInput
                labelItem={`phone_phone${index}${phonePayload.id}`}
                onChange={onHandleUpdatePhoneNumber}
                value={phonePayload?.phone}
                label={"Phone"}
                type={"number"}
                inputName={"phone"}
                isInvalidInput={(invalidPhoneDetail?.phoneId === phonePayload?.id) && invalidPhoneDetail?.isInvalidPhone}
                disabled={isEditDisabled}
                inputClassName={"input-number-spin-none disabled:px-2 disabled:bg-text-200 disabled:cursor-not-allowed"}
                customInputContainerStyle={isEditDisabled && "focus-within:!ring-none hover:!border-divider-lightDark !px-0.5"}
            />
            <div className={"w-full sm:w-72"}>
                <FloatingLabelSelect
                    labelItem={`phone_label${index}${phonePayload.id}`}
                    options={Object.values(crmContactLabelsEnum)}
                    onHandleSelect={(selectedOption) => onHandleChangePhoneDetail({ ...phonePayload, label: selectedOption })}
                    value={phonePayload?.label?.label}
                    OptionChild={({ option }) => (
                        <div className="bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between">
                            {option.label}
                            {(option.value === phonePayload?.label?.value) &&
                                <IoIosCheckmark size={20} color="green" />
                            }
                        </div>
                    )}
                    label={"Label"}
                    dropDownContainerClassName={"w-full"}
                    isDisabled={isEditDisabled}
                    customBtnStyle={isEditDisabled ? "bg-text-200 hover:!border-divider-lightDark focus-within:!ring-1 focus-within:!ring-divider-lightDark focus-within:!border-divider-lightDark !cursor-not-allowed" : ""}
                />
            </div>
            <div className={"hidden sm:flex mt-2"}>
                <SaveAndCancelBtn
                    isLoading={isLoading}
                    isShowSaveBtn={isShowSaveBtn}
                    isShowCancelBtn={isShowCancelBtn}
                    isBtnInvisible={isEditDisabled}
                    isBtnDisable={isSimilarPhoneDetail || invalidPhoneDetail?.isInvalidPhone || !phonePayload?.phone}
                    saveBtnTooltip={'Save Phone'}
                    cancelBtnTooltip={"Remove Phone"}
                    onHandleSaveBtn={() => onHandleSavePhoneDetail(phonePayload)}
                    onHandleCancelBtn={() => onHandleDeletePhoneDetail(phonePayload?.id)}
                />
            </div>
        </div>
    )
}

export default ContactPhoneItem;