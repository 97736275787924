import { Link } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { FiEdit } from "react-icons/fi";
import { MdOutlineDeleteOutline } from 'react-icons/md';

import { companyEditBtn } from "pages/auth/editCompany/data";

import { deleteEmployeeByEmployeeId } from "redux/company/company.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setEmployeeDetailPayload } from 'redux/company/company.slice';
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

const EditPeopleCard = ({ employee, editBtnConst }) => {
    const { modal } = useAppState((state) => state.local)

    const dispatcher = useAppDispatcher()

    const handleEditPeopleDetail = () => {
        dispatcher(setEmployeeDetailPayload(employee))
        dispatcher(setModal({
            ...modal,
            [modalConst.COMPANY_PEOPLE_MODAL.stateKey]: true
        }))
    }

    const handleDeletePeople = () => {
        dispatcher(deleteEmployeeByEmployeeId(employee?.employeeId))
    }

    return (
        <div className='relative w-52 h-[20rem] flex flex-col items-center justify-center gap-3 shadow-all rounded-md p-3'>
            <Link
                to={`/employee/${employee?.employeeId}`}
                className={"w-36 h-36 overflow-hidden rounded-full"}
            >
                <img
                    src={employee?.profilePicUrl}
                    alt={"employee-picture"}
                    className='w-full h-full object-cover'
                />
            </Link>
            <div className='flex flex-col justify-center items-center gap-3 overflow-hidden'>
                <div className='flex flex-col justify-center items-center gap-0.5'>
                    <Link
                        to={`/employee/${employee?.employeeId}`}
                        className={cn(
                            'text-text-900 font-bodyPri font-medium text-lg tracking-wide truncate',
                            "hover:text-secondary-main hover:underline decoration-1"
                        )}>
                        {employee?.firstName + " " + employee?.lastName?.charAt(0) + "."}
                    </Link>
                    <span className={"font-bodyPri font-normal italic text-sm text-text-700 tracking-wide"}>
                        {employee?.designation}
                    </span>
                </div>
                <div className={"font-bodyPri font-normal text-sm text-text-800"}>
                    <span className={"w-full text-center line-clamp-3"}>
                        {employee?.about}
                    </span>
                </div>
            </div>
            {editBtnConst === companyEditBtn.People.value &&
                <div className={"absolute top-2.5 right-2.5 flex items-center justify-center gap-1.5"}>
                    <FiEdit className={"text-lg text-text-500 hover:text-text-700 cursor-pointer"}
                        onClick={handleEditPeopleDetail}
                    />
                    <MdOutlineDeleteOutline className={"text-lg text-red-500 hover:text-red-700 cursor-pointer"}
                        onClick={handleDeletePeople}
                    />
                </div>
            }
        </div>
    )
}

export default EditPeopleCard