export const USER_DEFAULT_INITIAL_STATE = {
    userDefaultDetail: {
        isLoading: false,
        userDefaultDetail: null,
        errorMsg: null
    },
    addUserDefaultDetail: {
        isLoading: false,
        errorMsg: null
    }
}