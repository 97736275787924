import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FiEdit } from 'react-icons/fi';

import PageHeader from 'components/pageHeader/PageHeader';
import ToolTipView from 'components/tooltipView';
import Pagination from 'components/pagination/Pagination';

import LMSTable from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTable';

import TableFilterActions from './questionFilters/TableFilterActions';
import { searchParamsInfo, lmsQuestionTableHeaderConst, pageHeading } from './lmsQuestion.data';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { lmsQuestionTypeEnum } from "redux/edulyteLms/lmsQuestion/lmsQuestion.const";

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from "utils/dateTime.utils";
import { useTitle } from 'hooks/useTitle';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

const LMSQuestionPage = () => {
  const { lmsQuestionList } = useAppState((state) => state.lms.lmsQuestion)
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { user } = useAppState((state) => state.user)

  const navigate = useNavigate()
  const location = useLocation()
  const dispatcher = useAppDispatcher()
  const [title, setTitle] = useTitle()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.LMS_QUESTIONS_PAGE))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    if (user?.user) {
      setTitle({
        ...title,
        title: `${user?.user?.firstName}, Welcome to Edulyte LMS Questions`
      })
    }
  }, [user?.user, currentPageInfo])

  const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

  const onHandleOpenQuestion = useCallback((questionId) => {
    navigate(`${pagesInfo.EDIT_LMS_QUESTION.pagePath}/${questionId}/edit`)
  }, [])


  const onHandlePageChange = async (page) => {
    searchQueryParams.set(searchParamsInfo.page.key, page)
    navigate(`${location.pathname}?${searchQueryParams?.toString()}`);
  }

  const QuestionTitleContainer = ({ question }) => {
    return (
      <div className='flex flex-col items-start justify-start gap-1'>
        <span
          className='font-bodyPri font-normal text-text-900 text-base line-clamp-1 tracking-wide'
          title={question?.title && question?.title}
        >
          {question?.title
            ? (question?.title?.length > 30)
              ? question?.title?.slice(0, 30) + " ..."
              : question?.title
            : "-"
          }
        </span>
      </div>
    )
  }

  const tableRowsConst = lmsQuestionList?.data?.results?.map((question) => ([
    <QuestionTitleContainer question={question} />,
    lmsQuestionTypeEnum[question?.type?.toUpperCase()]?.label || "NA",
    dayjs(question?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",
    <ToolTipView content={"Edit Question"}>
      <button
        className={"w-fit flex items-center justify-center px-2 py-1 bg-text-700 text-white rounded-md text-center"}
        onClick={() => onHandleOpenQuestion(question?.id)}
      >
        <FiEdit className={"text-xl hover:scale-110"} />
      </button>
    </ToolTipView>
  ]))

  return (
    <div className={"h-full w-full p-3 mx-auto min-h-screen space-y-3"}>
      <PageHeader
        pageHeading={pageHeading}
        toolTipText={"Create and manage your own library of questions. Add, update, and reuse questions across multiple quizzes, and engage your audience effectively."}
      />
      <TableFilterActions />
      <LMSTable
        isLoading={lmsQuestionList?.isLoading}
        headers={lmsQuestionTableHeaderConst}
        rows={tableRowsConst}
        alternateRowColor={"bg-sky-100"}
        rowHeaderColor={"bg-sky-200"}
      />
      {(!lmsQuestionList?.isLoading && (lmsQuestionList?.data?.totalPages > 1)) &&
        <div className={'w-full flex items-center justify-center'}>
          <Pagination
            page={lmsQuestionList?.data?.page}
            totalPages={lmsQuestionList?.data?.totalPages}
            onChangePage={onHandlePageChange}
          />
        </div>
      }
      {(!lmsQuestionList?.isLoading && lmsQuestionList?.message) &&
        <div className={'w-full flex items-center justify-center'}>
          <span className={'font-bodyPri font-normal text-red-500 text-base text-center'}>
            {lmsQuestionList.message}
          </span>
        </div>
      }
    </div>
  )
}

export default LMSQuestionPage;