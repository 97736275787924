import { cn } from "utils/cn.utils";

import { MdVerified } from 'react-icons/md';

const NameBlueTickContent = ({
    firstName,
    lastName,
    isShowName = false,
    isShowBlueTick = false,
    containerClassName = "",
    nameClassName = "",
    blueTickContainerClassName = "",
    tooltipTextClassName = ""
}) => {
    return (
        <div className={cn("flex items-center gap-2 capitalize", containerClassName)}>
            {isShowName &&
                <p className={cn("text-xl text-text-900 font-medium font-bodyPri", nameClassName)}>
                    {firstName} {lastName?.charAt(0)?.toUpperCase() + "."}
                </p>
            }

            {isShowBlueTick &&
                <div className={cn('relative has-tooltip cursor-pointer', blueTickContainerClassName)}>
                    <MdVerified className={"text-lg text-primary-dark"} />
                    <span className={cn(
                        'tooltip w-fit px-2 py-1 bg-black text-white rounded-lg',
                        "font-bodyPri font-normal text-xs tracking-wide sm:whitespace-nowrap",
                        "-translate-x-[50%] -translate-y-[150%] md:-translate-y-[190%]", tooltipTextClassName
                    )}>
                        {"Verified Account"}
                    </span>
                </div>
            }
        </div>
    )
}

export default NameBlueTickContent