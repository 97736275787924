import { toast } from 'react-toastify';

import lmsQuizService from 'redux/edulyteLms/lmsQuiz/lmsQuiz.service';
import lmsQuestionService from '../lmsQuestion/lmsQuestion.service';
import lmsArticleService from '../lmsArticle/lmsArticle.service';
import lmsResourceService from '../lmsResource/lmsResource.service';

import {

    setLmsQuizListData,
    setLmsQuizListLoading,
    setLmsQuizListMessage,

    setLmsAiQuizDetailLoading,
    setLmsAiQuizDetailData,
    setLmsAiQuizDetailMessage,

    setLmsQuizDetailLoading,
    setLmsQuizDetailData,
    setLmsQuizDetailMessage,

    setUpdateLmsQuizData,
    setUpdateLmsQuizLoading,
    setUpdateLmsQuizMessage,

    setAddLmsQuizData,
    setAddLmsQuizLoading,
    setAddLmsQuizMessage,

    setAddLmsQuizSectionData,
    setAddLmsQuizSectionLoading,
    setAddLmsQuizSectionMessage,

    setLmsQuizSectionData,
    setLmsQuizSectionMessage,
    setLmsQuizSectionLoading,

    setLmsQuizSectionListData,
    setLmsQuizSectionListLoading,
    setLmsQuizSectionListMessage,

    setUpdateLmsQuizSectionData,
    setUpdateLmsQuizSectionLoading,
    setUpdateLmsQuizSectionMessage,

    setDeleteLmsQuizSectionLoading,
    setDeleteLmsQuizSectionMessage,

    setUpdateOrderLmsQuizSectionLoading,
    setUpdateOrderLmsQuizSectionMessage,

    setAddLmsQuizCategoryData,
    setAddLmsQuizCategoryLoading,
    setAddLmsQuizCategoryMessage,

    setLmsQuizCategoryData,
    setLmsQuizCategoryLoading,
    setLmsQuizCategoryMessage,

    setLmsQuizCategoryListData,
    setLmsQuizCategoryListMessage,
    setLmsQuizCategoryListLoading,

    setUpdateLmsQuizCategoryData,
    setUpdateLmsQuizCategoryLoading,
    setUpdateLmsQuizCategoryMessage,

    setDeleteLmsQuizCategoryLoading,
    setDeleteLmsQuizCategoryMessage,

    setAddLmsQuizTagDetailLoading,
    setAddLmsQuizTagDetailMessage,

    setModifyLmsQuizTagListOrderLoading,
    setModifyLmsQuizTagListOrderMessage,

    setDestroyLmsQuizTagDetailLoading,
    setDestroyLmsQuizTagDetailMessage,

    setAddLmsQuizSettingData,
    setAddLmsQuizSettingLoading,
    setAddLmsQuizSettingMessage,

    setDeleteLmsQuizSettingLoading,
    setDeleteLmsQuizSettingMessage,

    setLmsQuizSettingData,
    setLmsQuizSettingMessage,
    setLmsQuizSettingLoading,

    setLmsQuizSettingListData,
    setLmsQuizSettingListLoading,
    setLmsQuizSettingListMessage,

    setUpdateLmsQuizSettingData,
    setUpdateLmsQuizSettingLoading,
    setUpdateLmsQuizSettingMessage,

    setLmsQuizUserData,
    setLmsQuizUserLoading,
    setLmsQuizUserMessage,

    setLmsQuizUserListData,
    setLmsQuizUserListLoading,
    setLmsQuizUserListMessage,

    setUpdateLmsQuizUserData,
    setUpdateLmsQuizUserMessage,
    setUpdateLmsQuizUserLoading,

    setDeleteLmsQuizUserLoading,
    setDeleteLmsQuizUserMessage,

    setAddLmsQuizUserData,
    setAddLmsQuizUserLoading,
    setAddLmsQuizUserMessage,

    setAddLmsQuizResponseData,
    setAddLmsQuizResponseLoading,
    setAddLmsQuizResponseMessage,

    setLmsQuizResponseData,
    setLmsQuizResponseLoading,
    setLmsQuizResponseMessage,

    setLmsQuizResponseListData,
    setLmsQuizResponseListMessage,
    setLmsQuizResponseListLoading,

    setUpdateLmsQuizResponseData,
    setUpdateLmsQuizResponseLoading,
    setUpdateLmsQuizResponseMessage,

    setDeleteLmsQuizResponseLoading,
    setDeleteLmsQuizResponseMessage,

    setAddLmsQuizQuestionResponseData,
    setAddLmsQuizQuestionResponseLoading,
    setAddLmsQuizQuestionResponseMessage,

    setLmsQuizQuestionResponseListData,
    setLmsQuizQuestionResponseListMessage,
    setLmsQuizQuestionResponseListLoading,

    setLmsQuizQuestionResponseData,
    setLmsQuizQuestionResponseLoading,
    setLmsQuizQuestionResponseMessage,

    setUpdateLmsQuizQuestionResponseLoading,
    setUpdateLmsQuizQuestionResponseData,
    setUpdateLmsQuizQuestionResponseMessage,

    setDeleteLmsQuizQuestionResponseLoading,
    setDeleteLmsQuizQuestionResponseMessage,

    setAddLmsQuizQuestionResponseResourceData,
    setAddLmsQuizQuestionResponseResourceLoading,
    setAddLmsQuizQuestionResponseResourceMessage,

    setLmsQuizQuestionResponseResourceData,
    setLmsQuizQuestionResponseResourceLoading,
    setLmsQuizQuestionResponseResourceMessage,

    setLmsQuizQuestionResponseResourceListLoading,
    setLmsQuizQuestionResponseResourceListData,
    setLmsQuizQuestionResponseResourceListMessage,

    setUpdateLmsQuizQuestionResponseResourceData,
    setUpdateLmsQuizQuestionResponseResourceMessage,
    setUpdateLmsQuizQuestionResponseResourceLoading,

    setDeleteLmsQuizQuestionResponseResourceLoading,
    setDeleteLmsQuizQuestionResponseResourceMessage,

    setAddLmsQuizSlugDetailLoading,
    setAddLmsQuizSlugDetailMessage,

    setDestroyLmsQuizSlugDetailLoading,
    setDestroyLmsQuizSlugDetailMessage,

    setAddLmsQuizInstructorDetailLoading,
    setAddLmsQuizInstructorDetailData,
    setAddLmsQuizInstructorDetailMessage,

    setModifyLmsQuizInstructorDetailLoading,
    setModifyLmsQuizInstructorDetailData,
    setModifyLmsQuizInstructorDetailMessage,

    setDestroyLmsQuizInstructorDetailLoading,
    setDestroyLmsQuizInstructorDetailMessage,

    // student lms quiz list
    setStudentLmsQuizListLoading,
    setStudentLmsQuizListData,
    setStudentLmsQuizListMessage,

    // student lms quiz detail
    setStudentLmsQuizDetailLoading,
    setStudentLmsQuizDetailData,
    setStudentLmsQuizDetailMessage,


    // add lms quiz student quiz response
    setAddLmsQuizStudentQuizResponseLoading,
    setAddLmsQuizStudentQuizResponseData,
    setAddLmsQuizStudentQuizResponseMessage,

    // modify lms quiz student quiz response
    setModifyLmsQuizStudentQuizResponseLoading,
    setModifyLmsQuizStudentQuizResponseMessage,

    setLmsQuizStudentListLoading,
    setLmsQuizStudentListData,
    setLmsQuizStudentListMessage,

    setAddLmsQuizStudentDetailLoading,
    setAddLmsQuizStudentDetailMessage,

    setModifyLmsQuizStudentDetailLoading,
    setModifyLmsQuizStudentDetailMessage,

    setDestroyLmsQuizStudentDetailLoading,
    setDestroyLmsQuizStudentDetailMessage,

    setLmsQuizInvitationListLoading,
    setLmsQuizInvitationListData,
    setLmsQuizInvitationListMessage,

    setSendLmsQuizInvitationDetailLoading,
    setSendLmsQuizInvitationDetailData,
    setSendLmsQuizInvitationDetailMessage,

    setValidateLmsQuizInvitationDetailData,
    setValidateLmsQuizInvitationDetailLoading,
    setValidateLmsQuizInvitationDetailMessage,
    
    setAcceptAndDeclineLmsQuizInvitationDetailLoading,
    setAcceptAndDeclineLmsQuizInvitationDetailMessage,

    setDestroyLmsQuizInvitationDetailLoading,
    setDestroyLmsQuizInvitationDetailMessage,

    setLmsQuizPageQuestionListLoading,
    setLmsQuizPageQuestionListData,
    setLmsQuizPageQuestionListMessage,

    setLmsQuizPageArticleListLoading,
    setLmsQuizPageArticleListData,
    setLmsQuizPageArticleListMessage,

    setLmsQuizPageVideoResourceListLoading,
    setLmsQuizPageVideoResourceListData,
    setLmsQuizPageVideoResourceListMessage,

    setLmsQuizPageImageResourceListLoading,
    setLmsQuizPageImageResourceListData,
    setLmsQuizPageImageResourceListMessage,
} from 'redux/edulyteLms/lmsQuiz/lmsQuiz.slice';
import { setLmsCourseDetailData, setStudentLmsCourseDetailData } from '../lmsCourse/lmsCourse.slice';

export const getLmsQuizList = (query) => async (dispatch) => {
    dispatch(setLmsQuizListLoading(true))
    try {
        const requestData = {
            query: query
        }

        const response = await lmsQuizService.getLmsQuizList(requestData)

        if (response.status === 200) {
            dispatch(setLmsQuizListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        // toast.error(error?.response?.data?.message || error?.response?.data?.error)
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setLmsQuizListMessage(error?.response?.data?.message || error?.response?.data?.error))
    } finally {
        dispatch(setLmsQuizListLoading(false))
    }
}


export const getLmsQuizDetail = (quizId) => async (dispatch) => {
    dispatch(setLmsQuizDetailLoading(true))

    try {
        const requestData = {
            params: {
                quizId: String(quizId)
            }
        }

        const response = await lmsQuizService.getLmsQuizDetail(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuizDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error)
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setLmsQuizDetailMessage(error?.response?.data?.message || error?.response?.data?.error))
    } finally {
        dispatch(setLmsQuizDetailLoading(false))
    }
}


export const updateLmsQuizDetail = (quizId, body = {}, payload = { isShowToast: true }) => async (dispatch, getState) => {
    dispatch(setUpdateLmsQuizLoading(true))

    try {
        const { lmsCourseDetail } = getState().lms.lmsCourse
        const requestData = {
            params: { quizId: quizId },
            body: body
        }
        const response = await lmsQuizService.updateLmsQuizDetail(requestData)
        if (response.status === 200) {
            dispatch(setUpdateLmsQuizData(response.data.data))
            dispatch(setLmsQuizDetailData(response.data.data))
            if (payload?.sectionId && payload?.sectionLessonId) {
                dispatch(setLmsCourseDetailData({
                    ...lmsCourseDetail?.data,
                    course_sections: lmsCourseDetail?.data?.course_sections?.map((courseSection) => (
                        (courseSection?.id === payload?.sectionId)
                            ? {
                                ...courseSection, lms_course_section_lessons: courseSection?.lms_course_section_lessons?.map((courseSectionLesson) => (
                                    (courseSectionLesson?.id === payload?.sectionLessonId)
                                        ? { ...courseSectionLesson, quiz: { ...courseSectionLesson?.quiz, quiz_setting: { ...courseSectionLesson?.quiz?.quiz_setting, visibility: response.data.data?.quiz_setting?.visibility } } }
                                        : courseSectionLesson
                                ))
                            }
                            : courseSection
                    ))
                }))
            }
            if (payload?.isShowToast) {
                toast.success(response.data.message)
            }
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error)
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setUpdateLmsQuizMessage(error?.response?.data?.message || error?.response?.data?.error))
    } finally {
        dispatch(setUpdateLmsQuizLoading(false))
    }
}

export const addLmsQuizDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsQuizLoading(true))
    try {
        const requestData = {
            body: body
        }

        const response = await lmsQuizService.addLmsQuizDetail(requestData)

        if (response.status === 201) {
            toast.success(response.data.message)
            dispatch(setAddLmsQuizData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error)
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setAddLmsQuizMessage(error?.response?.data?.message || error?.response?.data?.error))
    } finally {
        dispatch(setAddLmsQuizLoading(false))
    }
}


export const createAIlmsQuizDetail = (body) => async (dispatch) => {
    dispatch(setLmsAiQuizDetailLoading(true));
    try {

        const requestData = {
            body: body
        }

        const response = await lmsQuizService.createAIlmsQuizDetail(requestData)

        if (response.status === 201) {
            toast.success(response.data.message)
            dispatch(setLmsAiQuizDetailData(response.data.data));
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error)
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setLmsAiQuizDetailMessage(error?.response?.data?.message || error?.response?.data?.error))
    } finally {
        dispatch(setLmsAiQuizDetailLoading(false))
    }
}

/*********************** Quiz-Section ******************************/

export const createLmsQuizSectionDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsQuizSectionLoading(true))

    try {
        const { lmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            body: body
        }
        const response = await lmsQuizService.createLmsQuizSectionDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddLmsQuizSectionData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setAddLmsQuizSectionMessage(error?.response?.data?.message || error?.response?.data?.error))
    } finally {
        dispatch(setAddLmsQuizSectionLoading(false))
    }
}


export const getLmsQuizSectionDetail = (quizSectionId) => async (dispatch) => {
    dispatch(setLmsQuizSectionLoading(true))

    try {

        const requestData = {
            params: {
                quizSectionId: quizSectionId
            },
        }

        const response = await lmsQuizService.getLmsQuizSectionDetail(requestData)

        if (response.status === 200) {
            dispatch(setLmsQuizSectionData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setLmsQuizSectionMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setLmsQuizSectionLoading(false))
    }
}


export const getLmsQuizSectionList = (query) => async (dispatch) => {
    dispatch(setLmsQuizSectionListLoading(true))

    try {

        const requestData = {
            query: query
        }

        const response = await lmsQuizService.getLmsQuizSectionList(requestData)

        if (response.status === 200) {
            dispatch(setLmsQuizSectionListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        console.error(error?.response?.data?.message || error.response.daat.error || "Something went wrong")
        dispatch(setLmsQuizSectionListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setLmsQuizSectionListLoading(false))
    }
}


export const updateLmsQuizSectionDetail = (quizSectionId, body, payload = { isShowToast: true }) => async (dispatch, getState) => {
    dispatch(setUpdateLmsQuizSectionLoading(true))

    try {
        const { lmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            params: { quizSectionId: quizSectionId },
            body: body
        }

        const response = await lmsQuizService.updateLmsQuizSectionDetail(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuizDetailData({
                ...lmsQuizDetail?.data,
                quiz_sections: lmsQuizDetail?.data?.quiz_sections?.map((quizSection) => (
                    (quizSection?.id === quizSectionId) ? response.data.data : quizSection
                ))
            }))
            if (payload?.isShowToast) {
                toast.success(response.data.message)
            }
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setUpdateLmsQuizSectionMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        setUpdateLmsQuizSectionLoading(false)
    }
}


export const deleteLmsQuizSectionDetail = (quizSectionId) => async (dispatch, getState) => {
    dispatch(setDeleteLmsQuizSectionLoading(true))

    try {
        const { lmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            params: { quizSectionId: quizSectionId }
        }

        const response = await lmsQuizService.deleteLmsQuizSectionDetail(requestData)
        if (response.status === 204) {
            // dispatch(getLmsQuizDetail(lmsQuizDetail?.data?.id))
            dispatch(setLmsQuizDetailData({
                ...lmsQuizDetail?.data,
                quiz_sections: lmsQuizDetail?.data?.quiz_sections?.filter((quizSection) => quizSection?.id != quizSectionId)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setDeleteLmsQuizSectionMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setDeleteLmsQuizSectionLoading(false))
    }
}


export const updateOrderLmsQuizSectionList = (body, query) => async (dispatch, getState) => {
    dispatch(setUpdateOrderLmsQuizSectionLoading(true))

    try {
        const { lmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            body: body,
            query: query
        }

        const response = await lmsQuizService.updateOrderLmsQuizSectionList(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuizDetailData({
                ...lmsQuizDetail?.data,
                quiz_sections: response.data.data
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setUpdateOrderLmsQuizSectionMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setUpdateOrderLmsQuizSectionLoading(false))
    }
}


/******************************* Quiz-Category ******************************/

export const createLmsQuizCategoryDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsQuizCategoryLoading(true))

    try {
        const { lmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            body: body
        }
        const response = await lmsQuizService.createLmsQuizCategoryDetail(requestData)
        if (response.status === 201) {
            dispatch(setLmsQuizDetailData({
                ...lmsQuizDetail?.data,
                quiz_sections: lmsQuizDetail?.data?.quiz_sections?.map((quizSection) => (
                    (quizSection?.id === body?.quiz_section_id)
                        ? { ...quizSection, quiz_categories: [...quizSection?.quiz_categories, response.data.data] }
                        : quizSection
                ))
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setAddLmsQuizCategoryMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setAddLmsQuizCategoryLoading(false))
    }
}


export const getLmsQuizCategoryDetail = (categoryId) => async (dispatch) => {
    dispatch(setLmsQuizCategoryLoading(true))

    try {
        const requestData = {
            params: {
                categoryId: categoryId
            }
        }

        const response = await lmsQuizService.getLmsQuizCategoryDetail(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuizCategoryData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setLmsQuizCategoryMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setLmsQuizCategoryLoading(false))
    }
}


export const getLmsQuizCategoryList = (query) => async (dispatch) => {
    dispatch(setLmsQuizCategoryListLoading(true))

    try {
        const requestData = {
            query: query
        }

        const response = await lmsQuizService.getLmsQuizCategoryList(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuizCategoryListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.success(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setLmsQuizCategoryListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setLmsQuizCategoryListLoading(false))
    }
}

export const updateLmsQuizCategoryOrderList = (body, query) => async (dispatch, getState) => {
    dispatch(setUpdateLmsQuizCategoryLoading(true))

    try {
        const { lmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            body: body,
            query: query
        }

        const response = await lmsQuizService.updateLmsQuizCategoryOrderList(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuizDetailData({
                ...lmsQuizDetail?.data,
                quiz_sections: lmsQuizDetail?.data?.quiz_sections?.map((quizSection) => (
                    (quizSection?.id === query?.id)
                        ? { ...quizSection, quiz_categories: response.data.data }
                        : quizSection
                ))
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setUpdateLmsQuizCategoryMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setUpdateLmsQuizCategoryLoading(false))
    }
}

export const updateLmsQuizCategoryDetail = (categoryId, body) => async (dispatch) => {
    dispatch(setUpdateLmsQuizCategoryLoading(true))

    try {
        const requestData = {
            params: {
                categoryId: categoryId
            },
            body: body
        }

        const response = await lmsQuizService.updateLmsQuizCategoryDetail(requestData)
        if (response.status === 200) {
            dispatch(setUpdateLmsQuizCategoryData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setUpdateLmsQuizCategoryMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setUpdateLmsQuizCategoryLoading(false))
    }
}


export const deleteLmsQuizCategoryDetail = (categoryId, quizSectionId) => async (dispatch, getState) => {
    dispatch(setDeleteLmsQuizCategoryLoading(true))

    try {
        const { lmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            params: { categoryId: categoryId }
        }

        const response = await lmsQuizService.deleteLmsQuizCategoryDetail(requestData)
        if (response.status === 204) {
            if (quizSectionId) {
                dispatch(setLmsQuizDetailData({
                    ...lmsQuizDetail?.data,
                    quiz_sections: lmsQuizDetail?.data?.quiz_sections?.map((quizSection) => (
                        (quizSection?.id === quizSectionId)
                            ? {
                                ...quizSection, quiz_categories: quizSection?.quiz_categories?.filter((quizCategory) => (
                                    (quizCategory?.id != categoryId)
                                ))
                            }
                            : quizSection
                    ))
                }))
            }
            toast.success(response.data.message || 'Deleted Successfully')
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setDeleteLmsQuizCategoryMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setDeleteLmsQuizCategoryLoading(false))
    }
}

// quiz tag request
// create lms course tag detail
export const createLmsQuizTagDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsQuizTagDetailLoading(true))

    try {
        const { lmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            body: body
        }
        const response = await lmsQuizService.createLmsQuizTagDetail(requestData)
        if (response.status === 201) {
            dispatch(setLmsQuizDetailData({
                ...lmsQuizDetail?.data,
                tags: [...lmsQuizDetail?.data?.tags, response.data.data]
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsQuizTagDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsQuizTagDetailLoading(false))
    }
}

// update lms course tag list order
export const updateLmsQuizTagListOrder = (body, query) => async (dispatch, getState) => {
    dispatch(setModifyLmsQuizTagListOrderLoading(true))

    try {
        const { lmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            body: body,
            query: query
        }
        const response = await lmsQuizService.updateLmsQuizTagListOrder(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuizDetailData({
                ...lmsQuizDetail?.data,
                tags: response.data.data
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyLmsQuizTagListOrderMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyLmsQuizTagListOrderLoading(false))
    }
}

// delete lms course tag detail
export const deleteLmsQuizTagDetail = (quizTagId) => async (dispatch, getState) => {
    dispatch(setDestroyLmsQuizTagDetailLoading(true))

    try {
        const { lmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            params: { quizTagId: quizTagId }
        }
        const response = await lmsQuizService.deleteLmsQuizTagDetail(requestData)
        if (response.status === 204) {
            dispatch(setLmsQuizDetailData({
                ...lmsQuizDetail?.data,
                tags: lmsQuizDetail?.data?.tags?.filter((tagItem) => tagItem?.id != quizTagId)
            }))
            toast.success(response.data.message || "Delete Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDestroyLmsQuizTagDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyLmsQuizTagDetailLoading(false))
    }
}

export const createLmsQuizSettingDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsQuizSettingLoading(true))

    try {

        const requestData = {
            body: body
        }

        const response = await lmsQuizService.createLmsQuizSettingDetail(requestData)

        if (response.status === 201) {
            toast.success(response.data.message)
            dispatch(setAddLmsQuizSettingData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setAddLmsQuizSettingMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {

        dispatch(setAddLmsQuizSettingLoading(false))
    }
}


export const getLmsQuizSettingDetail = (settingId) => async (dispatch) => {
    dispatch(setLmsQuizSettingLoading(true))

    try {

        const requestData = {
            params: {
                settingId: settingId
            }
        }

        const response = await lmsQuizService.getLmsQuizSettingDetail(requestData)

        if (response.status === 200) {
            dispatch(setLmsQuizSettingData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setLmsQuizSettingMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {

        dispatch(setLmsQuizSettingLoading(false))
    }
}


export const getLmsQuizSettinglist = (query) => async (dispatch) => {
    dispatch(setLmsQuizSettingListLoading(true))

    try {

        const requestData = {

            query: query
        }

        const response = await lmsQuizService.getLmsQuizSettinglist(requestData)

        if (response.status === 200) {

            dispatch(setLmsQuizSettingListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setLmsQuizSettingListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setLmsQuizSettingListLoading(false))
    }
}

export const updateLmsQuizSettingDetail = (settingId, body) => async (dispatch) => {

    dispatch(setUpdateLmsQuizSettingLoading(true))

    try {

        const requestData = {
            params: {
                settingId: settingId
            },
            body: body
        }

        const response = await lmsQuizService.updateLmsQuizSettingDetail(requestData)

        if (response.status === 200) {

            toast.success(response.data.message)
            dispatch(setUpdateLmsQuizSettingData(response.data.data))
        } else {

            throw new Error(response)
        }
    } catch (error) {

        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setUpdateLmsQuizSettingMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {

        dispatch(setUpdateLmsQuizSettingLoading(false))
    }
}


export const deleteLmsQuizSettingDetail = (settingId) => async (dispatch) => {

    dispatch(setDeleteLmsQuizSettingLoading(true))

    try {

        const requestData = {
            params: {
                settingId: settingId
            }
        }

        const response = await lmsQuizService.deleteLmsQuizSettingDetail(requestData)

        if (response.status === 204) {

            toast.success(response.data.message || "Deleted Successfully")

        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        dispatch(setDeleteLmsQuizSettingMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {

        dispatch(setDeleteLmsQuizSettingLoading(false))

    }
}


export const createLmsQuizUserDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsQuizUserLoading(true))

    try {

        const requestData = {
            body: body
        }

        const response = await lmsQuizService.createLmsQuizUserDetail(requestData)

        if (response.status === 201) {
            toast.success(response.data.message)
            dispatch(setAddLmsQuizUserData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setAddLmsQuizUserMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {
        dispatch(setAddLmsQuizUserLoading(false))
    }
}


export const getLmsQuizUserList = (query) => async (dispatch) => {
    dispatch(setLmsQuizUserListLoading(true))
    try {
        const requestData = {
            query: query
        }

        const response = await lmsQuizService.getLmsQuizUserList(requestData)

        if (response.status === 200) {
            dispatch(setLmsQuizUserListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setLmsQuizUserListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {

        dispatch(setLmsQuizUserListLoading(false))

    }
}


export const getLmsQuizUserDetail = (quizUserId) => async (dispatch) => {
    dispatch(setLmsQuizUserLoading(true))

    try {

        const requestData = {
            params: {
                quizUserId: quizUserId
            }
        }

        const response = await lmsQuizService.getLmsQuizUserDetail(requestData)

        if (response.status === 200) {

            dispatch(setLmsQuizUserData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setLmsQuizUserMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {
        dispatch(setLmsQuizUserLoading(false))
    }
}


export const updateLmsQuizUserDetail = (quizUserId, body) => async (dispatch) => {

    dispatch(setUpdateLmsQuizUserLoading(true))

    try {

        const requestData = {
            params: {
                quizUserId: quizUserId
            },
            body: body
        }

        const response = await lmsQuizService.updateLmsQuizUserDetail(requestData)

        if (response.status === 200) {
            toast.success(response.data.message)
            dispatch(setUpdateLmsQuizUserData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setUpdateLmsQuizUserMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {

        dispatch(setUpdateLmsQuizUserLoading(false))
    }
}


export const deleteLmsQuizUserDetail = (quizUserId) => async (dispatch) => {
    dispatch(setDeleteLmsQuizUserLoading(true))

    try {

        const requestData = {
            params: {
                quizUserId: quizUserId
            }
        }
        const response = await lmsQuizService.deleteLmsQuizUserDetail(requestData)

        if (response.status === 204) {

            toast.success(response.data.message || 'Deleted Successfully')
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setDeleteLmsQuizUserMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {

        dispatch(setDeleteLmsQuizUserLoading(false))
    }
}


export const createLmsQuizResponseDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsQuizResponseLoading(true))

    try {

        const requestData = {
            body: body
        }

        const response = await lmsQuizService.createLmsQuizResponseDetail(requestData)

        if (response.status === 201) {
            toast.success(response.data.data)
            dispatch(setAddLmsQuizResponseData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setAddLmsQuizResponseMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {

        dispatch(setAddLmsQuizResponseLoading(false))

    }
}


export const getLmsQuizResponseList = (query) => async (dispatch) => {
    dispatch(setLmsQuizResponseListLoading(true))

    try {

        const requestData = {
            query: query
        }

        const response = await lmsQuizService.getLmsQuizResponseList(requestData)

        if (response.status === 200) {

            dispatch(setLmsQuizResponseListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setLmsQuizResponseListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {
        dispatch(setLmsQuizResponseListLoading(false))
    }
}


export const getLmsQuizResponseDetail = (quizResponseId) => async (dispatch) => {
    dispatch(setLmsQuizResponseLoading(true))

    try {

        const requestData = {
            params: {
                quizResponseId: quizResponseId
            }
        }

        const response = await lmsQuizService.getLmsQuizResponseDetail(requestData)

        if (response.status === 200) {

            dispatch(setLmsQuizResponseData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setLmsQuizResponseMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {

        dispatch(setLmsQuizResponseLoading(false))
    }
}


export const updateLmsQuizResponseDetail = (quizResponseId, body) => async (dispatch) => {
    dispatch(setUpdateLmsQuizResponseLoading(true))

    try {

        const requestData = {

            params: {
                quizResponseId: quizResponseId
            },
            body: body
        }

        const response = await lmsQuizService.updateLmsQuizResponseDetail(requestData)

        if (response.status === 200) {
            toast.success(response.data.message)
            dispatch(setLmsQuizResponseData(response.data.data))
            dispatch(setUpdateLmsQuizResponseData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.success(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setUpdateLmsQuizResponseMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {

        dispatch(setUpdateLmsQuizResponseLoading(false))
    }
}


export const deleteLmsQuizResponseDetail = (quizResponseId) => async (dispatch, getState) => {
    dispatch(setDeleteLmsQuizResponseLoading(true))

    try {
        const { studentLmsCourseDetail } = getState().lms.lmsCourse
        const { studentLmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            params: {
                quizResponseId: quizResponseId
            }
        }
        const response = await lmsQuizService.deleteLmsQuizResponseDetail(requestData)
        if (response.status === 204) {
            if (!!studentLmsCourseDetail?.data) {
                const filteredList = studentLmsCourseDetail?.data?.lms_course_student?.lms_course_student_quiz_responses?.map(student_quiz_response => ({ ...student_quiz_response, quiz_response: null }))
                dispatch(setStudentLmsCourseDetailData({
                    ...studentLmsCourseDetail?.data,
                    lms_course_student: {
                        ...studentLmsCourseDetail?.data?.lms_course_student,
                        lms_course_student_quiz_responses: filteredList
                    }
                }))
            } else if (!!studentLmsQuizDetail?.data) {
                const filteredList = studentLmsQuizDetail?.data?.quiz_student?.quiz_student_quiz_responses?.map(student_quiz_response => ({ ...student_quiz_response, quiz_response: null }))
                dispatch(setStudentLmsQuizDetailData({
                    ...studentLmsQuizDetail?.data,
                    quiz_student: {
                        ...studentLmsQuizDetail?.data?.quiz_student,
                        quiz_student_quiz_responses: filteredList
                    }
                }))
            }
            dispatch(setLmsQuizResponseData(null))
            toast.success(response.data.message || "Response cleared" || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.success(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setDeleteLmsQuizResponseMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {
        dispatch(setDeleteLmsQuizResponseLoading(false))
    }
}


export const createLmsQuizQuestionResponseDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsQuizQuestionResponseLoading(true))

    try {
        const { lmsQuizResponse: { data } } = getState().lms.lmsQuiz
        const requestData = {
            body: body
        }

        const response = await lmsQuizService.createLmsQuizQuestionResponseDetail(requestData)

        if (response.status === 201) {
            dispatch(setLmsQuizResponseData({
                ...data,
                quiz_question_responses: [...data?.quiz_question_responses, response.data.data]
            }))
            dispatch(setAddLmsQuizQuestionResponseData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setAddLmsQuizQuestionResponseMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {
        dispatch(setAddLmsQuizQuestionResponseLoading(false))
    }
}

export const getLmsQuizQuestionResponseList = (query) => async (dispatch) => {
    dispatch(setLmsQuizQuestionResponseListLoading(true))

    try {

        const requestData = {
            query: query
        }

        const response = await lmsQuizService.getLmsQuizQuestionResponseList(requestData)

        if (response.status === 200) {
            dispatch(setLmsQuizQuestionResponseListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setLmsQuizQuestionResponseListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {

        dispatch(setLmsQuizQuestionResponseListLoading(false))

    }
}


export const getLmsQuizQuestionResponseDetail = (quizQuestionResponseId) => async (dispatch) => {
    dispatch(setLmsQuizQuestionResponseLoading(true))

    try {

        const requestData = {
            params: {
                quizQuestionResponseId: quizQuestionResponseId
            }
        }

        const response = lmsQuizService.getLmsQuizQuestionResponseDetail(requestData)

        if (response.status === 200) {
            dispatch(setLmsQuizQuestionResponseData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setLmsQuizQuestionResponseMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {

        dispatch(setLmsQuizQuestionResponseLoading(false))

    }
}


export const updateLmsQuizQuestionResponseDetail = (quizQuestionResponseId, body) => async (dispatch, getState) => {
    dispatch(setUpdateLmsQuizQuestionResponseLoading(true))

    try {
        const { lmsQuizResponse: { data } } = getState().lms.lmsQuiz
        const requestData = {
            params: {
                quizQuestionResponseId: quizQuestionResponseId,
            },
            body: body
        }

        const response = await lmsQuizService.updateLmsQuizQuestionResponseDetail(requestData)

        if (response.status === 200) {
            const filteredList = data?.quiz_question_responses?.filter(quiz_question_response => quiz_question_response?.id !== response?.data?.data?.id)
            dispatch(setLmsQuizResponseData({
                ...data,
                quiz_question_responses: [...filteredList, response.data.data]
            }))
            dispatch(setUpdateLmsQuizQuestionResponseData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setUpdateLmsQuizQuestionResponseMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {
        dispatch(setUpdateLmsQuizQuestionResponseLoading(false))
    }
}


export const deleteLmsQuizQuestionResponseDetail = (quizQuestionResponseId) => async (dispatch) => {
    dispatch(setDeleteLmsQuizQuestionResponseLoading(true))

    try {

        const requestData = {
            params: {
                quizQuestionResponseId: quizQuestionResponseId
            }
        }

        const response = await lmsQuizService.deleteLmsQuizQuestionResponseDetail(requestData)

        if (response.status === 204) {
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setDeleteLmsQuizQuestionResponseMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {

        dispatch(setDeleteLmsQuizQuestionResponseLoading(false))
    }
}


export const createLmsQuizQuestionResponseResourceDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsQuizQuestionResponseResourceLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await lmsQuizService.createLmsQuizQuestionResponseResourceDetail(requestData)

        if (response.status === 201) {
            toast.success(response.data.message)
            dispatch(setAddLmsQuizQuestionResponseResourceData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setAddLmsQuizQuestionResponseResourceMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {
        dispatch(setAddLmsQuizQuestionResponseResourceLoading(false))
    }
}


export const getLmsQuizQuestionResponseResourceList = (query) => async (dispatch) => {
    dispatch(setLmsQuizQuestionResponseResourceListLoading(true))

    try {

        const requestData = {

            query: query
        }

        const response = await lmsQuizService.getLmsQuizQuestionResponseResourceList(requestData)

        if (response.status === 200) {
            dispatch(setLmsQuizQuestionResponseResourceListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setLmsQuizQuestionResponseResourceListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {

        dispatch(setLmsQuizQuestionResponseResourceListLoading(false))
    }
}


export const getLmsQuizQuestionResponseResourceDetail = (quizQuestionResponseResourceId) => async (dispatch) => {
    dispatch(setLmsQuizQuestionResponseResourceLoading(true))

    try {
        const requestData = {
            params: {
                quizQuestionResponseResourceId: quizQuestionResponseResourceId
            }
        }

        const response = await lmsQuizService.getLmsQuizQuestionResponseResourceDetail(requestData)

        if (response.status === 200) {
            dispatch(setLmsQuizQuestionResponseResourceData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setLmsQuizQuestionResponseResourceMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {

        dispatch(setLmsQuizQuestionResponseResourceLoading(false))

    }
}


export const updateLmsQuizQuestionResponseResourceDetail = (quizQuestionResponseResourceId, body) => async (dispatch) => {
    dispatch(setUpdateLmsQuizQuestionResponseResourceLoading(true))

    try {

        const requestData = {
            params: {
                quizQuestionResponseResourceId: quizQuestionResponseResourceId
            },
            body: body
        }

        const response = await lmsQuizService.updateLmsQuizQuestionResponseResourceDetail(requestData)

        if (response.status === 200) {
            toast.success(response.data.message)
            dispatch(setUpdateLmsQuizQuestionResponseResourceData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setUpdateLmsQuizQuestionResponseResourceMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {

        dispatch(setUpdateLmsQuizQuestionResponseResourceLoading(false))
    }
}


export const deleteLmsQuizQuestionResponseResourceDetail = (quizQuestionResponseResourceId) => async (dispatch) => {
    dispatch(setDeleteLmsQuizQuestionResponseResourceLoading(true))

    try {

        const requestData = {
            params: {
                quizQuestionResponseResourceId: quizQuestionResponseResourceId
            }
        }

        const response = await lmsQuizService.deleteLmsQuizQuestionResponseResourceDetail(requestData)

        if (response.status === 204) {
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong.")
        dispatch(setDeleteLmsQuizQuestionResponseResourceMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong."))
    } finally {
        dispatch(setDeleteLmsQuizQuestionResponseResourceLoading(false))
    }
}

// create lms quiz slug detail
export const createLmsQuizSlugDetail = (body) => async (dispatch) => {
    dispatch(setAddLmsQuizSlugDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await lmsQuizService.createLmsQuizSlugDetail(requestData)
        if (response.status === 201) {
            dispatch(updateLmsQuizDetail(body?.quiz_id, { default_slug_id: response?.data?.data?.id }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddLmsQuizSlugDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsQuizSlugDetailLoading(false))
    }
}

// delete lms quiz slug detail
export const deleteLmsQuizSlugDetail = (quizSlugId) => async (dispatch, getState) => {
    dispatch(setDestroyLmsQuizSlugDetailLoading(true))

    try {
        const { lmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            params: { quizSlugId: quizSlugId }
        }
        const response = await lmsQuizService.deleteLmsQuizSlugDetail(requestData)
        if (response.status === 204) {
            dispatch(setLmsQuizDetailData({
                ...lmsQuizDetail?.data,
                quiz_slugs: lmsQuizDetail?.data?.quiz_slugs?.filter((quizSlug) => quizSlug?.id != quizSlugId)
            }))
            // dispatch(getLmsQuizDetail(lmsQuizDetail?.data?.id))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyLmsQuizSlugDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyLmsQuizSlugDetailLoading(false))
    }
}

// create lms quiz instructor detail
export const createLmsQuizInstructorDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsQuizInstructorDetailLoading(true))

    try {
        const { lmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            body: body
        }
        const response = await lmsQuizService.createLmsQuizInstructorDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddLmsQuizInstructorDetailData(response.data.data))
            dispatch(setLmsQuizDetailData({
                ...lmsQuizDetail?.data,
                quiz_tutors: [...lmsQuizDetail?.data?.quiz_tutors, response.data.data]
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddLmsQuizInstructorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsQuizInstructorDetailLoading(false))
    }
}

// update lms quiz instructor detail
export const updateLmsQuizInstructorDetail = (quizInstructorId, body) => async (dispatch, getState) => {
    dispatch(setModifyLmsQuizInstructorDetailLoading(true))

    try {
        const { lmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            params: { quizInstructorId: quizInstructorId },
            body: body
        }
        const response = await lmsQuizService.updateLmsQuizInstructorDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyLmsQuizInstructorDetailData(response.data.data))
            dispatch(setLmsQuizDetailData({
                ...lmsQuizDetail?.data,
                quiz_tutors: lmsQuizDetail?.data?.quiz_tutors?.map((quizTutor) => (
                    (quizTutor?.id === response?.data?.data?.id) ? response.data.data : quizTutor
                ))
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyLmsQuizInstructorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyLmsQuizInstructorDetailLoading(false))
    }
}

// delete lms quiz instructor detail
export const deleteLmsQuizInstructorDetail = (quizInstructorId) => async (dispatch, getState) => {
    dispatch(setDestroyLmsQuizInstructorDetailLoading(true))

    try {
        const { lmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            params: { quizInstructorId: quizInstructorId }
        }
        const response = await lmsQuizService.deleteLmsQuizInstructorDetail(requestData)
        if (response.status === 204) {
            dispatch(setLmsQuizDetailData({
                ...lmsQuizDetail?.data,
                quiz_tutors: lmsQuizDetail?.data?.quiz_tutors?.filter((quizTutor) => quizTutor?.id != quizInstructorId)
            }))
            toast.success(response.data.message || 'Deleted Successfully')
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyLmsQuizInstructorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyLmsQuizInstructorDetailLoading(false))
    }
}

// get student lms quiz list
export const getStudentLmsQuizList = (student_id, query = {}) => async (dispatch) => {
    dispatch(setStudentLmsQuizListLoading(true))

    try {
        const requestData = {
            params: { student_id },
            query
        }
        const response = await lmsQuizService.getStudentLmsQuizList(requestData)
        if (response.status === 200) {
            dispatch(setStudentLmsQuizListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setStudentLmsQuizListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setStudentLmsQuizListLoading(false))
    }
}

// get student lms quiz detail
export const getStudentLmsQuizDetail = (slug, student_id) => async (dispatch) => {
    dispatch(setStudentLmsQuizDetailLoading(true))

    try {
        const requestData = {
            params: { slug, student_id }
        }
        const response = await lmsQuizService.getStudentLmsQuizDetail(requestData)
        if (response.status === 200) {
            dispatch(setStudentLmsQuizDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setStudentLmsQuizDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setStudentLmsQuizDetailLoading(false))
    }
}

/* lms quiz student quiz response */
export const createLmsQuizStudentQuizResponseDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsQuizStudentQuizResponseLoading(true))
    try {
        const { studentLmsQuizDetail: { data } } = getState().lms.lmsQuiz
        const requestData = {
            body: body
        }
        const response = await lmsQuizService.createLmsQuizStudentQuizResponseDetail(requestData)
        if (response.status === 201) {
            const filteredList = data?.quiz_student?.quiz_student_quiz_responses?.filter(quiz_response => quiz_response?.id !== response.data.data.id)
            dispatch(setStudentLmsQuizDetailData({
                ...data,
                quiz_student: {
                    ...data?.quiz_student,
                    quiz_student_quiz_responses: [...filteredList, { ...response.data.data, quiz: response.data.data?.quiz, quiz_response: response.data.data?.quiz_response }]
                }
            }))
            dispatch(setAddLmsQuizStudentQuizResponseData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddLmsQuizStudentQuizResponseMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setAddLmsQuizStudentQuizResponseLoading(false))
    }
}

/* lms quiz student quiz response */
export const updateLmsQuizStudentQuizResponseDetail = (id, body) => async (dispatch, getState) => {
    dispatch(setModifyLmsQuizStudentQuizResponseLoading(true))
    try {
        const { studentLmsQuizDetail: { data } } = getState().lms.lmsQuiz
        const requestData = {
            params: { id: id },
            body: body
        }
        const response = await lmsQuizService.updateLmsQuizStudentQuizResponseDetail(requestData)
        if (response.status === 200) {
            const filteredList = data?.quiz_student?.quiz_student_quiz_responses?.filter(quiz_response => quiz_response?.id !== response.data.data.id)
            dispatch(setStudentLmsQuizDetailData({
                ...data,
                quiz_student: {
                    ...data?.quiz_student,
                    quiz_student_quiz_responses: [...filteredList, { ...response.data.data, quiz: response.data.data?.quiz, quiz_response: response.data.data?.quiz_response }]
                }
            }))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setModifyLmsQuizStudentQuizResponseMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyLmsQuizStudentQuizResponseLoading(false))
    }
}

// get lms course student list
export const getLmsQuizStudentList = (query) => async (dispatch) => {
    dispatch(setLmsQuizStudentListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await lmsQuizService.getLmsQuizStudentList(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuizStudentListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setLmsQuizStudentListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setLmsQuizStudentListLoading(false))
    }
}

// create lms quiz student detail
export const createLmsQuizStudentDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddLmsQuizStudentDetailLoading(true))

    try {
        const { lmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            body: body
        }
        const response = await lmsQuizService.createLmsQuizStudentDetail(requestData)
        if (response.status === 201) {
            dispatch(setLmsQuizDetailData({
                ...lmsQuizDetail?.data,
                students: [...lmsQuizDetail?.data?.students, response.data.data]
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddLmsQuizStudentDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddLmsQuizStudentDetailLoading(false))
    }
}

// update lms course student detail
export const updateLmsQuizStudentDetail = (quizStudentId, body) => async (dispatch, getState) => {
    dispatch(setModifyLmsQuizStudentDetailLoading(true))

    try {
        const { lmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            params: { quizStudentId: quizStudentId },
            body: body
        }
        const response = await lmsQuizService.updateLmsQuizStudentDetail(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuizDetailData({
                ...lmsQuizDetail?.data,
                students: lmsQuizDetail?.data?.students?.map((courseStudent) => (
                    courseStudent.id === response.data.data.id ? response.data.data : courseStudent
                ))
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyLmsQuizStudentDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyLmsQuizStudentDetailLoading(false))
    }
}

// delete lms course student detail
export const deleteLmsQuizStudentDetail = (quizStudentId) => async (dispatch, getState) => {
    dispatch(setDestroyLmsQuizStudentDetailLoading(true))

    try {
        const { lmsQuizDetail } = getState().lms.lmsQuiz
        const requestData = {
            params: { quizStudentId: quizStudentId }
        }
        const response = await lmsQuizService.deleteLmsQuizStudentDetail(requestData)
        if (response.status === 204) {
            dispatch(getLmsQuizDetail(lmsQuizDetail?.data?.id))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDestroyLmsQuizStudentDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyLmsQuizStudentDetailLoading(false))
    }
}

// lms quiz invitation list
export const getLmsQuizInvitationList = (query) => async (dispatch) => {
    dispatch(setLmsQuizInvitationListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await lmsQuizService.getLmsQuizInvitationList(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuizInvitationListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        dispatch(setLmsQuizInvitationListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setLmsQuizInvitationListLoading(false))
    }
}

// create send lms quiz invitation detail
export const createSendLmsQuizInvitationDetail = (body) => async (dispatch) => {
    dispatch(setSendLmsQuizInvitationDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await lmsQuizService.createSendLmsQuizInvitationDetail(requestData)
        if (response.status === 201) {
            dispatch(setSendLmsQuizInvitationDetailData(response.data.data))
            toast.success(response.data.message || "Created Successfully!")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setSendLmsQuizInvitationDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    }
    finally {
        dispatch(setSendLmsQuizInvitationDetailLoading(false))
    }
}


// Validate LmsQuiz Invitation detail

export const getValidateLmsQuizInvitationDetail = (query) => async(dispatch) => {
    dispatch(setValidateLmsQuizInvitationDetailLoading(true));

    try {
        const requestData = {
            query
        }
        const response = await lmsQuizService.getValidateLmsQuizInvitationDetail(requestData);
        if (response.status === 200) {
            dispatch(setValidateLmsQuizInvitationDetailData(response.data.data));
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.message || 'Something went wrong!')
        dispatch(setValidateLmsQuizInvitationDetailMessage(error.response.data.message || error.response.data.message || 'Something went wrong!'))
    } finally {
        dispatch(setValidateLmsQuizInvitationDetailLoading(false));
    }
}

// Accept or Decline LmsQuiz Invitation
export const getAcceptAndDeclineLmsQuizInvitationDetail = (body, query) => async( dispatch ) => {
    dispatch(setAcceptAndDeclineLmsQuizInvitationDetailLoading(true));

    try {
        const requestData = {
            body,
            query
        }
        const response = await lmsQuizService.getAcceptAndDeclineLmsQuizInvitationDetail(requestData);
        if (response.status === 200) {
            dispatch(setValidateLmsQuizInvitationDetailData(response.data.data));
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.message || 'Something went wrong!')
        dispatch(setAcceptAndDeclineLmsQuizInvitationDetailMessage(error.response.data.message || error.response.data.message || 'Something went wrong!'))
    }
    finally {
        dispatch(setAcceptAndDeclineLmsQuizInvitationDetailLoading(false));
    }
}

// delete lms quiz invitation detail
export const deleteLmsQuizInvitationDetail = (quizInvitationId, query = { page: 1, records: 5 }) => async (dispatch) => {
    dispatch(setDestroyLmsQuizInvitationDetailLoading(true))

    try {
        const requestData = {
            params: { QuizInvitationId: quizInvitationId }
        }
        const response = await lmsQuizService.deleteLmsQuizInvitationDetail(requestData)
        if (response.status === 204) {
            dispatch(getLmsQuizInvitationList(query))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.message || 'Something went wrong!')
        dispatch(setDestroyLmsQuizInvitationDetailMessage(error.response.data.message || error.response.data.message || 'Something went wrong!'))
        toast.error(error.response.data.message || error.response.data.message || 'Something went wrong!')
    }
    finally {
        dispatch(setDestroyLmsQuizInvitationDetailLoading(false))
    }
}

// page based request
export const getLmsQuizPageQuestionList = (query) => async (dispatch) => {
    dispatch(setLmsQuizPageQuestionListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await lmsQuestionService.getLmsQuestionList(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuizPageQuestionListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setLmsQuizPageQuestionListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setLmsQuizPageQuestionListLoading(false))
    }
}

export const getLmsQuizPageArticleList = (query) => async (dispatch) => {
    dispatch(setLmsQuizPageArticleListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await lmsArticleService.getLmsArticleList(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuizPageArticleListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setLmsQuizPageArticleListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setLmsQuizPageArticleListLoading(false))
    }
}

export const getLmsQuizPageVideoResourceList = (query) => async (dispatch) => {
    dispatch(setLmsQuizPageVideoResourceListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await lmsResourceService.getLmsResourceList(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuizPageVideoResourceListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setLmsQuizPageVideoResourceListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setLmsQuizPageVideoResourceListLoading(false))
    }
}

export const getLmsQuizPageImageResourceList = (query) => async (dispatch) => {
    dispatch(setLmsQuizPageImageResourceListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await lmsResourceService.getLmsResourceList(requestData)
        if (response.status === 200) {
            dispatch(setLmsQuizPageImageResourceListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setLmsQuizPageImageResourceListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setLmsQuizPageImageResourceListLoading(false))
    }
}