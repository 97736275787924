import { useEffect } from "react";
import { cn } from "utils/cn.utils";
import { Link } from "react-router-dom";

import ComponentLoader from "components/loader/ComponentLoader";
import OfferingCard from "components/offeringCard/OfferingCard"

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { getTutorCourseList, getUserCourseList } from 'redux/course/course.request';
import { setClearTutorCourseList, setClearUserCourseList } from 'redux/course/course.slice';
import { userRoles } from 'redux/auth/auth.const';

import { pagesInfo } from 'utils/pagesInfo';

export const MyOfferings: React.FC = () => {
  const { user } = useAppState((s: any) => s.user)
  const { userCourseList, tutorCourseList } = useAppState((s: any) => s.course)
  const { locals } = useAppState((s: any) => s.local)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    if (locals.userRole === userRoles.STUDENT.value) {
      dispatcher(getUserCourseList(user?.user?.userId, { page: 1, records: 5 }))
    }

    if (locals.userRole === userRoles.TUTOR.value) {
      dispatcher(getTutorCourseList(user?.user?.tutor?.tutorId, { page: 1, records: 5 }))
    }

    return () => {
      dispatcher(setClearUserCourseList())
      dispatcher(setClearTutorCourseList())
    }
  }, [locals.userRole])

  return (
    <div className="w-full p-5 bg-white shadow-md rounded-lg">
      <div className="flex justify-between">
        <p className="text-xl text-text-900 font-medium font-bodyPri tracking-wider">
          {"My Classes"}
        </p>
        <p className="text-sm text-text-700 font-bodyPri">
          {locals.userRole === userRoles.STUDENT.value &&
            <Link to={pagesInfo.MY_COURSES.pagePath}>
              {"View all"}
            </Link>
          }
          {locals.userRole === userRoles.TUTOR.value &&
            <Link to={`${pagesInfo.TUTOR_PRODUCTS.pagePath}/live-classes` + "?pageNo=1"}>
              {"View all"}
            </Link>
          }
        </p>
      </div>

      <div className={cn(
        "grid grid-flow-col gap-8 px-4 py-4 overflow-hidden overflow-x-scroll",
        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
      )}>
        <ComponentLoader isLoading={tutorCourseList?.isLoading} />
        {(locals.userRole === userRoles.STUDENT.value && userCourseList?.data?.records > 0) &&
          userCourseList?.data?.results?.map((courses: any, idx: any) => (
            <OfferingCard key={idx} offering={courses} />
          ))
        }
        {(locals.userRole === userRoles.TUTOR.value && tutorCourseList?.data?.records > 0) &&
          tutorCourseList?.data?.results?.map((offering: any, idx: any) => (
            <OfferingCard key={idx} offering={offering} />
          ))
        }
        {(userCourseList?.message || tutorCourseList?.message || userCourseList?.data?.records === 0 || tutorCourseList?.data?.records === 0) &&
          <span className="w-full font-bodyPri font-normal text-text-800 text-md text-center">
            {userCourseList?.message || tutorCourseList?.message || "No classes found!"}
          </span>
        }
      </div>
    </div>
  );
};
