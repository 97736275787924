import { useEffect, useState } from "react";
import { cn } from "utils/cn.utils";

import ButtonLoader from "components/loader/ButtonLoader";

import { createSendOtp } from "redux/notification/notification.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { otpDeliveryMethod } from "redux/notification/notification.const";
import { setAddUserAuthPayload } from "redux/auth/auth.slice";

import { validateEmail } from "utils/validation-functions";;

const NewEmailVerificationBox = ({ setIsCodeSent }) => {
    const { addUserAuth } = useAppState((state) => state.auth)
    const { user } = useAppState((state) => state.user)
    const { sendOtp } = useAppState((s) => s.notification)

    const dispatcher = useAppDispatcher()

    const [inputEmail, setInputEmail] = useState("")
    const [isInputEmailError, setIsInputEmailError] = useState({
        isAlreadyExistEmail: false,
        isInvalidEmail: false
    })

    useEffect(() => {
        setInputEmail(user?.user?.email)
    }, [])

    useEffect(() => {
        if (sendOtp?.sendOtp) {
            setIsCodeSent(true)
        }
        if (sendOtp?.errorMsg) {
            setIsCodeSent(false)
        }
    }, [sendOtp?.sendOtp])

    useEffect(() => {
        if (!validateEmail(inputEmail)) {
            setIsInputEmailError({
                isAlreadyExistEmail: false,
                isInvalidEmail: true
            })
        }
        if (inputEmail === user?.user?.email) {
            setIsInputEmailError({
                isAlreadyExistEmail: true,
                isInvalidEmail: false
            })
        }
        if (validateEmail(inputEmail) && inputEmail !== user?.user?.email) {
            setIsInputEmailError({
                isAlreadyExistEmail: false,
                isInvalidEmail: false
            })
        }
    }, [inputEmail])

    const onSendCode = () => {
        if (!inputEmail || isInputEmailError?.isAlreadyExistEmail || isInputEmailError?.isInvalidEmail) return;

        dispatcher(setAddUserAuthPayload({
            ...addUserAuth?.addUserAuthPayload,
            email: inputEmail
        }))
        dispatcher(createSendOtp({
            channel: otpDeliveryMethod?.EMAIL?.value,
            email: inputEmail
        }))
    }

    return (
        <div className={"w-full h-full flex flex-col items-center justify-center gap-8"}>
            <div className={"w-full flex flex-col justify-start items-start gap-1.5"}>
                <div className={"font-bodyPri font-medium text-text-800 text-base"}>
                    {"Email:"}
                </div>
                <div className={"w-full flex flex-col items-start justify-center gap-1"}>
                    <input
                        type={"text"}
                        className={cn(
                            "w-full px-3 py-1 rounded-md focus:outline-none",
                            "font-bodyPri font-normal text-text-800 text-base",
                            (isInputEmailError?.isInvalidEmail || isInputEmailError?.isAlreadyExistEmail)
                            && "border-2 border-red-500",
                            (!isInputEmailError?.isInvalidEmail || !isInputEmailError?.isAlreadyExistEmail)
                            && "border-2 border-divider-medium focus:border-2 focus:border-text-400 hover:border-text-400"
                        )}
                        value={inputEmail}
                        onChange={(event) => setInputEmail(event.target.value)}
                    />
                    <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                        {isInputEmailError?.isInvalidEmail &&
                            "Invalid Email!"
                        }
                    </span>
                    <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                        {isInputEmailError?.isAlreadyExistEmail &&
                            "An account already exist for this email"
                        }
                    </span>
                </div>
            </div>
            <div onClick={onSendCode}
                className={cn(
                    "w-full py-1 flex item-center justify-center rounded-md",
                    "font-buttons font-normal text-base text-text-50",
                    (isInputEmailError?.isInvalidEmail || isInputEmailError?.isAlreadyExistEmail)
                    && "bg-text-300",
                    ((!isInputEmailError?.isInvalidEmail && !isInputEmailError?.isAlreadyExistEmail) || sendOtp?.isLoading)
                    && "bg-primary-main cursor-pointer"
                )}>
                <span className={""}>
                    {sendOtp?.isLoading &&
                        <ButtonLoader isLoading={sendOtp?.isLoading} />
                    }
                    {!sendOtp?.isLoading && "Send Code"}
                </span>
            </div>
        </div>
    )
}

export default NewEmailVerificationBox