import { useState } from 'react';
import { cn } from "utils/cn.utils";

import {
    TaskItemContainer,
    TaskDetails,
    TaskTitleDesc,
    TaskTitleText,
    TaskDescText,
    TaskAction,
    Normal,
    Submitted,
    UnderReview,
    Verified,
    ArrowToggleIcon,
    TaskTime,
    VerifiedIcon,
    SubmittedIcon,
    DocumentTableContainer,
    Rejected,
    RejectedIcon,
    Pending,
    PendingIcon,
    DisabledButton
} from 'pages/auth/tutorOnboard/TutorOnboardStyle';

import DocumentsTable from 'pages/auth/tutorOnboard/components/documentVerification/components/DocumentsTable';

import { useAppState } from 'hooks/useStore';
import { documentStatus } from 'redux/onboarding/onboarding.const';

const DocumentVerification = () => {
    const { userOnboardingDetail } = useAppState((s) => s.onboarding)

    const [isOpenDocumentTable, setIsOpenDocumentTable] = useState(false)
    const [clickedItemIndex, setClickedItemIndex] = useState(null);

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const isOpen = clickedItemIndex === 1;

    const isEmailMobileVerified = userOnboardingDetail?.userOnboardingDetail?.user?.isEmailVerified && userOnboardingDetail?.userOnboardingDetail?.user?.isMobileNoVerified

    const onHandleTask = () => {
        if (isEmailMobileVerified) {
            setClickedItemIndex(isOpen ? null : 1)
            setIsOpenDocumentTable(!isOpenDocumentTable)
        }
    }

    return (
        <TaskItemContainer>
            <TaskDetails>
                <span className={cn(
                    "px-2.5 py-0.5 h-auto self-start rounded-full font-bodyPri font-medium text-lg",
                    (!isEmailMobileVerified || !userOnboardingDetail?.userOnboardingDetail?.onboardingDoc?.status) && "border-2 border-text-500 text-text-900 bg-white",
                    (isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingDoc?.status) && `text-text-50 bg-${documentStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingDoc?.status?.toUpperCase()]?.darkColor}`
                )}>
                    {"4"}
                </span>
                <TaskTitleDesc>
                    <TaskTitleText>{"Resume and Documents"}</TaskTitleText>
                    <TaskDescText>
                        {"Please upload your relevant documents to confirm your identity, qualifications and professional experiences."}
                    </TaskDescText>
                </TaskTitleDesc>
            </TaskDetails>
            <TaskAction>
                {!isEmailMobileVerified &&
                    <DisabledButton>
                        {"Upload Documents"}
                    </DisabledButton>
                }
                {(isEmailMobileVerified && (!userOnboardingDetail?.userOnboardingDetail?.onboardingDoc?.status || userOnboardingDetail?.userOnboardingDetail?.onboardingDoc?.status === documentStatus?.PENDING?.value)) &&
                    <Pending onClick={onHandleTask}>
                        <PendingIcon />
                        {documentStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingDoc?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Pending>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingDoc?.status === documentStatus?.SUBMITTED?.value) &&
                    <Submitted onClick={onHandleTask}>
                        <SubmittedIcon />
                        {documentStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingDoc?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Submitted>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingDoc?.status === documentStatus?.UNDER_REVIEW?.value) &&
                    <UnderReview onClick={onHandleTask}>
                        <SubmittedIcon />
                        {documentStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingDoc?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </UnderReview>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingDoc?.status === documentStatus?.APPROVED?.value) &&
                    <Verified onClick={onHandleTask}>
                        <VerifiedIcon />
                        {documentStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingDoc?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Verified>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.onboardingDoc?.status === documentStatus?.REJECTED?.value) &&
                    <Rejected onClick={onHandleTask}>
                        <RejectedIcon />
                        {documentStatus[userOnboardingDetail?.userOnboardingDetail?.onboardingDoc?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Rejected>
                }
                <TaskTime>{"5 mins"}</TaskTime>
            </TaskAction>
            {isOpenDocumentTable &&
                <DocumentTableContainer>
                    <DocumentsTable />
                </DocumentTableContainer>
            }
        </TaskItemContainer>
    )
}

export default DocumentVerification;