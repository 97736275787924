import { cn } from "utils/cn.utils";

import { FaInfoCircle } from "react-icons/fa";

import { titleToolTip, subHeaderToolTip, editBtnConst } from "pages/auth/editCourse/data";
import { InlineTextEditor } from "pages/auth/editCourse/Components/InlineTextEditor";

import { setOfferingCourse } from "redux/offering/offering.slice";

import { useAppDispatcher, useAppState } from "hooks/useStore";

const TitleEditor = ({ editBtn, setEditBtn }: any) => {
    const { offeringCourse } = useAppState((s) => s.offering)

    const dispatcher = useAppDispatcher()

    return (
        <div className={cn("space-y-2")}>
            <div
                className={cn(
                    "px-1.5 md:p-px flex items-center justify-start gap-1",
                    editBtn !== editBtnConst.title.value && "border border-secondary-dark border-dashed",
                    editBtn === editBtnConst.title.value && "border-2 border-secondary-main border-solid"
                )}
            >
                <div className={"relative has-tooltip cursor-pointer flex items-center justify-center"}>
                    <FaInfoCircle className="absolute -top-8.5 right-2 inline text-md text-text-500 hover:text-text-700" />
                    <span
                        className={cn(
                            "w-56 px-2 py-0.5 font-bodyPri font-normal text-xs rounded shadow-lg bg-text-900 text-text-50 tooltip top-0 left-0",
                            "flex flex-col items-start justify-start overflow-hidden"
                        )}
                    >
                        {titleToolTip}
                    </span>
                </div>
                <InlineTextEditor
                    onChange={(text) => {
                        dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, courseTitle: text }));
                    }}
                    rows={2}
                    textClassName="text-3xl font-bold text-white"
                    value={offeringCourse?.offeringCourse?.courseTitle}
                    editBtn={editBtn}
                    setEditBtn={setEditBtn}
                    title={"title"}
                />
            </div>
            <div
                className={cn(
                    "px-1.5 md:p-px flex items-center justify-start gap-1",
                    editBtn !== editBtnConst.titleDescription.value && "border border-secondary-dark border-dashed",
                    editBtn === editBtnConst.titleDescription.value && "border-2 border-secondary-main border-solid"
                )}
            >
                <div className={"relative has-tooltip cursor-pointer flex items-center justify-center"}>
                    <FaInfoCircle className={"absolute -top-8.5 right-2 inline text-md text-text-500 hover:text-text-700"} />
                    <span
                        className={cn(
                            "w-56 px-2 py-0.5 font-bodyPri font-normal text-xs rounded shadow-lg bg-text-900 text-text-50 tooltip top-0 left-0",
                            "flex flex-col items-start justify-start overflow-hidden"
                        )}
                    >
                        {subHeaderToolTip}
                    </span>
                </div>
                <InlineTextEditor
                    onChange={(t) => {
                        dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, courseTitleDescription: t }));
                    }}
                    rows={3}
                    textClassName={"text-base font-light text-white"}
                    value={offeringCourse?.offeringCourse?.courseTitleDescription}
                    editBtn={editBtn}
                    setEditBtn={setEditBtn}
                    titleDescription={"titleDescription"}
                />
            </div>
        </div>
    );
}

export default TitleEditor;