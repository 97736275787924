import { useState, useEffect } from 'react';
import { cn } from "utils/cn.utils";

import { BsCheckLg } from "react-icons/bs";

import ComponentLoader from 'components/loader/ComponentLoader';

const TutorIsFromFilter = ({
    options,
    selectedTutorFromList,
    isLoading = false,
    isShowList = false,
    isShowInputBox = false,
    isShowCheckBox = false,
    multipleOptions = false,
    className,
    onHandleSelectTutorFrom,
    onClearFilter
}) => {

    const [query, setQuery] = useState('')

    const handleSelectOption = (option) => {
        const selected = options?.find((o) => o.value === option.value)
        if (!selected?.value) {
            alert("Please select any!")
            return;
        }

        if (multipleOptions === false) {
            onHandleSelectTutorFrom([selected]);
            return;
        }
        const filteredList = selectedTutorFromList?.filter((option) => option?.value === selected?.value)
        if (filteredList?.length > 0) {
            const filteredOptions = selectedTutorFromList?.filter((option) => option?.value !== selected?.value)
            onHandleSelectTutorFrom(filteredOptions);
            return;
        }
        if (filteredList?.length === 0) {
            onHandleSelectTutorFrom([...selectedTutorFromList, selected]);
        }
    }

    const filteredList =
        query === ''
            ? options
            : options.filter((speak) => {
                return speak.value.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
            })

    return (
        <div className={cn("bg-white w-full max-h-80 rounded-lg px-3 py-1.5")}>
            <div className={"space-y-3"}>
                <div className={"w-full flex justify-between items-center gap-3"}>
                    <div className={"flex items-center justify-start gap-2"}>
                        <span className={"font-bodyPri font-medium text-text-900 text-base"}>
                            {"Tutor Is From"}
                        </span>
                        {(selectedTutorFromList?.length > 0) &&
                            <span className={"w-5 h-5 flex items-center justify-center bg-primary-dark rounded-full text-text-50 text-sm"}>
                                {selectedTutorFromList?.length}
                            </span>
                        }
                    </div>
                    <div
                        className={"font-bodyPri font-medium text-primary-dark text-sm underline cursor-pointer"}
                        onClick={() => onClearFilter()}>
                        {"Clear"}
                    </div>
                </div>
                {isShowInputBox &&
                    <input
                        className={"w-full border border-text-300 rounded-md cursor-text py-1.5 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:outline-none"}
                        onChange={(event) => setQuery(event.target.value)}
                        autoFocus={true}
                        placeholder={"Type"}
                    />
                }
            </div>
            {isLoading &&
                <ComponentLoader isLoading={isLoading} />
            }
            {filteredList &&
                <div className={cn(
                    "h-[70vh] px-1.5 py-3 overflow-hidden overflow-y-scroll",
                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                )}>
                    <div className={""}>
                        {filteredList.length === 0 && query !== '' ? (
                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                {"Nothing found."}
                            </div>
                        ) : (
                            <div className={"space-y-5"}>
                                {filteredList?.map((item, index) => (
                                    <div
                                        key={index}
                                        className={"w-full flex items-center justify-start gap-5 group cursor-pointer"}
                                        onClick={() => handleSelectOption(item)}
                                    >
                                        {isShowCheckBox &&
                                            <span className={cn(
                                                'relative w-5 h-5 border border-text-300 rounded',
                                            )}
                                            >
                                                {(selectedTutorFromList?.map((option) => option?.value)?.includes(item?.value)) &&
                                                    <span className={"absolute top-0.5 right-0"}>
                                                        <BsCheckLg className={"text-base text-primary-dark"} />
                                                    </span>
                                                }
                                            </span>
                                        }
                                        <span className={'w-11/12 font-bodyPri font-normal text-text-900 text-sm tracking-wide truncate'}>
                                            {item.label}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            }

        </div>
    )
}

export default TutorIsFromFilter;