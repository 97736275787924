import { memo } from "react";

import { MdOutlineArrowForwardIos, MdOutlineVideoCall } from 'react-icons/md';

import { searchParamsInfo } from "pages/auth/editAppointment/data";

const SessionDetail = memo(({ onHandleActiveSection }) => {

    return (
        <div
            className={"w-full p-5 h-32 hover:bg-blue-50 cursor-pointer font-bodyPri"}
            onClick={() => onHandleActiveSection(searchParamsInfo.action.key, searchParamsInfo.sessions.key)}
        >
            <div className={"w-full flex items-center justify-between gap-3"}>
                <div className={"flex items-start justify-start gap-3"}>
                    <MdOutlineVideoCall className={"text-2xl text-text-600"} />
                    <div className={"flex flex-col gap-3"}>
                        <span className={"font-medium text-text-900 text-md"}>
                            {"Booked Sessions"}
                        </span>
                    </div>
                </div>
                <MdOutlineArrowForwardIos className={"text-3xl text-text-600"} />
            </div>
        </div>
    )
})

export default SessionDetail;