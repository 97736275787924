import { walletTxnActions, txnType } from 'redux/wallet/wallet.const';

import { dayjs, timeZone } from "utils/dateTime.utils";

const WalletTxnItem = ({ walletTxnItem }) => {

    let amount = '';
    let color = null;
    let currency = "USD"

    switch (walletTxnItem?.txnType) {
        case txnType?.INR_WALLET?.value:
            currency = "INR"
            break;
        case txnType?.AUD_WALLET?.value:
            currency = "AUD"
            break;
        default:
            currency = "USD"
            break;
    }



    if (walletTxnItem.amountImpact === 'debit') {
        amount = "- " + currency + " " + Math.abs(parseFloat(walletTxnItem.amount / 100)).toFixed(2)
        color = 'text-primary-main'
    } else if (walletTxnItem.amountImpact === 'credit') {
        amount = "+ " + currency + " " + Math.abs(parseFloat(walletTxnItem.amount / 100)).toFixed(2)
        color = 'text-green-600'
    } else {
        amount = "(+/-) " + currency + " " + Math.abs(parseFloat(walletTxnItem.amount / 100)).toFixed(2)
        color = 'text-text-700'
    }

    return (
        <div className={"space-y-1"}>
            <div className="flex flex-row items-center justify-between">
                {walletTxnItem?.action &&
                    <span className={"font-bodyPri font-medium text-base text-text-900"}>
                        {walletTxnActions[(walletTxnItem?.action?.toUpperCase() || "OTHER")]?.label}
                    </span>
                }
                <span className={`font-bodyPri font-normal text-sm text-text-900 ${color}`}>
                    {amount}
                </span>
            </div>
            <div className={"flex flex-row items-center justify-between"}>
                <span className={"font-bodyPri font-normal text-sm text-text-900"}>
                    {dayjs(walletTxnItem.createdAt).tz(timeZone).format('DD/MM/YY hh:mm a')}
                </span>
                <span className={'font-bodyPri font-normal text-sm text-text-900 capitalize'}>
                    {[txnType?.PROMO?.value, txnType?.PENDING?.value]?.includes(walletTxnItem.txnType) ? txnType[walletTxnItem?.txnType?.toUpperCase()]?.label : ""}
                </span>
            </div>
        </div>
    )
}

export default WalletTxnItem;