import { cn } from "utils/cn.utils";

import { IoMdCalendar } from 'react-icons/io';

import { useAppState } from 'hooks/useStore';

const EventlyOrganizer = () => {
    const { user } = useAppState((state) => state.user)

    return (
        <div className={"w-full grid grid-cols-12 gap-x-2 gap-y-3 px-5"}>
            <div className={"col-start-1 col-span-1 self-center"}>
                <IoMdCalendar className={"text-text-700 text-lg"} />
            </div>
            <div className={cn("col-start-2 col-span-full self-center p-2")}>
                <span className={"font-bodyPri text-text-900 font-medium text-base tracking-wide truncate"}>
                    {`${user?.user?.firstName} ${user?.user?.lastName}`}
                </span>
            </div>
        </div>
    )
}

export default EventlyOrganizer;