import { cn } from "utils/cn.utils";

import ButtonLoader from 'components/loader/ButtonLoader';

const CreateOptionButton = ({
    isLoading = false,
    btnText = "Create new option",
    onHandleCreateOption
}) => {

    return (
        <button
            onClick={onHandleCreateOption}
            className={cn(
                'w-fit flex px-5 py-1 border rounded-full',
                !isLoading && "border-secondary-dark bg-white hover:bg-secondary-dark text-secondary-dark hover:text-white ease-in-out duration-300",
                isLoading && "bg-secondary-dark"
            )}
        >
            {isLoading &&
                <ButtonLoader isLoading={isLoading} />
            }
            {!isLoading && btnText}
        </button>
    )
}

export default CreateOptionButton;