import { useEffect, useState } from "react";
import { cn } from "utils/cn.utils";

import { MdOutlineRadioButtonChecked } from "react-icons/md";
import { MdOutlineRadioButtonUnchecked } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { BsPlusLg } from "react-icons/bs";

import FadedCollapse from "components/fadedCollapse";

import { createLmsQuizQuestionResponseDetail, updateLmsQuizQuestionResponseDetail } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { lmsResourceTypeEnum, resourceFileTypeEnum } from "redux/edulyteLms/lmsResource/lmsResource.const";

const SingleChoiceView = ({ question, quiz_response }) => {
    const { addLmsQuizQuestionResponse, updateLmsQuizQuestionResponse } = useAppState(state => state.lms.lmsQuiz)

    const [isSaving, setIsSaving] = useState(false)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (addLmsQuizQuestionResponse?.data || updateLmsQuizQuestionResponse?.data) {
            setIsSaving(false)
        }
    }, [addLmsQuizQuestionResponse?.data, updateLmsQuizQuestionResponse?.data])

    const question_response = quiz_response?.quiz_question_responses?.find(question_response => question_response?.question === question?.id)

    const sortOptions = (option1, option2) => option1?.order > option2?.order ? 1 : -1

    const onSelectOption = (option) => {
        if (!question_response) {
            setIsSaving(true)
            let requestBody = {
                quiz_response_id: quiz_response?.id,
                question_id: question?.id,
                response: [option?.option_id]
            }
            dispatcher(createLmsQuizQuestionResponseDetail(requestBody))
        } else {
            if (question_response?.response?.includes(option?.option_id)) {
                setIsSaving(true)
                let requestBody = { response: [] }
                dispatcher(updateLmsQuizQuestionResponseDetail(question_response?.id, requestBody))
            } else {
                setIsSaving(true)
                let requestBody = { response: [option?.option_id] }
                dispatcher(updateLmsQuizQuestionResponseDetail(question_response?.id, requestBody))
            }
        }
    }

    // On Completed
    if (quiz_response?.is_completed) {

        let has_answer_resource = !!(question?.answer?.answer_resources?.filter(answer_resource => (
            lmsResourceTypeEnum.FILE.value === answer_resource?.resource?.type &&
            [resourceFileTypeEnum.IMAGE.value, resourceFileTypeEnum.VIDEO.value].includes(answer_resource?.resource?.resource?.type)
        ))?.length)

        let is_answer_required = !question?.answer?.answer?.every(answer => question_response?.response?.includes(answer))

        const feedbackText = new DOMParser()?.parseFromString(`<div>${question?.answer?.feedback || ""}</div>`, "text/html")?.querySelector("div")?.textContent

        return (
            <div className={"space-y-5"}>

                {/* options */}
                <div className={"space-y-1"}>
                    <span className={"font-bodyPri font-medium text-base text-text-800"}>
                        {"Options:"}
                    </span>
                    <div className={"space-y-2"}>
                        {[...question?.question_options]?.sort(sortOptions)?.map((option, index) => {

                            let is_response = question_response?.response?.includes(option?.option_id)
                            let is_correct = option?.is_correct || question?.answer?.answer?.includes(option?.option_id)

                            return (
                                <div key={index}
                                    className={cn(
                                        "px-3 rounded flex items-start justify-start gap-3",
                                        is_response && (is_correct ? "bg-green-100 py-3 border-2 border-green-500" : "bg-red-100 py-3 border-2 border-red-500")
                                    )}
                                >
                                    <button className={"w-fit"} disabled={quiz_response?.is_completed}>
                                        {question_response?.is_attempt && is_response ? (
                                            <MdOutlineRadioButtonChecked className={"text-2xl text-primary-dark"} />
                                        ) : (
                                            <MdOutlineRadioButtonUnchecked className={"text-2xl text-text-500"} />
                                        )}
                                    </button>
                                    <div className={"w-full space-y-1"}>
                                        <div className={"flex items-center justify-between gap-2"}>
                                            {option?.option && (
                                                <p className={"font-bodyPri font-normal text-lg text-text-900"}>
                                                    {option?.option}
                                                </p>
                                            )}
                                            {is_response && (
                                                <div className="self-end">
                                                    {is_correct ? (
                                                        <FaCheck className={"text-xl text-green-500"} />
                                                    ) : (
                                                        <BsPlusLg className={"text-xl text-red-500 rotate-45"} />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        {(option?.question_option_resources?.filter(option_resource => (
                                            lmsResourceTypeEnum.FILE.value === option_resource?.resource?.type &&
                                            [resourceFileTypeEnum.IMAGE.value, resourceFileTypeEnum.VIDEO.value].includes(option_resource?.resource?.resource?.type)
                                        ))?.length > 0) && (
                                                <div className={"flex flex-wrap gap-2"}>
                                                    {option?.question_option_resources?.filter(option_resource => (
                                                        lmsResourceTypeEnum.FILE.value === option_resource?.resource?.type &&
                                                        [resourceFileTypeEnum.IMAGE.value].includes(option_resource?.resource?.resource?.type)
                                                    ))?.map((option_resource, index) => (
                                                        <div key={index}
                                                            className={"w-60 aspect-video border-2 border-text-40 overflow-hidden rounded-lg"}
                                                        >
                                                            <img
                                                                src={option_resource?.resource?.resource?.file?.file_url}
                                                                alt={option_resource?.resource?.title}
                                                                className={"w-full h-full object-cover"}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                {/* Correct answer view */}
                {(is_answer_required || has_answer_resource) && (
                    <div className={"space-y-1"}>
                        <span className={"font-bodyPri font-medium text-base text-text-800"}>
                            {"Correct answer:"}
                        </span>
                        {is_answer_required && (
                            <div className={"px-3 space-y-1"}>
                                {[...question?.question_options]?.sort(sortOptions)?.map((option, index) => (option?.is_correct || question?.answer?.answer?.includes(option?.option_id)) && (
                                    <div key={index}
                                        className={"w-full flex items-start justify-start gap-2"}
                                    >
                                        <FaCheck className={"text-lg text-green-500 mt-1.5"} />
                                        <div className={"w-full space-y-1"}>
                                            <div className={"flex items-center justify-between gap-2"}>
                                                {option?.option && (
                                                    <p className={"font-bodyPri font-normal text-lg text-text-900"}>
                                                        {option?.option}
                                                    </p>
                                                )}
                                            </div>
                                            {(option?.question_option_resources?.filter(option_resource => (
                                                lmsResourceTypeEnum.FILE.value === option_resource?.resource?.type &&
                                                [resourceFileTypeEnum.IMAGE.value, resourceFileTypeEnum.VIDEO.value].includes(option_resource?.resource?.resource?.type)
                                            ))?.length > 0) && (
                                                    <div className={"flex flex-wrap gap-2"}>
                                                        {option?.question_option_resources?.filter(option_resource => (
                                                            lmsResourceTypeEnum.FILE.value === option_resource?.resource?.type &&
                                                            [resourceFileTypeEnum.IMAGE.value].includes(option_resource?.resource?.resource?.type)
                                                        ))?.map((option_resource, index) => (
                                                            <div key={index}
                                                                className={"w-20 aspect-video border-2 border-text-40 overflow-hidden rounded-lg"}
                                                            >
                                                                <img
                                                                    src={option_resource?.resource?.resource?.file?.file_url}
                                                                    alt={option_resource?.resource?.title}
                                                                    className={"w-full h-full object-cover"}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {has_answer_resource && (
                            <div className={"px-3 flex flex-wrap gap-2"}>
                                {question?.answer?.answer_resources?.filter(answer_resource => (
                                    lmsResourceTypeEnum.FILE.value === answer_resource?.resource?.type &&
                                    [resourceFileTypeEnum.IMAGE.value, resourceFileTypeEnum.VIDEO.value].includes(answer_resource?.resource?.resource?.type)
                                ))?.map((answer_resource, index) => (
                                    <div key={index}
                                        className={"w-40 aspect-video border-2 border-text-40 overflow-hidden rounded-lg"}
                                    >
                                        {answer_resource?.resource?.resource?.type === resourceFileTypeEnum.IMAGE.value && (
                                            <img
                                                src={answer_resource?.resource?.resource?.file?.file_url}
                                                alt={answer_resource?.resource?.title}
                                                className={"w-full h-full object-cover"}
                                            />
                                        )}
                                        {answer_resource?.resource?.resource?.type === resourceFileTypeEnum.VIDEO.value && (
                                            <video
                                                src={answer_resource?.resource?.resource?.file?.file_url}
                                                alt={answer_resource?.resource?.title}
                                                className={"w-full h-full object-cover"}
                                                allowFullScreen={false}
                                                autoPlay={false}
                                                controls
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}

                {/* feedback */}
                {!!feedbackText && (
                    <div className={"space-y-1"}>
                        <span className={"font-bodyPri font-medium text-base text-text-800"}>
                            {"Feedback:"}
                        </span>
                        <FadedCollapse
                            isShowMore={feedbackText?.length > 500}
                            isFaded={feedbackText?.length > 500}
                            maxHeight={feedbackText?.length > 500 ? "7rem" : "100%"}
                            wrapperStyle="px-3"
                        >
                            <div dangerouslySetInnerHTML={{ __html: question?.answer?.feedback }}></div>
                        </FadedCollapse>
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className={"space-y-3"}>

            {/* option */}
            <div className={"space-y-1"}>
                <span className={"font-bodyPri font-medium text-base text-text-800"}>
                    {"Options:"}
                </span>
                <div className={"space-y-3"}>
                    {[...question?.question_options]?.sort(sortOptions)?.map((option, index) => {

                        let is_response = question_response?.response?.includes(option?.option_id)

                        return (
                            <div key={index}
                                className={"px-3 flex items-start justify-start gap-3"}
                            >
                                <button className={"w-fit"} onClick={() => onSelectOption(option)} disabled={quiz_response?.is_completed}>
                                    {question_response?.is_attempt && is_response ? (
                                        <MdOutlineRadioButtonChecked className={"text-2xl text-primary-dark"} />
                                    ) : (
                                        <MdOutlineRadioButtonUnchecked className={"text-2xl text-text-500"} />
                                    )}
                                </button>
                                <div className={"space-y-1"}>
                                    {option?.option && (
                                        <span className={"font-bodyPri font-normal text-lg text-text-900"}>
                                            {option?.option}
                                        </span>
                                    )}
                                    {(option?.question_option_resources?.filter(option_resource => (
                                        lmsResourceTypeEnum.FILE.value === option_resource?.resource?.type &&
                                        [resourceFileTypeEnum.IMAGE.value, resourceFileTypeEnum.VIDEO.value].includes(option_resource?.resource?.resource?.type)
                                    ))?.length > 0) && (
                                            <div className={"flex flex-wrap gap-2"}>
                                                {option?.question_option_resources?.filter(option_resource => (
                                                    lmsResourceTypeEnum.FILE.value === option_resource?.resource?.type &&
                                                    [resourceFileTypeEnum.IMAGE.value].includes(option_resource?.resource?.resource?.type)
                                                ))?.map((option_resource, index) => (
                                                    <div key={index}
                                                        className={"w-60 aspect-video border-2 border-text-40 overflow-hidden rounded-lg"}
                                                    >
                                                        <img
                                                            src={option_resource?.resource?.resource?.file?.file_url}
                                                            alt={option_resource?.resource?.title}
                                                            className={"w-full h-full object-cover"}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            {/* response state */}
            {!!question_response && question_response?.is_attempt && (
                <div className={"flex flex-row items-center justify-end"}>
                    <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                        {isSaving && (addLmsQuizQuestionResponse?.isLoading || updateLmsQuizQuestionResponse?.isLoading) ? "Saving.." : "Saved"}
                    </span>
                </div>
            )}
        </div>
    )
}

export default SingleChoiceView