import tw, { styled } from "twin.macro";

export const StyledChatViewMessage = styled.div(({ isSender }) => [
  tw`flex items-center justify-start`,
  isSender && tw`justify-end`,
]);

export const StyledChatViewMessageText = styled.div(({ isSender }) => [
  tw`flex flex-col bg-white text-base mb-2 p-2.5 rounded-md md:max-width[65%] shadow-md`,
  isSender && tw`bg-primary-dark text-white`,
]);

export const StyledChatViewMessageTextTime = styled.p(({ isSender }) => [
  tw`italic text-xs text-gray-500 flex justify-end`,
  isSender && tw`text-white`,
]);
