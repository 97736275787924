import { useReducer, useEffect } from 'react';
import { toast } from "react-toastify"
import { cn } from "utils/cn.utils";


import { FaSpinner } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { MdAdd, MdOutlineDeleteOutline, MdOutlineDragIndicator } from 'react-icons/md';

import { Draggable } from 'react-drag-reorder';
import SelectSearch, { fuzzySearch } from 'react-select-search';

import ComponentLoader from 'components/loader/ComponentLoader';

import { onboardingTooltipConst, profileEditBtnConst } from 'pages/auth/onboarding/data';
import AddBorderButton from 'pages/auth/onboarding/commonComponents/onboardingButtons/AddBorderButton';
import SaveCancelBtn from 'pages/auth/onboarding/commonComponents/onboardingButtons/SaveCancelBtn';
import OnboardingTooltip from 'pages/auth/onboarding/commonComponents/OnboardingTooltip';

import { getMasterLanguageLevelList, getMasterLanguageList } from 'redux/master/master.request';
import {
    createTutorLanguageDetail,
    deleteTutorLanguageDetail,
    updateTutorLanguageDetail,
    updateTutorLanguageListOrder
} from 'redux/tutor/tutor.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearLanguageLevelList, setClearLanguageList } from 'redux/master/master.slice';
import {
    resetAddTutorLanguageDetail,
    resetDeleteTutorLanguageDetail,
    resetUpdateTutorLanguageDetail,
    resetUpdateTutorLanguageListOrder
} from 'redux/tutor/tutor.slice';
import { languageLevelConst } from 'redux/master/master.const';

const languageLevelReducer = (state, action) => {
    switch (action.type) {
        case "SHOW_ADD_BTN":
            return { ...state, isShowAddBtn: action.payload }
        case "SHOW_DROPDOWNS":
            return { ...state, isShowDropdowns: action.payload }
        case "LANGUAGE_AND_LEVEL_LIST":
            return { ...state, languageAndLevelList: action.payload }
        case 'SELECTED_LANGUAGE':
            return { ...state, language: action.payload }
        case 'SELECTED_LANGUAGE_LEVEL':
            return { ...state, languageLevel: action.payload }
        case 'IS_UPDATE_ENABLED':
            return { ...state, isUpdateEnabled: action.payload }
        case 'TUTOR_LANGUAGE_ID':
            return { ...state, tutorLanguageId: action.payload }
        case "INITIAL_STATE":
            return {
                ...state,
                language: {
                    language: "",
                    id: null
                },
                languageLevel: {
                    languageLevel: "",
                    id: null
                },
                isUpdateEnabled: false,
                tutorLanguageId: null
            }
        default:
            return state;
    }
}

const LanguagesSpeak = ({ editBtnConst, setEditBtnConst }) => {
    const { languageList, languageLevelList } = useAppState((state) => state.master)
    const { user } = useAppState((state) => state.user)
    const { tutorLanguageList, addTutorLanguageDetail, updateTutorLanguage, updateTutorLanguageOrder, deleteTutorLanguage } = useAppState((state) => state.tutor)

    const [state, dispatch] = useReducer(languageLevelReducer, {
        isShowAddBtn: false,
        isShowDropdowns: false,
        isUpdateEnabled: false,
        tutorLanguageId: null,
        languageAndLevelList: [],
        language: {
            language: "",
            id: null
        },
        languageLevel: {
            languageLevel: '',
            id: null
        },
    })

    const dispatcher = useAppDispatcher()

    const loadingElement = tutorLanguageList?.isLoading || addTutorLanguageDetail?.isLoading || updateTutorLanguage?.isLoading || updateTutorLanguageOrder?.isLoading || deleteTutorLanguage?.isLoading
    const isLanguagesSpeakSimilar = JSON.stringify(tutorLanguageList?.data?.results) === JSON.stringify(state.languageAndLevelList)

    useEffect(() => {
        return () => {
            // dispatcher(resetTutorLanguageList())
            dispatcher(resetAddTutorLanguageDetail())
            dispatcher(resetUpdateTutorLanguageDetail())
            dispatcher(resetUpdateTutorLanguageListOrder())
            dispatcher(resetDeleteTutorLanguageDetail())
        }
    }, [])

    useEffect(() => {
        dispatcher(getMasterLanguageList())
        dispatcher(getMasterLanguageLevelList())

        return () => {
            dispatcher(setClearLanguageList())
            dispatcher(setClearLanguageLevelList())
        }

    }, [])

    // useEffect(() => {
    //     if (user?.user?.tutor?.tutorId) {
    //         dispatcher(getTutorLanguageList({ tutorId: user?.user?.tutor?.tutorId }))
    //     }
    // }, [user?.user])

    useEffect(() => {
        if (tutorLanguageList?.data?.results?.length > 0) {
            dispatch({
                type: "LANGUAGE_AND_LEVEL_LIST",
                payload: tutorLanguageList?.data?.results
            })

            dispatch({
                type: "SHOW_ADD_BTN",
                payload: true
            })
        }
    }, [tutorLanguageList?.data])

    const onHandleUpdateLanguageLevelOrder = () => {
        if (loadingElement) return;


        if (tutorLanguageList?.data?.results?.length >= 1 && !isLanguagesSpeakSimilar) {
            const body = {
                order: state.languageAndLevelList?.map((languageLevel) => ({
                    id: languageLevel?.id
                }))
            }

            dispatcher(updateTutorLanguageListOrder(body, { tutorId: user?.user?.tutor?.tutorId }))

        }
    }

    const onHandleUpdateLanguageAndLevel = () => {
        if (loadingElement) return;

        const body = {}
        if (state?.language?.language) {
            body["language"] = state?.language?.language
        }
        if (state?.languageLevel?.languageLevel) {
            body["languageLevel"] = state?.languageLevel?.languageLevel
        }

        dispatcher(updateTutorLanguageDetail(state?.tutorLanguageId, body))
        dispatch({
            type: "INITIAL_STATE",
            payload: null
        })

        dispatch({
            type: "SHOW_DROPDOWNS",
            payload: false
        })

        dispatch({
            type: "SHOW_ADD_BTN",
            payload: true
        })

        setEditBtnConst(null)
    }

    const onHandleSave = () => {
        if (loadingElement) return;

        if (!isLanguagesSpeakSimilar) {
            onHandleUpdateLanguageLevelOrder()
        }
        if (state.isShowDropdowns) {
            if (!state?.language?.id || !state?.languageLevel?.id) {
                toast.warn("Language and proficiency are required fields")
                return;
            }
            if (state.isUpdateEnabled) {
                onHandleUpdateLanguageAndLevel()
                return;
            }

            const filteredLanguageAndLevelList = tutorLanguageList?.data?.results?.filter((languageAndLevel) => (
                languageAndLevel?.masterLanguage?.id === state?.language?.id
            ))

            if (filteredLanguageAndLevelList?.length > 0) {
                toast.warn("Duplicate Entries are not allowed")
                return;
            }

            const requestDataPayload = {
                tutorId: user?.user?.tutor?.tutorId,
                language: state.language.language,
                languageLevel: state.languageLevel.languageLevel
            }
            dispatcher(createTutorLanguageDetail(requestDataPayload))
        }

        dispatch({
            type: "INITIAL_STATE",
            payload: null
        })

        dispatch({
            type: "SHOW_DROPDOWNS",
            payload: false
        })

        dispatch({
            type: "SHOW_ADD_BTN",
            payload: true
        })

        setEditBtnConst(null)
    }

    const onHandleCancel = () => {

        dispatcher(resetAddTutorLanguageDetail())
        dispatcher(resetUpdateTutorLanguageDetail())
        dispatcher(resetUpdateTutorLanguageListOrder())
        dispatcher(resetDeleteTutorLanguageDetail())

        dispatch({
            type: "INITIAL_STATE",
            payload: null
        })
        setEditBtnConst(null)
    }

    const onHandleAddLanguage = () => {
        if (!editBtnConst || editBtnConst !== profileEditBtnConst?.SPEAKS?.value) {
            setEditBtnConst(profileEditBtnConst?.SPEAKS?.value)
        }

        dispatcher(resetAddTutorLanguageDetail())
        dispatcher(resetUpdateTutorLanguageDetail())
        dispatcher(resetUpdateTutorLanguageListOrder())
        dispatcher(resetDeleteTutorLanguageDetail())

        if (state.languageAndLevelList?.length >= 1) {
            dispatch({
                type: "SHOW_ADD_BTN",
                payload: false
            })
            dispatch({
                type: "SHOW_DROPDOWNS",
                payload: true
            })
        } else {
            dispatch({
                type: "SHOW_ADD_BTN",
                payload: false
            })
            dispatch({
                type: "SHOW_DROPDOWNS",
                payload: true
            })
        }
    }

    const getChangedPos = (currentPos, newPos) => {
        if (!editBtnConst || editBtnConst !== profileEditBtnConst?.SPEAKS?.value) {
            setEditBtnConst(profileEditBtnConst?.SPEAKS?.value)
        }
        var updatedList = [...state.languageAndLevelList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(currentPos, 1);
        // Add dropped item
        updatedList.splice(newPos, 0, reorderedItem);
        // Update State
        dispatch({
            type: "LANGUAGE_AND_LEVEL_LIST",
            payload: updatedList
        })
    }

    const onHandleSelection = (key, option) => {

        if (key === "SelectLanguage") {
            const selectedLanguage = languageList?.languageList?.find((language) => language?.masterLanguageId === option)

            const newSelectedLanguage = {
                language: selectedLanguage?.language,
                id: selectedLanguage?.masterLanguageId
            }
            dispatch({
                type: "SELECTED_LANGUAGE",
                payload: newSelectedLanguage
            })
        } else {
            const selectedLanguageLevel = languageLevelList?.languageLevelList?.find((languageLevel) => languageLevel?.masterLanguageLevelId === option)

            const newSelectedLanguageLevel = {
                languageLevel: selectedLanguageLevel?.languageLevel,
                id: selectedLanguageLevel?.masterLanguageLevelId
            }
            dispatch({
                type: "SELECTED_LANGUAGE_LEVEL",
                payload: newSelectedLanguageLevel
            })
        }

    }

    const onHandleEditLanguage = (userLanguageLevel) => {

        dispatcher(resetAddTutorLanguageDetail())
        dispatcher(resetUpdateTutorLanguageDetail())
        dispatcher(resetUpdateTutorLanguageListOrder())
        dispatcher(resetDeleteTutorLanguageDetail())

        dispatch({
            type: "SHOW_ADD_BTN",
            payload: false
        })

        dispatch({
            type: "SHOW_DROPDOWNS",
            payload: true
        })
        dispatch({
            type: "IS_UPDATE_ENABLED",
            payload: true
        })
        dispatch({
            type: "TUTOR_LANGUAGE_ID",
            payload: userLanguageLevel?.id
        })
        dispatch({
            type: "SELECTED_LANGUAGE",
            payload: { language: userLanguageLevel?.masterLanguage?.language, id: userLanguageLevel?.masterLanguage?.id }
        })
        dispatch({
            type: "SELECTED_LANGUAGE_LEVEL",
            payload: { languageLevel: userLanguageLevel?.masterLanguageLevel?.languageLevel, id: userLanguageLevel?.masterLanguageLevel?.id }
        })
        setEditBtnConst(profileEditBtnConst?.SPEAKS?.value)
    }

    const removeLanguageAndLevel = (UserLanguageLevel) => {
        if (loadingElement) return;

        dispatcher(deleteTutorLanguageDetail(UserLanguageLevel?.id))
        dispatch({
            type: "INITIAL_STATE",
            payload: null
        })

        dispatch({
            type: "SHOW_DROPDOWNS",
            payload: false
        })

        dispatch({
            type: "SHOW_ADD_BTN",
            payload: true
        })
    }

    return (
        <div className={"w-full flex flex-col items-start justify-start space-y-2"}>
            <div className={"w-full flex items-center justify-between gap-2"}>
                <div className={"flex items-center justify-start gap-1"}>
                    <OnboardingTooltip tooltip={onboardingTooltipConst?.languageTooltip} translateX={0} translateY={0} />
                    <span className='font-bodyPri font-medium text-text-900 text-base tracking-wide'>
                        {"Languages I speak"}
                    </span>
                </div>
                {!tutorLanguageList?.isLoading &&
                    <SaveCancelBtn
                        isShowBtn={editBtnConst === profileEditBtnConst?.SPEAKS?.value || loadingElement}
                        onHandleSave={onHandleSave}
                        onHandleCancel={onHandleCancel}
                    />
                }
            </div>
            {tutorLanguageList?.isLoading &&
                <ComponentLoader isLoading={tutorLanguageList?.isLoading} />
            }
            {(!tutorLanguageList?.isLoading && tutorLanguageList?.message) &&
                <div className={"flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {tutorLanguageList?.message}
                    </span>
                </div>
            }
            {!tutorLanguageList?.isLoading && ((!editBtnConst || editBtnConst !== profileEditBtnConst?.SPEAKS?.value) && (!tutorLanguageList?.data || tutorLanguageList?.data?.results?.length === 0)) &&
                <AddBorderButton btnTitle={"Add Language(s)"} onHandleAdd={onHandleAddLanguage} />
            }
            {(!tutorLanguageList?.isLoading && (editBtnConst === profileEditBtnConst?.SPEAKS?.value || tutorLanguageList?.data?.results?.length > 0)) &&
                <div className={"w-full px-3 py-3 border border-divider-medium rounded-lg space-y-3"}>
                    {loadingElement &&
                        <ComponentLoader isLoading={loadingElement} />
                    }
                    {(!loadingElement && tutorLanguageList?.data?.results?.length > 0) &&
                        <Draggable onPosChange={getChangedPos}>
                            {tutorLanguageList?.data?.results?.length > 0 &&
                                tutorLanguageList?.data?.results?.map((language, index) => (
                                    <div key={index} className={"grid grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-2"}>
                                        <div className={"col-start-1 col-span-1 flex items-center justify-start gap-3"}>
                                            <span className={""}>
                                                <MdOutlineDragIndicator className={"text-lg text-text-800"} />
                                            </span>
                                            <div className={"w-fit px-3 py-1 flex items-center justify-start gap-1.5 border border-divider-lightDark rounded-lg overflow-hidden"}>
                                                <span className={"font-bodyPri font-medium text-text-900 text-sm tracking-wide"}>
                                                    {language?.masterLanguage?.language}
                                                </span>
                                                <span className={"font-bodyPri font-normal text-sm text-text-700 tracking-wide whitespace-normal sm:whitespace-nowrap"}>
                                                    ({languageLevelConst[language?.masterLanguageLevel?.languageLevel?.toUpperCase()]?.label})
                                                </span>
                                            </div>
                                        </div>
                                        <div className={"col-start-3 lg:col-start-2 lg:col-span-1 flex items-center justify-start gap-1.5"}>
                                            <FiEdit
                                                title={"Edit"}
                                                className={"text-lg text-text-500 cursor-pointer"}
                                                onClick={() => onHandleEditLanguage(language)}
                                            />
                                            <MdOutlineDeleteOutline
                                                title={"Delete"}
                                                className={"text-xl text-text-500 cursor-pointer"}
                                                onClick={() => removeLanguageAndLevel(language)}
                                            />
                                        </div>
                                        <div className={"col-start-1 col-span-full h-0.5 border border-divider-medium"}>
                                        </div>
                                    </div>
                                ))}
                        </Draggable>
                    }

                    {state.isShowAddBtn &&
                        <div
                            className={"w-fit flex items-center justify-start gap-2 px-2 py-1 bg-divider-darkLight rounded-lg cursor-pointer"}
                            onClick={onHandleAddLanguage}
                        >
                            <MdAdd className={"text-base text-text-700"} />
                            <span className={"font-bodyPri font-normal text-text-700 text-sm tracking-wide"}>
                                {"Add language"}
                            </span>
                        </div>

                    }
                    {(state.isShowDropdowns) &&
                        <div className={"flex flex-col items-start justify-start md:flex-row md:items-center md:justify-start gap-5"}>
                            {languageList?.isLoading &&
                                <div className={"flex items-center justify-start gap-0.5"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                                        {"Please wait ..."}
                                    </span>
                                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                                </div>
                            }
                            {(!languageList?.isLoading && languageList?.languageList) &&
                                <div className={cn(
                                    "w-full border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                                )}>
                                    <SelectSearch
                                        options={languageList?.languageList?.map((language) => ({
                                            name: language?.language,
                                            value: language?.masterLanguageId
                                        })) || []}
                                        search
                                        autoFocus={false}
                                        placeholder={"Select Language"}
                                        filterOptions={fuzzySearch}
                                        value={state?.language?.id}
                                        onChange={(option) => onHandleSelection("SelectLanguage", option)}
                                    />
                                </div>
                            }
                            {(!languageList?.isLoading && languageList?.errorMsg) &&
                                <span className={"font-bodyPri font-normal text-red-500 text-sm tracking-wide"}>
                                    {languageList?.errorMsg}
                                </span>
                            }

                            {languageLevelList?.isLoading &&
                                <div className={"flex items-center justify-start gap-0.5"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                                        {"Please wait ..."}
                                    </span>
                                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                                </div>
                            }
                            {(!languageLevelList?.isLoading && languageLevelList?.languageLevelList) &&
                                <div className={cn(
                                    "w-full border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                                )}>
                                    <SelectSearch
                                        options={languageLevelList?.languageLevelList?.map((languageLevel) => ({
                                            name: languageLevelConst[languageLevel?.languageLevel?.toUpperCase()]?.label,
                                            value: languageLevel?.masterLanguageLevelId
                                        })) || []}
                                        search
                                        autoFocus={false}
                                        placeholder={"Select Level"}
                                        filterOptions={fuzzySearch}
                                        value={state?.languageLevel?.id}
                                        onChange={(option) => onHandleSelection("SelectLanguageLevel", option)}
                                    />
                                </div>
                            }
                            {(!languageLevelList?.isLoading && languageLevelList?.errorMsg) &&
                                <span className={"font-bodyPri font-normal text-red-500 text-sm tracking-wide"}>
                                    {languageLevelList?.errorMsg}
                                </span>
                            }
                        </div>
                    }
                </div>
            }
            {(addTutorLanguageDetail?.message || updateTutorLanguageDetail?.message || deleteTutorLanguageDetail?.message) &&
                <div className={"flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {addTutorLanguageDetail?.message || updateTutorLanguageDetail?.message || deleteTutorLanguageDetail?.message}
                    </span>
                </div>
            }
        </div>
    )
}

export default LanguagesSpeak;