import { createSlice } from "@reduxjs/toolkit";

import { LMS_QUIZ_INITIAL_STATE } from "./lmsQuiz.initialState";

const lmsQuiz = createSlice({
    name: 'Lms Quiz',
    initialState: LMS_QUIZ_INITIAL_STATE,
    reducers: {
        // lms quiz list
        setLmsQuizListLoading: (state, { payload }) => {
            state.lmsQuizList.isLoading = payload
        },
        setLmsQuizListData: (state, { payload }) => {
            state.lmsQuizList.data = payload
            state.lmsQuizList.message = LMS_QUIZ_INITIAL_STATE.lmsQuizList.message
        },
        setLmsQuizListMessage: (state, { payload }) => {
            state.lmsQuizList.message = payload
            state.lmsQuizList.data = LMS_QUIZ_INITIAL_STATE.lmsQuizList.data
        },
        resetLmsQuizList: (state) => {
            state.lmsQuizList = LMS_QUIZ_INITIAL_STATE.lmsQuizList
        },

        // lms ai quiz detail
        setLmsAiQuizDetailLoading: (state, { payload }) => {
            state.lmsAiQuizDetail.isLoading = payload
        },
        setLmsAiQuizDetailData: (state, { payload }) => {
            state.lmsAiQuizDetail.data = payload
            state.lmsAiQuizDetail.message = LMS_QUIZ_INITIAL_STATE.lmsAiQuizDetail.message
        },
        setLmsAiQuizDetailMessage: (state, { payload }) => {
            state.lmsAiQuizDetail.message = payload
            state.lmsAiQuizDetail.data = LMS_QUIZ_INITIAL_STATE.lmsAiQuizDetail.data
        },
        resetLmsAiQuizDetail: (state) => {
            state.lmsAiQuizDetail = LMS_QUIZ_INITIAL_STATE.lmsAiQuizDetail
        },

        // lms quiz detail
        setLmsQuizDetailLoading: (state, { payload }) => {
            state.lmsQuizDetail.isLoading = payload
        },
        setLmsQuizDetailData: (state, { payload }) => {
            state.lmsQuizDetail.data = payload
            state.lmsQuizDetail.message = LMS_QUIZ_INITIAL_STATE.lmsQuizDetail.message
        },
        setLmsQuizDetailMessage: (state, { payload }) => {
            state.lmsQuizDetail.message = payload
            state.lmsQuizDetail.data = LMS_QUIZ_INITIAL_STATE.lmsQuizDetail.data
        },
        resetLmsQuizDetail: (state) => {
            state.lmsQuizDetail = LMS_QUIZ_INITIAL_STATE.lmsQuizDetail
        },

        // add lms quiz detail
        setAddLmsQuizLoading: (state, { payload }) => {
            state.addLmsQuiz.isLoading = payload
        },
        setAddLmsQuizData: (state, { payload }) => {
            state.addLmsQuiz.data = payload
            state.addLmsQuiz.message = LMS_QUIZ_INITIAL_STATE.addLmsQuiz.message
        },
        setAddLmsQuizMessage: (state, { payload }) => {
            state.addLmsQuiz.message = payload
            state.addLmsQuiz.data = LMS_QUIZ_INITIAL_STATE.addLmsQuiz.data
        },
        resetAddLmsQuiz: (state) => {
            state.addLmsQuiz = LMS_QUIZ_INITIAL_STATE.addLmsQuiz
        },

        setUpdateLmsQuizLoading: (state, { payload }) => {
            state.updateLmsQuiz.isLoading = payload
        },
        setUpdateLmsQuizData: (state, { payload }) => {
            state.updateLmsQuiz.data = payload
            state.updateLmsQuiz.message = LMS_QUIZ_INITIAL_STATE.updateLmsQuiz
        },
        setUpdateLmsQuizMessage: (state, { payload }) => {
            state.updateLmsQuiz.message = payload
            state.updateLmsQuiz.data = LMS_QUIZ_INITIAL_STATE.updateLmsQuiz
        },
        resetUpdateLmsQuiz: (state, { payload }) => {
            state.updateLmsQuiz = LMS_QUIZ_INITIAL_STATE.updateLmsQuiz
        },

        // add lms quiz section detail
        setAddLmsQuizSectionLoading: (state, { payload }) => {
            state.addLmsQuizSection.isLoading = payload
        },
        setAddLmsQuizSectionData: (state, { payload }) => {
            state.addLmsQuizSection.data = payload
            state.addLmsQuizSection.message = LMS_QUIZ_INITIAL_STATE.addLmsQuizSection.message
        },
        setAddLmsQuizSectionMessage: (state, { payload }) => {
            state.addLmsQuizSection.message = payload
            state.addLmsQuizSection.data = LMS_QUIZ_INITIAL_STATE.addLmsQuizSection.data
        },
        resetAddLmsQuizSectionData: (state) => {
            state.addLmsQuizSection.data = LMS_QUIZ_INITIAL_STATE.addLmsQuizSection.data
        },
        resetAddLmsQuizSection: (state) => {
            state.addLmsQuizSection = LMS_QUIZ_INITIAL_STATE.addLmsQuizSection
        },

        // add lms quiz section detail payload
        setAddLmsQuizSectionPayload: (state, { payload }) => {
            state.addLmsQuizSection.payload = payload
        },
        resetAddLmsQuizSectionPayload: (state) => {
            state.addLmsQuizSection.payload = LMS_QUIZ_INITIAL_STATE.addLmsQuizSection.payload
        },

        setLmsQuizSectionLoading: (state, { payload }) => {
            state.lmsQuizSection.isLoading = payload
        },
        setLmsQuizSectionData: (state, { payload }) => {
            state.lmsQuizSection.data = payload
            state.lmsQuizSection.message = LMS_QUIZ_INITIAL_STATE.lmsQuizSection.message
        },
        setLmsQuizSectionMessage: (state, { payload }) => {
            state.lmsQuizSection.message = payload
            state.lmsQuizSection.data = LMS_QUIZ_INITIAL_STATE.lmsQuizSection.data
        },
        resetLmsQuizSection: (state, { payload }) => {
            state.lmsQuizSection = LMS_QUIZ_INITIAL_STATE.lmsQuizSection
        },

        setLmsQuizSectionListLoading: (state, { payload }) => {
            state.lmsQuizSectionList.isLoading = payload
        },
        setLmsQuizSectionListData: (state, { payload }) => {
            state.lmsQuizSectionList.data = payload
            state.lmsQuizSectionList.message = LMS_QUIZ_INITIAL_STATE.lmsQuizSection.message
        },
        setLmsQuizSectionListMessage: (state, { payload }) => {
            state.lmsQuizSectionList.message = payload
            state.lmsQuizSectionList.data = LMS_QUIZ_INITIAL_STATE.lmsQuizSectionList.data
        },
        resetLmsQuizSectionList: (state) => {
            state.lmsQuizSectionList = LMS_QUIZ_INITIAL_STATE.lmsQuizSectionList
        },

        setUpdateLmsQuizSectionLoading: (state, { payload }) => {
            state.updateLmsQuizSection.isLoading = payload
        },
        setUpdateLmsQuizSectionData: (state, { payload }) => {
            state.updateLmsQuizSection.data = payload
            state.updateLmsQuizSection.message = LMS_QUIZ_INITIAL_STATE.updateLmsQuizSection
        },
        setUpdateLmsQuizSectionMessage: (state, { payload }) => {
            state.updateLmsQuizSection.message = payload
            state.updateLmsQuizSection.data = LMS_QUIZ_INITIAL_STATE.updateLmsQuizSection.data
        },
        resetUpdateLmsQuizSection: (state) => {
            state.updateLmsQuizSection = LMS_QUIZ_INITIAL_STATE.updateLmsQuizSection
        },

        setDeleteLmsQuizSectionLoading: (state, { payload }) => {
            state.deleteLmsQuizSection.isLoading = payload
        },
        setDeleteLmsQuizSectionMessage: (state, { payload }) => {
            state.deleteLmsQuizSection.message = payload
        },
        resetDeleteLmsQuizSection: (state) => {
            state.deleteLmsQuizSection = LMS_QUIZ_INITIAL_STATE.deleteLmsQuizSection
        },

        setUpdateOrderLmsQuizSectionLoading: (state, { payload }) => {
            state.updateOrderLmsQuizSection.isLoading = payload
        },
        setUpdateOrderLmsQuizSectionData: (state, { payload }) => {
            state.updateOrderLmsQuizSection.data = payload
            state.updateOrderLmsQuizSection.message = LMS_QUIZ_INITIAL_STATE.updateOrderLmsQuizSection.message
        },
        setUpdateOrderLmsQuizSectionMessage: (state, { payload }) => {
            state.updateOrderLmsQuizSection.message = payload
            state.updateOrderLmsQuizSection.data = LMS_QUIZ_INITIAL_STATE.updateOrderLmsQuizSection
        },
        resetUpdateOrderLmsQuizSection: (state) => {
            state.updateOrderLmsQuizSection = LMS_QUIZ_INITIAL_STATE.updateOrderLmsQuizSection
        },

        setAddLmsQuizCategoryLoading: (state, { payload }) => {
            state.addLmsQuizCategory.isLoading = payload
        },
        setAddLmsQuizCategoryData: (state, { payload }) => {
            state.addLmsQuizCategory.data = payload
            state.addLmsQuizCategory.message = LMS_QUIZ_INITIAL_STATE.addLmsQuizCategory.message
        },
        setAddLmsQuizCategoryMessage: (state, { payload }) => {
            state.addLmsQuizCategory.message = payload
            state.addLmsQuizCategory.data = LMS_QUIZ_INITIAL_STATE.addLmsQuizCategory.data
        },
        resetAddLmsQuizCategory: (state) => {
            state.addLmsQuizCategory = LMS_QUIZ_INITIAL_STATE.addLmsQuizCategory
        },

        setLmsQuizCategoryLoading: (state, { payload }) => {
            state.lmsQuizCategory.isLoading = payload
        },
        setLmsQuizCategoryData: (state, { payload }) => {
            state.lmsQuizCategory.data = payload
            state.lmsQuizCategory.message = LMS_QUIZ_INITIAL_STATE.lmsQuizCategory.message
        },
        setLmsQuizCategoryMessage: (state, { payload }) => {
            state.lmsQuizCategory.message = payload
            state.lmsQuizCategory.data = LMS_QUIZ_INITIAL_STATE.lmsQuizCategory.data
        },
        resetLmsQuizCategory: (state) => {
            state.lmsQuizCategory = LMS_QUIZ_INITIAL_STATE.lmsQuizCategory
        },

        setLmsQuizCategoryListLoading: (state, { payload }) => {
            state.lmsQuizCategoryList.isLoading = payload
        },
        setLmsQuizCategoryListData: (state, { payload }) => {
            state.lmsQuizCategoryList.data = payload
            state.lmsQuizCategoryList.message = LMS_QUIZ_INITIAL_STATE.lmsQuizCategoryList.message
        },
        setLmsQuizCategoryListMessage: (state, { payload }) => {
            state.lmsQuizCategoryList.message = payload
            state.lmsQuizCategoryList.data = LMS_QUIZ_INITIAL_STATE.lmsQuizCategoryList.data
        },
        resetLmsQuizCategoryList: (state) => {
            state.lmsQuizCategoryList = LMS_QUIZ_INITIAL_STATE.lmsQuizCategoryList
        },

        setUpdateLmsQuizCategoryLoading: (state, { payload }) => {
            state.updateLmsQuizCategory.isLoading = payload
        },
        setUpdateLmsQuizCategoryData: (state, { payload }) => {
            state.updateLmsQuizCategory.data = payload
            state.updateLmsQuizCategory.message = LMS_QUIZ_INITIAL_STATE.updateLmsQuizCategory.message
        },
        setUpdateLmsQuizCategoryMessage: (state, { payload }) => {
            state.updateLmsQuizCategory.message = payload
            state.updateLmsQuizCategory.data = LMS_QUIZ_INITIAL_STATE.updateLmsQuizCategory.data
        },
        resetUpdateLmsQuizCategory: (state) => {
            state.updateLmsQuizCategory = LMS_QUIZ_INITIAL_STATE.updateLmsQuizCategory
        },

        setDeleteLmsQuizCategoryLoading: (state, { payload }) => {
            state.deleteLmsQuizCategory.isLoading = payload
        },
        setDeleteLmsQuizCategoryMessage: (state, { payload }) => {
            state.deleteLmsQuizCategory.message = payload
        },
        resetDeleteLmsQuizCategory: (state) => {
            state.deleteLmsQuizCategory = LMS_QUIZ_INITIAL_STATE.deleteLmsQuizCategory
        },

        // lms quiz tag
        // add lms quiz tag detail
        setAddLmsQuizTagDetailLoading: (state, { payload }) => {
            state.addLmsQuizTagDetail.isLoading = payload
        },
        setAddLmsQuizTagDetailMessage: (state, { payload }) => {
            state.addLmsQuizTagDetail.message = payload
        },
        resetAddLmsQuizTagDetail: (state) => {
            state.addLmsQuizTagDetail = LMS_QUIZ_INITIAL_STATE.addLmsQuizTagDetail
        },

        // modify lms quiz tag list order
        setModifyLmsQuizTagListOrderLoading: (state, { payload }) => {
            state.modifyLmsQuizTagListOrderDetail.isLoading = payload
        },
        setModifyLmsQuizTagListOrderMessage: (state, { payload }) => {
            state.modifyLmsQuizTagListOrderDetail.message = payload
        },
        resetModifyLmsQuizTagListOrder: (state) => {
            state.modifyLmsQuizTagListOrderDetail = LMS_QUIZ_INITIAL_STATE.modifyLmsQuizTagListOrderDetail
        },

        // delete lms quiz tag detail
        setDestroyLmsQuizTagDetailLoading: (state, { payload }) => {
            state.destroyLmsQuizTagDetail.isLoading = payload
        },
        setDestroyLmsQuizTagDetailMessage: (state, { payload }) => {
            state.destroyLmsQuizTagDetail.message = payload
        },
        resetDestroyLmsQuizTagDetail: (state) => {
            state.destroyLmsQuizTagDetail = LMS_QUIZ_INITIAL_STATE.destroyLmsQuizTagDetail
        },

        setAddLmsQuizSettingLoading: (state, { payload }) => {
            state.addLmsQuizSetting.isLoading = payload
        },
        setAddLmsQuizSettingData: (state, { payload }) => {
            state.addLmsQuizSetting.data = payload
            state.addLmsQuizSetting.message = LMS_QUIZ_INITIAL_STATE.addLmsQuizSetting.message
        },
        setAddLmsQuizSettingMessage: (state, { payload }) => {
            state.addLmsQuizSetting.message = payload
            state.addLmsQuizSetting.data = LMS_QUIZ_INITIAL_STATE.addLmsQuizSetting.data
        },
        resetAddLmsQuizSetting: (state) => {
            state.addLmsQuizSetting = LMS_QUIZ_INITIAL_STATE.addLmsQuizSetting
        },

        setLmsQuizSettingLoading: (state, { payload }) => {
            state.lmsQuizSetting.isLoading = payload
        },
        setLmsQuizSettingData: (state, { payload }) => {
            state.lmsQuizSetting.data = payload
            state.lmsQuizSetting.message = LMS_QUIZ_INITIAL_STATE.lmsQuizSetting.message
        },
        setLmsQuizSettingMessage: (state, { payload }) => {
            state.lmsQuizSetting.message = payload
            state.lmsQuizSetting.data = LMS_QUIZ_INITIAL_STATE.lmsQuizSetting.data
        },
        resetLmsQuizSetting: (state) => {
            state.lmsQuizSetting = LMS_QUIZ_INITIAL_STATE.lmsQuizSetting
        },

        // lms quiz settings payload
        setLmsQuizSettingPayload: (state, { payload }) => {
            state.lmsQuizSetting.payload = payload
        },
        resetLmsQuizSettingPayload: (state) => {
            state.lmsQuizSetting.payload = LMS_QUIZ_INITIAL_STATE.lmsQuizSetting.payload
        },

        setLmsQuizSettingListLoading: (state, { payload }) => {
            state.lmsQuizSettingList.isLoading = payload
        },
        setLmsQuizSettingListData: (state, { payload }) => {
            state.lmsQuizSettingList.data = payload
            state.lmsQuizSettingList.message = LMS_QUIZ_INITIAL_STATE.lmsQuizSettingList.message
        },
        setLmsQuizSettingListMessage: (state, { payload }) => {
            state.lmsQuizSettingList.message = payload
            state.lmsQuizSettingList.data = LMS_QUIZ_INITIAL_STATE.lmsQuizSettingList.data
        },
        resetLmsQuizSettingList: (state) => {
            state.lmsQuizSettingList = LMS_QUIZ_INITIAL_STATE.lmsQuizSettingList
        },

        setUpdateLmsQuizSettingLoading: (state, { payload }) => {
            state.updateLmsQuizSetting.isLoading = payload
        },
        setUpdateLmsQuizSettingData: (state, { payload }) => {
            state.updateLmsQuizSetting.data = payload
            state.updateLmsQuizSetting.message = LMS_QUIZ_INITIAL_STATE.updateLmsQuizSetting.message
        },
        setUpdateLmsQuizSettingMessage: (state, { payload }) => {
            state.updateLmsQuizSetting.message = payload
            state.updateLmsQuizSetting.data = LMS_QUIZ_INITIAL_STATE.updateLmsQuizSetting.data
        },
        resetUpdateLmsQuizSetting: (state) => {
            state.updateLmsQuizSetting = LMS_QUIZ_INITIAL_STATE.updateLmsQuizSetting
        },

        setDeleteLmsQuizSettingLoading: (state, { payload }) => {
            state.deleteLmsQuizSetting.isLoading = payload
        },
        setDeleteLmsQuizSettingMessage: (state, { payload }) => {
            state.deleteLmsQuizSetting.message = payload
        },
        resetDeleteLmsQuizSetting: (state) => {
            state.deleteLmsQuizSetting = LMS_QUIZ_INITIAL_STATE.deleteLmsQuizSetting
        },

        setAddLmsQuizUserLoading: (state, { payload }) => {
            state.addLmsQuizUser.isLoading = payload
        },
        setAddLmsQuizUserData: (state, { payload }) => {
            state.addLmsQuizUser.data = payload
            state.addLmsQuizUser.message = LMS_QUIZ_INITIAL_STATE.addLmsQuizUser.message
        },
        setAddLmsQuizUserMessage: (state, { payload }) => {
            state.addLmsQuizUser.message = payload
            state.addLmsQuizUser.data = LMS_QUIZ_INITIAL_STATE.addLmsQuizUser.data
        },
        resetAddLmsQuizUser: (state) => {
            state.addLmsQuizUser = LMS_QUIZ_INITIAL_STATE.addLmsQuizUser
        },

        setLmsQuizUserListLoading: (state, { payload }) => {
            state.lmsQuizUserList.isLoading = payload
        },
        setLmsQuizUserListData: (state, { payload }) => {
            state.lmsQuizUserList.data = payload
            state.lmsQuizUserList.message = LMS_QUIZ_INITIAL_STATE.lmsQuizUserList.message
        },
        setLmsQuizUserListMessage: (state, { payload }) => {
            state.lmsQuizUserList.message = payload
            state.lmsQuizUserList.data = LMS_QUIZ_INITIAL_STATE.lmsQuizUserList.data
        },
        resetLmsQuizUserList: (state) => {
            state.lmsQuizUserList = LMS_QUIZ_INITIAL_STATE.lmsQuizUserList
        },

        setLmsQuizUserLoading: (state, { payload }) => {
            state.lmsQuizUser.isLoading = payload
        },
        setLmsQuizUserData: (state, { payload }) => {
            state.lmsQuizUser.data = payload
            state.lmsQuizUser.message = LMS_QUIZ_INITIAL_STATE.lmsQuizUser.message
        },
        setLmsQuizUserMessage: (state, { payload }) => {
            state.lmsQuizUser.message = payload
            state.lmsQuizUser.data = LMS_QUIZ_INITIAL_STATE.lmsQuizUser.data
        },
        resetLmsQuizUser: (state) => {
            state.lmsQuizUser = LMS_QUIZ_INITIAL_STATE.lmsQuizUser
        },

        setUpdateLmsQuizUserLoading: (state, { payload }) => {
            state.updateLmsQuizUser.isLoading = payload
        },
        setUpdateLmsQuizUserData: (state, { payload }) => {
            state.updateLmsQuizUser.data = payload
            state.updateLmsQuizUser.message = LMS_QUIZ_INITIAL_STATE.updateLmsQuizUser.message
        },
        setUpdateLmsQuizUserMessage: (state, { payload }) => {
            state.updateLmsQuizUser.message = payload
            state.updateLmsQuizUser.data = LMS_QUIZ_INITIAL_STATE.updateLmsQuizUser.data
        },
        resetUpdateLmsQuizUser: (state) => {
            state.updateLmsQuizUser = LMS_QUIZ_INITIAL_STATE.updateLmsQuizUser
        },

        setDeleteLmsQuizUserLoading: (state, { payload }) => {
            state.deleteLmsQuizUser.isLoading = payload
        },
        setDeleteLmsQuizUserMessage: (state, { payload }) => {
            state.deleteLmsQuizUser.message = payload
        },
        resetDeleteLmsQuizUser: (state) => {
            state.deleteLmsQuizUser = LMS_QUIZ_INITIAL_STATE.deleteLmsQuizUser
        },

        setAddLmsQuizResponseLoading: (state, { payload }) => {
            state.addLmsQuizResponse.isLoading = payload
        },
        setAddLmsQuizResponseData: (state, { payload }) => {
            state.addLmsQuizResponse.data = payload
            state.addLmsQuizResponse.message = LMS_QUIZ_INITIAL_STATE.addLmsQuizResponse.message
        },
        setAddLmsQuizResponseMessage: (state, { payload }) => {
            state.addLmsQuizResponse.message = payload
            state.addLmsQuizResponse.data = LMS_QUIZ_INITIAL_STATE.addLmsQuizResponse.data
        },
        resetAddLmsQuizResponse: (state) => {
            state.addLmsQuizResponse = LMS_QUIZ_INITIAL_STATE.addLmsQuizResponse
        },

        setLmsQuizResponseListLoading: (state, { payload }) => {
            state.lmsQuizResponseList.isLoading = payload
        },
        setLmsQuizResponseListData: (state, { payload }) => {
            state.lmsQuizResponseList.data = payload
            state.lmsQuizResponseList.message = LMS_QUIZ_INITIAL_STATE.lmsQuizResponseList.message
        },
        setLmsQuizResponseListMessage: (state, { payload }) => {
            state.lmsQuizResponseList.message = payload
            state.lmsQuizResponseList.data = LMS_QUIZ_INITIAL_STATE.lmsQuizResponseList.data
        },
        resetLmsQuizResponseList: (state) => {
            state.lmsQuizResponseList = LMS_QUIZ_INITIAL_STATE.lmsQuizResponseList
        },

        setLmsQuizResponseLoading: (state, { payload }) => {
            state.lmsQuizResponse.isLoading = payload
        },
        setLmsQuizResponseData: (state, { payload }) => {
            state.lmsQuizResponse.data = payload
            state.lmsQuizResponse.message = LMS_QUIZ_INITIAL_STATE.lmsQuizResponse.message
        },
        setLmsQuizResponseMessage: (state, { payload }) => {
            state.lmsQuizResponse.message = payload
            state.lmsQuizResponse.data = LMS_QUIZ_INITIAL_STATE.lmsQuizResponse.data
        },
        resetLmsQuizResponse: (state) => {
            state.lmsQuizResponse = LMS_QUIZ_INITIAL_STATE.lmsQuizResponse
        },

        setUpdateLmsQuizResponseLoading: (state, { payload }) => {
            state.updateLmsQuizResponse.isLoading = payload
        },
        setUpdateLmsQuizResponseData: (state, { payload }) => {
            state.updateLmsQuizResponse.data = payload
            state.updateLmsQuizResponse.message = LMS_QUIZ_INITIAL_STATE.updateLmsQuizResponse.message
        },
        setUpdateLmsQuizResponseMessage: (state, { payload }) => {
            state.updateLmsQuizResponse.message = payload
            state.updateLmsQuizResponse.data = LMS_QUIZ_INITIAL_STATE.updateLmsQuizResponse.data
        },
        resetUpdateLmsQuizResponse: (state) => {
            state.updateLmsQuizResponse = LMS_QUIZ_INITIAL_STATE.updateLmsQuizResponse
        },

        setDeleteLmsQuizResponseLoading: (state, { payload }) => {
            state.deleteLmsQuizResponse.isLoading = payload
        },
        setDeleteLmsQuizResponseMessage: (state, { payload }) => {
            state.deleteLmsQuizResponse.message = payload
        },
        resetDeleteLmsQuizResponse: (state) => {
            state.deleteLmsQuizResponse = LMS_QUIZ_INITIAL_STATE.deleteLmsQuizResponse
        },

        setAddLmsQuizQuestionResponseLoading: (state, { payload }) => {
            state.addLmsQuizQuestionResponse.isLoading = payload
        },
        setAddLmsQuizQuestionResponseData: (state, { payload }) => {
            state.addLmsQuizQuestionResponse.data = payload
            state.addLmsQuizQuestionResponse.message = LMS_QUIZ_INITIAL_STATE.addLmsQuizQuestionResponse.message
        },
        setAddLmsQuizQuestionResponseMessage: (state, { payload }) => {
            state.addLmsQuizQuestionResponse.message = payload
            state.addLmsQuizQuestionResponse.data = LMS_QUIZ_INITIAL_STATE.addLmsQuizQuestionResponse.data
        },
        resetAddLmsQuizQuestionResponse: (state) => {
            state.addLmsQuizQuestionResponse = LMS_QUIZ_INITIAL_STATE.addLmsQuizQuestionResponse
        },

        setLmsQuizQuestionResponseListLoading: (state, { payload }) => {
            state.lmsQuizQuestionResponseList.isLoading = payload
        },
        setLmsQuizQuestionResponseListData: (state, { payload }) => {
            state.lmsQuizQuestionResponseList.data = payload
            state.lmsQuizQuestionResponseList.message = LMS_QUIZ_INITIAL_STATE.lmsQuizQuestionResponseList.message
        },
        setLmsQuizQuestionResponseListMessage: (state, { payload }) => {
            state.lmsQuizQuestionResponseList.message = payload
            state.lmsQuizQuestionResponseList.data = LMS_QUIZ_INITIAL_STATE.lmsQuizQuestionResponseList.data
        },
        resetLmsQuizQuestionResponseList: (state) => {
            state.lmsQuizQuestionResponseList = LMS_QUIZ_INITIAL_STATE.lmsQuizQuestionResponseList
        },

        setLmsQuizQuestionResponseLoading: (state, { payload }) => {
            state.lmsQuizQuestionResponse.isLoading = payload
        },
        setLmsQuizQuestionResponseData: (state, { payload }) => {
            state.lmsQuizQuestionResponse.data = payload
            state.lmsQuizQuestionResponse.message = LMS_QUIZ_INITIAL_STATE.lmsQuizQuestionResponse.message
        },
        setLmsQuizQuestionResponseMessage: (state, { payload }) => {
            state.lmsQuizQuestionResponse.message = payload
            state.lmsQuizQuestionResponse.data = LMS_QUIZ_INITIAL_STATE.lmsQuizQuestionResponse.data
        },
        resetLmsQuizQuestionResponse: (state) => {
            state.lmsQuizQuestionResponse = LMS_QUIZ_INITIAL_STATE.lmsQuizQuestionResponse
        },

        setUpdateLmsQuizQuestionResponseLoading: (state, { payload }) => {
            state.updateLmsQuizQuestionResponse.isLoading = payload
        },
        setUpdateLmsQuizQuestionResponseData: (state, { payload }) => {
            state.updateLmsQuizQuestionResponse.data = payload
            state.updateLmsQuizQuestionResponse.message = LMS_QUIZ_INITIAL_STATE.updateLmsQuizQuestionResponse.message
        },
        setUpdateLmsQuizQuestionResponseMessage: (state, { payload }) => {
            state.updateLmsQuizQuestionResponse.message = payload
            state.updateLmsQuizQuestionResponse.data = LMS_QUIZ_INITIAL_STATE.updateLmsQuizQuestionResponse.data
        },
        resetUpdateLmsQuizQuestionResponse: (state) => {
            state.updateLmsQuizQuestionResponse = LMS_QUIZ_INITIAL_STATE.updateLmsQuizQuestionResponse
        },

        setDeleteLmsQuizQuestionResponseLoading: (state, { payload }) => {
            state.deleteLmsQuizQuestionResponse.isLoading = payload
        },
        setDeleteLmsQuizQuestionResponseMessage: (state, { payload }) => {
            state.deleteLmsQuizQuestionResponse.message = payload
        },
        resetDeleteLmsQuizQuestionResponse: (state) => {
            state.deleteLmsQuizQuestionResponse = LMS_QUIZ_INITIAL_STATE.deleteLmsQuizQuestionResponse
        },

        setAddLmsQuizQuestionResponseResourceLoading: (state, { payload }) => {
            state.addLmsQuizQuestionResponseResource.isLoading = payload
        },
        setAddLmsQuizQuestionResponseResourceData: (state, { payload }) => {
            state.addLmsQuizQuestionResponseResource.data = payload
            state.addLmsQuizQuestionResponseResource.message = LMS_QUIZ_INITIAL_STATE.addLmsQuizQuestionResponseResource.message
        },
        setAddLmsQuizQuestionResponseResourceMessage: (state, { payload }) => {
            state.addLmsQuizQuestionResponseResource.message = payload
            state.addLmsQuizQuestionResponseResource.data = LMS_QUIZ_INITIAL_STATE.addLmsQuizQuestionResponseResource.data
        },
        resetAddLmsQuizQuestionResponseResource: (state) => {
            state.addLmsQuizQuestionResponseResource = LMS_QUIZ_INITIAL_STATE.addLmsQuizQuestionResponseResource
        },

        setLmsQuizQuestionResponseResourceLoading: (state, { payload }) => {
            state.lmsQuizQuestionResponseResource.isLoading = payload
        },
        setLmsQuizQuestionResponseResourceData: (state, { payload }) => {
            state.lmsQuizQuestionResponseResource.data = payload
            state.lmsQuizQuestionResponseResource.message = LMS_QUIZ_INITIAL_STATE.lmsQuizQuestionResponseResource.message
        },
        setLmsQuizQuestionResponseResourceMessage: (state, { payload }) => {
            state.lmsQuizQuestionResponseResource.message = payload
            state.lmsQuizQuestionResponseResource.datq = LMS_QUIZ_INITIAL_STATE.lmsQuizQuestionResponseResource.data
        },
        resetLmsQuizQuestionResponseResource: (state) => {
            state.lmsQuizQuestionResponseResource = LMS_QUIZ_INITIAL_STATE.lmsQuizQuestionResponseResource
        },

        setLmsQuizQuestionResponseResourceListLoading: (state, { payload }) => {
            state.lmsQuizQuestionResponseResourceList.isLoading = payload
        },
        setLmsQuizQuestionResponseResourceListMessage: (state, { payload }) => {
            state.lmsQuizQuestionResponseResourceList.message = payload
            state.lmsQuizQuestionResponseResourceList.data = LMS_QUIZ_INITIAL_STATE.lmsQuizQuestionResponseResourceList.data
        },
        setLmsQuizQuestionResponseResourceListData: (state, { payload }) => {
            state.lmsQuizQuestionResponseResourceList.data = payload
            state.lmsQuizQuestionResponseResourceList.message = LMS_QUIZ_INITIAL_STATE.lmsQuizQuestionResponseResourceList.message
        },
        resetLmsQuizQuestionResponseResourceList: (state) => {
            state.lmsQuizQuestionResponseResourceList = LMS_QUIZ_INITIAL_STATE.lmsQuizQuestionResponseResourceList
        },

        setUpdateLmsQuizQuestionResponseResourceLoading: (state, { payload }) => {
            state.updateLmsQuizQuestionResponseResource.isLoading = payload
        },
        setUpdateLmsQuizQuestionResponseResourceData: (state, { payload }) => {
            state.updateLmsQuizQuestionResponseResource.data = payload
            state.updateLmsQuizQuestionResponseResource.message = LMS_QUIZ_INITIAL_STATE.updateLmsQuizQuestionResponseResource.message
        },
        setUpdateLmsQuizQuestionResponseResourceMessage: (state, { payload }) => {
            state.updateLmsQuizQuestionResponseResource.message = payload
            state.updateLmsQuizQuestionResponseResource.data = LMS_QUIZ_INITIAL_STATE.updateLmsQuizQuestionResponseResource.data
        },
        resetUpdateLmsQuizQuestionResponseResource: (state) => {
            state.updateLmsQuizQuestionResponseResource = LMS_QUIZ_INITIAL_STATE.updateLmsQuizQuestionResponseResource
        },

        setDeleteLmsQuizQuestionResponseResourceLoading: (state, { payload }) => {
            state.deleteLmsQuizQuestionResponseResource.isLoading = payload
        },

        setDeleteLmsQuizQuestionResponseResourceMessage: (state, { payload }) => {
            state.deleteLmsQuizQuestionResponseResource.message = payload
        },
        resetDeleteLmsQuizQuestionResponseResource: (state) => {
            state.deleteLmsQuizQuestionResponseResource = LMS_QUIZ_INITIAL_STATE.deleteLmsQuizQuestionResponseResource
        },

        // add lms quiz slug detail
        setAddLmsQuizSlugDetailLoading: (state, { payload }) => {
            state.addLmsQuizSlugDetail.isLoading = payload
        },
        setAddLmsQuizSlugDetailMessage: (state, { payload }) => {
            state.addLmsQuizSlugDetail.message = payload
        },
        resetAddLmsQuizSlugDetail: (state) => {
            state.addLmsQuizSlugDetail = LMS_QUIZ_INITIAL_STATE.addLmsQuizSlugDetail
        },

        // destroy lms quiz slug detail
        setDestroyLmsQuizSlugDetailLoading: (state, { payload }) => {
            state.destroyLmsQuizSlugDetail.isLoading = payload
        },
        setDestroyLmsQuizSlugDetailMessage: (state, { payload }) => {
            state.destroyLmsQuizSlugDetail.message = payload
        },
        resetDestroyLmsQuizSlugDetail: (state) => {
            state.destroyLmsQuizSlugDetail = LMS_QUIZ_INITIAL_STATE.destroyLmsQuizSlugDetail
        },

        // lms quiz instructor detail
        // add lms quiz instructor detail
        setAddLmsQuizInstructorDetailLoading: (state, { payload }) => {
            state.addLmsQuizInstructorDetail.isLoading = payload
        },
        setAddLmsQuizInstructorDetailData: (state, { payload }) => {
            state.addLmsQuizInstructorDetail.data = payload
            state.addLmsQuizInstructorDetail.message = LMS_QUIZ_INITIAL_STATE.addLmsQuizInstructorDetail.message
        },
        setAddLmsQuizInstructorDetailMessage: (state, { payload }) => {
            state.addLmsQuizInstructorDetail.message = payload
            state.addLmsQuizInstructorDetail.data = LMS_QUIZ_INITIAL_STATE.addLmsQuizInstructorDetail.data
        },
        resetAddLmsQuizInstructorDetail: (state) => {
            state.addLmsQuizInstructorDetail = LMS_QUIZ_INITIAL_STATE.addLmsQuizInstructorDetail
        },

        // lms quiz instructor detail payload
        setAddLmsQuizInstructorDetailPayload: (state, { payload }) => {
            state.addLmsQuizInstructorDetail.payload = payload
        },
        resetAddLmsQuizInstructorDetailPayload: (state) => {
            state.addLmsQuizInstructorDetail.payload = LMS_QUIZ_INITIAL_STATE.addLmsQuizInstructorDetail.payload
        },

        // modify lms quiz instructor detail
        setModifyLmsQuizInstructorDetailLoading: (state, { payload }) => {
            state.modifyLmsQuizInstructorDetail.isLoading = payload
        },
        setModifyLmsQuizInstructorDetailData: (state, { payload }) => {
            state.modifyLmsQuizInstructorDetail.data = payload
            state.modifyLmsQuizInstructorDetail.message = LMS_QUIZ_INITIAL_STATE.modifyLmsQuizInstructorDetail.message
        },
        setModifyLmsQuizInstructorDetailMessage: (state, { payload }) => {
            state.modifyLmsQuizInstructorDetail.message = payload
            state.modifyLmsQuizInstructorDetail.data = LMS_QUIZ_INITIAL_STATE.modifyLmsQuizInstructorDetail.data
        },
        resetModifyLmsQuizInstructorDetail: (state) => {
            state.modifyLmsQuizInstructorDetail = LMS_QUIZ_INITIAL_STATE.modifyLmsQuizInstructorDetail
        },

        // destroy lms quiz instructor detail
        setDestroyLmsQuizInstructorDetailLoading: (state, { payload }) => {
            state.destroyLmsQuizInstructorDetail.isLoading = payload
        },
        setDestroyLmsQuizInstructorDetailMessage: (state, { payload }) => {
            state.destroyLmsQuizInstructorDetail.message = payload
        },
        resetDestroyLmsQuizInstructorDetail: (state) => {
            state.destroyLmsQuizInstructorDetail = LMS_QUIZ_INITIAL_STATE.destroyLmsQuizInstructorDetail
        },

        // student lms quiz list
        setStudentLmsQuizListLoading: (state, {payload}) => {
            state.studentLmsQuizList.isLoading = payload
        },
        setStudentLmsQuizListData: (state, {payload}) => {
            state.studentLmsQuizList.data = payload
            state.studentLmsQuizList.message = LMS_QUIZ_INITIAL_STATE.studentLmsQuizList.message
        },
        setStudentLmsQuizListMessage: (state, {payload}) => {
            state.studentLmsQuizList.message = payload
            state.studentLmsQuizList.data = LMS_QUIZ_INITIAL_STATE.studentLmsQuizList.data
        },
        resetStudentLmsQuizList: (state) => {
            state.studentLmsQuizList = LMS_QUIZ_INITIAL_STATE.studentLmsQuizList
        },
        
        // student lms quiz detail
        setStudentLmsQuizDetailLoading: (state, {payload}) => {
            state.studentLmsQuizDetail.isLoading = payload
        },
        setStudentLmsQuizDetailData: (state, {payload}) => {
            state.studentLmsQuizDetail.data = payload
            state.studentLmsQuizDetail.message = LMS_QUIZ_INITIAL_STATE.studentLmsQuizDetail.message
        },
        setStudentLmsQuizDetailMessage: (state, {payload}) => {
            state.studentLmsQuizDetail.message = payload
            state.studentLmsQuizDetail.data = LMS_QUIZ_INITIAL_STATE.studentLmsQuizDetail.data
        },
        resetStudentLmsQuizDetail: (state) => {
            state.studentLmsQuizDetail = LMS_QUIZ_INITIAL_STATE.studentLmsQuizDetail
        },


        /* lms quiz student quiz response */
        // add
        setAddLmsQuizStudentQuizResponseLoading: (state, { payload }) => {
            state.addLmsQuizStudentQuizResponse.isLoading = payload
        },
        setAddLmsQuizStudentQuizResponseData: (state, { payload }) => {
            state.addLmsQuizStudentQuizResponse.data = payload
            state.addLmsQuizStudentQuizResponse.message = LMS_QUIZ_INITIAL_STATE.addLmsQuizStudentQuizResponse.message
        },
        setAddLmsQuizStudentQuizResponseMessage: (state, { payload }) => {
            state.addLmsQuizStudentQuizResponse.message = payload
            state.addLmsQuizStudentQuizResponse.data = LMS_QUIZ_INITIAL_STATE.addLmsQuizStudentQuizResponse.data
        },
        resetAddLmsQuizStudentQuizResponse: (state) => {
            state.addLmsQuizStudentQuizResponse = LMS_QUIZ_INITIAL_STATE.addLmsQuizStudentQuizResponse
        },
        // modify
        setModifyLmsQuizStudentQuizResponseLoading: (state, { payload }) => {
            state.modifyLmsQuizStudentQuizResponse.isLoading = payload
        },
        setModifyLmsQuizStudentQuizResponseMessage: (state, { payload }) => {
            state.modifyLmsQuizStudentQuizResponse.message = payload
        },
        resetModifyLmsQuizStudentQuizResponse: (state) => {
            state.modifyLmsQuizStudentQuizResponse = LMS_QUIZ_INITIAL_STATE.modifyLmsQuizStudentQuizResponse
        },

        // lms quiz student detail
        // get lms quiz student list
        setLmsQuizStudentListLoading: (state, { payload }) => {
            state.lmsQuizStudentList.isLoading = payload
        },
        setLmsQuizStudentListData: (state, { payload }) => {
            state.lmsQuizStudentList.data = payload
            state.lmsQuizStudentList.message = LMS_QUIZ_INITIAL_STATE.lmsQuizStudentList.message
        },
        setLmsQuizStudentListMessage: (state, { payload }) => {
            state.lmsQuizStudentList.message = payload
            state.lmsQuizStudentList.data = LMS_QUIZ_INITIAL_STATE.lmsQuizStudentList.data
        },
        resetLmsQuizStudentList: (state) => {
            state.lmsQuizStudentList = LMS_QUIZ_INITIAL_STATE.lmsQuizStudentList
        },

        // add lms quiz Student detail
        setAddLmsQuizStudentDetailLoading: (state, { payload }) => {
            state.addLmsQuizStudentDetail.isLoading = payload
        },
        setAddLmsQuizStudentDetailMessage: (state, { payload }) => {
            state.addLmsQuizStudentDetail.message = payload
        },
        resetAddLmsQuizStudentDetail: (state) => {
            state.addLmsQuizStudentDetail = LMS_QUIZ_INITIAL_STATE.addLmsQuizStudentDetail
        },

        // modified lms course Student detail
        setModifyLmsQuizStudentDetailLoading: (state, { payload }) => {
            state.modifyLmsQuizStudentDetail.isLoading = payload
        },
        setModifyLmsQuizStudentDetailMessage: (state, { payload }) => {
            state.modifyLmsQuizStudentDetail.message = payload
        },
        resetModifyLmsQuizStudentDetail: (state) => {
            state.modifyLmsQuizStudentDetail = LMS_QUIZ_INITIAL_STATE.modifyLmsQuizStudentDetail
        },

        // delete lms course Student detail
        setDestroyLmsQuizStudentDetailLoading: (state, { payload }) => {
            state.destroyLmsQuizStudentDetail.isLoading = payload
        },
        setDestroyLmsQuizStudentDetailMessage: (state, { payload }) => {
            state.destroyLmsQuizStudentDetail.message = payload
        },
        resetDestroyLmsQuizStudentDetail: (state) => {
            state.destroyLmsQuizStudentDetail = LMS_QUIZ_INITIAL_STATE.destroyLmsQuizStudentDetail
        },

        // lms quiz invitation
        setLmsQuizInvitationListLoading: (state, { payload }) => {
            state.lmsQuizInvitationList.isLoading = payload
        },
        setLmsQuizInvitationListData: (state, { payload }) => {
            state.lmsQuizInvitationList.data = payload
            state.lmsQuizInvitationList.message = LMS_QUIZ_INITIAL_STATE.lmsQuizInvitationList.message
        },
        setLmsQuizInvitationListMessage: (state, { payload }) => {
            state.lmsQuizInvitationList.message = payload
            state.lmsQuizInvitationList.data = LMS_QUIZ_INITIAL_STATE.lmsQuizInvitationList.data
        },
        resetLmsQuizInvitationList: (state) => {
            state.lmsQuizInvitationList = LMS_QUIZ_INITIAL_STATE.lmsQuizInvitationList
        },

        // add lms quiz invitation detail
        setSendLmsQuizInvitationDetailLoading: (state, { payload }) => {
            state.sendLmsQuizInvitationDetail.isLoading = payload
        },
        setSendLmsQuizInvitationDetailData: (state, { payload }) => {
            state.sendLmsQuizInvitationDetail.data = payload
            state.sendLmsQuizInvitationDetail.message = LMS_QUIZ_INITIAL_STATE.sendLmsQuizInvitationDetail.message
        },
        setSendLmsQuizInvitationDetailMessage: (state, { payload }) => {
            state.sendLmsQuizInvitationDetail.message = payload
            state.sendLmsQuizInvitationDetail.data = LMS_QUIZ_INITIAL_STATE.sendLmsQuizInvitationDetail.data
        },
        resetSendLmsQuizInvitationDetail: (state) => {
            state.sendLmsQuizInvitationDetail = LMS_QUIZ_INITIAL_STATE.sendLmsQuizInvitationDetail
        },

        // send lms quiz invitation payload
        setSendLmsQuizInvitationDetailPayload: (state, { payload }) => {
            state.sendLmsQuizInvitationDetail.payload = payload
        },
        resetSendLmsQuizInvitationDetailPayload: (state) => {
            state.sendLmsQuizInvitationDetail.payload = LMS_QUIZ_INITIAL_STATE.sendLmsQuizInvitationDetail.payload
        },

        // Validate LmsQuiz Invitation Detail
        setValidateLmsQuizInvitationDetailLoading: ( state, {payload}) => {
            state.validateLmsQuizInvitationDetail.isLoading = payload
        },
        setValidateLmsQuizInvitationDetailData: (state, { payload }) => {
            state.validateLmsQuizInvitationDetail.data = payload
        },

        setValidateLmsQuizInvitationDetailMessage: (state, { payload }) => {
            state.validateLmsQuizInvitationDetail.message = payload
        },
        
        resetValidateLmsQuizInvitationDetail: (state, { payload }) => {
            state.validateLmsQuizInvitationDetail = LMS_QUIZ_INITIAL_STATE.validateLmsQuizInvitationDetail
        },

        // Accept or Decline LmsQuiz Invitation Detail
        setAcceptAndDeclineLmsQuizInvitationDetailLoading: ( state, {payload}) => {
            state.acceptAndDeclineLmsQuizInvitationDetail.isLoading = payload
        },
        setAcceptAndDeclineLmsQuizInvitationDetailData: (state, { payload }) => {
            state.acceptAndDeclineLmsQuizInvitationDetail.data = payload
        },

        setAcceptAndDeclineLmsQuizInvitationDetailMessage: (state, { payload }) => {
            state.acceptAndDeclineLmsQuizInvitationDetail.message = payload
        },
        
        resetAcceptAndDeclineLmsQuizInvitationDetail: (state, { payload }) => {
            state.acceptAndDeclineLmsQuizInvitationDetail = LMS_QUIZ_INITIAL_STATE.acceptAndDeclineLmsQuizInvitationDetail
        },

        // destroy lms quiz invitation detail
        setDestroyLmsQuizInvitationDetailLoading: (state, { payload }) => {
            state.destroyLmsQuizInvitationDetail.isLoading = payload
        },
        setDestroyLmsQuizInvitationDetailMessage: (state, { payload }) => {
            state.destroyLmsQuizInvitationDetail.message = payload
        },
        resetDestroyLmsQuizInvitationDetail: (state) => {
            state.destroyLmsQuizInvitationDetail = LMS_QUIZ_INITIAL_STATE.destroyLmsQuizInvitationDetail
        },

        // page based reducers
        setLmsQuizPageQuestionListLoading: (state, { payload }) => {
            state.lmsQuizPageQuestionList.isLoading = payload
        },
        setLmsQuizPageQuestionListData: (state, { payload }) => {
            state.lmsQuizPageQuestionList.data = payload
            state.lmsQuizPageQuestionList.message = LMS_QUIZ_INITIAL_STATE.lmsQuizPageQuestionList.message
        },
        setLmsQuizPageQuestionListMessage: (state, { payload }) => {
            state.lmsQuizPageQuestionList.message = payload
            state.lmsQuizPageQuestionList.data = LMS_QUIZ_INITIAL_STATE.lmsQuizPageQuestionList.data
        },
        resetLmsQuizPageQuestionList: (state) => {
            state.lmsQuizPageQuestionList = LMS_QUIZ_INITIAL_STATE.lmsQuizPageQuestionList
        },

        //  quiz page article list
        setLmsQuizPageArticleListLoading: (state, { payload }) => {
            state.lmsQuizPageArticleList.isLoading = payload
        },
        setLmsQuizPageArticleListData: (state, { payload }) => {
            state.lmsQuizPageArticleList.data = payload
            state.lmsQuizPageArticleList.message = LMS_QUIZ_INITIAL_STATE.lmsQuizPageArticleList.message
        },
        setLmsQuizPageArticleListMessage: (state, { payload }) => {
            state.lmsQuizPageArticleList.message = payload
            state.lmsQuizPageArticleList.data = LMS_QUIZ_INITIAL_STATE.lmsQuizPageArticleList.data
        },
        resetLmsQuizPageArticleList: (state) => {
            state.lmsQuizPageArticleList = LMS_QUIZ_INITIAL_STATE.lmsQuizPageArticleList
        },

        //  quiz page video resource list
        setLmsQuizPageVideoResourceListLoading: (state, { payload }) => {
            state.lmsQuizPageVideoResourceList.isLoading = payload
        },
        setLmsQuizPageVideoResourceListData: (state, { payload }) => {
            state.lmsQuizPageVideoResourceList.data = payload
            state.lmsQuizPageVideoResourceList.message = LMS_QUIZ_INITIAL_STATE.lmsQuizPageVideoResourceList.message
        },
        setLmsQuizPageVideoResourceListMessage: (state, { payload }) => {
            state.lmsQuizPageVideoResourceList.message = payload
            state.lmsQuizPageVideoResourceList.data = LMS_QUIZ_INITIAL_STATE.lmsQuizPageVideoResourceList.data
        },
        resetLmsQuizPageVideoResourceList: (state) => {
            state.lmsQuizPageVideoResourceList = LMS_QUIZ_INITIAL_STATE.lmsQuizPageVideoResourceList
        },

        //  quiz page image resource list
        setLmsQuizPageImageResourceListLoading: (state, { payload }) => {
            state.lmsQuizPageImageResourceList.isLoading = payload
        },
        setLmsQuizPageImageResourceListData: (state, { payload }) => {
            state.lmsQuizPageImageResourceList.data = payload
            state.lmsQuizPageImageResourceList.message = LMS_QUIZ_INITIAL_STATE.lmsQuizPageImageResourceList.message
        },
        setLmsQuizPageImageResourceListMessage: (state, { payload }) => {
            state.lmsQuizPageImageResourceList.message = payload
            state.lmsQuizPageImageResourceList.data = LMS_QUIZ_INITIAL_STATE.lmsQuizPageImageResourceList.data
        },
        resetLmsQuizPageImageResourceList: (state) => {
            state.lmsQuizPageImageResourceList = LMS_QUIZ_INITIAL_STATE.lmsQuizPageImageResourceList
        },
    }
})

export const {
    setLmsQuizListData,
    setLmsQuizListLoading,
    setLmsQuizListMessage,
    resetLmsQuizList,

    setUpdateLmsQuizData,
    setUpdateLmsQuizLoading,
    setUpdateLmsQuizMessage,
    resetUpdateLmsQuiz,

    setLmsAiQuizDetailLoading,
    setLmsAiQuizDetailData,
    setLmsAiQuizDetailMessage,
    resetLmsAiQuizDetail,

    setLmsQuizDetailLoading,
    setLmsQuizDetailData,
    setLmsQuizDetailMessage,
    resetLmsQuizDetail,

    setAddLmsQuizData,
    setAddLmsQuizLoading,
    setAddLmsQuizMessage,
    resetAddLmsQuiz,
    setAddLmsQuizSectionPayload,
    resetAddLmsQuizSectionPayload,

    setAddLmsQuizSectionLoading,
    setAddLmsQuizSectionData,
    setAddLmsQuizSectionMessage,
    resetAddLmsQuizSectionData,
    resetAddLmsQuizSection,

    setLmsQuizSectionData,
    setLmsQuizSectionLoading,
    setLmsQuizSectionMessage,
    resetLmsQuizSection,

    setLmsQuizSectionListData,
    setLmsQuizSectionListLoading,
    setLmsQuizSectionListMessage,
    resetLmsQuizSectionList,

    setUpdateLmsQuizSectionLoading,
    setUpdateLmsQuizSectionData,
    setUpdateLmsQuizSectionMessage,
    resetUpdateLmsQuizSection,

    setDeleteLmsQuizSectionLoading,
    setDeleteLmsQuizSectionMessage,
    resetDeleteLmsQuizSection,

    setUpdateOrderLmsQuizSectionLoading,
    setUpdateOrderLmsQuizSectionData,
    setUpdateOrderLmsQuizSectionMessage,
    resetUpdateOrderLmsQuizSection,

    setAddLmsQuizCategoryData,
    setAddLmsQuizCategoryLoading,
    setAddLmsQuizCategoryMessage,
    resetAddLmsQuizCategory,

    setLmsQuizCategoryData,
    setLmsQuizCategoryMessage,
    setLmsQuizCategoryLoading,
    resetLmsQuizCategory,

    setLmsQuizCategoryListData,
    setLmsQuizCategoryListMessage,
    setLmsQuizCategoryListLoading,
    resetLmsQuizCategoryList,

    setUpdateLmsQuizCategoryData,
    setUpdateLmsQuizCategoryMessage,
    setUpdateLmsQuizCategoryLoading,
    resetUpdateLmsQuizCategory,

    setDeleteLmsQuizCategoryLoading,
    setDeleteLmsQuizCategoryMessage,
    resetDeleteLmsQuizCategory,

    setAddLmsQuizTagDetailLoading,
    setAddLmsQuizTagDetailMessage,
    resetAddLmsQuizTagDetail,

    setModifyLmsQuizTagListOrderLoading,
    setModifyLmsQuizTagListOrderMessage,
    resetModifyLmsQuizTagListOrder,

    setDestroyLmsQuizTagDetailLoading,
    setDestroyLmsQuizTagDetailMessage,
    resetDestroyLmsQuizTagDetail,

    setAddLmsQuizSettingData,
    setAddLmsQuizSettingLoading,
    setAddLmsQuizSettingMessage,
    resetAddLmsQuizSetting,

    setLmsQuizSettingData,
    setLmsQuizSettingMessage,
    setLmsQuizSettingLoading,
    resetLmsQuizSetting,
    setLmsQuizSettingPayload,
    resetLmsQuizSettingPayload,

    setLmsQuizSettingListData,
    setLmsQuizSettingListLoading,
    setLmsQuizSettingListMessage,
    resetLmsQuizSettingList,

    setUpdateLmsQuizSettingData,
    setUpdateLmsQuizSettingLoading,
    setUpdateLmsQuizSettingMessage,
    resetUpdateLmsQuizSetting,

    setDeleteLmsQuizSettingLoading,
    setDeleteLmsQuizSettingMessage,
    resetDeleteLmsQuizSetting,

    setAddLmsQuizUserData,
    setAddLmsQuizUserLoading,
    setAddLmsQuizUserMessage,
    resetAddLmsQuizUser,

    setDeleteLmsQuizUserLoading,
    setDeleteLmsQuizUserMessage,
    resetDeleteLmsQuizUser,

    setLmsQuizUserData,
    setLmsQuizUserLoading,
    setLmsQuizUserMessage,
    resetLmsQuizUser,

    setLmsQuizUserListData,
    setLmsQuizUserListLoading,
    setLmsQuizUserListMessage,
    resetLmsQuizUserList,

    setUpdateLmsQuizUserData,
    setUpdateLmsQuizUserLoading,
    setUpdateLmsQuizUserMessage,
    resetUpdateLmsQuizUser,

    setAddLmsQuizResponseLoading,
    setAddLmsQuizResponseData,
    setAddLmsQuizResponseMessage,
    resetAddLmsQuizResponse,

    setLmsQuizResponseListLoading,
    setLmsQuizResponseListMessage,
    setLmsQuizResponseListData,
    resetLmsQuizResponseList,

    setLmsQuizResponseLoading,
    setLmsQuizResponseMessage,
    setLmsQuizResponseData,
    resetLmsQuizResponse,

    setUpdateLmsQuizResponseLoading,
    setUpdateLmsQuizResponseMessage,
    setUpdateLmsQuizResponseData,
    resetUpdateLmsQuizResponse,

    setDeleteLmsQuizResponseLoading,
    setDeleteLmsQuizResponseMessage,
    resetDeleteLmsQuizResponse,

    setAddLmsQuizQuestionResponseData,
    setAddLmsQuizQuestionResponseLoading,
    setAddLmsQuizQuestionResponseMessage,
    resetAddLmsQuizQuestionResponse,

    setLmsQuizQuestionResponseListLoading,
    setLmsQuizQuestionResponseListMessage,
    setLmsQuizQuestionResponseListData,
    resetLmsQuizQuestionResponseList,

    setLmsQuizQuestionResponseData,
    setLmsQuizQuestionResponseLoading,
    setLmsQuizQuestionResponseMessage,
    resetLmsQuizQuestionResponse,

    setUpdateLmsQuizQuestionResponseLoading,
    setUpdateLmsQuizQuestionResponseData,
    setUpdateLmsQuizQuestionResponseMessage,
    resetUpdateLmsQuizQuestionResponse,

    setDeleteLmsQuizQuestionResponseLoading,
    setDeleteLmsQuizQuestionResponseMessage,
    resetDeleteLmsQuizQuestionResponse,

    setAddLmsQuizQuestionResponseResourceLoading,
    setAddLmsQuizQuestionResponseResourceData,
    setAddLmsQuizQuestionResponseResourceMessage,
    resetAddLmsQuizQuestionResponseResource,

    setLmsQuizQuestionResponseResourceListLoading,
    setLmsQuizQuestionResponseResourceListData,
    setLmsQuizQuestionResponseResourceListMessage,
    resetLmsQuizQuestionResponseResourceList,

    setLmsQuizQuestionResponseResourceData,
    setLmsQuizQuestionResponseResourceLoading,
    setLmsQuizQuestionResponseResourceMessage,
    resetLmsQuizQuestionResponseResource,

    setUpdateLmsQuizQuestionResponseResourceData,
    setUpdateLmsQuizQuestionResponseResourceLoading,
    setUpdateLmsQuizQuestionResponseResourceMessage,
    resetUpdateLmsQuizQuestionResponseResource,

    setDeleteLmsQuizQuestionResponseResourceLoading,
    setDeleteLmsQuizQuestionResponseResourceMessage,
    resetDeleteLmsQuizQuestionResponseResource,

    // lms quiz slug detail
    setAddLmsQuizSlugDetailLoading,
    setAddLmsQuizSlugDetailMessage,
    resetAddLmsQuizSlugDetail,

    setDestroyLmsQuizSlugDetailLoading,
    setDestroyLmsQuizSlugDetailMessage,
    resetDestroyLmsQuizSlugDetail,

    // lms quiz instructor detail
    setAddLmsQuizInstructorDetailLoading,
    setAddLmsQuizInstructorDetailData,
    setAddLmsQuizInstructorDetailMessage,
    resetAddLmsQuizInstructorDetail,
    setAddLmsQuizInstructorDetailPayload,
    resetAddLmsQuizInstructorDetailPayload,

    setModifyLmsQuizInstructorDetailLoading,
    setModifyLmsQuizInstructorDetailData,
    setModifyLmsQuizInstructorDetailMessage,
    resetModifyLmsQuizInstructorDetail,

    setDestroyLmsQuizInstructorDetailLoading,
    setDestroyLmsQuizInstructorDetailMessage,
    resetDestroyLmsQuizInstructorDetail,

    // student lms quiz list
    setStudentLmsQuizListLoading,
    setStudentLmsQuizListData,
    setStudentLmsQuizListMessage,
    resetStudentLmsQuizList,

    // student lms quiz detail
    setStudentLmsQuizDetailLoading,
    setStudentLmsQuizDetailData,
    setStudentLmsQuizDetailMessage,
    resetStudentLmsQuizDetail,

    // add lms quiz student quiz response
    setAddLmsQuizStudentQuizResponseLoading,
    setAddLmsQuizStudentQuizResponseData,
    setAddLmsQuizStudentQuizResponseMessage,
    resetAddLmsQuizStudentQuizResponse,

    // modify lms quiz student quiz response
    setModifyLmsQuizStudentQuizResponseLoading,
    setModifyLmsQuizStudentQuizResponseMessage,
    resetModifyLmsQuizStudentQuizResponse,
    
    // lms quiz student
    setLmsQuizStudentListLoading,
    setLmsQuizStudentListData,
    setLmsQuizStudentListMessage,
    resetLmsQuizStudentList,
    
    setAddLmsQuizStudentDetailLoading,
    setAddLmsQuizStudentDetailMessage,
    resetAddLmsQuizStudentDetail,

    setModifyLmsQuizStudentDetailLoading,
    setModifyLmsQuizStudentDetailMessage,
    resetModifyLmsQuizStudentDetail,

    setDestroyLmsQuizStudentDetailLoading,
    setDestroyLmsQuizStudentDetailMessage,
    resetDestroyLmsQuizStudentDetail,

    // lms quiz invitation
    setLmsQuizInvitationListLoading,
    setLmsQuizInvitationListData,
    setLmsQuizInvitationListMessage,
    resetLmsQuizInvitationList,

    setSendLmsQuizInvitationDetailLoading,
    setSendLmsQuizInvitationDetailData,
    setSendLmsQuizInvitationDetailMessage,
    resetSendLmsQuizInvitationDetail,

    setSendLmsQuizInvitationDetailPayload,
    resetSendLmsQuizInvitationDetailPayload,

    setValidateLmsQuizInvitationDetailLoading,
    setValidateLmsQuizInvitationDetailData,
    setValidateLmsQuizInvitationDetailMessage,
    resetValidateLmsQuizInvitationDetail,

    setAcceptAndDeclineLmsQuizInvitationDetailData,
    setAcceptAndDeclineLmsQuizInvitationDetailLoading,
    setAcceptAndDeclineLmsQuizInvitationDetailMessage,
    resetAcceptAndDeclineLmsQuizInvitationDetail,

    setDestroyLmsQuizInvitationDetailLoading,
    setDestroyLmsQuizInvitationDetailMessage,

    setLmsQuizPageQuestionListLoading,
    setLmsQuizPageQuestionListData,
    setLmsQuizPageQuestionListMessage,
    resetLmsQuizPageQuestionList,

    setLmsQuizPageArticleListLoading,
    setLmsQuizPageArticleListData,
    setLmsQuizPageArticleListMessage,
    resetLmsQuizPageArticleList,

    setLmsQuizPageVideoResourceListLoading,
    setLmsQuizPageVideoResourceListData,
    setLmsQuizPageVideoResourceListMessage,
    resetLmsQuizPageVideoResourceList,

    setLmsQuizPageImageResourceListLoading,
    setLmsQuizPageImageResourceListData,
    setLmsQuizPageImageResourceListMessage,
    resetLmsQuizPageImageResourceList,
} = lmsQuiz.actions;

export default lmsQuiz.reducer;