import MuxPlayer from "@mux/mux-player-react";
import { cn } from "utils/cn.utils";

import { MdOutlineCloudUpload } from "react-icons/md";
import { IoMdRefresh } from "react-icons/io";
import { BsRecordCircle } from "react-icons/bs";

import { MUX_ENV_KEY } from "const/default.const";
import { motion } from "framer-motion";
import ToolTipView from "components/tooltipView";

const MuxVideoPlayer = (
    {
        streamType = "on-demand",
        thumbnailTime = "10",
        disableCookies = true,
        autoPlay = false,
        paused = true,
        muted = false,
        isButtonDisabled = false,
        isShowRefreshButton = false,
        isShowButtons = true,
        playbackId,
        handleRecordVideo,
        handleUploadVideo,
        onHandleRefresh
    }
) => {

    return (
        <div className={"flex flex-col justify-center items-center gap-3"}>
            <div className={"rounded overflow-hidden w-full sm:w-[26rem] md:w-full h-full aspect-video z-0 shadow-xl"}>
                <MuxPlayer
                    streamType={streamType}
                    // ref={videoRef}
                    style={{ height: '100%', maxWidth: '100%' }}
                    // debug={true}
                    disableCookies={disableCookies}
                    playbackId={playbackId}
                    thumbnailTime={thumbnailTime}
                    controls
                    autoPlay={autoPlay}
                    muted={muted}
                    primaryColor={"#9e9e9e"}
                    forwardSeekOffset={"10"}
                    backwardSeekOffset={"10"}
                    muxOptions={{
                        application_name: "Edulyte"
                    }}
                    metadata={{
                        envKey: MUX_ENV_KEY,
                        video_id: playbackId,
                        video_title: playbackId,
                        player_name: 'Mux Video React',
                        viewer_user_id: "user-id-007",
                    }}
                />
            </div>

            <div className={cn("flex items-center justify-center z-10",
                "bg-white border border-secondary-light overflow-hidden rounded-full shadow"
            )}>
                {isShowRefreshButton &&
                    <ToolTipView content='Please Refresh' theme="light-border">
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            transition={{ duration: 0.3 }}
                            className={cn(
                                "px-3 py-2 flex items-center justify-center font-bodyPri font-normal text-xs cursor-pointer gap-1",
                                !isButtonDisabled && "text-secondary-main hover:bg-secondary-dark hover:text-white",
                                isButtonDisabled && "text-text-500 cursor-not-allowed"
                            )}
                            onClick={onHandleRefresh}
                        >
                            <IoMdRefresh className={"text-base md:text-lg lg:text-xl"} />
                        </motion.button>
                    </ToolTipView>
                }
                {isShowButtons &&
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        transition={{ duration: 0.3 }}
                        className={cn(
                            "px-5 py-2 flex items-center justify-center font-bodyPri font-medium text-sm cursor-pointer gap-2",
                            !isButtonDisabled && "text-secondary-dark border-x border-secondary-light hover:bg-secondary-dark hover:text-text-50 cursor-pointer",
                            isButtonDisabled && "text-text-500 cursor-not-allowed"
                        )}
                        onClick={handleRecordVideo}
                    >
                        <BsRecordCircle className={"text-base md:text-lg lg:text-xl"} />
                        <span className={"whitespace-nowrap"}>
                            {"Record Video"}
                        </span>
                    </motion.button>
                }
                {isShowButtons &&
                    <ToolTipView content='Upload Video' theme="light-border">
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            transition={{ duration: 0.3 }}
                            className={cn(
                                "px-3 py-2 flex items-center justify-center font-bodyPri font-normal text-xs cursor-pointer gap-1",
                                !isButtonDisabled && "text-secondary-main hover:bg-secondary-dark hover:text-white",
                                isButtonDisabled && "text-text-500 cursor-not-allowed"
                            )}
                            onClick={handleUploadVideo}
                        >
                            <MdOutlineCloudUpload className={"text-base md:text-lg lg:text-xl"} />
                        </motion.button>
                    </ToolTipView>
                }
            </div>
        </div>
    )
}

export default MuxVideoPlayer