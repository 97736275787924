export function validateEmail(email: string) {
  // var re = /\S+@\S+\.\S+/;
  // var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validateMobileNo(mobileNo: string) {
  // var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,10}$/im;
  var re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,10}$/im;
  // var re = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1) \d{1,14 }$/;
  return re.test(mobileNo);
}

export function validatePassword(password: string) {

  const requiredChar = /.{8,20}/;
  const uppercaseChar = /(?=.*[A-Z])/;
  const lowercaseChar = /(?=.*[a-z])/;
  const digitChar = /(?=.*\d)/;
  const noSpacesChar = /(?!.*\s)/;

  // var re = (?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s)[\S]{8,20}$

  let passwordCharValid = {
    isRequiredCharValid: requiredChar.test(password),
    isUppercaseCharValid: uppercaseChar.test(password),
    isLowercaseCharValid: lowercaseChar.test(password),
    isDigitCharValid: digitChar.test(password),
    isNoSpaceCharValid: noSpacesChar.test(password),
    isPasswordValid: requiredChar.test(password) && uppercaseChar.test(password) && lowercaseChar.test(password) && digitChar.test(password) && noSpacesChar.test(password)
  }
  return passwordCharValid;
}

export function validateZipCode(zipCode: string) {
  var re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  return re.test(zipCode)
}

export function validatePostalCode(postalCode: string) {
  var re = /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/
  return re.test(postalCode)
}

export function validateSlug(slug: string) {
  var re = /^[a-z0-9]+(?:-[a-z0-9]+)*$/
  return re.test(slug)
}

export function validateZoomMeetingUrl(meetingUrl: any) {
  var re = /https:\/\/[\w-]*\.?zoom.us\/(j|my)\/[\d\w?=-]+/g
  return re.test(meetingUrl)
}

export function validateSkypeMeetingUrl(meetingUrl: any) {
  var re = /^https:\/\/join\.skype\.com\/(?:call|dialin)\/[a-zA-Z0-9-_]+\/?$/g
  return re.test(meetingUrl)
}
export function validateGoogleMeetUrl(meetingUrl: any) {
  var re = /^https:\/\/meet\.google\.com\/[a-zA-Z0-9-_]+\/?$/g
  return re.test(meetingUrl)
}