import { Bars } from "react-loader-spinner";

const FullPageLoader = ({ isLoading = false, className = "" }) => {
    return isLoading ? (
        <div className={`w-full h-screen bg-background-light ${className}`}>
            <div className={"w-full h-full flex justify-center items-center"}>
            <Bars heigth="100" width="100" color="grey" ariaLabel="loading-indicator" />
            </div>
        </div>
    ) : null
}

export default FullPageLoader