import React from "react";

//component imports
import ChatListItem from "../ChatListItem/ChatListItem";

//style imports
import { StyledChatList } from "./ChatList.styles";

export default function ChatList({ chatsToDisplay }) {
  return (
    <StyledChatList>
      {chatsToDisplay.map((chat) => {
        return <ChatListItem key={chat.id} chat={chat} />;
      })}
    </StyledChatList>
  );
}
