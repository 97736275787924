import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/todo/todo.const"


class TodoServices {

  getUserTodoList = () => {
    const response = baseAuthApiInstance().get(
      url.GET_USER_TODO_LIST
    )

    return response
  }

  addUserTodo = ({ body }) => {
    const response = baseAuthApiInstance().post(
      url.ADD_USER_TODO,
      body
    )

    return response
  }

  updateUserTodo = ({ params, body }) => {
    const response = baseAuthApiInstance().put(
      format(url.UPDATE_USER_TODO, params),
      body
    )

    return response
  }

  deleteUserTodo = ({ params }) => {
    const response = baseAuthApiInstance().delete(
      format(url.DELETE_USER_TODO, params)
    )

    return response
  }

}

export default new TodoServices();

// , { data: body }