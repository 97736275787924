import { useState, useEffect } from 'react';
import { cn } from "utils/cn.utils";

import { FaSpinner } from 'react-icons/fa';

import IsdCodeSelector from 'components/optionSelector/IsdCodeSelector';

import { viewContainerConst } from 'components/modals/needATutorModal/data';

import { getMasterCountryList } from 'redux/master/master.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setAddLeadDetailPayload } from 'redux/lead/lead.slice';
import { setClearCountryList } from 'redux/master/master.slice';

import { validateMobileNo } from 'utils/validation-functions';
import { updateLeadDetailByLeadId } from 'redux/lead/lead.request';

const Phone = ({ setStepCount, setViewContainer }) => {
    const { countryList, timeZoneCountryDetail } = useAppState(s => s.master)
    const { addLeadDetail } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()

    const [mobileNo, setMobileNo] = useState("")
    const [mobileNumberError, setMobileNumberError] = useState("")
    const [myCountry, setMyCountry] = useState({
        masterCountryId: 0,
        country: "",
        countryDomain: "",
        isdCode: ""
    })

    useEffect(() => {
        dispatcher(getMasterCountryList())

        return () => {
            dispatcher(setClearCountryList())
        }
    }, [])

    useEffect(() => {
        if (addLeadDetail?.addLeadDetail?.mobileNo) {
            setStepCount(7)
            setViewContainer(viewContainerConst.CITY.value)
        }
    }, [addLeadDetail?.addLeadDetail])

    useEffect(() => {
        if (mobileNo && !validateMobileNo(mobileNo)) {
            setMobileNumberError("Invalid Phone Number")
        } else {
            setMobileNumberError("")
        }
    }, [mobileNo])

    useEffect(() => {
        if (countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country) => (
                    country.countryDomain === "US"
                ))
            }
            setMyCountry(myLocalCountryList[0])
        }
    }, [countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

    const onSelectIsdCode = (option) => {
        const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option.value)
        setMyCountry(selectedCountry)
    }

    const onAddMobileNo = (e) => {
        if (e.keyCode === 13) {
            onHandleButton()
        }
    }

    const onHandleButton = () => {
        if (mobileNumberError || !mobileNo) return;
        if (addLeadDetail?.isLoading) return;

        dispatcher(setAddLeadDetailPayload({
            ...addLeadDetail?.addLeadDetailPayload,
            mobileNo: myCountry?.isdCode + "-" + mobileNo
        }))
        dispatcher(updateLeadDetailByLeadId(
            addLeadDetail?.addLeadDetail?.leadId,
            {
                mobileNo: myCountry?.isdCode + "-" + mobileNo
            }
        ))
    }

    return (
        <div className={"w-full flex flex-col items-start justify-start gap-10 px-2 transition ease-in-out delay-1000 duration-1000 transform"}>
            <span className={"font-bodyPri font-bold text-4xl text-text-900 tracking-wide"}>
                {"Nice, what is your phone number?"}
            </span>
            <div className={"w-full flex flex-col items-start justify-start gap-0.5"}>
                <span className={"font-bodyPri font-medium text-xl text-text-900 tracking-wide"}>
                    {"Phone Number"}
                </span>
                <div className={"w-1/2 px-2 flex justify-start items-center gap-1.5 rounded-md border-2 border-divider-medium focus:border-2 focus:border-text-400"}>
                    {countryList?.isLoading
                        && <FaSpinner className={"animate-spin text-primary-main text-lg"} />
                    }
                    {countryList?.countryList &&
                        <IsdCodeSelector
                            options={countryList?.countryList?.map((country) => ({
                                label: country?.isdCode,
                                value: country?.masterCountryId,
                                country: country
                            }))}
                            value={myCountry?.masterCountryId}
                            onChange={(option) => onSelectIsdCode(option)}
                        />
                    }
                    <input
                        type={"number"}
                        placeholder={"0123456789"}
                        className={cn(
                            "my-3 input-number-spin-none focus:outline-none",
                            "font-bodyPri font-normal text-text-800 text-lg",
                            "placeholder:text-text-500 placeholder:text-lg"
                        )}
                        autoFocus={true}
                        value={mobileNo}
                        onChange={(event) => setMobileNo(event.target.value)}
                        onKeyDown={onAddMobileNo}
                    />
                </div>
                {mobileNumberError &&
                    <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                        {mobileNumberError}
                    </span>
                }
            </div>
            <span
                className={cn(
                    "px-12 py-3 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-medium text-2xl text-text-50 bg-primary-dark",
                    "transition ease-in-out delay-100 duration-300 transform hover:scale-105"
                )}
                onClick={onHandleButton}
            >
                {"Continue"}
            </span>
            {addLeadDetail?.isLoading &&
                <div className={"flex items-center justify-center gap-2"}>
                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                    <span className={"font-bodyPri font-normal text-base text-text-700 whitespace-nowrap"}>
                        {"Please wait ..."}
                    </span>
                </div>
            }
            {addLeadDetail?.errorMsg &&
                <div className={"flex items-center justify-center font-bodyPri font-normal text-base text-red-500"}>
                    {addLeadDetail?.errorMsg}
                </div>
            }
        </div>
    )
}

export default Phone;