import { useEffect, useState } from "react";
import { cn } from "utils/cn.utils";

// import { FaSpinner } from "react-icons/fa";

// import ButtonLoader from "components/loader/ButtonLoader";

import InputPasswordBox from "components/modals/verifyMobileNoModal/InputPasswordBox";
import NewMobileNoVerificationBox from "components/modals/verifyMobileNoModal/NewMobileNoVerificationBox";
import ShowAndVerifyOtp from "components/modals/verifyMobileNoModal/ShowAndVerifyOtp";

// import { verifyAuthorizedUser } from "redux/auth/auth.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";

const VerifyMobileNoModal = () => {
    const { verifyAuth, addUserAuth } = useAppState((state) => state.auth)
    // const { user } = useAppState((state) => state.user)

    // const dispatcher = useAppDispatcher()

    const [inputPassword, setInputPassword] = useState("")
    const [isInputPasswordVerified, setIsInputPasswordVerified] = useState(false)
    const [isCodeSent, setIsCodeSent] = useState(false)

    useEffect(() => {
        if (verifyAuth?.verifyAuth?.mobileNo) {
            setIsInputPasswordVerified(true)
            setInputPassword("")
        }
    }, [verifyAuth?.verifyAuth])

    useEffect(() => {
        setInputPassword("")
        if (addUserAuth?.addUserAuthPayload?.isOnlyVerifyMobileNo) {
            setIsCodeSent(true)
        }

        return () => {
            setInputPassword("")
            setIsInputPasswordVerified(false)
            setIsCodeSent(false)
        }
    }, [addUserAuth?.addUserAuthPayload?.isOnlyVerifyMobileNo])

    // const onConfirm = () => {
    //     if (!inputPassword) {
    //         alert("Please Enter Password!")
    //         return;
    //     }
    //     dispatcher(verifyAuthorizedUser({
    //         mobileNo: user?.user?.isdCode + "-" + user?.user?.mobileNo,
    //         password: inputPassword
    //     }))
    // }

    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"min-h-[18rem] block space-y-8 pt-5"}>
                {(!isInputPasswordVerified && !isCodeSent) &&
                    <InputPasswordBox
                        inputPassword={inputPassword}
                        setInputPassword={setInputPassword}
                        setIsInputPasswordVerified={setIsInputPasswordVerified}
                    />
                }
                {(isInputPasswordVerified && !isCodeSent) &&
                    <NewMobileNoVerificationBox
                        setIsCodeSent={setIsCodeSent}
                    />
                }
                {isCodeSent &&
                    <ShowAndVerifyOtp
                        setIsCodeSent={setIsCodeSent}
                    />
                }
            </div>
            {addUserAuth?.errorMsg &&
                <div className={"flex justify-center items-center gap-1"}>
                    <span className={"w-full font-bodyPri font-bold text-red-500 text-lg whitespace-nowrap text-center"}>
                        {addUserAuth?.errorMsg}
                    </span>
                </div>
            }
            {/* {(!isInputPasswordVerified && !isCodeSent) &&
                <div className={"flex justify-end px-5"}>
                    <div
                        className={cn(
                            "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                            "font-buttons font-normal text-base",
                            "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                        )}
                        onClick={onConfirm}
                    >{verifyAuth?.isLoading &&
                        <ButtonLoader isLoading={verifyAuth?.isLoading} />
                        }
                        {!verifyAuth?.isLoading && "Confirm"}
                    </div>
                </div>
            } */}
        </div>
    )
}

export default VerifyMobileNoModal