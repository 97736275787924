import { Doughnut } from 'react-chartjs-2';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ doughnutData, options }) => {
    return (
        <Doughnut data={doughnutData} options={options}/>
    )
}

export default DoughnutChart