import { useState, useEffect, useRef } from "react";

import { cn } from "utils/cn.utils";

const FloatingLabelInput = ({
    label = "Label",
    labelItem = 0,
    type = "text",
    onChange,
    value = "",
    inputName = "",
    inputClassName = "",
    isInvalidInput = false,
    customInputContainerStyle,
    ...props
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState(value);

    const inputRef = useRef()

    useEffect(() => {
        if (value) {
            setInputValue(value)
        }
    }, [value])

    const onHandleChangeInput = (e) => {
        setInputValue(e.target.value);
        onChange(e);
    };

    const handleLabelClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    return (
        <div className="w-full flex justify-center items-center font-bodyPri">
            <div className={cn(
                "relative w-full flex justify-between items-center group h-10 px-3 text-text-900 bg-white rounded",
                "border border-divider-lightDark hover:border-primary-main font-bodyPri cursor-text",
                "focus-within:ring-2 focus-within:ring-primary-main focus-within:border-none transition duration-200",
                isInvalidInput && "focus-within:ring-red-500 border-red-500 hover:border-red-500",
                customInputContainerStyle,
            )}>
                <input
                    id={labelItem}
                    ref={inputRef}
                    type={type}
                    name={inputName}
                    className={cn("h-full w-full text-text-900 bg-transparent outline-none placeholder:text-text-500", inputClassName)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={onHandleChangeInput}
                    value={inputValue}
                    {...props}
                />
                <label
                    for={labelItem}
                    className={cn("text-opacity-75 absolute left-2.5 top-2.5 px-1 transition duration-200 text-sm",
                        (isFocused || inputValue) && "text-primary-main transform -translate-y-5 -translate-x-1 scale-90 bg-white",
                        (!isFocused && inputValue) && "text-black",
                        (!isFocused && !inputValue) && "text-text-700 cursor-text",
                        isInvalidInput && "text-red-500"
                    )}
                    onClick={handleLabelClick}
                >
                    {label}
                </label>
            </div>
        </div>
    );
};

export default FloatingLabelInput;