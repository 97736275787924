import { addProductPriceDetailPayload } from "redux/product/product.const";

export const PRODUCT_INITIAL_STATE = {
    productDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addProductDetail: {
        isLoading: false,
        data: null,
        payload: null,
        message: null
    },
    modifyProductDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    // product price detail
    addProductPriceDetail: {
        isLoading: false,
        data: null,
        payload: addProductPriceDetailPayload,
        message: null
    },
    modifyProductPriceDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    // product slug detail
    addProductSlugDetail: {
        isLoading: false,
        message: null
    },
    modifyProductSlugDetail: {
        isLoading: false,
        message: null
    }
}