import { useState, useEffect } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import RecaptchaProvider from 'provider/RecaptchaProvider';
import ModalContainer from 'components/modalContainer/ModalContainer';
import GoogleOneTapProvider from 'provider/GoogleOneTapProvider'
import NavBar from 'components/navbar/NavBar';
import SideNavBar from 'components/sideNavBar/SideNavBar';
import AdminLoginAsNavBar from 'components/adminLoginAs/AdminLoginAsNavBar';

import { PageStyle, PageContainerStyle, BarContainer } from './Router.style';
import { getMasterTimezoneDetailByTimeZone, getMasterCurrencyRateList } from "redux/master/master.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { timeZone } from "utils/dateTime.utils";

import { useTitle } from "hooks/useTitle";

const LayoutRouter = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const pageName = currentPageInfo.pageName;

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const [title, setTitle] = useTitle()

    const [visibility, setVisibility] = useState({ isVisibleSideBar: true })

    function getCookie() {

        const cookies = document.cookie.split('; ');

        const adminTokenCookie = cookies.find(cookie => cookie.startsWith('isAdmin='));

        const adminToken = !!adminTokenCookie ? adminTokenCookie.split('=')[1] : null;
        return adminToken
    }
    const adminToken = getCookie() || null

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
        setTitle({ ...title })
    }, [location.pathname]);

    useEffect(() => {
        dispatcher(getMasterCurrencyRateList())
        dispatcher(getMasterTimezoneDetailByTimeZone(timeZone))
    }, [])


    if (location?.pathname === "/") {
        window.open("https://edulyte.com", "_self")
        return null
    }

    return (
        <PageStyle>
            <RecaptchaProvider>
                <GoogleOneTapProvider>
                    <ToastContainer
                        position={"bottom-right"}
                        hideProgressBar={false}
                        autoClose={2000}
                        closeOnClick={true}
                        pauseOnFocusLoss={true}
                        pauseOnHover={true}
                        draggable={true}
                    />
                    <ModalContainer />
                    {!!adminToken && <AdminLoginAsNavBar />}
                    <BarContainer pageName={pageName}>
                        <NavBar visibility={visibility} setVisibility={setVisibility} />
                    </BarContainer>
                    <PageContainerStyle pageName={pageName}>
                        <SideNavBar visible={visibility.isVisibleSideBar} />
                        <Outlet />
                    </PageContainerStyle>
                </GoogleOneTapProvider>
            </RecaptchaProvider>
        </PageStyle>
    )
}

export default LayoutRouter;