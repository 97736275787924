import { createSlice } from "@reduxjs/toolkit";
import { LMS_COURSE_INITIAL_STATE } from "./lmsCourse.initialState";

const lmsCourse = createSlice({
    name: "Lms Course",
    initialState: LMS_COURSE_INITIAL_STATE,
    reducers: {
        setLmsCourseListLoading: (state, { payload }) => {
            state.lmsCourseList.isLoading = payload
        },
        setLmsCourseListData: (state, { payload }) => {
            state.lmsCourseList.data = payload
            state.lmsCourseList.message = LMS_COURSE_INITIAL_STATE.lmsCourseList.message
        },
        setLmsCourseListMessage: (state, { payload }) => {
            state.lmsCourseList.message = payload
            state.lmsCourseList.data = LMS_COURSE_INITIAL_STATE.lmsCourseList.data
        },
        resetLmsCourseList: (state) => {
            state.lmsCourseList = LMS_COURSE_INITIAL_STATE.lmsCourseList
        },


        /* Lms Course detail slice */
        setLmsCourseDetailLoading: (state, { payload }) => {
            state.lmsCourseDetail.isLoading = payload
        },
        setLmsCourseDetailData: (state, { payload }) => {
            state.lmsCourseDetail.data = payload
            state.lmsCourseDetail.message = LMS_COURSE_INITIAL_STATE.lmsCourseDetail.message
        },
        setLmsCourseDetailMessage: (state, { payload }) => {
            state.lmsCourseDetail.message = payload
            state.lmsCourseDetail.data = LMS_COURSE_INITIAL_STATE.lmsCourseDetail.data
        },
        resetLmsCourseDetail: (state) => {
            state.lmsCourseDetail = LMS_COURSE_INITIAL_STATE.lmsCourseDetail
        },

        /* Lms Course Detail */
        setAddLmsCourseDetailLoading: (state, { payload }) => {
            state.addLmsCourseDetail.isLoading = payload
        },
        setAddLmsCourseDetailData: (state, { payload }) => {
            state.addLmsCourseDetail.data = payload
            state.addLmsCourseDetail.message = LMS_COURSE_INITIAL_STATE.addLmsCourseDetail.message
        },
        setAddLmsCourseDetailMessage: (state, { payload }) => {
            state.addLmsCourseDetail.message = payload
            state.addLmsCourseDetail.data = LMS_COURSE_INITIAL_STATE.addLmsCourseDetail.data
        },
        resetAddLmsCourseDetail: (state) => {
            state.addLmsCourseDetail = LMS_COURSE_INITIAL_STATE.addLmsCourseDetail
        },

        // lms course detail payload
        setAddLmsCourseDetailPayload: (state, { payload }) => {
            state.addLmsCourseDetail.payload = payload
        },
        resetAddLmsCourseDetailPayload: (state) => {
            state.addLmsCourseDetail.payload = LMS_COURSE_INITIAL_STATE.addLmsCourseDetail.payload
        },

        // modify lms course detail
        setModifyLmsCourseDetailLoading: (state, { payload }) => {
            state.modifyLmsCourseDetail.isLoading = payload
        },
        setModifyLmsCourseDetailData: (state, { payload }) => {
            state.modifyLmsCourseDetail.data = payload
            state.modifyLmsCourseDetail.message = LMS_COURSE_INITIAL_STATE.modifyLmsCourseDetail.message
        },
        setModifyLmsCourseDetailMessage: (state, { payload }) => {
            state.modifyLmsCourseDetail.message = payload
            state.modifyLmsCourseDetail.data = LMS_COURSE_INITIAL_STATE.modifyLmsCourseDetail.data
        },
        resetModifyLmsCourseDetail: (state) => {
            state.modifyLmsCourseDetail = LMS_COURSE_INITIAL_STATE.modifyLmsCourseDetail
        },

        /* LMS COURSE SECTIONS */

        // CREATE
        setAddLmsCourseSectionDetailLoading: (state, { payload }) => {
            state.addLmsCourseSectionDetail.isLoading = payload
        },
        setAddLmsCourseSectionDetailData: (state, { payload }) => {
            state.addLmsCourseSectionDetail.data = payload
            state.addLmsCourseSectionDetail.message = LMS_COURSE_INITIAL_STATE.addLmsCourseSectionDetail.message
        },
        setAddLmsCourseSectionDetailMessage: (state, { payload }) => {
            state.addLmsCourseSectionDetail.message = payload
            state.addLmsCourseSectionDetail.data = LMS_COURSE_INITIAL_STATE.addLmsCourseSectionDetail.data
        },
        resetAddLmsCourseSectionDetailData: (state) => {
            state.addLmsCourseSectionDetail.data = LMS_COURSE_INITIAL_STATE.addLmsCourseSectionDetail.data
        },
        resetAddLmsCourseSectionDetail: (state) => {
            state.addLmsCourseSectionDetail = LMS_COURSE_INITIAL_STATE.addLmsCourseSectionDetail
        },

        // add lms course detail payload
        setAddLmsCourseSectionDetailPayload: (state, { payload }) => {
            state.addLmsCourseSectionDetail.payload = payload
        },
        resetAddLmsCourseSectionDetailPayload: (state) => {
            state.addLmsCourseSectionDetail.payload = LMS_COURSE_INITIAL_STATE.addLmsCourseSectionDetail.payload
        },

        // UPDATE
        setUpdateLmsCourseSectionLoading: (state, { payload }) => {
            state.updateLmsCourseSectionDetail.isLoading = payload
        },
        setUpdateLmsCourseSectionData: (state, { payload }) => {
            state.updateLmsCourseSectionDetail.data = payload
            state.updateLmsCourseSectionDetail.message = LMS_COURSE_INITIAL_STATE.updateLmsCourseSectionDetail.message
        },
        setUpdateLmsCourseSectionMessage: (state, { payload }) => {
            state.updateLmsCourseSectionDetail.message = payload
            state.updateLmsCourseSectionDetail.data = LMS_COURSE_INITIAL_STATE.updateLmsCourseSectionDetail.data
        },
        resetUpdateLmsCourseSection: (state) => {
            state.updateLmsCourseSectionDetail = LMS_COURSE_INITIAL_STATE.updateLmsCourseSectionDetail
        },

        // UPDATE ORDER
        setUpdateOrderLmsCourseSectionLoading: (state, { payload }) => {
            state.updateOrderLmsCourseSectionDetail.isLoading = payload
        },
        setUpdateOrderLmsCourseSectionData: (state, { payload }) => {
            state.updateOrderLmsCourseSectionDetail.data = payload
            state.updateOrderLmsCourseSectionDetail.message = LMS_COURSE_INITIAL_STATE.updateOrderLmsCourseSectionDetail.message
        },
        setUpdateOrderLmsCourseSectionMessage: (state, { payload }) => {
            state.updateOrderLmsCourseSectionDetail.message = payload
            state.updateOrderLmsCourseSectionDetail.data = LMS_COURSE_INITIAL_STATE.updateOrderLmsCourseSectionDetail.data
        },
        resetUpdateOrderLmsCourseSection: (state) => {
            state.updateOrderLmsCourseSectionDetail = LMS_COURSE_INITIAL_STATE.updateOrderLmsCourseSectionDetail
        },

        // DETAIL
        setLmsCourseSectionDetailLoading: (state, { payload }) => {
            state.lmsCourseSectionDetail.isLoading = payload
        },
        setLmsCourseSectionDetailData: (state, { payload }) => {
            state.lmsCourseSectionDetail.data = payload
            state.lmsCourseSectionDetail.message = LMS_COURSE_INITIAL_STATE.lmsCourseSectionDetail.message
        },
        setLmsCourseSectionDetailMessage: (state, { payload }) => {
            state.lmsCourseSectionDetail.message = payload
            state.lmsCourseSectionDetail.data = LMS_COURSE_INITIAL_STATE.lmsCourseSectionDetail.data
        },
        resetLmsCourseSectionDetail: (state) => {
            state.lmsCourseSectionDetail = LMS_COURSE_INITIAL_STATE.lmsCourseSectionDetail
        },

        // LIST
        setLmsCourseSectionListLoading: (state, { payload }) => {
            state.lmsCourseSectionList.isLoading = payload
        },
        setLmsCourseSectionListData: (state, { payload }) => {
            state.lmsCourseSectionList.data = payload
            state.lmsCourseSectionList.message = LMS_COURSE_INITIAL_STATE.lmsCourseSectionList.message
        },
        setLmsCourseSectionListMessage: (state, { payload }) => {
            state.lmsCourseSectionList.message = payload
            state.lmsCourseSectionList.data = LMS_COURSE_INITIAL_STATE.lmsCourseSectionList.data
        },
        resetLmsCourseSectionList: (state) => {
            state.lmsCourseSectionList = LMS_COURSE_INITIAL_STATE.lmsCourseSectionList
        },

        // DELETE
        setDeleteLmsCourseSectionDetailLoading: (state, { payload }) => {
            state.deleteLmsCourseSectionDetail.isLoading = payload
        },
        setDeleteLmsCourseSectionDetailMessage: (state, { payload }) => {
            state.deleteLmsCourseSectionDetail.message = payload
            state.deleteLmsCourseSectionDetail.data = LMS_COURSE_INITIAL_STATE.deleteLmsCourseSectionDetail.data
        },
        resetDeleteLmsCourseSectionDetail: (state) => {
            state.deleteLmsCourseSectionDetail = LMS_COURSE_INITIAL_STATE.deleteLmsCourseSectionDetail
        },

        /* LMS COURSE LESSON-SECTIONS */

        // CREATE
        setAddLmsCourseSectionLessonLoading: (state, { payload }) => {
            state.addLmsCourseSectionLesson.isLoading = payload
        },
        setAddLmsCourseSectionLessonData: (state, { payload }) => {
            state.addLmsCourseSectionLesson.data = payload
            state.addLmsCourseSectionLesson.message = LMS_COURSE_INITIAL_STATE.addLmsCourseSectionLesson.message
        },
        setAddLmsCourseSectionLessonMessage: (state, { payload }) => {
            state.addLmsCourseSectionLesson.message = payload
            state.addLmsCourseSectionLesson.data = LMS_COURSE_INITIAL_STATE.addLmsCourseSectionLesson.data
        },
        resetAddLmsCourseSectionLesson: (state) => {
            state.addLmsCourseSectionLesson = LMS_COURSE_INITIAL_STATE.addLmsCourseSectionLesson
        },

        // LIST
        setLmsCourseSectionLessonListLoading: (state, { payload }) => {
            state.lmsCourseSectionLessonList.isLoading = payload
        },
        setLmsCourseSectionLessonListData: (state, { payload }) => {
            state.lmsCourseSectionLessonList.data = payload
            state.lmsCourseSectionLessonList.message = LMS_COURSE_INITIAL_STATE.lmsCourseSectionLessonList.message
        },
        setLmsCourseSectionLessonListMessage: (state, { payload }) => {
            state.lmsCourseSectionLessonList.message = payload
            state.lmsCourseSectionLesson.data = LMS_COURSE_INITIAL_STATE.lmsCourseSectionLesson.data
        },
        resetLmsCourseSectionLessonList: (state) => {
            state.lmsCourseSectionLesson = LMS_COURSE_INITIAL_STATE.lmsCourseSectionLesson
        },

        // DETAIL
        setLmsCourseSectionLessonLoading: (state, { payload }) => {
            state.lmsCourseSectionLesson.isLoading = payload
        },
        setLmsCourseSectionLessonData: (state, { payload }) => {
            state.lmsCourseSectionLesson.data = payload
            state.lmsCourseSectionLesson.message = LMS_COURSE_INITIAL_STATE.lmsCourseSectionLesson.message
        },
        setLmsCourseSectionLessonMessage: (state, { payload }) => {
            state.lmsCourseSectionLesson.message = payload
            state.lmsCourseSectionLesson.data = LMS_COURSE_INITIAL_STATE.lmsCourseSectionLesson.data
        },
        resetLmsCourseSectionLesson: (state) => {
            state.lmsCourseSectionLesson = LMS_COURSE_INITIAL_STATE.lmsCourseSectionLesson
        },

        // UPDATE
        setUpdateLmsCourseSectionLessonLoading: (state, { payload }) => {
            state.updateLmsCourseSectionLesson.isLoading = payload
        },
        setUpdateLmsCourseSectionLessonData: (state, { payload }) => {
            state.updateLmsCourseSectionLesson.data = payload
            state.updateLmsCourseSectionLesson.message = LMS_COURSE_INITIAL_STATE.updateLmsCourseSectionLesson
        },
        setUpdateLmsCourseSectionLessonMessage: (state, { payload }) => {
            state.updateLmsCourseSectionLesson.message = payload
            state.updateLmsCourseSectionLesson.data = LMS_COURSE_INITIAL_STATE.updateLmsCourseSectionLesson.data
        },
        resetUpdateLmsCourseSectionLesson: (state) => {
            state.updateLmsCourseSectionLesson = LMS_COURSE_INITIAL_STATE.updateLmsCourseSectionLesson
        },

        // UPDATE ORDER
        setUpdateOrderLmsCourseSectionLessonLoading: (state, { payload }) => {
            state.updateOrderLmsCourseSectionLesson.isLoading = payload
        },
        setUpdateOrderLmsCourseSectionLessonData: (state, { payload }) => {
            state.updateOrderLmsCourseSectionLesson.data = payload
            state.updateOrderLmsCourseSectionLesson.message = LMS_COURSE_INITIAL_STATE.updateOrderLmsCourseSectionLesson
        },
        setUpdateOrderLmsCourseSectionLessonMessage: (state, { payload }) => {
            state.updateOrderLmsCourseSectionLesson.message = payload
            state.updateOrderLmsCourseSectionLesson.data = LMS_COURSE_INITIAL_STATE.updateOrderLmsCourseSectionLesson.data
        },
        resetUpdateOrderLmsCourseSectionLesson: (state) => {
            state.updateOrderLmsCourseSectionLesson = LMS_COURSE_INITIAL_STATE.updateOrderLmsCourseSectionLesson
        },

        // DELETE
        setDeleteLmsCourseSectionLessonLoading: (state, { payload }) => {
            state.deleteLmsCourseSectionLesson.isLoading = payload
        },
        setDeleteLmsCourseSectionLessonMessage: (state, { payload }) => {
            state.deleteLmsCourseSectionLesson.message = payload
        },
        resetDeleteLmsCourseSectionLesson: (state) => {
            state.deleteLmsCourseSectionLesson = LMS_COURSE_INITIAL_STATE.deleteLmsCourseSectionLesson
        },

        /* STUDENT LMS COURSE */

        // STUDENT LMS COURSE LIST
        setStudentLmsCourseListLoading: (state, { payload }) => {
            state.studentLmsCourseList.isLoading = payload
        },
        setStudentLmsCourseListData: (state, { payload }) => {
            state.studentLmsCourseList.data = payload
            state.studentLmsCourseList.message = LMS_COURSE_INITIAL_STATE.studentLmsCourseList.message
        },
        setStudentLmsCourseListMessage: (state, { payload }) => {
            state.studentLmsCourseList.message = payload
            state.studentLmsCourseList.data = LMS_COURSE_INITIAL_STATE.studentLmsCourseList.data
        },
        resetStudentLmsCourseList: (state) => {
            state.studentLmsCourseList = LMS_COURSE_INITIAL_STATE.studentLmsCourseList
        },

        // STUDENT FAV LMS COURSE LIST
        setStudentFavLmsCourseListLoading: (state, { payload }) => {
            state.studentFavLmsCourseList.isLoading = payload
        },
        setStudentFavLmsCourseListData: (state, { payload }) => {
            state.studentFavLmsCourseList.data = payload
            state.studentFavLmsCourseList.message = LMS_COURSE_INITIAL_STATE.studentFavLmsCourseList.message
        },
        setStudentFavLmsCourseListMessage: (state, { payload }) => {
            state.studentFavLmsCourseList.message = payload
            state.studentFavLmsCourseList.data = LMS_COURSE_INITIAL_STATE.studentFavLmsCourseList
        },
        resetStudentFavLmsCourseList: (state) => {
            state.studentFavLmsCourseList = LMS_COURSE_INITIAL_STATE.studentFavLmsCourseList
        },

        // ADD STUDENT FAV LMS COURSE DETAIL
        setAddStudentFavLmsCourseDetailLoading: (state, { payload }) => {
            state.addStudentFavLmsCourseDetail.isLoading = payload
        },
        setAddStudentFavLmsCourseDetailMessage: (state, { payload }) => {
            state.addStudentFavLmsCourseDetail.message = payload
        },
        resetAddStudentFavLmsCourseDetail: (state) => {
            state.addStudentFavLmsCourseDetail = LMS_COURSE_INITIAL_STATE.addStudentFavLmsCourseDetail
        },

        // STUDENT LMS COURSE DETAIL
        setStudentLmsCourseDetailLoading: (state, { payload }) => {
            state.studentLmsCourseDetail.isLoading = payload
        },
        setStudentLmsCourseDetailData: (state, { payload }) => {
            state.studentLmsCourseDetail.data = payload
            state.studentLmsCourseDetail.message = LMS_COURSE_INITIAL_STATE.studentLmsCourseDetail.message
        },
        setStudentLmsCourseDetailMessage: (state, { payload }) => {
            state.studentLmsCourseDetail.message = payload
            state.studentLmsCourseDetail.data = LMS_COURSE_INITIAL_STATE.studentLmsCourseDetail.data
        },
        resetStudentLmsCourseDetail: (state) => {
            state.studentLmsCourseDetail = LMS_COURSE_INITIAL_STATE.studentLmsCourseDetail
        },

        // lms course tag
        // add lms course tag detail
        setAddLmsCourseTagDetailLoading: (state, { payload }) => {
            state.addLmsCourseTagDetail.isLoading = payload
        },
        setAddLmsCourseTagDetailMessage: (state, { payload }) => {
            state.addLmsCourseTagDetail.message = payload
        },
        resetAddLmsCourseTagDetail: (state) => {
            state.addLmsCourseTagDetail = LMS_COURSE_INITIAL_STATE.addLmsCourseTagDetail
        },

        // update lms course tag list order
        setUpdateLmsCourseTagListOrderLoading: (state, { payload }) => {
            state.updateLmsCourseTagListOrderDetail.isLoading = payload
        },
        setUpdateLmsCourseTagDetailMessage: (state, { payload }) => {
            state.updateLmsCourseTagListOrderDetail.message = payload
        },
        resetUpdateLmsCourseTagListOrder: (state) => {
            state.updateLmsCourseTagListOrderDetail = LMS_COURSE_INITIAL_STATE.updateLmsCourseTagListOrderDetail
        },

        // delete lms course tag detail
        setDeleteLmsCourseTagDetailLoading: (state, { payload }) => {
            state.deleteLmsCourseTagDetail.isLoading = payload
        },
        setDeleteLmsCourseTagDetailMessage: (state, { payload }) => {
            state.deleteLmsCourseTagDetail.message = payload
        },
        resetDeleteLmsCourseTagDetail: (state) => {
            state.deleteLmsCourseTagDetail = LMS_COURSE_INITIAL_STATE.deleteLmsCourseTagDetail
        },

        // lms course language
        // create lms course language detail
        setAddLmsCourseLanguageDetailLoading: (state, { payload }) => {
            state.addLmsCourseLanguageDetail.isLoading = payload
        },
        setAddLmsCourseLanguageDetailMessage: (state, { payload }) => {
            state.addLmsCourseLanguageDetail.message = payload
        },
        resetAddLmsCourseLanguageDetail: (state) => {
            state.addLmsCourseLanguageDetail = LMS_COURSE_INITIAL_STATE.addLmsCourseLanguageDetail
        },

        // update lms course language list order
        setUpdateLmsCourseLanguageListOrderLoading: (state, { payload }) => {
            state.updateLmsCourseLanguageListOrderDetail.isLoading = payload
        },
        setUpdateLmsCourseLanguageListOrderMessage: (state, { payload }) => {
            state.updateLmsCourseLanguageListOrderDetail.message = payload
        },
        resetUpdateLmsCourseLanguageListOrder: (state) => {
            state.updateLmsCourseLanguageListOrderDetail = LMS_COURSE_INITIAL_STATE.updateLmsCourseLanguageListOrderDetail
        },

        // delete lms course language detail
        setDeleteLmsCourseLanguageDetailLoading: (state, { payload }) => {
            state.deleteLmsCourseLanguageDetail.isLoading = payload
        },
        setDeleteLmsCourseLanguageDetailMessage: (state, { payload }) => {
            state.deleteLmsCourseLanguageDetail.message = payload
        },
        resetDeleteLmsCourseLanguageDetail: (state) => {
            state.deleteLmsCourseLanguageDetail = LMS_COURSE_INITIAL_STATE.deleteLmsCourseLanguageDetail
        },

        // lms course Prof
        // create lms course Prof detail
        setAddLmsCourseProfDetailLoading: (state, { payload }) => {
            state.addLmsCourseProfDetail.isLoading = payload
        },
        setAddLmsCourseProfDetailMessage: (state, { payload }) => {
            state.addLmsCourseProfDetail.message = payload
        },
        resetAddLmsCourseProfDetail: (state) => {
            state.addLmsCourseProfDetail = LMS_COURSE_INITIAL_STATE.addLmsCourseProfDetail
        },

        // update lms course Prof list order
        setUpdateLmsCourseProfListOrderLoading: (state, { payload }) => {
            state.updateLmsCourseProfListOrderDetail.isLoading = payload
        },
        setUpdateLmsCourseProfListOrderMessage: (state, { payload }) => {
            state.updateLmsCourseProfListOrderDetail.message = payload
        },
        resetUpdateLmsCourseProfListOrder: (state) => {
            state.updateLmsCourseProfListOrderDetail = LMS_COURSE_INITIAL_STATE.updateLmsCourseProfListOrderDetail
        },

        // delete lms course Prof detail
        setDeleteLmsCourseProfDetailLoading: (state, { payload }) => {
            state.deleteLmsCourseProfDetail.isLoading = payload
        },
        setDeleteLmsCourseProfDetailMessage: (state, { payload }) => {
            state.deleteLmsCourseProfDetail.message = payload
        },
        resetDeleteLmsCourseProfDetail: (state) => {
            state.deleteLmsCourseProfDetail = LMS_COURSE_INITIAL_STATE.deleteLmsCourseProfDetail
        },

        // lms course learning
        // create lms course learning detail
        setAddLmsCourseLearningDetailLoading: (state, { payload }) => {
            state.addLmsCourseLearningDetail.isLoading = payload
        },
        setAddLmsCourseLearningDetailMessage: (state, { payload }) => {
            state.addLmsCourseLearningDetail.message = payload
        },
        resetAddLmsCourseLearningDetail: (state) => {
            state.addLmsCourseLearningDetail = LMS_COURSE_INITIAL_STATE.addLmsCourseLearningDetail
        },

        // modify lms course learning detail
        setModifyLmsCourseLearningDetailLoading: (state, { payload }) => {
            state.modifyLmsCourseLearningDetail.isLoading = payload
        },
        setModifyLmsCourseLearningDetailData: (state, { payload }) => {
            state.modifyLmsCourseLearningDetail.data = payload
            state.modifyLmsCourseLearningDetail.message = LMS_COURSE_INITIAL_STATE.modifyLmsCourseLearningDetail.message
        },
        setModifyLmsCourseLearningDetailMessage: (state, { payload }) => {
            state.modifyLmsCourseLearningDetail.message = payload
            state.modifyLmsCourseLearningDetail.data = LMS_COURSE_INITIAL_STATE.modifyLmsCourseLearningDetail.data
        },
        resetModifyLmsCourseLearningDetail: (state) => {
            state.modifyLmsCourseLearningDetail = LMS_COURSE_INITIAL_STATE.modifyLmsCourseLearningDetail
        },

        // update lms course Learning list order
        setUpdateLmsCourseLearningListOrderLoading: (state, { payload }) => {
            state.updateLmsCourseLearningListOrderDetail.isLoading = payload
        },
        setUpdateLmsCourseLearningListOrderMessage: (state, { payload }) => {
            state.updateLmsCourseLearningListOrderDetail.message = payload
        },
        resetUpdateLmsCourseLearningListOrder: (state) => {
            state.updateLmsCourseLearningListOrderDetail = LMS_COURSE_INITIAL_STATE.updateLmsCourseLearningListOrderDetail
        },

        // delete lms course Learning detail
        setDeleteLmsCourseLearningDetailLoading: (state, { payload }) => {
            state.deleteLmsCourseLearningDetail.isLoading = payload
        },
        setDeleteLmsCourseLearningDetailMessage: (state, { payload }) => {
            state.deleteLmsCourseLearningDetail.message = payload
        },
        resetDeleteLmsCourseLearningDetail: (state) => {
            state.deleteLmsCourseLearningDetail = LMS_COURSE_INITIAL_STATE.deleteLmsCourseLearningDetail
        },

        // lms course faq
        // create lms course Faq detail
        setAddLmsCourseFaqDetailLoading: (state, { payload }) => {
            state.addLmsCourseFaqDetail.isLoading = payload
        },
        setAddLmsCourseFaqDetailMessage: (state, { payload }) => {
            state.addLmsCourseFaqDetail.message = payload
        },
        resetAddLmsCourseFaqDetail: (state) => {
            state.addLmsCourseFaqDetail = LMS_COURSE_INITIAL_STATE.addLmsCourseFaqDetail
        },

        // update lms course Faq detail
        setModifyLmsCourseFaqDetailLoading: (state, { payload }) => {
            state.modifyLmsCourseFaqDetail.isLoading = payload
        },
        setModifyLmsCourseFaqDetailData: (state, { payload }) => {
            state.modifyLmsCourseFaqDetail.data = payload
            state.modifyLmsCourseFaqDetail.message = LMS_COURSE_INITIAL_STATE.modifyLmsCourseFaqDetail.message
        },
        setModifyLmsCourseFaqDetailMessage: (state, { payload }) => {
            state.modifyLmsCourseFaqDetail.message = payload
            state.modifyLmsCourseFaqDetail.data = LMS_COURSE_INITIAL_STATE.modifyLmsCourseFaqDetail.data
        },
        resetModifyLmsCourseFaqDetail: (state) => {
            state.modifyLmsCourseFaqDetail = LMS_COURSE_INITIAL_STATE.modifyLmsCourseFaqDetail
        },

        // update lms course Faq list order
        setUpdateLmsCourseFaqListOrderLoading: (state, { payload }) => {
            state.updateLmsCourseFaqListOrderDetail.isLoading = payload
        },
        setUpdateLmsCourseFaqListOrderMessage: (state, { payload }) => {
            state.updateLmsCourseFaqListOrderDetail.message = payload
        },
        resetUpdateLmsCourseFaqListOrder: (state) => {
            state.updateLmsCourseFaqListOrderDetail = LMS_COURSE_INITIAL_STATE.updateLmsCourseFaqListOrderDetail
        },

        // delete lms course Faq detail
        setDeleteLmsCourseFaqDetailLoading: (state, { payload }) => {
            state.deleteLmsCourseFaqDetail.isLoading = payload
        },
        setDeleteLmsCourseFaqDetailMessage: (state, { payload }) => {
            state.deleteLmsCourseFaqDetail.message = payload
        },
        resetDeleteLmsCourseFaqDetail: (state) => {
            state.deleteLmsCourseFaqDetail = LMS_COURSE_INITIAL_STATE.deleteLmsCourseFaqDetail
        },

        // lms course slug detail
        // add lms course slug detail
        setAddLmsCourseSlugDetailLoading: (state, { payload }) => {
            state.addLmsCourseSlugDetail.isLoading = payload
        },
        setAddLmsCourseSlugDetailMessage: (state, { payload }) => {
            state.addLmsCourseSlugDetail.message = payload
        },
        resetAddLmsCourseSlugDetail: (state) => {
            state.addLmsCourseSlugDetail = LMS_COURSE_INITIAL_STATE.addLmsCourseSlugDetail
        },

        // delete lms course slug detail
        setDestroyLmsCourseSlugDetailLoading: (state, { payload }) => {
            state.destroyLmsCourseSlugDetail.isLoading = payload
        },
        setDestroyLmsCourseSlugDetailMessage: (state, { payload }) => {
            state.destroyLmsCourseSlugDetail.message = payload
        },
        resetDestroyLmsCourseSlugDetail: (state) => {
            state.destroyLmsCourseSlugDetail = LMS_COURSE_INITIAL_STATE.destroyLmsCourseSlugDetail
        },

        // lms course instructor detail
        // add lms course instructor detail
        setAddLmsCourseInstructorDetailLoading: (state, { payload }) => {
            state.addLmsCourseInstructorDetail.isLoading = payload
        },
        setAddLmsCourseInstructorDetailMessage: (state, { payload }) => {
            state.addLmsCourseInstructorDetail.message = payload
        },
        resetAddLmsCourseInstructorDetail: (state) => {
            state.addLmsCourseInstructorDetail = LMS_COURSE_INITIAL_STATE.addLmsCourseInstructorDetail
        },

        // modify lms course instructor detail
        setModifyLmsCourseInstructorDetailLoading: (state, { payload }) => {
            state.modifyLmsCourseInstructorDetail.isLoading = payload
        },
        setModifyLmsCourseInstructorDetailMessage: (state, { payload }) => {
            state.modifyLmsCourseInstructorDetail.message = payload
        },
        resetModifyLmsCourseInstructorDetail: (state) => {
            state.modifyLmsCourseInstructorDetail = LMS_COURSE_INITIAL_STATE.modifyLmsCourseInstructorDetail
        },

        // delete lms course instructor detail
        setDestroyLmsCourseInstructorDetailLoading: (state, { payload }) => {
            state.destroyLmsCourseInstructorDetail.isLoading = payload
        },
        setDestroyLmsCourseInstructorDetailMessage: (state, { payload }) => {
            state.destroyLmsCourseInstructorDetail.message = payload
        },
        resetDestroyLmsCourseInstructorDetail: (state) => {
            state.destroyLmsCourseInstructorDetail = LMS_COURSE_INITIAL_STATE.destroyLmsCourseInstructorDetail
        },

        // lms course Student detail
        // get lms course student list
        setLmsCourseStudentListLoading: (state, { payload }) => {
            state.lmsCourseStudentList.isLoading = payload
        },
        setLmsCourseStudentListData: (state, { payload }) => {
            state.lmsCourseStudentList.data = payload
            state.lmsCourseStudentList.message = LMS_COURSE_INITIAL_STATE.lmsCourseStudentList.message
        },
        setLmsCourseStudentListMessage: (state, { payload }) => {
            state.lmsCourseStudentList.message = payload
            state.lmsCourseStudentList.data = LMS_COURSE_INITIAL_STATE.lmsCourseStudentList.data
        },
        resetLmsCourseStudentList: (state) => {
            state.lmsCourseStudentList = LMS_COURSE_INITIAL_STATE.lmsCourseStudentList
        },

        // add lms course Student detail
        setAddLmsCourseStudentDetailLoading: (state, { payload }) => {
            state.addLmsCourseStudentDetail.isLoading = payload
        },
        setAddLmsCourseStudentDetailMessage: (state, { payload }) => {
            state.addLmsCourseStudentDetail.message = payload
        },
        resetAddLmsCourseStudentDetail: (state) => {
            state.addLmsCourseStudentDetail = LMS_COURSE_INITIAL_STATE.addLmsCourseStudentDetail
        },

        // modified lms course Student detail
        setModifyLmsCourseStudentDetailLoading: (state, { payload }) => {
            state.modifyLmsCourseStudentDetail.isLoading = payload
        },
        setModifyLmsCourseStudentDetailMessage: (state, { payload }) => {
            state.modifyLmsCourseStudentDetail.message = payload
        },
        resetModifyLmsCourseStudentDetail: (state) => {
            state.modifyLmsCourseStudentDetail = LMS_COURSE_INITIAL_STATE.modifyLmsCourseStudentDetail
        },

        // delete lms course Student detail
        setDestroyLmsCourseStudentDetailLoading: (state, { payload }) => {
            state.destroyLmsCourseStudentDetail.isLoading = payload
        },
        setDestroyLmsCourseStudentDetailMessage: (state, { payload }) => {
            state.destroyLmsCourseStudentDetail.message = payload
        },
        resetDestroyLmsCourseStudentDetail: (state) => {
            state.destroyLmsCourseStudentDetail = LMS_COURSE_INITIAL_STATE.destroyLmsCourseStudentDetail
        },

        // lms course invitation
        setLmsCourseInvitationListLoading: (state, { payload }) => {
            state.lmsCourseInvitationList.isLoading = payload
        },
        setLmsCourseInvitationListData: (state, { payload }) => {
            state.lmsCourseInvitationList.data = payload
            state.lmsCourseInvitationList.message = LMS_COURSE_INITIAL_STATE.lmsCourseInvitationList.message
        },
        setLmsCourseInvitationListMessage: (state, { payload }) => {
            state.lmsCourseInvitationList.message = payload
            state.lmsCourseInvitationList.data = LMS_COURSE_INITIAL_STATE.lmsCourseInvitationList.data
        },
        resetLmsCourseInvitationList: (state) => {
            state.lmsCourseInvitationList = LMS_COURSE_INITIAL_STATE.lmsCourseInvitationList
        },

        // add lms course invitation detail
        setSendLmsCourseInvitationDetailLoading: (state, { payload }) => {
            state.sendLmsCourseInvitationDetail.isLoading = payload
        },
        setSendLmsCourseInvitationDetailData: (state, { payload }) => {
            state.sendLmsCourseInvitationDetail.data = payload
            state.sendLmsCourseInvitationDetail.message = LMS_COURSE_INITIAL_STATE.sendLmsCourseInvitationDetail.message
        },
        setSendLmsCourseInvitationDetailMessage: (state, { payload }) => {
            state.sendLmsCourseInvitationDetail.message = payload
            state.sendLmsCourseInvitationDetail.data = LMS_COURSE_INITIAL_STATE.sendLmsCourseInvitationDetail.data
        },
        resetSendLmsCourseInvitationDetail: (state) => {
            state.sendLmsCourseInvitationDetail = LMS_COURSE_INITIAL_STATE.sendLmsCourseInvitationDetail
        },

        // send lms course invitation payload
        setSendLmsCourseInvitationDetailPayload: (state, { payload }) => {
            state.sendLmsCourseInvitationDetail.payload = payload
        },
        resetSendLmsCourseInvitationDetailPayload: (state) => {
            state.sendLmsCourseInvitationDetail.payload = LMS_COURSE_INITIAL_STATE.sendLmsCourseInvitationDetail.payload
        },

        // Validate LmsCourse Invitation Detail
        setValidateLmsCourseInvitationDetailLoading: ( state, {payload}) => {
            state.validateLmsCourseInvitationDetail.isLoading = payload
        },
        setValidateLmsCourseInvitationDetailData: (state, { payload }) => {
            state.validateLmsCourseInvitationDetail.data = payload
        },

        setValidateLmsCourseInvitationDetailMessage: (state, { payload }) => {
            state.validateLmsCourseInvitationDetail.message = payload
        },
        
        resetValidateLmsCourseInvitationDetail: (state, { payload }) => {
            state.validateLmsCourseInvitationDetail = LMS_COURSE_INITIAL_STATE.validateLmsCourseInvitationDetail
        },

        // Accept or Decline LmsCourse Invitation Detail
        setAcceptAndDeclineLmsCourseInvitationDetailLoading: ( state, {payload}) => {
            state.acceptAndDeclineLmsCourseInvitationDetail.isLoading = payload
        },
        setAcceptAndDeclineLmsCourseInvitationDetailData: (state, { payload }) => {
            state.acceptAndDeclineLmsCourseInvitationDetail.data = payload
        },

        setAcceptAndDeclineLmsCourseInvitationDetailMessage: (state, { payload }) => {
            state.acceptAndDeclineLmsCourseInvitationDetail.message = payload
        },
        
        resetAcceptAndDeclineLmsCourseInvitationDetail: (state, { payload }) => {
            state.acceptAndDeclineLmsCourseInvitationDetail = LMS_COURSE_INITIAL_STATE.acceptAndDeclineLmsCourseInvitationDetail
        },

        // destroy lms course invitation detail
        setDestroyLmsCourseInvitationDetailLoading: (state, { payload }) => {
            state.destroyLmsCourseInvitationDetail.isLoading = payload
        },
        setDestroyLmsCourseInvitationDetailMessage: (state, { payload }) => {
            state.destroyLmsCourseInvitationDetail.message = payload
        },
        resetDestroyLmsCourseInvitationDetail: (state) => {
            state.destroyLmsCourseInvitationDetail = LMS_COURSE_INITIAL_STATE.destroyLmsCourseInvitationDetail
        },

        /* lms course student section lesson */
        // addDestroy
        setAddDestroyLmsCourseStudentSectionLessonLoading: (state, { payload }) => {
            state.addDestroyLmsCourseStudentSectionLesson.isLoading = payload
        },
        setAddDestroyLmsCourseStudentSectionLessonMessage: (state, { payload }) => {
            state.addDestroyLmsCourseStudentSectionLesson.message = payload
        },
        resetAddDestroyLmsCourseStudentSectionLesson: (state) => {
            state.addDestroyLmsCourseStudentSectionLesson = LMS_COURSE_INITIAL_STATE.addDestroyLmsCourseStudentSectionLesson
        },

        /* lms course student quiz response */

        // add
        setAddLmsCourseStudentQuizResponseDetailLoading: (state, { payload }) => {
            state.addLmsCourseStudentQuizResponseDetail.isLoading = payload
        },
        setAddLmsCourseStudentQuizResponseDetailData: (state, { payload }) => {
            state.addLmsCourseStudentQuizResponseDetail.data = payload
            state.addLmsCourseStudentQuizResponseDetail.message = LMS_COURSE_INITIAL_STATE.addLmsCourseStudentQuizResponseDetail.message
        },
        setAddLmsCourseStudentQuizResponseDetailMessage: (state, { payload }) => {
            state.addLmsCourseStudentQuizResponseDetail.message = payload
            state.addLmsCourseStudentQuizResponseDetail.data = LMS_COURSE_INITIAL_STATE.addLmsCourseStudentQuizResponseDetail.data
        },
        resetAddLmsCourseStudentQuizResponseDetail: (state) => {
            state.addLmsCourseStudentQuizResponseDetail = LMS_COURSE_INITIAL_STATE.addLmsCourseStudentQuizResponseDetail
        },
        // modify
        setModifyLmsCourseStudentQuizResponseDetailLoading: (state, { payload }) => {
            state.modifyLmsCourseStudentQuizResponseDetail.isLoading = payload
        },
        setModifyLmsCourseStudentQuizResponseDetailMessage: (state, { payload }) => {
            state.modifyLmsCourseStudentQuizResponseDetail.message = payload
        },
        resetModifyLmsCourseStudentQuizResponseDetail: (state) => {
            state.modifyLmsCourseStudentQuizResponseDetail = LMS_COURSE_INITIAL_STATE.modifyLmsCourseStudentQuizResponseDetail
        },

        /* lms course student note */

        // add
        setAddLmsCourseStudentNoteDetailLoading: (state, { payload }) => {
            state.addLmsCourseStudentNoteDetail.isLoading = payload
        },
        setAddLmsCourseStudentNoteDetailData: (state, { payload }) => {
            state.addLmsCourseStudentNoteDetail.data = payload
            state.addLmsCourseStudentNoteDetail.message = LMS_COURSE_INITIAL_STATE.addLmsCourseStudentNoteDetail.message
        },
        setAddLmsCourseStudentNoteDetailMessage: (state, { payload }) => {
            state.addLmsCourseStudentNoteDetail.message = payload
            state.addLmsCourseStudentNoteDetail.data = LMS_COURSE_INITIAL_STATE.addLmsCourseStudentNoteDetail.data
        },
        resetAddLmsCourseStudentNoteDetail: (state) => {
            state.addLmsCourseStudentNoteDetail = LMS_COURSE_INITIAL_STATE.addLmsCourseStudentNoteDetail
        },

        // list
        setLmsCourseStudentNoteListLoading: (state, { payload }) => {
            state.lmsCourseStudentNoteList.isLoading = payload
        },
        setLmsCourseStudentNoteListData: (state, { payload }) => {
            state.lmsCourseStudentNoteList.data = payload
            state.lmsCourseStudentNoteList.message = LMS_COURSE_INITIAL_STATE.lmsCourseStudentNoteList.message
        },
        setLmsCourseStudentNoteListMessage: (state, { payload }) => {
            state.lmsCourseStudentNoteList.message = payload
            state.lmsCourseStudentNoteList.data = LMS_COURSE_INITIAL_STATE.lmsCourseStudentNoteList.data
        },
        resetLmsCourseStudentNoteList: (state) => {
            state.lmsCourseStudentNoteList = LMS_COURSE_INITIAL_STATE.lmsCourseStudentNoteList
        },

        // detail
        setLmsCourseStudentNoteDetailLoading: (state, { payload }) => {
            state.lmsCourseStudentNoteDetail.isLoading = payload
        },
        setLmsCourseStudentNoteDetailData: (state, { payload }) => {
            state.lmsCourseStudentNoteDetail.data = payload
            state.lmsCourseStudentNoteDetail.message = LMS_COURSE_INITIAL_STATE.lmsCourseStudentNoteDetail.message
        },
        setLmsCourseStudentNoteDetailMessage: (state, { payload }) => {
            state.lmsCourseStudentNoteDetail.message = payload
            state.lmsCourseStudentNoteDetail.data = LMS_COURSE_INITIAL_STATE.lmsCourseStudentNoteDetail.data
        },
        resetLmsCourseStudentNoteDetail: (state) => {
            state.lmsCourseStudentNoteDetail = LMS_COURSE_INITIAL_STATE.lmsCourseStudentNoteDetail
        },

        // modify
        setModifyLmsCourseStudentNoteDetailLoading: (state, { payload }) => {
            state.modifyLmsCourseStudentNoteDetail.isLoading = payload
        },
        setModifyLmsCourseStudentNoteDetailData: (state, { payload }) => {
            state.modifyLmsCourseStudentNoteDetail.data = payload
            state.modifyLmsCourseStudentNoteDetail.message = LMS_COURSE_INITIAL_STATE.modifyLmsCourseStudentNoteDetail.message
        },
        setModifyLmsCourseStudentNoteDetailMessage: (state, { payload }) => {
            state.modifyLmsCourseStudentNoteDetail.message = payload
            state.modifyLmsCourseStudentNoteDetail.data = LMS_COURSE_INITIAL_STATE.modifyLmsCourseStudentNoteDetail.data
        },
        resetModifyLmsCourseStudentNoteDetail: (state) => {
            state.modifyLmsCourseStudentNoteDetail = LMS_COURSE_INITIAL_STATE.modifyLmsCourseStudentNoteDetail
        },

        // destroy
        setDestroyLmsCourseStudentNoteDetailLoading: (state, { payload }) => {
            state.destroyLmsCourseStudentNoteDetail.isLoading = payload
        },
        setDestroyLmsCourseStudentNoteDetailData: (state, { payload }) => {
            state.destroyLmsCourseStudentNoteDetail.data = payload
            state.destroyLmsCourseStudentNoteDetail.message = LMS_COURSE_INITIAL_STATE.destroyLmsCourseStudentNoteDetail.message
        },
        setDestroyLmsCourseStudentNoteDetailMessage: (state, { payload }) => {
            state.destroyLmsCourseStudentNoteDetail.message = payload
            state.destroyLmsCourseStudentNoteDetail.data = LMS_COURSE_INITIAL_STATE.destroyLmsCourseStudentNoteDetail.data
        },
        resetDestroyLmsCourseStudentNoteDetail: (state) => {
            state.destroyLmsCourseStudentNoteDetail = LMS_COURSE_INITIAL_STATE.destroyLmsCourseStudentNoteDetail
        },

        // lms course page based reducers (lecture)
        setLmsCoursePageLectureListLoading: (state, { payload }) => {
            state.lmsCoursePageLectureList.isLoading = payload
        },
        setLmsCoursePageLectureListData: (state, { payload }) => {
            state.lmsCoursePageLectureList.data = payload
            state.lmsCoursePageLectureList.message = LMS_COURSE_INITIAL_STATE.lmsCoursePageLectureList.message
        },
        setLmsCoursePageLectureListMessage: (state, { payload }) => {
            state.lmsCoursePageLectureList.message = payload
            state.lmsCoursePageLectureList.data = LMS_COURSE_INITIAL_STATE.lmsCoursePageLectureList.data
        },
        resetLmsCoursePageLectureList: (state) => {
            state.lmsCoursePageLectureList = LMS_COURSE_INITIAL_STATE.lmsCoursePageLectureList
        },

        // lms course page based reducers (article)
        setLmsCoursePageArticleListLoading: (state, { payload }) => {
            state.lmsCoursePageArticleList.isLoading = payload
        },
        setLmsCoursePageArticleListData: (state, { payload }) => {
            state.lmsCoursePageArticleList.data = payload
            state.lmsCoursePageArticleList.message = LMS_COURSE_INITIAL_STATE.lmsCoursePageArticleList.message
        },
        setLmsCoursePageArticleListMessage: (state, { payload }) => {
            state.lmsCoursePageArticleList.message = payload
            state.lmsCoursePageArticleList.data = LMS_COURSE_INITIAL_STATE.lmsCoursePageArticleList.data
        },
        resetLmsCoursePageArticleList: (state) => {
            state.lmsCoursePageArticleList = LMS_COURSE_INITIAL_STATE.lmsCoursePageArticleList
        },

        // lms course page based reducers (quiz)
        setLmsCoursePageQuizListLoading: (state, { payload }) => {
            state.lmsCoursePageQuizList.isLoading = payload
        },
        setLmsCoursePageQuizListData: (state, { payload }) => {
            state.lmsCoursePageQuizList.data = payload
            state.lmsCoursePageQuizList.message = LMS_COURSE_INITIAL_STATE.lmsCoursePageQuizList.message
        },
        setLmsCoursePageQuizListMessage: (state, { payload }) => {
            state.lmsCoursePageQuizList.message = payload
            state.lmsCoursePageQuizList.data = LMS_COURSE_INITIAL_STATE.lmsCoursePageQuizList.data
        },
        resetLmsCoursePageQuizList: (state) => {
            state.lmsCoursePageQuizList = LMS_COURSE_INITIAL_STATE.lmsCoursePageQuizList
        },
    }
})

export const {
    setLmsCourseListLoading,
    setLmsCourseListData,
    setLmsCourseListMessage,
    resetLmsCourseList,

    setLmsCourseDetailData,
    setLmsCourseDetailLoading,
    setLmsCourseDetailMessage,
    resetLmsCourseDetail,

    setAddLmsCourseDetailLoading,
    setAddLmsCourseDetailData,
    setAddLmsCourseDetailMessage,
    resetAddLmsCourseDetail,

    setAddLmsCourseDetailPayload,
    resetAddLmsCourseDetailPayload,

    setModifyLmsCourseDetailLoading,
    setModifyLmsCourseDetailData,
    setModifyLmsCourseDetailMessage,
    resetModifyLmsCourseDetail,

    setAddLmsCourseSectionDetailData,
    setAddLmsCourseSectionDetailLoading,
    setAddLmsCourseSectionDetailMessage,
    resetAddLmsCourseSectionDetailData,
    resetAddLmsCourseSectionDetail,

    setAddLmsCourseSectionDetailPayload,
    resetAddLmsCourseSectionDetailPayload,

    setUpdateLmsCourseSectionLoading,
    setUpdateLmsCourseSectionData,
    setUpdateLmsCourseSectionMessage,
    resetUpdateLmsCourseSection,

    setUpdateOrderLmsCourseSectionData,
    setUpdateOrderLmsCourseSectionLoading,
    setUpdateOrderLmsCourseSectionMessage,
    resetUpdateOrderLmsCourseSection,

    setLmsCourseSectionDetailData,
    setLmsCourseSectionDetailMessage,
    setLmsCourseSectionDetailLoading,
    resetLmsCourseSectionDetail,

    setLmsCourseSectionListData,
    setLmsCourseSectionListLoading,
    setLmsCourseSectionListMessage,
    resetLmsCourseSectionList,

    setDeleteLmsCourseSectionDetailLoading,
    setDeleteLmsCourseSectionDetailMessage,
    resetDeleteLmsCourseSectionDetail,

    setAddLmsCourseSectionLessonData,
    setAddLmsCourseSectionLessonLoading,
    setAddLmsCourseSectionLessonMessage,
    resetAddLmsCourseSectionLesson,

    setLmsCourseSectionLessonData,
    setLmsCourseSectionLessonMessage,
    setLmsCourseSectionLessonLoading,
    resetLmsCourseSectionLesson,

    setLmsCourseSectionLessonListData,
    setLmsCourseSectionLessonListLoading,
    setLmsCourseSectionLessonListMessage,
    resetLmsCourseSectionLessonList,

    setUpdateLmsCourseSectionLessonData,
    setUpdateLmsCourseSectionLessonLoading,
    setUpdateLmsCourseSectionLessonMessage,
    resetUpdateLmsCourseSectionLesson,

    setUpdateOrderLmsCourseSectionLessonData,
    setUpdateOrderLmsCourseSectionLessonLoading,
    setUpdateOrderLmsCourseSectionLessonMessage,
    resetUpdateOrderLmsCourseSectionLesson,

    setDeleteLmsCourseSectionLessonLoading,
    setDeleteLmsCourseSectionLessonMessage,
    resetDeleteLmsCourseSectionLesson,

    setStudentLmsCourseListLoading,
    setStudentLmsCourseListData,
    setStudentLmsCourseListMessage,
    resetStudentLmsCourseList,

    setStudentFavLmsCourseListLoading,
    setStudentFavLmsCourseListData,
    setStudentFavLmsCourseListMessage,
    resetStudentFavLmsCourseList,

    setAddStudentFavLmsCourseDetailLoading,
    setAddStudentFavLmsCourseDetailMessage,
    resetAddStudentFavLmsCourseDetail,

    setStudentLmsCourseDetailLoading,
    setStudentLmsCourseDetailData,
    setStudentLmsCourseDetailMessage,
    resetStudentLmsCourseDetail,

    // lms course tag detail
    setAddLmsCourseTagDetailLoading,
    setAddLmsCourseTagDetailMessage,
    resetAddLmsCourseTagDetail,

    setUpdateLmsCourseTagListOrderLoading,
    setUpdateLmsCourseTagListOrderMessage,
    resetUpdateLmsCourseTagListOrder,

    setDeleteLmsCourseTagDetailLoading,
    setDeleteLmsCourseTagDetailMessage,
    resetDeleteLmsCourseTagDetail,

    // lms course language
    setAddLmsCourseLanguageDetailLoading,
    setAddLmsCourseLanguageDetailMessage,
    resetAddLmsCourseLanguageDetail,

    setUpdateLmsCourseLanguageListOrderLoading,
    setUpdateLmsCourseLanguageListOrderMessage,
    resetUpdateLmsCourseLanguageListOrder,

    setDeleteLmsCourseLanguageDetailLoading,
    setDeleteLmsCourseLanguageDetailMessage,
    resetDeleteLmsCourseLanguageDetail,

    // lms course prof
    setAddLmsCourseProfDetailLoading,
    setAddLmsCourseProfDetailMessage,
    resetAddLmsCourseProfDetail,

    setUpdateLmsCourseProfListOrderLoading,
    setUpdateLmsCourseProfListOrderMessage,
    resetUpdateLmsCourseProfListOrder,

    setDeleteLmsCourseProfDetailLoading,
    setDeleteLmsCourseProfDetailMessage,
    resetDeleteLmsCourseProfDetail,

    // lms course learning
    setAddLmsCourseLearningDetailLoading,
    setAddLmsCourseLearningDetailMessage,
    resetAddLmsCourseLearningDetail,

    setModifyLmsCourseLearningDetailLoading,
    setModifyLmsCourseLearningDetailData,
    setModifyLmsCourseLearningDetailMessage,
    resetModifyLmsCourseLearningDetail,

    setUpdateLmsCourseLearningListOrderLoading,
    setUpdateLmsCourseLearningListOrderMessage,
    resetUpdateLmsCourseLearningListOrder,

    setDeleteLmsCourseLearningDetailLoading,
    setDeleteLmsCourseLearningDetailMessage,
    resetDeleteLmsCourseLearningDetail,

    // lms course Faq
    setAddLmsCourseFaqDetailLoading,
    setAddLmsCourseFaqDetailMessage,
    resetAddLmsCourseFaqDetail,

    setModifyLmsCourseFaqDetailLoading,
    setModifyLmsCourseFaqDetailData,
    setModifyLmsCourseFaqDetailMessage,
    resetModifyLmsCourseFaqDetail,

    setUpdateLmsCourseFaqListOrderLoading,
    setUpdateLmsCourseFaqListOrderMessage,
    resetUpdateLmsCourseFaqListOrder,

    setDeleteLmsCourseFaqDetailLoading,
    setDeleteLmsCourseFaqDetailMessage,
    resetDeleteLmsCourseFaqDetail,

    // lms course slug
    setAddLmsCourseSlugDetailLoading,
    setAddLmsCourseSlugDetailMessage,
    resetAddLmsCourseSlugDetail,

    setDestroyLmsCourseSlugDetailLoading,
    setDestroyLmsCourseSlugDetailMessage,
    resetDestroyLmsCourseSlugDetail,

    //lms course instructor
    setAddLmsCourseInstructorDetailLoading,
    setAddLmsCourseInstructorDetailMessage,
    resetAddLmsCourseInstructorDetail,

    setModifyLmsCourseInstructorDetailLoading,
    setModifyLmsCourseInstructorDetailMessage,
    resetModifyLmsCourseInstructorDetail,

    setDestroyLmsCourseInstructorDetailLoading,
    setDestroyLmsCourseInstructorDetailMessage,
    resetDestroyLmsCourseInstructorDetail,

    // lms course student
    setLmsCourseStudentListLoading,
    setLmsCourseStudentListData,
    setLmsCourseStudentListMessage,
    resetLmsCourseStudentList,

    setAddLmsCourseStudentDetailLoading,
    setAddLmsCourseStudentDetailMessage,
    resetAddLmsCourseStudentDetail,

    setModifyLmsCourseStudentDetailLoading,
    setModifyLmsCourseStudentDetailMessage,
    resetModifyLmsCourseStudentDetail,

    setDestroyLmsCourseStudentDetailLoading,
    setDestroyLmsCourseStudentDetailMessage,
    resetDestroyLmsCourseStudentDetail,

    // lms course invitation
    setLmsCourseInvitationListLoading,
    setLmsCourseInvitationListData,
    setLmsCourseInvitationListMessage,
    resetLmsCourseInvitationList,

    setSendLmsCourseInvitationDetailLoading,
    setSendLmsCourseInvitationDetailData,
    setSendLmsCourseInvitationDetailMessage,
    resetSendLmsCourseInvitationDetail,

    setSendLmsCourseInvitationDetailPayload,
    resetSendLmsCourseInvitationDetailPayload,

    setValidateLmsCourseInvitationDetailLoading,
    setValidateLmsCourseInvitationDetailData,
    setValidateLmsCourseInvitationDetailMessage,
    resetValidateLmsCourseInvitationDetail,

    setAcceptAndDeclineLmsCourseInvitationDetailData,
    setAcceptAndDeclineLmsCourseInvitationDetailLoading,
    setAcceptAndDeclineLmsCourseInvitationDetailMessage,
    resetAcceptAndDeclineLmsCourseInvitationDetail,

    setDestroyLmsCourseInvitationDetailLoading,
    setDestroyLmsCourseInvitationDetailMessage,

    // lms course student section lesson
    setAddDestroyLmsCourseStudentSectionLessonLoading,
    setAddDestroyLmsCourseStudentSectionLessonMessage,
    resetAddDestroyLmsCourseStudentSectionLesson,

    // lms course student quiz response
    setAddLmsCourseStudentQuizResponseDetailLoading,
    setAddLmsCourseStudentQuizResponseDetailData,
    setAddLmsCourseStudentQuizResponseDetailMessage,
    resetAddLmsCourseStudentQuizResponseDetail,

    setModifyLmsCourseStudentQuizResponseDetailLoading,
    setModifyLmsCourseStudentQuizResponseDetailMessage,
    resetModifyLmsCourseStudentQuizResponseDetail,

    /* lms course student note */
    setAddLmsCourseStudentNoteDetailLoading,
    setAddLmsCourseStudentNoteDetailData,
    setAddLmsCourseStudentNoteDetailMessage,
    resetAddLmsCourseStudentNoteDetail,

    setLmsCourseStudentNoteListLoading,
    setLmsCourseStudentNoteListData,
    setLmsCourseStudentNoteListMessage,
    resetLmsCourseStudentNoteList,

    setLmsCourseStudentNoteDetailLoading,
    setLmsCourseStudentNoteDetailData,
    setLmsCourseStudentNoteDetailMessage,
    resetLmsCourseStudentNoteDetail,

    setModifyLmsCourseStudentNoteDetailLoading,
    setModifyLmsCourseStudentNoteDetailData,
    setModifyLmsCourseStudentNoteDetailMessage,
    resetModifyLmsCourseStudentNoteDetail,

    setDestroyLmsCourseStudentNoteDetailLoading,
    setDestroyLmsCourseStudentNoteDetailData,
    setDestroyLmsCourseStudentNoteDetailMessage,
    resetDestroyLmsCourseStudentNoteDetail,

    // page based reducers
    setLmsCoursePageLectureListLoading,
    setLmsCoursePageLectureListData,
    setLmsCoursePageLectureListMessage,
    resetLmsCoursePageLectureList,

    setLmsCoursePageArticleListLoading,
    setLmsCoursePageArticleListData,
    setLmsCoursePageArticleListMessage,
    resetLmsCoursePageArticleList,

    setLmsCoursePageQuizListLoading,
    setLmsCoursePageQuizListData,
    setLmsCoursePageQuizListMessage,
    resetLmsCoursePageQuizList,
} = lmsCourse.actions;

export default lmsCourse.reducer;