import React from "react";

//Helper functions
import getTimestamps from "../../helpers/getTimestamps";

//Style imports
import { StyledMeetingScheduled } from "./MeetingScheduled.styles";

export default function MeetingScheduled({ meetingDate }) {
  const displayedMeetingDate = getTimestamps(meetingDate);

  return (
    <StyledMeetingScheduled>
      <p>{`Meeting ${displayedMeetingDate}`}</p>
    </StyledMeetingScheduled>
  );
}
