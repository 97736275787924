import { useEffect, useState } from "react";
import { cn } from "utils/cn.utils";
import Slider from 'react-input-slider';

import { AnimatePresence, motion } from "framer-motion";

import { AiFillPlusCircle } from 'react-icons/ai';
import { AiFillMinusCircle } from 'react-icons/ai';

import { OptionSelector } from 'components/common-components/Select';

import { amountOptionList } from "../data";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setAddTokensPayload } from 'redux/wallet/wallet.slice';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';
import { setAddPaymentIntentDetailPayload } from "redux/payment/payment.slice";
import { paymentTypeEnum } from "redux/payment/payment.const";

const MIN_AMOUNT_TO_ADD = 1
const MAX_AMOUNT_TO_ADD = 1500

const MIN_INR_AMOUNT_TO_ADD = 100
const MAX_INR_AMOUNT_TO_ADD = 1500

const currencyObj = {
    INR: 200,
    USD: 20000,
    AUD: 10000
}

const getTokenCountWithSeparator = (tokenCount) => {
    return Intl.NumberFormat().format(tokenCount)
}

const TokenItemSection = ({ onHandleSelectCurrency }) => {
    const { user } = useAppState((state) => state.user)
    const { addTokens } = useAppState(s => s.wallet)
    const { addPaymentIntentDetail } = useAppState((state) => state.payment)

    const dispatcher = useAppDispatcher()

    const [isCountUpdated, setIsCountUpdated] = useState(false)

    const handleInputChange = (value) => {
        let masterCurrency = addTokens?.addTokenPayload?.currencyCode

        let walletCredit = [masterCurrencyCodeEnum.USD.value]?.includes(masterCurrency)
            ? user?.user?.netWalletCredit
            : [masterCurrencyCodeEnum.AUD.value]?.includes(masterCurrency)
                ? user?.user?.netAudWalletCredit
                : user?.user?.netInrWalletCredit

        let gatewayAmount = Math.ceil(addTokens?.addTokenPayload?.amount - walletCredit)
        let calculatedAmount = addTokens?.addTokenPayload?.amount

        if ([masterCurrencyCodeEnum.INR.value]?.includes(addTokens?.addTokenPayload?.currencyCode)) {
            calculatedAmount = value < MIN_INR_AMOUNT_TO_ADD ? MIN_INR_AMOUNT_TO_ADD * 100 : value > MAX_INR_AMOUNT_TO_ADD ? MAX_INR_AMOUNT_TO_ADD * 100 : value * 100;
            gatewayAmount = Math.ceil(calculatedAmount - walletCredit)
            dispatcher(setAddTokensPayload({
                ...addTokens?.addTokenPayload,
                amount: calculatedAmount,
                total_tokens: (calculatedAmount * currencyObj.INR) / 100,
                payThroughGateWay: gatewayAmount > 0,
                payThroughWallet: !(gatewayAmount > 0)
            }))

        } else if ([masterCurrencyCodeEnum.USD.value]?.includes(addTokens?.addTokenPayload?.currencyCode)) {
            calculatedAmount = value < MIN_AMOUNT_TO_ADD ? MIN_AMOUNT_TO_ADD * 100 : value > MAX_AMOUNT_TO_ADD ? MAX_AMOUNT_TO_ADD * 100 : value * 100;
            gatewayAmount = Math.ceil(calculatedAmount - walletCredit)
            dispatcher(setAddTokensPayload({
                ...addTokens?.addTokenPayload,
                amount: calculatedAmount,
                total_tokens: (calculatedAmount * currencyObj.USD) / 100,
                payThroughGateWay: gatewayAmount > 0,
                payThroughWallet: !(gatewayAmount > 0)
            }))
        } else if ([masterCurrencyCodeEnum.AUD.value]?.includes(addTokens?.addTokenPayload?.currencyCode)) {
            calculatedAmount = value < MIN_AMOUNT_TO_ADD ? MIN_AMOUNT_TO_ADD * 100 : value > MAX_AMOUNT_TO_ADD ? MAX_AMOUNT_TO_ADD * 100 : value * 100;
            gatewayAmount = Math.ceil(calculatedAmount - walletCredit)
            dispatcher(setAddTokensPayload({
                ...addTokens?.addTokenPayload,
                amount: calculatedAmount,
                total_tokens: (calculatedAmount * currencyObj.AUD) / 100,
                payThroughGateWay: gatewayAmount > 0,
                payThroughWallet: !(gatewayAmount > 0)
            }))
        }

        if (gatewayAmount > 0) {
            let amountToPay = (calculatedAmount >= 100 ? calculatedAmount : 100)
            let processingFeeAmount = Math.ceil(amountToPay * user?.user?.processingFeePct / 100)

            let totalAmountToPay = Math.ceil(amountToPay + processingFeeAmount)

            dispatcher(setAddPaymentIntentDetailPayload({
                ...addPaymentIntentDetail?.payload,
                paymentType: paymentTypeEnum.GPT_TOKEN.value,
                email: user?.user?.email,
                name: `${user?.user?.firstName} ${user?.user?.lastName}`,
                description: paymentTypeEnum.GPT_TOKEN.value,
                gatewayName: addTokens?.addTokenPayload?.gatewayName,
                currency: masterCurrency?.toLowerCase(),
                amountToPay: amountToPay,
                processingFeePct: user?.user?.processingFeePct,
                processingFee: processingFeeAmount,
                totalAmountToPay: totalAmountToPay,
            }))
        }
        setIsCountUpdated(!isCountUpdated)
    }

    return (
        <div className={"space-y-3"}>
            <div className={"font-bodyPri font-medium text-lg text-text-800 space-x-3"}>
                <span>{"1"}</span>
                <span>{"Review Token Item"}</span>
            </div>
            <div className={"W-full rounded-lg bg-white shadow-all p-5 space-y-8"}>
                <div className={"w-full flex items-center justify-center"}>
                    <div className={"w-full sm:max-w-sm flex flex-col justify-center gap-8"}>
                        <div className={"w-full flex items-center justify-around gap-3"}>
                            <AiFillMinusCircle
                                onClick={() => handleInputChange(parseFloat(addTokens?.addTokenPayload?.amount / 100) - 10)}
                                className={"text-text-500 hover:text-text-700 text-3xl cursor-pointer"}
                            />
                            <div className={cn(
                                "flex items-center justify-center gap-2",
                                "text-primary-dark text-xl font-bodyPri font-extrabold tracking-wider"
                            )}>
                                <OptionSelector
                                    className={""}
                                    options={Object.values(masterCurrencyCodeEnum)}
                                    value={addTokens?.addTokenPayload?.currencyCode}
                                    onChange={(option) => onHandleSelectCurrency(option?.value)}
                                />
                                <input
                                    className={"w-20 font-bodyPri font-extrabold tracking-wider input-number-spin-none focus:outline-none select-none text-center"}
                                    type={"number"}
                                    value={parseInt(addTokens?.addTokenPayload?.amount / 100)}
                                    onChange={event => handleInputChange(parseFloat(event.target.value))}
                                />
                            </div>
                            <AiFillPlusCircle
                                onClick={() => handleInputChange(parseFloat(addTokens?.addTokenPayload?.amount / 100) + 10)}
                                className={"text-text-500 hover:text-text-700 text-3xl cursor-pointer"}
                            />
                        </div>
                        <div className={"w-full flex items-center justify-center"}>
                            <Slider
                                axis="x"
                                xmin={[masterCurrencyCodeEnum.INR.value]?.includes(addTokens?.addTokenPayload?.currencyCode?.toUpperCase()) ? MIN_INR_AMOUNT_TO_ADD : MIN_AMOUNT_TO_ADD}
                                xmax={[masterCurrencyCodeEnum.INR.value]?.includes(addTokens?.addTokenPayload?.currencyCode?.toUpperCase()) ? MAX_INR_AMOUNT_TO_ADD : MAX_AMOUNT_TO_ADD}
                                x={addTokens?.addTokenPayload?.amount / 100}
                                onChange={({ x }) => handleInputChange(parseFloat(x))}
                                styles={{
                                    width: "100%",
                                    track: {
                                        backgroundColor: "#e0e0e0",
                                        height: 8,
                                    },
                                    active: {
                                        backgroundColor: "#0049b7"
                                    },
                                    thumb: {
                                        backgroundColor: "#fafafa",
                                        boxShadow: "0 0 5px 1px rgba(0,0,0, 0.3)",
                                        width: 15,
                                        height: 15
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                            />
                        </div>
                        <div className={"w-full grid grid-cols-4 gap-x-2 gap-y-3"}>
                            {amountOptionList[addTokens?.addTokenPayload?.currencyCode?.toUpperCase()]?.map((amountOption, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleInputChange(parseFloat(amountOption.amount))}
                                    className={cn(
                                        "col-span-1 flex items-center justify-center gap-1 w-14 py-1 rounded-lg cursor-pointer",
                                        "font-bodyPri font-medium text-base",
                                        ((amountOption.amount * 100) === addTokens?.addTokenPayload?.amount)
                                            ? "bg-primary-dark text-text-50"
                                            : "bg-primary-light text-primary-dark"
                                    )}>
                                    <span className={""}>
                                        {amountOption.label}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <AnimatePresence key={isCountUpdated}>
                            <motion.div
                                initial={{ opacity: 1, scale: 1 }}
                                animate={{ opacity: 1, scale: 1.1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                                className={"flex items-center justify-center"}
                            >
                                <span className={"font-bodyPri font-semibold text-text-800 text-base tracking-wide"}>
                                    {`You will get `}
                                    <span className={"text-primary-dark"}>
                                        {`${addTokens?.addTokenPayload?.total_tokens ? getTokenCountWithSeparator(addTokens?.addTokenPayload?.total_tokens) : 0}`}
                                    </span>
                                    {" tokens"}
                                </span>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TokenItemSection;