import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { FiSearch } from "react-icons/fi";
import { FaInfoCircle } from "react-icons/fa";

import { updateOfferingByCourseId } from 'redux/offering/offering.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setClearThumbnailDetailPayload, setThumbnailDetailPayload, setThumbnailDetailPayloadLoading } from 'redux/other/other.slice';
import { setClearUploadDocument, setUploadDocumentPayload } from 'redux/document/document.slice';
import { setOfferingCourse } from 'redux/offering/offering.slice';
import { modalConst } from 'redux/local/local.const';
import { fileCategories } from 'redux/document/document.const';
import { orientationTypeConst } from "redux/other/other.const";


const SelectThumbnails = () => {
    const { offeringCourse } = useAppState((s: any) => s.offering)
    const { uploadDocument } = useAppState((s: any) => s.document)
    const { thumbnailDetail } = useAppState((state: any) => state.other)
    const { modal } = useAppState((s: any) => s.local)

    const dispatcher = useAppDispatcher()
    const { courseId } = useParams()

    useEffect(() => {
        if (uploadDocument?.uploadDocument && (uploadDocument?.uploadDocumentPayload?.fileCategory === fileCategories.courseThumbnail.value)) {
            dispatcher(updateOfferingByCourseId(Number(courseId), {
                courseType: offeringCourse?.offeringCourse?.courseType,
                thumbnailPicUrl: uploadDocument?.uploadDocument?.documentUrl
            }))
            dispatcher(setClearUploadDocument())
        }
    }, [uploadDocument?.uploadDocument])

    useEffect(() => {
        if (thumbnailDetail?.thumbnailDetailPayload?.url) {
            dispatcher(setThumbnailDetailPayloadLoading(true))
            dispatcher(updateOfferingByCourseId(Number(courseId), {
                courseType: offeringCourse?.offeringCourse?.courseType,
                thumbnailPicUrl: thumbnailDetail?.thumbnailDetailPayload?.url
            }))
            setTimeout(() => {
                dispatcher(setClearThumbnailDetailPayload())
                dispatcher(setModal({
                    ...modal,
                    [modalConst.PHOTO_SEARCH_MODAL.stateKey]: false
                }))
            }, 2000)
        }
    }, [thumbnailDetail?.thumbnailDetailPayload?.url])

    const handleOnSelectImage = () => {
        dispatcher(setClearUploadDocument())
        dispatcher(setUploadDocumentPayload({
            ...uploadDocument?.uploadDocumentPayload,
            fileCategory: fileCategories.courseThumbnail.value
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.IMAGE_PICKER_MODAL.stateKey]: true
        }))
    }

    const onHandleSearchThumbnail = () => {
        dispatcher(setThumbnailDetailPayload({
            ...thumbnailDetail?.thumbnailDetailPayload,
            keywords: offeringCourse?.offeringCourse?.skills,
            query: offeringCourse?.offeringCourse?.category,
            orientation: orientationTypeConst.LANDSCAPE.value,
            dimensionUnit: 11
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.PHOTO_SEARCH_MODAL.stateKey]: true
        }))
    }

    return (
        <div className='w-full shadow-md bg-white space-y-5 p-5'>
            <div className={"w-full flex justify-between items-start gap-3 pb-1 border-b"}>
                <div className=''>
                    <div className="flex items-center justify-start gap-1">
                        <div className={"relative has-tooltip cursor-pointer flex items-start justify-start"}>
                            <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                            <span
                                className={cn(
                                    "w-56 px-2 py-0.5 font-bodyPri font-normal text-xs rounded shadow-lg bg-text-900 text-text-50 tooltip top-7",
                                    "flex flex-col items-start justify-start overflow-hidden"
                                )}
                            >
                                {"Thumbnail is a still image that acts as the preview image for your video or class."}
                            </span>
                        </div>
                        <div className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
                            {"Class Thumbnail"}
                        </div>
                    </div>
                    <div className="text-base text-text-700 font-normal font-bodyPri">
                        {"Upload your thumbnail or choose one"}
                    </div>
                </div>
            </div>
            <div className={"w-full mx-auto flex flex-wrap items-center justify-center gap-3"}>
                <div className={cn(
                    "min-w-80 max-w-full pl-5 pr-1 py-1 flex items-center justify-between cursor-default space-x-5",
                    "font-bodyPri font-normal text-text-500 text-base",
                    "border-2 border-divider-medium rounded-full hover:outline-none hover:border-2 hover:border-text-400"
                )}
                    onClick={onHandleSearchThumbnail}
                >
                    <span className={"overflow-hidden truncate"}>
                        {`e.g: ${offeringCourse?.offeringCourse?.category}`}
                    </span>
                    <span className={"flex items-center justify-center gap-1 rounded-full px-2 py-0.5 bg-primary-dark text-text-50 font-bodyPri font-light text-base"}>
                        <FiSearch className={"text-text-50 text-base"} />
                        <span className={"text-sm"}>
                            {"Search"}
                        </span>
                    </span>
                </div>
                <div className={"space-x-3"}>
                    <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                        {"Or"}
                    </span>
                    <span
                        className={cn(
                            "px-2 py-0.5 cursor-pointer border-2 border-primary-dark rounded-lg text-center",
                            "font-bodyPri font-normal text-primary-dark text-base",
                            "hover:bg-primary-dark hover:text-text-50"
                        )}
                        onClick={handleOnSelectImage}
                    >
                        {"Upload"}
                    </span>
                </div>
            </div>
            <div className={"w-full space-y-3"}>
                <hr className={"py-0.5"} />
                <div className={"w-full font-bodyPri font-normal text-text-900 text-base text-center"}>
                    {"Current Image"}
                </div>
                <div className={"mx-auto w-[50%] aspect-video rounded-lg overflow-hidden shadow-all-md"}>
                    <img
                        src={offeringCourse?.offeringCourse?.thumbnailPicUrl}
                        className={"w-full h-full object-cover"}
                        alt={"Thumbnail-photo"}
                    />
                </div>
            </div>
        </div>
    )
}

export default SelectThumbnails