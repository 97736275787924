import {useState} from "react";
import { useNavigate } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { BsCheckAll } from "react-icons/bs";
import { ImCross } from "react-icons/im";

import SideOverPanel from 'components/common-components/SideOverPanel';
import { HamburgerButton } from "../createCourse.style";
import HamburgerMenu from "./HamburgerMenu";

import { updateCourseDetail } from "redux/course/course.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";

import { pagesInfo } from "utils/pagesInfo";

export default function NavigationOptions({
    options,
    stepCount,
    setStepCount
}) {
    const {tutorCourseDetail, addCourseDetail} = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate();

    const [isScrolled, setIsScrolled] = useState(false);
    const [isMediumHamburger, setIsMediumHamburger] = useState(false);

    const onHandleNavigation = (stepNo) => {
        if (addCourseDetail?.isLoading || stepNo === stepCount) return;

        // if (courseTagList?.courseTagList?.results?.length < 3) {
        //     alert("Please add at least 3 skills!")
        //     return;
        // }
        if (stepNo === 4 && !tutorCourseDetail?.data?.courseAvailability?.id) {
            alert("Please submit your class schedule!")
            return;
        }

        if (stepNo === 5 && !tutorCourseDetail?.data?.coursePrice) {
            alert("Please submit your class price!")
            return;
        }

        setStepCount(stepNo)
        navigate(`${pagesInfo?.CREATE_COURSE?.pagePath}/${tutorCourseDetail?.data?.id}/edit?step=${stepNo}`)
        if (tutorCourseDetail?.data?.step < stepNo) {
            dispatcher(updateCourseDetail(tutorCourseDetail?.data?.id, { step: tutorCourseDetail?.data?.step + 1 }))
            return;
        }
    }

    return (
    <>
            <SideOverPanel
                child={
                <HamburgerMenu 
                setIsMediumHamburger={setIsMediumHamburger}
                options={options}
                stepCount={stepCount} 
                setStepCount={setStepCount}
                />
            }
                isOpen={isMediumHamburger || false}
                onClose={() => setIsMediumHamburger(false)}
            />
            
        <div className={cn(
            "w-full h-fit px-0 md:px-5 md:bg-white rounded-lg shadow-sm overflow-hidden overflow-x-scroll",
            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
            )}>
            <div className={"md:hidden"}>
                <div className={"w-full flex items-center justify-between gap-3"}>
                <HamburgerButton
                    onClick={() => setIsMediumHamburger(true)}
                    isScrolled={isScrolled}
                />
                        <span
                            className="p-1.5 bg-white border border-white rounded-full shadow-md cursor-pointer"
                            title={"Close"}
                            onClick={() => navigate(pagesInfo?.TEMPLATE?.pagePath)}
                        >
                            <ImCross className={"text-text-400 hover:text-text-600 text-xl"} />
                        </span>
                    </div>
            </div>

            <div className="hidden w-full md:flex md:items-center md:justify-start">
                {options.map((option, index) => (
                    <div key={index} className={cn(
                        "w-full flex items-center justify-start",
                        (stepCount == option?.stepNo)
                            ? "border-b-2 border-primary-main text-primary-main"
                            : "border-b-2 border-white"
                        )}>
                    <div
                        key={index}
                        onClick={() => onHandleNavigation(option?.stepNo)}
                        className={"w-full px-3 py-2 flex items-center justify-center gap-2 text-center truncate cursor-pointer"}>
                        <div className={cn(
                            "w-16 h-16 p-2 overflow-hidden rounded-full bg-divider-medium",
                            (tutorCourseDetail?.data?.step > option?.stepNo) && "bg-opacity-90",
                            (tutorCourseDetail?.data?.step <= option?.stepNo) && "bg-opacity-40"
                            )}>
                            <img 
                            src={option?.image}
                            className={"w-full h-full object-cover"}
                            alt={"Image"}
                            />
                        </div>
                        <div className={"flex flex-col items-start justify-start gap-2"}>
                                {(tutorCourseDetail?.data?.step <= option?.stepNo) &&
                                    <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                                        {`Step ${option?.stepNo}`}
                                    </span>
                                }
                                {(tutorCourseDetail?.data?.step > option?.stepNo) &&
                                <div className={"flex items-center justify-start gap-1"}>
                                    <BsCheckAll className={"text-green-600 text-base"}/>
                                    <span className={"font-bodyPri font-normal text-sm text-green-600"}>
                                        {"Done"}
                                    </span>
                                </div>
                                }
                            <span className={"font-bodyPri font-semibold text-base"}>
                                {option.label}
                            </span>
                        </div>
                    </div>
                        {(index != (options?.length - 1)) &&
                            <span className={"h-24 rotate-180 border border-divider-darkLight overflow-hidden"}></span>
                    }
                    </div>
                ))}
            </div>
        </div>
        </>
    );
}














// import { useState } from "react";

// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Box from "@mui/material/Box";

// const a11yProps = (index) => {
//     return {
//         id: `simple-tab-${index}`,
//         "aria-controls": `simple-tabpanel-${index}`,
//     };
// };

// const CreateCourseSteps = ({ courseSteps, containerIDs }) => {
//     const [value, setValue] = useState(0);
//     const [ABOUT_ME, SKILLS, TOPICS, CLASSES, REVIEWS] = containerIDs;

//     const handleChange = (event, newValue) => {

//         setValue(newValue);

//         switch (newValue) {
//             case 0:
//                 handleScroll(ABOUT_ME);
//                 break;

//             case 1:
//                 handleScroll(SKILLS);
//                 break;

//             case 2:
//                 handleScroll(TOPICS);
//                 break;

//             case 3:
//                 handleScroll(CLASSES);
//                 break;

//             case 4:
//                 handleScroll(REVIEWS);
//                 break;

//             default:
//                 handleScroll(ABOUT_ME);
//                 break;
//         }
//     };

//     const handleScroll = (selectedID) => {
//         if (selectedID) {
//             const section = document.querySelector(`#${selectedID}`);
//             // section.scrollIntoView({ behavior: "smooth", block: "start", alignToTop: false });
//             window.scrollTo({
//                 behavior: "smooth",
//                 left: 0,
//                 top: section.offsetTop - 50,
//             });
//         }
//     };

//     return (
//         <Box sx={{ width: "100%" }}>
//             <Box>
//                 <Tabs
//                     className={"w-full flex items-center justify-center"}
//                     value={value}
//                     onChange={handleChange}
//                     aria-label={"basic tabs example"}
//                 >
//                     {courseSteps &&
//                         courseSteps.map((courseStep, idx) => (
//                             <Tab
//                                 key={idx}
//                                 label={courseStep?.label}
//                                 {...a11yProps(idx)}
//                                 style={{
//                                     textTransform: "capitalize",
//                                     fontSize: 16,
//                                     fontWeight: 400,
//                                 }}
//                             />
//                         ))}
//                 </Tabs>
//             </Box>
//         </Box>

        
//     );
// };

// export default CreateCourseSteps;