import React from 'react'

const LiveClassCardUpdatedLoader = ({ isLoading = false }) => {
    return isLoading ? (
        <div className='animate-pulse rounded-lg shadow h-32 w-full bg-white space-y-3'>
            <div className='flex'>
                <div className='flex flex-col justify-between h-32 w-full md:w-2/3 p-3'>
                    <div className='flex w-full gap-20 justify-between'>
                        <div className='h-10 w-full bg-slate-200 animate-pulse rounded' />
                        <div className='h-6 w-32 bg-slate-200 animate-pulse rounded' />
                    </div>
                    <div className='flex w-full gap-20 justify-between'>
                        <div className='flex gap-3'>
                            <div className='h-5 w-5 bg-slate-200 animate-pulse rounded-full' />
                            <div className='h-5 w-5 bg-slate-200 animate-pulse rounded-full' />
                            <div className='h-5 w-5 bg-slate-200 animate-pulse rounded-full' />
                            <div className='h-5 w-5 bg-slate-200 animate-pulse rounded-full' />
                            <div className='h-5 w-5 bg-slate-200 animate-pulse rounded-full' />
                        </div>
                        <div className='h-8 w-40 bg-slate-200 animate-pulse rounded-full' />
                    </div>
                </div>
                <div className='hidden h-32 md:flex w-1/3 rounded-lg overflow-hidden bg-slate-200 animate-pulse' ></div>

            </div>
        </div>
    ) : null
}

export default LiveClassCardUpdatedLoader