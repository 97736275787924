import {
    Container,
    Content,
    Header,
    Heading,
    SubHeading,
    ItemContainer
} from "./LearningNeverStopsStyle"
import { learningData } from './LearningNeverStopsData';
import LearningItem from "./LearningItem";

const LearningNeverStops = () => {

    const panels = document.querySelectorAll(".panel")

    panels.forEach((panel) => {
        panel.addEventListener("mouseenter", () => {
            removeActiveClasses();
            panel.classList.add("active")
        })
    })

    const removeActiveClasses = () => {
        panels.forEach((panel) => {
            panel.classList.remove("active")
        })
    }


    return (
        <Container>
            <Content>
                <Header>
                    <Heading>{'Learning never stops at Edulyte'}</Heading>
                    <SubHeading>{'We have developed tools to seamlessly connect learners with passionate and skilled teachers!'}</SubHeading>
                </Header>
                <ItemContainer>
                    {learningData.map((learningItem, index) => (
                        <LearningItem key={index} learningItem={learningItem} className={`panel ${index === 0 ? "active" : ""}`} />
                    ))}
                </ItemContainer>
            </Content>
        </Container>
    )
}

export default LearningNeverStops;
