import { availabilityEndTypeEnum, availabilityIsRecurringEnum, availabilityTypeEnum, weekAvailTimeSlots } from "redux/availability/availability.const"

import { dayjs, timeZone } from "utils/dateTime.utils";

export const profileDetailSections = {
    MY_STORY: {
        value: "my_story"
    },
    MY_SKILLS_STYLE: {
        value: "my_skills_style"
    },
    INTRO_VIDEO: {
        value: "intro_video"
    },
    PRICE: {
        value: "price"
    },
    WEEKLY_SCHEDULE: {
        value: "weeklySchedule"
    },
    LESSONS: {
        value: "lessons"
    }
}

export const titleOptions = {
    MR: {
        label: "Mr",
        value: "Mr",
    },
    MS: {
        label: "Ms",
        value: "Ms"
    },
    MRS: {
        label: "Mrs",
        value: "Mrs"
    },
    MISS: {
        label: "Miss",
        value: "Miss"
    },
    Dr: {
        label: "Dr",
        value: "Dr"
    },
    SIR: {
        label: "Sir",
        value: "Sir"
    }
}

export const genderOptions = {
    MALE: {
        label: "Male",
        value: "male",
    },
    FEMALE: {
        label: "Female",
        value: "female"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}

export const profileEditBtnConst = {
    PROFILE_PIC_URL: {
        value: "profilePicUrl"
    },
    TITLE: {
        value: "title"
    },
    FIRST_NAME: {
        value: "firstName"
    },
    LAST_NAME: {
        value: "lastName"
    },
    PREFERRED_NAME: {
        value: "preferredName"
    },
    GENDER: {
        value: "gender"
    },
    DATE_OF_BIRTH: {
        value: "dateOfBirth"
    },
    CURRENT_COUNTRY: {
        value: "currentCountry"
    },
    HOME_COUNTRY: {
        value: "homeCountry"
    },
    ELEVATOR_PITCH: {
        value: "elevatorPitch"
    },
    ABOUT_ME: {
        value: "aboutMe"
    },
    SPEAKS: {
        value: "speaks"
    },
    QUALIFICATIONS: {
        value: "qualifications"
    },

    TEACHING_STYLE: {
        value: "teachingStyle"
    },
    SKILLS: {
        value: "skills"
    },
    SUBJECT_TOPIC: {
        value: "subjectAndTopics"
    },
    PRICE: {
        value: "price"
    },
    SCHEDULE: {
        value: "schedule"
    }
}

export const myPriceKeyConst = {
    TITLE: {
        value: "title"
    },
    SUB_TITLE: {
        value: "subtitle"
    },
    AVAILABILITY: {
        value: "availability"
    },
    DESCRIPTION: {
        value: "description"
    },
    DURATION: {
        value: "duration"
    },
    PRICE_MODEL: {
        value: "price_model"
    },
    PRICE: {
        value: "price"
    },
    DISCOUNT_PCT: {
        value: "discount_pct"
    }
}

export const sessionDurationConst = {
    THIRTY: {
        label: "30 min",
        value: 30
    },
    FORTY_FIVE: {
        label: "45 min",
        value: 45
    },
    SIXTY: {
        label: "60 min",
        value: 60
    },
    CUSTOM: {
        label: "Custom",
        value: "custom"
    }
}

export const bulkBookingDiscountConst = [
    {
        label: "5%",
        value: 5
    },
    {
        label: "10%",
        value: 10
    },
    {
        label: "15%",
        value: 15
    },
    {
        label: "20%",
        value: 20
    }
]

const lessonPrefConst = {
    autoConfirmLesson: {
        label: "Automatically Confirm Lessons",
        description: "Using this option would confirm all lesson request automatically."
    },
    instantBookNewStudent: {
        label: "Accept Lesson Request from current student",
        description: "If green, we will confirm all new requests/ bookings automatically from any student you met at least once."
    },
    instantBookCurrentStudent: {
        label: "Accept Lesson Request from new student",
        description: "If Green, we will confirm all new requests/ bookings automatically from new students."
    },
    allowNewStudent: {
        label: "Instant Booking From Current Student",
        description: "If Green, your current students can book start a meeting with you after making payment.You must be online and not in a meeting."
    },
    allowCurrentStudent: {
        label: "Instant Booking From New Student",
        description: "If Green, your new students can book start a meeting with you after making payment. You must be online and not in a meeting."
    }
}

export const setGetLessonPref = (lessonPref) => (
    Object.keys(lessonPref)?.filter((item) => item === "autoConfirmLesson")?.map((lesson) => ({
        key: lesson,
        label: lessonPrefConst[lesson]?.label,
        description: lessonPrefConst[lesson]?.description,
        value: lessonPref[lesson]
    }))
)

export const ctaButtonsConst = [
    {
        value: "getBlueCheckmark",
        title: "Get Blue Checkmark",
        description: "Stand out as a trusted provider",
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/tutor-onboarding/blue-tick.png",
        href: "https://buy.stripe.com/6oE4gX8jv1AAePedQR",
    },
    {
        value: "tailoredMarketingSupport",
        title: "Tailored Marketing Support",
        description: "Grow with custom marketing",
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/tutor-onboarding/marketing.png",
        href: "https://www.edulyte.com/exclusive-marketing-solutions-for-educators/",
    },
    {
        value: "listOnMarketPlace",
        title: "List on Marketplace",
        description: "Proceed with screening",
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/tutor-onboarding/list-on-marketplace.png",
        href: "",
    },
    {
        value: "exploreFaq",
        title: "Explore FAQs",
        description: "Common questions answered",
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/tutor-onboarding/explore-faq.png",
        href: "https://support.edulyte.com/portal/en/kb/tutor-helpdesk",
    }
]

export const navigationButtons = [
    {
        title: "Tools",
        url: "/tools"
    },
    {
        title: "Create Class",
        url: "/create-class"
    },
    {
        title: "My Dashboard",
        url: "/dashboard"
    }
]

export const onboardingTooltipConst = {
    elevatorPitchTooltip: "For your public profile, have a snappy one- line for Me in a sentence field.It's like an elevator pitch with a few words. A few words to define you as an educator.",
    aboutMeTooltip: "Include your compelling / aspirational story or background, what you teach and why in the About me field, as your potential learners will keenly go through it.",
    languageTooltip: "Write language(s) of instructions. Drag, drop and save to organise their display order.",
    teachingStyleTooltip: "Write briefly about your creative, innovative teaching style.",
    skillsToolTip: "Write 3-5 skills your learners will acquire in this class. Drag, drop and save to organise their display order.",
    videoTooltip: "Your intro video must focus on learners highlighting your brief background. Why should they learn from you? Talk about benefits from your sessions, What will they learn? How will they learn etc.The tone should motivate learners to sign up for your sessions.",
    trialLessonTooltip: "Set hourly fee for your trial sessions here. This is what learners will pay. Please make sure to account for fees and taxes.",
    oneOnOneChargeSessionTooltip: "Set your default hourly fee you are willing to accept after platform fee and taxes. You will be able to set fees for your classes while creating them. This input will be used for internal decision making.",
    GroupChargeSessionTooltip: "Default per session fee for 2 or more students in a group. This is the fee you are willing to accept after platform fee and taxes. You will be able to set fees for your classes while creating them. This input will be used for internal decision making.",
    bulkBookingTooltip: "Offer discounts to learners who book 5 sessions or more in one order(package of lessons).This input will be used for all packaged classes."
}

export const tutorPriceDefaultBody = (priceResponseData) => {
    return {
        minGroupCoursePrice: priceResponseData?.minGroupCoursePrice,
        minOneToOneCoursePrice: priceResponseData?.minOneToOneCoursePrice,
        pctDisOnFiveOneToOneSession: priceResponseData?.pctDisOnFiveOneToOneSession
    }
}

export const tutorPricePayloadBody = (priceLocalPayload) => {
    return {
        minGroupCoursePrice: priceLocalPayload?.minGroupCoursePrice,
        minOneToOneCoursePrice: priceLocalPayload?.minOneToOneCoursePrice,
        pctDisOnFiveOneToOneSession: priceLocalPayload?.pctDisOnFiveOneToOneSession
    }
}

// availability functions
export const validateAvailabilityDetail = (responseData, requestPayload) => {
    let isDataSimilarOrInvalid = false

    const responsePayload = {
        name: responseData?.name,
        isRecurring: responseData?.isRecurring,
        weeklyTimeSlots: responseData?.weeklyTimeSlots || weekAvailTimeSlots,
        dateTimeSlots: responseData?.dateTimeSlots || [],
        startDateTime: responseData?.startDateTime || null,
        endType: responseData?.endType || null,
        weeklyCycle: responseData?.weeklyCycle || null,
        endDateTime: responseData?.endDateTime || null,
        description: responseData?.description || "",
        minTimeBeforeAvailStart: responseData?.minTimeBeforeAvailStart,
        advanceSlotPeriodDays: responseData?.advanceSlotPeriodDays
    }

    const localPayload = {
        name: requestPayload?.name,
        isRecurring: requestPayload?.isRecurring,
        weeklyTimeSlots: requestPayload?.weeklyTimeSlots || weekAvailTimeSlots,
        dateTimeSlots: requestPayload?.dateTimeSlots || [],
        startDateTime: requestPayload?.startDateTime || null,
        endType: requestPayload?.endType || null,
        weeklyCycle: requestPayload?.weeklyCycle || null,
        endDateTime: requestPayload?.endDateTime || null,
        description: requestPayload?.description || "",
        minTimeBeforeAvailStart: requestPayload?.minTimeBeforeAvailStart,
        advanceSlotPeriodDays: requestPayload?.advanceSlotPeriodDays
    }

    if (!requestPayload?.name) {
        isDataSimilarOrInvalid = true
    }
    if (!requestPayload?.isRecurring) {
        isDataSimilarOrInvalid = true
    }
    if ([availabilityIsRecurringEnum.RECURRING.value]?.includes(requestPayload?.isRecurring)) {
        if ((requestPayload?.endType === availabilityEndTypeEnum?.ON_DATE?.value) && !requestPayload?.endDateTime) {
            isDataSimilarOrInvalid = true
        }
        if ((requestPayload?.endType === availabilityEndTypeEnum?.WEEKLY_CYCLE?.value) && !requestPayload?.weeklyCycle) {
            isDataSimilarOrInvalid = true
        }
    }
    if ([availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(requestPayload?.isRecurring) && (requestPayload?.dateTimeSlots?.length === 0)) {
        isDataSimilarOrInvalid = true
    }
    isDataSimilarOrInvalid = JSON.stringify(responsePayload) === JSON.stringify(localPayload)

    return isDataSimilarOrInvalid;
}

export const setUserAvailabilityDetailPayload = async (availabilityData) => {
    let requestPayload = {}

    if (availabilityData?.user?.userId) {
        requestPayload["userId"] = availabilityData?.user?.userId
    }
    if (availabilityData?.name) {
        requestPayload["name"] = availabilityData?.name
    }
    if (availabilityData?.description) {
        requestPayload["description"] = availabilityData?.description
    }
    if (availabilityData?.minTimeBeforeAvailStart) {
        requestPayload["minTimeBeforeAvailStart"] = availabilityData?.minTimeBeforeAvailStart
    }
    if (availabilityData?.advanceSlotPeriodDays) {
        requestPayload["advanceSlotPeriodDays"] = availabilityData?.advanceSlotPeriodDays
    }
    if (availabilityData?.type) {
        requestPayload["type"] = availabilityData?.type
    }
    if (availabilityData?.isRecurring) {
        requestPayload["isRecurring"] = availabilityData?.isRecurring
    }
    if ([availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(availabilityData?.isRecurring)) {
        requestPayload["dateTimeSlots"] = availabilityData?.dateTimeSlots
        requestPayload["endType"] = null
        requestPayload["startDateTime"] = null
        requestPayload["endDateTime"] = null
        requestPayload["weeklyCycle"] = null
    }
    if ([availabilityIsRecurringEnum.RECURRING.value]?.includes(availabilityData?.isRecurring)) {
        if (availabilityData?.weeklyTimeSlots) {
            requestPayload["weeklyTimeSlots"] = availabilityData?.weeklyTimeSlots
        }
        if (availabilityData?.startDateTime) {
            requestPayload["startDateTime"] = availabilityData?.startDateTime || dayjs()?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss")
        }
        if (availabilityData?.endType) {
            requestPayload["endType"] = availabilityData?.endType || availabilityEndTypeEnum.FOREVER.value
        }
        if ([availabilityEndTypeEnum.ON_DATE.value]?.includes(availabilityData?.endType)) {
            requestPayload["endDateTime"] = availabilityData?.endDateTime || null
        }
        if ([availabilityEndTypeEnum.WEEKLY_CYCLE.value]?.includes(availabilityData?.endType)) {
            requestPayload["weeklyCycle"] = availabilityData?.weeklyCycle || null
        }
    }
    return requestPayload;
}

export const validateUserAvailabilityDetail = async (payload) => {
    let requestPayload = {}
    let errorMsg = null

    if (!payload?.name) {
        errorMsg = "Please enter name!"
        return { errorMsg, requestPayload }
    }
    if (!payload?.minTimeBeforeAvailStart) {
        errorMsg = "Please enter minimum availability time!"
        return { errorMsg, requestPayload }
    }
    if (!payload?.advanceSlotPeriodDays) {
        errorMsg = "Please enter maximum advance booking time!"
        return { errorMsg, requestPayload }
    }

    if ((payload?.endType === availabilityEndTypeEnum?.ON_DATE?.value) && !payload?.endDateTime) {
        errorMsg = "Please enter end date for the availability!"
        return { errorMsg, requestPayload }
    }
    if ((payload?.endType === availabilityEndTypeEnum?.WEEKLY_CYCLE?.value) && !payload?.weeklyCycle) {
        errorMsg = "Please enter weekly cycle count!"
        return { errorMsg, requestPayload }
    }
    if ([availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(payload?.isRecurring) && (payload?.dateTimeSlots?.length === 0)) {
        errorMsg = "Please add date time slots!"
        return { errorMsg, requestPayload }
    }
    if ([availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(payload?.isRecurring)) {
        const filteredTimeSlots = payload?.dateTimeSlots?.filter((item) => item?.timeSlots?.length === 0)
        if (!!filteredTimeSlots?.length) {
            errorMsg = "Please add date time slots!"
            return { errorMsg, requestPayload }
        }
    }

    requestPayload = {
        name: payload?.name,
        timeZone: timeZone,
        type: availabilityTypeEnum?.ONE_ON_ONE?.value,
        minTimeBeforeAvailStart: payload?.minTimeBeforeAvailStart,
        advanceSlotPeriodDays: payload?.advanceSlotPeriodDays,
        isRecurring: payload?.isRecurring,
    }
    if (payload?.description) {
        requestPayload["description"] = payload?.description
    }
    if ([availabilityIsRecurringEnum?.NON_RECURRING?.value]?.includes(payload?.isRecurring)) {
        requestPayload["dateTimeSlots"] = payload?.dateTimeSlots
    }
    if ([availabilityIsRecurringEnum?.RECURRING?.value]?.includes(payload?.isRecurring)) {
        let newStartDateTime = payload?.startDateTime
        let newEndDateTime = payload?.endDateTime

        // newStartDateTime = await getDateTimeSlotFromDay(payload)

        const startDayName = dayjs(newStartDateTime)?.tz(timeZone)?.format('dddd')

        const startDayTimeSlot = payload?.weeklyTimeSlots[startDayName?.slice(0, 3)?.toUpperCase()]
        if (startDayTimeSlot?.length === 0) {
            errorMsg = "You must have a session on the start date. Please update"
            return { requestPayload, errorMsg };
        } else {
            const startDateTimeFormat = dayjs(newStartDateTime)?.tz(timeZone)?.format("YYYY-MM-DD") + " " + startDayTimeSlot[0]?.startTime
            newStartDateTime = dayjs(startDateTimeFormat)?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss")
        }

        if (payload?.endType === availabilityEndTypeEnum?.ON_DATE?.value) {
            const endDayName = dayjs(payload?.endDateTime)?.tz(timeZone)?.format('dddd')

            const endDayTimeSlot = payload?.weeklyTimeSlots[endDayName?.slice(0, 3)?.toUpperCase()]
            if (endDayTimeSlot?.length === 0) {
                errorMsg = "You must have a session on the end date. Please update"
                return { requestPayload, errorMsg };
            } else {
                const endDateTimeFormat = dayjs(payload?.endDateTime)?.tz(timeZone)?.format("YYYY-MM-DD") + " " + endDayTimeSlot[0]?.endTime
                newEndDateTime = dayjs(endDateTimeFormat)?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss")
            }
        }
        requestPayload["weeklyTimeSlots"] = payload?.weeklyTimeSlots
        requestPayload["startDateTime"] = newStartDateTime
        requestPayload["endType"] = payload?.endType
        if (payload?.endType === availabilityEndTypeEnum?.ON_DATE?.value) {
            requestPayload["endDateTime"] = newEndDateTime
        }
        if (payload?.endType === availabilityEndTypeEnum?.WEEKLY_CYCLE?.value) {
            requestPayload["weeklyCycle"] = payload?.weeklyCycle
        }
    }

    return { requestPayload, errorMsg }
}