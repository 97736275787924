import { createSlice } from "@reduxjs/toolkit";
import { AUTH_INITIAL_STATE } from "redux/auth/auth.initalState";

const auth = createSlice({
    name: "auth",
    initialState: AUTH_INITIAL_STATE,
    reducers: {
        // reducers for token
        setAccessToken: (state, { payload }) => {
            state.session.accessToken = payload
            state.session.message = AUTH_INITIAL_STATE.session.message
        },

        // reducers for session
        setSessionLoading: (state, { payload }) => {
            state.session.isLoading = payload
        },
        setSessionLoggedIn: (state, { payload }) => {
            if (payload) {
                state.session.isLoading = payload.isLoading
                state.session.isLoggedIn = payload.isLoggedIn
                state.session.message = AUTH_INITIAL_STATE.session.message
            } else {
                state.session.isLoading = false
                state.session.isLoggedIn = true
                state.session.message = AUTH_INITIAL_STATE.session.message
            }
        },
        setSessionMessage: (state, { payload }) => {
            state.session.message = payload
            state.session.isLoggedIn = AUTH_INITIAL_STATE.session.isLoggedIn
            state.session.accessToken = AUTH_INITIAL_STATE.session.accessToken
        },
        resetSession: (state) => {
            state.session = AUTH_INITIAL_STATE.session
        },

        // reducers for log in
        setLogInLoading: (state, { payload }) => {
            state.logIn.isLoading = payload
        },
        setLogInData: (state, { payload }) => {
            state.logIn.data = payload
            state.logIn.message = AUTH_INITIAL_STATE.logIn.message
        },
        setLogInMessage: (state, { payload }) => {
            state.logIn.message = payload
            state.logIn.data = AUTH_INITIAL_STATE.logIn.data
        },
        resetLogIn: (state) => {
            state.logIn = AUTH_INITIAL_STATE.logIn
        },
        setLogInPayload: (state, { payload }) => {
            state.logIn.payload = payload
        },
        setClearLogInPayload: (state) => {
            state.logIn.payload = AUTH_INITIAL_STATE.logIn.payload
        },

        // reducers for sign up
        setSignUpLoading: (state, { payload }) => {
            state.signUp.isLoading = payload
        },
        setSignUpData: (state, { payload }) => {
            state.signUp.data = payload
            state.signUp.message = AUTH_INITIAL_STATE.signUp.message
        },
        setSignUpMessage: (state, { payload }) => {
            state.signUp.message = payload
            state.signUp.data = AUTH_INITIAL_STATE.signUp.data
        },
        resetSignUp: (state) => {
            state.signUp = AUTH_INITIAL_STATE.signUp
        },
        setSignUpPayload: (state, { payload }) => {
            state.signUp.payload = payload
        },
        setClearSignUpPayload: (state) => {
            state.signUp.payload = AUTH_INITIAL_STATE.signUp.payload
        },

        // reducers for sign up with token
        setSignupWithTokenLoading: (state, { payload }) => {
            state.signupWithToken.isLoading = payload
        },
        setSignupWithTokenPayload: (state, { payload }) => {
            state.signupWithToken.payload = payload
        },
        setSignupWithTokenData: (state, { payload }) => {
            state.signupWithToken.data = payload
            state.signupWithToken.message = AUTH_INITIAL_STATE.signupWithToken.message
        },
        setSignupWithTokenMessage: (state, { payload }) => {
            state.signupWithToken.message = payload
            state.signupWithToken.data = AUTH_INITIAL_STATE.signupWithToken.data
        },
        resetSignUpWithToken: (state) => {
            state.signupWithToken = AUTH_INITIAL_STATE.signupWithToken
        },

        // reducers for verify signup
        setVerifySignupLoading: (state, { payload }) => {
            state.verifySignup.isLoading = payload
        },
        setVerifySignupPayload: (state, { payload }) => {
            state.verifySignup.payload = payload
        },
        setVerifySignupData: (state, { payload }) => {
            state.verifySignup.data = payload
            state.verifySignup.message = AUTH_INITIAL_STATE.verifySignup.message
        },
        setVerifySignupStatus: (state, { payload }) => {
            state.verifySignup.status = payload
        },
        setVerifySignupMessage: (state, { payload }) => {
            state.verifySignup.message = payload
            state.verifySignup.data = AUTH_INITIAL_STATE.verifySignup.data
        },
        resetVerifySignup: (state) => {
            state.verifySignup = AUTH_INITIAL_STATE.verifySignup
        },

        // reducers for signup s2
        setSignupS2Loading: (state, { payload }) => {
            state.signupS2.isLoading = payload
        },
        setSignupS2Payload: (state, { payload }) => {
            state.signupS2.payload = payload
        },
        setSignupS2Data: (state, { payload }) => {
            state.signupS2.data = payload
            state.signupS2.message = AUTH_INITIAL_STATE.signupS2.message
        },
        setSignupS2Message: (state, { payload }) => {
            state.signupS2.message = payload
            state.signupS2.data = AUTH_INITIAL_STATE.signupS2.data
        },
        setSignupS2Status: (state, { payload }) => {
            state.signupS2.status = payload
        },
        resetSignupS2: (state) => {
            state.signupS2 = AUTH_INITIAL_STATE.signupS2
        },
        

        // reducers for verify referral
        setVerifyReferralLoading: (state, { payload }) => {
            state.verifyReferral.isLoading = payload
        },
        setVerifyReferral: (state, { payload }) => {
            state.verifyReferral.verifyReferral = payload
            state.verifyReferral.errorMsg = AUTH_INITIAL_STATE.verifyReferral.errorMsg
        },
        setVerifyReferralErrorMsg: (state, { payload }) => {
            state.verifyReferral.errorMsg = payload
            state.verifyReferral.verifyReferral = AUTH_INITIAL_STATE.verifyReferral.verifyReferral
        },
        setClearVerifyReferral: (state) => {
            state.verifyReferral = AUTH_INITIAL_STATE.verifyReferral
        },

        // reducers for logout
        setLogoutLoading: (state, { payload }) => {
            state.logout.isLoading = payload
        },
        setLogout: (state, { payload }) => {
            state.logout.logout = payload
            state.isLogedIn = false
            state.accessToken = null
            state.logout.errorMsg = AUTH_INITIAL_STATE.logout.errorMsg
        },
        setLogoutErrorMsg: (state, { payload }) => {
            state.logout.errorMsg = payload
            state.logout.logout = AUTH_INITIAL_STATE.logout.logout
        },
        setClearLogout: (state) => {
            state.logout = AUTH_INITIAL_STATE.logout
        },

        // reducers for reset password
        setAddResetPasswordLoading: (state, { payload }) => {
            state.addResetPassword.isLoading = payload
        },
        setAddResetPassword: (state, { payload }) => {
            state.addResetPassword.addResetPassword = payload
            state.addResetPassword.errorMsg = AUTH_INITIAL_STATE.addResetPassword.errorMsg
        },
        setAddResetPasswordErrorMsg: (state, { payload }) => {
            state.addResetPassword.errorMsg = payload
            state.addResetPassword.addResetPassword = AUTH_INITIAL_STATE.addResetPassword.addResetPassword
        },
        setClearAddResetPassword: (state) => {
            state.addResetPassword = AUTH_INITIAL_STATE.addResetPassword
        },

        // reducers for reset password payload
        setAddResetPasswordPayload: (state, { payload }) => {
            state.addResetPassword.addResetPasswordPayload = payload
        },
        setClearAddResetPasswordPayload: (state) => {
            state.addResetPassword.addResetPasswordPayload = AUTH_INITIAL_STATE.addResetPassword.addResetPasswordPayload
        },
        // reducers for update user auth
        setAddUserAuthLoading: (state, { payload }) => {
            state.addUserAuth.isLoading = payload
        },
        setAddUserAuth: (state, { payload }) => {
            state.addUserAuth.addUserAuth = payload
            state.addUserAuth.errorMsg = AUTH_INITIAL_STATE.addUserAuth.errorMsg
        },
        setAddUserAuthErrorMsg: (state, { payload }) => {
            state.addUserAuth.errorMsg = payload
            state.addUserAuth.addUserAuth = AUTH_INITIAL_STATE.addUserAuth.addUserAuth
        },
        setClearAddUserAuth: (state) => {
            state.addUserAuth = AUTH_INITIAL_STATE.addUserAuth
        },

        // reducers for update user auth payload
        setAddUserAuthPayload: (state, { payload }) => {
            state.addUserAuth.addUserAuthPayload = payload
        },
        setClearAddUserAuthPayload: (state) => {
            state.addUserAuth.addUserAuthPayload = AUTH_INITIAL_STATE.addUserAuth.addUserAuthPayload
        },

        // reducers for update user password
        setAddUserPasswordLoading: (state, { payload }) => {
            state.addUserPassword.isLoading = payload
        },
        setAddUserPassword: (state, { payload }) => {
            state.addUserPassword.addUserPassword = payload
            state.addUserPassword.errorMsg = AUTH_INITIAL_STATE.addUserPassword.errorMsg
        },
        setAddUserPasswordErrorMsg: (state, { payload }) => {
            state.addUserPassword.errorMsg = payload
            state.addUserPassword.addUserPassword = AUTH_INITIAL_STATE.addUserPassword.addUserPassword
        },
        setClearAddUserPassword: (state) => {
            state.addUserPassword = AUTH_INITIAL_STATE.addUserPassword
        },

        // reducers for verify auth 
        setVerifyAuthLoading: (state, { payload }) => {
            state.verifyAuth.isLoading = payload
        },
        setVerifyAuth: (state, { payload }) => {
            state.verifyAuth.verifyAuth = payload
            state.verifyAuth.errorMsg = AUTH_INITIAL_STATE.verifyAuth.errorMsg
        },
        setVerifyAuthErrorMsg: (state, { payload }) => {
            state.verifyAuth.errorMsg = payload
            state.verifyAuth.verifyAuth = AUTH_INITIAL_STATE.verifyAuth.verifyAuth
        },
        setClearVerifyAuth: (state) => {
            state.verifyAuth = AUTH_INITIAL_STATE.verifyAuth
        },

        // reducers for verify user
        setVerifyUserLoading: (state, { payload }) => {
            state.verifyUser.isLoading = payload
        },
        setVerifyUser: (state, { payload }) => {
            state.verifyUser.verifyUser = payload
            state.verifyUser.errorMsg = AUTH_INITIAL_STATE.verifyUser.errorMsg
        },
        setVerifyUserErrorMsg: (state, { payload }) => {
            state.verifyUser.errorMsg = payload
            state.verifyUser.verifyUser = AUTH_INITIAL_STATE.verifyUser.verifyUser
        },
        setClearVerifyUser: (state) => {
            state.verifyUser = AUTH_INITIAL_STATE.verifyUser
        },

        // Password Reset
        setPasswordResetLoading: (state, { payload }) => {
            state.passwordReset.isLoading = payload
        },
        setPasswordResetData: (state, { payload }) => {
            state.passwordReset.data = payload
            state.passwordReset.message = AUTH_INITIAL_STATE.passwordReset.message
        },
        setPasswordResetMessage: (state, { payload }) => {
            state.passwordReset.message = payload
            state.passwordReset.data = AUTH_INITIAL_STATE.passwordReset.data
        },
        resetPasswordReset: (state,) => {
            state.passwordReset = AUTH_INITIAL_STATE.passwordReset
        },

        // Add Password Reset
        setAddPasswordResetLoading: (state, { payload }) => {
            state.addPasswordReset.isLoading = payload
            if (payload) {
                state.addPasswordReset.message = AUTH_INITIAL_STATE.addPasswordReset.message
                state.addPasswordReset.data = AUTH_INITIAL_STATE.addPasswordReset.data
            }
        },
        setAddPasswordResetData: (state, { payload }) => {
            state.addPasswordReset.data = payload
            state.addPasswordReset.message = AUTH_INITIAL_STATE.addPasswordReset.message
        },
        setAddPasswordResetMessage: (state, { payload }) => {
            state.addPasswordReset.message = payload
            state.addPasswordReset.data = AUTH_INITIAL_STATE.addPasswordReset.data
        },
        resetAddPasswordReset: (state) => {
            state.addPasswordReset = AUTH_INITIAL_STATE.addPasswordReset
        },

        // reset password link
        setForgetPasswordLinkLoading: (state, { payload }) => {
            state.forgetPasswordLink.isLoading = payload
        },
        setForgetPasswordLinkMessage: (state, { payload }) => {
            state.forgetPasswordLink.message = payload
        },

        // logout current user and switch to admin
        setLogoutCurrentUserAndSwitchToAdmin: (state, { payload }) => {
            state.logoutCurrentUserAndSwitchToAdmin.isLoading = payload
        }

    }
})

export const {

    setAccessToken,
    setSessionLoading,
    setSessionLoggedIn,
    setSessionMessage,
    resetSession,

    setLogInLoading,
    setLogInData,
    setLogInMessage,
    resetLogIn,
    setLogInPayload,
    setClearLogInPayload,
    
    setSignUpLoading,
    setSignUpData,
    setSignUpMessage,
    resetSignUp,
    setSignUpPayload,
    setClearSignUpPayload,
    
    setSignupWithTokenLoading,
    setSignupWithTokenPayload,
    setSignupWithTokenData,
    setSignupWithTokenMessage,
    resetSignUpWithToken,
    
    setVerifySignupLoading,
    setVerifySignupPayload,
    setVerifySignupData,
    setVerifySignupStatus,
    setVerifySignupMessage,
    resetVerifySignup,
    
    setSignupS2Loading,
    setSignupS2Payload,
    setSignupS2Data,
    setSignupS2Message,
    setSignupS2Status,
    resetSignupS2,

    setVerifyReferralLoading,
    setVerifyReferral,
    setVerifyReferralErrorMsg,
    setClearVerifyReferral,

    setLogoutLoading,
    setLogout,
    setLogoutErrorMsg,
    setClearLogout,

    setAddResetPasswordLoading,
    setAddResetPassword,
    setAddResetPasswordErrorMsg,
    setClearAddResetPassword,

    setAddResetPasswordPayload,
    setClearAddResetPasswordPayload,

    setAddUserAuthLoading,
    setAddUserAuth,
    setAddUserAuthErrorMsg,
    setClearAddUserAuth,

    setAddUserAuthPayload,
    setClearAddUserAuthPayload,

    setAddUserPasswordLoading,
    setAddUserPassword,
    setAddUserPasswordErrorMsg,
    setClearAddUserPassword,

    setVerifyAuthLoading,
    setVerifyAuth,
    setVerifyAuthErrorMsg,
    setClearVerifyAuth,

    setVerifyUserLoading,
    setVerifyUser,
    setVerifyUserErrorMsg,
    setClearVerifyUser,

    setPasswordResetLoading,
    setPasswordResetData,
    setPasswordResetMessage,
    resetPasswordReset,

    setAddPasswordResetLoading,
    setAddPasswordResetData,
    setAddPasswordResetMessage,
    resetAddPasswordReset,

    setForgetPasswordLinkLoading,
    setForgetPasswordLinkMessage,

    setLogoutCurrentUserAndSwitchToAdmin
} = auth.actions

export default auth.reducer