import { useState, useCallback, Fragment, useEffect, useMemo } from 'react';

import { cn } from "utils/cn.utils";

import { Listbox, Transition } from "@headlessui/react";

import { AiOutlineFolderOpen } from 'react-icons/ai';
import { FaCheck } from 'react-icons/fa';
import { RiArrowDropDownLine } from "react-icons/ri";

import { getLmsResourceList } from 'redux/edulyteLms/lmsResource/lmsResource.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setFilterProps } from 'redux/edulyteLms/lmsResource/lmsResource.slice';
import { lmsResourceTypeEnum, resourceFileTypeEnum } from 'redux/edulyteLms/lmsResource/lmsResource.const';

const FileTypeFilters = ({ showCheckMark = false }) => {
    const { filterProps } = useAppState((state) => state.lms.lmsResource)

    const dispatcher = useAppDispatcher()

    const [selectedOption, setSelectedOption] = useState({
        label: "File Type",
        value: "file_type"
    })

    useEffect(() => {
        if ([lmsResourceTypeEnum.FILE.value]?.includes(filterProps?.resourceType)) {
            const selectedFilterType = Object.values(resourceFileTypeEnum)?.find((selected) => (selected?.value === filterProps?.resourceFileType))
            setSelectedOption(selectedFilterType)
        }
    }, [filterProps])

    const isFiltersDisabled = useMemo(() => ((filterProps?.disabledFilters?.length > 0) && (filterProps?.disabledFilters?.includes(lmsResourceTypeEnum.YOUTUBE.value) || filterProps?.disabledFilters?.includes(lmsResourceTypeEnum.LINK.value))), filterProps)

    const onHandleSelectOption = useCallback((selectedType) => {
        if (isFiltersDisabled) return;

        const selectedFilterType = Object.values(resourceFileTypeEnum)?.find((selected) => (selected?.value === selectedType))
        setSelectedOption(selectedFilterType)
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, resourceType: lmsResourceTypeEnum.FILE.value, resourceFileType: selectedType }
        }))
        dispatcher(getLmsResourceList({ page: 1, records: 5, file_type: selectedType }))
    }, [selectedOption, isFiltersDisabled])

    return (
        <div className="w-full">
            <Listbox
                value={selectedOption?.value}
                onChange={(value) => onHandleSelectOption(value)}
            >
                {isFiltersDisabled &&
                    <Listbox.Button className={"w-full flex items-center justify-center gap-2 rounded-lg cursor-pointer focus:outline-none"}>
                        <AiOutlineFolderOpen className={"text-base"} />
                        <span className="font-bodyPri font-normal text-sm tracking-wide">
                            {selectedOption?.label}
                        </span>
                    </Listbox.Button>
                }
                {!isFiltersDisabled &&
                    <div className={"relative w-full"}>
                        <Listbox.Button className={"w-full flex items-center justify-center gap-2 rounded-lg cursor-pointer focus:outline-none"}>
                            <AiOutlineFolderOpen className={"text-base"} />
                            <span className="font-bodyPri font-normal text-sm tracking-wide">
                                {selectedOption?.label}
                            </span>
                            <RiArrowDropDownLine className={"text-base"} />
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className={cn(
                                "absolute z-20 w-full py-1 mt-1 overflow-auto min-w-[8rem] max-h-60 rounded-md",
                                "text-base bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                                "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                            )}>
                                {Object.values(resourceFileTypeEnum).map((option, optionIdx) => (
                                    <Listbox.Option
                                        key={optionIdx}
                                        className={({ active, selected }) =>
                                            cn(
                                                `cursor-pointer select-none relative py-2 pr-4 ${showCheckMark ? "pl-10" : "pl-5"
                                                }`,
                                                selected && "text-white bg-blue-500",
                                                active && !selected && "text-blue-500 "
                                            )
                                        }
                                        value={option?.value}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={`font-bodyPri ${selected ? "font-medium text-white" : "font-normal text-text-700"
                                                        } block truncate`}
                                                >
                                                    {option?.label}
                                                </span>
                                                {(selected && showCheckMark) ? (
                                                    <span
                                                        className={`text-white absolute inset-y-0 left-0 flex items-center pl-3`}
                                                    >
                                                        <FaCheck size={10} aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                }
            </Listbox>
        </div>
    )
}
export default FileTypeFilters;