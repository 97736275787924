import { useCallback, useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";

import { IoMdClose } from "react-icons/io";
import { GoPlus } from "react-icons/go";
import { FaCheck, FaSpinner, FaTimes } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

import ToolTipView from "components/tooltipView";
import FloatingLabelSelect from "components/floating/floatingLabelSelect";

import { crmDetailBtnConst } from "components/modals/crmModals/data";

import {
    createContactLabelDetail,
    createCrmContactLabelDetail,
    deleteContactLabelDetail,
    getCrmContactLabelList,
    updateCrmContactLabelDetail
} from "redux/crm/crm.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import {
    resetAddContactLabelDetail,
    resetAddCrmContactLabelDetail,
    resetCrmContactLabelList,
    resetModifyCrmContactLabelDetail,
    setCrmContactLabelListData,
    setModifyCrmContactDetailPayload
} from "redux/crm/crm.slice";

import { cn } from "utils/cn.utils";

const CreateLabel = ({
    editBtnConst = null,
    setEditBtnConst = () => { },
    customLabelDropdownWrapper = ""
}) => {
    const { user } = useAppState((state) => state.user)
    const {
        crmContactLabelList,
        modifyCrmContactDetail,
        addCrmContactLabelDetail,
        modifyCrmContactLabelDetail,
        addContactLabelDetail,
        destroyContactLabelDetail
    } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()

    const [labelPayload, setLabelPayload] = useState({
        isShowLabelList: false,
        isShowInput: false,
        labelId: "",
        labelText: "",
        labelKey: "",
        labelColor: "#000"
    });

    const filteredLabelList = useMemo(() => {
        if (crmContactLabelList?.data?.length) {
            let newContactLabelList = crmContactLabelList.data;
            if (!!modifyCrmContactDetail?.payload?.labels?.length) {
                newContactLabelList = newContactLabelList.filter((item) =>
                    !modifyCrmContactDetail?.payload?.labels.some((labelItem) => labelItem.value === item.key)
                );
            }
            return newContactLabelList;
        }
        return [];
    }, [crmContactLabelList?.data, modifyCrmContactDetail?.payload?.labels]);

    useEffect(() => {
        return () => {
            dispatcher(resetCrmContactLabelList())
        }
    }, [])

    useEffect(() => {
        if (user?.user?.userId) {
            dispatcher(getCrmContactLabelList({ owner_user_id: user?.user?.userId }))
        }
    }, [user?.user?.userId])

    useEffect(() => {
        if (addContactLabelDetail?.data) {
            dispatcher(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                labels: !!modifyCrmContactDetail?.payload?.labels?.length
                    ? [...modifyCrmContactDetail?.payload?.labels, { id: addContactLabelDetail?.data?.id, label: addContactLabelDetail?.data?.label?.name, value: addContactLabelDetail?.data?.label?.key, color: addContactLabelDetail?.data?.label?.color }]
                    : [{ id: addContactLabelDetail?.data?.id, label: addContactLabelDetail?.data?.label?.name, value: addContactLabelDetail?.data?.label?.key, color: addContactLabelDetail?.data?.label?.color }],
            }))
            dispatcher(resetAddContactLabelDetail())
        }
    }, [addContactLabelDetail?.data])

    useEffect(() => {
        if (addCrmContactLabelDetail?.data) {
            dispatcher(setCrmContactLabelListData([addCrmContactLabelDetail?.data, ...crmContactLabelList?.data]))
            setLabelPayload({
                isShowLabelList: true,
                isShowInput: false,
                labelText: "",
                labelKey: "",
                labelColor: "#000"
            });
            setEditBtnConst(null)
            dispatcher(resetAddCrmContactLabelDetail())
        }
    }, [addCrmContactLabelDetail?.data])

    useEffect(() => {
        if (modifyCrmContactLabelDetail?.data) {
            const newLabelList = crmContactLabelList?.data?.map((contactLabel) => (
                (contactLabel?.id === modifyCrmContactLabelDetail?.data?.id)
                    ? modifyCrmContactLabelDetail?.data
                    : contactLabel
            ))
            dispatcher(setCrmContactLabelListData(newLabelList))
            dispatcher(resetModifyCrmContactLabelDetail())
            setLabelPayload({
                isShowLabelList: true,
                isShowInput: false,
                labelText: "",
                labelKey: "",
                labelColor: "#000"
            });
        }
    }, [modifyCrmContactLabelDetail?.data])

    const onHandleSelectOption = useCallback((selectedOption) => {
        if (!!modifyCrmContactDetail?.payload?.labels?.length) {
            const isLabelAlreadyPresent = modifyCrmContactDetail?.payload?.labels?.filter((labelItem) => labelItem?.value === selectedOption?.value)

            if (!!isLabelAlreadyPresent?.length) {
                toast.warn("Label already added!")
                return;
            }
        }

        dispatcher(createContactLabelDetail({
            contact_id: modifyCrmContactDetail?.payload?.contact_id,
            label: selectedOption?.value
        }))
    }, [modifyCrmContactDetail?.payload]);

    const onHandleDeleteLabelDetail = useCallback((labelId) => {
        if (destroyContactLabelDetail?.isLoading) return;

        if (!window.confirm("Are you sure?. You want to delete label.")) return;

        dispatcher(deleteContactLabelDetail(labelId))
    }, [modifyCrmContactDetail?.payload, destroyContactLabelDetail?.isLoading])

    const onHandleUpdateLabelDetail = (event, selectedLabel) => {
        event.stopPropagation()
        setLabelPayload({
            ...labelPayload,
            isShowLabelList: false,
            isShowInput: true,
            labelId: selectedLabel?.id,
            labelText: selectedLabel?.label,
            labelKey: selectedLabel?.value,
            labelColor: selectedLabel?.color
        })
        setEditBtnConst({
            ...editBtnConst,
            active: crmDetailBtnConst.labels.key
        })
    }

    const onHandleChangeLabel = useCallback((event) => {
        let labelKey = labelPayload?.labelKey
        if (event.target.name == "labelText") {
            labelKey = event.target.value.toLowerCase()?.replaceAll(" ", "-")
        }
        setLabelPayload({
            ...labelPayload,
            [event.target.name]: event.target.value,
            labelKey: labelKey
        })
    }, [labelPayload])

    const onHandleKeyDown = useCallback((event) => {
        if (event.keyCode === 13) {
            onHandleAddNewLabel()
        }
    }, [labelPayload])

    const onHandleAddNewLabel = () => {
        if (addCrmContactLabelDetail?.isLoading || modifyCrmContactLabelDetail?.isLoading || addContactLabelDetail?.isLoading || (editBtnConst && (editBtnConst?.active !== crmDetailBtnConst.labels.key))) return;

        if (labelPayload?.labelText?.trim() === "") {
            toast.warn("Please enter a label.");
            return;
        }
        if (!!modifyCrmContactDetail?.payload?.labels?.length) {
            const isLabelAlreadyPresent = modifyCrmContactDetail?.payload?.labels?.filter((labelItem) => labelItem?.value === labelPayload?.labelKey)

            if (!!isLabelAlreadyPresent?.length) {
                toast.warn("Label already added!")
                return;
            }
        }

        let requestDataPayload = {
            name: labelPayload?.labelText?.trim(),
            color: labelPayload?.labelColor || "#000"
        }
        if (labelPayload?.labelId) {
            dispatcher(updateCrmContactLabelDetail(labelPayload?.labelId, requestDataPayload))
        } else {
            requestDataPayload["owner_user_id"] = user?.user?.userId
            dispatcher(createCrmContactLabelDetail(requestDataPayload))
        }

    }

    const onHandleReset = () => {
        if (addCrmContactLabelDetail?.isLoading || addContactLabelDetail?.isLoading) return;

        setLabelPayload({
            isShowLabelList: false,
            isShowInput: false,
            labelText: "",
            labelKey: "",
            labelColor: "#000",
        })
        setEditBtnConst(null)
    }

    const onHandleCreateNewLabel = useCallback(() => {
        setLabelPayload({
            isShowLabelList: false,
            isShowInput: true,
            labelText: "",
            labelKey: "",
            labelColor: "#000",
        })
        setEditBtnConst({
            ...editBtnConst,
            active: crmDetailBtnConst.labels.key
        })
    }, [editBtnConst, labelPayload])

    return (
        <div className={"w-full inline-flex flex-wrap items-start gap-3"}>
            {!!modifyCrmContactDetail?.payload?.labels?.length &&
                <>
                    {modifyCrmContactDetail?.payload?.labels?.map((labelItem, index) => (
                        <div
                            key={index}
                            className={`w-fit px-2 h-9 flex items-center justify-start rounded !${labelItem?.color}`}
                            style={{ backgroundColor: labelItem?.color }}
                        >
                            <span className={`font-bodyPri font-normal text-text-50 text-sm capitalize`}>
                                {labelItem?.label}
                            </span>
                            <button
                                className={cn(
                                    "pl-3 cursor-pointer",
                                    editBtnConst && "invisible"
                                )}
                                onClick={() => onHandleDeleteLabelDetail(labelItem?.id)}
                            >
                                <FaTimes className={"font-light text-text-50"} />
                            </button>
                        </div>
                    ))}
                </>
            }
            <div className={cn("min-w-[12rem]", customLabelDropdownWrapper)}>
                <FloatingLabelSelect
                    labelItem={"label_label_select_label"}
                    isLoading={crmContactLabelList?.isLoading || addCrmContactLabelDetail?.isLoading || addContactLabelDetail?.isLoading}
                    searchable={!!filteredLabelList?.length && true}
                    options={filteredLabelList?.map((label) => ({
                        id: label?.id,
                        label: label?.name,
                        value: label?.key,
                        color: label?.color
                    })) || []}
                    isOpenDropdown={labelPayload?.isShowLabelList}
                    onHandleSelect={onHandleSelectOption}
                    value={"Select label"}
                    label={"Label"}
                    isShowEmptyContent={!modifyCrmContactDetail?.payload?.labels?.length || !filteredLabelList?.length}
                    EmptyContentContainer={() => {
                        return (
                            <button
                                onClick={onHandleCreateNewLabel}
                                className={"w-full flex items-center justify-center gap-1 py-1.5"}
                            >
                                <GoPlus />
                                {"Create label"}
                            </button>
                        )
                    }}
                    OptionChild={({ option }) => (
                        <div className={"bg-white hover:bg-gray-100 px-4 py-2 flex justify-between gap-3"}>
                            <div
                                className={"flex items-center gap-1"}
                                style={{ color: option?.color }}
                                title={option?.label}
                            >
                                <span className={"line-clamp-1"}>
                                    {option?.label}
                                    <span className={"pl-1.5 text-sm"}>{`(${option?.value})`}</span>
                                </span>
                            </div>
                            <button className={"p-1 rounded-full hover:bg-divider-darkLight group"} onClick={(event) => onHandleUpdateLabelDetail(event, option)}>
                                <MdEdit className={"text-xl text-text-700 group-hover:text-primary-dark cursor-pointer"} />
                            </button>
                        </div>
                    )}
                    isDisabled={editBtnConst}
                    customBtnStyle={editBtnConst ? "bg-text-200 hover:!border-divider-lightDark focus-within:!ring-1 focus-within:!ring-divider-lightDark focus-within:!border-divider-lightDark !cursor-not-allowed" : "!h-9"}
                    dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                />
            </div>
            {labelPayload?.isShowInput ? (
                <div className={"flex flex-col gap-1 overflow-hidden"}>
                    <div className={"flex items-center justify-center gap-1.5 px-3 h-9 border rounded border-[#adadad88] focus-within:hover:border-primary-main"}>
                        <input
                            type={"text"}
                            value={labelPayload?.labelText}
                            name={"labelText"}
                            onChange={onHandleChangeLabel}
                            placeholder={"Enter new label"}
                            className={"flex-1 h-full outline-none text-black disabled:bg-text-200"}
                            onKeyDown={onHandleKeyDown}
                            autoFocus={true}
                            disabled={editBtnConst && (editBtnConst?.active !== crmDetailBtnConst.labels.key)}
                        />
                        <ToolTipView content={"Choose label color"}>
                            <input
                                type={"color"}
                                name={"labelColor"}
                                className={"w-[2rem] h-full rounded-full cursor-pointer"}
                                value={labelPayload?.labelColor}
                                onChange={onHandleChangeLabel}
                                disabled={editBtnConst && (editBtnConst?.active !== crmDetailBtnConst.labels.key)}
                            />
                        </ToolTipView>
                        <div className={"flex items-center gap-2 pl-1"}>
                            <ToolTipView content={"Save label"}>
                                <button
                                    className="cursor-pointer text-lg"
                                    onClick={onHandleAddNewLabel}
                                    disabled={editBtnConst && (editBtnConst?.active !== crmDetailBtnConst.labels.key)}
                                >
                                    {(addCrmContactLabelDetail?.isLoading || modifyCrmContactLabelDetail?.isLoading || addContactLabelDetail?.isLoading)
                                        && <FaSpinner className={"animate-spin text-primary-dark"} />
                                    }
                                    {(!addCrmContactLabelDetail?.isLoading && !modifyCrmContactLabelDetail?.isLoading && !addContactLabelDetail?.isLoading)
                                        && <FaCheck className={"text-green-500"} />
                                    }
                                </button>
                            </ToolTipView>
                            <ToolTipView content={"Reset"}>
                                <button
                                    className="cursor-pointer text-lg"
                                    onClick={onHandleReset}
                                    disabled={editBtnConst && (editBtnConst?.active !== crmDetailBtnConst.labels.key)}
                                >
                                    <IoMdClose />
                                </button>
                            </ToolTipView>
                        </div>
                    </div>
                    {!!labelPayload?.labelKey?.length &&
                        <span className={"text-sm line-clamp-1"}>
                            {`key: `}
                            <span className={"font-medium"}>
                                {labelPayload?.labelKey}
                            </span>
                        </span>
                    }
                </div>
            ) : (
                <button
                    onClick={onHandleCreateNewLabel}
                    className={cn(
                        "flex items-center justify-center gap-1 px-3 md:px-5 h-9 border border-[#adadad88] rounded",
                        !editBtnConst && "hover:text-primary-main hover:border-primary-main",
                        editBtnConst && "bg-text-200 text-text-400 cursor-not-allowed"
                    )}
                    disabled={editBtnConst}
                >
                    <GoPlus />
                    {"Create label"}
                </button>
            )}
        </div>
    );
};

export default CreateLabel;