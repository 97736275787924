import { DEFAULT_EVENTLY_PAGE, DEFAULT_EVENTLY_RECORDS } from "redux/evently/evently.const";
import { dayjs, timeZone } from "utils/dateTime.utils";

export const pageHeading = {
    heading: "My Events",
    subHeading: "Find the list of your events!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/offerings.svg"
}

export const menuItemsConst = {
    SHARE: {
        label: "Share",
        value: "share"
    },
    COPY: {
        label: "Copy",
        value: "copy"
    },
    VIEW: {
        label: "View",
        value: "view"
    },
    EDIT: {
        label: "Edit",
        value: "edit"
    },
    DELETE: {
        label: "Delete",
        value: "delete"
    },
}

export const eventlyTableHeaderConst = [
    "ID",
    "Title",
    "location",
    "Upcoming Event",
    "Created Date",
    "Actions",
]

export const searchParamsInfo = {
    page: {
        key: "page"
    },
    records: {
        key: "records"
    },
    id: {
        key: "id"
    },
    title: {
        key: "title"
    },
    date: {
        key: "date"
    },
    minCreatedAt: {
        key: "minCreatedAt"
    },
    maxCreatedAt: {
        key: "maxCreatedAt"
    },
    eventlyId: {
        key: "eventlyId"
    }
}

export const eventlyFilters = {
    [searchParamsInfo.id.key]: {
        key: "id",
        label: "ID"
    },
    [searchParamsInfo.title.key]: {
        key: "title",
        label: "Title"
    },
    [searchParamsInfo.date.key]: {
        key: "date",
        label: "Date"
    }
}

export const getEventlyListPayload = async (searchQueryParams) => {

    let requestDataPayload = {
        page: searchQueryParams.get(searchParamsInfo.page.key) || DEFAULT_EVENTLY_PAGE,
        records: searchQueryParams.get(searchParamsInfo.records.key) || DEFAULT_EVENTLY_RECORDS,
        timeZone: timeZone
    }

    if (!!searchQueryParams.get(searchParamsInfo.id.key)) {
        requestDataPayload[searchParamsInfo.id.key] = Number(searchQueryParams.get(searchParamsInfo.id.key))
    }
    if (!!searchQueryParams.get(searchParamsInfo.title.key)) {
        requestDataPayload[searchParamsInfo.title.key] = searchQueryParams.get(searchParamsInfo.title.key).replaceAll("-", " ")
    }
    if (!!searchQueryParams.get(searchParamsInfo.minCreatedAt.key)) {
        requestDataPayload[searchParamsInfo.minCreatedAt.key] = dayjs(searchQueryParams.get(searchParamsInfo.minCreatedAt.key)).tz(timeZone).format("YYYY-MM-DD")
    }
    if (!!searchQueryParams.get(searchParamsInfo.maxCreatedAt.key)) {
        requestDataPayload[searchParamsInfo.maxCreatedAt.key] = dayjs(searchQueryParams.get(searchParamsInfo.maxCreatedAt.key)).tz(timeZone).format("YYYY-MM-DD")
    }

    return requestDataPayload;
}