import { useEffect } from 'react';
import { cn } from "utils/cn.utils";

import { BiTimeFive } from 'react-icons/bi';
import { MdAccessTimeFilled } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';
import { durations, sessionCount } from 'components/modals/noOfSessionModal/noOfSessionModal.data';
import SessionCount from 'components/modals/noOfSessionModal/SessionCount';

import { getSelectedPublicCourseDetail } from 'redux/course/course.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetSelectedPublicCourseDetail } from 'redux/course/course.slice';
import { setAddBookingDetailPayload } from 'redux/booking/booking.slice';
import { modalConst } from 'redux/local/local.const';
import { courseType } from 'redux/course/course.const';

const NoOfSessionModal = () => {
    const { selectedPublicCourseDetail } = useAppState((state) => state.course)
    const { addBookingDetail } = useAppState((s) => s.booking)
    const { modal } = useAppState((s) => s.local)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        return () => {
            dispatcher(resetSelectedPublicCourseDetail())
        }
    }, [])

    useEffect(() => {
        if (selectedPublicCourseDetail?.data) {
            dispatcher(setAddBookingDetailPayload({
                ...addBookingDetail?.payload,
                offering: selectedPublicCourseDetail?.data
            }))
        } else {
            dispatcher(getSelectedPublicCourseDetail(addBookingDetail?.payload?.slug))
        }
    }, [selectedPublicCourseDetail?.data])

    const handleNext = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.NO_SESSION_MODAL.stateKey]: false,
            [modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey]: true
        }))
    }

    if (selectedPublicCourseDetail?.isLoading) {
        return (
            <ComponentLoader
                isLoading={selectedPublicCourseDetail?.isLoading}
                className={"min-h-[24rem] flex justify-center items-center"}
            />
        )
    }

    if (selectedPublicCourseDetail?.message) {
        return (
            <div className={"min-h-[24rem] flex justify-center items-center"}>
                <span className={"font-bodyPri font-medium text-md text-text-700"}>
                    {selectedPublicCourseDetail?.message}
                </span>
            </div>
        )
    }

    if (selectedPublicCourseDetail?.data && selectedPublicCourseDetail?.data?.type !== courseType.ONE_ON_ONE.value) {
        return (
            <div className={"min-h-[24rem] flex justify-center items-center"}>
                <span className={"font-bodyPri font-medium text-md text-text-700"}>
                    {"Required One on One course"}
                </span>
            </div>
        )
    }

    return (
        <div className={"space-y-5"}>
            <div className={'min-h-[24rem] block space-y-5 pt-5'}>

                <div className={"flex flex-col items-center justify-start gap-3"}>
                    <span className={"selft-center font-bodyPri font-normal text-base text-text-700 text-center"}>
                        {"How long? choose one"}
                    </span>
                    <div className={"flex flex-wrap items-center justify-evenly gap-3"}>
                        {durations.map((duration, index) => (
                            <span key={index} onClick={() => dispatcher(setAddBookingDetailPayload({ ...addBookingDetail?.payload, duration: duration }))}
                                className={cn(
                                    "flex items-center justify-center gap-1 px-2 py-0.5 rounded-lg cursor-pointer",
                                    "font-bodyPri font-normal text-sm whitespace-nowrap",
                                    addBookingDetail?.payload?.duration === duration
                                        ? "bg-primary-dark text-text-50"
                                        : "bg-primary-light text-primary-dark"
                                )}
                            >
                                {addBookingDetail?.payload?.duration === duration
                                    ? <MdAccessTimeFilled className={"text-sm"} />
                                    : <BiTimeFive className={"text-sm"} />
                                }
                                {duration + " min"}
                            </span>
                        ))}
                    </div>
                </div>

                <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>

                <div className={"flex flex-col items-center justify-start gap-3"}>
                    <span className={"font-bodyPri font-normal text-base text-text-700 text-center"}>
                        {"How many sessions?"}
                    </span>
                    <div className={"flex flex-row items-start justify-evenly gap-8"}>
                        {sessionCount.map((count, index) => (
                            <SessionCount key={index} count={count} />
                        ))}
                    </div>
                </div>

                <div className={"w-full flex items-end justify-center"}>
                    <ul className={"px-5 py-2 rounded-lg font-bodyPri font-normal text-sm text-text-800 list-disc list-outside space-y-2"}>
                        <li className={""}>
                            {"Select a single session or save money with a multisession pack!"}
                        </li>
                        <li className={""}>
                            {"See schedule / availability next"}
                        </li>
                    </ul>
                </div>

            </div>
            <div className={"flex justify-end"}>
                <span onClick={handleNext} className={cn(
                    "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-normal text-base text-secondary-main",
                    "border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                )}>
                    {"Next"}
                </span>
            </div>
        </div>
    )
}

export default NoOfSessionModal