import { userRoles } from 'redux/auth/auth.const';


export const pageHeading = {
    heading: "App Settings",
    subHeading: "Choose or update all the settings of your apps on mobile and web!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/settings.svg"
}

export const navigationOptions = [
    { label: "General", to: "/general", roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },
    { label: "Billing Info", to: "/billing", roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },
    { label: "Payment Methods", to: "/payment", roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },
    { label: "Payout Methods", to: "/payout", roles: [userRoles.TUTOR.value] },
    { label: "Calendar", to: "/calendar", roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },
    { label: "Notifications", to: "/notifications", roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },
];

export const languagesList = {
    ENGLISH: {
        label: "English",
        value: "english"
    },
    HINDI: {
        label: "Hindi",
        value: "hindi"
    },
    NEDERLANDS: {
        label: "Nederlands",
        value: "nederlands"
    },
    ITALIANO: {
        label: "Italiano",
        value: "italiano"
    },
    POLSKI: {
        label: "Polski",
        value: "polski"
    }
}

export const timezoneList = {

}

export const userSocialMediaLinksConst = {
    FACEBOOK: {
        label: "Facebook",
        value: "facebook",
        placeholder: "https://www.facebook.com/your_facebook_name",
        demoKey1: "https://www.facebook.com/your_facebook_name",
        demoKey2: "",
        socialLinkUrl: "facebook.com/"
    },
    INSTAGRAM: {
        label: "Instagram",
        value: "instagram",
        placeholder: "https://www.instagram.com/your_instagram_name",
        demoKey1: "https://www.instagram.com/your_instagram_name",
        demoKey2: "or @your_instagram_name",
        socialLinkUrl: "instagram.com/"
    },
    TWITTER: {
        label: "Twitter",
        value: "twitter",
        placeholder: "https://www.twitter.com/your_twitter_name",
        demoKey1: "https://www.twitter.com/your_twitter_name",
        demoKey2: "@Your_Twitter_Name",
        socialLinkUrl: "twitter.com/"
    },
    LINKEDIN: {
        label: "Linkedin",
        value: "linkedin",
        placeholder: "https://www.linkedin.com/yourlinkedinname",
        demoKey1: "https://www.linkedin.com/yourlinkedinname",
        demoKey2: "",
        socialLinkUrl: "linkedin.com/"
    }
}