import { useEffect, useReducer, useState } from 'react';
import { toast } from 'react-toastify';

import { BsCheckAll } from "react-icons/bs";
import { MdCancel } from "react-icons/md";

import ButtonLoader from 'components/loader/ButtonLoader';

import {
    FormContainer,
    BoxSection,
    NameSection,
    InputBox,
    SignUpButton,
} from 'components/modals/authorizationModal/Authorization.style';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setSignUpPayload } from "redux/auth/auth.slice";
import { setClearSendOtp, setClearVerifyOtp } from 'redux/notification/notification.slice';

import { validateEmail } from 'utils/validation-functions';

const formReducer = (state, event) => {
    return {
        ...state,
        [event.name]: event.value,
    }
}

const ModalSignUpForm = ({ setIsFormSubmitted }) => {
    const { modal } = useAppState(s => s.local)
    const { signUp } = useAppState((s) => s.auth)

    const dispatcher = useAppDispatcher()

    const [formData, setFromData] = useReducer(formReducer, {});
    const [emailErrorText, setEmailErrorText] = useState('')
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

    useEffect(() => {
        const { email } = formData;

        if (email && !validateEmail(email)) setEmailErrorText('Enter valid Email!');
        else setEmailErrorText('');

        const isDisabled = (emailErrorText.length === 0) && !(formData.firstName && formData.lastName && (formData.email || formData.mobileNo))
        setIsSubmitDisabled(isDisabled)

    }, [formData])


    const submitForm = (event) => {
        event.preventDefault();

        if (emailErrorText) {
            toast.warn("Invalid Email!")
            return;
        }

        dispatcher(setClearSendOtp())
        dispatcher(setClearVerifyOtp())
        setIsFormSubmitted(true)
        // dispatcher(setModal({
        //     ...modal,
        //     [modalConst.AUTHORIZATION_MODAL.stateKey]: false,
        //     [modalConst.CODE_VERIFICATION_MODAL.stateKey]: true
        // }))
    }

    const handleOnChange = (event) => {
        setFromData({
            name: event.target.name,
            value: event.target.value,
        })
        dispatcher(setSignUpPayload({
            ...signUp?.payload,
            [event?.target.name]: event?.target?.value
        }))
    }

    return (
        <FormContainer onSubmit={submitForm}>
            <NameSection>
                <BoxSection>
                    <InputBox type='text' name='firstName' value={signUp?.payload?.firstName} placeholder='First Name *' onChange={handleOnChange} autoFocus={true} required />
                </BoxSection>
                <BoxSection>
                    <InputBox type='text' name='lastName' value={signUp?.payload?.lastName} placeholder='Last Name *' onChange={handleOnChange} required />
                </BoxSection>
            </NameSection>
            <BoxSection>
                <InputBox type='email' name='email' value={signUp?.payload?.email} placeholder='Enter your email *' onChange={handleOnChange} required />
                {formData.email && emailErrorText.length === 0 && <BsCheckAll className='text-green-500' />}
                {formData.email && emailErrorText.length > 0 && <MdCancel className=' text-base font-bodyPri text-red-800' />}
            </BoxSection>

            <SignUpButton isDisabled={isSubmitDisabled} type={"submit"} aria-disabled="true">
                {signUp?.isLoading &&
                    <ButtonLoader isLoading={signUp?.isLoading} />
                }
                {!signUp?.isLoading && 'Sign up'}
            </SignUpButton>
        </FormContainer>
    )
};

export default ModalSignUpForm;