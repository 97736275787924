import React, { useState, useEffect } from "react";
import { cn } from "utils/cn.utils";

import OTPInput, { ResendOTP } from "otp-input-react";

import { MdCancel } from "react-icons/md";

import { createSendOtp } from "redux/notification/notification.request";

import { useAppDispatcher } from "hooks/useStore";
import { setClearVerifyOtp } from "redux/notification/notification.slice";
import { otpDeliveryMethod } from "redux/notification/notification.const";

const InputOtp = ({
    email = null,
    mobileNo = null,
    otpLength = 6,
    setMyOtp,
    isOtpError = null,
    setIsOtpError
}) => {

    const dispatcher = useAppDispatcher()

    const [OTP, setOTP] = useState("")

    useEffect(() => {
        if (OTP) {
            setMyOtp(OTP)
        }
    }, [OTP])

    const renderButton = (buttonProps) => {

        return (
            <button {...buttonProps}
                className={cn(
                    buttonProps.remainingTime !== 0
                        ? "font-bodyPri font-medium text-text-600 text-sm"
                        : "font-bodyPri font-medium text-primary-main text-sm cursor-pointer hover:underline hover:text-primary-dark"
                )}
            >
                {buttonProps.remainingTime !== 0
                    ? `Resend Code in 
                ${Math.floor((buttonProps.remainingTime % 3600) / 60) < 10
                        ? '0' + Math.floor((buttonProps.remainingTime % 3600) / 60)
                        : Math.floor((buttonProps.remainingTime % 3600) / 60)} : ${Math.floor(buttonProps.remainingTime % 60) < 10
                            ? '0' + Math.floor(buttonProps.remainingTime % 60)
                            : Math.floor(buttonProps.remainingTime % 60)
                    }` : "Resend Code"
                }
            </button>
        )
    }

    const renderTime = () => React.Fragment

    const onHandleCancel = () => {
        dispatcher(setClearVerifyOtp())
        setIsOtpError(false)
        setOTP("")
        setMyOtp("")
    }

    const resendOtp = () => {
        if (mobileNo) {
            dispatcher(createSendOtp({
                channel: otpDeliveryMethod.SMS.value,
                mobileNo: mobileNo
            }))
            return;
        }
        if (email) {
            dispatcher(createSendOtp({
                channel: otpDeliveryMethod.EMAIL.value,
                email: email
            }))
        }
    }

    return (
        <div className={"flex flex-col items-center justify-center gap-3"}>
            <div className={"w-fit flex justify-start items-center"}>
                <OTPInput
                    otpType={"number"}
                    inputClassName={cn(
                        "input-number-spin-none rounded-sm focus:outline-none",
                        "font-bodyPri font-normal text-text-900 text-base bg-white",
                        isOtpError && "border border-red-500",
                        !isOtpError && "border border-divider-dark"
                    )}
                    className={"outline-none"}
                    OTPLength={otpLength}
                    value={OTP}
                    onChange={setOTP}
                    disabled={false}
                    autoFocus={true}
                />
                {isOtpError &&
                    <MdCancel
                        className={'text-base font-bodyPri text-red-800 cursor-pointer'}
                        onClick={onHandleCancel}
                    />
                }
            </div>
            <ResendOTP
                maxTime={119}
                renderButton={renderButton}
                renderTime={renderTime}
                onResendClick={resendOtp}
            />
        </div >
    )
}

export default InputOtp