import { IoSchoolOutline } from "react-icons/io";
import { MdOutlineSchool } from "react-icons/md";

import { useAppState } from "hooks/useStore";

const Qualifications = () => {
    const { tutorPublicProfile } = useAppState((s) => s.tutor)

    return (
        <div className="p-2.5 md:p-5 space-y-5" id={"QUALIFICATIONS"}>
            <span className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
                {"My Qualifications"}
            </span>
            <div className="flex flex-wrap items-center gap-2">
                {tutorPublicProfile?.tutorPublicProfile?.educations?.length > 0
                    && tutorPublicProfile?.tutorPublicProfile?.educations?.map((education, idx) => (
                        <div
                            key={idx}
                            className={'w-max px-3 py-1 border-2 border-text-300 flex items-center justify-start gap-1.5 rounded-xl'}
                        >
                            <MdOutlineSchool className={"text-xl text-text-600"} />
                            <span className={"font-bodyPri font-medium text-text-900 text-base"}>
                                {education?.education}
                            </span>
                        </div>
                    ))}
                {(!tutorPublicProfile?.tutorPublicProfile?.educations || tutorPublicProfile?.tutorPublicProfile?.educations?.length === 0)
                    && <span className={"font-bodyPri font-normal text-text-800 text-lg py-0.5 md:py-1 px-3"}>
                        {"No Qualification Added.."}
                    </span>
                }
            </div>
        </div>
    )
}

export default Qualifications