import { useRef, useState } from "react";

import { FaCaretDown, FaSpinner } from "react-icons/fa";

import { motion, AnimatePresence } from "framer-motion";

import { cn } from "utils/cn.utils";

const ViewContactDropdown = ({
    isLoading = false,
    options = [],
    value,
    OptionChild,
    onHandleSelect,
}) => {
    const [isFocused, setIsFocused] = useState(false);

    const containerRef = useRef(null);

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setIsFocused(false);
        }
    }

    const onHandleToggleDropdown = () => {
        setIsFocused((s) => !s)
        if (!isFocused) {
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }
    }

    const onHandleSelectOption = (option) => {
        setIsFocused(false)
        onHandleSelect(option)
    }

    return (
        <div
            ref={containerRef}
            className={"relative flex justify-center items-center font-bodyPri"}
        >
            <div
                className={"flex items-center justify-start gap-1 cursor-pointer"}
                onClick={onHandleToggleDropdown}
            >
                <span className={"text-base text-text-700 capitalize"}>
                    {value}
                </span>
                {isLoading &&
                    <FaSpinner className={"animate-spin text-primary-dark"} />
                }
                {!isLoading &&
                    <FaCaretDown
                        className={cn("text-text-700 ease-in-out duration-300", isFocused && "-rotate-180")}
                    />
                }
            </div>
            <AnimatePresence>
                {isFocused && (
                    <motion.ul
                        initial={{ opacity: 0, y: -10, height: 0 }}
                        animate={{ opacity: 1, y: 0, height: "auto" }}
                        exit={{ opacity: 0, y: -10, height: 0 }}
                        transition={{ type: "spring", duration: 0.5 }}
                        className={"absolute z-50 top-7 left-0 rounded-md overflow-hidden bg-white shadow-lg ring-1 ring-black/5 focus:outline-none w-full md:w-[12rem]"}
                    >
                        {options?.map((option, index) => (
                            <motion.li
                                key={index}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className={"cursor-pointer w-full"}
                                onClick={() => onHandleSelectOption(option)}
                            >
                                <OptionChild option={{ ...option, index: index }} />
                            </motion.li>
                        ))}
                    </motion.ul>
                )}
            </AnimatePresence>
        </div>
    )
}

export default ViewContactDropdown