import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { z } from "zod";
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod";

import { PageContentStyle } from './style';

import FullPageLoader from 'components/loader/FullPageLoader';

import AppointmentDetail from "./components/appointmentDetail"
import AppointmentCalendar from "./components/appointmentCalendar"
import AppointmentLocation from './components/appointmentLocation';

import { useTitle } from 'hooks/useTitle';
import { useAppDispatcher, useAppState } from "hooks/useStore";

import { setPageInfo } from "redux/pageInfo/pageInfo.request";
import { getMasterCountryList } from "redux/master/master.request";
import { getPublicAppointmentDetail } from "redux/appointment/appointment.request";

import { resetPublicAppointment } from "redux/appointment/appointment.slice";

import { bookingOfferingTypeEnum } from 'redux/booking/booking.const';
import { locationPhoneCallTypeEnum } from 'redux/appointment/appointment.const';

import { pagesInfo } from "utils/pagesInfo";
import { cn } from 'utils/cn.utils';
import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';

const timeZoneOffset = getTimeZoneOffset(timeZone)

const createBookingSchema = z.object({
    userId: z.number({ message: "invalid User" }),
    offeringType: z.literal(bookingOfferingTypeEnum.APPOINTMENT.value),
    appointment: z.object({
        appointmentId: z.number({ message: "invalid Appointment" }),
        timeZone: z.literal(timeZone),
        schedule: z.array(
            z.object({
                date: z.string().date(),
                timeSlots: z.array(
                    z.object({
                        startTime: z.string().time(),
                        endTime: z.string().time()
                    })
                ).min(1, { message: "Slots must contain at least one element" }).nonempty({ message: "Slots cannot be empty" })
            }).refine((value) => value.timeSlots.find(timeSlot => dayjs(value?.date + " " + timeSlot?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
                .isSameOrBefore(dayjs(value?.date + " " + timeSlot?.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone))), { message: "Start Date time must before End Date time" })
        ).min(1, { message: "Schedule must contain at least one element" }),
        appointment_location: z.object({
            appointmentLocationId: z.number({ message: "Location is Required" }),
            phone_call: z.object({
                location_phone_call_type: z.literal(locationPhoneCallTypeEnum.i_will_call.key),
                phone_number: z.string({ message: "Phone number is Required" }).regex(/^[0-9][0-9]{0,2}-[0-9]{6,15}[0-9]$/, { message: "Invalid Phone number" })
            }).optional(),
            invitee: z.object({
                custom_text: z.string().trim()
                    .min(10, { message: "Text length not be less then 10 character" })
                    .max(500, { message: "Text length mot be more then 500 character" })
            }).optional()
        }, { message: "Location is Required" })
    })
})

const Appointment = () => {
    const { currentPageInfo } = useAppState((s) => s.pageInfo)
    const { publicAppointment } = useAppState(s => s.appointment)

    const appointment = useMemo(() => publicAppointment?.data?.result, [publicAppointment?.data])

    const dispatcher = useAppDispatcher()
    const { slug } = useParams()
    const [title, setTitle] = useTitle()

    const [selectedDate, setSelectedDate] = useState(null);

    const form = useForm({
        mode: "onChange",
        resolver: zodResolver(createBookingSchema)
    })
    const values = form.watch({ control: form.control })

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.APPOINTMENT))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        dispatcher(getPublicAppointmentDetail(slug))
        dispatcher(getMasterCountryList())
        return () => {
            dispatcher(resetPublicAppointment())
        }
    }, [slug])

    useEffect(() => {
        if (appointment) {
            setTitle({
                ...title,
                title: `Appointment Booking | ${appointment?.title} | Edulyte`,
                description: appointment?.subTitle
            })
        }
    }, [appointment])

    const onHandleNext = (payload) => {
        form.reset(payload)
    }

    const onHandleBack = () => {
        form.reset({
            offeringType: bookingOfferingTypeEnum.APPOINTMENT.value
        })
    }

    if (publicAppointment?.isLoading) {
        return <FullPageLoader isLoading={publicAppointment?.isLoading} />
    }

    if (publicAppointment?.message) {
        return (
            <PageContentStyle>
                <div className={"flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                    {publicAppointment?.message ? "Sorry, not found" : ""}
                </div>
            </PageContentStyle>
        )
    }

    return (
        <PageContentStyle className="bg-white">
            <div className="w-full h-full lg:h-screen">
                <div className={cn(
                    "w-full sm:max-w-xl md:max-w-3xl mx-auto mt-0 sm:mt-10 bg-white rounded-none sm:rounded-lg shadow-none sm:shadow-[0_0_5px_1px_rgba(0,0,0,0.2)] h-full lg:h-[80vh] overflow-hidden",
                    "transition-all ease-in-out duration-300",
                    selectedDate ? "lg:max-w-5xl" : "lg:max-w-4xl"
                )}>
                    <div className={"w-full flex flex-col lg:flex-row divide-x h-full"}>
                        <div className={cn("p-8 overflow-y-auto scrollbar-thin w-full lg:w-1/2 transition-all ease-in-out duration-300", selectedDate && "lg:w-2/5")}>
                            <AppointmentDetail
                                appointment={appointment}
                                onHandleBack={onHandleBack}
                                values={values}
                            />
                        </div>
                        <div className={cn("p-8 pt-0 lg:pt-8 lg:pr-0 w-full lg:w-1/2 transition-all ease-in-out duration-300", selectedDate && "lg:w-3/5")}>
                            {values?.appointment
                                ? (
                                    <AppointmentLocation
                                        appointment={appointment}
                                        values={values}
                                        form={form}
                                    />
                                ) : (
                                    <AppointmentCalendar
                                        appointment={appointment}
                                        selectedDate={selectedDate}
                                        setSelectedDate={setSelectedDate}
                                        onHandleNext={onHandleNext}
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </PageContentStyle>
    )
}

export default Appointment