import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { FiUploadCloud, FiX } from "react-icons/fi";

import ButtonLoader from "components/loader/ButtonLoader";

import { uploadFileDetail } from "redux/drive/drive.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { resetFileUpload } from "redux/drive/drive.slice";
import { modalConst } from "redux/local/local.const";


const UploadDriveFileModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { fileUpload } = useAppState((state) => state.drive)

    const dispatcher = useAppDispatcher()

    const [selectedFile, setSelectedFile] = useState(null);
    const [isDraggingOver, setIsDraggingOver] = useState(false);

    useEffect(() => {
        return () => {
            dispatcher(resetFileUpload())
        }
    }, [])

    useEffect(() => {
        if (fileUpload?.data) {
            dispatcher(resetFileUpload())
            dispatcher(setModal({
                ...modal,
                [modalConst.UPLOAD_DRIVE_FILE_MODAL.stateKey]: false
            }))
        }
    }, [fileUpload?.data])

    const handleFileSelect = (event) => {
        dispatcher(resetFileUpload())
        setSelectedFile(null)

        const file = event.target.files[0];
        if (!file || file.length === 0) {
            alert("file not selected!")
            return;
        }
        if (file?.size < 10 * 1048576) {
            setSelectedFile({
                file: file,
                fileList: event?.target?.files,
                fileUrl: URL.createObjectURL(file)
            })
        } else {
            toast.warn("File size is too large!")
        }
    };

    const handleRemoveFile = () => {
        dispatcher(resetFileUpload())
        setSelectedFile(null);
        setIsDraggingOver(false)
    };

    const handleDragOver = (event) => {
        event.preventDefault();

        setIsDraggingOver(true)
    };

    const handleDragEnter = (event) => {
        event.preventDefault();
        setIsDraggingOver(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setIsDraggingOver(false);
    };


    const handleDrop = (event) => {
        event.preventDefault();

        setIsDraggingOver(true)
        const droppedFiles = event.dataTransfer.files;

        if (droppedFiles?.length > 0) {
            if (droppedFiles[0]?.size < 10 * 1048576) {
                setSelectedFile({
                    file: droppedFiles[0],
                    fileList: droppedFiles,
                    fileUrl: URL.createObjectURL(droppedFiles[0])
                })
            } else {
                toast.warn("File size is too large!")
            }
        } else {
            toast.warn("Please drag a file to upload!")
        }
    };

    const onHandlePasteFile = (event) => {
        const clipboardData = event.clipboardData;
        const pastedFiles = clipboardData.files;

        if (pastedFiles?.length > 0) {
            if (pastedFiles[0]?.size < 10 * 1048576) {
                setSelectedFile({
                    file: pastedFiles[0],
                    fileList: pastedFiles,
                    fileUrl: URL.createObjectURL(pastedFiles[0])
                })
            } else {
                toast.warn("File size is too large!")
            }
        } else {
            toast.warn("Please paste a file to upload!")
        }
    }

    const handleUpload = () => {
        if (fileUpload?.isLoading) return;

        if (!selectedFile) {
            toast.warn("Please select a file to upload!")
            return;
        }
        dispatcher(resetFileUpload())
        dispatcher(uploadFileDetail(selectedFile?.file))
    };

    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"min-h-[12rem] block w-full space-y-5"}>
                <div className="w-full flex flex-col items-center justify-center gap-3">
                    <FiUploadCloud size={50} color={"#2196f3"} />
                    <h2 className="font-bodyPri font-bold text-text-900 text-xl tracking-wide">
                        {"Upload File"}
                    </h2>
                    {/* <p className="upload-subtitle">
                        {"File must be in pdf, png, or jpg format."}
                    </p> */}
                </div>
                <div
                    className={cn(
                        "w-full p-5 flex flex-col items-center justify-center gap-4 rounded-lg",
                        "border-2 border-dashed border-text-400",
                        (isDraggingOver || selectedFile) ? "bg-divider-darkLight" : ""
                    )}
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onPaste={onHandlePasteFile}
                >
                    <div className={"relative"}>
                        <label
                            htmlFor={"file-input"}
                            className={cn(
                                "w-fit px-3 py-3 flex items-center justify-center rounded-md bg-primary-dark text-text-50 cursor-pointer",
                                "hover:bg-secondary-dark"
                            )}
                        >
                            {"Browse"}
                        </label>
                        <input
                            id={"file-input"}
                            type="file"
                            onChange={handleFileSelect}
                            style={{ display: "none" }}
                        />
                    </div>
                    <span className={"w-fit px-3 py-2 m-0 bg-divider-darkLight rounded-lg font-bodyPri font-normal text-text-600 text-base overflow-hidden"}>
                        {selectedFile
                            ? <span className={"flex flex-col items-center justify-center gap-1.5 sm:flex-row sm:items-center sm:justify-start sm:space-x-2"}>
                                {"Selected File: "}
                                <span className={"font-medium text-text-800"}>
                                    {selectedFile?.file?.name?.length > 30
                                        ? selectedFile?.file?.name?.slice(0, 30) + " ..."
                                        : selectedFile?.file?.name
                                    }
                                </span>
                            </span>
                            : <span onClick={handleFileSelect}>
                                {"Drag & drop your file here or click to browse"}
                                </span>
                            }
                            </span>
                    {selectedFile && (
                            <div className={"w-full self-stretch flex flex-col items-center justify-between gap-3"}>
                                <div className={"flex items-center justify-start gap-2"}>
                                    <span className={"hidden sm:block max-w-[24rem] font-bodyPri font-medium text-text-800 text-base truncate"}>
                                        {selectedFile?.file?.name}
                                    </span>
                                    <span className={"block sm:hidden font-bodyPri font-medium text-text-800 text-base whitespace-nowrap"}>
                                        {selectedFile?.file?.name?.length > 25
                                            ? selectedFile?.file?.name?.slice(0, 25) + " ..."
                                            : selectedFile?.file?.name
                                        }
                                    </span>
                                    <button
                                        className={"p-0.5 bg-text-50 rounded-full cursor-pointer"}
                                        onClick={handleRemoveFile}>
                                        <FiX size={15} className={"font-bold text-divider-dark"} />
                                    </button>
                                </div>
                            </div>
                        )}
                </div>
            </div>
            {fileUpload?.message &&
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"w-full text-center font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {fileUpload?.message}
                    </span>
                </div>
            }
            <div className={"w-full flex justify-center items-center"}>
                <button
                    className={cn(
                        "w-fit px-3 py-3 flex items-center justify-center rounded-md bg-primary-dark text-text-50 cursor-pointer",
                        "hover:bg-secondary-dark disabled:bg-text-300 disabled:cursor-not-allowed"
                    )}
                    disabled={!selectedFile}
                    onClick={handleUpload}
                >
                    {fileUpload.isLoading &&
                        <ButtonLoader isLoading={fileUpload.isLoading} />
                    }
                    {!fileUpload?.isLoading && "Upload"}
                </button>
            </div>
        </div>
    );
};

export default UploadDriveFileModal;
