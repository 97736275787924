import { useEffect } from "react";

import { AnimatePresence } from "framer-motion";

import ViewContactContent from "./components/viewContactDetail/ViewContactContent";
import EditContactContent from "./components/editContactDetail/EditContactContent";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetDestroyCrmContactDetail, resetModifyCrmContactDetail, resetModifyCrmContactDetailPayload } from "redux/crm/crm.slice";

function ContactDetailContent({ closeModal }) {
    const { modifyCrmContactDetail, destroyCrmContactDetail } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (modifyCrmContactDetail?.data || destroyCrmContactDetail?.data) {
            dispatcher(resetModifyCrmContactDetail())
            dispatcher(resetModifyCrmContactDetailPayload())
            dispatcher(resetDestroyCrmContactDetail())
            closeModal()
        }
    }, [modifyCrmContactDetail?.data, destroyCrmContactDetail?.data])

    return (
        <AnimatePresence key={modifyCrmContactDetail?.payload?.isEditEnabled}>
            {modifyCrmContactDetail?.payload?.isEditEnabled ? (
                <EditContactContent closeModal={closeModal} />
            ) : (
                <ViewContactContent closeModal={closeModal} />
            )}
        </AnimatePresence>
    );
}

export default ContactDetailContent;