import { createSlice } from "@reduxjs/toolkit"
import { REFERRAL_INITIAL_STATE } from "redux/referral/referral.initialState"
import { referralStatus } from "redux/referral/referral.const"
import { filterName } from "redux/referral/referral.const"

export const referrral = createSlice({
    name: "referrral",
    initialState: REFERRAL_INITIAL_STATE,
    reducers: {
        // reducers for refer and earn table
        setUserReferralListLoading: (state, { payload }) => {
            state.userReferralList.isLoading = payload
        },
        setUserReferralList: (state, { payload }) => {
            state.userReferralList.userReferralList = payload
            state.userReferralList.filteredUserReferralList = payload
            state.userReferralList.errorMsg = REFERRAL_INITIAL_STATE.userReferralList.errorMsg
        },
        setUserReferralListErrorMsg: (state, { payload }) => {
            state.userReferralList.errorMsg = payload
            state.userReferralList.userReferralList = REFERRAL_INITIAL_STATE.userReferralList.userReferralList
            state.userReferralList.filteredUserReferralList = REFERRAL_INITIAL_STATE.userReferralList.filteredUserReferralList
        },
        setClearUserReferralList: (state) => {
            state.userReferralList = REFERRAL_INITIAL_STATE.userReferralList
        },

        // filter list for referral
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps;
            const filteredList = payload.list
                .filter((referral) => (
                    payload.filterProps.referralStatus === referralStatus.ALL.value || referral.status === payload.filterProps.referralStatus)
                )
            switch (payload.filterName) {
                case filterName.REFERRAL.value:
                    state.userReferralList.filteredUserReferralList = filteredList
                    break;
                default:
                    break;
            }
        },
        setClearFilterProps: (state, { payload }) => {
            state.filterProps = REFERRAL_INITIAL_STATE.filterProps
        }
    }
})
export const {
    setUserReferralListLoading,
    setUserReferralList,
    setUserReferralListErrorMsg,
    setClearUserReferralList,

    setFilterProps,
    setClearFilterProps,
} = referrral.actions
export default referrral.reducer;