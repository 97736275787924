import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { BsEye, BsEyeSlash } from "react-icons/bs";

import ButtonLoader from "components/loader/ButtonLoader";
import InputOtp from "components/inputOtp/InputOtp";

import { verifyAuthorizedUser } from "redux/auth/auth.request";
import { createSendOtp, createVerifyOtp } from "redux/notification/notification.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setClearVerifyAuth } from "redux/auth/auth.slice";
import { setClearVerifyOtp, setClearSendOtp } from 'redux/notification/notification.slice';
import { modalConst } from "redux/local/local.const";
import { authenticateWith } from "redux/auth/auth.const";
import { otpDeliveryMethod, otpStatus } from "redux/notification/notification.const";

import { validatePassword } from "utils/validation-functions";

const VerifyAuthenticationModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { verifyAuth } = useAppState((state) => state.auth)
    const { user, userGeneralDetail } = useAppState((state) => state.user)
    const { sendOtp, verifyOtp } = useAppState((s) => s.notification)

    const dispatcher = useAppDispatcher()

    const [inputPassword, setInputPassword] = useState("")
    const [isShowPassword, setIsShowPassword] = useState(false)
    const [authenticationType, setAuthenticationType] = useState(authenticateWith?.PASSWORD?.value)
    const [isSendCodeEnabled, setIsSendCodeEnabled] = useState(false)
    const [myOtp, setMyOtp] = useState("")
    const [isOtpError, setIsOtpError] = useState(false)

    useEffect(() => {
        if (verifyAuth?.verifyAuth?.email) {
            if (userGeneralDetail?.userGeneralDetailPayload?.isEmailUpdateEnabled) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.UPDATE_EMAIL_MODAL.stateKey]: true,
                    [modalConst.VERIFY_AUTHENTICATION_MODAL.stateKey]: false
                }))
            }
            if (userGeneralDetail?.userGeneralDetailPayload?.isMobileNoUpdateEnabled) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.UPDATE_MOBILE_NO_MODAL.stateKey]: true,
                    [modalConst.VERIFY_AUTHENTICATION_MODAL.stateKey]: false
                }))
            }
            if (userGeneralDetail?.userGeneralDetailPayload?.isPasswordUpdateEnabled) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.UPDATE_PASSWORD_MODAL.stateKey]: true,
                    [modalConst.VERIFY_AUTHENTICATION_MODAL.stateKey]: false
                }))
            }

        }

        return () => {
            dispatcher(setClearVerifyAuth())
        }
    }, [verifyAuth?.verifyAuth])

    useEffect(() => {
        if (sendOtp?.sendOtp) {
            setIsSendCodeEnabled(true)
        }
        if (sendOtp?.errorMsg) {
            setIsSendCodeEnabled(false)
        }
    }, [sendOtp?.sendOtp])

    useEffect(() => {
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            dispatcher(setClearVerifyOtp())
            dispatcher(setClearSendOtp())
            if (userGeneralDetail?.userGeneralDetailPayload?.isEmailUpdateEnabled) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.UPDATE_EMAIL_MODAL.stateKey]: true,
                    [modalConst.VERIFY_AUTHENTICATION_MODAL.stateKey]: false
                }))
            }
            if (userGeneralDetail?.userGeneralDetailPayload?.isMobileNoUpdateEnabled) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.UPDATE_MOBILE_NO_MODAL.stateKey]: true,
                    [modalConst.VERIFY_AUTHENTICATION_MODAL.stateKey]: false
                }))
            }
            if (userGeneralDetail?.userGeneralDetailPayload?.isPasswordUpdateEnabled) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.UPDATE_PASSWORD_MODAL.stateKey]: true,
                    [modalConst.VERIFY_AUTHENTICATION_MODAL.stateKey]: false
                }))
            }
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.PENDING.value) {
            toast.warning("Something went wrong")
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.EXPIRED.value) {
            toast.error("Code Expired")
            return;
        }
        if (!verifyOtp?.verifyOtp && verifyOtp?.errorMsg) {
            setIsOtpError(true)
        }
    }, [verifyOtp?.verifyOtp || verifyOtp?.errorMsg])

    const onSubmit = () => {
        if ((authenticationType === authenticateWith?.PASSWORD?.value) && !inputPassword) {
            toast.error("Please Enter Password!")
            return;
        }
        if ((authenticationType === authenticateWith?.PASSWORD?.value) && inputPassword && !validatePassword(inputPassword)?.isRequiredCharValid) {
            toast.error("Please Enter Valid Password!")
            return;
        }
        if (authenticationType === authenticateWith?.OTP?.value) {
            dispatcher(createSendOtp({
                channel: otpDeliveryMethod?.EMAIL?.value,
                email: user?.user?.email
            }))
            return;
        }
        if (authenticationType === authenticateWith?.PASSWORD?.value && inputPassword) {
            dispatcher(verifyAuthorizedUser({
                email: user?.user?.email,
                password: inputPassword
            }))
        }
    }

    const onHandleVerifyCode = () => {
        if (myOtp?.length < 6) return;
        dispatcher(setClearVerifyOtp())
        if (sendOtp?.sendOtp?.otpId) {
            dispatcher(createVerifyOtp({
                otpId: sendOtp?.sendOtp?.otpId,
                otp: myOtp
            }))
        }
    }

    if (isSendCodeEnabled) {
        return (
            <div className={"space-y-5 overflow-hidden"}>
                <div className={"min-h-[18rem] block space-y-10 pt-5"}>
                    <div className={"flex flex-col items-center justify-center gap-3"}>
                        <div className={"flex items-center justify-start gap-3 font-bodyPri font-normal text-text-900 text-base"}>
                            {user?.user?.email}
                        </div>
                        <InputOtp
                            email={user?.user?.email}
                            otpLength={6}
                            setMyOtp={setMyOtp}
                            isOtpError={isOtpError}
                            setIsOtpError={setIsOtpError}
                        />
                    </div>
                    <div
                        className={cn(
                            "py-1.5 rounded-lg",
                            "text-text-50 font-buttons font-medium text-base w-full text-center",
                            myOtp?.length < 6 && "bg-divider-medium",
                            myOtp?.length === 6 && "bg-primary-main hover:bg-secondary-dark cursor-pointer"
                        )}
                        onClick={onHandleVerifyCode}
                    >
                        {(verifyOtp?.isLoading || verifyAuth?.isLoading) &&
                            <ButtonLoader isLoading={verifyOtp?.isLoading || verifyAuth?.isLoading} />
                        }
                        {(!verifyOtp?.isLoading && !verifyAuth?.isLoading) && "Verify Code"}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={"space-y-5 overflow-hidden"}>
            <div className={"min-h-[18rem] block space-y-10 pt-5"}>
                <div className="w-full h-full flex flex-col items-center justify-center gap-8">
                    <input
                        type={"text"}
                        className={cn(
                            "w-full px-3 py-1 rounded-md border-2 border-divider-medium focus:outline-none focus:border-2 focus:border-text-400 hover:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                        )}
                        placeholder={""}
                        value={user?.user?.email}
                        disabled={user?.user?.email && true}
                    />
                    <div className={"w-full flex items-center justify-evenly"}>
                        <div className={"flex items-center justify-start gap-1"}>
                            <input
                                type={"radio"}
                                className={"border border-text-300 cursor-pointer focus:outline-none"}
                                checked={authenticationType === authenticateWith?.PASSWORD?.value && true}
                                onClick={() => setAuthenticationType(authenticateWith?.PASSWORD?.value)}
                            />
                            <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                                {"Password"}
                            </span>
                        </div>
                        <div className={"flex items-center justify-start gap-1"}>
                            <input
                                type={"radio"}
                                name={"status"}
                                className={"border border-text-300 cursor-pointer focus:outline-none"}
                                checked={authenticationType === authenticateWith?.OTP?.value && true}
                                onClick={() => setAuthenticationType(authenticateWith?.OTP?.value)}
                            />
                            <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                                {"Code"}
                            </span>
                        </div>
                    </div>
                    {(authenticationType === authenticateWith?.PASSWORD?.value) &&
                        <div className={cn(
                            "w-full flex items-center justify-between gap-1",
                            "px-3 py-1 font-bodyPri font-normal text-text-800 text-base rounded-md",
                            "border-2 border-divider-medium focus:outline-none focus:border-2 focus:border-text-400 hover:border-text-400"
                        )}>
                            <input
                                type={isShowPassword ? "text" : "password"}
                                className={"w-full focus:outline-none"}
                                value={inputPassword}
                                onChange={(event) => setInputPassword(event.target.value)}
                            />
                            <span className={""} onClick={() => setIsShowPassword(!isShowPassword)}>
                                {isShowPassword &&
                                    <BsEye className={"text-text-800 text-lg cursor-pointer"} />
                                }
                                {!isShowPassword &&
                                    <BsEyeSlash className={"text-text-800 text-lg cursor-pointer"} />
                                }
                            </span>
                        </div>
                    }
                    {verifyAuth?.errorMsg &&
                        <div className={"w-full flex items-center justify-center"}>
                            <div className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                {verifyAuth?.errorMsg}
                            </div>
                        </div>
                    }
                </div>
                <div
                    className={cn(
                        "px-3 py-1 flex justify-center items-center rounded-md cursor-pointer",
                        "font-buttons font-normal text-base",
                        "text-text-50 bg-primary-main hover:bg-secondary-main hover:text-text-50",
                    )}
                    onClick={onSubmit}
                >
                    {(verifyAuth?.isLoading || sendOtp?.isLoading) &&
                        <ButtonLoader isLoading={verifyAuth?.isLoading || sendOtp?.isLoading} />
                    }
                    {(!verifyAuth?.isLoading && !sendOtp?.isLoading && authenticationType === authenticateWith?.PASSWORD?.value) && "Submit"}
                    {(!verifyAuth?.isLoading && !sendOtp?.isLoading && authenticationType === authenticateWith?.OTP?.value) && "Send Code"}
                </div>
            </div>
            {/* <div className={"flex items-center justify-end"}>
                <div
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base",
                        "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                    )}
                    onClick={onSubmit}
                >
                    {verifyAuth?.isLoading &&
                        <ButtonLoader isLoading={verifyAuth?.isLoading} />
                    }
                    {!verifyAuth?.isLoading && "Submit"}
                </div>
            </div> */}
        </div>
    )
}

export default VerifyAuthenticationModal;