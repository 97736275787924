export const dataFAQ = [
    {
        question: "How can I sign up as an educator on Edulyte?",
        answer: 'To sign up as an educator on Edulyte, simply click on the  "Teach with us" button. Provide your valid email address and phone number to create your account.'
    },
    {
        question: "What information do I need to provide to create my professional tutor profile?",
        answer: "To build your winning profile, you'll need to provide details about your expertise, teaching style, qualifications, and skills. Stand out with a captivating one-liner and list the subjects you can teach at different difficulty levels."
    },
    {
        question: "How can I utilise Edulyte’s software for offering my services?",
        answer: "You can leverage Edulyte’s powerful software to effortlessly offer your services as a tutor. Our user-friendly platform allows you to schedule classes, communicate with learners, accept secure payments, and collect reviews to build trust. Additionally, you can expand your reach by offering flexible group classes and courses, and utilise our integrated video conference tool for seamless online sessions. With Edulyte, you have the freedom to set your own pricing and scheduling preferences, making it easy to manage and grow your teaching business without any upfront investment."
    },
    {
        question: "How can I grow my tutoring business with custom marketing solutions?",
        answer: "Edulyte offers value-added, customised marketing services to boost your online business. These tailored solutions can help you reach a wider audience and increase your impact. You can explore this option after signing up."
    },
    {
        question: "What is the significance of the blue checkmark on Edulyte?",
        answer: 'The blue checkmark represents a "verified account" status. It enhances your credibility as a trusted provider. Having this checkmark on your profile will attract more learners, whether your account is private or listed at an affordable one time fee.'
    },
    {
        question: "Can I offer both one-on-one and group classes?",
        answer: "Absolutely! As an educator on Edulyte, you have the flexibility to offer both one-on-one and group classes. You can create dynamic learning experiences for individual learners or engage with a small group of learners, fostering collaborative learning environments. The choice is yours to tailor your teaching approach and cater to different preferences."
    },
    {
        question: "What subjects can I teach on Edulyte?",
        answer: "You can teach a wide range of subjects. We welcome educators with expertise in various fields to share their knowledge and skills on our platform."
    },
    {
        question: "How can I earn money through my classes on Edulyte?",
        answer: "By offering one-on-one and group classes on Edulyte, you can set your desired pricing, and for every successful session, you will earn revenue, with Edulyte charging only 10% of your sales as a platform fee."
    },
    {
        question: "How can I update my profile and preferences on Edulyte?",
        answer: 'You can easily update your profile and preferences by logging into your account and accessing the "Profile Settings" section. Remember to switch your role to “Tutor”.'
    },
    {
        question: "Is the screening process mandatory to join the Edulyte Marketplace?",
        answer: "While listing your services on the Edulyte Marketplace is optional, the screening process ensures quality and trust among educators and learners. As a private tutor, you can share your professionally designed profile and link to classes with your learners and on social media."
    },
    {
        question: "Will I have access to future software upgrades?",
        answer: "Absolutely! As an Edulyte educator, you'll have access to all the exciting future upgrades and enhancements we roll out. We are committed to continuously improving our software to provide you with the best possible experience and support your teaching journey. You can rest assured that as our platform evolves, you'll have all the latest tools and features at your disposal to enhance your teaching and reach even greater heights of success."
    },
    {
        question: "Can I switch between the roles of a tutor and a student on the platform?",
        answer: "Absolutely! On your dashboard, located at the top left corner, you can easily switch between the roles of a tutor and a student. This flexibility allows you to seamlessly navigate and experience both perspectives, empowering you to make the most of your learning and teaching journey on Edulyte."
    },
    {
        question: "Can I explore FAQs and find answers to other common questions related to tutoring on Edulyte?",
        answer: <span>Explore our comprehensive FAQ section for detailed information and helpful answers related to your tutor account, teaching with Edulyte, and everything you need to know to make the most of our platform. From account setup to scheduling, payments, and more, we've got you covered. Go to our <a className={"text-primary-main"} href='https://support.edulyte.com/portal/en/kb/tutor-helpdesk/' target='_blank' rel='noopener noreferrer'>knowledge base</a>.</span>    },
];