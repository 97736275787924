import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";

import { cn } from "utils/cn.utils";

import { AiOutlinePlus } from "react-icons/ai";

import ComponentLoader from "components/loader/ComponentLoader";
import { libraryTabConst } from "components/modals/lmsModals/attachLibraryModal/data";

import { searchAlreadyAddedLesson } from "../../../editLMSCourse.data";

import { createLmsCourseSectionLessonDetail } from "redux/edulyteLms/lmsCourse/lmsCourse.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetLocalTabs, setLocalTabs, setModal } from 'redux/local/local.slice';
import { resetAddLmsCourseSectionDetailPayload, setAddLmsCourseSectionDetailPayload } from "redux/edulyteLms/lmsCourse/lmsCourse.slice";
import { modalConst } from 'redux/local/local.const';
import { lmsCourseSectionLessonTypeEnum, addLmsCourseSectionDetailPayload } from "redux/edulyteLms/lmsCourse/lmsCourse.const";

const EmptyCourseSectionItem = ({ courseSectionItem }) => {
    const { modal, localTabs } = useAppState((state) => state.local)
    const { lmsCourseDetail, addLmsCourseSectionDetail, addLmsCourseSectionLesson } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    const [isDraggingOver, setIsDraggingOver] = useState(false)

    useEffect(() => {
        if (localTabs?.tabItem && addLmsCourseSectionDetail?.payload?.sectionId) {
            if (localTabs?.tabItem?.payload?.lectureId && (localTabs?.tabItem?.tabType === libraryTabConst.LECTURES.value)) {
                const lecturePresent = searchAlreadyAddedLesson(lmsCourseDetail?.data?.course_sections, lmsCourseSectionLessonTypeEnum.LECTURE.value, localTabs?.tabItem?.payload?.lectureId)
                if (lecturePresent) {
                    alert(`Uh-oh! You've already added this lecture in ${lecturePresent}. No duplicates, please!`)
                    return;
                }
                dispatcher(resetLocalTabs())
                dispatcher(createLmsCourseSectionLessonDetail({
                    lecture_id: localTabs?.tabItem?.payload?.lectureId,
                    lms_course_section_id: addLmsCourseSectionDetail?.payload?.sectionId
                }))
                dispatcher(setModal({
                    ...modal,
                    [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: false
                }))
            }
            if (localTabs?.tabItem?.payload?.articleId && (localTabs?.tabItem?.tabType === libraryTabConst.ARTICLES.value)) {
                const articlePresent = searchAlreadyAddedLesson(lmsCourseDetail?.data?.course_sections, lmsCourseSectionLessonTypeEnum.ARTICLE.value, localTabs?.tabItem?.payload?.articleId)
                if (articlePresent) {
                    alert(`Uh-oh! You've already added this article in ${articlePresent}. No duplicates, please!`)
                    return;
                }
                dispatcher(createLmsCourseSectionLessonDetail({
                    article_id: localTabs?.tabItem?.payload?.articleId,
                    lms_course_section_id: addLmsCourseSectionDetail?.payload?.sectionId
                }))
                dispatcher(resetLocalTabs())
                dispatcher(setModal({
                    ...modal,
                    [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: false
                }))
            }
            if (localTabs?.tabItem?.payload?.quizId && (localTabs?.tabItem?.tabType === libraryTabConst.QUIZZES.value)) {
                const quizPresent = searchAlreadyAddedLesson(lmsCourseDetail?.data?.course_sections, lmsCourseSectionLessonTypeEnum.QUIZ.value, localTabs?.tabItem?.payload?.quizId)
                if (quizPresent) {
                    alert(`Uh-oh! You've already added this quiz in ${quizPresent}. No duplicates, please!`)
                    return;
                }
                dispatcher(createLmsCourseSectionLessonDetail({
                    quiz_id: localTabs?.tabItem?.payload?.quizId,
                    lms_course_section_id: addLmsCourseSectionDetail?.payload?.sectionId
                }))
                dispatcher(resetLocalTabs())
                dispatcher(setModal({
                    ...modal,
                    [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: false
                }))
            }
        }
    }, [localTabs])

    const onHandleDragOver = (event) => {
        event.preventDefault();
        setIsDraggingOver(true)
    };

    const onHandleDragEnter = (event) => {
        event.preventDefault();
        setIsDraggingOver(true);
    };

    const onHandleDragLeave = (event) => {
        event.preventDefault();
        setIsDraggingOver(false);
    };

    const onHandleDrop = async (event) => {
        event.preventDefault();

        setIsDraggingOver(false)
        let parsedData = {}
        try {
            parsedData = JSON.parse(event.dataTransfer.getData("application/json"))
        } catch (error) {
            console.error(error)
            toast.error("Something went wrong!")
        }

        if (parsedData) {
            const body = {
                lms_course_section_id: courseSectionItem?.id,
            }
            if (parsedData?.lessonType === lmsCourseSectionLessonTypeEnum.LECTURE.value) {
                const lecturePresent = searchAlreadyAddedLesson(lmsCourseDetail?.data?.course_sections, lmsCourseSectionLessonTypeEnum.LECTURE.value, parsedData?.lecture_id)
                // const filteredCourseSectionLessonLecture = courseSectionItem?.lms_course_section_lessons?.filter((courseSectionLessonLecture) => (courseSectionLessonLecture?.lecture?.id === parsedData?.lecture_id))
                if (lecturePresent) {
                    alert(`Uh-oh! You've already added this lecture in ${lecturePresent}. No duplicates, please!`)
                    return;
                }

                body["lecture_id"] = parsedData?.lecture_id
                dispatcher(createLmsCourseSectionLessonDetail(body))
            }
            if (parsedData?.lessonType === lmsCourseSectionLessonTypeEnum.ARTICLE.value) {
                const articlePresent = searchAlreadyAddedLesson(lmsCourseDetail?.data?.course_sections, lmsCourseSectionLessonTypeEnum.ARTICLE.value, parsedData?.article_id)
                // const filteredCourseSectionLessonArticle = courseSectionItem?.lms_course_section_lessons?.filter((courseSectionLessonArticle) => (courseSectionLessonArticle?.article?.id === parsedData?.article_id))
                if (articlePresent) {
                    alert(`Uh-oh! You've already added this article in ${articlePresent}. No duplicates, please!`)
                    return;
                }

                body["article_id"] = parsedData?.article_id
                dispatcher(createLmsCourseSectionLessonDetail(body))
            }
            if (parsedData?.lessonType === lmsCourseSectionLessonTypeEnum.QUIZ.value) {
                const quizPresent = searchAlreadyAddedLesson(lmsCourseDetail?.data?.course_sections, lmsCourseSectionLessonTypeEnum.QUIZ.value, parsedData?.id)
                // const filteredCourseSectionLessonQuiz = courseSectionItem?.lms_course_section_lessons?.filter((courseSectionLessonQuiz) => (courseSectionLessonQuiz?.quiz?.id === parsedData?.id))
                if (quizPresent) {
                    alert(`Uh-oh! You've already added this quiz in ${quizPresent}. No duplicates, please!`)
                    return;
                }

                body["quiz_id"] = parsedData?.id
                dispatcher(createLmsCourseSectionLessonDetail(body))
            }
        }
    }

    const onHandleOpenModal = useCallback((lessonType) => {
        dispatcher(resetAddLmsCourseSectionDetailPayload())
        dispatcher(resetLocalTabs())
        let activeTab = null

        if (lessonType === lmsCourseSectionLessonTypeEnum.LECTURE.value) {
            activeTab = libraryTabConst.LECTURES.value
        }
        if (lessonType === lmsCourseSectionLessonTypeEnum.ARTICLE.value) {
            activeTab = libraryTabConst.ARTICLES.value
        }
        if (lessonType === lmsCourseSectionLessonTypeEnum.QUIZ.value) {
            activeTab = libraryTabConst.QUIZZES.value
        }
        dispatcher(setLocalTabs({
            ...localTabs,
            tabList: Object.values(libraryTabConst),
            activeTabList: [activeTab],
            currentActiveTab: activeTab,
        }))
        dispatcher(setAddLmsCourseSectionDetailPayload({
            ...addLmsCourseSectionDetailPayload,
            sectionId: courseSectionItem?.id
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: true,
        }))
    }, [modal, localTabs, addLmsCourseSectionDetail?.payload])

    return (
        <div
            className={cn(
                "w-full px-5 py-3 bg-white rounded-lg flex items-center justify-center gap-3",
                "border border-dashed border-divider-dark cursor-pointer",
                isDraggingOver && "bg-divider-darkLight"
            )}
            onDragOver={onHandleDragOver}
            onDragEnter={onHandleDragEnter}
            onDragLeave={onHandleDragLeave}
            onDrop={onHandleDrop}
        >
            {addLmsCourseSectionLesson?.isLoading &&
                <ComponentLoader
                    isLoading={addLmsCourseSectionLesson?.isLoading}
                    className={"h-8"}
                />
            }
            {!addLmsCourseSectionLesson?.isLoading &&
                <div className={"flex flex-col gap-1 lg:flex-row flex-wrap items-center justify-center lg:gap-3"}>
                    <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                        {"Drag & drop from right "}
                    </span>
                    <span className={"font-bodyPri font-bold text-text-800 text-base"}>
                        {" OR "}
                    </span>
                    <div className={'flex flex-wrap items-center justify-center gap-2'}>
                        {Object.values(lmsCourseSectionLessonTypeEnum).map((lessonType, index) => (
                            <button
                                key={index}
                                className={cn(
                                    'flex justify-center items-center bg-white rounded-full px-3 py-0.5 md:px-5 md:py-1',
                                    'border border-divider-dark hover:border-primary-dark hover:text-primary-dark',
                                    'font-bodyPri font-normal text-text-900 text-base tracking-wide'
                                )}
                                onClick={() => onHandleOpenModal(lessonType?.value)}
                            >
                                <AiOutlinePlus className={'text-lg'} />
                                {lessonType.label}
                            </button>
                        ))}
                    </div>
                </div>
            }
        </div>
    )
}

export default EmptyCourseSectionItem;