import { createSlice } from "@reduxjs/toolkit";
import { LMS_LESSON_INITIAL_STATE } from "./lmsLesson.initialState";

const lmsLesson = createSlice({
    name: 'Lms lesson',
    initialState: LMS_LESSON_INITIAL_STATE,
    reducers: {
        /* student lms lesson */
        // list
        setStudentLmsLessonListLoading: (state, { payload }) => {
            state.studentLmsLessonList.isLoading = payload
        },
        setStudentLmsLessonListData: (state, { payload }) => {
            state.studentLmsLessonList.data = payload
            state.studentLmsLessonList.message = LMS_LESSON_INITIAL_STATE.studentLmsLessonList.message
        },
        setStudentLmsLessonListMessage: (state, { payload }) => {
            state.studentLmsLessonList.message = payload
            state.studentLmsLessonList.data = LMS_LESSON_INITIAL_STATE.studentLmsLessonList.data
        },
        resetStudentLmsLessonList: (state) => {
            state.studentLmsLessonList = LMS_LESSON_INITIAL_STATE.studentLmsLessonList
        },

        // detail
        setStudentLmsLessonDetailLoading: (state, { payload }) => {
            state.studentLmsLessonDetail.isLoading = payload
        },
        setStudentLmsLessonDetailData: (state, { payload }) => {
            state.studentLmsLessonDetail.data = payload
            state.studentLmsLessonDetail.message = LMS_LESSON_INITIAL_STATE.studentLmsLessonDetail.message
        },
        setStudentLmsLessonDetailMessage: (state, { payload }) => {
            state.studentLmsLessonDetail.message = payload
            state.studentLmsLessonDetail.data = LMS_LESSON_INITIAL_STATE.studentLmsLessonDetail.data
        },
        resetStudentLmsLessonDetail: (state) => {
            state.studentLmsLessonDetail = LMS_LESSON_INITIAL_STATE.studentLmsLessonDetail
        },

        /* tutor lms lesson */
        // list
        setTutorLmsLessonListLoading: (state, { payload }) => {
            state.tutorLmsLessonList.isLoading = payload
        },
        setTutorLmsLessonListData: (state, { payload }) => {
            state.tutorLmsLessonList.data = payload
            state.tutorLmsLessonList.message = LMS_LESSON_INITIAL_STATE.tutorLmsLessonList.message
        },
        setTutorLmsLessonListMessage: (state, { payload }) => {
            state.tutorLmsLessonList.message = payload
            state.tutorLmsLessonList.data = LMS_LESSON_INITIAL_STATE.tutorLmsLessonList.data
        },
        resetTutorLmsLessonList: (state) => {
            state.tutorLmsLessonList = LMS_LESSON_INITIAL_STATE.tutorLmsLessonList
        },

        // detail
        setTutorLmsLessonDetailLoading: (state, { payload }) => {
            state.tutorLmsLessonDetail.isLoading = payload
        },
        setTutorLmsLessonDetailData: (state, { payload }) => {
            state.tutorLmsLessonDetail.data = payload
            state.tutorLmsLessonDetail.message = LMS_LESSON_INITIAL_STATE.tutorLmsLessonDetail.message
        },
        setTutorLmsLessonDetailMessage: (state, { payload }) => {
            state.tutorLmsLessonDetail.message = payload
            state.tutorLmsLessonDetail.data = LMS_LESSON_INITIAL_STATE.tutorLmsLessonDetail.data
        },
        resetTutorLmsLessonDetail: (state) => {
            state.tutorLmsLessonDetail = LMS_LESSON_INITIAL_STATE.tutorLmsLessonDetail
        },

        /* lms lesson */
        // list
        setLmsLessonListLoading: (state, { payload }) => {
            state.lmsLessonList.isLoading = payload
        },
        setLmsLessonListData: (state, { payload }) => {
            state.lmsLessonList.data = payload
            state.lmsLessonList.message = LMS_LESSON_INITIAL_STATE.lmsLessonList.message
        },
        setLmsLessonListMessage: (state, { payload }) => {
            state.lmsLessonList.message = payload
            state.lmsLessonList.data = LMS_LESSON_INITIAL_STATE.lmsLessonList.data
        },
        resetLmsLessonList: (state) => {
            state.lmsLessonList = LMS_LESSON_INITIAL_STATE.lmsLessonList
        },

        // detail
        setLmsLessonDetailLoading: (state, { payload }) => {
            state.lmsLessonDetail.isLoading = payload
        },
        setLmsLessonDetailData: (state, { payload }) => {
            state.lmsLessonDetail.data = payload
            state.lmsLessonDetail.message = LMS_LESSON_INITIAL_STATE.lmsLessonDetail.message
        },
        setLmsLessonDetailMessage: (state, { payload }) => {
            state.lmsLessonDetail.message = payload
            state.lmsLessonDetail.data = LMS_LESSON_INITIAL_STATE.lmsLessonDetail.data
        },
        resetLmsLessonDetail: (state) => {
            state.lmsLessonDetail = LMS_LESSON_INITIAL_STATE.lmsLessonDetail
        },

        // add
        setAddLmsLessonDetailLoading: (state, { payload }) => {
            state.addLmsLessonDetail.isLoading = payload
        },
        setAddLmsLessonDetailPayload: (state, { payload }) => {
            state.addLmsLessonDetail.payload = payload
        },
        setAddLmsLessonDetailData: (state, { payload }) => {
            state.addLmsLessonDetail.data = payload
            state.addLmsLessonDetail.message = LMS_LESSON_INITIAL_STATE.addLmsLessonDetail.message
        },
        setAddLmsLessonDetailMessage: (state, { payload }) => {
            state.addLmsLessonDetail.message = payload
            state.addLmsLessonDetail.data = LMS_LESSON_INITIAL_STATE.addLmsLessonDetail.data
        },
        resetAddLmsLessonDetail: (state) => {
            state.addLmsLessonDetail = LMS_LESSON_INITIAL_STATE.addLmsLessonDetail
        },

        // modify
        setModifyLmsLessonDetailLoading: (state, { payload }) => {
            state.modifyLmsLessonDetail.isLoading = payload
        },
        setModifyLmsLessonDetailPayload: (state, { payload }) => {
            state.modifyLmsLessonDetail.payload = payload
        },
        setModifyLmsLessonDetailData: (state, { payload }) => {
            state.modifyLmsLessonDetail.data = payload
            state.modifyLmsLessonDetail.message = LMS_LESSON_INITIAL_STATE.modifyLmsLessonDetail.message
        },
        setModifyLmsLessonDetailMessage: (state, { payload }) => {
            state.modifyLmsLessonDetail.message = payload
            state.modifyLmsLessonDetail.data = LMS_LESSON_INITIAL_STATE.modifyLmsLessonDetail.data
        },
        resetModifyLmsLessonDetail: (state) => {
            state.modifyLmsLessonDetail = LMS_LESSON_INITIAL_STATE.modifyLmsLessonDetail
        },

        // destroy
        setDestroyLmsLessonDetailLoading: (state, { payload }) => {
            state.destroyLmsLessonDetail.isLoading = payload
        },
        setDestroyLmsLessonDetailMessage: (state, { payload }) => {
            state.destroyLmsLessonDetail.message = payload
        },
        resetDestroyLmsLessonDetail: (state) => {
            state.destroyLmsLessonDetail = LMS_LESSON_INITIAL_STATE.destroyLmsLessonDetail
        },

        /* lms lesson user */
        // list
        setLmsLessonUserListLoading: (state, { payload }) => {
            state.lmsLessonUserList.isLoading = payload
        },
        setLmsLessonUserListData: (state, { payload }) => {
            state.lmsLessonUserList.data = payload
            state.lmsLessonUserList.message = LMS_LESSON_INITIAL_STATE.lmsLessonUserList.message
        },
        setLmsLessonUserListMessage: (state, { payload }) => {
            state.lmsLessonUserList.message = payload
            state.lmsLessonUserList.data = LMS_LESSON_INITIAL_STATE.lmsLessonUserList.data
        },
        resetLmsLessonUserList: (state) => {
            state.lmsLessonUserList = LMS_LESSON_INITIAL_STATE.lmsLessonUserList
        },

        // detail
        setLmsLessonUserDetailLoading: (state, { payload }) => {
            state.lmsLessonUserDetail.isLoading = payload
        },
        setLmsLessonUserDetailData: (state, { payload }) => {
            state.lmsLessonUserDetail.data = payload
            state.lmsLessonUserDetail.message = LMS_LESSON_INITIAL_STATE.lmsLessonUserDetail.message
        },
        setLmsLessonUserDetailMessage: (state, { payload }) => {
            state.lmsLessonUserDetail.message = payload
            state.lmsLessonUserDetail.data = LMS_LESSON_INITIAL_STATE.lmsLessonUserDetail.data
        },
        resetLmsLessonUserDetail: (state) => {
            state.lmsLessonUserDetail = LMS_LESSON_INITIAL_STATE.lmsLessonUserDetail
        },

        // add
        setAddLmsLessonUserDetailLoading: (state, { payload }) => {
            state.addLmsLessonUserDetail.isLoading = payload
        },
        setAddLmsLessonUserDetailPayload: (state, { payload }) => {
            state.addLmsLessonUserDetail.payload = payload
        },
        setAddLmsLessonUserDetailData: (state, { payload }) => {
            state.addLmsLessonUserDetail.data = payload
            state.addLmsLessonUserDetail.message = LMS_LESSON_INITIAL_STATE.addLmsLessonUserDetail.message
        },
        setAddLmsLessonUserDetailMessage: (state, { payload }) => {
            state.addLmsLessonUserDetail.message = payload
            state.addLmsLessonUserDetail.data = LMS_LESSON_INITIAL_STATE.addLmsLessonUserDetail.data
        },
        resetAddLmsLessonUserDetail: (state) => {
            state.addLmsLessonUserDetail = LMS_LESSON_INITIAL_STATE.addLmsLessonUserDetail
        },

        // modify
        setModifyLmsLessonUserDetailLoading: (state, { payload }) => {
            state.modifyLmsLessonUserDetail.isLoading = payload
        },
        setModifyLmsLessonUserDetailPayload: (state, { payload }) => {
            state.modifyLmsLessonUserDetail.payload = payload
        },
        setModifyLmsLessonUserDetailData: (state, { payload }) => {
            state.modifyLmsLessonUserDetail.data = payload
            state.modifyLmsLessonUserDetail.message = LMS_LESSON_INITIAL_STATE.modifyLmsLessonUserDetail.message
        },
        setModifyLmsLessonUserDetailMessage: (state, { payload }) => {
            state.modifyLmsLessonUserDetail.message = payload
            state.modifyLmsLessonUserDetail.data = LMS_LESSON_INITIAL_STATE.modifyLmsLessonUserDetail.data
        },
        resetModifyLmsLessonUserDetail: (state) => {
            state.modifyLmsLessonUserDetail = LMS_LESSON_INITIAL_STATE.modifyLmsLessonUserDetail
        },

        // destroy
        setDestroyLmsLessonUserDetailLoading: (state, { payload }) => {
            state.destroyLmsLessonUserDetail.isLoading = payload
        },
        setDestroyLmsLessonUserDetailMessage: (state, { payload }) => {
            state.destroyLmsLessonUserDetail.message = payload
        },
        resetDestroyLmsLessonUserDetail: (state) => {
            state.destroyLmsLessonUserDetail = LMS_LESSON_INITIAL_STATE.destroyLmsLessonUserDetail
        },

        /* lms lesson slug */
        // list
        setLmsLessonSlugListLoading: (state, { payload }) => {
            state.lmsLessonSlugList.isLoading = payload
        },
        setLmsLessonSlugListData: (state, { payload }) => {
            state.lmsLessonSlugList.data = payload
            state.lmsLessonSlugList.message = LMS_LESSON_INITIAL_STATE.lmsLessonSlugList.message
        },
        setLmsLessonSlugListMessage: (state, { payload }) => {
            state.lmsLessonSlugList.message = payload
            state.lmsLessonSlugList.data = LMS_LESSON_INITIAL_STATE.lmsLessonSlugList.data
        },
        resetLmsLessonSlugList: (state) => {
            state.lmsLessonSlugList = LMS_LESSON_INITIAL_STATE.lmsLessonSlugList
        },

        // detail
        setLmsLessonSlugDetailLoading: (state, { payload }) => {
            state.lmsLessonSlugDetail.isLoading = payload
        },
        setLmsLessonSlugDetailData: (state, { payload }) => {
            state.lmsLessonSlugDetail.data = payload
            state.lmsLessonSlugDetail.message = LMS_LESSON_INITIAL_STATE.lmsLessonSlugDetail.message
        },
        setLmsLessonSlugDetailMessage: (state, { payload }) => {
            state.lmsLessonSlugDetail.message = payload
            state.lmsLessonSlugDetail.data = LMS_LESSON_INITIAL_STATE.lmsLessonSlugDetail.data
        },
        resetLmsLessonSlugDetail: (state) => {
            state.lmsLessonSlugDetail = LMS_LESSON_INITIAL_STATE.lmsLessonSlugDetail
        },

        // add
        setAddLmsLessonSlugDetailLoading: (state, { payload }) => {
            state.addLmsLessonSlugDetail.isLoading = payload
        },
        setAddLmsLessonSlugDetailPayload: (state, { payload }) => {
            state.addLmsLessonSlugDetail.payload = payload
        },
        setAddLmsLessonSlugDetailData: (state, { payload }) => {
            state.addLmsLessonSlugDetail.data = payload
            state.addLmsLessonSlugDetail.message = LMS_LESSON_INITIAL_STATE.addLmsLessonSlugDetail.message
        },
        setAddLmsLessonSlugDetailMessage: (state, { payload }) => {
            state.addLmsLessonSlugDetail.message = payload
            state.addLmsLessonSlugDetail.data = LMS_LESSON_INITIAL_STATE.addLmsLessonSlugDetail.data
        },
        resetAddLmsLessonSlugDetail: (state) => {
            state.addLmsLessonSlugDetail = LMS_LESSON_INITIAL_STATE.addLmsLessonSlugDetail
        },

        // modify
        setModifyLmsLessonSlugDetailLoading: (state, { payload }) => {
            state.modifyLmsLessonSlugDetail.isLoading = payload
        },
        setModifyLmsLessonSlugDetailPayload: (state, { payload }) => {
            state.modifyLmsLessonSlugDetail.payload = payload
        },
        setModifyLmsLessonSlugDetailData: (state, { payload }) => {
            state.modifyLmsLessonSlugDetail.data = payload
            state.modifyLmsLessonSlugDetail.message = LMS_LESSON_INITIAL_STATE.modifyLmsLessonSlugDetail.message
        },
        setModifyLmsLessonSlugDetailMessage: (state, { payload }) => {
            state.modifyLmsLessonSlugDetail.message = payload
            state.modifyLmsLessonSlugDetail.data = LMS_LESSON_INITIAL_STATE.modifyLmsLessonSlugDetail.data
        },
        resetModifyLmsLessonSlugDetail: (state) => {
            state.modifyLmsLessonSlugDetail = LMS_LESSON_INITIAL_STATE.modifyLmsLessonSlugDetail
        },

        // destroy
        setDestroyLmsLessonSlugDetailLoading: (state, { payload }) => {
            state.destroyLmsLessonSlugDetail.isLoading = payload
        },
        setDestroyLmsLessonSlugDetailMessage: (state, { payload }) => {
            state.destroyLmsLessonSlugDetail.message = payload
        },
        resetDestroyLmsLessonSlugDetail: (state) => {
            state.destroyLmsLessonSlugDetail = LMS_LESSON_INITIAL_STATE.destroyLmsLessonSlugDetail
        },

        /* lms lesson setting */
        // list
        setLmsLessonSettingListLoading: (state, { payload }) => {
            state.lmsLessonSettingList.isLoading = payload
        },
        setLmsLessonSettingListData: (state, { payload }) => {
            state.lmsLessonSettingList.data = payload
            state.lmsLessonSettingList.message = LMS_LESSON_INITIAL_STATE.lmsLessonSettingList.message
        },
        setLmsLessonSettingListMessage: (state, { payload }) => {
            state.lmsLessonSettingList.message = payload
            state.lmsLessonSettingList.data = LMS_LESSON_INITIAL_STATE.lmsLessonSettingList.data
        },
        resetLmsLessonSettingList: (state) => {
            state.lmsLessonSettingList = LMS_LESSON_INITIAL_STATE.lmsLessonSettingList
        },

        // detail
        setLmsLessonSettingDetailLoading: (state, { payload }) => {
            state.lmsLessonSettingDetail.isLoading = payload
        },
        setLmsLessonSettingDetailData: (state, { payload }) => {
            state.lmsLessonSettingDetail.data = payload
            state.lmsLessonSettingDetail.message = LMS_LESSON_INITIAL_STATE.lmsLessonSettingDetail.message
        },
        setLmsLessonSettingDetailMessage: (state, { payload }) => {
            state.lmsLessonSettingDetail.message = payload
            state.lmsLessonSettingDetail.data = LMS_LESSON_INITIAL_STATE.lmsLessonSettingDetail.data
        },
        resetLmsLessonSettingDetail: (state) => {
            state.lmsLessonSettingDetail = LMS_LESSON_INITIAL_STATE.lmsLessonSettingDetail
        },

        // payload
        setLmsLessonSettingDetailPayload: (state, { payload }) => {
            state.lmsLessonSettingDetail.payload = payload
        },
        resetLmsLessonSettingDetailPayload: (state) => {
            state.lmsLessonSettingDetail.payload = LMS_LESSON_INITIAL_STATE.lmsLessonSettingDetail.payload
        },

        // add
        setAddLmsLessonSettingDetailLoading: (state, { payload }) => {
            state.addLmsLessonSettingDetail.isLoading = payload
        },
        setAddLmsLessonSettingDetailPayload: (state, { payload }) => {
            state.addLmsLessonSettingDetail.payload = payload
        },
        setAddLmsLessonSettingDetailData: (state, { payload }) => {
            state.addLmsLessonSettingDetail.data = payload
            state.addLmsLessonSettingDetail.message = LMS_LESSON_INITIAL_STATE.addLmsLessonSettingDetail.message
        },
        setAddLmsLessonSettingDetailMessage: (state, { payload }) => {
            state.addLmsLessonSettingDetail.message = payload
            state.addLmsLessonSettingDetail.data = LMS_LESSON_INITIAL_STATE.addLmsLessonSettingDetail.data
        },
        resetAddLmsLessonSettingDetail: (state) => {
            state.addLmsLessonSettingDetail = LMS_LESSON_INITIAL_STATE.addLmsLessonSettingDetail
        },

        // modify
        setModifyLmsLessonSettingDetailLoading: (state, { payload }) => {
            state.modifyLmsLessonSettingDetail.isLoading = payload
        },
        setModifyLmsLessonSettingDetailPayload: (state, { payload }) => {
            state.modifyLmsLessonSettingDetail.payload = payload
        },
        setModifyLmsLessonSettingDetailData: (state, { payload }) => {
            state.modifyLmsLessonSettingDetail.data = payload
            state.modifyLmsLessonSettingDetail.message = LMS_LESSON_INITIAL_STATE.modifyLmsLessonSettingDetail.message
        },
        setModifyLmsLessonSettingDetailMessage: (state, { payload }) => {
            state.modifyLmsLessonSettingDetail.message = payload
            state.modifyLmsLessonSettingDetail.data = LMS_LESSON_INITIAL_STATE.modifyLmsLessonSettingDetail.data
        },
        resetModifyLmsLessonSettingDetail: (state) => {
            state.modifyLmsLessonSettingDetail = LMS_LESSON_INITIAL_STATE.modifyLmsLessonSettingDetail
        },

        // destroy
        setDestroyLmsLessonSettingDetailLoading: (state, { payload }) => {
            state.destroyLmsLessonSettingDetail.isLoading = payload
        },
        setDestroyLmsLessonSettingDetailMessage: (state, { payload }) => {
            state.destroyLmsLessonSettingDetail.message = payload
        },
        resetDestroyLmsLessonSettingDetail: (state) => {
            state.destroyLmsLessonSettingDetail = LMS_LESSON_INITIAL_STATE.destroyLmsLessonSettingDetail
        },

        /* lms lesson lecture */
        // list
        setLmsLessonLectureListLoading: (state, { payload }) => {
            state.lmsLessonLectureList.isLoading = payload
        },
        setLmsLessonLectureListData: (state, { payload }) => {
            state.lmsLessonLectureList.data = payload
            state.lmsLessonLectureList.message = LMS_LESSON_INITIAL_STATE.lmsLessonLectureList.message
        },
        setLmsLessonLectureListMessage: (state, { payload }) => {
            state.lmsLessonLectureList.message = payload
            state.lmsLessonLectureList.data = LMS_LESSON_INITIAL_STATE.lmsLessonLectureList.data
        },
        resetLmsLessonLectureList: (state) => {
            state.lmsLessonLectureList = LMS_LESSON_INITIAL_STATE.lmsLessonLectureList
        },

        // detail
        setLmsLessonLectureDetailLoading: (state, { payload }) => {
            state.lmsLessonLectureDetail.isLoading = payload
        },
        setLmsLessonLectureDetailData: (state, { payload }) => {
            state.lmsLessonLectureDetail.data = payload
            state.lmsLessonLectureDetail.message = LMS_LESSON_INITIAL_STATE.lmsLessonLectureDetail.message
        },
        setLmsLessonLectureDetailMessage: (state, { payload }) => {
            state.lmsLessonLectureDetail.message = payload
            state.lmsLessonLectureDetail.data = LMS_LESSON_INITIAL_STATE.lmsLessonLectureDetail.data
        },
        resetLmsLessonLectureDetail: (state) => {
            state.lmsLessonLectureDetail = LMS_LESSON_INITIAL_STATE.lmsLessonLectureDetail
        },

        // add
        setAddLmsLessonLectureDetailLoading: (state, { payload }) => {
            state.addLmsLessonLectureDetail.isLoading = payload
        },
        setAddLmsLessonLectureDetailPayload: (state, { payload }) => {
            state.addLmsLessonLectureDetail.payload = payload
        },
        setAddLmsLessonLectureDetailData: (state, { payload }) => {
            state.addLmsLessonLectureDetail.data = payload
            state.addLmsLessonLectureDetail.message = LMS_LESSON_INITIAL_STATE.addLmsLessonLectureDetail.message
        },
        setAddLmsLessonLectureDetailMessage: (state, { payload }) => {
            state.addLmsLessonLectureDetail.message = payload
            state.addLmsLessonLectureDetail.data = LMS_LESSON_INITIAL_STATE.addLmsLessonLectureDetail.data
        },
        resetAddLmsLessonLectureDetail: (state) => {
            state.addLmsLessonLectureDetail = LMS_LESSON_INITIAL_STATE.addLmsLessonLectureDetail
        },

        // modify
        setModifyLmsLessonLectureDetailLoading: (state, { payload }) => {
            state.modifyLmsLessonLectureDetail.isLoading = payload
        },
        setModifyLmsLessonLectureDetailPayload: (state, { payload }) => {
            state.modifyLmsLessonLectureDetail.payload = payload
        },
        setModifyLmsLessonLectureDetailData: (state, { payload }) => {
            state.modifyLmsLessonLectureDetail.data = payload
            state.modifyLmsLessonLectureDetail.message = LMS_LESSON_INITIAL_STATE.modifyLmsLessonLectureDetail.message
        },
        setModifyLmsLessonLectureDetailMessage: (state, { payload }) => {
            state.modifyLmsLessonLectureDetail.message = payload
            state.modifyLmsLessonLectureDetail.data = LMS_LESSON_INITIAL_STATE.modifyLmsLessonLectureDetail.data
        },
        resetModifyLmsLessonLectureDetail: (state) => {
            state.modifyLmsLessonLectureDetail = LMS_LESSON_INITIAL_STATE.modifyLmsLessonLectureDetail
        },

        // destroy
        setDestroyLmsLessonLectureDetailLoading: (state, { payload }) => {
            state.destroyLmsLessonLectureDetail.isLoading = payload
        },
        setDestroyLmsLessonLectureDetailMessage: (state, { payload }) => {
            state.destroyLmsLessonLectureDetail.message = payload
        },
        resetDestroyLmsLessonLectureDetail: (state) => {
            state.destroyLmsLessonLectureDetail = LMS_LESSON_INITIAL_STATE.destroyLmsLessonLectureDetail
        },

        /* lms lesson article */
        // list
        setLmsLessonArticleListLoading: (state, { payload }) => {
            state.lmsLessonArticleList.isLoading = payload
        },
        setLmsLessonArticleListData: (state, { payload }) => {
            state.lmsLessonArticleList.data = payload
            state.lmsLessonArticleList.message = LMS_LESSON_INITIAL_STATE.lmsLessonArticleList.message
        },
        setLmsLessonArticleListMessage: (state, { payload }) => {
            state.lmsLessonArticleList.message = payload
            state.lmsLessonArticleList.data = LMS_LESSON_INITIAL_STATE.lmsLessonArticleList.data
        },
        resetLmsLessonArticleList: (state) => {
            state.lmsLessonArticleList = LMS_LESSON_INITIAL_STATE.lmsLessonArticleList
        },

        // detail
        setLmsLessonArticleDetailLoading: (state, { payload }) => {
            state.lmsLessonArticleDetail.isLoading = payload
        },
        setLmsLessonArticleDetailData: (state, { payload }) => {
            state.lmsLessonArticleDetail.data = payload
            state.lmsLessonArticleDetail.message = LMS_LESSON_INITIAL_STATE.lmsLessonArticleDetail.message
        },
        setLmsLessonArticleDetailMessage: (state, { payload }) => {
            state.lmsLessonArticleDetail.message = payload
            state.lmsLessonArticleDetail.data = LMS_LESSON_INITIAL_STATE.lmsLessonArticleDetail.data
        },
        resetLmsLessonArticleDetail: (state) => {
            state.lmsLessonArticleDetail = LMS_LESSON_INITIAL_STATE.lmsLessonArticleDetail
        },

        // add
        setAddLmsLessonArticleDetailLoading: (state, { payload }) => {
            state.addLmsLessonArticleDetail.isLoading = payload
        },
        setAddLmsLessonArticleDetailPayload: (state, { payload }) => {
            state.addLmsLessonArticleDetail.payload = payload
        },
        setAddLmsLessonArticleDetailData: (state, { payload }) => {
            state.addLmsLessonArticleDetail.data = payload
            state.addLmsLessonArticleDetail.message = LMS_LESSON_INITIAL_STATE.addLmsLessonArticleDetail.message
        },
        setAddLmsLessonArticleDetailMessage: (state, { payload }) => {
            state.addLmsLessonArticleDetail.message = payload
            state.addLmsLessonArticleDetail.data = LMS_LESSON_INITIAL_STATE.addLmsLessonArticleDetail.data
        },
        resetAddLmsLessonArticleDetail: (state) => {
            state.addLmsLessonArticleDetail = LMS_LESSON_INITIAL_STATE.addLmsLessonArticleDetail
        },

        // modify
        setModifyLmsLessonArticleDetailLoading: (state, { payload }) => {
            state.modifyLmsLessonArticleDetail.isLoading = payload
        },
        setModifyLmsLessonArticleDetailPayload: (state, { payload }) => {
            state.modifyLmsLessonArticleDetail.payload = payload
        },
        setModifyLmsLessonArticleDetailData: (state, { payload }) => {
            state.modifyLmsLessonArticleDetail.data = payload
            state.modifyLmsLessonArticleDetail.message = LMS_LESSON_INITIAL_STATE.modifyLmsLessonArticleDetail.message
        },
        setModifyLmsLessonArticleDetailMessage: (state, { payload }) => {
            state.modifyLmsLessonArticleDetail.message = payload
            state.modifyLmsLessonArticleDetail.data = LMS_LESSON_INITIAL_STATE.modifyLmsLessonArticleDetail.data
        },
        resetModifyLmsLessonArticleDetail: (state) => {
            state.modifyLmsLessonArticleDetail = LMS_LESSON_INITIAL_STATE.modifyLmsLessonArticleDetail
        },

        // destroy
        setDestroyLmsLessonArticleDetailLoading: (state, { payload }) => {
            state.destroyLmsLessonArticleDetail.isLoading = payload
        },
        setDestroyLmsLessonArticleDetailMessage: (state, { payload }) => {
            state.destroyLmsLessonArticleDetail.message = payload
        },
        resetDestroyLmsLessonArticleDetail: (state) => {
            state.destroyLmsLessonArticleDetail = LMS_LESSON_INITIAL_STATE.destroyLmsLessonArticleDetail
        },

        /* lms lesson quiz */
        // list
        setLmsLessonQuizListLoading: (state, { payload }) => {
            state.lmsLessonQuizList.isLoading = payload
        },
        setLmsLessonQuizListData: (state, { payload }) => {
            state.lmsLessonQuizList.data = payload
            state.lmsLessonQuizList.message = LMS_LESSON_INITIAL_STATE.lmsLessonQuizList.message
        },
        setLmsLessonQuizListMessage: (state, { payload }) => {
            state.lmsLessonQuizList.message = payload
            state.lmsLessonQuizList.data = LMS_LESSON_INITIAL_STATE.lmsLessonQuizList.data
        },
        resetLmsLessonQuizList: (state) => {
            state.lmsLessonQuizList = LMS_LESSON_INITIAL_STATE.lmsLessonQuizList
        },

        // detail
        setLmsLessonQuizDetailLoading: (state, { payload }) => {
            state.lmsLessonQuizDetail.isLoading = payload
        },
        setLmsLessonQuizDetailData: (state, { payload }) => {
            state.lmsLessonQuizDetail.data = payload
            state.lmsLessonQuizDetail.message = LMS_LESSON_INITIAL_STATE.lmsLessonQuizDetail.message
        },
        setLmsLessonQuizDetailMessage: (state, { payload }) => {
            state.lmsLessonQuizDetail.message = payload
            state.lmsLessonQuizDetail.data = LMS_LESSON_INITIAL_STATE.lmsLessonQuizDetail.data
        },
        resetLmsLessonQuizDetail: (state) => {
            state.lmsLessonQuizDetail = LMS_LESSON_INITIAL_STATE.lmsLessonQuizDetail
        },

        // add
        setAddLmsLessonQuizDetailLoading: (state, { payload }) => {
            state.addLmsLessonQuizDetail.isLoading = payload
        },
        setAddLmsLessonQuizDetailPayload: (state, { payload }) => {
            state.addLmsLessonQuizDetail.payload = payload
        },
        setAddLmsLessonQuizDetailData: (state, { payload }) => {
            state.addLmsLessonQuizDetail.data = payload
            state.addLmsLessonQuizDetail.message = LMS_LESSON_INITIAL_STATE.addLmsLessonQuizDetail.message
        },
        setAddLmsLessonQuizDetailMessage: (state, { payload }) => {
            state.addLmsLessonQuizDetail.message = payload
            state.addLmsLessonQuizDetail.data = LMS_LESSON_INITIAL_STATE.addLmsLessonQuizDetail.data
        },
        resetAddLmsLessonQuizDetail: (state) => {
            state.addLmsLessonQuizDetail = LMS_LESSON_INITIAL_STATE.addLmsLessonQuizDetail
        },

        // modify
        setModifyLmsLessonQuizDetailLoading: (state, { payload }) => {
            state.modifyLmsLessonQuizDetail.isLoading = payload
        },
        setModifyLmsLessonQuizDetailPayload: (state, { payload }) => {
            state.modifyLmsLessonQuizDetail.payload = payload
        },
        setModifyLmsLessonQuizDetailData: (state, { payload }) => {
            state.modifyLmsLessonQuizDetail.data = payload
            state.modifyLmsLessonQuizDetail.message = LMS_LESSON_INITIAL_STATE.modifyLmsLessonQuizDetail.message
        },
        setModifyLmsLessonQuizDetailMessage: (state, { payload }) => {
            state.modifyLmsLessonQuizDetail.message = payload
            state.modifyLmsLessonQuizDetail.data = LMS_LESSON_INITIAL_STATE.modifyLmsLessonQuizDetail.data
        },
        resetModifyLmsLessonQuizDetail: (state) => {
            state.modifyLmsLessonQuizDetail = LMS_LESSON_INITIAL_STATE.modifyLmsLessonQuizDetail
        },

        // destroy
        setDestroyLmsLessonQuizDetailLoading: (state, { payload }) => {
            state.destroyLmsLessonQuizDetail.isLoading = payload
        },
        setDestroyLmsLessonQuizDetailMessage: (state, { payload }) => {
            state.destroyLmsLessonQuizDetail.message = payload
        },
        resetDestroyLmsLessonQuizDetail: (state) => {
            state.destroyLmsLessonQuizDetail = LMS_LESSON_INITIAL_STATE.destroyLmsLessonQuizDetail
        },

        /* LESSON-TOPIC */
        // CREATE 
        setAddLmsLessonTopicDetailLoading: (state, { payload }) => {
            state.addLmsLessonTopicDetail.isLoading = payload
        },
        setAddLmsLessonTopicDetailData: (state, { payload }) => {
            state.addLmsLessonTopicDetail.data = payload
            state.addLmsLessonTopicDetail.message = LMS_LESSON_INITIAL_STATE.addLmsLessonTopicDetail.message
        },
        setAddLmsLessonTopicDetailMessage: (state, { payload }) => {
            state.addLmsLessonTopicDetail.message = payload
            state.addLmsLessonTopicDetail.data = LMS_LESSON_INITIAL_STATE.addLmsLessonTopicDetail.data
        },
        resetAddLmsLessonTopicDetail: (state) => {
            state.addLmsLessonTopicDetail = LMS_LESSON_INITIAL_STATE.addLmsLessonTopicDetail
        },

        // LIST
        setLmsLessonTopicListLoading: (state, { payload }) => {
            state.lmsLessonTopicList.isLoading = payload
        },
        setLmsLessonTopicListData: (state, { payload }) => {
            state.lmsLessonTopicList.data = payload
            state.lmsLessonTopicList.message = LMS_LESSON_INITIAL_STATE.lmsLessonTopicList.message
        },
        setLmsLessonTopicListMessage: (state, { payload }) => {
            state.lmsLessonTopicList.message = payload
            state.lmsLessonTopicList.data = LMS_LESSON_INITIAL_STATE.lmsLessonTopicList.data
        },
        resetLmsLessonTopicList: (state, { payload }) => {
            state.lmsLessonTopicList = LMS_LESSON_INITIAL_STATE.lmsLessonTopicList
        },

        // DETAIL
        setLmsLessonTopicDetailLoading: (state, { payload }) => {
            state.lmsLessonTopicDetail.isLoading = payload
        },
        setLmsLessonTopicDetailData: (state, { payload }) => {
            state.lmsLessonTopicDetail.data = payload
            state.lmsLessonTopicDetail.message = LMS_LESSON_INITIAL_STATE.lmsLessonTopicDetail.message
        },
        setLmsLessonTopicDetailMessage: (state, { payload }) => {
            state.lmsLessonTopicDetail.message = payload
            state.lmsLessonTopicDetail.data = LMS_LESSON_INITIAL_STATE.lmsLessonTopicDetail.data
        },
        resetLmsLessonTopicDetail: (state) => {
            state.lmsLessonTopicDetail = LMS_LESSON_INITIAL_STATE.lmsLessonTopicDetail
        },

        // UPDATE
        setUpdateLmsLessonTopicDetailLoading: (state, { payload }) => {
            state.updateLmsLessonTopicDetail.isLoading = payload
        },
        setUpdateLmsLessonTopicDetailData: (state, { payload }) => {
            state.updateLmsLessonTopicDetail.data = payload
            state.updateLmsLessonTopicDetail.message = LMS_LESSON_INITIAL_STATE.updateLmsLessonTopicDetail.message
        },
        setUpdateLmsLessonTopicDetailMessage: (state, { payload }) => {
            state.updateLmsLessonTopicDetail.message = payload
            state.updateLmsLessonTopicDetail.data = LMS_LESSON_INITIAL_STATE.updateLmsLessonTopicDetail.data
        },
        resetUpdateLmsLessonTopicDetail: (state) => {
            state.updateLmsLessonTopicDetail = LMS_LESSON_INITIAL_STATE.updateLmsLessonTopicDetail
        },

        // DELETE
        setDeleteLmsLessonTopicDetailLoading: (state, { payload }) => {
            state.deleteLmsLessonTopicDetail.isLoading = payload
        },
        setDeleteLmsLessonTopicDetailData: (state, { payload }) => {
            state.deleteLmsLessonTopicDetail.data = payload
            state.deleteLmsLessonTopicDetail.message = LMS_LESSON_INITIAL_STATE.deleteLmsLessonTopicDetail.message
        },
        setDeleteLmsLessonTopicDetailMessage: (state, { payload }) => {
            state.deleteLmsLessonTopicDetail.message = payload
            state.deleteLmsLessonTopicDetail.data = LMS_LESSON_INITIAL_STATE.deleteLmsLessonTopicDetail.data
        },
        resetDeleteLmsLessonTopicDetail: (state) => {
            state.deleteLmsLessonTopicDetail = LMS_LESSON_INITIAL_STATE.deleteLmsLessonTopicDetail
        },

        // add lms lesson instructor detail
        setAddLmsLessonInstructorDetailLoading: (state, { payload }) => {
            state.addLmsLessonInstructorDetail.isLoading = payload
        },
        setAddLmsLessonInstructorDetailData: (state, { payload }) => {
            state.addLmsLessonInstructorDetail.data = payload
            state.addLmsLessonInstructorDetail.message = LMS_LESSON_INITIAL_STATE.addLmsLessonInstructorDetail.message
        },
        setAddLmsLessonInstructorDetailMessage: (state, { payload }) => {
            state.addLmsLessonInstructorDetail.message = payload
            state.addLmsLessonInstructorDetail.data = LMS_LESSON_INITIAL_STATE.addLmsLessonInstructorDetail.data
        },
        resetAddLmsLessonInstructorDetail: (state) => {
            state.addLmsLessonInstructorDetail = LMS_LESSON_INITIAL_STATE.addLmsLessonInstructorDetail
        },

        // add lms lesson instructor detail payload
        setAddLmsLessonInstructorDetailPayload: (state, { payload }) => {
            state.addLmsLessonInstructorDetail.payload = payload
        },
        resetAddLmsLessonInstructorDetailPayload: (state) => {
            state.addLmsLessonInstructorDetail.payload = LMS_LESSON_INITIAL_STATE.addLmsLessonInstructorDetail.payload
        },

        setModifyLmsLessonInstructorDetailLoading: (state, { payload }) => {
            state.modifyLmsLessonInstructorDetail.isLoading = payload
        },
        setModifyLmsLessonInstructorDetailData: (state, { payload }) => {
            state.modifyLmsLessonInstructorDetail.data = payload
            state.modifyLmsLessonInstructorDetail.message = LMS_LESSON_INITIAL_STATE.modifyLmsLessonInstructorDetail.message
        },
        setModifyLmsLessonInstructorDetailMessage: (state, { payload }) => {
            state.modifyLmsLessonInstructorDetail.message = payload
            state.modifyLmsLessonInstructorDetail.data = LMS_LESSON_INITIAL_STATE.modifyLmsLessonInstructorDetail.data
        },
        resetModifyLmsLessonInstructorDetail: (state) => {
            state.modifyLmsLessonInstructorDetail = LMS_LESSON_INITIAL_STATE.modifyLmsLessonInstructorDetail
        },

        setDestroyLmsLessonInstructorDetailLoading: (state, { payload }) => {
            state.destroyLmsLessonInstructorDetail.isLoading = payload
        },
        setDestroyLmsLessonInstructorDetailMessage: (state, { payload }) => {
            state.destroyLmsLessonInstructorDetail.message = payload
        },
        resetDestroyLmsLessonInstructorDetail: (state) => {
            state.destroyLmsLessonInstructorDetail = LMS_LESSON_INITIAL_STATE.destroyLmsLessonInstructorDetail
        },
    }
})

export const {
    /* student lms course */
    setStudentLmsLessonListLoading,
    setStudentLmsLessonListData,
    setStudentLmsLessonListMessage,
    resetStudentLmsLessonList,

    setStudentLmsLessonDetailLoading,
    setStudentLmsLessonDetailData,
    setStudentLmsLessonDetailMessage,
    resetStudentLmsLessonDetail,

    /* tutor lms course */
    setTutorLmsLessonListLoading,
    setTutorLmsLessonListData,
    setTutorLmsLessonListMessage,
    resetTutorLmsLessonList,

    setTutorLmsLessonDetailLoading,
    setTutorLmsLessonDetailData,
    setTutorLmsLessonDetailMessage,
    resetTutorLmsLessonDetail,

    /* lms course */
    setLmsLessonListLoading,
    setLmsLessonListData,
    setLmsLessonListMessage,
    resetLmsLessonList,

    setLmsLessonDetailLoading,
    setLmsLessonDetailData,
    setLmsLessonDetailMessage,
    resetLmsLessonDetail,

    setAddLmsLessonDetailLoading,
    setAddLmsLessonDetailPayload,
    setAddLmsLessonDetailData,
    setAddLmsLessonDetailMessage,
    resetAddLmsLessonDetail,

    setModifyLmsLessonDetailLoading,
    setModifyLmsLessonDetailPayload,
    setModifyLmsLessonDetailData,
    setModifyLmsLessonDetailMessage,
    resetModifyLmsLessonDetail,

    setDestroyLmsLessonDetailLoading,
    setDestroyLmsLessonDetailMessage,
    resetDestroyLmsLessonDetail,

    /* lms course user */
    setLmsLessonUserListLoading,
    setLmsLessonUserListData,
    setLmsLessonUserListMessage,
    resetLmsLessonUserList,

    setLmsLessonUserDetailLoading,
    setLmsLessonUserDetailData,
    setLmsLessonUserDetailMessage,
    resetLmsLessonUserDetail,

    setAddLmsLessonUserDetailLoading,
    setAddLmsLessonUserDetailPayload,
    setAddLmsLessonUserDetailData,
    setAddLmsLessonUserDetailMessage,
    resetAddLmsLessonUserDetail,

    setModifyLmsLessonUserDetailLoading,
    setModifyLmsLessonUserDetailPayload,
    setModifyLmsLessonUserDetailData,
    setModifyLmsLessonUserDetailMessage,
    resetModifyLmsLessonUserDetail,

    setDestroyLmsLessonUserDetailLoading,
    setDestroyLmsLessonUserDetailMessage,
    resetDestroyLmsLessonUserDetail,

    /* lms course slug */
    setLmsLessonSlugListLoading,
    setLmsLessonSlugListData,
    setLmsLessonSlugListMessage,
    resetLmsLessonSlugList,

    setLmsLessonSlugDetailLoading,
    setLmsLessonSlugDetailData,
    setLmsLessonSlugDetailMessage,
    resetLmsLessonSlugDetail,

    setAddLmsLessonSlugDetailLoading,
    setAddLmsLessonSlugDetailPayload,
    setAddLmsLessonSlugDetailData,
    setAddLmsLessonSlugDetailMessage,
    resetAddLmsLessonSlugDetail,

    setModifyLmsLessonSlugDetailLoading,
    setModifyLmsLessonSlugDetailPayload,
    setModifyLmsLessonSlugDetailData,
    setModifyLmsLessonSlugDetailMessage,
    resetModifyLmsLessonSlugDetail,

    setDestroyLmsLessonSlugDetailLoading,
    setDestroyLmsLessonSlugDetailMessage,
    resetDestroyLmsLessonSlugDetail,

    /* lms course setting */
    setLmsLessonSettingListLoading,
    setLmsLessonSettingListData,
    setLmsLessonSettingListMessage,
    resetLmsLessonSettingList,

    setLmsLessonSettingDetailLoading,
    setLmsLessonSettingDetailData,
    setLmsLessonSettingDetailMessage,
    resetLmsLessonSettingDetail,
    setLmsLessonSettingDetailPayload,
    resetLmsLessonSettingDetailPayload,

    setAddLmsLessonSettingDetailLoading,
    setAddLmsLessonSettingDetailPayload,
    setAddLmsLessonSettingDetailData,
    setAddLmsLessonSettingDetailMessage,
    resetAddLmsLessonSettingDetail,

    setModifyLmsLessonSettingDetailLoading,
    setModifyLmsLessonSettingDetailPayload,
    setModifyLmsLessonSettingDetailData,
    setModifyLmsLessonSettingDetailMessage,
    resetModifyLmsLessonSettingDetail,

    setDestroyLmsLessonSettingDetailLoading,
    setDestroyLmsLessonSettingDetailMessage,
    resetDestroyLmsLessonSettingDetail,

    /* lms course lecture */
    setLmsLessonLectureListLoading,
    setLmsLessonLectureListData,
    setLmsLessonLectureListMessage,
    resetLmsLessonLectureList,

    setLmsLessonLectureDetailLoading,
    setLmsLessonLectureDetailData,
    setLmsLessonLectureDetailMessage,
    resetLmsLessonLectureDetail,

    setAddLmsLessonLectureDetailLoading,
    setAddLmsLessonLectureDetailPayload,
    setAddLmsLessonLectureDetailData,
    setAddLmsLessonLectureDetailMessage,
    resetAddLmsLessonLectureDetail,

    setModifyLmsLessonLectureDetailLoading,
    setModifyLmsLessonLectureDetailPayload,
    setModifyLmsLessonLectureDetailData,
    setModifyLmsLessonLectureDetailMessage,
    resetModifyLmsLessonLectureDetail,

    setDestroyLmsLessonLectureDetailLoading,
    setDestroyLmsLessonLectureDetailMessage,
    resetDestroyLmsLessonLectureDetail,

    /* lms course article */
    setLmsLessonArticleListLoading,
    setLmsLessonArticleListData,
    setLmsLessonArticleListMessage,
    resetLmsLessonArticleList,

    setLmsLessonArticleDetailLoading,
    setLmsLessonArticleDetailData,
    setLmsLessonArticleDetailMessage,
    resetLmsLessonArticleDetail,

    setAddLmsLessonArticleDetailLoading,
    setAddLmsLessonArticleDetailPayload,
    setAddLmsLessonArticleDetailData,
    setAddLmsLessonArticleDetailMessage,
    resetAddLmsLessonArticleDetail,

    setModifyLmsLessonArticleDetailLoading,
    setModifyLmsLessonArticleDetailPayload,
    setModifyLmsLessonArticleDetailData,
    setModifyLmsLessonArticleDetailMessage,
    resetModifyLmsLessonArticleDetail,

    setDestroyLmsLessonArticleDetailLoading,
    setDestroyLmsLessonArticleDetailMessage,
    resetDestroyLmsLessonArticleDetail,

    /* lms course quiz */
    setLmsLessonQuizListLoading,
    setLmsLessonQuizListData,
    setLmsLessonQuizListMessage,
    resetLmsLessonQuizList,

    setLmsLessonQuizDetailLoading,
    setLmsLessonQuizDetailData,
    setLmsLessonQuizDetailMessage,
    resetLmsLessonQuizDetail,

    setAddLmsLessonQuizDetailLoading,
    setAddLmsLessonQuizDetailData,
    setAddLmsLessonQuizDetailMessage,
    resetAddLmsLessonQuizDetail,

    setModifyLmsLessonQuizDetailLoading,
    setModifyLmsLessonQuizDetailData,
    setModifyLmsLessonQuizDetailMessage,
    resetModifyLmsLessonQuizDetail,

    setDestroyLmsLessonQuizDetailLoading,
    setDestroyLmsLessonQuizDetailMessage,
    resetDestroyLmsLessonQuizDetail,

    setAddLmsLessonInstructorDetailLoading,
    setAddLmsLessonInstructorDetailData,
    setAddLmsLessonInstructorDetailMessage,
    resetAddLmsLessonInstructorDetail,
    setAddLmsLessonInstructorDetailPayload,
    resetAddLmsLessonInstructorDetailPayload,

    setModifyLmsLessonInstructorDetailLoading,
    setModifyLmsLessonInstructorDetailData,
    setModifyLmsLessonInstructorDetailMessage,
    resetModifyLmsLessonInstructorDetail,

    setDestroyLmsLessonInstructorDetailLoading,
    setDestroyLmsLessonInstructorDetailMessage,
    resetDestroyLmsLessonInstructorDetail
} = lmsLesson.actions

export default lmsLesson.reducer