import { useState, useEffect } from 'react';
import { cn } from "utils/cn.utils";

import ButtonLoader from 'components/loader/ButtonLoader';
import ComponentLoader from 'components/loader/ComponentLoader';
import SearchSelector from 'components/searchSelector/SearchSelector';
import InfinitePagination from 'components/pagination/InfinitePagination';

import { getMyStudentUserListByUserId } from 'redux/student/student.request';
import { getMyTutorUserListByUserId } from 'redux/tutor/tutor.request';
import { createShareDriveFileDetail } from 'redux/drive/drive.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearMyStudentList } from 'redux/student/student.slice';
import { setClearMyTutorList } from 'redux/tutor/tutor.slice';
import { resetShareDriveFileDetail } from 'redux/drive/drive.slice';
import { userRoles } from 'redux/auth/auth.const';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

const ShareDriveFileModal = () => {
    const { locals, modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { myStudentList } = useAppState((s) => s.student)
    const { myTutorList } = useAppState((state) => state.tutor)
    const { shareDriveFileDetail } = useAppState((state) => state.drive)

    const dispatcher = useAppDispatcher()

    const [selectedUsers, setSelectedUsers] = useState([])
    const [pageCount, setPageCount] = useState(1)

    const loadingElement = myStudentList?.isLoading || myTutorList?.isLoading
    const usersList = (locals?.userRole === userRoles?.TUTOR?.value)
        ? myStudentList?.myStudentList
        : myTutorList?.myTutorList

    useEffect(() => {
        return () => {
            dispatcher(setClearMyStudentList())
            dispatcher(setClearMyTutorList())
            dispatcher(resetShareDriveFileDetail())
        }
    }, [])

    useEffect(() => {
        if (shareDriveFileDetail?.data) {
            dispatcher(resetShareDriveFileDetail())
            dispatcher(setModal({
                ...modal,
                [modalConst.SHARE_DRIVE_FILE_MODAL.stateKey]: false
            }))
        }
    }, [shareDriveFileDetail?.data])

    useEffect(() => {
        if (locals?.userRole === userRoles?.TUTOR?.value) {
            dispatcher(getMyStudentUserListByUserId(user?.user?.userId, { page: pageCount, records: 10 }))
        }
        if (locals?.userRole === userRoles?.STUDENT?.value) {
            dispatcher(getMyTutorUserListByUserId(user?.user?.userId, { page: pageCount, records: 10 }))
        }
    }, [locals?.userRole])

    const onHandleSelectUserFromDropdown = (option) => {
        if (loadingElement || shareDriveFileDetail?.isLoading) return;

        const tempSelectedUser = selectedUsers?.map((tempUser) => tempUser?.userId)
        // const dropdownUsersUserIds = option?.map((op) => op?.value)
        const dropdownSelectedUsers = option?.map((op) => op?.value)?.filter((userId) => !tempSelectedUser?.includes(userId))

        // const existingSelectedUsers = dropdownUsersUserIds.filter(userId =>
        //     tempSelectedUser.includes(userId)
        // );

        // if (existingSelectedUsers.length > 0) {
        //     alert("User already selected");
        //     return;
        // }

        const newUserObjectDetail = usersList?.results?.filter((userOp) => dropdownSelectedUsers?.includes(userOp?.userId))
        if (newUserObjectDetail?.length > 0) {
            setSelectedUsers([...selectedUsers, ...newUserObjectDetail]);
        }
    }

    const onHandleRemoveUsersFromDropdown = (option) => {
        if (loadingElement || shareDriveFileDetail?.isLoading) return;

        const filteredSelectedUserList = selectedUsers?.filter((userInfo) => userInfo?.userId !== option?.value)

        setSelectedUsers(filteredSelectedUserList)
    }

    const onHandleRemoveAllOptions = () => {
        setSelectedUsers([])
    }

    const onHandleSelectUser = (userItem) => {
        if (loadingElement || shareDriveFileDetail?.isLoading) return;

        const filterDuplicateUsers = selectedUsers?.filter((userProf) => userProf?.userId === userItem?.userId)
        const filterSelectedUser = selectedUsers?.filter((userProf) => userProf?.userId !== userItem?.userId)
        if (filterDuplicateUsers?.length > 0) {
            setSelectedUsers(filterSelectedUser)
        } else {
            setSelectedUsers([...selectedUsers, userItem])
        }
    }

    const fetchMoreData = () => {
        if (usersList?.page === usersList?.totalPages) return;

        setPageCount(pageCount + 1)
        if (locals?.userRole === userRoles?.TUTOR?.value) {
            dispatcher(getMyStudentUserListByUserId(user?.user?.userId, { page: pageCount + 1, records: 10 }))
        }
        if (locals?.userRole === userRoles?.STUDENT?.value) {
            dispatcher(getMyTutorUserListByUserId(user?.user?.userId, { page: pageCount + 1, records: 10 }))
        }
    }

    const onHandleShareFile = () => {
        if (loadingElement || shareDriveFileDetail?.isLoading) return;

        const body = selectedUsers?.map((myUser) => myUser?.userId)

        dispatcher(createShareDriveFileDetail(shareDriveFileDetail?.payload?.fileId, { shared_with_users: body }))

    }

    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"w-full min-h-[28rem] block space-y-5"}>
                <div className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide capitalize"}>
                    {`Share ${shareDriveFileDetail?.payload?.fileName && shareDriveFileDetail?.payload?.fileName?.split(".")[0]}`}
                </div>
                <div className={"w-full"}>
                    <SearchSelector
                        isLoading={loadingElement}
                        isShowDropdownLabel={false}
                        placeholder={"Select User(s)"}
                        options={usersList?.results?.map((userItems) => ({
                            label: `${userItems?.firstName} ${userItems?.lastName?.charAt(0)?.toUpperCase()}.`,
                            value: userItems?.userId
                        })) || []}
                        multiple={true}
                        size={"medium"}
                        showGroupedOptions={true}
                        selectedValue={selectedUsers?.map((selectUser) => ({
                            label: `${selectUser?.firstName} ${selectUser?.lastName?.charAt(0)?.toUpperCase()}.`,
                            value: selectUser?.userId
                        }))}
                        onHandleSelect={(option) => onHandleSelectUserFromDropdown(option)}
                        onHandleRemove={(option) => onHandleRemoveUsersFromDropdown(option)}
                        onHandleRemoveAllOptions={onHandleRemoveAllOptions}
                    />
                </div>

                <div className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                    {"People with you can share"}
                </div>
                {(usersList && usersList?.results?.length > 0) &&
                    <div id={"scrollableDiv"} className={cn(
                        "flex flex-col w-full max-h-96 overflow-hidden overflow-y-scroll transition ease-in-out delay-150 duration-300",
                        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight",
                        "border-b-2 border-divider-lightDark pb-2",
                        usersList?.results?.length > 5 && "hover:pr-2"
                    )}>
                        <InfinitePagination
                            scrollableTarget={"scrollableDiv"}
                            dataLength={usersList?.totalRecords}
                            fetchData={fetchMoreData}
                            hasMore={(usersList?.page != usersList?.totalPages)}
                            loader={<ComponentLoader isLoading={loadingElement} />}
                            isShowEndMessage={true}
                        >
                            {usersList?.results?.map((userItem, index) => (
                                <div
                                    key={index}
                                    className={cn(
                                        "w-full px-4 py-2 my-1.5 flex items-center justify-between gap-3 hover:bg-divider-medium rounded-md cursor-pointer",
                                        (selectedUsers && selectedUsers?.length > 0) && selectedUsers?.filter((myUser) => myUser?.userId === userItem?.userId)?.length > 0 && "bg-divider-medium"
                                    )}
                                    onClick={() => onHandleSelectUser(userItem)}
                                >
                                    <div className={"w-full flex items-center justify-start gap-3"}>
                                        <div className={"w-fit"}>
                                            <input
                                                type={"checkbox"}
                                                className={"w-4 h-4 rounded-xl border border-divider-lightDark focus:border-divider-dark hover:border-divider-dark cursor-pointer focus:outline-none"}
                                                checked={(selectedUsers && selectedUsers?.length > 0) && selectedUsers?.filter((myUser) => myUser?.userId === userItem?.userId)?.length > 0 && true}
                                                onChange={(event) => onHandleSelectUser(userItem)}
                                            />
                                        </div>
                                        <div className={"relative"}>
                                            <div className={"w-16 h-16 rounded-full overflow-hidden"}>
                                                <img
                                                    src={userItem?.profilePicUrl}
                                                    className={"w-full h-full object-cover"}
                                                    alt={`${userItem?.firstName} ${userItem?.lastName?.charAt(0)?.toUpperCase()}.`}
                                                />
                                            </div>
                                            <div className={"relative has-tooltip"}>
                                                <div className={'absolute bottom-0 right-0 w-4 h-4 rounded-full overflow-hidden border-2 border-white'}>
                                                    <img
                                                        src={`https://flagcdn.com/16x12/${userItem?.fromCountry?.countryDomain}.png`.toLowerCase()}
                                                        alt={"country-flag"}
                                                        className='w-full h-full object-cover'
                                                    />
                                                </div>
                                                <span className='tooltip w-fit px-2 py-1 bg-black text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide sm:whitespace-nowrap translate-x-[50%] -translate-y-10'>
                                                    {userItem?.fromCountry?.country}
                                                </span>
                                            </div>
                                        </div>
                                        <div className={"w-full flex flex-col items-start justify-start gap-1"}>
                                            <div className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                                                {`${userItem?.firstName} ${userItem?.lastName?.charAt(0)?.toUpperCase()}.`}
                                            </div>
                                            <div className={"font-bodyPri font-normal text-text-700 text-sm tracking-wide"}>
                                                {userItem?.email}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                        {[userRoles?.STUDENT?.value]?.includes(locals?.userRole)
                                            ? userRoles?.TUTOR?.label
                                            : userRoles?.STUDENT?.label
                                        }
                                    </div>
                                </div>
                            ))}
                        </InfinitePagination>
                    </div>
                }
                {(loadingElement && (pageCount === 1)) &&
                    <ComponentLoader isLoading={loadingElement} className={"w-full max-h-96 flex items-center justify-center"} />
                }
                {(!loadingElement && (usersList && (usersList?.results?.length === 0))) &&
                    <span className={"w-full h-96 flex items-center justify-center"}>
                        <span className={"w-full font-bodyPri font-normal text-text-700 text-base tracking-wide text-center"}>
                            {"No Students Found!"}
                        </span>
                    </span>
                }
                {(!loadingElement && ((!myStudentList?.myStudentList && !myTutorList?.myTutorList) && (myStudentList?.errorMsg || myTutorList?.errorMsg))) &&
                    <span className={"w-full h-96 flex items-center justify-center"}>
                        <span className={"w-full font-bodyPri font-normal text-red-500 text-base tracking-wide text-center"}>
                            {myStudentList?.errorMsg || myTutorList?.errorMsg}
                        </span>
                    </span>
                }
            </div>

            {shareDriveFileDetail?.message &&
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"w-full font-bodyPri font-normal text-red-500 text-base tracking-wide text-center"}>
                        {shareDriveFileDetail?.message}
                    </span>
                </div>
            }

            <div className={"flex justify-end px-5"}>
                <span
                    className={cn(
                        "px-5 md:px-8 py-2 flex justify-center items-center rounded-full cursor-pointer",
                        "font-bodyPri font-normal text-base",
                        selectedUsers?.length > 0 && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                        (!selectedUsers || selectedUsers?.length === 0) && "bg-text-300 text-text-50 hover:bg-text-300 hover:border-text-300",
                        (shareDriveFileDetail?.isLoading) && "bg-secondary-dark"
                    )}
                    onClick={onHandleShareFile}
                >
                    {shareDriveFileDetail?.isLoading &&
                        <ButtonLoader isLoading={shareDriveFileDetail?.isLoading} />
                    }

                    {!shareDriveFileDetail?.isLoading && "Share"}
                </span>
            </div>
        </div>
    )
}

export default ShareDriveFileModal;