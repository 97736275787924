import { dayjs } from "utils/dateTime.utils";

export const generateStartTimeOptions = (currentDateTime = dayjs(), timeZone = dayjs.tz.guess().toString()) => {
    let timeOptions = {};
    new Array(24).fill("").forEach((_, hour) => {
        [0, 15, 30, 45].forEach((minute) => {
            let key = dayjs(currentDateTime.format("YYYY-MM-DD")).tz(timeZone).add(hour, "hour").add(minute, "minute").format("HHmmss");
            timeOptions[key] = {
                label: dayjs(currentDateTime.format("YYYY-MM-DD")).tz(timeZone).add(hour, "hour").add(minute, "minute").format("hh:mma"),
                value: dayjs(currentDateTime.format("YYYY-MM-DD")).tz(timeZone).add(hour, "hour").add(minute, "minute").format("HH:mm:ss")
            };
        });
    });
    const keys = Object.keys(timeOptions).sort();
    let timeOptionList = keys.map((key) => {
        return {
            label: timeOptions[key].label,
            value: timeOptions[key].value
        };
    });

    return timeOptionList;
};

export const generateEndTimeOptions = (startDateTime) => {
    const timeSlots = [];
    let currentDateTime = dayjs(startDateTime);
    const firstFourIncrement = 15
    const restIncrement = 30
    let hourFraction = 0

    for (let i = 0; i < 4; i++) {
        const label = currentDateTime.format('hh:mma')
        const value = currentDateTime.format('HH:mm:ss')
        const duration = `${i * firstFourIncrement} mins`
        timeSlots.push({ label: `${label} (${duration})`, value })
        currentDateTime = currentDateTime.add(firstFourIncrement, 'minute')
        hourFraction += 0.25
    }

    while (timeSlots.length < 50) {
        const label = currentDateTime.format('hh:mma')
        const value = currentDateTime.format('HH:mm:ss')
        const duration = (hourFraction === 1) ? `${hourFraction} hr` : `${hourFraction} hrs`
        timeSlots.push({ label: `${label} (${duration})`, value })
        currentDateTime = currentDateTime.add(restIncrement, 'minute')
        hourFraction += 0.5
    }

    return timeSlots;
};

// no use for now
export const generateTestTimeOptions = (startDateTime, timeZone = dayjs.tz.guess().toString()) => {
    const timeOptions = {}
    let interval = 30

    new Array(24 * 60 / interval).fill('').forEach((_, index) => {
        const time = startDateTime.tz(timeZone).add(index * interval, 'minute')
        const label = time.format('hh:mm a')
        const value = time.format('HH:mm:ss')
        const durationHours = Math.floor(index * interval / 60)
        const durationMins = index * interval % 60
        const durationLabel = ((durationHours === 0) && (durationMins === 0))
            ? '0 mins'
            : ((durationHours > 0)
                ? durationHours + ((durationHours === 1) && (durationMins === 0)
                    ? ' hr'
                    : ((durationMins > 0)
                        ? (durationMins === 30) ? ' .5 hrs'
                            : (durationMins === 0) ? ' hrs' : ''
                        : ' hrs'
                    )
                )
                : ((durationMins > 0)
                    ? (durationMins === 30) ? ' 30 mins'
                        : (durationMins === 0) ? ' 0 mins' : ''
                    : ''
                )
            )
        let key = time.format('HHmmss')
        timeOptions[key] = {
            label: `${label} (${durationLabel})`,
            value: value
        }
    })

    const keys = Object.keys(timeOptions)
    const timeOptionList = keys.map((key) => {
        return {
            label: timeOptions[key].label,
            value: timeOptions[key].value
        }
    })

    return timeOptionList;
}