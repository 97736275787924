import { useEffect, useMemo, useState } from 'react';

import { GoogleLogin } from '@react-oauth/google';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import {
    AnchorLink,
    TopLink,
    SignUpCardContent,
    SignUpCardHeader,
    Heading,
    SubHeading,
    SignUpFormWrapper,
    CheckBoxForTips,
    SignUpCardFooter,
    DividerWrapper,
    SocialSignUpWrapper,
} from './SignUpCardStyle';

import { ENVIRONMENT } from 'const/default.const';

import ButtonLoader from 'components/loader/button-loader';
import SignUpForm from 'components/signUpForm/SignUpForm';
import HDividerWithText from 'components/hDividerWithText/HDividerWithText';

import { createOneTapGoogleLogin, createSignUp } from 'redux/auth/auth.request';

import useWindowSize from 'hooks/useWindowSize';
import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setSignUpPayload } from 'redux/auth/auth.slice';
import { environmentEnum } from 'redux/local/local.const';
import { otpStatus } from 'redux/notification/notification.const';

import { pagesInfo } from 'utils/pagesInfo';
import { timeZone } from 'utils/dateTime.utils';

const edulyteTermsOfService = "https://www.edulyte.com/terms-of-service/"
const edulytePrivacyPolicy = "https://www.edulyte.com/privacy-policy/"

const SignUpCard = () => {
    const { signUp } = useAppState((s) => s.auth)
    const [stopLoading, setStopLoading] = useState(false);
    const { verifyOtp } = useAppState((s) => s.notification)
    const { user } = useAppState((s) => s.user)

    const dispatcher = useAppDispatcher()
    const { width } = useWindowSize()
    const { executeRecaptcha } = useGoogleReCaptcha();

    const CURRENT_ACTIVE_DOMAIN = useMemo(() => {
        if (ENVIRONMENT === environmentEnum.local.key) {
            return 'localhost'
        } else if (ENVIRONMENT === environmentEnum.dev.key) {
            return 'edulyte.net'
        } else {
            return 'edulyte.com'
        }
    }, [ENVIRONMENT])

    const getRecaptchaToken = async () => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha('signup');
        if (token) {
            return token;
        }
    }

    const createNewSignUpUser = async () => {
        const generatedToken = await getRecaptchaToken()
        if (generatedToken) {
            const body = {
                firstName: signUp?.payload?.firstName,
                lastName: signUp?.payload?.lastName,
                email: signUp?.payload?.email,
                referralCode: signUp?.payload?.referralCode,
                isPromotionActive: signUp?.payload?.isPromotionActive,
                rechaptcha_token: generatedToken,
                timeZone: timeZone,
                source: window?.location?.href
            }
            if (signUp?.payload?.mobileNo) {
                body["mobileNo"] = String(signUp?.payload?.country?.isdCode + "-" + signUp?.payload?.mobileNo)
                body["isMobileNoVerified"] = signUp?.payload?.isMobileNoVerified
            }
            // setStopLoading(false)
            dispatcher(createSignUp(body))
        }
    }

    useEffect(() => {
        if (verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            createNewSignUpUser()
        }
    }, [verifyOtp?.verifyOtp])


    const handleTipsBox = () => {
        dispatcher(setSignUpPayload({
            ...signUp?.payload,
            isPromotionActive: !signUp?.payload?.isPromotionActive
        }))
    }

    const onHandleSuccess = (credentialResponse) => {
        setStopLoading(true)
        const requestPayload = {
            timeZone: timeZone,
            credentials: credentialResponse?.credential,
            source: window?.location?.href
        }
        dispatcher(createOneTapGoogleLogin(requestPayload))
        setTimeout(() => { setStopLoading(false) }, 1000)
    }

    return (
        <>
            <TopLink>
                <AnchorLink to={pagesInfo.TEACH_WITH_US.pagePath}>{'Sign up as a teacher'}</AnchorLink>
            </TopLink>
            <SignUpCardContent>
                <SignUpCardHeader>
                    <Heading>{'Sign up free'}</Heading>
                    <SubHeading>
                        {'Already have an account?'}
                        <AnchorLink to={pagesInfo.LOG_IN.pagePath}>{'Log in'}</AnchorLink>
                    </SubHeading>
                </SignUpCardHeader>
                <SignUpFormWrapper>
                    {/* <SignUpForm formSubmit={formSubmit} /> */}
                    <SignUpForm />
                </SignUpFormWrapper>
                <DividerWrapper>
                    <HDividerWithText text={'or continue with'} />
                </DividerWrapper>
                <SocialSignUpWrapper>
                    {(stopLoading || user?.isLoading) &&
                        <div className='bg-primary-dark rounded-lg w-full'>
                            <ButtonLoader isLoading={stopLoading} styles={{ color: 'white', height: 40 }} />
                        </div>
                    }
                    {(!stopLoading && !user?.isLoading) &&
                        <GoogleLogin
                            onSuccess={onHandleSuccess}
                            onError={(error) => {
                                console.log('Login Failed: ', error);
                            }}
                            ux_mode={'popup'}
                            width={(width > 768) ? "380" : '340'}
                            size={'large'}
                            theme={'outline'}
                            shape={"rectangular"}
                            text="signup_with"
                            // select_account={true}
                            auto_select={true}
                            state_cookie_domain={CURRENT_ACTIVE_DOMAIN}
                            use_fedcm_for_prompt={true}
                        />
                    }

                </SocialSignUpWrapper>
                <CheckBoxForTips>
                    <input type='checkbox' checked={!signUp?.payload?.isPromotionActive} onClick={handleTipsBox} />
                    {`Please don't send me tips or marketing via email or SMS.`}
                </CheckBoxForTips>
                <SignUpCardFooter>
                    {"By signing up, I agree to "}
                    <a
                        href={edulyteTermsOfService}
                        rel={"noreferrer"}
                        target={"_blank"}
                        className={"text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer"}
                    >
                        {"Terms of service"}
                    </a>,
                    <AnchorLink to={'#'}>
                        {" Community Guidelines"}
                    </AnchorLink>
                    {" and "}
                    <a
                        href={edulytePrivacyPolicy}
                        rel={"noreferrer"}
                        target={"_blank"}
                        className={"text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer"}
                    >
                        {"Privacy Policy"}
                    </a>
                </SignUpCardFooter>
            </SignUpCardContent>
        </>
    )
}

export default SignUpCard;