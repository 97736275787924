import { FaSpinner } from "react-icons/fa";

import { buttonFeature } from 'pages/auth/wallet/data';

import { createUserCustomerPortalSession } from "redux/payment/payment.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setAddCreditPayload, setClearAddCreditPayload, setClearAddCreditToWallet } from "redux/wallet/wallet.slice";
import { setClearAddUserWithdrawalDetailPayload } from "redux/withdrawal/withdrawal.slice";
import { modalConst } from "redux/local/local.const";
import { addCreditToWalletPayload } from "redux/wallet/wallet.initialState";

import { cn } from "utils/cn.utils";

const WalletFeatureButton = ({ featureItem }) => {
  const { modal, locals } = useAppState(s => s.local)
  const { user } = useAppState(s => s.user)
  const { createUserCustomerPortalSession: addUserCustomerPortalSession } = useAppState(s => s.payment)

  const dispatcher = useAppDispatcher();

  const handleButton = () => {
    if (featureItem.value === buttonFeature.WITHDRAW.value) {
      dispatcher(setClearAddUserWithdrawalDetailPayload())
      dispatcher(setModal({
        ...modal,
        [modalConst.WITHDRAW_MONEY_MODAL.stateKey]: true
      }))
    } else if (featureItem.value === buttonFeature.ADD_CREDIT.value) {
      dispatcher(setClearAddCreditToWallet())
      dispatcher(setClearAddCreditPayload())
      dispatcher(setAddCreditPayload({
        ...addCreditToWalletPayload,
        currencyCode: locals?.currencyCode
      }))
      dispatcher(setModal({
        ...modal,
        [modalConst.ADD_CREDIT_MODAL.stateKey]: true
      }))
    } else if (featureItem.value === buttonFeature.REEDEM_GIFT_CARD.value) {
      dispatcher(setModal({
        ...modal,
        [modalConst.COMING_SOON_MODAL.stateKey]: true
      }))
    } else if (featureItem.value === buttonFeature.PROMO.value) {
      dispatcher(setModal({
        ...modal,
        [modalConst.PROMO_MODAL.stateKey]: true
      }))
    } else if (featureItem.value === buttonFeature.MANAGE_SUBSCRIPTION.value) {
      // dispatcher(setModal({
      //   ...modal,
      //   [modalConst.ADD_TOKEN_MODAL.stateKey]: true
      // }))
      dispatcher(createUserCustomerPortalSession({ "userId": user?.user?.userId }))
    }
  }

  return (
    <div
      onClick={handleButton}
      className={cn(
        "flex flex-col items-center justify-center gap-2 cursor-pointer",
        "font-semibold text-normal md:text-xl text-primary-dark group hover:text-secondary-dark hover:scale-105 transition-all ease-in-out delay-100 duration-150"
      )}>
      {featureItem.image}
      <span className="flex items-center gap-5 font-bodyPri font-medium text-md text-primary-dark group-hover:text-secondary-dark line-clamp-1">
        {featureItem.label}
        {featureItem.value === buttonFeature.MANAGE_SUBSCRIPTION.value && addUserCustomerPortalSession?.isLoading && (
          <FaSpinner className="text-md animate-spin text-primary-dark group-hover:text-secondary-dark" />
        )}
      </span>
    </div>
  );
}

export default WalletFeatureButton;