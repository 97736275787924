import { useState } from "react";

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

import { upcomingSessionFilterConst } from "../data";

const GestureDropdown = ({ selectedSessionFilter, setSelectedSessionFilter }) => {

    const [isOpenDropDownMenu, setIsOpenDropDownMenu] = useState(false)

    const onHandleSelectItem = (selected) => {
        setSelectedSessionFilter(selected)
        setIsOpenDropDownMenu(false)
    }

    return (
        <div
            className={"relative flex flex-col items-start justify-start gap-5 cursor-pointer"}
            onClick={() => setIsOpenDropDownMenu(true)}
            onMouseLeave={() => setIsOpenDropDownMenu(false)}
        >
            <div className={"m-0 md:mt-0.5 flex items-center justify-start gap-0.5 cursor-pointer"}>
                <span className={"font-bodyPri font-normal tracking-wide text-base md:whitespace-nowrap"}>
                    {(selectedSessionFilter?.length > 8)
                        ? `(${upcomingSessionFilterConst[selectedSessionFilter?.toUpperCase()]?.label?.slice(0, 8)}..)`
                        : `(${upcomingSessionFilterConst[selectedSessionFilter?.toUpperCase()]?.label})`
                    }
                </span>
                {!isOpenDropDownMenu &&
                    <ArrowDropDown />
                }
                {isOpenDropDownMenu &&
                    <ArrowDropUp />
                }
            </div>
            {isOpenDropDownMenu &&
                <div
                    className={"w-fit absolute top-6 z-40 mt-1 py-2.5 flex flex-col items-center justify-center gap-3 rounded bg-white shadow-all"}
                    onMouseOver={() => setIsOpenDropDownMenu(true)}
                    onMouseOut={() => setIsOpenDropDownMenu(false)}
                >
                    {Object.values(upcomingSessionFilterConst)?.map((item, index) => (
                        <div
                            key={index}
                            className={"w-full px-3 py-1 flex items-center justify-center hover:bg-background-lightBlue"}
                            onClick={() => onHandleSelectItem(item?.value)}
                        >
                            <span className={"font-bodyPri font-normal text-text-800 md:whitespace-nowrap cursor-pointer"}>
                                {item?.label}
                            </span>
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}

export default GestureDropdown;