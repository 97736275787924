import { memo } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

const MUIHorizontalTabs = memo(({
    tabList = [],
    activeTab,
    onHandleChangeTab,
    selectionFollowsFocus = true,
    wrapText = true,
    tabBoxContainerStyle = { width: "100%" },
    tabTextColor = "primary",
    tabIndicatorColor = "primary",
    tabItemStyle = { fontFamily: "Roboto", textTransform: "capitalize", fontSize: 16, fontWeight: 500 }
}) => {

    return (
        <Box sx={tabBoxContainerStyle}>
            <Box>
                <Tabs
                    value={activeTab}
                    onChange={onHandleChangeTab}
                    textColor={tabTextColor}
                    indicatorColor={tabIndicatorColor}
                    selectionFollowsFocus={selectionFollowsFocus}
                >
                    {tabList.map((ele, idx) => (
                        <Tab
                            key={idx}
                            value={ele.value}
                            label={ele.label}
                            {...a11yProps(idx)}
                            style={tabItemStyle}
                            wrapped={wrapText}
                        />
                    ))}
                </Tabs>
            </Box>
        </Box>
    );
});

export default MUIHorizontalTabs;