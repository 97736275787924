import format from "string-format";

import { howDoesItWork } from "pages/auth/referral/data";

import { useAppState } from "hooks/useStore";
import { currencyRateConversion } from 'utils/generators.utils';

const knowMoreLink = "https://support.edulyte.com/portal/en/kb/articles/how-does-the-edulyte-referral-affiliate-program-work"

const HowWork = () => {
  const { locals } = useAppState((state) => state.local)

  const onHandleKnowMore = () => {
    window.open(knowMoreLink, "_blank")
  }

  return (
    <div className="bg-white rounded-lg p-5 space-y-5">
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <div className="flex justify-start lg:justify-center">
            <div className="text-primary-dark text-3xl font-medium">
              {"How does it work?"}
            </div>
          </div>
          <div className="font-buttons font-medium text-sm text-text-600 cursor-pointer text-right -mt-12 md:-mt-10 hover:text-text-800"
            onClick={onHandleKnowMore}
          >
            <span>{"Know more"}</span>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-5 py-5">
        {howDoesItWork.map((items) => (
          <div className="col-span-12 lg:col-span-4">
            <div className="flex flex-col justify-center items-center px-20 gap-2">
              <div className="w-40 h-40 flex justify-center items-center overflow-hidden">
                <img src={items.image} alt="Refer-Invite" className="w-full h-full object-cover" />
              </div>
              <div className="font-bodyPri font-medium text-base leading-normal text-text-700 text-center">
                {format(items.title, { "amount1": currencyRateConversion("USD", 500), "amount2": currencyRateConversion(locals?.currencyCode, 500) })}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowWork;
