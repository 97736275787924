import { useState } from 'react';
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import SelectSearch, { fuzzySearch } from 'react-select-search';

import { FaSpinner } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { MdAdd } from 'react-icons/md';
import { MdOutlineDeleteOutline } from 'react-icons/md';

import { profileEditBtn } from 'pages/auth/profile/data';
import SaveAndCancelBtn from 'pages/auth/profile/commonComponents/SaveAndCancelBtn';

import {
    createStudentUserLanguageDetailByUserId,
    updateStudentLanguageDetailByStudentLanguageId,
    deleteStudentLanguageDetailByStudentLanguageId
} from 'redux/student/student.request';
import {
    createTutorUserLanguageDetailByUserId,
    updateTutorLanguageDetailByTutorLanguageId,
    deleteTutorLanguageDetailByTutorLanguageId
} from 'redux/tutor/tutor.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { userRoles } from 'redux/auth/auth.const';
import { languageLevelConst } from 'redux/master/master.const';

const UserLanguage = ({ editBtnConst, setEditBtnConst }) => {
    const { locals } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { languageList, languageLevelList } = useAppState((state) => state.master)
    const { tutorUserLanguageList, addTutorLanguageDetail } = useAppState((state) => state.tutor)
    const { studentUserLanguageList, addStudentLanguageDetail } = useAppState((state) => state.student)

    const dispatcher = useAppDispatcher()

    const [isShowAddLanguageButton, setIsShowAddLanguageButton] = useState(false)
    const [isAddLanguageEnabled, setIsAddLanguageEnabled] = useState(false)
    const [selectedLevelAndLanguage, setSelectedLevelAndLanguage] = useState({
        isUpdateUserLanguage: false,
        UserLanguageId: null,
        language: null,
        languageLevel: null
    })

    const removeLanguageAndLevel = (UserLanguage) => {
        if (locals.userRole === userRoles.TUTOR.value) {
            dispatcher(deleteTutorLanguageDetailByTutorLanguageId(UserLanguage?.tutorLanguageId))
        }
        if (locals.userRole === userRoles.STUDENT.value) {
            dispatcher(deleteStudentLanguageDetailByStudentLanguageId(UserLanguage?.studentLanguageId))
        }
    }

    const handleAddLanguage = () => {
        setIsShowAddLanguageButton(false)
        setIsAddLanguageEnabled(true)
        setSelectedLevelAndLanguage({
            isUpdateUserLanguage: false,
            UserLanguageId: null,
            language: null,
            languageLevel: null
        })
    }

    const onHandleUpdateLanguageAndLevel = () => {
        if (locals.userRole === userRoles.TUTOR.value) {
            dispatcher(updateTutorLanguageDetailByTutorLanguageId(
                selectedLevelAndLanguage?.UserLanguageId,
                {
                    masterLanguageId: selectedLevelAndLanguage?.language,
                    masterLanguageLevelId: selectedLevelAndLanguage?.languageLevel
                }
            ))
        }
        if (locals.userRole === userRoles.STUDENT.value) {
            dispatcher(updateStudentLanguageDetailByStudentLanguageId(
                selectedLevelAndLanguage?.UserLanguageId,
                {
                    masterLanguageId: selectedLevelAndLanguage?.language,
                    masterLanguageLevelId: selectedLevelAndLanguage?.languageLevel
                }
            ))
        }
        setIsShowAddLanguageButton(false)
        setIsAddLanguageEnabled(false)
        setEditBtnConst(null)
    }

    const onSaveLanguageAndLevel = () => {
        if (!selectedLevelAndLanguage?.language || !selectedLevelAndLanguage?.languageLevel) {
            toast.error("Language and proficiency are required fields")
            return;
        }
        if (selectedLevelAndLanguage?.isUpdateUserLanguage) {
            onHandleUpdateLanguageAndLevel()
            return;
        }
        if (locals.userRole === userRoles.TUTOR.value) {
            const languageAndLevelList = tutorUserLanguageList?.tutorUserLanguageList
            const filteredLanguageAndLevelList = languageAndLevelList?.filter((languageAndLevel) => (
                languageAndLevel?.language?.masterLanguageId === selectedLevelAndLanguage?.language
            ))

            if (filteredLanguageAndLevelList?.length > 0) {
                alert("Duplicate Entries are not allowed")
                return;
            }
            dispatcher(createTutorUserLanguageDetailByUserId(
                user?.user?.userId,
                {
                    masterLanguageId: selectedLevelAndLanguage?.language,
                    masterLanguageLevelId: selectedLevelAndLanguage?.languageLevel
                }
            ))
        }
        if (locals.userRole === userRoles.STUDENT.value) {
            const languageAndLevelList = studentUserLanguageList?.studentUserLanguageList
            const filteredLanguageAndLevelList = languageAndLevelList?.filter((languageAndLevel) => (
                languageAndLevel?.language?.masterLanguageId === selectedLevelAndLanguage?.language
            ))

            if (filteredLanguageAndLevelList?.length > 0) {
                alert("Duplicate Entries are not allowed")
                return;
            }
            dispatcher(createStudentUserLanguageDetailByUserId(
                user?.user?.userId,
                {
                    masterLanguageId: selectedLevelAndLanguage?.language,
                    masterLanguageLevelId: selectedLevelAndLanguage?.languageLevel
                }
            ))
        }
        setIsShowAddLanguageButton(false)
        setIsAddLanguageEnabled(false)
        setEditBtnConst(null)
    }

    return (
        <div className={""}>
            <div className="flex flex-col items-start justify-center gap-2">
                <div className={"flex items-center justify-start gap-2"}>
                    <span className="text-xl text-text-900 font-medium font-bodyPri tracking-wide">
                        {" I speak:"}
                    </span>
                    {!editBtnConst &&
                        <FiEdit className="text-lg text-text-500 cursor-pointer"
                            onClick={() => {
                                setEditBtnConst(profileEditBtn.languages.value)
                                setIsShowAddLanguageButton(true)
                            }}
                        />
                    }
                    {(addStudentLanguageDetail?.isLoading || addTutorLanguageDetail?.isLoading) &&
                        <div className={"flex items-center justify-start gap-0.5"}>
                            <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                                {"Please wait ..."}
                            </span>
                            <FaSpinner className={"inline-flex text-primary-dark animate-spin"} />
                        </div>
                    }
                    {editBtnConst === profileEditBtn.languages.value &&
                        <div
                            className={cn(
                                "flex item-center justify-center px-2 py-0.5 rounded-md cursor-pointer",
                                "font-buttons font-normal text-base text-text-800 bg-background-darkLight",
                                "hover:bg-background-medium hover:text-text-900"
                            )}
                            onClick={() => {
                                setIsShowAddLanguageButton(false)
                                setIsAddLanguageEnabled(false)
                                setEditBtnConst(null)
                            }}
                        >
                            <span className={""}>
                                {"Cancel"}
                            </span>
                        </div>
                    }
                </div>
                <div className={cn(
                    "w-full flex flex-col items-start justify-start gap-2 px-3 py-1",
                    editBtnConst === profileEditBtn.languages.value && "border-2 border-secondary-main border-dashed"
                )}>
                    {(locals.userRole === userRoles?.STUDENT.value && studentUserLanguageList?.studentUserLanguageList?.length > 0)
                        && studentUserLanguageList?.studentUserLanguageList?.map((studentUserLanguage) => (
                            <div className={"flex items-center justify-start gap-5"}>
                                <div className={"flex items-center justify-start gap-1"}>
                                    <span className={"font-bodyPri font-normal tracking-wide text-text-800 text-base"}>
                                        {studentUserLanguage?.language?.language}
                                    </span>
                                    <span className={"font-bodyPri font-normal text-text-700 text-sm"}>
                                        ({languageLevelConst[studentUserLanguage?.languageLevel?.languageLevel.toUpperCase()].label})
                                    </span>
                                </div>
                                {editBtnConst === profileEditBtn.languages.value &&
                                    <div className={"flex items-center justify-start gap-1.5"}>
                                        <FiEdit
                                            className={"text-lg text-text-500 cursor-pointer"}
                                            onClick={() => {
                                                setSelectedLevelAndLanguage({
                                                    isUpdateUserLanguage: true,
                                                    UserLanguageId: studentUserLanguage?.studentLanguageId,
                                                    language: studentUserLanguage?.language?.masterLanguageId,
                                                    languageLevel: studentUserLanguage?.languageLevel?.masterLanguageLevelId
                                                })
                                                setIsShowAddLanguageButton(false)
                                                setIsAddLanguageEnabled(true)
                                            }}
                                        />
                                        <MdOutlineDeleteOutline
                                            className={"text-xl text-text-500 cursor-pointer"}
                                            onClick={() => removeLanguageAndLevel(studentUserLanguage)}
                                        />
                                    </div>
                                }
                            </div>
                        ))}
                    {(locals.userRole === userRoles?.STUDENT.value && studentUserLanguageList?.studentUserLanguageList?.length === 0 && editBtnConst !== profileEditBtn.languages.value) &&
                        <span className={"font-bodyPri font-normal text-text-700 text-base"}>
                            {"No language Added!"}
                        </span>
                    }
                    {(locals.userRole === userRoles?.TUTOR.value && tutorUserLanguageList?.tutorUserLanguageList?.length > 0)
                        && tutorUserLanguageList?.tutorUserLanguageList?.map((tutorUserLanguage) => (
                            <div className={"flex items-center justify-start gap-5"}>
                                <div className={"flex items-center justify-start gap-1"}>
                                    <span className={"font-bodyPri font-normal tracking-wide text-text-800 text-base"}>
                                        {tutorUserLanguage?.language?.language}
                                    </span>
                                    <span className={"font-bodyPri font-normal text-text-700 text-sm"}>
                                        ({languageLevelConst[tutorUserLanguage?.languageLevel?.languageLevel.toUpperCase()].label})
                                    </span>
                                </div>
                                {editBtnConst === profileEditBtn.languages.value &&
                                    <div className={"flex items-center justify-start gap-1.5"}>
                                        <FiEdit
                                            className={"text-lg text-text-500 cursor-pointer"}
                                            onClick={() => {
                                                setSelectedLevelAndLanguage({
                                                    isUpdateUserLanguage: true,
                                                    UserLanguageId: tutorUserLanguage?.tutorLanguageId,
                                                    language: tutorUserLanguage?.language?.masterLanguageId,
                                                    languageLevel: tutorUserLanguage?.languageLevel?.masterLanguageLevelId
                                                })
                                                setIsShowAddLanguageButton(false)
                                                setIsAddLanguageEnabled(true)
                                            }}
                                        />
                                        <MdOutlineDeleteOutline
                                            className={"text-xl text-text-500 cursor-pointer"}
                                            onClick={() => removeLanguageAndLevel(tutorUserLanguage)}
                                        />
                                    </div>
                                }
                            </div>
                        ))}
                    {(locals.userRole === userRoles?.TUTOR.value && tutorUserLanguageList?.tutorUserLanguageList?.length === 0 && editBtnConst !== profileEditBtn.languages.value) &&
                        <span className={"font-bodyPri font-normal text-text-700 text-base"}>
                            {"No language Added!"}
                        </span>
                    }
                    {isShowAddLanguageButton &&
                        <div
                            className={cn(
                                "flex item-center justify-center cursor-pointer",
                                "font-buttons font-normal text-base text-text-700",
                                "hover:text-text-800"
                            )}
                            onClick={handleAddLanguage}
                        >
                            <MdAdd className={"text-xl"} />
                            <span className={"whitespace-nowrap"}>
                                {"Add language"}
                            </span>
                        </div>
                    }
                    {isAddLanguageEnabled &&
                        <div className={"flex flex-col items-start justify-center gap-3 pt-3"}>
                            <div className={"flex items-center justify-start gap-5"}>
                                {languageList?.isLoading || languageLevelList?.isLoading &&
                                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                                }
                                {(languageList?.languageList || []) &&
                                    <SelectSearch
                                        options={languageList?.languageList?.map((language) => ({
                                            name: language?.language,
                                            value: language?.masterLanguageId
                                        }))}
                                        autoFocus={false}
                                        placeholder={"Select language"}
                                        search
                                        filterOptions={fuzzySearch}
                                        value={selectedLevelAndLanguage?.language}
                                        onChange={(option) => {
                                            setSelectedLevelAndLanguage({
                                                ...selectedLevelAndLanguage,
                                                language: option
                                            })
                                        }}
                                    />
                                }
                                {(languageLevelList?.languageLevelList || []) &&
                                    <SelectSearch
                                        options={languageLevelList?.languageLevelList?.map((languageLevel) => ({
                                            name: languageLevelConst[languageLevel?.languageLevel?.toUpperCase()].label,
                                            value: languageLevel?.masterLanguageLevelId
                                        }))}
                                        autoFocus={false}
                                        placeholder={"Select language level"}
                                        filterOptions={fuzzySearch}
                                        value={selectedLevelAndLanguage?.languageLevel}
                                        onChange={(option) => {
                                            setSelectedLevelAndLanguage({
                                                ...selectedLevelAndLanguage,
                                                languageLevel: option
                                            })
                                        }}
                                    />
                                }
                            </div>
                            <SaveAndCancelBtn
                                onSave={() => onSaveLanguageAndLevel()}
                                onCancel={() => {
                                    setSelectedLevelAndLanguage({
                                        isUpdateUserLanguage: false,
                                        UserLanguageId: null,
                                        language: null,
                                        languageLevel: null,
                                    })
                                    setIsAddLanguageEnabled(false)
                                    setIsShowAddLanguageButton(true)
                                }}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default UserLanguage;