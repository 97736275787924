import { useEffect, useState } from 'react';
import { Navigate, useLocation, Outlet } from "react-router-dom";

import { pageHeading, navigationOptions } from 'pages/auth/studentLms/courses/data';

import PageHeader from 'components/pageHeader/PageHeader';

import NavigationTabs from './components/NavigationTabs';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';
import { useTitle } from 'hooks/useTitle';

export default function StudentLmsCourses({ allowedRoles }) {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { locals } = useAppState((s) => s.local)
    const { session } = useAppState((s) => s.auth)
    const { user } = useAppState((s) => s.user)

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.STUDENT_LMS_COURSES))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: `All My Courses | Edulyte`
        })
    }, [dispatcher, currentPageInfo])

    return (
        <div className={"h-full w-full p-3 mx-auto min-h-screen space-y-3"}>
            <PageHeader pageHeading={pageHeading} />

            <div className={"flex flex-col space-y-3"}>
                <NavigationTabs options={navigationOptions} />
                <div className={"w-full rounded-lg overflow-hidden p-5 bg-white"}>
                    {session?.isLoggedIn && user?.user?.roles?.find((role) => allowedRoles?.includes(role.role) && role.role === locals.userRole)
                        ? <Outlet />
                        : <Navigate to={pagesInfo.UNAUTHORIZED.pagePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />
                    }
                </div>
            </div>
        </div>
    );
}