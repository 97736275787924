import { useEffect, useMemo, useState } from 'react';

import { PageContentStyle } from './style';

import Footer1 from 'components/footer1/Footer1';

import Header from './components/header'
import AppointmentCard, { AppointmentCardLoader } from './components/appointmentCard'

import { useTitle } from 'hooks/useTitle';
import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setPageInfo } from "redux/pageInfo/pageInfo.request";
import { getPublicAppointmentList } from "redux/appointment/appointment.request";
import { resetPublicAppointments } from "redux/appointment/appointment.slice";

import { pagesInfo } from "utils/pagesInfo";
import { cn } from 'utils/cn.utils';
import { appointmentMessageTypeEnum } from 'redux/appointment/appointment.const';
import { BsInfoCircleFill } from 'react-icons/bs';
import { useNavigate, useLocation } from 'react-router-dom';
import Pagination from 'components/pagination/Pagination';

const DEFAULT_APPOINTMENT_RECORDS = 15

const Appointments = () => {
    const { currentPageInfo } = useAppState((s) => s.pageInfo)
    const { publicAppointments } = useAppState(s => s.appointment)

    const { appointments, pagination, message } = useMemo(() => ({
        appointments: publicAppointments?.data?.result,
        pagination: publicAppointments?.data?.pagination,
        message: publicAppointments?.data?.message
    }), [publicAppointments?.data])

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()
    const querySearchParams = new URLSearchParams(location.search.toString())
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.APPOINTMENTS))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        dispatcher(getPublicAppointmentList({ page: querySearchParams.get("pn") || 1, records: DEFAULT_APPOINTMENT_RECORDS }))
        return () => {
            dispatcher(resetPublicAppointments())
        }
    }, [location.search])

    useEffect(() => {
        if (appointments) {
            setTitle({
                ...title,
                title: `Appointment Booking | Edulyte`,
                description: ""
            })
        }
    }, [appointments])

    const onHandlePageChange = (page) => {
        querySearchParams.set("pn", page)
        navigate(`${pagesInfo.APPOINTMENTS.pagePath}?${querySearchParams?.toString()}`)
    }

    return (
        <PageContentStyle className={"bg-white"}>
            <Header />
            <div className='flex justify-center items-center pb-5 mt-6'>
                <div className='max-w-sm md:max-w-3xl lg:max-w-6xl w-full space-y-5'>
                    {!!message && (
                        <div className={"w-full px-2 py-2 sm:py-4 flex items-center justify-center gap-3 bg-white rounded-lg"}>
                            {message?.type === appointmentMessageTypeEnum?.INFO?.value &&
                                <span className={cn(`text-${appointmentMessageTypeEnum?.INFO?.darkColor}`)}>
                                    <BsInfoCircleFill className={"text-xl sm:text-2xl"} />
                                </span>
                            }
                            <span className={"font-bodyPri font-normal text-text-900 text-base lg:text-lg"}>
                                {message?.text}
                            </span>
                        </div>
                    )}
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-8 lg:gap-5'>
                        {appointments?.map((appointment, index) => (
                            <div key={index} className={'col-span-full md:col-span-1 flex items-center justify-center'}>
                                <AppointmentCard appointment={appointment} />
                            </div>
                        ))}
                        {publicAppointments?.isLoading && new Array(DEFAULT_APPOINTMENT_RECORDS).fill("").map((_, index) => (<AppointmentCardLoader key={index} />))}
                    </div>
                    {(pagination?.records === 0 || publicAppointments.message) &&
                        <span className='w-full h-full col-span-10 text-center text-base font-bodyPri tracking-wide pt-5'>
                            {publicAppointments.message}
                        </span>
                    }

                    {(appointments && pagination?.totalPages > 1) && (
                        <div className="w-full flex justify-center items-center">
                            <Pagination
                                page={pagination?.page}
                                totalPages={pagination?.totalPages}
                                onChangePage={(page) => onHandlePageChange(page)}
                            />
                        </div>
                    )}
                </div>
            </div>
            <Footer1 />
        </PageContentStyle>
    )
}

export default Appointments