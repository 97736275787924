import format from 'string-format';

import { baseAuthApiInstance } from 'apiInstances/axios.apiInstance';

import { url } from './lmsLesson.const';

class LmsLessonService {

    static lmsLessonService = new LmsLessonService()

    // student lms lesson service
    getStudentLmsLessonList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_STUDENT_LMS_LESSON_LIST, params),
            { params: query }
        )

        return response;
    }
    getStudentLmsLessonDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_STUDENT_LMS_LESSON_DETAIL, params)
        )

        return response;
    }

    // tutor lms lesson service
    getTutorLmsLessonList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_TUTOR_LMS_LESSON_LIST, params),
            { params: query }
        )

        return response;
    }
    getTutorLmsLessonDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_TUTOR_LMS_LESSON_DETAIL, params)
        )

        return response;
    }

    // lms lesson service
    createLmsLessonDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_LESSON_DETAIL),
            body
        )

        return response;
    }
    getLmsLessonList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LESSON_LIST),
            { params: query }
        )
        return response;
    }
    getLmsLessonDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LESSON_DETAIL, params)
        )

        return response;
    }
    updateLmsLessonDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_LESSON_DETAIL, params),
            body
        )

        return response;
    }
    destroyLmsLessonDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_LESSON_DETAIL, params)
        )

        return response;
    }

    /* lms lesson user service */
    createLmsLessonUserDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_LESSON_USER_DETAIL),
            body
        )

        return response;
    }
    getLmsLessonUserList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LESSON_USER_LIST),
            { params: query }
        )

        return response;
    }
    getLmsLessonUserDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LESSON_USER_DETAIL, params)
        )

        return response;
    }
    updateLmsLessonUserDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_LESSON_USER_DETAIL, params),
            body
        )

        return response;
    }
    deleteLmsLessonUserDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_LESSON_USER_DETAIL, params)
        )

        return response;
    }

    /* lms lesson slug service */
    getLmsLessonSlugList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LESSON_SLUG_LIST),
            { params: query }
        )

        return response;
    }
    getLmsLessonSlugDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LESSON_SLUG_DETAIL, params)
        )

        return response;
    }
    createLmsLessonSlugDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_LESSON_SLUG_DETAIL),
            body
        )

        return response;
    }
    updateLmsLessonSlugDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_LESSON_SLUG_DETAIL, params),
            body
        )

        return response;
    }
    deleteLmsLessonSlugDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_LESSON_SLUG_DETAIL, params)
        )

        return response;
    }

    /* lms lesson setting service */
    createLmsLessonSettingDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_LESSON_SETTING_DETAIL),
            body
        )

        return response;
    }
    getLmsLessonSettingList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LESSON_SETTING_LIST),
            { params: query }
        )

        return response;
    }
    getLmsLessonSettingDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LESSON_SETTING_DETAIL, params)
        )

        return response;
    }
    updateLmsLessonSettingDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_LESSON_SETTING_DETAIL, params),
            body
        )

        return response;
    }
    deleteLmsLessonSettingDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_LESSON_SETTING_DETAIL, params)
        )

        return response;
    }

    /* lms lesson lecture service */
    createLmsLessonLectureDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_LESSON_LECTURE_DETAIL),
            body
        )

        return response;
    }
    getLmsLessonLectureList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LESSON_LECTURE_LIST),
            { params: query }
        )

        return response;
    }
    getLmsLessonLectureDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LESSON_LECTURE_DETAIL, params)
        )

        return response;
    }
    updateLmsLessonLectureDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_LESSON_LECTURE_DETAIL, params),
            body
        )

        return response;
    }
    deleteLmsLessonLectureDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_LESSON_LECTURE_DETAIL, params)
        )

        return response;
    }

    /* lms lesson article service */
    createLmsLessonArticleDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_LESSON_ARTICLE_DETAIL),
            body
        )

        return response;
    }
    getLmsLessonArticleList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LESSON_ARTICLE_LIST),
            { params: query }
        )

        return response;
    }
    getLmsLessonArticleDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LESSON_ARTICLE_DETAIL, params)
        )

        return response;
    }
    updateLmsLessonArticleDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_LESSON_ARTICLE_DETAIL, params),
            body
        )

        return response;
    }
    deleteLmsLessonArticleDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_LESSON_ARTICLE_DETAIL, params)
        )

        return response;
    }

    /* lms lesson quiz service */
    getLmsLessonQuizList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LESSON_QUIZ_LIST),
            { params: query }
        )

        return response;
    }

    getLmsLessonQuizDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LESSON_QUIZ_DETAIL, params)
        )

        return response;
    }

    createLmsLessonQuizDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_LESSON_QUIZ_DETAIL),
            body
        )

        return response;
    }

    updateLmsLessonQuizDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_LESSON_QUIZ_DETAIL, params),
            body
        )

        return response;
    }

    deleteLmsLessonQuizDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_LESSON_QUIZ_DETAIL, params)
        )

        return response;
    }

    createLmsLessonInstructorDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_LESSON_INSTRUCTOR_DETAIL),
            body
        )

        return response;
    }

    updateLmsLessonInstructorDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_LESSON_INSTRUCTOR_DETAIL, params),
            body
        )

        return response;
    }

    deleteLmsLessonInstructorDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_LESSON_INSTRUCTOR_DETAIL, params)
        )

        return response;
    }

}

export default LmsLessonService.lmsLessonService;