import { useState, useEffect } from "react";
import { cn } from "utils/cn.utils";

import { FaSpinner } from "react-icons/fa";
import IsdCodeSelector from 'components/optionSelector/IsdCodeSelector';

import { getMasterCountryList } from 'redux/master/master.request';
import { createLeadDetail } from "redux/lead/lead.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearCountryList } from 'redux/master/master.slice';
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { setAddLeadDetailPayload, setClearAddLeadDetail } from "redux/lead/lead.slice";

import { validateEmail, validateMobileNo } from 'utils/validation-functions';
import { timeZone } from "utils/dateTime.utils";
import ButtonLoader from "components/loader/ButtonLoader";

const SpeakToUsModal = () => {
    const { countryList, timeZoneCountryDetail } = useAppState(s => s.master)
    const { modal } = useAppState((state) => state.local)
    const { addLeadDetail } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()


    const [emailAddressError, setEmailAddressError] = useState("")
    const [mobileNumber, setMobileNumber] = useState(addLeadDetail?.addLeadDetailPayload?.mobileNo?.split("-")[1] || "")
    const [mobileNoError, setMobileNoError] = useState("")
    const [myCountry, setMyCountry] = useState({
        masterCountryId: 0,
        country: "",
        countryDomain: "",
        isdCode: ""
    })

    useEffect(() => {
        if (addLeadDetail?.addLeadDetail) {
            dispatcher(setClearAddLeadDetail())
            dispatcher(setModal({
                ...modal,
                [modalConst.SPEAK_TO_US_MODAL.stateKey]: false
            }))
        }
    }, [addLeadDetail?.addLeadDetail])

    useEffect(() => {
        dispatcher(getMasterCountryList())

        return () => {
            dispatcher(setClearCountryList())
        }
    }, [])

    useEffect(() => {
        if (countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country) => (
                    country.countryDomain === "US"
                ))
            }
            setMyCountry(myLocalCountryList[0])
        }
    }, [countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

    useEffect(() => {
        if (addLeadDetail?.addLeadDetailPayload?.email && !validateEmail(addLeadDetail?.addLeadDetailPayload?.email)) {
            setEmailAddressError("Invalid Email")
        }
        if (addLeadDetail?.addLeadDetailPayload?.email && validateEmail(addLeadDetail?.addLeadDetailPayload?.email)) {
            setEmailAddressError("")
        }
    }, [addLeadDetail?.addLeadDetailPayload])

    useEffect(() => {
        if (mobileNumber && !validateMobileNo(mobileNumber)) {
            setMobileNoError("Invalid Phone Number")
        }
        if (mobileNumber && validateMobileNo(mobileNumber)) {
            setMobileNoError("")
        }
    }, [mobileNumber])

    const onSelectIsdCode = (option) => {
        const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option.value)
        setMyCountry(selectedCountry)
    }

    const onSubmit = (event) => {
        event.preventDefault()

        if (emailAddressError || mobileNoError || addLeadDetail?.isLoading) return;

        const body = {
            firstName: addLeadDetail?.addLeadDetailPayload?.firstName,
            lastName: addLeadDetail?.addLeadDetailPayload?.lastName,
            email: addLeadDetail?.addLeadDetailPayload?.email,
            isEmailVerified: false,
            classFor: addLeadDetail?.addLeadDetailPayload?.journey,
            contactTime: addLeadDetail?.addLeadDetailPayload?.contactTime,
            timeZone: timeZone,
            actionPageLink: addLeadDetail?.addLeadDetailPayload?.actionPageLink
        }
        if (addLeadDetail?.addLeadDetailPayload?.level) {
            body["grade"] = addLeadDetail?.addLeadDetailPayload?.level
        }
        if (addLeadDetail?.addLeadDetailPayload?.subject) {
            body["topic"] = addLeadDetail?.addLeadDetailPayload?.subject
        }
        if (addLeadDetail?.addLeadDetailPayload?.mobileNo) {
            body["mobileNo"] = addLeadDetail?.addLeadDetailPayload?.mobileNo
        }

        dispatcher(createLeadDetail(body))
    }

    return (
        <form className={"space-y-5 overflow-hidden"} onSubmit={onSubmit}>
            <div className={"min-h-[24rem] block space-y-8 pt-3"}>
                <div className={"flex items-center justify-center font-bodyPri font-normal text-text-800 text-lg"}>
                    {"Share your details and our friendly team member will get back to you"}
                </div>

                <div className={"flex flex-col items-center justify-center gap-5"}>

                    <div className={"w-full flex flex-col items-start justify-start md:flex-row md:items-center md:justify-start gap-5"}>

                        {/* first name container */}
                        <div className={"w-full flex flex-col items-start justify-start gap-0.5"}>
                            <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                                {"First Name"}
                                <span className={"text-lg text-red-500"}>{" *"}</span>
                            </span>
                            <input
                                type={"text"}
                                placeholder={"e.g. John"}
                                className={cn(
                                    "w-full px-3 py-2 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-md",
                                    "placeholder:text-text-500 placeholder:text-md"
                                )}
                                value={addLeadDetail?.addLeadDetailPayload?.firstName}
                                onChange={(event) => dispatcher(setAddLeadDetailPayload({
                                    ...addLeadDetail?.addLeadDetailPayload,
                                    firstName: event.target.value
                                }))}
                                required
                            />
                        </div>

                        {/* last name container */}
                        <div className={"w-full flex flex-col items-start justify-start gap-0.5"}>
                            <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                                {"Last Name"}
                                <span className={"text-lg text-red-500"}>{" *"}</span>
                            </span>
                            <input
                                type={"text"}
                                placeholder={"e.g. Doe"}
                                className={cn(
                                    "w-full px-3 py-2 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-md",
                                    "placeholder:text-text-500 placeholder:text-md"
                                )}
                                value={addLeadDetail?.addLeadDetailPayload?.lastName}
                                onChange={(event) => dispatcher(setAddLeadDetailPayload({
                                    ...addLeadDetail?.addLeadDetailPayload,
                                    lastName: event.target.value
                                }))}
                                required
                            />
                        </div>
                    </div>

                    {/* email address container */}
                    <div className={"w-full flex flex-col items-start justify-start gap-0.5"}>
                        <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                            {"Email Address"}
                            <span className={"text-lg text-red-500"}>{" *"}</span>
                        </span>
                        <input
                            type={"email"}
                            placeholder={"e.g. johndoe@gmail.com"}
                            className={cn(
                                "w-full px-3 py-2 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-md",
                                "placeholder:text-text-500 placeholder:text-md"
                            )}
                            value={addLeadDetail?.addLeadDetailPayload?.email}
                            onChange={(event) => dispatcher(setAddLeadDetailPayload({
                                ...addLeadDetail?.addLeadDetailPayload,
                                email: event.target.value
                            }))}
                            required
                        />
                        {emailAddressError &&
                            <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                {emailAddressError}
                            </span>
                        }
                    </div>

                    {/* phone number container */}
                    <div className={"w-full flex flex-col items-start justify-start gap-0.5 h-[11.5rem]"}>
                        <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                            {"Phone Number"}
                        </span>
                        <div className={"w-full px-3 py-2 flex justify-start items-center gap-1.5 rounded-md border-2 border-divider-medium focus:border-2 focus:border-text-400"}>
                            {countryList?.isLoading
                                && <FaSpinner className={"animate-spin text-primary-main text-lg"} />
                            }
                            {countryList?.countryList &&
                                <IsdCodeSelector
                                    options={countryList?.countryList?.map((country) => ({
                                        label: country?.isdCode,
                                        value: country?.masterCountryId,
                                        country: country
                                    }))}
                                    value={myCountry?.masterCountryId}
                                    onChange={(option) => onSelectIsdCode(option)}
                                />
                            }
                            <input
                                type={"number"}
                                placeholder={"0123456789"}
                                className={cn(
                                    "w-full input-number-spin-none focus:outline-none",
                                    "font-bodyPri font-normal text-text-800 text-md",
                                    "placeholder:text-text-500 placeholder:text-md"
                                )}
                                value={mobileNumber}
                                onChange={(event) => {
                                    setMobileNumber(event.target.value)
                                    dispatcher(setAddLeadDetailPayload({
                                        ...addLeadDetail?.addLeadDetailPayload,
                                        mobileNo: myCountry?.isdCode + "-" + event.target.value
                                    }))
                                }}
                            />
                        </div>
                        {mobileNoError &&
                            <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                {mobileNoError}
                            </span>
                        }
                    </div>
                </div>
            </div>
            {addLeadDetail?.errorMsg &&
                <div className={"w-full font-bodyPri font-normal text-red-500 text-sm text-center"}>
                    {addLeadDetail?.errorMsg}
                </div>
            }
            <div className={"flex justify-end px-5"}>
                <button
                    type={"submit"}
                    className={cn(
                        "px-3 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base text-secondary-main",
                        "border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                    )}
                >
                    {addLeadDetail?.isLoading &&
                        <ButtonLoader isLoading={addLeadDetail?.isLoading} />
                    }
                    {!addLeadDetail?.isLoading && "Contact Me"}
                </button>
            </div>
        </form>
    )
}

export default SpeakToUsModal