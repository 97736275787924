import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { MdEdit } from "react-icons/md";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdVerified } from "react-icons/md";
// import { FaSpinner } from "react-icons/fa";

import ButtonLoader from "components/loader/ButtonLoader";
import InputOtp from "components/inputOtp/InputOtp";

import { updateUserAuthByUserId } from "redux/auth/auth.request";
import { createSendOtp, createVerifyOtp } from 'redux/notification/notification.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setClearAddUserAuth } from "redux/auth/auth.slice";
import { setClearVerifyOtp, setClearSendOtp } from 'redux/notification/notification.slice';
import { modalConst } from "redux/local/local.const";
import { otpDeliveryMethod, otpStatus } from "redux/notification/notification.const";

import { validateEmail } from 'utils/validation-functions';

const ValidationBarEmailVerifyModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { addUserAuth } = useAppState((state) => state.auth)
    const { user } = useAppState((state) => state.user)
    const { sendOtp, verifyOtp } = useAppState((s) => s.notification)

    const dispatcher = useAppDispatcher()

    const [myOtp, setMyOtp] = useState("")
    const [isOtpError, setIsOtpError] = useState(false)
    const [isOpenEmailOtpContainer, setIsOpenEmailOtpContainer] = useState(false)
    const [emailAddress, setEmailAddress] = useState(user?.user?.email)
    const [isEmailEditMode, setIsEmailEditMode] = useState(false)

    useEffect(() => {
        if (addUserAuth?.addUserAuth) {
            dispatcher(setModal({
                ...modal,
                [modalConst.VALIDATION_BAR_EMAIL_VERIFY_MODAL.stateKey]: false
            }))
        }

        return () => {
            dispatcher(setClearAddUserAuth())
        }
    }, [addUserAuth?.addUserAuth])

    useEffect(() => {
        if (sendOtp?.sendOtp) {
            setIsOpenEmailOtpContainer(true)
        }
        if (sendOtp?.errorMsg) {
            setIsOpenEmailOtpContainer(false)
        }
    }, [sendOtp?.sendOtp])

    useEffect(() => {
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            dispatcher(setClearVerifyOtp())
            dispatcher(setClearSendOtp())
            dispatcher(updateUserAuthByUserId(
                user?.user?.userId,
                {
                    email: emailAddress
                }
            ))
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.PENDING.value) {
            toast.warning("Something went wrong")
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.EXPIRED.value) {
            toast.error("Code Expired")
            return;
        }
        if (!verifyOtp?.verifyOtp && verifyOtp?.errorMsg) {
            setIsOtpError(true)
        }
    }, [verifyOtp?.verifyOtp || verifyOtp?.errorMsg])

    const handleEditEmail = () => {
        setIsOpenEmailOtpContainer(false)
        if (isEmailEditMode) {
            if (validateEmail(emailAddress)) {
                setEmailAddress(emailAddress?.toLowerCase())
                setIsEmailEditMode(!isEmailEditMode)
            }
            else {
                toast.warning("Enter Valid Email!")
            }
        }
        else {
            setIsEmailEditMode(!isEmailEditMode)
        }
    }

    const handleEmailVerification = async () => {
        if (!emailAddress) {
            toast.warning("Please Enter Email for Verification")
            setIsEmailEditMode(true)
            return;
        }
        if (isOpenEmailOtpContainer) {
            if (myOtp?.length < 6) return;
            dispatcher(setClearVerifyOtp())
            if (sendOtp?.sendOtp?.otpId) {
                dispatcher(createVerifyOtp({
                    otpId: sendOtp?.sendOtp?.otpId,
                    otp: myOtp
                }))
            }
        }
        if (!isOpenEmailOtpContainer) {
            if (isEmailEditMode) return;
            if (validateEmail(emailAddress) && !isEmailEditMode) {
                dispatcher(createSendOtp({
                    channel: otpDeliveryMethod.EMAIL.value,
                    email: emailAddress
                }))
            }
        }
    }

    if (user?.user?.isEmailVerified) {
        return (
            <div className='flex flex-col justify-center items-center space-y-2'>
                <MdVerified className='text-4xl text-green-500' />
                <div className={"flex items-center justify-center gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                        {"Email:"}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                        {user?.user?.email}
                    </span>
                </div>
                <span className='font-bodyPri font-semibold text-lg text-text-900'>
                    {"Email verified successfully"}
                </span>
            </div>
        )
    }

    return (
        <div className={'space-y-5 overflow-x-hidden'}>
            <div className={"min-h-[10rem] block w-full space-y-10"}>
                <div className='flex flex-col items-center justify-start md:flex-row md:items-center md:justify-center gap-3'>
                    <span className='font-bodyPri font-normal text-text-700 text-base whitespace-nowrap'>
                        {"Email Address:"}
                        <span className='text-red-500'>{" * "}</span>
                    </span>
                    <div className={"flex items-center justify-start gap-5 overflow-hidden"}>
                        {!isEmailEditMode &&
                            <span className='font-bodySec font-normal text-text-900 text-base truncate'>
                                {emailAddress}
                            </span>
                        }
                        {isEmailEditMode &&
                            <input
                                type={"text"}
                                className={cn("w-full px-3 py-1 border-2 border-secondary-main border-dashed focus:outline-none",
                                    "font-bodySec font-normal text-text-900 text-base"
                                )}
                                value={emailAddress}
                                autoFocus={true}
                                onChange={(e) => setEmailAddress(e.target.value)}
                            />
                        }
                        <div className='cursor-pointer' onClick={handleEditEmail}>
                            {!isEmailEditMode && <MdEdit className='text-2xl text-text-500' />}
                            {isEmailEditMode && <AiFillCheckCircle className='text-2xl text-secondary-main' />}
                        </div>
                    </div>
                </div>
                {isOpenEmailOtpContainer &&
                    <InputOtp
                        email={emailAddress}
                        otpLength={6}
                        setMyOtp={setMyOtp}
                        isOtpError={isOtpError}
                        setIsOtpError={setIsOtpError}
                    />
                }
                {addUserAuth?.errorMsg &&
                    <div className={"flex justify-center items-center gap-1"}>
                        <span className={"w-full font-bodyPri font-bold text-red-500 text-lg whitespace-nowrap text-center"}>
                            {addUserAuth?.errorMsg}
                        </span>
                    </div>
                }
                <div className={"flex items-center justify-center"}>
                    <div className={cn(
                        "py-1.5 rounded-lg",
                        "text-text-50 font-buttons font-medium text-base w-full text-center",
                        (emailAddress && (!isEmailEditMode && !isOpenEmailOtpContainer)) && "bg-primary-main hover:bg-secondary-dark cursor-pointer",
                        (!emailAddress || isEmailEditMode || (isOpenEmailOtpContainer && myOtp?.length < 6)) && "bg-divider-medium",
                        emailAddress && (isOpenEmailOtpContainer && myOtp?.length === 6) && "bg-primary-main hover:bg-secondary-dark cursor-pointer"
                    )}
                        onClick={handleEmailVerification}
                    >
                        {(sendOtp?.isLoading || verifyOtp?.isLoading || addUserAuth?.isLoading) && <ButtonLoader isLoading={sendOtp?.isLoading || verifyOtp?.isLoading || addUserAuth?.isLoading} />}
                        {(!verifyOtp?.isLoading && !addUserAuth?.isLoading && isOpenEmailOtpContainer) && "Verify Code"}
                        {(!sendOtp?.isLoading && !addUserAuth?.isLoading && !isOpenEmailOtpContainer) && "Send Code"}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ValidationBarEmailVerifyModal;