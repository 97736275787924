import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ImSad } from "react-icons/im";
import {
    PageContentStyle,
    Container,
    PageNotFoundCard,
    PageNotFoundContentContainer,
    PageNotFoundContent,
    ErrorCodeHeading,
    ErrorContentHeading,
    SubHeading,
    HomeButtons,
    BackButton,
    HomePage
} from "./PageNotFoundStyle";

import { useTitle } from 'hooks/useTitle';

const Missing = ({ errorMsg = "404" }) => {
    const navigate = useNavigate();
    const [title, setTitle] = useTitle()

    useEffect(() => {
        setTitle({
            ...title,
            title: "Sorry, We Could Not Find This Page | Edulyte"
        })
    }, [])

    const goBack = () => navigate(-1, { replace: true });

    return (
        <PageContentStyle>
            <Container>
                <PageNotFoundCard>
                    <PageNotFoundContent>
                        <ImSad className={"text-8xl text-text-700 rounded-full"} />
                        <ErrorCodeHeading>
                            {errorMsg}
                        </ErrorCodeHeading>
                        <ErrorContentHeading>
                            {"Page Not Found"}
                        </ErrorContentHeading>
                    </PageNotFoundContent>
                    <PageNotFoundContentContainer>
                        <SubHeading>
                            {"The Page you are looking for doesn't exist or an other error occured. Go back, or head over to our homepage."}
                        </SubHeading>
                        <HomeButtons>
                            <BackButton onClick={goBack}>
                                {"Go Back"}
                            </BackButton>
                            <HomePage>
                                <Link to={"/"}>{"Visit Our Homepage"}</Link>
                            </HomePage>
                        </HomeButtons>
                    </PageNotFoundContentContainer>
                </PageNotFoundCard>
            </Container>
        </PageContentStyle>
    )
}

export default Missing;


{/* <article className="pt-[8rem] px-10">
    <div className="grid grid-cols-12 space-x-5">
        <div className="col-start-0 col-span-4 flex flex-col justify-center items-center space-y-3">
            <span className="font-bodyPri font-bold tracking-wide text-text-900 text-2xl">{errorMsg}</span>
            <p className="font-bodyPri font-bold tracking-wide text-text-900 text-2xl">Page Not Found</p>
            <span className="font-bodyPri font-normal tracking-wide text-normal text-text-800">
                {"Lorem ipsum dolor sit amet, consectetuer adipiscing elit.Aenean commodo ligula eget dolor.Aenean massa.Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.Nulla consequat massa quis enim.Donec pede justo, fringilla vel."}
            </span>
            <div className="py-5 w-full flex justify-between items-center">
                <button className="w-28 py-1.5 flex justify-center items-center border border-secondary-main rounded-full text-secondary-main font-normal font-buttons hover:bg-secondary-main hover:text-text-50 cursor-pointer">
                    <Link to={"/"}>{"Go Back"}</Link>
                </button>
                <Link to="/" className="text-primary-main font-bodyPri font-medium tracking-wide">
                    {"Visit Our Homepage"}
                </Link>
            </div>
        </div>
        <div className="col-start-5 col-span-8 w-[56rem] h-[22rem] overflow-hidden">
            <img src={pageNotFound} className={"w-full h-full object-cover"} />
        </div>
    </div>
</article> */}