import { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { MdEdit, MdVerified } from 'react-icons/md';
import { AiFillCheckCircle } from "react-icons/ai";
import { FaSpinner } from 'react-icons/fa';

import ButtonLoader from 'components/loader/ButtonLoader';
import ComponentLoader from 'components/loader/ComponentLoader';
import InputOtp from 'components/inputOtp/InputOtp';

import { createSendOtp, createVerifyOtp } from 'redux/notification/notification.request';
import { createVerifyUser } from 'redux/auth/auth.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearVerifyOtp, setClearSendOtp } from 'redux/notification/notification.slice';
import { setClearVerifyUser, setSignUpPayload } from 'redux/auth/auth.slice';
import { otpDeliveryMethod, otpStatus } from "redux/notification/notification.const";

import { validateEmail } from 'utils/validation-functions';

const VerifyAccountContainer = () => {
    const { signUp, verifyUser } = useAppState((s) => s.auth)
    const { sendOtp, verifyOtp } = useAppState((s) => s.notification)
    const { user } = useAppState((s) => s.user)

    const loadingElement = signUp?.isLoading || user?.isLoading

    const dispatcher = useAppDispatcher()

    const [myOtp, setMyOtp] = useState("")
    const [isOtpError, setIsOtpError] = useState(false)
    const [isOpenEmailOtpContainer, setIsOpenEmailOtpContainer] = useState(false)
    const [emailAddress, setEmailAddress] = useState(signUp?.payload?.email)
    const [isEmailEditMode, setIsEmailEditMode] = useState(false)

    useEffect(() => {
        if (validateEmail(signUp?.payload?.email)) {
            dispatcher(createVerifyUser(
                {
                    email: signUp?.payload?.email
                }
            ))
        }

        return () => {
            dispatcher(setClearVerifyUser())
        }
    }, [signUp?.payload?.email])

    useEffect(() => {
        if (sendOtp?.sendOtp) {
            setIsOpenEmailOtpContainer(true)
        }
        if (sendOtp?.errorMsg) {
            setIsOpenEmailOtpContainer(false)
        }
    }, [sendOtp?.sendOtp])

    useEffect(() => {
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            dispatcher(setClearVerifyOtp())
            dispatcher(setClearSendOtp())
            setIsOpenEmailOtpContainer(false)
            dispatcher(setSignUpPayload({
                ...signUp?.payload,
                isEmailVerified: true
            }))
            return;
        }
        if (verifyOtp?.verifyOtp && (verifyOtp?.verifyOtp?.status === otpStatus.PENDING.value)) {
            setIsOpenEmailOtpContainer(false)
            toast.warning("Something went wrong! Please wait!")
            return;
        }
        if (verifyOtp?.verifyOtp && (verifyOtp?.verifyOtp?.status === otpStatus.EXPIRED.value)) {
            setIsOpenEmailOtpContainer(false)
            toast.error("Code Expired")
            return;
        }
        if (!verifyOtp?.verifyOtp && verifyOtp?.errorMsg) {

            setIsOtpError(true)
            return;
        }
    }, [verifyOtp?.verifyOtp || verifyOtp?.errorMsg])

    const handleEditEmail = () => {
        if (verifyUser?.isLoading || sendOtp?.isLoading || verifyOtp?.isLoading || signUp?.isLoading) return;

        setIsOpenEmailOtpContainer(false)
        if (isEmailEditMode) {
            if (validateEmail(emailAddress)) {
                dispatcher(setSignUpPayload({
                    ...signUp?.payload,
                    email: emailAddress?.toLowerCase()
                }))
                setIsEmailEditMode(!isEmailEditMode)
            }
            else {
                toast.warning("Enter Valid Email!")
            }
        }
        else {
            setIsEmailEditMode(!isEmailEditMode)
        }
    }

    const handleEmailVerification = async () => {
        if (verifyUser?.isLoading || sendOtp?.isLoading || verifyOtp?.isLoading || signUp?.isLoading) return;

        if (isEmailEditMode || !emailAddress || !validateEmail(emailAddress)) return;
        if (isOpenEmailOtpContainer) {
            if (myOtp?.length < 6) return;
            dispatcher(setClearVerifyOtp())
            if (sendOtp?.sendOtp?.otpId) {
                dispatcher(createVerifyOtp({
                    otpId: sendOtp?.sendOtp?.otpId,
                    otp: myOtp
                }))
            }
            else {
                toast.error("Incorrect Code")
                setIsOtpError(true)
            }
        }
        if (!isOpenEmailOtpContainer) {
            if (validateEmail(emailAddress)) {
                dispatcher(createSendOtp({
                    channel: otpDeliveryMethod.EMAIL.value,
                    email: emailAddress
                }))
                return;
            }
            else {
                toast.warning("Please Enter Email for Verification")
                setIsEmailEditMode(true)
            }
        }
    }

    if (verifyOtp?.verifyOtp && (verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value)) {
        return (
            <div className='flex flex-col justify-center items-center space-y-2'>
                <MdVerified className='text-4xl text-green-500' />
                <div className={"font-bodyPri font-medium text-text-800 text-base"}>
                    {signUp?.payload?.email}
                </div>
                <span className='font-bodyPri font-semibold text-lg text-text-900'>
                    {"Email Verified Successfully!"}
                </span>
            </div>
        )
    }

    if (verifyUser?.isLoading) {
        return (
            <ComponentLoader isLoading={verifyUser?.isLoading} className={"w-full h-full items-center justify-center"} />
        )
    }

    return (
        <div className='space-y-8'>
            {(!isOpenEmailOtpContainer && !verifyUser?.verifyUser) &&
                <div className={"w-full font-bodyPri font-normal text-text-700 text-sm text-center"}>
                    {"Please use send code button to get a verification code by email. We will then create your shiny new dashboard."}
                </div>
            }
            {(!isOpenEmailOtpContainer && verifyUser?.verifyUser) &&
                <div className={"w-full font-bodyPri font-normal text-text-700 text-sm text-center"}>
                    {"We found an account with this email, please send code to login."}
                </div>
            }
            {isOpenEmailOtpContainer &&
                <span className={"w-full font-bodyPri font-normal text-text-800 text-sm tracking-wide text-center"}>
                    {"We’ve emailed a verification code! Please check your email, enter the code to proceed. Remember to check your spam folder, just in case."}
                </span>
            }
            <div className='flex flex-col items-center justify-center md:flex-row gap-3'>
                <div className='font-bodyPri font-normal text-text-900 text-base whitespace-nowrap'>
                    {"Email Address:"}
                    <span className='text-red-500'>{" * "}</span>
                </div>
                <div className={"flex items-center justify-start gap-3 overflow-hidden"}>
                    {!isEmailEditMode &&
                        <span className='font-bodySec font-semibold text-text-900 text-base truncate'>
                            {emailAddress?.length > 25
                                ? emailAddress?.slice(0, 25) + "..."
                                : emailAddress
                            }
                        </span>
                    }
                    {isEmailEditMode &&
                        <input
                            type={"text"}
                            className={cn("w-full px-3 py-1 border-2 border-secondary-main border-dashed focus:outline-none",
                                "font-bodySec font-normal text-text-900 text-base"
                            )}
                            value={emailAddress}
                            autoFocus={true}
                            onChange={(e) => setEmailAddress(e.target.value)}
                        />
                    }
                    <div className='cursor-pointer' onClick={handleEditEmail}>
                        {!isEmailEditMode && <MdEdit className='text-2xl text-text-500' />}
                        {isEmailEditMode && <AiFillCheckCircle className='text-2xl text-secondary-main' />}
                    </div>
                </div>
            </div>
            {isOpenEmailOtpContainer &&
                <div className='flex flex-col items-center justify-start gap-5'>
                    <InputOtp
                        email={emailAddress}
                        otpLength={6}
                        setMyOtp={setMyOtp}
                        isOtpError={isOtpError}
                        setIsOtpError={setIsOtpError}
                    />
                    {verifyOtp?.errorMsg &&
                        <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                            {verifyOtp?.errorMsg}
                        </span>
                    }
                </div>
            }
            <div className={"flex items-center justify-center"}>
                <div className={cn(
                    "py-1.5 rounded-lg",
                    "text-text-50 font-buttons font-medium text-base w-full text-center",
                    (emailAddress && !isEmailEditMode && !isOpenEmailOtpContainer) && "bg-primary-main hover:bg-secondary-dark cursor-pointer",
                    (isEmailEditMode || (isOpenEmailOtpContainer && myOtp?.length < 6) || !emailAddress) && "bg-divider-medium",
                    isOpenEmailOtpContainer && myOtp?.length === 6 && "bg-primary-main hover:bg-secondary-dark cursor-pointer"
                )}
                    onClick={handleEmailVerification}
                >
                    {(sendOtp?.isLoading || verifyOtp?.isLoading) && <ButtonLoader isLoading={sendOtp?.isLoading || verifyOtp?.isLoading} />}
                    {!verifyOtp?.isLoading && isOpenEmailOtpContainer && "Verify Code"}
                    {!sendOtp?.isLoading && !isOpenEmailOtpContainer && "Send Code"}
                </div>
            </div>

            {loadingElement &&
                <div className={"flex justify-center items-center gap-1"}>
                    <div className={"px-5 animate-spin"}>
                        <FaSpinner className={"text-primary-main text-2xl"} />
                    </div>
                    <span className={"w-full font-bodyPri font-bold text-primary-main text-lg whitespace-nowrap"}>
                        {"Loading..."}
                    </span>
                </div>
            }
        </div>
    )
}

export default VerifyAccountContainer;