import { useEffect } from "react";

import { setGetLessonPref } from "pages/auth/onboarding/data";

import ComponentLoader from 'components/loader/ComponentLoader'
import { ToggleWithText } from "components/common-components/Toggle";

import { getTutorUserLessonByUserId, updateTutorUserLessonByUserId } from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearTutorUserLesson } from "redux/tutor/tutor.slice";

const MyLessons = () => {
    const { tutorUserLesson, addTutorUserLesson } = useAppState((s) => s.tutor)
    const { user } = useAppState((s) => s.user)

    const dispatcher = useAppDispatcher();

    useEffect(() => {
        if (user?.user?.userId) {
            dispatcher(getTutorUserLessonByUserId(user?.user?.userId))
        }

        return () => {
            dispatcher(setClearTutorUserLesson())
        }
    }, [user?.user])


    const updateLessonStatus = (key, value) => {
        if (key === "autoConfirmLesson") {
            const lessonPref = { [key]: value }
            dispatcher(updateTutorUserLessonByUserId(user?.user?.userId, lessonPref))
        }
    }

    if (tutorUserLesson?.isLoading || addTutorUserLesson?.isLoading) {
        return (
            <ComponentLoader
                isLoading={tutorUserLesson?.isLoading || addTutorUserLesson?.isLoading}
                className={"h-40 flex items-center justify-center"}
            />
        )
    }

    return (
        <div className="space-y-2 py-5">
            {tutorUserLesson?.tutorUserLesson &&
                setGetLessonPref(tutorUserLesson?.tutorUserLesson)?.map((lesson, index) => (
                    <ToggleWithText
                        key={index}
                        description={lesson.description}
                        onChange={(isToggle) => updateLessonStatus(lesson.key, isToggle)}
                        title={lesson.label}
                        value={lesson.value}
                    />
                ))}
            {(tutorUserLesson?.errorMsg || addTutorUserLesson?.errorMsg) &&
                <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                    {tutorUserLesson?.errorMsg || addTutorUserLesson?.errorMsg}
                </div>
            }
        </div>
    )
}

export default MyLessons