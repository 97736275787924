import { GrNotes } from "react-icons/gr";

import FadedCollapse from "components/fadedCollapse";

function ContactNotes({ contact }) {
    return !!contact?.contact_notes?.length && (
        <div className={"flex flex-col w-full gap-3 border rounded-lg p-5"}>
            <div className={"flex items-center gap-3 px-1 text-text-800"}>
                <GrNotes />
                <p className={"font-medium"}>
                    {"Notes"}
                </p>
            </div>
            {!!contact?.contact_notes?.length &&
                <FadedCollapse
                    isShowMore={contact?.contact_notes?.length > 1}
                    maxHeight={(contact?.contact_notes?.length > 1) ? "7rem" : "5rem"}
                >
                    {contact?.contact_notes?.map((note, index) => (
                        <div
                            key={index}
                            className={"space-y-1 w-full bg-background-lightBlue rounded-lg p-3"}
                        >
                            <h2 className={"text-text-900 font-medium text-sm"}>
                                {note?.title}
                            </h2>
                            <p className={"text-text-800 text-sm"}>
                                {note?.note}
                            </p>
                        </div>
                    ))}
                </FadedCollapse>
            }
        </div>
    );
}

export default ContactNotes;
