import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import format from 'string-format';
import { cn } from "utils/cn.utils";

import { FaSpinner } from 'react-icons/fa';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setAddBookingPayload, setClearAddBooking, setAddBookingErrorMsg } from 'redux/booking/booking.slice';
import { addWalletCredit } from 'redux/wallet/wallet.request';
import { oldMakeAddBooking } from 'redux/booking/booking.request';
import { setClearModal } from 'redux/local/local.slice';
import { setClearMakePayment } from 'redux/payment/payment.slice';
import { setClearAddCreditToWallet } from 'redux/wallet/wallet.slice';
import { paymentIntentStatusEnum } from 'redux/payment/payment.const';
import { getMyProfile } from 'redux/user/user.request';

import { currencyRateConversion } from 'utils/generators.utils';

import { Toggle } from "components/common-components/Toggle";

import MakePaymentFooter from 'components/modals/makePaymentModal/MakePaymentFooter';
import OrderSummery from 'components/modals/makePaymentModal/OrderSummery';

const bookingConfirmationUrl = "/booking/confirmation?status={status}&bookingId={bookingId}"


const MakePaymentModal = () => {
    const { locals } = useAppState(s => s.local)
    const { addBooking } = useAppState(s => s.booking)
    const addBookingPayload = addBooking?.addBookingPayload
    const { makePayment } = useAppState(s => s.payment)
    const { addCreditToWallet } = useAppState(s => s.wallet)
    const { user } = useAppState(s => s.user)
    const { session } = useAppState(s => s.auth)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    useEffect(() => {
        if (makePayment?.makePaymentConfirm?.status === paymentIntentStatusEnum.SUCCEEDED.value) {
            dispatcher(addWalletCredit())
            dispatcher(setClearMakePayment())
        } else if (makePayment?.makePaymentConfirm) {
            dispatcher(setClearMakePayment())
            dispatcher(setClearAddCreditToWallet())
            dispatcher(setAddBookingErrorMsg("Something went wrong"))
        }
    }, [makePayment?.makePaymentConfirm])

    useEffect(() => {
        if (addCreditToWallet?.addCreditToWallet) {
            dispatcher(setAddBookingPayload({
                ...addBookingPayload,
                confirm: true
            }))
            dispatcher(oldMakeAddBooking())
        }
    }, [addCreditToWallet?.addCreditToWallet])

    useEffect(() => {
        if (addBooking?.addBooking) {
            const query = {
                status: addBooking?.addBooking?.status,
                bookingId: addBooking?.addBooking?.bookingId
            }
            dispatcher(setClearAddBooking())
            dispatcher(setClearAddCreditToWallet())
            dispatcher(setClearMakePayment())
            dispatcher(setClearModal())
            navigate(format(bookingConfirmationUrl, query))
        }
    }, [addBooking?.addBooking])

    useEffect(() => {
        dispatcher(getMyProfile())
    }, [])

    useEffect(() => {
        setAmountToPay()
    }, [addBookingPayload?.isWalletAdded, addBookingPayload?.isPromoCreditUsed])

    const setAmountToPay = () => {
        let promoCreditUsed = 0
        let walletAmountToPay = 0
        let gatewayAmountToPay = 0

        let promoAmountToBeUsed = parseFloat(addBookingPayload?.purchasedPrice * user?.user?.promoAmountUseOnBookingPct) / 100

        if (session?.isLoggedIn && user?.user && addBookingPayload?.isPromoCreditUsed) {
            promoCreditUsed = (user?.user?.netPromoCredit - promoAmountToBeUsed) > 0 ? promoAmountToBeUsed : user?.user?.netPromoCredit
        }

        if (session?.isLoggedIn && user?.user && addBookingPayload?.isWalletAdded) {
            const gatewayAmount = addBookingPayload?.purchasedPrice - (user?.user?.netWalletCredit + promoCreditUsed)
            if (gatewayAmount > 0) {
                walletAmountToPay = user?.user?.netWalletCredit
                gatewayAmountToPay = gatewayAmount
            } else {
                walletAmountToPay = addBookingPayload?.purchasedPrice - promoCreditUsed
                gatewayAmountToPay = 0
            }
        } else {
            walletAmountToPay = 0
            gatewayAmountToPay = addBookingPayload?.purchasedPrice - promoCreditUsed
        }
        dispatcher(setAddBookingPayload({
            ...addBookingPayload,
            walletAmountToPay: walletAmountToPay,
            promoCreditUsed: promoCreditUsed,
            gatewayAmountToPay: gatewayAmountToPay,
        }))
    }

    return (
        <div className={"space-y-5"}>
            <div className={"min-h-[24rem] block space-y-3 pt-5"}>
                <div className={"flex items-start justify-between"}>
                    <span className={"font-bodyPri font-medium text-lg text-text-800"}>
                        {"Sub total"}
                    </span>
                    <div className={"flex flex-col items-end justify-center"}>
                        <div className={"whitespace-nowrap flex items-center gap-1 font-bodyPri font-medium text-lg text-text-900"}>
                            <span>{"USD"}</span>
                            <span>{parseFloat(addBookingPayload?.purchasedPrice / 100).toFixed(2)}</span>
                        </div>
                        <div className={"whitespace-nowrap flex items-center gap-1 font-bodyPri font-normal text-sm text-text-800"}>
                            <span>{"Approx"} {currencyRateConversion(locals?.currencyCode, addBookingPayload?.purchasedPrice)}</span>
                        </div>
                    </div>
                </div>

                {(session?.isLoggedIn && user?.user) && <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>}

                {(session?.isLoggedIn && user?.user) &&
                    <div className={"w-full flex items-center justify-between gap-3"}>
                        <span className={"font-bodyPri font-normal text-sm text-text-800 truncate whitespace-nowrap"}>
                            {"My available balance"}
                        </span>
                        <div className={"flex items-center justify-end gap-2"}>
                            <div className={"flex items-center justify-start gap-1 font-bodyPri font-medium text-base text-text-900"}>
                                <span>{"USD"}</span>
                                <span>{parseFloat(user?.user?.netWalletCredit / 100).toFixed(2)}</span>
                            </div>
                            <Toggle
                                value={addBookingPayload?.isWalletAdded}
                                onChange={(isToggle) => dispatcher(setAddBookingPayload({ ...addBookingPayload, isWalletAdded: isToggle }))}
                            />
                        </div>
                    </div>
                }

                {(session?.isLoggedIn && user?.user) &&
                    <div className={"w-full flex items-center justify-between gap-3"}>
                        <div classList={"flex items-center justify-start gap-2"}>
                            <input
                                type={"checkbox"}
                                checked={addBookingPayload?.isPromoCreditUsed}
                                onClick={(event) => dispatcher(setAddBookingPayload({ ...addBookingPayload, isPromoCreditUsed: event?.target?.checked }))}
                                className={"cursor-pointer text-primary-dark mr-2"}
                            />
                            <span className={cn(
                                "font-bodyPri font-normal text-sm text-text-800 truncate whitespace-nowrap",
                                addBookingPayload?.isPromoCreditUsed && "text-green-500"
                            )}>
                                {"Applied Promo balance"}
                            </span>
                        </div>
                        <div className={"flex items-center justify-end gap-2"}>
                            <div className={cn(
                                "flex items-center justify-start gap-1 font-bodyPri font-normal text-sm text-text-900",
                                addBookingPayload?.isPromoCreditUsed && "text-green-500"
                            )}>
                                <span>{"USD"}</span>
                                <span>{parseFloat(addBookingPayload?.promoCreditUsed / 100).toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                }

                <div className={""}>
                    <OrderSummery />
                </div>

                <div className={"w-full h-10"}>
                    {(makePayment?.errorMsg || addCreditToWallet?.errorMsg || addBooking?.errorMsg) &&
                        <div className={"flex items-center justify-start"}>
                            <span className={"font-bodyPri font-medium text-base text-red-500 text-center"}>
                                {makePayment?.errorMsg || addCreditToWallet?.errorMsg || addBooking?.errorMsg}
                            </span>
                        </div>
                    }
                    {(makePayment?.isLoading || addCreditToWallet?.isLoading || addBooking?.isLoading) &&
                        <div classsName={"flex items-center justify-start gap-2 whitespace-nowrap"}>
                            <span className={"font-bodyPri font-normal text-base text-text-700 whitespace-nowrap"}>
                                {"Please wait while processing ..."}
                            </span>
                            <FaSpinner className="inline-flex text-primary-main animate-spin" />
                        </div>
                    }
                </div>

            </div>
            <div className={"w-full"}>
                <MakePaymentFooter />
            </div>
        </div>
    )
}

export default MakePaymentModal;