import { memo, useCallback, useMemo, useState } from 'react';

import { RiCheckboxBlankCircleFill, RiCheckboxBlankCircleLine } from 'react-icons/ri';

import DownloadTooltip from 'pages/auth/editDownload/commonComponents/DownloadTooltip'
import DownloadPreviewModal from 'pages/auth/editDownload/commonComponents/DownloadPreviewModal';
import { previewItemActionButtons } from 'pages/auth/editDownload/data'

import { deleteDownloadPreviewDetail, updateUserDownloadDetail } from 'redux/downloads/downloads.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { downloadPreviewTypeEnum } from "redux/downloads/downloads.const";

import { cn } from 'utils/cn.utils';

const DownloadPreviewItem = memo(({ index, previewItem, defaultPreviewId }) => {
    const ownerUserDownloadDetail = useAppState((state) => state.downloads.ownerUserDownloadDetail)
    const destroyDownloadPreviewDetail = useAppState((state) => state.downloads.destroyDownloadPreviewDetail)

    const dispatcher = useAppDispatcher()

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [activePreviewIndex, setActivePreviewIndex] = useState(0)
    const [hoverItem, setHoverItem] = useState(null)

    const sliderData = useMemo(() => {
        let previews = []
        if (!!ownerUserDownloadDetail?.data?.previews?.length) {
            previews = [ownerUserDownloadDetail?.data?.preview, ...ownerUserDownloadDetail?.data?.previews?.filter(item => item?.id !== ownerUserDownloadDetail?.data?.preview?.id)]
        }
        return previews;
    }, [ownerUserDownloadDetail?.data])

    const isPreviewItemImage = useMemo(() => {
        if ([downloadPreviewTypeEnum?.IMAGE?.value]?.includes(previewItem?.type)) {
            return true;
        }
    }, [previewItem])

    const isPreviewItemVideo = useMemo(() => {
        if ([downloadPreviewTypeEnum.VIDEO.value]?.includes(previewItem?.type)) {
            return true;
        }
    }, [previewItem])

    const previewActions = useMemo(() => {
        let previewActionItems = Object.values(previewItemActionButtons)
        if (previewItem?.id === defaultPreviewId) {
            previewActionItems = Object.values(previewItemActionButtons).filter((items) => (items?.value === previewItemActionButtons.PREVIEW.value))
        }
        if (previewItem?.type === downloadPreviewTypeEnum.VIDEO.value) {
            previewActionItems = Object.values(previewItemActionButtons).filter((items) => (items?.value !== previewItemActionButtons.MAKE_DEFAULT.value))
        }

        return previewActionItems;
    }, [previewItem, defaultPreviewId])

    const onHandlePreviewItem = useCallback((selectedItem) => {
        if (selectedItem === previewItemActionButtons.MAKE_DEFAULT.value) {
            if (previewItem?.id === defaultPreviewId) return;
            dispatcher(updateUserDownloadDetail(ownerUserDownloadDetail?.data?.id, { preview_id: previewItem?.id }))
        } else if (selectedItem === previewItemActionButtons.PREVIEW.value) {
            if (!previewItem?.preview_url) return;
            setActivePreviewIndex(index)
            setIsOpenModal(true)
        } else {
            if (destroyDownloadPreviewDetail?.isLoading) return;
            dispatcher(deleteDownloadPreviewDetail(previewItem?.id))
        }
    }, [previewItem, destroyDownloadPreviewDetail?.isLoading])

    return (
        <>
            <div
                key={index}
                className={cn(
                    "relative flex flex-col content-between justify-between overflow-hidden rounded-lg shadow-lg h-32 aspect-video text-xs cursor-pointer",
                    "hover:scale-105 hover:transition duration-200 hover:shadow-lg",
                    "ring-offset-2 ring-4 ring-white hover:ring-offset-2 hover:ring-4 hover:ring-secondary-dark",
                    (previewItem?.id === defaultPreviewId) && "ring-offset-2 ring-4 ring-secondary-dark"
                )}
                onMouseOver={() => {
                    if (!isPreviewItemImage && !isPreviewItemVideo) return;
                    setHoverItem(index)
                }}
                onMouseOut={() => setHoverItem(null)}
            >
                {isPreviewItemImage &&
                    <img
                        src={previewItem?.preview_url}
                        alt={previewItem?.title}
                        className={"w-full h-full object-cover rounded-lg cursor-zoom-in"}
                        onClick={() => onHandlePreviewItem(previewItemActionButtons.PREVIEW.value)}
                    />
                }
                {isPreviewItemVideo &&
                    <video
                        className={'w-full h-full object-cover rounded-lg cursor-zoom-in'}
                        src={previewItem?.preview_url}
                        alt={previewItem?.title}
                        allowFullScreen={true}
                        controls={isOpenModal ? false : true}
                        onClick={() => onHandlePreviewItem(previewItemActionButtons.PREVIEW.value)}
                    />
                }
                {((!isPreviewItemImage && !isPreviewItemVideo) || !previewItem?.preview_url) &&
                    <div className={"absolute inset-0 z-50 rounded-lg overflow-hidden flex items-center justify-center"}>
                        <span className={"bg-black bg-opacity-50 px-2 py-1 rounded-sm text-text-50 font-bodyPri font-normal text-xs"}>
                            {"No Preview Available"}
                        </span>
                    </div>
                }
                {((hoverItem === index) && previewItem?.preview_url) &&
                    <div className={"absolute inset-0 z-20 rounded-lg overflow-hidden flex items-center justify-center"}>
                        <div className={"px-2 py-0.5 flex items-center justify-center gap-2 bg-white rounded-lg shadow-md"}>
                            {Object.values(previewActions)?.map((itemIcon, idx) => (
                                <DownloadTooltip
                                    key={idx}
                                    tooltip={itemIcon?.tooltip}
                                    children={
                                        <span className={"space-x-2"}>
                                            {(itemIcon?.value === previewItemActionButtons.MAKE_DEFAULT.value)
                                                ? <button className={"p-2 rounded-full hover:bg-divider-darkLight group"} onClick={() => onHandlePreviewItem(itemIcon?.value)}>
                                                    {(previewItem?.id === defaultPreviewId)
                                                        ? <RiCheckboxBlankCircleFill className={"text-lg bg-primary-dark"} />
                                                        : <RiCheckboxBlankCircleLine className={"text-lg text-text-700"} />
                                                    }
                                                    {itemIcon.icon}
                                                </button>
                                                : <button className={"p-2 rounded-full hover:bg-divider-darkLight group"} onClick={() => onHandlePreviewItem(itemIcon?.value)}>
                                                    {itemIcon.icon}
                                                </button>
                                            }
                                        </span>
                                    }
                                />
                            ))}
                        </div>
                    </div>
                }
            </div>
            <DownloadPreviewModal
                isOpenModal={isOpenModal}
                sliderData={sliderData}
                overlaySlide={activePreviewIndex}
                setOverlaySlide={setActivePreviewIndex}
                onHandleCloseModal={() => {
                    setActivePreviewIndex(0)
                    setIsOpenModal(false)
                }}
            />
        </>
    )
})

export default DownloadPreviewItem;