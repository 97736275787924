import { toast } from "react-toastify";

import tutorService from "redux/tutor/tutor.services"
import {
    setTutorListLoading,
    setTutorList,
    setTutorListErrorMsg,

    setTutorPublicProfileLoading,
    setTutorPublicProfile,
    setTutorPublicProfileErrorMsg,

    setMyTutorListLoading,
    setMyTutorList,
    setMyTutorListErrorMsg,

    setFavTutorListLoading,
    setFavTutorList,
    setFavTutorListErrorMsg,

    setAddFavTutorLoading,
    setAddFavTutorErrorMsg,

    setTutorUserDetailLoading,
    setTutorUserDetail,
    setTutorUserDetailErrorMsg,

    setAddTutorUserDetailLoading,
    setAddTutorUserDetail,
    setAddTutorUserDetailErrorMsg,

    setTutorUserPriceLoading,
    setTutorUserPrice,
    setTutorUserPriceErrorMsg,

    setAddTutorUserPriceLoading,
    setAddTutorUserPriceErrorMsg,

    setTutorUserLessonLoading,
    setTutorUserLesson,
    setTutorUserLessonErrorMsg,

    setAddTutorUserLessonLoading,
    setAddTutorUserLessonErrorMsg,

    setTutorUserLanguageListLoading,
    setTutorUserLanguageList,
    setTutorUserLanguageListErrorMsg,

    setAddTutorLanguageDetailLoading,
    setAddTutorLanguageDetailErrorMsg,

    setTutorUserCategoryListLoading,
    setTutorUserCategoryList,
    setTutorUserCategoryListErrorMsg,

    setAddTutorCategoryDetailLoading,
    setAddTutorCategoryDetailErrorMsg,

    // new modified apis
    setTutorLanguageListLoading,
    setTutorLanguageListData,
    setTutorLanguageListMessage,

    // setAddTutorLanguageDetailLoading,
    setAddTutorLanguageDetailMessage,

    setUpdateTutorLanguageDetailLoading,
    setUpdateTutorLanguageDetailMessage,

    setUpdateTutorLanguageListOrderLoading,
    setUpdateTutorLanguageListOrderMessage,

    setDeleteTutorLanguageDetailLoading,
    setDeleteTutorLanguageDetailMessage,

    setTutorTagListLoading,
    setTutorTagListData,
    setTutorTagListMessage,

    setAddTutorTagDetailLoading,
    setAddTutorTagDetailMessage,

    setUpdateTutorTagListOrderLoading,
    setUpdateTutorTagListOrderMessage,

    setDeleteTutorTagDetailLoading,
    setDeleteTutorTagDetailMessage,

    setTutorCategoryListLoading,
    setTutorCategoryListData,
    setTutorCategoryListMessage,

    // setAddTutorCategoryDetailLoading,
    setAddTutorCategoryDetailMessage,

    setUpdateTutorCategoryDetailLoading,
    setUpdateTutorCategoryDetailMessage,

    setUpdateTutorCategoryListOrderLoading,
    setUpdateTutorCategoryListOrderMessage,

    setDeleteTutorCategoryDetailLoading,
    setDeleteTutorCategoryDetailMessage,

    // new tutor onboarding tutor apis
    setTutorDetailLoading,
    setTutorDetailData,
    setTutorDetailMessage,

    setAddTutorDetailLoading,
    setAddTutorDetailData,
    setAddTutorDetailMessage,

    setUpdateTutorDetailLoading,
    setUpdateTutorDetailMessage,
} from "redux/tutor/tutor.slice"
import { getMyProfile } from "redux/user/user.request";

import { setUser } from "redux/user/user.slice";

// request for public tutor list
export const getTutorPublicList = (query) => async (dispatch) => {
    dispatch(setTutorListLoading(true))
    const requestData = {
        query: query
    }
    try {
        const response = await tutorService.getTutorPublicList(requestData)
        if (response.status === 200) {
            dispatch(setTutorList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setTutorListErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setTutorListLoading(false))
    }
}

// request for tutor public profile by user id
export const getTutorUserPublicProfileByUserId = (userId) => async (dispatch) => {
    dispatch(setTutorPublicProfileLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await tutorService.getTutorUserPublicProfileByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorPublicProfile(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setTutorPublicProfileErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setTutorPublicProfileLoading(false))
    }
}

// request fro get my tutor list by user id 
export const getMyTutorUserListByUserId = (userId, query) => async (dispatch) => {
    dispatch(setMyTutorListLoading(true))

    try {
        const requestData = {
            query: query,
            params: { userId: userId }
        }
        const response = await tutorService.getMyTutorUserListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setMyTutorList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setMyTutorListErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setMyTutorListLoading(false))
    }
}

// request for get fav tutor list by user id
export const getFavTutorUserListByUserId = (userId, query) => async (dispatch) => {
    dispatch(setFavTutorListLoading(true))

    try {
        const requestData = {
            query: query,
            params: { userId: userId }
        }
        const response = await tutorService.getFavTutorUserListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setFavTutorList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setFavTutorListErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setFavTutorListLoading(false))
    }
}

// request for add fav tutor by user id
export const createFavTutorUserDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddFavTutorLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await tutorService.createFavTutorUserDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUser({
                ...user?.user,
                favoriteTutors: [...user?.user?.favoriteTutors, { tutorId: response.data.data.tutorId }]
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddFavTutorErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddFavTutorLoading(false))
    }
}

// request for delete fav tutor by user id
export const deleteFavTutorUserDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddFavTutorLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await tutorService.deleteFavTutorUserDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUser({
                ...user?.user,
                favoriteTutors: user?.user?.favoriteTutors?.filter((tutor) => tutor?.tutorId !== body.tutorId)
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddFavTutorErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddFavTutorLoading(false))
    }
}

// request for tutor detail by user id
export const getTutorUserDetailByUserId = (userId) => async (dispatch) => {
    dispatch(setTutorUserDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await tutorService.getTutorUserDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setTutorUserDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setTutorUserDetailLoading(false))
    }
}

// request for update tutor detail by user id
export const updateTutorUserDetailByUserId = (userId, body) => async (dispatch) => {
    dispatch(setAddTutorUserDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await tutorService.updateTutorUserDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setAddTutorUserDetail(response.data.data))
            dispatch(setTutorUserDetail(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddTutorUserDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddTutorUserDetailLoading(false))
    }
}

// request for tutor price by user id
export const getTutorUserPriceByUserId = (userId) => async (dispatch) => {
    dispatch(setTutorUserPriceLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await tutorService.getTutorUserPriceByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserPrice(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setTutorUserPriceErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setTutorUserPriceLoading(false))
    }
}

// request for update tutor price by user id
export const updateTutorUserPriceByUserId = (userId, body) => async (dispatch) => {
    dispatch(setAddTutorUserPriceLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await tutorService.updateTutorUserPriceByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserPrice(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddTutorUserPriceErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddTutorUserPriceLoading(false))
    }
}

// request for tutor lesson by user id
export const getTutorUserLessonByUserId = (userId) => async (dispatch) => {
    dispatch(setTutorUserLessonLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await tutorService.getTutorUserLessonByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserLesson(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setTutorUserLessonErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setTutorUserLessonLoading(false))
    }
}

// request for update tutor lesson by user id
export const updateTutorUserLessonByUserId = (userId, body) => async (dispatch) => {
    dispatch(setAddTutorUserLessonLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await tutorService.updateTutorUserLessonByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserLesson(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong")
        dispatch(setAddTutorUserLessonErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddTutorUserLessonLoading(false))
    }
}

// request for tutor language list
export const getTutorUserLanguageListByUserId = (userId) => async (dispatch) => {
    dispatch(setTutorUserLanguageListLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await tutorService.getTutorUserLanguageListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserLanguageList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setTutorUserLanguageListErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setTutorUserLanguageListLoading(false))
    }
}

// request for add tutor language detail
export const createTutorUserLanguageDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddTutorLanguageDetailLoading(true))

    try {
        const { tutorUserLanguageList } = getState().tutor
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await tutorService.createTutorUserLanguageDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserLanguageList([
                ...tutorUserLanguageList?.tutorUserLanguageList,
                response.data.data
            ]))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddTutorLanguageDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddTutorLanguageDetailLoading(false))
    }
}

// request for update tutor language detail
export const updateTutorLanguageDetailByTutorLanguageId = (tutorLanguageId, body) => async (dispatch, getState) => {
    dispatch(setAddTutorLanguageDetailLoading(true))

    try {
        const { tutorUserLanguageList } = getState().tutor
        const requestData = {
            params: { tutorLanguageId: tutorLanguageId },
            body: body
        }
        const response = await tutorService.updateTutorLanguageDetailByTutorLanguageId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserLanguageList(
                tutorUserLanguageList?.tutorUserLanguageList.map(tutorUserLanguage => (
                    tutorUserLanguage.tutorLanguageId === tutorLanguageId ? response.data.data : tutorUserLanguage
                ))
            ))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddTutorLanguageDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddTutorLanguageDetailLoading(false))
    }
}

export const deleteTutorLanguageDetailByTutorLanguageId = (tutorLanguageId) => async (dispatch, getState) => {
    dispatch(setAddTutorLanguageDetailLoading(true))

    try {
        const { tutorUserLanguageList } = getState().tutor
        const requestData = {
            params: { tutorLanguageId: tutorLanguageId }
        }
        const response = await tutorService.deleteTutorLanguageDetailByTutorLanguageId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserLanguageList(
                tutorUserLanguageList?.tutorUserLanguageList.filter(tutorUserLanguage => (
                    tutorUserLanguage.tutorLanguageId !== tutorLanguageId
                ))
            ))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddTutorLanguageDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddTutorLanguageDetailLoading(false))
    }
}

// request for tutor category list 
export const getTutorUserCategoryListByUserId = (userId) => async (dispatch) => {
    dispatch(setTutorUserCategoryListLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await tutorService.getTutorUserCategoryListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserCategoryList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setTutorUserCategoryListErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setTutorUserCategoryListLoading(false))
    }
}

// request for add tutor category detail
export const createTutorUserCategoryDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddTutorCategoryDetailLoading(false))

    try {
        const { tutorUserCategoryList } = getState().tutor
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await tutorService.createTutorUserCategoryDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserCategoryList([
                ...tutorUserCategoryList?.tutorUserCategoryList,
                response.data.data
            ]))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddTutorCategoryDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddTutorCategoryDetailLoading(false))
    }
}

// request for update tutor category detail
export const updateTutorCategoryDetailByTutorCategoryId = (tutorCategoryId, body) => async (dispatch, getState) => {
    dispatch(setAddTutorCategoryDetailLoading(false))

    try {
        const { tutorUserCategoryList } = getState().tutor
        const requestData = {
            params: { tutorCategoriesId: tutorCategoryId },
            body: body
        }
        const response = await tutorService.updateTutorCategoryDetailByTutorCategoryId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserCategoryList(
                tutorUserCategoryList?.tutorUserCategoryList.map(tutorUserCategory => (
                    tutorUserCategory?.tutorCategoryId === tutorCategoryId ? response.data.data : tutorUserCategory
                ))
            ))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddTutorCategoryDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddTutorCategoryDetailLoading(false))
    }
}

// request for delete tutor category detail
export const deleteTutorCategoryDetailByTutorCategoryId = (tutorCategoryId) => async (dispatch, getState) => {
    dispatch(setAddTutorCategoryDetailLoading(false))

    try {
        const { tutorUserCategoryList } = getState().tutor
        const requestData = {
            params: { tutorCategoriesId: tutorCategoryId }
        }
        const response = await tutorService.deleteTutorCategoryDetailByTutorCategoryId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserCategoryList(
                tutorUserCategoryList?.tutorUserCategoryList.filter(tutorUserCategory => (
                    tutorUserCategory?.tutorCategoryId !== tutorCategoryId
                ))
            ))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddTutorCategoryDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddTutorCategoryDetailLoading(false))
    }
}

// new modified tutor language apis
export const getTutorLanguageList = (query) => async (dispatch) => {
    dispatch(setTutorLanguageListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await tutorService.getTutorLanguageList(requestData)
        if (response.status === 200) {
            dispatch(setTutorLanguageListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setTutorLanguageListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setTutorLanguageListLoading(false))
    }
}

export const createTutorLanguageDetail = (body) => async (dispatch) => {
    dispatch(setAddTutorLanguageDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await tutorService.createTutorLanguageDetail(requestData)
        if (response.status === 200) {
            dispatch(getTutorLanguageList({ tutorId: body?.tutorId }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddTutorLanguageDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setAddTutorLanguageDetailLoading(false))
    }
}

export const updateTutorLanguageDetail = (tutorLanguageId, body) => async (dispatch, getState) => {
    dispatch(setUpdateTutorLanguageDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { tutorLanguageId: tutorLanguageId },
            body: body
        }
        const response = await tutorService.updateTutorLanguageDetail(requestData)
        if (response.status === 200) {
            dispatch(getTutorLanguageList({ tutorId: user?.user?.tutor?.tutorId }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setUpdateTutorLanguageDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setUpdateTutorLanguageDetailLoading(false))
    }
}

export const updateTutorLanguageListOrder = (body, query) => async (dispatch, getState) => {
    dispatch(setUpdateTutorLanguageListOrderLoading(true))

    try {
        const requestData = {
            body: body,
            query: query
        }
        const response = await tutorService.updateTutorLanguageListOrder(requestData)
        if (response.status === 200) {
            dispatch(getTutorLanguageList({ tutorId: query?.tutorId }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setUpdateTutorLanguageListOrderMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setUpdateTutorLanguageListOrderLoading(false))
    }
}

export const deleteTutorLanguageDetail = (tutorLanguageId) => async (dispatch, getState) => {
    dispatch(setDeleteTutorLanguageDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { tutorLanguageId: tutorLanguageId }
        }
        const response = await tutorService.deleteTutorLanguageDetail(requestData)
        if (response.status === 200) {
            dispatch(getTutorLanguageList({ tutorId: user?.user?.tutor?.tutorId }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDeleteTutorLanguageDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setDeleteTutorLanguageDetailLoading(false))
    }
}

// new modified tutor user api
export const getTutorTagList = (query) => async (dispatch) => {
    dispatch(setTutorTagListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await tutorService.getTutorTagList(requestData)
        if (response.status) {
            dispatch(setTutorTagListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setTutorTagListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setTutorTagListLoading(false))
    }
}

export const createTutorTagDetail = (body) => async (dispatch) => {
    dispatch(setAddTutorTagDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await tutorService.createTutorTagDetail(requestData)
        if (response.status === 200) {
            dispatch(getTutorTagList({ tutorId: body?.tutorId }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddTutorTagDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddTutorTagDetailLoading(false))
    }
}

export const updateTutorTagListOrder = (body, query) => async (dispatch, getState) => {
    dispatch(setUpdateTutorTagListOrderLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            body: body,
            query: query
        }
        const response = await tutorService.updateTutorTagListOrder(requestData)
        if (response.status === 200) {
            dispatch(getTutorTagList({ tutorId: user?.user?.tutor?.tutorId }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setUpdateTutorTagListOrderMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setUpdateTutorTagListOrderLoading(false))
    }
}

export const deleteTutorTagDetail = (tagId) => async (dispatch, getState) => {
    dispatch(setDeleteTutorTagDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { tagId: tagId }
        }
        const response = await tutorService.deleteTutorTagDetail(requestData)
        if (response.status === 200) {
            dispatch(getTutorTagList({ tutorId: user?.user?.tutor?.tutorId }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDeleteTutorTagDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDeleteTutorTagDetailLoading(false))
    }
}

// new modified tutor category apis
export const getTutorCategoryList = (query) => async (dispatch) => {
    dispatch(setTutorCategoryListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await tutorService.getTutorCategoryList(requestData)
        if (response.status === 200) {
            dispatch(setTutorCategoryListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setTutorCategoryListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setTutorCategoryListLoading(false))
    }
}

export const createTutorCategoryDetail = (body) => async (dispatch) => {
    dispatch(setAddTutorCategoryDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await tutorService.createTutorCategoryDetail(requestData)
        if (response.status === 200) {
            dispatch(getTutorCategoryList({ tutorId: body?.tutorId }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddTutorCategoryDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddTutorCategoryDetailLoading(false))
    }
}


export const updateTutorCategoryDetail = (categoryId, body) => async (dispatch) => {
    dispatch(setUpdateTutorCategoryDetailLoading(true))

    try {
        const requestData = {
            params: { categoryId: categoryId },
            body: body
        }
        const response = await tutorService.updateTutorCategoryDetail(requestData)
        if (response.status === 200) {
            dispatch(getTutorCategoryList({ tutorId: body?.tutorId }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setUpdateTutorCategoryDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setUpdateTutorCategoryDetailLoading(false))
    }
}

export const updateTutorCategoryListOrder = (body, query) => async (dispatch, getState) => {
    dispatch(setUpdateTutorCategoryListOrderLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            body: body,
            query: query
        }
        const response = await tutorService.updateTutorCategoryListOrder(requestData)
        if (response.status === 200) {
            dispatch(getTutorCategoryList({ tutorId: user?.user?.tutor?.tutorId }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setUpdateTutorCategoryListOrderMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setUpdateTutorCategoryListOrderLoading(false))
    }
}


export const deleteTutorCategoryDetail = (categoryId) => async (dispatch, getState) => {
    dispatch(setDeleteTutorCategoryDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { categoryId: categoryId }
        }
        const response = await tutorService.deleteTutorCategoryDetail(requestData)
        if (response.status === 200) {
            dispatch(getTutorCategoryList({ tutorId: user?.user?.tutor?.tutorId }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDeleteTutorCategoryDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDeleteTutorCategoryDetailLoading(false))
    }
}

// new tutor onboarding flow apis

export const getTutorDetail = (tutorId) => async (dispatch, getState) => {
    dispatch(setTutorDetailLoading(true))

    try {
        const requestData = {
            params: { tutorId: tutorId }
        }
        const response = await tutorService.getTutorDetail(requestData)
        if (response.status === 200) {
            dispatch(setTutorDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "SOmething went wrong!")
        dispatch(setTutorDetailMessage(error.response.data.message || error.response.data.error || "SOmething went wrong!"))
    } finally {
        dispatch(setTutorDetailLoading(false))
    }
}

export const createTutorDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddTutorDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await tutorService.createTutorDetail(requestData)
        if (response.status === 200) {
            await dispatch(getMyProfile())
            dispatch(setAddTutorDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "SOmething went wrong!")
        dispatch(setAddTutorDetailMessage(error.response.data.message || error.response.data.error || "SOmething went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "SOmething went wrong!")
    } finally {
        dispatch(setAddTutorDetailLoading(false))
    }
}

export const updateTutorDetail = (tutorId, body) => async (dispatch, getState) => {
    dispatch(setUpdateTutorDetailLoading(true))

    try {
        const requestData = {
            params: { tutorId: tutorId },
            body: body
        }
        const response = await tutorService.updateTutorDetail(requestData)
        if (response.status === 200) {
            dispatch(getTutorDetail(tutorId))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "SOmething went wrong!")
        dispatch(setUpdateTutorDetailMessage(error.response.data.message || error.response.data.error || "SOmething went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "SOmething went wrong!")
    } finally {
        dispatch(setUpdateTutorDetailLoading(false))
    }
}