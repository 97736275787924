import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INITIAL_DATA } from "./mock-data";
import { GiftCardRecipient, GiftCardStep } from "./types";

const giftCardSlice = createSlice({
  name: "giftCard",
  initialState: INITIAL_DATA,
  reducers: {
    setActiveStep: (state, action: PayloadAction<{ step: GiftCardStep }>) => {
      state.activeStep = action.payload.step;
    },
    setRecipientDeliveryMode: (state, action) => {
      state.recipentSelection.selectedRecipent.deliveryType = action.payload;
    },
    setDeliveryDate: (state, action: PayloadAction<{ date: string }>) => {
      state.recipentSelection.selectedRecipent.selectedDate =
        action.payload.date;
    },
    setDeliveryTime: (
      state,
      action: PayloadAction<{ label: string; value: string }>
    ) => {
      state.recipentSelection.selectedRecipent.selectedTime = {
        label: action.payload.label,
        value: action.payload.value,
      };
    },
    setOrderDetails: (
      state,
      action: PayloadAction<{ phone: string; email: string }>
    ) => {
      state.orderSummary.paymentEmail = action.payload.email;
      state.orderSummary.paymentPhone = action.payload.phone;
    },
    setCardSelection: (
      state,
      action: PayloadAction<{ isCompleted: boolean; selectedCard: any }>
    ) => {
      state.cardSelection = action.payload;
      state.cardSelection.isCompleted = true;
      state.activeStep = "step2";
    },
    setRecipentSelection: (
      state,
      action: PayloadAction<{ recipient: GiftCardRecipient }>
    ) => {
      state.recipentSelection.selectedRecipent = action.payload.recipient;
    },
    setRecipientSelectionCompleted: (state) => {
      state.recipentSelection.isCompleted = true;
      state.activeStep = "step3";
    },
    onPaymentSuccess: (
      state,
      action: PayloadAction<{
        orderId: string;
        paymentId: string;
        otherdetails: string;
        amountPaid: number;
      }>
    ) => {
      state.paymentSummary.orderId = action.payload.orderId;
      state.paymentSummary.paymentId = action.payload.paymentId;
      state.paymentSummary.otherdetails = action.payload.otherdetails;
      state.paymentSummary.isPaymentSuccess = true;
      state.paymentSummary.isCompleted = true;
      state.paymentSummary.amountPaid = action.payload.amountPaid;
      state.activeStep = "step4";
    },
    onPaymentFailure: (
      state,
      action: PayloadAction<{ message: string; amount: number }>
    ) => {
      state.paymentSummary.errorMessage = action.payload.message;
      state.paymentSummary.isCompleted = true;
      state.paymentSummary.amountPaid = action.payload.amount;
      state.paymentSummary.isPaymentSuccess = false;
      state.activeStep = "step4";
    },
    paymentModal: (state, action: PayloadAction<{ show: boolean }>) => {
      state._meta.showPaymentModal = action.payload.show;
    },
    setActiveImageIdx: (state, action: PayloadAction<{ idx: number }>) => {
      state.selectedImageIdx = action.payload.idx;
    },
  },
});

export const {
  onPaymentSuccess,
  setActiveStep,
  setCardSelection,
  setRecipentSelection,
  setRecipientDeliveryMode,
  setRecipientSelectionCompleted,
  setDeliveryDate,
  setDeliveryTime,
  onPaymentFailure,
  setOrderDetails,
  paymentModal,
  setActiveImageIdx,
} = giftCardSlice.actions;
export default giftCardSlice.reducer;
