import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { cn } from "utils/cn.utils";

import Pagination from 'components/pagination/Pagination';
import CourseCardLoader from "components/loader/CourseCardLoader";
import LmsFavCourseCard from 'components/courseCard/LmsFavCourseCard';

import { getStudentFavLmsCourseList } from "redux/edulyteLms/lmsCourse/lmsCourse.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetStudentFavLmsCourseList } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';

const RECORDS = 6

const FavouriteCourses = () => {
    const { user } = useAppState((s) => s.user)
    const { studentFavLmsCourseList } = useAppState((s) => s.lms.lmsCourse)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (user?.user?.student?.studentId) {
            const query = generateQueryParams(location.search)

            dispatcher(getStudentFavLmsCourseList(user?.user?.student?.studentId, { page: query.pn || 1, records: RECORDS }))
        }

        return () => {
            dispatcher(resetStudentFavLmsCourseList())
        }
    }, [location.search])

    const onHandleBuyCourse = () => {
        window.open(`${EDULYTE_WEB_DOMAIN_URL}/courses`, "_self")
    }

    return (
        <div className={'flex justify-center items-center'}>
            <div className={'max-w-sm md:max-w-3xl lg:max-w-full w-full space-y-5'}>

                {(studentFavLmsCourseList?.data?.records > 0) &&
                    <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-8 lg:gap-5'}>
                        {studentFavLmsCourseList?.data?.results?.map((courseItem, index) => (
                            <div key={index} className={'col-span-1 flex justify-center'}>
                                <LmsFavCourseCard course={courseItem} />
                            </div>
                        ))}
                    </div>
                }
                {studentFavLmsCourseList?.isLoading &&
                    <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-8 lg:gap-5'}>
                        {new Array(RECORDS).fill("").map((_, index) => (
                            <div key={index} className={"col-span-1 flex justify-center"}>
                                <CourseCardLoader key={index} isLoading={studentFavLmsCourseList?.isLoading} />
                            </div>
                        ))}
                    </div>
                }
                {(studentFavLmsCourseList?.data && (studentFavLmsCourseList?.data?.totalPages > 1)) && (
                    <div className="w-full flex justify-center items-center">
                        <Pagination
                            page={studentFavLmsCourseList?.data?.page}
                            totalPages={studentFavLmsCourseList?.data?.totalPages}
                            onChangePage={(page) => navigate(`${pagesInfo.STUDEN.pagePath}/courses?pn=${page}`)}
                        />
                    </div>
                )}
                {((studentFavLmsCourseList?.data?.records === 0) || studentFavLmsCourseList?.message) &&
                    <div className={'w-full h-full flex flex-col items-center justify-center gap-1 text-center text-base font-bodyPri tracking-wide'}>
                        {/* <span className={""}>
                            {"No Courses Found!"}
                        </span> */}
                        <div className={"w-[16rem] h-[16rem] overflow-hidden"}>
                            <img
                                src={"https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/my-lms-courses/lms-course-not-found.png"}
                                alt={"no-course-found"}
                                className={"w-full h-full object-cover"}
                            />
                        </div>
                        <div
                            className={cn(
                                "w-fit px-5 py-1 flex items-center justify-center rounded-lg",
                                "border border-primary-dark bg-primary-dark text-text-50 hover:opacity-90",
                                "transition-transform duration-300 delay-150 ease-in-out"
                            )}
                            onClick={onHandleBuyCourse}
                        >
                            <span className={"font-bodyPri font-normal text-base tracking-wide text-center cursor-pointer"}>
                                {"Buy a Course"}
                            </span>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default FavouriteCourses;