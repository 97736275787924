
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { dateRangesLabels } from "./data";

import { cn } from "utils/cn.utils";
import { dayjs } from "utils/dateTime.utils";

const ReactDateRangePicker = ({
    selectedDateRange = {
        startDate: dayjs(dayjs().subtract(29, 'days'))?.format("MM/DD/YYYY"),
        endDate: dayjs()?.format("MM/DD/YYYY")
    },
    onHandleSelectedRange,
    onHandleCancel,
    inputClassName = "",
    onHandleInputClick,
    onHandleBlurInput = () => {},
    dateRangesItems = dateRangesLabels,
}) => {

    return (
        <DateRangePicker
            initialSettings={{
                autoUpdateInput: true,
                locale: {
                    cancelLabel: 'Cancel',
                },
                ranges: dateRangesItems
            }}
            onCallback={(start, end, label) => onHandleSelectedRange({
                startDate: start?._d,
                endDate: end?._d,
                label: label
            })}
            onCancel={onHandleCancel}
        >
            <input
                type={"text"}
                defaultValue={""}
                className={cn(
                    "flex-1 px-1 py-1 flex items-center justify-center gap-1 bg-white cursor-pointer",
                    "border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                    "font-bodyPri font-normal text-text-700 text-base", inputClassName
                )}
                value={`${selectedDateRange?.startDate} - ${selectedDateRange?.endDate}`}
                onClick={onHandleInputClick}
                onBlur={onHandleBlurInput}
            />
        </DateRangePicker>
    )
}

export default ReactDateRangePicker;