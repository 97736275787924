import { cn } from "utils/cn.utils"

import { BsInfoCircle } from 'react-icons/bs';

import ToolTipView from "components/tooltipView";

const OnboardingTooltip = ({ tooltip, width = 60, translateX = 50, translateY = 100 }) => {
    return (
        <ToolTipView content={tooltip}>
            <button className={""}>
                <BsInfoCircle className="inline text-md text-text-900 hover:text-text-900" />
            </button>
        </ToolTipView>
    )
}

export default OnboardingTooltip;