import * as React from 'react';
import { cn } from "utils/cn.utils";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { useAppState } from 'hooks/useStore';
import { currencyRateConversion } from 'utils/generators.utils';

const minDistance = 0.1;

export default function PriceFilter({
    minValue,
    maxValue,
    setIsShowList,
    onHandlePriceRange,
    onClearFilter
}) {
    const { locals } = useAppState((s) => s.local)
    const { currencyRateList } = useAppState((state) => state.master)
    const targetCurrencyRate = currencyRateList?.currencyRateList[(locals?.currencyCode || "USD")]

    const [value, setValue] = React.useState([0, 100]);
    const [localCurrency, setLocalCurrency] = React.useState({ minLocal: minValue, maxLocal: maxValue })

    React.useEffect(() => {
        let minVal = (minValue * 100) / (targetCurrencyRate * 500)
        let maxVal = (maxValue * 100) / (targetCurrencyRate * 500)
        setValue([minVal, maxVal])
    }, [minValue, maxValue])

    const handleChange = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setValue([parseFloat(Math.min(newValue[0], value[1] - minDistance)?.toFixed(2)), value[1]]);
            setLocalCurrency({ ...localCurrency, minLocal: ((parseFloat(newValue[0] * (targetCurrencyRate * 500))) / 100)?.toFixed(2) })
            // onHandlePriceRange({ minLocal: ((parseFloat(newValue[0] * (targetCurrencyRate * 500))) / 100)?.toFixed(2) })
        } else {
            setValue([value[0], parseFloat(Math.max(newValue[1], value[0] + minDistance)?.toFixed(2))]);
            setLocalCurrency({ ...localCurrency, maxLocal: ((parseFloat(newValue[1] * (targetCurrencyRate * 500))) / 100)?.toFixed(2) })
            // onHandlePriceRange({ maxLocal: ((parseFloat(newValue[1] * (targetCurrencyRate * 500))) / 100)?.toFixed(2) })
        }

        onHandlePriceRange({
            minLocal: ((parseFloat(newValue[0] * (targetCurrencyRate * 500))) / 100)?.toFixed(2),
            maxLocal: ((parseFloat(newValue[1] * (targetCurrencyRate * 500))) / 100)?.toFixed(2)
        })
    };

    const onHandleApply = () => {
    }

    return (
        <Box className={"px-3 py-1.5 bg-white rounded-xl space-y-2"}>
            <div className={"w-full flex items-center justify-between gap-3"}>
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-medium text-text-900 text-base"}>
                        {"Price"}
                    </span>
                    {/* {selectedCourseTypes?.length > 0 &&
                        <span className={"w-5 h-5 flex items-center justify-center bg-primary-dark rounded-full text-text-50 text-sm"}>
                            {selectedCourseTypes?.length}
                        </span>
                    } */}
                </div>
                <div
                    className={"font-bodyPri font-medium text-primary-dark text-sm underline cursor-pointer"}
                    onClick={() => onClearFilter()}>
                    {"Clear"}
                </div>
            </div>
            <div className={"font-bodyPri font-normal text-text-900 text-lg"}>
                {/* {currencyRateConversion(locals?.currencyCode, localCurrency?.minLocal) + " - " + currencyRateConversion(locals?.currencyCode, localCurrency?.maxLocal)} */}
                {locals?.currencyCode + " " + parseFloat(localCurrency?.minLocal)?.toFixed(2) + " - " + locals?.currencyCode + " " + parseFloat(localCurrency?.maxLocal)?.toFixed(2)}
            </div>
            <div className={"px-3"}>
                <Slider
                    getAriaLabel={() => 'Minimum distance'}
                    value={value}
                    onChange={handleChange}
                    disableSwap
                />
            </div>
        </Box>
    );
}