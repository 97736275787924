import { useEffect, useState } from 'react';
import { cn } from "utils/cn.utils";

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { FaSpinner } from 'react-icons/fa';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { attachUserPaymentMethodDetail } from 'redux/payment/payment.request'
import { setMakePaymentErrorMsg } from 'redux/payment/payment.slice';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { ENVIRONMENT } from 'const/default.const';

import UserInfoForm from 'components/modals/paymentCardModal/UserInfoForm';

const CardForm = () => {
    const { user } = useAppState((state) => state.user)
    const { modal } = useAppState((state) => state.local)
    const { joinUserPaymentMethodDetail } = useAppState(s => s.payment)

    const [error, setError] = useState({ emailError: '', cardError: '' })
    const [isAddDisable, setIsAddDisable] = useState(true)

    const dispatcher = useAppDispatcher();
    const elements = useElements();
    const stripe = useStripe();

    useEffect(() => {
        setIsAddDisable(
            error.cardError.length !== 0 ||
            error.emailError.length !== 0 ||
            !joinUserPaymentMethodDetail?.payload?.email ||
            !joinUserPaymentMethodDetail?.payload?.name
        )
    }, [error, joinUserPaymentMethodDetail?.payload?.name, joinUserPaymentMethodDetail?.payload?.email])

    const handleCardOnChange = (event) => {
        if (event.error) {
            setError({
                ...error,
                cardError: event.error.message
            })
            setIsAddDisable(false)
        }
        else {
            setError({ ...error, cardError: '' });
        }
    }

    const handleAddCard = async () => {
        if (isAddDisable || joinUserPaymentMethodDetail?.isLoading) return;

        try {
            const cardElement = elements.getElement(CardElement)
            const paymentMethod = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
                billing_details: {
                    email: joinUserPaymentMethodDetail?.payload?.email,
                    name: joinUserPaymentMethodDetail?.payload?.name
                },
                metadata: {
                    ENV: ENVIRONMENT,
                    userId: user?.user?.userId
                }
            })
            if (paymentMethod.paymentMethod) {
                dispatcher(attachUserPaymentMethodDetail(joinUserPaymentMethodDetail?.payload?.userId, { paymentMethodId: paymentMethod?.paymentMethod?.id }))
                setTimeout(() => {
                    dispatcher(setModal({
                        ...modal,
                        [modalConst.PAYMENT_CARD_MODAL.stateKey]: false
                    }))
                }, 2000)
            } else if (paymentMethod.error) {
                dispatcher(setMakePaymentErrorMsg(paymentMethod.error.message))
            } else {
                dispatcher(setMakePaymentErrorMsg("Something went wrong!"))
            }
        } catch (error) {
            console.error(error)
            dispatcher(setMakePaymentErrorMsg("Something went wrong!"))
        }
    }

    return (
        <div className={"block space-y-16"}>

            <div className={"block space-y-2"}>
                <div>
                    <UserInfoForm error={error} setError={setError} />
                </div>
                <div className={"w-full space-y-1"}>
                    <CardElement onChange={handleCardOnChange} />
                    <span className={"font-bodyPri font-normal text-xs text-red-500"}>
                        {error.cardError}
                    </span>
                </div>
                <div className={"w-full h-10"}>
                    {joinUserPaymentMethodDetail?.message &&
                        <div className={"flex items-center justify-start"}>
                            <span className={"font-bodyPri font-medium text-xs text-red-500 text-center"}>
                                {joinUserPaymentMethodDetail?.message}
                            </span>
                        </div>
                    }
                    {joinUserPaymentMethodDetail?.isLoading &&
                        <div className={"flex items-center justify-start gap-2 whitespace-nowrap"}>
                            <span className={"font-bodyPri font-normal text-base text-text-700 whitespace-nowrap"}>
                                {"Please wait ..."}
                            </span>
                            <FaSpinner className="inline-flex text-primary-main animate-spin" />
                        </div>
                    }
                </div>
            </div>

            <div className={"flex justify-end"}>
                <span onClick={handleAddCard} className={cn(
                    "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-normal text-base text-secondary-main",
                    "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                    (isAddDisable || joinUserPaymentMethodDetail?.isLoading) && "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light"
                )}>
                    {"Add"}
                </span>
            </div>
        </div>
    )
}

export default CardForm;

// <CardElement onChange={handleCardOnChange} options={CARD_ELEMENT_OPTIONS} />
// const CARD_ELEMENT_OPTIONS = {
//     style: {
//         base: {
//             color: "#32325d",
//             fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
//             fontSmoothing: "antialiased",
//             fontSize: "16px",
//             "::placeholder": {
//                 color: "#aab7c4",
//             },
//         },
//         invalid: {
//             color: "#fa755a",
//             iconColor: "#fa755a",
//         },
//     },
// };