import { cn } from "utils/cn.utils";

import OnboardingTooltip from "pages/auth/onboarding/commonComponents/OnboardingTooltip";
import { onboardingTooltipConst, profileEditBtnConst } from 'pages/auth/onboarding/data';
import SaveCancelBtn from 'pages/auth/onboarding/commonComponents/onboardingButtons/SaveCancelBtn';
import AddBorderButton from "pages/auth/onboarding/commonComponents/onboardingButtons/AddBorderButton";

import { updateTutorUserDetailByUserId } from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setAddTutorUserDetailPayload } from "redux/tutor/tutor.slice";


const MIN_CHARACTER_LENGTH = 50
const MAX_CHARACTER_LENGTH = 700

const TeachingStyles = ({ editBtnConst, setEditBtnConst }) => {
    const { user } = useAppState((state) => state.user)
    const { tutorUserDetail, addTutorUserDetail } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()

    const onHandleSave = () => {
        if (tutorUserDetail?.isLoading || addTutorUserDetail?.isLoading) return;

        if (!(addTutorUserDetail?.payload?.teachingStyleBio?.length >= MIN_CHARACTER_LENGTH && addTutorUserDetail?.payload?.teachingStyleBio?.length <= MAX_CHARACTER_LENGTH)) {
            alert("Please enter between 50 to 700 characters")
            return;
        }

        dispatcher(updateTutorUserDetailByUserId(user?.user?.userId, { teachingStyleBio: addTutorUserDetail?.payload?.teachingStyleBio }))
        setEditBtnConst(null)
    }


    const onHandleCancel = () => {
        if (tutorUserDetail?.isLoading || addTutorUserDetail?.isLoading) return;
        dispatcher(setAddTutorUserDetailPayload({
            ...addTutorUserDetail?.payload,
            teachingStyleBio: tutorUserDetail?.tutorUserDetail?.teachingStyleBio
        }))
        setEditBtnConst(null)
    }

    const onHandleAddTeachingStyle = () => {
        if (tutorUserDetail?.isLoading || addTutorUserDetail?.isLoading) return;

        if (!editBtnConst || editBtnConst !== profileEditBtnConst?.TEACHING_STYLE?.value) {
            setEditBtnConst(profileEditBtnConst?.TEACHING_STYLE?.value)
        }
        dispatcher(setAddTutorUserDetailPayload({
            ...addTutorUserDetail?.payload,
            teachingStyleBio: tutorUserDetail?.tutorUserDetail?.teachingStyleBio?.length > 700
                ? tutorUserDetail?.tutorUserDetail?.teachingStyleBio?.slice(0, 700) + " ..."
                : tutorUserDetail?.tutorUserDetail?.teachingStyleBio
                    ? tutorUserDetail?.tutorUserDetail?.teachingStyleBio
                    : ""

        }))
    }

    const onHandleChange = (value) => {
        if (!editBtnConst || editBtnConst !== profileEditBtnConst?.TEACHING_STYLE?.value) {
            setEditBtnConst(profileEditBtnConst?.TEACHING_STYLE?.value)
        }
        dispatcher(setAddTutorUserDetailPayload({
            ...addTutorUserDetail?.payload,
            teachingStyleBio: value
        }))
    }

    return (
        <div className='w-full flex flex-col items-start justify-start gap-1'>
            <div className={"w-full flex items-center justify-between gap-2"}>
                <div className={"flex items-center justify-start gap-1"}>
                    <OnboardingTooltip tooltip={onboardingTooltipConst?.teachingStyleTooltip} translateX={0} translateY={0} />
                    <span className='font-bodyPri font-medium text-text-900 text-base tracking-wide'>
                        {"Teaching Style: My Unique Approach"}
                    </span>
                </div>
                <SaveCancelBtn
                    isLoading={addTutorUserDetail?.isLoading}
                    isShowBtn={editBtnConst === (profileEditBtnConst?.TEACHING_STYLE?.value) || addTutorUserDetail?.isLoading}
                    onHandleSave={onHandleSave}
                    onHandleCancel={onHandleCancel}
                />
            </div>
            {(!editBtnConst || editBtnConst !== profileEditBtnConst?.TEACHING_STYLE?.value) && (!addTutorUserDetail?.payload?.teachingStyleBio || addTutorUserDetail?.payload?.teachingStyleBio?.length === 0) &&
                <AddBorderButton btnTitle={"Add Your Teaching Style"} onHandleAdd={onHandleAddTeachingStyle} />
            }
            {((editBtnConst === profileEditBtnConst?.TEACHING_STYLE?.value) || (addTutorUserDetail?.payload?.teachingStyleBio && addTutorUserDetail?.payload?.teachingStyleBio?.length > 0)) &&
                <div className={"w-full flex flex-col gap-1.5"}>
                    <textarea
                        type={"text"}
                        placeholder={"Tell us about your teaching style ..."}
                        rows={5}
                        maxLength={MAX_CHARACTER_LENGTH}
                        autoFocus={true}
                        value={addTutorUserDetail?.payload?.teachingStyleBio}
                        className={cn(
                            "w-full px-3 py-1 resize-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                        )}
                        onChange={(e) => onHandleChange(e.target.value)}
                        disabled={editBtnConst && editBtnConst !== profileEditBtnConst?.TEACHING_STYLE?.value}
                    />
                    <div className={"w-full flex justify-between"}>
                        <div className={""}>
                            {((addTutorUserDetail?.payload?.teachingStyleBio?.length < MIN_CHARACTER_LENGTH) || (addTutorUserDetail?.payload?.teachingStyleBio?.length > MAX_CHARACTER_LENGTH)) &&
                                <span className="font-bodyPri font-normal tracking-wide text-red-500 text-xs">
                                    {"Please enter between 50 and 700 characters"}
                                </span>
                            }
                        </div>
                        <div className={"flex justify-end"}>
                            <span className="font-bodyPri font-normal text-text-800 text-xs">
                                {addTutorUserDetail?.payload?.teachingStyleBio?.length + "/" + MAX_CHARACTER_LENGTH}
                            </span>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default TeachingStyles;