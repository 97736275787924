import { memo, useCallback } from 'react';

import ComponentLoader from 'components/loader/ComponentLoader';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

const EmptyAppointmentContent = memo(({ searchValue }) => {
    const { modal } = useAppState((state) => state.local)
    const { userAppointmentList } = useAppState((state) => state.appointment)

    const dispatcher = useAppDispatcher()

    const onHandleAddAppointment = useCallback(() => {
        dispatcher(setModal({
            ...modal,
            [modalConst.createAppointmentDetailModal.key]: {
                ...modalConst.createAppointmentDetailModal,
                isVisible: true
            }
        }));
    }, [modal])

    return (
        <>
            {userAppointmentList?.isLoading &&
                <ComponentLoader isLoading={userAppointmentList?.isLoading} />
            }
            {(!userAppointmentList?.isLoading && userAppointmentList?.data && (userAppointmentList?.data?.result?.length === 0)) &&
                <div className={"py-4 flex items-center justify-center gap-1.5"}>
                    {searchValue &&
                        <div className={"w-full flex items-center justify-center"}>
                            <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                                {"No result found!"}
                            </span>
                        </div>
                    }
                    {!searchValue &&
                        <>
                            <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                                {"No Appointment Added!"}
                            </span>
                            <span
                                className={"font-bodyPri font-normal text-text-800 text-sm cursor-pointer hover:underline hover:text-primary-dark"}
                                onClick={onHandleAddAppointment}
                            >
                                {"Add Appointment"}
                            </span>
                        </>
                    }
                </div>
            }
        </>
    )
})

export default EmptyAppointmentContent;