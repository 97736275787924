import { useState, useMemo, useEffect, memo, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { motion } from "framer-motion";

import ComponentLoader from "components/loader/ComponentLoader";
import { searchParamsInfo } from "pages/auth/crm/data";

import { getContactDetailPayload } from "components/modals/crmModals/data";
import EditContactIdentity from "components/modals/crmModals/viewContactDetailModal/components/editContactDetail/components/EditContactIdentity";
import EditContactBasicDetails from "components/modals/crmModals/viewContactDetailModal/components/editContactDetail/components/EditContactBasicDetails";
import EditContactAddresses from "components/modals/crmModals/viewContactDetailModal/components/editContactDetail/components/EditContactAddresses";
import EditContactNotes from "components/modals/crmModals/viewContactDetailModal/components/editContactDetail/components/EditContactNotes";
import EditContactSocials from "components/modals/crmModals/viewContactDetailModal/components/editContactDetail/components/EditContactSocials";

import { getMasterCountryList, getMasterSocialList } from "redux/master/master.request";
import { getCrmContactDetail } from "redux/crm/crm.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearCountryList, resetSocialList } from "redux/master/master.slice";
import { resetCrmContactDetail, resetModifyCrmContactDetailPayload, setModifyCrmContactDetailPayload } from "redux/crm/crm.slice";

const EditContactContent = memo(({ closeModal }) => {
    const { countryList } = useAppState((state) => state.master)
    const { user } = useAppState((state) => state.user)
    const { crmContactDetail, modifyCrmContactDetail } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher();
    const location = useLocation();

    const [editBtnConst, setEditBtnConst] = useState(null)

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        dispatcher(getMasterCountryList())
        dispatcher(getMasterSocialList())

        return () => {
            dispatcher(setClearCountryList())
            dispatcher(resetSocialList())
            dispatcher(resetCrmContactDetail())
        }
    }, [])

    useEffect(() => {
        if (!!searchQueryParams?.get(searchParamsInfo.contact.key)) {
            dispatcher(getCrmContactDetail(searchQueryParams?.get(searchParamsInfo.contact.key), user?.user?.userId))
        }
    }, [searchQueryParams])

    useEffect(() => {
        if (crmContactDetail?.data) {
            setContactDetailPayload()
        }
    }, [crmContactDetail?.data])

    const setContactDetailPayload = async () => {
        const requestDataPayload = await getContactDetailPayload(crmContactDetail?.data, countryList?.countryList)
        if (requestDataPayload) {
            dispatcher(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                ...requestDataPayload
            }))
        }
    }

    const onHandleGoBack = useCallback(() => {
        dispatcher(resetCrmContactDetail())
        dispatcher(resetModifyCrmContactDetailPayload())
        setEditBtnConst(null)
    }, [])

    if (crmContactDetail?.isLoading) {
        return (
            <ComponentLoader isLoading={crmContactDetail?.isLoading} className={"min-h-[25rem]"} />
        )
    }

    if (crmContactDetail?.message) {
        return (
            <div className={"flex items-center justify-center min-h-[25rem]"}>
                <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                    {crmContactDetail?.message}
                </span>
            </div>
        )
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className={"flex flex-col gap-5 p-5 font-bodyPri"}>
                <EditContactIdentity onClose={closeModal} editBtnConst={editBtnConst} onHandleGoBack={onHandleGoBack} />
                <EditContactBasicDetails editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />
                <EditContactSocials editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />
                <EditContactAddresses editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />
                <EditContactNotes editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />
            </div>
        </motion.div>
    )
})

export default EditContactContent;