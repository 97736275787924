import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { motion } from 'framer-motion';

import { FiShare } from 'react-icons/fi';

import { searchParamsInfo } from "../data";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearShareSocial, setModal, setShareSocial } from "redux/local/local.slice";
import { modalConst, socialAction } from 'redux/local/local.const';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';

const MyDownloadedCard = ({ item }) => {
    const { modal, shareSocial } = useAppState((state) => state.local)

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const navigate = useNavigate()

    const downloadable = item?.downloadable
    const user = item?.downloadable_user?.added_by

    let searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    const handleShare = useCallback(() => {
        const publicUrl = `${EDULYTE_WEB_DOMAIN_URL}/download/${downloadable?.slug}`
        dispatcher(setClearShareSocial())
        dispatcher(setShareSocial({
            ...shareSocial,
            socialAction: socialAction?.SHARE_DOWNLOAD?.value,
            url: publicUrl
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
        }))
    }, [modal, downloadable])

    const onHandleDownload = useCallback(() => {
        searchQueryParams.set(searchParamsInfo.download_id.key, item?.downloadable?.id)
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`);
    }, [searchQueryParams, item?.downloadable?.id])

    return (
        <div className="relative w-full rounded-md overflow-hidden shadow-all flex flex-col bg-white">
            <img
                src={downloadable?.preview?.preview_url}
                alt={downloadable?.title}
                className="w-full aspect-video object-cover"
            />
            <motion.button
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
                onClick={handleShare}
                className="absolute top-3 right-3 bg-white shadow text-text-500 rounded-full h-8 w-8 flex justify-center items-center"
            >
                <FiShare size={18} />
            </motion.button>
            <div className="flex flex-col gap-3 p-3">
                <h4
                    onClick={() => window.open(`${EDULYTE_WEB_DOMAIN_URL}/downloadable/${downloadable?.slug}`, "_blank")}
                    className="font-medium text-base line-clamp-1 cursor-pointer hover:underline text-text-900 hover:text-secondary-dark">
                    {downloadable?.title}
                </h4>
                <p className="text-sm line-clamp-2 text-text-700">By: {`${user?.first_name} ${user?.last_name.charAt(0)}.`}</p>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="w-full h-8 ease-in-out duration-300 rounded-full border text-lg font-medium text-md bg-white text-text-700 border-background-dark hover:text-primary-main hover:border-primary-main"
                    onClick={onHandleDownload}
                >
                    {"Download"}
                </motion.button>
            </div>
        </div>
    )
}

export default MyDownloadedCard


export const MyDownloadedCardLoader = ({ isLoading }) => {
    return isLoading ? (
        <div className="w-full rounded-md overflow-hidden shadow-all flex flex-col cursor-pointer bg-white">
            <div className="w-full h-40 aspect-video bg-slate-100 animate-pulse object-cover"></div>
            <div className="flex flex-col gap-3 p-3">
                <div className="w-full h-8 rounded animate-pulse bg-slate-200"></div>
                <div className="w-40 h-6 rounded animate-pulse bg-slate-200"></div>
                <div className="w-full h-6 rounded-full animate-pulse bg-slate-200"></div>
            </div>
        </div>
    ) : null;
};
