import { toast } from "react-toastify";
import InterviewService from "redux/interview/interview.service";
import { setOnboardingInterviewDetail } from "redux/onboarding/onboarding.slice";

import {
    setInterviewDetailLoading,
    setInterviewDetail,
    setInterviewDetailErrorMsg,

    setAddInterviewSessionDetailLoading,
    setAddInterviewSessionDetailData,
    setAddInterviewSessionDetailMessage,
} from "./interview.slice";

// request for get interview detail
export const getInterviewDetailByInterviewId = (interviewId) => async (dispatch) => {
    dispatch(setInterviewDetailLoading(true))

    try {
        const requestData = {
            params: { interviewId: interviewId }
        }
        const response = await InterviewService.getInterviewDetailByInterviewId(requestData)
        if (response.status === 200) {
            dispatch(setInterviewDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setInterviewDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setInterviewDetailLoading(false))
    }
}

// request for add interview session detail
export const createInterviewSessionDetail = (interviewId, body) => async (dispatch, getState) => {
    dispatch(setAddInterviewSessionDetailLoading(true))

    try {
        const { onboardingInterviewDetail } = getState().onboarding
        const requestData = {
            params: { interviewId: interviewId },
            body: body
        }
        const response = await InterviewService.createInterviewSessionDetail(requestData)
        if (response.status === 200) {
            const filteredInterviewList = onboardingInterviewDetail?.onboardingInterviewDetail?.interviews?.filter((interview) => interview?.interviewId !== interviewId)
            dispatch(setOnboardingInterviewDetail({
                ...onboardingInterviewDetail?.onboardingInterviewDetail,
                interviews: [...filteredInterviewList, response.data.data]
            }))
            dispatch(setAddInterviewSessionDetailData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddInterviewSessionDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddInterviewSessionDetailLoading(false))
    }
}