import React from "react";
//Icon imports
import { RefreshIcon } from "@heroicons/react/outline";
//Style imports
import { StyledJitsiRenderSpinner } from "./JitsiRenderSpinner.styles";

export default function JitsiRenderSpinner() {
  return (
    <StyledJitsiRenderSpinner>
      <RefreshIcon className="h-6 w- animate-spin text-white" />
    </StyledJitsiRenderSpinner>
  );
}
