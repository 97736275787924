import { useState, useEffect } from 'react';
import { cn } from "utils/cn.utils";

import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';

import InputOtp from 'components/inputOtp/InputOtp';
import ButtonLoader from 'components/loader/ButtonLoader';
import PasswordValidator from 'components/passwordValidator/PasswordValidator';

import { updateResetPassword } from "redux/auth/auth.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearAddResetPassword } from 'redux/auth/auth.slice';

const ResetPasswordModal = () => {
    const { addResetPassword } = useAppState((s) => s.auth)

    const dispatcher = useAppDispatcher()

    const [emailAddress, setEmailAddress] = useState("")
    const [password, setPassword] = useState({
        newPassword: "",
        confirmPassword: ""
    })
    const [isShowPassword, setIsShowPassword] = useState(false)
    const [isNewPasswordValid, setIsNewPasswordValid] = useState(false)
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false)

    const [myOtp, setMyOtp] = useState("")
    const [isOtpError, setIsOtpError] = useState(false)

    useEffect(() => {
        return () => {
            dispatcher(setClearAddResetPassword())
        }
    }, [])

    useEffect(() => {
        if (addResetPassword?.addResetPasswordPayload?.email) {
            setEmailAddress(addResetPassword?.addResetPasswordPayload?.email)
        }
    }, [addResetPassword?.addResetPasswordPayload?.email])


    useEffect(() => {
        setIsConfirmPasswordValid((isNewPasswordValid && (password?.confirmPassword === password?.newPassword)))
    }, [password])

    const onHandleSubmit = async () => {
        if (myOtp?.length < 6) return;
        if (!password?.newPassword || !isNewPasswordValid || !password?.confirmPassword || !isConfirmPasswordValid) {
            return;
        }
        if ((password.newPassword && password.confirmPassword) && (password.newPassword !== password.confirmPassword)) return;

        if (addResetPassword?.addResetPasswordPayload?.otpId) {
            dispatcher(updateResetPassword({
                otpId: addResetPassword?.addResetPasswordPayload?.otpId,
                otp: myOtp,
                email: emailAddress,
                password: password?.newPassword
            }))
        }
    }

    return (
        <div className="space-y-5">
            <div className={"min-h-[16rem] block w-full space-y-3"}>
                <div className={"space-y-10"}>

                    <div className={'flex flex-col items-start justify-start gap-10'}>
                        <div className={"w-full font-bodyPri font-normal text-text-800 text-sm text-center tracking-wide"}>
                            {"We’ve emailed a verification code! Please check your email, enter the code to proceed. Remember to check your spam folder, just in case"}
                        </div>

                        <span className={"w-full font-bodyPri font-medium text-text-900 text-base tracking-wide text-center"}>
                            {emailAddress}
                        </span>
                    </div>
                    <div className='flex flex-col items-center justify-start space-y-2'>
                        <InputOtp
                            email={emailAddress}
                            otpLength={6}
                            setMyOtp={setMyOtp}
                            isOtpError={isOtpError}
                            setIsOtpError={setIsOtpError}
                        />
                        {(myOtp?.length === 6) &&
                            <div className={"w-full space-y-8"}>
                                <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wide"}>
                                    {"Enter your new password which you can remember but others can't guess."}
                                </span>
                                <div className={"w-full flex flex-col items-start justify-start gap-5"}>

                                    <PasswordValidator
                                        inputPassword={password?.newPassword}
                                        setIsNewPasswordValid={setIsNewPasswordValid}
                                    />

                                    {/* new password  */}
                                    <div className={"w-full flex flex-col items-start justify-start gap-3"}>
                                        <div className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                            {"New Password"}
                                        </div>
                                        <div className={cn(
                                            "w-full flex items-center justify-between gap-1",
                                            "px-3 py-1 focus:outline-none rounded-md",
                                            "font-bodyPri font-normal text-text-800 text-base",
                                            "border-2 border-text-400 focus:outline-none focus:border-text-400",
                                            password?.newPassword && "!border-text-400",
                                            (password?.newPassword && isNewPasswordValid) && "!border-red-500",
                                            (password?.newPassword && isNewPasswordValid) && "!border-green-500"
                                        )}>
                                            <input
                                                type={isShowPassword ? "text" : "password"}
                                                id={"newPassword"}
                                                className={"w-full focus:outline-none"}
                                                value={password.newPassword}
                                                onChange={(event) => {
                                                    setPassword({
                                                        ...password,
                                                        newPassword: event.target.value
                                                    })
                                                }}
                                                autoFocus={true}
                                            />
                                        </div>
                                    </div>

                                    {/* confirm password container */}
                                    <div className={"w-full flex flex-col items-start justify-start gap-3"}>
                                        <span className={cn(
                                            "font-bodyPri font-normal text-text-800 text-base tracking-wide lg:whitespace-nowrap"
                                        )}
                                        >
                                            {"Confirm Password"}
                                        </span>
                                        <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                                            <div className={cn(
                                                "w-full flex items-center justify-between gap-1",
                                                "px-3 py-1 focus:outline-none rounded-md",
                                                "font-bodyPri font-normal text-text-800 text-base",
                                                "border-2 border-text-400 focus:outline-none focus:border-text-400",
                                                password?.confirmPassword && "!border-text-400",
                                                (password?.confirmPassword && !isConfirmPasswordValid) && "!border-red-500",
                                                (password?.confirmPassword && isConfirmPasswordValid) && "!border-green-500"
                                            )}>
                                                <input
                                                    type={isShowPassword ? "text" : "password"}
                                                    id={"confirmPassword"}
                                                    className={"w-full focus:outline-none"}
                                                    value={password.confirmPassword}
                                                    onChange={(event) => setPassword({
                                                        ...password,
                                                        confirmPassword: event.target.value
                                                    })}
                                                />
                                            </div>
                                            {((password.newPassword && password.confirmPassword) && password.newPassword !== password.confirmPassword) &&
                                                <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                                    {"The passwords you entered do not match"}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className={"w-fit flex items-center justify-start gap-2 cursor-pointer"} onClick={() => setIsShowPassword(!isShowPassword)}>
                                        {isShowPassword && <ImCheckboxChecked id={"isShowPassword"} className={"text-primary-main"} />}
                                        {!isShowPassword && <ImCheckboxUnchecked id={"isShowPassword"} className={"text-text-400"} />}
                                        <label for={"isShowPassword"} className={"font-bodyPri font-normal text-base text-text-700"}>
                                            {"Show Password"}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={"w-full flex flex-col items-center justify-center gap-3"}>
                        {addResetPassword?.errorMsg &&
                            <span className={"font-bodyPri font-normal text-red-500 text-base line-clamp-1"}>
                                {"Invalid verification code"}
                            </span>
                        }
                        <div className={cn(
                            "py-1.5 rounded-lg",
                            "text-text-50 font-buttons font-medium text-base w-full text-center",
                            (!password?.newPassword || !password?.confirmPassword || (myOtp?.length < 6)) && "bg-divider-medium",
                            (myOtp?.length === 6) && "bg-primary-main hover:bg-secondary-dark cursor-pointer"
                        )}
                            onClick={onHandleSubmit}
                        >
                            {addResetPassword?.isLoading &&
                                <ButtonLoader isLoading={addResetPassword?.isLoading} />
                            }
                            {!addResetPassword?.isLoading && "Submit"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordModal;