import { motion } from "framer-motion";

import { cn } from 'utils/cn.utils';

const DownloadActionButtons = ({
    isLoading = false,
    buttonList = [],
    onHandleButtonClick
}) => {

    return (
        <div className={"flex items-center justify-start gap-3"}>
            {!!buttonList && buttonList?.map((buttonItem, index) => (
                <motion.div
                    key={`${buttonItem?.value}${index}`}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ duration: 0.3 }}
                    className={cn(
                        'relative group px-3 py-1 flex items-center justify-center border border-primary-dark text-primary-dark rounded-lg cursor-pointer',
                        'font-bodyPri font-normal text-sm sm:text-base hover:bg-primary-dark hover:text-text-50 ease-in-out duration-200',
                        buttonItem?.className,
                        isLoading && "cursor-processing"
                    )}
                >
                    <label
                        for={buttonItem?.labelInputId}
                        className={"w-full h-full flex items-center justify-start gap-2 cursor-pointer"}
                    >
                        {buttonItem.icon}
                        <span className={"whitespace-nowrap"}>
                            {buttonItem?.label}
                        </span>
                    </label>
                    <input
                        id={buttonItem?.labelInputId}
                        type={"file"}
                        style={{ display: "none", width: "100%", height: "100%", cursor:"pointer" }}
                        accept={buttonItem?.acceptFileTypes}
                        disabled={isLoading}
                        onChange={(event) => {
                            if (isLoading) return;
                            onHandleButtonClick(event, buttonItem)
                        }}
                    />
                </motion.div>
            ))}
        </div>
    )
};

export default DownloadActionButtons;