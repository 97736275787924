import React from "react";
import { z } from "zod";
import OTPInput, { ResendOTP } from "otp-input-react";

import useWindowSize from "hooks/useWindowSize.hooks"

import { cn } from "utils/cn.utils";


export const getOtpFormSchema = (otpLength = 4) => z.string().length(otpLength, { message: `Verification code must have ${otpLength} digit characters` }).refine((value) => !isNaN(value), { "message": "Otp must have number" })

const OtpInput = ({
    otpLength = 4,
    optRegister,
    value = "",
    onChange = () => { },
    error,
    maxTime = 119,
    resendOtp = () => { },
}) => {

    const { width } = useWindowSize()
    const isSmallScreen = width >= 640
    const renderTime = () => React.Fragment

    const renderButton = ({ remainingTime, onClick }) => (
        <button
            {...{ remainingTime }}
            disabled={remainingTime !== 0}
            onClick={onClick}
            className={cn(
                remainingTime !== 0
                    ? "font-bodyPri font-medium text-text-600 text-sm cursor-wait"
                    : "font-bodyPri font-medium text-primary-main text-sm cursor-pointer hover:underline hover:text-primary-dark"
            )}
        >
            {remainingTime !== 0
                ? `Resend Code in ${Math.floor((remainingTime % 3600) / 60)?.toString()?.padStart(2, "0")} : ${Math.floor(remainingTime % 60)?.toString()?.padStart(2, "0")}`
                : "Resend Code"
            }
        </button>
    )

    return (
        <div className={"w-full flex flex-col items-center justify-start gap-5"}>
            <div className={"relative group space-y-2"}>
                <OTPInput
                    otpType={"number"}
                    inputClassName={cn(
                        "input-number-spin-none w-full h-full outline-none border-b group-focus-within:shadow",
                        "font-bodyPri font-normal text-text-900 text-base",
                        !!error
                            ? 'border-secondary-dark group-focus-within:border-secondary-main shadow shadow-secondary-main/20 group-focus-within:shadow-secondary-main/20'
                            : !!value
                                ? 'border-primary-main group-focus-within:border-primary-main shadow shadow-primary-main/20 group-focus-within:shadow-primary-main/20'
                                : 'border-text-400 group-focus-within:border-primary-main group-focus-within:shadow-primary-main/20'

                    )}
                    inputStyles={{ width: isSmallScreen ? "52px" : "32px", height: isSmallScreen ? "52px" : "32px", }}
                    className={"outline-none"}
                    OTPLength={otpLength}
                    onChange={onChange}
                    disabled={false}
                    autoFocus={true}
                    value={value}
                // {...optRegister}
                />
                {!!error && (
                    <span className={"font-bodyPri font-normal text-xs text-secondary-main text-left transition-all ease-in-out duration-200"}>
                        {error?.message}
                    </span>
                )}
            </div>
            <ResendOTP
                maxTime={maxTime}
                renderButton={renderButton}
                renderTime={renderTime}
                onResendClick={resendOtp}
            />
        </div>
    )
}

export default OtpInput