import { cn } from "utils/cn.utils";

import { FaTimesCircle } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";

import { deleteOffDayDetail } from "redux/availability/availability.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from "redux/local/local.slice";
import { setAddOffDayDetailPayload } from "redux/availability/availability.slice";
import { modalConst } from "redux/local/local.const";

import { dayjs, getTimeZoneOffset, timeZone } from "utils/dateTime.utils";

const OffDayCard = ({ localOffDay }) => {
    const { modal } = useAppState((state) => state.local)
    const { offDayList, addOffDayDetail } = useAppState((state) => state.availability)

    const dispatcher = useAppDispatcher()

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    const onHandleEditOffDay = () => {
        dispatcher(setAddOffDayDetailPayload({
            offDayId: localOffDay?.id,
            name: localOffDay?.name,
            description: localOffDay?.description,
            startDateTime: localOffDay?.startDateTime,
            endDateTime: localOffDay?.endDateTime
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.ADD_OFF_DAY_MODAL.stateKey]: true
        }))
    }

    const onHandleDeleteOffDay = async () => {
        if (addOffDayDetail?.isLoading) return;

        const offDays = offDayList?.offDayList?.find(offDay => offDay.startDateTime === localOffDay?.startDateTime)

        dispatcher(deleteOffDayDetail(offDays?.id))
    }

    return (
        <div className="p-4 border rounded-md">
            <div>
                <div className="w-full p-4 bg-blue-100 rounded-lg shadow-md">
                    <div className="flex justify-between">
                        <p className="font-bodyPri font-medium text-lg text-text-900 truncate">
                            {dayjs(localOffDay.startDateTime + timeZoneOffset, "YYYY-MM-DDZ").tz(timeZone).format(`ddd, DD MMM YYYY[ - ${localOffDay?.name}]`)}
                        </p>
                        <div className={"flex items-center justify-start gap-0.5"}>
                            <button onClick={onHandleEditOffDay} className={cn(
                                "inline-flex items-center justify-center p-2 ",
                                "transform rounded-full hover:bg-blue-200 hover:scale-105"
                            )}>
                                <FiEdit size={20} className={"text-blue-400 hover:text-blue-600"} />
                            </button>
                            <button onClick={onHandleDeleteOffDay} className={cn(
                                "inline-flex items-center justify-center p-2 ",
                                "transform rounded-full hover:bg-red-200 hover:scale-105"
                            )}>
                                <FaTimesCircle size={20} className={"text-red-400 hover:text-red-600"} />
                            </button>
                        </div>
                    </div>
                    <div className={"font-bodyPri font-normal text-text-800 text-sm truncate"}>
                        {localOffDay?.description}
                    </div>
                </div>

                <div className="px-2 divide-y divide-blue-200 bg-blue-50 rounded-b-xl">

                    <div className={"flex items-center justify-start gap-0.5 w-full p-4"}>
                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                            {dayjs(localOffDay?.startDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("DD/MM/YYYY")}
                        </span>
                        <span className="">-</span>
                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                            {dayjs(localOffDay?.endDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("DD/MM/YYYY")}
                        </span>
                    </div>

                    {!localOffDay &&
                        <div className="py-5">
                            <p className="font-bodyPri font-semibold text-xl text-text-500 text-center">
                                {"No Schedules Found"}
                            </p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default OffDayCard;