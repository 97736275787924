import tw, { styled } from "twin.macro";

export const StyledNotificationBar = styled.div`
  ${tw`display[none] md:(relative flex w-full bg-white items-center justify-end py-2.5 px-5 mb-5 max-width[959px])`}
`;

export const StyledNotificationBellContainer = styled.div`
  ${tw`relative cursor-pointer`}
`;

export const StyledNotificationCount = styled.div`
  ${tw`absolute text-xs bg-secondary-dark text-white rounded-full px-1.5 width[fit-content] top[0] left[0]`}
`;
