import React from 'react'

const Header = () => {
    return (
        <div className={"w-full h-60 px-5 py-3 bg-[radial-gradient(farthest-side_at_top,_var(--tw-gradient-stops))] from-primary-light via-primary-light to-white flex flex-col items-center justify-center gap-5"}>
            <h1 className={"font-heading font-bold text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-text-800 text-center"}>
                {"Your Upcoming "}
                <span className={"text-secondary-dark"}>
                    {"Appointments"}
                </span>
            </h1>
            <p className={"font-subHeading font-medium text-base text-text-700 text-center"}>
                {"Access your scheduled appointments at a glance. Manage your time efficiently with our user-friendly appointments."}</p>
        </div>
    )
}

export default Header