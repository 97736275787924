import { createSlice } from "@reduxjs/toolkit";

import { DRIVE_INITIAL_STATE } from "./drive.initialState";

const drive = createSlice({
    name: 'drive',
    initialState: DRIVE_INITIAL_STATE,
    reducers: {
        setFileDetailLoading: (state, { payload }) => {
            state.fileDetail.isLoading = payload
        },
        setFileDetailData: (state, { payload }) => {
            state.fileDetail.data = payload
            state.fileDetail.message = DRIVE_INITIAL_STATE.fileDetail.message
        },
        setFileDetailMessage: (state, { payload }) => {
            state.fileDetail.message = payload
            state.fileDetail.data = DRIVE_INITIAL_STATE.fileDetail.data
        },
        resetFileDetail: (state) => {
            state.fileDetail = DRIVE_INITIAL_STATE.fileDetail
        },

        // FileList
        setFileListLoading: (state, { payload }) => {
            state.fileList.isLoading = payload
        },
        setFileListData: (state, { payload }) => {
            state.fileList.data = payload
            state.fileList.message = DRIVE_INITIAL_STATE.fileList.message
        },
        setFileListMessage: (state, { payload }) => {
            state.fileList.message = payload
            state.fileList.data = DRIVE_INITIAL_STATE.fileList.data
        },
        resetFileList: (state) => {
            state.fileList = DRIVE_INITIAL_STATE.fileList
        },

        // file upload
        setFileUploadLoading: (state, { payload }) => {
            state.fileUpload.isLoading = payload
        },
        setFileUploadData: (state, { payload }) => {
            state.fileUpload.data = payload;
        },
        setFileUploadMessage: (state, { payload }) => {
            state.fileUpload.message = payload;
        },
        resetFileUpload: (state) => {
            state.fileUpload = DRIVE_INITIAL_STATE.fileUpload;
        },

        // file download
        setFileDownloading: (state, { payload }) => {
            state.fileDownload.isLoading = payload
        },
        setFileDownloadData: (state, { payload }) => {
            state.fileDownload.data = payload
            state.fileDownload.message = DRIVE_INITIAL_STATE.fileDownload.message
        },
        setFileDownloadMessage: (state, { payload }) => {
            state.fileDownload.message = payload
            state.fileDownload.data = DRIVE_INITIAL_STATE.fileDownload.data
        },
        resetFileDownload: (state) => {
            state.fileDownload = DRIVE_INITIAL_STATE.fileDownload
        },

        // file download
        setFileViewLoading: (state, { payload }) => {
            state.fileView.isLoading = payload
        },
        setFileViewData: (state, { payload }) => {
            state.fileView.data = payload
            state.fileView.message = DRIVE_INITIAL_STATE.fileView.message
        },
        setFileViewMessage: (state, { payload }) => {
            state.fileView.message = payload
            state.fileView.data = DRIVE_INITIAL_STATE.fileView.data
        },
        resetFileView: (state) => {
            state.fileView = DRIVE_INITIAL_STATE.fileView
        },

        // user storage information
        setUserStorageLoading: (state, { payload }) => {
            state.userStorage.isLoading = payload
        },
        setUserStorageData: (state, { payload }) => {
            state.userStorage.data = payload
        },
        setUserStorageMessage: (state, { payload }) => {
            state.userStorage.message = payload
        },
        resetUserStorage: (state, { payload }) => {
            state.userStorage = DRIVE_INITIAL_STATE.userStorage
        },

        // user received files
        setUserReceivedFileListLoading: (state, { payload }) => {
            state.userReceivedFileList.isLoading = payload
        },
        setUserReceivedFileListData: (state, { payload }) => {
            state.userReceivedFileList.data = payload
        },
        setUserReceivedFileListMessage: (state, { payload }) => {
            state.userReceivedFileList.message = payload
        },
        resetUserReceivedFileList: (state, { payload }) => {
            state.userReceivedFileList = DRIVE_INITIAL_STATE.userReceivedFileList
        },

        // user Meeting recording
        setUserMeetingRecordingListLoading: (state, { payload }) => {
            state.userMeetingRecordingList.isLoading = payload
        },
        setUserMeetingRecordingListData: (state, { payload }) => {
            state.userMeetingRecordingList.data = payload
        },
        setUserMeetingRecordingListMessage: (state, { payload }) => {
            state.userMeetingRecordingList.message = payload
        },
        resetUserMeetingRecordingList: (state, { payload }) => {
            state.userMeetingRecordingList = DRIVE_INITIAL_STATE.userMeetingRecordingList
        },

        // recent open files
        setRecentOpenFileListLoading: (state, { payload }) => {
            state.recentOpenFileList.isLoading = payload
        },
        setRecentOpenFileListData: (state, { payload }) => {
            state.recentOpenFileList.data = payload
            state.recentOpenFileList.message = DRIVE_INITIAL_STATE.recentOpenFileList.message
        },
        setRecentOpenFileListMessage: (state, { payload }) => {
            state.recentOpenFileList.data = DRIVE_INITIAL_STATE.recentOpenFileList.data
            state.recentOpenFileList.message = payload
        },
        resetRecentOpenFileList: (state) => {
            state.recentOpenFileList = DRIVE_INITIAL_STATE.recentOpenFileList
        },

        // share file detail reducers
        setShareDriveFileDetailLoading: (state, {payload}) => {
            state.shareDriveFileDetail.isLoading = payload
        },
        setShareDriveFileDetailData: (state, {payload}) => {
            state.shareDriveFileDetail.data = payload
            state.shareDriveFileDetail.message = DRIVE_INITIAL_STATE.shareDriveFileDetail.message
        },
        setShareDriveFileDetailMessage: (state, {payload}) => {
            state.shareDriveFileDetail.message = payload
            state.shareDriveFileDetail.data = DRIVE_INITIAL_STATE.shareDriveFileDetail.data
        },
        resetShareDriveFileDetail: (state) => {
            state.shareDriveFileDetail = DRIVE_INITIAL_STATE.shareDriveFileDetail
        },

        // share file detail payload
        setShareDriveFileDetailPayload: (state, { payload }) => {
            state.shareDriveFileDetail.payload = payload
        },
        resetShareDriveFileDetailPayload: (state) => {
            state.shareDriveFileDetail.payload = DRIVE_INITIAL_STATE.shareDriveFileDetail.payload
        },
    },
})

export const {
    setFileDetailLoading,
    setFileDetailData,
    setFileDetailMessage,
    resetFileDetail,

    setFileListData,
    setFileListLoading,
    setFileListMessage,
    resetFileList,

    setFileUploadLoading,
    setFileUploadData,
    setFileUploadMessage,
    resetFileUpload,

    setFileDownloadData,
    setFileDownloadMessage,
    setFileDownloading,
    resetFileDownload,

    setFileViewLoading,
    setFileViewData,
    setFileViewMessage,
    resetFileView,

    setUserStorageData,
    setUserStorageLoading,
    setUserStorageMessage,
    resetUserStorage,

    setUserReceivedFileListLoading,
    setUserReceivedFileListData,
    setUserReceivedFileListMessage,
    resetUserReceivedFileList,

    setUserMeetingRecordingListData,
    setUserMeetingRecordingListLoading,
    setUserMeetingRecordingListMessage,
    resetUserMeetingRecordingList,

    setRecentOpenFileListData,
    setRecentOpenFileListLoading,
    setRecentOpenFileListMessage,
    resetRecentOpenFileList,

    setShareDriveFileDetailLoading,
    setShareDriveFileDetailData,
    setShareDriveFileDetailMessage,
    resetShareDriveFileDetail,

    setShareDriveFileDetailPayload,
    resetShareDriveFileDetailPayload,

} = drive.actions;

export default drive.reducer;