import { cn } from "utils/cn.utils";

import { InlineTextEditor } from "pages/auth/createCoursePreview/Components/InlineTextEditor";

import { useAppState } from "hooks/useStore";

const TitleEditor = () => {
    const { coursePreviewDetail } = useAppState((s) => s.course)

    return (
        <div className={cn("space-y-2")}>
            <div
                className={cn(
                    "px-1.5 md:p-px flex items-center justify-start gap-1"
                )}
            >
                <InlineTextEditor
                    rows={2}
                    textClassName="text-3xl font-bold text-white"
                    value={coursePreviewDetail?.data?.title}
                />
            </div>
            <div
                className={cn(
                    "px-1.5 md:p-px flex items-center justify-start gap-1"
                )}
            >
                <InlineTextEditor
                    rows={3}
                    textClassName={"text-base font-bodyPri font-normal text-white tracking-wide"}
                    value={coursePreviewDetail?.data?.subTitle}
                />
            </div>
        </div>
    );
}

export default TitleEditor;