import { useCallback, useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from "react-router-dom";

import { toast } from 'react-toastify';

import { IoMdArrowRoundBack } from "react-icons/io";
import { MdPlayLesson } from 'react-icons/md';

import ToolTipView from 'components/tooltipView';
import RefreshButton from 'components/common-components/RefreshButton';

import TopTabNavigation from 'pages/auth/editDownload/commonComponents/TopTabNavigation';
import StatusButton from 'pages/auth/editDownload/commonComponents/StatusButton';
import { downloadSettingsNavigationOptions, downloadTabConst } from 'pages/auth/editDownload/data';

import { getOwnerUserDownloadDetail, updateUserDownloadDetail } from 'redux/downloads/downloads.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetModifyUserDownloadDetail } from 'redux/downloads/downloads.slice';
import { downloadStatusEnum } from 'redux/downloads/downloads.const';

import { pagesInfo } from 'utils/pagesInfo';

const PageHeader = () => {
    const user = useAppState((state) => state.user.user)
    const ownerUserDownloadDetail = useAppState((state) => state.downloads.ownerUserDownloadDetail)
    const modifyUserDownloadDetail = useAppState((state) => state.downloads.modifyUserDownloadDetail)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const {downloadId} = useParams()

    const [downloadStatus, setDownloadStatus] = useState(ownerUserDownloadDetail?.data?.status)

    useEffect(() => {
        if (modifyUserDownloadDetail?.data && [downloadStatusEnum?.PUBLISHED?.value]?.includes(modifyUserDownloadDetail?.data?.status) && (downloadStatus !== downloadStatusEnum?.PUBLISHED?.value)) {
            dispatcher(resetModifyUserDownloadDetail())
            navigate(`${pagesInfo?.EDIT_DOWNLOAD?.pagePath}/${downloadId}/edit/${downloadSettingsNavigationOptions?.GENERAL?.to}`)
        }
    }, [modifyUserDownloadDetail?.data])

    useEffect(() => {
        if (ownerUserDownloadDetail?.data?.status) {
            setDownloadStatus(ownerUserDownloadDetail?.data?.status)
        }
    }, [ownerUserDownloadDetail?.data?.status])

    const onHandleBack = useCallback(() => {
        navigate(-1)
    }, [])

    const onHandleRefreshCourse = useCallback(() => {
        if (ownerUserDownloadDetail?.isLoading || modifyUserDownloadDetail?.isLoading) return;

        dispatcher(getOwnerUserDownloadDetail(ownerUserDownloadDetail?.data?.id, user?.user?.userId))
    }, [ownerUserDownloadDetail?.isLoading, modifyUserDownloadDetail?.isLoading, user?.user?.userId, ownerUserDownloadDetail?.data?.id])

    const onHandleUpdateStatus = useCallback((statusType) => {
        if (ownerUserDownloadDetail?.isLoading || modifyUserDownloadDetail?.isLoading) return;

        if (statusType?.value === downloadStatusEnum.DRAFT.value) {
            if (!window.confirm("Are you sure?. You want to save it as draft download.")) return;
        }
        if (statusType?.value === downloadStatusEnum.PUBLISHED.value) {
            if (!ownerUserDownloadDetail?.data?.title) {
                toast.warn("Please add title to publish this download.")
                return;
            }
            if (!window.confirm("Are you sure?. You want to publish this download.")) return;
        }
        if (statusType?.value === downloadStatusEnum.INACTIVE.value) {
            if (!window.confirm("Are you sure?. You want to deactivate this download.")) return;
        }
        dispatcher(updateUserDownloadDetail(ownerUserDownloadDetail?.data?.id, { status: statusType?.value }))
    }, [ownerUserDownloadDetail?.isLoading, modifyUserDownloadDetail?.isLoading, ownerUserDownloadDetail?.data?.id]);

    return (
        <>
            <div className={"sticky top-0 z-50 h-16 bg-white flex items-center justify-between drop-shadow-sm px-5"}>
                <div className={"h-10 flex justify-center items-center gap-2"}>
                    <ToolTipView content={"Back"}>
                        <button className={"p-2 rounded-full hover:bg-divider-darkLight cursor-pointer"} onClick={onHandleBack}>
                            <IoMdArrowRoundBack
                                className={'text-background-dark'}
                                size={25}
                            />
                        </button>
                    </ToolTipView>
                    <Link to={`${pagesInfo.DOWNLOADS.pagePath}`} className={"flex items-center justify-center gap-2  group"}>
                        <MdPlayLesson
                            size={30}
                            className={"text-primary-dark group-hover:text-secondary-dark"}
                        />
                        <p className={'font-bodyPri font-normal text-lg md:text-2xl text-text-900 group-hover:text-secondary-dark group-hover:underline'}>
                            {"Download"}
                        </p>
                    </Link>
                </div>
                <div className={"hidden md:flex items-center"}>
                    <TopTabNavigation tabs={Object.values(downloadTabConst)} />
                </div>
                <div className={'flex items-center justify-start gap-5'}>
                    <RefreshButton onHandleRefreshButton={onHandleRefreshCourse} />
                    <StatusButton
                        isLoading={modifyUserDownloadDetail?.isLoading}
                        loaderBtnClassName={`bg-${downloadStatusEnum[ownerUserDownloadDetail?.data?.status?.toUpperCase()]?.darkColor}`}
                        selectedBtnClassName={`text-white bg-${downloadStatusEnum[ownerUserDownloadDetail?.data?.status?.toUpperCase()]?.darkColor}`}
                        dropdownBtnClassName={`text-white bg-${downloadStatusEnum[ownerUserDownloadDetail?.data?.status?.toUpperCase()]?.darkColor}`}
                        optionsList={Object.values(downloadStatusEnum)?.filter((statusItem) => (statusItem?.value != ownerUserDownloadDetail?.data?.status))}
                        selectedOption={downloadStatusEnum[ownerUserDownloadDetail?.data?.status?.toUpperCase()]?.label}
                        onHandleUpdateStatus={onHandleUpdateStatus}
                    />
                </div>
            </div>
            <div className={"flex md:hidden justify-center items-center w-full rounded-lg px-3"}>
                <TopTabNavigation tabs={Object.values(downloadTabConst)} />
            </div>
        </>
    )
}

export default PageHeader;