import { useEffect, memo, useState, useCallback } from "react";
import { useParams } from "react-router-dom";

import { AiOutlinePlus } from "react-icons/ai";

import { cn } from "utils/cn.utils";

import ComponentLoader from "components/loader/ComponentLoader";
import InfinitePagination from "components/pagination/InfinitePagination";

import { uploadLmsMediaConst } from "components/modals/lmsModals/uploadLmsMediaModal/data";

import { getLmsQuestionPageResourceList } from "redux/edulyteLms/lmsQuestion/lmsQuestion.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetLocalTabs, setLocalTabs, setModal } from "redux/local/local.slice";
import { resetLmsQuestionPageResourceList } from "redux/edulyteLms/lmsQuestion/lmsQuestion.slice";
import { setFilterProps } from "redux/edulyteLms/lmsResource/lmsResource.slice";
import { modalConst } from "redux/local/local.const";
import { lmsResourceTypeEnum, resourceFileTypeEnum } from "redux/edulyteLms/lmsResource/lmsResource.const";

import { removeFileExtensionFromFileName } from "utils/generators.utils";

const QuestionResourcesListItems = memo(({ resourceSearch }) => {
    const { localTabs, modal } = useAppState((state) => state.local)
    const { lmsQuestionPageResourceList } = useAppState((state) => state.lms.lmsQuestion)
    const { filterProps } = useAppState((state) => state.lms.lmsResource)

    const dispatcher = useAppDispatcher()
    const { questionId } = useParams()

    const [isDragging, setIsDragging] = useState(false)

    useEffect(() => {
        if (questionId) {
            const requestDataPayload = {
                page: 1,
                records: 10,
                file_type: resourceFileTypeEnum?.IMAGE?.value,
                lms_question_id: `!${questionId}`
            }
            if (resourceSearch) {
                requestDataPayload["search"] = resourceSearch
            }
            dispatcher(getLmsQuestionPageResourceList(requestDataPayload))

            return () => {
                dispatcher(resetLmsQuestionPageResourceList())
            }
        }
    }, [questionId])


    const fetchMoreData = () => {
        if (lmsQuestionPageResourceList?.data?.page === lmsQuestionPageResourceList?.data?.totalPages) return;

        const requestDataPayload = {
            page: 1,
            records: lmsQuestionPageResourceList?.data?.records
                ? (lmsQuestionPageResourceList?.data?.records + 10)
                : 10,
            file_type: resourceFileTypeEnum?.IMAGE?.value
        }
        if (resourceSearch) {
            requestDataPayload["search"] = resourceSearch
        }
        dispatcher(getLmsQuestionPageResourceList(requestDataPayload))
    }

    const onHandleDragStart = (event, resourceObject) => {
        event.dataTransfer.setData('application/json', JSON.stringify(resourceObject))
    }


    const onHandleOpenResourceModal = useCallback(() => {
        dispatcher(resetLocalTabs())
        dispatcher(setLocalTabs({
            ...localTabs,
            tabList: Object.values(uploadLmsMediaConst),
            activeTabList: [uploadLmsMediaConst.UPLOAD.value],
            currentActiveTab: uploadLmsMediaConst.UPLOAD.value,
            tabItem: {
                ...localTabs?.tabItem,
                payload: null
            }
        }))
        dispatcher(setFilterProps({
            filterProps: {
                ...filterProps,
                resourceType: lmsResourceTypeEnum.FILE.value,
                resourceFileType: resourceFileTypeEnum.IMAGE.value,
                disabledFilters: [lmsResourceTypeEnum.YOUTUBE.value, lmsResourceTypeEnum.LINK.value]
            }
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.UPLOAD_LMS_MEDIA_MODAL.stateKey]: true,
        }))
    }, [modal, localTabs])

    return (
        <>
            {lmsQuestionPageResourceList?.isLoading &&
                <ComponentLoader isLoading={lmsQuestionPageResourceList?.isLoading} />
            }
            {lmsQuestionPageResourceList?.message &&
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"font-bodyPri font-medium text-text-900 text-sm"}>
                        {lmsQuestionPageResourceList?.message || "No Image Added"}
                    </span>
                </div>
            }
            {lmsQuestionPageResourceList?.data && (lmsQuestionPageResourceList?.data?.results?.length === 0) &&
                <div className={"w-full flex items-center justify-center"}>
                    <button
                        className={cn(
                            'w-full flex justify-center items-center gap-1 bg-white rounded-lg px-3 py-1.5',
                            'bg-text-200 hover:bg-text-300 ',
                            'font-bodyPri font-normal text-text-900 text-sm tracking-wide whitespace-nowrap'
                        )}
                        onClick={onHandleOpenResourceModal}
                    >
                        <AiOutlinePlus className={''} />
                        <span className={""}>
                            {"Add Image"}
                        </span>
                    </button>
                </div>
            }
            {(lmsQuestionPageResourceList?.data && (lmsQuestionPageResourceList?.data?.results?.length > 0)) &&
                <InfinitePagination
                    scrollableTarget={"scrollableDiv"}
                    dataLength={lmsQuestionPageResourceList?.data?.totalRecords}
                    fetchData={fetchMoreData}
                    hasMore={(lmsQuestionPageResourceList?.data?.page != lmsQuestionPageResourceList?.data?.totalPages)}
                    loader={<ComponentLoader isLoading={lmsQuestionPageResourceList?.isLoading} />}
                    isShowEndMessage={false}
                >
                    {lmsQuestionPageResourceList?.data?.results?.map((resourceItem) => (
                        <div
                            key={resourceItem?.id}
                            className={cn(
                                'mb-2 hover:bg-background-lightBlue rounded p-1 line-clamp-1',
                                isDragging ? "cursor-grabbing" : "cursor-grab"
                            )}
                            draggable={true}
                            onDragStart={(event) => onHandleDragStart(event, resourceItem)}
                            onMouseDown={() => setIsDragging(true)}
                            onMouseUp={() => setIsDragging(false)}
                            onMouseMove={() => {
                                if (isDragging) {
                                    setIsDragging(false)
                                }
                            }}
                        >
                            <span className={"font-bodyPri font-normal"}>
                                {removeFileExtensionFromFileName(resourceItem?.title)}
                            </span>
                        </div>
                    ))}
                </InfinitePagination>
            }
        </>
    )
})

export default QuestionResourcesListItems;