import { useEffect } from 'react';
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { GiGraduateCap } from "react-icons/gi";
import { FaRegComment } from "react-icons/fa";
import { MdOutlineVideoCall, MdVerified } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';
import Pagination from 'components/pagination/Pagination';
import CourseCardTwo from 'components/courseCardTwo/CourseCardTwo';
import FullPageLoader from 'components/loader/FullPageLoader';
import Icon from "components/Icon";
import RatingStars from "components/ratingStars/RatingStars";
import ReviewCard from "components/ReviewCard";
import Footer1 from 'components/footer1/Footer1';

import { PageContentStyle } from './TutorCoursePage.style';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getFBChat, getFBChatId } from 'redux/firebase/firebase.request';
import { getTutorUserPublicProfileByUserId } from 'redux/tutor/tutor.request';
import {
    createCourseFavoriteDetail,
    deleteCourseFavoriteDetail,
    getPublicTutorCourseList
} from "redux/course/course.request";
import { getTutorReviewListByTutorId } from "redux/review/review.request";

import { useTitle } from 'hooks/useTitle';
import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setMessageTemplate } from 'redux/firebase/firebase.slice';
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { appointmentPriceModelEnum } from 'redux/appointment/appointment.const';
import { blueTickEnum } from 'redux/user/user.const';
import { setClearTutorPublicProfile } from "redux/tutor/tutor.slice";
import { setClearTutorReviewList } from 'redux/review/review.slice';
import { setClearPublicTutorCourseList } from "redux/course/course.slice";

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';

const TutorCourse = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { session } = useAppState(s => s.auth)
    const { messageTemplate } = useAppState((s) => s.firebase)
    const { user } = useAppState((s) => s.user)
    const { modal, locals } = useAppState(s => s.local)
    const { tutorPublicProfile } = useAppState((s) => s.tutor)
    const { publicTutorCourseList, addCourseFavDetail, deleteCourseFavDetail } = useAppState((s) => s.course)
    const { tutorReviewList } = useAppState((s) => s.review)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()
    const { userId } = useParams()
    const [title, setTitle] = useTitle()

    const isMyOffering = user?.user?.userId === tutorPublicProfile?.tutorPublicProfile?.userId

    const NEW_TUTOR_PUBLIC_PROFILE_RATING = 2;

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.TUTOR_COURSE))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (userId) {
            dispatcher(getTutorUserPublicProfileByUserId(Number(userId)))
        }

        return () => {
            dispatcher(setClearTutorPublicProfile())
        }
    }, [userId])

    useEffect(() => {
        if (tutorPublicProfile?.tutorPublicProfile?.tutorId) {
            const query = generateQueryParams(location.search)

            dispatcher(getPublicTutorCourseList(tutorPublicProfile?.tutorPublicProfile?.tutorId, { page: query?.pn || 1, records: 12 }))
            dispatcher(getTutorReviewListByTutorId(tutorPublicProfile?.tutorPublicProfile?.tutorId))
        }

        return () => {
            dispatcher(setClearPublicTutorCourseList())
            dispatcher(setClearTutorReviewList())
        }
    }, [location.search, tutorPublicProfile?.tutorPublicProfile])


    useEffect(() => {
        if (tutorPublicProfile?.tutorPublicProfile) {
            setTitle({
                ...title,
                title: `Classes Offered by ${tutorPublicProfile?.tutorPublicProfile?.firstName} | Book Now!`,
                description: tutorPublicProfile?.tutorPublicProfile?.bio
            })
        }
    }, [tutorPublicProfile?.tutorPublicProfile])

    const handleMessage = async () => {
        if (!session?.isLoggedIn) {
            dispatcher(setModal({
                ...modal,
                [modalConst.AUTHORIZATION_MODAL.stateKey]: true
            }))
            return;
        }

        if (isMyOffering) return;

        const userIdOne = user?.user?.userId
        const userIdTwo = tutorPublicProfile?.tutorPublicProfile?.userId

        const chatId = await getFBChatId(userIdOne, userIdTwo)
        let fbChat = await getFBChat(chatId)

        if (!fbChat || fbChat === undefined) {
            dispatcher(setMessageTemplate({
                ...messageTemplate,
                isLoading: false,
                userIdOne: userIdOne,
                userIdTwo: userIdTwo,
                tutorUserId: tutorPublicProfile?.tutorPublicProfile?.userId,
                message: '',
                errorMsg: null
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: true
            }))
            return;
        }

        navigate(`${pagesInfo?.MESSAGES?.pagePath}/${fbChat.chatId}`)
    }

    const handleTrialBooking = () => {
        navigate(`${pagesInfo.APPOINTMENT.pagePath}/${tutorPublicProfile?.tutorPublicProfile?.appointment?.slug}`)
    }

    if (tutorPublicProfile?.isLoading) {
        return (
            <FullPageLoader isLoading={tutorPublicProfile?.isLoading} />
        )
    }

    return (
        <PageContentStyle>
            <div className={'space-y-3'}>
                <div className={'w-full mx-auto container'}>
                    <div className={"grid grid-cols-12 gap-5 p-5"}>
                        <div className={"relative col-start-1 col-span-12 lg:col-start-1 lg:col-span-4"}>
                            <div className={"lg:sticky lg:top-[5rem] bg-white shadow-all rounded-lg p-5"}>
                                <div className={"flex flex-col items-start justify-start md:flex-row md:gap-x-10 lg:flex-col lg:gap-3"}>
                                    <div className={"flex flex-row flex-[0] md:flex-[4] lg:flex-[0] items-start justify-start md:flex-col md:items-center md:justify-center lg:flex-row lg:items-start gap-5 md:gap-3 lg:gap-5 "}>
                                        <div className={"flex flex-col items-start justify-start gap-5"}>
                                            <div className={"relative w-24 h-24 rounded-full"}>
                                                <Link to={`${pagesInfo.TUTOR.pagePath}/${tutorPublicProfile?.tutorPublicProfile?.userId}`}>
                                                    <img
                                                        src={tutorPublicProfile?.tutorPublicProfile?.profilePicUrl}
                                                        alt="profileImg"
                                                        className="w-full h-full rounded-full p-2 object-cover"
                                                    />
                                                </Link>
                                                <div className={"relative has-tooltip"}>
                                                    <div className={'absolute -top-9 right-1 w-6 h-6 rounded-full overflow-hidden border-2 border-white'}>
                                                        <img
                                                            src={`https://flagcdn.com/16x12/${tutorPublicProfile?.tutorPublicProfile?.fromCountry?.countryDomain}.png`.toLowerCase()}
                                                            alt={"country-flag"}
                                                            className='w-full h-full object-cover'
                                                        />
                                                    </div>
                                                    <span
                                                        className={cn(
                                                            "w-16 px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -top-3 -right-0",
                                                            "flex flex-col items-center justify-start overflow-hidden"
                                                        )}
                                                    >
                                                        {tutorPublicProfile?.tutorPublicProfile?.fromCountry?.country}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-start justify-start md:items-center md:justify-center lg:items-start lg:justify-start gap-3 text-2xl font-bodyPri tracking-wide">
                                            <div className={"flex items-center justify-start md:justify-center lg:justify-start gap-3"}>
                                                <Link
                                                    to={`${pagesInfo.TUTOR.pagePath}/${tutorPublicProfile?.tutorPublicProfile?.userId}`}
                                                    className="flex items-center justify-start gap-2 font-bodyPri tracking-wide text-text-800 text-left md:text-center lg:text-left">
                                                    {tutorPublicProfile?.tutorPublicProfile?.firstName} {tutorPublicProfile?.tutorPublicProfile?.lastName?.charAt(0) + "."}
                                                    {tutorPublicProfile?.tutorPublicProfile?.onlineStatus == "online" && <div className={"w-3 h-3 rounded-full bg-green-600"}></div>}
                                                </Link>
                                                {(tutorPublicProfile?.tutorPublicProfile?.blueTick === blueTickEnum?.YES?.value) &&
                                                    <div className='relative has-tooltip cursor-pointer'>
                                                        <MdVerified className={"text-lg text-primary-dark"} />
                                                        <span className='tooltip w-fit px-2 py-1 bg-black text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide sm:whitespace-nowrap -translate-x-[50%] -translate-y-[150%] md:-translate-y-[190%]'>
                                                            {"Verified Account"}
                                                        </span>
                                                    </div>}
                                                {tutorPublicProfile?.tutorPublicProfile?.instantBooking &&
                                                    <div className="">
                                                        <Icon type="light" />
                                                    </div>
                                                }
                                            </div>
                                            <div className="flex flex-col items-center justify-center gap-1">
                                                {tutorPublicProfile?.tutorPublicProfile?.rating?.avgRating > NEW_TUTOR_PUBLIC_PROFILE_RATING
                                                    ? <><RatingStars
                                                        rating={tutorPublicProfile?.tutorPublicProfile?.rating?.avgRating}
                                                        fullRating={5}
                                                    />
                                                        <div className="font-normal text-xs text-complementry-main">
                                                            {tutorPublicProfile?.tutorPublicProfile?.rating?.avgRating} ({tutorPublicProfile?.tutorPublicProfile?.rating?.totalStars?.count?.toFixed(1)})
                                                        </div>
                                                    </>
                                                    : <div className='text-sm font-bodyPri tracking-wide text-complementry-main text-center'>
                                                        {"New"}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"flex flex-[0] md:flex-[6] lg:flex-[0] flex-col item-start justify-start gap-5"}>
                                        <div className={"space-y-3"}>
                                            <div className="font-bodyPri text-lg text-text-800 line-clamp-2">
                                                {tutorPublicProfile?.tutorPublicProfile?.elevatorPitch}
                                            </div>
                                            <div className='h-1 w-5 bg-secondary-main rounded-lg'></div>
                                        </div>

                                        <div className='flex flex-row items-center justify-start gap-2'>
                                            {tutorPublicProfile?.tutorPublicProfile?.badges?.slice(0, 6)?.map((badge, index) => (
                                                <div
                                                    key={index}
                                                    className='relative has-tooltip w-8 h-8 rounded-full border border-text-500 p-0.5 cursor-pointer'>
                                                    <img
                                                        src={badge?.badge?.badgePicUrl}
                                                        className={"w-full h-full object-cover"}
                                                        alt={badge?.badge?.tooltip}
                                                    />
                                                    <span className='tooltip top-8 -left-5 w-fit px-2 py-1 bg-black text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide sm:whitespace-nowrap'>
                                                        {badge?.badge?.tooltip}
                                                    </span>
                                                </div>
                                            ))}
                                            {tutorPublicProfile?.tutorPublicProfile?.badges?.length > 6 &&
                                                <span className='relative has-tooltip text-primary-main font-medium text-lg cursor-pointer'>
                                                    {`+${tutorPublicProfile?.tutorPublicProfile?.badges?.length - 6}`}
                                                    <span className='tooltip top-8 -left-5 w-20 px-2 py-1 bg-black text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide'>
                                                        {"see more"}
                                                    </span>
                                                </span>
                                            }
                                        </div>

                                        <div className="flex flex-col gap-3">
                                            <div className="flex items-center text-base font-bodyPri tracking-wide gap-x-2">
                                                <FaRegComment className="text-text-500" size={24} />
                                                <span className="font-normal font-bodyPri tracking-wide text-text-600">
                                                    {"Speaks:"}
                                                </span>
                                                {tutorPublicProfile?.tutorPublicProfile?.languages?.length > 0
                                                    ? <span className={"font-medium font-bodyPri tracking-wide text-text-800 text-medium"}>
                                                        {tutorPublicProfile?.tutorPublicProfile?.languages?.slice(0, 2)?.map((language) => language?.language)?.join(" | ")}
                                                        {tutorPublicProfile?.tutorPublicProfile?.languages?.length > 2 &&
                                                            <span className='font-medium text-blue-500'>
                                                                {` +${tutorPublicProfile?.tutorPublicProfile?.languages?.length - 2}`}
                                                            </span>
                                                        }
                                                    </span>
                                                    : "-"
                                                }
                                            </div>
                                            <div className="flex items-start justify-start text-base font-bodyPri tracking-wide gap-x-2">
                                                <GiGraduateCap className='text-text-500' size={24} />
                                                <span className="font-normal font-bodyPri tracking-wide text-text-600">
                                                    {"Teaches:"}
                                                </span>
                                                {tutorPublicProfile?.tutorPublicProfile?.categories?.length > 0
                                                    ? <span className="font-medium font-bodyPri tracking-wide text-text-800 text-medium">
                                                        {tutorPublicProfile?.tutorPublicProfile?.categories.slice(0, 2).join(" | ")}
                                                        {tutorPublicProfile?.tutorPublicProfile?.categories?.length > 2 &&
                                                            <Link
                                                                to={`${pagesInfo?.TUTOR?.pagePath}/${tutorPublicProfile?.tutorPublicProfile?.userId}`}
                                                                className='font-medium text-blue-500' >
                                                                {` +${tutorPublicProfile?.tutorPublicProfile?.categories?.length - 2}`}
                                                            </Link>
                                                        }
                                                    </span>
                                                    : "-"
                                                }
                                            </div>

                                            {tutorPublicProfile?.tutorPublicProfile?.appointment &&
                                                <>
                                                    <div className={"flex justify-start items-center gap-x-2"}>
                                                        <MdOutlineVideoCall className="text-gray-700 text-xl" />
                                                        <span className="text-text-700 font-normal font-bodyPri whitespace-nowrap">
                                                            {"Trial Session:"}
                                                        </span>
                                                        <div className="flex gap-1">
                                                            {[appointmentPriceModelEnum?.FLAT_FEE?.value]?.includes(tutorPublicProfile?.tutorPublicProfile?.appointment?.appointment_price?.price_model) &&
                                                                <span className="text-text-900 font-medium font-bodyPri flex gap-1">
                                                                    {`${tutorPublicProfile?.tutorPublicProfile?.appointment?.appointment_price?.masterCurrency?.code} ` + parseFloat((tutorPublicProfile?.tutorPublicProfile?.appointment?.appointment_price?.price - ((tutorPublicProfile?.tutorPublicProfile?.appointment?.appointment_price?.price * tutorPublicProfile?.tutorPublicProfile?.appointment?.appointment_price?.discount_pct) / 100)) / 100).toFixed(2)}
                                                                </span>
                                                            }
                                                            {[appointmentPriceModelEnum?.FREE?.value]?.includes(tutorPublicProfile?.tutorPublicProfile?.appointment?.appointment_price?.price_model) &&
                                                                <span className="text-text-900 font-medium font-bodyPri flex gap-1">
                                                                    {"Free"}
                                                                </span>
                                                            }
                                                            <span className="text-text-900 font-medium font-bodyPri flex gap-1">
                                                                ({tutorPublicProfile?.tutorPublicProfile?.appointment?.appointment_availability?.duration < 60
                                                                    ? tutorPublicProfile?.tutorPublicProfile?.appointment?.appointment_availability?.duration + " min"
                                                                    : (tutorPublicProfile?.tutorPublicProfile?.appointment?.appointment_availability?.duration / 60)?.toFixed(2) + ' hour(s)'
                                                                })
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className='flex items-center w-full justify-center gap-5'>
                                            {tutorPublicProfile?.tutorPublicProfile?.appointment &&
                                                <span onClick={handleTrialBooking} className={cn(
                                                    'w-1/2 py-1 flex justify-center items-center gap-1 rounded-lg font-normal font-buttons border',
                                                    "border-green-700 text-text-50 bg-green-700 hover:border-secondary-dark hover:bg-secondary-dark hover:text-text-50 cursor-pointer"
                                                )}>
                                                    {"Book a Trial"}
                                                </span>
                                            }
                                            <span onClick={handleMessage} className={cn(
                                                'w-1/2 py-1 flex justify-center items-center gap-1 rounded-lg font-normal font-buttons border',
                                                isMyOffering
                                                    ? "border-text-300 text-text-300"
                                                    : "border-primary-dark text-primary-dark hover:bg-primary-dark hover:text-text-50 cursor-pointer"
                                            )}>
                                                {"Send Message"}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"col-start-1 col-span-12 lg:col-start-5 lg:col-span-8 rounded-lg space-y-5"}>
                            <div className={"flex flex-wrap items-center justify-center md:justify-start gap-x-3 gap-y-5"}>
                                {publicTutorCourseList?.isLoading && <ComponentLoader isLoading={publicTutorCourseList?.isLoading} />}
                                {(!publicTutorCourseList?.isLoading && publicTutorCourseList?.data?.records > 0)
                                    && publicTutorCourseList?.data?.results?.map((course, idx) => (
                                        <div className={"w-64"}>
                                            <CourseCardTwo
                                                key={idx}
                                                course={course}
                                                isFav={user?.user?.favoriteCourses?.filter((favCourse) =>
                                                    favCourse.courseId === course.id).length > 0 ? true : false}
                                                handleFav={(courseId) => {
                                                    if (addCourseFavDetail?.isLoading || deleteCourseFavDetail?.isLoading) return;
                                                    const favoriteCourses = user?.user?.favoriteCourses?.filter((favCourse) =>
                                                        favCourse.courseId === courseId)
                                                    if (favoriteCourses?.length > 0) {
                                                        dispatcher(deleteCourseFavoriteDetail({ userId: user?.user?.userId, courseId: courseId }))
                                                    }
                                                    else {
                                                        dispatcher(createCourseFavoriteDetail({ userId: user?.user?.userId, courseId: courseId }))
                                                    }
                                                }}
                                            />
                                        </div>
                                    ))}
                                {(publicTutorCourseList?.data && publicTutorCourseList?.data.totalPages > 1) && (
                                    <div className="w-full flex justify-center items-center">
                                        <Pagination
                                            page={publicTutorCourseList?.data?.page}
                                            totalPages={publicTutorCourseList?.data?.totalPages}
                                            onChangePage={(page) => navigate(`${pagesInfo?.TUTOR_COURSE?.pagePath}/${userId}/classes?pn=${page}`)}
                                        />
                                    </div>
                                )}
                                {(publicTutorCourseList?.data?.records === 0 || publicTutorCourseList.message) &&
                                    <div className='w-full h-full bg-white p-5 rounded-lg text-center text-base font-bodyPri tracking-wide'>
                                        {publicTutorCourseList.message || "No Classes Found!"}
                                    </div>
                                }
                            </div>
                            {tutorReviewList?.isLoading &&
                                <ComponentLoader isLoading={tutorReviewList?.isLoading} className={"w-full h-fit flex items-center justify-center"} />
                            }
                            {(!tutorReviewList?.isLoading && tutorReviewList?.filteredTutorReviewList?.reviews?.length > 0) &&
                                <div className={"flex flex-col items-center justify-center md:items-start md:justify-end gap-3 p-5 bg-white rounded-lg"}>
                                    <div className="flex items-center justify-start">
                                        <span className='font-bodyPri font-medium text-lg tracking-wide text-text-900'>
                                            {"Reviews"}
                                        </span>
                                        {tutorReviewList?.filteredTutorReviewList?.rating?.avgRating && <RatingStars rating={tutorReviewList?.filteredTutorReviewList?.rating?.avgRating?.toFixed(1)} fullRating={5} />}
                                        <span className="font-medium text-md">
                                            {tutorReviewList?.filteredTutorReviewList?.avgRating?.toFixed(1)} ({tutorReviewList?.filteredTutorReviewList?.rating?.totalStars?.count})
                                        </span>
                                    </div>
                                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-8 lg:gap-5'>
                                        {tutorReviewList?.filteredTutorReviewList?.reviews?.map((ele, idx) => (
                                            <div className='col-span-1'>
                                                <ReviewCard
                                                    key={idx}
                                                    rating={ele?.rating}
                                                    content={ele?.review}
                                                    profileImg={ele?.provider?.profilePicUrl}
                                                    fName={ele?.provider?.firstName}
                                                    lName={ele?.provider?.lastName}
                                                    createdAt={ele?.createdAt}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            {(tutorReviewList?.filteredTutorReviewList?.reviews?.length === 0 || tutorReviewList?.errorMsg) &&
                                <div className='w-full bg-white p-5 rounded-lg flex flex-col items-start justify-start gap-5'>
                                    <span className='font-bodyPri font-medium text-lg tracking-wide text-text-900'>
                                        {"Reviews"}
                                    </span>
                                    <span className={"text-base font-bodyPri font-normal tracking-wide"}>
                                        {tutorReviewList?.errorMsg || "No reviews Found!"}
                                    </span>
                                </div>
                            }
                        </div>
                        {tutorPublicProfile?.errorMsg &&
                            <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                                {tutorPublicProfile?.errorMsg}
                            </div>
                        }
                    </div>
                </div>
                <Footer1 />
            </div>
        </PageContentStyle>
    )
}

export default TutorCourse;