export const courseSteps = {
    SELECT_A_TEMPLATE: {
        label: "Select a Template",
        value: "select_a_template",
        stepNo: 1,
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/create-course/select-a-template.png"
    },
    BASIC_INPUTS: {
        label: "Basic Inputs",
        value: "basic_inputs",
        stepNo: 2,
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/create-course/basic-input.png"
    },
    CONFIRM_SCHEDULE: {
        label: "Confirm Schedule",
        value: "confirm_schedule",
        stepNo: 3,
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/create-course/confirm-schedule.png"
    },
    SET_PRICING: {
        label: "Set Pricing",
        value: "set_pricing",
        stepNo: 4,
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/create-course/set-pricing.png"
    },
    PREVIEW_AND_STATUS: {
        label: "Preview & Status",
        value: "preview_and_status",
        stepNo: 5,
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/create-course/preview-and-status.png"
    },
}

export const editBtnConst = {
    sessionDuration: {
        value: 'sessionDuration'
    },
    sessionCount: {
        value: 'sessionCount'
    },
    studentCapacity: {
        value: 'studentCapacity'
    },
    startDate: {
        value: 'startDate'
    },
    endDate: {
        value: 'endDate'
    },
    weeklyTimeSlots: {
        value: 'weeklyTimeSlots'
    },
    skills: {
        value: 'skills'
    },
    courseTitle: {
        value: 'title'
    },
    courseTitleDescription: {
        value: 'subTitle'
    },
    price: {
        value: 'price'
    },
    discountPct: {
        value: 'discountPct'
    },
    proficiency: {
        value: 'proficiency'
    },
    language: {
        value: 'language'
    },
    visibility: {
        value: 'visibility'
    },
    category: {
        value: 'category'
    },
    status: {
        value: 'status'
    },
    comments: {
        value: 'comments'
    },
}

export const inputSections = {
    COURSE_DETAILS: {
        value: "course_details"
    },
    SKILLS: {
        value: "skills"
    },
    COURSE_DESCRIPTION: {
        value: "course_description"
    },
    COURSE_VIDEO: {
        value: "course_video,"
    },

    COURSE_THUMBNAIL: {
        value: 'course_thumbnail'
    },
    COURSE_LANGUAGE: {
        value: "course_language"
    },
    COURSE_PROFICIENCY: {
        value: "course_proficiency"
    },
    COURSE_TUTORS: {
        value: "course_tutors"
    },
    CLASS_SCHEDULE: {
        value: "class_schedule"
    },
    PRICE_DETAILS: {
        value: "price_details"
    }
}

export const groupRepeatSession = {
    RECURRING: {
        label: "Repeat weekly",
        value: "recurring"
    },
    NON_RECURRING: {
        label: "Doesn't repeat",
        value: "non_recurring"
    }
}

export const oneOnOneRepeatSession = {
    RECURRING: {
        label: "Repeat weekly",
        value: "recurring"
    }
}

export const oneOnOnePricingModelType = {
    PER_HOUR: {
        label: "Per Hour",
        value: "per_hour"
    }
}

export const groupPricingModelType = {
    FLAT_FEE: {
        label: "Flat Fee",
        value: "flat_fee"
    },
    PER_HOUR: {
        label: "Per Hour",
        value: "per_hour"
    },
    PER_SESSION: {
        label: "Per Session",
        value: "per_session"
    }
}

export const TempCoursePriceType = {
    REGULAR: {
        label: "Regular",
        value: "regular"
    }
}

export const sortTimeSlots = (timeSlot1, timeSlot2) => {
    return timeSlot2?.startTime < timeSlot1?.startTime ? 1 : -1
}

export const sortDateTimeSlots = (dateTime1, dateTime2) => {
    return dateTime2?.date < dateTime1?.date ? 1 : -1
}


export const classTemplateTooltip = "See the demo data from selected template.Please complete all the steps to customise your class."

export const titleToolTip = "Use attention-grabbing words. Include the subject keywords and write in Title Case. Keep it short and simple with the spotlight on class outcomes. Mention it is a one-on-one class (Up to 10 words / 70 characters)"

export const subTitleToolTip = "Expand on the class title / name and talk about the benefits of your class and the progress students will make through it.  Compel the reader to view class details. (30-60 words)"

export const classTypeTooltip = "Select another template to change the class type."

export const visibilityTooltip = "Visibility can not be changed later, please keep it public if you want visitors to discover your class in search results."

export const courseDescriptionToolTip = "Include all the relevant details of your class. Give an accurate overview of key content, uniqueness of your teaching style, innovative instructional, examples, and conclusion. Do not forget to mention it will be a personalised class catering to learners’ learning needs."

export const videoTooltip = "You have the option to upload a new, short video specifically for this class, or you can choose to keep your default introductory video."

export const thumbnailTooltip = "Find a free thumbnail image to capture the essence of your video or class, providing viewers with a visual preview."

export const skillsToolTip = "Write 3-5 skills your learners will acquire in this class. Drag, drop and save to organise their display order."

export const languageTooltip = "Write language(s) of instructions. Drag, drop and save to organise their display order."

export const proficiencyTooltip = `Specify the level of difficulty that best suits your learners in this class, such as 'Beginner,' 'Intermediate', or 'Advanced.'`

export const sessionDurationTooltip = "Update total duration of each session.Slots are in the multiple of 15 minutes."

export const repeatTooltip = "Choose start time and we’ll calculate the session end times. Select “Repeat weekly” for a recurring schedule every week Conduct your session every week at a fixed schedule using repeat weekly option or add any date/time of your choice."

export const startDateTooltip = "Choose any future date to start your class, please give sufficient time to your audience."

export const endsTooltip = "You can offer ongoing classes with no end date, customers will pay weekly, monthly or yearly. You can choose a fixed end date or enter number of weeks you would like to conduct sessions for this class."

export const priceTypeTooltip = "Set your fee for this class, any applicable platform fee will be deducted from sales."

export const planNameTooltip = "Select a plan and save. you must have subscription plan with price to attach with this class, if you just created a new plan, please use refresh button."

export const oneOnOneRegularPriceTooltip = "Set your hourly fee for this class, customers can choose their session duration starting from 30 minute. Platform fee will be deducted, if applicable."

export const oneOnOneRegularDiscountTooltip = "Offer discount on the above price to make it attractive or keep at zero discount. This is in addition to any discounts you may offer for bulk purchase"

export const regularPriceModelTooltip = "During checkout, learners will make a one-time payment for the entire class. The FLAT FEE reflects the total amount to be paid at the time of checkout. The PER-SESSION price calculates the overall cost based on the number of sessions, irrespective of their duration. The PER-HOUR fee considers the duration, number of sessions, and hourly fee to calculate the total class fee."