import React from "react";

//Context imports
import { useUser } from "../../contexts/userContext";
import { useChat } from "../../contexts/chatContext";

//Component imports
import ChatViewMessageRead from "..//ChatViewMessageRead/ChatViewMessageRead";

//Helper functions
import getTimestamps from "../../helpers/getTimestamps";
import showProfilePicture from "../../helpers/showProfilePicture";

//Style imports
import {
  StyledChatViewMessageText,
  StyledChatViewMessage,
  StyledChatViewMessageTextTime,
} from "./ChatViewMessage.styles";

//icon imports
import placeholder from "../../icons/placeholder.png";

export default function ChatViewMessage({ message, isGroup }) {
  const { user } = useUser();
  const { messages, selectedChat, groupMembers } = useChat();
  const { sender, sentAt, status, text } = message;

  const { userId } = user;

  const isSender = sender === userId;
  const seen = status === "seen";

  const otherUser = selectedChat.users.filter(
    (chatUser) => chatUser !== userId
  )[0];

  function getImageToDisplay() {
    if (selectedChat.type === "group") {
      const member = groupMembers?.filter((member) => member.id === sender)[0];
      const photoUrl = member ? member.image : placeholder;
      return photoUrl;
    } else {
      return selectedChat[otherUser].image;
    }
  }

  const showPhoto = showProfilePicture(message, messages);

  const timeToDisplay = getTimestamps(sentAt);

  const imageToDisplay = getImageToDisplay();

  return (
    <StyledChatViewMessage isSender={isSender}>
      {showPhoto && !isSender && (
        <img
          className="w-12 h-12 rounded-full mr-2"
          src={imageToDisplay}
          alt="profile"
        />
      )}
      {!showPhoto && <div className="w-12 h-12 rounded-full mr-2"></div>}
      <StyledChatViewMessageText isSender={isSender}>
        <p>{text}</p>
        <StyledChatViewMessageTextTime isSender={isSender}>
          {timeToDisplay}
        </StyledChatViewMessageTextTime>
      </StyledChatViewMessageText>
      {isSender && !isGroup && <ChatViewMessageRead seen={seen} />}
    </StyledChatViewMessage>
  );
}
