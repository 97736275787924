import { orderByConst, orientationTypeConst } from "redux/other/other.const"

const thumbnailDetailPayload = {
    keywords: null,
    query: "Education",
    orientation: orientationTypeConst.LANDSCAPE.value,
    dimensionUnit: 1,
    size: "",
    page: 1,
    perPage: 99,
    orderBy: orderByConst.RELEVANT.value,
    url: ""
}

export const OTHER_INITIAL_STATE = {
    thumbnailList: {
        isLoading: false,
        thumbnailList: null,
        errorMsg: null
    },
    thumbnailDetail: {
        isLoading: false,
        thumbnailDetailPayload: thumbnailDetailPayload,
        errorMsg: null
    }
}