import { createSlice } from "@reduxjs/toolkit";
import { BOOKING_INITIAL_STATE } from "redux/booking/booking.initialState";

const booking = createSlice({
    name: "booking",
    initialState: BOOKING_INITIAL_STATE,
    reducers: {
        // Booking
        // Add Booking Detail Reducers
        setAddBookingDetailLoading: (state, { payload }) => {
            state.addBookingDetail.isLoading = payload
            if (payload) state.addBookingDetail.message = BOOKING_INITIAL_STATE.addBookingDetail.message
        },
        setAddBookingDetailPayload: (state, { payload }) => {
            state.addBookingDetail.payload = payload
            state.addBookingDetail.message = BOOKING_INITIAL_STATE.addBookingDetail.message
        },
        setAddBookingDetailMessage: (state, { payload }) => {
            state.addBookingDetail.message = payload
        },
        resetAddBookingDetail: (state) => {
            state.addBookingDetail = BOOKING_INITIAL_STATE.addBookingDetail
        },

        // Booking Detail Reducers
        setBooking_DetailLoading: (state, { payload }) => {
            state.booking_Detail.isLoading = payload
        },
        setBooking_DetailData: (state, { payload }) => {
            state.booking_Detail.data = payload
            state.booking_Detail.message = BOOKING_INITIAL_STATE.booking_Detail.message
        },
        setBooking_DetailMessage: (state, { payload }) => {
            state.booking_Detail.message = payload
            state.booking_Detail.data = BOOKING_INITIAL_STATE.booking_Detail.detail
        },
        resetBooking_Detail: (state) => {
            state.booking_Detail = BOOKING_INITIAL_STATE.booking_Detail
        },

        // Initialize Booking Detail Reducers
        setInitializeBookingDetailLoading: (state, { payload }) => {
            state.initializeBookingDetail.isLoading = payload
            if (payload) state.initializeBookingDetail.message = BOOKING_INITIAL_STATE.initializeBookingDetail.message
        },
        setInitializeBookingDetailData: (state, { payload }) => {
            state.initializeBookingDetail.data = payload
            state.initializeBookingDetail.message = BOOKING_INITIAL_STATE.initializeBookingDetail.message
        },
        setInitializeBookingDetailMessage: (state, { payload }) => {
            state.initializeBookingDetail.message = payload
            state.initializeBookingDetail.data = BOOKING_INITIAL_STATE.initializeBookingDetail.data
        },
        resetInitializeBookingDetail: (state) => {
            state.initializeBookingDetail = BOOKING_INITIAL_STATE.initializeBookingDetail
        },

        // Booking Detail Confirmation Reducers
        setBookingDetailConfirmationLoading: (state, { payload }) => {
            state.bookingDetailConfirmation.isLoading = payload
            if (payload) state.bookingDetailConfirmation.message = BOOKING_INITIAL_STATE.bookingDetailConfirmation.message
        },
        setBookingDetailConfirmationData: (state, { payload }) => {
            state.bookingDetailConfirmation.data = payload
            state.bookingDetailConfirmation.message = BOOKING_INITIAL_STATE.bookingDetailConfirmation.message
        },
        setBookingDetailConfirmationMessage: (state, { payload }) => {
            state.bookingDetailConfirmation.message = payload
            state.bookingDetailConfirmation.data = BOOKING_INITIAL_STATE.bookingDetailConfirmation.data
        },
        resetBookingDetailConfirmation: (state) => {
            state.bookingDetailConfirmation = BOOKING_INITIAL_STATE.bookingDetailConfirmation
        },

        // User Booking
        // User Booking List Reducers
        setUserBookingListLoading: (state, { payload }) => {
            state.userBookingList.isLoading = payload
        },
        setUserBookingListData: (state, { payload }) => {
            state.userBookingList.data = payload
            state.userBookingList.message = BOOKING_INITIAL_STATE.userBookingList.message
        },
        setUserBookingListMessage: (state, { payload }) => {
            state.userBookingList.message = payload
            state.userBookingList.data = BOOKING_INITIAL_STATE.userBookingList.data
        },
        resetUserBookingList: (state) => {
            state.userBookingList = BOOKING_INITIAL_STATE.userBookingList
        },
        // User Booking Detail Reducers
        setUserBookingDetailLoading: (state, { payload }) => {
            state.userBookingDetail.isLoading = payload
        },
        setUserBookingDetailData: (state, { payload }) => {
            state.userBookingDetail.data = payload
            state.userBookingDetail.message = BOOKING_INITIAL_STATE.userBookingDetail.message
        },
        setUserBookingDetailMessage: (state, { payload }) => {
            state.userBookingDetail.message = payload
            state.userBookingDetail.data = BOOKING_INITIAL_STATE.userBookingDetail.detail
        },
        resetUserBookingDetail: (state) => {
            state.userBookingDetail = BOOKING_INITIAL_STATE.userBookingDetail
        },
        // Modify User Booking Detail Reducers
        setModifyUserBookingDetailLoading: (state, { payload }) => {
            state.modifyUserBookingDetail.isLoading = payload
            if (payload) state.modifyUserBookingDetail.message = BOOKING_INITIAL_STATE.modifyUserBookingDetail.message
        },
        setModifyUserBookingDetailPayload: (state, { payload }) => {
            state.modifyUserBookingDetail.payload = payload
        },
        setModifyUserBookingDetailMessage: (state, { payload }) => {
            state.modifyUserBookingDetail.message = payload
        },
        resetModifyUserBookingDetail: (state) => {
            state.modifyUserBookingDetail = BOOKING_INITIAL_STATE.modifyUserBookingDetail
        },
        // user pre booking detail
        setUserPreBookingDetailLoading: (state, { payload }) => {
            state.userPreBookingDetail.isLoading = payload
        },
        setUserPreBookingDetailData: (state, { payload }) => {
            state.userPreBookingDetail.data = payload
            state.userPreBookingDetail.message = BOOKING_INITIAL_STATE.userPreBookingDetail.message
        },
        setUserPreBookingDetailMessage: (state, { payload }) => {
            state.userPreBookingDetail.message = payload
            state.userPreBookingDetail.data = BOOKING_INITIAL_STATE.userPreBookingDetail.data
        },
        setClearUserPreBookingDetail: (state) => {
            state.userPreBookingDetail = BOOKING_INITIAL_STATE.userPreBookingDetail
        },

        // tutor booking
        // tutor booking list
        setTutorBookingListLoading: (state, { payload }) => {
            state.tutorBookingList.isLoading = payload
        },
        setTutorBookingListData: (state, { payload }) => {
            state.tutorBookingList.data = payload
            state.tutorBookingList.message = BOOKING_INITIAL_STATE.tutorBookingList.message
        },
        setTutorBookingListMessage: (state, { payload }) => {
            state.tutorBookingList.message = payload
            state.tutorBookingList.data = BOOKING_INITIAL_STATE.tutorBookingList.data
        },
        resetTutorBookingList: (state) => {
            state.tutorBookingList = BOOKING_INITIAL_STATE.tutorBookingList
        },

        // tutor booking detail
        setTutorBookingDetailLoading: (state, { payload }) => {
            state.tutorBookingDetail.isLoading = payload
        },
        setTutorBookingDetailData: (state, { payload }) => {
            state.tutorBookingDetail.data = payload
            state.tutorBookingDetail.message = BOOKING_INITIAL_STATE.tutorBookingDetail.message
        },
        setTutorBookingDetailMessage: (state, { payload }) => {
            state.tutorBookingDetail.message = payload
            state.tutorBookingDetail.data = BOOKING_INITIAL_STATE.tutorBookingDetail.data
        },
        resetTutorBookingDetail: (state) => {
            state.tutorBookingDetail = BOOKING_INITIAL_STATE.tutorBookingDetail
        },

        // add booking session detail
        setAddBookingSessionDetailLoading: (state, { payload }) => {
            state.addBookingSessionDetail.isLoading = payload
        },
        setAddBookingSessionDetailData: (state, { payload }) => {
            state.addBookingSessionDetail.data = payload
            state.addBookingSessionDetail.message = BOOKING_INITIAL_STATE.addBookingSessionDetail.message
        },
        setAddBookingSessionDetailMessage: (state, { payload }) => {
            state.addBookingSessionDetail.message = payload
            state.addBookingSessionDetail.data = BOOKING_INITIAL_STATE.addBookingSessionDetail.data
        },
        resetAddBookingSessionDetail: (state) => {
            state.addBookingSessionDetail = BOOKING_INITIAL_STATE.addBookingSessionDetail
        },

        // cancel booking session detail
        setCancelBookingSessionDetailLoading: (state, { payload }) => {
            state.cancelBookingSessionDetail.isLoading = payload
        },
        setCancelBookingSessionDetailData: (state, { payload }) => {
            state.cancelBookingSessionDetail.data = payload
            state.cancelBookingSessionDetail.message = BOOKING_INITIAL_STATE.cancelBookingSessionDetail.message
        },
        setCancelBookingSessionDetailMessage: (state, { payload }) => {
            state.cancelBookingSessionDetail.message = payload
            state.cancelBookingSessionDetail.data = BOOKING_INITIAL_STATE.data
        },
        resetCancelBookingSessionDetail: (state) => {
            state.cancelBookingSessionDetail = BOOKING_INITIAL_STATE.cancelBookingSessionDetail
        },

        // cancel booking session detail payload
        setCancelBookingSessionDetailPayload: (state, { payload }) => {
            state.cancelBookingSessionDetail.payload = payload
        },
        resetCancelBookingSessionDetailPayload: (state) => {
            state.cancelBookingSessionDetail.payload = BOOKING_INITIAL_STATE.cancelBookingSessionDetail.payload
        },

        // OLD REDUCERS
        // reducers for booking detail payload
        setBookingDetailPayload: (state, { payload }) => {
            state.bookingDetail.bookingDetailPayload = payload
        },
        setClearBookingDetailPayload: (state) => {
            state.bookingDetail.bookingDetailPayload = BOOKING_INITIAL_STATE.bookingDetail.bookingDetailPayload
        },

        // reducers for add booking
        setAddBookingLoading: (state, { payload }) => {
            state.addBooking.isLoading = payload
            if (payload) state.addBooking.errorMsg = null
        },
        setAddBooking: (state, { payload }) => {
            state.addBooking.addBooking = payload
            state.addBooking.errorMsg = BOOKING_INITIAL_STATE.addBooking.errorMsg
        },
        setAddBookingErrorMsg: (state, { payload }) => {
            state.addBooking.errorMsg = payload
            state.addBooking.addBooking = BOOKING_INITIAL_STATE.addBooking.addBooking
        },
        setClearAddBooking: (state) => {
            state.addBooking = BOOKING_INITIAL_STATE.addBooking
        },
        setAddBookingPayload: (state, { payload }) => {
            state.addBooking.addBookingPayload = payload
        },
        setClearAddBookingPayload: (state) => {
            state.addBooking.addBookingPayload = BOOKING_INITIAL_STATE.addBooking.addBookingPayload
        },
        setChooseTimeSlotPayload: (state, { payload }) => {
            state.addBooking.chooseTimeSlotPayload = payload
        },
        setClearChooseTimeSlotPayload: (state) => {
            state.addBooking.chooseTimeSlotPayload = BOOKING_INITIAL_STATE.addBooking.chooseTimeSlotPayload
        },

        // reducers for filter booking list
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps;
        },
        setClearFilterProps: (state) => {
            state.filterProps = BOOKING_INITIAL_STATE.filterProps
        }
    }
})

export const {
    setAddBookingDetailLoading,
    setAddBookingDetailPayload,
    setAddBookingDetailMessage,
    resetAddBookingDetail,

    setBooking_DetailLoading,
    setBooking_DetailData,
    setBooking_DetailMessage,
    resetBooking_Detail,

    setInitializeBookingDetailLoading,
    setInitializeBookingDetailData,
    setInitializeBookingDetailMessage,
    resetInitializeBookingDetail,

    setBookingDetailConfirmationLoading,
    setBookingDetailConfirmationData,
    setBookingDetailConfirmationMessage,
    resetBookingDetailConfirmation,

    setUserBookingListLoading,
    setUserBookingListData,
    setUserBookingListMessage,
    resetUserBookingList,

    setUserBookingDetailLoading,
    setUserBookingDetailData,
    setUserBookingDetailMessage,
    resetUserBookingDetail,

    setModifyUserBookingDetailLoading,
    setModifyUserBookingDetailPayload,
    setModifyUserBookingDetailMessage,
    resetModifyUserBookingDetail,

    setUserPreBookingDetailLoading,
    setUserPreBookingDetailData,
    setUserPreBookingDetailMessage,
    setClearUserPreBookingDetail,

    setTutorBookingListLoading,
    setTutorBookingListData,
    setTutorBookingListMessage,
    resetTutorBookingList,

    setTutorBookingDetailLoading,
    setTutorBookingDetailData,
    setTutorBookingDetailMessage,
    resetTutorBookingDetail,

    setAddBookingSessionDetailLoading,
    setAddBookingSessionDetailData,
    setAddBookingSessionDetailMessage,
    resetAddBookingSessionDetail,

    setCancelBookingSessionDetailLoading,
    setCancelBookingSessionDetailData,
    setCancelBookingSessionDetailMessage,
    resetCancelBookingSessionDetail,

    setCancelBookingSessionDetailPayload,
    resetCancelBookingSessionDetailPayload,

    setBookingDetailPayload,
    setClearBookingDetailPayload,
    
    // OLD ACTION
    setAddBookingLoading,
    setAddBooking,
    setAddBookingErrorMsg,
    setClearAddBooking,
    setAddBookingPayload,
    setClearAddBookingPayload,
    setChooseTimeSlotPayload,
    setClearChooseTimeSlotPayload,

    setFilterProps,
    setClearFilterProps,
} = booking.actions

export default booking.reducer