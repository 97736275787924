import { modifyCrmBulkContactListPayload,  addCrmContactDetailPayload, modifyCrmContactDetailPayload } from "./crm.const";

export const CRM_INITIAL_STATE = {
    crmContactList: {
        isLoading: false,
        data: null,
        message: null
    },
    crmLeadList: {
        isLoading: false,
        data: null,
        message: null
    },
    modifyCrmBulkContactList: {
        isLoading: false,
        payload: modifyCrmBulkContactListPayload,
        message: null
    },
    destroyCrmBulkContactList: {
        isLoading: false,
        message: null
    },
    crmContactDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addCrmContactDetail: {
        isLoading: false,
        data: null,
        payload: addCrmContactDetailPayload,
        message: null
    },
    modifyCrmContactDetail: {
        isLoading: false,
        data: null,
        payload: modifyCrmContactDetailPayload,
        message: null
    },
    destroyCrmContactDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    // crm contact status list
    crmContactStatusList: {
        isLoading: false,
        data: null,
        message: null
    },
    crmModalContactStatusList: {
        isLoading: false,
        data: null,
        message: null
    },

    // crm contact label
    crmContactLabelList: {
        isLoading: false,
        data: null,
        message: null
    },
    addCrmContactLabelDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    modifyCrmContactLabelDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    // add contact label
    addContactLabelDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    destroyContactLabelDetail: {
        isLoading: false,
        message: null
    },

    // contact emails
    addCrmContactEmailDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    modifyCrmContactEmailDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    destroyCrmContactEmailDetail: {
        isLoading: false,
        message: null
    },

    // contact phones
    addCrmContactPhoneDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    modifyCrmContactPhoneDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    destroyCrmContactPhoneDetail: {
        isLoading: false,
        message: null
    },

    // contact socials
    addCrmContactSocialDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    modifyCrmContactSocialDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    destroyCrmContactSocialDetail: {
        isLoading: false,
        message: null
    },

    // contact addresses
    addCrmContactAddressDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    modifyCrmContactAddressDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    destroyCrmContactAddressDetail: {
        isLoading: false,
        message: null
    },

    // contact notes
    addCrmContactNoteDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    modifyCrmContactNoteDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    destroyCrmContactNoteDetail: {
        isLoading: false,
        message: null
    },
}