import { useEffect } from 'react';
import { cn } from "utils/cn.utils";

import { FiUser } from 'react-icons/fi';
import { MdOutlineEmail } from 'react-icons/md';
import { BsCheckAll } from 'react-icons/bs';
import { MdCancel } from 'react-icons/md';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setMakePaymentPayload } from 'redux/payment/payment.slice';

import { validateEmail } from 'utils/validation-functions';


const UserInfoForm = ({ error, setError }) => {
    const { session } = useAppState(s => s.auth)
    const { user } = useAppState(s => s.user)
    const { makePayment } = useAppState(s => s.payment)
    const makePaymentPayload = makePayment?.makePaymentPayload

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (makePaymentPayload?.email && !validateEmail(makePaymentPayload?.email)) {
            setError({ ...error, emailError: 'Enter valid Email!' });
        } else {
            setError({ ...error, emailError: '' });
        }
    }, [makePaymentPayload?.email])

    const handleOnChange = (event) => {
        dispatcher(setMakePaymentPayload({
            ...makePaymentPayload,
            [event.target.name]: event.target.value
        }))
    }

    return (
        <div className={"flex flex-col gap-2"}>
            <div className={"flex items-center gap-2"}>
                <div className={"w-full border-2 border-divider-medium bg-white rounded-md py-1 px-2 flex items-center gap-2"}>
                    <FiUser className={"text-text-500 text-base"} />
                    <input
                        className={"text-text-900 text-base font-bodyPri w-full focus:outline-none bg-white"}
                        type='text'
                        disabled={session?.isLoggedIn || user?.user}
                        name='firstName'
                        placeholder='First Name'
                        value={makePaymentPayload?.firstName}
                        onChange={handleOnChange}
                    />
                </div>
                <div className={"w-full border-2 border-divider-medium bg-white rounded-md py-1 px-2 flex items-center gap-2"}>
                    <FiUser className={"text-text-500 text-base"} />
                    <input
                        className={"text-text-900 text-base font-bodyPri w-full focus:outline-none bg-white"}
                        type='text'
                        disabled={session?.isLoggedIn || user?.user}
                        name='lastName'
                        placeholder='Last Name'
                        value={makePaymentPayload?.lastName}
                        onChange={handleOnChange}
                    />
                </div>
            </div>
            <div className={cn(
                "w-full border-2 border-divider-medium bg-white rounded-md py-1 px-2 flex items-center justify-between gap-2"
            )}>
                <div className={"flex items-center justify-start gap-2"}>
                    <MdOutlineEmail className={"text-text-500 text-base"} />
                    <input
                        className={"text-text-900 text-base font-bodyPri w-full focus:outline-none bg-white"}
                        type='email'
                        disabled={(session?.isLoggedIn || user?.user) && makePaymentPayload?.email}
                        name='email'
                        placeholder='Enter your email'
                        value={makePaymentPayload?.email}
                        onChange={handleOnChange}
                    />
                </div>
                {makePaymentPayload?.email && error.emailError.length === 0 && <BsCheckAll className={"text-green-500 text-base"} />}
                {makePaymentPayload?.email && error.emailError.length > 0 && <MdCancel className={"text-red-800 text-base"} />}
            </div>
        </div>
    )
}

export default UserInfoForm;