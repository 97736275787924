import { MUX_DEMO_PLAYBACK_ID } from "const/default.const";

export const url = {
    TUTOR_PUBLIC_LIST: "/tutors/public",
    TUTOR_USER_PUBLIC_PROFILE: "/tutors/users/{userId}/public",
    MY_TUTOR_USER_LIST: "/tutors/users/{userId}/my-tutors",
    FAV_TUTOR_USER_LIST: "/tutors/users/{userId}/fav-tutors",
    TUTOR_USER_DETAIL: "/tutors/users/{userId}/details",
    TUTOR_USER_PRICE: "/tutors/users/{userId}/prices",
    TUTOR_USER_LESSON: "/tutors/users/{userId}/lessons",
    TUTOR_USER_LANGUAGE_LIST: "/tutors/users/{userId}/languages",
    TUTOR_LANGUAGE_DETAIL: "/tutors/languages/{tutorLanguageId}",
    TUTOR_USER_CATEGORY_LIST: "/tutors/users/{userId}/categories",
    TUTOR_CATEGORY_DETAIL: "/tutors/categories/{tutorCategoriesId}",

    // new modified urls
    GET_TUTOR_LANGUAGE_LIST: "/tutors/languages",
    ADD_TUTOR_LANGUAGE_DETAIL: "/tutors/languages",
    UPDATE_TUTOR_LANGUAGE_DETAIL: "/tutors/languages/{tutorLanguageId}",
    UPDATE_TUTOR_LANGUAGE_LIST_ORDER: "/tutors/languages/order",
    DELETE_TUTOR_LANGUAGE_DETAIL: "/tutors/languages/{tutorLanguageId}",

    GET_TUTOR_TAG_LIST: "/tutors/tags",
    ADD_TUTOR_TAG_DETAIL: "/tutors/tags",
    UPDATE_TUTOR_TAG_LIST_ORDER: "/tutors/tags/order",
    DELETE_TUTOR_TAG_DETAIL: "/tutors/tags/{tagId}",

    GET_TUTOR_CATEGORY_LIST: "/tutors/categories",
    ADD_TUTOR_CATEGORY_DETAIL: "/tutors/categories",
    UPDATE_TUTOR_CATEGORY_DETAIL: "/tutors/categories/{categoryId}",
    UPDATE_TUTOR_CATEGORY_LIST_ORDER: "/tutors/categories/order",
    DELETE_TUTOR_CATEGORY_DETAIL: "/tutors/categories/{categoryId}",

    // new tutor onboarding tutor urls
    GET_TUTOR_DETAIL: "/tutors/tutors/{tutorId}",
    ADD_TUTOR_DETAIL: "/tutors/tutors",
    UPDATE_TUTOR_DETAIL: "/tutors/tutors/{tutorId}",
}

export const DEFAULT_TUTOR_PAGE = 1
export const DEFAULT_TUTOR_RECORDS = 15

export const tutorSortBy = {
    RELEVANCE: {
        label: "Relevance",
        value: "relevance"
    },
    PRICELOWFIRST: {
        label: "Price lowest first",
        value: "priceLowFirst"
    },
    PRICEHIGHFIRST: {
        label: "Price highest first",
        value: "priceHighFirst"
    }
}

export const weekOptions = {
    SUNDAY: {
        label: "Sun",
        value: "Sunday"
    },
    MONDAY: {
        label: "Mon",
        value: "Monday"
    },
    TUESDAY: {
        label: "Tue",
        value: "Tuesday"
    },
    WEDNESDAY: {
        label: "Wed",
        value: "Wednesday"
    },
    THURSDAY: {
        label: "Thu",
        value: "Thursday"
    },
    FRIDAY: {
        label: "Fri",
        value: "Friday"
    },
    SATURDAY: {
        label: "Sat",
        value: "Saturday"
    }
}

export const tutorMessageType = {
    INFO: {
        label: "Info",
        value: "info",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    ERROR: {
        label: "Error",
        value: "error",
        lightColor: "red-100",
        darkColor: "red-500"
    }
}

export const filterName = {
    FIND_TUTORS: {
        value: "find_tutors"
    },
    ALL_TUTORS: {
        value: "all_tutors"
    },
    FAV_TUTORS: {
        value: "fav_tutors"
    }
}

export const addTutorUserDetailPayload = {
    elevatorPitch: "",
    bio: "",
    teachingStyleBio: "",
    videoUrl: MUX_DEMO_PLAYBACK_ID
}

export const tutorFilterProps = {
    page: DEFAULT_TUTOR_PAGE,
    records: DEFAULT_TUTOR_RECORDS,
    sortBy: tutorSortBy?.RELEVANCE?.value,
    search: "",
    minHrsPrice: 0,
    maxHrsPrice: 0,
    roleStatus: [],
    language: [],
    languageLevel: [],
    segment: [],
    category: [],
    proficiency: [],
    tutorFrom: [],
    day: []
}
