import { cn } from "utils/cn.utils";

import { onboardingTooltipConst, profileEditBtnConst } from 'pages/auth/onboarding/data';
import SaveCancelBtn from 'pages/auth/onboarding/commonComponents/onboardingButtons/SaveCancelBtn';
import OnboardingTooltip from 'pages/auth/onboarding/commonComponents/OnboardingTooltip';

import { updateTutorUserDetailByUserId } from "redux/tutor/tutor.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setAddTutorUserDetailPayload } from "redux/tutor/tutor.slice";


const MIN_CHARACTER_LENGTH = 50
const MAX_CHARACTER_LENGTH = 700

const AboutMe = ({ editBtnConst, setEditBtnConst }) => {
    const { user } = useAppState((state) => state.user)
    const { tutorUserDetail, addTutorUserDetail } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()

    const onHandleSave = () => {
        if (tutorUserDetail?.isLoading || addTutorUserDetail?.isLoading) return;

        if (!(addTutorUserDetail?.payload?.bio.length >= MIN_CHARACTER_LENGTH && addTutorUserDetail?.payload?.bio.length <= MAX_CHARACTER_LENGTH)) {
            alert("Please enter between 50 to 700 characters")
            return;
        }
        dispatcher(updateTutorUserDetailByUserId(user?.user?.userId, { bio: addTutorUserDetail?.payload?.bio }))
        setEditBtnConst(null)
    }


    const onHandleCancel = () => {
        if (tutorUserDetail?.isLoading || addTutorUserDetail?.isLoading) return;
        dispatcher(setAddTutorUserDetailPayload({
            ...addTutorUserDetail?.payload,
            bio: tutorUserDetail?.tutorUserDetail?.bio
        }))
        setEditBtnConst(null)
    }

    const onHandleChange = (value) => {
        if (tutorUserDetail?.isLoading || addTutorUserDetail?.isLoading) return;

        if (!editBtnConst || editBtnConst !== profileEditBtnConst?.ABOUT_ME?.value) {
            setEditBtnConst(profileEditBtnConst?.ABOUT_ME?.value)
        }
        dispatcher(setAddTutorUserDetailPayload({
            ...addTutorUserDetail?.payload,
            bio: value
        }))
    }

    return (
        <div className='w-full flex flex-col items-start justify-start gap-1'>
            <div className={"w-full flex items-center justify-between gap-2"}>
                <div className={"flex items-center justify-start gap-1"}>
                    <OnboardingTooltip tooltip={onboardingTooltipConst?.aboutMeTooltip} translateX={0} translateY={-100} />
                    <span className='font-bodyPri font-medium text-text-900 text-base tracking-wide'>
                        {"About Me"}
                    </span>
                </div>
                <SaveCancelBtn
                    isLoading={addTutorUserDetail?.isLoading}
                    isShowBtn={(editBtnConst === profileEditBtnConst?.ABOUT_ME?.value) || addTutorUserDetail?.isLoading}
                    onHandleSave={onHandleSave}
                    onHandleCancel={onHandleCancel}
                />
            </div>
            <textarea
                type={"text"}
                placeholder={"Tell us about yourself ..."}
                rows={5}
                maxLength={MAX_CHARACTER_LENGTH}
                value={addTutorUserDetail?.payload?.bio}
                className={cn(
                    "w-full px-3 py-1 resize-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                    "font-bodyPri font-normal text-text-800 text-base",
                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                )}
                onChange={(e) => onHandleChange(e.target.value)}
                disabled={editBtnConst && editBtnConst !== profileEditBtnConst?.ABOUT_ME?.value}
            />
            <div className={"w-full flex justify-between"}>
                <div className={""}>
                    {((addTutorUserDetail?.payload?.bio?.length < MIN_CHARACTER_LENGTH) || (addTutorUserDetail?.payload?.bio?.length > MAX_CHARACTER_LENGTH)) &&
                        <span className="font-bodyPri font-normal tracking-wide text-red-500 text-xs">
                            {"Please enter between 50 and 700 characters"}
                        </span>
                    }
                </div>
                <div className='flex justify-end'>
                    <span className="font-bodyPri font-normal text-text-800 text-xs">
                        {addTutorUserDetail?.payload?.bio?.length + "/" + MAX_CHARACTER_LENGTH}
                    </span>
                </div>
            </div>
            {(tutorUserDetail?.errorMsg || addTutorUserDetail?.errorMsg) &&
                <span className={"flex items-center justify-center font-bodyPri font-normal text-red-500 tracking-wide text-center"}>
                    {tutorUserDetail?.errorMsg || addTutorUserDetail?.errorMsg}
                </span>
            }
        </div>
    )
}

export default AboutMe;