import { createSlice } from "@reduxjs/toolkit";
import { CATEGORY_INITIAL_STATE } from "redux/category/category.initialState";
import { filterName } from "redux/category/category.const"

const category = createSlice({
    name: "category",
    initialState: CATEGORY_INITIAL_STATE,
    reducers: {
        // reducers for category list
        setCategoryListLoading: (state, { payload }) => {
            state.categoryList.isLoading = payload
        },
        setCategoryList: (state, { payload }) => {
            state.categoryList.categoryList = payload
            state.categoryList.filteredCategoryList = payload
            state.categoryList.errorMsg = CATEGORY_INITIAL_STATE.categoryList.errorMsg
        },
        setCategoryListErrorMsg: (state, { payload }) => {
            state.categoryList.errorMsg = payload
            state.categoryList.categoryList = CATEGORY_INITIAL_STATE.categoryList.categoryList
            state.categoryList.filteredCategoryList = CATEGORY_INITIAL_STATE.categoryList.filteredCategoryList
        },
        setClearCategoryList: (state) => {
            state.categoryList = CATEGORY_INITIAL_STATE.categoryList
        },

        // filter props for category list
        setfilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
            const filteredList = payload.list
                .filter((category) => (
                    !payload.filterProps.searchText || category.category.includes(payload.filterProps.searchText)
                ))
            switch (payload.filterName) {
                case filterName.CATEGORY.value:
                    state.categoryList.filteredCategoryList = filteredList
                    break;
                default:
                    break;
            }
        },
        setClearFilterProps: (state) => {
            state.filterProps = CATEGORY_INITIAL_STATE.filterProps
        }
    }
})

export const {
    setCategoryListLoading,
    setCategoryList,
    setCategoryListErrorMsg,
    setClearCategoryList,

    setfilterProps,
    setClearFilterProps
} = category.actions

export default category.reducer