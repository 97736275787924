import { useState, memo } from 'react';

import { MdChevronRight } from "react-icons/md";

import { Toggle } from 'components/common-components/Toggle';
import { FaSpinner } from 'react-icons/fa';

const IntergrationCard = memo(({ card, active, onActive, loading = false }) => {
    const [toggle, setToggle] = useState(active)

    const onHandleActive = (key, value) => {
        if (loading) return;

        setToggle(value)
        onActive(key, value)
    }

    return (
        <div className='flex flex-col justify-between rounded shadow overflow-hidden'>
            <div className='flex items-center justify-center gap-3 p-5 bg-background-light'>
                <img
                    className='h-10 object-contain'
                    src={card?.logo}
                    alt={card?.name}
                />
                <h3 className='font-medium text-lg whitespace-nowrap'>{card?.name}</h3>
            </div>
            <div className='space-y-2 px-5 py-8'>
                <h2 className='font-medium'>{card?.title}</h2>
                <p className='text-xs text-text-600 pb-3'>{card?.subTitle}</p>
                <a
                    href={card?.learnMore}
                    target='_black'
                    className='flex items-center gap-0.5 text-xs text-primary-main hover:text-secondary-dark'
                >
                    {"Learn more"}
                    <MdChevronRight size={16} />
                </a>
            </div>
            <div className='flex items-center justify-between gap-3 p-5 border-t border-t-text-300'>
                <p className='text-sm font-medium'>{"Enable Intergration"}</p>
                {loading ? (
                    <FaSpinner className={"animate-spin text-lg text-primary-main"} />
                ) : (
                    <Toggle value={toggle} onChange={() => onHandleActive(card?.key, !toggle)} />
                )
                }
            </div>
        </div>
    )
})

export default IntergrationCard;
