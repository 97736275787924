import ComponentLoader from "components/loader/ComponentLoader";

import { cn } from "utils/cn.utils";

export default function AppointmentTable({
    isLoading = false,
    headers,
    rows,
    className = "",
    title,
    alternateRowColor = "bg-gray-100",
    rowHeaderColor = "bg-gray-100",
}) {

    return (
        <div className={"min-w-full border-b border-gray-200 rounded-md shadow overflow-x-auto scrollbar-thin"}>
            {title && (
                <p className="px-6 pt-6 font-bold tracking-wider text-left text-slate-900">
                    {title}
                </p>
            )}
            {/* table */}
            <table className={"w-full divide-y divide-gray-200"}>
                <thead className={cn(rowHeaderColor)}>
                    <tr>
                        {headers.map((head, index) => (
                            <th
                                key={index}
                                scope="col"
                                className={cn(
                                    "px-5 py-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
                                    "font-bold whitespace-nowrap"
                                )}
                            >
                                {head}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {isLoading &&
                        <tr>
                            <td colSpan={headers?.length}>
                                <ComponentLoader isLoading={true} className="bg-white" />
                            </td>
                        </tr>
                    }
                    {(!isLoading && rows?.length > 0) && rows?.map((row, idx) => (
                        <tr
                            key={idx}
                            className={cn(
                                idx % 2 === 0 ? "bg-white" : alternateRowColor
                            )}
                        >
                            {row?.map((r, index) => (
                                <td key={index} className="px-5 py-2.5 whitespace-nowrap">
                                    <div className="text-sm text-gray-900 capitalize">
                                        {r}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            {!isLoading && (!rows || (rows?.length === 0)) &&
                <div className="w-full bg-white rounded-md p-5 flex justify-center items-center font-bodyPri font-medium text-text-800 text-base tracking-wide whitespace-nowrap">
                    {"No Data Found!"}
                </div>
            }
        </div>
    );
}