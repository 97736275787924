import { memo, useEffect, useMemo, useState } from 'react';

import { cn } from "utils/cn.utils";

import { MdEdit } from 'react-icons/md';
import { FaSpinner } from 'react-icons/fa';

import FadedCollapse from 'components/fadedCollapse';
import ViewOnlyQuillEditor from 'components/textEditor/ViewOnlyQuillEditor';
import QuillEditor from 'components/textEditor/QuillEditor';

import SaveAndCancelBtn from './lmsButtons/SaveAndCancelBtn';
import useAiText from 'hooks/useAiText';
import { toast } from 'react-toastify';
import AiTextButton from 'components/createWithAi/aiTextButton';

const Editor = {
    modules: {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'size': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'list': 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'color': [] }, { 'background': [] }]
        ],
        clipboard: {
            matchVisual: false,
        }
    },
    formats: [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'blockquote', 'code-block',
        'list', 'bullet', 'indent',
        'color', 'background',
    ]
}

const LmsEditorDescription = memo(({
    isLoading = false,
    isEditorVisible = false,
    descriptionPlaceholder,
    customDescriptionPlaceholderContainer = () => { },
    customDescriptionContentContainer = () => { },
    description = "",
    onHandleSaveDescription,
    descriptionContainerClassName = "",
    editorContainerClassName = "",
    descriptionTextContainerClassName = "",
    descriptionPlaceholderStyle = "",
    editorClassName = "",
    isShowAiBtn = false
}) => {
    const [isMouseHovered, setIsMouseHovered] = useState(false)
    const [isShowEditor, setIsShowEditor] = useState(isEditorVisible)
    const [descriptionInput, setDescriptionInput] = useState(description)
    const [isShowInput, setIsShowInput] = useState(false)
    const [topic, setTopic] = useState('')

    const { aiText, generateAiText } = useAiText()

    const has_content = useMemo(() => (!!new DOMParser()?.parseFromString(`<div>${descriptionInput}</div>`, "text/html")?.querySelector("div")?.textContent), [descriptionInput])

    const contentLength = useMemo(() => {
        const parsedHTML = new DOMParser().parseFromString(`<div>${descriptionInput}</div>`, "text/html");
        const textContent = parsedHTML.querySelector("div")?.textContent || '';
        return textContent.length > 200;
    }, [descriptionInput]);

    const onHandleChangeInputText = (content) => {
        setDescriptionInput(content)
    }

    const onHandleSaveContent = () => {
        onHandleSaveDescription(descriptionInput)
        setIsShowEditor(false)
    }

    const onHandleCloseEditor = () => {
        setDescriptionInput(description)
        setIsShowEditor(false)
    }

    const requestData = {
        purpose: "description", // description or title 
        total_chars: 200,
        topic: topic,
        response_type: "html" // text or html 
    };

    const getAiText = () => {
        if (topic === "") {
            toast.error("Please write ai prompt");
            return
        }
        setIsShowInput(false)
        generateAiText(requestData);
    };

    const handlePrompt = (e) => {
        const value = e.target.value
        setTopic(value)
    }

    useEffect(() => {
        if (aiText?.data?.ai_response) {
            setDescriptionInput(aiText.data.ai_response);
            onHandleSaveDescription(aiText.data.ai_response)
        }
    }, [aiText?.data]);

    const defaultDescriptionPlaceholderContainer = () => {
        return (
            <div className={"w-full flex items-center justify-between"}>
                <span
                    className={cn(
                        "font-bodyPri font-normal text-text-700 group-hover:text-primary-main text-sm cursor-pointer line-clamp-2",
                        descriptionPlaceholderStyle
                    )}
                    onClick={() => setIsShowEditor(!isShowEditor)}
                >
                    {descriptionPlaceholder}
                </span>
            </div>
        )
    }

    const defaultDescriptionContentContainer = () => {
        return (
            <div className={"w-full bg-white hover:bg-background-lightBlue"}>
                <ViewOnlyQuillEditor
                    editorContent={descriptionInput}
                    editorClassName={""}
                />
            </div>
        )
    }

    return (
        <div className='flex gap-2'>
            <div className={cn("w-full", descriptionContainerClassName)}>
                {isLoading &&
                    <div className={"flex self-start gap-1 ml-3"}>
                        <span className={"font-bodyPri font-normal text-primary-dark text-sm whitespace-nowrap"}>
                            {"Please wait"}
                        </span>
                        <FaSpinner className={"animate-spin text-lg text-primary-dark"} />
                    </div>
                }
                {!isShowEditor &&
                    <div
                        className={cn("w-full relative flex items-start justify-between cursor-pointer group py-1", descriptionTextContainerClassName)}
                        onMouseOver={() => setIsMouseHovered(true)}
                        onMouseOut={() => setIsMouseHovered(false)}
                        onClick={() => setIsShowEditor(!isShowEditor)}
                    >
                        {(!has_content || !descriptionInput) && (
                            customDescriptionPlaceholderContainer() || defaultDescriptionPlaceholderContainer()
                        )}
                        {(has_content && descriptionInput) &&
                            (customDescriptionContentContainer() || defaultDescriptionContentContainer())
                        }
                        {isMouseHovered &&
                            <span className={"absolute top-1 right-2"}>
                                <MdEdit className={"text-lg md:text-xl text-text-800 cursor-pointer"}
                                />
                            </span>
                        }
                    </div>
                }
                {isShowEditor &&
                    <div className={cn("relative flex-1 flex flex-col gap-1 mt-1", editorContainerClassName)}>
                        <div className={"absolute right-3"}>
                            <SaveAndCancelBtn
                                className={"flex items-center justify-start mt-1 gap-0.5"}
                                isLoading={isLoading}
                                onHandleSave={onHandleSaveContent}
                                onHandleReset={onHandleCloseEditor}
                            />
                        </div>
                        <QuillEditor
                            editorToolModules={Editor?.modules}
                            editorToolFormats={Editor?.formats}
                            className={editorClassName}
                            editorText={descriptionInput}
                            onHandleChangeText={onHandleChangeInputText}
                            autoFocus={true}
                        />
                    </div>
                }
            </div>
            {isShowAiBtn && (
                <div className='relative flex justify-end'>
                    <AiTextButton
                        btnClassName="rounded h-6 addTextWithAi"
                        containerClassName="top-0 right-0"
                        btnTitle=''
                        tooltip='Create description with AI'
                        isShowInput={isShowInput}
                        setIsShowInput={setIsShowInput}
                        handlePrompt={handlePrompt}
                        getAiText={getAiText}
                        aiText={aiText}
                    />
                </div>
            )}
        </div>
    )
});

export default LmsEditorDescription;