import { useEffect } from "react";

import { getDefaultEmailPhoneDetail } from "../data";
import AddContactIdentity from "./components/AddContactIdentity";
import AddContactBasicDetails from "./components/AddContactBasicDetails";
import AddContactSocials from "./components/AddContactSocials";
import AddContactAddresses from "./components/AddContactAddresses";
import AddContactNotes from "./components/AddContactNotes";

import { getMasterCountryList, getMasterSocialList } from "redux/master/master.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetSocialList, setClearCountryList, setClearCityList, setClearStateList } from "redux/master/master.slice";

import { resetAddCrmContactDetail, resetAddCrmContactDetailPayload, setAddCrmContactDetailPayload } from "redux/crm/crm.slice";

const ContactContent = ({ closeModal }) => {
    const { countryList, timeZoneCountryDetail } = useAppState((state) => state.master)
    const addCrmContactDetail = useAppState((state) => state.crm.addCrmContactDetail)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (addCrmContactDetail?.data) {
            dispatcher(resetAddCrmContactDetail())
            dispatcher(resetAddCrmContactDetailPayload())
            closeModal()
        }
    }, [addCrmContactDetail?.data])

    useEffect(() => {
        dispatcher(getMasterCountryList())
        dispatcher(getMasterSocialList())

        return () => {
            dispatcher(setClearCountryList())
            dispatcher(setClearStateList())
            dispatcher(setClearCityList())
            dispatcher(resetSocialList())
        }
    }, [])

    useEffect(() => {
        if (countryList?.countryList && !addCrmContactDetail?.payload?.emails?.length && !addCrmContactDetail?.payload?.phones?.length) {
            ;(async () => {
                const requestPayload = await getDefaultEmailPhoneDetail(countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail)
                dispatcher(setAddCrmContactDetailPayload({
                    ...addCrmContactDetail?.payload,
                    ...requestPayload
                }))
            })()
        }
    }, [countryList?.countryList, addCrmContactDetail?.payload])

    return (
        <div className={"flex flex-col gap-5 p-5 font-bodyPri"}>
            <AddContactIdentity onClose={closeModal} />
            <AddContactBasicDetails />
            <AddContactSocials />
            <AddContactAddresses />
            <AddContactNotes />
        </div>
    )
}

export default ContactContent;