import CrmLeadsLayoutPage from "./leads/CrmLeadsLayoutPage";
import CrmClientsLayoutPage from "./clients/CrmClientsLayoutPage";
import CrmContactsLayoutPage from "pages/auth/crm/contacts/CrmContactsLayoutPage";

import CrmContactsTileView from "./contacts/CrmContactsTileView";
import CrmContactsListView from "./contacts/CrmContactsListView";

import CrmLeadsTileView from "./leads/CrmLeadsTileView";
import CrmLeadsListView from "./leads/CrmLeadsListView";

import CrmClientsTileView from "./clients/CrmClientsTileView";
import CrmClientsListView from "./clients/CrmClientsListView";

import { DEFAULT_CRM_CONTACT_PAGE, DEFAULT_CRM_CONTACT_RECORDS } from "redux/crm/crm.const";

export const crmNavigationOptions = {
    LEADS: {
        label: "Leads",
        value: "leads",
        pagePath: "/leads",
        routePath: "leads",
        to: "crm/leads",
        component: CrmLeadsLayoutPage
    },
    CONTACTS: {
        label: "Contacts",
        value: "contacts",
        pagePath: "/contacts",
        routePath: "contacts",
        to: "crm/contacts",
        component: CrmContactsLayoutPage
    },
    CLIENTS: {
        label: "Clients",
        value: "clients",
        pagePath: "/clients",
        routePath: "clients",
        to: "crm/clients",
        component: CrmClientsLayoutPage
    },

}

export const crmLeadsNavigationOptions = {
    TILE_VIEW: {
        label: "Tile View",
        value: "tile-view",
        pagePath: "/tile-view",
        routePath: "tile-view",
        to: "/crm/leads/tile-view",
        component: CrmLeadsTileView
    },
    LIST_VIEW: {
        label: "List View",
        value: "list-view",
        pagePath: "/list-view",
        routePath: "list-view",
        to: "/crm/leads/list-view",
        component: CrmLeadsListView
    },
}

export const crmContactsNavigationOptions = {
    TILE_VIEW: {
        label: "Tile View",
        value: "tile-view",
        pagePath: "/tile-view",
        routePath: "tile-view",
        to: "/crm/contacts/tile-view",
        component: CrmContactsTileView
    },
    LIST_VIEW: {
        label: "List View",
        value: "list-view",
        pagePath: "/list-view",
        routePath: "list-view",
        to: "/crm/contacts/list-view",
        component: CrmContactsListView
    },
}

export const crmClientsNavigationOptions = {
    TILE_VIEW: {
        label: "Tile View",
        value: "tile-view",
        pagePath: "/tile-view",
        routePath: "tile-view",
        to: "/crm/clients/tile-view",
        component: CrmClientsTileView
    },
    LIST_VIEW: {
        label: "List View",
        value: "list-view",
        pagePath: "/list-view",
        routePath: "list-view",
        to: "/crm/clients/list-view",
        component: CrmClientsListView
    },
}


export const getSearchQueryString = async (searchQueryParams, category = "") => {
    let queryParamPayload = {}

    queryParamPayload[searchParamsInfo.page.key] = searchQueryParams.get(searchParamsInfo.page.key) || DEFAULT_CRM_CONTACT_PAGE
    queryParamPayload[searchParamsInfo.records.key] = searchQueryParams.get(searchParamsInfo.records.key) || DEFAULT_CRM_CONTACT_RECORDS

    if (category) {
        queryParamPayload[searchParamsInfo.category.key] = category
    }
    if (searchQueryParams?.get(searchParamsInfo.search.key)) {
        queryParamPayload[searchParamsInfo.search.key] = searchQueryParams?.get(searchParamsInfo.search.key)
    }

    return queryParamPayload;
}

export const searchParamsInfo = {
    page: {
        key: "page"
    },
    records: {
        key: "records"
    },
    category: {
        key: "category"
    },
    search: {
        key: "search"
    },
    contact: {
        key: "contact"
    },
    action: {
        key: "action"
    },
    createContact: {
        key: "create-contact"
    }
}


export const menuItemsConst = {
    VIEW: {
        label: "View",
        value: "view"
    },
    DELETE: {
        label: "Delete",
        value: "delete"
    },
    // MOVE: {
    //     label: "Move",
    //     value: "move"
    // }
}

export const dropdownMenuItemsConst = {
    SELECT_ALL: {
        label: "Select All",
        value: "select_all"
    },
    NONE: {
        label: "None",
        value: "none"
    }
}

export const bulkDropdownItemsConst = {
    DELETE: {
        label: "Delete",
        value: "delete"
    }
}

export const bulkDropdownContactItemsConst = {
    MASS_UPDATE: {
        label: "Mass Update",
        value: "mass_update",
        className: "font-bodyPri py-2 px-2 sm:px-5 text-white text-sm sm:text-base ease-in-out duration-200 border bg-primary-dark hover:opacity-90 rounded"
    },
    DELETE_CONTACT: {
        label: "Delete Contact(s)",
        value: "delete_contact",
        className: "font-bodyPri py-2 px-2 sm:px-5 text-white text-sm sm:text-base ease-in-out duration-200 border bg-red-500 hover:opacity-90 rounded"
    },
    CLEAR_ALL: {
        label: "Clear All",
        value: "clear_all",
        className: "font-bodyPri ease-in-out duration-200 text-primary-dark text-sm sm:text-base"
    }
}

export const crmNoDataImage = "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/my-lms-courses/lms-course-not-found.png"

export const ITEM_TYPE = "ITEM"