import { useState } from 'react';

import { cn } from "utils/cn.utils";

import { MdArrowDropDown } from 'react-icons/md';
import { motion, AnimatePresence } from 'framer-motion';
import ButtonLoader from 'components/loader/ButtonLoader';

const PublishBtn = ({
  isLoading,
  optionsList = [],
  selectedOption = null,
  onHandleUpdateStatus,
  loaderBtnClassName = "bg-primary-dark",
  selectedBtnClassName = "bg-primary-dark hover:bg-blue-900 text-white",
  dropdownBtnClassName = "bg-primary-dark hover:bg-blue-900 text-white"
}) => {

  const [showDropdown, setShowDropdown] = useState(false);

  const onHandleOpenDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <>
      {isLoading &&
        <div className={cn("w-[6rem] py-2 rounded", loaderBtnClassName)}>
          <ButtonLoader isLoading={isLoading} className='w-full h-full' />
        </div>
      }
      {!isLoading &&
        <div className={"flex items-center justify-center h-10 relative font-bodyPri publishBtn"}>
          <motion.button
            whileHover={{ opacity: 0.9 }}
            className={cn(
              "px-5 h-8 sm:h-10 w-fit flex items-center justify-center rounded-l drop-shadow-sm",
              "ease-in-out duration-300", selectedBtnClassName
            )}
            onClick={onHandleOpenDropdown}
          >
            {selectedOption}
          </motion.button>
          <button
            className={cn(
              "px-2 h-8 sm:h-10 w-8 sm:w-10 flex items-center justify-center rounded-r drop-shadow-sm",
              "ease-in-out duration-300", dropdownBtnClassName
            )}
            onClick={onHandleOpenDropdown}
          >
            <MdArrowDropDown size={20} />
          </button>
          <AnimatePresence initial={showDropdown}>
            {showDropdown && (
              <motion.ul
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.5, type: 'spring' }}
                className={cn(
                  "absolute top-10 right-0 w-32 shadow-md rounded z-10 text-left overflow-hidden",
                  "bg-white border border-gray-300 transition-transform ease-in-out duration-300"
                )}>
                {Object.values(optionsList)?.map((option, index) => (
                  <li
                    key={index}
                    className={cn(
                      "cursor-pointer px-3 py-1 ease-in-out duration-300",
                      "hover:text-secondary-dark hover:bg-background-lightBlue"
                    )}
                    onClick={() => {
                      onHandleUpdateStatus(option)
                      setShowDropdown(false)
                    }}>
                    {option?.label}
                  </li>
                ))}
              </motion.ul>
            )}
          </AnimatePresence>
        </div>
      }
    </>
  );
};

export default PublishBtn;