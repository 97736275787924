import { memo } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const defaultDateStyles = {
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#9e9e9e",
        },
        "&:hover fieldset": {
            borderColor: "#2196f3",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#2196f3",
        },
        "&:disabled fieldset": {
            borderColor: "#eeeeee",
            // backgroundColor: "#f5f5f5",
            cursor: "not-allowed"
        },
        "&.Mui-disabled fieldset": {
            borderColor: "#eeeeee",
            cursor: "not-allowed"
        },
        "& input::placeholder": {
            color: "#9e9e9e",
            fontSize: "0.5em",
        },
    },
}

const MuiDatePicker = memo(({ label = "Date of Birth", sx = {}, ...props }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                slotProps={{ textField: { size: "small" } }}
                className={"w-full"}
                label={label}
                sx={{ defaultDateStyles, ...sx }}
                {...props}
            />
        </LocalizationProvider>
    );
});

export default MuiDatePicker;