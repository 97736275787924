import { useCallback, useMemo, useState, useEffect, memo } from 'react';

import { IoIosCheckmark } from 'react-icons/io';
import { MdOutlineMail } from 'react-icons/md';

import FloatingLabelSelect from 'components/floating/floatingLabelSelect';
import FloatingLabelInput from 'components/floating/floatingLabelInput';

import SaveAndCancelBtn from 'components/modals/crmModals/commonComponents/SaveAndCancelBtn';
import RadioInput from 'components/modals/crmModals/commonComponents/RadioInput';

import { crmContactLabelsEnum } from 'redux/crm/crm.const';

import { validateEmail } from "utils/validation-functions";

const ContactEmailItem = memo(({
    index = 0,
    isLoading = false,
    editBtnConst = null,
    setEditBtnConst = () => { return },
    isShowSaveBtn = false,
    isShowCancelBtn = false,
    emailItem = null,
    onHandlePrimaryEmailDetail,
    onHandleSaveEmailDetail,
    onHandleDeleteEmailDetail
}) => {
    const [emailPayload, setEmailPayload] = useState(emailItem)
    const [invalidEmailDetail, setInvalidEmailDetail] = useState({
        emailId: null,
        isInvalidEmail: false
    })

    useEffect(() => {
        setEmailPayload(emailItem)
    }, [emailItem])

    const isEditDisabled = useMemo(() => {
        if (editBtnConst && (editBtnConst?.id !== emailItem?.id)) return true;
        else return false;
    }, [editBtnConst, emailItem])

    const isSimilarEmailDetail = useMemo(() => {
        const isSimilarData = JSON.stringify(emailPayload) === JSON.stringify(emailItem)
        return isSimilarData
    }, [emailPayload, emailItem, editBtnConst])

    const onHandleChangeEmailDetail = (updatedValue) => {
        setEmailPayload(updatedValue)
        if (!editBtnConst) {
            setEditBtnConst({
                ...editBtnConst,
                id: emailPayload?.id
            })
        }
    }

    const onHandleUpdateEmail = useCallback((event) => {
        const newEmail = event.target.value
        onHandleChangeEmailDetail({ ...emailPayload, [event.target.name]: newEmail })
        if (newEmail && !validateEmail(newEmail)) {
            setInvalidEmailDetail({
                emailId: emailPayload?.id,
                isInvalidEmail: true
            })
        } else {
            setInvalidEmailDetail(null)
        }
    }, [invalidEmailDetail, emailPayload])

    return (
        <div className={"w-full flex flex-col sm:flex-row gap-5 sm:gap-3 items-center border px-3 py-5 rounded shadow-sm"}>
            <div className={"w-full sm:w-fit flex justify-between gap-3"}>
                <div className={"flex gap-3 mt-2"}>
                    <div className="flex text-lg text-text-700">
                        <MdOutlineMail />
                    </div>
                    <div className={"flex sm:hidden"}>
                        <RadioInput
                            isInputDisabled={isEditDisabled}
                            tooltipContent={emailPayload?.is_primary ? "Default" : "Make Default"}
                            isInputChecked={emailPayload?.is_primary}
                            onHandleClickInput={(event) => onHandlePrimaryEmailDetail(emailPayload?.id, {
                                ...emailPayload,
                                is_primary: event?.target?.checked
                            })}
                        />
                    </div>
                </div>
                <div className={"flex sm:hidden mt-2"}>
                    <SaveAndCancelBtn
                        isLoading={isLoading}
                        isShowSaveBtn={isShowSaveBtn}
                        isShowCancelBtn={isShowCancelBtn}
                        isBtnInvisible={isEditDisabled}
                        isBtnDisable={isSimilarEmailDetail || invalidEmailDetail?.isInvalidEmail || !emailPayload?.email}
                        saveBtnTooltip={'Save Email'}
                        cancelBtnTooltip={"Remove Email"}
                        onHandleSaveBtn={() => onHandleSaveEmailDetail(emailPayload)}
                        onHandleCancelBtn={() => onHandleDeleteEmailDetail(emailPayload?.id)}
                    />
                </div>
            </div>
            <div className={"hidden sm:flex mt-2.5"}>
                <RadioInput
                    isInputDisabled={isEditDisabled}
                    tooltipContent={emailPayload?.is_primary ? "Default" : "Make Default"}
                    isInputChecked={emailPayload?.is_primary}
                    onHandleClickInput={(event) => onHandlePrimaryEmailDetail(emailPayload?.id, {
                        ...emailPayload,
                        is_primary: event?.target?.checked
                    })}
                />
            </div>
            <div className={"w-full flex items-center gap-3"}>
                <FloatingLabelInput
                    labelItem={`email_email${index}${emailPayload.id}`}
                    onChange={onHandleUpdateEmail}
                    value={emailPayload?.email}
                    label={"Email"}
                    inputName={"email"}
                    isInvalidInput={(invalidEmailDetail?.emailId === emailPayload?.id) && invalidEmailDetail?.isInvalidEmail}
                    disabled={isEditDisabled}
                    inputClassName={"disabled:px-2 disabled:bg-text-200 disabled:cursor-not-allowed"}
                    customInputContainerStyle={isEditDisabled && "focus-within:!ring-none hover:!border-divider-lightDark !px-0.5"}
                />
            </div>
            <div className="w-full sm:w-72">
                <FloatingLabelSelect
                    labelItem={`email_type${index}${emailPayload.id}`}
                    options={Object.values(crmContactLabelsEnum)}
                    onHandleSelect={(selectedOption) => onHandleChangeEmailDetail({ ...emailPayload, label: selectedOption })}
                    value={emailPayload?.label?.label}
                    OptionChild={({ option }) => (
                        <div className="bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between">
                            {option.label}
                            {option.value === emailPayload?.label?.value && <IoIosCheckmark size={20} color="green" />}
                        </div>
                    )}
                    label={"Type"}
                    dropDownContainerClassName={"w-full"}
                    isDisabled={isEditDisabled}
                    customBtnStyle={isEditDisabled ? "bg-text-200 hover:!border-divider-lightDark focus-within:!ring-1 focus-within:!ring-divider-lightDark focus-within:!border-divider-lightDark !cursor-not-allowed" : ""}
                />
            </div>
            <div className={"hidden sm:flex mt-2 h-fit"}>
                <SaveAndCancelBtn
                    isLoading={isLoading}
                    isShowSaveBtn={isShowSaveBtn}
                    isShowCancelBtn={isShowCancelBtn}
                    isBtnInvisible={isEditDisabled}
                    isBtnDisable={isSimilarEmailDetail || invalidEmailDetail?.isInvalidEmail || !emailPayload?.email}
                    saveBtnTooltip={'Save Email'}
                    cancelBtnTooltip={"Remove Email"}
                    onHandleSaveBtn={() => onHandleSaveEmailDetail(emailPayload)}
                    onHandleCancelBtn={() => onHandleDeleteEmailDetail(emailPayload?.id)}
                />
            </div>
        </div>
    )
})

export default ContactEmailItem;