import { MdOutlineOndemandVideo } from "react-icons/md";
import { FiFileText } from "react-icons/fi";
import { FiFolderPlus } from "react-icons/fi";

import EditLMSCourseDetailPage from "./components/courseDetail/EditLMSCourseDetailPage";

import CourseBasicDetail from "./components/courseDetail/components/courseBasicDetail/CourseBasicDetail";
import CourseDescription from "./components/courseDetail/components/courseDescription/CourseDescription";
import CourseLearning from "./components/courseDetail/components/courseLearning/CourseLearning";
import CourseSkills from "./components/courseDetail/components/courseSkills/CourseSkills";
import CourseLanguages from "./components/courseDetail/components/courseLanguges/CourseLanguages";
import CourseProficiency from "./components/courseDetail/components/courseProficiency/CourseProficiency";
import CourseThumbnail from "./components/courseDetail/components/courseThumbnail/CourseThumbnail";
import CourseVideo from "./components/courseDetail/components/courseVideo/CourseVideo";
import CourseFAQ from "./components/courseDetail/components/courseFaq/CourseFAQ";
import CourseProduct from "./components/courseDetail/components/courseProduct/CourseProduct";

import EditLMSCourseBuilderPage from "./components/courseBuilder/EditLMSCourseBuilderPage";

import EditLMSCourseSettingsPage from "./components/courseSettings/EditLMSCourseSettingsPage";

import GeneralSettings from "./components/courseSettings/components/generalSettings/CourseGeneralSettings";
import CourseSlug from "./components/courseSettings/components/courseSlug/CourseSlug";
import CourseInstructor from "./components/courseSettings/components/courseInstructor/CourseInstructor";

import EditLMSCourseParticipantsPage from "./components/courseParticipants/EditLMSCourseParticipantsPage";

import JoinedParticipants from "./components/courseParticipants/joinedParticipants/JoinedParticipants";
import InvitedParticipants from "./components/courseParticipants/invitedParticipants/InvitedParticipants";

export const lmsCourseTabConst = {
    COURSE_DETAILS: {
        label: "Details",
        value: "details",
        pagePath: "/details",
        routePath: "details",
        component: EditLMSCourseDetailPage
    },
    COURSE_BUILDER: {
        label: "Builder",
        value: "builder",
        pagePath: "/builder",
        routePath: "builder",
        component: EditLMSCourseBuilderPage
    },
    COURSE_SETTINGS: {
        label: "Settings",
        value: "settings",
        pagePath: "/settings",
        routePath: "settings",
        component: EditLMSCourseSettingsPage
    },
    COURSE_PARTICIPANTS: {
        label: "Participants",
        value: "participants",
        pagePath: "/participants",
        routePath: "participants",
        component: EditLMSCourseParticipantsPage
    }
}

export const courseDetailNavigationOptions = {
    GENERAL: {
        label: "General details",
        value: "general",
        pagePath: "/general",
        routePath: "general",
        to: "details/general",
        component: CourseBasicDetail
    },
    DESCRIPTION: {
        label: "Description",
        value: "description",
        pagePath: "/description",
        routePath: "description",
        to: "details/description",
        component: CourseDescription
    },
    SKILLS_YOU_WILL_LEARN: {
        label: "Skills you'll learn",
        value: "skills-you-will-learn",
        pagePath: "/skills-you-will-learn",
        routePath: "skills-you-will-learn",
        to: "details/skills-you-will-learn",
        component: CourseSkills
    },
    LANGUAGES: {
        label: "Language of instruction",
        value: 'language-of-instruction',
        pagePath: '/language-of-instruction',
        routePath: 'language-of-instruction',
        to: "details/language-of-instruction",
        component: CourseLanguages
    },
    PROFICIENCY: {
        label: "Level of difficulty",
        value: "level-of-difficulty",
        pagePath: "/level-of-difficulty",
        routePath: "level-of-difficulty",
        to: "details/level-of-difficulty",
        component: CourseProficiency
    },
    THUMBNAIL: {
        label: "Thumbnail",
        value: "thumbnail",
        pagePath: "/thumbnail",
        routePath: "thumbnail",
        to: "details/thumbnail",
        component: CourseThumbnail
    },
    VIDEO: {
        label: "Promo video",
        value: "promo-video",
        pagePath: "/promo-video",
        routePath: "promo-video",
        to: "details/promo-video",
        component: CourseVideo
    },
    WHAT_YOU_WILL_LEARN: {
        label: "What students will learn",
        value: "what-students-will-learn",
        pagePath: "/what-students-will-learn",
        routePath: "what-students-will-learn",
        to: "details/what-students-will-learn",
        component: CourseLearning
    },
    FAQS: {
        label: "FAQs",
        value: "faqs",
        pagePath: "/faqs",
        routePath: "faqs",
        to: "details/faqs",
        component: CourseFAQ
    },
    PRODUCT: {
        label: "Pricing (Product)",
        value: "product",
        pagePath: "/product",
        routePath: "product",
        to: "details/product",
        component: CourseProduct
    },
}

export const courseDetailBtnConst = {
    TITLE: {
        value: "title"
    },
    SUB_TITLE: {
        value: "sub_title"
    },
    CATEGORY: {
        value: "category"
    },
    DESCRIPTION: {
        value: "description"
    }
}

export const courseSettingsNavigationOptions = {
    GENERAL: {
        label: "General settings",
        value: "general",
        pagePath: "/general",
        routePath: "general",
        to: "settings/general",
        component: GeneralSettings
    },
    SLUG: {
        label: "Shareable link(s)",
        value: "slug",
        pagePath: "/slug",
        routePath: "slug",
        to: "settings/slug",
        component: CourseSlug
    },
    USER: {
        label: "Instructor",
        value: "instructors",
        pagePath: "/instructors",
        routePath: "instructors",
        to: "settings/instructors",
        component: CourseInstructor
    },
}

export const courseParticipantsNavigationOptions = {
    JOINED: {
        label: "Joined",
        value: "joined",
        pagePath: "/joined",
        routePath: "joined",
        to: "participants/joined",
        component: JoinedParticipants
    },
    INVITED: {
        label: "Invited",
        value: "invited",
        pagePath: "/invited",
        routePath: "invited",
        to: "participants/invited",
        component: InvitedParticipants
    },
}

export const courseInstructorHeaderConst = {
    Image: {
        label: "Image",
        value: "image"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    PERMISSION: {
        label: "Permission",
        value: "permission"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

export const courseInstructorInvitationHeaderConst = {
    IMAGE: {
        label: "Image",
        value: "image"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    INVITE_TO: {
        label: "Invite to",
        value: "invite_to"
    },
    INVITE_EMAIL_SENT: {
        label: "Invite email sent",
        value: "invite_email_sent"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE_JOINED: {
        label: "Date Joined",
        value: "date_joined"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

export const courseInstructorMenuItemConst = {
    MAKE_DEFAULT: {
        label: "Make Default",
        value: "make_default"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    DELETE: {
        label: "Delete",
        value: "delete"
    }
}

export const courseInstructorInviteMenuItemsConst = {
    DELETE: {
        label: "Delete",
        value: "delete"
    }
}

export const courseStudentHeaderConst = {
    Image: {
        label: "Image",
        value: "image"
    },
    USER_ID: {
        label: "User ID",
        value: "user_id"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    JOINING_FLOW: {
        label: "Joining Flow",
        value: "joining_flow"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    IS_ACTIVE: {
        label: "Is Active",
        value: "is_active"
    },
    JOINED_ON: {
        label: "Joined on",
        value: "joined_on"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

export const courseStudentInvitationHeaderConst = {
    IMAGE: {
        label: "Image",
        value: "image"
    },
    EMAIL: {
        label: "Email",
        value: "email"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    // INVITE_TO: {
    //     label: "Invite to",
    //     value: "invite_to"
    // },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE_JOINED: {
        label: "Date Joined",
        value: "date_joined"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

export const courseStudentMenuItemsConst = {
    ACTIVE: {
        label: "Active",
        value: "active"
    },
    INACTIVE: {
        label: "In Active",
        value: "inactive"
    },
    BLOCKED: {
        label: "Block",
        value: "blocked"
    },
    DELETE: {
        label: "Delete",
        value: "delete"
    }
}

export const courseStudentInviteMenuItemsConst = {
    DELETE: {
        label: "Delete",
        value: "delete"
    }
}


export const courseSectionIconList = {
    QUIZ: {
        label: "Add Quiz",
        value: "quiz",
        icon: <FiFolderPlus className={"text-xl text-white"} />,
        tooltip: "Add Quiz"
    },
    VIDEO_LECTURE: {
        label: "Video Lecture",
        value: "lecture",
        icon: <MdOutlineOndemandVideo className={"text-xl text-text-700 group-hover:text-primary-dark"} />,
        tooltip: "Add Video Lecture"
    },
    ARTICLE: {
        label: "Article",
        value: "article",
        icon: <FiFileText className={"text-xl text-text-700 group-hover:text-primary-dark"} />,
        tooltip: "Add Article"
    },
}

export const lmsCourseTooltips = {
    courseSubTitle: "Mini subheader to give further insight or support to the course name",
    category: "Select the primary category that best fits your course. This helps students find your content based on their interests.",
    description: "Provide a compelling overview of your course. Highlight what makes it unique and what students can expect to achieve upon completion.",
    skills: "List key skills or concepts students will acquire. Use keywords (upto 3 words for each keyword) to make your course easily discoverable.",
    languageOfInstructions: "Specify the language you'll use for teaching this course. This ensures students are comfortable with the language before enrolling.",
    levelOfDifficulty: "Choose the difficulty level that best describes your course content, helping students to pick courses that match their skill level.",
    thumbnail: "Upload / Find an eye-catching image that represents your course. First impressions matter!",
    promoVideo: "Add a short promotional video to give potential students a taste of your teaching style and course content.",
    studentLearning: "Detail the main learning outcomes of your course using bullet points for easy reading. Be clear and concise.",
    faq: "Include answers to frequently asked questions about your course. This can cover course prerequisites, materials provided, and any other common queries.",
    productPricing: "Set the price for your course. Consider your content's value and your target audience. Free or paid, it's up to you!",
    shareableCourseLink: "Share a direct link to your course. Perfect for promoting your course on social media, emails, or any other marketing channels.",
    publishedInviteBtnTooltip: "Send course invitations through email.",
    disabledInviteBtnTooltip: "Publish this course to invite participants.",
}

export const searchAlreadyAddedLesson = (courseSections = [], targetLessonType = null, targetLessonId = null) => {

    if (!courseSections || (courseSections?.length === 0) || !targetLessonId || !targetLessonType) return;

    if (courseSections?.length > 0) {
        for (const courseSection of courseSections) {
            if (courseSection?.lms_course_section_lessons?.length > 0) {
                for (const sectionLesson of courseSection?.lms_course_section_lessons) {
                    if ((sectionLesson?.type === targetLessonType) && sectionLesson[targetLessonType]?.id === targetLessonId) {
                        const courseSectionTitle = courseSection?.title
                        return courseSectionTitle
                    }
                }
            }
        }
    }

    return false;
}