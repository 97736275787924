import { Line } from 'react-chartjs-2';

import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

const LineChart = ({ lineData, options }) => {
    return (
        <Line data={lineData} options={options} />
    )
}

export default LineChart;