import { styled } from 'twin.macro';

const Svg = styled.svg`
    width: 50px;
    height: 50px;
`;

const SvgIcon = ({ children, className }) => {
    return (
        <Svg className={className} width='current' height='current' viewBox="0 0 200 200" xmlns='http://www.w3.org/2000/svg'>
            {children}
        </Svg>
    )
}

export default SvgIcon;