import { memo, useCallback, useEffect } from 'react';

import { IoIosCheckmark } from 'react-icons/io';

import FloatingLabelInput from 'components/floating/floatingLabelInput';
import FloatingLabelSelect from 'components/floating/floatingLabelSelect';

import { inPersonMeetingInputConst, locationInputLengths } from 'pages/auth/editAppointment/data';

import { useAppState } from 'hooks/useStore';
import { locationLabelsEnum } from "redux/appointment/appointment.const";

const InPersonMeetingDetail = memo(({ locationPayload, setLocationPayload }) => {
    const { countryList, timeZoneCountryDetail } = useAppState((state) => state.master)

    useEffect(() => {
        if (!locationPayload?.in_person_meeting?.country?.country && countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country?.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country) => (
                    country.countryDomain === "US"
                ))
            }
            setLocationPayload({
                ...locationPayload,
                in_person_meeting: {
                    ...locationPayload?.in_person_meeting,
                    country: myLocalCountryList[0]
                }
            })
        }
    }, [locationPayload?.in_person_meeting?.country?.country, countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

    const sortCountryList = (country1, country2) => {
        if (country1?.masterCountryId === locationPayload?.in_person_meeting?.country?.masterCountryId) return -1;
        if (country2?.masterCountryId === locationPayload?.in_person_meeting?.country?.masterCountryId) return 1;
        return 0;
    }

    const onHandleChangeDetail = useCallback((key, value) => {
        setLocationPayload({
            ...locationPayload,
            in_person_meeting: {
                ...locationPayload?.in_person_meeting,
                [key]: value
            }
        })
    }, [locationPayload])

    const onHandleChangeCustomLabel = useCallback((key, value) => {
        setLocationPayload({
            ...locationPayload,
            [key]: value
        })
    }, [locationPayload])

    return (
        <div className={"flex flex-col gap-5 w-full"}>
            <FloatingLabelSelect
                labelItem={`meeting_country`}
                searchable={true}
                options={countryList?.countryList?.slice(0)?.sort(sortCountryList)?.map((country) => ({
                    label: country?.country,
                    value: country?.masterCountryId,
                    country: country
                })) || []}
                onHandleSelect={(selectedOption) => onHandleChangeDetail("country", {
                    masterCountryId: selectedOption?.value,
                    country: selectedOption?.country?.country,
                    countryDomain: selectedOption?.country?.domain,
                    isdCode: selectedOption?.isdCode
                })}
                value={locationPayload?.in_person_meeting?.country?.country}
                OptionChild={({ option }) => (
                    <div className="bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between">
                        <div className="flex items-center gap-3">
                            <img
                                src={`https://flagcdn.com/16x12/${option.country?.countryDomain}.png`.toLowerCase()}
                                className={"w-10 h-6 object-cover"}
                            />
                            {option?.country?.country}
                        </div>
                        {(option?.value === locationPayload?.in_person_meeting?.country?.masterCountryId) &&
                            <IoIosCheckmark size={20} color="green" />
                        }
                    </div>
                )}
                label={"Country"}
                dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
            />
            {Object.values(inPersonMeetingInputConst).map((addressInput, index) => (
                <div className={"space-y-1"}>
                    <FloatingLabelInput
                        key={index}
                        labelItem={`meeting_${addressInput?.value}${index}`}
                        inputName={addressInput?.value}
                        onChange={(event) => onHandleChangeDetail(event.target.name, event?.target?.value)}
                        value={locationPayload?.in_person_meeting[addressInput?.value]}
                        label={addressInput?.label}
                        inputClassName={"disabled:px-2 disabled:bg-text-200 disabled:cursor-not-allowed"}
                        isInvalidInput={locationPayload?.in_person_meeting[addressInput?.value] && (locationPayload?.in_person_meeting[addressInput?.value]?.length < locationInputLengths[addressInput?.value]?.minLength)}
                        minLength={locationInputLengths[addressInput?.value]?.minLength}
                        maxLength={locationInputLengths[addressInput?.value]?.maxLength}
                    />
                    {!!locationPayload?.in_person_meeting[addressInput?.value]?.length &&
                        <div className={"flex justify-end"}>
                            <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                                {`${locationPayload?.in_person_meeting[addressInput?.value] ? locationPayload?.in_person_meeting[addressInput?.value]?.length : 0}/${locationInputLengths[addressInput?.value].maxLength}`}
                            </span>
                        </div>
                    }
                </div>
            ))}
            <FloatingLabelSelect
                labelItem={`meeting_label`}
                options={Object.values(locationLabelsEnum)}
                onHandleSelect={(selectedOption) => onHandleChangeDetail("label", selectedOption?.key)}
                value={locationLabelsEnum[locationPayload?.in_person_meeting?.label]?.label}
                OptionChild={({ option }) => (
                    <div className={"bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between"}>
                        {option?.label}
                        {(option?.value === locationPayload?.in_person_meeting?.label) &&
                            <IoIosCheckmark size={20} color="green" />
                        }
                    </div>
                )}
                label={"Label"}
                dropDownContainerClassName={"w-full"}
            />
            {[locationLabelsEnum.custom.key]?.includes(locationPayload?.in_person_meeting?.label) &&
                <div className={"space-y-1"}>
                    <FloatingLabelInput
                        labelItem={`meeting_custom_label`}
                        inputName={"in_person_label_text"}
                        onChange={(event) => onHandleChangeCustomLabel(event.target.name, event?.target?.value)}
                        value={locationPayload?.in_person_label_text}
                        label={"Label"}
                        inputClassName={"disabled:px-2 disabled:bg-text-200 disabled:cursor-not-allowed"}
                        isInvalidInput={locationPayload?.in_person_label_text && (locationPayload?.in_person_label_text?.length < locationInputLengths.label.minLength)}
                        minLength={locationInputLengths.label.minLength}
                        maxLength={locationInputLengths.label.maxLength}
                    />
                    {!!locationPayload?.in_person_label_text?.length &&
                        <div className={"flex justify-end"}>
                            <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                                {`${locationPayload?.in_person_label_text ? locationPayload?.in_person_label_text?.length : 0}/${locationInputLengths.label.maxLength}`}
                            </span>
                        </div>
                    }
                </div>
            }
        </div>
    )
})

export default InPersonMeetingDetail;