import { createSlice } from "@reduxjs/toolkit";

import { LEAD_INITIAL_STATE } from "redux/lead/lead.initialState";

const lead = createSlice({
    name: "lead",
    initialState: LEAD_INITIAL_STATE,
    reducers: {
        setAddLeadDetailLoading: (state, { payload }) => {
            state.addLeadDetail.isLoading = payload
        },
        setAddLeadDetail: (state, { payload }) => {
            state.addLeadDetail.addLeadDetail = payload
            state.addLeadDetail.errorMsg = LEAD_INITIAL_STATE.addLeadDetail.errorMsg
        },
        setAddLeadDetailErrorMsg: (state, { payload }) => {
            state.addLeadDetail.errorMsg = payload
            state.addLeadDetail.addLeadDetail = LEAD_INITIAL_STATE.addLeadDetail.addLeadDetail
        },
        setClearAddLeadDetail: (state) => {
            state.addLeadDetail = LEAD_INITIAL_STATE.addLeadDetail
        },

        setAddLeadDetailPayload: (state, { payload }) => {
            state.addLeadDetail.addLeadDetailPayload = payload
        },
        setClearAddLeadDetailPayload: (state) => {
            state.addLeadDetail.addLeadDetailPayload = LEAD_INITIAL_STATE.addLeadDetail.addLeadDetailPayload
        },
    }
})

export const {
    setAddLeadDetailLoading,
    setAddLeadDetail,
    setAddLeadDetailErrorMsg,
    setClearAddLeadDetail,

    setAddLeadDetailPayload,
    setClearAddLeadDetailPayload
} = lead.actions

export default lead.reducer
