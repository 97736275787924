import { AiOutlinePlus } from 'react-icons/ai'

const AddSection = ({ onAddNewPress }) => {
  return (
    <div className='w-full flex items-center justify-center'>
      <button
        className='addSection flex justify-center items-center bg-white rounded-full px-5 py-2 text-text-900 border border-divider-dark hover:border-secondary-dark hover:text-secondary-dark'
        onClick={onAddNewPress}
      >
        <AiOutlinePlus className='mr-1' size={20} />Add new section
      </button>
    </div>
  )
}

export default AddSection;
