import { createSlice } from "@reduxjs/toolkit"
import { PROMO_INITIAL_STATE } from "redux/promo/promo.initialState"

export const promo = createSlice({
    name: "promo",
    initialState: PROMO_INITIAL_STATE,
    reducers: {
        // reducers for verify promo
        setVerifyPromoLoading: (state, { payload }) => {
            state.verifyPromo.isLoading = payload
        },
        setVerifyPromo: (state, { payload }) => {
            state.verifyPromo.verifyPromo = payload
            state.verifyPromo.errorMsg = PROMO_INITIAL_STATE.verifyPromo.errorMsg
        },
        setVerifyPromoErrorMsg: (state, { payload }) => {
            state.verifyPromo.errorMsg = payload
            state.verifyPromo.verifyPromo = PROMO_INITIAL_STATE.verifyPromo.verifyPromo
        },
        setClearVerifyPromo: (state) => {
            state.verifyPromo = PROMO_INITIAL_STATE.verifyPromo
        },
        setVerifyPromoPayload: (state, { payload }) => {
            state.verifyPromo.verifyPromoPayload = payload
        },

        // reducers for redeem promo
        setRedeemPromoLoading: (state, { payload }) => {
            state.redeemPromo.isLoading = payload
        },
        setRedeemPromo: (state, { payload }) => {
            state.redeemPromo.redeemPromo = payload
            state.redeemPromo.errorMsg = PROMO_INITIAL_STATE.redeemPromo.errorMsg
        },
        setRedeemPromoErrorMsg: (state, { payload }) => {
            state.redeemPromo.errorMsg = payload
            state.redeemPromo.redeemPromo = PROMO_INITIAL_STATE.redeemPromo.redeemPromo
        },
        setClearRedeemPromo: (state) => {
            state.redeemPromo = PROMO_INITIAL_STATE.redeemPromo
        },
        setRedeemPromoPayload: (state, { payload }) => {
            state.redeemPromo.redeemPromoPayload = payload
        },

    }
})
export const {
    setVerifyPromoLoading,
    setVerifyPromo,
    setVerifyPromoErrorMsg,
    setClearVerifyPromo,
    setVerifyPromoPayload,

    setRedeemPromoLoading,
    setRedeemPromo,
    setRedeemPromoErrorMsg,
    setClearRedeemPromo,
    setRedeemPromoPayload,
} = promo.actions
export default promo.reducer;