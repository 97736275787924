import * as React from "react";

import { cn } from "utils/cn.utils";

import ComponentLoader from "components/loader/ComponentLoader"

import { useAppState } from "hooks/useStore";

import { dayjs, timeZone } from "utils/dateTime.utils";

const currentDay = dayjs();

const isCurrentTime = (events: any) => {

  return (
    currentDay.isAfter(dayjs(events?.startDateTime, "hh:mm A")) &&
    currentDay.isBefore(dayjs(events?.endDateTime, "hh:mm A"))
  );
};

const SessionScheduleView: React.FC<{
  from: string;
  to: string;
  session: {
    profile: string;
    title: string;
    description: string;
  };
  isActive?: boolean;
}> = ({ from, to, session, isActive }) => {
  return (
    <div
      className={cn(
        "w-full flex gap-3",
        isActive && "border-2 border-blue-400 bg-blue-300"
      )}
    >
      <div className={"flex flex-col justify-between"}>
        <p className={"font-bodyPri text-xs text-gray-500 whitespace-nowrap"}>
          {dayjs(from, "hh:mm A").format("hh:mm a")}
        </p>
        <p className={"font-bodyPri text-xs text-gray-500 whitespace-nowrap"}>
          {dayjs(to, "hh:mm A").format("hh:mm a")}
        </p>
      </div>
      <div className={"flex-1 p-2.5 flex flex-col gap-1.5 bg-text-200 rounded-lg overflow-hidden"}>
        <p className={"text-base font-medium font-bodyPri text-text-900 truncate"}>
          {session.title}
        </p>
        <p className={"font-bodyPri text-xs font-light text-text-700"}>
          {session.description}
        </p>
      </div>
    </div>
  );
};

const CalendarSelectedDateEvent = ({ sessions }: any) => {
  return (
    <div className={"w-full flex flex-col space-y-2"}>
      <div className={"w-full"}>
        {isCurrentTime(sessions) && (
          <div className={"relative w-full h-0.5 bg-primary-main mb-2"}>
            <span className={"absolute w-2 h-2 bg-primary-main rounded-full -left-1 -top-[3px]"} />
          </div>
        )}
        <SessionScheduleView
          from={dayjs(sessions?.startDateTime)?.tz(timeZone)?.format('hh:mm A')}
          to={dayjs(sessions?.endDateTime)?.tz(timeZone)?.format('hh:mm A')}
          session={{
            profile: sessions?.course!?.thumbnailPicUrl,
            description: `${dayjs(sessions?.startDateTime)?.tz(timeZone)?.format('hh:mm A')} - ${dayjs(sessions?.endDateTime)?.tz(timeZone)?.format('hh:mm A')}`,
            title: sessions?.name,
          }}
          isActive={isCurrentTime(sessions)}
        />
      </div>
    </div>
  )
}

export const TimelineView = ({ selectedDate }: any) => {
  const { dashboardCalendarEventList } = useAppState((s: any) => s.session)

  const filteredEventList = dashboardCalendarEventList?.data?.filter((calEvent: any) => (dayjs(calEvent?.startDateTime)?.isSame(selectedDate, 'day')))

  if (dashboardCalendarEventList?.isLoading) {
    return (
      <ComponentLoader isLoading={dashboardCalendarEventList?.isLoading} />
    )
  }

  if (dashboardCalendarEventList?.message) {
    return (
      <div className={"flex items-center justify-center pt-5"}>
        <p className={"font-bodyPri font-normal text-text-800 text-md tracking-wide"}>
          {dashboardCalendarEventList?.message}
        </p>
      </div>
    )
  }

  return (!!filteredEventList) ? (
    <div className={"flex flex-col space-y-3"}>
      <p className="font-bodyPri font-medium text-text-600 text-md">
        {filteredEventList?.length + " Schedules"}
      </p>
      {filteredEventList?.map((sessions: any, idx: any) => (
        <>
          <CalendarSelectedDateEvent key={idx} sessions={sessions} />
          <hr className={"h-0.5 bg-text-300"} />
        </>
      ))}
    </div>
  ) : null;
};