import { useState } from 'react';

import tw from 'twin.macro';
import { cn } from "utils/cn.utils";

import { AiFillLock } from "react-icons/ai";

import DropDownMenu from 'components/dropdownMenu/DropDownMenu';
import RightLogedInToggleMenu from 'components/navbar/RightLogedInToggleMenu';

import { useAppState } from 'hooks/useStore';

const HeaderSection = () => {
    const { user } = useAppState((state) => state.user)

    const [isClick, setIsClick] = useState(false)

    return (
        <div className={"w-full bg-white h-16 flex item-center group hover:bg-primary-dark"}>
            <div className={"container mx-auto flex items-center justify-between px-8 group-hover:bg-primary-dark"}>
                <div>
                    <span className={"font-bodyPri text-3xl text-text-800 tracking-wide group-hover:text-text-50"}>
                        {"Edulyte"}
                    </span>
                </div>
                <span className={"font-bodyPri font-medium text-2xl text-primary-dark tracking-wide group-hover:text-text-50"}>
                    {"Checkout"}
                </span>
                <div className={"flex items-center justify-start gap-5"}>
                    <div
                        className={cn(
                            " w-8 h-8 rounded-full overflow-hidden relative",
                            "border-2 border-text-800 cursor-pointer",
                            "group-hover:border-white hover:border-complementry-light!"
                        )}
                        onClick={() => setIsClick(!isClick)}
                    >
                        <img
                            src={user?.user?.profilePicUrl}
                            alt={'profile-pic'}
                            className={"w-full h-full object-cover"}
                        />
                    </div>
                    <DropDownMenu isClick={isClick} setIsClick={setIsClick} boxStyle={tw`mt-3 mr-8 right-0 max-h-[36rem] min-w-[20rem]`} arrowStyle={tw`bg-white`}>
                        <RightLogedInToggleMenu setIsClick={setIsClick} />
                    </DropDownMenu>
                    <AiFillLock className={"text-2xl text-text-600 group-hover:text-text-50"} />
                </div>
            </div>
        </div >
    )
}

export default HeaderSection