import { useLocation, useNavigate } from 'react-router-dom';

const ClearFilter = ({ setFilterPayload }) => {

    const navigate = useNavigate()
    const location = useLocation()

    const onHandleClearAllFilters = () => {
        setFilterPayload(null)
        navigate(`${location.pathname}`)
    }

    return (
        <div 
        className={""} 
        onClick={onHandleClearAllFilters}
        >
            <span className={"font-bodyPri font-medium text-primary-dark hover:opacity-90 cursor-pointer whitespace-nowrap"}>
                {"Clear Filters"}
            </span>
        </div>
    )
}

export default ClearFilter;