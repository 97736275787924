import { uploadFileTypeEnum } from "redux/storage/storage.const";

export const generateFormData = async (files, fileOwnerId, fileType = uploadFileTypeEnum?.MEDIA?.value) => {

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
    }
    formData.append("fileOwnerId", fileOwnerId)
    formData.append("type", fileType)
    
    return formData;
}