import { createSlice } from "@reduxjs/toolkit";

import { USER_DEFAULT_INITIAL_STATE } from "redux/default/default.initialState";

const userDefault = createSlice({
    name: "userDefault",
    initialState: USER_DEFAULT_INITIAL_STATE,
    reducers: {
        // reducers for get user default details
        setUserDefaultDetailLoading: (state, { payload }) => {
            state.userDefaultDetail.isLoading = payload
        },
        setUserDefaultDetail: (state, { payload }) => {
            state.userDefaultDetail.userDefaultDetail = payload
            state.userDefaultDetail.errorMsg = USER_DEFAULT_INITIAL_STATE.userDefaultDetail.errorMsg
        },
        setUserDefaultDetailErrorMsg: (state, { payload }) => {
            state.userDefaultDetail.errorMsg = payload
            state.userDefaultDetail.userDefaultDetail = USER_DEFAULT_INITIAL_STATE.userDefaultDetail.userDefaultDetail
        },
        setClearUserDefaultDetail: (state) => {
            state.userDefaultDetail = USER_DEFAULT_INITIAL_STATE.userDefaultDetail
        },

        // reducers for add user default details
        setAddUserDefaultDetailLoading: (state, { payload }) => {
            state.addUserDefaultDetail.isLoading = payload
        },
        setAddUserDefaultDetailErrorMsg: (state, { payload }) => {
            state.addUserDefaultDetail.errorMsg = payload
        },
        setClearAddUserPayoutDetail: (state) => {
            state.addUserDefaultDetail = USER_DEFAULT_INITIAL_STATE.addUserDefaultDetail
        }
    }
})

export const {
    setUserDefaultDetailLoading,
    setUserDefaultDetail,
    setUserDefaultDetailErrorMsg,
    setClearUserDefaultDetail,

    setUserPayoutDetailLoading,
    setUserPayoutDetail,
    setUserPayoutDetailErrorMsg,
    setClearUserPayoutDetail,

    setAddUserDefaultDetailLoading,
    setAddUserDefaultDetailErrorMsg,
    setClearAddUserDefaultDetail
} = userDefault.actions

export default userDefault.reducer;