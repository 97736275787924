import { useEffect, useMemo, useCallback } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

import FullPageLoader from 'components/loader/FullPageLoader';

import { searchParamsInfo, setAppointmentDetailPayload } from 'pages/auth/editAppointment/data';
import SectionView from 'pages/auth/editAppointment/commonComponents/SectionView';
import AppointmentCalendarInfo from 'pages/auth/editAppointment/calendar';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getMasterCountryList } from 'redux/master/master.request';
import { getUserAppointmentDetail } from 'redux/appointment/appointment.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearCountryList } from 'redux/master/master.slice';
import { setModal, setShareSocial } from 'redux/local/local.slice';
import { resetUserAppointmentDetail, setModifyAppointmentDetailPayload, resetModifyAppointmentDetail, resetModifyAppointmentDetailPayload, setUserAppointmentDetailData } from 'redux/appointment/appointment.slice';
import { setAddBookingDetailPayload } from 'redux/booking/booking.slice';
import { modalConst, socialAction } from 'redux/local/local.const';
import { appointmentStatusEnum } from 'redux/appointment/appointment.const';
import { bookingOfferingTypeEnum } from 'redux/booking/booking.const';

import { pagesInfo } from 'utils/pagesInfo';
import { useTitle } from 'hooks/useTitle';

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

const EditAppointmentDetailPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { modal, shareSocial } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { userAppointmentDetail, modifyAppointmentDetail } = useAppState((state) => state.appointment)
    const { addBookingDetail } = useAppState((s) => s.booking)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()
    const { appointmentId } = useParams()
    const [title, setTitle] = useTitle()

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.EDIT_APPOINTMENT))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (user?.user) {
            setTitle({
                ...title,
                title: `${user?.user?.firstName}, Welcome to Appointment Detail`
            })
        }
    }, [user?.user, currentPageInfo])

    useEffect(() => {
        return () => {
            dispatcher(setClearCountryList())
            dispatcher(resetUserAppointmentDetail())
            dispatcher(resetModifyAppointmentDetail())
            dispatcher(resetModifyAppointmentDetailPayload())
        }
    }, [])

    useEffect(() => {
        if (appointmentId && user?.user?.userId) {
            dispatcher(getMasterCountryList())
            dispatcher(getUserAppointmentDetail(appointmentId, user?.user?.userId))
        }
    }, [appointmentId, user?.user?.userId])

    useEffect(() => {
        if (modifyAppointmentDetail?.data) {
            if ((userAppointmentDetail?.data?.result?.status !== modifyAppointmentDetail?.data?.result?.status) && [appointmentStatusEnum.PUBLISHED.value]?.includes(modifyAppointmentDetail?.data?.result?.status)) {
                dispatcher(setShareSocial({
                    ...shareSocial,
                    socialAction: socialAction.SHARE_APPOINTMENT,
                    url: `${EDULYTE_APP_DOMAIN_URL}${pagesInfo.APPOINTMENT.pagePath}/${modifyAppointmentDetail?.data?.result?.slug}`
                }))
                dispatcher(setModal({
                    ...modal,
                    [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
                }))
            }
            dispatcher(setUserAppointmentDetailData(modifyAppointmentDetail?.data))
            dispatcher(resetModifyAppointmentDetail())
            if (searchQueryParams.get(searchParamsInfo.action.key)) {
                searchQueryParams.delete(searchParamsInfo.action.key)
                navigate(`${location.pathname}?${searchQueryParams.toString()}`)
            }
        }
    }, [modifyAppointmentDetail?.data])

    useEffect(() => {
        if (userAppointmentDetail?.data) {
            setLocalAppointmentDetail()
        }
    }, [userAppointmentDetail?.data])

    const setLocalAppointmentDetail = useCallback(() => {
        const payload = setAppointmentDetailPayload(userAppointmentDetail?.data?.result)
        dispatcher(setModifyAppointmentDetailPayload(payload))
        dispatcher(setAddBookingDetailPayload({
            ...addBookingDetail?.payload,
            offeringType: bookingOfferingTypeEnum.APPOINTMENT.value,
            appointmentId: userAppointmentDetail?.data?.result?.id,
            offering: userAppointmentDetail?.data?.result,
            duration: userAppointmentDetail?.data?.result?.appointment_availability?.duration,
            sessionCount: 1
        }))
    }, [userAppointmentDetail?.data?.result, addBookingDetail?.payload])

    if (userAppointmentDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={userAppointmentDetail?.isLoading} />
        )
    }

    if (userAppointmentDetail?.message) {
        return (
            <div className='w-screen h-screen flex items-center justify-center'>
                <span className='w-full font-bodyPri font-normal text-red-500 text-base tracking-wide text-center'>
                    {userAppointmentDetail?.message}
                </span>
            </div>
        )
    }

    return (
        <div className={"flex min-h-screen overflow-hidden"}>
            <div className={"w-full md:w-[440px] sticky top-0 h-screen bg-white shadow-[0_0_5px_1px_rgba(0,0,0,0.2)]"}>
                <SectionView />
            </div>
            <div className={"hidden md:flex flex-1 items-center justify-center bg-white py-10 xl:py-0 px-5 xl:px-8"}>
                <AppointmentCalendarInfo />
            </div>
        </div>
    )
}

export default EditAppointmentDetailPage;