import { toast } from "react-toastify";
import { useState, useEffect, useCallback } from 'react';
import { Draggable } from "react-drag-reorder";
import { cn } from "utils/cn.utils";

import { MdAdd } from 'react-icons/md';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";

import ComponentLoader from "components/loader/ComponentLoader";
// import SkillTag from 'components/skillTag/SkillTag';

import { ArrowToggleIcon, CollapsableContainer } from 'pages/auth/createCourse/createCourse.style';
import { inputSections, skillsToolTip } from "pages/auth/createCourse/data";

import { createCourseTagDetail, deleteCourseTagDetail, getCourseTagList, updateCourseTagListOrder } from "redux/course/course.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearAddCourseTagDetail, setClearCourseTagList } from "redux/course/course.slice";

const CourseSkillsSection = ({ clickedItem, setClickedItem }) => {
    const { tutorCourseDetail, courseTagList, addCourseTagDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const [isAddSkillsEnabled, setIsAddSkillsEnabled] = useState(false)
    const [skillList, setSkillList] = useState([])
    const [newSkill, setNewSkill] = useState("");

    useEffect(() => {
        if (clickedItem === inputSections?.SKILLS?.value) {
            dispatcher(getCourseTagList({ course: tutorCourseDetail?.data?.id }))
        }

        return () => {
            dispatcher(setClearCourseTagList())
            dispatcher(setClearAddCourseTagDetail())
        }
    }, [clickedItem])

    useEffect(() => {
        if (courseTagList?.courseTagList?.results?.length > 0) {
            setSkillList(courseTagList?.courseTagList?.results)
            setIsAddSkillsEnabled(true)
        }
    }, [courseTagList?.courseTagList])

    const isOpen = inputSections?.SKILLS?.value === clickedItem;

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const answerVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    }

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const isSkillsSimilar = JSON.stringify(skillList) === JSON.stringify(courseTagList?.courseTagList?.results)

    const onHandleToggle = () => {
        if (!clickedItem || (clickedItem !== inputSections?.SKILLS?.value)) {
            setClickedItem(inputSections?.SKILLS?.value)
        } else {
            setIsAddSkillsEnabled(false)
            setClickedItem(null)
        }
    }

    const onHandleRemoveSkill = useCallback(
        (courseSkillId) => {
            // const skillId = skillList?.find((skill) => skill?.masterTag?.tag === selectedSkill)?.id;
            // setSkillList(updatedSkill)
            dispatcher(deleteCourseTagDetail(courseSkillId))
        },
        [dispatcher, skillList]
    );

    const handleAddNewSkill = (skill) => {
        if (!skill || skill?.split(" ")?.length > 3) {
            toast.warn("Skills can have upto 3 words")
            return;
        }
        const skillTag = skill.trim()
        const filteredSkills = skillList?.filter((skill) => skill?.skill === skillTag)
        if (filteredSkills?.length > 0) {
            toast.warning("Duplicate skills not allowed!")
            setNewSkill("");
            const element = document.getElementById("skill")
            element?.focus()
            return;
        }

        // setSkillList([...skillList, { skill: skillTag }])
        dispatcher(createCourseTagDetail({ course: tutorCourseDetail?.data?.id, tag: skillTag }))
        setNewSkill("");
        const element = document.getElementById("skill")
        element?.focus()
    }

    const handleSave = (e) => {
        if ((e.keyCode === 9 || e.keyCode === 13) && newSkill.trim()) {
            const skillTag = newSkill.trim()
            // setSkillList([...skillList, { skill: skillTag }])
            dispatcher(createCourseTagDetail({ course: tutorCourseDetail?.data?.id, tag: skillTag }))
            setNewSkill("");
            const element = document.getElementById("skill")
            element?.focus()
        }
    };

    const getChangedPos = (currentPos, newPos) => {
        var updatedList = [...skillList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(currentPos, 1);
        // Add dropped item
        updatedList.splice(newPos, 0, reorderedItem);
        // Update State
        setSkillList(updatedList);
    };

    const onHandleUpdateOrder = () => {
        if (isSkillsSimilar || addCourseTagDetail?.isLoading) return;

        if (courseTagList?.courseTagList?.results?.length <= 1) return;

        const body = {
            order: skillList?.map((skill) => ({
                id: skill?.id
            }))
        }

        dispatcher(updateCourseTagListOrder(body, { course: tutorCourseDetail?.data?.id }))
    }

    return (
        <div className={"w-full h-full bg-white rounded-lg select-none"}>
            <div
                className={"flex flex-row items-center justify-between px-5 py-3 bg-background-darkLight rounded-lg cursor-pointer hover:shadow-all-md"}
                onClick={onHandleToggle}
            >

                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Skills to be covered"}
                </div>
                <ArrowToggleIcon
                    variants={arrowToggleVariants}
                    initial={'collapsed'}
                    animate={isOpen ? 'open' : 'collapsed'}
                    transition={transition}
                />
            </div>

            <CollapsableContainer
                isOpen={isOpen}
                variants={answerVariants}
                initial={'collapsed'}
                animate={isOpen ? 'open' : 'collapsed'}
                transition={transition}
            >
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"w-full text-center font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                        {skillsToolTip}
                    </span>
                </div>
                {courseTagList?.isLoading &&
                    <ComponentLoader isLoading={courseTagList?.isLoading} />
                }
                {courseTagList?.ErrorMsg &&
                    <div className={"flex items-center justify-start font-bodyPri font-normal text-red-500 text-base"}>
                        {courseTagList?.errorMsg}
                    </div>
                }
                {(!isAddSkillsEnabled && !courseTagList?.isLoading) &&
                    <div className={"w-full flex flex-col items-start justify-start gap-2"}>

                        <div className={cn(
                            "w-full px-3 py-3 my-2 flex items-center justify-center border border-dashed border-text-500 rounded-lg",
                            "font-bodyPri font-normal text-text-900 cursor-pointer"
                        )}
                            onClick={() => setIsAddSkillsEnabled(true)}
                        >
                            <MdAdd className={"text-xl text-text-900"} />
                            <span className={"text-xl"}>
                                {"Add Skills"}
                            </span>
                        </div>
                    </div>
                }
                {(isAddSkillsEnabled && !courseTagList?.isLoading) &&
                    <div className={"flex flex-col items-start justify-start gap-2"}>
                        <div className={cn(
                            "w-full px-3 py-3 my-2 flex items-center justify-start border border-dashed border-text-500 rounded-lg",
                            "font-bodyPri font-normal text-text-900"
                        )}>
                            <div className={"w-full flex flex-col items-start justify-start gap-5"}>
                                {addCourseTagDetail?.isLoading &&
                                    <ComponentLoader isLoading={addCourseTagDetail?.isLoading} />
                                }
                                {(!courseTagList?.isLoading && !addCourseTagDetail?.isLoading) &&
                                    <div className={"flex flex-wrap gap-2"}>
                                        <Draggable onPosChange={getChangedPos}>
                                            {courseTagList?.courseTagList?.results?.length > 0
                                                && courseTagList?.courseTagList?.results?.map((skill, index) => (
                                                    <div key={index} className="w-fit flex items-center px-3 py-1 bg-primary-light rounded-lg font-bodyPri font-normal text-text-900 text-sm">
                                                        <span className="sm:whitespace-nowrap">
                                                            {skill?.masterTag?.tag}
                                                        </span>
                                                        <button className="pl-3 cursor-pointer">
                                                            <FaTimes className="font-light text-secondary-dark" onClick={() => onHandleRemoveSkill(skill?.id)} />
                                                        </button>
                                                    </div>
                                                ))}
                                        </Draggable>
                                        <div className="flex items-center gap-3">
                                            <input
                                                className={cn(
                                                    "w-40 px-3 py-1 border-2 border-text-100 rounded-lg focus:outline-none focus:border-2 focus:border-text-300",
                                                    "font-bodyPri font-normal text-text-700 text-sm placeholder:text-text-400 placeholder:text-sm"
                                                )}
                                                type={"text"}
                                                placeholder={"Skill name"}
                                                value={newSkill}
                                                onChange={(event) => setNewSkill(event.target.value)}
                                                onKeyDown={(event) => handleSave(event)}
                                                autoFocus={true}
                                            />
                                            <AiOutlineCheckCircle className={"text-2xl text-text-800 cursor-pointer"} onClick={() => handleAddNewSkill(newSkill)} />
                                        </div>
                                    </div>
                                }
                                {addCourseTagDetail?.ErrorMsg &&
                                    <div className={"flex items-center justify-start font-bodyPri font-normal text-red-500 text-base"}>
                                        {addCourseTagDetail?.errorMsg}
                                    </div>
                                }
                            </div>
                        </div>
                        {courseTagList?.courseTagList?.results?.length < 3 &&
                            <div className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                                {"Please add at least 3 skills"}
                            </div>
                        }
                        <div className={"flex items-center justify-start gap-1 mb-2"}>
                            <div className={cn(
                                "w-fit px-3 py-0.5 rounded-lg",
                                "font-bodyPri font-normal text-text-800 text-sm",
                                isSkillsSimilar && "border border-text-300 bg-text-300 cursor-not-allowed",
                                !isSkillsSimilar && "border border-green-500 text-green-500 hover:bg-green-700 hover:text-text-50 cursor-pointer"
                            )}
                                onClick={onHandleUpdateOrder}
                            >
                                {"Save Display Order"}
                            </div>
                        </div>
                    </div>
                }
            </CollapsableContainer>
        </div>
    )
}

export default CourseSkillsSection