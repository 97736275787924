import { useEffect, useState } from 'react';
import { cn } from "utils/cn.utils";

import { MdAccessTimeFilled } from 'react-icons/md';
import { HiVideoCamera } from 'react-icons/hi';
import { AiTwotoneCalendar } from 'react-icons/ai';

import ComponentLoader from 'components/loader/ComponentLoader';
import TimeSlot from 'components/modals/chooseTimeSlotModal/TimeSlot';

import { getPublicAppointmentDetail } from 'redux/appointment/appointment.request';
import { getAvailableSlotDetail } from 'redux/availability/availability.request';
import { getSelectedPublicCourseDetail } from 'redux/course/course.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetSelectedPublicCourseDetail } from 'redux/course/course.slice';
import { resetUserAppointmentDetail } from 'redux/appointment/appointment.slice';
import { resetAvailableSlotDetail } from 'redux/availability/availability.slice';
import { setAddBookingDetailPayload } from 'redux/booking/booking.slice';
import { bookingOfferingTypeEnum } from 'redux/booking/booking.const';
import { courseType } from 'redux/course/course.const';

import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';

const ChooseTimeSlotModal = () => {
    const { addBookingDetail } = useAppState((s) => s.booking)
    const { selectedPublicCourseDetail } = useAppState((s) => s.course)
    const { publicAppointment } = useAppState((s) => s.appointment)
    const { availableSlotDetail } = useAppState((s) => s.availability)

    const dispatcher = useAppDispatcher()

    const [nowDayjs, setNowDayjs] = useState(dayjs())

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    useEffect(() => {
        const interval = setInterval(() => {
            setNowDayjs(dayjs())
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    useEffect(() => {
        return () => {
            dispatcher(resetSelectedPublicCourseDetail())
            dispatcher(resetUserAppointmentDetail())
            dispatcher(resetAvailableSlotDetail())
        }
    }, [])

    useEffect(() => {
        if (addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COURSE.value) {
            if (selectedPublicCourseDetail?.data) {
                dispatcher(setAddBookingDetailPayload({
                    ...addBookingDetail?.payload,
                    offering: selectedPublicCourseDetail?.data,
                }))
                dispatcher(getAvailableSlotDetail(selectedPublicCourseDetail?.data?.courseAvailability?.availabilityId, { timeZone: timeZone, duration: addBookingDetail?.payload?.duration }))
            } else {
                dispatcher(getSelectedPublicCourseDetail(addBookingDetail?.payload?.slug))
            }
        } else if (addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value) {
            if (publicAppointment?.data?.result) {
                dispatcher(setAddBookingDetailPayload({
                    ...addBookingDetail?.payload,
                    offering: publicAppointment?.data?.result,
                    duration: publicAppointment?.data?.result?.appointment_availability?.duration,
                    sessionCount: 1
                }))
                dispatcher(getAvailableSlotDetail(publicAppointment?.data?.result?.appointment_availability?.availability?.id, { timeZone: timeZone, duration: publicAppointment?.data?.result?.appointment_availability?.duration }))
            } else {
                dispatcher(getPublicAppointmentDetail(addBookingDetail?.payload?.slug))
            }
        }
    }, [selectedPublicCourseDetail?.data, publicAppointment?.data?.result])

    if (selectedPublicCourseDetail?.isLoading || publicAppointment?.isLoading) {
        return (
            <ComponentLoader
                isLoading={selectedPublicCourseDetail?.isLoading || publicAppointment?.isLoading}
                className={"min-h-[24rem] flex justify-center items-center"}
            />
        )
    }

    if (selectedPublicCourseDetail?.message || publicAppointment?.message) {
        return (
            <div className={"min-h-[24rem] flex justify-center items-center"}>
                <span className={"font-bodyPri font-medium text-md text-text-700"}>
                    {selectedPublicCourseDetail?.message || publicAppointment?.message}
                </span>
            </div>
        )
    }

    if (selectedPublicCourseDetail?.data && selectedPublicCourseDetail?.data?.type !== courseType.ONE_ON_ONE.value) {
        return (
            <div className={"min-h-[24rem] flex justify-center items-center"}>
                <span className={"font-bodyPri font-medium text-md text-text-700"}>
                    {"Required One on One Course"}
                </span>
            </div>
        )
    }

    if (availableSlotDetail?.data?.result && availableSlotDetail?.data?.result?.type === courseType.GROUP.value) {
        return (
            <div className={"min-h-[24rem] flex justify-center items-center"}>
                <span className={"font-bodyPri font-medium text-md text-text-700"}>
                    {"Required One on One Availability"}
                </span>
            </div>
        )
    }

    const getUser = () => {
        if (addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COURSE.value) {
            return addBookingDetail?.payload?.offering?.tutor?.user
        } else if (addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value) {
            return addBookingDetail?.payload?.offering?.user
        }
    }

    return (
        <div className={"space-y-5"}>
            <div className={"min-h-[24rem] block"}>

                <div className={'grid grid-cols-12 gap-5'}>

                    <div className={"col-span-12 space-y-3"}>
                        <span className={"truncate block text-center font-bodyPri font-medium text-base text-text-900"}>
                            {addBookingDetail?.payload?.offering?.title?.substring(0, 50) + (addBookingDetail?.payload?.offering?.title?.length > 50 ? "..." : "")}
                        </span>
                        <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>
                    </div>

                    <div className={"col-span-12 md:col-span-4 space-y-3"}>
                        {addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COURSE.value &&
                            <div className={"w-52 h-32 border border-border-medium rounded-lg overflow-hidden"}>
                                <img
                                    src={addBookingDetail?.payload?.offering?.thumbnailPicUrl}
                                    alt={"course-thumbnail"}
                                    className={"w-full h-full object-cover"}
                                />
                            </div>
                        }

                        <div className={"w-full md:w-52 flex flex-col items-start gap-2 overflow-hidden"}>

                            <div className={"flex items-center justify-start gap-2"}>
                                <div className='relative w-12 h-12 rounded-full'>
                                    <img
                                        src={getUser()?.profilePicUrl}
                                        alt={"profile-pic"}
                                        className="w-full h-full rounded-full object-cover"
                                    />
                                    <div className='absolute right-0.5 bottom-0.5 w-3 h-3 rounded-full overflow-hidden border-white'>
                                        <img
                                            src={`https://flagcdn.com/16x12/${getUser()?.fromCountry?.countryDomain}.png`.toLowerCase()}
                                            alt={"country-flag"}
                                            className='w-full h-full object-cover'
                                        />
                                    </div>
                                </div>
                                <span className='font-normal font-bodyPri text-sm tracking-wide'>
                                    {getUser()?.firstName + " " + getUser()?.lastName.charAt(0) + "."}
                                </span>
                            </div>

                            <span className={"font-bodyPri font-normal text-sm text-text-700 flex justify-start items-center gap-1"}>
                                <MdAccessTimeFilled className={"text-sm"} />
                                {addBookingDetail?.payload?.duration + " min | " + addBookingDetail?.payload?.sessionCount + " sessions"}
                            </span>
                            <span className={"inline space-x-3 font-bodyPri font-normal text-sm text-text-700"}>
                                <HiVideoCamera className={"text-md inline"} />
                                {(addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COURSE.value)
                                    ? addBookingDetail?.payload?.offering?.subTitle
                                    : addBookingDetail?.payload?.offering?.subtitle
                                }
                            </span>
                        </div>
                    </div>

                    <div className={"col-span-12 md:col-span-8 space-y-2"}>

                        <div className={"flex flex-wrap items-center justify-between"}>
                            <span className={"font-bodyPri font-normal text-sm text-text-700 text-center"}>
                                {"Available Slots"}
                            </span>
                            <span className={"font-bodyPri font-normal text-sm text-text-700 text-center"}>
                                {nowDayjs.tz(timeZone).format(`ddd, DD/MMM/YY hh:mm:ss A (z)`)}
                            </span>
                        </div>

                        <div className={cn(
                            "w-full flex flex-col items-start justify-start gap-5 h-full md:h-96 overflow-hidden overflow-y-scroll",
                            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                        )}>
                            <ComponentLoader isLoading={availableSlotDetail?.isLoading} />
                            {(availableSlotDetail?.message || availableSlotDetail?.data?.result?.availableSlots?.length <= 0) &&
                                <div className={"w-full h-full flex items-center justify-center"}>
                                    <span className={"font-bodyPri font-semibold text-md text-text-700"}>
                                        {"No Available Slots"}
                                    </span>
                                </div>
                            }
                            {availableSlotDetail?.data?.result?.availableSlots?.map((dailyAvail, index) => (
                                <div key={index} className={"flex flex-col items-start justify-start gap-3"}>
                                    <span className={"font-bodyPri font-medium text-base text-text-900 flex items-center justify-start gap-2"}>
                                        <AiTwotoneCalendar />
                                        {dayjs(dailyAvail.date + timeZoneOffset, "YYYY-MM-DDZ").tz(timeZone).format('dddd, DD MMM YYYY')}
                                    </span>
                                    <div className={"px-3 flex flex-wrap items-center gap-2"}>
                                        {dailyAvail.timeSlots.map((timeSlot, index) => (
                                            <TimeSlot key={index} timeSlot={timeSlot} dailyAvail={dailyAvail} />
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseTimeSlotModal