import { cn } from "utils/cn.utils";
import * as React from "react";
import { useParams } from "react-router-dom";
import JoditEditor from "jodit-react";

import { FiEdit } from 'react-icons/fi';
import { FaInfoCircle } from 'react-icons/fa';

import { courseDescriptionToolTip, editBtnConst } from "pages/auth/editCourse/data";
import EditAndDoneBtn from "./EditAndDoneBtn";

import { updateOfferingByCourseId } from "redux/offering/offering.request";

import { setOfferingCourse } from "redux/offering/offering.slice"

import { useAppDispatcher, useAppState } from "hooks/useStore";

const config = {
  buttons: 'bold, italic'
}

export const CourseDescriptionEditor = ({ editBtn, setEditBtn }: any) => {
  const { offeringCourse } = useAppState((s: any) => s.offering)

  const dispatcher = useAppDispatcher();
  const { courseId } = useParams()

  const [content, setContent] = React.useState('')

  React.useEffect(() => {
    setContent(offeringCourse?.offeringCourse?.courseDescription)
  }, [offeringCourse?.offeringCourse?.courseDescription])

  const updateContent = () => {
    dispatcher(updateOfferingByCourseId(Number(courseId),
      {
        courseType: offeringCourse?.offeringCourse?.courseType,
        courseDescription: content
      }))
    dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, courseDescription: content }));
  };

  const onSave = () => {
    updateContent()
    setEditBtn(null)
  }

  const onCancel = () => {
    setEditBtn(null)
  }

  return (
    <div
      className={cn(
        "relative bg-white p-5"
      )}
    >
      <div className={cn("relative w-full h-full overflow-hidden space-y-3")}>
        <div>
          <button
            className={"absolute right-1 top-0"}
          // onClick={() => {
          //   if (editMode) {
          //     updateContent();
          //   }
          //   setEditMode(!editMode);
          // }}
          >
            {editBtn === editBtnConst.courseDescription.value &&
              <EditAndDoneBtn onSave={onSave} onCancel={onCancel} />
            }
            {!editBtn &&
              <FiEdit className="text-text-500 text-lg" onClick={() => setEditBtn(editBtnConst.courseDescription.value)} />
            }
          </button>
          <div className={"w-full flex items-center justify-start gap-1"}>
            <div className={"relative has-tooltip cursor-pointer flex items-start justify-start -mt-10 md:-mt-2.5"}>
              <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
              <span
                className={cn(
                  "w-56 px-2 py-0.5 font-bodyPri font-normal text-xs rounded shadow-lg bg-text-900 text-text-50 tooltip top-7",
                  "flex flex-col items-start justify-start overflow-hidden"
                )}
              >
                {courseDescriptionToolTip}
              </span>
            </div>
            <div className="w-full flex flex-col justify-start items-start gap-1 md:flex-row md:items-center md:gap-3 pb-1 border-b">
              <span className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
                {"Class Description"}
              </span>
              <span className="text-base text-text-700 font-normal">
                ({"Class Details"})
              </span>
            </div>
          </div>
        </div>
        {editBtn !== editBtnConst.courseDescription.value && (
          <div className="p-3 border border-secondary-main border-dashed">
            <div
              className={""}
              dangerouslySetInnerHTML={{ __html: offeringCourse?.offeringCourse?.courseDescription }}
            >
            </div>
          </div>
        )}
        {(editBtn === editBtnConst.courseDescription.value) && (
          <div className="p-3 border-2 border-secondary-main border-solid mt-4 h-fit">
            <JoditEditor
              config={config}
              value={content}
              onChange={(newContent: any) => setContent(newContent)}
            />
          </div>
        )}
      </div>
    </div>
  );
};