import { useCallback, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import { GoPlus } from 'react-icons/go';
import { MdEdit } from 'react-icons/md';
import { IoIosCheckmark } from 'react-icons/io';
import { BsInfoCircle } from 'react-icons/bs';

import { motion } from "framer-motion";

import ComponentLoader from 'components/loader/ComponentLoader';
import ToolTipView from 'components/tooltipView';

import { appointmentDetailTooltips } from 'pages/auth/editAppointment/data';
import AppointmentDropdown from 'pages/auth/editAppointment/commonComponents/AppointmentDropdown';

import { getUserAvailabilityList } from 'redux/availability/availability.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModifyAppointmentDetailPayload } from 'redux/appointment/appointment.slice';
import { resetUserAvailabilityList } from 'redux/availability/availability.slice';
import { availabilityIsRecurringEnum } from 'redux/availability/availability.const';

import { pagesInfo } from 'utils/pagesInfo';
import { cn } from 'utils/cn.utils';
import { debounce } from 'utils/generators.utils';

const Schedule = () => {
    const { user } = useAppState((state) => state.user)
    const { userAppointmentDetail, modifyAppointmentDetail } = useAppState((state) => state.appointment)
    const { userAvailabilityList } = useAppState((state) => state.availability)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [searchValue, setSearchValue] = useState("")

    useEffect(() => {
        if (user?.user?.userId) {
            dispatcher(getUserAvailabilityList(user?.user?.userId, { page: 1, records: 5 }))
        }

        return () => {
            dispatcher(resetUserAvailabilityList())
        }
    }, [user?.user?.userId])

    const onHandleChangeText = useCallback((key, value) => {
        dispatcher(setModifyAppointmentDetailPayload({
            ...modifyAppointmentDetail?.payload,
            [key]: value
        }))
    }, [modifyAppointmentDetail?.payload])

    const onHandleNavigateToAvailability = useCallback(() => {
        navigate(`${pagesInfo.PREFERENCES.pagePath}/availability/create`)
    }, [])

    const onHandleUpdateAvailability = useCallback((event, selectedOption) => {
        event.stopPropagation()
        navigate(`${pagesInfo.PREFERENCES.pagePath}/availability/${selectedOption?.value}/edit`)
    }, [userAvailabilityList?.data])

    const onHandleSearch = useCallback((searchText) => {
        if (searchText) {
            dispatcher(getUserAvailabilityList(user?.user?.userId, { name: searchText }))
        } else {
            dispatcher(getUserAvailabilityList(user?.user?.userId))
        }
    }, [user?.user?.userId])

    const onHandleSearchAvailability = useCallback((searchText) => {
        setSearchValue(searchText)
        const delayFunction = debounce.debounce(onHandleSearch)
        delayFunction(searchText)
    }, [searchValue])

  return (
      <div className={"w-full flex flex-col gap-2"}>
          <div className={"w-full flex items-center justify-between gap-3"}>
              <div className={"w-full flex items-center justify-start gap-2"}>
                  <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                      {"Schedule *"}
                  </span>
                  <ToolTipView content={appointmentDetailTooltips.schedule}>
                      <button className={""}>
                          <BsInfoCircle className={"inline text-md text-text-900 hover:text-text-900"} />
                      </button>
                  </ToolTipView>
              </div>
              <motion.button
                  key={"add_new_schedule"}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ duration: 0.3 }}
                  className={cn(
                      "w-fit py-1 px-2 flex items-center justify-center gap-1.5 text-primary-dark ease-in-out duration-200 border border-primary-dark rounded",
                      "hover:bg-primary-dark hover:text-text-50 font-bodyPri font-normal cursor-pointer whitespace-nowrap group",
                  )}
                  disabled={modifyAppointmentDetail?.isLoading || userAvailabilityList?.isLoading}
                  onClick={onHandleNavigateToAvailability}
              >
                  <GoPlus className={"text-lg group-hover:text-text-50"} />
                  <span className={"text-sm line-clamp-1"}>
                      {"Add New Schedule"}
                  </span>
              </motion.button>
          </div>
          <AppointmentDropdown
              isLoading={userAvailabilityList?.isLoading}
              searchable={true}
              labelItem={`appointment_schedule`}
              showLabel={false}
              isShowEmptyContent={(!userAvailabilityList?.data?.result || (userAvailabilityList?.data?.result?.length === 0)) && true}
              options={userAvailabilityList?.data?.result?.map((item) => ({
                  label: `${item?.name} (${availabilityIsRecurringEnum[item?.isRecurring?.toUpperCase()]?.label})`,
                  value: item?.id,
                  availability: item
              }))}
              value={<span className={"line-clamp-1"}>
                  {modifyAppointmentDetail?.payload?.availability ? `${modifyAppointmentDetail?.payload?.availability?.name} (${availabilityIsRecurringEnum[modifyAppointmentDetail?.payload?.availability?.isRecurring?.toUpperCase()]?.label})` : "Select an existing schedule or create a new one"}
              </span>}
              searchValue={searchValue}
              setSearchValue={onHandleSearchAvailability}
              EmptyContentContainer={() => {
                  return (
                      <>
                          {userAvailabilityList?.isLoading &&
                              <ComponentLoader isLoading={userAvailabilityList?.isLoading} />
                          }
                          {(!userAvailabilityList?.isLoading && userAvailabilityList?.data && (userAvailabilityList?.data?.result?.length === 0)) &&
                              <div className={"py-4 flex items-center justify-center gap-1.5"}>
                                  {searchValue &&
                                      <div className={"w-full flex items-center justify-center"}>
                                          <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                                              {"No result found!"}
                                          </span>
                                      </div>
                                  }
                                  {!searchValue &&
                                      <>
                                          <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                                              {"No Schedule Added!"}
                                          </span>
                                          <span
                                              className={"font-bodyPri font-normal text-text-800 text-sm cursor-pointer hover:underline hover:text-primary-dark"}
                                              onClick={onHandleNavigateToAvailability}
                                          >
                                              {"Add Schedule"}
                                          </span>
                                      </>
                                  }
                              </div>
                          }
                      </>
                  )
              }}
              onHandleSelect={(option) => onHandleChangeText("availability", option?.availability)}
              OptionChild={({ option }) => (
                  <div className={"bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between"}>
                      <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide line-clamp-1"}>
                          {option?.label}
                      </span>
                      <div className={"flex items-center justify-start gap-1.5"}>
                          <button>
                              {(option?.value === modifyAppointmentDetail?.payload?.availability?.id)
                                  && <IoIosCheckmark size={20} color="green" />
                              }
                          </button>
                          <button
                              className={""}
                              onClick={(event) => onHandleUpdateAvailability(event, option)}
                          >
                              <MdEdit className={"text-xl text-text-700 group-hover:text-primary-dark cursor-pointer"} />
                          </button>
                      </div>
                  </div>
              )}
              dropdownWrapperClassName={"w-full"}
              dropDownContainerClassName={"w-full max-h-44 overflow-y-auto scrollbar-thin"}
          />
      </div>
  )
}

export default Schedule