import { masterCurrencyCodeEnum } from "redux/master/master.const";

export const userWithdrawalDetailPayload = {
    currencyCode: masterCurrencyCodeEnum?.USD?.value,
    amount: null,
    withdrawMethod: null
}

export const WITHDRAWAL_INITIAL_STATE = {
    myWithdrawalList: {
        isLoading: true,
        myWithdrawalList: null,
        filteredMyWithdrawalList: null,
        errorMsg: null
    },
    userWithdrawalList: {
        isLoading: false,
        userWithdrawalList: null,
        filteredUserWithdrawalList: null,
        errorMsg: null
    },
    addUserWithdrawalDetail: {
        isLoading: false,
        userWithdrawalDetailPayload: userWithdrawalDetailPayload,
        addUserWithdrawalDetail: null,
        errorMsg: null
    },
}