import { useAppState } from 'hooks/useStore';


const SessionActionTitle = () => {
    const { sessionUserDetail } = useAppState((state) => state.session)

    return sessionUserDetail?.data?.result?.name ? (
        <div className={"flex items-center justify-center overflow-hidden"}>
            <span className={"font-bodyPri font-semibold text-lg text-text-900 text-center truncate"}>
                {sessionUserDetail?.data?.result?.name}
            </span>
        </div>
    ) : null
}

export default SessionActionTitle;