export const url = {
    USER_ONBOARDING_LIST: "/onboardings/users/{userId}",
    ONBOARDING_DETAIL: "/onboardings/{onboardingId}",
    USER_ONBOARDING_DETAIL: "/onboardings/{onboardingId}/users/{userId}",
    ONBOARDING_USER_DETAIL: "/onboardings/{onboardingId}/user-details",
    USER_ONBOARDING_QUESTION_DETAIL: "/onboardings/{onboardingId}/onboarding-questions",
    ONBOARDING_DOC_LIST: "/onboardings/{onboardingId}/onboarding-docs",
    ONBOARDING_DOC_DETAIL: "/onboardings/onboarding-docs/{onboardingDocId}",
    ONBOARDING_INTERVIEW_DETAIL: "/onboardings/{onboardingId}/onboarding-interviews",
    ONBOARDING_TUTOR_PREF_DETAIL: "/onboardings/{onboardingId}/onboarding-tutor-pref",
    TUTOR_TRIAL_COURSE_DETAIL: "/onboardings/{onboardingId}/onboarding-tutor-trial-course",
    GET_ONBOARDING_COMMENT_LIST: "/onboardings/comments/",
    ADD_ONBOARDING_COMMENT_DETAIL: "/onboardings/comments/"
}

export const onboardingType = {
    TUTOR: {
        label: "Tutor",
        value: "tutor"
    }
}

export const onboardingRequestType = {
    ALLOWED: {
        label: "Allowed",
        value: "allowed"
    },
    WAITING: {
        label: "Waiting",
        value: "waiting"
    },
    BLOCKED: {
        label: "Blocked",
        value: "blocked"
    },
}

export const docType = {
    RESUME: {
        label: "Resume",
        value: "resume"
    },
    IDENTITY: {
        label: "Identity Documents",
        value: "identity"
    },
    ACADEMIC: {
        label: "Academic Qualifications",
        value: "academic"
    },
    CERTIFICATE: {
        label: "Certificates",
        value: "certificate"
    },
    WORK_EXP: {
        label: "Work Experience",
        value: "work_exp"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}

export const userDetailStatus = {
    PENDING: {
        label: "Fill Your Details",
        value: "pending",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    UNDER_REVIEW: {
        label: "Details Submitted",
        value: "under_review",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    APPROVED: {
        label: "Verified",
        value: "approved",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        lightColor: "red-100",
        darkColor: "red-500"
    }
}

export const introVideoStatus = {
    PENDING: {
        label: "Upload Video",
        value: "pending",
        textName: "Pending",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted",
        textName: "Submitted",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    UNDER_REVIEW: {
        label: "Video submitted",
        value: "under_review",
        textName: "Under Review",
        lightColor: "pink-100",
        darkColor: "pink-500"
    },
    APPROVED: {
        label: "Verified",
        value: "approved",
        textName: "Approved",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        textName: "Rejected",
        lightColor: "red-100",
        darkColor: "red-500"
    }
}

export const relevantQuestionStatus = {
    PENDING: {
        label: "Show Questions",
        value: "pending",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },
    SUBMITTED: {
        label: "Show Response",
        value: "submitted",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    UNDER_REVIEW: {
        label: "Submitted",
        value: "under_review",
        lightColor: "pink-100",
        darkColor: "pink-500"
    },
    APPROVED: {
        label: "Verified",
        value: "approved",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        lightColor: "red-100",
        darkColor: "red-500"
    }
}

export const documentStatus = {
    PENDING: {
        label: "Upload Documents",
        value: "pending",
        textName: "Pending",
        textColor: "yellow-500",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted",
        textName: "Submitted",
        textColor: "blue-500",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    UNDER_REVIEW: {
        label: "Under Review",
        value: "under_review",
        textName: "Under Review",
        textColor: "pink-500",
        lightColor: "pink-100",
        darkColor: "pink-500"
    },
    APPROVED: {
        label: "Verified",
        value: "approved",
        textName: "Verified",
        textColor: "green-500",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    RE_UPLOAD: {
        label: "Re Upload",
        value: "re_upload",
        textName: "Upload Again",
        textColor: "orange-500",
        lightColor: "orange-100",
        darkColor: "orange-500"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        textName: "Rejected",
        textColor: "red-500",
        lightColor: "red-100",
        darkColor: "red-500"
    },
}

export const interviewCallStatus = {
    PENDING: {
        label: "Select a slot",
        value: "pending",
        lightColor: "yellow-100",
        darkColor: "yellow-500",
    },
    SCHEDULED: {
        label: "Scheduled",
        value: "scheduled",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    APPROVED: {
        label: "Verified",
        value: "approved",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        lightColor: "red-100",
        darkColor: "red-500"
    },
    RESCHEDULED: {
        label: "Rescheduled",
        value: "rescheduled",
        lightColor: "sky-100",
        darkColor: "sky-500"
    },
    ON_GOING: {
        label: "On Going",
        value: "on_going",
        lightColor: "gray-100",
        darkColor: "gray-500"
    }
}

export const tutorPrefStatus = {
    PENDING: {
        label: "Preference",
        value: "pending",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },
    SUBMITTED: {
        label: "Submitted",
        value: 'submitted',
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    UNDER_REVIEW: {
        label: "Under Review",
        value: "under_review",
        lightColor: "pink-100",
        darkColor: "pink-500"
    },
    APPROVED: {
        label: "Verified",
        value: "approved",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        lightColor: "red-100",
        darkColor: "red-500"
    }
}

export const qualifiedTutorStatus = {
    PENDING: {
        label: "Create Class",
        value: "pending",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    UNDER_REVIEW: {
        label: "Under Review",
        value: "under_review",
        lightColor: "pink-100",
        darkColor: "pink-500"
    },
    APPROVED: {
        label: "Verified",
        value: "approved",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        lightColor: "red-100",
        darkColor: "red-500"
    }
}

export const trialClassStatus = {
    PENDING: {
        label: "Create Class",
        value: "pending",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    UNDER_REVIEW: {
        label: "Under Review",
        value: "under_review",
        lightColor: "pink-100",
        darkColor: "pink-500"
    },
    APPROVED: {
        label: "Verified",
        value: "approved",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        lightColor: "red-100",
        darkColor: "red-500"
    }
}

// to be deleted later
export const onBoardingStatus = {
    PENDING: {
        label: "Pending",
        value: "pending",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },
    UNDER_REVIEW: {
        label: "Under Review",
        value: "under_review",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    WAITING: {
        label: "Waiting",
        value: "waiting",
        lightColor: "orange-100",
        darkColor: "orange-500"
    },
    APPROVED: {
        label: "Approved",
        value: "approved",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    VERIFIED: {
        label: "Verified",
        value: "verified",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        lightColor: "red-100",
        darkColor: "red-500"
    },
    BLOCKED: {
        label: "Blocked",
        value: "blocked",
        lightColor: "gray-100",
        darkColor: "gray-500"
    }
}