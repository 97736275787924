import format from 'string-format';

import {baseApiInstance, baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/master/master.const"

class MasterService {

    getMasterCountryList = async () => {
       const response = await baseApiInstance().get(
            url.COUNTRY_LIST
        )

        return response;
    }

    getMasterCountryDetailByMasterCountryId = async ({params}) => {
       const response = await baseApiInstance().get(
           format(url.COUNTRY_DETAIL, params)
        )

        return response;
    }

    getMasterTimezoneDetailByTimeZone = async ({params}) => {
       const response = await baseApiInstance().get(
            format(url.MASTER_TIMEZONE_DETAIL, params)
        )

        return response;
    }

    getMasterStateList = async ({params}) => {
        const response = await baseApiInstance().get(
            format(url.STATE_LIST, params)
        )

        return response;
    }

    getMasterCityList = async ({params}) => {
        const response = await baseApiInstance().get(
            format(url.CITY_LIST, params)
        )

        return response
    }
    
    getMasterCurrencyList = async () => {
        const response = await baseApiInstance().get(
            url.CURRENCY_LIST
        )

        return response
    }

    getMasterCurrencyRateList = async () => {
        const response = await baseAuthApiInstance().get(
            url.CURRENCY_RATE_LIST
        )

        return response
    }

    getMasterTimezoneList = async () => {
        const response = await baseApiInstance().get(
            url.TIMEZONE_LIST
        )

        return response
    }

    getMasterLanguageList = async () => {
        const response = await baseApiInstance().get(
            url.LANGUAGE_LIST
        )

        return response
    }

    getMasterLanguageLevelList = async () => {
        const response = await baseApiInstance().get(
            url.LANGUAGE_LEVEL_LIST
        )

        return response
    }

    getMasterProficiencyList = async () => {
        const response = await baseApiInstance().get(
            url.PROFICIENCY_LIST
        )

        return response
    }

    getMasterTagList = async () => {
        const response = await baseApiInstance().get(
            url.TAG_LIST
        )

        return response
    }

    getMasterSocialList = async () => {
        const response = await baseApiInstance().get(
            url.SOCIAL_LIST
        )

        return response;
    }
}

export default new MasterService()