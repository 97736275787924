import { useGoogleOneTapLogin } from "@react-oauth/google";
import { useAppDispatcher, useAppState } from "hooks/useStore";

import { createOneTapGoogleLogin } from "redux/auth/auth.request";

import { timeZone } from "utils/dateTime.utils";

const GoogleOneTapProvider = ({ children }) => {
    const { session } = useAppState(s => s.auth)
    const { user } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()

    const onHandleSuccess = (credentialResponse) => {
        const requestPayload = {
            timeZone: timeZone,
            credentials: credentialResponse?.credential,
            source: window.location.href
        }
        dispatcher(createOneTapGoogleLogin(requestPayload))
    }

    useGoogleOneTapLogin({
        disabled: (session?.isLoggedIn || session?.isLoading || user?.isLoading),
        onSuccess: onHandleSuccess,
        onError: () => {
            console.log('Login Failed');
        },
        select_account: true,
        use_fedcm_for_prompt: true
    })

    return children;
};

export default GoogleOneTapProvider;
