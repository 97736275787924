import { useState } from "react";
import { cn } from "utils/cn.utils";

import { FaPlusCircle } from "react-icons/fa";
import { AiOutlineCheck } from "react-icons/ai";
import { FaSpinner } from "react-icons/fa";

import { Button } from "components/common-components/Buttons";
import { InputWithLabel } from "components/common-components/Form";

import { createUserPayoutDetailByUserId } from "redux/payout/payout.request";
import { updateUserDefaultDetailsByUserId } from "redux/default/default.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { payoutType } from "redux/payout/payout.const";

export const UPITransferSection = () => {
    const { user } = useAppState((state) => state.user)
    const { userPayoutList } = useAppState((state) => state.payout)
    const { userDefaultDetail } = useAppState((state) => state.userDefault)

    const dispatcher = useAppDispatcher();

    const [isFillUpiIdDetail, setIsFillUpiIdDetail] = useState(false)
    const [upiId, setUpiId] = useState("")

    const filteredPayoutType = userPayoutList?.userPayoutList?.filter((userPayout) => userPayout?.payoutType === payoutType?.UPI?.value)
    const filteredPayout = userPayoutList?.userPayoutList?.filter((userPayout) => userPayout?.payoutType === payoutType?.UPI?.value)?.filter((payout) => payout?.userPayoutId === userDefaultDetail?.userDefaultDetail?.userPayoutId)

    const makeDefault = () => {
        const filteredUserPayoutId = userPayoutList?.userPayoutList?.find((userPayout) => userPayout?.payoutType === payoutType?.UPI?.value)
        if (filteredUserPayoutId) {
            dispatcher(updateUserDefaultDetailsByUserId(
                user?.user?.userId,
                {
                    userPayoutId: filteredUserPayoutId?.userPayoutId
                }
            ))
        }
        else {
            alert("Please add UPI ID!")
        }
    };

    const submitUPIDetails = () => {
        if (upiId?.includes("@") && upiId?.length > 5 && upiId?.length < 15) {
            alert("Are you Sure you want to submit your UPI ID?")
            dispatcher(createUserPayoutDetailByUserId(
                user?.user?.userId,
                {
                    payoutType: payoutType?.UPI?.value,
                    upiId: upiId
                }
            ))
            setIsFillUpiIdDetail(false)
        } else {
            alert("Invalid UPI ID")
        }
    }

    return (
        <div className={"col-start-1 col-span-full grid grid-cols-12 gap-5"}>
            <div className="col-start-1 col-span-full md:col-start-1 md:col-span-4 lg:col-start-1 lg:col-span-3">
                <p className="px-5 py-2 font-bodyPri font-bold text-text-900 text-base whitespace-normal lg:whitespace-nowrap">
                    {"UPI ID (INR Only)"}
                </p>
                {filteredPayoutType?.length > 0 && filteredPayout?.length === 0 && (
                    <button
                        onClick={makeDefault}
                        className={"px-5 font-bodyPri font-normal text-base text-primary-dark underline"}
                    >
                        {"Make Default"}
                    </button>
                )}
                {userDefaultDetail?.isLoading &&
                    <div className={"flex items-center justify-start gap-1"}>
                        <span className={"font-bodyPri font-normal text-text-700 text-sm"}>
                            {"Please Wait ..."}
                        </span>
                        <FaSpinner className={"text-lg text-primary-main"} />
                    </div>
                }
                {filteredPayoutType?.length > 0 && filteredPayout?.length > 0 && (
                    <div className={"flex items-center justify-start gap-1.5 px-10"}>
                        <span className={"font-bodyPri font-normal text-text-800 text-sm italic"}>
                            {"Default"}
                        </span>
                        <AiOutlineCheck className={"text-lg text-green-500"} />
                    </div>
                )}
            </div>
            {userPayoutList?.isLoading &&
                <div className={"flex items-center justify-start gap-1"}>
                    <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                        {"Please wait ..."}
                    </span>
                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                </div>
            }
            {userPayoutList?.userPayoutList &&
                userPayoutList?.userPayoutList?.filter((payout) => (payout?.payoutType === payoutType?.UPI?.value))?.map((userPayout) => (
                    <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-4 lg:col-span-full flex items-center justify-start gap-3 px-5 md:px-0"}>

                        {/* account user details */}
                        <div className={"flex items-center justify-start gap-5"}>
                            <span className={"font-bodyPri font-normal text-text-700 text-sm"}>
                                {"UPI ID:"}
                            </span>
                            <span className={"font-bodyPri font-normal text-text-900 text-lg"}>
                                {userPayout?.upiId}
                            </span>
                        </div>
                    </div>
                ))}
            {isFillUpiIdDetail &&
                <div className={"w-full max-w-sm p-4 pl-6 space-y-4 border rounded-md md:w-96"}>
                    <InputWithLabel
                        label="Your UPI ID"
                        onChange={(event) => setUpiId(event.target.value)}
                        placeholder="Eg. JohnDoe@upi"
                        value={upiId}
                    />
                    <div className="flex justify-end items-center gap-3">
                        <Button
                            name="Cancel"
                            size="small"
                            style="outline"
                            onClick={() => {
                                setIsFillUpiIdDetail(false)
                            }}
                        />
                        <Button
                            name={"Submit"}
                            size={"small"}
                            style={"primary"}
                            onClick={submitUPIDetails}
                        />
                    </div>
                </div>
            }
            {(!isFillUpiIdDetail && userPayoutList?.userPayoutList?.filter((payout) => payout?.payoutType === payoutType?.UPI?.value)?.length === 0) &&
                <div
                    className={cn(
                        "col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-4 lg:col-span-full",
                        "flex justify-center flex-1 py-4 bg-blue-100 rounded-md cursor-pointer px-5 md:px-0"
                    )}
                    onClick={() => {
                        setUpiId("")
                        setIsFillUpiIdDetail(true)
                    }}
                >
                    <div className="flex flex-col items-center justify-center">
                        <FaPlusCircle className="text-blue-500 " size={30} />
                        <p className="text-lg font-bold text-blue-500">
                            {"Add UPI ID"}
                        </p>
                    </div>
                </div>
            }
        </div>
    );
}