import { doc, updateDoc } from "firebase/firestore";
import { database } from "config/firebase.config"

export default async function updatePaymentStatus(selectedChat, message) {
  try {
    const messageRef = doc(
      database,
      "chats",
      selectedChat.id,
      "messages",
      message.id
    );
    await updateDoc(messageRef, {
      paymentStatus: "paid",
    });
    console.log("updated payment status");
  } catch (err) {
    console.log(err);
  }
}
