import { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setMakePaymentPayload } from 'redux/payment/payment.slice';

import PaymentMethodSummery from 'components/modals/paymentMethodModal/PaymentMethodSummary';
import PaymentForm from 'components/modals/paymentMethodModal/PaymentForm';


const PaymentMethodModal = () => {
    const { user } = useAppState(s => s.user)
    const { makePayment } = useAppState(s => s.payment)
    const makePaymentPayload = makePayment?.makePaymentPayload

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        const processingFeeAmount = Math.ceil(makePaymentPayload?.amountToPay * user?.user?.processingFeePct / 100)
        const totalAmount = Math.ceil(makePaymentPayload?.amountToPay + processingFeeAmount)
        dispatcher(setMakePaymentPayload({
            ...makePaymentPayload,
            email: user?.user?.email || '',
            firstName: user?.user?.firstName || '',
            lastName: user?.user?.lastName || '',
            processingFeePct: user?.user?.processingFeePct,
            processingFee: processingFeeAmount,
            totalAmountToPay: totalAmount,
        }))
    }, [user?.user])

    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"min-h-[24rem] block space-y-5"}>
                <div>
                    <PaymentMethodSummery />
                </div>

                <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>

                <div className={"self-center w-full"}>
                    <Elements stripe={loadStripe(user?.user?.stripePk)}>
                        <PaymentForm />
                    </Elements>
                </div>
            </div>
        </div>
    )
}

export default PaymentMethodModal;