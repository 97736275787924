import { toast } from "react-toastify";
import appointmentService from "./appointment.service";

import {
    setPublicAppointmentsLoading,
    setPublicAppointmentsData,
    setPublicAppointmentsStatus,
    setPublicAppointmentsMessage,

    setPublicAppointmentLoading,
    setPublicAppointmentData,
    setPublicAppointmentStatus,
    setPublicAppointmentMessage,

    setUserAppointmentListLoading,
    setUserAppointmentListData,
    setUserAppointmentListMessage,

    setUserAppointmentDetailLoading,
    setUserAppointmentDetailData,
    setUserAppointmentDetailMessage,

    setAddAppointmentDetailLoading,
    setAddAppointmentDetailData,
    setAddAppointmentDetailMessage,

    setModifyAppointmentDetailLoading,
    setModifyAppointmentDetailData,
    setModifyAppointmentDetailMessage,

    setDestroyAppointmentDetailLoading,
    setDestroyAppointmentDetailData,
    setDestroyAppointmentDetailMessage,

    setAppointmentLocationListLoading,
    setAppointmentLocationListData,
    setAppointmentLocationListMessage,

    setAddAppointmentLocationDetailLoading,
    setAddAppointmentLocationDetailData,
    setAddAppointmentLocationDetailMessage,

    setModifyAppointmentLocationDetailLoading,
    setModifyAppointmentLocationDetailData,
    setModifyAppointmentLocationDetailMessage,

    setDestroyAppointmentLocationDetailLoading,
    setDestroyAppointmentLocationDetailMessage,
} from "redux/appointment/appointment.slice";

export const getPublicAppointmentList = (query) => async (dispatch) => {
    dispatch(setPublicAppointmentsLoading(true))

    try {
        const requestData = { query }
        const response = await appointmentService.getPublicAppointmentList(requestData)
        if (response.status === 200) {
            dispatch(setPublicAppointmentsData(response.data.data))
            dispatch(setPublicAppointmentsStatus(response.status))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        dispatch(setPublicAppointmentStatus(error?.response.status))
        dispatch(setPublicAppointmentsMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
    } finally {
        dispatch(setPublicAppointmentsLoading(false))
    }
}

export const getPublicAppointmentDetail = (appointmentId) => async (dispatch) => {
    dispatch(setPublicAppointmentLoading(true))

    try {
        const requestData = {
            params: { id: appointmentId },
        }
        const response = await appointmentService.getPublicAppointmentDetail(requestData)
        if (response.status === 200) {
            dispatch(setPublicAppointmentData(response.data.data))
            dispatch(setPublicAppointmentStatus(response.status))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        dispatch(setPublicAppointmentStatus(error?.response.status))
        dispatch(setPublicAppointmentMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
    } finally {
        dispatch(setPublicAppointmentLoading(false))
    }
}

// user appointment list
export const getUserAppointmentList = (userId, query) => async (dispatch) => {
    dispatch(setUserAppointmentListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: query
        }
        const response = await appointmentService.getUserAppointmentList(requestData)
        if (response.status === 200) {
            dispatch(setUserAppointmentListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setUserAppointmentListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setUserAppointmentListLoading(false))
    }
}

// user appointment detail
export const getUserAppointmentDetail = (appointmentId, userId) => async (dispatch) => {
    dispatch(setUserAppointmentDetailLoading(true))

    try {
        const requestData = {
            params: { appointmentId: appointmentId, userId: userId }
        }
        const response = await appointmentService.getUserAppointmentDetail(requestData)
        if (response.status === 200) {
            dispatch(setUserAppointmentDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setUserAppointmentDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setUserAppointmentDetailLoading(false))
    }
}

// add appointment detail
export const createAppointmentDetail = (body) => async (dispatch) => {
    dispatch(setAddAppointmentDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await appointmentService.createAppointmentDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddAppointmentDetailData(response.data.data))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        dispatch(setAddAppointmentDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setAddAppointmentDetailLoading(false))
    }
}

// update appointment detail
export const updateAppointmentDetail = (appointmentId, body) => async (dispatch) => {
    dispatch(setModifyAppointmentDetailLoading(true))

    try {
        const requestData = {
            params: { appointmentId: appointmentId },
            body: body
        }
        const response = await appointmentService.updateAppointmentDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyAppointmentDetailData(response.data.data))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        dispatch(setModifyAppointmentDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setModifyAppointmentDetailLoading(false))
    }
}

// delete appointment detail
export const deleteAppointmentDetail = (appointmentId) => async (dispatch) => {
    dispatch(setDestroyAppointmentDetailLoading(true))

    try {
        const requestData = {
            params: { appointmentId: appointmentId }
        }
        const response = await appointmentService.deleteAppointmentDetail(requestData)
        if (response.status === 204) {
            dispatch(setDestroyAppointmentDetailData({ id: appointmentId }))
            toast.success(response.data.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setDestroyAppointmentDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyAppointmentDetailLoading(false))
    }
}

// location list
export const getAppointmentLocationList = (appointmentId) => async (dispatch) => {
    dispatch(setAppointmentLocationListLoading(true))

    try {
        const requestData = {
            params: { appointmentId: appointmentId }
        }
        const response = await appointmentService.getAppointmentLocationList(requestData)
        if (response.status === 200) {
            dispatch(setAppointmentLocationListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setAppointmentLocationListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
    } finally {
        dispatch(setAppointmentLocationListLoading(false))
    }
}

// create location detail
export const createAppointmentLocationDetail = (appointmentId, body) => async (dispatch) => {
    dispatch(setAddAppointmentLocationDetailLoading(true))

    try {

        const requestData = {
            params: { appointmentId: appointmentId },
            body: body
        }
        const response = await appointmentService.createAppointmentLocationDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddAppointmentLocationDetailData(response.data.data))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setAddAppointmentLocationDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
    } finally {
        dispatch(setAddAppointmentLocationDetailLoading(false))
    }
}

// update appointment location
export const updateAppointmentLocationDetail = (appointmentId, locationId, body) => async (dispatch) => {
    dispatch(setModifyAppointmentLocationDetailLoading(true))

    try {
        const requestData = {
            params: { appointmentId: appointmentId, locationId: locationId },
            body: body
        }
        const response = await appointmentService.updateAppointmentLocationDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyAppointmentLocationDetailData(response.data.data))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setModifyAppointmentLocationDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
    } finally {
        dispatch(setModifyAppointmentLocationDetailLoading(false))
    }
}

export const deleteAppointmentLocationDetail = (appointmentId, locationId) => async (dispatch, getState) => {
    dispatch(setDestroyAppointmentLocationDetailLoading(false))

    try {
        const { appointmentLocationList } = getState().appointment
        const requestData = {
            params: { appointmentId: appointmentId, locationId: locationId }
        }
        const response = await appointmentService.deleteAppointmentLocationDetail(requestData)
        if (response.status === 204) {
            dispatch(setAppointmentLocationListData({
                ...appointmentLocationList?.data,
                result: appointmentLocationList?.data?.result.filter((locationItem) => locationItem?.id !== locationId)
            }))
            toast.success(response?.data?.data?.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setDestroyAppointmentLocationDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
    } finally {
        dispatch(setDestroyAppointmentLocationDetailLoading(false))
    }
}