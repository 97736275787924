import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { modalConst } from "redux/local/local.const";
import { courseType } from "redux/course/course.const";
import { setModal } from "redux/local/local.slice";

const SubmitFormBar = ({ editBtn }: any) => {
    const { modal } = useAppState((s) => s.local)
    const { offeringCourse } = useAppState((s) => s.offering)

    const dispatcher = useAppDispatcher()

    const onSubmit = () => {
        if (offeringCourse?.offeringCourse?.skills?.length < 5) {
            toast.warning("Please add at least 5 skills!")
            return;
        }
        if (editBtn) {
            if (window.confirm("You have unsaved changes, which will be discarded. are you sure you want to submit?")) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.SUBMIT_COURSE_MODAL.stateKey]: true
                }))
                return;
            } else return;
        }

        dispatcher(setModal({
            ...modal,
            [modalConst.SUBMIT_COURSE_MODAL.stateKey]: true
        }))
    }

    return (
        <div className="px-2">
            <div
                className={cn(
                    "shadow-lg rounded-md m-2",
                    "flex items-center justify-between max-w-4xl px-3 py-3 mx-auto bg-white",
                    "flex-col md:flex-row"
                )}
            >
                <p className="text-xl font-medium text-text-800 font-bodyPri tracking-wide">
                    DRAFT: Creating your own {offeringCourse?.offeringCourse?.courseType === courseType?.ONE_ON_ONE?.value ? courseType?.ONE_ON_ONE?.label + " lesson" : courseType?.GROUP?.label + " lesson"}
                </p>
                <div className="flex mt-2 space-x-2 md:m-0 font-medium text-text-800 font-bodyPri tracking-wide">
                    <button
                        className={cn(
                            "text-center py-1 px-4  text-primary-main border-2 border-primary-main hover:bg-primary-main hover:text-text-50 rounded-lg",
                            "hover:scale-95 transition-transform",
                            "whitespace-nowrap "
                        )}
                        onClick={onSubmit}
                    >
                        {"Submit"}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SubmitFormBar;