import { memo } from 'react';

import { motion } from "framer-motion";

import ButtonLoader from 'components/loader/button-loader';

import { cn } from 'utils/cn.utils';

const SaveAndResetButtons = memo(({
    isSaveBtnLoading = false,
    isResetBtnLoading = false,
    saveBtnDisabled = false,
    resetBtnDisabled = false,
    onHandleSave,
    onHandleReset,
}) => {
    return (
        <div className={"flex justify-center items-center py-5 space-x-5"}>
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.3 }}
                className={cn(
                    "py-2 px-3 text-text-900 text-sm md:text-base ease-in-out duration-200 border border-text-400 hover:border-text-600 rounded",
                    "font-bodyPri font-normal text-sm sm:text-base",
                    isResetBtnLoading && "cursor-wait"
                )}
                disabled={resetBtnDisabled || isResetBtnLoading}
                onClick={onHandleReset}
            >
                {isResetBtnLoading &&
                    <ButtonLoader isLoading={isResetBtnLoading} className={"h-10"} />
                }
                {!isResetBtnLoading &&
                    <span className={""}>
                        {"Reset"}
                    </span>
                }
            </motion.button>
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.3 }}
                className={cn(
                    "py-2 px-3 text-white text-sm md:text-base ease-in-out duration-200 bg-primary-dark hover:bg-secondary-dark rounded",
                    "font-bodyPri font-normal text-sm sm:text-base",
                    saveBtnDisabled && "bg-text-300 text-text-200 hover:bg-text-300 cursor-not-allowed",
                    isSaveBtnLoading && "cursor-wait"
                )}
                disabled={saveBtnDisabled || isSaveBtnLoading}
                onClick={onHandleSave}
            >
                {isSaveBtnLoading &&
                    <ButtonLoader isLoading={isSaveBtnLoading} className={"py-2.5"} />
                }
                {!isSaveBtnLoading &&
                    <span className={""}>
                        {"Save"}
                    </span>
                }
            </motion.button>
        </div>
    )
})

export default SaveAndResetButtons;