import React from 'react'

const HelpBtn = ({ startTour }) => {
    return (
        <button
            onClick={startTour}
            className='text-primary-dark font-medium hover:text-secondary-dark'
        >
            Guide
        </button>
    )
}

export default HelpBtn


