import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { FiX } from "react-icons/fi";

import ButtonLoader from "components/loader/ButtonLoader";

import UploadIcon from 'pages/auth/edulyteLms/assets/uploadIcon.svg';

import { uploadFileDetail } from "redux/drive/drive.request";
import { createLmsResourceDetail } from "redux/edulyteLms/lmsResource/lmsResource.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetLocalTabs, setModal } from "redux/local/local.slice";
import { resetFileUpload } from "redux/drive/drive.slice";
import { modalConst } from "redux/local/local.const";
import { lmsResourceTypeEnum } from "redux/edulyteLms/lmsResource/lmsResource.const";


const UploadFile = () => {
  const { modal } = useAppState((state) => state.local)
  const { user } = useAppState((state) => state.user)
  const { fileUpload } = useAppState((state) => state.drive)

  const dispatcher = useAppDispatcher()

  const [selectedFile, setSelectedFile] = useState(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  useEffect(() => {
    return () => {
      dispatcher(resetFileUpload())
    }
  }, [])

  useEffect(() => {
    if (fileUpload?.data) {
      const body = {
        type: lmsResourceTypeEnum.FILE.value,
        title: fileUpload?.data?.file_name,
        file_id: fileUpload?.data?.id,
        resource_owner_user_id: user?.user?.userId
      }
      dispatcher(createLmsResourceDetail(body))
      dispatcher(resetFileUpload())
      dispatcher(resetLocalTabs())
      dispatcher(setModal({
        ...modal,
        [modalConst.UPLOAD_LMS_MEDIA_MODAL.stateKey]: false
      }))
    }
  }, [fileUpload?.data])

  const handleFileSelect = (event) => {
    dispatcher(resetFileUpload())
    setSelectedFile(null)

    const file = event.target.files[0];
    if (!file || file.length === 0) {
      alert("file not selected!")
      return;
    }
    if (file?.size < 10 * 1048576) {
      setSelectedFile({
        file: file,
        fileList: event?.target?.files,
        fileUrl: URL.createObjectURL(file)
      })
    } else {
      toast.warn("File size is too large!")
    }
  };

  const handleRemoveFile = () => {
    dispatcher(resetFileUpload())
    setSelectedFile(null);
    setIsDraggingOver(false)
  };

  const handleDragOver = (event) => {
    event.preventDefault();

    setIsDraggingOver(true)
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);
  };


  const handleDrop = (event) => {
    event.preventDefault();

    setIsDraggingOver(true)
    const droppedFiles = event.dataTransfer.files;

    if (droppedFiles?.length > 0) {
      if (droppedFiles[0]?.size < 10 * 1048576) {
        setSelectedFile({
          file: droppedFiles[0],
          fileList: droppedFiles,
          fileUrl: URL.createObjectURL(droppedFiles[0])
        })
      } else {
        toast.warn("File size is too large!")
      }
    } else {
      toast.warn("Please drag a file to upload!")
    }
  };

  const onHandlePasteFile = (event) => {
    const clipboardData = event.clipboardData;
    const pastedFiles = clipboardData.files;

    if (pastedFiles?.length > 0) {
      if (pastedFiles[0]?.size < 10 * 1048576) {
        setSelectedFile({
          file: pastedFiles[0],
          fileList: pastedFiles,
          fileUrl: URL.createObjectURL(pastedFiles[0])
        })
      } else {
        toast.warn("File size is too large!")
      }
    } else {
      toast.warn("Please paste a file to upload!")
    }
  }

  const onHandleUploadFile = () => {
    if (fileUpload?.isLoading) return;

    if (!selectedFile) {
      toast.warn("Please select a file to upload!")
      return;
    }
    dispatcher(resetFileUpload())
    dispatcher(uploadFileDetail(selectedFile?.file))
  };

  return (
    <div className={"w-full flex flex-col items-center justify-center p-5 mt-10 gap-8"}>
      <div
        className={cn(
          "w-full flex flex-col justify-center items-center",
          (isDraggingOver || selectedFile)
            ? "px-3 py-1 w-fit bg-divider-darkLight rounded-lg"
            : ""
        )}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onPaste={onHandlePasteFile}
      >
        {!selectedFile &&
          <div className={"relative"}>
            <label
              htmlFor={"fileInput"}
              className={"flex flex-col justify-center items-center cursor-pointer text-gray-500"}
            >
              <img
                src={UploadIcon}
                style={{ width: '100px' }}
                alt={"Upload"}
              />
              {"Browse or drag a file here"}
            </label>
            <input
              id={"fileInput"}
              type="file"
              onChange={handleFileSelect}
              style={{ display: "none" }}
            />
          </div>
        }
        {selectedFile && (
          <div className={"w-full px-5 py-1.5 flex items-center justify-center gap-5"}>
            <span className={"font-bodyPri font-medium text-text-800 text-base whitespace-nowrap"}>
              {selectedFile?.file?.name?.length > 25
                ? selectedFile?.file?.name?.slice(0, 25) + " ..."
                : selectedFile?.file?.name
              }
            </span>
            <button
              className={"p-0.5 bg-text-50 rounded-full cursor-pointer"}
              onClick={handleRemoveFile}>
              <FiX size={15} className={"font-bold text-divider-dark"} />
            </button>
          </div>
        )}
      </div>

      {fileUpload?.message &&
        <div className={"w-full flex items-center justify-center"}>
          <span className={"w-full text-center font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
            {fileUpload?.message}
          </span>
        </div>
      }
      <div className={"w-full flex justify-center items-center"}>
        <button
          className={cn(
            "w-fit px-3 sm:px-5 py-1.5 flex items-center justify-center rounded-lg bg-primary-dark text-text-50 cursor-pointer",
            "hover:bg-secondary-dark disabled:bg-text-300 disabled:cursor-not-allowed"
          )}
          disabled={!selectedFile}
          onClick={onHandleUploadFile}
        >
          {fileUpload.isLoading &&
            <ButtonLoader isLoading={fileUpload.isLoading} />
          }
          {!fileUpload?.isLoading && "Upload"}
        </button>
      </div>
    </div>
  );
};

export default UploadFile;