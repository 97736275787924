export const data = {
  tabs: [
    {
      tab: "About Me",
      value: "ABOUT_ME"
    },
    {
      tab: "Appointments",
      value: "ABOUT_ME"
    },
    {
      tab: "Skills",
      value: "SKILLS"
    },
    {
      tab: "Teaching Style",
      value: "TEACHING_STYLE"
    },
    {
      tab: "Qualifications",
      value: "QUALIFICATIONS"
    },
    {
      tab: "Topics",
      value: "TOPICS"
    },
    {
      tab: "Classes",
      value: "CLASSES"
    },
    {
      tab: "Reviews",
      value: "REVIEWS"
    },
  ],
};
