import { useEffect, useReducer } from 'react';
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { FaSpinner, FaTimesCircle } from 'react-icons/fa';
import { MdAdd, MdOutlineDragIndicator } from 'react-icons/md';

import { Draggable } from 'react-drag-reorder';
import SelectSearch, { fuzzySearch } from 'react-select-search';

import ComponentLoader from 'components/loader/ComponentLoader';

import SaveCancelBtn from 'pages/auth/onboarding/commonComponents/onboardingButtons/SaveCancelBtn';
import AddBorderButton from 'pages/auth/onboarding/commonComponents/onboardingButtons/AddBorderButton';
import { profileEditBtnConst } from 'pages/auth/onboarding/data';

import { getAllCategoryList } from 'redux/category/category.request';
import { getMasterProficiencyList } from 'redux/master/master.request';
import {
    createTutorCategoryDetail,
    deleteTutorCategoryDetail,
    getTutorCategoryList,
    updateTutorCategoryListOrder
} from 'redux/tutor/tutor.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearCategoryList } from 'redux/category/category.slice';
import { setClearProfList } from 'redux/master/master.slice';
import {
    resetAddTutorCategoryDetail,
    resetDeleteTutorCategoryDetail,
    resetUpdateTutorCategoryListOrder
} from 'redux/tutor/tutor.slice';

const subjectTopicReducer = (state, action) => {
    switch (action.type) {
        case "SHOW_ADD_BTN":
            return { ...state, isShowAddBtn: action.payload }
        case "SHOW_DROPDOWNS":
            return { ...state, isShowDropdowns: action.payload }
        case "CATEGORY_AND_PROFICIENCY_LIST":
            return { ...state, categoryProficiencyList: action.payload }
        case "SELECTED_CATEGORY":
            return { ...state, category: action.payload }
        case "SELECTED_PROFICIENCY":
            return { ...state, proficiency: action.payload }
        case "INITIAL_STATE":
            return {
                ...state,
                category: { category: "", id: null },
                proficiency: { proficiency: "", id: null }
            }
        default:
            return state;
    }
}


const SubjectTopicTeach = ({ editBtnConst, setEditBtnConst }) => {
    const { profList } = useAppState((state) => state.master)
    const { categoryList } = useAppState((state) => state.category)
    const { user } = useAppState((state) => state.user)
    const { tutorCategoryList, addTutorCategoryDetail, updateTutorCategoryOrder, deleteTutorCategory } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()

    const [state, dispatch] = useReducer(subjectTopicReducer, {
        isShowAddBtn: false,
        isShowDropdowns: false,
        categoryProficiencyList: [],
        category: {
            category: "",
            id: null
        },
        proficiency: {
            proficiency: "",
            id: null
        }
    })

    const loadingElement = tutorCategoryList?.isLoading || addTutorCategoryDetail?.isLoading || updateTutorCategoryOrder?.isLoading || deleteTutorCategory?.isLoading
    const isTutorCategorySimilar = JSON.stringify(state.categoryProficiencyList) === JSON.stringify(tutorCategoryList?.data?.results)

    useEffect(() => {
        return () => {
            // dispatcher(resetTutorCategoryList())
            dispatcher(resetAddTutorCategoryDetail())
            dispatcher(resetUpdateTutorCategoryListOrder())
            dispatcher(resetDeleteTutorCategoryDetail())
        }
    }, [])

    useEffect(() => {
        dispatcher(getMasterProficiencyList())
        dispatcher(getAllCategoryList())

        return () => {
            dispatcher(setClearProfList())
            dispatcher(setClearCategoryList())
        }
    }, [])

    // useEffect(() => {
    //     if (user?.user?.tutor?.tutorId) {
    //         dispatcher(getTutorCategoryList({ tutorId: user?.user?.tutor?.tutorId }))
    //     }
    // }, [user?.user])

    useEffect(() => {
        if (tutorCategoryList?.data?.results?.length > 0) {
            dispatch({
                type: "CATEGORY_AND_PROFICIENCY_LIST",
                payload: tutorCategoryList?.data?.results
            })
            dispatch({
                type: "SHOW_ADD_BTN",
                payload: true
            })
        }
    }, [tutorCategoryList?.data])

    const updateTutorCategoryProfOrder = () => {
        if (loadingElement) return;


        if (tutorCategoryList?.data?.results?.length >= 1 && !isTutorCategorySimilar) {
            const body = {
                order: state.categoryProficiencyList?.map((tutorCatProf) => ({
                    id: tutorCatProf?.id
                }))
            }

            dispatcher(updateTutorCategoryListOrder(body, { tutorId: user?.user?.tutor?.tutorId }))

        }
    }

    const onHandleSave = () => {
        if (!isTutorCategorySimilar) {
            updateTutorCategoryProfOrder()
        }
        if (state.isShowDropdowns) {
            if (!state?.category?.id || !state?.proficiency?.id) {
                toast.warn("category and proficiency are required fields")
                return;
            }

            const filteredCategoryAndProfList = tutorCategoryList?.data?.results?.filter((categoryProf) => (
                categoryProf?.category?.id === state?.category?.id
            ))

            if (filteredCategoryAndProfList?.length > 0) {
                toast.warn("Duplicate Entries are not allowed")
                return;
            }
            const requestDataPayload = {
                tutorId: user?.user?.tutor?.tutorId,
                category: state.category.category,
                proficiency: state.proficiency.proficiency
            }
            dispatcher(createTutorCategoryDetail(requestDataPayload))
        }

        dispatch({
            type: "INITIAL_STATE",
            payload: null
        })

        dispatch({
            type: "SHOW_DROPDOWNS",
            payload: false
        })
        dispatch({
            type: "SHOW_ADD_BTN",
            payload: true
        })

        setEditBtnConst(null)

    }

    const onHandleCancel = () => {

        dispatcher(resetAddTutorCategoryDetail())
        dispatcher(resetUpdateTutorCategoryListOrder())
        dispatcher(resetDeleteTutorCategoryDetail())

        dispatch({
            type: "INITIAL_STATE",
            payload: null
        })

        dispatch({
            type: "SHOW_DROPDOWNS",
            payload: false
        })
        dispatch({
            type: "SHOW_ADD_BTN",
            payload: true
        })
        setEditBtnConst(null)
    }

    const onHandleAddNewSubject = () => {

        dispatcher(resetAddTutorCategoryDetail())
        dispatcher(resetUpdateTutorCategoryListOrder())
        dispatcher(resetDeleteTutorCategoryDetail())

        if (!editBtnConst || editBtnConst !== profileEditBtnConst?.SUBJECT_TOPIC?.value) {
            setEditBtnConst(profileEditBtnConst?.SUBJECT_TOPIC?.value)
        }
        if (state.categoryProficiencyList?.length >= 1) {
            dispatch({
                type: "SHOW_ADD_BTN",
                payload: false
            })
            dispatch({
                type: "SHOW_DROPDOWNS",
                payload: true
            })
        } else {
            dispatch({
                type: "SHOW_ADD_BTN",
                payload: false
            })
            dispatch({
                type: "SHOW_DROPDOWNS",
                payload: true
            })
        }
    }

    const getChangedPos = (currentPos, newPos) => {
        if (!editBtnConst || editBtnConst !== profileEditBtnConst?.SUBJECT_TOPIC?.value) {
            setEditBtnConst(profileEditBtnConst?.SUBJECT_TOPIC?.value)
        }
        var updatedList = [...state.categoryProficiencyList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(currentPos, 1);
        // Add dropped item
        updatedList.splice(newPos, 0, reorderedItem);
        // Update State
        dispatch({
            type: "CATEGORY_AND_PROFICIENCY_LIST",
            payload: updatedList
        })
    }

    const removeSelectedCategoryProf = (categoryProf) => {
        if (loadingElement) return;

        dispatcher(deleteTutorCategoryDetail(categoryProf?.id))
    }

    const onHandleSelection = (key, option) => {
        if (categoryList?.isLoading || profList?.isLoading || loadingElement) return;

        if (key === "SelectCategory") {
            const selectedCategory = categoryList?.categoryList?.find((category) => category?.categoryId === option)
            const newSelectedCategory = {
                category: selectedCategory?.category,
                id: selectedCategory?.categoryId
            }
            dispatch({
                type: "SELECTED_CATEGORY",
                payload: newSelectedCategory
            })
        } else {
            const selectedProficiency = profList?.profList?.find((proficiency) => proficiency?.masterProficiencyId === option)
            const newSelectedProficiency = {
                proficiency: selectedProficiency?.proficiency,
                id: selectedProficiency?.masterProficiencyId
            }
            dispatch({
                type: "SELECTED_PROFICIENCY",
                payload: newSelectedProficiency
            })
        }
    }

    return (
        <div className='w-full flex flex-col items-start justify-start gap-1'>
            <div className={"w-full flex items-center justify-between gap-2"}>
                <div className='font-bodyPri font-medium text-text-900 text-base tracking-wide'>
                    {"Subjects & Topics, I teach: Levels of Mastery"}
                </div>
                {!tutorCategoryList?.isLoading &&
                    <SaveCancelBtn
                        isShowBtn={editBtnConst === profileEditBtnConst?.SUBJECT_TOPIC?.value || loadingElement}
                        onHandleSave={onHandleSave}
                        onHandleCancel={onHandleCancel}
                    />
                }
            </div>
            {tutorCategoryList?.isLoading &&
                <ComponentLoader isLoading={tutorCategoryList?.isLoading} />
            }
            {(!tutorCategoryList?.isLoading && tutorCategoryList?.message) &&
                <div className={"flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {tutorCategoryList?.message}
                    </span>
                </div>
            }
            {!tutorCategoryList?.isLoading && ((!editBtnConst || editBtnConst !== profileEditBtnConst?.SUBJECT_TOPIC?.value) && (!tutorCategoryList?.data || tutorCategoryList?.data?.results?.length === 0)) &&
                <AddBorderButton btnTitle={"Add Subject(s)"} onHandleAdd={onHandleAddNewSubject} />
            }
            {(!tutorCategoryList?.isLoading && (editBtnConst === profileEditBtnConst?.SUBJECT_TOPIC?.value || tutorCategoryList?.data?.results?.length > 0)) &&
                <div className={"w-full px-3 py-3 border border-divider-medium rounded-lg space-y-3"}>
                    {loadingElement &&
                        <ComponentLoader isLoading={loadingElement} />
                    }
                    {(!loadingElement && tutorCategoryList?.data?.results?.length > 0) &&
                        <div className={"space-y-3 divide-y divide-text-200 px-3 py-0.5 md:py-1"}>
                            <Draggable onPosChange={getChangedPos}>
                                {tutorCategoryList?.data?.results?.length > 0 &&
                                    tutorCategoryList?.data?.results?.map((tutorCategory, index) => (
                                        <div key={index} className={"grid grid-cols-12 gap-5"}>
                                            <span className={"col-start-1 col-span-1 self-start"}>
                                                <MdOutlineDragIndicator className={"text-lg text-text-800"} />
                                            </span>
                                            <span className={"col-start-2 col-span-4 font-bodyPri font-medium text-text-700 text-base tracking-wide truncate"}>
                                                {tutorCategory?.category?.category}
                                            </span>
                                            <span className={"col-start-7 col-span-4 font-bodyPri font-medium text-text-900 text-base tracking-wide truncate"}>
                                                {tutorCategory?.masterProficiency?.proficiency}
                                            </span>
                                            <span className={"col-start-11 col-span-1"}>
                                                <FaTimesCircle
                                                    className={"text-lg text-red-700 cursor-pointer"}
                                                    onClick={() => removeSelectedCategoryProf(tutorCategory)}
                                                />
                                            </span>
                                        </div>
                                    ))}
                            </Draggable>
                        </div>
                    }

                    {state.isShowAddBtn &&
                        <div
                            className={"w-fit flex items-center justify-start gap-2 px-2 py-1 bg-divider-darkLight rounded-lg cursor-pointer"}
                            onClick={onHandleAddNewSubject}
                        >
                            <MdAdd className={"text-base text-text-700"} />
                            <span className={"font-bodyPri font-normal text-text-700 text-sm tracking-wide"}>
                                {"Add subject/topic"}
                            </span>
                        </div>

                    }
                    {state.isShowDropdowns &&
                        <div className={"flex flex-col items-start justify-start md:flex-row md:items-center md:justify-start gap-5"}>
                            {categoryList?.isLoading &&
                                <div className={"flex items-center justify-start gap-0.5"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                                        {"Please wait ..."}
                                    </span>
                                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                                </div>
                            }
                            {(!categoryList?.isLoading && categoryList?.categoryList) &&
                                <div className={cn(
                                    "w-full h-56 md:h-60 z-[500] overflow-hidden border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                                )}>
                                    <SelectSearch
                                        options={categoryList?.categoryList?.map((category) => ({
                                            name: category?.category,
                                            value: category?.categoryId
                                        })) || []}
                                        search
                                        autoFocus={false}
                                        placeholder={"Select Category"}
                                        filterOptions={fuzzySearch}
                                        value={state?.category?.id}
                                        onChange={(option) => onHandleSelection("SelectCategory", option)}
                                    />
                                </div>
                            }
                            {(!categoryList?.isLoading && categoryList?.errorMsg) &&
                                <span className={"font-bodyPri font-normal text-red-500 text-sm tracking-wide"}>
                                    {categoryList?.errorMsg}
                                </span>
                            }

                            {profList?.isLoading &&
                                <div className={"flex items-center justify-start gap-0.5"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                                        {"Please wait ..."}
                                    </span>
                                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                                </div>
                            }
                            {(!profList?.isLoading && profList?.profList) &&
                                <div className={cn(
                                    "w-full h-56 md:h-60 z-[500] overflow-hidden border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                                )}>
                                    <SelectSearch
                                        options={profList?.profList?.map((proficiency) => ({
                                            name: proficiency.proficiency,
                                            value: proficiency.masterProficiencyId
                                        })) || []}
                                        search
                                        autoFocus={false}
                                        placeholder={"Select Level"}
                                        filterOptions={fuzzySearch}
                                        value={state?.proficiency?.id}
                                        onChange={(option) => onHandleSelection("SelectProficiency", option)}
                                    />
                                </div>
                            }
                            {(!profList?.isLoading && profList?.errorMsg) &&
                                <span className={"font-bodyPri font-normal text-red-500 text-sm tracking-wide"}>
                                    {profList?.errorMsg}
                                </span>
                            }

                        </div>
                    }

                    {(addTutorCategoryDetail?.message || updateTutorCategoryOrder?.message || deleteTutorCategory?.message) &&
                        <div className={"w-full flex items-center justify-start gap-3"}>
                            <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                                {addTutorCategoryDetail?.message || updateTutorCategoryOrder?.message || deleteTutorCategory?.message}
                            </span>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default SubjectTopicTeach;