import { useState, memo, useCallback } from 'react';
import { toast } from 'react-toastify';

import { cn } from "utils/cn.utils";

import { FaCheck } from 'react-icons/fa';

// import ComponentLoader from 'components/loader/ComponentLoader';
import ToolTipView from 'components/tooltipView';
import { libraryTabConst } from 'components/modals/lmsModals/attachLibraryModal/data';

import QuestionOptionContainer from 'pages/auth/edulyteLms/editLmsPages/editLmsQuestion/commonComponents/QuestionOptionContainer';
import QuestionOptionResource from './QuestionOptionResource';

import { createLmsQuestionOptionResource, deleteLmsQuestionOptionDetail, updateLmsQuestionOptionDetail } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetLocalTabs, setLocalTabs, setModal } from 'redux/local/local.slice';
import { resetAddLmsQuestionOptionPayload, setAddLmsQuestionOptionPayload } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.slice';
import { setFilterProps } from 'redux/edulyteLms/lmsResource/lmsResource.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsResourceTypeEnum, resourceFileTypeEnum } from 'redux/edulyteLms/lmsResource/lmsResource.const';

const QuestionOptionItem = memo(({ index, questionOption }) => {
    const { modal, localTabs } = useAppState((state) => state.local)
    const { filterProps } = useAppState((state) => state.lms.lmsResource)
    const { lmsQuestionDetail, addLmsQuestionOption, updateLmsQuestionOption, deleteLmsQuestionOption } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()

    const [optionInputStatus, setOptionInputStatus] = useState("")
    const [isDraggingOver, setIsDraggingOver] = useState(false)

    const onHandleDragOver = (event) => {
        event.preventDefault();
        setIsDraggingOver(true)
    };

    const onHandleDragEnter = (event) => {
        event.preventDefault();
        setIsDraggingOver(true);
    };

    const onHandleDragLeave = (event) => {
        event.preventDefault();
        setIsDraggingOver(false);
    };

    const onHandleDrop = async (event) => {
        event.preventDefault();

        setIsDraggingOver(false)
        let parsedData = {}
        try {
            parsedData = JSON.parse(event.dataTransfer.getData("application/json"))
        } catch (error) {
            console.error(error)
            toast.error("Something went wrong!")
        }

        if (parsedData) {
            if (questionOption?.question_option_resources?.length > 0) {
                const filteredQuestionOptionResourceItem = questionOption?.question_option_resources?.filter((questionResource) => (questionResource?.resource?.id === parsedData?.id))
                if (filteredQuestionOptionResourceItem?.length > 0) {
                    alert("Uh-oh! You've already added this image. No duplicates, please!")
                    return;
                }
            }
            const body = {
                question_option_id: questionOption?.option_id,
                resource_id: parsedData?.id
            }
            dispatcher(createLmsQuestionOptionResource(body))
        }
    }

    const onHandleCorrectAnswer = () => {
        if (lmsQuestionDetail?.isLoading || updateLmsQuestionOption?.isLoading || deleteLmsQuestionOption?.isLoading) return;

        const body = {
            is_correct: !questionOption?.is_correct
        }
        dispatcher(updateLmsQuestionOptionDetail(questionOption?.option_id, body))

    }

    const onHandleSaveOption = useCallback((editedOption) => {
        setOptionInputStatus("Saving...")
        // if (!editedOption) {
        //     toast.warn("Please enter option!")
        //     setOptionInputStatus("")
        //     return;
        // }
        const body = {
            option: editedOption,
        }
        const payload = {
            isShowToast: false
        }
        dispatcher(updateLmsQuestionOptionDetail(questionOption?.option_id, body, payload))

        setTimeout(() => {
            setOptionInputStatus("")
        }, 1000)
    }, [questionOption])

    const onHandleAttachResource = useCallback(() => {
        dispatcher(resetLocalTabs())
        dispatcher(resetAddLmsQuestionOptionPayload())
        dispatcher(setAddLmsQuestionOptionPayload({
            ...addLmsQuestionOption?.payload,
            optionId: questionOption?.option_id
        }))
        dispatcher(setLocalTabs({
            ...localTabs,
            modalTitle: "Library",
            tabList: Object.values(libraryTabConst),
            activeTabList: [libraryTabConst.RESOURCES.value],
            currentActiveTab: libraryTabConst.RESOURCES.value,
        }))
        dispatcher(setFilterProps({
            filterProps: {
                ...filterProps,
                resourceType: lmsResourceTypeEnum.FILE.value,
                resourceFileType: resourceFileTypeEnum.IMAGE.value,
                disabledFilters: [lmsResourceTypeEnum.YOUTUBE.value, lmsResourceTypeEnum.LINK.value]
            }
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: true
        }))
    }, [modal, localTabs, questionOption])

    const onHandleDeleteOption = () => {
        if (lmsQuestionDetail?.isLoading || updateLmsQuestionOption?.isLoading || deleteLmsQuestionOption?.isLoading) return;

        if (questionOption?.option_id) {
            dispatcher(deleteLmsQuestionOptionDetail(questionOption?.option_id))
        }
    }

    return (
        <div
            className={cn(
                'w-full flex flex-1 flex-col gap-2 bg-white rounded-lg px-5 py-3 cursor-grab',
                isDraggingOver && "bg-divider-medium",
                questionOption?.is_correct && "border-2 border-green-500",
                !questionOption?.option && "border-2 border-yellow-500",
            )}
            onDragOver={onHandleDragOver}
            onDragEnter={onHandleDragEnter}
            onDragLeave={onHandleDragLeave}
            onDrop={onHandleDrop}
        >
            <QuestionOptionContainer
                index={index}
                isLoading={updateLmsQuestionOption?.isLoading}
                inputCheckbox={
                    <ToolTipView content={questionOption?.is_correct ? "Correct Answer" : "Mark Correct"}>
                        <div
                            className={cn(
                                "relative w-5 h-5 border border-text-700 rounded cursor-pointer",
                                questionOption?.is_correct && "border-green-500 bg-green-500"
                            )}
                            onClick={onHandleCorrectAnswer}
                        >
                            {questionOption?.is_correct &&
                                <span className={"absolute top-0"}>
                                    <FaCheck className={"text-lg text-white"} />
                                </span>
                            }
                        </div>
                    </ToolTipView>
                }
                optionLabel={`Type a choice here`}
                optionInput={questionOption?.option}
                optionInputStatus={optionInputStatus}
                setOptionInputStatus={setOptionInputStatus}
                onHandleUpdateOption={onHandleSaveOption}
                onHandleAttachResource={onHandleAttachResource}
                onHandleDeleteOption={onHandleDeleteOption}
            />
            {/* {addLmsQuestionOptionResource?.isLoading &&
                <ComponentLoader isLoading={addLmsQuestionOptionResource?.isLoading} />
            } */}
            {(questionOption?.question_option_resources?.length > 0) &&
                <QuestionOptionResource questionOption={questionOption} />
            }
        </div>
    )
});

export default QuestionOptionItem;