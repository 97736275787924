import tw from 'twin.macro';
import { PageContent } from 'components/generalComponent/StyledComponent';

export const PageContentStyle = tw(PageContent)`
    bg-white min-h-screen
`;

export const SignUpContainer = tw.div`
    absolute grid grid-cols-12 gap-3 place-items-center min-h-screen w-full pt-20 md:pt-0
`;

export const SignUpCardContainer = tw.div`
    w-[25rem] md:w-[28rem] col-start-1 col-span-full md:col-start-4 md:col-span-5 lg:col-start-3 lg:col-span-3
`;

export const ImageContainer = tw.div`
    hidden lg:block col-start-8 col-span-2 w-[28rem] h-[28rem] overflow-hidden rounded-md
`;