import { addUserDownloadDetailPayload, sendDownloadInvitationDetailPayload } from "./downloads.const";

export const DOWNLOADS_INITIAL_STATE = {
    userDownloadsList: {
        isLoading: false,
        data: null,
        message: null
    },
    userDownloadDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    ownerUserDownloadsList: {
        isLoading: false,
        data: null,
        message: null
    },
    ownerUserDownloadDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addUserDownloadDetail: {
        isLoading: false,
        data: null,
        payload: addUserDownloadDetailPayload,
        message: null
    },
    modifyUserDownloadDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    destroyUserDownloadDetail: {
        isLoading: false,
        message: null
    },
    // download preview detail
    addDownloadPreviewDetail: {
        isLoading: false,
        message: null
    },
    modifyOrderDownloadPreviewDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    destroyDownloadPreviewDetail: {
        isLoading: false,
        message: null
    },

    // download file detail
    addDownloadFileDetail: {
        isLoading: false,
        message: null
    },
    modifyOrderDownloadFileDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    destroyDownloadFileDetail: {
        isLoading: false,
        message: null
    },

    // download user list
    downloadUserList: {
        isLoading: false,
        data: null,
        message: null
    },
    modifyDownloadUserDetail: {
        isLoading: false,
        message: null
    },
    destroyDownloadUserDetail: {
        isLoading: false,
        message: null
    },

    // download invitation
    downloadInvitationList: {
        isLoading: false,
        data: null,
        message: null
    },
    sendDownloadInvitationDetail: {
        isLoading: false,
        data: null,
        payload: sendDownloadInvitationDetailPayload,
        message: null
    },
    validateDownloadInvitationDetail: {
        isLoading: false,
        data: null,
        message: null,
    },
    acceptAndDeclineDownloadInvitationDetail: {
        isLoading: false,
        data: null,
        message: null,
    },
    destroyDownloadInvitationDetail: {
        isLoading: false,
        message: null
    },

    // my downloads page payload
    myDownloadsPayloadDetail: {
        payload: null
    },
}