import { useAppState } from 'hooks/useStore';

import { currencyRateConversion } from 'utils/generators.utils';

const OrderItemProfileSection = () => {
    const { locals } = useAppState((state) => state.local)
    const { userOrderItemDetail } = useAppState((state) => state.order)

    return (
        <div className={"w-full flex flex-col gap-3"}>
            <div className={"w-full flex justify-between items-center gap-5"}>
                <span className={"font-bodyPri tracking-wide text-text-800 truncate"}>
                    {"Order No :"} {userOrderItemDetail?.data?.order?.order_no
                        ? (userOrderItemDetail?.data?.order?.order_no?.length > 18)
                            ? userOrderItemDetail?.data?.order?.order_no?.slice(0, 18) + " ..."
                            : userOrderItemDetail?.data?.order?.order_no
                        : "-"
                    }
                </span>
                <span className={"font-bodyPri tracking-wide text-text-800 truncate"}>
                    {"Price : "} {userOrderItemDetail?.data?.order?.master_currency?.code?.toUpperCase()} {parseFloat("" + (userOrderItemDetail?.data?.order?.total_amount / 100)).toFixed(2)}
                    {/* {"Price : "} {(currencyRateConversion(locals?.currencyCode, userOrderItemDetail?.data?.order?.total_amount))} */}
                </span>
            </div>
        </div>
    )
}

export default OrderItemProfileSection;