import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { FiChevronDown, FiChevronUp, FiChevronRight } from "react-icons/fi";

import { cn } from "utils/cn.utils";

import styles from "./styles.module.css";


const FAQ = ({ faqs, faqCount = 5, questionClassName, answerClassName }) => {

  const [minFaqCount, setMinFaqCount] = useState(faqCount)
  const [activeIndices, setActiveIndices] = useState([]);

  const toggleFAQ = (index) => {
    if (activeIndices.includes(index)) {
      setActiveIndices(activeIndices.filter((item) => item !== index));
    } else {
      setActiveIndices([...activeIndices, index]);
    }
  }

  return (
    <div className="flex flex-col">
      <AnimatePresence>
        {faqs?.slice(0, minFaqCount).map((faq, index) => (
          <motion.div
            key={index}
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            transition={{ duration: 0.2 }}
            exit={{ height: 0, opacity: 0 }}
            className={"border rounded-sm border-white border-b-divider-medium hover:border-primary-main overflow-hidden cursor-pointer"}
          >
            <div
              onClick={() => toggleFAQ(index)}
              className={cn("flex items-center gap-2 p-3 hover:text-primary-dark hover:bg-background-lightBlue", questionClassName)}
            >
              <FiChevronRight size={18} className={`ease-in-out duration-300 ${activeIndices.includes(index) ? "rotate-90" : ""}`} />
              <p className={"font-semibold"}>{faq?.question}</p>
            </div>
            <div className={`${styles.customToggle} ${activeIndices.includes(index) ? styles.toggleShow : ""}`}>
              <p className={cn("p-3", answerClassName)}>{faq?.answer}</p>
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
      {(faqs?.length > faqCount) && (
        <button
          className={"flex items-center justify-center text-primary-main hover:text-secondary-dark mt-5"}
          onClick={() => setMinFaqCount((faqCount === minFaqCount) ? faqs?.length : faqCount)}
        >
          {(faqCount === minFaqCount) ? (
            <span className="flex items-center gap-1">
              <FiChevronDown size={20} />
              {"Show all FAQs"}
            </span>
          ) : (
            <span className="flex items-center gap-1">
              <FiChevronUp size={20} />
              {"Show less"}
            </span>
          )}
        </button>
      )}
    </div>
  );
};

export default FAQ;
