import { memo } from 'react';

import { IoIosCheckmark } from 'react-icons/io';
import { MdOutlineMail } from 'react-icons/md';

import FloatingLabelSelect from 'components/floating/floatingLabelSelect';
import FloatingLabelInput from 'components/floating/floatingLabelInput';

import SaveAndCancelBtn from 'components/modals/crmModals/commonComponents/SaveAndCancelBtn';

import { useAppState } from 'hooks/useStore';

const ContactSocialItem = memo(({
    index = 0,
    isLoading = false,
    isShowSaveBtn = false,
    isShowCancelBtn = false,
    socialItem = null,
    onHandleChangeSocialDetail,
    onHandleSaveSocialDetail = () => { },
    onHandleDeleteSocialDetail
}) => {
    const { socialList } = useAppState((state) => state.master)

    return (
        <div className={"w-full flex flex-col sm:flex-row gap-5 sm:gap-3 items-center border px-3 py-5 rounded shadow-sm"}>
            <div className={"w-full sm:w-fit flex justify-between gap-3 mt-2"}>
                <div className={"text-lg text-text-700"}>
                    <MdOutlineMail />
                </div>
                <div className={"flex sm:hidden"}>
                    <SaveAndCancelBtn
                        isLoading={isLoading}
                        isShowSaveBtn={isShowSaveBtn}
                        isShowCancelBtn={isShowCancelBtn}
                        saveBtnTooltip={'Save Social'}
                        cancelBtnTooltip={"Remove Social"}
                        isBtnDisable={!socialItem?.social || !socialItem?.link}
                        onHandleSaveBtn={() => onHandleSaveSocialDetail(socialItem)}
                        onHandleCancelBtn={() => onHandleDeleteSocialDetail(socialItem?.id)}
                    />
                </div>
            </div>
            <div className={"w-full sm:w-80"}>
                <FloatingLabelSelect
                    labelItem={`social_social${index}${socialItem?.id}`}
                    options={socialList?.data?.map((socialContent) => ({
                        label: socialContent?.name,
                        value: socialContent?.key,
                        picture_url: socialContent?.picture_url,
                        color: socialContent?.color
                    }))}
                    dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                    searchable={true}
                    onHandleSelect={(selectedOption) => onHandleChangeSocialDetail(socialItem?.id, { ...socialItem, social: selectedOption })}
                    value={socialItem?.social?.label}
                    OptionChild={({ option }) => (
                        <div className={"bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between"}>
                            <div className={"flex items-center gap-1"}>
                                <div className={"w-6 h-6 overflow-hidden rounded-full"}>
                                    <img
                                        src={option?.picture_url}
                                        className={`mr-1 ${option?.color} w-full h-full object-cover`}
                                        alt={option?.value}
                                    />
                                </div>
                                {option?.label}
                            </div>
                            {option?.value === socialItem?.social?.value && <IoIosCheckmark size={20} color="green" />}
                        </div>
                    )}
                    label={"Social"}
                />
            </div>
            <div className="w-full flex items-center gap-3">
                <FloatingLabelInput
                    labelItem={`social_url${index}${socialItem?.id}`}
                    onChange={(e) => onHandleChangeSocialDetail(socialItem?.id, { ...socialItem, link: e.target.value })}
                    value={socialItem?.link}
                    label={"Url"}
                    inputClassName={"disabled:px-2 disabled:bg-text-200 disabled:cursor-not-allowed"}
                />
            </div>
            <div className={"hidden sm:flex mt-2"}>
                <SaveAndCancelBtn
                    isLoading={isLoading}
                    isShowSaveBtn={isShowSaveBtn}
                    isShowCancelBtn={isShowCancelBtn}
                    saveBtnTooltip={'Save Social'}
                    cancelBtnTooltip={"Remove Social"}
                    isBtnDisable={!socialItem?.social || !socialItem?.link}
                    onHandleSaveBtn={() => onHandleSaveSocialDetail(socialItem)}
                    onHandleCancelBtn={() => onHandleDeleteSocialDetail(socialItem?.id)}
                />
            </div>
        </div>
    )
})

export default ContactSocialItem;