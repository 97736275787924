import { toast } from "react-toastify";
import DefaultService from "redux/default/default.service";

import {
    setUserDefaultDetailLoading,
    setUserDefaultDetail,
    setUserDefaultDetailErrorMsg,

    setAddUserDefaultDetailLoading,
    setAddUserDefaultDetailErrorMsg
} from "redux/default/default.slice";

export const getUserDefaultDetailsByUserId = (userId) => async (dispatch) => {
    dispatch(setUserDefaultDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await DefaultService.getUserDefaultDetailsByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserDefaultDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserDefaultDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setUserDefaultDetailLoading(false))
    }
}

export const updateUserDefaultDetailsByUserId = (userId, body) => async (dispatch) => {
    dispatch(setAddUserDefaultDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }

        const response = await DefaultService.updateUserDefaultDetailsByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserDefaultDetail(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserDefaultDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddUserDefaultDetailLoading(false))
    }
}