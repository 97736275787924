import { useCallback, useEffect, useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { MdAdd } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';
import ToolTipView from 'components/tooltipView';
import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';
import Pagination from 'components/pagination/Pagination';

import TableComponent from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTableComponent';
import { courseParticipantsNavigationOptions, courseStudentHeaderConst, courseStudentMenuItemsConst, lmsCourseTooltips } from '../../../editLMSCourse.data';

import { deleteLmsCourseStudentDetail, getLmsCourseStudentList, updateLmsCourseStudentDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetLmsCourseStudentList } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';
import { lmsCourseStudentStatusEnum, lmsCourseJoiningFlowEnum, lmsCourseStudentIsActiveEnum, lmsCourseStatusEnum } from 'redux/edulyteLms/lmsCourse/lmsCourse.const';

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from 'utils/dateTime.utils';
import { cn } from 'utils/cn.utils';

const RECORDS = 10;

const JoinedParticipants = () => {
    const { lmsCourseDetail, lmsCourseStudentList } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    useEffect(() => {
        return () => {
            dispatcher(resetLmsCourseStudentList())
        }
    }, [])

    useEffect(() => {
        if (lmsCourseDetail?.data?.id) {
            const query = {
                page: 1,
                records: RECORDS,
                lms_course_id: lmsCourseDetail?.data?.id
            }
            dispatcher(getLmsCourseStudentList(query))
        }
    }, [lmsCourseDetail?.data?.id])

    const onHandleSelectMenu = useCallback((menuItem, studentItem) => {
        switch (menuItem?.value) {
            case courseStudentMenuItemsConst?.ACTIVE?.value:
                if (courseStudentMenuItemsConst?.ACTIVE?.value === studentItem?.status) return;
                dispatcher(updateLmsCourseStudentDetail(studentItem?.id, { added_by: studentItem?.added_by?.userId, is_active: lmsCourseStudentIsActiveEnum.ACTIVE.value }))
                break;
            case courseStudentMenuItemsConst?.INACTIVE?.value:
                if (courseStudentMenuItemsConst?.INACTIVE?.value === studentItem?.status) return;
                dispatcher(updateLmsCourseStudentDetail(studentItem?.id, { added_by: studentItem?.added_by?.userId, is_active: lmsCourseStudentIsActiveEnum.INACTIVE.value }))
                break;
            case courseStudentMenuItemsConst?.BLOCKED?.value:
                if (courseStudentMenuItemsConst?.BLOCKED?.value === studentItem?.status) return;
                dispatcher(updateLmsCourseStudentDetail(studentItem?.id, { added_by: studentItem?.added_by?.userId, is_active: lmsCourseStudentIsActiveEnum.BLOCKED.value }))
                break;
            case courseStudentMenuItemsConst?.DELETE?.value:
                dispatcher(deleteLmsCourseStudentDetail(studentItem?.id))
        }
    }, [lmsCourseDetail?.data])

    const onHandleChangePage = useCallback((page) => {
        const query = {
            page: page,
            records: RECORDS,
            lms_course_id: lmsCourseDetail?.data?.id
        }
        dispatcher(getLmsCourseStudentList(query))
    }, [lmsCourseDetail?.data])

    const onHandleInviteParticipants = useCallback(() => {
        if (lmsCourseDetail?.data?.course_setting?.status !== lmsCourseStatusEnum?.PUBLISHED?.value) return;

        navigate(`${pagesInfo?.EDIT_LMS_COURSE?.pagePath}/${lmsCourseDetail?.data?.id}/edit/${courseParticipantsNavigationOptions?.INVITED?.to}?action=invite-participants`)
    }, [lmsCourseDetail?.data])


    const StudentImageContainer = memo(({ profilePicUrl }) => {
        return (
            <div className={"flex items-center justify-center"}>
                <div className={"relative w-10 h-10 overflow-hidden rounded-full"}>
                    <img
                        src={profilePicUrl}
                        className={"w-full h-full object-cover"}
                    />
                </div>
            </div>
        )
    })

    const courseStudentRows = useMemo(() => { 
        if (!!lmsCourseStudentList?.data?.results?.length) {
            return lmsCourseStudentList?.data?.results?.map((student) => [
                <StudentImageContainer profilePicUrl={student?.student?.user?.profile_pic_url} />,
                student?.student?.user?.userId || "-",
                student?.student?.user?.first_name + " " + student?.student?.user?.last_name?.charAt(0) + "." || "-",
                lmsCourseJoiningFlowEnum[student?.joining_flow?.toUpperCase()]?.label || "-",
                lmsCourseStudentStatusEnum[student?.status?.toUpperCase()]?.label || "-",
                lmsCourseStudentIsActiveEnum[student?.is_active?.toUpperCase()]?.label || "-",
                dayjs(student?.joined_at)?.tz(timeZone)?.format("ddd, MMM D, YYYY") || "-",
                <div className={"flex items-center justify-center"}>
                    <ThreeDotMenu
                        menuItems={Object.values(courseStudentMenuItemsConst)}
                        onHandleSelect={(menuItem) => onHandleSelectMenu(menuItem, student)}
                    />
                </div>
            ])
        } else return [];
    }, [lmsCourseStudentList?.data?.results])

    return (
        <div className={"px-5 space-y-3"}>
            {lmsCourseStudentList?.isLoading &&
                <ComponentLoader isLoading={lmsCourseStudentList?.isLoading} />
            }
            {(!lmsCourseStudentList?.isLoading && (lmsCourseStudentList?.data?.records > 0)) &&
                <TableComponent
                    headers={Object.values(courseStudentHeaderConst)}
                    subRowHeader={[]}
                    rows={(lmsCourseStudentList?.data?.results?.length > 0) ? courseStudentRows : []}
                    rowHeaderColor={"bg-background-medium"}
                    alternateRowColor={"bg-primary-light"}
                />
            }
            {(lmsCourseStudentList?.data && (lmsCourseStudentList?.data?.totalPages > 1)) &&
                <div className={"flex justify-center items-center"}>
                    <Pagination
                        page={lmsCourseStudentList?.data?.page}
                        totalPages={lmsCourseStudentList?.data?.totalPages}
                        onChangePage={onHandleChangePage}
                    />
                </div>
            }
            {(lmsCourseStudentList?.data && (lmsCourseStudentList?.data?.records === 0)) &&
                <div className={'w-full h-full flex flex-col items-center justify-center gap-1 text-center text-base font-bodyPri tracking-wide'}>
                    <div className={"w-[16rem] h-[16rem] overflow-hidden"}>
                        <img
                            src={"https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/lms-quiz/quiz-student-not-found.jpg"}
                            alt={"no-student-found"}
                            className={"w-full h-full object-cover"}
                        />
                    </div>
                    <ToolTipView
                        content={(lmsCourseDetail?.data?.course_setting?.status === lmsCourseStatusEnum?.PUBLISHED?.value)
                            ? lmsCourseTooltips?.publishedInviteBtnTooltip
                            : lmsCourseTooltips?.disabledInviteBtnTooltip
                        }>
                        <div
                            className={cn(
                                "flex items-center justify-center gap-1 py-1 px-3 rounded-lg",
                                (lmsCourseDetail?.data?.course_setting?.status === lmsCourseStatusEnum?.PUBLISHED?.value)
                                    ? "border border-divider-dark text-divider-dark cursor-pointer hover:bg-divider-dark group"
                                    : "border border-divider-medium bg-text-300 text-text-400 cursor-not-allowed"
                            )}
                            onClick={onHandleInviteParticipants}
                        >
                            <MdAdd className={"text-lg group-hover:text-text-50"} />
                            <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                                {"Invite someone"}
                            </span>
                        </div>
                    </ToolTipView>
                </div>
            }
            {(!lmsCourseDetail?.isLoading && lmsCourseDetail?.message) &&
                <div className={"flex items-center justify-start gap-1"}>
                    <span className={"font-bodyPri font-medium text-red-500 text-base tracking-wide"}>
                        {lmsCourseDetail?.message}
                    </span>
                </div>
            }
        </div>
    )
}

export default JoinedParticipants;