import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import FullPageLoader from 'components/loader/FullPageLoader';
import ScreenLoaderOverlayModal from 'components/modals/screenLoaderOverlayModal/ScreenLoaderOverlayModal';

import QuestionHeader from 'pages/auth/edulyteLms/editLmsPages/editLmsQuestion/components/questionHeader/QuestionHeader';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getLmsQuestionDetail } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetAddLmsQuestionOptionPayload, resetLmsQuestionDetail } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.slice';

import { pagesInfo } from 'utils/pagesInfo';
import { useTitle } from 'hooks/useTitle';

const EditLMSQuestionPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)
    const { lmsQuestionDetail, updateLmsQuestion, updateLmsQuestionOption, updateLmsQuestionOptionResource } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()
    const { questionId } = useParams()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.EDIT_LMS_QUESTION))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (user?.user) {
            setTitle({
                ...title,
                title: `${user?.user?.firstName}, Welcome to Edulyte LMS Questions`
            })
        }
    }, [user?.user, currentPageInfo])

    useEffect(() => {
        if (questionId) {
            dispatcher(getLmsQuestionDetail(questionId))
        }

        return () => {
            dispatcher(resetLmsQuestionDetail())
            dispatcher(resetAddLmsQuestionOptionPayload())
        }
    }, [questionId])

    if (lmsQuestionDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={lmsQuestionDetail?.isLoading} />
        )
    }

    if (lmsQuestionDetail?.message) {
        return (
            <div className='w-screen h-screen flex items-center justify-center'>
                <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                    {lmsQuestionDetail?.message}
                </span>
            </div>
        )
    }

    return lmsQuestionDetail?.data && (
        <>
            <div className={'space-y-3'}>
                <QuestionHeader />
                <div className={'w-full px-3'}>
                    <Outlet />
                </div>
            </div>

            {(updateLmsQuestionOptionResource?.isLoading) &&
                <ScreenLoaderOverlayModal openModal={(updateLmsQuestionOptionResource?.isLoading)} />
            }
        </>
    )
};

export default EditLMSQuestionPage;