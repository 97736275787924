import { createSlice } from "@reduxjs/toolkit";

import { TUTOR_INITIAL_STATE } from "redux/tutor/tutor.initialState";

const tutor = createSlice({
    name: "tutor",
    initialState: TUTOR_INITIAL_STATE,
    reducers: {
        // reducers for tutor list
        setTutorListLoading: (state, { payload }) => {
            state.tutorList.isLoading = payload
        },
        setTutorList: (state, { payload }) => {
            state.tutorList.tutorList = payload
            state.tutorList.filteredTutorList = payload
            state.tutorList.errorMsg = TUTOR_INITIAL_STATE.tutorList.errorMsg
        },
        setTutorListErrorMsg: (state, { payload }) => {
            state.tutorList.errorMsg = payload
            state.tutorList.tutorList = TUTOR_INITIAL_STATE.tutorList.tutorList
            state.tutorList.filteredTutorList = TUTOR_INITIAL_STATE.tutorList.filteredTutorList
        },
        setClearTutorList: (state) => {
            state.tutorList = TUTOR_INITIAL_STATE.tutorList
        },

        // reducers for tutor public profile
        setTutorPublicProfileLoading: (state, { payload }) => {
            state.tutorPublicProfile.isLoading = payload
        },
        setTutorPublicProfile: (state, { payload }) => {
            state.tutorPublicProfile.tutorPublicProfile = payload
            state.tutorPublicProfile.errorMsg = TUTOR_INITIAL_STATE.tutorPublicProfile.errorMsg
        },
        setTutorPublicProfileErrorMsg: (state, { payload }) => {
            state.tutorPublicProfile.errorMsg = payload
            state.tutorPublicProfile.tutorPublicProfile = TUTOR_INITIAL_STATE.tutorPublicProfile.tutorPublicProfile
        },
        setClearTutorPublicProfile: (state) => {
            state.tutorPublicProfile = TUTOR_INITIAL_STATE.tutorPublicProfile
        },

        // reducers for my tutor list
        setMyTutorListLoading: (state, { payload }) => {
            state.myTutorList.isLoading = payload
        },
        setMyTutorList: (state, { payload }) => {
            state.myTutorList.myTutorList = payload
            state.myTutorList.filteredMyTutorList = payload
            state.myTutorList.errorMsg = TUTOR_INITIAL_STATE.myTutorList.errorMsg
        },
        setMyTutorListErrorMsg: (state, { payload }) => {
            state.myTutorList.errorMsg = payload
            state.myTutorList.myTutorList = TUTOR_INITIAL_STATE.myTutorList.myTutorList
            state.myTutorList.filteredMyTutorList = TUTOR_INITIAL_STATE.myTutorList.filteredMyTutorList
        },
        setClearMyTutorList: (state) => {
            state.myTutorList = TUTOR_INITIAL_STATE.myTutorList
        },

        // reducers for fav tutor list
        setFavTutorListLoading: (state, { payload }) => {
            state.favTutorList.isLoading = payload
        },
        setFavTutorList: (state, { payload }) => {
            state.favTutorList.favTutorList = payload
            state.favTutorList.filteredFavTutorList = payload
            state.favTutorList.errorMsg = TUTOR_INITIAL_STATE.favTutorList.errorMsg
        },
        setFavTutorListErrorMsg: (state, { payload }) => {
            state.favTutorList.errorMsg = payload
            state.favTutorList.favTutorList = TUTOR_INITIAL_STATE.favTutorList.favTutorList
            state.favTutorList.filteredFavTutorList = TUTOR_INITIAL_STATE.favTutorList.filteredFavTutorList
        },
        setClearFavTutorList: (state) => {
            state.favTutorList = TUTOR_INITIAL_STATE.favTutorList
        },

        // reducers for add fav tutors
        setAddFavTutorLoading: (state, { payload }) => {
            state.addFavTutor.isLoading = payload
        },
        setAddFavTutorErrorMsg: (state, { payload }) => {
            state.addFavTutor.errorMsg = payload
            state.addFavTutor.successMsg = TUTOR_INITIAL_STATE.addFavTutor.addFavTutor
        },
        setClearAddFavTutor: (state) => {
            state.addFavTutor = TUTOR_INITIAL_STATE.addFavTutor
        },

        // reducers for tutor user detail
        setTutorUserDetailLoading: (state, { payload }) => {
            state.tutorUserDetail.isLoading = payload
        },
        setTutorUserDetail: (state, { payload }) => {
            state.tutorUserDetail.tutorUserDetail = payload
            state.tutorUserDetail.errorMsg = TUTOR_INITIAL_STATE.tutorUserDetail.errorMsg
        },
        setTutorUserDetailErrorMsg: (state, { payload }) => {
            state.tutorUserDetail.errorMsg = payload
            state.tutorUserDetail.tutorUserDetail = TUTOR_INITIAL_STATE.tutorUserDetail.tutorUserDetail
        },
        setClearTutorUserDetail: (state) => {
            state.tutorUserDetail = TUTOR_INITIAL_STATE.tutorUserDetail
        },

        // reducers for update tutor user detail
        setAddTutorUserDetailLoading: (state, { payload }) => {
            state.addTutorUserDetail.isLoading = payload
        },
        setAddTutorUserDetail: (state, { payload }) => {
            state.addTutorUserDetail.addTutorUserDetail = payload
            state.addTutorUserDetail.errorMsg = TUTOR_INITIAL_STATE.addTutorUserDetail.errorMsg
        },
        setAddTutorUserDetailErrorMsg: (state, { payload }) => {
            state.addTutorUserDetail.errorMsg = payload
        },
        setClearAddTutorUserDetail: (state) => {
            state.addTutorUserDetail = TUTOR_INITIAL_STATE.addTutorUserDetail
        },

        // reducers for add tutor user detail payload
        setAddTutorUserDetailPayload: (state, { payload }) => {
            state.addTutorUserDetail.payload = payload
        },
        resetAddTutorUserDetailPayload: (state) => {
            state.addTutorUserDetail.payload = TUTOR_INITIAL_STATE.addTutorUserDetail.payload
        },

        // reducers for upload document type
        setProfilePageDocumentPayload: (state, { payload }) => {
            state.profilePageDocumentUpload.profilePageDocumentUploadPayload = payload
        },
        setClearProfilePageDocumentPayload: (state) => {
            state.profilePageDocumentUpload.profilePageDocumentUploadPayload = TUTOR_INITIAL_STATE.profilePageDocumentUpload.profilePageDocumentUploadPayload
        },

        // reducers for tutor user price
        setTutorUserPriceLoading: (state, { payload }) => {
            state.tutorUserPrice.isLoading = payload
        },
        setTutorUserPrice: (state, { payload }) => {
            state.tutorUserPrice.tutorUserPrice = payload
            state.tutorUserPrice.errorMsg = TUTOR_INITIAL_STATE.tutorUserPrice.errorMsg
        },
        setTutorUserPriceErrorMsg: (state, { payload }) => {
            state.tutorUserPrice.errorMsg = payload
            state.tutorUserPrice.tutorUserPrice = TUTOR_INITIAL_STATE.tutorUserPrice.tutorUserPrice
        },
        setClearTutorUserPrice: (state) => {
            state.tutorUserPrice = TUTOR_INITIAL_STATE.tutorUserPrice
        },

        // reducers for update tutor user price
        setAddTutorUserPriceLoading: (state, { payload }) => {
            state.addTutorUserPrice.isLoading = payload
        },
        setAddTutorUserPriceErrorMsg: (state, { payload }) => {
            state.addTutorUserPrice.errorMsg = payload
        },
        setClearAddTutorUserPrice: (state) => {
            state.addTutorUserPrice = TUTOR_INITIAL_STATE.addTutorUserPrice
        },

        // reducers for tutor user lesson
        setTutorUserLessonLoading: (state, { payload }) => {
            state.tutorUserLesson.isLoading = payload
        },
        setTutorUserLesson: (state, { payload }) => {
            state.tutorUserLesson.tutorUserLesson = payload
            state.tutorUserLesson.errorMsg = TUTOR_INITIAL_STATE.tutorUserLesson.errorMsg
        },
        setTutorUserLessonErrorMsg: (state, { payload }) => {
            state.tutorUserLesson.errorMsg = payload
            state.tutorUserLesson.tutorUserLesson = TUTOR_INITIAL_STATE.tutorUserLesson.tutorUserLesson
        },
        setClearTutorUserLesson: (state) => {
            state.tutorUserLesson = TUTOR_INITIAL_STATE.tutorUserLesson
        },

        // reducers for update tutor user lesson
        setAddTutorUserLessonLoading: (state, { payload }) => {
            state.addTutorUserLesson.isLoading = payload
        },
        setAddTutorUserLessonErrorMsg: (state, { payload }) => {
            state.addTutorUserLesson.errorMsg = payload
        },
        setClearAddTutorUserLesson: (state) => {
            state.addTutorUserLesson = TUTOR_INITIAL_STATE.addTutorUserLesson
        },

        // reducers for tutor user language
        setTutorUserLanguageListLoading: (state, { payload }) => {
            state.tutorUserLanguageList.isLoading = payload
        },
        setTutorUserLanguageList: (state, { payload }) => {
            state.tutorUserLanguageList.tutorUserLanguageList = payload
            state.tutorUserLanguageList.filteredTutorUserLanguageList = payload
            state.tutorUserLanguageList.errorMsg = TUTOR_INITIAL_STATE.tutorUserLanguageList.errorMsg
        },
        setTutorUserLanguageListErrorMsg: (state, { payload }) => {
            state.tutorUserLanguageList.errorMsg = payload
            state.tutorUserLanguageList.tutorUserLanguageList = TUTOR_INITIAL_STATE.tutorUserLanguageList.tutorUserLanguageList
        },
        setClearTutorUserLanguageList: (state) => {
            state.tutorUserLanguageList = TUTOR_INITIAL_STATE.tutorUserLanguageList
        },

        // reducers for update tutor user language
        setAddTutorLanguageDetailLoading: (state, { payload }) => {
            state.addTutorLanguageDetail.isLoading = payload
        },
        setAddTutorLanguageDetailErrorMsg: (state, { payload }) => {
            state.addTutorLanguageDetail.errorMsg = payload
        },
        setClearAddTutorLanguageDetail: (state) => {
            state.addTutorLanguageDetail = TUTOR_INITIAL_STATE.addTutorLanguageDetail
        },

        // reducers for tutor category list
        setTutorUserCategoryListLoading: (state, { payload }) => {
            state.tutorUserCategoryList.isLoading = payload
        },
        setTutorUserCategoryList: (state, { payload }) => {
            state.tutorUserCategoryList.tutorUserCategoryList = payload
            state.tutorUserCategoryList.filteredTutorUserCategoryList = payload
            state.tutorUserCategoryList.errorMsg = TUTOR_INITIAL_STATE.tutorUserCategoryList.errorMsg
        },
        setTutorUserCategoryListErrorMsg: (state, { payload }) => {
            state.tutorUserCategoryList.errorMsg = payload
            state.tutorUserCategoryList.tutorUserCategoryList = TUTOR_INITIAL_STATE.tutorUserCategoryList.tutorUserCategoryList
            state.tutorUserCategoryList.filteredTutorUserCategoryList = TUTOR_INITIAL_STATE.tutorUserCategoryList.filteredTutorUserCategoryList
        },
        setClearTutorUserCategoryList: (state) => {
            state.tutorUserCategoryList = TUTOR_INITIAL_STATE.tutorUserCategoryList
        },

        // reducers for update tutor category list
        setAddTutorCategoryDetailLoading: (state, { payload }) => {
            state.addTutorCategoryDetail.isLoading = payload
        },
        setAddTutorCategoryDetailErrorMsg: (state, { payload }) => {
            state.addTutorCategoryDetail.errorMsg = payload
        },
        setClearAddTutorCategoryDetail: (state) => {
            state.addTutorCategoryDetail = TUTOR_INITIAL_STATE.addTutorCategoryDetail
        },


        // reducers for new modified tutor user detail apis


        // new tutor language apis
        setTutorLanguageListLoading: (state, { payload }) => {
            state.tutorLanguageList.isLoading = payload
        },
        setTutorLanguageListData: (state, { payload }) => {
            state.tutorLanguageList.data = payload
            state.tutorLanguageList.message = TUTOR_INITIAL_STATE.message
        },
        setTutorLanguageListMessage: (state, { payload }) => {
            state.tutorLanguageList.message = payload
        },
        resetTutorLanguageList: (state) => {
            state.tutorLanguageList = TUTOR_INITIAL_STATE.tutorLanguageList
        },

        // add tutor language detail
        // setAddTutorLanguageDetailLoading: (state, { payload }) => {
        //     state.addTutorLanguageDetail.isLoading = payload
        // },
        setAddTutorLanguageDetailMessage: (state, { payload }) => {
            state.addTutorLanguageDetail.message = payload
        },
        resetAddTutorLanguageDetail: (state) => {
            state.addTutorLanguageDetail = TUTOR_INITIAL_STATE.addTutorLanguageDetail
        },

        // update tutor category detail
        setUpdateTutorLanguageDetailLoading: (state, { payload }) => {
            state.updateTutorLanguage.isLoading = payload
        },
        setUpdateTutorLanguageDetailMessage: (state, { payload }) => {
            state.updateTutorLanguage.message = payload
        },
        resetUpdateTutorLanguageDetail: (state) => {
            state.updateTutorLanguage = TUTOR_INITIAL_STATE.updateTutorLanguage
        },

        // update tutor category list order
        setUpdateTutorLanguageListOrderLoading: (state, { payload }) => {
            state.updateTutorLanguageOrder.isLoading = payload
        },
        setUpdateTutorLanguageListOrderMessage: (state, { payload }) => {
            state.updateTutorLanguageOrder.message = payload
        },
        resetUpdateTutorLanguageListOrder: (state) => {
            state.updateTutorLanguageOrder = TUTOR_INITIAL_STATE.updateTutorLanguageOrder
        },

        // delete tutor category detail
        setDeleteTutorLanguageDetailLoading: (state, { payload }) => {
            state.deleteTutorLanguage.isLoading = payload
        },
        setDeleteTutorLanguageDetailMessage: (state, { payload }) => {
            state.deleteTutorLanguage.message = payload
        },
        resetDeleteTutorLanguageDetail: (state) => {
            state.deleteTutorLanguage = TUTOR_INITIAL_STATE.deleteTutorLanguage
        },

        // reducers for new tutor tag apis
        setTutorTagListLoading: (state, { payload }) => {
            state.tutorTagList.isLoading = payload
        },
        setTutorTagListData: (state, { payload }) => {
            state.tutorTagList.data = payload
            state.tutorTagList.message = TUTOR_INITIAL_STATE.tutorTagList.message
        },
        setTutorTagListMessage: (state, { payload }) => {
            state.tutorTagList.message = payload
            state.tutorTagList.data = TUTOR_INITIAL_STATE.tutorTagList.data
        },
        resetTutorTagList: (state) => {
            state.tutorTagList = TUTOR_INITIAL_STATE.tutorTagList
        },

        // reducers for add tutor tag
        setAddTutorTagDetailLoading: (state, { payload }) => {
            state.addTutorTagDetail.isLoading = payload
        },
        setAddTutorTagDetailMessage: (state, { payload }) => {
            state.addTutorTagDetail.message = payload
        },
        resetAddTutorTagDetail: (state) => {
            state.addTutorTagDetail = TUTOR_INITIAL_STATE.addTutorTagDetail
        },

        // reducers for update tutor tag
        setUpdateTutorTagListOrderLoading: (state, { payload }) => {
            state.updateTutorTagOrder.isLoading = payload
        },
        setUpdateTutorTagListOrderMessage: (state, { payload }) => {
            state.updateTutorTagOrder.message = payload
        },
        resetUpdateTutorTagListOrder: (state) => {
            state.updateTutorTagOrder = TUTOR_INITIAL_STATE.updateTutorTagOrder
        },

        // reducers for delete tutor tag
        setDeleteTutorTagDetailLoading: (state, { payload }) => {
            state.deleteTutorTag.isLoading = payload
        },
        setDeleteTutorTagDetailMessage: (state, { payload }) => {
            state.deleteTutorTag.message = payload
        },
        resetDeleteTutorTagDetail: (state) => {
            state.deleteTutorTag = TUTOR_INITIAL_STATE.deleteTutorTag
        },

        // new tutor category apis
        setTutorCategoryListLoading: (state, { payload }) => {
            state.tutorCategoryList.isLoading = payload
        },
        setTutorCategoryListData: (state, { payload }) => {
            state.tutorCategoryList.data = payload
            state.tutorCategoryList.message = TUTOR_INITIAL_STATE.message
        },
        setTutorCategoryListMessage: (state, { payload }) => {
            state.tutorCategoryList.message = payload
        },
        resetTutorCategoryList: (state) => {
            state.tutorCategoryList = TUTOR_INITIAL_STATE.tutorCategoryList
        },

        // add tutor category detail
        // setAddTutorCategoryDetailLoading: (state, {payload}) => {
        //     state.addTutorCategoryDetail.isLoading = payload
        // },
        setAddTutorCategoryDetailMessage: (state, { payload }) => {
            state.addTutorCategoryDetail.message = payload
        },
        resetAddTutorCategoryDetail: (state) => {
            state.addTutorCategoryDetail = TUTOR_INITIAL_STATE.addTutorCategoryDetail
        },

        // update tutor category detail
        setUpdateTutorCategoryDetailLoading: (state, { payload }) => {
            state.updateTutorCategory.isLoading = payload
        },
        setUpdateTutorCategoryDetailMessage: (state, { payload }) => {
            state.updateTutorCategory.message = payload
        },
        resetUpdateTutorCategoryDetail: (state) => {
            state.updateTutorCategory = TUTOR_INITIAL_STATE.updateTutorCategory
        },

        // update tutor category list order
        setUpdateTutorCategoryListOrderLoading: (state, { payload }) => {
            state.updateTutorCategoryOrder.isLoading = payload
        },
        setUpdateTutorCategoryListOrderMessage: (state, { payload }) => {
            state.updateTutorCategoryOrder.message = payload
        },
        resetUpdateTutorCategoryListOrder: (state) => {
            state.updateTutorCategoryOrder = TUTOR_INITIAL_STATE.updateTutorCategoryOrder
        },

        // delete tutor category detail
        setDeleteTutorCategoryDetailLoading: (state, { payload }) => {
            state.deleteTutorCategory.isLoading = payload
        },
        setDeleteTutorCategoryDetailMessage: (state, { payload }) => {
            state.deleteTutorCategory.message = payload
        },
        resetDeleteTutorCategoryDetail: (state) => {
            state.deleteTutorCategory = TUTOR_INITIAL_STATE.deleteTutorCategory
        },

        // new tutor onboarding tutor apis
        setTutorDetailLoading: (state, { payload }) => {
            state.tutorDetail.isLoading = payload
        },
        setTutorDetailData: (state, { payload }) => {
            state.tutorDetail.data = payload
            state.tutorDetail.message = TUTOR_INITIAL_STATE.tutorDetail.message
        },
        setTutorDetailMessage: (state, { payload }) => {
            state.tutorDetail.message = payload
            state.tutorDetail.data = TUTOR_INITIAL_STATE.tutorDetail.data
        },
        resetTutorDetail: (state) => {
            state.tutorDetail = TUTOR_INITIAL_STATE.tutorDetail
        },

        // reducers for add tutor detail
        setAddTutorDetailLoading: (state, { payload }) => {
            state.addTutorDetail.isLoading = payload
        },
        setAddTutorDetailData: (state, { payload }) => {
            state.addTutorDetail.data = payload
            state.addTutorDetail.message = TUTOR_INITIAL_STATE.addTutorDetail.message
        },
        setAddTutorDetailMessage: (state, { payload }) => {
            state.addTutorDetail.message = payload
        },
        resetAddTutorDetail: (state) => {
            state.addTutorDetail = TUTOR_INITIAL_STATE.addTutorDetail
        },


        // reducers for update tutor detail
        setUpdateTutorDetailLoading: (state, { payload }) => {
            state.updateTutorDetail.isLoading = payload
        },
        setUpdateTutorDetailMessage: (state, { payload }) => {
            state.updateTutorDetail.message = payload
        },
        resetUpdateTutorDetail: (state) => {
            state.updateTutorDetail = TUTOR_INITIAL_STATE.updateTutorDetail
        },


        // reducers for filter props
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
        },
        setClearFilterProps: (state) => {
            state.filterProps = TUTOR_INITIAL_STATE.filterProps
        }
    }
});

export const {
    setTutorListLoading,
    setTutorList,
    setTutorListErrorMsg,
    setClearTutorList,

    setTutorPublicProfileLoading,
    setTutorPublicProfile,
    setTutorPublicProfileErrorMsg,
    setClearTutorPublicProfile,

    setMyTutorListLoading,
    setMyTutorList,
    setMyTutorListErrorMsg,
    setClearMyTutorList,

    setFavTutorListLoading,
    setFavTutorList,
    setFavTutorListErrorMsg,
    setClearFavTutorList,

    setAddFavTutorLoading,
    setAddFavTutorErrorMsg,
    setClearAddFavTutor,

    setTutorUserDetailLoading,
    setTutorUserDetail,
    setTutorUserDetailErrorMsg,
    setClearTutorUserDetail,

    setAddTutorUserDetailLoading,
    setAddTutorUserDetail,
    setAddTutorUserDetailErrorMsg,
    setClearAddTutorUserDetail,

    setAddTutorUserDetailPayload,
    resetAddTutorUserDetailPayload,

    setProfilePageDocumentPayload,
    setClearProfilePageDocumentPayload,

    setTutorUserPriceLoading,
    setTutorUserPrice,
    setTutorUserPriceErrorMsg,
    setClearTutorUserPrice,

    setAddTutorUserPriceLoading,
    setAddTutorUserPriceErrorMsg,
    setClearAddTutorUserPrice,

    setTutorUserLessonLoading,
    setTutorUserLesson,
    setTutorUserLessonErrorMsg,
    setClearTutorUserLesson,

    setAddTutorUserLessonLoading,
    setAddTutorUserLessonErrorMsg,
    setClearAddTutorUserLesson,

    setTutorUserLanguageListLoading,
    setTutorUserLanguageList,
    setTutorUserLanguageListErrorMsg,
    setClearTutorUserLanguageList,

    setAddTutorLanguageDetailLoading,
    setAddTutorLanguageDetailErrorMsg,
    setClearAddTutorLanguageDetail,

    setTutorUserCategoryListLoading,
    setTutorUserCategoryList,
    setTutorUserCategoryListErrorMsg,
    setClearTutorUserCategoryList,

    setAddTutorCategoryDetailLoading,
    setAddTutorCategoryDetailErrorMsg,
    setClearAddTutorCategoryDetail,

    // new modified apis
    setTutorLanguageListLoading,
    setTutorLanguageListData,
    setTutorLanguageListMessage,
    resetTutorLanguageList,

    // setAddTutorLanguageDetailLoading,
    setAddTutorLanguageDetailMessage,
    resetAddTutorLanguageDetail,

    setUpdateTutorLanguageDetailLoading,
    setUpdateTutorLanguageDetailMessage,
    resetUpdateTutorLanguageDetail,

    setUpdateTutorLanguageListOrderLoading,
    setUpdateTutorLanguageListOrderMessage,
    resetUpdateTutorLanguageListOrder,

    setDeleteTutorLanguageDetailLoading,
    setDeleteTutorLanguageDetailMessage,
    resetDeleteTutorLanguageDetail,


    setTutorTagListLoading,
    setTutorTagListData,
    setTutorTagListMessage,
    resetTutorTagList,

    setAddTutorTagDetailLoading,
    setAddTutorTagDetailMessage,
    resetAddTutorTagDetail,

    setUpdateTutorTagListOrderLoading,
    setUpdateTutorTagListOrderMessage,
    resetUpdateTutorTagListOrder,

    setDeleteTutorTagDetailLoading,
    setDeleteTutorTagDetailMessage,
    resetDeleteTutorTagDetail,

    setTutorCategoryListLoading,
    setTutorCategoryListData,
    setTutorCategoryListMessage,
    resetTutorCategoryList,

    // setAddTutorCategoryDetailLoading (already created above),
    setAddTutorCategoryDetailMessage,
    resetAddTutorCategoryDetail,

    setUpdateTutorCategoryDetailLoading,
    setUpdateTutorCategoryDetailMessage,
    resetUpdateTutorCategoryDetail,

    setUpdateTutorCategoryListOrderLoading,
    setUpdateTutorCategoryListOrderMessage,
    resetUpdateTutorCategoryListOrder,

    setDeleteTutorCategoryDetailLoading,
    setDeleteTutorCategoryDetailMessage,
    resetDeleteTutorCategoryDetail,

    // new onboarding tutor apis
    setTutorDetailLoading,
    setTutorDetailData,
    setTutorDetailMessage,
    resetTutorDetail,

    setAddTutorDetailLoading,
    setAddTutorDetailData,
    setAddTutorDetailMessage,
    resetAddTutorDetail,

    setUpdateTutorDetailLoading,
    setUpdateTutorDetailMessage,
    resetUpdateTutorDetail,

    setFilterProps,
    setClearFilterProps
} = tutor.actions

export default tutor.reducer;