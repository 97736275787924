import { useCallback, useEffect, useState } from 'react';
import { cn } from "utils/cn.utils";
import { Link } from 'react-router-dom';

import { MdDelete } from 'react-icons/md';
import { MdEdit } from 'react-icons/md';
import { FaPlus } from "react-icons/fa";

import QuillEditor from "components/textEditor/QuillEditor";
import FadedCollapse from 'components/fadedCollapse';
import ToolTipView from 'components/tooltipView';

import { deleteLmsCourseStudentNote, updateLmsCourseStudentNote } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';
import { resetModifyLmsCourseStudentNoteDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';


const max_content_to_show_more = 550

const Editor = {
    modules: {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'size': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline'],
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'color': [] }, { 'background': [] }]
        ],
        clipboard: {
            matchVisual: false,
        }
    },
    formats: [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'blockquote', 'code-block',
        'list', 'bullet', 'indent',
        'color', 'background',
    ]
}

let timer;
const debounce = (fn, delay = 1000) => {
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            fn(...args)
        }, delay)
    }
}

const NoteItemView = ({ student_note, lms_course, editNote, setEditNote, isAddNoteVisible, getLesson }) => {

    const { addLmsCourseStudentNoteDetail, modifyLmsCourseStudentNoteDetail, destroyLmsCourseStudentNoteDetail } = useAppState(state => state.lms.lmsCourse)

    const [noteInput, setNoteInput] = useState(student_note?.note || "")
    const [inputStatus, setInputStatus] = useState("Saved")

    const dispatcher = useAppDispatcher()

    const is_loading = addLmsCourseStudentNoteDetail?.isLoading || modifyLmsCourseStudentNoteDetail?.isLoading || destroyLmsCourseStudentNoteDetail?.isLoading
    const error_message = addLmsCourseStudentNoteDetail?.message || modifyLmsCourseStudentNoteDetail?.message || destroyLmsCourseStudentNoteDetail?.message
    const content = new DOMParser()?.parseFromString(`<div>${student_note?.note}</div>`, "text/html")?.querySelector(`div`)?.textContent

    useEffect(() => {
        if (modifyLmsCourseStudentNoteDetail?.data) {
            setInputStatus("Saved")
            dispatcher(resetModifyLmsCourseStudentNoteDetail())
        }
    }, [modifyLmsCourseStudentNoteDetail?.data])

    const onHandleSave = useCallback((value) => {

        setInputStatus("Saving...")
        let requestBody = {
            note: value?.trim()
        }

        dispatcher(updateLmsCourseStudentNote(student_note?.id, requestBody))
    }, [student_note?.id])

    const onHandleChangeNote = (value) => {
        setInputStatus("Typing...")
        setNoteInput(value)

        const delayFunction = debounce(onHandleSave)
        delayFunction(value)
    }

    const onHandleDeleteNote = () => {
        if (is_loading || isAddNoteVisible) return;

        dispatcher(deleteLmsCourseStudentNote(student_note?.id))
        setEditNote(null)
    }

    return (
        <div className={cn(
            "group border border-text-300 hover:border-text-500",
            !!editNote && "border-text-500"
        )}>
            <div className={cn(
                "px-5 py-2 flex justify-between items-center border-b border-text-300 bg-background-light",
                "group-hover:border-text-500 group-hover:bg-background-darkLight",
                !!editNote && "border-text-500"
            )}>
                <div className={"flex-1 space-y-1"}>
                    <p className={"font-bodyPri font-normal text-sm text-text-800 line-clamp-1"}>
                        {student_note?.lms_course_section_lesson?.lms_course_section?.title}
                    </p>
                    <Link to={`${pagesInfo.STUDENT_LMS_COURSE.pagePath}/${lms_course?.active_slug}/learn/lesson/${student_note?.lms_course_section_lesson?.id}`}
                        className={"w-fit font-bodyPri font-medium text-md text-text-800 group-hover:text-secondary-dark group-hover:underline line-clamp-1"}
                    >
                        {getLesson(student_note?.lms_course_section_lesson)?.title}
                    </Link>
                </div>
                <div className={"flex items-center gap-3"}>
                    {(!isAddNoteVisible && !editNote) && (
                        <ToolTipView content={"Edit"}>
                            <button
                                className={"group p-2 text-text-500 hover:text-primary-dark hover:bg-background-light rounded-full transition-all ease-in-out duration-100 delay-75"}
                                onClick={() => setEditNote(student_note)}
                                disabled={isAddNoteVisible || is_loading}
                            >
                                <MdEdit className={"cursor-pointer text-xl"} />
                            </button>
                        </ToolTipView>
                    )}
                    {(editNote?.id === student_note?.id) && (
                        <ToolTipView content={"Cancel"}>
                            <button
                                className={"group p-2 text-text-500 hover:text-primary-dark hover:bg-background-light rounded-full transition-all ease-in-out duration-100 delay-75"}
                                onClick={() => setEditNote(null)}
                                disabled={isAddNoteVisible || is_loading}
                            >
                                <FaPlus className={"cursor-pointer text-xl rotate-45"} />
                            </button>
                        </ToolTipView>
                    )}
                    {!isAddNoteVisible && (
                        <ToolTipView content={"Delete"}>
                            <button
                                className={"group p-2 text-text-500 hover:text-secondary-dark hover:bg-background-light rounded-full transition-all ease-in-out duration-100 delay-75"}
                                onClick={() => onHandleDeleteNote()}
                                disabled={isAddNoteVisible || is_loading}
                            >
                                <MdDelete className={"cursor-pointer text-xl"} />
                            </button>
                        </ToolTipView>
                    )}
                </div>
            </div>
            {(editNote?.id !== student_note?.id) && (
                <FadedCollapse
                    isShowMore={content?.length > max_content_to_show_more ? true : false}
                    isFaded={content?.length > max_content_to_show_more ? true : false}
                    wrapperStyle={"px-5 py-3"}
                    btnStyle={""}
                    maxHeight={content?.length > max_content_to_show_more ? "7rem" : "fit-content"}
                >
                    <div dangerouslySetInnerHTML={{ __html: student_note?.note }}></div>
                </FadedCollapse>
            )}
            {(editNote?.id === student_note?.id) && (
                <>
                    <QuillEditor
                        editorToolModules={Editor?.modules}
                        editorToolFormats={Editor?.formats}
                        className={cn("border-none")}
                        editorText={noteInput}
                        onHandleChangeText={onHandleChangeNote}
                    />
                    <div className={"px-5 flex flex-row items-center justify-end"}>
                        <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                            {inputStatus}
                        </span>
                    </div>
                </>
            )}
        </div>
    )
}

export default NoteItemView