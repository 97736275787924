import { useRef } from "react";

import { AnimatePresence, motion } from "framer-motion";

import { AiOutlinePlusCircle } from "react-icons/ai";

import { cn } from "utils/cn.utils";

function FilterBox({
    isActive = false,
    boxIcon = <AiOutlinePlusCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />,
    boxTitle,
    customBoxClassName = "",
    activeContainerClassName = "-translate-x-10 sm:translate-x-0",
    boxItems = [],
    BoxItemContent = () => { },
    onHandleMouseLeave,
    onHandleToggleBox,
    children
}) {

    const DefaultBoxItemContent = () => {
        return !!boxItems?.length && (
            <>
                <div className={"h-[20px] border border-divider-lightDark"} />
                <span className={"font-bodyPri font-normal text-sm text-primary-dark line-clamp-1 capitalize"}>
                    {boxItems?.slice(0, 1)?.join(", ")}
                </span>
            </>
        )
    }

    return (
        <div className={"relative"} onMouseLeave={onHandleMouseLeave}>
            <div
                className={cn(
                    "max-w-60 flex items-center gap-2 border border-dashed border-text-500 rounded-xl hover:bg-primary-light",
                    "px-3 py-0.5 cursor-pointer shadow-sm whitespace-nowrap",
                    isActive && "bg-primary-light",
                    !!boxItems?.length && "!bg-text-200 !border-solid",
                    customBoxClassName
                )}
                onClick={onHandleToggleBox}
            >
                {boxIcon}
                <span className={"font-bodyPri font-medium text-text-800 text-base tracking-wide"}>
                    {boxTitle}
                </span>
                {BoxItemContent() || DefaultBoxItemContent()}
            </div>
            <AnimatePresence initial={isActive}>
                {isActive && (
                    <div className={cn("absolute group z-[500]", activeContainerClassName)}>
                        <div className={cn(
                            "bg-white/95 rounded-lg border shadow mt-3 overflow-hidden overflow-y-auto max-h-[25rem]",
                            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                            "group-hover:scrollbar-thumb-divider-lightDark"
                        )}>
                            <motion.div
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ height: "auto", opacity: 1 }}
                                exit={{ height: 0, opacity: 0 }}
                                className={"w-full flex items-center justify-center"}
                            >
                                {children}
                            </motion.div>
                        </div>
                    </div>
                )}
            </AnimatePresence>
        </div>
    );
}

export default FilterBox;