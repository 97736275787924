import { AiOutlineQuestionCircle } from "react-icons/ai"
import { BiCustomize } from "react-icons/bi"
import { FiPhoneCall } from "react-icons/fi"
import { IoVideocamOutline } from "react-icons/io5"
import { MdOutlineLocationOn } from "react-icons/md"
import { SiGooglemeet } from "react-icons/si"

export const url = {
    GET_PUBLIC_APPOINTMENT_LIST: "appointments/appointments/public",
    GET_PUBLIC_USER_APPOINTMENT_LIST: "appointments/appointments/users/{user_id}/public",
    GET_PUBLIC_APPOINTMENT_DETAIL: "appointments/appointments/{id}/public",

    GET_USER_APPOINTMENT_LIST: "appointments/appointments/users/{userId}",
    GET_USER_APPOINTMENT_DETAIL: "appointments/appointments/{appointmentId}/users/{userId}",
    ADD_APPOINTMENT_DETAIL: "appointments/appointments",
    UPDATE_APPOINTMENT_DETAIL: "appointments/appointments/{appointmentId}",
    DELETE_APPOINTMENT_DETAIL: "appointments/appointments/{appointmentId}",

    // location
    GET_APPOINTMENT_LOCATION_LIST: "appointments/appointments/{appointmentId}/locations",
    CREATE_APPOINTMENT_LOCATION_DETAIL: "appointments/appointments/{appointmentId}/locations",
    UPDATE_APPOINTMENT_LOCATION_DETAIL: "appointments/appointments/{appointmentId}/locations/{locationId}",
    DELETE_APPOINTMENT_LOCATION_DETAIL: "appointments/appointments/{appointmentId}/locations/{locationId}"
}

export const appointmentStatusEnum = {
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    }
}

export const appointmentVisibilityEnum = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    }
}

export const appointmentPriceModelEnum = {
    FREE: {
        value: "free",
        label: "Free"
    },
    FLAT_FEE: {
        value: "flat_fee",
        label: "Flat Fee"
    }
}

export const locationLabelsEnum = {
    home: {
        key: "home",
        label: "Home",
    },
    work: {
        key: "work",
        label: "Work",
    },
    custom: {
        key: "custom",
        label: "Custom",
    },
}

export const locationTypeEnum = {
    custom: {
        key: "custom",
        label: "Custom",
        icon: BiCustomize,
    },
    in_person_meeting: {
        key: "in_person_meeting",
        label: "In Person Meeting",
        icon: MdOutlineLocationOn,
    },
    phone_call: {
        key: "phone_call",
        label: "Phone Call",
        icon: FiPhoneCall,
    },
    invitee: {
        key: "invitee",
        label: "Ask Invitee",
        icon: AiOutlineQuestionCircle,
    },
    google_meet: {
        key: "google_meet",
        label: "Google Meet",
        icon: SiGooglemeet,
    },
    edulyte_meet: {
        key: "edulyte_meet",
        label: "Edulyte Meet",
        icon: IoVideocamOutline,
    },
}

export const locationPhoneCallTypeEnum = {
    i_will_call: {
        label: "",
        key: "i_will_call",
        title: "I will call my invitee",
        subtitle: "Edulyte will require your invitee’s phone number before scheduling."
    },
    call_me: {
        label: "",
        key: "call_me",
        title: "My invitee should call me",
        subtitle: "Edulyte will provide your number after the call has been scheduled."
    }
}

export const modifyAppointmentDetailPayload = {
    id: null,
    slug: "",
    title: "",
    subtitle: "",
    duration: 30,
    price: 0,
    price_model: "",
    discount_pct: 0,
    status: "",
    description: "",
    availability: null,
    user: null,
    masterCurrency: null,
    visibility: null,
    instant_session_schedule: false
}

export const appointmentMessageTypeEnum = {
    INFO: {
        label: "Info",
        value: "info",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    ERROR: {
        label: "Error",
        value: "error",
        lightColor: "red-100",
        darkColor: "red-500"
    }
}

export const DEFAULT_APPOINTMENT_PAGE = 1;
export const DEFAULT_APPOINTMENT_RECORDS = 15;