import { BsCircle } from "react-icons/bs";
import { BsRecordCircleFill } from "react-icons/bs";

import { updateUserDefaultDetailsByUserId } from "redux/default/default.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { payoutType } from 'redux/payout/payout.const';

const trailingCharacters = 4

const BankTransferDetails = ({ userPayout }) => {
    const { user } = useAppState((state) => state.user)
    const { userDefaultDetail } = useAppState((state) => state.userDefault)

    const dispatcher = useAppDispatcher()

    const handleBankWithdrawMethod = () => {
        dispatcher(updateUserDefaultDetailsByUserId(
            user?.user?.userId,
            {
                userPayoutId: userPayout?.userPayoutId
            }
        ))
    }

    return (
        <div className={"w-full flex justify-between items-center gap-3"}>
            <span className={"font-bodyPri font-medium text-text-900 text-lg"}>
                {payoutType[userPayout?.payoutType?.toUpperCase()]?.label}
            </span>
            <div className={"flex items-center justify-center gap-1"}>
                <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                    {new Array(userPayout?.bankAccountNumber?.length - trailingCharacters + 1)?.join("*") + userPayout?.bankAccountNumber?.slice(-trailingCharacters)}
                </span>
            </div>
            {(userDefaultDetail?.userDefaultDetail?.userPayoutId !== userPayout?.userPayoutId) &&
                <BsCircle
                    className={"text-lg text-text-800 cursor-pointer"}
                    onClick={handleBankWithdrawMethod}
                />
            }
            {(userDefaultDetail?.userDefaultDetail?.userPayoutId === userPayout?.userPayoutId) &&
                <BsRecordCircleFill
                    className={"text-lg text-primary-dark cursor-pointer"}
                    onClick={handleBankWithdrawMethod}
                />
            }
        </div>
    )
}

export default BankTransferDetails