import { memo } from "react";

import { BsInfoCircle } from 'react-icons/bs';

import ToolTipView from 'components/tooltipView';

const DefaultChildren = () => {
    return (
        <button className={"cursor-pointer"}>
            <BsInfoCircle className="text-md text-text-900 hover:text-text-900" />
        </button>
    )
}

const DownloadTooltip = memo(({
    tooltip = "",
    children,
    ...props
}) => {

    return (
        <ToolTipView content={tooltip} {...props}>
            {children || DefaultChildren()}
        </ToolTipView>
    )
})

export default DownloadTooltip;