import { dayjs, timeZone } from "utils/dateTime.utils";

export const url = {
    GET_USER_AVAILABILITY_LIST: "availabilities/availabilities/users/{userId}",
    CREATE_USER_AVAILABILITY_DETAIL: "availabilities/availabilities",
    GET_USER_AVAILABILITY_DETAIL: "availabilities/availabilities/{availabilityId}/users/{userId}",
    UPDATE_USER_AVAILABILITY_DETAIL: "availabilities/availabilities/{availabilityId}",
    DELETE_USER_AVAILABILITY_DETAIL: "availabilities/availabilities/{availabilityId}",
    
    OFF_DAY_LIST: "/availabilities/off-days",
    OFF_DAY_DETAIL: "/availabilities/off-days/{offDayId}",

    GET_AVAILABILITY_SLOT_DETAIL: "availabilities/availabilities/{id}/slots"
}

export const availabilityTypeEnum = {
    ONE_ON_ONE: {
        label: "One on One",
        value: "one_on_one"
    },
    GROUP: {
        label: "Group",
        value: "group"
    }
}

export const availabilityIsRecurringEnum = {
    RECURRING: {
        label: "Repeat weekly",
        value: "recurring"
    },
    NON_RECURRING: {
        label: "Doesn't repeat",
        value: "non_recurring"
    }
}

export const availabilityEndTypeEnum = {
    FOREVER: {
        value: "forever",
        label: "Forever"
    },
    ON_DATE: {
        value: "on_date",
        label: "On Date"
    },
    WEEKLY_CYCLE: {
        value: "weekly_cycle",
        label: "Weekly Cycle"
    },
}

export const weekAvailTimeSlots = {
    SUN: [],
    MON: [],
    TUE: [],
    WED: [],
    THU: [],
    FRI: [],
    SAT: []
}

export const addUserAvailabilityDetailPayload = {
    name: null,
    description: null,
    type: availabilityTypeEnum.ONE_ON_ONE.value,
    minTimeBeforeAvailStart: 720,
    advanceSlotPeriodDays: 30,
    isRecurring: availabilityIsRecurringEnum.RECURRING.value,
    timeZone: null,
    weeklyTimeSlots: weekAvailTimeSlots,
    dateTimeSlots: [],
    startDateTime: dayjs()?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss"),
    endType: availabilityEndTypeEnum?.FOREVER?.value,
    weeklyCycle: null,
    endDateTime: null
}

export const modifyUserAvailabilityDetailPayload = {
    userId: null,
    name: null,
    description: null,
    type: null,
    minTimeBeforeAvailStart: 720,
    advanceSlotPeriodDays: 30,
    isRecurring: null,
    timeZone: null,
    weeklyTimeSlots: weekAvailTimeSlots,
    startDateTime: dayjs()?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss"),
    endType: availabilityEndTypeEnum?.FOREVER?.value,
    weeklyCycle: null,
    endDateTime: null
}

export const DEFAULT_AVAILABILITY_LIST_PAGE = 1;
export const DEFAULT_AVAILABILITY_LIST_RECORDS = 5;