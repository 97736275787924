import { useState, useEffect } from 'react';

import { IoMdInformationCircleOutline } from "react-icons/io";
import { FaLanguage } from "react-icons/fa";
import { AiOutlineFieldTime } from "react-icons/ai";
import { AiOutlineVideoCamera } from "react-icons/ai";
import { BsFileEarmarkMedical } from "react-icons/bs";
import { MdOutlineBarChart } from "react-icons/md";
import { MdOutlineClass } from "react-icons/md";
import { MdOutlineDashboard } from "react-icons/md";
import { MdOutlineQuiz } from "react-icons/md";
import { FiFileText } from "react-icons/fi";

import { Reorder } from 'framer-motion';

import ComponentLoader from 'components/loader/ComponentLoader';

import CreateSection from 'pages/auth/edulyteLms/commonComponents/CreateSection';
import AddSection from 'pages/auth/edulyteLms/commonComponents/AddSection';

import LMSCourseSectionItem from './LMSCourseSectionItem';

import { createLmsCourseSectionDetail, updateOrderLmsCourseSection } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetAddLmsCourseSectionDetailData, setAddLmsCourseSectionDetailPayload, setLmsCourseDetailData } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';
import { addLmsCourseSectionDetailPayload } from "redux/edulyteLms/lmsCourse/lmsCourse.const";

import { isOrderUpdated } from 'utils/generators.utils';
import ToolTipView from 'components/tooltipView';

const CourseSection = () => {
  const { lmsCourseDetail, addLmsCourseSectionDetail } = useAppState((state) => state.lms.lmsCourse)
  const dispatcher = useAppDispatcher()

  const [sectionTitle, setSectionTitle] = useState('');
  const [isInputVisible, setInputVisible] = useState(false);
  const [isCourseSectionOrderUpdated, setIsCourseSectionOrderUpdated] = useState(false)

  useEffect(() => {
    if (lmsCourseDetail?.data?.course_sections && !!lmsCourseDetail?.data?.course_sections?.length) {
      dispatcher(setAddLmsCourseSectionDetailPayload({
        ...addLmsCourseSectionDetailPayload,
        sectionId: lmsCourseDetail?.data?.course_sections[0]?.id
      }))
    }
  }, [])

  useEffect(() => {
    if (addLmsCourseSectionDetail?.data) {
      dispatcher(setAddLmsCourseSectionDetailPayload({
        ...addLmsCourseSectionDetailPayload,
        sectionId: addLmsCourseSectionDetail?.data?.id
      }))
      dispatcher(setLmsCourseDetailData({
        ...lmsCourseDetail?.data,
        course_sections: [...lmsCourseDetail?.data?.course_sections, addLmsCourseSectionDetail?.data]
      }))
      dispatcher(resetAddLmsCourseSectionDetailData())
    }
  }, [addLmsCourseSectionDetail?.data])

  const onHandleReorderGroup = (newOrder) => {
    if (newOrder) {
      const isOrderModified = isOrderUpdated(lmsCourseDetail?.data?.course_sections, newOrder)
      if (!isOrderModified) {
        setIsCourseSectionOrderUpdated(false)
        return;
      }
      if (isOrderModified) {
        setIsCourseSectionOrderUpdated(true)
        dispatcher(setLmsCourseDetailData({
          ...lmsCourseDetail?.data,
          course_sections: newOrder
        }))
      }
    }
  }

  const onHandleUpdateCourseSectionOrder = () => {
    if (isCourseSectionOrderUpdated && (lmsCourseDetail?.data?.course_sections?.length > 0)) {
      const body = {
        order: lmsCourseDetail?.data?.course_sections?.map((courseSection) => ({
          id: courseSection?.id
        }))
      }
      dispatcher(updateOrderLmsCourseSection(body, { lms_course_id: lmsCourseDetail?.data?.id }))
      setIsCourseSectionOrderUpdated(false)
    }
  }

  const onHandleAddNewSection = () => {
    setSectionTitle("")
    setInputVisible(true)
  };

  const onHandleCreateSection = () => {
    if (lmsCourseDetail?.isLoading || addLmsCourseSectionDetail?.isLoading) return;

    dispatcher(createLmsCourseSectionDetail({
      lms_course_id: String(lmsCourseDetail?.data?.id),
      title: sectionTitle,
    }))
    setInputVisible(false)
  };

  const CourseIncludes = () => {

    function getCount(lmsCourse) {
      let sectionCount = lmsCourse?.course_sections?.length
      let lessons = lmsCourse?.course_sections?.reduce((initial, section) => [...initial, ...section?.lms_course_section_lessons], [])
      let lessonsCount = lessons?.reduce((initial, section_lesson) => ({ ...initial, [section_lesson?.type]: ++initial[section_lesson?.type] }), { lecture: 0, article: 0, quiz: 0 })
      let downloadResourceCount = 0

      return {
        sectionCount,
        lessonCount: lessons?.length,
        videoCount: lessonsCount?.lecture,
        articleCount: lessonsCount?.article,
        quizCount: lessonsCount?.quiz,
        downloadResourceCount
      }
    }

    const count = getCount(lmsCourseDetail?.data)

    return (
      <div className={"p-3 space-y-2"}>
        <h4 className={"font-bodyPri font-medium text-base text-text-900"}>{"This course includes:"}</h4>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
          <div className={"inline-flex items-center justify-start gap-3"}>
            <MdOutlineDashboard className={"text-text-600 text-lg"} />
            <span className={"font-bodyPri font-normal text-base text-text-800"}>
              {`${count?.sectionCount} Sections`}
            </span>
          </div>
          <div className={"inline-flex items-center justify-start gap-3"}>
            <MdOutlineClass className={"text-text-600 text-lg"} />
            <span className={"font-bodyPri font-normal text-base text-text-800"}>
              {`${count?.lessonCount} Lessons`}
            </span>
          </div>
          <div className={"inline-flex items-center justify-start gap-3"}>
            <AiOutlineVideoCamera className={"text-text-600 text-lg"} />
            <span className={"font-bodyPri font-normal text-base text-text-800"}>
              {`${count?.videoCount} Videos`}
            </span>
          </div>
          <div className={"inline-flex items-center justify-start gap-3"}>
            <MdOutlineQuiz className={"text-text-600 text-lg"} />
            <span className={"font-bodyPri font-normal text-base text-text-800"}>
              {`${count?.quizCount} Quizzes`}
            </span>
          </div>
          <div className={"inline-flex items-center justify-start gap-3"}>
            <FiFileText className={"text-text-600 text-lg"} />
            <span className={"font-bodyPri font-normal text-base text-text-800"}>
              {`${count?.articleCount} Articles`}
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={"w-full flex flex-col items-start justify-start gap-5"}>
      {(lmsCourseDetail?.data?.course_sections?.length > 0) &&
        <div className={"w-full flex items-center justify-between gap-5"}>
          <span className={"font-bodyPri font-medium text-sm md:text-lg tracking-wide"}>
            {"Course Content"}
          </span>
          <ToolTipView content={<CourseIncludes />} allowHTML={true} theme={"light"} interactive={true}>
            <span className={"p-2 rounded-full hover:bg-text-300 cursor-pointer"}>
              <IoMdInformationCircleOutline className={"text-xl text-text-700"} />
            </span>
          </ToolTipView>
        </div>
      }
      {(lmsCourseDetail?.data?.course_sections?.length > 0) &&
        <Reorder.Group
          className={'w-full flex flex-col gap-5'}
          values={lmsCourseDetail?.data?.course_sections}
          onReorder={onHandleReorderGroup}
        >
          {lmsCourseDetail?.data?.course_sections?.map((courseSectionItem, index) => (
            <Reorder.Item
              key={courseSectionItem?.id}
              value={courseSectionItem}
              onDragEnd={onHandleUpdateCourseSectionOrder}
            >
              <LMSCourseSectionItem courseSectionItem={courseSectionItem} />
            </Reorder.Item>
          ))}
        </Reorder.Group>
      }
      {addLmsCourseSectionDetail?.isLoading &&
        <ComponentLoader isLoading={addLmsCourseSectionDetail?.isLoading} />
      }
      {!isInputVisible &&
        <AddSection
          onAddNewPress={onHandleAddNewSection}
        />
      }
      {isInputVisible && (
        <CreateSection
          isLoading={addLmsCourseSectionDetail?.isLoading}
          sectionName={'New Section'}
          onClose={() => setInputVisible(false)}
          value={sectionTitle}
          onChange={(e) => setSectionTitle(e.target.value)}
          placeholder={'Section Title'}
          onCreate={onHandleCreateSection}
        />
      )}
    </div>
  );
};

export default CourseSection;