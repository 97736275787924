import { useCallback, useEffect } from 'react';

import { motion } from "framer-motion";

import { AiOutlineClose } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';
import { IoIosCheckmark } from 'react-icons/io';

import ButtonLoader from 'components/loader/button-loader';
import ToolTipView from 'components/tooltipView';
import FloatingLabelSelect from 'components/floating/floatingLabelSelect';

import { getCrmModalContactStatusList, updateCrmBulkContactList } from 'redux/crm/crm.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import {
    resetCrmModalContactStatusList,
    resetModifyCrmBulkContactList,
    resetModifyCrmBulkContactListPayload,
    setModifyCrmBulkContactListPayload
} from 'redux/crm/crm.slice';
import { crmContactCategoryEnum } from 'redux/crm/crm.const';

const UpdateBulkContactListContent = ({ onClose }) => {
    const { crmModalContactStatusList, modifyCrmBulkContactList } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        return () => {
            dispatcher(resetCrmModalContactStatusList())
        }
    }, [])

    useEffect(() => {
        if (modifyCrmBulkContactList?.data) {
            dispatcher(resetModifyCrmBulkContactList())
            dispatcher(resetModifyCrmBulkContactListPayload())
            onClose()
        }
    }, [modifyCrmBulkContactList?.data])

    useEffect(() => {
        if (modifyCrmBulkContactList?.payload?.category) {
            dispatcher(getCrmModalContactStatusList({ category: modifyCrmBulkContactList?.payload?.category }))
        }
    }, [modifyCrmBulkContactList?.payload?.category])

    useEffect(() => {
        if (modifyCrmBulkContactList?.payload?.category && !!crmModalContactStatusList?.data?.length) {
            dispatcher(setModifyCrmBulkContactListPayload({
                ...modifyCrmBulkContactList?.payload,
                status: {
                    label: modifyCrmBulkContactList?.payload?.status?.label || crmModalContactStatusList?.data[0]?.name,
                    value: modifyCrmBulkContactList?.payload?.status?.value || crmModalContactStatusList?.data[0]?.key
                }
            }))
        }
    }, [crmModalContactStatusList?.data])

    const onHandleChangeBasicDetail = useCallback((key, value) => {
        let payload = {
            [key]: value
        }
        if (key === "category") {
            payload = {
                ...payload,
                status: { label: "", value: "" }
            }
        }
        dispatcher(setModifyCrmBulkContactListPayload({
            ...modifyCrmBulkContactList?.payload,
            ...payload
        }))
    }, [modifyCrmBulkContactList?.payload])

    const onHandleUpdateBulkDetail = useCallback(() => {
        if (modifyCrmBulkContactList?.isLoading) return;

        const body = {
            data: modifyCrmBulkContactList?.payload?.selectedContactList?.map((item) => ({
                id: item,
                status: modifyCrmBulkContactList?.payload?.status?.value,
                category: modifyCrmBulkContactList?.payload?.category
            }))
        }
        dispatcher(updateCrmBulkContactList(body))
    }, [modifyCrmBulkContactList])

    return (
        <div className={"flex flex-col gap-8 p-5 font-bodyPri"}>
            <div className={"flex items-center justify-end gap-3"}>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ duration: 0.3 }}
                    className="flex justify-center items-center h-8 px-5 text-white bg-primary-dark hover:bg-secondary-dark rounded ease-in-out duration-200"
                    onClick={onHandleUpdateBulkDetail}
                >
                    {modifyCrmBulkContactList?.isLoading &&
                        <ButtonLoader isLoading={modifyCrmBulkContactList?.isLoading} />
                    }
                    {!modifyCrmBulkContactList?.isLoading && "Save"}
                </motion.button>
                <ToolTipView content={"Close"}>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ duration: 0.3 }}
                        className="flex justify-center items-center h-8 w-8 text-lg hover:bg-background-light rounded-full ease-in-out duration-200"
                        onClick={onClose}
                    >
                        <AiOutlineClose />
                    </motion.button>
                </ToolTipView>
            </div>
            <div className={"w-full flex flex-col items-center gap-8"}>
                <div className={"w-full"}>
                    <FloatingLabelSelect
                        options={Object.values(crmContactCategoryEnum)}
                        onHandleSelect={(selectedOption) => onHandleChangeBasicDetail("category", selectedOption?.value)}
                        value={crmContactCategoryEnum[modifyCrmBulkContactList?.payload?.category?.toUpperCase()]?.label}
                        OptionChild={({ option }) => (
                            <div className="bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between">
                                {option.label}
                                {(option.value === crmContactCategoryEnum[modifyCrmBulkContactList?.payload?.category?.toUpperCase()]?.value) && <IoIosCheckmark size={20} color="green" />}
                            </div>
                        )}
                        label={"Category"}
                        dropDownContainerClassName={"w-full !z-[500]"}
                    />
                </div>
                <div className={"w-full"}>
                    {crmModalContactStatusList?.isLoading &&
                        <FaSpinner className={"text-lg text-primary-dark animate-spin"} />
                    }
                    {!crmModalContactStatusList?.isLoading && crmModalContactStatusList?.data &&
                        <FloatingLabelSelect
                            isOpenDropdown={true}
                            options={crmModalContactStatusList?.data?.map((contactStatus) => ({
                                label: contactStatus?.name,
                                value: contactStatus?.key
                            })) || []}
                            onHandleSelect={(selectedOption) => onHandleChangeBasicDetail("status", selectedOption)}
                            value={modifyCrmBulkContactList?.payload?.status?.label}
                            OptionChild={({ option }) => (
                                <div className="bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between">
                                    {option?.label}
                                    {(option?.value === modifyCrmBulkContactList?.payload?.status?.value) && <IoIosCheckmark size={20} color="green" />}
                                </div>
                            )}
                            label={"Status"}
                            dropDownContainerClassName={"w-full max-h-44 overflow-y-auto scrollbar-thin"}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default UpdateBulkContactListContent;