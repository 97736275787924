import { useEffect, useReducer, useRef } from 'react';
import { toast } from 'react-toastify';

import { IoCloseOutline } from 'react-icons/io5';
import { FaSpinner } from 'react-icons/fa';
import { IoIosArrowDown } from "react-icons/io";

import { JOINED_STATUS, eventlyGuestsActions, eventlyGuestsReducer } from '../data';

import userService from 'redux/user/user.service';
import { deleteEventlyInvitationDetail, getEventlyInvitationList } from 'redux/evently/evently.request';
import eventlyService from 'redux/evently/evently.service';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetEventlyInvitationList, resetSendEventlyInvitationDetail, setModifyUserEventlyDetailPayload } from 'redux/evently/evently.slice';
import { eventlyInviteToEnum } from 'redux/evently/evently.const';
import { USER_DEMO_PROFILE } from 'redux/user/user.const';

import { validateEmail } from 'utils/validation-functions';
import { cn } from 'utils/cn.utils';

const DEFAULT_PAGE_COUNT = 1;
const DEFAULT_PAGE_RECORDS = 50;

const EventlyGuests = () => {
    const { user } = useAppState((state) => state.user)
    const { userEventlyDetail, modifyUserEventlyDetail, eventlyInvitationList, sendEventlyInvitationDetail, destroyEventlyInvitationDetail } = useAppState((state) => state.evently)

    const dispatcher = useAppDispatcher()
    const inputRef = useRef()

    const [state, dispatch] = useReducer(eventlyGuestsReducer, {
        isLoading: false,
        emailAddress: "",
        invalidEmail: ""
    })

    useEffect(() => {
        return () => {
            dispatcher(resetEventlyInvitationList())
            dispatcher(resetSendEventlyInvitationDetail())
        }
    }, [])

    useEffect(() => {
        if (userEventlyDetail?.data?.id) {
            const filteredEmails = userEventlyDetail?.data?.evently_users?.map((item) => `!${item?.user?.email}`)?.join(",")
            const query = {
                page: DEFAULT_PAGE_COUNT,
                records: DEFAULT_PAGE_RECORDS,
                evently_id: userEventlyDetail?.data?.id
            }
            if (!!filteredEmails?.length) {
                query["email"] = filteredEmails
            }
            dispatcher(getEventlyInvitationList(query))
        }
    }, [userEventlyDetail?.data])

    useEffect(() => {
        if (eventlyInvitationList?.data) {
            const filteredInvitationList = eventlyInvitationList?.data?.results?.map((invitationItem) => ({
                id: invitationItem?.id,
                name: invitationItem?.user?.first_name
                    ? `${invitationItem?.user?.first_name} ${invitationItem?.user?.last_name?.charAt(0)}.`
                    : invitationItem?.user?.email,
                profilePicUrl: invitationItem?.user?.profile_pic_url ? invitationItem?.user?.profile_pic_url : USER_DEMO_PROFILE,
                email: invitationItem?.user?.email,
                isPlatformUser: false,
                status: invitationItem?.status
            }))
            dispatcher(setModifyUserEventlyDetailPayload({
                ...modifyUserEventlyDetail?.payload,
                selectedGuestsList: [...modifyUserEventlyDetail?.payload?.selectedGuestsList, ...filteredInvitationList]
            }))
        }
    }, [eventlyInvitationList?.data])

    useEffect(() => {
        if (state.emailAddress && !validateEmail(state.emailAddress)) {
            dispatch({
                type: eventlyGuestsActions.INVALID_EMAIL.value,
                payload: "Please enter valid email address."
            })
        }
        if (state.emailAddress && validateEmail(state.emailAddress)) {
            dispatch({
                type: eventlyGuestsActions.INVALID_EMAIL.value,
                payload: ""
            })
        }
    }, [state.emailAddress])

    const onHandleChangeEmailAddress = (event) => {
        dispatch({
            type: eventlyGuestsActions.EMAIL_ADDRESS.value,
            payload: event?.target?.value?.toLowerCase()
        })
    }

    const onHandleSendInvitation = async (userObject) => {
        dispatch({
            type: eventlyGuestsActions.IS_LOADING.value,
            payload: true
        })

        try {
            const requestPayload = {
                body: {
                    evently_id: userEventlyDetail?.data?.id,
                    emails: [userObject.email],
                    invite_to: eventlyInviteToEnum.STUDENT.value,
                    invite_email_sent: true,
                    sender_user_id: user?.user?.userId
                }
            }
            const response = await eventlyService.createSendEventlyInvitationDetail(requestPayload)
            if (response.status === 201) {
                let newGuestList = [];
                if (!!response?.data?.data?.length) {
                    response?.data?.data?.forEach((invitation) => {
                        let matchFound = false;
                        const updatedList = modifyUserEventlyDetail?.payload?.selectedGuestsList?.map((item) => {
                            if (item?.id === invitation?.id) {
                                matchFound = true;
                                return (item?.status !== JOINED_STATUS) ? {
                                    id: invitation?.id,
                                    name: invitation?.user?.first_name
                                        ? `${invitation?.user?.first_name} ${invitation?.user?.last_name?.charAt(0)}`
                                        : invitation?.user?.email,
                                    profilePicUrl: invitation?.user?.profile_pic_url ? invitation?.user?.profile_pic_url : USER_DEMO_PROFILE,
                                    email: invitation?.user?.email,
                                    isPlatformUser: userObject?.isPlatformUser,
                                    status: invitation.status
                                } : item;
                            } else {
                                return item;
                            }
                        });
                        newGuestList = [...updatedList];
                        if (!matchFound) {
                            newGuestList.push({
                                id: invitation?.id,
                                name: invitation?.user?.first_name
                                    ? `${invitation?.user?.first_name} ${invitation?.user?.last_name?.charAt(0)}`
                                    : invitation?.user?.email,
                                profilePicUrl: invitation?.user?.profile_pic_url ? invitation?.user?.profile_pic_url : USER_DEMO_PROFILE,
                                email: invitation?.user?.email,
                                isPlatformUser: userObject?.isPlatformUser,
                                status: invitation.status
                            });
                        }
                    });
                }
                dispatcher(setModifyUserEventlyDetailPayload({
                    ...modifyUserEventlyDetail?.payload,
                    selectedGuestsList: newGuestList
                }))
                dispatch({
                    type: eventlyGuestsActions.EMAIL_ADDRESS.value,
                    payload: ""
                })
                inputRef.current.focus()
                toast.success(response.data.message || "Invitation sent!")
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || error)
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        } finally {
            dispatch({
                type: eventlyGuestsActions.IS_LOADING.value,
                payload: false
            })
        }
    }

    const onHandleAddUser = async () => {
        if (sendEventlyInvitationDetail?.isLoading || state.invalidEmail) return;

        if (state.emailAddress && !validateEmail(state.emailAddress)) {
            dispatch({
                type: eventlyGuestsActions.INVALID_EMAIL.value,
                payload: "Please enter valid email address."
            })
            return;
        }
        if (modifyUserEventlyDetail?.payload?.selectedGuestsList?.filter((guestItem) => (guestItem?.status !== JOINED_STATUS))?.length > 50) {
            dispatch({
                type: eventlyGuestsActions.INVALID_EMAIL.value,
                payload: "Maximum 50 emails can be sent."
            })
            return;
        }
        if (modifyUserEventlyDetail?.payload?.selectedGuestsList?.length > 0) {
            const filterDuplicateEmail = modifyUserEventlyDetail?.payload?.selectedGuestsList?.filter((emailItem) => ((emailItem?.email === state.emailAddress) && (emailItem?.status === JOINED_STATUS)))
            if (filterDuplicateEmail?.length > 0) {
                toast.warn("Duplicate emails are not allowed!")
                return;
            }
        }
        dispatch({
            type: eventlyGuestsActions.IS_LOADING.value,
            payload: true
        })
        let userObject = {
            name: state.emailAddress,
            profilePicUrl: USER_DEMO_PROFILE,
            email: state.emailAddress,
            isPlatformUser: false
        }
        const requestPayload = {
            query: { email: state.emailAddress }
        }
        const response = await userService.getUserList(requestPayload)
        if (response.status === 200) {
            if (response?.data.data?.records > 0) {
                userObject = {
                    name: response?.data.data?.results[0]?.firstName
                        ? `${response?.data.data?.results[0]?.firstName} ${response?.data.data?.results[0]?.lastName?.charAt(0)}`
                        : response?.data?.data?.results[0]?.email,
                    profilePicUrl: response?.data.data?.results[0]?.profilePicUrl ? response?.data.data?.results[0]?.profilePicUrl : USER_DEMO_PROFILE,
                    email: response?.data?.data?.results[0]?.email,
                    isPlatformUser: true
                }
            }
        }
        onHandleSendInvitation(userObject)
    }

    const onHandleKeyDown = (e) => {
        if ((e.keyCode === 13) && state.emailAddress) {
            onHandleAddUser()
        }
        else if ((e.keyCode === 8) && !state.emailAddress && !!modifyUserEventlyDetail?.payload?.selectedGuestsList) {
            const filteredUsers = modifyUserEventlyDetail?.payload?.selectedGuestsList?.slice(0, -1)
            dispatcher(setModifyUserEventlyDetailPayload({
                ...modifyUserEventlyDetail?.payload,
                selectedGuestsList: filteredUsers
            }))
        }
    }

    const onHandleRemoveEmailAddress = (inviteEmail) => {
        if ((inviteEmail?.status === JOINED_STATUS) || destroyEventlyInvitationDetail?.isLoading) return;

        if (!window.confirm("Are you sure?. You want to delete invite.")) return;

        dispatcher(deleteEventlyInvitationDetail(inviteEmail?.id))
        dispatch({
            type: eventlyGuestsActions.EMAIL_ADDRESS.value,
            payload: ""
        })
    }

    const onHandleFetchMoreData = async () => {
        if (eventlyInvitationList?.data?.page === eventlyInvitationList?.data?.totalPages) return;

        const filteredEmails = userEventlyDetail?.data?.evently_users?.map((item) => `!${item?.user?.email}`)?.join(",")
        const requestDataPayload = {
            page: eventlyInvitationList?.data?.page
                ? eventlyInvitationList?.data?.page + 1
                : DEFAULT_PAGE_COUNT,
            records: DEFAULT_PAGE_RECORDS,
            evently_id: userEventlyDetail?.data?.id
        }
        if (!!filteredEmails?.length) {
            requestDataPayload["email"] = filteredEmails
        }
        dispatcher(getEventlyInvitationList(requestDataPayload))
    }

    return (
        <div className={"w-full flex flex-col gap-3"}>
            <div className={cn(
                "w-full inline-flex flex-wrap items-center justify-start gap-2 p-2",
                "bg-background-light border-b focus-within:border-primary-main"
            )}>
                {modifyUserEventlyDetail?.payload?.selectedGuestsList?.map((userItem, index) => (
                    <div
                        key={index}
                        className={cn(
                            "w-fit pl-1 pr-2 py-1 flex items-center justify-start gap-1.5 bg-white shadow-sm border rounded-full",
                            (userItem?.status !== JOINED_STATUS) && "border-complementry-main"
                        )}
                    >
                        <div className={"relative w-[1.5rem] h-[1.5rem] rounded-full overflow-hidden"}>
                            <img
                                src={userItem?.profilePicUrl || USER_DEMO_PROFILE}
                                className={"w-full h-full object-cover"}
                                alt={"profile-pic"}
                            />
                        </div>
                        <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wide"}>
                            {userItem?.name}
                        </span>
                        <IoCloseOutline
                            className={cn(
                                "text-text-700 hover:text-secondary-main text-md md:text-xl cursor-pointer",
                                (userItem?.status === JOINED_STATUS) && "invisible"
                            )}
                            onClick={() => onHandleRemoveEmailAddress(userItem)}
                        />
                    </div>
                ))}
                {(eventlyInvitationList?.data?.totalPages > eventlyInvitationList?.data?.page) &&
                    <div
                        className={"w-fit px-2.5 py-1 bg-white flex items-center gap-2 shadow-sm border rounded-full cursor-pointer group hover:border-secondary-dark"}
                        onClick={onHandleFetchMoreData}
                    >
                        <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wide group-hover:text-secondary-dark"}>
                            {"Show More"}
                        </span>
                        <IoIosArrowDown className={"text-lg text-text-800 group-hover:text-secondary-dark"} />
                    </div>
                }
                <div className={"flex flex-1 items-center justify-start gap-1.5"}>
                    <input
                        type={"email"}
                        ref={inputRef}
                        className={cn(
                            "flex-1 focus:outline-none bg-transparent focus:border-primary-main",
                            "font-bodyPri font-normal text-text-900 text-base placeholder:text-text-500"
                        )}
                        placeholder={"Guests emails"}
                        value={state.emailAddress}
                        onChange={onHandleChangeEmailAddress}
                        onKeyDown={onHandleKeyDown}
                    />
                </div>
                {(state.isLoading || sendEventlyInvitationDetail?.isLoading || eventlyInvitationList?.isLoading) &&
                    <div className={"flex justify-end"}>
                        <FaSpinner className={"animate-spin text-primary-dark"} />
                    </div>
                }
            </div>
            <div className={"w-full flex items-center justify-center gap-1 mt-1"}>
                {((state.emailAddress?.length > 0) && !!state.invalidEmail) &&
                    <span className={"font-bodyPri font-normal text-red-500 text-sm tracking-wide"}>
                        {state.invalidEmail}
                    </span>
                }
            </div>
        </div>
    )
}

export default EventlyGuests;