import { EDULYTE_WS_DOMAIN_URL } from "const/default.const"

import store from "redux/store"

const getQueryString = (query_obj) => Object.keys(query_obj)?.reduce((initial, query_key) => initial + `&${query_key}=${query_obj[query_key]?.toString()}`, "")?.substring(1)


export const edulyteWebSocket = async ({ url = "", query = {} }) => {
    
    let endpoint = `${EDULYTE_WS_DOMAIN_URL}/v1${url}`
    
    let queryString = await getQueryString(query)
    if (queryString) {
        endpoint +=  `?${queryString}`
    }

    const ws = await new WebSocket(endpoint)

    return ws
}

export const edulyteAuthWebSocket = async ({ url = "", query = {} }) => {
    
    let endpoint = `${EDULYTE_WS_DOMAIN_URL}/v1${url}?`
    
    let queryString = await getQueryString(query)
    if (queryString) {
        endpoint +=  queryString + "&"
    }

    const { accessToken } = store?.getState()?.auth?.session
    endpoint += `Authorization=${accessToken}`

    const ws = await new WebSocket(endpoint)

    return ws
}