export const imageType = {
    profilePicture: {
        value: "profilePicture",
        title: "Select your profile picture",
        aspect: 1 / 1,
        fileSize: {
            minSize: 1,
            maxSize: 2
        },
        acceptFileType: "image/x-png, image/png, image/jpg, image/jpeg, image/gif"
    },
}

export const titleOptions = {
    MR: {
        label: "Mr",
        value: "Mr",
    },
    MS: {
        label: "Ms",
        value: "Ms"
    },
    MRS: {
        label: "Mrs",
        value: "Mrs"
    },
    MISS: {
        label: "Miss",
        value: "Miss"
    },
    Dr: {
        label: "Dr",
        value: "Dr"
    },
    SIR: {
        label: "Sir",
        value: "Sir"
    }
}

export const genderOptions = {
    MALE: {
        label: "Male",
        value: "male",
    },
    FEMALE: {
        label: "Female",
        value: "female"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}

export const addressTypeConst = {
    HOME: {
        label: "Home",
        value: "home"
    },
    WORK: {
        label: "Work",
        value: "work"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}

export const userSocialMediaLinksConst = {
    FACEBOOK: {
        label: "Facebook",
        value: "facebook",
        placeholder: "https://www.facebook.com/your_facebook_name",
        demoKey1: "https://www.facebook.com/your_facebook_name",
        demoKey2: "",
        socialLinkUrl: "facebook.com/",
        profileLinkType: "facebookProfileLink"
    },
    INSTAGRAM: {
        label: "Instagram",
        value: "instagram",
        placeholder: "https://www.instagram.com/your_instagram_name",
        demoKey1: "https://www.instagram.com/your_instagram_name",
        demoKey2: "or @your_instagram_name",
        socialLinkUrl: "instagram.com/",
        profileLinkType: "instagramProfileLink"
    },
    TWITTER: {
        label: "Twitter",
        value: "twitter",
        placeholder: "https://www.twitter.com/your_twitter_name",
        demoKey1: "https://www.twitter.com/your_twitter_name",
        demoKey2: "@Your_Twitter_Name",
        socialLinkUrl: "twitter.com/",
        profileLinkType: "twitterProfileLink"
    },
    LINKEDIN: {
        label: "Linkedin",
        value: "linkedin",
        placeholder: "https://www.linkedin.com/yourlinkedinname",
        demoKey1: "https://www.linkedin.com/yourlinkedinname",
        demoKey2: "",
        socialLinkUrl: "linkedin.com/",
        profileLinkType: "linkedinProfileLink"
    }
}

// < div className = 'col-start-0 col-span-full ms:col-start-0 md:col-span-6' >
//     <span className='font-bodyPri font-normal text-text-900 text-sm'>
//         {"State/Province/Region"}
//         {/* <span className="text-red-500 text-lg">{"*"}
//             </span> */}
//         {stateList?.isLoading &&
//             <div className={"flex items-center justify-start gap-0.5"}>
//                 <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
//                     {"Please wait ..."}
//                 </span>
//                 <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
//             </div>
//         }
//         <div className={cn(
//             "w-full border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
//             "font-bodyPri font-normal text-text-800 text-base",
//             "placeholder:text-text-500 placeholder:text-sm"
//         )}>
//             <SelectSearch
//                 options={stateList?.stateList?.map((state) => ({
//                     name: state?.state,
//                     value: state?.masterStateId,
//                 })) || []}
//                 search
//                 autoFocus={false}
//                 placeholder={"Select State"}
//                 filterOptions={fuzzySearch}
//                 value={onboardingUserDetail?.onboardingUserDetail?.state?.masterStateId}
//                 onChange={(option) => onSelectState(option)}
//                 disabled={isFormDisabled}
//             />
//         </div>
//         {stateList?.errorMsg &&
//             <div className={"font-bodyPri font-normal text-text-900 text-base"}>
//                 {stateList?.errorMsg}
//             </div>
//         }
//     </span>
//         </div >
// <div className='col-start-0 col-span-full md:col-start-0 md:col-span-6'>
//     <span className='font-bodyPri font-normal text-text-900 text-sm'>
//         {"City/Suburb"}
//         {/* <span className="text-red-500 text-lg">{"*"}
//             </span> */}
//         {cityList?.isLoading &&
//             <div className={"flex items-center justify-start gap-0.5"}>
//                 <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
//                     {"Please wait ..."}
//                 </span>
//                 <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
//             </div>
//         }
//         <div className={cn(
//             "w-full border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
//             "font-bodyPri font-normal text-text-800 text-base",
//             "placeholder:text-text-500 placeholder:text-sm"
//         )}>
//             <SelectSearch
//                 options={cityList?.cityList?.map((city) => ({
//                     name: city?.city,
//                     value: city?.masterCityId,
//                 })) || []}
//                 search
//                 placeholder={"Select City"}
//                 filterOptions={fuzzySearch}
//                 value={onboardingUserDetail?.onboardingUserDetail?.city?.masterCityId}
//                 onChange={(option) => onSelectCity(option)}
//                 disabled={isFormDisabled}
//             />
//         </div>
//         {cityList?.errorMsg &&
//             <div className={"font-bodyPri font-normal text-text-900 text-base"}>
//                 {cityList?.errorMsg}
//             </div>
//         }
//     </span>
// </div>