import { Section, SectionContent } from 'components/generalComponent/StyledComponent';
import tw from 'twin.macro';

export const Container = tw(Section)`
    bg-white
`;

export const Content = tw(SectionContent)`
    col-start-1 col-span-full md:col-start-3 md:col-span-8
`;
