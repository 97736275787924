import tw from 'twin.macro';

export const Page = tw.div`
    bg-background-darkLight min-h-screen
`;

export const PageContainer = tw.div`
    w-full flex flex-col
`;

export const PageContent = tw.div``;

export const Section = tw.div`
    grid grid-cols-12 py-20 w-full
`;

export const SectionContent = tw.div`
    grid col-start-3 col-span-8
`;

export const HorizontalLine = tw.span`
    w-full h-[2px] bg-background-darkLight rounded-lg
`;

export const ScreenCard = tw.div`
    bg-white shadow-xl rounded-3xl max-w-sm sm:max-w-xl w-full
`;

export const Card = tw.div`
    bg-white shadow-2xl rounded-3xl max-w-md py-8 px-10
`;

/////////////
export const RowFlex = tw.div`
    flex flex-row items-center
`;

export const BtnSecondarysm = tw.button`
    bg-secondary-main lg:text-sm hover:bg-secondary-dark text-white  py-1 sm:px-4 rounded-2xl
`;

export const BtnSecondary = tw.button`
    bg-secondary-main hover:bg-secondary-dark text-white px-2 py-2 sm:px-4 rounded-2xl
`;

export const BtnPrimary = tw.button`
    bg-primary-light hover:bg-primary-dark text-primary-main hover:text-white px-2 py-2 sm:px-4 rounded-2xl
`;

export const BtnOutlineDark = tw.button`
    bg-white text-gray-500 font-bold text-sm
    border border-gray-500 
    hover:bg-gray-500 hover:text-white 
    px-2 py-1 sm:px-4 rounded-2xl
`;

export const Chip = tw.div`
    bg-primary-light text-primary-main text-sm font-semibold
    rounded-xl px-4 py-2 mr-3 mb-3
`;
/////