import tw, { styled } from "twin.macro";

export const StyledChatFilter = styled.div`
  ${tw`flex mt-5 px-4`}
`;

export const StyledChatFilterItem = styled.div`
  ${tw`flex items-center justify-start bg-primary-main py-1 px-2 text-sm rounded-md text-white leading-none mx-2`}

  p {
    ${tw`mr-2`}
  }

  div {
    ${tw`border-l border-white pl-1.5 cursor-pointer`}
  }
`;
