import { BsPlusLg } from 'react-icons/bs';

import { cn } from 'utils/cn.utils';

const AddItemBtn = ({
    btnTitle = "",
    isBtnDisable = false,
    onHandleAddBtn
}) => {
    return (
        <button
            disabled={isBtnDisable}
            onClick={onHandleAddBtn}
            className={cn(
                "flex justify-center items-center gap-2 rounded-full w-full py-1 px-3 cursor-pointer",
                !isBtnDisable && "bg-primary-light text-primary-dark hover:shadow-sm hover:text-secondary-dark",
                isBtnDisable && "bg-text-200 text-text-400 cursor-not-allowed"
            )}
        >
            <BsPlusLg size={13} />
            {btnTitle}
        </button>
    )
}

export default AddItemBtn;