import { useEffect } from 'react';

import { pageHeading } from 'pages/auth/subscriptions/subscriptions.data';

import PageHeader from 'components/pageHeader/PageHeader';
import PlansSection from 'components/plans';

import HeroSection from './components/hero';
import FaqSection from './components/faqs';

import { useTitle } from 'hooks/useTitle';
import { useAppDispatcher, useAppState } from "hooks/useStore";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { userPlans } from "redux/subscription/subscription.const";

import { pagesInfo } from 'utils/pagesInfo';


const SubscriptionsPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const dispatcher = useAppDispatcher();
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.SUBSCRIPTIONS))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "My Subscriptions | Edulyte"
        })
    }, [currentPageInfo])


    return (
        <div className="h-full w-full p-3 mx-auto min-h-screen space-y-3">
            <PageHeader pageHeading={pageHeading} toolTipText={"Manage your paid subscription."} />
            <main className="w-full space-y-5 bg-white bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)]">
                <HeroSection />
                <PlansSection planData={userPlans} />
                <FaqSection />
            </main>
        </div>
    );
};

export default SubscriptionsPage;
