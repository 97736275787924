import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";

import FilterBox from 'components/pageFilter/FilterBox';

import { DEFAULT_LMS_QUESTION_PAGE, lmsQuestionTypeEnum } from "redux/edulyteLms/lmsQuestion/lmsQuestion.const";

import { searchParamsInfo } from "../lmsQuestion.data";

import { cn } from "utils/cn.utils";

const QuestionTypeFilter = memo(({ filterItem, activeFilter, setActiveFilter }) => {

    const navigate = useNavigate()
    const location = useLocation()

    const [questionTypeFilterPayload, setQuestionTypeFilterPayload] = useState({
        selectedQuestionTypeList: [],
        message: ""
    })

    let searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        if (!!searchQueryParams?.getAll(searchParamsInfo.type.key)?.length) {
            setQuestionTypeFilterPayload({
                ...questionTypeFilterPayload,
                selectedQuestionTypeList: searchQueryParams?.getAll(searchParamsInfo.type.key)?.map((item) => item?.replaceAll("-", "_")),
                message: ""
            })
        } else {
            setQuestionTypeFilterPayload({
                ...questionTypeFilterPayload,
                selectedQuestionTypeList: [],
                message: ""
            })
        }
    }, [searchQueryParams.getAll(searchParamsInfo.type.key)?.length])

    const onHandleSelectOption = useCallback((option) => {
        if (questionTypeFilterPayload?.selectedQuestionTypeList?.length > 0) {
            const filterDuplicateQuestionType = questionTypeFilterPayload?.selectedQuestionTypeList?.filter((selectedItem) => (selectedItem === option))
            if (filterDuplicateQuestionType?.length > 0) {
                setQuestionTypeFilterPayload({
                    ...questionTypeFilterPayload,
                    selectedQuestionTypeList: questionTypeFilterPayload?.selectedQuestionTypeList?.filter((item) => (item !== option)),
                    message: ""
                })
            } else {
                setQuestionTypeFilterPayload({
                    ...questionTypeFilterPayload,
                    selectedQuestionTypeList: [...questionTypeFilterPayload?.selectedQuestionTypeList, option],
                    message: ""
                })
            }
        } else {
            setQuestionTypeFilterPayload({
                ...questionTypeFilterPayload,
                selectedQuestionTypeList: [...questionTypeFilterPayload?.selectedQuestionTypeList, option],
                message: ""
            })
        }
    }, [questionTypeFilterPayload, activeFilter])

    const onHandleMouseLeave = () => {
        if (!searchQueryParams?.getAll(searchParamsInfo.type.key)) {
            setQuestionTypeFilterPayload({
                message: "",
                selectedQuestionTypeList: []
            })
        }
        setActiveFilter(null)
    }

    const onHandleApplyFilter = useCallback(() => {
        if (!questionTypeFilterPayload?.selectedQuestionTypeList) {
            setQuestionTypeFilterPayload({
                ...questionTypeFilterPayload,
                message: "Please select type!"
            })
            return;
        }
        setQuestionTypeFilterPayload({
            ...questionTypeFilterPayload,
            message: ""
        })

        searchQueryParams.delete(searchParamsInfo.type.key);
        searchQueryParams.delete(searchParamsInfo.page.key);
        searchQueryParams.set(searchParamsInfo.page.key, DEFAULT_LMS_QUESTION_PAGE);
        questionTypeFilterPayload?.selectedQuestionTypeList.forEach(item => {
            searchQueryParams.append(searchParamsInfo.type.key, item);
        });
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
        setActiveFilter(null)
    }, [questionTypeFilterPayload, searchQueryParams, activeFilter])

    const onHandleClearFilter = useCallback((e) => {
        e.stopPropagation()
        setQuestionTypeFilterPayload({
            ...questionTypeFilterPayload,
            selectedQuestionTypeList: [],
            message: ""
        })

        searchQueryParams.delete(searchParamsInfo.type.key)
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
        setActiveFilter(null)
    }, [searchQueryParams, activeFilter])

    return (
        <FilterBox
            boxTitle={filterItem?.label}
            boxIcon={!!searchQueryParams?.getAll(searchParamsInfo.type.key)?.length
                ? <button onClick={onHandleClearFilter}>
                    <AiOutlineCloseCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
                : <button>
                    <AiOutlinePlusCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
            }
            isActive={activeFilter === filterItem.key}
            onHandleToggleBox={() => setActiveFilter(filterItem.key)}
            onHandleMouseLeave={onHandleMouseLeave}
            BoxItemContent={() => {
                return !!searchQueryParams?.getAll(searchParamsInfo.type.key)?.length && (
                    <>
                        <div className={"h-[20px] border border-divider-lightDark"} />
                        <span className={"font-bodyPri font-normal text-sm text-primary-dark line-clamp-1 capitalize"}>
                            {searchQueryParams?.getAll(searchParamsInfo.type.key)?.map((item) => lmsQuestionTypeEnum[item?.replaceAll("-", "_").toUpperCase()]?.label)?.join(" , ")}
                        </span>
                    </>
                )
            }}
            customBoxClassName={!!searchQueryParams?.getAll(searchParamsInfo.type.key)?.length && "!bg-text-200 !border-solid"}
            activeContainerClassName={""}
        >
            <div className={"w-60 md:w-72 p-3 flex flex-col gap-5"}>
                <span className={"font-bodyPri font-bold text-text-900 text-base tracking-wide"}>
                    {"Filter By Type"}
                </span>
                <div className={"space-y-5"}>
                    {Object.values(lmsQuestionTypeEnum)?.filter((type) => ((type?.value === lmsQuestionTypeEnum.SINGLE_CHOICE.value) || (type?.value === lmsQuestionTypeEnum.MULTIPLE_CHOICE.value)))?.map((item, index) => (
                        <div
                            key={index}
                            className={"w-full flex items-center justify-start gap-3 group cursor-pointer"}
                            onClick={() => onHandleSelectOption(item?.value)}
                        >
                            <input
                                type={"checkbox"}
                                checked={(questionTypeFilterPayload?.selectedQuestionTypeList.includes(item?.value)) && true}
                            />
                            <span className={"font-bodyPri font-normal text-text-900 text-sm tracking-wide line-clamp-1"}>
                                {item?.label}
                            </span>
                        </div>
                    ))}
                </div>
                <div className={cn(
                    "w-full py-1 flex items-center justify-center rounded-md border bg-primary-dark cursor-pointer hover:opacity-90",
                    (!questionTypeFilterPayload?.selectedQuestionTypeList) && "bg-text-300 text-text-300 hover:!bg-text-300 cursor-not-allowed"
                )}
                    onClick={onHandleApplyFilter}
                >
                    <span className={"font-bodyPri font-medium text-text-50 text-sm tracking-wide "}>
                        {"Apply Filters"}
                    </span>
                </div>
            </div>
        </FilterBox>
    )
});

export default QuestionTypeFilter;