import format from 'string-format';

import { baseAuthApiInstance } from 'apiInstances/axios.apiInstance';

import { url } from './downloads.const';

class DownloadsService {

    static downloadsService = new DownloadsService()

    getUserDownloadsList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_DOWNLOADS_LIST, params),
            { params: query }
        )

        return response;
    }

    getUserDownloadDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_DOWNLOAD_DETAIL, params)
        )

        return response;
    }

    getOwnerUserDownloadsList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_OWNER_USER_DOWNLOADS_LIST, params),
            { params: query }
        )

        return response;
    }

    getOwnerUserDownloadDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_OWNER_USER_DOWNLOAD_DETAIL, params)
        )

        return response;
    }

    createUserDownloadDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_USER_DOWNLOAD_DETAIL),
            body
        )

        return response;
    }

    updateUserDownloadDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_USER_DOWNLOAD_DETAIL, params),
            body
        )

        return response;
    }

    deleteUserDownloadDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_USER_DOWNLOAD_DETAIL, params)
        )

        return response;
    }

    createDownloadPreviewDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_DOWNLOAD_PREVIEW_DETAIL),
            body
        )

        return response;
    }

    updateOrderDownloadPreviewDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_ORDER_DOWNLOAD_PREVIEW_DETAIL, params),
            body
        )

        return response;
    }
    deleteDownloadPreviewDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_DOWNLOAD_PREVIEW_DETAIL, params)
        )

        return response;
    }

    createDownloadFileDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_DOWNLOAD_FILE_DETAIL),
            body
        )

        return response;
    }

    updateOrderDownloadFileDetail = async ({ query, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_ORDER_DOWNLOAD_FILE_DETAIL),
            body, { params: query }
        )

        return response;
    }
    deleteDownloadFileDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_DOWNLOAD_FILE_DETAIL, params)
        )

        return response;
    }

    getDownloadUserList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_DOWNLOAD_USER_LIST),
            { params: query }
        )

        return response;
    }

    updateDownloadUserDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_DOWNLOAD_USER_DETAIL, params),
            body
        )

        return response;
    }

    deleteDownloadUserDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_DOWNLOAD_USER_DETAIL, params)
        )

        return response;
    }

    getDownloadInvitationList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_DOWNLOAD_INVITATION_LIST),
            { params: query }
        )

        return response;
    }

    createSendDownloadInvitationDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_SEND_DOWNLOAD_INVITATION_DETAIL),
            body
        )

        return response;
    }

    getValidateDownloadInvitationDetail = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            url.GET_VALIDATE_DOWNLOAD_INVITATION_DETAIL,
            { params: query }
        );

        return response;
    }

    getAcceptAndDeclineDownloadInvitationDetail = async ({ body, query }) => {
        const response = await baseAuthApiInstance().post(
            url.GET_ACCEPT_AND_DECLINE_DOWNLOAD_INVITATION_DETAIL,
            body,
            { params: query }
        )

        return response;
    }

    deleteDownloadInvitationDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_DOWNLOAD_INVITATION_DETAIL, params)
        )

        return response;
    }

}

export default DownloadsService.downloadsService;