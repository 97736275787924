import { useMemo, memo } from 'react'
import { cn } from "utils/cn.utils";
import { TbSection } from 'react-icons/tb';

import ButtonLoader from 'components/loader/button-loader';
import FadedCollapse from 'components/fadedCollapse';

import { lmsQuizCategoryEnum } from "redux/edulyteLms/lmsQuiz/lmsQuiz.const"
import ToolTipView from 'components/tooltipView';

const QuizPreview = memo(({
  quiz,
  isLoading = false,
  message = "",
  isQuizStarted = false,
  onHandleStartQuiz,
  onHandleResumeQuiz,
}) => {

  const descriptionText = useMemo(() => new DOMParser()?.parseFromString(`<div>${quiz?.description || ""}</div>`, "text/html")?.querySelector("div")?.textContent, [quiz?.description])

  const sectionCount = useMemo(() => quiz?.quiz_sections?.length || 0, [quiz?.quiz_sections])
  const questionCount = useMemo(() => {
    return quiz?.quiz_sections?.reduce((initial, quiz_section) => [...initial, ...quiz_section?.quiz_categories?.filter(quiz_category => quiz_category?.type === lmsQuizCategoryEnum.QUESTION.value)], [])?.length || 0
  }, [quiz?.quiz_sections])


  return (
    <div className={"max-w-2xl mx-auto py-5 space-y-10 bg-white px-5 rounded-lg mt-10"}>

      {/* header */}
      <div className={"space-y-3"}>
        <h2 className={"font-bodyPri font-bold text-3xl text-primary-dark text-center"}>
          {`Quiz: ${quiz?.title}`}
        </h2>
        <div className={"rounded-lg overflow-hidden"}>
          <img src={quiz?.thumbnail_pic_url} alt={quiz?.title} className={"w-full aspect-video object-cover"} />
        </div>
      </div>

      <div className={"self-center flex flex-col items-center gap-5"}>
        <button
          className={cn(
            "w-60 h-12 px-5 py-2 self-center bg-primary-dark hover:bg-green-800",
            "font-buttons font-semibold text-lg text-text-50"
          )}
          onClick={isQuizStarted ? onHandleResumeQuiz : onHandleStartQuiz}
          disabled={isLoading}
        >
          {isLoading
            ? <ButtonLoader />
            : isQuizStarted
              ? "Resume Quiz"
              : "Start Quiz"
          }
        </button>
        {(!!message) && (
          <span className={"font-bodyPri font-medium text-lg text-secondary-main text-center"}>
            {message || ""}
          </span>
        )}
      </div>

      {!!descriptionText && (
        <div className={"space-y-2"}>
          <h4 className={"font-bodyPri font-medium text-xl text-text-800"}>{"Description"}</h4>
          <FadedCollapse
            isShowMore={descriptionText?.length > 500}
            isFaded={descriptionText?.length > 500}
            maxHeight={descriptionText?.length > 500 ? "7rem" : "100%"}
            wrapperStyle={"block mt-3"}
          >
            <div dangerouslySetInnerHTML={{ __html: quiz?.description }}></div>
          </FadedCollapse>
        </div>
      )}

      {!!quiz?.quiz_sections?.length && (
        <div className="space-y-2">
          <div className={"flex items-center justify-start gap-5"}>
            <h4 className={"font-bodyPri font-medium text-xl text-text-800"}>{"Content"}</h4>
            <span className={"font-bodyPri font-normal text-base text-text-800"}>
              {`${sectionCount} Section(s) | ${questionCount} Question(s)`}
            </span>
          </div>
          <div className="space-y-2">
            {quiz?.quiz_sections?.map((quiz_section, index) => (
              <div key={index} className={"space-y-0.5 px-5 py-2 bg-background-light rounded"}>
                <div className={"flex items-center justify-between gap-2"}>
                  <div className={"flex items-center justify-start gap-2"}>
                    <ToolTipView content={`Section ${(index + 1).toString().padStart(2, "0")}`}>
                      <span>
                        <TbSection className={"text-3xl text-text-600"} />
                      </span>
                    </ToolTipView>
                    <span className={"font-bodyPri font-medium text-base text-text-800 line-clamp-1"}>
                      {quiz_section?.title}
                    </span>
                  </div>
                  {(!!quiz_section?.question_count) && (
                    <span className={"font-bodyPri font-normal text-sm text-text-800 line-clamp-1"}>
                      {`${quiz_section?.question_count} Question(s)`}
                    </span>
                  )}
                </div>
                {(quiz_section?.description) && (
                  <span className={"ml-10 font-bodyPri font-normal text-sm text-text-800 line-clamp-2"}>
                    {quiz_section?.description}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

    </div>
  )
})

export default QuizPreview