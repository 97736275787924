import { tempCourseType } from "redux/template/template.const";

export const TEMPLATE_INITIAL_STATE = {
    tempCourseList: {
        isLoading: false,
        tempCourseList: null,
        errorMsg: null,
        filteredTempCourseList: null,
    },

    filterProps: {
        tempCourseType: tempCourseType.ALL.value
    }
}