import tw, { styled } from 'twin.macro';
import { Link as RouteLink } from 'react-router-dom';

export const Container = tw.form`
    flex flex-col flex-1 items-center justify-center gap-5 overflow-hidden
`;

export const Section = tw.div`
    border-2 border-divider-medium bg-white rounded-md py-1.5 px-2
    text-text-900 text-base font-bodyPri w-full
    focus:outline-none flex items-center gap-3
`;

export const NameSection = tw.div`
    flex flex-col lg:flex-row items-center justify-center gap-3 w-full
`;

export const InputBox = tw.input`
    text-text-900 text-base font-bodyPri w-full
    focus:outline-none
`;

export const SelectCheckBox = tw.div`
    w-full flex justify-around
`;

export const CheckBox = tw.div`
     flex justify-center items-center gap-2 cursor-pointer
`;

export const Boxes = tw.div`
    flex justify-center items-center gap-2
`;

export const BackButton = tw.div`
    flex items-center justify-center gap-3 cursor-pointer
`;

export const DigitBox = styled.input`
    ${tw`
        w-8 h-8 py-2 px-2 border border-divider-dark text-base font-bodyPri text-text-900 bg-white rounded-sm focus:outline-none
    `}
    ${({ isOtpError }) => isOtpError && tw`
        w-8 h-8 py-2 px-2 border border-red-500 text-base font-bodyPri text-text-900 bg-white rounded-sm focus:outline-none
    `}
`;

export const EmailLabel = tw.div`
    w-full flex justify-center items-center font-bodyPri italic text-sm gap-2
`;

export const ResendOtpContainer = styled.div`
    ${tw`
        font-bodyPri font-medium text-sm text-text-600
    `}
    ${({ minutes, seconds }) => (minutes === 0 && seconds === 0) && tw`
        font-bodyPri font-medium text-sm text-primary-main cursor-pointer
    `}
`;

export const ForgotPasswordSection = tw(RouteLink)`
    flex justify-end w-full
    text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer
`;

export const LogInButton = styled.button`
    ${tw`
        w-full flex items-center justify-center space-x-5 bg-primary-main hover:bg-secondary-dark h-9 rounded-md
        text-text-50 font-buttons font-medium text-base text-center
    `}
`;