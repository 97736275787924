import { useCallback, useEffect } from "react";

import { FaRegUser, FaSpinner } from "react-icons/fa";
import { MdOutlineCake } from "react-icons/md";
import { IoIosCheckmark } from "react-icons/io";
import { MdOutlineCategory } from "react-icons/md";
import { RiBuilding2Line } from "react-icons/ri";
import { BiTagAlt } from "react-icons/bi";

import FloatingLabelInput from "components/floating/floatingLabelInput";
import FloatingLabelSelect from "components/floating/floatingLabelSelect";
import MuiDatePicker from "components/MUIComponents/MuiDatePicker";

import { crmDetailBtnConst } from "components/modals/crmModals/data";
import CreateLabel from "../commonComponents/createLabel";
import AddContactEmails from "./AddContactEmails";
import AddContactNumbers from "./AddContactNumbers";

import { getCrmModalContactStatusList } from "redux/crm/crm.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetCrmModalContactStatusList, setAddCrmContactDetailPayload } from "redux/crm/crm.slice";
import { crmContactCategoryEnum, titleOptionsEnum } from "redux/crm/crm.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

function AddContactBasicDetails() {
    const { addCrmContactDetail, crmModalContactStatusList } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        return () => {
            dispatcher(resetCrmModalContactStatusList())
        }
    }, [])

    useEffect(() => {
        if (addCrmContactDetail?.payload?.category) {
            dispatcher(getCrmModalContactStatusList({ category: addCrmContactDetail?.payload?.category }))
        }
    }, [addCrmContactDetail?.payload?.category])

    useEffect(() => {
        if (addCrmContactDetail?.payload?.category && !!crmModalContactStatusList?.data?.length) {
            dispatcher(setAddCrmContactDetailPayload({
                ...addCrmContactDetail?.payload,
                status: {
                    label: addCrmContactDetail?.payload?.status?.label || crmModalContactStatusList?.data[0]?.name,
                    value: addCrmContactDetail?.payload?.status?.value || crmModalContactStatusList?.data[0]?.key
                }
            }))
        }
    }, [crmModalContactStatusList?.data])

    const onHandleChangeBasicDetail = useCallback((key, value) => {
        let payload = {
            [key]: value
        }
        if (key === crmDetailBtnConst.category.key) {
            payload = {
                ...payload,
                status: { label: "", value: "" }
            }
        }
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetail?.payload,
            ...payload
        }))
    }, [addCrmContactDetail?.payload])

    return (
        <div className="w-full flex flex-col gap-8 items-center mt-5">
            <div className="w-full flex flex-col sm:flex-row gap-8 sm:gap-3 items-center">
                <div className="hidden sm:flex text-lg text-text-700">
                    <FaRegUser />
                </div>
                <div className={"w-full sm:w-72"}>
                    <FloatingLabelSelect
                        labelItem={addCrmContactDetail?.payload?.title}
                        options={Object.values(titleOptionsEnum)}
                        onHandleSelect={(selectedOption) => onHandleChangeBasicDetail(crmDetailBtnConst.title.key, selectedOption?.value)}
                        value={titleOptionsEnum[addCrmContactDetail?.payload?.title?.toUpperCase()]?.label}
                        OptionChild={({ option }) => (
                            <div className="bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between">
                                {option.label}
                                {(option.value === titleOptionsEnum[addCrmContactDetail?.payload?.title?.toUpperCase()]?.value) && <IoIosCheckmark size={20} color="green" />}
                            </div>
                        )}
                        label={crmDetailBtnConst.title.label}
                        dropDownContainerClassName={"w-full"}
                    />
                </div>
                <FloatingLabelInput
                    labelItem={addCrmContactDetail?.payload?.first_name}
                    onChange={(e) => onHandleChangeBasicDetail(crmDetailBtnConst.first_name.key, e.target.value)}
                    value={addCrmContactDetail?.payload?.first_name}
                    label={`${crmDetailBtnConst.first_name.label} *`}
                    autoFocus={true}
                />
                <FloatingLabelInput
                    labelItem={addCrmContactDetail?.payload?.last_name}
                    onChange={(e) => onHandleChangeBasicDetail(crmDetailBtnConst.last_name.key, e.target.value)}
                    value={addCrmContactDetail?.payload?.last_name}
                    label={crmDetailBtnConst.last_name.label}
                />
            </div>

            <AddContactEmails />
            <AddContactNumbers />

            <div className={"w-full flex-col sm:flex-row flex gap-8 sm:gap-3 items-center"}>
                <div className={"hidden sm:flex text-lg text-text-700"}>
                    <MdOutlineCategory />
                </div>
                <div className={"w-full md:w-[25rem]"}>
                    <FloatingLabelSelect
                        labelItem={addCrmContactDetail?.payload?.category}
                        options={Object.values(crmContactCategoryEnum)}
                        onHandleSelect={(selectedOption) => onHandleChangeBasicDetail(crmDetailBtnConst.category.key, selectedOption?.value)}
                        value={crmContactCategoryEnum[addCrmContactDetail?.payload?.category?.toUpperCase()]?.label}
                        OptionChild={({ option }) => (
                            <div className="bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between">
                                {option.label}
                                {(option.value === crmContactCategoryEnum[addCrmContactDetail?.payload?.category?.toUpperCase()]?.value) && <IoIosCheckmark size={20} color="green" />}
                            </div>
                        )}
                        label={crmDetailBtnConst.category.label}
                        dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                    />
                </div>
                <div className={"w-full md:w-[25rem]"}>
                    {crmModalContactStatusList?.isLoading &&
                        <FaSpinner className={"text-lg text-primary-dark animate-spin"} />
                    }
                    {!crmModalContactStatusList?.isLoading && crmModalContactStatusList?.data &&
                        <FloatingLabelSelect
                            labelItem={addCrmContactDetail?.payload?.status?.value}
                            options={crmModalContactStatusList?.data?.map((contactStatus) => ({
                                label: contactStatus?.name,
                                value: contactStatus?.key
                            })) || []}
                            onHandleSelect={(selectedOption) => onHandleChangeBasicDetail(crmDetailBtnConst.status.key, selectedOption)}
                            value={addCrmContactDetail?.payload?.status?.label}
                            OptionChild={({ option }) => (
                                <div className="bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between">
                                    {option?.label}
                                    {(option?.value === addCrmContactDetail?.payload?.status?.value) && <IoIosCheckmark size={20} color="green" />}
                                </div>
                            )}
                            label={crmDetailBtnConst.status.label}
                            dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                        />
                    }
                </div>
            </div>

            <div className={"w-full flex gap-3"}>
                <div className={"hidden sm:flex text-lg text-text-700 mt-2"}>
                    <BiTagAlt />
                </div>
                <CreateLabel />
            </div>

            <div className={"w-full flex gap-3 items-center"}>
                <div className={"hidden sm:flex text-lg text-text-700"}>
                    <MdOutlineCake />
                </div>
                <MuiDatePicker
                    label={crmDetailBtnConst.date_of_birth.label}
                    value={addCrmContactDetail?.payload?.date_of_birth ? dayjs(addCrmContactDetail?.payload?.date_of_birth).tz(timeZone) : null}
                    onChange={(date) => onHandleChangeBasicDetail(crmDetailBtnConst.date_of_birth.key, dayjs(date).tz(timeZone).format("YYYY-MM-DD"))}
                    slotProps={{ textField: { size: "small" } }}
                />
            </div>

            <div className={"w-full flex gap-3 items-center"}>
                <div className={"hidden sm:flex text-lg text-text-700"}>
                    <RiBuilding2Line />
                </div>
                <FloatingLabelInput
                    labelItem={addCrmContactDetail?.payload?.company}
                    onChange={(e) => onHandleChangeBasicDetail(crmDetailBtnConst.company.key, e.target.value)}
                    value={addCrmContactDetail?.payload?.company}
                    label={crmDetailBtnConst.company.label}
                />
            </div>

            <div className={"w-full flex-col sm:flex-row flex gap-8 sm:gap-3 items-center"}>
                <div className={"hidden sm:flex text-lg text-text-700"}>
                    <RiBuilding2Line />
                </div>
                <FloatingLabelInput
                    labelItem={addCrmContactDetail?.payload?.department}
                    onChange={(e) => onHandleChangeBasicDetail(crmDetailBtnConst.department.key, e.target.value)}
                    value={addCrmContactDetail?.payload?.department}
                    label={crmDetailBtnConst.department.label}
                />
                <FloatingLabelInput
                    labelItem={addCrmContactDetail?.payload?.job_title}
                    onChange={(e) => onHandleChangeBasicDetail(crmDetailBtnConst.job_title.key, e.target.value)}
                    value={addCrmContactDetail?.payload?.job_title}
                    label={crmDetailBtnConst.job_title.label}
                />
            </div>
        </div>
    );
}

export default AddContactBasicDetails;