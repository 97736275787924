import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import AddContactNoteItem from "components/modals/crmModals/createCrmContactDetailModal/commonComponents/ContactNoteItem";
import ContactNoteItem from "components/modals/crmModals/viewContactDetailModal/commonComponents/ContactNoteItem";
import AddItemBtn from "components/modals/crmModals/commonComponents/AddItemBtn";
import { crmDetailBtnConst } from "components/modals/crmModals/data";

import { createCrmContactNoteDetail, deleteCrmContactNoteDetail, updateCrmContactNoteDetail } from "redux/crm/crm.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetAddCrmContactNoteDetail, resetModifyCrmContactNoteDetail, setModifyCrmContactDetailPayload } from "redux/crm/crm.slice";

const newNoteInitialState = {
    id: 0,
    isShowInput: false,
    title: null,
    note: ""
}

function EditContactNotes({ editBtnConst, setEditBtnConst }) {
    const { addCrmContactNoteDetail, modifyCrmContactDetail, modifyCrmContactNoteDetail, destroyCrmContactNoteDetail } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()

    const [newNoteDetail, setNewNoteDetail] = useState({
        id: 0,
        isShowInput: false,
        title: null,
        note: ""
    })

    const getUpdatedContactNote = () => {
        if (addCrmContactNoteDetail?.data) {
            const notePayload = {
                id: addCrmContactNoteDetail?.data?.id,
                title: addCrmContactNoteDetail?.data?.title,
                note: addCrmContactNoteDetail?.data?.note
            }
            notePayload["id"] = addCrmContactNoteDetail?.data?.id
            dispatcher(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                notes: [...modifyCrmContactDetail?.payload?.notes, notePayload]
            }))
            dispatcher(resetAddCrmContactNoteDetail())
        } else if (modifyCrmContactNoteDetail?.data) {
            const notePayload = {
                id: modifyCrmContactNoteDetail?.data?.id,
                title: modifyCrmContactNoteDetail?.data?.title,
                note: modifyCrmContactNoteDetail?.data?.note
            }
            dispatcher(setModifyCrmContactDetailPayload({
                ...modifyCrmContactDetail?.payload,
                notes: modifyCrmContactDetail?.payload?.notes?.map((noteItem) => (
                    (noteItem?.id === modifyCrmContactNoteDetail?.data?.id) ? notePayload : noteItem
                ))
            }))
            dispatcher(resetModifyCrmContactNoteDetail())
        }
        setEditBtnConst(null)
        setNewNoteDetail(newNoteInitialState)
    }

    useEffect(() => {
        if (addCrmContactNoteDetail?.data || modifyCrmContactNoteDetail?.data) {
            getUpdatedContactNote()
        }
    }, [addCrmContactNoteDetail?.data, modifyCrmContactNoteDetail?.data])

    const onHandleAddNewNoteDetail = () => {
        if (editBtnConst || addCrmContactNoteDetail?.isLoading || modifyCrmContactNoteDetail?.isLoading || destroyCrmContactNoteDetail?.isLoading) return;

        setNewNoteDetail({
            ...newNoteDetail,
            isShowInput: true,
        })
        setEditBtnConst({
            ...editBtnConst,
            id: 0,
            active: crmDetailBtnConst?.notes.key
        })
    }

    const onHandleSaveNoteDetail = (noteItem) => {
        if (addCrmContactNoteDetail?.isLoading || modifyCrmContactNoteDetail?.isLoading) return;

        const payload = {
            title: noteItem?.title,
            note: noteItem?.note,
        }
        dispatcher(updateCrmContactNoteDetail(noteItem?.id, payload))
        setNewNoteDetail(noteItem)
    }

    const onHandleDeleteNoteDetail = useCallback((noteId) => {
        if (modifyCrmContactNoteDetail?.isLoading || destroyCrmContactNoteDetail?.isLoading) return;
        if (!window.confirm("Are you sure?. You want to delete note details.")) return;

        dispatcher(deleteCrmContactNoteDetail(noteId))
        setEditBtnConst(null)
    }, [modifyCrmContactNoteDetail?.isLoading, destroyCrmContactNoteDetail?.isLoading])

    const onHandleSaveNewNote = useCallback((noteItem) => {
        if (addCrmContactNoteDetail?.isLoading || modifyCrmContactNoteDetail?.isLoading) return;
        if (!noteItem?.title) {
            toast.warn("Please enter title!")
            return;
        }
        const payload = {
            contact_id: modifyCrmContactDetail?.payload?.contact_id,
            title: noteItem?.title,
            note: noteItem?.note
        }
        dispatcher(createCrmContactNoteDetail(payload))
        setNewNoteDetail(noteItem)
    }, [newNoteDetail, addCrmContactNoteDetail?.isLoading, modifyCrmContactNoteDetail])

    const onHandleDeleteNewNote = useCallback(() => {
        setNewNoteDetail(newNoteInitialState)
        setEditBtnConst(null)
    }, [])

    return (
        <div className="w-full flex flex-col gap-8 items-center mt-5">
            {!!modifyCrmContactDetail?.payload?.notes?.length &&
                modifyCrmContactDetail?.payload?.notes?.map((noteItem, index) => (
                    <ContactNoteItem
                        key={noteItem?.id}
                        index={index}
                        isLoading={modifyCrmContactNoteDetail?.isLoading || destroyCrmContactNoteDetail?.isLoading}
                        isShowSaveBtn={true}
                        isShowCancelBtn={true}
                        editBtnConst={editBtnConst}
                        setEditBtnConst={setEditBtnConst}
                        noteItem={noteItem}
                        onHandleSaveNoteDetail={onHandleSaveNoteDetail}
                        onHandleDeleteNoteDetail={onHandleDeleteNoteDetail}
                    />
                ))}
            {newNoteDetail?.isShowInput &&
                <AddContactNoteItem
                    isLoading={addCrmContactNoteDetail?.isLoading}
                    isShowSaveBtn={true}
                    isShowCancelBtn={true}
                    noteItem={newNoteDetail}
                    onHandleChangeNoteDetail={(NoteId, updatedValue) => setNewNoteDetail(updatedValue)}
                    onHandleSaveNoteDetail={onHandleSaveNewNote}
                    onHandleDeleteNoteDetail={onHandleDeleteNewNote}
                />
            }
            {!newNoteDetail?.isShowInput &&
                <AddItemBtn
                    btnTitle={"Add Note"}
                    onHandleAddBtn={onHandleAddNewNoteDetail}
                    isBtnDisable={editBtnConst}
                />
            }
        </div>
    );
}

export default EditContactNotes;