import { cn } from "utils/cn.utils";

import QuizSideBarItem from './components/QuizSideBarItem';

const QuizSideBar = () => {

  return (
    <div
      className={cn(
        "flex flex-col w-full h-[calc(100vh-20px)] bg-white p-5 rounded-lg overflow-hidden overflow-y-auto transition ease-in-out delay-150 duration-300",
        "scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-track-white scrollbar-w-sm",
        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-light",
      )}
    // style={{ height: 'calc(100vh - 20px)' }}
    >
      <QuizSideBarItem />
    </div>
  )
}

export default QuizSideBar;