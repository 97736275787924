import { useState, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { MdOutlineClose } from "react-icons/md";

import { motion, AnimatePresence } from "framer-motion";

import FilterIcon from "components/pageFilter/FilterIcon";
import RefreshButton from "components/common-components/RefreshButton";
import ClearFilter from "components/pageFilter/ClearFilter";

import { appointmentFilters, getAppointmentListPayload, searchParamsInfo } from "pages/auth/appointments/data";

import TitleFilter from "./TitleFilter";
import StatusFilter from "./StatusFilter";
import DateFilter from "./DateFilter";

import { getUserAppointmentList } from "redux/appointment/appointment.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

import { cn } from "utils/cn.utils";

const AppointmentFilters = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher();
    const location = useLocation();

    const [activeFilter, setActiveFilter] = useState(null)
    const [mobileFilter, setMobileFilter] = useState(false)

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    const getAppointmentList = async () => {
        const requestDataPayload = await getAppointmentListPayload(searchQueryParams)

        dispatcher(getUserAppointmentList(user?.user?.userId, { ...requestDataPayload }))
    }

    const onHandleCreateAppointment = useCallback(() => {
        dispatcher(setModal({
            ...modal,
            [modalConst.createAppointmentDetailModal.key]: {
                ...modalConst.createAppointmentDetailModal,
                isVisible: true
            }
        }));
    }, [modal])

    const FilterItemList = () => {
        return (
            <>
                {Object?.values(appointmentFilters)?.map((filterItem, index) => (
                    <div key={index}>
                        {(filterItem.key === searchParamsInfo.title.key) &&
                            <TitleFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                        {(filterItem.key === searchParamsInfo.status.key) &&
                            <StatusFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                        {(filterItem.key === searchParamsInfo.date.key) &&
                            <DateFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                    </div>
                ))}
                <ClearFilter setFilterPayload={setActiveFilter} />
            </>
        )
    }

    return (
        <div className={"relative w-full px-6 py-3 bg-white shadow-md rounded-lg"}>
            <div className={"flex flex-col items-center justify-center gap-3 sm:flex-row sm:justify-between sm:gap-5"}>
                <AnimatePresence initial={mobileFilter}>
                    {mobileFilter && (
                        <motion.div
                            initial={{ x: -200 }}
                            animate={{ x: 0 }}
                            exit={{ x: -200 }}
                            transition={{ duration: 0.3 }}
                            className={'absolute left-0 top-0 h-screen w-60 flex flex-col gap-5 p-5 bg-white rounded lg:hidden shadow-md z-10'}
                        >
                            <div className={'flex justify-between items-center'}>
                                <h3 className={'text-lg font-bodyPri font-semibold text-text-900'}>{"Filter"}</h3>
                                <MdOutlineClose size={20} onClick={() => setMobileFilter(false)} className={'cursor-pointer'} />
                            </div>
                            <FilterItemList />
                        </motion.div>
                    )}
                </AnimatePresence>
                <div className={'hidden w-full lg:flex flex-grow flex-wrap justify-center items-center sm:justify-start gap-3'}>
                    <FilterIcon iconTooltip={"Filter Appointments"} />
                    <FilterItemList />
                </div>
                <div className={"flex w-full items-center justify-between lg:justify-end gap-3"}>
                    <div className={'block lg:hidden text-primary-main font-medium hover:text-secondary-dark whitespace-nowrap cursor-pointer'} onClick={() => setMobileFilter(!mobileFilter)}>
                        {'Filter'}
                    </div>
                    <div className={"flex items-center justify-start gap-3"}>
                        <RefreshButton onHandleRefreshButton={getAppointmentList} />
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            transition={{ duration: 0.3 }}
                            className={cn(
                                "py-2 px-3 md:px-5 text-white text-sm md:text-base ease-in-out duration-200 bg-primary-dark hover:bg-secondary-dark rounded",
                                "font-bodyPri font-normal text-sm sm:text-base"
                            )}
                            onClick={onHandleCreateAppointment}
                        >
                            <span className={"whitespace-normal sm:whitespace-nowrap"}>
                                {"Create New Appointment"}
                            </span>
                        </motion.button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppointmentFilters;