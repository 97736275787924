import { useEffect, useState } from "react";

import { Screen } from "pages/auth/offersAndDiscount/styles/Style"

import { pageHeading } from 'pages/auth/offersAndDiscount/offersAndDiscount.data';

// import OffersTabs from "./components/OffersTabs";
import EdOffers from "pages/auth/offersAndDiscount/components/EdOffers";
import PartOffers from "pages/auth/offersAndDiscount/components/PartOffers";

import PageHeader from 'components/pageHeader/PageHeader';

import { useTitle } from "hooks/useTitle";

function Offers() {
    const [title, setTitle] = useTitle()

    const [value, setValue] = useState("Partner Offers");


    useEffect(() => {
        setTitle({
            ...title,
            title: "Offers and Discounts | Edulyte"
        })
    }, [])

    return (
        <Screen>
            <PageHeader pageHeading={pageHeading} />
            {/* <OffersTabs value={value} setValue={setValue}/> */}
            {value === "Edulyte Offers" && <EdOffers />}
            {value === "Partner Offers" && <PartOffers />}


        </Screen>
    );
}
export default Offers;
