import React from "react";

//Style imports
import { StyledInput } from "./Input.styles";

export default function Input(props) {
  const { type, onChange, placeholder, value } = props;
  return (
    <StyledInput
      type={type}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    />
  );
}
