export const url = {
    /* student lms lesson */
    GET_STUDENT_LMS_LESSON_LIST: 'lms/lessons/lessons/students',
    GET_STUDENT_LMS_LESSON_DETAIL: 'lms/lessons/lessons/{slug}/students',

    /* tutor lms lesson */
    GET_TUTOR_LMS_LESSON_LIST: 'lms/lessons/lessons/tutors',
    GET_TUTOR_LMS_LESSON_DETAIL: 'lms/lessons/lessons/{id}/tutors',

    /* lms Lesson */
    CREATE_LMS_LESSON_DETAIL: 'lms/lessons/lessons',
    GET_LMS_LESSON_LIST: 'lms/lessons/lessons',
    GET_LMS_LESSON_DETAIL: 'lms/lessons/lessons/{id}',
    UPDATE_LMS_LESSON_DETAIL: 'lms/lessons/lessons/{id}',
    DELETE_LMS_LESSON_DETAIL: 'lms/lessons/lessons/{id}',

    /* lms Lesson users */
    CREATE_LMS_LESSON_USER_DETAIL: 'lms/lessons/users',
    GET_LMS_LESSON_USER_LIST: 'lms/lessons/users',
    GET_LMS_LESSON_USER_DETAIL: 'lms/lessons/users/{id}',
    UPDATE_LMS_LESSON_USER_DETAIL: 'lms/lessons/users/{id}',
    DELETE_LMS_LESSON_USER_DETAIL: 'lms/lessons/users/{id}',

    /* lms Lesson slug */
    CREATE_LMS_LESSON_SLUG_DETAIL: 'lms/lessons/slugs',
    GET_LMS_LESSON_SLUG_LIST: 'lms/lessons/slugs',
    GET_LMS_LESSON_SLUG_DETAIL: 'lms/lessons/slugs/{id}',
    UPDATE_LMS_LESSON_SLUG_DETAIL: 'lms/lessons/slugs/{id}',
    DELETE_LMS_LESSON_SLUG_DETAIL: 'lms/lessons/slugs/{id}',

    /* lms Lesson setting */
    CREATE_LMS_LESSON_SETTING_DETAIL: 'lms/lessons/settings',
    GET_LMS_LESSON_SETTING_LIST: 'lms/lessons/settings',
    GET_LMS_LESSON_SETTING_DETAIL: 'lms/lessons/settings/{id}',
    UPDATE_LMS_LESSON_SETTING_DETAIL: 'lms/lessons/settings/{id}',
    DELETE_LMS_LESSON_SETTING_DETAIL: 'lms/lessons/settings/{id}',

    /* lesson lecture */
    GET_LMS_LESSON_LECTURE_LIST: 'lms/lessons/lectures',
    CREATE_LMS_LESSON_LECTURE_DETAIL: 'lms/lessons/lectures',
    GET_LMS_LESSON_LECTURE_DETAIL: 'lms/lessons/lectures/{id}',
    UPDATE_LMS_LESSON_LECTURE_DETAIL: 'lms/lessons/lectures/{id}',
    DELETE_LMS_LESSON_LECTURE_DETAIL: 'lms/lessons/lectures/{id}',

    /* lesson article */
    GET_LMS_LESSON_ARTICLE_LIST: 'lms/lessons/articles',
    CREATE_LMS_LESSON_ARTICLE_DETAIL: 'lms/lessons/articles',
    GET_LMS_LESSON_ARTICLE_DETAIL: 'lms/lessons/articles/{id}',
    UPDATE_LMS_LESSON_ARTICLE_DETAIL: 'lms/lessons/articles/{id}',
    DELETE_LMS_LESSON_ARTICLE_DETAIL: 'lms/lessons/articles/{id}',

    /* lesson quiz */
    GET_LMS_LESSON_QUIZ_LIST: 'lms/lessons/quizzes',
    CREATE_LMS_LESSON_QUIZ_DETAIL: 'lms/lessons/quizzes',
    GET_LMS_LESSON_QUIZ_DETAIL: 'lms/lessons/quizzes/{id}',
    UPDATE_LMS_LESSON_QUIZ_DETAIL: 'lms/lessons/quizzes/{id}',
    DELETE_LMS_LESSON_QUIZ_DETAIL: 'lms/lessons/quizzes/{id}',

    // lesson instructor
    CREATE_LMS_LESSON_INSTRUCTOR_DETAIL: "lms/lessons/users",
    UPDATE_LMS_LESSON_INSTRUCTOR_DETAIL: "lms/lessons/users/{lessonInstructorId}",
    DELETE_LMS_LESSON_INSTRUCTOR_DETAIL: "lms/lessons/users/{lessonInstructorId}"
}

export const lmsLessonStatusEnum = {
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    }
}

export const lmsLessonShareableEnum = {
    YES: {
        label: "Yes",
        value: "yes"
    },
    NO: {
        label: "No",
        value: "no"
    }
}

export const lmsLessonVisibilityEnum = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    }
}

export const lmsLessonTypeEnum = {
    LECTURE: {
        label: 'Lecture',
        value: 'lecture'
    },
    ARTICLE: {
        label: 'Article',
        value: 'article'
    },
    QUIZ: {
        label: 'Quiz',
        value: 'quiz'
    },
}

export const lmsLessonInstructorPermissionEnum = {
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    }
}

export const lmsLessonSettingDetailPayload = {
    activeTab: "general"
}

export const addLmsLessonInstructorDetailPayload = {
    userId: null,
    permission: null,
    lessonInstructorId: null
}