import { useState, useEffect } from 'react';
import { cn } from "utils/cn.utils";

import {
    TaskItemContainer,
    TaskDetails,
    TaskTitleDesc,
    TaskTitleText,
    TaskDescText,
    TaskAction,
    Pending,
    Submitted,
    UnderReview,
    Verified,
    ArrowToggleIcon,
    TaskTime,
    PendingIcon,
    VerifiedIcon,
    SubmittedIcon,
    VideoUrlContainer,
    Rejected,
    RejectedIcon,
    DisabledButton
} from 'pages/auth/tutorOnboard/TutorOnboardStyle';

import VideoDetail from 'pages/auth/tutorOnboard/components/shareIntroVideo/components/VideoDetail';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearAddOnboardingDocDetail } from 'redux/onboarding/onboarding.slice';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { introVideoStatus } from "redux/onboarding/onboarding.const";

const ShareIntroVideo = () => {
    const { modal } = useAppState((state) => state.local)
    const { addOnboardingDocDetail, userOnboardingDetail } = useAppState((s) => s.onboarding)

    const [isOpenVideoDetail, setIsOpenVideoDetail] = useState(false)
    const [clickedItemIndex, setClickedItemIndex] = useState(null);

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (addOnboardingDocDetail.addOnboardingDocDetail) {
            dispatcher(setModal({
                ...modal,
                [modalConst.RECORD_VIDEO_MODAL.stateKey]: false,
                [modalConst.UPLOAD_VIDEO_MODAL.stateKey]: false
            }))
            dispatcher(setClearAddOnboardingDocDetail())
        }
    }, [addOnboardingDocDetail.addOnboardingDocDetail])

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const isOpen = clickedItemIndex === 1;

    const isEmailMobileVerified = userOnboardingDetail?.userOnboardingDetail?.user?.isEmailVerified && userOnboardingDetail?.userOnboardingDetail?.user?.isMobileNoVerified

    const handleTaskItems = () => {
        setClickedItemIndex(isOpen ? null : 1)
        setIsOpenVideoDetail(!isOpenVideoDetail)
    }

    return (
        <TaskItemContainer>
            <TaskDetails>
                <span className={cn(
                    "px-2.5 py-0.5 h-auto self-start rounded-full font-bodyPri font-medium text-lg",
                    (!isEmailMobileVerified || !userOnboardingDetail?.userOnboardingDetail?.introVideo?.status) && "border-2 border-text-500 text-text-900 bg-white",
                    (isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.introVideo?.status) && `text-text-50 bg-${introVideoStatus[userOnboardingDetail?.userOnboardingDetail?.introVideo?.status?.toUpperCase()]?.darkColor}`
                )}>
                    {"2"}
                </span>
                <TaskTitleDesc>
                    <TaskTitleText>{"Share your introduction video for learners"}</TaskTitleText>
                    <TaskDescText>
                        {"Please upload your introduction video focused on learners while highlighting your background. Why should they learn from you? Talk about benefits from your sessions, what will they learn? how will they learn etc.The tone should motivate learners to sign up for your live classes."}
                    </TaskDescText>
                </TaskTitleDesc>
            </TaskDetails>
            <TaskAction>
                {!isEmailMobileVerified &&
                    <DisabledButton>
                        {"Upload short video"}
                    </DisabledButton>
                }
                {(isEmailMobileVerified && (!userOnboardingDetail?.userOnboardingDetail?.introVideo?.status || userOnboardingDetail?.userOnboardingDetail?.introVideo?.status === introVideoStatus?.PENDING?.value)) &&
                    <Pending onClick={handleTaskItems}>
                        <PendingIcon />
                        {introVideoStatus[userOnboardingDetail?.userOnboardingDetail?.introVideo?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Pending>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.introVideo?.status === introVideoStatus?.SUBMITTED?.value) &&
                    <Submitted onClick={handleTaskItems}>
                        <SubmittedIcon />
                        {introVideoStatus[userOnboardingDetail?.userOnboardingDetail?.introVideo?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Submitted>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.introVideo?.status === introVideoStatus.UNDER_REVIEW.value) &&
                    <UnderReview onClick={handleTaskItems}>
                        <SubmittedIcon />
                        {introVideoStatus[userOnboardingDetail?.userOnboardingDetail?.introVideo?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </UnderReview>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.introVideo?.status === introVideoStatus.APPROVED.value) &&
                    <Verified onClick={handleTaskItems}>
                        <VerifiedIcon />
                        {introVideoStatus[userOnboardingDetail?.userOnboardingDetail?.introVideo?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Verified>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.introVideo?.status === introVideoStatus.REJECTED.value) &&
                    <Rejected onClick={handleTaskItems}>
                        <RejectedIcon />
                        {introVideoStatus[userOnboardingDetail?.userOnboardingDetail?.introVideo?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Rejected>
                }
                <TaskTime>{"5 mins"}</TaskTime>
            </TaskAction>
            {isOpenVideoDetail &&
                <VideoUrlContainer>
                    <VideoDetail />
                </VideoUrlContainer>
            }
        </TaskItemContainer>
    )
}

export default ShareIntroVideo;