import { useState, useCallback, useMemo } from "react";

import { toast } from "react-toastify";

import { FaSpinner } from "react-icons/fa";

import FadedCollapse from "components/fadedCollapse";
import ViewOnlyQuillEditor from "components/textEditor/ViewOnlyQuillEditor";

import AddBorderButton from 'pages/auth/edulyteLms/commonComponents/AddBorderButton';
import UpdateLmsInputText from "pages/auth/edulyteLms/commonComponents/UpdateLmsInputText";
import LmsEditorDescription from "pages/auth/edulyteLms/commonComponents/LmsEditorDescription";

import { updateLmsArticle } from 'redux/edulyteLms/lmsArticle/lmsArticle.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

const CustomDescriptionContentContainer = ({ isLoading = false, description }) => {

    const contentLength = useMemo(() => {
        const parsedHTML = new DOMParser().parseFromString(`<div>${description}</div>`, "text/html");
        const textContent = parsedHTML.querySelector("div")?.textContent || '';
        return textContent.length > 400;
    }, [description]);

    return (
        <div className={"w-full px-3 py-2 bg-white rounded-lg space-y-3 hover:bg-text-100"}>
            {isLoading &&
                <div className={"flex justify-end gap-1 pr-4"}>
                    <span className={"font-bodyPri font-normal text-primary-dark text-sm"}>
                        {"Please wait"}
                    </span>
                    <FaSpinner className={"animate-spin text-lg text-primary-dark"} />
                </div>
            }
            <FadedCollapse
                isShowMore={contentLength}
                maxHeight={contentLength ? "24rem" : '3.5rem'}
            >
                <ViewOnlyQuillEditor
                    editorContent={description}
                    editorClassName={""}
                />
            </FadedCollapse>
        </div>
    )
}

const EditLMSArticleBuilderPage = () => {
    const { lmsArticleDetail, updateLmsArticleDetail } = useAppState((state) => state.lms.lmsArticle)

    const dispatcher = useAppDispatcher()

    const [titleInputStatus, setTitleInputStatus] = useState("")
    const [isShowEditor, setIsShowEditor] = useState(lmsArticleDetail?.data?.content)

    const onHandleSaveTitle = useCallback((editedTitle) => {
        if ((editedTitle === lmsArticleDetail?.data?.title)) return;

        setTitleInputStatus("Saving...")
        if (!editedTitle || (editedTitle?.length === 0)) {
            toast.warn("Please enter title ...")
            setTitleInputStatus("Saved")
            return;
        }

        const body = {
            title: editedTitle
        }
        dispatcher(updateLmsArticle(lmsArticleDetail?.data?.article_id, body, { isShowToast: false }))

        setTimeout(() => {
            setTitleInputStatus("")
        }, 1000)
    }, [lmsArticleDetail?.data])

    const onHandleSaveDescription = useCallback((editedDescription) => {
        if ((editedDescription === lmsArticleDetail?.data?.description)) return;

        const body = {
            description: editedDescription
        }
        dispatcher(updateLmsArticle(lmsArticleDetail?.data?.article_id, body, { isShowToast: false }))
    }, [lmsArticleDetail?.data])

    const onHandleUpdateArticle = useCallback((articleContent) => {
        if (lmsArticleDetail?.isLoading || updateLmsArticleDetail?.isLoading) return;

        if (lmsArticleDetail?.data?.content === articleContent) return;

        if (!articleContent || (articleContent?.length === 0)) {
            toast.warn("Please write article!")
            return;
        }

        const body = {
            content: articleContent
        }
        dispatcher(updateLmsArticle(lmsArticleDetail?.data?.article_id, body))
    }, [lmsArticleDetail, updateLmsArticleDetail])

    return (
        <div className={"flex flex-row items-start justify-start gap-3"}>
            <div className={'flex-1 w-full space-y-3'}>
                <UpdateLmsInputText
                    isLoading={updateLmsArticleDetail?.isLoading}
                    isShowInputLabel={true}
                    descriptionPlaceholder={"Add Description (optional)"}
                    containerClassName={"mt-3 mb-1"}
                    inputClassName={"font-tagLine font-semibold text-primary-dark text-lg border-b border-text-400"}
                    title={lmsArticleDetail?.data?.title}
                    titleInputStatus={titleInputStatus}
                    setTitleInputStatus={setTitleInputStatus}
                    onHandleSaveTitle={onHandleSaveTitle}
                />
                <LmsEditorDescription
                    isLoading={updateLmsArticleDetail?.isLoading}
                    descriptionPlaceholder={"Description (optional)"}
                    editorContainerClassName={"px-3 py-2 bg-white rounded-lg"}
                    description={lmsArticleDetail?.data?.description}
                    onHandleSaveDescription={onHandleSaveDescription}
                />

                <div className={"font-bodyPri font-semibold text-lg md:text-xl tracking-wide"}>
                    {"Article Content:"}
                </div>
                <LmsEditorDescription
                    isLoading={updateLmsArticleDetail?.isLoading}
                    isEditorVisible={isShowEditor}
                    editorContainerClassName={"px-3 py-2 bg-white rounded-lg"}
                    editorClassName={"rounded border-b border-text-400 focus-within:border-primary-dark"}
                    defaultHtmlContentLength={600}
                    description={lmsArticleDetail?.data?.content}
                    customDescriptionPlaceholderContainer={() => {
                        return (
                            <div className={"w-full px-3 py-2 bg-white rounded-lg"}>
                                <AddBorderButton
                                    btnTitle={"Add Article"}
                                    onHandleAdd={() => setIsShowEditor(true)}
                                />
                            </div>
                        )
                    }}
                    customDescriptionContentContainer={() =>
                        <CustomDescriptionContentContainer
                            isLoading={updateLmsArticleDetail?.isLoading}
                            description={lmsArticleDetail?.data?.content}
                        />
                    }
                    onHandleSaveDescription={onHandleUpdateArticle}
                />
            </div>
        </div>
    )
}

export default EditLMSArticleBuilderPage;