import React from 'react'
import Achievement from './previewUI/achievment/achievement'
import Participation from './previewUI/participation/participation'
import Completion from './previewUI/completion/completion'

const Preview = ({ certificate }) => {

    return (
        <>
            {certificate?.template === "achievement" && (
                <Achievement certificate={certificate} />
            )}
            {certificate?.template === "participation" && (
                <Participation certificate={certificate} />
            )}
            {certificate?.template === "completion" && (
                <Completion certificate={certificate} />
            )}
        </>
    )
}

export default Preview
