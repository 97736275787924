import { useCallback, useEffect, useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { MdAdd } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';
import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';
import Pagination from 'components/pagination/Pagination';

import DownloadTooltip from 'pages/auth/editDownload/commonComponents/DownloadTooltip';
import DownloadTable from 'pages/auth/editDownload/commonComponents/DownloadTable';
import { downloadParticipantsNavigationOptions, downloadStudentHeaderConst, downloadTooltips } from '../../data';

import {  getDownloadUserList, updateDownloadUserDetail } from 'redux/downloads/downloads.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetDownloadUserList } from 'redux/downloads/downloads.slice';
import { downloadUserStatusEnum, downloadJoiningFlowEnum, downloadUserIsActiveEnum, downloadStatusEnum } from 'redux/downloads/downloads.const';

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from 'utils/dateTime.utils';
import { cn } from 'utils/cn.utils';

const RECORDS = 10;

const Joined = () => {
    const { ownerUserDownloadDetail, downloadUserList } = useAppState((state) => state.downloads)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    useEffect(() => {
        return () => {
            dispatcher(resetDownloadUserList())
        }
    }, [])

    useEffect(() => {
        if (ownerUserDownloadDetail?.data?.id) {
            const query = {
                page: 1,
                records: RECORDS,
                downloadable_id: ownerUserDownloadDetail?.data?.id
            }
            dispatcher(getDownloadUserList(query))
        }
    }, [ownerUserDownloadDetail?.data?.id])

    const onHandleSelectMenu = useCallback((menuItem, studentItem) => {
        switch (menuItem?.value) {
            case downloadUserIsActiveEnum?.ACTIVE?.value:
                if (downloadUserIsActiveEnum?.ACTIVE?.value === studentItem?.status) return;
                dispatcher(updateDownloadUserDetail(studentItem?.id, { is_active: downloadUserIsActiveEnum.ACTIVE.value }))
                break;
            case downloadUserIsActiveEnum?.INACTIVE?.value:
                if (downloadUserIsActiveEnum?.INACTIVE?.value === studentItem?.status) return;
                dispatcher(updateDownloadUserDetail(studentItem?.id, { is_active: downloadUserIsActiveEnum.INACTIVE.value }))
                break;
            case downloadUserIsActiveEnum?.BLOCK?.value:
                if (downloadUserIsActiveEnum?.BLOCK?.value === studentItem?.status) return;
                dispatcher(updateDownloadUserDetail(studentItem?.id, { is_active: downloadUserIsActiveEnum.BLOCK.value }))
                break;
        }
    }, [ownerUserDownloadDetail?.data])

    const onHandleChangePage = useCallback((page) => {
        const query = {
            page: page,
            records: RECORDS,
            downloadable_id: ownerUserDownloadDetail?.data?.id
        }
        dispatcher(getDownloadUserList(query))
    }, [ownerUserDownloadDetail?.data])

    const onHandleInviteParticipants = useCallback(() => {
        if (ownerUserDownloadDetail?.data?.status !== downloadStatusEnum?.PUBLISHED?.value) return;

        navigate(`${pagesInfo?.EDIT_DOWNLOAD?.pagePath}/${ownerUserDownloadDetail?.data?.id}/edit/${downloadParticipantsNavigationOptions?.INVITED?.to}?action=invite-participants`)
    }, [ownerUserDownloadDetail?.data])


    const UserImageContainer = memo(({ profilePicUrl }) => {
        return (
            <div className={"flex items-center justify-center"}>
                <div className={"relative w-10 h-10 overflow-hidden rounded-full"}>
                    <img
                        src={profilePicUrl}
                        className={"w-full h-full object-cover"}
                    />
                </div>
            </div>
        )
    })

    const courseStudentRows = useMemo(() => {
        if (!!downloadUserList?.data?.result?.length) {
            return downloadUserList?.data?.result?.map((student) => [
                <UserImageContainer profilePicUrl={student?.user?.profile_pic_url} />,
                student?.user?.userId || "-",
                student?.user?.first_name + " " + student?.user?.last_name?.charAt(0) + "." || "-",
                downloadJoiningFlowEnum[student?.joining_flow?.toUpperCase()]?.label || "-",
                downloadUserStatusEnum[student?.status?.toUpperCase()]?.label || "-",
                downloadUserIsActiveEnum[student?.is_active?.toUpperCase()]?.label || "-",
                dayjs(student?.joined_at)?.tz(timeZone)?.format("ddd, MMM D, YYYY") || "-",
                <div className={"flex items-center justify-center"}>
                    <ThreeDotMenu
                        menuItems={Object.values(downloadUserIsActiveEnum)?.filter((item) => item?.value !== student?.is_active)}
                        onHandleSelect={(menuItem) => onHandleSelectMenu(menuItem, student)}
                    />
                </div>
            ])
        } else return [];
    }, [downloadUserList?.data?.result])

    return (
        <div className={"px-5 space-y-3"}>
            {downloadUserList?.isLoading &&
                <ComponentLoader isLoading={downloadUserList?.isLoading} />
            }
            {(!downloadUserList?.isLoading && (downloadUserList?.data?.pagination?.records > 0)) &&
                <DownloadTable
                    headers={Object.values(downloadStudentHeaderConst)}
                    rows={courseStudentRows}
                    rowHeaderColor={"bg-background-medium"}
                    alternateRowColor={"bg-primary-light"}
                />
            }
            {(downloadUserList?.data && (downloadUserList?.data?.pagination?.totalPages > 1)) &&
                <div className={"flex justify-center items-center"}>
                    <Pagination
                        page={downloadUserList?.data?.pagination?.page}
                        totalPages={downloadUserList?.data?.pagination?.totalPages}
                        onChangePage={onHandleChangePage}
                    />
                </div>
            }
            {(downloadUserList?.data && (downloadUserList?.data?.pagination?.records === 0)) &&
                <div className={'w-full h-full flex flex-col items-center justify-center gap-1 text-center text-base font-bodyPri tracking-wide'}>
                    <div className={"w-[16rem] h-[16rem] overflow-hidden"}>
                        <img
                            src={"https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/lms-quiz/quiz-student-not-found.jpg"}
                            alt={"no-student-found"}
                            className={"w-full h-full object-cover"}
                        />
                    </div>
                    <DownloadTooltip
                        tooltip={(ownerUserDownloadDetail?.data?.status === downloadStatusEnum?.PUBLISHED?.value)
                            ? downloadTooltips?.publishedInviteBtnTooltip
                            : downloadTooltips?.disabledInviteBtnTooltip
                        }
                        children={
                            <div
                                className={cn(
                                    "flex items-center justify-center gap-1 py-1 px-3 rounded-lg",
                                    (ownerUserDownloadDetail?.data?.status === downloadStatusEnum?.PUBLISHED?.value)
                                        ? "border border-divider-dark text-divider-dark cursor-pointer hover:bg-divider-dark group"
                                        : "border border-divider-medium bg-text-300 text-text-400 cursor-not-allowed"
                                )}
                                onClick={onHandleInviteParticipants}
                            >
                                <MdAdd className={"text-lg group-hover:text-text-50"} />
                                <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                                    {"Invite someone"}
                                </span>
                            </div>
                        }
                    />
                </div>
            }
            {(!ownerUserDownloadDetail?.isLoading && ownerUserDownloadDetail?.message) &&
                <div className={"flex items-center justify-start gap-1"}>
                    <span className={"font-bodyPri font-medium text-red-500 text-base tracking-wide"}>
                        {ownerUserDownloadDetail?.message}
                    </span>
                </div>
            }
        </div>
    )
}

export default Joined;