import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';

import { MdOutlineClose } from 'react-icons/md';

import ClearFilter from 'components/pageFilter/ClearFilter';
import FilterIcon from 'components/pageFilter/FilterIcon';

import LmsRefreshAndAddBtn from '../../../commonComponents/lmsButtons/LmsRefreshAndAddBtn';

import { getLmsQuestionListPayload, lmsQuestionFilters, searchParamsInfo } from '../lmsQuestion.data';
import QuestionTitleFilter from './QuestionTitleFilter';
import QuestionTypeFilter from './QuestionTypeFilter';
import QuestionDateFilter from './QuestionDateFilter';

import { getLmsQuestionList } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetAddLmsQuestionDetail, resetLmsQuestionList } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.slice';
import { modalConst } from 'redux/local/local.const';

const TableFilterActions = () => {
    const { modal } = useAppState((state) => state.local)
    const { lmsQuestionList } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()
    const location = useLocation()

    const [activeFilter, setActiveFilter] = useState(null)
    const [mobileFilter, setMobileFilter] = useState(false)

    let searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    const getQuestionList = async () => {
        const requestDataPayload = await getLmsQuestionListPayload(searchQueryParams)

        dispatcher(getLmsQuestionList({ ...requestDataPayload }))
    }

    useEffect(() => {
        return () => {
            dispatcher(resetLmsQuestionList())
        }
    }, [])

    useEffect(() => {
        getQuestionList()
    }, [location.search])

    const onHandleRefresh = async () => {
        if (lmsQuestionList?.isLoading) return;

        const requestDataPayload = await getLmsQuestionListPayload(searchQueryParams)

        dispatcher(getLmsQuestionList({ ...requestDataPayload }))
    }

    const onHandleAddBtn = () => {
        dispatcher(resetAddLmsQuestionDetail())
        dispatcher(setModal({
            ...modal,
            [modalConst.CREATE_LMS_QUESTION_MODAL.stateKey]: true
        }))
    }

    const handleMobileFilter = () => {
        setMobileFilter(!mobileFilter)
    }

    const FilterItemList = () => {
        return (
            <>
                {Object?.values(lmsQuestionFilters)?.map((filterItem, index) => (
                    <div key={index}>
                        {(filterItem.key === searchParamsInfo.title.key) &&
                            <QuestionTitleFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                        {(filterItem.key === searchParamsInfo.type.key) &&
                            <QuestionTypeFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                        {(filterItem.key === searchParamsInfo.date.key) &&
                            <QuestionDateFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                    </div>
                ))}
                <ClearFilter setFilterPayload={setActiveFilter} />
            </>
        )
    }

    return (
        <div className={"relative w-full px-6 py-3 bg-white shadow-md rounded-lg"}>
            <div className={"w-full flex flex-col items-center justify-between gap-3 sm:flex-row sm:gap-5"}>
                <AnimatePresence initial={mobileFilter}>
                    {mobileFilter && (
                        <motion.div
                            initial={{ x: -200 }}
                            animate={{ x: 0 }}
                            exit={{ x: -200 }}
                            transition={{ duration: 0.3 }}
                            className='absolute left-0 top-0 h-screen w-60 flex flex-col gap-5 p-5 bg-white rounded lg:hidden shadow-md z-10'
                        >
                            <div className='flex justify-between items-center'>
                                <h3 className='text-lg font-bodyPri font-semibold text-text-900'>{"Filter"}</h3>
                                <MdOutlineClose size={20} onClick={() => setMobileFilter(false)} className='cursor-pointer' />
                            </div>
                            <FilterItemList />
                        </motion.div>
                    )}
                </AnimatePresence>
                <div className={'hidden w-full lg:flex flex-grow flex-wrap justify-center items-center sm:justify-start gap-3'}>
                    <FilterIcon iconTooltip={"Filter Questions"} />
                    <FilterItemList />
                </div>
                <div className={"flex w-full items-center justify-between lg:justify-end gap-3"}>
                    <div onClick={handleMobileFilter} className={'block lg:hidden text-primary-main font-medium hover:text-secondary-dark whitespace-nowrap cursor-pointer'}>
                        {"Filter"}
                    </div>
                    <LmsRefreshAndAddBtn
                        btnName={"Add a question"}
                        onHandleRefresh={onHandleRefresh}
                        onHandleAddBtn={onHandleAddBtn}
                    />
                </div>
            </div>
        </div>
    )
}

export default TableFilterActions;