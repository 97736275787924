import { useState } from 'react';
import { cn } from "utils/cn.utils";

import { IoIosArrowForward } from 'react-icons/io';

import { useAppState } from 'hooks/useStore';


const UserProfileInfo = ({ userProfileInfo }) => {
    const { user } = useAppState((state) => state.user)

    return (
        <div className={"w-full py-1.5 px-5 rounded-md hover:bg-text-100"}>
            <div className={"flex items-center justify-start gap-3"}>
                <img
                    src={userProfileInfo?.user?.profilePicUrl}
                    alt="profile-pic"
                    className={"w-10 h-10 rounded-full"}
                />
                <div className={"flex flex-col items-start justify-evenly h-full"}>
                    <span className={"font-bodyPri font-medium text-base text-text-900"}>
                        {userProfileInfo?.user?.firstName + " " + userProfileInfo?.user?.lastName.charAt(0)}
                    </span>
                    {(userProfileInfo?.user?.id === user?.user?.userId) &&
                        <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                            {"Owner"}
                        </span>
                    }
                </div>
            </div>
        </div>
    )
}


const EventlyUser = () => {
    const { userEventlyDetail } = useAppState(s => s.evently)

    const [isShow, setIsShow] = useState(false)

    return (userEventlyDetail?.data?.evently_users?.length > 0) ? (
        <div className={"space-y-1 px-5"}>
            <div onClick={() => setIsShow(!isShow)}
                className={"flex items-center justify-between gap-2 px-2 py-1 cursor-pointer rounded-lg hover:bg-background-light"}
            >
                <span className={"font-bodyPri font-semibold text-md text-text-800"}>
                    {"Guest(s)"}
                </span>
                <IoIosArrowForward className={cn(
                    "font-bold text-2xl text-text-800 transition ease-in-out delay-50 duration-100",
                    isShow
                        ? "rotate-90"
                        : "rotate-0"
                )} />
            </div>
            <div className={cn(
                isShow
                    ? "flex flex-col transition ease-in-out delay-200 duration-300"
                    : "hidden"
            )}>
                {userEventlyDetail?.data?.evently_users?.map((userProfileInfo, index) => (
                    <UserProfileInfo key={index} userProfileInfo={userProfileInfo} />
                ))}
            </div>
        </div>
    ) : null
}

export default EventlyUser;