import { useEffect, useCallback, useState } from 'react';
import { cn } from "utils/cn.utils";

import { toast } from "react-toastify";
import { Draggable } from 'react-drag-reorder';

import { FaTimes } from 'react-icons/fa';

import SelectSearch, { fuzzySearch } from 'react-select-search';

import ComponentLoader from 'components/loader/ComponentLoader';

import AddBorderButton from '../../../../../../commonComponents/AddBorderButton';
import PreviousNextButtons from '../../../../../../commonComponents/lmsButtons/PreviousNextButtons';

import { courseDetailNavigationOptions, lmsCourseTooltips } from '../../../../editLMSCourse.data';

import { getMasterLanguageList } from 'redux/master/master.request';
import { createLmsCourseLanguageDetail, deleteLmsCourseLanguage, updateLmsCourseLanguageListOrder } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearLanguageList } from 'redux/master/master.slice';

const CourseLanguages = () => {
  const { languageList } = useAppState((state) => state.master)
  const { lmsCourseDetail, addLmsCourseLanguageDetail, deleteLmsCourseLanguageDetail } = useAppState((state) => state.lms.lmsCourse)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(getMasterLanguageList())

    return () => {
      dispatcher(setClearLanguageList())
    }
  }, [])

  const [isShowLanguage, setIsShowLanguage] = useState(lmsCourseDetail?.data?.languages?.length > 0)

  const onHandleAddLanguage = useCallback(() => {
    dispatcher(createLmsCourseLanguageDetail({
      lms_course_id: lmsCourseDetail?.data?.id,
      language: 'English'
    }))
    setIsShowLanguage(true)
  }, [isShowLanguage])

  const getChangedPos = useCallback((currentPos, newPos) => {
    var updatedList = [...lmsCourseDetail?.data?.languages];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(currentPos, 1);
    // Add dropped item
    updatedList.splice(newPos, 0, reorderedItem);
    // Update State

    const body = {
      order: updatedList?.map((language) => ({
        id: language?.id
      }))
    }

    dispatcher(updateLmsCourseLanguageListOrder(body, { lms_course_id: lmsCourseDetail?.data?.id }))
  }, [lmsCourseDetail?.data]);

  const onHandleRemoveLanguage = useCallback((selectedLanguageId) => {

    dispatcher(deleteLmsCourseLanguage(selectedLanguageId))
  }, [lmsCourseDetail?.data])

  const onHandleSelectLanguage = (option) => {
    const filteredLanguageList = lmsCourseDetail?.data?.languages?.filter((language) => language?.master_language?.language === option)

    if (filteredLanguageList?.length > 0) {
      toast.warn("Duplicate languages are not allowed!")
      return;
    }
    dispatcher(createLmsCourseLanguageDetail({ lms_course_id: lmsCourseDetail?.data?.id, language: option }))
  }

  return (
    <div className={"w-full flex flex-col justify-between gap-5 rounded-lg overflow-hidden p-5 bg-white"}>
      <div className={"w-full flex flex-col gap-5"}>
        <div className={"w-full px-3 py-2 bg-divider-medium rounded-lg"}>
          <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
            {"Language of instruction"}
          </span>
        </div>
        <div className={"w-full text-center font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
          {lmsCourseTooltips?.languageOfInstructions}
        </div>

        {!isShowLanguage &&
          <AddBorderButton
            btnTitle={"Add Language(s)"}
            onHandleAdd={onHandleAddLanguage}
          />
        }
        {isShowLanguage &&
          <div
            className={cn(
              "w-full px-3 py-3 flex flex-col items-start justify-start border border-dashed border-text-500 rounded-lg",
              "font-bodyPri font-normal text-text-900",
            )}
          >
            {(languageList?.isLoading || addLmsCourseLanguageDetail?.isLoading || deleteLmsCourseLanguageDetail?.isLoading) &&
              <ComponentLoader isLoading={languageList?.isLoading || addLmsCourseLanguageDetail?.isLoading || deleteLmsCourseLanguageDetail?.isLoading} />
            }
            {(!languageList?.isLoading && !addLmsCourseLanguageDetail?.isLoading && !deleteLmsCourseLanguageDetail?.isLoading) &&
              <div className={"flex flex-wrap items-start justify-start gap-3"}>
                <Draggable onPosChange={getChangedPos}>
                  {lmsCourseDetail?.data?.languages?.map((courseLanguage, idx) => (
                    <div
                      key={idx}
                      className={'w-max px-3 py-1 border-2 border-text-300 flex items-center justify-start gap-1.5 rounded-xl'}
                    >
                      <span className={"font-bodyPri font-medium text-text-900 text-base"}>
                        {courseLanguage?.master_language?.language}
                      </span>
                      <button className="pl-3 cursor-pointer">
                        <FaTimes
                          className="font-light text-secondary-dark"
                          onClick={() => onHandleRemoveLanguage(courseLanguage?.id)}
                        />
                      </button>
                    </div>
                  ))}
                </Draggable>
                <div className={"w-52 h-32 md:h-48 z-[500] overflow-hidden border-2 border-divider-medium rounded-lg"}>
                  <SelectSearch
                    options={languageList?.languageList?.map((language) => ({
                      name: language?.language,
                      value: language?.language
                    })) || []}
                    placeholder={"Select Language"}
                    search
                    filterOptions={fuzzySearch}
                    value={""}
                    onChange={(option) => onHandleSelectLanguage(option)}
                  />
                </div>
              </div>
            }
          </div>
        }
      </div>
      <PreviousNextButtons
        previousBtn={courseDetailNavigationOptions?.SKILLS_YOU_WILL_LEARN?.to}
        nextBtn={courseDetailNavigationOptions?.PROFICIENCY?.to}
      />
    </div>
  )
}

export default CourseLanguages;