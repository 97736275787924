import { createSlice } from "@reduxjs/toolkit";
import { PREFERENCE_INITIAL_STATE } from "redux/preference/preference.const";


const preference = createSlice({
    name: "preference",
    initialState: PREFERENCE_INITIAL_STATE,
    reducers: {
        // private reducers
        setPreferenceLoading: (state, { payload }) => {
            state.preference.isLoading = payload
        },
        setPreference: (state, { payload }) => {
            state.preference.preference = payload
            state.preference.errorMsg = PREFERENCE_INITIAL_STATE.preference.errorMsg
        },
        setPreferenceError: (state, { payload }) => {
            state.preference.errorMsg = payload
            state.preference.preference = PREFERENCE_INITIAL_STATE.preference.preference
        },
        setClearPreference: (state) => {
            state.preference = PREFERENCE_INITIAL_STATE.preference
        },

        // global user reducers
        setUserPreferenceLoading: (state, { payload }) => {
            state.userPreference.isLoading = payload
        },
        setUserPreference: (state, { payload }) => {
            state.userPreference.userPreference = payload
            state.userPreference.errorMsg = PREFERENCE_INITIAL_STATE.userPreference.errorMsg
        },
        setUserPreferenceError: (state, { payload }) => {
            state.userPreference.errorMsg = payload
            state.userPreference.userPreference = PREFERENCE_INITIAL_STATE.userPreference.userPreference
        },
        setClearUserPreference: (state) => {
            state.userPreference = PREFERENCE_INITIAL_STATE.userPreference
        }
    }
})

export const {
    setPreferenceLoading,
    setPreference,
    setPreferenceMsg,
    setPreferenceError,
    setClearPreference,

    setUserPreferenceLoading,
    setUserPreference,
    setUserPreferenceMsg,
    setUserPreferenceError,
    setClearUserPreference
} = preference.actions

export default preference.reducer