import { useState } from 'react';
import { cn } from "utils/cn.utils";

import { FaPlus } from "react-icons/fa";
import AddNoteView from './components/addNote';
import NoteItemView from './components/noteItem';

import { useAppState } from 'hooks/useStore';


const NoteTab = ({ activeSection, activeLesson, getLesson }) => {

  const { studentLmsCourseDetail: { data: { lms_course, lms_course_student } } } = useAppState(state => state.lms.lmsCourse)

  const [isAddNoteVisible, setIsAddNoteVisible] = useState(false)
  const [editNote, setEditNote] = useState(null)

  const onHandleAddNote = () => {
    setIsAddNoteVisible(!isAddNoteVisible);
  };

  return (
    <div className='px-10 py-5 space-y-5'>
      {!isAddNoteVisible ? (
        <button className={cn(
          "w-full px-5 py-3 flex items-center justify-between gap-2 border border-text-300 cursor-pointer group",
          "font-buttons font-medium text-xl text-text-700",
          "hover:bg-primary-light hover:border-primary-dark hover:text-primary-dark",
          (!!editNote || !activeLesson?.current) && "cursor-not-allowed hover:border-text-300 hover:text-text-700 hover:bg-transparent"
        )}
          onClick={onHandleAddNote}
          disabled={!!editNote || !activeLesson?.current}
        >
          <span>{"Create a note"}</span>
          <FaPlus className={cn("text-xl text-text-500 group-hover:text-primary-dark", (!!editNote || !activeLesson?.current) && "group-hover:text-text-500")} />
        </button>
      ) : (
        <AddNoteView
          lms_course={lms_course}
          lms_course_student={lms_course_student}
          activeSection={activeSection}
          activeLesson={activeLesson}
          setIsAddNoteVisible={setIsAddNoteVisible}
          getLesson={getLesson}
        />
      )}
      <div className='flex flex-col gap-5'>
        {lms_course_student?.lms_course_student_notes.map((student_note, index) => (
          <NoteItemView
            key={index}
            student_note={student_note}
            lms_course={lms_course}
            editNote={editNote}
            setEditNote={setEditNote}
            isAddNoteVisible={isAddNoteVisible}
            getLesson={getLesson}
          />
        ))}
      </div>
    </div>
  );
}

export default NoteTab;
