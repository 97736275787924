export const LINK_TREE_INITIAL_STATE = {
    createLinkTree: {
        isLoading: false,
        data: null,
        errorMsg: null
    },
    updateLink: {
        isLoading: false,
        data: null,
        errorMsg: null
    },
    addLink: {
        isLoading: false,
        data: null,
        errorMsg: null
    },
    deleteLink: {
        isLoading: false,
        errorMsg: null
    },
    reorderLink: {
        isLoading: false,
        data: null,
        errorMsg: null
    },
}