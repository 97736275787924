import { useState } from 'react';
import { cn } from "utils/cn.utils";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';

import { FaSpinner } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { makeStyles } from '@mui/styles';

// this component is for showing grouped container
const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: "#0049b7",
  backgroundColor: "#e3f2fd"
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const useStyles = makeStyles({
  listbox: {
    maxHeight: 200, // Adjust the maxHeight as per your requirement
    overflowY: 'auto',
  },
});

export default function SearchSelector({
  key,
  containerWidth = "100%",
  isLoading = false,
  isShowDropdownLabel = false,
  isOpenDropdown = false,
  size = "small",
  options,
  selectedValue,
  multiple = false,
  showGroupedOptions = false,
  placeholder = "Select Any",
  dropdownLabel = "Select Any",
  disabled = false,
  autoFocus= false,
  onHandleSelect,
  onHandleRemove,
  onHandleRemoveAllOptions
}) {
  const [open, setOpen] = useState(isOpenDropdown);
  const classes = useStyles();

  const groupedOptions = showGroupedOptions && options?.map((option) => {
    const firstLetter = option?.label[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  return (
    <Autocomplete
      id={key}
      sx={{ width: containerWidth }}
      classes={{listbox: classes.listbox}}
      size={size}
      open={open}
      placeholder={placeholder}
      clearIcon={
        <IoMdClose className={"p-0.5 rounded-full hover:bg-divider-medium text-2xl cursor-pointer"}
          onClick={onHandleRemoveAllOptions}
        />
      }
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      getOptionLabel={(option) => option.label}
      value={selectedValue}
      defaultValue={multiple ? [] : ""}
      options={showGroupedOptions
        ? groupedOptions?.sort((a, b) => -b?.firstLetter?.localeCompare(a?.firstLetter))?.filter(option => !selectedValue?.some(selected => selected?.value === option?.value))
        : options
      }
      renderTags={(value, getTagProps) =>
        value?.map((option, index) => (
          <div
            key={index}
            className={cn(
              "w-max ml-2 mr-1 my-0.5 px-2 py-1 flex items-center justify-center gap-1 rounded-2xl",
              "border border-divider-dark bg-primary-light hover:opacity-90"
            )}
          >
            <span className={"font-bodyPri font-normal text-text-900 text-base"}>
              {option?.label}
            </span>
            <span
              className={"p-0.5 cursor-pointer hover:bg-divider-medium rounded-full group"}
              onClick={() => onHandleRemove(option)}
            >
              <IoMdClose className={"text-base text-divider-dark group-hover:text-divider-dark"} />
            </span>
          </div>
        ))
      }
      groupBy={(option) => option.firstLetter}
      onChange={(event, selectedOption) => onHandleSelect(selectedOption)}
      loading={isLoading}
      disabled={disabled}
      multiple={multiple}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={isShowDropdownLabel && dropdownLabel}
          placeholder={placeholder}
          autoFocus={autoFocus}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <FaSpinner className={"animate-spin text-lg text-primary-dark"} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}