import { addUserDetailPayload, addUserCultureDetailPayload } from "redux/user/user.const";

const userGeneralDetailPayload = {
    isEmailUpdateEnabled: false,
    isMobileNoUpdateEnabled: false,
    isPasswordUpdateEnabled: false,
    isOnlyVerifyEmail: false,
    isOnlyVerifyMobileNo: false,
}

export const USER_INITIAL_STATE = {
    user: {
        isLoading: false,
        user: null,
        errorMsg: null
    },

    userDetail: {
        isLoading: false,
        userDetail: null,
        errorMsg: null
    },
    addUserDetail: {
        isLoading: null,
        payload: addUserDetailPayload,
        errorMsg: null
    },

    userCultureDetail: {
        isLoading: false,
        userCultureDetail: null,
        errorMsg: null
    },
    addUserCultureDetail: {
        isLoading: false,
        payload: addUserCultureDetailPayload,
        errorMsg: null
    },

    userEducationList: {
        isLoading: false,
        userEducationList: null,
        errorMsg: null
    },
    addUserEducationDetail: {
        isLoading: false,
        errorMsg: null
    },

    userOtherPref: {
        isLoading: false,
        userOtherPref: null,
        errorMsg: null
    },
    addUserOtherPref: {
        isLoading: false,
        errorMsg: null
    },

    userSocialMediaLinks: {
        isLoading: false,
        userSocialMediaLinks: null,
        errorMsg: null
    },
    addUserSocialMediaLinks: {
        isLoading: false,
        errorMsg: null
    },

    userAddressList: {
        isLoading: false,
        userAddressList: null,
        errorMsg: null
    },
    addUserAddressDetail: {
        isLoading: false,
        errorMsg: null
    },

    userList: {
        isLoading: false,
        data: null,
        message: null
    },

    modifyUserConfig: {
        isLoading: false,
        data: null,
        message: null
    },
    userGeneralDetail: {
        userGeneralDetailPayload: userGeneralDetailPayload
    }
}