import { cn } from "utils/cn.utils";

import { BiTimeFive } from 'react-icons/bi';
import { MdAccessTimeFilled } from 'react-icons/md';
import { FiEdit } from 'react-icons/fi';
import { IoMdPricetag } from 'react-icons/io';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { updateUserBookingDetail } from 'redux/booking/booking.request';
import { setModal } from 'redux/local/local.slice';
import { setModifyUserBookingDetailPayload } from 'redux/booking/booking.slice';
import { modalConst } from 'redux/local/local.const';
import { bookingOfferingTypeEnum, bookingRegularPriceModelEnum } from 'redux/booking/booking.const';
import { courseType, coursePricingModelTypeEnum } from 'redux/course/course.const';
import { availabilityIsRecurringEnum, availabilityEndTypeEnum } from 'redux/availability/availability.const';

import { currencyRateConversion } from 'utils/generators.utils';
import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';


const DayItems = ({ localDay, timeSlots }) => {

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    return timeSlots?.length > 0 && (
        <div className={cn(
            "col-span-1 flex flex-col justify-start items-center gap-1 py-2 w-32 h-full",
            "border-2 border-divider-medium rounded-lg bg-primary-light"
        )}>
            <span className={"font-bodyPri font-medium text-base text-primary-dark"}>
                {localDay}
            </span>
            <div className={"flex flex-col items-center gap-1"}>
                {timeSlots?.length > 0 && timeSlots?.map((timeSlot, index) => (
                    <span key={index} className={"font-bodyPri font-medium text-base text-primary-dark"}>
                        {dayjs(dayjs().tz(timeZone).format("YYYY-MM-DD") + " " + timeSlot?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("hh:mm A")}
                    </span>
                ))}
            </div>
        </div>
    )
}

const DateTimeItems = ({ dateTimeSlot }) => {

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    return dateTimeSlot?.timeSlots?.length > 0 && (
        <div className={cn(
            "col-span-1 flex flex-col justify-start items-center gap-1 py-2 w-32 h-full",
            "border-2 border-divider-medium rounded-lg bg-primary-light"
        )}>
            <span className={"font-bodyPri font-medium text-base text-primary-dark"}>
                {dayjs(dateTimeSlot?.date + " " + dateTimeSlot?.timeSlots[0]?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("ddd, DD MMM YY")}
            </span>
            <div className={"flex flex-col items-center gap-1"}>
                {dateTimeSlot?.timeSlots.map((timeSlot, index) => (
                    <span key={index} className={"font-bodyPri font-medium text-base text-primary-dark"}>
                        {dayjs(dateTimeSlot?.date + " " + timeSlot?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("hh:mm A")}
                    </span>
                ))}
            </div>
        </div>
    )
}

const BookingItemSection = () => {
    const { locals, modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()

    const { userBookingDetail, modifyUserBookingDetail } = useAppState((state) => state.booking)

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    const addCheckoutSlot = () => {
        dispatcher(setModifyUserBookingDetailPayload({
            ...modifyUserBookingDetail?.payload,
            schedule: null
        }))

        dispatcher(setModal({
            ...modal,
            [modalConst.CHECKOUT_SLOT_MODAL.stateKey]: true
        }))
    }

    return (
        <div className={"space-y-3"}>
            <div className={"font-bodyPri font-medium text-lg text-text-800 space-x-3"}>
                <span>{"1"}</span>
                <span>{"Review Booking Item"}</span>
            </div>
            <div className={"W-full rounded-lg bg-white shadow-all p-5 space-y-3"}>

                {userBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value &&
                    <div className={"flex flex-col md:flex-row items-center md:items-start justify-start gap-5"}>
                        <div className={"flex flex-col items-center gap-3"}>
                            <div className={"w-32 aspect-square overflow-hidden rounded-full"}>
                                <img
                                    src={userBookingDetail?.data?.result?.appointment?.user?.profilePicUrl}
                                    alt={userBookingDetail?.data?.result?.appointment?.user?.firstName}
                                    className={"w-full h-full object-cover"}
                                />
                            </div>
                            <span className={"font-bodyPri font-medium text-base text-text-800"}>
                                {userBookingDetail?.data?.result?.appointment?.user?.firstName + " " + userBookingDetail?.data?.result?.appointment?.user?.lastName?.charAt(0)}
                            </span>
                        </div>
                        <div className={"space-y-3"}>
                            <div className={"flex flex-col items-start justify-between gap-3"}>
                                <span className={"font-bodyPri font-medium text-md text-text-900 line-clamp-1"}>
                                    {userBookingDetail?.data?.result?.appointment?.title}
                                </span>
                                <span className={"font-bodyPri font-normal text-base text-text-800 line-clamp-2"}>
                                    {userBookingDetail?.data?.result?.appointment?.subtitle}
                                </span>
                            </div>
                            <div className={"flex items-center gap-3"}>
                                <IoMdPricetag className={"text-xl text-text-700"} />
                                <span className={"font-bodyPri font-normal text-base text-text-900"}>
                                    {(userBookingDetail?.data?.result?.bookingPrice?.discountPct === 100
                                        || userBookingDetail?.data?.result?.bookingPrice?.priceModel === bookingRegularPriceModelEnum.FREE.value)
                                        ? bookingRegularPriceModelEnum.FREE.label
                                        : (currencyRateConversion(locals?.currencyCode, userBookingDetail?.data?.result?.bookingPrice?.amount)
                                            + ` ${bookingRegularPriceModelEnum[userBookingDetail?.data?.result?.bookingPrice?.priceModel?.toUpperCase()]?.label}`)
                                    }
                                </span>
                                <span className={"font-bodyPri font-normal text-base text-text-900 space-x-2"}>
                                    <del className={"text-text-800"}>{currencyRateConversion(locals?.currencyCode, userBookingDetail?.data?.result?.bookingPrice?.price)}</del>
                                    <span>{userBookingDetail?.data?.result?.bookingPrice?.discountPct + "% off"}</span>
                                </span>
                            </div>
                            <div className={"flex items-center gap-3"}>
                                <MdAccessTimeFilled className={"text-text-700 text-xl"} />
                                <span className={"font-bodyPri font-normal text-base text-text-900"}>
                                    {`${userBookingDetail?.data?.result?.bookingPrice?.duration} min | ${userBookingDetail?.data?.result?.bookingPrice?.sessionCount} session(s)`}
                                </span>
                            </div>
                        </div>
                    </div>
                }
                {userBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum.COURSE.value &&
                    <div className={"flex flex-col md:flex-row items-center md:items-start justify-start gap-5"}>
                        <div className={"w-72 md:w-60 lg:w-52 aspect-video overflow-hidden rounded-md"}>
                            <img
                                src={userBookingDetail?.data?.result?.course?.thumbnailPicUrl}
                                alt={userBookingDetail?.data?.result?.course?.title}
                                className={"w-full h-full object-cover"}
                            />
                        </div>
                        <div className={"space-y-3"}>
                            <div className={"flex flex-col items-start justify-between gap-3"}>
                                <span className={"font-bodyPri font-medium text-md text-text-900 line-clamp-1"}>
                                    {userBookingDetail?.data?.result?.course?.title}
                                </span>
                                <span className={"font-bodyPri font-normal text-base text-text-800 line-clamp-2"}>
                                    {userBookingDetail?.data?.result?.course?.subTitle}
                                </span>
                            </div>
                            <div className={"flex items-center gap-3"}>
                                <IoMdPricetag className={"text-xl text-text-700"} />
                                <span className={"font-bodyPri font-normal text-base text-text-900"}>
                                    {(userBookingDetail?.data?.result?.bookingPrice?.discountPct === 100
                                        || userBookingDetail?.data?.result?.bookingPrice?.priceModel === bookingRegularPriceModelEnum.FREE.value)
                                        ? bookingRegularPriceModelEnum.FREE.label
                                        : (currencyRateConversion(locals?.currencyCode, userBookingDetail?.data?.result?.bookingPrice?.amount)
                                            + ` ${coursePricingModelTypeEnum[userBookingDetail?.data?.result?.bookingPrice?.priceModel?.toUpperCase()]?.label}`)
                                    }
                                </span>
                                <span className={"font-bodyPri font-normal text-base text-text-900 space-x-2"}>
                                    <del className={"text-text-800"}>{currencyRateConversion(locals?.currencyCode, userBookingDetail?.data?.result?.bookingPrice?.price)}</del>
                                    <span>{userBookingDetail?.data?.result?.bookingPrice?.discountPct + "% off"}</span>
                                </span>
                            </div>
                            {userBookingDetail?.data?.result?.course?.type === courseType?.ONE_ON_ONE.value &&
                                <div className={"flex items-center gap-3"}>
                                    <MdAccessTimeFilled className={"text-text-700 text-xl"} />
                                    <span className={"font-bodyPri font-normal text-base text-text-900"}>
                                        {`${userBookingDetail?.data?.result?.bookingPrice?.duration} min | ${userBookingDetail?.data?.result?.bookingPrice?.sessionCount} session(s)`}
                                    </span>
                                </div>
                            }
                            {userBookingDetail?.data?.result?.course?.type === courseType?.GROUP.value &&
                                <div className={"flex items-center gap-3"}>
                                    <MdAccessTimeFilled className={"text-text-700 text-xl"} />
                                    <span className={"font-bodyPri font-normal text-base text-text-900"}>
                                        {`${userBookingDetail?.data?.result?.bookingPrice?.duration} min | ${userBookingDetail?.data?.result?.course?.basicDetail?.sessionCount} session(s) ${userBookingDetail?.data?.result?.bookingSchedule?.isRecurring === "recurring" ? "per week" : ""}`}
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                }

                <hr className={"h-0.5 w-full bg-background-medium rounded-full"} />

                {userBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum.COURSE.value
                    && userBookingDetail?.data?.result?.course?.type === courseType.ONE_ON_ONE.value &&
                    <div className={"flex flex-col items-center justify-center gap-10"}>
                        <div className={"flex flex-col items-center gap-3"}>
                            <span className={"font-bodyPri font-normal text-base text-text-600"}>{"How long? choose one"}</span>
                            <div className={"flex items-center justify-center gap-3"}>
                                {[30, 60, 90, 120].map((duration, index) => (
                                    <div key={index} className={cn(
                                        "flex items-center gap-1 px-2 py-1 rounded-lg font-bodyPri font-normal text-sm text-primary-dark bg-primary-light cursor-pointer whitespace-nowrap",
                                        (duration === userBookingDetail?.data?.result?.bookingPrice?.duration) && "!text-text-50 !bg-primary-dark"
                                    )} onClick={() => dispatcher(updateUserBookingDetail(userBookingDetail?.data?.result?.id, user?.user?.userId, { timeZone: timeZone, duration: duration }))}>
                                        <BiTimeFive />
                                        <span>{`${duration} min`}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={"flex flex-col items-center gap-3"}>
                            <span className={"font-bodyPri font-normal text-base text-text-600"}>{"How many sessions?"}</span>
                            <div className={"flex items-center justify-center gap-3"}>
                                {[1, 5].map((sessionCount, index) => {
                                    let amount = userBookingDetail?.data?.result?.bookingPrice?.amount
                                    let netAmount = (amount / 4 * userBookingDetail?.data?.result?.bookingPrice?.duration / 15) * sessionCount
                                    let totalAmount = netAmount - (netAmount * sessionCount >= 5 ? (userBookingDetail?.data?.result?.course?.volumeDiscountPct / 100) : 0)
                                    return (
                                        <div key={index} className={"flex flex-col items-center gap-2"}>
                                            <div className={cn(
                                                "px-2 py-1 rounded-lg font-bodyPri font-normal text-sm text-primary-dark bg-primary-light cursor-pointer whitespace-nowrap",
                                                (sessionCount === userBookingDetail?.data?.result?.bookingPrice?.sessionCount) && "!text-text-50 !bg-primary-dark"
                                            )} onClick={() => dispatcher(updateUserBookingDetail(userBookingDetail?.data?.result?.id, user?.user?.userId, { timeZone: timeZone, sessionCount: sessionCount }))}>
                                                <span>{`${sessionCount} session(s)`}</span>
                                            </div>
                                            <span className={cn("font-bodyPri font-normal text-xs text-text-800", (sessionCount === userBookingDetail?.data?.result?.bookingPrice?.sessionCount) && "!text-primary-dark")}>
                                                {currencyRateConversion(locals?.currencyCode, totalAmount)}
                                            </span>
                                            <span className={cn("font-bodyPri font-normal text-xs text-text-800", (sessionCount === userBookingDetail?.data?.result?.bookingPrice?.sessionCount) && "!text-primary-dark")}>
                                                {userBookingDetail?.data?.result?.bookingPrice?.discountPct + "% off" + (sessionCount >= 5 ? " + " + userBookingDetail?.data?.result?.course?.volumeDiscountPct + "% off" : "")}
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                }
                <div className={"space-y-3"}>
                    <div className={"flex items-center justify-between"}>
                        <span className={"font-bodyPri font-medium text-md text-text-800"}>
                            {`Date and time (${dayjs().tz(timeZone).format("z")})`}
                        </span>
                        {((userBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value)
                            || ((userBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum.COURSE.value)
                                && (userBookingDetail?.data?.result?.course?.type === courseType.ONE_ON_ONE.value))) &&
                            <FiEdit onClick={() => addCheckoutSlot()} className={"text-xl text-text-700 hover:text-text-800 cursor-pointer"} />
                        }
                    </div>

                    <div className={"flex flex-col gap-2"}>
                        {userBookingDetail?.data?.result?.bookingSchedule?.type === courseType.GROUP.value && userBookingDetail?.data?.result?.bookingSchedule?.startDateTime &&
                            <div className={"flex flex-row items-start justify-start gap-2"}>
                                <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                    {dayjs(userBookingDetail?.data?.result?.bookingSchedule?.startDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format('ddd, MMM DD YYYY')}
                                </span>
                                {userBookingDetail?.data?.result?.bookingSchedule?.endType === availabilityEndTypeEnum.ON_DATE.value
                                    && userBookingDetail?.data?.result?.bookingSchedule?.endDateTime &&
                                    <>
                                        <span className={"font-bodyPri font-medium text-base text-text-700"}>{"-"}</span>
                                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                            {dayjs(userBookingDetail?.data?.result?.bookingSchedule?.endDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format('ddd, MMM DD YYYY')}
                                        </span>
                                    </>
                                }
                                {userBookingDetail?.data?.result?.bookingSchedule?.endType === availabilityEndTypeEnum.WEEKLY_CYCLE.value &&
                                    <>
                                        <span className={"font-bodyPri font-medium text-base text-text-700"}>{"-"}</span>
                                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                            {`Repeat ${userBookingDetail?.data?.result?.bookingSchedule?.weeklyCycle} weeks`}
                                        </span>
                                    </>
                                }
                                {userBookingDetail?.data?.result?.bookingSchedule?.endType === availabilityEndTypeEnum.FOREVER.value &&
                                    <>
                                        <span className={"font-bodyPri font-medium text-base text-text-700"}>{"-"}</span>
                                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                            {"Never Ends"}
                                        </span>
                                    </>
                                }
                            </div>
                        }
                        {(userBookingDetail?.data?.result?.bookingSchedule?.isRecurring === availabilityIsRecurringEnum.NON_RECURRING.value) &&
                            <div className={cn(
                                "grid grid-flow-col place-content-start gap-2 px-1 pb-3 overflow-hidden overflow-x-scroll",
                                "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                            )}>
                                {userBookingDetail?.data?.result?.bookingSchedule?.schedule?.map((dateTimeSlot, index) => (
                                    <DateTimeItems key={index} dateTimeSlot={dateTimeSlot} />
                                ))}
                            </div>
                        }
                        {(userBookingDetail?.data?.result?.bookingSchedule?.isRecurring === availabilityIsRecurringEnum.RECURRING.value) &&
                            <div className={cn(
                                "grid grid-flow-col place-content-start gap-2 px-1 pb-3 overflow-hidden overflow-x-scroll",
                                "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                            )}>
                                {Object.keys(userBookingDetail?.data?.result?.bookingSchedule?.schedule).map((localDay, index) => (
                                    <DayItems key={index} localDay={localDay} timeSlots={userBookingDetail?.data?.result?.bookingSchedule?.schedule[localDay]} />
                                ))}
                            </div>
                        }
                        {!userBookingDetail?.data?.result?.bookingSchedule?.schedule &&
                            <div className={"w-fit mx-auto mt-3 px-5 py-2 rounded-md bg-background-darkLight"}>
                                <span className={"font-bodyPri font-normal text-lg text-text-700 text-center"}>{"Slot doesn't exists"}</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingItemSection