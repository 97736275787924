import { memo } from 'react';

import { motion } from "framer-motion";

import RefreshButton from './RefreshButton';

import { cn } from 'utils/cn.utils';

const LmsRefreshAndAddBtn = memo(({
    btnName = "",
    buttonClassName = "",
    onHandleRefresh,
    onHandleAddBtn,
}) => {

    return (
        <div className={"flex items-center justify-start gap-3"}>
            <RefreshButton onHandleRefreshButton={onHandleRefresh} />
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.3 }}
                className={cn(
                    "py-2 px-3 md:px-5 flex items-center justify-center text-white text-sm md:text-base ease-in-out duration-200 bg-primary-dark hover:bg-secondary-dark rounded",
                    "font-bodyPri font-normal text-sm sm:text-base",
                    buttonClassName
                )}
                onClick={onHandleAddBtn}
            >
                <span className={""}>
                    {btnName}
                </span>
            </motion.button>
        </div>
    )
});

export default LmsRefreshAndAddBtn;