import tw, { styled } from "twin.macro";

export const StyledChatList = styled.div`
  ${tw`
    mt-0.5 px-5 height[80%] overflow-hidden overflow-y-auto
    scrollbar scrollbar-track-white scrollbar-track-rounded-full scrollbar-thumb-rounded-full
    hover:(scrollbar-thumb-divider-lightDark scrollbar-track-white)
  `}
  ::-webkit-scrollbar {
    ${tw`w-1.5`}
  }
`;
