import { useEffect } from 'react';

import ComponentLoader from 'components/loader/ComponentLoader';

import OrderItemProfileSection from './components/OrderItemProfileSection';
import OrderItemProductItemsSection from './components/OrderItemProductItemsSection';
import OrderItemDetailSection from './components/OrderItemDetailSection';

import { getUserOrderItemDetail } from 'redux/order/order.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetUserOrderItemDetail, resetUserOrderItemDetailPayload } from 'redux/order/order.slice';

const OrderSideOverContent = () => {
    const { user } = useAppState((state) => state.user)
    const { userOrderItemDetail } = useAppState((state) => state.order)

    const dispatcher = useAppDispatcher()

    useEffect(() => {

        return () => {
            dispatcher(resetUserOrderItemDetail())
            dispatcher(resetUserOrderItemDetailPayload())
        }
    }, [])

    useEffect(() => {
        if (userOrderItemDetail?.payload?.orderItemId) {
            dispatcher(getUserOrderItemDetail(userOrderItemDetail?.payload?.orderItemId, user?.user?.userId))
        }
    }, [userOrderItemDetail?.payload?.orderItemId])

    return (
        <div className={"p-5 h-screen sm:px-10 max-w-2xl md:max-w-xl lg:max-w-lg"}>
            <ComponentLoader isLoading={userOrderItemDetail?.isLoading} />
            {(userOrderItemDetail?.data && !userOrderItemDetail?.isLoading) && (
                <div className={"w-full flex flex-col gap-8"}>
                    <OrderItemProfileSection />
                    {(userOrderItemDetail?.data?.order_item_product_prices?.length > 0) &&
                        <div className={"w-full flex flex-col gap-3"}>
                            {userOrderItemDetail?.data?.order_item_product_prices?.map((orderItemProductPrice, index) => (
                                <OrderItemDetailSection
                                    key={index}
                                    orderItemProductPrice={orderItemProductPrice}
                                />
                            ))}
                        </div>
                    }
                    {(userOrderItemDetail?.data?.order?.order_items?.length > 0) &&
                        <div className={"w-full flex flex-col gap-3"}>
                            <div className={"w-full h-0.5 bg-divider-medium"} />
                            <span className={"font-bodyPri font-medium text-text-900 text-md tracking-wide"}>
                                {"Other items in this order"}
                            </span>
                            <div className={"w-full h-0.5 bg-divider-medium"} />
                            {userOrderItemDetail?.data?.order?.order_items?.map((orderProductItem, index) => (
                                <OrderItemProductItemsSection key={index} orderProductItem={orderProductItem} />
                            ))}
                        </div>
                    }
                </div>
            )}
            {(userOrderItemDetail?.message) &&
                <div className={"w-full h-full flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-base"}>
                        {userOrderItemDetail?.message}
                    </span>
                </div>
            }
        </div>
    )
}

export default OrderSideOverContent;