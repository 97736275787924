import { createSlice } from "@reduxjs/toolkit";

import { PRODUCT_INITIAL_STATE } from "./product.initialState";

const product = createSlice({
    name: "product",
    initialState: PRODUCT_INITIAL_STATE,
    reducers: {
        // product detail
        setProductDetailLoading: (state, { payload }) => {
            state.productDetail.isLoading = payload
        },
        setProductDetailData: (state, { payload }) => {
            state.productDetail.data = payload
            state.productDetail.message = PRODUCT_INITIAL_STATE.productDetail.message
        },
        setProductDetailMessage: (state, { payload }) => {
            state.productDetail.message = payload
            state.productDetail.data = PRODUCT_INITIAL_STATE.productDetail.data
        },
        resetProductDetail: (state) => {
            state.productDetail = PRODUCT_INITIAL_STATE.productDetail
        },

        // add product detail
        setAddProductDetailLoading: (state, { payload }) => {
            state.addProductDetail.isLoading = payload
        },
        setAddProductDetailData: (state, { payload }) => {
            state.addProductDetail.data = payload
            state.addProductDetail.message = PRODUCT_INITIAL_STATE.addProductDetail.message
        },
        setAddProductDetailMessage: (state, { payload }) => {
            state.addProductDetail.message = payload
            state.addProductDetail.data = PRODUCT_INITIAL_STATE.addProductDetail.data
        },
        resetAddProductDetail: (state) => {
            state.addProductDetail = PRODUCT_INITIAL_STATE.addProductDetail
        },

        // add product detail payload
        setAddProductDetailPayload: (state, { payload }) => {
            state.addProductDetail.payload = payload
        },
        resetAddProductDetailPayload: (state) => {
            state.addProductDetail.payload = PRODUCT_INITIAL_STATE.addProductDetail.payload
        },

        // modify product detail
        setModifyProductDetailLoading: (state, { payload }) => {
            state.modifyProductDetail.isLoading = payload
        },
        setModifyProductDetailData: (state, { payload }) => {
            state.modifyProductDetail.data = payload
            state.modifyProductDetail.message = PRODUCT_INITIAL_STATE.modifyProductDetail.message
        },
        setModifyProductDetailMessage: (state, { payload }) => {
            state.modifyProductDetail.message = payload
            state.modifyProductDetail.data = PRODUCT_INITIAL_STATE.modifyProductDetail.data
        },
        resetModifyProductDetail: (state) => {
            state.modifyProductDetail = PRODUCT_INITIAL_STATE.modifyProductDetail
        },

        // add product price detail
        setAddProductPriceDetailLoading: (state, { payload }) => {
            state.addProductPriceDetail.isLoading = payload
        },
        setAddProductPriceDetailData: (state, { payload }) => {
            state.addProductPriceDetail.data = payload
            state.addProductPriceDetail.message = PRODUCT_INITIAL_STATE.addProductPriceDetail.message
        },
        setAddProductPriceDetailMessage: (state, { payload }) => {
            state.addProductPriceDetail.message = payload
            state.addProductPriceDetail.data = PRODUCT_INITIAL_STATE.addProductPriceDetail.data
        },
        resetAddProductPriceDetail: (state) => {
            state.addProductPriceDetail = PRODUCT_INITIAL_STATE.addProductPriceDetail
        },

        // add product price detail payload
        setAddProductPriceDetailPayload: (state, { payload }) => {
            state.addProductPriceDetail.payload = payload
        },
        resetAddProductPriceDetailPayload: (state) => {
            state.addProductPriceDetail.payload = PRODUCT_INITIAL_STATE.addProductPriceDetail.payload
        },

        // modify product price detail
        setModifyProductPriceDetailLoading: (state, { payload }) => {
            state.modifyProductPriceDetail.isLoading = payload
        },
        setModifyProductPriceDetailData: (state, { payload }) => {
            state.modifyProductPriceDetail.data = payload
            state.modifyProductPriceDetail.message = PRODUCT_INITIAL_STATE.modifyProductPriceDetail.message
        },
        setModifyProductPriceDetailMessage: (state, { payload }) => {
            state.modifyProductPriceDetail.message = payload
            state.modifyProductPriceDetail.data = PRODUCT_INITIAL_STATE.modifyProductPriceDetail.data
        },
        resetModifyProductPriceDetail: (state) => {
            state.modifyProductPriceDetail = PRODUCT_INITIAL_STATE.modifyProductPriceDetail
        },

        // add product slug detail
        setAddProductSlugDetailLoading: (state, { payload }) => {
            state.addProductSlugDetail.isLoading = payload
        },
        setAddProductSlugDetailMessage: (state, { payload }) => {
            state.addProductSlugDetail.message = payload
        },
        resetAddProductSlugDetail: (state) => {
            state.addProductSlugDetail = PRODUCT_INITIAL_STATE.addProductSlugDetail
        },

        // update product slug detail
        setModifyProductSlugDetailLoading: (state, { payload }) => {
            state.modifyProductSlugDetail.isLoading = payload
        },
        setModifyProductSlugDetailMessage: (state, { payload }) => {
            state.modifyProductPriceDetail.message = payload
        },
        resetModifyProductSlugDetail: (state) => {
            state.modifyProductSlugDetail = PRODUCT_INITIAL_STATE.modifyProductSlugDetail
        },
    }
})

export const {
    setProductDetailLoading,
    setProductDetailData,
    setProductDetailMessage,
    resetProductDetail,

    setAddProductDetailLoading,
    setAddProductDetailData,
    setAddProductDetailMessage,
    resetAddProductDetail,
    setAddProductDetailPayload,
    resetAddProductDetailPayload,

    setModifyProductDetailLoading,
    setModifyProductDetailData,
    setModifyProductDetailMessage,
    resetModifyProductDetail,

    setAddProductPriceDetailLoading,
    setAddProductPriceDetailData,
    setAddProductPriceDetailMessage,
    resetAddProductPriceDetail,

    setAddProductPriceDetailPayload,
    resetAddProductPriceDetailPayload,

    setModifyProductPriceDetailLoading,
    setModifyProductPriceDetailData,
    setModifyProductPriceDetailMessage,
    resetModifyProductPriceDetail,

    setAddProductSlugDetailLoading,
    setAddProductSlugDetailMessage,
    resetAddProductSlugDetail,

    setModifyProductSlugDetailLoading,
    setModifyProductSlugDetailMessage,
    resetModifyProductSlugDetail
} = product.actions;

export default product.reducer;