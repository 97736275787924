import { cn } from "utils/cn.utils"
import { memo } from "react"

import { lmsResourceTypeEnum, resourceFileTypeEnum } from "redux/edulyteLms/lmsResource/lmsResource.const"

import { getYoutubePlayId } from "utils/generators.utils"

const LectureResource = memo(({ resource }) => {

  if (
    !resource ||
    !resource?.resource ||
    (resource?.type === lmsResourceTypeEnum.YOUTUBE.value && !resource?.resource?.resource_link) ||
    ((resource?.type === lmsResourceTypeEnum.FILE.value && resource?.resource?.type === resourceFileTypeEnum.VIDEO.value) && !(
      resource?.resource?.file ||
      resource?.resource?.file?.file_url
    ))
  ) {
    return (
      <div className={"flex items-center justify-center mt-20 md:sticky md:top-16"}>
        <span className={"font-bodyPri font-semibold text-2xl text-test-800"}>
          {"Video Resource not found!"}
        </span>
      </div>
    )
  }

  return (
    <div className={"w-full aspect-video overflow-hidden rounded-lg md:sticky md:top-16"}>
      {(resource?.type === lmsResourceTypeEnum.YOUTUBE.value) && (
        <iframe
          title={resource?.title}
          src={`https://www.youtube.com/embed/${getYoutubePlayId(resource?.resource?.resource_link)}?autoplay=1`}
          className={"w-full h-full object-cover"}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
        >
        </iframe>
      )}
      {(resource?.type === lmsResourceTypeEnum.FILE.value && resource?.resource?.type === resourceFileTypeEnum.VIDEO.value) && (
        <video
          title={resource?.title}
          src={resource?.resource?.file?.file_url}
          className={"w-full h-full object-contain"}
          allowFullScreen={true}
          controls
          autoPlay
        >
        </video>
      )}
    </div>
  )
})

const LectureView = ({ lecture }) => {

  const descriptionText = new DOMParser()?.parseFromString(`<div>${lecture?.description || ""}</div>`, "text/html")?.querySelector("div")?.textContent

  return (
    <div className={"space-y-5 py-5 px-8"}>
      <div className={"flex flex-col items-center gap-2"}>
        <h2 className={"font-bodyPri font-bold text-3xl text-900 text-center"}>
          {lecture?.title}
        </h2>
        <hr className={"h-0.5 w-1/2 mx-auto bg-text-300 mt-2"} />
      </div>
      <div className={"grid grid-cols-1 md:grid-cols-2 gap-5"}>
        <div className={"space-y-5 order-2 md:order-1"}>
          <h4 className={"font-body font-medium text-2xl text-text-900 text-center md:text-left"}>
            {"Description"}
          </h4>
          <div dangerouslySetInnerHTML={{ __html: lecture?.description || "<div>No Content</div>" }}></div>
        </div>
        <div className={"relative order-1 md:order-2 col-span-1"}>
          <LectureResource resource={lecture?.resource} />
        </div>
      </div>
    </div>
  )
}

export default LectureView