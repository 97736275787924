import { useState, useEffect } from "react";

const initial = {
    icon: "https://www.edulyte.com/wp-content/uploads/2021/08/Edulyte-Favicon-100x100.png",
    title: "Edulyte Online Tutoring - Personalised, Flexible Live Classes 🙂",
    description: "Live Online Learning - Personalised, flexible and effective tutoring for languages, Exam prep, technology, hobbies & more! Group or One-on-One."
}

export const useTitle = (state = initial) => {
    const [title, setTitle] = useState<any>(state);

    useEffect(() => {
        if (title) {
            window.document.getElementsByTagName("link")[1].href = title.icon
            window.document.getElementsByTagName("link")[2].href = title.icon
            window.document.getElementsByTagName("link")[3].href = title.icon
            window.document.title = title.title.charAt(0).toUpperCase() + title.title.slice(1)
            window.document.getElementsByTagName("meta")[4].content = title.description
        }
    }, [title]);

    return [title, setTitle];
};
