import { SubscriptionPageState } from "./types";

export const SUBSCRIPTION_INITIAL_DATA: SubscriptionPageState = {
  FAQs: [
    {
      id: 1,
      question:
        "What is the difference between a free trial and a paid subscription?",
      answer:
        "A free trial is a subscription that is free to use for a limited period of time. A paid subscription is a subscription that is charged to your credit card. You can cancel your free trial at any time. You can cancel your paid subscription at any time.",
    },
  ],
  billingInformation: {
    name: "John Smith",
    company: "Telegram Inc.",
    billingAddress: "123 Main St, Vancouver, BC V6B 3A1",
    GST: "None",
    cardInformation: {
      cardNumber: "XXXX XXXX XXXX 9022",
      expiryDate: "08/25",
    },
  },
  referal: {
    url: "https://example.com?referal=12345",
    code: "12345",
    shareContent: "Become a part of our community",
  },
  subscriptions: [
    {
      id: 1,
      amount: "$90.00",
      nextPayment: "23/05/2022",
      status: "Active",
      paymentInvoice: "12345",
      planName: "Telegram Pro",
      paymentMethod: "VISA Card",
    },
    {
      id: 2,
      amount: "Free",
      nextPayment: "-",
      status: "Active",
      paymentInvoice: "12345",
      planName: "Free ",
      paymentMethod: "-",
    },
  ],
  _meta: {
    showPlansModal: false,
    showInvoiceModal: {
      invoiceId: "",
      showModal: false,
    },
  },
};

export const MOCK_FAQS = [
  {
    question: "Lorem ipsum dolor sit amet ?",
    answer:
      "Quae enim cupiditates a natura proficiscuntur, facile explentur sine ulla iniuria, quae autem inanes sunt, iis parendum non est.",
  },
  {
    question: "Lorem ipsum dolor sit amet ?",
    answer:
      "Quae enim cupiditates a natura proficiscuntur, facile explentur sine ulla iniuria, quae autem inanes sunt, iis parendum non est.",
  },
  {
    question: "Lorem ipsum dolor sit amet ?",
    answer:
      "Quae enim cupiditates a natura proficiscuntur, facile explentur sine ulla iniuria, quae autem inanes sunt, iis parendum non est.",
  },
  {
    question: "Lorem ipsum dolor sit amet ?",
    answer:
      "Quae enim cupiditates a natura proficiscuntur, facile explentur sine ulla iniuria, quae autem inanes sunt, iis parendum non est.",
  },
  {
    question: "Lorem ipsum dolor sit amet ?",
    answer:
      "Quae enim cupiditates a natura proficiscuntur, facile explentur sine ulla iniuria, quae autem inanes sunt, iis parendum non est.",
  },
];
