import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "./evently.const";

class EventlyService {
    getUserEventlyList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_EVENTLY_LIST, params),
            { params: query }
        )

        return response;
    }

    getUserEventlyDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_EVENTLY_DETAIL, params)
        )

        return response;
    }

    createUserEventlyDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_USER_EVENTLY_DETAIL),
            body
        )

        return response;
    }

    updateUserEventlyDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_USER_EVENTLY_DETAIL, params),
            body
        )

        return response;
    }

    deleteUserEventlyDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_USER_EVENTLY_DETAIL, params)
        )

        return response;
    }

    // evently events detail
    updateEventlyEventsDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_EVENTLY_EVENTS_DETAIL, params),
            body
        )

        return response;
    }

    // evently invitation list
    getEventlyInvitationList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_EVENTLY_INVITATION_LIST),
            { params: query }
        )

        return response;
    }

    // create evently invitation detail
    createSendEventlyInvitationDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_SEND_EVENTLY_INVITATION_DETAIL),
            body
        )

        return response;
    }

    getValidateEventlyInvitationDetail = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            url.GET_VALIDATE_EVENTLY_INVITATION_DETAIL,
            { params: query }
        );

        return response;
    }

    getAcceptAndDeclineEventlyInvitationDetail = async ({ body, query }) => {
        const response = await baseAuthApiInstance().post(
            url.GET_ACCEPT_AND_DECLINE_EVENTLY_INVITATION_DETAIL,
            body,
            { params: query }
        )

        return response;
    }

    // delete evently invitation detail
    deleteEventlyInvitationDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_EVENTLY_INVITATION_DETAIL, params)
        )

        return response;
    }
}

export default new EventlyService();