import { useCallback, useState, memo } from 'react';

import { IoIosCheckmark } from 'react-icons/io';
import { MdOutlineMail } from 'react-icons/md';

import FloatingLabelSelect from 'components/floating/floatingLabelSelect';
import FloatingLabelInput from 'components/floating/floatingLabelInput';

import SaveAndCancelBtn from 'components/modals/crmModals/commonComponents/SaveAndCancelBtn';
import RadioInput from '../../commonComponents/RadioInput';

import { crmContactLabelsEnum } from 'redux/crm/crm.const';

import { validateEmail } from "utils/validation-functions";

const ContactEmailItem = memo(({
    index = 0,
    isLoading = false,
    isShowSaveBtn = false,
    isShowCancelBtn = false,
    emailItem = null,
    onHandleChangeEmailDetail,
    onHandlePrimaryEmailDetail,
    onHandleSaveEmailDetail = () => { },
    onHandleDeleteEmailDetail
}) => {
    const [invalidEmailDetail, setInvalidEmailDetail] = useState({
        emailId: null,
        isInvalidEmail: false
    })

    const onHandleUpdateEmail = useCallback((event) => {
        const newEmail = event.target.value
        onHandleChangeEmailDetail(emailItem?.id, { ...emailItem, [event.target.name]: newEmail })
        if (newEmail && !validateEmail(newEmail)) {
            setInvalidEmailDetail({
                emailId: emailItem?.id,
                isInvalidEmail: true
            })
        } else {
            setInvalidEmailDetail(null)
        }
    }, [invalidEmailDetail, emailItem])

    return (
        <div className={"w-full flex flex-col sm:flex-row gap-5 sm:gap-3 items-center border px-3 py-5 rounded shadow-sm"}>
            <div className={"w-full sm:w-fit flex justify-between gap-3"}>
                <div className={"flex gap-3 mt-2"}>
                    <div className="flex text-lg text-text-700">
                        <MdOutlineMail />
                    </div>
                    <div className={"flex sm:hidden"}>
                        <RadioInput
                            tooltipContent={emailItem?.is_primary ? "Default" : "Make Default"}
                            isInputChecked={emailItem?.is_primary}
                            onHandleClickInput={(event) => onHandlePrimaryEmailDetail(emailItem?.id, {
                                ...emailItem,
                                is_primary: event?.target?.checked
                            })}
                        />
                    </div>
                </div>
                <div className={"flex sm:hidden mt-2"}>
                    <SaveAndCancelBtn
                        isLoading={isLoading}
                        isShowSaveBtn={isShowSaveBtn}
                        isShowCancelBtn={isShowCancelBtn}
                        saveBtnTooltip={'Save Email'}
                        cancelBtnTooltip={"Remove Email"}
                        isBtnDisable={invalidEmailDetail?.isInvalidEmail || !emailItem?.email}
                        onHandleSaveBtn={() => onHandleSaveEmailDetail(emailItem)}
                        onHandleCancelBtn={() => onHandleDeleteEmailDetail(emailItem?.id)}
                    />
                </div>
            </div>
            <div className={"hidden sm:flex mt-2.5"}>
                <RadioInput
                    tooltipContent={emailItem?.is_primary ? "Default" : "Make Default"}
                    isInputChecked={emailItem?.is_primary}
                    onHandleClickInput={(event) => onHandlePrimaryEmailDetail(emailItem?.id, {
                        ...emailItem,
                        is_primary: event?.target?.checked
                    })}
                />
            </div>
            <div className={"w-full flex items-center gap-3"}>
                <FloatingLabelInput
                    labelItem={`email_email${index}${emailItem?.id}`}
                    onChange={onHandleUpdateEmail}
                    value={emailItem?.email}
                    label={"Email"}
                    inputName={"email"}
                    isInvalidInput={(invalidEmailDetail?.emailId === emailItem?.id) && invalidEmailDetail?.isInvalidEmail}
                    inputClassName={"disabled:px-2 disabled:bg-text-200 disabled:cursor-not-allowed"}
                />
            </div>
            <div className="w-full sm:w-72">
                <FloatingLabelSelect
                    labelItem={`email_type${index}${emailItem?.id}`}
                    options={Object.values(crmContactLabelsEnum)}
                    onHandleSelect={(selectedOption) => onHandleChangeEmailDetail(emailItem?.id, { ...emailItem, label: selectedOption })}
                    value={emailItem?.label?.label}
                    OptionChild={({ option }) => (
                        <div className="bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between">
                            {option.label}
                            {option.value === emailItem?.label?.value && <IoIosCheckmark size={20} color="green" />}
                        </div>
                    )}
                    label={"Type"}
                    dropDownContainerClassName={"w-full"} />
            </div>
            <div className={"hidden sm:flex mt-2 h-fit"}>
                <SaveAndCancelBtn
                    isLoading={isLoading}
                    isShowSaveBtn={isShowSaveBtn}
                    isShowCancelBtn={isShowCancelBtn}
                    saveBtnTooltip={'Save Email'}
                    cancelBtnTooltip={"Remove Email"}
                    isBtnDisable={invalidEmailDetail?.isInvalidEmail || !emailItem?.email}
                    onHandleSaveBtn={() => onHandleSaveEmailDetail(emailItem)}
                    onHandleCancelBtn={() => onHandleDeleteEmailDetail(emailItem?.id)}
                />
            </div>
        </div>
    )
})

export default ContactEmailItem;