import { useEffect } from 'react';
import { cn } from "utils/cn.utils";
import { useLocation } from 'react-router-dom';

import ComponentLoader from 'components/loader/ComponentLoader';

import WithdrawalItem from 'pages/auth/wallet/withdrawal/WithdrawalItem';

import { getMyWithdrawalListByUserId } from 'redux/withdrawal/withdrawal.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearMyWithdrawalList } from 'redux/withdrawal/withdrawal.slice';

import { dayjs } from "utils/dateTime.utils";
import { generateQueryParams } from 'utils/generators.utils';

const RECORDS = 10

const WithdrawalListCard = () => {
  const { user } = useAppState((state) => state.user)
  const { myWithdrawalList } = useAppState((state) => state.withdrawal)

  const dispatcher = useAppDispatcher()
  const location = useLocation()

  useEffect(() => {
    const query = generateQueryParams(location.search)

    dispatcher(getMyWithdrawalListByUserId(user?.user?.userId, { page: query.pn || 1, records: RECORDS }))

    return () => {
      dispatcher(setClearMyWithdrawalList())
    }
  }, [])

  const sortWithdrawalList = (txn1, txn2) => {
    let dateTime1 = dayjs(txn1?.createdAt)
    let dateTime2 = dayjs(txn2?.createdAt)
    return (dateTime1.isBefore(dateTime2)) ? 1 : -1;
  }

  return (
    <div className="bg-white rounded-lg py-5 px-8 flex flex-col gap-5">
      <div className={cn(
        "flex flex-col gap-2 divide-y-2 h-[30rem] px-5 overflow-hidden overflow-y-auto",
        " scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full",
        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
      )}>
        <ComponentLoader isLoading={myWithdrawalList.isLoading} />
        {myWithdrawalList?.filteredMyWithdrawalList?.results?.slice()?.sort(sortWithdrawalList)?.map((withdrawalItem, index) => (
          <WithdrawalItem key={index} withdrawalItem={withdrawalItem} />
        ))}
        {(!myWithdrawalList?.filteredMyWithdrawalList || myWithdrawalList?.filteredMyWithdrawalList?.records === 0) &&
          <span className={"h-full flex items-center justify-center font-bodyPri font-medium text-lg text-text-500"}>
            {myWithdrawalList.errorMsg || "No withdrawals found!"}
          </span>
        }
      </div>
    </div>
  )
};

export default WithdrawalListCard;