import { toast } from "react-toastify";
import withdrawalService from "redux/withdrawal/withdrawal.service";

import {
    setMyWithdrawalListLoading,
    setMyWithdrawalList,
    setMyWithdrawalListErrorMsg,

    setUserWithdrawalListLoading,
    setUserWithdrawalList,
    setUserWithdrawalListErrorMsg,

    setAddUserWithdrawalDetailLoading,
    setAddUserWithdrawalDetail,
    setAddUserWithdrawalDetailErrorMsg
} from "redux/withdrawal/withdrawal.slice";

import { getMyProfile } from "redux/user/user.request";

export const getUserWithdrawalListByUserId = (userId, query) => async (dispatch) => {
    dispatch(setUserWithdrawalListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: { ...query }
        }
        const response = await withdrawalService.getUserWithdrawalListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserWithdrawalList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserWithdrawalListErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setUserWithdrawalListLoading(false))
    }
}

export const getMyWithdrawalListByUserId = (userId, query) => async (dispatch) => {
    dispatch(setMyWithdrawalListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: { ...query }
        }
        const response = await withdrawalService.getUserWithdrawalListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setMyWithdrawalList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setMyWithdrawalListErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setMyWithdrawalListLoading(false))
    }
}

export const createUserWithdrawalDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddUserWithdrawalDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await withdrawalService.createUserWithdrawalDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setAddUserWithdrawalDetail(response.data.data))
            dispatch(getMyWithdrawalListByUserId(user?.user?.userId, { page: 1, records: 10 }))
            dispatch(getMyProfile())
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserWithdrawalDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setAddUserWithdrawalDetailLoading(false))
    }
}