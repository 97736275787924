import { useCallback } from 'react';

import { BsInfoCircle } from 'react-icons/bs';

import MuiDatePicker from 'components/MUIComponents/MuiDatePicker';

import { availabilityDetailBtnConst } from "pages/auth/preferences/data";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModifyUserAvailabilityDetailPayload } from 'redux/availability/availability.slice';
import { availabilityEndTypeEnum } from 'redux/availability/availability.const';

import { cn } from 'utils/cn.utils';
import { dayjs, timeZone } from 'utils/dateTime.utils';

const StartEndDateSchedule = () => {
    const { userAvailabilityDetail, modifyUserAvailabilityDetail } = useAppState((s) => s.availability)

    const dispatcher = useAppDispatcher()

    const onHandleSelectEndType = useCallback((key, value) => {
        if (modifyUserAvailabilityDetail?.isLoading) return;

        dispatcher(setModifyUserAvailabilityDetailPayload({
            ...modifyUserAvailabilityDetail?.payload,
            [key]: value,
            endDateTime: userAvailabilityDetail?.data?.result?.endDateTime || null,
            weeklyCycle: userAvailabilityDetail?.data?.result?.weeklyCycle || null
        }))
    }, [modifyUserAvailabilityDetail, userAvailabilityDetail?.data?.result])

    const onHandleChangeDetail = useCallback((key, value) => {
        if (modifyUserAvailabilityDetail?.isLoading) return;

        dispatcher(setModifyUserAvailabilityDetailPayload({
            ...modifyUserAvailabilityDetail?.payload,
            [key]: value
        }))
    }, [modifyUserAvailabilityDetail])

    return (
        <div className={"w-full flex flex-col gap-y-5 sm:gap-y-10"}>
            <div className={"w-full flex flex-col gap-y-5 sm:flex-row sm:justify-start sm:gap-x-10 sm:gap-y-0"}>
                <span className={"font-bodyPri font-medium text-gray-900"}>
                    {`${availabilityDetailBtnConst.endType.label}:`}
                    <span className={"font-bodyPri font-normal text-red-500 text-sm sm:text-base"}>{"*"}</span>
                </span>
                <div className={"flex flex-col gap-y-5 sm:flex-row sm:items-center sm:justify-start sm:gap-x-10 sm:gap-y-0"}>
                    {Object.values(availabilityEndTypeEnum).map((endType, index) => (
                        <div
                            key={index}
                            className={"flex items-center justify-start gap-2 cursor-pointer"}
                            onClick={() => onHandleSelectEndType(availabilityDetailBtnConst.endType.key, endType?.value)}
                        >
                            <input
                                type={"radio"}
                                className={""}
                                name={availabilityDetailBtnConst.endType.key}
                                value={endType?.value}
                                checked={[endType?.value]?.includes(modifyUserAvailabilityDetail.payload.endType)}
                                disabled={modifyUserAvailabilityDetail?.isLoading}
                            />
                            <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                {endType?.label}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
            {[availabilityEndTypeEnum.ON_DATE.value]?.includes(modifyUserAvailabilityDetail.payload.endType) &&
            <div className={"space-y-2"}>
                <div className={"w-full md:w-[25rem]"}>
                    <MuiDatePicker
                        label={availabilityDetailBtnConst.endDateTime.label}
                        value={modifyUserAvailabilityDetail?.payload?.endDateTime ? dayjs(modifyUserAvailabilityDetail?.payload?.endDateTime).tz(timeZone) : null}
                        onChange={(date) => onHandleChangeDetail(availabilityDetailBtnConst.endDateTime.key, dayjs(date).tz(timeZone).format("YYYY-MM-DD"))}
                        slotProps={{ textField: { size: "small" } }}
                        disabled={modifyUserAvailabilityDetail?.isLoading}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "#9e9e9e",
                                    height: 50,
                                },
                                "&:hover fieldset": {
                                    borderColor: "#2196f3",
                                },
                            },
                            "& .MuiInputLabel-root": {
                                fontWeight: '700',
                                color: '#212121',
                            },
                        }}
                        format={"DD-MM-YYYY"}
                        color={"primary"}
                        disablePast={true}
                        helperText={"SelectDate"}
                        margin={"dense"}
                        minDate={dayjs().tz(timeZone)}
                    />
                </div>
                    <div className={"flex items-center justify-start gap-2"}>
                        <BsInfoCircle className={"text-text-700 text-lg"} />
                        <span className={"font-bodyPri font-normal text-text-700 text-sm"}>
                            {"You must have a session on the end date."}
                        </span>
                    </div>
                </div>
            }
            {[availabilityEndTypeEnum.WEEKLY_CYCLE.value]?.includes(modifyUserAvailabilityDetail.payload.endType) &&
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                        {"After"}
                    </span>
                    <input
                        type={"number"}
                        placeholder={"Enter weekly cycles"}
                        className={cn(
                            "w-24 px-3 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-900 text-lg",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        name={availabilityDetailBtnConst.weeklyCycle.key}
                        value={modifyUserAvailabilityDetail?.payload?.weeklyCycle ? modifyUserAvailabilityDetail?.payload?.weeklyCycle?.toString() : ""}
                        onChange={(event) => {
                            const weeklyCycleCount = Number(event.target.value)
                            if (!isNaN(weeklyCycleCount) && (event.target.value?.length < 3)) {
                                onHandleChangeDetail(availabilityDetailBtnConst.weeklyCycle.key, weeklyCycleCount)
                            }
                        }}
                        required={(modifyUserAvailabilityDetail?.payload?.endType === availabilityEndTypeEnum?.WEEKLY_CYCLE?.value) && true}
                        disabled={modifyUserAvailabilityDetail?.isLoading || (modifyUserAvailabilityDetail?.payload?.endType !== availabilityEndTypeEnum?.WEEKLY_CYCLE?.value) && true}
                    />
                    <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                        {availabilityEndTypeEnum?.WEEKLY_CYCLE?.label}
                    </span>
                </div>
            }
        </div>
    )
}

export default StartEndDateSchedule;