import { toast } from "react-toastify";

import availabilityService from 'redux/availability/availability.service';
import {
    setUserAvailabilityListLoading,
    setUserAvailabilityListData,
    setUserAvailabilityListMessage,

    setAddUserAvailabilityDetailLoading,
    setAddUserAvailabilityDetailData,
    setAddUserAvailabilityDetailMessage,

    setUserAvailabilityDetailLoading,
    setUserAvailabilityDetailData,
    setUserAvailabilityDetailMessage,

    setModifyUserAvailabilityDetailLoading,
    setModifyUserAvailabilityDetailMessage,

    setDestroyUserAvailabilityDetailLoading,
    setDestroyUserAvailabilityDetailData,
    setDestroyUserAvailabilityDetailMessage,

    setOffDayListLoading,
    setOffDayList,
    setOffDayListErrorMsg,

    setOffDayDetailLoading,
    setOffDayDetail,
    setOffDayDetailErrorMsg,

    setAddOffDayDetailLoading,
    setAddOffDayDetail,
    setAddOffDayDetailErrorMsg,

    setAvailableSlotDetailLoading,
    setAvailableSlotDetailData,
    setAvailableSlotDetailMessage,

    setAddAvailabilityDetailLoading,
    setAvailabilityDetail,
    setAddAvailabilityDetail,
    setAddAvailabilityDetailErrorMsg,
} from 'redux/availability/availability.slice';

import { timeZone } from "utils/dateTime.utils";

// new availability request
export const getUserAvailabilityList = (userId, query) => async (dispatch) => {
    dispatch(setUserAvailabilityListLoading(true));

    try {
        const requestData = {
            params: { userId: userId },
            query: query
        }
        const response = await availabilityService.getUserAvailabilityList(requestData)
        if (response.status === 200) {
            dispatch(setUserAvailabilityListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setUserAvailabilityListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setUserAvailabilityListLoading(false));
    }
}

export const createUserAvailabilityDetail = (body) => async (dispatch) => {
    dispatch(setAddUserAvailabilityDetailLoading(true));

    try {
        const requestData = {
            body: body
        }
        const response = await availabilityService.createUserAvailabilityDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddUserAvailabilityDetailData(response.data.data))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setAddUserAvailabilityDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
    } finally {
        dispatch(setAddUserAvailabilityDetailLoading(false));
    }
}

export const getUserAvailabilityDetail = (availabilityId, userId, query) => async (dispatch) => {
    dispatch(setUserAvailabilityDetailLoading(true));

    try {
        const requestData = {
            params: { availabilityId: availabilityId, userId: userId },
            query: query
        }
        const response = await availabilityService.getUserAvailabilityDetail(requestData)
        if (response.status === 200) {
            dispatch(setUserAvailabilityDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setUserAvailabilityDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setUserAvailabilityDetailLoading(false));
    }
}

export const updateUserAvailabilityDetail = (availabilityId, body) => async (dispatch) => {
    dispatch(setModifyUserAvailabilityDetailLoading(true));

    try {
        const requestData = {
            params: { availabilityId: availabilityId },
            body: body
        }
        const response = await availabilityService.updateUserAvailabilityDetail(requestData)
        if (response.status === 200) {
            dispatch(setUserAvailabilityDetailData(response.data.data))
            toast.success(response.data.data.message || "Updated Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setModifyUserAvailabilityDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
    } finally {
        dispatch(setModifyUserAvailabilityDetailLoading(false));
    }
}

export const deleteUserAvailabilityDetail = (availabilityId) => async (dispatch) => {
    dispatch(setDestroyUserAvailabilityDetailLoading(true));

    try {
        const requestData = {
            params: { availabilityId: availabilityId }
        }
        const response = await availabilityService.deleteUserAvailabilityDetail(requestData)
        if (response.status === 204) {
            dispatch(setDestroyUserAvailabilityDetailData({ id: availabilityId }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setDestroyUserAvailabilityDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
    } finally {
        dispatch(setDestroyUserAvailabilityDetailLoading(false));
    }
}

// off day availability list
export const getOffDayList = (query) => async (dispatch) => {
    dispatch(setOffDayListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await availabilityService.getOffDayList(requestData)
        if (response.status === 200) {
            dispatch(setOffDayList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setOffDayListErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setOffDayListLoading(false))
    }
}

// off day availability detail
export const getOffDayDetail = (offDayId, query) => async (dispatch) => {
    dispatch(setOffDayDetailLoading(true))

    try {
        const requestData = {
            params: { offDayId: offDayId },
            query: query
        }
        const response = await availabilityService.getOffDayDetail(requestData)
        if (response.status === 200) {
            dispatch(setOffDayDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setOffDayDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setOffDayDetailLoading(false))
    }
}

// create off day detail
export const createOffDayDetail = (body) => async (dispatch) => {
    dispatch(setAddOffDayDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await availabilityService.createOffDayDetail(requestData)
        if (response.status === 200) {
            dispatch(setAddOffDayDetail(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddOffDayDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddOffDayDetailLoading(false))
    }
}

// update off day detail
export const updateOffDayDetail = (offDayId, body) => async (dispatch) => {
    dispatch(setAddOffDayDetailLoading(true))

    try {
        const requestData = {
            params: { offDayId: offDayId },
            body: body
        }
        const response = await availabilityService.updateOffDayDetail(requestData)
        if (response.status === 200) {
            dispatch(setAddOffDayDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddOffDayDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddOffDayDetailLoading(false))
    }
}

// delete off day detail
export const deleteOffDayDetail = (offDayId) => async (dispatch, getState) => {
    dispatch(setAddOffDayDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { offDayId: offDayId }
        }
        const response = await availabilityService.deleteOffDayDetail(requestData)
        if (response.status === 200) {
            dispatch(getOffDayList({ userId: user?.user?.userId, timeZone: timeZone }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddOffDayDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddOffDayDetailLoading(false))
    }
}

// get available slot detail
export const getAvailableSlotDetail = (availabilityId, query) => async (dispatch) => {
    dispatch(setAvailableSlotDetailLoading(true))

    try {
        const requestData = {
            params: { id: availabilityId },
            query: query
        }
        const response = await availabilityService.getAvailableSlotDetail(requestData)
        if (response.status === 200) {
            dispatch(setAvailableSlotDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAvailableSlotDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setAvailableSlotDetailLoading(false))
    }
}

// different request for creating availability in create course (but service and end point is same)

export const createAvailabilityDetail = (body) => async (dispatch) => {
    dispatch(setAddAvailabilityDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await availabilityService.createUserAvailabilityDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddAvailabilityDetail(response.data.data))
            dispatch(setAvailabilityDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddAvailabilityDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddAvailabilityDetailLoading(false))
    }
}

export const updateAvailabilityDetail = (availabilityId, body) => async (dispatch) => {
    dispatch(setAddAvailabilityDetailLoading(true))

    try {
        const requestData = {
            params: { availabilityId: availabilityId },
            body: body
        }
        const response = await availabilityService.updateUserAvailabilityDetail(requestData)
        if (response.status === 200) {
            dispatch(setAddAvailabilityDetail(response.data.data))
            dispatch(setAvailabilityDetail(response.data.data))
            toast.success(response.data.data.message || "Updated Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddAvailabilityDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddAvailabilityDetailLoading(false))
    }
}