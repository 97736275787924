import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import ComponentLoader from 'components/loader/ComponentLoader';

import ProductPriceBtn from '../../commonComponents/ProductPriceBtn';

import PriceInformation from './PriceInformation';

import { createProductDetail, getProductDetail } from 'redux/product/product.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setUserEventlyDetailData } from 'redux/evently/evently.slice';
import {
    resetAddProductDetail,
    resetAddProductPriceDetailPayload,
    resetProductDetail,
    setAddProductPriceDetailPayload
} from 'redux/product/product.slice';
import { modalConst } from 'redux/local/local.const';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';
import {
    addProductPriceDetailPayload,
    productPriceDiscountTypeEnum,
    productPriceModelEnum,
    productPriceStatusEnum,
    productPriceTypeEnum,
    productTypeEnum
} from 'redux/product/product.const';

const EventlyProductDetail = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { userEventlyDetail, modifyUserEventlyDetail } = useAppState((state) => state.evently)
    const { productDetail, addProductDetail } = useAppState((state) => state.product)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (userEventlyDetail?.data?.product_evently?.product_id) {
            dispatcher(getProductDetail(userEventlyDetail?.data?.product_evently?.product_id))
        }

        return () => {
            dispatcher(resetProductDetail())
        }
    }, [userEventlyDetail?.data?.product_evently?.product_id])

    useEffect(() => {
        if (addProductDetail?.data) {
            dispatcher(resetAddProductPriceDetailPayload())
            dispatcher(setAddProductPriceDetailPayload({
                ...addProductPriceDetailPayload,
                product_id: addProductDetail?.data?.id,
                type: productPriceTypeEnum.ONE_TIME.value,
                currency_code: masterCurrencyCodeEnum?.USD?.value,
                discount_type: productPriceDiscountTypeEnum?.PERCENTAGE?.value,
                discount_pct: 10,
                price_model: productPriceModelEnum?.STANDARD?.value,
                status: productPriceStatusEnum?.ACTIVE?.value,
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.PRODUCT_PRICE_DETAIL_MODAL.stateKey]: true
            }))
            dispatcher(setUserEventlyDetailData({
                ...userEventlyDetail?.data,
                product_evently: {
                    ...userEventlyDetail?.data?.product_evently,
                    id: addProductDetail?.data?.id
                }
            }))
            dispatcher(resetAddProductDetail())
        }
    }, [addProductDetail?.data])

    const onHandleAddProduct = useCallback(() => {
        if (!userEventlyDetail?.data?.title) {
            toast.warn("Please add title!")
            return;
        }
        const body = {
            evently_id: userEventlyDetail?.data?.id,
            title: userEventlyDetail?.data?.title,
            type: productTypeEnum?.EVENTLY?.value,
            owner_user_id: user?.user?.userId
        }

        dispatcher(createProductDetail(body))
    }, [userEventlyDetail?.data])

    if (productDetail?.isLoading || addProductDetail?.isLoading) {
        return (
            <ComponentLoader isLoading={productDetail?.isLoading || addProductDetail?.isLoading} />
        )
    }

    if (productDetail?.message || addProductDetail?.message) {
        return (
            <div className={"w-full flex items-center justify-center"}>
                <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                    {productDetail?.message || addProductDetail?.message}
                </span>
            </div>
        )
    }

    return (
        <div className={"w-full flex flex-col gap-3"}>
            {(!userEventlyDetail?.data?.product_evently?.product_id) &&
                <ProductPriceBtn
                    isLoading={addProductDetail?.isLoading}
                    disabled={(!modifyUserEventlyDetail?.payload?.title || (modifyUserEventlyDetail?.payload?.title?.length === 0))}
                    onHandleProductBtn={onHandleAddProduct}
                />
            }
            {(userEventlyDetail?.data?.product_evently?.product_id) &&
                <>
                    <hr className={"border border-text-300"} />
                    <div className={"w-full flex flex-col gap-8"}>
                        <PriceInformation />
                    </div>
                </>
            }
        </div>
    )
}

export default EventlyProductDetail;