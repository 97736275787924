import { Link as RouteLink } from "react-router-dom"
import tw, { styled } from 'twin.macro';
import { Section, SectionContent } from 'components/generalComponent/StyledComponent';

export const Container = tw(Section)``;

export const Content = tw(SectionContent)`
    col-start-3 lg:col-start-2 col-span-8 lg:col-span-10 space-y-8
`;

export const Header = tw.div`
    flex flex-col items-center gap-5 lg:mx-32
`;

export const Heading = tw.h3`
    font-heading font-semibold text-3xl text-text-900 text-center
`;

export const SubHeading = tw.h4`
    font-subHeading font-normal text-lg text-text-800 text-center
`;

export const ItemContainer = styled.div`
    ${tw`
        flex flex-col gap-5 overflow-hidden lg:(flex-row gap-5)
    `}
    .panel.active {
        ${tw`
            lg:w-4/5
        `}
    }
    .panel.active > div {
        ${tw`
            lg:opacity-100
        `}
    }
`;

export const LearningItemContainer = styled.div`
    ${tw`
        rounded-2xl overflow-hidden
        transition-all ease-in-out delay-100 duration-500
        bg-black relative w-full lg:w-1/5 h-80 lg:h-[32rem]
    `}
    img {
        ${tw`
            h-full w-full opacity-75 bg-cover
        `}
    }
`;

export const ColorGradient = styled.div`
    ${tw`
        opacity-100 lg:opacity-0 absolute z-30 top-0 w-full h-full
        bg-gradient-to-br from-text-900 to-transparent
        transition-all ease-in-out delay-150 duration-300
    `}
`;

export const LearningItemContent = styled.div`
    ${tw`
        opacity-100 lg:opacity-0 flex flex-col p-5 gap-5 cursor-default lg:cursor-pointer
        absolute z-40 top-0 w-full h-full overflow-hidden
        transition-all ease-in-out delay-150 duration-300
    `}
`;

export const Title = tw.div`
    font-subHeading font-medium text-2xl text-text-50
`;

export const Description = tw.div`
    font-bodyPri font-normal text-base text-text-50
`;

export const Button = tw(RouteLink)`
    px-4 py-1 rounded-full border-2 border-text-50 self-start cursor-pointer
    font-buttons font-normal text-sm text-text-50 whitespace-nowrap
    hover:(bg-white text-text-900)
`;