import { NavHashLink } from 'react-router-hash-link';
import { cn } from "utils/cn.utils";

import {
    Container,
    Content,
    TextContainer,
    Header,
    Heading,
    Lists,
    SubHeading,
    ImageContainer,
    BtnSection,
    BtnApplyTeach,
} from './BecomeTutorStyle'

import { becomeTutorData } from './BecomeTutorData'

import { pagesInfo } from 'utils/pagesInfo';

const BECOME_TUTOR_IMAGE_URL = 'https://edulyte-website-image.s3.ap-south-1.amazonaws.com/Become+a+tutor.png';

const BecomeTutor = () => {
    return (
        <Container>
            <Content>
                <TextContainer>
                    <Header>
                        <Heading>{'Become a tutor'}</Heading>
                        <SubHeading>{'Share your knowledge with the world, make a difference and earn money'}</SubHeading>
                    </Header>
                    <Lists>
                        {becomeTutorData.map((tutorItem, index) => (
                            <li key={index}>{tutorItem.label}</li>
                        ))}
                    </Lists>
                    <BtnSection>
                        <BtnApplyTeach to={pagesInfo.TEACH_WITH_US.pagePath}>{'Apply to Teach'}</BtnApplyTeach>
                        <NavHashLink
                            to={pagesInfo.TEACH_WITH_US.pagePath + "/#faq"}
                            className={cn(
                                "font-buttons font-normal text-lg text-primary-main bg-white border-2 border-primary-main whitespace-nowrap",
                                "rounded cursor-pointer w-48 py-1.5 flex justify-center items-center",
                                "hover:(text-secondary-dark border-secondary-dark)"
                            )}
                        >
                            {'Know more'}
                        </NavHashLink>
                    </BtnSection>
                </TextContainer>
                <ImageContainer>
                    <img src={BECOME_TUTOR_IMAGE_URL} alt={'become-tutor'} />
                </ImageContainer>
            </Content>
        </Container>
    )
}

export default BecomeTutor
