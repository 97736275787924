import { createSlice } from "@reduxjs/toolkit";
import { REVIEW_INITIAL_STATE } from 'redux/review/review.initialState';


const review = createSlice({
    name: "review",
    initialState: REVIEW_INITIAL_STATE,
    reducers: {
        setTutorReviewLoading: (state, { payload }) => {
            state.tutorReviewList.isLoading = payload
        },
        setTutorReviewList: (state, { payload }) => {
            state.tutorReviewList.tutorReviewList = payload
            state.tutorReviewList.filteredTutorReviewList = payload
            state.tutorReviewList.errorMsg = REVIEW_INITIAL_STATE.tutorReviewList.errorMsg
        },
        setTutorReviewErrorMsg: (state, { payload }) => {
            state.tutorReviewList.errorMsg = payload
            state.tutorReviewList.tutorReviewList = REVIEW_INITIAL_STATE.tutorReviewList.tutorReviewList
            state.tutorReviewList.filteredTutorReviewList = REVIEW_INITIAL_STATE.tutorReviewList.filteredTutorReviewList
        },
        setClearTutorReviewList: (state) => {
            state.tutorReviewList = REVIEW_INITIAL_STATE.tutorReviewList
        },

        // set user review list
        setUserReviewListLoading: (state, { payload }) => {
            state.userReviewList.isLoading = payload
        },
        setUserReviewList: (state, { payload }) => {
            state.userReviewList.userReviewList = payload
            state.userReviewList.filteredUserReviewList = payload
            state.userReviewList.errorMsg = REVIEW_INITIAL_STATE.userReviewList.errorMsg
        },
        setUserReviewListErrorMsg: (state, { payload }) => {
            state.userReviewList.errorMsg = payload
            state.userReviewList.userReviewList = REVIEW_INITIAL_STATE.userReviewList.userReviewList
            state.userReviewList.filteredUserReviewList = REVIEW_INITIAL_STATE.userReviewList.filteredUserReviewList
        },
        setClearUserReviewList: (state) => {
            state.userReviewList = REVIEW_INITIAL_STATE.userReviewList
        },

        // reducers for add user review detail
        setAddUserReviewDetailLoading: (state, {payload}) => {
            state.addUserReviewDetail.isLoading = payload
        },
        setAddUserReviewDetail: (state, {payload}) => {
            state.addUserReviewDetail.addUserReviewDetail = payload
            state.addUserReviewDetail.errorMsg = REVIEW_INITIAL_STATE.addUserReviewDetail.errorMsg
        },
        setAddUserReviewDetailErrorMsg: (state, {payload}) => {
            state.addUserReviewDetail.errorMsg = payload
            state.addUserReviewDetail.addUserReviewDetail = REVIEW_INITIAL_STATE.addUserReviewDetail.addUserReviewDetail
        },
        setClearAddUserReviewDetail: (state) => {
            state.addUserReviewDetail = REVIEW_INITIAL_STATE.addUserReviewDetail
        },

        // reducers for add user detail payload
        setAddUserReviewDetailPayload: (state, {payload}) => {
            state.addUserReviewDetail.addUserReviewDetailPayload = payload
        },
        setClearAddUserReviewDetailPayload: (state) => {
            state.addUserReviewDetail.addUserReviewDetailPayload = REVIEW_INITIAL_STATE.addUserReviewDetail.addUserReviewDetailPayload
        }
    }
})

export const {
    setTutorReviewLoading,
    setTutorReviewList,
    setTutorReviewErrorMsg,
    setClearTutorReviewList,

    setUserReviewListLoading,
    setUserReviewList,
    setUserReviewListErrorMsg,
    setClearUserReviewList,

    setAddUserReviewDetailLoading,
    setAddUserReviewDetail,
    setAddUserReviewDetailErrorMsg,
    setClearAddUserReviewDetail,

    setAddUserReviewDetailPayload,
    setClearAddUserReviewDetailPayload
} = review.actions
export default review.reducer