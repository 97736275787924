import { memo, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { IoIosArrowForward } from 'react-icons/io';

import LogTimeLine from "components/logTimeLine/LogTimeLine";

import { bookingLogConst } from 'pages/auth/bookings/data';

import { bookingStatusEnum } from 'redux/booking/booking.const';

import { dayjs } from 'utils/dateTime.utils';
import { cn } from 'utils/cn.utils';

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const sortBookingLogs = (booking1, booking2) => {
    return dayjs(booking1?.createdAt).isSameOrBefore(booking2?.createdAt) ? 1 : -1
}

const createBookingLogs = (bookingLogs) => {
    return !!bookingLogs?.length && bookingLogs?.slice()?.sort(sortBookingLogs)?.map((booking) => {
        return {
            ...bookingStatusEnum[booking?.status?.toUpperCase()],
            ...booking,
            title: bookingLogConst[booking?.status?.toUpperCase()]?.title
        }
    })
}

const BookingLogs = memo(({ newBookingDetail }) => {

    const [visible, setVisible] = useState(false);

    return (
        <div className={"w-full flex flex-col gap-5 mt-4"}>
            <div className={"w-full flex items-center justify-between cursor-pointer"}
                onClick={() => setVisible(!visible)}
            >
                <span className={"text-lg font-bold font-bodyPri"}>
                    {"Booking Logs"}
                </span>
                <IoIosArrowForward className={cn(
                    "font-bold text-2xl text-text-800 transition ease-in-out delay-50 duration-100",
                    visible
                        ? "rotate-90"
                        : "rotate-0"
                )} />
            </div>
            <AnimatePresence initial={visible}>
                {visible && (
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        className={"w-full"}
                    >
                        <LogTimeLine logList={createBookingLogs(newBookingDetail?.data?.result?.bookingLogs)} />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
})

export default BookingLogs;