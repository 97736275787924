export const questionType = {
    SINGLE_TEXT: {
        value: "single_text"
    },
    MULTIPLE_TEXT: {
        value: "multiple_text"
    },
    SINGLE_CHOICE: {
        value: "single_choice"
    },
    MULTIPLE_CHOICE: {
        value: "multiple_choice"
    }
}