import { useNavigate } from 'react-router-dom';
import { pagesInfo } from 'utils/pagesInfo';

const MessageSection = () => {

    const navigate = useNavigate()

    return (
        <div className={"bg-white p-10 rounded-lg shadow-all w-96 md:w-[32rem] flex flex-col items-center gap-5 select-none"}>
            <span className={"font-bodyPri font-semibold text-xl text-primary-dark text-center"}>
                Whoops, that's an expired/invalid link
            </span>
            <p className={"font-bodyPri font-normal text-base text-text-700 text-center"}>
                For security reasons, password reset links expire / become invalid after a little white. If you still need to reset your password, you can request a new reset password.
            </p>
            <div className={"w-full md:w-3/4 px-5 py-2 rounded-lg bg-secondary-dark hover:opacity-75 cursor-pointer font-bodyPri font-medium text-md text-text-50 text-center"}
                onClick={() => navigate(pagesInfo.LOG_IN.pagePath, {replace: true})}
            >
                Request a new reset password link
            </div>
        </div>
    )
}

export default MessageSection