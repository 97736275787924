export const url = {
    GET_GPT_TXN_LIST_FOR_USERS: 'gpt/transactions/users',
    GPT_CHECKOUT: 'gpt/checkout',
    GET_DETAIL_GPT_CONFIRMATION: 'gpt/confirmation',

    GENERATE_GPT_CONTENT: 'gpt/generate-response',
    GENERATE_GPT_TEXT: 'gpt/generate-content',

    // ai question generation
    CREATE_AI_SINGLE_QUESTION: 'gpt/generate-question-response'
}

export const gptTokenTxnActions = {
    TOKEN_GIFTED: {
        label: "Tokens Gifted",
        value: "token_gifted",
        action: "token_gifted"
    },
    TOKEN_PURCHASED: {
        label: "Tokens Purchased",
        value: "token_purchased",
        action: "token_purchased"
    },
    TOKEN_SUBSCRIBED: {
        label: "Tokens Subscribed",
        value: "token_subscribed",
        action: "token_subscribed"
    },
    SUBSCRIPTION_TOKEN_CONSUMED: {
        label: "Subscription Token Consumed",
        value: "subscription_token_consumed",
        action: "subscription_token_consumed"
    },
    PURCHASED_TOKEN_CONSUMED: {
        label: "Token used",
        value: "purchased_token_consumed",
        action: "purchased_token_consumed"
    },
    TOKEN_EXPIRED: {
        label: "Token Expired",
        value: "token_expired",
        action: "token_expired"
    },
    OTHER: {
        label: 'Other',
        value: 'other',
        action: 'other'
    },
}