import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { FaInfoCircle } from "react-icons/fa";

import { BsCreditCard2Back } from 'react-icons/bs';
import { BiCreditCard } from 'react-icons/bi';
import { MdDeleteOutline } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { getUserPaymentMethodList, detachUserPaymentMethodDetail } from 'redux/payment/payment.request';
import { resetUserPaymentMethodList } from 'redux/payment/payment.slice';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { paymentFundingEnum } from 'redux/payment/payment.const';

import { pagesInfo } from "utils/pagesInfo";

export const PaymentPage = () => {
  const { user } = useAppState((state) => state.user)
  const { modal } = useAppState((state) => state.local)
  const { userPaymentMethodList } = useAppState((state) => state.payment)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    return () => {
      dispatcher(resetUserPaymentMethodList())
    }
  }, [])

  useEffect(() => {
    dispatcher(getUserPaymentMethodList(user?.user?.userId))
  }, [])

  const addPaymentMethod = () => {
    dispatcher(setModal({
      ...modal,
      [modalConst.PAYMENT_CARD_MODAL.stateKey]: true
    }))
  }

  const removePaymentMethod = (paymentMethodId) => {
    dispatcher(detachUserPaymentMethodDetail(user?.user?.userId, { paymentMethodId: paymentMethodId }))
  }

  return (
    <div className={"space-y-5"}>
      <div className="py-4 mx-8 border-b flex items-center justify-start gap-2">
        <p className="text-lg text-left">
          {"Payment Methods"}
        </p>
        <div className={"relative has-tooltip cursor-pointer"}>
          <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
          <span
            className={cn(
              "w-56 px-2 py-0.5 font-bodyPri font-normal text-xs rounded shadow-lg bg-text-900 text-text-50 tooltip -top-8 left-4",
              "flex flex-col flex-wrap items-start justify-start overflow-hidden"
            )}
          >
            {"For security reasons, we don’t save full card details. Only a token is retained. It's easy to make payments using added payment methods."}
          </span>
        </div>
      </div>
      <div className={"w-full flex items-center justify-between gap-2"}>
        <div className={"flex items-center gap-5"}>
          <BsCreditCard2Back className={"text-text-700 text-xl"} />
          <span className={"font-bodyPri font-medium text-md text-text-800"}>{"Credit/Debit Card Detail"}</span>
        </div>
        <div onClick={() => addPaymentMethod()}
          className={cn(
            "px-3 py-1 font-bodyPri font-normal text-sm text-text-50 bg-primary-dark hover:opacity-90 cursor-pointer rounded-md"
          )}>
          {"Add Payment Method"}
        </div>
      </div>
      <ComponentLoader isLoading={userPaymentMethodList?.isLoading} className={"h-40"} />
      {(userPaymentMethodList?.message || userPaymentMethodList?.data?.length === 0) &&
        <div className={"w-full flex items-center justify-center h-40"}>
          <span className={"font-bodyPri font-medium text-lg text-text-800 text-center"}>
            {"You haven't done any transactions yet. "}
            <Link
              to={`${pagesInfo?.USER_FINANCE?.pagePath}/wallet`}
              className={"text-primary-main cursor-pointer hover:underline"}
            >
              {" Add Credit"}
            </Link>
          </span>
        </div>
      }
      <div className={"w-full flex flex-col items-start gap-3 px-10"}>
        {userPaymentMethodList?.data?.map((paymentMethod, index) => {
          return (
            <div className={"w-full md:w-[40%]  flex gap-3 items-start"}>
              <div key={index} className={cn(
                "w-full flex flex-col items-start gap-1 p-3 border-2 border-text-300 rounded-lg"
              )}>
                <span className={cn(
                  "w-full flex items-center justify-between gap-2"
                )}>
                  <span className={"flex items-center justify-start gap-2 font-bodyPri font-medium text-base text-text-900"}>
                    <BiCreditCard />
                    {`${paymentMethod?.card?.brand?.toUpperCase().replace("_", " ")} ${paymentFundingEnum[paymentMethod?.card?.funding?.toUpperCase()].label} Card`}
                  </span>
                  <MdDeleteOutline onClick={() => removePaymentMethod(paymentMethod?.id)} className={"text-lg text-red-500 hover:text-red-400 cursor-pointer"} />
                </span>
                <span className={cn(
                  "font-bodyPri font-medium text-md text-text-900"
                )}>
                  {`xxxx xxxx xxxx ${paymentMethod?.card?.last4}`}
                </span>
                <span className={cn(
                  "font-bodyPri font-medium text-md text-text-900"
                )}>
                  {`${paymentMethod?.card?.exp_month}/${paymentMethod?.card?.exp_year}  `}
                  <span className={cn(
                    "font-bodyPri font-normal text-base text-text-800"
                  )}>
                    {`(${paymentMethod?.card?.country}) ${paymentMethod?.billing_details?.name ? paymentMethod?.billing_details?.name : ""}`}
                  </span>
                </span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};
