import FAQ from 'components/faq/Faq';

const FaqSection = () => {
    return (
        <div className="w-full p-5 flex flex-col justify-center items-center gap-3 text-center overflow-hidden bg-[radial-gradient(100%_50%_at_50%_100%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)]">
            <h2 className="text-2xl font-medium font-subHeading mb-6">Frequently asked questions</h2>
            <div className='w-full md:w-[40rem]'>
                <FAQ
                    faqs={faq}
                    questionClassName="bg-background-lightBlue"
                    answerClassName="text-left"
                />
            </div>
        </div>
    );
};

export default FaqSection;

export const faq = [
    {
        question: "What products can I sell on Edulyte?",
        answer: <p>On Edulyte, you can sell a wide range of educational products, including self-paced courses, cohort-based classes, events, appointment sessions, quizzes, and downloadable content. Additionally, you have the capability to issue verified certificates with QR codes, enhancing the value of your offerings. Please explore our <a href={"https://www.edulyte.com/features/"} target="_blank" className='text-primary-dark underline cursor-pointer'>features page</a> for more information.</p>
    },
    {
        question: "What are the benefits of choosing the Pro plan over the Free plan?",
        answer: <p>The Pro plan offers a comprehensive set of tools designed to enhance your productivity and revenue potential. With the Pro plan, you get 250,000 AI tokens per month, a lower transaction fee of 5%, access to CRM, unlimited product listings, 50 GB of storage, paid events, focus groups, and premium email and chat support.</p>
    },
    {
        question: "Can I get a demo to understand how everything works?",
        answer: <p>Absolutely! We’re happy to provide a demo to help you explore how Edulyte can support your business. Please send us a <a href={"https://www.edulyte.com/contact-us/"} target="_blank" className='text-primary-dark underline cursor-pointer'>quick message</a> today.</p>
    },
    {
        question: "How does the AI token system work?",
        answer: <p>AI tokens are used to access various AI-driven features on the platform, such as the AI Quiz Builder. The Free plan includes 3,000 AI tokens on signup, while the Pro plan provides 250,000 tokens monthly, allowing for more extensive usage of AI-powered tools.</p>
    },
    {
        question: "What does the 5% transaction fee mean on the Pro plan?",
        answer: <p>On the Pro plan, a 5% transaction fee is applied to the revenue generated from sales on the platform. This is lower than the 10% transaction fee applied on the Free plan, helping you maximise your earnings.<br />If you are on a custom business plan, you can connect your own stripe and pay your gateway charges yourself.</p>
    },
    {
        question: "Are there any limitations on the number of products I can sell on the Free plan?",
        answer: <p>Yes, on the Free plan, you can sell up to 15 products. The Pro plan removes this limitation, allowing you to sell an unlimited number of products.</p>
    },
    {
        question: "How do paid events work in the Pro plan?",
        answer: <p>With the Pro plan, you can create and host paid events, whether online or offline. This feature is great for monetising workshops, seminars, or any other events where you charge participants.</p>
    },
    {
        question: "What support options are available on the Free vs. Pro plans?",
        answer: <p>The Free plan offers standard support, while the Pro plan includes premium email and chat support, ensuring quicker response times and more personalised assistance. Please <a href={"https://www.edulyte.com/contact-us/"} target="_blank" className='text-primary-dark underline cursor-pointer'>contact us</a> for more details. Our support email is <a href={"mailto:support@edulyte.com"} className='text-primary-dark underline cursor-pointer'>support@edulyte.com</a> and you may send us WhatsApp to <a href={"https://wa.me/+61480080151"} target="_blank" className='text-primary-dark underline'>+61480080151</a></p>
    },
    {
        question: "What happens to my data if I downgrade from Pro to Free?",
        answer: <p>If you downgrade to the Free plan, your data will remain secure, but you’ll need to reduce your usage to fit within the Free plan’s limitations, such as storage and the number of products you can list. Access to Pro-exclusive features will also be restricted.</p>
    },
]