export const url = {
    GET_FILE_DETAILS: 'fileboxes/files/{id}/',
    GET_FILE_LIST_DETAILS: 'fileboxes/files/',
    FILE_UPLOAD: 'fileboxes/files/',
    VIEW_FILE_DETAIL: "fileboxes/files/{id}/download/",
    GET_USER_STORAGE_INFO:'fileboxes/files/get_user_storage/',
    GET_USER_FILES_RECEIVED_LIST_DETAILS: 'fileboxes/files/received/',
    GET_USERS_MEETING_RECORDING_LIST:'fileboxes/files/get-s3-files/',
    GET_RECENT_OPEN_FILE_LIST: 'fileboxes/files/recent-open/',
    ADD_SHARE_DRIVE_FILE_DETAIL: "fileboxes/files/{fileId}/share/"
}

export const shareDriveFileDetailPayload = {
    fileId: null,
    fileName: ""
}

export const driveFileTypeConst = {
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ".docx",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": ".pptx",
    "application/pdf": ".pdf",
    "application/rtf": ".rtf",
    "image/jpeg": ".jpg",
    "image/png": ".png",
    "video/mp4": ".mp4",
    "video/x-msvideo": ".avi",
    "video/quicktime": ".mov",
    "video/x-ms-wmv": ".wmv",
    "video/x-matroska": ".mkv",
    "video/webm": ".webm",

}

export const fileExtensionEnum = {
    MP4: {
        label: "mp4",
        value: "mp4",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },

    PNG: {
        label: "Png",
        value: "png",
        lightColor: "red-100",
        darkColor: "red-500"
    },

    PDF: {
        label: "Pdf",
        value: "pdf",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    DOCX: {
        label: "Docx",
        value: "docx",
        lightColor: "orange-100",
        darkColor: "orange-500"
    },
    DOC: {
        label: "Doc",
        value: "doc",
        lightColor: "purple-100",
        darkColor: "purple-500"
    },
    TXT: {
        label: "Txt",
        value: 'txt',
        lightColor: "pink-100",
        darkColor: "pink-500"
    },
    JPG: {
        label: "Jpg",
        value: "jpg",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    JPEG: {
        label: "Jpeg",
        value: "jpeg",
        lightColor: "gray-100",
        darkColor: "gray-500"
    }
}