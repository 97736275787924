import { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { FaEye } from 'react-icons/fa';
import { BsCardHeading } from 'react-icons/bs';
import { AiOutlineNumber } from 'react-icons/ai';
import { BiTimeFive } from 'react-icons/bi';
import { IoMdCalendar } from 'react-icons/io';

import ToolTipView from 'components/tooltipView';

import { sessionStatusEnum } from 'redux/session/session.const';

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from "utils/dateTime.utils";
import { cn } from 'utils/cn.utils';

const SessionAppointmentCard = memo(({ sessionAppointment }) => {

    const navigate = useNavigate()

    const sessionStatusDetail = useMemo(() => {
        if (sessionAppointment?.session?.status) {
            return sessionStatusEnum[sessionAppointment?.session?.status?.toUpperCase()]
        } else {
            return sessionStatusEnum.PENDING
        }
    }, [sessionAppointment])

    const isDateSimilar = useMemo(() => {
        return dayjs(sessionAppointment?.session?.startDateTime).isSame(sessionAppointment?.session?.endDateTime, "date")
    }, [sessionAppointment])

    const onHandleViewSession = useCallback(() => {
        navigate(`${pagesInfo.SCHEDULE.pagePath}?sessionId=${sessionAppointment?.session?.id}`)
    }, [sessionAppointment])

    return (
        <div className={`p-5 bg-white border-2 border-${sessionStatusDetail?.darkColor} rounded-md space-y-3`}>
            <div className={"flex items-center justify-between gap-5"}>
                <div className={cn(
                    `rounded-md font-medium font-bodyPri tracking-wide leading-6 py-1 w-32 flex justify-center items-center`,
                    `border-2 border-${sessionStatusDetail?.darkColor} bg-${sessionStatusDetail?.lightColor} text-${sessionStatusDetail?.darkColor}`
                )}
                >
                    {sessionStatusDetail?.label}
                </div>
                <ToolTipView content={"View Session"}>
                    <button className={"p-2 rounded-full hover:bg-divider-darkLight cursor-pointer"} onClick={onHandleViewSession}>
                        <FaEye className={"text-lg text-text-700"} />
                    </button>
                </ToolTipView>
            </div>

            <div className="h-0.5 bg-background-medium"></div>

            {/* session id */}
            <div className={"grid grid-cols-4 w-full gap-3 lg:gap-5"}>
                <div className={"col-start-1 col-span-full lg:col-start-1 lg:col-span-2 flex items-center justify-start gap-3"}>
                    <div className={"flex items-center justify-start gap-1.5"}>
                        <AiOutlineNumber className={"text-text-700 text-lg"} />
                        <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                            {"Session ID:"}
                        </span>
                    </div>
                    <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide line-clamp-1"}>
                        {sessionAppointment?.session?.id}
                    </span>
                </div>

                {/* duration */}
                <div className={"col-start-1 col-span-full lg:col-start-3 lg:col-span-2 flex items-center justify-start gap-3"}>
                    <div className={"flex items-center justify-start gap-1.5"}>
                        <BiTimeFive className={"text-text-700 text-lg"} />
                        <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                            {"Duration:"}
                        </span>
                    </div>
                    <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide line-clamp-1"}>
                        {(sessionAppointment?.session?.duration <= 60)
                            ? sessionAppointment?.session?.duration + " min"
                            : parseFloat(sessionAppointment?.session?.duration / 60)?.toFixed(2) + " hrs"
                        }
                    </span>
                </div>

                {/* session title */}
                <div className={"col-start-1 col-span-full flex flex-col items-start justify-start gap-2 lg:flex-row lg:items-center lg:gap-3"}>
                    <div className={"flex items-center justify-start gap-1.5"}>
                        <BsCardHeading className={"text-text-700 text-lg"} />
                        <span className={"text-md text-text-800 font-normal font-bodyPri tracking-wide whitespace-nowrap"}>
                            {'Session Title:'}
                        </span>
                    </div>
                    <div className={cn(
                        "font-bodyPri font-medium text-base text-text-900 line-clamp-1",
                        [sessionStatusEnum?.COMPLETED?.value]?.includes(sessionAppointment?.session?.status) && "!text-primary-main !hover:underline !hover:text-primary-dark cursor-pointer"
                    )}
                        onClick={onHandleViewSession}
                    >
                        {sessionAppointment?.session?.name}
                    </div>
                </div>

                {/* session date */}
                <div className={"col-start-1 col-span-full flex flex-col items-start justify-start gap-2 mt-0 lg:flex-row lg:items-start lg:mt-1 lg:gap-3"}>
                    <div className={"flex items-center justify-start gap-1.5"}>
                        <IoMdCalendar className={"text-text-700 text-lg"} />
                        <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide whitespace-nowrap"}>
                            {"Session Date:"}
                        </span>
                    </div>
                    <div className={"flex flex-wrap items-center justify-start gap-3"}>
                        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide truncate"}>
                            {dayjs(sessionAppointment?.session?.startDateTime)?.tz(timeZone)?.format("dddd, DD-MMM-YYYY") || "-"}
                        </span>
                        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                            {dayjs(sessionAppointment?.session?.startDateTime)?.tz(timeZone)?.format("hh:mma") || "-"}
                        </span>
                        <span className={"font-bodyPri font-medium text-text-800 text-base"}>
                            {"-"}
                        </span>
                        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                            {dayjs(sessionAppointment?.session?.endDateTime)?.tz(timeZone)?.format("hh:mma") || "-"}
                        </span>
                        {!isDateSimilar &&
                            <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide truncate"}>
                                {dayjs(sessionAppointment?.session?.endDateTime)?.tz(timeZone)?.format("dddd, DD-MMM-YYYY") || "-"}
                            </span>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
})

export default SessionAppointmentCard;

export const SessionAppointmentCardLoader = ({ isLoading = false }) => {
    return isLoading ? (
        <div className='animate-pulse shadow p-5 bg-white space-y-3'>
            <div className='flex flex-row items-center justify-between gap-5'>
                <div className='bg-slate-200 py-4 w-32'></div>
                <div className='bg-slate-200 w-10 h-5 rounded-full'></div>
            </div>
            <div className="bg-slate-200 h-0.5"></div>
            <div className='grid grid-cols-4 gap-5'>
                <div className='col-span-full md:col-span-2'>
                    <div className='bg-slate-200 w-28 h-8'></div>
                </div>
                <div className='col-span-full md:col-start-3 md:col-span-2'>
                    <div className='bg-slate-200 w-28 h-8'></div>
                </div>
                <div className='col-span-full'>
                    <div className='bg-slate-200 w-full h-8 flex items-center'>
                    </div>
                </div>
                <div className='col-span-full'>
                    <div className='bg-slate-200 w-full h-8 flex items-center'>
                    </div>
                </div>
            </div>
        </div>
    ) : null
}