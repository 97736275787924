import { onBoardingStatus } from "redux/onboarding/onboarding.const";

const addOnboardingDocDetailPayload = {
    documentId: null,
    fileCategory: null,
    fileName: "",
    file: null,
    fileUrl: "",
}

const categoryListPayload = {
    categoryListItems: null,
    isVideoUploadEnabled: false
}

const addReferencePayload = {
    referenceId: 0,
    referenceName: "",
    referenceRelationship: "",
    knownYears: 0,
    knownMonths: 0,
    referenceMobile: "",
    referenceEmail: "",
    isEmailSent: false,
    isPrivacyEnabled: true,
    isFormSubmitted: false,
    referenceStatus: onBoardingStatus.PENDING.value
}

export const ONBOARDING_INITIAL_STATE = {
    userOnboardingList: {
        isLoading: false,
        userOnboardingList: null,
        errorMsg: null
    },
    addUserOnboardingDetail: {
        isLoading: false,
        addUserOnboardingDetail: null,
        errorMsg: null
    },

    // onboardingDetail: {
    //     isLoading: false,
    //     onboardingDetail: null,
    //     errorMsg: null
    // },

    userOnboardingDetail: {
        isLoading: false,
        userOnboardingDetail: null,
        errorMsg: null
    },

    onboardingUserDetail: {
        isLoading: false,
        onboardingUserDetail: null,
        errorMsg: null
    },
    addOnboardingUserDetail: {
        isLoading: false,
        addOnboardingUserDetail: null,
        errorMsg: null
    },

    userOnboardingQuestionDetail: {
        isLoading: false,
        userOnboardingQuestionDetail: null,
        errorMsg: null,
    },
    addUserOnboardingQuestionDetail: {
        isLoading: false,
        addUserOnboardingQuestionDetail: null,
        errorMsg: null
    },

    onboardingDocList: {
        isLoading: false,
        onboardingDocList: null,
        errorMsg: null
    },
    addOnboardingDocDetail: {
        isLoading: false,
        addOnboardingDocDetail: null,
        addOnboardingDocDetailPayload: addOnboardingDocDetailPayload,
        errorMsg: null
    },
    categoryList: {
        isLoading: false,
        categoryList: categoryListPayload,
        errorMsg: null
    },

    onboardingInterviewDetail: {
        isLoading: false,
        onboardingInterviewDetail: null,
        errorMsg: null
    },
    addOnboardingInterviewDetail: {
        isLoading: false,
        addOnboardingInterviewDetail: null,
        errorMsg: null
    },

    onboardingTutorPrefDetail: {
        isLoading: false,
        onboardingTutorPrefDetail: null,
        errorMsg: null
    },
    addOnboardingTutorPrefDetail: {
        isLoading: false,
        errorMsg: null
    },

    onboardingTutorTrialCourseDetail: {
        isLoading: false,
        onboardingTutorTrialCourseDetail: null,
        errorMsg: null
    },
    addOnboardingTutorTrialCourseDetail: {
        isLoading: false,
        addOnboardingTutorTrialCourseDetail: null,
        errorMsg: null
    },

    onboardingCommentList: {
        isLoading: false,
        data: null,
        message: null
    },
    addOnboardingCommentDetail: {
        isLoading: false,
        message: null
    },

    // commented reference (No use)
    referenceList: {
        isLoading: false,
        referenceList: [],
        errorMsg: null,
    },
    addReference: {
        isLoading: false,
        addReference: null,
        addReferencePayload: addReferencePayload,
        errorMsg: null
    },
}