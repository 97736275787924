import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import ButtonLoader from 'components/loader/ButtonLoader';
import { createVerifyUser } from "redux/auth/auth.request";
import { createSendOtp } from 'redux/notification/notification.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { otpDeliveryMethod } from "redux/notification/notification.const";
import { setAddResetPasswordPayload, setClearVerifyUser } from 'redux/auth/auth.slice';
import { setClearSendOtp, setClearVerifyOtp } from "redux/notification/notification.slice";
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { validateEmail } from 'utils/validation-functions';
import { pagesInfo } from 'utils/pagesInfo';

const ForgetPasswordModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { addResetPassword, verifyUser } = useAppState((state) => state.auth)
    const { sendOtp } = useAppState((state) => state.notification)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [email, setEmail] = useState(addResetPassword?.addResetPasswordPayload?.email)
    const [emailErrorText, setEmailErrorText] = useState('');

    useEffect(() => {
        if (email && !validateEmail(email)) setEmailErrorText('Enter your valid Email!');
        else setEmailErrorText('');
    }, [email])

    useEffect(() => {
        if (verifyUser?.verifyUser) {
            dispatcher(createSendOtp({
                channel: otpDeliveryMethod?.EMAIL?.value,
                email: email
            }))
        }
    }, [verifyUser?.verifyUser])

    useEffect(() => {
        if (sendOtp?.sendOtp) {
            dispatcher(setAddResetPasswordPayload({
                ...addResetPassword?.addResetPasswordPayload,
                email: email,
                otpId: sendOtp?.sendOtp?.otpId,
            }))
            dispatcher(setClearSendOtp())
            dispatcher(setClearVerifyOtp())
            dispatcher(setClearVerifyUser())
            dispatcher(setModal({
                ...modal,
                [modalConst.FORGET_PASSWORD_MODAL.stateKey]: false,
                [modalConst.RESET_PASSWORD_MODAL.stateKey]: true
            }))
        }
    }, [sendOtp?.sendOtp])

    const handleSendCode = () => {
        if (emailErrorText || !email) return;
        if (email) {
            dispatcher(createVerifyUser({
                email: email
            }))
        }
        else {
            toast.warning("Please enter valid email or phone number")
        }
    }

    const handleSignUp = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.FORGET_PASSWORD_MODAL.stateKey]: false
        }))
        navigate(pagesInfo?.SIGN_UP?.pagePath)
    }

    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"min-h-[16rem] w-full block space-y-5"}>
                {!addResetPassword?.addResetPasswordPayload?.isEmailVerified &&
                    <div className={'flex flex-col items-start justify-start gap-10'}>
                        {!verifyUser?.errorMsg &&
                            <div className={"w-full font-bodyPri font-normal text-text-800 text-sm text-center tracking-wide"}>
                                {"Enter your email to send a verification code."}
                            </div>
                        }
                        {verifyUser?.errorMsg &&
                            <div className={"font-bodyPri font-normal text-red-500 text-sm tracking-wide"}>
                                {"We could not find your account with this email. "}
                                <span className={"text-text-900"}>
                                    {"Please"}
                                </span>
                                <span
                                    className={"text-primary-main cursor-pointer"}
                                    onClick={handleSignUp}
                                >
                                    {" Sign Up "}
                                </span>
                                <span className={"text-text-900"}>
                                    {" Or "}
                                </span>
                                <a
                                    href={"https://www.edulyte.com/contact-us/"}
                                    target={"_blank"}
                                    className={"text-primary-main cursor-pointer"}
                                >
                                    {"contact us"}
                                </a>
                            </div>
                        }

                        <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                            <div className={cn(
                                "w-full py-1.5 px-2 flex items-center gap-3 border-2 border-divider-medium bg-white rounded-md",
                                "text-text-900 text-base font-bodySec focus:outline-none"
                            )}>
                                <input
                                    type={"email"}
                                    placeholder={'Enter your email'}
                                    className={cn(
                                        "w-full px-2 py-1 focus:outline-none"
                                    )}
                                    value={email}
                                    autoFocus={true}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            {emailErrorText &&
                                <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                    {emailErrorText}
                                </span>
                            }
                            {/* {verifyUser?.errorMsg &&
                                <div className={"font-bodyPri font-normal text-red-500 text-sm tracking-wide"}>
                                    {"We could not find your account with this email. "}
                                    <span className={"text-text-900"}>
                                        {"Please"}
                                    </span>
                                    <Link
                                        to={pagesInfo?.SIGN_UP?.pagePath}
                                        className={"text-primary-main"}>
                                        {" Sign Up "}
                                    </Link>
                                    <span className={"text-text-900"}>
                                        {" Or "}
                                    </span>
                                    <Link to={"https://www.edulyte.com/contact-us/"} className={"text-primary-main"}>
                                        {"contact us"}
                                    </Link>
                                </div>
                            } */}
                        </div>
                    </div>
                }
                <div className={"flex items-center justify-center"}>
                    <div className={cn(
                        "py-1.5 rounded-lg",
                        "text-text-50 font-buttons font-medium text-base w-full text-center",
                        (!email || emailErrorText || verifyUser?.errorMsg) && "bg-divider-medium",
                        (email && !verifyUser?.errorMsg) && "bg-primary-main hover:bg-secondary-dark cursor-pointer"
                    )}
                        onClick={handleSendCode}
                    >
                        {(verifyUser?.isLoading || sendOtp?.isLoading) &&
                            <ButtonLoader isLoading={verifyUser?.isLoading || sendOtp?.isLoading} />
                        }
                        {(!verifyUser?.isLoading && !sendOtp?.isLoading) && "Send Code"}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgetPasswordModal