const LiveOnlineLessons = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/live-online-classes-personalised-tutoring-home.png';
const HassleFreeAndSafe = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/hassle-free-and-safe-personalised-tutoring-home.png';
const QualifiedTutors = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/qualified-tutors-personalised-tutoring-home.png';
const OneOnOneOrGroupClasses = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/one-to-one-or-group-courses--personalised-tutoring-home.png';
const SelfPacedLearning = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/self-pace-learning-personalised-tutoring-home.png';
const AnytimeAnywhere = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/anytime-anywhere-personalised-tutoring-home.png';


export const tutoringData = [
    {
        title: 'Live online lessons',
        description: 'Recorded lectures are not sufficient, learn live with the best tutors for your topics to get ahead in your life.',
        imageUrl: LiveOnlineLessons,
    },
    {
        title: 'Hassle Free and Safe',
        description: 'Your safety and convenience are our priority, we make learning fun and safe online, use your digital wallet to make payments.',
        imageUrl: HassleFreeAndSafe,
    },
    {
        title: 'Qualified Tutors',
        description: 'We allow only trusted, qualified and certified teachers to teach our students so that you get the quality instructions.',
        imageUrl: QualifiedTutors,
    },
    {
        title: '1-on-1 or Group Classes',
        description: 'Every learner is not the same, get tailored help just designed for you or learn in small interactive groups.',
        imageUrl: OneOnOneOrGroupClasses,
    },
    {
        title: 'Self-Paced Learning',
        description: 'Schedule and attend classes at your suitable time, complete your assignments when possible. No deadlines or pressure.',
        imageUrl: SelfPacedLearning,
    },
    {
        title: 'Anytime, Anywhere',
        description: 'Time and place should not affect your learning, we offer on demand live tutoring services so that you can start learning whenever you like.',
        imageUrl: AnytimeAnywhere,
    },
]