import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BsQuestionCircle } from 'react-icons/bs';
import { AiOutlinePlus } from 'react-icons/ai';
import { FaRegPlusSquare } from 'react-icons/fa';

import ToolTipView from 'components/tooltipView';

import SideBarExpendable from 'pages/auth/edulyteLms/commonComponents/SideBarExpendable';

import QuizThumbnail from '../../../commonComponents/QuizThumbnail';
import QuizTags from '../../../commonComponents/QuizTags';
import QuizQuestionListItems from './QuestionListItems';
import QuizArticleListItems from './QuizArticleListItems';
import QuizVideoListItems from './QuizVideoListItems';
import QuizImageListItems from './QuizImageListItems';

import {
    getLmsQuizPageQuestionList,
    getLmsQuizPageArticleList,
    getLmsQuizPageVideoResourceList,
    getLmsQuizPageImageResourceList,
} from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsQuizCategoryEnum } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.const';
import { resourceFileTypeEnum } from 'redux/edulyteLms/lmsResource/lmsResource.const';

import { debounce } from 'utils/generators.utils';

const QuizSideBarItem = () => {
    const { modal } = useAppState((state) => state.local)
    const { lmsQuizDetail } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()
    const { quizId } = useParams()

    const [librarySearch, setLibrarySearch] = useState({
        questionSearch: "",
        articleSearch: "",
        videoSearch: "",
        imageSearch: ""
    })

    const onHandleSearchQuestionList = useCallback((value) => {
        if (value) {
            dispatcher(getLmsQuizPageQuestionList({ search: value }))
        } else {
            dispatcher(getLmsQuizPageQuestionList({
                page: 1,
                records: 10,
                lms_quiz_id: `!${quizId}`
            }))
        }
    }, [librarySearch?.questionSearch, quizId])

    const onHandleSearchArticleList = useCallback((value) => {
        if (value) {
            dispatcher(getLmsQuizPageArticleList({ search: value }))
        } else {
            dispatcher(getLmsQuizPageArticleList({
                page: 1,
                records: 10,
                lms_quiz_id: `!${quizId}`
            }))
        }
    }, [librarySearch?.articleSearch, quizId])

    const onHandleSearchVideoResourceList = useCallback((value) => {
        if (value) {
            dispatcher(getLmsQuizPageVideoResourceList({ file_type: resourceFileTypeEnum?.VIDEO?.value, search: value }))
        } else {
            dispatcher(getLmsQuizPageVideoResourceList({
                page: 1,
                records: 10,
                file_type: resourceFileTypeEnum?.VIDEO?.value,
                lms_quiz_id: `!${quizId}`
            }))
        }
    }, [librarySearch?.videoSearch, quizId])

    const onHandleSearchImageResourceList = useCallback((value) => {
        if (value) {
            dispatcher(getLmsQuizPageImageResourceList({ file_type: resourceFileTypeEnum?.IMAGE?.value, search: value }))
        } else {
            dispatcher(getLmsQuizPageImageResourceList({
                page: 1,
                records: 10,
                file_type: resourceFileTypeEnum?.IMAGE?.value,
                lms_quiz_id: `!${quizId}`
            }))
        }
    }, [librarySearch?.imageSearch, quizId])

    const onHandleQuestionSearch = useCallback((event) => {
        setLibrarySearch({
            ...librarySearch,
            questionSearch: event?.target?.value
        })
        const delayFunction = debounce.debounce(onHandleSearchQuestionList)
        delayFunction(event.target.value)
    }, [librarySearch?.questionSearch])

    const onHandleArticleSearch = useCallback((event) => {
        setLibrarySearch({
            ...librarySearch,
            articleSearch: event?.target?.value
        })
        const delayFunction = debounce.debounce(onHandleSearchArticleList)
        delayFunction(event.target.value)
    }, [librarySearch?.articleSearch])

    const onHandleVideoSearch = useCallback((event) => {
        setLibrarySearch({
            ...librarySearch,
            videoSearch: event?.target?.value
        })
        const delayFunction = debounce.debounce(onHandleSearchVideoResourceList)
        delayFunction(event.target.value)
    }, [librarySearch?.videoSearch])

    const onHandleImageSearch = useCallback((event) => {
        setLibrarySearch({
            ...librarySearch,
            imageSearch: event?.target?.value
        })
        const delayFunction = debounce.debounce(onHandleSearchImageResourceList)
        delayFunction(event.target.value)
    }, [librarySearch?.imageSearch])

    const onHandleAddQuestion = useCallback(() => {
        dispatcher(setModal({
            ...modal,
            [modalConst.CREATE_LMS_QUESTION_MODAL.stateKey]: true
        }))
    }, [modal])

    const onHandleAddArticle = useCallback(() => {
        dispatcher(setModal({
            ...modal,
            [modalConst.CREATE_LMS_ARTICLE_MODAL.stateKey]: true
        }))
    }, [modal])

    return (
        <div className={'flex flex-col w-full gap-5 font-bodyPri'}>
            <QuizThumbnail />
            <QuizTags />
            <div className={'w-full flex flex-col gap-5'}>
                <div className={""}>
                    <p className={'text-lg text-black font-medium'}>
                        {"Library"}
                    </p>
                    <p className={'text-sm text-text-600'}>
                        {"Quick access, pick from library."}
                    </p>
                </div>
                <div className={"flex items-start justify-start gap-1.5"}>
                    <SideBarExpendable
                        isExpended={true}
                        placeholder={'Search question'}
                        disabledClassName={"border-text-200"}
                        contentTooltip={"Add quiz section to enable these features."}
                        isDisabled={!lmsQuizDetail?.data?.quiz_sections || (lmsQuizDetail?.data?.quiz_sections?.length === 0)}
                        category={`${lmsQuizCategoryEnum?.QUESTION?.label}s`}
                        onChange={onHandleQuestionSearch}
                        children={<QuizQuestionListItems questionSearch={librarySearch?.questionSearch} />}
                        expendHeight={'300px'}
                    />
                    <ToolTipView content={"Add Question"}>
                        <button
                            className={"p-2 rounded-full transition-transform duration-200 hover:bg-divider-darkLight"}
                            onClick={onHandleAddQuestion}
                        >
                            <FaRegPlusSquare className={"text-2xl text-text-700 hover:text-primary-dark"} />
                        </button>
                    </ToolTipView>
                </div>
                <div className={"flex items-start justify-start gap-1.5"}>
                    <SideBarExpendable
                        placeholder={'Search article'}
                        disabledClassName={"border-text-200"}
                        contentTooltip={"Add quiz section to enable these features."}
                        isDisabled={!lmsQuizDetail?.data?.quiz_sections || (lmsQuizDetail?.data?.quiz_sections?.length === 0)}
                        category={`${lmsQuizCategoryEnum?.ARTICLE?.label}s`}
                        onChange={onHandleArticleSearch}
                        children={<QuizArticleListItems articleSearch={librarySearch?.articleSearch} />}
                        expendHeight={'300px'}
                    />
                    <ToolTipView content={"Add Article"}>
                        <button
                            className={"p-2 rounded-full transition-transform duration-200 hover:bg-divider-darkLight"}
                            onClick={onHandleAddArticle}
                        >
                            <FaRegPlusSquare className={"text-2xl text-text-700 hover:text-primary-dark"} />
                        </button>
                    </ToolTipView>
                </div>
                <div className={"flex items-start justify-start gap-1.5"}>
                    <SideBarExpendable
                        placeholder={'Search video'}
                        disabledClassName={"border-text-200"}
                        contentTooltip={"Add quiz section to enable these features."}
                        isDisabled={!lmsQuizDetail?.data?.quiz_sections || (lmsQuizDetail?.data?.quiz_sections?.length === 0)}
                        category={`${lmsQuizCategoryEnum?.VIDEO?.label}s`}
                        onChange={onHandleVideoSearch}
                        children={<QuizVideoListItems videoSearch={librarySearch?.videoSearch} />}
                        expendHeight={'300px'}
                    />

                    <ToolTipView content={"Add Article"}>
                        <button
                            className={"invisible p-2 rounded-full transition-transform duration-200 hover:bg-divider-darkLight"}
                        // onClick={onHandleAddArticle}
                        >
                            <AiOutlinePlus className={"text-2xl text-text-700 hover:text-primary-dark"} />
                        </button>
                    </ToolTipView>
                </div>
                <div className={"flex items-start justify-start gap-1.5"}>
                    <SideBarExpendable
                        placeholder={'Search image'}
                        disabledClassName={"border-text-200"}
                        contentTooltip={"Add quiz section to enable these features."}
                        isDisabled={!lmsQuizDetail?.data?.quiz_sections || (lmsQuizDetail?.data?.quiz_sections?.length === 0)}
                        category={`${lmsQuizCategoryEnum?.IMAGE?.label}s`}
                        onChange={onHandleImageSearch}
                        children={<QuizImageListItems imageSearch={librarySearch?.imageSearch} />}
                        expendHeight={'300px'}
                    />
                    <ToolTipView content={"Add Article"}>
                        <button
                            className={"invisible p-2 rounded-full transition-transform duration-200 hover:bg-divider-darkLight"}
                        // onClick={onHandleAddArticle}
                        >
                            <AiOutlinePlus className={"text-2xl text-text-700 hover:text-primary-dark"} />
                        </button>
                    </ToolTipView>
                </div>
            </div>
        </div>
    )
}

export default QuizSideBarItem;