import React from "react";

//Style imports
import { StyledChatUserMenu } from "./ChatUserMenu.styles";

export default function ChatUserMenu({
  setShowReportUserModal,
  setShowChatUserMenu,
}) {
  function openReportUserModal() {
    setShowChatUserMenu(false);
    setShowReportUserModal(true);
  }

  return (
    <StyledChatUserMenu>
      <p>View Profile</p>
      <p onClick={openReportUserModal}>Report User</p>
      <p>Shared Files</p>
    </StyledChatUserMenu>
  );
}
