import * as React from "react";

import { Button } from "components/common-components/Buttons";
import { DatePicker } from "components/common-components/Form";
import { OptionSelector } from "components/common-components/Select";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import {
  setDeliveryDate,
  setDeliveryTime,
  setRecipentSelection,
  setRecipientDeliveryMode,
  setRecipientSelectionCompleted,
} from "../store/GiftCardSlice";

import { cn } from "utils/cn.utils";
import { dayjs } from "utils/dateTime.utils";
import { validateEmail } from "utils/validation-functions";

const TimeSelectionOptions = [
  {
    label: "Morning",
    value: "08:00 AM",
  },
  {
    label: "Afternoon",
    value: "01:00 PM",
  },
  {
    label: "Evening",
    value: "07:00 PM",
  },
];

function RecipientToggle({
  state,
  onToggle,
}: {
  state: boolean;
  onToggle: (value: boolean) => void;
}) {
  return (
    <div className="flex mx-auto bg-blue-200 rounded-lg w-72">
      <button
        className={cn(
          "flex-1 rounded-l-lg px-5 py-3",
          state && "bg-blue-600 text-white"
        )}
        onClick={() => onToggle(true)}
      >
        Someone else
      </button>
      <button
        className={cn(
          "flex-1 rounded-r-lg px-5 py-3",
          !state && "bg-blue-600 text-white"
        )}
        onClick={() => onToggle(false)}
      >
        Yourself
      </button>
    </div>
  );
}

function RecipientInputField({
  onChange,
  value,
  placeholder,
  label,
}: {
  label: string;
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
}) {
  return (
    <div className="flex flex-col">
      <label className="pb-1 text-sm font-semibold opacity-70">{label}</label>
      <input
        className="px-4 py-2 border border-blue-200 rounded-lg shadow-sm"
        placeholder={placeholder}
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}

function getTodayAsString() {
  const day = dayjs();
  return day.format("dddd, M MMM YY hh:mm A");
}

export const RecipientSelection: React.FC = () => {
  const cardStatus = useAppState((s) => s.buyGiftCard.cardSelection);
  const recipientState = useAppState(
    (s) => s.buyGiftCard.recipentSelection.selectedRecipent
  );
  const dispatcher = useAppDispatcher();
  // const [deliveryDate, setDeliveryDate] = React.useState(getTodayAsString());

  const onDeliverySelection = (value: string) => {
    dispatcher(setRecipientDeliveryMode(value));
  };

  const onDateChange = (value: string) => {
    dispatcher(setDeliveryDate({ date: value }));
  };

  const onTimeSelect = (label: string, value: string) => {
    dispatcher(setDeliveryTime({ label, value }));
  };

  const onSubmit = () => {
    // validate the data first
    if (recipientState.name.length <= 3) {
      alert("Please enter a name");
      return;
    }

    if (!validateEmail(recipientState.recipientEmail)) {
      alert("Invalid Email Address");
      return;
    }

    if (recipientState.type === "someone_else") {
      if (recipientState.recipientName.length <= 3) {
        alert("Please enter recipient's name");
        return;
      }
    }

    dispatcher(setRecipientSelectionCompleted());
  };

  return (
    <div className="pb-6">
      <p className="pb-3 text-base font-semibold text-center text-blue-600">
        {cardStatus.selectedCard!.name}
      </p>
      <RecipientToggle
        onToggle={(val) =>
          dispatcher(
            setRecipentSelection({
              recipient: {
                ...recipientState,
                type: val ? "someone_else" : "yourself",
              },
            })
          )
        }
        state={recipientState.type === "someone_else"}
      />
      {/* Recipient Form */}
      <div className="px-2">
        <div className="flex flex-col space-y-4">
          <RecipientInputField
            label="Your Name"
            onChange={(txt) => {
              dispatcher(
                setRecipentSelection({
                  recipient: { ...recipientState, name: txt },
                })
              );
            }}
            placeholder="John Smith"
            value={recipientState.name}
          />
          {recipientState.type === "someone_else" && (
            <>
              <RecipientInputField
                label="Recipient Name"
                onChange={(txt) => {
                  dispatcher(
                    setRecipentSelection({
                      recipient: { ...recipientState, recipientName: txt },
                    })
                  );
                }}
                placeholder="Kent Clark"
                value={recipientState.recipientName}
              />
              <RecipientInputField
                label="Message"
                onChange={(txt) => {
                  dispatcher(
                    setRecipentSelection({
                      recipient: { ...recipientState, message: txt },
                    })
                  );
                }}
                placeholder="I would like to give you a gift card for your birthday"
                value={recipientState.message}
              />
            </>
          )}
          <div className="flex ">
            <div className="flex flex-col flex-1">
              <label className="pb-1 text-sm font-semibold opacity-70">
                Recipient Email
              </label>
              <input
                type="text"
                placeholder="example@gmail.com"
                className="px-4 py-2 mr-5 border border-blue-200 rounded-lg shadow-sm"
                onChange={(e) => {
                  dispatcher(
                    setRecipentSelection({
                      recipient: {
                        ...recipientState,
                        recipientEmail: e.target.value,
                      },
                    })
                  );
                }}
                value={recipientState.recipientEmail}
              />
            </div>
            <div className="flex flex-col w-40">
              <label className="pb-1 text-sm font-semibold opacity-70">
                &nbsp;
              </label>
              <OptionSelector
                onChange={(nValue) => {
                  onDeliverySelection(nValue.value);
                }}
                options={[
                  { label: "Send Now", value: "Instantly" },
                  { label: "Choose Date", value: "Custom Date" },
                ]}
                value={recipientState.deliveryType}
                className="py-2"
              />
            </div>
          </div>
        </div>

        <div className="py-2">
          {recipientState.deliveryType === "Custom Date" && (
            <>
              <DatePicker
                date={recipientState.selectedDate}
                onDateChange={(newDate) => {
                  onDateChange(newDate);
                }}
              />

              <div className="flex mt-3 bg-blue-100 divide-x divide-blue-200 rounded-lg shadow-md">
                {TimeSelectionOptions.map((option, idx) => (
                  <button
                    className={cn(
                      "inline-flex flex-col items-center justify-center flex-1 px-3 py-2",
                      option.value === recipientState.selectedTime.value &&
                      "bg-blue-600 text-white",
                      idx === 0 && "rounded-l-lg",
                      idx === TimeSelectionOptions.length - 1 && "rounded-r-lg"
                    )}
                    onClick={() => onTimeSelect(option.label, option.value)}
                  >
                    <p className="text-sm">{option.label}</p>
                    <p>{option.value}</p>
                  </button>
                ))}
              </div>
            </>
          )}
        </div>

        {recipientState.deliveryType === "Instantly" && (
          <p className="pt-3 pb-1 font-semibold tracking-wider text-center text-opacity-90">
            {getTodayAsString()}
          </p>
        )}

        <Button
          name="Continue"
          className="w-full my-6"
          style="primary"
          size="medium"
          onClick={() => onSubmit()}
        />
      </div>
    </div>
  );
};
