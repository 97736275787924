import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { cn } from "utils/cn.utils";

import ComponentLoader from "components/loader/ComponentLoader";
import SideOverPanel from "components/common-components/SideOverPanel";

import { ctaButtonsConst } from "pages/auth/onboarding/data";
import WaitListSideOverContent from "../waitListNotice/WaitListNotice";

import { createUserOnboardingDetailByUserId, getUserOnboardingListByUserId } from "redux/onboarding/onboarding.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setLocals } from "redux/local/local.slice";
import { setClearAddUserOnboardingDetail, setClearUserOnboardingList } from "redux/onboarding/onboarding.slice";
import { localsConst } from "redux/local/local.const";
import { userRoles } from "redux/auth/auth.const";
import { onboardingRequestType, onboardingType } from "redux/onboarding/onboarding.const";

import { generateQueryParams } from "utils/generators.utils";
import { pagesInfo } from "utils/pagesInfo";


const RECORDS = 10

const CTAButtons = () => {
    const { locals } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { userOnboardingList, addUserOnboardingDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const navigate = useNavigate()

    const [isShowSideOverPanel, setIsShowSideOverPanel] = useState(false)

    const tutorOnboardingList = userOnboardingList?.userOnboardingList?.results?.filter((onboarding) => onboarding?.onboardingType === onboardingType?.TUTOR?.value)
    const isWaitingListEnabled = [onboardingRequestType?.WAITING?.value]?.includes(user?.user?.tutorOnboardingRequest)


    useEffect(() => {
        return () => {
            dispatcher(setClearUserOnboardingList())
            dispatcher(setClearAddUserOnboardingDetail())
        }
    }, [])

    useEffect(() => {
        const query = generateQueryParams(location.search)

        dispatcher(getUserOnboardingListByUserId(user?.user?.userId, { page: query.pn || 1, records: RECORDS }))

    }, [location.search])


    useEffect(() => {
        if (addUserOnboardingDetail?.addUserOnboardingDetail) {
            if (locals.userRole !== userRoles?.TUTOR?.value) {

                dispatcher(setLocals({
                    ...locals,
                    [localsConst.USER_ROLE.value]: userRoles?.TUTOR?.value
                }))
            }
            const onboardingId = addUserOnboardingDetail?.addUserOnboardingDetail?.onboardingId
            dispatcher(setClearAddUserOnboardingDetail())
            navigate(`${pagesInfo.TUTOR_ONBOARDING_S2.pagePath}/${onboardingId}/s2`)
        }
    }, [addUserOnboardingDetail?.addUserOnboardingDetail])

    const onHandleLink = (ctaButton) => {

        if (ctaButton?.value === "listOnMarketPlace") {
            if (tutorOnboardingList?.length === 0) {
                if (isWaitingListEnabled) {
                    setIsShowSideOverPanel(true)
                } else {
                    dispatcher(createUserOnboardingDetailByUserId(
                        user?.user?.userId,
                        {
                            onboardingType: onboardingType.TUTOR.value,
                        }
                    ))
                }
                return;
            }

            if (tutorOnboardingList?.length > 0) {
                navigate(`${pagesInfo.TUTOR_ONBOARDING_S2.pagePath}/${tutorOnboardingList?.[0]?.onboardingId}/s2`)
            }
        } else {
            window.open(ctaButton?.href, "_blank")
        }
    }

    return (
        <>
            <SideOverPanel
                child={<WaitListSideOverContent />}
                isOpen={isShowSideOverPanel}
                onClose={(isShow) => setIsShowSideOverPanel(isShow)}
            />
            <div className={"w-full flex flex-col items-center justify-center space-y-10 py-10 bg-primary-main"}>
                <div className={"w-full font-bodyPri font-medium text-xl md:text-2xl text-text-50 tracking-wide text-center"}>
                    {"Boost Your Impact: Tailored Solutions & Blue Checkmark"}
                </div>
                {addUserOnboardingDetail?.isLoading &&
                    <ComponentLoader isLoading={addUserOnboardingDetail?.isLoading} className={"h-52 bg-primary-main"} />
                }
                {!addUserOnboardingDetail?.isLoading &&
                    <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 lg:gap-16">
                        {ctaButtonsConst.map((ctaButton, index) => (
                            <div
                                key={index}
                                onClick={() => onHandleLink(ctaButton)}
                                className={cn(
                                    "w-full md:w-[22rem] flex items-start justify-center gap-5 cursor-pointer bg-white shadow-all-md shadow-white overflow-hidden",
                                    "p-3 space-y-3 border-2 group rounded-lg",
                                    "border-primary-light hover:border-secondary-main"
                                )}
                            >
                                <div className={"w-20 h-20 overflow-hidden"}>
                                    <img
                                        src={ctaButton.image}
                                        alt={ctaButton.title}
                                        className={"w-full h-full object-cover"}
                                    />
                                </div>
                                <div className={"flex flex-col items-start justify-start gap-1.5"}>
                                    <p className="w-fit font-bodyPri font-semibold text-center text-base text-primary-dark group-hover:text-secondary-dark">
                                        {ctaButton.title}
                                    </p>
                                    <p className="w-fit font-bodyPri font-normal text-center text-sm text-text-900">
                                        {ctaButton.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                }

                {(!addUserOnboardingDetail?.isLoading && addUserOnboardingDetail?.errorMsg) &&
                    <div className={"w-full font-bodyPri font-medium text-base text-red-500 tracking-wide text-center"}>
                        {addUserOnboardingDetail?.errorMsg}
                    </div>
                }

            </div>
        </>
    )
}

export default CTAButtons;