export const testimonialsData = [
    {
        imageUrl: "https://edulyte-website-image.s3.ap-south-1.amazonaws.com/Neena.png",
        quote: "My English classes were conduct online and to be honest it was very convenient. The teachers were knowledgeable and they made it very easy to understand.",
        userName: "Leena",
    },
    {
        imageUrl: "https://edulyte-website-image.s3.ap-south-1.amazonaws.com/Kung.png",
        quote: "I wanted to have a better command over English and this is just what I needed. There is a friendly administration and professional educators. So, if you need guidance in English language this is for you.",
        userName: "Kung",
    },
    {
        imageUrl: "https://edulyte-website-image.s3.ap-south-1.amazonaws.com/Bhavesh.png",
        quote: "I attended a live session in a small group, they provided personalised feedback in an interactive session. This is the best thing about Edulyte.",
        userName: "Bhavesh",
    }
]