import { toast } from "react-toastify";

import OnboardingService from "redux/onboarding/onboarding.service";

import {
    setUserOnboardingListLoading,
    setUserOnboardingList,
    setUserOnboardingListErrorMsg,

    setAddUserOnboardingDetailLoading,
    setAddUserOnboardingDetail,
    setAddUserOnboardingDetailErrorMsg,

    // setOnboardingDetailLoading,
    // setOnboardingDetail,
    // setOnboardingDetailErrorMsg,

    setUserOnboardingDetailLoading,
    setUserOnboardingDetail,
    setUserOnboardingDetailErrorMsg,

    setOnboardingUserDetailLoading,
    setOnboardingUserDetail,
    setOnboardingUserDetailErrorMsg,

    setAddOnboardingUserDetailLoading,
    setAddOnboardingUserDetail,
    setAddOnboardingUserDetailErrorMsg,

    setUserOnboardingQuestionDetailLoading,
    setUserOnboardingQuestionDetail,
    setUserOnboardingQuestionDetailErrorMsg,

    setAddUserOnboardingQuestionDetailLoading,
    setAddUserOnboardingQuestionDetail,
    setAddUserOnboardingQuestionDetailErrorMsg,

    setOnboardingDocListLoading,
    setOnboardingDocList,
    setOnboardingDocListErrorMsg,

    setAddOnboardingDocDetailLoading,
    setAddOnboardingDocDetail,
    setAddOnboardingDocDetailErrorMsg,

    setOnboardingInterviewDetailLoading,
    setOnboardingInterviewDetail,
    setOnboardingInterviewDetailErrorMsg,

    setOnboardingTutorPrefDetailLoading,
    setOnboardingTutorPrefDetail,
    setOnboardingTutorPrefDetailErrorMsg,

    setAddOnboardingTutorPrefDetailLoading,
    setAddOnboardingTutorPrefDetailErrorMsg,

    setOnboardingTutorTrialCourseDetailLoading,
    setOnboardingTutorTrialCourseDetail,
    setOnboardingTutorTrialCourseDetailErrorMsg,

    setAddOnboardingTutorTrialCourseDetailLoading,
    setAddOnboardingTutorTrialCourseDetail,
    setAddOnboardingTutorTrialCourseDetailErrorMsg,

    setReferenceListLoading,
    setReferenceList,
    setReferenceListErrorMsg,

    setAddReferenceLoading,
    setAddReference,
    setAddReferenceErrorMsg,

    setOnboardingCommentListLoading,
    setOnboardingCommentListData,
    setOnboardingCommentListMessage,
    
    setAddOnboardingCommentDetailLoading,
    setAddOnboardingCommentDetailMessage,
} from "redux/onboarding/onboarding.slice";
import { getMyProfile } from "redux/user/user.request";


// request for get onboarding list
export const getUserOnboardingListByUserId = (userId, query) => async (dispatch) => {
    dispatch(setUserOnboardingListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: { ...query }
        }
        const response = await OnboardingService.getUserOnboardingListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserOnboardingList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.message)
        dispatch(setUserOnboardingListErrorMsg(error.response.data.message || error.response.data.message))
    } finally {
        dispatch(setUserOnboardingListLoading(false))
    }
}

// request for creating onboarding detail
export const createUserOnboardingDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddUserOnboardingDetailLoading(true))

    try {
        const { userOnboardingList } = getState().onboarding
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await OnboardingService.createUserOnboardingDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setAddUserOnboardingDetail(response.data.data))
            dispatch(setUserOnboardingList([...userOnboardingList?.userOnboardingList?.results, response.data.data]))
            // toast.success(response.data.message)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserOnboardingDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddUserOnboardingDetailLoading(false))
    }
}

// request for onboarding page detail status
export const getUserOnboardingDetailByOnboardingIdAndUserId = (onboardingId, userId) => async (dispatch) => {
    dispatch(setUserOnboardingDetailLoading(true))

    try {
        const requestData = {
            params: { onboardingId: onboardingId, userId: userId }
        }
        const response = await OnboardingService.getUserOnboardingDetailByOnboardingIdAndUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserOnboardingDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserOnboardingDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setUserOnboardingDetailLoading(false))
    }
}

// request for share personal detail
export const getOnboardingUserDetailByOnboardingId = (onboardingId) => async (dispatch) => {
    dispatch(setOnboardingUserDetailLoading(true))

    try {
        const requestData = {
            params: { onboardingId: onboardingId }
        }
        const response = await OnboardingService.getOnboardingUserDetailByOnboardingId(requestData)
        if (response.status === 200) {
            dispatch(setOnboardingUserDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setOnboardingUserDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setOnboardingUserDetailLoading(false))
    }
}

// request for update share personal detail
export const updateOnboardingUserDetailByOnboardingId = (onboardingId, body) => async (dispatch, getState) => {
    dispatch(setAddOnboardingUserDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { onboardingId: onboardingId },
            body: body
        }
        const response = await OnboardingService.updateOnboardingUserDetailByOnboardingId(requestData)
        if (response.status === 200) {
            dispatch(getUserOnboardingDetailByOnboardingIdAndUserId(onboardingId, user?.user?.userId))
            dispatch(getMyProfile())
            toast.success(response.data.message)
            dispatch(setOnboardingUserDetail(response.data.data))
            dispatch(setAddOnboardingUserDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddOnboardingUserDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddOnboardingUserDetailLoading(false))
    }
}

// request for onboarding question detail
export const getUserOnboardingQuestionDetailByOnboardingId = (onboardingId) => async (dispatch) => {
    dispatch(setUserOnboardingQuestionDetailLoading(true))

    try {
        const requestData = {
            params: { onboardingId: onboardingId }
        }
        const response = await OnboardingService.getUserOnboardingQuestionDetailByOnboardingId(requestData)
        if (response.status === 200) {
            dispatch(setUserOnboardingQuestionDetail(response.data.data))
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserOnboardingQuestionDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setUserOnboardingQuestionDetailLoading(false))
    }
}

export const updateUserOnboardingQuestionDetailByOnboardingId = (onboardingId, body) => async (dispatch, getState) => {
    dispatch(setAddUserOnboardingQuestionDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { onboardingId: onboardingId },
            body: body
        }
        const response = await OnboardingService.updateUserOnboardingQuestionDetailByOnboardingId(requestData)
        if (response.status === 200) {
            dispatch(getUserOnboardingDetailByOnboardingIdAndUserId(onboardingId, user?.user?.userId))
            toast.success(response.data.message)
            dispatch(setUserOnboardingQuestionDetail(response.data.data))
            dispatch(setAddUserOnboardingQuestionDetail(response.data.data))
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserOnboardingQuestionDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddUserOnboardingQuestionDetailLoading(false))
    }
}

// request for get onboarding document
export const getOnboardingDocListByOnboardingId = (onboardingId, query) => async (dispatch) => {
    dispatch(setOnboardingDocListLoading(true))

    try {
        const requestData = {
            params: { onboardingId: onboardingId },
            query: { ...query }
        }
        const response = await OnboardingService.getOnboardingDocListByOnboardingId(requestData)
        if (response.status === 200) {
            dispatch(setOnboardingDocList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setOnboardingDocListErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setOnboardingDocListLoading(false))
    }
}

// request for create document details
export const createOnboardingDocDetailByOnboardingId = (onboardingId, body) => async (dispatch, getState) => {
    dispatch(setAddOnboardingDocDetailLoading(true))

    try {
        const { user } = getState().user
        const { onboardingDocList } = getState().onboarding
        const requestData = {
            params: { onboardingId: onboardingId },
            body: body
        }
        const response = await OnboardingService.createOnboardingDocDetailByOnboardingId(requestData)
        if (response.status === 200) {
            dispatch(getUserOnboardingDetailByOnboardingIdAndUserId(onboardingId, user?.user?.userId))
            toast.success(response.data.message)
            dispatch(setOnboardingDocList([...onboardingDocList?.onboardingDocList, response.data.data]))
            dispatch(setAddOnboardingDocDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddOnboardingDocDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddOnboardingDocDetailLoading(false))
    }
}

// request for update document list
export const updateOnboardingDocDetailByOnboardingDocId = (onboardingDocId, body) => async (dispatch, getState) => {
    dispatch(setAddOnboardingDocDetailLoading(true))

    try {
        const { user } = getState().user
        const { onboardingDocList, userOnboardingDetail } = getState().onboarding
        const requestData = {
            params: { onboardingDocId: onboardingDocId },
            body: body
        }
        const response = await OnboardingService.updateOnboardingDocDetailByOnboardingDocId(requestData)
        if (response.status === 200) {
            const filteredDocumentList = onboardingDocList?.onboardingDocList?.filter((documents) => documents?.onboardingDocId !== onboardingDocId)
            dispatch(getUserOnboardingDetailByOnboardingIdAndUserId(userOnboardingDetail?.userOnboardingDetail?.onboardingId, user?.user?.userId))
            toast.success(response.data.message)
            dispatch(setAddOnboardingDocDetail(response.data.data))
            dispatch(setOnboardingDocList([...filteredDocumentList, response.data.data]))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddOnboardingDocDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddOnboardingDocDetailLoading(false))
    }
}

// request for delete document
export const deleteOnboardingDocDetailByOnboardingDocId = (onboardingDocId) => async (dispatch, getState) => {
    dispatch(setAddOnboardingDocDetailLoading(true))

    try {
        const { user } = getState().user
        const { onboardingDocList, userOnboardingDetail } = getState().onboarding
        const requestData = {
            params: { onboardingDocId: onboardingDocId }
        }
        const response = await OnboardingService.deleteOnboardingDocDetailByOnboardingDocId(requestData)
        if (response.status === 200) {
            const filteredList = onboardingDocList?.onboardingDocList?.filter((document) => document.onboardingDocId !== onboardingDocId)
            dispatch(setOnboardingDocList(filteredList))
            dispatch(getUserOnboardingDetailByOnboardingIdAndUserId(userOnboardingDetail?.userOnboardingDetail?.onboardingId, user?.user?.userId))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddOnboardingDocDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddOnboardingDocDetailLoading(false))
    }
}

// request for get onboarding Interview detail
export const getOnboardingInterviewDetailByOnboardingId = (onboardingId) => async (dispatch) => {
    dispatch(setOnboardingInterviewDetailLoading(true))

    try {
        const requestData = {
            params: { onboardingId: onboardingId }
        }
        const response = await OnboardingService.getOnboardingInterviewDetailByOnboardingId(requestData)
        if (response.status === 200) {
            dispatch(setOnboardingInterviewDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setOnboardingInterviewDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setOnboardingInterviewDetailLoading(false))
    }
}

// get onboarding tutor pref detail by onboarding id
export const getOnboardingTutorPrefDetailByOnboardingId = (onboardingId) => async (dispatch) => {
    dispatch(setOnboardingTutorPrefDetailLoading(true))

    try {
        const requestData = {
            params: { onboardingId: onboardingId }
        }
        const response = await OnboardingService.getOnboardingTutorPrefDetailByOnboardingId(requestData)
        if (response.status === 200) {
            dispatch(setOnboardingTutorPrefDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setOnboardingTutorPrefDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setOnboardingTutorPrefDetailLoading(false))
    }
}

// update tutor onboarding pref detail
export const updateOnboardingTutorPrefDetailByOnboardingId = (onboardingId, body) => async (dispatch, getState) => {
    dispatch(setAddOnboardingTutorPrefDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { onboardingId: onboardingId },
            body: body
        }
        const response = await OnboardingService.updateOnboardingTutorPrefDetailByOnboardingId(requestData)
        if (response.status === 200) {
            dispatch(setOnboardingTutorPrefDetail(response.data.data))
            dispatch(getUserOnboardingDetailByOnboardingIdAndUserId(onboardingId, user?.user?.userId))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddOnboardingTutorPrefDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddOnboardingTutorPrefDetailLoading(false))
    }
}

// get onboarding tutor trial course 
export const getOnboardingTutorTrialCourseDetailByOnboardingId = (onboardingId) => async (dispatch) => {
    dispatch(setOnboardingTutorTrialCourseDetailLoading(true))

    try {
        const requestData = {
            params: { onboardingId: onboardingId }
        }
        const response = await OnboardingService.getOnboardingTutorTrialCourseDetailByOnboardingId(requestData)
        if (response.status === 200) {
            dispatch(setOnboardingTutorTrialCourseDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setOnboardingTutorTrialCourseDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setOnboardingTutorTrialCourseDetailLoading(false))
    }
}

export const createOnboardingTutorTrialCourseDetailByOnboardingId = (onboardingId, body) => async (dispatch) => {
    dispatch(setAddOnboardingTutorTrialCourseDetailLoading(true))

    try {
        const requestData = {
            params: { onboardingId: onboardingId },
            body: body
        }
        const response = await OnboardingService.updateOnboardingTutorTrialCourseDetailByOnboardingId(requestData)
        if (response.status === 200) {
            dispatch(setOnboardingTutorTrialCourseDetail(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddOnboardingTutorTrialCourseDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddOnboardingTutorTrialCourseDetailLoading(false))
    }
}

export const updateOnboardingTutorTrialCourseDetailByOnboardingId = (onboardingId, body) => async (dispatch, getState) => {
    dispatch(setAddOnboardingTutorTrialCourseDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { onboardingId: onboardingId },
            body: body
        }
        const response = await OnboardingService.updateOnboardingTutorTrialCourseDetailByOnboardingId(requestData)
        if (response.status === 200) {
            dispatch(getUserOnboardingDetailByOnboardingIdAndUserId(onboardingId, user?.user?.userId))
            toast.success(response.data.message)
            dispatch(setAddOnboardingTutorTrialCourseDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddOnboardingTutorTrialCourseDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddOnboardingTutorTrialCourseDetailLoading(false))
    }
}

// request for onboarding comment list
export const getOnboardingCommentList = (query) => async (dispatch) => {
    dispatch(setOnboardingCommentListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await OnboardingService.getOnboardingCommentList(requestData)
        if (response.status === 200) {
            dispatch(setOnboardingCommentListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setOnboardingCommentListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setOnboardingCommentListLoading(false))
    }
}

// request for add onboarding comment detail
export const createOnboardingCommentDetail = (body) => async (dispatch) => {
    dispatch(setAddOnboardingCommentDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await OnboardingService.createOnboardingCommentDetail(requestData)
        if (response.status === 200) {
            dispatch(getOnboardingCommentList({ onboardingId: body?.onboardingId }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddOnboardingCommentDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddOnboardingCommentDetailLoading(false))
    }

}

// updated later (No use)
export const getReferenceList = () => async (dispatch) => {
    dispatch(setReferenceListLoading(true))
    try {
        // dispatch(setReferenceList())
    } catch (error) {
        console.error(error)
        dispatch(setReferenceListErrorMsg(error.message))
    } finally {
        dispatch(setReferenceListLoading(false))
    }
}

export const createReference = () => async (dispatch, getState) => {
    dispatch(setAddReferenceLoading(true))

    try {
        const { referenceList, addReference } = getState().onboarding
        const list = [
            ...referenceList?.referenceList, addReference?.addReferencePayload
        ]
        dispatch(setReferenceList(list))
        dispatch(setAddReference(addReference?.addReferencePayload))

        // dispatch(setClearAddReference())
    } catch (error) {
        console.error(error)
        dispatch(setAddReferenceErrorMsg(error.message))
    } finally {
        dispatch(setAddReferenceLoading(false))
    }
}

export const updateReference = () => async (dispatch, getState) => {
    dispatch(setAddReferenceLoading(true))

    try {
        const { referenceList, addReference } = getState().onboarding
        const filteredList = referenceList?.referenceList?.filter((reference) => reference.referenceId !== addReference?.addReferencePayload?.referenceId)
        const referencePayload = {
            ...addReference?.addReferencePayload,
            referenceStatus: "submitted",
            isFormSubmitted: true,
            referenceId: addReference?.addReferencePayload?.referenceId
        }
        dispatch(setReferenceList([...filteredList, referencePayload]))
        dispatch(setAddReference(referencePayload))

        // dispatch(setClearAddReference())
    } catch (error) {
        console.error(error)
        dispatch(setAddReferenceErrorMsg(error.message))
    } finally {
        dispatch(setAddReferenceLoading(false))
    }
}

export const deleteReference = () => async (dispatch, getState) => {
    dispatch(setAddReferenceLoading(true))

    try {
        const { referenceList, addReference } = getState().onboarding
        const filteredList = referenceList?.referenceList?.filter((items) => items.referenceId !== addReference?.addReferencePayload?.referenceId)

        dispatch(setReferenceList(filteredList))
        // dispatch(setClearAddReference())
    } catch (error) {
        console.error(error)
        dispatch(setAddReferenceErrorMsg(error.message))
    } finally {
        dispatch(setAddReferenceLoading(false))
    }
}

