import { userRoles } from 'redux/auth/auth.const';
import { courseType } from 'redux/course/course.const';

import { dayjs, timeZone } from "utils/dateTime.utils";

//  Your current learning score is { 1 }
//  Your current teaching score is { 1 }
export const heroCard = {
  title: "Welcome back, {0}",
  studentText: "You completed {0} sessions!",
  tutorText: "You completed {0} sessions!",
}

export const dashboardStats = {
  HOURS_TAUGHT: {
    value: 'HOURS_TAUGHT',
    label: "Hours Taught",
    data: 0,
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/dashboard-stats/hours-taught.png",
    unit: "Hours",
    options: [],
    roles: [userRoles.TUTOR.value]
  },
  LEARNERS: {
    value: 'LEARNERS',
    label: "Learners",
    data: 0,
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/dashboard-stats/learners.png",
    unit: "Students",
    options: [],
    roles: [userRoles.TUTOR.value]
  },
  HOURS_LEARN: {
    value: 'HOURS_LEARN',
    label: "Hours Learnt",
    data: 0,
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/dashboard-stats/hours-learnt.png",
    unit: "Hours",
    options: [],
    roles: [userRoles.STUDENT.value]
  },
  SESSION_COMPLETED: {
    value: 'SESSION_COMPLETED',
    label: "Sessions Completed",
    data: 0,
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/dashboard-stats/sessions-completed.png",
    unit: "Sessions",
    options: [],
    roles: [userRoles.TUTOR.value, userRoles.STUDENT.value]
  },
  MY_BALANCE: {
    value: 'MY_BALANCE',
    label: "My Balance",
    data: 0,
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/dashboard-stats/my-balance.png",
    unit: "USD",
    options: [],
    roles: [userRoles.TUTOR.value, userRoles.STUDENT.value]
  },
  REVIEWS_EARNED: {
    value: 'REVIEWS_EARNED',
    label: "Reviews Earned",
    data: 0,
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/dashboard-stats/reviews-earned.png",
    unit: "Reviews",
    options: [],
    roles: [userRoles.TUTOR.value]
  },
  BOOKINGS: {
    value: 'BOOKINGS',
    label: "Bookings",
    data: 0,
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/dashboard-stats/bookings.png",
    unit: "Confirmed",
    options: [],
    roles: [userRoles.TUTOR.value, userRoles.STUDENT.value]
  },
}

export const listOfTimeZones = [
  {
    name: "Sydney",
    zone: "Australia/Sydney",
  },
  {
    name: "Adelaide",
    zone: "Australia/Adelaide",
  },
  {
    name: "Mumbai",
    zone: "Asia/Kolkata",
  },
  {
    name: "New York",
    zone: "America/New_York",
  },
  {
    name: "Singapore",
    zone: "Asia/Singapore",
  },
  {
    name: "Beijing",
    zone: "Asia/Shanghai",
  },
];

export const doSomethingActionList = [
  {
    name: "Create a Quiz",
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/quiz.svg",
    href: `/lms/quizzes`,
    roles: [userRoles.TUTOR.value]
  },
  {
    name: "Create a Course",
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/course.svg",
    href: `/lms/courses`,
    roles: [userRoles.TUTOR.value]
  },
  {
    name: "Add Your Questions",
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/question.svg",
    href: `/lms/questions`,
    roles: [userRoles.TUTOR.value]
  },
  {
    name: "Offer 1 to 1 lesson",
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/offer-one-to-one.svg",
    href: `/create-class?classType=${courseType?.ONE_ON_ONE?.value}`,
    roles: [userRoles.TUTOR.value]
  },
  {
    name: "Offer a group lesson",
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/offer-group.svg",
    href: `/create-class?classType=${courseType?.GROUP?.value}`,
    roles: [userRoles.TUTOR.value]
  },
  {
    name: "Update availability",
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/update-availability.svg",
    href: "/settings/preferences/availability",
    roles: [userRoles.TUTOR.value]
  },
  {
    name: "Withdraw money",
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/withdraw-money.svg",
    href: "/finance/wallet",
    roles: [userRoles.TUTOR.value]
  },
  {
    name: "Share your profile",
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/share-your-profile.svg",
    href: "/dashboard",
    roles: [userRoles.TUTOR.value]
  },
  // {
  //   name: "Get certified badge",
  //   image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/add-money.svg",
  //   href: "/get-certified-badge",
  //   roles: [userRoles.TUTOR.value]
  // },
  {
    name: "Find a Tutor",
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/find-a-tutor.svg",
    href: "/find-tutors",
    roles: [userRoles.STUDENT.value]
  },
  {
    name: "Find a Live Class",
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/find-a-live-course.svg",
    href: "/find-classes",
    roles: [userRoles.STUDENT.value]
  },
  {
    name: "Refer a Friend",
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/refer-a-friend.svg",
    href: "/marketing/referral",
    roles: [userRoles.STUDENT.value]
  },
  {
    name: "Add Money",
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/add-money.svg",
    href: "/finance/wallet",
    roles: [userRoles.STUDENT.value]
  },
  // {
  //   name: "Buy a Gift Card",
  //   image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/add-money.svg",
  //   href: "/share-your-profile",
  //   roles: [userRoles.STUDENT.value]
  // },
  {
    name: "Apply to teach",
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/apply-to-teach.svg",
    href: "/teach-with-us",
    roles: [userRoles.STUDENT.value]
  },
  {
    name: "Update Profile",
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/update-profile.svg",
    href: "/settings/profile",
    roles: [userRoles.STUDENT.value]
  },

  {
    name: "Get Reviews",
    image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/review/get-review-modal.png",
    href: "/dashboard",
    roles: [userRoles.TUTOR.value]
  },
];

export const learnersMenuOptions = {
  VIEW_ALL: {
    name: "learners",
    label: "View all",
    value: "view_all",
    to: "/crm/clients"
  }
}

export const sessionCompletedMenuOptions = {
  VIEW_ALL: {
    name: "sessionCompleted",
    label: "View all",
    value: "view_all",
    to: "/calendar"
  }
}

export const myBalanceMenuOptions = {
  WALLET: {
    label: "Wallet",
    value: "wallet",
    to: "/finance/wallet"
  }
}

export const reviewEarnedMenuOptions = {
  GET_MORE_REVIEWS: {
    name: "moreReviews",
    label: "Get more reviews",
    value: "get_more_reviews",
    to: ""
  },
  VIEW_ALL: {
    name: "allReviews",
    label: "View all",
    value: "view_all",
    to: ""
  }
}

export const bookingMenuOptions = {
  VIEW_ALL: {
    name: "booking",
    label: "View all",
    value: "view_all",
    to: "/finance/bookings"
  }
}

export const upcomingSessionFilterConst = {
  THIS_WEEK: {
    label: "This Week",
    value: "this_week",
  },
  THIS_MONTH: {
    label: "This Month",
    value: "this_month",
  },
  THIS_YEAR: {
    label: "This Year",
    value: "this_year",
  },
}

export const activeUserRoleOptions = {
  STUDENT: {
    label: "Student",
    value: "student"
  },
  TUTOR: {
    label: "Tutor",
    value: "tutor"
  }
}

export const getSessionFilterQuery = (selectedSessionFilter) => {
  const currentDate = dayjs()?.tz(timeZone);
  const queryParams = {
    minStartDateTime: currentDate?.utc()?.format("YYYY-MM-DD HH:mm:ssZ")
  }
  if (selectedSessionFilter === upcomingSessionFilterConst?.THIS_WEEK?.value) {
    const futureFormattedDate = currentDate?.add(7, 'day')?.utc()?.format("YYYY-MM-DD HH:mm:ssZ")
    queryParams['maxEndDateTime'] = futureFormattedDate
  }
  if (selectedSessionFilter === upcomingSessionFilterConst?.THIS_MONTH?.value) {
    const remainingDays = currentDate.daysInMonth() - currentDate.date()
    const futureFormattedDate = currentDate?.add(remainingDays, 'day')?.utc()?.format("YYYY-MM-DD HH:mm:ssZ")
    queryParams['maxEndDateTime'] = futureFormattedDate
  }
  if (selectedSessionFilter === upcomingSessionFilterConst?.THIS_YEAR?.value) {
    const remainingDays = currentDate?.endOf('year').diff(currentDate, 'day')
    const futureFormattedDate = currentDate?.add(remainingDays, 'day')?.utc()?.format("YYYY-MM-DD HH:mm:ssZ")
    queryParams['maxEndDateTime'] = futureFormattedDate
  }

  return queryParams;
}

export const lmsModalsQueryString = {
  createQuiz: {
    key: "create-a-quiz"
  },
  createCourse: {
    key: "create-a-course"
  },
  createQuestion: {
    key: "create-a-question"
  }
}