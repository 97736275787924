const CourseCardLoader = ({ isLoading }) => {
    return isLoading ? (
        <div className='w-[22rem] h-full flex flex-col bg-white rounded-lg shadow-[0_0_10px_1px_rgba(0,0,0,0.1)]'>
            <div className='animate-pulse w-full h-48 overflow-hidden relative bg-slate-300'>
                <div className='rounded-t-lg overflow-hidden h-full w-full'>
                </div>

                <div className='absolute right-2 top-2 p-3 rounded-full w-10 h-10 bg-slate-200'>
                </div>
            </div>
            <div className='animate-pulse flex w-full flex-col gap-3 p-5 pt-3'>
                <div className='flex flex-col gap-5'>
                    <div className='flex flex-col justify-between gap-5'>
                        <div className='flex justify-between items-center'>
                            <span className='w-16 py-2 flex items-center justify-center rounded bg-slate-200'></span>
                            <div className='flex justify-center items-center gap-2'>
                                <span className='w-16 py-2 flex items-center justify-center rounded bg-slate-200'></span>
                                <span className='w-10 py-2 flex items-center justify-center rounded bg-slate-200'></span>

                            </div>
                        </div>
                        <span className='w-full py-3 flex items-center justify-center rounded bg-slate-200'></span>
                    </div>
                    <div className='flex flex-col justify-between gap-5'>
                        <div className='flex justify-between items-center'>
                            <div className='flex justify-start gap-2'>
                                <div className='relative w-12 h-12 rounded-full bg-slate-200'>

                                    <div className='absolute right-0.5 bottom-0.5 w-3 h-3 rounded-full'>
                                    </div>
                                </div>
                                <div className='flex flex-col justify-evenly items-start'>
                                    <div className='flex justify-center items-center gap-2'>

                                        <span className={"w-12 h-3 bg-slate-200"}></span>
                                    </div>
                                    <span className={"w-6 h-3 bg-slate-200"}></span>
                                    <div className='flex justify-start items-center gap-0.5'>


                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col justify-evenly items-center gap-1 '>
                                <span className='w-12 h-3 bg-slate-200'></span>
                                <span className='w-12 h-3 bg-slate-200'></span>

                            </div>
                        </div>
                        <hr />
                        <div className='flex justify-between items-center'>
                            <div className='flex items-center gap-2'>
                                <span className='w-28 h-4 bg-slate-200'></span>
                            </div>
                            <span className='w-28 py-2 flex justify-center items-center border rounded-full bg-slate-200'>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null
}

export default CourseCardLoader