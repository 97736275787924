import { billingCycles, subscriptionType } from "redux/chargeBee/chargeBee.const"

const addUserItemDetailPayload = {
    userItemId: null,
    productFamily: "",
    name: "",
    description: "",
    type: subscriptionType?.PLAN?.value
}

const addItemPriceDetailPayload = {
    itemId: null,
    itemPriceId: null,
    name: "",
    description: "",
    priceModel: "",
    currencyCode: "",
    price: "",
    billingCycleType: billingCycles?.FOREVER?.value,
    billingCycle: "",
    period: "",
    periodUnit: "",
    showDescriptionInInvoices: false,
    showDescriptionInQuotes: false
}

export const CHARGEBEE_INITIAL_STATE = {
}