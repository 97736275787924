import * as React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { FaInfoCircle, FaSpinner, FaTimes } from "react-icons/fa";
import { FiEdit } from 'react-icons/fi';
import { AiOutlineCheckCircle } from "react-icons/ai";

import { editBtnConst, skillsToolTip } from "pages/auth/editCourse/data";
import EditAndDoneBtn from "pages/auth/editCourse/Components/EditAndDoneBtn";

import { updateOfferingByCourseId } from "redux/offering/offering.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setOfferingCourse } from "redux/offering/offering.slice"

const SkillChipView: React.FC<{ onRemove?: () => void; name: string; editMode: boolean }> = ({
  editMode,
  name,
  onRemove,
}) => (
  <div className="flex items-center px-3 py-1 bg-primary-light rounded-lg">
    <p className="bg-primary-light rounded-lg text-text-900 font-bodyPri font-normal text-sm">
      {name}
    </p>
    <button className="pl-3 cursor-pointer">
      {editMode && <FaTimes className="font-light text-secondary-dark" onClick={onRemove} />}
    </button>
  </div>
);

export const SkillsSection = ({ editBtn, setEditBtn }: any) => {
  const { offeringCourse, addOffering } = useAppState((s) => s.offering)

  const dispatcher = useAppDispatcher();
  const { courseId } = useParams()

  const [newSkill, setNewSkill] = React.useState("");

  const onHandleSaveSkill = () => {
    if (!newSkill || newSkill?.split(" ")?.length > 3) {
      toast.warn("Skills can have upto 3 words")
      return;
    }
    const skillTag = newSkill.trim()
    const mySkills = offeringCourse?.offeringCourse?.skills
    const filteredSkills = offeringCourse?.offeringCourse?.skills?.filter((skill: any) => skill?.skill === skillTag)
    if (filteredSkills?.length > 0) {
      toast.warning("Duplicate skills not allowed!")
      setNewSkill("");
      const element = document.getElementById("skill")
      element?.focus()
      return;
    }

    dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, skills: [...mySkills, { skill: skillTag }] }))
    setNewSkill("");
    const element = document.getElementById("skill")
    element?.focus()
  }

  const onSave = () => {
    if (editBtn === editBtnConst.skills.value && offeringCourse?.offeringCourse?.skills?.length < 5) {
      toast.warning("Enter at least 5 skills")
      return;
    }
    dispatcher(updateOfferingByCourseId(Number(courseId),
      {
        courseType: offeringCourse?.offeringCourse?.courseType,
        skills: offeringCourse?.offeringCourse?.skills
      }
    ))
    setEditBtn(null)
  }

  const onCancel = () => {
    setEditBtn(null)
  }

  const removeSkill = React.useCallback(
    (skill: string) => {
      const newSkills = offeringCourse?.offeringCourse?.skills?.filter((skills: any) => skills?.skill !== skill);
      dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, skills: newSkills }));
    },
    [dispatcher, offeringCourse?.offeringCourse?.skills]
  );

  const handleSave = (e: any) => {
    if ((e.keyCode === 9 || e.keyCode === 13) && newSkill.trim()) {
      onHandleSaveSkill()
    };
  }

  const onAddSkills = (newSkill: any) => {
    if (!newSkill || newSkill?.split(" ")?.length > 3) {
      toast.warn("Skills can have upto 3 words")
      return;
    }
    const skillTag = newSkill.trim()
    const mySkills = offeringCourse?.offeringCourse?.skills
    const filteredSkills = offeringCourse?.offeringCourse?.skills?.filter((skill: any) => skill?.skill === skillTag)
    if (filteredSkills?.length > 0) {
      toast.warning("Duplicate skills not allowed!")
      setNewSkill("");
      const element = document.getElementById("skill")
      element?.focus()
      return;
    }

    dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, skills: [...mySkills, { skill: skillTag }] }))
    setNewSkill("");
    const element = document.getElementById("skill")
    element?.focus()
  }

  return (
    <div
      className={cn(
        "p-5 bg-white space-y-3"
      )}
    >
      <div className="relative flex items-center justify-between">
        <div className={"w-full flex items-center justify-start gap-1"}>
          <div className={"relative has-tooltip cursor-pointer flex items-start justify-start -mt-9 md:-mt-2.5"}>
            <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
            <span
              className={cn(
                "w-56 px-2 py-0.5 font-bodyPri font-normal text-xs rounded shadow-lg bg-text-900 text-text-50 tooltip top-7",
                "flex flex-col items-start justify-start overflow-hidden"
              )}
            >
              {skillsToolTip}
            </span>
          </div>
          <div className="w-full flex flex-col items-start justify-start md:flex-row md:items-center gap-1 md:gap-3 pb-1 border-b">
            <p className="text-xl font-medium font-bodyPri tracking-wide text-text-900 whitespace-nowrap">
              {"Skills you will gain"}
            </p>
            <p className="text-base text-text-700 font-normal whitespace-nowrap">
              ({"Benefits from this class"})
            </p>
            {addOffering?.isLoading &&
              <FaSpinner className={"text-primary-main animate-spin"} />
            }
          </div>
        </div>
        <button
          className={"absolute right-1 top-0"}
        // onClick={() => {
        //   if (editMode && offeringCourse?.offeringCourse?.skills?.length < 5) {
        //     toast.warning("Enter at least 5 skills")
        //   }
        //   else {
        //     setEditMode(!editMode)
        //   }}}
        >
          {editBtn === editBtnConst.skills.value &&
            <EditAndDoneBtn onSave={onSave} onCancel={onCancel} />
          }
          {!editBtn &&
            <FiEdit className="text-text-500 text-lg" onClick={() => setEditBtn(editBtnConst.skills.value)} />
          }


        </button>
      </div>

      {editBtn !== editBtnConst.skills.value &&
        <div className="border border-secondary-main border-dashed flex flex-wrap py-5 space-x-3 space-y-2">
          <div></div>
          {offeringCourse?.offeringCourse?.skills?.map((skill: any, index: number) => (
            <SkillChipView
              key={index}
              name={skill?.skill}
              editMode={false}
            />
          ))}
        </div>
      }
      {(editBtn === editBtnConst.skills.value) &&
        <div className="border-2 border-secondary-main border-solid flex flex-wrap py-5 space-x-3 space-y-2">
          <div></div>
          {offeringCourse?.offeringCourse?.skills?.map((skill: any, index: number) => (
            <SkillChipView
              key={index}
              name={skill?.skill}
              editMode={true}
              onRemove={() => { removeSkill(skill?.skill) }}
            />
          ))}
          <div className="flex items-center w-40 space-x-2">
            <div className="flex items-center justify-center gap-1.5">
              <input
                className="w-40 h-8 rounded-lg mt-1 px-2 py-0 border-2 border-gray-100 focus:outline-none"
                type="text"
                placeholder={"Skill name"}
                id="skill"
                autoFocus={true}
                minLength={3}
                value={newSkill}
                onChange={(e) => {
                  setNewSkill(e.target.value);
                }}
                onKeyDown={handleSave}
              />
              <AiOutlineCheckCircle className={"text-2xl text-text-800 cursor-pointer"} onClick={() => onAddSkills(newSkill)} />
            </div>
          </div>
        </div>
      }

      {offeringCourse?.offeringCourse?.skills?.length < 5 && (
        <div className="flex items-center justify-center pt-3 font-bodyPri font-normal text-red-500 text-xs">
          ({"Minimum 5 Skills"})
        </div>
      )}
    </div>
  );
};