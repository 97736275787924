import React from "react";

//Style imports
import {
  StyledChatViewFileImageContainer,
  StyledChatViewFileImageTime,
  StyledChatViewFileImage,
} from "./ChatViewFile.styles";

export default function ChatViewFileImage(props) {
  const { fileName, url, timeToDisplay, isSender } = props;

  return (
    <StyledChatViewFileImageContainer isSender={isSender}>
      <StyledChatViewFileImage
        src={url}
        alt={fileName}
        referrerPolicy="no-referrer"
      />
      <StyledChatViewFileImageTime isSender={isSender}>
        {timeToDisplay}
      </StyledChatViewFileImageTime>
    </StyledChatViewFileImageContainer>
  );
}
