import { useNavigate } from "react-router-dom";
import { cn } from "utils/cn.utils";

import format from 'string-format';

import { FiEdit } from "react-icons/fi";
import NameBlueTickContent from "components/nameBlueTickContent/NameBlueTickContent";

import { heroCard } from 'pages/auth/dashboard/data';

import ComponentLoader from "components/loader/ComponentLoader";

import ActiveRoleContainer from "./ActiveRoleContainer";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setSideOverPanel } from "../store/DashboardSlice";
import { userRoles } from 'redux/auth/auth.const';
import { blueTickEnum } from "redux/user/user.const";

import { pagesInfo } from "utils/pagesInfo";

const HeroCard = () => {
  const { locals } = useAppState((s) => s.local)
  const { user } = useAppState((s) => s.user)

  const dispatcher = useAppDispatcher();
  const navigate = useNavigate()

  const onClick = () => {
    dispatcher(setSideOverPanel({ status: true }));
  };

  const onHandleViewProfile = () => {
    window.open(`${pagesInfo?.TUTOR?.pagePath}/${user?.user?.userId}`, "_blank")
  }

  return (
    <div className="relative px-2 py-4 w-full bg-white rounded-lg sm:px-8 sm:py-5">
      {user?.isLoading && <ComponentLoader isLoading={user?.isLoading} />}
      <div className="flex flex-col-reverse items-center justify-between w-full sm:flex-row gap-5 mt-2">

        <div className="w-full max-w-sm px-3 space-y-5">
          <div className={"flex items-center justify-start gap-1.5"}>
            <div className="text-xl text-text-900 font-medium font-bodyPri">
              {format(heroCard.title, user?.user?.firstName + " " + user?.user?.lastName)}
            </div>
            <NameBlueTickContent isShowBlueTick={user?.user?.blueTick === blueTickEnum?.YES?.value} />
          </div>
          <div className="text-lg font-normal font-bodyPri text-text-700">
            {locals.userRole === userRoles.TUTOR.value &&
              format(heroCard.tutorText, user?.user?.tutor?.noOfSessionTaken, user?.user?.tutor?.teachingScores)
            }
            {locals.userRole === userRoles.STUDENT.value &&
              format(heroCard.studentText, user?.user?.student?.noOfSessionTaken, user?.user?.student?.learningScores)
            }
          </div>
          <button onClick={onClick} className={cn(
            "px-3 py-1 border-2 border-primary-main w-min rounded-xl whitespace-nowrap",
            "text-primary-main font-bodyPri font-medium",
            "hover:bg-primary-dark hover:text-white hover:border-primary-dark cursor-pointer"
          )}>
            {"Do Something"}
          </button>
        </div>

        <div className="w-full flex justify-center sm:justify-end sm:w-min">
          <div className={"w-full flex flex-col items-center justify-center gap-2"}>
            <div className="h-36 aspect-square overflow-hidden rounded-full ring ring-primary-light">
              <img
                src={user?.user?.profilePicUrl}
                alt={"profile-img"}
                className={"w-full h-full object-cover object-top"}
              />
            </div>
            {(locals?.userRole === userRoles?.TUTOR?.value) &&
              <div className={"flex items-center justify-center gap-3"}>
                <span
                  onClick={onHandleViewProfile}
                  className={"font-bodyPri font-normal text-primary-dark text-base hover:underline hover:text-secondary-dark cursor-pointer text-center"}
                >
                  {"View Profile"}
                </span>
                <FiEdit
                  onClick={() => navigate(`${pagesInfo?.SETTINGS?.pagePath}/profile`)}
                  className={"text-text-700 text-md cursor-pointer"}
                />
              </div>
            }
          </div>
        </div>
      </div>
      <ActiveRoleContainer />
    </div>
  );
};

export default HeroCard;