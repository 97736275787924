import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';

import FullPageLoader from 'components/loader/FullPageLoader';

import PageHeader from 'pages/auth/editDownload/commonComponents/PageHeader';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getOwnerUserDownloadDetail } from 'redux/downloads/downloads.request';

import { useTitle } from 'hooks/useTitle';
import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetOwnerUserDownloadDetail, resetAddUserDownloadDetail } from 'redux/downloads/downloads.slice';

import { pagesInfo } from 'utils/pagesInfo';

const EditDownloadPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)
    const ownerUserDownloadDetail = useAppState((state) => state.downloads.ownerUserDownloadDetail)

    const dispatcher = useAppDispatcher()
    const { downloadId } = useParams()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.EDIT_DOWNLOAD))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (user?.user) {
            setTitle({
                ...title,
                title: `${user?.user?.firstName}, Welcome to Edulyte Downloads`
            })
        }
    }, [user?.user, currentPageInfo])

    useEffect(() => {
        return () => {
            dispatcher(resetOwnerUserDownloadDetail())
            dispatcher(resetAddUserDownloadDetail())
        }
    }, [])

    useEffect(() => {
        if (downloadId && user?.user?.userId) {
            dispatcher(getOwnerUserDownloadDetail(downloadId, user?.user?.userId))
        }
    }, [downloadId, user?.user?.userId])

    if (ownerUserDownloadDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={ownerUserDownloadDetail?.isLoading} />
        )
    }

    if (ownerUserDownloadDetail?.message) {
        return (
            <div className='w-screen h-screen flex items-center justify-center'>
                <span className='w-full font-bodyPri font-normal text-red-500 text-base tracking-wide text-center'>
                    {ownerUserDownloadDetail?.message}
                </span>
            </div>
        )
    }

    return (
        <AnimatePresence>
            <div className={'space-y-3'}>
                <PageHeader />

                <div className={'w-full px-3'}>
                    <Outlet />
                </div>
            </div>
        </AnimatePresence>
    )
}

export default EditDownloadPage;