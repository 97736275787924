import { DEFAULT_COURSE_PAGE, DEFAULT_COURSE_RECORDS } from "redux/course/course.const";

export const pageHeading = {
  heading: "Group Classes",
  subHeading: "Find the list of your lessons, see details, clone and modify them!",
  headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/offerings.svg"
}

export const menuItems = {
  MAKE_PRIVATE: {
    label: 'Make Private',
    value: 'private'
  },
  MAKE_PUBLIC: {
    label: 'Make Public',
    value: 'public'
  },
  SHARE_LINK: {
    label: 'Share Link',
    value: 'share_link'
  },
  PREVIEW: {
    label: 'Preview',
    value: 'preview'
  },
  CLONE: {
    label: 'Clone',
    value: 'clone'
  },
  MODIFY_DETAILS: {
    label: 'Modify Details',
    value: 'modify_details'
  },
  DELETE: {
    label: "Delete",
    value: "delete"
  },
  VIEW: {
    label: "View",
    value: "view"
  },
  ADD_SESSIONS: {
    label: "Future Sessions",
    value: "add_sessions"
  }
}

export const menu = {
  ONE_ON_ONE: {
    DRAFT: {
      public: [
        {
          label: 'Make Private',
          value: 'private'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
        {
          label: 'Delete',
          value: 'delete'
        }
      ],
      private: [
        {
          label: 'Make Public',
          value: 'public'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
        {
          label: 'Delete',
          value: 'delete'
        }
      ],
      hidden: [
        {
          label: 'Make Public',
          value: 'public'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
        {
          label: 'Delete',
          value: 'delete'
        }
      ],
    },
    UNDER_REVIEW: {
      public: [
        {
          label: 'Preview',
          value: 'preview'
        },
        {
          label: 'Clone',
          value: 'clone'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
      ],
      private: [
        {
          label: 'Preview',
          value: 'preview'
        },
        {
          label: 'Clone',
          value: 'clone'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
      ],
      hidden: [
        {
          label: 'Preview',
          value: 'preview'
        },
        {
          label: 'Clone',
          value: 'clone'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
      ],
    },
    PUBLISHED: {
      public: [
        {
          label: 'Share Link',
          value: 'share_link'
        },
        {
          label: 'Clone',
          value: 'clone'
        },
        {
          label: 'Make Private',
          value: 'private'
        },
        {
          label: 'View',
          value: 'view'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
      ],
      private: [
        {
          label: 'Share Link',
          value: 'share_link'
        },
        {
          label: 'Clone',
          value: 'clone'
        },
        {
          label: 'Make Public',
          value: 'public'
        },
        {
          label: 'View',
          value: 'view'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
      ],
      hidden: [
        {
          label: 'Share Link',
          value: 'share_link'
        },
        {
          label: 'Clone',
          value: 'clone'
        },
        {
          label: 'Make Public',
          value: 'public'
        },
        {
          label: 'View',
          value: 'view'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
      ],
    },
    INACTIVE: {
      public: [
        {
          label: 'Clone',
          value: 'clone'
        },
      ],
      private: [
        {
          label: 'Clone',
          value: 'clone'
        },
      ],
      hidden: [
        {
          label: 'Clone',
          value: 'clone'
        },
      ],
    },
  },
  GROUP: {
    DRAFT: {
      public: [
        {
          label: 'Make Private',
          value: 'private'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
        {
          label: 'Delete',
          value: 'delete'
        }
      ],
      private: [
        {
          label: 'Make Public',
          value: 'public'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
        {
          label: 'Delete',
          value: 'delete'
        }
      ],
      hidden: [
        {
          label: 'Make Public',
          value: 'public'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
        {
          label: 'Delete',
          value: 'delete'
        }
      ],
    },
    UNDER_REVIEW: {
      public: [
        {
          label: 'Preview',
          value: 'preview'
        },
        {
          label: 'Clone',
          value: 'clone'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
      ],
      private: [
        {
          label: 'Preview',
          value: 'preview'
        },
        {
          label: 'Clone',
          value: 'clone'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
      ],
      hidden: [
        {
          label: 'Preview',
          value: 'preview'
        },
        {
          label: 'Clone',
          value: 'clone'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
      ],
    },
    PUBLISHED: {
      public: [
        {
          label: 'Share Link',
          value: 'share_link'
        },
        {
          label: 'Clone',
          value: 'clone'
        },
        {
          label: 'Make Private',
          value: 'private'
        },
        {
          label: 'View',
          value: 'view'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
        {
          label: 'Future Sessions',
          value: 'add_sessions'
        }
      ],
      private: [
        {
          label: 'Share Link',
          value: 'share_link'
        },
        {
          label: 'Clone',
          value: 'clone'
        },
        {
          label: 'Make Public',
          value: 'public'
        },
        {
          label: 'View',
          value: 'view'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
        {
          label: 'Future Sessions',
          value: 'add_sessions'
        }
      ],
      hidden: [
        {
          label: 'Share Link',
          value: 'share_link'
        },
        {
          label: 'Clone',
          value: 'clone'
        },
        {
          label: 'Make Public',
          value: 'public'
        },
        {
          label: 'View',
          value: 'view'
        },
        {
          label: 'Modify Details',
          value: 'modify_details'
        },
        {
          label: 'Future Sessions',
          value: 'add_sessions'
        }
      ],
    },
    INACTIVE: {
      public: [
        {
          label: 'Clone',
          value: 'clone'
        },
      ],
      private: [
        {
          label: 'Clone',
          value: 'clone'
        },
      ],
      hidden: [
        {
          label: 'Clone',
          value: 'clone'
        },
      ],
    },
  }
};

export const searchParamsInfo = {
  page: {
    key: "page"
  },
  records: {
    key: "records"
  },
  status: {
    key: "status"
  }
}

export const courseFilters = {
  [searchParamsInfo.status.key]: {
    key: "status",
    label: "Status"
  }
}


export const getCourseListPayload = async (searchQueryParams) => {

  let requestDataPayload = {
    page: searchQueryParams.get(searchParamsInfo.page.key) || DEFAULT_COURSE_PAGE,
    records: searchQueryParams.get(searchParamsInfo.records.key) || DEFAULT_COURSE_RECORDS
  }
  if (!!searchQueryParams.get(searchParamsInfo.status.key)) {
    requestDataPayload[searchParamsInfo.status.key] = searchQueryParams.get(searchParamsInfo.status.key)?.replaceAll("-", "_")
  }

  return requestDataPayload;
}