import React, { useEffect } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const MUITabs = (props) => {
  const [value, setValue] = React.useState(0);
  const [ABOUT_ME, SKILLS, TOPICS, CLASSES, REVIEWS] =
    props.containerIDs;

  useEffect(() => {
    window.addEventListener("scroll", handleScrollListener);

    return () => {
      window.removeEventListener("scroll", handleScrollListener);
    }
  }, [])

  const handleScrollListener = () => {
    const scrollValue = window.scrollY;

    if (scrollValue >= 0 && scrollValue < 520) {
      setValue(0);
    } else if (scrollValue >= 520 && scrollValue < 720) {
      setValue(1);
    } else if (scrollValue >= 720 && scrollValue < 1020) {
      setValue(2);
    } else if (scrollValue >= 1020 && scrollValue < 1620) {
      setValue(3);
    } else if (scrollValue >= 1620 && scrollValue < 1700) {
      setValue(4);
    } else if (scrollValue >= 1600) {
      setValue(5)
    }
  }

  const handleChange = (event, newValue) => {

    setValue(newValue);

    switch (newValue) {
      case 0:
        handleScroll(ABOUT_ME);
        break;

      case 1:
        handleScroll(SKILLS);
        break;

      case 2:
        handleScroll(TOPICS);
        break;

      case 3:
        handleScroll(CLASSES);
        break;

      case 4:
        handleScroll(REVIEWS);
        break;

      default:
        handleScroll(ABOUT_ME);
        break;
    }
  };

  const handleScroll = (selectedID) => {
    if (selectedID) {
      const section = document.querySelector(`#${selectedID}`);
      // section.scrollIntoView({ behavior: "smooth", block: "start", alignToTop: false });
      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: section.offsetTop - 50,
      });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label={"basic tabs example"}
        >
          {props.tabs &&
            props.tabs.map((ele, idx) => (
              <Tab
                key={idx}
                label={ele.tab}
                {...a11yProps(idx)}
                style={{
                  textTransform: "capitalize",
                  fontSize: 16,
                  fontWeight: 400,
                }}
              />
            ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default MUITabs;
