import { BsCalendarCheck } from 'react-icons/bs'

import { useAppState } from 'hooks/useStore';

import { timeZone, dayjs } from 'utils/dateTime.utils';

const ConfirmReschedule = () => {
    const { rescheduleSessionUserDetail, addSessionUserDetailPayload } = useAppState((state) => state.session)

    return (
        <div className={"w-full flex flex-col items-start justify-start space-y-5"}>
            <div className={"w-full flex flex-col items-center justify-center gap-3"}>
                <BsCalendarCheck className={"text-4xl text-primary-dark"} />
                <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                    {"This Meeting is scheduled"}
                </span>
                <span className={"font-bodyPri font-normal text-text-700 text-sm tracking-wide"}>
                    {"We emailed you and other participants an invitation with all the details."}
                </span>
            </div>

            <div className={"w-full h-0.5 border border-divider-medium"}></div>
            <div className={"grid grid-cols-12 w-full p-5 gap-3"}>
                <span className={"col-start-1 col-span-3 font-bodyPri font-normal text-text-900 text-base"}>
                    {"What"}
                </span>
                <span className={"col-start-5 col-span-full font-bodyPri font-medium text-text-900 text-base"}>
                    {rescheduleSessionUserDetail?.data?.result?.name || "-"}
                </span>

                <span className={"col-start-1 col-span-3 font-bodyPri font-normal text-text-900 text-base"}>
                    {"When"}
                </span>
                <div className={"col-start-5 col-span-full flex flex-col items-start justify-start gap-1.5"}>
                    <del className={"flex flex-col items-start justify-start gap-0.5"}>
                        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                            {dayjs(addSessionUserDetailPayload?.payload?.startDateTime)?.tz(timeZone)?.format("dddd, MMM DD, YYYY")}
                        </span>
                        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                            {dayjs(addSessionUserDetailPayload?.payload?.startDateTime)?.tz(timeZone)?.format("HH:mm A") + " - " + dayjs(addSessionUserDetailPayload?.payload?.endDateTime)?.tz(timeZone)?.format("HH:mm A")}
                        </span>
                    </del>
                    <div className={"flex flex-col items-start justify-start gap-0.5"}>
                        <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                            {dayjs(rescheduleSessionUserDetail?.data?.result?.startDateTime)?.tz(timeZone)?.format("dddd, MMM DD, YYYY")}
                        </span>
                        <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                            {dayjs(rescheduleSessionUserDetail?.data?.result?.startDateTime)?.tz(timeZone)?.format("hh:mm A") + " - " + dayjs(rescheduleSessionUserDetail?.data?.result?.endDateTime)?.tz(timeZone)?.format("hh:mm A")}
                        </span>
                    </div>
                </div>

                <span className={"col-start-1 col-span-3 font-bodyPri font-normal text-text-900 text-base"}>
                    {"Meeting Link"}
                </span>
                <span className={"col-start-5 col-span-full font-bodyPri font-medium text-text-900 text-base truncate"}>
                    {rescheduleSessionUserDetail?.data?.result?.meeting?.meetingUrl || "-"}
                </span>
            </div>
        </div>
    )
}

export default ConfirmReschedule