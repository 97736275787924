import { Outlet } from "react-router-dom";

import LMSNavigationTabs from "../../../../commonComponents/navigations/LMSNavigationTabs";
import { quizSettingsNavigationOptions } from "../../editLMSQuiz.data";

const EditLMSQuizSettingsPage = () => {

    return (
        <div className={"flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3"}>
            <LMSNavigationTabs options={Object.values(quizSettingsNavigationOptions)} />
            <div className="w-full rounded-lg overflow-hidden p-5 bg-white">
                <Outlet />
            </div>
        </div>
    )
}

export default EditLMSQuizSettingsPage;