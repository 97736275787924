import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { cn } from "utils/cn.utils";

import {
    ListItem,
    ItemLink,
    ItemIcon,
    ItemName,
    ListItems,
} from './SideNavBarStyle';

import { IoIosArrowForward } from "react-icons/io";

import SideBarSubMenuItem from "./SideBarSubMenuItem";

import ToolTipView from "components/tooltipView";

import { createLogout } from "redux/auth/auth.request"

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { userRoles } from "redux/auth/auth.const";

const SideBarItem = ({ dataItem, visible }) => {
    const { modal, locals } = useAppState((s) => s.local)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation();

    const [activeMenuItem, setActiveMenuItem] = useState(dataItem?.value === "lms" ? dataItem?.value : null);

    const itemNameVariants = { close: { width: '0px' }, open: { width: '100%' } }

    const transition = { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }

    // useEffect(() => {
    //     const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    //     const topLevelPath = pathSegments[0];
    //     setActiveMenuItem(topLevelPath);

    // }, [location.pathname]);

    useEffect(() => {
        if (dataItem?.value !== "lms") {
            setActiveMenuItem(location.pathname?.startsWith(dataItem?.path) ? dataItem?.value : null);
        }
    }, [location.pathname]);


    const handleComingSoon = () => {
        if (["Buy Gift Card"].includes(dataItem.name)) {
            dispatcher(setModal({
                ...modal,
                [modalConst.COMING_SOON_MODAL.stateKey]: true
            }))
        }
    }

    const handleLogOut = () => {
        if (["Log Out"].includes(dataItem.name)) {
            dispatcher(createLogout())
        }
    }

    if (["Buy Gift Card"].includes(dataItem.name)) {
        return (

            <ToolTipView content={dataItem.name} placement="right" disabled={!!visible}>
                <ListItem
                    isTutor={locals?.userRole === userRoles?.TUTOR?.value}
                    onClick={() => handleComingSoon()}
                >
                    <ItemIcon>
                        <dataItem.icon />
                    </ItemIcon>
                    <ItemName
                        variants={itemNameVariants}
                        initial={'open'}
                        animate={visible ? 'open' : 'close'}
                        transition={transition}
                    >
                        {dataItem.name}
                    </ItemName>
                </ListItem>
            </ToolTipView>
        )
    }

    if (["Log Out"].includes(dataItem.name)) {
        return (
            <ToolTipView content={dataItem.name} placement="right" disabled={!!visible}>
                <ListItem
                    isTutor={locals?.userRole === userRoles?.TUTOR?.value}
                    className={"relative has-tooltip"}
                    onClick={() => handleLogOut()}
                >
                    <ItemIcon>
                        <dataItem.icon />
                    </ItemIcon>
                    <ItemName
                        variants={itemNameVariants}
                        initial={'open'}
                        animate={visible ? 'open' : 'close'}
                        transition={transition}
                    >
                        {dataItem.name}
                    </ItemName>
                </ListItem>
            </ToolTipView>
        )
    }

    const onHandleGroupedMenu = (event) => {
        if (!dataItem?.subMenu || (dataItem?.subMenu?.length === 0)) {
            navigate(dataItem?.path)
        } else {
            if (!activeMenuItem || (activeMenuItem !== dataItem?.value)) {
                setActiveMenuItem(dataItem?.value)
            } else {
                setActiveMenuItem(null)
            }
        }
        event.preventDefault();
    }

    return (
        <>
            <ToolTipView content={dataItem.name} placement="right" disabled={!!visible}>
                <ItemLink to={dataItem?.path}
                    isTutor={locals?.userRole === userRoles?.TUTOR?.value}
                    onClick={onHandleGroupedMenu}
                    className={"relative has-tooltip group"}
                >
                    <ItemIcon>
                        <dataItem.icon />
                    </ItemIcon>
                    <ItemName
                        variants={itemNameVariants}
                        initial={'open'}
                        animate={visible ? 'open' : 'close'}
                        transition={transition}
                    >
                        {dataItem.name}
                    </ItemName>
                    {(dataItem?.subMenu?.length > 0) &&
                        <div className={cn(
                            visible ? "pl-5 flex justify-end" : "hidden"
                        )}>
                            <IoIosArrowForward className={cn(
                                "font-bold text-2xl transition ease-in-out delay-50 duration-100 group-hover:bg-primary-light group-hover:text-primary-main group-hover:scale-110",
                                (locals?.userRole === userRoles?.TUTOR?.value) && "group-hover:!text-slate-700",
                                (activeMenuItem && (activeMenuItem === dataItem?.value)) && "rotate-90",
                                (!activeMenuItem || (activeMenuItem && (activeMenuItem !== dataItem?.value))) && "rotate-0",
                            )}
                            />
                        </div>
                    }
                </ItemLink>
            </ToolTipView>
            {visible && ((dataItem?.subMenu?.length > 0) && (activeMenuItem === dataItem?.value)) &&
                <ListItems isTutor={locals?.userRole === userRoles?.TUTOR?.value}>
                    {dataItem?.subMenu?.map((menuItem, index) => menuItem?.roles?.includes(locals.userRole) && (
                        <SideBarSubMenuItem
                            key={index}
                            menuItem={menuItem}
                            visible={visible}
                        />
                    ))}
                </ListItems>
            }
        </>
    )
}

export default SideBarItem;
