import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import FullPageLoader from 'components/loader/FullPageLoader';

import { getToken } from 'redux/auth/auth.request';
import { setFBUserLogin } from 'redux/firebase/firebase.request';

import { useUser } from 'pages/auth/message/contexts/userContext';
import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setLocals } from "redux/local/local.slice";
import { setClearLogout } from 'redux/auth/auth.slice';
import { LOCAL_INITIAL_STATE } from "redux/local/local.initialState";
import { wsUrl } from 'redux/auth/auth.const';
import { notificationWsUrl } from 'redux/notification/notification.const'

import { edulyteAuthWebSocket } from 'websocket/edulyte.websocket';
import { setUser } from 'redux/user/user.slice';
import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

const PersistLoginRouter = () => {
    const { timeZoneCountryDetail } = useAppState((state) => state.master)
    const { session, logout } = useAppState(s => s.auth)
    const { user } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()
    const userContext = useUser()

    const [persist] = useState(JSON.parse(localStorage.getItem("persist")) || true)

    let currentUserWebSocket = null
    let userPushNotificationWebSocket = null

    const sendNotification = () => {

        /* 
            If we want to show notification when user switch tab or minimize the browser.
            then invoke 
            document.onvisibilitychange = () => {}
        */

        const storedNotifications = localStorage.getItem('notifications') || '[]'

        const userNotifications = JSON.parse(storedNotifications)

        while (userNotifications.length > 0) {

            const userNotification = userNotifications.shift()
            const notification = new Notification("New Notification from Edulyte", {
                icon: "https://www.edulyte.com/wp-content/uploads/2021/08/Edulyte-Favicon-100x100.png",
                body: userNotification?.message
            })

            notification.onclick = () => {
                window.location.href = userNotification?.action_url
            }
        }

        localStorage.setItem('notifications', JSON.stringify(userNotifications))
    }

    const checkPageStatus = () => {

        if (!("Notification" in window)) {
            console.error("This browser does not support system notifications!")
        }

        if (Notification.permission === "granted") {
            sendNotification()
        }

        else if (Notification.permission !== "denied") {

            Notification.requestPermission((permission) => {

                if (permission === "granted") {
                    sendNotification()
                }
            })
        }
    }

    let timeout;
    const loginWindowMessageEvent = (event) => {
        if (event.origin === EDULYTE_APP_DOMAIN_URL && event.data?.login_popup && event.data?.success) {
            if (!session?.isLoggedIn) {
                if (timeout) clearTimeout(timeout)
                timeout = setTimeout(() => {
                    dispatcher(getToken())
                }, 500)
            }
        }
    }

    useEffect(() => {
        if (!session?.isLoggedIn) {
            console.log("login")
            window.addEventListener("message", loginWindowMessageEvent);
        }
        return () => {
            console.log("loggedIn")
            window.removeEventListener("message", loginWindowMessageEvent)
        }
    }, [session?.isLoggedIn])

    useEffect(() => {
        if (!session?.isLoggedIn && persist) {
            dispatcher(getToken())
        }
    }, [])

    // useEffect(() => {
    //     if (session?.isLoggedIn && user?.user?.roles?.length > 1) {
    //         getRoleSwitchModal()
    //     }
    // }, [session?.isLoggedIn])

    useEffect(() => {
        if (session?.accessToken) {
            checkPageStatus()
            getCurrentUserWs()
            getUserPushNotificationWs()
        }

        return () => {
            if (!!currentUserWebSocket && (currentUserWebSocket.readyState === WebSocket.OPEN)) {
                currentUserWebSocket?.close()
            }
            if (!!userPushNotificationWebSocket && (userPushNotificationWebSocket.readyState === WebSocket.OPEN)) {
                userPushNotificationWebSocket?.close()
            }
        }
    }, [session?.accessToken])

    useEffect(() => {
        if (user?.user) {
            setFBUserLogin(user?.user?.userId, userContext)
        }
    }, [user?.user])

    useEffect(() => {
        if (logout?.logout) {
            if (!!currentUserWebSocket &&
                (currentUserWebSocket.readyState === WebSocket.OPEN)) {
                currentUserWebSocket?.close()
            }
            if (!!userPushNotificationWebSocket &&
                (userPushNotificationWebSocket.readyState === WebSocket.OPEN)) {
                userPushNotificationWebSocket?.close()
            }
            dispatcher(setLocals({
                ...LOCAL_INITIAL_STATE.locals,
                currencyCode: timeZoneCountryDetail.timeZoneCountryDetail?.country?.currency?.code,
                timeZone: timeZoneCountryDetail.timeZoneCountryDetail?.timeZone
            }))
            dispatcher(setClearLogout())
        }
    }, [logout?.logout])

    const storeNotifications = (notification) => {

        if (!!notification) {

            const storedNotifications = localStorage.getItem('notifications')

            let notificationList = []

            if (!!storedNotifications) {
                notificationList = JSON.parse(storedNotifications)
            }

            notificationList.push(notification)
            localStorage.setItem('notifications', JSON.stringify(notificationList))
        }
    }

    const getCurrentUserWs = async () => {
        const wsConfig = { url: wsUrl.CURRENT_USER }
        currentUserWebSocket = await edulyteAuthWebSocket(wsConfig)
        currentUserWebSocket.onmessage = async (event) => {
            const userMeObj = JSON.parse(event?.data)
            if (userMeObj?.error) return;
            dispatcher(setUser(userMeObj))
        }
    }

    const getUserPushNotificationWs = async () => {
        const wsConfig = { url: notificationWsUrl.NOTIFICATION_URL }
        userPushNotificationWebSocket = await edulyteAuthWebSocket(wsConfig)
        userPushNotificationWebSocket.onmessage = async (event) => {
            const pushNotification = JSON.parse(event?.data)
            if (pushNotification?.error) return;
            storeNotifications(pushNotification)
            checkPageStatus()
        }
    }

    // const getRoleSwitchModal = async () => {
    //     const isShowModal = await localStorage.getItem("isShowRoleModal")

    //     if (!isShowModal) {
    //         dispatcher(setModal({
    //             ...modal,
    //             [modalConst.ROLE_SWITCH_MODAL.stateKey]: true
    //         }))
    //     }
    // }

    return (
        <>
            {!persist
                ? <Outlet />
                : (session?.isLoading || logout?.isLoading)
                    ? <FullPageLoader isLoading={true} />
                    : <Outlet />
            }
        </>
    )
}

export default PersistLoginRouter;