import { categoryFilterProps } from "redux/category/category.const"

export const CATEGORY_INITIAL_STATE = {
    categoryList: {
        isLoading: false,
        categoryList: null,
        filteredCategoryList: null,
        errorMsg: null
    },
    filterProps: categoryFilterProps
}