import React from 'react'
import { Link } from 'react-router-dom';
import { cn } from "utils/cn.utils"

import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { MdVerified } from "react-icons/md";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { userRoles } from 'redux/auth/auth.const';
import { setModal } from "redux/local/local.slice";
import { modalConst } from 'redux/local/local.const';
import { pagesInfo } from 'utils/pagesInfo';

const TutorFeatures = () => {
    const { locals, modal } = useAppState((state) => state.local)

    const dispatcher = useAppDispatcher()

    const handleSwitchRole = (role) => {
        if (role !== locals.userRole) {
            dispatcher(setModal({
                ...modal,
                [modalConst.ROLE_SWITCH_MODAL.stateKey]: true
            }))
        }
    }

    return (
        <div className={"w-full flex flex-col items-center justify-center gap-3 border border-text-300 rounded-lg px-5 py-3"}>
            <MdVerified className={"text-green-500 text-6xl"} />
            {locals.userRole !== userRoles?.TUTOR?.value &&
                <div
                    className={cn(
                        "flex items-center gap-1 rounded-lg px-2 py-1",
                        "cursor-pointer group"
                    )}
                    onClick={() => handleSwitchRole(userRoles?.TUTOR?.value)}
                >
                    <PublishedWithChangesIcon style={{ fontSize: "18px" }} className={"text-text-800 group-hover:text-secondary-main"} />
                    <span className={cn(
                        "font-bodyPri font-normal text-text-800 text-sm group-hover:text-secondary-main"
                    )}>
                        {"Switch to tutor"}
                    </span>
                </div>
            }
            < div className={"w-full flex flex-col items-center justify-center sm:flex-row sm:items-center sm:justify-between gap-3"}>
                <Link to={`${pagesInfo.USER_RESOURCES.pagePath}/tools`} className={"font-bodyPri font-normal text-base text-primary-main cursor-pointer hover:underline"}>
                    {"Tools"}
                </Link>
                <Link to={pagesInfo?.CREATE_COURSE?.pagePath} className={"pl-0 sm:pl-5 md:pl-10 font-bodyPri font-normal text-base text-primary-main cursor-pointer hover:underline"}>
                    {"Create class"}
                </Link>
                <Link to={`${pagesInfo.TUTOR_PRODUCTS.pagePath}/live-classes`} className={"font-bodyPri font-normal text-base text-primary-main cursor-pointer hover:underline"}>
                    {"My offerings"}
                </Link>
            </div>
        </div >
    )
}

export default TutorFeatures;