import { useNavigate } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { IoIosSearch } from "react-icons/io";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { DEFAULT_COURSE_PAGE } from "redux/course/course.const";

import { pagesInfo } from "utils/pagesInfo";
import { setFilterProps } from 'redux/course/course.slice';

const CourseSearchBar = () => {
    const { filterProps } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const onHandleKeyDownSearch = (e) => {
        if (e.keyCode === 13 && filterProps?.search) {
            navigate(`${pagesInfo?.FIND_COURSES?.pagePath}?&pn=${DEFAULT_COURSE_PAGE}&search=${filterProps?.search?.replaceAll(" ", "-")?.toString()?.toLowerCase()}`)
        }
    }

    return (
        <div className={"w-full flex items-center justify-center px-5 lg:px-0"}>
            <div className={"w-full lg:w-[40vw] rounded-full bg-white border border-divider-medium hover:border-text-900 hover:shadow-all"}>
                <div className={"w-full flex items-center justify-between gap-3 px-5"}>
                    <div className={"w-full flex items-center justify-start gap-2"}>
                        <IoIosSearch className={"text-2xl text-text-600"} />
                        <input
                            type={"text"}
                            placeholder={"Topic/Interest/Keyword"}
                            className={"w-full px-3 py-3 font-bodyPri font-normal text-text-900 text-base focus:outline-none placeholder:truncate"}
                            autoFocus={true}
                            value={filterProps?.search}
                            onChange={(event) => {
                                dispatcher(setFilterProps({
                                    filterProps: { ...filterProps, search: event?.target?.value }
                                }))
                            }}
                            onKeyDown={onHandleKeyDownSearch}
                        />
                    </div>
                    <div className={cn(
                        "px-3 py-0.5 border border-divider-medium bg-divider-medium rounded-full cursor-pointer hover:bg-secondary-dark hover:text-text-50",
                        "font-bodyPri font-normal text-text-800 text-base"
                    )}
                        onClick={() => {
                            if (filterProps?.search) {
                                navigate(`${pagesInfo?.FIND_COURSES?.pagePath}?&pn=${DEFAULT_COURSE_PAGE}&search=${filterProps?.search?.replaceAll(" ", "-")?.toString()?.toLowerCase()}`)
                            }
                        }}
                    >
                        {"Search"}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseSearchBar