import tw, { styled } from "twin.macro";

//geneal styling for chatviewfile
export const StyledChatViewFile = styled.div(({ isSender }) => [
  tw`flex items-center`,
  isSender && tw`justify-end`,
]);

//styling for chatviewfileimage
export const StyledChatViewFileImageContainer = styled.div(({ isSender }) => [
  tw`p-1.5 bg-white shadow-md rounded-md max-width[250px] mb-2`,
  isSender && tw`bg-primary-dark text-white`,
]);

export const StyledChatViewFileImage = styled.img`
  ${tw`rounded-md`}
`;

export const StyledChatViewFileImageTime = styled.p(({ isSender }) => [
  tw`text-right italic text-gray-500 text-xs mt-2`,
  isSender && tw`text-white`,
]);

//Styling for chatviewfiledocument
export const StyledChatViewDocument = styled.div(({ isSender }) => [
  tw`bg-white shadow-md width[250px] max-width[250px] rounded-md p-2 mb-2`,
  isSender && tw`bg-primary-dark text-white`,
]);

export const StyledChatViewFileDocumentName = styled.div`
  ${tw`flex items-center text-white justify-between`}
`;

export const StyledChatViewFileDocumentNameText = styled.div(({ isSender }) => [
  tw`flex items-center bg-primary-main rounded-md mr-2.5 p-1`,
  isSender && tw`bg-primary-main`,
]);

export const StyledChatViewDocumentExtension = styled.div(({ isSender }) => [
  tw`flex justify-between text-xs px-1.5 mt-1.5 text-gray-500`,
  isSender && tw`text-white`,
]);
