import { useLocation, useNavigate } from "react-router-dom";

import { cn } from "utils/cn.utils";

import ToolTipView from "components/tooltipView";

import { SubMenuItemLink, SubMenuItemName } from "./SideNavBarStyle";

import { useAppState } from "hooks/useStore";
import { userRoles } from "redux/auth/auth.const";

const SideBarSubMenuItem = ({ menuItem, visible }) => {
    const { locals } = useAppState((state) => state.local)

    const navigate = useNavigate()
    const location = useLocation()

    const itemNameVariants = { close: { width: '0px' }, open: { width: '100%' } }

    const transition = { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }

    const onHandleSubMenuItems = (event) => {
        if (menuItem?.pathType === 'href') {
            window.open(menuItem?.path, "_blank")
        } else {
            navigate(menuItem?.path)
        }
        event.preventDefault();
    }

    const isActive = location.pathname.startsWith(menuItem.path);

    return (
        <ToolTipView content={menuItem?.name} placement="right" disabled={!!visible}>
            <SubMenuItemLink
                to={menuItem?.path}
                isTutor={locals?.userRole === userRoles?.TUTOR?.value}
                onClick={onHandleSubMenuItems}
                className={cn(
                    isActive && "bg-white text-primary-main hover:bg-primary-light hover:!text-primary-main hover:scale-125",
                    (isActive && (locals?.userRole === userRoles?.TUTOR?.value)) && "bg-white text-[#334155] hover:!text-[#334155] hover:scale-125"
                )}
            >
                <SubMenuItemName
                    variants={itemNameVariants}
                    initial={'open'}
                    animate={visible ? 'open' : 'close'}
                    transition={transition}
                >
                    {menuItem?.name}
                </SubMenuItemName>
            </SubMenuItemLink>
        </ToolTipView>
    )
}

export default SideBarSubMenuItem;