import { motion } from "framer-motion";

import { useAppState } from "hooks/useStore";

import { cn } from "utils/cn.utils";

const ContactSocials = ({ contact }) => {

    const onHandleContactSocial = (social) => {
        window.open(social?.link, "_blank")
    }

    return (contact && !!contact?.contact_socials?.length) && (
        <div className="flex items-center gap-4">
            {contact?.contact_socials.map((social, index) => (
                <motion.button
                    key={index}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ duration: 0.3 }}
                    style={{ color: social?.social?.color }}
                    className={cn(
                        "w-10 aspect-square flex justify-center items-center rounded-full overflow-hidden shadow bg-background-lightBlue hover:bg-white"
                    )}
                    onClick={() => onHandleContactSocial(social)}
                >
                    {(() => {
                        return social?.social?.picture_url
                            ? <img
                                src={social?.social?.picture_url}
                                className={`${social?.social?.color} w-full h-full object-cover`}
                                alt={social?.social?.value}
                            />
                            : null
                    })()}
                </motion.button>
            ))}
        </div>
    );
};

export default ContactSocials;