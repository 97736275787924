import { useMemo, memo, useState, useCallback, useEffect } from 'react';

import { MdOutlineNoteAlt } from 'react-icons/md';

import FloatingLabelInput from 'components/floating/floatingLabelInput';

import SaveAndCancelBtn from 'components/modals/crmModals/commonComponents/SaveAndCancelBtn';

const ContactNoteItem = memo(({
    index = 0,
    isLoading = false,
    isShowSaveBtn = false,
    isShowCancelBtn = false,
    editBtnConst = null,
    setEditBtnConst = () => { },
    noteItem = null,
    onHandleSaveNoteDetail,
    onHandleDeleteNoteDetail,
}) => {

    const [notePayload, setNotePayload] = useState(noteItem)

    const isEditDisabled = useMemo(() => {
        if (editBtnConst && (editBtnConst?.id !== noteItem?.id)) return true;
        else return false;
    }, [noteItem, editBtnConst])

    const isSimilarNoteDetail = useMemo(() => JSON.stringify(notePayload) === JSON.stringify(noteItem), [notePayload, noteItem])

    useEffect(() => {
        setNotePayload(noteItem)
    }, [noteItem])

    const onHandleChangeNoteDetail = useCallback((event) => {
        setNotePayload({
            ...notePayload,
            [event.target.name]: event.target.value
        })
        if (!editBtnConst) {
            setEditBtnConst({
                ...editBtnConst,
                id: notePayload?.id
            })
        }
    }, [notePayload])

    return (
        <div className={"w-full flex flex-col sm:flex-row gap-5 sm:gap-3 border px-3 py-5 rounded shadow-sm"}>
            <div className={"flex justify-between gap-3 mt-2"}>
                <div className="text-lg text-text-700">
                    <MdOutlineNoteAlt />
                </div>
                <div className={"flex sm:hidden"}>
                    <SaveAndCancelBtn
                        isLoading={isLoading}
                        isShowSaveBtn={isShowSaveBtn}
                        isShowCancelBtn={isShowCancelBtn}
                        isBtnInvisible={isEditDisabled}
                        isBtnDisable={isSimilarNoteDetail || !notePayload?.title}
                        saveBtnTooltip={'Save Note'}
                        cancelBtnTooltip={"Remove Note"}
                        onHandleSaveBtn={() => onHandleSaveNoteDetail(notePayload)}
                        onHandleCancelBtn={() => onHandleDeleteNoteDetail(notePayload?.id)}
                    />
                </div>
            </div>
            <div className="flex flex-col gap-5 w-full">
                <FloatingLabelInput
                    labelItem={`note_title${index}${notePayload.id}`}
                    label={"Note Title"}
                    inputName={"title"}
                    value={notePayload?.title}
                    onChange={onHandleChangeNoteDetail}
                    disabled={isEditDisabled}
                    inputClassName={"disabled:px-2 disabled:bg-text-200 disabled:cursor-not-allowed"}
                    customInputContainerStyle={isEditDisabled && "focus-within:!ring-none hover:!border-divider-lightDark !px-0.5"}
                />
                <textarea
                    className={"border rounded outline-primary-main px-3 py-1 disabled:bg-text-200 disabled:cursor-not-allowed"}
                    placeholder={"Type your notes here"}
                    value={notePayload?.note}
                    name={"note"}
                    onChange={onHandleChangeNoteDetail}
                    disabled={isEditDisabled}
                />
            </div>
            <div className={"hidden sm:flex mt-2 h-fit"}>
                <SaveAndCancelBtn
                    isLoading={isLoading}
                    isShowSaveBtn={isShowSaveBtn}
                    isShowCancelBtn={isShowCancelBtn}
                    isBtnInvisible={isEditDisabled}
                    isBtnDisable={isSimilarNoteDetail}
                    saveBtnTooltip={'Save Note'}
                    cancelBtnTooltip={"Remove Note"}
                    onHandleSaveBtn={() => onHandleSaveNoteDetail(notePayload)}
                    onHandleCancelBtn={() => onHandleDeleteNoteDetail(notePayload?.id)}
                />
            </div>
        </div>
    )
})

export default ContactNoteItem;