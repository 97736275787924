import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "./lmsArticle.const";

class LmsArticleService {
    getLmsArticleList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_ARTICLE_LIST),
            { params: query }
        )

        return response;
    }

    getLmsArticleDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_ARTICLE_DETAIL, params)
        )

        return response;
    }

    createLmsArticleDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_ARTICLE_DETAIL),
            body
        )

        return response;
    }

    updateLmsArticle = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_ARTICLE_DETAIL, params),
            body
        )

        return response;
    }

    deleteLmsArticle = async ({params}) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_ARTICLE_DETAIL, params)
        )

        return response;
    }

    // lms article settings

    // create lms course instructor detail
    createLmsArticleInstructorDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LMS_ARTICLE_INSTRUCTOR_DETAIL),
            body
        )

        return response;
    }

    // update lms course instructor detail
    updateLmsArticleInstructorDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_ARTICLE_INSTRUCTOR_DETAIL, params),
            body
        )

        return response;
    }

    // delete lms course instructor detail
    deleteLmsArticleInstructorDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_ARTICLE_INSTRUCTOR_DETAIL, params)
        )

        return response;
    }
}

export default new LmsArticleService();