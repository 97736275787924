export const learningData = [
    {
        title: 'Find a live group lesson',
        description1: 'Learning in small groups has many benefits.',
        description2: 'Live, personalised, affordable and quality classes within your reach!',
        buttonText: 'EXPLORE CLASSES',
        path: '/find-classes',
        bgImage: 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/find-a-live-group-lesson-learning-never-stop.jpg',
    },
    {
        title: 'Live one-on-one lesson',
        description1: 'Find live tutoring services',
        description2: 'to help you reach your goals quicker',
        buttonText: 'EXPLORE ONE-ON-ONE',
        path: '/find-classes',
        bgImage: 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/live-one-on-one-lesson-learning-never-stop.jpg',
    },
    {
        title: 'Tutoring jobs',
        description1: 'Be your own boss, share your knowledge  anytime, anywhere.',
        description2: 'Freelancing opportunities for high achievers!',
        buttonText: 'APPLY TO TEACH',
        path: '/teach-with-us',
        bgImage: 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/tutoring-jobs-slider-learning-never-stop.jpg',
    }
]