import EditLMSArticleBuilderPage from "./components/articleBuilder/EditLMSArticleBuilderPage";
import EditLMSArticleSettingsPage from "./components/articleSettings/EditLMSArticleSettingsPage";

import ArticleInstructor from "./components/articleSettings/ArticleInstructor";
import GeneralSettings from "./components/articleSettings/ArticleGeneralSettings";

export const lmsArticleTabConst = {
    BUILDER: {
        label: "Builder",
        value: "builder",
        pagePath: "/builder",
        routePath: "builder",
        component: EditLMSArticleBuilderPage
    },
    SETTINGS: {
        label: "Settings",
        value: "settings",
        pagePath: "/settings",
        routePath: "settings",
        component: EditLMSArticleSettingsPage
    }
}

export const articleSettingsNavigationOptions = {
    GENERAL: {
        label: "General settings",
        value: "general",
        pagePath: "/general",
        routePath: "general",
        to: "settings/general",
        component: GeneralSettings
    },
    USER: {
        label: "Instructor",
        value: "user",
        pagePath: "/instructors",
        routePath: "instructors",
        to: "settings/instructors",
        component: ArticleInstructor
    },
}


export const articleInstructorHeaderConst = {
    Image: {
        label: "Image",
        value: "image"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    PERMISSION: {
        label: "Permission",
        value: "permission"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

export const articleInstructorMenuItemConst = {
    MAKE_DEFAULT: {
        label: "Make Default",
        value: "make_default"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    DELETE: {
        label: "Delete",
        value: "delete"
    }
}