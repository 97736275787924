import { useState } from 'react';
import { cn } from "utils/cn.utils";

import {
    TaskItemContainer,
    TaskDetails,
    TaskTitleDesc,
    TaskTitleText,
    TaskDescText,
    TaskAction,
    Pending,
    Submitted,
    UnderReview,
    Verified,
    ArrowToggleIcon,
    TaskTime,
    PendingIcon,
    SubmittedIcon,
    UnderReviewIcon,
    VerifiedIcon,
    Rejected,
    RejectedIcon,
    DisabledButton,
} from 'pages/auth/tutorOnboard/TutorOnboardStyle';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { userDetailStatus } from "redux/onboarding/onboarding.const";
import { setUploadFilePayload } from 'redux/storage/storage.slice';
// import { setUploadDocumentPayload } from 'redux/document/document.slice';
import { modalConst } from 'redux/local/local.const';

const SharePersonalDetails = () => {
    const { modal } = useAppState((state) => state.local)
    // const { uploadDocument } = useAppState(s => s.document)
    const { uploadFile } = useAppState(s => s.storage)
    const { userOnboardingDetail } = useAppState((s) => s.onboarding)

    const dispatcher = useAppDispatcher()

    const [clickedItemIndex] = useState(null);

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const isOpen = clickedItemIndex === 1;

    const isEmailMobileVerified = userOnboardingDetail?.userOnboardingDetail?.user?.isEmailVerified && userOnboardingDetail?.userOnboardingDetail?.user?.isMobileNoVerified

    const handleTaskItems = () => {
        dispatcher(setUploadFilePayload({
            ...uploadFile?.payload,
            fileCategory: "profilePicture"
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.PERSONAL_DETAIL_MODAL.stateKey]: true
        }))
    }

    return (
        <TaskItemContainer index={0}>
            <TaskDetails>
                <span className={cn(
                    "px-2.5 py-0.5 h-auto self-start rounded-full font-bodyPri font-medium text-lg",
                    (!isEmailMobileVerified || !userOnboardingDetail?.userOnboardingDetail?.userDetail?.status) && "border-2 border-text-500 text-text-900 bg-white",
                    (isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.userDetail?.status) && `text-text-50 bg-${userDetailStatus[userOnboardingDetail?.userOnboardingDetail?.userDetail?.status?.toUpperCase()]?.darkColor}`
                )}>
                    {"1"}
                </ span>
                <TaskTitleDesc>
                    <TaskTitleText>{"Your personal information"}</TaskTitleText>
                    <TaskDescText>
                        {"We will keep your contact details private and safe. We need to know your address, preferred name, public profile photo and social media presence. Some fields are optional, we encourage you to share all the details."}
                    </TaskDescText>
                </TaskTitleDesc>
            </TaskDetails>
            <TaskAction>
                {(!isEmailMobileVerified || (isEmailMobileVerified && !userOnboardingDetail?.userOnboardingDetail?.userDetail?.status)) &&
                    <DisabledButton>
                        {"Fill Your Details"}
                    </DisabledButton>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.userDetail?.status === userDetailStatus.PENDING.value) &&
                    <Pending onClick={handleTaskItems}>
                        <PendingIcon />
                        {userDetailStatus[userOnboardingDetail?.userOnboardingDetail?.userDetail?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Pending>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.userDetail?.status === userDetailStatus.SUBMITTED.value) &&
                    <Submitted onClick={handleTaskItems}>
                        <SubmittedIcon />
                        {userDetailStatus[userOnboardingDetail?.userOnboardingDetail?.userDetail?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Submitted>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.userDetail?.status === userDetailStatus.UNDER_REVIEW.value) &&
                    <UnderReview onClick={handleTaskItems}>
                        <UnderReviewIcon />
                        {userDetailStatus[userOnboardingDetail?.userOnboardingDetail?.userDetail?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </UnderReview>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.userDetail?.status === userDetailStatus.APPROVED.value) &&
                    <Verified onClick={handleTaskItems}>
                        <VerifiedIcon />
                        {userDetailStatus[userOnboardingDetail?.userOnboardingDetail?.userDetail?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Verified>
                }
                {(isEmailMobileVerified && userOnboardingDetail?.userOnboardingDetail?.userDetail?.status === userDetailStatus.REJECTED.value) &&
                    <Rejected onClick={handleTaskItems}>
                        <RejectedIcon />
                        {userDetailStatus[userOnboardingDetail?.userOnboardingDetail?.userDetail?.status?.toUpperCase()]?.label}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Rejected>
                }
                <TaskTime>{"1 min"}</TaskTime>
            </TaskAction>
        </TaskItemContainer>
    )
}

export default SharePersonalDetails;