import React, { useState, useEffect, useCallback, useMemo } from "react";
import { cn } from "utils/cn.utils";
import { NavLink as RouterLink } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion";
import tw, { styled } from 'twin.macro';

import { BsQuestionCircle } from "react-icons/bs";
import { FiFileText } from "react-icons/fi";
import { RiMenuFoldFill } from "react-icons/ri";
import { BiChevronDown } from "react-icons/bi";
import { MdCheckBox } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { MdOutlineOndemandVideo } from "react-icons/md";

import ToolTipView from "components/tooltipView";

import { createDeleteLmsCourseStudentSectionLesson } from "redux/edulyteLms/lmsCourse/lmsCourse.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { lmsCourseSectionLessonTypeEnum } from "redux/edulyteLms/lmsCourse/lmsCourse.const";


const RouteLinkItems = styled.div`
    div > a.active > div > div {
        ${tw`!bg-primary-light text-primary-dark hover:text-primary-dark`}
    }
`

const SectionItem = ({ sectionNo, title, section_lessons, getLesson, isExpanded, toggleExpand }) => {
    const { studentLmsCourseDetail: { data: { lms_course_student, lms_course } } } = useAppState(state => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    let lessonNo = useMemo(() => lms_course?.course_sections?.slice(0, (sectionNo - 1))?.reduce((initial, course_section) => [...initial, ...course_section?.lms_course_section_lessons], [])?.length, [lms_course?.course_sections])

    const getIsCompleted = useCallback((section_lesson) => {
        return !!lms_course_student?.lms_course_student_section_lessons?.find(sectionLesson => sectionLesson?.lms_course_section_lesson?.id === section_lesson?.id)
    }, [lms_course_student?.lms_course_student_section_lessons])

    const onHandleComplete = useCallback((section_lesson) => {
        const requestBody = {
            lms_course_student_id: lms_course_student?.id,
            lms_course_section_lesson_id: section_lesson?.id
        }
        dispatcher(createDeleteLmsCourseStudentSectionLesson(requestBody))
    }, [lms_course_student?.id])


    return (
        <>
            <div
                className="group flex justify-between items-center p-4 gap-5 cursor-pointer hover:bg-primary-light"
                onClick={toggleExpand}
            >
                <h2 className="font-bodyPri font-medium text-base text-text-900 line-clamp-2">{`Section ${sectionNo?.toString().padStart(2, "0")}: ${title}`}</h2>
                <span
                    className={`ease-in-out duration-300 ${isExpanded ? "rotate-180" : ""}`}
                >
                    <BiChevronDown size={20} />
                </span>
            </div>
            <AnimatePresence>
                {isExpanded && (
                    <RouteLinkItems className="flex flex-col divide-y divide-divider-darkLight">
                        {section_lessons?.map((section_lesson, index) => (
                            <div className="relative group parent">
                                <div className={"absolute top-2 left-3 cursor-pointer hover:bg-text-300 transition-all ease-in-out delay-100 duration-100 w-8 h-8 rounded-full flex items-center justify-center"}>
                                    {getIsCompleted(section_lesson) ? (
                                        <MdCheckBox size={20} className={"text-green-500"} onClick={() => onHandleComplete(section_lesson)} />
                                    ) : (
                                        <MdCheckBoxOutlineBlank size={20} className={"text-text-700"} onClick={() => onHandleComplete(section_lesson)} />
                                    )}
                                </div>
                                <RouterLink key={index} to={`lesson/${section_lesson?.id}`}>
                                    <motion.div
                                        initial={{ height: 0, opacity: 0 }}
                                        animate={{ height: "auto", opacity: 1 }}
                                        transition={{ duration: 0.1 }}
                                        exit={{ height: 0, opacity: 0 }}
                                    >
                                        <div className="pr-5 pl-12 py-3 group-hover:bg-primary-light cursor-pointer flex flex-col gap-1 text-text-800 group-hover:text-text-900">
                                            <h3 className="h3 font-bodyPri font-normal text-base line-clamp-2">
                                                {`Lesson ${(lessonNo + index + 1)?.toString().padStart(2, "0")}: ${getLesson(section_lesson)?.title}`}
                                            </h3>
                                            <span className="flex items-center gap-2 text-sm text-text-800">
                                                {(section_lesson?.type === lmsCourseSectionLessonTypeEnum.LECTURE.value) && (<MdOutlineOndemandVideo size={15} />)}
                                                {(section_lesson?.type === lmsCourseSectionLessonTypeEnum.ARTICLE.value) && (<FiFileText size={15} />)}
                                                {(section_lesson?.type === lmsCourseSectionLessonTypeEnum.QUIZ.value) && (<BsQuestionCircle size={15} />)}
                                                {lmsCourseSectionLessonTypeEnum[section_lesson?.type?.toUpperCase()]?.label}
                                                {(section_lesson?.time ? " | " : "")}
                                                {section_lesson?.time}
                                            </span>
                                        </div>
                                    </motion.div>
                                </RouterLink>
                            </div>
                        ))}
                    </RouteLinkItems>
                )}
            </AnimatePresence>
        </>
    )
};

const SectionView = ({ activeSection, activeLesson, getLesson, course_sections, onSideBarClose }) => {

    const [expandedSections, setExpandedSections] = useState(new Array(course_sections?.length).fill(false));

    useEffect(() => {
        setExpandedSections(course_sections?.map(course_section => course_section?.id === activeSection?.current?.id))
    }, [activeSection, course_sections])

    const toggleExpand = (index) => {
        const updatedSections = [...expandedSections];
        updatedSections[index] = !updatedSections[index];
        setExpandedSections(updatedSections);
    };

    return (
        <div className={cn(
            "space-y-1 overflow-auto h-screen",
            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-light",
        )}>
            <div className="flex justify-between items-center px-5 py-3 bg-background-darkLight text-text-800 sticky top-0 z-10 drop-shadow-sm">
                <p className="font-medium text-lg">{"Course Content"}</p>
                <ToolTipView content="Expanded view" placement='right'>
                    <span><RiMenuFoldFill size={20} onClick={onSideBarClose} className="cursor-pointer" /></span>
                </ToolTipView>
            </div>
            {course_sections?.map((section, index) => (
                <SectionItem
                    key={index}
                    sectionNo={index + 1}
                    title={section?.title}
                    section_lessons={section?.lms_course_section_lessons}
                    getLesson={getLesson}
                    isExpanded={expandedSections[index]}
                    toggleExpand={() => toggleExpand(index)}
                />
            ))}
        </div>
    );
};

export default SectionView;
