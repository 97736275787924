import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Reorder } from 'framer-motion';

import { libraryTabConst } from 'components/modals/lmsModals/attachLibraryModal/data';

import CreateOptionButton from '../../../../commonComponents/CreateOptionButton';
import AnswerFeedback from '../../../../commonComponents/AnswerFeedback';

import QuestionOptionItem from './components/QuestionOptionItem';

import { updateOrderLmsQuestionOption, createLmsQuestionOptionResource, createLmsQuestionOption } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetLocalTabs, setModal } from 'redux/local/local.slice';
import { setLmsQuestionDetailData } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.slice';
import { modalConst } from 'redux/local/local.const';

import { isOrderUpdated } from 'utils/generators.utils';

const MultipleChoiceOption = () => {
  const { modal, localTabs } = useAppState((state) => state.local)
  const { lmsQuestionDetail, addLmsQuestionOption } = useAppState((state) => state.lms.lmsQuestion)

  const dispatcher = useAppDispatcher()

  const [isOptionOrderUpdated, setIsOptionOrderUpdated] = useState(false)

  useEffect(() => {
    if (localTabs?.tabItem && (localTabs?.tabItem?.tabType === libraryTabConst.RESOURCES.value) && localTabs?.tabItem?.payload?.resourceId && addLmsQuestionOption?.payload?.optionId) {
      const body = {
        question_option_id: addLmsQuestionOption?.payload?.optionId,
        resource_id: localTabs?.tabItem?.payload?.resourceId
      }
      dispatcher(createLmsQuestionOptionResource(body))
      dispatcher(resetLocalTabs())
      dispatcher(setModal({
        ...modal,
        [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: false
      }))
    }
  }, [localTabs])

  const onHandleReorderGroup = (newOrder) => {
    if (newOrder) {
      const isOrderModified = isOrderUpdated(lmsQuestionDetail?.data?.question_options, newOrder)
      if (!isOrderModified) {
        setIsOptionOrderUpdated(false)
        return;
      }
      dispatcher(setLmsQuestionDetailData({
        ...lmsQuestionDetail?.data,
        question_options: newOrder,
      }))
      setIsOptionOrderUpdated(true)
    }
  }

  const onHandleUpdateQuestionOptionOrder = () => {
    if (isOptionOrderUpdated && (lmsQuestionDetail?.data?.question_options?.length > 0)) {
      const body = {
        order: lmsQuestionDetail?.data?.question_options?.map((questionOption) => ({
          id: questionOption?.option_id
        }))
      }
      dispatcher(updateOrderLmsQuestionOption(body, { question_id: lmsQuestionDetail?.data?.id }))
      setIsOptionOrderUpdated(false)
    }
  }

  const onHandleCreateOption = useCallback(() => {
    if (lmsQuestionDetail?.isLoading || addLmsQuestionOption?.isLoading) return;

    if (lmsQuestionDetail?.data?.question_options?.length >= 12) {
      toast.warn("Sorry, you can't add more than 12 answer choices")
      return;
    }

    const body = {
      question_id: lmsQuestionDetail?.data?.id,
      is_correct: false,
      option: ""
    }
    dispatcher(createLmsQuestionOption(body))
  }, [lmsQuestionDetail, addLmsQuestionOption?.isLoading])

  return (
    <div className={'flex flex-col gap-5 font-bodyPri'}>
      <div className={"flex flex-col gap-3"}>
        <div className={'px-3'}>
          <p className={'font-bodyPri font-medium text-text-900 text-lg'}>
            {"Answer Choices:"}
          </p>
        </div>
        <div className={"w-full flex flex-col gap-3"}>
          {(lmsQuestionDetail?.data?.question_options?.length > 0) &&
            <Reorder.Group
              className={'w-full flex flex-col gap-2'}
              values={lmsQuestionDetail?.data?.question_options}
              onReorder={onHandleReorderGroup}
            >
              {lmsQuestionDetail?.data?.question_options?.map((questionOption, index) => (
                <Reorder.Item
                  key={questionOption?.option_id}
                  value={questionOption}
                  onDragEnd={onHandleUpdateQuestionOptionOrder}
                >
                  <QuestionOptionItem
                    key={questionOption?.option_id}
                    index={index}
                    questionOption={questionOption}
                  />
                </Reorder.Item>
              ))}
            </Reorder.Group>
          }
          <CreateOptionButton
            isLoading={addLmsQuestionOption?.isLoading}
            onHandleCreateOption={onHandleCreateOption}
          />
        </div>
      </div>
      {lmsQuestionDetail?.data?.answer?.answer &&
        <AnswerFeedback />
      }
    </div>
  )
}

export default MultipleChoiceOption;