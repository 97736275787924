import { cn } from "utils/cn.utils";

import ButtonLoader from 'components/loader/ButtonLoader';

import { createUserEventlyDetail } from 'redux/evently/evently.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

import { dayjs, timeZone } from 'utils/dateTime.utils';

const EventlyFooter = () => {
    const { addUserEventlyDetail } = useAppState((state) => state.evently)
    const { addProductDetail } = useAppState((state) => state.product)

    const dispatcher = useAppDispatcher()

    const onHandleSave = () => {
        if (addUserEventlyDetail?.isLoading) return;

        const body = {
            title: (addUserEventlyDetail?.payload?.title?.length > 0) ? addUserEventlyDetail?.payload?.title : "(No title)",
            schedule: {
                timezone: timeZone,
                slots: [
                    {
                        startDateTime: dayjs(addUserEventlyDetail?.payload?.startDateTime)?.format("YYYY-MM-DD HH:mm:ss"),
                        endDateTime: dayjs(addUserEventlyDetail?.payload?.endDateTime)?.format("YYYY-MM-DD HH:mm:ss")
                    }
                ]
            },
        }
        if (addUserEventlyDetail?.payload?.selectedGuestsList) {
            body["emails"] = addUserEventlyDetail?.payload?.selectedGuestsList?.map((userEmail) => userEmail?.email)
        }
        if (addUserEventlyDetail?.payload?.meetingLink) {
            body["link"] = addUserEventlyDetail?.payload?.meetingLink
        }
        if (addUserEventlyDetail?.payload?.selectedLocation) {
            body["location"] = addUserEventlyDetail?.payload?.selectedLocation
        }
        if (addUserEventlyDetail?.payload?.isSendEmail) {
            body["isSendEmail"] = addUserEventlyDetail?.payload?.isSendEmail
        }
        if (addUserEventlyDetail?.payload?.description) {
            body["description"] = addUserEventlyDetail?.payload?.description
        }
        if (addProductDetail?.data) {
            body["product_id"] = addProductDetail?.data?.id
        }
        dispatcher(createUserEventlyDetail(body))
    }

    return (
        <div className={"flex justify-end gap-3 px-5"}>
            {/* <div className={cn(
                "w-fit px-3 py-1 flex items-center justify-center rounded-md",
                "hover:bg-background-light cursor-pointer group"
            )}
                onClick={onHandleMoreOptions}
            >
                <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide group-hover:text-text-900"}>
                    {"More options"}
                </span>
            </div> */}
            <div
                className={cn(
                    "w-fit px-10 py-2 flex items-center justify-center rounded-full",
                    "bg-primary-dark hover:opacity-90 cursor-pointer shadow"
                )}
                onClick={onHandleSave}
            >
                {addUserEventlyDetail?.isLoading &&
                    <ButtonLoader isLoading={addUserEventlyDetail?.isLoading} className={"w-fit"} />
                }
                {!addUserEventlyDetail?.isLoading &&
                    <span
                        className={"font-bodyPri font-medium text-text-50 text-base tracking-wide"}>
                        {"Save"}
                    </span>
                }
            </div>
        </div>
    )
}

export default EventlyFooter;