import { useEffect } from 'react';
import { Navigate, useLocation, Outlet } from "react-router-dom";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

export default function UserFinancePage({ allowedRoles }) {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { locals } = useAppState((s) => s.local)
    const { session } = useAppState((s) => s.auth)
    const { user } = useAppState((s) => s.user)

    const dispatcher = useAppDispatcher()
    const location = useLocation()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.USER_FINANCE))
    }, [dispatcher, currentPageInfo])

    return (
        <div className="h-full w-full mx-auto min-h-screen rounded-lg overflow-hidden">
            {session?.isLoggedIn && user?.user?.roles?.find((role) => allowedRoles?.includes(role.role) && role.role === locals.userRole)
                ? <Outlet />
                : <Navigate to={pagesInfo.UNAUTHORIZED.pagePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />
            }
        </div>
    );
}