import { useState, useEffect } from 'react';
import {useNavigate, Outlet } from 'react-router-dom';

import { LinearProgress } from '@mui/material';

import TabComponent from 'components/tab/TabComponent';
import FullPageLoader from 'components/loader/FullPageLoader';

import { getCurrentActiveRoute, getProfileCountDetail, profileTabConst } from 'pages/auth/profile/data';
import { useGetProfileDetails } from 'pages/auth/profile/commonComponents/useGetProfileDetails';
import NameDetails from "pages/auth/profile/commonComponents/NameDetails";

import { useAppState } from "hooks/useStore";
import { userRoles } from "redux/auth/auth.const";

import { pagesInfo } from 'utils/pagesInfo';

const ProfilePage = () => {
  const { locals } = useAppState((s) => s.local)
  const { user, userEducationList, userDetail, userCultureDetail } = useAppState((state) => state.user)
  const { studentUserDetail, studentUserLanguageList } = useAppState((state) => state.student);
  const { tutorUserDetail, tutorUserLanguageList } = useAppState((state) => state.tutor);

  const [totalStepCount, setTotalStepCount] = useState(13)
  const [stepCount, setStepCount] = useState(0)

  const navigate = useNavigate()
  const { isLoading } = useGetProfileDetails()

  useEffect(() => {
    setTotalStepCount(locals.userRole === userRoles?.TUTOR.value ? 15 : 13)
    
    const payload = {
      profilePicUrl: user?.user?.profilePicUrl,
      firstName: user?.user?.firstName,
      lastName: user?.user?.lastName,
      isEducationListEnabled: userEducationList?.userEducationList?.length > 0,
      gender: userDetail?.userDetail?.gender,
      dateOfBirth: userDetail?.userDetail?.dateOfBirth,
      appointment: userDetail?.userDetail?.appointment,
      fromCountry: userCultureDetail?.userCultureDetail?.fromCountry?.country,
      liveInCountry: userCultureDetail?.userCultureDetail?.liveInCountry?.country,
      isStudentRoleEnabled: locals.userRole === userRoles?.STUDENT.value,
      isStudentLanguageListEnabled: studentUserLanguageList?.studentUserLanguageList?.length > 0,
      elevatorPitch: studentUserDetail?.studentUserDetail?.elevatorPitch,
      bio: studentUserDetail?.studentUserDetail?.bio,
      learningStyleBio: studentUserDetail?.studentUserDetail?.learningStyleBio,
      isValidStudentSkills: studentUserDetail?.studentUserDetail?.skills?.length > 4,
      isTutorRoleEnabled: locals.userRole === userRoles?.TUTOR.value,
      isTutorLanguageListEnabled: tutorUserLanguageList?.tutorUserLanguageList?.length > 0,
      elevatorPitch: tutorUserDetail?.tutorUserDetail?.elevatorPitch,
      bio: tutorUserDetail?.tutorUserDetail?.bio,
      teachingStyleBio: tutorUserDetail?.tutorUserDetail?.teachingStyleBio,
      isValidTutorSkills: tutorUserDetail?.tutorUserDetail?.skills?.length > 4,
      videoUrl: tutorUserDetail?.tutorUserDetail?.videoUrl,
    }

    const newCount = getProfileCountDetail(payload)
    setStepCount(newCount)
  }, [
    user?.user,
    userDetail?.userDetail,
    userEducationList?.userEducationList,
    userCultureDetail?.userCultureDetail,
    studentUserLanguageList?.studentUserLanguageList,
    tutorUserLanguageList?.tutorUserLanguageList,
    studentUserDetail?.studentUserDetail,
    tutorUserDetail?.tutorUserDetail
  ])

  if (isLoading) {
    return (
      <FullPageLoader isLoading={true} />
    )
  }

  return (!isLoading && userDetail?.userDetail) && (
    <div className={"w-full min-h-screen bg-white rounded-lg font-bodyPri"}>
      <div className='rounded-t-lg overflow-hidden'>
        <LinearProgress
          variant="determinate"
          value={parseFloat((stepCount / totalStepCount * 100).toFixed(2))}
          className='w-full'
          color={"primary"}
        />
        <NameDetails />
      </div>

      {/* tab section */}
      <div className={'w-full flex flex-col px-5 mt-10 pb-10'}>
        <div className={'w-full overflow-x-scroll scrollbar-thin pb-2 mb-5 sticky top-0 bg-white z-20'}>
          <div className={"flex shadow w-fit border border-text-100 rounded-full overflow-hidden whitespace-nowrap"}>
            <TabComponent
              tabs={Object.values(profileTabConst).filter(tab => tab.roles.includes(locals.userRole)).map((item) => ({ ...item, id: item.value }))}
              activeTab={getCurrentActiveRoute()}
              setActiveTab={(selectedTab) => navigate(`${pagesInfo.SETTINGS.pagePath}${pagesInfo.PROFILE.pagePath}/${selectedTab?.routePath}`)}
              tabStyle={"px-10 py-1.5 font-semibold text-white"}
              pillStyle={"bg-primary-main rounded-full"}
              activeStyle={"!text-primary-dark hover:!text-secondary-dark"}
            />
          </div>
        </div>
       <Outlet />
      </div>
    </div>
  );
};

export default ProfilePage;
