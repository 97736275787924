import { toast } from 'react-toastify';

import { FaSpinner } from 'react-icons/fa';

import DownloadActionButtons from 'pages/auth/editDownload/commonComponents/DownloadActionButtons';
import EmptyDownloadContent from 'pages/auth/editDownload/commonComponents/EmptyDownloadItemContent';

import DownloadFileItem from './DownloadFileItem';
import { downloadTypeConst, fileActionButtons, onHandleValidateFileDetails } from 'pages/auth/editDownload/data';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setAddUserDownloadDetailPayload } from 'redux/downloads/downloads.slice';
import { addUserDownloadDetailPayload } from 'redux/downloads/downloads.const';

const DownloadFiles = () => {
    const uploadFile = useAppState((state) => state.storage.uploadFile)
    const { ownerUserDownloadDetail, addDownloadFileDetail } = useAppState((state) => state.downloads)

    const dispatcher = useAppDispatcher()

    const onHandleButtonClick = async (event) => {
        const { requestPayload, errorMsg } = await onHandleValidateFileDetails(event?.target?.files, downloadTypeConst.FILE.key)
        if (errorMsg) {
            toast.warn(errorMsg)
            return;
        }
        if (requestPayload) {
            dispatcher(setAddUserDownloadDetailPayload({
                ...addUserDownloadDetailPayload,
                ...requestPayload,
                downloadType: downloadTypeConst.FILE.key
            }))
        }
    }

    return (
        <div className={"w-full flex flex-col items-start justify-start gap-5 px-3 py-5 bg-white rounded-lg"}>
            <div className={"w-full flex items-center justify-between gap-5"}>
                <span className={"font-bodyPri font-semibold text-lg md:text-xl text-primary-dark tracking-wide"}>
                    {"File(s)"}
                </span>
                <div className={"flex items-center justify-start gap-2"}>
                    {(uploadFile?.isLoading || addDownloadFileDetail?.isLoading) &&
                        <FaSpinner className={"animate-spin text-xl text-primary-dark"} />
                    }
                    {(!uploadFile?.isLoading && !addDownloadFileDetail?.isLoading) &&
                        <DownloadActionButtons
                            isLoading={uploadFile?.isLoading || addDownloadFileDetail?.isLoading}
                            buttonList={Object.values(fileActionButtons)}
                            onHandleButtonClick={onHandleButtonClick}
                        />
                    }
                </div>
            </div>
            {!!ownerUserDownloadDetail?.data?.files?.length &&
                <div className={"w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 px-4 py-3"}>
                    {ownerUserDownloadDetail?.data?.files?.map((fileItem, index) => (
                        <div className={"col-span-full md:col-span-1"}>
                        <DownloadFileItem index={index} fileItem={fileItem} />
                        </div>
                    ))}
                </div>
            }
            {(ownerUserDownloadDetail?.data && (ownerUserDownloadDetail?.data?.files?.length === 0)) &&
                <EmptyDownloadContent downloadType={downloadTypeConst.FILE.key} />
            }
        </div>
    )
}

export default DownloadFiles