import { useCallback, useEffect, useMemo, useState, memo } from "react";
import { toast } from "react-toastify";

import { FaSpinner } from "react-icons/fa";

import FadedCollapse from "components/fadedCollapse";
import ViewOnlyQuillEditor from "components/textEditor/ViewOnlyQuillEditor";

import UpdateLmsInputText from "pages/auth/edulyteLms/commonComponents/UpdateLmsInputText";
import LmsTextAreaDescription from "pages/auth/edulyteLms/commonComponents/LmsTextAreaDescription";
import LmsEditorDescription from "pages/auth/edulyteLms/commonComponents/LmsEditorDescription";

import { downloadTypeConst } from "../data";
import DownloadPreviews from "./downloadPreviews/DownloadPreviews";
import DownloadFiles from "./downloadFiles/DownloadFiles";

import { uploadFileToS3 } from "redux/storage/storage.request";
import { createDownloadFileDetail, createDownloadPreviewDetail, updateUserDownloadDetail } from 'redux/downloads/downloads.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from "redux/local/local.slice";
import { resetUploadFile } from "redux/storage/storage.slice";
import { resetAddUserDownloadDetailPayload } from "redux/downloads/downloads.slice";
import { setClearThumbnailDetailPayload, setThumbnailDetailPayloadLoading } from "redux/other/other.slice";
import { modalConst } from "redux/local/local.const";
import { downloadPreviewTypeEnum } from "redux/downloads/downloads.const";

const DescriptionContentContainer = memo(({
    isLoading = false,
    contentTitle = "Description",
    descriptionInput = "",
    allowHtmlContent = false,
}) => {

    const contentLength = useMemo(() => {
        const parsedHTML = new DOMParser().parseFromString(`<div>${descriptionInput}</div>`, "text/html");
        const textContent = parsedHTML.querySelector("div")?.textContent || '';
        return textContent.length > 200;
    }, [descriptionInput]);

    return (
        <div className={"w-full px-1 py-2 bg-white rounded-lg space-y-3 hover:bg-text-100"}>
            <div className={"px-3 w-full flex items-center justify-between gap-3"}>
                <span className={"font-bodyPri font-semibold text-lg md:text-xl tracking-wide text-primary-dark"}>
                    {contentTitle}
                </span>
                <div className={""}>
                    {isLoading &&
                        <div className={"flex justify-end gap-1 pr-4"}>
                            <span className={"font-bodyPri font-normal text-primary-dark text-sm"}>
                                {"Please wait"}
                            </span>
                            <FaSpinner className={"animate-spin text-lg text-primary-dark"} />
                        </div>
                    }
                </div>
            </div>
            {!allowHtmlContent &&
                <div className={"px-3"}>
                    <span className={"font-bodyPri font-normal text-text-800 text-base cursor-pointer line-clamp-2"}>
                        {descriptionInput}
                    </span>
                </div>
            }
            {allowHtmlContent &&
                <FadedCollapse
                    isShowMore={contentLength}
                    maxHeight={contentLength ? "7.5rem" : '3rem'}
                >
                    <ViewOnlyQuillEditor
                        editorContent={descriptionInput}
                        editorClassName={""}
                    />
                </FadedCollapse>
            }
        </div>
    )
});

const BuilderPage = () => {
    const { modal } = useAppState((state) => state.local)
    const { uploadFile } = useAppState((state) => state.storage)
    const { user } = useAppState((state) => state.user)
    const { ownerUserDownloadDetail, addUserDownloadDetail, modifyOwnerUserDownloadDetail } = useAppState((state) => state.downloads)
    const { thumbnailDetail } = useAppState((state) => state.other)

    const dispatcher = useAppDispatcher()

    const [titleInputStatus, setTitleInputStatus] = useState("")
    const [subTitleInputStatus, setSubTitleInputStatus] = useState("")

    useEffect(() => {
        if (uploadFile?.data && (uploadFile?.data?.length > 0)) {
            if ([downloadTypeConst.PREVIEW.key]?.includes(addUserDownloadDetail.payload.downloadType)) {
                if (Object.values(downloadPreviewTypeEnum)?.map((item) => item?.value)?.includes(addUserDownloadDetail.payload.type)) {
                    dispatcher(createDownloadPreviewDetail({
                        type: addUserDownloadDetail.payload.type,
                        downloadable_id: ownerUserDownloadDetail?.data?.id,
                        preview_url: uploadFile?.data[0]?.fileUrl
                    }))
                } else alert("Something went wrong!")
            } else {
                dispatcher(createDownloadFileDetail({
                    type: addUserDownloadDetail.payload.type,
                    downloadable_id: ownerUserDownloadDetail?.data?.id,
                    file_url: uploadFile?.data[0]?.fileUrl
                }))
            }
            dispatcher(resetUploadFile())
            dispatcher(resetAddUserDownloadDetailPayload())
        }
    }, [uploadFile?.data])

    useEffect(() => {
        if (thumbnailDetail?.thumbnailDetailPayload?.url) {
            dispatcher(setThumbnailDetailPayloadLoading(true))
            dispatcher(createDownloadPreviewDetail({
                type: downloadPreviewTypeEnum.IMAGE.value,
                downloadable_id: ownerUserDownloadDetail?.data?.id,
                preview_url: thumbnailDetail?.thumbnailDetailPayload?.url
            }))
            setTimeout(() => {
                dispatcher(setClearThumbnailDetailPayload())
                dispatcher(setModal({
                    ...modal,
                    [modalConst.PHOTO_SEARCH_MODAL.stateKey]: false
                }))
            }, 2000)
        }
    }, [thumbnailDetail?.thumbnailDetailPayload?.url])

    useEffect(() => {
        if (addUserDownloadDetail?.payload?.selectedFile) {
            dispatcher(uploadFileToS3(addUserDownloadDetail?.payload?.selectedFile?.fileList, user?.user?.userId))
        }
    }, [addUserDownloadDetail?.payload?.selectedFile])

    const onHandleSaveTitle = (editedTitle) => {
        setTitleInputStatus("Saving...")
        if (!editedTitle || (editedTitle?.length === 0)) {
            toast.warn("Please enter title ...")
            setTitleInputStatus("Saved")
            return;
        }
        const body = {
            title: editedTitle
        }
        const payload = {
            isShowToast: false
        }
        dispatcher(updateUserDownloadDetail(ownerUserDownloadDetail?.data?.id, body, payload))

        setTimeout(() => {
            setTitleInputStatus("")
        }, 1000)
    }

    const onHandleSaveSubTitle = (editedSubTitle) => {
        setSubTitleInputStatus("Saving...")
        const body = {
            subtitle: editedSubTitle
        }
        const payload = {
            isShowToast: false
        }
        dispatcher(updateUserDownloadDetail(ownerUserDownloadDetail?.data?.id, body, payload))

        setTimeout(() => {
            setSubTitleInputStatus("")
        }, 1000)
    }

    const onHandleSaveDescription = useCallback((content) => {
        dispatcher(updateUserDownloadDetail(ownerUserDownloadDetail?.data?.id, { description: content }))
    }, [ownerUserDownloadDetail?.data?.id])

    return (
        <div className={'w-full space-y-5 px-0 md:px-1.5 lg:px-3'}>
            <UpdateLmsInputText
                isLoading={modifyOwnerUserDownloadDetail?.isLoading}
                isShowInputLabel={true}
                containerClassName={"mt-3 mb-1"}
                inputClassName={"font-tagLine font-semibold text-primary-dark text-lg border-b border-text-400"}
                title={ownerUserDownloadDetail?.data?.title}
                titleInputStatus={titleInputStatus}
                setTitleInputStatus={setTitleInputStatus}
                onHandleSaveTitle={onHandleSaveTitle}
            />
            <LmsTextAreaDescription
                isLoading={modifyOwnerUserDownloadDetail?.isLoading}
                isShowInputLabel={true}
                maxLength={256}
                inputLabelClassName={(ownerUserDownloadDetail?.data?.subtitle || subTitleInputStatus) && "!-translate-y-10"}
                containerClassName={"px-3 py-2 bg-white rounded-lg"}
                // descriptionTextContainerClassName={!ownerUserDownloadDetail?.data?.subtitle && "px-3"}
                description={ownerUserDownloadDetail?.data?.subtitle}
                descriptionInputStatus={subTitleInputStatus}
                setDescriptionInputStatus={setSubTitleInputStatus}
                onHandleSaveDescription={onHandleSaveSubTitle}
                descriptionPlaceholderStyle={"!text-primary-dark"}
                customViewTextDescription={() => (
                    <DescriptionContentContainer
                        isLoading={modifyOwnerUserDownloadDetail?.isLoading}
                        contentTitle={"Sub Header"}
                        descriptionInput={ownerUserDownloadDetail?.data?.subtitle}
                    />
                )}
            />
            <LmsEditorDescription
                isLoading={modifyOwnerUserDownloadDetail?.isLoading}
                descriptionPlaceholder={"Add More Details"}
                editorContainerClassName={"px-3 py-2 bg-white rounded-lg"}
                description={ownerUserDownloadDetail?.data?.description}
                onHandleSaveDescription={onHandleSaveDescription}
                descriptionPlaceholderStyle={"!text-primary-dark"}
                customDescriptionContentContainer={() => (
                    <DescriptionContentContainer
                        isLoading={modifyOwnerUserDownloadDetail?.isLoading}
                        contentTitle={"Description"}
                        allowHtmlContent={true}
                        descriptionInput={ownerUserDownloadDetail?.data?.description}
                    />
                )}
            />
            <div className={"pt-3 space-y-5"}>
                <DownloadPreviews />
                <DownloadFiles />
            </div>
        </div>
    )
}

export default BuilderPage;