import tw, { styled } from 'twin.macro';

import { ArrowForwardIos } from '@mui/icons-material';
import { motion } from 'framer-motion';

import { PageContent } from 'components/generalComponent/StyledComponent';

export const PageContentStyle = tw(PageContent)`
    grid grid-cols-12 bg-background-light relative
`;

const Content = tw.div`
    grid col-start-2 col-span-10 md:(col-start-3 col-span-8)
`;

export const Header = tw(Content)`
    w-full flex flex-col items-center justify-center gap-8 pb-5 pt-10 md:pt-28
`;

export const Heading = tw.h2`
    font-tagLine font-bold text-4xl text-primary-dark text-center
`;

export const SubHeading = tw.h4`
    font-bodyPri font-normal text-lg text-text-700 text-center
`;

export const ProgressBarContainer = tw(Content)`
    w-full flex flex-col justify-center gap-3 py-5 md:px-16
`;

export const ProgressBarText = tw.div`
    font-subHeading font-medium text-xl text-text-900 text-center
`;

export const DoughnutChartContainer = tw.div`
    w-full flex items-center justify-center
`

export const ViewProfileBtnContainer = tw(Content)`
    w-full flex flex-col items-center justify-center gap-3 py-5
`;

export const ViewProfileButton = tw.div`
    px-10 py-1.5 inline-block rounded-xl cursor-pointer font-medium whitespace-nowrap
    border-2 border-secondary-dark text-secondary-dark text-base hover:(bg-secondary-dark text-white)
`;

export const WaitListNoticeContainer = tw(Content)`
    flex flex-col justify-center py-5 md:px-20 lg:px-40
`;

export const TasksContainer = tw(Content)`
    flex flex-col justify-center items-center w-full my-10 shadow-lg
`;

export const Tasks = tw.div`
    w-full h-auto bg-white rounded-xl px-5 py-5 md:(px-16 py-10)
`;

export const ArrowToggleIcon = motion(styled(ArrowForwardIos)``);

export const FAQContainer = tw.div`
    col-start-1 col-span-full md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 py-5
`;

export const FooterContainer = tw.div`
    col-span-full pt-5
`;


// collapsable container styles

export const CollapsableContainer = motion(styled.section`
    ${tw`
        px-2 md:px-10 bg-white rounded-lg overflow-hidden border border-divider-darkLight border-t-0
        font-bodyPri font-normal text-base text-text-800
    `}
    ${({ isOpen }) => isOpen && tw`
        py-3 mt-0.5
    `}
`);