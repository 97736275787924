const ArticleView = ({ article }) => {

  const descriptionText = new DOMParser()?.parseFromString(`<div>${article?.description || ""}</div>`, "text/html")?.querySelector("div")?.textContent

  return (
    <div className={"max-w-2xl mx-auto py-5 space-y-5"}>

      {/* header */}
      <div className={""}>
        <h2 className={"font-bodyPri font-bold text-3xl text-900 text-center"}>{article?.title}</h2>
        {!!descriptionText && (
          <div className={"block mt-3"} dangerouslySetInnerHTML={{ __html: article?.description }}></div>
        )}
        <hr className={"h-0.5 bg-text-300 mt-2"} />
      </div>

      {/* content */}
      <div dangerouslySetInnerHTML={{ __html: article?.content }}></div>

    </div>
  )
}

export default ArticleView