import tw, { styled } from "twin.macro";

//Styles for ChatViewHeader
export const StyledChatViewHeader = styled.div`
  ${tw`flex justify-between items-center border-b border-gray-200 py-2.5 px-5 text-primary-dark`},
`;

//Styles for ChatViewHeaderUser component
export const StyledChatViewHeaderUser = styled.div(({ isGroup }) => [
  tw`flex items-center gap-x-3`,

  isGroup && tw`cursor-pointer`,
]);

export const StyledChatViewHeaderUserText = styled.p`
  ${tw`text-xs font-semibold mr-2.5 md:text-base md:font-bold`}
`;

//Styles for menu components
export const StyledChatViewHeaderMenu = styled.div`
  ${tw`flex items-center text-base text-gray-500 cursor-pointer`}

  svg {
    ${tw`mr-0.5`}
  }
`;

//Styles for menus container
export const StyledHeaderMenusContainer = styled.div`
  ${tw`flex`}

  div {
    &:last-of-type {
      ${tw`ml-3`}
    }
  }
`;

export const StyledViewChatViewHeaderUserImage = styled.img`
  ${tw`w-12 h-12 rounded-full object-cover`}
`;

export const StyledViewChatViewHeaderUserImageContainer = styled.div`
  ${tw`min-width[48px] min-height[48px] rounded-full bg-primary-light`}
`;

export const StyledChatViewHeaderPresence = styled.p`
  ${tw`text-sm text-gray-500`}
`;
