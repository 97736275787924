import {
    Container,
    Content,
    Header,
    Heading,
    ItemContainer,
    Slider,
    TestimonialContainer,
    ArrowContainer,
    PrevArrowIcon,
    NextArrowIcon,
    ButtonContainer,
    FindTutorBtn,
    PostYourNeedBtn,
} from './TestimonialsStyle';

import { testimonialsData } from './TestimonialsData';
import TestimonialItem from './TestimonialItem';
import "slick-carousel/slick/slick.css";

import { useAppDispatcher, useAppState  } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

const PrevArrow = ({ currentSlide, slideCount, ...props }) => {
    return (
        <ArrowContainer>
            <PrevArrowIcon {...props} />
        </ArrowContainer>
    )
}

const NextArrow = ({ currentSlide, slideCount, ...props }) => {
    return (
        <ArrowContainer>
            <NextArrowIcon {...props} />
        </ArrowContainer>
    )
}

const Testimonials = () => {
    const {modal} = useAppState((s) => s.local)
    const dispatcher = useAppDispatcher()

    const handlePostOnClick = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.POST_REQUIREMENT_MODAL.stateKey]: true
        }))
    }

    return (
        <Container>
            <Content>
                <Header>
                    <Heading>{'Learners love our platform'}</Heading>
                </Header>
                <ItemContainer>
                    <Slider>
                        <TestimonialContainer prevArrow={<PrevArrow />} nextArrow={<NextArrow />}>
                            {testimonialsData.map((testimonialItem, index) => (
                                <TestimonialItem key={index} testimonialItem={testimonialItem} />
                            ))}
                        </TestimonialContainer>
                    </Slider>
                    <ButtonContainer>
                        <FindTutorBtn to={'/find-tutors'}>{'Find tutor'}</FindTutorBtn>
                        <PostYourNeedBtn to={'#'} onClick={handlePostOnClick}>{'Post your need'}</PostYourNeedBtn>
                    </ButtonContainer>
                </ItemContainer>
            </Content>
        </Container>
    )
}

export default Testimonials
