import { cn } from "utils/cn.utils";
import * as React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setActiveStep } from "../store/GiftCardSlice";
import { AllSteps, StepValues, GiftCardStep } from "../store/types";

export function NavigationDots() {
  const activeStep: GiftCardStep = useAppState((s) => s.buyGiftCard.activeStep);
  const dispatcher = useAppDispatcher();
  return (
    <div className="flex items-center justify-center w-full space-x-4">
      {/* <div
        className={cn(
          "p-1 rounded-full",
          state.activeStep === "step1"
            ? "bg-green-400 ring-2 ring-offset-1 ring-green-400"
            : "bg-green-200"
        )}
      >
        {state.activeStep !== "step1" ? (
          <FaCheckCircle size={15} className="text-green-500" />
        ) : (
          <div className="w-3 h-3 " />
        )}
      </div> */}

      {AllSteps.slice(0, 3).map((step, index) => (
        <div
          className={cn(
            "rounded-full",
            StepValues[step] <= StepValues[activeStep]
              ? "bg-green-200 ring-1 ring-offset-1 ring-green-400"
              : "bg-gray-500"
          )}
          onClick={() => {
            if (
              StepValues[step] < StepValues[activeStep] &&
              activeStep !== "step4"
            ) {
              dispatcher(setActiveStep({ step: step }));
            }
          }}
        >
          {StepValues[step] < StepValues[activeStep] ? (
            <FaCheckCircle size={15} className="text-green-500" />
          ) : (
            <div className="w-3 h-3 " />
          )}
        </div>
      ))}
    </div>
  );
}
