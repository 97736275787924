import React from "react";

//Style imports
import { StyledReportUser } from "./ReportUser.styles";

export default function ReportUser({ setShowReportUserModal }) {
  function closeReportUserModal() {
    setShowReportUserModal(false);
  }

  return (
    <StyledReportUser>
      <p>Report User?</p>
      <button>Report</button>
      <button onClick={closeReportUserModal}>Cancel</button>
    </StyledReportUser>
  );
}
