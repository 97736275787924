import { useCallback } from 'react';

import DownloadSlug from './DownloadSlug';

import { updateUserDownloadDetail } from 'redux/downloads/downloads.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { downloadStatusEnum, downloadVisibilityEnum } from 'redux/downloads/downloads.const';

import { cn } from "utils/cn.utils";
import { dayjs, timeZone } from 'utils/dateTime.utils';

const GeneralSettings = () => {
    const { ownerUserDownloadDetail } = useAppState((state) => state.downloads)

    const dispatcher = useAppDispatcher()

    const onHandleChangeSetting = useCallback((key, value) => {
        const body = {
            [key]: value
        }
        dispatcher(updateUserDownloadDetail(ownerUserDownloadDetail?.data?.id, body))
    }, [ownerUserDownloadDetail?.data])

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <div className={"w-full px-3 py-2 bg-divider-medium rounded-lg"}>
                <span className={"font-bodyPri font-bold text-text-800 text-lg tracking-wide"}>
                    {"General Settings"}
                </span>
            </div>

            <div className={"w-full px-5 flex flex-col gap-3"}>
                <DownloadSlug />
                <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                    <span className={"col-span-8 order-1 lg:col-span-7 w-full flex items-center justify-start lg:justify-between font-bodyPri font-medium text-text-700 text-base tracking-wide"}>
                        <span>{"Make Download Visible for"}</span>
                        <span>{":"}</span>
                    </span>

                    <div className={"col-span-full order-3 lg:order-2 lg:col-span-5 justify-self-stretch flex items-center justify-start gap-5"}>
                        {Object.values(downloadVisibilityEnum)?.map((visibility, index) => (
                            <div
                                key={index}
                                className={"flex items-center justify-start gap-2 cursor-pointer"}
                                onClick={() => onHandleChangeSetting("visibility", visibility?.value)}
                            >
                                <input
                                    type={"radio"}
                                    className={""}
                                    value={visibility?.value}
                                    checked={visibility?.value === ownerUserDownloadDetail?.data?.visibility}
                                />
                                <span className={"font-bodyPri font-normal text-text-900 text-md tracking-wide"}>
                                    {visibility?.label}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                    <span className={"col-span-8 order-1 lg:col-span-7 w-full flex items-center justify-start lg:justify-between font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                        <span>{"Status"}</span>
                        <span>{":"}</span>
                    </span>
                    <span className={cn(
                        "col-span-full order-3 lg:order-2 lg:col-span-5 justify-self-stretch",
                        `font-bodyPri font-semibold text-base tracking-wide text-${downloadStatusEnum[ownerUserDownloadDetail?.data?.status?.toUpperCase()]?.darkColor}`
                    )}>
                        {downloadStatusEnum[ownerUserDownloadDetail?.data?.status?.toUpperCase()]?.label}
                    </span>
                </div>

                <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                    <span className={"col-span-8 order-1 lg:col-span-7 w-full flex items-center justify-start lg:justify-between font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                        <span>{"Created At"}</span>
                        <span>{":"}</span>
                    </span>
                    <span className={"col-span-full order-3 lg:order-2 lg:col-span-5 justify-self-stretch font-bodyPri font-normal text-text-900 text-md tracking-wide"}>
                        {dayjs(ownerUserDownloadDetail?.data?.createdAt)?.tz(timeZone)?.format("ddd, MMM D, YY h:mm A")}
                    </span>
                </div>

                <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                    <span className={"col-span-8 order-1 lg:col-span-7 w-full flex items-center justify-start lg:justify-between font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                        <span>{"Updated At"}</span>
                        <span>{":"}</span>
                    </span>
                    <span className={"col-span-full order-3 lg:order-2 lg:col-span-5 justify-self-stretch font-bodyPri font-normal text-text-900 text-md tracking-wide"}>
                        {dayjs(ownerUserDownloadDetail?.data?.updatedAt)?.tz(timeZone)?.format("ddd, MMM D, YY h:mm A")}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default GeneralSettings;