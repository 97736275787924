import { useState } from "react";
import { cn } from "utils/cn.utils";

import { FaPlusCircle } from "react-icons/fa";
import { AiOutlineCheck } from "react-icons/ai";
import { FaInfoCircle } from 'react-icons/fa';
import { FaSpinner } from "react-icons/fa";


import { Button } from "components/common-components/Buttons";
import { InputWithLabel } from "components/common-components/Form";

import { createUserPayoutDetailByUserId } from "redux/payout/payout.request";
import { updateUserDefaultDetailsByUserId } from "redux/default/default.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { payoutType } from "redux/payout/payout.const";

const trailingCharacters = 4

export const BankTransferSection = () => {
    const { user } = useAppState((state) => state.user)
    const { userPayoutList } = useAppState((state) => state.payout)
    const { userDefaultDetail } = useAppState((state) => state.userDefault)

    const [isFillBankDetail, setIsFillBankDetail] = useState(false)
    const [bankTransferDetails, setBankTransferDetails] = useState({
        accountHolderName: "",
        accountNumber: "",
        confirmAccountNumber: "",
        ifscCode: ""
    })

    const dispatcher = useAppDispatcher();

    const filteredPayoutType = userPayoutList?.userPayoutList?.filter((userPayout) => userPayout?.payoutType === payoutType?.BANK?.value)
    const filteredPayout = userPayoutList?.userPayoutList?.filter((userPayout) => userPayout?.payoutType === payoutType?.BANK?.value)?.filter((payout) => payout?.userPayoutId === userDefaultDetail?.userDefaultDetail?.userPayoutId)

    const onSubmit = () => {
        if (bankTransferDetails?.accountHolderName && bankTransferDetails?.accountNumber && (bankTransferDetails?.accountNumber === bankTransferDetails?.confirmAccountNumber) && bankTransferDetails?.ifscCode) {
            alert("Are you Sure you want to submit your bank details?")
            dispatcher(createUserPayoutDetailByUserId(
                user?.user?.userId,
                {
                    payoutType: payoutType?.BANK?.value,
                    accountHolderName: bankTransferDetails?.accountHolderName,
                    bankAccountNumber: bankTransferDetails?.accountNumber,
                    bankIfscCode: bankTransferDetails?.ifscCode
                }
            ))
            setIsFillBankDetail(false)
            return;
        }
        if (!bankTransferDetails?.accountHolderName) {
            alert("Bank Name is Not Provided!")
            return;
        }
        if (!bankTransferDetails?.accountNumber || bankTransferDetails?.accountNumber?.length > 20) {
            alert("Invalid Account Number!")
            return;
        }
        if (bankTransferDetails?.accountNumber !== bankTransferDetails?.confirmAccountNumber) {
            alert("Account number does not match!")
            return;
        }
        if (!bankTransferDetails?.ifscCode) {
            alert("Bank Code is Not Provided!")
            return;
        }
    };

    const makeDefault = () => {
        const filteredUserPayoutId = userPayoutList?.userPayoutList?.find((userPayout) => userPayout?.payoutType === payoutType?.BANK?.value)
        if (filteredUserPayoutId) {
            dispatcher(updateUserDefaultDetailsByUserId(
                user?.user?.userId,
                {
                    userPayoutId: filteredUserPayoutId?.userPayoutId
                }
            ))
        }
        else {
            alert("Please add Bank Details!")
        }
    };

    return (
        <div className={"col-start-1 col-span-full grid grid-cols-12 gap-5"}>
            <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-4 lg:col-start-1 lg:col-span-3"}>
                <p className="px-5 py-2 font-bodyPri font-bold text-text-900 text-base">
                    {"Bank Transfer"}
                </p>
                {filteredPayoutType?.length > 0 && filteredPayout?.length === 0 && (
                    <button
                        className={"px-5 font-bodyPri font-normal text-base text-primary-dark underline"}
                        onClick={makeDefault}
                    >
                        {"Make Default"}
                    </button>
                )}
                {userDefaultDetail?.isLoading &&
                    <div className={"flex items-center justify-start gap-1"}>
                        <span className={"font-bodyPri font-normal text-text-700 text-sm"}>
                            {"Please Wait ..."}
                        </span>
                        <FaSpinner className={"text-lg text-primary-main"} />
                    </div>
                }
                {filteredPayoutType?.length > 0 && filteredPayout?.length > 0 && (
                    <div className={"flex items-center justify-start gap-1.5 px-10"}>
                        <span className={"font-bodyPri font-normal text-text-800 text-sm italic"}>
                            {"Default"}
                        </span>
                        <AiOutlineCheck className={"text-lg text-green-500"} />
                    </div>
                )}
            </div>
            {userPayoutList?.userPayoutList &&
                userPayoutList?.userPayoutList?.filter((payout) => payout?.payoutType === payoutType?.BANK?.value)?.map((userPayout) => (
                    <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-4 lg:col-span-full flex flex-col items-start justify-center gap-3 px-5 md:px-0"}>

                        {/* account user details */}
                        <div className={"flex items-center justify-start gap-5"}>
                            <span className={"font-bodyPri font-normal text-text-700 text-sm"}>
                                {"Account Holder Name:"}
                            </span>
                            <span className={"font-bodyPri font-normal text-text-900 text-lg"}>
                                {userPayout?.accountHolderName}
                            </span>
                        </div>

                        {/* account number details */}
                        <div className={"flex items-center justify-start gap-5"}>
                            <span className={"font-bodyPri font-normal text-text-700 text-sm lg:whitespace-nowrap"}>
                                {"Account Number:"}
                            </span>
                            <span className={"font-bodyPri font-normal text-text-900 text-lg"}>
                                {new Array(userPayout?.bankAccountNumber?.length - trailingCharacters + 1)?.join("*") + userPayout?.bankAccountNumber?.slice(-trailingCharacters)}
                            </span>
                        </div>

                        {/* ifsc code details */}
                        <div className={"flex items-center justify-start gap-5"}>
                            <span className={"font-bodyPri font-normal text-text-700 text-sm"}>
                                {"Bank Code:"}
                            </span>
                            <span className={"font-bodyPri font-normal text-text-900 text-lg"}>
                                {new Array(userPayout?.bankIfscCode?.length - trailingCharacters + 1)?.join("*") + userPayout?.bankIfscCode?.slice(-trailingCharacters)}
                            </span>
                        </div>
                    </div>
                ))}
            {isFillBankDetail && (
                <div className={cn(
                    "col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-4 lg:col-span-full",
                    "w-full max-w-sm p-4 pl-6 space-y-4 border rounded-md md:w-96"
                )}>
                    {/* Edit */}
                    <InputWithLabel
                        label={"Account Holder Name"}
                        onChange={(event) => setBankTransferDetails({
                            ...bankTransferDetails,
                            accountHolderName: event.target.value
                        })}
                        placeholder={"e.g. John Doe"}
                        value={bankTransferDetails?.accountHolderName}
                    />
                    <InputWithLabel
                        label="Account Number"
                        onChange={(event) => {
                            if (event?.target?.value?.length <= 20) {
                                setBankTransferDetails({
                                    ...bankTransferDetails,
                                    accountNumber: event.target.value
                                })
                            }
                        }}
                        placeholder="Eg. 1234567890"
                        value={bankTransferDetails?.accountNumber}
                    />
                    <InputWithLabel
                        label="Confirm Account Number"
                        onChange={(e) => {
                            if (e.target?.value?.length <= 20) {
                                setBankTransferDetails({
                                    ...bankTransferDetails,
                                    confirmAccountNumber: e.target.value
                                })
                            }
                        }}
                        placeholder="Eg. 1234567890"
                        value={bankTransferDetails?.confirmAccountNumber}
                    />
                    <InputWithLabel
                        label="Bank Code"
                        onChange={(event) => setBankTransferDetails({
                            ...bankTransferDetails,
                            ifscCode: event?.target?.value
                        })}
                        placeholder={"Your Bank Code"}
                        value={bankTransferDetails?.ifscCode}
                    />
                    <div className={"relative has-tooltip cursor-pointer"}>
                        <div className={"absolute -top-14 -right-5"}>
                            <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                            <span
                                className={cn(
                                    "w-56 px-2 py-0.5 text-xs rounded shadow-lg bg-text-300 text-text-800 tooltip top-6.5",
                                    "flex flex-col items-start justify-start overflow-hidden"
                                )}
                            >
                                {"Alphanumeric or number only depends on the country and bank."}
                            </span>
                        </div>
                    </div>
                    <div className={"flex justify-end items-center gap-3"}>
                        <Button
                            name="Cancel"
                            size="small"
                            style="outline"
                            onClick={() => {
                                setIsFillBankDetail(false)
                            }}
                        />
                        <Button
                            name="Submit"
                            size="small"
                            style="primary"
                            onClick={onSubmit}
                        />
                    </div>
                </div>
            )}
            {(!isFillBankDetail && userPayoutList?.userPayoutList?.filter((payout) => payout?.payoutType === payoutType?.BANK?.value)?.length === 0) &&
                <div
                    className={cn(
                        "col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-4 lg:col-span-full",
                        "flex justify-center flex-1 py-4 bg-blue-100 rounded-md cursor-pointer px-5 md:px-0"
                    )}
                    onClick={() => {
                        setBankTransferDetails({
                            accountHolderName: "",
                            accountNumber: "",
                            confirmAccountNumber: "",
                            ifscCode: ""
                        })
                        setIsFillBankDetail(true)
                    }}
                >
                    <div className="flex flex-col items-center justify-center">
                        <FaPlusCircle className="text-blue-500 " size={30} />
                        <p className="text-lg font-bold text-blue-500">Add Bank Account</p>
                    </div>
                </div>
            }
        </div>
    );
};