import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { cn } from "utils/cn.utils";

import ComponentLoader from "components/loader/ComponentLoader";

import { getSessionFilterQuery, upcomingSessionFilterConst } from "../data";
import GestureDropdown from "./GestureDropdown";

import { getDashboardUpcomingEventList } from "redux/session/session.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetDashboardUpcomingEventList } from "redux/session/session.slice";
import { calendarEventTypeEnum } from "redux/session/session.const";

import { pagesInfo } from "utils/pagesInfo";
import { dayjs, timeZone } from "utils/dateTime.utils";

const UpcomingEventList = ({ sessionUser }) => {
    const navigate = useNavigate()

    const onEventSlotSelect = () => {
        if (sessionUser?.type === calendarEventTypeEnum.EVENTLY.value) {
            navigate(`${pagesInfo?.SCHEDULE?.pagePath}?eventlyId=${sessionUser?.evently_event?.evently_id}`)
        } else {
            navigate(`${pagesInfo?.SCHEDULE?.pagePath}?sessionId=${sessionUser?.sessionUser?.session_id}`)
        }
    };

    return (
        <div onClick={onEventSlotSelect} className={cn(
            "px-5 py-3 space-y-2 rounded-lg shadow-all border-2 hover:bg-background-darkLight cursor-pointer"
        )}>
            <p className="font-bodyPri font-medium text-md text-text-900 truncate overflow-hidden">
                {sessionUser?.name}
            </p>
            <p className={"font-bodyPri font-normal text-base text-text-700 truncate overflow-hidden"}>
                {dayjs(sessionUser?.startDateTime)?.tz(timeZone).format("ddd, DD/MMM/YY hh:mm A - ")?.toString() + dayjs(sessionUser?.endDateTime)?.tz(timeZone)?.format("hh:mm A")?.toString()}
            </p>
        </div>
    )
}

export const UpcomingClasses = () => {
    const { locals } = useAppState((s) => s.local)
    const { user } = useAppState((state) => state.user)
    const { dashboardUpcomingEventList } = useAppState((s) => s.session)

    const dispatcher = useAppDispatcher()

    const [selectedSessionFilter, setSelectedSessionFilter] = useState(upcomingSessionFilterConst?.THIS_WEEK?.value)

    useEffect(() => {
        const query = getSessionFilterQuery(selectedSessionFilter)
        if (query) {
            dispatcher(getDashboardUpcomingEventList(user?.user?.userId, query))
        }

        return () => {
            dispatcher(resetDashboardUpcomingEventList())
        }
    }, [locals.userRole, selectedSessionFilter])

    const sortSessions = (session1, session2) => {
        const session1StartTime = dayjs(session1?.startDateTime)
        const session2StartTime = dayjs(session2?.startDateTime)
        return session1StartTime.isSameOrAfter(session2StartTime) ? 1 : -1
    }

    return (
        <div className={"p-5 space-y-3 w-full bg-white rounded-lg"}>
            <div className={"flex items-center justify-between"}>
                <div className={"flex flex-wrap items-center justify-start gap-3 md:gap-1"}>
                    <span className={"text-lg font-medium font-bodyPri text-text-900 tracking-wide lg:whitespace-nowrap"}>
                        {"Upcoming Event(s)"}
                    </span>
                    <GestureDropdown
                        selectedSessionFilter={selectedSessionFilter}
                        setSelectedSessionFilter={setSelectedSessionFilter}
                    />
                </div>
                <p className={"text-sm text-text-700 font-bodyPri whitespace-nowrap"}>
                    <Link to={pagesInfo.SCHEDULE.pagePath}>{"View all"}</Link>
                </p>
            </div>
            <div className={cn(
                "flex flex-col w-full h-96 overflow-hidden overflow-y-scroll space-y-3 pl-2 transition ease-in-out delay-150 duration-300",
                "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight",
                (dashboardUpcomingEventList?.data?.length > 5) && "hover:pr-5"
            )}>
                {dashboardUpcomingEventList?.isLoading &&
                    <ComponentLoader isLoading={dashboardUpcomingEventList?.isLoading} className={"h-96"} />
                }
                {(dashboardUpcomingEventList?.data?.length > 0) &&
                    dashboardUpcomingEventList?.data?.slice(0)?.sort(sortSessions)?.slice(0, 5)?.map((sessionUser, index) => (
                        <UpcomingEventList key={index} sessionUser={sessionUser} />
                    ))}
                {(dashboardUpcomingEventList?.message || (dashboardUpcomingEventList?.data?.length === 0)) &&
                    <p className="w--full h-full flex items-center justify-center font-bodyPri font-normal text-text-800 text-md">
                        {dashboardUpcomingEventList?.message || "No Sessions Found!"}
                    </p>
                }
            </div>
        </div>
    );
};