import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quill-editor.css';

import { useAppState } from 'hooks/useStore';

const CourseDescriptionDiv = () => {
    const { publicCourseDetail } = useAppState((s) => s.course)

    return (
        <div className="w-full h-full overflow-hidden space-y-3">
            <div className='flex items-center gap-1'>
                <span className="text-lg font-medium font-bodyPri tracking-wide text-text-900">
                    {"Class Description"}
                </span>
            </div>
            <ReactQuill
                readOnly={true}
                modules={{ toolbar: false }}
                // formats={[]}
                value={publicCourseDetail?.data?.description}
                className={"react-quill"}
            />
            {/* <div className='px-3'
                dangerouslySetInnerHTML={{ __html: publicCourseDetail?.data?.description }}
            >
            </div> */}
        </div>
    )
}

export default CourseDescriptionDiv
