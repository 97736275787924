import { useState, useEffect, useMemo } from "react";
import { cn } from "utils/cn.utils"

import { FaSpinner } from 'react-icons/fa';

import FadedCollapse from "components/fadedCollapse";

import SectionsView from "./components/sections"
import ResultView from "./components/result";

import { createLmsCourseStudentQuizResponseDetail } from "redux/edulyteLms/lmsCourse/lmsCourse.request";
import { getLmsQuizResponseDetail } from "redux/edulyteLms/lmsQuiz/lmsQuiz.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetLmsQuizResponse } from "redux/edulyteLms/lmsQuiz/lmsQuiz.slice";
import { lmsQuizCategoryEnum } from "redux/edulyteLms/lmsQuiz/lmsQuiz.const"
import FullPageLoader from "components/loader/FullPageLoader";
import { resetAddLmsCourseStudentQuizResponseDetail } from "redux/edulyteLms/lmsCourse/lmsCourse.slice";


const QuizView = ({ quiz }) => {

  const { studentLmsCourseDetail: { data: { lms_course_student } }, addLmsCourseStudentQuizResponseDetail } = useAppState(state => state.lms.lmsCourse)
  const { lmsQuizResponse } = useAppState(state => state.lms.lmsQuiz)

  const [isQuizResume, setIsQuizResume] = useState(false)

  const dispatcher = useAppDispatcher()

  const sectionCount = quiz?.quiz_sections?.length || 0
  const questionCount = quiz?.quiz_sections?.reduce((initial, quiz_section) => [...initial, ...quiz_section?.quiz_categories?.filter(quiz_category => quiz_category?.type === lmsQuizCategoryEnum.QUESTION.value)], [])?.length || 0

  const lms_course_student_quiz_response = lms_course_student?.lms_course_student_quiz_responses?.find(lms_course_student_quiz_response => lms_course_student_quiz_response?.quiz === quiz?.id)
  const isQuizStarted = !!lms_course_student_quiz_response

  const sortQuizSection = (section1, section2) => section1?.order > section2?.order ? 1 : -1

  useEffect(() => {
    if (!!lms_course_student_quiz_response?.quiz_response) {
      dispatcher(getLmsQuizResponseDetail(lms_course_student_quiz_response?.quiz_response))
    }
    return () => {
      dispatcher(resetLmsQuizResponse())
    }
  }, [lms_course_student_quiz_response?.quiz_response])

  useEffect(() => {
    if (!!addLmsCourseStudentQuizResponseDetail?.data) {
      dispatcher(resetAddLmsCourseStudentQuizResponseDetail())
      setIsQuizResume(true)
    }
  }, [addLmsCourseStudentQuizResponseDetail?.data])

  const onHandleStartQuiz = () => {
    if (addLmsCourseStudentQuizResponseDetail?.isLoading) return;

    const requestBody = {
      lms_course_student_id: lms_course_student?.id,
      quiz_id: quiz?.id
    }
    dispatcher(createLmsCourseStudentQuizResponseDetail(requestBody))
  }

  const onHandleResumeQuiz = () => {
    if (!!lms_course_student_quiz_response?.quiz_response) {
      setIsQuizResume(true)
    } else {
      onHandleStartQuiz()
    }
  }

  const onHandleCancel = () => {
    setIsQuizResume(false)
  }

  const descriptionText = useMemo(() => new DOMParser()?.parseFromString(`<div>${quiz?.description || ""}</div>`, "text/html")?.querySelector("div")?.textContent, [quiz?.description])

  if (!quiz) {
    return (
      <div className={"flex items-center justify-center mt-20"}>
        <span className={"font-bodyPri font-semibold text-2xl text-test-800"}>
          {"Quiz not found!"}
        </span>
      </div>
    )
  }

  if (lmsQuizResponse?.isLoading) {
    return <FullPageLoader isLoading={true} />
  }

  if (lmsQuizResponse?.data?.is_completed) {
    return (
      <div className={"max-w-full sm:max-w-xl md:max-w-3xl lg:max-w-5xl w-full mx-auto px-3 md:px-8 py-5 space-y-10 select-none"}>

        {/* header */}
        <div className={"flex flex-col items-start gap-1"}>
          <h2 className={"font-bodyPri font-bold text-2xl text-900 line-clamp-1"}>{`Quiz: ${quiz?.title}`}</h2>
          {!!descriptionText && (
            <FadedCollapse
              isShowMore={descriptionText?.length > 500}
              isFaded={descriptionText?.length > 500}
              maxHeight={descriptionText?.length > 500 ? "7rem" : "100%"}
              wrapperStyle={"block mt-3"}
            >
              <div dangerouslySetInnerHTML={{ __html: quiz?.description }}></div>
            </FadedCollapse>
          )}
          <hr className={"h-0.5 w-full bg-text-300"} />
        </div>

        {/* Section */}
        <ResultView quiz={quiz} quiz_response={lmsQuizResponse?.data} />

      </div>
    )
  } else if (isQuizResume && !!lms_course_student_quiz_response?.quiz_response) {
    return (
      <div className={"max-w-full sm:max-w-xl md:max-w-3xl lg:max-w-5xl w-full mx-auto px-3 md:px-8 py-5 space-y-10 select-none"}>

        {/* header */}
        <div className={"flex flex-col items-start gap-1"}>
          <h2 className={"font-bodyPri font-bold text-2xl text-900 line-clamp-1"}>{`Quiz: ${quiz?.title}`}</h2>
          {!!descriptionText && (
            <FadedCollapse
              isShowMore={descriptionText?.length > 500}
              isFaded={descriptionText?.length > 500}
              maxHeight={descriptionText?.length > 500 ? "7rem" : "100%"}
              wrapperStyle={"block mt-3"}
            >
              <div dangerouslySetInnerHTML={{ __html: quiz?.description }}></div>
            </FadedCollapse>
          )}
          <hr className={"h-0.5 w-full bg-text-300"} />
        </div>

        {/* Section */}
        <SectionsView
          sections={[...quiz?.quiz_sections]?.sort(sortQuizSection)}
          quiz_response={lmsQuizResponse?.data}
          onHandleCancel={onHandleCancel}
        />

      </div>
    )
  } else {
    return (
      <div className={"max-w-2xl mx-auto py-5 space-y-10"}>

        {/* header */}
        <div className={""}>
          <h2 className={"font-bodyPri font-bold text-3xl text-900 text-center"}>{`Quiz: ${quiz?.title}`}</h2>
          {!!descriptionText && (
            <FadedCollapse
              isShowMore={descriptionText?.length > 500}
              isFaded={descriptionText?.length > 500}
              maxHeight={descriptionText?.length > 500 ? "7rem" : "100%"}
              wrapperStyle={"block mt-3"}
            >
              <div dangerouslySetInnerHTML={{ __html: quiz?.description }}></div>
            </FadedCollapse>
          )}
        </div>

        <span className={"block font-bodyPri font-semibold text-xl text-text-800 text-center"}>
          {`${sectionCount} Section(s) | ${questionCount} Question(s)`}
        </span>

        <div className={"self-center flex items-center justify-center gap-5"}>
          {!isQuizStarted && (
            <button
              className={cn(
                "px-5 py-2 bg-primary-dark hover:bg-green-800",
                "font-buttons font-semibold text-lg text-text-50",
                addLmsCourseStudentQuizResponseDetail?.isLoading && "!bg-primary-light !hover:opacity-100"
              )}
              onClick={() => onHandleStartQuiz()}
              disabled={addLmsCourseStudentQuizResponseDetail?.isLoading || lmsQuizResponse?.isLoading}
            >
              {"Start Quiz"}
            </button>
          )}
          {isQuizStarted && (
            <button
              className={cn(
                "px-5 py-2 bg-primary-dark hover:bg-green-800",
                "font-buttons font-semibold text-lg text-text-50",
                addLmsCourseStudentQuizResponseDetail?.isLoading && "!bg-primary-light !hover:opacity-100"
              )}
              onClick={() => onHandleResumeQuiz()}
              disabled={addLmsCourseStudentQuizResponseDetail?.isLoading || lmsQuizResponse?.isLoading}
            >
              {"Resume Quiz"}
            </button>
          )}
        </div>

        <div className={"self-center flex items-center justify-center gap-5"}>
          {addLmsCourseStudentQuizResponseDetail?.isLoading && (
            <span className={"flex flex-nowrap items-center justify-center gap-2"}>
              {"Loading..."}
              <FaSpinner className="text-primary-main animate-spin" />
            </span>
          )}
          {(!!addLmsCourseStudentQuizResponseDetail?.message || !!lmsQuizResponse?.message) && (
            <span className={"flex items-center justify-center gap-2"}>
              {!!addLmsCourseStudentQuizResponseDetail?.message || !!lmsQuizResponse?.message || ""}
            </span>
          )}
        </div>
      </div>
    )
  }
}

export default QuizView