import img1 from './images/One.png';
import img2 from './images/Two.png';
import img3 from './images/Three.png';
import img4 from './images/Four.png';

const CourseDetailBar = () => {

    return (
        <div className="flex items-center justify-between ">
            <div className="flex flex-col justify-center items-center">
                <img src={img1} alt="" className="w-12 h-12 lg:h-16 lg:w-16 rounded-full" />
                <span className={"lg:font-bold lg:text-sm text-xs text-center text-gray-500"}>Live Real Tutor</span>
            </div>
            <div className="flex flex-col justify-center items-center">
                <img src={img2} alt="" className="w-12 h-12 lg:h-16 lg:w-16  rounded-full" />
                <span className={"lg:font-bold lg:text-sm text-xs text-center text-gray-500"}>Secure & Convenient</span>
            </div>
            <div className="flex flex-col justify-center items-center">
                <img src={img3} alt="" className="w-12 h-12 lg:h-16 lg:w-16  rounded-full" />
                <span className={"lg:font-bold lg:text-sm text-xs text-center text-gray-500"}>Personalised Learning</span>
            </div>
            <div className="flex flex-col justify-center items-center">
                <img src={img4} alt="" className="w-12 h-12 lg:h-16 lg:w-16  rounded-full" />
                <span className={"lg:font-bold lg:text-sm text-xs text-center text-gray-500"}>Verified Teacher</span>
            </div>
        </div>
    )
}

export default CourseDetailBar
