import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { FaSpinner } from "react-icons/fa";

import BigCalendar from "components/calendar/BigCalendar";

import { calendarViewConst, calendarViewDetail, getCalendarListPayload, getStartEndDateTime, getVariableViewRange, searchParamsInfo, sessionStatusColorCodes } from "../data";

import { getCalendarSessionUserList } from "redux/session/session.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from 'redux/local/local.slice';
import { setFilterProps } from "redux/session/session.slice";
import { setAddUserEventlyDetailPayload } from "redux/evently/evently.slice";
import { modalConst } from 'redux/local/local.const';
import { addUserEventlyDetailPayload } from "redux/evently/evently.const";

import { pagesInfo } from "utils/pagesInfo";
import { sessionStatusEnum } from "redux/session/session.const";

const ScheduleCalendar = () => {
  const { modal } = useAppState((s) => s.local)
  const { user } = useAppState((state) => state.user)
  const { calendarSessionUserList, filterProps } = useAppState((s) => s.session)

  const dispatcher = useAppDispatcher();
  const navigate = useNavigate()
  const location = useLocation()

  let searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])
  const activeView = useMemo(() => calendarViewDetail(), [location])

  useEffect(() => {
    if (searchQueryParams && (searchQueryParams.get(searchParamsInfo.sessionId.key) || searchQueryParams.get(searchParamsInfo.eventlyId.key))) {
      const currentCalendarEvent = {}
      if (searchQueryParams.get(searchParamsInfo.sessionId.key)) {
        currentCalendarEvent[searchParamsInfo.sessionId.key] = searchQueryParams.get(searchParamsInfo.sessionId.key)
      }
      if (searchQueryParams.get(searchParamsInfo.eventlyId.key)) {
        currentCalendarEvent[searchParamsInfo.eventlyId.key] = searchQueryParams.get(searchParamsInfo.eventlyId.key)
      }
      onHandleOpenEventFromQuery(currentCalendarEvent)
    }
  }, [searchQueryParams.get(searchParamsInfo.sessionId.key), searchQueryParams.get(searchParamsInfo.eventlyId.key)]);

  const onHandleOpenEventFromQuery = (calendarEvent) => {
    if (calendarEvent?.eventlyId) {
      dispatcher(setModal({
        ...modal,
        [modalConst.viewEventlySlotModal.key]: {
          ...modal[modalConst.viewEventlySlotModal.key],
          isVisible: true,
          title: "View Event",
        }
      }))
    } else if (calendarEvent?.sessionId) {
      dispatcher(setModal({
        ...modal,
        [modalConst.SESSION_DETAIL_MODAL.stateKey]: true
      }))
    }
  }

  const onEventSlotSelect = (calendarEvent) => {
    if (calendarEvent?.evently_event?.evently_id) {
      searchQueryParams.set(searchParamsInfo.eventlyId.key, calendarEvent?.evently_event?.evently_id)
      navigate(`${location.pathname}?${searchQueryParams.toString()}`)
      dispatcher(setModal({
        ...modal,
        [modalConst.viewEventlySlotModal.key]: {
          ...modal[modalConst.viewEventlySlotModal.key],
          isVisible: true,
          title: "View Event",
        }
      }))
    } else if (calendarEvent?.sessionUser?.session_id) {
      searchQueryParams.set(searchParamsInfo.sessionId.key, calendarEvent?.sessionUser?.session_id)
      navigate(`${location.pathname}?${searchQueryParams.toString()}`)
      dispatcher(setModal({
        ...modal,
        [modalConst.SESSION_DETAIL_MODAL.stateKey]: true
      }))
    }
  };

  const onHandleSelectSlot = (slotInfo) => {
    const { newModifiedStartDateTime, newModifiedEndDateTime } = getStartEndDateTime(slotInfo?.start)
    dispatcher(setAddUserEventlyDetailPayload({
      ...addUserEventlyDetailPayload,
      box: {
        clientX: slotInfo?.box?.clientX,
        clientY: slotInfo?.box?.clientY,
      },
      startDateTime: newModifiedStartDateTime,
      endDateTime: newModifiedEndDateTime
    }))
    dispatcher(setModal({
      ...modal,
      [modalConst.addEventlySlotModal.key]: {
        ...modal[modalConst.addEventlySlotModal.key],
        isVisible: true,
        title: "Add Event"
      }
    }))
  }

  const onHandleViewButtons = useCallback((option) => {
    if (calendarSessionUserList?.isLoading || (option === activeView?.viewType)) return;

    navigate(`${pagesInfo.SCHEDULE.pagePath}/${option}`)
  }, [calendarSessionUserList?.isLoading, activeView])

  const onHandleChangeRange = useCallback((range, view) => {
    if (calendarSessionUserList?.isLoading || (view === activeView?.viewType)) return;

    const viewProps = getVariableViewRange(range)

    const requestDataPayload = getCalendarListPayload(searchQueryParams, viewProps)

    dispatcher(setFilterProps({ filterProps: { ...filterProps, startDateTime: viewProps?.startDateTime, endDateTime: viewProps?.endDateTime } }))
    dispatcher(getCalendarSessionUserList(user?.user?.userId, { ...requestDataPayload }))
  }, [calendarSessionUserList?.isLoading, activeView])

  const onHandleSessionStyle = useCallback((session, start, end, isSelected) => {
    const bgColor = sessionStatusEnum[session?.sessionUser?.status?.toUpperCase()]?.darkColorHexCode || "#424242"

    let style = {
      backgroundColor: bgColor
    }

    return { style };
  }, [calendarSessionUserList?.data])

  return (
    <div className={"w-full h-full pt-5 px-5 rounded-lg bg-white"}>
      <div className={"w-full h-full relative"}>
        <BigCalendar
          className={"h-full min-h-[70vh]"}
          eventList={(calendarSessionUserList?.data?.length > 0) ? calendarSessionUserList?.data : []}
          startAccessor={(calendarEvent) => new Date(calendarEvent?.startDateTime)}
          endAccessor={(calendarEvent) => new Date(calendarEvent?.endDateTime)}
          titleAccessor={(calendarEvent) => (calendarEvent?.name?.length > 20) ? calendarEvent?.name?.slice(0, 20) + " ..." : calendarEvent?.name}
          view={activeView?.viewType ? activeView?.viewType : calendarViewConst.month.key}
          onHandleSelectEvent={onEventSlotSelect}
          selectable
          onHandleSelectSlot={onHandleSelectSlot}
          onHandleRangeChange={onHandleChangeRange}
          onHandleViewButtons={onHandleViewButtons}
          // onHandleNavigationButtons={(date, view, option) => console.log(date, view, option)}
          eventPropGetter={onHandleSessionStyle}
        />
        {(!!calendarSessionUserList?.isLoading) && (
          <>
            <div className={"absolute z-40 inset-0 bg-white/50 blur-lg"} />
            <div className={"absolute z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"}>
              <div className={"bg-primary-light rounded-full shadow-all-md px-5 py-3"}>
                <FaSpinner className={"text-4xl text-text-700 animate-spin"} />
              </div>
            </div>
          </>
        )}
        {!calendarSessionUserList?.isLoading && !!calendarSessionUserList?.message && (
          <>
            <div className={"absolute z-40 inset-0 bg-white/50 blur-lg"} />
            <div className={"absolute z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"}>
              <div className={"bg-primary-light rounded-full shadow-all-md px-5 py-3"}>
                <div className={"w-full font-bodyPri font-medium text-base text-primary-dark text-center whitespace-nowrap"}>
                  {calendarSessionUserList?.message},
                  <br />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <p className={"mt-3 text-center text-red-500 opacity-60 lg:opacity-0"}>
        {"Best Viewed on Desktop Browser"}
      </p>
    </div>
  );
};

export default ScheduleCalendar;