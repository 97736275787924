import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cn } from "utils/cn.utils";
import format from 'string-format';

import { useStripe } from '@stripe/react-stripe-js';

import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { initializeBooking, confirmBooking, getBookingDetailConfirmation } from 'redux/booking/booking.request';
import { createPaymentIntentDetail } from 'redux/payment/payment.request';
import { resetInitializeBookingDetail, resetBookingDetailConfirmation } from 'redux/booking/booking.slice';
import {
    setAddPaymentIntentDetailData,
} from 'redux/payment/payment.slice';
import { bookingRegularPriceModelEnum } from 'redux/booking/booking.const';
import { paymentIntentStatusEnum } from 'redux/payment/payment.const';

import { currencyRateConversion } from 'utils/generators.utils';

const paymentConfirmationUrl = "/payment/confirmation?payment_intent={payment_intent}&payment_intent_client_secret={payment_intent_client_secret}&redirect_status={redirect_status}";
const bookingConfirmationUrl = "/booking/confirmation?booking_id={booking_id}&redirect_status={redirect_status}"

const initialStateConfirmCardPayment = {
    isLoading: false,
    data: null,
    message: null
}

const PaymentSummerySection = () => {
    const { userBookingDetail, modifyUserBookingDetail, initializeBookingDetail, bookingDetailConfirmation } = useAppState(s => s.booking)
    const { addPaymentIntentDetail } = useAppState(s => s.payment)
    const { locals } = useAppState(s => s.local)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const stripe = useStripe();

    const [confirmCardPayment, setConfirmCardPayment] = useState(initialStateConfirmCardPayment)

    useEffect(() => {
        if (initializeBookingDetail?.data?.result) {
            if (modifyUserBookingDetail?.payload?.isGatewayIncluded) {
                let requestPayload = {
                    email: addPaymentIntentDetail?.payload?.email,
                    name: addPaymentIntentDetail?.payload?.name,
                    currency: addPaymentIntentDetail?.payload?.currency,
                    amount: addPaymentIntentDetail?.payload?.totalAmountToPay,
                    description: addPaymentIntentDetail?.payload?.description,
                    metadata: {
                        paymentType: addPaymentIntentDetail?.payload?.paymentType,
                        bookingId: initializeBookingDetail?.data?.result?.id
                    }
                }
                dispatcher(createPaymentIntentDetail(requestPayload))
                dispatcher(resetInitializeBookingDetail())
            } else {
                dispatcher(confirmBooking(initializeBookingDetail?.data?.result?.id))
                dispatcher(resetInitializeBookingDetail())
            }
        }
    }, [initializeBookingDetail?.data?.result])

    useEffect(() => {
        if (addPaymentIntentDetail?.data) {
            let clientSecret = addPaymentIntentDetail?.data?.client_secret
            let bookingId = addPaymentIntentDetail?.data?.metadata?.bookingId

            handlePayment({ clientSecret, bookingId })

            dispatcher(setAddPaymentIntentDetailData(null))
        }
    }, [addPaymentIntentDetail?.data])

    const handlePayment = async ({ clientSecret, bookingId }) => {
        setConfirmCardPayment({ ...confirmCardPayment, isLoading: true })
        try {
            const paymentIntentConfirmation = await stripe.confirmCardPayment(clientSecret, {
                payment_method: addPaymentIntentDetail?.payload?.paymentMethodId
            })

            if (paymentIntentConfirmation?.paymentIntent) {
                await handleNext(paymentIntentConfirmation?.paymentIntent, bookingId)
            } else if (paymentIntentConfirmation?.error) {
                setConfirmCardPayment({ ...confirmCardPayment, message: paymentIntentConfirmation?.error?.message })
            } else {
                throw new Error("Something went wrong!")
            }
        } catch (error) {
            console.error(error)
            setConfirmCardPayment({ ...confirmCardPayment, message: "Something went wrong!" })
        }
        setConfirmCardPayment({ ...confirmCardPayment, isLoading: false })
    }

    const handleNext = async (paymentIntent, bookingId) => {
        if (paymentIntent.status === paymentIntentStatusEnum.SUCCEEDED.value) {
            setTimeout(() => {
                dispatcher(getBookingDetailConfirmation(bookingId))
            }, 2000)
        } else {
            const query = {
                payment_intent: paymentIntent.id,
                payment_intent_client_secret: paymentIntent.client_secret,
                redirect_status: paymentIntent.status
            }
            navigate(format(paymentConfirmationUrl, query), { replace: true })
        }
    }

    useEffect(() => {
        if (bookingDetailConfirmation?.data?.result) {
            const query = {
                booking_id: bookingDetailConfirmation?.data?.result?.id,
                redirect_status: bookingDetailConfirmation?.data?.result?.status,
            }
            dispatcher(resetBookingDetailConfirmation())
            navigate(format(bookingConfirmationUrl, query), { replace: true })
        }
    }, [bookingDetailConfirmation?.data?.result])

    let payableAmount = 0
    let payButtonText = ""
    let isPayButtonEnable = false
    if (modifyUserBookingDetail?.payload?.isGatewayIncluded) {
        payableAmount = modifyUserBookingDetail?.payload?.gatewayAmountToPay
        payButtonText = "Add & Pay"
        isPayButtonEnable = modifyUserBookingDetail?.payload?.paymentMethod && !(initializeBookingDetail?.isLoading || addPaymentIntentDetail?.isLoading || confirmCardPayment?.isLoading || bookingDetailConfirmation?.isLoading)
    } else {
        payableAmount = modifyUserBookingDetail?.payload?.walletAmountToPay + userBookingDetail?.data?.result?.bookingPrice?.promoCreditUsed
        payButtonText = "Pay From Wallet"
        isPayButtonEnable = !(initializeBookingDetail?.isLoading || bookingDetailConfirmation?.isLoading)
    }

    const handlePay = () => {
        if (!isPayButtonEnable) return;

        dispatcher(initializeBooking(userBookingDetail?.data?.result?.id))
    }

    const getNetAmount = () => {
        let amount = userBookingDetail?.data?.result?.bookingPrice?.price
        let netAmount = 0
        if (userBookingDetail?.data?.result?.bookingPrice?.priceModel === bookingRegularPriceModelEnum.FREE.value) {
            netAmount = 0
        } else if (userBookingDetail?.data?.result?.bookingPrice?.priceModel === bookingRegularPriceModelEnum.FLAT_FEE.value) {
            netAmount = amount
        } else if (userBookingDetail?.data?.result?.bookingPrice?.priceModel === bookingRegularPriceModelEnum.PER_SESSION.value) {
            netAmount = amount * userBookingDetail?.data?.result?.bookingPrice?.sessionCount
        } else if (userBookingDetail?.data?.result?.bookingPrice?.priceModel === bookingRegularPriceModelEnum.PER_HOUR.value) {
            netAmount = (amount / 4 * userBookingDetail?.data?.result?.bookingPrice?.duration / 15) * userBookingDetail?.data?.result?.bookingPrice?.sessionCount
        }

        return netAmount
    }

    const getDiscount = () => {
        let netAmount = getNetAmount()

        let courseDiscountPrice = netAmount * (userBookingDetail?.data?.result?.bookingPrice?.discountPct / 100)
        let volumeDiscountPrice = (netAmount - courseDiscountPrice) * (userBookingDetail?.data?.result?.bookingPrice?.volumeDiscountPct / 100)

        return { courseDiscountPrice, volumeDiscountPrice }
    }

    return (
        <div className={"W-full bg-white shadow-all rounded-lg p-5 space-y-5"}>
            <div className={"w-full flex items-center justify-center"}>
                <span className={"text-center font-bodyPri font-semibold text-lg text-text-800"}>
                    {"Order Summary"}
                </span>
            </div>

            <div className={"space-y-1"}>
                <div className={"flex items-center justify-between font-bodyPri font-normal text-sm text-text-700"}>
                    <span>
                        {"Order price:"}
                    </span>
                    <span>
                        {`USD ${parseFloat(getNetAmount() / 100).toFixed(2)}`}
                    </span>
                </div>

                {userBookingDetail?.data?.result?.bookingPrice?.totalPrice !== 0 && <div className={"h-0.5 bg-divider-darkLight w-full rounded-full"}></div>}

                <div className={"flex items-center justify-between font-bodyPri font-normal text-sm text-text-700"}>
                    <div className={"flex items-center justify-center gap-1"}>
                        <span>
                            {"Discount:"}
                        </span>
                        {(getNetAmount() - userBookingDetail?.data?.result?.bookingPrice?.totalAmount) > 0 &&
                            <span className={"relative has-tooltip cursor-pointer"}>
                                <AiOutlineQuestionCircle className={"text-text-700"} />
                                <span className={cn(
                                    'tooltip z-50! -translate-x-[50%] bottom-5 px-2 py-1 leading-0.5 text-center',
                                    'bg-black/90 text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide',
                                )}>
                                    {getDiscount()?.courseDiscountPrice > 0 &&
                                        <div className={"flex items-center justify-center gap-1 whitespace-nowrap"}>
                                            <span>
                                                {"Course discount:"}
                                            </span>
                                            <span>
                                                {"USD"} {parseFloat(getDiscount()?.courseDiscountPrice / 100).toFixed(2)}
                                            </span>
                                        </div>
                                    }
                                    {getDiscount()?.volumeDiscountPrice > 0 &&
                                        <div className={"flex items-center justify-center gap-1 whitespace-nowrap"}>
                                            <span>
                                                {"Volume discount:"}
                                            </span>
                                            <span>
                                                {"USD"} {parseFloat(getDiscount()?.volumeDiscountPrice / 100).toFixed(2)}
                                            </span>
                                        </div>
                                    }
                                </span>
                            </span>
                        }
                    </div>
                    <span>
                        {"- USD"} {parseFloat((getNetAmount() - userBookingDetail?.data?.result?.bookingPrice?.totalAmount) / 100).toFixed(2)}
                    </span>
                </div>

                {userBookingDetail?.data?.result?.bookingPrice?.totalPrice !== 0 && <div className={"h-0.5 bg-divider-darkLight w-full rounded-full"}></div>}

                <div className={"flex items-center justify-between font-bodyPri font-normal text-sm text-text-700"}>
                    <span>
                        {"Total:"}
                    </span>
                    <span>
                        {"USD"} {parseFloat(userBookingDetail?.data?.result?.bookingPrice?.totalAmount / 100).toFixed(2)}
                    </span>
                </div>

                {userBookingDetail?.data?.result?.bookingPrice?.totalPrice !== 0 && <div className={"h-0.5 bg-divider-darkLight w-full rounded-full"}></div>}

                <div className={"flex items-center justify-between font-bodyPri font-normal text-sm text-text-700"}>
                    <span>
                        {"Promo used:"}
                    </span>
                    <span>
                        {"USD"} {parseFloat(userBookingDetail?.data?.result?.bookingPrice?.promoCreditUsed / 100).toFixed(2)}
                    </span>
                </div>

                {userBookingDetail?.data?.result?.bookingPrice?.totalPrice !== 0 && <div className={"h-0.5 bg-divider-darkLight w-full rounded-full"}></div>}

                <div className={"flex items-center justify-between font-bodyPri font-normal text-sm text-text-700"}>
                    <span>
                        {"Wallet used:"}
                    </span>
                    <span>
                        {"USD"} {parseFloat(modifyUserBookingDetail?.payload?.walletAmountToPay / 100).toFixed(2)}
                    </span>
                </div>

            </div>

            <div className={"flex flex-col"}>
                <div className={"w-full flex items-center justify-between gap-2"}>
                    <span className={"font-bodyPri font-medium text-md text-primary-dark"}>
                        {"Sub Total:"}
                    </span>
                    <span className={"font-bodyPri font-semibold text-md text-primary-dark"}>
                        {"USD"} {parseFloat(payableAmount / 100).toFixed(2)}
                    </span>
                </div>
                <div className={"w-full flex justify-end"}>
                    <span className={"whitespace-nowrap flex items-center gap-1 font-bodyPri font-normal text-sm text-text-800"}>
                        {"Approx"} {currencyRateConversion(locals?.currencyCode, payableAmount)}
                    </span>
                </div>
            </div>

            {modifyUserBookingDetail?.payload?.isGatewayIncluded &&
                <div className={"w-full rounded-lg border-2 border-text-300 space-y-2 p-5 transition-all ease-in-out delay-100 duration-150"}>
                    <div className={"w-full flex items-center justify-center"}>
                        <span className={"font-bodyPri font-medium text-md text-text-800 text-center"}>
                            {"Card Payment"}
                        </span>
                    </div>
                    <div className={"flex items-center justify-between font-bodyPri font-normal text-base text-text-900"}>
                        <span>{"Amount:"}</span>
                        <span>{"USD"} {parseFloat(addPaymentIntentDetail?.payload?.amountToPay / 100).toFixed(2)}</span>
                    </div>
                    <div className={"flex items-center justify-between font-bodyPri font-normal text-base text-text-900"}>
                        <div className={"flex items-center justify-satart gap-1"}>
                            <span>{"Processing fee:"}</span>
                            <span className={"relative has-tooltip cursor-pointer"}>
                                <AiOutlineQuestionCircle className={"text-text-700"} />
                                <span className={cn(
                                    'tooltip z-50! bottom-5 -translate-x-[50%] px-2 py-1 w-80 leading-0.5 text-center',
                                    'bg-black/90 text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide',
                                )}>
                                    {"Processing fees are charged by the third party payment providers, we recommend purchasing more sessions at one time to reduce the processing fee"}
                                </span>
                            </span>
                        </div>
                        <span>{"USD"} {parseFloat(parseInt(Math.ceil(addPaymentIntentDetail?.payload?.totalAmountToPay * (addPaymentIntentDetail?.payload?.processingFeePct / 100))) / 100).toFixed(2)}</span>
                    </div>
                    <div className={"flex itmes-start justify-between font-bodyPri font-normal text-base text-text-900"}>
                        <span>{"Total amount:"}</span>
                        <div className={"flex flex-col items-end justify-start"}>
                            <span>{"USD"} {parseFloat(addPaymentIntentDetail?.payload?.totalAmountToPay / 100).toFixed(2)}</span>
                            <span className={"text-sm text-text-800"}>
                                {"Approx"} {currencyRateConversion(locals?.currencyCode, addPaymentIntentDetail?.payload?.totalAmountToPay)}
                            </span>
                        </div>
                    </div>
                </div>
            }

            <div className={cn(
                "w-full px-4 py-2 rounded-md bg-secondary-dark hover:opacity-90 cursor-pointer",
                "text-center font-buttons font-medium text-base text-text-50 whitespace-nowrap",
                !isPayButtonEnable && "!bg-secondary-light !cursor-not-allowed"
            )} onClick={handlePay}>
                <span>{payButtonText}</span>
            </div>

            {(initializeBookingDetail?.isLoading || bookingDetailConfirmation?.isLoading || addPaymentIntentDetail?.isLoading || confirmCardPayment?.isLoading) &&
                <div className={cn(
                    "w-full font-bodyPri font-medium text-base text-text-800 text-start flex flex-row gap-1 items-center justify-center"
                )}>
                    <span>{"Please wait. Loading..."}</span>
                    <FaSpinner className={"text-lg text-primary-dark animate-spin"} />
                </div>
            }
            {(initializeBookingDetail?.message || bookingDetailConfirmation?.message || addPaymentIntentDetail?.message || confirmCardPayment?.message) &&
                <div className={cn(
                    "w-full font-bodyPri font-normal text-xs text-red-500 text-center"
                )}>
                    <span>{initializeBookingDetail?.message || bookingDetailConfirmation?.message || addPaymentIntentDetail?.message || confirmCardPayment?.message}</span>
                </div>
            }

        </div>
    )
}

export default PaymentSummerySection