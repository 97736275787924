import { useEffect, useState } from 'react';
import { cn } from "utils/cn.utils";

import { FaSpinner } from 'react-icons/fa';
import { AiTwotoneCalendar } from 'react-icons/ai';

import ComponentLoader from 'components/loader/ComponentLoader';

import ChooseTimeSlot from 'components/modals/chooseInterviewSlot/ChooseTimeSlot';

import { getAvailableSlotDetail } from 'redux/availability/availability.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetAvailableSlotDetail } from 'redux/availability/availability.slice';

import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';

const ChooseInterviewSlotModal = () => {
  const { availableSlotDetail } = useAppState((state) => state.availability)
  const { interviewDetail, addInterviewSessionDetail } = useAppState((state) => state.interview)

  const dispatcher = useAppDispatcher()

  const [nowDayjs, setNowDayjs] = useState(dayjs())

  const timeZoneOffset = getTimeZoneOffset(timeZone)

  useEffect(() => {
    const interval = setInterval(() => {
      setNowDayjs(dayjs())
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })

  useEffect(() => {
    if (interviewDetail?.interviewDetail?.recruiter?.availabilityId) {
      dispatcher(getAvailableSlotDetail(interviewDetail?.interviewDetail?.recruiter?.availabilityId, { timeZone: timeZone, duration: interviewDetail?.interviewDetail?.duration }))
    }

    return () => {
      dispatcher(resetAvailableSlotDetail())
    }
  }, [interviewDetail?.interviewDetail?.recruiter?.availabilityId])

  if (interviewDetail?.isLoading) {
    return (
      <ComponentLoader isLoading={interviewDetail?.isLoading} />
    )
  }

  return (
    <div className={"space-y-5"}>
      <div className={"min-h-[24rem] block"}>

        <div className={'grid grid-cols-12 gap-8'}>

          <div className={"col-span-12 space-y-3"}>
            <span className={"truncate block text-center font-bodyPri font-medium text-base text-text-900"}>
              {interviewDetail?.interviewDetail?.interviewTitle?.substring(0, 50) + (interviewDetail?.interviewDetail?.interviewTitle?.length > 50 ? "..." : "")}
            </span>
            <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>
          </div>

          <div className={"col-span-12 md:col-span-4 space-y-3"}>
            <div className={"flex flex-col items-start justify-start gap-1"}>
              <span className={"inline font-bodyPri font-normal text-text-700 text-base"}>
                {"Description: "}
              </span>
              <span className={"inline font-bodyPri font-medium text-text-900 text-base"}>
                {interviewDetail?.interviewDetail?.description}
              </span>
            </div>
            <div className={""}>
              <span className={"inline font-bodyPri font-normal text-text-700 text-base"}>
                {"Duration: "}
              </span>
              <span className={"inline font-bodyPri font-medium text-text-900 text-base"}>
                {interviewDetail?.interviewDetail?.duration + " min."}
              </span>
            </div>

            <div className={"w-full flex flex-col items-center justify-start space-y-3"}>
              <div className={'relative w-24 h-24 rounded-full'}>
                <img
                  src={interviewDetail?.interviewDetail?.recruiter?.profilePicUrl}
                  alt={"profile-pic"}
                  className={"w-full h-full rounded-full object-cover"}
                />
                <div className={"relative has-tooltip"}>
                  <div className={'absolute right-1 bottom-1 w-6 h-6 rounded-full overflow-hidden border-2 border-white'}>
                    <img
                      src={`https://flagcdn.com/16x12/${interviewDetail?.interviewDetail?.recruiter?.fromCountry?.countryDomain}.png`.toLowerCase()}
                      alt={"country-flag"}
                      className='w-full h-full object-cover'
                    />
                  </div>
                  <span
                    className={cn(
                      "w-16 px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -top-1 -right-0",
                      "flex flex-col items-center justify-start overflow-hidden"
                    )}
                  >
                    {interviewDetail?.interviewDetail?.recruiter?.fromCountry?.country}
                  </span>
                </div>
              </div>
              <div className={'flex flex-col justify-start items-center space-y-2'}>
                <span className={'font-bodyPri font-normal text-sm text-text-700 tracking-wide'}>
                  {interviewDetail?.interviewDetail?.recruiter?.firstName + " " + interviewDetail?.interviewDetail?.recruiter?.lastName.charAt(0) + "."}
                </span>
                <span
                  className={'w-full font-bodyPri font-normal text-sm text-text-700 tracking-wide text-center line-clamp-4'}>
                  {interviewDetail?.interviewDetail?.recruiter?.bio}
                </span>
              </div>
            </div>
            {interviewDetail?.errorMsg &&
              <span className={"font-bodyPri font-normal text-text-900 text-sm"}>
                {interviewDetail?.errorMsg}
              </span>
            }
            {addInterviewSessionDetail?.isLoading &&
              <div className={"flex items-end"}>
                <div className={"flex items-center justify-center gap-1"}>
                  <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                    {"Please Wait ..."}
                  </span>
                  <FaSpinner className={"animate spin text-lg text-primary-main"} />
                </div>
              </div>
            }
          </div>

          <div className={"col-span-12 md:col-span-8 space-y-2"}>

            <div className={"flex flex-wrap items-center justify-between"}>
              <span className={"font-bodyPri font-normal text-sm text-text-700 text-center"}>
                {"Available Slots"}
              </span>
              <span className={"font-bodyPri font-normal text-sm text-text-700 text-center"}>
                {nowDayjs.tz(timeZone).format(`ddd, DD/MMM/YY hh:mm:ss A (z)`)}
              </span>
            </div>

            <div className={cn(
              "w-full flex flex-col items-start justify-start gap-5 h-full md:h-96 overflow-hidden overflow-y-scroll",
              "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
              "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
            )}>
              <ComponentLoader isLoading={availableSlotDetail?.isLoading} />
              {availableSlotDetail?.data?.result?.availableSlots?.map((dailyAvail, index) => (
                <div key={index} className={"flex flex-col items-start justify-start gap-3"}>
                  <span className={"font-bodyPri font-medium text-base text-text-900 flex items-center justify-start gap-2"}>
                    <AiTwotoneCalendar />
                    {dayjs(dailyAvail?.date + timeZoneOffset, "YYYY-MM-DDZ").tz(timeZone).format('dddd, DD MMM YYYY')}
                  </span>
                  <div className={"px-3 flex flex-wrap items-center gap-2"}>
                    {dailyAvail?.timeSlots?.map((timeSlot, index) => (
                      <ChooseTimeSlot key={index} timeSlot={timeSlot} dailyAvail={dailyAvail} />
                    ))}
                  </div>
                </div>
              ))}
              {(availableSlotDetail?.message || availableSlotDetail?.data?.result?.availableSlots?.length <= 0) &&
                <div className={"w-full h-full flex items-center justify-center"}>
                  <span className={"font-bodyPri font-semibold text-md text-text-700"}>
                    {"No Available Slots"}
                  </span>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChooseInterviewSlotModal