import React from "react";

//Context imports
import { useChat } from "../../contexts/chatContext";

//style imports
import { StyledModalBackground } from "./ModalBackground.styles";

export default function ModalBackground({
  setModalState,
  backgroundColor,
  loading,
}) {
  const { dispatch } = useChat();
  function closeRenderedModal() {
    if (setModalState) {
      setModalState(false);
    } else {
      if (loading) return;
      dispatch({
        type: "SET_MAKE_PAYMENT",
        payload: null,
      });
    }
  }
  return (
    <StyledModalBackground
      onClick={closeRenderedModal}
      backgroundColor={backgroundColor}
    ></StyledModalBackground>
  );
}
