import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { MdAdd } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';
import { OptionSelector } from 'components/common-components/Select';

import { classTypeTooltip, groupRepeatSession, oneOnOneRepeatSession, repeatTooltip, sessionDurationTooltip } from "pages/auth/createCourse/data";
import CreateCourseTooltip from 'pages/auth/createCourse/CreateCourseTooltip';
import RepeatWeeklySchedule from './repeatWeeklySchedule/RepeatWeeklySchedule';
import DoesNotRepeatSchedule from './doesNotRepeatSchedule/DoesNotRepeatSchedule';

import {
    updateCourseDetail,
    getCourseAvailabilityDetail,
    createCourseAvailabilityDetail,
    getCoursePriceDetail
} from 'redux/course/course.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearAddAvailabilityDetail } from 'redux/availability/availability.slice';
import {
    setAddCourseAvailDetailPayload,
    setClearAddCourseAvailDetail,
    setClearAddCourseAvailDetailPayload,
    setClearCourseAvailDetail,
    setClearCoursePriceDetail
} from 'redux/course/course.slice';
import { courseIsRecurringEnum, courseEndTypeEnum, courseType, weekAvailTimeSlots, courseStatus } from 'redux/course/course.const';

import { timeZone, weekdays } from 'utils/dateTime.utils';
import { getWeeklyAvailTimeSlotsWithDuration, getDateTimeSlotsWithDuration } from 'utils/availability.utils';
import { pagesInfo } from 'utils/pagesInfo';

const AddScheduleDetail = ({ stepCount, setStepCount }) => {
    const { addAvailabilityDetail } = useAppState((state) => state.availability)
    const { tutorCourseDetail, courseAvailDetail, addCourseAvailDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [isAddCourseScheduleEnabled, setIsAddCourseScheduleEnabled] = useState(courseAvailDetail?.courseAvailDetail ? true : false)

    const isGroupCourseType = tutorCourseDetail?.data?.type === courseType?.GROUP?.value
    const isOneOnOneCourseType = tutorCourseDetail?.data?.type === courseType?.ONE_ON_ONE?.value
    const underReviewOrPublishedStatus = [courseStatus?.UNDER_REVIEW?.value, courseStatus?.PUBLISHED?.value]?.includes(tutorCourseDetail?.data?.status)

    const repeatType = isGroupCourseType ? groupRepeatSession : oneOnOneRepeatSession

    useEffect(() => {
        if (tutorCourseDetail?.data?.courseAvailability?.id) {
            dispatcher(getCourseAvailabilityDetail(tutorCourseDetail?.data?.courseAvailability?.id, { timeZone: timeZone }))
        }
        if (tutorCourseDetail?.data?.coursePrice) {
            dispatcher(getCoursePriceDetail(tutorCourseDetail?.data?.coursePrice))
        }

        return () => {
            dispatcher(setClearCourseAvailDetail())
            dispatcher(setClearAddAvailabilityDetail())
            dispatcher(setClearAddCourseAvailDetail())
            dispatcher(setClearCoursePriceDetail())
        }
    }, [tutorCourseDetail?.data])

    useEffect(() => {
        if (courseAvailDetail?.courseAvailDetail) {
            dispatcher(setClearAddCourseAvailDetailPayload())
            if (tutorCourseDetail?.data?.type === courseType?.ONE_ON_ONE?.value) {
                dispatcher(setAddCourseAvailDetailPayload(courseAvailDetail?.courseAvailDetail?.availability))
            } else {
                dispatcher(setAddCourseAvailDetailPayload({
                    ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                    userId: courseAvailDetail?.courseAvailDetail?.availability?.user?.userId,
                    name: courseAvailDetail?.courseAvailDetail?.availability?.name,
                    description: courseAvailDetail?.courseAvailDetail?.availability?.description,
                    type: courseAvailDetail?.courseAvailDetail?.availability?.type,
                    isRecurring: courseAvailDetail?.courseAvailDetail?.availability?.isRecurring,
                    minTimeBeforeAvailStart: (isOneOnOneCourseType)
                        ? courseAvailDetail?.courseAvailDetail?.availability?.minTimeBeforeAvailStart : addCourseAvailDetail?.addCourseAvailDetailPayload?.minTimeBeforeAvailStart,
                    advanceSlotPeriodDays: (isOneOnOneCourseType)
                        ? courseAvailDetail?.courseAvailDetail?.availability?.advanceSlotPeriodDays : addCourseAvailDetail?.addCourseAvailDetailPayload?.advanceSlotPeriodDays,
                    duration: (isGroupCourseType)
                        ? courseAvailDetail?.courseAvailDetail?.availability?.duration / 15 : addCourseAvailDetail?.addCourseAvailDetailPayload?.duration,
                    weeklyTimeSlots: (courseAvailDetail?.courseAvailDetail?.availability?.isRecurring === courseIsRecurringEnum?.RECURRING?.value)
                        ? courseAvailDetail?.courseAvailDetail?.availability?.weeklyTimeSlots : addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyTimeSlots,
                    startDateTime: courseAvailDetail?.courseAvailDetail?.availability?.startDateTime
                        ? courseAvailDetail?.courseAvailDetail?.availability?.startDateTime : addCourseAvailDetail?.addCourseAvailDetailPayload?.startDateTime,
                    endType: courseAvailDetail?.courseAvailDetail?.availability?.endType
                        ? courseAvailDetail?.courseAvailDetail?.availability?.endType : courseEndTypeEnum?.ON_DATE?.value,
                    endDateTime: (courseAvailDetail?.courseAvailDetail?.availability?.endType === courseEndTypeEnum?.ON_DATE?.value)
                        ? courseAvailDetail?.courseAvailDetail?.availability?.endDateTime : addCourseAvailDetail?.addCourseAvailDetailPayload?.endDateTime,
                    weeklyCycle: (courseAvailDetail?.courseAvailDetail?.availability?.endType === courseEndTypeEnum?.WEEKLY_CYClE?.value)
                        ? courseAvailDetail?.courseAvailDetail?.availability?.weeklyCycle : addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyCycle,
                    dateTimeSlots: (courseAvailDetail?.courseAvailDetail?.availability?.isRecurring === courseIsRecurringEnum?.NON_RECURRING?.value)
                        ? courseAvailDetail?.courseAvailDetail?.availability?.dateTimeSlots : addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots
                }))
            }
            setIsAddCourseScheduleEnabled(true)
        }
    }, [courseAvailDetail?.courseAvailDetail])

    useEffect(() => {
        if (addAvailabilityDetail?.addAvailabilityDetail) {
            if (!tutorCourseDetail?.data?.courseAvailability?.id) {
                dispatcher(createCourseAvailabilityDetail(
                    {
                        course: tutorCourseDetail?.data?.id,
                        availability: addAvailabilityDetail?.addAvailabilityDetail?.result?.id
                    }
                ))
            }

            if (tutorCourseDetail?.data?.courseAvailability?.id) {
                dispatcher(getCourseAvailabilityDetail(tutorCourseDetail?.data?.courseAvailability?.id, { timeZone: timeZone }))
            }
            if (tutorCourseDetail?.data?.step <= stepCount) {
                dispatcher(updateCourseDetail(tutorCourseDetail?.data?.id, { step: tutorCourseDetail?.data?.step + 1 }))
            }
            const queryStepCount = stepCount < 5 ? stepCount + 1 : 5
            navigate(`${pagesInfo?.CREATE_COURSE?.pagePath}/${tutorCourseDetail?.data?.id}/edit?step=${queryStepCount}`)
            setStepCount(queryStepCount)
            dispatcher(setClearAddAvailabilityDetail())
        }
    }, [addAvailabilityDetail?.addAvailabilityDetail])

    const onHandleAddSchedule = () => {
        if (courseAvailDetail?.isLoading || addAvailabilityDetail?.isLoading) return;

        if (isGroupCourseType && underReviewOrPublishedStatus) return;

        dispatcher(setClearCourseAvailDetail())
        dispatcher(setClearAddCourseAvailDetail())
        dispatcher(setClearAddCourseAvailDetailPayload())

        if (isOneOnOneCourseType && !courseAvailDetail?.courseAvailDetail) {
            dispatcher(createCourseAvailabilityDetail({ course: tutorCourseDetail?.data?.id }))
        }
        setIsAddCourseScheduleEnabled(true)
    }

    const onHandleSessionDuration = async (duration) => {
        if (isGroupCourseType && underReviewOrPublishedStatus) return;

        const weeklyAvail = addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyTimeSlots
        const dateTimeAvailabilities = addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots
        const sessionDuration = duration * 15
        if (addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring === courseIsRecurringEnum?.RECURRING?.value) {
            const newWeeklyAvail = getWeeklyAvailTimeSlotsWithDuration({ timeZone, weekdays, weeklyAvail, sessionDuration })

            dispatcher(setAddCourseAvailDetailPayload({
                ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                duration: duration,
                weeklyTimeSlots: newWeeklyAvail,
            }))
        } else {
            const newDateTimeSlotAvail = await getDateTimeSlotsWithDuration(timeZone, dateTimeAvailabilities, sessionDuration)
            dispatcher(setAddCourseAvailDetailPayload({
                ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                duration: duration,
                dateTimeSlots: newDateTimeSlotAvail,
            }))
        }
    }

    const onHandleRepeat = (option) => {
        if (isOneOnOneCourseType) return;

        if (isGroupCourseType && underReviewOrPublishedStatus) return;

        if (!tutorCourseDetail?.data?.courseAvailability?.id) {
            if (option?.value === courseIsRecurringEnum?.RECURRING?.value) {
                dispatcher(setAddCourseAvailDetailPayload({
                    ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                    weeklyTimeSlots: addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyTimeSlots,
                    dateTimeSlots: []
                }))
            } else {
                dispatcher(setAddCourseAvailDetailPayload({
                    ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                    dateTimeSlots: addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots,
                    weeklyTimeSlots: []
                }))
            }
        }

        if (tutorCourseDetail?.data?.courseAvailability?.id) {
            if (option?.value === courseIsRecurringEnum?.RECURRING?.value) {
                dispatcher(setAddCourseAvailDetailPayload({
                    ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                    weeklyTimeSlots: courseAvailDetail?.courseAvailDetail?.availability?.weeklyTimeSlots,
                    dateTimeSlots: courseAvailDetail?.courseAvailDetail?.availability?.dateTimeSlots || []
                }))
            } else {
                dispatcher(setAddCourseAvailDetailPayload({
                    ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                    dateTimeSlots: courseAvailDetail?.courseAvailDetail?.availability?.dateTimeSlots,
                    weeklyTimeSlots: courseAvailDetail?.courseAvailDetail?.availability?.weeklyTimeSlots || weekAvailTimeSlots
                }))
            }
        }

        dispatcher(setAddCourseAvailDetailPayload({
            ...addCourseAvailDetail?.addCourseAvailDetailPayload,
            isRecurring: option?.value
        }))
    }

    return (
        <div className={cn(
            "w-full h-full bg-white rounded-lg select-none",
            isAddCourseScheduleEnabled && "min-h-[60vh]"
        )}>
            <div className={"w--full px-5 py-3 bg-background-darkLight rounded-lg"}>
                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Class Schedule"}
                </div>
            </div>
            {(addCourseAvailDetail?.isLoading || courseAvailDetail?.isLoading) &&
                <ComponentLoader isLoading={addCourseAvailDetail?.isLoading || courseAvailDetail?.isLoading} />
            }
            {(addCourseAvailDetail?.errorMsg || addAvailabilityDetail?.errorMsg || courseAvailDetail?.errorMsg) &&
                <div className={"w-full mt-3 flex items-center justify-center font-bodyPri font-normal text-red-500 text-base"}>
                    {addCourseAvailDetail?.errorMsg || addAvailabilityDetail?.errorMsg || courseAvailDetail?.errorMsg}
                </div>
            }
            {(!isAddCourseScheduleEnabled && !addCourseAvailDetail?.isLoading && !courseAvailDetail?.isLoading) &&
                <div className={cn(
                    "w-full px-3 py-3 mt-3 flex items-center justify-center border border-dashed border-text-500 rounded-lg",
                    "font-bodyPri font-normal text-text-900 cursor-pointer"
                )}
                    onClick={onHandleAddSchedule}
                >
                    <MdAdd className={"text-xl text-text-900"} />
                    <span className={"text-xl"}>
                        {"Add Schedule"}
                    </span>
                </div>
            }
            {(isAddCourseScheduleEnabled && !addCourseAvailDetail?.isLoading && !courseAvailDetail?.isLoading) &&
                <form className={"w-full grid grid-cols-12 gap-5 p-3 md:px-10 md:py-5"}>

                    {(isOneOnOneCourseType) &&
                        <div className={"col-start-1 col-span-full bg-complementry-light px-2 py-1"}>
                            <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                {"Applicable to all 1on1 sessions, this is the standard weekly schedule from your preferences. Updating this will affect all your 1on1 sessions."}
                            </span>
                        </div>
                    }

                    {/* course type container */}
                    <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-full lg:col-start-1 lg:col-span-3"}>
                        <div className={"flex items-center justify-start gap-1"}>
                            <CreateCourseTooltip tooltip={classTypeTooltip} translateX={0} />
                            <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                                {"Class Type:"}
                                <span className={"font-bodyPri font-normal text-red-500 text-base"}>{"*"}</span>
                            </span>
                        </div>
                    </div>
                    <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-full lg:col-start-4 lg:col-span-full md:w-80 flex flex-col items-start justify-start gap-1"}>
                        <input
                            type="text"
                            className={cn(
                                "w-full px-2 py-1 bg-background-light input-number-spin-none focus:outline-none border-2 border-text-300 rounded-md",
                                "text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide"
                            )}
                            placeholder={"Course Type..."}
                            value={courseType[tutorCourseDetail?.data?.type?.toUpperCase()]?.label}
                            disabled={true}
                        />
                        <Link
                            to={pagesInfo?.TEMPLATE?.pagePath}
                            className={"w-full flex justify-end font-bodyPri font-normal text-primary-dark text-xs tracking-wide hover:underline"}
                        >
                            {"Choose another template"}
                        </Link>
                    </div>

                    {/* minimum notice period container */}
                    {(isOneOnOneCourseType) &&
                        <div className={"col-start-1 col-span-full lg:col-start-1 lg:col-span-3 font-bodyPri font-normal text-text-900 text-base"}>
                            {"Minimum notice period required, before appointment can be booked:"}
                            <span className={"font-bodyPri font-normal text-red-500 text-base"}>{"*"}</span>
                        </div>
                    }
                    {(isOneOnOneCourseType) &&
                        <div className={"col-start-1 col-span-full lg:col-start-4 lg:col-span-full w-full lg:w-80 inline-flex items-center gap-1 bg-transparent border-2 border-text-300 px-1 py-0.5 rounded-md"}>
                            <input
                                type="number"
                                className={cn(
                                    "w-full px-1 py-1 bg-transparent input-number-spin-none focus:outline-none",
                                    "text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide"
                                )}
                                placeholder={"Price..."}
                                value={(addCourseAvailDetail?.addCourseAvailDetailPayload?.minTimeBeforeAvailStart / 60)?.toString()}
                                onChange={(e) => dispatcher(setAddCourseAvailDetailPayload({
                                    ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                                    minTimeBeforeAvailStart: Number(e.target.value) * 60
                                }))}
                                required={true}
                            />
                            <p className={cn("whitespace-nowrap font-bodyPri tracking-wide")}>
                                {"Hour(s)"}
                            </p>
                        </div>
                    }

                    {/* maximum time container */}
                    {(isOneOnOneCourseType) &&
                        <div className={"col-start-1 col-span-full lg:col-start-1 lg:col-span-3 font-bodyPri font-normal text-text-900 text-base"}>
                            {"Maximum time in advance that an appointment can be booked:"}
                            <span className={"font-bodyPri font-normal text-red-500 text-base"}>{"*"}</span>
                        </div>
                    }
                    {(isOneOnOneCourseType) &&
                        <div className={"col-start-1 col-span-full lg:col-start-4 lg:col-span-full w-full lg:w-80 inline-flex items-center gap-1 bg-transparent border-2 border-text-300 px-1 py-0.5 rounded-md"}>
                            <input
                                type="number"
                                className={cn(
                                    "w-full px-1 py-1 bg-transparent input-number-spin-none focus:outline-none",
                                    "text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide"
                                )}
                                placeholder={"Days..."}
                                value={addCourseAvailDetail?.addCourseAvailDetailPayload?.advanceSlotPeriodDays?.toString()}
                                onChange={(e) => dispatcher(setAddCourseAvailDetailPayload({
                                    ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                                    advanceSlotPeriodDays: Number(e.target.value)
                                }))}
                                required={true}
                            />
                            <p className={cn("whitespace-nowrap font-bodyPri tracking-wide")}>
                                {"Day(s)"}
                            </p>
                        </div>
                    }


                    {/* session duration container */}
                    {(isGroupCourseType) &&
                        <div className={"col-start-1 col-span-full lg:col-start-1 lg:col-span-3"}>
                            <div className={"flex items-center justify-start gap-1"}>
                                <CreateCourseTooltip tooltip={sessionDurationTooltip} translateX={0} />
                                <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                                    {"Session Duration:"}
                                    <span className={"font-bodyPri font-normal text-red-500 text-base"}>{"*"}</span>
                                </span>
                            </div>
                        </div>
                    }
                    {(isGroupCourseType) &&
                        <div className={"col-start-1 col-span-full lg:col-start-4 lg:col-span-full flex flex-col items-start justify-start gap-1"}>
                            <div className={"flex items-center justify-start gap-3"}>
                                <input
                                    type={"number"}
                                    className={cn(
                                        "w-16 px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400 bg-complementry-light",
                                        "font-bodyPri font-normal text-text-900 text-lg",
                                        "placeholder:text-text-500 placeholder:text-sm"
                                    )}
                                    minValue={2}
                                    defaultValue={2}
                                    value={addCourseAvailDetail?.addCourseAvailDetailPayload?.duration?.toString()}
                                    onChange={(event) => onHandleSessionDuration(event?.target?.value)}
                                    required={true}
                                    disabled={underReviewOrPublishedStatus}
                                />
                                <span className={"font-bodyPri font-normal text-text-800 text-base whitespace-nowrap"}>
                                    {"x 15 min. ="}
                                </span>
                                <div className={"inline-flex items-center justify-start gap-1 font-bodyPri font-bold text-text-800 text-lg"}>
                                    <span className={""}>
                                        {addCourseAvailDetail?.addCourseAvailDetailPayload?.duration <= 3
                                            ? addCourseAvailDetail?.addCourseAvailDetailPayload?.duration * 15
                                            : (parseFloat("" + (addCourseAvailDetail?.addCourseAvailDetailPayload?.duration * 15 / 60))?.toFixed(2))
                                        }
                                    </span>
                                    <span className={""}>
                                        {addCourseAvailDetail?.addCourseAvailDetailPayload?.duration <= 3
                                            ? " Minutes"
                                            : " Hour(s)"
                                        }
                                    </span>
                                </div>
                            </div>
                            {addCourseAvailDetail?.addCourseAvailDetailPayload?.duration < 1 &&
                                <div className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                                    {"session duration can not be 0"}
                                </div>
                            }
                        </div>
                    }

                    {/* repeat container */}
                    <div className={"col-start-1 col-span-full lg:col-start-1 lg:col-span-3"}>
                        <div className={"flex items-center justify-start gap-1"}>
                            <CreateCourseTooltip tooltip={repeatTooltip} translateX={0} />
                            <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                                {(isGroupCourseType)
                                    ? "Repeat:"
                                    : "Weekly availability:"
                                }
                                <span className={"font-bodyPri font-normal text-red-500 text-base"}>{"*"}</span>
                            </span>
                        </div>
                    </div>
                    {isOneOnOneCourseType &&
                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-full lg:col-start-4 lg:col-span-full md:w-80 flex flex-col items-start justify-start gap-1"}>
                            <input
                                type="text"
                                className={cn(
                                    "w-full px-2 py-1 bg-background-light input-number-spin-none focus:outline-none border-2 border-text-300 rounded-md",
                                    "text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide"
                                )}
                                value={"Default Weekly Schedule"}
                                disabled={true}
                            />
                        </div>
                    }
                    {isGroupCourseType &&
                        <div className={"col-start-1 col-span-full lg:col-start-4 lg:col-span-full md:w-80"}>
                            <OptionSelector
                                className={"w-full"}
                                options={Object.values(repeatType)}
                                value={addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring}
                                onChange={(option) => onHandleRepeat(option)}
                                required={true}
                            />
                        </div>
                    }


                    {(addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring === courseIsRecurringEnum?.RECURRING?.value) &&
                        <div className={"col-start-1 col-span-full"}>
                            <RepeatWeeklySchedule setIsAddCourseScheduleEnabled={setIsAddCourseScheduleEnabled} />
                        </div>
                    }
                    {(addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring === courseIsRecurringEnum?.NON_RECURRING?.value) &&
                        <div className={"col-start-1 col-span-full"}>
                            <DoesNotRepeatSchedule setIsAddCourseScheduleEnabled={setIsAddCourseScheduleEnabled} />
                        </div>
                    }
                </form>
            }
        </div>
    )
}

export default AddScheduleDetail;