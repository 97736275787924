import { cn } from "utils/cn.utils";
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CreateCourseTooltip from 'pages/auth/createCourse/CreateCourseTooltip';
import SaveAndCancelBtn from 'pages/auth/createCourse/SaveAndCancelBtn';
import ScheduleCard from "pages/auth/createCourse/components/confirmSchedulePage/components/repeatWeeklySchedule/ScheduleCard";
import { endsTooltip, startDateTooltip } from "pages/auth/createCourse/data";

import { createAvailabilityDetail, updateAvailabilityDetail } from 'redux/availability/availability.request';
import {
    getCourseAvailabilityDetail,
    deleteCourseAvailabilityDetail
} from 'redux/course/course.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setAddCourseAvailDetailPayload, setClearAddCourseAvailDetailPayload } from 'redux/course/course.slice';
import { courseType, courseIsRecurringEnum, courseEndTypeEnum, courseStatus, coursePriceTypeEnum } from "redux/course/course.const";

import { dayjs, timeZone, weekdays, getTimeZoneOffset } from 'utils/dateTime.utils';

const RepeatWeeklySchedule = ({ setIsAddCourseScheduleEnabled }) => {
    const { user } = useAppState((state) => state.user)
    const { tutorCourseDetail, courseAvailDetail, addCourseAvailDetail, coursePriceDetail } = useAppState((state) => state.course)
    const { addAvailabilityDetail } = useAppState((s) => s.availability)

    const dispatcher = useAppDispatcher()

    const [current, setCurrent] = useState(dayjs().tz(timeZone))

    const isGroupCourseType = tutorCourseDetail?.data?.type === courseType?.GROUP?.value
    const isOneOnOneCourseType = tutorCourseDetail?.data?.type === courseType?.ONE_ON_ONE?.value
    const underReviewOrPublishedStatus = [courseStatus?.UNDER_REVIEW?.value, courseStatus?.PUBLISHED?.value]?.includes(tutorCourseDetail?.data?.status)

    const isOneOneCourseScheduleSimilar = JSON.stringify(courseAvailDetail?.courseAvailDetail?.availability) === JSON.stringify(addCourseAvailDetail?.addCourseAvailDetailPayload)
    const isGroupCourseScheduleSimilar = JSON.stringify(courseAvailDetail?.courseAvailDetail?.availability) === JSON.stringify(addCourseAvailDetail?.addCourseAvailDetailPayload)

    var todayDate = new Date();
    var dateAfterSevenDays = todayDate.setDate(todayDate.getDate() + 8);
    var dateAfterFourteenDays = new Date(dateAfterSevenDays).setDate(new Date(dateAfterSevenDays).getDate() + 14);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrent(dayjs().tz(timeZone))
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    const saveWeeklyAvail = async (localWeeklyAvail) => {
        if (addAvailabilityDetail?.isLoading || addCourseAvailDetail?.isLoading) return;

        if (isGroupCourseType && underReviewOrPublishedStatus) return;

        dispatcher(setAddCourseAvailDetailPayload({
            ...addCourseAvailDetail?.addCourseAvailDetailPayload,
            weeklyTimeSlots: localWeeklyAvail
        }))
    }

    const getDateTimeSlotFromDay = async () => {
        let firstSlot = null
        let newStartDateTimeSlot = dayjs().tz(timeZone)

        for (let index = 0; index <= 7; index++) {
            const localDay = newStartDateTimeSlot?.format('dddd')
            const weeklyAvailSlots = addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyTimeSlots[localDay?.slice(0, 3)?.toUpperCase()]
            if (weeklyAvailSlots?.length > 0) {
                firstSlot = {
                    startTime: weeklyAvailSlots[0]?.startTime
                }
                break;
            } else {
                firstSlot = {
                    startTime: newStartDateTimeSlot?.format("HH:mm:ss")
                }
                newStartDateTimeSlot = newStartDateTimeSlot.add(1, "day")
            }
        }
        let startDate = newStartDateTimeSlot?.format("YYYY-MM-DD")
        newStartDateTimeSlot = startDate + " " + firstSlot?.startTime

        return newStartDateTimeSlot
    }

    const onHandleSave = async (event) => {
        event.preventDefault()

        if (isGroupCourseType && underReviewOrPublishedStatus) return;

        if (addCourseAvailDetail?.isLoading || addAvailabilityDetail?.isLoading || isOneOneCourseScheduleSimilar || isGroupCourseScheduleSimilar) return;

        if (isOneOnOneCourseType) {
            if (!addCourseAvailDetail?.addCourseAvailDetailPayload?.minTimeBeforeAvailStart) {
                toast.error("Please enter minimum notice period!")
                return;
            }
            if (!addCourseAvailDetail?.addCourseAvailDetailPayload?.advanceSlotPeriodDays) {
                toast.error("Please enter maximum advance booking time!")
                return;
            }
        }

        if (isGroupCourseType) {
            if (!addCourseAvailDetail?.addCourseAvailDetailPayload?.duration || addCourseAvailDetail?.addCourseAvailDetailPayload?.duration < 1) {
                toast.error("Please enter session duration!")
                return;
            }
        }

        if (addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.ON_DATE?.value && !addCourseAvailDetail?.addCourseAvailDetailPayload?.endDateTime) {
            toast.error("Please enter end date for the course!")
            return;
        }
        if (addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.WEEKLY_CYClE?.value && !addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyCycle) {
            toast.error("Please enter weekly cycle count!")
            return;
        }

        let newStartDateTime = addCourseAvailDetail?.addCourseAvailDetailPayload?.startDateTime
        let newEndDateTime = addCourseAvailDetail?.addCourseAvailDetailPayload?.endDateTime

        newStartDateTime = await getDateTimeSlotFromDay()

        if (isGroupCourseType) {

            if (addCourseAvailDetail?.addCourseAvailDetailPayload?.startDateTime) {
                const startDayName = dayjs(addCourseAvailDetail?.addCourseAvailDetailPayload?.startDateTime)?.tz(timeZone)?.format('dddd')

                const startDayTimeSlot = addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyTimeSlots[startDayName?.slice(0, 3)?.toUpperCase()]
                if (startDayTimeSlot?.length === 0) {
                    alert("You must have a session on the start date. Please update")
                    return;
                } else {
                    const startDateTimeFormat = dayjs(addCourseAvailDetail?.addCourseAvailDetailPayload?.startDateTime)?.tz(timeZone)?.format("YYYY-MM-DD") + " " + startDayTimeSlot[0]?.startTime
                    newStartDateTime = dayjs(startDateTimeFormat)?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss")
                }
            }

            if (addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.ON_DATE?.value) {
                const endDayName = dayjs(addCourseAvailDetail?.addCourseAvailDetailPayload?.endDateTime)?.tz(timeZone)?.format('dddd')

                const endDayTimeSlot = addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyTimeSlots[endDayName?.slice(0, 3)?.toUpperCase()]
                if (endDayTimeSlot?.length === 0) {
                    alert("You must have a session on the end date. Please update")
                    return;
                } else {
                    const endDateTimeFormat = dayjs(addCourseAvailDetail?.addCourseAvailDetailPayload?.endDateTime)?.tz(timeZone)?.format("YYYY-MM-DD") + " " + endDayTimeSlot[0]?.endTime
                    newEndDateTime = dayjs(endDateTimeFormat)?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss")
                }
            }

            if ([courseEndTypeEnum?.ON_DATE?.value, courseEndTypeEnum?.WEEKLY_CYClE?.value]?.includes(addCourseAvailDetail?.addCourseAvailDetailPayload?.endType) && (coursePriceDetail?.coursePriceDetail && coursePriceDetail?.coursePriceDetail?.type === coursePriceTypeEnum?.PLAN?.value)) {
                alert("You need to delete plan price, if you want to change weekly schedule for this class!")
                return;
            }
            if ([courseEndTypeEnum?.FOREVER?.value]?.includes(addCourseAvailDetail?.addCourseAvailDetailPayload?.endType) && (coursePriceDetail?.coursePriceDetail && coursePriceDetail?.coursePriceDetail?.type === coursePriceTypeEnum?.REGULAR?.value)) {
                alert("you need to delete regular price as Regular price cannot be set for a group class which have forever end type")
                return;
            }
        }

        if (!tutorCourseDetail?.data?.courseAvailability?.id) {
            const body = {
                name: tutorCourseDetail?.data?.title,
                description: tutorCourseDetail?.data?.subTitle,
                type: tutorCourseDetail?.data?.type,
                isRecurring: addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring,
                timeZone: timeZone,
            }

            if (isOneOnOneCourseType) {
                body["minTimeBeforeAvailStart"] = addCourseAvailDetail?.addCourseAvailDetailPayload?.minTimeBeforeAvailStart ? addCourseAvailDetail?.addCourseAvailDetailPayload?.minTimeBeforeAvailStart : 0
                body["advanceSlotPeriodDays"] = addCourseAvailDetail?.addCourseAvailDetailPayload?.advanceSlotPeriodDays
            }
            if (isGroupCourseType) {
                body["duration"] = addCourseAvailDetail?.addCourseAvailDetailPayload?.duration * 15
            }
            if (addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring === courseIsRecurringEnum?.RECURRING?.value) {
                body["weeklyTimeSlots"] = addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyTimeSlots
                body["startDateTime"] = newStartDateTime
                body["endType"] = addCourseAvailDetail?.addCourseAvailDetailPayload?.endType
                if (addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.ON_DATE?.value) {
                    body["endDateTime"] = newEndDateTime
                }
                if (addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.WEEKLY_CYClE?.value) {
                    body["weeklyCycle"] = addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyCycle
                }
            }
            if (isGroupCourseType) {
                body["userId"] = user?.user?.userId
                dispatcher(createAvailabilityDetail(body))
                return;
            } else {
                dispatcher(updateAvailabilityDetail(tutorCourseDetail?.data?.courseAvailability?.availability, body))
                return;
            }
        }

        if (tutorCourseDetail?.data?.courseAvailability?.id) {
            const body = {
                name: tutorCourseDetail?.data?.title,
                description: tutorCourseDetail?.data?.subTitle,
                type: tutorCourseDetail?.data?.type,
                isRecurring: addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring,
                timeZone: timeZone,
            }
            if (isOneOnOneCourseType) {
                body["minTimeBeforeAvailStart"] = addCourseAvailDetail?.addCourseAvailDetailPayload?.minTimeBeforeAvailStart
                body["advanceSlotPeriodDays"] = addCourseAvailDetail?.addCourseAvailDetailPayload?.advanceSlotPeriodDays
            }
            if (isGroupCourseType) {
                body["duration"] = addCourseAvailDetail?.addCourseAvailDetailPayload?.duration * 15
            }
            if (addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring === courseIsRecurringEnum?.RECURRING?.value) {
                body["weeklyTimeSlots"] = addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyTimeSlots
                body["startDateTime"] = newStartDateTime
                body["endType"] = addCourseAvailDetail?.addCourseAvailDetailPayload?.endType
                if (addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.ON_DATE?.value) {
                    body["endDateTime"] = newEndDateTime
                }
                if (addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.WEEKLY_CYClE?.value) {
                    body["weeklyCycle"] = addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyCycle
                }
            }
            dispatcher(updateAvailabilityDetail(tutorCourseDetail?.data?.courseAvailability?.availability, body))
            return;
        }
    }

    const onHandleCancel = () => {
        if (isOneOnOneCourseType || isOneOneCourseScheduleSimilar || isGroupCourseScheduleSimilar) return;

        if (isGroupCourseType && underReviewOrPublishedStatus) return;

        if (tutorCourseDetail?.data?.courseAvailability?.id) {
            dispatcher(getCourseAvailabilityDetail(tutorCourseDetail?.data?.courseAvailability?.id, { timeZone: timeZone }))
        } else {
            setIsAddCourseScheduleEnabled(false)
            dispatcher(setClearAddCourseAvailDetailPayload())
        }
    }

    const onHandleDelete = () => {
        if (!courseAvailDetail?.courseAvailDetail || isOneOnOneCourseType) return;

        if (isGroupCourseType && underReviewOrPublishedStatus) return;

        if (!window.confirm("The action will erase any saved schedule, are you sure?")) return;

        if (tutorCourseDetail?.data?.courseAvailability?.id) {
            dispatcher(deleteCourseAvailabilityDetail(tutorCourseDetail?.data?.courseAvailability?.id))
        } else {
            dispatcher(setClearAddCourseAvailDetailPayload())
        }
    }

    const onHandleReset = () => {
        if (isGroupCourseType || isOneOneCourseScheduleSimilar || isGroupCourseScheduleSimilar) return;

        if (tutorCourseDetail?.data?.courseAvailability?.id) {
            dispatcher(getCourseAvailabilityDetail(tutorCourseDetail?.data?.courseAvailability?.id, { timeZone: timeZone }))
        } else {
            dispatcher(setClearAddCourseAvailDetailPayload())
        }
    }


    return (
        <div className={"grid grid-cols-12 gap-5 w-full"}>
            <div className={"col-start-1 col-span-full lg:col-start-4 lg:col-span-full space-y-3"}>
                <div className={"space-y-1"}>
                    <p className="font-bodyPri font-normal text-sm text-text-700 text-right">
                        {current.format("ddd, DD MMM YY hh:mm:ss A")} - [{timeZone} ({getTimeZoneOffset(timeZone)})]
                    </p>
                    <div className={"h-0.5 bg-divider-medium w-full"}></div>
                </div>
                <div className={"w-full sm:max-w-xl md:max-w-3xl lg:max-w-5xl mx-auto space-y-3"}>
                    {(addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyTimeSlots) && weekdays?.map((day, index) => (
                        <ScheduleCard
                            key={index}
                            localDay={day}
                            weeklyAvailability={addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyTimeSlots}
                            saveWeeklyAvail={saveWeeklyAvail}
                        />
                    ))}
                </div>
            </div>

            {isGroupCourseType &&
                <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3"}>
                    <div className={"flex items-center justify-start gap-1"}>
                        <CreateCourseTooltip tooltip={startDateTooltip} translateX={0} />
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {"Start Date:"}
                            <span className={"text-lg text-red-500"}>{"*"}</span>
                        </span>
                    </div>
                </div>
            }
            {isGroupCourseType &&
                <div className={cn(
                    "col-start-1 col-span-full md:col-start-4 md:col-span-full",
                    "w-full md:w-80 px-3 py-1 border-2 border-divider-medium rounded-md mx-6",
                    (isOneOnOneCourseType) && "bg-text-100"
                )}>
                    <DatePicker
                        className={"w-full font-bodyPri font-normal text-text-900 text-base placeholder:text-text-500 placeholder:text-sm focus:outline-none"}
                        dateFormat={"dd/MM/yyyy"}
                        value={addCourseAvailDetail?.addCourseAvailDetailPayload?.startDateTime ? dayjs(addCourseAvailDetail?.addCourseAvailDetailPayload?.startDateTime)?.toDate() : dateAfterSevenDays}
                        selected={addCourseAvailDetail?.addCourseAvailDetailPayload?.startDateTime ? dayjs(addCourseAvailDetail?.addCourseAvailDetailPayload?.startDateTime)?.toDate() : dateAfterSevenDays}
                        minDate={dateAfterSevenDays}
                        placeholderText={"Select Start Date"}
                        scrollableYearDropdown={true}
                        showYearDropdown={true}
                        // showTimeSelect={true}
                        // timeIntervals={15}
                        yearDropdownItemNumber={80}
                        showMonthDropdown={true}
                        onChange={(date) => {
                            if (isOneOnOneCourseType) return;
                            if (isGroupCourseType && underReviewOrPublishedStatus) return;

                            dispatcher(setAddCourseAvailDetailPayload({
                                ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                                startDateTime: dayjs(date)?.format("YYYY-MM-DD HH:mm:ss")
                            }))
                        }}
                        required={true}
                        disabled={(isOneOnOneCourseType) || (isGroupCourseType && underReviewOrPublishedStatus)}
                    />
                </div>
            }
            {isGroupCourseType &&
                <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3"}>
                    <div className={"flex items-center justify-start gap-1"}>
                        <CreateCourseTooltip tooltip={endsTooltip} translateX={0} />
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {"Ends:"}
                            <span className={"text-lg text-red-500"}>{"*"}</span>
                        </span>
                    </div>
                </div>
            }
            {isGroupCourseType &&
                <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-full flex flex-col items-start justify-start gap-3"}>

                    {/* never radio button */}

                    {/* <div className={"flex items-center justify-start gap-3"}>
                        <input
                            type={"radio"}
                            className={cn(
                                tutorCourseDetail?.data?.type !== courseType?.ONE_ON_ONE?.value && "cursor-pointer",
                                isOneOnOneCourseType && "cursor-not-allowed"
                            )}
                            value={courseEndTypeEnum?.FOREVER?.value}
                            checked={(addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.FOREVER?.value) && true}
                            onClick={(event) => {
                                if (isOneOnOneCourseType) return;
                                if (isGroupCourseType && underReviewOrPublishedStatus) return;

                                dispatcher(setAddCourseAvailDetailPayload({
                                    ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                                    endType: event?.target?.value
                                }))
                            }}
                            disabled={(isOneOnOneCourseType) || (isGroupCourseType && underReviewOrPublishedStatus)}
                        />
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {courseEndTypeEnum?.FOREVER?.label}
                        </span>
                    </div> */}

                    {/* select a date radio button */}
                    <div className={"flex items-center justify-start gap-3"}>
                        <input
                            type={"radio"}
                            className={cn(
                                tutorCourseDetail?.data?.type !== courseType?.ONE_ON_ONE?.value && "cursor-pointer",
                                isOneOnOneCourseType && "cursor-not-allowed"
                            )}
                            value={courseEndTypeEnum?.ON_DATE?.value}
                            checked={(addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.ON_DATE?.value) && true}
                            onClick={(event) => {
                                if (isOneOnOneCourseType) return;
                                if (isGroupCourseType && underReviewOrPublishedStatus) return;

                                dispatcher(setAddCourseAvailDetailPayload({
                                    ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                                    endType: event?.target?.value
                                }))
                            }}
                            disabled={(isOneOnOneCourseType) || (isGroupCourseType && underReviewOrPublishedStatus)}
                        />
                        {(addCourseAvailDetail?.addCourseAvailDetailPayload?.endType !== courseEndTypeEnum?.ON_DATE?.value) &&
                            <span className={"px-2 py-1 bg-divider-darkLight rounded-lg font-bodyPri font-normal text-text-900 text-base"}>
                                {"DD MMM YYYY"}
                            </span>
                        }
                        {(addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.ON_DATE?.value) &&
                            <DatePicker
                                className={cn(
                                    "w-full md:w-80 px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                dateFormat={"dd/MM/yyyy"}
                                value={addCourseAvailDetail?.addCourseAvailDetailPayload?.endDateTime ? dayjs(addCourseAvailDetail?.addCourseAvailDetailPayload?.endDateTime)?.toDate() : dateAfterFourteenDays}
                                selected={addCourseAvailDetail?.addCourseAvailDetailPayload?.endDateTime ? dayjs(addCourseAvailDetail?.addCourseAvailDetailPayload?.endDateTime)?.toDate() : dateAfterFourteenDays}
                                minDate={dateAfterFourteenDays}
                                placeholderText={"Select End Date"}
                                scrollableYearDropdown={true}
                                showYearDropdown={true}
                                // showTimeSelect={true}
                                // timeIntervals={15}
                                yearDropdownItemNumber={80}
                                showMonthDropdown={true}
                                onChange={(date) => {
                                    if (isGroupCourseType && underReviewOrPublishedStatus) return;
                                    dispatcher(setAddCourseAvailDetailPayload({
                                        ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                                        endDateTime: dayjs(date)?.format("YYYY-MM-DD HH:mm:ss")
                                    }))
                                }}
                                required={(addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.ON_DATE?.value) && true}
                                disabled={(isOneOnOneCourseType) || (isGroupCourseType && underReviewOrPublishedStatus)}
                            />
                        }
                    </div>

                    <div className={"flex items-center justify-start"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                            {"OR"}
                        </span>
                    </div>

                    {/* weekly cycles radio Button */}
                    <div className={"flex items-center justify-start gap-3"}>
                        <input
                            type={"radio"}
                            className={cn(
                                tutorCourseDetail?.data?.type !== courseType?.ONE_ON_ONE?.value && "cursor-pointer",
                                isOneOnOneCourseType && "cursor-not-allowed"
                            )}
                            value={courseEndTypeEnum?.WEEKLY_CYClE?.value}
                            checked={(addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.WEEKLY_CYClE?.value) && true}
                            onClick={(event) => {
                                if (isOneOnOneCourseType) return;
                                if (isGroupCourseType && underReviewOrPublishedStatus) return;

                                dispatcher(setAddCourseAvailDetailPayload({
                                    ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                                    endType: event?.target?.value
                                }))
                            }}
                            disabled={(isOneOnOneCourseType) || (isGroupCourseType && underReviewOrPublishedStatus)}
                        />
                        <div className={"flex items-center justify-start gap-2"}>
                            <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                                {"After"}
                            </span>
                            <input
                                type={"number"}
                                placeholder={"Enter weekly cycles"}
                                className={cn(
                                    "w-16 px-3 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-900 text-lg",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                value={addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyCycle?.toString()}
                                onChange={(event) => {
                                    const weeklyCycleCount = Number(event.target.value)
                                    if (!isNaN(weeklyCycleCount) && event.target.value?.length < 3) {
                                        dispatcher(setAddCourseAvailDetailPayload({
                                            ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                                            weeklyCycle: weeklyCycleCount
                                        }))
                                    }
                                }}
                                required={(addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.WEEKLY_CYClE?.value) && true}
                                disabled={((addCourseAvailDetail?.addCourseAvailDetailPayload?.endType !== courseEndTypeEnum?.WEEKLY_CYClE?.value) || (isOneOnOneCourseType) || (isGroupCourseType && underReviewOrPublishedStatus)) && true}
                            />
                            <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                                {courseEndTypeEnum?.WEEKLY_CYClE?.label}
                            </span>
                        </div>
                    </div>
                </div>
            }

            <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-full flex items-center justify-start mt-5"}>
                <SaveAndCancelBtn
                    isLoading={addAvailabilityDetail?.isLoading || addCourseAvailDetail?.isLoading}
                    btnName={"Save & Next"}
                    deleteBtnLabel={"Delete & Start Over"}
                    isShowDeleteBtn={isOneOnOneCourseType ? false : true}
                    isShowCancelBtn={isOneOnOneCourseType ? false : true}
                    isShowResetBtn={isOneOnOneCourseType ? true : false}
                    isSaveBtnDisabled={isOneOneCourseScheduleSimilar || isGroupCourseScheduleSimilar || (isGroupCourseType && underReviewOrPublishedStatus)}
                    isCancelBtnDisabled={isOneOneCourseScheduleSimilar || isGroupCourseScheduleSimilar || (isGroupCourseType && underReviewOrPublishedStatus)}
                    isDeleteBtnDisabled={!courseAvailDetail?.courseAvailDetail || (isGroupCourseType && underReviewOrPublishedStatus)}
                    onSave={onHandleSave}
                    onCancel={onHandleCancel}
                    onReset={onHandleReset}
                    onDelete={onHandleDelete}
                />
            </div>
        </div>
    )
}

export default RepeatWeeklySchedule

// AiOutlineEnter