import { cn } from "utils/cn.utils";

import { MdAccessTimeFilled } from 'react-icons/md';
import { FiEdit } from 'react-icons/fi';
import { IoMdPricetag } from 'react-icons/io';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from 'redux/local/local.slice';
import { setModifyUserBookingDetailPayload } from 'redux/booking/booking.slice';
import { modalConst } from 'redux/local/local.const';
import { bookingOfferingTypeEnum, bookingRegularPriceModelEnum } from 'redux/booking/booking.const';
import { coursePricingModelTypeEnum } from 'redux/course/course.const';
import { availabilityIsRecurringEnum, availabilityEndTypeEnum } from 'redux/availability/availability.const';
import { locationPhoneCallTypeEnum, locationTypeEnum } from "redux/appointment/appointment.const";

import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';


const DayItems = ({ localDay, timeSlots }) => {

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    return timeSlots?.length > 0 && (
        <div className={cn(
            "col-span-1 flex flex-col justify-start items-center gap-1 py-2 w-32 h-full",
            "border-2 border-divider-medium rounded-lg bg-primary-light"
        )}>
            <span className={"font-bodyPri font-medium text-base text-primary-dark"}>
                {localDay}
            </span>
            <div className={"flex flex-col items-center gap-1"}>
                {timeSlots?.length > 0 && timeSlots?.map((timeSlot, index) => (
                    <span key={index} className={"font-bodyPri font-medium text-base text-primary-dark"}>
                        {dayjs(dayjs().tz(timeZone).format("YYYY-MM-DD") + " " + timeSlot?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("hh:mm A")}
                    </span>
                ))}
            </div>
        </div>
    )
}

const DateTimeItems = ({ dateTimeSlot }) => {

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    return dateTimeSlot?.timeSlots?.length > 0 && (
        <div className={cn(
            "col-span-1 flex flex-col justify-start items-center gap-1 py-2 w-32 h-full",
            "border-2 border-divider-medium rounded-lg bg-primary-light"
        )}>
            <span className={"font-bodyPri font-medium text-base text-primary-dark"}>
                {dayjs(dateTimeSlot?.date + " " + dateTimeSlot?.timeSlots[0]?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("ddd, DD MMM YY")}
            </span>
            <div className={"flex flex-col items-center gap-1"}>
                {dateTimeSlot?.timeSlots.map((timeSlot, index) => (
                    <span key={index} className={"font-bodyPri font-medium text-base text-primary-dark"}>
                        {dayjs(dateTimeSlot?.date + " " + timeSlot?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("hh:mm A")}
                    </span>
                ))}
            </div>
        </div>
    )
}

const BookingItemSection = () => {
    const { modal } = useAppState((state) => state.local)
    const { userBookingDetail, modifyUserBookingDetail } = useAppState((state) => state.booking)

    const dispatcher = useAppDispatcher()

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    const addCheckoutSlot = () => {
        dispatcher(setModifyUserBookingDetailPayload({
            ...modifyUserBookingDetail?.payload,
            schedule: null
        }))

        dispatcher(setModal({
            ...modal,
            [modalConst.CHECKOUT_SLOT_MODAL.stateKey]: true
        }))
    }

    const onHandelChangeLocation = () => {
        dispatcher(setModifyUserBookingDetailPayload({
            ...modifyUserBookingDetail?.payload,
        }))

        dispatcher(setModal({
            ...modal,
            [modalConst.checkoutLocationModal.key]: {
                ...modal.checkoutLocationModal,
                isVisible: true
            }
        }))
    }

    return (
        <div className={"space-y-3"}>
            <div className={"font-bodyPri font-medium text-lg text-text-800 space-x-3"}>
                <span>{"1"}</span>
                <span>{"Review Booking Item"}</span>
            </div>
            <div className={"W-full rounded-lg bg-white shadow-all p-5 space-y-3"}>

                {userBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value &&
                    <div className={"flex flex-col md:flex-row items-center md:items-start justify-start gap-5"}>
                        <div className={"flex flex-col items-center gap-3"}>
                            <div className={"w-32 aspect-square overflow-hidden rounded-full"}>
                                <img
                                    src={userBookingDetail?.data?.result?.appointment?.user?.profilePicUrl}
                                    alt={userBookingDetail?.data?.result?.appointment?.user?.firstName}
                                    className={"w-full h-full object-cover"}
                                />
                            </div>
                            <span className={"font-bodyPri font-medium text-base text-text-800"}>
                                {userBookingDetail?.data?.result?.appointment?.user?.firstName + " " + userBookingDetail?.data?.result?.appointment?.user?.lastName?.charAt(0)}
                            </span>
                        </div>
                        <div className={"space-y-3"}>
                            <div className={"flex flex-col items-start justify-between gap-3"}>
                                <span className={"font-bodyPri font-medium text-md text-text-900 line-clamp-1"}>
                                    {userBookingDetail?.data?.result?.appointment?.title}
                                </span>
                                <span className={"font-bodyPri font-normal text-base text-text-800 line-clamp-2"}>
                                    {userBookingDetail?.data?.result?.appointment?.subtitle}
                                </span>
                            </div>
                            <div className={"flex items-center gap-3"}>
                                <IoMdPricetag className={"text-xl text-text-700"} />
                                <span className={"font-bodyPri font-normal text-base text-text-900"}>
                                    {((userBookingDetail?.data?.result?.bookingPrice?.discountPct === 100)
                                        || (userBookingDetail?.data?.result?.bookingPrice?.priceModel === bookingRegularPriceModelEnum.FREE.value))
                                        ? bookingRegularPriceModelEnum.FREE.label
                                        : (`${userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code} ${parseFloat(userBookingDetail?.data?.result?.bookingPrice?.amount / 100).toFixed(2)}`
                                            + ` ${bookingRegularPriceModelEnum[userBookingDetail?.data?.result?.bookingPrice?.priceModel?.toUpperCase()]?.label}`)
                                    }
                                </span>
                                {userBookingDetail?.data?.result?.bookingPrice?.discountPct > 0 && (
                                    <span className={"font-bodyPri font-normal text-base text-text-900 space-x-2"}>
                                        <del className={"text-text-800"}>{`${userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code} ${parseFloat(userBookingDetail?.data?.result?.bookingPrice?.price / 100).toFixed(2)}`}</del>
                                        <span>{userBookingDetail?.data?.result?.bookingPrice?.discountPct + "% off"}</span>
                                    </span>
                                )}
                            </div>
                            <div className={"flex items-center gap-3"}>
                                <MdAccessTimeFilled className={"text-text-700 text-xl"} />
                                <span className={"font-bodyPri font-normal text-base text-text-900"}>
                                    {`${userBookingDetail?.data?.result?.bookingPrice?.duration} min | ${userBookingDetail?.data?.result?.bookingPrice?.sessionCount} session(s)`}
                                </span>
                            </div>
                        </div>
                    </div>
                }
                {userBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum.COURSE.value &&
                    <div className={"flex flex-col md:flex-row items-center md:items-start justify-start gap-5"}>
                        <div className={"w-72 md:w-60 lg:w-52 aspect-video overflow-hidden rounded-md"}>
                            <img
                                src={userBookingDetail?.data?.result?.course?.thumbnailPicUrl}
                                alt={userBookingDetail?.data?.result?.course?.title}
                                className={"w-full h-full object-cover"}
                            />
                        </div>
                        <div className={"space-y-3"}>
                            <div className={"flex flex-col items-start justify-between gap-3"}>
                                <span className={"font-bodyPri font-medium text-md text-text-900 line-clamp-1"}>
                                    {userBookingDetail?.data?.result?.course?.title}
                                </span>
                                {/* <span className={"font-bodyPri font-normal text-base text-text-800 line-clamp-2"}>
                                    {userBookingDetail?.data?.result?.course?.subTitle}
                                </span> */}
                            </div>
                            <div className={"flex items-center gap-3"}>
                                <IoMdPricetag className={"text-xl text-text-700"} />
                                <span className={"font-bodyPri font-normal text-base text-text-900"}>
                                    {(userBookingDetail?.data?.result?.bookingPrice?.discountPct === 100
                                        || userBookingDetail?.data?.result?.bookingPrice?.priceModel === bookingRegularPriceModelEnum.FREE.value)
                                        ? bookingRegularPriceModelEnum.FREE.label
                                        : (`${userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code} ${parseFloat(userBookingDetail?.data?.result?.bookingPrice?.amount / 100).toFixed(2)}`
                                            + ` ${coursePricingModelTypeEnum[userBookingDetail?.data?.result?.bookingPrice?.priceModel?.toUpperCase()]?.label}`)
                                    }
                                </span>
                                {userBookingDetail?.data?.result?.bookingPrice?.discountPct > 0 && (
                                    <span className={"font-bodyPri font-normal text-base text-text-900 space-x-2"}>
                                        <del className={"text-text-800"}>{`${userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code} ${parseFloat(userBookingDetail?.data?.result?.bookingPrice?.price / 100).toFixed(2)}`}</del>
                                        <span>{userBookingDetail?.data?.result?.bookingPrice?.discountPct + "% off"}</span>
                                    </span>
                                )}
                            </div>
                            <div className={"flex items-center gap-3"}>
                                <MdAccessTimeFilled className={"text-text-700 text-xl"} />
                                <span className={"font-bodyPri font-normal text-base text-text-900"}>
                                    {`${userBookingDetail?.data?.result?.bookingPrice?.duration} min | ${userBookingDetail?.data?.result?.course?.basicDetail?.sessionCount} session(s) ${userBookingDetail?.data?.result?.bookingSchedule?.isRecurring === "recurring" ? "per week" : ""}`}
                                </span>
                            </div>
                        </div>
                    </div>
                }

                <hr className={"h-0.5 w-full bg-background-medium rounded-full"} />

                <div className={"space-y-3"}>
                    <div className={"flex items-center justify-between"}>
                        <div className={"flex items-center justify-start gap-0.5"}>
                            <span className={"font-bodyPri font-medium text-md text-text-800"}>
                                {`Date and time (${dayjs().tz(timeZone).format("z")} ${timeZone})`}
                            </span>
                            {/* {(userBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum.COURSE.value
                                && userBookingDetail?.data?.result?.course?.type === courseType.ONE_ON_ONE.value) &&
                                <div className={"relative has-tooltip cursor-pointer flex items-center justify-start"}>
                                    <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                                    <span
                                        className={cn(
                                            "w-fit md:w-56 px-2 py-1 font-bodyPri font-normal text-xs rounded shadow-lg bg-text-900 text-text-50 tooltip -translate-x-[50%] -translate-y-[60%]",
                                            "flex flex-col items-start justify-start overflow-hidden"
                                        )}
                                    >
                                        {"Choose slot for your 1st session now & book remaining slots anytime after checkout. Go to booking section of your account."}
                                    </span>
                                </div>
                            } */}

                        </div>
                        {(userBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value) &&
                            <FiEdit onClick={() => addCheckoutSlot()} className={"text-xl text-text-700 hover:text-text-800 cursor-pointer"} />
                        }
                    </div>

                    <div className={"flex flex-col gap-2"}>
                        {userBookingDetail?.data?.result?.bookingSchedule?.type === "group" && userBookingDetail?.data?.result?.bookingSchedule?.startDateTime &&
                            <div className={"flex flex-row items-start justify-start gap-2"}>
                                <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                    {dayjs(userBookingDetail?.data?.result?.bookingSchedule?.startDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format('ddd, MMM DD YYYY')}
                                </span>
                                {((userBookingDetail?.data?.result?.bookingSchedule?.endType === availabilityEndTypeEnum.ON_DATE.value)
                                    && userBookingDetail?.data?.result?.bookingSchedule?.endDateTime) &&
                                    <>
                                        <span className={"font-bodyPri font-medium text-base text-text-700"}>{"-"}</span>
                                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                            {dayjs(userBookingDetail?.data?.result?.bookingSchedule?.endDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format('ddd, MMM DD YYYY')}
                                        </span>
                                    </>
                                }
                                {(userBookingDetail?.data?.result?.bookingSchedule?.endType === availabilityEndTypeEnum.WEEKLY_CYCLE.value) &&
                                    <>
                                        <span className={"font-bodyPri font-medium text-base text-text-700"}>{"-"}</span>
                                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                            {`Repeat ${userBookingDetail?.data?.result?.bookingSchedule?.weeklyCycle} weeks`}
                                        </span>
                                    </>
                                }
                                {(userBookingDetail?.data?.result?.bookingSchedule?.endType === availabilityEndTypeEnum.FOREVER.value) &&
                                    <>
                                        <span className={"font-bodyPri font-medium text-base text-text-700"}>{"-"}</span>
                                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                            {"Never Ends"}
                                        </span>
                                    </>
                                }
                            </div>
                        }
                        {(userBookingDetail?.data?.result?.bookingSchedule?.isRecurring === availabilityIsRecurringEnum.NON_RECURRING.value) &&
                            <div className={cn(
                                "grid grid-flow-col place-content-start gap-2 px-1 pb-3 overflow-hidden overflow-x-scroll",
                                "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                            )}>
                                {userBookingDetail?.data?.result?.bookingSchedule?.schedule?.map((dateTimeSlot, index) => (
                                    <DateTimeItems key={index} dateTimeSlot={dateTimeSlot} />
                                ))}
                            </div>
                        }
                        {(userBookingDetail?.data?.result?.bookingSchedule?.isRecurring === availabilityIsRecurringEnum.RECURRING.value) &&
                            <div className={cn(
                                "grid grid-flow-col place-content-start gap-2 px-1 pb-3 overflow-hidden overflow-x-scroll",
                                "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                            )}>
                                {Object.keys(userBookingDetail?.data?.result?.bookingSchedule?.schedule).map((localDay, index) => (
                                    <DayItems key={index} localDay={localDay} timeSlots={userBookingDetail?.data?.result?.bookingSchedule?.schedule[localDay]} />
                                ))}
                            </div>
                        }
                        {!userBookingDetail?.data?.result?.bookingSchedule?.schedule &&
                            <div
                                className={"w-fit mx-auto mt-3 px-5 py-2 rounded-md bg-primary-light cursor-pointer group hover:bg-primary-dark"}
                                onClick={addCheckoutSlot}
                            >
                                <span className={"font-bodyPri font-normal text-lg text-primary-dark text-center group-hover:text-text-50"}>
                                    {"Choose your slot(s)"}
                                </span>
                            </div>
                        }
                    </div>
                </div>
                {userBookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value && (
                    <>
                        <hr className={"h-0.5 w-full bg-background-medium rounded-full"} />
                        <div className={"space-y-3"}>
                            <div className={"flex items-center justify-between"}>
                                <div className={"flex items-center justify-start gap-0.5"}>
                                    <span className={"font-bodyPri font-medium text-md text-text-800"}>
                                        {"Booking Location:"}
                                    </span>
                                </div>
                                <FiEdit onClick={() => onHandelChangeLocation()} className={"text-xl text-text-700 hover:text-text-800 cursor-pointer"} />
                            </div>
                            {userBookingDetail?.data?.result?.bookingLocation?.location_type === locationTypeEnum.custom.key && (
                                <div className={"w-full flex items-start justify-start gap-2"}>
                                    <locationTypeEnum.custom.icon className={"text-2xl text-text-700"} />
                                    <p className={"font-bodyPri font-medium text-md text-text-800"}>
                                        {userBookingDetail?.data?.result?.bookingLocation?.custom?.custom_text}
                                    </p>
                                </div>
                            )}
                            {userBookingDetail?.data?.result?.bookingLocation?.location_type === locationTypeEnum.in_person_meeting.key && (
                                <div className={"w-full flex items-start justify-start gap-2"}>
                                    <locationTypeEnum.in_person_meeting.icon className={"text-2xl text-text-700"} />
                                    <p className={"flex flex-wrap gap-1 font-bodyPri font-medium text-md text-text-800 "}>
                                        <span>{userBookingDetail?.data?.result?.bookingLocation?.in_person_meeting?.street_line_1}</span>
                                        {userBookingDetail?.data?.result?.bookingLocation?.in_person_meeting?.street_line_2 && (<>|<span>{userBookingDetail?.data?.result?.bookingLocation?.in_person_meeting?.street_line_2}</span></>)}
                                        {userBookingDetail?.data?.result?.bookingLocation?.in_person_meeting?.city && (<>|<span>{userBookingDetail?.data?.result?.bookingLocation?.in_person_meeting?.city}</span></>)}
                                        {userBookingDetail?.data?.result?.bookingLocation?.in_person_meeting?.post_office && (<>|<span>{userBookingDetail?.data?.result?.bookingLocation?.in_person_meeting?.post_office}</span></>)}
                                        {userBookingDetail?.data?.result?.bookingLocation?.in_person_meeting?.state && (<>|<span>{userBookingDetail?.data?.result?.bookingLocation?.in_person_meeting?.state}</span></>)}
                                        {userBookingDetail?.data?.result?.bookingLocation?.in_person_meeting?.country && (<>|<span>{userBookingDetail?.data?.result?.bookingLocation?.in_person_meeting?.country}</span></>)}
                                        {userBookingDetail?.data?.result?.bookingLocation?.in_person_meeting?.zip && (<>|<span>{userBookingDetail?.data?.result?.bookingLocation?.in_person_meeting?.zip}</span></>)}
                                        {userBookingDetail?.data?.result?.bookingLocation?.in_person_meeting?.label && (<>|<span>{userBookingDetail?.data?.result?.bookingLocation?.in_person_meeting?.label}</span></>)}
                                    </p>
                                </div>
                            )}
                            {userBookingDetail?.data?.result?.bookingLocation?.location_type === locationTypeEnum.phone_call.key && (
                                <div className={"w-full flex items-start justify-start gap-2"}>
                                    <locationTypeEnum.phone_call.icon className={"text-2xl text-text-700"} />
                                    <div className={"w-full space-y-1"}>
                                        <p className={"font-bodyPri font-medium text-md text-text-800"}>
                                            {locationTypeEnum.phone_call.label}
                                        </p>
                                        {userBookingDetail?.data?.result?.bookingLocation?.phone_call?.location_phone_call_type === locationPhoneCallTypeEnum.call_me.key && (
                                            <p className={"font-bodyPri font-normal text-xs text-text-800"}>
                                                {"You will call the Host. Phone number will be provided upon booking completion."}
                                            </p>
                                        )}
                                        {userBookingDetail?.data?.result?.bookingLocation?.phone_call?.location_phone_call_type === locationPhoneCallTypeEnum.i_will_call.key && (
                                            <p className={"font-bodyPri font-medium text-md text-text-800"}>
                                                {`+${userBookingDetail?.data?.result?.bookingLocation?.phone_call?.isd_code}-${userBookingDetail?.data?.result?.bookingLocation?.phone_call?.phone_number}`}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                            {userBookingDetail?.data?.result?.bookingLocation?.location_type === locationTypeEnum.invitee.key && (
                                <div className={"w-full flex items-start justify-start gap-2"}>
                                    <locationTypeEnum.invitee.icon className={"text-2xl text-text-700"} />
                                    <div className={"w-full space-y-1"}>
                                        <p className={"font-bodyPri font-medium text-md text-text-800"}>
                                            {"Somewhere else"}
                                        </p>
                                        <p className={"font-bodyPri font-medium text-md text-text-800"}>
                                            {userBookingDetail?.data?.result?.bookingLocation?.invitee?.custom_text}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {userBookingDetail?.data?.result?.bookingLocation?.location_type === locationTypeEnum.edulyte_meet.key && (
                                <div className={"w-full flex items-start justify-start gap-2"}>
                                    <locationTypeEnum.edulyte_meet.icon className={"text-2xl text-text-700"} />
                                    <div className={"w-full space-y-1"}>
                                        <p className={"font-bodyPri font-medium text-md text-text-800"}>
                                            {locationTypeEnum.edulyte_meet?.label}
                                        </p>
                                        <p className={"font-bodyPri font-normal text-xs text-text-800"}>
                                            {"Web conferencing details provided upon confirmation."}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {userBookingDetail?.data?.result?.bookingLocation?.location_type === locationTypeEnum.google_meet.key && (
                                <div className={"w-full flex items-start justify-start gap-2"}>
                                    <locationTypeEnum.google_meet.icon className={"text-2xl text-text-700"} />
                                    <div className={"w-full space-y-1"}>
                                        <p className={"font-bodyPri font-medium text-md text-text-800"}>
                                            {locationTypeEnum.google_meet?.label}
                                        </p>
                                        <p className={"font-bodyPri font-normal text-xs text-text-800"}>
                                            {"Web conferencing details provided upon confirmation."}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default BookingItemSection