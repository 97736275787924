import { useCallback, useState, memo } from 'react';

import { IoIosCheckmark } from 'react-icons/io';
import { MdOutlineLocalPhone } from 'react-icons/md';

import FloatingLabelSelect from 'components/floating/floatingLabelSelect';
import FloatingLabelInput from 'components/floating/floatingLabelInput';

import SaveAndCancelBtn from 'components/modals/crmModals/commonComponents/SaveAndCancelBtn';
import RadioInput from 'components/modals/crmModals/commonComponents/RadioInput';

import { useAppState } from 'hooks/useStore';
import { crmContactLabelsEnum } from 'redux/crm/crm.const';

import { validateMobileNo } from "utils/validation-functions";

const ContactPhoneItem = memo(({
    index = 0,
    isLoading = false,
    isShowSaveBtn = false,
    isShowCancelBtn = false,
    phoneItem = null,
    onHandleChangePhoneDetail,
    onHandlePrimaryPhoneDetail,
    onHandleSavePhoneDetail = () => { },
    onHandleDeletePhoneDetail
}) => {
    const { countryList } = useAppState((state) => state.master)

    const [invalidPhoneDetail, setInvalidPhoneDetail] = useState({
        phoneId: null,
        isInvalidPhone: false
    })

    const onHandleUpdatePhoneNumber = useCallback((event) => {
        const newPhoneNumber = event.target.value
        if (newPhoneNumber && !validateMobileNo(newPhoneNumber)) {
            setInvalidPhoneDetail({
                phoneId: phoneItem?.id,
                isInvalidPhone: true
            })
        } else {
            setInvalidPhoneDetail(null)
        }
        onHandleChangePhoneDetail(phoneItem?.id, { ...phoneItem, [event.target.name]: newPhoneNumber })
    }, [invalidPhoneDetail, phoneItem])

    return (
        <div className={"w-full flex flex-col sm:flex-row gap-5 sm:gap-3 items-center border px-3 py-5 rounded shadow-sm"}>
            <div className={"w-full sm:w-fit flex justify-between gap-3"}>
                <div className={"flex gap-3 mt-2"}>
                    <div className="flex text-lg text-text-700">
                        <MdOutlineLocalPhone />
                    </div>
                    <div className={"flex sm:hidden"}>
                        <RadioInput
                            tooltipContent={phoneItem?.is_primary ? "Default" : "Make Default"}
                            isInputChecked={phoneItem?.is_primary}
                            onHandleClickInput={(event) => onHandlePrimaryPhoneDetail(phoneItem?.id, {
                                ...phoneItem,
                                is_primary: event?.target?.checked
                            })}
                        />
                    </div>
                </div>
                <div className={"flex sm:hidden mt-2"}>
                    <SaveAndCancelBtn
                        isLoading={isLoading}
                        isShowSaveBtn={isShowSaveBtn}
                        isShowCancelBtn={isShowCancelBtn}
                        saveBtnTooltip={'Save Phone'}
                        cancelBtnTooltip={"Remove Phone"}
                        isBtnDisable={invalidPhoneDetail?.isInvalidPhone || !phoneItem?.phone}
                        onHandleSaveBtn={() => onHandleSavePhoneDetail(phoneItem)}
                        onHandleCancelBtn={() => onHandleDeletePhoneDetail(phoneItem?.id)}
                    />
                </div>
            </div>
            <div className={"hidden sm:flex mt-2.5"}>
                <RadioInput
                    tooltipContent={phoneItem?.is_primary ? "Default" : "Make Default"}
                    isInputChecked={phoneItem?.is_primary}
                    onHandleClickInput={(event) => onHandlePrimaryPhoneDetail(phoneItem?.id, {
                        ...phoneItem,
                        is_primary: event?.target?.checked
                    })}
                />
            </div>
            <div className={"w-full sm:w-[16rem]"}>
                <FloatingLabelSelect
                    labelItem={`phone_country${index}${phoneItem?.id}`}
                    searchable={true}
                    options={countryList?.countryList?.map((country) => ({
                        label: `${country?.country} (+${country?.isdCode})`,
                        value: country?.masterCountryId,
                        country: country
                    })) || []}
                    onHandleSelect={(selectedOption) => onHandleChangePhoneDetail(phoneItem?.id, {
                        ...phoneItem,
                        country: {
                            masterCountryId: selectedOption?.value,
                            country: selectedOption?.label,
                            countryDomain: selectedOption?.country?.countryDomain,
                            isdCode: selectedOption?.country?.isdCode
                        }
                    })}
                    value={
                        <div className={"flex items-center gap-1"}>
                            <img
                                src={`https://flagcdn.com/16x12/${phoneItem?.country?.countryDomain}.png`.toLowerCase()}
                                className={"w-8 h-5 object-cover"}
                            />
                            <span className={"text-sm"}>{` (+${phoneItem?.country?.isdCode})`}</span>
                        </div>
                    }
                    OptionChild={({ option }) => (
                        <div className="bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between">
                            <div className="flex items-center gap-3">
                                <img
                                    src={`https://flagcdn.com/16x12/${option?.country?.countryDomain}.png`.toLowerCase()}
                                    className={"w-10 h-6 object-cover"}
                                />
                                {option?.label}
                            </div>
                            {(option?.value === phoneItem?.country?.masterCountryId)
                                && <IoIosCheckmark size={20} color="green" />
                            }
                        </div>
                    )}
                    label={"Country"}
                    dropdownWrapperClassName={"w-full sm:w-[25.4rem]"}
                    dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                />
            </div>
            <FloatingLabelInput
                labelItem={`phone_phone${index}${phoneItem?.id}`}
                onChange={onHandleUpdatePhoneNumber}
                value={phoneItem?.phone}
                label={"Phone"}
                type={"number"}
                inputName={"phone"}
                isInvalidInput={(invalidPhoneDetail?.phoneId === phoneItem?.id) && invalidPhoneDetail?.isInvalidPhone}
                inputClassName={"input-number-spin-none disabled:px-2 disabled:bg-text-200 disabled:cursor-not-allowed"}
            />
            <div className={"w-full sm:w-72"}>
                <FloatingLabelSelect
                    labelItem={`phone_label${index}${phoneItem?.id}`}
                    options={Object.values(crmContactLabelsEnum)}
                    onHandleSelect={(selectedOption) => onHandleChangePhoneDetail(phoneItem?.id, { ...phoneItem, label: selectedOption })}
                    value={phoneItem?.label?.label}
                    OptionChild={({ option }) => (
                        <div className="bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between">
                            {option.label}
                            {(option.value === phoneItem?.label?.value) &&
                                <IoIosCheckmark size={20} color="green" />
                            }
                        </div>
                    )}
                    label={"Label"}
                    dropDownContainerClassName={"w-full"}
                />
            </div>
            <div className={"hidden sm:flex mt-2"}>
                <SaveAndCancelBtn
                    isLoading={isLoading}
                    isShowSaveBtn={isShowSaveBtn}
                    isShowCancelBtn={isShowCancelBtn}
                    isBtnDisable={invalidPhoneDetail?.isInvalidPhone || !phoneItem?.phone}
                    saveBtnTooltip={'Save Phone'}
                    cancelBtnTooltip={"Remove Phone"}
                    onHandleSaveBtn={() => onHandleSavePhoneDetail(phoneItem)}
                    onHandleCancelBtn={() => onHandleDeletePhoneDetail(phoneItem?.id)}
                />
            </div>
        </div>
    )
})

export default ContactPhoneItem;