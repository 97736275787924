import { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { FaSpinner } from 'react-icons/fa';

import SelectSearch, { fuzzySearch } from "react-select-search";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ButtonLoader from 'components/loader/ButtonLoader';
import { OptionSelector } from 'components/common-components/Select';

import SaveCancelBtn from 'pages/auth/onboarding/commonComponents/onboardingButtons/SaveCancelBtn';
import { genderOptions, profileEditBtnConst, titleOptions } from 'pages/auth/onboarding/data';

import { getMasterCountryList } from 'redux/master/master.request';
import { updateUserCultureDetailByUserId, updateUserDetailByUserId } from 'redux/user/user.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setClearCountryList } from 'redux/master/master.slice';
import { resetUploadFile, setUploadFilePayload } from 'redux/storage/storage.slice';
import { setAddUserDetailPayload } from 'redux/user/user.slice';
import { modalConst } from 'redux/local/local.const';
import { fileCategories } from 'redux/storage/storage.const';
import { USER_DEMO_PROFILE } from 'redux/user/user.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';

const BasicDetail = ({ editBtnConst, setEditBtnConst }) => {
    const { modal } = useAppState((state) => state.local)
    const { countryList, timeZoneCountryDetail } = useAppState((state) => state.master)
    const { user, userDetail, addUserDetail, userCultureDetail, addUserCultureDetail } = useAppState((state) => state.user)
    const { uploadFile } = useAppState((state) => state.storage)

    const dispatcher = useAppDispatcher()

    const loadingElement = userDetail?.isLoading || userCultureDetail?.isLoading || addUserDetail?.isLoading || addUserCultureDetail?.isLoading || uploadFile?.isLoading

    const [myCountry, setMyCountry] = useState({
        currentCountry: {
            masterCountryId: 0,
            country: "",
            countryDomain: "",
            isdCode: ""
        },
        homeCountry: {
            masterCountryId: 0,
            country: "",
            countryDomain: "",
            isdCode: ""
        }
    })


    const getUserCultureByCultureDetail = () => {
        setMyCountry({
            currentCountry: userCultureDetail?.userCultureDetail?.liveInCountry,
            homeCountry: userCultureDetail?.userCultureDetail?.fromCountry
        })
    }

    const getUserCultureByTimeZone = () => {
        if (countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country) => (
                    country.countryDomain === "US"
                ))
            }
            setMyCountry({
                currentCountry: myLocalCountryList[0],
                homeCountry: myLocalCountryList[0]
            })
        }
    }

    useEffect(() => {
        dispatcher(getMasterCountryList())

        return () => {
            dispatcher(setClearCountryList())
        }
    }, [])


    // user culture detail
    useEffect(() => {
        if (userCultureDetail?.userCultureDetail) {
            getUserCultureByCultureDetail()
        }
        if (!userCultureDetail?.userCultureDetail && countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
            getUserCultureByTimeZone()
        }
    }, [countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail, userCultureDetail])

    // use effect for response of upload
    useEffect(() => {
        if (uploadFile?.data && uploadFile?.data?.length > 0) {
            dispatcher(updateUserDetailByUserId(user?.user?.userId, { [profileEditBtnConst?.PROFILE_PIC_URL?.value]: uploadFile?.data[0]?.fileUrl }))
            dispatcher(resetUploadFile())
            setEditBtnConst(null)
        }

    }, [uploadFile?.data])


    const onHandleUploadImage = () => {
        if (loadingElement) return;

        // if (!editBtnConst || (editBtnConst !== profileEditBtnConst?.PROFILE_PIC_URL?.value)) {
        //     setEditBtnConst(profileEditBtnConst?.PROFILE_PIC_URL?.value)
        // }
        dispatcher(resetUploadFile())
        dispatcher(setUploadFilePayload({
            ...uploadFile?.payload,
            fileCategory: fileCategories.profilePicture.value
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.IMAGE_PICKER_MODAL.stateKey]: true
        }))
    }

    const onSelectCountry = (key, option) => {
        if (loadingElement) return;

        const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option)
        const mySelectedCountry = {
            country: selectedCountry?.country,
            countryDomain: selectedCountry?.countryDomain,
            masterCountryId: selectedCountry?.masterCountryId,
            isdCode: selectedCountry?.isdCode
        }
        setEditBtnConst(key)

        if (key === profileEditBtnConst?.CURRENT_COUNTRY?.value) {
            setMyCountry({
                ...myCountry,
                currentCountry: mySelectedCountry
            })
        } else {
            setMyCountry({
                ...myCountry,
                homeCountry: mySelectedCountry
            })
        }

    }

    const onHandleChange = (key, value) => {
        if (loadingElement) return;

        if (!editBtnConst || editBtnConst !== key) {
            setEditBtnConst(key)
        }
        dispatcher(setAddUserDetailPayload({
            ...addUserDetail?.payload,
            [key]: value
        }))
    }

    const onHandleSaveUserDetail = (key) => {
        if (loadingElement) return;

        if (key === profileEditBtnConst?.PROFILE_PIC_URL?.value) {
            if (!addUserDetail?.payload?.profilePicUrl || addUserDetail?.payload?.profilePicUrl?.includes(USER_DEMO_PROFILE) || addUserDetail?.payload?.profilePicUrl?.includes("https://api.multiavatar.com")) {
                toast.warn("Please Upload Profile Picture!")
                return;
            }
        }
        if (key === profileEditBtnConst?.TITLE?.value && !addUserDetail?.payload?.title) {
            toast.warn("Please select title!")
            return;
        }
        if (key === profileEditBtnConst?.FIRST_NAME?.value && !addUserDetail?.payload?.firstName) {
            toast.warn("Please enter your first name!")
            return;
        }
        if (key === profileEditBtnConst?.LAST_NAME?.value && !addUserDetail?.payload?.lastName) {
            toast.warn("Please enter your last name!")
            return;
        }
        if (key === profileEditBtnConst?.PREFERRED_NAME?.value && !addUserDetail?.payload?.preferredName) {
            toast.warn("Please enter your preferred name!")
            return;
        }
        if (key === profileEditBtnConst?.GENDER?.value && !addUserDetail?.payload?.gender) {
            toast.warn("Please select your gender!")
            return;
        }

        if (key === profileEditBtnConst?.DATE_OF_BIRTH?.value) {
            if (addUserDetail?.payload?.dateOfBirth > new Date()) {
                toast.warn("Invalid Date!")
                return;
            }
            if ((dayjs(new Date())?.format("YYYY") - dayjs(addUserDetail?.payload?.dateOfBirth)?.format("YYYY")) < 18) {
                toast.warn("Minimum age should be 18 years!")
                return;
            }
        }

        dispatcher(updateUserDetailByUserId(user?.user?.userId, { [key]: addUserDetail?.payload[key] }))
        setEditBtnConst(null)
    }

    const onHandleSaveUserCulture = (key, value) => {
        if (loadingElement) return;
        const body = {}

        if (key === profileEditBtnConst?.CURRENT_COUNTRY?.value) {
            body["liveInMasterCountryId"] = myCountry?.currentCountry?.masterCountryId
        }
        if (key === profileEditBtnConst?.HOME_COUNTRY?.value) {
            body["fromMasterCountryId"] = myCountry?.homeCountry?.masterCountryId
        }

        dispatcher(updateUserCultureDetailByUserId(user?.user?.userId, body))
        setEditBtnConst(null)
    }

    const onHandleCancelUserDetail = (key) => {
        if (loadingElement) return;
        setEditBtnConst(null)
        dispatcher(setAddUserDetailPayload({
            ...addUserDetail?.payload,
            [key]: userDetail?.userDetail[key]
        }))
    }

    const onHandleCancelUserCulture = (key) => {
        if (loadingElement) return;
        setEditBtnConst(null)
        getUserCultureByCultureDetail()
    }

    return (
        <div className='grid grid-cols-12 gap-5 px-2 pt-2'>
            <div className={"col-start-0 col-span-full md:col-start-1 md:col-span-2 flex items-center justify-center"}>
                <div className={" w-24 h-24 rounded-full overflow-hidden"}>
                    <img
                        src={addUserDetail?.payload?.profilePicUrl}
                        className={"w-full h-full object-cover"}
                    />
                </div>
            </div>
            <div className={"col-start-0 col-span-full md:col-start-4 md:col-span-full flex flex-col justify-center items-center md:justify-start md:items-start gap-2"}>
                <div className={"flex flex-col items-center justify-center md:justify-start md:items-start gap-1 font-bodyPri font-normal text-text-800 text-sm"}>
                    <span className={"text-center md:text-left"}>
                        {"This will be displayed to other users when they view your profile."}
                    </span>
                    <span className='text-center md:text-left'>
                        {"Max Size: 2MB"}
                    </span>
                </div>
                <div className={cn(
                    "px-3 py-1 flex justify-center items-center rounded-lg text-sm",
                    (!editBtnConst || editBtnConst === profileEditBtnConst?.PROFILE_PIC_URL?.value) && "border border-divider-dark text-divider-dark hover:bg-divider-dark hover:text-text-50 cursor-pointer",
                    (editBtnConst && editBtnConst !== profileEditBtnConst?.PROFILE_PIC_URL?.value) && "border border-divider-darkLight bg-divider-darkLight",
                    uploadFile?.isLoading && "bg-divider-dark"
                )}
                    onClick={onHandleUploadImage}
                >
                    {uploadFile?.isLoading &&
                        <ButtonLoader isLoading={uploadFile?.isLoading} />
                    }
                    {!uploadFile?.isLoading && "Choose File"}
                </div>

            </div>

            <div className='col-start-1 col-span-full md:col-start-1 md:col-span-4 space-y-1'>
                <div className={"flex items-center justify-between gap-2"}>
                    <span className='font-bodyPri font-medium text-text-900 text-base tracking-wide'>
                        {"Title"}
                    </span>
                    <SaveCancelBtn
                        isShowBtn={editBtnConst === profileEditBtnConst?.TITLE?.value}
                        onHandleSave={() => onHandleSaveUserDetail(profileEditBtnConst?.TITLE?.value)}
                        onHandleCancel={() => onHandleCancelUserDetail(profileEditBtnConst?.TITLE?.value)}
                    />
                </div>
                <OptionSelector
                    options={Object.values(titleOptions)}
                    placeholder={"Please pick a type"}
                    required={true}
                    value={addUserDetail?.payload?.title}
                    onChange={(option) => onHandleChange(profileEditBtnConst?.TITLE?.value, option.value)}
                    disabled={editBtnConst && editBtnConst !== profileEditBtnConst?.TITLE?.value}
                />
            </div>
            <div className='col-start-1 col-span-full md:col-start-5 md:col-span-4 space-y-1'>
                <div className={"flex items-center justify-between gap-2"}>
                    <span className='font-bodyPri font-medium text-text-900 text-base tracking-wide'>
                        {"First Name"}
                    </span>
                    <SaveCancelBtn
                        isShowBtn={(editBtnConst === profileEditBtnConst?.FIRST_NAME?.value)}
                        onHandleSave={() => onHandleSaveUserDetail(profileEditBtnConst?.FIRST_NAME?.value)}
                        onHandleCancel={() => onHandleCancelUserDetail(profileEditBtnConst?.FIRST_NAME?.value)}
                    />
                </div>
                <input
                    type={"text"}
                    placeholder={"John Doe"}
                    autoFocus={true}
                    value={addUserDetail?.payload?.firstName}
                    className={cn(
                        "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                        "font-bodyPri font-normal text-text-800 text-base",
                        "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                    )}
                    onChange={(e) => onHandleChange(profileEditBtnConst?.FIRST_NAME?.value, (e.target.value).charAt(0).toUpperCase() + (e.target.value.slice(1)))}
                    disabled={(editBtnConst && editBtnConst !== profileEditBtnConst?.FIRST_NAME?.value)}
                />
            </div>
            <div className='col-start-1 col-span-full md:col-start-9 md:col-span-4 space-y-1'>

                <div className={"flex items-center justify-between gap-2"}>
                    <span className='font-bodyPri font-medium text-text-900 text-base tracking-wide'>
                        {"Last Name"}
                    </span>
                    <SaveCancelBtn
                        isShowBtn={editBtnConst === profileEditBtnConst?.LAST_NAME?.value}
                        onHandleSave={() => onHandleSaveUserDetail(profileEditBtnConst?.LAST_NAME?.value)}
                        onHandleCancel={() => onHandleCancelUserDetail(profileEditBtnConst?.LAST_NAME?.value)}
                    />
                </div>
                <input
                    type={"text"}
                    placeholder={"John Doe"}
                    value={addUserDetail?.payload?.lastName}
                    className={cn(
                        "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                        "font-bodyPri font-normal text-text-800 text-base",
                        "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                    )}
                    onChange={(e) => onHandleChange(profileEditBtnConst?.LAST_NAME?.value, (e.target.value).charAt(0).toUpperCase() + (e.target.value.slice(1)))}
                    disabled={(editBtnConst && editBtnConst !== profileEditBtnConst?.LAST_NAME?.value)}
                />
            </div>
            <div className='col-start-1 col-span-full md:col-start-1 md:col-span-4 space-y-1'>
                <div className={"flex items-center justify-between gap-2"}>
                    <span className='font-bodyPri font-medium text-text-900 text-base tracking-wide'>
                        {"Preferred Name"}
                    </span>
                    <SaveCancelBtn
                        isShowBtn={(editBtnConst === profileEditBtnConst?.PREFERRED_NAME?.value)}
                        onHandleSave={() => onHandleSaveUserDetail(profileEditBtnConst?.PREFERRED_NAME?.value)}
                        onHandleCancel={() => onHandleCancelUserDetail(profileEditBtnConst?.PREFERRED_NAME?.value)}
                    />
                </div>
                <input
                    type={"text"}
                    placeholder={"Smith"}
                    value={addUserDetail?.payload?.preferredName}
                    className={cn(
                        "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                        "font-bodyPri font-normal text-text-800 text-base",
                        "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                    )}
                    onChange={(e) => onHandleChange(profileEditBtnConst?.PREFERRED_NAME?.value, (e.target.value).charAt(0).toUpperCase() + (e.target.value.slice(1)))}
                    disabled={editBtnConst && editBtnConst !== profileEditBtnConst?.PREFERRED_NAME?.value}
                />
            </div>
            <div className='col-start-1 col-span-full md:col-start-5 md:col-span-4 space-y-1'>
                <div className={"flex items-center justify-between space-y-1"}>
                    <span className='font-bodyPri font-medium text-text-900 text-base tracking-wide'>
                        {"Gender"}
                    </span>
                    <SaveCancelBtn
                        isShowBtn={(editBtnConst === profileEditBtnConst?.GENDER?.value)}
                        onHandleSave={() => onHandleSaveUserDetail(profileEditBtnConst?.GENDER?.value)}
                        onHandleCancel={() => onHandleCancelUserDetail(profileEditBtnConst?.GENDER?.value)}
                    />
                </div>
                <OptionSelector
                    options={Object.values(genderOptions)}
                    placeholder={"Please pick a type"}
                    value={addUserDetail?.payload?.gender}
                    onChange={(option) => onHandleChange(profileEditBtnConst?.GENDER?.value, option.value)}
                    disabled={editBtnConst && editBtnConst !== profileEditBtnConst?.GENDER?.value}
                />
            </div>
            <div className='col-start-1 col-span-full md:col-start-9 md:col-span-4 space-y-1'>
                <div className={"flex items-center justify-between gap-2"}>
                    <span className='font-bodyPri font-medium text-text-900 text-base tracking-wide'>
                        {"Date Of Birth"}
                    </span>
                    <SaveCancelBtn
                        isShowBtn={editBtnConst === profileEditBtnConst?.DATE_OF_BIRTH?.value}
                        onHandleSave={() => onHandleSaveUserDetail(profileEditBtnConst?.DATE_OF_BIRTH?.value)}
                        onHandleCancel={() => onHandleCancelUserDetail(profileEditBtnConst?.DATE_OF_BIRTH?.value)} />
                </div>
                <DatePicker
                    className={cn(
                        "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                        "font-bodyPri font-normal text-text-800 text-base",
                        "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                    )}
                    value={addUserDetail?.payload?.dateOfBirth ? dayjs(addUserDetail?.payload?.dateOfBirth)?.tz(timeZone)?.toDate() : dayjs(new Date())?.tz(timeZone)?.toDate()}
                    selected={addUserDetail?.payload?.dateOfBirth ? dayjs(addUserDetail?.payload?.dateOfBirth)?.tz(timeZone)?.toDate() : dayjs(new Date())?.tz(timeZone)?.toDate()}
                    maxDate={new Date()}
                    placeholderText={"Select Date"}
                    scrollableYearDropdown={true}
                    showYearDropdown={true}
                    yearDropdownItemNumber={100}
                    showMonthDropdown={true}
                    onChange={(date) => onHandleChange(profileEditBtnConst?.DATE_OF_BIRTH?.value, dayjs(date)?.format("YYYY-MM-DD"))}
                    popperPlacement={"top-end"}
                    required={true}
                    disabled={editBtnConst && editBtnConst !== profileEditBtnConst?.DATE_OF_BIRTH?.value}
                />
                <div className={"font-bodyPri font-medium text-text-600 text-sm"}>
                    {"Private to you"}
                </div>
            </div>

            {/* country container */}
            <div className='col-start-0 col-span-full md:col-start-0 md:col-span-6 space-y-1'>
                <div className={"flex items-center justify-between gap-2"}>
                    <span className='font-bodyPri font-medium text-text-900 text-base tracking-wide'>
                        {"Current Country"}
                    </span>
                    <SaveCancelBtn
                        isShowBtn={(editBtnConst === profileEditBtnConst?.CURRENT_COUNTRY?.value)}
                        onHandleSave={() => onHandleSaveUserCulture(profileEditBtnConst?.CURRENT_COUNTRY?.value)}
                        onHandleCancel={() => onHandleCancelUserCulture(profileEditBtnConst?.CURRENT_COUNTRY?.value)}
                    />
                </div>
                {countryList?.isLoading &&
                    <div className={"flex items-center justify-start gap-0.5"}>
                        <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                            {"Please wait ..."}
                        </span>
                        <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                    </div>
                }
                <div className={cn(
                    "w-full border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                    "font-bodyPri font-normal text-text-800 text-base",
                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                )}>
                    <SelectSearch
                        options={countryList?.countryList?.map((country) => ({
                            name: country?.country,
                            value: country?.masterCountryId,
                            domain: country?.countryDomain
                        })) || []}
                        search
                        autoFocus={false}
                        placeholder={"Select Country"}
                        filterOptions={fuzzySearch}
                        value={myCountry?.currentCountry?.masterCountryId}
                        onChange={(option) => onSelectCountry(profileEditBtnConst?.CURRENT_COUNTRY?.value, option)}
                        disabled={editBtnConst && editBtnConst !== profileEditBtnConst?.CURRENT_COUNTRY?.value}
                    />
                </div>
            </div>

            {/* home country */}
            <div className='col-start-0 col-span-full md:col-start-0 md:col-span-6 space-y-1'>
                <div className={"flex items-center justify-between gap-2"}>
                    <span className='font-bodyPri font-medium text-text-900 text-base tracking-wide'>
                        {"Home Country"}
                    </span>
                    <SaveCancelBtn
                        isShowBtn={(editBtnConst === profileEditBtnConst?.HOME_COUNTRY?.value)}
                        onHandleSave={() => onHandleSaveUserCulture(profileEditBtnConst?.HOME_COUNTRY?.value)}
                        onHandleCancel={() => onHandleCancelUserCulture(profileEditBtnConst?.HOME_COUNTRY?.value)}
                    />
                </div>
                {countryList?.isLoading &&
                    <div className={"flex items-center justify-start gap-0.5"}>
                        <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                            {"Please wait ..."}
                        </span>
                        <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                    </div>
                }
                <div className={cn(
                    "w-full border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                    "font-bodyPri font-normal text-text-800 text-base",
                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                )}>
                    <SelectSearch
                        options={countryList?.countryList?.map((country) => ({
                            name: country?.country,
                            value: country?.masterCountryId,
                            domain: country?.countryDomain
                        })) || []}
                        search
                        autoFocus={false}
                        placeholder={"Select Country"}
                        filterOptions={fuzzySearch}
                        value={myCountry?.homeCountry?.masterCountryId}
                        onChange={(option) => onSelectCountry(profileEditBtnConst?.HOME_COUNTRY?.value, option)}
                        disabled={editBtnConst && editBtnConst !== profileEditBtnConst?.HOME_COUNTRY?.value}
                    />
                </div>
            </div>
        </div>
    )
}

export default BasicDetail;