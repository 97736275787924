import { motion } from "framer-motion";

const TabComponent = ({
  tabs,
  activeTab,
  setActiveTab,
  tabStyle,
  pillStyle,
  activeStyle,
}) => {
  return (
    <>
      {tabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => setActiveTab(tab)}
          className={`${activeTab === tab.id ? "" : activeStyle
            } relative transition ${tabStyle}`}
        >
          {activeTab === tab.id && (
            <motion.span
              layoutId="active-pill"
              className={`absolute inset-0 ${pillStyle}`}
              transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
            />
          )}
          <span className="relative">{tab.label}</span>
        </button>
      ))}
    </>
  );
};

export default TabComponent;
