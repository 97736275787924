import { memo } from 'react';

import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

const ShowMoreButton = memo(({
    isShowLessBtn = false,
    isShowMoreBtn = false,
    showLessText = "Show less",
    showMoreText = "Show all FAQs",
    onHandleToggleBtn
}) => {

    return (
        <button
            className={"flex items-center justify-center font-bodyPri text-primary-main hover:text-secondary-dark mt-5"}
            onClick={onHandleToggleBtn}
        >
            {isShowLessBtn && (
                <span className="flex items-center gap-1">
                    <FiChevronUp size={20} />
                    {showLessText}
                </span>
            )}
            {isShowMoreBtn &&
                <span className="flex items-center gap-1">
                    <FiChevronDown size={20} />
                    {showMoreText}
                </span>
            }
        </button>
    )
});

export default ShowMoreButton;