import { cn } from "utils/cn.utils";

import moment from "moment";

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import "./Calender.css";

const BigCalendar = ({
    toolbar = true,
    eventList = [],
    startAccessor = new Date(),
    endAccessor = new Date(),
    titleAccessor = "",
    view="month",
    defaultView="month",
    onHandleSelectEvent = () => {},
    onHandleSelectSlot = () => {},
    onHandleNavigationButtons = () => {},
    onHandleViewButtons = () => {},
    onHandleRangeChange = () => {},
    eventPropGetter = () => {},
    selectable = false,
    dayLayoutAlgorithm = "no-overlap",
    showMultiDayTimes = false,
    className = "",
    calendarStyle = {},
}) => {

    const localizer = momentLocalizer(moment);

    return (
        <Calendar
            className={cn("min-h-screen gap-3 font-heading font-normal text-text-900 text-base tracking-wide", className)}
            localizer={localizer}
            toolbar={toolbar}
            events={eventList}
            startAccessor={startAccessor}
            endAccessor={endAccessor}
            titleAccessor={titleAccessor}
            view={view}
            defaultView={defaultView}
            onSelectEvent={onHandleSelectEvent}
            dayLayoutAlgorithm={dayLayoutAlgorithm}
            showMultiDayTimes={showMultiDayTimes}
            selectable={selectable}
            onSelectSlot={onHandleSelectSlot}
            onNavigate={onHandleNavigationButtons}
            onView={onHandleViewButtons}
            onRangeChange={onHandleRangeChange}
            eventPropGetter={eventPropGetter}
            style={calendarStyle}
        />
    )
}

export default BigCalendar;