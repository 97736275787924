import SkillsTeach from "./components/SkillsTeach";
import TeachingStyles from "./components/TeachingStyles";
import SubjectTopicTeach from "./components/SubjectTopicTeach";

const MySkillsStyle = ({ editBtnConst, setEditBtnConst }) => {

    return (
        <div className={"space-y-5 py-3"}>
            <TeachingStyles editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />
            <SkillsTeach editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />
            <SubjectTopicTeach editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />
        </div>
    )
}

export default MySkillsStyle;