import { useState, useEffect, useRef } from "react";
import Cropper from "react-easy-crop";

import { Slider } from "@mui/material";

import { centerAspectCrop } from 'components/modals/imagePickerModal/imagePickerModal.data';

import {
    base64StringToFile,
    // extractImageFileExtensionFromBase64
} from 'utils/generators.utils';

const CROP_AREA_ASPECT = 4 / 3;

export default function ImageApp({ selectedFile, setCroppedFile }) {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [aspect, setAspect] = useState(CROP_AREA_ASPECT);
    const [zoom, setZoom] = useState(1);
    const [imageSrc, setImageSrc] = useState('');
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const [completedCrop, setCompletedCrop] = useState();

    let imgRef = useRef(null)

    useEffect(() => {
        const fileReader = new FileReader()
        fileReader.addEventListener("load", () => {
            setImageSrc(fileReader.result.toString() || "")
        }, false)
        fileReader.readAsDataURL(selectedFile)
    }, [selectedFile])


    useEffect(() => {
        if (croppedImageUrl) {
            // const fileExtension = extractImageFileExtensionFromBase64(imageSrc)
            // const imageData64 = canvasRef.toDataURL("image/" + (fileExtension))

            const fileName = selectedFile.name
            const newCroppedFile = base64StringToFile(croppedImageUrl, fileName)
            setCroppedFile(newCroppedFile)
        }
    }, [completedCrop])

    // const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    //     setCompletedCrop(croppedAreaPixels)
    //     const canvas = document.createElement('canvas');
    //     const image = document.createElement('img');
    //     image.src = imageSrc;
    //     canvas.width = croppedAreaPixels.width;
    //     canvas.height = croppedAreaPixels.height;
    //     const ctx = canvas.getContext('2d');
    //     ctx.drawImage(
    //         image,
    //         croppedAreaPixels.x,
    //         croppedAreaPixels.y,
    //         croppedAreaPixels.width,
    //         croppedAreaPixels.height,
    //         0,
    //         0,
    //         croppedAreaPixels.width,
    //         croppedAreaPixels.height
    //     );
    //     const newCroppedImageUrl = canvas.toDataURL();
    //     setCroppedImageUrl(newCroppedImageUrl);
    // }, [completedCrop])

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCompletedCrop(croppedAreaPixels)
        const canvas = document.createElement('canvas');
        const image = document.createElement('img');
        image.src = imageSrc;
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            croppedAreaPixels.x,
            croppedAreaPixels.y,
            croppedAreaPixels.width,
            croppedAreaPixels.height,
            0,
            0,
            croppedAreaPixels.width,
            croppedAreaPixels.height
        );
        const newCroppedImageUrl = canvas.toDataURL();
        setCroppedImageUrl(newCroppedImageUrl);
    }

    const onImageLoad = (event) => {
        if (aspect) {
            const { width, height } = event.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }


    return (
        <div className={"w-full block"}>
            <div className={"grid grid-cols-1 lg:grid-cols-2 gap-5"}>
                <div className={"w-full flex flex-col items-center gap-3"}>

                    <span className={"font-bodyPri font-medium text-base text-text-800"}>
                        {"Crop your image"}
                    </span>
                    <div className="relative h-[50vh] w-[50vh]">
                        <Cropper
                            className={"w-full col-span-1 border border-black bg-background-dark"}
                            // ref={imgRef}
                            image={imageSrc}
                            aspect={CROP_AREA_ASPECT}
                            crop={crop}
                            zoom={zoom}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                        >
                            <img
                                ref={imgRef}
                                src={imageSrc}
                                onLoad={onImageLoad}
                                alt={"cropped-pic"}
                                className={"w-full object-contain"}
                            />
                        </Cropper>
                    </div>
                    <div className="w-full">
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            onChange={(e) => setZoom(e.target.value)}
                        />
                    </div>
                </div>

                <div className={"w-full col-span-1 flex flex-col items-center gap-3"}>

                    <span className={"font-bodyPri font-medium text-base text-text-800"}>
                        {"Preview"}
                    </span>


                    <div className={"h-[50vh] flex items-center justify-center 1px border border-black"}>
                        {croppedImageUrl &&
                            <img src={croppedImageUrl} className={"w-full h-full object-cover"} />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}