import {useState, useEffect} from "react";

import { listOfTimeZones } from 'pages/auth/dashboard/data';

import { dayjs, getTimeZoneOffset, timeZone } from 'utils/dateTime.utils';


export const GlobalClock = () => {
  const [current, setCurrent] = useState(dayjs());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(dayjs());
    }, 1000);

    return () => {
      clearInterval(interval)
    }
  });

  return (
    <div className="p-5 space-y-3 w-full bg-white rounded-lg">
      <div className="flex justify-between">
        <span className="text-lg font-medium font-bodyPri text-text-900 tracking-wider">
          {"World Clock"}
        </span>
        <span className="text-sm text-text-700 font-bodyPri whitespace-nowrap">
          {`${timeZone} ${getTimeZoneOffset(timeZone)}`}
        </span>
      </div>
      <div className={"space-y-3"}>
      {listOfTimeZones.map((zone, index) => (
        <div key={index} className="px-5 py-3 rounded-lg shadow-all space-y-2">
          <p className={"font-bodyPri font-semibold text-xl text-text-800 tracking-wide"}>{zone.name}</p>
          <p className={"font-bodyPri font-normal text-md text-text-700"}>{current.tz(zone.zone).format(`ddd, DD/MMM/YY hh:mm:ss A (Z)`)}</p>
        </div>
      ))}
        </div>
    </div>
  );
};
