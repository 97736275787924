import { useCallback, useState } from 'react';

import SideBarExpendable from 'pages/auth/edulyteLms/commonComponents/SideBarExpendable'

import QuestionResourcesListItems from './QuestionResourcesListItems';
import QuestionTags from './QuestionTags';

import { getLmsResourceList } from 'redux/edulyteLms/lmsResource/lmsResource.request';

import { useAppDispatcher } from 'hooks/useStore';
import { resourceFileTypeEnum } from 'redux/edulyteLms/lmsResource/lmsResource.const';

import { debounce } from 'utils/generators.utils';

const QuestionSidebarItems = () => {

    const dispatcher = useAppDispatcher()

    const [resourceSearch, setResourceSearch] = useState('')

    const onHandleFilterResourceList = useCallback((value) => {
        dispatcher(getLmsResourceList({ file_type: resourceFileTypeEnum?.IMAGE?.value, search: value }))
    }, [])

    const onHandleSearchResource = useCallback((e) => {
        setResourceSearch(e.target.value)

        const delayFunction = debounce.debounce(onHandleFilterResourceList)
        delayFunction(e.target.value)
    }, [resourceSearch])

    return (
        <div className={'flex flex-col w-full gap-5 font-bodyPri'}>
            <div className={'w-full flex flex-col gap-5'}>
                <div>
                    <p className={'text-lg text-black font-medium'}>
                        {"Library"}
                    </p>
                    <p className={'text-sm text-text-600'}>
                        {"Quick access, pick from library."}
                    </p>
                </div>
                <div className={"flex flex-col items-center justify-center"}>
                    <SideBarExpendable
                        isExpended={true}
                        placeholder={'Search Image'}
                        category={'Images'}
                        onChange={onHandleSearchResource}
                        children={<QuestionResourcesListItems resourceSearch={resourceSearch} />}
                        expendHeight={'200px'}
                    />
                </div>
            </div>
            <QuestionTags />
        </div>
    )
}

export default QuestionSidebarItems;