import { addSessionUserDetailPayload, sessionFilterProps } from "redux/session/session.const";

export const SESSION_INITIAL_STATE = {
    // dashboard page upcoming event list
    dashboardUpcomingEventList: {
        isLoading: false,
        data: null,
        message: null
    },
    
    // dashboard page calendar event list
    dashboardCalendarEventList: {
        isLoading: false,
        data: null,
        message: null
    },

    calendarSessionUserList: {
        isLoading: false,
        data: null,
        message: null
    },

    sessionUserDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    startSessionUserDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    acceptSessionUserDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    declineSessionUserDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    rescheduleSessionUserDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    completeSessionUserDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    addSessionUserDetailPayload: {
        payload: addSessionUserDetailPayload,
    },

    // session course list
    sessionCourseList: {
        isLoading: false,
        data: null,
        message: null
    },

    // session appointment list
    sessionAppointmentList: {
        isLoading: false,
        data: null,
        message: null
    },

    // session location
    sessionLocationList: {
        isLoading: false,
        data: null,
        message: null
    },
    sessionLocationDetail: {
        isLoading: false,
        data: null,
        message: null,
    },
    addSessionLocationDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    modifySessionLocationDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    destroySessionLocationDetail: {
        isLoading: false,
        message: null
    },

    filterProps: sessionFilterProps
}