import React, { useEffect } from 'react'
import { cn } from "utils/cn.utils";

import { FaSpinner } from 'react-icons/fa';
import { BsInfoCircle } from 'react-icons/bs';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from 'redux/local/local.slice';
import { redeemPromoCode } from 'redux/promo/promo.request';
import { setRedeemPromoPayload, setClearRedeemPromo } from 'redux/promo/promo.slice';
import { modalConst } from 'redux/local/local.const';

const PromoModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { redeemPromo } = useAppState((state) => state.promo)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        return () => {
            dispatcher(setClearRedeemPromo())
        }
    }, [])

    useEffect(() => {
        if (redeemPromo?.redeemPromo) {
            dispatcher(setModal({
                ...modal,
                [modalConst.PROMO_MODAL.stateKey]: false
            }))
        }
    }, [redeemPromo?.redeemPromo])

    const handleRedeem = () => {
        if (!user?.user?.userId || !redeemPromo?.redeemPromoPayload?.promoCode) {
            return;
        }

        dispatcher(redeemPromoCode({ promoCode: redeemPromo?.redeemPromoPayload?.promoCode, userId: user?.user?.userId }))
    }

    return (

        <div className={"space-y-5"}>
            <div className={"min-h-[12rem] block"}>
                <div className='flex flex-col justify-center items-center gap-2 group'>
                    <div className={cn(
                        "relative mt-5 px-2 bg-white border-b-4 border-text-400 group-focus-within:border-divider-lightDark",
                        redeemPromo?.redeemPromoPayload?.promoCode && "border-divider-lightDark"
                    )} >
                        <input
                            type={"text"}
                            id={"promo-code-input"}
                            className={cn(
                                "w-full h-12 outline-none font-bodyPri font-normal text-text-900 text-lg tracking-wide",
                            )}
                            onChange={(e) => dispatcher(setRedeemPromoPayload({ ...redeemPromo?.redeemPromoPayload, promoCode: e.target.value }))}
                            value={redeemPromo?.redeemPromoPayload?.promoCode}
                            autoFocus={true}
                        />
                        <label className={cn(
                            "absolute top-0 left-2 h-12 flex items-center cursor-text",
                            "font-bodyPri font-normal text-text-500 text-lg",
                            "transition-all ease-in-out delay-75 duration-150",
                            "group-focus-within:text-text-700 group-focus-within:text-sm group-focus-within:-top-8 group-focus-within:pointer-none",
                            redeemPromo?.redeemPromoPayload?.promoCode && "!text-text-700 text-sm -top-8 pointer-none"
                        )}>
                            {"Promo Code"}
                        </label>
                    </div>
                    <div className={"h-5"}>
                        {redeemPromo?.isLoading &&
                            <div className={"flex items-center justify-start gap-2"}>
                                <FaSpinner className="animate-spin text-xl text-primary-main" />
                                <span className={"font-bodyPri font-normal text-base text-primary-main"}>{"Loading..."}</span>
                            </div>
                        }
                        {(!redeemPromo?.isLoading && redeemPromo?.errorMsg) &&
                            <span className="flex justify-start items-center gap-2">
                                <BsInfoCircle className={"text-sm text-red-500"} />
                                <p className={"font-bodySec font-normal text-xs tracking-wide text-red-500"}>
                                    {redeemPromo?.errorMsg}
                                </p>
                            </span>
                        }
                    </div>
                    <div className={"flex justify-center"}>
                        <span
                            className={cn(
                                "w-32 flex justify-center items-center py-1 rounded-md font-buttons font-normal text-base text-center cursor-pointer border-2",
                                (!redeemPromo?.isLoading ^ !redeemPromo?.redeemPromoPayload?.promoCode) && "border-primary-dark text-primary-dark hover:text-text-50 hover:bg-primary-dark",
                                (redeemPromo?.isLoading || !redeemPromo?.redeemPromoPayload?.promoCode) && "border-primary-light text-primary-light cursor-default",
                            )}
                            onClick={handleRedeem}
                        >
                            {"Apply"}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromoModal