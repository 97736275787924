import React from "react";

//Icon imports
import { DocumentDownloadIcon } from "@heroicons/react/outline";
import { DocumentIcon } from "@heroicons/react/outline";

//Style imports
import {
  StyledChatViewDocument,
  StyledChatViewFileDocumentName,
  StyledChatViewDocumentExtension,
  StyledChatViewFileDocumentNameText,
} from "./ChatViewFile.styles";

export default function ChatViewFileDocument(props) {
  const { fileName, url, fileSize, timeToDisplay, isSender } = props;
  const fileNameFormatted =
    fileName.length > 20 ? `${fileName.substring(0, 18)}...` : fileName;
  const fileSizeFormattedToKb = `${Math.round(fileSize / 1000)} KB`;
  const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);

  return (
    <StyledChatViewDocument isSender={isSender}>
      <StyledChatViewFileDocumentName>
        <StyledChatViewFileDocumentNameText isSender={isSender}>
          <DocumentIcon className="h-5 w-5 text-white mr-1" />
          <p>{fileNameFormatted}</p>
        </StyledChatViewFileDocumentNameText>
        <a href={`${url}`} download target="_blank">
          <DocumentDownloadIcon
            className="h-7 w-7 hover:cursor-pointer 
              text-primary-main"
          />
        </a>
      </StyledChatViewFileDocumentName>
      <StyledChatViewDocumentExtension isSender={isSender}>
        <p>{`${fileExtension.toUpperCase()} . ${fileSizeFormattedToKb}`}</p>
        <p className="italic">{timeToDisplay}</p>
      </StyledChatViewDocumentExtension>
    </StyledChatViewDocument>
  );
}
