import { toast } from "react-toastify";

import eventlyService from "redux/evently/evently.service";
import {
    setUserEventlyListLoading,
    setUserEventlyListData,
    setUserEventlyListMessage,

    setUserEventlyDetailLoading,
    setUserEventlyDetailData,
    setUserEventlyDetailMessage,

    setAddUserEventlyDetailLoading,
    setAddUserEventlyDetailData,
    setAddUserEventlyDetailMessage,

    setModifyUserEventlyDetailLoading,
    setModifyUserEventlyDetailMessage,

    setDestroyUserEventlyDetailLoading,
    setDestroyUserEventlyDetailData,
    setDestroyUserEventlyDetailMessage,

    setModifyEventlyEventsDetailLoading,
    setModifyEventlyEventsDetailData,
    setModifyEventlyEventsDetailMessage,
    setModifyUserEventlyDetailPayload,

    setEventlyInvitationListLoading,
    setEventlyInvitationListData,
    setEventlyInvitationListMessage,

    setSendEventlyInvitationDetailLoading,
    setSendEventlyInvitationDetailData,
    setSendEventlyInvitationDetailMessage,

    setDestroyEventlyInvitationDetailLoading,
    setDestroyEventlyInvitationDetailMessage,

    setValidateEventlyInvitationDetailLoading,
    setValidateEventlyInvitationDetailData,
    setValidateEventlyInvitationDetailMessage,

    setAcceptAndDeclineEventlyInvitationDetailLoading,
    setAcceptAndDeclineEventlyInvitationDetailMessage,
} from "redux/evently/evently.slice";

import { getCalendarSessionUserList } from "redux/session/session.request";

// user evently list
export const getUserEventlyList = (userId, query) => async (dispatch) => {
    dispatch(setUserEventlyListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: query
        }
        const response = await eventlyService.getUserEventlyList(requestData)
        if (response.status === 200) {
            dispatch(setUserEventlyListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserEventlyListMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setUserEventlyListLoading(false))
    }
}

// user evently detail
export const getUserEventlyDetail = (eventlyId) => async (dispatch) => {
    dispatch(setUserEventlyDetailLoading(true))

    try {
        const requestData = {
            params: { eventlyId: eventlyId }
        }
        const response = await eventlyService.getUserEventlyDetail(requestData)
        if (response.status === 200) {
            dispatch(setUserEventlyDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "something went wrong!")
        dispatch(setUserEventlyDetailMessage(error.response.data.message || error.response.data.error || "something went wrong!"))
    } finally {
        dispatch(setUserEventlyDetailLoading(false))
    }
}

// create user evently detail
export const createUserEventlyDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddUserEventlyDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            body: body
        }
        const response = await eventlyService.createUserEventlyDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddUserEventlyDetailData(response.data.data))
            dispatch(getCalendarSessionUserList(user?.user?.userId))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddUserEventlyDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddUserEventlyDetailLoading(false))
    }
}

// update user evently detail
export const updateUserEventlyDetail = (eventlyId, body) => async (dispatch) => {
    dispatch(setModifyUserEventlyDetailLoading(true))

    try {
        const requestData = {
            params: { eventlyId: eventlyId },
            body: body
        }
        const response = await eventlyService.updateUserEventlyDetail(requestData)
        if (response.status === 200) {
            dispatch(setUserEventlyDetailData(response.data.data))
            toast.success(response.data.message || "Updated Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyUserEventlyDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyUserEventlyDetailLoading(false))
    }
}

// delete user evently detail
export const deleteUserEventlyDetail = (eventlyId) => async (dispatch, getState) => {
    dispatch(setDestroyUserEventlyDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { eventlyId: eventlyId }
        }
        const response = await eventlyService.deleteUserEventlyDetail(requestData)
        if ((response.status === 200) || (response.status === 204)) {
            dispatch(setDestroyUserEventlyDetailData({ id: eventlyId }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setDestroyUserEventlyDetailMessage(error.response.data.message || error.response.data.message || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.message || "Something went wrong!")
    } finally {
        dispatch(setDestroyUserEventlyDetailLoading(false))
    }
}

// update evently events
export const updateEventlyEventsDetail = (eventId, body) => async (dispatch, getState) => {
    dispatch(setModifyEventlyEventsDetailLoading(true))

    try {
        const { userEventlyDetail } = getState().evently
        const requestData = {
            params: { eventId: eventId },
            body: body
        }
        const response = await eventlyService.updateEventlyEventsDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyEventlyEventsDetailData(response.data.data))
            dispatch(setUserEventlyDetailData({
                ...userEventlyDetail?.data,
                evently_events: userEventlyDetail?.data?.evently_events?.map((eventlyEvent) => ({
                    ...eventlyEvent,
                    event: response.data.data[0]
                }))
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.message || error)
        dispatch(setModifyEventlyEventsDetailMessage(error.response.data.message || error.response.data.message || "Something went wrong!"))
    } finally {
        dispatch(setModifyEventlyEventsDetailLoading(false))
    }
}

// get evently invitation list
export const getEventlyInvitationList = (query) => async (dispatch, getState) => {
    dispatch(setEventlyInvitationListLoading(true))

    try {
        const { eventlyInvitationList } = getState().evently
        const requestData = {
            query: query
        }
        const response = await eventlyService.getEventlyInvitationList(requestData)
        if (response.status === 200) {
            const invitationList = !!eventlyInvitationList?.data?.results?.length
                ? {
                    ...eventlyInvitationList?.data,
                    results: [...eventlyInvitationList?.data?.results, ...response.data.data.results],
                    records: eventlyInvitationList?.data?.records + response.data.data.records,
                    totalPages: response.data.data.totalPages,
                    totalRecords: response.data.data.totalRecords,
                    page: response.data.data.page
                }
                : response.data.data
            dispatch(setEventlyInvitationListData(invitationList))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setEventlyInvitationListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setEventlyInvitationListLoading(false))
    }
}

// create send evently invitation detail
export const createSendEventlyInvitationDetail = (body) => async (dispatch) => {
    dispatch(setSendEventlyInvitationDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await eventlyService.createSendEventlyInvitationDetail(requestData)
        if (response.status === 201) {
            dispatch(setSendEventlyInvitationDetailData(response.data.data))
            toast.success(response.data.message || "Created Successfully!")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setSendEventlyInvitationDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    }
    finally {
        dispatch(setSendEventlyInvitationDetailLoading(false))
    }
}

// Validate Evently Invitation detail
export const getValidateEventlyInvitationDetail = (query) => async (dispatch) => {
    dispatch(setValidateEventlyInvitationDetailLoading(true));

    try {
        const requestData = {
            query
        }
        const response = await eventlyService.getValidateEventlyInvitationDetail(requestData);
        if (response.status === 200) {
            dispatch(setValidateEventlyInvitationDetailData(response.data.data));
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.message || 'Something went wrong!')
        dispatch(setValidateEventlyInvitationDetailMessage(error.response.data.message || error.response.data.message || 'Something went wrong!'))
    } finally {
        dispatch(setValidateEventlyInvitationDetailLoading(false));
    }
}

// Accept or Decline Evently Invitation
export const getAcceptAndDeclineEventlyInvitationDetail = (body, query) => async (dispatch) => {
    dispatch(setAcceptAndDeclineEventlyInvitationDetailLoading(true));

    try {
        const requestData = {
            body,
            query
        }
        const response = await eventlyService.getAcceptAndDeclineEventlyInvitationDetail(requestData);
        if (response.status === 200) {
            dispatch(setValidateEventlyInvitationDetailData(response.data.data));
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.message || 'Something went wrong!')
        dispatch(setAcceptAndDeclineEventlyInvitationDetailMessage(error.response.data.message || error.response.data.message || 'Something went wrong!'))
    }
    finally {
        dispatch(setAcceptAndDeclineEventlyInvitationDetailLoading(false));
    }
}

// delete evently invitation detail
export const deleteEventlyInvitationDetail = (eventlyInvitationId) => async (dispatch, getState) => {
    dispatch(setDestroyEventlyInvitationDetailLoading(true))

    try {
        const { modifyUserEventlyDetail } = getState().evently
        const requestData = {
            params: { eventlyInvitationId: eventlyInvitationId }
        }
        const response = await eventlyService.deleteEventlyInvitationDetail(requestData)
        if (response.status === 200) {
            dispatch(setModifyUserEventlyDetailPayload({
                ...modifyUserEventlyDetail?.payload,
                selectedGuestsList: modifyUserEventlyDetail?.payload?.selectedGuestsList?.filter((guest) => guest?.id !== eventlyInvitationId)
            }))
            toast.success(response.data.message || "Deleted Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.message || 'Something went wrong!')
        dispatch(setDestroyEventlyInvitationDetailMessage(error.response.data.message || error.response.data.message || 'Something went wrong!'))
        toast.error(error.response.data.message || error.response.data.message || 'Something went wrong!')
    }
    finally {
        dispatch(setDestroyEventlyInvitationDetailLoading(false))
    }
}