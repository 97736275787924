import ToolTipView from 'components/tooltipView';
import { cn } from 'utils/cn.utils';

const LmsIconButtons = ({
    iconButtonList = [],
    onHandleIconButton
}) => {
    return (
        <div className={"flex flex-wrap items-center justify-start gap-2"}>
            {iconButtonList?.map((iconButton, index) => (
                <ToolTipView key={index} content={iconButton?.tooltip}>
                    <button
                        key={index}
                        className={cn("h-10 w-10 flex justify-center items-center rounded-full shadow-sm hover:bg-divider-darkLight group",
                            index === 0 && 'bg-primary-main animate-pulse hover:bg-secondary-dark'
                        )}
                        onClick={() => onHandleIconButton(iconButton)}
                    >
                        {iconButton.icon}
                    </button>
                </ToolTipView>
            ))}
        </div>
    )
}

export default LmsIconButtons;