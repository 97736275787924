import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Pagination from 'components/pagination/Pagination';
import TutorCard from 'components/tutorCard/TutorCard';
import TutorCardLoader from "components/loader/TutorCardLoader"

import {
    deleteFavTutorUserDetailByUserId,
    createFavTutorUserDetailByUserId,
    getMyTutorUserListByUserId
} from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearMyTutorList } from 'redux/tutor/tutor.slice';

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';

const RECORDS = 5


const AllTutors = () => {
    const { myTutorList } = useAppState((s) => s.tutor)
    const { user } = useAppState((s) => s.user)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const query = generateQueryParams(location.search)

        dispatcher(getMyTutorUserListByUserId(user?.user?.userId, { page: query.pn || "1", records: RECORDS }))

        return () => {
            dispatcher(setClearMyTutorList())
        }
    }, [location?.search])

    return (
        <div className="grid grid-cols-12">
            <div className='col-start-2 col-span-10 w-full space-y-3 md:col-start-1 md:col-span-12 lg:col-start-2 lg:col-span-10'>
                {myTutorList?.filteredMyTutorList?.results?.map((tutor, index) => (
                    <TutorCard key={index}
                        tutor={tutor}
                        isFav={user?.user?.favoriteTutors?.filter((favTutor) => favTutor?.tutorId === tutor?.tutorId).length > 0}
                        handleFav={(tutorId) => {
                            const favoriteTutors = user?.user?.favoriteTutors?.filter((favTutor) => favTutor?.tutorId === tutorId)
                            if (favoriteTutors.length > 0) {
                                dispatcher(deleteFavTutorUserDetailByUserId(user?.user?.userId, { tutorId: tutorId }))
                            }
                            else {
                                dispatcher(createFavTutorUserDetailByUserId(user?.user?.userId, { tutorId: tutorId }))
                            }
                        }}
                    />
                ))}
                {new Array(RECORDS).fill("").map((_, index) => (<TutorCardLoader key={index} isLoading={myTutorList?.isLoading} />))}
                {myTutorList?.filteredMyTutorList && (myTutorList?.filteredMyTutorList?.totalPages > 1) &&
                    <div className="w-full flex justify-center items-center">
                        <Pagination
                            page={myTutorList?.filteredMyTutorList?.page}
                            totalPages={myTutorList?.filteredMyTutorList?.totalPages}
                            onChangePage={(page) => navigate(pagesInfo.MY_COURSES.pagePath + "?pn=" + page)}
                        />
                    </div>
                }
                {(myTutorList?.filteredMyTutorList?.records === 0 || myTutorList?.errorMessage) &&
                    <span className='w-full h-full flex items-center justify-center text-center text-base font-bodyPri tracking-wide pt-5'>
                        {myTutorList?.errorMessage || "No Teacher Found!"}
                    </span>
                }
            </div>
        </div>
    )
}

export default AllTutors;