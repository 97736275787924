import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { cn } from "utils/cn.utils";

import { MdAdd } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';
import ToolTipView from 'components/tooltipView';
import Pagination from 'components/pagination/Pagination';
import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';

import LMSTableComponent from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTableComponent';
import { courseParticipantsNavigationOptions, courseStudentInvitationHeaderConst, courseStudentInviteMenuItemsConst, lmsCourseTooltips } from '../../../editLMSCourse.data';

import { deleteLmsCourseInvitationDetail, getLmsCourseInvitationList } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetLmsCourseInvitationList, resetSendLmsCourseInvitationDetail, resetSendLmsCourseInvitationDetailPayload } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsCourseInviteStatusEnum, lmsCourseInviteToEnum, lmsCourseStatusEnum } from 'redux/edulyteLms/lmsCourse/lmsCourse.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';
import { generateQueryParams } from 'utils/generators.utils';
import { pagesInfo } from 'utils/pagesInfo';

const RECORDS = 10;

const InvitedParticipants = () => {
    const { modal } = useAppState((state) => state.local)
    const { lmsCourseDetail, lmsCourseInvitationList, sendLmsCourseInvitationDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const query = generateQueryParams(location.search)

    useEffect(() => {
        return () => {
            dispatcher(resetLmsCourseInvitationList())
        }
    }, [])

    useEffect(() => {

        return () => {
            dispatcher(resetSendLmsCourseInvitationDetail())
            dispatcher(resetSendLmsCourseInvitationDetailPayload())
            dispatcher(setModal({
                ...modal,
                [modalConst.inviteLmsCourseUserModal.key]: {
                    ...modal[modalConst.inviteLmsCourseUserModal.key],
                    isVisible: false,
                    title: "Invite Student"
                }
            }))
        }
    }, [query?.action])

    useEffect(() => {
        if (lmsCourseDetail?.data) {
            if (query && (query?.action && (query?.action === "invite-participants"))) {
                if (lmsCourseDetail?.data?.course_setting?.status === lmsCourseStatusEnum?.PUBLISHED?.value) {
                    dispatcher(resetSendLmsCourseInvitationDetail())
                    dispatcher(resetSendLmsCourseInvitationDetailPayload())
                    dispatcher(setModal({
                        ...modal,
                        [modalConst.inviteLmsCourseUserModal.key]: {
                            ...modal[modalConst.inviteLmsCourseUserModal.key],
                            isVisible: true,
                            title: "Invite Student"
                        }
                    }))
                } else {
                    navigate(`${pagesInfo?.EDIT_LMS_COURSE?.pagePath}/${lmsCourseDetail?.data?.id}/edit/${courseParticipantsNavigationOptions?.INVITED?.to}`)
                }
            }
        }
    }, [lmsCourseDetail?.data, query?.action]);

    useEffect(() => {
        if (sendLmsCourseInvitationDetail?.data) {
            navigate(`${pagesInfo?.EDIT_LMS_COURSE?.pagePath}/${lmsCourseDetail?.data?.id}/edit/${courseParticipantsNavigationOptions?.INVITED?.to}`)
        }
    }, [sendLmsCourseInvitationDetail?.data])


    useEffect(() => {
        if (lmsCourseDetail?.data?.id) {
            const query = {
                page: 1,
                records: RECORDS,
                invite_to: lmsCourseInviteToEnum.STUDENT.value,
                lms_course_id: lmsCourseDetail?.data?.id
            }
            dispatcher(getLmsCourseInvitationList(query))
        }
    }, [sendLmsCourseInvitationDetail?.data, lmsCourseDetail?.data?.id])

    const onHandleInviteStudent = useCallback(() => {
        if (lmsCourseDetail?.data?.course_setting?.status !== lmsCourseStatusEnum?.PUBLISHED?.value) return;

        navigate(`${pagesInfo?.EDIT_LMS_COURSE?.pagePath}/${lmsCourseDetail?.data?.id}/edit/${courseParticipantsNavigationOptions?.INVITED?.to}?action=invite-participants`)

    }, [lmsCourseDetail?.data])

    const onHandleChangePage = useCallback((page) => {
        const query = {
            page: page,
            records: RECORDS,
            invite_to: lmsCourseInviteToEnum.STUDENT.value,
            lms_course_id: lmsCourseDetail?.data?.id
        }
        dispatcher(getLmsCourseInvitationList(query))
    }, [lmsCourseDetail?.data])

    const onHandleSelectMenu = useCallback((menuItem, courseInvitation) => {
        switch (menuItem?.value) {
            case courseStudentInviteMenuItemsConst?.DELETE?.value:
                dispatcher(deleteLmsCourseInvitationDetail(courseInvitation?.id))
                break;
            default:
                break;
        }
    }, [lmsCourseInvitationList?.data])

    const StudentImageContainer = ({ profilePicUrl }) => {
        return (
            <div className={"flex items-center justify-center"}>
                <div className={"relative w-10 h-10 overflow-hidden rounded-full"}>
                    <img
                        src={profilePicUrl}
                        className={"w-full h-full object-cover"}
                        alt={"profile-pic"}
                    />
                </div>
            </div>
        )
    }

    const courseInvitationRows = lmsCourseInvitationList?.data?.results?.map((courseInvitation) => [
        <StudentImageContainer profilePicUrl={courseInvitation?.user?.profile_pic_url} />,
        courseInvitation?.user?.email ? `${courseInvitation?.user?.email}` : "-",
        courseInvitation?.user?.first_name ? `${courseInvitation?.user?.first_name} ${courseInvitation?.user?.last_name?.charAt(0)}.` : "-",
        lmsCourseInviteStatusEnum[courseInvitation?.status?.toUpperCase()]?.label || "-",
        dayjs(courseInvitation?.created_at)?.tz(timeZone)?.format("ddd, MMM D, YYYY") || "-",
        <div className={"flex items-center justify-center"}>
            <ThreeDotMenu
                menuItems={Object.values(courseStudentInviteMenuItemsConst)}
                onHandleSelect={(menuItem) => onHandleSelectMenu(menuItem, courseInvitation)}
            />
        </div>
    ])

    return (
        <div className={"flex flex-col gap-5"}>
            <div className={"w-full flex items-center justify-end gap-3"}>
                <ToolTipView
                    content={(lmsCourseDetail?.data?.course_setting?.status === lmsCourseStatusEnum?.PUBLISHED?.value)
                        ? lmsCourseTooltips?.publishedInviteBtnTooltip
                        : lmsCourseTooltips?.disabledInviteBtnTooltip
                    }>
                    <div
                        className={cn(
                            "flex items-center justify-center gap-1 py-1 px-3 rounded-lg",
                            (lmsCourseDetail?.data?.course_setting?.status === lmsCourseStatusEnum?.PUBLISHED?.value)
                                ? "border border-primary-dark text-primary-dark cursor-pointer hover:bg-primary-dark group"
                                : "border border-divider-medium bg-text-300 text-text-400 cursor-not-allowed"
                        )}
                        onClick={onHandleInviteStudent}
                    >
                        <MdAdd className={"text-lg group-hover:text-text-50"} />
                        <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                            {"Invite someone"}
                        </span>
                    </div>
                </ToolTipView>
            </div>

            <div className={"px-5 space-y-3"}>
                {lmsCourseInvitationList?.isLoading &&
                    <ComponentLoader isLoading={lmsCourseInvitationList?.isLoading} />
                }
                {lmsCourseInvitationList?.message &&
                    <div className={"flex items-center justify-start gap-1"}>
                        <span className={"font-bodyPri font-medium text-red-500 text-base tracking-wide"}>
                            {lmsCourseInvitationList?.message}
                        </span>
                    </div>
                }
                {(!lmsCourseInvitationList?.isLoading && lmsCourseInvitationList?.data) &&
                    <LMSTableComponent
                        headers={Object.values(courseStudentInvitationHeaderConst)}
                        subRowHeader={[]}
                        rows={(lmsCourseInvitationList?.data?.results?.length > 0) ? courseInvitationRows : []}
                        rowHeaderColor={"bg-background-medium"}
                        alternateRowColor={"bg-primary-light"}
                    />
                }
                {(lmsCourseInvitationList?.data && (lmsCourseInvitationList?.data?.totalPages > 1)) &&
                    <div className={"flex justify-center items-center"}>
                        <Pagination
                            page={lmsCourseInvitationList?.data?.page}
                            totalPages={lmsCourseInvitationList?.data?.totalPages}
                            onChangePage={onHandleChangePage}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default InvitedParticipants;