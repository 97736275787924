import { FaSpinner } from "react-icons/fa";

import EmailVerificationContainer from 'components/modals/codeVerificationModal/components/EmailVerificationContainer';

import { useAppState } from 'hooks/useStore';

const CodeVerificationModal = () => {
  const { signUp } = useAppState((s) => s.auth)
  const { user } = useAppState((s) => s.user)

  const loadingElement = signUp?.isLoading || user?.isLoading

  return (
    <div className={"space-y-5 overflow-hidden"}>
      <div className={"min-h-[16rem] block"}>
        <div className='w-full h-full space-y-5 p-5'>
          <EmailVerificationContainer />
        </div>
      </div>
      {loadingElement &&
        <div className={"flex justify-center items-center gap-1"}>
          <div className={"px-5 animate-spin"}>
            <FaSpinner className={"text-primary-main text-2xl"} />
          </div>
          <span className={"w-full font-bodyPri font-bold text-primary-main text-lg whitespace-nowrap"}>
            {"Loading..."}
          </span>
        </div>
      }
      {(signUp?.message || user?.errorMsg) &&
        <div className={"flex justify-center items-center gap-1 px-3"}>
          <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
            {signUp?.message || user?.errorMsg}
          </span>
        </div>
      }
    </div>
  )
}

export default CodeVerificationModal;