import { createSlice } from "@reduxjs/toolkit";
import { OFFERING_INITIAL_STATE } from "redux/offering/offering.initialState";
import { offeringStatus, filterName } from "redux/offering/offering.const";
import { courseType } from "redux/course/course.const";

export const offering = createSlice({
    name: "offering",
    initialState: OFFERING_INITIAL_STATE,
    reducers: {
        // reducers for offering list
        setOfferingListLoading: (state, { payload }) => {
            state.offeringList.isLoading = payload
        },
        setOfferingList: (state, { payload }) => {
            state.offeringList.offeringList = payload
            state.offeringList.filteredOfferingList = payload
            state.offeringList.errorMsg = OFFERING_INITIAL_STATE.offeringList.errorMsg
        },
        setOfferingListErrorMsg: (state, { payload }) => {
            state.offeringList.errorMsg = payload
            state.offeringList.offeringList = OFFERING_INITIAL_STATE.offeringList.offeringList
            state.offeringList.filteredOfferingList = OFFERING_INITIAL_STATE.offeringList.filteredOfferingList
        },
        setClearOfferingList: (state) => {
            state.offeringList = OFFERING_INITIAL_STATE.offeringList
        },

        // reducers for offering course detail by course id
        setOfferingCourseLoading: (state, { payload }) => {
            state.offeringCourse.isLoading = payload
        },
        setOfferingCourse: (state, { payload }) => {
            state.offeringCourse.offeringCourse = payload
            state.offeringCourse.errorMsg = OFFERING_INITIAL_STATE.offeringCourse.errorMsg
        },
        setOfferingCourseErrorMsg: (state, { payload }) => {
            state.offeringCourse.errorMsg = payload
            state.offeringCourse.offeringCourse = OFFERING_INITIAL_STATE.offeringCourse.offeringCourse
        },
        setClearOfferingCourse: (state) => {
            state.offeringCourse = OFFERING_INITIAL_STATE.offeringCourse
        },

        // reducers for add offering/course
        setAddOfferingLoading: (state, { payload }) => {
            state.addOffering.isLoading = payload
        },
        setAddOffering: (state, { payload }) => {
            state.addOffering.addOffering = payload
            state.addOffering.errorMsg = OFFERING_INITIAL_STATE.addOffering.errorMsg
        },
        setAddOfferingErrorMsg: (state, { payload }) => {
            state.addOffering.errorMsg = payload
            state.addOffering.addOffering = OFFERING_INITIAL_STATE.addOffering.addOffering
        },
        setClearAddOffering: (state) => {
            state.addOffering = OFFERING_INITIAL_STATE.addOffering
        },

        // reducers for clone offerings
        setCloneOfferingLoading: (state, { payload }) => {
            state.cloneOffering.isLoading = payload
        },
        setCloneOffering: (state, { payload }) => {
            state.cloneOffering.cloneOffering = payload
            state.cloneOffering.errorMsg = OFFERING_INITIAL_STATE.cloneOffering.errorMsg
        },
        setCloneOfferingErrorMsg: (state, { payload }) => {
            state.cloneOffering.errorMsg = payload
            state.cloneOffering.cloneOffering = OFFERING_INITIAL_STATE.cloneOffering.cloneOffering
        },
        setClearCloneOffering: (state) => {
            state.cloneOffering = OFFERING_INITIAL_STATE.cloneOffering
        },

        // reducers for filter offering list
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps;
            const filteredList = payload.list
                .filter((offering) => (!payload.filterProps.searchText || (offering.courseTitle.toLowerCase().includes(payload.filterProps.searchText.toLowerCase()))
                ))
                .filter((offering) => (
                    payload.filterProps.courseType === courseType.ALL.value || offering.courseType === payload.filterProps.courseType
                ))
                .filter((offering) => (
                    payload.filterProps.offeringStatus === offeringStatus.ALL.value || offering.status === payload.filterProps.offeringStatus
                ))

            switch (payload.filterName) {
                case filterName.OFFERING.value:
                    state.offeringList.filteredOfferingList = filteredList
                    break;
                default:
                    break;
            }
        },
        setClearFilterProps: (state) => {
            state.filterProps = OFFERING_INITIAL_STATE.filterProps
        }
    }
})

export const {
    setOfferingListLoading,
    setOfferingList,
    setOfferingListErrorMsg,
    setClearOfferingList,

    setOfferingCourseLoading,
    setOfferingCourse,
    setOfferingCourseErrorMsg,
    setClearOfferingCourse,

    setAddOfferingLoading,
    setAddOffering,
    setAddOfferingErrorMsg,
    setClearAddOffering,

    setCloneOfferingLoading,
    setCloneOffering,
    setCloneOfferingErrorMsg,
    setClearCloneOffering,

    setFilterProps,
    setClearFilterProps
} = offering.actions
export default offering.reducer;