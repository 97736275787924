import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { calendarViewDetail, getCalendarListPayload, pageHeading } from 'pages/auth/schedule/data';

// import ScheduleStatistics from "pages/auth/schedule/components/ScheduleStatistics";
import ScheduleFilter from "pages/auth/schedule/components/Filters/ScheduleFilter";
import ScheduleCalendar from "pages/auth/schedule/components/ScheduleCalendar";
import Todos from "pages/auth/schedule/todos/Todos";

import PageHeader from 'components/pageHeader/PageHeader';

import { getCalendarSessionUserList } from 'redux/session/session.request';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetCalendarSessionUserList, resetFilterProps, setFilterProps } from 'redux/session/session.slice';
import { sessionFilterProps } from 'redux/session/session.const';

import { pagesInfo } from 'utils/pagesInfo';

import { useTitle } from 'hooks/useTitle';

const SchedulePage = () => {
  const { user } = useAppState(s => s.user)
  const { currentPageInfo } = useAppState(s => s.pageInfo)

  const dispatcher = useAppDispatcher()
  const location = useLocation()
  const navigate = useNavigate()
  const [title, setTitle] = useTitle()

  const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.SCHEDULE))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    setTitle({
      ...title,
      title: `${user?.user?.firstName} My Schedule & Calendar`
    })
  }, [dispatcher, currentPageInfo])

  useEffect(() => {

    return () => {
      dispatcher(resetCalendarSessionUserList())
      dispatcher(resetFilterProps())
    }
  }, [])

  useEffect(() => {
    getCalendarEventList()
  }, [])

  const getCalendarEventList = () => {
    const { viewProps, viewType } = calendarViewDetail()
    if (viewProps) {
      dispatcher(setFilterProps({
        filterProps: {
          ...sessionFilterProps,
          startDateTime: viewProps?.startDateTime, endDateTime: viewProps?.endDateTime
        }
      }))

      const requestDataPayload = getCalendarListPayload(searchQueryParams, viewProps)

      dispatcher(getCalendarSessionUserList(user?.user?.userId, { ...requestDataPayload }))

    } else {
      navigate(pagesInfo.PAGE_NOT_FOUND.pagePath)
    }
  }

  return (
    <div className="h-full w-full p-3 mx-auto min-h-screen flex flex-col gap-3">
      <PageHeader pageHeading={pageHeading} />
      {/* <ScheduleStatistics /> */}
      <ScheduleFilter />
      <ScheduleCalendar />
      <Todos />
    </div>
  );
};

export default SchedulePage;