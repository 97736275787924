import { useEffect } from 'react';
import { cn } from "utils/cn.utils";

import { studentPageHeading, tutorPageHeading, wallet, buttonFeature } from "pages/auth/wallet/data";

import WalletCard from "pages/auth/wallet/WalletCard";
import WalletFeatureButton from "pages/auth/wallet/WalletFeatureButton";
import WalletTabContent from 'pages/auth/wallet/WalletTabContent';

import PageHeader from 'components/pageHeader/PageHeader';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getMyProfile } from "redux/user/user.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

import { useTitle } from 'hooks/useTitle';
import { userRoles } from 'redux/auth/auth.const';

const WalletPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { locals } = useAppState((s) => s.local)
  const { user } = useAppState((s) => s.user)

  const dispatcher = useAppDispatcher()
  const [title, setTitle] = useTitle()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.WALLET))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    setTitle({
      ...title,
      title: "Wallet and Financial Transactions | Edulyte"
    })
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    dispatcher(getMyProfile())
  }, [])

  return (
    <div className="h-full w-full p-3 mx-auto min-h-screen space-y-3">

      <PageHeader
        pageHeading={(locals?.userRole === userRoles?.TUTOR?.value)
          ? tutorPageHeading
          : studentPageHeading}
        tooltipText={"Manage your credits in USD, AUD or INR seamlessly. Store all your transactions securely in one place."}
      />

      <div className='grid grid-cols-1 lg:grid-cols-2 gap-3'>

        <div className="bg-white rounded-lg p-5 w-full">
          <div className={cn(
            "grid grid-flow-col gap-5 place-items-center overflow-hidden overflow-x-auto px-5",
            "scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thin",
            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
          )}>
            <WalletCard walletItem={{ ...wallet.TOKEN_CREDIT, amount: user?.user?.gpt_user_token?.total_tokens * 100 }} />
            {Object.values(wallet).filter((item) => (item.value !== wallet.TOKEN_CREDIT.value)).map(item => {
              switch (item?.value) {
                case wallet.TOKEN_CREDIT.value:
                  return { ...item, amount: user?.user?.netTokenCredit }
                case wallet.WALLET_CREDIT.value:
                  return { ...item, amount: user?.user?.netWalletCredit }
                // case wallet.PENDING_CREDIT.value:
                //   return { ...item, amount: user?.user?.netPendingCredit }
                case wallet.PROMO_CREDIT.value:
                  return { ...item, amount: user?.user?.netPromoCredit }
                case wallet.AUD_CREDIT.value:
                  return { ...item, amount: user?.user?.netAudWalletCredit }
                case wallet.INR_CREDIT.value:
                  return { ...item, amount: user?.user?.netInrWalletCredit }
                default:
                  return item
              }
            }).sort((item1, item2) => item1.amount < item2.amount ? 1 : -1)?.map((walletItem, index) => {
              return walletItem.roles.includes(locals.userRole) && (
                <WalletCard key={index} walletItem={walletItem} />
              )
            })}
          </div>
        </div>

        <div className="bg-white rounded-lg p-5 w-full">
          <div className={cn(
            "grid grid-flow-col gap-3 h-full place-items-center overflow-hidden overflow-x-auto overflow-y-hidden px-3 md:px-5",
            "scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thin",
            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
          )}>
            {Object.keys(buttonFeature).map((key, index) => buttonFeature[key].roles.includes(locals.userRole) && (
              <WalletFeatureButton key={index} featureItem={buttonFeature[key]} />
            ))}
          </div>
        </div>
      </div>

      <WalletTabContent />
    </div>
  )
};

export default WalletPage;