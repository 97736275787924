import {
    StarsContainer,
    FullStar,
    HalfStar,
    EmptyStar,
} from './RatingStarsStyle';

const RatingStars = ({ rating, fullRating, size = 1 }) => {
    const fullStar = new Array(Math.floor(rating))?.fill(' ');
    const halfStar = Math.ceil(rating) - Math.floor(rating)
    const emptyStar = new Array(Math.abs(Math.ceil(fullRating) - Math.ceil(rating))).fill(' ');

    return !isNaN(fullRating) && fullRating >= 0 && (
        <StarsContainer>
            {fullStar?.map((_, index) => (
                <FullStar key={index} size={size} />
            ))}
            {(halfStar === 1) && (
                <HalfStar size={size} />
            )}
            {emptyStar?.map((_, index) => (
                <EmptyStar key={index} size={size} />
            ))}
        </StarsContainer>
    )
}

export default RatingStars
