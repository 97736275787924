export const appData = [
    {
        listItem: 'Mobile Apps to manage your account on the go'
    },
    {
        listItem: 'Chat with your teacher and support team'
    },
    {
        listItem: '24/7 access to personalised learning'
    },
    {
        listItem: 'Save time and money, refer & earn'
    },
    {
        listItem: 'Buy a gift card for your family and friends'
    },
];