import { useEffect } from 'react'
import { useTitle } from 'hooks/useTitle'

import CtaFormStep from './_components/cta-form-step'

import { setPageInfo } from 'redux/pageInfo/pageInfo.request'

import { useAppDispatcher, useAppState } from 'hooks/useStore'

import { pagesInfo } from 'utils/pagesInfo'
import { useLocation, useNavigate } from 'react-router-dom'


const FeatureGetYouAccount = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const session = useAppState(state => state.auth.session)

    const [title, setTitle] = useTitle()

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const redirect_url = new URL(window.location.href)

    const from = location.state?.from?.url || pagesInfo.DASHBOARD.pagePath;

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.FEATURE_GET_YOUR_ACCOUNT))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Educators - Access Powerful Teaching Tools",
            description: "Register to access advanced tools designed to enhance your teaching. Simplify your process, engage students, and maximise your educational impact."
        })
    }, [currentPageInfo])

    if (session?.isLoggedIn) {
        if (!!redirect_url?.searchParams?.get("redirect_url")) {
            window.open(redirect_url?.searchParams?.get("redirect_url"), "_self")
        } else {
            navigate(from, { replace: true });
        }
    }

    return (
        <main className='overflow-hidden w-full min-h-screen pt-20 bg-white'>
            <div className='container mx-auto px-5 lg:px-10 space-y-5'>
                <h1 className='font-bodyPri font-bold text-text-900 text-center leading-tight text-xl sm:text-2xl md:text-3xl lg:text-4xl'>
                    Unlock Powerful Tools for <span className='text-secondary-dark'>Educators</span> & Small <span className='text-secondary-dark'>Businesses</span>
                </h1>
                <p className='font-bodyPri font-medium text-text-600 text-base md:text-md text-center'>Enhance Your Teaching with Advanced Features</p>
            </div>
            <section className={"w-full px-2 py-10 md:p-10"}>
                <CtaFormStep />
            </section>
        </main>
    )
}

export default FeatureGetYouAccount