import { baseUnsplashApiInstance } from "apiInstances/axios.apiInstance";
// import { UNSPLASH_ACCESS_KEY } from "const/default.const";
// import { createApi } from "unsplash-js";

// const api = createApi({
//     accessKey: UNSPLASH_ACCESS_KEY
// })

class OtherService {
    getUnsplashThumbnailList = async (requestData) => {
        const response = await baseUnsplashApiInstance().search.getPhotos(requestData)

        return response;
    }

    createDownloadUnsplashThumbnail = async ({ body }) => {
        const response = await baseUnsplashApiInstance().photos.trackDownload(body)

        return response;
    }
}

export default new OtherService()