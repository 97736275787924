import { useEffect } from "react";

import { FaInfoCircle } from 'react-icons/fa';

import ComponentLoader from "components/loader/ComponentLoader";
import ToolTipView from "components/tooltipView";
import { ToggleWithText } from "components/common-components/Toggle";

import { setGetOtherPref, studentOtherPreferenceToolTip, tutorOtherPreferenceToolTip } from "pages/auth/preferences/data";

import {
  getUserOtherPrefDetailByUserId,
  updateUserOtherPrefDetailByUserId
} from "redux/user/user.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearUserOtherPref } from "redux/user/user.slice";
import { userRoles } from "redux/auth/auth.const";

type tOtherPref = {
  key: string;
  label: string;
  description: string;
  value: boolean;
}

const OtherPreferenceHeader = () => {
  const { locals } = useAppState((s) => s.local)

  return (
    <div className={"py-3 space-y-3"}>
      <div className="flex justify-center w-full">
        <div className="flex item-start justify-center gap-1">
          <span className="font-subHeading font-medium text-2xl text-text--800">
            {"Other Preferences"}
          </span>
          <ToolTipView content={(locals.userRole === userRoles.TUTOR?.value) ? tutorOtherPreferenceToolTip : studentOtherPreferenceToolTip}>
            <button className={"cursor-pointer"}>
              <FaInfoCircle className={"inline text-md text-text-500 hover:text-text-700"} />
            </button>
          </ToolTipView>
        </div>
      </div>
      <div className="h-0.5 bg-gray-300 w-full"></div>
    </div>
  );
}

export default function OtherPreferencesSettings() {
  const { user, userOtherPref } = useAppState((s) => s.user)

  const dispatcher = useAppDispatcher();

  useEffect(() => {
    dispatcher(getUserOtherPrefDetailByUserId(user?.user?.userId))

    return () => {
      dispatcher(setClearUserOtherPref())
    }
  }, [])

  const updateOtherStatus = (key: string, value: boolean) => {
    const otherPref = { [key]: value }
    dispatcher(updateUserOtherPrefDetailByUserId(user?.user?.userId, otherPref))
  }

  return (
    <div className="">
      <OtherPreferenceHeader />
      <div className={"px-4 py-10 space-y-2 divide-y divide-blue-50"}>
        {userOtherPref?.isLoading &&
          <ComponentLoader isLoading={userOtherPref?.isLoading} className={"min-h-[16rem]"} />
        }
        {(!userOtherPref?.isLoading && userOtherPref?.userOtherPref) &&
          setGetOtherPref(userOtherPref?.userOtherPref).map((other: tOtherPref, index: number) => (
            <ToggleWithText
              key={index}
              description={other.description}
              onChange={(isToggle) => updateOtherStatus(other.key, isToggle)}
              title={other.label}
              value={other.value}
            />
          ))}
        {(!userOtherPref?.isLoading && userOtherPref?.errorMsg) &&
          <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
            {userOtherPref?.errorMsg}
          </div>
        }
      </div>
    </div>
  );
}
