import tw, { styled } from "twin.macro";

export const StyledMakePayment = styled.div`
  ${tw`absolute h-screen w-screen flex flex-col items-center justify-center bg-white rounded-md shadow-md px-5 z-30 md:(width[350px] height[fit-content]) top[50%] left[50%] transform[translate(-50%, -50%)] py-5 `}
`;

export const StyledMakePaymentHeader = styled.h3`
  ${tw`font-semibold`}
`;

export const StyledMakePaymentButton = styled.div`
  ${tw`mt-2.5 w-full`}
`;

export const StyledMakePaymentError = styled.p`
  ${tw`text-secondary-main text-sm font-semibold pl-1`}
`;
