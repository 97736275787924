import { useState } from 'react';
import { cn } from "utils/cn.utils";

import { FiEdit } from 'react-icons/fi';
import { FaInfoCircle } from 'react-icons/fa';

import { profileEditBtn } from 'pages/auth/profile/data';

import SaveAndCancelBtn from 'pages/auth/profile/commonComponents/SaveAndCancelBtn';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { updateStudentUserDetailByUserId } from 'redux/student/student.request';
import { updateTutorUserDetailByUserId } from 'redux/tutor/tutor.request';
import { userRoles } from 'redux/auth/auth.const';

const MAX_CHARACTER_LENGTH = 60

const ElevatorPitchContainer = ({ editBtnConst, setEditBtnConst }) => {
    const { locals } = useAppState((state) => state.local);
    const { user } = useAppState((state) => state.user);
    const { studentUserDetail } = useAppState((state) => state.student);
    const { tutorUserDetail } = useAppState((state) => state.tutor);

    const dispatcher = useAppDispatcher();

    const [inputElevatorPitch, setInputElevatorPitch] = useState('');

    const onHandleSave = (key, value) => {
        if (inputElevatorPitch.length < 10 || inputElevatorPitch.length > 60) {
            alert("Please enter between 10 to 60 characters")
            return;
        }

        if (locals.userRole === userRoles.STUDENT.value) {
            dispatcher(updateStudentUserDetailByUserId(user?.user?.userId, { [key]: value }))
        } else if (locals.userRole === userRoles.TUTOR.value) {
            dispatcher(updateTutorUserDetailByUserId(user?.user?.userId, { [key]: value }))
        }
        setEditBtnConst(null)
    }

    return (
        <div className="w-full flex flex-col justify-start items-start gap-2">
            <div className={"flex items-center justify-start gap-2"}>
                <div className={"relative has-tooltip cursor-pointer flex justify-end"}>
                    <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                    <span
                        className={cn(
                            "w-56 px-2 py-0.5 font-bodyPri font-normal text-xs rounded shadow-lg bg-text-900 text-text-50 tooltip -top-20 left-1",
                            "flex flex-col items-start justify-start overflow-hidden"
                        )}
                    >
                        {"For your public profile, have a snappy one-line for Me in a sentence field. It's like an elevator pitch with a few words. A few words to define you as an educator."}
                    </span>
                </div>
                <span className="text-xl text-text-900 font-medium font-bodyPri tracking-wide whitespace-nowrap">
                    {"Me in one sentence:"}
                </span>
                {!editBtnConst &&
                    <FiEdit
                        className={"text-lg text-text-500 cursor-pointer"}
                        onClick={() => {
                            if (locals.userRole === userRoles.STUDENT.value) {
                                setInputElevatorPitch(studentUserDetail?.studentUserDetail?.elevatorPitch || "")
                            } else if (locals.userRole === userRoles.TUTOR.value) {
                                setInputElevatorPitch(tutorUserDetail?.tutorUserDetail?.elevatorPitch || "")
                            }
                            setEditBtnConst(profileEditBtn.elevatorPitch.value)
                        }}
                    />
                }
            </div>
            {editBtnConst !== profileEditBtn.elevatorPitch.value &&
                <div className={"w-full px-5"}>
                    {locals.userRole === userRoles.STUDENT.value &&
                        <span className='font-bodyPri font-normal tracking-wide text-text-800 text-base'>
                            {studentUserDetail?.studentUserDetail?.elevatorPitch}
                        </span>
                    }
                    {locals.userRole === userRoles.TUTOR.value &&
                        <span className='font-bodyPri font-normal tracking-wide text-text-800 text-base'>
                            {tutorUserDetail?.tutorUserDetail?.elevatorPitch}
                        </span>
                    }
                </div>
            }
            {editBtnConst === profileEditBtn.elevatorPitch.value &&
                <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                    <div className={"w-full flex flex-col justify-center items-start"}>
                        <input
                            className="w-full px-2 py-1 border-secondary-dark border-dashed border-2 focus:outline-none font-bodyPri font-normal text-lg text-text-600 tracking-wide"
                            minLength={10}
                            maxLength={60}
                            autoFocus={true}
                            value={inputElevatorPitch}
                            onChange={(event) => setInputElevatorPitch(event.target.value)}
                        />
                        <div className={"w-full flex justify-between"}>
                            <span className={"font-bodyPri font-normal tracking-wide text-red-500 text-xs"}>
                                {"Please enter between 10 to 60 characters"}
                            </span>
                            <span className="flex justify-end font-bodyPri font-normal text-text-800 text-xs">
                                {inputElevatorPitch.length + "/" + MAX_CHARACTER_LENGTH}
                            </span>
                        </div>
                    </div>
                    <SaveAndCancelBtn
                        onCancel={() => setEditBtnConst(null)}
                        onSave={() => onHandleSave(editBtnConst, inputElevatorPitch)}
                    />
                </div>
            }
        </div>
    )
}

export default ElevatorPitchContainer