import tw from 'twin.macro';
import { PageContent, ScreenCard } from 'components/generalComponent/StyledComponent';

export const PageContentStyle = tw(PageContent)
    `flex justify-center items-center min-h-screen my-10 md:my-0`;

export const Container = tw(ScreenCard)``;

export const UnauthorizedCard = tw.div`
    p-10 min-h-full
`;

export const UnauthorizedCardHeader = tw.div`
    flex items-start justify-start gap-5
`;

export const UnauthorizedContentContainer = tw.div`
    flex flex-col items-start justify-start gap-5
`;

export const UnAuthorizedContent = tw.span`
    flex flex-col gap-2
`;

export const Heading = tw.h1`
    text-3xl font-bodyPri font-bold text-text-900
`;

export const SubHeading = tw.span`
    font-subHeading font-normal font-bodyPri text-text-500 text-lg w-full flex flex-col justify-center items-center gap-1
    lg:flex-row
`;

export const HomeButtons = tw.div`
    w-full flex justify-between items-center
`;

export const RoleSwitchButton = tw.button`
    w-28 py-1.5 flex justify-center items-center border border-primary-dark rounded-full text-primary-dark font-normal font-buttons hover:bg-primary-dark hover:text-text-50 cursor-pointer
`;

export const GoToDashboardButton = tw.button`
    text-primary-dark font-bodyPri font-medium tracking-wide cursor-pointer
`;