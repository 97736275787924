import { useEffect } from "react";
import { cn } from "utils/cn.utils";
import { useNavigate, useParams } from "react-router-dom";

import { FiMessageSquare } from "react-icons/fi";
import { BiTimeFive } from "react-icons/bi";
import { BiCalendar } from "react-icons/bi";
import { IoIosStats } from 'react-icons/io';
import { FiEdit } from "react-icons/fi";
import { FaLanguage } from "react-icons/fa";
import { IoMdPeople } from "react-icons/io";
import { MdGroups } from "react-icons/md";
import { MdOutlineVideoCall } from "react-icons/md";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";

import { OptionSelector } from "components/common-components/Select";

import { editBtnConst, sessionDruation } from 'pages/auth/editCourse/data';

import EditAndDoneButton from "pages/auth/editCourse/Components/EditAndDoneBtn";
import VideoSelector from "pages/auth/editCourse/Components/VideoSelector";
import { AffixedInlineTextEditor } from "pages/auth/editCourse/Components/InlineTextEditor";

import { getMasterLanguageList, getMasterProficiencyList } from "redux/master/master.request";
import { updateOfferingByCourseId } from "redux/offering/offering.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setOfferingCourse } from "redux/offering/offering.slice";
import { setClearLanguageList, setClearProfList } from "redux/master/master.slice";
import { setModal } from "redux/local/local.slice";
import { courseType } from "redux/course/course.const";
import { modalConst } from "redux/local/local.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const EditAndDoneBtn = ({ editBtn, editBtnValue, setEditBtn }) => {
    const { languageList, profList } = useAppState((s) => s.master)
    const { offeringCourse } = useAppState((state) => state.offering)

    const dispatcher = useAppDispatcher()
    const { courseId } = useParams()

    const onSave = () => {
        const body = {
            courseType: offeringCourse?.offeringCourse?.courseType
        }
        if (editBtnValue === editBtnConst.level.value) {
            body["masterProficiencyId"] = profList?.profList?.filter((prof) => (
                prof?.proficiency === offeringCourse?.offeringCourse?.proficiency
            ))[0]?.masterProficiencyId
            dispatcher(updateOfferingByCourseId(Number(courseId), body))
            setEditBtn(null)
            return;
        }
        if (editBtnValue === editBtnConst.language.value) {
            body["masterLanguageId"] = languageList?.languageList?.filter((language) => (
                language?.language === offeringCourse?.offeringCourse?.language
            ))[0]?.masterLanguageId
            dispatcher(updateOfferingByCourseId(Number(courseId), body))
            setEditBtn(null)
            return;
        }
        dispatcher(updateOfferingByCourseId(Number(courseId),
            {
                courseType: offeringCourse?.offeringCourse?.courseType,
                [editBtnValue]: offeringCourse?.offeringCourse[editBtnValue]
            }
        ))
        setEditBtn(null)
    }

    const onCancel = () => {
        setEditBtn(null)
    }

    return (
        <div className={"text-lg cursor-pointer"}>
            {!editBtn &&
                <FiEdit className={"text-text-500 text-lg"} onClick={() => setEditBtn(editBtnConst[editBtnValue].value)} />
            }
            {(editBtn === editBtnConst[editBtnValue].value) &&
                <EditAndDoneButton onSave={onSave} onCancel={onCancel} />
            }
        </div>
    )
}

const HorizontalStickyCard = ({ editBtn, setEditBtn }) => {
    const { offeringCourse } = useAppState((s) => s.offering)
    const { languageList, profList } = useAppState((s) => s.master)
    const { modal } = useAppState((s) => s.local)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    useEffect(() => {
        dispatcher(getMasterProficiencyList())
        dispatcher(getMasterLanguageList())

        return () => {
            dispatcher(setClearProfList())
            dispatcher(setClearLanguageList())
        }
    }, [])

    const discountedPrice = (offeringCourse?.offeringCourse?.price * ((100 - offeringCourse?.offeringCourse?.discountPct) / 100))

    const handleOnEditBtn = (editBtnValue) => {
        setEditBtn(editBtnValue)
        // setEditBtn({
        //     ...editBtnConst,
        //     [editBtnValue]: {
        //         ...editBtn[editBtnValue],
        //         isEnable: !editBtn[editBtnValue].isEnable
        //     }
        // })
    }

    const handleAvailModal = () => {
        if (offeringCourse?.offeringCourse?.courseType === courseType.ONE_ON_ONE.value) {
            navigate("/settings/preferences/availability")
        } else if (offeringCourse?.offeringCourse?.courseType === courseType.GROUP.value) {
            dispatcher(setModal({
                ...modal,
                [modalConst.COURSE_SCHEDULE_EDIT_MODAL.stateKey]: true
            }))
        }
    }

    return (
        <div className={"w-full p-5 bg-white space-y-3"}>
            <div className={"flex justify-center items-center"}>
                <VideoSelector />
            </div>
            <div className={"p-3 z-[1000] space-y-3"}>
                <div className={"border border-secondary-main border-dashed"}>
                    <AffixedInlineTextEditor
                        prefix={'USD'}
                        suffix={offeringCourse?.offeringCourse?.courseType === courseType?.GROUP?.value ? "per session" : "per hour"}
                        textClassName="text-3xl font-medium font-bodyPri tracking-wide"
                        discountPercentage={offeringCourse?.offeringCourse?.discountPct}
                        value={offeringCourse?.offeringCourse?.price}
                        discountValue={discountedPrice}
                        editBtn={editBtn}
                        setEditBtn={setEditBtn}
                    />
                </div>
                <div className='flex items-center w-full justify-evenly'>
                    <span className={cn(
                        'w-2/3 py-1 flex justify-center items-center rounded-full font-normal font-buttons cursor-pointer',
                        'border border-secondary-light text-secondary-light',
                        'hover:bg-secondary-light hover:text-text-50'
                    )}>
                        {"Book Now"}
                    </span>
                    <span className='text-3xl text-text-300 hover:text-primary-light cursor-pointer'>
                        <FiMessageSquare />
                    </span>
                </div>

                <div className='flex flex-col space-y-2'>

                    {/* Course Type */}
                    {offeringCourse?.offeringCourse?.courseType === courseType.ONE_ON_ONE.value &&
                        <div className='flex justify-start gap-3'>
                            <IoMdPeople className="text-gray-700 text-xl" />
                            <span className="text-text-700 font-normal font-bodyPri">
                                {"Class Type:"}
                            </span>
                            <span className='text-text-900 font-medium font-bodyPri'>
                                {courseType.ONE_ON_ONE.label}
                            </span>
                        </div>
                    }
                    {offeringCourse?.offeringCourse?.courseType === courseType.GROUP.value &&
                        <div className='flex justify-start gap-3'>
                            <MdGroups className="text-gray-700 text-xl" />
                            <span className="text-text-700 font-normal font-bodyPri">
                                {"Course Type:"}
                            </span>
                            <span className='text-text-900 font-medium font-bodyPri'>
                                {courseType.GROUP.label}
                            </span>
                        </div>
                    }

                    {/* Level */}
                    <div className={"flex items-center justify-between"}>
                        <div className="flex items-center justify-start gap-2">
                            <IoIosStats className="text-gray-700 text-xl" />
                            <span className="font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap">
                                {"Level:"}
                            </span>
                            {(editBtn !== editBtnConst.level.value) &&
                                <span className="font-bodyPri font-medium text-base text-text-900 tracking-wide whitespace-nowrap">
                                    {offeringCourse?.offeringCourse?.proficiency}
                                </span>
                            }
                            {(editBtn === editBtnConst.level.value) &&
                                <div className={"w-full inline-flex items-center"}>
                                    <OptionSelector
                                        options={profList?.profList?.map((proficiency) => ({
                                            label: proficiency?.proficiency,
                                            value: proficiency?.proficiency
                                        }))}
                                        value={offeringCourse?.offeringCourse?.proficiency}
                                        onChange={(option) => dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, proficiency: option.value }))}
                                    />
                                </div>
                            }
                        </div>
                        <EditAndDoneBtn
                            editBtn={editBtn}
                            editBtnValue={editBtnConst.level.value}
                            handleOnEditBtn={handleOnEditBtn}
                            setEditBtn={setEditBtn}
                        />
                    </div>

                    {/* Language */}
                    <div className={"flex items-center justify-between"}>
                        <div className="flex items-center justify-start gap-2">
                            <FaLanguage className="text-gray-700 text-xl" />
                            <span className="font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap">
                                {"Language:"}
                            </span>
                            {(editBtn !== editBtnConst.language.value) &&
                                <span className="font-bodyPri font-medium text-base text-text-900 tracking-wide whitespace-nowrap">
                                    {offeringCourse?.offeringCourse?.language}
                                </span>
                            }
                            {(editBtn === editBtnConst.language.value) &&
                                <div className={"w-full inline-flex items-center"}>
                                    <OptionSelector
                                        options={languageList?.languageList?.map((language) => ({
                                            label: language?.language,
                                            value: language?.language
                                        }))}
                                        value={offeringCourse?.offeringCourse?.language}
                                        onChange={(option) => dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, language: option.value }))}
                                    />
                                </div>
                            }
                        </div>
                        <EditAndDoneBtn
                            editBtn={editBtn}
                            editBtnValue={editBtnConst.language.value}
                            handleOnEditBtn={handleOnEditBtn}
                            setEditBtn={setEditBtn}
                        />
                    </div>

                    {/* Session Duration */}
                    {offeringCourse?.offeringCourse?.courseType === courseType.ONE_ON_ONE.value &&
                        <div className="flex justify-start items-center gap-2">
                            <BiTimeFive className="text-gray-700 text-xl" />
                            <span className="font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap">
                                {"Session Duration:"}
                            </span>
                            <span className="font-bodyPri font-medium text-base text-text-900 tracking-wide whitespace-nowrap">
                                {"upto 2 hours"}
                            </span>
                        </div>
                    }
                    {offeringCourse?.offeringCourse?.courseType === courseType.GROUP.value &&
                        <div className={"flex items-center justify-between"}>
                            <div className="flex justify-start items-center gap-2">
                                <BiTimeFive className="text-gray-700 text-xl" />
                                <span className="font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap">
                                    {"Session Duration:"}
                                </span>
                                {(editBtn !== editBtnConst.sessionDuration.value) &&
                                    <span className="font-bodyPri font-medium text-base text-text-900 tracking-wide whitespace-nowrap">
                                        {offeringCourse?.offeringCourse?.sessionDuration + " min"}
                                    </span>
                                }
                                {(editBtn === editBtnConst.sessionDuration.value) &&
                                    <div className={"w-full inline-flex items-center"}>
                                        <OptionSelector
                                            options={sessionDruation}
                                            value={offeringCourse?.offeringCourse?.sessionDuration}
                                            onChange={(option) => dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, sessionDuration: parseInt(option.value) }))}
                                        />
                                    </div>
                                }
                            </div>
                            <EditAndDoneBtn
                                editBtn={editBtn}
                                editBtnValue={editBtnConst.sessionDuration.value}
                                handleOnEditBtn={handleOnEditBtn}
                                setEditBtn={setEditBtn}
                            />
                        </div>
                    }

                    {/* Session Count */}
                    {offeringCourse?.offeringCourse?.courseType === courseType.GROUP.value &&
                        <div className={"flex items-center justify-between"}>
                            <div className="flex justify-start items-center gap-2">
                                <BiTimeFive className="text-gray-700 text-xl" />
                                <span className="font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap">
                                    {"Session Count:"}
                                </span>
                                {(editBtn !== editBtnConst.sessionCount.value) &&
                                    <span className="font-bodyPri font-medium text-base text-text-900 tracking-wide whitespace-nowrap">
                                        {offeringCourse?.offeringCourse?.noOfSessions}
                                    </span>
                                }
                                {(editBtn === editBtnConst.sessionCount.value) &&
                                    <div className={"w-full inline-flex items-center"}>
                                        <input
                                            className={cn(
                                                "w-full input-number-spin-none border-2 border-text-500 px-1 py-0.5 rounded-md focus:outline-none",
                                                "font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap bg-transparent"
                                            )}
                                            type={"number"}
                                            autoFocus={true}
                                            value={offeringCourse?.offeringCourse?.noOfSessions}
                                            onChange={(event) => dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, noOfSessions: event.target.value > 0 ? parseInt(event.target.value) : 1 }))}
                                        />
                                    </div>
                                }
                            </div>
                            {/* <EditAndDoneBtn
                                editBtn={editBtn}
                                editBtnValue={editBtnConst.sessionCount.value}
                                handleOnEditBtn={handleOnEditBtn}
                            setEditBtn={setEditBtn}
                            /> */}
                        </div>
                    }

                    {/* Total Duration */}
                    {offeringCourse?.offeringCourse?.courseType === courseType.GROUP.value &&
                        <div className="flex justify-start gap-3">
                            <BiTimeFive className="text-gray-700 text-xl" />
                            <span className="font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap">
                                {"Total Duration:"}
                            </span>
                            <span className="font-bodyPri font-medium text-base text-text-900 tracking-wide whitespace-nowrap">
                                {offeringCourse?.offeringCourse?.noOfSessions * offeringCourse?.offeringCourse?.sessionDuration < 60
                                    ? offeringCourse?.offeringCourse?.noOfSessions * offeringCourse?.offeringCourse?.sessionDuration + " min"
                                    : Math.ceil(offeringCourse?.offeringCourse?.noOfSessions * offeringCourse?.offeringCourse?.sessionDuration / 60) + " hrs"
                                }
                            </span>
                        </div>
                    }

                    {/* Student Capacity */}
                    {offeringCourse?.offeringCourse?.courseType === courseType.GROUP.value &&
                        <div className={"flex items-center justify-between"}>
                            <div className="flex justify-start items-center gap-2">
                                <MdGroups className="text-gray-700 text-xl" />
                                <span className="font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap">
                                    {"Participants:"}
                                </span>
                                {(editBtn !== editBtnConst.studentCapacity.value) &&
                                    <span className="font-bodyPri font-medium text-base text-text-900 tracking-wide whitespace-nowrap">
                                        {offeringCourse?.offeringCourse?.studentCapacity + " max"}
                                    </span>
                                }
                                {(editBtn === editBtnConst.studentCapacity.value) &&
                                    <div className={"w-full inline-flex items-center"}>
                                        <input
                                            className={cn(
                                                "w-full input-number-spin-none border-2 border-text-500 px-1 py-0.5 rounded-md focus:outline-none",
                                                "font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"
                                            )}
                                            type={"number"}
                                            autoFocus={true}
                                            value={offeringCourse?.offeringCourse?.studentCapacity}
                                            onChange={(event) => dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, studentCapacity: event.target.value > 0 ? parseInt(event.target.value) : 1 }))}
                                        />
                                    </div>
                                }
                            </div>
                            <EditAndDoneBtn
                                editBtn={editBtn}
                                editBtnValue={editBtnConst.studentCapacity.value}
                                handleOnEditBtn={handleOnEditBtn}
                                setEditBtn={setEditBtn}
                            />
                        </div>
                    }

                    {/* Total Cost */}
                    {offeringCourse?.offeringCourse?.courseType === courseType.GROUP.value &&
                        <div className="flex justify-start gap-2">
                            <MdOutlineAccountBalanceWallet className="text-gray-700 text-xl" />
                            <span className="font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap">
                                {"Total Cost:"}
                            </span>
                            <span className="font-bodyPri font-medium text-base text-text-900 tracking-wide whitespace-nowrap">
                                {"USD " + parseFloat("" + ((discountedPrice) * offeringCourse?.offeringCourse?.noOfSessions) / 100).toFixed(2)}
                            </span>
                        </div>
                    }

                    {/* Course Availability */}
                    {offeringCourse?.offeringCourse?.courseType === courseType.ONE_ON_ONE.value &&
                        <div className="flex justify-start gap-3">
                            <BiCalendar className="text-gray-500 text-lg" />
                            <span className="font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap">
                                {"Availability"}
                            </span>
                            <span onClick={handleAvailModal} className={"font-bodyPri font-medium text-base text-primary-main tracking-wide whitespace-nowrap underline cursor-pointer hover:text-secondary-main"}>
                                {"See Calendar"}
                            </span>
                        </div>
                    }
                    {offeringCourse?.offeringCourse?.courseType === courseType.GROUP.value &&
                        <div className="flex justify-start gap-3">
                            <BiCalendar className="text-gray-500 text-lg" />
                            <span className="font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap">
                                {"Class Time:"}
                            </span>
                            <span onClick={handleAvailModal} className={"font-bodyPri font-medium text-base text-primary-main tracking-wide whitespace-nowrap underline cursor-pointer hover:text-secondary-main"}>
                                {"See Schedule"}
                            </span>
                        </div>
                    }

                    <div className="flex justify-start gap-3">
                        <MdOutlineVideoCall className="text-gray-700 text-xl" />
                        <span className="font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap">
                            {"Trial Session:"}
                        </span>
                        <div className="flex gap-1 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap">
                            <span>
                                {"USD " + parseFloat("" + offeringCourse?.offeringCourse?.tutor?.trialCourse?.price / 100).toFixed(2)}
                            </span>
                            <span>
                                ({offeringCourse?.offeringCourse?.tutor?.trialCourse?.sessionDuration + ' min'})
                            </span>
                        </div>
                    </div>

                    <hr className="w-full bg-gray-900" />

                    <div className='flex flex-col gap-2 pt-2'>
                        <span className={cn(
                            'w-full py-0.5 flex justify-center items-center font-buttons cursor-pointer',
                            'border border-text-300 rounded-full text-gray-300 font-normal',
                            'hover:border hover:border-secondary-light hover:text-gray-400'
                        )}>
                            {"Book a Trial"}
                        </span>
                        <div className='flex flex-col justify-center items-center p-2 bg-yellow-300 font-bold'>
                            <span>{"Enroll Now"}</span>
                            {offeringCourse?.offeringCourse?.courseType === "group" && <span>{"Starts on " + dayjs(offeringCourse?.offeringCourse?.startDate).tz(timeZone).format("DD MMMM, YYYY")}</span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HorizontalStickyCard;