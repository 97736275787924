export const sampleCourses = [
  {
    id: "1",
    classTitle: "Introduction to Discrete Mathematics",
    price: 10,
    link: "https://www.google.com",
    classCoverImageUrl:
      "https://firebasestorage.googleapis.com/v0/b/edulyte-7223b.appspot.com/o/sonya-pix-NHKyeVXY1aY-unsplash.jpg?alt=media&token=194f72de-5ecb-4b3d-ae8d-d2890a050999",
  },
  {
    id: "2",
    classTitle: "Sample Course",
    price: 20,
    link: "https://www.google.com",
    classCoverImageUrl:
      "https://firebasestorage.googleapis.com/v0/b/edulyte-7223b.appspot.com/o/sonya-pix-NHKyeVXY1aY-unsplash.jpg?alt=media&token=194f72de-5ecb-4b3d-ae8d-d2890a050999",
  },
];
