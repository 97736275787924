import { cn } from "utils/cn.utils";
import * as React from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useAppState } from "hooks/useStore";
import { GIFT_CARD_IMAGES } from "../store/mock-data";

const DOWNLOAD_HOST = "http://localhost:7000/api/generate-gift-card/"
export const PaymentSummary: React.FC = () => {
  const paymentState = useAppState((s) => s.buyGiftCard.paymentSummary);
  const recipientState = useAppState(
    (s) => s.buyGiftCard.recipentSelection.selectedRecipent
  );
  const cardStatus = useAppState((s) => s.buyGiftCard.cardSelection.selectedCard);
  const activeImage = useAppState(s => s.buyGiftCard.selectedImageIdx);

  const getDownloadURL = () => {
    let url = `${DOWNLOAD_HOST}?name=${encodeURIComponent(cardStatus?.name!)}
      &sender_name=${encodeURIComponent(recipientState.name!)}
      &email=${encodeURIComponent(recipientState.recipientEmail)}
      &design_url=${encodeURIComponent(GIFT_CARD_IMAGES[activeImage])}`
    if (recipientState.type === "someone_else") {
      url = `${url}&recipient_name=${encodeURIComponent(recipientState.recipientName)}
        &message=${encodeURIComponent(recipientState.message)}`
    }
    return url
  }

  return (
    <div className="pb-8">
      <div className="flex flex-col px-5 py-6 mx-auto space-y-4 bg-white shadow-lg rounded-xl">
        <p className="text-lg font-bold tracking-wider text-center text-slate-700">
          Order Recieved
        </p>
        <div className="flex items-center justify-center py-4">
          {paymentState.isPaymentSuccess ? (
            <FaCheckCircle size={50} className="text-lime-500" />
          ) : (
            <FaTimesCircle size={50} className="text-red-500" />
          )}
        </div>
        <div className="text-center text-slate-600">
          {paymentState.isPaymentSuccess ? (
            <>
              <p>
                Thank you for you payment of ${paymentState.amountPaid} has been
                taken, We've just sent an email to{" "}
                {recipientState.recipientEmail}
              </p>

              <p className="pt-7">
                Thank you so much for your support, we hope you enjoy your gift
                card
              </p>
            </>
          ) : (
            <>
              <p>
                Sorry, your payment of ${paymentState.amountPaid} has failed!
              </p>
              <p>Reason: {paymentState.errorMessage}</p>
            </>
          )}
        </div>
        <div className="w-full px-8 py-4">
          <a
            href={getDownloadURL()}
            className={cn(
              "text-base font-medium whitespace-nowrap",
              "rounded-md shadow-sm",
              "px-3 py-2 w-full",
              "focus:outline-none focus:ring-2 focus:ring-offset-2 ",
              "sm:flex-shrink-0 sm:inline-flex sm:items-center sm:justify-center",
              "text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 border border-transparent ",
            )}>
            Download
          </a>
        </div>
      </div>

      <div className="flex flex-col px-5 py-6 mx-auto mt-5 space-y-4 bg-white shadow-lg rounded-xl">
        <div className="">
          <p className="max-w-2xl mt-1 text-lg text-center text-gray-500">
            {cardStatus?.name}
          </p>
        </div>
        <div className="flex flex-col my-4 space-y-3">
          <div className="flex w-full space-x-4">
            <p className="pr-4 text-gray-500">Your Name:</p>
            <p>{recipientState.name}</p>
          </div>
          {recipientState.type === "someone_else" && (
            <>
              <div className="flex w-full space-x-4">
                <p className="pr-4 text-gray-500">Recipient Name:</p>
                <p>{recipientState.recipientName}</p>
              </div>
              <div className="flex w-full space-x-4">
                <p className="pr-4 text-gray-500">Message:</p>
                <p>
                  {recipientState.message
                    ? recipientState.message
                    : "No message"}
                </p>
              </div>
            </>
          )}
          <div className="flex w-full space-x-4">
            <p className="pr-4 text-gray-500">Recipient Email:</p>
            <p>{recipientState.recipientEmail}</p>
          </div>
          {/* 
          <div className="bg-gray-100 h-0.5 w-full mx-auto" />

          <div className="flex justify-between w-full space-x-4">
            <p className="pr-4 text-gray-500">Delivery Charges</p>
            <p>$0</p>
          </div>

          <div className="bg-gray-100 h-0.5 w-full mx-auto" />

          <div className="flex justify-between w-full space-x-4">
            <p className="pr-4 text-gray-500">Total</p>
            <p>${cardStatus?.price}</p>
          </div> */}
        </div>
      </div>
    </div >
  );
};
