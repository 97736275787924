import { useNavigate } from 'react-router-dom';

import { BsFillCheckCircleFill } from 'react-icons/bs';

import { Container } from "pages/auth/bookingConfirmation/BookingConfirmationPageStyle";

import { useAppState } from 'hooks/useStore';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';

import { pagesInfo } from 'utils/pagesInfo';
import { currencyRateConversion } from 'utils/generators.utils';


const ConfirmedBookingConfirmation = () => {
  const { locals } = useAppState(s => s.local)
  const { bookingDetailConfirmation } = useAppState((state) => state.booking)

  const navigate = useNavigate()

  const pageRedirect = () => {
    navigate(`${pagesInfo.USER_FINANCE.pagePath}${pagesInfo.BOOKINGS.pagePath}?actionBookingId=${bookingDetailConfirmation?.data?.result?.id}`, { replace: true })
  }

  return bookingDetailConfirmation?.data?.result ? (
    <Container className={"!shadow-all-md"}>
      <BsFillCheckCircleFill className={"text-8xl text-green-500"} />
      <div className={"flex items-center justify-center gap-2 font-bodyPri font-semibold text-2xl text-primary-dark"}>
        <span>{bookingDetailConfirmation?.data?.result?.bookingPrice?.masterCurrency?.code}</span>
        <span>{parseFloat(bookingDetailConfirmation?.data?.result?.bookingPrice?.totalAmount / 100).toFixed(2)}</span>
      </div>
      {([masterCurrencyCodeEnum.USD.value]?.includes(bookingDetailConfirmation?.data?.result?.bookingPrice?.masterCurrency?.code) && (locals.currencyCode !== masterCurrencyCodeEnum.USD.value)) &&
        <div className={"flex items-center justify-center gap-2 font-bodyPri font-medium text-lg text-text-800"}>
          <span>{currencyRateConversion(locals?.currencyCode, bookingDetailConfirmation?.data?.result?.bookingPrice?.totalAmount)}</span>
        </div>
      }
      <div className={"flex flex-col items-center gap-2"}>
        <span className={"font-bodyPri font-medium text-lg text-text-800"}>
          {"Congratulations"}
        </span>
        <span className={"font-bodyPri font-medium text-md text-text-800"}>
          {"Transaction was successful"}
        </span>
        {/* <span className={"font-bodyPri font-semibold text-lg text-green-500 tracking-wider"}>
          {"Successfully"}
        </span> */}
      </div>
      <div className={"flex items-center justify-center gap-2"}>
        <span className={"font-bodyPri font-normal text-base text-800"}>
          {"Transaction ID:"}
        </span>
        <span className={"font-bodyPri font-semibold text-md text-primary-dark"}>
          {bookingDetailConfirmation?.data?.result?.bookingPrice?.walletTransaction?.virtualTxnId}
        </span>
      </div>
      <div className={"flex items-center justify-center gap-2"}>
        <span className={"font-bodyPri font-normal text-base text-800"}>
          {"Booking ID:"}
        </span>
        <span className={"font-bodyPri font-semibold text-md text-text-900"}>
          {bookingDetailConfirmation?.data?.result?.id}
        </span>
      </div>
      <div onClick={pageRedirect}>
        <span className={"px-5 py-1.5 rounded-full cursor-pointer font-buttons font-medium text-md text-secondary-main border-2 border-secondary-main hover:text-text-50 hover:bg-secondary-main"}>
          {"View Booking"}
        </span>
      </div>
    </Container>
  ) : null
}

export default ConfirmedBookingConfirmation