import { useEffect } from 'react';
import { cn } from "utils/cn.utils";
import { Link, useNavigate } from 'react-router-dom';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { pagesInfo } from 'utils/pagesInfo';
import { setClearModal, setClearPages } from 'redux/local/local.slice';

import { PageContentStyle } from 'pages/global/thankYou/ThankYouPageStyle';

import { useTitle } from 'hooks/useTitle';

const ThankYouPage = () => {
    const { currentPageInfo } = useAppState(s => s.pageInfo)
    const { pages } = useAppState(s => s.local)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.THANK_YOU))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Thank You from Edulyte Team"
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        dispatcher(setClearModal())
        return () => {
            dispatcher(setClearPages())
        }
    }, [])

    if (!pages.isThankYou) {
        navigate(-1)
    }

    return pages.isThankYou && (
        <PageContentStyle>
            <div className={"w-screen h-72 pb-20 flex items-center justify-center bg-primary-main"}>
                <span className={"font-tagLine font-bold text-5xl sm:text-6xl md:text-7xl lg:text-8xl text-text-50"}>
                    {"THANK YOU"}
                </span>
            </div>
            <div className={"w-screen relative px-10 flex items-center justify-center bg-red-200"}>
                <div className={cn(
                    "max-w-sm sm:max-w-xl md:max-w-3xl lg:max-w-4xl w-full h-40 bg-white px-10 py-5",
                    "flex items-center justify-center absolute shadow-xl"
                )}>
                    <span className={"font-bodyPri font-normal text-base text-text-900"}>
                        {"Thank you for visiting us, please contact us: "}
                        <a href={"mailto:info@edulyte.com"}
                            className={"text-primary-main"}
                        >
                            {"info@edulyte.com"}
                        </a>
                    </span>
                </div>
            </div>
            <div className={"flex justify-center mt-40"}>
                <Link to={"/"} className={cn(
                    "w-fit px-4 py-1 font-buttons font-medium text-md text-text-50 bg-secondary-main",
                    "hover:bg-secondary-dark cursor-pointer rounded"
                )}>
                    <span>{"BACK TO HOME"}</span>
                </Link>
            </div>
        </PageContentStyle>
    )
}

export default ThankYouPage