import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import mainRootReducer from 'redux/rootReducer';

const middlewares = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancers = composeEnhancers(applyMiddleware(...middlewares));

const store = createStore(mainRootReducer, enhancers);

export default store;