import { cn } from "utils/cn.utils";
import { useState, useEffect } from "react";

import { FaCheck, FaPen, FaTrash } from "react-icons/fa";

import SelectSearch, { fuzzySearch } from "react-select-search";

import { Button, IconWithTextButton } from "components/common-components/Buttons";
import { InputWithLabel } from "components/common-components/Form";
import { OptionSelector } from "components/common-components/Select";
import { TextAreaField } from "components/common-components/TextArea";


import { getMasterCountryList } from 'redux/master/master.request';
import { getUserAddressListByUserId } from "redux/user/user.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearCountryList } from 'redux/master/master.slice';
import {
    addNewAddressToBilling,
    setBillingInformation,
} from "../store/MySettingsSlice";
import { userRoles } from "redux/auth/auth.const";

import { setClearUserAddressList } from "redux/user/user.slice";

const MOCK_BUSINESS_TYPE_OPTIONS = [
    {
        label: "Sole Proprietorship",
        value: "Sole Proprietorship",
    },
    {
        label: "Freelancer",
        value: "Freelancer",
    },
    {
        label: "NGO",
        value: "NGO",
    },
    {
        label: "One Person Company",
        value: "One Personal Company",
    },

    {
        label: "Private Limited Company",
        value: "Private Limited Company",
    },
    {
        label: "Public Limited Company",
        value: "Public Limited Company",
    },
];

const GST_STATUS = [
    {
        label: "Registered",
        value: true,
    },
    {
        label: "Not Registered",
        value: false,
    },
];


export default function BillingPreferencesSettings() {
    const { locals } = useAppState((s) => s.local)
    const { user } = useAppState((state) => state.user)
    // const { userAddressList } = useAppState((state) => state.user)
    const { countryList, timeZoneCountryDetail } = useAppState(s => s.master)
    const state = useAppState((s) => s.mySettings.billingSettings);

    const dispatcher = useAppDispatcher();

    const [showGSTValidation, setShowGSTValidation] = useState(false);
    const [editAddressId, setEditAddressId] = useState<number>();
    const [myCountry, setMyCountry] = useState({
        masterCountryId: 0,
        country: "",
        countryDomain: "",
        isdCode: ""
    })

    useEffect(() => {
        dispatcher(getMasterCountryList())
        dispatcher(getUserAddressListByUserId(user?.user?.userId))

        return () => {
            dispatcher(setClearCountryList())
            dispatcher(setClearUserAddressList())
        }
    }, [])

    useEffect(() => {
        if (countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
            let myLocalCountryList = countryList?.countryList?.filter((country: any) => (
                country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country: any) => (
                    country.countryDomain === "US"
                ))
            }
            setMyCountry(myLocalCountryList[0])
        }
    }, [countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

    const setGSTNumber = (text: string) => {
        dispatcher(setBillingInformation({ ...state, GSTNumber: text }));
    };

    const validateGST = () => { };

    const setGSTStatus = (value: boolean) => {
        setShowGSTValidation(value);
        dispatcher(
            setBillingInformation({
                ...state,
                GSTStatus: value,
            })
        );
    };

    const setBusinessType = (value: string) => {
        dispatcher(
            setBillingInformation({
                ...state,
                bussinessType: value,
            })
        );
    };

    const onSelectCountry = (option: any) => {
        const selectedCountry = countryList?.countryList?.find((country: any) => country?.masterCountryId === option)
        setMyCountry(selectedCountry)
    }

    const addAddress = () => {
        const isValid = state.addresses.every(
            // no empty address
            (address: any) => address.billingAddress && address.shippingAddress
        );
        if (isValid) {
            const id = Math.floor(Math.random() * 1000000);
            const newaddress = {
                _id: id,
                billingAddress: "",
                shippingAddress: "",
            };
            dispatcher(addNewAddressToBilling(newaddress));
            setEditAddressId(id);
        } else {
            alert("Please fill all the address fields");
        }
    };

    const editAddress =
        (type: "billing" | "shipping", _id: number) => (e: any) => {
            const address = e.target.value;
            const copy = { ...state };
            const addressToEdit = copy.addresses.map((a: any) => {
                let newAdrs = { ...a };
                if (a._id === _id) {
                    if (type === "billing") {
                        newAdrs.billingAddress = address;
                    } else if (type === "shipping") {
                        newAdrs.shippingAddress = address;
                    }
                }
                return newAdrs;
            });
            copy.addresses = addressToEdit;
            dispatcher(setBillingInformation(copy));
        };

    const makeAddressDefault = (id: number) => {
        dispatcher(setBillingInformation({ ...state, defaultAddress: id }));
    };

    const onDoneEditing = () => {
        if (editAddressId) {
            const idx = state.addresses.findIndex((a: any) => a._id === editAddressId);
            const newAddresss = state.addresses[idx];
            if (
                newAddresss.billingAddress.length === 0 ||
                newAddresss.shippingAddress.length === 0
            ) {
                alert("Please enter all the address fields");
            } else {
                setEditAddressId(undefined);
            }
        }
    };

    const onDeleteAddress = (id: number) => {
        const copy = { ...state };
        const addresses = copy.addresses.filter((a: any) => a._id !== id);
        copy.addresses = addresses;
        dispatcher(setBillingInformation(copy));
    };

    return (
        <div className="">
            <div className="justify-center w-full bg-white">
                <h3 className="py-2 m-0 text-2xl text-center">
                    {"Billing Preferences"}
                </h3>
                <div className="h-0.5 bg-gray-100 w-9/12 mx-auto"></div>
            </div>

            <div className="px-6 py-3">
                {/* Tax Informatoin */}
                <div>
                    <h3 className="py-2 m-0 text-lg text-left text-gray-600">
                        {"Business And Tax Information"}
                    </h3>
                    <div className="h-0.5 bg-gray-300 w-full" />
                    <div className="grid grid-cols-1 md:grid-cols-2 max-w-xs gap-3 py-4">
                        <div className="flex items-center justify-start md:items-start md:justify-center">
                            <p className={"text-lg"}>
                                {locals.userRole === userRoles.STUDENT.value && 'Entity Type'}
                                {locals.userRole === userRoles.TUTOR.value && 'Business Type'}
                            </p>
                        </div>
                        <div className="self-start w-56 sm:w-72">
                            <OptionSelector
                                options={MOCK_BUSINESS_TYPE_OPTIONS}
                                onChange={(c) => {
                                    setBusinessType(c.value);
                                }}
                                value={state.bussinessType}
                            />
                        </div>
                        <div className="flex items-center justify-start md:items-start md:justify-center">
                            <p className="text-lg">
                                {"GST Status"}
                            </p>
                        </div>
                        <div className="flex flex-col self-start w-56 space-y-4 sm:w-72">
                            <OptionSelector
                                options={GST_STATUS}
                                onChange={(option) => {
                                    if (option.value) {
                                        setGSTStatus(true);
                                    } else {
                                        setGSTStatus(false);
                                    }
                                }}
                                value={state.GSTStatus}
                            />
                            {showGSTValidation && (
                                <div className={"flex flex-col items-start justify-center gap-5"}>
                                    {countryList?.countryList &&
                                        <div className={"w-full shadow-all rounded-lg"}>
                                            <SelectSearch
                                                options={countryList?.countryList?.map((country: any) => ({
                                                    name: country?.country,
                                                    value: country?.masterCountryId,
                                                }))}
                                                search
                                                filterOptions={fuzzySearch}
                                                value={String(myCountry?.masterCountryId)}
                                                onChange={(option) => onSelectCountry(option)}
                                            />

                                        </div>
                                    }
                                    <div className="flex space-x-2">
                                        <InputWithLabel
                                            className="flex-1"
                                            label="GST Number"
                                            onChange={(e) => {
                                                setGSTNumber(e.target.value);
                                            }}
                                            placeholder="Enter GST Number"
                                            value={state.GSTNumber || ""}
                                        />
                                        <Button
                                            name="Save"
                                            onClick={() => validateGST()}
                                            size="small"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>


                        {locals.userRole === userRoles.STUDENT.value &&
                            <div className={"flex items-center justify-start md:items-start text-lg"}>
                                <p className={""}>
                                    {'Country'}
                                </p>
                            </div>
                        }

                        {locals.userRole === userRoles.STUDENT.value &&
                            <div className={"self-start w-56 sm:w-72"}>
                                <input
                                    type={'text'}
                                    placeholder={"Enter Country Name"}
                                    className={"w-full py-1 px-3 border-none ring-2 ring-text-100 focus:outline-text-300 rounded-md shadow-md"}
                                />
                            </div>
                        }
                        {locals.userRole === userRoles.STUDENT.value &&
                            <div className={"flex items-center justify-start md:items-start"}>
                                <p className={"text-lg"}>
                                    {'State'}
                                </p>
                            </div>
                        }
                        {locals.userRole === userRoles.STUDENT.value &&
                            <div className="self-start w-56 sm:w-72">
                                <input
                                    type={'text'}
                                    placeholder={"Enter State Name"}
                                    className={"w-full py-1 px-3 border-none ring-2 ring-text-100  focus:outline-text-300 rounded-md shadow-md"}
                                />
                            </div>
                        }


                    </div>
                </div>

                {/* Address information */}

                <div>
                    <h3 className="py-2 m-0 text-lg text-left text-gray-600">
                        {"Business And Tax Information"}
                    </h3>
                    <div className="h-0.5 bg-gray-300 w-full" />

                    <div className="my-4 space-y-4">
                        {state.addresses.length > 0 ? (
                            state.addresses.map((address: any, index: any) => (
                                <div
                                    className={cn(
                                        "grid lg:grid-cols-2 gap-3",
                                        "px-4 py-2 border rounded-lg shadow-sm",
                                        state.defaultAddress === address._id &&
                                        "shadow-md bg-white border-l-4 border-green-500"
                                    )}
                                >
                                    <div className="flex flex-col">
                                        <p className="text-base font-bold text-gray-700">
                                            {"Shipping Address"}
                                        </p>

                                        <TextAreaField
                                            onChange={editAddress("shipping", address._id)}
                                            placeholder="Enter Shipping Address"
                                            value={address.shippingAddress}
                                            disabled={address._id !== editAddressId}
                                        />
                                    </div>

                                    <div>
                                        <p className="text-base font-bold text-gray-700">
                                            {"Billing Address"}
                                        </p>
                                        <TextAreaField
                                            onChange={editAddress("billing", address._id)}
                                            placeholder="Enter Billing Address"
                                            value={address.billingAddress}
                                            disabled={address._id !== editAddressId}
                                        />
                                    </div>

                                    <div className="flex justify-between w-full col-span-full ">
                                        <div>
                                            {state.defaultAddress === address._id && (
                                                <p className="pl-4 text-sm font-semibold text-green-700 ">
                                                    {'Default Address'}
                                                </p>
                                            )}
                                            {state.defaultAddress !== address._id && (
                                                <Button
                                                    name="Make Default"
                                                    size="small"
                                                    style="primary"
                                                    onClick={() => makeAddressDefault(address._id)}
                                                />
                                            )}
                                        </div>
                                        <div className="flex items-center justify-end flex-1 w-full space-x-3">
                                            {editAddressId === address._id ? (
                                                <IconWithTextButton
                                                    icon={<FaCheck />}
                                                    placeholder="Done"
                                                    onClick={onDoneEditing}
                                                    className="text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                />
                                            ) : (
                                                <IconWithTextButton
                                                    icon={<FaPen />}
                                                    placeholder="Edit"
                                                    onClick={() => setEditAddressId(address._id)}
                                                    className="border "
                                                />
                                            )}
                                            {/* <IconWithTextButton
                      icon={<FaTimesCircle />}
                      placeholder="Remove"
                      onClick={() => onDeleteAddress(address._id)}
                      className="text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    /> */}
                                            {address._id !== state.defaultAddress && (
                                                <button
                                                    className={cn(
                                                        "inline-flex items-center justify-center ",
                                                        "transform rounded-full bg-red-50 hover:scale-105"
                                                    )}
                                                    onClick={() => onDeleteAddress(address._id)}
                                                >
                                                    <FaTrash size={20} className="text-red-500 " />
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="my-20">
                                <p className="text-xl font-bold text-center text-gray-400">
                                    {"No Address Found"}
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="h-0.5 bg-gray-300 w-full mt-10 mb-3" />
                    <Button
                        name="Add New Address"
                        size="small"
                        // className="mt-7"
                        onClick={addAddress}
                    />
                </div>
            </div >

            {/* Actions  */}
            < div className="my-10 border-t shadow-sm" >
                <div className="flex justify-center py-6 space-x-4">
                    <Button name="Cancel" style="outline" />
                    <Button name="Save" style="primary" />
                </div>
            </div >
        </div >
    );
}

