import { useState, useCallback, memo } from 'react';

import { cn } from "utils/cn.utils";

import { HiOutlineDocument } from 'react-icons/hi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { MdOutlinePreview } from 'react-icons/md';

import ToolTipView from 'components/tooltipView';

import { getThumbnailUrl } from 'pages/auth/edulyteLms/editLmsPages/editLmsQuestion/editLMSQuestion.data';

import { deleteLmsQuestionOptionResourceDetail } from "redux/edulyteLms/lmsQuestion/lmsQuestion.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetPreviewImageContent, setModal, setPreviewImageContent } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsResourceTypeEnum, resourceFileTypeEnum } from 'redux/edulyteLms/lmsResource/lmsResource.const';

import { getYouTubeVideoIdFromUrl } from 'utils/generators.utils';

const QuestionOptionResource = memo(({ questionOption }) => {
    const { modal, previewImage } = useAppState(s => s.local)
    const { deleteLmsQuestionOptionResource } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()

    const [hoverItem, setHoverItem] = useState(null)

    const onHandlePreviewLink = useCallback((resourceUrl) => {
        dispatcher(resetPreviewImageContent())
        dispatcher(setPreviewImageContent({
            ...previewImage,
            previewUrl: resourceUrl
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.LMS_PREVIEW_IMAGE_MODAL.stateKey]: true
        }))
    }, [modal, previewImage])

    const onHandleDeleteResource = useCallback((resourceItem) => {
        if (deleteLmsQuestionOptionResource?.isLoading) return;

        dispatcher(deleteLmsQuestionOptionResourceDetail(resourceItem?.id, questionOption?.option_id))
    }, [questionOption, deleteLmsQuestionOptionResource?.isLoading])

    return (
        <div className={"px-2 sm:px-10 w-fit flex flex-wrap items-center justify-start gap-5"}>
            {(questionOption?.question_option_resources?.length > 0) &&
                questionOption?.question_option_resources?.map((optionResource, index) => {
                    let optionResourceUrl = null
                    if ([lmsResourceTypeEnum.FILE.value]?.includes(optionResource?.resource?.type)) {
                        optionResourceUrl = optionResource?.resource?.resource?.file?.file_url
                    } else {
                        optionResourceUrl = optionResource?.resource?.resource?.resource_link
                    }
                    return (
                        <div
                            key={index}
                            className={cn(
                                "relative flex flex-col content-between justify-between rounded-lg shadow-lg h-20 aspect-square text-xs cursor-pointer",
                                "hover:scale-105 hover:transition duration-200 hover:shadow-lg",
                                "hover:ring-offset-2 hover:ring-4 hover:ring-secondary-dark",
                                [lmsResourceTypeEnum.FILE.value]?.includes(optionResource?.resource?.type) &&
                                ([resourceFileTypeEnum?.IMAGE?.value]?.includes(optionResource?.resource?.resource?.type)) && "bg-black",
                                [lmsResourceTypeEnum.FILE.value]?.includes(optionResource?.resource?.type) &&
                                (![resourceFileTypeEnum?.IMAGE?.value]?.includes(optionResource?.resource?.resource?.type)) && "bg-sky-200",
                                [lmsResourceTypeEnum.YOUTUBE.value]?.includes(optionResource?.resource?.type) && "bg-black",
                                [lmsResourceTypeEnum.LINK.value]?.includes(optionResource?.resource?.type) && "bg-sky-200",
                            )}
                            onMouseOver={() => setHoverItem(index)}
                            onMouseOut={() => setHoverItem(null)}
                        >
                            {[lmsResourceTypeEnum.FILE.value]?.includes(optionResource?.resource?.type)
                                && ([resourceFileTypeEnum?.IMAGE?.value]?.includes(optionResource?.resource?.resource?.type))
                                && <img
                                    src={optionResourceUrl}
                                    alt={optionResource?.resource?.title}
                                    className={"object-cover w-full h-full rounded-lg opacity-90"}
                                />
                            }
                            {[lmsResourceTypeEnum.FILE.value]?.includes(optionResource?.resource?.type)
                                && (![resourceFileTypeEnum?.IMAGE?.value]?.includes(optionResource?.resource?.resource?.type))
                                && <div className={"mx-0.5 w-full h-full flex items-end justify-start gap-0.5 overflow-hidden"}>
                                    <span className={""}>
                                        <HiOutlineDocument className={"text-md text-text-600"} />
                                    </span>
                                    <span className={"w-fit font-bodyPri font-normal text-xs text-text-600 truncate"}>
                                        {(optionResource?.resource?.title?.length > 8)
                                            ? optionResource?.resource?.title?.slice(0, 8) + "..."
                                            : optionResource?.resource?.title}
                                    </span>
                                </div>
                            }
                            {[lmsResourceTypeEnum.YOUTUBE.value]?.includes(optionResource?.resource?.type) &&
                                <img
                                    src={getThumbnailUrl(getYouTubeVideoIdFromUrl(optionResourceUrl))}
                                    alt={'Video Thumbnail'}
                                    className={"object-cover w-full h-full rounded-lg opacity-90"}
                                />
                            }
                            {[lmsResourceTypeEnum.LINK.value]?.includes(optionResource?.resource?.type) &&
                                <div className={"mx-0.5 w-full h-full flex items-end justify-start gap-0.5 overflow-hidden"}>
                                    <span className={""}>
                                        <HiOutlineDocument className={"text-md text-text-600"} />
                                    </span>
                                    <span className={"w-fit font-bodyPri font-normal text-xs text-text-600 truncate"}>
                                        {(optionResource?.resource?.title?.length > 8)
                                            ? optionResource?.resource?.title?.slice(0, 8) + "..."
                                            : optionResource?.resource?.title}
                                    </span>
                                </div>
                            }
                            {(hoverItem === index) &&
                                <div className={"absolute w-full h-full rounded-lg overflow-hidden flex justify-center items-center"}>
                                    <div className={"px-2 py-0.5 flex items-center justify-center gap-3 bg-white rounded-lg"}>
                                        <ToolTipView content={"Preview Image"}>
                                            <button className={"group"} onClick={() => onHandlePreviewLink(optionResourceUrl)}>
                                                <MdOutlinePreview className={"text-text-700 group-hover:text-primary-dark text-xl"} />
                                            </button>
                                        </ToolTipView>
                                        <ToolTipView content={"Remove Image"}>
                                            <button className={"group"} onClick={() => onHandleDeleteResource(optionResource)}>
                                                <RiDeleteBin6Line className={"text-xl text-text-700 hover:text-red-500 cursor-pointer"} />
                                            </button>
                                        </ToolTipView>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })
            }
        </div>
    )
});

export default QuestionOptionResource;