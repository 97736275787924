import tw from 'twin.macro';
import { PageContent } from 'components/generalComponent/StyledComponent';

export const PageContentStyle = tw(PageContent)``;

export const CompanySection = tw.div`
    w-full space-y-3
`;

export const FooterContainer = tw.div`
    py-3
`;