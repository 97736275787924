import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "./user.const";


class UserService {
    getMyProfile = async () => {
        const response = await baseAuthApiInstance().get(
            url.MY_PROFILE
        )

        return response;
    }

    getUserDetailByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.USER_DETAIL, params)
        )

        return response;
    }

    updateUserDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.USER_DETAIL, params),
            body
        )

        return response;
    }

    getUserCultureDetailByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.USER_CULTURE_DETAIL, params)
        )

        return response;
    }

    updateUserCultureDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.USER_CULTURE_DETAIL, params),
            body
        )

        return response;
    }

    getUserEducationListByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.USER_EDUCATION_LIST, params)
        )

        return response;
    }

    createUserEducationDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.USER_EDUCATION_LIST, params),
            body
        )

        return response;
    }

    updateEducationDetailByUserEducationId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.EDUCATION_DETAIL, params),
            body
        )

        return response;
    }

    deleteEducationDetailByUserEducationId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.EDUCATION_DETAIL, params),
            { data: body }
        )

        return response;
    }

    getUserOtherPrefDetailByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.USER_OTHER_PREF_DETAIL, params)
        )

        return response;
    }

    updateUserOtherPrefDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.USER_OTHER_PREF_DETAIL, params),
            body
        )

        return response;
    }

    getUserSocialListByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.USER_SOCIAL_LIST, params)
        )

        return response;
    }

    createUserSocialDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.USER_SOCIAL_LIST, params),
            body
        )

        return response;
    }

    updateSocialDetailByUserSocialId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.USER_SOCIAL_DETAIL, params),
            body
        )

        return response;
    }

    getUserAddressListByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.USER_ADDRESS_LIST, params)
        )

        return response;
    }

    createUserAddressDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.USER_ADDRESS_LIST, params),
            body
        )

        return response;
    }

    updateAddressDetailByUserAddressId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.USER_ADDRESS_DETAIL, params),
            body
        )

        return response;
    }

    deleteAddressDetailByUserAddressId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.USER_ADDRESS_DETAIL, params),
            body
        )

        return response;
    }

    getUserList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_LIST),
            { params: query }
        )

        return response;
    }
    updateUserConfig = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_USER_CONFIG, params),
            body
        )

        return response;
    }
}

export default new UserService()