import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import PageHeader from 'components/pageHeader/PageHeader';

import { cardData, pageHeading } from './data';
import IntergrationCard from './intergrationCard';

import authService from 'redux/auth/auth.service';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setUser } from 'redux/user/user.slice';
import { pagesInfo } from 'utils/pagesInfo';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { useTitle } from 'hooks/useTitle';

const Integrations = () => {
    const { user } = useAppState(s => s.user);
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useTitle()
    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.STUDENT_CERTIFICATES))
    }, [dispatcher])

    useEffect(() => {
        if (user?.user) {
            setTitle({
                ...title,
                title: `${user?.user?.firstName}, Intergrations | Edulyte`
            })
        }
    }, [user?.user])

    const createRevokeGoogleCalendarAccess = async () => {
        const isConfirmed = window.confirm("Are you sure? Disconnecting will remove the integration, potentially disrupting your connected experience.");
        if (!isConfirmed) return;
        setLoading(true)
        try {
            const response = await authService.createRevokeGoogleCalendarAccess()
            if (response.status === 200) {
                dispatcher(setUser({
                    ...user?.user,
                    calendar: {
                        ...user?.user?.calendar,
                        google: {
                            ...user?.user?.calendar?.google,
                            active: false
                        }
                    }
                }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error("Google revoke error:", error)
            toast.error("An error occurred while disconnecting to Google Calendar. Please try again.")
        } finally {
            setLoading(false)
        }
    }

    const createGoogleCalendarIntegration = async () => {
        setLoading(true)

        try {
            const response = await authService.createGoogleCalendarIntegration()
            if (response.status === 200) {
                toast.success(response.data.message)
                window.location.href = response?.data?.auth_url;
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error("Google integration error:", error)
            toast.error("An error occurred while connecting to Google Calendar. Please try again.")
        } finally {
            setLoading(false)
        }
    }

    const handleToggle = (key, value) => {
        if (key === cardData?.google_calendar?.key) {
            if (user?.user?.calendar?.google?.active) {
                createRevokeGoogleCalendarAccess()
            } else if (!user?.user?.calendar?.google?.active) {
                createGoogleCalendarIntegration()
            }
        }
    }

    const getCardData = (cardList) => cardList?.map((cardItem) => {
        if (cardItem?.key === cardData?.google_calendar?.key) {
            return { ...cardItem, active: user?.user?.calendar?.google?.active }
        }
    })

    return (
        <div className="h-full w-full p-3 mx-auto min-h-screen space-y-5 bg-background-light font-bodyPri">
            <PageHeader pageHeading={pageHeading} />
            <div className='bg-white p-5 rounded-lg space-y-8'>
                <h2 className='font-medium'>Edulyte integrates with various tools to streamline your workflow and boost productivity.</h2>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5'>
                    {getCardData(Object?.values(cardData))?.map((card, index) => (
                        <IntergrationCard
                            key={index}
                            card={card}
                            active={card?.active}
                            onActive={handleToggle}
                            loading={loading || user?.isLoading}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Integrations;
