import { createSlice } from "@reduxjs/toolkit";
import { COURSE_INITIAL_STATE } from "redux/course/course.initialState";

const course = createSlice({
    name: "course",
    initialState: COURSE_INITIAL_STATE,
    reducers: {
        // course detail
        setCourseDetailLoading: (state, { payload }) => {
            state.courseDetail.isLoading = payload
        },
        setCourseDetail: (state, { payload }) => {
            state.courseDetail.courseDetail = payload
            state.courseDetail.errorMsg = COURSE_INITIAL_STATE.courseDetail.errorMsg
        },
        setCourseDetailErrorMsg: (state, { payload }) => {
            state.courseDetail.errorMsg = payload
            state.courseDetail.courseDetail = COURSE_INITIAL_STATE.courseDetail.courseDetail
        },
        setClearCourseDetail: (state) => {
            state.courseDetail = COURSE_INITIAL_STATE.courseDetail
        },

        // add course detail
        setAddCourseDetailLoading: (state, { payload }) => {
            state.addCourseDetail.isLoading = payload
        },
        setAddCourseDetail: (state, { payload }) => {
            state.addCourseDetail.addCourseDetail = payload
            state.addCourseDetail.errorMsg = COURSE_INITIAL_STATE.addCourseDetail.addCourseDetail
        },
        setAddCourseDetailErrorMsg: (state, { payload }) => {
            state.addCourseDetail.errorMsg = payload
            state.addCourseDetail.addCourseDetail = COURSE_INITIAL_STATE.addCourseDetail.addCourseDetail
        },
        setClearAddCourseDetail: (state) => {
            state.addCourseDetail = COURSE_INITIAL_STATE.addCourseDetail
        },

        // new course slice

        // user course list
        setUserCourseListLoading: (state, { payload }) => {
            state.userCourseList.isLoading = payload
        },
        setUserCourseListData: (state, { payload }) => {
            state.userCourseList.data = payload
            state.userCourseList.message = COURSE_INITIAL_STATE.userCourseList.message
        },
        setUserCourseListMessage: (state, { payload }) => {
            state.userCourseList.message = payload
            state.userCourseList.data = COURSE_INITIAL_STATE.userCourseList.data
        },
        setClearUserCourseList: (state) => {
            state.userCourseList = COURSE_INITIAL_STATE.userCourseList
        },

        // user favorite course list
        setUserFavCourseListLoading: (state, { payload }) => {
            state.userFavCourseList.isLoading = payload
        },
        setUserFavCourseListData: (state, { payload }) => {
            state.userFavCourseList.data = payload
            state.userFavCourseList.message = COURSE_INITIAL_STATE.userFavCourseList.message
        },
        setUserFavCourseListMessage: (state, { payload }) => {
            state.userFavCourseList.message = payload
            state.userFavCourseList.data = COURSE_INITIAL_STATE.userFavCourseList.data
        },
        setClearUserFavCourseList: (state) => {
            state.userFavCourseList = COURSE_INITIAL_STATE.userFavCourseList
        },

        // add course favorite detail
        setAddCourseFavDetailLoading: (state, { payload }) => {
            state.addCourseFavDetail.isLoading = payload
        },
        setAddCourseFavDetailMessage: (state, { payload }) => {
            state.addCourseFavDetail.message = payload
        },

        // delete course favorite detail
        setDeleteCourseFavDetailLoading: (state, { payload }) => {
            state.deleteCourseFavDetail.isLoading = payload
        },
        setDeleteCourseFavDetailMessage: (state, { payload }) => {
            state.deleteCourseFavDetail.message = payload
        },

        // public course list
        setPublicCourseListLoading: (state, { payload }) => {
            state.publicCourseList.isLoading = payload
        },
        setPublicCourseListData: (state, { payload }) => {
            state.publicCourseList.data = payload
            state.publicCourseList.message = COURSE_INITIAL_STATE.publicCourseList.message
        },
        setPublicCourseListMessage: (state, { payload }) => {
            state.publicCourseList.message = payload
            state.publicCourseList.data = COURSE_INITIAL_STATE.publicCourseList.data
        },
        setClearPublicCourseList: (state) => {
            state.publicCourseList = COURSE_INITIAL_STATE.publicCourseList
        },

        // public course detail
        setPublicCourseDetailLoading: (state, { payload }) => {
            state.publicCourseDetail.isLoading = payload
        },
        setPublicCourseDetailData: (state, { payload }) => {
            state.publicCourseDetail.data = payload
            state.publicCourseDetail.message = COURSE_INITIAL_STATE.publicCourseDetail.message
        },
        setPublicCourseDetailMessage: (state, { payload }) => {
            state.publicCourseDetail.message = payload
            state.publicCourseDetail.data = COURSE_INITIAL_STATE.publicCourseDetail.data
        },
        setClearPublicCourseDetail: (state) => {
            state.publicCourseDetail = COURSE_INITIAL_STATE.publicCourseDetail
        },

        // Selected Public Course Detail
        setSelectedPublicCourseDetailLoading: (state, { payload }) => {
            state.selectedPublicCourseDetail.isLoading = payload
        },
        setSelectedPublicCourseDetail: (state, { payload }) => {
            state.selectedPublicCourseDetail.data = payload
            state.selectedPublicCourseDetail.message = COURSE_INITIAL_STATE.selectedPublicCourseDetail.message
        },
        setSelectedPublicCourseDetailMessage: (state, { payload }) => {
            state.selectedPublicCourseDetail.message = payload
            state.selectedPublicCourseDetail.data = COURSE_INITIAL_STATE.selectedPublicCourseDetail.data
        },
        resetSelectedPublicCourseDetail: (state) => {
            state.selectedPublicCourseDetail = COURSE_INITIAL_STATE.selectedPublicCourseDetail
        },

        // public tutor course list
        setPublicTutorCourseListLoading: (state, { payload }) => {
            state.publicTutorCourseList.isLoading = payload
        },
        setPublicTutorCourseListData: (state, { payload }) => {
            state.publicTutorCourseList.data = payload
            state.publicTutorCourseList.message = COURSE_INITIAL_STATE.publicTutorCourseList.message
        },
        setPublicTutorCourseListMessage: (state, { payload }) => {
            state.publicTutorCourseList.message = payload
            state.publicTutorCourseList.data = COURSE_INITIAL_STATE.publicTutorCourseList.data
        },
        setClearPublicTutorCourseList: (state) => {
            state.publicTutorCourseList = COURSE_INITIAL_STATE.publicTutorCourseList
        },

        // template course list
        setCourseTemplateListLoading: (state, { payload }) => {
            state.courseTemplateList.isLoading = payload
        },
        setCourseTemplateList: (state, { payload }) => {
            state.courseTemplateList.courseTemplateList = payload
        },
        setCourseTemplateListErrorMsg: (state, { payload }) => {
            state.courseTemplateList.errorMsg = payload
            state.courseTemplateList.courseTemplateList = COURSE_INITIAL_STATE.courseTemplateList.courseTemplateList
        },
        setClearCourseTemplateList: (state) => {
            state.courseTemplateList = COURSE_INITIAL_STATE.courseTemplateList
        },

        // template course detail
        setCourseTemplateDetailLoading: (state, { payload }) => {
            state.courseTemplateDetail.isLoading = payload
        },
        setCourseTemplateDetail: (state, { payload }) => {
            state.courseTemplateDetail.courseTemplateDetail = payload
            state.courseTemplateDetail.errorMsg = COURSE_INITIAL_STATE.courseTemplateDetail.errorMsg
        },
        setCourseTemplateDetailErrorMsg: (state, { payload }) => {
            state.courseTemplateDetail.errorMsg = payload
            state.courseTemplateDetail.courseTemplateDetail = COURSE_INITIAL_STATE.courseTemplateDetail.courseTemplateDetail
        },
        setClearCourseTemplateDetail: (state) => {
            state.courseTemplateDetail = COURSE_INITIAL_STATE.courseTemplateDetail
        },

        // template course detail payload
        setCourseTemplateDetailPayload: (state, { payload }) => {
            state.courseTemplateDetail.courseTemplateDetailPayload = payload
        },
        setClearCourseTemplateDetailPayload: (state) => {
            state.courseTemplateDetail.courseTemplateDetailPayload = COURSE_INITIAL_STATE.courseTemplateDetail.courseTemplateDetailPayload
        },

        // course tag list
        setCourseTagListLoading: (state, { payload }) => {
            state.courseTagList.isLoading = payload
        },
        setCourseTagList: (state, { payload }) => {
            state.courseTagList.courseTagList = payload
            state.courseTagList.errorMsg = COURSE_INITIAL_STATE.courseTagList.errorMsg
        },
        setCourseTagListErrorMsg: (state, { payload }) => {
            state.courseTagList.errorMsg = payload
            state.courseTagList.courseTagList = COURSE_INITIAL_STATE.courseTagList.courseTagList
        },
        setClearCourseTagList: (state) => {
            state.courseTagList = COURSE_INITIAL_STATE.courseTagList
        },

        // add course tag detail
        setAddCourseTagDetailLoading: (state, { payload }) => {
            state.addCourseTagDetail.isLoading = payload
        },
        setAddCourseTagDetail: (state, { payload }) => {
            state.addCourseTagDetail.addCourseTagDetail = payload
            state.addCourseTagDetail.errorMsg = COURSE_INITIAL_STATE.addCourseTagDetail.errorMsg
        },
        setAddCourseTagDetailErrorMsg: (state, { payload }) => {
            state.addCourseTagDetail.errorMsg = payload
            state.addCourseTagDetail.addCourseTagDetail = COURSE_INITIAL_STATE.addCourseTagDetail.addCourseTagDetail
        },
        setClearAddCourseTagDetail: (state) => {
            state.addCourseTagDetail = COURSE_INITIAL_STATE.addCourseTagDetail
        },

        // course tutor list
        setCourseTutorListLoading: (state, { payload }) => {
            state.courseTutorList.isLoading = payload
        },
        setCourseTutorList: (state, { payload }) => {
            state.courseTutorList.courseTutorList = payload
            state.courseTutorList.errorMsg = COURSE_INITIAL_STATE.courseTutorList.errorMsg
        },
        setCourseTutorListErrorMsg: (state, { payload }) => {
            state.courseTutorList.errorMsg = payload
            state.courseTutorList.courseTutorList = COURSE_INITIAL_STATE.courseTutorList.courseTutorList
        },
        setClearCourseTutorList: (state) => {
            state.courseTutorList = COURSE_INITIAL_STATE.courseTutorList
        },

        // course tutor detail
        setCourseTutorDetailLoading: (state, { payload }) => {
            state.courseTutorDetail.isLoading = payload
        },
        setCourseTutorDetail: (state, { payload }) => {
            state.courseTutorDetail.courseTutorDetail = payload
            state.courseTutorDetail.errorMsg = COURSE_INITIAL_STATE.courseTutorDetail.errorMsg
        },
        setCourseTutorDetailErrorMsg: (state, { payload }) => {
            state.courseTutorDetail.errorMsg = payload
            state.courseTutorDetail.courseTutorDetail = COURSE_INITIAL_STATE.courseTutorDetail.courseTutorDetail
        },
        setClearCourseTutorDetail: (state) => {
            state.courseTutorDetail = COURSE_INITIAL_STATE.courseTutorDetail
        },

        // course prof list 
        setCourseProfListLoading: (state, { payload }) => {
            state.courseProfList.isLoading = payload
        },
        setCourseProfList: (state, { payload }) => {
            state.courseProfList.courseProfList = payload
            state.courseProfList.errorMsg = COURSE_INITIAL_STATE.courseProfList.errorMsg
        },
        setCourseProfListErrorMsg: (state, { payload }) => {
            state.courseProfList.errorMsg = payload
            state.courseProfList.courseProfList = COURSE_INITIAL_STATE.courseProfList.courseProfList
        },
        setClearCourseProficiencyList: (state) => {
            state.courseProfList = COURSE_INITIAL_STATE.courseProfList
        },

        // add course proficiency
        setAddCourseProfDetailLoading: (state, { payload }) => {
            state.addCourseProfDetail.isLoading = payload
        },
        setAddCourseProfDetail: (state, { payload }) => {
            state.addCourseProfDetail.addCourseProfDetail = payload
            state.addCourseProfDetail.errorMsg = COURSE_INITIAL_STATE.addCourseProfDetail.errorMsg
        },
        setAddCourseProfDetailErrorMsg: (state, { payload }) => {
            state.addCourseProfDetail.errorMsg = payload
            state.addCourseProfDetail.addCourseProfDetail = COURSE_INITIAL_STATE.addCourseProfDetail.addCourseProfDetail
        },
        setClearAddCourseProfDetail: (state) => {
            state.addCourseProfDetail = COURSE_INITIAL_STATE.addCourseProfDetail
        },

        //course language list
        setCourseLanguageListLoading: (state, { payload }) => {
            state.courseLanguageList.isLoading = payload
        },
        setCourseLanguageList: (state, { payload }) => {
            state.courseLanguageList.courseLanguageList = payload
            state.courseLanguageList.errorMsg = COURSE_INITIAL_STATE.courseLanguageList.errorMsg
        },
        setCourseLanguageListErrorMsg: (state, { payload }) => {
            state.courseLanguageList.errorMsg = payload
            state.courseLanguageList.courseLanguageList = COURSE_INITIAL_STATE.courseLanguageList.courseLanguageList
        },
        setClearCourseLanguageList: (state) => {
            state.courseLanguageList = COURSE_INITIAL_STATE.courseLanguageList
        },

        // add course language
        setAddCourseLanguageDetailLoading: (state, { payload }) => {
            state.addCourseLanguageDetail.isLoading = payload
        },
        setAddCourseLanguageDetail: (state, { payload }) => {
            state.addCourseLanguageDetail.addCourseLanguageDetail = payload
            state.addCourseLanguageDetail.errorMsg = COURSE_INITIAL_STATE.addCourseLanguageDetail.errorMsg
        },
        setAddCourseLanguageDetailErrorMsg: (state, { payload }) => {
            state.addCourseLanguageDetail.errorMsg = payload
            state.addCourseLanguageDetail.addCourseLanguageDetail = COURSE_INITIAL_STATE.addCourseLanguageDetail.addCourseLanguageDetail
        },
        setClearAddCourseLanguageDetail: (state) => {
            state.addCourseLanguageDetail = COURSE_INITIAL_STATE.addCourseLanguageDetail
        },

        //course availability detail
        setCourseAvailDetailLoading: (state, { payload }) => {
            state.courseAvailDetail.isLoading = payload
        },
        setCourseAvailDetail: (state, { payload }) => {
            state.courseAvailDetail.courseAvailDetail = payload
            state.courseAvailDetail.errorMsg = COURSE_INITIAL_STATE.courseAvailDetail.errorMsg
        },
        setCourseAvailErrorMsg: (state, { payload }) => {
            state.courseAvailDetail.errorMsg = payload
            state.courseAvailDetail.courseAvailDetail = COURSE_INITIAL_STATE.courseAvailDetail.courseAvailDetail
        },
        setClearCourseAvailDetail: (state) => {
            state.courseAvailDetail = COURSE_INITIAL_STATE.courseAvailDetail
        },

        // add course availability detail
        setAddCourseAvailDetailLoading: (state, { payload }) => {
            state.addCourseAvailDetail.isLoading = payload
        },
        setAddCourseAvailDetail: (state, { payload }) => {
            state.addCourseAvailDetail.addCourseAvailDetail = payload
            state.addCourseAvailDetail.errorMsg = COURSE_INITIAL_STATE.addCourseAvailDetail.errorMsg
        },
        setAddCourseAvailDetailErrorMsg: (state, { payload }) => {
            state.addCourseAvailDetail.errorMsg = payload
            state.addCourseAvailDetail.addCourseAvailDetail = COURSE_INITIAL_STATE.addCourseAvailDetail.addCourseAvailDetail
        },
        setClearAddCourseAvailDetail: (state) => {
            state.addCourseAvailDetail = COURSE_INITIAL_STATE.addCourseAvailDetail
        },

        //course avail detail payload
        setAddCourseAvailDetailPayload: (state, { payload }) => {
            state.addCourseAvailDetail.addCourseAvailDetailPayload = payload
        },
        setClearAddCourseAvailDetailPayload: (state) => {
            state.addCourseAvailDetail.addCourseAvailDetailPayload = COURSE_INITIAL_STATE.addCourseAvailDetail.addCourseAvailDetailPayload
        },

        // course price detail
        setCoursePriceDetailLoading: (state, { payload }) => {
            state.coursePriceDetail.isLoading = payload
        },
        setCoursePriceDetail: (state, { payload }) => {
            state.coursePriceDetail.coursePriceDetail = payload
            state.coursePriceDetail.errorMsg = COURSE_INITIAL_STATE.coursePriceDetail.errorMsg
        },
        setCoursePriceDetailErrorMsg: (state, { payload }) => {
            state.coursePriceDetail.coursePriceDetail = payload
            state.coursePriceDetail.coursePriceDetail = COURSE_INITIAL_STATE.coursePriceDetail.coursePriceDetail
        },
        setClearCoursePriceDetail: (state) => {
            state.coursePriceDetail = COURSE_INITIAL_STATE.coursePriceDetail
        },

        // add course price detail
        setAddCoursePriceDetailLoading: (state, { payload }) => {
            state.addCoursePriceDetail.isLoading = payload
        },
        setAddCoursePriceDetail: (state, { payload }) => {
            state.addCoursePriceDetail.addCoursePriceDetail = payload
            state.addCoursePriceDetail.errorMsg = COURSE_INITIAL_STATE.addCoursePriceDetail.errorMsg
        },
        setAddCoursePriceDetailErrorMsg: (state, { payload }) => {
            state.addCoursePriceDetail.errorMsg = payload
            state.addCoursePriceDetail.addCoursePriceDetail = COURSE_INITIAL_STATE.addCoursePriceDetail.addCoursePriceDetail
        },
        setClearAddCoursePriceDetail: (state) => {
            state.addCoursePriceDetail = COURSE_INITIAL_STATE.addCoursePriceDetail
        },

        // preview course detail
        setCoursePreviewDetailLoading: (state, { payload }) => {
            state.coursePreviewDetail.isLoading = payload
        },
        setCoursePreviewDetailData: (state, { payload }) => {
            state.coursePreviewDetail.data = payload
            state.coursePreviewDetail.message = COURSE_INITIAL_STATE.coursePreviewDetail.message
        },
        setCoursePreviewDetailMessage: (state, { payload }) => {
            state.coursePreviewDetail.message = payload
            state.coursePreviewDetail.data = COURSE_INITIAL_STATE.coursePreviewDetail.data
        },
        setClearCoursePreviewDetail: (state) => {
            state.coursePreviewDetail = COURSE_INITIAL_STATE.coursePreviewDetail
        },

        // tutor course list
        setTutorCourseListLoading: (state, { payload }) => {
            state.tutorCourseList.isLoading = payload
        },
        setTutorCourseListData: (state, { payload }) => {
            state.tutorCourseList.data = payload
            state.tutorCourseList.message = COURSE_INITIAL_STATE.tutorCourseList.message
        },
        setTutorCourseListMessage: (state, { payload }) => {
            state.tutorCourseList.message = payload
            state.tutorCourseList.data = COURSE_INITIAL_STATE.tutorCourseList.data
        },
        setClearTutorCourseList: (state) => {
            state.tutorCourseList = COURSE_INITIAL_STATE.tutorCourseList
        },

        // tutor course detail
        setTutorCourseDetailLoading: (state, { payload }) => {
            state.tutorCourseDetail.isLoading = payload
        },
        setTutorCourseDetailData: (state, { payload }) => {
            state.tutorCourseDetail.data = payload
            state.tutorCourseDetail.message = COURSE_INITIAL_STATE.tutorCourseDetail.message
        },
        setTutorCourseDetailMessage: (state, { payload }) => {
            state.tutorCourseDetail.message = payload
            state.tutorCourseDetail.data = COURSE_INITIAL_STATE.tutorCourseDetail.data
        },
        setClearTutorCourseDetail: (state) => {
            state.tutorCourseDetail = COURSE_INITIAL_STATE.tutorCourseDetail
        },

        // clone course detail
        setCloneCourseDetailLoading: (state, { payload }) => {
            state.cloneCourseDetail.isLoading = payload
        },
        setCloneCourseDetailData: (state, { payload }) => {
            state.cloneCourseDetail.data = payload
            state.cloneCourseDetail.message = COURSE_INITIAL_STATE.cloneCourseDetail.message
        },
        setCloneCourseDetailMessage: (state, { payload }) => {
            state.cloneCourseDetail.message = payload
            state.cloneCourseDetail.data = COURSE_INITIAL_STATE.cloneCourseDetail.data
        },
        setClearCloneCourseDetail: (state) => {
            state.cloneCourseDetail = COURSE_INITIAL_STATE.cloneCourseDetail
        },

        // reducers for create next course session detail
        setNextCourseSessionDetailLoading: (state, { payload }) => {
            state.nextCourseSessionDetail.isLoading = payload
        },
        setNextCourseSessionDetailData: (state, { payload }) => {
            state.nextCourseSessionDetail.data = payload
            state.nextCourseSessionDetail.message = COURSE_INITIAL_STATE.nextCourseSessionDetail.message
        },
        setNextCourseSessionDetailMessage: (state, { payload }) => {
            state.nextCourseSessionDetail.message = payload
            state.nextCourseSessionDetail.data = COURSE_INITIAL_STATE.nextCourseSessionDetail.data
        },
        resetNextCourseSessionDetail: (state) => {
            state.nextCourseSessionDetail = COURSE_INITIAL_STATE.nextCourseSessionDetail
        },

        // reducers for create next course session payload
        setNextCourseSessionDetailPayload: (state, { payload }) => {
            state.nextCourseSessionDetail.payload = payload
        },
        resetNextCourseSessionDetailPayload: (state) => {
            state.nextCourseSessionDetail.payload = COURSE_INITIAL_STATE.nextCourseSessionDetail.payload
        },

        // course comment list
        setCourseCommentListLoading: (state, { payload }) => {
            state.courseCommentList.isLoading = payload
        },
        setCourseCommentListData: (state, { payload }) => {
            state.courseCommentList.data = payload
            state.courseCommentList.message = COURSE_INITIAL_STATE.courseCommentList.message
        },
        setCourseCommentListMessage: (state, { payload }) => {
            state.courseCommentList.message = payload
            state.courseCommentList.data = COURSE_INITIAL_STATE.courseCommentList.data
        },
        resetCourseCommentList: (state) => {
            state.courseCommentList = COURSE_INITIAL_STATE.courseCommentList
        },
        // add course comment detail
        setAddCourseCommentDetailLoading: (state, { payload }) => {
            state.addCourseCommentDetail.isLoading = payload
        },
        setAddCourseCommentDetailMessage: (state, { payload }) => {
            state.addCourseCommentDetail.message = payload
        },
        resetAddCourseCommentDetail: (state) => {
            state.addCourseCommentDetail = COURSE_INITIAL_STATE.addCourseCommentDetail
        },

        // check for recording camera
        setCameraStatus: (state, { payload }) => {
            state.cameraStatus.isCameraOpen = payload
        },
        resetCameraStatus: (state, { payload }) => {
            state.cameraStatus = COURSE_INITIAL_STATE.cameraStatus
        },

        // filter props
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps;
        },
        setClearFilterProps: (state) => {
            state.filterProps = COURSE_INITIAL_STATE.filterProps
        }
    }
})

export const {
    // extras
    setCourseDetailLoading,
    setCourseDetail,
    setCourseDetailErrorMsg,
    setClearCourseDetail,

    setAddCourseDetailLoading,
    setAddCourseDetail,
    setAddCourseDetailErrorMsg,
    setClearAddCourseDetail,

    // new course slice
    setUserFavCourseListLoading,
    setUserFavCourseListData,
    setUserFavCourseListMessage,
    setClearUserFavCourseList,

    setUserCourseListLoading,
    setUserCourseListData,
    setUserCourseListMessage,
    setClearUserCourseList,

    setPublicCourseListLoading,
    setPublicCourseListData,
    setPublicCourseListMessage,
    setClearPublicCourseList,

    setPublicCourseDetailLoading,
    setPublicCourseDetailData,
    setPublicCourseDetailMessage,
    setClearPublicCourseDetail,

    setSelectedPublicCourseDetailLoading,
    setSelectedPublicCourseDetail,
    setSelectedPublicCourseDetailMessage,
    resetSelectedPublicCourseDetail,

    setPublicTutorCourseListLoading,
    setPublicTutorCourseListData,
    setPublicTutorCourseListMessage,
    setClearPublicTutorCourseList,

    setAddCourseFavDetailLoading,
    setAddCourseFavDetailMessage,

    setDeleteCourseFavDetailLoading,
    setDeleteCourseFavDetailMessage,

    setTutorCourseListLoading,
    setTutorCourseListData,
    setTutorCourseListMessage,
    setClearTutorCourseList,

    setTutorCourseDetailLoading,
    setTutorCourseDetailData,
    setTutorCourseDetailMessage,
    setClearTutorCourseDetail,

    setCourseTemplateListLoading,
    setCourseTemplateList,
    setCourseTemplateListErrorMsg,
    setClearCourseTemplateList,

    setCourseTemplateDetailLoading,
    setCourseTemplateDetail,
    setCourseTemplateDetailErrorMsg,
    setClearCourseTemplateDetail,

    setCourseTemplateDetailPayload,
    setClearCourseTemplateDetailPayload,

    setCourseTagListLoading,
    setCourseTagList,
    setCourseTagListErrorMsg,
    setClearCourseTagList,

    setAddCourseTagDetailLoading,
    setAddCourseTagDetail,
    setAddCourseTagDetailErrorMsg,
    setClearAddCourseTagDetail,

    setCourseTutorListLoading,
    setCourseTutorList,
    setCourseTutorListErrorMsg,
    setClearCourseTutorList,

    setCourseTutorDetailLoading,
    setCourseTutorDetail,
    setCourseTutorDetailErrorMsg,
    setClearCourseTutorDetail,

    setCourseProfListLoading,
    setCourseProfList,
    setCourseProfListErrorMsg,
    setClearCourseProficiencyList,

    setAddCourseProfDetailLoading,
    setAddCourseProfDetail,
    setAddCourseProfDetailErrorMsg,
    setClearAddCourseProfDetail,

    setCourseLanguageListLoading,
    setCourseLanguageList,
    setCourseLanguageListErrorMsg,
    setClearCourseLanguageList,

    setAddCourseLanguageDetailLoading,
    setAddCourseLanguageDetail,
    setAddCourseLanguageDetailErrorMsg,
    setClearAddCourseLanguageDetail,

    setCourseAvailDetailLoading,
    setCourseAvailDetail,
    setCourseAvailErrorMsg,
    setClearCourseAvailDetail,

    setAddCourseAvailDetailLoading,
    setAddCourseAvailDetail,
    setAddCourseAvailDetailErrorMsg,
    setClearAddCourseAvailDetail,

    setAddCourseAvailDetailPayload,
    setClearAddCourseAvailDetailPayload,

    setCoursePriceDetailLoading,
    setCoursePriceDetail,
    setCoursePriceDetailErrorMsg,
    setClearCoursePriceDetail,

    setAddCoursePriceDetailLoading,
    setAddCoursePriceDetail,
    setAddCoursePriceDetailErrorMsg,
    setClearAddCoursePriceDetail,

    setCoursePreviewDetailLoading,
    setCoursePreviewDetailData,
    setCoursePreviewDetailMessage,
    setClearCoursePreviewDetail,

    setCloneCourseDetailLoading,
    setCloneCourseDetailData,
    setCloneCourseDetailMessage,
    setClearCloneCourseDetail,

    setNextCourseSessionDetailLoading,
    setNextCourseSessionDetailData,
    setNextCourseSessionDetailMessage,
    resetNextCourseSessionDetail,

    setNextCourseSessionDetailPayload,
    resetNextCourseSessionDetailPayload,

    setCourseCommentListLoading,
    setCourseCommentListData,
    setCourseCommentListMessage,
    resetCourseCommentList,

    setAddCourseCommentDetailLoading,
    setAddCourseCommentDetailMessage,
    resetAddCourseCommentDetail,

    // camera recording status
    setCameraStatus,
    resetCameraStatus,

    setFilterProps,
    setClearFilterProps,
} = course.actions

export default course.reducer