import { OffersState } from "pages/auth/offersAndDiscount/store/types"

export const INITIAL_STATE: OffersState = {
    discounts: [
        {
            name: "Rs. 200 off",
            discount: 200.0,
            code: "EDUSANTA",
            termsAndConditions: [
                {
                    title: "heading1",
                    content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam ullam numquam reprehenderit provident, et nemo aperiam doloribus deleniti. Voluptas, ipsam? Voluptatem unde eligendi vero a sapiente cupiditate ea magnam, nemo, quod molestiae, illo voluptates facilis."
                },
                {
                    title: "heading2",
                    content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam ullam numquam reprehenderit provident, et nemo aperiam doloribus deleniti. Voluptas, ipsam? Voluptatem unde eligendi vero a sapiente cupiditate ea magnam, nemo, quod molestiae, illo voluptates facilis."
                },
                {
                    title: "heading3",
                    content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam ullam numquam reprehenderit provident, et nemo aperiam doloribus deleniti. Voluptas, ipsam? Voluptatem unde eligendi vero a sapiente cupiditate ea magnam, nemo, quod molestiae, illo voluptates facilis."
                },

            ]
        },
        {
            name: "Rs. 200 off",
            discount: 200.0,
            code: "EDUSANTA",
            termsAndConditions: [
                {
                    title: "heading1",
                    content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam ullam numquam reprehenderit provident, et nemo aperiam doloribus deleniti. Voluptas, ipsam? Voluptatem unde eligendi vero a sapiente cupiditate ea magnam, nemo, quod molestiae, illo voluptates facilis."
                },
                {
                    title: "heading2",
                    content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam ullam numquam reprehenderit provident, et nemo aperiam doloribus deleniti. Voluptas, ipsam? Voluptatem unde eligendi vero a sapiente cupiditate ea magnam, nemo, quod molestiae, illo voluptates facilis."
                },
                {
                    title: "heading3",
                    content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam ullam numquam reprehenderit provident, et nemo aperiam doloribus deleniti. Voluptas, ipsam? Voluptatem unde eligendi vero a sapiente cupiditate ea magnam, nemo, quod molestiae, illo voluptates facilis."
                },

            ]
        },


    ]
}