export const downloadActions = {
    TITLE: {
        action: "title"
    },
    SUB_TITLE: {
        action: "subTitle"
    },
    TITLE_ERROR: {
        action: "title_error"
    },
    INITIAL_STATE: {
        action: "initial_state"
    }
}

export const downloadReducer = (state, action) => {
    switch (action.type) {
        case downloadActions.TITLE.action:
            return { ...state, title: action.payload }

        case downloadActions.SUB_TITLE.action:
            return { ...state, subTitle: action.payload }

        case downloadActions.TITLE_ERROR.action:
            return { ...state, title_error: action.payload }

        case downloadActions.INITIAL_STATE.action:
            return state;

        default:
            return state;
    }
}