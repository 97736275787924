import { useEffect } from 'react';
import { cn } from "utils/cn.utils";

import { FiUser } from 'react-icons/fi';
import { MdOutlineEmail } from 'react-icons/md';
import { BsCheckAll } from 'react-icons/bs';
import { MdCancel } from 'react-icons/md';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setJoinUserPaymentMethodDetailPayload } from 'redux/payment/payment.slice';

import { validateEmail } from 'utils/validation-functions';


const UserInfoForm = ({ error, setError }) => {
    const { joinUserPaymentMethodDetail } = useAppState(s => s.payment)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (joinUserPaymentMethodDetail?.payload?.email && !validateEmail(joinUserPaymentMethodDetail?.payload?.email)) {
            setError({ ...error, emailError: 'Enter valid Email!' });
        } else {
            setError({ ...error, emailError: '' });
        }
    }, [joinUserPaymentMethodDetail?.payload?.email])

    const handleOnChange = (event) => {
        dispatcher(setJoinUserPaymentMethodDetailPayload({
            ...joinUserPaymentMethodDetail?.payload,
            [event.target.name]: event.target.value
        }))
    }

    return (
        <div className={"flex flex-col gap-2"}>
            <div className={"flex items-center gap-2"}>
                <div className={"w-full border-2 border-divider-medium bg-white rounded-md py-1 px-2 flex items-center gap-2"}>
                    <FiUser className={"text-text-500 text-base"} />
                    <input
                        className={"text-text-900 text-base font-bodyPri w-full focus:outline-none bg-white"}
                        type='text'
                        name='name'
                        placeholder='Enter your name'
                        value={joinUserPaymentMethodDetail?.payload?.name}
                        onChange={handleOnChange}
                    />
                </div>
            </div>
            <div className={cn(
                "w-full border-2 border-divider-medium bg-white rounded-md py-1 px-2 flex items-center justify-between gap-2"
            )}>
                <div className={"flex items-center justify-start gap-2"}>
                    <MdOutlineEmail className={"text-text-500 text-base"} />
                    <input
                        className={"text-text-900 text-base font-bodyPri w-full focus:outline-none bg-white"}
                        type='email'
                        name='email'
                        placeholder='Enter your email'
                        value={joinUserPaymentMethodDetail?.payload?.email}
                        onChange={handleOnChange}
                    />
                </div>
                {joinUserPaymentMethodDetail?.payload?.email && error.emailError.length === 0 && <BsCheckAll className={"text-green-500 text-base"} />}
                {joinUserPaymentMethodDetail?.payload?.email && error.emailError.length > 0 && <MdCancel className={"text-red-800 text-base"} />}
            </div>
        </div>
    )
}

export default UserInfoForm