import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { lmsModalsQueryString } from 'pages/auth/dashboard/data';
import HeroCard from "pages/auth/dashboard/components/HeroCard";
import { DashboardStatistics } from "pages/auth/dashboard/components/DashboardStatistics";
import { MyStudents } from "pages/auth/dashboard/components/MyStudents";
import { MyOfferings } from "pages/auth/dashboard/components/MyOfferings";
import { UpcomingClasses } from "pages/auth/dashboard/components/UpcomingClasses";
import CalendarComponent from "pages/auth/dashboard/components/CalendarComponent";
import { GlobalClock } from "pages/auth/dashboard/components/GlobalClocks";
import DashboardSideOverPanel from "pages/auth/dashboard/components/DashboardSideOverPanel";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useTitle } from 'hooks/useTitle';
import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';

const DashboardPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const [title, setTitle] = useTitle()

    const query = generateQueryParams(location.search)

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.DASHBOARD))
    }, [dispatcher])

    useEffect(() => {
        if (user?.user) {
            setTitle({
                ...title,
                title: `${user?.user?.firstName}, Welcome to Your Edulyte Dashboard`
            })
        }
    }, [user?.user])

    useEffect(() => {
        if (query && query?.action) {
            if (query?.action === lmsModalsQueryString.createQuiz.key) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.CREATE_LMS_QUIZ_MODAL.stateKey]: true
                }))
            } else if (query?.action === lmsModalsQueryString.createCourse.key) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.CREATE_LMS_COURSE_MODAL.stateKey]: true
                }))
            } else if (query?.action === lmsModalsQueryString.createQuestion.key) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.CREATE_LMS_QUESTION_MODAL.stateKey]: true
                }))
            }
        }
    }, [query?.action])

    return (
        <div className={"h-full w-full p-3 mx-auto min-h-screen"}>
            <div className={"grid grid-cols-12 gap-3"}>
                <div className="col-span-full lg:col-span-8 space-y-3">
                    <HeroCard />
                    <DashboardSideOverPanel />
                    <DashboardStatistics />
                    <div className="grid grid-cols-12 lg:space-x-3">
                        <div className="col-span-12">
                            <MyStudents />
                        </div>
                    </div>
                    <MyOfferings />
                </div>
                <div className="col-span-full lg:col-span-4 w-full h-full space-y-3">
                    <UpcomingClasses />
                    <CalendarComponent />
                    <GlobalClock />
                </div>
            </div>
        </div>
    );
};

export default DashboardPage;