import React from "react";

export default function ChatViewSendMessageMenu() {
  return (
    <div className={"h-6 w-6 text-gray-500 cursor-pointer hover:text-secondary-dark hover:rounded-full hover:bg-secondary-light hover:ring-4 hover:ring-secondary-light"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 10V3L4 14h7v7l9-11h-7z"
        />
      </svg>
    </div>
  );
}
