export const workData = [
    {
        step: 1,
        title: 'Find a live class (Group / One-on-One)',
        description: 'Search live class available in your topic and Choose a suitable class from the search results which fits into your schedule.',
        linkText: 'Explore',
        link: '/find-classes',
        imageUrl: 'https://edulyte-website-image.s3.ap-south-1.amazonaws.com/find.gif',
    },
    {
        step: 2,
        title: 'Make a booking',
        description: 'Confirm your lesson(s) by making payment securely, get in touch with a tutor if you have any question. Group classes have predefined schedule displayed on the class.',
        linkText: 'See tutors',
        link: '/find-tutors',
        imageUrl: 'https://edulyte-website-image.s3.ap-south-1.amazonaws.com/booking.gif',
    },
    {
        step: 3,
        title: 'Start learning',
        description: 'Take the lesson anywhere on your smartphone or computer. Our friendly support team is available to help you every step of the way.',
        linkText: 'Explore classes',
        link: '/find-classes',
        imageUrl: 'https://edulyte-website-image.s3.ap-south-1.amazonaws.com/imgpsh_fullsize_anim.gif',
    },
]