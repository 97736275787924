import tw, { styled } from 'twin.macro';
import { Section, SectionContent } from 'components/generalComponent/StyledComponent';

export const Container = tw(Section)`
    bg-white 
`;

export const Content = tw(SectionContent)``;

export const Header = tw.div`
    flex flex-col items-center gap-5 md:mx-16 lg:mx-32
`;

export const Heading = tw.h3`
    font-heading font-semibold text-3xl text-text-900 text-center
`;

export const ItemContainer = tw.div`
    w-full mt-10 flex flex-col gap-10
`;

export const BlogsSection = tw.div`
    w-full grid grid-cols-1 lg:grid-cols-3 gap-10
`;

export const BlogItemContainer = tw.div`
    shadow-2xl rounded col-span-1 flex flex-col
     md:flex-row lg:flex-col overflow-hidden cursor-pointer
`;

export const ImageContainer = styled.div`
    background-image: url('${({ imageUrl }) => imageUrl}');
    ${tw`
        bg-cover bg-center h-64 w-full md:(h-full w-64) lg:(h-64 w-full)
        rounded rounded-b-none
    `}
`;

export const TextContainer = tw.div`
    p-5 flex flex[1] flex-col gap-3
`;

export const Title = tw.span`
    font-subHeading font-medium text-lg text-text-900
`;

export const Description = tw.span`
    font-bodyPri font-normal text-sm text-text-800
`;

export const RedirectPath = tw.a`
    font-buttons font-normal text-base text-primary-main
     hover:text-secondary-main cursor-pointer text-center self-center
`;

export const BtnSeeBlogs = tw.a`
    self-center bg-white
    font-buttons font-normal text-base text-primary-main
    px-10 py-1 rounded border-2 border-primary-main text-center
    hover:(border-secondary-main text-secondary-main)
`;
