import { useMemo } from "react";

import { cn } from "utils/cn.utils";
import { Link, useNavigate } from 'react-router-dom'

import { IoIosStats } from 'react-icons/io';
import { MdOutlineVideoCall } from 'react-icons/md';
import { BiCalendar } from 'react-icons/bi'

import MuxVideoPlayer from "components/muxVideoPlayer/MuxVideoPlayer";

import Profile from "./Profile";

import { getFBChat, getFBChatId } from 'redux/firebase/firebase.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setMessageTemplate } from 'redux/firebase/firebase.slice';
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { appointmentPriceModelEnum } from "redux/appointment/appointment.const";

import { pagesInfo } from "utils/pagesInfo";
import { currencyRateConversion } from 'utils/generators.utils';

import { MUX_DEMO_PLAYBACK_ID } from "const/default.const";

const MobileCard = ({ tutor, ...props }) => {
  const { user } = useAppState((s) => s.user)
  const { modal, locals } = useAppState(s => s.local)
  const { session } = useAppState(s => s.auth)
  const { messageTemplate } = useAppState((s) => s.firebase)

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()

  const isMyOffering = useMemo(() => (user?.user?.userId === tutor?.userId), [user?.user?.userId, tutor?.userId])

  const handleMessage = async () => {
    if (!session?.isLoggedIn) {
      navigate(`${pagesInfo.LOG_IN.pagePath}?redirect_url=${window.location.href}`)
      return;
    }

    if (isMyOffering) return;

    const userIdOne = user?.user?.userId
    const userIdTwo = tutor?.userId

    const chatId = await getFBChatId(userIdOne, userIdTwo)
    let fbChat = await getFBChat(chatId)

    if (!fbChat || fbChat === undefined) {
      dispatcher(setMessageTemplate({
        ...messageTemplate,
        isLoading: false,
        userIdOne: userIdOne,
        userIdTwo: userIdTwo,
        tutorUserId: tutor?.userId,
        message: '',
        errorMsg: null
      }))
      dispatcher(setModal({
        ...modal,
        [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: true
      }))
      return;
    }

    navigate(`${pagesInfo.MESSAGES.pagePath}/${fbChat.chatId}`)
  }

  const handleTrialBooking = () => {
    navigate(`${pagesInfo.APPOINTMENT.pagePath}/${tutor?.appointment?.slug}`)
  }

  return (
    <div className={"w-full flex flex-col gap-1.5 md:gap-5"}>
      <div className="w-full h-full sm:w-[36rem] md:w-[28rem] rounded-lg overflow-hidden mx-auto mt-5 px-5">
        <MuxVideoPlayer
          playbackId={tutor?.videoUrl ? tutor?.videoUrl : MUX_DEMO_PLAYBACK_ID}
          isShowButtons={false}
          isButtonDisabled={true}
          autoPlay={false}
          muted={true}
        />
      </div>

      <Profile tutor={tutor} id={props.id} />

      <div className="space-y-3 px-7">
        <div className="flex justify-start items-center text-text-600 font-lg font-bodyPri gap-3">
          {" From "}
          <span className="text-text-900 text-xl font-semibold">
            {currencyRateConversion(locals?.currencyCode, tutor?.price)}
          </span>{"per hour"}

        </div>

        <div className={"flex flex-col gap-5"}>
          <div className="flex flex-col">
            <div className='w-full flex items-center justify-center gap-5'>
              <Link
                to={`${pagesInfo.TUTOR.pagePath}/${tutor?.userId}/classes`}
                className={'w-36 px-2 py-1 flex justify-center items-center border border-secondary-main rounded-lg text-secondary-main font-normal font-buttons hover:bg-secondary-main hover:text-text-50 cursor-pointer'}>
                {"View Classes"}
              </Link>
              <span onClick={handleMessage} className={cn(
                'w-36 px-2 py-1 flex justify-center items-center gap-1 rounded-lg font-normal font-buttons border whitespace-nowrap',
                isMyOffering
                  ? "border-text-300 text-text-300"
                  : "border-primary-dark text-primary-dark hover:bg-primary-dark hover:text-text-50 cursor-pointer"
              )}>
                {"Send Message"}
              </span>
              {/* <span onClick={handleMessage} className={cn(
                'text-3xl',
                isMyOffering
                  ? "text-text-300"
                  : "text-text-700 hover:text-primary-main cursor-pointer"
              )}>
                <FiMessageSquare />
              </span> */}
            </div>
          </div>
          <div className={"flex flex-col gap-3"}>
            <div className={"flex items-start justify-start gap-2"}>
              <IoIosStats className="text-gray-700 text-xl" />
              <span className="text-text-700 font-normal font-bodyPri whitespace-nowrap">
                {"Learner Level:"}
              </span>
              <span className='w-full text-text-900 font-medium font-bodyPri'>
                {tutor?.proficiencies.length > 2 ? tutor?.proficiencies.slice(0, 2).join(" , ") : tutor?.proficiencies.join(" , ")}
              </span>
            </div>
            {tutor?.appointment &&
              <>
                <div className="flex items-start justify-start gap-2">
                  <BiCalendar className="text-gray-500 text-lg" />
                  <div className="text-text-700 font-normal font-bodyPri">{"Availability:"} </div>
                  <div
                    className={"font-medium font-bodyPri text-primary-main underline cursor-pointer"}
                    onClick={handleTrialBooking}
                  >
                    {"See Calendar"}
                  </div>
                </div>
                <div className="flex items-start justify-start text-base font-bodyPri tracking-wide gap-2">
                  <MdOutlineVideoCall className="text-gray-700 text-xl" />
                  <div className="text-text-700 font-normal font-bodyPri">Trial Session: </div>
                  <div className="flex gap-1">
                    {[appointmentPriceModelEnum?.FLAT_FEE?.value]?.includes(tutor?.appointment?.appointment_price?.price_model) &&
                      <span className="text-text-900 font-medium font-bodyPri flex gap-1">
                      {`${tutor?.appointment?.appointment_price?.masterCurrency?.code} ` + parseFloat((tutor?.appointment?.appointment_price?.price - ((tutor?.appointment?.appointment_price?.price * tutor?.appointment?.appointment_price?.discount_pct) / 100)) / 100).toFixed(2)}
                      </span>
                    }
                    {[appointmentPriceModelEnum?.FREE?.value]?.includes(tutor?.appointment?.appointment_price?.price_model) &&
                      <span className="text-text-900 font-medium font-bodyPri flex gap-1">
                        {"Free"}
                      </span>
                    }
                    <span className="text-text-900 font-medium font-bodyPri flex gap-1">
                      ({(tutor?.appointment?.appointment_availability?.duration < 60)
                        ? tutor?.appointment?.appointment_availability?.duration + " min"
                        : (tutor?.appointment?.appointment_availability?.duration / 60)?.toFixed(2) + ' hour(s)'
                      })
                    </span>
                  </div>
                </div>
                <hr />
                <span
                  onClick={handleTrialBooking}
                  className={cn(
                    'w-full py-1 mb-2 flex justify-center items-center rounded-full font-normal font-buttons border',
                    'border-text-700 text-text-700 hover:border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer'
                  )}>
                  {"Book a Trial"}
                </span>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileCard;
