import { createSlice } from "@reduxjs/toolkit";
import { ONBOARDING_INITIAL_STATE } from "redux/onboarding/onboarding.initialState"

const onboarding = createSlice({
    name: "onboarding",
    initialState: ONBOARDING_INITIAL_STATE,
    reducers: {
        // reducers for user onboarding list
        setUserOnboardingListLoading: (state, { payload }) => {
            state.userOnboardingList.isLoading = payload
        },
        setUserOnboardingList: (state, { payload }) => {
            state.userOnboardingList.userOnboardingList = payload
            state.userOnboardingList.errorMsg = ONBOARDING_INITIAL_STATE.userOnboardingList.errorMsg
        },
        setUserOnboardingListErrorMsg: (state, { payload }) => {
            state.userOnboardingList.errorMsg = payload
            state.userOnboardingList.userOnboardingList = ONBOARDING_INITIAL_STATE.userOnboardingList.userOnboardingList
        },
        setClearUserOnboardingList: (state) => {
            state.userOnboardingList = ONBOARDING_INITIAL_STATE.userOnboardingList
        },

        // reducers for update user onboarding list
        setAddUserOnboardingDetailLoading: (state, { payload }) => {
            state.addUserOnboardingDetail.isLoading = payload
        },
        setAddUserOnboardingDetail: (state, { payload }) => {
            state.addUserOnboardingDetail.addUserOnboardingDetail = payload
            state.addUserOnboardingDetail.errorMsg = ONBOARDING_INITIAL_STATE.addUserOnboardingDetail.errorMsg
        },
        setAddUserOnboardingDetailErrorMsg: (state, { payload }) => {
            state.addUserOnboardingDetail.errorMsg = payload
            state.addUserOnboardingDetail.addUserOnboardingDetail = ONBOARDING_INITIAL_STATE.addUserOnboardingDetail.addUserOnboardingDetail
        },
        setClearAddUserOnboardingDetail: (state) => {
            state.addUserOnboardingDetail = ONBOARDING_INITIAL_STATE.addUserOnboardingDetail
        },

        // // reducers for onboarding detail
        // setOnboardingDetailLoading: (state, { payload }) => {
        //     state.onboardingDetail.isLoading = payload
        // },
        // setOnboardingDetail: (state, { payload }) => {
        //     state.onboardingDetail.onboardingDetail = payload
        //     state.onboardingDetail.errorMsg = ONBOARDING_INITIAL_STATE.onboardingDetail.errorMsg
        // },
        // setOnboardingDetailErrorMsg: (state, { payload }) => {
        //     state.onboardingDetail.errorMsg = payload
        //     state.onboardingDetail.onboardingDetail = ONBOARDING_INITIAL_STATE.onboardingDetail.onboardingDetail
        // },
        // setClearOnboardingDetail: (state) => {
        //     state.onboardingDetail = ONBOARDING_INITIAL_STATE.onboardingDetail
        // },

        // reducers for user onboarding detail
        setUserOnboardingDetailLoading: (state, { payload }) => {
            state.userOnboardingDetail.isLoading = payload
        },
        setUserOnboardingDetail: (state, { payload }) => {
            state.userOnboardingDetail.userOnboardingDetail = payload
            state.userOnboardingDetail.errorMsg = ONBOARDING_INITIAL_STATE.userOnboardingDetail.errorMsg
        },
        setUserOnboardingDetailErrorMsg: (state, { payload }) => {
            state.userOnboardingDetail.errorMsg = payload
            state.userOnboardingDetail.userOnboardingDetail = ONBOARDING_INITIAL_STATE.userOnboardingDetail.userOnboardingDetail
        },
        setClearUserOnboardingDetail: (state, { payload }) => {
            state.userOnboardingDetail = ONBOARDING_INITIAL_STATE.userOnboardingDetail
        },

        // reducers for onboarding user detail
        setOnboardingUserDetailLoading: (state, { payload }) => {
            state.onboardingUserDetail.isLoading = payload
        },
        setOnboardingUserDetail: (state, { payload }) => {
            state.onboardingUserDetail.onboardingUserDetail = payload
            state.onboardingUserDetail.errorMsg = ONBOARDING_INITIAL_STATE.onboardingUserDetail.errorMsg
        },
        setOnboardingUserDetailErrorMsg: (state, { payload }) => {
            state.onboardingUserDetail.errorMsg = payload
            state.onboardingUserDetail.onboardingUserDetail = ONBOARDING_INITIAL_STATE.onboardingUserDetail.onboardingUserDetail
        },
        setClearOnboardingUserDetail: (state) => {
            state.onboardingUserDetail = ONBOARDING_INITIAL_STATE.onboardingUserDetail
        },

        // reducers for update user onboarding user detail
        setAddOnboardingUserDetailLoading: (state, { payload }) => {
            state.addOnboardingUserDetail.isLoading = payload
        },
        setAddOnboardingUserDetail: (state, { payload }) => {
            state.addOnboardingUserDetail.addOnboardingUserDetail = payload
            state.addOnboardingUserDetail.errorMsg = ONBOARDING_INITIAL_STATE.addOnboardingUserDetail.errorMsg
        },
        setAddOnboardingUserDetailErrorMsg: (state, { payload }) => {
            state.addOnboardingUserDetail.isLoading = payload
        },
        setClearAddOnboardingUserDetail: (state) => {
            state.addOnboardingUserDetail = ONBOARDING_INITIAL_STATE.addOnboardingUserDetail
        },

        // reducers for question answers
        setAddQuestionAnswerLoading: (state, { payload }) => {
            state.addQuestionAnswer.isLoading = payload
        },
        setAddQuestionAnswer: (state, { payload }) => {
            state.addQuestionAnswer.addQuestionAnswer = payload
            state.addQuestionAnswer.errorMsg = ONBOARDING_INITIAL_STATE.addQuestionAnswer.errorMsg
        },
        setAddQuestionAnswerErrorMsg: (state, { payload }) => {
            state.addQuestionAnswer.errorMsg = payload
            state.addQuestionAnswer.addQuestionAnswer = ONBOARDING_INITIAL_STATE.addQuestionAnswer.addQuestionAnswer
        },
        setClearAddQuestionAnswer: (state) => {
            state.addQuestionAnswer = ONBOARDING_INITIAL_STATE.addQuestionAnswer
        },
        setAddQuestionAnswerPayload: (state, { payload }) => {
            state.addQuestionAnswer.addQuestionAnswerPayload = payload
        },
        setClearQuestionAnswerPayload: (state) => {
            state.addQuestionAnswer.addQuestionAnswerPayload = ONBOARDING_INITIAL_STATE.addQuestionAnswer.addQuestionAnswerPayload
        },

        // user onboarding question detail reducers
        setUserOnboardingQuestionDetailLoading: (state, { payload }) => {
            state.userOnboardingQuestionDetail.isLoading = payload
        },
        setUserOnboardingQuestionDetail: (state, { payload }) => {
            state.userOnboardingQuestionDetail.userOnboardingQuestionDetail = payload
            state.userOnboardingQuestionDetail.errorMsg = ONBOARDING_INITIAL_STATE.userOnboardingQuestionDetail.errorMsg
        },
        setUserOnboardingQuestionDetailErrorMs: (state, { payload }) => {
            state.userOnboardingQuestionDetail.errorMsg = payload
            state.userOnboardingQuestionDetail.userOnboardingQuestionDetail = ONBOARDING_INITIAL_STATE.userOnboardingQuestionDetail.userOnboardingQuestionDetail
        },
        setClearUserOnboardingQuestionDetail: (state) => {
            state.userOnboardingQuestionDetail = ONBOARDING_INITIAL_STATE.userOnboardingQuestionDetail
        },

        // update user onboarding question detail
        setAddUserOnboardingQuestionDetailLoading: (state, { payload }) => {
            state.addUserOnboardingQuestionDetail.isLoading = payload
        },
        setAddUserOnboardingQuestionDetail: (state, { payload }) => {
            state.addUserOnboardingQuestionDetail.addUserOnboardingQuestionDetail = payload
            state.addUserOnboardingQuestionDetail.errorMsg = ONBOARDING_INITIAL_STATE.addUserOnboardingQuestionDetail.errorMsg
        },
        setAddUserOnboardingQuestionDetailErrorMsg: (state, { payload }) => {
            state.addUserOnboardingQuestionDetail.errorMsg = payload
            state.addUserOnboardingQuestionDetail = ONBOARDING_INITIAL_STATE.addUserOnboardingQuestionDetail
        },
        setClearAddUserOnboardingQuestionDetail: (state) => {
            state.addUserOnboardingQuestionDetail = ONBOARDING_INITIAL_STATE.addUserOnboardingQuestionDetail
        },


        // question answers payload
        setAddUserOnboardingQuestionDetailPayload: (state, { payload }) => {
            state.addUserOnboardingQuestionDetail.addQuestionAnswerPayload = payload
        },


        // reducers for document list
        setOnboardingDocListLoading: (state, { payload }) => {
            state.onboardingDocList.isLoading = payload
        },
        setOnboardingDocList: (state, { payload }) => {
            state.onboardingDocList.onboardingDocList = payload
            state.onboardingDocList.errorMsg = ONBOARDING_INITIAL_STATE.onboardingDocList.errorMsg
        },
        setOnboardingDocListErrorMsg: (state, { payload }) => {
            state.onboardingDocList.errorMsg = payload
            state.onboardingDocList.onboardingDocList = ONBOARDING_INITIAL_STATE.onboardingDocList.onboardingDocList
        },
        setClearOnboardingDocList: (state, { payload }) => {
            state.onboardingDocList = ONBOARDING_INITIAL_STATE.onboardingDocList
        },

        // Add Document reducers
        setAddOnboardingDocDetailLoading: (state, { payload }) => {
            state.addOnboardingDocDetail.isLoading = payload
        },
        setAddOnboardingDocDetail: (state, { payload }) => {
            state.addOnboardingDocDetail.addOnboardingDocDetail = payload
            state.addOnboardingDocDetail.errorMsg = ONBOARDING_INITIAL_STATE.addOnboardingDocDetail.errorMsg
        },
        setAddOnboardingDocDetailErrorMsg: (state, { payload }) => {
            state.addOnboardingDocDetail.errorMsg = payload
            state.addOnboardingDocDetail.addOnboardingDocDetail = ONBOARDING_INITIAL_STATE.addOnboardingDocDetail.addOnboardingDocDetail
        },
        setClearAddOnboardingDocDetail: (state) => {
            state.addOnboardingDocDetail = ONBOARDING_INITIAL_STATE.addOnboardingDocDetail
        },

        setAddOnboardingDocDetailPayload: (state, { payload }) => {
            state.addOnboardingDocDetail.addOnboardingDocDetailPayload = payload
        },
        setClearAddOnboardingDocDetailPayload: (state) => {
            state.addOnboardingDocDetail.addOnboardingDocDetailPayload = ONBOARDING_INITIAL_STATE.addOnboardingDocDetail.addOnboardingDocDetailPayload
        },

        // set category list for document
        setDocumentCategoryList: (state, { payload }) => {
            state.categoryList.categoryList = payload
        },
        setClearDocumentCategoryList: (state, { payload }) => {
            state.categoryList = ONBOARDING_INITIAL_STATE.categoryList
        },

        // onboarding interview detail reducers
        setOnboardingInterviewDetailLoading: (state, { payload }) => {
            state.onboardingInterviewDetail.isLoading = payload
        },
        setOnboardingInterviewDetail: (state, { payload }) => {
            state.onboardingInterviewDetail.onboardingInterviewDetail = payload
            state.onboardingInterviewDetail.errorMsg = ONBOARDING_INITIAL_STATE.onboardingInterviewDetail.errorMsg
        },
        setOnboardingInterviewDetailErrorMsg: (state, { payload }) => {
            state.onboardingInterviewDetail.errorMsg = payload
            state.onboardingInterviewDetail.onboardingInterviewDetail = ONBOARDING_INITIAL_STATE.onboardingInterviewDetail.onboardingInterviewDetail
        },
        setClearOnboardingInterviewDetail: (state) => {
            state.onboardingInterviewDetail = ONBOARDING_INITIAL_STATE.onboardingInterviewDetail
        },

        // add onboarding Interview detail reducers
        setAddOnboardingInterviewDetailLoading: (state, { payload }) => {
            state.addOnboardingInterviewDetail.isLoading = payload
        },
        setAddOnboardingInterviewDetailErrorMsg: (state, { payload }) => {
            state.addOnboardingInterviewDetail.errorMsg = payload
        },
        setClearAddOnboardingInterviewDetail: (state) => {
            state.addOnboardingInterviewDetail = ONBOARDING_INITIAL_STATE.addOnboardingInterviewDetail
        },

        // onboarding tutor pref detail reducers
        setOnboardingTutorPrefDetailLoading: (state, { payload }) => {
            state.onboardingTutorPrefDetail.isLoading = payload
        },
        setOnboardingTutorPrefDetail: (state, { payload }) => {
            state.onboardingTutorPrefDetail.onboardingTutorPrefDetail = payload
            state.onboardingTutorPrefDetail.errorMsg = ONBOARDING_INITIAL_STATE.onboardingTutorPrefDetail.errorMsg
        },
        setOnboardingTutorPrefDetailErrorMsg: (state, { payload }) => {
            state.onboardingTutorPrefDetail.errorMsg = payload
            state.onboardingTutorPrefDetail.onboardingTutorPrefDetail = ONBOARDING_INITIAL_STATE.onboardingTutorPrefDetail.onboardingTutorPrefDetail
        },
        setClearOnboardingTutorPrefDetail: (state) => {
            state.onboardingTutorPrefDetail = ONBOARDING_INITIAL_STATE.onboardingTutorPrefDetail
        },

        // update tutor onboarding pref detail
        setAddOnboardingTutorPrefDetailLoading: (state, { payload }) => {
            state.addOnboardingTutorPrefDetail.isLoading = payload
        },
        setAddOnboardingTutorPrefDetailErrorMsg: (state, { payload }) => {
            state.addOnboardingTutorPrefDetail.errorMsg = payload
        },

        // onboarding tutor trial course detail
        setOnboardingTutorTrialCourseDetailLoading: (state, { payload }) => {
            state.onboardingTutorTrialCourseDetail.isLoading = payload
        },
        setOnboardingTutorTrialCourseDetail: (state, { payload }) => {
            state.onboardingTutorTrialCourseDetail.onboardingTutorTrialCourseDetail = payload
            state.onboardingTutorTrialCourseDetail.errorMsg = ONBOARDING_INITIAL_STATE.onboardingTutorTrialCourseDetail.errorMsg
        },
        setOnboardingTutorTrialCourseDetailErrorMsg: (state, { payload }) => {
            state.onboardingTutorTrialCourseDetail.errorMsg = payload
            state.onboardingTutorTrialCourseDetail.onboardingTutorTrialCourseDetail = ONBOARDING_INITIAL_STATE.onboardingTutorTrialCourseDetail.onboardingTutorTrialCourseDetail
        },
        setClearOnboardingTutorTrialCourseDetail: (state) => {
            state.onboardingTutorTrialCourseDetail = ONBOARDING_INITIAL_STATE.onboardingTutorTrialCourseDetail
        },

        // update onboarding tutor trial course
        setAddOnboardingTutorTrialCourseDetailLoading: (state, { payload }) => {
            state.addOnboardingTutorTrialCourseDetail.isLoading = payload
        },
        setAddOnboardingTutorTrialCourseDetail: (state, { payload }) => {
            state.addOnboardingTutorTrialCourseDetail.addOnboardingTutorTrialCourseDetail = payload
            state.addOnboardingTutorTrialCourseDetail.errorMsg = ONBOARDING_INITIAL_STATE.addOnboardingTutorTrialCourseDetail.errorMsg
        },
        setAddOnboardingTutorTrialCourseDetailErrorMsg: (state, { payload }) => {
            state.addOnboardingTutorTrialCourseDetail.errorMsg = payload
            state.addOnboardingTutorTrialCourseDetail.addOnboardingTutorTrialCourseDetail = ONBOARDING_INITIAL_STATE.addOnboardingTutorTrialCourseDetail.addOnboardingTutorTrialCourseDetail
        },
        setClearAddOnboardingTutorTrialCourseDetail: (state) => {
            state.addOnboardingTutorTrialCourseDetail = ONBOARDING_INITIAL_STATE.addOnboardingTutorTrialCourseDetail
        },

        // onboarding comment list reducers
        setOnboardingCommentListLoading: (state, { payload }) => {
            state.onboardingCommentList.isLoading = payload
        },
        setOnboardingCommentListData: (state, { payload }) => {
            state.onboardingCommentList.data = payload
            state.onboardingCommentList.message = ONBOARDING_INITIAL_STATE.onboardingCommentList.message
        },
        setOnboardingCommentListMessage: (state, { payload }) => {
            state.onboardingCommentList.message = payload
            state.onboardingCommentList.data = ONBOARDING_INITIAL_STATE.onboardingCommentList.data
        },
        resetOnboardingCommentList: (state) => {
            state.onboardingCommentList = ONBOARDING_INITIAL_STATE.onboardingCommentList
        },

        // reducers for add onboarding comments
        setAddOnboardingCommentDetailLoading: (state, { payload }) => {
            state.addOnboardingCommentDetail.isLoading = payload
        },
        setAddOnboardingCommentDetailMessage: (state, { payload }) => {
            state.addOnboardingCommentDetail.message = payload
        },
        resetAddOnboardingCommentDetail: (state) => {
            state.addOnboardingCommentDetail = ONBOARDING_INITIAL_STATE.addOnboardingCommentDetail
        },

        // reference list reducers (No use)
        setReferenceListLoading: (state, { payload }) => {
            state.referenceList.isLoading = payload
        },
        setReferenceList: (state, { payload }) => {
            state.referenceList.referenceList = payload
            state.referenceList.errorMsg = ONBOARDING_INITIAL_STATE.referenceList.errorMsg
        },
        setReferenceListErrorMsg: (state, { payload }) => {
            state.referenceList.errorMsg = payload
            state.referenceList.referenceList = ONBOARDING_INITIAL_STATE.referenceList.referenceList
        },
        setClearReferenceList: (state) => {
            state.referenceList = ONBOARDING_INITIAL_STATE.referenceList
        },

        // Add professional references reducers
        setAddReferenceLoading: (state, { payload }) => {
            state.addReference.isLoading = payload
        },
        setAddReference: (state, { payload }) => {
            state.addReference.addReference = payload
            state.addReference.errorMsg = ONBOARDING_INITIAL_STATE.addReference.errorMsg
        },
        setAddReferencePayload: (state, { payload }) => {
            state.addReference.addReferencePayload = payload
        },
        setAddReferenceErrorMsg: (state, { payload }) => {
            state.addReference.errorMsg = payload
            state.addReference.addReference = ONBOARDING_INITIAL_STATE.addReference.addReference
        },
        setClearAddReference: (state) => {
            state.addReference = ONBOARDING_INITIAL_STATE.addReference
        },
    }
})

export const {
    setUserOnboardingListLoading,
    setUserOnboardingList,
    setUserOnboardingListErrorMsg,
    setClearUserOnboardingList,

    setAddUserOnboardingDetailLoading,
    setAddUserOnboardingDetail,
    setAddUserOnboardingDetailErrorMsg,
    setClearAddUserOnboardingDetail,

    // setOnboardingDetailLoading,
    // setOnboardingDetail,
    // setOnboardingDetailErrorMsg,
    // setClearOnboardingDetail,

    setUserOnboardingDetailLoading,
    setUserOnboardingDetail,
    setUserOnboardingDetailErrorMsg,
    setClearUserOnboardingDetail,

    setOnboardingUserDetailLoading,
    setOnboardingUserDetail,
    setOnboardingUserDetailErrorMsg,
    setClearOnboardingUserDetail,

    setAddOnboardingUserDetailLoading,
    setAddOnboardingUserDetail,
    setAddOnboardingUserDetailErrorMsg,
    setClearAddOnboardingUserDetail,

    setUserOnboardingQuestionDetailLoading,
    setUserOnboardingQuestionDetail,
    setUserOnboardingQuestionDetailErrorMsg,
    setClearUserOnboardingQuestionDetail,

    setAddUserOnboardingQuestionDetailLoading,
    setAddUserOnboardingQuestionDetail,
    setAddUserOnboardingQuestionDetailErrorMsg,
    setClearAddUserOnboardingQuestionDetail,

    setOnboardingDocListLoading,
    setOnboardingDocList,
    setOnboardingDocListErrorMsg,
    setClearOnboardingDocList,

    setAddOnboardingDocDetailLoading,
    setAddOnboardingDocDetail,
    setAddOnboardingDocDetailErrorMsg,
    setClearAddOnboardingDocDetail,
    setAddOnboardingDocDetailPayload,
    setClearAddOnboardingDocDetailPayload,

    setDocumentCategoryList,
    setClearDocumentCategoryList,

    setOnboardingInterviewDetailLoading,
    setOnboardingInterviewDetail,
    setOnboardingInterviewDetailErrorMsg,
    setClearOnboardingInterviewDetail,

    setAddOnboardingInterviewDetailLoading,
    setAddOnboardingInterviewDetailErrorMsg,
    setClearAddOnboardingInterviewDetail,

    setOnboardingTutorPrefDetailLoading,
    setOnboardingTutorPrefDetail,
    setOnboardingTutorPrefDetailErrorMsg,
    setClearOnboardingTutorPrefDetail,

    setAddOnboardingTutorPrefDetailLoading,
    setAddOnboardingTutorPrefDetailErrorMsg,

    setOnboardingTutorTrialCourseDetailLoading,
    setOnboardingTutorTrialCourseDetail,
    setOnboardingTutorTrialCourseDetailErrorMsg,
    setClearOnboardingTutorTrialCourseDetail,

    setAddOnboardingTutorTrialCourseDetailLoading,
    setAddOnboardingTutorTrialCourseDetail,
    setAddOnboardingTutorTrialCourseDetailErrorMsg,
    setClearAddOnboardingTutorTrialCourseDetail,

    setOnboardingCommentListLoading,
    setOnboardingCommentListData,
    setOnboardingCommentListMessage,
    resetOnboardingCommentList,

    setAddOnboardingCommentDetailLoading,
    setAddOnboardingCommentDetailMessage,
    resetAddOnboardingCommentDetail,

    setReferenceListLoading,
    setReferenceList,
    setReferenceListErrorMsg,

    setAddReferenceLoading,
    setAddReference,
    setAddReferenceErrorMsg,
    setAddReferencePayload,
    setClearAddReference,
} = onboarding.actions;

export default onboarding.reducer;