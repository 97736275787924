import { useState } from 'react';

import UserLanguage from "pages/auth/profile/aboutInformation/UserLanguage";
import UserElevatorPitch from "pages/auth/profile/aboutInformation/UserElevatorPitch";
import About from "pages/auth/profile/aboutInformation/About";
import MyTeachingStyle from "pages/auth/profile/aboutInformation/MyTeachingStyle";
import MyLearningStyle from "pages/auth/profile/aboutInformation/MyLearningStyle";

import { useAppState } from 'hooks/useStore';
import { userRoles } from 'redux/auth/auth.const';

const AboutInformation = () => {
    const { locals } = useAppState((state) => state.local)

    const [editBtnConst, setEditBtnConst] = useState(null)

    return (
        <div className={'space-y-5 p-5 border border-background-light rounded shadow'}>
            <UserLanguage
                editBtnConst={editBtnConst}
                setEditBtnConst={setEditBtnConst}
            />
            <UserElevatorPitch
                editBtnConst={editBtnConst}
                setEditBtnConst={setEditBtnConst}
            />
            <About
                editBtnConst={editBtnConst}
                setEditBtnConst={setEditBtnConst}
            />
            {(locals.userRole === userRoles.STUDENT.value) &&
                <MyLearningStyle
                    editBtnConst={editBtnConst}
                    setEditBtnConst={setEditBtnConst}
                />
            }
            {(locals.userRole === userRoles.TUTOR.value) &&
                <MyTeachingStyle
                    editBtnConst={editBtnConst}
                    setEditBtnConst={setEditBtnConst}
                />
            }
        </div>
    )
}

export default AboutInformation;