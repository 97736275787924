import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
import { database } from "config/firebase.config"

export default async function updateNotification(muted, selectedChat, user) {
  const chatId = selectedChat.id;
  const { userId } = user;
  const notificationRef = doc(database, "groupnotifications", chatId);
  if (muted) {
    await updateDoc(notificationRef, {
      muted: arrayRemove(userId),
    });
    return;
  }
  await updateDoc(notificationRef, {
    muted: arrayUnion(userId),
  });
}
