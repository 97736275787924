import { cn } from "utils/cn.utils";

const SocialMediaIcons = ({ socialIcons }) => {

    return (
        <a
            className={cn(
                "text-text-400 cursor-pointer rounded-lg",
                `transition delay-150 ease-in-out duration-75 hover:scale-90`
            )}
            href={socialIcons?.path}
            target={'_blank'}
        >
            <socialIcons.icon size={40} className={`text-${socialIcons?.color}`} />
        </a>
    )
}

export default SocialMediaIcons