import { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import { PageContentStyle } from "pages/auth/orderConfirmation/OrderConfirmationPageStyle";

import FullPageLoader from 'components/loader/FullPageLoader';

import PendingOrderConfirmation from 'pages/auth/orderConfirmation/components/PendingOrderConfirmation';
import ConfirmedOrderConfirmation from 'pages/auth/orderConfirmation/components/ConfirmedOrderConfirmation';
import FailedOrderConfirmation from 'pages/auth/orderConfirmation/components/FailedOrderConfirmation';
import NotExistOrderConfirmation from 'pages/auth/orderConfirmation/components/NotExistOrderConfirmation';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { confirmationOrderDetail } from 'redux/order/order.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { resetConfirmationOrder } from 'redux/order/order.slice';
import { orderStatusEnum } from 'redux/order/order.const';

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';

import { useTitle } from 'hooks/useTitle';


const OrderConfirmationPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { confirmationOrder } = useAppState((state) => state.order)

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const navigate = useNavigate()
    const [title, setTitle] = useTitle()

    const from = location.state?.from?.url || pagesInfo.DASHBOARD.pagePath;

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.ORDER_CONFIRMATION))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Thank You for Your Order | Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        return () => {
            dispatcher(resetConfirmationOrder())
        }
    }, [])

    useEffect(() => {
        let query = generateQueryParams(location.search)

        if (!query?.order_id) {
            navigate(from, { replace: true })
        } else if (query?.order_id) {
            dispatcher(confirmationOrderDetail(query?.order_id))
        }

    }, [location.search])

    if (confirmationOrder?.isLoading) {
        return (
            <FullPageLoader isLoading={true} />
        )
    }

    if (confirmationOrder?.message) {
        return (
            <PageContentStyle>
                <FailedOrderConfirmation />
            </PageContentStyle>
        )
    }

    return confirmationOrder?.data ? (
        <PageContentStyle>
            {[orderStatusEnum.PENDING.value, orderStatusEnum.PROCESSING.value].includes(confirmationOrder?.data?.status) &&
                <PendingOrderConfirmation />
            }
            {[orderStatusEnum.CONFIRMED.value, orderStatusEnum.REFUNDED.value, orderStatusEnum.CANCELLED.value].includes(confirmationOrder?.data?.status) &&
                <ConfirmedOrderConfirmation />
            }
            {[orderStatusEnum.FAILED.value].includes(confirmationOrder?.data?.status) &&
                <FailedOrderConfirmation />
            }
            {confirmationOrder?.data?.status === "not_exist" &&
                <NotExistOrderConfirmation />
            }
        </PageContentStyle>
    ) : null
};

export default OrderConfirmationPage;
