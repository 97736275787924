import { toast } from 'react-toastify';

import reviewService from "redux/review/review.services";
import {
    setTutorReviewLoading,
    setTutorReviewList,
    setTutorReviewErrorMsg,

    setUserReviewListLoading,
    setUserReviewList,
    setUserReviewListErrorMsg,

    setAddUserReviewDetailLoading,
    setAddUserReviewDetail,
    setAddUserReviewDetailErrorMsg
} from "redux/review/review.slice";


export const getTutorReviewListByTutorId = (tutorId) => async (dispatch) => {
    dispatch(setTutorReviewLoading(true))
    const requestData = {
        params: { tutorId: tutorId },
        query: { status: "published" }
    }
    try {
        const response = await reviewService.getTutorReviewListByTutorId(requestData)
        if (response.status === 200) {
            dispatch(setTutorReviewList(response.data))
        } else {
            dispatch(setTutorReviewErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error)
        dispatch(setTutorReviewErrorMsg(error.message))
    } finally {
        dispatch(setTutorReviewLoading(false))
    }
}

export const getPublicUserReviewListByUserId = (userId, query) => async (dispatch) => {
    dispatch(setUserReviewListLoading(true))
    const requestData = {
        params: { userId: userId },
        query: query
    }
    try {
        const response = await reviewService.getPublicUserReviewListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserReviewList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        dispatch(setUserReviewListErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
    } finally {
        dispatch(setUserReviewListLoading(false))
    }
}

export const createUserReviewDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddUserReviewDetailLoading(true))

    try {
        // const { tutorReviewList } = getState().review
        const requestData = {
            body: body
        }
        const response = await reviewService.createUserReviewDetail(requestData)
        if (response.status === 200) {
            dispatch(setAddUserReviewDetail(response.data.data))
            dispatch(getPublicUserReviewListByUserId(body?.receiverUserId))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        dispatch(setAddUserReviewDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
    } finally {
        dispatch(setAddUserReviewDetailLoading(false))
    }
}