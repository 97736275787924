export const url = {
    SEND_OTP: "/notifications/send-otp",
    VERIFY_OTP: "/notifications/verify-otp",
    SEND_MESSAGE_EMAIL: "/notifications/send-message",
    GET_NOTIFICATION_LIST: '/notifications/notifications',
    ACKNOWLEDGED_NOTIFICATION: '/notifications/notifications'
}

export const notificationWsUrl = {
    NOTIFICATION_URL: "/notifications/notifications"
}

export const otpStatus = {
    SUBMITTED: {
        label: "Submitted",
        value: "approved"
    },
    PENDING: {
        label: "Pending",
        value: "pending"
    },
    EXPIRED: {
        label: "Expired",
        value: "expired"
    }
}

export const otpDeliveryMethod = {
    EMAIL: {
        label: "Email",
        value: "email"
    },
    SMS: {
        label: "Sms",
        value: "sms"
    }
}