import { useEffect } from "react";

import ComponentLoader from "components/loader/ComponentLoader";
import MuxVideoPlayer from "components/muxVideoPlayer/MuxVideoPlayer";
import MyTooltip from "components/Tooltip";

import { onboardingTooltipConst } from "pages/auth/onboarding/data";

import { getTutorUserDetailByUserId, updateTutorUserDetailByUserId } from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { resetUploadFile, setUploadFilePayload } from "redux/storage/storage.slice";
import { setClearAddTutorUserDetail } from "redux/tutor/tutor.slice";
import { modalConst } from "redux/local/local.const";
import { fileCategories } from "redux/storage/storage.const";

import { MUX_DEMO_PLAYBACK_ID } from "const/default.const";


const MyIntroVideo = () => {
    const { modal } = useAppState((state) => state.local)
    const { uploadFile } = useAppState((state) => state.storage)
    const { user } = useAppState((state) => state.user)
    const { tutorUserDetail, addTutorUserDetail } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (addTutorUserDetail?.addTutorUserDetail?.videoUrl) {
            dispatcher(setClearAddTutorUserDetail())
            dispatcher(setModal({
                ...modal,
                [modalConst.RECORD_VIDEO_MODAL.stateKey]: false,
                [modalConst.UPLOAD_VIDEO_MODAL.stateKey]: false
            }))
        }
    }, [addTutorUserDetail?.addTutorUserDetail?.videoUrl])


    useEffect(() => {
        if (uploadFile?.data && uploadFile?.data?.length > 0 && (uploadFile?.payload?.fileCategory === fileCategories.INTRO_VIDEO.value)) {
            dispatcher(updateTutorUserDetailByUserId(user?.user?.userId, { videoUrl: uploadFile?.data[0]?.fileUrl }))
            dispatcher(resetUploadFile())
        }
    }, [uploadFile?.data])

    const onHandleRefresh = () => {
        dispatcher(getTutorUserDetailByUserId(user?.user?.userId))
    }

    const onHandleUploadVideo = () => {
        dispatcher(resetUploadFile())
        dispatcher(setUploadFilePayload({
            ...uploadFile?.payload,
            fileCategory: fileCategories.INTRO_VIDEO.value
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.UPLOAD_VIDEO_MODAL.stateKey]: true
        }))
    }

    const onHandleRecordVideo = () => {
        dispatcher(resetUploadFile())
        dispatcher(setUploadFilePayload({
            ...uploadFile?.payload,
            fileCategory: fileCategories.INTRO_VIDEO.value
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.RECORD_VIDEO_MODAL.stateKey]: true
        }))
    }

    return (
        <div className={"w-full flex items-center justify-center py-5"}>
            <div className={"flex flex-col items-center justify-center space-y-3"}>
                <div className={"w-full font-bodyPri font-normal text-text-800 text-base tracking-wide text-center"}>
                    {"Mute device while recording to avoid echo. Playback for quality check, submit for processing. Wait a few seconds, then refresh."}
                </div>
                {(tutorUserDetail?.isLoading || addTutorUserDetail?.isLoading) &&
                    <ComponentLoader isLoading={tutorUserDetail?.isLoading || addTutorUserDetail?.isLoading} />
                }
                {(!tutorUserDetail?.isLoading && !addTutorUserDetail?.isLoading) &&
                    <div className={"w-full flex items-start justify-center gap-1"}>
                        <div className={"w-full md:w-[25rem]"}>
                            <MuxVideoPlayer
                                playbackId={addTutorUserDetail?.payload?.videoUrl ? addTutorUserDetail?.payload?.videoUrl : MUX_DEMO_PLAYBACK_ID}
                                isShowRefreshButton={true}
                                onHandleRefresh={onHandleRefresh}
                                handleUploadVideo={onHandleUploadVideo}
                                handleRecordVideo={onHandleRecordVideo}
                            />
                        </div>
                        <MyTooltip
                            tooltip={onboardingTooltipConst.videoTooltip}
                        />
                    </div>
                }

            </div>
        </div>
    )
}

export default MyIntroVideo;