export const amountOptionList = {
    USD: [
        {
            label: '100',
            amount: 100,
        },
        {
            label: '200',
            amount: 200,
        },
        {
            label: '300',
            amount: 300,
        },
        {
            label: '500',
            amount: 500,
        },
        {
            label: '800',
            amount: 800,
        },
        {
            label: '1000',
            amount: 1000,
        },
        {
            label: '1200',
            amount: 1200,
        },
        {
            label: '1500',
            amount: 1500,
        },
    ],
    AUD: [
        {
            label: '100',
            amount: 100,
        },
        {
            label: '200',
            amount: 200,
        },
        {
            label: '300',
            amount: 300,
        },
        {
            label: '500',
            amount: 500,
        },
        {
            label: '800',
            amount: 800,
        },
        {
            label: '1000',
            amount: 1000,
        },
        {
            label: '1200',
            amount: 1200,
        },
        {
            label: '1500',
            amount: 1500,
        },
    ],
    INR: [
        {
            label: '100',
            amount: 100,
        },
        {
            label: '200',
            amount: 200,
        },
        {
            label: '300',
            amount: 300,
        },
        {
            label: '500',
            amount: 500,
        },
        {
            label: '800',
            amount: 800,
        },
        {
            label: '1000',
            amount: 1000,
        },
        {
            label: '1200',
            amount: 1200,
        },
        {
            label: '1500',
            amount: 1500,
        },
    ]
}