import { cn } from "utils/cn.utils";

import QuestionSidebarItems from 'pages/auth/edulyteLms/editLmsPages/editLmsQuestion/components/questionSidebar/components/QuestionSidebarItems';

const QuestionSidebar = () => {
    return (
        <div
            className={cn(
                "flex flex-col w-full bg-white p-5 rounded-lg overflow-hidden overflow-y-scroll transition ease-in-out delay-150 duration-300",
                "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-light",
            )}
            style={{ height: 'calc(100vh - 20px)' }}
        >
            <QuestionSidebarItems />
        </div>
    )
}

export default QuestionSidebar;