import { cn } from "utils/cn.utils";
import ButtonLoader from 'components/loader/ButtonLoader';

const SaveAndCancelBtn = ({
    isLoading = false,
    isNextBtnLoading = false,
    btnName = "Save",
    deleteBtnLabel = "Delete",
    nextBtnLabel = "Next",
    isNextBtnDisabled = false,
    isDeleteBtnDisabled = false,
    isResetBtnDisabled = false,
    isCancelBtnDisabled = false,
    isSaveBtnDisabled = false,
    isShowDeleteBtn = false,
    isShowResetBtn = false,
    isShowCancelBtn = false,
    isShowNextBtn = false,
    onDelete,
    onReset,
    onCancel,
    onSave,
    onNext
}) => {

    return (
        <div className={"flex items-center justify-center gap-3"}>
            {isShowDeleteBtn &&
                <div onClick={onDelete} className={cn(
                    "flex item-center justify-center px-3 py-1 rounded-md",
                    "font-bodyPri font-normal text-base",
                    isDeleteBtnDisabled && "bg-text-300 text-text-50 hover:bg-text-300 cursor-not-allowed",
                    !isDeleteBtnDisabled && "text-text-50 bg-red-500 hover:bg-red-700 hover:text-text-50 cursor-pointer"
                )}>
                    <span className={"whitespace-nowrap"}>
                        {deleteBtnLabel}
                    </span>
                </div>
            }
            {isShowResetBtn &&
                <div onClick={onReset} className={cn(
                    "flex item-center justify-center px-3 py-1 rounded-md",
                    "font-bodyPri font-normal text-base",
                    isResetBtnDisabled && "bg-text-300 text-text-50 hover:bg-text-300 cursor-not-allowed",
                    !isResetBtnDisabled && "text-text-800 bg-background-darkLight hover:bg-background-medium hover:text-text-900 cursor-pointer"
                )}>
                    <span>
                        {"Reset"}
                    </span>
                </div>
            }

            {isShowCancelBtn &&
                <div onClick={onCancel} className={cn(
                    "flex item-center justify-center px-3 py-1 rounded-md",
                    "font-bodyPri font-normal text-base",
                    isCancelBtnDisabled && "bg-text-300 text-text-50 hover:bg-text-300 cursor-not-allowed",
                    !isCancelBtnDisabled && "text-text-800 bg-background-darkLight hover:bg-background-medium hover:text-text-900 cursor-pointer"
                )}>
                    <span>
                        {"Cancel"}
                    </span>
                </div>
            }

            <div onClick={onSave} className={cn(
                "flex item-center justify-center px-3 py-1 rounded-md",
                "font-bodyPri font-normal text-base whitespace-nowrap",
                isSaveBtnDisabled && "bg-text-300 text-text-50 hover:bg-text-300 cursor-not-allowed",
                isLoading && "bg-green-700",
                (!isSaveBtnDisabled && !isLoading) && "text-text-50 bg-green-500 hover:bg-green-700 cursor-pointer"
            )}>
                {isLoading && <ButtonLoader isLoading={isLoading} />}
                {!isLoading &&
                    <span>
                        {btnName}
                    </span>
                }
            </div>

            {isShowNextBtn &&
                <div onClick={onNext} className={cn(
                    "flex item-center justify-center px-3 py-1 rounded-md",
                    "font-bodyPri font-normal text-base",
                    isNextBtnDisabled && "bg-text-300 text-text-50 hover:bg-text-300 cursor-not-allowed",
                    isNextBtnLoading && "bg-primary-dark",
                    (!isNextBtnDisabled && !isNextBtnLoading) && "text-text-50 bg-primary-main hover:bg-primary-dark cursor-pointer"
                )}>
                    <span>
                        {nextBtnLabel}
                    </span>
                </div>
            }
        </div>
    )
}

export default SaveAndCancelBtn;