import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';

import LMSTableComponent from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTableComponent';
import { courseInstructorHeaderConst, courseInstructorMenuItemConst } from '../../../../editLMSCourse.data';

import CourseInstructorInvitation from './CourseInstructorInvitation';

import { deleteLmsCourseInstructorDetail, getLmsCourseInvitationList, updateLmsCourseDetail, updateLmsCourseInstructorDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetLmsCourseInvitationList } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';
import { lmsCourseInstructorPermissionEnum, lmsCourseInviteToEnum } from 'redux/edulyteLms/lmsCourse/lmsCourse.const';

import { pagesInfo } from 'utils/pagesInfo';
import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

const CourseInstructor = () => {
    const { lmsCourseDetail, sendLmsCourseInvitationDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        const query = {
            page: 1,
            records: 5,
            invite_to: lmsCourseInviteToEnum.TUTOR.value,
            lms_course_id: lmsCourseDetail?.data?.id
        }
        dispatcher(getLmsCourseInvitationList(query))

        return () => {
            dispatcher(resetLmsCourseInvitationList())
        }
    }, [sendLmsCourseInvitationDetail?.data])

    const sortCourseInstructor = (instructor1, instructor2) => {
        if (instructor1?.id === lmsCourseDetail?.data?.course_setting?.default_owner?.id) {
            return -1;
        } else if (instructor2?.id === lmsCourseDetail?.data?.course_setting?.default_owner?.id) {
            return 1;
        } else {
            return instructor1 - instructor2;
        }
    }

    const onHandleSelectMenuItem = (menuItem, courseInstructorItem) => {
        switch (menuItem.value) {
            case courseInstructorMenuItemConst?.MAKE_DEFAULT?.value:
                dispatcher(updateLmsCourseDetail(lmsCourseDetail?.data?.id, { owner_user_id: courseInstructorItem?.id }))
                return;
            case courseInstructorMenuItemConst?.VIEWER?.value:
                if (courseInstructorMenuItemConst?.VIEWER?.value === courseInstructorItem?.permission) return;
                dispatcher(updateLmsCourseInstructorDetail(courseInstructorItem?.id, { user_id: courseInstructorItem?.tutor?.user?.userId, permission: courseInstructorMenuItemConst?.VIEWER?.value }))
                break;
            case courseInstructorMenuItemConst?.EDITOR?.value:
                if (courseInstructorMenuItemConst?.EDITOR?.value === courseInstructorItem?.permission) return;
                dispatcher(updateLmsCourseInstructorDetail(courseInstructorItem?.id, { user_id: courseInstructorItem?.tutor?.user?.userId, permission: courseInstructorMenuItemConst?.EDITOR?.value }))
                break;
            case courseInstructorMenuItemConst?.OWNER?.value:
                if (courseInstructorMenuItemConst?.OWNER?.value === courseInstructorItem?.permission) return;
                dispatcher(updateLmsCourseInstructorDetail(courseInstructorItem?.id, { user_id: courseInstructorItem?.tutor?.user?.userId, permission: courseInstructorMenuItemConst?.OWNER?.value }))
                break;
            case courseInstructorMenuItemConst?.DELETE?.value:
                dispatcher(deleteLmsCourseInstructorDetail(courseInstructorItem?.id))
                break;

            default:
                break;
        }
    }

    const CourseTutorProfileImageContainer = ({ courseInstructor }) => {
        return (
            <div className={"flex items-center justify-center"}>
                <div className={'relative w-12 h-12 rounded-full'}>
                    <Link to={`${pagesInfo.TUTOR.pagePath}/${courseInstructor?.tutor?.user?.userId}`}>
                        <img
                            src={courseInstructor?.tutor?.user?.profile_pic_url}
                            alt={"profile-pic"}
                            className={"w-full h-full rounded-full object-cover"}
                        />
                    </Link>
                </div>
            </div>
        )
    }

    const CourseTutorNameContainer = ({ courseInstructor }) => {
        return (
            <div className={'flex items-center justify-center gap-1'}>
                <a
                    href={`${EDULYTE_APP_DOMAIN_URL}${pagesInfo.TUTOR.pagePath}/${courseInstructor?.tutor?.user?.userId}`}
                    target={"_blank"}
                    className={"w-fit"}
                >
                    <span className={'font-bodyPri font-normal text-base tracking-wide truncate capitalize'}>
                        {courseInstructor?.tutor?.user?.first_name} {courseInstructor?.tutor?.user?.last_name.charAt(0) + "."}
                    </span>
                </a>
                {(lmsCourseDetail?.data?.course_setting?.default_owner?.id === courseInstructor?.id) &&
                    <span className={"font-bodyPri font-medium text-green-500 text-sm"}>
                        {"(Primary)"}
                    </span>
                }
            </div>
        )
    }

    const courseInstructorRows = lmsCourseDetail?.data?.course_tutors && lmsCourseDetail?.data?.course_tutors?.slice(0)?.sort(sortCourseInstructor)?.map((courseInstructor) => [
        <CourseTutorProfileImageContainer courseInstructor={courseInstructor} />,
        <CourseTutorNameContainer courseInstructor={courseInstructor} />,
        lmsCourseInstructorPermissionEnum[courseInstructor?.permission?.toUpperCase()]?.label,
        (lmsCourseDetail?.data?.course_setting?.default_owner?.id != courseInstructor?.id)
            ? <div className={"w-full flex items-center justify-center"}>
                <ThreeDotMenu
                    menuItems={Object.values(courseInstructorMenuItemConst)}
                    onHandleSelect={(menuItem) => onHandleSelectMenuItem(menuItem, courseInstructor)}
                />
            </div>
            : "-"
    ])

    return (
        <div className={"w-full flex flex-col gap-8"}>

            <div className={"w-full flex flex-col gap-5"}>
                <span className={"w-full font-bodyPri font-bold text-text-900 text-lg text-left tracking-wide"}>
                    {"1. Instructor(s)"}
                </span>
                <div className={"w-full px-5"}>
                    <LMSTableComponent
                        headers={Object.values(courseInstructorHeaderConst)}
                        subRowHeader={[]}
                        rows={(lmsCourseDetail?.data?.course_tutors?.length > 0) ? courseInstructorRows : []}
                        rowHeaderColor={"bg-background-medium"}
                        alternateRowColor={"bg-primary-light"}
                    />
                </div>
            </div>

            <div className={"w-full h-0.5 bg-divider-medium"} />

            <CourseInstructorInvitation />
        </div>
    )
}

export default CourseInstructor;