import React, { useState } from "react";

//Firebase imports
import { Timestamp } from "firebase/firestore";

//Context imports
import { useUser } from "../../contexts/userContext";
import { useChat } from "../../contexts/chatContext";

//Helper imports
import sendMessage from "../../helpers/sendMessage";
import { requestPaymentError } from "../../helpers/inputValidation";
import sendNotification from "../../helpers/sendNotification";

//Conmponent imports
import Button from "../presentationcomponents/Button/Button";
import Input from "../presentationcomponents/Input/Input";

//Style imports
import {
  StyledRequestPaymentModal,
  StyledRequestPaymentModalContent,
  StyledError,
} from "./RequestPayment.styles";

//Component imports
import SelectCourse from "../SelectCourse/SelectCourse";

export default function RequestPayment({ setShowPaymentRequest }) {
  const { user } = useUser();
  const { selectedChat, groupMembers, courses } = useChat();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [amount, setAmount] = useState("");
  const [additionalComments, setAdditionalComments] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const { userId } = user;
  const userName = `${user.firstName} ${user.lastName}`;

  const isGroup = selectedChat.type === "group";
  const otherChatUser = selectedChat.users.filter(
    (chatUser) => chatUser !== userId
  )[0];

  function handleAmount(e) {
    setErrors({ ...errors, amount: "" });
    setAmount(e.target.value);
  }

  function handleAdditionalComments(e) {
    setAdditionalComments(e.target.value);
  }

  async function submitPaymentRequest() {
    const { valid, requestErrors } = requestPaymentError(
      selectedCourse,
      amount
    );
    if (!valid) {
      setErrors(requestErrors);
      return;
    }
    try {
      setErrors({});
      setLoading(true);
      const messageObject = {
        additionalComments: additionalComments,
        amount: amount,
        course: selectedCourse.classTitle,
        sender: userId,
        type: "paymentRequest",
        paymentStatus: "pending",
        sentAt: Timestamp.fromDate(new Date()),
        status: "sent",
      };

      const individualNotificationObject = {
        fromName: userName,
        fromId: userId,
        receiver: otherChatUser,
        type: "paymentRequest",
        sentAt: Timestamp.fromDate(new Date()),
        seen: false,
        chatId: selectedChat.id,
        chatType: selectedChat.type,
      };

      const groupNotificationObject = {
        sentAt: Timestamp.fromDate(new Date()),
        type: "paymentRequest",
      };

      const notificationObject = isGroup
        ? groupNotificationObject
        : individualNotificationObject;

      await sendNotification(
        notificationObject,
        selectedChat,
        groupMembers,
        user
      );
      await sendMessage(messageObject, selectedChat, groupMembers);
      setLoading(false);
      setShowPaymentRequest(false);
    } catch (err) {
      setLoading(false);
      setErrors({ ...errors, submit: "Database Error! Try again" });
      console.log(err);
    }
  }

  function closePaymentRequestModal() {
    setShowPaymentRequest(false);
  }

  return (
    <StyledRequestPaymentModal>
      <h3>Request a Payment</h3>
      <SelectCourse
        courses={courses}
        selectedCourse={selectedCourse}
        setSelectedCourse={setSelectedCourse}
        setAmount={setAmount}
        setErrors={setErrors}
        errors={errors}
      />
      {errors && errors.selectedCourse && (
        <StyledError>{errors.selectedCourse}</StyledError>
      )}
      <StyledRequestPaymentModalContent>
        <Input
          placeholder="Amount"
          onChange={handleAmount}
          type="text"
          value={amount}
        />
      </StyledRequestPaymentModalContent>
      {errors && errors.amount && <StyledError>{errors.amount}</StyledError>}
      <StyledRequestPaymentModalContent>
        <Input
          placeholder="Additional Comments"
          onChange={handleAdditionalComments}
          type="text"
          value={additionalComments}
        />
      </StyledRequestPaymentModalContent>

      <StyledRequestPaymentModalContent>
        <Button
          variant="primary"
          text="Request Payment"
          onClick={submitPaymentRequest}
          loading={loading}
          disabled={loading}
        />
      </StyledRequestPaymentModalContent>

      <StyledRequestPaymentModalContent>
        <Button
          variant="secondary"
          text="Cancel"
          onClick={closePaymentRequestModal}
        />
      </StyledRequestPaymentModalContent>
      {errors && errors.submit && <StyledError>{errors.submit}</StyledError>}
    </StyledRequestPaymentModal>
  );
}
