import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { courseFilterConst, courseTypeConst, getQueryString } from '../../data';
// import SortByContainer from './mobileViewComponents/SortByContainer';
import StudentLevel from './mobileViewComponents/StudentLevel';
import CourseTypeFilter from './mobileViewComponents/CourseTypeFilter';
import SpeaksFilter from './mobileViewComponents/SpeaksFilter';
import AvailabilityFilter from "./mobileViewComponents/AvailabilityFilter";
import TutorIsFromFilter from './mobileViewComponents/TutorIsFromFilter';
import PriceFilter from './mobileViewComponents/PriceFilter';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setFilterProps } from 'redux/course/course.slice';
import { DEFAULT_COURSE_PAGE, DEFAULT_COURSE_RECORDS, courseSortBy, weekOptions, courseFilterProps, coursePriceTypeEnum } from 'redux/course/course.const';

import { pagesInfo } from "utils/pagesInfo";

const FilterCourseSideOverContent = ({ onClose }) => {
    const { locals } = useAppState((s) => s.local)
    const { languageList, profList, countryList, currencyRateList } = useAppState((state) => state.master)
    const { filterProps } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [activeCourseFilter, setActiveCourseFilter] = useState("price")

    // const [selectedSorting, setSelectedSorting] = useState(courseSortBy?.RELEVANCE)
    // const [studentLevelList, setStudentLevelList] = useState([])
    // const [classTypeList, setClassTypeList] = useState([])
    // const [speaksList, setSpeaksList] = useState([])
    // const [tutorFromList, setTutorFromList] = useState([])
    // const [availabilityList, setAvailabilityList] = useState([])
    // const [selectedPriceRange, setSelectedPriceRange] = useState([])

    const onHandleCourseFilter = (courseFilterValue) => {
        setActiveCourseFilter(courseFilterValue)
    }

    const onApplyFilter = () => {
        const queryFilterPayload = {
            page: DEFAULT_COURSE_PAGE,
            records: DEFAULT_COURSE_RECORDS,
        }
        if (filterProps?.sortBy !== courseSortBy?.RELEVANCE?.value) {
            queryFilterPayload["sortBy"] = filterProps?.sortBy
        }
        if (filterProps?.proficiency?.length > 0) {
            queryFilterPayload["proficiency"] = filterProps?.proficiency
        }
        if (filterProps?.courseType?.length > 0) {
            queryFilterPayload["courseType"] = filterProps?.courseType
        }
        if (filterProps?.speak?.length > 0) {
            queryFilterPayload["speak"] = filterProps?.speak
        }
        if (filterProps?.tutorFrom?.length > 0) {
            queryFilterPayload["tutorFrom"] = filterProps?.tutorFrom
        }
        if (filterProps?.day?.length > 0) {
            queryFilterPayload["day"] = filterProps?.day
        }
        if (filterProps?.priceType?.length > 0) {
            queryFilterPayload["priceType"] = filterProps?.priceType
        }
        if (filterProps?.minPrice != 0) {
            queryFilterPayload["minPrice"] = filterProps?.minPrice
        }
        if (filterProps?.maxPrice != 0) {
            queryFilterPayload["maxPrice"] = filterProps?.maxPrice
        }
        const query = getQueryString(queryFilterPayload)
        navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
        onClose(false)
    }

    const onHandleClearAllFilters = () => {
        const query = getQueryString(courseFilterProps)
        navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
        onClose(false)
    }

    return (
        <div className={"w-screen h-screen flex flex-col lg:hidden"}>
            <div className={"w-full flex grow-0 justify-between items-center gap-3 px-5 py-3"}>
                <div className={"font-bodyPri font-normal text-text-900 text-base tracking-wide text-center"}>
                    {"FILTER CLASSES"}
                </div>
                <button
                    className={cn(
                        "px-2 py-0.5 border border-text-400 focus:outline-none rounded-lg",
                        "font-bodyPri font-normal text-text-800 text-sm",
                        "hover:text-text-50 hover:bg-text-400"
                    )}
                    onClick={() => onClose(false)}
                >
                    {"Close"}
                </button>
            </div>
            <hr className={"border border-divider-medium"} />
            <div className={"w-full h-[80vh] flex items-start justify-start gap-3"}>
                <div className={"w-4/12 sm:w-1/4 h-full bg-divider-medium"}>
                    {Object.values(courseFilterConst)?.map((courseFilter) => (
                        <>
                            <div
                                className={cn(
                                    "px-5 py-2 cursor-pointer",
                                    (activeCourseFilter === courseFilter?.value) && "bg-white"
                                )}
                                onClick={() => onHandleCourseFilter(courseFilter?.value)}
                            >
                                <span className={cn(
                                    "font-bodyPri font-medium text-text-800 text-base",
                                    (activeCourseFilter === courseFilter?.value) && "text-primary-dark"
                                )}>
                                    {courseFilter?.label}
                                </span>
                            </div>
                            <hr className={"border border-divider-darkLight w-full"} />
                        </>
                    ))}
                </div>
                <div className={"w-8/12 sm:w-3/4 h-full mr-5 px-5 py-3"}>
                    {(activeCourseFilter === courseFilterConst?.PRICE?.value) &&
                        <PriceFilter
                            options={Object.values(coursePriceTypeEnum)}
                            selectedOption={filterProps?.priceType}
                            minValue={filterProps?.minPrice || currencyRateList?.currencyRateList[(locals?.currencyCode || "USD")]?.toFixed(2)}
                            maxValue={filterProps?.maxPrice || (currencyRateList?.currencyRateList[(locals?.currencyCode || "USD")] * 500)?.toFixed(2)}
                            onHandlePriceRange={(options) => {
                                dispatcher(setFilterProps({
                                    filterProps: { ...filterProps, priceType: options?.priceType, minPrice: options?.minLocal, maxPrice: options?.maxLocal }
                                }))
                            }}
                            onClearFilter={() => {
                                dispatcher(setFilterProps({
                                    filterProps: { ...filterProps, priceType: "", minPrice: 0, maxPrice: 0 }
                                }))
                            }}
                        />
                    }
                    {(activeCourseFilter === courseFilterConst?.AVAILABILITY?.value) &&
                        <div>
                            <div className={"w-full flex items-center justify-between gap-3"}>
                                <div className={"flex items-center justify-start gap-2"}>
                                    <span className={"font-bodyPri font-medium text-text-900 text-base"}>
                                        {"Availability"}
                                    </span>
                                    {filterProps?.day?.length > 0 &&
                                        <span className={"w-5 h-5 flex items-center justify-center bg-primary-dark rounded-full text-text-50 text-sm"}>
                                            {filterProps?.day?.length}
                                        </span>
                                    }
                                </div>
                                <div
                                    className={"font-bodyPri font-medium text-primary-dark text-sm underline cursor-pointer"}
                                    onClick={() => dispatcher(setFilterProps({
                                        filterProps: { ...filterProps, day: [] }
                                    }))}>
                                    {"Clear"}
                                </div>
                            </div>
                            <AvailabilityFilter
                                weekOptions={Object.values(weekOptions)}
                                selectedAvailabilityList={filterProps?.day?.map((items) => ({
                                    label: items,
                                    value: items
                                })) || []}
                                onHandleSelectAvailability={(week) => dispatcher(setFilterProps({
                                    filterProps: { ...filterProps, day: week?.map((opt) => opt?.value) }
                                }))}
                            />
                        </div>
                    }
                    {(activeCourseFilter === courseFilterConst?.TUTOR_IS_FROM?.value) &&
                        <TutorIsFromFilter
                            options={countryList?.countryList?.map((country) => ({
                                label: country?.country,
                                value: country?.country,
                            }))}
                            selectedTutorFromList={filterProps?.tutorFrom?.map((items) => ({
                                label: items,
                                value: items
                            })) || []}
                            isLoading={countryList?.isLoading}
                            multipleOptions={true}
                            isShowInputBox={true}
                            isShowCheckBox={true}
                            onHandleSelectTutorFrom={(options) => dispatcher(setFilterProps({
                                filterProps: { ...filterProps, tutorFrom: options?.map((op) => op?.value) }
                            }))}
                            onClearFilter={() => dispatcher(setFilterProps({
                                filterProps: { ...filterProps, tutorFrom: [] }
                            }))}
                        />
                    }
                    {(activeCourseFilter === courseFilterConst?.SPEAKS?.value) &&
                        <SpeaksFilter
                            options={languageList?.languageList?.map((language) => ({
                                label: language?.language,
                                value: language?.language
                            }))}
                            selectedSpeaksList={filterProps?.speak?.map((item) => ({
                                label: item,
                                value: item
                            })) || []}
                            isLoading={languageList?.isLoading}
                            multipleOptions={true}
                            isShowInputBox={true}
                            isShowCheckBox={true}
                            onHandleSelectSpeaks={(options) => {
                                dispatcher(setFilterProps({
                                    filterProps: { ...filterProps, speak: options?.map((opt) => opt?.value) }
                                }))
                            }}
                            onClearFilter={() => dispatcher(setFilterProps({
                                filterProps: { ...filterProps, speak: [] }
                            }))}
                        />
                    }
                    {(activeCourseFilter === courseFilterConst?.CLASS_TYPE?.value) &&
                        <CourseTypeFilter
                            options={Object.values(courseTypeConst)}
                            selectedCourseTypes={filterProps?.courseType?.length > 0 ? filterProps?.courseType?.map((items) => ({
                                label: items,
                                value: items
                            })) : []}
                            onHandleSelectCourseType={(options) => dispatcher(setFilterProps({
                                filterProps: { ...filterProps, courseType: options?.map((op) => op?.value) }
                            }))}
                            onClearFilter={() => dispatcher(setFilterProps({
                                filterProps: { ...filterProps, courseType: [] }
                            }))}
                        />
                    }

                    {(activeCourseFilter === courseFilterConst?.STUDENT_LEVEL?.value) &&
                        <StudentLevel
                            options={profList?.profList?.map((proficiency) => ({
                                label: proficiency?.proficiency,
                                value: proficiency?.proficiency
                            }))}
                            isLoading={profList?.isLoading}
                            selectedLevels={filterProps?.proficiency?.length > 0 ? filterProps?.proficiency?.map((proficiency) => ({
                                label: proficiency,
                                value: proficiency
                            })) : []}
                            onHandleSelectedLevels={(options) => dispatcher(setFilterProps({
                                filterProps: { ...filterProps, proficiency: options?.map((opt) => opt?.value) }
                            }))}
                            onClearFilter={() => dispatcher(setFilterProps({
                                filterProps: { ...filterProps, proficiency: [] }
                            }))}
                        />
                    }
                    {/* {(activeCourseFilter === courseFilterConst?.SORT_BY?.value) &&
                        <div>
                            <div className={"w-full flex items-center justify-between gap-3"}>
                                <div className={"flex items-center justify-start gap-2"}>
                                    <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                                        {"Sort By:"}
                                    </span>
                                    <span className={"font-bodyPri font-medium text-text-800 text-base tracking-wide"}>
                                        {selectedSorting?.label}
                                    </span>
                                </div>
                                <div
                                    className={"font-bodyPri font-medium text-primary-dark text-sm underline cursor-pointer"}
                                    onClick={() => dispatcher(setFilterProps({
                                        filterProps: { ...filterProps, sortBy: courseSortBy?.RELEVANCE?.value }
                                    }))}>
                                    {"Clear"}
                                </div>
                            </div>
                            <SortByContainer
                                options={Object?.values(courseSortBy)}
                                selectedSorting={{ label: filterProps?.sortBy, value: filterProps?.sortBy } || courseSortBy?.RELEVANCE}
                                handleSelectSorting={(option) => {
                                    setSelectedSorting(option)
                                    dispatcher(setFilterProps({
                                        filterProps: { ...filterProps, sortBy: option?.value }
                                    }))
                                }}
                                onClearFilter={() => dispatcher(setFilterProps({
                                    filterProps: { ...filterProps, sortBy: courseSortBy?.RELEVANCE?.value }
                                }))}
                            />
                        </div>
                    } */}
                </div>
            </div>
            <hr className={"border border-divider-medium"} />
            <div className={"w-full flex items-center justify-center gap-1 px-1 py-0.5"}>
                <span className={cn(
                    "flex-1 flex items-center justify-center px-4 py-2 rounded font-bodyPri font-medium text-sm text-text-900 bg-text-400 cursor-pointer whitespace-nowrap",
                    "hover:bg-background-medium hover:text-text-800"
                )}
                    onClick={onHandleClearAllFilters}
                >
                    {"Clear All"}
                </span>
                <span className={cn(
                    "flex-1 flex items-center justify-center px-4 py-2 rounded font-bodyPri font-medium text-sm text-text-50 bg-primary-dark cursor-pointer whitespace-nowrap",
                    "hover:bg-primary-main"
                )}
                    onClick={onApplyFilter}
                >
                    {"Apply Filters"}
                </span>
            </div>
        </div>
    )
}

export default FilterCourseSideOverContent