import { useState, useEffect } from "react";
import { cn } from "utils/cn.utils";

import { AiOutlineClose } from "react-icons/ai";

import { OptionSelector } from "components/common-components/Select";

import { useAppState } from "hooks/useStore";
import { courseType } from "redux/course/course.const";

import { timeZone, generateTimeOption } from 'utils/dateTime.utils';


const ScheduleTimeRange = ({ timeSlot, updateTimeSlot, deleteTimeSlot }) => {
    const { tutorCourseDetail } = useAppState((state) => state.course)

    const timeOptions = generateTimeOption(timeZone)
    const startTimeOption = timeOptions
    const endTimeOption = timeOptions

    const [startTimeIndex, setStartTimeIndex] = useState(0)

    useEffect(() => {
        for (let index = 0; index < endTimeOption.length; index++) {
            if (timeSlot.startTime === endTimeOption[index].value) {
                setStartTimeIndex(index)
                break;
            }
        }
    }, [timeSlot])

    const handleOnDelete = () => {
        deleteTimeSlot(timeSlot)
    }

    return (
        <div className={"w-full flex items-start justify-start md:justify-between lg:justify-between gap-5"}>
            <div className={"w-full flex flex-col items-start justify-start gap-1"}>
                <div className={"w-fit p-1 flex items-center justify-start space-x-1 rounded-lg bg-divider-darkLight"}>
                    <div className="w-24 md:w-32">
                        <OptionSelector
                            options={startTimeOption}
                            onChange={(option) => updateTimeSlot(timeSlot, { startTime: option?.value, endTime: timeSlot?.endTime })}
                            value={timeSlot?.startTime}
                        />
                    </div>
                    <span className="font-bodyPri font-normal text-text-900 text-base px-1">
                        {"-"}
                    </span>
                    <div className="w-24 md:w-32">
                        <OptionSelector
                            options={[...endTimeOption.slice(startTimeIndex + 1), timeOptions[0]]}
                            onChange={(option) => {
                                if (tutorCourseDetail?.data?.type === courseType?.GROUP?.value) return;
                                updateTimeSlot(timeSlot, { startTime: timeSlot?.startTime, endTime: option?.value })
                            }}
                            value={timeSlot?.endTime}
                        />
                    </div>
                </div>
            </div>

            <div className={"w-full flex items-center justify-end mt-2.5"}>
                <span
                    className={"relative has-tooltip inline-flex items-center justify-center cursor-pointer"}
                    onClick={handleOnDelete}
                >
                    <AiOutlineClose className={"font-bodyPri font-semibold text-xl text-text-600 hover:text-text-800"} />
                    <span
                        className={cn(
                            "w-fit px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip absolute -top-5",
                            "flex flex-col items-center justify-start overflow-hidden whitespace-nowrap"
                        )}
                    >
                        {"Remove"}
                    </span>
                </span>
            </div>
        </div>
    );
};

export default ScheduleTimeRange;