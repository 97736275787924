import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useTitle } from 'hooks/useTitle';

import { PageContentStyle } from 'pages/auth/checkoutBooking/CheckoutBookingStyle';
import HeaderSection from './components/HeaderSection';
import PaymentMethodSection from './components/PaymentMethodSection';
import PaymentSummerySection from './components/PaymentSummerySection';
import RescheduleItemSection from './components/RescheduleItemSection';

import FullPageLoader from 'components/loader/FullPageLoader';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getMyProfile } from 'redux/user/user.request';
import { getUserBookingDetail } from 'redux/booking/booking.request';
import { 
    resetUserBookingDetail, 
    setModifyUserBookingDetailPayload, 
    resetModifyUserBookingDetail,
    resetBookingDetailConfirmation
} from 'redux/booking/booking.slice';
import { setAddPaymentIntentDetailPayload, resetAddPaymentIntentDetail } from 'redux/payment/payment.slice';
import { paymentTypeEnum } from 'redux/payment/payment.const';

import { pagesInfo } from 'utils/pagesInfo';

const GATEWAY_NAME = "stripe"
const PROCESSING_FEE_PCT = 2.5
// const CURRENCY = "usd"

const CheckoutReschedule = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)
    const { userBookingDetail, modifyUserBookingDetail } = useAppState((state) => state.booking)
    const { addPaymentIntentDetail } = useAppState((state) => state.payment)


    const dispatcher = useAppDispatcher()
    const { sessionId } = useParams()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.CHECKOUT_RESCHEDULE))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        return () => {
            dispatcher(resetUserBookingDetail())
            dispatcher(resetModifyUserBookingDetail())
            dispatcher(resetAddPaymentIntentDetail())
            dispatcher(resetBookingDetailConfirmation())
        }
    }, [])

    useEffect(() => {
        dispatcher(getMyProfile())
    }, [])

    useEffect(() => {
        // dispatcher(getUserBookingDetail(sessionId, user?.user?.userId, { timeZone: timeZone }))
    }, [])

    useEffect(() => {
        if (userBookingDetail?.data?.result) {
            setTitle({
                ...title
            })
        }
        handleWalletToggle(modifyUserBookingDetail?.payload?.isWalletAdded)
    }, [userBookingDetail?.data?.result])

    const handleWalletToggle = (isToggle) => {
        let walletAmountToPay = 0
        let gatewayAmountToPay = 0

        if (isToggle) {
            let gatewayAmount = userBookingDetail?.data?.result?.bookingPrice?.totalAmount - (user?.user?.netWalletCredit + userBookingDetail?.data?.result?.bookingPrice?.promoCreditUsed)
            if (gatewayAmount > 0) {
                walletAmountToPay = user?.user?.netWalletCredit
                gatewayAmountToPay = gatewayAmount
            } else {
                walletAmountToPay = userBookingDetail?.data?.result?.bookingPrice?.totalAmount - userBookingDetail?.data?.result?.bookingPrice?.promoCreditUsed
                gatewayAmountToPay = 0
            }
        } else {
            walletAmountToPay = 0
            gatewayAmountToPay = userBookingDetail?.data?.result?.bookingPrice?.totalAmount - userBookingDetail?.data?.result?.bookingPrice?.promoCreditUsed
        }

        dispatcher(setModifyUserBookingDetailPayload({
            ...modifyUserBookingDetail?.payload,
            isWalletAdded: isToggle,
            note: userBookingDetail?.data?.result?.note,
            walletAmountToPay: walletAmountToPay,
            isGatewayIncluded: gatewayAmountToPay > 0,
            gatewayAmountToPay: gatewayAmountToPay
        }))

        if (gatewayAmountToPay > 0) {
            let amountToPay = (gatewayAmountToPay >= 100 ? gatewayAmountToPay : 100)
            let totalAmountToPay = Math.ceil((amountToPay * 100) / (100 - PROCESSING_FEE_PCT))
            dispatcher(setAddPaymentIntentDetailPayload({
                ...addPaymentIntentDetail?.payload,
                paymentType: paymentTypeEnum.BOOKING.value,
                currency: userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code?.toLowerCase(),
                amountToPay: amountToPay,
                processingFeePct: PROCESSING_FEE_PCT,
                totalAmountToPay: totalAmountToPay,
                description: paymentTypeEnum.BOOKING.value,
                gatewayName: GATEWAY_NAME,
                name: `${user?.user?.firstName} ${user?.user?.lastName}`,
                email: user?.user?.email
            }))
        } else {
            dispatcher(resetAddPaymentIntentDetail())
        }
    }

    if (userBookingDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={userBookingDetail?.isLoading} />
        )
    }

    if (userBookingDetail?.errorMsg) {
        return (
            <PageContentStyle>
                <HeaderSection />
                <div className={"flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                    {userBookingDetail?.errorMsg ? "Sorry, Booking not found." : ""}
                </div>
            </PageContentStyle>
        )
    }

    return userBookingDetail?.data?.result ? (
        <PageContentStyle>

            <HeaderSection />

            <div className={"container mx-auto px-8 py-5 grid grid-cols-12 gap-5"}>

                <div className={"col-start-1 col-span-full lg:col-span-8 space-y-5"}>

                    <RescheduleItemSection />

                    <hr className={"h-1 bg-divider-medium w-full rounded-full"} />

                    <PaymentMethodSection handleWalletToggle={handleWalletToggle} />

                </div>

                <div className={"relative col-start-1 col-span-full lg:col-start-9 lg:col-span-4"}>
                    <div className={"lg:sticky lg:top-[1rem] lg:mt-10"}>

                        <Elements stripe={loadStripe(user?.user?.stripePk)}>
                            <PaymentSummerySection />
                        </Elements>
                    </div>
                </div>

            </div>
        </PageContentStyle>
    ) : null
}

export default CheckoutReschedule;