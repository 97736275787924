import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { getCourseListPayload, pageHeading, searchParamsInfo } from 'pages/auth/offerings/data';

import FilterSection from "pages/auth/offerings/filters/FilterSection";
import LiveClassCardUpdated from "./components/liveClassCardUpdated";
import LiveClassCardUpdatedLoader from "components/loader/LiveClassCardUpdatedLoader";

import PageHeader from "components/pageHeader/PageHeader";
import Pagination from "components/pagination/Pagination";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getTutorCourseList } from 'redux/course/course.request';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearFilterProps, setClearTutorCourseList, setClearAddCourseDetail } from "redux/course/course.slice";

import { pagesInfo } from 'utils/pagesInfo';
import { useTitle } from "hooks/useTitle";

const RECORDS = 5

const OfferingsPage = () => {
  const { currentPageInfo } = useAppState((s) => s.pageInfo)
  const { user } = useAppState((state) => state.user)
  const { tutorCourseList, addCourseDetail } = useAppState((state) => state.course)

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()
  const location = useLocation()
  const [title, setTitle] = useTitle()

  const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.OFFERINGS))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    setTitle({
      ...title,
      title: "All My Classes | Tutor at Edulyte"
    })
  }, [dispatcher, currentPageInfo])

  const getTutorOfferingList = async () => {
    const queryParamPayload = await getCourseListPayload(searchQueryParams)

    dispatcher(getTutorCourseList(user?.user?.tutor?.tutorId, { ...queryParamPayload }))
  }

  useEffect(() => {

    return () => {
      dispatcher(setClearTutorCourseList())
      dispatcher(setClearFilterProps())
    }
  }, [])

  useEffect(() => {
    if (user?.user?.tutor?.tutorId) {
      dispatcher(setClearAddCourseDetail())
      getTutorOfferingList()
    }
  }, [user?.user?.tutor?.tutorId, location?.search, addCourseDetail?.addCourseDetail])

  const onHandlePageChange = async (page) => {
    searchQueryParams.set(searchParamsInfo.page.key, page)
    navigate(`${location.pathname}?${searchQueryParams?.toString()}`);
  }

  return (
    <div className={"space-y-3"}>
      <PageHeader
        pageHeading={pageHeading}
        toolTipText={"Host engaging group sessions for your customers."}
      />
      <FilterSection />
      {new Array(RECORDS).fill("").map((_, index) => <LiveClassCardUpdatedLoader key={index} isLoading={tutorCourseList?.isLoading || addCourseDetail?.isLoading} />)}
      {(!tutorCourseList?.isLoading && !addCourseDetail?.isLoading) && (tutorCourseList?.data && tutorCourseList?.data?.records > 0) &&
        tutorCourseList?.data?.results?.map((offering, index) => (
          <LiveClassCardUpdated offering={offering} key={index} />
        ))}
      {(tutorCourseList?.data && (tutorCourseList?.data?.totalPages > 1)) &&
        <div className={"flex justify-center items-center"}>
          <Pagination
            page={tutorCourseList?.data?.page}
            totalPages={tutorCourseList?.data?.totalPages}
            onChangePage={onHandlePageChange}
          />
        </div>
      }
      {((tutorCourseList?.data?.records === 0) || tutorCourseList?.message) &&
        <div className={"w-full h-full flex items-center justify-center font-bodyPri font-semibold text-text-800 text-md"}>
          {tutorCourseList?.message || "No records found!"}
        </div>
      }
    </div>
  );
};

export default OfferingsPage;
