import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import { Reorder, motion } from 'framer-motion';

import { FaSpinner } from 'react-icons/fa';

import DownloadActionButtons from 'pages/auth/editDownload/commonComponents/DownloadActionButtons';
import EmptyDownloadContent from 'pages/auth/editDownload/commonComponents/EmptyDownloadItemContent';
import PreviewMenuButtons from 'pages/auth/editDownload/commonComponents/PreviewMenuButtons';

import DownloadPreviewItem from './DownloadPreviewItem';
import { onHandleValidateFileDetails, previewActionButtons, downloadTypeConst } from 'pages/auth/editDownload/data';

import { updateOrderDownloadPreviewDetail } from "redux/downloads/downloads.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setAddUserDownloadDetailPayload, setOwnerUserDownloadDetailData } from 'redux/downloads/downloads.slice';
import { setThumbnailDetailPayload } from 'redux/other/other.slice';
import { modalConst } from 'redux/local/local.const';
import { addUserDownloadDetailPayload } from 'redux/downloads/downloads.const';
import { orientationTypeConst } from 'redux/other/other.const';

import { isOrderUpdated } from 'utils/generators.utils';
import { cn } from 'utils/cn.utils';

const DownloadPreviews = () => {
    const { modal } = useAppState(s => s.local)
    const { thumbnailDetail } = useAppState((state) => state.other)
    const uploadFile = useAppState((state) => state.storage.uploadFile)
    const { ownerUserDownloadDetail, addDownloadPreviewDetail } = useAppState((state) => state.downloads)

    const dispatcher = useAppDispatcher()

    const [isDownloadPreviewOrderUpdated, setIsDownloadPreviewOrderUpdated] = useState(false)

    const onHandleReorderGroup = useCallback((newOrder) => {
        if (newOrder) {
            const isOrderModified = isOrderUpdated(ownerUserDownloadDetail?.data?.previews, newOrder)
            if (!isOrderModified) {
                setIsDownloadPreviewOrderUpdated(false)
                return;
            }
            if (isOrderModified) {
                setIsDownloadPreviewOrderUpdated(true)
                dispatcher(setOwnerUserDownloadDetailData({
                    ...ownerUserDownloadDetail?.data,
                    previews: newOrder
                }))
            }
        }
    }, [ownerUserDownloadDetail?.data?.previews])

    const onHandleUpdatePreviewOrder = useCallback(() => {
        if (isDownloadPreviewOrderUpdated && !!ownerUserDownloadDetail?.data?.previews) {
            const body = {
                order: ownerUserDownloadDetail?.data?.previews?.map((previewItem) => ({
                    id: previewItem?.id
                }))
            }
            dispatcher(updateOrderDownloadPreviewDetail(ownerUserDownloadDetail?.data?.id, body))
            setIsDownloadPreviewOrderUpdated(false)
        }
    }, [ownerUserDownloadDetail?.data])

    const onHandleSelectButton = useCallback((selectedBtn, event) => {
        if ([previewActionButtons.SEARCH_IMAGE.value]?.includes(selectedBtn?.value)) {
            onHandleSearchImages()
        } else {
            onHandleButtonClick(event)
        }
    }, [modal, thumbnailDetail?.thumbnailDetailPayload])

    const onHandleSearchImages = useCallback(() => {
        dispatcher(setThumbnailDetailPayload({
            ...thumbnailDetail?.thumbnailDetailPayload,
            query: ownerUserDownloadDetail?.data?.title,
            orientation: orientationTypeConst.LANDSCAPE.value,
            dimensionUnit: 11
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.PHOTO_SEARCH_MODAL.stateKey]: true
        }))
    }, [modal, thumbnailDetail?.thumbnailDetailPayload])

    const onHandleButtonClick = async (event) => {
        const { requestPayload, errorMsg } = await onHandleValidateFileDetails(event?.target?.files, downloadTypeConst.PREVIEW.key)
        if (errorMsg) {
            toast.warn(errorMsg)
            return;
        }
        if (requestPayload) {
            dispatcher(setAddUserDownloadDetailPayload({
                ...addUserDownloadDetailPayload,
                ...requestPayload,
                downloadType: downloadTypeConst.PREVIEW.key
            }))
        }
    }

    const sortPreviewList = useCallback((preview1, preview2) => {
        const defaultId = ownerUserDownloadDetail?.data?.preview?.id
        if (preview1?.id === defaultId) return -1;
        if (preview2?.id === defaultId) return 1;
        return 0;
    }, [ownerUserDownloadDetail?.data])

    return (
        <div className={"w-full flex flex-col items-start justify-start gap-5 px-3 py-5 bg-white rounded-lg"}>
            <div className={"w-full flex flex-row items-center justify-between gap-5"}>
                <span className={"font-bodyPri font-semibold text-lg md:text-xl text-primary-dark tracking-wide"}>
                    {"Preview(s)"}
                </span>
                <div className={"flex items-center justify-start gap-2"}>
                    {(uploadFile?.isLoading || addDownloadPreviewDetail?.isLoading) &&
                        <FaSpinner className={"animate-spin text-xl text-primary-dark"} />
                    }
                    {(!uploadFile?.isLoading && !addDownloadPreviewDetail?.isLoading) &&
                        <>
                            <div className={"flex md:hidden"}>
                                <PreviewMenuButtons
                                    menuItems={Object.values(previewActionButtons)}
                                    onHandleSelect={onHandleSelectButton}
                                />
                            </div>
                            <div className={"hidden md:flex items-center justify-start gap-3"}>
                                <DownloadActionButtons
                                    isLoading={uploadFile?.isLoading || addDownloadPreviewDetail?.isLoading}
                                    buttonList={Object.values(previewActionButtons).filter((item) => (item.value !== previewActionButtons.SEARCH_IMAGE.value))}
                                    onHandleButtonClick={onHandleButtonClick}
                                />
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    transition={{ duration: 0.3 }}
                                    className={cn(
                                        'relative group px-3 py-1 flex items-center justify-center border border-primary-dark text-primary-dark rounded-lg cursor-pointer',
                                        'font-bodyPri font-normal text-sm sm:text-base hover:bg-primary-dark hover:text-text-50 ease-in-out duration-200',
                                    )}
                                    onClick={onHandleSearchImages}
                                >
                                    <div className={"w-full h-full flex items-center justify-start gap-2 cursor-pointer"}>
                                        {previewActionButtons.SEARCH_IMAGE.icon}
                                        <span className={"whitespace-nowrap"}>
                                            {previewActionButtons.SEARCH_IMAGE?.label}
                                        </span>
                                    </div>
                                </motion.div>
                            </div>
                        </>
                    }
                </div>
            </div>
            {!!ownerUserDownloadDetail?.data?.previews?.length &&
                <Reorder.Group
                    className={cn(
                        "px-4 py-3 w-full flex items-center justify-start gap-8 md:gap-5 overflow-hidden overflow-x-scroll",
                        "scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                    )}
                    values={ownerUserDownloadDetail?.data?.previews}
                    onReorder={onHandleReorderGroup}
                    axis={"x"}
                >
                    {ownerUserDownloadDetail?.data?.previews?.slice(0)?.sort(sortPreviewList)?.map((previewItem, index) => (
                        <Reorder.Item
                            key={previewItem?.id}
                            value={previewItem}
                            onDragEnd={onHandleUpdatePreviewOrder}
                        >
                            <DownloadPreviewItem
                                index={index}
                                previewItem={previewItem}
                                defaultPreviewId={ownerUserDownloadDetail?.data?.preview?.id}
                            />
                        </Reorder.Item>
                    ))}
                    {(addDownloadPreviewDetail?.isLoading) &&
                        <div className={"animate-pulse bg-slate-300 flex flex-col content-between justify-between overflow-hidden rounded-lg shadow-lg h-32 aspect-video cursor-processing"}></div>
                    }
                </Reorder.Group>
            }
            {(ownerUserDownloadDetail?.data && (ownerUserDownloadDetail?.data?.previews?.length === 0)) &&
                <EmptyDownloadContent downloadType={downloadTypeConst.PREVIEW.key} />
            }
        </div>
    )
}

export default DownloadPreviews