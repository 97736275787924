import { useEffect } from "react";
import { toast } from "react-toastify";

import { FaDollarSign, FaPercent } from "react-icons/fa";
import { FaInfoCircle } from 'react-icons/fa';

import ButtonLoader from 'components/loader/ButtonLoader';
import ComponentLoader from "components/loader/ComponentLoader";
import ToolTipView from "components/tooltipView";
import { Button } from "components/common-components/Buttons";
import { InputFieldWithPrefix } from "components/common-components/Form";
import { OptionSelector } from "components/common-components/Select";

import { pricingPrefDiscountConst, pricingPreferenceToolTip } from "pages/auth/preferences/data";

import { getTutorUserPriceByUserId, updateTutorUserPriceByUserId } from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetUserAppointmentDetail } from "redux/appointment/appointment.slice";
import { setTutorUserPrice, setClearTutorUserPrice } from "redux/tutor/tutor.slice";

const PricePreferenceHeader = () => {

  return (
    <div className={"py-3 space-y-3"}>
      <div className="flex justify-center w-full">
        <div className="flex item-start justify-center gap-1">
          <span className="font-subHeading font-medium text-2xl text-text--800">
            {"Pricing Preferences"}
          </span>
          <ToolTipView content={pricingPreferenceToolTip}>
            <button className={""}>
              <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
            </button>
          </ToolTipView>
        </div>
      </div>
      <div className="h-0.5 bg-gray-300 w-full"></div>
    </div>
  );
}

export default function PricingPreferencesSettings() {
  const { user } = useAppState((s: any) => s.user)
  const { tutorUserPrice, addTutorUserPrice } = useAppState((s: any) => s.tutor)

  const dispatcher = useAppDispatcher();

  useEffect(() => {
    dispatcher(getTutorUserPriceByUserId(user?.user?.userId))

    return () => {
      dispatcher(resetUserAppointmentDetail())
      dispatcher(setClearTutorUserPrice())
    }
  }, [])

  const generatePricingPref = async () => {
    const pricing = {
      trialCourseDuration: tutorUserPrice?.tutorUserPrice?.trialCourseDuration,
      minTrialCoursePrice: tutorUserPrice?.tutorUserPrice?.minTrialCoursePrice,
      minOneToOneCoursePrice: tutorUserPrice?.tutorUserPrice?.minOneToOneCoursePrice,
      minGroupCoursePrice: tutorUserPrice?.tutorUserPrice?.minGroupCoursePrice,
      pctDisOnFiveOneToOneSession: tutorUserPrice?.tutorUserPrice?.pctDisOnFiveOneToOneSession
    }
    return pricing
  }

  const onConfirm = async () => {
    if (tutorUserPrice?.tutorUserPrice?.minTrialCoursePrice === 0) {
      toast.warn("Trial class Price cannot be zero!")
      return;
    }
    if (tutorUserPrice?.tutorUserPrice?.minOneToOneCoursePrice === 0) {
      toast.warn("One on One session Price cannot be zero!")
      return;
    }
    if (tutorUserPrice?.tutorUserPrice?.minGroupCoursePrice === 0) {
      toast.warn("Group session Price cannot be zero!")
      return;

    }
    const pricingPreference = await generatePricingPref()
    dispatcher(updateTutorUserPriceByUserId(user?.user?.userId, pricingPreference))
  }

  return (
    <div className="">
      <PricePreferenceHeader />
      {tutorUserPrice?.isLoading &&
        <ComponentLoader isLoading={tutorUserPrice?.isLoading} className={"min-h-[16rem]"} />
      }
      {(!tutorUserPrice?.isLoading && tutorUserPrice?.tutorUserPrice) &&
      <>
        <div className="px-4 py-10 space-y-8">

          {/* Minimum hoursly charge - 1-to-1 */}
          <div>
            <div className={"flex items-start justify-start gap-1 md:items-center"}>
              <div className={"flex items-start justify-start gap-1 md:items-center"}>
                <FaDollarSign size={15} className={"font-semibold text-gray-700 mt-1.5 md:mt-0"} />
                <p className="text-base font-semibold text-gray-700 tracking-wide">
                  {"Minimum hourly charges for One-on-One sessions"}
                </p>
              </div>
              <ToolTipView content={"Set your default hourly fee you are willing to accept after platform fee and taxes. You will be able to set fees for your classes while creating them. This input will be used for internal decision making."}>
                <button className={""}>
                  <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                </button>
              </ToolTipView>
            </div>
            <div className={"flex my-4 ml-5 space-x-4"}>
              <div>
                <p className="text-sm font-medium">
                  {"Price"}
                </p>
                <InputFieldWithPrefix
                  onChange={(e) => {
                    let safe_number = Number(e.target.value)
                    if (isNaN(safe_number)) {
                      safe_number = 0
                    }
                    dispatcher(setTutorUserPrice({
                      ...tutorUserPrice?.tutorUserPrice,
                      minOneToOneCoursePrice: (safe_number * 100)
                    }))
                  }}
                  placeholder="0.0"
                  prefix={"USD"}
                  value={(tutorUserPrice?.tutorUserPrice?.minOneToOneCoursePrice / 100)?.toString()}
                />
              </div>
            </div>
          </div>

          {/* Minimum hoursly charge - group session */}
          <div>
            <div className={"flex items-start justify-start gap-1 md:items-center"}>
              <div className={"flex items-start justify-start gap-1 md:items-center"}>
                <FaDollarSign size={15} className={"font-semibold text-gray-700 mt-1.5 md:mt-0"} />
                <p className="text-base font-semibold text-gray-700 tracking-wide">
                  {"Minimum charges for group sessions"}
                </p>
              </div>
              <ToolTipView content={"Default per session fee for 2 or more students in a group. This is the fee you are willing to accept after platform fee and taxes. You will be able to set fees for your classes while creating them. This input will be used for internal decision making."}>
                <button className={"relative has-tooltip cursor-pointer"}>
                  <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                </button>
              </ToolTipView>
            </div>
            <div className={"flex my-4 ml-5 space-x-4"}>
              <div>
                <p className="text-sm font-medium">
                  {"Price"}
                </p>
                <InputFieldWithPrefix
                  onChange={(e) => {
                    let safe_number = Number(e.target.value)
                    if (isNaN(safe_number)) {
                      safe_number = 0
                    }
                    dispatcher(setTutorUserPrice({
                      ...tutorUserPrice?.tutorUserPrice,
                      minGroupCoursePrice: (safe_number * 100)
                    }))
                  }}
                  placeholder="0.0"
                  prefix={"USD"}
                  value={(tutorUserPrice?.tutorUserPrice?.minGroupCoursePrice / 100)?.toString()}
                />
              </div>
            </div>
          </div>

          {/* % discount */}
          <div>
            <div className={"flex items-center justify-start gap-1"}>
              <div className={"flex items-center justify-start gap-1"}>
                <FaPercent size={15} className={"font-semibold text-gray-700"} />
                <p className="px-1 text-base font-semibold text-gray-700 tracking-wide">
                  {"Discounts for bulk booking"}
                </p>
              </div>
              <ToolTipView content={"Offer discounts to learners who book 5 sessions or more in one order (package of lessons). This input will be used for all packaged classes."}>
                <button className={""}>
                  <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                </button>
              </ToolTipView>
            </div>
            <div className="flex my-4 ml-5 space-x-4">
              <div className="w-52">
                <p className="text-sm font-medium">
                  {"Discount"}
                </p>
                <OptionSelector
                  onChange={(e) => {
                    dispatcher(setTutorUserPrice({
                      ...tutorUserPrice?.tutorUserPrice,
                      pctDisOnFiveOneToOneSession: Number(e.value)
                    }))
                  }}
                  options={Object.values(pricingPrefDiscountConst)}
                  value={tutorUserPrice?.tutorUserPrice?.pctDisOnFiveOneToOneSession}
                />
              </div>
            </div>
          </div>
        </div>
        {(!tutorUserPrice?.isLoading && tutorUserPrice?.errorMsg) &&
          <div className={"w-full h-full flex justify-center items-center p-5 font-bodyPri font-semibold text-text-800 text-md"}>
            {tutorUserPrice?.errorMsg}
          </div>
        }

        <div className="border-t shadow-sm">
          <div className="flex justify-center py-6 space-x-4">
            <Button name="Cancel" style="outline" size="medium" />
            {addTutorUserPrice?.isLoading &&
              <div className={"w-12 h-10 flex items-center justify-center bg-primary-dark rounded-md"}>
                <ButtonLoader isLoading={addTutorUserPrice?.isLoading} />
              </div>
            }
            {!addTutorUserPrice?.isLoading &&
              <Button name="Save" style="primary" size="medium" onClick={onConfirm} />
            }
          </div>
        </div>
      </>
      }
    </div>
  );
}
