import { masterCurrencyCodeEnum } from "redux/master/master.const";

export const addCreditToWalletPayload = {
    currencyCode: masterCurrencyCodeEnum.USD.value,
    amount: 10000,
    gatewayName: null,
    gatewayPaymentId: null,
    totalAmount: 0,
    processingFee: 0,
    requiredAmount: 0
}


export const addTokensPayload = {
    currencyCode: null,
    amount: 10000,
    gatewayName: null,
    gatewayPaymentId: null,
    payThroughWallet: true,
    payThroughGateWay: false,
    paymentMethod: null,
    totalAmount: 0,
    processingFee: 0,
    requiredAmount: 0,
    total_tokens: 0,
}

export const WALLET_INITIAL_STATE = {
    addCreditToWallet: {
        isLoading: false,
        addCreditPayload: addCreditToWalletPayload,
        addCreditToWallet: null,
        errorMsg: null,
    },
    addTokens: {
        isLoading: false,
        addTokenPayload: addTokensPayload,
        addTokenToWallet: null,
        errorMsg: null,
    },

    walletConfirmation: {
        isLoading: false,
        walletConfirmation: null,
        errorMsg: null
    },
    
    walletTxnList: {
        isLoading: false,
        walletTxnList: null,
        errorMsg: null,
    },
}