import tw, { styled } from 'twin.macro';
import { Link as RouteLink } from 'react-router-dom';
import { ScreenCard } from 'components/generalComponent/StyledComponent';

export const Container = tw(ScreenCard)``;

export const AnchorLink = tw(RouteLink)`
    text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer
`;

export const TopLink = tw.div`
    flex justify-center items-center pt-5 lg:justify-end
`;

export const SignUpCardContent = tw.div`
    px-3 min-h-full min-w-full
`;

export const SignUpCardHeader = tw.div`
    flex flex-col items-center justify-center gap-1
`;

export const Heading = tw.h1`
    text-xl font-bodyPri font-bold text-text-900
`;

export const SubHeading = tw.span`
    font-subHeading font-normal text-text-500 flex flex-col justify-center w-full gap-1 items-center text-base
    lg:flex-row
`;

export const SignUpFormWrapper = tw.div`
    my-5 px-5
`;

export const DividerWrapper = tw.div`
    md:px-10
`;

export const SocialSignUpWrapper = tw.div`
    px-5 my-5
`;

export const CheckBoxForTips = styled.div`
    ${tw`
        font-bodyPri font-normal text-sm text-text-800 mb-3
    `}
    input {
        ${tw`
            mr-2 cursor-pointer
        `}
    }
`;

export const SignUpCardFooter = tw.span`
    w-full font-bodyPri font-normal text-sm text-text-700
`;