import { useParams } from "react-router-dom";

import CourseMuxVideoPlayer from "components/courseMuxVideoPlayer/CourseMuxVideoPlayer";

import { getOfferingByCourseId } from "redux/offering/offering.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { setClearUploadDocument } from 'redux/document/document.slice';

import { MUX_DEMO_PLAYBACK_ID } from 'const/default.const';

const VideoSelector = () => {
    const { modal } = useAppState((state) => (state.local))
    const { offeringCourse } = useAppState((s) => s.offering)

    const dispatcher = useAppDispatcher()
    const { courseId } = useParams()

    const handleRecordVideo = () => {
        dispatcher(setClearUploadDocument())
        dispatcher(setModal({
            ...modal,
            [modalConst.RECORD_VIDEO_MODAL.stateKey]: true
        }))
    }

    const handleUploadVideo = () => {
        dispatcher(setClearUploadDocument())
        dispatcher(setModal({
            ...modal,
            [modalConst.UPLOAD_VIDEO_MODAL.stateKey]: true
        }))
    }

    const handleRefreshVideo = () => {
        dispatcher(getOfferingByCourseId(Number(courseId)))
    }

    return (
        <div className={"w-full h-full flex items-center justify-center rounded-t-lg"}>
            <CourseMuxVideoPlayer
                playbackId={offeringCourse?.offeringCourse?.videoUrl ? offeringCourse?.offeringCourse?.videoUrl : MUX_DEMO_PLAYBACK_ID}
                handleRecordVideo={handleRecordVideo}
                handleUploadVideo={handleUploadVideo}
                onHandleRefresh={handleRefreshVideo}
                autoPlay={false}
                withLabel={false}
                isShowRefreshButton={true}
                muted={true}
            />
        </div>
    );
};

export default VideoSelector;