import { Fragment, memo, useCallback } from "react";

import { Dialog, Transition } from "@headlessui/react";

import AddDownloadModalContent from "./AddDownloadModalContent";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

import { cn } from "utils/cn.utils";

const AddDownloadModalLayout = memo(({
    openModal = false,
    onCloseModal,
    maxWidth = "max-w-2xl",
    bgColor = "bg-white",

}) => {
    const { modal } = useAppState((state) => state.local)

    const dispatcher = useAppDispatcher()

    const closeModal = useCallback(() => {
        dispatcher(setModal({
            ...modal,
            [modalConst.addDownloadModal.key]: {
                ...modal[modalConst.addDownloadModal.key],
                isVisible: false,
                title: ""
            }
        }))
    }, [modal])

    return (
        <Transition appear show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className="fixed max-h-screen inset-0 z-50 overflow-y-auto"
                open={openModal}
                onClose={closeModal}
            >
                <div className="p-5 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span aria-hidden="true" className="inline-block h-screen align-middle">&#8203;</span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={cn(
                            "inline-block w-full shadow-xl rounded-lg space-y-5 overflow-hidden",
                            `text-left align-middle transition-all transform ${maxWidth} ${bgColor}`
                        )}>
                            <Dialog.Description as="div">
                                <div className={cn(
                                    "w-full h-full overflow-hidden overflow-y-scroll",
                                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                                )}>
                                    <AddDownloadModalContent closeModal={closeModal} />
                                </div>
                            </Dialog.Description>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
});

export default AddDownloadModalLayout;