import React from 'react'

import { AiFillCheckCircle } from "react-icons/ai";
import { MdCancel } from "react-icons/md";

const EditAndDoneBtn = ({ onSave, onCancel }) => {
    return (
        <div className={"flex items-center justify-center gap-3"}>
            <span className={""} onClick={onSave}>
                <AiFillCheckCircle className={"text-green-500 text-lg cursor-pointer"} />
            </span>
            <span className={""} onClick={onCancel}>
                <MdCancel className={"text-text-500 text-lg cursor-pointer"} />
            </span>
        </div>
    )
}

export default EditAndDoneBtn