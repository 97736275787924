import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/payout/payout.const";

class PayoutService {
    getUserPayoutListByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.USER_PAYOUTS, params)
        )

        return response
    }

    createUserPayoutDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.USER_PAYOUTS, params),
            body
        )

        return response
    }

    // getUserPayoutDetailByUserPayoutId = async ({params}) => {
    //     const response = await baseAuthApiInstance().get(
    //         format(url.USER_PAYOUT_DETAIL, params)
    //     )

    //     return response
    // }
}

export default new PayoutService();