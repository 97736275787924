import { contactTime } from "redux/lead/lead.const"

const addLeadDetailPayload = {
    journey: "",
    level: "",
    subject: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    city: "",
    selectedTime: contactTime.ANYTIME.value,
    timeZone: "",
    actionPageLink: "",
    isEmailVerified: false,
    isDone: false
}

export const LEAD_INITIAL_STATE = {
    addLeadDetail: {
        isLoading: false,
        addLeadDetail: null,
        addLeadDetailPayload: addLeadDetailPayload,
        errorMsg: null
    }
}