export const url = {
    GET_PRODUCT_DETAIL: "products/products/{productId}",
    CREATE_PRODUCT_DETAIL: "products/products",
    UPDATE_PRODUCT_DETAIL: "products/products/{productId}",

    CREATE_PRODUCT_PRICE_DETAIL: "products/prices",
    UPDATE_PRODUCT_PRICE_DETAIL: "products/prices/{productPriceId}",

    CREATE_PRODUCT_SLUG_DETAIL: "products/slugs",
    UPDATE_PRODUCT_SLUG_DETAIL: "products/slugs/{productSlugId}"
}

export const productTypeEnum = {
    NONE: {
        label: "None",
        value: "none"
    },
    LIVE_COURSE: {
        label: "Live course",
        value: "live_course"
    },
    LMS_COURSE: {
        label: "Lms course",
        value: "lms_course"
    },
    EVENTLY: {
        label: "Evently",
        value: "evently"
    },
    QUIZ: {
        label: "Quiz",
        value: "quiz"
    },
    DOWNLOADABLE: {
        label: "Download",
        value: "downloadable"
    }
}

export const productPriceTypeEnum = {
    ONE_TIME: {
        label: "One Time",
        value: "one_time"
    },
    RECURRING: {
        label: "Recurring",
        value: "recurring"
    }
}

export const productPriceStatusEnum = {
    DRAFT: {
        label: "Draft",
        value: "draft"
    },
    ACTIVE: {
        label: "Active",
        value: "active"
    },
    INACTIVE: {
        label: "InActive",
        value: "inactive"
    }
}

export const productPriceModelEnum = {
    FREE: {
        label: "Free",
        value: "free"
    },
    STANDARD: {
        label: "Standard",
        value: "standard"
    },
    PACKAGE: {
        label: "Package",
        value: "package"
    },
    GRADUATED: {
        label: "Graduated",
        value: "graduated"
    },
    VOLUME: {
        label: "Volume",
        value: "volume"
    },
    STAIRSTEP: {
        label: "Stair Step",
        value: "stairstep"
    },
    CUSTOM: {
        label: "Custom",
        value: "custom"
    }
}

export const productPriceDiscountTypeEnum = {
    NONE: {
        label: "None",
        value: "none"
    },
    PERCENTAGE: {
        label: "Percentage",
        value: "percentage"
    },
    FLAT: {
        label: "Flat",
        value: "flat"
    }
}

export const productSettingsStatusEnum = {
    DRAFT: {
        label: "Draft",
        value: "draft"
    },
    PUBLISHED: {
        label: "Published",
        value: "published"
    },
    INACTIVE: {
        label: "In active",
        value: "inactive"
    }
}

export const productSettingsVisibilityEnum = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    }
}

export const productAssetEnum = {
    IMAGE: {
        label: "Image",
        value: "image"
    },
    VIDEO: {
        label: "Video",
        value: "video"
    },
    YOUTUBE: {
        label: "YouTube",
        value: "youtube"
    }
}

export const addProductDetailPayload = {
    title: "",
    description: "",
    thumbnail_pic_url: "",
    type: productTypeEnum.NONE.value,
    status: "",
    visibility: "",
    slug: "",
    product_slug_id: null
}

export const addProductPriceDetailPayload = {
    product_id: null,
    product_price_id: null,
    type: "",
    price_model: "",
    currency_code: "",
    price: "",
    unit: 1,
    discount_type: "",
    discount_price: "",
    discount_pct: "",
    make_default_price: true,
    preset_price: "",
    min_price: "",
    max_price: "",
    status: ""
}