import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { AnimatePresence, motion } from 'framer-motion';

import { MdOutlineClose } from 'react-icons/md';

import RefreshButton from "components/common-components/RefreshButton";
import FilterIcon from "components/pageFilter/FilterIcon";
import ClearFilter from "components/pageFilter/ClearFilter";

import { bookingFilters, getBookingListPayload, searchParamsInfo } from "../../data";
import BookingIdFilter from "./BookingIdFilter";
import BookingTypeFilter from "./BookingTypeFilter";
import BookingStatusFilter from "./BookingStatusFilter";

import { getTutorBookingList, getUserBookingList } from "redux/booking/booking.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { userRoles } from "redux/auth/auth.const";

export function TableFilerActions() {
  const { locals } = useAppState((s) => s.local)
  const { user } = useAppState((state) => state.user)
  const { userBookingList, tutorBookingList } = useAppState((s) => s.booking)

  const [activeFilter, setActiveFilter] = useState(null)
  const [mobileFilter, setMobileFilter] = useState(false)

  const dispatcher = useAppDispatcher()
  const location = useLocation()

  let searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])
  const bookingList = useMemo(() => locals.userRole === userRoles?.STUDENT?.value ? userBookingList : tutorBookingList, [locals.userRole, userBookingList, tutorBookingList])

  useEffect(() => {
    if (bookingList?.data?.result) {
      getBookingList()
    }
  }, [
    searchQueryParams.get(searchParamsInfo.page.key),
    searchQueryParams.get(searchParamsInfo.bookingId.key),
    searchQueryParams.get(searchParamsInfo.offeringType.key),
    searchQueryParams.get(searchParamsInfo.status.key)
  ])

  const getBookingList = async () => {
    const requestDataPayload = await getBookingListPayload(searchQueryParams)
    if (locals?.userRole === userRoles?.STUDENT?.value) {
      dispatcher(getUserBookingList(user?.user?.userId, { ...requestDataPayload }))
    }
    if (locals?.userRole === userRoles?.TUTOR?.value) {
      dispatcher(getTutorBookingList(user?.user?.tutor?.tutorId, { ...requestDataPayload }))
    }
  }

  const handleMobileFilter = () => {
    setMobileFilter(!mobileFilter)
  }

  const FilterItemList = () => {
    return (
      <>
        {Object.values(bookingFilters)?.map((filterItem, index) => (
          <div key={index}>
            {(filterItem.key === searchParamsInfo.bookingId.key) &&
              <BookingIdFilter
                filterItem={filterItem}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
              />
            }
            {(filterItem.key === searchParamsInfo.offeringType.key) &&
              <BookingTypeFilter
                filterItem={filterItem}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
              />
            }
            {(filterItem.key === searchParamsInfo.status.key) &&
              <BookingStatusFilter
                filterItem={filterItem}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
              />
            }
          </div>
        ))}
        <ClearFilter setFilterPayload={setActiveFilter} />
      </>
    )
  }

  return (
    <div className={"relative w-full px-6 py-3 bg-white shadow-md rounded-lg"}>
      <div className={"flex flex-col items-center justify-center gap-3 sm:flex-row sm:justify-between sm:gap-5"}>
        <AnimatePresence initial={mobileFilter}>
          {mobileFilter && (
            <motion.div
              initial={{ x: -200 }}
              animate={{ x: 0 }}
              exit={{ x: -200 }}
              transition={{ duration: 0.3 }}
              className='absolute left-0 top-0 h-screen w-60 flex flex-col gap-5 p-5 bg-white rounded lg:hidden shadow-md z-10'
            >
              <div className='flex justify-between items-center'>
                <h3 className='text-lg font-bodyPri font-semibold text-text-900'>{"Filter"}</h3>
                <MdOutlineClose size={20} onClick={() => setMobileFilter(false)} className='cursor-pointer' />
              </div>
              <FilterItemList />
            </motion.div>
          )}
        </AnimatePresence>
        <div className='hidden w-full lg:flex flex-grow flex-wrap justify-center items-center sm:justify-start gap-3'>
          <FilterIcon iconTooltip={"Filter Bookings"} />
          <FilterItemList />
        </div>
        <div className={"flex w-full items-center justify-between lg:justify-end gap-3"}>
          <div onClick={handleMobileFilter} className={'block lg:hidden text-primary-main font-medium hover:text-secondary-dark whitespace-nowrap cursor-pointer'}>
            {"Filter"}
          </div>
          <RefreshButton onHandleRefreshButton={getBookingList} />
        </div>
      </div>
    </div>
  );
}