import { useCallback } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { cn } from "utils/cn.utils";

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { useAppDispatcher, useAppState } from "hooks/useStore";

import { createStudentFavLmsCourseDetail } from "redux/edulyteLms/lmsCourse/lmsCourse.request";

import { pagesInfo } from "utils/pagesInfo";

function LmsFavCourseCard({ course }) {
    const { user } = useAppState((state) => state.user);
    const { addStudentFavLmsCourseDetail } = useAppState((s) => s.lms.lmsCourse)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const isFav = !!user?.user?.favoriteLmsCourses?.find((favCourse) => favCourse?.lmsCourseId === course?.id)

    const isMyOffering = user?.user?.userId === course?.course_setting?.default_owner?.tutor?.user?.userId
    const isMyCourse = !!user?.user?.bookedLmsCourses?.find(courseItem => courseItem?.lmsCourseId === course?.id)

    const onHandleFavCourse = useCallback(() => {
        if (addStudentFavLmsCourseDetail?.isLoading) return;

        dispatcher(createStudentFavLmsCourseDetail({ lms_course_id: course?.id, user_id: user?.user?.userId }))
    }, [])

    const onHandleBuyNow = useCallback(() => {
        if (isMyOffering || isMyCourse) return

    }, [])

    const onHandleGoToCourse = useCallback(() => {
        if (isMyOffering || !isMyCourse) return

        navigate(`${pagesInfo.STUDENT_LMS_COURSE.pagePath}/${course?.active_slug}/learn`)
    }, [])


    return (
        <div className={"w-[22rem] h-full rounded-md overflow-hidden shadow-all flex flex-col bg-white relative"}>
            <div className={'w-full h-40 overflow-hidden relative'}>
                <div className={'rounded-t-lg overflow-hidden h-full w-full'}>
                    <Link to={`${pagesInfo?.COURSE?.pagePath}/${course?.active_slug}`}>
                        <img
                            src={course?.thumbnail_pic_url}
                            alt={course?.title}
                            className={"w-full h-full object-cover cursor-pointer"}
                        />
                    </Link>
                </div>
                <div className={'absolute right-2 top-2 p-1.5 bg-white rounded-full shadow-md'}>
                    <span
                        onClick={onHandleFavCourse}
                        className={"cursor-pointer"}
                    >
                        {isFav
                            ? (<FavoriteIcon className='text-red-500' style={{ fontSize: "1.5rem" }} />)
                            : <FavoriteBorderIcon className='text-text-700' style={{ fontSize: "1.5rem" }} />
                        }
                    </span>
                </div>
            </div>

            <div className={"w-full flex flex-col justify-end gap-5 px-5 py-3"}>
                <div className={"w-full flex flex-col gap-1"}>
                    <Link
                        to={`${pagesInfo?.COURSE?.pagePath}/${course?.active_slug}`}
                        className={cn(
                            'text-text-900 font-bodyPri font-medium text-lg tracking-wide truncate',
                            "hover:text-secondary-main hover:underline decoration-1"
                        )}
                    >
                        {course?.title}
                    </Link>
                </div>

                {/* price */}

                {/* action buttons */}
                <motion.button
                    whileHover={{ scale: isMyOffering ? 1 : 1.05 }}
                    whileTap={{ scale: isMyOffering ? 1 : 0.95 }}
                    className={cn(
                        "px-5 h-9 w-full rounded ease-in-out duration-300",
                        "font-buttons font-medium text-base tracking-wide",
                        isMyOffering
                            ? "bg-primary-light text-text-50 cursor-not-allowed"
                            : isMyCourse
                                ? "border border-primary-dark text-primary-dark"
                                : "bg-secondary-dark hover:opacity-90 text-text-50"
                    )}
                    onClick={isMyCourse ? onHandleGoToCourse : onHandleBuyNow}
                    disabled={isMyOffering}
                >
                    {isMyCourse ? "Go to course" : "Buy Now"}
                </motion.button>
            </div>
        </div>
    );
}

export default LmsFavCourseCard;