import { useParams } from 'react-router-dom';

import { cn } from "utils/cn.utils";

const PageTabs = ({ tabs, onHandleTabs }) => {
    const params = useParams()
    const pathParam = params["*"]

    return (
        <div className="p-4 bg-white rounded-lg">
            <div className="flex flex-col sm:flex-row items-center justify-between gap-3">
                <div className="rounded-md ring-2 ring-primary-main divide-x-2 divide-primary-main overflow-hidden ">
                    {tabs?.map((tab, idx) => (
                        <button key={idx} className={cn(
                            "py-2 w-32 font-buttons font-normal text-base focus:outline-none",
                            (pathParam === tab.to)
                                ? "bg-primary-main text-white"
                                : "text-primary-main hover:bg-primary-light"
                        )}
                            onClick={() => onHandleTabs(tab)}
                        >
                            {tab.label}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PageTabs;