import React from 'react'
import CourseSearchBar from './courseFilter/CourseSearchBar'

const Header = ({ pageHeading }) => {
  return (
    <div className={"bg-primary-light pt-5 pb-12 lg:pb-16 space-y-5"}>
      <div className="mx-auto container flex justify-center flex-col items-center gap-3">
        <p className="font-tagLine font-bold text-xl md:text-2xl lg:text-3xl text-center">
          {pageHeading.heading}
        </p>
        <p className="font-normal text-md md:text-lg lg:text-xl text-center">
          {pageHeading.subHeading}
        </p>
      </div>
      <div className={"mx-auto container"}>
        <CourseSearchBar />
      </div>
      {/* <div className="grid grid-cols-3 gap-5 md:gap-10 mt-8">
            {props.filterText.steps.map((item, index) => (
              <div className="col-span-3 md:col-span-1" key={index}>
                <NumberCard num={item.step} title={item.stepText} />
              </div>
            ))}
          </div> */}
    </div>
  )
}

export default Header