import paymentService from "redux/payment/payment.service"

export const initialResponse = { isLoading: false, data: null, message: null }

export async function getProductsPrices(request) {
    try {
        const response = await paymentService.getProductsPrices(request)
        if (response.status === 200) {
            return { ...initialResponse, data: response.data?.data }
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.log(error?.response?.data?.message || error?.response?.data?.error || error);
        return {
            ...initialResponse,
            message: error?.response?.data?.message || error?.response?.data?.error || "Something went wrong",
        };
    }
}