import { toast } from "react-toastify";

import promoServices from "redux/promo/promo.service"
import {
    setVerifyPromoLoading,
    setVerifyPromo,
    setVerifyPromoErrorMsg,

    setRedeemPromoLoading,
    setRedeemPromo,
    setRedeemPromoErrorMsg,
} from "redux/promo/promo.slice"

import { getMyProfile } from 'redux/user/user.request';
import { getWalletTxnList } from 'redux/wallet/wallet.request';


export const verifyPromoCode = (body) => async (dispatch) => {
    dispatch(setVerifyPromoLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await promoServices.verifyPromo(requestData)

        if (response.status === 200) {
            dispatch(getWalletTxnList())
            dispatch(getMyProfile())
            dispatch(setVerifyPromo(response.data.data))
            toast.success(response.data.message)
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setVerifyPromoErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setVerifyPromoLoading(false))
    }
}


export const redeemPromoCode = (body) => async (dispatch) => {
    dispatch(setRedeemPromoLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await promoServices.redeemPromo(requestData)

        if (response.status === 200) {
            dispatch(getWalletTxnList())
            dispatch(getMyProfile())
            dispatch(setRedeemPromo(response.data.data))
            toast.success(response.data.message)
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setRedeemPromoErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setRedeemPromoLoading(false))
    }
}