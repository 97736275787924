export const url = {
    GET_USER_REFERRAL_LIST: "/referrals/list/user/{userId}",
    GET_REFERRAL_LIST: "/referrals/list",
    GET_REFERRAL_LIST_BY_REFERRAL_ID: "/referrals",
}

export const filterName = {
    REFERRAL: {
        value: "referral"
    }
}

export const referralStatus = {
    ALL: {
        label: 'All',
        value: 'all',
    },
    PENDING: {
        label: 'Pending',
        value: 'pending',
    },
    PURCHASED: {
        label: 'Purchased',
        value: 'purchased',
    },
    APPROVED: {
        label: 'Approved',
        value: 'approved',
    },
    REJECTED: {
        label: 'Rejected',
        value: 'rejected',
    },
}

export const referralFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: "",
    referralStatus: referralStatus.ALL.value
}