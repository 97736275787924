export const productBtnConst = {
    TITLE: {
        value: "title"
    },
    DESCRIPTION: {
        value: "description"
    },
    THUMBNAIL_PIC_URL: {
        value: "thumbnail_pic_url"
    },
    TYPE: {
        value: "type"
    },
    STATUS: {
        value: "status"
    },
    VISIBILITY: {
        value: "visibility"
    },
    SLUG: {
        value: "slug"
    },
    PRODUCT_SLUG_ID: {
        value: "default_product_slug_id"
    }
}

export const breadcrumbItemsConst = [
    {
        text: 'LMS',
        link: '/lms'
    },
    {
        text: 'Product',
        link: ''
    }
];