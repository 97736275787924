import { useAppState } from 'hooks/useStore';

const SelectThumbnails = () => {
    const { coursePreviewDetail } = useAppState((s) => s.course)

    return (
        <div className='w-full shadow-md bg-white space-y-5 p-5'>
            <div className="flex items-center justify-start gap-1">
                <div className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
                    {"Class Thumbnail"}
                </div>
            </div>
            <div className={"mx-auto w-[50%] aspect-video rounded-lg overflow-hidden shadow-all-md"}>
                <img
                    src={coursePreviewDetail?.data?.thumbnailPicUrl}
                    className={"w-full h-full object-cover"}
                    alt={"Thumbnail-photo"}
                />
            </div>
        </div>
    )
}

export default SelectThumbnails