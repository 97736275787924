import { useEffect, useState, useMemo, memo, useCallback } from 'react';

import ComponentLoader from 'components/loader/ComponentLoader';

import { tutorPriceDefaultBody, tutorPricePayloadBody } from 'pages/auth/onboarding/data';
import SaveAndResetBtn from "pages/auth/onboarding/commonComponents/ctaButtons/SaveAndResetBtn";
import AppointmentDetail from './appointmentDetail';
import SessionBookingPrice from './SessionBookingPrice';

import { updateUserDetailByUserId } from 'redux/user/user.request';

import { getTutorUserPriceByUserId, updateTutorUserPriceByUserId } from 'redux/tutor/tutor.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearAddTutorUserPrice, setClearTutorUserPrice } from 'redux/tutor/tutor.slice';

const Price = memo(({ editBtnConst, setEditBtnConst }) => {
    const { tutorUserPrice, addTutorUserPrice } = useAppState((state) => state.tutor)
    const { user, userDetail, addUserDetail } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()

    const [selectedAvailability, setSelectedAvailability] = useState(userDetail?.userDetail?.appointment)
    const [tutorPricePayload, setTutorPricePayload] = useState({
        minOneToOneCoursePrice: 0,
        minGroupCoursePrice: 0,
        pctDisOnFiveOneToOneSession: null
    })

    const isAppointmentDetailSimilar = useMemo(() => JSON.stringify(userDetail?.userDetail?.appointment?.id) === JSON.stringify(selectedAvailability?.id), [userDetail?.userDetail?.appointment, selectedAvailability])
    const isTutorPriceDetailSimilar = useMemo(() => JSON.stringify(tutorPriceDefaultBody(tutorUserPrice?.tutorUserPrice)) === JSON.stringify(tutorPricePayloadBody(tutorPricePayload)), [tutorUserPrice?.tutorUserPrice, tutorPricePayload])

    useEffect(() => {
        return () => {
            dispatcher(setClearTutorUserPrice())
            dispatcher(setClearAddTutorUserPrice())
        }
    }, [])

    useEffect(() => {
        if (user?.user?.userId) {
            dispatcher(getTutorUserPriceByUserId(user?.user?.userId))
        }
    }, [user?.user])

    useEffect(() => {
        if (tutorUserPrice?.tutorUserPrice) {
            onHandleSetPricePayload()
        }
    }, [tutorUserPrice?.tutorUserPrice])

    const onHandleSetPricePayload = useCallback(() => {
        setTutorPricePayload({
            minGroupCoursePrice: tutorUserPrice?.tutorUserPrice?.minGroupCoursePrice,
            minOneToOneCoursePrice: tutorUserPrice?.tutorUserPrice?.minOneToOneCoursePrice,
            pctDisOnFiveOneToOneSession: tutorUserPrice?.tutorUserPrice?.pctDisOnFiveOneToOneSession
        })
    }, [tutorUserPrice?.tutorUserPrice])


    const onHandleReset = useCallback(() => {
        onHandleSetPricePayload()
        setEditBtnConst(null)
    }, [])

    const onHandleSave = () => {
        if ((addUserDetail?.isLoading || addTutorUserPrice?.isLoading) || (isAppointmentDetailSimilar && isTutorPriceDetailSimilar)) return;

        if (!isAppointmentDetailSimilar) {
            const appointmentRequestPayload = {}
            if (selectedAvailability?.id) {
                appointmentRequestPayload["appointment_id"] = selectedAvailability?.id
            }
            dispatcher(updateUserDetailByUserId(user?.user?.userId, appointmentRequestPayload))
        }

        if (!isTutorPriceDetailSimilar) {
            const priceRequestPayload = {}
            if (tutorPricePayload?.minGroupCoursePrice >= 0) {
                priceRequestPayload["minGroupCoursePrice"] = tutorPricePayload?.minGroupCoursePrice
            }
            if (tutorPricePayload?.minOneToOneCoursePrice >= 0) {
                priceRequestPayload["minOneToOneCoursePrice"] = tutorPricePayload?.minOneToOneCoursePrice
            }
            if (tutorPricePayload?.pctDisOnFiveOneToOneSession) {
                priceRequestPayload["pctDisOnFiveOneToOneSession"] = tutorPricePayload?.pctDisOnFiveOneToOneSession
            }

            dispatcher(updateTutorUserPriceByUserId(user?.user?.userId, priceRequestPayload))
        }

        setEditBtnConst(null)
    }

    if (userDetail?.isLoading || tutorUserPrice?.isLoading) {
        return (
            <ComponentLoader isLoading={userDetail?.isLoading || tutorUserPrice?.isLoading} className={"h-60"} />
        )
    }

    return (
        <div className={"w-full py-3 space-y-5"}>

            <AppointmentDetail
                editBtnConst={editBtnConst}
                setEditBtnConst={setEditBtnConst}
                selectedAvailability={selectedAvailability}
                setSelectedAvailability={setSelectedAvailability}
            />
            
            <div className={"-mx-5 my-3 col-start-1 col-span-full h-0.5 border border-divider-medium"}></div>

            <SessionBookingPrice
                editBtnConst={editBtnConst}
                setEditBtnConst={setEditBtnConst}
                tutorPricePayload={tutorPricePayload}
                setTutorPricePayload={setTutorPricePayload}
            />

            <div className={"flex items-center justify-center"}>
                {(addUserDetail?.message || addTutorUserPrice?.errorMsg) &&
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {addUserDetail?.message || addTutorUserPrice?.errorMsg}
                    </span>
                }
            </div>

            <div className={"flex items-center justify-center gap-5"}>
                <SaveAndResetBtn
                    isSaveBtnLoading={addUserDetail?.isLoading || addTutorUserPrice?.isLoading}
                    saveBtnDisabled={isAppointmentDetailSimilar && isTutorPriceDetailSimilar}
                    onHandleSave={onHandleSave}
                    onHandleReset={onHandleReset}
                />
            </div>
        </div>
    )
})

export default Price;