import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import FullPageLoader from 'components/loader/FullPageLoader';
import Footer1 from 'components/footer1/Footer1';

import {
    PageContentStyle,
    LogInContainer,
    LogInCardContainer,
    ImageContainer
} from './LogInPageStyle';

import LogInCard from './logInCard/LogInCard';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { pagesInfo } from 'utils/pagesInfo';
import { useTitle } from 'hooks/useTitle';

const loginOneImageUrl = "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/auth-page/login-one.png"

const LogInPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { session } = useAppState(s => s.auth)
    const { user } = useAppState((state) => state.user)
    const { modal } = useAppState((state) => state.local)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()
    const [title, setTitle] = useTitle()

    const redirect_url = new URL(window.location.href)

    const from = location.state?.from?.url || pagesInfo.DASHBOARD.pagePath;

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.LOG_IN))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Log in to Access Your Account | Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    if (user?.isLoading) {
        return (
            <FullPageLoader isLoading={user?.isLoading} />
        )
    }

    if (session?.isLoggedIn) {
        if (!!redirect_url?.searchParams?.get("redirect_url")) {
            window.open(redirect_url?.searchParams?.get("redirect_url"), "_self")
        } if (redirect_url?.searchParams?.get("action_window") === "login_popup") {
            window.opener.postMessage({ login_popup: true, success: true }, "*");
            window.close()
        } else {
            navigate(from, { replace: true });
        }
        dispatcher(setModal({
            ...modal,
            [modalConst.RESET_PASSWORD_MODAL.stateKey]: false
        }))
    }

    return (
        <PageContentStyle>
            <div className={"my-10 md:my-0 flex justify-between items-center"}>
                <div className='-ml-[34rem] w-1/2 min-h-screen bg-primary-dark rounded-br-full'></div>
                <div className='-mr-[34rem] w-1/2 min-h-screen bg-secondary-dark rounded-tl-full'></div>
                <LogInContainer>
                    <LogInCardContainer>
                        <LogInCard />
                    </LogInCardContainer>
                    <ImageContainer>
                        <img src={loginOneImageUrl} alt={"login-one"} className={"w-full h-full object-cover"} />
                    </ImageContainer>
                </LogInContainer>
            </div>
            <Footer1 />
        </PageContentStyle>
    )
}

export default LogInPage;