import React from "react";

//Helper functions
import getTimestamps from "../../helpers/getTimestamps";

//Style imports
import {
  StyledNotificationListItem,
  StyledNotificationListItemName,
  StyledNotificationListItemDate,
} from "./NotificationListItem.styles";

export default function NotificationListItem({ notification }) {
  const { fromName, sentAt, type } = notification;
  const senderName = fromName.split(" ")[0];
  const timeStamp = getTimestamps(sentAt);

  function notificationText() {
    if (type === "text") return "new message";
    if (type === "paymentRequest") return "new payment request";
    if (type === "course") return "new course";
    if (type === "file") return "new file";
  }

  const getNotificationText = notificationText();

  return (
    <StyledNotificationListItem>
      <StyledNotificationListItemName>{`${senderName} `}</StyledNotificationListItemName>
      {`has sent you a ${getNotificationText}`}
      <StyledNotificationListItemDate>
        {timeStamp}
      </StyledNotificationListItemDate>
    </StyledNotificationListItem>
  );
}
