import tw, { styled } from 'twin.macro';
import { motion } from 'framer-motion';

import { PageContent } from 'components/generalComponent/StyledComponent';

export const PageContentStyle = tw(PageContent)``;

export const CollapsableContainer = motion(styled.section`
    ${tw`
        px-10 bg-white rounded-lg overflow-hidden
        font-bodyPri font-normal text-base text-text-800
    `}
`);

export const MoreDetailsCollapsable = motion(styled.section`
    ${tw`
        bg-white rounded-lg overflow-hidden
        font-bodyPri font-normal text-base text-text-800
    `}
`);